import { useEffect, useRef } from 'react';

export const actions = {
	CONCATENATE: 'concatenate',
	INCREMENT: 'increment',
	DECREMENT: 'decrement',
	SLICE: 'slice',
	DECIMAL: 'decimal',
};

const useNumPad = (
	value,
	setValue,
	initValue = 0,
	max = null,
	min = 0,
	decimalLimit = 2,
	onEnter = null,
	customValidate = null,
	disabled = false
) => {
	const changed = useRef(false);

	const applyInput = (operation, amount = null, _changed = null) => {
		let _value;

		if (_changed !== null) _value = _changed ? value : '';
		else _value = changed.current ? value : '';

		changed.current = true;
		switch (operation) {
			case actions.CONCATENATE:
				// if value is 0. overwrite it with new input, amount is key
				if (_value === '0') setValue(validate(amount));
				else setValue(validate(_value + amount));
				break;
			case actions.INCREMENT:
				if (_value === '') _value = initValue.toString();
				setValue(validate((parseFloat(_value) + 1).toString()));
				break;
			case actions.DECREMENT:
				if (_value === '') _value = initValue.toString();
				setValue(validate((parseFloat(_value) - 1).toString()));
				break;
			case actions.SLICE:
				setValue(validate(_value.slice(0, -1)));
				break;
			case actions.DECIMAL:
				if (_value.indexOf('.') !== -1) break;
				setValue(`${_value}.`);
				break;
			default:
				break;
		}
	};

	const validate =
		customValidate ||
		(newValue => {
			if (newValue === '') return '0';

			if (max && parseFloat(newValue) > max) return max.toString();

			if (parseFloat(newValue) < min) return min.toString();

			if (newValue.indexOf('.') !== -1 && newValue.split('.')[1].length > decimalLimit)
				return value;

			return newValue;
		});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getKeyPress = event => {
		if (disabled) return;

		const eventKeyCode = event.keyCode;
		switch (eventKeyCode) {
			case 13:
				if (onEnter) onEnter();
				break;
			// decimal point input
			case 110:
			case 188:
			case 190:
				applyInput(actions.DECIMAL);
				break;

			// decrement input
			case 109:
			case 189:
				applyInput(actions.DECREMENT);
				break;

			// increment input
			case 107:
				applyInput(actions.INCREMENT);
				break;

			// backspacing
			case 8:
				// to prevent going back to previous page in some browsers
				event.preventDefault();
				applyInput(actions.SLICE);
				break;

			/* Numeric Inputs */
			case 48:
			case 96:
				applyInput(actions.CONCATENATE, '0');
				break;
			case 49:
			case 97:
				applyInput(actions.CONCATENATE, '1');
				break;
			case 50:
			case 98:
				applyInput(actions.CONCATENATE, '2');
				break;
			case 51:
			case 99:
				applyInput(actions.CONCATENATE, '3');
				break;
			case 52:
			case 100:
				applyInput(actions.CONCATENATE, '4');
				break;
			case 53:
			case 101:
				applyInput(actions.CONCATENATE, '5');
				break;
			case 54:
			case 102:
				applyInput(actions.CONCATENATE, '6');
				break;
			case 55:
			case 103:
				applyInput(actions.CONCATENATE, '7');
				break;
			case 56:
			case 104:
				applyInput(actions.CONCATENATE, '8');
				break;
			case 57:
			case 105:
				applyInput(actions.CONCATENATE, '9');
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', getKeyPress);
		return () => document.removeEventListener('keydown', getKeyPress);
	}, [getKeyPress]);

	return [applyInput];
};

export default useNumPad;
