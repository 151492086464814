import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../app/contexts/UserContext';
import pages from '../../pages';

import FormContainer from '../../reusables/template/FormContainer';
import RoleForm from './RoleForm';

const RoleEdit = ({ match }) => {
	const userContext = useContext(UserContext);
	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle={pages.systemSettings.roles.text}
			dataName={pages.systemSettings.roles.route}
			lists={['permissions', 'roleLevels']}
			fields={<RoleForm />}
			afterSubmit={data => {
				if (
					userContext.data.user.userRoleOutlets.findIndex(
						ro => ro.role.id === data.id
					) !== -1
				)
					userContext.updatePermissions(data, 'permissions', data.id);
			}}
		/>
	);
};

RoleEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default RoleEdit;
