import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';
import List from '../../reusables/template/List';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import ListContainer from '../../reusables/template/ListContainer';
import forms from '../../forms';
import HeaderContext from '../../../app/contexts/HeaderContext';

const ProductCategoryCampgroundList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
			{ title: pages.campground.settings.text, path: pages.campground.settings.path },
			{ title: pages.campground.settings.productCategories.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.campground.settings.productCategories.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='productCategoryCampgrounds'
			title={pages.campground.settings.productCategories.text}
			history={history}
			icon={pages.campground.settings.productCategories.icon}
			forms={forms.campground.settings.productCategories}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
ProductCategoryCampgroundList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default ProductCategoryCampgroundList;
