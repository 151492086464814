import { useContext, useEffect, useState } from 'react';
import UserContext from '../../app/contexts/UserContext';
import defaultPages from '../../components/pages';

const usePages = (settings = null) => {
	const userContext = useContext(UserContext);

	const [pages, setPages] = useState(defaultPages);

	useEffect(() => {
		const _settings = settings || userContext?.data?.selectedOutlet?.settings;

		if (_settings) {
			const {
				bookingCaption,
				bookingIcon,
				marinaCaption,
				marinaIcon,
				campgroundCaption,
				campgroundIcon,
			} = _settings;

			if (bookingCaption && pages.booking.default.text !== bookingCaption) {
				pages.booking.default.text = bookingCaption;

				pages.reports.booking.text = bookingCaption;
			}

			if (bookingIcon && pages.booking.default.icon !== bookingIcon) {
				pages.booking.default.icon = bookingIcon;

				pages.reports.booking.icon = bookingIcon;
			}

			if (marinaCaption && pages.marina.default.text !== marinaCaption) {
				pages.marina.default.text = marinaCaption;

				pages.reports.marina.text = marinaCaption;
			}

			if (marinaIcon && pages.marina.default.icon !== marinaIcon) {
				pages.marina.default.icon = marinaIcon;

				pages.reports.marina.icon = marinaIcon;
			}

			if (campgroundCaption && pages.campground.default.text !== campgroundCaption) {
				pages.campground.default.text = campgroundCaption;

				pages.reports.campground.text = campgroundCaption;
			}

			if (campgroundIcon && pages.campground.default.icon !== campgroundIcon) {
				pages.campground.default.icon = campgroundIcon;

				pages.reports.campground.icon = campgroundIcon;
			}

			setPages({ ...pages });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userContext.data, settings]);

	return pages;
};

export default usePages;
