import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import useModal from '../../../utils/hooks/useModal';
import useDate from '../../../utils/hooks/useDate';
import { addErrorNotification, addSuccessNotification } from '../../../utils/helpers/helper';
import apiCall from '../../../utils/helpers/apiCall';
import { filterComponents } from '../../../utils/constants/constants';

import Button from '../../reusables/element/Button';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import Dropdown from '../../reusables/element/Dropdown';
import CustomerModal from '../../reusables/modals/CustomerModal';
import DialogBox from '../../reusables/element/DialogBox';
import Badge from '../../reusables/element/Badge';
import AccountingExport from '../../reusables/modals/AccountingExport';

const customFilters = [
	{
		name: 'statusFilter',
		component: filterComponents.RADIO,
		fieldName: 'status',
		default: `e,i`,
		options: [
			{
				display: 'All',
				value: `e,i,s,q`,
			},
			{
				display: 'Error',
				value: `e,i`,
			},
			{
				display: 'Success',
				value: `s`,
			},
			{
				display: 'In-Queue',
				value: `q`,
			},
		],
	},
];

const DateTimeCell = ({ data }) => {
	const [dateFormatter] = useDate();

	return <span>{dateFormatter(data.enqueue_datetime)}</span>;
};

DateTimeCell.propTypes = {
	data: PropTypes.shape({
		enqueue_datetime: PropTypes.string,
	}),
};
DateTimeCell.defaultProps = {
	data: {
		enqueue_datetime: null,
	},
};

const IdentCell = ({ data }) => {
	const pages = usePages();

	const identLinks = {
		AccountAdd: pages.accounting.chartOfAccounts.path,
		AccountMod: pages.accounting.chartOfAccounts.path,
		CustomerAdd: pages.crm.customers.path,
		CustomerMod: pages.crm.customers.path,
		ItemDiscountAdd: pages.accounting.settings.discount.path,
		ItemDiscountMod: pages.accounting.settings.discount.path,
		TermAdd: pages.accounting.settings.paymentTerms.path,
		SalesTaxCodeAdd: pages.accounting.settings.taxCode.path,
		SalesTaxCodeMod: pages.accounting.settings.taxCode.path,
		VendorAdd: pages.accounting.settings.taxAgency.path,
		VendorMod: pages.accounting.settings.taxAgency.path,
		ItemSalesTaxAdd: pages.accounting.settings.taxRate.path,
		ItemSalesTaxMod: pages.accounting.settings.taxRate.path,
		SalesReceiptAdd: pages.crm.invoices.path,
		InvoiceAdd: pages.crm.invoices.path,
		InvoiceMod: pages.crm.invoices.path,
		InvoiceVoid: pages.crm.invoices.path,
		ReceivePaymentAdd: pages.crm.payments.path,
		ReceivePaymentMod: pages.crm.payments.path,
		DepositAdd: pages.crm.payments.path,
		CreditMemoAdd: pages.crm.creditMemos.path,
		CreditMemoMod: pages.crm.creditMemos.path,
		CheckAdd: pages.crm.refunds.path,
		ChargeAdd: pages.crm.statementCharges.path,
		ChargeMod: pages.crm.statementCharges.path,
	};

	if (identLinks[data.qb_action])
		return (
			<a
				href={`${window.location.origin}${identLinks[data.qb_action]}/${data.ident}`}
				target='_blank'
				rel='noopener noreferrer'>
				{data.ident}
			</a>
		);

	return data.ident;
};

IdentCell.propTypes = {
	qb_action: PropTypes.string,
	ident: PropTypes.string,
};

IdentCell.defaultProps = {
	qb_action: '',
	ident: '',
};

const CustomerEditButton = ({ data }) => {
	const pages = usePages();

	return (
		<Dropdown.Item
			itemsColor='info'
			icon='Edit'
			target='_blank'
			to={`${pages.crm.customers.path}/${data.ident}`}
			type='NavLink'>
			Edit Customer
		</Dropdown.Item>
	);
};
CustomerEditButton.propTypes = {
	data: PropTypes.shape({
		ident: PropTypes.string,
	}),
};
CustomerEditButton.defaultProps = {
	data: { ident: '' },
};

const StatusCell = ({ data }) => {
	if (data.qb_status === 'e' || data.qb_status === 'i')
		return (
			<Badge design='danger' isInline isUnified fontWeight='bold' size='lg'>
				Error
			</Badge>
		);

	if (data.qb_status === 'q')
		return (
			<Badge design='info' isInline isUnified fontWeight='bold' size='lg'>
				In-Queue
			</Badge>
		);

	return (
		<Badge design='success' isInline isUnified fontWeight='bold' size='lg'>
			Success
		</Badge>
	);
};
StatusCell.propTypes = {
	data: PropTypes.shape({
		qb_status: PropTypes.string,
	}),
};
StatusCell.defaultProps = {
	data: { qb_status: 'e' },
};

const modals = {
	CUSTOMER: 'customer',
	CUSTOMER_MERGE_DIALOG: 'customer_merge_dialog',
	EXPORT: 'export',
	FIX_SETTLEMENTS: 'fix_Settlements',
	FIX_CLOSED_SERVICE_FEE: 'fix_closed_service_fee',
};

const ErrorLogList = ({ history }) => {
	const pages = usePages();

	const query = new URLSearchParams(history.location.search);

	const headerContext = useContext(HeaderContext);

	const [modal, openModal, closeModal] = useModal();

	const [exportTypes, setExportTypes] = useState([]);

	const customActions = {
		requeue: (log, updateData) => reQueue([log], updateData),
		ignore: (log, updateData) => ignore([log], updateData),
		editCustomer: () => {},
		mergeCustomer: (log, updateData) => openModal({ open: modals.CUSTOMER, updateData, log }),
		fixSettlements: (log, updateData) =>
			openModal({ open: modals.FIX_SETTLEMENTS, updateData, log }),
		fixClosedServiceFee: (log, updateData) =>
			openModal({ open: modals.FIX_CLOSED_SERVICE_FEE, updateData, log }),
	};

	const customMultiActions = {
		requeue: {
			func: (logs, updateData) => {
				reQueue(logs, updateData);
			},
			component: (
				<Button
					design='link'
					text='Requeue'
					icon='Repeat'
					size='sm'
					elevate
					key='requeue'
				/>
			),
		},
		ignore: {
			func: (logs, updateData) => {
				ignore(logs, updateData);
			},
			component: (
				<Button design='danger' text='Ignore' icon='Spy' size='sm' elevate key='ignore' />
			),
		},
	};

	const reQueue = (logs, updateData) => {
		apiCall(
			'POST',
			'accountingReQueue',
			res => {
				addSuccessNotification('Item(s) successfully requeued');
				updateData(
					[],
					res.logs.map(l => l.id)
				);
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ logs }
		);
	};

	const ignore = (logs, updateData) => {
		apiCall(
			'POST',
			'accountingIgnoreLog',
			res => {
				addSuccessNotification('Item(s) successfully ignored');
				updateData(
					[],
					res.logs.map(l => l.id)
				);
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ logs }
		);
	};

	const onCustomerMerge = (log, customer, updateData) => {
		apiCall(
			'POST',
			'accountingMergeCustomers',
			() => {
				addSuccessNotification('Customers successfully merged.');
				updateData([], [log.id]);
				closeModal();
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ log, customer }
		);
	};

	const onFixSettlements = (log, updateData) => {
		apiCall(
			'POST',
			'accountingFixSettlements',
			() => {
				addSuccessNotification('Settlements successfully fixed and action requeued');
				updateData([], [log.id]);
				closeModal();
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ log }
		);
	};

	const onFixClosedServiceFee = (log, updateData) => {
		apiCall(
			'POST',
			'accountingFixClosedServiceFee',
			res => {
				addSuccessNotification(res.message);
				updateData([], [log.id]);
				closeModal();
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ log }
		);
	};

	useEffect(() => {
		if (exportTypes.length === 0) {
			apiCall(
				'GET',
				'enumExportTypes',
				res => {
					setExportTypes(res);
				},
				err => {
					addErrorNotification(err.toString());
				},
				''
			);
		}

		headerContext.setBreadcrumbs([
			{ title: pages.accounting.default.text, path: pages.accounting.dashboard.path },
			{
				title: pages.accounting.qbErrorLogs.text,
				path: pages.accounting.qbErrorLogs.path,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(pages.accounting.qbErrorLogs.text);

		// clear query string
		const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

		window.history.replaceState({ path: url }, '', url);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ListContainer
				route='accountingErrorLogs'
				title={pages.accounting.qbErrorLogs.text}
				history={history}
				icon={pages.accounting.qbErrorLogs.icon}
				checkEditable={() => {
					return ['requeue', 'ignore'];
				}}
				customMultiActions={customMultiActions}
				customActions={customActions}
				customFilters={customFilters}
				customFiltersInQuickPanel={false}
				defaultSearchText={query.get('s') ?? ''}
				headerActions={[
					{
						component: (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Dropdown
									title='Export'
									label='default'
									color='default'
									aligned='right'
									icon='Export'
									outline={false}
									arrow={false}>
									{exportTypes.map(type => (
										<Dropdown.Item
											icon='Subtract'
											key={type.id}
											onClick={() =>
												openModal({ open: modals.EXPORT, type })
											}>
											{type.value}
										</Dropdown.Item>
									))}
								</Dropdown>
							</div>
						),
					},
				]}>
				<List
					withCheckBox
					fluid='fluid'
					checkEditable={log => {
						if (log.qb_status !== 'e' && log.qb_status !== 'i') return [];

						const actions = ['requeue', 'ignore'];

						if (log.qb_action === 'CustomerAdd' && log.msg.includes('3100'))
							actions.push('editCustomer', 'mergeCustomer');

						if (
							(log.qb_action === 'ReceivePaymentAdd' ||
								log.qb_action === 'ReceivePaymentMod') &&
							log.msg.includes('specified in the request cannot be found.')
						)
							actions.push('fixSettlements');

						if (
							log.qb_action === 'InvoiceMod' &&
							log.msg.includes('3171: An attempt was made to modify a Invoice')
						) {
							actions.push('fixClosedServiceFee');
						}

						return actions;
					}}>
					<List.Col
						label='ID'
						cellData='quickbooks_queue_id'
						width={120}
						sortable='quickbooks_queue_id'
					/>
					<List.Col
						label='Action'
						cellData='qb_action'
						width={150}
						sortable='qb_action'
					/>
					<List.Col
						label='Ident'
						cellComponent={<IdentCell />}
						width={120}
						sortable='ident'
					/>
					<List.Col label='Status' cellComponent={<StatusCell />} width={120} />
					<List.Col label='Message' cellData='msg' />
					<List.Col
						label='Date'
						cellComponent={<DateTimeCell />}
						width={150}
						sortable='enqueue_datetime'
					/>
					<List.Col align='right' onlyHover width={180}>
						<Button
							className='sdms-margin-r-15'
							design='link'
							text='Requeue'
							icon='Repeat'
							size='sm'
							elevate
							key='requeue'
						/>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<CustomerEditButton key='editCustomer' />
							<Dropdown.Item itemsColor='info' icon='Edit' key='mergeCustomer'>
								Merge Customer
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='info'
								icon='Screwdriver'
								key='fixSettlements'>
								Fix Settlements
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='info'
								icon='Screwdriver'
								key='fixClosedServiceFee'>
								Fix Closed Fee
							</Dropdown.Item>
							<Dropdown.Item itemsColor='danger' icon='Spy' key='ignore'>
								Ignore
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			<CustomerModal
				onClose={closeModal}
				setSelectedCustomer={customer => {
					if (customer.id.toString() === modal.log.ident) {
						addErrorNotification(
							'You cannot select same customer.Please select different customer to merge'
						);
						return;
					}

					openModal({ open: modals.CUSTOMER_MERGE_DIALOG, customer });
				}}
				isOpen={modal.open === modals.CUSTOMER}
				closeOnSelect={false}
			/>
			<DialogBox
				open={modal.open === modals.CUSTOMER_MERGE_DIALOG}
				title=''
				content='Customers will merge. Are you sure?'
				type='question'>
				<Button
					className='sdms-font-transform-c'
					text='Yes, Merge'
					label='success'
					icon='Edit'
					onClick={() => onCustomerMerge(modal.log, modal.customer, modal.updateData)}
				/>
				<Button
					className='sdms-font-transform-c'
					text='No'
					design='clean'
					icon='Edit'
					onClick={closeModal}
				/>
			</DialogBox>
			<AccountingExport
				isOpen={modal.open === modals.EXPORT}
				type={modal.type}
				onClose={closeModal}
			/>
			<DialogBox
				open={modal.open === modals.FIX_SETTLEMENTS}
				title=''
				content='Are you sure about fixing settlements?'
				type='question'>
				<Button
					className='sdms-font-transform-c'
					text='Yes, Fix'
					label='success'
					icon='Edit'
					onClick={() => onFixSettlements(modal.log, modal.updateData)}
				/>
				<Button
					className='sdms-font-transform-c'
					text='No'
					design='clean'
					icon='Edit'
					onClick={closeModal}
				/>
			</DialogBox>
			<DialogBox
				open={modal.open === modals.FIX_CLOSED_SERVICE_FEE}
				title=''
				content='Are you sure about fixing closed fee?'
				type='question'>
				<Button
					className='sdms-font-transform-c'
					text='Yes, Fix'
					label='success'
					icon='Edit'
					onClick={() => onFixClosedServiceFee(modal.log, modal.updateData)}
				/>
				<Button
					className='sdms-font-transform-c'
					text='No'
					design='clean'
					icon='Edit'
					onClick={closeModal}
				/>
			</DialogBox>
		</>
	);
};
ErrorLogList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
		location: PropTypes.object,
	}).isRequired,
};

export default ErrorLogList;
