import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import { bookingPeriods } from '../../../utils/constants/constants';
import { modules, pathToUrl } from '../../../utils/helpers/apiCall';
import { priceFormatter, randomColor } from '../../../utils/helpers/helper';
import forms from '../../forms';
import Button from '../../reusables/element/Button';
import Badge from '../../reusables/element/Badge';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import SVGIcon from '../../reusables/element/SVGIcon';
import AvailabilityCalendarModal from '../../reusables/modals/AvailabilityCalendarModal';

const ImageCell = ({ data }) => {
	if ((data?.productImages || []).length > 0)
		return <img alt={data.name} src={pathToUrl(data.productImages[0].thumb)} />;

	return (
		<Badge
			design={randomColor(data.id)}
			className='sdms-font-transform-u'
			isUnified
			size='lg'
			isRounded
			fontWeight='bold'>
			{data.name.charAt(0)}
		</Badge>
	);
};
ImageCell.propTypes = {
	data: PropTypes.shape({
		name: PropTypes.string,
		productImage: PropTypes.shape({ thumb: PropTypes.string }),
		id: PropTypes.number,
	}),
};
ImageCell.defaultProps = {
	data: { name: 'Product', productImage: { thumb: null }, id: 0 },
};

const PriceCell = ({ data }) => {
	if (data.price > 0) return <>{priceFormatter(data.price)}</>;
	return (
		<Badge design='success' isInline isUnified size='lg' fontWeight='bold'>
			Free
		</Badge>
	);
};
PriceCell.propTypes = {
	data: PropTypes.shape({
		price: PropTypes.number,
	}),
};
PriceCell.defaultProps = {
	data: { price: 0 },
};

const CategoryCell = ({ data }) => {
	if (data.productCategory === null) return null;
	return (
		<>
			<Badge design='brand' isInline isUnified size='lg'>
				{data.productCategory.name}
			</Badge>
		</>
	);
};
CategoryCell.propTypes = {
	data: PropTypes.shape({
		productCategory: PropTypes.shape({
			name: PropTypes.string,
		}),
	}),
};
CategoryCell.defaultProps = {
	data: {
		productCategory: {
			name: null,
		},
	},
};

const TypeCell = ({ data }) => {
	return (
		<Badge design='brand' isDot className='sdms-font-transform-c'>
			{data.bookingType.name}
		</Badge>
	);
};
TypeCell.propTypes = {
	data: PropTypes.shape({
		bookingType: PropTypes.shape({ name: PropTypes.string }),
	}),
};
TypeCell.defaultProps = {
	data: {
		bookingType: {
			name: null,
		},
	},
};

export const PeriodCell = ({ data }) => {
	const pages = usePages();

	const getIcon = periodValue => {
		switch (periodValue) {
			// Hourly
			case bookingPeriods.HOURLY:
				return {
					icon: pages.booking.products.period.hourly.icon,
					color: pages.booking.products.period.hourly.color,
				};
			// Daily
			case bookingPeriods.DAILY:
				return {
					icon: pages.booking.products.period.daily.icon,
					color: pages.booking.products.period.daily.color,
				};
			// Nightly
			case bookingPeriods.NIGHTLY:
				return {
					icon: pages.booking.products.period.nightly.icon,
					color: pages.booking.products.period.nightly.color,
				};
			default:
				return 'Layers';
		}
	};
	return (
		<Badge
			design={getIcon(data.bookingPeriod.value).color}
			isInline
			isUnified
			size='xl'
			className='sdms-font-transform-c'>
			<SVGIcon
				name={getIcon(data.bookingPeriod.value).icon}
				fill={`var(--${getIcon(data.bookingPeriod.value).color})`}
				className='sdms-mr-5'
			/>
			{data.bookingPeriod.value}
		</Badge>
	);
};
PeriodCell.propTypes = {
	data: PropTypes.shape({
		bookingPeriod: PropTypes.shape({ id: PropTypes.number, value: PropTypes.string }),
	}),
};
PeriodCell.defaultProps = {
	data: {
		bookingPeriod: {
			id: null,
			value: null,
		},
	},
};

const ProductBookingList = ({ history }) => {
	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const pages = usePages();

	const [availabilityModalProducts, setAvailabilityModalProducts] = useState([]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.booking.default.text, path: pages.booking.dashboard.path },
			{
				title: pages.booking.products.text,
				path: pages.booking.products.path,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(pages.booking.products.text);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ListContainer
				route='productBookings'
				title={pages.booking.products.text}
				history={history}
				icon={pages.booking.products.icon}
				forms={forms.booking.products}
				module={modules.BOOKINGS}
				customActions={{
					availability: product => setAvailabilityModalProducts([product]),
				}}
				customMultiActions={{
					availability: {
						func: products => setAvailabilityModalProducts(products),
						component: (
							<Button
								label='primary'
								icon='Done-circle'
								text='Availability'
								key='availability'
							/>
						),
					},
				}}
				staticFilters={{ 'bookingType.inactive': false }}>
				<List withCheckBox fluid='fluid' withImage>
					<List.Col label='Image' cellComponent={<ImageCell />} width={70} />
					<List.Col
						label='Product Id'
						width={130}
						cellData='productId'
						isLinkColumn
						sortable='productId'
					/>
					<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
					<List.Col label='Price' cellComponent={<PriceCell />} sortable='price' />
					<List.Col
						label='Category'
						cellComponent={<CategoryCell />}
						sortable='productCategory.name'
					/>
					<List.Col
						label='Type'
						cellComponent={<TypeCell />}
						sortable='bookingType.name'
					/>
					<List.Col
						label='Period'
						cellComponent={<PeriodCell />}
						sortable='bookingPeriod.value'
					/>
					<List.Col align='right' onlyHover width={150}>
						<Button
							className='sdms-margin-r-15'
							design='link'
							text='Edit'
							icon='Edit'
							size='sm'
							elevate
							key='edit'
							noPermission={!userContext.hasPermission('edit_bookings_products')}
						/>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item
								icon='Done-circle'
								text='Availability'
								key='availability'>
								Availability
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='danger'
								icon='Trash'
								key='delete'
								noPermission={
									!userContext.hasPermission('delete_bookings_products')
								}>
								Delete
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			<AvailabilityCalendarModal
				isOpen={availabilityModalProducts.length > 0}
				onClose={() => setAvailabilityModalProducts([])}
				products={availabilityModalProducts.map(p => p.id)}
				inModal
				title={`Availability - ${availabilityModalProducts.map(p => p.name).join(', ')}`}
				outlet={userContext.data.selectedOutlet.id}
			/>
		</>
	);
};
ProductBookingList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default ProductBookingList;
