import React from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import ModifierGroupForm from './ModifierGroupForm';
import FormContainer from '../../reusables/template/FormContainer';
import pages from '../../pages';
import useOutlet from '../../../utils/hooks/useOutlet';

const ModifierGroupEdit = ({ match }) => {
	const [outletKey] = useOutlet(true);

	return (
		<DndProvider backend={HTML5Backend}>
			<FormContainer
				key={outletKey}
				dataId={match.params.id}
				dataName='modifierGroups'
				lists={['prepStations', 'modifierPrefixes']}
				fields={<ModifierGroupForm />}
				listUrl={pages.pos.modifierGroups.path}
			/>
		</DndProvider>
	);
};

ModifierGroupEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ModifierGroupEdit;
