import React, { useRef } from 'react';
import classNames from 'classnames';

import { priceFormatter } from '../../../../../utils/helpers/helper';

import Portlet from '../../../layout/Portlet';
import Section from '../../../layout/Section';
import Button from '../../../element/Button';
import FormGroup from '../../../layout/FormGroup';
import FormField from '../../../template/FormField';
import Input from '../../../field/Input';
import Toggle from '../../../field/Toggle';
import Selects from '../../../field/Selects';
import Alert from '../../../element/Alert';
import Separator from '../../../layout/Separator';
import Textarea from '../../../field/Textarea';
import Loading from '../../../template/Loading';

const ReservationAdvancedTransientItemFormLoading = () => {
	const refHead = useRef(null);

	const submitButtonAttr = {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	};

	const _Input = (
		<Input type='text' placeholder='Placeholder' value='' onChange={() => {}} disabled />
	);

	const _Toggle = <Toggle value={false} onChange={() => {}} disabled />;

	const _Select = (
		<Selects options={[]} placeholder='Placeholder' value={null} onChange={() => {}} disabled />
	);

	const _TextArea = <Textarea onChange={() => {}} value='' disabled />;

	return (
		<Portlet
			fluid='fluid'
			className='sdms-bg-transparent sdms-portlet--unelevate sdms-t-modifier__container'>
			<Portlet.Body className='sdms-last-margin sdms-p0'>
				<Portlet stickyTop={0} className='flex-shrink-0' ref={refHead}>
					<Portlet.Head>
						<Portlet.HeadLabel>
							<Loading isLoading type='div'>
								<>
									<h3 className='sdms-portlet__head-title'>Booking Loading</h3>
									<Portlet.Separator />
									<h3 className='sdms-portlet__head-title'>
										Motel
										<small>
											Total <b>{priceFormatter(0)}</b>
										</small>
									</h3>
								</>
							</Loading>
						</Portlet.HeadLabel>
					</Portlet.Head>
				</Portlet>
				<Portlet className='flex-grow-0 sdms-portlet__modifiers sdms-min-h-fit-content'>
					<Portlet.Body>
						<FormGroup isLast>
							<Loading isLoading>
								<FormField name='fromDate' label='Start date' id={0} colMd={3}>
									{_Input}
								</FormField>
							</Loading>
							<Loading isLoading>
								<FormField name='fromDate' label='Start date' id={0} colMd={3}>
									{_Input}
								</FormField>
							</Loading>
							<Loading isLoading>
								<FormField name='fromDate' label='Start date' id={0} colMd={3}>
									{_Input}
								</FormField>
							</Loading>
							<Loading isLoading>
								<FormField name='fromDate' label='Start date' id={0} colMd={3}>
									{_Input}
								</FormField>
							</Loading>
							<Loading isLoading>
								<FormField name='fromDate' label='Start date' id={0} colMd={3}>
									{_Toggle}
								</FormField>
							</Loading>
							<Loading isLoading>
								<FormField
									name='searchAvailability'
									label='Search Availability'
									id={0}
									colMd={3}>
									<Loading isLoading type='button'>
										<Button
											label='brand'
											text='Search Availability'
											icon='Search'
											size='sm'
											block
											disabled
											onClick={() => {}}
										/>
									</Loading>
								</FormField>
							</Loading>
							<Loading isLoading>
								<FormField name='unit' label='Unit' id={0} colMd={3}>
									{_Select}
								</FormField>
							</Loading>
							<Loading isLoading>
								<FormField name='swapUnits' label='Swap' id={0} colMd={3}>
									<Loading isLoading type='button'>
										<Button
											label='brand'
											text='Swap Units'
											icon='Route'
											block
											size='sm'
											onClick={() => {}}
											disabled
										/>
									</Loading>
								</FormField>
							</Loading>
						</FormGroup>
					</Portlet.Body>
				</Portlet>
				<Portlet className='flex-grow-0 sdms-portlet__modifiers sdms-min-h-fit-content'>
					<Portlet.Body>
						<div className='row'>
							<div className='col-md-6'>
								<Section title='Plans'>
									<Section.Body>
										<FormGroup>
											<Loading isLoading>
												<FormField
													name='ratePlan'
													label='Rate Plan'
													id={0}
													col={12}
													description='Search Availability to update'>
													{_Select}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='policy'
													label='Cancellation Policy'
													id={0}
													col={12}>
													{_Select}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='depositAmount'
													label='Deposit Required'
													id={0}
													col={12}>
													{_Input}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='productContracts'
													label='Product Contracts'
													id={0}
													col={12}>
													{_Select}
												</FormField>
											</Loading>
										</FormGroup>
									</Section.Body>
								</Section>
							</div>
							<div className='col-md-6'>
								<Alert outline title='Pricing' design='dark' marginLess={false}>
									<div className='row align-items-baseline sdms-fitText--md sdms-mb-5'>
										<div className='col-4'>Subtotal</div>
										<Loading isLoading type='div'>
											<div className='col-8 text-right'>{_Input}</div>
										</Loading>
									</div>
									<div className='row sdms-fitText--md sdms-mb-10'>
										<div className='col'>Extras</div>
										<Loading isLoading type='div'>
											<div className='col-auto text-right'>
												<span className='sdms-mr-15'>
													{priceFormatter(0)}
												</span>
											</div>
										</Loading>
									</div>
									<div className='row sdms-fitText--md sdms-mb-10'>
										<div className='col'>Tax</div>
										<Loading isLoading type='div'>
											<div className='col-auto text-right'>
												<span className='sdms-mr-15'>
													{priceFormatter(0)}
												</span>
											</div>
										</Loading>
									</div>
									<Separator space='sm' />
									<div className='row sdms-fitText--lg sdms-font-bolder'>
										<div className='col'>Total</div>
										<Loading isLoading type='div'>
											<div className='col-auto text-right'>
												<span className='sdms-mr-15'>
													{priceFormatter(0)}
												</span>
											</div>
										</Loading>
									</div>
									<div className='sdms-mb-20' />
									<div className='row sdms-fitText--md sdms-mb-10'>
										<div className='col'>Deposit Required</div>
										<Loading isLoading type='div'>
											<div className='col-auto text-right'>
												<span className='sdms-mr-15'>
													{priceFormatter(0)}
												</span>
											</div>
										</Loading>
									</div>
									<Separator space='sm' />
									<div className='row sdms-fitText--md sdms-mb-10'>
										<div className='col'>Amount Paid</div>
									</div>
									<div className='sdms-mb-20' />
									<div className='row sdms-fitText--lg sdms-font-bolder'>
										<div className='col'>Balance</div>
										<Loading isLoading type='div'>
											<div className='col-auto text-right'>
												<span className='sdms-mr-15'>
													{priceFormatter(0)}
												</span>
											</div>
										</Loading>
									</div>
								</Alert>
							</div>
						</div>
						<Section>
							<Section.Body>
								<FormGroup>
									<Loading isLoading>
										<FormField
											name='note'
											label='Special Requests'
											id={0}
											col={12}>
											{_TextArea}
										</FormField>
									</Loading>
								</FormGroup>
							</Section.Body>
						</Section>
					</Portlet.Body>
				</Portlet>
				<Portlet stickyBottom={0} className='flex-shrink-0'>
					<Portlet.Foot
						tall='sm'
						className='sdms-align-left'
						subClassName='justify-content-between'>
						<div className='col-auto'>
							<Loading isLoading type='div'>
								<Button design='clean' text='Back' size='sm' onClick={() => {}} />
							</Loading>
						</div>
						<div className='col-auto'>
							<Loading isLoading type='div'>
								<Button
									className={classNames('sdms-mw-105 justify-content-center')}
									label={submitButtonAttr.color}
									text={submitButtonAttr.text}
									icon={submitButtonAttr.icon}
									size='sm'
									disabled
									onClick={() => {}}
								/>
							</Loading>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Portlet.Body>
		</Portlet>
	);
};

export default ReservationAdvancedTransientItemFormLoading;
