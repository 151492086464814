import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { priceFormatter } from '../../../../../utils/helpers/helper';
import { invoiceStatuses } from '../../../../../utils/constants/constants';

import SVGIcon from '../../../element/SVGIcon';
import Loading from '../../../template/Loading';

const IncidentCharges = ({ reservation, reservationItem, isLoading, disabled, onAdd, onView }) => {
	const amount = useMemo(
		() =>
			reservation
				? reservation.orderSaleInvoices
						.filter(
							invoice =>
								invoice.reservationItemId === reservationItem.id &&
								invoice.status.value !== invoiceStatuses.VOIDED
						)
						.reduce((partialSum, invoice) => partialSum + invoice.total, 0)
				: 0,
		[reservation, reservationItem]
	);

	return (
		<div className='row sdms-item-card-incidental-charges'>
			<div className='col sdms-paddingless sdms-align-left'>
				Incidental Charges
				<SVGIcon
					className={classNames('sdms-ml-5', {
						'sdms-cursor--pointer': !disabled,
						'sdms-pointer-events--not-allowed': disabled,
					})}
					name='Plus'
					size={16}
					onClick={() => {
						if (!isLoading && !disabled) onAdd(reservationItem);
					}}
				/>
			</div>
			<div
				className='col-auto sdms-paddingless sdms-text-dec-line--underline sdms-cursor--pointer'
				role='presentation'
				onClick={() => onView(reservationItem)}>
				<Loading isLoading={isLoading} type='span'>
					<span>{priceFormatter(amount)}</span>
				</Loading>
			</div>
		</div>
	);
};

IncidentCharges.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	isLoading: PropTypes.bool,
	disabled: PropTypes.bool,
	onAdd: PropTypes.func,
	onView: PropTypes.func,
};

IncidentCharges.defaultProps = {
	reservation: null,
	reservationItem: null,
	isLoading: false,
	disabled: false,
	onAdd: () => {},
	onView: () => {},
};

export default IncidentCharges;
