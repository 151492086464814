import FileSaver from 'file-saver';
import { SELECTED_OUTLET_STORAGE_KEY, SELECTED_REGISTER_STORAGE_KEY } from '../constants/constants';

const headers = {
	'Content-Type': 'application/ld+json;charset=UTF-8',
	Accept: 'application/ld+json',
};

const endpoints = {
	acctAccounts: '/api/acct_accounts',
	acctSubtotals: '/api/acct_subtotals',
	acctProducts: '/api/acct_products',
	acctClasses: '/api/acct_classes',
	accounts: '/api/sdms_accounts',
	accountTypes: '/api/enum_account_types',
	accountDetailTypes: '/api/sdms_account_detail_types',
	accountingErrorLogs: '/accounting/error-logs',
	accountingReQueue: '/accounting/requeue',
	accountingIgnoreLog: '/accounting/ignore-log',
	accountingMergeCustomers: '/accounting/merge-customers',
	accountingFixSettlements: '/accounting/fix-settlements',
	accountingFixClosedServiceFee: '/accounting/fix-closed-service-fee',
	attachmentTypes: '/api/sdms_attachment_types',
	authorizePayment: '/pay/authorize-payment',
	bookingExtraCharges: '/api/sdms_product_extra_charges',
	bookingTypes: '/api/sdms_booking_types',
	customReservationStatuses: '/api/sdms_custom_reservation_statuses',
	bookingUnits: '/api/sdms_unit_bookings',
	productBookings: '/api/sdms_product_transients',
	bookingTypeBookings: '/api/sdms_booking_type_bookings',
	calendars: '/api/sdms_calendars',
	canServerCloseOut: '/server/can-close-out',
	calendarPeriods: '/api/sdms_calendar_periods',
	clockIn: '/anonymous/clock_in',
	clockOut: '/anonymous/clock_out',
	companies: '/api/sdms_companies',
	companySettings: '/api/sdms_company_settings',
	contracts: '/api/sdms_contracts',
	countries: '/api/enum_countries',
	creditCards: '/api/sdms_credit_cards',
	creditMemoReasons: '/api/sdms_credit_memo_reasons',
	customers: '/api/sdms_customers',
	customModifiers: '/api/sdms_custom_modifiers',
	discounts: '/api/sdms_discounts',
	enumAccountTypes: '/api/enum_account_types',
	enumBoatTypes: '/api/enum_boat_types',
	enumRvTypes: '/api/enum_rv_types',
	enumBoatMakes: '/api/enum_boat_makes',
	enumFuelTypes: '/api/enum_fuel_types',
	enumExportTypes: '/api/enum_export_types',
	enumDocumentTypes: '/api/enum_document_types',
	enumVehicleMakes: '/api/enum_vehicle_makes',
	enumBookBies: '/api/enum_book_bies',
	enumBookingCalculations: '/api/enum_booking_calculations',
	enumBookingGuarantee: '/api/enum_booking_guarantees',
	enumBookingPeriods: '/api/enum_booking_periods',
	enumReservationStatuses: '/api/enum_reservation_statuses',
	enumRemittanceStatuses: '/api/enum_remittance_statuses',
	enumCalculations: '/api/enum_calculations',
	enumCategoryTypes: '/api/enum_category_types',
	enumDepositTypes: '/api/enum_deposit_types',
	enumDays: '/api/enum_days',
	enumExtraChargeTypes: '/api/enum_extra_charge_types',
	enumUnitBlockoutTypes: '/api/enum_unit_blockout_types',
	enumSearchTypes: '/api/enum_search_types',
	enumOrderStatuses: '/api/enum_order_statuses',
	enumPaymentTypes: '/api/enum_payment_types',
	enumPaymentMethods: '/api/enum_payment_methods',
	enumPricingTypes: '/api/enum_pricing_types',
	enumProductServiceFees: '/api/enum_product_service_fees',
	enumReportDataSets: '/api/enum_report_data_sets',
	enumReportTypes: '/api/enum_report_types',
	enumMailEncryptions: '/api/enum_mail_encryptions',
	enumMailAccountTypes: '/api/enum_mail_account_types',
	enumAnalyticsWidgetTypes: '/api/enum_analytics_widget_types',
	enumAnalyticsWidgetCalculations: '/api/enum_analytics_widget_calculations',
	emailAddresses: '/api/sdms_email_addresses',
	emailReceipt: '/payment/email-receipt',
	enumChartTypes: '/api/enum_chart_types',
	itemTypes: '/api/enum_item_types',
	getServerCloseOutData: '/server/get-close-out-data',
	gridGroupProducts: '/api/sdms_grid_group_products',
	gridGroups: '/api/sdms_grid_groups',
	grids: '/api/sdms_grids',
	icons: '/api/enum_icons',
	invoices: '/api/sdms_invoices',
	invoiceVoid: '/invoice/void',
	invoiceDelete: '/invoice/delete',
	invoicePay: '/invoice/pay',
	sendInvoiceEmail: '/invoice/send-email',
	saveInvoiceDeferredIncomes: '/invoice/save-deferred-incomes',
	getPrintInvoiceContent: '/invoice/get-print-content',
	enumInvoiceStatuses: '/api/enum_invoice_statuses',
	invoiceFirstOptions: '/api/enum_first_invoice_options',
	invoiceNextOptions: '/api/enum_next_invoice_options',
	invoicingFrequencies: '/api/enum_invoicing_frequencies',
	marinaBookingTypes: '/api/sdms_booking_type_marinas',
	campgroundBookingTypes: '/api/sdms_booking_type_campgrounds',
	marinaUnits: '/api/sdms_unit_marinas',
	campgroundUnits: '/api/sdms_unit_campgrounds',
	media: '/api/sdms_media',
	modifierGroups: '/api/sdms_modifier_groups',
	modifierPrefixes: '/api/sdms_modifier_prefixes',
	modifierOverrides: '/api/sdms_modifier_overrides',
	modifiers: '/api/sdms_modifiers',
	modifierSections: '/api/sdms_modifier_sections',
	outlets: '/api/sdms_outlets',
	outletSettings: '/api/sdms_outlet_settings',
	payBill: '/pay/paybill',
	payBillGet: '/pay/get-paybill-data',
	payDeposit: '/anonymous/pay/deposit',
	payReservation: '/reservation/advanced/pay',
	payNonTransientReservation: '/custom/pay-reservation',
	paymentMethods: '/api/enum_payment_methods',
	paymentTerms: '/api/sdms_payment_terms',
	paymentTypes: '/api/enum_payment_types',
	permissions: '/api/enum_permissions',
	policies: '/api/sdms_policies',
	saleOrderFinalize: '/pay/order-sale-finalize',
	saleOrderPay: '/pay/order-sale',
	saleOrderPaymentEdit: '/pay/order-sale-payment-edit',
	saleOrderPaymentRefund: '/pay/order-sale-payment-refund',
	saleOrderPaymentVoid: '/pay/order-sale-payment-void',
	saleOrders: '/api/sdms_order_sales',
	posProducts: '/api/sdms_product_pos',
	posProductSkus: '/api/sdms_product_pos_skus',
	prepStations: '/api/sdms_prep_stations',
	printerLibraries: '/api/enum_printer_libraries',
	printLogs: '/api/sdms_print_logs',
	printerLogActions: '/api/enum_printer_log_actions',
	printers: '/api/sdms_printers',
	printSettings: '/api/enum_print_settings',
	printerTypes: '/api/enum_printer_types',
	printerWidths: '/api/enum_printer_widths',
	productCategories: '/api/sdms_product_categories',
	productCategoryBookings: '/api/sdms_product_category_bookings',
	productCategoryExtraCharges: '/api/sdms_product_category_extra_charges',
	products: '/api/sdms_products',
	ratePlans: '/api/sdms_rate_plans',
	refund: '/pay/refund',
	registerCheck: '/anonymous/check_register',
	registerClose: '/register/close',
	registerCloseCheck: '/register/check',
	registerEnableLogin: '/register/enable-login',
	registerOpen: '/register/open',
	registers: '/api/sdms_registers',
	registerSaveZOuts: '/register/save-z-outs',
	registerTypes: '/api/sdms_register_types',
	reports: '/api/sdms_reports',
	analyticsWidgets: '/api/sdms_analytics_widgets',
	analyticsDashboards: '/api/sdms_analytics_dashboards',
	reservationItems: '/api/sdms_reservation_items',
	reservationItemBookings: '/api/sdms_reservation_item_bookings',
	reservationItemMarinas: '/api/sdms_reservation_item_marinas',
	reservationItemCampgrounds: '/api/sdms_reservation_item_campgrounds',
	reservations: '/api/sdms_reservations',
	roleLevels: '/api/enum_role_levels',
	roles: '/api/sdms_roles',
	serverCloseOut: '/server/close-out',
	spaceGenerate: '/space/generate',
	splitReload: '/split/reload',
	splitSave: '/split/save',
	recallReload: '/recall/reload',
	seasons: '/api/sdms_seasons',
	states: '/api/enum_states',
	systemSettings: '/api/sdms_system_settings',
	getSystemSetting: '/anonymous/get-system-settings',
	unitGenerate: '/unit/generate',
	unitMaps: '/api/sdms_unit_maps',
	units: '/api/sdms_units',
	taxAgencies: '/api/sdms_tax_agencies',
	taxCodes: '/api/sdms_tax_codes',
	taxRates: '/api/sdms_tax_rates',
	terminalOpen: '/anonymous/terminal_open',
	terminalUnlock: '/anonymous/terminal_unlock',
	templates: '/api/sdms_templates',
	templateTypes: '/api/enum_template_types',
	testIncomingAddressConnectivity: '/anonymous/test-incoming-address-connectivity',
	timezones: '/api/enum_timezones',
	unitBlackouts: '/api/sdms_unit_blockouts',
	updateUnitBlackouts: '/custom/update-unit-blockout',
	unitGroups: '/api/sdms_unit_groups',
	unitMeasures: '/api/sdms_unit_measures',
	userLogin: '/anonymous/user_login',
	userShifts: '/api/sdms_user_shifts',
	users: '/api/sdms_users',
	vessels: '/api/sdms_vessels',
	vehicles: '/api/sdms_vehicles',
	clearUnUsedModifierGroups: '/modifier-groups/clear-unused',
	sendCustomerMarketingEmail: '/custom/send-customer-marketing-email',
	sendConfirmationEmail: '/reservation/advanced/send-confirmation-email',
	sendPaymentEmail: '/payment/send-email',
	sendStatementEmail: '/custom/send-statement-email',
	syncCustomer: '/custom/sync-customer',
	getPrintContract: '/reservation/advanced/get-contract-content',
	getPrintPaymentContent: '/payment/get-print-content',
	getPrintStatement: '/custom/get-print-statement-content',
	advancedReservationIsAvailable: '/reservation/advanced/is-available',
	advancedReservationUpdateItem: '/reservation/advanced/update-item',
	advancedReservationCancelItem: '/reservation/advanced/cancel-item',
	advancedReservationCancel: '/reservation/advanced/cancel',
	advancedReservationUpdateStatus: '/reservation/advanced/update-status',
	advancedReservationUpdateCustomStatus: '/reservation/advanced/update-custom-status',
	advancedReservationGetSwappableUnits: '/reservation/advanced/get-swappable-units',
	advancedReservationGetNonTransientProductPrice:
		'/reservation/advanced/get-nontransient-product-price',
	advancedReservationRefund: '/reservation/advanced/refund',
	advancedReservationOverPaymentRefund: '/reservation/advanced/over-payment-refund',
	advancedReservationDepositOverPaymentApplyInvoice:
		'/reservation/advanced/deposit-over-payment-apply-invoice',
	advancedReservationDepositCheckRefund: '/reservation/advanced/deposit-check-refund',
	advancedReservationRetain: '/reservation/advanced/retain',
	advancedReservationAutoRefund: '/reservation/advanced/auto-refund',
	advancedReservationGetUnitStatesForSpaceAssignment:
		'/reservation/advanced/get-available-units-for-space-assignment',
	advancedReservationCheckout: '/reservation/advanced/check-out',
	advancedReservationDeleteItem: '/reservation/advanced/delete-item',
	reservationCheckIn: '/reservation/check-in',
	reservationCheckOut: '/reservation/check-out',
	reservationGetUnitStates: '/reservation/get-unit-states',
	reservationGetUnitStatesForMap: '/reservation/get-unit-states-for-map',
	reservationGetCustomerBalancesForMap: '/reservation/get-customer-balances-for-map',
	reservationSearchAvailability: '/anonymous/reservation/search_availability',
	reservationSearchAvailabilityForMap: '/reservation/search-availability-for-map',
	reservationReserveItem: '/anonymous/reservation/reserve-item',
	reservationUpdateItem: '/anonymous/reservation/update-item',
	reservationCancelItem: '/anonymous/reservation/cancel-item',
	reservationCancel: '/anonymous/reservation/cancel',
	reservationUpdateTimeModified: '/anonymous/reservation/update-time-modified',
	reservationUpdateCustomer: '/anonymous/reservation/update-customer',
	onlineGetData: '/anonymous/online-booking/get-data',
	onlineCustomerUniqueControl: '/anonymous/online-booking/unique-control',
	onlineCustomerLogin: '/anonymous/online-booking/login',
	onlineCustomerRegister: '/anonymous/online-booking/register',
	onlineCustomerSendResetPasswordEmail: '/anonymous/online-booking/send-reset-password',
	onlineCustomerResetPassword: '/anonymous/online-booking/reset-password',
	onlineGetCustomerData: '/anonymous/online-booking/get-customer-data',
	onlineCheckPromoCode: '/anonymous/online-booking/check-promo-code',
	onlineUpdateReservationExtraCharges:
		'/anonymous/online-booking/update-reservation-extra-charges',
	reportPayroll: '/report/payroll',
	reportGeneric: '/report/generic',
	reportZOut: '/report/z-out',
	registerGetCloseData: '/register/get-close-data',
	customGetPrintData: '/custom/get-print-data',
	bookingTypeMarinas: '/api/sdms_booking_type_marinas',
	bookingTypeCampgrounds: '/api/sdms_booking_type_campgrounds',
	productCategoryMarinas: '/api/sdms_product_category_marinas',
	productCategoryCampgrounds: '/api/sdms_product_category_campgrounds',
	paymentCreditCards: '/api/sdms_payment_credit_cards',
	reservationItemUpdatePayment: '/payment/reservation-item-update-payment',
	renewalPeriods: '/api/enum_renewal_periods',
	enumThemes: '/api/enum_themes',
	spaceAssignments: '/api/sdms_space_assignments',
	enumSpaceAssignmentTypes: '/api/enum_space_assignment_types',
	updateSpaceAssignment: '/custom/update-space-assignment',
	deleteSpaceAssignment: '/custom/delete-space-assignment',
	reservationItemPayments: '/custom/get-payments-by-reservation-item',
	reservationPayments: '/custom/get-payments-by-reservation',
	refundReservationPayment: '/custom/refund-reservation-payment',
	fontSizes: '/api/enum_font_sizes',
	paymentGateways: '/api/enum_payment_gateways',
	registerGetCapturedPaymentCount: '/register/get-captured-payment-count',
	getI4GOAccessBlock: '/anonymous/get-i4go-access-block',
	availabilityCalendar: '/anonymous/availability-calendar',
	onlinePortalGetCustomerData: '/anonymous/online-portal/get-customer-data',
	onlinePortalGetInvoiceStatuses: '/anonymous/online-portal/get-invoice-statuses',
	onlinePortalGetRemittanceStatuses: '/anonymous/online-portal/get-remittance-statuses',
	onlinePortalGetInvoices: '/anonymous/online-portal/get-invoices',
	onlinePortalGetPrintStatementContent: '/anonymous/online-portal/get-print-statement-content',
	onlinePortalGetPrintInvoiceContent: '/anonymous/online-portal/get-print-invoice-content',
	onlinePortalGetPayments: '/anonymous/online-portal/get-payments',
	onlinePortalGetPrintPaymentContent: '/anonymous/online-portal/get-print-payment-content',
	onlinePortalGetSendPaymentEmail: '/anonymous/online-portal/send-payment-email',
	onlinePortalGetCreditMemos: '/anonymous/online-portal/get-credit-memos',
	onlinePortalGetPrintCreditMemoContent: '/anonymous/online-portal/get-print-credit-memo-content',
	onlinePortalGetSendCreditMemoEmail: '/anonymous/online-portal/send-credit-memo-email',
	onlinePortalCreatePayment: '/anonymous/online-portal/create-payment',
	onlinePortalGetSpaces: '/anonymous/online-portal/get-spaces',
	onlinePortalGetBookings: '/anonymous/online-portal/get-bookings',
	onlinePortalCustomerUniqueFieldControl:
		'/anonymous/online-portal/customer-unique-field-control',
	onlinePortalUpdateCustomer: '/anonymous/online-portal/update-customer',
	onlinePortalGetCustomerPaymentMethods: '/anonymous/online-portal/get-customer-payment-methods',
	onlinePortalAddCustomerPaymentMethod: '/anonymous/online-portal/add-customer-payment-method',
	onlinePortalDeleteCustomerPaymentMethod:
		'/anonymous/online-portal/delete-customer-payment-method',
	onlinePortalChangeCustomerDefaultPaymentMethod:
		'/anonymous/online-portal/change-customer-default-payment-method',
	onlinePortalGetPayInvoiceData: '/anonymous/online-portal/get-pay-invoice-data',
	powerMeterTypes: '/api/sdms_power_meter_types',
	powerMeters: '/api/sdms_power_meters',
	meterReadings: '/api/sdms_power_meter_readings',
	campgroundMeterReadings: '/api/sdms_power_meter_readings',
	searchPowerMeterBookings: '/meter-reading/search-power-meter-bookings',
	updateMeterReading: '/meter-reading/update-meter-reading',
	meterReadingBatchEntry: '/meter-reading/batch-entry',
	generateInvoice: '/custom/generate-invoices',
	autoPay: '/custom/auto-pay',
	saveReservationItemStatusContract: '/custom/save-reservation-item-status-contract',
	reservationItemStatusContracts: '/api/sdms_reservation_item_status_contracts',
	sendReservationItemStatusContractEmail: '/custom/send-reservation-item-status-contract-email',
	getReservationItemStatusContractTemplate:
		'/custom/get-reservation-item-status-contract-template',
	requeueCreditCardPayment: '/custom/requeue-credit-card-payment',
	reservationChangeAutoPay: '/reservation/advanced/change-auto-pay',
	payments: '/api/sdms_payments',
	paymentChecks: '/api/sdms_payment_checks',
	updatePayment: '/payment/update',
	refundPayment: '/payment/refund',
	voidPayment: '/payment/void',
	updateReservationInvoice: '/reservation/advanced/update-reservation-invoice',
	updateReservationTaxCode: '/anonymous/reservation/update-tax-code',
	recognizeRevenue: '/custom/recognize-revenue',
	journals: '/api/sdms_journals',
	credits: '/api/sdms_credits',
	updateCredit: '/credit/update',
	voidCredit: '/credit/void',
	creditGiveRefund: '/credit/give-refund',
	refunds: '/api/sdms_refunds',
	updateRefund: '/refund/update',
	voidRefund: '/refund/void',
	statementCharges: '/api/sdms_statement_charges',
	statementChargePay: '/custom/pay-statement-charge',
	statementChargeVoid: '/custom/void-statement-charge',
	getCustomerOpenTransactions: '/payment/get-open-customer-transactions',
	paymentProcessors: '/api/sdms_payment_processors',
	enumCustomerPaymentForms: '/api/enum_customer_payment_forms',
	getCheckToken: '/anonymous/get-check-token',
	saveCustomerPaymentMethod: '/custom/save-customer-payment-method',
	sendAchSignUpEmail: '/custom/send-ach-signup-email',
	productFilters: '/api/sdms_product_filters',
	metas: '/api/sdms_metas',
	enumMetaTypes: '/api/enum_meta_types',
	enumProductFilterTypes: '/api/enum_product_filter_types',
	enumProductFilterComparisons: '/api/enum_product_filter_comparisons',
	promoCodes: '/api/sdms_promo_codes',
	enumSearchResultViews: '/api/enum_search_result_views',
	getCustomerVessels: '/anonymous/online-booking/get-customer-vessels',
	getCustomerVehicles: '/anonymous/online-booking/get-customer-vehicles',
	captureSchedulePayment: '/payment/capture-schedule-payment',
	paymentSchedules: '/api/sdms_payment_schedules',
	reservationChangePaymentSchedule: '/reservation/advanced/change-reservation-payment-schedule',
	reservationChangeCustomerPaymentMethod:
		'/reservation/advanced/change-reservation-customer-payment-method',
	inquiries: '/api/sdms_inquiries',
	messages: '/api/sdms_messages',
	sendMessage: '/message/send',
	changeMessageStatus: '/message/change-status',
	messageCount: '/message/count',
	customerTypes: '/api/sdms_customer_types',
	attachments: '/api/sdms_attachments',
	emailAddressAuth: '/message/email-address/auth',
	enumAttachmentEntities: '/api/enum_attachment_entities',
	audits: '/api/sdms_audits',
	reservationInvoices: '/reservation/advanced/get-invoices',
	terminalProductSearchBySku: '/custom/terminal-product-search-by-sku',
	captureAuthorizedPayment: '/pay/capture-authorized-payment',
	voidAuthorizedPayment: '/pay/void-authorized-payment',
	companySyncs: '/api/sdms_company_syncs',
	reservationCheckForOverride: '/reservation/advanced/check-for-override',
	bounceCheck: '/payment/bounce-check',
	transactions: '/custom/transactions',
	accountingExport: '/accounting/export',
	emailDocuments: '/custom/email-documents',
	invoiceTypes: '/api/enum_invoice_types',
	advancedSetDepartureDate: '/reservation/advanced/set-departure-date',
	enumDiscountCalculations: '/api/enum_discount_calculations',
	activateTerminal: '/anonymous/activate-terminal',
	getPrintTransientFolioContent: '/custom/get-print-transient-folio-content',
	sendTransientFolioEmail: '/custom/send-transient-folio-email',
	invoiceImport: '/import-data/invoice',
	vesselImport: '/import-data/vessel',
	paymentImport: '/import-data/payment',
	mergeCustomers: '/custom/merge-customers',
	meterReadImport: '/import-data/meter-readings',
	remittanceApplyToInvoice: '/custom/apply-remittance-to-invoices',
	getPrintCreditMemoContent: '/credit-memo/get-print-content',
	sendCreditMemoEmail: '/credit-memo/send-email',
	analyticsSaveLayout: '/custom/analytics-save-layout',
};

export const externalEndpoints = {
	i4goToken: 'https://payment.i4go.com/index.cfm?fuseaction=api.jsonPostCardEntry',
	i4goTokenTest: 'https://i4m.shift4test.com/index.cfm?fuseaction=api.jsonPostCardEntry',
};

export const filters = {
	customers: {
		searchField: ['displayName', 'customerId', 'email'],
		orderField: 'customerId',
		groups: {
			list: {
				read: 'customer-list:read',
			},
			search: 'customer:search',
		},
		pagination: true,
		itemsPerPage: 100,
	},
	userShifts: {
		searchField: 'user.displayName',
	},
	products: {
		searchField: ['name', 'productId', 'description'],
		orderField: 'productId',
	},
	posProducts: {
		searchField: ['name', 'productId'],
		orderField: 'productId',
	},
	productBookings: {
		searchField: ['name', 'productId'],
		orderField: 'productId',
		groups: {
			list: {
				read: 'product-list:read',
			},
		},
	},
	users: {
		searchField: 'displayName',
		orderField: 'displayName',
		groups: {
			list: {
				read: 'user-list:read',
			},
			form: {
				read: 'user-form:read',
				write: 'user-form:write',
			},
			app: {
				read: 'user:read',
			},
		},
	},
	registers: {
		groups: {
			list: {
				read: 'register:read',
			},
			form: {
				read: 'register:read',
				write: 'register:write',
			},
		},
	},
	modifierPrefixes: {
		orderField: 'sortOrder',
	},
	reservationItems: {
		searchField: [
			'reservation.reservationId',
			'reservation.customer.displayName',
			'reservationItemId',
			'unit.name',
		],
		orderField: 'reservation.id',
	},
	reservationItemBookings: {
		searchField: [
			'reservation.reservationId',
			'reservation.customer.displayName',
			'reservationItemId',
			'unit.name',
		],
		orderField: 'reservation.id',
	},
	reservationItemMarinas: {
		searchField: [
			'reservation.reservationId',
			'reservation.customer.displayName',
			'reservationItemId',
			'unit.name',
			'vessel.name',
		],
		orderField: 'reservation.id',
	},
	reservationItemCampgrounds: {
		searchField: [
			'reservation.reservationId',
			'reservation.customer.displayName',
			'reservationItemId',
			'unit.name',
			'vehicle.name',
		],
		orderField: 'reservation.id',
	},
	printLogs: {
		searchField: ['printer.name', 'sdmsOrder.id'],
		orderField: 'id',
	},
	accountingErrorLogs: {
		searchField: 'search',
		orderField: 'enqueue_datetime',
		order: 'desc',
	},
	invoices: {
		searchField: [
			'customer.displayName',
			'invoiceId',
			'invoiceItems.description',
			'vessel.name',
		],
		orderField: 'timeCreated',
		order: 'desc',
		groups: {
			list: {
				read: 'invoice:read',
			},
			form: {
				read: 'invoice:read',
				write: 'invoice:write',
			},
		},
	},
	reservationInvoices: {
		searchField: ['customer.displayName', 'invoiceId'],
		orderField: 'timeCreated',
		order: 'desc',
	},
	payments: {
		searchField: ['remittanceId', 'customer.displayName'],
		orderField: 'remittanceDate',
		order: 'desc',
		groups: {
			list: {
				read: 'payment:read',
			},
			form: {
				read: 'payment:read',
				write: 'payment:write',
			},
		},
	},
	paymentCreditCards: {
		searchField: ['remittanceId', 'customer.displayName'],
		orderField: 'remittanceId',
		order: 'desc',
	},
	paymentCreditChecks: {
		searchField: ['remittanceId', 'customer.displayName'],
		orderField: 'remittanceId',
		order: 'desc',
	},
	reservationItemPayments: {
		searchField: ['remittanceId'],
		orderField: 'remittanceId',
		order: 'desc',
		groups: {
			list: {
				read: 'payment:read',
			},
			form: {
				read: 'payment:read',
				write: 'payment:write',
			},
		},
	},
	spaceAssignments: {
		searchField: 'space.name',
	},
	onlinePortalGetInvoices: {
		orderField: 'invoiceDate',
		order: 'desc',
	},
	onlinePortalGetPayments: {
		orderField: 'remittanceDate',
		order: 'desc',
	},
	onlinePortalGetCreditMemos: {
		orderField: 'remittanceDate',
		order: 'desc',
	},
	onlinePortalGetSpaces: {
		orderField: 'fromDate',
		order: 'desc',
	},
	onlinePortalGetBookings: {
		orderField: 'fromDate',
		order: 'desc',
	},
	onlinePortalGetCustomerPaymentMethods: {
		orderField: 'timeCreated',
		order: 'desc',
	},
	meterReadings: {
		searchField: [
			'id',
			'powerMeter.name',
			'powerMeter.space.name',
			'reservationItem.id',
			'reservationItem.reservation.customer.displayName',
		],
	},
	campgroundMeterReadings: {
		searchField: [
			'id',
			'powerMeter.name',
			'powerMeter.campgroundSpace.name',
			'reservationItem.id',
			'reservationItem.reservation.customer.displayName',
		],
	},
	journals: {
		searchField: ['id', 'reference'],
	},
	vessels: {
		searchField: ['name', 'customer.displayName'],
	},
	vehicles: {
		searchField: ['name', 'customer.displayName'],
	},
	credits: {
		orderField: 'id',
		order: 'desc',
		searchField: ['id', 'customer.displayName'],
	},
	refunds: {
		orderField: 'refundId',
		order: 'desc',
		searchField: ['refundId', 'customer.displayName'],
	},
	statementCharges: {
		searchField: ['customer.displayName', 'statementChargeId'],
		orderField: 'timeCreated',
		order: 'desc',
	},
	promoCodes: {
		searchField: ['name', 'productId', 'code'],
		orderField: 'productId',
		groups: {
			list: {
				read: 'promo-code:read',
			},
			form: {
				read: 'promo-code:read',
				write: 'promo-code:write',
			},
		},
	},
	emailAddresses: {
		searchField: ['displayName', 'emailAddress'],
	},
	messages: {
		searchField: ['fromEmail', 'subject', 'toEmail'],
		orderField: 'date',
		order: 'desc',
		groups: {
			list: {
				read: 'message-list:read',
			},
			form: {
				read: 'message:read',
				write: 'message:write',
			},
		},
	},

	audits: {
		searchField: 'entityId',
		orderField: 'id',
		order: 'desc',
	},
	bookingExtraCharges: {
		searchField: ['name', 'productId'],
	},
	ratePlans: {
		searchField: ['internalName', 'externalName'],
	},
	contracts: {
		searchField: ['name', 'eSignTemplate.name'],
	},
	productFilters: {
		searchField: ['name', 'meta.name'],
	},
	transactions: {
		searchField: 's',
		orderField: 'transactionDate',
		order: 'desc',
	},
};

export const outletRelatedEndpoints = [
	'credits',
	'grids',
	'invoices',
	'orders',
	'outletSettings',
	'payments',
	'prepStations',
	'printLogs',
	'printers',
	'refunds',
	'registers',
	'remittances',
	'reservations',
	'reservationItems',
	'reservationItemBookings',
	'reservationItemMarinas',
	'reservationItemCampgrounds',
	'units',
	'bookingUnits',
	'marinaUnits',
	'campgroundUnits',
	'unitMaps',
	'userShifts',
	'journals',
	'paymentProcessors',
	'statementCharges',
	'analyticsDashboards',
];

export const moduleRelatedEndpoints = [
	'units',
	'enumBookBies',
	'enumBookingPeriods',
	'enumSearchTypes',
	'unitGroups',
	'enumPricingTypes',
	'unitMaps',
	'productBookings',
	'bookingExtraCharges',
	'productCategories',
	'customReservationStatuses',
	'powerMeters',
	'policies',
	'bookingTypes',
	'analyticsDashboards',
];

export const modules = {
	POS: 'Point of Sale',
	BOOKINGS: 'Bookings',
	MARINA: 'Marina',
	POS_TERMINAL: 'POS Terminal',
	CAMPGROUND: 'Campground',
	INVENTORY: 'Inventory',
	SERVICE: 'Service',
	VENDORS: 'Vendors',
	MESSAGES: 'Messages',
	ACCOUNTING: 'Accounting',
	CRM: 'CRM',
};

export const customGets = {
	reservationItemPayments: 'payments',
};

export const customSubmits = {
	unitBlackouts: 'updateUnitBlackouts',
	reservationItemPayments: 'reservationItemUpdatePayment',
	spaceAssignments: 'updateSpaceAssignment',
	meterReadings: 'updateMeterReading',
	campgroundMeterReadings: 'updateMeterReading',
	payments: 'updatePayment',
	credits: 'updateCredit',
	refunds: 'updateRefund',
	messages: 'sendMessage',
};

export const pathToUrl = path => process.env.REACT_APP_BASE_URL + path;

const shouldNotAutoParseEndpoints = [
	'SdmsGridGroup',
	'SdmsUnitMapUnit',
	'SdmsModifier',
	'SdmsModifierOverride',
	'SdmsGridGroupProduct',
	'SdmsModifierSection',
	'SdmsModifierGroup',
	'SdmsOrderItemSale',
	'SdmsOrderSale',
	'SdmsOrderItemModifier',
	'SdmsProductRatePlan',
	'SdmsReservation',
	'SdmsReservationItem',
	'SdmsReservationItemBooking',
	'SdmsReservationItemExtraCharge',
	'SdmsOutletSetting',
	'SdmsProductRatePlanTransient',
	'SdmsProductRatePlanMarina',
	'SdmsProductRatePlanCampground',
	'SdmsProductRatePlanSeasonal',
	'SdmsProductRatePlanLongTerm',
	'SdmsInvoiceItem',
	'SdmsReservationItemMarina',
	'SdmsReservationItemCampground',
	'SdmsInvoiceItemDeferredIncome',
	'SdmsJournalLine',
	'SdmsOrderRefund',
	'SdmsOrderItemRefund',
	'SdmsPaymentScheduleItem',
	'SdmsProductPosSku',
	'SdmsProductExtraChargeExcludeDate',
	'SdmsCustomerPaymentMethodCreditCard',
	'SdmsCustomerPaymentMethodACH',
];

const paymentEndpoints = [
	'onlinePortalCreatePayment',
	'invoicePay',
	'updatePayment',
	'payDeposit',
	'payReservation',
];

const importFiles = {
	meterReadImport: 'SMMS_Meter_Readings_Import',
	invoiceImport: 'SMMS_Invoice_Import',
	vesselImport: 'SMMS_Vessel_Import',
	paymentImport: 'SMMS_Payment_Import',
};

const objectToIRI = (data, ignore = false) => {
	if (typeof data !== 'object' || !data) return data;

	if (Object.keys(data).length === 0 && !Array.isArray(data)) return null;

	if (data['@id'] && (!shouldNotAutoParseEndpoints.includes(data['@type']) || ignore))
		return `${data['@id'].substr(0, data['@id'].lastIndexOf('/'))}/${data.id}`;

	Object.keys(data).forEach(key => {
		if (data[key]) {
			data[key] = Array.isArray(data[key])
				? data[key].map(d => objectToIRI(d, ignore))
				: objectToIRI(data[key], ignore);
		}
	});

	return data;
};

export const parseData = (data, route = null, ignore = false) => {
	if (route) return data && data.id ? `${endpoints[route]}/${data.id}` : null;

	return objectToIRI(data, ignore);
};

/**
 * @description Generic API call
 * @param {string} method Request method type (GET, POST, PUT or DELETE)
 * @param {string} route Entity to be accessed
 * @param {function(*=): undefined} onSuccess Callback for request success
 * @param {function(*=): undefined} onFail Callback for request failure
 * @param {string | number} id Entity id
 * @param {string | object} body Request body
 * @param {object} filter Request resource filters
 * @param signal
 */
const apiCall = async (
	method,
	route,
	onSuccess,
	onFail,
	id = '',
	body = null,
	filter = null,
	signal = null
) => {
	const userCookie = JSON.parse(localStorage.getItem('user'));
	if (userCookie && userCookie.token) headers.Authorization = `Bearer ${userCookie.token}`;

	let url = externalEndpoints[route] || `${process.env.REACT_APP_BASE_URL}${endpoints[route]}`;

	if (id) url = `${url}/${id}`;

	const selectedOutlet = localStorage.getItem(SELECTED_OUTLET_STORAGE_KEY);

	const selectedRegister = localStorage.getItem(SELECTED_REGISTER_STORAGE_KEY);

	const paymentFilters = { paymentOutlet: selectedOutlet, paymentRegister: selectedRegister };

	let _filter = filter;

	if (paymentEndpoints.indexOf(route) > -1) {
		_filter = filter ? { ...filter, ...paymentFilters } : paymentFilters;
	}
	if (_filter) {
		const searchParams = new URLSearchParams();
		Object.keys(_filter).forEach(k => {
			if (Array.isArray(_filter[k])) _filter[k].forEach(p => searchParams.append(k, p));
			else searchParams.append(k, _filter[k]);
		});

		url = `${url}?${searchParams.toString()}`;
	}

	const options = {
		method,
	};

	// check for multipart/form-data.
	if (body instanceof FormData) {
		options.body = body;
		if (headers.Authorization) options.headers = { Authorization: headers.Authorization };
	} else if (route === 'i4goToken' || route === 'i4goTokenTest') {
		options.headers = {
			'Content-Type': 'application/x-www-form-urlencoded',
			Accept: '*',
		};

		const bodyQuery = new URLSearchParams();
		Object.keys(body).forEach(k => {
			bodyQuery.append(k, body[k]);
		});

		options.body = bodyQuery.toString();
	} else {
		options.headers = headers;
		if (body) options.body = JSON.stringify(body);
	}

	if (signal) options.signal = signal;

	try {
		const res = await fetch(url, options);

		if (res.ok) {
			/**
			 * Because of response has empty content no need for json parse.
			 */
			if (method === 'DELETE') {
				onSuccess();
				return;
			}

			if (res.headers.get('content-type').includes('openxmlformats')) {
				const file = await res.blob();
				FileSaver.saveAs(file, importFiles[route]);
				onSuccess();
				return;
			}
			const response = await res.json();
			/**
			 * POST and PUT responses don't have view and member properties.
			 */
			if (method !== 'GET') {
				onSuccess(response);
				return;
			}

			/**
			 * If response has view property it means paginated.
			 */
			if (filter && filter.pagination) onSuccess(response);
			else if (response['hydra:member']) onSuccess(response['hydra:member']);
			else onSuccess(response);
		} else {
			const response = await res.json();

			if (response.code === 401) {
				localStorage.removeItem('user');
				window.location.reload();
			} else {
				if (response['hydra:description']) throw new Error(response['hydra:description']);

				if (response.message)
					throw new Error(
						response.message.includes('Failed to fetch')
							? 'Unable to connect to server'
							: response.message
					);
			}

			throw new Error('An error occurred');
		}
	} catch (error) {
		onFail(
			error.toString().includes('Failed to fetch') ? 'Unable to connect to server' : error
		);
	}
};

export default apiCall;
