import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import useDate from '../../../utils/hooks/useDate';
import usePages from '../../../utils/hooks/usePages';
import { priceFormatter } from '../../../utils/helpers/helper';
import { invoiceStatuses } from '../../../utils/constants/constants';

import Badge from './Badge';
import NullBadge from '../design/NullBadge';
import SVGIcon from './SVGIcon';
import Portal from '../layout/Portal';

export const CustomerCell = ({ data, onClick }) => {
	return (
		<div onClick={onClick} role='presentation'>
			<span className='sdms-link sdms-link--dark'>{data.customer.displayName}</span>
		</div>
	);
};

CustomerCell.propTypes = {
	data: PropTypes.shape({
		customer: PropTypes.shape({
			displayName: PropTypes.string,
		}),
	}),
	onClick: PropTypes.func,
};

CustomerCell.defaultProps = {
	data: {
		customer: {
			displayName: '',
		},
	},
	onClick: () => {},
};

export const InvoiceDateCell = ({ data }) => {
	const [dateFormatter] = useDate();

	return dateFormatter(data.invoiceDate || data.timeCreated, false);
};

export const InvoiceDueDateCell = ({ data }) => {
	const [dateFormatter] = useDate();

	return dateFormatter(data.dueDate, false);
};

export const InvoiceTotalCell = ({ data }) => {
	return priceFormatter(data.isSalesReceipt ? data.amountPaid : data.total);
};

export const InvoicePaidCell = ({ data }) => {
	return priceFormatter(data.amountPaid);
};

export const InvoiceBalanceCell = ({ data }) => {
	const balanceDue = data.isSalesReceipt ? 0 : data.total - data.amountPaid;
	return priceFormatter(balanceDue < 0 ? 0 : balanceDue);
};

export const getInvoiceStatusCellText = data => {
	if (data?.status?.value === invoiceStatuses.PAID) return 'Paid';
	if (data?.status?.value === invoiceStatuses.VOIDED) return 'Voided';
	if (data?.amountPaid) return 'Partial';

	return 'Open';
};

export const InvoiceStatusCell = ({ data }) => {
	const [dateFormatter] = useDate();

	const getColor = () => {
		if (data.status.value === invoiceStatuses.PAID) return 'success';
		if (data.status.value === invoiceStatuses.VOIDED) return 'danger';
		if (data.amountPaid) return 'warning';

		return 'info';
	};

	return (
		<div data-for={`invoice-email-${data.id}`} data-tip='tooltip'>
			<Badge
				design={getColor()}
				isInline
				isUnified
				fontWeight='bold'
				size='lg'
				className='sdms-text-overflow'>
				<span className='sdms-text-overflow'>{getInvoiceStatusCellText(data)}</span>
			</Badge>
			{data.inquiry && (
				<>
					<SVGIcon className='sdms-ml-10' name='Send' fill='var(--success)' size={18} />
					{data.lastOutgoingMessageDate && (
						<Portal isTooltip>
							<ReactTooltip
								delayShow={250}
								delayHide={250}
								id={`invoice-email-${data.id}`}
								type='light'
								className='sdms-p3'
								place='bottom'>
								<span className='sdms-font-bold'>
									Email sent at: {dateFormatter(data.lastOutgoingMessageDate)}
								</span>
							</ReactTooltip>
						</Portal>
					)}
				</>
			)}
		</div>
	);
};

InvoiceStatusCell.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		status: PropTypes.object,
		amountPaid: PropTypes.number,
		inquiry: PropTypes.bool,
		lastOutgoingMessageDate: PropTypes.string,
	}),
};

InvoiceStatusCell.defaultProps = {
	data: {
		status: { value: invoiceStatuses.OPEN },
		amountPaid: 0,
		inquiry: false,
	},
};

export const InvoiceIdLinkCell = ({ data }) => {
	const pages = usePages();

	return (
		<a
			target='_blank'
			rel='noopener noreferrer'
			href={`${window.location.origin}${pages.crm.invoices.path}/${data.id}`}>
			{data.invoiceId}
		</a>
	);
};

InvoiceIdLinkCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

InvoiceIdLinkCell.defaultProps = { data: null };

export const InvoiceTypeCell = ({ data = {} }) => {
	const itemUnits = (data?.invoiceItems || []).map(item => item?.reservationItem?.unit?.name);
	const subText = data?.bookingType?.name || itemUnits[0];

	return (
		<div>
			<span className='sdms-font-bold'>{data.type?.value || '#'}</span>
			<div className='sdms-font-sm sdms-list-layout__small-text'>{subText}</div>
		</div>
	);
};

InvoiceTypeCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

InvoiceTypeCell.defaultProps = { data: null };

export const InvoiceVesselCell = ({ data }) => data.vessel?.name || <NullBadge />;

InvoiceVesselCell.propTypes = {
	data: PropTypes.shape({
		vessel: PropTypes.shape({
			name: PropTypes.string,
		}),
	}),
};

InvoiceVesselCell.defaultProps = {
	data: {
		vessel: null,
	},
};
