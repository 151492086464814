import { useState, useCallback } from 'react';

import apiCall from '../helpers/apiCall';
import { addErrorNotification, addSuccessNotification } from '../helpers/helper';

const useSendInvoice = () => {
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * useSendInvoice hook to send an invoice email.
	 * @param {Object} apiCallBody - The body of the API call.
	 * @param {Function} [onSuccess] - Optional function to update data on success.
	 * @param {Function} [onError] - Optional function to update data on error.
	 * @returns {Object} - Returns the sendInvoice function.
	 * @returns {Boolean} - Returns the isLoading state.
	 */
	const sendInvoice = useCallback(async (apiCallBody, onSuccess, onError) => {
		try {
			setIsLoading(true);
			const successCallback = res => {
				if (onSuccess) onSuccess(res);

				let notificationText = 'Email successfully sent';
				if (apiCallBody.invoiceIds && apiCallBody.invoiceIds.length > 1) {
					notificationText = `${apiCallBody.invoiceIds.length} Emails successfully sent`;
				}
				addSuccessNotification(notificationText);
				setIsLoading(false);
			};

			const errorCallback = error => {
				if (onError) onError(error);
				addErrorNotification(error.toString());
				setIsLoading(false);
			};

			await apiCall(
				'POST',
				'sendInvoiceEmail',
				successCallback,
				errorCallback,
				'',
				apiCallBody
			);
		} catch (error) {
			if (onError) onError(error);
			setIsLoading(false);
			addErrorNotification(error.toString());
		}
	}, []);

	return { sendInvoice, isLoading };
};

export default useSendInvoice;
