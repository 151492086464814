import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Portlet from '../../reusables/layout/Portlet';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Toggle from '../../reusables/field/Toggle';
import MultiSelect from '../../reusables/element/MultiSelect';
import Button from '../../reusables/element/Button';
import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';
import { convertDateToUTC, generateId, getColorList } from '../../../utils/helpers/helper';
import Selects from '../../reusables/field/Selects';
import DatePicker from '../../reusables/field/DatePicker';
import Badge from '../../reusables/element/Badge';

const CalendarPeriodForm = ({ data, setTitle, onSave, onCancel }) => {
	const [isValid, setIsValid] = useState(false);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		() => {},
		[required],
		'',
		null,
		setTitle
	);

	const [color, colorOnChange] = useField(data, 'color', () => {}, [], 'random');

	const [startDate, setStartDate] = useState(
		moment()
			.utcOffset(0)
			.hour(0)
			.minute(0)
			.second(0)
			.millisecond(0)
	);

	const [endDate, setEndDate] = useState(
		moment()
			.utcOffset(0)
			.hour(0)
			.minute(0)
			.second(0)
			.millisecond(0)
	);

	const colorList = useRef(getColorList());

	const colorOptions = useRef([{ name: 'Random color', class: 'random' }, ...colorList.current]);

	const weekdays = useRef([
		{
			id: 0,
			name: 'Sunday',
		},
		{
			id: 1,
			name: 'Monday',
		},
		{
			id: 2,
			name: 'Tuesday',
		},
		{
			id: 3,
			name: 'Wednesday',
		},
		{
			id: 4,
			name: 'Thursday',
		},
		{
			id: 5,
			name: 'Friday',
		},
		{
			id: 6,
			name: 'Saturday',
		},
	]);

	const [selectedDays, setSelectedDays] = useState(data.id === 0 ? weekdays.current : []);

	const [overwritePeriods, setOverwritePeriods] = useState(false);

	const setFields = () => {
		if (data.calendarPeriodDays.length) {
			let _startDate = moment(data.calendarPeriodDays[0].date).utcOffset(0);

			let _endDate = moment(
				data.calendarPeriodDays[data.calendarPeriodDays.length - 1].date
			).utcOffset(0);

			const _days = [];

			data.calendarPeriodDays.forEach(cpd => {
				const _date = moment(cpd.date).utcOffset(0);

				if (_date.isBefore(_startDate)) _startDate = _date.clone();

				if (_date.isAfter(_endDate)) _endDate = _date.clone();

				if (_days.findIndex(d => d.id === parseInt(_date.format('d'), 10)) === -1)
					_days.push({ id: parseInt(_date.format('d'), 10) });
			});

			setStartDate(_startDate);
			setEndDate(_endDate);
			setSelectedDays(_days);
		}
	};
	const getRandomColor = () =>
		colorList.current[Math.floor(Math.random() * colorList.current.length)].class;

	const submit = () => {
		setIsSubmitted(true);

		if (!isValid) return;

		if (color === 'random') data.color = getRandomColor();

		const currentDate = startDate.clone();

		const days = [];

		while (currentDate.isSameOrBefore(endDate)) {
			if (selectedDays.findIndex(d => d.id === parseInt(currentDate.format('d'), 10)) > -1) {
				days.push({
					id: generateId(days),
					date: currentDate.toISOString(),
				});
			}

			currentDate.add(1, 'day');
		}

		onSave({ ...data, days, overwritePeriods });
	};

	useEffect(() => {
		setIsValid(nameValRes.isValid);
	}, [nameValRes.isValid, setIsValid]);

	useEffect(() => {
		if (isSubmitted) setNameShowVal();
	}, [isSubmitted, setNameShowVal]);

	useEffect(() => {
		if (data.id !== 0) setFields();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.id]);

	return (
		<Portlet fluid='fluid'>
			<Portlet.Head>
				<Portlet.HeadLabel portletIcon='Layers'>
					<h3 className='sdms-portlet__head-title'>{name || 'New Period Calendar'}</h3>
				</Portlet.HeadLabel>
			</Portlet.Head>
			<Portlet.Body>
				<FormGroup isLast>
					<FormField
						name='name'
						label='Name'
						id={data.id}
						valRes={nameValRes}
						showValidation={nameShowVal}
						col={12}>
						<Input
							type='text'
							placeholder='Name (Required)'
							value={name}
							onChange={nameOnChange}
							onBlur={setNameShowVal}
						/>
					</FormField>
					<FormField name='startDate' label='Start Date' col={6}>
						<DatePicker
							id='startDate'
							type='calendar'
							value={convertDateToUTC(startDate.toDate())}
							onChange={e => {
								const _startDate = moment()
									.utcOffset(0)
									.year(moment(e.target.value).year())
									.month(moment(e.target.value).month())
									.date(moment(e.target.value).date())
									.hour(0)
									.minute(0)
									.second(0)
									.millisecond(0);
								setStartDate(_startDate);
								if (_startDate.isAfter(endDate)) setEndDate(_startDate);
							}}
						/>
					</FormField>
					<FormField name='endDate' label='End Date' col={6}>
						<DatePicker
							id='endDate'
							type='calendar'
							value={convertDateToUTC(endDate.toDate())}
							onChange={e =>
								setEndDate(
									moment()
										.utcOffset(0)
										.year(moment(e.target.value).year())
										.month(moment(e.target.value).month())
										.date(moment(e.target.value).date())
										.hour(0)
										.minute(0)
										.second(0)
										.millisecond(0)
								)
							}
							minDate={convertDateToUTC(startDate.toDate())}
						/>
					</FormField>
					<FormField name='periodDays' label='Select Period Days' id={data.id} col={12}>
						<MultiSelect
							titleProp='name'
							itemsCol={6}
							data={weekdays.current}
							value={selectedDays}
							onChange={e => setSelectedDays(e.target.value)}
						/>
					</FormField>
					<FormField name='color' label='Calendar Period Color' id={data.id} col={12}>
						<Selects
							className='sdms-font-transform-c'
							options={colorOptions.current}
							value={colorOptions.current.find(c => c.class === color)}
							onChange={c =>
								colorOnChange({
									target: { value: c.target.value ? c.target.value.class : null },
								})
							}
							displayKey='name'
							valueKey='class'
							renderOption={option => (
								<Badge
									className={`sdms-custom-colors--${option.class} sdms-font-transform-c`}
									isInline>
									<span>{option.name}</span>
								</Badge>
							)}
						/>
					</FormField>
					<FormField
						name='overWrite'
						label='Overwrite Existing Periods'
						id={data.id}
						col={12}>
						<Toggle
							onChange={e => {
								setOverwritePeriods(e.target.value);
							}}
							value={overwritePeriods}
						/>
					</FormField>
				</FormGroup>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button design='default' text='Cancel' size='sm' elevate onClick={onCancel} />
				</div>
				<div className='col-auto'>
					<Button
						label={process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR}
						text='OK'
						icon='Done-circle'
						size='sm'
						elevate
						onClick={submit}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
CalendarPeriodForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		calendarPeriodDays: PropTypes.arrayOf(PropTypes.object),
		color: PropTypes.string,
	}),
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};
CalendarPeriodForm.defaultProps = {
	data: {
		id: 0,
		calendarPeriodDays: [],
	},
};

export default CalendarPeriodForm;
