import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { maxLength, phoneNumber, required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import UserContext from '../../../app/contexts/UserContext';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Loading from '../../reusables/template/Loading';
import Input from '../../reusables/field/Input';
import Section from '../../reusables/layout/Section';
import Selects from '../../reusables/field/Selects';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import { phoneNumberParser } from '../../../utils/helpers/helper';

const TaxAgencyForm = ({
	data,
	setIsValid,
	isSubmitted,
	countries,
	states,
	setTitle,
	isLoading,
	onFormChange,
	submitButtonAttr,
	submit,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [
		addressLineOne,
		addressLineOneOnChange,
		addressLineOneValRes,
		addressLineOneShowVal,
		setAddressLineOneShowVal,
	] = useField(data, 'addressLineOne', onFormChange, [maxLength()]);

	const [
		addressLineTwo,
		addressLineTwoOnChange,
		addressLineTwoValRes,
		addressLineTwoShowVal,
		setAddressLineTwoShowVal,
	] = useField(data, 'addressLineTwo', onFormChange, [maxLength()]);

	const [city, cityOnChange, cityValRes, cityShowVal, setCityShowVal] = useField(
		data,
		'city',
		onFormChange,
		[maxLength()]
	);

	const [state, stateOnChange] = useField(data, 'state', onFormChange, [], null);

	const [zip, zipOnChange, zipValRes, zipShowVal, setZipShowVal] = useField(
		data,
		'zip',
		onFormChange,
		[maxLength()]
	);

	const [country, countryOnChange] = useField(
		data,
		'country',
		onFormChange,
		[],
		userContext.data.selectedOutlet.country
	);

	const [phone, phoneOnChange, phoneValRes, phoneShowVal, setPhoneShowVal] = useField(
		data,
		'phone',
		onFormChange,
		[phoneNumber, maxLength()],
		'',
		phoneNumberParser
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setPhoneShowVal();
			setAddressLineOneShowVal();
			setAddressLineTwoShowVal();
			setCityShowVal();
			setZipShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setPhoneShowVal,
		setAddressLineOneShowVal,
		setAddressLineTwoShowVal,
		setCityShowVal,
		setZipShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				phoneValRes.isValid &&
				addressLineOneValRes.isValid &&
				addressLineTwoValRes.isValid &&
				cityValRes.isValid &&
				zipValRes.isValid
		);
	}, [
		nameValRes.isValid,
		phoneValRes.isValid,
		addressLineOneValRes.isValid,
		addressLineTwoValRes.isValid,
		cityValRes.isValid,
		zipValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.accounting.default.text,
				path: pages.accounting.dashboard.path,
			},
			{
				title: pages.accounting.settings.text,
				path: pages.accounting.settings.path,
			},
			{
				title: pages.accounting.settings.taxAgency.text,
				path: pages.accounting.settings.taxAgency.path,
			},
			{ title: name || `New ${pages.accounting.settings.taxAgency.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.accounting.settings.taxAgency.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	// update states and reset selected state.
	const changeCountryState = ({ target }) => {
		stateOnChange({
			target: {
				name: 'state',
				value: null,
			},
		});

		countryOnChange({ target });
	};

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<Section title='General'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='name'
										label='Name'
										id={data.id}
										valRes={nameValRes}
										showValidation={nameShowVal}
										colLg={6}>
										<Input
											type='text'
											placeholder='Tax Agency Name (Required)'
											value={name}
											onChange={nameOnChange}
											onBlur={setNameShowVal}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Address'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='addressLineOne'
										label='Address 1'
										id={data.id}
										colLg={6}
										valRes={addressLineOneValRes}
										showValidation={addressLineOneShowVal}>
										<Input
											type='text'
											placeholder='Address Line 1'
											value={addressLineOne}
											onChange={addressLineOneOnChange}
											onBlur={setAddressLineOneShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='addressLineTwo'
										label='Address 2'
										id={data.id}
										colLg={6}
										valRes={addressLineTwoValRes}
										showValidation={addressLineTwoShowVal}>
										<Input
											type='text'
											placeholder='Address Line 2'
											value={addressLineTwo}
											onChange={addressLineTwoOnChange}
											onBlur={setAddressLineTwoShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='city'
										label='City'
										id={data.id}
										colMd={6}
										colLg={3}
										valRes={cityValRes}
										showValidation={cityShowVal}>
										<Input
											type='text'
											placeholder='City'
											value={city}
											onChange={cityOnChange}
											onBlur={setCityShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='state'
										label='State'
										id={data.id}
										colMd={6}
										colLg={3}>
										<Selects
											options={
												country
													? states.filter(
															s => s.country.id === country.id
													  )
													: []
											}
											placeholder='State'
											value={state}
											onChange={stateOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='zip'
										label='Zip'
										id={data.id}
										colMd={6}
										colLg={3}
										valRes={zipValRes}
										showValidation={zipShowVal}>
										<Input
											type='text'
											placeholder='ZIP Code'
											value={zip}
											onChange={zipOnChange}
											onBlur={setZipShowVal}
											mask={process.env.REACT_APP_ZIP_FORMAT}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='country'
										label='Country'
										id={data.id}
										colMd={6}
										colLg={3}>
										<Selects
											options={countries}
											placeholder='Country'
											value={country}
											onChange={changeCountryState}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='phone'
										label='Phone'
										id={data.id}
										valRes={phoneValRes}
										showValidation={phoneShowVal}
										colLg={6}>
										<Input
											type='text'
											value={phone}
											onChange={phoneOnChange}
											onBlur={setPhoneShowVal}
											mask={process.env.REACT_APP_PHONE_FORMAT}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
TaxAgencyForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		addressLineOne: PropTypes.string,
		addressLineTwo: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.object,
		zip: PropTypes.string,
		country: PropTypes.shape({ states: PropTypes.arrayOf(PropTypes.object) }),
		phone: PropTypes.string,
	}),
	countries: PropTypes.arrayOf(PropTypes.object),
	states: PropTypes.arrayOf(PropTypes.object),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
TaxAgencyForm.defaultProps = {
	data: {
		id: 0,
	},
	countries: [],
	states: [],
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default TaxAgencyForm;
