import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import useComponentSize from '@rehooks/component-size';

import HeaderContext from '../../../app/contexts/HeaderContext';
import UserContext from '../../../app/contexts/UserContext';
import usePages from '../../../utils/hooks/usePages';
import useField from '../../../utils/hooks/useField';
import { numeric, required } from '../../../utils/helpers/validation';
import {
	calculateMeterReadingTotal,
	getAccountingClosedDate,
	numberParser,
	parseDatePickerChange,
	parseDatePickerValue,
	priceFormatter,
} from '../../../utils/helpers/helper';
import { modules } from '../../../utils/helpers/apiCall';
import useDate from '../../../utils/hooks/useDate';
import { getBookingHourlyStep } from '../../../utils/helpers/reusable';

import FormGroup from '../../reusables/layout/FormGroup';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import FormField from '../../reusables/template/FormField';
import Selects from '../../reusables/field/Selects';
import Loading from '../../reusables/template/Loading';
import DatePicker from '../../reusables/field/DatePicker';
import TimePickerInput from '../../reusables/field/TimePickerInput';
import Input from '../../reusables/field/Input';
import Toggle from '../../reusables/field/Toggle';
import AsyncSelect from '../../reusables/field/AsyncSelect';

const MeterReadingForm = ({
	data,
	isLoading,
	onFormChange,
	isSubmitted,
	setIsValid,
	submitButtonAttr,
	submit,
	powerMeters,
	paymentTerms,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const [, utcDateFormatter] = useDate();

	const refForTimePicker = useRef(null);

	const sizeTimePicker = useComponentSize(refForTimePicker);

	// I am not sure about using booking hourly step for reading time.
	const timePickerStep = useRef(getBookingHourlyStep(userContext));

	const powerMeterUnit = useRef(
		userContext.data.selectedOutlet.settings &&
			userContext.data.selectedOutlet.settings.powerMeterUnit
			? userContext.data.selectedOutlet.settings.powerMeterUnit
			: 'kWh'
	);

	const alwaysCharge = userContext.data.selectedOutlet.settings.alwaysChargeMeterReading;

	const currentReadingValidate = (value, setValRes) => {
		if (value === '' || previousReading === '') return true;

		if (alwaysCharge && parseFloat(value) < parseFloat(previousReading)) {
			setValRes({
				isValid: false,
				status: 'invalidCurrentReading',
				message: `Current reading must be equal or greater than previous reading`,
			});
			return false;
		}

		if (!alwaysCharge && parseFloat(value) <= parseFloat(previousReading)) {
			setValRes({
				isValid: false,
				status: 'invalidCurrentReading',
				message: `Current reading must be greater than previous reading`,
			});
			return false;
		}

		return false;
	};

	const serviceChargeItem = userContext.data.selectedOutlet.settings
		? userContext.data.selectedOutlet.settings.meterReadServiceCharge
		: null;

	const [
		powerMeter,
		powerMeterOnChange,
		powerMeterValRes,
		powerMeterShowVal,
		setPowerMeterShowVal,
	] = useField(data, 'powerMeter', onFormChange, [required], null);

	const [
		reservationItem,
		reservationItemOnChange,
		reservationItemValRes,
		reservationItemShowVal,
		setReservationItemShowVal,
	] = useField(data, 'reservationItem', onFormChange, [required], null);

	const [
		readingDate,
		readingDateOnChange,
		readingDateValRes,
		readingDateShowVal,
		setReadingDateShowVal,
	] = useField(data, 'readingDate', onFormChange, [required], null);

	const [
		readingTime,
		readingTimeOnChange,
		readingTimeValRes,
		readingTimeShowVal,
		setReadingTimeShowVal,
	] = useField(data, 'readingTime', onFormChange, [required], null);

	const [invoiceDate, invoiceDateOnChange] = useField(
		data,
		'invoiceDate',
		onFormChange,
		[],
		null
	);

	const [
		serviceCharge,
		serviceChargeOnChange,
		serviceChargeValRes,
		serviceChargeShowVal,
		setServiceChargeShowVal,
	] = useField(data, 'serviceCharge', onFormChange, [numeric], '', numberParser(true));

	const [invoiceNow, invoiceNowOnChange] = useField(data, 'invoiceNow', onFormChange, [], false);

	const [autoPay, autoPayOnChange] = useField(
		data,
		'autoPay',
		onFormChange,
		[],
		userContext.data.selectedOutlet.settings &&
			userContext.data.selectedOutlet.settings.autoPayMarina
	);

	const [paymentTerm, paymentTermOnChange] = useField(
		data,
		'paymentTerm',
		onFormChange,
		[],
		null
	);

	const [
		previousReading,
		previousReadingOnChange,
		previousReadingValRes,
		previousReadingShowVal,
		setPreviousReadingShowVal,
	] = useField(
		data,
		'previousReading',
		onFormChange,
		[required, numeric],
		'',
		numberParser(true)
	);

	const [
		currentReading,
		currentReadingOnChange,
		currentReadingValRes,
		currentReadingShowVal,
		setCurrentReadingShowVal,
		validateCurrentReading,
	] = useField(
		data,
		'currentReading',
		onFormChange,
		[required, numeric, currentReadingValidate],
		'',
		numberParser(true)
	);

	const [
		costPerUnit,
		costPerUnitOnChange,
		costPerUnitValRes,
		costPerUnitShowVal,
		setCostPerUnitShowVal,
	] = useField(data, 'costPerUnit', onFormChange, [required, numeric], '', numberParser(true));

	const [tax, setTax] = useState(0);

	const [totalCost, totalCostOnChange] = useField(data, 'totalCost', onFormChange, [], 0);

	const optionalShow = option =>
		`${option.reservation.customer.displayName} - ${option.unit.name} - ${option.reservation.reservationId}`;

	const renderOption = option =>
		`${option.reservation.reservationId} - ${option.id} - ${
			option.reservation.customer.displayName
		} -  ${option.product.name} - ${option.unit.name} - ${utcDateFormatter(
			option.fromDate
		)} - ${utcDateFormatter(option.toDate)} - ${option.status.value}`;

	const getUsage = useCallback(() => {
		if (previousReading === '' || currentReading === '') return 0;

		const usage = parseFloat(currentReading) - parseFloat(previousReading);

		return Math.max(usage, 0);
	}, [previousReading, currentReading]);

	useEffect(() => {
		if (isSubmitted) {
			setPowerMeterShowVal();
			setReservationItemShowVal();
			setReadingDateShowVal();
			setReadingTimeShowVal();
			setServiceChargeShowVal();
			setPreviousReadingShowVal();
			setCurrentReadingShowVal();
			setCostPerUnitShowVal();
		}
	}, [
		isSubmitted,
		setPowerMeterShowVal,
		setReservationItemShowVal,
		setReadingDateShowVal,
		setReadingTimeShowVal,
		setServiceChargeShowVal,
		setPreviousReadingShowVal,
		setCurrentReadingShowVal,
		setCostPerUnitShowVal,
	]);

	useEffect(() => {
		setIsValid(
			powerMeterValRes.isValid &&
				reservationItemValRes.isValid &&
				readingDateValRes.isValid &&
				readingTimeValRes.isValid &&
				serviceChargeValRes.isValid &&
				previousReadingValRes.isValid &&
				currentReadingValRes.isValid &&
				costPerUnitValRes.isValid
		);
	}, [
		setIsValid,
		powerMeterValRes.isValid,
		reservationItemValRes.isValid,
		readingDateValRes.isValid,
		readingTimeValRes.isValid,
		serviceChargeValRes.isValid,
		previousReadingValRes.isValid,
		currentReadingValRes.isValid,
		costPerUnitValRes.isValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.marina.default.text,
				path: pages.marina.dashboard.path,
			},
			{
				title: pages.marina.meterReadings.text,
				path: pages.marina.meterReadings.path,
			},
			{ title: data.id || `New ${pages.marina.meterReadings.text}`, isActive: true },
		]);

		headerContext.setPageTitle(
			data.id ? data.id.toString() : `New ${pages.marina.meterReadings.text}`
		);

		if (data.id === 0) {
			readingDateOnChange({
				target: {
					value: moment()
						.utc(true)
						.toISOString(),
				},
			});

			readingTimeOnChange({
				target: {
					value: moment()
						.utc(true)
						.toISOString(),
				},
			});

			if (serviceChargeItem)
				serviceChargeOnChange({
					target: {
						value: serviceChargeItem.price,
					},
				});
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	useEffect(() => {
		const totals = calculateMeterReadingTotal(
			previousReading,
			currentReading,
			costPerUnit,
			powerMeter,
			serviceCharge,
			userContext.data.selectedOutlet.settings.taxRate,
			serviceChargeItem,
			alwaysCharge
		);

		setTax(totals.tax);

		totalCostOnChange({
			target: {
				value: totals.total,
			},
		});
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [previousReading, currentReading, costPerUnit, serviceCharge, powerMeter]);

	useEffect(() => {
		validateCurrentReading(currentReading);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [previousReading]);

	useEffect(() => {
		if (reservationItem && data.id === 0) {
			// set default payment term from customer or outlet.
			let _paymentTerm;

			if (reservationItem.reservation.customer.paymentTermMarina)
				_paymentTerm = reservationItem.reservation.customer.paymentTermMarina;
			else if (reservationItem.reservation.customer.paymentTerm)
				_paymentTerm = reservationItem.reservation.customer.paymentTerm;
			else if (userContext.data.selectedOutlet.settings.paymentTermMarina)
				_paymentTerm = userContext.data.selectedOutlet.settings.paymentTermMarina;
			else _paymentTerm = userContext.data.selectedOutlet.settings.paymentTerm;

			paymentTermOnChange({ target: { value: _paymentTerm } });
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [reservationItem]);

	useEffect(() => {
		if (!isLoading) data.module = modules.MARINA;
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [data.module, isLoading]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup row>
						<Loading isLoading={isLoading}>
							<FormField
								name='powerMeter'
								label='Power Meter'
								valRes={powerMeterValRes}
								showValidation={powerMeterShowVal}
								id={data.id}
								colLg={6}>
								<Selects
									options={
										data.id === 0
											? powerMeters.filter(meter => meter.inactive === false)
											: powerMeters
									}
									placeholder='Select a Power Meter'
									value={powerMeter}
									onChange={e => {
										powerMeterOnChange(e);
										reservationItemOnChange({
											target: {
												value:
													e.target.value && e.target.value.reservationItem
														? e.target.value.reservationItem
														: null,
											},
										});
										costPerUnitOnChange({
											target: {
												value: e.target.value
													? e.target.value.product.price
													: '',
											},
										});
										if (e.target.value && e.target.value.previousReading)
											previousReadingOnChange({
												target: { value: e.target.value.previousReading },
											});
									}}
									onBlur={setPowerMeterShowVal}
									disabled={data.id !== 0}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='reservationItem'
								label='Booking'
								valRes={reservationItemValRes}
								showValidation={reservationItemShowVal}
								id={data.id}
								colLg={6}>
								<AsyncSelect
									options={reservationItem ? [reservationItem] : []}
									placeholder='Select a Booking'
									value={reservationItem}
									onChange={reservationItemOnChange}
									onBlur={setReservationItemShowVal}
									route='searchPowerMeterBookings'
									field='search'
									optionalShow={optionalShow}
									renderOption={renderOption}
									presetFilters={
										powerMeter ? { powerMeterId: powerMeter.id } : {}
									}
									disabled={!powerMeter || data.id !== 0}
									displayKey='id'
									valueKey='id'
									minSearchChar={3}
									notFoundText={`Cannot found booking for ${
										powerMeter ? powerMeter.name : ''
									} power meter.`}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='readingDate'
								label='Reading Date'
								valRes={readingDateValRes}
								showValidation={readingDateShowVal}
								id={data.id}
								colLg={4}>
								<DatePicker
									id='readingDate'
									type='calendar'
									value={parseDatePickerValue(readingDate)}
									onChange={e => {
										readingDateOnChange({
											target: {
												value: parseDatePickerChange(
													e.target.value,
													readingDate
												),
											},
										});
									}}
									onBlur={setReadingDateShowVal}
									disabled={data.invoice}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='readingTime'
								label='Reading Time'
								valRes={readingTimeValRes}
								showValidation={readingTimeShowVal}
								id={data.id}
								colLg={4}>
								<TimePickerInput
									showSecond={false}
									value={moment(readingTime).utc(false)}
									defaultValue={moment()}
									onChange={target => {
										readingTimeOnChange({
											target: {
												value: target
													? moment(readingTime)
															.utc(false)
															.hour(target.hour())
															.minute(target.minute())
															.toISOString()
													: null,
											},
										});
									}}
									size={sizeTimePicker}
									use12Hours
									minuteStep={timePickerStep.current}
									onClose={setReadingTimeShowVal}
									disabled={data.invoice}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='invoiceDate'
								label='Invoice Date'
								id={data.id}
								colLg={4}>
								<DatePicker
									id='invoiceDate'
									type='calendar'
									value={parseDatePickerValue(invoiceDate)}
									onChange={e => {
										invoiceDateOnChange({
											target: {
												value: parseDatePickerChange(
													e.target.value,
													invoiceDate
												),
											},
										});
									}}
									disabled={data.invoice}
									minDate={getAccountingClosedDate(userContext)}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='invoiceNow' label='Invoice Now' id={data.id} colLg={4}>
								<Toggle
									value={invoiceNow}
									onChange={invoiceNowOnChange}
									disabled={data.invoice}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='autoPay' label='Auto Pay' id={data.id} colLg={4}>
								<Toggle
									value={autoPay}
									onChange={autoPayOnChange}
									disabled={data.invoice}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='paymentTerm'
								label='Payment Term'
								id={data.id}
								colLg={4}>
								<Selects
									options={paymentTerms}
									placeholder='Select a Payment Term'
									value={paymentTerm}
									onChange={paymentTermOnChange}
									disabled={data.invoice}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='previousReading'
								label='Previous Reading'
								id={data.id}
								valRes={previousReadingValRes}
								showValidation={previousReadingShowVal}
								colLg={2}>
								<Input
									placeholder='Previous Reading'
									value={previousReading}
									onChange={previousReadingOnChange}
									onBlur={setPreviousReadingShowVal}
									append={powerMeterUnit.current}
									disabled={data.invoice}
									pattern={process.env.REACT_APP_PRICE_PATTERN}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='currentReading'
								label='Current Reading'
								id={data.id}
								valRes={currentReadingValRes}
								showValidation={currentReadingShowVal}
								colLg={2}>
								<Input
									placeholder='Current Reading'
									value={currentReading}
									onChange={currentReadingOnChange}
									onBlur={setCurrentReadingShowVal}
									append={powerMeterUnit.current}
									disabled={data.invoice}
									pattern={process.env.REACT_APP_PRICE_PATTERN}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='usage' label='Usage' id={data.id} colLg={2}>
								<Input
									placeholder='Usage'
									value={`${getUsage()} ${powerMeterUnit.current}`}
									onChange={() => {}}
									readOnly
									borderLess
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='costPerUnit'
								label='Cost Per'
								id={data.id}
								valRes={costPerUnitValRes}
								showValidation={costPerUnitShowVal}
								colLg={2}>
								<Input
									placeholder='Cost Per'
									value={costPerUnit}
									onChange={costPerUnitOnChange}
									onBlur={setCostPerUnitShowVal}
									prependIcon='Dollar'
									disabled={data.invoice}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='serviceCharge'
								label='Service Charge'
								id={data.id}
								valRes={serviceChargeValRes}
								showValidation={serviceChargeShowVal}
								colLg={2}>
								<Input
									placeholder='Service Charge'
									value={serviceCharge}
									onChange={serviceChargeOnChange}
									onBlur={setServiceChargeShowVal}
									prependIcon='Dollar'
									disabled={!serviceChargeItem || data.invoice}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='taxTotal' label='Tax' id={data.id} colLg={1}>
								<Input
									value={priceFormatter(tax)}
									onChange={() => {}}
									readOnly
									borderLess
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='totalCost' label='Total' id={data.id} colLg={1}>
								<Input
									placeholder='Total'
									value={priceFormatter(totalCost)}
									onChange={() => {}}
									readOnly
									borderLess
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
					disabled={data.invoice}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
MeterReadingForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		amperage: PropTypes.number,
		voltage: PropTypes.object,
		invoice: PropTypes.object,
		module: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setIsValid: PropTypes.func,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
	powerMeters: PropTypes.arrayOf(PropTypes.object),
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
};
MeterReadingForm.defaultProps = {
	data: {
		id: 0,
	},
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
	powerMeters: [],
	paymentTerms: [],
};

export default MeterReadingForm;
