import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Section from '../../reusables/layout/Section';
import FormGroup from '../../reusables/layout/FormGroup';
import useField from '../../../utils/hooks/useField';
import { numberParser } from '../../../utils/helpers/helper';
import Loading from '../../reusables/template/Loading';
import FormField from '../../reusables/template/FormField';
import Radio from '../../reusables/field/Radio';
import Toggle from '../../reusables/field/Toggle';
import Input from '../../reusables/field/Input';
import { max, min } from '../../../utils/helpers/validation';
import Selects from '../../reusables/field/Selects';

const BatchJobsForm = ({
	data,
	isLoading,
	onFormChange,
	submit,
	submitButtonAttr,
	isSubmitted,
	setIsValid,
	emailAddresses,
}) => {
	const [auditBalances, auditBalancesOnChange] = useField(
		data,
		'auditBalances',
		onFormChange,
		[],
		false
	);

	const [enableAutoPayBatch, enableAutoPayBatchOnChange] = useField(
		data,
		'enableAutoPayBatch',
		onFormChange,
		[],
		false
	);

	const [autoPayByInvoiceDate, autoPayByInvoiceDateOnChange] = useField(
		data,
		'autoPayByInvoiceDate',
		onFormChange,
		[],
		false
	);

	const [
		autoPayOffSetDays,
		autoPayOffSetDaysOnChange,
		autoPayOffSetDaysValRes,
		autoPayOffSetDaysShowVal,
		setAutoPayOffSetDaysShowVal,
	] = useField(
		data,
		'autoPayOffSetDays',
		onFormChange,
		[min(0), max(185)],
		null,
		numberParser(false)
	);

	const [enableAutoInvoiceBatch, enableAutoInvoiceBatchOnChange] = useField(
		data,
		'enableAutoInvoiceBatch',
		onFormChange,
		[],
		false
	);

	const [emailInvoices, emailInvoicesOnChange] = useField(
		data,
		'emailInvoices',
		onFormChange,
		[],
		false
	);

	const [emailStatements, emailStatementsOnChange] = useField(
		data,
		'emailStatements',
		onFormChange,
		[],
		false
	);

	const [
		generateMarinaInvoiceOffSetDays,
		generateMarinaInvoiceOffSetDaysOnChange,
		generateMarinaInvoiceOffSetDaysValRes,
		generateMarinaInvoiceOffSetDaysShowVal,
		setGenerateMarinaInvoiceOffSetDaysShowVal,
	] = useField(
		data,
		'generateMarinaInvoiceOffSetDays',
		onFormChange,
		[min(0), max(185)],
		null,
		numberParser(false)
	);

	const [
		generateCampgroundInvoiceOffSetDays,
		generateCampgroundInvoiceOffSetDaysOnChange,
		generateCampgroundInvoiceOffSetDaysValRes,
		generateCampgroundInvoiceOffSetDaysShowVal,
		setGenerateCampgroundInvoiceOffSetDaysShowVal,
	] = useField(
		data,
		'generateCampgroundInvoiceOffSetDays',
		onFormChange,
		[min(0), max(185)],
		null,
		numberParser(false)
	);

	const [
		generateBookingInvoiceOffSetDays,
		generateBookingInvoiceOffSetDaysOnChange,
		generateBookingInvoiceOffSetDaysValRes,
		generateBookingInvoiceOffSetDaysShowVal,
		setGenerateBookingInvoiceOffSetDaysShowVal,
	] = useField(
		data,
		'generateBookingInvoiceOffSetDays',
		onFormChange,
		[min(0), max(185)],
		null,
		numberParser(false)
	);

	const [generateInvoiceIncludeTransients, generateInvoiceIncludeTransientsOnChange] = useField(
		data,
		'generateInvoiceIncludeTransients',
		onFormChange,
		[],
		false
	);

	const [
		extendLongTermDays,
		extendLongTermDaysOnChange,
		extendLongTermDaysValRes,
		extendLongTermDaysShowVal,
		setExtendLongTermDaysShowVal,
	] = useField(data, 'extendLongTermDays', onFormChange, [min(0)], null, numberParser(false));

	const [
		purgeLogOffSetDays,
		purgeLogOffSetDaysOnChange,
		purgeLogOffSetDaysValRes,
		purgeLogOffSetDaysShowVal,
		setPurgeLogOffSetDaysShowVal,
	] = useField(data, 'purgeLogOffSetDays', onFormChange, [min(90)], null, numberParser(false));

	const [
		revRecOffsetDays,
		revRecOffsetDaysOnChange,
		revRecOffsetDaysValRes,
		revRecOffsetDaysShowVal,
		setRevRecOffsetDaysShowVal,
	] = useField(data, 'revRecOffsetDays', onFormChange, [min(0)], null, numberParser(false));

	const [revRecSummarizeEntry, revRecSummarizeEntryOnChange] = useField(
		data,
		'revRecSummarizeEntry',
		onFormChange,
		[],
		false
	);

	const [companyFromEmail, companyFromEmailOnChange] = useField(
		data,
		'companyFromEmail',
		onFormChange,
		[],
		null
	);

	const [installerEmail, installerEmailOnChange] = useField(data, 'installerEmail', onFormChange);

	const [autoPayItemizedPayments, autoPayItemizedPaymentsOnChange] = useField(
		data,
		'autoPayItemizedPayments',
		onFormChange,
		[],
		false
	);

	const [
		checkReservationOffsetDays,
		checkReservationOffsetDaysOnChange,
		checkReservationOffsetDaysValRes,
		checkReservationOffsetDaysShowVal,
		setCheckReservationOffsetDaysShowVal,
	] = useField(
		data,
		'checkReservationOffsetDays',
		onFormChange,
		[min(0)],
		null,
		numberParser(false)
	);

	useEffect(() => {
		if (isSubmitted) {
			setAutoPayOffSetDaysShowVal();
			setGenerateBookingInvoiceOffSetDaysShowVal();
			setGenerateMarinaInvoiceOffSetDaysShowVal();
			setGenerateCampgroundInvoiceOffSetDaysShowVal();
			setExtendLongTermDaysShowVal();
			setPurgeLogOffSetDaysShowVal();
			setRevRecOffsetDaysShowVal();
			setCheckReservationOffsetDaysShowVal();
		}
	}, [
		isSubmitted,
		setAutoPayOffSetDaysShowVal,
		setGenerateBookingInvoiceOffSetDaysShowVal,
		setGenerateMarinaInvoiceOffSetDaysShowVal,
		setGenerateCampgroundInvoiceOffSetDaysShowVal,
		setExtendLongTermDaysShowVal,
		setPurgeLogOffSetDaysShowVal,
		setRevRecOffsetDaysShowVal,
		setCheckReservationOffsetDaysShowVal,
	]);

	useEffect(() => {
		setIsValid(
			autoPayOffSetDaysValRes.isValid &&
				generateMarinaInvoiceOffSetDaysValRes.isValid &&
				generateCampgroundInvoiceOffSetDaysValRes.isValid &&
				generateBookingInvoiceOffSetDaysValRes.isValid &&
				extendLongTermDaysValRes.isValid &&
				purgeLogOffSetDaysValRes.isValid &&
				revRecOffsetDaysValRes.isValid &&
				checkReservationOffsetDaysValRes.isValid
		);
	}, [
		setIsValid,
		autoPayOffSetDaysValRes.isValid,
		generateMarinaInvoiceOffSetDaysValRes.isValid,
		generateCampgroundInvoiceOffSetDaysValRes.isValid,
		generateBookingInvoiceOffSetDaysValRes.isValid,
		extendLongTermDaysValRes.isValid,
		purgeLogOffSetDaysValRes.isValid,
		revRecOffsetDaysValRes.isValid,
		checkReservationOffsetDaysValRes.isValid,
	]);

	return (
		<Portlet className='sdms-form' fluid='fluid' hasFrame>
			<Portlet.Body>
				<Section title='Settings'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='companyFromEmail'
									label='Default From Email'
									id={data.id}
									colMd={6}>
									<Selects
										options={emailAddresses}
										placeholder='Default From Email'
										value={companyFromEmail}
										onChange={companyFromEmailOnChange}
										displayKey='displayName'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='installerEmail'
									label='Installer Emails'
									id={data.id}
									colMd={6}>
									<Input
										placeholder='Installer Emails'
										onChange={installerEmailOnChange}
										value={installerEmail}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='auditBalances'
									label='Audit Balances'
									id='auditBalances'
									colMd={2}>
									<Toggle
										spaceLess
										value={auditBalances}
										onChange={auditBalancesOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Auto-Pay'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableAutoPayBatch'
									label='Enable Auto-Pay'
									id='enableAutoPayBatch'
									colMd={2}>
									<Toggle
										spaceLess
										value={enableAutoPayBatch}
										onChange={enableAutoPayBatchOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoPayByInvoiceDate'
									label='Date Selection'
									id={data.id}
									colMd={4}>
									<Radio.Container isInline>
										<Radio
											checked={!autoPayByInvoiceDate}
											id='yes'
											name='autoPayByInvoiceDate'
											content='Due Date'
											className='sdms-radio--primary'
											onChange={() =>
												autoPayByInvoiceDateOnChange({
													target: { value: false },
												})
											}
										/>
										<Radio
											checked={autoPayByInvoiceDate}
											id='no'
											name='autoPayByInvoiceDate'
											content='Invoice Date'
											className='sdms-radio--primary'
											onChange={() =>
												autoPayByInvoiceDateOnChange({
													target: { value: true },
												})
											}
										/>
									</Radio.Container>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoPayItemizedPayments'
									label='Itemized Payments'
									id='autoPayItemizedPayments'
									colMd={2}>
									<Toggle
										spaceLess
										value={autoPayItemizedPayments}
										onChange={autoPayItemizedPaymentsOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoPayOffSetDays'
									label='Auto-Pay Offset Days'
									id={data.id}
									colMd={4}
									valRes={autoPayOffSetDaysValRes}
									showValidation={autoPayOffSetDaysShowVal}>
									<Input
										type='number'
										onChange={autoPayOffSetDaysOnChange}
										value={autoPayOffSetDays}
										onBlur={setAutoPayOffSetDaysShowVal}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Invoice Generation'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableAutoInvoiceBatch'
									label='Enable Auto-Invoice'
									id='enableAutoInvoiceBatch'
									colMd={2}>
									<Toggle
										spaceLess
										value={enableAutoInvoiceBatch}
										onChange={enableAutoInvoiceBatchOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='emailInvoices'
									label='Email Invoices'
									id='emailInvoices'
									colMd={2}>
									<Toggle
										spaceLess
										value={emailInvoices}
										onChange={emailInvoicesOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='emailStatements'
									label='Email Statements'
									id='emailStatements'
									colMd={2}>
									<Toggle
										spaceLess
										value={emailStatements}
										onChange={emailStatementsOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='generateMarinaInvoiceOffSetDays'
									label='Generate Marina Invoice Offset Days'
									id={data.id}
									colMd={3}
									valRes={generateMarinaInvoiceOffSetDaysValRes}
									showValidation={generateMarinaInvoiceOffSetDaysShowVal}>
									<Input
										type='number'
										onChange={generateMarinaInvoiceOffSetDaysOnChange}
										value={generateMarinaInvoiceOffSetDays}
										onBlur={setGenerateMarinaInvoiceOffSetDaysShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='generateCampgroundInvoiceOffSetDays'
									label='Generate Campground Invoice Offset Days'
									id={data.id}
									colMd={3}
									valRes={generateCampgroundInvoiceOffSetDaysValRes}
									showValidation={generateCampgroundInvoiceOffSetDaysShowVal}>
									<Input
										type='number'
										onChange={generateCampgroundInvoiceOffSetDaysOnChange}
										value={generateCampgroundInvoiceOffSetDays}
										onBlur={setGenerateCampgroundInvoiceOffSetDaysShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='generateBookingInvoiceOffSetDays'
									label='Generate Booking Invoice Offset Days'
									id={data.id}
									colMd={3}
									valRes={generateBookingInvoiceOffSetDaysValRes}
									showValidation={generateBookingInvoiceOffSetDaysShowVal}>
									<Input
										type='number'
										onChange={generateBookingInvoiceOffSetDaysOnChange}
										value={generateBookingInvoiceOffSetDays}
										onBlur={setGenerateBookingInvoiceOffSetDaysShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='generateInvoiceIncludeTransients'
									label='Include Transients'
									id='generateInvoiceIncludeTransients'
									colMd={2}>
									<Toggle
										spaceLess
										value={generateInvoiceIncludeTransients}
										onChange={generateInvoiceIncludeTransientsOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Reservations'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='extendLongTermDays'
									label='Extend Long Term Days'
									id={data.id}
									colMd={6}
									valRes={extendLongTermDaysValRes}
									showValidation={extendLongTermDaysShowVal}>
									<Input
										type='number'
										onChange={extendLongTermDaysOnChange}
										value={extendLongTermDays}
										onBlur={setExtendLongTermDaysShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='checkReservationOffsetDays'
									label='Check Reservations Offset Days'
									id={data.id}
									colMd={6}
									showValidation={checkReservationOffsetDaysShowVal}
									valRes={checkReservationOffsetDaysValRes}>
									<Input
										type='number'
										onChange={checkReservationOffsetDaysOnChange}
										value={checkReservationOffsetDays}
										onBlur={setCheckReservationOffsetDaysShowVal}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Logs'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='purgeLogOffSetDays'
									label='Purge Log Offset Days'
									id={data.id}
									colMd={6}
									showValidation={purgeLogOffSetDaysShowVal}
									valRes={purgeLogOffSetDaysValRes}>
									<Input
										type='number'
										onChange={purgeLogOffSetDaysOnChange}
										value={purgeLogOffSetDays}
										onBlur={setPurgeLogOffSetDaysShowVal}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Revenue Recognition'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='revRecOffsetDays'
									label='Schedule Offset Days'
									id={data.id}
									colMd={6}
									showValidation={revRecOffsetDaysShowVal}
									valRes={revRecOffsetDaysValRes}>
									<Input
										type='number'
										onChange={revRecOffsetDaysOnChange}
										value={revRecOffsetDays}
										onBlur={setRevRecOffsetDaysShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='revRecSummarizeEntry'
									label='Summarize Entry'
									id={data.id}
									colMd={6}>
									<Toggle
										onChange={revRecSummarizeEntryOnChange}
										value={revRecSummarizeEntry}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};

BatchJobsForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		auditBalances: PropTypes.bool,
		enableAutoPayBatch: PropTypes.bool,
		autoPayByInvoiceDate: PropTypes.bool,
		autoPayOffSetDays: PropTypes.number,
		enableAutoInvoiceBatch: PropTypes.bool,
		emailInvoices: PropTypes.bool,
		emailStatements: PropTypes.bool,
		generateMarinaInvoiceOffSetDays: PropTypes.number,
		generateCampgroundInvoiceOffSetDays: PropTypes.number,
		generateBookingInvoiceOffSetDays: PropTypes.number,
		extendLongTermDays: PropTypes.number,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submit: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	emailAddresses: PropTypes.arrayOf(PropTypes.object),
};
BatchJobsForm.defaultProps = {
	data: {
		id: 0,
		auditBalances: false,
		enableAutoPayBatch: false,
		autoPayByInvoiceDate: false,
		autoPayOffSetDays: 0,
		enableAutoInvoiceBatch: false,
		emailInvoices: false,
		emailStatements: false,
		generateMarinaInvoiceOffSetDays: 0,
		generateCampgroundInvoiceOffSetDays: 0,
		generateBookingInvoiceOffSetDays: 0,
		extendLongTermDays: 0,
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	submit: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	emailAddresses: [],
};

export default BatchJobsForm;
