import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVGIcon from '../element/SVGIcon';

const ModalInput = ({ value, onCancel, isFocused, isSelected, onClick }) => {
	const classRef = useRef();
	const first = useRef(true);

	useEffect(() => {
		if (isFocused) {
			classRef.current.focus();
			if (first.current || isSelected) {
				classRef.current.classList.add('sdms-input--select-all');
				first.current = false;
			} else {
				classRef.current.classList.remove('sdms-input--select-all');
			}
		} else {
			classRef.current.blur();
			first.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, isFocused, isSelected]);

	const inputInterior = (
		<div
			role='presentation'
			tabIndex={-1}
			ref={classRef}
			className='form-control form-control-lg sdms-input--cursor'
			onClick={onClick}>
			<div>{value}</div>
		</div>
	);

	if (onCancel) {
		return (
			<div
				className={classNames('sdms-input-icon', {
					'sdms-input-icon--right': value !== '0',
				})}>
				{inputInterior}
				{value !== '0' && (
					<span className='sdms-input-icon__icon sdms-input-icon__icon--right'>
						<span>
							<SVGIcon
								name='Error-circle'
								className='sdms-cursor--pointer'
								onClick={onCancel}
							/>
						</span>
					</span>
				)}
			</div>
		);
	}
	return inputInterior;
};
ModalInput.propTypes = {
	onCancel: PropTypes.func,
	value: PropTypes.string.isRequired,
	isFocused: PropTypes.bool,
	onClick: PropTypes.func,
};
ModalInput.defaultProps = {
	onCancel: null,
	isFocused: true,
	onClick: () => {},
};

export default ModalInput;
