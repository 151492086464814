export const modals = {
	CUSTOMER: 'customer',
	LOGIN: 'login',
	CANCEL: 'cancel',
	DELETE: 'delete',
	CONTINUE: 'continue',
	CONFIRMATION: 'confirmation',
	SIGN_OUT: 'sign_out',
	SIMPLE_ONLINE_CONFIRMATION: 'simple_online_confirmation',
};

export const reservationPayStatuses = {
	NOT_PAID: 'not_paid',
	DEFAULT: 'default',
	PAYING: 'paying',
	PAID: 'paid',
};

export const payButtonAttributes = {
	not_paid: { text: 'Not Paid, Retry', icon: 'Error-circle', label: 'danger' },
	default: { text: 'Pay & Confirm Booking', icon: 'Dollar', label: 'success' },
	paying: { text: 'Paying...', icon: 'Other#2', label: 'success' },
	paid: { text: 'Paid & Confirm Booking', icon: 'Done-circle', label: 'success' },
};

export const searchResultViews = {
	LIST: 'List',
	GRID: 'Grid',
	MAP: 'Map',
};
