import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import ReactTooltip from 'react-tooltip';

import ReactDiffViewer from 'react-diff-viewer';
import pages from '../../pages';
import HeaderContext from '../../../app/contexts/HeaderContext';
import { convertDateToUTC, dateFormatter } from '../../../utils/helpers/helper';
import { auditTables } from '../../../utils/constants/constants';
import useTooltipPlace from '../../../utils/hooks/useTooltipPlace';

import ListContainer from '../../reusables/template/ListContainer';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import Badge from '../../reusables/element/Badge';
import Portal from '../../reusables/layout/Portal';

const DateCell = ({ data }) => dateFormatter(convertDateToUTC(moment(data.dmlTimestamp).toDate()));

const StatusCell = ({ data }) => {
	const colors = {
		INSERT: 'success',
		UPDATE: 'info',
		DELETE: 'warning',
	};

	return (
		<Badge
			design={colors[data.dmlType]}
			isInline
			isUnified
			fontWeight='bold'
			size='lg'
			className='sdms-text-overflow'>
			<span className='sdms-text-overflow sdms-font-transform-c'>{data.dmlType}</span>
		</Badge>
	);
};

StatusCell.propTypes = {
	data: PropTypes.shape({
		dmlType: PropTypes.string,
	}),
};

StatusCell.defaultProps = {
	data: {
		dmlType: '',
	},
};

const JsonDiffer = ({ data }) => {
	const tooltipRef = useRef();

	const tooltipPlace = useTooltipPlace('bottom', tooltipRef);

	return (
		<>
			<span
				ref={tooltipRef}
				style={{ cursor: 'pointer' }}
				data-tip='tooltip'
				data-for={data.id.toString()}
				data-event='click'
				onClick={() => {}}
				role='presentation'>
				<Badge
					design='warning'
					isInline
					isUnified
					fontWeight='boldest'
					size='lg'
					className='sdms-text-overflow'>
					<span className='sdms-text-overflow'>!</span>
				</Badge>
			</span>
			<Portal isTooltip>
				<ReactTooltip
					id={data.id.toString()}
					clickable
					type='light'
					place={tooltipPlace}
					globalEventOff='click'
					className='sdms-audit-tooltip sdms-p0'>
					<ReactDiffViewer
						oldValue={JSON.stringify(data.oldData, null, 2)}
						newValue={JSON.stringify(data.newData, null, 2)}
						splitView
						showDiffOnly
						hideLineNumbers
					/>
				</ReactTooltip>
			</Portal>
		</>
	);
};

JsonDiffer.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

JsonDiffer.defaultProps = {
	data: null,
};

const AuditList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.systemSettings.auditLogs.text,
				path: pages.systemSettings.auditLogs.path,
			},
		]);
		headerContext.setPageTitle(pages.systemSettings.auditLogs.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='audits'
			title='Audits'
			fluid='fluid'
			history={history}
			customFilters={[
				{
					component: 'selects',
					fieldName: 'tableName',
					displayKey: 'display',
					placeholder: 'All',
					default: {
						display: 'All',
						id: null,
					},
					options: auditTables,
				},
			]}
			customFiltersInQuickPanel={false}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='#' cellData='id' sortable='id' />
				<List.Col label='Entity' cellData='entityId' sortable='entityId' />
				<List.Col label='Table' cellData='tableName' sortable='tableName' />
				<List.Col label='Diff' cellComponent={<JsonDiffer />} />
				<List.Col label='Type' cellComponent={<StatusCell />} sortable='dmlType' />
				<List.Col label='Timestamp' cellComponent={<DateCell />} sortable='dmlTimestamp' />
				<List.Col align='right' onlyHover width={150}>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

AuditList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default AuditList;
