import React, { useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import { MapInteractionCSS } from 'react-map-interaction';
import update from 'immutability-helper';
import Moveable from 'react-moveable';
import { useLocation } from 'react-router-dom';
import tinyColor from 'tinycolor2';

import useComponentSize from '@rehooks/component-size';
import useField from '../../../utils/hooks/useField';
import usePages from '../../../utils/hooks/usePages';
import { required } from '../../../utils/helpers/validation';
import apiCall, { modules, pathToUrl } from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	generateId,
	getThemeColor,
	getSizeVal,
} from '../../../utils/helpers/helper';

import FormGroup from '../layout/FormGroup';
import FormField from '../template/FormField';
import Input from '../field/Input';
import ImageUpload, { _defaultImage } from '../field/ImageUpload';
import Loading from '../template/Loading';
import Portlet from '../layout/Portlet';
import SVGIcon from '../element/SVGIcon';
import HeaderContext from '../../../app/contexts/HeaderContext';
import UserContext from '../../../app/contexts/UserContext';
import MapUnitList from './MapUnitList';
import MapUnitForm from './MapUnitForm';
import ThemeContext from '../../../app/contexts/ThemeContext';

export const MapItem = ({ unitMapUnit, dispatch, usedSize, editItem }) => {
	const themeContext = useContext(ThemeContext);
	const moveAbleRef = useRef();

	const icon = `url('data:image/svg+xml;utf8,${ReactDOMServer.renderToString(
		<SVGIcon
			name={unitMapUnit.unit.icon ? unitMapUnit.unit.icon.value : 'Square'}
			fill={tinyColor(
				getThemeColor(themeContext.data.theme)[unitMapUnit.unit.mapColor || 'info']
			)
				.setAlpha(unitMapUnit.unit.opacity ? unitMapUnit.unit.opacity / 100 : 1)
				.toRgbString()}
			preserveAspectRatio='none'
		/>
	)}')`;

	const sizes = {
		x: (unitMapUnit.posX * usedSize.width) / 100,
		y: (unitMapUnit.posY * usedSize.height) / 100,
		width: (unitMapUnit.width * usedSize.width) / 20,
		height: (unitMapUnit.height * usedSize.width) / 20,
		rotation: unitMapUnit.rotation,
	};

	const onEventStart = () => {
		dispatch({
			type: 'edit',
			payload: unitMapUnit.unit.id,
		});
	};

	useEffect(() => {
		if (moveAbleRef.current) moveAbleRef.current.updateRect();
	}, [unitMapUnit, usedSize]);

	return (
		<>
			<div
				id={`unitMapItem-${unitMapUnit.id}`}
				role='presentation'
				className='register-item__table register-item__table--draggable'
				style={{
					width: sizes.width,
					height: sizes.height,
					top: sizes.y,
					left: sizes.x,
					transform: `rotate(${sizes.rotation}deg)`,
					backgroundImage: `${icon}`,
					position: 'absolute',
					fontSize:
						usedSize.height >= usedSize.width
							? `${usedSize.width *
									0.01618 *
									(unitMapUnit.fontSize ? unitMapUnit.fontSize.size : 1)}px`
							: `${usedSize.height *
									0.01618 *
									(unitMapUnit.fontSize ? unitMapUnit.fontSize.size : 1)}px`,
				}}
				onClick={() => dispatch({ type: 'edit', payload: unitMapUnit.unit.id })}>
				{unitMapUnit.hideCaption
					? ''
					: unitMapUnit.unit.mapCaption
					? unitMapUnit.unit.mapCaption
					: unitMapUnit.unit.name}
			</div>
			{editItem && (
				<Moveable
					target={document.getElementById(`unitMapItem-${unitMapUnit.id}`)}
					ref={moveAbleRef}
					draggable
					throttleDrag={0}
					resizable
					throttleResize={0}
					rotatable
					rotationPosition='top'
					throttleRotate={0}
					origin={false}
					snappable
					bounds={{ left: 0, top: 0, right: usedSize.width, bottom: usedSize.height }}
					onDragStart={onEventStart}
					onResizeStart={onEventStart}
					onRotateStart={onEventStart}
					onDrag={({ top, left }) => {
						dispatch({
							type: 'change',
							payload: {
								...unitMapUnit.unit,
								width: unitMapUnit.width,
								height: unitMapUnit.height,
								posX: (left / usedSize.width) * 100,
								posY: (top / usedSize.height) * 100,
								rotation: unitMapUnit.rotation,
								fontSize: unitMapUnit.fontSize,
								hideCaption: unitMapUnit.hideCaption,
							},
						});
					}}
					onResize={({ width, height }) => {
						dispatch({
							type: 'change',
							payload: {
								...unitMapUnit.unit,
								width: (width / usedSize.width) * 20,
								height: (height / usedSize.width) * 20,
								posX: unitMapUnit.posX,
								posY: unitMapUnit.posY,
								rotation: unitMapUnit.rotation,
								fontSize: unitMapUnit.fontSize,
								hideCaption: unitMapUnit.hideCaption,
							},
						});
					}}
					onRotate={({ beforeDelta }) => {
						dispatch({
							type: 'change',
							payload: {
								...unitMapUnit.unit,
								width: unitMapUnit.width,
								height: unitMapUnit.height,
								posX: unitMapUnit.posX,
								posY: unitMapUnit.posY,
								rotation: parseInt(unitMapUnit.rotation + beforeDelta, 10) % 360,
								fontSize: unitMapUnit.fontSize,
								hideCaption: unitMapUnit.hideCaption,
							},
						});
					}}
				/>
			)}
		</>
	);
};
MapItem.propTypes = {
	dispatch: PropTypes.func,
	unitMapUnit: PropTypes.shape({
		id: PropTypes.number,
		unit: PropTypes.object,
		posX: PropTypes.number,
		posY: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number,
		rotation: PropTypes.number,
		fontSize: PropTypes.object,
		hideCaption: PropTypes.bool,
	}).isRequired,
	usedSize: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}).isRequired,
	editItem: PropTypes.bool,
};
MapItem.defaultProps = {
	dispatch: () => {},
	editItem: false,
};

const MapForm = ({
	data,
	setIsValid,
	isLoading,
	isSubmitted,
	onFormChange,
	setTitle,
	marinaUnits,
	bookingUnits,
	campgroundUnits,
	isSubmitting,
	fontSizes,
}) => {
	const location = useLocation(); // React Hook

	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const pages = usePages();

	const moduleData = useMemo(() => {
		if (location.pathname.indexOf('marina') > -1)
			return {
				text: pages.marina.settings.maps.text,
				breadcrumb: [
					{ title: pages.marina.default.text, path: pages.marina.dashboard.path },
					{ title: pages.marina.settings.text, path: pages.marina.settings.path },
					{
						title: pages.marina.settings.maps.text,
						path: pages.marina.settings.maps.path,
					},
				],
				title: pages.marina.settings.maps.text,
				icon: pages.marina.settings.maps.icon,
				module: userContext.data.user.company.modules.find(m => m.value === modules.MARINA),
			};

		if (location.pathname.indexOf('campground') > -1)
			return {
				text: pages.campground.settings.maps.text,
				breadcrumb: [
					{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
					{ title: pages.campground.settings.text, path: pages.campground.settings.path },
					{
						title: pages.campground.settings.maps.text,
						path: pages.campground.settings.maps.path,
					},
				],
				title: pages.campground.settings.maps.text,
				icon: pages.campground.settings.maps.icon,
				module: userContext.data.user.company.modules.find(
					m => m.value === modules.CAMPGROUND
				),
			};

		return {
			text: pages.booking.settings.maps.text,
			breadcrumb: [
				{ title: pages.booking.default.text, path: pages.booking.dashboard.path },
				{ title: pages.booking.settings.text, path: pages.booking.settings.path },
				{
					title: pages.booking.settings.maps.text,
					path: pages.booking.settings.maps.path,
				},
			],
			title: pages.booking.settings.maps.text,
			icon: pages.booking.settings.maps.icon,
			module: userContext.data.user.company.modules.find(m => m.value === modules.BOOKINGS),
		};
	}, [location.pathname, userContext.data.user.company.modules, pages]);

	const unitMapContainer = useRef();

	const unitMapContainerSize = useComponentSize(unitMapContainer);

	const [unitMapImageSize, setUnitMapImageSize] = useState({ top: 0, left: 0 });

	const unitMapImageRef = useRef(null);

	const usedSize = getSizeVal(unitMapContainerSize, unitMapImageSize);

	const [mapSize, setMapSize] = useState({ scale: 1, translation: { x: 0, y: 0 } });

	const backup = useRef({});

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [defaultBackground, setDefaultBackground] = useState(null);

	const [
		background,
		backgroundOnChange,
		backgroundValRes,
		backgroundShowVal,
		setBackgroundShowVal,
	] = useField(data, 'background', onFormChange, [required], null);

	const defaultUnit = {
		posX: 0,
		posY: 0,
		width: 2,
		height: 1,
		rotation: 0,
		isInMap: false,
	};

	const reducer = (state, action) => {
		const { type, payload } = action;

		if (type === 'set') {
			if ('unitMapUnits' in payload) {
				const nameSet = new Set(payload.unitMapUnits.map(item => item.unit.name));

				payload.unitMapUnits = Array.from(nameSet).map(name =>
					payload.unitMapUnits.find(item => item.unit.name === name)
				);
			}

			return update(state, { $merge: payload });
		}

		if (type === 'add') {
			if (state.unitMapUnits.findIndex(umu => umu.unit.id === payload.id) > -1) return state;

			onFormChange();

			const unitIndex = state.units.findIndex(u => u.id === payload.id);

			const newUnitMapUnitId = generateId(state.unitMapUnits);

			return update(state, {
				units: {
					[unitIndex]: {
						unitMapUnits: {
							$push: [
								{
									id: newUnitMapUnitId,
									unitMap: update(data, { $unset: ['unitMapUnits'] }),
									posX: defaultUnit.posX,
									posY: defaultUnit.posY,
									width: defaultUnit.width,
									height: defaultUnit.height,
									rotation: defaultUnit.rotation,
									fontSize: fontSizes.find(fs => fs.value === 'Normal'),
									hideCaption: defaultUnit.hideCaption,
								},
							],
						},
					},
				},
				unitMapUnits: {
					$push: [
						{
							unit: update(payload, {
								$unset: [
									'unitMapUnits',
									'posX',
									'posY',
									'width',
									'height',
									'rotation',
									'fontSize',
									'hideCaption',
								],
							}),
							id: newUnitMapUnitId,
							posX: defaultUnit.posX,
							posY: defaultUnit.posY,
							width: defaultUnit.width,
							height: defaultUnit.height,
							rotation: defaultUnit.rotation,
							fontSize: fontSizes.find(fs => fs.value === 'Normal'),
							hideCaption: defaultUnit.hideCaption,
							module: moduleData.module,
						},
					],
				},
			});
		}

		if (type === 'edit') {
			if (state.editUnit && state.editUnit.id === payload) return state;

			backup.current = { ...state };

			// get unit form data and open it.
			const unit = update(
				state.units.find(t => t.id === payload),
				{ $unset: ['unitMapUnits'] }
			);

			const unitMapUnit = state.unitMapUnits.find(umu => umu.unit.id === payload);

			if (!unitMapUnit) return state;

			return update(state, {
				editUnit: {
					$set: {
						id: unit.id,
						name: unit.name,
						icon: unit.icon,
						openNewOrderByDefault: unit.openNewOrderByDefault,
						priorityPrint: unit.priorityPrint,
						posX: (unitMapUnit || {}).posX,
						posY: (unitMapUnit || {}).posY,
						width: (unitMapUnit || {}).width,
						height: (unitMapUnit || {}).height,
						rotation: (unitMapUnit || {}).rotation,
						fontSize: (unitMapUnit || {}).fontSize,
						hideCaption: (unitMapUnit || {}).hideCaption,
						isInMap: typeof unitMapUnit !== 'undefined',
					},
				},
				currentUnitMapUnit: { $set: unitMapUnit.id },
			});
		}

		if (type === 'remove') {
			const unitMapUnit = state.unitMapUnits.filter(umu => umu.unit.id === payload)[0];

			if (!unitMapUnit) return state;

			onFormChange();

			return update(state, {
				units: {
					$set: state.units.map(t => {
						if (t.id === payload) {
							t.unitMapUnits = t.unitMapUnits.filter(
								umu => umu.id !== unitMapUnit.id
							);
						}

						return t;
					}),
				},
				unitMapUnits: {
					$set: state.unitMapUnits.filter(umu => umu.unit.id !== payload),
				},
				editUnit: { $set: null },
			});
		}

		if (type === 'delete') {
			onFormChange();

			return update(state, {
				units: {
					$set: state.units.filter(t => t.id !== payload),
				},
				unitMapUnits: {
					$set: state.unitMapUnits.filter(umu => umu.unit.id !== payload),
				},
			});
		}

		if (type === 'cancel') {
			return update(state, {
				units: {
					$set: backup.current.units,
				},
				unitMapUnits: {
					$set: backup.current.unitMapUnits,
				},
				isTableListOpen: { $set: true },
				editUnit: { $set: null },
			});
		}

		if (type === 'save') {
			const unitIndex = state.units.findIndex(t => t.id === state.editUnit.id);

			const unitMapUnitIndex = state.unitMapUnits.findIndex(
				umu => umu.unit.id === state.editUnit.id
			);

			state = update(state, {
				editUnit: { $set: null },
				units: { [unitIndex]: { id: { $set: payload.id } } },
			});

			if (unitMapUnitIndex > -1) {
				state = update(state, {
					unitMapUnits: {
						[unitMapUnitIndex]: {
							unit: {
								id: { $set: payload.id },
								'@id': { $set: payload['@id'] },
								'@type': { $set: payload['@type'] },
							},
						},
					},
				});
			}
			return state;
		}

		if (type === 'change') {
			const unitIndex = state.units.findIndex(u => u.id === payload.id);
			if (unitIndex === -1) return state;

			const unitItemUnitMapUnitIndex = state.units[unitIndex].unitMapUnits.findIndex(
				umu => umu.unitMap.id === data.id
			);

			const unitMapUnitIndex = state.unitMapUnits.findIndex(
				umu => umu.unit.id === payload.id
			);

			if (unitMapUnitIndex === -1 || unitItemUnitMapUnitIndex === -1) return state;

			onFormChange();

			// add/update unitMapUnit
			state = update(state, {
				unitMapUnits: {
					[unitMapUnitIndex]: {
						$merge: {
							posX: payload.posX,
							posY: payload.posY,
							width: payload.width,
							height: payload.height,
							rotation: payload.rotation,
							fontSize: payload.fontSize,
							hideCaption: payload.hideCaption,
						},
					},
				},
			});

			if (state.editUnit !== null) {
				return update(state, {
					editUnit: {
						$merge: {
							posX: payload.posX,
							posY: payload.posY,
							width: payload.width,
							height: payload.height,
							rotation: payload.rotation,
							fontSize: payload.fontSize,
							hideCaption: payload.hideCaption,
						},
					},
				});
			}
		}

		if (type === 'closeEdit') {
			return update(state, {
				isTableListOpen: { $set: true },
				editUnit: { $set: null },
				currentUnitMapUnit: { $set: null },
			});
		}

		if (type === 'afterSave') {
			payload.forEach(umu => {
				const unitIndex = state.units.findIndex(u => u.id === umu.unit.id);

				if (unitIndex > -1) {
					const unitUnitMapUnitIndex = state.units[unitIndex].unitMapUnits.findIndex(
						uumu => uumu.id === 0
					);

					if (unitUnitMapUnitIndex > -1) {
						state = update(state, {
							units: {
								[unitIndex]: {
									unitMapUnits: {
										[unitUnitMapUnitIndex]: { id: { $set: umu.id } },
									},
								},
							},
						});
					}
				}

				const unitMapUnitIndex = state.unitMapUnits.findIndex(
					_umu => _umu.unit.id === umu.unit.id
				);

				if (unitMapUnitIndex > -1)
					state = update(state, {
						unitMapUnits: { [unitMapUnitIndex]: { id: { $set: umu.id } } },
					});
			});
		}

		return state;
	};

	const [unitMap, dispatch] = useReducer(reducer, {
		units: [],
		unitMapUnits: data.unitMapUnits ? [...data.unitMapUnits] : [],
		editUnit: null,
		isTableListOpen: false,
	});

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setBackgroundShowVal();
		}
	}, [isSubmitted, setNameShowVal, setBackgroundShowVal]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid && backgroundValRes.isValid && unitMap.units.filter(t => t.id !== 0)
		);
	}, [nameValRes.isValid, backgroundValRes.isValid, unitMap, setIsValid]);

	useEffect(() => {
		if (!isLoading)
			dispatch({
				type: 'set',
				payload: { unitMapUnits: data.unitMapUnits || [] },
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		if (marinaUnits.length)
			dispatch({
				type: 'set',
				payload: { units: marinaUnits },
			});
	}, [marinaUnits]);

	useEffect(() => {
		if (bookingUnits.length)
			dispatch({
				type: 'set',
				payload: { units: bookingUnits },
			});
	}, [bookingUnits]);

	useEffect(() => {
		if (campgroundUnits.length)
			dispatch({
				type: 'set',
				payload: { units: campgroundUnits },
			});
	}, [campgroundUnits]);

	useEffect(() => {
		data.unitMapUnits = [...unitMap.unitMapUnits];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unitMap.unitMapUnits]);

	useEffect(() => {
		data.module = moduleData.module;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [moduleData]);

	useEffect(() => {
		if (!isSubmitting && !isLoading)
			dispatch({ type: 'afterSave', payload: data.unitMapUnits });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitting]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			...moduleData.breadcrumb,
			{ title: name || `New ${moduleData.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${moduleData.text}`);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		if (!isLoading) {
			apiCall(
				'GET',
				'media',
				res => {
					if (!data.background) {
						backgroundOnChange({
							target: {
								name: 'productImage',
								value: res[0],
							},
						});
					}
					setDefaultBackground(res[0]);
				},
				err => {
					addErrorNotification(err.toString());
				},
				'',
				null,
				{ name: 'default_table_map_background' }
			);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	return (
		<div className='row h-100'>
			<div className='col-md-6 col-xl-4 sdms-portlet--fluid-container'>
				{unitMap.editUnit === null ? (
					<>
						<Portlet className='sdms-form flex-grow-0' style={{ minHeight: 330.64 }}>
							<Portlet.Head>
								<Portlet.HeadLabelTitle
									portletIcon={pages.marina.settings.maps.icon2}
									smallTitle={name || 'New Map'}>
									Settings
								</Portlet.HeadLabelTitle>
							</Portlet.Head>
							<Portlet.Body>
								<FormGroup isLast>
									<Loading isLoading={isLoading}>
										<FormField
											name='name'
											label='Name'
											id={data.id}
											valRes={nameValRes}
											showValidation={nameShowVal}
											col={12}>
											<Input
												type='text'
												placeholder='Name (Required)'
												value={name}
												onChange={nameOnChange}
												onBlur={setNameShowVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='background'
											label='Background'
											description='.png, .jpg or .jpeg only!'
											valRes={backgroundValRes}
											showValidation={backgroundShowVal}
											col={12}>
											<ImageUpload
												media={background}
												setMedia={image =>
													backgroundOnChange({
														target: {
															name: 'productImage',
															value: image,
														},
													})
												}
												defaultImage={defaultBackground}
											/>
										</FormField>
									</Loading>
								</FormGroup>
							</Portlet.Body>
						</Portlet>
						<MapUnitList
							units={unitMap.units.sort((a, b) => a.name.localeCompare(b.name))}
							dispatch={dispatch}
							unitMapId={data.id}
						/>
					</>
				) : (
					<MapUnitForm
						key={unitMap.editUnit.id}
						data={unitMap.editUnit}
						dispatch={dispatch}
						fontSizes={fontSizes}
					/>
				)}
			</div>
			<div className='col-md-6 col-xl-8'>
				<Portlet id='register-table' fluid='fluid' everyTimeFluid>
					<Portlet.Body
						ref={unitMapContainer}
						className='sdms-portlet__body--fit overflow-hidden'>
						<MapInteractionCSS
							showControls
							disablePan
							minScale={1}
							maxScale={10}
							value={mapSize}
							onChange={value =>
								setMapSize({
									scale: value.scale,
									translation: {
										x:
											value.translation.x > 0
												? 0
												: value.translation.x <
												  usedSize.width * -1 * (value.scale - 1)
												? usedSize.width * -1 * (value.scale - 1)
												: value.translation.x,
										y:
											value.translation.y > 0
												? 0
												: value.translation.y <
												  usedSize.height * -1 * (value.scale - 1)
												? usedSize.height * -1 * (value.scale - 1)
												: value.translation.y,
									},
								})
							}>
							<img
								alt='Map'
								src={background ? pathToUrl(background.path) : _defaultImage}
								ref={unitMapImageRef}
								style={{ position: 'absolute', width: '100%' }}
								onLoad={() =>
									setUnitMapImageSize({
										width: unitMapImageRef.current.naturalWidth,
										height: unitMapImageRef.current.naturalHeight,
									})
								}
							/>
							<div
								role='presentation'
								style={{ ...usedSize, transform: 'translateX(0px)' }}>
								{unitMap.unitMapUnits
									.filter(umu => umu.unit.id)
									.map(umu =>
										unitMapImageSize.width ? (
											<MapItem
												key={umu.unit.id}
												unitMapUnit={umu}
												dispatch={dispatch}
												units={unitMap.units}
												mapSize={unitMapImageSize}
												usedSize={usedSize}
												editItem={
													unitMap.editUnit &&
													unitMap.editUnit.id === umu.unit.id
												}
											/>
										) : null
									)}
							</div>
						</MapInteractionCSS>
					</Portlet.Body>
				</Portlet>
			</div>
		</div>
	);
};
MapForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		unitMapUnits: PropTypes.arrayOf(PropTypes.object),
		module: PropTypes.object,
		background: PropTypes.object,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isSubmitting: PropTypes.bool,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	marinaUnits: PropTypes.arrayOf(PropTypes.object),
	bookingUnits: PropTypes.arrayOf(PropTypes.object),
	campgroundUnits: PropTypes.arrayOf(PropTypes.object),

	fontSizes: PropTypes.arrayOf(PropTypes.object),
};
MapForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		unitMapUnits: [],
		module: null,
	},
	setIsValid: () => {},
	isSubmitted: false,
	isSubmitting: false,
	isLoading: false,
	onFormChange: () => {},
	marinaUnits: [],
	bookingUnits: [],
	campgroundUnits: [],
	fontSizes: [],
};

export default MapForm;
