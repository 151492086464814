import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import DialogBox from '../../DialogBox';

const DeleteDialog = ({ isOpen, onDelete, onClose }) => {
	return (
		<DialogBox
			open={isOpen}
			title=''
			content='Booking will be deleted. Are you sure?'
			type='question'>
			<Button
				className='sdms-font-transform-c'
				text='Delete booking'
				label='danger'
				icon='Trash'
				onClick={onDelete}
			/>
			<Button
				className='sdms-font-transform-c'
				text='Continue'
				design='clean'
				icon='Edit'
				onClick={onClose}
			/>
		</DialogBox>
	);
};

DeleteDialog.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onDelete: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default DeleteDialog;
