import React, { useContext, useEffect } from 'react';
import UserContext from '../../../app/contexts/UserContext';

import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';
import useOutlet from '../../../utils/hooks/useOutlet';

import FormContainer from '../../reusables/template/FormContainer';
import GeneralSettingsForm from './GeneralSettingsForm';

const GeneralSettingsEdit = () => {
	// Use current user to get company data.
	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const [outletKey] = useOutlet();

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.pos.default.text, path: pages.pos.dashboard.path },
			{ title: pages.pos.settings.text, path: pages.pos.settings.path },
			{ title: pages.pos.settings.posSettings.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.pos.settings.posSettings.text);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<FormContainer
			key={outletKey}
			dataId={
				userContext.data.selectedOutlet.settings
					? userContext.data.selectedOutlet.settings.id.toString()
					: '0'
			}
			dataName='outletSettings'
			fields={<GeneralSettingsForm />}
			lists={['taxRates', 'printers', 'printSettings']}
			showBackButton={false}
			pageTitle={pages.pos.settings.posSettings.text}
			afterSubmit={outletSetting => userContext.updateOutlet(outletSetting, true)}
		/>
	);
};

export default GeneralSettingsEdit;
