import ReactDOM from 'react-dom';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactNotification from 'react-notifications-component';
import { registerLicense } from '@syncfusion/ej2-base';
import { UserContextProvider } from './app/contexts/UserContext';
import { HeaderContextProvider } from './app/contexts/HeaderContext';
import { ThemeContextProvider } from './app/contexts/ThemeContext';
import { MessageContextProvider } from './app/contexts/MessageContext';
import Portal from './components/reusables/layout/Portal';
import * as serviceWorker from './serviceWorker';
import App from './app/App';
import Loading from './components/reusables/template/Loading';

import './assets/fonts/Poppins.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './assets/styles/general/style.scss';
import 'react-slidedown/lib/slidedown.css';

console.log(`Version:${process.env.REACT_APP_VERSION}`);

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE);

ReactDOM.render(
	<Suspense fallback={<Loading isLoading type='initial' />}>
		<BrowserRouter>
			<React.StrictMode>
				<ThemeContextProvider>
					<UserContextProvider>
						<MessageContextProvider>
							<HeaderContextProvider>
								<App />
							</HeaderContextProvider>
						</MessageContextProvider>
					</UserContextProvider>
				</ThemeContextProvider>
				<Portal>
					<ReactNotification />
				</Portal>
			</React.StrictMode>
		</BrowserRouter>
	</Suspense>,
	document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
