import { getModuleByPath } from './reusable';
import { modules } from './apiCall';

const hasPermission = (userContext, permissions) => {
	const module = getModuleByPath();

	let permissionName = null;

	if (module === modules.MARINA) permissionName = permissions.marina;
	else if (module === modules.CAMPGROUND) permissionName = permissions.campground;
	else if (module === modules.BOOKINGS) permissionName = permissions.bookings;

	return userContext.hasPermission(permissionName);
};

export const hasOverrideConstraintPermission = userContext =>
	hasPermission(userContext, {
		marina: 'override_marina_constraints',
		campground: 'override_campground_constraints',
		bookings: 'override_bookings_constraints',
	});

export const hasCheckInReservationPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_check_in_reservations',
		campground: 'campground_check_in_reservations',
		bookings: 'bookings_check_in_reservations',
	});

export const hasCheckOutReservationPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_check_out_reservations',
		campground: 'campground_check_out_reservations',
		bookings: 'bookings_check_out_reservations',
	});

export const hasCancelReservationPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_cancel_reservations',
		campground: 'campground_cancel_reservations',
		bookings: 'bookings_cancel_reservations',
	});

export const hasChangeReservationStatusPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_change_reservation_status',
		campground: 'campground_change_reservation_status',
		bookings: 'bookings_change_reservation_status',
	});

export const hasEditLockedReservationPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_edit_locked_reservation',
		campground: 'campground_edit_locked_reservation',
		bookings: 'bookings_edit_locked_reservation',
	});

export const hasEditReservationDatesPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_edit_reservation_dates',
		campground: 'campground_edit_reservation_dates',
		bookings: 'bookings_edit_reservation_dates',
	});

export const hasEditReservationUnitPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_edit_reservation_unit',
		campground: 'campground_edit_reservation_unit',
		bookings: 'bookings_edit_reservation_unit',
	});

export const hasSwapReservationUnitPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_swap_reservation_units',
		campground: 'campground_swap_reservation_units',
		bookings: 'bookings_swap_reservation_units',
	});

export const hasEditReservationCancellationPolicyPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_edit_reservation_cancellation_policy',
		campground: 'campground_edit_reservation_cancellation_policy',
		bookings: 'bookings_edit_reservation_cancellation_policy',
	});

export const hasEditReservationProductContractsPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_edit_reservation_product_contracts',
		campground: 'campground_edit_reservation_product_contracts',
		bookings: 'bookings_edit_reservation_product_contracts',
	});

export const hasEditReservationSpecialRequestsPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_edit_reservation_special_requests',
		campground: 'campground_edit_reservation_special_requests',
		bookings: 'bookings_edit_reservation_special_requests',
	});

export const hasEditReservationFeesPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_edit_reservation_fees',
		campground: 'campground_edit_reservation_fees',
		bookings: 'bookings_edit_reservation_fees',
	});

export const hasEditReservationPricePermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_edit_reservation_price',
		campground: 'campground_edit_reservation_price',
		bookings: 'bookings_edit_reservation_price',
	});

export const hasVoidReservationPaymentPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_void_reservation_payment',
		campground: 'campground_void_reservation_payment',
		bookings: 'bookings_void_reservation_payment',
	});

export const hasRefundReservationPaymentPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_refund_reservation_payment',
		campground: 'campground_refund_reservation_payment',
		bookings: 'bookings_refund_reservation_payment',
	});

export const hasEditSeasonDatesPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_edit_season_dates',
		campground: 'campground_edit_season_dates',
		bookings: 'bookings_edit_season_dates',
	});

export const hasPriceAdjustmentGroupOverrideConstraintsPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_override_price_adjustment_group_constraints',
		campground: 'campground_override_price_adjustment_group_constraints',
		bookings: 'bookings_override_price_adjustment_group_constraints',
	});

export const hasReservationProductChangePermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_change_product',
		campground: 'campground_change_product',
		bookings: 'bookings_change_product',
	});

export const hasReservationEditExternalNotesPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_edit_external_notes',
		campground: 'campground_edit_external_notes',
		bookings: 'bookings_edit_external_notes',
	});

export const hasDeleteReservationItemStatusContractsPermission = userContext =>
	hasPermission(userContext, {
		marina: 'marina_delete_reservation_item_status_contracts',
		campground: 'campground_delete_reservation_item_status_contracts',
		bookings: 'bookings_delete_reservation_item_status_contracts',
	});
