import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';
import {
	addErrorNotification,
	addSuccessNotification,
	convertDateToUTC,
} from '../../../utils/helpers/helper';
import apiCall, { modules } from '../../../utils/helpers/apiCall';
import UserContext from '../../../app/contexts/UserContext';

import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import FormField from '../../reusables/template/FormField';
import DatePicker from '../../reusables/field/DatePicker';
import FormGroup from '../../reusables/layout/FormGroup';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import Toggle from '../../reusables/field/Toggle';
import Selects from '../../reusables/field/Selects';

const GenerateInvoices = ({ onCancel, afterSubmit }) => {
	const userContext = useContext(UserContext);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [isLoading, setIsLoading] = useState(true);

	const [bookingTypes, setBookingTypes] = useState([]);

	const [date, dateOnChange, dateValRes, dateShowVal, setDateShowVal] = useField(
		{},
		'date',
		() => {},
		[required],
		moment()
			.utc(true)
			.hours(23)
			.minutes(59)
			.seconds(59)
			.milliseconds(999)
	);

	const [customers, customersOnChange] = useField({}, 'customers', () => {}, [], []);

	const [selectedBookingTypes, selectedBookingTypesOnChange] = useField(
		{},
		'selectedBookingTypes',
		() => {},
		[],
		[]
	);

	const [marinaReservations, marinaReservationsOnChange] = useField(
		{},
		'marinaReservations',
		() => {},
		[],
		false
	);

	const [campgroundReservations, campgroundReservationsOnChange] = useField(
		{},
		'campgroundReservations',
		() => {},
		[],
		false
	);

	const [powerMeterReadings, powerMeterReadingsOnChange] = useField(
		{},
		'powerMeterReadings',
		() => {},
		[],
		false
	);

	const [bookingReservations, bookingReservationsOnChange] = useField(
		{},
		'bookingReservations',
		() => {},
		[],
		false
	);

	const [includeTransients, includeTransientsOnChange] = useField(
		{},
		'includeTransients',
		() => {},
		[],
		false
	);

	const [emailInvoices, emailInvoicesOnChange] = useField(
		{},
		'emailInvoices',
		() => {},
		[],
		false
	);

	const [emailStatements, emailStatementsOnChange] = useField(
		{},
		'emailStatements',
		() => {},
		[],
		false
	);

	const [overrideInvoiceDate, overrideInvoiceDateOnChange] = useField(
		{},
		'overrideInvoiceDate',
		() => {},
		[],
		null
	);

	const getModuleCaption = moduleName => {
		let caption = '';

		const { settings } = userContext.data.selectedOutlet;

		if (moduleName === modules.MARINA) caption = settings.marinaCaption || 'Marina';
		else if (moduleName === modules.CAMPGROUND)
			caption = settings.campgroundCaption || 'Campground';
		else if (moduleName === modules.BOOKINGS) caption = settings.bookingCaption || 'Booking';

		return caption;
	};

	const onSubmit = () => {
		setDateShowVal();

		if (!dateValRes.isValid) return;

		if (
			!marinaReservations &&
			!powerMeterReadings &&
			!bookingReservations &&
			!campgroundReservations
		) {
			addErrorNotification('Please select invoice type.');
			return;
		}

		setIsSubmitting(true);

		apiCall(
			'POST',
			'generateInvoice',
			() => {
				setIsSubmitting(false);
				addSuccessNotification('Successfully generated');
				afterSubmit(true);
			},
			e => {
				setIsSubmitting(false);
				addErrorNotification(e.toString());
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				date: date.toISOString(),
				customers: customers.map(c => c.id),
				bookingTypes: selectedBookingTypes.map(bt => bt.id),
				marinaReservations,
				campgroundReservations,
				powerMeterReadings,
				bookingReservations,
				includeTransients,
				emailInvoices,
				emailStatements,
				overrideDate: overrideInvoiceDate ? overrideInvoiceDate.toISOString() : null,
			}
		);
	};

	useEffect(() => {
		apiCall(
			'GET',
			'bookingTypes',
			res => {
				setBookingTypes(res);
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			null,
			{ pagination: false }
		);
	}, []);

	return (
		<Portlet
			fluid='fluid'
			className='sdms-bg-transparent sdms-portlet--unelevate sdms-portlet--portletception sdms-mb-0'>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Clipboard-list'>
					Generate Invoices
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<FormGroup isLast>
					<FormField
						name='invoiceThrough'
						label='Invoice Through'
						id='invoiceThrough'
						colMd={12}
						showValidation={dateShowVal}
						valRes={dateValRes}>
						<DatePicker
							id='invoiceThrough'
							type='calendar'
							value={convertDateToUTC(date.toDate())}
							onChange={e =>
								dateOnChange({
									target: {
										value: moment(e.target.value)
											.utc(true)
											.endOf('day'),
									},
								})
							}
							onBlur={setDateShowVal}
							disableClear
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
					<FormField name='customer' label='Customer' id='customer' colLg={12}>
						<AsyncSelect
							options={[]}
							placeholder='Search and select customer'
							value={customers}
							onChange={customersOnChange}
							route='customers'
							field='displayName'
							displayKey='displayName'
							onBlur={() => {}}
							isMulti
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
					<FormField
						name='selectedBookingTypes'
						label='Booking Type'
						id='selectedBookingType'
						colLg={12}>
						<Selects
							options={bookingTypes}
							placeholder='Select a Booking Type'
							value={selectedBookingTypes}
							onChange={selectedBookingTypesOnChange}
							displayKey='name'
							multiple
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
					<FormField
						name='marinaReservations'
						label={`${getModuleCaption(modules.MARINA)} Reservations`}
						id='marinaReservations'
						colLg={12}
						inFormDesign={false}>
						<Toggle
							spaceLess
							value={marinaReservations}
							onChange={marinaReservationsOnChange}
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
					<FormField
						name='campgroundReservations'
						label={`${getModuleCaption(modules.CAMPGROUND)} Reservations`}
						id='campgroundReservations'
						colLg={12}
						inFormDesign={false}>
						<Toggle
							spaceLess
							value={campgroundReservations}
							onChange={campgroundReservationsOnChange}
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
					<FormField
						name='powerMeterReadings'
						label='Power Meter Readings'
						id='powerMeterReadings'
						colLg={12}
						inFormDesign={false}>
						<Toggle
							spaceLess
							value={powerMeterReadings}
							onChange={powerMeterReadingsOnChange}
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
					<FormField
						name='bookingReservations'
						label={`${getModuleCaption(modules.BOOKINGS)} Reservations`}
						id='bookingReservations'
						colLg={6}
						inFormDesign={false}>
						<Toggle
							spaceLess
							value={bookingReservations}
							onChange={bookingReservationsOnChange}
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
					<FormField
						name='includeTransients'
						label='Include Transients'
						id='includeTransients'
						colLg={6}
						inFormDesign={false}>
						<Toggle
							spaceLess
							value={includeTransients}
							onChange={includeTransientsOnChange}
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
					<FormField
						name='emailInvoices'
						label='Email Invoices'
						id='emailInvoices'
						colLg={6}>
						<Toggle
							spaceLess
							value={emailInvoices}
							onChange={emailInvoicesOnChange}
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
					<FormField
						name='emailStatements'
						label='Email Statements'
						id='emailStatements'
						colLg={6}>
						<Toggle
							spaceLess
							value={emailStatements}
							onChange={emailStatementsOnChange}
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
					<FormField
						name='overrideInvoiceDate'
						label='Override Invoice Date'
						id='overrideInvoiceDate'
						colMd={12}>
						<DatePicker
							id='overrideInvoiceDate'
							type='calendar'
							value={
								overrideInvoiceDate
									? convertDateToUTC(overrideInvoiceDate.toDate())
									: null
							}
							onChange={e => {
								overrideInvoiceDateOnChange({
									target: {
										value: e.target.value
											? moment(e.target.value)
													.utc(true)
													.startOf('day')
											: null,
									},
								});
							}}
							disabled={
								!userContext.hasPermission('override_generate_invoice_date') ||
								isLoading ||
								isSubmitting
							}
							minDate={
								userContext.data.user.company.settings.accountingClosingDate
									? moment(
											userContext.data.user.company.settings
												.accountingClosingDate
									  ).toDate()
									: undefined
							}
						/>
					</FormField>
				</FormGroup>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						icon='Error-circle'
						size='sm'
						elevate
						text='Cancel'
						onClick={onCancel}
						disabled={isSubmitting || isLoading}
					/>
				</div>
				<div className='col-auto'>
					<Button
						className={classNames(' sdms-mw-100', {
							'sdms-fading-dots': isSubmitting,
						})}
						label='info'
						icon={
							isSubmitting
								? process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON
								: 'Clipboard-list'
						}
						text={isSubmitting ? 'Generating' : 'Generate'}
						size='sm'
						elevate
						onClick={onSubmit}
						disabled={isSubmitting || isLoading}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};

GenerateInvoices.propTypes = {
	onCancel: PropTypes.func,
	afterSubmit: PropTypes.func,
};

GenerateInvoices.defaultProps = {
	onCancel: () => {},
	afterSubmit: () => {},
};

export default GenerateInvoices;
