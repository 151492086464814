import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import Portlet from '../layout/Portlet';

import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';
import { SELECTED_PRINTER_STORAGE_KEY } from '../../../utils/constants/constants';

import Button from '../element/Button';
import Portal from '../layout/Portal';
import Selects from '../field/Selects';
import FormField from '../template/FormField';
import Textarea from '../field/Textarea';
import Toggle from '../field/Toggle';

const PrinterModal = ({ printers, onSubmit, onClose, isOpen, preview, enableRemember }) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<PrinterModalContent
					printers={printers}
					onClose={onClose}
					onSubmit={onSubmit}
					preview={preview}
					enableRemember={enableRemember}
				/>
			</Popup>
		</Portal>
	);
};
PrinterModal.propTypes = {
	printers: PropTypes.arrayOf(PropTypes.object),
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	isOpen: PropTypes.bool,
	preview: PropTypes.string,
	enableRemember: PropTypes.bool,
};
PrinterModal.defaultProps = {
	printers: [],
	onSubmit: () => {},
	onClose: () => {},
	isOpen: false,
	preview: '',
	enableRemember: false,
};

const PrinterModalContent = ({ printers, onSubmit, onClose, preview, enableRemember }) => {
	const [printer, printerOnChange, printerValRes, printerShowVal, setPrinterShowVal] = useField(
		{},
		'printer',
		() => {},
		[required],
		null
	);

	const [remember, setRemember] = useState(false);

	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Printer'>Printers</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				{preview !== '' && (
					<FormField name='preview' label='Preview' id={0} inFormDesign={false}>
						<Textarea
							className='sdms-font-monospace sdms-preview-textarea'
							value={preview}
							disabled
							onChange={() => {}}
						/>
					</FormField>
				)}
				<FormField
					name='printer'
					id={0}
					valRes={printerValRes}
					showValidation={printerShowVal}
					inFormDesign={false}>
					<Selects
						value={printer}
						options={printers}
						onChange={printerOnChange}
						onBlur={setPrinterShowVal}
						placeholder='Select a Printer'
					/>
				</FormField>
			</Portlet.Body>
			<Portlet.Foot
				tall='sm'
				className='sdms-align-left'
				subClassName='justify-content-between'>
				<div className='col-auto'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
				{enableRemember && (
					<div className='col-auto'>
						<div
							id='sdms-pay--remember'
							className='form-group align-items-center d-flex form-group-last sdms-last-margin--h'>
							<Toggle onChange={() => setRemember(!remember)} value={remember} />
							{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
							<label htmlFor='sdms-pay--remember' className='sdms-cursor--pointer'>
								Remember
							</label>
						</div>
					</div>
				)}
				<div className='col-auto'>
					<Button
						label='brand'
						icon='Printer'
						text='Print'
						size='sm'
						onClick={() => {
							setPrinterShowVal();
							if (printerValRes.isValid) {
								// if remember enabled set printer id to local storage.
								if (remember)
									localStorage.setItem(SELECTED_PRINTER_STORAGE_KEY, printer.id);
								onSubmit(printer);
							}
						}}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
PrinterModalContent.propTypes = {
	printers: PropTypes.arrayOf(PropTypes.object).isRequired,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	preview: PropTypes.string.isRequired,
	enableRemember: PropTypes.bool.isRequired,
};

export default PrinterModal;
