import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import usePages from '../../../utils/hooks/usePages';
import HeaderContext from '../../../app/contexts/HeaderContext';
import { maxLength, required, uniqueFnc } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';

import Input from '../../reusables/field/Input';
import FormGroup from '../../reusables/layout/FormGroup';
import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import FormField from '../../reusables/template/FormField';
import OutletList from './OutletList';
import Button from '../../reusables/element/Button';
import Loading from '../../reusables/template/Loading';

const CompanyForm = ({
	data,
	setIsValid,
	submit,
	isSubmitted,
	isLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
}) => {
	const pages = usePages();

	// OutletList need history.
	const history = useHistory();

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required, uniqueFnc('companies', 'name', data.id)],
		'',
		null,
		setTitle
	);

	const [taxId, taxIdOnChange, taxIdValRes, taxIdShowVal, setTaxIdShoVal] = useField(
		data,
		'taxId',
		onFormChange,
		[maxLength()]
	);

	const [duns, dunsOnChange, dunsValRes, dunsShowVal, setDunsShowVal] = useField(
		data,
		'duns',
		onFormChange,
		[maxLength()]
	);

	const [token, tokenOnChange] = useField(data, 'token', onFormChange);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setTaxIdShoVal();
			setDunsShowVal();
		}
	}, [isSubmitted, setNameShowVal, setTaxIdShoVal, setDunsShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && taxIdValRes.isValid && dunsValRes.isValid);
	}, [nameValRes.isValid, taxIdValRes.isValid, dunsValRes.isValid, setIsValid]);

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.companySettings.default.text,
				path: pages.companySettings.dashboard.path,
			},
			{
				title: pages.companySettings.company.text,
				path: pages.companySettings.company.path,
			},
			{ title: name, isActive: true },
		]);

		headerContext.setPageTitle(name);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<>
			<Portlet className='sdms-form flex-grow-0' hasFrame style={{ minHeight: 189.89 }}>
				<Portlet.Head>
					<Portlet.HeadLabelTitle portletIcon={pages.companySettings.company.icon}>
						Company Information
					</Portlet.HeadLabelTitle>
					<Portlet.HeadToolbar>
						<Portlet.HeadActions>
							<Button
								label={submitButtonAttr.color}
								text={submitButtonAttr.text}
								icon={submitButtonAttr.icon}
								className={classNames({
									'sdms-fading-dots':
										submitButtonAttr.text ===
										process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
								})}
								onClick={submit}
							/>
						</Portlet.HeadActions>
					</Portlet.HeadToolbar>
				</Portlet.Head>
				<Portlet.Body>
					<form>
						<Section last>
							<Section.Body>
								<FormGroup>
									<Loading isLoading={isLoading}>
										<FormField
											name='name'
											label='Name'
											id={data.id}
											valRes={nameValRes}
											showValidation={nameShowVal}
											loadingContainer
											colMd={3}>
											<Input
												type='text'
												placeholder='Company Name (Required)'
												value={name}
												onChange={nameOnChange}
												onBlur={setNameShowVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='taxId'
											label='Tax Id'
											id={data.id}
											colMd={3}
											valRes={taxIdValRes}
											showValidation={taxIdShowVal}>
											<Input
												type='text'
												placeholder='Tax ID'
												value={taxId}
												onChange={taxIdOnChange}
												onBlur={setTaxIdShoVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='duns'
											label='DUNS'
											id={data.id}
											colMd={3}
											valRes={dunsValRes}
											showValidation={dunsShowVal}>
											<Input
												type='text'
												placeholder='DUNS'
												value={duns}
												onChange={dunsOnChange}
												onBlur={setDunsShowVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='token'
											label='Token'
											id={data.id}
											colMd={3}>
											<Input
												type='text'
												placeholder='Token'
												value={token}
												onChange={tokenOnChange}
											/>
										</FormField>
									</Loading>
								</FormGroup>
							</Section.Body>
						</Section>
					</form>
				</Portlet.Body>
			</Portlet>
			<OutletList history={history} />
		</>
	);
};
CompanyForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		taxId: PropTypes.string,
		duns: PropTypes.string,
	}),
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
};
CompanyForm.defaultProps = {
	data: {
		id: 0,
	},
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
};

export default CompanyForm;
