import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
	paymentTypes as _paymentTypes,
	paymentGateWays,
} from '../../../../utils/constants/constants';
import {
	achOption,
	calculateServiceFee,
	filterCustomerPaymentMethods,
	getPaymentProcessorServiceFeeAmount,
} from '../../../../utils/helpers/helper';
import Selects from '../../field/Selects';
import achImage from '../../../../assets/img/ach.png';
import Radio from '../../field/Radio';
import Input from '../../field/Input';
import NumPad from './NumPad';
import Paid from './Paid';
import Toggle from '../../field/Toggle';

const Check = ({
	outlet,
	amount,
	payment,
	setPaymentData,
	isRefund,
	isPayBill,
	refundAmountChangeable,
	max,
	balanceDue,
	paymentProcessor,
	isPaying,
	setTabDisplaySave,
	serviceFeeModule,
	disableServiceFee,
	isServiceFeeEditable,
	customServiceFeeAmount,
	disable,
}) => {
	const [display, setDisplay] = useState({
		default: true,
		amountNumPad: !paymentProcessor,
		checkNumber: true,
		ach: false,
	});

	const [isPayrocACH] = useState(paymentProcessor?.gateway?.value === paymentGateWays.PAYROC_ACH);

	const updateDisplay = current => {
		if (current === 'default')
			setDisplay({
				default: !!paymentProcessor,
				amountNumPad: !paymentProcessor,
				checkNumber: true,
				serviceFeeNumPad: false,
				ach: false,
			});

		if (current === 'amountNumPad')
			setDisplay({
				default: false,
				amountNumPad: true,
				checkNumber: true,
				serviceFeeNumPad: false,
				ach: false,
			});

		if (current === 'ach')
			setDisplay({
				default: false,
				amountNumPad: false,
				checkNumber: false,
				serviceFeeNumPad: false,
				ach: true,
			});

		if (current === 'fee')
			setDisplay({
				default: false,
				amountNumPad: false,
				checkNumber: false,
				serviceFeeNumPad: true,
				ach: false,
			});

		setTabDisplaySave(current === 'ach');
	};

	const savedAchAccounts = useMemo(
		() => filterCustomerPaymentMethods(payment.customer.paymentMethods, paymentProcessor),
		[payment.customer, paymentProcessor]
	);

	const serviceFeeAmount = useMemo(
		() =>
			customServiceFeeAmount !== null
				? customServiceFeeAmount
				: getPaymentProcessorServiceFeeAmount(
						outlet,
						serviceFeeModule,
						paymentProcessor,
						disableServiceFee
				  ),
		[customServiceFeeAmount, outlet, serviceFeeModule, paymentProcessor, disableServiceFee]
	);

	const getAmount = useCallback(() => {
		if (balanceDue) return balanceDue;
		if (isPayBill) return amount.toString();
		return payment.amount > amount || !payment.amount ? amount.toFixed(2) : payment.amount;
	}, [balanceDue, isPayBill, payment, amount]);

	const hasAchEntry = useCallback(() => payment.achAccount || payment.bankName, [payment]);

	useEffect(() => {
		const _amount = getAmount();

		setPaymentData({
			type: _paymentTypes.CHECK,
			amount: _amount,
			checkNumber: '',
			holdForSignature: false,
			serviceFee: 0,
			isChecking: true,
			isCorporate: false,
		});

		setTabDisplaySave(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Paid
				amountInputFocus={display.amountNumPad}
				amount={balanceDue || amount}
				paid={payment.amount}
				onClick={() => {
					if (!isRefund || refundAmountChangeable) {
						if (display.amountNumPad) updateDisplay(hasAchEntry() ? 'ach' : 'default');
						else updateDisplay('amountNumPad');
					}
				}}
				onServiceFeeClick={() => {
					if (isServiceFeeEditable) {
						if (display.amountNumPad) updateDisplay(hasAchEntry() ? 'ach' : 'default');
						else updateDisplay('fee');
					}
				}}
				hasServiceFee={!disableServiceFee}
				serviceFee={payment.serviceFee}
				isPayBill={isPayBill}>
				{paymentProcessor && savedAchAccounts.length > 0 && display.ach && (
					<Selects
						options={savedAchAccounts}
						placeholder='Select a Saved ACH Account'
						displayKey='accountNumber'
						onChange={({ target }) => {
							let _paymentData = {
								achAccount: target.value,
								token: target.value ? target.value.token : null,
								serviceFee: calculateServiceFee(payment.amount, serviceFeeAmount),
								bankName: '',
								accountName: '',
								routingName: '',
								accountNumber: '',
								checkNumber: '',
								accountHolderName: '',
							};

							if (isPayrocACH) {
								_paymentData = {
									..._paymentData,
									accountHolderName: '',
								};
							}
							setPaymentData(_paymentData);
						}}
						value={payment.achAccount || null}
						className='h-100'
						renderOption={achOption}
						optionalShow={option => `${option.bankName} - ${option.accountName}`}
					/>
				)}
				{display.checkNumber && (
					<div className='input-group'>
						<div className='input-group-prepend'>
							<span className='input-group-text'>Check Number:</span>
						</div>
						<Input
							name='checkNumber'
							className='form-control form-control-lg'
							value={payment.checkNumber}
							onChange={e => {
								let _paymentData = {
									checkNumber: e.target.value,
									bankName: '',
									accountName: '',
									routingName: '',
									accountNumber: '',
									achAccount: null,
								};

								if (isPayrocACH) {
									_paymentData = {
										..._paymentData,
										accountHolderName: '',
									};
								}

								setPaymentData(_paymentData);
							}}
							disabled={isPaying}
							onFocus={() => {
								updateDisplay(payment.achAccount ? 'ach' : 'default');
							}}
						/>
					</div>
				)}
			</Paid>
			{display.amountNumPad && (
				<NumPad
					key='amountNumPad'
					value={payment.amount}
					setValue={value =>
						setPaymentData({
							amount: value,
							changeDue: value - amount > 0 ? value - amount : 0,
							serviceFee: payment.checkNumber
								? 0
								: calculateServiceFee(value, serviceFeeAmount),
						})
					}
					initValue={balanceDue || amount}
					max={max}
					disableTenderedButton
					disable={(isRefund && !refundAmountChangeable) || disable}
					onCloseNumpad={() => updateDisplay(payment.achAccount ? 'ach' : 'default')}
				/>
			)}
			{display.serviceFeeNumPad && (
				<NumPad
					key='serviceFeeNumbed'
					value={payment.serviceFee}
					setValue={value => {
						setPaymentData({
							serviceFee: value,
						});
					}}
					initValue={0}
					max={0}
					disableTenderedButton
					disableExact
					disable={isRefund && !refundAmountChangeable}
					onCloseNumpad={() => updateDisplay(payment.achAccount ? 'ach' : 'default')}
				/>
			)}
			{(display.default || display.ach) && paymentProcessor && (
				<div
					className={classNames('row', 'sdms-d-block-mobile', {
						'sdms-ach--authorizing': isPaying,
					})}>
					<div className='col sdms-mb-10-mobile'>
						<div
							className='row align-items-center justify-content-center sdms-cursor--pointer sdms-mt-20'
							role='presentation'
							onClick={() => updateDisplay(display.ach ? 'default' : 'ach')}>
							<div>
								<img src={achImage} alt='ACH' />
							</div>
						</div>
						{display.default && (
							<div className='row align-items-center justify-content-center'>
								<div className='col-auto sdms-font-bold'>
									Click Check above to enter ACH
								</div>
							</div>
						)}
						{display.ach && (
							<div className='row align-items-center justify-content-center sdms-mt-10'>
								<div className='form-group align-items-center d-flex form-group-last sdms-last-margin--h'>
									<Toggle
										id='sdms-pay--hold-for-signature'
										spaceLess
										onChange={e =>
											setPaymentData({
												isCorporate: e.target.value,
											})
										}
										value={payment.isCorporate}
									/>
									{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
									<label
										htmlFor='sdms-pay--hold-for-signature'
										className='sdms-cursor--pointer'>
										Corporate
									</label>
								</div>
							</div>
						)}
					</div>
					{display.ach && (
						<div className='col'>
							<div
								className='form-group row sdms-pay-modal-ach-input'
								style={{ paddingLeft: 15 }}>
								<Radio.Container isInline>
									<Radio
										checked={payment.isChecking}
										id='checking'
										name='checking'
										content='Checking'
										className='sdms-radio--primary'
										onChange={() =>
											setPaymentData({
												isChecking: true,
												checkNumber: '',
												serviceFee: calculateServiceFee(
													payment.amount,
													serviceFeeAmount
												),
											})
										}
										disabled={isPaying}
									/>
									<Radio
										checked={!payment.isChecking}
										id='savings'
										name='savings'
										content='Savings'
										className='sdms-radio--primary'
										onChange={() =>
											setPaymentData({
												isChecking: false,
												checkNumber: '',
												serviceFee: calculateServiceFee(
													payment.amount,
													serviceFeeAmount
												),
											})
										}
										disabled={isPaying}
									/>
								</Radio.Container>
							</div>
							<div className='form-group sdms-pay-modal-ach-input'>
								<Input
									name='bankName'
									className='form-control'
									value={payment.bankName}
									onChange={e =>
										setPaymentData({
											bankName: e.target.value,
											checkNumber: '',
											serviceFee: calculateServiceFee(
												payment.amount,
												serviceFeeAmount
											),
										})
									}
									disabled={isPaying}
									placeholder='Bank Name'
								/>
							</div>
							<div className='form-group sdms-pay-modal-ach-input'>
								<Input
									name='accountName'
									className='form-control'
									value={payment.accountName}
									onChange={e =>
										setPaymentData({
											accountName: e.target.value,
											checkNumber: '',
											serviceFee: calculateServiceFee(
												payment.amount,
												serviceFeeAmount
											),
										})
									}
									disabled={isPaying}
									placeholder='Account Name'
								/>
							</div>
							{paymentProcessor?.gateway?.value === paymentGateWays.PAYROC_ACH && (
								<div className='form-group sdms-pay-modal-ach-input'>
									<Input
										name='accountHolderName'
										className='form-control'
										value={payment.accountHolderName}
										onChange={e =>
											setPaymentData({
												accountHolderName: e.target.value,
												checkNumber: '',
												serviceFee: calculateServiceFee(
													payment.amount,
													serviceFeeAmount
												),
											})
										}
										disabled={isPaying}
										placeholder='Account Holder Name'
									/>
								</div>
							)}
							<div className='form-group sdms-pay-modal-ach-input'>
								<Input
									name='routingNumber'
									className='form-control'
									value={payment.routingNumber}
									onChange={e =>
										setPaymentData({
											routingNumber: e.target.value,
											checkNumber: '',
											serviceFee: calculateServiceFee(
												payment.amount,
												serviceFeeAmount
											),
										})
									}
									disabled={isPaying}
									placeholder='Routing (9 digits)'
									pattern={process.env.REACT_APP_ACH_ROUTING_PATTERN}
								/>
							</div>
							<div className='form-group' style={{ marginBottom: 0 }}>
								<Input
									name='accountNumber'
									className='form-control'
									value={payment.accountNumber}
									onChange={e =>
										setPaymentData({
											accountNumber: e.target.value,
											checkNumber: '',
											serviceFee: calculateServiceFee(
												payment.amount,
												serviceFeeAmount
											),
										})
									}
									disabled={isPaying}
									placeholder='Account Number'
									pattern={process.env.REACT_APP_ACH_ACCOUNT_PATTERN}
								/>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};
Check.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	amount: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	payment: PropTypes.object.isRequired,
	setPaymentData: PropTypes.func.isRequired,
	isRefund: PropTypes.bool.isRequired,
	isPayBill: PropTypes.bool.isRequired,
	refundAmountChangeable: PropTypes.bool.isRequired,
	max: PropTypes.number.isRequired,
	balanceDue: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	paymentProcessor: PropTypes.object,
	isPaying: PropTypes.bool.isRequired,
	setTabDisplaySave: PropTypes.func.isRequired,
	serviceFeeModule: PropTypes.string.isRequired,
	disableServiceFee: PropTypes.bool,
	isServiceFeeEditable: PropTypes.bool,
	customServiceFeeAmount: PropTypes.number,
	disable: PropTypes.bool,
};

Check.defaultProps = {
	paymentProcessor: null,
	disableServiceFee: false,
	isServiceFeeEditable: false,
	customServiceFeeAmount: null,
	disable: false,
};

export default Check;
