import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Card from 'react-credit-cards';
import PropTypes from 'prop-types';

import {
	paymentGateWays,
	paymentTypes as _paymentTypes,
} from '../../../../utils/constants/constants';
import {
	addErrorNotification,
	calculateServiceFee,
	creditCardOption,
	creditCardParser,
	filterCustomerPaymentMethods,
	getPaymentProcessorServiceFeeAmount,
} from '../../../../utils/helpers/helper';
import Selects from '../../field/Selects';
import Scanner from '../../element/Scanner';
import Input from '../../field/Input';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from '../utils';
import Paid from './Paid';
import NumPad from './NumPad';

const CreditCard = ({
	outlet,
	amount,
	payment,
	setPaymentData,
	pay,
	isRefund,
	canEnterCreditCard,
	isPayBill,
	isPaying,
	refundAmountChangeable,
	defaultCreditCard,
	balanceDue,
	isReading,
	setIsReading,
	serviceFeeModule,
	disableServiceFee,
	isServiceFeeEditable,
	setTabDisplaySave,
	paymentProcessor,
	authorizedPayment,
	customServiceFeeAmount,
}) => {
	const [activeContent, setActiveContent] = useState(
		paymentProcessor?.gateway?.value === paymentGateWays.SIDE_TERMINAL_CREDIT_CARD
			? 'creditCard'
			: 'swipe'
	);

	const [focused, setFocused] = useState('name');

	const handleInputFocus = ({ target }) => setFocused(target.name);

	const nameInputRef = useRef();

	const savedCreditCards = useMemo(
		() =>
			filterCustomerPaymentMethods(
				payment.customer.paymentMethods,
				paymentProcessor,
				authorizedPayment
			),
		[paymentProcessor, payment.customer, authorizedPayment]
	);

	const serviceFeeAmount = useMemo(
		() =>
			customServiceFeeAmount !== null
				? customServiceFeeAmount
				: getPaymentProcessorServiceFeeAmount(
						outlet,
						serviceFeeModule,
						paymentProcessor,
						disableServiceFee
				  ),
		[customServiceFeeAmount, outlet, serviceFeeModule, paymentProcessor, disableServiceFee]
	);

	const defaultContent = useMemo(
		() =>
			paymentProcessor?.gateway?.value === paymentGateWays.SIDE_TERMINAL_CREDIT_CARD
				? 'creditCard'
				: 'swipe',
		[paymentProcessor]
	);

	useEffect(() => {
		if (activeContent === 'creditCard') nameInputRef.current.focus();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeContent]);

	useEffect(() => {
		const creditCardAmount = () => {
			if (balanceDue) return balanceDue;
			if (isPayBill) return amount.toString();
			return payment.amount > amount || !payment.amount ? amount.toFixed(2) : payment.amount;
		};

		const _amount = creditCardAmount();

		const paymentData = {
			type: _paymentTypes.CREDIT_CARD,
			amount: _amount,
			changeDue: 0,
			serviceFee: calculateServiceFee(_amount, serviceFeeAmount),
		};

		if (defaultCreditCard !== null) {
			paymentData.creditCard = defaultCreditCard;
			paymentData.token = defaultCreditCard.token;
		}

		setPaymentData(paymentData);

		setTabDisplaySave(true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (authorizedPayment && savedCreditCards) {
			const creditCard = savedCreditCards.find(
				item => authorizedPayment.token === item.token
			);

			if (!creditCard) return;

			setPaymentData({
				type: _paymentTypes.CREDIT_CARD,
				amount: balanceDue || amount,
				serviceFee: calculateServiceFee(authorizedPayment.amount, serviceFeeAmount),
				creditCard,
				swipe: null,
				token: creditCard.token,
				cardNumber: '',
				expiry: '',
				cvc: '',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [savedCreditCards]);

	return (
		<>
			<Paid
				amountInputFocus={activeContent === 'numpad'}
				amount={balanceDue || amount}
				paid={payment.amount}
				onClick={() => {
					if ((!isRefund || refundAmountChangeable) && !isPayBill)
						setActiveContent(activeContent === 'numpad' ? defaultContent : 'numpad');
				}}
				onServiceFeeClick={() => {
					if (isServiceFeeEditable)
						setActiveContent(activeContent === 'fee' ? defaultContent : 'fee');
				}}
				serviceFeeInputFocus={activeContent === 'fee'}
				isPayBill={isPayBill}
				hasServiceFee={!disableServiceFee}
				serviceFee={payment.serviceFee}>
				{savedCreditCards.length > 0 && (
					<Selects
						options={savedCreditCards}
						placeholder='Select a Saved Credit Card'
						displayKey='cardNumber'
						onChange={({ target }) =>
							setPaymentData({
								creditCard: target.value,
								swipe: null,
								token: target.value ? target.value.token : null,
								cardNumber: '',
								expiry: '',
								cvc: '',
							})
						}
						value={payment.creditCard || null}
						className='h-100'
						renderOption={creditCardOption}
					/>
				)}
			</Paid>
			{activeContent === 'swipe' || activeContent === 'creditCard' ? (
				<>
					<div
						className={classNames(
							'row',
							'sdms-d-block-mobile',
							{
								'sdms-cc-swipe--animation': isReading || isPaying,
							},
							{
								'sdms-cc-swipe--reading': isReading,
							},
							{
								'sdms-cc-swipe--authorizing': isPaying,
							}
						)}>
						<div
							className='col sdms-cursor--pointer sdms-mb-10-mobile'
							role='presentation'
							onClick={() => {
								if (
									canEnterCreditCard &&
									paymentProcessor?.gateway?.value !==
										paymentGateWays.SIDE_TERMINAL_CREDIT_CARD
								)
									setActiveContent(
										activeContent === 'swipe' ? 'creditCard' : 'swipe'
									);
							}}>
							<Card
								number={payment.cardNumber || ''}
								name={payment.cardName || ''}
								expiry={payment.expiry || ''}
								cvc={payment.cvc || ''}
								focused={focused}
								callback={() => {}}
							/>
						</div>
						{activeContent === 'swipe' ? (
							<Scanner
								onScan={scan => {
									setIsReading(false);

									const swipe = creditCardParser(scan);

									if (!swipe.isRecognized) {
										addErrorNotification('Cannot recognize card');
										return;
									}

									if (
										isPaying ||
										payment.amount === '' ||
										payment.amount === '0' ||
										parseFloat(payment.amount).toFixed(2) === 0
									)
										addErrorNotification('Amount cannot be zero');
									else pay(swipe);
								}}
								onScanDetect={() => setIsReading(true)}
								onError={() => setIsReading(false)}
							/>
						) : (
							<div className='col'>
								<div className='form-group'>
									<Input
										ref={nameInputRef}
										type='text'
										name='name'
										placeholder='Name'
										className='form-control'
										value={payment.cardName}
										onChange={e =>
											setPaymentData({
												cardName: e.target.value,
												swipe: null,
												token: null,
												creditCard: null,
											})
										}
										onClick={() => setFocused('name')}
										onFocus={handleInputFocus}
										disabled={isReading || isPaying}
										autoComplete={false}
									/>
								</div>
								<div className='form-group'>
									<Input
										type='tel'
										name='number'
										className='form-control'
										// Do not replace the placeholder for automatic filling
										placeholder='Credit Card Front Number'
										value={payment.cardNumber}
										onChange={e =>
											setPaymentData({
												cardNumber: formatCreditCardNumber(e.target.value),
												swipe: null,
												token: null,
												creditCard: null,
											})
										}
										onFocus={handleInputFocus}
										onClick={() => setFocused('number')}
										disabled={isReading || isPaying}
										autoComplete={false}
									/>
								</div>
								<div className='row'>
									<div className='col-6'>
										<Input
											className='form-control'
											type='tel'
											name='validThru'
											placeholder='Valid Thru'
											onChange={e =>
												setPaymentData({
													expiry: formatExpirationDate(e.target.value),
													swipe: null,
													token: null,
													creditCard: null,
												})
											}
											onFocus={handleInputFocus}
											onClick={() => setFocused('expiry')}
											value={payment.expiry}
											disabled={isReading || isPaying}
											autoComplete={false}
										/>
									</div>
									<div className='col-6'>
										<Input
											type='tel'
											name='cvc'
											className='form-control'
											placeholder='CVC'
											onChange={e =>
												setPaymentData({
													cvc: formatCVC(e.target.value),
													swipe: null,
													token: null,
													creditCard: null,
												})
											}
											onFocus={handleInputFocus}
											onClick={() => setFocused('cvc')}
											value={payment.cvc}
											disabled={isReading || isPaying}
											autoComplete={false}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
					{activeContent === 'swipe' && (
						<div className='row sdms-mt-20 align-items-center justify-content-center'>
							{activeContent === 'swipe' && (
								<div className='col-auto sdms-font-bold'>
									{canEnterCreditCard
										? 'Insert Card or click card above to key in'
										: 'Swipe Card'}
								</div>
							)}
						</div>
					)}
				</>
			) : (
				<NumPad
					value={activeContent === 'numpad' ? payment.amount : payment.serviceFee}
					setValue={value => {
						if (activeContent === 'numpad')
							setPaymentData({
								amount: value,
								serviceFee: calculateServiceFee(value, serviceFeeAmount),
							});
						else
							setPaymentData({
								serviceFee: value,
							});
					}}
					initValue={balanceDue || amount}
					max={amount}
					onCloseNumpad={() => setActiveContent('swipe')}
				/>
			)}
		</>
	);
};
CreditCard.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	amount: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	payment: PropTypes.object.isRequired,
	setPaymentData: PropTypes.func.isRequired,
	pay: PropTypes.func.isRequired,
	isRefund: PropTypes.bool.isRequired,
	canEnterCreditCard: PropTypes.bool.isRequired,
	isPayBill: PropTypes.bool.isRequired,
	isPaying: PropTypes.bool.isRequired,
	refundAmountChangeable: PropTypes.bool.isRequired,
	defaultCreditCard: PropTypes.number,
	balanceDue: PropTypes.number.isRequired,
	isReading: PropTypes.bool.isRequired,
	setIsReading: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	serviceFeeModule: PropTypes.string.isRequired,
	disableServiceFee: PropTypes.bool,
	isServiceFeeEditable: PropTypes.bool,
	setTabDisplaySave: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	paymentProcessor: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	authorizedPayment: PropTypes.object,
	customServiceFeeAmount: PropTypes.number,
};
CreditCard.defaultProps = {
	defaultCreditCard: null,
	disableServiceFee: false,
	isServiceFeeEditable: false,
	paymentProcessor: null,
	authorizedPayment: null,
	customServiceFeeAmount: null,
};

export default CreditCard;
