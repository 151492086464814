import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useField from '../../../utils/hooks/useField';
import { required, url } from '../../../utils/helpers/validation';

import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import Button from '../../reusables/element/Button';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Selects from '../../reusables/field/Selects';
import Loading from '../../reusables/template/Loading';
import Input from '../../reusables/field/Input';
import Toggle from '../../reusables/field/Toggle';
import AsyncSelect from '../../reusables/field/AsyncSelect';

const OutletDefaultsForm = ({
	data,
	setIsValid,
	isLoading,
	isSubmitted,
	onFormChange,
	submit,
	submitButtonAttr,
	taxRates,
	acctAccounts,
	emailAddresses,
	paymentTerms,
	accounts,
}) => {
	const accountOptionsRef = useRef([]);

	const parseOption = (option, key) => {
		return option ? { ...option, name: option[key].name } : null;
	};

	const [taxRate, taxRateOnChange, taxRateValRes, taxRateShowVal, setTaxRateShowVal] = useField(
		data,
		'taxRate',
		onFormChange,
		[required],
		null
	);

	const [depositProduct, depositProductOnChange] = useField(
		data,
		'depositProduct',
		onFormChange,
		[],
		{}
	);

	const [
		paymentTerm,
		paymentTermOnChange,
		paymentTermValRes,
		paymentTermShowVal,
		setPaymentTermShowVal,
	] = useField(data, 'paymentTerm', onFormChange, [required], null);

	const [onlineDefaultPaymentTerm, onlineDefaultPaymentTermOnChange] = useField(
		data,
		'onlineDefaultPaymentTerm',
		onFormChange,
		[],
		null
	);

	const [outletFromEmail, outletFromEmailOnChange] = useField(
		data,
		'outletFromEmail',
		onFormChange,
		[],
		null
	);

	const [openCashDrawerOnCheck, openCashDrawerOnCheckOnChange] = useField(
		data,
		'openCashDrawerOnCheck',
		onFormChange,
		[],
		false
	);

	const [automaticallySettle, automaticallySettleOnChange] = useField(
		data,
		'automaticallySettle',
		onFormChange,
		[],
		false
	);

	const [compactInvoicePrint, compactInvoicePrintOnChange] = useField(
		data,
		'compactInvoicePrint',
		onFormChange,
		[],
		false
	);

	const [printDiscountDetail, printDiscountDetailOnChange] = useField(
		data,
		'printDiscountDetail',
		onFormChange,
		[],
		false
	);

	const [hideFutureInvoices, hideFutureInvoicesOnChange] = useField(
		data,
		'hideFutureInvoices',
		onFormChange,
		[],
		false
	);

	const [
		eSignContractUrl,
		eSignContractUrlOnChange,
		eSignContractUrlValRes,
		eSignContractUrlShowVal,
		setEsignContractUrlValRes,
	] = useField(data, 'eSignContractUrl', onFormChange, [url]);

	const [autoPayPos, autoPayPosOnChange] = useField(data, 'autoPayPos', onFormChange);

	const [autoPayBooking, autoPayBookingOnChange] = useField(data, 'autoPayBooking', onFormChange);

	const [autoPayMarina, autoPayMarinaOnChange] = useField(data, 'autoPayMarina', onFormChange);

	const [autoPayCampground, autoPayCampgroundOnChange] = useField(
		data,
		'autoPayCampground',
		onFormChange
	);

	const [enableServiceFeeForAutoPay, enableServiceFeeForAutoPayOnChange] = useField(
		data,
		'enableServiceFeeForAutoPay',
		onFormChange,
		[],
		false
	);

	const [enableServiceFeeForBooking, enableServiceFeeForBookingOnChange] = useField(
		data,
		'enableServiceFeeForBooking',
		onFormChange,
		[],
		false
	);

	const [enableServiceFeeForCrm, enableServiceFeeForCrmOnChange] = useField(
		data,
		'enableServiceFeeForCrm',
		onFormChange,
		[],
		false
	);

	const [enableServiceFeeForPos, enableServiceFeeForPosOnChange] = useField(
		data,
		'enableServiceFeeForPos',
		onFormChange,
		[],
		false
	);

	const [enableServiceFeeForPosInvoices, enableServiceFeeForPosInvoicesOnChange] = useField(
		data,
		'enableServiceFeeForPosInvoices',
		onFormChange,
		[],
		false
	);

	const [enableServiceFeeForSlips, enableServiceFeeForSlipsOnChange] = useField(
		data,
		'enableServiceFeeForSlips',
		onFormChange,
		[],
		false
	);

	const [enableServiceFeeForTransientSlips, enableServiceFeeForTransientSlipsOnChange] = useField(
		data,
		'enableServiceFeeForTransientSlips',
		onFormChange,
		[],
		false
	);

	const [enableServiceFeeForOnlineBookings, enableServiceFeeForOnlineBookingsOnChange] = useField(
		data,
		'enableServiceFeeForOnlineBookings',
		onFormChange,
		[],
		false
	);

	const [enableServiceFeeForOnlinePayments, enableServiceFeeForOnlinePaymentsOnChange] = useField(
		data,
		'enableServiceFeeForOnlinePayments',
		onFormChange,
		[],
		false
	);

	const [
		enableServiceFeeForScheduledPayments,
		enableServiceFeeForScheduledPaymentsOnChange,
	] = useField(data, 'enableServiceFeeForScheduledPayments', onFormChange, [], false);

	const [meterReadServiceCharge, meterReadServiceChargeOnChange] = useField(
		data,
		'meterReadServiceCharge',
		onFormChange,
		[],
		null
	);

	const [powerMeterUnit, powerMeterUnitOnChange] = useField(
		data,
		'powerMeterUnit',
		onFormChange,
		[],
		'kWh'
	);

	const [alwaysChargeMeterReading, alwaysChargeMeterReadingOnChange] = useField(
		data,
		'alwaysChargeMeterReading',
		onFormChange,
		[],
		false
	);

	const [
		defaultCustomer,
		defaultCustomerOnChange,
		defaultCustomerValRes,
		defaultCustomerShowVal,
		setDefaultCustomerShowVal,
	] = useField(data, 'defaultCustomer', onFormChange, [required], {});

	const [emailPaymentOnPos, emailPaymentOnPosOnChange] = useField(
		data,
		'emailPaymentOnPos',
		onFormChange
	);

	const [emailPaymentOnCrm, emailPaymentOnCrmOnChange] = useField(
		data,
		'emailPaymentOnCrm',
		onFormChange
	);

	const [emailPaymentOnAutoPay, emailPaymentOnAutoPayOnChange] = useField(
		data,
		'emailPaymentOnAutoPay',
		onFormChange
	);

	const [emailPaymentOnScheduled, emailPaymentOnScheduledOnChange] = useField(
		data,
		'emailPaymentOnScheduled',
		onFormChange
	);

	const [emailPaymentOnOnlinePortal, emailPaymentOnOnlinePortalOnChange] = useField(
		data,
		'emailPaymentOnOnlinePortal',
		onFormChange
	);

	const [emailPaymentOnOnlineBooking, emailPaymentOnOnlineBookingOnChange] = useField(
		data,
		'emailPaymentOnOnlineBooking',
		onFormChange
	);

	const [serviceFeeText, serviceFeeTextOnChange] = useField(
		data,
		'serviceFeeText',
		onFormChange,
		[],
		null
	);

	const [defaultAccountReceivableAccount, defaultAccountReceivableAccountOnChange] = useField(
		data,
		'defaultAccountReceivableAccount',
		onFormChange,
		[],
		null
	);

	const [defaultBankAccount, defaultBankAccountOnChange] = useField(
		data,
		'defaultBankAccount',
		onFormChange,
		[],
		null
	);

	const [cashBankAccount, cashBankAccountOnChange] = useField(
		data,
		'cashBankAccount',
		onFormChange,
		[],
		null
	);

	const [depositRefundAccount, depositRefundAccountOnChange] = useField(
		data,
		'depositRefundAccount',
		onFormChange,
		[],
		null
	);

	const [accountingPaymentPrefix, accountingPaymentPrefixOnChange] = useField(
		data,
		'accountingPaymentPrefix',
		onFormChange
	);

	const [accountingSalesReceiptPrefix, accountingSalesReceiptPrefixOnChange] = useField(
		data,
		'accountingSalesReceiptPrefix',
		onFormChange
	);

	const [accountingInvoicePrefix, accountingInvoicePrefixOnChange] = useField(
		data,
		'accountingInvoicePrefix',
		onFormChange
	);

	const [accountingCreditMemoPrefix, accountingCreditMemoPrefixOnChange] = useField(
		data,
		'accountingCreditMemoPrefix',
		onFormChange
	);

	const [accountingCheckPrefix, accountingCheckPrefixOnChange] = useField(
		data,
		'accountingCheckPrefix',
		onFormChange
	);

	useEffect(() => {
		if (isSubmitted) {
			setTaxRateShowVal();
			setEsignContractUrlValRes();
			setDefaultCustomerShowVal();
			setPaymentTermShowVal();
		}
	}, [
		isSubmitted,
		setTaxRateShowVal,
		setEsignContractUrlValRes,
		setDefaultCustomerShowVal,
		setPaymentTermShowVal,
	]);

	useEffect(() => {
		setIsValid(
			taxRateValRes.isValid &&
				eSignContractUrlValRes.isValid &&
				defaultCustomerValRes.isValid &&
				paymentTermValRes.isValid
		);
	}, [
		setIsValid,
		taxRateValRes.isValid,
		eSignContractUrlValRes.isValid,
		defaultCustomerValRes.isValid,
		paymentTermValRes.isValid,
	]);

	useEffect(() => {
		accountOptionsRef.current = acctAccounts.map(aa => parseOption(aa, 'sdmsAccount'));
	}, [acctAccounts]);

	return (
		<Portlet className='sdms-form' fluid='fluid' hasFrame>
			<Portlet.Body>
				<Section title='General'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='taxRate'
									label='Tax Rate'
									id={data.id}
									valRes={taxRateValRes}
									showValidation={taxRateShowVal}
									colMd={6}
									colLg={4}>
									<Selects
										options={taxRates}
										placeholder='Tax Rate (Required)'
										value={taxRate}
										onChange={taxRateOnChange}
										onBlur={setTaxRateShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='depositProduct'
									label='Deposit Product'
									id={data.id}
									colMd={6}
									colLg={4}>
									<AsyncSelect
										placeholder='Search and select product'
										value={depositProduct}
										onChange={depositProductOnChange}
										route='products'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='defaultCustomer'
									label='Default Reservation Customer'
									description="For reservations until they're confirmed"
									id={data.id}
									colMd={6}
									colLg={4}
									showValidation={defaultCustomerShowVal}
									valRes={defaultCustomerValRes}>
									<AsyncSelect
										placeholder='Search and select customer'
										value={defaultCustomer}
										onChange={defaultCustomerOnChange}
										route='customers'
										field='displayName'
										displayKey='displayName'
										onBlur={setDefaultCustomerShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='paymentTerm'
									label='Default Payment Term'
									id={data.id}
									valRes={paymentTermValRes}
									showValidation={paymentTermShowVal}
									colMd={6}
									colLg={4}>
									<Selects
										options={paymentTerms}
										placeholder='Payment Term (Required)'
										value={paymentTerm}
										onChange={paymentTermOnChange}
										onBlur={setPaymentTermShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='onlineDefaultPaymentTerm'
									label='Online Default Payment Term'
									id={data.id}
									colMd={6}
									colLg={4}>
									<Selects
										options={paymentTerms}
										placeholder='Select payment term'
										value={onlineDefaultPaymentTerm}
										onChange={onlineDefaultPaymentTermOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='eSignContractUrl'
									label='E-sign Email Contract Url'
									id={data.id}
									valRes={eSignContractUrlValRes}
									showValidation={eSignContractUrlShowVal}
									colMd={6}
									colLg={4}>
									<Input
										type='text'
										placeholder='example.com'
										value={eSignContractUrl}
										onChange={eSignContractUrlOnChange}
										onBlur={setEsignContractUrlValRes}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='outletFromEmail'
									label='Outlet From Email'
									id={data.id}
									colMd={6}
									colLg={4}>
									<Selects
										options={emailAddresses}
										placeholder='Outlet From Email'
										value={outletFromEmail}
										onChange={outletFromEmailOnChange}
										displayKey='displayName'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='compactInvoicePrint'
									label='Compact Invoice Print'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle
										onChange={compactInvoicePrintOnChange}
										value={compactInvoicePrint}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='printDiscountDetail'
									label='Print Invoice Discount Detail'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle
										onChange={printDiscountDetailOnChange}
										value={printDiscountDetail}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='openCashDrawerOnCheck'
									label='Cash Drawer On Check'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle
										onChange={openCashDrawerOnCheckOnChange}
										value={openCashDrawerOnCheck}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='hideFutureInvoices'
									label='Hide Future Invoices'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle
										onChange={hideFutureInvoicesOnChange}
										value={hideFutureInvoices}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='automaticallySettle'
									label='Payment Auto Settle'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle
										onChange={automaticallySettleOnChange}
										value={automaticallySettle}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Payment Receipts'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='emailPaymentOnPos'
									label='Point Of Sale'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle
										value={emailPaymentOnPos}
										onChange={emailPaymentOnPosOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='emailPaymentOnCrm'
									label='CRM'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle
										value={emailPaymentOnCrm}
										onChange={emailPaymentOnCrmOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='emailPaymentOnAutoPay'
									label='Auto Pay'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle
										value={emailPaymentOnAutoPay}
										onChange={emailPaymentOnAutoPayOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='emailPaymentOnScheduled'
									label='Scheduled'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle
										value={emailPaymentOnScheduled}
										onChange={emailPaymentOnScheduledOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='emailPaymentOnOnlinePortal'
									label='Online Portal'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle
										value={emailPaymentOnOnlinePortal}
										onChange={emailPaymentOnOnlinePortalOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='emailPaymentOnOnlineBooking'
									label='Online Bookings'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle
										value={emailPaymentOnOnlineBooking}
										onChange={emailPaymentOnOnlineBookingOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Auto Pay'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoPayPos'
									label='Auto Pay Pos'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle value={autoPayPos} onChange={autoPayPosOnChange} />
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoPayBooking'
									label='Auto Pay Booking'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle
										value={autoPayBooking}
										onChange={autoPayBookingOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoPayMarina'
									label='Auto Pay Marina'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle
										value={autoPayMarina}
										onChange={autoPayMarinaOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoPayCampground'
									label='Auto Pay Campground'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle
										value={autoPayCampground}
										onChange={autoPayCampgroundOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Apply Service Fees'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableServiceFeeForAutoPay'
									label='Auto Pay'
									id={data.id}
									colMd={2}
									colLg={1}>
									<Toggle
										onChange={enableServiceFeeForAutoPayOnChange}
										value={enableServiceFeeForAutoPay}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableServiceFeeForBooking'
									label='Booking'
									id={data.id}
									colMd={2}
									colLg={1}>
									<Toggle
										onChange={enableServiceFeeForBookingOnChange}
										value={enableServiceFeeForBooking}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableServiceFeeForCrm'
									label='CRM'
									id={data.id}
									colMd={2}
									colLg={1}>
									<Toggle
										onChange={enableServiceFeeForCrmOnChange}
										value={enableServiceFeeForCrm}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableServiceFeeForPos'
									label='POS'
									id={data.id}
									colMd={2}
									colLg={1}>
									<Toggle
										onChange={enableServiceFeeForPosOnChange}
										value={enableServiceFeeForPos}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableServiceFeeForPosInvoices'
									label='POS Invoices'
									id={data.id}
									colMd={2}
									colLg={1}>
									<Toggle
										onChange={enableServiceFeeForPosInvoicesOnChange}
										value={enableServiceFeeForPosInvoices}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableServiceFeeForSlips'
									label='Slips'
									id={data.id}
									colMd={2}
									colLg={1}>
									<Toggle
										onChange={enableServiceFeeForSlipsOnChange}
										value={enableServiceFeeForSlips}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableServiceFeeForTransientSlips'
									label='Transient Slips'
									id={data.id}
									colMd={2}
									colLg={1}>
									<Toggle
										onChange={enableServiceFeeForTransientSlipsOnChange}
										value={enableServiceFeeForTransientSlips}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableServiceFeeForOnlineBookings'
									label='Online Bookings'
									id={data.id}
									colMd={2}
									colLg={1}>
									<Toggle
										onChange={enableServiceFeeForOnlineBookingsOnChange}
										value={enableServiceFeeForOnlineBookings}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableServiceFeeForOnlinePayments'
									label='Online Payments'
									id={data.id}
									colMd={2}
									colLg={1}>
									<Toggle
										onChange={enableServiceFeeForOnlinePaymentsOnChange}
										value={enableServiceFeeForOnlinePayments}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableServiceFeeForScheduledPayments'
									label='Scheduled Payments'
									id={data.id}
									colMd={4}
									colLg={2}>
									<Toggle
										onChange={enableServiceFeeForScheduledPaymentsOnChange}
										value={enableServiceFeeForScheduledPayments}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Meter Read'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='meterReadServiceCharge'
									label='Meter Read Service Charge'
									id={data.id}
									colMd={6}
									colLg={4}>
									<AsyncSelect
										options={
											data.meterReadServiceCharge
												? [data.meterReadServiceCharge]
												: []
										}
										placeholder='Search and select product'
										value={meterReadServiceCharge}
										onChange={meterReadServiceChargeOnChange}
										route='products'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='powerMeterUnit'
									label='Power Meter Unit'
									id={data.id}
									colMd={6}
									colLg={4}>
									<Input
										onChange={powerMeterUnitOnChange}
										value={powerMeterUnit}
										placeholder='Power Meter Unit'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='alwaysChargeMeterReading'
									label='Always Charge Meter Reading'
									id={data.id}
									colMd={3}
									colLg={4}>
									<Toggle
										onChange={alwaysChargeMeterReadingOnChange}
										value={alwaysChargeMeterReading}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Accounting'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='defaultAccountReceivableAccount'
									label='Default Account Receivable Account'
									id={data.id}
									colMd={4}>
									<Selects
										options={accounts.filter(
											a => a.accountType.value === 'AccountsReceivable'
										)}
										placeholder='Select an account'
										value={defaultAccountReceivableAccount}
										onChange={defaultAccountReceivableAccountOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='defaultBankAccount'
									label='Default Bank Account'
									id={data.id}
									colMd={4}>
									<Selects
										options={accounts}
										placeholder='Select an account'
										value={defaultBankAccount}
										onChange={defaultBankAccountOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='cashBankAccount'
									label='Cash Bank Account'
									id={data.id}
									colMd={4}>
									<Selects
										options={accounts}
										placeholder='Select an account'
										value={cashBankAccount}
										onChange={cashBankAccountOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='depositRefundAccount'
									label='Deposit Refund Account'
									id={data.id}
									colMd={4}>
									<Selects
										options={accounts}
										placeholder='Select an account'
										value={depositRefundAccount}
										onChange={depositRefundAccountOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='accountingPaymentPrefix'
									label='Payment Prefix'
									id={data.id}
									colMd={4}>
									<Input
										type='text'
										placeholder='Payment Prefix'
										value={accountingPaymentPrefix}
										onChange={accountingPaymentPrefixOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='accountingSalesReceiptPrefix'
									label='Sales Receipt Prefix'
									id={data.id}
									colMd={4}>
									<Input
										type='text'
										placeholder='Sales Receipt Prefix'
										value={accountingSalesReceiptPrefix}
										onChange={accountingSalesReceiptPrefixOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='accountingInvoicePrefix'
									label='Invoice Prefix'
									id={data.id}
									colMd={4}>
									<Input
										type='text'
										placeholder='Invoice Prefix'
										value={accountingInvoicePrefix}
										onChange={accountingInvoicePrefixOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='accountingCreditMemoPrefix'
									label='Credit Memo Prefix'
									id={data.id}
									colMd={4}>
									<Input
										type='text'
										placeholder='Credit Memo Prefix'
										value={accountingCreditMemoPrefix}
										onChange={accountingCreditMemoPrefixOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='accountingCheckPrefix'
									label='Check(Refund) Prefix'
									id={data.id}
									colMd={4}>
									<Input
										type='text'
										placeholder='Check(Refund) Prefix'
										value={accountingCheckPrefix}
										onChange={accountingCheckPrefixOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Service Fee Settings'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='serviceFeeText'
									label='Service Fee Text'
									description='Overwrites default "Service Fee" text'
									id={data.id}
									colMd={6}
									colLg={4}>
									<Input
										type='text'
										placeholder='Service Fee'
										value={serviceFeeText}
										onChange={serviceFeeTextOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};

OutletDefaultsForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		taxRate: PropTypes.object,
		depositProduct: PropTypes.object,
		autoPayPos: PropTypes.bool,
		autoPayBooking: PropTypes.bool,
		autoPayMarina: PropTypes.bool,
		autoPayCampground: PropTypes.bool,
		defaultCustomer: PropTypes.object,
		meterReadServiceCharge: PropTypes.object,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submit: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	taxRates: PropTypes.arrayOf(PropTypes.object),
	acctAccounts: PropTypes.arrayOf(PropTypes.object),
	emailAddresses: PropTypes.arrayOf(PropTypes.object),
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	accounts: PropTypes.arrayOf(PropTypes.object),
};
OutletDefaultsForm.defaultProps = {
	data: {
		id: 0,
		taxRate: null,
		depositProduct: {},
		autoPayPos: false,
		autoPayBooking: false,
		autoPayMarina: false,
		autoPayCampground: false,
		defaultCustomer: null,
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	submit: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	taxRates: [],
	acctAccounts: [],
	emailAddresses: [],
	paymentTerms: [],
	accounts: [],
};

export default OutletDefaultsForm;
