import React, { useState, useContext, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from './contexts/UserContext';
import Container from '../components/reusables/layout/Container';
import HeaderMobile from '../components/HeaderMobile';
import Aside from '../components/Aside';
import Header from '../components/Header';
import Content from '../components/Content';
import ScrollTop from '../components/ScrollTop';
import TerminalUnLock from '../components/modules/terminal/TerminalUnlock';
import Login from '../components/Login';
import Terminal from '../components/modules/terminal/Terminal';
import SVGIcon from '../components/reusables/element/SVGIcon';
import ThemeContext from './contexts/ThemeContext';
import Head from '../components/reusables/layout/Head';
import ContentInner from '../components/reusables/template/ContentInner';
import Button from '../components/reusables/element/Button';
import Nav from '../components/Nav';
import Loading from '../components/reusables/template/Loading';
import Portlet from '../components/reusables/layout/Portlet';
import { setTheme } from '../utils/helpers/helper';
import useWebWorker from '../utils/hooks/useWebWorker';
import versionWorker from './versionWorker';
import useModal from '../utils/hooks/useModal';
import VersionCountDownModal from '../components/reusables/modals/VersionCountDownModal';
import { USER_STORAGE_KEY } from '../utils/constants/constants';

const modals = { VERSION: 'version' };

const userCookie = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));

const App = () => {
	const location = useLocation();
	const themeContext = useContext(ThemeContext);
	const userContext = useContext(UserContext);
	const [modal, openModal, closeModal] = useModal();
	const [overlay, setOverlay] = useState({
		aside: false,
		header: false,
		topBar: false,
		order: false,
	});

	const [versionWorkerStart, , versionWorkerTerminate] = useWebWorker(versionWorker, 1800);

	const onVersionCheck = ({ data }) => {
		if (
			data.version &&
			data.version.localeCompare(process.env.REACT_APP_VERSION, undefined, {
				numeric: true,
				sensitivity: 'base',
			}) === 1
		) {
			openModal({ open: modals.VERSION });
		}
	};

	// For mobile menu
	useEffect(() => {
		setOverlay({
			aside: false,
			header: false,
			topBar: false,
			order: false,
		});
		return () => {};
	}, [location]);

	useEffect(() => {
		if (themeContext.data.brightness !== 100 || themeContext.data.contrast !== 100) {
			document.documentElement.style.filter = `brightness(${themeContext.data.brightness}%) contrast(${themeContext.data.contrast}%)`;
		}
	}, [themeContext, location]);

	useEffect(() => {
		versionWorkerStart(onVersionCheck, 0, true);

		return () => versionWorkerTerminate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => setTheme(themeContext.data.theme), [themeContext, location]);

	const asideOverlayClose = () => {
		setOverlay({
			aside: false,
			header: false,
			topBar: false,
			order: false,
		});
	};

	const VERSION_COUNTER = (
		<VersionCountDownModal
			isOpen={modal.open === modals.VERSION}
			counter={30}
			onUpdate={() => {
				closeModal();
				versionWorkerTerminate();
				window.location.reload();
			}}
			onPostPone={() => {
				versionWorkerStart(onVersionCheck);
				closeModal();
			}}
		/>
	);

	if (
		userContext.data === null &&
		userCookie !== null &&
		!/\/terminal\/(\d+)/.test(window.location.pathname)
	)
		return (
			<>
				<HeaderMobile />
				<Container>
					<Container.Page>
						<div
							className='sdms-aside-close'
							id='sdms_aside_close_btn'
							onClick={asideOverlayClose}
							aria-hidden='true'>
							<SVGIcon name='Close' size={18} />
						</div>
						<Aside />
						{overlay && (
							<div
								className='sdms-aside-overlay'
								onClick={asideOverlayClose}
								aria-hidden='true'
							/>
						)}
						<Container.Wrapper>
							<Head>
								<Head.MenuWrapper>
									<Head.Menu>
										<Nav>
											<Nav.Item text='loading' path='#' isLoading />
											<Nav.Item text='loading' path='#' isLoading />
											<Nav.Item text='loading' path='#' isLoading />
										</Nav>
									</Head.Menu>
								</Head.MenuWrapper>
								<div className='sdms-header__topbar'>
									<div className='sdms-header__topbar-item align-items-center sdms-mr-15'>
										<Loading isLoading type='button'>
											<Button
												icon='Building'
												size='sm'
												design='primary'
												outline>
												Loading
											</Button>
										</Loading>
									</div>
									<div className='sdms-header__topbar-item align-items-center sdms-mr-15'>
										<Loading isLoading type='button'>
											<Button
												icon='Location-arrow'
												size='sm'
												design='primary'
												outline>
												Loading
											</Button>
										</Loading>
									</div>
									<div className='sdms-header__topbar-item align-items-center sdms-mr-15'>
										<Loading isLoading type='button'>
											<Button
												icon='Keyboard'
												size='sm'
												design='primary'
												outline>
												Loading
											</Button>
										</Loading>
									</div>
									<div className='sdms-header__topbar-item sdms-header__topbar-item--user'>
										<div
											className='sdms-header__topbar-wrapper'
											data-toggle='dropdown'
											aria-expanded='true'>
											<div className='sdms-header__topbar-user'>
												<Loading isLoading type='span' width={30}>
													<span className='sdms-header__topbar-welcome sdms-hidden-mobile'>
														Hi,
													</span>
												</Loading>
												<Loading isLoading type='span' width={50}>
													<span className='sdms-header__topbar-username sdms-hidden-mobile sdms-mr-15'>
														User
													</span>
												</Loading>
												<span className='sdms-badge sdms-badge--username sdms-badge--unified-info sdms-badge--lg sdms-badge--rounded sdms-badge--bold'>
													?
												</span>
											</div>
										</div>
									</div>
								</div>
							</Head>
							<Container.Content>
								<ContentInner.SubHeader>
									<ContentInner.SubHeaderItem>
										<Loading isLoading width={150} type='title'>
											<ContentInner.SubHeaderTitle title='Loading' />
										</Loading>
										<Loading isLoading type='breadcrumb' />
									</ContentInner.SubHeaderItem>
									<ContentInner.SubHeaderItem type='toolbar'>
										<Loading isLoading type='button'>
											<Button label='brand' text='Loading' icon='Plus' />
										</Loading>
									</ContentInner.SubHeaderItem>
								</ContentInner.SubHeader>
								<ContentInner.Container title='Loading' hasFrame>
									<Portlet.Placeholder />
								</ContentInner.Container>
							</Container.Content>
						</Container.Wrapper>
					</Container.Page>
				</Container>
				<ScrollTop />
			</>
		);

	if (/\/terminal\/(\d+)/.test(window.location.pathname)) {
		const registerId = parseInt(
			window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
			10
		);
		if (userContext.data === null && userCookie !== null) return null;
		if (
			userContext.data &&
			userContext.data.access.includes('terminal') &&
			userContext.data.user.registers.findIndex(r => r.id === registerId) > -1
		)
			return (
				<>
					<Terminal id={registerId} />
					{VERSION_COUNTER}
				</>
			);

		return (
			<>
				<TerminalUnLock />
				{VERSION_COUNTER}
			</>
		);
	}

	if (userContext.data && userContext.data.access.includes('admin')) {
		return (
			<>
				<HeaderMobile isAside overlay={overlay} setOverlay={setOverlay} />
				<Container>
					<Container.Page>
						<div
							className='sdms-aside-close'
							id='sdms_aside_close_btn'
							onClick={asideOverlayClose}
							aria-hidden='true'>
							<SVGIcon name='Close' size={18} />
						</div>
						<Aside overlay={overlay} />
						{overlay && (
							<div
								className='sdms-aside-overlay'
								onClick={asideOverlayClose}
								aria-hidden='true'
							/>
						)}

						<Container.Wrapper>
							<Header />
							<Content />
							{/* <Footer></Footer> */}
						</Container.Wrapper>
					</Container.Page>
				</Container>
				<ScrollTop />
				{VERSION_COUNTER}
			</>
		);
	}

	return (
		<>
			<Login />
			{VERSION_COUNTER}
		</>
	);
};

export default App;
