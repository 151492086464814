import { useState } from 'react';
import { paymentTypes } from '../constants/constants';
import apiCall from '../helpers/apiCall';
import { cryptoEncrypt } from '../helpers/crypto';

const paymentGateWays = {
	BLUEPAY: 'bluepay',
	SHIFT4: 'shift4',
	PAYA: 'paya',
	PAYROC_CREDIT_CARD: 'payroc_credit_card',
	PAYROC_ACH: 'payroc_ach',
	SIDE_TERMINAL_CREDIT_CARD: 'side_terminal_credit_card',
};

const i4ShiftCardTypes = {
	VS: 'VISA',
	MC: 'MC',
	AX: 'AMEX',
	NS: 'DISC',
	JC: 'JCB',
};

export const customerPaymentForms = {
	PORTAL_PAYMENTS: 'Portal Payments',
	RESERVATION_DEPOSITS: 'Reservation Deposits',
};

export const parsePaymentData = (type, paymentData) => {
	if (type === paymentTypes.CREDIT_CARD)
		return {
			number: paymentData.cardNumber,
			expiry: paymentData.expiry,
			expMonth: paymentData.expiry ? paymentData.expiry.substring(0, 2) : '#',
			expYear: paymentData.expiry ? paymentData.expiry.substring(3, 5) : '#',
			cvc: paymentData.cvc,
			creditCard: paymentData.creditCard,
			swipe: paymentData.swipe,
			token: paymentData.token,
			makeDefault: paymentData.makeDefault,
		};

	return paymentData;
};

export const getPaymentProcessor = (outlet, type, customerForm = null) => {
	if (!outlet || !outlet.paymentProcessors) return null;

	let processors = outlet.paymentProcessors.filter(
		pp => pp.enabled && pp.paymentType.value === type
	);

	if (customerForm)
		processors = processors.filter(pp => pp.customerPaymentForms.some(cpf => cpf.value));

	return processors.length ? processors[0] : null;
};

export const getPaymentProcessorPhrase = paymentProcessor =>
	`${paymentProcessor.name}${paymentProcessor.id}${paymentProcessor.gateway.value}`;

const getBit = (number, bitPosition) => {
	// eslint-disable-next-line no-bitwise
	return (number & (1 << bitPosition)) === 0 ? 0 : 1;
};

const littleEndianHexStringToDecimal = string => {
	if (!string) return undefined;
	const len = string.length;
	let bigEndianHexString = '0x';
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < len / 2; i++)
		bigEndianHexString += string.substring(len - (i + 1) * 2, len - i * 2);
	// eslint-disable-next-line radix
	return parseInt(bigEndianHexString);
};

const hexToAscii = str1 => {
	const hex = str1.toString();
	let str = '';
	for (let n = 0; n < hex.length; n += 2) {
		str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
	}
	return str;
};

const KNOWN_TAGS = {
	'42': true,
	'50': true,
	'52': true,
	'56': true,
	'57': true,
	'61': true,
	'62': true,
	'70': true,
	'71': true,
	'72': true,
	'73': true,
	'77': true,
	'80': true,
	'81': true,
	'82': true,
	'83': true,
	'84': true,
	'86': true,
	'87': true,
	'88': true,
	'89': true,
	'90': true,
	'91': true,
	'92': true,
	'93': true,
	'94': true,
	'95': true,
	'97': true,
	'98': true,
	'99': true,
	'4F': true,
	'5A': true,
	'5D': true,
	'5F20': true,
	'5F24': true,
	'5F25': true,
	'5F28': true,
	'5F2A': true,
	'5F2D': true,
	'5F30': true,
	'5F34': true,
	'5F36': true,
	'5F3C': true,
	'5F3D': true,
	'5F50': true,
	'5F53': true,
	'5F54': true,
	'5F55': true,
	'5F56': true,
	'5F57': true,
	'6F': true,
	'8A': true,
	'8C': true,
	'8D': true,
	'8E': true,
	'8F': true,
	'9A': true,
	'9B': true,
	'9C': true,
	'9D': true,
	'9F01': true,
	'9F02': true,
	'9F03': true,
	'9F04': true,
	'9F05': true,
	'9F06': true,
	'9F07': true,
	'9F08': true,
	'9F09': true,
	'9F0B': true,
	'9F0D': true,
	'9F0E': true,
	'9F0F': true,
	'9F10': true,
	'9F11': true,
	'9F12': true,
	'9F13': true,
	'9F14': true,
	'9F15': true,
	'9F16': true,
	'9F17': true,
	'9F18': true,
	'9F19': true,
	'9F1A': true,
	'9F1B': true,
	'9F1C': true,
	'9F1D': true,
	'9F1E': true,
	'9F1F': true,
	'9F20': true,
	'9F21': true,
	'9F22': true,
	'9F23': true,
	'9F25': true,
	'9F26': true,
	'9F27': true,
	'9F28': true,
	'9F29': true,
	'9F2A': true,
	'9F2B': true,
	'9F2D': true,
	'9F2E': true,
	'9F2F': true,
	'9F32': true,
	'9F33': true,
	'9F34': true,
	'9F35': true,
	'9F36': true,
	'9F37': true,
	'9F38': true,
	'9F39': true,
	'9F3A': true,
	'9F3B': true,
	'9F3C': true,
	'9F3D': true,
	'9F40': true,
	'9F41': true,
	'9F42': true,
	'9F43': true,
	'9F44': true,
	'9F45': true,
	'9F46': true,
	'9F47': true,
	'9F48': true,
	'9F49': true,
	'9F4A': true,
	'9F4B': true,
	'9F4C': true,
	'9F4D': true,
	'9F4E': true,
	'9F4F': true,
	'9F50': true,
	'9F51': true,
	'9F52': true,
	'9F53': true,
	'9F54': true,
	'9F55': true,
	'9F56': true,
	'9F57': true,
	'9F58': true,
	'9F59': true,
	'9F5A': true,
	'9F5B': true,
	'9F5C': true,
	'9F5D': true,
	'9F5E': true,
	'9F5F': true,
	'9F60': true,
	'9F61': true,
	'9F62': true,
	'9F63': true,
	'9F64': true,
	'9F65': true,
	'9F66': true,
	'9F67': true,
	'9F68': true,
	'9F69': true,
	'9F6A': true,
	'9F6B': true,
	'9F6C': true,
	'9F6D': true,
	'9F6E': true,
	'9F6F': true,
	'9F70': true,
	'9F71': true,
	'9F72': true,
	'9F73': true,
	'9F74': true,
	'9F75': true,
	'9F76': true,
	'9F77': true,
	'9F78': true,
	'9F79': true,
	'9F7A': true,
	'9F7B': true,
	'9F7C': true,
	'9F7D': true,
	'9F7E': true,
	'9F7F': true,
	A5: true,
	BF0C: true,
	BF50: true,
	BF60: true,
	C3: true,
	C4: true,
	C5: true,
	C6: true,
	C7: true,
	C8: true,
	C9: true,
	CA: true,
	CB: true,
	CD: true,
	CE: true,
	CF: true,
	D1: true,
	D2: true,
	D3: true,
	D5: true,
	D6: true,
	D7: true,
	D8: true,
	D9: true,
	DA: true,
	DB: true,
	DC: true,
	DD: true,
	DF01: true,
	DF02: true,
	DF03: true,
	DF04: true,
	DF05: true,
	DF06: true,
	DF07: true,
	DF08: true,
	DF09: true,
	DF0B: true,
	DF0C: true,
	DF0D: true,
	DF0E: true,
	DF0F: true,
	DF10: true,
	DF11: true,
	DF12: true,
	DF13: true,
	DF14: true,
	DF15: true,
	DF16: true,
	DF17: true,
	DF18: true,
	DF19: true,
	DF1F: true,
	DF20: true,
	DF21: true,
	DF22: true,
	DF23: true,
	DF24: true,
	DF25: true,
	DF26: true,
	DF27: true,
	DF28: true,
	DF29: true,
	DF2A: true,
	DF2B: true,
	DF2C: true,
	DF30: true,
	DF31: true,
	DF32: true,
	DF33: true,
	DF40: true,
	DF41: true,
	DF42: true,
	DF43: true,
	DF44: true,
	DF45: true,
	DF46: true,
	DF47: true,
	DF48: true,
	DF49: true,
	DF4A: true,
	DF4B: true,
	DF4C: true,
	DF4D: true,
	DF4E: true,
	DF4F: true,
	DF50: true,
	DF51: true,
	DF52: true,
	DF53: true,
	DF54: true,
	DF55: true,
	DF56: true,
	DF57: true,
	DF58: true,
	DF5A: true,
	DF5B: true,
	DF5C: true,
	DF5D: true,
	DF5E: true,
	DF5F: true,
	DF60: true,
	DF61: true,
	DF62: true,
	DF63: true,
	DF64: true,
	DF65: true,
	DF66: true,
	DF68: true,
	DF69: true,
	DF6A: true,
	DF6B: true,
	DF6C: true,
	DF6D: true,
	DF6E: true,
	DF6F: true,
	DF70: true,
	DF71: true,
	DF72: true,
	DF73: true,
	DF74: true,
	DF75: true,
	DF76: true,
	DF77: true,
	DF78: true,
	DF79: true,
	DF7A: true,
	DF7B: true,
	DF7C: true,
	DF7D: true,
	DF7F: true,
	DF8101: true,
	DF8102: true,
	DF8104: true,
	DF8105: true,
	DF8106: true,
	DF8107: true,
	DF8108: true,
	DF8109: true,
	DF810A: true,
	DF810B: true,
	DF810C: true,
	DF810D: true,
	DF810E: true,
	DF810F: true,
	DF8110: true,
	DF8111: true,
	DF8112: true,
	DF8113: true,
	DF8114: true,
	DF8115: true,
	DF8116: true,
	DF8117: true,
	DF8118: true,
	DF8119: true,
	DF811A: true,
	DF811B: true,
	DF811C: true,
	DF811D: true,
	DF811E: true,
	DF811F: true,
	DF8120: true,
	DF8121: true,
	DF8122: true,
	DF8123: true,
	DF8124: true,
	DF8125: true,
	DF8126: true,
	DF8127: true,
	DF8128: true,
	DF8129: true,
	DF812A: true,
	DF812B: true,
	DF812C: true,
	DF812D: true,
	DF8130: true,
	DF8131: true,
	DFDE04: true,
	DFEE12: true,
	DFEE15: true,
	DFEE16: true,
	DFEE17: true,
	DFEE18: true,
	DFEE19: true,
	DFEE1A: true,
	DFEE1B: true,
	DFEE1E: true,
	DFEE1F: true,
	DFEE20: true,
	DFEE21: true,
	DFEE22: true,
	DFEE23: true,
	DFEE24: true,
	DFEE25: true,
	DFEE26: true,
	DFEE27: true,
	DFEF1E: true,
	DFEF1F: true,
	DFEF20: true,
	DFEF21: true,
	DFEF22: true,
	DFEF23: true,
	DFEF24: true,
	DFEF25: true,
	DFEF26: true,
	DFEF27: true,
	DFEF28: true,
	DFEF2C: true,
	DFEF2D: true,
	DFEF2E: true,
	DFEF2F: true,
	DFEF30: true,
	DFEF31: true,
	DFEF32: true,
	DFEF33: true,
	DFEF34: true,
	DFEF35: true,
	DFEF36: true,
	DFEF37: true,
	DFEF38: true,
	DFEF39: true,
	DFEF3A: true,
	DFEF3B: true,
	DFEF40: true,
	DFEF41: true,
	DFEF42: true,
	DFEF43: true,
	DFEF4B: true,
	DFEF4C: true,
	DFEF4D: true,
	DFEF59: true,
	DFEF5A: true,
	DFEF5B: true,
	DFEF5C: true,
	DFEF5D: true,
	DFEF5E: true,
	DFEF5F: true,
	DFEF60: true,
	DFEF61: true,
	DFEF62: true,
	FF60: true,
	FF62: true,
	FF63: true,
	FF69: true,
	FF70: true,
	FF71: true,
	FF72: true,
	FF73: true,
	FF74: true,
	FF75: true,
	FF76: true,
	FF77: true,
	FF78: true,
	FF79: true,
	FF7A: true,
	FF7B: true,
	FF7C: true,
	FF7D: true,
	FF8101: true,
	FF8102: true,
	FF8103: true,
	FF8104: true,
	FF8105: true,
	FF8106: true,
	FFE0: true,
	FFE1: true,
	FFE2: true,
	FFE3: true,
	FFE4: true,
	FFE5: true,
	FFE6: true,
	FFE7: true,
	FFE8: true,
	FFE9: true,
	FFEA: true,
	FFEE01: true,
	FFEE02: true,
	FFEE03: true,
	FFEE04: true,
	FFEE05: true,
	FFEE06: true,
	FFEE07: true,
	FFEE08: true,
	FFEE0A: true,
	FFEE0B: true,
	FFEE0C: true,
	FFEE10: true,
	FFEE11: true,
	FFEE12: true,
	FFEE13: true,
	FFEE14: true,
	FFEE1C: true,
	FFEE1D: true,
	FFF0: true,
	FFF1: true,
	FFF2: true,
	FFF3: true,
	FFF4: true,
	FFF5: true,
	FFF6: true,
	FFF7: true,
	FFF8: true,
	FFF9: true,
	FFFA: true,
	FFFB: true,
	FFFC: true,
	FFFD: true,
	FFFE: true,
	FFFF: true,
};

export const parseTags = data => {
	const TLV = {}; // results go here

	// inner method
	function readData(amt, tag) {
		data = data.slice(amt); // get past tag bytes

		// find the Length (the L in TLV)
		let length = data.slice(0, 2); // read two nibbles
		data = data.slice(2); // get past those nibbles
		length = 1 * `0x${length}`; // cast to Number

		// eslint-disable-next-line no-bitwise
		if (length & 0x80) {
			// eslint-disable-next-line no-bitwise
			let lengthOfLength = length & 0x1f;
			lengthOfLength *= 2; // convert to nibbles!
			length = data.slice(0, lengthOfLength);
			data = data.slice(lengthOfLength); // get past actual length
			length = 1 * `0x${length}`; // cast to Number
		}

		length *= 2; // number of nibbles of data to read

		const V = data.slice(0, length);

		// push the V onto the TLV array
		TLV[tag] = V;

		// if it was a constructed tag (FFEE01, e.g.) recurse:
		if (tag.slice(0, 2) === 'FF') {
			const tmptlv = parseTags(V);
			// eslint-disable-next-line guard-for-in,no-restricted-syntax
			for (const t in tmptlv) TLV[t] = tmptlv[t];
		}
		data = data.slice(length); // get past the data
	}

	const THE_SUN_SHINES = 1;
	let amtRead;
	let tag;

	while (THE_SUN_SHINES) {
		if (data === '') break; // loop ends

		for (amtRead = 2; amtRead <= 6; amtRead += 2)
			// eslint-disable-next-line no-cond-assign
			if ((tag = data.slice(0, amtRead).toUpperCase()) in KNOWN_TAGS) break;
			else if (amtRead === 6) {
				// no known tag?
				data = data.slice(2); // no tag found; just advance 2 chars
				console.error(`Expected a tag, found none. Data: n${data}`);
			} // if not 3-byte

		readData(amtRead, tag); // This method shortens data (by amtRead) each time
	} // while loop

	return TLV; // return an object in which TLV[ key ] == V
};

export const parseSwipe = swipe => {
	if (swipe.toString().includes('*')) {
		const part1 = swipe.split('%*');

		const part2 = swipe.split('?*');

		swipe = part1[0] + part2[part2.length - 1];
	}

	const regex = /(^02([^%]{18})(\w+)03).*$/gim;
	const match = regex.exec(swipe);

	if (match === null || match.length !== 4) return null;

	const outputDetails = match[2];
	const trackData = match[3];

	const decoded = {
		dataLen: littleEndianHexStringToDecimal(outputDetails.substr(0, 4)),
		cardEncodeType: parseInt(outputDetails.substring(4, 6), 16),
		trackStatus: parseInt(outputDetails.substring(6, 8), 16),
		track1Len: parseInt(outputDetails.substring(8, 10), 16),
		track2Len: parseInt(outputDetails.substring(10, 12), 16),
		track3Len: parseInt(outputDetails.substring(12, 14), 16),
		clearMaskedSentStatus: parseInt(outputDetails.substring(14, 16), 16),
		encryptedHashDataSendStatus: parseInt(outputDetails.substring(16, 18), 16),
	};

	const isEncryptedTrack2Decoded =
		getBit(decoded.trackStatus, 1) === 1 &&
		decoded.track2Len > 0 &&
		getBit(decoded.encryptedHashDataSendStatus, 1) === 1;

	if (isEncryptedTrack2Decoded) {
		let startIdx = 0;
		if (getBit(decoded.trackStatus, 6) === 1) {
			const optionalBytesLen = parseInt(trackData[0], 16) * 2;
			startIdx += optionalBytesLen;
		}

		const decodedTracks = {
			encryptedData: '',
			serialNumber: '',
			dataKsn: '',
		};

		// encrypted track 1
		if (getBit(decoded.encryptedHashDataSendStatus, 0) === 1) {
			// If the original data length is not a multiple of 8 bytes for TDES the reader right pads the data with 0 before encryption
			const encryptedTrack1Len = Math.ceil(decoded.track1Len / 8) * 8;
			startIdx += encryptedTrack1Len * 2;
		}

		// encrypted track 2 (we just need this data for the sale request)
		if (getBit(decoded.encryptedHashDataSendStatus, 1) === 1) {
			// If the original data length is not a multiple of 8 bytes for TDES the reader right pads the data with 0 before encryption
			const encryptedTrack2Len = Math.ceil(decoded.track2Len / 8) * 8;

			decodedTracks.encryptedData = trackData.substring(
				startIdx,
				startIdx + encryptedTrack2Len * 2
			);
			startIdx += decodedTracks.encryptedData.length;
		}

		// encrypted track 3
		if (getBit(decoded.encryptedHashDataSendStatus, 2) === 1) {
			// If the original data length is not a multiple of 8 bytes for TDES the reader right pads the data with 0 before encryption
			const encryptedTrack3Len = Math.ceil(decoded.track3Len / 8) * 8;
			startIdx += encryptedTrack3Len * 2;
		}

		// add 40 bytes for the hash data

		// track 1 hash
		if (getBit(decoded.encryptedHashDataSendStatus, 3) === 1) {
			startIdx += 40;
		}
		// track 2 hash
		if (getBit(decoded.encryptedHashDataSendStatus, 4) === 1) {
			startIdx += 40;
		}
		// track 3 hash
		if (getBit(decoded.encryptedHashDataSendStatus, 5) === 1) {
			startIdx += 40;
		}

		decodedTracks.serialNumber = hexToAscii(trackData.substring(startIdx, startIdx + 20));
		startIdx += 20;

		decodedTracks.dataKsn = trackData.substring(startIdx, startIdx + 20);

		return decodedTracks;
	}

	return null;
};

const usePaymentGateway = (outlet, methods = []) => {
	const [isGeneratingToken, setIsGeneratingToken] = useState(false);

	const tokenize = (type, data, onSuccess, onError, _methods = null) => {
		const paymentProcessor = getPaymentProcessor(outlet, type);

		const paymentMethods = _methods || methods;

		if (type === paymentTypes.CREDIT_CARD) {
			// credit card payment not working without processors.
			if (!paymentProcessor) {
				onError('Missing payment processor');
				return;
			}

			if (
				data.swipe &&
				!data.swipe.isEmv &&
				paymentProcessor.gateway.value === paymentGateWays.PAYROC_CREDIT_CARD
			) {
				let swipePayload = data.swipe.swipe;

				let isFallback = false;

				// check is fallback.
				if (swipePayload.includes('DFEE23')) {
					const tlv = parseTags(swipePayload);

					if (!tlv.DFEE23) {
						onError('Swipe failed.');
						return;
					}

					swipePayload = tlv.DFEE23;
					isFallback = true;
				}

				const parsedSwipe = parseSwipe(swipePayload);

				if (!parsedSwipe) {
					onError('Swipe failed');
				} else
					onSuccess({ swipe: { ...data.swipe, swipe: { ...parsedSwipe, isFallback } } });

				return;
			}

			// if data has saved credit card or is swipe do nothing or scheduled payment without transaction.
			if (
				data.creditCard ||
				data.swipe ||
				!data.number ||
				data.token ||
				(paymentProcessor.gateway.value === paymentGateWays.SIDE_TERMINAL_CREDIT_CARD &&
					!data.number)
			) {
				onSuccess(null);
				return;
			}

			if (paymentProcessor.gateway.value === paymentGateWays.SHIFT4) {
				setIsGeneratingToken(true);
				apiCall(
					'POST',
					'getI4GOAccessBlock',
					res => {
						apiCall(
							'POST',
							paymentProcessor.testMode ? 'i4goTokenTest' : 'i4goToken',
							tokenResponse => {
								if (tokenResponse.i4go_responsecode.toString() !== '1') {
									onError(tokenResponse.i4go_responsetext);
								} else {
									onSuccess({
										token: tokenResponse.i4go_uniqueid,
										cardNumber: tokenResponse.otn.cardnumber,
										paymentMethod: paymentMethods.find(
											pm =>
												pm.abbreviation ===
												(i4ShiftCardTypes[tokenResponse.otn.cardtype] ||
													'cc_other')
										),
										paymentGateway: paymentProcessor.gateway,
										cvc: data.cvc,
										creditCard: null,
										swipe: null,
										processor: paymentProcessor.id,
										expMonth: data.expMonth,
										expYear: data.expYear,
									});
								}
								setIsGeneratingToken(false);
							},
							err => {
								setIsGeneratingToken(false);
								onError(err.toString());
							},
							'',
							{
								i4go_accessBlock: res.accessBlock,
								i4go_cardNumber: data.number,
								i4go_cvv2Code: data.cvc,
								i4go_expirationMonth: data.expMonth,
								i4go_expirationYear: data.expYear,
							}
						);
					},
					err => {
						setIsGeneratingToken(false);
						onError(err.toString());
					},
					'',
					{
						outletId: outlet.id,
					}
				);

				return;
			}

			if (
				paymentProcessor.gateway.value === paymentGateWays.PAYROC_CREDIT_CARD ||
				paymentProcessor.gateway.value === paymentGateWays.SIDE_TERMINAL_CREDIT_CARD
			) {
				setIsGeneratingToken(true);

				const phrase = getPaymentProcessorPhrase(paymentProcessor);

				onSuccess({
					token: null,
					cardNumber: cryptoEncrypt(data.number, phrase),
					expiry: data.expiry,
					expMonth: data.expMonth,
					expYear: data.expYear,
					cvc: data.cvc,
					paymentMethod: paymentMethods.find(pm => pm.name === paymentTypes.CREDIT_CARD),
					paymentGateway: paymentProcessor.gateway,
					encrypted: true,
					creditCard: null,
					swipe: null,
					processor: paymentProcessor.id,
					makeDefault: data.makeDefault,
				});

				setIsGeneratingToken(false);

				return;
			}
		}

		if (
			type === paymentTypes.CHECK &&
			paymentProcessor &&
			data?.routingNumber &&
			data?.accountNumber
		) {
			if (
				paymentProcessor.gateway.value === paymentGateWays.PAYA ||
				paymentProcessor.gateway.value === paymentGateWays.PAYROC_ACH
			) {
				if (data.achAccount) {
					onSuccess(null);
					return;
				}

				setIsGeneratingToken(true);

				const phrase = getPaymentProcessorPhrase(paymentProcessor);

				onSuccess({
					...data,
					routingNumber: cryptoEncrypt(data.routingNumber, phrase),
					accountNumber: cryptoEncrypt(data.accountNumber, phrase),
					processor: paymentProcessor.id,
				});

				setIsGeneratingToken(false);

				return;
			}
		}

		onSuccess(null);
	};

	return [tokenize, isGeneratingToken];
};

export default usePaymentGateway;
