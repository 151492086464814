import React, { useState, createContext, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import useWebWorker from '../../utils/hooks/useWebWorker';
import messageWorker from '../messageWorker';
import UserContext from './UserContext';
import { modules } from '../../utils/helpers/apiCall';
import { addCustomNotification } from '../../utils/helpers/helper';

const MessageContext = createContext(null);

export const MessageContextProvider = ({ children }) => {
	const userContext = useContext(UserContext);

	const [count, setCount] = useState(0);

	const [entityCount, setEntityCount] = useState(0);

	const [workerStart, workerStop, workerTerminate] = useWebWorker(messageWorker, 300);

	const countRef = useRef(0);

	const [isEnabled, setIsEnabled] = useState(false);

	const updateEntityCount = data => setEntityCount(data?.unReadMessage || 0);

	const onCountUpdate = ({ data }) => {
		if (countRef.current < parseInt(data.count, 10))
			addCustomNotification(
				'',
				`You have ${data.count - countRef.current} unread message(s)`,
				'',
				'info'
			);
		setCount(data.count);
		countRef.current = parseInt(data.count, 10);
	};

	useEffect(() => {
		workerStop();

		setIsEnabled(
			userContext.data &&
				userContext.data.user.company.modules.some(m => m.value === modules.MESSAGES)
		);

		if (
			userContext.data &&
			userContext.data.user.company.modules.some(m => m.value === modules.MESSAGES) &&
			!/\/terminal\/(\d+)/.test(window.location.pathname)
		)
			workerStart(onCountUpdate, 0, true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userContext]);

	useEffect(() => {
		return () => workerTerminate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<MessageContext.Provider
			value={{ isEnabled, count, setCount, entityCount, setEntityCount, updateEntityCount }}>
			{children}
		</MessageContext.Provider>
	);
};

MessageContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default MessageContext;
