import React, { useState } from 'react';
import PropTypes from 'prop-types';

import apiCall from '../../../utils/helpers/apiCall';
import { addErrorNotification, addSuccessNotification } from '../../../utils/helpers/helper';

import DialogBox from '../../reusables/element/DialogBox';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import Search from '../../reusables/element/Search';
import Portlet from '../../reusables/layout/Portlet';
import pages from '../../pages';

const TableList = ({ units, dispatch, unitMapId, addNewButton }) => {
	const [deletedItemId, setDeletedItemId] = useState(null);

	const [searchText, setSearchText] = useState('');

	const deleteDialog = (
		<DialogBox
			open={deletedItemId !== null}
			title='Delete'
			content='Are you sure about delete?'
			type='question'
			onClose={() => {
				setDeletedItemId(null);
			}}>
			<Button
				className='sdms-font-transform-c'
				design='clean'
				icon='Error-circle'
				text={`No, Don't delete!`}
				onClick={() => {
					setDeletedItemId(null);
				}}
			/>
			<Button
				className='sdms-font-transform-c'
				label='danger'
				icon='Trash'
				text='Yes, Delete!'
				onClick={() => {
					if (deletedItemId === null) return;

					deleteItem(deletedItemId);

					setDeletedItemId(null);
				}}
			/>
		</DialogBox>
	);

	const deleteItem = id => {
		apiCall(
			'DELETE',
			'units',
			() => {
				addSuccessNotification('Item(s) successfully deleted.');
				dispatch({ type: 'delete', payload: id });
			},
			err => addErrorNotification(err.toString()),
			id
		);
	};

	const editItem = id =>
		dispatch({
			type: 'edit',
			payload: id,
		});

	return (
		<Portlet fluid='fluid' className='sdms-list-layout' everyTimeFluid>
			<Portlet.Head>
				<Portlet.HeadLabel portletIcon={pages.pos.settings.tableMaps.icon}>
					<Search
						inPortlet
						searchText={searchText}
						setSearchText={setSearchText}
						placeHolder='Filter Tables'
					/>
				</Portlet.HeadLabel>
				<Portlet.HeadToolbar>
					<Portlet.HeadActions>{addNewButton}</Portlet.HeadActions>
				</Portlet.HeadToolbar>
			</Portlet.Head>
			<Portlet.Body>
				<div className='sdms-pos--table-list'>
					{units
						.filter(
							t => t.name.toLowerCase().search(searchText.toLocaleLowerCase()) > -1
						)
						.map(unit => {
							return (
								<div className='sdms-pos--table-list__item' key={unit.id}>
									<div
										role='presentation'
										className='sdms-pos--table-list__name sdms-link sdms-link--dark'
										onClick={() => editItem(unit.id)}>
										{unit.name}
									</div>
									<div className='sdms-pos--table-list__actions'>
										{unit.unitMapUnits.findIndex(
											tmt => tmt.unitMap.id === unitMapId
										) < 0 && (
											<Button
												label='primary'
												size='sm'
												elevate
												text='Add To Map'
												icon='Redo'
												onClick={() =>
													dispatch({ type: 'add', payload: unit })
												}
											/>
										)}
										<Dropdown
											icon='Other#1'
											color='clean'
											inline
											aligned='right'
											circle
											outline={false}>
											<Dropdown.Header>Other Actions</Dropdown.Header>
											<Dropdown.Item
												icon='Edit'
												onClick={() => editItem(unit.id)}>
												Edit
											</Dropdown.Item>
											{unit.unitMapUnits &&
												unit.unitMapUnits.findIndex(
													tmt => tmt.unitMap.id === unitMapId
												) > -1 && (
													<Dropdown.Item
														itemsColor='danger'
														icon='Undo'
														onClick={() =>
															dispatch({
																type: 'remove',
																payload: unit.id,
															})
														}>
														Remove
													</Dropdown.Item>
												)}
											<Dropdown.Item
												itemsColor='danger'
												icon='Trash'
												onClick={() => setDeletedItemId(unit.id)}>
												Delete
											</Dropdown.Item>
										</Dropdown>
									</div>
								</div>
							);
						})}
					{deleteDialog}
				</div>
			</Portlet.Body>
		</Portlet>
	);
};
TableList.propTypes = {
	units: PropTypes.arrayOf(PropTypes.object).isRequired,
	dispatch: PropTypes.func.isRequired,
	unitMapId: PropTypes.number,
	addNewButton: PropTypes.node.isRequired,
};
TableList.defaultProps = {
	unitMapId: 0,
};

export default TableList;
