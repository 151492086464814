import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import AccountDetailTypeForm from './AccountDetailTypeForm';

const AccountDetailTypeEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='accountDetailTypes'
			lists={['accountTypes']}
			fields={<AccountDetailTypeForm />}
			pageTitle={pages.accounting.settings.accountDetailTypes.text}
			icon={pages.accounting.settings.accountDetailTypes.icon}
		/>
	);
};

AccountDetailTypeEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default AccountDetailTypeEdit;
