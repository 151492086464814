import React, { useMemo } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
	customerPaymentForms,
	getPaymentProcessor,
} from '../../../../../../utils/hooks/usePaymentGateway';
import { modals, reservationPayStatuses } from '../../constants';
import { getTotals } from '../../../../../../utils/helpers/basicReservationHelper';
import {
	calculateServiceFee,
	filterCustomerPaymentMethods,
	getServiceFeeText,
	priceFormatter,
} from '../../../../../../utils/helpers/helper';
import {
	paymentTypes as _paymentTypes,
	paymentTypes,
} from '../../../../../../utils/constants/constants';

import Portlet from '../../../../layout/Portlet';
import FormGroup from '../../../../layout/FormGroup';
import Section from '../../../../layout/Section';
import Button from '../../../Button';
import Separator from '../../../../layout/Separator';
import PaymentMethodSelect from '../../../PaymentMethodSelect';
import CreditCardForm from '../../../CreditCardForm';
import CheckForm from '../../../CheckForm';
import Alert from '../../../Alert';
import FormField from '../../../../template/FormField';
import Input from '../../../../field/Input';

const Payment = ({
	isOnline,
	customer,
	outlet,
	selectedPaymentMethod,
	setSelectedPaymentMethod,
	payment,
	setPayment,
	isSubmitted,
	data,
	perUnitPromoCodeAmount,
	appliedPromoCode,
	reservationItems,
	promoCode,
	setPromoCode,
	isCheckingPromoCode,
	onPromoCodeRemove,
	onPromoCodeApply,
	openModal,
	setPayStatus,
	serviceFeeAmount,
	needPayment,
}) => {
	const creditCardProcessor = useMemo(
		() => getPaymentProcessor(outlet, _paymentTypes.CREDIT_CARD),
		[outlet]
	);

	const checkProcessor = useMemo(() => getPaymentProcessor(outlet, _paymentTypes.CHECK), [
		outlet,
	]);

	return (
		<Portlet hasFrame={isOnline} className='sdms-analytics-payment'>
			<Portlet.Body>
				<FormGroup row={false} isLast>
					<Section>
						{!isOnline && (
							<>
								<Section.Body className='row'>
									{!isOnline && (
										<div className='col-lg-4'>
											<Button
												className={classNames('h-100', 'sdms-p0', {
													'sdms-c-mh60': !isOnline,
												})}
												label='dark'
												icon='User'
												iconSize={36}
												block
												onClick={() =>
													openModal({
														open: modals.CUSTOMER,
													})
												}>
												{customer ? customer.displayName : 'Customer Name'}
											</Button>
										</div>
									)}
								</Section.Body>
								<Separator />
							</>
						)}
						<PaymentMethodSelect
							selected={selectedPaymentMethod}
							paymentProcessors={outlet.paymentProcessors}
							onSelect={paymentMethod => setSelectedPaymentMethod(paymentMethod)}
							availablePaymentTypes={[paymentTypes.CREDIT_CARD, paymentTypes.CHECK]}
							render={content => (
								<>
									<Section.Body className='row'>
										<div className='col-lg-8'>{content}</div>
									</Section.Body>
									<Separator />
								</>
							)}
							customerPaymentForm={customerPaymentForms.RESERVATION_DEPOSITS}
							disabled={!needPayment}
						/>
						<Section.Body className='row'>
							<div className='col-lg-8'>
								<div className='row sdms-padding-l-30 sdms-padding-r-30'>
									{selectedPaymentMethod === paymentTypes.CREDIT_CARD && (
										<CreditCardForm
											onChange={creditCard => {
												setPayment({
													...payment,
													...creditCard,
													type: paymentTypes.CREDIT_CARD,
												});
												setPayStatus(reservationPayStatuses.DEFAULT);
											}}
											creditCards={
												customer
													? filterCustomerPaymentMethods(
															customer.paymentMethods,
															creditCardProcessor
													  )
													: []
											}
											disableScanner
											autoComplete
											displayInputPrependIcon={false}
											withLabel={false}
											isSubmitted={isSubmitted}
											isDisabled={!needPayment}
											displaySaveCardToggle={
												customer?.canSavePaymentMethod !== false
											}
										/>
									)}

									{selectedPaymentMethod === paymentTypes.CHECK && (
										<CheckForm
											onChange={achAccount => {
												setPayment({
													...payment,
													...achAccount,
													isWeb: isOnline,
													type: paymentTypes.CHECK,
												});
												setPayStatus(reservationPayStatuses.DEFAULT);
											}}
											achAccounts={
												customer
													? filterCustomerPaymentMethods(
															customer.paymentMethods,
															checkProcessor
													  )
													: []
											}
											hasAch
											onlyAch
											withLabel={false}
											isSubmitted={isSubmitted}
											isDisabled={!needPayment}
											displaySaveCardToggle={
												customer?.canSavePaymentMethod !== false
											}
										/>
									)}

									{selectedPaymentMethod === '' && (
										<div className='col-12'>
											<Alert solid icon='Info-circle' design='info'>
												No Available Payment Method
											</Alert>
										</div>
									)}
								</div>
							</div>
							<div className='col-lg-4'>
								<Portlet className='sdms-portlet--solid-info' isLast>
									<Portlet.Head>
										<Portlet.HeadLabelTitle portletIcon='Dollar'>
											Price
										</Portlet.HeadLabelTitle>
									</Portlet.Head>
									<Portlet.Body className='sdms-pr20'>
										{data.extraCharges &&
											data.extraCharges.map(e => (
												<div className='row sdms-fitText--lg sdms-font-bolder'>
													<div className='col'>{e.extraCharge.name}</div>
													<div className='col-auto text-right'>
														{priceFormatter(e.total)}
													</div>
												</div>
											))}
										{perUnitPromoCodeAmount !== 0 && appliedPromoCode && (
											<div className='row sdms-fitText--lg sdms-font-bolder'>
												<div className='col'>{appliedPromoCode.name}</div>
												<div className='col-auto text-right'>
													{priceFormatter(perUnitPromoCodeAmount)}
												</div>
											</div>
										)}
										<div className='row sdms-fitText--lg sdms-font-bolder'>
											<div className='col'>Total due today</div>
											<div className='col-auto text-right'>
												{priceFormatter(
													getTotals(data, reservationItems).totalDueToday
												)}
											</div>
										</div>
										{calculateServiceFee(
											getTotals(data, reservationItems).totalDueToday,
											serviceFeeAmount
										) > 0 && (
											<div className='row sdms-fitText--lg sdms-font-bolder'>
												<div className='col'>
													{getServiceFeeText(null, outlet)}
												</div>
												<div className='col-auto text-right'>
													{priceFormatter(
														calculateServiceFee(
															getTotals(data, reservationItems)
																.totalDueToday,
															serviceFeeAmount
														)
													)}
												</div>
											</div>
										)}

										<div className='row sdms-fitText--lg sdms-font-bolder'>
											<div className='col'>
												{outlet.settings.checkoutTotalText ??
													'Due at property'}
											</div>
											<div className='col-auto text-right'>
												{priceFormatter(
													getTotals(data, reservationItems).dueAtProperty
												)}
											</div>
										</div>
									</Portlet.Body>
									<Portlet.Foot className='sdms-fitText--xl sdms-font-bolder'>
										<div className='col'>Reservation Total</div>
										<div className='col-auto text-right'>
											{priceFormatter(
												getTotals(data, reservationItems).total +
													calculateServiceFee(
														getTotals(data, reservationItems)
															.totalDueToday,
														serviceFeeAmount
													)
											)}
										</div>
									</Portlet.Foot>
								</Portlet>
								<Portlet isLast>
									<Portlet.Body>
										<div className='row sdms-fitText--lg sdms-font-bolder'>
											<div className='col'>
												<FormField name='promoCode' label='Promo Code'>
													<Input
														onChange={e => setPromoCode(e.target.value)}
														value={promoCode}
														placeholder='Promo Code'
														disabled={!!appliedPromoCode}
													/>
												</FormField>
											</div>
											<div
												className='col-auto text-right'
												style={{ paddingTop: 28 }}>
												<Button
													label='info'
													text={
														appliedPromoCode
															? 'Remove Code'
															: 'Apply Code'
													}
													icon='Clipboard-list'
													size='sm'
													onClick={
														appliedPromoCode
															? onPromoCodeRemove
															: onPromoCodeApply
													}
													disabled={!promoCode || isCheckingPromoCode}
												/>
											</div>
										</div>
									</Portlet.Body>
								</Portlet>
							</div>
						</Section.Body>
					</Section>
				</FormGroup>
			</Portlet.Body>
		</Portlet>
	);
};

Payment.propTypes = {
	isOnline: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	setSelectedPaymentMethod: PropTypes.func.isRequired,
	selectedPaymentMethod: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	payment: PropTypes.object.isRequired,
	setPayment: PropTypes.func.isRequired,
	isSubmitted: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	perUnitPromoCodeAmount: PropTypes.number,
	// eslint-disable-next-line react/forbid-prop-types
	appliedPromoCode: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItems: PropTypes.any.isRequired,
	promoCode: PropTypes.string,
	setPromoCode: PropTypes.func.isRequired,
	isCheckingPromoCode: PropTypes.bool.isRequired,
	onPromoCodeRemove: PropTypes.func.isRequired,
	onPromoCodeApply: PropTypes.func.isRequired,
	openModal: PropTypes.func.isRequired,
	setPayStatus: PropTypes.func.isRequired,
	serviceFeeAmount: PropTypes.number,
	needPayment: PropTypes.bool,
};

Payment.defaultProps = {
	customer: null,
	perUnitPromoCodeAmount: 0,
	appliedPromoCode: null,
	promoCode: '',
	serviceFeeAmount: 0,
	needPayment: false,
};

export default Payment;
