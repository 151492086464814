import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import ListContainer from '../../reusables/template/ListContainer';
import List from '../../reusables/template/List';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import forms from '../../forms';
import {
	BookBy,
	InactiveCell,
	ShowOnlineCell,
	Transient,
} from '../../reusables/element/BookintTypeListCells';

const BookingTypeList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.marina.default.text, path: pages.marina.dashboard.path },
			{ title: pages.marina.settings.text, path: pages.marina.settings.path },
			{ title: pages.marina.settings.bookingType.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.marina.settings.bookingType.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='bookingTypeMarinas'
			title={pages.marina.settings.bookingType.text}
			history={history}
			icon={pages.marina.settings.bookingType.icon}
			forms={forms.marina.settings.bookingTypes}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col label='Book By' cellComponent={<BookBy />} />
				<List.Col label='Booking Periods' cellComponent={<Transient />} />
				<List.Col label='Active' cellComponent={<InactiveCell />} />
				<List.Col label='Show Online' cellComponent={<ShowOnlineCell />} />
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
BookingTypeList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default BookingTypeList;
