import React from 'react';

import Separator from '../../../layout/Separator';
import Badge from '../../../element/Badge';
import Button from '../../../element/Button';
import Dropdown from '../../../element/Dropdown';
import ItemImage from '../../../element/reservation_form/elements/ItemImage';
import Textarea from '../../../field/Textarea';
import CardPricingDetails from '../elements/CardPricingDetails';
import IncidentCharges from '../elements/IncidentCharges';
import Loading from '../../../template/Loading';
import UnitButton from '../elements/UnitButton';

const CarouselLoading = () => {
	return (
		<div className='row sdms-row-align-items' style={{ justifyContent: 'center' }}>
			<div className='sdms-carousel-card col-xlg-9 col-12'>
				<div className='sdms-carousel-card-inner sdms-carousel-card-inner--info'>
					<div className='row sdms-carousel-card-header'>
						<div className='col'>
							<div className='row'>
								<Loading isLoading type='div'>
									<h6 className='sdms-carouselcard-title'>Product name</h6>
								</Loading>
								<div className='sdms-vertical-separator' />
								<Loading isLoading type='div'>
									<Badge design='info' isInline isUnified>
										1 Night
									</Badge>
								</Loading>
							</div>
						</div>
						<div className='col-auto'>
							<div className='row'>
								<Loading isLoading type='button'>
									<Button
										className='sdms-ml-10'
										label='info'
										icon='Edit'
										size='sm'
										text='Edit'
										onClick={() => {}}
										disabled
									/>
								</Loading>
								<Dropdown
									className='sdms-ml-10'
									icon='Other#1'
									size='sm'
									label='info'
									inline
									aligned='right'
									arrow={false}
									outline={false}
									disabled>
									<Dropdown.Item icon='Mail-box' onClick={() => {}}>
										Send Confirmation Mail
									</Dropdown.Item>
								</Dropdown>
							</div>
						</div>
					</div>
					<Separator />
					<div className='row'>
						<div className='col-5'>
							<div className='sdms-mb-10'>
								<ItemImage />
							</div>
							<div className='sdms-mb-10 sdms-align-center sdms-carousel-card-description'>
								<Loading isLoading type='div' height={100} borderRadius='0.2rem'>
									<span>Product description</span>
								</Loading>
							</div>
							<div className='sdms-mb-10 sdms-align-left sdms-font-bold sdms-carousel-card-vessel-vehicle-info'>
								<Loading isLoading type='div' height={20} borderRadius='0.2rem'>
									<span>Vessel Info</span>
								</Loading>
							</div>
							<div className='sdms-mb-10'>
								<Textarea
									textRow={5}
									onChange={() => {}}
									value=''
									placeholder='No Internal Notes'
									disabled
								/>
							</div>
							<div>
								<Textarea
									textRow={5}
									onChange={() => {}}
									value=''
									placeholder='No Special Request'
									disabled
								/>
							</div>
						</div>
						<div className='col-7 sdms-pr0'>
							<div className='row h-100'>
								<div className='col-12'>
									<div className='sdms-mb-10'>
										<CardPricingDetails
											date={null}
											subtotal={0}
											extras={0}
											tax={0}
											total={0}
											isLoading
										/>
									</div>
									<div className='sdms-mb-5 sdms-mt-20 sdms-align-left sdms-font-bold'>
										<Loading isLoading type='div'>
											<span className='sdms-font-boldest'>Rate Plan:</span>
										</Loading>
									</div>
									<div className='sdms-mb-5 sdms-align-left sdms-font-bold'>
										<Loading isLoading type='div'>
											<span className='sdms-font-boldest'>
												Cancellation Policy:
											</span>
										</Loading>
									</div>
									<div className='sdms-mb-5 sdms-align-left sdms-font-bold'>
										<Loading isLoading type='div'>
											<span className='sdms-font-boldest'>
												Deposit Required:
											</span>
										</Loading>
									</div>
									<div className='sdms-mb-5 sdms-align-left sdms-font-bold'>
										<Loading isLoading type='div'>
											<span className='sdms-font-boldest'>Contracts:</span>
										</Loading>
									</div>
									<div className='sdms-mb-5 sdms-align-left sdms-font-bold'>
										<Loading isLoading type='div'>
											<span className='sdms-font-boldest'>
												Deferred Income:
											</span>
										</Loading>
									</div>
									<div className='sdms-mb-20 sdms-align-left sdms-font-bold'>
										<Loading isLoading type='div'>
											<span className='sdms-font-boldest'>Taxable:</span>
										</Loading>
									</div>
								</div>
								<div className='col-12' style={{ marginTop: 'auto' }}>
									<div className='sdms-mb-20'>
										<IncidentCharges amount={0} onClick={() => {}} isLoading />
									</div>
									<div className='row'>
										<div className='col sdms-paddingless sdms-align-left'>
											<Loading isLoading type='button'>
												<Loading isLoading type='button'>
													<UnitButton />
												</Loading>
											</Loading>
										</div>
										<div className='col-auto sdms-paddingless'>
											<Loading isLoading type='button'>
												<Button
													design='info'
													icon='Check'
													size='sm'
													text='Confirm'
													onClick={() => {}}
												/>
											</Loading>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CarouselLoading;
