import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import UserContext from '../../../../app/contexts/UserContext';
import useDate from '../../../../utils/hooks/useDate';
import { priceFormatter } from '../../../../utils/helpers/helper';
import pages from '../../../pages';
import { invoiceStatuses, paymentTypes } from '../../../../utils/constants/constants';
import { fixInvoiceItems } from '../../../../utils/helpers/invoiceHelper';

import List from '../../template/List';
import Portlet from '../../layout/Portlet';
import Button from '../../element/Button';
import { getReservationInvoices } from '../../../../utils/helpers/reservationHelper';

const DateCell = ({ data }) => {
	const [dateFormatter] = useDate();

	return dateFormatter(data.date, false);
};

const PriceCell = ({ data, field }) => {
	return !data.isCredit && field === 'amount'
		? `(${priceFormatter(data[field])})`
		: priceFormatter(data[field]);
};

const DepositDetails = ({
	reservation,
	depositDetails,
	onDepositPay,
	onApplyInvoice,
	onRefund,
	onRetain,
	onCheckRefund,
	onBack,
}) => {
	const userContext = useContext(UserContext);

	const getLink = (transaction, id) => {
		const urls = {
			Credit: pages.crm.creditMemos.path,
			Payment: pages.crm.payments.path,
			Refund: pages.crm.refunds.path,
			Invoice: pages.crm.invoices.path,
			Journal: pages.accounting.journals.path,
		};

		return urls[transaction] ? (
			<Link to={`${urls[transaction]}/${id}`} target='_blank'>
				{id}
			</Link>
		) : (
			id
		);
	};

	const transactions = useMemo(() => {
		const { payments, refunds, creditMemos } = depositDetails;

		let balance = 0;

		return [
			...payments,
			...refunds,
			...creditMemos,
			...getReservationInvoices(reservation)
				.filter(invoice => invoice.status.value !== invoiceStatuses.VOIDED)
				.map(invoice => {
					const amount = fixInvoiceItems(invoice)
						.filter(item => item.isDepositItem)
						.reduce((partialSum, item) => partialSum + item.subtotal * -1, 0);

					return {
						...invoice,
						transaction: 'Invoice',
						amount: Math.abs(amount),
						isCredit: amount < 0,
					};
				}),
		]
			.filter(item => item.amount)

			.sort((a, b) => new Date(a.timeCreated) - new Date(b.timeCreated))
			.map(item => {
				const isCredit = item?.transaction === 'Payment';

				if (
					item?.transaction === 'Refund' &&
					item?.customerSettlements &&
					!item.customerSettlements.some(cs => cs.refund)
				)
					item.transaction = 'Credit';

				balance += item?.amount * (isCredit ? 1 : -1);

				return {
					transaction: item?.transaction,
					id: getLink(item?.transaction, item?.id),
					type: item?.paymentMethod?.name || item?.transaction,
					isCredit,
					date: item.timeCreated,
					amount: item?.amount,
					debit: isCredit ? 0 : item?.amount,
					credit: isCredit ? item?.amount : 0,
					balance,
				};
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [depositDetails]);

	const hasOpenInvoices = useMemo(
		() =>
			getReservationInvoices(reservation).some(i => i.status.value === invoiceStatuses.OPEN),
		[reservation]
	);

	return (
		<Portlet fluid='fluid' className='sdms-list-layout sdms-list-deposit-details' hasFrame>
			<Portlet.Head wrapMaxSize='md'>
				<Portlet.HeadLabel portletIcon='Bullet-list'>
					<h3 className='sdms-portlet__head-title'>Deposit Details</h3>
				</Portlet.HeadLabel>
				<Portlet.HeadToolbar>
					<Portlet.HeadActions className='row'>
						<Button
							design='default'
							size='sm'
							text='Pay deposit'
							disabled={
								depositDetails.applicable === 0 || depositDetails.remaining <= 0
							}
							onClick={() => onDepositPay(depositDetails.remaining)}
						/>
						<Button
							design='default'
							size='sm'
							text='Apply to invoice'
							disabled={depositDetails.remaining >= 0 || !hasOpenInvoices}
							onClick={() => onApplyInvoice(Math.abs(depositDetails.remaining))}
						/>
						<Button
							design='default'
							size='sm'
							text='Refund'
							disabled={depositDetails.remaining >= 0}
							onClick={() => onRefund(Math.abs(depositDetails.remaining))}
						/>
						<Button
							design='default'
							size='sm'
							text='Retain'
							disabled={depositDetails.remaining >= 0}
							onClick={() => onRetain(Math.abs(depositDetails.remaining), false)}
						/>
						<Button
							design='default'
							size='sm'
							text='Issue Credit'
							disabled={depositDetails.remaining >= 0}
							onClick={() =>
								onRefund(
									Math.abs(depositDetails.remaining),
									paymentTypes.HOUSE_ACCOUNT
								)
							}
						/>
						<Button
							design='default'
							size='sm'
							text='Check Refund'
							disabled={
								depositDetails.remaining >= 0 ||
								!userContext.data.selectedOutlet.settings.depositRefundAccount
							}
							onClick={() => onCheckRefund(Math.abs(depositDetails.remaining))}
						/>
						<Button
							design='default'
							size='sm'
							text='Back to Reservation'
							onClick={onBack}
						/>
					</Portlet.HeadActions>
				</Portlet.HeadToolbar>
			</Portlet.Head>
			<List
				fluid='fluid'
				withCheckBox={false}
				withOutPortlet
				data={transactions}
				hasPagination={false}>
				<List.Col label='Transaction' cellData='transaction' />
				<List.Col label='Id' cellData='id' />
				<List.Col label='Type' cellData='type' />
				<List.Col label='Date' cellComponent={<DateCell />} />
				<List.Col label='Amount' cellComponent={<PriceCell field='amount' />} />
				<List.Col label='Debit' cellComponent={<PriceCell field='debit' />} />
				<List.Col label='Credit' cellComponent={<PriceCell field='credit' />} />
				<List.Col label='Balance' cellComponent={<PriceCell field='balance' />} />
			</List>
		</Portlet>
	);
};

DepositDetails.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	depositDetails: PropTypes.shape({
		required: PropTypes.number,
		applied: PropTypes.number,
		applicable: PropTypes.number,
		remaining: PropTypes.number,
		paid: PropTypes.number,
		refunded: PropTypes.number,
		retained: PropTypes.number,
		credit: PropTypes.number,
		payments: PropTypes.array,
		refunds: PropTypes.array,
		retains: PropTypes.array,
		creditMemos: PropTypes.array,
		reservationRemaining: PropTypes.number,
	}),
	onDepositPay: PropTypes.func,
	onApplyInvoice: PropTypes.func,
	onRefund: PropTypes.func,
	onCheckRefund: PropTypes.func,
	onRetain: PropTypes.func,
	onBack: PropTypes.func,
};

DepositDetails.defaultProps = {
	reservation: null,
	depositDetails: {
		required: 0,
		applied: 0,
		applicable: 0,
		remaining: 0,
		paid: 0,
		refunded: 0,
		retained: 0,
		credit: 0,
		payments: [],
		refunds: [],
		retains: [],
		creditMemos: [],
		reservationRemaining: [],
	},
	onDepositPay: () => {},
	onApplyInvoice: () => {},
	onRefund: () => {},
	onRetain: () => {},
	onCheckRefund: () => {},
	onBack: () => {},
};

export default DepositDetails;
