import React from 'react';

import Separator from '../../../layout/Separator';
import Badge from '../../../element/Badge';
import Button from '../../../element/Button';
import Dropdown from '../../../element/Dropdown';
import ItemImage from '../../../element/reservation_form/elements/ItemImage';
import Textarea from '../../../field/Textarea';
import CardPricingDetails from '../elements/CardPricingDetails';
import IncidentCharges from '../elements/IncidentCharges';
import Loading from '../../../template/Loading';
import UnitButton from '../elements/UnitButton';

const ItemLoading = () => {
	return (
		<div className='sdms-item-card col-xlg-6 col-12'>
			<div className='sdms-item-card-inner sdms-item-card-inner--info'>
				<div className='row sdms-item-card-header'>
					<div className='col'>
						<div className='row'>
							<Loading isLoading type='span'>
								<span className='sdms-item-card-title sdms-font-bold sdms-font-size-1-rem sdms-text-overflow'>
									Product Name
								</span>
							</Loading>
							<div className='sdms-vertical-separator' />
							<Loading isLoading type='div'>
								<Badge design='info' isInline isUnified>
									1 Night
								</Badge>
							</Loading>
						</div>
					</div>
					<div className='col-auto'>
						<div className='row'>
							<Loading isLoading type='button'>
								<Button
									icon='Git#4'
									label='info'
									size='sm'
									text='View'
									onClick={() => {}}
								/>
							</Loading>
							<Loading isLoading type='button'>
								<Button
									className='sdms-ml-10'
									label='info'
									icon='Edit'
									size='sm'
									text='Edit'
									onClick={() => {}}
									disabled
								/>
							</Loading>
							<Dropdown
								className='sdms-ml-10'
								icon='Other#1'
								size='sm'
								label='info'
								inline
								aligned='right'
								arrow={false}
								outline={false}
								disabled
							/>
						</div>
					</div>
				</div>
				<Separator />
				<div className='row'>
					<div className='col-5'>
						<div className='sdms-mb-20'>
							<ItemImage />
						</div>
						<div>
							<Textarea
								textRow={5}
								onChange={() => {}}
								value=''
								placeholder='No Special Request'
								disabled
							/>
						</div>
					</div>
					<div className='col-7 sdms-pr0 sdms-pl0'>
						<div className='row h-100'>
							<div className='col-12'>
								<div className='sdms-mb-10'>
									<CardPricingDetails
										date={null}
										subtotal={0}
										extras={0}
										tax={0}
										total={0}
										isLoading
									/>
								</div>
							</div>
							<div className='col-12' style={{ marginTop: 'auto' }}>
								<div className='sdms-mb-10'>
									<IncidentCharges isLoading />
								</div>
								<div className='sdms-mb-10'>
									<div className='row'>
										<div className='col sdms-paddingless'>
											<Loading isLoading type='button'>
												<UnitButton />
											</Loading>
										</div>
										<div className='col-auto sdms-paddingless'>
											<Loading isLoading type='button'>
												<Button
													design='info'
													icon='Check'
													size='sm'
													text='Confirm'
													onClick={() => {}}
												/>
											</Loading>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ItemLoading;
