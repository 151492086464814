import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useComponentSize from '@rehooks/component-size';

import UserContext from '../../../../../app/contexts/UserContext';
import useField from '../../../../../utils/hooks/useField';
import useDate from '../../../../../utils/hooks/useDate';
import { required } from '../../../../../utils/helpers/validation';
import { addErrorNotification, addSuccessNotification } from '../../../../../utils/helpers/helper';
import apiCall from '../../../../../utils/helpers/apiCall';
import { getBookingHourlyStep } from '../../../../../utils/helpers/reusable';
import { reservationColor } from '../../../element/ReservationStatusCell';
import { reservationStatuses } from '../../../../../utils/constants/constants';

import QuickPanelForm from '../../../template/QuickPanelForm';
import FormGroup from '../../../layout/FormGroup';
import FormField from '../../../template/FormField';
import DatePicker from '../../../field/DatePicker';
import TimePickerInput from '../../../field/TimePickerInput';
import Portal from '../../../layout/Portal';
import Portlet from '../../../layout/Portlet';
import Button from '../../../element/Button';
import Toggle from '../../../field/Toggle';

const DepartureDate = ({
	status,
	onClose,
	afterSubmit,
	reservationItem,
	defaultDepartureDate,
	defaultAutoCheckout,
}) => {
	const userContext = useContext(UserContext);

	const refForTimePicker = useRef(null);

	const sizeTimePicker = useComponentSize(refForTimePicker);

	const timePickerStep = useRef(getBookingHourlyStep(userContext));

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [
		,
		,
		,
		,
		parseReservationDatePickerValue,
		parseReservationDatePickerChange,
		parseReservationTimePickerValue,
		parseReservationTimePickerChange,
	] = useDate();

	const [
		departureDate,
		departureDateOnChange,
		departureDateValRes,
		departureDateShowVal,
		setDepartureDateShowVal,
	] = useField({}, 'departureDate', () => {}, [required], null);

	const [autoCheckout, autoCheckoutOnChange] = useField(
		{},
		'autoCheckout',
		() => {},
		[],
		defaultAutoCheckout
	);

	const dateConstraints = useMemo(() => {
		if (!reservationItem)
			return {
				defaultDate: moment(),
				minDate: moment().toDate(),
				maxDate: moment().toDate(),
			};

		let defaultDate = defaultDepartureDate || moment();

		const minDate = moment(reservationItem.fromDate).utc(false);

		if (defaultDate.isBefore(minDate)) defaultDate = minDate;

		return { defaultDate, minDate: minDate.toDate() };
	}, [reservationItem, defaultDepartureDate]);

	const onCheckout = () => {
		setDepartureDateShowVal();

		if (!departureDateValRes.isValid) return;

		setIsSubmitting(true);

		apiCall(
			'POST',
			'advancedSetDepartureDate',
			res => {
				setIsSubmitting(false);
				addSuccessNotification('Departure Date successfully updated.');
				onClose();
				afterSubmit(res.reservation, res.contracts);
			},
			e => {
				setIsSubmitting(false);
				addErrorNotification(e.toString());
			},
			'',
			{
				reservationItem,
				departureDate,
				autoCheckout,
			}
		);
	};

	useEffect(() => {
		if (dateConstraints.defaultDate)
			departureDateOnChange({ target: { value: dateConstraints.defaultDate.toISOString() } });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateConstraints]);

	return (
		<Portal>
			<QuickPanelForm status={status} onCancel={onClose}>
				<Portlet hasFrame fluid='fluid' className='sdms-marginles h-100'>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon='Sign-out'>
							Set Departure Date
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<FormGroup isLast>
							<FormField
								name='toDate'
								label='End date'
								id='toDate'
								colMd={6}
								showValidation={departureDateShowVal}
								valRes={departureDateValRes}>
								<DatePicker
									id='toDate'
									type='calendar'
									value={parseReservationDatePickerValue(departureDate)}
									onChange={e => {
										departureDateOnChange({
											target: {
												value: parseReservationDatePickerChange(
													departureDate,
													e.target.value
												),
											},
										});
									}}
									onBlur={setDepartureDateShowVal}
									minDate={dateConstraints.minDate}
									disabled={isSubmitting}
								/>
							</FormField>
							<FormField
								name='toDate'
								label='End time'
								id='toDateTime'
								colMd={6}
								showValidation={departureDateShowVal}
								valRes={departureDateValRes}>
								<TimePickerInput
									showSecond={false}
									defaultValue={dateConstraints.defaultDate}
									value={parseReservationTimePickerValue(departureDate)}
									onChange={target => {
										departureDateOnChange({
											target: {
												value: parseReservationTimePickerChange(
													departureDate,
													target
												),
											},
										});
									}}
									size={sizeTimePicker}
									use12Hours
									minuteStep={timePickerStep.current}
									onClose={() => setDepartureDateShowVal()}
									disabled={isSubmitting}
								/>
							</FormField>
							<FormField
								name='autoCheckout'
								label='Auto Checkout-Out'
								id='autoCheckout'
								colMd={6}>
								<Toggle
									onChange={autoCheckoutOnChange}
									value={autoCheckout}
									disabled={isSubmitting}
								/>
							</FormField>
						</FormGroup>
					</Portlet.Body>
					<Portlet.Foot tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
								disabled={isSubmitting}
							/>
						</div>
						<div className='col-auto'>
							<Button
								className='sdms-mw-100'
								label={reservationColor(reservationStatuses.CHECKED_OUT)}
								text={isSubmitting ? 'Setting' : 'Set'}
								icon='Sign-out'
								size='sm'
								onClick={onCheckout}
								disabled={isSubmitting}
								isSubmitting={isSubmitting}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</QuickPanelForm>
		</Portal>
	);
};

DepartureDate.propTypes = {
	status: PropTypes.bool,
	onClose: PropTypes.func,
	afterSubmit: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	defaultDepartureDate: PropTypes.object,
	defaultAutoCheckout: PropTypes.bool,
};

DepartureDate.defaultProps = {
	status: false,
	onClose: () => {},
	afterSubmit: () => {},
	reservationItem: null,
	defaultDepartureDate: null,
	defaultAutoCheckout: false,
};

export default DepartureDate;
