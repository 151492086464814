import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import moment from 'moment';
import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';
import {
	addErrorNotification,
	addSuccessNotification,
	convertDateToUTC,
} from '../../../utils/helpers/helper';
import apiCall from '../../../utils/helpers/apiCall';

import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import FormField from '../../reusables/template/FormField';
import DatePicker from '../../reusables/field/DatePicker';
import FormGroup from '../../reusables/layout/FormGroup';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import UserContext from '../../../app/contexts/UserContext';
import Radio from '../../reusables/field/Radio';
import Toggle from '../../reusables/field/Toggle';

const AutoPay = ({ onCancel, afterSubmit }) => {
	const userContext = useContext(UserContext);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [
		whichDate,
		whichDateOnChange,
		whichDateValRes,
		whichDateShowVal,
		setWhichDateShowVal,
	] = useField({}, 'whichDate', () => {}, [required], 'dueDate');

	const [date, dateOnChange, dateValRes, dateShowVal, setDateShowVal] = useField(
		{},
		'date',
		() => {},
		[required],
		moment()
			.utc(true)
			.hours(23)
			.minutes(59)
			.seconds(59)
			.milliseconds(999)
	);

	const [customers, customersOnChange] = useField({}, 'customers', () => {}, [], []);

	const [autoPayItemizedPayments, autoPayItemizedPaymentsOnChange] = useField(
		{},
		'autoPayItemizedPayments',
		() => {},
		[],
		userContext.data.user.company.settings?.autoPayItemizedPayments || false
	);

	const onSubmit = () => {
		setDateShowVal();
		setWhichDateShowVal();

		if (!dateValRes.isValid || !whichDateValRes.isValid) return;

		setIsSubmitting(true);

		apiCall(
			'POST',
			'autoPay',
			res => {
				setIsSubmitting(false);
				if (res.invoicesCount) {
					afterSubmit(true);
					addSuccessNotification(`Attempt to pay for ${res.invoicesCount} invoice(s)`);
				} else addSuccessNotification('No invoice');
			},
			e => {
				setIsSubmitting(false);
				addErrorNotification(e.toString());
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				whichDate,
				date: date.toISOString(),
				customers: customers.map(c => c.id),
				itemizedPayments: autoPayItemizedPayments,
			}
		);
	};

	return (
		<Portlet
			fluid='fluid'
			className='sdms-bg-transparent sdms-portlet--unelevate sdms-portlet--portletception sdms-mb-0'>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Dollar'>
					Auto Pay Invoices
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<FormGroup isLast>
					<FormField
						name='whichDate'
						label='Date Selection'
						id='whichDate'
						colMd={12}
						showValidation={whichDateShowVal}
						valRes={whichDateValRes}>
						<Radio.Container isInline>
							<Radio
								checked={whichDate === 'dueDate'}
								id='whichDate'
								name='whichDate'
								content='Due Date'
								className='sdms-radio--primary'
								onChange={() =>
									whichDateOnChange({
										target: { value: 'dueDate' },
									})
								}
							/>
							<Radio
								checked={whichDate === 'invoiceDate'}
								id='invoiceDate'
								name='invoiceDate'
								content='Invoice Date'
								className='sdms-radio--primary'
								onChange={() =>
									whichDateOnChange({
										target: { value: 'invoiceDate' },
									})
								}
							/>
						</Radio.Container>
					</FormField>
					<FormField
						name='date'
						label='Auto Pay Through'
						id='date'
						colMd={12}
						showValidation={dateShowVal}
						valRes={dateValRes}>
						<DatePicker
							id='toDate'
							type='calendar'
							value={convertDateToUTC(date.toDate())}
							onChange={e => {
								dateOnChange({
									target: {
										value: moment(e.target.value)
											.utc(true)
											.hours(23)
											.minutes(59)
											.seconds(59)
											.milliseconds(999),
									},
								});
							}}
							onBlur={setDateShowVal}
						/>
					</FormField>
					<FormField name='customer' label='Customer' id='customer' colLg={12}>
						<AsyncSelect
							options={[]}
							placeholder='Search and select customer'
							value={customers}
							onChange={customersOnChange}
							route='customers'
							field='displayName'
							displayKey='displayName'
							onBlur={() => {}}
							isMulti
						/>
					</FormField>
					<FormField
						name='autoPayItemizedPayments'
						label='Itemized Payments'
						id='autoPayItemizedPayments'
						colLg={6}>
						<Toggle
							spaceLess
							value={autoPayItemizedPayments}
							onChange={autoPayItemizedPaymentsOnChange}
						/>
					</FormField>
				</FormGroup>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						icon='Error-circle'
						size='sm'
						elevate
						text='Cancel'
						onClick={onCancel}
						disabled={isSubmitting}
					/>
				</div>
				<div className='col-auto'>
					<Button
						className={classNames(' sdms-mw-100', {
							'sdms-fading-dots': isSubmitting,
						})}
						label='info'
						icon={
							isSubmitting
								? process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON
								: 'Dollar'
						}
						text={isSubmitting ? 'Paying' : 'Pay'}
						size='sm'
						elevate
						onClick={onSubmit}
						disabled={isSubmitting}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};

AutoPay.propTypes = {
	onCancel: PropTypes.func,
	afterSubmit: PropTypes.func,
};

AutoPay.defaultProps = {
	onCancel: () => {},
	afterSubmit: () => {},
};

export default AutoPay;
