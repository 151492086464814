import React from 'react';
import PropTypes from 'prop-types';

import { paymentStatuses } from '../../../utils/constants/constants';
import apiCall from '../../../utils/helpers/apiCall';
import usePrint from '../../../utils/hooks/usePrint';
import {
	addErrorNotification,
	addSuccessNotification,
	getCreditRemainingAmount,
	priceFormatter,
} from '../../../utils/helpers/helper';
import { getOnlinePortalDefaultFilters } from './functions';

import ListContainer from '../../reusables/template/ListContainer';
import List from '../../reusables/template/List';
import { PaymentDateCell, PaymentStatusCell } from '../../reusables/element/PaymentListCells';
import Dropdown from '../../reusables/element/Dropdown';

const TotalCell = ({ data }) => {
	return priceFormatter(data.amount);
};

const RemainingCreditCell = ({ data }) => {
	return priceFormatter(getCreditRemainingAmount(data));
};

const CreditMemoList = ({ data, outlet }) => {
	const [onPrintReceipt, PRINT_COMPONENT] = usePrint();

	const onPrint = creditMemo => {
		apiCall(
			'POST',
			'onlinePortalGetPrintCreditMemoContent',
			res => {
				onPrintReceipt(res);
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ creditMemoId: creditMemo.id, outletId: outlet.id },
			getOnlinePortalDefaultFilters(data.customer, outlet)
		);
	};

	const onSendEmail = creditMemo => {
		apiCall(
			'POST',
			'onlinePortalGetSendCreditMemoEmail',
			() => {
				addSuccessNotification('Email(s) successfully sent');
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{
				creditMemoId: creditMemo.id,
				outletId: outlet.id,
			},
			getOnlinePortalDefaultFilters(data.customer, outlet)
		);
	};

	return (
		<>
			<ListContainer
				route='onlinePortalGetCreditMemos'
				title='Credit Memos'
				icon='Ticket'
				staticFilters={getOnlinePortalDefaultFilters(data.customer, outlet)}
				isTabList
				largeQuickPanel
				forms={[]}
				fluid='fluid'
				hasSearch={false}
				customActions={{
					printReceipt: onPrint,
					sendEmail: onSendEmail,
				}}>
				<List
					fluid='fluid'
					withCheckBox={false}
					withOutPortlet
					checkEditable={payment => {
						const actions = [];
						if (
							payment.status.value === paymentStatuses.UNSETTLED ||
							payment.status.value === paymentStatuses.SETTLED
						) {
							actions.push('sendEmail', 'printReceipt');
						}
						return actions;
					}}>
					<List.Col label='Credit Memo' cellData='remittanceId' />
					<List.Col
						label='Date'
						cellComponent={<PaymentDateCell />}
						sortable='remittanceDate'
					/>
					<List.Col label='Total' cellComponent={<TotalCell />} />
					<List.Col label='Remaining Credit' cellComponent={<RemainingCreditCell />} />
					<List.Col label='Status' cellComponent={<PaymentStatusCell />} />
					<List.Col align='right' onlyHover width={150}>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item icon='Printer' key='printReceipt'>
								Print Receipt
							</Dropdown.Item>
							<Dropdown.Item icon='Mail-box' key='sendEmail'>
								Email Credit Memo
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			{PRINT_COMPONENT}
		</>
	);
};

CreditMemoList.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
};

const CreditMemos = ({ data, isActive, outlet }) => {
	if (!isActive) return null;

	return (
		<div className='sdms-online-portal-list-container'>
			<CreditMemoList data={data} outlet={outlet} />
		</div>
	);
};

CreditMemos.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	isActive: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
};

export default CreditMemos;
