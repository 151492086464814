import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Portlet from '../../../../layout/Portlet';
import FormGroup from '../../../../layout/FormGroup';
import Radio from '../../../../field/Radio';

import FormField from '../../../../template/FormField';
import { searchResultViews } from '../../constants';
import Separator from '../../../../layout/Separator';
import Selects from '../../../../field/Selects';
import { productFilterTypes } from '../../../../../../utils/constants/constants';
import Input from '../../../../field/Input';
import Toggle from '../../../../field/Toggle';
import Button from '../../../Button';

const ProductFilter = ({ productFilter, onChange }) => {
	const [value, setValue] = useState(null);

	const _Content = useMemo(() => {
		const { type, options: optionsJson, icon, placeholder } = productFilter;

		if (
			(type.value === productFilterTypes.SELECT ||
				type.value === productFilterTypes.MULTI_SELECT) &&
			optionsJson
		) {
			try {
				const parsedJson = JSON.parse(optionsJson);

				const options = Object.keys(parsedJson).map(o => ({
					label: o,
					value: parsedJson[o],
				}));

				if (type.value === productFilterTypes.SELECT) {
					return (
						<Selects
							options={options}
							value={value || null}
							onChange={e => {
								onChange(e.target.value);
								setValue(e.target.value);
							}}
							displayKey='label'
							valueKey='value'
							prependIcon={icon?.value}
							placeholder={placeholder}
						/>
					);
				}

				if (type.value === productFilterTypes.MULTI_SELECT) {
					if (value === null) setValue([]);
					return (
						<Selects
							options={options}
							value={value || []}
							onChange={e => {
								onChange(e.target.value);
								setValue(e.target.value);
							}}
							displayKey='label'
							valueKey='value'
							prependIcon={icon?.value}
							placeholder={placeholder}
							multiple
						/>
					);
				}
			} catch (err) {
				return null;
			}
		}

		if (type.value === productFilterTypes.INPUT) {
			if (value === null) setValue('');
			return (
				<Input
					type='text'
					placeholder={placeholder}
					value={value}
					onChange={e => {
						onChange(e.target.value);
						setValue(e.target.value);
					}}
					prependIcon={icon?.value}
				/>
			);
		}

		if (type.value === productFilterTypes.TOGGLE) {
			if (value === null) setValue(false);
			return (
				<Toggle
					value={value || false}
					onChange={e => {
						onChange(e.target.value);
						setValue(e.target.value);
					}}
				/>
			);
		}

		return null;
	}, [productFilter, value, onChange]);

	if (!_Content) return null;

	return (
		<FormField
			name={productFilter.name}
			id={productFilter.id}
			colLg={12}
			label={productFilter.name}
			inFormDesign={false}>
			{_Content}
		</FormField>
	);
};

ProductFilter.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	productFilter: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

const SearchSidebar = ({
	resultView,
	setResultView,
	productCategories,
	selectedCategory,
	setSelectedCategory,
	bookingType,
	filters,
	onFilterUpdate,
	onFilterReset,
}) => {
	const categoryOptions = useMemo(() => {
		const options = [{ label: 'All', value: 'all' }];
		if (Object.keys(productCategories).length === 0) return options;

		options.push(
			...Object.keys(productCategories)
				.filter(categoryId => productCategories[categoryId].categoryName)
				.map(categoryId => ({
					label: productCategories[categoryId].categoryName,
					value: categoryId,
				}))
		);

		return options;
	}, [productCategories]);

	useEffect(() => {
		if (!categoryOptions.find(co => co.value === selectedCategory)) setSelectedCategory('all');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [categoryOptions]);

	return (
		<Portlet className='sdms-bg-transparent' isElevate={false}>
			<Portlet.Body>
				<FormGroup>
					<FormField
						name='listView'
						id='listView'
						colLg={12}
						label='Result View'
						inFormDesign={false}>
						<Radio.Container isInline>
							{Object.keys(searchResultViews).map(swt => (
								<Radio
									key={searchResultViews[swt]}
									checked={resultView === searchResultViews[swt]}
									id={searchResultViews[swt]}
									name='listViews'
									content={searchResultViews[swt]}
									className='sdms-radio--primary'
									onChange={() => setResultView(searchResultViews[swt])}
									disabled={
										searchResultViews[swt] === searchResultViews.MAP &&
										(!bookingType || !bookingType.unitMap)
									}
								/>
							))}
						</Radio.Container>
					</FormField>
				</FormGroup>
				<Separator space='sm' />
				<FormGroup>
					<FormField
						name='Category'
						id='category'
						colLg={12}
						label='Category'
						inFormDesign={false}>
						<Selects
							options={categoryOptions}
							value={categoryOptions.find(co => co.value === selectedCategory)}
							onChange={e => setSelectedCategory(e.target.value.value)}
							displayKey='label'
							valueKey='value'
							disabled={categoryOptions.length === 1}
							disableClearable
						/>
					</FormField>
					{bookingType &&
						bookingType.productFilters
							.sort((a, b) => a.sortOrder - b.sortOrder)
							.map(productFilter => (
								<ProductFilter
									key={productFilter.id}
									productFilter={productFilter}
									onChange={value => onFilterUpdate(productFilter, value)}
								/>
							))}
				</FormGroup>
				{(selectedCategory !== 'all' || Object.keys(filters).length > 0) && (
					<>
						<Separator space='sm' />
						<FormGroup>
							<div className='col-lg-12'>
								<Button
									label='secondary'
									text='Reset Filters'
									block
									size='sm'
									elevate
									onClick={onFilterReset}
								/>
							</div>
						</FormGroup>
					</>
				)}
			</Portlet.Body>
		</Portlet>
	);
};

SearchSidebar.propTypes = {
	resultView: PropTypes.string.isRequired,
	setResultView: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	productCategories: PropTypes.object,
	selectedCategory: PropTypes.string.isRequired,
	setSelectedCategory: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	bookingType: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	filters: PropTypes.object.isRequired,
	onFilterUpdate: PropTypes.func.isRequired,
	onFilterReset: PropTypes.func.isRequired,
};

SearchSidebar.defaultProps = {
	productCategories: null,
	bookingType: null,
};

export default SearchSidebar;
