import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Radio = ({
	checked,
	disabled,
	id,
	name,
	className,
	color,
	bold,
	solid,
	tick,
	content,
	onChange,
}) => {
	return (
		<label
			htmlFor={id}
			className={classNames(
				'sdms-radio',
				{ 'sdms-radio--disabled': disabled },
				{ 'sdms-radio--bold': bold },
				{ 'sdms-radio--solid': solid },
				{ 'sdms-radio--tick': tick },
				{ [`sdms-radio--${color}`]: color },
				className
			)}>
			<input
				id={id}
				name={name}
				type='radio'
				className={classNames({ checked })}
				checked={checked}
				disabled={disabled}
				readOnly
				onChange={onChange}
			/>
			{content}
			<span />
		</label>
	);
};
Radio.propTypes = {
	checked: PropTypes.bool.isRequired,
	disabled: PropTypes.bool,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
	color: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'info',
		'light',
		'primary',
		'secondary',
		'success',
		'warning',
	]),
	bold: PropTypes.bool,
	solid: PropTypes.bool,
	tick: PropTypes.bool,
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	onChange: PropTypes.func,
};
Radio.defaultProps = {
	disabled: false,
	className: null,
	color: null,
	bold: false,
	solid: false,
	tick: false,
	content: null,
	onChange: () => {},
};

const Container = React.forwardRef(({ children, className, isInline }, ref) => (
	<div
		ref={ref}
		className={classNames(className, isInline ? 'sdms-radio-inline' : 'sdms-radio-list')}>
		{children}
	</div>
));
Container.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	isInline: PropTypes.bool,
};
Container.defaultProps = {
	className: null,
	isInline: false,
};

Radio.Container = Container;

export default Radio;
