import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../../app/contexts/UserContext';
import { getCapacity } from '../../../../utils/helpers/reservationHelper';
import {
	addErrorNotification,
	addSuccessNotification,
	calculateSqft,
} from '../../../../utils/helpers/helper';
import apiCall, { modules } from '../../../../utils/helpers/apiCall';

import Portlet from '../../layout/Portlet';
import Search from './item_form/Search';
import Pricing from './item_form/Pricing';
import Customer from './item_form/Customer';
import Vessel from './item_form/Vessel';
import Vehicle from './item_form/Vehicle';
import Comments from './item_form/Comments';
import Accounting from './item_form/Accounting';
import ItemSummary from './item_form/ItemSummary';
import Button from '../../element/Button';
import { useHistory } from 'react-router-dom';

const ItemForm = ({
	reservation,
	reservationItem,
	module,
	taxRate,
	onFormChange,
	onBack,
	onFromDateChange,
	onCustomerChange,
	onVesselChange,
	onVehicleChange,
	onInvoicingChange,
	onTotalsChange,
	seasons,
	enumBookingCalculations,
	policies,
	ratePlans,
	taxCodes,
	invoicingFrequencies,
	invoiceFirstOptions,
	invoiceNextOptions,
	paymentTerms,
	accounts,
	states,
	countries,
	enumBoatTypes,
	enumBoatMakes,
	enumFuelTypes,
	enumRvTypes,
	enumVehicleMakes,
	contracts,
	onUpdate,
	onOverride,
}) => {
	const userContext = useContext(UserContext);

	const history = useHistory();

	const [isSearching, setIsSearching] = useState(reservationItem.id !== 0);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isSaving, setIsSaving] = useState(false);

	const [pricing, setPricing] = useState({ isInit: true, available: false });

	const searchSectionRef = useRef(null);

	const pricingSectionRef = useRef(null);

	const customerSectionRef = useRef(null);

	const vesselSectionRef = useRef(null);

	const vehicleSectionRef = useRef(null);

	const accountingSectionRef = useRef(null);

	const sections = useRef({
		search: {
			isValid: reservationItem.id,
			message: reservationItem?.product?.isVirtual
				? 'Please calculate price'
				: 'Please check availability.',
			ref: searchSectionRef,
		},
		pricing: { isValid: false, message: '', ref: pricingSectionRef },
		customerSelect: {
			isValid: !!reservation?.customer,
			message: 'Please select or create a customer',
			ref: customerSectionRef,
		},
		customer: {
			isValid: false,
			message: 'Unsaved customer',
			ref: customerSectionRef,
		},
		vessel: { isValid: true, message: 'Unsaved vessel.', ref: vesselSectionRef },
		vehicle: { isValid: true, message: 'Unsaved vehicle.', ref: vehicleSectionRef },
		accounting: {
			isValid: true,
			message: 'Please check accounting.',
			ref: accountingSectionRef,
		},
	});

	const setSectionIsValid = (sectionKey, isValid, message = '') => {
		sections.current[sectionKey] = {
			...sections.current[sectionKey],
			isValid,
			message: message || sections.current[sectionKey].message,
		};
	};

	const onSearch = (isInit = false) => {
		setIsSearching(true);

		sections.current.search = { ...sections.current.search, isValid: true };

		const { id, ignoredRules, ignoredCapacity, product, quantity, loa, beam } = reservationItem;

		const _data = {
			...reservationItem,
			outletId: userContext.data.selectedOutlet.id,
			ignoredReservationItemId: id,
			productId: product.id,
			capacity:
				module === modules.MARINA || module === modules.CAMPGROUND
					? getCapacity(product, quantity, loa, beam)
					: quantity,
			sqft: calculateSqft(loa, beam),
			ignoreRules: ignoredRules || isInit,
			ignoreCapacity: ignoredCapacity || isInit,
		};

		apiCall(
			'POST',
			'advancedReservationIsAvailable',
			res => {
				setIsSearching(false);

				if (!res.units) {
					setPricing({ isInit, available: false });
					setSectionIsValid('search', false);
					addErrorNotification(res.message || 'There are no available units');
					return;
				}

				if (Object.keys(res.ratePlans).length === 0) {
					setPricing({ isInit, available: false });
					setSectionIsValid('search', false);
					addErrorNotification(res.message || 'There are no matching rate plan');
					return;
				}

				setPricing({ ...res, isInit, available: true });
			},
			err => {
				setPricing({ isInit, available: false });
				addErrorNotification(err.toString().replace('Error:', ''));
				setIsSearching(false);
			},
			'',
			_data
		);
	};

	const onSave = () => {
		setIsSubmitted(true);

		const invalidSections = Object.keys(sections.current).filter(
			key => !sections.current[key].isValid
		);

		if (invalidSections.length > 0) {
			if (sections.current[invalidSections[0]].message)
				addErrorNotification(sections.current[invalidSections[0]].message);

			if (sections.current[invalidSections[0]].ref)
				sections.current[invalidSections[0]].ref.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});

			return;
		}

		if (!reservationItem.unit && !reservationItem.product.isVirtual) {
			addErrorNotification('Please select unit');

			return;
		}

		setIsSaving(true);

		apiCall(
			'POST',
			'advancedReservationUpdateItem',
			res => {
				addSuccessNotification(
					`Booking successfully ${reservationItem.id ? 'updated' : 'added'}.`
				);
				if (!reservationItem.id)
					history.push(
						`${window.location.pathname}?reservationId=${res.reservation.id}`,
						{ shallow: true }
					);

				onUpdate(res.reservation);

				setIsSaving(false);
			},
			e => {
				if (e.toString().includes('override')) onOverride();
				else addErrorNotification(e.toString());
				setIsSaving(false);
			},
			'',
			{
				reservation,
				reservationItem: {
					...reservationItem,
					capacity: getCapacity(
						reservationItem.product,
						reservationItem.quantity,
						reservationItem.loa,
						reservationItem.beam
					),
				},
				outletId: userContext.data.selectedOutlet.id,
			}
		);
	};

	useEffect(() => {
		// make check availability to get pricing.
		if (reservationItem.id) onSearch(true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='row  h-100'>
			<div className='col-lg-9 col-md-12 col-12'>
				<Portlet
					className='sdms-bg-transparent sdms-no-shadow sdms-advanced-reservation-item-form'
					hasFrame={false}
					fluid='fluid'>
					<Portlet.Body className='sdms-paddingless sdms-pb-20'>
						<Search
							sectionRef={searchSectionRef}
							reservationItem={reservationItem}
							seasons={seasons}
							onFormChange={onFormChange}
							onSearch={() => onSearch()}
							isSearching={isSearching}
							disabled={
								isSearching || (reservationItem.spaceAssignments || []).length > 0
							}
							onNeedSearch={() => setSectionIsValid('search', false)}
							onFromDateChange={onFromDateChange}
							module={module}
							customer={reservation.customer}
							vessel={reservationItem.vessel}
							onVesselChange={vessel => {
								onVesselChange(vessel);
								onCustomerChange(vessel?.customer);
								const isValid = !!vessel?.customer;
								setSectionIsValid('customer', isValid);
								setSectionIsValid('customerSelect', isValid);
							}}
							vehicle={reservationItem.vehicle}
							onVehicleChange={vehicle => {
								onVehicleChange(vehicle);
								onCustomerChange(vehicle?.customer);
								const isValid = !!vehicle?.customer;
								setSectionIsValid('customer', isValid);
								setSectionIsValid('customerSelect', isValid);
							}}
						/>
						<Pricing
							sectionRef={pricingSectionRef}
							reservation={reservation}
							reservationItem={reservationItem}
							onFormChange={onFormChange}
							disabled={isSearching || !pricing.units}
							setIsValid={isValid => {
								setTimeout(() => setSectionIsValid('pricing', isValid), 25);
							}}
							onTotalsChange={onTotalsChange}
							enumBookingCalculations={enumBookingCalculations}
							pricing={pricing}
							taxRate={taxRate}
							ratePlans={ratePlans}
							policies={policies}
							taxCodes={taxCodes}
							invoicingFrequencies={invoicingFrequencies}
							invoiceFirstOptions={invoiceFirstOptions}
							invoiceNextOptions={invoiceNextOptions}
							paymentTerms={paymentTerms}
							accounts={accounts}
							contracts={contracts}
						/>
						<Customer
							key={reservation.customer?.id}
							sectionRef={customerSectionRef}
							reservation={reservation}
							customer={{ ...(reservation.customer || {}) }}
							disabled={
								isSearching ||
								!pricing.units ||
								(!!reservation.id &&
									reservation.customer?.id &&
									userContext.data.selectedOutlet.settings.defaultCustomer.id !==
										reservation.customer?.id)
							}
							states={states}
							countries={countries}
							setSectionIsValid={isValid => {
								setTimeout(() => setSectionIsValid('customer', isValid), 50);
							}}
							onFormChange={onFormChange}
							onCustomerChange={customer => {
								setTimeout(
									() => setSectionIsValid('customerSelect', !!customer),
									50
								);
								onCustomerChange(customer);
							}}
						/>
						{module === modules.MARINA && (
							<Vessel
								key={reservationItem.vessel?.id}
								sectionRef={vesselSectionRef}
								reservationItem={reservationItem}
								customer={reservation.customer}
								vessel={{ ...(reservationItem.vessel || {}) }}
								disabled={isSearching || !pricing.units || !reservation.customer}
								enumBoatMakes={enumBoatMakes}
								enumBoatTypes={enumBoatTypes}
								enumFuelTypes={enumFuelTypes}
								onVesselChange={onVesselChange}
								onFormChange={onFormChange}
								setSectionIsValid={isValid => {
									setTimeout(() => setSectionIsValid('vessel', isValid), 75);
								}}
							/>
						)}
						{module === modules.CAMPGROUND && (
							<Vehicle
								key={reservationItem.vehicle?.id}
								sectionRef={vehicleSectionRef}
								reservationItem={reservationItem}
								customer={reservation.customer}
								vehicle={{ ...(reservationItem.vehicle || {}) }}
								disabled={isSearching || !pricing.units || !reservation.customer}
								enumRvTypes={enumRvTypes}
								enumVehicleMakes={enumVehicleMakes}
								onFormChange={onFormChange}
								onVehicleChange={onVehicleChange}
								setSectionIsValid={isValid => {
									setTimeout(() => setSectionIsValid('vehicle', isValid), 100);
								}}
							/>
						)}
						<Comments
							reservationItem={reservationItem}
							onFormChange={onFormChange}
							disabled={isSearching || !pricing.units}
						/>
						<Accounting
							sectionRef={accountingSectionRef}
							reservation={reservation}
							reservationItem={reservationItem}
							onFormChange={onFormChange}
							disabled={isSearching || !pricing.units}
							isItemSubmitted={isSubmitted}
							setSectionIsValid={isValid => {
								setTimeout(() => setSectionIsValid('accounting', isValid), 125);
							}}
							invoicingFrequencies={invoicingFrequencies}
							invoiceNextOptions={invoiceNextOptions}
							paymentTerms={paymentTerms}
							invoiceFirstOptions={invoiceFirstOptions}
							accounts={accounts}
							onInvoicingChange={extraCharges => {
								if (!isSearching) onInvoicingChange(extraCharges);
							}}
						/>
					</Portlet.Body>
					<Portlet.Foot
						tall='sm'
						className='sdms-align-left sdms-bg-white'
						subClassName='justify-content-between'>
						<div className='col'>
							<Button design='clean' text='Back' size='sm' onClick={onBack} />
						</div>
						<div className='col-auto'>
							<Button
								className='sdms-mw-105 justify-content-center'
								label='brand'
								icon='Save'
								text={isSaving ? 'Saving' : 'Save'}
								size='sm'
								disabled={isSearching || isSaving}
								onClick={onSave}
								isSubmitting={isSaving}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</div>
			<div className='col-lg-3 col-md-12 col-12'>
				<ItemSummary
					reservation={reservation}
					reservationItem={reservationItem}
					module={module}
					disabled={isSearching || !pricing.units}
					isItemSubmitted={isSubmitted}
					onFormChange={onFormChange}
					onProductChange={() => setSectionIsValid('search', false)}
					pricing={pricing}
					isSearching={isSearching}
				/>
			</div>
		</div>
	);
};

ItemForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	module: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	taxRate: PropTypes.object,
	onBack: PropTypes.func,
	onFormChange: PropTypes.func,
	onFromDateChange: PropTypes.func,
	onCustomerChange: PropTypes.func,
	onVesselChange: PropTypes.func,
	onVehicleChange: PropTypes.func,
	onInvoicingChange: PropTypes.func,
	onTotalsChange: PropTypes.func,
	seasons: PropTypes.arrayOf(PropTypes.object),
	enumBookingCalculations: PropTypes.arrayOf(PropTypes.object),
	policies: PropTypes.arrayOf(PropTypes.object),
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	invoicingFrequencies: PropTypes.arrayOf(PropTypes.object),
	invoiceFirstOptions: PropTypes.arrayOf(PropTypes.object),
	invoiceNextOptions: PropTypes.arrayOf(PropTypes.object),
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	accounts: PropTypes.arrayOf(PropTypes.object),
	states: PropTypes.arrayOf(PropTypes.object),
	countries: PropTypes.arrayOf(PropTypes.object),
	enumBoatTypes: PropTypes.arrayOf(PropTypes.object),
	enumBoatMakes: PropTypes.arrayOf(PropTypes.object),
	enumFuelTypes: PropTypes.arrayOf(PropTypes.object),
	enumRvTypes: PropTypes.arrayOf(PropTypes.object),
	enumVehicleMakes: PropTypes.arrayOf(PropTypes.object),
	contracts: PropTypes.arrayOf(PropTypes.object),
	onUpdate: PropTypes.func,
	onOverride: PropTypes.func,
};

ItemForm.defaultProps = {
	reservation: null,
	reservationItem: null,
	taxRate: null,
	onBack: () => {},
	onFormChange: () => {},
	onFromDateChange: () => {},
	onCustomerChange: () => {},
	onVesselChange: () => {},
	onVehicleChange: () => {},
	onInvoicingChange: () => {},
	onTotalsChange: () => {},
	seasons: [],
	enumBookingCalculations: [],
	policies: [],
	ratePlans: [],
	taxCodes: [],
	invoicingFrequencies: [],
	invoiceFirstOptions: [],
	invoiceNextOptions: [],
	paymentTerms: [],
	accounts: [],
	states: [],
	countries: [],
	enumBoatTypes: [],
	enumBoatMakes: [],
	enumFuelTypes: [],
	enumRvTypes: [],
	enumVehicleMakes: [],
	contracts: [],
	onUpdate: () => {},
	onOverride: () => {},
};

export default ItemForm;
