import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';
import { getBookingCalculationText, priceFormatter } from '../../../utils/helpers/helper';

import ListContainer from '../../reusables/template/ListContainer';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import Badge from '../../reusables/element/Badge';
import Forms from '../../forms';

const ExtraChargeTypeCell = ({ data }) => {
	return (
		<Badge design='brand' isDot className='sdms-font-transform-c'>
			{data.extraChargeType.value}
		</Badge>
	);
};
ExtraChargeTypeCell.propTypes = {
	data: PropTypes.shape({
		extraChargeType: PropTypes.shape({
			value: PropTypes.string,
		}),
	}),
};
ExtraChargeTypeCell.defaultProps = {
	data: {
		extraChargeType: {
			value: '',
		},
	},
};

const OptionalCell = ({ data }) => {
	return data.optional ? <Badge.Yes /> : <Badge.No />;
};
OptionalCell.propTypes = {
	data: PropTypes.shape({
		optional: PropTypes.bool,
	}),
};
OptionalCell.defaultProps = {
	data: {
		optional: false,
	},
};

const BookingCalculationCell = ({ data }) =>
	getBookingCalculationText(data.bookingPeriod, data.bookingCalculation);

BookingCalculationCell.propTypes = {
	data: PropTypes.shape({
		bookingCalculations: PropTypes.object,
		bookingPeriod: PropTypes.object,
	}),
};

BookingCalculationCell.defaultProps = {
	data: {
		bookingCalculations: {},
	},
};

const PriceCell = ({ data }) =>
	data.bookingCalculation.value === 'percent' ? `${data.price}%` : priceFormatter(data.price);

PriceCell.propTypes = {
	data: PropTypes.shape({
		price: PropTypes.number,
	}),
};

PriceCell.defaultProps = {
	data: {
		price: 0,
	},
};

const ExtraChargesList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
			{ title: pages.campground.settings.text, path: pages.campground.settings.path },
			{
				title: pages.campground.settings.extraCharges.text,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(pages.campground.settings.extraCharges.text);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='bookingExtraCharges'
			title={pages.campground.settings.extraCharges.text}
			history={history}
			forms={Forms.campground.settings.extraCharges}
			icon={pages.campground.settings.extraCharges.icon}
			module={modules.CAMPGROUND}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col
					label='Type'
					cellComponent={<ExtraChargeTypeCell />}
					sortable='extraChargeType.value'
				/>
				<List.Col label='Optional' cellComponent={<OptionalCell />} sortable='optional' />
				<List.Col label='Price' cellComponent={<PriceCell />} />
				<List.Col label='Calculation' cellComponent={<BookingCalculationCell />} />
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
ExtraChargesList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default ExtraChargesList;
