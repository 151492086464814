import React from 'react';
import PrinterEdit from './modules/company_settings/PrinterEdit';
import DiscountEdit from './modules/accounting/DiscountEdit';
import CreditMemoReasonEdit from './modules/accounting/CreditMemoReasonEdit';
import AccountEdit from './modules/accounting/AccountEdit';

import AccountDetailTypeEdit from './modules/accounting/AccountDetailTypeEdit';
import UnitMeasureEdit from './modules/accounting/UnitMeasureEdit';
import PaymentTermEdit from './modules/accounting/PaymentTermEdit';
import TaxAgencyEdit from './modules/accounting/TaxAgencyEdit';
import TaxRateEdit from './modules/accounting/TaxRateEdit';
import TaxCodeEdit from './modules/accounting/TaxCodeEdit';
import BookingTypeEdit from './modules/booking/BookingTypeEdit';
import ProductCategoryEdit from './modules/pos/ProductCategoryEdit';
import RatePlanEdit from './modules/booking/RatePlanEdit';
import PolicyEdit from './modules/booking/PolicyEdit';
import UnitGroupsEdit from './reusables/forms/unit_groups/UnitGroupsEdit';
import CustomerEdit from './modules/crm/CustomerEdit';
import InvoiceEdit from './modules/crm/InvoiceEdit';
import RegisterEdit from './modules/pos/RegisterEdit';
import ProductEdit from './modules/pos/ProductEdit';
import GridEdit from './modules/pos/GridEdit';
import ModifierGroupEdit from './modules/pos/ModifierGroupEdit';
import RegisterTypeEdit from './modules/pos/RegisterTypeEdit';
import PrepStationEdit from './modules/pos/PrepStationEdit';
import TableMapEdit from './modules/pos/TableMapEdit';
import ModifierPrefixEdit from './modules/pos/ModifierPrefixEdit';
import CalendarEdit from './modules/company_settings/CalendarEdit';
import RoleEdit from './modules/system_settings/RoleEdit';
import UserEdit from './modules/system_settings/UserEdit';
import OutletEdit from './modules/company_settings/OutletEdit';
import UnitEdit from './modules/booking/UnitEdit';
import UnitsGenerateForm from './modules/booking/UnitsGenerateForm';
import ProductCategoryBookingEdit from './modules/booking/ProductCategoryBookingEdit';
import ExtraChargeSelect from './modules/booking/ExtraChargeSelect';
import ExtraChargeOrderEdit from './modules/booking/ExtraChargeOrderEdit';
import ExtraChargeOnlineOrderEdit from './modules/booking/ExtraChargeOnlineOrderEdit';
import ExtraChargeOfflineOrderEdit from './modules/booking/ExtraChargeOfflineOrderEdit';
import ExtraChargePerUnitEdit from './modules/booking/ExtraChargePerUnitEdit';
import Button from './reusables/element/Button';
import ProductCategoryExtraChargeEdit from './modules/booking/ProductCategoryExtraChargeEdit';
import ProductBookingNightlyEdit from './modules/booking/ProductBookingNightlyEdit';
import ProductBookingHourlyEdit from './modules/booking/ProductBookingHourlyEdit';
import ProductBookingDailyEdit from './modules/booking/ProductBookingDailyEdit';
import ProductBookingSelect from './modules/booking/ProductBookingSelect';
import Dropdown from './reusables/element/Dropdown';
import pages from './pages';
import ReservationEdit from './reusables/forms/ReservationEdit';
import TemplateEdit from './modules/system_settings/TemplateEdit';
import ContractEdit from './modules/system_settings/ContractEdit';
import PayrollHoursEdit from './modules/accounting/PayrollHoursEdit';
import UnitBlockoutEdit from './modules/booking/UnitBlockoutEdit';
import BookingTypeMarinaEdit from './modules/marina/BookingTypeEdit';
import ProductCategoryMarinaEdit from './modules/marina/ProductCategoryMarinaEdit';
import MarinaPolicyEdit from './modules/marina/PolicyEdit';
import MarinaProductCategoryExtraChargeEdit from './modules/marina/ProductCategoryExtraChargeEdit';
import MarinaRatePlanEdit from './modules/marina/RatePlanEdit';
import SpaceEdit from './modules/marina/SpaceEdit';
import SpaceBlockoutEdit from './modules/marina/SpaceBlockoutEdit';
import SeasonEdit from './modules/system_settings/SeasonEdit';
import VesselEdit from './modules/marina/VesselEdit';
// eslint-disable-next-line no-unused-vars
import MarinaExtraChargeOrderEdit from './modules/marina/ExtraChargeOrderEdit';
// eslint-disable-next-line no-unused-vars
import MarinaExtraChargeSelect from './modules/marina/ExtraChargeSelect';
// eslint-disable-next-line no-unused-vars
import MarinaExtraChargeOfflineOrderEdit from './modules/marina/ExtraChargeOfflineOrderEdit';
// eslint-disable-next-line no-unused-vars
import MarinaExtraChargeOnlineOrderEdit from './modules/marina/ExtraChargeOnlineOrderEdit';
import MarinaExtraChargePerUnitEdit from './modules/marina/ExtraChargePerUnitEdit';
import MapEdit from './reusables/forms/MapEdit';
import SpacesGenerateForm from './modules/marina/SpacesGenerateForm';
import ProductMarinaSelect from './modules/marina/ProductMarinaSelect';
import ProductMarinaHourlyEdit from './modules/marina/ProductMarinaHourlyEdit';
import ProductMarinaDailyEdit from './modules/marina/ProductMarinaDailyEdit';
import ProductMarinaNightlyEdit from './modules/marina/ProductMarinaNightlyEdit';
import ProductMarinaSeasonalEdit from './modules/marina/ProductMarinaSeasonalEdit';
import ProductMarinaLongTermEdit from './modules/marina/ProductMarinaLongTermEdit';
import ReportsEdit from './modules/reports/ReportsEdit';
import AccountingReportSelect from './modules/reports/AccountingReportSelect';
import BookingReportSelect from './modules/reports/BookingReportSelect';
import CrmReportSelect from './modules/reports/CrmReportSelect';
import MarinaReportSelect from './modules/reports/MarinaReportSelect';
import CampgroundReportSelect from './modules/reports/CampgroundReportSelect';
import PosReportSelect from './modules/reports/PosReportSelect';
import PowerMeterTypeEdit from './modules/marina/PowerMeterTypeEdit';
import PowerMeterEdit from './modules/marina/PowerMeterEdit';
import MeterReadingEdit from './modules/marina/MeterReadingEdit';
import MeterReadBatchEdit from './modules/marina/MeterReadBatchEdit';
import GenerateInvoices from './modules/crm/GenerateInvoices';
import PaymentEdit from './modules/crm/PaymentEdit';
import AutoPay from './modules/crm/AutoPay';
import TaxRateGroupEdit from './modules/accounting/TaxRateGroupEdit';
import MarketingEmail from './modules/crm/MarketingEmail';
import RecognizeRevenue from './modules/crm/RecognizeRevenue';
import JournalEdit from './modules/accounting/JournalEdit';
import CreditMemoEdit from './modules/crm/CreditMemoEdit';
import RefundEdit from './modules/crm/RefundEdit';
import StatementChargeEdit from './modules/crm/StatementChargeEdit';
import PaymentProcessorEdit from './modules/company_settings/PaymentProcessorEdit';
import ProductFilterEdit from './modules/system_settings/ProductFilterEdit';
import MetaEdit from './modules/system_settings/MetaEdit';
import PromoCodeEdit from './reusables/forms/PromoCodeEdit';

import BookingTypeCampgroundEdit from './modules/campground/BookingTypeEdit';
import ProductCategoryCampgroundEdit from './modules/campground/ProductCategoryCampgroundEdit';
import CampgroundPolicyEdit from './modules/campground/PolicyEdit';
import CampgroundProductCategoryExtraChargeEdit from './modules/campground/ProductCategoryExtraChargeEdit';
import CampgroundRatePlanEdit from './modules/campground/RatePlanEdit';
import CampgroundSpaceEdit from './modules/campground/SpaceEdit';
import CampgroundCompositeSpaceEdit from './modules/campground/CompositeSpaceEdit';
import CampgroundSpaceBlockoutEdit from './modules/campground/SpaceBlockoutEdit';
import CampgroundVehicleEdit from './modules/campground/VehicleEdit';
// eslint-disable-next-line no-unused-vars
import CampgroundExtraChargeOrderEdit from './modules/campground/ExtraChargeOrderEdit';
// eslint-disable-next-line no-unused-vars
import CampgroundExtraChargeSelect from './modules/campground/ExtraChargeSelect';
// eslint-disable-next-line no-unused-vars
import CampgroundExtraChargeOfflineOrderEdit from './modules/campground/ExtraChargeOfflineOrderEdit';
// eslint-disable-next-line no-unused-vars
import CampgroundExtraChargeOnlineOrderEdit from './modules/campground/ExtraChargeOnlineOrderEdit';
import CampgroundExtraChargePerUnitEdit from './modules/campground/ExtraChargePerUnitEdit';
import CampgroundSpacesGenerateForm from './modules/campground/SpacesGenerateForm';
import ProductCampgroundSelect from './modules/campground/ProductCampgroundSelect';
import ProductCampgroundHourlyEdit from './modules/campground/ProductCampgroundHourlyEdit';
import ProductCampgroundDailyEdit from './modules/campground/ProductCampgroundDailyEdit';
import ProductCampgroundNightlyEdit from './modules/campground/ProductCampgroundNightlyEdit';
import ProductCampgroundSeasonalEdit from './modules/campground/ProductCampgroundSeasonalEdit';
import ProductCampgroundLongTermEdit from './modules/campground/ProductCampgroundLongTermEdit';
import CampgroundPowerMeterTypeEdit from './modules/campground/PowerMeterTypeEdit';
import CampgroundPowerMeterEdit from './modules/campground/PowerMeterEdit';
import CampgroundMeterReadingEdit from './modules/campground/MeterReadingEdit';
import CampgroundMeterReadBatchEdit from './modules/campground/MeterReadBatchEdit';
import EmailAddressesEdit from './modules/system_settings/EmailAddressesEdit';

import ReservationAdvanceEdit from './reusables/forms/advanced_reservation/Edit';
import ReservationAdvanceSelect from './reusables/forms/advanced_reservation/Select';

import PaymentScheduleEdit from './modules/accounting/PaymentScheduleEdit';
import MessageEdit from './modules/messages/MessageEdit';
import CustomReservationStatusEdit from './reusables/forms/CustomReservationStatusEdit';
import AttachmentTypesEdit from './modules/system_settings/AttachmentTypesEdit';
import CompanySyncsEdit from './modules/company_settings/CompanySyncsEdit';
import ModuleEdit from './modules/company_settings/ModuleEdit';
import CompositeSpaceEdit from './modules/marina/CompositeSpaceEdit';
import EmailDocuments from './modules/crm/EmailDocuments';
import ImportButton from './reusables/element/ImportButton';
import AnalyticsDashboardEdit from './modules/analytics/AnalyticsDashboardEdit';

const forms = {
	accounting: {
		chartOfAccounts: [
			{
				name: 'add',
				component: AccountEdit,
				path: pages.accounting.chartOfAccounts.path,
				permission: 'edit_chart_of_accounts',
			},
		],
		journals: [
			{
				name: 'add',
				component: JournalEdit,
				path: pages.accounting.journals.path,
				permission: 'edit_journal_entries',
			},
		],
		payrollHours: [
			{
				name: 'add',
				component: PayrollHoursEdit,
				isQuickPanel: true,
			},
		],
		settings: {
			discount: [
				{
					name: 'add',
					component: DiscountEdit,
					path: pages.accounting.settings.discount.path,
				},
			],
			creditMemoReasons: [
				{
					name: 'add',
					component: CreditMemoReasonEdit,
					path: pages.accounting.settings.creditMemoReasons.path,
				},
			],
			paymentTerms: [
				{
					name: 'add',
					component: PaymentTermEdit,
					path: pages.accounting.settings.paymentTerms.path,
				},
			],
			accountDetailTypes: [
				{
					name: 'add',
					component: AccountDetailTypeEdit,
					path: pages.accounting.settings.accountDetailTypes.path,
				},
			],
			unitMeasures: [
				{
					name: 'add',
					component: UnitMeasureEdit,
					path: pages.accounting.settings.unitMeasures.path,
				},
			],
			taxAgency: [
				{
					name: 'add',
					component: TaxAgencyEdit,
					path: pages.accounting.settings.taxAgency.path,
				},
			],
			taxRate: [
				{
					name: 'add',
					component: TaxRateEdit,
					path: pages.accounting.settings.taxRate.path,
				},
			],
			taxRateGroup: [
				{
					name: 'add',
					component: TaxRateGroupEdit,
					path: pages.accounting.settings.taxRateGroup.path,
				},
			],
			taxCode: [
				{
					name: 'add',
					component: TaxCodeEdit,
					path: pages.accounting.settings.taxCode.path,
				},
			],
			paymentSchedules: [
				{
					name: 'add',
					component: PaymentScheduleEdit,
					path: pages.accounting.settings.paymentSchedules.path,
				},
			],
		},
	},
	booking: {
		reservations: [
			{
				name: 'add',
				component: ReservationEdit,
				path: pages.booking.reservations.path,
				permission: 'create_bookings_reservations',
			},
			{
				name: 'advance',
				button: (
					<Dropdown.Item icon='Plus' key='advance'>
						Advanced Reservation
					</Dropdown.Item>
				),
				isInDropdown: true,
				path: pages.booking.reservations.advanced.path,
				component: ReservationAdvanceEdit,
				permission: 'create_bookings_advanced_reservations',
			},
		],
		availability: [
			{
				name: 'add',
				component: ReservationAdvanceEdit,
				path: pages.booking.availability.path,
				button: <Dropdown.Item icon='Plus'>Advanced Reservation</Dropdown.Item>,
				isInDropdown: true,
			},
		],
		settings: {
			bookingTypes: [
				{
					name: 'add',
					component: BookingTypeEdit,
					path: pages.booking.settings.bookingType.path,
				},
			],
			customReservationStatuses: [
				{
					name: 'add',
					component: CustomReservationStatusEdit,
					path: pages.booking.settings.customReservationStatus.path,
				},
			],
			productCategories: [
				{
					name: 'add',
					component: ProductCategoryBookingEdit,
					path: pages.booking.settings.productCategories.path,
				},
			],
			productCategoriesExtraCharges: [
				{
					name: 'add',
					component: ProductCategoryExtraChargeEdit,
					path: pages.booking.settings.productCategoriesExtraCharges.path,
				},
			],
			units: [
				{
					name: 'generate',
					component: UnitsGenerateForm,
					button: (
						<Button
							className='sdms-margin-r-5'
							icon='Clipboard-list'
							design='brand'
							outline
							text='Generate Units'
							size='sm'
						/>
					),
					isModal: true,
				},
				{
					name: 'add',
					component: UnitEdit,
					path: pages.booking.settings.units.path,
				},
			],
			unitBlackouts: [
				{
					name: 'add',
					component: UnitBlockoutEdit,
					path: pages.booking.settings.unitBlackouts.path,
				},
			],
			ratePlans: [
				{
					name: 'add',
					component: RatePlanEdit,
					path: pages.booking.settings.ratePlans.path,
				},
			],
			policies: [
				{
					name: 'add',
					component: PolicyEdit,
					path: pages.booking.settings.policies.path,
				},
			],
			extraCharges: [
				{
					name: 'add',
					component: ExtraChargeSelect,
					path: pages.booking.settings.extraCharges.path,
				},
				{
					name: 'allOrders',
					component: ExtraChargeOrderEdit,
					path: pages.booking.settings.extraCharges.allOrders.path,
					which: data => data.extraChargeType.value === 'All Orders',
					hideButton: true,
				},
				{
					name: 'offlineOrders',
					component: ExtraChargeOfflineOrderEdit,
					path: pages.booking.settings.extraCharges.offlineOrders.path,
					which: data => data.extraChargeType.value === 'Offline Orders',
					hideButton: true,
				},
				{
					name: 'onlineOrders',
					component: ExtraChargeOnlineOrderEdit,
					path: pages.booking.settings.extraCharges.onlineOrders.path,
					which: data => data.extraChargeType.value === 'Online Orders',
					hideButton: true,
				},

				{
					name: 'perUnit',
					component: ExtraChargePerUnitEdit,
					path: pages.booking.settings.extraCharges.perUnit.path,
					which: data => data.extraChargeType.value === 'Per Unit',
					hideButton: true,
				},
			],
			unitGroups: [
				{
					name: 'add',
					component: UnitGroupsEdit,
					path: pages.booking.settings.unitGroups.path,
				},
			],
			maps: [
				{
					name: 'add',
					component: MapEdit,
					path: pages.booking.settings.maps.path,
				},
			],
			promoCodes: [
				{
					name: 'add',
					component: PromoCodeEdit,
					path: pages.booking.settings.promoCodes.path,
				},
			],
		},
		products: [
			{
				name: 'add',
				component: ProductBookingSelect,
				path: pages.booking.products.path,
				permission: 'edit_bookings_products',
			},
			{
				name: 'hourly',
				component: ProductBookingHourlyEdit,
				path: pages.booking.products.period.hourly.path,
				which: data => data.bookingPeriod.value === 'Hourly',
				hideButton: true,
				permission: 'edit_bookings_products',
			},
			{
				name: 'daily',
				component: ProductBookingDailyEdit,
				path: pages.booking.products.period.daily.path,
				which: data => data.bookingPeriod.value === 'Daily',
				hideButton: true,
				permission: 'edit_bookings_products',
			},
			{
				name: 'nightly',
				component: ProductBookingNightlyEdit,
				path: pages.booking.products.period.nightly.path,
				which: data => data.bookingPeriod.value === 'Nightly',
				hideButton: true,
				permission: 'edit_bookings_products',
			},
		],
	},
	crm: {
		customers: [
			{
				name: 'emailDocuments',
				component: EmailDocuments,
				button: (
					<Button
						className='sdms-margin-r-5'
						icon='Send'
						design='brand'
						outline
						text='Email Documents'
						size='sm'
					/>
				),
				permission: 'recognize_revenue',
				isQuickPanel: true,
			},
			{
				name: 'marketing',
				component: MarketingEmail,
				button: (
					<Button
						className='sdms-margin-r-5'
						icon='Clipboard-list'
						design='brand'
						outline
						text='Marketing Email'
						size='sm'
					/>
				),
				permission: 'marketing_emails',
				isQuickPanel: true,
			},
			{
				name: 'add',
				component: CustomerEdit,
				path: pages.crm.customers.path,
				permission: 'view_customers',
				editPermission: 'edit_customers',
			},
		],
		invoices: [
			{
				name: 'revenue',
				component: RecognizeRevenue,
				button: (
					<Button
						className='sdms-margin-r-5'
						icon='Clipboard-list'
						design='brand'
						outline
						text='Recognize Revenue'
						size='sm'
					/>
				),
				permission: 'recognize_revenue',
				isQuickPanel: true,
			},
			{
				name: 'generate',
				component: GenerateInvoices,
				button: (
					<Button
						className='sdms-margin-r-5'
						icon='Clipboard-list'
						design='brand'
						outline
						text='Generate Invoices'
						size='sm'
					/>
				),
				permission: 'generate_invoices',
				isQuickPanel: true,
			},
			{
				name: 'import',
				button: (
					<ImportButton
						route='invoiceImport'
						text='Invoice'
						permission='import_invoices'
					/>
				),
			},
			{
				name: 'add',
				component: InvoiceEdit,
				path: pages.crm.invoices.path,
				editPermission: 'edit_invoices',
				permission: 'view_invoices',
			},
		],
		payments: [
			{
				name: 'generate',
				component: AutoPay,
				button: (
					<Button
						className='sdms-margin-r-5'
						icon='Dollar'
						design='brand'
						outline
						text='Auto Pay'
						size='sm'
					/>
				),
				isQuickPanel: true,
				editPermission: 'edit_payments',
			},
			{
				name: 'import',
				button: (
					<ImportButton
						route='paymentImport'
						text='Payment'
						permission='import_payments'
					/>
				),
			},
			{
				name: 'add',
				component: PaymentEdit,
				path: pages.crm.payments.path,
				editPermission: 'edit_payments',
				permission: 'view_payments',
			},
		],
		creditMemos: [
			{
				name: 'add',
				component: CreditMemoEdit,
				path: pages.crm.creditMemos.path,
				permission: 'view_credit_memos',
				editPermission: 'edit_credit_memos',
			},
		],
		refunds: [
			{
				name: 'add',
				component: RefundEdit,
				path: pages.crm.refunds.path,
				permission: 'view_refunds',
				editPermission: 'edit_refunds',
			},
		],
		statementCharges: [
			{
				name: 'add',
				component: StatementChargeEdit,
				path: pages.crm.statementCharges.path,
				permission: 'view_statement_charges',
				editPermission: 'edit_statement_charges',
			},
		],
	},
	pos: {
		registers: [
			{
				name: 'add',
				component: RegisterEdit,
				path: pages.pos.registers.path,
				permission: 'edit_registers',
			},
		],
		products: [
			{
				name: 'add',
				component: ProductEdit,
				path: pages.pos.products.path,
				permission: 'edit_pos_products',
			},
		],
		productGrids: [
			{
				name: 'add',
				component: GridEdit,
				path: pages.pos.productGrids.path,
				permission: 'edit_product_grids',
			},
		],
		modifierGroups: [
			{
				name: 'clear',
				component: null,
				button: (
					<Button
						className='sdms-margin-r-5'
						icon='Clipboard-list'
						design='brand'
						outline
						text='Clear Unused Groups'
						size='sm'
					/>
				),
			},
			{
				name: 'add',
				component: ModifierGroupEdit,
				path: pages.pos.modifierGroups.path,
				permission: 'edit_modifiers_group',
			},
		],
		settings: {
			registerTypes: [
				{
					name: 'add',
					component: RegisterTypeEdit,
					path: pages.pos.settings.registerTypes.path,
					permission: 'maintain_register_types',
				},
			],
			prepStations: [
				{
					name: 'add',
					component: PrepStationEdit,
					path: pages.pos.settings.prepStations.path,
					permission: 'maintain_prep_stations',
				},
			],
			tableMaps: [
				{
					name: 'add',
					component: TableMapEdit,
					path: pages.pos.settings.tableMaps.path,
					permission: 'maintain_table_maps',
				},
			],
			modifierPrefixes: [
				{
					name: 'add',
					component: ModifierPrefixEdit,
					path: pages.pos.settings.modifierPrefixes.path,
					permission: 'maintain_modifier_prefixes',
				},
			],
			productCategories: [
				{
					name: 'add',
					component: ProductCategoryEdit,
					path: pages.pos.settings.productCategories.path,
					permission: 'maintain_product_categories',
				},
			],
		},
	},
	marina: {
		products: [
			{
				name: 'add',
				component: ProductMarinaSelect,
				path: pages.marina.products.path,
				permission: 'edit_marina_products',
			},
			{
				name: 'hourly',
				component: ProductMarinaHourlyEdit,
				path: pages.marina.products.period.hourly.path,
				which: data => data.bookingPeriod.value === 'Hourly',
				hideButton: true,
				permission: 'edit_marina_products',
			},
			{
				name: 'daily',
				component: ProductMarinaDailyEdit,
				path: pages.marina.products.period.daily.path,
				which: data => data.bookingPeriod.value === 'Daily',
				hideButton: true,
				permission: 'edit_marina_products',
			},
			{
				name: 'nightly',
				component: ProductMarinaNightlyEdit,
				path: pages.marina.products.period.nightly.path,
				which: data => data.bookingPeriod.value === 'Nightly',
				hideButton: true,
				permission: 'edit_marina_products',
			},
			{
				name: 'longTerm',
				component: ProductMarinaLongTermEdit,
				path: pages.marina.products.period.longTerm.path,
				which: data => data.bookingPeriod.value === 'Long Term',
				hideButton: true,
				permission: 'edit_marina_products',
			},
			{
				name: 'seasonal',
				component: ProductMarinaSeasonalEdit,
				path: pages.marina.products.period.seasonal.path,
				which: data => data.bookingPeriod.value === 'Seasonal',
				hideButton: true,
				permission: 'edit_marina_products',
			},
		],
		reservations: [
			{
				name: 'add',
				component: ReservationEdit,
				path: pages.marina.reservations.path,
				permission: 'create_marina_reservations',
			},
			{
				name: 'advance',
				button: (
					<Dropdown.Item icon='Plus' key='advance'>
						Advanced Reservation
					</Dropdown.Item>
				),
				isInDropdown: true,
				path: pages.marina.reservations.select.path,
				component: ReservationAdvanceSelect,
				permission: 'create_marina_advanced_reservations',
			},
			{
				name: 'transient',
				component: ReservationAdvanceEdit,
				path: pages.marina.reservations.transient.path,
				hideButton: true,
			},
			{
				name: 'seasonal',
				component: ReservationAdvanceEdit,
				path: pages.marina.reservations.seasonal.path,
				hideButton: true,
			},
			{
				name: 'longTerm',
				component: ReservationAdvanceEdit,
				path: pages.marina.reservations.longTerm.path,
				hideButton: true,
			},
		],
		meterReadings: [
			{
				name: 'batch',
				component: MeterReadBatchEdit,
				path: pages.marina.meterReadings.batch.path,
				button: (
					<Button
						className='sdms-margin-r-5'
						icon='Clipboard-list'
						design='brand'
						outline
						text='Meter Read Batch'
						size='sm'
					/>
				),
			},
			{
				name: 'import',
				button: (
					<ImportButton
						route='meterReadImport'
						text='Meter Read'
						permission='marina_import_meter_reads'
					/>
				),
			},
			{
				name: 'add',
				component: MeterReadingEdit,
				path: pages.marina.meterReadings.path,
			},
		],
		vessels: [
			{
				name: 'import',
				button: (
					<ImportButton route='vesselImport' text='Vessel' permission='import_vessels' />
				),
			},
			{
				name: 'add',
				component: VesselEdit,
				path: pages.marina.vessels.path,
			},
		],
		settings: {
			bookingTypes: [
				{
					name: 'add',
					component: BookingTypeMarinaEdit,
					path: pages.marina.settings.bookingType.path,
				},
			],
			customReservationStatuses: [
				{
					name: 'add',
					component: CustomReservationStatusEdit,
					path: pages.marina.settings.customReservationStatus.path,
				},
			],
			productCategories: [
				{
					name: 'add',
					component: ProductCategoryMarinaEdit,
					path: pages.marina.settings.productCategories.path,
				},
			],
			policies: [
				{
					name: 'add',
					component: MarinaPolicyEdit,
					path: pages.marina.settings.policies.path,
				},
			],
			spaceGroups: [
				{
					name: 'add',
					component: UnitGroupsEdit,
					path: pages.marina.settings.spaceGroups.path,
				},
			],
			extraChargeGroups: [
				{
					name: 'add',
					component: MarinaProductCategoryExtraChargeEdit,
					path: pages.marina.settings.extraChargeGroups.path,
				},
			],
			spaces: [
				{
					name: 'generate',
					component: SpacesGenerateForm,
					button: (
						<Button
							className='sdms-margin-r-5'
							icon='Clipboard-list'
							design='brand'
							outline
							text='Generate Spaces'
							size='sm'
						/>
					),
					isModal: true,
				},
				{
					name: 'add',
					component: SpaceEdit,
					path: pages.marina.settings.spaces.path,
				},
			],
			compositeSpaces: [
				{
					name: 'add',
					component: CompositeSpaceEdit,
					path: pages.marina.settings.compositeSpaces.path,
				},
			],
			spaceBlockouts: [
				{
					name: 'add',
					component: SpaceBlockoutEdit,
					path: pages.marina.settings.spaceBlockouts.path,
				},
			],
			ratePlans: [
				{
					name: 'add',
					component: MarinaRatePlanEdit,
					path: pages.marina.settings.ratePlans.path,
				},
			],
			extraCharges: [
				{
					name: 'add',
					component: MarinaExtraChargeSelect,
					path: pages.marina.settings.extraCharges.path,
				},
				{
					name: 'allOrders',
					component: MarinaExtraChargeOrderEdit,
					path: pages.marina.settings.extraCharges.allOrders.path,
					which: data => data.extraChargeType.value === 'All Orders',
					hideButton: true,
				},
				{
					name: 'offlineOrders',
					component: MarinaExtraChargeOfflineOrderEdit,
					path: pages.marina.settings.extraCharges.offlineOrders.path,
					which: data => data.extraChargeType.value === 'Offline Orders',
					hideButton: true,
				},
				{
					name: 'onlineOrders',
					component: MarinaExtraChargeOnlineOrderEdit,
					path: pages.marina.settings.extraCharges.onlineOrders.path,
					which: data => data.extraChargeType.value === 'Online Orders',
					hideButton: true,
				},
				{
					name: 'perUnit',
					component: MarinaExtraChargePerUnitEdit,
					path: pages.marina.settings.extraCharges.perUnit.path,
					which: data => data.extraChargeType.value === 'Per Unit',
					hideButton: true,
				},
			],
			maps: [
				{
					name: 'add',
					component: MapEdit,
					path: pages.marina.settings.maps.path,
				},
			],
			powerMeterTypes: [
				{
					name: 'add',
					component: PowerMeterTypeEdit,
					path: pages.marina.settings.powerMeterTypes.path,
				},
			],
			powerMeters: [
				{
					name: 'add',
					component: PowerMeterEdit,
					path: pages.marina.settings.powerMeters.path,
				},
			],
			promoCodes: [
				{
					name: 'add',
					component: PromoCodeEdit,
					path: pages.marina.settings.promoCodes.path,
				},
			],
		},
	},
	campground: {
		products: [
			{
				name: 'add',
				component: ProductCampgroundSelect,
				path: pages.campground.products.path,
				permission: 'edit_campground_products',
			},
			{
				name: 'hourly',
				component: ProductCampgroundHourlyEdit,
				path: pages.campground.products.period.hourly.path,
				which: data => data.bookingPeriod.value === 'Hourly',
				hideButton: true,
				permission: 'edit_campground_products',
			},
			{
				name: 'daily',
				component: ProductCampgroundDailyEdit,
				path: pages.campground.products.period.daily.path,
				which: data => data.bookingPeriod.value === 'Daily',
				hideButton: true,
				permission: 'edit_campground_products',
			},
			{
				name: 'nightly',
				component: ProductCampgroundNightlyEdit,
				path: pages.campground.products.period.nightly.path,
				which: data => data.bookingPeriod.value === 'Nightly',
				hideButton: true,
				permission: 'edit_campground_products',
			},
			{
				name: 'longTerm',
				component: ProductCampgroundLongTermEdit,
				path: pages.campground.products.period.longTerm.path,
				which: data => data.bookingPeriod.value === 'Long Term',
				hideButton: true,
				permission: 'edit_campground_products',
			},
			{
				name: 'seasonal',
				component: ProductCampgroundSeasonalEdit,
				path: pages.campground.products.period.seasonal.path,
				which: data => data.bookingPeriod.value === 'Seasonal',
				hideButton: true,
				permission: 'edit_campground_products',
			},
		],
		reservations: [
			{
				name: 'add',
				component: ReservationEdit,
				path: pages.campground.reservations.path,
				permission: 'create_campground_reservations',
			},
			{
				name: 'advance',
				button: (
					<Dropdown.Item icon='Plus' key='advance'>
						Advanced Reservation
					</Dropdown.Item>
				),
				isInDropdown: true,
				path: pages.campground.reservations.select.path,
				component: ReservationAdvanceSelect,
				permission: 'create_campground_advanced_reservations',
			},
			{
				name: 'transient',
				component: ReservationAdvanceEdit,
				path: pages.campground.reservations.transient.path,
				hideButton: true,
			},
			{
				name: 'seasonal',
				component: ReservationAdvanceEdit,
				path: pages.campground.reservations.seasonal.path,
				hideButton: true,
			},
			{
				name: 'longTerm',
				component: ReservationAdvanceEdit,
				path: pages.campground.reservations.longTerm.path,
				hideButton: true,
			},
		],
		meterReadings: [
			{
				name: 'batch',
				component: CampgroundMeterReadBatchEdit,
				path: pages.campground.meterReadings.batch.path,
				button: (
					<Button
						className='sdms-margin-r-5'
						icon='Clipboard-list'
						design='brand'
						outline
						text='Meter Read Batch'
						size='sm'
					/>
				),
			},
			{
				name: 'import',
				button: (
					<ImportButton
						route='meterReadImport'
						text='Meter Read'
						permission='campground_import_meter_reads'
					/>
				),
			},
			{
				name: 'add',
				component: CampgroundMeterReadingEdit,
				path: pages.campground.meterReadings.path,
			},
		],
		vehicles: [
			{
				name: 'add',
				component: CampgroundVehicleEdit,
				path: pages.campground.vehicles.path,
			},
		],
		settings: {
			bookingTypes: [
				{
					name: 'add',
					component: BookingTypeCampgroundEdit,
					path: pages.campground.settings.bookingType.path,
				},
			],
			customReservationStatuses: [
				{
					name: 'add',
					component: CustomReservationStatusEdit,
					path: pages.campground.settings.customReservationStatus.path,
				},
			],
			productCategories: [
				{
					name: 'add',
					component: ProductCategoryCampgroundEdit,
					path: pages.campground.settings.productCategories.path,
				},
			],
			policies: [
				{
					name: 'add',
					component: CampgroundPolicyEdit,
					path: pages.campground.settings.policies.path,
				},
			],
			spaceGroups: [
				{
					name: 'add',
					component: UnitGroupsEdit,
					path: pages.campground.settings.spaceGroups.path,
				},
			],
			extraChargeGroups: [
				{
					name: 'add',
					component: CampgroundProductCategoryExtraChargeEdit,
					path: pages.campground.settings.extraChargeGroups.path,
				},
			],
			spaces: [
				{
					name: 'generate',
					component: CampgroundSpacesGenerateForm,
					button: (
						<Button
							className='sdms-margin-r-5'
							icon='Clipboard-list'
							design='brand'
							outline
							text='Generate Spaces'
							size='sm'
						/>
					),
					isModal: true,
				},
				{
					name: 'add',
					component: CampgroundSpaceEdit,
					path: pages.campground.settings.spaces.path,
				},
			],
			compositeSpaces: [
				{
					name: 'add',
					component: CampgroundCompositeSpaceEdit,
					path: pages.campground.settings.compositeSpaces.path,
				},
			],
			spaceBlockouts: [
				{
					name: 'add',
					component: CampgroundSpaceBlockoutEdit,
					path: pages.campground.settings.spaceBlockouts.path,
				},
			],
			ratePlans: [
				{
					name: 'add',
					component: CampgroundRatePlanEdit,
					path: pages.campground.settings.ratePlans.path,
				},
			],
			extraCharges: [
				{
					name: 'add',
					component: CampgroundExtraChargeSelect,
					path: pages.campground.settings.extraCharges.path,
				},
				{
					name: 'allOrders',
					component: CampgroundExtraChargeOrderEdit,
					path: pages.campground.settings.extraCharges.allOrders.path,
					which: data => data.extraChargeType.value === 'All Orders',
					hideButton: true,
				},
				{
					name: 'offlineOrders',
					component: CampgroundExtraChargeOfflineOrderEdit,
					path: pages.campground.settings.extraCharges.offlineOrders.path,
					which: data => data.extraChargeType.value === 'Offline Orders',
					hideButton: true,
				},
				{
					name: 'onlineOrders',
					component: CampgroundExtraChargeOnlineOrderEdit,
					path: pages.campground.settings.extraCharges.onlineOrders.path,
					which: data => data.extraChargeType.value === 'Online Orders',
					hideButton: true,
				},
				{
					name: 'perUnit',
					component: CampgroundExtraChargePerUnitEdit,
					path: pages.campground.settings.extraCharges.perUnit.path,
					which: data => data.extraChargeType.value === 'Per Unit',
					hideButton: true,
				},
			],
			maps: [
				{
					name: 'add',
					component: MapEdit,
					path: pages.campground.settings.maps.path,
				},
			],
			powerMeterTypes: [
				{
					name: 'add',
					component: CampgroundPowerMeterTypeEdit,
					path: pages.campground.settings.powerMeterTypes.path,
				},
			],
			powerMeters: [
				{
					name: 'add',
					component: CampgroundPowerMeterEdit,
					path: pages.campground.settings.powerMeters.path,
				},
			],
			promoCodes: [
				{
					name: 'add',
					component: PromoCodeEdit,
					path: pages.campground.settings.promoCodes.path,
				},
			],
		},
	},
	messages: [
		{
			name: 'add',
			component: MessageEdit,
			path: pages.messages.default.path,
		},
	],
	analytics: {
		accounting: [
			{
				name: 'add',
				component: AnalyticsDashboardEdit,
				path: pages.analytics.accounting.path,
			},
		],
		booking: [
			{
				name: 'add',
				component: AnalyticsDashboardEdit,
				path: pages.analytics.booking.path,
			},
		],
		campground: [
			{
				name: 'add',
				component: AnalyticsDashboardEdit,
				path: pages.analytics.campground.path,
			},
		],
		crm: [
			{
				name: 'add',
				component: AnalyticsDashboardEdit,
				path: pages.analytics.crm.path,
			},
		],
		marina: [
			{
				name: 'add',
				component: AnalyticsDashboardEdit,
				path: pages.analytics.marina.path,
			},
		],
		pos: [
			{
				name: 'add',
				component: AnalyticsDashboardEdit,
				path: pages.analytics.pos.path,
			},
		],
	},
	reports: {
		// newreporttype
		accounting: [
			{
				name: 'add',
				component: AccountingReportSelect,
				path: pages.reports.accounting.path,
			},
			{
				name: 'deferred_income',
				component: ReportsEdit,
				path: pages.reports.accounting.deferred_income.path,
				which: data => data.type.value === 'deferred_income',
				hideButton: true,
			},
			{
				name: 'general_ledger',
				component: ReportsEdit,
				path: pages.reports.accounting.general_ledger.path,
				which: data => data.type.value === 'general_ledger',
				hideButton: true,
			},
			{
				name: 'payroll',
				component: ReportsEdit,
				path: pages.reports.accounting.payroll.path,
				which: data => data.type.value === 'payroll',
				hideButton: true,
			},
			{
				name: 'settlement_details',
				component: ReportsEdit,
				path: pages.reports.accounting.settlement_details.path,
				which: data => data.type.value === 'settlement_details',
				hideButton: true,
			},
			{
				name: 'deposit_balances',
				component: ReportsEdit,
				path: pages.reports.accounting.deposit_balances.path,
				which: data => data.type.value === 'deposit_balances',
				hideButton: true,
			},
			{
				name: 'reservation_daily_revenue',
				component: ReportsEdit,
				path: pages.reports.accounting.reservation_daily_revenue.path,
				which: data => data.type.value === 'reservation_daily_revenue',
				hideButton: true,
			},
		],
		booking: [
			{
				name: 'add',
				component: BookingReportSelect,
				path: pages.reports.booking.path,
			},
			{
				name: 'reservations',
				component: ReportsEdit,
				path: pages.reports.booking.reservations.path,
				which: data => data.type.value === 'booking_reservations',
				hideButton: true,
			},
		],
		campground: [
			{
				name: 'add',
				component: CampgroundReportSelect,
				path: pages.reports.campground.path,
			},
			{
				name: 'reservations',
				component: ReportsEdit,
				path: pages.reports.campground.reservations.path,
				which: data => data.type.value === 'campground_reservations',
				hideButton: true,
			},
			{
				name: 'rent_roll',
				component: ReportsEdit,
				path: pages.reports.campground.rent_roll.path,
				which: data => data.type.value === 'campground_rent_roll',
				hideButton: true,
			},
		],
		crm: [
			{
				name: 'add',
				component: CrmReportSelect,
				path: pages.reports.crm.path,
			},
			{
				name: 'customers',
				component: ReportsEdit,
				path: pages.reports.crm.customers.path,
				which: data => data.type.value === 'crm_customers',
				hideButton: true,
			},
			{
				name: 'balance_details',
				component: ReportsEdit,
				path: pages.reports.crm.balance_details.path,
				which: data => data.type.value === 'crm_balance_details',
				hideButton: true,
			},
			{
				name: 'invoices',
				component: ReportsEdit,
				path: pages.reports.crm.invoices.path,
				which: data => data.type.value === 'crm_invoices',
				hideButton: true,
			},
			{
				name: 'invoice_details',
				component: ReportsEdit,
				path: pages.reports.crm.invoice_details.path,
				which: data => data.type.value === 'crm_invoice_details',
				hideButton: true,
			},
			{
				name: 'payments',
				component: ReportsEdit,
				path: pages.reports.crm.payments.path,
				which: data => data.type.value === 'crm_payments',
				hideButton: true,
			},
		],
		marina: [
			{
				name: 'add',
				component: MarinaReportSelect,
				path: pages.reports.marina.path,
			},
			{
				name: 'reservations',
				component: ReportsEdit,
				path: pages.reports.marina.reservations.path,
				which: data => data.type.value === 'marina_reservations',
				hideButton: true,
			},
			{
				name: 'rent_roll',
				component: ReportsEdit,
				path: pages.reports.marina.rent_roll.path,
				which: data => data.type.value === 'marina_rent_roll',
				hideButton: true,
			},
			{
				name: 'vessels',
				component: ReportsEdit,
				path: pages.reports.marina.vessels.path,
				which: data => data.type.value === 'marina_vessels',
				hideButton: true,
			},
		],
		pos: [
			{
				name: 'add',
				component: PosReportSelect,
				path: pages.reports.pos.path,
			},
			{
				name: 'zout',
				component: ReportsEdit,
				path: pages.reports.pos.zout.path,
				which: data => data.type.value === 'z_out',
				hideButton: true,
			},
			{
				name: 'orders',
				component: ReportsEdit,
				path: pages.reports.pos.orders.path,
				which: data => data.type.value === 'pos_orders',
				hideButton: true,
			},
			{
				name: 'orderdetails',
				component: ReportsEdit,
				path: pages.reports.pos.orderdetails.path,
				which: data => data.type.value === 'pos_orderdetails',
				hideButton: true,
			},
			{
				name: 'ordermodifiers',
				component: ReportsEdit,
				path: pages.reports.pos.ordermodifiers.path,
				which: data => data.type.value === 'pos_ordermodifiers',
				hideButton: true,
			},
			{
				name: 'tips',
				component: ReportsEdit,
				path: pages.reports.pos.tips.path,
				which: data => data.type.value === 'pos_tips',
				hideButton: true,
			},
		],
	},
	companySettings: {
		company: {
			location: [
				{
					name: 'add',
					component: OutletEdit,
					path: pages.companySettings.company.location.path,
					permission: 'maintain_company',
				},
			],
		},
		companySyncs: [
			{
				name: 'add',
				component: CompanySyncsEdit,
				path: pages.companySettings.companySyncs.path,
			},
		],
		module: [
			{
				name: 'add',
				component: ModuleEdit,
				path: pages.companySettings.module.path,
			},
		],
		paymentProcessors: [
			{
				name: 'add',
				component: PaymentProcessorEdit,
				path: pages.companySettings.paymentProcessors.path,
				installerRequired: true,
			},
		],
		printers: [
			{
				name: 'add',
				component: PrinterEdit,
				path: pages.companySettings.printers.path,
			},
		],
	},
	systemSettings: {
		roles: [
			{
				name: 'add',
				component: RoleEdit,
				path: pages.systemSettings.roles.path,
			},
		],
		users: [
			{
				name: 'add',
				component: UserEdit,
				path: pages.systemSettings.users.path,
				permission: 'edit_users',
			},
		],
		calendar: [
			{
				name: 'add',
				component: CalendarEdit,
				path: pages.systemSettings.calendars.path,
			},
		],
		contracts: [
			{
				name: 'add',
				component: ContractEdit,
				path: pages.systemSettings.contracts.path,
			},
		],
		templates: [
			{
				name: 'add',
				component: TemplateEdit,
				path: pages.systemSettings.templates.path,
			},
		],
		productFilters: [
			{
				name: 'add',
				component: ProductFilterEdit,
				path: pages.systemSettings.productFilters.path,
			},
		],
		metas: [
			{
				name: 'add',
				component: MetaEdit,
				path: pages.systemSettings.metas.path,
			},
		],
		emailAddresses: [
			{
				name: 'add',
				component: EmailAddressesEdit,
				path: pages.systemSettings.emailAddresses.path,
			},
		],
		attachmentTypes: [
			{
				name: 'add',
				component: AttachmentTypesEdit,
				path: pages.systemSettings.attachmentTypes.path,
			},
		],
		seasons: [
			{
				name: 'add',
				component: SeasonEdit,
				path: pages.systemSettings.seasons.path,
			},
		],
	},
};

export default forms;
