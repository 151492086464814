import { discountCalculations, paymentTypes, printStatuses } from '../constants/constants';
import {
	addFloats,
	canApplyServiceFeeToOrderItem,
	getItemTaxCode,
	getPaymentTermByModule,
	numberFormat,
	priceFormatter,
} from './helper';
import { getPaymentProcessor } from '../hooks/usePaymentGateway';

export const calculateOrderTotals = order => {
	const totals = {
		subtotal: 0,
		taxTotal: 0,
		total: 0,
		discountTotal: 0,
		taxDiscount: 0,
	};

	if (!order || order.orderItems.length === 0) return totals;

	let orderTaxableAmount = 0;

	let additionalTax = 0;

	let additionalTaxDiscountAmount = 0;

	// calculate order subtotal.
	order.orderItems.forEach(orderItem => {
		const itemSubtotal = numberFormat(orderItem.subtotal);

		const taxRate = orderItem.taxRate || 0;

		let itemTaxableAmount = itemSubtotal;

		let itemDiscountAmount = 0;

		if (orderItem.discount) {
			const discountInPercent =
				orderItem.discountType.value === 'percent'
					? orderItem.discountAmount
					: (orderItem.discountAmount / itemSubtotal) * 100;

			itemDiscountAmount = numberFormat((itemSubtotal * discountInPercent) / 100);

			if (orderItem.discount.taxCode.taxable) itemTaxableAmount -= itemDiscountAmount;
		}

		totals.subtotal += itemSubtotal - itemDiscountAmount;

		if (taxRate) {
			if (taxRate > order.taxRate.amount)
				additionalTax += numberFormat(
					(itemTaxableAmount * (taxRate - order.taxRate.amount)) / 100
				);

			orderTaxableAmount = addFloats(orderTaxableAmount, itemTaxableAmount);
		}
	});

	if (order.discount && totals.subtotal) {
		const discountInPercent =
			order.discountType.value === 'percent'
				? order.discountAmount
				: (order.discountAmount / totals.subtotal) * 100;

		totals.discountTotal = numberFormat((totals.subtotal * discountInPercent) / 100);

		// if order discount taxable apply discount both order taxable amount and item additional taxes.
		if (order.discount.taxCode.taxable)
			orderTaxableAmount -= numberFormat((orderTaxableAmount * discountInPercent) / 100);

		if (order.discountType.value === 'percent') {
			additionalTaxDiscountAmount = numberFormat((additionalTax * discountInPercent) / 100);

			additionalTax -= additionalTaxDiscountAmount;
		}
	}

	(order.taxRate.taxRates.length === 0 ? [order.taxRate] : order.taxRate.taxRates).forEach(
		taxRate => {
			totals.taxTotal = addFloats(
				totals.taxTotal,
				numberFormat((orderTaxableAmount * taxRate.amount) / 100)
			);
		}
	);

	totals.taxTotal = addFloats(totals.taxTotal, additionalTax);

	totals.discountTotal += additionalTaxDiscountAmount;

	totals.total =
		totals.subtotal + totals.taxTotal - totals.discountTotal + additionalTaxDiscountAmount;

	return totals;
};

export const createOrder = (
	outlet,
	register,
	unit,
	type,
	status,
	server = null,
	customer = null,
	vessel = null
) => {
	return {
		id: 0,
		name: 'New Order',
		orderItems: [],
		note: '',
		seats: register.registerType.requireSeats ? 1 : 0,
		customer: customer || register.defaultCustomer,
		vessel,
		subtotal: 0,
		taxTotal: 0,
		total: 0,
		discountTotal: 0,
		amountInvoiced: 0,
		parentOrder: null,
		status,
		outlet,
		taxRate: register.outlet.settings.taxRate,
		type,
		server,
		timeCreated: new Date().toISOString(),
		timeModified: new Date().toISOString(),
		unit,
		paymentTerm: getPaymentTermByModule(outlet, customer || register.defaultCustome),
		serverClosed: false,
		registerBatch: register.currentRegisterBatch,
		invoices: [],
		nextChildNumber: 2,
	};
};

export const getRelatedOrders = (allOrders, order, includeSelf = false) => {
	if (!order) return [];
	return allOrders.filter(o => {
		if (order.parentOrder) {
			return (
				(o.parentOrder &&
					(order.id !== o.id || includeSelf) &&
					o.parentOrder.id === order.parentOrder.id) ||
				order.parentOrder.id === o.id
			);
		}
		return (
			(o.parentOrder && o.parentOrder.id === order.id) || (o.id === order.id && includeSelf)
		);
	});
};

export const getOrderItemTaxRate = (product, price, taxRate, taxCode) => {
	if (!taxCode.taxable) return 0;

	let _taxRate = taxRate;

	if (product.additionalTaxes) {
		product.additionalTaxes.forEach(at => {
			if (at.type.value === 'fixed') _taxRate += (at.amount / price) * 100;
			else _taxRate += at.amount;
		});
	}

	return _taxRate;
};

export const applyAutoDiscountToItem = (
	customer,
	product,
	quantity,
	subtotal,
	discounts,
	calculations
) => {
	const autoDiscount = {
		discountType: null,
		discountAmount: null,
		discount: null,
	};

	const autoApplyDiscount = discounts.find(
		d =>
			d.eligibleProducts.some(p => p.id === product.id) &&
			d.automaticDiscounts.some(c => c.id === customer.id)
	);

	if (autoApplyDiscount) {
		autoDiscount.discount = autoApplyDiscount;
		autoDiscount.discountType = calculations.find(
			c =>
				c.value ===
				(autoApplyDiscount.calculation.value === discountCalculations.PERCENT
					? discountCalculations.PERCENT
					: discountCalculations.FIXED)
		);

		if (autoApplyDiscount.calculation.value === discountCalculations.PER_QUANTITY)
			autoDiscount.discountAmount = Math.min(subtotal, autoApplyDiscount.amount * quantity);
		else if (autoApplyDiscount.calculation.value === discountCalculations.FIXED)
			autoDiscount.discountAmount = Math.min(subtotal, autoApplyDiscount.amount);
		else autoDiscount.discountAmount = autoApplyDiscount.amount;
	}

	return autoDiscount;
};

export const createOrderItem = (
	id,
	product,
	price,
	printStatus,
	taxRate,
	customer,
	quantity = 1,
	discounts,
	calculations
) => {
	const taxCode = getItemTaxCode(customer.taxCode, product);

	let _quantity = quantity;

	let _price = price;

	if (product.calculateQuantity && product.price) {
		_quantity = parseFloat((_price / product.price).toFixed(5));
		_price = product.price;
	}

	const subtotal = numberFormat(_price * _quantity);

	return {
		id,
		description: null,
		printStatus,
		orderItemModifiers: [],
		name: product.name,
		note: null,
		price: _price,
		quantity: _quantity,
		taxCode,
		taxRate: getOrderItemTaxRate(product, price, taxRate, taxCode),
		subtotal,
		product,
		...applyAutoDiscountToItem(customer, product, _quantity, subtotal, discounts, calculations),
	};
};

export const getOrderItemDescription = orderItem => {
	let description = '';

	const sectionDescriptions = {};

	orderItem.orderItemModifiers.forEach(oim => {
		if (!sectionDescriptions[oim.modifierSection.abbreviation])
			sectionDescriptions[oim.modifierSection.abbreviation] = {
				desc: [],
				total: 0,
			};

		let className = '';

		if (
			oim.printStatus &&
			orderItem.printStatus &&
			orderItem.printStatus.value !== oim.printStatus.value
		) {
			if (oim.printStatus.value === printStatuses.ERROR)
				className = 'sdms-modifier-print-error';
			else if (oim.printStatus.value === printStatuses.SENT)
				className = 'sdms-modifier-print-success';
		}

		sectionDescriptions[oim.modifierSection.abbreviation].desc.push(
			`<span class="${className}">${oim.name}</span>`
		);

		sectionDescriptions[oim.modifierSection.abbreviation].total += numberFormat(
			oim.price * orderItem.quantity
		);
	});

	Object.keys(sectionDescriptions).forEach(abbreviation => {
		const t = abbreviation !== '' ? `<b>${abbreviation}:</b>` : '';
		description += `<div><div>${t}${sectionDescriptions[abbreviation].desc.join(
			', '
		)}</div><div>${
			sectionDescriptions[abbreviation].total === 0
				? '-'
				: priceFormatter(sectionDescriptions[abbreviation].total)
		}</div></div>`;
	});
	return description === '' ? null : description;
};

export const calculateOrderItemTotals = item => {
	const itemTotals = {
		price: 0,
		quantity: 0,
		subtotal: 0,
		discountAmount: 0,
		taxableAmount: 0,
		additionalTax: 0,
		taxRate: 0,
		taxTotal: 0,
		total: 0,
	};

	itemTotals.price = item.price;

	itemTotals.quantity = item.quantity;

	itemTotals.subtotal = numberFormat(item.price * item.quantity);

	itemTotals.taxableAmount = item.taxCode.taxable ? itemTotals.subtotal : 0;

	itemTotals.taxRate = item.taxRate;

	if (item.discount && item.discountType) {
		itemTotals.discountAmount =
			item.discountType.value === 'percent'
				? numberFormat((itemTotals.subtotal * item.discountAmount) / 100)
				: item.discount.amount;

		if (itemTotals.discountAmount > itemTotals.subtotal)
			itemTotals.discountAmount = itemTotals.subtotal;

		if (item.discount.taxCode.taxable && itemTotals.taxableAmount > 0)
			itemTotals.taxableAmount -= itemTotals.discountAmount;
	}

	itemTotals.taxTotal = numberFormat((itemTotals.taxableAmount * item.taxRate) / 100);

	itemTotals.total = itemTotals.subtotal - itemTotals.discountAmount + itemTotals.taxTotal;

	return itemTotals;
};

export const getServiceFeeAmountsByOrder = (order, outlet) => {
	const paymentProcessorCreditCard = getPaymentProcessor(outlet, paymentTypes.CREDIT_CARD);

	const paymentProcessorCheck = getPaymentProcessor(outlet, paymentTypes.CHECK);

	const serviceFeeAmounts = {
		creditCard: paymentProcessorCreditCard?.serviceFeeAmount || 0,
		check: paymentProcessorCheck?.serviceFeeAmount || 0,
	};

	if (!order) return serviceFeeAmounts;

	const serviceFeeAddablePrice = order.orderItems
		.filter(orderItem => canApplyServiceFeeToOrderItem(orderItem, outlet))
		.reduce((partialSum, orderItem) => {
			const orderItemTotals = calculateOrderItemTotals(orderItem);

			return addFloats(partialSum, orderItemTotals.total);
		}, 0);

	const orderTotals = calculateOrderTotals(order);

	const payableServiceFeePercent = serviceFeeAddablePrice
		? serviceFeeAddablePrice / orderTotals.total
		: 0;

	return {
		creditCard: numberFormat(payableServiceFeePercent * serviceFeeAmounts.creditCard),
		check: numberFormat(payableServiceFeePercent * serviceFeeAmounts.check),
	};
};

export const orderItemPrintable = orderItem =>
	!orderItem.printStatus ||
	(orderItem.printStatus.value !== printStatuses.FAKE &&
		orderItem.printStatus.value !== printStatuses.SENT);
