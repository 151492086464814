import React, { useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import update from 'immutability-helper';
import Popup from 'reactjs-popup';

import {
	addErrorNotification,
	addSuccessNotification,
	generateId,
	numberFormat,
	parseOrderItemModifiersData,
	priceFormatter,
	useWindowSize,
} from '../../../utils/helpers/helper';
import { getOrderItemDescription, calculateOrderTotals } from '../../../utils/helpers/orderHelper';
import apiCall, { parseData } from '../../../utils/helpers/apiCall';
import { mediaBreakpoint, orderStatuses } from '../../../utils/constants/constants';

import Button from '../../reusables/element/Button';
import ContentInner from '../../reusables/template/ContentInner';
import Container from '../../reusables/layout/Container';
import { OrderTable, OrderHead, OrderBody, OrderItem } from './Order';
import Toggle from '../../reusables/field/Toggle';
import Portlet from '../../reusables/layout/Portlet';
import Input from '../../reusables/field/Input';
import Portal from '../../reusables/layout/Portal';
import DialogBox from '../../reusables/element/DialogBox';
import TouchSpin from '../../reusables/element/TouchSpin';
import { StatusCell } from './RecallOrders';

// const SplitItem = ({ className, name, quantity, price }) => {
// 	const [check, setCheck] = useState(false);
// 	return (
// 		<tr className={classNames('sdms-t-orders--item', className)}>
// 			<td className='sdms-t-orders--item__check'>
// 				<Checkbox onChange={() => setCheck(!check)} value={check} />
// 			</td>
// 			<td className='sdms-t-orders--item__name'>{name}</td>
// 			<td className='sdms-t-orders--item__quantity'>{quantity}</td>
// 			<td className='sdms-t-orders--item__price'>${price}</td>
// 		</tr>
// 	);
// };
// SplitItem.propTypes = {
// 	className: PropTypes.string,
// 	name: PropTypes.string.isRequired,
// 	quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
// 	price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
// };
// SplitItem.defaultProps = {
// 	className: null,
// };

const DivideModalContainer = ({ isOpen, onClose, divideCount, onChange, onSubmit }) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<DivideModal
					divideCount={divideCount}
					onChange={onChange}
					onClose={onClose}
					onSubmit={onSubmit}
				/>
			</Popup>
		</Portal>
	);
};
DivideModalContainer.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	divideCount: PropTypes.string,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
};
DivideModalContainer.defaultProps = {
	isOpen: false,
	onClose: () => {},
	divideCount: '2',
	onChange: () => {},
	onSubmit: () => {},
};

const DivideModal = ({ divideCount, onChange, onClose, onSubmit }) => {
	const divideCountInputRef = useRef();
	useEffect(() => {
		divideCountInputRef.current.focus();
	}, [divideCountInputRef]);

	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Scissors'>Divide</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<Input
					ref={divideCountInputRef}
					name='divideCount'
					value={divideCount}
					onChange={({ target }) => onChange(target.value)}
					type='number'
				/>
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='brand'
						icon='Clipboard'
						text='Divide'
						size='sm'
						onClick={onSubmit}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
DivideModal.propTypes = {
	divideCount: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

const SplitOrder = ({
	data,
	isActive,
	selectedOrderItems,
	onOrderItemSelect,
	onAllOrderItemsSelect,
	changeActiveOrder,
	deleteOrder,
	openDivideModal,
	combineOrderItems,
	splitEvenly,
	newOrder,
	moveHere,
	willPrint,
	onWillPrintChange,
	canDelete,
	splitOrderCount,
	isSaving,
}) => {
	return (
		<div
			className={classNames(
				'col-xl-3',
				'col-lg-4',
				'col-md-6',
				'col-sm-12',
				'sdms-t-split-item',
				{
					'sdms-t-split-item--active': isActive,
				}
			)}>
			<Portlet fluid='fluid'>
				<Portlet.Head>
					<Portlet.HeadLabel
						portletIcon='Clipboard'
						iconFill={isActive ? 'var(--info)' : null}>
						<h3
							className={classNames('sdms-portlet__head-title', {
								'sdms-pr0 sdms-cursor--pointer sdms-link sdms-link--dark':
									data.status.value === orderStatuses.OPEN,
							})}
							onClick={
								data.status.value === orderStatuses.OPEN ? changeActiveOrder : null
							}
							role='presentation'>
							{data.name}
						</h3>
						{data.status.value !== orderStatuses.OPEN && (
							<>
								<Portlet.Separator />
								<div className='sdms-portlet__head-desc'>
									<StatusCell data={data} size='sm' />
								</div>
							</>
						)}
					</Portlet.HeadLabel>
					{canDelete && (
						<Portlet.HeadToolbarActions>
							<Button
								icon='Trash'
								className='btn-icon'
								label='danger'
								size='sm'
								onClick={deleteOrder}
								disabled={data.status.value !== orderStatuses.OPEN || isSaving}
							/>
						</Portlet.HeadToolbarActions>
					)}
				</Portlet.Head>
				<Portlet.Head className='sdms-portlet__head--fit align-items-center'>
					<OrderTable>
						<OrderHead
							isMultiActionsEnabled={selectedOrderItems.length > 0 && isActive}
							isAllSelected={
								data.orderItems.length > 0 &&
								selectedOrderItems.length === data.orderItems.length &&
								isActive &&
								data.status.value === orderStatuses.OPEN
							}
							onCheckAll={
								data.status.value === orderStatuses.OPEN
									? isCheck => onAllOrderItemsSelect(isCheck)
									: () => {}
							}
							hasActions={false}
							disabled={data.status.value !== orderStatuses.OPEN}>
							<Button
								icon='Scissors'
								bold='bold'
								label='info'
								size='sm'
								onClick={openDivideModal}>
								Divide
							</Button>
							<Button
								icon='Clip'
								bold='bold'
								label='info'
								size='sm'
								disabled={
									!(
										selectedOrderItems.length > 1 &&
										[
											...new Set(
												data.orderItems
													.filter(oi =>
														selectedOrderItems.includes(oi.id)
													)
													.map(oi => oi.product.id)
											),
										].length === 1 &&
										[
											...new Set(
												data.orderItems
													.filter(oi =>
														selectedOrderItems.includes(oi.id)
													)
													.map(oi => oi.note)
											),
										].length === 1 &&
										[
											...new Set(
												data.orderItems
													.filter(oi =>
														selectedOrderItems.includes(oi.id)
													)
													.map(oi => oi.discount)
											),
										].length === 1 &&
										data.orderItems.filter(
											oi =>
												selectedOrderItems.includes(oi.id) &&
												oi.orderItemModifiers.length > 0
										).length === 0
									) ||
									selectedOrderItems.forEach(soi => soi.printStatus === 'New')
								}
								onClick={combineOrderItems}>
								Combine
							</Button>
						</OrderHead>
					</OrderTable>
				</Portlet.Head>
				<Portlet.Body className='sdms-portlet__body--fit'>
					<OrderTable>
						<OrderBody>
							{data.orderItems.map((item, index) => (
								<OrderItem
									key={item.id}
									id={item.id}
									description={item.description}
									orderItemModifiers={item.orderItemModifiers}
									name={item.name}
									note={item.note}
									price={item.price}
									quantity={item.quantity}
									subtotal={item.subtotal}
									hasActions={false}
									index={index}
									onSelect={onOrderItemSelect}
									isSelected={selectedOrderItems.includes(item.id) && isActive}
									isRowSelect={data.status.value === orderStatuses.OPEN}
									discountName={item.discount ? item.discount.name : null}
									discountAmount={item.discountAmount}
									discountType={item.discountType}
									discount={item.discount}
									disabled={data.status.value !== orderStatuses.OPEN}
								/>
							))}
						</OrderBody>
					</OrderTable>
				</Portlet.Body>
				<Portlet.Foot tall='sm'>
					<div className='col-4'>
						<div className='row align-items-center'>
							<div className='col sdms-font-bolder'>Print</div>
							<div className='col-auto d-flex'>
								<Toggle
									className='sdms-t-split-printer'
									size='sm'
									value={willPrint && data.status.value === orderStatuses.OPEN}
									onChange={() => onWillPrintChange(!willPrint)}
								/>
							</div>
						</div>
					</div>
					<div className='col-8'>
						<div className='row sdms-font-lg sdms-font-bolder'>
							<div className='col'>Tax</div>
							<div className='col-auto text-right'>
								{priceFormatter(data.taxTotal)}
							</div>
						</div>
						<div className='row sdms-font-lg sdms-font-bolder'>
							<div className='col'>Total</div>
							<div className='col-auto text-right'>{priceFormatter(data.total)}</div>
						</div>
					</div>
				</Portlet.Foot>
				<Portlet.Foot className='sdms-pt10' border={false}>
					{isActive ? (
						<>
							<div className='col-6'>
								<Button
									label='info'
									icon='Chart-pie'
									block
									bold='bold'
									onClick={splitEvenly}
									disabled={
										splitOrderCount < 2 ||
										selectedOrderItems.length < 1 ||
										data.status.value !== orderStatuses.OPEN
									}>
									Split Evenly
								</Button>
							</div>
							<div className='col-6'>
								<Button
									label='info'
									icon='Clipboard-new'
									block
									bold='bold'
									onClick={newOrder}
									disabled={
										selectedOrderItems.length < 1 ||
										data.status.value !== orderStatuses.OPEN
									}>
									New Order
								</Button>
							</div>
						</>
					) : (
						<div className='col-12'>
							<Button
								label='info'
								icon='Incoming-box'
								block
								bold='bold'
								onClick={moveHere}
								disabled={
									selectedOrderItems.length <= 0 ||
									data.status.value !== orderStatuses.OPEN
								}
								customPermission={{
									status: data.status.value !== orderStatuses.OPEN,
									message: 'Not an open order!',
								}}>
								Move Here
							</Button>
						</div>
					)}
				</Portlet.Foot>
			</Portlet>
		</div>
	);
};
SplitOrder.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		parentOrder: PropTypes.object,
		orderItems: PropTypes.arrayOf(PropTypes.object),
		note: PropTypes.string,
		seats: PropTypes.number,
		customer: PropTypes.object,
		discountTotal: PropTypes.number,
		subtotal: PropTypes.number,
		status: PropTypes.object,
		taxTotal: PropTypes.number,
		total: PropTypes.number,
		server: PropTypes.object,
		timeCreated: PropTypes.string,
		timeModified: PropTypes.string,
		unit: PropTypes.object,
	}),
	isActive: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	selectedOrderItems: PropTypes.array,
	onOrderItemSelect: PropTypes.func,
	onAllOrderItemsSelect: PropTypes.func,
	changeActiveOrder: PropTypes.func,
	deleteOrder: PropTypes.func,
	openDivideModal: PropTypes.func,
	combineOrderItems: PropTypes.func,
	splitEvenly: PropTypes.func,
	newOrder: PropTypes.func,
	moveHere: PropTypes.func,
	willPrint: PropTypes.bool,
	onWillPrintChange: PropTypes.func,
	canDelete: PropTypes.bool,
	splitOrderCount: PropTypes.number,
	isSaving: PropTypes.bool,
};
SplitOrder.defaultProps = {
	data: {
		id: 0,
		name: '',
		parentOrder: null,
		orderItems: [],
		note: null,
		seats: 0,
		customer: null,
		discountTotal: null,
		subtotal: 0,
		taxTotal: 0,
		total: 0,
		server: null,
		timeCreated: null,
		timeModified: null,
		unit: null,
		isLoading: false,
	},
	isActive: false,
	selectedOrderItems: [],
	onOrderItemSelect: () => {},
	onAllOrderItemsSelect: () => {},
	changeActiveOrder: () => {},
	deleteOrder: () => {},
	openDivideModal: () => {},
	combineOrderItems: () => {},
	splitEvenly: () => {},
	newOrder: () => {},
	moveHere: () => {},
	willPrint: true,
	onWillPrintChange: () => {},
	canDelete: true,
	splitOrderCount: 1,
	isSaving: false,
};

const Split = ({ orders, onCancel, onSave, activeOrderId, createOrder, title }) => {
	const windowSize = useWindowSize();

	// we will compare later to capture any change on the orders
	const orderRef = useRef(orders);
	// true means disabled
	const [showCancelDialog, setShowCancelDialog] = useState(false);

	const [isDivideModalOpen, setIsDivideModalOpen] = useState(false);

	const [divideCount, setDivideCount] = useState('2');

	const [moveItemsModalData, setMoveItemsModalData] = useState({
		isOpen: false,
		from: null,
	});

	const [isSaving, setIsSaving] = useState(false);

	const [isReloadModalOpen, setIsReloadModalOpen] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const [orderCount, setOrderCount] = useState(0);

	const onMoveItemsModalClose = () => setMoveItemsModalData({ isOpen: false, from: null });

	const reducer = (state, action) => {
		const { type, payload } = { ...action };

		const getSelectedOrderItems = () =>
			state.orders[state.activeOrderIndex].orderItems.filter(oi =>
				state.selectedOrderItems.includes(oi.id)
			);
		const getOrderItemsWithoutSelected = () =>
			state.orders[state.activeOrderIndex].orderItems.filter(
				oi => !state.selectedOrderItems.includes(oi.id)
			);

		const copyOrderItem = (orderItem, quantity, list) => {
			quantity = numberFormat(quantity, 5);

			if (orderItem.orderItemModifiers) {
				orderItem.orderItemModifiers.forEach((oim, index) => {
					orderItem = update(orderItem, {
						orderItemModifiers: {
							[index]: {
								$unset: ['@id', '@type'],
								$merge: {
									id: generateId(orderItem.orderItemModifiers),
									price: oim.price,
								},
							},
						},
					});

					if (oim.customModifier) {
						orderItem = update(orderItem, {
							orderItemModifiers: {
								[index]: {
									customModifier: {
										$unset: ['@id', '@type'],
										$merge: {
											id: 0,
											price: oim.price,
										},
									},
								},
							},
						});
					}
				});

				orderItem = update(orderItem, {
					description: {
						$set: getOrderItemDescription({ ...orderItem, quantity }),
					},
				});
			}

			if (orderItem.discount && orderItem.discountType.value === 'fixed') {
				orderItem = update(orderItem, {
					discountAmount: {
						$set: (orderItem.discountAmount * quantity) / orderItem.quantity,
					},
				});
			}

			return update(orderItem, {
				$unset: ['@id', '@type'],
				$merge: {
					id: generateId(list),
					subtotal: numberFormat(orderItem.price * quantity),
					quantity,
				},
			});
		};

		const getParentOrder = _orders => {
			let _parentOrder = _orders.find(o => o.parentOrder === null);

			if (_parentOrder) return _parentOrder;

			_orders.every(o => {
				if (o.parentOrder) {
					_parentOrder = o.parentOrder;
					return false;
				}

				return true;
			});

			return _parentOrder;
		};

		if (type === 'set') {
			const activeOrderIndex = payload.findIndex(o => o.id === activeOrderId);

			const parentOrder = getParentOrder(payload);

			return update(state, {
				orders: { $set: payload },
				activeOrderIndex: {
					$set: activeOrderIndex > -1 ? activeOrderIndex : 0,
				},
				selectedOrderItems: { $set: [] },
				willPrintOrders: {
					$set: payload.filter(o => o.status.value === orderStatuses.OPEN).map(o => o.id),
				},
				mainOrderId: {
					$set:
						parentOrder.status && parentOrder.status.value === orderStatuses.OPEN
							? parentOrder.id
							: payload.find(o => o.status.value === orderStatuses.OPEN).id,
				},
				parentOrder: { $set: parentOrder },
			});
		}

		if (type === 'changeActiveOrder') {
			return update(state, {
				activeOrderIndex: { $set: payload },
				selectedOrderItems: { $set: [] },
			});
		}

		if (type === 'createOrder') {
			const orderId = generateId(state.orders);

			return update(state, {
				orders: {
					$push: [
						{
							...createOrder(),
							id: orderId,
							customer: state.parentOrder.customer,
							server: state.parentOrder.server,
							name: `${state.parentOrder.name} - ${state.parentOrder.nextChildNumber}`,
							number: state.parentOrder.nextChildNumber,
							parentOrder: { id: state.parentOrder.id },
							unit: state.parentOrder.unit,
							seats: 0,
						},
					],
				},
				willPrintOrders: { $push: [orderId] },
				parentOrder: { nextChildNumber: { $set: state.parentOrder.nextChildNumber + 1 } },
			});
		}

		if (type === 'deleteOrder') {
			const sourceOrderIndex = state.orders.findIndex(o => o.id === payload.from);

			if (sourceOrderIndex === -1) return state;

			let nextActiveOrder = state.activeOrderIndex > 0 ? state.activeOrderIndex - 1 : 0;

			if (typeof payload.to !== 'undefined') {
				// set target order as active order.
				const toOrderIndex = state.orders.findIndex(o => o.id === payload.to);
				if (
					sourceOrderIndex === state.activeOrderIndex &&
					state.orders.length >= sourceOrderIndex + 2
				)
					nextActiveOrder = sourceOrderIndex;

				// generate order item id and move.
				state.orders[sourceOrderIndex].orderItems.forEach(oi => {
					state = update(state, {
						orders: {
							[toOrderIndex]: {
								orderItems: {
									$push: [
										copyOrderItem(
											oi,
											oi.quantity,
											state.orders[toOrderIndex].orderItems
										),
									],
								},
							},
						},
					});
				});

				// recalculate order totals.
				state = update(state, {
					orders: {
						[toOrderIndex]: {
							$merge: calculateOrderTotals(state.orders[toOrderIndex]),
						},
					},
				});
			}
			// remove source order.
			return update(state, {
				orders: {
					$splice: [[sourceOrderIndex, 1]],
					$apply: _orders => {
						if (
							_orders.findIndex(o => o.id === state.parentOrder.id) > -1 ||
							state.parentOrder.id !== 0
						)
							return _orders;

						return _orders.map((order, i) => {
							order.parentOrder = i === 0 ? null : { id: _orders[0].id };

							return order;
						});
					},
				},
				activeOrderIndex: {
					$set:
						state.activeOrderIndex === sourceOrderIndex
							? nextActiveOrder
							: state.activeOrderIndex,
				},
				willDeleteOrders: {
					$push:
						state.orders[sourceOrderIndex].id > 0
							? [state.orders[sourceOrderIndex].id]
							: [],
				},
				selectedOrderItems: {
					$set:
						state.activeOrderIndex === sourceOrderIndex ? [] : state.selectedOrderItems,
				},
				willPrintOrders: {
					$set: state.willPrintOrders.filter(
						oi => oi !== state.orders[sourceOrderIndex].id
					),
				},
				parentOrder: {
					$set:
						state.parentOrder.id === payload.from && payload.from === 0
							? {
									...state.orders[1],
									nextChildNumber: state.parentOrder.nextChildNumber,
							  }
							: state.parentOrder,
				},
			});
		}

		// select order item.
		if (type === 'selectOrderItem') {
			if (state.activeOrderIndex !== payload.orderIndex)
				return update(state, {
					activeOrderIndex: { $set: payload.orderIndex },
					selectedOrderItems: { $set: [payload.orderItemId] },
				});

			if (state.selectedOrderItems.includes(payload.orderItemId)) return state;

			return update(state, { selectedOrderItems: { $push: [payload.orderItemId] } });
		}

		// select all order items.
		if (type === 'selectAllOrderItems') {
			if (state.activeOrderIndex !== payload)
				return update(state, {
					activeOrderIndex: { $set: payload },
					selectedOrderItems: {
						$set: state.orders[payload].orderItems.map(oi => oi.id),
					},
				});

			return update(state, {
				selectedOrderItems: {
					$set: state.orders[state.activeOrderIndex].orderItems.map(oi => oi.id),
				},
			});
		}

		// unselect order item.
		if (type === 'unSelectOrderItem') {
			if (!state.selectedOrderItems.includes(payload.orderItemId)) return state;

			return update(state, {
				selectedOrderItems: {
					$set: state.selectedOrderItems.filter(soi => soi !== payload.orderItemId),
				},
			});
		}

		// unselect all order items.
		if (type === 'unSelectAllOrderItems') {
			return update(state, {
				selectedOrderItems: {
					$set: [],
				},
			});
		}

		if (type === 'divideOrderItems') {
			if (state.selectedOrderItems.length === 0) return state;

			getSelectedOrderItems().forEach(orderItem => {
				// We found the index for divide operation.
				const index = state.orders[state.activeOrderIndex].orderItems.findIndex(
					element => element === orderItem
				);
				for (let i = 0; i < payload; i += 1) {
					state = update(state, {
						orders: {
							[state.activeOrderIndex]: {
								orderItems: {
									// insert the new order items to the state
									$splice: [
										[
											index + i,
											0,
											copyOrderItem(
												orderItem,
												orderItem.quantity / payload,
												state.orders[state.activeOrderIndex].orderItems
											),
										],
									],
								},
							},
						},
					});
				}
			});

			state = update(state, {
				orders: {
					[state.activeOrderIndex]: {
						orderItems: {
							$set: getOrderItemsWithoutSelected(),
						},
					},
				},
				selectedOrderItems: { $set: [] },
			});

			return update(state, {
				orders: {
					[state.activeOrderIndex]: {
						$merge: calculateOrderTotals(state.orders[state.activeOrderIndex]),
					},
				},
			});
		}

		if (type === 'combineOrderItems') {
			if (state.selectedOrderItems.length === 0) return state;

			const selectedOrderItems = getSelectedOrderItems();

			let quantity = 0;

			// calculate new quantity and subtotal.
			selectedOrderItems.forEach(orderItem => {
				quantity += orderItem.quantity;
			});

			// add new order item.
			state = update(state, {
				orders: {
					[state.activeOrderIndex]: {
						orderItems: {
							$push: [
								copyOrderItem(
									selectedOrderItems[0],
									quantity,
									state.orders[state.activeOrderIndex].orderItems
								),
							],
						},
					},
				},
			});

			state = update(state, {
				orders: {
					[state.activeOrderIndex]: {
						orderItems: {
							$set: getOrderItemsWithoutSelected(),
						},
					},
				},
				selectedOrderItems: { $set: [] },
			});

			return update(state, {
				orders: {
					[state.activeOrderIndex]: {
						$merge: calculateOrderTotals(state.orders[state.activeOrderIndex]),
					},
				},
			});
		}

		if (type === 'splitEvenly') {
			if (state.selectedOrderItems.length === 0) return state;

			const openOrdersLength = state.orders.filter(o => o.status.value === orderStatuses.OPEN)
				.length;

			getSelectedOrderItems().forEach(orderItem => {
				// add new order item to all orders except active one.
				state.orders.forEach((o, index) => {
					if (o.status.value === orderStatuses.OPEN)
						state = update(state, {
							orders: {
								[index]: {
									orderItems: {
										$push: [
											copyOrderItem(
												orderItem,
												orderItem.quantity / openOrdersLength,
												state.orders[index].orderItems
											),
										],
									},
								},
							},
						});
				});
			});

			// remove moved order items from active order.
			state = update(state, {
				orders: {
					[state.activeOrderIndex]: {
						orderItems: {
							$set: getOrderItemsWithoutSelected(),
						},
					},
				},
			});

			// update totals of all orders.
			state.orders.forEach((o, index) => {
				if (o.status.value === orderStatuses.OPEN)
					state = update(state, {
						orders: { [index]: { $merge: calculateOrderTotals(state.orders[index]) } },
					});
			});

			// clear selected order items.
			return update(state, { selectedOrderItems: { $set: [] } });
		}

		if (type === 'moveOrderItemsToNewOrder') {
			if (state.selectedOrderItems.length === 0) return state;

			const orderId = generateId(state.orders);

			// add new order to orders.
			state = update(state, {
				orders: {
					$push: [
						{
							...createOrder(),
							id: orderId,
							name: `${state.parentOrder.name} - ${state.parentOrder.nextChildNumber}`,
							customer: state.parentOrder.customer,
							server: state.parentOrder.server,
							number: state.parentOrder.nextChildNumber,
							parentOrder: { id: state.parentOrder.id },
							unit: state.parentOrder.unit,
							seats: 0,
						},
					],
				},
				parentOrder: { nextChildNumber: { $set: state.parentOrder.nextChildNumber + 1 } },
			});

			const orderIndex = state.orders.findIndex(o => o.id === orderId);

			// move order items with new id to new order.
			getSelectedOrderItems().forEach(orderItem => {
				state = update(state, {
					orders: {
						[orderIndex]: {
							orderItems: {
								$push: [
									copyOrderItem(
										orderItem,
										orderItem.quantity,
										state.orders[orderIndex].orderItems
									),
								],
							},
						},
					},
				});
			});

			// remove moved order items from active one.
			state = update(state, {
				orders: {
					[state.activeOrderIndex]: {
						orderItems: {
							$set: getOrderItemsWithoutSelected(),
						},
					},
				},
			});

			// push new order with totals and recalculate active order totals.
			return update(state, {
				orders: {
					[orderIndex]: { $merge: calculateOrderTotals(state.orders[orderIndex]) },
					[state.activeOrderIndex]: {
						$merge: calculateOrderTotals(state.orders[state.activeOrderIndex]),
					},
				},
				selectedOrderItems: { $set: [] },
				willPrintOrders: { $push: [orderId] },
			});
		}

		if (type === 'moveHere') {
			if (state.selectedOrderItems.length === 0) return state;

			// move order items to target order with new id.
			getSelectedOrderItems().forEach(orderItem => {
				state = update(state, {
					orders: {
						[payload]: {
							orderItems: {
								$push: [
									copyOrderItem(
										orderItem,
										orderItem.quantity,
										state.orders[payload].orderItems
									),
								],
							},
						},
					},
				});
			});

			// remove moved order items from source order.
			state = update(state, {
				orders: {
					[state.activeOrderIndex]: {
						orderItems: { $set: getOrderItemsWithoutSelected() },
					},
				},
			});

			// recalculate both order's totals.
			return update(state, {
				orders: {
					[payload]: { $merge: calculateOrderTotals(state.orders[payload]) },
					[state.activeOrderIndex]: {
						$merge: calculateOrderTotals(state.orders[state.activeOrderIndex]),
					},
				},
				selectedOrderItems: { $set: [] },
			});
		}

		if (type === 'toggleWillPrint') {
			if (payload.willPrint && !state.willPrintOrders.includes(payload.orderId))
				return update(state, { willPrintOrders: { $push: [payload.orderId] } });

			if (!payload.willPrint)
				return update(state, {
					willPrintOrders: {
						$set: state.willPrintOrders.filter(oi => oi !== payload.orderId),
					},
				});
		}

		return state;
	};

	const save = () => {
		const _orders = JSON.parse(JSON.stringify(split.orders));

		_orders.forEach(o => {
			o.orderItems = o.orderItems.map(oi => {
				if (oi.orderItemModifiers)
					oi.orderItemModifiers = parseOrderItemModifiersData(oi.orderItemModifiers);
				return oi;
			});

			Object.keys(o).forEach(field => {
				o[field] = parseData(o[field]);
			});

			if (o.id === split.parentOrder.id)
				o.nextChildNumber = split.parentOrder.nextChildNumber;
		});

		setIsSaving(true);

		apiCall(
			'POST',
			'splitSave',
			res => {
				setIsSaving(false);

				addSuccessNotification('Order(s) successfully saved');

				onSave({
					orders: res.orders,
					deletedOrders: split.willDeleteOrders,
					willPrintOrders: split.willPrintOrders.map(
						orderId => res.orders[split.orders.findIndex(o => o.id === orderId)].id
					),
				});
			},
			err => {
				if (
					err.toString().includes('override') ||
					err.toString().includes('Item not found for')
				)
					setIsReloadModalOpen(true);
				else addErrorNotification(err.toString());

				setIsSaving(false);
			},
			'',
			{ orders: _orders, willDeleteOrders: split.willDeleteOrders }
		);
	};

	const reloadOrders = () => {
		setIsLoading(true);

		setIsReloadModalOpen(false);

		addSuccessNotification('Reloading orders...');

		apiCall(
			'POST',
			'splitReload',
			res => {
				dispatch({
					type: 'set',
					payload: res.orders.sort((a, b) => a.id - b.id),
				});

				onSave(
					{
						orders: res.orders,
						deletedOrders: split.orders
							.filter(o => res.orders.findIndex(_o => _o.id === o.id) === -1)
							.map(o => o.id),
					},
					false
				);

				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ orderId: split.parentOrder.id }
		);
	};

	const [split, dispatch] = useReducer(reducer, {
		orders: [],
		activeOrderIndex: null,
		selectedOrderItems: [],
		willPrintOrders: [],
		willDeleteOrders: [],
		mainOrderId: null,
		parentOrder: { name: 'New Order' },
	});

	useEffect(() => {
		dispatch({
			type: 'set',
			payload: orders.sort((a, b) => a.id - b.id),
		});
		setOrderCount(orders.length);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onCancelClick = () => {
		if (orderRef.current !== split.orders) setShowCancelDialog(true);
		else onCancel();
	};

	// This function controls the input of order counter.
	const onChangeOrderInput = value => {
		let counter = 0;
		setOrderCount(value);
		if (parseFloat(value) > split.orders.length) {
			for (let i = 0; i < value - split.orders.length; i += 1) {
				dispatch({ type: 'createOrder' });
			}
		} else if (parseFloat(value) < split.orders.length) {
			for (let i = split.orders.length; i > value; i -= 1) {
				if (split.orders[i - 1].orderItems.length < 1) {
					dispatch({ type: 'deleteOrder', payload: { from: split.orders[i - 1].id } });
					counter += 1;
				}
			}
			setOrderCount(split.orders.length - counter);
		}
	};

	const _splitActions = (
		<>
			<Button
				design='default'
				text='Cancel'
				onClick={onCancelClick}
				disabled={isSaving || isLoading}
			/>
			<Button
				className={classNames('sdms-mw-105 justify-content-center', {
					'sdms-fading-dots': false,
				})}
				design='primary'
				text={isSaving ? 'Saving...' : 'Save & Close'}
				icon='Save'
				onClick={save}
				disabled={isSaving || isLoading}
			/>
		</>
	);

	return (
		<>
			<Container.Content>
				<ContentInner.SubHeader>
					<ContentInner.SubHeaderItem>
						<ContentInner.SubHeaderTitle
							title={split.parentOrder.name}
							icon='Clipboard'
						/>
						<ContentInner.SubHeaderSeparator
							className={classNames({
								'mr-auto': windowSize.width <= mediaBreakpoint.SM,
							})}
						/>
						{windowSize.width <= mediaBreakpoint.SM && (
							<ContentInner.SubHeaderGroup>
								<ContentInner.SubHeaderDesc className='d-flex align-items-center sdms-mr-0'>
									{_splitActions}
								</ContentInner.SubHeaderDesc>
							</ContentInner.SubHeaderGroup>
						)}
						<ContentInner.SubHeaderGroup>
							<ContentInner.SubHeaderDesc className='d-flex align-items-center'>
								Ordered to
								<span className='sdms-ml-10 sdms-mr-10'>
									<TouchSpin
										onChange={({ target }) =>
											onChangeOrderInput(
												target.value > 99 ? 99 : target.value
											)
										}
										value={orderCount}
										minusOnClick={() => {
											onChangeOrderInput(parseFloat(orderCount) - 1);
										}}
										plusOnClick={() => {
											onChangeOrderInput(
												orderCount > 98
													? parseFloat(orderCount)
													: parseFloat(orderCount) + 1
											);
										}}
									/>
								</span>
								<b>Total:</b>{' '}
								{priceFormatter(
									split.orders.length > 0
										? split.orders.map(o => o.total).reduce((a, b) => a + b)
										: 0
								)}
							</ContentInner.SubHeaderDesc>
						</ContentInner.SubHeaderGroup>
					</ContentInner.SubHeaderItem>
					{windowSize.width > mediaBreakpoint.SM && (
						<ContentInner.SubHeaderItem type='toolbar'>
							{_splitActions}
						</ContentInner.SubHeaderItem>
					)}
				</ContentInner.SubHeader>
				<ContentInner.Container title={title}>
					<div className='row sdms-t-split-container h-100'>
						{split.orders.map((order, index) => (
							<SplitOrder
								key={order.id}
								data={order}
								isActive={split.activeOrderIndex === index}
								selectedOrderItems={split.selectedOrderItems}
								index={index}
								onOrderItemSelect={(isSelected, orderItemId) =>
									dispatch({
										type: isSelected ? 'selectOrderItem' : 'unSelectOrderItem',
										payload: { orderItemId, orderIndex: index },
									})
								}
								onAllOrderItemsSelect={isSelected =>
									dispatch({
										type: isSelected
											? 'selectAllOrderItems'
											: 'unSelectAllOrderItems',
										payload: index,
									})
								}
								changeActiveOrder={() =>
									dispatch({
										type: 'changeActiveOrder',
										payload: index,
									})
								}
								canDelete={
									split.orders.filter(o => o.status.value === orderStatuses.OPEN)
										.length > 1
								}
								deleteOrder={() => {
									setOrderCount(split.orders.length - 1);
									if (!order.orderItems.length) {
										dispatch({
											type: 'deleteOrder',
											payload: { from: order.id },
										});
									} else if (split.orders.length > 2) {
										setMoveItemsModalData({
											isOpen: true,
											from: order.id,
										});
									} else {
										dispatch({
											type: 'deleteOrder',
											payload: {
												from: order.id,
												to:
													index === 0
														? split.orders[1].id
														: split.orders[0].id,
											},
										});
									}
								}}
								openDivideModal={() => setIsDivideModalOpen(true)}
								combineOrderItems={() => dispatch({ type: 'combineOrderItems' })}
								splitEvenly={() => dispatch({ type: 'splitEvenly' })}
								newOrder={() => {
									dispatch({ type: 'moveOrderItemsToNewOrder' });
									setOrderCount(parseInt(orderCount, 10) + 1);
								}}
								moveHere={() => dispatch({ type: 'moveHere', payload: index })}
								willPrint={split.willPrintOrders.includes(order.id)}
								onWillPrintChange={willPrint =>
									dispatch({
										type: 'toggleWillPrint',
										payload: { willPrint, orderId: order.id },
									})
								}
								splitOrderCount={split.orders.length}
								isSaving={isSaving}
							/>
						))}
						<div className='col-xl-4 col-lg-5 col-md-6 col-sm-12 sdms-t-split-item'>
							<div className='row align-items-center h-100'>
								<div className='col-9'>
									<Button
										label='info'
										icon='Clipboard-new'
										iconSize={36}
										block
										bold='bold'
										size='lg'
										onClick={() => {
											if (split.selectedOrderItems.length > 0)
												dispatch({ type: 'moveOrderItemsToNewOrder' });
											else dispatch({ type: 'createOrder' });
											setOrderCount(split.orders.length + 1);
										}}>
										New Order
									</Button>
								</div>
							</div>
						</div>
					</div>
				</ContentInner.Container>
			</Container.Content>
			<DivideModalContainer
				isOpen={isDivideModalOpen}
				divideCount={divideCount}
				onChange={value => {
					if (value === '') {
						setDivideCount('');
						return;
					}
					if (parseFloat(value) < 1) {
						setDivideCount(divideCount);
					} else if (parseFloat(value) > 99) setDivideCount('99');
					else setDivideCount(value);
				}}
				onClose={() => {
					setDivideCount('2');
					setIsDivideModalOpen(false);
				}}
				onSubmit={() => {
					if (divideCount !== '') {
						dispatch({ type: 'divideOrderItems', payload: parseInt(divideCount, 10) });
						setIsDivideModalOpen(false);
						setDivideCount('2');
					}
				}}
			/>
			<Portal>
				<Popup
					open={moveItemsModalData.isOpen}
					closeOnDocumentClick={false}
					lockScroll
					modal
					onClose={onMoveItemsModalClose}
					contentStyle={{
						padding: 0,
						background: 'unset',
						border: 'unset',
						maxWidth: '640px',
					}}>
					<Portlet>
						<Portlet.Head>
							<Portlet.HeadLabelTitle portletIcon='Incoming-box'>
								Move items to:
							</Portlet.HeadLabelTitle>
						</Portlet.Head>
						<Portlet.Body>
							<div className='row'>
								{split.orders
									.filter(o => o.status.value === orderStatuses.OPEN)
									.map(o => {
										if (o.id === moveItemsModalData.from) return null;

										return (
											<div className='col-3' key={o.id}>
												<Button
													className='sdms-mb-20'
													label='brand'
													icon='Clipboard'
													text={o.name}
													size='sm'
													block
													onClick={() => {
														dispatch({
															type: 'deleteOrder',
															payload: {
																from: moveItemsModalData.from,
																to: o.id,
															},
														});
														onMoveItemsModalClose();
													}}
												/>
											</div>
										);
									})}
							</div>
						</Portlet.Body>
						<Portlet.Foot className='sdms-align-left' tall='sm'>
							<div className='col'>
								<Button
									design='clean'
									text='Cancel'
									icon='Error-circle'
									size='sm'
									elevate
									onClick={onMoveItemsModalClose}
								/>
							</div>
						</Portlet.Foot>
					</Portlet>
				</Popup>
			</Portal>
			<DialogBox
				open={isReloadModalOpen}
				title=''
				content='Orders have been changed since you opened it.'
				type='question'
				onClose={() => setIsReloadModalOpen(false)}>
				<Button
					className='sdms-font-transform-c'
					label='success'
					text='Reload'
					onClick={reloadOrders}
				/>
			</DialogBox>
			<DialogBox
				open={showCancelDialog}
				title=''
				content='You changed something on the orders. Would you like to cancel?'
				type='question'
				onClose={() => setShowCancelDialog(false)}>
				<Button
					className='sdms-font-transform-c'
					text='Cancel without saving'
					label='danger'
					icon='Angle-left-circle'
					onClick={onCancel}
				/>
				<Button
					className='sdms-font-transform-c'
					text='Continue editing'
					design='clean'
					icon='Edit'
					onClick={() => setShowCancelDialog(false)}
				/>
			</DialogBox>
		</>
	);
};
Split.propTypes = {
	orders: PropTypes.arrayOf(PropTypes.object).isRequired,
	onCancel: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	activeOrderId: PropTypes.number.isRequired,
	createOrder: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
};

export default Split;
