import React from 'react';
import moment from 'moment/moment';
import { ReactSVG } from 'react-svg';
import { getItemTaxCode, getItemTotalTaxAmount, numberFormat } from './helper';
import { bookingCalculations, bookingPeriods, depositTypes } from '../constants/constants';
import { canApplyExtraCharge, getItemBookingDays } from './reservationHelper';
import bg from '../../assets/img/bg.svg';

export const getTaxTotal = (tax, extraTax) => {
	let totalTax = tax;
	extraTax.forEach(category =>
		category.forEach(et => {
			totalTax += et;
		})
	);
	return totalTax;
};

export const getExtraChargeTotal = extraCharge => {
	if (extraCharge.length)
		return extraCharge
			.reduce((a, b) => a.concat(b)) // flatten
			.reduce((a, b) => (a === -1 ? 0 : a) + (b === -1 ? 0 : b), 0); // sum

	return 0;
};

export const getTotalIsTaxExtraCharges = (extra, extraCharge) => {
	let totalIsTax = 0;

	extra.forEach((category, c) => {
		category.forEach((ext, e) => {
			if (ext.isTax) totalIsTax += extraCharge[c][e];
		});
	});

	return totalIsTax;
};

export const getItemTotal = (price, extraCharge, tax, extraTax) =>
	price + getExtraChargeTotal(extraCharge) + numberFormat(getTaxTotal(tax, extraTax));

export const getReservationExtraChargePeriod = (
	reservationItemQuantity,
	reservationItemBookingPeriod,
	extraChargeBookingCalculation,
	extraChargeBookingPeriod
) => {
	if (!extraChargeBookingPeriod) return reservationItemQuantity;

	let period = reservationItemQuantity;

	if (
		extraChargeBookingCalculation === bookingCalculations.PER_PERIOD ||
		extraChargeBookingCalculation === bookingCalculations.PER_QUANTITY_PER_PERIOD
	) {
		if (
			reservationItemBookingPeriod === bookingPeriods.DAILY &&
			extraChargeBookingPeriod === bookingPeriods.NIGHTLY
		)
			period -= 1;
		else if (
			reservationItemBookingPeriod === bookingPeriods.NIGHTLY &&
			extraChargeBookingPeriod === bookingPeriods.DAILY
		)
			period += 1;
	}

	return period;
};

export const addExtraChargeToItem = (
	reservationItem,
	extraCharge,
	customer,
	taxRate,
	extraCharges
) => {
	// Check item has already extra charge
	if (
		reservationItem.extra.filter(
			category => category.filter(c => c.id === extraCharge.id).length > 0
		).length > 0
	)
		return null;

	let price = calculateExtraChargePrice(reservationItem.product, extraCharge, extraCharges);

	if (!price) return null;

	const { minPrice, maxPrice } = extraCharge;

	if (minPrice && price < minPrice) price = minPrice;

	if (maxPrice && price > maxPrice) price = maxPrice;

	const taxCode = getItemTaxCode(customer ? customer.taxCode : null, extraCharge);

	let tax = 0;

	if (taxCode.taxable && price) {
		const itemTaxRate = getItemTotalTaxAmount(extraCharge, taxRate, price);

		tax = numberFormat((price * itemTaxRate) / 100, 2);
	}

	if (!reservationItem.extra[0]) {
		reservationItem.extra[0] = [];
		reservationItem.extraCharge[0] = [];
		reservationItem.extraTax[0] = [];
		reservationItem.extraTaxCode[0] = [];
	}

	reservationItem.extra[0].push({
		...extraCharge,
		bookingCalculation: extraCharge.bookingCalculation.value,
		bookingPeriod: extraCharge.bookingPeriod?.value,
		type: extraCharge.extraChargeType.value,
	});

	const index = reservationItem.extra[0].findIndex(ec => ec.id === extraCharge.id);

	reservationItem.extraCharge[0][index] = price;

	reservationItem.extraTax[0][index] = tax;

	reservationItem.extraTaxCode[0][index] = taxCode.id;

	return reservationItem;
};

export const addReservationExtraCharge = (
	reservationExtraCharges,
	extraCharge,
	customer,
	taxRate,
	totals
) => {
	if (
		reservationExtraCharges.some(_extraCharge => _extraCharge.extraCharge.id === extraCharge.id)
	)
		return null;

	let amount = extraCharge.price;

	if (extraCharge.bookingCalculation.value === bookingCalculations.PERCENT)
		amount = (totals.subtotalWithoutExtraCharges * extraCharge.price) / 100;

	const taxCode = getItemTaxCode(customer ? customer.taxCode : null, extraCharge);

	let tax = 0;

	if (taxCode.taxable && amount) {
		const itemTaxRate = getItemTotalTaxAmount(extraCharge, taxRate, amount);

		tax = numberFormat((amount * itemTaxRate) / 100, 2);
	}

	reservationExtraCharges.push({
		id: 0,
		extraCharge,
		quantity: 1,
		amount,
		tax,
		total: numberFormat(tax + amount),
		taxCode,
	});

	return reservationExtraCharges;
};

export const itemToExtras = reservationItem => {
	let retVal = [];

	reservationItem.extra.forEach((category, c) => {
		retVal = retVal.concat(
			category.map((extra, e) => {
				return reservationItem.extraCharge[c][e] !== -1
					? {
							id: extra.id,
							quantity:
								extra.bookingCalculation === 'percent' || extra.price === 0
									? 1
									: reservationItem.extraCharge[c][e] /
									  extra.price /
									  (extra.bookingCalculation ===
											bookingCalculations.PER_PERIOD ||
									  extra.bookingCalculation ===
											bookingCalculations.PER_QUANTITY_PER_PERIOD
											? getReservationExtraChargePeriod(
													reservationItem.quantity,
													reservationItem.product.bookingPeriod.value,
													extra.bookingCalculation,
													extra.bookingPeriod
											  )
											: 1),
							tax: reservationItem.extraTax[c][e],
							taxCode: reservationItem.extraTaxCode[c][e],
							total:
								reservationItem.extraCharge[c][e] + reservationItem.extraTax[c][e],
					  }
					: null;
			})
		);
	});

	return retVal;
};

export const getPricesWithExtraCharges = (
	extras,
	prices,
	includeFees,
	includePromoCode,
	appliedPromoCode,
	fromDate
) => {
	try {
		const [itemBookingDays, itemBookingDates] = getItemBookingDays(fromDate, prices);

		return prices.map((price, index) => {
			extras.forEach(category => {
				category.forEach(extra => {
					if (
						canApplyExtraCharge(
							extra,
							itemBookingDates[index],
							itemBookingDays[index],
							includeFees,
							includePromoCode,
							index
						)
					) {
						switch (
							typeof extra.bookingCalculation === 'object'
								? extra.bookingCalculation.value
								: extra.bookingCalculation
						) {
							case bookingCalculations.FIXED:
								price = extra.price;
								break;
							case bookingCalculations.PERCENT:
								price += (price * extra.price) / 100;
								break;
							case bookingCalculations.PER_PERIOD:
								price += extra.price;
								break;
							case bookingCalculations.PER_QUANTITY_PER_PERIOD:
								price += extra.multiSelectQuantity * extra.price;
								break;
							default:
								price += extra.price;
								break;
						}
					}
				});
			});

			return Math.max(price, 0);
		});
	} catch (e) {
		return prices;
	}
};

export const getItemDepositAmount = (
	product,
	price,
	tax,
	extrasCharges,
	extraChargeAmounts,
	extraTaxes,
	appliedPromoCode
) => {
	let depositAmount = 0;

	const today = moment();

	const totalPrice = getItemTotal(price, extraChargeAmounts, tax, extraTaxes);

	const fromDate =
		product.dateFrom && product.dateFrom.date ? moment(product.dateFrom.date) : null;

	const pricesWithExtraCharges = getPricesWithExtraCharges(
		extrasCharges,
		product.pricing.prices[1],
		product.includeFees,
		product.includePromoCode,
		appliedPromoCode,
		fromDate
	);

	if (
		product.fullPaymentDaysPrior &&
		fromDate &&
		fromDate.diff(today, 'days') < product.fullPaymentDaysPrior
	) {
		depositAmount = totalPrice;
	} else if (product.depositType === depositTypes.PERCENT)
		depositAmount = (totalPrice * product.depositAmount) / 100;
	else if (product.depositType === depositTypes.FIXED) depositAmount = product.depositAmount;
	else if (product.depositType === depositTypes.PER_BLOCK) {
		const numBlocks =
			product.depositAmount <= product.pricing.prices[1].length
				? product.depositAmount
				: product.pricing.prices[1].length;

		for (let i = 0; i < numBlocks; i += 1) {
			depositAmount += pricesWithExtraCharges.length
				? pricesWithExtraCharges[i]
				: product.pricing.prices[1];
		}
	}

	return numberFormat(
		!product.isSecurityDeposit && depositAmount > totalPrice ? totalPrice : depositAmount
	);
};

export const getExtraChargeApplicablePrice = (
	product,
	productPrice,
	extraCharge,
	extraCharges,
	priceIndex
) => {
	let applicablePrice = 0;

	const { applyTaxes } = extraCharge;

	if (applyTaxes.some(tc => tc.id === product.taxCode.id)) applicablePrice += productPrice;

	extraCharges.forEach(itemExtras => {
		itemExtras.forEach(category => {
			category.forEach(extra => {
				if (
					!extra.isTax &&
					extra.id !== extraCharge.id &&
					applyTaxes.some(tc => tc.id === extra.taxCode.id)
				) {
					applicablePrice += calculateExtraChargePrice(
						product,
						extra,
						extraCharges,
						priceIndex
					);
				}
			});
		});
	});

	return applicablePrice;
};

export const calculateExtraChargePrice = (
	product,
	extraCharge,
	extraCharges = {},
	priceIndex = null
) => {
	const fromDate =
		product.dateFrom && product.dateFrom.date ? moment(product.dateFrom.date) : null;

	const [itemBookingDays, itemBookingDates] = getItemBookingDays(
		fromDate,
		product.pricing.prices[1]
	);

	const reservationFeeQuantity = getReservationExtraChargePeriod(
		product.pricing.prices[1].length,
		product.bookingPeriod,
		extraCharge.bookingCalculation,
		extraCharge.bookingPeriod
	);

	let extraChargeAmount = 0;

	product.pricing.prices[1].forEach((price, index) => {
		if (
			canApplyExtraCharge(
				extraCharge,
				itemBookingDates[index],
				itemBookingDays[index],
				true,
				true,
				index
			) &&
			index < reservationFeeQuantity &&
			(priceIndex === null || priceIndex === index)
		) {
			const applicablePrice = extraCharge.isTax
				? getExtraChargeApplicablePrice(product, price, extraCharge, extraCharges, index)
				: price;

			switch (
				typeof extraCharge.bookingCalculation === 'object'
					? extraCharge.bookingCalculation.value
					: extraCharge.bookingCalculation
			) {
				case bookingCalculations.FIXED:
					extraChargeAmount = extraCharge.price;
					break;
				case bookingCalculations.PERCENT:
					extraChargeAmount += (applicablePrice * extraCharge.price) / 100;
					break;
				case bookingCalculations.PER_PERIOD:
					extraChargeAmount += extraCharge.price;
					break;
				case bookingCalculations.PER_QUANTITY_PER_PERIOD:
					extraChargeAmount += extraCharge.multiSelectQuantity * extraCharge.price;
					break;
				case bookingCalculations.PER_QUANTITY:
					extraChargeAmount = extraCharge.multiSelectQuantity * extraCharge.price;
					break;
				default:
					extraChargeAmount += extraCharge.price;
					break;
			}
		}
	});

	return extraChargeAmount;
};

export const getPerUnitPromoCodeAmount = (reservationItems, appliedPromoCode) => {
	if (!appliedPromoCode) return 0;

	let amount = 0;
	reservationItems.forEach(reservationItem => {
		reservationItem.extra.forEach((category, categoryIndex) => {
			category.forEach((extra, extraIndex) => {
				if (appliedPromoCode.id === extra.id) {
					amount +=
						reservationItem.extraCharge[categoryIndex][extraIndex] +
						reservationItem.extraTax[categoryIndex][extraIndex];
				}
			});
		});
	});

	return numberFormat(amount);
};

export const getHeaderHeight = isOnline => {
	if (isOnline) {
		const isFixedHeader = document.getElementsByClassName('sdms--fixed--header');
		return window.innerWidth > 768 &&
			window.screen.orientation &&
			window.screen.orientation.angle === 0 &&
			isFixedHeader.length > 0
			? document.getElementsByTagName('header')[0].offsetHeight +
					document.getElementsByTagName('header')[0].offsetTop
			: 0;
	}

	return document.getElementById('sdms_container').offsetTop;
};

export const getTotals = (data, reservationItems) => {
	const totals = {
		totalDueToday: 0,
		dueAtProperty: 0,
		total: 0,
		subtotalWithoutExtraCharges: 0,
	};

	reservationItems.current.forEach(reservationItem => {
		const itemPrice = getItemTotal(
			reservationItem.price,
			reservationItem.extraCharge,
			reservationItem.tax,
			reservationItem.extraTax
		);

		totals.subtotalWithoutExtraCharges += reservationItem.price;

		const depositAmount = reservationItem.product.isSecurityDeposit
			? reservationItem.deposit
			: Math.min(reservationItem.deposit, itemPrice);

		totals.totalDueToday += depositAmount;

		totals.total += itemPrice;

		totals.dueAtProperty += itemPrice;

		if (!reservationItem.product.isSecurityDeposit) totals.dueAtProperty -= depositAmount;
	});

	if (data.extraCharges)
		data.extraCharges.forEach(extraCharge => {
			totals.total += extraCharge.total;
			totals.dueAtProperty += extraCharge.total;
		});

	totals.dueAtProperty = numberFormat(totals.dueAtProperty);

	totals.totalDueToday = numberFormat(totals.totalDueToday);

	totals.total = numberFormat(totals.total);

	if (totals.dueAtProperty < 0) totals.dueAtProperty = 0;

	// if (totals.totalDueToday > totals.total) totals.totalDueToday = totals.total;

	return totals;
};

export const getSearchSvg = isOnline => {
	if (isOnline)
		// eslint-disable-next-line react/no-danger
		return <div className='svg-container' dangerouslySetInnerHTML={{ __html: bg }} />;

	return <ReactSVG src={bg} className='svg-container' />;
};

export const onlineDateFormatter = datetime => moment(datetime).format('MMM D');
