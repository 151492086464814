import React from 'react';
import PropTypes from 'prop-types';
import FormContainer from '../../reusables/template/FormContainer';
import pages from '../../pages';
import ProductCategoryForm from './ProductCategoryForm';

const ProductCategoryEdit = ({ match }) => {
	return (
		<FormContainer
			dataId={match.params.id}
			dataName='productCategories'
			lists={['productCategories']}
			fields={<ProductCategoryForm />}
			pageTitle='Page Category Name'
			icon={pages.pos.settings.productCategories.icon}
		/>
	);
};

ProductCategoryEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ProductCategoryEdit;
