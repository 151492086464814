import React from 'react';
import PropTypes from 'prop-types';
import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import PolicyForm from './PolicyForm';
import { modules } from '../../../utils/helpers/apiCall';

const PolicyEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='policies'
			fields={<PolicyForm />}
			lists={['enumBookingPeriods', 'enumBookingGuarantee', 'enumDepositTypes']}
			pageTitle={pages.campground.settings.policies.text}
			icon={pages.campground.settings.policies.icon}
			module={modules.CAMPGROUND}
		/>
	);
};

PolicyEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default PolicyEdit;
