import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DocumentTitle from 'react-document-title';
import Portlet from '../layout/Portlet';
import SVGIcon from '../element/SVGIcon';

// eslint-disable-next-line no-unused-vars
const Child = ({
	title,
	desc,
	icon,
	footer,
	disabled,
	actions,
	prependChildren,
	appendChildren,
}) => {
	throw new Error(`Title ${title}  component should be used as a child in the component Wizard.
	Description: "${desc}", Icon: "${icon}"`);
};
Child.propTypes = {
	title: PropTypes.string.isRequired,
	desc: PropTypes.string,
	icon: PropTypes.string,
	footer: PropTypes.node,
	disabled: PropTypes.bool,
	actions: PropTypes.node,
	prependChildren: PropTypes.node,
	appendChildren: PropTypes.node,
};
Child.defaultProps = {
	desc: null,
	icon: null,
	footer: null,
	disabled: false,
	actions: null,
	prependChildren: null,
	appendChildren: null,
};

const WizardTop = ({ children, activeStep, setActiveStep, documentTitle, hideNav, hideTitle }) => {
	const _Content = (
		<>
			{React.Children.map(
				children,
				(child, index) =>
					index === activeStep && (
						<Fragment key={`content_${child.props.title}`}>
							{child.props.prependChildren}
						</Fragment>
					)
			)}
			{hideNav && !hideTitle && (
				<Portlet
					isElevate={false}
					className='sdms-bg-transparent sdms-portlet--fit flex-grow-0'
					isLast>
					<Portlet.Head>
						{React.Children.map(
							children,
							(child, index) =>
								index === activeStep && (
									<Fragment key={`content_${child.props.title}`}>
										<Portlet.HeadLabelTitle portletIcon={child.props.icon}>
											{child.props.title}
										</Portlet.HeadLabelTitle>
										<Portlet.HeadToolbarActions>
											{child.props.actions}
										</Portlet.HeadToolbarActions>
									</Fragment>
								)
						)}
					</Portlet.Head>
				</Portlet>
			)}

			{React.Children.map(
				children,
				(child, index) =>
					index === activeStep && (
						<Fragment key={`content_${child.props.title}`}>
							{documentTitle ? (
								<DocumentTitle title={`${child.props.title} | ${documentTitle}`}>
									{child.props.children}
								</DocumentTitle>
							) : (
								child.props.children
							)}
						</Fragment>
					)
			)}

			{React.Children.map(
				children,
				(child, index) =>
					index === activeStep && (
						<Fragment key={`content_${child.props.title}`}>
							{child.props.appendChildren}
						</Fragment>
					)
			)}

			{React.Children.map(
				children,
				(child, index) =>
					index === activeStep && (
						<Fragment key={`content_${child.props.title}`}>
							{child.props.footer}
						</Fragment>
					)
			)}
		</>
	);
	if (hideNav) {
		return _Content;
	}
	return (
		<div
			className='sdms-wizard-v1 d-flex flex-grow-1'
			data-sdmswizard-state={
				activeStep === 0
					? 'first'
					: (activeStep === children.length - 1 && 'last') || 'between'
			}>
			<div className='sdms-wizard-v1__nav'>
				<div className='sdms-wizard-v1__nav-items sdms-wizard-v1__nav-items--clickable'>
					{React.Children.map(children, (child, index) => (
						<div
							key={`nav_${child.props.title}`}
							onClick={() => {
								if (!child.props.disabled) setActiveStep(index);
							}}
							role='presentation'
							className={classNames('sdms-wizard-v1__nav-item', {
								'sdms-wizard-v1__nav-item--disabled': child.props.disabled,
							})}
							data-sdmswizard-step-count={children.length}
							data-sdmswizard-state={
								index === activeStep
									? 'current'
									: (activeStep < index && 'pending') || 'done'
							}>
							<div className='sdms-wizard-v1__nav-body'>
								<div className='sdms-wizard-v1__nav-number'>
									{child.props.icon ? (
										<SVGIcon name={child.props.icon} />
									) : (
										index + 1
									)}
								</div>
								<div className='sdms-wizard-v1__nav-label'>
									<div className='sdms-wizard-v1__nav-label-title'>
										{child.props.title}
									</div>
									<div className='sdms-wizard-v1__nav-label-desc'>
										{child.props.desc}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			{_Content}
		</div>
	);
};
WizardTop.propTypes = {
	children: PropTypes.node.isRequired,
	activeStep: PropTypes.number.isRequired,
	setActiveStep: PropTypes.func.isRequired,
	documentTitle: PropTypes.string,
	hideNav: PropTypes.bool,
	hideTitle: PropTypes.bool,
};
WizardTop.defaultProps = {
	documentTitle: null,
	hideNav: false,
	hideTitle: false,
};

WizardTop.Child = Child;

export default WizardTop;
