import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { priceFormatter } from '../../../utils/helpers/helper';
import useDate from '../../../utils/hooks/useDate';
import { paymentStatuses, paymentTypes } from '../../../utils/constants/constants';

import SVGIcon from './SVGIcon';
import Portlet from '../layout/Portlet';
import Button from './Button';
import Loading from '../template/Loading';
import FitText from './FitText';
import { PaymentStatusCell } from './PaymentListCells';
import Checkbox from '../field/Checkbox';
import Dropdown from './Dropdown';
import Badge from './Badge';

export const ItemRow = ({ children, className, disabled, isActive, onClick, printStatus }) => {
	return (
		<tr
			className={classNames(
				'sdms-t-orders--item',
				{ 'sdms-t-orders--item--disable': disabled },
				{ 'sdms-t-orders--item--active': isActive },
				className
			)}
			data-printer={printStatus}
			onClick={onClick}>
			{children}
		</tr>
	);
};
ItemRow.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	isActive: PropTypes.bool,
	onClick: PropTypes.func,
	printStatus: PropTypes.string,
};
ItemRow.defaultProps = {
	className: null,
	disabled: false,
	isActive: false,
	onClick: null,
	printStatus: null,
};

export const ItemCellCheckBox = ({ disabled, hideCheckbox, onChange, value }) => {
	if (!hideCheckbox) {
		return (
			<td className='sdms-t-orders--item__check'>
				<Checkbox onChange={onChange} value={value} disabled={disabled} />
			</td>
		);
	}
	return null;
};
ItemCellCheckBox.propTypes = {
	disabled: PropTypes.bool,
	hideCheckbox: PropTypes.bool,
	onChange: PropTypes.func,
	value: PropTypes.bool,
};
ItemCellCheckBox.defaultProps = {
	disabled: false,
	hideCheckbox: false,
	onChange: null,
	value: false,
};

export const ItemCellName = ({ children, className, onClick }) => {
	return (
		<td className={classNames('sdms-t-orders--item__name', className)}>
			{onClick ? (
				<span
					className='sdms-link sdms-link--dark sdms-text-overflow'
					onClick={onClick}
					role='presentation'>
					{children}
				</span>
			) : (
				children
			)}
		</td>
	);
};
ItemCellName.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	className: PropTypes.string,
	onClick: PropTypes.func,
};
ItemCellName.defaultProps = {
	className: null,
	onClick: null,
};

export const ItemCellQuantity = ({ children, className }) => {
	return <td className={classNames('sdms-t-orders--item__quantity', className)}>{children}</td>;
};
ItemCellQuantity.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	className: PropTypes.string,
};
ItemCellQuantity.defaultProps = {
	className: null,
};

export const ItemCellPrice = ({ children, className, onClick }) => {
	return (
		<FitText>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
			<td
				role={onClick ? 'presentation' : null}
				className={classNames('sdms-t-orders--item__price', className)}
				onClick={onClick}>
				{children}
			</td>
		</FitText>
	);
};
ItemCellPrice.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	className: PropTypes.string,
	onClick: PropTypes.func,
};
ItemCellPrice.defaultProps = {
	className: null,
	onClick: null,
};

export const ItemCellActions = ({ children, className, disabled, title }) => {
	return (
		<td className={classNames('sdms-t-orders--item__actions', className)}>
			<Dropdown
				icon='Other#1'
				size='sm'
				color={null}
				inline
				aligned='right'
				arrow={false}
				outline={false}
				disabled={disabled}>
				{title && <Dropdown.Header>{title}</Dropdown.Header>}
				{children}
			</Dropdown>
		</td>
	);
};
ItemCellActions.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	title: PropTypes.string,
};
ItemCellActions.defaultProps = {
	className: null,
	disabled: false,
	title: null,
};

const ItemRowInfoContainer = ({
	children,
	className,
	disabled,
	isLast,
	printStatus,
	hideCheckbox,
}) => {
	return (
		<tr
			className={classNames(
				'sdms-t-orders--item__info',
				'sdms-font-md',
				{ 'sdms-t-orders--item--disable': disabled },
				{ 'sdms-t-orders--item--has-check': !hideCheckbox },
				{
					'sdms-t-orders--item__info-last': isLast,
				},
				className
			)}
			data-printer={printStatus}>
			{children}
		</tr>
	);
};
ItemRowInfoContainer.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	isLast: PropTypes.bool,
	printStatus: PropTypes.string,
	hideCheckbox: PropTypes.bool,
};
ItemRowInfoContainer.defaultProps = {
	className: null,
	disabled: false,
	isLast: false,
	printStatus: null,
	hideCheckbox: false,
};

export const ItemRowDescription = ({
	description,
	disabled,
	isLast,
	printStatus,
	hideCheckbox,
}) => {
	if (description) {
		return (
			<ItemRowInfoContainer
				disabled={disabled}
				isLast={isLast}
				printStatus={printStatus}
				hideCheckbox={hideCheckbox}>
				{!hideCheckbox && <td />}
				<td
					colSpan={3}
					className='sdms-t-orders--item__modifiers'
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: description }}
				/>
				<td />
			</ItemRowInfoContainer>
		);
	}
	return null;
};
ItemRowDescription.propTypes = {
	description: PropTypes.string,
	disabled: PropTypes.bool,
	isLast: PropTypes.bool,
	printStatus: PropTypes.string,
	hideCheckbox: PropTypes.bool,
};
ItemRowDescription.defaultProps = {
	description: null,
	disabled: false,
	isLast: false,
	printStatus: null,
	hideCheckbox: false,
};

export const ItemRowNote = ({ disabled, isLast, note, printStatus, label, hideCheckbox }) => {
	if (note) {
		return (
			<ItemRowInfoContainer
				disabled={disabled}
				isLast={isLast}
				printStatus={printStatus}
				hideCheckbox={hideCheckbox}>
				{!hideCheckbox && <td />}
				<td colSpan={3}>
					<b>{label}: </b>
					{note}
				</td>
				<td />
			</ItemRowInfoContainer>
		);
	}
	return null;
};
ItemRowNote.propTypes = {
	disabled: PropTypes.bool,
	isLast: PropTypes.bool,
	note: PropTypes.string,
	printStatus: PropTypes.string,
	label: PropTypes.string,
	hideCheckbox: PropTypes.bool,
};
ItemRowNote.defaultProps = {
	disabled: false,
	isLast: false,
	note: null,
	printStatus: null,
	label: 'Note',
	hideCheckbox: false,
};

export const ItemRowTax = ({ disabled, isLast, name, printStatus, tax, value }) => {
	if (tax) {
		return (
			<ItemRowInfoContainer disabled={disabled} isLast={isLast} printStatus={printStatus}>
				<td />
				<td colSpan={2} className='sdms-text-overflow'>
					<b>Tax:</b>
					{` ${name}`}
				</td>
				<td className='sdms-t-orders--item__price sdms-font-sm'>{value}</td>
				<td />
			</ItemRowInfoContainer>
		);
	}
	return null;
};
ItemRowTax.propTypes = {
	disabled: PropTypes.bool,
	isLast: PropTypes.bool,
	name: PropTypes.string,
	printStatus: PropTypes.string,
	tax: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
ItemRowTax.defaultProps = {
	disabled: false,
	isLast: false,
	name: null,
	printStatus: null,
	tax: false,
	value: null,
};

export const ItemRowDiscount = ({
	disabled,
	discount,
	discountAmount,
	discountType,
	printStatus,
	subtotal,
}) => {
	if (discountAmount) {
		return (
			<ItemRowInfoContainer
				disabled={disabled}
				isLast
				printStatus={printStatus}
				className='sdms-font-danger'>
				<td />
				<td colSpan={2} className='sdms-text-overflow'>
					{discount.name}: {discountType.value === 'fixed' && '$'}
					{discountAmount}
					{discountType.value === 'percent' && '%'}
				</td>
				<td className='sdms-t-orders--item__price'>
					{priceFormatter(
						discountType.value === 'percent'
							? (subtotal * discountAmount) / 100
							: discountAmount
					)}
				</td>
				<td />
			</ItemRowInfoContainer>
		);
	}
	return null;
};
ItemRowDiscount.propTypes = {
	disabled: PropTypes.bool,
	discount: PropTypes.shape({ name: PropTypes.string }),
	discountAmount: PropTypes.number,
	discountType: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		value: PropTypes.string,
	}),
	printStatus: PropTypes.string,
	subtotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
ItemRowDiscount.defaultProps = {
	disabled: false,
	discount: null,
	discountAmount: null,
	discountType: null,
	printStatus: null,
};

export const OrderNote = ({ note }) => {
	if (note) {
		return (
			<Portlet.Foot tall='sm' className='sdms-bg-fill-warning'>
				<div className='col-12'>
					<SVGIcon name='Write' fill='var(--dark)' size={20} className='sdms-mr-5' />
					<b>Note:</b>
					{` ${note}`}
				</div>
			</Portlet.Foot>
		);
	}
	return null;
};
OrderNote.propTypes = {
	note: PropTypes.string,
};
OrderNote.defaultProps = {
	note: null,
};

export const OrderSeats = ({ seats, onClick, disabled }) => {
	if (seats) {
		return (
			<Portlet.Foot tall='sm' className='sdms-bg-fill-info'>
				<div className='col-8'>
					<SVGIcon name='Chair2' fill='var(--dark)' size={20} className='sdms-mr-5' />
					<b>Seats:</b>
					{` ${seats}`}
				</div>
				<div className='col-4 text-right'>
					<Button
						className='sdms-margin-r-15'
						design='info'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
						onClick={onClick}
						disabled={disabled}
					/>
				</div>
			</Portlet.Foot>
		);
	}
	return null;
};
OrderSeats.propTypes = {
	seats: PropTypes.number,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
};
OrderSeats.defaultProps = {
	seats: 1,
	onClick: () => {},
	disabled: false,
};

// Payment Components
export const PaymentsTitle = ({ isShow, title, hideCheckbox }) => {
	if (isShow) {
		return (
			<tr className='sdms-bg-fill-dark sdms-font-boldest'>
				{!hideCheckbox && <td />}
				<td colSpan={99}>{title}</td>
			</tr>
		);
	}
	return null;
};
PaymentsTitle.propTypes = {
	isShow: PropTypes.bool,
	title: PropTypes.string,
	hideCheckbox: PropTypes.bool,
};
PaymentsTitle.defaultProps = {
	isShow: false,
	title: 'Payments',
	hideCheckbox: false,
};

export const PaymentItem = ({
	data,
	hasActions,
	isLoading,
	isEditable,
	isVoidable,
	isRefundable,
	onPrint,
	onRefund,
	onEdit,
	onVoid,
	hideCheckbox,
	refundedAmount,
	onPaymentRefundVoid,
	onCapture,
	onEmail,
	onEditAuthorizedPayment,
	onCaptureAuthorizedPayment,
	onVoidAuthorizedPayment,
}) => {
	const [dateFormatter] = useDate();

	const getNameColumnCol = () => {
		if (refundedAmount !== 0 && refundedAmount < data.amount) return hideCheckbox ? 2 : 1;

		return hideCheckbox ? 3 : 2;
	};

	const getDate = () => {
		if (data.remittanceDate) return data.remittanceDate;

		if (data.refundDate) return data.refundDate;

		return data.timeCreated;
	};

	const getServiceFee = () => {
		if (!data.originalAmount || data.amount === data.originalAmount - data.serviceFee)
			return data.serviceFee;

		return data.amount * (data.serviceFee / (data.originalAmount - data.serviceFee));
	};

	return (
		<>
			<tr className='sdms-t-orders--payment'>
				{!hideCheckbox && <td />}
				<td colSpan={getNameColumnCol()} className='sdms-font-monospace'>
					<b>{data.paymentMethod.name}</b>{' '}
					{!data.isAuthorizedPayment &&
						data.status.value === paymentStatuses.PROCESSING &&
						' - Processing'}
					{data.status.value === paymentStatuses.SCHEDULED && (
						<PaymentStatusCell data={data} />
					)}
					{` - ${dateFormatter(getDate())}`}
					{data.isAuthorizedPayment && (
						<>
							{' '}
							<Badge
								design='info'
								isInline
								isUnified
								fontWeight='bold'
								size='lg'
								className='sdms-text-overflow'>
								<span className='sdms-text-overflow sdms-font-transform-c'>
									Authorized
								</span>
							</Badge>
						</>
					)}
				</td>
				{refundedAmount !== 0 && refundedAmount < data.amount && (
					<td className='sdms-t-orders--item__price sdms-font-monospace sdms-font-italic sdms-text-dec-line--line-through sdms-label-font-color-2'>
						{priceFormatter(data.amount)}
					</td>
				)}
				<td
					className={classNames('sdms-t-orders--item__price sdms-font-monospace', {
						'sdms-font-italic':
							refundedAmount === data.amount && !data.isAuthorizedPayment,
						'sdms-text-dec-line--line-through':
							refundedAmount === data.amount && !data.isAuthorizedPayment,
						'sdms-label-font-color-2':
							refundedAmount === data.amount && !data.isAuthorizedPayment,
					})}>
					{priceFormatter(
						refundedAmount === 0 || refundedAmount === data.amount
							? data.amount
							: data.amount - refundedAmount
					)}
				</td>
				{hasActions ? (
					<td className='sdms-t-orders--item__actions'>
						<Dropdown
							icon='Other#1'
							size='sm'
							color={null}
							inline
							aligned='right'
							arrow={false}
							outline={false}
							disabled={isLoading || data.paymentMethod.name === paymentTypes.CREDIT}>
							<Dropdown.Header>Payment Item Actions</Dropdown.Header>
							{onPrint && (
								<Dropdown.Item icon='Printer' onClick={() => onPrint(data)}>
									Print
								</Dropdown.Item>
							)}
							{onEmail && (
								<Dropdown.Item icon='Mail-box' onClick={onEmail}>
									Email Receipt
								</Dropdown.Item>
							)}
							{onRefund && (
								<Dropdown.Item
									icon='Undo'
									onClick={onRefund}
									noPermission={!isRefundable}>
									Refund
								</Dropdown.Item>
							)}
							{onEdit && (
								<Dropdown.Item
									icon='Edit'
									onClick={onEdit}
									noPermission={!isEditable}>
									Edit
								</Dropdown.Item>
							)}
							{onCapture && (
								<Dropdown.Item icon='Undo' onClick={onCapture}>
									Capture Now
								</Dropdown.Item>
							)}
							{onVoid && (
								<Dropdown.Item
									icon='Subtract'
									onClick={onVoid}
									noPermission={!isVoidable}>
									Void
								</Dropdown.Item>
							)}
							{onPaymentRefundVoid && (
								<Dropdown.Item icon='Subtract' onClick={onPaymentRefundVoid}>
									Void
								</Dropdown.Item>
							)}
							{onEditAuthorizedPayment && (
								<Dropdown.Item icon='Edit' onClick={onEditAuthorizedPayment}>
									Edit
								</Dropdown.Item>
							)}
							{onCaptureAuthorizedPayment && (
								<Dropdown.Item icon='Undo' onClick={onCaptureAuthorizedPayment}>
									Capture
								</Dropdown.Item>
							)}
							{onVoidAuthorizedPayment && (
								<Dropdown.Item icon='Subtract' onClick={onVoidAuthorizedPayment}>
									Release
								</Dropdown.Item>
							)}
						</Dropdown>
					</td>
				) : (
					<td />
				)}
			</tr>
			{data.paymentMethod.paymentType.value === paymentTypes.HOUSE_ACCOUNT && (
				<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
					<td />
					<td colSpan={99}>Customer: {data.customer.displayName}</td>
				</tr>
			)}
			{data.paymentMethod.paymentType.value === paymentTypes.BOOKING && (
				<>
					<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
						<td />
						<td colSpan={99}>Customer: {data.customer.displayName}</td>
					</tr>
					<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
						<td />
						<td colSpan={99}>
							{`Booking ${data.reservationItem.id} - ${
								data.reservationItem.product.name
							} (${data.reservationItem.unit?.name || 'N/A'})`}
						</td>
					</tr>
				</>
			)}
			{data.paymentMethod.paymentType.value === paymentTypes.CHECK &&
				(data.number || data.bankName) && (
					<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
						<td />
						{data.bankName ? (
							<td className='sdms-text-overflow'>
								ACH:
								{`${data.bankName} - ${data.accountName}${
									data.accountHolderName ? ` - ${data.accountHolderName}` : ''
								}`}
							</td>
						) : (
							<td className='sdms-text-overflow'>
								Number:
								{data.number}
							</td>
						)}
						{data.serviceFee ? (
							<td colSpan={2} className='sdms-t-orders--item__price'>
								Service Fee: {priceFormatter(getServiceFee())}
							</td>
						) : (
							''
						)}
					</tr>
				)}
			{data.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD && data.cardNumber && (
				<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
					<td />
					<td className='sdms-text-overflow'>
						Number:
						{data.cardNumber}
					</td>
					{data.serviceFee ? (
						<td colSpan={2} className='sdms-t-orders--item__price'>
							Service Fee: {priceFormatter(getServiceFee())}
						</td>
					) : (
						''
					)}
				</tr>
			)}
		</>
	);
};
PaymentItem.propTypes = {
	data: PropTypes.shape({
		paymentMethod: PropTypes.object,
		customer: PropTypes.object,
		timeCreated: PropTypes.string,
		remittanceDate: PropTypes.string,
		refundDate: PropTypes.string,
		number: PropTypes.string,
		cardNumber: PropTypes.string,
		status: PropTypes.object,
		amount: PropTypes.number,
		originalAmount: PropTypes.number,
		reservationItem: PropTypes.object,
		serviceFee: PropTypes.number,
		bankName: PropTypes.string,
		accountName: PropTypes.string,
		isAuthorizedPayment: PropTypes.bool,
		token: PropTypes.string,
		captured: PropTypes.bool,
		accountHolderName: PropTypes.string,
	}).isRequired,
	hasActions: PropTypes.bool,
	isLoading: PropTypes.bool,
	isEditable: PropTypes.bool,
	isVoidable: PropTypes.bool,
	isRefundable: PropTypes.bool,
	onPrint: PropTypes.func,
	onRefund: PropTypes.func,
	onEdit: PropTypes.func,
	onVoid: PropTypes.func,
	hideCheckbox: PropTypes.bool,
	refundedAmount: PropTypes.number,
	onPaymentRefundVoid: PropTypes.func,
	onCapture: PropTypes.func,
	onEmail: PropTypes.func,
	onEditAuthorizedPayment: PropTypes.func,
	onCaptureAuthorizedPayment: PropTypes.func,
	onVoidAuthorizedPayment: PropTypes.func,
};
PaymentItem.defaultProps = {
	hasActions: true,
	isLoading: false,
	isEditable: false,
	isVoidable: false,
	isRefundable: true,
	onPrint: null,
	onRefund: null,
	onEdit: null,
	onVoid: null,
	hideCheckbox: false,
	refundedAmount: 0,
	onPaymentRefundVoid: null,
	onCapture: null,
	onEmail: null,
	onEditAuthorizedPayment: null,
	onCaptureAuthorizedPayment: null,
	onVoidAuthorizedPayment: null,
};

export const PayButton = ({
	className,
	color,
	colorOpacity,
	disabled,
	icon,
	onClick,
	isLoading,
	text,
	price,
	priceAlign,
	noPermission,
}) => {
	return (
		<div className={classNames('col-6', className)}>
			<Button
				className='sdms-font-transform-u sdms-fitText--lg'
				design={!colorOpacity ? color : null}
				label={colorOpacity ? color : null}
				iconSize={36}
				block
				fontWeight='boldest'
				onClick={onClick}
				disabled={disabled}
				noPermission={noPermission}>
				<div className='row align-items-center'>
					<div className='col text-left sdms-pr0'>
						{icon && <SVGIcon name={icon} size={36} />}
						{text}
					</div>
					{price && (
						<Loading isLoading={isLoading} type='div' width={50}>
							<div
								className={classNames('col-auto', {
									'sdms-orders__pay-button-align': priceAlign,
								})}>
								{price}
							</div>
						</Loading>
					)}
				</div>
			</Button>
		</div>
	);
};
PayButton.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string.isRequired,
	colorOpacity: PropTypes.bool,
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	isLoading: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	price: PropTypes.string,
	text: PropTypes.string.isRequired,
	priceAlign: PropTypes.bool,
	noPermission: PropTypes.bool,
};
PayButton.defaultProps = {
	className: null,
	colorOpacity: true,
	price: null,
	icon: null,
	isLoading: false,
	priceAlign: true,
	noPermission: null,
	disabled: false,
};

export default ItemRow;
