import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import { convertDateToUTC, dateFormatter } from '../../../utils/helpers/helper';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import forms from '../../forms';
import UserContext from '../../../app/contexts/UserContext';

export const TransactionDateCell = ({ data }) => {
	return dateFormatter(convertDateToUTC(moment(data.transactionDate).toDate()), false);
};

const JournalList = ({ history }) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.accounting.default.text, path: pages.accounting.dashboard.path },
			{ title: pages.accounting.journals.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.accounting.default.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);
	return (
		<ListContainer
			route='journals'
			title={pages.accounting.journals.text}
			history={history}
			forms={forms.accounting.journals}>
			<List
				withCheckBox
				fluid='fluid'
				responsive='grid'
				checkEditable={() => {
					return ['edit'];
				}}>
				<List.Col
					label='#'
					cellData='id'
					sortable='id'
					isLinkColumn={userContext.hasPermission('edit_journal_entries')}
				/>
				<List.Col label='Reference' cellData='reference' />
				<List.Col
					label='Transaction Date'
					cellComponent={<TransactionDateCell />}
					sortable='transactionDate'
				/>
				<List.Col
					label='Created By'
					cellData='displayName'
					cellDataObject='createdBy'
					sortable='createdBy.displayName'
					isLinkColumn
				/>
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
						noPermission={!userContext.hasPermission('edit_journal_entries')}
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item
							itemsColor='danger'
							icon='Erase'
							key='delete'
							noPermission={!userContext.hasPermission('delete_journal_entries')}>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
JournalList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default JournalList;
