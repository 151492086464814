import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../../app/contexts/UserContext';
import useModal from '../../../../utils/hooks/useModal';
import { addErrorNotification, addSuccessNotification } from '../../../../utils/helpers/helper';
import {
	canVoidPayment,
	canBounceCheck,
	getPaymentRefundableAmount,
} from '../../../../utils/helpers/paymentHelper';
import {
	nonTransientAdvancedFormSteps,
	paymentStatuses,
	reservationReceiptTypes,
	serviceFeeModules,
} from '../../../../utils/constants/constants';
import { nonTransientAdvancedFormPages } from '../../../pages';
import apiCall from '../../../../utils/helpers/apiCall';
import {
	getPaymentTypes,
	printReservationReceipt,
} from '../../../../utils/helpers/reservationHelper';

import List from '../../template/List';
import Dropdown from '../../element/Dropdown';
import Button from '../../element/Button';
import ListContainer from '../../template/ListContainer';
import DialogBox from '../../element/DialogBox';
import PrinterModal from '../../modals/PrinterModal';
import PayModal from '../../modals/PayModal';
import {
	PaymentAmountCell,
	PaymentDateCell,
	PaymentTypeCell,
	PaymentStatusCell,
	PaymentIdLinkCell,
	BalanceCell,
	SettledCell,
} from '../../element/PaymentListCells';

const modals = {
	VOID_DIALOG: 'voidDialog',
	PRINTER: 'printers',
	REFUND: 'refund',
	CAPTURE: 'capture',
	AUTHORIZE_PAYMENT: 'authorize_payment',
	CAPTURE_AUTHORIZED_PAYMENT: 'capture_authorized_payment',
	VOID_AUTHORIZED_PAYMENT: 'void_authorized_payment',
	BOUNCE_CHECK: 'bounceCheck',
};

const PaymentList = ({
	isReservation,
	filters,
	presetData,
	disabled,
	paymentTypes,
	webPrint,
	headerActions,
	openInCrm,
	onEditAuthorizedPayment,
	onCaptureAuthorizedPayment,
	onVoidAuthorizedPayment,
	onChange,
}) => {
	const userContext = useContext(UserContext);

	const [isLoading, setIsLoading] = useState(false);

	const [modal, openModal, closeModal] = useModal();

	const openPrinterModal = (reservation, printData, type) =>
		openModal({
			open: modals.PRINTER,
			reservation,
			printData,
			type,
		});

	const onVoid = () => {
		setIsLoading(true);
		apiCall(
			'POST',
			'voidPayment',
			res => {
				addSuccessNotification('Payment successfully voided.');

				if (modal.updateData) modal.updateData([res]);

				closeModal();
				setIsLoading(false);

				onChange();
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ payment: modal.payment, outletId: userContext.data.selectedOutlet.id }
		);
	};

	const onRefund = (payment, refund) => {
		setIsLoading(true);

		apiCall(
			'POST',
			'refundPayment',
			res => {
				if (modal.updateData && res.payment) modal.updateData([res.payment]);

				closeModal();
				setIsLoading(false);

				printReservationReceipt(
					webPrint,
					presetData.reservation,
					[res.refund],
					reservationReceiptTypes.REFUND,
					null,
					openPrinterModal
				);

				onChange();
			},
			e => {
				addErrorNotification(e.toString());
				setIsLoading(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				payment,
				refund,
			}
		);
	};

	const onCapture = () => {
		setIsLoading(true);
		apiCall(
			'POST',
			'captureSchedulePayment',
			res => {
				if (res.success) addSuccessNotification('Payment successfully captured.');
				else addErrorNotification(res.message);
				if (modal.updateData) modal.updateData([res.payment]);
				closeModal();
				setIsLoading(false);

				onChange();
			},
			err => {
				closeModal();
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ payment: modal.payment, outletId: userContext.data.selectedOutlet.id }
		);
	};

	const onBounce = () => {
		setIsLoading(true);

		apiCall(
			'POST',
			'bounceCheck',
			res => {
				addSuccessNotification('Check successfully bounced.');
				if (modal.updateData) modal.updateData([res]);
				closeModal();
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
				closeModal();
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				payment: modal.payment,
			}
		);
	};

	return (
		<>
			<ListContainer
				route='reservationPayments'
				title={isReservation ? 'Remittances' : 'Payments'}
				icon={
					nonTransientAdvancedFormPages.find(
						s => s.title === nonTransientAdvancedFormSteps.PAYMENTS
					).icon
				}
				staticFilters={filters}
				customActions={{
					void: (payment, updateData) =>
						openModal({ open: modals.VOID_DIALOG, payment, updateData }),
					print: payment =>
						printReservationReceipt(
							webPrint,
							presetData.reservation,
							payment,
							reservationReceiptTypes.PAYMENT,
							null,
							openPrinterModal
						),
					refund: (payment, updateData) =>
						openModal({
							open: modals.REFUND,
							payment,
							updateData,
							amount: getPaymentRefundableAmount(payment),
							customer: payment.customer,
						}),
					captureScheduledPayment: (payment, updateData) =>
						openModal({ open: modals.CAPTURE, payment, updateData }),
					editAuthorizedPayment: payment => onEditAuthorizedPayment(payment),
					captureAuthorizedPayment: payment => onCaptureAuthorizedPayment(payment),
					voidAuthorizedPayment: payment => onVoidAuthorizedPayment(payment),
					bounceCheck: (payment, updateData) =>
						openModal({ open: modals.BOUNCE_CHECK, payment, updateData }),
				}}
				isTabList
				largeQuickPanel
				forms={[]}
				presetData={presetData}
				onChange={payment => {
					printReservationReceipt(
						webPrint,
						presetData.reservation,
						payment,
						reservationReceiptTypes.PAYMENT,
						null,
						openPrinterModal
					);
					onChange(payment);
				}}
				fluid='fluid'
				hasSearch={false}
				headerActions={headerActions}>
				<List
					fluid='fluid'
					withCheckBox={false}
					checkEditable={payment => {
						if (!payment.paymentMethod) return [];

						const actions = disabled ? ['print'] : ['edit', 'print'];

						if (canVoidPayment(payment)) actions.push('void');

						if (getPaymentRefundableAmount(payment) > 0) actions.push('refund');

						if (payment.status.value === paymentStatuses.SCHEDULED && payment.token)
							actions.push('captureScheduledPayment');

						if (payment.isAuthorizedPayment)
							actions.push(
								'editAuthorizedPayment',
								'captureAuthorizedPayment',
								'voidAuthorizedPayment'
							);

						if (canBounceCheck(payment)) actions.push('bounceCheck');

						return actions;
					}}
					withOutPortlet>
					<List.Col
						label={isReservation ? 'Remittance' : 'Payment'}
						cellData='remittanceId'
						isLinkColumn
						cellComponent={openInCrm ? <PaymentIdLinkCell /> : null}
						sortable='remittanceId'
					/>
					<List.Col
						label='Date'
						cellComponent={<PaymentDateCell />}
						id='remittanceDate'
					/>
					<List.Col label='Type' cellComponent={<PaymentTypeCell />} />
					<List.Col label='Amount' cellComponent={<PaymentAmountCell />} />
					<List.Col label='Reference' cellData='reference' />
					<List.Col label='Settled' cellComponent={<SettledCell />} />
					<List.Col label='Balance' cellComponent={<BalanceCell />} />
					<List.Col label='Status' cellComponent={<PaymentStatusCell />} />
					<List.Col align='right' onlyHover width={150}>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item icon='Expand-arrows' key='edit'>
								Display
							</Dropdown.Item>
							<Dropdown.Item icon='Printer' key='print'>
								Print
							</Dropdown.Item>
							<Dropdown.Item icon='Undo' key='captureScheduledPayment'>
								Capture Now
							</Dropdown.Item>
							<Dropdown.Item icon='Undo' key='refund'>
								Refund
							</Dropdown.Item>
							<Dropdown.Item itemsColor='danger' icon='Error-circle' key='void'>
								Void
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='danger'
								icon='Error-circle'
								key='bounceCheck'>
								Bounce Check
							</Dropdown.Item>
							<Dropdown.Item icon='Edit' key='editAuthorizedPayment'>
								Edit
							</Dropdown.Item>
							<Dropdown.Item icon='Undo' key='captureAuthorizedPayment'>
								Capture
							</Dropdown.Item>
							<Dropdown.Item icon='Subtract' key='voidAuthorizedPayment'>
								Release
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			<DialogBox
				open={modal.open === modals.VOID_DIALOG}
				title='Void'
				content='Are you sure you want to void this payment?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't Void!`}
					onClick={closeModal}
					disabled={isLoading}
				/>
				<Button
					className='sdms-font-transform-c'
					label='danger'
					icon='Trash'
					text='Yes, Void!'
					onClick={onVoid}
					disabled={isLoading}
				/>
			</DialogBox>
			<PrinterModal
				onClose={closeModal}
				printers={webPrint ? webPrint.current.printers : []}
				isOpen={modal.open === modals.PRINTER}
				enableRemember
				onSubmit={printer => {
					printReservationReceipt(
						webPrint,
						modal.reservation,
						modal.printData,
						modal.type,
						printer.id,
						openPrinterModal
					);

					closeModal();
				}}
			/>
			<PayModal
				isOpen={modal.open === modals.REFUND}
				onClose={closeModal}
				paymentTypes={getPaymentTypes(
					true,
					paymentTypes,
					userContext.hasPermission('cash_payments')
				)}
				amount={modal.amount || 0}
				customer={modal.customer}
				onPay={refundData => onRefund(modal.payment, refundData)}
				isPaying={isLoading}
				canSavePaymentMethod={userContext.hasPermission('save_customer_payment_methods')}
				canEnterCreditCard={userContext.hasPermission('key_in_credit_cards')}
				isRefund
				refundAmountChangeable
				max={modal.amount}
				defaultCreditCard={null}
				paymentGateway={userContext.data.selectedOutlet.settings.paymentGateway}
				serviceFeeModule={serviceFeeModules.SLIPS}
				disableServiceFee
				outlet={userContext.data.selectedOutlet}
			/>
			<DialogBox
				open={modal.open === modals.CAPTURE}
				title='Capture'
				content='Are you sure you want to capture this payment?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't Capture!`}
					onClick={closeModal}
					disabled={isLoading}
				/>
				<Button
					className='sdms-font-transform-c'
					label='info'
					icon='Trash'
					text='Yes, Capture!'
					onClick={onCapture}
					disabled={isLoading}
				/>
			</DialogBox>
			<DialogBox
				open={modal.open === modals.BOUNCE_CHECK}
				title='Bounce check'
				content='Are you sure you want to bounce this check?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't bounce!`}
					onClick={closeModal}
					disabled={isLoading}
				/>
				<Button
					className='sdms-font-transform-c'
					label='info'
					icon='Ticket'
					text='Yes, bounce!'
					onClick={onBounce}
					disabled={isLoading}
				/>
			</DialogBox>
		</>
	);
};
PaymentList.propTypes = {
	isReservation: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	filters: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	presetData: PropTypes.object,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	paymentTypes: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	webPrint: PropTypes.object,
	headerActions: PropTypes.arrayOf(PropTypes.object),
	openInCrm: PropTypes.bool,
	onEditAuthorizedPayment: PropTypes.func,
	onCaptureAuthorizedPayment: PropTypes.func,
	onVoidAuthorizedPayment: PropTypes.func,
};
PaymentList.defaultProps = {
	isReservation: false,
	filters: null,
	presetData: null,
	onChange: () => {},
	disabled: false,
	paymentTypes: [],
	webPrint: null,
	headerActions: [],
	openInCrm: false,
	onEditAuthorizedPayment: null,
	onCaptureAuthorizedPayment: null,
	onVoidAuthorizedPayment: null,
};

export default PaymentList;
