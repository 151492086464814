import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Portlet from '../../reusables/layout/Portlet';
import IconBox from '../../reusables/layout/IconBox';
import pages from '../../pages';
import ContentInner from '../../reusables/template/ContentInner';
import HeaderContext from '../../../app/contexts/HeaderContext';
import BreadcrumbContainer from '../../reusables/template/BreadcrumbContainer';
import Button from '../../reusables/element/Button';

const PosReportSelect = () => {
	const history = useHistory();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.reports.default.text, path: pages.reports.dashboard.path },
			{ title: pages.reports.pos.text, path: pages.reports.pos.path },
			{ title: 'Select Report Type', isActive: true },
		]);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title='Select Report Type' />
					<BreadcrumbContainer />
				</ContentInner.SubHeaderItem>
				<ContentInner.SubHeaderItem type='toolbar'>
					<Button
						design='default'
						text='Back To The List'
						onClick={() => history.push('/reports/pos')}
					/>
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title='Select Report Type'>
				<Portlet fluid='fluid'>
					<Portlet.Body>
						<div className='row sdms-iconbox--container'>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox
									icon={pages.reports.pos.orders.icon}
									title={pages.reports.pos.orders.text}
									onClick={() =>
										history.push(`${pages.reports.pos.orders.path}/0`)
									}>
									Build Order reports.
								</IconBox>
							</div>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox
									icon={pages.reports.pos.orderdetails.icon}
									title={pages.reports.pos.orderdetails.text}
									onClick={() =>
										history.push(`${pages.reports.pos.orderdetails.path}/0`)
									}>
									Build Order Detail reports.
								</IconBox>
							</div>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox
									icon={pages.reports.pos.ordermodifiers.icon}
									title={pages.reports.pos.ordermodifiers.text}
									onClick={() =>
										history.push(`${pages.reports.pos.ordermodifiers.path}/0`)
									}>
									Build Order Modifier reports.
								</IconBox>
							</div>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox
									icon={pages.reports.pos.tips.icon}
									title={pages.reports.pos.tips.text}
									onClick={() =>
										history.push(`${pages.reports.pos.tips.path}/0`)
									}>
									Build Tips reports.
								</IconBox>
							</div>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox // newreporttype
									icon={pages.reports.pos.zout.icon}
									title={pages.reports.pos.zout.text}
									onClick={() => history.push(`${pages.reports.pos.zout.path}/0`)}
									// design={pages.marina.products.period.hourly.color}
									animate='fast'>
									Build Z-Out reports.
								</IconBox>
							</div>
						</div>
					</Portlet.Body>
				</Portlet>
			</ContentInner.Container>
		</>
	);
};

export default PosReportSelect;
