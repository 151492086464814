import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import DialogBox from '../../DialogBox';

const SignOutDialog = ({ isOpen, onSignOut, onClose }) => {
	return (
		<DialogBox
			open={isOpen}
			title=''
			content='All changes will be lost. Are you sure?'
			type='question'>
			<Button
				className='sdms-font-transform-c'
				text='Yes, Sign out'
				label='danger'
				icon='Error-circle'
				onClick={onSignOut}
			/>
			<Button
				className='sdms-font-transform-c'
				text='Cancel'
				design='clean'
				icon='Edit'
				onClick={onClose}
			/>
		</DialogBox>
	);
};

SignOutDialog.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onSignOut: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default SignOutDialog;
