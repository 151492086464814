import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderContext from '../../../app/contexts/HeaderContext';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import pages from '../../pages';
import UserContext from '../../../app/contexts/UserContext';
import forms from '../../forms';

const GridList = ({ history }) => {
	const headerContext = useContext(HeaderContext);
	const userContext = useContext(UserContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.pos.default.text, path: pages.pos.dashboard.path },
			{ title: pages.pos.productGrids.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.pos.productGrids.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);
	return (
		<ListContainer
			route='grids'
			title='Product Grids'
			forms={forms.pos.productGrids}
			history={history}>
			<List withCheckBox fluid='fluid'>
				<List.Col
					label='Name'
					cellData='name'
					isLinkColumn={userContext.hasPermission('edit_product_grids')}
					sortable='name'
				/>
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
						noPermission={!userContext.hasPermission('edit_product_grids')}
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item
							itemsColor='danger'
							icon='Erase'
							key='delete'
							noPermission={!userContext.hasPermission('delete_product_grids')}>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
GridList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default GridList;
