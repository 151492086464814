import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import Button from '../../reusables/element/Button';
import MultiSelect from '../../reusables/element/MultiSelect';
import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import FormGroup from '../../reusables/layout/FormGroup';
import Loading from '../../reusables/template/Loading';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import useModifierGroup from '../../../utils/hooks/useModifierGroup';
import pages from '../../pages';
import HeaderContext from '../../../app/contexts/HeaderContext';
import Badge from '../../reusables/element/Badge';
import Portal from '../../reusables/layout/Portal';

const ModifierGroupForm = ({
	data,
	setIsValid,
	isSubmitted,
	prepStations,
	modifierPrefixes,
	isLoading,
	setTitle,
	onFormChange,
}) => {
	const [
		name,
		nameOnChange,
		nameValRes,
		nameShowVal,
		setNameShowVal,
		assignedModifierPrefixes,
		selectPrefixOpen,
		setSelectPrefixOpen,
		addModifier,
		modifiers,
		renderModifier,
		updateAssignedPrefixes,
	] = useModifierGroup({
		data,
		setIsValid,
		isSubmitted,
		prepStations,
		modifierPrefixes,
		setTitle,
		onFormChange,
	});

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.pos.default.text, path: pages.pos.dashboard.path },
			{ title: pages.pos.modifierGroups.text, path: pages.pos.modifierGroups.path },
			{ title: name || `New ${pages.pos.modifierGroups.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.pos.modifierGroups.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<>
			<Portlet className='flex-grow-0' hasFrame style={{ minHeight: 166.89 }}>
				<Portlet.Body>
					<form>
						<Section title='Modifier Group Settings' last>
							<Section.Body>
								<FormGroup isLast>
									<Loading isLoading={isLoading}>
										<FormField
											name='name'
											label='Name'
											id={data.id}
											valRes={nameValRes}
											showValidation={nameShowVal}
											colMd={6}
											isLast>
											<Input
												type='text'
												placeholder='Modifier Group Name (Required)'
												value={name}
												onChange={nameOnChange}
												onBlur={setNameShowVal}
											/>
										</FormField>
									</Loading>
								</FormGroup>
							</Section.Body>
						</Section>
					</form>
				</Portlet.Body>
			</Portlet>
			<Portlet
				className='sdms-form sdms-list-layout sdms-modifier sdms-list-scroll'
				fluid='fluid'
				hasFrame>
				<Portlet.Head wrapMaxSize='md'>
					<Portlet.HeadLabelTitle portletIcon={pages.pos.productGrids.icon}>
						Modifiers
					</Portlet.HeadLabelTitle>
					<Portlet.HeadToolbar>
						<Portlet.HeadActions>
							<Button
								className='sdms-margin-r-15'
								icon='Clipboard-list'
								design='brand'
								outline
								text='Select Prefixes'
								key='selectPrefixes'
								size='sm'
								onClick={() => setSelectPrefixOpen(true)}
							/>
							<Button
								label='brand'
								icon='Plus'
								text='New Modifier'
								key='newModifier'
								size='sm'
								onClick={() => addModifier()}
							/>
						</Portlet.HeadActions>
					</Portlet.HeadToolbar>
				</Portlet.Head>
				<Portlet.Body>
					<div className='sdms-modifier--container'>
						{/* Title */}
						<table className='table'>
							<TableHead data={assignedModifierPrefixes} />
							{/* Row */}
							<tbody>
								{modifiers.map((modifier, index) =>
									renderModifier(modifier, index)
								)}
							</tbody>
						</table>
					</div>
				</Portlet.Body>
			</Portlet>
			{/* Select Prefix */}
			<Portal>
				<Popup
					contentStyle={{
						padding: 0,
						background: 'unset',
						border: 'unset',
					}}
					closeOnDocumentClick={false}
					modal
					lockScroll
					open={selectPrefixOpen}
					onClose={() => setSelectPrefixOpen(false)}>
					<Portlet>
						<Portlet.Head>
							<Portlet.HeadLabelTitle portletIcon='Clipboard-list'>
								Select Modifier
							</Portlet.HeadLabelTitle>
						</Portlet.Head>
						<Portlet.Body>
							<MultiSelect
								data={
									modifierPrefixes &&
									modifierPrefixes.sort((a, b) =>
										a.sortOrder > b.sortOrder ? 1 : -1
									)
								}
								titleProp='name'
								value={assignedModifierPrefixes}
								onChange={e => updateAssignedPrefixes(e.target.value)}
							/>
						</Portlet.Body>
						<Portlet.Foot tall='sm'>
							<div className='col'>
								<Button
									design='clean'
									text='Cancel'
									icon='Error-circle'
									size='sm'
									elevate
									onClick={() => {
										setSelectPrefixOpen(false);
									}}
								/>
							</div>
							<div className='col-auto'>
								<Button
									design='brand'
									icon='Save'
									size='sm'
									elevate
									text='Select'
									onClick={() => {
										setSelectPrefixOpen(false);
									}}
								/>
							</div>
						</Portlet.Foot>
					</Portlet>
				</Popup>
			</Portal>
		</>
	);
};
ModifierGroupForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		modifiers: PropTypes.arrayOf(PropTypes.object),
		modifierPrefixes: PropTypes.arrayOf(PropTypes.object),
		disabled: PropTypes.bool,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	prepStations: PropTypes.arrayOf(PropTypes.object),
	modifierPrefixes: PropTypes.arrayOf(PropTypes.object),
	isLoading: PropTypes.bool,
	setTitle: PropTypes.func,
	onFormChange: PropTypes.func,
};
ModifierGroupForm.defaultProps = {
	data: {},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	prepStations: [],
	modifierPrefixes: [],
	setTitle: () => {},
};

export const TableHead = ({ data }) => {
	return (
		<thead data-head='sticky-cell'>
			<tr>
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<th className='sdms-modifier__draggable sdms-list-scroll--sticky' />
				<th className='sdms-modifier__modifiers sdms-list-scroll--sticky'>Modifiers</th>
				<th className='sdms-modifier__price sdms-list-scroll--sticky'>Price</th>
				<th className='sdms-modifier__prefix-req sdms-list-scroll--sticky'>Force Prefix</th>
				<th className='sdms-modifier__printer sdms-list-scroll--sticky sdms-list-scroll--sticky--last'>
					Printer
				</th>
				{data
					? data.map(prefix => {
							return (
								<th key={prefix.id} className='sdms-modifier__prefix'>
									<Badge
										design='info'
										isInline
										isUnified
										isOutline
										fontWeight='bolder'
										size='lg'>
										{prefix.name}
									</Badge>
								</th>
							);
					  })
					: null}
				<th className='sdms-modifier__actions'>Actions</th>
			</tr>
		</thead>
	);
};
TableHead.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ModifierGroupForm;
