import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { maxLength, required, url as urlValidation } from '../../../utils/helpers/validation';
import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import Button from '../../reusables/element/Button';
import useField from '../../../utils/hooks/useField';
import Loading from '../../reusables/template/Loading';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';

const PrinterForm = ({
	data,
	setIsValid,
	submit,
	isSubmitted,
	printerTypes,
	printerWidths,
	printers,
	printerLibraries,
	isLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
}) => {
	const pages = usePages();

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);
	const [backupPrinter, backupPrinterOnChange] = useField(
		data,
		'backupPrinter',
		onFormChange,
		[],
		null
	);

	const [url, urlOnChange, urlValRes, urlShowVal, setUrlShowVal] = useField(
		data,
		'url',
		onFormChange,
		[required, maxLength(), urlValidation]
	);

	const [type, typeOnChange, typeValRes, typeShowVal, setTypeShowVal] = useField(
		data,
		'type',
		onFormChange,
		[required],
		null
	);
	const [width, widthOnChange, widthValRes, widthShowVal, setWidthShowVal] = useField(
		data,
		'width',
		onFormChange,
		[required],
		null
	);

	const [library, libraryOnChange, libraryValRes, libraryShowVal, setLibraryShowVal] = useField(
		data,
		'library',
		onFormChange,
		[required],
		null
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setUrlShowVal();
			setTypeShowVal();
			setWidthShowVal();
			setLibraryShowVal();
		}
	}, [
		isSubmitted,
		setLibraryShowVal,
		setNameShowVal,
		setTypeShowVal,
		setUrlShowVal,
		setWidthShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				urlValRes.isValid &&
				typeValRes.isValid &&
				widthValRes.isValid &&
				libraryValRes.isValid
		);
	}, [
		setIsValid,
		nameValRes.isValid,
		urlValRes.isValid,
		typeValRes.isValid,
		widthValRes.isValid,
		libraryValRes.isValid,
	]);

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.companySettings.default.text,
				path: pages.companySettings.dashboard.path,
			},
			{
				title: pages.companySettings.printers.text,
				path: pages.companySettings.printers.path,
			},
			{ title: name || `New ${pages.companySettings.printers.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.companySettings.printers.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form>
					<Section title='General'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='name'
										label='Name'
										id={data.id}
										valRes={nameValRes}
										showValidation={nameShowVal}
										colMd={6}>
										<Input
											type='text'
											placeholder='Name (Required)'
											value={name}
											onChange={nameOnChange}
											onBlur={setNameShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='url'
										label='URL'
										id={data.id}
										valRes={urlValRes}
										showValidation={urlShowVal}
										colMd={6}
										messages={{ valid: 'Valid URL.' }}>
										<Input
											type='text'
											placeholder='URL (Required)'
											value={url}
											onChange={urlOnChange}
											onBlur={setUrlShowVal}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Printing'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='type'
										label='Type'
										id={data.id}
										valRes={typeValRes}
										showValidation={typeShowVal}
										colMd={6}>
										<Selects
											options={printerTypes}
											placeholder='Type (Required)'
											value={type}
											displayKey='value'
											onChange={typeOnChange}
											onBlur={setTypeShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='backupPrinter'
										label='Backup Printer'
										id={data.id}
										colMd={6}>
										<Selects
											options={printers.filter(p => p.id !== data.id)}
											placeholder='Backup Printer'
											value={backupPrinter}
											onChange={backupPrinterOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Device'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='Width'
										label='Width'
										id={data.id}
										valRes={widthValRes}
										showValidation={widthShowVal}
										colMd={6}
										messages={{ valid: 'Valid Width.' }}>
										<Selects
											options={printerWidths}
											placeholder='Width (Required)'
											value={width}
											onChange={widthOnChange}
											onBlur={setWidthShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='library'
										label='Printer Library'
										id={data.id}
										valRes={libraryValRes}
										showValidation={libraryShowVal}
										colMd={6}>
										<Selects
											options={printerLibraries}
											placeholder='Printer Library (Required)'
											value={library}
											onChange={libraryOnChange}
											onBlur={setLibraryShowVal}
											displayKey='value'
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
PrinterForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		backupPrinter: PropTypes.object,
		url: PropTypes.string,
		library: PropTypes.object,
		type: PropTypes.object,
		width: PropTypes.object,
	}),
	printerTypes: PropTypes.arrayOf(PropTypes.object),
	printerWidths: PropTypes.arrayOf(PropTypes.object),
	printers: PropTypes.arrayOf(PropTypes.object),
	printerLibraries: PropTypes.arrayOf(PropTypes.object),
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
};
PrinterForm.defaultProps = {
	data: {
		id: 0,
		name: '',
	},
	printerTypes: [],
	printers: [],
	printerWidths: [],
	printerLibraries: [],
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
};

export default PrinterForm;
