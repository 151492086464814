import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { maxLength, required, uniqueFnc } from '../../../utils/helpers/validation';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';

import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import Button from '../../reusables/element/Button';
import useField from '../../../utils/hooks/useField';
import Loading from '../../reusables/template/Loading';

const AccountForm = ({
	data,
	setIsValid,
	submit,
	isSubmitted,
	accountTypes,
	accountDetailTypes,
	accounts,
	isLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
}) => {
	const pages = usePages();

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required, uniqueFnc('accounts', 'name', data.id)],
		'',
		null,
		setTitle
	);

	const [
		accountNum,
		accountNumOnChange,
		accountNumValRes,
		accountNumShowVal,
		setAccountNumShowVal,
	] = useField(data, 'accountNum', onFormChange, [maxLength()]);

	const [
		description,
		descriptionOnChange,
		descriptionValRes,
		descriptionShowVal,
		setDescriptionShowVal,
	] = useField(data, 'description', onFormChange, [maxLength()]);

	const [
		accountType,
		accountTypeOnChange,
		accountTypeValRes,
		accountTypeShowVal,
		setAccountTypeShowVal,
	] = useField(data, 'accountType', onFormChange, [required], null);

	const [accountDetailType, accountDetailTypeOnChange] = useField(
		data,
		'accountDetailType',
		onFormChange,
		[],
		null
	);

	const [parentAccount, parentAccountOnChange] = useField(
		data,
		'parentAccount',
		onFormChange,
		[],
		null
	);

	const [
		exportCaption,
		exportCaptionOnChange,
		exportCaptionValRes,
		exportCaptionShowVal,
		setExportCaptionShowVal,
	] = useField(data, 'exportCaption', onFormChange, [maxLength()]);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setAccountNumShowVal();
			setAccountTypeShowVal();
			setDescriptionShowVal();
			setExportCaptionShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setAccountNumShowVal,
		setAccountTypeShowVal,
		setDescriptionShowVal,
		setExportCaptionShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				accountNumValRes.isValid &&
				accountTypeValRes.isValid &&
				descriptionValRes.isValid &&
				exportCaptionValRes.isValid
		);
	}, [
		setIsValid,
		nameValRes.isValid,
		accountNumValRes.isValid,
		accountTypeValRes.isValid,
		descriptionValRes.isValid,
		exportCaptionValRes.isValid,
	]);

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.accounting.default.text, path: pages.accounting.dashboard.path },
			{
				title: pages.accounting.chartOfAccounts.text,
				path: pages.accounting.chartOfAccounts.path,
			},
			{ title: name || `New ${pages.accounting.chartOfAccounts.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.accounting.chartOfAccounts.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid' hasFrame>
			<Portlet.Body>
				<form>
					<Section title='General'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='name'
										label='Name'
										id={data.id}
										valRes={nameValRes}
										showValidation={nameShowVal}
										loadingContainer
										colMd={6}>
										<Input
											type='text'
											placeholder='Name (Required)'
											value={name}
											onChange={nameOnChange}
											onBlur={setNameShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='accountNum'
										label='Account Number'
										id={data.id}
										valRes={accountNumValRes}
										showValidation={accountNumShowVal}
										colMd={6}>
										<Input
											type='text'
											placeholder='Account Number'
											value={accountNum}
											onChange={accountNumOnChange}
											onBlur={setAccountNumShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='description'
										label='Description'
										id={data.id}
										valRes={descriptionValRes}
										showValidation={descriptionShowVal}
										colMd={6}>
										<Input
											type='text'
											placeholder='Description'
											value={description}
											onChange={descriptionOnChange}
											onBlur={setDescriptionShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='accountType'
										label='Account Type'
										id={data.id}
										valRes={accountTypeValRes}
										showValidation={accountTypeShowVal}
										colMd={6}>
										<Selects
											options={accountTypes}
											placeholder='Account Type (Required)'
											value={accountType}
											displayKey='value'
											onChange={e => {
												accountDetailTypeOnChange({
													target: {
														name: 'accountDetailType',
														value: {},
													},
												});

												accountTypeOnChange(e);
											}}
											onBlur={setAccountTypeShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='accountDetailType'
										label='Detail Type'
										description={`See {{${pages.accounting.settings.accountDetailTypes.path}|account detail type}} `}
										id={data.id}
										colMd={6}>
										<Selects
											options={
												accountType
													? accountDetailTypes.filter(
															adt =>
																adt.accountType.id ===
																accountType.id
													  )
													: []
											}
											placeholder='Detail Type'
											value={accountDetailType}
											displayKey='name'
											onChange={accountDetailTypeOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='parentAccount'
										label='Parent Account'
										description={`See {{${pages.accounting.chartOfAccounts.path}|parent account}}`}
										id={data.id}
										colMd={6}>
										<Selects
											options={accounts.filter(a => a.id !== data.id)}
											placeholder='Parent Account'
											value={parentAccount}
											displayKey='name'
											onChange={parentAccountOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='exportCaption'
										label='Export Caption'
										id={data.id}
										valRes={exportCaptionValRes}
										showValidation={exportCaptionShowVal}
										colMd={6}>
										<Input
											type='text'
											placeholder='Export Caption'
											value={exportCaption}
											onChange={exportCaptionOnChange}
											onBlur={setExportCaptionShowVal}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
AccountForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		accountNum: PropTypes.string,
		description: PropTypes.string,
		accountDetailType: PropTypes.object,
		accountType: PropTypes.shape({ accountDetailTypes: PropTypes.arrayOf(PropTypes.object) }),
		parentAccount: PropTypes.object,
		exportCaption: PropTypes.string,
	}),
	accountTypes: PropTypes.arrayOf(PropTypes.object),
	accounts: PropTypes.arrayOf(PropTypes.object),
	accountDetailTypes: PropTypes.arrayOf(PropTypes.object),
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
};
AccountForm.defaultProps = {
	data: {
		id: 0,
		name: '',
	},
	accountTypes: [],
	accounts: [],
	accountDetailTypes: [],
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
};

export default AccountForm;
