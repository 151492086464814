/**
 * @todo add onBlur
 * @todo fix placeholder color and active border color.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactMultiEmail } from 'react-multi-email';

import { InputGroup, InputPend } from './Input';

import 'react-multi-email/style.css';

const MultiEmailInput = ({
	value,
	onChange,
	prepend,
	placeholder,
	isValid,
	showValidation,
	onBlur,
}) => {
	const _INPUT = (
		<ReactMultiEmail
			className={classNames({
				'is-invalid': showValidation && !isValid,
			})}
			placeholder={placeholder}
			emails={value ? value.split(',') : []}
			onChange={emails => onChange({ target: { value: emails.join(',') } })}
			getLabel={(email, index, removeEmail) => {
				return (
					<div data-tag='' key={index} style={{ marginRight: 5 }}>
						<div data-tag-item=''>{email}</div>
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
						<span data-tag-handle='' onClick={() => removeEmail(index)}>
							×
						</span>
					</div>
				);
			}}
		/>
	);

	if (prepend)
		return (
			<InputGroup>
				<InputPend text={prepend} />
				{_INPUT}
			</InputGroup>
		);

	return _INPUT;
};

MultiEmailInput.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	prepend: PropTypes.string,
	placeholder: PropTypes.string,
	showValidation: PropTypes.bool,
	isValid: PropTypes.bool,
	onBlur: PropTypes.func,
};

MultiEmailInput.defaultProps = {
	value: '',
	onChange: () => {},
	prepend: '',
	placeholder: 'Enter a email',
	showValidation: false,
	isValid: false,
	onBlur: () => {},
};

export default MultiEmailInput;
