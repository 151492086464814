import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import update from 'immutability-helper';
import { ReactSVG } from 'react-svg';

import classNames from 'classnames';
import UserContext from '../../../app/contexts/UserContext';
import apiCall, { modules, parseData } from '../../../utils/helpers/apiCall';
// eslint-disable-next-line import/namespace,import/default,import/no-named-as-default,import/no-named-as-default-member
import WebPrint from '../../reusables/print/WebPrint';
import {
	addErrorNotification,
	addSuccessNotification,
	parseOrderItemModifiersData,
	generateId,
	numberFormat,
	quantityFormatter,
	invoiceToPayment,
	priceFormatter,
	useWindowSize,
	getPaymentTermByModule,
	getItemTaxCode,
	isRebelModifier,
	isModuleEnabled,
} from '../../../utils/helpers/helper';
import {
	createOrder,
	createOrderItem,
	getOrderItemTaxRate,
	getOrderItemDescription,
	getServiceFeeAmountsByOrder,
	calculateOrderTotals,
	getRelatedOrders,
	orderItemPrintable,
	applyAutoDiscountToItem,
} from '../../../utils/helpers/orderHelper';

import useWebWorker from '../../../utils/hooks/useWebWorker';
import registerWorker from './registerWorker';

import {
	screens,
	terminalModals,
	printStatuses,
	afterSaveActions,
	paymentTypes,
	orderStatuses,
	orderTypes,
	mediaBreakpoint,
	serviceFeeModules,
	discountCalculations,
} from '../../../utils/constants/constants';

import Container from '../../reusables/layout/Container';
import Head from '../../reusables/layout/Head';
import Nav from '../../Nav';
import ContentInner from '../../reusables/template/ContentInner';
import Clock from '../../reusables/element/Clock';
import TableMap from './TableMap';
import ProductGrid from './ProductGrid';
import Order from './Order';
import RecallOrders from './RecallOrders';
import PayBill from './PayBill';
import Modifiers from './Modifiers';
import Split from './Split';
import QuantityModal from '../../reusables/modals/QuantityModal';
import Button from '../../reusables/element/Button';
import DialogBox from '../../reusables/element/DialogBox';
import HeaderMobile from '../../HeaderMobile';
import Loading from '../../reusables/template/Loading';
import DiscountModal from '../../reusables/modals/DiscountModal';
import NoteModal from '../../reusables/modals/NoteModal';
import TaxCodeModal from '../../reusables/modals/TaxCodeModal';
import UserModal from '../../reusables/modals/UserModal';
import CustomerModal from '../../reusables/modals/CustomerModal';
import TableModal from '../../reusables/modals/TableModal';
import PayModal from '../../reusables/modals/PayModal';
import logoLoading from '../../../assets/img/logoLoading.svg';
import Scanner from '../../reusables/element/Scanner';
import PaymentModal from '../../reusables/modals/PaymentModal';
import RotateContent from '../../reusables/design/RotateContent';
import OrderVoidModal from '../../reusables/modals/OrderVoidModal';
import Alert from '../../reusables/element/Alert';
import AuthorizePaymentModal from '../../reusables/modals/AuthorizePaymentModal';
import useModal from '../../../utils/hooks/useModal';
import VesselModal from '../../reusables/modals/VesselModal';

const Terminal = ({
	id,
	reservationItem,
	inReservationForm,
	onBackToReservation,
	customer,
	vessel,
	productGrid,
}) => {
	const windowSize = useWindowSize();

	const notificationSize = windowSize.width < mediaBreakpoint.MD ? windowSize.width - 30 : 300;

	const hasSideCar = windowSize.width <= mediaBreakpoint.SM;

	const userContext = useContext(UserContext);

	const [isLoading, setIsLoading] = useState(true);

	const [isOrderLoading, setIsOrderLoading] = useState(false);

	const [error, setError] = useState('');

	const [screen, setScreen] = useState('');

	const [register, setRegister] = useState({});

	const [table, setTable] = useState(null);

	const [orders, setOrders] = useState([]);

	const [activeOrderIndex, setActiveOrderIndex] = useState(null);

	const [selectedOrderItems, setSelectedOrderItems] = useState([]);

	const [activeProduct, setActiveProduct] = useState(null);

	const [activeOrderItemIndex, setActiveOrderItemIndex] = useState(null);

	const [currentDoNotPrint, setCurrentDoNotPrint] = useState(false);

	const [hasOrderChange, setHasOrderChange] = useState(false);

	const [isAuthorizing, setIsAuthorizing] = useState(false);

	const [modal, openModal, closeModal] = useModal();

	const prevScreen = useRef('');

	const lists = useRef({});

	const newOrderStatus = useRef();

	const orderType = useRef();

	const newOrderItemPrintStatus = useRef();

	const doNotPrintOrderItemPrintStatus = useRef();

	const taxRate = useRef();

	const print = useRef({
		actions: {},
		orderPrint: () => {},
		servicePrint: () => {},
		openCashDrawer: () => {},
		receiptPrint: () => {},
		refundPrint: () => {},
		serverCloseOut: () => {},
		onServicePrintFinish: () => {},
	});

	const hasPayBillChange = useRef(false);

	const logoutCounter = 5;

	const count = useRef(0);

	const backupOrders = useRef(orders);

	const isSaved = useRef(false);

	// Menu Overlay Status
	const [overlay, setOverlay] = useState({
		aside: false,
		header: false,
		topBar: false,
		order: false,
	});

	const setOverlayInitial = () => {
		setOverlay({ aside: false, header: false, topBar: false, order: false });
	};

	// Mobile device recall screen list or order
	const [sideCar, setSideCar] = useState(false);

	const reloadRelatedInProgress = useRef(false);

	const [
		registerWorkerStart,
		registerWorkerStop,
		registerWorkerTerminate,
	] = useWebWorker(registerWorker, 0, { registerId: id });

	const changeScreen = _screen => {
		if (hasPayBillChange.current) {
			openModal({ open: terminalModals.PAYBILL_QUIT });
			return;
		}
		if (isOrderLoading) return;

		prevScreen.current = screen;

		if (_screen === screens.RECALL) {
			// close recall
			if (screen === screens.RECALL) {
				if (register.unitMap) {
					setScreen(screens.MAP);
				} else {
					newOrder();
					setScreen(screens.GRID);
				}
			} else {
				setScreen(screens.RECALL);
				setOrders(oldOrders => oldOrders.filter(o => o.id !== 0));
				setActiveOrderIndex(null);
				setHasOrderChange(false);
				setSelectedOrderItems([]);
				setTable(null);
			}
			return;
		}

		if (_screen === screens.MAP) {
			setScreen(_screen);
			setTable(null);
			setActiveOrderIndex(null);
			setOrders([]);
			setHasOrderChange(false);
			setSelectedOrderItems([]);

			return;
		}

		setScreen(_screen);
	};

	const newOrder = (_orders = null, type = null, status = null, showSeats = true) => {
		const order = createOrder(
			register.outlet,
			register,
			table,
			type || orderType.current,
			status || newOrderStatus.current,
			{
				'@id': userContext.data.user['@id'],
				'@type': userContext.data.user['@type'],
				id: userContext.data.user.id,
				displayName: userContext.data.user.displayName,
			},
			customer,
			vessel
		);

		_orders = update(
			(_orders || orders).filter(o => o.id !== 0),
			{ $push: [order] }
		);

		setOrders(_orders);

		setActiveOrderIndex(_orders.findIndex(o => o.id === 0));

		setSelectedOrderItems([]);

		setHasOrderChange(false);

		if (
			register.registerType.requireSeats &&
			register.outlet.settings.showSeatsInput &&
			showSeats
		)
			openModal({ open: terminalModals.SEATS });
	};

	const takeBackupOnChangeOrders = () => {
		if (!hasOrderChange) backupOrders.current = orders[activeOrderIndex];
		setHasOrderChange(true);
	};

	const addOrderItemNotification = productName => {
		if (windowSize.width < mediaBreakpoint.MD)
			addSuccessNotification(`${productName} added.`, notificationSize);
	};

	const newOrderItem = (product, price, quantity, doNotPrint = false) => {
		const _orders = update(orders, {
			[activeOrderIndex]: {
				orderItems: {
					$push: [
						createOrderItem(
							generateId(orders[activeOrderIndex].orderItems),
							product,
							price,
							doNotPrint
								? doNotPrintOrderItemPrintStatus.current
								: newOrderItemPrintStatus.current,
							taxRate.current,
							orders[activeOrderIndex].customer,
							quantity,
							lists.current.discounts,
							lists.current.calculations
						),
					],
				},
			},
		});
		setOrders(
			update(_orders, {
				[activeOrderIndex]: { $merge: calculateOrderTotals(_orders[activeOrderIndex]) },
			})
		);

		takeBackupOnChangeOrders();

		addOrderItemNotification(product.name);
	};

	const addModifierOrderItem = modifierData => {
		// edit order item.

		let _orders = orders;
		modifierData.note = modifierData.note === '' ? null : modifierData.note;
		if (activeOrderItemIndex !== null) {
			const orderItemSubtotal =
				modifierData.price *
				orders[activeOrderIndex].orderItems[activeOrderItemIndex].quantity;

			_orders = update(_orders, {
				[activeOrderIndex]: {
					orderItems: {
						[activeOrderItemIndex]: {
							$merge: {
								orderItemModifiers: modifierData.orderItemModifiers,
								price: modifierData.price,
								note: modifierData.note,
								description: modifierData.description,
								subtotal: orderItemSubtotal > 0 ? orderItemSubtotal : 0,
								discountAmount:
									orderItemSubtotal > 0
										? orders[activeOrderIndex].orderItems[activeOrderItemIndex]
												.discountAmount
										: orderItemSubtotal,
							},
						},
					},
				},
			});
		} else {
			_orders = update(_orders, {
				[activeOrderIndex]: {
					orderItems: {
						$push: [
							{
								...createOrderItem(
									generateId(orders[activeOrderIndex].orderItems),
									activeProduct,
									modifierData.price,
									currentDoNotPrint
										? doNotPrintOrderItemPrintStatus.current
										: newOrderItemPrintStatus.current,
									taxRate.current,
									orders[activeOrderIndex].customer,
									1,
									lists.current.discounts,
									lists.current.calculations
								),
								orderItemModifiers: modifierData.orderItemModifiers,
								note: modifierData.note,
								description: modifierData.description,
							},
						],
					},
				},
			});
		}

		setOrders(
			update(_orders, {
				[activeOrderIndex]: { $merge: calculateOrderTotals(_orders[activeOrderIndex]) },
			})
		);

		addOrderItemNotification(modifierData.name);

		takeBackupOnChangeOrders();

		setActiveOrderItemIndex(null);

		setActiveProduct(null);

		setCurrentDoNotPrint(false);

		changeScreen(screens.GRID);
	};

	const deleteOrderItems = orderItemIds => {
		let _orders = orders;

		orderItemIds.forEach(oii => {
			const orderItemIndex = _orders[activeOrderIndex].orderItems.findIndex(
				oi => oi.id === oii
			);

			_orders = update(_orders, {
				[activeOrderIndex]: {
					orderItems: {
						$splice: [[orderItemIndex, 1]],
					},
				},
			});
		});

		setOrders(
			update(_orders, {
				[activeOrderIndex]: { $merge: calculateOrderTotals(_orders[activeOrderIndex]) },
			})
		);

		setSelectedOrderItems(oldSelectedOrderItems =>
			oldSelectedOrderItems.filter(oii => !orderItemIds.includes(oii))
		);
		takeBackupOnChangeOrders();
	};

	const onSplitSave = (splitData, closeSplit = true) => {
		if (closeSplit) changeScreen(screens.GRID);

		const activeOrderId = orders[activeOrderIndex].id;

		let _orders = orders;

		// clear not saved orders.
		if (activeOrderId === 0)
			_orders = update(_orders, { $set: orders.filter(o => o.id !== 0) });

		splitData.orders.forEach(o => {
			const orderIndex = _orders.findIndex(_o => _o.id === o.id);

			if (orderIndex > -1) _orders = update(_orders, { [orderIndex]: { $set: o } });
			else _orders = update(_orders, { $push: [o] });
		});

		splitData.deletedOrders.forEach(oi => {
			const orderIndex = _orders.findIndex(_o => _o.id === oi);
			if (orderIndex > -1) _orders = update(_orders, { $splice: [[orderIndex, 1]] });
		});

		if (closeSplit && splitData.willPrintOrders && splitData.willPrintOrders.length > 0) {
			print.current.orderPrint(_orders.filter(o => splitData.willPrintOrders.includes(o.id)));
		}

		setOrders(_orders);

		// if active order is new or deleted set new active order index.
		if (
			splitData.orders.findIndex(o => o.id === activeOrderId) === -1 ||
			!_orders[activeOrderIndex]
		) {
			setActiveOrderIndex(_orders.findIndex(o => o.id === splitData.orders[0].id));
		}

		setSelectedOrderItems([]);
		setHasOrderChange(false);
	};

	const saveOrder = (afterAction = '', _order = null, paymentData = null) => {
		isSaved.current = true;

		_order = _order || orders[activeOrderIndex];

		if (!_order.customer) {
			addErrorNotification('Please select a customer', notificationSize);

			return;
		}

		const formData = JSON.parse(JSON.stringify(_order));

		formData.parentOrder = parseData(formData.parentOrder, 'saleOrders');

		formData.orderItems = formData.orderItems.map(oi => {
			if (oi.orderItemModifiers)
				oi.orderItemModifiers = parseOrderItemModifiersData(oi.orderItemModifiers);

			return oi;
		});

		if (afterAction === afterSaveActions.PAY_ZERO)
			formData.status = lists.current.orderStatuses.find(
				os => os.value === orderStatuses.PAID
			);

		// Parse data for api call.
		Object.keys(formData).forEach(field => {
			formData[field] = parseData(formData[field]);
		});

		setIsOrderLoading(true);

		delete formData.timeCreated;

		apiCall(
			formData.id ? 'PUT' : 'POST',
			'saleOrders',
			res => {
				const _selectedOrderItems = selectedOrderItems;

				// update selected order items ids with news.
				if (selectedOrderItems.length > 0 && afterAction !== '') {
					_selectedOrderItems.forEach(oii => {
						if (oii < 1) {
							const orderItemIndex = orders[activeOrderIndex].orderItems.findIndex(
								oi => oi.id === oii
							);
							_selectedOrderItems.push(res.orderItems[orderItemIndex].id);
						}
					});

					setSelectedOrderItems(_selectedOrderItems.filter(oii => oii > 0));
				}

				// update active order.
				setOrders(oldOrders =>
					oldOrders.map(o => {
						if (o.id === formData.id) o = res;
						return o;
					})
				);

				setHasOrderChange(false);
				setIsOrderLoading(false);
				afterSave(afterAction, res, _selectedOrderItems, paymentData);
			},
			err => {
				setIsOrderLoading(false);
				if (
					err.toString().includes('override') ||
					err.toString().includes('Item not found for')
				)
					openModal({
						open: terminalModals.RELOAD,
						onSubmit: () => {
							reloadOrder();
							closeModal();
						},
					});
				else addErrorNotification(err.toString(), notificationSize);
			},
			formData.id ? formData.id : '',
			formData,
			{ 'groups[]': ['order-sale:read', 'order-sale:write'] }
		);
	};

	const afterSave = (action, _order = null, _selectedOrderItems = [], paymentData = null) => {
		switch (action) {
			case afterSaveActions.SERVICE:
				const orderItems = getSendableOrderItems(_order, _selectedOrderItems);

				if (orderItems.length === 0) return;

				setSelectedOrderItems([]);
				setIsOrderLoading(true);
				servicePrintItems.current = [];

				print.current.servicePrint(_order, orderItems);
				break;
			case afterSaveActions.HOLD:
				if (register.unitMap) changeScreen(screens.MAP);
				else newOrder();
				break;
			case afterSaveActions.VOID:
				if (register.unitMap) {
					changeScreen(screens.MAP);
				} else {
					newOrder(update(orders, { $splice: [[activeOrderIndex, 1]] }));
				}
				if (_order)
					addSuccessNotification(`${_order.name} successfully voided.`, notificationSize);
				break;
			case afterSaveActions.PAY:
				openModal({
					open: terminalModals.PAY,
				});
				break;
			case afterSaveActions.CASH_PAY:
				openModal({
					open: terminalModals.CASH_PAY,
				});
				break;
			case afterSaveActions.CC_PAY:
				openModal({
					open: terminalModals.CC_PAY,
				});
				break;
			case afterSaveActions.FINALIZE:
				onFinalize();
				break;
			case afterSaveActions.TIP_EDIT:
				openModal({ open: terminalModals.TIP });
				break;
			case afterSaveActions.AUTHORIZE_PAYMENT:
			case afterSaveActions.CAPTURE_AUTHORIZED_PAYMENT:
				openModal({
					open: terminalModals.AUTHORIZE_PAYMENT,
					isOrder: true,
					paymentData,
					isCapture: afterSaveActions.CAPTURE_AUTHORIZED_PAYMENT === action,
				});
				break;
			case afterSaveActions.VOID_AUTHORIZED_PAYMENT:
				openModal({
					open: terminalModals.VOID_AUTHORIZED_PAYMENT,
					paymentData,
				});
				break;
			case afterSaveActions.MAKE_PAYMENT:
				onPay(paymentData, _order.id);
				break;
			case afterSaveActions.PAY_WITH_BOOKING:
				onPay(
					{
						customer: customer.id,
						type: paymentTypes.BOOKING,
						booking: reservationItem,
						amount: _order.total,
						holdForSignature: false,
					},
					_order.id
				);
				break;
			default:
				addSuccessNotification(`${_order.name} successfully saved.`, notificationSize);
				break;
		}
	};

	const reloadOrder = () => {
		setIsOrderLoading(true);
		apiCall(
			'GET',
			'saleOrders',
			res => {
				setOrders(oldOrders =>
					oldOrders.map((o, i) => {
						if (i === activeOrderIndex) o = res;
						return o;
					})
				);
				setIsOrderLoading(false);

				setHasOrderChange(false);
			},
			err => {
				setIsOrderLoading(false);
				addErrorNotification(err.toString(), notificationSize);
			},
			orders[activeOrderIndex].id,
			null,
			{ 'groups[]': 'order-sale:read' }
		);
	};

	const voidOrder = (voidReason = '') => {
		if (orders[activeOrderIndex].id) {
			const order = orders[activeOrderIndex];

			order.status = lists.current.orderStatuses.find(
				os => os.value === orderStatuses.VOIDED
			);

			// close order for server.
			order.serverClosed = true;

			order.voidReason = voidReason;

			order.voidedBy = userContext.data.user;

			saveOrder(afterSaveActions.VOID, order);
		} else {
			afterSave(afterSaveActions.VOID);
		}
	};

	const voidPayment = invoice => {
		setIsOrderLoading(true);

		apiCall(
			'POST',
			'saleOrderPaymentVoid',
			res => {
				setOrders(update(orders, { [activeOrderIndex]: { $set: res } }));
				setIsOrderLoading(false);
				closeModal();
			},
			err => {
				addErrorNotification(err.toString(), notificationSize);
				setIsOrderLoading(false);
			},
			'',
			{ register: register.id, invoice: invoice.id }
		);
	};

	const saveRefundOrder = payment => {
		setIsOrderLoading(true);

		apiCall(
			'POST',
			'refund',
			res => {
				addSuccessNotification('Refund created.', notificationSize);
				print.current.refundPrint(res.order, res.refund);
				setIsOrderLoading(false);
				closeModal();
				if (register.unitMap) changeScreen(screens.MAP);
				else newOrder();
			},
			err => {
				setIsOrderLoading(false);
				addErrorNotification(err.toString(), notificationSize);
			},
			'',
			{
				order: orders[activeOrderIndex],
				payment,
				register: register.id,
			}
		);
	};

	const getSendableOrderItems = (_order, _selectedOrderItems) => {
		if (_order.orderItems.length === 0) return [];

		let items = _order.orderItems.filter(
			oi =>
				orderItemPrintable(oi) || oi.orderItemModifiers.some(oim => orderItemPrintable(oim))
		);

		if (_selectedOrderItems && _selectedOrderItems.length > 0)
			items = items.filter(oi => _selectedOrderItems.includes(oi.id));

		return register.registerType.sendAnyProduct
			? items
			: items.filter(item => item.product.prepStations.length);
	};

	const userHasPermission = permission => userContext.hasPermission(permission);

	const servicePrintItems = useRef([]);

	const onPrintFinish = printData => {
		// update order item print statuses and save order.
		if (printData.action === print.current.actions.SERVICE) {
			servicePrintItems.current.push(
				...printData.items
					.filter(i => servicePrintItems.current.findIndex(spi => spi.id === i.id) === -1)
					.map(i => ({ ...i, success: true }))
			);
		} else if (printData.action === print.current.actions.SERVER_CLOSE_OUT) {
			addSuccessNotification('Closing out...', notificationSize);

			apiCall(
				'POST',
				'serverCloseOut',
				() => {
					userContext.logout('terminal');
				},
				err => {
					addErrorNotification(err.toString(), notificationSize);
					setIsOrderLoading(false);
				}
			);
		} else {
			setIsOrderLoading(false);
		}
		setSelectedOrderItems([]);
	};

	const onPrintFail = (printData, errorText) => {
		if (printData.action === print.current.actions.SERVICE) {
			servicePrintItems.current = [
				...servicePrintItems.current.filter(
					spi => printData.items.findIndex(i => i.id === spi.id) === -1
				),
				...printData.items.map(i => ({ ...i, success: false })),
			];
		} else {
			setIsOrderLoading(false);
		}
		addErrorNotification(errorText, notificationSize);
	};

	const onAllServicePrintFinish = () => {
		const errorStatus = lists.current.printStatuses.find(
			ps => ps.value === printStatuses.ERROR
		);

		const fake = lists.current.printStatuses.find(ps => ps.value === printStatuses.FAKE);

		const sent = lists.current.printStatuses.find(ps => ps.value === printStatuses.SENT);

		let _orders = orders;

		servicePrintItems.current.forEach(item => {
			const orderItemIndex = _orders[activeOrderIndex].orderItems.findIndex(
				oi => oi.id === (item.type === 'modifier' ? item.orderItemId : item.id)
			);

			let updateItemDescription = false;

			let printStatus = fake;

			if (
				_orders[activeOrderIndex].orderItems[orderItemIndex].product.prepStations.filter(
					ps => register.excludePrepStations.findIndex(eps => eps.id === ps.id) === -1
				).length &&
				_orders[activeOrderIndex].orderItems[orderItemIndex].printStatus.value !==
					printStatuses.DONOTPRINT
			)
				printStatus = item.success ? sent : errorStatus;

			if (item.type === 'modifier') {
				const orderItemModifierIndex = _orders[activeOrderIndex].orderItems[
					orderItemIndex
				].orderItemModifiers.findIndex(oim => oim.id === item.id);

				_orders = update(_orders, {
					[activeOrderIndex]: {
						orderItems: {
							[orderItemIndex]: {
								orderItemModifiers: {
									[orderItemModifierIndex]: {
										printStatus: { $set: printStatus },
									},
								},
							},
						},
					},
				});

				updateItemDescription = true;
			} else {
				_orders = update(_orders, {
					[activeOrderIndex]: {
						orderItems: {
							[orderItemIndex]: {
								printStatus: {
									$set: printStatus,
								},
							},
						},
					},
				});

				const modifiers = _orders[activeOrderIndex].orderItems[
					orderItemIndex
				].orderItemModifiers.filter(
					oim =>
						!servicePrintItems.current.some(
							spi => spi.id === oim.id && spi.type === 'modifier'
						) &&
						!isRebelModifier(
							_orders[activeOrderIndex].orderItems[orderItemIndex],
							oim,
							register.excludePrepStations
						)
				);

				modifiers.forEach(oim => {
					const orderItemModifierIndex = _orders[activeOrderIndex].orderItems[
						orderItemIndex
					].orderItemModifiers.findIndex(_oim => _oim.id === oim.id);

					_orders = update(_orders, {
						[activeOrderIndex]: {
							orderItems: {
								[orderItemIndex]: {
									orderItemModifiers: {
										[orderItemModifierIndex]: {
											printStatus: { $set: printStatus },
										},
									},
								},
							},
						},
					});
				});

				if (modifiers.length > 0) updateItemDescription = true;
			}

			if (updateItemDescription)
				_orders = update(_orders, {
					[activeOrderIndex]: {
						orderItems: {
							[orderItemIndex]: {
								description: {
									$set: getOrderItemDescription(
										_orders[activeOrderIndex].orderItems[orderItemIndex]
									),
								},
							},
						},
					},
				});
		});

		saveOrder('', _orders[activeOrderIndex]);
	};

	const serverCloseOut = () => {
		setIsOrderLoading(true);

		apiCall(
			'POST',
			'canServerCloseOut',
			res => {
				setIsOrderLoading(false);
				openModal({ open: terminalModals.CASH_TIP, closingCash: res.closingCash });
			},
			err => {
				addErrorNotification(err.toString(), notificationSize);
				setIsOrderLoading(false);
			}
		);
	};

	const getValueForQuantityModal = () => {
		if (!modal.orderItems) return 1;

		if (modal.orderItems.length > 1 || modal.orderItems.length === 0)
			return modal.open === terminalModals.PRICE ? 0 : 1;

		const orderItem = orders[activeOrderIndex].orderItems.find(
			oi => oi.id === modal.orderItems[0]
		);
		return modal.open === terminalModals.PRICE ? orderItem.subtotal : orderItem.quantity;
	};

	const onQuantityModalSubmit = value => {
		if (modal.open === terminalModals.QUANTITY && value === 0) {
			addErrorNotification('Invalid Quantity', notificationSize);
			return;
		}

		if (!modal.orderItems || modal.orderItems.length === 0) {
			newOrderItem(
				modal.product,
				modal.open === terminalModals.PRICE ? value : modal.product.price,
				modal.open === terminalModals.QUANTITY ? value : 1
			);
		} else {
			let _orders = orders;

			modal.orderItems.forEach(oii => {
				const orderItemIndex = _orders[activeOrderIndex].orderItems.findIndex(
					oi => oi.id === oii
				);

				if (modal.open === terminalModals.PRICE)
					_orders = update(_orders, {
						[activeOrderIndex]: {
							orderItems: {
								[orderItemIndex]: {
									$merge: {
										quantity:
											_orders[activeOrderIndex].orderItems[orderItemIndex]
												.product.calculateQuantity &&
											_orders[activeOrderIndex].orderItems[orderItemIndex]
												.price
												? parseFloat(
														(
															value /
															_orders[activeOrderIndex].orderItems[
																orderItemIndex
															].price
														).toFixed(5)
												  )
												: _orders[activeOrderIndex].orderItems[
														orderItemIndex
												  ].quantity,
										subtotal: value,
										price: _orders[activeOrderIndex].orderItems[orderItemIndex]
											.product.calculateQuantity
											? _orders[activeOrderIndex].orderItems[orderItemIndex]
													.price
											: parseFloat(
													(
														value /
														_orders[activeOrderIndex].orderItems[
															orderItemIndex
														].quantity
													).toFixed(5)
											  ),
									},
								},
							},
						},
					});

				if (modal.open === terminalModals.QUANTITY) {
					const orderItem = _orders[activeOrderIndex].orderItems[orderItemIndex];

					let { discountAmount } = orderItem;

					if (
						orderItem.discount?.calculation?.value === discountCalculations.PER_QUANTITY
					) {
						discountAmount = (discountAmount / orderItem.quantity) * value;
					}

					_orders = update(_orders, {
						[activeOrderIndex]: {
							orderItems: {
								[orderItemIndex]: {
									$merge: {
										quantity: value,
										description: _orders[activeOrderIndex].orderItems[
											orderItemIndex
										].orderItemModifiers.length
											? getOrderItemDescription({
													..._orders[activeOrderIndex].orderItems[
														orderItemIndex
													],
													quantity: value,
											  })
											: null,
										subtotal: numberFormat(
											_orders[activeOrderIndex].orderItems[orderItemIndex]
												.price * value
										),
										discountAmount,
									},
								},
							},
						},
					});
				}
			});

			setOrders(
				update(_orders, {
					[activeOrderIndex]: {
						$merge: calculateOrderTotals(_orders[activeOrderIndex]),
					},
				})
			);

			takeBackupOnChangeOrders();
		}

		closeModal();
	};

	const getUsedDiscountForDiscountModal = () => {
		if (modal.open !== terminalModals.DISCOUNT) return null;

		if (modal.isOrder) {
			return orders[activeOrderIndex].discount
				? {
						...orders[activeOrderIndex].discount,
						type: orders[activeOrderIndex].discountType,
						amount: orders[activeOrderIndex].discountAmount,
				  }
				: null;
		}

		if (modal.orderItems.length > 1) return null;

		const orderItem = orders[activeOrderIndex].orderItems.find(
			oi => oi.id === modal.orderItems[0]
		);

		return orderItem.discount
			? {
					...orderItem.discount,
					type: orderItem.discountType,
					amount:
						orderItem.discount.calculation.value === discountCalculations.PER_QUANTITY
							? orderItem.discountAmount / orderItem.quantity
							: orderItem.discountAmount,
			  }
			: null;
	};

	const getTotalForDiscountModal = () => {
		if (modal.open !== terminalModals.DISCOUNT) return 0;

		if (modal.isOrder) return orders[activeOrderIndex].subtotal;

		if (modal.orderItems.length > 1)
			return orders[activeOrderIndex].orderItems
				.filter(oi => modal.orderItems.includes(oi.id))
				.map(oi => oi.subtotal)
				.reduce((a, b) => a + b);

		const orderItem = orders[activeOrderIndex].orderItems.find(
			oi => oi.id === modal.orderItems[0]
		);

		return numberFormat(orderItem.subtotal);
	};

	const onDiscountModalSubmit = discount => {
		let _orders = orders;

		const discountType = discount.amount
			? lists.current.calculations.find(
					c =>
						c.value ===
						(discount.calculation.value === discountCalculations.PERCENT
							? discountCalculations.PERCENT
							: discountCalculations.FIXED)
			  )
			: null;

		if (modal.isOrder) {
			_orders[activeOrderIndex] = discount.amount
				? {
						..._orders[activeOrderIndex],
						discount,
						discountType,
						discountAmount: discount.amount,
				  }
				: {
						..._orders[activeOrderIndex],
						discount: null,
						discountType: null,
						discountAmount: null,
				  };
			_orders = update(_orders, {
				[activeOrderIndex]: { $merge: calculateOrderTotals(_orders[activeOrderIndex]) },
			});
		} else {
			modal.orderItems.forEach(oii => {
				const orderItemIndex = _orders[activeOrderIndex].orderItems.findIndex(
					oi => oi.id === oii
				);

				let discountAmount = discount.amount || null;

				if (
					discountAmount &&
					discount.calculation.value === discountCalculations.PER_QUANTITY
				)
					discountAmount *= _orders[activeOrderIndex].orderItems[orderItemIndex].quantity;

				_orders = update(_orders, {
					[activeOrderIndex]: {
						orderItems: {
							[orderItemIndex]: {
								$merge: {
									discount: discount.amount ? discount : null,
									discountType,
									discountAmount,
								},
							},
						},
					},
				});
			});

			const orderTotals = calculateOrderTotals(_orders[activeOrderIndex]);

			if (
				orderTotals.total < 0 &&
				_orders[activeOrderIndex].discount.amount > orderTotals.subtotal
			) {
				_orders = update(_orders, {
					[activeOrderIndex]: { discountAmount: { $set: orderTotals.subtotal } },
				});
			} else if (
				_orders[activeOrderIndex].discountAmount &&
				orderTotals.discountTotal === 0
			) {
				_orders = update(_orders, {
					[activeOrderIndex]: { discountAmount: { $set: 0 } },
				});
			}

			_orders = update(_orders, {
				[activeOrderIndex]: { $merge: calculateOrderTotals(_orders[activeOrderIndex]) },
			});
		}

		setOrders(_orders);

		takeBackupOnChangeOrders();
		setSelectedOrderItems([]);
		closeModal();
	};

	const checkForAutoDiscounts = (_orders, _customer = null) => {
		let updateOrder = false;

		_orders[activeOrderIndex].orderItems.forEach((orderItem, orderItemIndex) => {
			if (!orderItem.discount) {
				const autoDiscount = applyAutoDiscountToItem(
					_customer || _orders[activeOrderIndex].customer,
					orderItem.product,
					orderItem.quantity,
					orderItem.subtotal,
					lists.current.discounts,
					lists.current.calculations
				);

				if (autoDiscount.discount) {
					_orders = update(_orders, {
						[activeOrderIndex]: {
							orderItems: {
								[orderItemIndex]: {
									$merge: autoDiscount,
								},
							},
						},
					});

					updateOrder = true;
				}
			}
		});

		if (updateOrder) {
			setOrders(
				update(_orders, {
					[activeOrderIndex]: {
						$merge: calculateOrderTotals(_orders[activeOrderIndex]),
					},
				})
			);

			return {
				..._orders[activeOrderIndex],
				...calculateOrderTotals(_orders[activeOrderIndex]),
			};
		}

		return null;
	};

	const onClearDiscountOrderItemSubmit = orderItemId => {
		let _orders = orders;

		const orderItemIndex = _orders[activeOrderIndex].orderItems.findIndex(
			oi => oi.id === orderItemId
		);

		_orders = update(_orders, {
			[activeOrderIndex]: {
				orderItems: {
					[orderItemIndex]: {
						$merge: { discount: null, discountType: null, discountAmount: null },
					},
				},
			},
		});

		_orders = update(_orders, {
			[activeOrderIndex]: { $merge: calculateOrderTotals(_orders[activeOrderIndex]) },
		});

		setOrders(_orders);
		takeBackupOnChangeOrders();
		setSelectedOrderItems([]);
	};

	const onClearDiscountSubmit = () => {
		let _orders = orders;

		_orders = update(_orders, {
			[activeOrderIndex]: {
				$merge: {
					discount: null,
					discountType: null,
					discountAmount: null,
				},
			},
		});

		_orders = update(_orders, {
			[activeOrderIndex]: { $merge: calculateOrderTotals(_orders[activeOrderIndex]) },
		});

		setOrders(_orders);
		takeBackupOnChangeOrders();
		setSelectedOrderItems([]);
	};

	const getNoteForNoteModal = (isExternal = false) => {
		if (modal.open !== terminalModals.NOTE && modal.open !== terminalModals.EXTERNAL_NOTE)
			return '';

		if (modal.isOrder)
			return isExternal
				? orders[activeOrderIndex].externalNote
				: orders[activeOrderIndex].note;

		if (modal.orderItems.length > 1) return '';

		return orders[activeOrderIndex].orderItems.find(oi => oi.id === modal.orderItems[0]).note;
	};

	const onNoteModalSubmit = (note, isExternal = false) => {
		let _orders = orders;

		if (modal.isOrder) {
			_orders = update(_orders, {
				[activeOrderIndex]: isExternal
					? { externalNote: { $set: note === '' ? null : note } }
					: { note: { $set: note === '' ? null : note } },
			});
		} else {
			modal.orderItems.forEach(oii => {
				const orderItemIndex = orders[activeOrderIndex].orderItems.findIndex(
					oi => oi.id === oii
				);

				_orders = update(_orders, {
					[activeOrderIndex]: {
						orderItems: {
							[orderItemIndex]: { note: { $set: note === '' ? null : note } },
						},
					},
				});
			});
		}

		setOrders(_orders);

		takeBackupOnChangeOrders();
		setSelectedOrderItems([]);
		closeModal();
	};

	const getSeatsForSeatsModal = () => {
		if (modal.open !== terminalModals.SEATS) return 0;

		return orders[activeOrderIndex].seats || 0;
	};

	const onSeatsModalSubmit = seats => {
		let _orders = orders;

		_orders = update(_orders, {
			[activeOrderIndex]: { seats: { $set: seats === 0 ? null : seats } },
		});
		setOrders(_orders);

		takeBackupOnChangeOrders();
		setSelectedOrderItems([]);
		closeModal();
	};

	const getTaxCodeForTaxModal = () => {
		if (modal.open !== terminalModals.TAX || modal.orderItems.length > 1) return null;

		return orders[activeOrderIndex].orderItems.find(oi => oi.id === modal.orderItems[0])
			.taxCode;
	};

	const onTaxCodeModalSubmit = taxCode => {
		let _orders = orders;

		modal.orderItems.forEach(oii => {
			const orderItemIndex = _orders[activeOrderIndex].orderItems.findIndex(
				oi => oi.id === oii
			);

			_orders = update(_orders, {
				[activeOrderIndex]: {
					orderItems: {
						[orderItemIndex]: {
							$merge: {
								taxCode,
								taxRate: getOrderItemTaxRate(
									_orders[activeOrderIndex].orderItems[orderItemIndex].product,
									_orders[activeOrderIndex].orderItems[orderItemIndex].price,
									taxRate.current,
									taxCode
								),
							},
						},
					},
				},
			});
		});

		_orders = update(_orders, {
			[activeOrderIndex]: { $merge: calculateOrderTotals(_orders[activeOrderIndex]) },
		});

		setOrders(_orders);
		takeBackupOnChangeOrders();
		setSelectedOrderItems([]);
		closeModal();
	};

	const getUsersForUserModal = () => {
		if (!orders[activeOrderIndex]) return [];

		if (register.unitMap && orders[activeOrderIndex].unit)
			return lists.current.users.filter(
				u => u.units.findIndex(t => t.id === orders[activeOrderIndex].unit.id) > -1
			);

		return lists.current.users.filter(
			u => u.registers.findIndex(r => r.id === register.id) > -1
		);
	};

	const onSelectUserModalSubmit = user => {
		setOrders(update(orders, { [activeOrderIndex]: { server: { $set: user } } }));
		takeBackupOnChangeOrders();
		closeModal();
	};

	const onCustomerModalSubmit = (_customer, _vessel) => {
		let _orders = orders;

		_orders = update(_orders, {
			[activeOrderIndex]: {
				$merge: {
					customer: _customer,
					vessel: _vessel,
					paymentTerm: getPaymentTermByModule(register.outlet, _customer),
					orderItems: orders[activeOrderIndex].orderItems.map(orderItem => {
						const taxCode = getItemTaxCode(_customer.taxCode, orderItem.product);

						orderItem.taxCode = taxCode;
						orderItem.taxRate = getOrderItemTaxRate(
							orderItem.product,
							orderItem.price,
							taxRate.current,
							taxCode
						);

						return orderItem;
					}),
				},
			},
		});

		_orders = update(_orders, {
			[activeOrderIndex]: { $merge: calculateOrderTotals(_orders[activeOrderIndex]) },
		});

		setOrders(_orders);
		checkForAutoDiscounts(_orders);
		takeBackupOnChangeOrders();
		closeModal();
	};

	const onTableModalSubmit = unit => {
		setOrders(update(orders, { [activeOrderIndex]: { unit: { $set: unit } } }));
		takeBackupOnChangeOrders();
		closeModal();
	};

	const productSearch = sku => {
		if (sku.trim() === '' || isOrderLoading) return;

		if (!orders[activeOrderIndex]) {
			addErrorNotification('Cannot find order', notificationSize);
			return;
		}

		setIsOrderLoading(true);

		apiCall(
			'POST',
			'terminalProductSearchBySku',
			res => {
				setIsOrderLoading(false);

				if (res.modifierSections.length && res.alwaysModify) {
					setActiveProduct(res);
					setActiveOrderItemIndex(null);
					changeScreen(screens.MODIFIER);
				} else if (res.addByPrice) {
					openModal({ open: terminalModals.PRICE, product: res });
				} else if (res.addByQuantity) {
					openModal({ open: terminalModals.QUANTITY, product: res });
				} else newOrderItem(res, res.price, 1);
			},
			() => {
				setIsOrderLoading(false);
				openModal({ open: terminalModals.PRODUCT_NOT_FOUND });
			},
			'',
			{ sku }
		);
	};

	const onReceiptPromptSubmit = answer => {
		const { order, payment, paymentType } = modal.printData;

		saveOrder(null, { ...order, printReceiptOnFinalize: answer });

		if (
			answer ||
			paymentType === paymentTypes.CREDIT_CARD ||
			paymentType === paymentTypes.HOUSE_ACCOUNT
		)
			print.current.receiptPrint(order, payment);

		closeModal();
	};

	const onPay = (paymentData, orderId = null) => {
		if (!orders[activeOrderIndex]) return;

		if (orders[activeOrderIndex].status.value === orderStatuses.REFUNDED)
			saveRefundOrder(paymentData);
		else {
			setIsOrderLoading(true);

			apiCall(
				'POST',
				'saleOrderPay',
				res => {
					if (res.order)
						setOrders(update(orders, { [activeOrderIndex]: { $set: res.order } }));

					if (res.success) {
						if (paymentData.holdForSignature) {
							if (register.unitMap) changeScreen(screens.MAP);
							else newOrder();
						}

						closeModal();

						if (register.printer) {
							const {
								openCashDrawerOnCheck,
								posPromptForReceipt,
							} = register.outlet.settings;

							if (posPromptForReceipt) {
								openModal({
									open: terminalModals.PRINT_RECEIPT_PROMPT,
									printData: {
										order: res.order,
										payment: invoiceToPayment(
											res.order.invoices[res.order.invoices.length - 1]
										),
										paymentType: paymentData.type,
									},
								});

								if (
									paymentData.type === paymentTypes.CASH ||
									(paymentData.type === paymentTypes.CHECK &&
										openCashDrawerOnCheck)
								)
									print.current.openCashDrawer();
							} else if (paymentData.printReceipt) {
								print.current.receiptPrint(
									res.order,
									invoiceToPayment(
										res.order.invoices[res.order.invoices.length - 1]
									),
									null,
									res.customerBalance
								);
							}
						}

						setIsOrderLoading(false);
					} else {
						setIsOrderLoading(false);
						addErrorNotification(res.message, notificationSize);
					}
				},
				err => {
					setIsOrderLoading(false);
					addErrorNotification(err.toString(), notificationSize);
				},
				'',
				{
					orderId: orderId || orders[activeOrderIndex].id,
					customer: paymentData.customer.id,
					register: register.id,
					...paymentData,
				}
			);
		}
	};

	const onFinalize = () => {
		if (!orders[activeOrderIndex]) return;

		setIsOrderLoading(true);

		apiCall(
			'POST',
			'saleOrderFinalize',
			res => {
				setOrders(update(orders, { [activeOrderIndex]: { $set: res } }));
				setIsOrderLoading(false);

				if (orders[activeOrderIndex].printReceiptOnFinalize !== false) {
					res.invoices.forEach(i => {
						if (i.fromHouseAccount && !i.holdForSignature)
							print.current.receiptPrint(res, invoiceToPayment(i));

						if (
							!i.fromHouseAccount &&
							!i.holdForSignature &&
							i.customerSettlements[0].remittance.paymentMethod.paymentType.value ===
								paymentTypes.CREDIT_CARD
						)
							print.current.receiptPrint(res, invoiceToPayment(i));
					});
				}

				if (inReservationForm) onBackToReservation();
				else if (screen === screens.RECALL) setActiveOrderIndex(null);
				else if (register.unitMap) changeScreen(screens.MAP);
				else newOrder();
			},
			err => {
				setIsOrderLoading(false);
				addErrorNotification(err.toString(), notificationSize);
			},
			'',
			{
				id: orders[activeOrderIndex].id,
			}
		);
	};

	const onPaymentModalSubmit = payments => {
		if (!orders[activeOrderIndex]) return;

		let paymentsTotal = 0;

		orders[activeOrderIndex].invoices
			.filter(i => payments.findIndex(p => p.id === i.id) === -1)
			.forEach(i => {
				paymentsTotal += i.total - (i.tip || 0);
			});

		payments.forEach(p => {
			paymentsTotal += p.amount;
		});

		if (parseFloat(paymentsTotal).toFixed(2) > orders[activeOrderIndex].total) {
			addErrorNotification(
				'Sum of payments amount cannot be more than order total',
				notificationSize
			);
			return;
		}

		setIsOrderLoading(true);

		apiCall(
			'POST',
			'saleOrderPaymentEdit',
			res => {
				setOrders(update(orders, { [activeOrderIndex]: { $set: res } }));
				setIsOrderLoading(false);
				closeModal();
			},
			err => {
				setIsOrderLoading(false);
				addErrorNotification(err.toString(), notificationSize);
			},
			'',
			{
				id: orders[activeOrderIndex].id,
				payments,
			}
		);
	};

	const onCashTipModalSubmit = cashTip => {
		setIsOrderLoading(true);

		closeModal();

		addSuccessNotification('Getting server data...', notificationSize);

		apiCall(
			'POST',
			'getServerCloseOutData',
			res => {
				setIsOrderLoading(false);
				print.current.serverCloseOut(
					userContext.data.user,
					res.orders,
					res.refunds,
					register,
					res.cashTip,
					res.defaultIncomeAccount
				);
			},
			err => {
				addErrorNotification(err.toString(), notificationSize);
				setIsOrderLoading(false);
			},
			'',
			{ cashTip, register: register.id }
		);
	};

	const reloadRelatedOrders = order => {
		reloadRelatedInProgress.current = true;

		apiCall(
			'POST',
			'recallReload',
			res => {
				reloadRelatedInProgress.current = false;

				const missingOrders = res.orders.filter(
					o => orders.findIndex(_o => _o.id === o.id) === -1
				);
				if (missingOrders.length)
					setOrders(
						update(orders, {
							$push: missingOrders,
						})
					);
			},
			err => {
				addErrorNotification(err.toString(), notificationSize);
				reloadRelatedInProgress.current = false;
			},
			'',
			{ orderId: (order.parentOrder || order).id }
		);
	};

	const getAvailablePaymentTypes = isRefund => {
		const availablePaymentTypes = userHasPermission('cash_payments')
			? [...register.paymentTypes]
			: register.paymentTypes.filter(rt => rt.value !== paymentTypes.CASH);

		if (isRefund || !userHasPermission('pay_via_booking'))
			return availablePaymentTypes.filter(apt => apt.value !== paymentTypes.BOOKING);

		return availablePaymentTypes;
	};

	const onVoidAuthorization = payment => {
		setIsAuthorizing(true);

		apiCall(
			'POST',
			'voidAuthorizedPayment',
			() => {
				addSuccessNotification('Successfully voided');
				reloadOrder();
				closeModal();
				setIsAuthorizing(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsAuthorizing(false);
			},
			'',
			{
				id: payment.id,
				entityId: orders[activeOrderIndex].id,
				entityType: 'order',
			}
		);
	};

	// get terminal data.
	useEffect(() => {
		let autoLogoutIntervalId = 0;

		const windowType = window.ontouchstart ? 'touchstart' : 'mousemove';

		apiCall(
			'GET',
			'registers',
			res => {
				// if register not open
				if (!res.currentRegisterBatch || !res.loginAllowed) {
					userContext.logout('terminal');
					return;
				}

				lists.current = {
					discounts: res.discounts['hydra:member'],
					printStatuses: res.printStatuses['hydra:member'],
					orderStatuses: res.orderStatuses['hydra:member'],
					orderTypes: res.orderTypes['hydra:member'],
					prepStations: res.prepStations['hydra:member'],
					calculations: res.calculations['hydra:member'],
					taxCodes: res.taxCodes['hydra:member'],
					users: res.users['hydra:member'],
					enumBoatTypes: res.enumBoatTypes,
					enumBoatMakes: res.enumBoatMakes,
					discountCalculations: res.discountCalculations,
				};

				newOrderStatus.current = lists.current.orderStatuses.find(
					os => os.value === orderStatuses.OPEN
				);

				orderType.current = lists.current.orderTypes.find(
					ot => ot.value === orderTypes.SALE
				);

				newOrderItemPrintStatus.current = lists.current.printStatuses.find(
					ps => ps.value === printStatuses.NEW
				);

				doNotPrintOrderItemPrintStatus.current = lists.current.printStatuses.find(
					ps => ps.value === printStatuses.DONOTPRINT
				);

				if (!res.outlet.settings.taxRate) {
					setError('Missing outlet tax rate!');
					setIsLoading(false);
					return;
				}

				taxRate.current = res.outlet.settings.taxRate.amount;

				print.current = new WebPrint({
					user: userContext.data.user,
					register: res,
					outlet: res.outlet,
					printers: res.printers['hydra:member'],
					enumPrinterLogActions: res.enumPrinterLogActions['hydra:member'],
					onStart: () => addSuccessNotification('Printing...', notificationSize),
					onFinish: onPrintFinish,
					onFail: onPrintFail,
					onServicePrintFinish: onAllServicePrintFinish,
				});

				setRegister(res);

				setError('');

				setIsLoading(false);

				// if register has not table map open product grid with new order.
				changeScreen(res.unitMap ? screens.MAP : screens.GRID);

				if (res.registerType.checkInterval) {
					registerWorkerStart(({ data }) => {
						if (!data.loginAllowed) {
							registerWorkerStop();
							openModal({ open: terminalModals.TERMINAL_CLOSE });
							setTimeout(() => {
								userContext.logout('terminal');
							}, res.registerType.checkInterval * 1000);
						}
					}, res.registerType.checkInterval);
				}

				if (res.autoLogoutTime && !inReservationForm) {
					count.current = res.autoLogoutTime;

					document.addEventListener(windowType, () => {
						count.current = res.autoLogoutTime;
					});

					autoLogoutIntervalId = setInterval(() => {
						if (count.current <= logoutCounter)
							openModal({ open: terminalModals.COUNT_DOWN });
						if (count.current <= 0) userContext.logout('terminal');
						count.current -= 1;
					}, 1000);
				}
			},
			err => {
				setError(err.toString());
				setIsLoading(false);
			},
			id,
			null,
			{ 'groups[]': 'terminal:read' }
		);
		return () => {
			document.removeEventListener(windowType, () => {});
			clearInterval(autoLogoutIntervalId);
			registerWorkerTerminate();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//	Scrollbar
	useLayoutEffect(() => {
		if (register && register.largerScrollbars) {
			document.body.classList.add('sdms-scrollbars--large');
		}
		return () => {
			document.body.classList.remove('sdms-scrollbars--large');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [register.largerScrollbars]);

	// update order when table change.
	useEffect(() => {
		if (table) {
			changeScreen(screens.GRID);

			if (table.openNewOrderByDefault) {
				newOrder();
			} else {
				setIsOrderLoading(true);
				const filters = {
					'unit.id': table.id,
					'status.id[]': [
						orderStatuses.OPEN,
						orderStatuses.PARTIAL,
						orderStatuses.PAID,
						orderStatuses.FINALIZED,
					].map(o => lists.current.orderStatuses.find(os => os.value === o).id),
					'groups[]': 'order-sale:read',
					pagination: false,
				};

				if (!userContext.hasPermission('view_others_orders'))
					filters.server = userContext.data.user.id;

				apiCall(
					'GET',
					'saleOrders',
					res => {
						const openOrders = res.filter(o => o.status.value === orderStatuses.OPEN);

						if (openOrders.length === 0) newOrder();
						else {
							setOrders(res);
							setActiveOrderIndex(
								res.findIndex(o => o.id === openOrders[openOrders.length - 1].id)
							);
						}

						setIsOrderLoading(false);
					},
					err => {
						addErrorNotification(err.toString(), notificationSize);
					},
					'',
					null,
					filters
				);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [table]);

	useEffect(() => {
		if (screen === screens.GRID && orders.length === 0) newOrder(null, null, null, false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [screen]);

	useEffect(() => {
		if (print.current) {
			print.current.onFinish = onPrintFinish;
			print.current.onFail = onPrintFail;
			print.current.onServicePrintFinish = onAllServicePrintFinish;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orders]);

	useEffect(() => {
		if (screen === screens.RECALL && orders[activeOrderIndex]) {
			setSideCar(true);
		}
	}, [activeOrderIndex, orders, screen]);

	useEffect(() => {
		if (
			screen === screens.RECALL &&
			orders[activeOrderIndex] &&
			!reloadRelatedInProgress.current
		)
			reloadRelatedOrders(orders[activeOrderIndex]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orders]);

	if (isLoading) return <ReactSVG src={logoLoading} className='svg-container logo-loading' />;

	if (error !== '')
		return (
			<div className='row'>
				<div className='col-12 sdms-mb-20'>
					<Alert solid icon='Info-circle' design='warning'>
						{error}
					</Alert>
				</div>
			</div>
		);

	const LeftMenu = (
		<>
			<Nav.TerItem icon='Clock' className='sdms-font-monospace'>
				<Clock />
			</Nav.TerItem>
			{register.paymentTypes.findIndex(r => r.value === 'Cash') !== -1 && (
				<Nav.TerItem
					icon='Dollar'
					// TODO: Disabled if cash not in register payment types !!!
					isDisable={isOrderLoading || register.printer === null}
					onClick={() => {
						print.current.openCashDrawer();
						setOverlayInitial();
					}}
					noPermission={!userHasPermission('open_cash_drawer')}>
					Cash Drawer
				</Nav.TerItem>
			)}
			<Nav.TerItem
				icon='Clipboard-list'
				isActive={screen === screens.PAYBILL}
				onClick={() => {
					changeScreen(screens.PAYBILL === screen ? prevScreen.current : screens.PAYBILL);
					setOverlayInitial();
				}}
				isDisable={isOrderLoading}
				noPermission={!userHasPermission('access_pay_bill')}>
				Invoices
			</Nav.TerItem>
			{!inReservationForm && (
				<Nav.TerItem
					icon='Sign-out'
					onClick={() => {
						if (hasOrderChange) {
							openModal({ open: terminalModals.UNSAVED, onSubmit: serverCloseOut });
							return;
						}
						serverCloseOut();
						setOverlayInitial();
					}}
					isDisable={isOrderLoading}>
					Server Close Out
				</Nav.TerItem>
			)}
		</>
	);

	const RightMenu = (
		<>
			<Nav.TerItem
				icon='File-plus'
				onClick={() => {
					if (register.unitMap && !table) {
						changeScreen(screens.MAP);
						addErrorNotification('Please select a table.', notificationSize);
						return;
					}

					if (hasOrderChange) {
						setScreen(screens.GRID);

						openModal({
							open: terminalModals.UNSAVED,
							onSubmit: () => {
								closeModal();
								newOrder();
							},
						});

						return;
					}

					setScreen(screens.GRID);
					newOrder();
					setOverlayInitial();
				}}
				noPermission={!userHasPermission('new_orders')}
				isDisable={isOrderLoading}>
				New Order
			</Nav.TerItem>
			{!inReservationForm && (
				<>
					<Nav.TerItem
						icon='Undo'
						isActive={
							orders[activeOrderIndex] &&
							orders[activeOrderIndex].status.value === orderStatuses.REFUNDED
						}
						isDisable={isOrderLoading}
						onClick={() => {
							const refundOrderStatus = lists.current.orderStatuses.find(
								os => os.value === orderStatuses.REFUNDED
							);

							const refundOrderType = lists.current.orderTypes.find(
								ot => ot.value === orderTypes.REFUND
							);

							if (hasOrderChange) {
								setScreen(screens.GRID);

								openModal({
									open: terminalModals.UNSAVED,
									onSubmit: () => {
										closeModal();
										newOrder(null, refundOrderType, refundOrderStatus);
									},
								});

								return;
							}

							setScreen(screens.GRID);
							newOrder(null, refundOrderType, refundOrderStatus);
							setOverlayInitial();
						}}
						noPermission={!userHasPermission('issue_refunds')}>
						Refund
					</Nav.TerItem>
					<Nav.TerItem
						icon='File-cloud'
						isActive={screen === screens.RECALL}
						onClick={() => {
							if (hasOrderChange) {
								openModal({
									open: terminalModals.UNSAVED,
									onSubmit: () => {
										closeModal();
										changeScreen(screens.RECALL);
									},
								});

								return;
							}
							changeScreen(screens.RECALL);
							setOverlayInitial();
						}}
						isDisable={isOrderLoading}>
						Orders
					</Nav.TerItem>
				</>
			)}

			{register.unitMap ? (
				<Nav.TerItem
					icon='Subtract'
					isActive={
						screen === screens.MAP ||
						(screen !== screens.RECALL &&
							orders[activeOrderIndex] &&
							orders[activeOrderIndex].status.value === orderStatuses.OPEN)
					}
					onClick={() => {
						if (hasOrderChange) {
							openModal({
								open: terminalModals.UNSAVED,
								onSubmit: () => {
									closeModal();
									changeScreen(screens.MAP);
								},
							});

							return;
						}

						changeScreen(screens.MAP);
						setOverlayInitial();
					}}
					isDisable={isOrderLoading}>
					{table ? table.name : 'No Table'}
				</Nav.TerItem>
			) : (
				isLoading && <Nav.TerItem icon='Subtract'>No Table</Nav.TerItem>
			)}
			{inReservationForm ? (
				<Nav.TerItem
					icon='Sign-out'
					onClick={onBackToReservation}
					isDisable={isOrderLoading}>
					Back to Reservation
				</Nav.TerItem>
			) : (
				<Nav.TerItem
					icon='Sign-out'
					onClick={() => {
						if (hasOrderChange) {
							openModal({
								open: terminalModals.UNSAVED,
								onSubmit: () => {
									closeModal();
									userContext.logout('terminal');
								},
							});

							return;
						}
						userContext.logout('terminal');
						setOverlayInitial();
					}}
					isDisable={isOrderLoading}>
					{windowSize.width < mediaBreakpoint.LG && 'Logout'}
				</Nav.TerItem>
			)}
		</>
	);

	const _title = `${!isLoading && `${register.outlet.name} - ${register.name}`} | Sharper MMS`;

	if (
		windowSize.width < mediaBreakpoint.MD &&
		windowSize.width > windowSize.height &&
		windowSize.landscape
	) {
		return <RotateContent />;
	}

	if (error)
		return (
			<Container id='sdms_terminal'>
				<Container.Page>
					<Container.Wrapper>{error}</Container.Wrapper>
				</Container.Page>
			</Container>
		);

	return (
		<>
			<HeaderMobile
				isHeader
				overlay={overlay}
				setOverlay={setOverlay}
				isSideCar={hasSideCar && (screen === screens.GRID || screen === screens.RECALL)}
				screen={screen}
				sideCar={sideCar}
				setSideCar={setSideCar}
				register={register}
				isLoading={isLoading}
				isOrderLoading={isOrderLoading}
			/>
			<Container id='sdms_terminal'>
				<Container.Page>
					<Container.Wrapper addWrapperClass={!inReservationForm}>
						<Head className='sdms-header--dark'>
							<Head.MenuWrapper id='sdms_header_left_menu_wrapper'>
								<Head.Menu notMore>
									<Nav>{LeftMenu}</Nav>
								</Head.Menu>
							</Head.MenuWrapper>
							<div className='sdms-header__topbar align-items-center sdms-pr0 sdms-hidden-minimal-desktop-and-below'>
								<div className='row text-center'>
									<Loading isLoading={isLoading} type='div'>
										<div className='col sdms-font-lg sdms-font-light'>
											{!isLoading && register.outlet.name}
										</div>
									</Loading>
									<div className='w-100' />
									<Loading isLoading={isLoading} type='div'>
										<div className='col sdms-font-xl sdms-font-boldest sdms-font-light'>
											{!isLoading && register.name}
										</div>
									</Loading>
								</div>
							</div>
							<Head.MenuWrapper
								id='sdms_header_right_menu_wrapper'
								forMobile
								mobileRight
								overlay={overlay}
								setOverlay={setOverlay}>
								<Head.Menu notMore className='sdms-header-menu--last'>
									<Nav className='sdms-hidden-desktop'>{LeftMenu}</Nav>
									<Nav>{RightMenu}</Nav>
								</Head.Menu>
							</Head.MenuWrapper>
						</Head>
						{(screen === screens.GRID || screen === screens.RECALL) && (
							<Container.Content>
								<ContentInner.Container title={_title}>
									<div
										className={classNames('row', 'h-100', {
											'sdms-t-sidecar': hasSideCar,
											'sdms-vh-100':
												!hasSideCar && navigator.vendor.includes('Apple'),
										})}>
										{screen === screens.GRID && (
											<ProductGrid
												data={productGrid || register.grid}
												openModifier={(product, doNotPrint = false) => {
													if (isOrderLoading) return;
													setActiveProduct(product);
													setActiveOrderItemIndex(null);
													setCurrentDoNotPrint(doNotPrint);
													changeScreen(screens.MODIFIER);
												}}
												openPriceModal={product => {
													if (isOrderLoading) return;
													openModal({
														open: terminalModals.PRICE,
														product,
													});
												}}
												openQuantityModal={product => {
													if (isOrderLoading) return;
													openModal({
														open: terminalModals.QUANTITY,
														product,
													});
												}}
												addOrderItem={newOrderItem}
												hasSearchMorePermission={userHasPermission(
													'search_more'
												)}
												isOrderLoading={isOrderLoading}
												isDisable={
													!(
														orders[activeOrderIndex] &&
														(orders[activeOrderIndex].status.value ===
															orderStatuses.OPEN ||
															orders[activeOrderIndex].status
																.value === orderStatuses.REFUNDED)
													)
												}
												hasSideCar={hasSideCar}
											/>
										)}
										{screen === screens.RECALL && (
											<RecallOrders
												users={lists.current.users}
												orderStatuses={lists.current.orderStatuses}
												tables={userContext.data.user.units}
												canViewOthersOrders={userHasPermission(
													'view_others_orders'
												)}
												outlet={register.outlet}
												user={userContext.data.user}
												onClick={(order, relatedOrders) => {
													setOrders(relatedOrders);
													setActiveOrderIndex(
														relatedOrders.length
															? relatedOrders.findIndex(
																	ro => ro.id === order.id
															  )
															: null
													);
												}}
												selectedOrder={orders[activeOrderIndex]}
												isOrderLoading={isOrderLoading}
												setIsOrderLoading={setIsOrderLoading}
												hasSideCar={hasSideCar}
											/>
										)}
										<Order
											data={orders[activeOrderIndex]}
											orders={getRelatedOrders(
												orders,
												orders[activeOrderIndex]
											).filter(o => o.status.value !== orderStatuses.VOIDED)}
											openSplit={
												inReservationForm
													? null
													: () => changeScreen(screens.SPLIT)
											}
											selectedOrderItems={selectedOrderItems}
											onSelectOrderItem={(isSelected, orderItemId) => {
												if (isSelected)
													setSelectedOrderItems(
														update(selectedOrderItems, {
															$push: [orderItemId],
														})
													);
												else {
													setSelectedOrderItems(oldSelectedOrderItems =>
														oldSelectedOrderItems.filter(
															oii => oii !== orderItemId
														)
													);
												}
											}}
											onSelectAllOrderItems={isSelected => {
												if (orders[activeOrderIndex])
													setSelectedOrderItems(
														isSelected
															? orders[
																	activeOrderIndex
															  ].orderItems.map(oi => oi.id)
															: []
													);
											}}
											openModal={openModal}
											changeActiveOrder={orderId => {
												setActiveOrderIndex(
													orders.findIndex(o => o.id === orderId)
												);
												setHasOrderChange(false);
												setSelectedOrderItems([]);
											}}
											saveOrder={saveOrder}
											isLoading={isOrderLoading}
											userHasPermission={userHasPermission}
											onOrderItemEdit={(product, index) => {
												if (!product.modifierSection) {
													register.grid.gridGroups.forEach(gg => {
														gg.gridGroupProducts.forEach(ggp => {
															if (ggp.product.id === product.id)
																product = ggp.product;
														});
													});
												}

												setActiveProduct(product);
												setActiveOrderItemIndex(index);
												changeScreen(screens.MODIFIER);
											}}
											orderPrint={() => {
												try {
													print.current.orderPrint([
														orders[activeOrderIndex],
													]);
												} catch (e) {
													addErrorNotification(
														e.toString(),
														notificationSize
													);
												}
											}}
											servicePrint={() =>
												saveOrder(print.current.actions.SERVICE)
											}
											deleteOrderItem={deleteOrderItems}
											getSendableOrderItems={getSendableOrderItems}
											isCreditCardEnabled={
												register.paymentTypes
													? register.paymentTypes.findIndex(
															pt =>
																pt.value ===
																paymentTypes.CREDIT_CARD
													  ) > -1
													: false
											}
											isCashEnabled={
												register.paymentTypes
													? register.paymentTypes.findIndex(
															pt => pt.value === paymentTypes.CASH
													  ) > -1
													: false
											}
											defaultCreditCard={
												userContext.data.user.company.settings
													.defaultCreditCard
											}
											paymentType={register.paymentTypes}
											onFinalize={onFinalize}
											paymentPrint={payment => {
												setIsOrderLoading(true);
												print.current.receiptPrint(
													orders[activeOrderIndex],
													{
														...payment,
														amount:
															payment.amount +
															(payment.serviceFee || 0),
													}
												);
											}}
											enableOverlay={
												orders[activeOrderIndex] &&
												orders[activeOrderIndex].status.value ===
													orderStatuses.OPEN &&
												screen === screens.RECALL
											}
											onOverlayClick={() => {
												changeScreen(screens.GRID);
											}}
											discountAllowed={lists.current.discounts.length > 0}
											clearDiscount={onClearDiscountSubmit}
											clearOrderItemDiscount={onClearDiscountOrderItemSubmit}
											hasOrderChange={hasOrderChange}
											closeModal={closeModal}
											noPermission={
												orders[activeOrderIndex] &&
												orders[activeOrderIndex].server.id !==
													userContext.data.user.id &&
												!userHasPermission('edit_others_orders')
											}
											hasSideCar={hasSideCar}
											onPayWithBooking={
												reservationItem
													? () =>
															saveOrder(
																afterSaveActions.PAY_WITH_BOOKING
															)
													: null
											}
										/>
									</div>
								</ContentInner.Container>
							</Container.Content>
						)}
						{screen === screens.MAP && !isLoading && (
							<TableMap
								data={register.unitMap}
								onChange={_table => setTable(_table)}
								title={_title}
								enableAssignServer={
									register.outlet
										? register.outlet.settings.enableAssignServer
										: true
								}
							/>
						)}
						{screen === screens.PAYBILL && (
							<PayBill
								paymentTypes={register.paymentTypes}
								userHasPermission={userHasPermission}
								register={register}
								onClose={() =>
									changeScreen(
										screens.PAYBILL === screen
											? prevScreen.current
											: screens.PAYBILL
									)
								}
								title={_title}
								onChange={modalIsRequired => {
									hasPayBillChange.current = modalIsRequired;
								}}
								webPrint={print.current}
								customerFor={customer}
							/>
						)}
						{screen === screens.MODIFIER && (
							<Modifiers
								productData={activeProduct}
								orderItemData={
									activeOrderItemIndex !== null
										? orders[activeOrderIndex].orderItems[activeOrderItemIndex]
										: undefined
								}
								onAdd={addModifierOrderItem}
								onCancel={() => {
									setActiveProduct(null);
									setActiveOrderItemIndex(null);
									setCurrentDoNotPrint(false);
									changeScreen(screens.GRID);
								}}
								prepStations={lists.current.prepStations}
								title={_title}
								printStatuses={lists.current.printStatuses}
							/>
						)}
						{screen === screens.SPLIT && (
							<Split
								orders={getRelatedOrders(
									orders,
									orders[activeOrderIndex],
									true
								).filter(o => o.status.value !== orderStatuses.VOIDED)}
								activeOrderId={
									orders[activeOrderIndex] ? orders[activeOrderIndex].id : 0
								}
								onCancel={() => changeScreen(screens.GRID)}
								onSave={onSplitSave}
								createOrder={() =>
									createOrder(
										register.outlet,
										register,
										table,
										orderType.current,
										newOrderStatus.current,
										{
											'@id': userContext.data.user['@id'],
											'@type': userContext.data.user['@type'],
											id: userContext.data.user.id,
											displayName: userContext.data.user.displayName,
										},
										customer,
										vessel
									)
								}
								title={_title}
							/>
						)}
					</Container.Wrapper>
				</Container.Page>
				<DialogBox
					open={modal.open === terminalModals.UNSAVED}
					title=''
					content='There are unsaved changes on this order.'
					type='question'
					onClose={closeModal}>
					<Button
						className='sdms-font-transform-c'
						label='danger'
						text='Proceed Without Saving'
						icon='Angle-right-circle'
						onClick={() => {
							setOrders(
								update(orders, {
									[activeOrderIndex]: { $set: backupOrders.current },
								})
							);
							modal.onSubmit();
						}}
					/>
					<Button
						className='sdms-font-transform-c'
						design='clean'
						text='Return to Order'
						icon='Clipboard-list'
						onClick={closeModal}
					/>
				</DialogBox>
				<DialogBox
					open={modal.open === terminalModals.RELOAD}
					title=''
					content={
						orders[activeOrderIndex]
							? `${orders[activeOrderIndex].name} has been changed since you opened it.`
							: ''
					}
					type='question'
					onClose={closeModal}>
					<Button label='success' text='Reload' onClick={modal.onSubmit} />
				</DialogBox>
				<DialogBox
					open={modal.open === terminalModals.VOID_PAYMENT}
					title='Are you sure?'
					content='You can not undo this action'
					type='question'
					onClose={closeModal}>
					<Button
						className='sdms-font-transform-c'
						label='danger'
						icon='Trash'
						text='Yes, Void!'
						onClick={() => {
							closeModal();
							voidPayment(modal.invoice);
						}}
					/>
					<Button
						className='sdms-font-transform-c'
						design='clean'
						icon='Error-circle'
						text={`No, Don't void!`}
						onClick={closeModal}
					/>
				</DialogBox>
				<OrderVoidModal
					isOpen={modal.open === terminalModals.VOID_ORDER}
					onClose={closeModal}
					onVoid={voidReason => {
						voidOrder(voidReason);
						closeModal();
					}}
					withNote={
						register.outlet &&
						register.outlet.settings.requireVoidReason &&
						orders[activeOrderIndex] &&
						orders[activeOrderIndex].id !== 0
					}
				/>
				<DialogBox
					open={modal.open === terminalModals.REFUND_PAYMENT}
					title='Are you sure?'
					content='You can not undo this action'
					type='question'
					onClose={closeModal}>
					<Button
						label='danger'
						icon='Trash'
						text='Yes, Refund!'
						onClick={() => {
							voidPayment(modal.invoice);
							closeModal();
						}}
					/>
					<Button
						design='clean'
						icon='Error-circle'
						text={`No, Don't refund`}
						onClick={closeModal}
					/>
				</DialogBox>
				<DialogBox
					open={modal.open === terminalModals.PRODUCT_NOT_FOUND}
					title=''
					content='Cannot find product!'
					type='warning'
					onClose={closeModal}>
					<Button design='clean' icon='Error-circle' text='Ok' onClick={closeModal} />
				</DialogBox>
				<DialogBox
					open={modal.open === terminalModals.COUNT_DOWN}
					title={<span>Logging out in {count.current} second(s)</span>}
					content={
						<div className='sdms-progress sdms-progress--info'>
							<progress max={logoutCounter} value={count.current} />
						</div>
					}
					type='warning'>
					<Button
						className='sdms-font-transform-c'
						label='danger'
						icon='Sign-out'
						text='Logout'
						onClick={() => userContext.logout('terminal')}
					/>
					<Button
						className='sdms-font-transform-c'
						design='clean'
						icon='Angle-right-circle'
						text='Continue'
						onClick={() => {
							count.current = register.autoLogoutTime;
							closeModal();
						}}
					/>
				</DialogBox>
				<DialogBox
					open={modal.open === terminalModals.PAYBILL_QUIT}
					title=''
					content='Are you sure to close without paying the bill?'
					type='question'
					onClose={closeModal}>
					<Button
						className='sdms-font-transform-c'
						label='danger'
						icon='Angle-left-circle'
						text='Proceed Without Paying'
						onClick={() => {
							hasPayBillChange.current = false;
							changeScreen(
								screens.PAYBILL === screen ? prevScreen.current : screens.PAYBILL
							);
							closeModal();
						}}
					/>
					<Button
						className='sdms-font-transform-c'
						design='clean'
						icon='Dollar'
						text='Continue paying'
						onClick={closeModal}
					/>
				</DialogBox>
				<QuantityModal
					modalTitle={modal.open === terminalModals.PRICE ? 'Price' : 'Quantity'}
					initValue={quantityFormatter(getValueForQuantityModal())}
					defaultValue={0}
					isOpen={
						modal.open === terminalModals.PRICE ||
						modal.open === terminalModals.QUANTITY
					}
					lowerLimit={0}
					upperLimit={parseFloat(process.env.REACT_APP_PRICE_UPPER_LIMIT)}
					onClose={closeModal}
					setDashValue={value => onQuantityModalSubmit(parseFloat(value))}
				/>
				<DiscountModal
					isOpen={modal.open === terminalModals.DISCOUNT}
					usedDiscount={getUsedDiscountForDiscountModal()}
					updateDiscount={onDiscountModalSubmit}
					total={getTotalForDiscountModal()}
					onClose={closeModal}
					discountPresets={
						register.outlet
							? [
									register.outlet.settings.posDiscountPresetOne,
									register.outlet.settings.posDiscountPresetTwo,
									register.outlet.settings.posDiscountPresetThree,
									register.outlet.settings.posDiscountPresetFour,
							  ].filter(dp => !!dp)
							: []
					}
					discounts={lists.current.discounts || []}
					calculations={lists.current.discountCalculations || []}
					disableFixed={modal.orderItems && modal.orderItems.length > 1}
					orderItems={
						modal.orderItems && orders[activeOrderIndex]
							? orders[activeOrderIndex].orderItems.filter(
									oi => modal.orderItems.indexOf(oi.id) > -1
							  )
							: []
					}
					customer={orders[activeOrderIndex]?.customer}
				/>
				<AuthorizePaymentModal
					customer={orders[activeOrderIndex] ? orders[activeOrderIndex].customer : null}
					entityId={orders[activeOrderIndex] ? orders[activeOrderIndex].id : null}
					entityType='order'
					onSubmit={() => {
						reloadOrder();
						closeModal();
					}}
					onClose={closeModal}
					isOpen={modal.open === terminalModals.AUTHORIZE_PAYMENT}
					payment={modal.paymentData}
					isCapture={modal.isCapture}
					serviceFeeModule={serviceFeeModules.POS}
					defaultAmount={
						modal.isCapture && orders[activeOrderIndex]
							? numberFormat(
									orders[activeOrderIndex].total -
										orders[activeOrderIndex].amountInvoiced
							  )
							: modal.paymentData?.amount
					}
					outlet={register.outlet}
				/>
				<NoteModal
					initNote={getNoteForNoteModal()}
					setNoteValue={onNoteModalSubmit}
					onClose={closeModal}
					isOpen={modal.open === terminalModals.NOTE}
				/>
				<NoteModal
					initNote={getNoteForNoteModal(true)}
					setNoteValue={note => onNoteModalSubmit(note, true)}
					onClose={closeModal}
					isOpen={modal.open === terminalModals.EXTERNAL_NOTE}
					title='External Note'
				/>
				<QuantityModal
					modalTitle='Number of Seats'
					initValue={0}
					defaultValue={getSeatsForSeatsModal()}
					isOpen={modal.open === terminalModals.SEATS}
					decimalLimit={0}
					lowerLimit={0}
					upperLimit={parseFloat(process.env.REACT_APP_PRICE_UPPER_LIMIT)}
					onClose={closeModal}
					setDashValue={value => onSeatsModalSubmit(parseFloat(value))}
				/>
				<TaxCodeModal
					selectedTaxCode={getTaxCodeForTaxModal()}
					setSelectedTaxCode={onTaxCodeModalSubmit}
					onClose={closeModal}
					taxCodes={lists.current.taxCodes || []}
					isOpen={modal.open === terminalModals.TAX}
				/>
				<UserModal
					onClose={closeModal}
					data={lists.current.users ? getUsersForUserModal() : []}
					setSelectedItem={onSelectUserModalSubmit}
					isOpen={modal.open === terminalModals.USER}
					selectedItem={orders[activeOrderIndex] ? orders[activeOrderIndex].server : null}
				/>
				<CustomerModal
					isOpen={modal.open === terminalModals.CUSTOMER}
					setSelectedCustomer={onCustomerModalSubmit}
					onClose={closeModal}
					defaultCustomer={
						orders[activeOrderIndex] ? orders[activeOrderIndex].customer : null
					}
					isHouseAccount={false}
					onVesselSelect={
						isModuleEnabled(userContext, modules.MARINA)
							? () => openModal({ open: terminalModals.VESSEL })
							: null
					}
				/>
				<VesselModal
					isOpen={modal.open === terminalModals.VESSEL}
					onCustomerSelect={() => openModal({ open: terminalModals.CUSTOMER })}
					onClose={closeModal}
					onSelect={_vessel => onCustomerModalSubmit(_vessel?.customer, _vessel)}
					defaultVessel={
						orders[activeOrderIndex] ? orders[activeOrderIndex].vessel : null
					}
					enumBoatTypes={lists.current.enumBoatTypes}
					enumBoatMakes={lists.current.enumBoatMakes}
				/>
				<TableModal
					isOpen={modal.open === terminalModals.TABLE}
					tables={userContext.data.user.units.sort((a, b) =>
						a.name.localeCompare(b.name)
					)}
					onSelect={onTableModalSubmit}
					onClose={closeModal}
				/>
				<PayModal
					isOpen={
						modal.open === terminalModals.PAY ||
						modal.open === terminalModals.CC_PAY ||
						modal.open === terminalModals.CASH_PAY
					}
					onClose={closeModal}
					paymentTypes={getAvailablePaymentTypes(
						orders[activeOrderIndex] &&
							orders[activeOrderIndex].status.value === orderStatuses.REFUNDED
					)}
					amount={
						orders[activeOrderIndex]
							? numberFormat(
									orders[activeOrderIndex].total -
										orders[activeOrderIndex].amountInvoiced
							  )
							: 0
					}
					customer={(orders[activeOrderIndex] || {}).customer}
					holdForSignatureDefault={
						register.registerType ? register.registerType.holdForSignature : false
					}
					printReceiptDefault
					onPay={paymentData => {
						let _order = null;
						if (paymentData.customer !== orders[activeOrderIndex].customer.id) {
							_order = checkForAutoDiscounts(orders, {
								id: paymentData.customer,
							});

							if (_order && paymentData.amount > _order.total)
								paymentData.amount = _order.total;
						}

						if (_order) saveOrder(afterSaveActions.MAKE_PAYMENT, _order, paymentData);
						else onPay(paymentData);
					}}
					isPaying={isOrderLoading}
					activePaymentMethod={
						modal.open === terminalModals.CC_PAY
							? paymentTypes.CREDIT_CARD
							: modal.open === terminalModals.CASH_PAY
							? paymentTypes.CASH
							: null
					}
					canSavePaymentMethod={userContext.hasPermission(
						'save_customer_payment_methods'
					)}
					isRefund={
						orders[activeOrderIndex] &&
						orders[activeOrderIndex].status.value === orderStatuses.REFUNDED
					}
					canEnterCreditCard={userHasPermission('key_in_credit_cards')}
					reloadRelatedOrders={reloadRelatedOrders}
					serviceFeeModule={serviceFeeModules.POS}
					max={
						orders[activeOrderIndex] &&
						orders[activeOrderIndex].status.value === orderStatuses.REFUNDED
							? numberFormat(
									orders[activeOrderIndex].total -
										orders[activeOrderIndex].amountInvoiced
							  )
							: 0
					}
					authorizedPayment={orders[activeOrderIndex]?.authorizedPayment}
					customServiceFeeAmounts={getServiceFeeAmountsByOrder(
						orders[activeOrderIndex],
						register.outlet
					)}
					outlet={register.outlet}
					displayEmailReceipt
					emailReceiptDefault={register.outlet.settings.emailPaymentOnPos}
				/>
				{screen === screens.GRID &&
					modal.open === '' &&
					orders[activeOrderIndex] &&
					(orders[activeOrderIndex].status.value === orderStatuses.OPEN ||
						orders[activeOrderIndex].status.value === orderStatuses.REFUNDED) && (
						<Scanner
							onScan={productSearch}
							avgTimeByChar={register.barcodeScannerAvgTime || 50}
							minLength={3}
						/>
					)}
				<PaymentModal
					onSubmit={onPaymentModalSubmit}
					onClose={closeModal}
					isOpen={
						modal.open === terminalModals.PAYMENT || modal.open === terminalModals.TIP
					}
					invoices={
						modal.payment
							? [modal.payment]
							: (orders[activeOrderIndex] || {}).invoices || []
					}
					// invoices={(orders[activeOrderIndex] || {}).invoices || []}
					isUpdating={isOrderLoading}
					isPayment={modal.open === terminalModals.PAYMENT}
				/>
				<QuantityModal
					modalTitle='Cash Tip'
					initValue={0}
					defaultValue={0}
					isOpen={modal.open === terminalModals.CASH_TIP}
					decimalLimit={2}
					lowerLimit={0}
					upperLimit={parseFloat(process.env.REACT_APP_PRICE_UPPER_LIMIT)}
					onClose={closeModal}
					setDashValue={value => onCashTipModalSubmit(parseFloat(value))}
					submitButtonText='Close Out'
					content={
						// sfc 2020-05-27 should we update api response / modal variables to cashPayments?
						// need to define what is included in closingCash
						<div className='d-flex align-items-end'>
							<div className='mr-2'>Cash Payments:</div>
							<div className='sdms-font-boldest sdms-font-lg'>
								{priceFormatter(modal.closingCash || 0)}
							</div>
						</div>
					}
				/>
				<DialogBox
					open={modal.open === terminalModals.TERMINAL_CLOSE}
					title=''
					content={`${register ? register.name : ''} closed`}
					type='warning'
					onClose={() => userContext.logout('terminal')}>
					<Button
						className='sdms-font-transform-c'
						design='clean'
						text='Ok'
						onClick={() => {
							if (inReservationForm) onBackToReservation();
							else userContext.logout('terminal');
						}}
					/>
				</DialogBox>
				<DialogBox
					open={modal.open === terminalModals.VOID_AUTHORIZED_PAYMENT}
					title=''
					content='Are you sure you want to release authorization?'
					type='question'
					onClose={() => {}}>
					<Button
						className='sdms-font-transform-c'
						text='No'
						label='danger'
						icon='Angle-left-circle'
						disabled={isAuthorizing}
						onClick={closeModal}
					/>
					<Button
						className='sdms-font-transform-c'
						text='Yes, Release'
						design='clean'
						icon='Edit'
						disabled={isAuthorizing}
						onClick={() => onVoidAuthorization(modal.paymentData)}
					/>
				</DialogBox>
				<DialogBox
					open={modal.open === terminalModals.PRINT_RECEIPT_PROMPT}
					title=''
					content='Print Receipt?'
					type='question'
					onClose={() => {}}>
					<Button
						className='sdms-font-transform-c'
						text='No'
						design='clean'
						icon='Angle-left-circle'
						onClick={() => onReceiptPromptSubmit(false)}
					/>
					<Button
						className='sdms-font-transform-c'
						text='Yes'
						design='clean'
						icon='Printer'
						onClick={() => onReceiptPromptSubmit(true)}
					/>
				</DialogBox>
			</Container>
		</>
	);
};
Terminal.propTypes = {
	id: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	inReservationForm: PropTypes.bool,
	onBackToReservation: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	vessel: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	productGrid: PropTypes.object,
};

Terminal.defaultProps = {
	reservationItem: null,
	inReservationForm: false,
	onBackToReservation: () => {},
	customer: null,
	vessel: null,
	productGrid: null,
};

export default Terminal;
