import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';
import {
	addErrorNotification,
	addSuccessNotification,
	canVoidStatementCharge,
	numberFormat,
	priceFormatter,
	printReceipt,
} from '../../../utils/helpers/helper';
import forms from '../../forms';
import {
	invoiceStatuses,
	paymentTypes,
	serviceFeeModules,
} from '../../../utils/constants/constants';
import useModal from '../../../utils/hooks/useModal';
import HeaderContext from '../../../app/contexts/HeaderContext';
import UserContext from '../../../app/contexts/UserContext';
import apiCall from '../../../utils/helpers/apiCall';
// eslint-disable-next-line import/namespace,import/default,import/no-named-as-default,import/no-named-as-default-member
import WebPrint from '../../reusables/print/WebPrint';
import useDate from '../../../utils/hooks/useDate';

import ListContainer from '../../reusables/template/ListContainer';
import Button from '../../reusables/element/Button';
import List from '../../reusables/template/List';
import { CustomerCell } from '../../reusables/element/InvoiceListCells';
import Dropdown from '../../reusables/element/Dropdown';
import DialogBox from '../../reusables/element/DialogBox';
import PayModal from '../../reusables/modals/PayModal';
import Badge from '../../reusables/element/Badge';
import PrinterModal from '../../reusables/modals/PrinterModal';

const modals = {
	VOID_DIALOG: 'voidDialog',
	PRINTER: 'printers',
	PAY: 'pay',
	DEFERRED_SCHEDULE: 'deferred_schedule',
};

export const DateCell = ({ data }) => {
	const [dateFormatter] = useDate();

	return dateFormatter(data.statementChargeDate, false);
};

export const DueDateCell = ({ data }) => {
	const [dateFormatter] = useDate();

	return dateFormatter(data.dueDate, false);
};

export const AmountCell = ({ data }) => {
	return priceFormatter(data.amount);
};

export const PaidCell = ({ data }) => {
	return priceFormatter(data.amountPaid);
};

export const BalanceCell = ({ data }) => {
	return priceFormatter(data.amount - data.amountPaid);
};

export const StatusCell = ({ data }) => {
	const getColor = () => {
		if (data.status.value === invoiceStatuses.PAID) return 'success';

		if (data.status.value === invoiceStatuses.VOIDED) return 'danger';

		if (data.amountPaid) return 'warning';

		return 'info';
	};

	const getText = () => {
		if (data.status.value === invoiceStatuses.PAID) return 'Paid';

		if (data.status.value === invoiceStatuses.VOIDED) return 'Voided';

		if (data.amountPaid) return 'Partial';

		return 'Open';
	};

	return (
		<Badge
			design={getColor()}
			isInline
			isUnified
			fontWeight='bold'
			size='lg'
			className='sdms-text-overflow'>
			<span className='sdms-text-overflow'>{getText()}</span>
		</Badge>
	);
};

StatusCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

StatusCell.defaultProps = {
	data: { status: { value: 'Paid' } },
};

const StatementChargeList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const [isLoading, setIsLoading] = useState(false);

	const userContext = useContext(UserContext);

	const [modal, openModal, closeModal] = useModal();

	const webPrint = useRef({
		payBillReceipt: () => {},
		printers: [],
	});

	const getPaymentTypes = () => {
		const _paymentTypes = [];

		if (userContext.hasPermission('cash_payments'))
			_paymentTypes.push({ value: paymentTypes.CASH });

		_paymentTypes.push(...[{ value: paymentTypes.CHECK }, { value: paymentTypes.CREDIT_CARD }]);

		return _paymentTypes;
	};

	const openPrinterModal = (payment, user, customer) =>
		openModal({
			open: modals.PRINTER,
			payment,
			user,
			customer,
		});

	const onVoid = () => {
		setIsLoading(true);
		apiCall(
			'POST',
			'statementChargeVoid',
			res => {
				addSuccessNotification('Statement charge successfully voided.');

				if (modal.updateData) modal.updateData(res);

				closeModal();
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ statementChargeId: modal.statementCharge.id }
		);
	};

	const onPay = payment => {
		setIsLoading(true);
		apiCall(
			'POST',
			'statementChargePay',
			res => {
				if (modal.updateData) modal.updateData([res.statementCharge]);
				closeModal();
				setIsLoading(false);

				if (payment.printReceipt) {
					printReceipt(
						webPrint,
						res.payment,
						userContext.data.user,
						res.statementCharge.customer,
						null,
						openPrinterModal
					);
				}
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ statementCharge: modal.statementCharge, payment }
		);
	};

	useEffect(() => {
		webPrint.current = new WebPrint({
			user: userContext.data.user,
			outlet: userContext.data.selectedOutlet,
			onStart: () => addSuccessNotification('Printing...'),
			onFinish: () => {},
			onFail: (printItem, err) => addErrorNotification(err.toString()),
		});

		headerContext.setBreadcrumbs([
			{ title: pages.crm.default.text, path: pages.crm.dashboard.path },
			{ title: pages.crm.statementCharges.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.crm.statementCharges.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ListContainer
				route='statementCharges'
				title={pages.crm.statementCharges.text}
				history={history}
				customActions={{
					pay: (statementCharge, updateData) => {
						openModal({
							open: modals.PAY,
							statementCharge,
							amount: numberFormat(
								statementCharge.amount - statementCharge.amountPaid
							),
							updateData,
						});
					},
					void: (statementCharge, updateData) =>
						openModal({ open: modals.VOID_DIALOG, statementCharge, updateData }),
				}}
				forms={forms.crm.statementCharges}
				fluid='fluid'
				checkEditable={() => []}>
				<List
					withCheckBox
					fluid='fluid'
					responsive='scroll'
					checkEditable={statementCharge => {
						const actions = ['edit'];
						if (statementCharge.status.value === invoiceStatuses.OPEN)
							actions.push('pay');

						if (canVoidStatementCharge(statementCharge, userContext))
							actions.push('void');

						return actions;
					}}>
					<List.Col
						label='Statement Charge'
						cellData='statementChargeId'
						isLinkColumn
						sortable='statementChargeId'
					/>
					<List.Col label='Customer' isLinkColumn cellComponent={<CustomerCell />} />
					<List.Col label='Date' cellComponent={<DateCell />} />
					<List.Col label='Due Date' cellComponent={<DueDateCell />} />
					<List.Col label='Amount' cellComponent={<AmountCell />} />
					<List.Col label='Paid' cellComponent={<PaidCell />} />
					<List.Col label='Balance' cellComponent={<BalanceCell />} />
					<List.Col label='Status' cellComponent={<StatusCell />} />
					<List.Col align='right' onlyHover width={150}>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item
								icon='Edit'
								text='Edit'
								key='edit'
								noPermission={!userContext.hasPermission('edit_statement_charges')}>
								Edit
							</Dropdown.Item>
							<Dropdown.Item
								icon='Dollar'
								text='Pay'
								key='pay'
								noPermission={!userContext.hasPermission('edit_statement_charges')}>
								Pay
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='danger'
								icon='Error-circle'
								text='Void'
								key='void'
								noPermission={!userContext.hasPermission('edit_statement_charges')}>
								Void
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			<DialogBox
				open={modal.open === modals.VOID_DIALOG}
				title='Void'
				content='Are you sure you want to void this statement charge?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't Void!`}
					onClick={closeModal}
					disabled={isLoading}
				/>
				<Button
					className='sdms-font-transform-c'
					label='danger'
					icon='Trash'
					text='Yes, Void!'
					onClick={onVoid}
					disabled={isLoading}
				/>
			</DialogBox>
			<PayModal
				isOpen={modal.open === modals.PAY}
				onClose={closeModal}
				paymentTypes={getPaymentTypes()}
				amount={modal.amount || 0}
				customer={modal.statementCharge ? modal.statementCharge.customer : null}
				onPay={onPay}
				isPaying={isLoading}
				canSavePaymentMethod={userContext.hasPermission('save_customer_payment_methods')}
				canEnterCreditCard={userContext.hasPermission('key_in_credit_cards')}
				max={modal.amount || 0}
				paymentGateway={userContext.data.selectedOutlet.settings.paymentGateway}
				serviceFeeModule={serviceFeeModules.CRM}
				outlet={userContext.data.selectedOutlet}
				displayEmailReceipt
				emailReceiptDefault={userContext.data.selectedOutlet.settings.emailPaymentOnCrm}
			/>
			<PrinterModal
				onClose={closeModal}
				printers={webPrint ? webPrint.current.printers : []}
				isOpen={modal.open === modals.PRINTER}
				enableRemember
				onSubmit={printer => {
					printReceipt(
						webPrint,
						modal.payment,
						modal.user,
						modal.customer,
						printer.id,
						openPrinterModal
					);

					closeModal();
				}}
			/>
		</>
	);
};

StatementChargeList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default StatementChargeList;
