import React, { useContext } from 'react';
import update from 'immutability-helper';

import pages from '../../pages';

import FormContainer from '../../reusables/template/FormContainer';
import UserContext from '../../../app/contexts/UserContext';
import PasswordSettingsForm from './PasswordSettingsForm';

const PasswordSettingsEdit = () => {
	const userContext = useContext(UserContext);

	return (
		<FormContainer
			dataId={
				userContext.data.user.company.systemSettings
					? userContext.data.user.company.systemSettings.id.toString()
					: 0
			}
			pageTitle={pages.systemSettings.passwordSettings.text}
			dataName={pages.systemSettings.passwordSettings.route}
			fields={<PasswordSettingsForm />}
			showBackButton={false}
			afterSubmit={data => {
				userContext.setData(
					update(userContext.data, {
						user: { company: { systemSettings: { $set: data } } },
					})
				);
			}}
		/>
	);
};

export default PasswordSettingsEdit;
