import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import useDate from '../../../utils/hooks/useDate';
import {
	getCreditRemainingAmount,
	getRemittanceRemainingAmount,
	numberFormat,
	priceFormatter,
} from '../../../utils/helpers/helper';
import defaultPages from '../../pages';

import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import Badge from '../../reusables/element/Badge';
import { getInvoiceAmountPaid } from '../../../utils/helpers/invoiceHelper';
import { InvoiceStatusCell } from '../../reusables/element/InvoiceListCells';
import { PaymentStatusCell } from '../../reusables/element/PaymentListCells';
import { StatusCell as StatementChargeStatusCell } from './StatementChargeList';
import Dropdown from '../../reusables/element/Dropdown';
import { filterComponents, paymentTypes } from '../../../utils/constants/constants';

const RefundSettledStatus = () => (
	<Badge
		design='success'
		isInline
		isUnified
		fontWeight='bold'
		size='lg'
		className='sdms-text-overflow'>
		<span className='sdms-text-overflow sdms-font-transform-c'>Settled</span>
	</Badge>
);

const transactions = {
	invoice: {
		customId: 'invoiceId',
		date: 'invoiceDate',
		label: 'Invoice',
		getType: invoice => [invoice.invoiceType, invoice.bookingType],
		amount: 'total',
		getSettled: getInvoiceAmountPaid,
		getBalance: invoice => numberFormat(invoice.total - getInvoiceAmountPaid(invoice)),
		status: InvoiceStatusCell,
		form: defaultPages.crm.invoices.path,
	},
	remittance: {
		customId: 'remittanceId',
		date: 'remittanceDate',
		label: 'Remittance',
		type: ['Remittance'],
		amount: 'amount',
		getSettled: payment => numberFormat(payment.amount - getRemittanceRemainingAmount(payment)),
		getBalance: getRemittanceRemainingAmount,
		status: PaymentStatusCell,
		form: defaultPages.crm.payments.path,
	},
	payment: {
		customId: 'remittanceId',
		date: 'remittanceDate',
		label: 'Payment',
		getType: payment => {
			if (payment?.paymentMethod?.paymentType?.value === paymentTypes.WIRE) return ['Wire'];

			return ['Payment'];
		},
		amount: 'amount',
		getSettled: payment => numberFormat(payment.amount - getRemittanceRemainingAmount(payment)),
		getBalance: getRemittanceRemainingAmount,
		status: PaymentStatusCell,
		form: defaultPages.crm.payments.path,
	},
	payment_cash: {
		customId: 'remittanceId',
		date: 'remittanceDate',
		label: 'Payment',
		type: ['Cash'],
		amount: 'amount',
		getSettled: payment => numberFormat(payment.amount - getRemittanceRemainingAmount(payment)),
		getBalance: getRemittanceRemainingAmount,
		status: PaymentStatusCell,
		form: defaultPages.crm.payments.path,
	},
	payment_check: {
		customId: 'remittanceId',
		date: 'remittanceDate',
		label: 'Payment',
		type: ['Check'],
		amount: 'amount',
		getSettled: payment => numberFormat(payment.amount - getRemittanceRemainingAmount(payment)),
		getBalance: getRemittanceRemainingAmount,
		status: PaymentStatusCell,
		form: defaultPages.crm.payments.path,
	},
	payment_credit_card: {
		customId: 'remittanceId',
		date: 'remittanceDate',
		label: 'Payment',
		type: ['Credit Card'],
		amount: 'amount',
		getSettled: payment => numberFormat(payment.amount - getRemittanceRemainingAmount(payment)),
		getBalance: getRemittanceRemainingAmount,
		status: PaymentStatusCell,
		form: defaultPages.crm.payments.path,
	},
	credit: {
		customId: 'remittanceId',
		date: 'remittanceDate',
		label: 'Credit Memo',
		type: ['Credit Memo'],
		amount: 'amount',
		getSettled: credit => numberFormat(credit.amount - getRemittanceRemainingAmount(credit)),
		getBalance: getCreditRemainingAmount,
		status: PaymentStatusCell,
		form: defaultPages.crm.creditMemos.path,
	},
	refund: {
		customId: 'refundId',
		date: 'refundDate',
		label: 'Refund',
		type: ['Refund'],
		amount: 'amount',
		getSettled: refund => refund.amount,
		getBalance: () => 0,
		status: RefundSettledStatus,
		form: defaultPages.crm.refunds.path,
	},
	check: {
		customId: 'refundId',
		date: 'refundDate',
		label: 'Refund',
		type: ['Check'],
		amount: 'amount',
		getSettled: refund => refund.amount,
		getBalance: () => 0,
		status: RefundSettledStatus,
		form: defaultPages.crm.refunds.path,
	},
	credit_card: {
		customId: 'refundId',
		date: 'refundDate',
		label: 'Refund',
		type: ['Credit Card'],
		amount: 'amount',
		getSettled: refund => refund.amount,
		getBalance: () => 0,
		status: RefundSettledStatus,
		form: defaultPages.crm.refunds.path,
	},
	statement_charge: {
		customId: 'statementChargeId',
		date: 'statementChargeDate',
		label: 'Statement Charge',
		type: ['Statement Charge'],
		amount: 'amount',
		getSettled: getInvoiceAmountPaid,
		getBalance: statementCharge =>
			numberFormat(statementCharge.amount - getInvoiceAmountPaid(statementCharge)),
		status: StatementChargeStatusCell,
		form: defaultPages.crm.statementCharges.path,
	},
};

const LabelCell = ({ data, onClick }) => {
	return (
		<span
			className='sdms-link sdms-link--dark sdms-text-overflow'
			onClick={onClick}
			role='presentation'>
			{transactions[data.type]?.label}
		</span>
	);
};

LabelCell.propTypes = {
	data: PropTypes.shape({
		type: PropTypes.string,
	}),
	onClick: PropTypes.func,
};
LabelCell.defaultProps = {
	data: {
		type: '',
	},
	onClick: () => {},
};

const CustomIdCell = ({ data, onClick }) => {
	return (
		<span
			className='sdms-link sdms-link--dark sdms-text-overflow'
			onClick={onClick}
			role='presentation'>
			{data[transactions[data.type]?.customId]}
		</span>
	);
};

CustomIdCell.propTypes = {
	data: PropTypes.shape({
		type: PropTypes.string,
	}),
	onClick: PropTypes.func,
};
CustomIdCell.defaultProps = {
	data: {
		type: '',
	},
	onClick: () => {},
};

const CustomerCell = ({ data }) => {
	return data.customer.displayName;
};

CustomerCell.propTypes = {
	data: PropTypes.shape({
		customer: PropTypes.shape({
			displayName: PropTypes.string,
		}),
	}),
};
CustomerCell.defaultProps = {
	data: {
		customer: {
			displayName: '',
		},
	},
};

const TypeCell = ({ data }) => {
	const itemUnits = (data?.invoiceItems || []).map(item => item?.reservationItem?.unit?.name);

	const [type, subText] = transactions[data.type]?.getType
		? transactions[data.type]?.getType(data)
		: transactions[data.type]?.type;

	return (
		<Badge design='brand' isDot>
			{type}
			<div className='sdms-font-sm sdms-list-layout__small-text'>
				{subText || itemUnits[0]}
			</div>
		</Badge>
	);
};
TypeCell.propTypes = {
	data: PropTypes.shape({
		type: PropTypes.string,
	}),
};
TypeCell.defaultProps = {
	data: {
		type: '',
	},
};

const DateCell = ({ data }) => {
	const [dateFormatter] = useDate();

	return dateFormatter(data[transactions[data.type]?.date], false);
};

const AmountCell = ({ data }) => priceFormatter(data[transactions[data.type]?.amount]);
const SettledCell = ({ data }) => priceFormatter(transactions[data.type].getSettled(data));
const BalanceCell = ({ data }) => priceFormatter(transactions[data.type].getBalance(data));
const StatusCell = ({ data }) => {
	const COMPONENT = transactions[data.type].status;
	return <COMPONENT data={data} />;
};

StatusCell.propTypes = {
	data: PropTypes.shape({
		type: PropTypes.string,
	}),
};
StatusCell.defaultProps = {
	data: {
		type: '',
	},
};

const TransactionList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	const pages = usePages();

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.crm.default.text,
				path: pages.crm.dashboard.path,
			},
			{ title: pages.crm.transactions.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.crm.transactions.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='transactions'
			history={history}
			title={pages.crm.transactions.text}
			forms={[]}
			customFilters={[
				{
					component: filterComponents.ASYNC_SELECTS,
					dataName: 'customers',
					searchField: 'displayName',
					fieldName: 'customers',
					label: 'Customer',
					placeholder: 'Search a Customer',
					displayKey: 'displayName',
					default: [],
					multiple: true,
				},
				{
					component: filterComponents.SELECTS,
					fieldName: 'types',
					label: 'Transaction Type',
					placeholder: 'All Transaction Types',
					displayKey: 'value',
					default: [],
					multiple: true,
					options: [
						{ id: 'invoice', value: 'Invoice' },
						{ id: 'payment', value: 'Payment' },
						{ id: 'credit', value: 'Credit Memo' },
						{ id: 'refund', value: 'Refund' },
						{ id: 'statement_charge', value: 'Statement Charge' },
					],
				},
				{
					component: filterComponents.DATE_RANGE,
					fieldName: 'transactionDate',
					label: 'Date',
					placeholder: 'Select Range',
					default: null,
				},

				{
					component: filterComponents.SELECTS,
					fieldName: 'settled',
					label: 'Settled',
					placeholder: 'All',
					displayKey: 'value',
					default: null,
					options: [
						{ id: 'yes', value: 'Yes' },
						{ id: 'no', value: 'No' },
					],
					summaryWithLabel: true,
				},
			]}
			customActions={{
				edit: transaction => {
					window.open(
						`${window.location.origin}${transactions[transaction.type].form}/${
							transaction.id
						}`,
						'_blank'
					);
				},
			}}
			checkEditable={() => []}>
			<List withCheckBox checkEditable={() => ['edit']} fluid='fluid'>
				<List.Col label='Transaction' cellComponent={<LabelCell />} isLinkColumn />
				<List.Col
					label='Id'
					cellComponent={<CustomIdCell />}
					isLinkColumn
					sortable='customId'
				/>
				<List.Col label='Customer' cellComponent={<CustomerCell />} />
				<List.Col label='Type' cellComponent={<TypeCell />} width={200} />
				<List.Col label='Date' cellComponent={<DateCell />} sortable='transactionDate' />
				<List.Col label='Amount' cellComponent={<AmountCell />} />
				<List.Col label='Settled' cellComponent={<SettledCell />} />
				<List.Col label='Balance' cellComponent={<BalanceCell />} />
				<List.Col label='Status' cellComponent={<StatusCell />} />
				<List.Col align='right'>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item icon='Edit' text='Edit' key='edit'>
							Edit
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

TransactionList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default TransactionList;
