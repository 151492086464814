import React from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';

import FormContainer from '../../reusables/template/FormContainer';
import MeterReadBatchForm from './MeterReadBatchForm';

const MeterReadBatchEdit = () => {
	const pages = usePages();

	return (
		<FormContainer
			dataId='0'
			dataName='meterReadingBatchEntry'
			fields={<MeterReadBatchForm />}
			pageTitle={pages.campground.meterReadings.batch.text}
			icon={pages.campground.meterReadings.batch.text}
			lists={['unitGroups', 'paymentTerms']}
			module={modules.CAMPGROUND}
			presetData={{ module: modules.CAMPGROUND }}
		/>
	);
};
MeterReadBatchEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default MeterReadBatchEdit;
