import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import Loading from '../../reusables/template/Loading';
import UserContext from '../../../app/contexts/UserContext';
import pages from '../../pages';
import HeaderContext from '../../../app/contexts/HeaderContext';
import Button from '../../reusables/element/Button';
import Portlet from '../../reusables/layout/Portlet';
import { modules } from '../../../utils/helpers/apiCall';

const ProductCategoryForm = ({
	data,
	setIsValid,
	isSubmitted,
	productCategories,
	setTitle,
	isLoading,
	onFormChange,
	submitButtonAttr,
	submit,
}) => {
	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const posModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.POS)
	);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [parentCategory, parentCategoryOnChange] = useField(
		data,
		'parentCategory',
		onFormChange,
		[],
		null
	);
	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.pos.dashboard.text,
				path: pages.pos.dashboard.path,
			},
			{
				title: pages.pos.settings.text,
				path: pages.pos.settings.path,
			},
			{
				title: pages.pos.settings.productCategories.text,
				path: pages.pos.settings.productCategories.path,
			},
			{ title: name || `New ${pages.pos.settings.productCategories.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.pos.settings.productCategories.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
		}
	}, [isSubmitted, setNameShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid);
	}, [nameValRes.isValid, setIsValid]);

	useEffect(() => {
		if (!isLoading) data.module = posModule.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='Product Category Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='parentCategory' label='Parent' id={data.id} colMd={6}>
								<Selects
									options={productCategories.filter(pc => pc.id !== data.id)}
									placeholder='Select a Category'
									value={parentCategory}
									onChange={parentCategoryOnChange}
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
ProductCategoryForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		module: PropTypes.object,
	}),
	productCategories: PropTypes.arrayOf(PropTypes.object),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
ProductCategoryForm.defaultProps = {
	data: {
		id: 0,
		module: null,
	},
	productCategories: [],
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default ProductCategoryForm;
