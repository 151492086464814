import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import useField from '../../../utils/hooks/useField';
import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';
import { required, uniqueFnc } from '../../../utils/helpers/validation';

import Loading from '../../reusables/template/Loading';
import Input from '../../reusables/field/Input';
import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Selects from '../../reusables/field/Selects';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Toggle from '../../reusables/field/Toggle';
import AsyncSelect from '../../reusables/field/AsyncSelect';

const UnitForm = ({
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	isLoading,
	onFormChange,
	bookingTypeBookings,
	unitGroups,
	submitButtonAttr,
	submit,
}) => {
	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const pages = usePages();

	const bookingsModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.BOOKINGS)
	);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[
			required,
			uniqueFnc(
				'bookingUnits',
				'name',
				data.id,
				true,
				null,
				null,
				null,
				null,
				userContext.data.selectedOutlet.id
			),
		],
		'',
		null,
		setTitle
	);

	const [
		bookingType,
		bookingTypeOnChange,
		bookingTypeValRes,
		bookingTypeShowVal,
		setBookingTypeShowVal,
	] = useField(data, 'bookingType', onFormChange, [required], null);

	const [
		unitGroup,
		unitGroupOnChange,
		unitGroupValRes,
		unitGroupShowVal,
		setUnitGroupShowVal,
	] = useField(data, 'unitGroup', onFormChange, [required], null);

	const [description, descriptionOnChange] = useField(data, 'description', onFormChange);

	const [mapCaption, mapCaptionOnChange] = useField(data, 'mapCaption', onFormChange);

	const [owner, ownerOnChange] = useField(data, 'owner', onFormChange, [], null);

	const [inactive, inactiveOnChange] = useField(data, 'inactive', onFormChange, [], false);

	const [notes, notesOnChange] = useField(data, 'notes', onFormChange);

	const [alert, alertOnChange] = useField(data, 'alert', onFormChange);

	useEffect(() => {
		if (!isLoading) data.module = bookingsModule.current;
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setBookingTypeShowVal();
			setUnitGroupShowVal();
		}
	}, [isSubmitted, setNameShowVal, setBookingTypeShowVal, setUnitGroupShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && bookingTypeValRes.isValid && unitGroupValRes.isValid);
	}, [nameValRes.isValid, bookingTypeValRes.isValid, unitGroupValRes.isValid, setIsValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.booking.default.text,
				path: pages.booking.dashboard.path,
			},
			{
				title: pages.booking.settings.text,
				path: pages.booking.settings.path,
			},
			{
				title: pages.booking.settings.units.text,
				path: pages.booking.settings.units.path,
			},
			{ title: name || `New ${pages.booking.settings.units.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.booking.settings.units.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								loadingContainer
								colMd={6}>
								<Input
									type='text'
									placeholder='Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='bookingType'
								label='Booking Type'
								id={data.id}
								valRes={bookingTypeValRes}
								showValidation={bookingTypeShowVal}
								colMd={6}>
								<Selects
									options={bookingTypeBookings.sort((a, b) =>
										a.name.localeCompare(b.name)
									)}
									placeholder='Select a Booking Type (Required)'
									value={bookingType}
									onChange={bookingTypeOnChange}
									onBlur={setBookingTypeShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='unitGroup'
								label='Unit Group'
								id={data.id}
								valRes={unitGroupValRes}
								showValidation={unitGroupShowVal}
								colMd={6}>
								<Selects
									options={unitGroups}
									placeholder='Select a Unit Group (Required)'
									value={unitGroup}
									onChange={unitGroupOnChange}
									onBlur={setUnitGroupShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='description'
								label='Description'
								id={data.id}
								colMd={6}>
								<Input
									type='text'
									placeholder='Description'
									value={description}
									onChange={descriptionOnChange}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='mapCaption' label='Map Caption' id={data.id} colMd={6}>
								<Input
									type='text'
									placeholder='Map Caption'
									value={mapCaption}
									onChange={mapCaptionOnChange}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='owner' label='Owner' id='owner' colLg={6}>
								<AsyncSelect
									options={data.owner ? [data.owner] : []}
									placeholder='Search and select customer'
									value={owner}
									onChange={ownerOnChange}
									route='customers'
									field='displayName'
									displayKey='displayName'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
									<FormField name='notes' label='Notes' id={data.id} colMd={6}>
										<Input
											type='text'
											placeholder='Notes'
											value={notes}
											onChange={notesOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField name='alert' label='Alert' id={data.id} colMd={6}>
										<Input
											type='text'
											placeholder='Alert'
											value={alert}
											onChange={alertOnChange}
										/>
									</FormField>
								</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='inactive' label='Inactive' id={data.id} colMd={6}>
								<Toggle spaceLess value={inactive} onChange={inactiveOnChange} />
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
UnitForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		bookingType: PropTypes.object,
		unitGroup: PropTypes.object,
		description: PropTypes.string,
		mapCaption: PropTypes.string,
		module: PropTypes.object,
		owner: PropTypes.object,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	bookingTypeBookings: PropTypes.arrayOf(PropTypes.object),
	unitGroups: PropTypes.arrayOf(PropTypes.object),
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
UnitForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		bookingType: {},
		unitGroup: {},
		description: '',
		mapCaption: '',
		owner: null,
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	bookingTypeBookings: [],
	unitGroups: [],
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default UnitForm;
