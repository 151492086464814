import React from 'react';
import PropTypes from 'prop-types';
import { priceFormatter } from '../../../../../utils/helpers/helper';

const ExtraChargeSummaryItem = ({ extra, charge }) => {
	if (extra.isTax) return '';

	return (
		<div className='row' key={extra.id}>
			<div className='col'>{`${extra.externalDescription || extra.name}:`}</div>
			<div className='col-auto text-right'>
				{charge === -1 ? 'Per Order' : (charge === 0 && 'Free') || priceFormatter(charge)}
			</div>
		</div>
	);
};

ExtraChargeSummaryItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	extra: PropTypes.object.isRequired,
	charge: PropTypes.number.isRequired,
};

export default ExtraChargeSummaryItem;
