import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import forms from '../../forms';
import { modules } from '../../../utils/helpers/apiCall';

import List from '../../reusables/template/List';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import ListContainer from '../../reusables/template/ListContainer';

const ProductCategoryExtraChargeList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	const pages = usePages();

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.booking.default.text, path: pages.booking.dashboard.path },
			{ title: pages.booking.settings.text, path: pages.booking.settings.path },
			{ title: pages.booking.settings.productCategoriesExtraCharges.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.booking.settings.productCategoriesExtraCharges.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='productCategoryExtraCharges'
			title={pages.booking.settings.productCategoriesExtraCharges.text}
			history={history}
			icon={pages.booking.settings.productCategoriesExtraCharges.icon}
			forms={forms.booking.settings.productCategoriesExtraCharges}
			module={modules.BOOKINGS}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col
					label='Min Qty'
					cellData='minChargeQuantity'
					sortable='minChargeQuantity'
				/>
				<List.Col
					label='Max Qty'
					cellData='maxChargeQuantity'
					sortable='maxChargeQuantity'
				/>
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
ProductCategoryExtraChargeList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default ProductCategoryExtraChargeList;
