import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import useField from '../../../utils/hooks/useField';
import usePages from '../../../utils/hooks/usePages';
import {
	excludeDatesValidation,
	maxLength,
	numeric,
	required,
} from '../../../utils/helpers/validation';
import {
	bookingPeriodFinder,
	filterInvoicingFrequency,
	getBookingCalculationText,
	negativeNumberParser,
	numberParser,
} from '../../../utils/helpers/helper';
import { bookingCalculations, extraChargeTypes } from '../../../utils/constants/constants';
import { modules } from '../../../utils/helpers/apiCall';

import FormGroup from '../../reusables/layout/FormGroup';
import Section from '../../reusables/layout/Section';
import Loading from '../../reusables/template/Loading';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import ImageUpload from '../../reusables/field/ImageUpload';
import Toggle from '../../reusables/field/Toggle';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import ExcludeDates from '../../reusables/element/ExcludeDates';

const ExtraChargePerUnitForm = ({
	data,
	isLoading,
	setTitle,
	onFormChange,
	isSubmitted,
	setIsValid,
	bookingTypeBookings,
	enumBookingPeriods,
	enumBookingCalculations,
	enumExtraChargeTypes,
	itemTypes,
	productCategoryExtraCharges,
	taxCodes,
	taxAgencies,
	accounts,
	unitMeasures,
	taxRates,
	submitButtonAttr,
	submit,
	invoicingFrequencies,
}) => {
	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const pages = usePages();

	const bookingModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.BOOKINGS)
	);

	const maxDurationValidation = (value, setValRes) => {
		if (!value) return true;
		if (minDuration > value) {
			setValRes({
				isValid: false,
				status: 'invalidMaxDuration',
				message: 'Max duration time must be equal or bigger than min duration',
			});
			return false;
		}

		return true;
	};

	// conditionally required for deferred fields.
	const deferredRequired = (value, setValRes) => {
		if (enableDeferredIncome) return required(value, setValRes);
		return true;
	};

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [
		bookingType,
		bookingTypeOnChange,
		bookingTypeValRes,
		bookingTypeShowVal,
		setBookingTypeShowVal,
	] = useField(data, 'bookingType', onFormChange, [required], null);

	const [
		bookingPeriod,
		bookingPeriodOnChange,
		bookingPeriodValRes,
		bookingPeriodShowVal,
		setBookingPeriodShowVal,
	] = useField(data, 'bookingPeriod', onFormChange, [required], null);

	const [
		itemType,
		itemTypeOnChange,
		itemTypeValRes,
		itemTypeShowVal,
		setItemTypeShowVal,
	] = useField(data, 'itemType', onFormChange, [required], null);

	const [productCategory, productCategoryOnChange] = useField(
		data,
		'productCategory',
		onFormChange,
		[],
		null
	);

	const [account, accountOnChange, accountValRes, accountShowVal, setAccountShowVal] = useField(
		data,
		'account',
		onFormChange,
		[required],
		null
	);

	const [
		incomeAccount,
		incomeAccountOnChange,
		incomeAccountValRes,
		incomeAccountShowVal,
		setIncomeAccountShowVal,
	] = useField(data, 'incomeAccount', onFormChange, [required], null);

	const [
		cogsAccount,
		cogsAccountOnChange,
		cogsAccountValRes,
		cogsAccountShowVal,
		setCogsAccountShowVal,
	] = useField(data, 'cogsAccount', onFormChange, [required], null);

	const [
		assetsAccount,
		assetsAccountOnChange,
		assetsAccountValRes,
		assetsAccountShowVal,
		setAssetsAccountShowVal,
	] = useField(data, 'assetsAccount', onFormChange, [required], null);

	const [
		description,
		descriptionOnChange,
		descriptionValRes,
		descriptionShowVal,
		setDescriptionShowVal,
	] = useField(data, 'description', onFormChange, [maxLength]);

	const [
		externalDescription,
		externalDescriptionOnChange,
		externalDescriptionValRes,
		externalDescriptionShowVal,
		setExternalDescriptionShowVal,
	] = useField(data, 'externalDescription', onFormChange, [maxLength]);

	const [productImage, setProductImage] = useField(data, 'productImage', onFormChange, [], null);

	const [taxCode, taxCodeOnChange, taxCodeValRes, taxCodeShowVal, setTaxCodeShowVal] = useField(
		data,
		'taxCode',
		onFormChange,
		[required],
		null
	);

	const [price, priceOnChange, priceValRes, priceShowVal, setPriceShowVal] = useField(
		data,
		'price',
		onFormChange,
		[required, numeric],
		0,
		negativeNumberParser
	);

	const [
		minPrice,
		minPriceOnChange,
		minPriceValRes,
		minPriceShowVal,
		setMinPriceShowVal,
	] = useField(data, 'minPrice', onFormChange, [numeric], 0, negativeNumberParser);

	const [
		maxPrice,
		maxPriceOnChange,
		maxPriceValRes,
		maxPriceShowVal,
		setMaxPriceShowVal,
	] = useField(data, 'maxPrice', onFormChange, [numeric], 0, negativeNumberParser);

	const [minDuration, minDurationOnChange] = useField(
		data,
		'minDuration',
		onFormChange,
		[],
		0,
		numberParser(false, 0)
	);

	const [
		maxDuration,
		maxDurationOnChange,
		maxDurationValRes,
		maxDurationShowVal,
		setMaxDurationShowVal,
		validateMaxDuration,
	] = useField(
		data,
		'maxDuration',
		onFormChange,
		[maxDurationValidation],
		0,
		numberParser(false, 0)
	);

	const [
		bookingCalculation,
		bookingCalculationOnChange,
		bookingCalculationValRes,
		bookingCalculationShowVal,
		setBookingCalculationShowVal,
	] = useField(data, 'bookingCalculation', onFormChange, [required], null);

	const [unitMeasure, unitMeasureOnChange] = useField(
		data,
		'unitMeasure',
		onFormChange,
		[],
		null
	);

	const [additionalTaxes, additionalTaxesOnChange] = useField(
		data,
		'additionalTaxes',
		onFormChange,
		[],
		[]
	);

	const [optional, optionalOnChange] = useField(data, 'optional', onFormChange, [], true);

	const [isTax, isTaxOnChange] = useField(data, 'isTax', onFormChange, [], false);

	const [applyTaxes, applyTaxesOnChange] = useField(data, 'applyTaxes', onFormChange, [], []);

	const [
		taxAgency,
		taxAgencyOnChange,
		taxAgencyValRes,
		taxAgencyShowVal,
		setTaxAgencyShowVal,
	] = useField(data, 'taxAgency', onFormChange, [], null);

	const [hideInResults, hideInResultsOnChange] = useField(
		data,
		'hideInResults',
		onFormChange,
		[],
		false
	);

	const [enableDeferredIncome, enableDeferredIncomeOnChange] = useField(
		data,
		'enableDeferredIncome',
		onFormChange,
		[],
		false
	);

	const [
		deferredIncomeFrequency,
		deferredIncomeFrequencyOnChange,
		deferredIncomeFrequencyValRes,
		deferredIncomeFrequencyShowVal,
		setDeferredIncomeFrequencyShowVal,
		validateDeferredIncomeFrequency,
	] = useField(data, 'deferredIncomeFrequency', onFormChange, [deferredRequired], null);

	const [
		deferredIncomeSalesAccount,
		deferredIncomeSalesAccountOnChange,
		deferredIncomeSalesAccountValRes,
		deferredIncomeSalesAccountShowVal,
		setDeferredIncomeSalesAccountShowVal,
		validateDeferredIncomeSalesAccount,
	] = useField(data, 'deferredIncomeSalesAccount', onFormChange, [deferredRequired], null);

	const [excludeDates, excludeDatesOnChange, excludeDatesValRes] = useField(
		data,
		'excludeDates',
		onFormChange,
		[excludeDatesValidation],
		[]
	);

	const parseBookingCalculationOptions = bc => {
		if (bc) {
			if (!bc.value) return bc;

			bc.label = getBookingCalculationText(bookingPeriod, bc);
		}

		return bc;
	};

	const changeBookingPeriodBookingCalculation = ({ target }) => {
		bookingCalculationOnChange({
			target: {
				name: 'bookingCalculation',
				value: {},
			},
		});

		bookingPeriodOnChange({ target });
	};

	const filterBookingPeriods = () => {
		if (!bookingType) return enumBookingPeriods;

		return enumBookingPeriods.filter(
			ebp =>
				(ebp.value === 'Hourly' && bookingType.hourly) ||
				(ebp.value === 'Daily' && bookingType.daily) ||
				(ebp.value === 'Nightly' && bookingType.nightly)
		);
	};

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.booking.default.text,
				path: pages.booking.default.path,
			},
			{
				title: pages.booking.settings.text,
				path: pages.booking.settings.path,
			},
			{
				title: pages.booking.settings.extraCharges.text,
				path: pages.booking.settings.extraCharges.path,
			},
			{
				title: pages.booking.settings.extraCharges.perUnit.text,
				path: pages.booking.settings.extraCharges.path,
			},
			{ title: name || `New ${pages.booking.settings.extraCharges.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.booking.settings.extraCharges.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setBookingTypeShowVal();
			setBookingPeriodShowVal();
			setBookingCalculationShowVal();
			setPriceShowVal();
			setTaxCodeShowVal();
			setTaxAgencyShowVal();
			setItemTypeShowVal();
			setAccountShowVal();
			setIncomeAccountShowVal();
			setCogsAccountShowVal();
			setAssetsAccountShowVal();
			setDescriptionShowVal();
			setExternalDescriptionShowVal();
			setDeferredIncomeFrequencyShowVal();
			setDeferredIncomeSalesAccountShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setPriceShowVal,
		setBookingTypeShowVal,
		setBookingPeriodShowVal,
		setBookingCalculationShowVal,
		setTaxCodeShowVal,
		setTaxAgencyShowVal,
		setItemTypeShowVal,
		setAccountShowVal,
		setIncomeAccountShowVal,
		setCogsAccountShowVal,
		setAssetsAccountShowVal,
		setDescriptionShowVal,
		setExternalDescriptionShowVal,
		setDeferredIncomeFrequencyShowVal,
		setDeferredIncomeSalesAccountShowVal,
	]);

	// useEffect to update form validity
	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				bookingPeriodValRes.isValid &&
				bookingTypeValRes.isValid &&
				priceValRes.isValid &&
				taxCodeValRes.isValid &&
				taxAgencyValRes.isValid &&
				itemTypeValRes.isValid &&
				bookingCalculationValRes.isValid &&
				descriptionValRes.isValid &&
				externalDescriptionValRes.isValid &&
				itemType &&
				(itemType.value === 'inventory'
					? cogsAccountValRes.isValid &&
					  incomeAccountValRes.isValid &&
					  assetsAccountValRes.isValid
					: accountValRes.isValid) &&
				deferredIncomeFrequencyValRes.isValid &&
				deferredIncomeSalesAccountValRes.isValid &&
				excludeDatesValRes.isValid
		);
	}, [
		nameValRes.isValid,
		priceValRes.isValid,
		taxCodeValRes.isValid,
		taxAgencyValRes.isValid,
		itemTypeValRes.isValid,
		accountValRes.isValid,
		bookingCalculationValRes.isValid,
		incomeAccountValRes.isValid,
		cogsAccountValRes.isValid,
		assetsAccountValRes.isValid,
		descriptionValRes.isValid,
		externalDescriptionValRes.isValid,
		setIsValid,
		bookingPeriodValRes.isValid,
		bookingTypeValRes.isValid,
		itemType,
		deferredIncomeFrequencyValRes.isValid,
		deferredIncomeSalesAccountValRes.isValid,
		excludeDatesValRes.isValid,
	]);

	useEffect(() => {
		if (!isLoading) data.module = bookingModule.current;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		if (enumExtraChargeTypes.length > 0)
			data.extraChargeType = enumExtraChargeTypes.find(
				ect => ect.value === extraChargeTypes.PER_UNIT
			);
	}, [data.extraChargeType, enumExtraChargeTypes]);

	useEffect(() => {
		// re-validate deferred field on enableDeferredIncome change.
		validateDeferredIncomeFrequency(deferredIncomeFrequency);
		validateDeferredIncomeSalesAccount(deferredIncomeSalesAccount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enableDeferredIncome]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						<div className='col-12'>
							<Section title='General'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='productId'
												label='Item ID'
												id={data.id}
												colMd={6}>
												<Input
													type='text'
													placeholder='Auto Generate'
													value={data.productId}
													onChange={() => {}}
													disabled
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='name'
												label='Name'
												id={data.id}
												valRes={nameValRes}
												showValidation={nameShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Name (required)'
													value={name}
													onChange={nameOnChange}
													onBlur={setNameShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='bookingType'
												label='Booking Type'
												id={data.id}
												valRes={bookingTypeValRes}
												showValidation={bookingTypeShowVal}
												colMd={3}>
												<Selects
													options={bookingTypeBookings}
													placeholder='Booking Types'
													value={bookingType}
													onChange={e => {
														bookingTypeOnChange(e);
														bookingPeriodOnChange({
															target: { value: {} },
														});
													}}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='bookingPeriod'
												label='Booking Period'
												id={data.id}
												valRes={bookingPeriodValRes}
												showValidation={bookingPeriodShowVal}
												colMd={3}>
												<Selects
													options={filterBookingPeriods()}
													placeholder='Booking Period'
													value={bookingPeriod}
													onChange={changeBookingPeriodBookingCalculation}
													displayKey='value'
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='itemType'
												label='Item Type'
												valRes={itemTypeValRes}
												showValidation={itemTypeShowVal}
												id={data.id}
												colMd={6}>
												<Selects
													options={itemTypes.filter(it => !it.isSystem)}
													placeholder='Type (Required)'
													value={itemType}
													onChange={({ target }) => {
														itemTypeOnChange({ target });
														accountOnChange({
															target: {
																name: 'account',
																value: null,
															},
														});
														incomeAccountOnChange({
															target: {
																name: 'incomeAccount',
																value: null,
															},
														});
														cogsAccountOnChange({
															target: {
																name: 'cogsAccount',
																value: null,
															},
														});
														assetsAccountOnChange({
															target: {
																name: 'assetsAccount',
																value: null,
															},
														});
													}}
													onBlur={setItemTypeShowVal}
													displayKey='value'
													disabled={data.id !== 0}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='productCategory'
												label='Group'
												description='Select a category to group Price Adjustments as an option'
												id={data.id}
												colMd={6}>
												<Selects
													options={productCategoryExtraCharges}
													placeholder='Group'
													value={productCategory}
													onChange={productCategoryOnChange}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='description'
												label='Description'
												id={data.id}
												valRes={descriptionValRes}
												showValidation={descriptionShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Description'
													value={description}
													onChange={descriptionOnChange}
													onBlur={setDescriptionShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='externalDescription'
												label='External Description'
												id={data.id}
												valRes={externalDescriptionValRes}
												showValidation={externalDescriptionShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='External Description'
													value={externalDescription}
													onChange={externalDescriptionOnChange}
													onBlur={setExternalDescriptionShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='productImage'
												label='Image'
												description='.png, .jpg or .jpeg only'
												colMd={3}>
												<ImageUpload
													media={productImage}
													setMedia={image =>
														setProductImage({
															target: {
																name: 'productImage',
																value: image,
															},
														})
													}
												/>
											</FormField>
										</Loading>
									</FormGroup>
								</Section.Body>
							</Section>
							<Section title='Accounting'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='taxCode'
												label='Tax Code'
												id={data.id}
												valRes={taxCodeValRes}
												showValidation={taxCodeShowVal}
												colMd={6}>
												<Selects
													options={taxCodes}
													placeholder='Tax Code (Required)'
													value={taxCode}
													onChange={taxCodeOnChange}
													onBlur={setTaxCodeShowVal}
												/>
											</FormField>
										</Loading>
										{(itemType || {}).value === 'inventory' ? (
											<>
												<Loading isLoading={isLoading}>
													<FormField
														name='incomeAccount'
														label='Income Account'
														id={data.id}
														valRes={incomeAccountValRes}
														showValidation={incomeAccountShowVal}
														colMd={6}>
														<Selects
															options={accounts}
															placeholder='Income Account (Required)'
															value={incomeAccount}
															onChange={incomeAccountOnChange}
															onBlur={setIncomeAccountShowVal}
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='cogsAccount'
														label='COGS Account'
														id={data.id}
														valRes={cogsAccountValRes}
														showValidation={cogsAccountShowVal}
														colMd={6}>
														<Selects
															options={accounts}
															placeholder='COGS Account (Required)'
															value={cogsAccount}
															onChange={cogsAccountOnChange}
															onBlur={setCogsAccountShowVal}
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='assetsAccount'
														label='Assets Account'
														id={data.id}
														valRes={assetsAccountValRes}
														showValidation={assetsAccountShowVal}
														colMd={6}>
														<Selects
															options={accounts}
															placeholder='Assets Account (Required)'
															value={assetsAccount}
															onChange={assetsAccountOnChange}
															onBlur={setAssetsAccountShowVal}
														/>
													</FormField>
												</Loading>
											</>
										) : (
											<Loading isLoading={isLoading}>
												<FormField
													name='account'
													label='Account'
													id={data.id}
													valRes={accountValRes}
													showValidation={accountShowVal}
													colMd={6}>
													<Selects
														options={accounts}
														placeholder='Account (Required)'
														value={account}
														onChange={accountOnChange}
														onBlur={setAccountShowVal}
													/>
												</FormField>
											</Loading>
										)}
									</FormGroup>
								</Section.Body>
							</Section>
							<Section title='Base Pricing'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='price'
												label='Price'
												id={data.id}
												valRes={priceValRes}
												showValidation={priceShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Price (Required)'
													value={price.toString()}
													onChange={priceOnChange}
													onBlur={setPriceShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='unitMeasure'
												label='Unit of Measure'
												id={data.id}
												colMd={6}>
												<Selects
													options={unitMeasures}
													placeholder='Unit of Measure'
													value={unitMeasure}
													onChange={unitMeasureOnChange}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='bookingCalculation'
												label='Calculation'
												id={data.id}
												valRes={bookingCalculationValRes}
												showValidation={bookingCalculationShowVal}
												colMd={6}>
												<Selects
													options={enumBookingCalculations
														.filter(
															ebc =>
																ebc.value ===
																	bookingCalculations.FIXED ||
																ebc.value ===
																	bookingCalculations.PERCENT ||
																ebc.value ===
																	bookingCalculations.PER_PERIOD ||
																ebc.value ===
																	bookingCalculations.PER_QUANTITY ||
																ebc.value ===
																	bookingCalculations.PER_QUANTITY_PER_PERIOD
														)
														.map(ebc =>
															parseBookingCalculationOptions(ebc)
														)}
													placeholder='Calculation (Required)'
													value={parseBookingCalculationOptions(
														bookingCalculation
													)}
													onChange={bookingCalculationOnChange}
													onBlur={setBookingCalculationShowVal}
													displayKey='label'
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='additionalTaxes'
												label='Additional Taxes'
												id={data.id}
												colMd={6}>
												<Selects
													options={taxRates.filter(
														tr =>
															tr.id !==
																userContext.data.selectedOutlet
																	.settings.taxRate.id &&
															!tr.isGroup
													)}
													placeholder='Select Tax Rate'
													value={additionalTaxes}
													onChange={additionalTaxesOnChange}
													multiple
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='minPrice'
												label='Minimum Price'
												id={data.id}
												valRes={minPriceValRes}
												showValidation={minPriceShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Minimum Price'
													value={minPrice.toString()}
													onChange={minPriceOnChange}
													onBlur={setMinPriceShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='maxPrice'
												label='Maximum Price'
												id={data.id}
												valRes={maxPriceValRes}
												showValidation={maxPriceShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Maximum Price'
													value={maxPrice.toString()}
													onChange={maxPriceOnChange}
													onBlur={setMaxPriceShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='minDuration'
												label='Minimum Duration'
												id={data.id}
												colMd={6}>
												<Input
													type='text'
													placeholder='Min Duration'
													value={minDuration}
													onChange={e => {
														minDurationOnChange(e);
														validateMaxDuration(maxDuration);
													}}
													append={bookingPeriodFinder(bookingPeriod)}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='maxDuration'
												label='Maximum Duration'
												id={data.id}
												valRes={maxDurationValRes}
												showValidation={maxDurationShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Max Duration'
													value={maxDuration}
													onChange={maxDurationOnChange}
													append={bookingPeriodFinder(bookingPeriod)}
													onBlur={setMaxDurationShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='optional'
												label='Optional'
												id={data.id}
												colMd={2}>
												{productCategory ? (
													<Toggle
														onChange={() => {}}
														value={false}
														disabled
													/>
												) : (
													<Toggle
														onChange={optionalOnChange}
														value={optional}
													/>
												)}
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='hideInResults'
												label='Hide In Results'
												id={data.id}
												colMd={4}>
												<Toggle
													onChange={hideInResultsOnChange}
													value={hideInResults}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='isTax'
												label='Is Tax'
												id={data.id}
												colMd={1}>
												<Toggle
													value={isTax}
													onChange={e => {
														isTaxOnChange(e);

														taxAgencyOnChange({
															target: { value: null },
														});
													}}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='applyTaxes'
												label='Apply Taxes'
												id={data.id}
												colMd={2}>
												<Selects
													options={taxCodes}
													placeholder='Select Tax Codes'
													value={applyTaxes}
													onChange={applyTaxesOnChange}
													multiple
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='taxAgency'
												label='Tax Agency'
												id={data.id}
												valRes={taxAgencyValRes}
												showValidation={taxAgencyShowVal}
												colMd={3}>
												<Selects
													options={taxAgencies}
													placeholder='Tax Agency'
													value={taxAgency}
													onChange={taxAgencyOnChange}
													onBlur={setTaxAgencyShowVal}
													disabled={!data.isTax}
												/>
											</FormField>
										</Loading>
										<ExcludeDates
											items={excludeDates}
											isSubmitted={isSubmitted}
											onChange={items =>
												excludeDatesOnChange({ target: { value: items } })
											}
											onFormChange={onFormChange}
										/>
									</FormGroup>
								</Section.Body>
							</Section>
							<Section title='Deferred Income'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='enableDeferredIncome'
												label='Enable Deferred Income'
												id={data.id}
												colMd={3}>
												<Toggle
													spaceLess
													value={enableDeferredIncome}
													onChange={enableDeferredIncomeOnChange}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='deferredIncomeFrequency'
												label='Frequency'
												id={data.id}
												valRes={deferredIncomeFrequencyValRes}
												showValidation={deferredIncomeFrequencyShowVal}
												colMd={3}>
												<Selects
													options={
														bookingPeriod
															? filterInvoicingFrequency(
																	invoicingFrequencies,
																	bookingPeriod.value,
																	true
															  )
															: []
													}
													placeholder={`Frequency${
														enableDeferredIncome ? ' (Required)' : ''
													}`}
													value={deferredIncomeFrequency}
													onChange={deferredIncomeFrequencyOnChange}
													onBlur={setDeferredIncomeFrequencyShowVal}
													displayKey='value'
													disabled={!enableDeferredIncome}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='deferredIncomeSalesAccount'
												label='Sales Income Account'
												id={data.id}
												valRes={deferredIncomeSalesAccountValRes}
												showValidation={deferredIncomeSalesAccountShowVal}
												colMd={3}>
												<Selects
													options={accounts}
													placeholder={`Sales Income Account${
														enableDeferredIncome ? ' (Required)' : ''
													}`}
													value={deferredIncomeSalesAccount}
													onChange={deferredIncomeSalesAccountOnChange}
													onBlur={setDeferredIncomeSalesAccountShowVal}
													disabled={!enableDeferredIncome}
												/>
											</FormField>
										</Loading>
									</FormGroup>
								</Section.Body>
							</Section>
						</div>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
ExtraChargePerUnitForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		productId: PropTypes.string,
		name: PropTypes.string,
		description: PropTypes.string,
		// eslint-disable-next-line react/require-default-props
		module: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		extraChargeType: PropTypes.object,
		bookingCalculation: PropTypes.object,
		isTax: PropTypes.bool,
	}),
	isLoading: PropTypes.bool,
	itemTypes: PropTypes.arrayOf(PropTypes.object),
	productCategoryExtraCharges: PropTypes.arrayOf(PropTypes.object),
	enumBookingPeriods: PropTypes.arrayOf(PropTypes.object),
	bookingTypeBookings: PropTypes.arrayOf(PropTypes.object),
	enumBookingCalculations: PropTypes.arrayOf(PropTypes.object),
	enumExtraChargeTypes: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	taxAgencies: PropTypes.arrayOf(PropTypes.object),
	unitMeasures: PropTypes.arrayOf(PropTypes.object),
	accounts: PropTypes.arrayOf(PropTypes.object),
	taxRates: PropTypes.arrayOf(PropTypes.object),
	setTitle: PropTypes.func,
	onFormChange: PropTypes.func,
	isSubmitted: PropTypes.bool,
	setIsValid: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
	invoicingFrequencies: PropTypes.arrayOf(PropTypes.object),
};
ExtraChargePerUnitForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		description: '',
		extraChargeType: {},
	},
	itemTypes: [],
	enumBookingPeriods: [],
	bookingTypeBookings: [],
	productCategoryExtraCharges: [],
	enumBookingCalculations: [],
	enumExtraChargeTypes: [],
	taxCodes: [],
	taxAgencies: [],
	unitMeasures: [],
	accounts: [],
	taxRates: [],
	isLoading: false,
	setTitle: () => {},
	onFormChange: () => {},
	isSubmitted: false,
	setIsValid: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
	invoicingFrequencies: [],
};

export default ExtraChargePerUnitForm;
