import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import VehicleForm from './VehicleForm';

const VehicleEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='vehicles'
			fields={<VehicleForm />}
			lists={['enumRvTypes', 'enumVehicleMakes']}
			pageTitle={pages.campground.vehicles.text}
		/>
	);
};

VehicleEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default VehicleEdit;
