import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import ExtraChargeOnlineOrderForm from './ExtraChargeOnlineOrderForm';

const ExtraChargeOnlineOrderEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='bookingExtraCharges'
			lists={[
				'itemTypes',
				'enumExtraChargeTypes',
				'productCategoryExtraCharges',
				'taxCodes',
				'accounts',
				'unitMeasures',
				'taxRates',
				'enumBookingCalculations',
				'taxAgencies',
			]}
			fields={<ExtraChargeOnlineOrderForm />}
			pageTitle={pages.campground.settings.extraCharges.onlineOrders.text}
			icon={pages.campground.settings.extraCharges.onlineOrders.icon}
			listUrl={pages.campground.settings.extraCharges.path}
		/>
	);
};

ExtraChargeOnlineOrderEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ExtraChargeOnlineOrderEdit;
