import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import useDate from '../../../../../utils/hooks/useDate';
import { paymentStatuses, paymentTypes } from '../../../../../utils/constants/constants';
import { PaymentStatusCell } from '../../../element/PaymentListCells';
import { priceFormatter } from '../../../../../utils/helpers/helper';

import Badge from '../../../element/Badge';
import Dropdown from '../../../element/Dropdown';

const RefundItem = ({ refund, isLoading, onPrint, onVoid }) => {
	const [dateFormatter] = useDate();

	const date = useMemo(() => {
		if (refund.remittanceDate) return refund.remittanceDate;

		if (refund.refundDate) return refund.refundDate;

		return refund.timeCreated;
	}, [refund]);

	const serviceFee = useMemo(() => {
		if (!refund.originalAmount || refund.amount === refund.originalAmount - refund.serviceFee)
			return refund.serviceFee;

		return refund.amount * (refund.serviceFee / (refund.originalAmount - refund.serviceFee));
	}, [refund]);

	return (
		<>
			<tr className='sdms-t-orders--payment'>
				<td colSpan={2} className='sdms-font-monospace'>
					<b>{refund.paymentMethod.name}</b>{' '}
					{!refund.isAuthorizedPayment &&
						refund.status.value === paymentStatuses.PROCESSING &&
						' - Processing'}
					{refund.status.value === paymentStatuses.SCHEDULED && (
						<PaymentStatusCell payment={refund} />
					)}
					{` - ${dateFormatter(date)}`}
					{refund.isAuthorizedPayment && (
						<>
							{' '}
							<Badge
								design='info'
								isInline
								isUnified
								fontWeight='bold'
								size='lg'
								className='sdms-text-overflow'>
								<span className='sdms-text-overflow sdms-font-transform-c'>
									Authorized
								</span>
							</Badge>
						</>
					)}
				</td>
				<td className='sdms-t-orders--item__price sdms-font-monospace'>
					{priceFormatter(refund.amount)}
				</td>
				<td className='sdms-t-orders--item__actions'>
					<Dropdown
						icon='Other#1'
						size='sm'
						color={null}
						inline
						aligned='right'
						arrow={false}
						outline={false}
						disabled={isLoading || refund.paymentMethod.name === paymentTypes.CREDIT}>
						<Dropdown.Header>Payment Item Actions</Dropdown.Header>
						{onPrint && (
							<Dropdown.Item icon='Printer' onClick={() => onPrint(refund)}>
								Print
							</Dropdown.Item>
						)}
						{onVoid && (
							<Dropdown.Item icon='Subtract' onClick={onVoid}>
								Void
							</Dropdown.Item>
						)}
					</Dropdown>
				</td>
			</tr>
			{refund.paymentMethod.paymentType.value === paymentTypes.HOUSE_ACCOUNT && (
				<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
					<td />
					<td colSpan={99}>Customer: {refund.customer.displayName}</td>
				</tr>
			)}
			{refund.paymentMethod.paymentType.value === paymentTypes.BOOKING && (
				<>
					<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
						<td />
						<td colSpan={99}>Customer: {refund.customer.displayName}</td>
					</tr>
					<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
						<td />
						<td colSpan={99}>
							Booking:{' '}
							{`${refund.reservationItem.id} - ${refund.reservationItem.product.name} (${refund.reservationItem.unit.name})`}
						</td>
					</tr>
				</>
			)}
			{refund.paymentMethod.paymentType.value === paymentTypes.CHECK &&
				(refund.number || refund.bankName) && (
					<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
						{refund.bankName ? (
							<td colSpan={2} className='sdms-text-overflow'>
								ACH:
								{`${refund.bankName} - ${refund.accountName}${
									refund.accountHolderName ? ` - ${refund.accountHolderName}` : ''
								}`}
							</td>
						) : (
							<td colSpan={2} className='sdms-text-overflow'>
								Number:
								{refund.number}
							</td>
						)}
						{refund.serviceFee ? (
							<td colSpan={1} className='sdms-t-orders--item__price'>
								Fee: {priceFormatter(serviceFee)}
							</td>
						) : (
							''
						)}
					</tr>
				)}
			{refund.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD &&
				refund.cardNumber && (
					<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
						<td colSpan={2} className='sdms-text-overflow'>
							Number:
							{refund.cardNumber}
						</td>
						{refund.serviceFee ? (
							<td colSpan={1} className='sdms-t-orders--item__price'>
								Fee: {priceFormatter(serviceFee)}
							</td>
						) : (
							''
						)}
					</tr>
				)}
		</>
	);
};

RefundItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	refund: PropTypes.object,
	isLoading: PropTypes.bool,
	onPrint: PropTypes.func,
	onVoid: PropTypes.func,
};

RefundItem.defaultProps = {
	refund: null,
	isLoading: false,
	onPrint: null,
	onVoid: null,
};

export default RefundItem;
