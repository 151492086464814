import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import classNames from 'classnames';
import apiCall, { modules, parseData, pathToUrl } from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	addSuccessNotification,
	numberParser,
	parseDatePickerChange,
	parseDatePickerValue,
	phoneNumberParser,
	randomColor,
	useWindowSize,
} from '../../../utils/helpers/helper';

import Portlet from '../layout/Portlet';
import Loading from '../template/Loading';
import Search from '../element/Search';
import SelectableList from '../template/SelectableList';
import Button from '../element/Button';
import Portal from '../layout/Portal';
import { mediaBreakpoint } from '../../../utils/constants/constants';
import QuickPanel from '../../QuickPanel';
import Badge from '../element/Badge';
import {
	required,
	phoneNumber,
	maxLength,
	email as emailValidation,
} from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import useFeet from '../../../utils/hooks/useFeet';
import UserContext from '../../../app/contexts/UserContext';
import Section from '../layout/Section';
import FormGroup from '../layout/FormGroup';
import FormField from '../template/FormField';
import Input from '../field/Input';
import Toggle from '../field/Toggle';
import AsyncSelect from '../field/AsyncSelect';
import Selects from '../field/Selects';
import ImageUpload from '../field/ImageUpload';
import LengthInputGroup from '../field/LengthInputGroup';
import DatePicker from '../field/DatePicker';

const _title = 'Select a Vehicle';
const _icon = 'Caravan';

export const NameCell = ({ data }) => {
	return <span className='sdms-font-bold sdms-link sdms-link--dark'>{data.name}</span>;
};
NameCell.propTypes = {
	data: PropTypes.shape({
		name: PropTypes.string,
	}),
};
NameCell.defaultProps = {
	data: {
		name: '',
	},
};

export const CustomerNameCell = ({ data }) => {
	return (
		<span className='sdms-font-bold sdms-link sdms-link--dark'>
			{data.customer.displayName}
		</span>
	);
};
CustomerNameCell.propTypes = {
	data: PropTypes.shape({
		customer: PropTypes.shape({
			displayName: PropTypes.string,
		}),
	}),
};
CustomerNameCell.defaultProps = {
	data: {
		customer: {
			displayName: '',
		},
	},
};

const LOACell = ({ data }) => {
	return (
		<>
			<Badge design='brand' isDot>
				{data.loa > 11
					? `${Math.floor(data.loa / 12).toString()}"${data.loa % 12}'`
					: `${data.loa}'`}
			</Badge>
		</>
	);
};
LOACell.propTypes = {
	data: PropTypes.shape({
		loa: PropTypes.number,
	}),
};
LOACell.defaultProps = {
	data: {
		loa: 0,
	},
};

const ImageCell = ({ data }) => {
	if (data.images && data.images[0]) {
		return <img alt={data.name} src={pathToUrl(data.images[0].thumb)} />;
	}
	return (
		<Badge
			design={randomColor(data.id)}
			className='sdms-font-transform-u'
			isUnified
			size='lg'
			isRounded
			fontWeight='bold'
		/>
	);
};
ImageCell.propTypes = {
	data: PropTypes.shape({
		name: PropTypes.string,
		images: PropTypes.arrayOf(PropTypes.object),
		id: PropTypes.number,
	}),
};
ImageCell.defaultProps = {
	data: { name: 'Product', images: [], id: 0 },
};

const VehicleModal = ({
	defaultVehicle,
	onSelect,
	hasForm,
	isOpen,
	onClose,
	openForm,
	enumRvTypes,
	enumVehicleMakes,
	defaultSearchText,
}) => {
	const windowSize = useWindowSize();

	if (windowSize.width < mediaBreakpoint.MD) {
		return (
			<QuickPanel
				status={isOpen}
				setStatus={onClose}
				title={_title}
				icon={_icon}
				portletClass='sdms-list-layout'>
				<VehicleModalContent
					defaultVehicle={defaultVehicle}
					onSelect={onSelect}
					onClose={onClose}
					hasForm={hasForm}
					withOutPortlet
					openForm={openForm}
					enumRvTypes={enumRvTypes}
					enumVehicleMakes={enumVehicleMakes}
					defaultSearchText={defaultSearchText}
				/>
			</QuickPanel>
		);
	}
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
					minHeight: '480px',
					height: '50%',
				}}>
				<VehicleModalContent
					defaultVehicle={defaultVehicle}
					onSelect={onSelect}
					onClose={onClose}
					hasForm={hasForm}
					openForm={openForm}
					enumRvTypes={enumRvTypes}
					enumVehicleMakes={enumVehicleMakes}
					defaultSearchText={defaultSearchText}
				/>
			</Popup>
		</Portal>
	);
};
VehicleModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	defaultVehicle: PropTypes.object,
	onSelect: PropTypes.func,
	hasForm: PropTypes.bool,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	openForm: PropTypes.bool,
	enumRvTypes: PropTypes.arrayOf(PropTypes.object),
	enumVehicleMakes: PropTypes.arrayOf(PropTypes.object),
	defaultSearchText: PropTypes.string,
};
VehicleModal.defaultProps = {
	defaultVehicle: null,
	onSelect: () => {},
	hasForm: true,
	openForm: false,
	enumVehicleMakes: [],
	enumRvTypes: [],
	defaultSearchText: '',
};

const VehicleFormModal = ({ onRegister, onCancel, enumRvTypes, enumVehicleMakes, onClose }) => {
	const userContext = useContext(UserContext);

	const data = useRef({ id: 0 });
	const campgroundModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.CAMPGROUND)
	);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data.current,
		'name',
		() => {},
		[required],
		'',
		null
	);

	const [
		customer,
		customerOnChange,
		customerValRes,
		customerShowVal,
		setCustomerShowVal,
	] = useField(data.current, 'customer', () => {}, [required], '', null);

	const [currentSpace, currentSpaceOnChange] = useField(data.current, 'currentSpace', () => {});

	const [images, imagesOnChange] = useField(data.current, 'images', () => {}, [], []);

	const [rvType, rvTypeOnChange, rvTypeValRes, rvTypeShowVal, setRvTypeShowVal] = useField(
		data.current,
		'rvType',
		() => {},
		[required],
		'',
		null
	);

	const [make, makeOnChange] = useField(data.current, 'make', () => {}, [], {}, null);

	const [model, modelOnChange] = useField(data.current, 'model', () => {});

	const [year, yearOnChange] = useField(data.current, 'year', () => {});

	const [hullColor, hullColorOnChange] = useField(data.current, 'hullColor', () => {});

	const [licensePlateNumber, licensePlateNumberOnChange] = useField(
		data.current,
		'licensePlateNumber',
		() => {}
	);

	const [isMotorized, isMotorizedOnChange] = useField(
		data.current,
		'isMotorized',
		() => {},
		[],
		false
	);

	const [serialNumber, serialNumberOnChange] = useField(data.current, 'serialNumber', () => {});

	const [, loaOnChange] = useField(data.current, 'loa', () => {}, [], 0, numberParser());

	const [loaFt, setLoaFt, loaIn, setLoaIn] = useFeet(data.current, loaOnChange);

	const [, beamOnChange] = useField(data.current, 'beam', () => {}, [], 0, null);

	const [beamFt, setBeamFt, beamIn, setBeamIn] = useFeet(data.current, beamOnChange);

	const [, heightOnChange] = useField(data.current, 'height', () => {}, [], 0, null);

	const [heightFt, setHeightFt, heightIn, setHeightIn] = useFeet(data.current, heightOnChange);

	const [insuranceCompany, insuranceCompanyOnChange] = useField(
		data.current,
		'insuranceCompany',
		() => {}
	);

	const [insurancePolicyNumber, insurancePolicyNumberOnChange] = useField(
		data.current,
		'insurancePolicyNumber',
		() => {}
	);

	const [insuranceExpirationDate, insuranceExpirationDateOnChange] = useField(
		data.current,
		'insuranceExpirationDate',
		() => {},
		[],
		null
	);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isSubmitting] = useState(false);

	const [isValid, setIsValid] = useState(false);

	const [rvTypes, setRvTypes] = useState(enumRvTypes);

	const [vehicleMakes, setVehicleMakes] = useState(enumVehicleMakes);

	const [additionalInsurance, additionalInsuranceOnChange] = useField(
		data.current,
		'additionalInsurance',
		() => {},
		[],
		false
	);

	const [coiReceived, coiReceivedOnChange] = useField(
		data.current,
		'coiReceived',
		() => {},
		[],
		false
	);

	const [agentName, agentNameOnChange] = useField(data.current, 'agentName', () => {});

	const [
		agentPhone,
		agentPhoneOnChange,
		agentPhoneValRes,
		agentPhoneShowVal,
		setAgentPhoneShowVal,
	] = useField(
		data.current,
		'agentPhone',
		() => {},
		[maxLength(), phoneNumber],
		'',
		phoneNumberParser
	);

	const [
		agentEmail,
		agentEmailOnChange,
		agentEmailValRes,
		agentEmailShowVal,
		setAgentEmailShowVal,
	] = useField(data.current, 'agentEmail', () => {}, [maxLength(), emailValidation], null);

	useEffect(() => {
		data.current.module = campgroundModule.current;

		if (vehicleMakes.length === 0) {
			apiCall(
				'GET',
				'enumVehicleMakes',
				res => {
					setVehicleMakes(res);
				},
				err => {
					addErrorNotification(err.toString());
				},
				''
			);
		}
		if (rvTypes.length === 0) {
			apiCall(
				'GET',
				'enumRvTypes',
				res => {
					setRvTypes(res);
				},
				err => {
					addErrorNotification(err.toString());
				},
				''
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setCustomerShowVal();
			setRvTypeShowVal();
			setAgentPhoneShowVal();
			setAgentEmailShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setCustomerShowVal,
		setRvTypeShowVal,
		setAgentPhoneShowVal,
		setAgentEmailShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				customerValRes.isValid &&
				rvTypeValRes.isValid &&
				agentPhoneValRes.isValid &&
				agentEmailValRes.isValid
		);
	}, [
		nameValRes.isValid,
		customerValRes.isValid,
		rvTypeValRes.isValid,
		agentPhoneValRes.isValid,
		agentEmailValRes.isValid,
		setIsValid,
	]);

	const onSubmit = () => {
		setIsSubmitted(true);

		if (isValid) {
			const formData = JSON.parse(JSON.stringify(data.current));

			// Parse data for api call.
			Object.keys(formData).forEach(field => {
				formData[field] = parseData(formData[field]);
			});

			apiCall(
				'POST',
				'vehicles',
				res => {
					onRegister(res);
					addSuccessNotification(`Vehicle ${name} successfully added`);
				},
				err => {
					addErrorNotification(err.toString());
				},
				'',
				formData
			);
		} else {
			document
				.getElementById('sdms-register-content')
				.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	return (
		<Portlet fluid='fluid' isLast everyTimeFluid>
			<Portlet.Head>
				<Portlet.HeadLabelTitle>{name || 'New Vehicle'}</Portlet.HeadLabelTitle>
				<Portlet.HeadToolbarActions className='sdms-last-margin'>
					<Button
						label='brand'
						text='Back to Vehicle Search'
						size='sm'
						onClick={onClose}
					/>
				</Portlet.HeadToolbarActions>
			</Portlet.Head>
			<Portlet.Body id='sdms-register-content'>
				<form className='sdms-form'>
					<Section title='General Settings'>
						<Section.Body>
							<FormGroup>
								<FormField
									name='name'
									label='Vehicle Name'
									id={data.id}
									valRes={nameValRes}
									showValidation={nameShowVal}
									colMd={6}>
									<Input
										type='text'
										placeholder='Name (Required)'
										value={name}
										onChange={nameOnChange}
										onBlur={setNameShowVal}
									/>
								</FormField>
								<FormField
									name='customer'
									label='Customer'
									id={data.id}
									valRes={customerValRes}
									showValidation={customerShowVal}
									colMd={6}>
									<AsyncSelect
										options={data.customer ? [data.customer] : []}
										placeholder='Search and select customer (Required)'
										value={customer}
										onChange={customerOnChange}
										onBlur={setCustomerShowVal}
										route='customers'
										field='displayName'
										displayKey='displayName'
									/>
								</FormField>
								<FormField
									name='currentSpace'
									label='Current Space'
									id={data.id}
									colMd={6}>
									<Selects
										options={data.currentSpace ? [data.currentSpace] : []}
										placeholder='Current Space'
										value={currentSpace}
										onChange={currentSpaceOnChange}
										disabled
									/>
								</FormField>
								<FormField name='images' label='Images' id={data.id} colMd={6}>
									<ImageUpload
										media={images}
										multiple
										setMedia={media => {
											imagesOnChange({
												target: {
													value: media,
												},
											});
										}}
									/>
								</FormField>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Vehicle Information'>
						<Section.Body>
							<FormGroup>
								<FormField
									name='rvType'
									label='Rv Type'
									id={data.id}
									valRes={rvTypeValRes}
									showValidation={rvTypeShowVal}
									colMd={6}>
									<Selects
										options={rvTypes}
										placeholder='Vehicle type (Required)'
										value={rvType}
										onChange={rvTypeOnChange}
										onBlur={setRvTypeShowVal}
										displayKey='value'
									/>
								</FormField>
								<FormField name='make' label='Make' id={data.id} colMd={6}>
									<Selects
										options={vehicleMakes}
										placeholder='Make'
										value={make}
										onChange={makeOnChange}
										displayKey='value'
									/>
								</FormField>
								<FormField name='model' label='Model' id={data.id} colMd={6}>
									<Input
										type='text'
										placeholder='Model'
										value={model}
										onChange={modelOnChange}
									/>
								</FormField>
								<FormField name='year' label='Year' id={data.id} colMd={6}>
									<Input
										type='number'
										withOutSpin
										placeholder='Year'
										value={year}
										onChange={yearOnChange}
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
								<FormField
									name='hullColor'
									label='Hull Color'
									id={data.id}
									colMd={6}>
									<Input
										type='text'
										placeholder='Hull Color'
										value={hullColor}
										onChange={hullColorOnChange}
									/>
								</FormField>
								<FormField
									name='licensePlateNumber'
									label='License Plate Number'
									id={data.id}
									colMd={6}>
									<Input
										type='text'
										placeholder='License Plate Number'
										value={licensePlateNumber}
										onChange={licensePlateNumberOnChange}
									/>
								</FormField>
								<FormField
									name='serialNumber'
									label='Serial Number'
									id={data.id}
									colMd={6}>
									<Input
										type='text'
										placeholder='Serial Number'
										value={serialNumber}
										onChange={serialNumberOnChange}
									/>
								</FormField>
								<FormField
									name='isMotorized'
									label='Motorized'
									id={data.id}
									colMd={6}>
									<Toggle value={isMotorized} onChange={isMotorizedOnChange} />
								</FormField>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Dimension Settings'>
						<Section.Body>
							<FormGroup>
								<FormField
									name='loaFt'
									label='Length Overall'
									id={data.id}
									colMd={6}
									inFormDesign={false}>
									<LengthInputGroup
										ft={loaFt}
										ftOnChange={setLoaFt}
										inch={loaIn}
										inchOnChange={setLoaIn}
										placeHolder='Length Overall'
									/>
								</FormField>
								<FormField
									name='beamFt'
									label='Beam'
									id={data.id}
									colMd={6}
									inFormDesign={false}>
									<LengthInputGroup
										ft={beamFt}
										ftOnChange={setBeamFt}
										inch={beamIn}
										inchOnChange={setBeamIn}
										placeHolder='Beam'
									/>
								</FormField>
								<FormField
									name='heightFt'
									label='Height'
									id={data.id}
									colMd={6}
									inFormDesign={false}>
									<LengthInputGroup
										ft={heightFt}
										ftOnChange={setHeightFt}
										inch={heightIn}
										inchOnChange={setHeightIn}
										placeHolder='Height'
									/>
								</FormField>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Insurance Settings'>
						<Section.Body>
							<FormGroup>
								<FormField
									name='insuranceCompany'
									label='Insurance Company'
									id={data.id}
									colMd={6}>
									<Input
										type='text'
										placeholder='Insurance Company'
										value={insuranceCompany}
										onChange={insuranceCompanyOnChange}
									/>
								</FormField>
								<FormField
									name='policyNumber'
									label='Insurance Policy Number'
									id={data.id}
									colMd={6}>
									<Input
										type='text'
										placeholder='Insurance Policy Number'
										value={insurancePolicyNumber}
										onChange={insurancePolicyNumberOnChange}
									/>
								</FormField>

								<FormField
									name='expirationDate'
									label='Insurance Expiration Date'
									id={data.id}
									colMd={6}>
									<DatePicker
										id='expirationDate'
										type='calendar'
										placeholder='Insurance Expiration Date'
										value={parseDatePickerValue(insuranceExpirationDate)}
										onChange={e =>
											insuranceExpirationDateOnChange({
												target: {
													value: parseDatePickerChange(
														e.target.value,
														insuranceExpirationDate
													),
												},
											})
										}
									/>
								</FormField>
								<FormField
									name='agentName'
									label='Agent Name'
									id={data.id}
									colMd={6}>
									<Input
										type='text'
										placeholder='Agent Name'
										value={agentName}
										onChange={agentNameOnChange}
									/>
								</FormField>
								<FormField
									name='agentPhone'
									label='Agent Phone'
									id={data.id}
									valRes={agentPhoneValRes}
									showValidation={agentPhoneShowVal}
									colMd={6}>
									<Input
										type='text'
										placeholder='(555) 555-5555'
										value={agentPhone}
										onChange={agentPhoneOnChange}
										onBlur={setAgentPhoneShowVal}
										mask={process.env.REACT_APP_PHONE_FORMAT}
										xAutoCompleteType='phone-national'
										autoComplete='phone-national'
									/>
								</FormField>
								<FormField
									name='agentEmail'
									label='Agent Email'
									valRes={agentEmailValRes}
									showValidation={agentEmailShowVal}
									id={data.id}
									colMd={6}>
									<Input
										type='text'
										placeholder='Agent Email'
										value={agentEmail}
										onChange={agentEmailOnChange}
										onBlur={setAgentEmailShowVal}
									/>
								</FormField>
								<FormField
									name='isCoiReceived'
									label='COI Received'
									id={data.id}
									colMd={6}>
									<Toggle value={coiReceived} onChange={coiReceivedOnChange} />
								</FormField>
								<FormField
									name='isAdditionalInsurance'
									label='Additional Insured'
									id={data.id}
									colMd={6}>
									<Toggle
										value={additionalInsurance}
										onChange={additionalInsuranceOnChange}
									/>
								</FormField>
							</FormGroup>
						</Section.Body>
					</Section>
				</form>
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						icon='Error-circle'
						size='sm'
						elevate
						text='Cancel'
						onClick={onCancel}
					/>
				</div>
				<div className='col-auto'>
					<Button
						design='info'
						text='Add Vehicle'
						size='sm'
						onClick={onSubmit}
						disabled={isSubmitting}
						className={classNames({
							'sdms-fading-dots': isSubmitting,
						})}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
VehicleFormModal.propTypes = {
	enumRvTypes: PropTypes.arrayOf(PropTypes.object),
	enumVehicleMakes: PropTypes.arrayOf(PropTypes.object),
	onRegister: PropTypes.func,
	onCancel: PropTypes.func,
	onClose: PropTypes.func,
};
VehicleFormModal.defaultProps = {
	enumRvTypes: [],
	enumVehicleMakes: [],
	onRegister: () => {},
	onCancel: () => {},
	onClose: () => {},
};

const VehicleModalContent = ({
	defaultVehicle,
	onSelect,
	onClose,
	hasForm,
	openForm,
	enumRvTypes,
	enumVehicleMakes,
	defaultSearchText,
}) => {
	const _isMounted = useRef(false);
	const totalItems = useRef(0);
	const inputRef = useRef();
	const abortController = useRef(null);

	const [data, setData] = useState([]);
	const [searchText, setSearchText] = useState(defaultSearchText);
	const [isListLoading, setIsListLoading] = useState(true);
	const [error, setError] = useState('');
	const [isFormOpen, setIsFormOpen] = useState(openForm);

	const itemTotalInfo = () => {
		if (totalItems.current > 0) {
			return (
				<>
					<span>{totalItems.current}</span>
					<span>{totalItems.current === 1 ? ' Total' : ' Totals'}</span>
				</>
			);
		}
		return 'No Results';
	};

	useEffect(() => {
		_isMounted.current = true;

		if (inputRef.current) {
			inputRef.current.focus();
		}
		return () => {
			_isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (searchText === '') {
			totalItems.current = 0;
			setData(defaultVehicle ? [defaultVehicle] : []);
			setIsListLoading(false);
			return;
		}

		if (abortController.current) abortController.current.abort();

		abortController.current = new AbortController();

		setIsListLoading(true);

		const filters = {
			'order[name]': 'asc',
			'or[customer.displayName&name]': searchText,
			pagination: false,
			inactive: false,
		};

		apiCall(
			'GET',
			'vehicles',
			res => {
				if (!_isMounted.current) return;
				totalItems.current =
					typeof res['hydra:totalItems'] !== 'undefined'
						? res['hydra:totalItems']
						: res.length;
				setData(res);
				setIsListLoading(false);
				setError('');
			},
			err => {
				if (err.toString().search('AbortError') === -1) {
					setError(err.toString());
					addErrorNotification(err.toString());
					setIsListLoading(false);
				}
			},
			'',
			null,
			filters,
			abortController.current.signal
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText]);

	if (error !== '') return <div>{error}</div>;

	if (isFormOpen) {
		return (
			<VehicleFormModal
				onRegister={_vehicle => onSelect(_vehicle)}
				onCancel={onClose}
				onClose={() => setIsFormOpen(false)}
				enumVehicleMakes={enumVehicleMakes}
				enumRvTypes={enumRvTypes}
			/>
		);
	}

	const _Inner = (
		<>
			<Portlet.Head wrapMaxSize='md'>
				<Portlet.HeadLabel portletIcon={_icon}>
					<>
						<h3 className='sdms-portlet__head-title'>Select a Vehicle</h3>
						<Portlet.Separator />
					</>
					<div className='sdms-portlet__head-desc'>
						<Loading isLoading={isListLoading} width={70} type='title'>
							{itemTotalInfo()}
						</Loading>
					</div>
				</Portlet.HeadLabel>
				<Portlet.HeadToolbarActions className='sdms-last-margin'>
					<Search
						inPortlet
						searchText={searchText}
						setSearchText={setSearchText}
						isSearching={isListLoading}
						ref={inputRef}
						className={classNames({ 'sdms-mr-0': !hasForm })}
					/>
					{hasForm && (
						<Button
							label='brand'
							icon='Plus'
							text='New'
							size='sm'
							onClick={() => setIsFormOpen(true)}
						/>
					)}
				</Portlet.HeadToolbarActions>
			</Portlet.Head>
			<SelectableList
				withOutPortlet
				data={data}
				onClick={item => {
					onSelect(item);
				}}
				isLoading={isListLoading}
				withImage>
				<SelectableList.Col
					width={65}
					className='sdms-cursor--pointer'
					label='Image'
					cellComponent={<ImageCell />}
				/>
				<SelectableList.Col
					className='sdms-cursor--pointer'
					label='Name'
					cellComponent={<NameCell />}
				/>
				<SelectableList.Col
					className='sdms-cursor--pointer'
					label='Customer Name'
					cellComponent={<CustomerNameCell />}
				/>
				<SelectableList.Col
					className='sdms-cursor--pointer'
					label='Length Overall'
					cellComponent={<LOACell />}
				/>
			</SelectableList>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
			</Portlet.Foot>
		</>
	);

	return (
		<Portlet className='sdms-list-layout' fluid='fluid' isLast everyTimeFluid>
			{_Inner}
		</Portlet>
	);
};
VehicleModalContent.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	defaultVehicle: PropTypes.object,
	onSelect: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	hasForm: PropTypes.bool,
	openForm: PropTypes.bool,
	enumRvTypes: PropTypes.arrayOf(PropTypes.object),
	enumVehicleMakes: PropTypes.arrayOf(PropTypes.object),
	defaultSearchText: PropTypes.string,
};
VehicleModalContent.defaultProps = {
	defaultVehicle: null,
	hasForm: false,
	openForm: false,
	enumRvTypes: [],
	enumVehicleMakes: [],
	defaultSearchText: '',
};

export default VehicleModal;
