import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import classNames from 'classnames';

import { maxLength, required, zero } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import {
	isBeforeAccountingClosedPeriod,
	getAccountingClosedDate,
	negativeNumberParser,
	parseDatePickerChange,
	parseDatePickerValue,
	priceFormatter,
} from '../../../utils/helpers/helper';
import usePages from '../../../utils/hooks/usePages';
import HeaderContext from '../../../app/contexts/HeaderContext';
import { invoiceStatuses } from '../../../utils/constants/constants';
import UserContext from '../../../app/contexts/UserContext';

import Portlet from '../../reusables/layout/Portlet';
import FormGroup from '../../reusables/layout/FormGroup';
import Loading from '../../reusables/template/Loading';
import FormField from '../../reusables/template/FormField';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import DatePicker from '../../reusables/field/DatePicker';
import Alert from '../../reusables/element/Alert';
import Input from '../../reusables/field/Input';
import Button from '../../reusables/element/Button';

const StatementChargeForm = ({
	data,
	setIsValid,
	isSubmitted,
	isLoading,
	onFormChange,
	enumInvoiceStatuses,
	submitButtonAttr,
	submit,
	isEditable,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const [
		customer,
		customerOnChange,
		customerValRes,
		customerShowVal,
		setCustomerShowVal,
	] = useField(data, 'customer', onFormChange, [required], null);

	const [item, itemOnChange, itemValRes, itemShowVal, setItemShowVal] = useField(
		data,
		'item',
		onFormChange,
		[required],
		null
	);

	const [
		statementChargeDate,
		statementChargeDateOnChange,
		statementChargeDateValRes,
		statementChargeDateShowVal,
		setStatementChargeDateShowVal,
	] = useField(data, 'statementChargeDate', onFormChange, [required]);

	const [
		dueDate,
		dueDateOnChange,
		dueDateValRes,
		dueDateShowVal,
		setDueDateShowVal,
	] = useField(data, 'dueDate', onFormChange, [required]);

	const [
		quantity,
		quantityOnChange,
		quantityValRes,
		quantityShowVal,
		setQuantityShowVal,
	] = useField(data, 'quantity', onFormChange, [required], '', negativeNumberParser);

	const [rate, rateOnChange, rateValRes, rateShowVal, setRateShowVal] = useField(
		data,
		'rate',
		onFormChange,
		[required, zero],
		'',
		negativeNumberParser
	);

	const [
		description,
		descriptionOnChange,
		descriptionValRes,
		descriptionShowVal,
		setDescriptionShowVal,
	] = useField(data, 'description', onFormChange, [maxLength(4095)]);

	const isClosed =
		!isLoading &&
		data.id !== 0 &&
		isBeforeAccountingClosedPeriod(data.statementChargeDate, userContext);

	const disabled = (data.status && data.status.value === invoiceStatuses.VOIDED) || isClosed;

	const getBanner = () => {
		if (isClosed)
			return (
				<div className='col-12 sdms-mb-20'>
					<Alert solid icon='Error-circle' design='danger'>
						Accounting Period Closed
					</Alert>
				</div>
			);

		if (data.status && data.status.value === invoiceStatuses.VOIDED)
			return (
				<div className='col-12 sdms-mb-20'>
					<Alert solid icon='Error-circle' design='danger'>
						Statement Voided
					</Alert>
				</div>
			);

		return null;
	};

	useEffect(() => {
		if (isSubmitted) {
			setCustomerShowVal();
			setItemShowVal();
			setQuantityShowVal();
			setRateShowVal();
			setStatementChargeDateShowVal();
			setDueDateShowVal();
		}
	}, [
		isSubmitted,
		setCustomerShowVal,
		setItemShowVal,
		setQuantityShowVal,
		setRateShowVal,
		setStatementChargeDateShowVal,
		setDueDateShowVal,
	]);

	useEffect(() => {
		setIsValid(
			customerValRes.isValid &&
				itemValRes.isValid &&
				quantityValRes.isValid &&
				rateValRes.isValid &&
				statementChargeDateValRes.isValid &&
				dueDateValRes.isValid &&
				!disabled
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		setIsValid,
		customerValRes.isValid,
		itemValRes.isValid,
		quantityValRes.isValid,
		rateValRes.isValid,
		statementChargeDateValRes.isValid,
		dueDateValRes.isValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.crm.default.text, path: pages.crm.dashboard.path },
			{ title: pages.crm.statementCharges.text, path: pages.crm.statementCharges.path },
			{ title: `Statement Charge ${data.statementChargeId || 'New'}`, isActive: true },
		]);

		headerContext.setPageTitle(`Statement Charge ${data.statementChargeId || 'New'}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [data.id]);

	useEffect(() => {
		if (!isLoading && data.id === 0) {
			if (!data.outlet) data.outlet = userContext.data.selectedOutlet;

			if (!data.amountPaid) data.amountPaid = 0;

			// set default quantity.
			quantityOnChange({ target: { value: '1' } });
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	useEffect(() => {
		if (enumInvoiceStatuses.length && data.id === 0)
			data.status = enumInvoiceStatuses.find(eis => eis.value === invoiceStatuses.OPEN);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [enumInvoiceStatuses]);

	useEffect(() => {
		data.amount = rate * quantity;

		if (data.amount < 0)
			data.status = enumInvoiceStatuses.find(eis => eis.value === invoiceStatuses.PAID);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [rate, quantity]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						{getBanner()}
						<Loading isLoading={isLoading}>
							<FormField
								name='customer'
								label='Customer'
								id={data.id}
								colMd={3}
								valRes={customerValRes}
								showValidation={customerShowVal}>
								<AsyncSelect
									options={data.customer ? [data.customer] : []}
									placeholder='Search and select customer'
									value={customer}
									onChange={customerOnChange}
									route='customers'
									field='displayName'
									displayKey='displayName'
									onBlur={setCustomerShowVal}
									disabled={data.id !== 0 || !isEditable}
									customFilters={{ isActive: true }}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='item'
								label='Item'
								id={data.id}
								colMd={3}
								valRes={itemValRes}
								showValidation={itemShowVal}>
								<AsyncSelect
									options={data.item ? [data.item] : []}
									placeholder='Search and select item'
									value={item}
									onChange={e => {
										itemOnChange(e);

										if (e.target.value)
											rateOnChange({
												target: { value: e.target.value.price },
											});
									}}
									route='products'
									displayKey='name'
									onBlur={setCustomerShowVal}
									disabled={disabled || !isEditable}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='statementChargeDate'
								label='Date'
								id={data.id}
								loadingContainer
								colMd={3}
								valRes={statementChargeDateValRes}
								showValidation={statementChargeDateShowVal}>
								<DatePicker
									id='statementChargeDate'
									type='calendar'
									placeholder='Statement Charge Date'
									value={parseDatePickerValue(statementChargeDate)}
									onChange={e => {
										const _statementChargeDate = parseDatePickerChange(
											e.target.value,
											statementChargeDate
										);

										statementChargeDateOnChange({
											target: {
												value: _statementChargeDate,
											},
										});

										if (
											!dueDate ||
											moment(dueDate).isBefore(moment(_statementChargeDate))
										)
											dueDateOnChange({
												target: { value: _statementChargeDate },
											});
									}}
									onBlur={setStatementChargeDateShowVal}
									disabled={disabled || !isEditable}
									minDate={getAccountingClosedDate(userContext)}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='dueDate'
								label='Due Date'
								id={data.id}
								valRes={dueDateValRes}
								showValidation={dueDateShowVal}
								loadingContainer
								colMd={3}>
								<DatePicker
									id='dueDate'
									type='calendar'
									placeholder='Due Date'
									value={parseDatePickerValue(dueDate)}
									onChange={e => {
										dueDateOnChange({
											target: {
												value: parseDatePickerChange(
													e.target.value,
													dueDate
												),
											},
										});
									}}
									onBlur={setDueDateShowVal}
									minDate={moment(statementChargeDate || undefined).toDate()}
									disabled={disabled || !isEditable}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='description'
								label='Description'
								id={data.id}
								valRes={descriptionValRes}
								showValidation={descriptionShowVal}
								loadingContainer
								colMd={3}>
								<Input
									id='dueDate'
									placeholder='Description'
									value={description}
									onChange={descriptionOnChange}
									onBlur={setDescriptionShowVal}
									disabled={disabled || !isEditable}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='quantity'
								label='Quantity'
								id={data.id}
								valRes={quantityValRes}
								showValidation={quantityShowVal}
								loadingContainer
								colMd={3}>
								<Input
									type='text'
									placeholder='Qty'
									value={quantity}
									onChange={quantityOnChange}
									onBlur={setQuantityShowVal}
									disabled={disabled || !isEditable}
									pattern={
										data.customerSettlements && data.customerSettlements.length
											? process.env.REACT_APP_PRICE_PATTERN
											: process.env.REACT_APP_NEGATIVE_PRICE_PATTERN
									}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='rate'
								label='Rate'
								id={data.id}
								valRes={rateValRes}
								showValidation={rateShowVal}
								loadingContainer
								colMd={3}>
								<Input
									type='text'
									placeholder='Rate'
									value={rate}
									onChange={rateOnChange}
									onBlur={setRateShowVal}
									disabled={disabled || !isEditable}
									prependIcon='Dollar'
									pattern={process.env.REACT_APP_PRICE_PATTERN}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='amount'
								label='Amount'
								id={data.id}
								loadingContainer
								colMd={3}>
								<Input
									type='text'
									placeholder='Amount'
									value={priceFormatter(data.amount)}
									onChange={() => {}}
									disabled
									prependIcon='Dollar'
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
					disabled={disabled || !isEditable}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};

StatementChargeForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		statementChargeId: PropTypes.string,
		customer: PropTypes.object,
		outlet: PropTypes.object,
		item: PropTypes.object,
		quantity: PropTypes.number,
		rate: PropTypes.number,
		amount: PropTypes.object,
		statementChargeDate: PropTypes.string,
		dueDate: PropTypes.string,
		status: PropTypes.object,
		amountPaid: PropTypes.number,
		customerSettlements: PropTypes.arrayOf(PropTypes.object),
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
	enumInvoiceStatuses: PropTypes.arrayOf(PropTypes.object),
	submit: PropTypes.func,
	isEditable: PropTypes.bool,
};
StatementChargeForm.defaultProps = {
	data: {
		id: 0,
	},
	isSubmitted: false,
	setIsValid: () => {},
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
	enumInvoiceStatuses: [],
	submit: () => {},
	isEditable: true,
};

export default StatementChargeForm;
