import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { numeric, required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import HeaderContext from '../../../app/contexts/HeaderContext';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Input from '../../reusables/field/Input';
import MultiSelect from '../../reusables/element/MultiSelect';
import Selects from '../../reusables/field/Selects';
import Section from '../../reusables/layout/Section';
import Wizard from '../../reusables/template/Wizard';
import pages from '../../pages';
import Loading from '../../reusables/template/Loading';

import UserContext from '../../../app/contexts/UserContext';
import { numberParser } from '../../../utils/helpers/helper';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import Toggle from '../../reusables/field/Toggle';
import { modules } from '../../../utils/helpers/apiCall';

const RegisterForm = ({
	data,
	isValid,
	setIsValid,
	submit,
	isSubmitted,
	registerTypes,
	grids,
	printers,
	unitMaps,
	paymentTypes,
	users,
	prepStations,
	isLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
}) => {
	const userContext = useContext(UserContext);

	const posModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.POS)
	);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);
	const [
		registerType,
		registerTypeOnChange,
		registerTypeValRes,
		registerTypeShowVal,
		setRegisterTypeShowVal,
	] = useField(data, 'registerType', onFormChange, [required], null);

	const [grid, gridOnChange, gridValRes, gridShowVal, setGridShowVal] = useField(
		data,
		'grid',
		onFormChange,
		[required],
		null
	);

	const [
		defaultCustomer,
		defaultCustomerOnChange,
		defaultCustomerValRes,
		defaultCustomerShowVal,
		setDefaultCustomerShowVal,
	] = useField(data, 'defaultCustomer', onFormChange, [required], {});

	const [printer, printerOnChange, printerValRes, printerShowVal, setPrinterShowVal] = useField(
		data,
		'printer',
		onFormChange,
		[required],
		null
	);

	const [unitMap, unitMapOnChange] = useField(data, 'unitMap', onFormChange, [], null);

	const [
		autoLogoutTime,
		autoLogoutTimeOnChange,
		autoLogoutTimeValRes,
		autoLogoutTimeShowVal,
		setAutoLogoutTimeShowVal,
	] = useField(
		data,
		'autoLogoutTime',
		onFormChange,
		[required, numeric],
		0,
		numberParser(false, 0)
	);

	const [assignedPaymentTypes, assignedPaymentTypesOnChange] = useField(
		data,
		'paymentTypes',
		onFormChange,
		[],
		[]
	);

	const [assignedUsers, assignedUsersOnChange] = useField(data, 'users', onFormChange, [], []);

	const [
		defaultOpeningCash,
		defaultOpeningCashOnChange,
		defaultOpeningCashValRes,
		defaultOpeningCashShowVal,
		setDefaultOpeningCashShowVal,
	] = useField(data, 'defaultOpeningCash', onFormChange, [numeric], '', numberParser(true));

	const [
		barcodeScannerAvgTime,
		barcodeScannerAvgTimeOnChange,
		barcodeScannerAvgTimeValRes,
		barcodeScannerAvgTimeShowVal,
		setBarcodeScannerAvgTimeShowVal,
	] = useField(data, 'barcodeScannerAvgTime', onFormChange, [numeric], '', numberParser());

	const [largerScrollbars, largerScrollbarsOnChange] = useField(
		data,
		'largerScrollbars',
		onFormChange,
		[],
		false
	);

	const [excludePrepStations, excludePrepStationsOnChange] = useField(
		data,
		'excludePrepStations',
		onFormChange,
		[],
		[]
	);

	const [inactive, inactiveOnChange] = useField(data, 'inactive', onFormChange, [], false);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setRegisterTypeShowVal();
			setGridShowVal();
			setAutoLogoutTimeShowVal();
			setDefaultOpeningCashShowVal();
			setDefaultCustomerShowVal();
			setPrinterShowVal();
			setBarcodeScannerAvgTimeShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setRegisterTypeShowVal,
		setGridShowVal,
		setAutoLogoutTimeShowVal,
		setDefaultOpeningCashShowVal,
		setDefaultCustomerShowVal,
		setPrinterShowVal,
		setBarcodeScannerAvgTimeShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				registerTypeValRes.isValid &&
				gridValRes.isValid &&
				autoLogoutTimeValRes.isValid &&
				defaultOpeningCashValRes.isValid &&
				defaultCustomerValRes.isValid &&
				printerValRes.isValid &&
				barcodeScannerAvgTimeValRes.isValid
		);
	}, [
		nameValRes.isValid,
		registerTypeValRes.isValid,
		gridValRes.isValid,
		autoLogoutTimeValRes.isValid,
		defaultOpeningCashValRes.isValid,
		defaultCustomerValRes.isValid,
		printerValRes.isValid,
		barcodeScannerAvgTimeValRes.isValid,
		setIsValid,
	]);

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.pos.default.text, path: pages.pos.dashboard.path },
			{ title: pages.pos.registers.text, path: pages.pos.registers.path },
			{ title: name || `New ${pages.pos.registers.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.pos.registers.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Wizard onSubmit={submit} submitButtonAttr={submitButtonAttr} isLoading={isLoading}>
			<Wizard.Item
				title='General'
				description='Setup Your General Details'
				isValid={isSubmitted ? isValid : true}>
				<Section>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='name'
									label='Name'
									id={data.id}
									valRes={nameValRes}
									showValidation={nameShowVal}
									colLg={6}>
									<Input
										type='text'
										placeholder='Name (Required)'
										value={name}
										onChange={nameOnChange}
										onBlur={setNameShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='registerType'
									label='Register Type'
									id={data.id}
									valRes={registerTypeValRes}
									showValidation={registerTypeShowVal}
									colLg={6}>
									<Selects
										options={registerTypes}
										placeholder='Register Type (Required)'
										value={registerType}
										onChange={registerTypeOnChange}
										onBlur={setRegisterTypeShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='grid'
									label='Product Grid'
									id={data.id}
									valRes={gridValRes}
									showValidation={gridShowVal}
									colLg={6}>
									<Selects
										options={grids}
										placeholder='Product Grid (Required)'
										value={grid}
										onChange={gridOnChange}
										onBlur={setGridShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='defaultCustomer'
									label='Default Customer'
									id={data.id}
									valRes={defaultCustomerValRes}
									showValidation={defaultCustomerShowVal}
									colLg={6}>
									<AsyncSelect
										options={data.defaultCustomer ? [data.defaultCustomer] : []}
										placeholder='Search and select customer'
										value={defaultCustomer}
										onChange={defaultCustomerOnChange}
										onBlur={setDefaultCustomerShowVal}
										route='customers'
										field='displayName'
										displayKey='displayName'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='printer'
									label='Receipt Printer'
									id={data.id}
									valRes={printerValRes}
									showValidation={printerShowVal}
									colLg={6}>
									<Selects
										options={printers}
										placeholder='Receipt Printer'
										value={printer}
										onChange={printerOnChange}
										onBlur={setPrinterShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField name='unitMap' label='Table Map' id={data.id} colLg={6}>
									<Selects
										options={unitMaps.filter(
											um => um.module.id === posModule.current.id
										)}
										placeholder='Table Map'
										value={unitMap}
										onChange={unitMapOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoLogoutTime'
									label='Auto Logout Time'
									description='Auto logout period for users in seconds'
									id={data.id}
									valRes={autoLogoutTimeValRes}
									showValidation={autoLogoutTimeShowVal}
									colLg={6}>
									<Input
										type='text'
										placeholder='Auto Logout Time (Required)'
										value={autoLogoutTime.toString()}
										onChange={autoLogoutTimeOnChange}
										onBlur={setAutoLogoutTimeShowVal}
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='defaultOpeningCash'
									label='Default Opening Cash'
									id={data.id}
									valRes={defaultOpeningCashValRes}
									showValidation={defaultOpeningCashShowVal}
									colLg={6}>
									<Input
										type='text'
										placeholder='Default Opening Cash'
										value={
											defaultOpeningCash ? defaultOpeningCash.toString() : ''
										}
										onChange={defaultOpeningCashOnChange}
										onBlur={setDefaultOpeningCashShowVal}
										pattern={process.env.REACT_APP_PRICE_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='barcodeScannerAvgTime'
									label='Barcode Scanner Average Time'
									id={data.id}
									valRes={barcodeScannerAvgTimeValRes}
									showValidation={barcodeScannerAvgTimeShowVal}
									colLg={6}>
									<Input
										type='text'
										placeholder='Barcode Scanner Average Time'
										value={
											barcodeScannerAvgTime
												? barcodeScannerAvgTime.toString()
												: ''
										}
										onChange={barcodeScannerAvgTimeOnChange}
										onBlur={setBarcodeScannerAvgTimeShowVal}
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='largerScrollbars'
									label='Larger Scrollbars'
									id={data.id}
									colLg={6}>
									<Toggle
										value={largerScrollbars}
										onChange={largerScrollbarsOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='excludePrepStations'
									label='Exclude Prep Stations'
									description='Select prep stations not to print'
									id={data.id}
									colMd={6}>
									<Selects
										options={prepStations}
										placeholder='Exclude Prep Stations'
										value={excludePrepStations}
										onChange={excludePrepStationsOnChange}
										multiple
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField name='inactive' label='Inactive' id={data.id} colMd={2}>
									<Toggle
										onChange={inactiveOnChange}
										value={inactive}
										disabled={!userContext.data.user.isInstallerUser}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
			<Wizard.Item
				title='Payment Methods'
				description='Select Payment Methods'
				icon={pages.pos.registers.icon2}>
				<Section>
					<Section.Body>
						<FormGroup>
							<FormField
								name='paymentTypes'
								label='Select payment methods:'
								id={data.id}
								col={12}>
								<MultiSelect
									data={paymentTypes}
									titleProp='value'
									value={assignedPaymentTypes}
									onChange={assignedPaymentTypesOnChange}
								/>
							</FormField>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
			<Wizard.Item
				title='Users'
				description='Select Users'
				icon={pages.systemSettings.users.icon}>
				<Section>
					<Section.Body>
						<FormGroup>
							<FormField
								name='user'
								label='Select users:'
								description='Users allowed to access this register'
								id={data.id}
								col={12}>
								<MultiSelect
									data={users.sort((a, b) => {
										return a.firstName.localeCompare(b.firstName);
									})}
									titleProp='displayName'
									value={assignedUsers}
									onChange={assignedUsersOnChange}
									noPermission={
										!userContext.hasPermission('assign_users_to_register')
									}
								/>
							</FormField>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
		</Wizard>
	);
};
RegisterForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		registerType: PropTypes.object,
		printer: PropTypes.object,
		paymentTypes: PropTypes.arrayOf(PropTypes.object),
		users: PropTypes.arrayOf(PropTypes.object),
		defaultCustomer: PropTypes.object,
		grid: PropTypes.object,
		unitMap: PropTypes.object,
		autoLogoutTime: PropTypes.number,
		outlet: PropTypes.object,
		excludePrepStations: PropTypes.arrayOf(PropTypes.object),
	}),
	registerTypes: PropTypes.arrayOf(PropTypes.object),
	printers: PropTypes.arrayOf(PropTypes.object),
	paymentTypes: PropTypes.arrayOf(PropTypes.object),
	users: PropTypes.arrayOf(PropTypes.object),
	grids: PropTypes.arrayOf(PropTypes.object),
	unitMaps: PropTypes.arrayOf(PropTypes.object),
	prepStations: PropTypes.arrayOf(PropTypes.object),
	isValid: PropTypes.bool,
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
};
RegisterForm.defaultProps = {
	data: {
		id: 0,
	},
	registerTypes: [],
	printers: [],
	paymentTypes: [],
	users: [],
	grids: [],
	unitMaps: [],
	prepStations: [],
	isValid: false,
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
};

export default RegisterForm;
