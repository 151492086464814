// to enable debug button run document.getElementById('sdms-form-data-dump-button').style.display = 'block' in browser console.

export const debugTypes = {
	generic: 'generic',
	product: 'product',
	reservation: 'reservation',
	invoice: 'invoice',
};

const fields = {
	generic: ['id', 'name', 'value'],
	product: [
		'id',
		'name',
		'bookingType',
		'bookingPeriod',
		'price',
		'bookingCalculation',
		'taxCode',
		'hideInResults',
		'inactive',
		'onlineUrl',
		'confirmationBccEmails',
		'checkInDays',
		'checkOutDays',
		'checkInTime',
		'checkOutTime',
		'policy',
		'invoiceOffSetDays',
		'minBookInAdvance',
		'maxBookInAdvance',
		'advancedPricing',
		'productContracts',
		'productCustomReservationStatuses',
		'productExtraCharges',
		'sortOrder',
		'enableDeferredIncome',
		'deferredIncomeFrequency',
		'deferredIncomeSalesAccount',
		'minDuration',
		'maxDuration',
		'dynamicPrices',
		'metas',
		'invoiceGenerationAutomatedBatch',
		'invoicingFrequency',
		'paymentTerm',
		'firstInvoice',
		'nextInvoice',
		'proratedFrequency',
		'coTermDate',
		'unitBookings',
		'unitCampgrounds',
		'unitMarinas',
		'minPeople',
		'maxPeople',
		'minLoa',
		'maxLoa',
		'minBeam',
		'maxBeam',
		'minHeight',
		'maxHeight',
		'minWeight',
		'maxWeight',
		'minDraft',
		'maxDraft',
		'minSqft',
		'maxSqft',
		'vehicleInformationName',
		'vehicleInformationType',
		'vehicleInformationMake',
		'vehicleInformationModel',
		'vehicleInformationYear',
		'vehicleInformationLicensePlate',
		'vehicleInformationIsMotorized',
	],
	reservation: ['id', 'reservationId', 'items', 'invoices', 'extraCharges'],
	reservationItem: [
		'id',
		'checkInTime',
		'checkOutTime',
		'policy',
		'taxCode',
		'enableDeferredIncome',
		'deferredIncomeFrequency',
		'deferredIncomeSalesAccount',
		'product',
		'fromDate',
		'toDate',
		'checkInTime',
		'checkOutTime',
	],
	invoice: [
		'id',
		'invoiceDate',
		'invoiceItems',
		'subtotal',
		'taxTotal',
		'total',
		'amountPaid',
		'timeCreated',
		'timeModified',
		'customerSettlements',
		'status',
		'tip',
		'customerTaxCode',
	],
	invoiceItem: [
		'id',
		'name',
		'price',
		'quantity',
		'discount',
		'discountAmount',
		'discountType',
		'taxRate',
		'subtotal',
		'enableDeferredIncome',
		'deferredIncomeAccount',
		'deferredIncomeSalesAccount',
		'product',
		'reservationItem',
	],
	policy: ['id', 'name', 'depositAmount', 'depositType', 'isSecurityDeposit'],
};

const mapping = {
	product: fields.product,
	productExtraCharges: fields.product,
	reservation: fields.reservation,
	reservationItem: fields.reservationItem,
	items: fields.reservationItem,
	extraCharges: fields.reservationItem,
	invoice: fields.invoice,
	invoices: fields.invoice,
	invoiceItem: fields.invoiceItem,
	invoiceItems: fields.invoiceItem,
	policy: fields.policy,
};

const isObject = item => item !== null && typeof item === 'object' && !Array.isArray(item);

const isArray = item => item && item.constructor === Array;

const recursiveFilter = (data, allowedFields) => {
	if (isObject(data)) {
		const result = {};

		Object.keys(data).forEach(key => {
			if (allowedFields.indexOf(key) > -1)
				result[key] = recursiveFilter(data[key], mapping[key] || fields.generic);
		});

		return result;
	}

	if (isArray(data)) return data.map(item => recursiveFilter(item, allowedFields));

	return data;
};

export const resolveDebugData = (data, type) =>
	recursiveFilter(data, fields[type] || fields.generic);
