import React, { useContext, useEffect } from 'react';
import { ReactSVG } from 'react-svg';

import PropTypes from 'prop-types';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import ContentInner from '../../reusables/template/ContentInner';
import BreadcrumbContainer from '../../reusables/template/BreadcrumbContainer';
import IconBox from '../../reusables/layout/IconBox';
import UserContext from '../../../app/contexts/UserContext';
import logo from '../../../assets/img/logo.svg';

const _Settings = ({ history }) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);
	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.accounting.default.text,
				path: pages.accounting.dashboard.path,
			},
			{
				title: pages.accounting.settings.text,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(pages.accounting.settings.text);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	const _colClass = 'col-xl-4 col-md-6';

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title={pages.accounting.settings.text} />
					<BreadcrumbContainer />
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title={pages.accounting.settings.text}>
				<div className='row'>
					<div className='col-3' />
					<div className='col-6 sdms-align-center'>
						<ReactSVG
							src={logo}
							className='svg-container sdms-block-center sdms-w-35p-desktop sdms-margin-t-30'
						/>
					</div>
					<div className='offset-xl-2 col-xl-8 offset-lg-1 col-lg-10 col-md-12 sdms-mt-50'>
						<div className='row sdms-iconbox--container'>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.accounting.settings.accountDetailTypes.icon}
									title={pages.accounting.settings.accountDetailTypes.text}
									onClick={() =>
										history.push(
											pages.accounting.settings.accountDetailTypes.path
										)
									}
									noPermission={
										!userContext.hasPermission(
											'maintain_accounting_general_settings'
										)
									}>
									View, Add & Edit Account Detail Types
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.accounting.settings.creditMemoReasons.icon}
									title={pages.accounting.settings.creditMemoReasons.text}
									onClick={() =>
										history.push(
											pages.accounting.settings.creditMemoReasons.path
										)
									}
									noPermission={
										!userContext.hasPermission(
											'maintain_accounting_general_settings'
										)
									}>
									View, Add & Edit Credit Memo Reasons
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.accounting.settings.discount.icon}
									title={pages.accounting.settings.discount.text}
									onClick={() =>
										history.push(pages.accounting.settings.discount.path)
									}
									noPermission={
										!userContext.hasPermission(
											'maintain_accounting_general_settings'
										)
									}>
									View, Add & Edit Discounts
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.accounting.settings.paymentSchedules.icon}
									title={pages.accounting.settings.paymentSchedules.text}
									onClick={() =>
										history.push(
											pages.accounting.settings.paymentSchedules.path
										)
									}>
									View, Add & Edit Payment Schedules
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.accounting.settings.paymentTerms.icon}
									title={pages.accounting.settings.paymentTerms.text}
									onClick={() =>
										history.push(pages.accounting.settings.paymentTerms.path)
									}
									noPermission={
										!userContext.hasPermission(
											'maintain_accounting_general_settings'
										)
									}>
									View, Add & Edit Payment Terms
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.accounting.settings.unitMeasures.icon}
									title={pages.accounting.settings.unitMeasures.text}
									onClick={() =>
										history.push(pages.accounting.settings.unitMeasures.path)
									}
									noPermission={
										!userContext.hasPermission(
											'maintain_accounting_general_settings'
										)
									}>
									Unit of Measure
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.accounting.settings.taxAgency.icon}
									title={pages.accounting.settings.taxAgency.text}
									onClick={() =>
										history.push(pages.accounting.settings.taxAgency.path)
									}
									noPermission={
										!userContext.hasPermission('maintain_tax_settings')
									}>
									View, Add & Edit Tax Agencies
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.accounting.settings.taxCode.icon}
									title={pages.accounting.settings.taxCode.text}
									onClick={() =>
										history.push(pages.accounting.settings.taxCode.path)
									}
									noPermission={
										!userContext.hasPermission('maintain_tax_settings')
									}>
									View, Add & Edit Tax Codes
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.accounting.settings.taxRate.icon}
									title={pages.accounting.settings.taxRate.text}
									onClick={() =>
										history.push(pages.accounting.settings.taxRate.path)
									}
									noPermission={
										!userContext.hasPermission('maintain_tax_settings')
									}>
									View, Add & Edit Tax Rates
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.accounting.settings.taxRateGroup.icon}
									title={pages.accounting.settings.taxRateGroup.text}
									onClick={() =>
										history.push(pages.accounting.settings.taxRateGroup.path)
									}
									noPermission={
										!userContext.hasPermission('maintain_tax_settings')
									}>
									View, Add & Edit Tax Rate Groups
								</IconBox>
							</div>
						</div>
					</div>
				</div>
			</ContentInner.Container>
		</>
	);
};
_Settings.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default _Settings;
