import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useField from '../../../utils/hooks/useField';
import { url } from '../../../utils/helpers/validation';
import { templateTypes } from '../../../utils/constants/constants';

import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import Button from '../../reusables/element/Button';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Selects from '../../reusables/field/Selects';
import Loading from '../../reusables/template/Loading';
import Input from '../../reusables/field/Input';

const TemplateSettingsForm = ({
	data,
	setIsValid,
	isLoading,
	isSubmitted,
	onFormChange,
	submit,
	submitButtonAttr,
	templates,
}) => {
	const [statementTemplate, statementTemplateOnChange] = useField(
		data,
		'statementTemplate',
		onFormChange,
		[],
		null
	);

	const [statementEmailTemplate, statementEmailTemplateOnChange] = useField(
		data,
		'statementEmailTemplate',
		onFormChange,
		[],
		null
	);

	const [statementEmailAttachmentTemplate, statementEmailAttachmentTemplateOnChange] = useField(
		data,
		'statementEmailAttachmentTemplate',
		onFormChange,
		[],
		null
	);

	const [invoiceTemplate, invoiceTemplateOnChange] = useField(
		data,
		'invoiceTemplate',
		onFormChange,
		[],
		null
	);

	const [invoiceEmailTemplate, invoiceEmailTemplateOnChange] = useField(
		data,
		'invoiceEmailTemplate',
		onFormChange,
		[],
		null
	);

	const [invoiceEmailAttachmentTemplate, invoiceEmailAttachmentTemplateOnChange] = useField(
		data,
		'invoiceEmailAttachmentTemplate',
		onFormChange,
		[],
		null
	);

	const [
		invoicePayUrl,
		invoicePayUrlOnChange,
		invoicePayUrlValRes,
		invoicePayUrlShowVal,
		setInvoicePayUrlShowVal,
	] = useField(data, 'invoicePayUrl', onFormChange, [url]);

	const [achSignUpEmailTemplate, achSignUpEmailTemplateOnChange] = useField(
		data,
		'achSignUpEmailTemplate',
		onFormChange,
		[],
		null
	);

	const [achDeclinedEmailTemplate, achDeclinedEmailTemplateOnChange] = useField(
		data,
		'achDeclinedEmailTemplate',
		onFormChange
	);

	const [achDeclinedEmailTo, achDeclinedEmailToOnChange] = useField(
		data,
		'achDeclinedEmailTo',
		onFormChange
	);
	const [
		achSignUpUrl,
		achSignUpUrlOnChange,
		achSignUpUrlValRes,
		achSignUpUrlShowVal,
		setAchSignUpUrlShowVal,
	] = useField(data, 'achSignUpUrl', onFormChange);

	const [achTermsAndConditions, achTermsAndConditionsOnChange] = useField(
		data,
		'achTermsAndConditions',
		onFormChange,
		[],
		null
	);

	const [paymentPrintTemplate, paymentPrintTemplateOnChange] = useField(
		data,
		'paymentPrintTemplate',
		onFormChange,
		[],
		null
	);

	const [paymentEmailTemplate, paymentEmailTemplateOnChange] = useField(
		data,
		'paymentEmailTemplate',
		onFormChange,
		[],
		null
	);

	const [paymentEmailAttachmentTemplate, paymentEmailAttachmentTemplateOnChange] = useField(
		data,
		'paymentEmailAttachmentTemplate',
		onFormChange,
		[],
		null
	);

	const [transientFolioTemplate, transientFolioTemplateOnChange] = useField(
		data,
		'transientFolioTemplate',
		onFormChange,
		[],
		null
	);

	const [transientFolioEmailTemplate, transientFolioEmailTemplateOnChange] = useField(
		data,
		'transientFolioEmailTemplate',
		onFormChange,
		[],
		null
	);

	const [
		transientFolioEmailAttachmentTemplate,
		transientFolioEmailAttachmentTemplateOnChange,
	] = useField(data, 'transientFolioEmailAttachmentTemplate', onFormChange, [], null);

	const [creditMemoPrintTemplate, creditMemoPrintTemplateOnChange] = useField(
		data,
		'creditMemoPrintTemplate',
		onFormChange,
		[],
		null
	);

	const [creditMemoEmailTemplate, creditMemoEmailTemplateOnChange] = useField(
		data,
		'creditMemoEmailTemplate',
		onFormChange,
		[],
		null
	);

	const [creditMemoEmailAttachmentTemplate, creditMemoEmailAttachmentTemplateOnChange] = useField(
		data,
		'creditMemoEmailAttachmentTemplate',
		onFormChange,
		[],
		null
	);

	useEffect(() => {
		if (isSubmitted) {
			setInvoicePayUrlShowVal();
			setAchSignUpUrlShowVal();
		}
	}, [isSubmitted, setInvoicePayUrlShowVal, setAchSignUpUrlShowVal]);

	useEffect(() => {
		setIsValid(invoicePayUrlValRes.isValid && achSignUpUrlValRes.isValid);
	}, [setIsValid, invoicePayUrlValRes.isValid, achSignUpUrlValRes.isValid]);

	return (
		<Portlet className='sdms-form' fluid='fluid' hasFrame>
			<Portlet.Body>
				<Section title='ACH'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='achSignUpEmailTemplate'
									label='ACH Sign-up Email Template'
									id={data.id}
									colMd={6}
									colLg={4}>
									<Selects
										options={templates.filter(
											t => t.type.value === templateTypes.ACH_SIGNUP_EMAIL
										)}
										placeholder='ACH Sign-up Email Template'
										value={achSignUpEmailTemplate}
										onChange={achSignUpEmailTemplateOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='achDeclinedEmailTemplate'
									label='ACH Declined Email Template'
									id={data.id}
									colMd={6}
									colLg={4}>
									<Selects
										options={templates.filter(
											t => t.type.value === templateTypes.ACH_DECLINED
										)}
										placeholder='ACH Declined Email Template'
										value={achDeclinedEmailTemplate}
										onChange={achDeclinedEmailTemplateOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='achDeclinedEmailTo'
									label='ACH Declined Email To'
									id={data.id}
									colMd={6}
									colLg={4}>
									<Input
										type='text'
										placeholder='ACH Declined Email To'
										value={achDeclinedEmailTo}
										onChange={achDeclinedEmailToOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='achSignUpUrl'
									label='ACH Sign-up Form Url'
									id={data.id}
									valRes={achSignUpUrlValRes}
									showValidation={achSignUpUrlShowVal}
									colMd={6}
									colLg={4}>
									<Input
										type='text'
										placeholder='example.com'
										value={achSignUpUrl}
										onChange={achSignUpUrlOnChange}
										onBlur={setAchSignUpUrlShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='achTermsAndConditions'
									label='ACH Terms And Conditions'
									id={data.id}
									colMd={6}
									colLg={4}>
									<Selects
										options={templates.filter(
											t =>
												t.type.value ===
												templateTypes.ACH_TERMS_AND_CONDITIONS
										)}
										placeholder='ACH Terms And Conditions'
										value={achTermsAndConditions}
										onChange={achTermsAndConditionsOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Invoices'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='invoiceTemplate'
									label='Invoice Print Template'
									id={data.id}
									colMd={6}
									colLg={4}>
									<Selects
										options={templates.filter(t => t.type.value === 'Invoice')}
										placeholder='Invoice Template'
										value={invoiceTemplate}
										onChange={invoiceTemplateOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='invoiceEmailTemplate'
									label='Invoice Email Template'
									id={data.id}
									colMd={6}
									colLg={4}>
									<Selects
										options={templates.filter(t => t.type.value === 'Invoice')}
										placeholder='Invoice Email Template'
										value={invoiceEmailTemplate}
										onChange={invoiceEmailTemplateOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='invoiceEmailAttachmentTemplate'
									label='Invoice Email Attachment Template'
									id={data.id}
									colMd={6}
									colLg={4}>
									<Selects
										options={templates.filter(t => t.type.value === 'Invoice')}
										placeholder='Invoice Email Attachment Template'
										value={invoiceEmailAttachmentTemplate}
										onChange={invoiceEmailAttachmentTemplateOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='invoicePayUrl'
									label='Invoice Email Pay Url'
									id={data.id}
									valRes={invoicePayUrlValRes}
									showValidation={invoicePayUrlShowVal}
									colMd={6}
									colLg={4}>
									<Input
										type='text'
										placeholder='example.com'
										value={invoicePayUrl}
										onChange={invoicePayUrlOnChange}
										onBlur={setInvoicePayUrlShowVal}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Payment Receipts'>
					<Section.Body>
						<FormGroup>
							<FormField
								name='paymentPrintTemplate'
								label='Payment Print Template'
								id={data.id}
								colMd={6}
								colLg={4}>
								<Selects
									options={templates.filter(
										t => t.type.value === 'Payment Print'
									)}
									placeholder='Payment Print Template'
									value={paymentPrintTemplate}
									onChange={paymentPrintTemplateOnChange}
								/>
							</FormField>
							<FormField
								name='paymentEmailTemplate'
								label='Payment Email Template'
								id={data.id}
								colMd={6}
								colLg={4}>
								<Selects
									options={templates.filter(
										t => t.type.value === 'Payment Email'
									)}
									placeholder='Payment Email Template'
									value={paymentEmailTemplate}
									onChange={paymentEmailTemplateOnChange}
								/>
							</FormField>
							<FormField
								name='paymentEmailAttachmentTemplate'
								label='Payment Email Attachment Template'
								id={data.id}
								colMd={6}
								colLg={4}>
								<Selects
									options={templates.filter(
										t => t.type.value === 'Payment Email Attachment'
									)}
									placeholder='Payment Email Attachment Template'
									value={paymentEmailAttachmentTemplate}
									onChange={paymentEmailAttachmentTemplateOnChange}
								/>
							</FormField>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Credit Memo Receipts'>
					<Section.Body>
						<FormGroup>
							<FormField
								name='creditMemoPrintTemplate'
								label='Credit Memo Print Template'
								id={data.id}
								colMd={6}
								colLg={4}>
								<Selects
									options={templates.filter(
										t => t.type.value === 'Credit Memo Print'
									)}
									placeholder='Credit Memo Print Template'
									value={creditMemoPrintTemplate}
									onChange={creditMemoPrintTemplateOnChange}
								/>
							</FormField>
							<FormField
								name='creditMemoEmailTemplate'
								label='Credit Memo Email Template'
								id={data.id}
								colMd={6}
								colLg={4}>
								<Selects
									options={templates.filter(
										t => t.type.value === 'Credit Memo Email'
									)}
									placeholder='Credit Memo Email Template'
									value={creditMemoEmailTemplate}
									onChange={creditMemoEmailTemplateOnChange}
								/>
							</FormField>
							<FormField
								name='creditMemoEmailAttachmentTemplate'
								label='Credit Memo Email Attachment Template'
								id={data.id}
								colMd={6}
								colLg={4}>
								<Selects
									options={templates.filter(
										t => t.type.value === 'Credit Memo Email Attachment'
									)}
									placeholder='Credit Memo Email Attachment Template'
									value={creditMemoEmailAttachmentTemplate}
									onChange={creditMemoEmailAttachmentTemplateOnChange}
								/>
							</FormField>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Statements'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='statementTemplate'
									label='Statement Print Template'
									id={data.id}
									colMd={6}
									colLg={4}>
									<Selects
										options={templates.filter(
											t => t.type.value === 'Statement'
										)}
										placeholder='Statement Template'
										value={statementTemplate}
										onChange={statementTemplateOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='statementEmailTemplate'
									label='Statement Email Template'
									id={data.id}
									colMd={6}
									colLg={4}>
									<Selects
										options={templates.filter(
											t => t.type.value === 'Statement'
										)}
										placeholder='Statement Email Template'
										value={statementEmailTemplate}
										onChange={statementEmailTemplateOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='statementEmailAttachmentTemplate'
									label='Statement Email Attachment Template'
									id={data.id}
									colMd={6}
									colLg={4}>
									<Selects
										options={templates.filter(
											t => t.type.value === 'Statement'
										)}
										placeholder='Statement Email Attachment Template'
										value={statementEmailAttachmentTemplate}
										onChange={statementEmailAttachmentTemplateOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Transient Folio'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='transientFolioTemplate'
									label='Transient Folio Template'
									id={data.id}
									colMd={4}>
									<Selects
										options={templates.filter(
											t => t.type.value === templateTypes.TRANSIENT_FOLIO
										)}
										placeholder='Select template'
										value={transientFolioTemplate}
										onChange={transientFolioTemplateOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='transientFolioEmailTemplate'
									label='Transient Folio Email Template'
									id={data.id}
									colMd={4}>
									<Selects
										options={templates.filter(
											t => t.type.value === templateTypes.TRANSIENT_FOLIO
										)}
										placeholder='Select template'
										value={transientFolioEmailTemplate}
										onChange={transientFolioEmailTemplateOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='transientFolioEmailAttachmentTemplate'
									label='Transient Folio Email Attachment Template'
									id={data.id}
									colMd={4}>
									<Selects
										options={templates.filter(
											t => t.type.value === templateTypes.TRANSIENT_FOLIO
										)}
										placeholder='Select template'
										value={transientFolioEmailAttachmentTemplate}
										onChange={transientFolioEmailAttachmentTemplateOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};

TemplateSettingsForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		invoiceTemplate: PropTypes.object,
		invoiceEmailTemplate: PropTypes.object,
		invoiceEmailAttachmentTemplate: PropTypes.object,
		statementTemplate: PropTypes.object,
		statementEmailTemplate: PropTypes.object,
		statementEmailAttachmentTemplate: PropTypes.object,
		invoicePayUrl: PropTypes.string,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submit: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	templates: PropTypes.arrayOf(PropTypes.object),
};
TemplateSettingsForm.defaultProps = {
	data: {
		id: 0,
		invoiceTemplate: null,
		invoiceEmailTemplate: null,
		invoiceEmailAttachmentTemplate: null,
		statementTemplate: null,
		statementEmailTemplate: null,
		statementEmailAttachmentTemplate: null,
		invoicePayUrl: '',
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	submit: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	templates: [],
};

export default TemplateSettingsForm;
