import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../element/Button';
import DialogBox from '../../../element/DialogBox';

const AuthorizedPaymentVoid = ({ open, isSubmitting, onVoid, onClose }) => {
	return (
		<DialogBox
			open={open}
			title=''
			content='Are you sure you want to release authorization?'
			type='question'
			onClose={onClose}>
			<Button
				className='sdms-font-transform-c'
				text='No'
				label='danger'
				icon='Angle-left-circle'
				disabled={isSubmitting}
				onClick={onClose}
			/>
			<Button
				className='sdms-font-transform-c'
				text={isSubmitting ? 'Releasing' : 'Yes, Release'}
				design='clean'
				icon='Edit'
				disabled={isSubmitting}
				onClick={onVoid}
				isSubmitting={isSubmitting}
			/>
		</DialogBox>
	);
};
AuthorizedPaymentVoid.propTypes = {
	open: PropTypes.bool,
	isSubmitting: PropTypes.bool,
	onVoid: PropTypes.func,
	onClose: PropTypes.func,
};

AuthorizedPaymentVoid.defaultProps = {
	open: false,
	isSubmitting: false,
	onVoid: () => {},
	onClose: () => {},
};

export default AuthorizedPaymentVoid;
