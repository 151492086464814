import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../reusables/element/Button';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';
import {
	addErrorNotification,
	addSuccessNotification,
	parsePrint,
} from '../../../utils/helpers/helper';
import UserContext from '../../../app/contexts/UserContext';
import PrinterModal from '../../reusables/modals/PrinterModal';
// eslint-disable-next-line import/namespace,import/default,import/no-named-as-default,import/no-named-as-default-member
import WebPrint from '../../reusables/print/WebPrint';
import Badge from '../../reusables/element/Badge';
import useDate from '../../../utils/hooks/useDate';

const DateCell = ({ data }) => {
	const [dateFormatter] = useDate();

	return dateFormatter(data.timeCreated, true, 'M/D/YYYY h:mm:ss a');
};

const StatusCell = ({ data }) => {
	return (
		<>
			{data.success ? (
				<Badge design='success' isInline isUnified size='lg' fontWeight='bold'>
					Success
				</Badge>
			) : (
				<Badge design='danger' isInline isUnified size='lg' fontWeight='bold'>
					Failed
				</Badge>
			)}
		</>
	);
};

StatusCell.propTypes = {
	data: PropTypes.shape({
		success: PropTypes.bool,
	}),
};

StatusCell.defaultProps = {
	data: {
		success: null,
	},
};

const PrintLogList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const webPrint = useRef({ print: () => {}, printers: [] });

	const [isPrinterModalOpen, setIsPrinterModalOpen] = useState(false);

	const printContent = useRef('');

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.pos.default.text, path: pages.pos.dashboard.path },
			{ title: pages.pos.settings.text, path: pages.pos.settings.path },
			{ title: pages.pos.settings.printLogs.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.pos.settings.printLogs.text);

		webPrint.current = new WebPrint({
			user: userContext.data.user,
			outlet: userContext.data.selectedOutlet,
			onStart: () => addSuccessNotification('Printing...'),
			onFinish: () => {},
			onFail: (printItem, err) => addErrorNotification(err.toString()),
		});

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	const customMultiActions = {
		print: {
			func: logs => {
				printContent.current = '';
				logs.forEach(l => {
					printContent.current += l.content;
				});

				setIsPrinterModalOpen(true);
			},
			component: <Button label='success' text='Preview' icon='Printer' key='print' />,
		},
	};

	const customActions = {
		print: log => {
			printContent.current = log.content;

			setIsPrinterModalOpen(true);
		},
	};

	return (
		<>
			<ListContainer
				route='printLogs'
				title={pages.pos.settings.printLogs.text}
				history={history}
				icon={pages.pos.settings.printLogs.icon}
				forms={[]}
				customMultiActions={customMultiActions}
				customActions={customActions}>
				<List withCheckBox fluid='fluid'>
					<List.Col label='#' cellData='id' sortable='id' />
					<List.Col
						label='Printer'
						cellData='name'
						cellDataObject='printer'
						sortable='printer.name'
					/>
					<List.Col label='Order' cellData='name' cellDataObject='sdmsOrder' />
					<List.Col
						label='Action'
						cellData='value'
						cellDataObject='action'
						sortable='action.value'
					/>
					<List.Col label='Status' cellComponent={<StatusCell />} />
					<List.Col label='Date' cellComponent={<DateCell />} sortable='timeCreated' />
					<List.Col align='right' onlyHover width={150}>
						<Button label='success' text='Preview' icon='Printer' key='print' />
					</List.Col>
				</List>
			</ListContainer>
			<PrinterModal
				isOpen={isPrinterModalOpen}
				onClose={() => setIsPrinterModalOpen(false)}
				printers={webPrint.current.printers}
				preview={parsePrint(printContent.current)}
				onSubmit={printer => {
					webPrint.current.print(printContent.current, printer.id);
					setIsPrinterModalOpen(false);
				}}
			/>
		</>
	);
};

PrintLogList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default PrintLogList;
