export const widths = [
	{ value: '24%', label: '1/4 page width' },
	{ value: '32%', label: '1/3 page width' },
	{ value: '49%', label: '1/2 page width' },
	{ value: '99%', label: 'page width' },
];

export const alignments = [
	{ value: 'left', label: 'Left' },
	{ value: 'center', label: 'Center' },
	{ value: 'right', label: 'Right' },
];
