import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';

import FormContainer from '../template/FormContainer';
import PromoCodeForm from './PromoCodeForm';

const PromoCodeEdit = ({ match }) => {
	const location = useLocation();

	const pages = usePages();

	const data = useMemo(() => {
		if (location.pathname.indexOf('marina') > -1)
			return {
				pageTitle: pages.marina.settings.promoCodes.text,
				icon: pages.marina.settings.promoCodes.icon,
				lists: [
					'bookingTypes',
					'enumBookingPeriods',
					'itemTypes',
					'taxCodes',
					'accounts',
					'enumBookingCalculations',
					'enumDays',
					'enumExtraChargeTypes',
				],
				module: modules.MARINA,
			};

		if (location.pathname.indexOf('campground') > -1)
			return {
				pageTitle: pages.campground.settings.promoCodes.text,
				icon: pages.campground.settings.promoCodes.icon,
				lists: [
					'bookingTypes',
					'enumBookingPeriods',
					'itemTypes',
					'taxCodes',
					'accounts',
					'enumBookingCalculations',
					'enumDays',
					'enumExtraChargeTypes',
				],
				module: modules.CAMPGROUND,
			};

		return {
			pageTitle: pages.booking.settings.promoCodes.text,
			icon: pages.booking.settings.promoCodes.icon,
			lists: [
				'bookingTypes',
				'enumBookingPeriods',
				'itemTypes',
				'taxCodes',
				'accounts',
				'enumBookingCalculations',
				'enumDays',
				'enumExtraChargeTypes',
			],
			module: modules.BOOKINGS,
		};
	}, [location, pages]);

	return (
		<FormContainer
			dataId={match.params.id.toString()}
			dataName='promoCodes'
			fields={<PromoCodeForm />}
			pageTitle={data.pageTitle}
			icon={data.icon}
			lists={data.lists}
			module={data.module}
		/>
	);
};
PromoCodeEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default PromoCodeEdit;
