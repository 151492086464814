import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Utils as QbUtils } from 'react-awesome-query-builder';
import { generateApiKey } from 'generate-api-key';

import HeaderContext from '../../../app/contexts/HeaderContext';
import UserContext from '../../../app/contexts/UserContext';
import { reportTypes } from '../../../utils/constants/constants';
import {
	getReportQueryFields,
	getReportPath,
	getReportText,
	getQueryBuildConfig,
	reportCheckChildren,
} from '../../../utils/helpers/helper';
import { required, uniqueFnc } from '../../../utils/helpers/validation';
import pages from '../../pages';

import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Toggle from '../../reusables/field/Toggle';
import Loading from '../../reusables/template/Loading';
import Selects from '../../reusables/field/Selects';
import useField from '../../../utils/hooks/useField';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Badge from '../../reusables/element/Badge';
import QueryBuilder from '../../reusables/field/QueryBuilder';
import DualListbox from '../../reusables/field/DualListbox';

const ReportsForm = ({
	data,
	isLoading,
	setTitle,
	onFormChange,
	isSubmitted,
	setIsValid,
	submitButtonAttr,
	submit,
	enumReportTypes,
	report,
}) => {
	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const historyLocation = useLocation();

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		report ? report.name : '',
		null,
		setTitle
	);

	const [summarizeReport, summarizeReportOnChange] = useField(
		data,
		'summarizeReport',
		onFormChange,
		[],
		report ? report.summarizeReport : false
	);

	const [
		subtotal,
		subtotalOnChange,
		subtotalValRes,
		subtotalShowVal,
		setSubtotalShowVal,
	] = useField(data, 'subtotal', onFormChange, [], null);

	const [token, tokenOnChange, tokenValRes, tokenShowVal, setTokenShowVal] = useField(
		data,
		'token',
		onFormChange,
		[uniqueFnc('users', 'token', data.id, false)]
	);

	const [fieldListValRes, setFieldListValRes] = useState({ message: '', isValid: false });

	const [fieldListShowVal, setFieldListShowVal] = useState(false);

	const getReportFields = reportType => {
		const fields = [];

		if (!reportType) return fields;

		reportType.dataSets
			.filter(a => a.fieldGroup !== null)
			.sort((a, b) => {
				const n = (a.fieldGroup.sortOrder || 0) - (b.fieldGroup.sortOrder || 0);
				if (n !== 0) {
					return n;
				}

				return a.fieldGroup.label < b.fieldGroup.label ? -1 : 1;
			})
			.forEach(a => {
				const curGroup = fields.find(group => group.label === a.fieldGroup.label);
				if (curGroup)
					curGroup.options.push({ value: a.alias, label: a.label, dataSetField: a });
				else
					fields.push({
						label: a.fieldGroup.label,
						options: [{ value: a.alias, label: a.label, dataSetField: a }],
					});
			});

		return fields;
	};

	const getSelectedFields = (reportFields, listBox = true) => {
		if (!reportFields) return [];

		return reportFields
			.sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : 1))
			.map(a => {
				if (listBox) return a.dataSetField.alias;
				return a.dataSetField;
			});
	};

	const getReportType = () => {
		// newreporttype
		const reportType = historyLocation.pathname.split('/');

		switch (`${reportType[2]}/${reportType[3]}`) {
			case 'accounting/deferred_income':
				return reportTypes.DEFERRED_INCOME;
			case 'accounting/general_ledger':
				return reportTypes.GENERAL_LEDGER;
			case 'accounting/payroll':
				return reportTypes.PAYROLL;
			case 'accounting/settlement_details':
				return reportTypes.SETTLEMENT_DETAILS;
			case 'accounting/deposit_balances':
				return reportTypes.DEPOSIT_BALANCES;
			case 'accounting/reservation_daily_revenue':
				return reportTypes.RESERVATION_DAILY_REVENUE;
			case 'campground/reservations':
				return reportTypes.CAMPGROUND_RESERVATIONS;
			case 'campground/rent_roll':
				return reportTypes.CAMPGROUND_RENT_ROLL;
			case 'crm/customers':
				return reportTypes.CRM_CUSTOMERS;
			case 'crm/balance_details':
				return reportTypes.CRM_BALANCE_DETAILS;
			case 'crm/payments':
				return reportTypes.CRM_PAYMENTS;
			case 'crm/invoices':
				return reportTypes.CRM_INVOICES;
			case 'crm/invoice_details':
				return reportTypes.CRM_INVOICE_DETAILS;
			case 'pos/zout':
				return reportTypes.ZOUT;
			case 'pos/orders':
				return reportTypes.POS_ORDERS;
			case 'pos/orderdetails':
				return reportTypes.POS_ORDERDETAILS;
			case 'pos/ordermodifiers':
				return reportTypes.POS_ORDERMODIFIERS;
			case 'pos/tips':
				return reportTypes.POS_TIPS;
			case 'booking/reservations':
				return reportTypes.BOOKING_RESERVATIONS;
			case 'marina/reservations':
				return reportTypes.MARINA_RESERVATIONS;
			case 'marina/rent_roll':
				return reportTypes.MARINA_RENT_ROLL;
			case 'marina/vessels':
				return reportTypes.MARINA_VESSELS;
			default:
				return null;
		}
	};

	const [subtotalOptions, setSubtotalOptions] = useState(
		report ? getSelectedFields(report.reportFields, false) : []
	);

	const [availableFields, setAvailableFields] = useState(
		getReportFields(report ? report.type : null)
	);

	const [selectedFields, setSelectedFields] = useState(
		report ? getSelectedFields(report.reportFields) : []
	);

	const [initTree, setInitTree] = useState(report ? JSON.parse(report.queryJSON) : null);

	const [queryFields, setQueryFields] = useState(getReportQueryFields(null));

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setFieldListShowVal(!fieldListValRes.isValid);
			setTokenShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setFieldListShowVal,
		fieldListValRes.isValid,
		setTokenShowVal,
	]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && tokenValRes.isValid && fieldListValRes.isValid);
	}, [nameValRes.isValid, fieldListValRes.isValid, setIsValid, tokenValRes.isValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.reports.default.text,
				path: pages.reports.dashboard.path,
			},
			{
				title: getReportText(historyLocation.pathname),
				path: getReportPath(historyLocation.pathname),
			},
			{ title: name || `New ${getReportText(historyLocation.pathname)}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${getReportText(historyLocation.pathname)}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		if (!isLoading) {
			data.isSystem = false;

			// eslint-disable-next-line no-unused-expressions
			data.queryJSON
				? setInitTree(JSON.parse(data.queryJSON))
				: setInitTree({ id: QbUtils.uuid(), type: 'group' });

			if (data.reportFields) setSelectedFields(getSelectedFields(data.reportFields));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		data.type = enumReportTypes.find(ert => ert.value === getReportType());
		setQueryFields(getReportQueryFields(data.type));
		setAvailableFields(getReportFields(data.type));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enumReportTypes]);

	useEffect(() => {
		data.queryJSON = JSON.stringify(initTree);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initTree]);

	useEffect(() => {
		if (initTree && Object.keys(queryFields).length) {
			const config = { ...getQueryBuildConfig(), fields: queryFields };

			const tree = QbUtils.checkTree(
				QbUtils.loadTree(reportCheckChildren(JSON.parse(data.queryJSON))),
				config
			);

			data.querySQL = JSON.stringify(QbUtils.sqlFormat(tree, config)) || null;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initTree, queryFields]);

	useEffect(() => {
		if (!availableFields.length) return;

		if (selectedFields.length) {
			setFieldListValRes({
				isValid: true,
				message: '',
			});
			setFieldListShowVal(false);
		} else {
			setFieldListValRes({
				isValid: false,
				status: 'required',
				message: 'Field is required',
			});
			// setFieldListShowVal(true);
		}

		data.reportFields = selectedFields.map((m, i) => {
			let dataSetField = {};
			return availableFields.some(v => {
				const findObj = v.options.find(a => a.value === m);
				if (findObj) {
					dataSetField = findObj.dataSetField;
					return true;
				}
				return false;
			})
				? { dataSetField, sortOrder: i }
				: {};
		});

		setSubtotalOptions(
			data.reportFields.map(m => {
				return m.dataSetField;
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availableFields, selectedFields]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colMd={4}>
								<Input
									type='text'
									placeholder='Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='summarizeReport'
								label='Summarize Report'
								id={data.id}
								col={2}>
								<Toggle
									value={summarizeReport}
									onChange={summarizeReportOnChange}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='subtotal'
								label='Subtotal'
								id={data.id}
								valRes={subtotalValRes}
								showValidation={subtotalShowVal}
								col={3}>
								<Selects
									options={subtotalOptions}
									placeholder='Subtotal'
									value={subtotal}
									displayKey='label'
									onChange={subtotalOnChange}
									onBlur={setSubtotalShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='token'
								label={
									userContext.hasPermission('change_token') ? (
										<>
											Token
											<Badge
												className='sdms-cursor--pointer sdms-ml-10'
												design='info'
												isInline
												isElevate
												onMouseDown={() => {
													tokenOnChange({
														target: {
															value: generateApiKey({
																method: 'base32',
															}),
														},
													});
												}}>
												Generate Token
											</Badge>
										</>
									) : (
										'Token'
									)
								}
								labelClassName='d-flex'
								description='API Key Security Validation'
								id={data.id}
								valRes={tokenValRes}
								showValidation={tokenShowVal}
								colLg={3}
								noPermission={!userContext.hasPermission('change_token')}>
								<Input
									type='password'
									placeholder='API Key'
									autoComplete={false}
									value={token}
									onChange={tokenOnChange}
									onBlur={setTokenShowVal}
									togglePassword
									noPermission={!userContext.hasPermission('change_token')}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='fieldList'
								id={data.id}
								valRes={fieldListValRes}
								showValidation={fieldListShowVal}
								colMd={12}>
								<DualListbox
									disabled={isLoading}
									options={availableFields}
									selected={selectedFields}
									onChange={selected => {
										setSelectedFields(selected);
										onFormChange();
									}}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='queryJSON' label='Query' id={data.id} colMd={12}>
								<QueryBuilder
									onChange={json => {
										data.queryJSON = JSON.stringify(JSON.parse(json));

										if (initTree && Object.keys(queryFields).length) {
											const config = {
												...getQueryBuildConfig(),
												fields: queryFields,
											};

											const tree = QbUtils.checkTree(
												QbUtils.loadTree(
													reportCheckChildren(JSON.parse(data.queryJSON))
												),
												config
											);

											data.querySQL =
												JSON.stringify(QbUtils.sqlFormat(tree, config)) ||
												null;
										}
									}}
									initTree={initTree}
									fields={queryFields}
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
ReportsForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		isSystem: PropTypes.bool,
		subtotal: PropTypes.object,
		token: PropTypes.string,
		queryJSON: PropTypes.string,
		querySQL: PropTypes.string,
		type: PropTypes.object,
		reportFields: PropTypes.arrayOf(PropTypes.object),
	}),
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setIsValid: PropTypes.func,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
	enumReportTypes: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	report: PropTypes.object,
};
ReportsForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		isSystem: false,
		subtotal: null,
		token: '',
		queryJSON: '',
		querySQL: '',
		type: null,
		reportFields: [],
	},
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
	enumReportTypes: [],
	report: null,
};

export default ReportsForm;
