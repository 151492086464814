import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import forms from '../../forms';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import Badge from '../../reusables/element/Badge';
import { pathToUrl } from '../../../utils/helpers/apiCall';
import { randomColor } from '../../../utils/helpers/helper';

const LOACell = ({ data }) => {
	return (
		<>
			<Badge design='brand' isDot>
				{data.loa > 11
					? `${Math.floor(data.loa / 12).toString()}'${data.loa % 12}"`
					: `${data.loa}'`}
			</Badge>
		</>
	);
};
LOACell.propTypes = {
	data: PropTypes.shape({
		loa: PropTypes.number,
	}),
};
LOACell.defaultProps = {
	data: {
		loa: 0,
	},
};

const ImageCell = ({ data }) => {
	if (data.images && data.images[0]) {
		return <img alt={data.name} src={pathToUrl(data.images[0].thumb)} />;
	}
	return (
		<Badge
			design={randomColor(data.id)}
			className='sdms-font-transform-u'
			isUnified
			size='lg'
			isRounded
			fontWeight='bold'>
			{data.name.charAt(0)}
		</Badge>
	);
};
ImageCell.propTypes = {
	data: PropTypes.shape({
		name: PropTypes.string,
		images: PropTypes.arrayOf(PropTypes.object),
		id: PropTypes.number,
	}),
};
ImageCell.defaultProps = {
	data: { name: 'Product', images: [], id: 0 },
};

const VehicleList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
			{ title: pages.campground.text, path: pages.campground.path },
			{ title: pages.campground.vehicles.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.campground.vehicles.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='vehicles'
			title={pages.campground.vehicles.text}
			history={history}
			forms={forms.campground.vehicles}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Images' cellComponent={<ImageCell />} width={70} />
				<List.Col label='Vehicle Id' cellData='id' sortable='id' isLinkColumn />
				<List.Col label='Name' cellData='name' sortable='name' isLinkColumn />
				<List.Col
					label='Customer'
					cellData='displayName'
					cellDataObject='customer'
					sortable='customer.displayName'
				/>
				<List.Col
					label='Current Space'
					cellDataObject='currentSpace'
					cellData='name'
					sortable='currentSpace.name'
				/>
				<List.Col label='LOA' cellComponent={<LOACell />} sortable='loa' />
				<List.Col
					label='Type'
					cellData='value'
					cellDataObject='rvType'
					sortable='rvType.value'
				/>
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Erase' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

VehicleList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default VehicleList;
