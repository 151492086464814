import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import { pathToUrl } from '../../../utils/helpers/apiCall';
import { priceFormatter, randomColor } from '../../../utils/helpers/helper';
import HeaderContext from '../../../app/contexts/HeaderContext';
import Badge from '../../reusables/element/Badge';
import pages from '../../pages';
import UserContext from '../../../app/contexts/UserContext';
import forms from '../../forms';
import PrintBarcodeModal from './PrintBarcodeModal';

const ImageCell = ({ data }) => {
	if (data.productImage) {
		return (
			<img
				alt={data.name}
				src={
					data.productImage &&
					data.productImage.thumb &&
					pathToUrl(data.productImage.thumb)
				}
			/>
		);
	}
	return (
		<Badge
			design={randomColor(data.id)}
			className='sdms-font-transform-u'
			isUnified
			size='lg'
			isRounded
			fontWeight='bold'>
			{data.name.charAt(0)}
		</Badge>
	);
};
ImageCell.propTypes = {
	data: PropTypes.shape({
		name: PropTypes.string,
		productImage: PropTypes.shape({ thumb: PropTypes.string }),
		id: PropTypes.number,
	}),
};
ImageCell.defaultProps = {
	data: { name: 'Product', productImage: { thumb: null }, id: 0 },
};

const ProductNameCell = ({ data, onClick, noPermission }) => {
	return (
		<div onClick={noPermission ? onClick : null} role='presentation'>
			<span
				className={classNames('sdms-font-bold', {
					'sdms-link sdms-link--dark': noPermission,
				})}>
				{data.name}
			</span>
			<div className='sdms-font-sm sdms-list-layout__small-text'>Sku: {data.sku}</div>
		</div>
	);
};
ProductNameCell.propTypes = {
	data: PropTypes.shape({
		name: PropTypes.string,
		sku: PropTypes.string,
	}),
	onClick: PropTypes.func,
	noPermission: PropTypes.bool,
};
ProductNameCell.defaultProps = {
	data: {
		name: null,
		sku: null,
	},
	onClick: () => {},
	noPermission: null,
};

const PriceCell = ({ data }) => {
	if (data.price > 0) return <>{priceFormatter(data.price)}</>;
	return (
		<Badge design='success' isInline isUnified size='lg' fontWeight='bold'>
			Free
		</Badge>
	);
};
PriceCell.propTypes = {
	data: PropTypes.shape({
		price: PropTypes.number,
	}),
};
PriceCell.defaultProps = {
	data: { price: 0 },
};

const CategoryCell = ({ data }) => {
	if (data.productCategory === null) return null;
	return (
		<>
			<Badge design='brand' isInline isUnified size='lg'>
				{data.productCategory.name}
			</Badge>
		</>
	);
};
CategoryCell.propTypes = {
	data: PropTypes.shape({
		productCategory: PropTypes.shape({
			name: PropTypes.string,
		}),
	}),
};
CategoryCell.defaultProps = {
	data: {
		productCategory: {
			name: null,
		},
	},
};

const TypeCell = ({ data }) => {
	return (
		<>
			<Badge design='brand' isDot className='sdms-font-transform-c'>
				{data.itemType.value}
			</Badge>
		</>
	);
};
TypeCell.propTypes = {
	data: PropTypes.shape({
		itemType: PropTypes.object,
		value: PropTypes.string,
	}),
};
TypeCell.defaultProps = {
	data: {
		itemType: null,
		value: null,
	},
};

const ProductList = ({ history }) => {
	const headerContext = useContext(HeaderContext);
	const userContext = useContext(UserContext);
	const [isPrintBarcodeModalOpen, setIsPrintBarcodeModalOpen] = useState(false);

	const printBarcodeData = useRef();
	const customActions = {
		printBarcode: data => {
			printBarcodeData.current = {
				productId: data.productId,
				sku: data.sku,
				name: data.name,
				price: data.price,
				margin: 10,
			};
			setIsPrintBarcodeModalOpen(true);
		},
	};
	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.pos.default.text, path: pages.pos.dashboard.path },
			{ title: pages.pos.products.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.pos.products.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);
	const customFilters = [
		{
			component: 'selects',
			dataName: 'productCategories',
			fieldName: 'productCategory',
			placeholder: 'Category',
		},
		{
			component: 'selects',
			dataName: 'itemTypes',
			fieldName: 'itemType',
			displayKey: 'value',
			placeholder: 'Types',
		},
	];

	return (
		<>
			<ListContainer
				route='posProducts'
				title='Products'
				history={history}
				forms={forms.pos.products}
				customActions={customActions}
				customFilters={customFilters}>
				<List withCheckBox fluid='fluid' withImage>
					<List.Col label='Image' cellComponent={<ImageCell />} width={70} />
					<List.Col
						label='Product Id'
						width={130}
						cellData='productId'
						isLinkColumn={userContext.hasPermission('edit_pos_products')}
						sortable='productId'
					/>
					<List.Col
						label='Name'
						cellComponent={
							<ProductNameCell
								noPermission={userContext.hasPermission('edit_pos_products')}
							/>
						}
						isLinkColumn={userContext.hasPermission('edit_pos_products')}
						sortable='name'
					/>
					<List.Col label='Price' cellComponent={<PriceCell />} sortable='price' />
					<List.Col
						label='Category'
						cellComponent={<CategoryCell />}
						sortable='productCategory.name'
					/>
					<List.Col label='Type' cellComponent={<TypeCell />} sortable='itemType.value' />
					<List.Col align='right' onlyHover width={150}>
						<Button
							className='sdms-margin-r-15'
							design='link'
							text='Edit'
							icon='Edit'
							size='sm'
							elevate
							key='edit'
							noPermission={!userContext.hasPermission('edit_pos_products')}
						/>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item
								icon='Barcode'
								key='printBarcode'
								noPermission={
									!userContext.hasPermission('print_pos_products_barcodes')
								}>
								Print Barcodes
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='danger'
								icon='Erase'
								key='delete'
								noPermission={!userContext.hasPermission('delete_pos_products')}>
								Delete
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			{isPrintBarcodeModalOpen && (
				<PrintBarcodeModal
					data={printBarcodeData.current}
					isOpen={isPrintBarcodeModalOpen}
					onClose={() => setIsPrintBarcodeModalOpen(false)}
				/>
			)}
		</>
	);
};
ProductList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default ProductList;
