import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const FitText = ({ children, withClass, defaultSize, aspectRatio }) => {
	const ref = useRef(null);
	const size =
		ref &&
		ref.current &&
		(aspectRatio * ref.current.offsetWidth) / ref.current.innerText.length;

	if (withClass) {
		return React.cloneElement(children, {
			className: `sdms-fitText ${children.props.className}`,
		});
	}
	return React.cloneElement(children, {
		style: { fontSize: size <= defaultSize && size, ...children.props.style },
		ref,
	});
};
FitText.propTypes = {
	children: PropTypes.node.isRequired,
	withClass: PropTypes.bool,
	defaultSize: PropTypes.number,
	aspectRatio: PropTypes.number,
};
FitText.defaultProps = {
	withClass: false,
	defaultSize: 13,
	// Poppins font aspect ratio
	aspectRatio: 1.4,
};

export default FitText;
