import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import useDate from '../../../utils/hooks/useDate';
import {
	addErrorNotification,
	invoiceToPayment,
	isModuleEnabled,
	priceFormatter,
} from '../../../utils/helpers/helper';
import { getRelatedOrders } from '../../../utils/helpers/orderHelper';
import apiCall, { modules } from '../../../utils/helpers/apiCall';
import {
	orderStatuses as _orderStatuses,
	paymentTypeIcons,
	paymentTypes,
} from '../../../utils/constants/constants';

import Portlet from '../../reusables/layout/Portlet';
import Search from '../../reusables/element/Search';
import SelectableList from '../../reusables/template/SelectableList';
import Selects from '../../reusables/field/Selects';
import Badge from '../../reusables/element/Badge';
import Button from '../../reusables/element/Button';
import DatePicker from '../../reusables/field/DatePicker';
import Loading from '../../reusables/template/Loading';
import TablePagination from '../../reusables/element/TablePagination';
import NullBadge from '../../reusables/design/NullBadge';
import SVGIcon from '../../reusables/element/SVGIcon';

const getColor = s => {
	let color = null;
	switch (s) {
		case 'closed':
		case _orderStatuses.VOIDED:
			color = 'danger';
			break;
		case _orderStatuses.FINALIZED:
			color = 'primary';
			break;
		case _orderStatuses.OPEN:
			color = 'info';
			break;
		case _orderStatuses.PAID:
			color = 'success';
			break;
		case _orderStatuses.PARTIAL:
			color = 'warning';
			break;
		case _orderStatuses.REFUNDED:
			color = 'warning';
			break;
		default:
			break;
	}
	return color;
};

const OrderStatus = ({ data }) => {
	return getColor(data.status.value);
};

const OrderNameCell = ({ data }) => {
	return <span className='sdms-link sdms-link--dark'>{`${data.name}`}</span>;
};
OrderNameCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};
OrderNameCell.defaultProps = {
	data: {
		name: '-',
	},
};

const OrderTableNameCell = ({ data }) => {
	return <span className='sdms-link sdms-link--dark'>{data.unit ? data.unit.name : '-'}</span>;
};
OrderTableNameCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};
OrderTableNameCell.defaultProps = {
	data: {
		unit: {
			name: '-',
		},
	},
};

const CustomerNameCell = ({ data }) => {
	return (
		<span
			className='sdms-link sdms-link--dark sdms-text-overflow'
			title={data.customer.displayName}>
			{data.customer.displayName}
		</span>
	);
};
CustomerNameCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};
CustomerNameCell.defaultProps = {
	data: {
		customer: {
			displayName: '-',
		},
	},
};

const VesselCell = ({ data }) => {
	return data.vessel?.name || <NullBadge />;
};
VesselCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};
VesselCell.defaultProps = {
	data: {
		vessel: null,
	},
};

const ServerNameCell = ({ data }) => {
	return (
		<>
			<span className='sdms-link sdms-link--dark sdms-text-overflow'>
				{data.server.displayName}
			</span>
			{data.note && (
				<div className='sdms-font-bold sdms-font-sm sdms-list-layout__small-text sdms-text-overflow'>
					{data.note}
				</div>
			)}
		</>
	);
};
ServerNameCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};
ServerNameCell.defaultProps = {
	data: {
		server: {
			displayName: '-',
		},
	},
};

const TotalCell = ({ data }) => {
	return <span className='sdms-link sdms-link--dark'>{priceFormatter(data.total)}</span>;
};
TotalCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};
TotalCell.defaultProps = {
	data: {
		total: 0,
	},
};

const PaidCell = ({ data }) => {
	return <span className='sdms-link sdms-link--dark'>{priceFormatter(data.amountInvoiced)}</span>;
};
PaidCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};
PaidCell.defaultProps = {
	data: {
		amountInvoiced: 0,
	},
};

const TipCell = ({ data }) => {
	return <span className='sdms-link sdms-link--dark'>{priceFormatter(data.tip)}</span>;
};
TipCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};
TipCell.defaultProps = {
	data: {
		invoices: [],
	},
};

const DateCell = ({ data }) => {
	const [dateFormatter] = useDate();

	return <span className='sdms-link sdms-link--dark'>{dateFormatter(data.timeCreated)}</span>;
};
DateCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};
DateCell.defaultProps = {
	data: {
		timeCreated: new Date().getTime(),
	},
};

export const PaymentMethodCell = ({ data }) => {
	if (data.invoices.length === 0) return <NullBadge />;

	return [
		...new Set(
			data.invoices
				.map(invoiceToPayment)
				.map(payment => payment?.paymentMethod?.paymentType?.value)
		),
	].map(paymentType => <SVGIcon name={paymentTypeIcons[paymentType] || 'Cash'} />);
};

PaymentMethodCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};
PaymentMethodCell.defaultProps = {
	data: null,
};

export const StatusCell = ({ data, size }) => {
	return (
		<Badge
			design={getColor(data.status.value)}
			isInline
			isUnified={data.status.value !== _orderStatuses.PARTIAL}
			size={size}
			fontWeight='bold'
			className='sdms-font-transform-c'>
			{data.status.value}
		</Badge>
	);
};
StatusCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	size: PropTypes.string,
};
StatusCell.defaultProps = {
	data: {
		status: {
			value: '-',
		},
	},
	size: 'lg',
};

const RecallOrders = ({
	users,
	orderStatuses,
	tables,
	canViewOthersOrders,
	outlet,
	user,
	onClick,
	selectedOrder,
	isOrderLoading,
	setIsOrderLoading,
	hasSideCar,
}) => {
	const userContext = useContext(UserContext);

	const [currentPage, setCurrentPage] = useState(1);

	const [itemsPerPage, setItemsPerPage] = useState(50);

	const [totalPage, setTotalPage] = useState(1);

	const [totalItems, setTotalItems] = useState(0);

	const [timeoutId, setTimeoutId] = useState(0);

	const [orderSearchText, setOrderSearchText] = useState('');

	const [customerSearchText, setCustomerSearchText] = useState('');

	const [selectedTable, setSelectedTable] = useState(null);

	const [selectedServer, setSelectedServer] = useState(null);

	const [selectedStatus, setSelectedStatus] = useState(null);

	const [range, setRange] = useState({ startDate: null, endDate: null });

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

	const [orders, setOrders] = useState([]);

	const abortControllerArray = useRef([]);

	const [dateFormatter] = useDate();

	const [moreFilters, setMoreFilters] = useState(false);

	const paymentMethodOptions = useRef(
		Object.values(paymentTypes)
			.filter(pt => ['Wire', 'Credit'].indexOf(pt) === -1)
			.map(pt => ({ id: pt, name: pt }))
	);

	useEffect(() => {
		clearTimeout(timeoutId);
		setTimeoutId(
			setTimeout(() => {
				// setIsPageLoading(true);
				setIsOrderLoading(true);

				const filters = {
					'groups[]': 'order-sale:read',
					pagination: true,
					itemsPerPage,
					page: currentPage,
					outlet: outlet.id,
					'order[id]': 'desc',
				};

				if (!canViewOthersOrders) filters.server = user.id;

				if (customerSearchText.length) filters['customer.displayName'] = customerSearchText;

				if (orderSearchText.length) filters.name = orderSearchText;

				if (selectedTable && selectedTable.id) filters['unit.id'] = selectedTable.id;

				if (selectedServer && selectedServer.id) filters.server = selectedServer.id;

				if (selectedStatus && selectedStatus.id) {
					filters['status.id'] = selectedStatus.id;

					if (selectedStatus.value !== _orderStatuses.CLOSED)
						filters['exists[registerBatch.endTime]'] = false;
				} else {
					filters['status.id[]'] = [
						_orderStatuses.OPEN,
						_orderStatuses.PARTIAL,
						_orderStatuses.PAID,
						_orderStatuses.FINALIZED,
						_orderStatuses.VOIDED,
					].map(o => orderStatuses.find(os => os.value === o).id);

					filters['exists[registerBatch.endTime]'] = false;
				}

				if (range.startDate) {
					filters[
						'timeCreated[after]'
					] = `${range.startDate.getFullYear()}-${range.startDate.getMonth() +
						1}-${range.startDate.getDate()}`;
					filters[
						'timeCreated[before]'
					] = `${range.endDate.getFullYear()}-${range.endDate.getMonth() +
						1}-${range.endDate.getDate()}`;
				}

				if (selectedPaymentMethod) filters.payment_method = selectedPaymentMethod.id;

				const abortController = new AbortController();

				if (abortControllerArray.current.length) abortControllerArray.current[0].abort();

				abortControllerArray.current.push(abortController);

				apiCall(
					'GET',
					'saleOrders',
					res => {
						if ((res['hydra:member'] || {}).length === 0) {
							setOrders([]);
						} else {
							setOrders(res['hydra:member']);
						}
						setTotalItems(res['hydra:totalItems']);
						setIsOrderLoading(false);
						abortControllerArray.current = [];
					},
					err => {
						if (err.toString().search('AbortError') === -1) {
							addErrorNotification(err.toString());
							setIsOrderLoading(false);
							abortControllerArray.current = [];
						}
					},
					'',
					null,
					filters,
					abortController.signal
				);
			}, 500)
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		orderSearchText,
		customerSearchText,
		selectedTable,
		selectedServer,
		selectedStatus,
		selectedPaymentMethod,
		range,
		itemsPerPage,
		currentPage,
	]);

	useEffect(() => {
		setTotalPage(Math.ceil(totalItems / itemsPerPage));
	}, [totalItems, itemsPerPage]);

	useEffect(() => {
		setOrders(
			orders.map(o => {
				if (selectedOrder && selectedOrder.id && o.id === selectedOrder.id) {
					o = selectedOrder;
				}
				return o;
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOrder]);

	const [filterVisible, setFilterVisible] = useState(false);

	const _leftFilterItems = [
		<Search
			placeHolder='Order...'
			inPortlet
			searchText={orderSearchText}
			setSearchText={setOrderSearchText}
			setCurrentPage={setCurrentPage}
			isSearching={isOrderLoading}
			key='orderNameSearch'
		/>,
		<Selects
			placeholder='All Tables'
			inPortlet
			onChange={event => {
				setSelectedTable(event.target.value);
				setCurrentPage(1);
			}}
			options={tables}
			value={selectedTable}
			disabled={isOrderLoading}
			key='tableSelect'
		/>,
		<Search
			placeHolder='Customer...'
			inPortlet
			searchText={customerSearchText}
			setSearchText={setCustomerSearchText}
			setCurrentPage={setCurrentPage}
			isSearching={isOrderLoading}
			key='customerSearch'
		/>,
		<SVGIcon
			className='sdms-recall-order-filter-icon'
			name='Filter'
			size={72}
			fill='var(--info)'
			onClick={() => setMoreFilters(!moreFilters)}
		/>,
	];

	const _rightFilterItems = [
		<DatePicker
			id='range'
			value={range}
			onChange={setRange}
			place={window.outerWidth < 1400 ? 'right' : 'bottom'}
			key='datePicker'
			type='dateRange'
			className='sdms-portlet__search'
		/>,
		<Selects
			placeholder='All Status'
			inPortlet
			onChange={event => setSelectedStatus(event.target.value)}
			options={orderStatuses}
			value={selectedStatus}
			displayKey='value'
			disabled={isOrderLoading}
			className='sdms-mr-0 sdms-font-transform-c'
			key='statusSelect'
		/>,
	];

	const _moreFilterItems = [
		<Selects
			placeholder='All Server'
			inPortlet
			onChange={e => {
				setSelectedServer(e.target.value);
				setCurrentPage(1);
			}}
			options={users}
			value={selectedServer}
			displayKey='displayName'
			disabled={isOrderLoading}
			key='serverSelect'
		/>,
		<Selects
			placeholder='All Payment Methods'
			inPortlet
			onChange={e => {
				setSelectedPaymentMethod(e.target.value);
				setCurrentPage(1);
			}}
			options={paymentMethodOptions.current}
			value={selectedPaymentMethod}
			disabled={isOrderLoading}
			key='paymentMethodSelect'
		/>,
	];

	return (
		<div
			id='sdms_recall_list'
			className={classNames(
				'col-xl-8',
				'col-lg-7',
				'col-md-12',
				'col-12',
				'sdms-select-list',
				{ 'sdms-h-50-tablet-and-mobile': !hasSideCar }
			)}>
			<Portlet fluid='fluid' className='sdms-list-layout'>
				<Portlet.Head
					className={classNames('sdms-hidden-minimal-desktop-and-below', {
						'sdms-portlet__head--noborder': moreFilters,
					})}>
					<Portlet.HeadLabel>{_leftFilterItems}</Portlet.HeadLabel>
					<Portlet.HeadLabel>{_rightFilterItems}</Portlet.HeadLabel>
				</Portlet.Head>
				<Portlet.Head
					className={classNames(
						'sdms-hidden-minimal-desktop-and-below',
						'sdms-recall-order-more-filters',
						{
							'sdms-hidden': !moreFilters,
						}
					)}>
					<Portlet.HeadLabel>{_moreFilterItems}</Portlet.HeadLabel>
				</Portlet.Head>
				<Portlet.Head className='sdms-hidden-desktop-xl'>
					<Portlet.HeadLabel>
						<Button
							label='info'
							icon='Filter'
							onClick={() => setFilterVisible(!filterVisible)}
							text='Filter'
							size='sm'
							className='sdms-mr-15'
						/>
						{(orderSearchText ||
							(selectedTable && selectedTable.id) ||
							customerSearchText ||
							(selectedServer && selectedServer.id) ||
							(selectedStatus && selectedStatus.id) ||
							range.startDate ||
							range.endDate) && (
							<>
								<Portlet.Separator />
								<div className='sdms-portlet__head-desc'>
									{orderSearchText && (
										<Badge
											design='info'
											isInline
											isUnified
											onClick={() => setOrderSearchText('')}>
											<b>Order:</b>
											{orderSearchText}
										</Badge>
									)}
									{selectedTable && selectedTable.id && (
										<Badge
											design='info'
											isInline
											isUnified
											onClick={() => setSelectedTable({})}>
											<b>Table:</b>
											{selectedTable.name}
										</Badge>
									)}
									{customerSearchText && (
										<Badge
											design='info'
											isInline
											isUnified
											onClick={() => setCustomerSearchText('')}>
											<b>Customer:</b>
											{customerSearchText}
										</Badge>
									)}
									{selectedServer && selectedServer.id && (
										<Badge
											design='info'
											isInline
											isUnified
											onClick={() => setSelectedServer({})}>
											<b>Server:</b>
											{selectedServer.displayName}
										</Badge>
									)}
									{selectedStatus && selectedStatus.id && (
										<Badge
											design='info'
											isInline
											isUnified
											onClick={() => setSelectedStatus({})}>
											<b>Status:</b>
											{selectedStatus.value}
										</Badge>
									)}
									{range.startDate && (
										<Badge
											design='info'
											isInline
											isUnified
											onClick={() => {
												setRange({ startDate: null, endDate: null });
											}}>
											<b>Date:</b>
											{`${dateFormatter(
												range.startDate.getTime(),
												false
											)} - ${dateFormatter(range.endDate.getTime(), false)}`}
										</Badge>
									)}
								</div>
							</>
						)}
					</Portlet.HeadLabel>
				</Portlet.Head>
				{filterVisible && (
					<>
						<Portlet.Head className='sdms-portlet__head--noborder'>
							<Portlet.HeadLabel>{_leftFilterItems[0]}</Portlet.HeadLabel>
							<Portlet.HeadLabel>{_leftFilterItems[1]}</Portlet.HeadLabel>
						</Portlet.Head>
						<Portlet.Head className='sdms-portlet__head--noborder'>
							<Portlet.HeadLabel>{_leftFilterItems[2]}</Portlet.HeadLabel>
						</Portlet.Head>
						<Portlet.Head className='sdms-portlet__head--noborder'>
							<Portlet.HeadLabel>{_moreFilterItems[0]}</Portlet.HeadLabel>
							<Portlet.HeadLabel>{_moreFilterItems[1]}</Portlet.HeadLabel>
						</Portlet.Head>
						<Portlet.Head>
							<Portlet.HeadLabel>{_rightFilterItems[0]}</Portlet.HeadLabel>
							<Portlet.HeadLabel>{_rightFilterItems[1]}</Portlet.HeadLabel>
						</Portlet.Head>
					</>
				)}
				<SelectableList
					withOutPortlet
					isLoading={isOrderLoading}
					data={orders}
					selectedItem={selectedOrder}
					onClick={order => onClick(order, getRelatedOrders(orders, order, true))}
					withCheckbox={false}
					withActions={false}
					minWidth={900}>
					<SelectableList.Col
						width={75}
						label='Order #'
						cellComponent={<OrderNameCell />}
						cellStatus={<OrderStatus />}
					/>
					<SelectableList.Col
						width={90}
						label='Table'
						cellComponent={<OrderTableNameCell />}
					/>
					<SelectableList.Col label='Customer' cellComponent={<CustomerNameCell />} />
					{isModuleEnabled(userContext, modules.MARINA) && (
						<SelectableList.Col label='Vessel' cellComponent={<VesselCell />} />
					)}
					<SelectableList.Col label='Server' cellComponent={<ServerNameCell />} />
					<SelectableList.Col width={90} label='Total' cellComponent={<TotalCell />} />
					<SelectableList.Col width={90} label='Paid' cellComponent={<PaidCell />} />
					<SelectableList.Col width={90} label='Tip' cellComponent={<TipCell />} />
					<SelectableList.Col width={155} label='Date' cellComponent={<DateCell />} />
					<SelectableList.Col
						width={100}
						label='Payment'
						align='center'
						cellComponent={<PaymentMethodCell />}
					/>
					<SelectableList.Col
						width={90}
						align='right'
						label='Status'
						cellComponent={<StatusCell />}
					/>
				</SelectableList>
				<Portlet.Foot tall='sm'>
					<Loading isLoading={isOrderLoading} type='pagination'>
						<TablePagination
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							totalPage={totalPage}
							itemsPerPage={itemsPerPage}
							setItemsPerPage={setItemsPerPage}
							totalItems={totalItems}
						/>
					</Loading>
				</Portlet.Foot>
			</Portlet>
		</div>
	);
};
RecallOrders.propTypes = {
	users: PropTypes.arrayOf(PropTypes.object),
	orderStatuses: PropTypes.arrayOf(PropTypes.object),
	tables: PropTypes.arrayOf(PropTypes.object),
	canViewOthersOrders: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	user: PropTypes.object,
	onClick: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	selectedOrder: PropTypes.object,
	isOrderLoading: PropTypes.bool.isRequired,
	setIsOrderLoading: PropTypes.func.isRequired,
	hasSideCar: PropTypes.bool,
};
RecallOrders.defaultProps = {
	users: [],
	orderStatuses: [],
	tables: [],
	canViewOthersOrders: false,
	outlet: {},
	user: {},
	onClick: () => {},
	selectedOrder: {},
	hasSideCar: false,
};

export default RecallOrders;
