import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';

import FormContainer from '../../reusables/template/FormContainer';
import StatementChargeForm from './StatementChargeForm';
import UserContext from '../../../app/contexts/UserContext';

const StatementChargeEdit = ({ match }) => {
	const userContext = useContext(UserContext);
	
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle={pages.crm.statementCharges.text}
			dataName='statementCharges'
			lists={['enumInvoiceStatuses']}
			fields={<StatementChargeForm />}
			isEditable={userContext.hasPermission('edit_statement_charges')}
		/>
	);
};

StatementChargeEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default StatementChargeEdit;
