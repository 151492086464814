import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import usePages from '../../../utils/hooks/usePages';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Loading from '../../reusables/template/Loading';
import HeaderContext from '../../../app/contexts/HeaderContext';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Selects from '../../reusables/field/Selects';
import { modules } from '../../../utils/helpers/apiCall';
import { templateTypes } from '../../../utils/constants/constants';
import Section from '../../reusables/layout/Section';
import Toggle from '../../reusables/field/Toggle';
import Input from '../../reusables/field/Input';

const ModuleForm = ({
	data,
	setIsValid,
	isSubmitted,
	isLoading,
	onFormChange,
	submitButtonAttr,
	submit,
	unitMaps,
	templates,
	grids,
	paymentTerms,
	contracts,
}) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const [bookingMap, bookingMapOnChange] = useField(
		data,
		'defaultBookingMap',
		onFormChange,
		[],
		null
	);

	const [marinaMap, marinaMapOnChange] = useField(
		data,
		'defaultMarinaMap',
		onFormChange,
		[],
		null
	);

	const [campgroundMap, campgroundMapOnChange] = useField(
		data,
		'defaultCampgroundMap',
		onFormChange,
		[],
		null
	);

	const [bookingProductGrid, bookingProductGridOnChange] = useField(
		data,
		'bookingProductGrid',
		onFormChange,
		[],
		null
	);

	const [marinaProductGrid, marinaProductGridOnChange] = useField(
		data,
		'marinaProductGrid',
		onFormChange,
		[],
		null
	);

	const [campgroundProductGrid, campgroundProductGridOnChange] = useField(
		data,
		'campgroundProductGrid',
		onFormChange,
		[],
		null
	);

	const [paymentTermPos, paymentTermPosOnChange] = useField(
		data,
		'paymentTermPos',
		onFormChange,
		[],
		null
	);

	const [paymentTermBooking, paymentTermBookingOnChange] = useField(
		data,
		'paymentTermBooking',
		onFormChange,
		[],
		null
	);

	const [paymentTermMarina, paymentTermMarinaOnChange] = useField(
		data,
		'paymentTermMarina',
		onFormChange,
		[],
		null
	);

	const [paymentTermCampground, paymentTermCampgroundOnChange] = useField(
		data,
		'paymentTermCampground',
		onFormChange,
		[],
		null
	);

	const [defaultRentRollContract, defaultRentRollContractOnChange] = useField(
		data,
		'defaultRentRollContract',
		onFormChange,
		[],
		null
	);

	const [defaultRentRollContractCampground, defaultRentRollContractCampgroundOnChange] = useField(
		data,
		'defaultRentRollContractCampground',
		onFormChange,
		[],
		null
	);

	const [
		bookingConfirmationEmailTemplate,
		bookingConfirmationEmailTemplateOnChange,
		bookingConfirmationEmailTemplateValRes,
		bookingConfirmationEmailTemplateShowVal,
		setBookingConfirmationEmailTemplateShowVal,
	] = useField(data, 'reservationConfirmationEmailTemplate', onFormChange, [required], null);

	const [
		marinaConfirmationEmailTemplate,
		marinaConfirmationEmailTemplateOnChange,
		marinaConfirmationEmailTemplateValRes,
		marinaConfirmationEmailTemplateShowVal,
		setMarinaConfirmationEmailTemplateShowVal,
	] = useField(
		data,
		'marinaReservationConfirmationEmailTemplate',
		onFormChange,
		[required],
		null
	);

	const [
		campgroundConfirmationEmailTemplate,
		campgroundConfirmationEmailTemplateOnChange,
		campgroundConfirmationEmailTemplateValRes,
		campgroundConfirmationEmailTemplateShowVal,
		setCampgroundConfirmationEmailTemplateShowVal,
	] = useField(
		data,
		'campgroundReservationConfirmationEmailTemplate',
		onFormChange,
		[required],
		null
	);

	const [bookingUseLegacyReservationForm, bookingUseLegacyReservationFormOnChange] = useField(
		data,
		'bookingUseLegacyReservationForm',
		onFormChange,
		[],
		false
	);

	const [marinaUseLegacyReservationForm, marinaUseLegacyReservationFormOnChange] = useField(
		data,
		'marinaUseLegacyReservationForm',
		onFormChange,
		[],
		false
	);

	const [
		campgroundUseLegacyReservationForm,
		campgroundUseLegacyReservationFormOnChange,
	] = useField(data, 'campgroundUseLegacyReservationForm', onFormChange, [], false);

	const [bookingCollapseUnitGroups, bookingCollapseUnitGroupsOnChange] = useField(
		data,
		'bookingCollapseUnitGroups',
		onFormChange,
		[],
		false
	);

	const [marinaCollapseUnitGroups, marinaCollapseUnitGroupsOnChange] = useField(
		data,
		'marinaCollapseUnitGroups',
		onFormChange,
		[],
		false
	);

	const [campgroundCollapseUnitGroups, campgroundCollapseUnitGroupsOnChange] = useField(
		data,
		'campgroundCollapseUnitGroups',
		onFormChange,
		[],
		false
	);

	const [bookingCaption, bookingCaptionOnChange] = useField(data, 'bookingCaption', onFormChange);

	const [bookingIcon, bookingIconOnChange] = useField(data, 'bookingIcon', onFormChange);

	const [marinaCaption, marinaCaptionOnChange] = useField(data, 'marinaCaption', onFormChange);

	const [marinaIcon, marinaIconOnChange] = useField(data, 'marinaIcon', onFormChange);

	const [campgroundCaption, campgroundCaptionOnChange] = useField(
		data,
		'campgroundCaption',
		onFormChange
	);

	const [campgroundIcon, campgroundIconOnChange] = useField(data, 'campgroundIcon', onFormChange);

	const [showVesselOnAvailability, showVesselOnAvailabilityOnChange] = useField(
		data,
		'showVesselOnAvailability',
		onFormChange,
		[],
		false
	);

	const [showVehicleOnAvailability, showVehicleOnAvailabilityOnChange] = useField(
		data,
		'showVehicleOnAvailability',
		onFormChange,
		[],
		false
	);

	const [marinaDoubleBerth, marinaDoubleBerthOnChange] = useField(
		data,
		'marinaDoubleBerth',
		onFormChange,
		[],
		false
	);

	const [campgroundDoubleBerth, campgroundDoubleBerthOnChange] = useField(
		data,
		'campgroundDoubleBerth',
		onFormChange,
		[],
		false
	);

	useEffect(() => {
		if (isSubmitted) {
			setMarinaConfirmationEmailTemplateShowVal();
			setCampgroundConfirmationEmailTemplateShowVal();
			setBookingConfirmationEmailTemplateShowVal();
		}
	}, [
		isSubmitted,
		setMarinaConfirmationEmailTemplateShowVal,
		setCampgroundConfirmationEmailTemplateShowVal,
		setBookingConfirmationEmailTemplateShowVal,
	]);

	useEffect(() => {
		setIsValid(
			marinaConfirmationEmailTemplateValRes.isValid &&
				campgroundConfirmationEmailTemplateValRes.isValid &&
				bookingConfirmationEmailTemplateValRes.isValid
		);
	}, [
		setIsValid,
		marinaConfirmationEmailTemplateValRes.isValid,
		campgroundConfirmationEmailTemplateValRes.isValid,
		bookingConfirmationEmailTemplateValRes.isValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.companySettings.default.text,
				path: pages.companySettings.dashboard.path,
			},
			{
				title: `${pages.companySettings.module.text}`,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(`${pages.companySettings.module.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form' onSubmit={e => e.preventDefault()}>
					<Section title='Point of Sale'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='paymentTermPos'
										label='POS Payment Term'
										colMd={6}
										colLg={4}>
										<Selects
											options={paymentTerms}
											placeholder='Pos Payment Term'
											value={paymentTermPos}
											onChange={paymentTermPosOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Booking'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='paymentTermBooking'
										label='Booking Payment Term'
										colMd={6}
										colLg={4}>
										<Selects
											options={paymentTerms}
											placeholder='Booking Payment Term'
											value={paymentTermBooking}
											onChange={paymentTermBookingOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='bookingReservationConfirmationEmailTemplate'
										label='Confirmation Email Template'
										colLg={4}
										colMd={6}
										showValidation={bookingConfirmationEmailTemplateShowVal}
										valRes={bookingConfirmationEmailTemplateValRes}>
										<Selects
											options={templates.filter(
												t =>
													t.type.value ===
													templateTypes.RESERVATION_CONFIRMATION
											)}
											placeholder='Select template (Required)'
											value={bookingConfirmationEmailTemplate}
											onChange={bookingConfirmationEmailTemplateOnChange}
											onBlur={setBookingConfirmationEmailTemplateShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='bookingMap'
										label='Default Map'
										colLg={4}
										colMd={6}>
										<Selects
											options={unitMaps.filter(
												um => um.module.value === modules.BOOKINGS
											)}
											placeholder='Select Map'
											value={bookingMap}
											onChange={bookingMapOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='bookingProductGrid'
										label='Booking Product Grid'
										colLg={4}
										colMd={6}>
										<Selects
											options={grids}
											placeholder='Select a Grid'
											value={bookingProductGrid}
											onChange={bookingProductGridOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='bookingUseLegacyReservationForm'
										label='Use Legacy Reservation Form'
										id='bookingUseLegacyReservationForm'
										colLg={4}
										colMd={6}>
										<Toggle
											spaceLess
											value={bookingUseLegacyReservationForm}
											onChange={bookingUseLegacyReservationFormOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='bookingCollapseUnitGroups'
										label='Collapse Unit Groups'
										id='bookingCollapseUnitGroups'
										colLg={4}
										colMd={6}>
										<Toggle
											spaceLess
											value={bookingCollapseUnitGroups}
											onChange={bookingCollapseUnitGroupsOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='bookingCaption'
										label='Caption'
										colMd={6}
										colLg={4}>
										<Input
											type='text'
											placeholder='Caption'
											value={bookingCaption}
											onChange={bookingCaptionOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField name='bookingIcon' label='Icon' colMd={6} colLg={4}>
										<Input
											type='text'
											placeholder='Icon'
											value={bookingIcon}
											onChange={bookingIconOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Marina'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='paymentTermMarina'
										label='Marina Payment Term'
										colMd={6}
										colLg={4}>
										<Selects
											options={paymentTerms}
											placeholder='Marina Payment Term'
											value={paymentTermMarina}
											onChange={paymentTermMarinaOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='marinaReservationConfirmationEmailTemplate'
										label='Confirmation Email Template'
										colLg={4}
										colMd={6}
										showValidation={marinaConfirmationEmailTemplateShowVal}
										valRes={marinaConfirmationEmailTemplateValRes}>
										<Selects
											options={templates.filter(
												t =>
													t.type.value ===
													templateTypes.RESERVATION_CONFIRMATION
											)}
											placeholder='Select template (Required)'
											value={marinaConfirmationEmailTemplate}
											onChange={marinaConfirmationEmailTemplateOnChange}
											onBlur={setMarinaConfirmationEmailTemplateShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='marinaMap'
										label='Default Map'
										colLg={4}
										colMd={6}>
										<Selects
											options={unitMaps.filter(
												um => um.module.value === modules.MARINA
											)}
											placeholder='Select Map'
											value={marinaMap}
											onChange={marinaMapOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='marinaProductGrid'
										label='Marina Product Grid'
										colLg={4}
										colMd={6}>
										<Selects
											options={grids}
											placeholder='Select a Grid'
											value={marinaProductGrid}
											onChange={marinaProductGridOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='defaultRentRollContract'
										label='Default Rent Roll Contract'
										colMd={6}
										colLg={4}>
										<Selects
											options={contracts}
											placeholder='Select Contract'
											value={defaultRentRollContract}
											onChange={defaultRentRollContractOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='marinaUseLegacyReservationForm'
										label='Use Legacy Reservation Form'
										id='marinaUseLegacyReservationForm'
										colLg={4}
										colMd={6}>
										<Toggle
											spaceLess
											value={marinaUseLegacyReservationForm}
											onChange={marinaUseLegacyReservationFormOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='marinaCollapseUnitGroups'
										label='Collapse Unit Groups'
										id='marinaCollapseUnitGroups'
										colLg={4}
										colMd={6}>
										<Toggle
											spaceLess
											value={marinaCollapseUnitGroups}
											onChange={marinaCollapseUnitGroupsOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='marinaCaption'
										label='Caption'
										colMd={6}
										colLg={4}>
										<Input
											type='text'
											placeholder='Caption'
											value={marinaCaption}
											onChange={marinaCaptionOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField name='marinaIcon' label='Icon' colMd={6} colLg={4}>
										<Input
											type='text'
											placeholder='Icon'
											value={marinaIcon}
											onChange={marinaIconOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='showVesselOnAvailability'
										label='Show Vessel On Availability'
										id='showVesselOnAvailability'
										colLg={4}
										colMd={6}>
										<Toggle
											spaceLess
											value={showVesselOnAvailability}
											onChange={showVesselOnAvailabilityOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='marinaDoubleBerth'
										label='Double Berth'
										id='marinaDoubleBerth'
										colLg={4}
										colMd={6}>
										<Toggle
											spaceLess
											value={marinaDoubleBerth}
											onChange={marinaDoubleBerthOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Campground'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='paymentTermCampground'
										label='Campground Payment Term'
										colMd={6}
										colLg={4}>
										<Selects
											options={paymentTerms}
											placeholder='Campground Payment Term'
											value={paymentTermCampground}
											onChange={paymentTermCampgroundOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='campgroundReservationConfirmationEmailTemplate'
										label='Confirmation Email Template'
										colLg={4}
										colMd={6}
										showValidation={campgroundConfirmationEmailTemplateShowVal}
										valRes={campgroundConfirmationEmailTemplateValRes}>
										<Selects
											options={templates.filter(
												t =>
													t.type.value ===
													templateTypes.RESERVATION_CONFIRMATION
											)}
											placeholder='Select template (Required)'
											value={campgroundConfirmationEmailTemplate}
											onChange={campgroundConfirmationEmailTemplateOnChange}
											onBlur={setCampgroundConfirmationEmailTemplateShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='campgroundMap'
										label='Default Map'
										colLg={4}
										colMd={6}>
										<Selects
											options={unitMaps.filter(
												um => um.module.value === modules.CAMPGROUND
											)}
											placeholder='Select Map'
											value={campgroundMap}
											onChange={campgroundMapOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='campgroundProductGrid'
										label='Campground Product Grid'
										colLg={4}
										colMd={6}>
										<Selects
											options={grids}
											placeholder='Select a Grid'
											value={campgroundProductGrid}
											onChange={campgroundProductGridOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='defaultRentRollContractCampground'
										label='Default Rent Roll Contract'
										colMd={6}
										colLg={4}>
										<Selects
											options={contracts}
											placeholder='Select Contract'
											value={defaultRentRollContractCampground}
											onChange={defaultRentRollContractCampgroundOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='campgroundUseLegacyReservationForm'
										label='Use Legacy Reservation Form'
										id='campgroundUseLegacyReservationForm'
										colLg={4}
										colMd={6}>
										<Toggle
											spaceLess
											value={campgroundUseLegacyReservationForm}
											onChange={campgroundUseLegacyReservationFormOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='campgroundCollapseUnitGroups'
										label='Collapse Unit Groups'
										id='campgroundCollapseUnitGroups'
										colLg={4}
										colMd={6}>
										<Toggle
											spaceLess
											value={campgroundCollapseUnitGroups}
											onChange={campgroundCollapseUnitGroupsOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='campgroundCaption'
										label='Caption'
										colMd={6}
										colLg={4}>
										<Input
											type='text'
											placeholder='Caption'
											value={campgroundCaption}
											onChange={campgroundCaptionOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='campgroundIcon'
										label='Icon'
										colMd={6}
										colLg={4}>
										<Input
											type='text'
											placeholder='Icon'
											value={campgroundIcon}
											onChange={campgroundIconOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='showVehicleOnAvailability'
										label='Show Vehicle On Availability'
										id='showVehicleOnAvailability'
										colLg={4}
										colMd={6}>
										<Toggle
											spaceLess
											value={showVehicleOnAvailability}
											onChange={showVehicleOnAvailabilityOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='campgroundDoubleBerth'
										label='Double Berth'
										id='campgroundDoubleBerth'
										colLg={4}
										colMd={6}>
										<Toggle
											spaceLess
											value={campgroundDoubleBerth}
											onChange={campgroundDoubleBerthOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
ModuleForm.propTypes = {
	data: PropTypes.shape({
		marinaMap: PropTypes.object,
		campgroundMap: PropTypes.object,
		bookingMap: PropTypes.object,
		marinaReservationConfirmationEmailTemplate: PropTypes.object,
		campgroundReservationConfirmationEmailTemplate: PropTypes.object,
		bookingReservationConfirmationEmailTemplate: PropTypes.object,
		bookingUseLegacyReservationForm: PropTypes.bool,
		marinaUseLegacyReservationForm: PropTypes.bool,
		campgroundUseLegacyReservationForm: PropTypes.bool,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
	unitMaps: PropTypes.arrayOf(PropTypes.object),
	templates: PropTypes.arrayOf(PropTypes.object),
	grids: PropTypes.arrayOf(PropTypes.object),
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	contracts: PropTypes.arrayOf(PropTypes.object),
};
ModuleForm.defaultProps = {
	data: {
		marinaMap: null,
		campgroundMap: null,
		bookingMap: null,
		marinaReservationConfirmationEmailTemplate: null,
		campgroundReservationConfirmationEmailTemplate: null,
		bookingReservationConfirmationEmailTemplate: null,
		bookingUseLegacyReservationForm: false,
		marinaUseLegacyReservationForm: false,
		campgroundUseLegacyReservationForm: false,
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
	unitMaps: [],
	templates: [],
	grids: [],
	paymentTerms: [],
	contracts: [],
};

export default ModuleForm;
