import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import {
	addErrorNotification,
	addSuccessNotification,
	dateFormatter,
	priceFormatter,
} from '../../../utils/helpers/helper';
import HeaderContext from '../../../app/contexts/HeaderContext';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import Badge from '../../reusables/element/Badge';
import NullBadge from '../../reusables/design/NullBadge';
import pages from '../../pages';
import OpeningCashModal from '../../reusables/modals/OpeningCashModal';
import CountDownModal from '../../reusables/modals/CountDownModal';

import { paymentTypes } from '../../../utils/constants/constants';
import apiCall from '../../../utils/helpers/apiCall';
import DialogBox from '../../reusables/element/DialogBox';
import PrinterModal from '../../reusables/modals/PrinterModal';
import UserContext from '../../../app/contexts/UserContext';
// eslint-disable-next-line import/namespace,import/default,import/no-named-as-default,import/no-named-as-default-member
import WebPrint from '../../reusables/print/WebPrint';
import useModal from '../../../utils/hooks/useModal';

import forms from '../../forms';
import Toggle from '../../reusables/field/Toggle';
import FormField from '../../reusables/template/FormField';
import Loading from '../../reusables/template/Loading';

// eslint-disable-next-line no-unused-vars
const StatusCell = ({ data, onClick, noPermission, isLoading }) => {
	return (
		<Loading isLoading={isLoading}>
			<FormField name='status' inFormDesign={false} className='sdms-m0'>
				<Toggle
					onChange={onClick}
					value={data.currentRegisterBatch !== null}
					color='success'
					outline
					spaceLess
					noPermission={noPermission}
					disabled={data.inactive}
				/>
			</FormField>
		</Loading>
	);
};
StatusCell.propTypes = {
	data: PropTypes.shape({
		currentRegisterBatch: PropTypes.shape({ openingCash: PropTypes.number }),
		inactive: PropTypes.bool,
	}),
	onClick: PropTypes.func,
	noPermission: PropTypes.bool,
	isLoading: PropTypes.bool,
};
StatusCell.defaultProps = {
	data: { currentRegisterBatch: null },
	onClick: () => {},
	noPermission: false,
	isLoading: false,
};

const CashManagementCell = ({ data }) => {
	if (data.paymentTypes.filter(pt => pt.value === paymentTypes.CASH).length > 0) {
		return (
			<>
				<Badge.Yes />
				{data.currentRegisterBatch && (
					<span className='sdms-ml-5'>
						<b>Starting Balance:</b>{' '}
						{priceFormatter(data.currentRegisterBatch.openingCash)}
					</span>
				)}
			</>
		);
	}
	return <Badge.No />;
};
CashManagementCell.propTypes = {
	data: PropTypes.shape({
		currentRegisterBatch: PropTypes.shape({ openingCash: PropTypes.number }),
		paymentTypes: PropTypes.array,
	}),
};
CashManagementCell.defaultProps = {
	data: { currentRegisterBatch: null },
};

const LastBatchCell = ({ data }) => {
	if (data.lastRegisterBatch === null) return <NullBadge />;

	return (
		<>
			{data.currentRegisterBatch ? (
				<>
					<b>Opened:</b> {dateFormatter(data.lastRegisterBatch.startTime)}
				</>
			) : (
				<>
					<b>Closed:</b>{' '}
					{data.lastRegisterBatch.endTime
						? dateFormatter(data.lastRegisterBatch.endTime)
						: ''}
				</>
			)}
		</>
	);
};
LastBatchCell.propTypes = {
	data: PropTypes.shape({
		lastRegisterBatch: PropTypes.shape({
			// registerBatchId: PropTypes.string,
			startTime: PropTypes.string,
			endTime: PropTypes.string,
		}),
		currentRegisterBatch: PropTypes.object,
	}),
};
LastBatchCell.defaultProps = {
	data: { lastRegisterBatch: null, currentRegisterBatch: null },
};

const LastActivityCell = ({ data }) => {
	return <>{data.lastActivity ? dateFormatter(data.lastActivity) : <NullBadge />}</>;
};
LastActivityCell.propTypes = {
	data: PropTypes.shape({
		lastActivity: PropTypes.string,
	}),
};
LastActivityCell.defaultProps = {
	data: { lastActivity: null },
};

const OpenCloseButton = ({ data, onClick, disabled }) => {
	return (
		<Button
			label={data.currentRegisterBatch ? 'danger' : 'success'}
			text={data.currentRegisterBatch ? 'Close' : 'Open'}
			icon={data.currentRegisterBatch ? 'Lock' : 'Unlock'}
			size='sm'
			elevate
			key={data.currentRegisterBatch ? 'close' : 'open'}
			onClick={onClick}
			disabled={disabled}
		/>
	);
};
OpenCloseButton.propTypes = {
	data: PropTypes.shape({
		currentRegisterBatch: PropTypes.object,
	}),
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
};
OpenCloseButton.defaultProps = {
	data: { currentRegisterBatch: null },
	onClick: () => {},
	disabled: true,
};

const ViewButton = ({ data }) => {
	return (
		<Dropdown.Item
			target='_blank'
			to={`/terminal/${data.id}`}
			icon='TV#1'
			type='NavLink'
			isDisabled={!data.currentRegisterBatch}>
			View
			{!data.currentRegisterBatch && (
				<Badge className='sdms-ml-15' design='warning' isInline>
					Closed Register
				</Badge>
			)}
		</Dropdown.Item>
	);
};
ViewButton.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		currentRegisterBatch: PropTypes.object,
	}),
};
ViewButton.defaultProps = {
	data: { id: 0, currentRegisterBatch: null },
};

const modals = {
	OPENING_CASH: 'opening_cash',
	ERROR: 'error',
	PRINTER: 'printer',
	COUNT_DOWN: 'count_down',
};

const RegisterList = ({ history }) => {
	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const selectedRegisters = useRef([]);

	const closeData = useRef([]);

	const updateDataFunc = useRef();

	const waitForLogout = useRef(true);

	const timeoutId = useRef(0);

	const webPrint = useRef({ registerCloseOut: () => {}, printers: [] });

	const [modal, openModal, closeModal] = useModal();

	const [isLoading, setIsLoading] = useState(false);

	const [countTime, setCountTime] = useState(0);

	const [countDownModalContent, setCountDownModalContent] = useState(null);

	const open = openingCashes => {
		if (isLoading || selectedRegisters.current.length === 0) return;

		setIsLoading(true);

		apiCall(
			'POST',
			'registerOpen',
			res => {
				updateDataFunc.current(res.registers);
				setIsLoading(false);

				userContext.updateRegister(
					res.registers.map(r => ({
						id: r.id,
						currentRegisterBatch: r.currentRegisterBatch,
					}))
				);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{
				registers: selectedRegisters.current.map(r => r.id),
				openingCashes,
			}
		);
	};

	const check = () => {
		setCountDownModalContent('Checking...');
		openModal({ open: modals.COUNT_DOWN });

		setIsLoading(true);

		apiCall(
			'POST',
			'registerCloseCheck',
			res => {
				if (res.unfinalizedOrderMessages && res.unfinalizedOrderMessages.length) {
					openModal({
						open: modals.ERROR,
						error: res.unfinalizedOrderMessages.join('\n'),
					});
					setIsLoading(false);
				} else if (res.notClosedServerMessages && res.notClosedServerMessages.length) {
					res.notClosedServerMessages.unshift('These servers have not closed out yet:');
					openModal({
						open: modals.ERROR,
						error: res.notClosedServerMessages.join('\n'),
					});
					setIsLoading(false);
				} else if (waitForLogout.current && res.checkInterval) {
					waitForLogout.current = false;
					setCountTime((res.checkInterval + 5) * 1000);
					setCountDownModalContent(null);
					openModal({ open: modals.COUNT_DOWN });
					timeoutId.current = setTimeout(() => {
						check();
					}, (res.checkInterval + 5) * 1000);
				} else {
					close();
				}
			},
			err => {
				openModal({ open: modals.ERROR, error: err.toString() });
				setIsLoading(false);
			},
			'',
			{
				registers: selectedRegisters.current.map(r => r.id),
			}
		);
	};

	const close = () => {
		setCountDownModalContent('Closing...');
		openModal({ open: modals.COUNT_DOWN });

		apiCall(
			'POST',
			'registerClose',
			res => {
				setIsLoading(false);

				if (res.errors && res.errors.length) {
					openModal({ open: modals.ERROR, error: res.errors.join('\n') });
				} else {
					closeData.current = res.closeData;
					openModal({ open: modals.PRINTER });
					selectedRegisters.current.forEach(r => {
						addSuccessNotification(`${r.name} successfully closed`);
					});
					updateDataFunc.current(res.registers);
					userContext.updateRegister(
						res.registers.map(r => ({
							id: r.id,
							currentRegisterBatch: null,
						}))
					);
				}
			},
			err => {
				openModal({ open: modals.ERROR, error: err.toString() });
				setIsLoading(false);
			},
			'',
			{
				registers: selectedRegisters.current.map(r => r.id),
			}
		);
	};

	const enableLogin = () => {
		setIsLoading(true);

		apiCall(
			'POST',
			'registerEnableLogin',
			() => setIsLoading(false),
			err => {
				setIsLoading(false);
				addErrorNotification(err.toString());
			},
			'',
			{ registers: selectedRegisters.current.map(r => r.id) }
		);
	};

	const print = printer => {
		webPrint.current.registerCloseOut(
			closeData.current.map(r => {
				r.data = selectedRegisters.current.find(_r => _r.id === r.id);
				return r;
			}),
			printer.id
		);
	};

	const saveNotPrintedZOuts = () => {
		const printer = selectedRegisters.current[0].printer || webPrint.current.printers[0];

		const registers = closeData.current.map(cd => {
			const register = selectedRegisters.current.find(r => r.id === cd.id);

			return {
				batch: cd.batch,
				zOut: webPrint.current.getRegisterClosePrint(
					{ ...cd, data: register },
					webPrint.current.queues[printer.id].characterPerLine - 1
				),
			};
		});

		saveZOuts({ registers });
	};

	const saveZOuts = printItem => {
		setIsLoading(true);

		apiCall(
			'POST',
			'registerSaveZOuts',
			() => {
				setIsLoading(false);
				addSuccessNotification('Z-Out(s) saved');
			},
			err => {
				setIsLoading(false);
				addErrorNotification(err.toString());
			},
			'',
			{
				batches: printItem.registers.map(r => {
					return { id: r.batch.id, zOut: r.zOut };
				}),
			}
		);
	};

	const customActions = {
		openClose: (register, updateData) => {
			updateDataFunc.current = updateData;

			selectedRegisters.current = [register];

			waitForLogout.current = true;

			if (register.currentRegisterBatch) {
				check();
				return;
			}

			if (register.paymentTypes.findIndex(pt => pt.value === paymentTypes.CASH) > -1) {
				openModal({ open: modals.OPENING_CASH });
			} else open();
		},
	};

	const customMultiActions = {
		open: {
			func: (registers, updateData) => {
				selectedRegisters.current = registers;

				updateDataFunc.current = updateData;

				if (
					registers.filter(
						r =>
							r.paymentTypes.findIndex(pt => pt.value === paymentTypes.CASH) > -1 &&
							!r.inactive
					).length > 0
				) {
					openModal({ open: modals.OPENING_CASH });
				} else open();
			},
			component: (
				<Button
					label='success'
					text='Open'
					icon='Unlock'
					key='open'
					noPermission={!userContext.hasPermission('open_close_registers')}
				/>
			),
		},
		close: {
			func: (registers, updateData) => {
				updateDataFunc.current = updateData;

				selectedRegisters.current = registers.filter(r => r.currentRegisterBatch !== null);

				waitForLogout.current = true;

				check();
			},
			component: (
				<Button
					label='danger'
					text='Close'
					icon='Lock'
					key='close'
					noPermission={!userContext.hasPermission('open_close_registers')}
				/>
			),
		},
	};

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.pos.default.text, path: pages.pos.dashboard.path },
			{ title: pages.pos.registers.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.pos.registers.text);

		// get outlet's printers for printer select modal and WebPrint.
		webPrint.current = new WebPrint({
			user: userContext.data.user,
			outlet: userContext.data.selectedOutlet,
			onStart: () => addSuccessNotification('Printing...'),
			onFinish: saveZOuts,
			onFail: (printItem, err) => {
				addErrorNotification(err.toString());
				saveZOuts(printItem);
			},
		});
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ListContainer
				route='registers'
				title='Registers'
				history={history}
				customActions={customActions}
				customMultiActions={customMultiActions}
				checkEditable={registers => {
					const actions = [];
					registers
						.filter(r => !r.inactive)
						.forEach(register => {
							if (register.currentRegisterBatch) {
								if (!actions.includes('close')) actions.push('close');
							} else if (!actions.includes('open')) actions.push('open');
						});
					actions.push('delete');

					return actions;
				}}
				forms={forms.pos.registers}>
				<List withCheckBox fluid='fluid' isLoadingOverride={isLoading}>
					<List.Col
						width='100%'
						label='Name'
						cellData='name'
						isLinkColumn={userContext.hasPermission('edit_registers')}
						sortable='name'
					/>
					<List.Col label='Status' width={90} align='center'>
						<StatusCell
							key='openClose'
							dataType='data'
							noPermission={!userContext.hasPermission('open_close_registers')}
							isLoading={isLoading}
						/>
					</List.Col>
					<List.Col
						width='100%'
						label='Open & Close Date'
						cellComponent={<LastBatchCell />}
					/>
					<List.Col
						width='100%'
						label='Cash Management'
						cellComponent={<CashManagementCell />}
					/>
					<List.Col
						width={155}
						label='Last Activity'
						cellComponent={<LastActivityCell />}
						sortable='currentRegisterBatch.startTime'
					/>
					<List.Col width={250} align='right' onlyHover>
						{/* <OpenCloseButton */}
						{/*	className='sdms-margin-r-15' */}
						{/*	key='openClose' */}
						{/*	dataType='data' */}
						{/*	disabled={!userContext.hasPermission('open_close_registers')} */}
						{/* /> */}
						<Button
							className={classNames('sdms-margin-r-15', { 'is-loading': isLoading })}
							design='link'
							text='Edit'
							icon='Edit'
							size='sm'
							elevate
							key='edit'
							noPermission={!userContext.hasPermission('edit_registers')}>
							{isLoading ? <span>Loading</span> : 'Edit'}
						</Button>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}
							disabled={isLoading}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<ViewButton />
							<Dropdown.Item
								itemsColor='danger'
								icon='Trash'
								key='delete'
								noPermission={!userContext.hasPermission('delete_registers')}>
								Delete
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			<OpeningCashModal
				isOpen={modal.open === modals.OPENING_CASH}
				onClose={closeModal}
				onSubmit={openingCashes => {
					open(openingCashes);
					closeModal();
				}}
				registers={selectedRegisters.current.filter(r => !r.inactive)}
			/>
			<DialogBox
				open={modal.open === modals.ERROR}
				title='Cannot close register'
				content={modal.error}
				type='warning'
				onClose={closeModal}>
				<Button
					label='dark'
					className='sdms-font-transform-c'
					text='Ok'
					onClick={closeModal}
				/>
			</DialogBox>
			<PrinterModal
				isOpen={modal.open === modals.PRINTER}
				onSubmit={printer => {
					print(printer);
					closeModal();
				}}
				onClose={() => {
					saveNotPrintedZOuts();

					enableLogin();

					closeModal();
				}}
				printers={webPrint.current.printers}
			/>
			<CountDownModal
				isOpen={modal.open === modals.COUNT_DOWN}
				counter={countTime}
				onClose={closeModal}
				content={countDownModalContent}
			/>
		</>
	);
};
RegisterList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default RegisterList;
