import React, { useEffect, useContext, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import {
	required,
	email as emailValidation,
	password as passwordValidation,
	uniqueFnc,
	phoneNumber,
	maxLength,
	phoneNumberRequired,
} from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import UserContext from '../../../app/contexts/UserContext';
import { paymentTypes as _paymentTypes, paymentTypes } from '../../../utils/constants/constants';
import { addCustomNotification, phoneNumberParser } from '../../../utils/helpers/helper';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Loading from '../../reusables/template/Loading';
import Input from '../../reusables/field/Input';
import Section from '../../reusables/layout/Section';
import Selects from '../../reusables/field/Selects';
import Toggle from '../../reusables/field/Toggle';
import Wizard from '../../reusables/template/Wizard';
import Button from '../../reusables/element/Button';
import Badge from '../../reusables/element/Badge';
import CustomerPaymentMethodList from './CustomerPaymentMethodList';
import Textarea from '../../reusables/field/Textarea';
import { getPaymentProcessor } from '../../../utils/hooks/usePaymentGateway';

const CustomerForm = ({
	data,
	isValid,
	setIsValid,
	submit,
	isSubmitted,
	countries,
	states,
	paymentMethods,
	paymentTerms,
	isLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
	isSubmitting,
	taxCodes,
	taxRates,
	customerTypes,
	isEditable,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	// New Password input & Button
	const [passwordInputShow, setPasswordInputShow] = useState(false);

	const passwordRef = useRef();

	const passwordRequiredValidation = (value, setValRes) =>
		passwordInputShow ? required(value, setValRes) : true;

	const [
		displayName,
		displayNameOnChange,
		displayNameValRes,
		displayNameShowVal,
		setDisplayNameShowVal,
	] = useField(
		data,
		'displayName',
		onFormChange,
		[required, maxLength(), uniqueFnc('customers', 'displayName', data.id)],
		'',
		null,
		setTitle
	);

	const [email, emailOnChange, emailValRes, emailShowVal, setEmailShowVal] = useField(
		data,
		'email',
		onFormChange,
		[required, maxLength(), emailValidation, uniqueFnc('customers', 'email', data.id)]
	);

	const [ccEmails, ccEmailsOnChange] = useField(data, 'ccEmails', onFormChange, [], null);

	const [
		password,
		passwordOnChange,
		passwordValRes,
		passwordShowVal,
		setPasswordShowVal,
		validatePassword,
	] = useField(data, 'password', onFormChange, [
		maxLength(),
		passwordRequiredValidation,
		passwordValidation,
	]);

	const [
		firstName,
		firstNameOnChange,
		firstNameValRes,
		firstNameShowVal,
		setFirstNameShowVal,
	] = useField(data, 'firstName', onFormChange, [maxLength()]);

	const [
		lastName,
		lastNameOnChange,
		lastNameValRes,
		lastNameShowVal,
		setLastNameShowVal,
	] = useField(data, 'lastName', onFormChange, [maxLength()]);

	const [
		customerCompany,
		customerCompanyOnChange,
		customerCompanyValRes,
		customerCompanyShowVal,
		setCustomerCompanyShowVal,
	] = useField(data, 'customerCompany', onFormChange, [maxLength()]);

	const [phone, phoneOnChange, phoneValRes, phoneShowVal, setPhoneShowVal] = useField(
		data,
		'phone',
		onFormChange,
		[phoneNumberRequired(userContext?.data?.selectedOutlet), phoneNumber, maxLength()],
		'',
		phoneNumberParser
	);

	const [
		altPhone,
		altPhoneOnChange,
		altPhoneValRes,
		altPhoneShowVal,
		setAltPhoneShowVal,
	] = useField(data, 'altPhone', onFormChange, [phoneNumber, maxLength()], '', phoneNumberParser);

	const [
		addressLineOne,
		addressLineOneOnChange,
		addressLineOneValRes,
		addressLineOneShowVal,
		setAddressLineOneShowVal,
	] = useField(data, 'addressLineOne', onFormChange, [maxLength()]);

	const [
		addressLineTwo,
		addressLineTwoOnChange,
		addressLineTwoValRes,
		addressLineTwoShowVal,
		setAddressLineTwoShowVal,
	] = useField(data, 'addressLineTwo', onFormChange, [maxLength()]);

	const [city, cityOnChange, cityValRes, cityShowVal, setCityShowVal] = useField(
		data,
		'city',
		onFormChange,
		[maxLength()]
	);

	const [state, stateOnChange] = useField(data, 'state', onFormChange, [], null);

	const [zip, zipOnChange, zipValRes, zipShowVal, setZipShowVal] = useField(
		data,
		'zip',
		onFormChange,
		[maxLength()]
	);

	const [country, countryOnChange] = useField(
		data,
		'country',
		onFormChange,
		[],
		userContext.data.selectedOutlet.country
	);

	const [houseAccount, houseAccountOnChange] = useField(
		data,
		'houseAccount',
		onFormChange,
		[],
		true
	);

	const [canSavePaymentMethod, canSavePaymentMethodOnChange] = useField(
		data,
		'canSavePaymentMethod',
		onFormChange,
		[],
		true
	);

	const [excludeBalanceCheck, excludeBalanceCheckOnChange] = useField(
		data,
		'excludeBalanceCheck',
		onFormChange,
		[],
		false
	);

	const [paymentTerm, paymentTermOnChange] = useField(
		data,
		'paymentTerm',
		onFormChange,
		[],
		null
	);

	const [paymentTermPos, paymentTermPosOnChange] = useField(
		data,
		'paymentTermPos',
		onFormChange,
		[],
		null
	);

	const [paymentTermBooking, paymentTermBookingOnChange] = useField(
		data,
		'paymentTermBooking',
		onFormChange,
		[],
		null
	);

	const [paymentTermMarina, paymentTermMarinaOnChange] = useField(
		data,
		'paymentTermMarina',
		onFormChange,
		[],
		null
	);

	const [paymentTermCampground, paymentTermCampgroundOnChange] = useField(
		data,
		'paymentTermCampground',
		onFormChange,
		[],
		null
	);

	const [isActive, isActiveOnChange] = useField(data, 'isActive', onFormChange, [], true);

	const [customerPaymentMethods, customerPaymentMethodsOnChange] = useField(
		data,
		'paymentMethods',
		onFormChange,
		[],
		[]
	);

	const [taxCode, taxCodeOnChange] = useField(
		data,
		'taxCode',
		onFormChange,
		[],
		userContext.data.user.company.settings
			? userContext.data.user.company.settings.defaultTaxCode
			: null
	);

	const [defaultTaxRate, defaultTaxRateOnChange] = useField(
		data,
		'defaultTaxRate',
		onFormChange,
		[],
		userContext.data.user.company.settings
			? userContext.data.user.company.settings.defaultTaxRate
			: null
	);

	const [doNotEmail, doNotEmailOnChange] = useField(data, 'doNotEmail', onFormChange, [], false);

	const [internalNote, internalNoteOnChange] = useField(data, 'internalNote', onFormChange, []);

	const [type, typeOnChange] = useField(data, 'type', onFormChange, [], null);

	const headerContext = useContext(HeaderContext);

	const checkProcessor = useMemo(
		() => getPaymentProcessor(userContext.data.selectedOutlet, _paymentTypes.CHECK),
		[userContext.data.selectedOutlet]
	);

	// update states and reset selected state.
	const changeCountryState = ({ target }) => {
		stateOnChange({
			target: {
				name: 'state',
				value: {},
			},
		});

		countryOnChange({ target });
	};

	const onPaymentMethodAdd = paymentMethod =>
		customerPaymentMethodsOnChange({
			target: {
				value: update(
					customerPaymentMethods.map(cpm => {
						if (paymentMethod.isDefault && cpm.outlet.id === paymentMethod.outlet.id)
							cpm.isDefault = false;
						return cpm;
					}),
					{ $push: [paymentMethod] }
				),
			},
		});

	const onPaymentMethodDelete = paymentMethodId => {
		const paymentMethodIndex = customerPaymentMethods.findIndex(
			cpm => cpm.id === paymentMethodId
		);

		if (paymentMethodIndex > -1) {
			customerPaymentMethodsOnChange({
				target: {
					value: update(customerPaymentMethods, {
						$splice: [[paymentMethodIndex, 1]],
					}),
				},
			});

			addCustomNotification('', 'Please save customer to apply changes.', '', 'info');
		}
	};

	const onPaymentMethodDefaultSelect = () =>
		data.id
			? paymentMethod => {
					customerPaymentMethodsOnChange({
						target: {
							value: customerPaymentMethods.map(cpm => {
								if (paymentMethod && cpm.outlet.id === paymentMethod.outlet.id)
									cpm.isDefault = cpm.id === paymentMethod.id;

								if (
									!paymentMethod &&
									cpm.outlet.id === userContext.data.selectedOutlet.id
								)
									cpm.isDefault = false;

								return cpm;
							}),
						},
					});

					addCustomNotification('', 'Please save customer to apply changes.', '', 'info');
			  }
			: null;

	useEffect(() => {
		if (isSubmitted) {
			setDisplayNameShowVal();
			if (passwordInputShow) setPasswordShowVal();
			setEmailShowVal();
			setPhoneShowVal();
			setAltPhoneShowVal();
			setFirstNameShowVal();
			setLastNameShowVal();
			setCustomerCompanyShowVal();
			setAddressLineOneShowVal();
			setAddressLineTwoShowVal();
			setCityShowVal();
			setZipShowVal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isSubmitted,
		setDisplayNameShowVal,
		setPasswordShowVal,
		setEmailShowVal,
		setPhoneShowVal,
		setAltPhoneShowVal,
		setFirstNameShowVal,
		setLastNameShowVal,
		setCustomerCompanyShowVal,
		setAddressLineOneShowVal,
		setAddressLineTwoShowVal,
		setCityShowVal,
		setZipShowVal,
	]);

	useEffect(() => {
		setIsValid(
			displayNameValRes.isValid &&
				passwordValRes.isValid &&
				emailValRes.isValid &&
				phoneValRes.isValid &&
				altPhoneValRes.isValid &&
				firstNameValRes.isValid &&
				lastNameValRes.isValid &&
				customerCompanyValRes.isValid &&
				addressLineOneValRes.isValid &&
				addressLineTwoValRes.isValid &&
				cityValRes.isValid &&
				zipValRes.isValid
		);
	}, [
		displayNameValRes.isValid,
		passwordValRes.isValid,
		emailValRes.isValid,
		phoneValRes.isValid,
		altPhoneValRes.isValid,
		firstNameValRes.isValid,
		customerCompanyValRes.isValid,
		lastNameValRes.isValid,
		addressLineOneValRes.isValid,
		addressLineTwoValRes.isValid,
		cityValRes.isValid,
		zipValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.crm.default.text, path: pages.crm.dashboard.path },
			{ title: pages.crm.customers.text, path: pages.crm.customers.path },
			{
				title: displayName || `New ${pages.crm.customers.text}`,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(displayName || `New ${pages.crm.customers.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [displayName]);

	useEffect(() => {
		if (passwordInputShow && data.id) {
			passwordRef.current.focus();
		}
		validatePassword(password);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [passwordInputShow]);

	useEffect(() => {
		if (!isSubmitting) setPasswordInputShow(false);
	}, [isSubmitting]);

	useEffect(() => {
		if (!isLoading) setPasswordInputShow(data.id === 0);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	return (
		<Wizard
			onSubmit={submit}
			submitButtonAttr={submitButtonAttr}
			isLoading={isLoading}
			submitDisabled={!isEditable}>
			<Wizard.Item
				title={pages.crm.customers.general.text}
				icon={pages.crm.customers.general.icon}
				description='General Information'
				isValid={isSubmitted ? isValid : true}>
				<Section>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='customerId'
									label='Customer ID'
									id={data.id}
									colLg={6}>
									<Input
										type='text'
										placeholder='Auto Generate'
										value={data.customerId}
										onChange={() => {}}
										disabled
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='displayName'
									label='Display Name'
									description='Display Name to be used everywhere in the system'
									id={data.id}
									valRes={displayNameValRes}
									showValidation={displayNameShowVal}
									loadingContainer
									colLg={6}>
									<Input
										type='text'
										placeholder='DisplayName (Required)'
										value={displayName}
										onChange={displayNameOnChange}
										onBlur={setDisplayNameShowVal}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='email'
									label='Email'
									id={data.id}
									valRes={emailValRes}
									showValidation={emailShowVal}
									loadingContainer
									colLg={6}>
									<Input
										type='text'
										placeholder='Email (Required)'
										autoComplete={false}
										value={email}
										onChange={emailOnChange}
										onBlur={setEmailShowVal}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='password'
									label={
										passwordInputShow && data.id ? (
											<>
												Password
												<Badge
													className='sdms-cursor--pointer sdms-ml-10'
													design='info'
													isInline
													isElevate
													onMouseDown={() => {
														setPasswordInputShow(false);
														passwordOnChange({
															target: { value: '' },
														});
														setPasswordShowVal(false);
														if (password === '') validatePassword('');
													}}>
													Use Current Password
												</Badge>
											</>
										) : (
											'Password'
										)
									}
									labelClassName='d-flex'
									id={data.id}
									valRes={passwordValRes}
									showValidation={passwordShowVal}
									colLg={6}>
									{passwordInputShow ? (
										<Input
											ref={passwordRef}
											type='password'
											placeholder='Password (Required)'
											autoComplete={false}
											value={password}
											onChange={passwordOnChange}
											onBlur={setPasswordShowVal}
											togglePassword
											disabled={!isEditable}
										/>
									) : (
										<Button
											label='primary'
											text='Reset Password'
											block
											onClick={() => {
												setPasswordInputShow(true);
											}}
											disabled={!isEditable}
										/>
									)}
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='firstName'
									label='First Name'
									id={data.id}
									colLg={6}
									valRes={firstNameValRes}
									showValidation={firstNameShowVal}>
									<Input
										type='text'
										placeholder='First Name'
										autoComplete={false}
										value={firstName}
										onChange={firstNameOnChange}
										onBlur={setFirstNameShowVal}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='lastName'
									label='Last Name'
									id={data.id}
									colLg={6}
									valRes={lastNameValRes}
									showValidation={lastNameShowVal}>
									<Input
										type='text'
										placeholder='Last Name'
										value={lastName}
										onChange={lastNameOnChange}
										onBlur={setLastNameShowVal}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='customerCompany'
									label='Company'
									id={data.id}
									colLg={6}
									valRes={customerCompanyValRes}
									showValidation={customerCompanyShowVal}>
									<Input
										type='text'
										placeholder='Company'
										value={customerCompany}
										onChange={customerCompanyOnChange}
										onBlur={setCustomerCompanyShowVal}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='phone'
									label='Phone'
									id={data.id}
									valRes={phoneValRes}
									showValidation={phoneShowVal}
									colLg={6}>
									<Input
										type='text'
										placeholder='(555) 555-5555'
										value={phone}
										onChange={phoneOnChange}
										onBlur={setPhoneShowVal}
										mask={process.env.REACT_APP_PHONE_FORMAT}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='altPhone'
									label='Alt. Phone'
									id={data.id}
									valRes={altPhoneValRes}
									showValidation={altPhoneShowVal}
									colLg={6}>
									<Input
										type='text'
										placeholder='(555) 555-5555'
										value={altPhone}
										onChange={altPhoneOnChange}
										onBlur={setAltPhoneShowVal}
										mask={process.env.REACT_APP_PHONE_FORMAT}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField name='taxCode' label='Tax Code' id={data.id} colLg={6}>
									<Selects
										options={taxCodes}
										placeholder='Tax Code'
										value={taxCode}
										onChange={taxCodeOnChange}
										displayKey='name'
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='defaultTaxRate'
									label='Tax Rate'
									id={data.id}
									colLg={6}>
									<Selects
										options={taxRates}
										placeholder='Tax Rate'
										value={defaultTaxRate}
										onChange={defaultTaxRateOnChange}
										displayKey='name'
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField name='type' label='Customer Type' id={data.id} colLg={6}>
									<Selects
										options={customerTypes}
										placeholder='Select a Customer Type'
										value={type}
										onChange={typeOnChange}
										displayKey='name'
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='ccEmails'
									label='CC Emails'
									description='Enter CC emails comma seperated'
									id={data.id}
									colLg={6}>
									<Input
										type='text'
										placeholder='CC EMails'
										value={ccEmails}
										onChange={ccEmailsOnChange}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField name='isActive' label='Inactive' id={data.id} colLg={3}>
									<Toggle
										value={!isActive}
										onChange={e => {
											isActiveOnChange({
												target: { value: !e.target.value },
											});
										}}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='doNotEmail'
									label='Do Not Email'
									id={data.id}
									colLg={3}>
									<Toggle
										value={doNotEmail}
										onChange={doNotEmailOnChange}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='internalNote'
									label='Internal Notes'
									id={data.id}
									colMd={6}>
									<Textarea
										onChange={internalNoteOnChange}
										value={internalNote}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Address'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='addressLineOne'
									label='Address 1'
									id={data.id}
									colLg={6}
									valRes={addressLineOneValRes}
									showValidation={addressLineOneShowVal}>
									<Input
										type='text'
										placeholder='Address Line 1'
										value={addressLineOne}
										onChange={addressLineOneOnChange}
										onBlur={setAddressLineOneShowVal}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='addressLineTwo'
									label='Address 2'
									id={data.id}
									colLg={6}
									valRes={addressLineTwoValRes}
									showValidation={addressLineTwoShowVal}>
									<Input
										type='text'
										placeholder='Address Line 2'
										value={addressLineTwo}
										onChange={addressLineTwoOnChange}
										onBlur={setAddressLineTwoShowVal}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='city'
									label='City'
									id={data.id}
									colLg={3}
									valRes={cityValRes}
									showValidation={cityShowVal}>
									<Input
										type='text'
										placeholder='City'
										value={city}
										onChange={cityOnChange}
										onBlur={setCityShowVal}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField name='state' label='State' id={data.id} colLg={3}>
									<Selects
										options={
											Object.keys(country || {}).length
												? states.filter(s => s.country.id === country.id)
												: []
										}
										placeholder='State'
										value={state}
										onChange={stateOnChange}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='zip'
									label='ZIP Code'
									id={data.id}
									colLg={3}
									valRes={zipValRes}
									showValidation={zipShowVal}>
									<Input
										type='text'
										placeholder='ZIP Code'
										value={zip}
										onChange={zipOnChange}
										onBlur={setZipShowVal}
										mask={process.env.REACT_APP_ZIP_FORMAT}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField name='country' label='Country' id={data.id} colLg={3}>
									<Selects
										options={countries}
										placeholder='Select Country'
										value={country}
										onChange={changeCountryState}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
			<Wizard.Item
				title={pages.crm.customers.paymentSettings.text}
				description='Payment Settings'
				icon={pages.crm.customers.paymentSettings.icon}>
				<Section>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='houseAccount'
									label='House Account'
									id={data.id}
									colLg={4}>
									<Toggle
										spaceLess
										value={houseAccount}
										onChange={houseAccountOnChange}
										noPermission={
											!userContext.hasPermission('enable_house_account')
										}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='canSavePaymentMethod'
									label='Can Save Payment Method'
									id={data.id}
									colLg={4}>
									<Toggle
										spaceLess
										value={canSavePaymentMethod}
										onChange={canSavePaymentMethodOnChange}
										noPermission={
											!userContext.hasPermission('save_payment_methods')
										}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='excludeBalanceCheck'
									label='Exclude Balance Check'
									id={data.id}
									colLg={4}>
									<Toggle
										onChange={excludeBalanceCheckOnChange}
										value={excludeBalanceCheck}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<CustomerPaymentMethodList
								type={paymentTypes.CREDIT_CARD}
								title='Saved Credit Cards'
								icon='Credit-card'
								formTitle='New Account'
								data={customerPaymentMethods}
								paymentMethods={paymentMethods}
								onAdd={onPaymentMethodAdd}
								onDelete={onPaymentMethodDelete}
								onDefaultSelect={onPaymentMethodDefaultSelect()}
								customer={data}
								disabled={data.id === 0 || !isEditable}
							/>
							{checkProcessor && (
								<CustomerPaymentMethodList
									type={paymentTypes.CHECK}
									title='Saved ACH'
									icon='Ticket'
									formTitle='New ACH'
									data={customerPaymentMethods}
									paymentMethods={paymentMethods}
									onAdd={onPaymentMethodAdd}
									onDelete={onPaymentMethodDelete}
									onDefaultSelect={onPaymentMethodDefaultSelect()}
									customer={data}
									disabled={data.id === 0 || !isEditable}
								/>
							)}
							<Loading isLoading={isLoading}>
								<FormField
									name='paymentTerm'
									label='Payment Term'
									id={data.id}
									colLg={6}>
									<Selects
										options={paymentTerms}
										placeholder='Select Payment Term'
										value={paymentTerm}
										onChange={paymentTermOnChange}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='paymentTermPos'
									label='Pos Payment Term'
									id={data.id}
									colLg={6}>
									<Selects
										options={paymentTerms}
										placeholder='Select Payment Term'
										value={paymentTermPos}
										onChange={paymentTermPosOnChange}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='paymentTermBooking'
									label='Booking Payment Term'
									id={data.id}
									colLg={6}>
									<Selects
										options={paymentTerms}
										placeholder='Select Payment Term'
										value={paymentTermBooking}
										onChange={paymentTermBookingOnChange}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='paymentTermMarina'
									label='Marina Payment Term'
									id={data.id}
									colLg={6}>
									<Selects
										options={paymentTerms}
										placeholder='Select Payment Term'
										value={paymentTermMarina}
										onChange={paymentTermMarinaOnChange}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='paymentTermCampground'
									label='Campground Payment Term'
									id={data.id}
									colLg={6}>
									<Selects
										options={paymentTerms}
										placeholder='Select Payment Term'
										value={paymentTermCampground}
										onChange={paymentTermCampgroundOnChange}
										disabled={!isEditable}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
		</Wizard>
	);
};

CustomerForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		customerId: PropTypes.string,
		displayName: PropTypes.string,
		email: PropTypes.string,
		password: PropTypes.string,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		customerCompany: PropTypes.string,
		phone: PropTypes.string,
		altPhone: PropTypes.string,
		addressLineOne: PropTypes.string,
		addressLineTwo: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.object,
		zip: PropTypes.string,
		country: PropTypes.shape({ states: PropTypes.arrayOf(PropTypes.object) }),
		houseAccount: PropTypes.bool,
		creditCards: PropTypes.array,
	}),
	countries: PropTypes.arrayOf(PropTypes.object),
	states: PropTypes.arrayOf(PropTypes.object),
	paymentMethods: PropTypes.arrayOf(PropTypes.object),
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	isValid: PropTypes.bool,
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isSubmitting: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	taxRates: PropTypes.arrayOf(PropTypes.object),
	customerTypes: PropTypes.arrayOf(PropTypes.object),
	isEditable: PropTypes.bool,
};
CustomerForm.defaultProps = {
	data: {
		id: 0,
	},
	countries: [],
	states: [],
	paymentMethods: [],
	paymentTerms: [],
	isValid: false,
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isSubmitting: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
	taxCodes: [],
	taxRates: [],
	customerTypes: [],
	isEditable: true,
};

export default CustomerForm;
