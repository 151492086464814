import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import classNames from 'classnames';

import useField from '../../../utils/hooks/useField';
import {
	email as emailValidation,
	maxLength,
	password as passwordValidation,
	phoneNumber,
	phoneNumberRequired,
	required,
	uniqueFnc,
} from '../../../utils/helpers/validation';

import Button from '../element/Button';
import FormGroup from '../layout/FormGroup';
import FormField from '../template/FormField';
import Input from '../field/Input';
import Portal from '../layout/Portal';
import Portlet from '../layout/Portlet';
import Section from '../layout/Section';
import Selects from '../field/Selects';
import Toggle from '../field/Toggle';
import apiCall, { parseData } from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	addSuccessNotification,
	phoneNumberParser,
} from '../../../utils/helpers/helper';

export const Login = ({ openRegister, onLogin, outlet }) => {
	const [email, setEmail] = useState('');

	const [password, setPassword] = useState('');

	const [isForgotPass, setIsForgotPass] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const onSubmit = () => {
		setIsSubmitting(true);

		apiCall(
			'POST',
			'onlineCustomerLogin',
			res => {
				setIsSubmitting(false);
				onLogin(res);
			},
			err => {
				addErrorNotification(err.toString());
				setIsSubmitting(false);
			},
			'',
			{
				email,
				password,
				outletId: outlet.id,
				token: outlet.settings.onlineSiteToken,
			}
		);
	};

	return (
		<Portlet fluid='fluid' isLast everyTimeFluid>
			<Portlet.Head>
				{isForgotPass ? (
					<Portlet.HeadLabelTitle portletIcon='Send'>
						Forgot your password?
					</Portlet.HeadLabelTitle>
				) : (
					<>
						<Portlet.HeadLabelTitle portletIcon='Sign-in'>
							Sign In
						</Portlet.HeadLabelTitle>
						{openRegister && (
							<Portlet.HeadToolbarActions>
								or{' '}
								<span
									role='presentation'
									className='sdms-link'
									onClick={openRegister}>
									Sign Up
								</span>
							</Portlet.HeadToolbarActions>
						)}
					</>
				)}
			</Portlet.Head>
			<Portlet.Body>
				<FormGroup isLast>
					<FormField label='Email' name='email' col={12}>
						<Input
							placeholder='Email'
							type='text'
							name='username'
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
					</FormField>
					{!isForgotPass && (
						<FormField label='Password' name='password' col={12}>
							<Input
								className={classNames('form-control-last', {
									'form-control--empty': true,
									// pass === '',
								})}
								type='password'
								placeholder='Password'
								name='pass'
								value={password}
								togglePassword
								onChange={e => setPassword(e.target.value)}
							/>
						</FormField>
					)}
					{!isForgotPass && (
						<div className='col-auto ml-auto sdms-mb-10'>
							<span
								className='sdms-link'
								role='presentation'
								onClick={() => setIsForgotPass(true)}>
								Forgot your password?
							</span>
						</div>
					)}
				</FormGroup>
				{isForgotPass ? (
					<div className='row'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								block
								onClick={() => setIsForgotPass(false)}
							/>
						</div>
						<div className='col'>
							<Button
								text='Submit'
								label='info'
								icon={
									isSubmitting
										? process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON
										: 'Send'
								}
								bold='bold'
								className={classNames({
									'sdms-fading-dots': isSubmitting,
								})}
								block
								onClick={() => {
									setIsForgotPass(false);
								}}
								disabled={isSubmitting}
							/>
						</div>
					</div>
				) : (
					<Button
						text='Sign In'
						label='success'
						icon={
							isSubmitting
								? process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON
								: 'Sign-in'
						}
						iconSize={36}
						bold='bold'
						className={classNames('sdms-p5', 'sdms-font-lg', {
							'sdms-fading-dots': isSubmitting,
						})}
						block
						onClick={onSubmit}
						disabled={isSubmitting}
					/>
				)}
			</Portlet.Body>
		</Portlet>
	);
};
Login.propTypes = {
	openRegister: PropTypes.func,
	onLogin: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
};

Login.defaultProps = {
	openRegister: null,
};

export const Register = ({
	inCustomerModal,
	onRegister,
	onCancel,
	onClose,
	paymentTerms,
	countries,
	defaultCountry,
	states,
	enableHouseAccount,
	saveCreditCards,
	isOnline,
	outlet,
	passwordSettings,
	onlySignUp,
}) => {
	const data = useRef({ id: 0 });

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [isValid, setIsValid] = useState(false);

	const onlineRequiredValidation = () => {
		return isOnline ? required : () => true;
	};

	const [
		displayName,
		displayNameOnChange,
		displayNameValRes,
		displayNameShowVal,
		setDisplayNameShowVal,
	] = useField(data.current, 'displayName', () => {}, [
		required,
		maxLength(),
		uniqueFnc(
			isOnline ? 'onlineCustomerUniqueControl' : 'customers',
			'displayName',
			data.current.id,
			true,
			isOnline ? { outletId: outlet.id, token: outlet.settings.onlineSiteToken } : null
		),
	]);

	const [
		email,
		emailOnChange,
		emailValRes,
		emailShowVal,
		setEmailShowVal,
	] = useField(data.current, 'email', () => {}, [
		required,
		maxLength(),
		emailValidation,
		uniqueFnc(
			isOnline ? 'onlineCustomerUniqueControl' : 'customers',
			'email',
			data.current.id,
			true,
			isOnline ? { outletId: outlet.id, token: outlet.settings.onlineSiteToken } : null,
			isOnline ? `Email already exists. Please use Forgot Password ` : null
		),
	]);

	const [
		password,
		passwordOnChange,
		passwordValRes,
		passwordShowVal,
		setPasswordShowVal,
	] = useField(data.current, 'password', () => {}, [
		onlineRequiredValidation(),
		maxLength(),
		passwordValidation(passwordSettings),
	]);

	const [
		firstName,
		firstNameOnChange,
		firstNameValRes,
		firstNameShowVal,
		setFirstNameShowVal,
	] = useField(data.current, 'firstName', () => {}, [onlineRequiredValidation(), maxLength()]);

	const [
		lastName,
		lastNameOnChange,
		lastNameValRes,
		lastNameShowVal,
		setLastNameShowVal,
	] = useField(data.current, 'lastName', () => {}, [onlineRequiredValidation(), maxLength()]);

	const [
		customerCompany,
		customerCompanyOnChange,
		customerCompanyValRes,
		customerCompanyShowVal,
		setCustomerCompanyShowVal,
	] = useField(data.current, 'customerCompany', () => {}, [maxLength()]);

	const [phone, phoneOnChange, phoneValRes, phoneShowVal, setPhoneShowVal] = useField(
		data.current,
		'phone',
		() => {},
		[phoneNumberRequired(outlet), onlineRequiredValidation(), phoneNumber, maxLength()],
		'',
		phoneNumberParser
	);

	const [
		altPhone,
		altPhoneOnChange,
		altPhoneValRes,
		altPhoneShowVal,
		setAltPhoneShowVal,
	] = useField(
		data.current,
		'altPhone',
		() => {},
		[phoneNumber, maxLength()],
		'',
		phoneNumberParser
	);

	const [
		addressLineOne,
		addressLineOneOnChange,
		addressLineOneValRes,
		addressLineOneShowVal,
		setAddressLineOneShowVal,
	] = useField(data.current, 'addressLineOne', () => {}, [
		onlineRequiredValidation(),
		maxLength(),
	]);

	const [
		addressLineTwo,
		addressLineTwoOnChange,
		addressLineTwoValRes,
		addressLineTwoShowVal,
		setAddressLineTwoShowVal,
	] = useField(data.current, 'addressLineTwo', () => {}, [maxLength()]);

	const [city, cityOnChange, cityValRes, cityShowVal, setCityShowVal] = useField(
		data.current,
		'city',
		() => {},
		[onlineRequiredValidation(), maxLength()]
	);

	const [state, stateOnChange, stateValRes, stateShowVal, setStateShowVal] = useField(
		data.current,
		'state',
		() => {},
		[onlineRequiredValidation()],
		null
	);

	const [zip, zipOnChange, zipValRes, zipShowVal, setZipShowVal] = useField(
		data.current,
		'zip',
		() => {},
		[onlineRequiredValidation(), maxLength()]
	);

	const [country, countryOnChange, countryValRes, countryShowVal, setCountryShowVal] = useField(
		data.current,
		'country',
		() => {},
		[onlineRequiredValidation()],
		defaultCountry
	);

	const [houseAccount, houseAccountOnChange] = useField(
		data.current,
		'houseAccount',
		() => {},
		[],
		true
	);

	const [canSavePaymentMethod, canSavePaymentMethodOnChange] = useField(
		data.current,
		'canSavePaymentMethod',
		() => {},
		[],
		true
	);

	const [paymentTerm, paymentTermOnChange] = useField(
		data.current,
		'paymentTerm',
		() => {},
		[],
		null
	);

	const [doNotEmail, doNotEmailOnChange] = useField(
		data.current,
		'doNotEmail',
		() => {},
		[],
		false
	);

	// update states and reset selected state.
	const changeCountryState = ({ target }) => {
		stateOnChange({
			target: {
				name: 'state',
				value: null,
			},
		});

		countryOnChange({ target });
	};

	const onSubmit = () => {
		setIsSubmitted(true);

		if (isValid) {
			setIsSubmitting(true);

			const formData = JSON.parse(JSON.stringify(data.current));

			// Parse data for api call.
			Object.keys(formData).forEach(field => {
				formData[field] = parseData(formData[field]);
			});

			if (isOnline) {
				formData.outletId = outlet.id;

				formData.token = outlet.settings.onlineSiteToken;
			}

			apiCall(
				'POST',
				isOnline ? 'onlineCustomerRegister' : 'customers',
				res => {
					onRegister(res);
					addSuccessNotification(`Customer ${displayName} successfully added`);
					setIsSubmitting(false);
				},
				err => {
					addErrorNotification(err.toString());
					setIsSubmitting(false);
				},
				'',
				formData
			);
		} else {
			document
				.getElementById('sdms-register-content')
				.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	useEffect(() => {
		if (isSubmitted) {
			setDisplayNameShowVal();
			setPasswordShowVal();
			setEmailShowVal();
			setPhoneShowVal();
			setAltPhoneShowVal();
			setFirstNameShowVal();
			setLastNameShowVal();
			setCustomerCompanyShowVal();
			setAddressLineOneShowVal();
			setAddressLineTwoShowVal();
			setCityShowVal();
			setZipShowVal();
			setStateShowVal();
			setCountryShowVal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isSubmitted,
		setDisplayNameShowVal,
		setPasswordShowVal,
		setEmailShowVal,
		setPhoneShowVal,
		setAltPhoneShowVal,
		setFirstNameShowVal,
		setLastNameShowVal,
		setCustomerCompanyShowVal,
		setAddressLineOneShowVal,
		setAddressLineTwoShowVal,
		setCityShowVal,
		setZipShowVal,
		setStateShowVal,
		setCountryShowVal,
	]);

	useEffect(() => {
		setIsValid(
			displayNameValRes.isValid &&
				passwordValRes.isValid &&
				emailValRes.isValid &&
				phoneValRes.isValid &&
				altPhoneValRes.isValid &&
				firstNameValRes.isValid &&
				lastNameValRes.isValid &&
				customerCompanyValRes.isValid &&
				addressLineOneValRes.isValid &&
				addressLineTwoValRes.isValid &&
				cityValRes.isValid &&
				zipValRes.isValid &&
				stateValRes.isValid &&
				countryValRes.isValid
		);
	}, [
		displayNameValRes.isValid,
		passwordValRes.isValid,
		emailValRes.isValid,
		phoneValRes.isValid,
		altPhoneValRes.isValid,
		firstNameValRes.isValid,
		customerCompanyValRes.isValid,
		lastNameValRes.isValid,
		addressLineOneValRes.isValid,
		addressLineTwoValRes.isValid,
		cityValRes.isValid,
		zipValRes.isValid,
		stateValRes.isValid,
		countryValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		data.current.isActive = true;
	}, []);

	return (
		<Portlet fluid='fluid' isLast everyTimeFluid>
			{inCustomerModal && (
				<Portlet.Head>
					<Portlet.HeadLabelTitle portletIcon='Add-user'>
						{displayName || 'New Customer'}
					</Portlet.HeadLabelTitle>
					<Portlet.HeadToolbarActions className='sdms-last-margin'>
						<Button
							label='brand'
							icon='Add-user'
							text='Back to Search'
							size='sm'
							onClick={onCancel}
						/>
					</Portlet.HeadToolbarActions>
				</Portlet.Head>
			)}
			{!inCustomerModal && (
				<Portlet.Head>
					<Portlet.HeadLabelTitle portletIcon='Add-user'>Sign up</Portlet.HeadLabelTitle>
					{!onlySignUp && (
						<Portlet.HeadToolbarActions>
							or{' '}
							<span role='presentation' className='sdms-link' onClick={onCancel}>
								Sign In
							</span>
						</Portlet.HeadToolbarActions>
					)}
				</Portlet.Head>
			)}
			<Portlet.Body id='sdms-register-content'>
				<Section title='General'>
					<Section.Body>
						<FormGroup>
							<FormField
								name='firstName'
								label='First Name'
								id={data.id}
								colMd={6}
								valRes={firstNameValRes}
								showValidation={firstNameShowVal}>
								<Input
									type='text'
									placeholder='First Name'
									value={firstName}
									onChange={firstNameOnChange}
									onBlur={setFirstNameShowVal}
									xAutoCompleteType='given-name'
									autoComplete='given-name'
								/>
							</FormField>
							<FormField
								name='lastName'
								label='Last Name'
								id={data.id}
								colMd={6}
								valRes={lastNameValRes}
								showValidation={lastNameShowVal}>
								<Input
									type='text'
									placeholder='Last Name'
									value={lastName}
									onChange={lastNameOnChange}
									onBlur={setLastNameShowVal}
									xAutoCompleteType='family-name'
									autoComplete='family-name'
								/>
							</FormField>
							{!isOnline && (
								<FormField
									name='displayName'
									label='Display Name'
									description='To be used everywhere in the system'
									id={data.id}
									valRes={displayNameValRes}
									showValidation={displayNameShowVal}
									loadingContainer
									colMd={6}>
									<Input
										type='text'
										placeholder='DisplayName (Required)'
										value={displayName}
										onChange={displayNameOnChange}
										onBlur={setDisplayNameShowVal}
										xAutoCompleteType='nickname'
										autoComplete='nickname'
									/>
								</FormField>
							)}
							<FormField
								name='email'
								label='Email'
								id={data.id}
								valRes={emailValRes}
								showValidation={emailShowVal}
								loadingContainer
								colMd={6}>
								<Input
									type='text'
									placeholder='Email (Required)'
									value={email}
									onChange={emailOnChange}
									onBlur={setEmailShowVal}
									xAutoCompleteType='email'
									autoComplete='email'
								/>
							</FormField>
							<FormField
								label='Password'
								name='password'
								colMd={6}
								id={data.id}
								valRes={passwordValRes}
								showValidation={passwordShowVal}>
								<Input
									className={classNames('form-control-last', {
										'form-control--empty': true,
									})}
									type='password'
									placeholder='Password'
									name='pass'
									value={password}
									togglePassword
									onChange={passwordOnChange}
									onBlur={setPasswordShowVal}
								/>
							</FormField>
							<FormField
								name='phone'
								label='Phone'
								id={data.id}
								valRes={phoneValRes}
								showValidation={phoneShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='(555) 555-5555'
									value={phone}
									onChange={phoneOnChange}
									onBlur={setPhoneShowVal}
									mask={process.env.REACT_APP_PHONE_FORMAT}
									xAutoCompleteType='phone-national'
									autoComplete='phone-national'
								/>
							</FormField>
							{!isOnline && (
								<FormField
									name='altPhone'
									label='Alt. Phone'
									id={data.id}
									valRes={altPhoneValRes}
									showValidation={altPhoneShowVal}
									colMd={6}>
									<Input
										type='text'
										placeholder='(555) 555-5555'
										value={altPhone}
										onChange={altPhoneOnChange}
										onBlur={setAltPhoneShowVal}
										mask={process.env.REACT_APP_PHONE_FORMAT}
										xAutoCompleteType='phone-national'
										autoComplete='phone-national'
									/>
								</FormField>
							)}
							<FormField
								name='customerCompany'
								label='Company'
								id={data.id}
								colMd={6}
								valRes={customerCompanyValRes}
								showValidation={customerCompanyShowVal}>
								<Input
									type='text'
									placeholder='Company'
									value={customerCompany}
									onChange={customerCompanyOnChange}
									onBlur={setCustomerCompanyShowVal}
									xAutoCompleteType='org'
									autoComplete='org'
								/>
							</FormField>
							{inCustomerModal && (
								<FormField
									name='paymentTerm'
									label='Payment Term'
									id={data.id}
									colMd={6}>
									<Selects
										options={paymentTerms}
										placeholder='Select Payment Term'
										value={paymentTerm}
										onChange={paymentTermOnChange}
									/>
								</FormField>
							)}
							{enableHouseAccount && (
								<FormField
									name='houseAccount'
									label='House Account'
									id={data.id}
									colMd={3}>
									<Toggle
										spaceLess
										value={houseAccount}
										onChange={houseAccountOnChange}
									/>
								</FormField>
							)}
							<FormField
								name='doNotEmail'
								label='Do Not Email'
								id={data.id}
								colMd={3}>
								<Toggle
									spaceLess
									value={doNotEmail}
									onChange={doNotEmailOnChange}
								/>
							</FormField>
							{saveCreditCards && (
								<FormField
									name='canSavePaymentMethod'
									label='Can Save Payment Method'
									id={data.id}
									colMd={6}>
									<Toggle
										spaceLess
										value={canSavePaymentMethod}
										onChange={canSavePaymentMethodOnChange}
									/>
								</FormField>
							)}
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Address'>
					<Section.Body>
						<FormGroup>
							<FormField
								name='addressLineOne'
								label='Address 1'
								id={data.id}
								colMd={12}
								valRes={addressLineOneValRes}
								showValidation={addressLineOneShowVal}>
								<Input
									type='text'
									placeholder='Address Line 1'
									value={addressLineOne}
									onChange={addressLineOneOnChange}
									onBlur={setAddressLineOneShowVal}
									xAutoCompleteType='address-line1'
									autoComplete='address-line1'
								/>
							</FormField>
							<FormField
								name='addressLineTwo'
								label='Address 2'
								id={data.id}
								colMd={12}
								valRes={addressLineTwoValRes}
								showValidation={addressLineTwoShowVal}>
								<Input
									type='text'
									placeholder='Address Line 2'
									value={addressLineTwo}
									onChange={addressLineTwoOnChange}
									onBlur={setAddressLineTwoShowVal}
									xAutoCompleteType='address-line2'
									autoComplete='address-line2'
								/>
							</FormField>
							<FormField
								name='city'
								label='City'
								id={data.id}
								colMd={6}
								valRes={cityValRes}
								showValidation={cityShowVal}>
								<Input
									type='text'
									placeholder='City'
									value={city}
									onChange={cityOnChange}
									onBlur={setCityShowVal}
									xAutoCompleteType='city'
									autoComplete='city'
								/>
							</FormField>
							<FormField
								name='state'
								label='State'
								id={data.id}
								colMd={6}
								showValidation={stateShowVal}
								valRes={stateValRes}>
								<Selects
									options={
										Object.keys(country).length
											? states.filter(s => s.country.id === country.id)
											: []
									}
									placeholder='State'
									value={state}
									onChange={stateOnChange}
									xAutoCompleteType='state'
									autoComplete='state'
									onBlur={setStateShowVal}
								/>
							</FormField>
							<FormField
								name='zip'
								label='ZIP Code'
								id={data.id}
								colMd={6}
								valRes={zipValRes}
								showValidation={zipShowVal}>
								<Input
									type='text'
									placeholder='ZIP Code'
									value={zip}
									onChange={zipOnChange}
									onBlur={setZipShowVal}
									mask={process.env.REACT_APP_ZIP_FORMAT}
									xAutoCompleteType='postal-code'
									autoComplete='postal-code'
								/>
							</FormField>
							<FormField
								name='country'
								label='Country'
								id={data.id}
								colMd={6}
								showValidation={countryShowVal}
								valRes={countryValRes}>
								<Selects
									options={countries}
									placeholder='Select Country'
									value={country}
									onChange={changeCountryState}
									disableClearable
									xAutoCompleteType='country'
									autoComplete='country'
									onBlur={setCountryShowVal}
								/>
							</FormField>
						</FormGroup>
					</Section.Body>
				</Section>
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						icon='Error-circle'
						size='sm'
						elevate
						text='Cancel'
						onClick={onClose}
					/>
				</div>
				<div className='col-auto'>
					<Button
						design='info'
						text={inCustomerModal ? 'Add Customer' : 'Sign Up'}
						icon={inCustomerModal ? 'Add-user' : 'Sign-in'}
						size='sm'
						onClick={onSubmit}
						disabled={isSubmitting}
						className={classNames({
							'sdms-fading-dots': isSubmitting,
						})}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
Register.propTypes = {
	inCustomerModal: PropTypes.bool,
	onRegister: PropTypes.func,
	onCancel: PropTypes.func,
	onClose: PropTypes.func,
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	countries: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	defaultCountry: PropTypes.object,
	states: PropTypes.arrayOf(PropTypes.object),
	enableHouseAccount: PropTypes.bool,
	saveCreditCards: PropTypes.bool,
	isOnline: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	passwordSettings: PropTypes.object,
	onlySignUp: PropTypes.bool,
};
Register.defaultProps = {
	inCustomerModal: false,
	onRegister: () => {},
	onCancel: () => {},
	onClose: () => {},
	paymentTerms: [],
	countries: [],
	defaultCountry: null,
	states: [],
	enableHouseAccount: false,
	saveCreditCards: false,
	isOnline: false,
	outlet: null,
	passwordSettings: null,
	onlySignUp: false,
};

const LoginModal = ({
	isOpen,
	onClose,
	onLogin,
	onRegister,
	countries,
	defaultCountry,
	states,
	outlet,
	passwordSettings,
}) => {
	const [isRegisterFormOpen, setIsRegisterFormOpen] = useState(false);

	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				closeOnEscape={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
					minHeight: '480px',
					height: '50%',
				}}>
				{isRegisterFormOpen ? (
					<Register
						onRegister={onRegister}
						onCancel={() => setIsRegisterFormOpen(false)}
						onClose={onClose}
						countries={countries}
						defaultCountry={defaultCountry}
						states={states}
						isOnline
						outlet={outlet}
						passwordSettings={passwordSettings}
					/>
				) : (
					<Login
						openRegister={() => setIsRegisterFormOpen(true)}
						onLogin={onLogin}
						outlet={outlet}
					/>
				)}
			</Popup>
		</Portal>
	);
};
LoginModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onLogin: PropTypes.func.isRequired,
	onRegister: PropTypes.func.isRequired,
	countries: PropTypes.arrayOf(PropTypes.object).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	defaultCountry: PropTypes.object.isRequired,
	states: PropTypes.arrayOf(PropTypes.object).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	passwordSettings: PropTypes.object,
};

LoginModal.defaultProps = {
	passwordSettings: null,
};

LoginModal.defaultProps = {
	passwordSettings: null,
};

export default LoginModal;
