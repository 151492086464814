import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import update from 'immutability-helper';

import { required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import usePages from '../../../utils/hooks/usePages';
import {
	dateFormatterWithoutYear,
	generateId,
	numberParser,
	parseDatePickerChange,
	parseDatePickerValue,
	parseEnumCalculationLabel,
} from '../../../utils/helpers/helper';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Input from '../../reusables/field/Input';
import Loading from '../../reusables/template/Loading';
import HeaderContext from '../../../app/contexts/HeaderContext';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Selects from '../../reusables/field/Selects';
import { ListBody, ListTable } from '../../reusables/template/List';
import DatePicker from '../../reusables/field/DatePicker';

const PaymentScheduleItem = ({
	data,
	onFormChange,
	isSubmitted,
	enumCalculations,
	setIsValid,
	onDelete,
}) => {
	const [
		invoiceOffset,
		invoiceOffsetOnChange,
		invoiceOffsetValRes,
		invoiceOffsetShowVal,
		setInvoiceOffsetShowVal,
	] = useField(data, 'invoiceOffset', onFormChange, [required], '', numberParser(false));

	const [maxDate, maxDateOnChange] = useField(data, 'maxDate', onFormChange, [], null);

	const [amount, amountOnChange, amountValRes, amountShowVal, setAmountShowVal] = useField(
		data,
		'amount',
		onFormChange,
		[required],
		'',
		numberParser(true)
	);

	const [type, typeOnChange, typeValRes, typeShowVal, setTypeShowVal] = useField(
		data,
		'type',
		onFormChange,
		[required],
		null
	);

	useEffect(() => {
		if (isSubmitted) {
			setInvoiceOffsetShowVal();
			setAmountShowVal();
			setTypeShowVal();
		}
	}, [isSubmitted, setInvoiceOffsetShowVal, setAmountShowVal, setTypeShowVal]);

	useEffect(() => {
		setIsValid(invoiceOffsetValRes.isValid && amountValRes.isValid && typeValRes.isValid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoiceOffsetValRes.isValid, amountValRes.isValid, typeValRes.isValid]);

	return (
		<tr>
			<th>
				<FormField
					name='invoiceOffset'
					id={data.id}
					inFormDesign={false}
					showValidation={invoiceOffsetShowVal}
					valRes={invoiceOffsetValRes}
					isLast>
					<Input
						type='text'
						placeholder='Invoice offset (Required)'
						value={invoiceOffset}
						onChange={invoiceOffsetOnChange}
						onBlur={setInvoiceOffsetShowVal}
						pattern={process.env.REACT_APP_INTEGER_PATTERN}
						append='Days'
					/>
				</FormField>
			</th>
			<th>
				<FormField name='maxDate' id={data.id} inFormDesign={false} isLast>
					<DatePicker
						id='maxDate'
						type='calendar'
						placeholder='Max Date'
						place='top'
						value={parseDatePickerValue(maxDate)}
						onChange={e =>
							maxDateOnChange({
								target: {
									value: parseDatePickerChange(e.target.value, maxDate) || null,
								},
							})
						}
						formatter={dateFormatterWithoutYear}
					/>
				</FormField>
			</th>
			<th>
				<FormField
					name='amount'
					id={data.id}
					inFormDesign={false}
					showValidation={amountShowVal}
					valRes={amountValRes}
					isLast>
					<Input
						type='text'
						placeholder='Amount (Required)'
						value={amount}
						onChange={amountOnChange}
						onBlur={setAmountShowVal}
						pattern={process.env.REACT_APP_PRICE_PATTERN}
					/>
				</FormField>
			</th>
			<th>
				<FormField
					name='type'
					id={data.id}
					inFormDesign={false}
					showValidation={typeShowVal}
					valRes={typeValRes}
					isLast>
					<Selects
						value={parseEnumCalculationLabel(type)}
						options={enumCalculations.map(parseEnumCalculationLabel)}
						onChange={typeOnChange}
						onBlur={setTypeShowVal}
						displayKey='label'
					/>
				</FormField>
			</th>
			<th>
				<Button
					btnIcon
					label='danger'
					icon='Trash'
					size='sm'
					elevate
					key='delete'
					onClick={onDelete}
				/>
			</th>
		</tr>
	);
};

PaymentScheduleItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	onFormChange: PropTypes.func.isRequired,
	isSubmitted: PropTypes.bool.isRequired,
	enumCalculations: PropTypes.arrayOf(PropTypes.object).isRequired,
	setIsValid: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

const PaymentScheduleForm = ({
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	isLoading,
	onFormChange,
	submitButtonAttr,
	submit,
	enumCalculations,
}) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const itemsRequired = (value, setValRes) => {
		if (value.length === 0 || value.some(v => v.isValid === false)) {
			setValRes({
				isValid: false,
				status: 'required',
				message: 'Field is required',
			});

			return false;
		}

		return true;
	};

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [items, itemsOnChange, itemsValRes] = useField(
		data,
		'items',
		onFormChange,
		[itemsRequired],
		[]
	);

	const onAddItem = () => {
		itemsOnChange({ target: { value: [...items, { id: generateId(items) }] } });
	};

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
		}
	}, [isSubmitted, setNameShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && itemsValRes.isValid);
	}, [setIsValid, nameValRes.isValid, itemsValRes.isValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.accounting.default.text,
				path: pages.accounting.dashboard.path,
			},
			{
				title: pages.accounting.settings.text,
				path: pages.accounting.settings.path,
			},
			{
				title: pages.accounting.settings.paymentSchedules.text,
				path: pages.accounting.settings.paymentSchedules.path,
			},
			{
				title: name || `New ${pages.accounting.settings.paymentSchedules.text}`,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(
			name || `New ${pages.accounting.settings.paymentSchedules.text}`
		);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form' onSubmit={e => e.preventDefault()}>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
					</FormGroup>
					<Portlet className='sdms-list-layout' border>
						<Portlet.Head>
							<Portlet.HeadLabelTitle>Items</Portlet.HeadLabelTitle>
							<Portlet.HeadToolbar>
								<Portlet.HeadActions className='sdms-last-margin--h d-flex'>
									<Button
										className='sdms-mr-0'
										label='brand'
										icon='Plus'
										text='Add New'
										size='sm'
										onClick={onAddItem}
									/>
								</Portlet.HeadActions>
							</Portlet.HeadToolbar>
						</Portlet.Head>
						<ListBody
							className='table--everytime--scroll sdms-portlet__body--fit'
							responsive='scroll'>
							<ListTable childrenLength={4}>
								<colgroup>
									<col />
									<col />
									<col />
									<col />
									<col width={180} />
								</colgroup>
								<thead>
									<tr>
										<th>Invoice Offset</th>
										<th>Max Date</th>
										<th>Amount</th>
										<th>Type</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{items.map(item => (
										<PaymentScheduleItem
											onDelete={() =>
												itemsOnChange({
													target: {
														value: items.filter(i => i.id !== item.id),
													},
												})
											}
											enumCalculations={enumCalculations}
											setIsValid={_isValid => {
												const itemIndex = items.findIndex(
													i => i.id === item.id
												);

												itemsOnChange({
													target: {
														value: update(items, {
															[itemIndex]: {
																isValid: { $set: _isValid },
															},
														}),
													},
												});
											}}
											data={item}
											isSubmitted={isSubmitted}
											onFormChange={onFormChange}
										/>
									))}
								</tbody>
							</ListTable>
						</ListBody>
					</Portlet>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
PaymentScheduleForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		items: PropTypes.arrayOf(PropTypes.object),
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
	enumCalculations: PropTypes.arrayOf(PropTypes.object),
};
PaymentScheduleForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		items: [],
	},
	setIsValid: () => {},
	isSubmitted: false,
	setTitle: () => {},
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
	enumCalculations: [],
};

export default PaymentScheduleForm;
