import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useField from '../../../utils/hooks/useField';

import UserContext from '../../../app/contexts/UserContext';
import usePages from '../../../utils/hooks/usePages';
import { required, numeric } from '../../../utils/helpers/validation';
import {
	addErrorNotification,
	addSuccessNotification,
	numberParser,
} from '../../../utils/helpers/helper';
import apiCall, {
	filters,
	moduleRelatedEndpoints,
	modules,
	outletRelatedEndpoints,
	parseData,
} from '../../../utils/helpers/apiCall';

import Input from '../../reusables/field/Input';
import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Selects from '../../reusables/field/Selects';
import Section from '../../reusables/layout/Section';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';

const UnitsGenerateForm = ({ afterSubmit, onCancel }) => {
	const userContext = useContext(UserContext);

	const pages = usePages();

	const lists = ['bookingTypeBookings', 'unitGroups'];

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [isValid, setIsValid] = useState(false);

	const [listState, setListState] = useState({
		bookingTypeBookings: [],
		unitGroups: [],
	});

	const _isMounted = useRef(false);

	const bookingsModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.BOOKINGS)
	);

	const endNumberValidation = (value, setValRes) => {
		if (startingNumber === '') return true;

		// eslint-disable-next-line no-undef
		if (parseFloat(startingNumber) >= parseFloat(value)) {
			setValRes({
				isValid: false,
				status: 'invalidEndNumber',
				message: 'Ending number must be greater than starting number',
			});

			return false;
		}

		if (parseFloat(value) - parseFloat(startingNumber) > 99) {
			setValRes({
				isValid: false,
				status: 'invalidUnitCount',
				message: 'You cannot generate unit more than 99',
			});

			return false;
		}
		return true;
	};

	const [prefix, prefixOnChange] = useField({}, 'prefix', () => {});

	const [
		startingNumber,
		startingNumberOnChange,
		startingNumberValRes,
		startingNumberShowVal,
		setStartingNumberShowVal,
	] = useField({}, 'startingNumber', () => {}, [required, numeric], '', numberParser(false));

	const [
		endingNumber,
		endingNumberOnChange,
		endingNumberValRes,
		endingNumberShowVal,
		setEndingNumberShowVal,
		validateEndingNumber,
	] = useField(
		{},
		'endingNumber',
		() => {},
		[required, numeric, endNumberValidation],
		'',
		numberParser(false)
	);

	const [suffix, suffixOnChange] = useField({}, 'suffix', () => {});

	const [
		bookingType,
		bookingTypeOnChange,
		bookingTypeValRes,
		bookingTypeShowVal,
		setBookingTypeShowVal,
	] = useField({}, 'bookingType', () => {}, [required], null);

	const [
		unitGroup,
		unitGroupOnChange,
		unitGroupValRes,
		unitGroupShowVal,
		setUnitGroupShowVal,
	] = useField({}, 'unitGroup', () => {}, [required], null);

	const [description, descriptionOnChange] = useField({}, 'description', () => {});

	const [prefixMapCaption, prefixMapCaptionOnChange] = useField({}, 'prefixMapCaption', () => {});

	const [suffixMapCaption, suffixMapCaptionOnChange] = useField({}, 'suffixMapCaption', () => {});

	const submit = () => {
		setIsSubmitted(true);

		if (!isValid) return;

		setIsSubmitting(true);

		const formData = JSON.parse(
			JSON.stringify({
				prefix,
				startingNumber,
				endingNumber,
				suffix,
				bookingType,
				unitGroup,
				description,
				prefixMapCaption,
				suffixMapCaption,
			})
		);

		// Parse data for api call.
		Object.keys(formData).forEach(field => {
			formData[field] = parseData(formData[field]);
		});

		// set outlet.
		formData.outlet = parseData(userContext.data.selectedOutlet, 'outlets');

		// set module.
		formData.module = parseData(bookingsModule.current);

		apiCall(
			'POST',
			'unitGenerate',
			res => {
				afterSubmit(res.units);
				addSuccessNotification('Units successfully added.');

				setIsSubmitting(false);
			},
			err => {
				addErrorNotification(err.toString());

				setIsSubmitting(false);
			},
			'',
			formData
		);
	};

	useEffect(() => {
		_isMounted.current = true;

		return () => {
			_isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		lists.forEach(name => {
			const _filters = {
				pagination: false,
			};

			// if list is related outlet add filter.
			if (outletRelatedEndpoints.includes(name))
				_filters['outlet.id'] = userContext.data.selectedOutlet.id;

			if ((((filters[name] || {}).groups || {}).list || {}).read)
				_filters['groups[]'] = filters[name].groups.list.read;

			if (moduleRelatedEndpoints.includes(name)) _filters['module.value'] = modules.BOOKINGS;
			apiCall(
				'GET',
				name,
				fetchedData => {
					if (!_isMounted.current) return;
					setListState(oldListState => ({
						...oldListState,
						[name]: fetchedData,
					}));
				},
				err => addErrorNotification(err.toString()),
				'',
				null,
				_filters
			);
		});
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [userContext.data.selectedOutlet.id]);

	useEffect(() => {
		if (isSubmitted) {
			setStartingNumberShowVal();
			setEndingNumberShowVal();
			setBookingTypeShowVal();
			setUnitGroupShowVal();
		}
	}, [
		isSubmitted,
		setStartingNumberShowVal,
		setEndingNumberShowVal,
		setBookingTypeShowVal,
		setUnitGroupShowVal,
	]);

	useEffect(() => {
		setIsValid(
			startingNumberValRes.isValid &&
				endingNumberValRes.isValid &&
				bookingTypeValRes.isValid &&
				unitGroupValRes.isValid
		);
	}, [
		startingNumberValRes.isValid,
		endingNumberValRes.isValid,
		bookingTypeValRes.isValid,
		unitGroupValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		validateEndingNumber(endingNumber);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startingNumber]);

	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon={pages.booking.settings.units.icon}>
					Generate
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<Section>
					<Section.Body>
						<FormGroup>
							<FormField name='prefix' label='Prefix' id={0} col={3}>
								<Input
									type='text'
									placeholder='Prefix'
									value={prefix}
									onChange={e => {
										prefixOnChange(e);

										prefixMapCaptionOnChange({
											target: { value: e.target.value },
										});
									}}
								/>
							</FormField>
							<FormField
								name='startingNumber'
								label='Starting Number'
								id={0}
								valRes={startingNumberValRes}
								showValidation={startingNumberShowVal}
								col={3}>
								<Input
									type='text'
									placeholder='Starting Number (Required)'
									value={startingNumber}
									onChange={e => {
										startingNumberOnChange(e);
										validateEndingNumber(endingNumber);
									}}
									onBlur={setStartingNumberShowVal}
									pattern={process.env.REACT_APP_INTEGER_PATTERN}
								/>
							</FormField>
							<FormField
								name='endingNumber'
								label='Ending Number'
								id={0}
								valRes={endingNumberValRes}
								showValidation={endingNumberShowVal}
								col={3}>
								<Input
									type='text'
									placeholder='Ending Number (Required)'
									value={endingNumber}
									onChange={endingNumberOnChange}
									onBlur={setEndingNumberShowVal}
									pattern={process.env.REACT_APP_INTEGER_PATTERN}
								/>
							</FormField>
							<FormField name='suffix' label='Suffix' id={0} col={3}>
								<Input
									type='text'
									placeholder='Suffix'
									value={suffix}
									onChange={e => {
										suffixOnChange(e);

										suffixMapCaptionOnChange({
											target: { value: e.target.value },
										});
									}}
								/>
							</FormField>
							<FormField
								name='bookingType'
								label='Booking Type'
								id={0}
								valRes={bookingTypeValRes}
								showValidation={bookingTypeShowVal}
								col={6}>
								<Selects
									options={listState.bookingTypeBookings}
									placeholder='Booking Type (Required)'
									value={bookingType}
									onChange={bookingTypeOnChange}
									onBlur={setBookingTypeShowVal}
								/>
							</FormField>
							<FormField
								name='unitGroup'
								label='Unit Group'
								id={0}
								valRes={unitGroupValRes}
								showValidation={unitGroupShowVal}
								col={6}>
								<Selects
									options={listState.unitGroups}
									placeholder='Unit Group (Required)'
									value={unitGroup}
									onChange={unitGroupOnChange}
									onBlur={setUnitGroupShowVal}
								/>
							</FormField>
							<FormField name='description' label='Description' id={0} col={6}>
								<Input
									type='text'
									placeholder='Enter Description'
									value={description}
									onChange={descriptionOnChange}
								/>
							</FormField>
						</FormGroup>
					</Section.Body>
					<Section.Body>
						<Portlet border className='sdms-portlet--head-noborder'>
							<Portlet.Head>
								<Portlet.HeadLabelTitle>Map Caption</Portlet.HeadLabelTitle>
							</Portlet.Head>
							<Portlet.Body>
								<div className='row'>
									<FormField
										name='prefixMapCaption'
										label='Prefix'
										id={0}
										colMd={3}>
										<Input
											type='text'
											placeholder='Prefix Map Caption'
											value={prefixMapCaption}
											onChange={prefixMapCaptionOnChange}
										/>
									</FormField>
									<FormField
										name='startingNumber'
										label='Starting Number'
										id={0}
										col={3}>
										<Input
											type='text'
											placeholder='Starting Number'
											value={startingNumber}
											disabled
										/>
									</FormField>
									<FormField
										name='endingNumber'
										label='Ending Number'
										id={0}
										col={3}>
										<Input
											type='text'
											placeholder='Ending Number'
											value={endingNumber}
											disabled
										/>
									</FormField>
									<FormField
										name='suffixMapCaption'
										label='Suffix'
										id={0}
										colMd={3}>
										<Input
											type='text'
											placeholder='Suffix Map Caption'
											value={suffixMapCaption}
											onChange={suffixMapCaptionOnChange}
										/>
									</FormField>
								</div>
							</Portlet.Body>
						</Portlet>
					</Section.Body>
				</Section>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						icon='Error-circle'
						size='sm'
						elevate
						text='Cancel'
						onClick={onCancel}
						disabled={isSubmitting}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='info'
						icon='Clipboard-list'
						text='Generate'
						size='sm'
						elevate
						onClick={submit}
						disabled={isSubmitting}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
UnitsGenerateForm.propTypes = {
	onCancel: PropTypes.func,
	afterSubmit: PropTypes.func,
};
UnitsGenerateForm.defaultProps = {
	onCancel: () => {},
	afterSubmit: () => {},
};

export default UnitsGenerateForm;
