import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useField from '../../../utils/hooks/useField';

import UserContext from '../../../app/contexts/UserContext';
import { required, numeric } from '../../../utils/helpers/validation';
import {
	addErrorNotification,
	addSuccessNotification,
	getCampgroundColorList,
	numberParser,
} from '../../../utils/helpers/helper';
import apiCall, {
	filters,
	moduleRelatedEndpoints,
	modules,
	outletRelatedEndpoints,
	parseData,
} from '../../../utils/helpers/apiCall';
import usePages from '../../../utils/hooks/usePages';
import useFeet from '../../../utils/hooks/useFeet';

import Input from '../../reusables/field/Input';
import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Selects from '../../reusables/field/Selects';
import Section from '../../reusables/layout/Section';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Radio from '../../reusables/field/Radio';
import SVGIcon from '../../reusables/element/SVGIcon';
import Badge from '../../reusables/element/Badge';
import LengthInputGroup from '../../reusables/field/LengthInputGroup';

const SpacesGenerateForm = ({ afterSubmit, onCancel }) => {
	const pages = usePages();

	const lists = ['campgroundBookingTypes', 'unitGroups', 'icons'];

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [isValid, setIsValid] = useState(false);

	const userContext = useContext(UserContext);

	const [listState, setListState] = useState({
		campgroundBookingTypes: [],
		unitGroups: [],
		icons: [],
	});

	const _isMounted = useRef(false);

	const campgroundModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.CAMPGROUND)
	);

	const spaceIcons = useRef(['Square', 'Circle', 'Hexagon']);

	const colorList = useRef(getCampgroundColorList());

	const endNumberValidation = (value, setValRes) => {
		if (startingNumber === '') return true;

		if (parseFloat(startingNumber) >= parseFloat(value)) {
			setValRes({
				isValid: false,
				status: 'invalidEndNumber',
				message: 'Ending number must be greater than the starting number',
			});

			return false;
		}

		if (parseFloat(value) - parseFloat(startingNumber) > 99) {
			setValRes({
				isValid: false,
				status: 'invalidUnitCount',
				message: 'You cannot generate more than 99 spaces',
			});

			return false;
		}
		return true;
	};

	const [prefix, prefixOnChange] = useField({}, 'prefix', () => {});

	const [
		startingNumber,
		startingNumberOnChange,
		startingNumberValRes,
		startingNumberShowVal,
		setStartingNumberShowVal,
	] = useField({}, 'startingNumber', () => {}, [required, numeric], '', numberParser(false));

	const [
		endingNumber,
		endingNumberOnChange,
		endingNumberValRes,
		endingNumberShowVal,
		setEndingNumberShowVal,
		validateEndingNumber,
	] = useField(
		{},
		'endingNumber',
		() => {},
		[required, numeric, endNumberValidation],
		'',
		numberParser(false)
	);

	const [suffix, suffixOnChange] = useField({}, 'suffix', () => {});

	const [
		bookingType,
		bookingTypeOnChange,
		bookingTypeValRes,
		bookingTypeShowVal,
		setBookingTypeShowVal,
	] = useField({}, 'bookingType', () => {}, [required], null);

	const [
		unitGroup,
		unitGroupOnChange,
		unitGroupValRes,
		unitGroupShowVal,
		setUnitGroupShowVal,
	] = useField({}, 'unitGroup', () => {}, [required], null);

	const [description, descriptionOnChange] = useField({}, 'description', () => {});

	const [prefixMapCaption, prefixMapCaptionOnChange] = useField({}, 'prefixMapCaption', () => {});

	const [suffixMapCaption, suffixMapCaptionOnChange] = useField({}, 'suffixMapCaption', () => {});

	const [inventory, inventoryOnChange] = useField({}, 'inventory', () => {});

	const [icon, iconOnChange] = useField({}, 'icon', () => {}, [], false);

	const [mapColor, mapColorOnChange] = useField({}, 'mapColor', () => {}, [], null);

	const [opacity, opacityOnChange] = useField({}, 'opacity', () => {}, [], 100);

	const [minLoa, minLoaOnChange] = useField({}, 'minLoa', () => {}, [], '', numberParser());

	const [minLoaFt, setMinLoaFt, minLoaIn, setMinLoaIn] = useFeet(null, minLoaOnChange);

	const [warnLoa, warnLoaOnChange] = useField({}, 'warnLoa', () => {}, [], '', numberParser());

	const [warnLoaFt, setWarnLoaFt, warnLoaIn, setWarnLoaIn] = useFeet(null, warnLoaOnChange);

	const [maxLoa, maxLoaOnChange] = useField(
		{},
		'maxLengthOverAll',
		() => {},
		[],
		'',
		numberParser()
	);

	const [maxLoaFt, setMaxLoaFt, maxLoaIn, setMaxLoaIn] = useFeet(null, maxLoaOnChange);

	const [minBeam, minBeamOnChange] = useField({}, 'minBeam', () => {}, [], '', numberParser());

	const [minBeamFt, setMinBeamFt, minBeamIn, setMinBeamIn] = useFeet(null, minBeamOnChange);

	const [warnBeam, warnBeamOnChange] = useField({}, 'warnBeam', () => {}, [], '', numberParser());

	const [warnBeamFt, setWarnBeamFt, warnBeamIn, setWarnBeamIn] = useFeet(null, warnBeamOnChange);

	const [maxBeam, maxBeamOnChange] = useField({}, 'maxBeam', () => {}, [], '', numberParser());

	const [maxBeamFt, setMaxBeamFt, maxBeamIn, setMaxBeamIn] = useFeet(null, maxBeamOnChange);

	const [minSqft, minSqftOnChange] = useField({}, 'minSqft', () => {}, [], '', numberParser());

	const [warnSqft, warnSqftOnChange] = useField({}, 'warnSqft', () => {}, [], '', numberParser());

	const [maxSqft, maxSqftOnChange] = useField({}, 'maxSqft', () => {}, [], '', numberParser());

	const [minHeight, minHeightOnChange] = useField(
		{},
		'minHeight',
		() => {},
		[],
		'',
		numberParser()
	);

	const [minHeightFt, setMinHeightFt, minHeightIn, setMinHeightIn] = useFeet(
		null,
		minHeightOnChange
	);

	const [warnHeight, warnHeightOnChange] = useField(
		{},
		'warnHeight',
		() => {},
		[],
		'',
		numberParser()
	);

	const [warnHeightFt, setWarnHeightFt, warnHeightIn, setWarnHeightIn] = useFeet(
		null,
		warnHeightOnChange
	);

	const [maxHeight, maxHeightOnChange] = useField(
		{},
		'maxHeight',
		() => {},
		[],
		'',
		numberParser()
	);

	const [maxHeightFt, setMaxHeightFt, maxHeightIn, setMaxHeightIn] = useFeet(
		null,
		maxHeightOnChange
	);

	const submit = () => {
		setIsSubmitted(true);

		if (!isValid) return;

		setIsSubmitting(true);

		const formData = JSON.parse(
			JSON.stringify({
				prefix,
				startingNumber,
				endingNumber,
				suffix,
				bookingType,
				unitGroup,
				description,
				prefixMapCaption,
				suffixMapCaption,
				inventory,
				opacity,
				minLoa,
				warnLoa,
				maxLoa,
				minBeam,
				warnBeam,
				maxBeam,
				minHeight,
				warnHeight,
				maxHeight,
				minSqft,
				warnSqft,
				maxSqft,
				icon,
				mapColor,
			})
		);

		// Parse data for api call.
		Object.keys(formData).forEach(field => {
			formData[field] = parseData(formData[field]);
		});

		// set outlet.
		formData.outlet = parseData(userContext.data.selectedOutlet, 'outlets');

		// set module.
		formData.module = parseData(campgroundModule.current);

		apiCall(
			'POST',
			'spaceGenerate',
			res => {
				afterSubmit(res.spaces);
				addSuccessNotification('Spaces successfully added.');

				setIsSubmitting(false);
			},
			err => {
				addErrorNotification(err.toString());

				setIsSubmitting(false);
			},
			'',
			formData
		);
	};

	useEffect(() => {
		_isMounted.current = true;

		return () => {
			_isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		lists.forEach(name => {
			const _filters = {
				pagination: false,
			};

			// if list is related outlet add filter.
			if (outletRelatedEndpoints.includes(name))
				_filters['outlet.id'] = userContext.data.selectedOutlet.id;

			if ((((filters[name] || {}).groups || {}).list || {}).read)
				_filters['groups[]'] = filters[name].groups.list.read;

			if (moduleRelatedEndpoints.includes(name))
				_filters['module.value'] = modules.CAMPGROUND;

			apiCall(
				'GET',
				name,
				fetchedData => {
					if (!_isMounted.current) return;
					setListState(oldListState => ({
						...oldListState,
						[name]: fetchedData,
					}));
				},
				err => {
					addErrorNotification(err.toString());
				},
				'',
				null,
				_filters
			);
		});
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [userContext.data.selectedOutlet.id]);

	useEffect(() => {
		if (isSubmitted) {
			setStartingNumberShowVal();
			setEndingNumberShowVal();
			setBookingTypeShowVal();
			setUnitGroupShowVal();
		}
	}, [
		isSubmitted,
		setStartingNumberShowVal,
		setEndingNumberShowVal,
		setBookingTypeShowVal,
		setUnitGroupShowVal,
	]);

	useEffect(() => {
		setIsValid(
			startingNumberValRes.isValid &&
				endingNumberValRes.isValid &&
				bookingTypeValRes.isValid &&
				unitGroupValRes.isValid
		);
	}, [
		startingNumberValRes.isValid,
		endingNumberValRes.isValid,
		bookingTypeValRes.isValid,
		unitGroupValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		validateEndingNumber(endingNumber);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startingNumber]);

	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon={pages.campground.settings.spaces.icon}>
					Generate
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<Section title='General Settings'>
					<Section.Body>
						<FormGroup>
							<FormField name='prefix' label='Prefix' id={0} colMd={3}>
								<Input
									type='text'
									placeholder='Prefix'
									value={prefix}
									onChange={e => {
										prefixOnChange(e);

										prefixMapCaptionOnChange({
											target: { value: e.target.value },
										});
									}}
								/>
							</FormField>
							<FormField
								name='startingNumber'
								label='Starting Number'
								id={0}
								valRes={startingNumberValRes}
								showValidation={startingNumberShowVal}
								colMd={3}>
								<Input
									type='text'
									placeholder='Starting Number (Required)'
									value={startingNumber}
									onChange={e => {
										startingNumberOnChange(e);
										validateEndingNumber(endingNumber);
									}}
									onBlur={setStartingNumberShowVal}
									pattern={process.env.REACT_APP_INTEGER_PATTERN}
								/>
							</FormField>
							<FormField
								name='endingNumber'
								label='Ending Number'
								id={0}
								valRes={endingNumberValRes}
								showValidation={endingNumberShowVal}
								colMd={3}>
								<Input
									type='text'
									placeholder='Ending Number (Required)'
									value={endingNumber}
									onChange={endingNumberOnChange}
									onBlur={setEndingNumberShowVal}
									pattern={process.env.REACT_APP_INTEGER_PATTERN}
								/>
							</FormField>
							<FormField name='suffix' label='Suffix' id={0} colMd={3}>
								<Input
									type='text'
									placeholder='Suffix'
									value={suffix}
									onChange={e => {
										suffixOnChange(e);

										suffixMapCaptionOnChange({
											target: { value: e.target.value },
										});
									}}
								/>
							</FormField>
							<FormField
								name='bookingType'
								label='Booking Type'
								id={0}
								valRes={bookingTypeValRes}
								showValidation={bookingTypeShowVal}
								colMd={6}>
								<Selects
									options={listState.campgroundBookingTypes}
									placeholder='Booking Type (Required)'
									value={bookingType}
									onChange={bookingTypeOnChange}
									onBlur={setBookingTypeShowVal}
								/>
							</FormField>
							<FormField
								name='unitGroup'
								label='Unit Group'
								id={0}
								valRes={unitGroupValRes}
								showValidation={unitGroupShowVal}
								colMd={6}>
								<Selects
									options={listState.unitGroups}
									placeholder='Unit Group (Required)'
									value={unitGroup}
									onChange={unitGroupOnChange}
									onBlur={setUnitGroupShowVal}
								/>
							</FormField>
							<FormField name='description' label='Description' id={0} colMd={6}>
								<Input
									type='text'
									placeholder='Enter Description!'
									value={description}
									onChange={descriptionOnChange}
								/>
							</FormField>
							<FormField name='inventory' label='Inventory' id={0} colMd={6}>
								<Input
									type='text'
									placeholder='Inventory'
									value={inventory}
									onChange={inventoryOnChange}
								/>
							</FormField>
							<FormField name='icon' label='Icon' id={0} colMd={4}>
								<Radio.Container
									isInline
									className={classNames('sdms-mt-5', {
										'sdms-bg-fill-dark': mapColor === 'white',
									})}>
									{listState.icons
										.filter(i => spaceIcons.current.indexOf(i.value) > -1)
										.map(i => {
											return (
												<Radio
													key={i.id}
													checked={icon && icon.id === i.id}
													id={i.id.toString()}
													name='icon'
													content={
														<SVGIcon
															name={i.value}
															className={classNames({
																[`sdms-custom-colors--fill--${
																	mapColor
																		? mapColor + opacity
																		: 'info'
																}`]: mapColor,
															})}
														/>
													}
													className='sdms-radio--primary sdms-mt-5'
													onChange={() => {
														iconOnChange({
															target: {
																name: 'icon',
																value: i,
															},
														});
													}}
												/>
											);
										})}
								</Radio.Container>
							</FormField>
							<FormField name='mapColor' label='Map Color' id={0} colMd={4}>
								<Selects
									className='sdms-font-transform-c'
									placeholder='Select a Color'
									options={colorList.current}
									value={
										mapColor
											? colorList.current.find(c => c.class === mapColor)
											: null
									}
									onChange={c =>
										mapColorOnChange({
											target: {
												value: c.target.value ? c.target.value.class : null,
											},
										})
									}
									displayKey='name'
									valueKey='class'
									renderOption={option => (
										<Badge
											className={`sdms-custom-colors--${option.class} sdms-font-transform-c`}
											isInline>
											<span>{option.name}</span>
										</Badge>
									)}
								/>
							</FormField>
							<FormField
								name='opacity'
								label={
									<>
										Opacity
										<Badge
											className='sdms-ml-10'
											design='info'
											isInline
											isUnified>
											{opacity}%
										</Badge>
									</>
								}
								id={0}
								colMd={4}>
								<Input
									className='sdms-mt-10'
									type='range'
									min={1}
									max={100}
									value={opacity}
									onChange={opacityOnChange}
								/>
							</FormField>
						</FormGroup>
					</Section.Body>
					<Section.Body>
						<Portlet border className='sdms-portlet--head-noborder'>
							<Portlet.Head>
								<Portlet.HeadLabelTitle>Map Caption</Portlet.HeadLabelTitle>
							</Portlet.Head>
							<Portlet.Body>
								<div className='row'>
									<FormField
										name='prefixMapCaption'
										label='Prefix'
										id={0}
										colMd={3}>
										<Input
											type='text'
											placeholder='Prefix Map Caption'
											value={prefixMapCaption}
											onChange={prefixMapCaptionOnChange}
										/>
									</FormField>
									<FormField
										name='startingNumber'
										label='Starting Number'
										id={0}
										col={3}>
										<Input
											type='text'
											placeholder='Starting Number'
											value={startingNumber}
											disabled
										/>
									</FormField>
									<FormField
										name='endingNumber'
										label='Ending Number'
										id={0}
										col={3}>
										<Input
											type='text'
											placeholder='Ending Number'
											value={endingNumber}
											disabled
										/>
									</FormField>
									<FormField
										name='suffixMapCaption'
										label='Suffix'
										id={0}
										colMd={3}>
										<Input
											type='text'
											placeholder='Suffix Map Caption'
											value={suffixMapCaption}
											onChange={suffixMapCaptionOnChange}
										/>
									</FormField>
								</div>
							</Portlet.Body>
						</Portlet>
					</Section.Body>
				</Section>
				<Section title='Vehicle Constraints'>
					<Section.Body>
						<Portlet border className='sdms-portlet--head-noborder'>
							<Portlet.Head>
								<Portlet.HeadLabelTitle>Length Overall</Portlet.HeadLabelTitle>
							</Portlet.Head>
							<Portlet.Body>
								<div className='row'>
									<FormField
										name='minLengthOverAll'
										label='Minimum Length Overall'
										inFormDesign={false}
										id={0}
										colLg={4}>
										<LengthInputGroup
											ft={minLoaFt}
											ftOnChange={setMinLoaFt}
											inch={minLoaIn}
											inchOnChange={setMinLoaIn}
											placeHolder='Minimum Length Overall'
										/>
									</FormField>
									<FormField
										name='warnLengthOverAll'
										label='Warn Length Overall'
										inFormDesign={false}
										id={0}
										colLg={4}>
										<LengthInputGroup
											ft={warnLoaFt}
											ftOnChange={setWarnLoaFt}
											inch={warnLoaIn}
											inchOnChange={setWarnLoaIn}
											placeHolder='Warn Length Overall'
										/>
									</FormField>
									<FormField
										name='maxLengthOverAll'
										label='Maximum Length Overall'
										inFormDesign={false}
										id={0}
										colLg={4}>
										<LengthInputGroup
											ft={maxLoaFt}
											ftOnChange={setMaxLoaFt}
											inch={maxLoaIn}
											inchOnChange={setMaxLoaIn}
											placeHolder='Maximum Length Overall'
										/>
									</FormField>
								</div>
							</Portlet.Body>
						</Portlet>
					</Section.Body>
					<Section.Body>
						<Portlet border className='sdms-portlet--head-noborder'>
							<Portlet.Head>
								<Portlet.HeadLabelTitle>Beam</Portlet.HeadLabelTitle>
							</Portlet.Head>
							<Portlet.Body>
								<div className='row'>
									<FormField
										name='minBeamFt'
										label='Minimum Beam'
										inFormDesign={false}
										id={0}
										colLg={4}>
										<LengthInputGroup
											ft={minBeamFt}
											ftOnChange={setMinBeamFt}
											inch={minBeamIn}
											inchOnChange={setMinBeamIn}
											placeHolder='Minimum Beam'
										/>
									</FormField>
									<FormField
										name='warnBeamFt'
										label='Warn Beam'
										inFormDesign={false}
										id={0}
										colLg={4}>
										<LengthInputGroup
											ft={warnBeamFt}
											ftOnChange={setWarnBeamFt}
											inch={warnBeamIn}
											inchOnChange={setWarnBeamIn}
											placeHolder='Warn Beam'
										/>
									</FormField>
									<FormField
										name='maxBeamFt'
										label='Maximum Beam'
										inFormDesign={false}
										id={0}
										colLg={4}>
										<LengthInputGroup
											ft={maxBeamFt}
											ftOnChange={setMaxBeamFt}
											inch={maxBeamIn}
											inchOnChange={setMaxBeamIn}
											placeHolder='Maximum Beam'
										/>
									</FormField>
								</div>
							</Portlet.Body>
						</Portlet>
					</Section.Body>
					<Section.Body>
						<Portlet border className='sdms-portlet--head-noborder'>
							<Portlet.Head>
								<Portlet.HeadLabelTitle>Height</Portlet.HeadLabelTitle>
							</Portlet.Head>
							<Portlet.Body>
								<div className='row'>
									<FormField
										name='minHeightFt'
										label='Minimum Height'
										inFormDesign={false}
										id={0}
										colLg={4}>
										<LengthInputGroup
											ft={minHeightFt}
											ftOnChange={setMinHeightFt}
											inch={minHeightIn}
											inchOnChange={setMinHeightIn}
											placeHolder='Minimum Height'
										/>
									</FormField>
									<FormField
										name='warnHeightFt'
										label='Warn Height'
										inFormDesign={false}
										id={0}
										colLg={4}>
										<LengthInputGroup
											ft={warnHeightFt}
											ftOnChange={setWarnHeightFt}
											inch={warnHeightIn}
											inchOnChange={setWarnHeightIn}
											placeHolder='Warn Height'
										/>
									</FormField>
									<FormField
										name='maxHeightFt'
										label='Maximum Height'
										inFormDesign={false}
										id={0}
										colLg={4}>
										<LengthInputGroup
											ft={maxHeightFt}
											ftOnChange={setMaxHeightFt}
											inch={maxHeightIn}
											inchOnChange={setMaxHeightIn}
											placeHolder='Maximum Height'
										/>
									</FormField>
								</div>
							</Portlet.Body>
						</Portlet>
					</Section.Body>
					<Section.Body>
						<Portlet border className='sdms-portlet--head-noborder'>
							<Portlet.Head>
								<Portlet.HeadLabelTitle>Square Feet</Portlet.HeadLabelTitle>
							</Portlet.Head>
							<Portlet.Body>
								<div className='row'>
									<FormField
										name='minSqft'
										label='Minimum Square Feet'
										inFormDesign={false}
										id={0}
										colLg={4}>
										<Input
											type='number'
											withOutSpin
											min={0}
											placeholder='Minimum Square Feet'
											value={minSqft}
											onChange={minSqftOnChange}
											pattern={process.env.REACT_APP_INTEGER_PATTERN}
											append='sqft'
										/>
									</FormField>
									<FormField
										name='warnSqft'
										label='Warn Square Feet'
										inFormDesign={false}
										id={0}
										colLg={4}>
										<Input
											type='number'
											withOutSpin
											min={0}
											placeholder='Warn Square Feet'
											value={warnSqft}
											onChange={warnSqftOnChange}
											pattern={process.env.REACT_APP_INTEGER_PATTERN}
											append='sqft'
										/>
									</FormField>
									<FormField
										name='maxSqft'
										label='Maximum Square Feet'
										inFormDesign={false}
										id={0}
										colLg={4}>
										<Input
											type='number'
											withOutSpin
											min={0}
											placeholder='Maximum Square Feet'
											value={maxSqft}
											onChange={maxSqftOnChange}
											pattern={process.env.REACT_APP_INTEGER_PATTERN}
											append='sqft'
										/>
									</FormField>
								</div>
							</Portlet.Body>
						</Portlet>
					</Section.Body>
				</Section>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						icon='Error-circle'
						size='sm'
						elevate
						text='Cancel'
						onClick={onCancel}
						disabled={isSubmitting}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='info'
						icon='Clipboard-list'
						text='Generate'
						size='sm'
						elevate
						onClick={submit}
						disabled={isSubmitting}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
SpacesGenerateForm.propTypes = {
	onCancel: PropTypes.func,
	afterSubmit: PropTypes.func,
};
SpacesGenerateForm.defaultProps = {
	onCancel: () => {},
	afterSubmit: () => {},
};

export default SpacesGenerateForm;
