import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import SpaceBlockoutForm from './SpaceBlockoutForm';

const SpaceBlockoutEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='unitBlackouts'
			fields={<SpaceBlockoutForm />}
			lists={['campgroundUnits', 'enumUnitBlockoutTypes', 'campgroundBookingTypes']}
			pageTitle={pages.campground.settings.spaceBlockouts.text}
			icon={pages.campground.settings.spaceBlockouts.icon}
		/>
	);
};

SpaceBlockoutEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default SpaceBlockoutEdit;
