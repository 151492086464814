import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Container = ({ id, className, children }) => {
	return (
		<div
			id={id}
			className={classNames('sdms-grid', 'sdms-grid--hor', 'sdms-grid--root', className)}>
			{children}
		</div>
	);
};
Container.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};
Container.defaultProps = {
	id: null,
	className: null,
};

const Page = ({ id, className, children }) => {
	return (
		<div
			id={id}
			className={classNames(
				'sdms-grid__item',
				'sdms-grid__item--fluid',
				'sdms-grid',
				'sdms-grid--ver',
				'sdms-page',
				className
			)}>
			{children}
		</div>
	);
};
Page.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};
Page.defaultProps = {
	id: null,
	className: null,
};

const Wrapper = ({ id, className, children, addWrapperClass }) => {
	return (
		<div
			id={id}
			className={classNames(
				'sdms-grid__item',
				'sdms-grid__item--fluid',
				'sdms-grid',
				'sdms-grid--hor',
				className,
				{
					'sdms-wrapper': addWrapperClass,
				}
			)}>
			{children}
		</div>
	);
};
Wrapper.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	addWrapperClass: PropTypes.bool,
};
Wrapper.defaultProps = {
	id: 'sdms_wrapper',
	className: null,
	addWrapperClass: true,
};

const Content = ({ id, className, children }) => {
	return (
		<div
			id={id}
			className={classNames(
				'sdms-content',
				'sdms-grid__item',
				'sdms-grid__item--fluid',
				'sdms-grid sdms-grid--hor',
				className
			)}>
			{children}
		</div>
	);
};
Content.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};
Content.defaultProps = {
	id: 'sdms_content',
	className: null,
};

Container.Page = Page;
Container.Wrapper = Wrapper;
Container.Content = Content;

export default Container;
