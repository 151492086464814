import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../app/contexts/UserContext';
import usePages from '../../../utils/hooks/usePages';
import usePaymentGateway, { parsePaymentData } from '../../../utils/hooks/usePaymentGateway';
import { paymentTypes as _paymentTypes } from '../../../utils/constants/constants';
import apiCall, { customSubmits } from '../../../utils/helpers/apiCall';
import useOutlet from '../../../utils/hooks/useOutlet';

import FormContainer from '../../reusables/template/FormContainer';
import RefundForm from './RefundForm';

const RefundEdit = ({ match }) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const [outletKey] = useOutlet(true);

	const [tokenizePayment] = usePaymentGateway(userContext.data.selectedOutlet);

	const submit = (data, formData, onSuccess, onFail, filters, lists, e) => {
		apiCall(
			data.current.id ? 'PUT' : 'POST',
			customSubmits.refunds || 'refunds',
			res => {
				onSuccess(res, e, data.current.id);
			},
			err => onFail(err, true),
			data.current.id || '',
			formData,
			filters
		);
	};

	const customSubmit = (data, formData, onSuccess, onFail, filters, lists, e) => {
		if (data.current.id === 0) {
			tokenizePayment(
				data.current.type,
				parsePaymentData(
					data.current.type,
					data.current.type === _paymentTypes.CHECK
						? formData.achAccount
						: formData.creditCard
				),
				tokenizedPayment => {
					if (tokenizedPayment) {
						if (data.current.type === _paymentTypes.CREDIT_CARD)
							formData.creditCard = { ...formData.creditCard, ...tokenizedPayment };

						if (data.current.type === _paymentTypes.CHECK)
							formData.achAccount = { ...formData.achAccount, ...tokenizedPayment };
					}

					submit(data, formData, onSuccess, onFail, filters, lists, e);
				},
				err => onFail(err.toString(), true),
				lists.paymentMethods
			);
		} else submit(data, formData, onSuccess, onFail, filters, lists, e);
	};

	return (
		<FormContainer
			key={outletKey}
			dataId={match.params.id}
			pageTitle={pages.crm.refunds.text}
			dataName='refunds'
			lists={['paymentTypes', 'paymentMethods']}
			fields={<RefundForm />}
			customSubmit={customSubmit}
			isEditable={userContext.hasPermission('edit_refunds')}
		/>
	);
};

RefundEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default RefundEdit;
