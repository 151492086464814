import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Portlet from './Portlet';
import SVGIcon from '../element/SVGIcon';

const IconBox = ({
	className,
	design,
	icon,
	iconSize,
	title,
	children,
	animate,
	onClick,
	noPermission,
}) => {
	return (
		<Portlet
			className={classNames(
				'sdms-iconbox',
				{ [`sdms-iconbox--${design}`]: design },
				{ [`sdms-iconbox--animate-${animate}`]: animate },
				className
			)}
			noPermission={noPermission}
			onClick={onClick}>
			<Portlet.Body>
				<div className='sdms-iconbox__body'>
					<div className='sdms-iconbox__icon'>
						<SVGIcon name={icon} size={iconSize} className='sdms-svg-icon--fill' />
					</div>
					<div className='sdms-iconbox__desc'>
						<h3 className='sdms-iconbox__title'>
							<span className='sdms-link'>{title}</span>
						</h3>
						<div className='sdms-iconbox__content'>{children}</div>
					</div>
				</div>
			</Portlet.Body>
		</Portlet>
	);
};
IconBox.propTypes = {
	animate: PropTypes.oneOf(['slower', 'slow', 'fast', 'faster']),
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	className: PropTypes.string,
	design: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'info',
		'primary',
		'secondary',
		'success',
		'warning',
	]),
	icon: PropTypes.string.isRequired,
	iconSize: PropTypes.number,
	title: PropTypes.string,
	onClick: PropTypes.func,
	noPermission: PropTypes.bool,
};
IconBox.defaultProps = {
	animate: 'slow',
	children: null,
	className: null,
	design: 'info',
	iconSize: null,
	title: null,
	onClick: null,
	noPermission: null,
};

export default IconBox;
