import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import { priceFormatter } from '../../../utils/helpers/helper';
import { discountCalculations } from '../../../utils/constants/constants';
import forms from '../../forms';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import Badge from '../../reusables/element/Badge';
import NullBadge from '../../reusables/design/NullBadge';

const AmountCell = ({ data }) => (
	<Badge design='info' isInline isUnified size='lg' fontWeight='boldest'>
		{data.calculation.value === discountCalculations.PERCENT && `${data.amount}%`}
		{data.calculation.value === discountCalculations.PER_QUANTITY &&
			`Quantity/${priceFormatter(data.amount)}`}
		{data.calculation.value === discountCalculations.FIXED && priceFormatter(data.amount)}
	</Badge>
);
AmountCell.propTypes = {
	data: PropTypes.shape({
		calculation: PropTypes.object,
		amount: PropTypes.number,
	}),
};
AmountCell.defaultProps = {
	data: {
		calculation: { value: 'percent' },
		amount: 0,
	},
};

const EligibleProductsCell = ({ data }) => {
	if (data.eligibleProducts.length === 0) return <NullBadge />;

	return data.eligibleProducts.map(p => p.name).join(', ');
};
EligibleProductsCell.propTypes = {
	data: PropTypes.shape({
		eligibleProducts: PropTypes.arrayOf(PropTypes.object),
	}),
};
EligibleProductsCell.defaultProps = {
	data: {
		eligibleProducts: [],
	},
};

const AutomaticDiscountsCell = ({ data }) => {
	if (data.automaticDiscounts.length === 0) return <NullBadge />;

	return data.automaticDiscounts.map(p => p.displayName).join(', ');
};
AutomaticDiscountsCell.propTypes = {
	data: PropTypes.shape({
		automaticDiscounts: PropTypes.arrayOf(PropTypes.object),
	}),
};
AutomaticDiscountsCell.defaultProps = {
	data: {
		automaticDiscounts: [],
	},
};

const DiscountList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.accounting.default.text, path: pages.accounting.dashboard.path },
			{ title: pages.accounting.settings.text, path: pages.accounting.settings.path },
			{ title: pages.accounting.settings.discount.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.accounting.settings.discount.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='discounts'
			title={pages.accounting.settings.discount.text}
			history={history}
			icon={pages.accounting.settings.discount.icon}
			forms={forms.accounting.settings.discount}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col label='Amount' cellComponent={<AmountCell />} sortable='amount' />
				<List.Col label='Eligible Products' cellComponent={<EligibleProductsCell />} />
				<List.Col label='Automatic Discounts' cellComponent={<AutomaticDiscountsCell />} />
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
DiscountList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default DiscountList;
