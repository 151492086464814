import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Portlet from '../../reusables/layout/Portlet';
import usePages from '../../../utils/hooks/usePages';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Loading from '../../reusables/template/Loading';
import Input from '../../reusables/field/Input';
import Radio from '../../reusables/field/Radio';
import Notes from '../../reusables/element/Notes';
import HeaderContext from '../../../app/contexts/HeaderContext';
import useField from '../../../utils/hooks/useField';
import { maxLength, numeric, required } from '../../../utils/helpers/validation';
import { numberParser } from '../../../utils/helpers/helper';
import Selects from '../../reusables/field/Selects';
import UserContext from '../../../app/contexts/UserContext';
import Button from '../../reusables/element/Button';
import { bookingPeriods, pricingTypes } from '../../../utils/constants/constants';
import Toggle from '../../reusables/field/Toggle';
import { modules } from '../../../utils/helpers/apiCall';
import useFeet from '../../../utils/hooks/useFeet';
import LengthInputGroup from '../../reusables/field/LengthInputGroup';

const RatePlanForm = ({
	isLoading,
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	onFormChange,
	enumBookingPeriods,
	policies,
	enumPricingTypes,
	submit,
	submitButtonAttr,
}) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const [showMinDurationInput, setShowMinDurationInput] = useState(false);

	const [showMaxDurationInput, setShowMaxDurationInput] = useState(false);

	const [showMinBookingInAdvanceInput, setShowMinBookingInAdvanceInput] = useState(false);

	const [showMaxBookingInAdvanceInput, setShowMaxBookingInAdvanceInput] = useState(false);

	const [showMinLoaInput, setShowMinLoaInput] = useState(false);

	const [showMaxLoaInput, setShowMaxLoaInput] = useState(false);

	const [showMinBeamInput, setShowMinBeamInput] = useState(false);

	const [showMaxBeamInput, setShowMaxBeamInput] = useState(false);

	const [showMinSqftInput, setHowMinSqftInput] = useState(false);

	const [showMaxSqftInput, setShowMaxSqftInput] = useState(false);

	const [showMinHeightInput, setShowMinHeightInput] = useState(false);

	const [showMaxHeightInput, setShowMaxHeightInput] = useState(false);

	const [showMinUnitsInput, setShowMinUnitsInput] = useState(false);

	const [showMaxUnitsInput, setShowMaxUnitsInput] = useState(false);

	const [showMinPricingLoaInput, setShowMinPricingLoaInput] = useState(false);

	const userContext = useContext(UserContext);

	const campgroundModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.CAMPGROUND)
	);

	const [, minPricingLoaOnChange] = useField(
		data,
		'minPricingLoa',
		onFormChange,
		[],
		null,
		numberParser()
	);

	const [
		minPricingLoaFt,
		setMinPricingLoaFt,
		minPricingLoaIn,
		setMinPricingLoaIn,
		resetMinPricingLoa,
	] = useFeet(data.minPricingLoa, minPricingLoaOnChange, isLoading);

	const [
		internalName,
		internalNameOnChange,
		internalNameValRes,
		internalNameShowVal,
		setInternalNameShowVal,
	] = useField(data, 'internalName', onFormChange, [required, maxLength()], '', null, setTitle);

	const [
		externalName,
		externalNameOnChange,
		externalNameValRes,
		externalNameShowVal,
		setExternalNameShowVal,
	] = useField(data, 'externalName', onFormChange, [required, maxLength()], '', null);
	const [
		bookingPeriod,
		bookingPeriodOnChange,
		bookingPeriodValRes,
		bookingPeriodShowVal,
		setBookingPeriodShowVal,
	] = useField(data, 'bookingPeriod', onFormChange, [required], null);

	const [policy, policyOnChange, policyValRes, policyShowVal, setPolicyShowVal] = useField(
		data,
		'policy',
		onFormChange,
		[],
		{}
	);

	const [pricingType, pricingTypeOnChange] = useField(data, 'pricingType', onFormChange);

	const [costPer, costPerOnChange, costPerValRes, costPerShowVal, setCostPerShowVal] = useField(
		data,
		'costPer',
		onFormChange,
		[required, numeric],
		1,
		numberParser(true, '')
	);

	const [
		minDuration,
		minDurationOnChange,
		minDurationValRes,
		minDurationShowVal,
		setMinDurationShowVal,
	] = useField(data, 'minDuration', onFormChange, [numeric], '', numberParser(false));

	const [
		maxDuration,
		maxDurationOnChange,
		maxDurationValRes,
		maxDurationShowVal,
		setMaxDurationShowVal,
	] = useField(data, 'maxDuration', onFormChange, [numeric], '', numberParser(false));

	const [
		minBookInAdvance,
		minBookInAdvanceOnChange,
		minBookInAdvanceValRes,
		minBookInAdvanceShowVal,
		setMinBookInAdvanceShowVal,
	] = useField(data, 'minBookInAdvance', onFormChange, [numeric], '', numberParser(false));

	const [
		maxBookInAdvance,
		maxBookInAdvanceOnChange,
		maxBookInAdvanceValRes,
		maxBookInAdvanceShowVal,
		setMaxBookInAdvanceShowVal,
	] = useField(data, 'maxBookInAdvance', onFormChange, [numeric], '', numberParser(false));

	const [isDiscountRatePlan, isDiscountRatePlanOnChange] = useField(
		data,
		'isDiscountRatePlan',
		onFormChange,
		[],
		false
	);

	const [minUnits, minUnitsOnChange] = useField(
		data,
		'minUnits',
		onFormChange,
		[],
		'',
		numberParser(true)
	);

	const [maxUnits, maxUnitsOnChange] = useField(
		data,
		'maxUnits',
		onFormChange,
		[],
		'',
		numberParser(true)
	);

	const [, minLoaOnChange] = useField(data, 'minLoa', onFormChange, [], null, numberParser());

	const [minLoaFt, setMinLoaFt, minLoaIn, setMinLoaIn, resetMinLoa] = useFeet(
		data.minLoa,
		minLoaOnChange,
		isLoading
	);

	const [, maxLoaOnChange] = useField(data, 'maxLoa', onFormChange, [], null, numberParser());

	const [maxLoaFt, setMaxLoaFt, maxLoaIn, setMaxLoaIn, resetMaxLoa] = useFeet(
		data.maxLoa,
		maxLoaOnChange,
		isLoading
	);

	const [, minBeamOnChange] = useField(data, 'minBeam', onFormChange, [], null, numberParser());

	const [minBeamFt, setMinBeamFt, minBeamIn, setMinBeamIn, resetMinBeam] = useFeet(
		data.minBeam,
		minBeamOnChange,
		isLoading
	);

	const [, maxBeamOnChange] = useField(data, 'maxBeam', onFormChange, [], null, numberParser());

	const [maxBeamFt, setMaxBeamFt, maxBeamIn, setMaxBeamIn, resetMaxBeam] = useFeet(
		data.maxBeam,
		maxBeamOnChange,
		isLoading
	);

	const [minSqft, minSqftOnChange] = useField(
		data,
		'minSqft',
		onFormChange,
		[],
		null,
		numberParser()
	);

	const [maxSqft, maxSqftOnChange] = useField(
		data,
		'maxSqft',
		onFormChange,
		[],
		null,
		numberParser()
	);

	const [, minHeightOnChange] = useField(
		data,
		'minHeight',
		onFormChange,
		[],
		null,
		numberParser()
	);

	const [minHeightFt, setMinHeightFt, minHeightIn, setMinHeightIn, resetMinHeight] = useFeet(
		data.minHeight,
		minHeightOnChange,
		isLoading
	);

	const [, maxHeightOnChange] = useField(
		data,
		'maxHeight',
		onFormChange,
		[],
		null,
		numberParser()
	);

	const [maxHeightFt, setMaxHeightFt, maxHeightIn, setMaxHeightIn, resetMaxHeight] = useFeet(
		data.maxHeight,
		maxHeightOnChange,
		isLoading
	);

	const bookingPeriodFinder = () => {
		if (bookingPeriod) {
			if (bookingPeriod.value === bookingPeriods.HOURLY) return 'Hour(s)';
			if (bookingPeriod.value === bookingPeriods.NIGHTLY) return 'Night(s)';
			if (
				bookingPeriod.value === bookingPeriods.LONG_TERM ||
				bookingPeriod.value === bookingPeriods.SEASONAL
			)
				return 'Month(s)';
		}
		return 'Day(s)';
	};

	const getPricingTypeLabel = _pricingType => {
		let unit = 'unit';

		if (bookingPeriod) {
			switch (bookingPeriod.value) {
				case bookingPeriods.HOURLY:
					unit = 'hour';
					break;
				case bookingPeriods.DAILY:
					unit = 'day';
					break;
				case bookingPeriods.NIGHTLY:
					unit = 'night';
					break;
				case bookingPeriods.SEASONAL:
					unit = 'season';
					break;
				default:
					unit = 'unit';
					break;
			}
		}

		if (
			_pricingType.value === pricingTypes.PER_UNIT ||
			_pricingType.value === pricingTypes.NUMBER_OF_UNIT ||
			_pricingType.value === pricingTypes.PER_UNIT_PER_LOA ||
			_pricingType.value === pricingTypes.PER_UNIT_PER_SQFT
		)
			return _pricingType.value.replace('unit', unit);

		return _pricingType.value;
	};

	useEffect(() => {
		if (isSubmitted) {
			setInternalNameShowVal();
			setExternalNameShowVal();
			setBookingPeriodShowVal();
			setCostPerShowVal();
			setMinDurationShowVal();
			setMaxDurationShowVal();
			setMinBookInAdvanceShowVal();
			setMaxBookInAdvanceShowVal();
		}
	}, [
		isSubmitted,
		setInternalNameShowVal,
		setExternalNameShowVal,
		setBookingPeriodShowVal,
		setCostPerShowVal,
		setMinDurationShowVal,
		setMaxDurationShowVal,
		setMinBookInAdvanceShowVal,
		setMaxBookInAdvanceShowVal,
	]);

	useEffect(() => {
		setIsValid(
			internalNameValRes.isValid &&
				externalNameValRes.isValid &&
				bookingPeriodValRes.isValid &&
				costPerValRes.isValid &&
				minDurationValRes.isValid &&
				maxDurationValRes.isValid &&
				minBookInAdvanceValRes.isValid &&
				maxBookInAdvanceValRes.isValid
		);
	}, [
		internalNameValRes.isValid,
		externalNameValRes.isValid,
		bookingPeriodValRes.isValid,
		costPerValRes.isValid,
		minDurationValRes.isValid,
		maxDurationValRes.isValid,
		minBookInAdvanceValRes.isValid,
		maxBookInAdvanceValRes.isValid,
		setIsValid,
	]);
	useEffect(() => {
		data.module = campgroundModule.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!isLoading) {
			if (data.minDuration) setShowMinDurationInput(true);
			if (data.maxDuration) setShowMaxDurationInput(true);
			if (data.minBookInAdvance) setShowMinBookingInAdvanceInput(true);
			if (data.maxBookInAdvance) setShowMaxBookingInAdvanceInput(true);
			if (data.minLoa) setShowMinLoaInput(true);
			if (data.maxLoa) setShowMaxLoaInput(true);
			if (data.minBeam) setShowMinBeamInput(true);
			if (data.maxBeam) setShowMaxBeamInput(true);
			if (data.minSqft) setHowMinSqftInput(true);
			if (data.maxSqft) setShowMaxSqftInput(true);
			if (data.minHeight) setShowMinHeightInput(true);
			if (data.maxHeight) setShowMaxHeightInput(true);
			if (data.minUnits !== null) setShowMinUnitsInput(true);
			if (data.maxUnits !== null) setShowMaxUnitsInput(true);
			if (data.minPricingLoa) setShowMinPricingLoaInput(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
			{ title: pages.campground.settings.text, path: pages.campground.settings.path },
			{
				title: pages.campground.settings.ratePlans.text,
				path: pages.campground.settings.ratePlans.path,
			},
			{
				title: internalName || `New ${pages.campground.settings.ratePlans.text}`,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(
			internalName || `New ${pages.campground.settings.ratePlans.text}`
		);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [internalName]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<FormGroup isLast>
					<div className='col-12'>
						<Notes>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='internalName'
										label='What do you want to call this rate plan?'
										description={`This name is just for you. We won't show it to customers using Online Booking.`}
										id={data.id}
										valRes={internalNameValRes}
										showValidation={internalNameShowVal}
										inFormDesign={false}
										colLg={6}>
										<Input
											type='text'
											value={internalName}
											onChange={internalNameOnChange}
											onBlur={setInternalNameShowVal}
										/>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='externalName'
										label='How do you want customers to see this rate plan?'
										description='This name will be visible to users when using Online Booking.'
										id={data.id}
										valRes={externalNameValRes}
										showValidation={externalNameShowVal}
										inFormDesign={false}
										colLg={6}>
										<Input
											type='text'
											value={externalName}
											onChange={externalNameOnChange}
											onBlur={setExternalNameShowVal}
										/>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='bookingPeriod'
										label='How do you want to price this rate plan?'
										description='This will create cost break points. For example, $200 for 4 hours will calculate cost at $50/hour.'
										id={data.id}
										valRes={bookingPeriodValRes}
										showValidation={bookingPeriodShowVal}
										inFormDesign={false}
										colLg={6}>
										<Selects
											options={enumBookingPeriods}
											placeholder='Booking Periods (Required)'
											value={bookingPeriod}
											onChange={e => {
												policyOnChange({
													target: { value: null },
												});

												costPerOnChange({ target: { value: 1 } });

												maxDurationOnChange({
													target: { value: null },
												});
												minDurationOnChange({
													target: { value: null },
												});
												pricingTypeOnChange({
													target: {
														value: e.target.value
															? enumPricingTypes.filter(
																	ept =>
																		e.target.value &&
																		ept.bookingPeriods.findIndex(
																			bp =>
																				bp.value ===
																				e.target.value.value
																		) > -1
															  )[0]
															: null,
													},
												});

												bookingPeriodOnChange(e);
											}}
											onBlur={setBookingPeriodShowVal}
											displayKey='value'
										/>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='policy'
										label='Which policy do you want to use for this rate plan?'
										id={data.id}
										valRes={policyValRes}
										showValidation={policyShowVal}
										inFormDesign={false}
										colLg={6}>
										<Selects
											options={policies.filter(
												pol =>
													bookingPeriod &&
													pol.bookingPeriod.value === bookingPeriod.value
											)}
											placeholder='Booking Policy'
											value={policy}
											onChange={e => {
												policyOnChange({
													target: { value: e.target.value },
												});
											}}
											onBlur={setPolicyShowVal}
											displayKey='name'
										/>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='costPer'
										label='How do you want to set the price for this rate plan?'
										description='This will create cost break points. For example, $200 for 4 hours will calculate cost at $50/hour.'
										id={data.id}
										valRes={costPerValRes}
										showValidation={costPerShowVal}
										inFormDesign={false}>
										<Radio.Container isInline>
											{enumPricingTypes
												.filter(
													ept =>
														bookingPeriod &&
														ept.bookingPeriods.findIndex(
															bp => bp.value === bookingPeriod.value
														) > -1
												)
												.map(ept => (
													<Radio
														key={ept.id}
														checked={
															pricingType && ept.id === pricingType.id
														}
														id={`pricingType${ept.id.toString()}`}
														name='pricingType'
														content={getPricingTypeLabel(ept)}
														className='sdms-radio--primary'
														onChange={() => {
															pricingTypeOnChange({
																target: { value: ept },
															});
															if (
																ept.value !==
																pricingTypes.NUMBER_OF_UNIT
															)
																costPerOnChange({
																	target: { value: 1 },
																});
														}}
													/>
												))}

											{pricingType &&
												pricingType.value ===
													pricingTypes.NUMBER_OF_UNIT && (
													<div className='row'>
														<div className='col-lg-12'>
															<Input
																type='text'
																value={costPer}
																onChange={costPerOnChange}
																onBlur={setCostPerShowVal}
																prepend='Total Price for'
																append={bookingPeriodFinder()}
																pattern={
																	process.env
																		.REACT_APP_INTEGER_PATTERN
																}
															/>
														</div>
													</div>
												)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							{(pricingType?.value === pricingTypes.PER_UNIT_PER_LOA ||
								pricingType?.value === pricingTypes.PER_MONTH_PER_LOA ||
								pricingType?.value === pricingTypes.PER_YEAR_PER_LOA) && (
								<Notes.Item>
									<Loading isLoading={isLoading}>
										<FormField
											name='minPricingLoad'
											label='Want to set a minimum pricing length overall for this rate plan?'
											description='This is the minimum length overall with this rate plan can be rented'
											id={data.id}
											inFormDesign={false}>
											<Radio.Container isInline>
												<Radio
													checked={!showMinPricingLoaInput}
													id='showMinPricingLengthOverAllInputNo'
													name='showMinPricingLengthOverAllInput'
													content='No'
													className='sdms-radio--primary'
													onChange={() => {
														setShowMinPricingLoaInput(false);
														resetMinPricingLoa();
													}}
												/>
												<Radio
													checked={showMinPricingLoaInput}
													id='showMinPricingLengthOverAllInputYes'
													name='showMinPricingLengthOverAllInput'
													content='Yes'
													className='sdms-radio--primary'
													onChange={() => setShowMinPricingLoaInput(true)}
												/>
												{showMinPricingLoaInput && (
													<div className='row'>
														<div className='col-lg-6'>
															<LengthInputGroup
																ft={minPricingLoaFt}
																ftOnChange={setMinPricingLoaFt}
																inch={minPricingLoaIn}
																inchOnChange={setMinPricingLoaIn}
																placeHolder='Minimum Length Overall'
															/>
														</div>
													</div>
												)}
											</Radio.Container>
										</FormField>
									</Loading>
								</Notes.Item>
							)}
							{bookingPeriod === null ||
							(bookingPeriod.value !== bookingPeriods.LONG_TERM &&
								bookingPeriod.value !== bookingPeriods.SEASONAL) ? (
								<>
									<Notes.Item>
										<Loading isLoading={isLoading}>
											<FormField
												name='minDuration'
												label='Want to set a minimum rental length of time for this rate plan?'
												description='This is the minimum amount of time an item with this rate plan can be rented'
												id={data.id}
												valRes={minDurationValRes}
												showValidation={minDurationShowVal}
												inFormDesign={false}>
												<Radio.Container isInline>
													<Radio
														checked={!showMinDurationInput}
														id='showMinDurationInputNo'
														name='showMinDurationInput'
														content='No'
														className='sdms-radio--primary'
														onChange={() => {
															setShowMinDurationInput(false);
															minDurationOnChange({
																target: {
																	value: null,
																},
															});
														}}
													/>
													<Radio
														checked={showMinDurationInput}
														id='showMinDurationInputYes'
														name='showMinDurationInput'
														content='Yes'
														className='sdms-radio--primary'
														onChange={() =>
															setShowMinDurationInput(true)
														}
													/>
													{showMinDurationInput && (
														<div className='row'>
															{bookingPeriod &&
															bookingPeriod.value === 'Hourly' ? (
																<>
																	<div className='col-lg-6'>
																		<Input
																			type='text'
																			value={
																				minDuration &&
																				minDuration !== ''
																					? parseInt(
																							parseFloat(
																								minDuration
																							) / 60,
																							10
																					  ).toString()
																					: 0
																			}
																			onChange={e => {
																				minDurationOnChange(
																					{
																						target: {
																							value:
																								e
																									.target
																									.value *
																									60 +
																								(minDuration ===
																								''
																									? 0
																									: minDuration %
																									  60),
																						},
																					}
																				);
																			}}
																			onBlur={
																				setMinDurationShowVal
																			}
																			append={bookingPeriodFinder()}
																			pattern={
																				process.env
																					.REACT_APP_INTEGER_PATTERN
																			}
																		/>
																	</div>
																	<div className='col-lg-6'>
																		<Selects
																			value={
																				minDuration === ''
																					? {
																							id: 0,
																							name: 0,
																					  }
																					: {
																							id:
																								minDuration %
																								60,
																							name:
																								minDuration %
																								60,
																					  }
																			}
																			onChange={e =>
																				minDurationOnChange(
																					{
																						target: {
																							value:
																								minDuration -
																								(minDuration %
																									60) +
																								parseFloat(
																									e
																										.target
																										.value
																										? e
																												.target
																												.value
																												.id
																										: 0
																								),
																						},
																					}
																				)
																			}
																			options={[
																				{ id: 0, name: 0 },
																				{
																					id: 15,
																					name: 15,
																				},
																				{
																					id: 30,
																					name: 30,
																				},
																				{
																					id: 45,
																					name: 45,
																				},
																			]}
																			append='Minutes'
																		/>
																	</div>
																</>
															) : (
																<div className='col-lg-6'>
																	<Input
																		type='text'
																		value={minDuration}
																		onChange={
																			minDurationOnChange
																		}
																		onBlur={
																			setMinDurationShowVal
																		}
																		append={bookingPeriodFinder()}
																	/>
																</div>
															)}
														</div>
													)}
												</Radio.Container>
											</FormField>
										</Loading>
									</Notes.Item>
									<Notes.Item>
										<Loading isLoading={isLoading}>
											<FormField
												name='maxDuration'
												label='Want to set a maximum rental length of time for this rate plan?'
												description='This is the maximum amount of time an item with this rate plan can be rented'
												id={data.id}
												valRes={maxDurationValRes}
												showValidation={maxDurationShowVal}
												inFormDesign={false}>
												<Radio.Container isInline>
													<Radio
														checked={!showMaxDurationInput}
														id='showMaxDurationInputNo'
														name='showMaxDurationInput'
														content='No'
														className='sdms-radio--primary'
														onChange={() => {
															setShowMaxDurationInput(false);
															maxDurationOnChange({
																target: {
																	value: null,
																},
															});
														}}
													/>
													<Radio
														checked={showMaxDurationInput}
														id='showMaxDurationInputYes'
														name='showMaxDurationInput'
														content='Yes'
														className='sdms-radio--primary'
														onChange={() =>
															setShowMaxDurationInput(true)
														}
													/>
													{showMaxDurationInput && (
														<div className='row'>
															{bookingPeriod &&
															bookingPeriod.value === 'Hourly' ? (
																<>
																	<div className='col-lg-6'>
																		<Input
																			type='text'
																			value={
																				maxDuration &&
																				maxDuration !== ''
																					? parseInt(
																							parseFloat(
																								maxDuration
																							) / 60,
																							10
																					  ).toString()
																					: ''
																			}
																			onChange={e => {
																				maxDurationOnChange(
																					{
																						target: {
																							value:
																								e
																									.target
																									.value *
																									60 +
																								(maxDuration ===
																								''
																									? 0
																									: maxDuration %
																									  60),
																						},
																					}
																				);
																			}}
																			onBlur={
																				setMaxDurationShowVal
																			}
																			append='Hours'
																			pattern={
																				process.env
																					.REACT_APP_INTEGER_PATTERN
																			}
																		/>
																	</div>
																	<div className='col-lg-6'>
																		<Selects
																			value={
																				maxDuration === ''
																					? {
																							id: 0,
																							name: 0,
																					  }
																					: {
																							id:
																								maxDuration %
																								60,
																							name:
																								maxDuration %
																								60,
																					  }
																			}
																			onChange={e =>
																				maxDurationOnChange(
																					{
																						target: {
																							value:
																								maxDuration -
																								(maxDuration %
																									60) +
																								parseFloat(
																									e
																										.target
																										.value
																										? e
																												.target
																												.value
																												.id
																										: 0
																								),
																						},
																					}
																				)
																			}
																			options={[
																				{ id: 0, name: 0 },
																				{
																					id: 15,
																					name: 15,
																				},
																				{
																					id: 30,
																					name: 30,
																				},
																				{
																					id: 45,
																					name: 45,
																				},
																			]}
																			append='Minutes'
																		/>
																	</div>
																</>
															) : (
																<div className='col-lg-6'>
																	<Input
																		type='text'
																		value={maxDuration}
																		onChange={
																			maxDurationOnChange
																		}
																		onBlur={
																			setMaxDurationShowVal
																		}
																		append='Days'
																	/>
																</div>
															)}
														</div>
													)}
												</Radio.Container>
											</FormField>
										</Loading>
									</Notes.Item>
								</>
							) : (
								''
							)}
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='minBookInAdvance'
										label='What is the minimum number of days before check-in can guests book this rate plan?'
										id={data.id}
										valRes={minBookInAdvanceValRes}
										showValidation={minBookInAdvanceShowVal}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMinBookingInAdvanceInput}
												id='showMinBookingInAdvanceInputNo'
												name='showMinBookingInAdvanceInput'
												content='Any Time'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMinBookingInAdvanceInput(false);
													minBookInAdvanceOnChange({
														target: { value: null },
													});
												}}
											/>
											<Radio
												checked={showMinBookingInAdvanceInput}
												id='showMinBookingInAdvanceInputYes'
												name='showMinBookingInAdvanceInput'
												content='Set number of days before check-in'
												className='sdms-radio--primary'
												onChange={() =>
													setShowMinBookingInAdvanceInput(true)
												}
											/>
											{showMinBookingInAdvanceInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<Input
															type='text'
															value={minBookInAdvance}
															onChange={minBookInAdvanceOnChange}
															onBlur={setMinBookInAdvanceShowVal}
															append='day(s) or less before check-in'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='maxBookInAdvance'
										label='What is the maximum number of days before check-in can guests book this rate plan?'
										id={data.id}
										valRes={maxBookInAdvanceValRes}
										showValidation={maxBookInAdvanceShowVal}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMaxBookingInAdvanceInput}
												id='showMaxBookingInAdvanceInputNo'
												name='showMaxBookingInAdvanceInput'
												content='Any Time'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMaxBookingInAdvanceInput(false);
													maxBookInAdvanceOnChange({
														target: { value: null },
													});
												}}
											/>
											<Radio
												checked={showMaxBookingInAdvanceInput}
												id='showMaxBookingInAdvanceInputYes'
												name='showMaxBookingInAdvanceInput'
												content='Set number of days before check-in'
												className='sdms-radio--primary'
												onChange={() =>
													setShowMaxBookingInAdvanceInput(true)
												}
											/>
											{showMaxBookingInAdvanceInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<Input
															type='text'
															value={maxBookInAdvance}
															onChange={maxBookInAdvanceOnChange}
															onBlur={setMaxBookInAdvanceShowVal}
															append='day(s) or less before check-in'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='minUnits'
										label='Want to set minimum units available?'
										id={data.id}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMinUnitsInput}
												id='showMinUnitsInputNo'
												name='showMinUnitsInput'
												content='No'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMinUnitsInput(false);
													minUnitsOnChange({
														target: { value: null },
													});
												}}
											/>
											<Radio
												checked={showMinUnitsInput}
												id='showMinUnitsInputYes'
												name='showMinUnitsInput'
												content='Yes'
												className='sdms-radio--primary'
												onChange={() => setShowMinUnitsInput(true)}
											/>
											{showMinUnitsInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<Input
															type='text'
															value={minUnits}
															onChange={minUnitsOnChange}
															append='Unit(s)'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='maxUnits'
										label='Want to set maximum units available?'
										id={data.id}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMaxUnitsInput}
												id='showMaxUnitsInputNo'
												name='showMaxUnitsInput'
												content='No'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMaxUnitsInput(false);
													maxUnitsOnChange({
														target: { value: null },
													});
												}}
											/>
											<Radio
												checked={showMaxUnitsInput}
												id='showMaxUnitsInputYes'
												name='showMaxUnitsInput'
												content='Yes'
												className='sdms-radio--primary'
												onChange={() => setShowMaxUnitsInput(true)}
											/>
											{showMaxUnitsInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<Input
															type='text'
															value={maxUnits}
															onChange={maxUnitsOnChange}
															append='Unit(s)'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='isDiscountRatePlan'
										label='Is this a Discount Rate Plan?'
										description='If you check this box, this rate plan won’t show on search results unless conditions are met.'
										id={data.id}
										inFormDesign={false}>
										<Toggle
											onChange={isDiscountRatePlanOnChange}
											value={isDiscountRatePlan}
										/>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='minLoad'
										label='Want to set a minimum length overall for this rate plan?'
										description='This is the minimum length overall with this rate plan can be rented'
										id={data.id}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMinLoaInput}
												id='showMinLengthOverAllInputNo'
												name='showMinLengthOverAllInput'
												content='No'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMinLoaInput(false);
													resetMinLoa();
												}}
											/>
											<Radio
												checked={showMinLoaInput}
												id='showMinLengthOverAllInputYes'
												name='showMinLengthOverAllInput'
												content='Yes'
												className='sdms-radio--primary'
												onChange={() => setShowMinLoaInput(true)}
											/>
											{showMinLoaInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<LengthInputGroup
															ft={minLoaFt}
															ftOnChange={setMinLoaFt}
															inch={minLoaIn}
															inchOnChange={setMinLoaIn}
															placeHolder='Minimum Length Overall'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='maxLoad'
										label='Want to set a maximum length overall for this rate plan?'
										description='This is the maximum length overall with this rate plan can be rented'
										id={data.id}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMaxLoaInput}
												id='showMaxLengthOverAllInputNo'
												name='showMaxLengthOverAllInput'
												content='No'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMaxLoaInput(false);
													resetMaxLoa();
												}}
											/>
											<Radio
												checked={showMaxLoaInput}
												id='showMaxLengthOverAllInputYes'
												name='showMaxLengthOverAllInput'
												content='Yes'
												className='sdms-radio--primary'
												onChange={() => setShowMaxLoaInput(true)}
											/>
											{showMaxLoaInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<LengthInputGroup
															ft={maxLoaFt}
															ftOnChange={setMaxLoaFt}
															inch={maxLoaIn}
															inchOnChange={setMaxLoaIn}
															placeHolder='Maximum Length Overall'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='minBeam'
										label='Want to set a minimum beam for this rate plan?'
										description='This is the minimum minimum beam with this rate plan can be rented'
										id={data.id}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMinBeamInput}
												id='showMinBeamInputNo'
												name='showMinBeamInput'
												content='No'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMinBeamInput(false);
													resetMinBeam();
												}}
											/>
											<Radio
												checked={showMinBeamInput}
												id='showMinBeamInputYes'
												name='showMinBeamInput'
												content='Yes'
												className='sdms-radio--primary'
												onChange={() => setShowMinBeamInput(true)}
											/>
											{showMinBeamInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<LengthInputGroup
															ft={minBeamFt}
															ftOnChange={setMinBeamFt}
															inch={minBeamIn}
															inchOnChange={setMinBeamIn}
															placeHolder='Minimum Beam'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='maxBeam'
										label='Want to set a maximum beam for this rate plan?'
										description='This is the maximum beam with this rate plan can be rented'
										id={data.id}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMaxBeamInput}
												id='showMaxBeamInputNo'
												name='showMaxBeamInput'
												content='No'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMaxBeamInput(false);
													resetMaxBeam();
												}}
											/>
											<Radio
												checked={showMaxBeamInput}
												id='showMaxBeamInputYes'
												name='showMaxBeamInput'
												content='Yes'
												className='sdms-radio--primary'
												onChange={() => setShowMaxBeamInput(true)}
											/>
											{showMaxBeamInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<LengthInputGroup
															ft={maxBeamFt}
															ftOnChange={setMaxBeamFt}
															inch={maxBeamIn}
															inchOnChange={setMaxBeamIn}
															placeHolder='Maximum Beam'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='minSqft'
										label='Want to set a minimum square feet for this rate plan?'
										description='This is the minimum square feet with this rate plan can be rented'
										id={data.id}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMinSqftInput}
												id='showMinSquareFeetInputNo'
												name='showMinSquareFeetInput'
												content='No'
												className='sdms-radio--primary'
												onChange={() => {
													setHowMinSqftInput(false);
													minSqftOnChange({
														target: {
															value: null,
														},
													});
												}}
											/>
											<Radio
												checked={showMinSqftInput}
												id='showMinSquareFeetInputYes'
												name='showMinSquareFeetInput'
												content='Yes'
												className='sdms-radio--primary'
												onChange={() => setHowMinSqftInput(true)}
											/>
											{showMinSqftInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<Input
															type='text'
															value={minSqft || ''}
															onChange={minSqftOnChange}
															append='sqft'
															pattern={
																process.env
																	.REACT_APP_INTEGER_PATTERN
															}
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='maxSqft'
										label='Want to set a maximum square feet for this rate plan?'
										description='This is the maximum square feet with this rate plan can be rented'
										id={data.id}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMaxSqftInput}
												id='showMaxSquareFeetInputNo'
												name='showMaxSquareFeetInput'
												content='No'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMaxSqftInput(false);
													maxSqftOnChange({
														target: {
															value: null,
														},
													});
												}}
											/>
											<Radio
												checked={showMaxSqftInput}
												id='showMaxSquareFeetInputYes'
												name='showMaxSquareFeetInput'
												content='Yes'
												className='sdms-radio--primary'
												onChange={() => setShowMaxSqftInput(true)}
											/>
											{showMaxSqftInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<Input
															type='text'
															value={maxSqft || ''}
															onChange={maxSqftOnChange}
															append='sqft'
															pattern={
																process.env
																	.REACT_APP_INTEGER_PATTERN
															}
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='minHeight'
										label='Want to set a minimum height for this rate plan?'
										description='This is the minimum height with this rate plan can be rented'
										id={data.id}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMinHeightInput}
												id='showMinHeightInputNo'
												name='showMinHeightInput'
												content='No'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMinHeightInput(false);
													resetMinHeight();
												}}
											/>
											<Radio
												checked={showMinHeightInput}
												id='showMinHeightInputYes'
												name='showMinHeightInput'
												content='Yes'
												className='sdms-radio--primary'
												onChange={() => setShowMinHeightInput(true)}
											/>
											{showMinHeightInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<LengthInputGroup
															ft={minHeightFt}
															ftOnChange={setMinHeightFt}
															inch={minHeightIn}
															inchOnChange={setMinHeightIn}
															placeHolder='Minimum Height'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='maxHeight'
										label='Want to set a maximum height for this rate plan?'
										description='This is the maximum height with this rate plan can be rented'
										id={data.id}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMaxHeightInput}
												id='showMaxHeightInputNo'
												name='showMaxHeightInput'
												content='No'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMaxHeightInput(false);
													resetMaxHeight();
												}}
											/>
											<Radio
												checked={showMaxHeightInput}
												id='showMaxHeightInputYes'
												name='showMaxHeightInput'
												content='Yes'
												className='sdms-radio--primary'
												onChange={() => setShowMaxHeightInput(true)}
											/>
											{showMaxHeightInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<LengthInputGroup
															ft={maxHeightFt}
															ftOnChange={setMaxHeightFt}
															inch={maxHeightIn}
															inchOnChange={setMaxHeightIn}
															placeHolder='Maximum Height'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
						</Notes>
					</div>
				</FormGroup>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
RatePlanForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		internalName: PropTypes.string,
		externalName: PropTypes.string,
		bookingPeriod: PropTypes.shape({
			id: PropTypes.number,
			value: PropTypes.string,
		}),
		policy: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
		}),
		costPer: PropTypes.number,
		minDuration: PropTypes.number,
		maxDuration: PropTypes.number,
		minBookInAdvance: PropTypes.number,
		maxBookInAdvance: PropTypes.number,
		module: PropTypes.object,
		minLoa: PropTypes.number,
		maxLoa: PropTypes.number,
		minHeight: PropTypes.number,
		maxHeight: PropTypes.number,
		minBeam: PropTypes.number,
		maxBeam: PropTypes.number,
		minSqft: PropTypes.number,
		maxSqft: PropTypes.number,
		minUnits: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
		maxUnits: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
		minPricingLoa: PropTypes.number,
	}),
	isLoading: PropTypes.bool,
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	setTitle: PropTypes.func,
	onFormChange: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	policies: PropTypes.arrayOf(PropTypes.object),
	enumBookingPeriods: PropTypes.arrayOf(PropTypes.object),
	enumPricingTypes: PropTypes.arrayOf(PropTypes.object),
	submit: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
};
RatePlanForm.defaultProps = {
	data: {
		id: 0,
		internalName: '',
		externalName: '',
		bookingPeriod: {
			id: 0,
			value: '',
		},
		policy: {
			id: 0,
			name: '',
		},
		costPer: 1,
		minDuration: 0,
		maxDuration: 0,
		minBookInAdvance: 0,
		maxBookInAdvance: 0,
		module: {},
		minUnits: null,
		maxUnits: null,
	},
	isLoading: false,
	setIsValid: () => {},
	isSubmitted: false,
	setTitle: () => {},
	onFormChange: () => {},
	policies: [],
	enumPricingTypes: [],
	enumBookingPeriods: [],
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default RatePlanForm;
