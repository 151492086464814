import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiCall from '../../utils/helpers/apiCall';
import { themes } from '../../utils/constants/constants';

const ThemeContext = createContext(null);

export const ThemeContextProvider = ({ children }) => {
	const [data, setData] = useState({
		brightness: 100,
		contrast: 100,
		theme: themes.SHARPER,
	});

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const companyId = process.env.REACT_APP_DEFAULT_COMPANY_ID;

		if (companyId) {
			setIsLoading(true);
			apiCall(
				'POST',
				'getSystemSetting',
				res => {
					if (res.settings)
						setData({
							brightness: res.settings.brightness || 100,
							contrast: res.settings.contrast || 100,
							theme: res.settings.theme ? res.settings.theme.value : themes.SHARPER,
						});
					setIsLoading(false);
				},
				() => {
					setIsLoading(false);
				},
				'',
				{ company: companyId }
			);
		}
	}, []);

	return (
		<ThemeContext.Provider value={{ data, setData, isLoading }}>
			{children}
		</ThemeContext.Provider>
	);
};

ThemeContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ThemeContext;
