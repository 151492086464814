import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../reusables/element/Dropdown';
import Badge from '../../reusables/element/Badge';
import Search from '../../reusables/element/Search';
import Portlet from '../../reusables/layout/Portlet';

const CalendarPeriodList = ({ periods, addNewButton, onEdit, onDelete }) => {
	const [searchText, setSearchText] = useState('');

	return (
		<Portlet fluid='fluid' className='sdms-list-layout' everyTimeFluid>
			<Portlet.Head>
				<Portlet.HeadLabel portletIcon='Settings'>
					<Search
						inPortlet
						searchText={searchText}
						setSearchText={setSearchText}
						placeHolder='Filter Periods'
					/>
				</Portlet.HeadLabel>
				<Portlet.HeadToolbar>
					<Portlet.HeadActions>{addNewButton}</Portlet.HeadActions>
				</Portlet.HeadToolbar>
			</Portlet.Head>
			<Portlet.Body>
				<div className='sdms-period--table-list'>
					{periods
						.filter(
							p => p.name.toLowerCase().search(searchText.toLocaleLowerCase()) > -1
						)
						.map(period => (
							<div className='sdms-period--table-list__item' key={period.id}>
								<div
									role='presentation'
									className='sdms-period--table-list__name sdms-link sdms-link--dark'
									onClick={() => onEdit(period.id)}>
									{period.name}
								</div>
								<div className='sdms-period--table-list__actions'>
									<Badge className={`sdms-custom-colors--${period.color}`} />
									<Dropdown
										icon='Other#1'
										color='clean'
										inline
										aligned='right'
										circle
										outline={false}>
										<Dropdown.Header>Other Actions</Dropdown.Header>
										<Dropdown.Item
											icon='Edit'
											onClick={() => onEdit(period.id)}>
											Edit
										</Dropdown.Item>
										<Dropdown.Item
											itemsColor='danger'
											icon='Trash'
											onClick={() => onDelete(period.id)}>
											Delete
										</Dropdown.Item>
									</Dropdown>
								</div>
							</div>
						))}
				</div>
			</Portlet.Body>
		</Portlet>
	);
};
CalendarPeriodList.propTypes = {
	addNewButton: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	periods: PropTypes.arrayOf(PropTypes.object).isRequired,
	onEdit: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};
CalendarPeriodList.defaultProps = {
	addNewButton: { label: 'brand', text: 'New', icon: 'Plus', size: 'sm', onClick: () => {} },
};

export default CalendarPeriodList;
