import React, { useContext, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import UserContext from '../app/contexts/UserContext';
import {
	getCompaniesFromUserRoleOutlets,
	getOutletsFromUserRoleOutlets,
} from '../utils/helpers/helper';
import usePages from '../utils/hooks/usePages';
import { outletSwitchedAlertPages } from './pages';
import Nav from './Nav';
import NavItems from './NavItems';
import Dropdown from './reusables/element/Dropdown';
import Button from './reusables/element/Button';
import User from './User';
import Head from './reusables/layout/Head';
import DialogBox from './reusables/element/DialogBox';

const Header = () => {
	const userContext = useContext(UserContext);

	const pages = usePages();

	const [, setCompanySwitchAlert] = useState(false);

	const [outletSwitchAlert, setOutletSwitchAlert] = useState(false);

	const useRouteMatchNav = token => {
		const menu = pages[Object.keys(pages).find(key => pages[key].default.path === token)];
		const { default: _, ...submenu } = menu;
		return useRouteMatch(token) ? <NavItems menu={submenu} /> : null;
	};

	//TODO: need to update for multicompany - temporary commit for demo
	const companyDropdownOptions = useMemo(
		() => getCompaniesFromUserRoleOutlets(userContext.data.user.userRoleOutlets),
		[userContext]
	);

	const outletDropdownOptions = useMemo(
		() =>
			(userContext.data.user.isOwner || userContext.data.user.isInstallerUser
				? userContext.data.outlets
				: getOutletsFromUserRoleOutlets(userContext.data.user.userRoleOutlets)
			).filter(outlet => outlet.id !== userContext.data.selectedOutlet.id),
		[userContext]
	);

	const registerDropdownOptions = useMemo(
		() => userContext?.data?.selectedOutlet?.registers || [],
		[userContext]
	);

	//TODO: update for multi company. This is placeholder and won't be called with current code
	const onCompanyChange = company => {
		userContext.setData({
			...userContext.data,
			selectedCompany: company,
			selectedOutlet: userContext.data.user.defaultOutlet,
			selectedRegister: null,
		});
	};

	const onOutletChange = outlet => {
		userContext.setData({
			...userContext.data,
			selectedOutlet: outlet,
			selectedRegister: null,
		});
	};

	const onRegisterChange = register => {
		userContext.setData({
			...userContext.data,
			selectedRegister: register,
		});
	};

	return (
		<Head>
			<Head.MenuWrapper>
				<Head.Menu>
					<Nav>
						{useRouteMatchNav(pages.accounting.default.path)}
						{useRouteMatchNav(pages.booking.default.path)}
						{useRouteMatchNav(pages.crm.default.path)}
						{useRouteMatchNav(pages.pos.default.path)}
						{useRouteMatchNav(pages.marina.default.path)}
						{useRouteMatchNav(pages.campground.default.path)}
						{useRouteMatchNav(pages.reports.default.path)}
						{useRouteMatchNav(pages.companySettings.default.path)}
						{useRouteMatchNav(pages.systemSettings.default.path)}
						{useRouteMatchNav(pages.messages.default.path)}
						{useRouteMatchNav(pages.analytics.default.path)}
						<Nav.More>
							{useRouteMatchNav(pages.accounting.default.path)}
							{useRouteMatchNav(pages.booking.default.path)}
							{useRouteMatchNav(pages.crm.default.path)}
							{useRouteMatchNav(pages.pos.default.path)}
							{useRouteMatchNav(pages.marina.default.path)}
							{useRouteMatchNav(pages.campground.default.path)}
							{useRouteMatchNav(pages.reports.default.path)}
							{useRouteMatchNav(pages.companySettings.default.path)}
							{useRouteMatchNav(pages.systemSettings.default.path)}
							{useRouteMatchNav(pages.messages.default.path)}
							{useRouteMatchNav(pages.analytics.default.path)}
						</Nav.More>
					</Nav>
				</Head.Menu>
			</Head.MenuWrapper>
			{userContext.data && (
				<div className='sdms-header__topbar'>
					{companyDropdownOptions.length === 0 ? (
						<div className='sdms-header__topbar-item align-items-center sdms-mr-15'>
							<Button
								text={userContext.data.user.company.name}
								icon='Building'
								size='sm'
								design='primary'
								outline
							/>
						</div>
					) : (
						<Dropdown
							title={userContext.data.user.company.name}
							className='sdms-header__topbar-item align-items-center sdms-mr-15'
							arrow
							icon='Building'
							color='primary'
							aligned='right'
							size='sm'
							outline>
							<Dropdown.Header>Other Companies</Dropdown.Header>
							{companyDropdownOptions.map(company => (
								<Dropdown.Item
									icon='Location-arrow'
									key={company.id}
									onClick={() => {
										const segments = window.location.href.split('/');

										if (
											!Number.isNaN(
												parseInt(segments[segments.length - 1], 10)
											) &&
											outletSwitchedAlertPages.findIndex(
												path => window.location.pathname.search(path) > -1
											) > -1
										)
											setCompanySwitchAlert(company);
										else onCompanyChange(company);
									}}>
									{company.name}
								</Dropdown.Item>
							))}
						</Dropdown>
					)}
					{outletDropdownOptions.length === 0 ? (
						<div className='sdms-header__topbar-item align-items-center sdms-mr-15'>
							<Button
								text={userContext.data.selectedOutlet.name}
								icon='Location-arrow'
								size='sm'
								design='primary'
								outline
							/>
						</div>
					) : (
						<Dropdown
							title={userContext.data.selectedOutlet.name}
							className='sdms-header__topbar-item align-items-center sdms-mr-15'
							arrow
							icon='Location-arrow'
							color='primary'
							aligned='right'
							size='sm'
							outline>
							<Dropdown.Header>Other Outlets</Dropdown.Header>
							{outletDropdownOptions.map(outlet => (
								<Dropdown.Item
									icon='Location-arrow'
									key={outlet.id}
									onClick={() => {
										const segments = window.location.href.split('/');

										if (
											!Number.isNaN(
												parseInt(segments[segments.length - 1], 10)
											) &&
											outletSwitchedAlertPages.findIndex(
												path => window.location.pathname.search(path) > -1
											) > -1
										)
											setOutletSwitchAlert(outlet);
										else onOutletChange(outlet);
									}}>
									{outlet.name}
								</Dropdown.Item>
							))}
						</Dropdown>
					)}
					{registerDropdownOptions.length === 0 ? (
						<div className='sdms-header__topbar-item align-items-center sdms-mr-15'>
							<Button
								text='No Registers'
								icon='Keyboard'
								size='sm'
								design='primary'
								outline
							/>
						</div>
					) : (
						<Dropdown
							title={
								userContext.data.selectedRegister?.name || 'No Register Selected'
							}
							className='sdms-header__topbar-item align-items-center sdms-mr-15'
							arrow
							icon='Keyboard'
							color='primary'
							aligned='right'
							size='sm'
							outline>
							<Dropdown.Header>Other Registers</Dropdown.Header>
							{registerDropdownOptions.map(register => (
								<Dropdown.Item
									icon='Keyboard'
									key={register.id}
									onClick={() => onRegisterChange(register)}
									isDisabled={register.currentRegisterBatch === null}>
									{register.name}
								</Dropdown.Item>
							))}
							{!!userContext.data.selectedRegister && (
								<Dropdown.Item
									icon='Error-circle'
									key='clear'
									onClick={() => userContext.clearSelectedRegister()}>
									Clear
								</Dropdown.Item>
							)}
						</Dropdown>
					)}
					<User />
					<DialogBox
						open={outletSwitchAlert}
						title=''
						content='Are you sure about switching location? Your unsaved changes will be lost!'
						type='question'
						onClose={() => setOutletSwitchAlert(false)}>
						<Button
							className='sdms-font-transform-c'
							text='Go back'
							design='clean'
							icon='Angle-left-circle'
							onClick={() => setOutletSwitchAlert(false)}
						/>
						<Button
							className='sdms-font-transform-c'
							text='Yes, Switch'
							label='danger'
							icon='Location-arrow'
							onClick={() => {
								onOutletChange(outletSwitchAlert);
								setOutletSwitchAlert(false);
							}}
						/>
					</DialogBox>
				</div>
			)}
		</Head>
	);
};

export default Header;
