import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import forms from '../../forms';
import Badge from '../../reusables/element/Badge';

const EnabledCell = ({ data }) => (data.enabled ? <Badge.Yes /> : <Badge.No />);

EnabledCell.propTypes = {
	data: PropTypes.shape({
		enabled: PropTypes.bool,
	}),
};

EnabledCell.defaultProps = {
	data: {
		enabled: false,
	},
};

const TestModeCell = ({ data }) => (data.testMode ? <Badge.Yes /> : <Badge.No />);

TestModeCell.propTypes = {
	data: PropTypes.shape({
		testMode: PropTypes.bool,
	}),
};

TestModeCell.defaultProps = {
	data: {
		testMode: false,
	},
};

const ClientFormsCell = ({ data }) => data.customerPaymentForms.map(cpf => cpf.value).join(', ');

ClientFormsCell.propTypes = {
	data: PropTypes.shape({
		customerPaymentForms: PropTypes.arrayOf(PropTypes.object),
	}),
};

ClientFormsCell.defaultProps = {
	data: {
		customerPaymentForms: [],
	},
};

const PaymentProcessorList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.companySettings.default.text,
				path: pages.companySettings.dashboard.path,
			},
			{ title: pages.companySettings.paymentProcessors.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.companySettings.paymentProcessors.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route={pages.companySettings.paymentProcessors.route}
			history={history}
			title={pages.companySettings.paymentProcessors.text}
			forms={forms.companySettings.paymentProcessors}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col label='Payment Type' cellData='value' cellDataObject='paymentType' />
				<List.Col label='Gateway' cellData='value' cellDataObject='gateway' />
				<List.Col label='Client Enabled Payments' cellComponent={<ClientFormsCell />} />
				<List.Col label='Enabled' cellComponent={<EnabledCell />} />
				<List.Col label='Test Mode' cellComponent={<TestModeCell />} />
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

PaymentProcessorList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default PaymentProcessorList;
