import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SVGIcon from './SVGIcon';
import { USER_STORAGE_KEY } from '../../../utils/constants/constants';

const TablePaginationButton = ({ isActive, onClick, label, pageNumber, button, className }) => {
	const listItemClass = classNames(
		{ 'sdms-pagination__link--active': isActive },
		{ 'sdms-pagination__link--first': button === 'first' },
		{ 'sdms-pagination__link--prev': button === 'previous' },
		{ 'sdms-pagination__link--next': button === 'next' },
		{ 'sdms-pagination__link--last': button === 'last' },
		className
	);

	let content = '';

	switch (button) {
		case 'first':
			content = <SVGIcon name='Angle-double-left' />;
			break;
		case 'previous':
			content = <SVGIcon name='Angle-left' />;
			break;
		case 'next':
			content = <SVGIcon name='Angle-right' />;
			break;
		case 'last':
			content = <SVGIcon name='Angle-double-right' />;
			break;
		default:
			content = label.toString();
	}

	return (
		<li className={listItemClass}>
			<span role='presentation' onClick={() => onClick(pageNumber)} title={label}>
				{content}
			</span>
		</li>
	);
};
TablePaginationButton.propTypes = {
	label: PropTypes.node.isRequired,
	pageNumber: PropTypes.node.isRequired,
	isActive: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	button: PropTypes.oneOf(['first', 'next', 'previous', 'last']),
	className: PropTypes.string,
};
TablePaginationButton.defaultProps = {
	isActive: false,
	button: null,
	className: null,
};

const TablePagination = ({
	previousLabel,
	nextLabel,
	firstLabel,
	lastLabel,
	setCurrentPage,
	currentPage,
	totalPage,
	itemsPerPage,
	setItemsPerPage,
	totalItems,
	listName,
}) => {
	const pagination = () => {
		let items = [];

		let i = currentPage - 1;

		while (i >= currentPage - 2 && i > 0) {
			items.push(
				<TablePaginationButton key={i} label={i} onClick={setCurrentPage} pageNumber={i} />
			);

			i -= 1;
		}

		items = items.reverse();

		items.push(
			<TablePaginationButton
				key={currentPage}
				label={currentPage}
				isActive
				onClick={setCurrentPage}
				pageNumber={currentPage}
			/>
		);

		i = currentPage + 1;

		while (i <= currentPage + 2 && i <= totalPage) {
			items.push(
				<TablePaginationButton key={i} label={i} onClick={setCurrentPage} pageNumber={i} />
			);

			i += 1;
		}

		return items;
	};

	const getInfo = () => {
		const start = itemsPerPage * (currentPage - 1) + 1;

		const end = itemsPerPage * currentPage;

		return (
			<span className='pagination__desc'>
				Showing {start} - {end > totalItems ? totalItems : end} of {totalItems}
			</span>
		);
	};

	const persistPaginationSize = paginationSize => {
		const userCookie = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));

		userCookie.lists[listName] = paginationSize;

		localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(userCookie));
	};

	const handlePaginationSizeChange = e => {
		setCurrentPage(1);

		const paginationSize = parseInt(e.target.value, 10);

		setItemsPerPage(paginationSize);

		if (paginationSize === 999999) return;

		persistPaginationSize(paginationSize);
	};

	return (
		<div className='col-12'>
			<div className='sdms-pagination sdms-pagination--primary'>
				<ul className='sdms-pagination__links'>
					{totalPage > 1 && (
						<>
							<TablePaginationButton
								label={firstLabel}
								button='first'
								key='first'
								pageNumber={1}
								onClick={setCurrentPage}
								className={classNames({
									'sdms-pagination__link--disabled': currentPage === 1,
								})}
							/>
							<TablePaginationButton
								key='previous'
								button='previous'
								label={previousLabel}
								pageNumber={currentPage - 1}
								onClick={setCurrentPage}
								className={classNames({
									'sdms-pagination__link--disabled': !(currentPage - 1 > 0),
								})}
							/>
							{currentPage - 2 > 1 && (
								<TablePaginationButton
									label='...'
									pageNumber={currentPage - 3}
									onClick={setCurrentPage}
								/>
							)}

							{pagination()}

							{currentPage + 2 < totalPage && (
								<TablePaginationButton
									label='...'
									pageNumber={currentPage + 3}
									onClick={setCurrentPage}
								/>
							)}
							<TablePaginationButton
								key='next'
								button='next'
								label={nextLabel}
								pageNumber={currentPage + 1}
								onClick={setCurrentPage}
								className={classNames({
									'sdms-pagination__link--disabled': !(
										currentPage + 1 <=
										totalPage
									),
								})}
							/>
							<TablePaginationButton
								key='last'
								button='last'
								label={lastLabel}
								pageNumber={totalPage}
								onClick={setCurrentPage}
								className={classNames({
									'sdms-pagination__link--disabled': currentPage === totalPage,
								})}
							/>
						</>
					)}
				</ul>
				<div className='sdms-pagination__toolbar'>
					<select
						className='form-control sdms-font-brand'
						onChange={handlePaginationSizeChange}
						value={itemsPerPage}>
						<option value={10}>10</option>
						<option value={20}>20</option>
						<option value={30}>30</option>
						<option value={50}>50</option>
						<option value={100}>100</option>
						<option value={999999}>All</option>
					</select>
					{getInfo()}
				</div>
			</div>
		</div>
	);
};
TablePagination.propTypes = {
	previousLabel: PropTypes.string,
	nextLabel: PropTypes.string,
	firstLabel: PropTypes.string,
	lastLabel: PropTypes.string,
	setCurrentPage: PropTypes.func.isRequired,
	currentPage: PropTypes.number.isRequired,
	totalPage: PropTypes.number.isRequired,
	itemsPerPage: PropTypes.number.isRequired,
	setItemsPerPage: PropTypes.func.isRequired,
	totalItems: PropTypes.number.isRequired,
	listName: PropTypes.string,
};
TablePagination.defaultProps = {
	previousLabel: 'Previous',
	nextLabel: 'Next',
	firstLabel: 'First',
	lastLabel: 'Last',
	listName: 'List',
};

export default TablePagination;
