import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import RegisterForm from './RegisterForm';
import { modules } from '../../../utils/helpers/apiCall';
import useOutlet from '../../../utils/hooks/useOutlet';

const RegisterEdit = ({ match }) => {
	const [outletKey] = useOutlet(true);

	return (
		<FormContainer
			key={outletKey}
			dataId={match.params.id}
			pageTitle='Register Name'
			dataName='registers'
			lists={[
				'registerTypes',
				'grids',
				'printers',
				'unitMaps',
				'paymentTypes',
				'prepStations',
				'users',
				'outlets',
			]}
			fields={<RegisterForm />}
			customFilters={{ 'groups[]': 'register:read' }}
			module={modules.POS}
		/>
	);
};

RegisterEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default RegisterEdit;
