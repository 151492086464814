import React from 'react';
import PropTypes from 'prop-types';

import MultiSelect from '../../../element/MultiSelect';
import { bookingCalculations } from '../../../../../utils/constants/constants';
import { getItemTaxCode } from '../../../../../utils/helpers/helper';

const BasicExtraCharges = ({
	title,
	extraCharges,
	selectedExtraCharges,
	customer,
	max,
	disabled,
	onChange,
	maxSelection,
}) => {
	const checkPrice = (price, extraCharge) => {
		if (
			!price ||
			Number.isNaN(parseFloat(price)) ||
			price > 0 ||
			extraCharge.bookingCalculation.value !== bookingCalculations.FIXED
		)
			return price;

		const newPrice = max !== null ? Math.min(max, parseFloat(price) * -1) * -1 : price;

		return Number.isNaN(parseFloat(newPrice)) ? price : newPrice;
	};

	return (
		<div className='col-12 sdms-paddingless'>
			<div className='row'>
				<div className='col-12'>
					<span className='sdms-font-bold sdms-font-size-1-point-2-rem'>{title}</span>
				</div>
				<div className='col-12'>
					<MultiSelect
						data={extraCharges.map(extraCharge => {
							const selectedExtraChargeItem = selectedExtraCharges.find(
								sec => sec.extraCharge.id === extraCharge.id
							);

							let multiSelectQuantity;

							if (extraCharge.hasMultiSelectQuantity) {
								if (selectedExtraChargeItem?.multiSelectQuantity)
									multiSelectQuantity =
										selectedExtraChargeItem.multiSelectQuantity;

								if (selectedExtraChargeItem?.quantity)
									multiSelectQuantity = selectedExtraChargeItem.quantity;
							}
							return {
								...extraCharge,
								multiSelectQuantity: extraCharge.hasMultiSelectQuantity
									? multiSelectQuantity || 1
									: undefined,
							};
						})}
						titleProp='name'
						withoutSelectAll
						value={extraCharges.filter(extraCharge =>
							selectedExtraCharges.some(
								selectedExtraCharge =>
									selectedExtraCharge.extraCharge.id === extraCharge.id &&
									(selectedExtraCharge.isChecked || selectedExtraCharge.id > 0)
							)
						)}
						onChange={({ target }) => {
							// add or update selected extra charge item.
							target.value.forEach(e => {
								const selectedExtraChargeItem = selectedExtraCharges.find(
									sec => sec.extraCharge.id === e.id
								);

								onChange(
									selectedExtraChargeItem
										? {
												...selectedExtraChargeItem,
												isChecked: true,
												quantity:
													e.multiSelectQuantity ||
													selectedExtraChargeItem.quantity,
												multiSelectQuantity: e.multiSelectQuantity,
										  }
										: {
												isChecked: true,
												extraCharge: e,
												amount: checkPrice(e.price, e),
												quantity: e.multiSelectQuantity || 1,
												multiSelectQuantity: e.hasMultiSelectQuantity
													? 1
													: e.multiSelectQuantity,
												taxCode: getItemTaxCode(
													customer ? customer.taxCode : null,
													e
												),
										  }
								);
							});

							// remove selected extra charge for category.
							selectedExtraCharges
								.filter(
									sec =>
										!target.value.some(e => sec.extraCharge.id === e.id) &&
										sec.category === title
								)
								.forEach(sec => {
									onChange({
										...sec,
										isChecked: false,
									});
								});
						}}
						displayTaxes
						disabled={disabled}
						maxSelection={maxSelection}
					/>
				</div>
			</div>
		</div>
	);
};

BasicExtraCharges.propTypes = {
	title: PropTypes.string,
	extraCharges: PropTypes.arrayOf(PropTypes.object),
	selectedExtraCharges: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	max: PropTypes.number,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	maxSelection: PropTypes.number,
};

BasicExtraCharges.defaultProps = {
	title: '',
	extraCharges: [],
	selectedExtraCharges: [],
	customer: null,
	max: null,
	disabled: false,
	onChange: () => {},
	maxSelection: 0,
};

export default BasicExtraCharges;
