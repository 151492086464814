import React from 'react';
import PropTypes from 'prop-types';

import { getSearchSvg } from '../../../../../../utils/helpers/basicReservationHelper';
import { searchResultViews } from '../../constants';

import Portlet from '../../../../layout/Portlet';
import SearchResultListView from './SearchResultListView';
import SearchResultGridView from './SearchResultGridView';
import SearchResultMapView from './SearchResultMapView';

const SearchResult = ({
	resultView,
	productCategories,
	isOnline,
	searched,
	isLoading,
	selectProduct,
	multipleSelected,
	setMultipleSelected,
	headerHeight,
	outlet,
	initProducts,
	selectedCategory,
	updateSearchData,
	bookingType,
	searchData,
}) => {
	const getContent = () => {
		if (Object.keys(productCategories).length === 0 && resultView !== searchResultViews.MAP) {
			if (searched)
				return (
					<div className='row sdms-marginless'>
						<div className='offset-3 col-6'>
							<div className='offset-3 col-6'>No products available!</div>
						</div>
					</div>
				);

			if (bookingType?.onlineBookingTemplate)
				return (
					<div // eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: bookingType.onlineBookingTemplate.text,
						}}
					/>
				);

			return (
				<div className='row sdms-marginless'>
					<div className='offset-3 col-6'>
						<div className='offset-3 col-6'>{getSearchSvg(isOnline)}</div>
					</div>
				</div>
			);
		}

		if (resultView === searchResultViews.LIST)
			return (
				<SearchResultListView
					searched={searched}
					isLoading={isLoading}
					productCategories={productCategories}
					multipleSelected={multipleSelected}
					setMultipleSelected={setMultipleSelected}
					isOnline={isOnline}
					headerHeight={headerHeight}
					selectProduct={selectProduct}
					outlet={outlet}
					initProducts={initProducts}
					displayHeader={selectedCategory === 'all'}
					searchData={searchData}
				/>
			);

		if (resultView === searchResultViews.GRID)
			return (
				<SearchResultGridView
					searched={searched}
					isLoading={isLoading}
					productCategories={productCategories}
					multipleSelected={multipleSelected}
					setMultipleSelected={setMultipleSelected}
					isOnline={isOnline}
					selectProduct={selectProduct}
					outlet={outlet}
					initProducts={initProducts}
					updateSearchData={updateSearchData}
					searchData={searchData}
				/>
			);

		return (
			<SearchResultMapView
				bookingType={bookingType}
				productCategories={productCategories}
				isLoading={isLoading}
				selectProduct={selectProduct}
				outlet={outlet}
				isOnline={isOnline}
				selectedCategory={selectedCategory}
			/>
		);
	};

	return (
		<Portlet
			fluid={isOnline ? null : 'fluid'}
			className='sdms-bg-transparent sdms-online-booking-search-result'
			isElevate={false}>
			<Portlet.Body style={{ padding: 0 }}>{getContent()}</Portlet.Body>
		</Portlet>
	);
};

SearchResult.propTypes = {
	resultView: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	productCategories: PropTypes.object.isRequired,
	isOnline: PropTypes.bool.isRequired,
	searched: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	selectProduct: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	multipleSelected: PropTypes.object,
	setMultipleSelected: PropTypes.func.isRequired,
	headerHeight: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initProducts: PropTypes.array,
	selectedCategory: PropTypes.string.isRequired,
	updateSearchData: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	bookingType: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	searchData: PropTypes.object,
};

SearchResult.defaultProps = {
	initProducts: [],
	bookingType: null,
	multipleSelected: null,
	searchData: null,
};

export default SearchResult;
