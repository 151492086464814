import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useField from '../../../utils/hooks/useField';

import Button from './Button';
import { ListBody, ListTable } from '../template/List';
import FormField from '../template/FormField';
import Portlet from '../layout/Portlet';
import TimePickerInput from '../field/TimePickerInput';
import moment from 'moment';
import useComponentSize from '@rehooks/component-size';

const TimeSlotItem = ({
	data,
	disabled,
	onDelete,
	onChange,
	minuteStep,
	bookingInterval,
	startTime,
	endTime,
	timeSlotExists,
}) => {
	const cellRef = useRef(null);

	const size = useComponentSize(cellRef);

	const getValueMoment = value =>
		moment(`1970/01/01 ${value}`, 'YYYY/MM/DD HH:mm:ss')
			.second(0)
			.utc(true);

	const validateTimeSlot = (value, setValRes) => {
		if (typeof value === 'string') value = getValueMoment(value);

		const start = startTime
			.clone()
			.year(1970)
			.month(0)
			.date(1);

		const end = endTime
			.clone()
			.year(1970)
			.month(0)
			.date(1)
			.add(-1 * bookingInterval, 'minutes');

		const error =
			value < start
				? 'Time slot cannot be earlier than the earliest rental time'
				: value > end
				? 'Time slot cannot be later than the latest rental time'
				: timeSlotExists(value)
				? 'Duplicate slot entry'
				: null;

		if (error) {
			setValRes({
				isValid: false,
				status: 'invalidTimeSlot',
				message: error,
			});

			return false;
		}

		setValRes({
			isValid: true,
		});

		return true;
	};

	const [
		value,
		valueOnChange,
		valueValRes,
		valueShowVal,
		setValueShowVal,
		validateValue,
	] = useField(data, 'value', () => {}, [validateTimeSlot], null);

	const startTimeMoment = useRef(
		value
			? getValueMoment(value)
			: moment()
					.hour(0)
					.minute(0)
					.second(0)
	);

	const endTimeMoment = useRef(
		moment()
			.hour(0)
			.minute(0)
			.second(0)
	);

	useEffect(() => {
		endTimeMoment.current = startTimeMoment.current.clone().add(bookingInterval, 'minutes');

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [bookingInterval]);

	useEffect(() => {
		validateValue(value);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [value, startTime, endTime]);

	useEffect(() => {
		onChange({ id: data.id, value, isValid: valueValRes.isValid });

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [value, valueValRes]);

	useEffect(() => {
		setValueShowVal();

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<tr className={classNames({ disabled })}>
			<td ref={cellRef}>
				<FormField
					name='value'
					id={data.id}
					inFormDesign={false}
					isLast
					valRes={valueValRes}
					showValidation={valueShowVal}>
					<TimePickerInput
						defaultValue={startTimeMoment.current}
						showSecond={false}
						value={startTimeMoment.current}
						onChange={target => {
							valueOnChange({
								target: {
									value: target ? target.format('HH:mm:ss') : '12:00:00',
								},
							});

							startTimeMoment.current =
								target || startTimeMoment.current.hour(0).minute(0);

							endTimeMoment.current = startTimeMoment.current
								.clone()
								.add(bookingInterval, 'minutes');
						}}
						onBlur={setValueShowVal}
						use12Hours
						minuteStep={minuteStep}
						size={size}
						allowEmpty={false}
					/>
				</FormField>
			</td>
			<td style={{ verticalAlign: 'top' }}>
				<TimePickerInput
					defaultValue={endTimeMoment.current}
					showSecond={false}
					value={endTimeMoment.current}
					use12Hours
					size={size}
					disabled
				/>
			</td>
			<td style={{ verticalAlign: 'top' }}>
				<Button
					btnIcon
					label='danger'
					icon='Trash'
					size='sm'
					elevate
					key='delete'
					onClick={onDelete}
					disabled={disabled}
				/>
			</td>
		</tr>
	);
};

TimeSlotItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	onDelete: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	minuteStep: PropTypes.number,
	bookingInterval: PropTypes.number,
	startTime: PropTypes.any,
	endTime: PropTypes.any,
	timeSlotExists: PropTypes.func,
};

const TimeSlotList = ({
	timeSlots,
	onAdd,
	onChange,
	onDelete,
	disabled,
	displayTitle,
	minuteStep,
	bookingInterval,
	startTime,
	endTime,
}) => {
	const timeSlotExists = value =>
		timeSlots.filter(item => item.value === value.format('HH:mm:ss').toString()).length > 1;

	return (
		<div className='col-12'>
			<Portlet className='sdms-list-layout sdms-portlet--section' border>
				<Portlet.Head wrapMaxSize='lg'>
					<Portlet.HeadLabel portletIcon='Clock'>
						{displayTitle && <h3 className='sdms-portlet__head-title'>Time slots</h3>}
					</Portlet.HeadLabel>
					<Portlet.HeadToolbar>
						<Portlet.HeadActions>
							<Button
								label='brand'
								text='Add New'
								icon='Plus'
								onClick={() => onAdd()}
							/>
						</Portlet.HeadActions>
					</Portlet.HeadToolbar>
				</Portlet.Head>
				<Portlet.Body>
					<ListBody
						className='table--everytime--scroll sdms-portlet__body--fit'
						responsive='scroll'>
						<ListTable>
							<colgroup>
								<col />
								<col />
							</colgroup>
							<thead>
								<tr>
									<th className='sdms-bg-transparent'>Start</th>
									<th className='sdms-bg-transparent'>End</th>
									<th className='sdms-bg-transparent'>Actions</th>
								</tr>
							</thead>
							<tbody>
								{timeSlots.map(pm => (
									<TimeSlotItem
										key={pm.id}
										data={pm}
										onChange={onChange}
										onDelete={() => onDelete(pm.id)}
										disabled={disabled}
										minuteStep={minuteStep}
										bookingInterval={bookingInterval}
										startTime={startTime}
										endTime={endTime}
										timeSlotExists={timeSlotExists}
									/>
								))}
							</tbody>
						</ListTable>
					</ListBody>
				</Portlet.Body>
			</Portlet>
		</div>
	);
};

TimeSlotList.propTypes = {
	productMetas: PropTypes.arrayOf(PropTypes.object),
	onAdd: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	displayTitle: PropTypes.bool,
	minuteStep: PropTypes.number,
	bookingInterval: PropTypes.number,
	startTime: PropTypes.any,
	endTime: PropTypes.any,
};
TimeSlotList.defaultProps = {
	productMetas: [],
	disabled: false,
	displayTitle: false,
	minuteStep: 15,
	bookingInterval: 15,
	startTime: null,
	endTime: null,
};

export default TimeSlotList;
