import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import CustomerForm from './CustomerForm';
import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import UserContext from '../../../app/contexts/UserContext';

const CustomerEdit = ({ match }) => {
	const userContext = useContext(UserContext);
	
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='customers'
			lists={[
				'countries',
				'states',
				'paymentMethods',
				'paymentTerms',
				'taxCodes',
				'taxRates',
				'customerTypes',
			]}
			fields={<CustomerForm />}
			pageTitle={pages.crm.customers.text}
			parseSubmitData={data => {
				if (data.defaultCreditCard && data.defaultCreditCard.id <= 0) {
					return {
						...data,
						creditCards: data.creditCards.filter(
							cc => cc.id !== data.defaultCreditCard.id
						),
						defaultCreditCard: {
							...data.defaultCreditCard,
							customer: {
								id: data.id,
								'@id': data['@id'],
								'@context': data['@context'],
								'@type': data['@type'],
							},
						},
					};
				}

				return data;
			}}
			isEditable={userContext.hasPermission('edit_customers')}
		/>
	);
};

CustomerEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default CustomerEdit;
