const pages = {
	accounting: {
		default: {
			text: 'Accounting',
			icon: 'Wallet',
			path: '/accounting',
			permission: 'access_accounting',
		},
		dashboard: {
			text: 'Dashboard',
			icon: 'Layers',
			path: '/accounting/dashboard',
			permission: 'view_accounting_dashboard',
		},
		chartOfAccounts: {
			text: 'Chart of accounts',
			icon: 'Chart-line#1',
			path: '/accounting/chart-of-accounts',
			permission: 'view_chart_of_accounts',
		},
		journals: {
			text: 'Journal Entries',
			icon: 'Clipboard-list',
			path: '/accounting/journals',
			permission: 'view_journal_entries',
		},
		payrollHours: {
			text: 'Payroll Hours',
			icon: 'Clock',
			path: '/accounting/payroll-hours',
		},
		qbErrorLogs: {
			text: 'Accounting Sync',
			icon: 'Folder-error',
			path: '/accounting/accounting-sync-logs',
		},
		ccErrorLogs: {
			text: 'CC Error Log',
			icon: 'Credit-card',
			path: '/accounting/cc-error-logs',
		},
		achErrorLogs: {
			text: 'ACH Error Log',
			icon: 'Ticket',
			path: '/accounting/ach-error-logs',
		},
		settings: {
			text: 'Settings',
			icon: 'Settings',
			path: '/accounting/settings',
			permission: 'view_accounting_settings',
			discount: {
				text: 'Discounts',
				icon: 'Sale#2',
				path: '/accounting/settings/discounts',
				permission: 'maintain_accounting_general_settings',
			},
			creditMemoReasons: {
				text: 'Credit Memo Reasons',
				icon: 'Ticket',
				path: '/accounting/settings/credit-memo-reasons',
				permission: 'maintain_accounting_general_settings',
			},
			paymentTerms: {
				text: 'Payment Terms',
				icon: 'Clipboard',
				path: '/accounting/settings/payment-terms',
				permission: 'maintain_accounting_general_settings',
			},
			accountDetailTypes: {
				text: 'Account Detail Types',
				icon: 'Wallet',
				path: '/accounting/settings/account-detail-types',
				permission: 'maintain_accounting_general_settings',
			},
			unitMeasures: {
				text: 'Unit Measures',
				icon: 'Roulette',
				path: '/accounting/settings/unit-measures',
				permission: 'maintain_accounting_general_settings',
			},
			taxAgency: {
				text: 'Tax Agency',
				path: '/accounting/settings/taxAgency',
				icon: 'Sale#1',
				permission: 'maintain_tax_settings',
			},
			taxRate: {
				text: 'Tax Rate',
				path: '/accounting/settings/tax-rate',
				icon: 'Sale#1',
				permission: 'maintain_tax_settings',
			},
			taxRateGroup: {
				text: 'Tax Rate Group',
				path: '/accounting/settings/tax-rate-group',
				icon: 'Sale#1',
				permission: 'maintain_tax_settings',
			},
			taxCode: {
				text: 'Tax Code',
				path: '/accounting/settings/taxCode',
				icon: 'Sale#1',
				permission: 'maintain_tax_settings',
			},
			paymentSchedules: {
				text: 'Payment Schedules',
				path: '/accounting/settings/payment-schedules',
				icon: 'Wallet',
			},
		},
	},
	booking: {
		default: {
			text: 'Booking',
			icon: 'Building',
			path: '/booking',
			permission: 'access_bookings',
		},
		dashboard: {
			text: 'Dashboard',
			icon: 'Layers',
			path: '/booking/dashboard',
			permission: 'view_bookings_dashboard',
		},
		map: {
			text: 'Map',
			icon: 'Subtract',
			path: '/booking/map',
		},
		availability: {
			text: 'Availability',
			icon: 'Done-circle',
			path: '/booking/availability',
			newReservation: {
				text: 'New Reservation',
				path: '/booking/reservations/advance',
			},
			permission: 'view_bookings_availability',
		},
		reservations: {
			text: 'Reservations',
			icon: 'Mail-box',
			path: '/booking/reservations',
			advanced: {
				icon: 'Done-circle',
				text: 'New Reservation',
				path: '/booking/reservations/advanced',
			},
			new: {
				text: 'New Reservation',
				path: '/booking/reservations/0',
			},
			permission: 'view_bookings_reservations',
		},
		products: {
			text: 'Products',
			icon: 'Price1',
			path: '/booking/products',
			permission: 'view_bookings_products',
			period: {
				hourly: {
					text: 'Hourly',
					path: '/booking/products/hourly',
					icon: 'Clock',
					color: 'success',
				},
				daily: {
					text: 'Daily',
					path: '/booking/products/daily',
					icon: 'Sun',
					color: 'warning',
				},
				nightly: {
					text: 'Nightly',
					path: '/booking/products/nightly',
					icon: 'Moon',
					color: 'info',
				},
			},
		},
		settings: {
			text: 'Settings',
			icon: 'Settings',
			path: '/booking/settings',
			permission: 'view_bookings_settings',
			bookingType: {
				text: 'Booking Types',
				path: '/booking/settings/booking-types',
				icon: 'Bed',
				permission: 'bookings_maintain_booking_types',
			},
			customReservationStatus: {
				text: 'Custom Status',
				path: '/booking/settings/custom-reservation-statuses',
				icon: 'Bed',
				// permission: 'maintain_custom_reservation_statuses',
			},
			unitGroups: {
				text: 'Unit Groups',
				icon: 'Caravan',
				path: '/booking/settings/unitGroups',
				permission: 'bookings_maintain_unit_groups',
			},
			productCategories: {
				text: 'Product Categories',
				path: '/booking/settings/product-categories',
				icon: 'Building',
				permission: 'maintain_bookings_product_categories',
			},
			productCategoriesExtraCharges: {
				text: 'Price Adjustment Groups',
				path: '/booking/settings/product-categories-price-adjustments',
				icon: 'Subtract',
				permission: 'bookings_maintain_extra_charge_groups',
			},
			units: {
				text: 'Units',
				path: '/booking/settings/units',
				icon: 'Clipboard-list',
				permission: 'maintain_bookings_units',
			},
			unitBlackouts: {
				text: 'Unit Blackouts',
				path: '/booking/settings/unit-blockouts',
				icon: 'Layout-3d',
				permission: 'maintain_bookings_unit_blocks',
			},
			policies: {
				text: 'Policies',
				path: '/booking/settings/policies',
				icon: 'Clipboard-check',
				permission: 'maintain_bookings_policies',
			},
			ratePlans: {
				text: 'Rate Plans',
				path: '/booking/settings/rate-plans',
				icon: 'Printer',
				permission: 'maintain_bookings_rate_plans',
			},
			extraCharges: {
				text: 'Price Adjustments',
				path: '/booking/settings/price-adjustments',
				icon: 'Component',
				permission: 'maintain_bookings_extra_charges',
				allOrders: {
					text: 'All Orders',
					path: '/booking/settings/price-adjustments/all-orders',
					icon: 'Clipboard-check',
				},
				offlineOrders: {
					text: 'Offline Orders',
					path: '/booking/settings/price-adjustments/offline-orders',
					icon: 'Laptop-macbook',
				},
				onlineOrders: {
					text: 'Online Orders',
					path: '/booking/settings/price-adjustments/online-orders',
					icon: 'Laptop-macbook',
				},
				perUnit: {
					text: 'Per Unit',
					path: '/booking/settings/price-adjustments/per-unit',
					icon: 'Clipboard-list',
				},
			},
			maps: {
				text: 'Maps',
				icon: 'Subtract',
				path: '/booking/settings/maps',
				permission: 'access_bookings',
			},
			promoCodes: {
				text: 'Promo Codes',
				icon: 'Sale#2',
				path: '/booking/settings/promo-codes',
				permission: 'access_bookings',
			},
		},
	},
	crm: {
		default: {
			text: 'CRM',
			icon: 'User',
			path: '/crm',
			permission: 'access_customers',
		},
		dashboard: {
			text: 'Dashboard',
			icon: 'Layers',
			path: '/crm/dashboard',
		},
		customers: {
			text: 'Customers',
			icon: 'User',
			path: '/crm/customers',
			permission: 'view_customers',
			general: {
				text: 'General',
				icon: 'Layers',
			},
			paymentSettings: {
				text: 'Payment Settings',
				icon: 'Wallet',
			},
		},
		transactions: {
			text: 'Transactions',
			icon: 'Layout-left-panel-2',
			path: '/crm/transactions',
			permission: 'view_invoices',
		},
		// todo: add pos order to crm module
		// orders: {
		// 	text: 'Order',
		// 	icon: 'Cart#1',
		// 	path: '/crm/orders',
		// },
		invoices: {
			text: 'Invoices',
			icon: 'Clipboard-list',
			path: '/crm/invoices',
			permission: 'view_invoices',
		},
		payments: {
			text: 'Payments',
			icon: 'Wallet',
			path: '/crm/payments',
			permission: 'view_payments',
		},
		creditMemos: {
			text: 'Credit Memos',
			icon: 'Ticket',
			path: '/crm/credit-memos',
			permission: 'view_credit_memos',
		},
		refunds: {
			text: 'Refunds',
			icon: 'Undo',
			path: '/crm/refunds',
			permission: 'view_refunds',
		},
		statementCharges: {
			text: 'Statement Charges',
			icon: 'Clip',
			path: '/crm/statement-charges',
			permission: 'view_statement_charges',
		},
	},
	marina: {
		default: {
			text: 'Marina',
			icon: 'Marina',
			path: '/marina',
			permission: 'access_marina',
		},
		dashboard: {
			text: 'Dashboard',
			icon: 'Layers',
			path: '/marina/dashboard',
			permission: 'view_marina_dashboard',
		},
		map: {
			text: 'Map',
			icon: 'Subtract',
			path: '/marina/map',
		},
		availability: {
			text: 'Availability',
			icon: 'Done-circle',
			path: '/marina/availability',
			permission: 'view_marina_availability',
		},
		reservations: {
			text: 'Reservations',
			icon: 'Mail-box',
			path: '/marina/reservations',
			select: {
				icon: 'Mail-box',
				text: 'New Reservation',
				path: '/marina/reservations/advanced',
			},
			transient: {
				icon: 'Clock',
				text: 'Transient',
				path: '/marina/reservations/advanced/transient',
				color: 'success',
			},
			seasonal: {
				icon: 'Day-rain',
				text: 'Seasonal',
				path: '/marina/reservations/advanced/seasonal',
				color: 'warning',
			},
			longTerm: {
				icon: 'Waiting',
				text: 'Long Term',
				path: '/marina/reservations/advanced/long-term',
				color: 'info',
			},
			new: {
				text: 'New Reservation',
				path: '/marina/reservations/0',
			},
			permission: 'view_marina_reservations',
		},
		products: {
			text: 'Products',
			icon: 'Price1',
			path: '/marina/products',
			permission: 'view_marina_products',
			period: {
				hourly: {
					text: 'Hourly',
					path: '/marina/products/hourly',
					icon: 'Clock',
					color: 'success',
				},
				daily: {
					text: 'Daily',
					path: '/marina/products/daily',
					icon: 'Sun',
					color: 'warning',
				},
				nightly: {
					text: 'Nightly',
					path: '/marina/products/nightly',
					icon: 'Moon',
					color: 'info',
				},
				longTerm: {
					text: 'Long Term',
					path: '/marina/products/long-term',
					icon: 'Waiting',
					color: 'info',
				},
				seasonal: {
					text: 'Seasonal',
					path: '/marina/products/seasonal',
					icon: 'Day-rain',
					color: 'warning',
				},
			},
		},
		meterReadings: {
			text: 'Meter Readings',
			icon: 'Roulette',
			path: '/marina/meter-readings',
			permission: 'access_marina',
			batch: {
				text: 'Meter Read Batch',
				icon: 'Roulette',
				path: '/marina/meter-readings/batch',
			},
		},
		vessels: {
			text: 'Vessels',
			icon: 'Marina',
			path: '/marina/vessels',
			permission: 'access_marina',
		},
		settings: {
			text: 'Settings',
			icon: 'Settings',
			path: '/marina/settings',
			permission: 'view_marina_settings',
			bookingType: {
				text: 'Booking Types',
				icon: 'Bed',
				path: '/marina/settings/booking-types',
				permission: 'marina_maintain_booking_types',
			},
			customReservationStatus: {
				text: 'Custom Status',
				icon: 'Bed',
				path: '/marina/settings/custom-reservation-statuses',
				permission: 'access_marina',
			},
			extraCharges: {
				text: 'Price Adjustments',
				path: '/marina/settings/price-adjustments',
				icon: 'Component',
				permission: 'maintain_marina_extra_charges',
				allOrders: {
					text: 'All Orders',
					path: '/marina/settings/price-adjustments/all-orders',
					icon: 'Clipboard-check',
				},
				offlineOrders: {
					text: 'Offline Orders',
					path: '/marina/settings/price-adjustments/offline-orders',
					icon: 'Laptop-macbook',
				},
				onlineOrders: {
					text: 'Online Orders',
					path: '/marina/settings/price-adjustments/online-orders',
					icon: 'Laptop-macbook',
				},
				perUnit: {
					text: 'Per Unit',
					path: '/marina/settings/price-adjustments/per-unit',
					icon: 'Clipboard-list',
				},
			},
			extraChargeGroups: {
				text: 'Price Adjustment Groups',
				icon: 'Subtract',
				path: '/marina/settings/price-adjustment-groups',
				permission: 'marina_maintain_extra_charge_groups',
			},
			maps: {
				text: 'Maps',
				icon: 'Subtract',
				path: '/marina/settings/maps',
				permission: 'access_marina',
			},
			policies: {
				text: 'Policies',
				icon: 'Clipboard-check',
				path: '/marina/settings/policies',
				permission: 'maintain_marina_policies',
			},
			productCategories: {
				text: 'Product Categories',
				icon: 'Layers',
				path: '/marina/settings/product-categories',
				permission: 'maintain_marina_product_categories',
			},
			ratePlans: {
				text: 'Rate Plans',
				icon: 'Printer',
				path: '/marina/settings/rate-plans',
				permission: 'maintain_marina_rate_plans',
			},
			spaces: {
				text: 'Spaces',
				icon: 'Commit',
				path: '/marina/settings/spaces',
				permission: 'maintain_marina_spaces',
			},
			compositeSpaces: {
				text: 'Space Collections',
				icon: 'Commit',
				path: '/marina/settings/composite-spaces',
				permission: 'access_marina',
			},
			spaceBlockouts: {
				text: 'Space Blackouts',
				icon: 'Git#2',
				path: '/marina/settings/space-blackouts',
				permission: 'maintain_marina_space_blocks',
			},
			spaceGroups: {
				text: 'Space Groups',
				icon: 'Git#4',
				path: '/marina/settings/space-groups',
				permission: 'marina_maintain_space_groups',
			},
			powerMeterTypes: {
				text: 'Power Meter Types',
				icon: 'Roulette',
				path: '/marina/settings/power-meter-types',
				permission: 'access_marina',
			},
			powerMeters: {
				text: 'Power Meters',
				icon: 'Roulette',
				path: '/marina/settings/power-meters',
				permission: 'access_marina',
			},
			promoCodes: {
				text: 'Promo Codes',
				icon: 'Sale#2',
				path: '/marina/settings/promo-codes',
				permission: 'access_marina',
			},
		},
	},
	pos: {
		default: {
			text: 'Point of Sale',
			icon: 'TV#1',
			path: '/pos',
			permission: 'access_pos',
		},
		dashboard: {
			text: 'Dashboard',
			icon: 'Layers',
			path: '/pos/dashboard',
			permission: 'view_pos_dashboard',
		},
		registers: {
			text: 'Registers',
			icon: 'Keyboard',
			path: '/pos/registers',
			icon2: 'Wallet',
			permission: 'view_registers',
		},
		products: {
			text: 'Products',
			icon: 'Price1',
			path: '/pos/products',
			permission: 'view_pos_products',
		},
		productGrids: {
			text: 'Product Grids',
			icon: 'Layout-grid',
			path: '/pos/product-grids',
			icon2: 'Layout-4-blocks',
			permission: 'view_product_grids',
		},
		modifierGroups: {
			text: 'Modifier Groups',
			icon: 'Layout-left-panel-2',
			path: '/pos/modifier-groups',
			permission: 'view_modifiers_groups',
		},
		// barcodes: {
		// 	text: 'Barcodes',
		// 	icon: 'Barcode',
		// 	path: '/pos/barcodes',
		// },
		// assignServers: {
		// 	text: 'Assign Servers',
		// 	icon: 'Server',
		// 	path: '/pos/assign-servers',
		// },
		settings: {
			text: 'Settings',
			icon: 'Settings',
			path: '/pos/settings',
			permission: 'view_pos_settings',
			posSettings: {
				text: 'POS Settings',
				icon: 'Settings',
				path: '/pos/settings/pos-settings',
				permission: 'maintain_pos_general_settings',
			},
			registerTypes: {
				text: 'Register Types',
				icon: 'Keyboard',
				path: '/pos/settings/register-types',
				permission: 'maintain_register_types',
			},
			prepStations: {
				text: 'Prep Stations',
				icon: 'Printer',
				path: '/pos/settings/prep-stations',
				permission: 'maintain_prep_stations',
			},
			tableMaps: {
				text: 'Table Maps',
				icon: 'Subtract',
				icon2: 'Settings',
				path: '/pos/settings/table-maps',
				permission: 'maintain_table_maps',
			},
			modifierPrefixes: {
				text: 'Modifier Prefixes',
				icon: 'Layout-left-panel-2',
				path: '/pos/settings/modifier-prefixes',
				permission: 'maintain_modifier_prefixes',
			},
			productCategories: {
				text: 'Product Categories',
				icon: 'Layout-grid',
				path: '/pos/settings/product-categories',
				permission: 'maintain_product_categories',
			},
			printLogs: {
				text: 'Print Logs',
				icon: 'Layout-grid',
				path: '/pos/settings/printLogs',
			},
		},
	},
	inventory: {
		default: {
			text: 'Inventory',
			icon: 'Box#2',
			path: '/inventory',
			permission: 'access_inventory',
			disabled: true,
		},
		dashboard: {
			text: 'Dashboard',
			icon: 'Layers',
			path: '/inventory/dashboard',
			permission: 'view_inventory_dashboard',
		},
	},
	service: {
		default: {
			text: 'Service',
			icon: 'Tools',
			path: '/service',
			permission: 'access_service',
			disabled: true,
		},
		dashboard: {
			text: 'Dashboard',
			icon: 'Layers',
			path: '/service/dashboard',
			permission: 'view_service_dashboard',
		},
	},
	vendors: {
		default: {
			text: 'Vendors',
			icon: 'Cart#1',
			path: '/vendors',
			permission: 'access_vendors',
			disabled: true,
		},
		dashboard: {
			text: 'Dashboard',
			icon: 'Layers',
			path: '/vendors/dashboard',
			permission: 'view_vendors_dashboard',
		},
	},
	reports: {
		// newreporttype
		default: {
			text: 'Reports',
			icon: 'Selected-file',
			path: '/reports',
		},
		dashboard: {
			text: 'All',
			icon: 'Layers',
			path: '/reports/dashboard',
		},
		accounting: {
			text: 'Accounting',
			icon: 'Wallet',
			path: '/reports/accounting',
			permission: 'access_accounting',
			deferred_income: {
				text: 'Deferred Income',
				icon: 'Timer',
				path: '/reports/accounting/deferred_income',
				// permission:
			},
			general_ledger: {
				text: 'General Ledger',
				icon: 'Dollar',
				path: '/reports/accounting/general_ledger',
				// permission:
			},
			payroll: {
				text: 'Payroll',
				icon: 'Files',
				path: '/reports/accounting/payroll',
				// permission:
			},
			settlement_details: {
				text: 'Settlement Details',
				icon: 'Compiling',
				path: '/reports/accounting/settlement_details',
				// permission:
			},
			deposit_balances: {
				text: 'Deposit Balances',
				icon: 'Compiling',
				path: '/reports/accounting/deposit_balances',
				// permission:
			},
			reservation_daily_revenue: {
				text: 'Reservation Daily Revenue',
				icon: 'Compiling',
				path: '/reports/accounting/reservation_daily_revenue',
				// permission:
			},
		},
		booking: {
			text: 'Booking',
			icon: 'Bookings',
			path: '/reports/booking',
			permission: 'access_bookings',
			reservations: {
				text: 'Reservations',
				icon: 'File-done',
				path: '/reports/booking/reservations',
				// permission:
			},
		},
		campground: {
			text: 'Campground',
			icon: 'Caravan',
			path: '/reports/campground',
			permission: 'access_campground',
			reservations: {
				text: 'Reservations',
				icon: 'File-done',
				path: '/reports/campground/reservations',
				// permission:
			},
			rent_roll: {
				text: 'Rent Roll',
				icon: 'File-done',
				path: '/reports/campground/rent_roll',
				// permission:
			},
		},
		crm: {
			text: 'CRM',
			icon: 'Install',
			path: '/reports/crm',
			permission: 'access_customers',
			customers: {
				text: 'Customer List',
				icon: 'File-done',
				path: '/reports/crm/customers',
				// permission:
			},
			balance_details: {
				text: 'Balance Details',
				icon: 'Dollar',
				path: '/reports/crm/balance_details',
				// permission:
			},
			invoices: {
				text: 'Invoices',
				icon: 'Clipboard-list',
				path: '/reports/crm/invoices',
				// permission:
			},
			invoice_details: {
				text: 'Invoice Details',
				icon: 'Keyboard',
				path: '/reports/crm/invoice_details',
				// permission:
			},
			payments: {
				text: 'Payments',
				icon: 'Wallet',
				path: '/reports/crm/payments',
				// permission:
			},
		},
		marina: {
			text: 'Marina',
			icon: 'Marina',
			path: '/reports/marina',
			permission: 'access_marina',
			reservations: {
				text: 'Reservations',
				icon: 'File-done',
				path: '/reports/marina/reservations',
				// permission:
			},
			rent_roll: {
				text: 'Rent Roll',
				icon: 'File-done',
				path: '/reports/marina/rent_roll',
				// permission:
			},
			vessels: {
				text: 'Vessels',
				icon: 'File-done',
				path: '/reports/marina/vessels',
				// permission:
			},
		},
		pos: {
			text: 'Point of Sale',
			icon: 'TV#1',
			path: '/reports/pos',
			permission: 'access_pos',
			zout: {
				text: 'Z-Out',
				icon: 'File-done',
				path: '/reports/pos/zout',
				// permission:
			},
			orders: {
				text: 'Orders',
				icon: 'Keyboard',
				path: '/reports/pos/orders',
				// permission:
			},
			orderdetails: {
				text: 'Order Details',
				icon: 'Keyboard',
				path: '/reports/pos/orderdetails',
				// permission:
			},
			ordermodifiers: {
				text: 'Order Modifiers',
				icon: 'Keyboard',
				path: '/reports/pos/ordermodifiers',
				// permission:
			},
			tips: {
				text: 'Tips',
				icon: 'Keyboard',
				path: '/reports/pos/tips',
				// permission:
			},
		},
	},
	companySettings: {
		default: {
			text: 'Company Settings',
			icon: 'Position',
			path: '/company-settings',
			permission: 'access_company_settings',
		},
		dashboard: {
			text: 'All',
			icon: 'Layers',
			path: '/company-settings/dashboard',
		},
		company: {
			text: 'Company',
			icon: 'Building',
			path: '/company-settings/company',
			permission: 'maintain_company',
			location: {
				text: 'Location',
				icon: 'Marker#2',
				path: '/company-settings/location',
			},
		},
		module: {
			text: 'Module Defaults',
			icon: 'Settings',
			path: '/company-settings/module-settings',
		},
		outletDefaults: {
			text: 'Outlet Defaults',
			icon: 'Marker#2',
			path: '/company-settings/outlet-defaults',
		},
		paymentProcessors: {
			text: 'Payment Processors',
			icon: 'Wallet#3',
			path: '/company-settings/payment-processors',
			route: 'paymentProcessors',
			installerRequired: true,
		},
		printers: {
			text: 'Printers',
			icon: 'Printer',
			route: 'printers',
			path: '/company-settings/printers',
			permission: 'maintain_printers',
		},
		reservationSettings: {
			text: 'Reservation Settings',
			path: '/company-settings/reservation-settings',
			icon: 'Settings',
			route: 'outletSettings',
		},
		companySyncs: {
			text: 'Syncs',
			icon: 'Arrows-h',
			path: '/company-settings/syncs',
			permission: 'view_company_syncs',
			route: 'companySyncs',
		},
		templateSettings: {
			text: 'Template Settings',
			icon: 'Marker#2',
			path: '/company-settings/templates',
			route: 'outletSettings',
		},
	},
	systemSettings: {
		default: {
			text: 'System Settings',
			icon: 'Settings#4',
			path: '/system-settings',
			permission: 'access_system_settings',
		},
		dashboard: {
			text: 'All',
			icon: 'Layers',
			path: '/system-settings/dashboard',
		},
		accountingIntegration: {
			text: 'Accounting Integration',
			icon: 'Wallet',
			path: '/system-settings/accounting-integration',
			route: 'companySettings',
		},
		attachmentTypes: {
			text: 'Attachment Types',
			icon: 'Attachment#1',
			path: '/system-settings/attachment-types',
			route: 'attachmentTypes',
		},
		auditLogs: {
			text: 'Audit Logs',
			icon: 'Clock',
			path: '/system-settings/audit-logs',
			permission: 'view_audit_logs',
		},
		batchJobs: {
			text: 'Batch Jobs',
			icon: 'Clipboard-list',
			path: '/system-settings/batch-jobs',
			route: 'companySettings',
			installerRequired: true,
		},
		calendars: {
			text: 'Calendars',
			icon: 'Layout-left-panel-1',
			path: '/system-settings/calendars',
			permission: 'maintain_calendar',
			route: 'calendars',
		},
		contracts: {
			text: 'Contracts',
			icon: 'User',
			path: '/system-settings/contracts',
			route: 'contracts',
		},
		emailAddresses: {
			text: 'Email Addresses',
			icon: 'Mail-box',
			path: '/system-settings/email-addresses',
			route: 'emailAddresses',
		},
		emailAddressAuth: {
			text: 'Email Addresses Authorization',
			icon: 'Mail-box',
			path: '/system-settings/email-address-auth',
		},
		metas: {
			text: 'Metas',
			icon: 'Bullet-list',
			path: '/system-settings/metas',
			route: 'metas',
		},
		passwordSettings: {
			text: 'Password Settings',
			icon: 'Key',
			path: '/system-settings/password-settings',
			route: 'systemSettings',
		},
		personalization: {
			text: 'Personalization',
			icon: 'Compass',
			path: '/system-settings/personalization',
			route: 'systemSettings',
		},
		productFilters: {
			text: 'Product Filters',
			icon: 'Bullet-list',
			path: '/system-settings/product-filters',
			route: 'productFilters',
		},
		roles: {
			text: 'Roles',
			icon: 'Shield-user',
			path: '/system-settings/roles',
			permission: 'maintain_roles',
			route: 'roles',
		},
		seasons: {
			text: 'Seasons',
			icon: 'Day-rain',
			path: '/system-settings/seasons',
			route: 'seasons',
		},
		templates: {
			text: 'Templates',
			icon: 'User',
			path: '/system-settings/templates',
			route: 'templates',
		},
		users: {
			text: 'Users',
			icon: 'User',
			path: '/system-settings/users',
			permission: 'view_users',
			route: 'users',
		},
	},
	campground: {
		default: {
			text: 'Campground',
			icon: 'Caravan',
			path: '/campground',
			permission: 'access_campground',
		},
		dashboard: {
			text: 'Dashboard',
			icon: 'Layers',
			path: '/campground/dashboard',
			permission: 'view_campground_dashboard',
		},
		map: {
			text: 'Map',
			icon: 'Subtract',
			path: '/campground/map',
		},
		availability: {
			text: 'Availability',
			icon: 'Done-circle',
			path: '/campground/availability',
			permission: 'view_campground_availability',
		},
		reservations: {
			text: 'Reservations',
			icon: 'Mail-box',
			path: '/campground/reservations',
			select: {
				icon: 'Mail-box',
				text: 'New Reservation',
				path: '/campground/reservations/advanced',
			},
			transient: {
				icon: 'Clock',
				text: 'Transient',
				path: '/campground/reservations/advanced/transient',
				color: 'success',
			},
			seasonal: {
				icon: 'Day-rain',
				text: 'Seasonal',
				path: '/campground/reservations/advanced/seasonal',
				color: 'warning',
			},
			longTerm: {
				icon: 'Waiting',
				text: 'Long Term',
				path: '/campground/reservations/advanced/long-term',
				color: 'info',
			},
			new: {
				text: 'New Reservation',
				path: '/campground/reservations/0',
			},
			permission: 'view_campground_reservations',
		},
		products: {
			text: 'Products',
			icon: 'Price1',
			path: '/campground/products',
			permission: 'view_campground_products',
			period: {
				hourly: {
					text: 'Hourly',
					path: '/campground/products/hourly',
					icon: 'Clock',
					color: 'success',
				},
				daily: {
					text: 'Daily',
					path: '/campground/products/daily',
					icon: 'Sun',
					color: 'warning',
				},
				nightly: {
					text: 'Nightly',
					path: '/campground/products/nightly',
					icon: 'Moon',
					color: 'info',
				},
				longTerm: {
					text: 'Long Term',
					path: '/campground/products/long-term',
					icon: 'Waiting',
					color: 'info',
				},
				seasonal: {
					text: 'Seasonal',
					path: '/campground/products/seasonal',
					icon: 'Day-rain',
					color: 'warning',
				},
			},
		},
		meterReadings: {
			text: 'Meter Readings',
			icon: 'Roulette',
			path: '/campground/meter-readings',
			permission: 'access_campground',
			batch: {
				text: 'Meter Read Batch',
				icon: 'Roulette',
				path: '/campground/meter-readings/batch',
			},
		},
		vehicles: {
			text: 'Vehicles',
			icon: 'Caravan',
			path: '/campground/vehicles',
			permission: 'access_campground',
		},
		settings: {
			text: 'Settings',
			icon: 'Settings',
			path: '/campground/settings',
			permission: 'view_campground_settings',
			bookingType: {
				text: 'Booking Types',
				icon: 'Bed',
				path: '/campground/settings/booking-types',
				permission: 'campground_maintain_booking_types',
			},
			customReservationStatus: {
				text: 'Custom Status',
				icon: 'Bed',
				path: '/campground/settings/custom-reservation-statuses',
				permission: 'access_campground',
			},
			extraCharges: {
				text: 'Price Adjustments',
				path: '/campground/settings/price-adjustments',
				icon: 'Component',
				permission: 'maintain_campground_extra_charges',
				allOrders: {
					text: 'All Orders',
					path: '/campground/settings/price-adjustments/all-orders',
					icon: 'Clipboard-check',
				},
				offlineOrders: {
					text: 'Offline Orders',
					path: '/campground/settings/price-adjustments/offline-orders',
					icon: 'Laptop-macbook',
				},
				onlineOrders: {
					text: 'Online Orders',
					path: '/campground/settings/price-adjustments/online-orders',
					icon: 'Laptop-macbook',
				},
				perUnit: {
					text: 'Per Unit',
					path: '/campground/settings/price-adjustments/per-unit',
					icon: 'Clipboard-list',
				},
			},
			extraChargeGroups: {
				text: 'Price Adjustment Groups',
				icon: 'Subtract',
				path: '/campground/settings/price-adjustment-groups',
				permission: 'campground_maintain_extra_charge_groups',
			},
			maps: {
				text: 'Maps',
				icon: 'Subtract',
				path: '/campground/settings/maps',
				permission: 'access_campground',
			},
			policies: {
				text: 'Policies',
				icon: 'Clipboard-check',
				path: '/campground/settings/policies',
				permission: 'maintain_campground_policies',
			},
			productCategories: {
				text: 'Product Categories',
				icon: 'Layers',
				path: '/campground/settings/product-categories',
				permission: 'maintain_campground_product_categories',
			},
			ratePlans: {
				text: 'Rate Plans',
				icon: 'Printer',
				path: '/campground/settings/rate-plans',
				permission: 'maintain_campground_rate_plans',
			},
			spaces: {
				text: 'Spaces',
				icon: 'Commit',
				path: '/campground/settings/spaces',
				permission: 'maintain_campground_spaces',
			},
			compositeSpaces: {
				text: 'Space Collections',
				icon: 'Commit',
				path: '/campground/settings/composite-spaces',
				permission: 'access_campground',
			},
			spaceBlockouts: {
				text: 'Space Blackouts',
				icon: 'Git#2',
				path: '/campground/settings/space-blackouts',
				permission: 'maintain_campground_space_blocks',
			},
			spaceGroups: {
				text: 'Space Groups',
				icon: 'Git#4',
				path: '/campground/settings/space-groups',
				permission: 'campground_maintain_space_groups',
			},
			powerMeterTypes: {
				text: 'Power Meter Types',
				icon: 'Roulette',
				path: '/campground/settings/power-meter-types',
				permission: 'access_campground',
			},
			powerMeters: {
				text: 'Power Meters',
				icon: 'Roulette',
				path: '/campground/settings/power-meters',
				permission: 'access_campground',
			},
			promoCodes: {
				text: 'Promo Codes',
				icon: 'Sale#2',
				path: '/campground/settings/promo-codes',
				permission: 'access_campground',
			},
		},
	},
	messages: {
		default: {
			text: 'Message Center',
			icon: 'Mail-box',
			path: '/messages',
			permission: 'view_messages',
		},
		dashboard: {
			text: 'All',
			icon: 'Layers',
			path: '/messages/dashboard',
			permission: 'view_messages',
		},
		unread: {
			text: 'Unread',
			icon: 'Mail-box',
			path: '/messages/unread',
			permission: 'view_messages',
		},
		read: {
			text: 'Read',
			icon: 'Mail-box',
			path: '/messages/read',
			permission: 'view_messages',
		},
		sent: {
			text: 'Sent',
			icon: 'Mail-box',
			path: '/messages/sent',
			permission: 'view_messages',
		},
		archived: {
			text: 'Archived',
			icon: 'Mail-box',
			path: '/messages/archived',
			permission: 'view_messages',
		},
	},
	analytics: {
		default: {
			text: 'Analytics',
			icon: 'Chart-bar1',
			path: '/analytics',
			permission: 'view_analytics',
		},
		dashboard: {
			text: 'Dashboard',
			icon: 'Layers',
			path: '/analytics/dashboard',
			permission: 'view_analytics',
		},
		accounting: {
			text: 'Accounting',
			icon: 'Wallet',
			path: '/analytics/accounting',
			permission: 'access_accounting_analytics',
			dashboard: {
				text: 'Accounting Analytics Dashboard',
				icon: 'Chart-bar1',
				path: '/analytics/accounting/dashboard/*',
				permission: 'access_accounting_analytics',
			},
		},
		booking: {
			text: 'Booking',
			icon: 'Bookings',
			path: '/analytics/booking',
			permission: 'access_bookings_analytics',
			dashboard: {
				text: 'Booking Analytics Dashboard',
				icon: 'Chart-bar1',
				path: '/analytics/booking/dashboard/*',
				permission: 'access_bookings_analytics',
			},
		},
		campground: {
			text: 'Campground',
			icon: 'Caravan',
			path: '/analytics/campground',
			permission: 'access_campground_analytics',
			dashboard: {
				text: 'Campground Analytics Dashboard',
				icon: 'Chart-bar1',
				path: '/analytics/campground/dashboard/*',
				permission: 'access_campground_analytics',
			},
		},
		crm: {
			text: 'CRM',
			icon: 'Install',
			path: '/analytics/crm',
			permission: 'access_customers_analytics',
			dashboard: {
				text: 'CRM Analytics Dashboard',
				icon: 'Chart-bar1',
				path: '/analytics/crm/dashboard/*',
				permission: 'access_customers_analytics',
			},
		},
		marina: {
			text: 'Marina',
			icon: 'Marina',
			path: '/analytics/marina',
			permission: 'access_marina_analytics',
			dashboard: {
				text: 'Marina Analytics Dashboard',
				icon: 'Chart-bar1',
				path: '/analytics/marina/dashboard/*',
				permission: 'access_marina_analytics',
			},
		},
		pos: {
			text: 'POS',
			icon: 'TV#1',
			path: '/analytics/pos',
			permission: 'access_pos_analytics',
			dashboard: {
				text: 'POS Analytics Dashboard',
				icon: 'Chart-bar1',
				path: '/analytics/pos/dashboard/*',
				permission: 'access_pos_analytics',
			},
		},
	},
};

export const nonTransientAdvancedFormPages = [
	{
		title: 'General',
		description: 'General Reservation information',
		icon: 'Layers',
	},
	{
		title: 'Invoices',
		description: 'Invoices',
		icon: 'Clipboard-list',
	},
	{
		title: 'Payments',
		description: 'Payment History',
		icon: 'Wallet',
	},
	{
		title: 'Space Assignment',
		description: 'Manage Space Assignment',
		icon: 'Commit',
	},
	{
		title: 'Contracts',
		description: 'View Contracts',
		icon: 'Clipboard-check',
	},
];

export const outletSwitchedAlertPages = [
	pages.booking.reservations.new.path,
	pages.booking.reservations.advanced.path,
	pages.marina.reservations.new.path,
	pages.marina.reservations.transient.path,
	pages.marina.reservations.seasonal.path,
	pages.marina.reservations.longTerm.path,
	pages.campground.reservations.new.path,
	pages.campground.reservations.transient.path,
	pages.campground.reservations.seasonal.path,
	pages.campground.reservations.longTerm.path,
	pages.pos.registers.path,
	pages.pos.productGrids.path,
	pages.pos.modifierGroups.path,
	pages.pos.settings.posSettings.path,
	pages.pos.settings.prepStations.path,
	pages.pos.settings.tableMaps.path,
	pages.pos.settings.printLogs.path,
	pages.booking.settings.units.path,
	pages.marina.settings.spaces.path,
	pages.campground.settings.spaces.path,
	pages.crm.invoices.path,
	pages.crm.payments.path,
	pages.crm.creditMemos.path,
	pages.crm.refunds.path,
	pages.crm.statementCharges.path,
	pages.companySettings.outletDefaults.path,
	pages.companySettings.reservationSettings.path,
	pages.companySettings.templateSettings.path,
];

export default pages;
