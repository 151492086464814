import React, { useState, useEffect } from 'react';

const Clock = () => {
	const [date, setDate] = useState(new Date());

	useEffect(() => {
		const interval = setInterval(() => setDate(new Date()), 1000);
		return () => clearInterval(interval);
	}, []);

	return (
		<>
			{date.toLocaleTimeString('en-US', {
				hour: '2-digit',
				minute: '2-digit',
				hour12: true,
			})}
		</>
	);
};

export default Clock;
