import React from 'react';
import PropTypes from 'prop-types';

import { modules } from '../../../utils/helpers/apiCall';
import usePages from '../../../utils/hooks/usePages';
import useOutlet from '../../../utils/hooks/useOutlet';

import FormContainer from '../../reusables/template/FormContainer';
import SpaceForm from './SpaceForm';

const CompositeSpaceEdit = ({ match }) => {
	const pages = usePages();

	const [outletKey] = useOutlet(true);

	return (
		<FormContainer
			key={outletKey}
			dataId={match.params.id}
			dataName='campgroundUnits'
			fields={<SpaceForm isComposite />}
			lists={['campgroundBookingTypes', 'unitGroups', 'icons', 'campgroundUnits']}
			pageTitle={pages.campground.settings.compositeSpaces.text}
			icon={pages.campground.settings.compositeSpaces.icon}
			module={modules.CAMPGROUND}
		/>
	);
};

CompositeSpaceEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default CompositeSpaceEdit;
