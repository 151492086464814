import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import TaxRateForm from './TaxRateForm';
import usePages from '../../../utils/hooks/usePages';

const TaxRateEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='taxRates'
			lists={['enumCalculations', 'taxAgencies']}
			fields={<TaxRateForm />}
			pageTitle={pages.accounting.settings.taxRate.text}
			icon={pages.accounting.settings.taxRate.icon}
		/>
	);
};

TaxRateEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default TaxRateEdit;
