import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { useLocation } from 'react-router-dom';

import forms from '../../forms';
import pages from '../../pages';
import HeaderContext from '../../../app/contexts/HeaderContext';
import { messageStatuses } from '../../../utils/constants/constants';
import apiCall from '../../../utils/helpers/apiCall';
import { addErrorNotification, dateFormatter } from '../../../utils/helpers/helper';

import ListContainer from '../../reusables/template/ListContainer';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import Badge from '../../reusables/element/Badge';

const DateCell = ({ data }) => dateFormatter(moment(data.date).toDate());

const StatusCell = ({ data }) => {
	const colors = {
		read: 'success',
		unread: 'info',
		archived: 'secondary',
		sent: 'warning',
	};

	return (
		<Badge
			design={colors[data.status.value]}
			isInline
			isUnified
			fontWeight='bold'
			size='lg'
			className='sdms-text-overflow'>
			<span className='sdms-text-overflow sdms-font-transform-c'>{data.status.value}</span>
		</Badge>
	);
};

StatusCell.propTypes = {
	data: PropTypes.shape({
		status: PropTypes.shape({
			value: PropTypes.string,
		}),
	}),
};

StatusCell.defaultProps = {
	data: {
		status: { value: 'read' },
	},
};

const MessagesList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	const location = useLocation();

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.messages.default.text, path: pages.messages.dashboard.path },
			{
				title: pages.messages.read.text,
				path: pages.messages.read.path,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(pages.messages.read.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	const data = useMemo(() => {
		const _data = {
			status: 'all',
			staticFilters: {},
			columns: [
				<List.Col label='From' cellData='fromEmail' isLinkColumn sortable='fromEmail' />,
				<List.Col label='To' cellData='toEmail' isLinkColumn sortable='toEmail' />,
				<List.Col label='Subject' cellData='subject' sortable='subject' />,
				<List.Col label='Received' cellComponent={<DateCell />} sortable='date' />,
			],
		};

		Object.values(messageStatuses).forEach(status => {
			if (location.pathname.indexOf(status) > -1 && !_data.staticFilters['status.value']) {
				_data.staticFilters['status.value'] = status;
				_data.status = status;
			}
		});

		if (_data.status === 'all')
			_data.columns.push(<List.Col label='Status' cellComponent={<StatusCell />} />);

		_data.columns.push(
			<List.Col align='right' onlyHover width={150}>
				<Dropdown
					icon='Other#1'
					color='clean'
					inline
					aligned='right'
					circle
					outline={false}>
					<Dropdown.Header>Other Actions</Dropdown.Header>
					<Dropdown.Item itemsColor='success' icon='Erase' key='read'>
						Mark as Read
					</Dropdown.Item>
					<Dropdown.Item itemsColor='info' icon='Erase' key='unRead'>
						Mark as Unread
					</Dropdown.Item>
					<Dropdown.Item itemsColor='secondary' icon='Erase' key='archive'>
						Move to Archive
					</Dropdown.Item>
					<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
						Delete
					</Dropdown.Item>
				</Dropdown>
			</List.Col>
		);

		return _data;
	}, [location]);

	const onChangeStatus = (message, updateData, status) => {
		apiCall(
			'POST',
			'changeMessageStatus',
			res => {
				updateData(
					data.status === 'all' ? [res] : [],
					data.status === 'all' ? [] : [message.id]
				);
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ id: message.id, status }
		);
	};

	return (
		<ListContainer
			route='messages'
			title='Messages'
			fluid='fluid'
			history={history}
			forms={forms.messages}
			checkEditable={() => []}
			staticFilters={data.staticFilters}
			customActions={{
				read: (message, updateData) =>
					onChangeStatus(message, updateData, messageStatuses.READ),
				unRead: (message, updateData) =>
					onChangeStatus(message, updateData, messageStatuses.UNREAD),
				archive: (message, updateData) =>
					onChangeStatus(message, updateData, messageStatuses.ARCHIVED),
			}}>
			<List
				withCheckBox
				fluid='fluid'
				checkEditable={message => {
					const actions = ['edit', 'delete'];

					if (message.status.value === messageStatuses.UNREAD)
						return [...actions, 'read', 'archive'];

					if (message.status.value === messageStatuses.READ)
						return [...actions, 'unRead', 'archive'];

					return [...actions, 'read', 'unRead'];
				}}>
				{data.columns}
			</List>
		</ListContainer>
	);
};

MessagesList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default MessagesList;
