import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import forms from '../../forms';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';

const UnitsCell = ({ data }) => (data.units || []).map(u => u.name).join(', ');

const CompositeSpaceList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
			{ title: pages.campground.settings.text, path: pages.campground.settings.path },
			{ title: pages.campground.settings.compositeSpaces.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.campground.settings.compositeSpaces.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='campgroundUnits'
			title={pages.campground.settings.compositeSpaces.text}
			history={history}
			forms={forms.campground.settings.compositeSpaces}
			icon={pages.campground.settings.compositeSpaces.icon}
			staticFilters={{ isComposite: true }}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col label='Spaces' cellComponent={<UnitsCell />} />
				<List.Col
					label='Type'
					cellData='name'
					cellDataObject='bookingType'
					sortable='bookingType.name'
				/>
				<List.Col
					label='Unit Group'
					cellData='name'
					cellDataObject='unitGroup'
					sortable='unitGroup.name'
				/>
				<List.Col label='Description' cellData='description' sortable='description' />
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Erase' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

CompositeSpaceList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default CompositeSpaceList;
