import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import pages from '../../../pages';
import { modules } from '../../../../utils/helpers/apiCall';
import { getModuleByPath } from '../../../../utils/helpers/reusable';

import FormContainer from '../../template/FormContainer';
import UnitGroupsForm from './UnitGroupsForm';

const UnitGroupsEdit = ({ match }) => {
	const location = useLocation(); // React Hook

	const data = useMemo(() => {
		const module = getModuleByPath(location.pathname);

		if (module === modules.MARINA)
			return {
				title: pages.marina.settings.spaceGroups.text,
				icon: pages.marina.settings.spaceGroups.icon,
				module: modules.MARINA,
			};

		if (module === modules.CAMPGROUND)
			return {
				title: pages.campground.settings.maps.text,
				icon: pages.campground.settings.maps.icon,
				module: modules.CAMPGROUND,
			};

		return {
			title: pages.booking.settings.maps.text,
			icon: pages.booking.settings.maps.icon,
			module: modules.BOOKINGS,
		};
	}, [location]);

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='unitGroups'
			fields={<UnitGroupsForm module={data.module} />}
			pageTitle={data.title}
			icon={data.icon}
		/>
	);
};

UnitGroupsEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default UnitGroupsEdit;
