import React, { useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import update from 'immutability-helper';

import useField from '../../../utils/hooks/useField';
import {
	checkValidation,
	creditCardRequired as creditCardValidation,
	required,
	zero,
} from '../../../utils/helpers/validation';
import {
	addErrorNotification,
	addFloats,
	calculateServiceFee,
	filterCustomerPaymentMethods,
	getAccountingClosedDate,
	getPaymentProcessorServiceFeeAmount,
	getRemittanceRemainingAmount,
	getSettlementMaxPaymentAmount,
	numberParser,
	priceFormatter,
} from '../../../utils/helpers/helper';
import {
	paymentStatuses,
	paymentTypes as _paymentTypes,
	serviceFeeModules,
} from '../../../utils/constants/constants';
import apiCall from '../../../utils/helpers/apiCall';
import UserContext from '../../../app/contexts/UserContext';
import { getPaymentProcessor } from '../../../utils/hooks/usePaymentGateway';
import useDate from '../../../utils/hooks/useDate';
import usePages from '../../../utils/hooks/usePages';

import Portlet from '../../reusables/layout/Portlet';
import FormGroup from '../../reusables/layout/FormGroup';
import Loading from '../../reusables/template/Loading';
import FormField from '../../reusables/template/FormField';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import Input from '../../reusables/field/Input';
import Radio from '../../reusables/field/Radio';
import SVGIcon from '../../reusables/element/SVGIcon';
import DatePicker from '../../reusables/field/DatePicker';
import CreditCardForm from '../../reusables/element/CreditCardForm';
import { ListBody, ListTable } from '../../reusables/template/List';
import Button from '../../reusables/element/Button';
import HeaderContext from '../../../app/contexts/HeaderContext';
import Alert from '../../reusables/element/Alert';
import CheckForm from '../../reusables/element/CheckForm';

const SettlementItem = ({ index, data, settlements, refundAmount, disabled, onAmountChange }) => {
	const [dateFormatter] = useDate();

	const maxSettlementAmount = getSettlementMaxPaymentAmount(settlements, index, refundAmount);

	return (
		<tr>
			<td className='sdms-vertical-middle sdms-align-center'>
				<input
					id='checkbox-head'
					type='checkbox'
					onChange={() => {
						onAmountChange(data.amount ? '' : maxSettlementAmount);
					}}
					checked={data.amount !== '' && parseFloat(data.amount) > 0}
					disabled={maxSettlementAmount <= 0 || disabled}
				/>
			</td>
			<td className='sdms-vertical-middle sdms-align-center'>
				{dateFormatter(data.transactionDate, false)}
			</td>
			<td className='sdms-vertical-middle sdms-align-center'>
				<a href={data.link} target='_blank' rel='noopener noreferrer'>
					{data.customId}
				</a>
			</td>
			<td className='sdms-vertical-middle sdms-align-center'>{data.type}</td>
			<td className='sdms-vertical-middle sdms-align-center'>
				{priceFormatter(data.originalAmount)}
			</td>
			<td className='sdms-vertical-middle sdms-align-center'>
				{priceFormatter(data.balanceDue)}
			</td>
			<td>
				<FormGroup row isLast className='sdms-flex-center'>
					<FormField name='name' id={data.id} inFormDesign={false} isLast colLg={4}>
						<Input
							placeholder='0.00'
							value={data.amount}
							onChange={e => {
								if (e.target.value && !Number.isNaN(e.target.value)) {
									const maxAmount = getSettlementMaxPaymentAmount(
										settlements,
										index,
										refundAmount
									);

									const _amount =
										maxAmount <= parseFloat(e.target.value)
											? maxAmount
											: e.target.value;

									onAmountChange(_amount);
								} else onAmountChange(e.target.value);
							}}
							disabled={disabled}
							pattern={process.env.REACT_APP_PRICE_PATTERN}
						/>
					</FormField>
				</FormGroup>
			</td>
		</tr>
	);
};
SettlementItem.propTypes = {
	index: PropTypes.number,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	settlements: PropTypes.arrayOf(PropTypes.object),
	refundAmount: PropTypes.number,
	disabled: PropTypes.bool,
	onAmountChange: PropTypes.func,
};

SettlementItem.defaultProps = {
	index: 0,
	data: null,
	settlements: [],
	refundAmount: 0,
	disabled: false,
	onAmountChange: () => {},
};

const RefundForm = ({
	data,
	onFormChange,
	isSubmitted,
	setIsValid,
	isLoading,
	isSubmitting,
	submitButtonAttr,
	submit,
	paymentTypes,
	isEditable,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const [, , parseDatePickerValue, parseDatePickerChange] = useDate();

	const [isAchEnabled, setIsAchEnabled] = useState(false);

	const [isReadOnly, setIsReadOnly] = useState(false);

	const creditCardRequired = () => {
		return (value, setValRes) => {
			if (type === _paymentTypes.CREDIT_CARD) return creditCardValidation(value, setValRes);
			return true;
		};
	};

	const checkNumberRequired = () => {
		return (value, setValRes) => {
			if (type === _paymentTypes.CHECK && !isAchEnabled) {
				return required(value, setValRes);
			}
			return true;
		};
	};

	const achRequired = () => {
		return (value, setValRes) => {
			if (type === _paymentTypes.CHECK && checkNumber === '')
				return checkValidation(value, setValRes);
			return true;
		};
	};

	const [
		customer,
		customerOnChange,
		customerValRes,
		customerShowVal,
		setCustomerShowVal,
	] = useField(data, 'customer', onFormChange, [required], null);

	const [
		refundDate,
		refundDateOnChange,
		refundDateValRes,
		refundDateShowVal,
		setRefundDateShowVal,
	] = useField(
		data,
		'refundDate',
		onFormChange,
		[required],
		data.id ? data.refundDate || data.timeCreated : null
	);

	const [type, typeOnChange, typeValRes, typeShowVal, setTypeShowVal] = useField(
		data,
		'type',
		onFormChange,
		[required],
		null
	);

	const [amount, amountOnChange, amountValRes, amountShowVal, setAmountShowVal] = useField(
		data,
		'amount',
		onFormChange,
		[required, zero],
		'',
		numberParser(true)
	);

	const [serviceFee, serviceFeeOnChange] = useField(
		data,
		'serviceFee',
		onFormChange,
		[],
		'',
		numberParser(true)
	);

	const [
		checkNumber,
		checkNumberOnChange,
		checkNumberValRes,
		checkNumberShowVal,
		setCheckNumberShowVal,
		validateCheckNumber,
	] = useField(data, 'number', onFormChange, [checkNumberRequired()]);

	const [
		,
		creditCardOnChange,
		creditCardValRes,
		,
		setCreditCardShowVal,
		validateCreditCard,
	] = useField(data, 'creditCard', onFormChange, [creditCardRequired()], null);

	const [
		achAccount,
		achAccountOnChange,
		achAccountValRes,
		,
		setAchAccountShowVal,
		validateAchAccount,
	] = useField(data, 'achAccount', onFormChange, [achRequired()], null);

	const [reference, referenceOnChange] = useField(data, 'reference', onFormChange);

	const [settlements, setSettlements] = useState([]);

	const [isSettlementsLoading, setIsSettlementsLoading] = useState(false);

	const BANNER = useMemo(() => {
		if (data.refundOrder)
			return (
				<div className='col-12 sdms-mb-20'>
					<Alert solid icon='Info-circle' design='info'>
						Credit Memo Refund
					</Alert>
				</div>
			);

		return null;
	}, [data.refundOrder]);

	const serviceFeeAmount = useMemo(
		() =>
			getPaymentProcessorServiceFeeAmount(
				userContext.data.selectedOutlet,
				serviceFeeModules.CRM,
				getPaymentProcessor(userContext.data.selectedOutlet, type)
			),
		[userContext.data.selectedOutlet, type]
	);

	const creditCardProcessor = useMemo(
		() => getPaymentProcessor(userContext.data.selectedOutlet, _paymentTypes.CREDIT_CARD),
		[userContext.data.selectedOutlet]
	);

	const checkProcessor = useMemo(
		() => getPaymentProcessor(userContext.data.selectedOutlet, _paymentTypes.CHECK),
		[userContext.data.selectedOutlet]
	);

	const getRefundInfo = () => {
		if (data.id === 0 || !type || type === _paymentTypes.CASH) return null;

		let content = '';

		if (type === _paymentTypes.CHECK) {
			if (data.bankName && data.accountName)
				content = `ACH Account: ${data.bankName} - ${
					data.accountName
				} - ${data.routingNumber || '#'} - ${data.accountNumber || '#'}`;
			else if (data.number) content = `Check Number: ${data.number}`;
		}

		if (type === _paymentTypes.CREDIT_CARD) content = `Card Number: ${data.cardNumber || '#'}`;

		return <div className='col-lg-6 sdms-font-bold'>{content}</div>;
	};

	const getTotalSettlement = () => {
		const _settlements = settlements
			.filter(s => !Number.isNaN(parseFloat(s.amount)))
			.map(s => parseFloat(s.amount));

		return _settlements.length ? _settlements.reduce((a, b) => a + b) : 0;
	};

	const getSettlementItems = (customerSettlements, payments) => {
		const _settlements = [];

		if (customerSettlements)
			_settlements.push(
				...customerSettlements
					.filter(
						cs => cs.remittance && cs.remittance.status.value !== paymentStatuses.VOIDED
					)
					.map(cs => ({
						id: cs.id,
						amount: cs.amount,
						payment: cs.remittance,
						customId: cs.remittance.remittanceId,
						transactionDate: cs.remittance.remittanceDate || cs.remittance.timeCreated,
						originalAmount: cs.remittance.amount,
						balanceDue: addFloats(
							getRemittanceRemainingAmount({
								...cs.remittance,
								customerSettlements: [...cs.remittance.customerSettlements, cs],
							}),
							cs.amount
						),
						timeModified: cs.remittance.timeModified,
						type: cs.remittance['@type'] === 'SdmsCredit' ? 'Credit' : 'Payment',
						link:
							cs.remittance['@type'] === 'SdmsCredit'
								? `${window.location.origin}${pages.crm.creditMemos.path}/${cs.remittance.id}`
								: `${window.location.origin}${pages.crm.payments.path}/${cs.remittance.id}`,
					}))
			);

		_settlements.push(
			...payments
				.filter(p => _settlements.findIndex(s => s.payment && s.payment.id === p.id) === -1)
				.map(p => ({
					id: 0,
					amount: 0,
					payment: p,
					customId: p.remittanceId,
					transactionDate: p.remittanceDate || p.timeCreated,
					originalAmount: p.amount,
					balanceDue: getRemittanceRemainingAmount(p),
					timeModified: p.timeModified,
					type: p['@type'] === 'SdmsCredit' ? 'Credit' : 'Payment',
					link:
						p['@type'] === 'SdmsCredit'
							? `${window.location.origin}${pages.crm.creditMemos.path}/${p.id}`
							: `${window.location.origin}${pages.crm.payments.path}/${p.id}`,
				}))
		);

		return _settlements;
	};

	useEffect(() => {
		if (isSubmitted) {
			setCustomerShowVal();
			setRefundDateShowVal();
			setAmountShowVal();
			setTypeShowVal();
			setCheckNumberShowVal();
			setCreditCardShowVal();
			setAchAccountShowVal();
		}
	}, [
		isSubmitted,
		setCustomerShowVal,
		setRefundDateShowVal,
		setAmountShowVal,
		setTypeShowVal,
		setCheckNumberShowVal,
		setCreditCardShowVal,
		setAchAccountShowVal,
	]);

	useEffect(() => {
		// disable save for voided refunds.
		if (data.id !== 0 && !data.amount) setIsValid(false);
		else
			setIsValid(
				customerValRes.isValid &&
					refundDateValRes.isValid &&
					amountValRes.isValid &&
					typeValRes.isValid &&
					achAccountValRes.isValid &&
					creditCardValRes.isValid &&
					creditCardValRes.isValid &&
					!isReadOnly
			);
	}, [
		data,
		setIsValid,
		customerValRes.isValid,
		refundDateValRes.isValid,
		amountValRes.isValid,
		typeValRes.isValid,
		checkNumberValRes.isValid,
		achAccountValRes.isValid,
		creditCardValRes.isValid,
		isReadOnly,
	]);

	useEffect(() => {
		if (data.id === 0) {
			validateCheckNumber();
			validateCreditCard();
			validateAchAccount();
			setIsAchEnabled(false);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [type]);

	useEffect(() => {
		if (data.id === 0) validateCheckNumber(checkNumber);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isAchEnabled]);

	useEffect(() => {
		if (data.id === 0) validateAchAccount(achAccount);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [checkNumber]);

	useEffect(() => {
		if (data.id === 0) {
			serviceFeeOnChange({
				target: {
					value: checkNumber ? 0 : calculateServiceFee(amount, serviceFeeAmount),
				},
			});
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [amount, type, achAccount, checkNumber]);

	useEffect(() => {
		if (!customer || isLoading) {
			setSettlements([]);
			return;
		}

		if (
			data.id !== 0 &&
			data.customerSettlements.filter(cs => cs.remittance['@type'] === 'SdmsCredit').length >
				0
		) {
			setSettlements(getSettlementItems(data.customerSettlements, []));
		} else {
			setIsSettlementsLoading(true);
			apiCall(
				'GET',
				'payments',
				res => {
					setSettlements(getSettlementItems(data.customerSettlements, res));
					setIsSettlementsLoading(false);
				},
				err => {
					addErrorNotification(err.toString());
					setIsSettlementsLoading(false);
				},
				'',
				null,
				{
					'customer.id': customer.id,
					'status.value': paymentStatuses.UNSETTLED,
					pagination: false,
				}
			);
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [customer, isLoading]);

	useEffect(() => {
		data.settlements = [...settlements];
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [settlements]);

	useEffect(() => {
		if (!isLoading && !isSubmitting) {
			typeOnChange({
				target: {
					value:
						data.id !== 0 && data.paymentMethod
							? data.paymentMethod.paymentType.value
							: _paymentTypes.CASH,
				},
			});

			if (data.id === 0) data.outlet = userContext.data.selectedOutlet;
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading, isSubmitting]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.crm.dashboard.text,
				path: pages.crm.dashboard.path,
			},
			{
				title: pages.crm.refunds.text,
				path: pages.crm.refunds.path,
			},

			{ title: data.refundId || `New ${pages.crm.refunds.text}`, isActive: true },
		]);

		headerContext.setPageTitle(data.refundId || `New ${pages.crm.refunds.text}`);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [data]);

	useEffect(() => {
		if (data.id !== 0 && !isLoading) {
			setIsReadOnly(
				data.refundOrder ||
					(data.customerSettlements &&
						data.customerSettlements.some(
							cs =>
								cs.remittance &&
								cs.remittance.customerSettlements.some(_cs => _cs.isDeposit)
						))
			);
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						{BANNER}
						<Loading isLoading={isLoading}>
							<FormField name='refundId' label='Refund Id' id={data.id} colLg={2}>
								<Input
									disabled
									value={data.refundId || ''}
									placeholder='Auto Generate'
									onChange={() => {}}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='customer'
								label='Customer'
								id={data.id}
								colLg={2}
								showValidation={customerShowVal}
								valRes={customerValRes}>
								<AsyncSelect
									options={data.customer ? [data.customer] : []}
									placeholder='Search and select customer'
									value={customer}
									onChange={customerOnChange}
									onBlur={() => setCustomerShowVal()}
									route='customers'
									field='displayName'
									displayKey='displayName'
									disabled={data.id !== 0 || !isEditable}
									customFilters={{ isActive: true }}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='refundDate'
								label='Refund Date'
								id={data.id}
								loadingContainer
								colLg={2}
								valRes={refundDateValRes}
								showValidation={refundDateShowVal}>
								<DatePicker
									id='refundDate'
									type='calendar'
									placeholder='Date'
									value={parseDatePickerValue(refundDate)}
									onChange={e => {
										refundDateOnChange({
											target: {
												value: parseDatePickerChange(e.target.value),
											},
										});
									}}
									onBlur={setRefundDateShowVal}
									disabled={data.id !== 0 || !isEditable}
									disableClear
									minDate={getAccountingClosedDate(userContext)}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='amount'
								label='Amount'
								id={data.id}
								colLg={2}
								showValidation={amountShowVal}
								valRes={amountValRes}>
								<Input
									type='text'
									value={
										data.id === 0
											? amount.toString()
											: priceFormatter(amount - serviceFee)
									}
									placeholder='Amount'
									onChange={amountOnChange}
									onBlur={() => setAmountShowVal()}
									disabled={data.id !== 0 || !isEditable}
									pattern={process.env.REACT_APP_PRICE_PATTERN}
									prependIcon='Dollar'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='serviceFee' label='Service Fee' id={data.id} colLg={2}>
								<Input
									type='text'
									value={
										data.id === 0
											? serviceFee.toString()
											: priceFormatter(serviceFee)
									}
									placeholder='Service Fee'
									onChange={serviceFeeOnChange}
									disabled={
										data.id !== 0 ||
										serviceFeeAmount === 0 ||
										!userContext.hasPermission('edit_cc_service_fee') ||
										!isEditable
									}
									prependIcon='Dollar'
									pattern={process.env.REACT_APP_PRICE_PATTERN}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='total'
								label='Total'
								id={data.id}
								colLg={2}
								showValidation={amountShowVal}
								valRes={amountValRes}>
								<Input
									type='text'
									value={priceFormatter(
										data.id
											? amount
											: parseFloat(amount || 0) + parseFloat(serviceFee || 0)
									)}
									placeholder='Total'
									onChange={() => {}}
									disabled
									prependIcon='Dollar'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='reference' label='Reference' id={data.id} colMd={2}>
								<Input
									type='text'
									value={reference}
									placeholder='Reference'
									onChange={referenceOnChange}
									disabled={data.id !== 0}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='paymentType'
								id={data.id}
								loadingContainer
								colLg={3}
								valRes={typeValRes}
								showValidation={typeShowVal}>
								<Radio.Container isInline>
									{paymentTypes
										.filter(
											pt =>
												pt.value !== _paymentTypes.HOUSE_ACCOUNT &&
												pt.value !== _paymentTypes.BOOKING
										)
										.map(pt => (
											<Radio
												key={pt.value}
												checked={type === pt.value}
												id={pt.value}
												name='paymentTypes'
												content={
													<>
														<SVGIcon
															name={
																(pt.value === _paymentTypes.CASH &&
																	'Dollar') ||
																(pt.value === _paymentTypes.CHECK &&
																	'Ticket') ||
																(pt.value ===
																	_paymentTypes.CREDIT_CARD &&
																	'Credit-card')
															}
														/>
														<div>{pt.value}</div>
													</>
												}
												className='sdms-radio--primary'
												onChange={() => {
													typeOnChange({
														target: {
															value: pt.value,
														},
													});

													if (
														pt.value === _paymentTypes.CASH ||
														pt.value === _paymentTypes.CHECK
													)
														data.changeDue = 0;
												}}
												disabled={data.id !== 0 || !isEditable}
											/>
										))}
								</Radio.Container>
							</FormField>
						</Loading>
						{type === _paymentTypes.CHECK && data.id === 0 && (
							<FormField name='checkForm' colLg={6}>
								<CheckForm
									onChange={_achAccount => {
										achAccountOnChange({
											target: { value: _achAccount },
										});
										checkNumberOnChange({ target: { value: '' } });
									}}
									isDisabled={isSubmitting || !isEditable}
									achAccounts={
										customer
											? filterCustomerPaymentMethods(
													customer.paymentMethods,
													checkProcessor
											  )
											: []
									}
									setIsAchEnabled={setIsAchEnabled}
									hasAch={!!checkProcessor}
									checkNumberProps={{
										isLoading,
										id: data.id,
										data: checkNumber,
										onChange: checkNumberOnChange,
										showVal: checkNumberShowVal,
										valRes: checkNumberValRes,
										setShowVal: setCheckNumberShowVal,
									}}
									isSubmitted={isSubmitted}
									withLabel={false}
									displayCorporateToggle
									displaySaveCardToggle={customer?.canSavePaymentMethod !== false}
									halfInputs
								/>
							</FormField>
						)}
						{type === _paymentTypes.CREDIT_CARD && data.id === 0 && (
							<Loading isLoading={isLoading}>
								<FormField name='creditCardForm' colLg={6}>
									<CreditCardForm
										onChange={_creditCard =>
											creditCardOnChange({
												target: { value: _creditCard },
											})
										}
										isDisabled={isSubmitting || !isEditable}
										isAuthorizing={isSubmitting}
										creditCards={
											customer
												? filterCustomerPaymentMethods(
														customer.paymentMethods,
														creditCardProcessor
												  )
												: []
										}
										isSubmitted={isSubmitted}
										withLabel={false}
										displaySaveCardToggle={
											customer?.canSavePaymentMethod !== false
										}
									/>
								</FormField>
							</Loading>
						)}
						{getRefundInfo()}
					</FormGroup>
				</form>
				{settlements.length > 0 && (
					<ListBody
						className='table--everytime--scroll sdms-portlet__body--fit'
						responsive='scroll'>
						<ListTable isLoading={isSettlementsLoading}>
							<colgroup>
								<col width={40} />
								<col />
								<col />
								<col />
								<col />
								<col />
								<col />
							</colgroup>
							<thead>
								<tr>
									<th className='sdms-align-center'>
										<input
											id='checkbox-head'
											type='checkbox'
											onClick={() => {}}
											defaultChecked={isEditable}
											readOnly
											disabled={!isEditable}
										/>
									</th>
									<th className='sdms-align-center'>Date</th>
									<th className='sdms-align-center'>Number</th>
									<th className='sdms-align-center'>Type</th>
									<th className='sdms-align-center'>Original Amount</th>
									<th className='sdms-align-center'>Amount Due</th>
									<th className='sdms-align-center'>Payment</th>
								</tr>
							</thead>
							<tbody>
								{settlements.map((s, index) => (
									<SettlementItem
										key={s.customId}
										index={index}
										data={s}
										settlements={settlements}
										refundAmount={amount + (data.id === 0 ? 0 : serviceFee)}
										disabled={
											amount === '' ||
											isSubmitting ||
											isReadOnly ||
											!isEditable
										}
										onAmountChange={_amount =>
											setSettlements(
												update(settlements, {
													[index]: { amount: { $set: _amount } },
												})
											)
										}
									/>
								))}
							</tbody>
							<tfoot>
								<tr>
									<th colSpan={4}>Totals</th>
									<th className='sdms-align-center'>
										{priceFormatter(
											settlements.length
												? settlements
														.map(s => s.originalAmount)
														.reduce((a, b) => a + b)
												: 0
										)}
									</th>
									<th className='sdms-align-center'>
										{priceFormatter(
											settlements.length
												? settlements
														.map(s => s.balanceDue)
														.reduce((a, b) => a + b)
												: 0
										)}
									</th>
									<th className='sdms-align-center'>
										{priceFormatter(getTotalSettlement())}
									</th>
								</tr>
							</tfoot>
						</ListTable>
					</ListBody>
				)}
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
					disabled={isReadOnly || !isEditable}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};

RefundForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setIsValid: PropTypes.func,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
	paymentTypes: PropTypes.arrayOf(PropTypes.object),
	isSubmitting: PropTypes.bool,
	isEditable: PropTypes.bool,
};
RefundForm.defaultProps = {
	data: null,
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
	paymentTypes: [],
	isSubmitting: false,
	isEditable: true,
};

export default RefundForm;
