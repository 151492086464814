import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactToPrint from 'react-to-print';
import Popup from 'reactjs-popup';

import {
	paymentTypes,
	paymentStatuses,
	serviceFeeModules,
	filterComponents,
} from '../../../utils/constants/constants';
import {
	addErrorNotification,
	calculateServiceFee,
	filterCustomerPaymentMethods,
	getPaymentProcessorServiceFeeAmount,
	numberFormat,
	addSuccessNotification,
	getServiceFeeText,
} from '../../../utils/helpers/helper';
import usePrint from '../../../utils/hooks/usePrint';
import usePaymentGateway, {
	customerPaymentForms,
	getPaymentProcessor,
	parsePaymentData,
} from '../../../utils/hooks/usePaymentGateway';
import apiCall from '../../../utils/helpers/apiCall';
import { getOnlinePortalDefaultFilters } from './functions';

import ListContainer from '../../reusables/template/ListContainer';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Portlet from '../../reusables/layout/Portlet';
import SVGIcon from '../../reusables/element/SVGIcon';
import Portal from '../../reusables/layout/Portal';
import PaymentMethodSelect from '../../reusables/element/PaymentMethodSelect';
import CheckForm from '../../reusables/element/CheckForm';
import CreditCardForm from '../../reusables/element/CreditCardForm';
import Alert from '../../reusables/element/Alert';
import {
	PaymentAmountCell,
	PaymentDateCell,
	PaymentTypeCell,
	PaymentStatusCell,
	SettledCell,
	BalanceCell,
} from '../../reusables/element/PaymentListCells';

const PaymentList = ({ data, outlet, onNewPayment }) => {
	const [onPrintReceipt, PRINT_COMPONENT] = usePrint();

	const onPrint = payment => {
		apiCall(
			'POST',
			'onlinePortalGetPrintPaymentContent',
			res => {
				onPrintReceipt(res);
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ paymentId: payment.id, outletId: outlet.id },
			getOnlinePortalDefaultFilters(data.customer, outlet)
		);
	};

	const onSendEmail = payment => {
		apiCall(
			'POST',
			'onlinePortalGetSendPaymentEmail',
			() => {
				addSuccessNotification('Email(s) successfully sent');
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{
				paymentId: payment.id,
				outletId: outlet.id,
			},
			getOnlinePortalDefaultFilters(data.customer, outlet)
		);
	};

	return (
		<>
			<ListContainer
				route='onlinePortalGetPayments'
				title='Payments'
				icon='Wallet'
				staticFilters={getOnlinePortalDefaultFilters(data.customer, outlet)}
				customActions={{
					printReceipt: onPrint,
					sendEmail: onSendEmail,
				}}
				isTabList
				largeQuickPanel
				forms={[]}
				fluid='fluid'
				hasSearch={false}
				headerActions={[
					{
						text: 'New Payment',
						icon: 'Plus',
						design: 'brand',
						onClick: () => {
							onNewPayment();
						},
						outline: false,
						disabled: data.customer.customerBalance <= 0,
					},
				]}
				customFilters={[
					{
						component: filterComponents.SELECTS,
						dataName: 'onlinePortalGetRemittanceStatuses',
						fieldName: 'status_id',
						placeholder: 'All Payments',
						displayKey: 'value',
					},
				]}
				customFiltersInQuickPanel={false}>
				<List
					fluid='fluid'
					withCheckBox={false}
					checkEditable={payment => {
						const actions = [];
						if (
							payment.status.value === paymentStatuses.UNSETTLED ||
							payment.status.value === paymentStatuses.SETTLED
						) {
							actions.push('sendEmail', 'printReceipt');
						}
						return actions;
					}}
					withOutPortlet>
					<List.Col label='Payment' cellData='remittanceId' isLinkColumn />
					<List.Col
						label='Date'
						cellComponent={<PaymentDateCell />}
						id='remittanceDate'
						sortable='remittanceDate'
					/>
					<List.Col label='Type' cellComponent={<PaymentTypeCell />} />
					<List.Col label='Amount' cellComponent={<PaymentAmountCell />} />
					<List.Col label='Settled' cellComponent={<SettledCell />} />
					<List.Col label='Balance' cellComponent={<BalanceCell />} />
					<List.Col label='Status' cellComponent={<PaymentStatusCell />} />
					<List.Col align='right' onlyHover width={150}>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item icon='Printer' key='printReceipt'>
								Print Receipt
							</Dropdown.Item>
							<Dropdown.Item icon='Mail-box' key='sendEmail'>
								Email Payment
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			{PRINT_COMPONENT}
		</>
	);
};

PaymentList.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	onNewPayment: PropTypes.func.isRequired,
};

export const PaymentForm = ({
	data,
	balanceDue,
	onCancel,
	outlet,
	updateCustomer,
	onPaid,
	invoice,
	isOnline,
}) => {
	const printButtonRef = useRef();

	const printContentRef = useRef();

	const [printContent, setPrintContent] = useState('');

	const [isTermsOfServiceModalOpen, setIsTermsOfServiceModalOpen] = useState(false);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [submitButtonAttr, setSubmitButtonAttr] = useState({
		text: 'Complete Payment',
		icon: 'Dollar',
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	});

	const [amount, setAmount] = useState(balanceDue);

	const [serviceFee, setServiceFee] = useState(0);

	const [payment, setPayment] = useState({
		number: '',
	});

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

	const [tokenizePayment] = usePaymentGateway(outlet, data.paymentMethods);

	const paymentProcessor = useMemo(
		() =>
			getPaymentProcessor(
				outlet,
				selectedPaymentMethod,
				customerPaymentForms.PORTAL_PAYMENTS
			),
		[selectedPaymentMethod, outlet]
	);

	const serviceFeeAmount = useMemo(
		() =>
			getPaymentProcessorServiceFeeAmount(
				outlet,
				serviceFeeModules.ONLINE_PAYMENTS,
				getPaymentProcessor(
					outlet,
					selectedPaymentMethod,
					customerPaymentForms.PORTAL_PAYMENTS
				)
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[paymentProcessor]
	);

	const isValid = () => {
		if (!payment) return false;

		if (selectedPaymentMethod === paymentTypes.CREDIT_CARD) {
			return (
				!!payment.creditCard ||
				(payment.cardNumber !== '' &&
					payment.name !== '' &&
					payment.expiry !== '' &&
					payment.cvc !== '')
			);
		}

		if (selectedPaymentMethod === paymentTypes.CHECK)
			return (
				!!payment.achAccount ||
				(payment.bankName !== '' &&
					payment.accountName !== '' &&
					payment.routingNumber !== '' &&
					payment.accountNumber !== '')
			);

		return false;
	};

	const onFail = err => {
		setSubmitButtonAttr({
			text: 'Complete Payment',
			icon: 'Dollar',
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});
		setIsSubmitting(false);
		addErrorNotification(err.toString().replace('Error:', ''));
	};

	const onPay = () => {
		setIsSubmitted(true);

		if (!isValid()) {
			addErrorNotification('Please fill all payment fields.');
			return;
		}

		setIsSubmitting(true);

		setSubmitButtonAttr({
			text: 'Paying...',
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});

		let paymentData = {
			...payment,
			amount,
			serviceFee,
		};

		tokenizePayment(
			payment.type,
			parsePaymentData(payment.type, payment),
			tokenizedPayment => {
				if (tokenizedPayment) paymentData = { ...paymentData, ...tokenizedPayment };

				apiCall(
					'POST',
					'onlinePortalCreatePayment',
					res => {
						setIsSubmitting(false);

						setSubmitButtonAttr({
							text: 'Paid',
							icon: 'Dollar',
							color: process.env.REACT_APP_SUBMIT_BUTTON_SAVED_COLOR,
						});

						updateCustomer(res);

						onPaid();
					},
					onFail,
					'',
					{
						payment: paymentData,
						outletId: outlet.id,
						invoiceId: invoice ? invoice.id : null,
						isOnline,
					},
					getOnlinePortalDefaultFilters(data.customer, outlet)
				);
			},
			() => onFail('An error occurred')
		);
	};

	const onPrintInvoice = invoiceId => {
		apiCall(
			'POST',
			'onlinePortalGetPrintInvoiceContent',
			res => {
				setPrintContent(res);
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ invoiceId, outletId: outlet.id },
			getOnlinePortalDefaultFilters(data.customer, outlet)
		);
	};

	useEffect(() => {
		if (printContent !== '' && printButtonRef.current) {
			printButtonRef.current.click();
			setTimeout(() => {
				setPrintContent('');
			}, 1000);
		}
	}, [printContent]);

	useEffect(() => {
		setServiceFee(calculateServiceFee(amount, serviceFeeAmount));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [amount, selectedPaymentMethod]);

	return (
		<>
			<Portlet
				className='sdms-bg-transparent sdms-online-portal-payment-form sdms-online-portal-not-shadow'
				hasFrame={false}
				fluid='fluid'>
				{invoice && (
					<>
						<Portlet.Head className='row no-min-height'>
							<Portlet.HeadLabel>
								<h3 className='sdms-portlet__head-title'>{`Invoice ${invoice.invoiceId}`}</h3>
							</Portlet.HeadLabel>
							<Portlet.HeadToolbarActions>
								<Button
									className='sdms-mw-100'
									label={process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR}
									text='Print Invoice'
									icon='Clipboard'
									size='sm'
									onClick={() => {
										onPrintInvoice(invoice.id);
									}}
								/>
							</Portlet.HeadToolbarActions>
						</Portlet.Head>
					</>
				)}
				<Portlet.Body className='no-top-padding'>
					<PaymentMethodSelect
						availablePaymentTypes={[paymentTypes.CREDIT_CARD, paymentTypes.CHECK]}
						paymentProcessors={outlet.paymentProcessors}
						onSelect={type => setSelectedPaymentMethod(type)}
						selected={selectedPaymentMethod}
						render={content => (
							<FormGroup isLast>
								<div className='col-lg-12 sdms-online-portal-payment-form-section-title'>
									<SVGIcon name='Dollar' /> PAYMENT METHOD
								</div>
								<div className='col-lg-12'>
									<div className='row sdms-online-portal-bg-white sdms-online-portal-payment-form-section-content'>
										{content}
									</div>
								</div>
							</FormGroup>
						)}
						customerPaymentForm={customerPaymentForms.PORTAL_PAYMENTS}
					/>
					<FormGroup isLast>
						<div className='col-lg-12 sdms-online-portal-payment-form-section-title'>
							<SVGIcon name='Dollar' /> AMOUNT
						</div>
						<div className='col-lg-12'>
							<div className='row sdms-online-portal-bg-white sdms-online-portal-payment-form-section-content'>
								<div className='col-lg-4'>
									<FormField
										label='Balance Due'
										name='balanceDue'
										id={0}
										col={12}
										inFormDesign={false}>
										<Input
											type='text'
											placeholder='Balance Due'
											className='form-control'
											value={balanceDue.toFixed(2)}
											onChange={() => {}}
											disabled
										/>
									</FormField>
								</div>
								<div className='col-lg-4'>
									<FormField
										label='Amount'
										name='amount'
										id={0}
										col={12}
										inFormDesign={false}>
										<Input
											value={amount}
											type='text'
											className='form-control'
											placeholder='Amount'
											onChange={e => {
												if (e.target.value && e.target.value > balanceDue)
													setAmount(balanceDue);
												else setAmount(e.target.value);
											}}
											pattern={process.env.REACT_APP_PRICE_PATTERN}
										/>
									</FormField>
								</div>
								{serviceFeeAmount !== 0 && (
									<div className='col-lg-4'>
										<FormField
											label={getServiceFeeText(null, outlet)}
											name='serviceFee'
											id={0}
											col={12}
											inFormDesign={false}>
											<Input
												className='form-control'
												type='text'
												placeholder={getServiceFeeText(null, outlet)}
												value={serviceFee}
												onChange={() => {}}
												disabled
											/>
										</FormField>
									</div>
								)}
							</div>
						</div>
					</FormGroup>

					<FormGroup isLast>
						<div className='col-lg-12 sdms-online-portal-payment-form-section-title'>
							<SVGIcon name='Dollar' /> PAYMENT
						</div>
						<div className='col-lg-12'>
							<div className='row sdms-online-portal-bg-white sdms-online-portal-payment-form-section-content'>
								{selectedPaymentMethod === paymentTypes.CHECK && (
									<CheckForm
										onlyAch
										onChange={achAccount =>
											setPayment({
												...payment,
												...achAccount,
												isWeb: isOnline,
												type: paymentTypes.CHECK,
											})
										}
										achAccounts={filterCustomerPaymentMethods(
											data.customer.paymentMethods,
											getPaymentProcessor(
												outlet,
												paymentTypes.CHECK,
												customerPaymentForms.PORTAL_PAYMENTS
											)
										)}
										hasAch={
											!!getPaymentProcessor(
												outlet,
												paymentTypes.CHECK,
												customerPaymentForms.PORTAL_PAYMENTS
											)
										}
										isSubmitted={isSubmitted}
										displaySaveCardToggle={
											data.customer?.canSavePaymentMethod !== false
										}
										halfInputs
									/>
								)}
								{selectedPaymentMethod === paymentTypes.CREDIT_CARD && (
									<CreditCardForm
										isDisabled={false}
										isAuthorizing={false}
										disableScanner
										displayInputPrependIcon={false}
										creditCards={filterCustomerPaymentMethods(
											data.customer.paymentMethods,
											getPaymentProcessor(
												outlet,
												paymentTypes.CREDIT_CARD,
												customerPaymentForms.PORTAL_PAYMENTS
											)
										)}
										onChange={creditCard => {
											setPayment({
												...payment,
												...creditCard,
												type: paymentTypes.CREDIT_CARD,
											});
										}}
										isSubmitted={isSubmitted}
										autoComplete
										displaySaveCardToggle={
											data.customer?.canSavePaymentMethod !== false
										}
									/>
								)}

								{selectedPaymentMethod === '' && (
									<div className='col-12'>
										<Alert solid icon='Info-circle' design='info'>
											No Available Payment Method
										</Alert>
									</div>
								)}
							</div>
						</div>
					</FormGroup>
				</Portlet.Body>
				<Portlet.Foot>
					<div className='col-lg-12 sdms-online-portal-payment-form-section-title'>
						<SVGIcon name='File-done' /> CONFIRM
					</div>
					<div className='col-lg-12'>
						<div className='row sdms-online-portal-bg-white sdms-online-portal-payment-form-section-content'>
							<div className='col-lg-4 col-12 sdms-online-portal-payment-form-footer-col'>
								{onCancel && (
									<Button
										className={classNames(' sdms-mw-100')}
										label='clean'
										text='Cancel'
										icon='Backspace'
										size='sm'
										onClick={onCancel}
									/>
								)}
							</div>
							{paymentProcessor?.termsOfService && (
								<div className='col-lg-4 col-12 text-center sdms-online-portal-terms-of-service sdms-online-portal-payment-form-footer-col'>
									I accept{' '}
									<span
										className='sdms-link'
										role='presentation'
										onClick={() => setIsTermsOfServiceModalOpen(true)}>
										Terms of Service
									</span>
								</div>
							)}
							<div className='col-lg-4 col-12 text-right sdms-online-portal-payment-form-footer-col'>
								<Button
									className={classNames(' sdms-mw-100', {
										'sdms-fading-dots':
											submitButtonAttr.text ===
											process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
									})}
									label={submitButtonAttr.color}
									text={submitButtonAttr.text}
									icon={submitButtonAttr.icon}
									size='sm'
									onClick={onPay}
									disabled={
										amount === '' ||
										amount === '0' ||
										isSubmitting ||
										selectedPaymentMethod === ''
									}
								/>
							</div>
						</div>
					</div>
				</Portlet.Foot>
			</Portlet>
			<Popup
				open={isTermsOfServiceModalOpen}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
				}}
				closeOnDocumentClick={false}
				modal
				onClose={() => setIsTermsOfServiceModalOpen(false)}>
				<Portlet>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon='File-done'>
							Terms of Service
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<div
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: paymentProcessor?.termsOfService,
							}}
						/>
					</Portlet.Body>
					<Portlet.Foot tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Close'
								icon='Close'
								size='sm'
								elevate
								onClick={() => setIsTermsOfServiceModalOpen(false)}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
			<Portal>
				<div
					className='sdms--only-print'
					ref={printContentRef}
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: printContent }}
				/>
			</Portal>
			<ReactToPrint
				trigger={() => (
					<input
						type='button'
						id='printButton'
						style={{ display: 'none' }}
						ref={printButtonRef}
					/>
				)}
				content={() => printContentRef.current}
				pageStyle='@page { size: auto; } @media print { body { -webkit-print-color-adjust: exact; } }'
			/>
		</>
	);
};

PaymentForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	balanceDue: PropTypes.number.isRequired,
	onCancel: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	invoice: PropTypes.object,
	updateCustomer: PropTypes.func.isRequired,
	onPaid: PropTypes.func.isRequired,
	isOnline: PropTypes.bool.isRequired,
};

PaymentForm.defaultProps = {
	invoice: null,
};

const Payments = ({
	data,
	isActive,
	steps,
	setActiveStep,
	params,
	outlet,
	updateCustomer,
	isOnline,
}) => {
	const [isFormActive, setIsFormActive] = useState(false);

	useEffect(() => {
		setIsFormActive(!!params.amount);
	}, [params]);

	if (!isActive) return null;

	return (
		<div className='sdms-online-portal-list-container'>
			{isFormActive && params.amount ? (
				<PaymentForm
					data={data}
					balanceDue={numberFormat(params.amount)}
					onCancel={() => setIsFormActive(false)}
					outlet={outlet}
					updateCustomer={updateCustomer}
					onPaid={() => setIsFormActive(false)}
					invoice={params.invoice}
					isOnline={isOnline}
				/>
			) : (
				<PaymentList
					data={data}
					outlet={outlet}
					onNewPayment={() =>
						setActiveStep({
							index: steps.PAYMENTS.index,
							amount:
								data.customer.customerBalance <= 0
									? 0
									: data.customer.customerBalance,
						})
					}
				/>
			)}
		</div>
	);
};

Payments.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	isActive: PropTypes.bool.isRequired,
	setActiveStep: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	steps: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	params: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	updateCustomer: PropTypes.func.isRequired,
	isOnline: PropTypes.bool.isRequired,
};

export default Payments;
