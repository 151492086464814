import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Portlet from '../../reusables/layout/Portlet';
import IconBox from '../../reusables/layout/IconBox';
import Section from '../../reusables/layout/Section';
import Separator from '../../reusables/layout/Separator';
import usePages from '../../../utils/hooks/usePages';
import HeaderContext from '../../../app/contexts/HeaderContext';
import ContentInner from '../../reusables/template/ContentInner';
import BreadcrumbContainer from '../../reusables/template/BreadcrumbContainer';
import Button from '../../reusables/element/Button';

const ExtraChargeSelect = () => {
	const pages = usePages();

	const history = useHistory();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
			{ title: pages.campground.settings.text, path: pages.campground.settings.path },
			{
				title: pages.campground.settings.extraCharges.text,
				path: pages.campground.settings.extraCharges.path,
			},
			{ title: 'Select Type', isActive: true },
		]);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title='Select Type' />
					<BreadcrumbContainer />
				</ContentInner.SubHeaderItem>
				<ContentInner.SubHeaderItem type='toolbar'>
					<Button
						design='default'
						text='Back To The List'
						onClick={() => history.push(pages.campground.settings.extraCharges.path)}
					/>
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title='Select Type'>
				<Portlet fluid='fluid'>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon='Plus'>
							Add New Price Adjustments
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<Section title='Per Order' last>
							<Section.Body>
								<div className='row sdms-iconbox--container'>
									<div className='col-xl-4 col-md-6'>
										<IconBox
											icon={
												pages.campground.settings.extraCharges.allOrders
													.icon
											}
											title='All Orders'
											onClick={() =>
												history.push(
													`${pages.campground.settings.extraCharges.allOrders.path}/0`
												)
											}>
											Per order, for all orders
										</IconBox>
									</div>
									<div className='col-xl-4 col-md-6'>
										<IconBox
											icon={
												pages.campground.settings.extraCharges.offlineOrders
													.icon
											}
											title='Offline Orders Only'
											onClick={() =>
												history.push(
													`${pages.campground.settings.extraCharges.offlineOrders.path}/0`
												)
											}>
											Per order, only for offline orders.
										</IconBox>
									</div>
									<div className='col-xl-4 col-md-6'>
										<IconBox
											icon={
												pages.campground.settings.extraCharges.onlineOrders
													.icon
											}
											title='Online Orders Only'
											onClick={() =>
												history.push(
													`${pages.campground.settings.extraCharges.onlineOrders.path}/0`
												)
											}>
											Per order, only for online orders.
										</IconBox>
									</div>
								</div>
							</Section.Body>
						</Section>
						<Separator />
						<Section title='Per Unit'>
							<Section.Body>
								<div className='row sdms-iconbox--container'>
									<div className='col-xl-4 col-md-6'>
										<IconBox
											icon={
												pages.campground.settings.extraCharges.perUnit.icon
											}
											title='Per Unit'
											design='success'
											onClick={() =>
												history.push(
													`${pages.campground.settings.extraCharges.perUnit.path}/0`
												)
											}>
											Per each unit in the order.
										</IconBox>
									</div>
								</div>
							</Section.Body>
						</Section>
					</Portlet.Body>
				</Portlet>
			</ContentInner.Container>
		</>
	);
};
ExtraChargeSelect.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ExtraChargeSelect;
