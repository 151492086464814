import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import useField from '../../../utils/hooks/useField';
import { required, email, url, phoneNumber, maxLength } from '../../../utils/helpers/validation';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import UserContext from '../../../app/contexts/UserContext';

import Button from '../../reusables/element/Button';
import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Loading from '../../reusables/template/Loading';
import Input from '../../reusables/field/Input';
import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import Selects from '../../reusables/field/Selects';
import Wysiwyg from '../../reusables/field/Wysiwyg';
import { phoneNumberParser } from '../../../utils/helpers/helper';

const OutletForm = ({
	data,
	setIsValid,
	submit,
	isSubmitted,
	isLoading,
	countries,
	states,
	timezones,
	setTitle,
	submitButtonAttr,
	onFormChange,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);
	const [
		addressLineOne,
		addressLineOneOnChange,
		addressLineOneValRes,
		addressLineOneShowVal,
		setAddressLineOneShowVal,
	] = useField(data, 'addressLineOne', onFormChange, [required, maxLength()]);

	const [
		addressLineTwo,
		addressLineTwoOnChange,
		addressLineTwoValRes,
		addressLineTwoShowVal,
		setAddressLineTwoShowVal,
	] = useField(data, 'addressLineTwo', onFormChange, [maxLength()]);

	const [city, cityOnChange, cityValRes, cityShowVal, setCityShowVal] = useField(
		data,
		'city',
		onFormChange,
		[required, maxLength()]
	);
	const [state, stateOnChange, stateValRes, stateShowVal, setStateShowVal] = useField(
		data,
		'state',
		onFormChange,
		[required],
		null
	);

	const [zip, zipOnChange, zipValRes, zipShowVal, setZipShowVal] = useField(
		data,
		'zip',
		onFormChange,
		[required, maxLength()]
	);

	const [country, countryOnChange, countryValRes, countryShowVal, setCountryShowVal] = useField(
		data,
		'country',
		onFormChange,
		[required],
		userContext.data.selectedOutlet.country
	);
	const [
		timezone,
		timezoneOnChange,
		timezoneValRes,
		timezoneShowVal,
		setTimeZoneShowVal,
	] = useField(data, 'timezone', onFormChange, [required], null);

	const [
		contactEmail,
		contactEmailOnChange,
		contactEmailValRes,
		contactEmailShowVal,
		setContactEmailShowVal,
	] = useField(data, 'contactEmail', onFormChange, [email, maxLength()]);
	
	const [
		contactPhone,
		contactPhoneOnChange,
		contactPhoneValRes,
		contactPhoneShowVal,
		setContactPhoneShowVal,
	] = useField(
		data,
		'contactPhone',
		onFormChange,
		[phoneNumber, maxLength()],
		'',
		phoneNumberParser
	);

	const [
		website,
		websiteOnChange,
		websiteValRes,
		websiteShowVal,
		setWebsiteShowVal,
	] = useField(data, 'website', onFormChange, [url, maxLength()]);

	const [companyCode, companyCodeOnChange] = useField(data, 'companyCode', onFormChange);

	const [termsOfService, termsOfServiceOnChange] = useField(
		data,
		'termsOfService',
		onFormChange,
		[],
		data.settings ? data.settings.termsOfService : null
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setTimeZoneShowVal();
			setAddressLineOneShowVal();
			setAddressLineTwoShowVal();
			setCityShowVal();
			setStateShowVal();
			setZipShowVal();
			setCountryShowVal();
			setContactEmailShowVal();
			setContactPhoneShowVal();
			setWebsiteShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setTimeZoneShowVal,
		setAddressLineOneShowVal,
		setAddressLineTwoShowVal,
		setCityShowVal,
		setStateShowVal,
		setZipShowVal,
		setCountryShowVal,
		setContactEmailShowVal,
		setContactPhoneShowVal,
		setWebsiteShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				timezoneValRes.isValid &&
				addressLineOneValRes.isValid &&
				addressLineTwoValRes.isValid &&
				cityValRes.isValid &&
				stateValRes.isValid &&
				zipValRes.isValid &&
				countryValRes.isValid &&
				contactEmailValRes.isValid &&
				contactPhoneValRes.isValid &&
				websiteValRes.isValid
		);
	}, [
		nameValRes.isValid,
		timezoneValRes.isValid,
		addressLineOneValRes.isValid,
		addressLineTwoValRes.isValid,
		cityValRes.isValid,
		stateValRes.isValid,
		zipValRes.isValid,
		countryValRes.isValid,
		setIsValid,
		contactEmailValRes.isValid,
		contactPhoneValRes.isValid,
		websiteValRes.isValid,
	]);

	// update states and reset selected state.
	const changeCountryState = ({ target }) => {
		stateOnChange({
			target: {
				name: 'state',
				value: null,
			},
		});

		countryOnChange({ target });
	};

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.companySettings.default.text,
				path: pages.companySettings.dashboard.path,
			},
			{
				title: pages.companySettings.company.text,
				path: pages.companySettings.company.path,
			},
			{ title: name, isActive: true },
		]);

		headerContext.setPageTitle(name);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		if (!isLoading && data.settings) {
			termsOfServiceOnChange({ target: { value: data.settings.termsOfService || '' } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		data.settings = {
			...data.settings,
			termsOfService,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [termsOfService]);

	return (
		<Portlet className='sdms-form' fluid='fluid' hasFrame>
			<Portlet.Body>
				<form>
					<Section title='General'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='name'
										label='Name'
										id={data.id}
										valRes={nameValRes}
										showValidation={nameShowVal}
										colMd={6}>
										<Input
											type='text'
											placeholder='Outlet Name (Required)'
											value={name}
											onChange={nameOnChange}
											onBlur={setNameShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='companyCode'
										label='Code'
										id={data.id}
										colMd={6}>
										<Input
											type='text'
											placeholder='Outlet Code'
											value={companyCode}
											onChange={companyCodeOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Contact'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='addressLineOne'
										label='Address 1'
										id={data.id}
										valRes={addressLineOneValRes}
										showValidation={addressLineOneShowVal}
										colLg={6}>
										<Input
											type='text'
											placeholder='Address Line 1 (Required)'
											value={addressLineOne}
											onChange={addressLineOneOnChange}
											onBlur={setAddressLineOneShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='addressLineTwo'
										label='Address 2'
										id={data.id}
										colLg={6}
										valRes={addressLineTwoValRes}
										showValidation={addressLineTwoShowVal}>
										<Input
											type='text'
											placeholder='Address Line 2'
											value={addressLineTwo}
											onChange={addressLineTwoOnChange}
											onBlur={setAddressLineTwoShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='city'
										label='City'
										id={data.id}
										valRes={cityValRes}
										showValidation={cityShowVal}
										colMd={6}
										colLg={3}>
										<Input
											type='text'
											placeholder='City (Required)'
											value={city}
											onChange={cityOnChange}
											onBlur={setCityShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='state'
										label='State'
										id={data.id}
										valRes={stateValRes}
										showValidation={stateShowVal}
										colMd={6}
										colLg={3}>
										<Selects
											placeholder='State (Required)'
											options={
												Object.keys(country).length
													? states.filter(
															s => s.country.id === country.id
													  )
													: []
											}
											value={state}
											onChange={stateOnChange}
											onBlur={setStateShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='zip'
										label='ZIP Code'
										id={data.id}
										valRes={zipValRes}
										showValidation={zipShowVal}
										colMd={6}
										colLg={3}>
										<Input
											type='text'
											placeholder='ZIP Code (Required)'
											value={zip}
											onChange={zipOnChange}
											onBlur={setZipShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='country'
										label='Country'
										id={data.id}
										valRes={countryValRes}
										showValidation={countryShowVal}
										colMd={6}
										colLg={3}>
										<Selects
											options={countries}
											placeholder='Country (Required)'
											value={country}
											onChange={changeCountryState}
											onBlur={setCountryShowVal}
											disableClearable
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='contactEmail'
										label='Contact Email'
										id={data.id}
										valRes={contactEmailValRes}
										showValidation={contactEmailShowVal}
										colMd={6}
										colLg={3}>
										<Input
											type='text'
											placeholder='Email address'
											value={contactEmail}
											onChange={contactEmailOnChange}
											onBlur={setContactEmailShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='contactPhone'
										label='Contact Phone'
										id={data.id}
										valRes={contactPhoneValRes}
										showValidation={contactPhoneShowVal}
										colMd={6}
										colLg={3}>
										<Input
											type='tel'
											placeholder='(555) 555-5555'
											value={contactPhone}
											onChange={contactPhoneOnChange}
											onBlur={setContactPhoneShowVal}
											mask={process.env.REACT_APP_PHONE_FORMAT}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='website'
										label='Website'
										id={data.id}
										valRes={websiteValRes}
										showValidation={websiteShowVal}
										colMd={6}
										colLg={3}>
										<Input
											type='text'
											placeholder='example.com'
											value={website}
											onChange={websiteOnChange}
											onBlur={setWebsiteShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='timezone'
										label='Timezone'
										id={data.id}
										valRes={timezoneValRes}
										showValidation={timezoneShowVal}
										colMd={6}
										colLg={3}>
										<Selects
											options={timezones}
											placeholder='Timezone (Required)'
											value={timezone}
											onChange={timezoneOnChange}
											onBlur={setTimeZoneShowVal}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Terms of Service'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='termsOfService'
										label='Terms of Service'
										id={data.id}
										col={12}>
										<Wysiwyg
											value={termsOfService}
											onChange={termsOfServiceOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
OutletForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		addressLineOne: PropTypes.string,
		addressLineTwo: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.shape({}),
		zip: PropTypes.string,
		country: PropTypes.shape({ states: PropTypes.arrayOf(PropTypes.object) }),
		timezone: PropTypes.object,
		contactEmail: PropTypes.string,
		contactPhone: PropTypes.string,
		website: PropTypes.string,
		settings: PropTypes.object,
	}),
	countries: PropTypes.arrayOf(PropTypes.object),
	states: PropTypes.arrayOf(PropTypes.object),
	timezones: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/no-unused-prop-types
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
};
OutletForm.defaultProps = {
	data: {
		id: 0,
	},
	countries: [],
	states: [],
	timezones: [],
	paymentTerms: [],
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
};

export default OutletForm;
