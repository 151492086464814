import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from '../field/Input';
import SVGIcon from './SVGIcon';

const Search = forwardRef(
	(
		{
			searchText,
			setSearchText,
			isSearching,
			className,
			setCurrentPage,
			placeHolder,
			inPortlet,
			fluid,
			disabled,
		},
		ref
	) => {
		return (
			<div
				className={classNames(
					'sdms-input-icon',
					'sdms-input-icon--left',
					{ 'sdms-input-icon--right': searchText },
					{ 'sdms-portlet__search': inPortlet },
					{ 'h-100': fluid },
					className
				)}>
				<span className='sdms-input-icon__icon sdms-input-icon__icon--left'>
					<span>
						<SVGIcon
							name={!isSearching ? 'Search' : 'Loading'}
							className={classNames({ 'sdms-animate-spinner': isSearching })}
							fill='var(--primary)'
						/>
					</span>
				</span>
				<Input
					type='text'
					onChange={({ target }) => {
						setCurrentPage(1);
						setSearchText(target.value);
					}}
					value={searchText}
					placeholder={placeHolder}
					ref={ref}
					className={classNames({ 'h-100': fluid })}
					disabled={disabled}
				/>
				{searchText && (
					<span className='sdms-input-icon__icon sdms-input-icon__icon--right'>
						<span>
							<SVGIcon
								name='Backspace'
								className='sdms-cursor--pointer'
								onClick={() => {
									setSearchText('');
								}}
							/>
						</span>
					</span>
				)}
			</div>
		);
	}
);

Search.propTypes = {
	searchText: PropTypes.string.isRequired,
	setSearchText: PropTypes.func.isRequired,
	isSearching: PropTypes.bool,
	className: PropTypes.string,
	setCurrentPage: PropTypes.func,
	placeHolder: PropTypes.string,
	inPortlet: PropTypes.bool,
	fluid: PropTypes.bool,
	disabled: PropTypes.bool,
};

Search.defaultProps = {
	isSearching: false,
	className: null,
	setCurrentPage: () => {},
	placeHolder: 'Search...',
	inPortlet: false,
	fluid: false,
	disabled: false,
};

export default Search;
