import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import usePages from '../../../utils/hooks/usePages';

import Portal from '../layout/Portal';
import Portlet from '../layout/Portlet';
import { OrderBody, OrderHead, OrderItem, OrderTable } from '../../modules/terminal/Order';
import Button from '../element/Button';
import { priceFormatter } from '../../../utils/helpers/helper';

const StatementChargeModal = ({ data, isOpen, onClose }) => {
	const pages = usePages();

	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<Portlet className='sdms-list-layout'>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon={pages.crm.statementCharges.icon}>
							Statement Charge Details
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Head className='align-items-center'>
						<OrderTable>
							<OrderHead
								className='sdms-invoice-table__head'
								hasActions={false}
								isMultiActionsEnabled={false}
								isAllSelected={false}
								hideCheckbox
							/>
						</OrderTable>
					</Portlet.Head>
					<Portlet.Body>
						<OrderTable>
							<OrderBody>
								<OrderItem
									key={data.id}
									id={data.id}
									description={data.description}
									name={data.item.name}
									price={data.rate}
									quantity={data.quantity}
									subtotal={data.amount}
									hasActions={false}
									index={data.id}
									disableActions={false}
									isSelected={false}
									isPriceEditable={false}
									hideCheckbox
									disableQuantity
									userHasPermission={() => true}
								/>
							</OrderBody>
						</OrderTable>
					</Portlet.Body>
					<Portlet.Foot>
						<div className='col-12'>
							<div className='row sdms-font-lg sdms-font-bolder'>
								<div className='col'>Amount</div>
								<div className='col-auto'>{priceFormatter(data.amount)}</div>
							</div>
						</div>
					</Portlet.Foot>
					<Portlet.Foot className='sdms-align-left' tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};

StatementChargeModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
};
StatementChargeModal.defaultProps = {
	data: {
		id: 0,
	},
};

export default StatementChargeModal;
