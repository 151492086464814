import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import UserContext from '../../../../../app/contexts/UserContext';
import useModal from '../../../../../utils/hooks/useModal';
import apiCall, { modules } from '../../../../../utils/helpers/apiCall';
import { getModuleByPath } from '../../../../../utils/helpers/reusable';
import { reservationStatuses } from '../../../../../utils/constants/constants';
import { getCapacity, getCurrentUnit } from '../../../../../utils/helpers/reservationHelper';
import {
	addErrorNotification,
	addSuccessNotification,
	calculateSqft,
} from '../../../../../utils/helpers/helper';

import Button from '../../../element/Button';
import Unit from '../modals/Unit';

const UnitButton = ({ reservation, reservationItem, disabled, onUnitChange }) => {
	const userContext = useContext(UserContext);

	const location = useLocation();

	const [modal, openModal, closeModal] = useModal();

	const module = useMemo(() => getModuleByPath(location.pathname), [location]);

	const [isSearching, setIsSearching] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [availableUnits, setAvailableUnits] = useState([]);

	const onSearch = () => {
		setIsSearching(true);

		const { ignoredRules, ignoredCapacity, product, quantity, loa, beam } = reservationItem;

		const _data = {
			...reservationItem,
			outletId: userContext.data.selectedOutlet.id,
			productId: product.id,
			capacity:
				module === modules.MARINA || module === modules.CAMPGROUND
					? getCapacity(product, quantity, loa, beam)
					: quantity,
			isWaitList: reservationItem?.status?.value === reservationStatuses.WAITLIST,
			sqft: calculateSqft(loa, beam),
			ignoreRules: ignoredRules,
			ignoreCapacity: ignoredCapacity,
		};

		apiCall(
			'POST',
			'advancedReservationIsAvailable',
			res => {
				setIsSearching(false);
				if (!res.units)
					addErrorNotification(
						res.message ||
							`There are no available ${
								module === modules.BOOKINGS ? 'units' : 'spaces'
							}`
					);
				else {
					setAvailableUnits(
						[
							...product.unitBookings,
							...product.unitMarinas,
							...product.unitCampgrounds,
						].filter(unit => res.units.indexOf(unit.id) > -1)
					);
					openModal({ open: 'units' });
				}
			},
			err => {
				addErrorNotification(err.toString().replace('Error:', ''));
				setIsSearching(false);
			},
			'',
			_data
		);
	};

	const onChange = unit => {
		setIsSubmitting(true);

		apiCall(
			'POST',
			'advancedReservationUpdateItem',
			() => {
				addSuccessNotification('Booking successfully updated');
				setIsSubmitting(false);
				onUnitChange(unit);
				closeModal();
			},
			e => {
				addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{
				reservation,
				reservationItem: {
					...reservationItem,
					unit,
					capacity: getCapacity(
						reservationItem.product,
						reservationItem.quantity,
						reservationItem.loa,
						reservationItem.beam
					),
				},
				outletId: userContext.data.selectedOutlet.id,
			}
		);
	};

	return (
		<>
			<Button
				icon={module}
				design='default'
				size='sm'
				text={
					isSearching
						? 'Checking'
						: `${module === modules.BOOKINGS ? 'Unit' : 'Space'}: ${getCurrentUnit(
								reservationItem,
								reservationItem.unit
						  )?.name || 'N/A'} `
				}
				onClick={onSearch}
				disabled={
					disabled ||
					isSearching ||
					isSubmitting ||
					reservationItem?.status?.value === reservationStatuses.CANCELLED ||
					(reservationItem.spaceAssignments || []).length > 0
				}
				isSubmitting={isSearching}
			/>
			{modal.open === 'units' && (
				<Unit
					open={modal.open === 'units'}
					isSubmitting={isSubmitting}
					module={module}
					availableUnits={availableUnits}
					onClose={closeModal}
					onChange={onChange}
				/>
			)}
		</>
	);
};

UnitButton.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	disabled: PropTypes.bool,
	onUnitChange: PropTypes.func,
};

UnitButton.defaultProps = {
	reservation: null,
	reservationItem: null,
	disabled: false,
	onUnitChange: () => {},
};

export default UnitButton;
