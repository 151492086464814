import React, { useState } from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';

import Button from '../element/Button';
import Dropdown from '../element/Dropdown';
import Search from '../element/Search';
import Portlet from '../layout/Portlet';

const MapUnitList = ({ units, dispatch, unitMapId }) => {
	const pages = usePages();

	const [searchText, setSearchText] = useState('');

	const editItem = id =>
		dispatch({
			type: 'edit',
			payload: id,
		});

	return (
		<Portlet fluid='fluid' className='sdms-list-layout' everyTimeFluid>
			<Portlet.Head>
				<Portlet.HeadLabel portletIcon={pages.pos.settings.tableMaps.icon}>
					<Search
						inPortlet
						searchText={searchText}
						setSearchText={setSearchText}
						placeHolder='Filter'
					/>
				</Portlet.HeadLabel>
			</Portlet.Head>
			<Portlet.Body>
				<div className='sdms-pos--table-list'>
					{units
						.filter(
							t => t.name.toLowerCase().search(searchText.toLocaleLowerCase()) > -1
						)
						.map(unit => {
							return (
								<div className='sdms-pos--table-list__item' key={unit.id}>
									<div
										role='presentation'
										className='sdms-pos--table-list__name sdms-link sdms-link--dark'
										onClick={() => editItem(unit.id)}>
										{unit.name}
									</div>
									<div className='sdms-pos--table-list__actions'>
										{unit.unitMapUnits.findIndex(
											tmt => tmt.unitMap.id === unitMapId
										) < 0 && (
											<Button
												label='primary'
												size='sm'
												elevate
												text='Add To Map'
												icon='Redo'
												onClick={() =>
													dispatch({ type: 'add', payload: unit })
												}
											/>
										)}
										<Dropdown
											icon='Other#1'
											color='clean'
											inline
											aligned='right'
											circle
											outline={false}>
											<Dropdown.Header>Other Actions</Dropdown.Header>
											{unit.unitMapUnits.findIndex(
												tmt => tmt.unitMap.id === unitMapId
											) >= 0 && (
												<Dropdown.Item
													icon='Edit'
													onClick={() => editItem(unit.id)}>
													Edit
												</Dropdown.Item>
											)}
											{unit.unitMapUnits &&
												unit.unitMapUnits.findIndex(
													tmt => tmt.unitMap.id === unitMapId
												) > -1 && (
													<Dropdown.Item
														itemsColor='danger'
														icon='Undo'
														onClick={() =>
															dispatch({
																type: 'remove',
																payload: unit.id,
															})
														}>
														Remove
													</Dropdown.Item>
												)}
										</Dropdown>
									</div>
								</div>
							);
						})}
				</div>
			</Portlet.Body>
		</Portlet>
	);
};
MapUnitList.propTypes = {
	units: PropTypes.arrayOf(PropTypes.object).isRequired,
	dispatch: PropTypes.func.isRequired,
	unitMapId: PropTypes.number,
};
MapUnitList.defaultProps = {
	unitMapId: 0,
};

export default MapUnitList;
