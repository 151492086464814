import React from 'react';
import PropTypes from 'prop-types';
import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';
import MeterReadingForm from './MeterReadingForm';

const MeterReadingEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='meterReadings'
			fields={<MeterReadingForm />}
			lists={['powerMeters', 'paymentTerms']}
			pageTitle={pages.marina.meterReadings.text}
			icon={pages.marina.meterReadings.icon}
			module={modules.MARINA}
			showSaveButton={match.params.id === '0'}
		/>
	);
};

MeterReadingEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default MeterReadingEdit;
