import apiCall from './apiCall';
import { addErrorNotification, getDescriptionLink, phoneNumberParser } from './helper';

/**
 * Validate field value existence
 * @param {string | number | array} value String, number, or array input value to validate
 * @param {function} setValRes Callback function to set validation result
 * @returns {object} { isValid: bool,status : string, message: string }
 */

export const required = (value, setValRes) => {
	if (
		value === null ||
		value === '' ||
		value === undefined ||
		(Array.isArray(value) && value.length === 0) ||
		(typeof value === 'object' &&
			!(value instanceof Date) &&
			Object.entries(value).length === 0)
	) {
		setValRes({
			isValid: false,
			status: 'required',
			message: 'Field is required',
		});
		return false;
	}
	return true;
};

export const zero = (value, setValRes) => {
	if (value === 0 || value === '0') {
		setValRes({
			isValid: false,
			status: 'zero',
			message: 'Cannot be zero',
		});
		return false;
	}
	return true;
};

/**
 * Validate field value according to min value
 * @param {number} minValue Field min value
 * @param countSpaces
 * @returns {object} { isValid: bool,status:string message: string }
 */
export const minLength = (minValue, countSpaces = true) => {
	return (value, setValRes) => {
		if (value || value === 0) {
			if ((countSpaces ? value : value.replace(/\s/g, '')).length < minValue) {
				setValRes({
					isValid: false,
					status: 'minLength',
					message: `Field must have at least ${minValue} chars!`,
				});
				return false;
			}

			return true;
		}

		return true;
	};
};

/**
 * Validate field value according to max value
 * @param {number} maxValue Field max value
 * @param countSpaces
 * @returns {object} { isValid: bool, status:string,message: string }
 */
export const maxLength = (maxValue = 255, countSpaces = true) => {
	return (value, setValRes) => {
		if (value || value === 0) {
			if ((countSpaces ? value : value.replace(/\s/g, '')).length > maxValue) {
				setValRes({
					isValid: false,
					status: 'maxLength',
					message: `Field can be up to ${maxValue} chars!`,
				});
				return false;
			}
			return true;
		}
		return true;
	};
};

/**
 * Validate email field value
 * @param {string} value Email to validate
 * @param {function} setValRes Callback function to set validation result
 * @returns {object} { isValid: bool, status:string message: string }
 */
export const email = (value, setValRes) => {
	const regex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
	if (value && !regex.test(value)) {
		setValRes({
			isValid: false,
			status: 'invalidEmail',
			message: 'Invalid Email!',
		});
		return false;
	}
	return true;
};

/**
 * Validate password field value
 * @returns {object} { isValid: bool, status:string, message: string }
 * @param settings
 */
export const password = settings => {
	return (value, setValRes) => {
		if (!settings) return true;

		if (settings.passwordMinLength && value.length < settings.passwordMinLength) {
			setValRes({
				isValid: false,
				status: 'invalidPassword',
				message: `Minimum length of password is ${settings.passwordMinLength}!`,
			});
			return false;
		}

		if (settings.passwordRequiredUpperCase && !value.match(/[A-Z]/)) {
			setValRes({
				isValid: false,
				status: 'invalidPassword',
				message: 'Password must contain at lease 1 upper case!',
			});
			return false;
		}

		if (settings.passwordRequiredNumber && !value.match(/\d/)) {
			setValRes({
				isValid: false,
				status: 'invalidPassword',
				message: 'Password must contain at least 1 number!',
			});
			return false;
		}

		if (
			settings.passwordRequiredSpecialCharacter &&
			!value.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/)
		) {
			setValRes({
				isValid: false,
				status: 'invalidPassword',
				message: 'Password must contain at least 1 special character!',
			});
			return false;
		}

		return true;
	};
};

/**
 * Validate phone field value
 * @param {string} value Phone value to validate
 * @param {function} setValRes Callback function to set validation result
 * @returns {object} { isValid: bool, status:string, message: string }
 */
export const phoneNumber = (value, setValRes) => {
	const regex = new RegExp(`^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4}$`);
	if (
		value &&
		value !== process.env.REACT_APP_PHONE_FORMAT.replaceAll('9', '_') &&
		!regex.test(phoneNumberParser(value))
	) {
		setValRes({
			isValid: false,
			status: 'invalidPhone',
			message: 'Invalid phone number!',
		});
		return false;
	}
	return true;
};

/**
 * Validate url field value
 * @param value
 * @param setValRes
 */
export const url = (value, setValRes) => {
	// eslint-disable-next-line no-useless-escape
	const regex = /^(https?):\/\/[^\s$.?#].[^\s]*$/gm;
	if (value && !regex.test(value)) {
		setValRes({
			isValid: false,
			status: 'invalidUrl',
			message: 'Invalid Url',
		});
		return false;
	}
	return true;
};

/**
 * Validate numeric value
 * @param {string} (value, setValRes) String value to validate
 * @param {function} setValRes Callback function to set validation result
 * @returns {object} { isValid: bool, status:string, message: string }
 */
export const numeric = (value, setValRes) => {
	if (value === '' || !value) return true;

	if (
		!(
			value.toString().match(/^-{0,1}\d+$/) ||
			value.toString().match(/^-{0,1}\d+\.\d+$/) ||
			value.toString().match(/^\d+\.\d+$/)
		)
	) {
		setValRes({
			isValid: false,
			status: 'invalidNumber',
			message: 'Please enter valid number.',
		});
		return false;
	}
	return true;
};

/**
 * Create a validation function for unique value
 * @param {string} entity Route to fetch related data
 * @param {string} field Field to query
 * @param {string} id ID of the edited object
 * @param isRequired
 * @param body
 * @param customMessage
 * @param customFilters
 * @param moduleId
 * @param outletId
 * @returns {function} { isValid: bool, status:string, message: string }
 */
export const uniqueFnc = (
	entity,
	field,
	id,
	isRequired = true,
	body = null,
	customMessage = null,
	customFilters = null,
	moduleId = null,
	outletId = null
) => {
	return (value, setValRes, timeoutId, setTimeoutId) => {
		clearTimeout(timeoutId);

		if (value === '' && !isRequired) return true;

		setValRes({
			isValid: false,
			status: 'isLoading',
			message: 'Checking',
		});

		setTimeoutId(
			setTimeout(() => {
				const filters = {
					pagination: false,
					...customFilters,
				};

				filters[`unique_${field}`] = value;

				apiCall(
					body ? 'POST' : 'GET',
					entity,
					data => {
						if (moduleId) data = data.filter(item => item.module.id === moduleId);
						if (outletId) data = data.filter(item => item.outlet.id === outletId);

						if (data.length === 0 || data[0].id === id) {
							setValRes({
								isValid: true,
								status: 'valid',
								message: '',
							});
						} else {
							setValRes({
								isValid: false,
								status: 'notUnique',
								message:
									customMessage !== null
										? getDescriptionLink(customMessage, true)
										: `The ${field
												.replace(/([A-Z])/g, match => ` ${match}`)
												.replace(/^./, match =>
													match.toUpperCase()
												)} should be unique!`,
							});
						}
					},
					err => {
						addErrorNotification(err.toString());
					},
					'',
					body,
					filters
				);
			}, 750)
		);

		return true;
	};
};

/**
 * Validate field value according to max value
 * @param {number} maxValue Field max value
 * @returns {boolean}
 */
export const max = maxValue => {
	return (value, setValRes) => {
		if (value === '') return true;

		if (parseFloat(value) > maxValue) {
			setValRes({
				isValid: false,
				status: 'max',
				message: `Field can be up to ${maxValue}`,
			});
			return false;
		}

		return true;
	};
};

/**
 * Validate field value according to min value
 * @param {number} minValue Field max value
 * @returns {boolean}
 */
export const min = minValue => {
	return (value, setValRes) => {
		if (value === '') return true;

		if (parseFloat(value) < minValue) {
			setValRes({
				isValid: false,
				status: 'max',
				message: `Field can be equal or more than ${minValue}`,
			});
			return false;
		}

		return true;
	};
};

/**
 * Validate that number of selected items is larger than a minimum
 * @param {number} minValue Field min value
 * @returns {object} { isValid: bool,status:string message: string }
 */
export const minSelection = minValue => {
	return (value, setValRes) => {
		if (value.length < minValue) {
			setValRes({
				isValid: false,
				status: 'minSelection',
				message: `At least ${minValue} items should be selected!`,
			});
			return false;
		}
		return true;
	};
};
/**
 * Validate that number of selected items is smaller than a maximum
 * @param {number} maxValue Field max value
 * @returns {object} { isValid: bool,status:string message: string }
 */
export const maxSelection = maxValue => {
	return (value, setValRes) => {
		// warning at max not above max! Above max is not allowed through multiselect.
		if (value.length >= maxValue) {
			setValRes({
				isValid: false,
				status: 'maxSelection',
				message: `At most ${maxValue} items can be selected!`,
			});
			return false;
		}
		return true;
	};
};

export const percentage = (value, setValRes) => {
	if (numeric(value, setValRes)) {
		if (parseFloat(value) > 100) {
			setValRes({
				isValid: false,
				status: 'max',
				message: `Field can be up to 100`,
			});
			return false;
		}

		return true;
	}

	return false;
};

export const creditCardRequired = (value, setValRes) => {
	if (
		value === null ||
		value === '' ||
		value === undefined ||
		(Array.isArray(value) && value.length === 0) ||
		(typeof value === 'object' &&
			!(value instanceof Date) &&
			Object.entries(value).length === 0)
	) {
		setValRes({
			isValid: false,
			status: 'required',
			message: 'Field is required',
		});
		return false;
	}

	if (
		!value.swipe &&
		!value.token &&
		!value.creditCard &&
		(!value.cardNumber || value.cardNumber === '' || !value.expiry || value.expiry === '')
	) {
		setValRes({
			isValid: false,
			status: 'required',
			message: 'Field is required',
		});
		return false;
	}

	return true;
};

export const checkValidation = (value, setValRes) => {
	if (
		value === null ||
		value === '' ||
		value === undefined ||
		(Array.isArray(value) && value.length === 0) ||
		(typeof value === 'object' &&
			!(value instanceof Date) &&
			Object.entries(value).length === 0)
	) {
		setValRes({
			isValid: false,
			status: 'required',
			message: 'Field is required',
		});
		return false;
	}

	if (
		!value.achAccount &&
		(!value.bankName ||
			value.accountName === '' ||
			value.routingNumber === '' ||
			value.accountNumber === '')
	) {
		setValRes({
			isValid: false,
			status: 'required',
			message: 'Field is required',
		});
		return false;
	}

	return true;
};

export const excludeDatesValidation = (value, setValRes) => {
	if (value.some(v => v.isValid === false)) {
		setValRes({
			isValid: false,
			status: 'invalid',
			message: 'Invalid',
		});
		return false;
	}

	return true;
};

export const phoneNumberRequired = outlet => {
	return (value, setValRes) => {
		if (!outlet?.settings?.phoneNumberRequired) return true;

		return required(value, setValRes);
	};
};
