import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormGroup from '../layout/FormGroup';
import SVGIcon from '../element/SVGIcon';
import { getDescriptionLink, noPermissionStatus } from '../../../utils/helpers/helper';

const FormField = React.forwardRef(
	(
		{
			name,
			label,
			description,
			id,
			valRes,
			showValidation,
			className,
			labelClassName,
			col,
			colSm,
			colMd,
			colLg,
			colXl,
			children,
			messages,
			isLast,
			loadingContainer,
			noPermission,
			inFormDesign,
			customFieldId,
		},
		ref
	) => {
		const fieldId = `${name}_${id}`;
		const _FormFieldInner = (
			<>
				{React.Children.map(children, child =>
					React.cloneElement(child, {
						id: customFieldId || fieldId,
						name,
						isValid: valRes.isValid,
						status: valRes.status,
						showValidation,
					})
				)}
			</>
		);

		const getDescription = useCallback(() => {
			if (!description) return null;

			return getDescriptionLink(description);
		}, [description]);

		const _FormField = (
			<FormGroup
				ref={!(col || colSm || colMd || colLg || colXl) ? ref : null}
				row={false}
				className={classNames(
					{ 'form-group--in-form': inFormDesign },
					{ validated: showValidation },
					{ [className]: !(col || colSm || colMd || colLg || colXl) && className },
					noPermissionStatus(noPermission)
				)}
				isLast={isLast}>
				{label && (
					<label htmlFor={fieldId} className={classNames(labelClassName)}>
						{label}
					</label>
				)}
				{/* If unique */}
				{loadingContainer ? (
					<div className='sdms-input-icon sdms-input-icon--right'>
						{_FormFieldInner}
						{valRes.status === 'isLoading' && (
							<span className='sdms-input-icon__icon sdms-input-icon__icon--right'>
								<span>
									<SVGIcon name='Loading' className='sdms-animate-spinner' />
								</span>
							</span>
						)}
					</div>
				) : (
					_FormFieldInner
				)}

				{valRes.status === 'isLoading' ||
					(showValidation && ((messages && messages[valRes.status]) || valRes.message) && (
						<div
							className={classNames(
								{
									'invalid-feedback': !valRes.isValid,
								},
								{
									'valid-feedback': valRes.isValid,
								}
							)}>
							{messages && messages[valRes.status]
								? messages[valRes.status]
								: valRes.message}
						</div>
					))}
				{getDescription()}
			</FormGroup>
		);

		if (col || colSm || colMd || colLg || colXl) {
			return (
				<div
					ref={ref}
					className={classNames(
						{ col: typeof col === 'boolean' },
						{ 'col-sm': typeof colSm === 'boolean' },
						{ 'col-md': typeof colMd === 'boolean' },
						{ 'col-lg': typeof colLg === 'boolean' },
						{ 'col-xl': typeof colXl === 'boolean' },
						{ [`col-${col}`]: typeof col === 'number' || typeof col === 'string' },
						{
							[`col-sm-${colSm}`]:
								typeof colSm === 'number' || typeof colSm === 'string',
						},
						{
							[`col-md-${colMd}`]:
								typeof colMd === 'number' || typeof colMd === 'string',
						},
						{
							[`col-lg-${colLg}`]:
								typeof colLg === 'number' || typeof colLg === 'string',
						},
						{
							[`col-xl-${colXl}`]:
								typeof colXl === 'number' || typeof colXl === 'string',
						},
						className
					)}>
					{_FormField}
				</div>
			);
		}
		return <>{_FormField}</>;
	}
);
FormField.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string.isRequired,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	className: PropTypes.string,
	labelClassName: PropTypes.string,
	col: PropTypes.oneOfType([
		PropTypes.oneOf(['auto', 'stretch']),
		PropTypes.number,
		PropTypes.bool,
	]),
	colSm: PropTypes.oneOfType([PropTypes.oneOf(['auto']), PropTypes.number]),
	colMd: PropTypes.oneOfType([PropTypes.oneOf(['auto']), PropTypes.number]),
	colLg: PropTypes.oneOfType([PropTypes.oneOf(['auto']), PropTypes.number]),
	colXl: PropTypes.oneOfType([PropTypes.oneOf(['auto']), PropTypes.number]),
	children: PropTypes.node,
	description: PropTypes.string,
	showValidation: PropTypes.bool,
	valRes: PropTypes.shape({
		isValid: PropTypes.bool,
		status: PropTypes.string,
		message: PropTypes.string,
	}),
	messages: PropTypes.shape({
		valid: PropTypes.string,
		required: PropTypes.string,
	}),
	isLast: PropTypes.bool,
	loadingContainer: PropTypes.bool,
	noPermission: PropTypes.bool,
	inFormDesign: PropTypes.bool,
	customFieldId: PropTypes.string,
};
FormField.defaultProps = {
	id: undefined,
	col: null,
	colSm: null,
	colMd: null,
	colLg: null,
	colXl: null,
	description: null,
	className: null,
	labelClassName: null,
	showValidation: false,
	valRes: { isValid: true, message: '', status: null },
	messages: undefined,
	label: null,
	isLast: false,
	loadingContainer: false,
	noPermission: null,
	inFormDesign: true,
	children: null,
	customFieldId: '',
};

export default FormField;
