import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import {
	bookBies,
	bookingCalculations,
	bookingPeriods,
} from '../../../../../utils/constants/constants';
import { inchToFeet, numberFormat, priceFormatter } from '../../../../../utils/helpers/helper';

import Badge from '../../Badge';
import Button from '../../Button';
import FlexibleDates from '../../FlexibleDates';
import Alert from '../../Alert';
import Loading from '../../../template/Loading';
import Portlet from '../../../layout/Portlet';
import NumberOfUnitsInput from './NumberOfUnits';
import IconBox from '../../../layout/IconBox';
import Portal from '../../../layout/Portal';
import pages from '../../../../pages';
import Separator from '../../../layout/Separator';

const PricingItem = ({
	productCategories,
	productCategoryKey,
	productKey,
	pricingKey,
	selectProduct,
	isMulti,
	isLoading,
	multipleSelected,
	setMultipleSelected,
	outlet,
	isOnline,
	initProducts,
	isGrid,
	bookButtonRef,
	hideBookButton,
	searchData,
}) => {
	const [numUnits, setNumUnits] = useState(1);

	const product = useMemo(() => {
		const _product = productCategories[productCategoryKey].products[productKey];

		const { bookingPeriod, pricing, duration: numPeriod, onlineUrl } = _product;

		const hasAvailableUnit = Array.isArray(pricing[pricingKey].availability)
			? pricing[pricingKey].availability.length > 0
			: Object.keys(pricing[pricingKey].availability).length > 0;

		const failed = pricing[pricingKey].failedConstraints.flat().length > 0;

		const hasAlternateDate = pricing[pricingKey].alternateDates.length > 0;

		const { hasAnyAvailable } = pricing[pricingKey];

		return {
			..._product,
			categoryName: productCategories[productCategoryKey].categoryName,
			isHourly: bookingPeriod === bookingPeriods.HOURLY,
			pricing: pricing[pricingKey],
			alternateDates: pricing[pricingKey].alternateDates,
			flexibleDates: pricing[pricingKey].flexibleDates,
			numPeriod,
			hasAvailableUnit,
			displayPrice: (failed && (!isGrid || !hasAlternateDate)) || hasAvailableUnit,
			displayFlexibleDate: !isGrid && hasAnyAvailable && !failed && !hasAvailableUnit,
			displayConstraint: failed && (!isGrid || !hasAlternateDate),
			displayViewButton: !isGrid && hasAnyAvailable && onlineUrl && !failed,
		};
	}, [productCategories, productCategoryKey, productKey, pricingKey, isGrid]);

	const totalPrice = useMemo(() => {
		let _totalPrice = 0;
		for (let i = 1; i <= numUnits; i += 1) {
			_totalPrice += product.pricing.prices[i].reduce((a, b) => a + b, 0);
		}
		return numberFormat(_totalPrice / numUnits);
	}, [numUnits, product]);

	const avgPrice = useMemo(() => {
		return totalPrice / product.numPeriod;
	}, [totalPrice, product]);

	const totalText = useMemo(() => {
		if (product.isHourly) return '';

		let _totalText = 'night';
		if (product.bookingPeriod === bookingPeriods.DAILY) _totalText = 'day';

		return product.numPeriod > 1 ? _totalText.concat('s') : _totalText;
	}, [product]);

	const maxNumUnits = useMemo(() => {
		let _maxNumUnits = 0;
		if (product.isHourly) {
			Object.keys(product.pricing.availability).forEach(startTime => {
				Object.keys(product.pricing.availability[startTime]).forEach(endTime => {
					if (product.pricing.availability[startTime][endTime] > _maxNumUnits)
						_maxNumUnits = product.pricing.availability[startTime][endTime];
				});
			});
		} else if (product.pricing.ratePlanName)
			_maxNumUnits = product.pricing.availability ? product.pricing.availability.length : 0;
		else _maxNumUnits = product.pricing.availability.length;

		return _maxNumUnits;
	}, [product]);

	const constraintText = useMemo(() => {
		const constraintSummary = {};

		product.pricing.failedConstraints.flat().forEach(message => {
			const type = message.split('-')[0];
			const param = message.split('-')[1];
			if (type === 'noUnits') {
				if (
					initProducts &&
					initProducts.map(p => p.toString()).indexOf(product.id.toString()) > -1
				)
					constraintSummary[type] = param;
			} else if (type.search('min') > -1) {
				if (
					typeof constraintSummary[type] === 'undefined' ||
					constraintSummary[type] < parseInt(param, 10)
				)
					constraintSummary[type] = parseInt(param, 10);
			} else if (type.search('max') > -1) {
				if (
					typeof constraintSummary[type] === 'undefined' ||
					constraintSummary[type] > parseInt(param, 10)
				)
					constraintSummary[type] = parseInt(param, 10);
			} else if (type === 'cid' || type === 'cod') {
				constraintSummary[type] = param.split('/').filter(s => s !== '');
			} else if (typeof constraintSummary[type] === 'undefined') {
				constraintSummary[type] = param.split('/');
				constraintSummary[type].pop();
			}
		});

		const periodText = product.bookingPeriod === bookingPeriods.NIGHTLY ? 'night(s)' : 'day(s)';

		const _constraintText = [];

		Object.keys(constraintSummary).forEach(type => {
			if (type === 'minDur')
				_constraintText.push(
					`Minimum duration of stay is ${constraintSummary[type]} ${periodText}.`
				);
			else if (type === 'maxDur')
				_constraintText.push(
					`Maximum duration of stay is ${constraintSummary[type]} ${periodText}.`
				);
			else if (type === 'minBia')
				_constraintText.push(
					`Booking should be booked minimum ${constraintSummary[type]} day(s) in advance.`
				);
			else if (type === 'maxBia')
				_constraintText.push(
					`Booking should be booked maximum ${constraintSummary[type]} day(s) in advance.`
				);
			else if (type === 'minPeople')
				_constraintText.push(`Minimum people is ${constraintSummary[type]}.`);
			else if (type === 'maxPeople')
				_constraintText.push(`Maximum people is ${constraintSummary[type]}.`);
			else if (type === 'minLoa')
				_constraintText.push(
					`Minimum length over all is ${inchToFeet(constraintSummary[type])}.`
				);
			else if (type === 'maxLoa')
				_constraintText.push(
					`Maximum length over all is ${inchToFeet(constraintSummary[type])}.`
				);
			else if (type === 'minBeam')
				_constraintText.push(`Minimum beam is ${inchToFeet(constraintSummary[type])}.`);
			else if (type === 'maxBeam')
				_constraintText.push(`Maximum beam is ${inchToFeet(constraintSummary[type])}.`);
			else if (type === 'minDraft')
				_constraintText.push(`Minimum draft is ${inchToFeet(constraintSummary[type])}.`);
			else if (type === 'maxDraft')
				_constraintText.push(`Maximum draft is ${inchToFeet(constraintSummary[type])}.`);
			else if (type === 'minWeight')
				_constraintText.push(`Minimum weight is ${constraintSummary[type]} lbs.`);
			else if (type === 'maxWeight')
				_constraintText.push(`Minimum weight is ${constraintSummary[type]} lbs.`);
			else if (type === 'minHeight')
				_constraintText.push(`Minimum height is ${inchToFeet(constraintSummary[type])}.`);
			else if (type === 'maxHeight')
				_constraintText.push(`Maximum height is ${inchToFeet(constraintSummary[type])}.`);
			else if (type === 'minSqft')
				_constraintText.push(`Minimum sqft is ${constraintSummary[type]}.`);
			else if (type === 'maxSqft')
				_constraintText.push(`Maximum sqft is ${constraintSummary[type]}.`);
			else if (type === 'cid')
				_constraintText.push(
					`Check in day${
						constraintSummary[type].length > 1 ? 's are' : ' is'
					} ${constraintSummary[type].join(', ')}.`
				);
			else if (type === 'cod')
				_constraintText.push(
					`Check out day${
						constraintSummary[type].length > 1 ? 's are' : ' is'
					} ${constraintSummary[type].join(', ')}.`
				);
			else if (type === 'noUnits') _constraintText.push(constraintSummary[type]);
		});

		return _constraintText;
	}, [initProducts, product]);

	const isSelectDisabled = useMemo(() => {
		return (
			multipleSelected !== null &&
			// eslint-disable-next-line no-prototype-builtins
			(!multipleSelected.hasOwnProperty(product.id) ||
				multipleSelected[product.id] !== product.pricing.ratePlanId)
		);
	}, [product, multipleSelected]);

	const calculationText = useMemo(() => {
		if (product.bookingCalculation === bookingCalculations.PER_PERIOD) {
			if (product.bookingPeriod === bookingPeriods.NIGHTLY) return 'per night';
			if (product.bookingPeriod === bookingPeriods.DAILY) return 'per day';
			return 'per hour';
		}
		return product.bookingCalculation;
	}, [product]);

	const buttonClasses = useMemo(() => {
		if (isGrid)
			return {
				multiple: 'col text-left sdms-paddingless',
				book: 'col-auto text-right sdms-paddingless',
			};

		return {
			multiple: 'col text-right',
			book: 'col-auto text-right',
		};
	}, [isGrid]);

	const tooltipData = useMemo(() => {
		let singularTotalText = totalText;

		if (product.numPeriod > 1)
			singularTotalText = singularTotalText.slice(0, singularTotalText.length - 1);

		const startDate = moment(searchData?.fromDate);

		const content = Array(numUnits)
			.fill(0)
			.map((value, index) => (
				<div key={`tooltip-row${product.id}${index + 1}`}>
					{index > 0 && (
						<div className='row sdms-pricing-tooltip-row'>
							<div className='col'>
								<Separator space='sm' />
							</div>
						</div>
					)}
					{numUnits > 1 && (
						<div
							className='row sdms-pricing-tooltip-row'
							style={{ fontWeight: 'bold' }}>
							<div className='col'>
								{product.unitLabel} {index + 1}
							</div>
						</div>
					)}
					{product.pricing.prices[index + 1].map((price, priceIndex) => (
						<div
							className='row sdms-pricing-tooltip-row'
							key={`pricing-tooltip${priceIndex + 1}`}>
							<div className='col'>
								{moment(startDate)
									.add(priceIndex, 'day')
									.format('ddd M/D/YYYY')}
							</div>
							<div className='col'> {priceFormatter(price)}</div>
						</div>
					))}
					<div className='row sdms-pricing-tooltip-row sdms-pricing-tooltip-row-average'>
						<div className='col'>Average / {singularTotalText} :</div>
						<div className='col'>
							{priceFormatter(
								product.pricing.prices[index + 1].reduce((a, b) => a + b, 0) /
									product.pricing.prices[index + 1].length
							)}
						</div>
					</div>
				</div>
			));

		if (product.bookingPeriod === bookingPeriods.NIGHTLY)
			return {
				icon: pages.booking.products.period.nightly.icon,
				design: pages.booking.products.period.nightly.color,
				content,
			};
		if (product.bookingPeriod === bookingPeriods.DAILY)
			return {
				icon: pages.booking.products.period.daily.icon,
				design: pages.booking.products.period.daily.color,
				content,
			};
		if (product.bookingPeriod === bookingPeriods.HOURLY)
			return {
				icon: pages.booking.products.period.hourly.icon,
				design: pages.booking.products.period.hourly.color,
				content,
			};

		// Default
		return {
			icon: 'Screwdriver',
			design: 'brand',
			content,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [product, numUnits, totalText]);

	const _Inner = (
		<>
			<div className='row justify-content-between sdms-online-booking-show-unit-left-below'>
				<div className='col'>
					{product.pricing.ratePlanName && isMulti && (
						<h6>{product.pricing.ratePlanName}</h6>
					)}
				</div>
				<div className='col-auto text-right'>
					<div className='sdms-booking-item__remaining'>
						{maxNumUnits > 0 &&
							(!outlet.settings.showUnitsLeftBelow ||
								maxNumUnits < outlet.settings.showUnitsLeftBelow) && (
								<Badge className='sdms-mr-0' design='success' isInline>
									{`We have ${maxNumUnits} left at`}
								</Badge>
							)}
					</div>
				</div>
			</div>
			{(product.displayPrice || product.displayViewButton) && (
				<div className='row justify-content-between'>
					{!isGrid && <div className='col-6' />}
					<div className={isGrid ? 'col-12' : 'col-6'}>
						<div className='sdms-booking-item__remaining'>
							{product.displayViewButton && (
								<div className='col-auto text-right'>
									<Button
										className='sdms-booking-result-item__reserve-btn'
										label='brand'
										text='View'
										icon='Angle-right-circle'
										size='sm'
										elevate
										onClick={() => window.open(product.onlineUrl, '_blank')}
									/>
								</div>
							)}
							{product.displayPrice && (
								<>
									<div className='booking-item__actual-price sdms-font-xl sdms-font-bolder text-right'>
										{priceFormatter(avgPrice)}
									</div>
									<p className='sdms-font-sm text-right sdms-mb-0'>
										{calculationText}
									</p>
									<p
										className='sdms-font-sm sdms-font-bold text-right'
										data-tip='tooltip'
										data-for={`booking-item-price_${product.id}`}
										data-event='touchstart focus mouseover'
										data-event-off='mouseout'
										globalEventOff='touchstart'>
										{!product.isHourly &&
											`${priceFormatter(totalPrice)} for ${
												product.numPeriod
											} ${totalText}`}
									</p>
									<Portal>
										<ReactTooltip
											id={`booking-item-price_${product.id}`}
											type='light'
											delayShow={500}
											place={isGrid ? 'top' : 'left'}
											className='sdms-p0'
											clickable>
											<IconBox
												icon={tooltipData.icon}
												design={tooltipData.design}
												title={product.name}
												className='sdms-marginless sdms-portlet--fit'>
												{tooltipData.content}
											</IconBox>
										</ReactTooltip>
									</Portal>
								</>
							)}
						</div>
					</div>
				</div>
			)}
			<div className='row justify-content-between'>
				{product.hasAvailableUnit ? (
					<>
						<div className={buttonClasses.multiple}>
							{maxNumUnits > 1 &&
								product.bookBy === bookBies.UNIT &&
								setMultipleSelected && (
									<NumberOfUnitsInput
										value={numUnits}
										setValue={setNumUnits}
										maxValue={
											outlet.settings.maxMultipleUnitsOnline &&
											outlet.settings.maxMultipleUnitsOnline < maxNumUnits &&
											isOnline
												? outlet.settings.maxMultipleUnitsOnline
												: maxNumUnits
										}
										isLoading={isLoading}
										setMultipleSelected={(clear = false) =>
											setMultipleSelected(
												clear
													? null
													: { [product.id]: product.pricing.ratePlanId }
											)
										}
										disabled={isSelectDisabled}
										isGrid={isGrid}
									/>
								)}
						</div>
						<div
							className={buttonClasses.book}
							style={hideBookButton ? { display: 'none' } : {}}>
							<Loading isLoading={isLoading} type='button'>
								<Button
									ref={bookButtonRef}
									className='sdms-booking-result-item__reserve-btn'
									label='brand'
									text='Book'
									icon='Angle-right-circle'
									size='sm'
									elevate
									onClick={() =>
										selectProduct(
											product,
											numUnits,
											product.numPeriod,
											totalText
										)
									}
									disabled={isSelectDisabled}
								/>
							</Loading>
						</div>
					</>
				) : (
					<>
						{product.displayFlexibleDate && (
							<div className='col'>
								<FlexibleDates
									key={product.id}
									dates={product.flexibleDates}
									nightly={product.bookingPeriod === bookingPeriods.NIGHTLY}
									searchData={searchData}
								/>
							</div>
						)}
						{product.displayConstraint && constraintText.length > 0 && (
							<div className='col'>
								<Alert
									icon='Warning-2'
									solid
									design='info'
									bold
									content={constraintText}
								/>
							</div>
						)}
						{!product.displayFlexibleDate && !product.displayConstraint && (
							<div className='col'>
								<Alert
									icon='Warning-2'
									solid
									design='info'
									bold
									content='There are no available units'
								/>
							</div>
						)}
					</>
				)}
			</div>
		</>
	);

	if (isMulti) {
		return (
			<Portlet
				className={classNames('sdms-booking-item__details-price', {
					'sdms-booking-item__details-price--disabled': isSelectDisabled,
				})}
				border>
				<Portlet.Body>{_Inner}</Portlet.Body>
			</Portlet>
		);
	}
	return _Inner;
};
PricingItem.propTypes = {
	productCategories: PropTypes.objectOf(PropTypes.object).isRequired,
	productCategoryKey: PropTypes.string.isRequired,
	productKey: PropTypes.string.isRequired,
	pricingKey: PropTypes.string.isRequired,
	selectProduct: PropTypes.func.isRequired,
	isMulti: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	multipleSelected: PropTypes.object,
	setMultipleSelected: PropTypes.func,
	outlet: PropTypes.shape({
		id: PropTypes.number,
		taxRate: PropTypes.object,
		settings: PropTypes.shape({
			paymentGateway: PropTypes.object,
			taxRate: PropTypes.object,
			onlineSiteToken: PropTypes.string,
			defaultCustomer: PropTypes.object,
			termsAndConditions: PropTypes.string,
			checkoutTotalText: PropTypes.string,
			showUnitsLeftBelow: PropTypes.number,
			maxMultipleUnitsOnline: PropTypes.number,
		}),
		country: PropTypes.object,
		paymentProcessors: PropTypes.arrayOf(PropTypes.object),
	}).isRequired,
	isOnline: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initProducts: PropTypes.array.isRequired,
	isGrid: PropTypes.bool,
	hideBookButton: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	searchData: PropTypes.object,
};
PricingItem.defaultProps = {
	multipleSelected: null,
	isGrid: false,
	setMultipleSelected: null,
	hideBookButton: false,
	searchData: null,
};

export default PricingItem;
