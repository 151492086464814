import React from 'react';
import { ReactSVG } from 'react-svg';
import bg from '../../assets/img/bg.svg';
import logo from '../../assets/img/logo.svg';
import ContentInner from '../reusables/template/ContentInner';

const Dashboard = () => {
	return (
		<ContentInner.Container hasFrame>
			<div className='row'>
				<div className='col-3' />
				<div className='col-6 sdms-align-center'>
					<ReactSVG
						src={logo}
						className='svg-container sdms-block-center sdms-w-35p-desktop sdms-margin-t-50'
					/>
					<ReactSVG src={bg} className='svg-container sdms-margin-t-50' />
				</div>
				<div className='col-3' />
			</div>
		</ContentInner.Container>
	);
};

export default Dashboard;
