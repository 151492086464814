import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Separator = ({ className, design, space, isFit, type, size }) => {
	return (
		<div
			className={classNames(
				'sdms-separator',
				{ 'sdms-separator--fit': isFit },
				{ [`sdms-separator--${design}`]: design },
				{ [`sdms-separator--${type}`]: type },
				{ [`sdms-separator--${size}`]: size },
				{ [`sdms-separator--space-${space}`]: space },
				className
			)}
		/>
	);
};

Separator.propTypes = {
	className: PropTypes.string,
	design: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'info',
		'light',
		'primary',
		'secondary',
		'success',
		'warning',
	]),
	space: PropTypes.oneOf(['space', 'sm', 'md', 'lg', 'xl']),
	size: PropTypes.oneOf(['sm', 'md', 'lg']),
	type: PropTypes.oneOf(['dashed', 'border-dashed', 'border-2x']),
	isFit: PropTypes.bool,
};

Separator.defaultProps = {
	className: null,
	design: null,
	space: null,
	size: null,
	type: null,
	isFit: false,
};

export default Separator;
