import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import {
	mediaBreakpoint,
	paymentGateWays,
	paymentTypes as _paymentTypes,
} from '../../../utils/constants/constants';
import { addErrorNotification, priceFormatter, useWindowSize } from '../../../utils/helpers/helper';
import usePaymentGateway, {
	getPaymentProcessor,
	parsePaymentData,
} from '../../../utils/hooks/usePaymentGateway';

import Portal from '../layout/Portal';
import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import Toggle from '../field/Toggle';
import QuickPanel from '../../QuickPanel';
import Cash from './PayModal/Cash';
import HouseAccount from './PayModal/HouseAccount';
import CreditCard from './PayModal/CreditCard';
import Check from './PayModal/Check';
import Booking from './PayModal/Booking';
import Input from '../field/Input';
import Wire from './PayModal/Wire';

const PayModal = ({
	isOpen,
	onClose,
	amount,
	paymentTypes,
	customer,
	holdForSignatureDefault,
	printReceiptDefault,
	onPay,
	isPaying,
	activePaymentMethod,
	canSavePaymentMethod,
	isRefund,
	isDeposit,
	canEnterCreditCard,
	isPayBill,
	refundAmountChangeable,
	max,
	defaultCreditCard,
	balanceDue,
	serviceFeeModule,
	disableServiceFee,
	authorizedPayment,
	customServiceFeeAmounts,
	outlet,
	displayEmailReceipt,
	emailReceiptDefault,
	isPrePayment,
	automaticallySettleDefault,
}) => {
	const userContext = useContext(UserContext);

	const windowSize = useWindowSize();

	const [payment, setPayment] = useState({
		amount,
		customer,
		holdForSignature: holdForSignatureDefault,
		printReceipt: printReceiptDefault,
		emailReceipt: emailReceiptDefault,
		automaticallySettle: automaticallySettleDefault,
	});

	const [tokenizePayment, isGeneratingToken] = usePaymentGateway(outlet, []);

	const [isReadingCC, setIsReadingCC] = useState(false);

	const [tabDisplaySave, setTabDisplaySave] = useState(false);

	const [reference, setReference] = useState('');

	const [referenceFocused, setReferenceFocused] = useState(false);

	const checkPaymentProcessor = useMemo(() => getPaymentProcessor(outlet, _paymentTypes.CHECK), [
		outlet,
	]);

	const buttonText = useMemo(() => {
		if (isReadingCC) return 'Reading...';

		if (isGeneratingToken) return 'Connecting...';

		if (isPaying) return isRefund ? 'Refunding' : 'Paying';

		return isRefund ? 'Refund' : 'Pay';
	}, [isGeneratingToken, isPaying, isReadingCC, isRefund]);

	const displaySaveToggle = useMemo(() => {
		const paymentProcessor = getPaymentProcessor(outlet, payment.type);

		return (
			paymentProcessor &&
			customer &&
			paymentProcessor.gateway.value !== paymentGateWays.SIDE_TERMINAL_CREDIT_CARD &&
			(customer.canSavePaymentMethod === null ||
				(customer.canSavePaymentMethod && canSavePaymentMethod && tabDisplaySave))
		);
	}, [customer, canSavePaymentMethod, payment.type, tabDisplaySave, outlet]);

	const getAvailablePaymentTypes = () =>
		paymentTypes.filter(pt => {
			if (pt.value === _paymentTypes.WIRE && (isRefund || !isDeposit)) return false;

			return !isPayBill || pt.value !== _paymentTypes.HOUSE_ACCOUNT;
		});

	const getDefaultPaymentMethodIndex = () => {
		if (activePaymentMethod) {
			const index = getAvailablePaymentTypes().findIndex(
				pt => pt.value === activePaymentMethod
			);

			if (index > -1) return index;
		}

		if (authorizedPayment || userContext.data.user.company.settings.defaultCreditCard) {
			const index = getAvailablePaymentTypes().findIndex(
				pt => pt.value === _paymentTypes.CREDIT_CARD
			);

			if (index > -1) return index;
		}

		return 0;
	};

	const setPaymentData = data => setPayment({ ...payment, ...data });

	const getTabContent = paymentType => {
		if (paymentType.value === _paymentTypes.CASH)
			return (
				<Portlet.TabItem
					isDisabled={payment && payment.type !== _paymentTypes.CASH && isPaying}
					title='Cash'
					icon='Money'
					key={paymentType.value}>
					<Cash
						amount={amount}
						payment={payment}
						setPaymentData={setPaymentData}
						isRefund={isRefund}
						refundAmountChangeable={refundAmountChangeable}
						max={max}
						isPayBill={isPayBill}
						balanceDue={balanceDue}
						disable={referenceFocused}
					/>
				</Portlet.TabItem>
			);

		if (paymentType.value === _paymentTypes.HOUSE_ACCOUNT)
			return (
				<Portlet.TabItem
					isDisabled={payment && payment.type !== _paymentTypes.HOUSE_ACCOUNT && isPaying}
					title={isRefund ? 'Credit Memo' : 'House Account'}
					icon='Home'
					key={paymentType.id}>
					<HouseAccount
						amount={amount}
						payment={payment}
						setPaymentData={setPaymentData}
						isRefund={isRefund}
						refundAmountChangeable={refundAmountChangeable}
						max={max}
						balanceDue={balanceDue}
					/>
				</Portlet.TabItem>
			);

		if (paymentType.value === _paymentTypes.CREDIT_CARD)
			return (
				<Portlet.TabItem
					isDisabled={payment && payment.type !== _paymentTypes.CREDIT_CARD && isPaying}
					title='Credit Card'
					icon='Credit-card'
					key={paymentType.value}>
					<CreditCard
						outlet={outlet}
						amount={amount}
						payment={payment}
						setPaymentData={setPaymentData}
						pay={pay}
						canSaveCreditCard={canSavePaymentMethod}
						isRefund={isRefund}
						canEnterCreditCard={canEnterCreditCard}
						isPayBill={isPayBill}
						isPaying={isPaying}
						refundAmountChangeable={refundAmountChangeable}
						max={max}
						balanceDue={balanceDue}
						defaultCreditCard={defaultCreditCard}
						paymentGateway={outlet.settings.paymentGateway}
						isReading={isReadingCC}
						setIsReading={setIsReadingCC}
						serviceFeeModule={serviceFeeModule}
						disableServiceFee={disableServiceFee}
						isServiceFeeEditable={userContext.hasPermission('edit_cc_service_fee')}
						setTabDisplaySave={setTabDisplaySave}
						paymentProcessor={getPaymentProcessor(outlet, _paymentTypes.CREDIT_CARD)}
						authorizedPayment={authorizedPayment}
						customServiceFeeAmount={customServiceFeeAmounts?.creditCard}
					/>
				</Portlet.TabItem>
			);

		if (paymentType.value === _paymentTypes.CHECK)
			return (
				<Portlet.TabItem
					isDisabled={payment && payment.type !== _paymentTypes.CHECK && isPaying}
					title='Check'
					icon='Credit-card'
					key={paymentType.value}>
					<Check
						outlet={outlet}
						amount={amount}
						payment={payment}
						setPaymentData={setPaymentData}
						isRefund={isRefund}
						isPayBill={isPayBill}
						refundAmountChangeable={refundAmountChangeable}
						max={max}
						balanceDue={balanceDue}
						paymentProcessor={checkPaymentProcessor}
						isPaying={isPaying}
						setTabDisplaySave={setTabDisplaySave}
						serviceFeeModule={serviceFeeModule}
						disableServiceFee={disableServiceFee}
						isServiceFeeEditable={userContext.hasPermission('edit_cc_service_fee')}
						customServiceFeeAmount={customServiceFeeAmounts?.check}
						disable={referenceFocused}
					/>
				</Portlet.TabItem>
			);

		if (paymentType.value === _paymentTypes.BOOKING)
			return (
				<Portlet.TabItem title='Booking' icon='Building' key={paymentType.id}>
					<Booking
						amount={amount}
						payment={payment}
						setPaymentData={setPaymentData}
						balanceDue={balanceDue}
					/>
				</Portlet.TabItem>
			);

		if (paymentType.value === _paymentTypes.WIRE)
			return (
				<Portlet.TabItem
					isDisabled={payment && payment.type !== _paymentTypes.WIRE && isPaying}
					title='Wire'
					icon='Exchange'
					key={paymentType.value}>
					<Wire
						amount={amount}
						payment={payment}
						setPaymentData={setPaymentData}
						balanceDue={balanceDue}
						disable={referenceFocused}
					/>
				</Portlet.TabItem>
			);

		return null;
	};

	const pay = (swipe = null) => {
		if (payment.amount === '') {
			addErrorNotification('Please enter valid amount');
			return;
		}

		if (isPayBill && payment.amount < amount) {
			addErrorNotification(`You cannot pay less than ${priceFormatter(amount)}`);
			return;
		}

		if (!payment.customer) {
			addErrorNotification('Customer cannot be empty!');
			return;
		}

		if (payment.type === _paymentTypes.HOUSE_ACCOUNT && !payment.customer.houseAccount) {
			addErrorNotification('Customer has no house account!');
			return;
		}

		if (payment.type === _paymentTypes.CHECK) {
			if (getPaymentProcessor(outlet, _paymentTypes.CHECK) && !payment.checkNumber) {
				if (
					!payment.achAccount &&
					(!payment.bankName ||
						!payment.bankName ||
						!payment.routingNumber ||
						!payment.accountNumber)
				) {
					addErrorNotification('Please enter required fields.');
					return;
				}
			} else if (!payment.checkNumber) {
				addErrorNotification('Please enter check number');
				return;
			}
		}

		if (
			payment.type === _paymentTypes.CREDIT_CARD &&
			getPaymentProcessor(outlet, _paymentTypes.CREDIT_CARD)?.gateway?.value !==
				paymentGateWays.SIDE_TERMINAL_CREDIT_CARD &&
			!swipe &&
			!payment.creditCard &&
			(!payment.cardNumber ||
				payment.cardNumber === '' ||
				!payment.expiry ||
				payment.expiry === '')
		) {
			addErrorNotification('Please swipe card or manually enter!');

			return;
		}

		let paymentData = {
			...payment,
			swipe,
			amount: parseFloat(payment.amount).toFixed(2),
			changeDue: payment.amount - amount > 0 ? payment.amount - amount : 0,
			customer: payment.customer.id,
			creditCard: payment.creditCard && !swipe ? payment.creditCard.id : null,
			achAccount: payment.achAccount ? payment.achAccount.id : null,
			token: !swipe ? payment.token : null,
			reference,
		};

		tokenizePayment(
			payment.type,
			parsePaymentData(payment.type, { ...paymentData, swipe }),
			tokenizedPayment => {
				if (tokenizedPayment) paymentData = { ...paymentData, ...tokenizedPayment };

				onPay(paymentData);

				setReference('');
			},
			err => addErrorNotification(err.toString())
		);
	};

	const _Inner = (
		<>
			<Portlet.Tabs
				id='terminal-pay'
				design='info'
				activeTab={getDefaultPaymentMethodIndex()}>
				{getAvailablePaymentTypes().map(pt => getTabContent(pt))}
			</Portlet.Tabs>
			{payment.type !== _paymentTypes.HOUSE_ACCOUNT &&
				payment.type !== _paymentTypes.BOOKING && (
					<div style={{ padding: '0px 1rem 1rem 1rem' }}>
						<Input
							type='text'
							placeholder='Reference'
							value={reference}
							onChange={e => setReference(e.target.value)}
							onFocus={() => setReferenceFocused(true)}
							onBlur={() => setReferenceFocused(false)}
						/>
					</div>
				)}
			<Portlet.Foot
				tall='sm'
				className='sdms-align-left'
				subClassName='justify-content-between'>
				<div className='col-auto order-1 order-md-0'>
					<Button
						design='clean'
						text='Close'
						icon='Error-circle'
						size='sm'
						disabled={isPaying}
						onClick={onClose}
					/>
				</div>
				{!isRefund && displayEmailReceipt && (
					<div className='col-md-auto col-12 order-0 order-md-1 sdms-mb-10-mobile'>
						<div className='form-group align-items-center d-flex form-group-last sdms-last-margin--h'>
							<Toggle
								id='sdms-pay--print-receipt'
								spaceLess
								onChange={() =>
									setPaymentData({
										emailReceipt: !payment.emailReceipt,
									})
								}
								value={payment.emailReceipt || false}
							/>
							{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
							<label
								htmlFor='sdms-pay--print-receipt'
								className='sdms-cursor--pointer'>
								Email
							</label>
						</div>
					</div>
				)}
				{!isRefund &&
					(payment.type === _paymentTypes.CREDIT_CARD ||
					payment.type === _paymentTypes.HOUSE_ACCOUNT ? (
						<div className='col-md-auto col-12 order-0 order-md-1 sdms-mb-10-mobile'>
							<div className='form-group align-items-center d-flex form-group-last sdms-last-margin--h'>
								<Toggle
									id='sdms-pay--hold-for-signature'
									spaceLess
									onChange={() =>
										setPaymentData({
											holdForSignature: !payment.holdForSignature,
										})
									}
									value={payment.holdForSignature || false}
								/>
								{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
								<label
									htmlFor='sdms-pay--hold-for-signature'
									className='sdms-cursor--pointer'>
									Hold
								</label>
							</div>
						</div>
					) : (
						<div className='col-md-auto col-12 order-0 order-md-1 sdms-mb-10-mobile'>
							<div className='form-group align-items-center d-flex form-group-last sdms-last-margin--h'>
								<Toggle
									id='sdms-pay--print-receipt'
									spaceLess
									onChange={() =>
										setPaymentData({
											printReceipt: !payment.printReceipt,
										})
									}
									value={payment.printReceipt || false}
								/>
								{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
								<label
									htmlFor='sdms-pay--print-receipt'
									className='sdms-cursor--pointer'>
									Print
								</label>
							</div>
						</div>
					))}
				{displaySaveToggle && (
					<div className='col-md-auto col-12 order-0 order-md-1 sdms-mb-10-mobile'>
						<div className='form-group align-items-center d-flex form-group-last sdms-last-margin--h'>
							<Toggle
								id='sdms-pay--save-payment-method'
								spaceLess
								onChange={() =>
									setPaymentData({
										save: !payment.save,
									})
								}
								value={payment.save || false}
							/>
							{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
							<label
								htmlFor='sdms-pay--print-receipt'
								className='sdms-cursor--pointer'>
								Save
							</label>
						</div>
					</div>
				)}
				{isPrePayment && (
					<div className='col-md-auto col-12 order-0 order-md-1 sdms-mb-10-mobile'>
						<div className='form-group align-items-center d-flex form-group-last sdms-last-margin--h'>
							<Toggle
								id='sdms-pay--auto-settle'
								spaceLess
								onChange={() =>
									setPaymentData({
										automaticallySettle: !payment.automaticallySettle,
									})
								}
								value={payment.automaticallySettle}
							/>
							{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
							<label
								htmlFor='sdms-pay--print-receipt'
								className='sdms-cursor--pointer'>
								Auto Settle
							</label>
						</div>
					</div>
				)}
				<div className='col-auto order-last'>
					<Button
						className={classNames({
							'sdms-fading-dots': isPaying,
						})}
						design='success'
						icon={
							isRefund
								? 'Undo'
								: (isPaying && process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON) ||
								  'Money'
						}
						text={buttonText}
						size='sm'
						disabled={
							isPaying ||
							isGeneratingToken ||
							isReadingCC ||
							!payment.amount ||
							Number(payment.amount) === 0
						}
						onClick={() => pay()}
					/>
				</div>
			</Portlet.Foot>
		</>
	);

	useEffect(() => {
		setPayment({
			amount: isRefund ? amount.toString() : '',
			customer,
			holdForSignature: holdForSignatureDefault,
			printReceipt: printReceiptDefault,
			emailReceipt: emailReceiptDefault,
			automaticallySettle: automaticallySettleDefault,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	if (windowSize.width < mediaBreakpoint.MD) {
		return (
			<QuickPanel
				status={isOpen}
				setStatus={onClose}
				portletClass='sdms-portlet--tabs sdms-portlet--tabs-nowrap'>
				{_Inner}
			</QuickPanel>
		);
	}
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
					height: '630px',
				}}>
				<Portlet className='sdms-portlet--tabs h-100' fluid='fluid'>
					{_Inner}
				</Portlet>
			</Popup>
		</Portal>
	);
};
PayModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	amount: PropTypes.number,
	paymentTypes: PropTypes.arrayOf(PropTypes.object),
	customer: PropTypes.shape({
		id: PropTypes.number,
		displayName: PropTypes.string,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		phone: PropTypes.string,
		altPhone: PropTypes.string,
		email: PropTypes.string,
		addressLineOne: PropTypes.string,
		addressLineTwo: PropTypes.string,
		city: PropTypes.string,
		zip: PropTypes.string,
		state: PropTypes.object,
		houseAccount: PropTypes.bool,
		canSavePaymentMethod: PropTypes.bool,
	}),
	holdForSignatureDefault: PropTypes.bool,
	printReceiptDefault: PropTypes.bool,
	onPay: PropTypes.func,
	isPaying: PropTypes.bool,
	activePaymentMethod: PropTypes.string,
	canSavePaymentMethod: PropTypes.bool,
	isRefund: PropTypes.bool,
	isDeposit: PropTypes.bool,
	canEnterCreditCard: PropTypes.bool,
	isPayBill: PropTypes.bool,
	refundAmountChangeable: PropTypes.bool,
	max: PropTypes.number,
	defaultCreditCard: PropTypes.number,
	balanceDue: PropTypes.number,
	serviceFeeModule: PropTypes.string.isRequired,
	disableServiceFee: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	authorizedPayment: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	customServiceFeeAmounts: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	displayEmailReceipt: PropTypes.bool,
	emailReceiptDefault: PropTypes.bool,
	isPrePayment: PropTypes.bool,
	automaticallySettleDefault: PropTypes.bool,
};
PayModal.defaultProps = {
	isOpen: false,
	onClose: () => {},
	amount: 0,
	paymentTypes: [],
	customer: {
		id: 0,
	},
	holdForSignatureDefault: false,
	printReceiptDefault: true,
	onPay: () => {},
	isPaying: false,
	activePaymentMethod: '',
	canSavePaymentMethod: false,
	isRefund: false,
	isDeposit: false,
	canEnterCreditCard: false,
	isPayBill: false,
	refundAmountChangeable: false,
	max: 0,
	defaultCreditCard: null,
	balanceDue: 0,
	disableServiceFee: false,
	authorizedPayment: null,
	customServiceFeeAmounts: null,
	displayEmailReceipt: false,
	emailReceiptDefault: false,
	isPrePayment: false,
	automaticallySettleDefault: false,
};

export default PayModal;
