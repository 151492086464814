import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import HeaderContext from '../../../../app/contexts/HeaderContext';
import { getModuleByPath, modulePageKeys } from '../../../../utils/helpers/reusable';
import pages from '../../../pages';

import Portlet from '../../layout/Portlet';
import IconBox from '../../layout/IconBox';
import ContentInner from '../../template/ContentInner';
import BreadcrumbContainer from '../../template/BreadcrumbContainer';
import Button from '../../element/Button';

const AdvancePeriodSelect = () => {
	const history = useHistory();

	const headerContext = useContext(HeaderContext);

	const location = useLocation();

	const data = useMemo(() => {
		const module = getModuleByPath(location.pathname);

		return {
			module,
			page: pages[modulePageKeys[module]],
		};
	}, [location]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: data.page.default.text, path: data.page.dashboard.path },
			{ title: data.page.reservations.text, path: data.page.reservations.path },
			{ title: 'Select Period', isActive: true },
		]);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [data]);

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title='Select Period' />
					<BreadcrumbContainer />
				</ContentInner.SubHeaderItem>
				<ContentInner.SubHeaderItem type='toolbar'>
					<Button
						design='default'
						text='Back To The List'
						onClick={() => history.push(data.page.reservations.path)}
					/>
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title='Select Period'>
				<Portlet fluid='fluid'>
					<Portlet.Body>
						<div className='row sdms-iconbox--container'>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox
									icon={data.page.reservations.transient.icon}
									title={data.page.reservations.transient.text}
									onClick={() =>
										history.push(`${data.page.reservations.transient.path}/0`)
									}
									design={data.page.reservations.transient.color}
									animate='fast'>
									Booked hourly, daily and nightly
								</IconBox>
							</div>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox
									icon={data.page.reservations.seasonal.icon}
									title={data.page.reservations.seasonal.text}
									onClick={() =>
										history.push(`${data.page.reservations.seasonal.path}/0`)
									}
									design={data.page.reservations.seasonal.color}>
									Booked seasonal
								</IconBox>
							</div>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox
									icon={data.page.reservations.longTerm.icon}
									title={data.page.reservations.longTerm.text}
									onClick={() =>
										history.push(`${data.page.reservations.longTerm.path}/0`)
									}
									design={data.page.reservations.longTerm.color}>
									Booked long term
								</IconBox>
							</div>
						</div>
					</Portlet.Body>
				</Portlet>
			</ContentInner.Container>
		</>
	);
};

export default AdvancePeriodSelect;
