import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Head = ({ id, className, children }) => {
	return (
		<div
			id={id}
			className={classNames(
				'sdms-header',
				'sdms-grid__item',
				'sdms-header--fixed',
				className
			)}>
			{children}
		</div>
	);
};
Head.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};
Head.defaultProps = {
	id: 'sdms_header',
	className: null,
};

const MenuWrapper = ({ id, className, children, forMobile, mobileRight, overlay, setOverlay }) => {
	return (
		<>
			<div
				id={id}
				className={classNames(
					'sdms-header-menu-wrapper',
					{ 'sdms-header-menu-wrapper--on': overlay.header },
					{ 'sdms-header-menu-wrapper--mobile': forMobile },
					{ 'sdms-header-menu-wrapper--right': mobileRight },
					className
				)}>
				{children}
			</div>
			{forMobile && (
				<div
					role='presentation'
					className='sdms-header-menu-wrapper-overlay'
					onClick={() =>
						setOverlay({
							aside: false,
							header: false,
							topBar: false,
							order: false,
						})
					}
				/>
			)}
		</>
	);
};
MenuWrapper.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	forMobile: PropTypes.bool,
	mobileRight: PropTypes.bool,
	overlay: PropTypes.shape({
		aside: PropTypes.bool,
		header: PropTypes.bool,
		topBar: PropTypes.bool,
		order: PropTypes.bool,
	}),
	setOverlay: PropTypes.func,
};
MenuWrapper.defaultProps = {
	id: 'sdms_header_menu_wrapper',
	className: null,
	forMobile: false,
	mobileRight: false,
	overlay: {
		aside: false,
		header: false,
		topBar: false,
		order: false,
	},
	setOverlay: () => {},
};

const Menu = ({ id, className, children, notMore }) => {
	return (
		<div
			id={id}
			className={classNames(
				'sdms-header-menu',
				'sdms-header-menu-mobile',
				'sdms-header-menu--layout-default',
				{ 'sdms-header-menu--not-more': notMore },
				className
			)}>
			{children}
		</div>
	);
};
Menu.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	notMore: PropTypes.bool,
};
Menu.defaultProps = {
	id: 'sdms_header_menu',
	className: null,
	notMore: false,
};

Head.MenuWrapper = MenuWrapper;
Head.Menu = Menu;

export default Head;
