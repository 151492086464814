import React from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { modules } from '../../../utils/helpers/apiCall';
import { debugTypes } from '../../../utils/helpers/debugHelper';
import useOutlet from '../../../utils/hooks/useOutlet';

import FormContainer from '../../reusables/template/FormContainer';
import ProductForm from './ProductForm';
import OutletInactive from '../../reusables/element/OutletInactive';

const ProductEdit = ({ match }) => {
	const [, outletConfigured] = useOutlet();

	if (!outletConfigured) return <OutletInactive />;

	return (
		<DndProvider backend={HTML5Backend}>
			<FormContainer
				dataId={match.params.id}
				pageTitle='Product Name'
				dataName='posProducts'
				lists={[
					'accounts',
					'taxRates',
					'taxCodes',
					'unitMeasures',
					'itemTypes',
					'productCategories',
					'prepStations',
					'modifierPrefixes',
					'modifierGroups',
					'enumProductServiceFees',
				]}
				fields={<ProductForm />}
				module={modules.POS}
				debugType={debugTypes.product}
			/>
		</DndProvider>
	);
};

ProductEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ProductEdit;
