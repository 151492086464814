import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FormGroup = ({ children, className, row, isLast }) => (
	<div className={classNames('form-group', { row }, { 'form-group-last': isLast }, className)}>
		{children}
	</div>
);
FormGroup.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
	className: PropTypes.string,
	row: PropTypes.bool,
	isLast: PropTypes.bool,
};
FormGroup.defaultProps = {
	className: null,
	row: true,
	isLast: false,
};

export default FormGroup;
