import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Textarea = React.forwardRef(
	(
		{ className, disabled, id, name, onChange, placeholder, textCol, textRow, value, onBlur },
		ref
	) => (
		<textarea
			className={classNames('form-control', className)}
			disabled={disabled}
			id={id}
			name={name}
			onChange={onChange}
			placeholder={placeholder}
			cols={textCol}
			rows={textRow}
			value={value}
			ref={ref}
			onBlur={() => onBlur()}
		/>
	)
);

Textarea.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	id: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	textCol: PropTypes.number,
	textRow: PropTypes.number,
	value: PropTypes.string,
	onBlur: PropTypes.func,
};

Textarea.defaultProps = {
	className: null,
	disabled: false,
	id: null,
	name: null,
	placeholder: null,
	textCol: null,
	textRow: null,
	value: '',
	onBlur: () => {},
};

export default Textarea;
