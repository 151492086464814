import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import Portal from '../../reusables/layout/Portal';
import apiCall from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	addSuccessNotification,
	priceFormatter,
} from '../../../utils/helpers/helper';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import NullBadge from '../../reusables/design/NullBadge';
import Badge from '../../reusables/element/Badge';
import UserContext from '../../../app/contexts/UserContext';
import forms from '../../forms';
import useModal from '../../../utils/hooks/useModal';
import CustomerModal from '../../reusables/modals/CustomerModal';
import DialogBox from '../../reusables/element/DialogBox';
import { filterComponents } from '../../../utils/constants/constants';

const modals = {
	CUSTOMER: 'customer',
	CUSTOMER_MERGE_DIALOG: 'customer_merge_dialog',
};

const CustomerMailCell = ({ data }) => {
	return (
		<a href={`mailto:${data.email}`} className='sdms-link sdms-text-overflow'>
			{data.email}
		</a>
	);
};
CustomerMailCell.propTypes = {
	data: PropTypes.shape({
		email: PropTypes.string,
	}),
};
CustomerMailCell.defaultProps = {
	data: {
		email: null,
	},
};

const CustomerPhoneCell = ({ data }) => {
	if (data.phone === null) return null;
	return (
		<a href={`tel:${data.phone}`} className='sdms-link sdms-text-overflow'>
			{data.phone}
		</a>
	);
};
CustomerPhoneCell.propTypes = {
	data: PropTypes.shape({
		phone: PropTypes.string,
	}),
};
CustomerPhoneCell.defaultProps = {
	data: {
		phone: null,
	},
};

const CustomerAddressCell = ({ data }) => {
	if (data.addressLineOne === '' || data.city === '' || data.state === null) return <NullBadge />;
	return (
		<div>
			<div>{data.addressLineOne}</div>
			<div>{data.addressLineTwo}</div>
			<div>
				{data.city} {data.state ? data.state.code : ''} {data.zip}{' '}
			</div>
			<div>{data.country ? data.country.code : ''}</div>
		</div>
	);
};
CustomerAddressCell.propTypes = {
	data: PropTypes.shape({
		addressLineOne: PropTypes.string,
		addressLineTwo: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				code: PropTypes.string,
			}),
		]),
		zip: PropTypes.string,
		country: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				code: PropTypes.string,
			}),
		]),
	}),
};
CustomerAddressCell.defaultProps = {
	data: {
		addressLineOne: null,
		addressLineTwo: null,
		city: null,
		state: {
			code: null,
		},
		zip: null,
		country: {
			code: null,
		},
	},
};

const CustomerBalanceCell = ({ data, pages }) => {
	const searchParams = new URLSearchParams();

	searchParams.append('s', data.displayName);

	const accountingError = data.hasAccountingError ? (
		<Badge
			design='danger'
			isInline
			isUnified
			size='lg'
			fontWeight='bold'
			className='sdms-ml-15 sdms-cursor--pointer'
			onClick={() =>
				window.open(
					`${window.location.origin}${
						pages.accounting.qbErrorLogs.path
					}/?${searchParams.toString()}`,
					'_blank'
				)
			}>
			!
		</Badge>
	) : null;

	if (data.customerBalance !== 0)
		return (
			<>
				{priceFormatter(data.customerBalance)}
				{accountingError}
			</>
		);
	return (
		<>
			<Badge design='success' isInline isUnified size='lg' fontWeight='bold'>
				Paid
			</Badge>
			{accountingError}
		</>
	);
};
CustomerBalanceCell.propTypes = {
	data: PropTypes.shape({
		displayName: PropTypes.string,
		customerBalance: PropTypes.number,
		hasAccountingError: PropTypes.bool,
	}),
	// eslint-disable-next-line react/forbid-prop-types
	pages: PropTypes.object.isRequired,
};
CustomerBalanceCell.defaultProps = {
	data: { displayName: '', customerBalance: 0, hasAccountingError: false },
};

const CustomerList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const [modal, openModal, closeModal] = useModal();

	const printButtonRef = useRef();

	const printContentRef = useRef();

	const [printContent, setPrintContent] = useState('');

	const onPrintStatement = customers => {
		apiCall(
			'POST',
			'getPrintStatement',
			res => {
				setPrintContent(res.statements.join('<p style="page-break-before: always">'));
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ outletId: userContext.data.selectedOutlet.id, customerIds: customers.map(i => i.id) }
		);
	};

	const onSendStatement = customers => {
		apiCall(
			'POST',
			'sendStatementEmail',
			() => {
				addSuccessNotification('Email(s) successfully sent');
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ outletId: userContext.data.selectedOutlet.id, customerIds: customers.map(i => i.id) }
		);
	};

	const onSyncCustomer = customers => {
		apiCall(
			'POST',
			'syncCustomer',
			() => {
				addSuccessNotification('Customer sync submitted');
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ outletId: userContext.data.selectedOutlet.id, customerIds: customers.map(i => i.id) }
		);
	};

	const onSendAchSignUpEmail = customers => {
		apiCall(
			'POST',
			'sendAchSignUpEmail',
			() => {
				addSuccessNotification('Email(s) successfully sent');
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ outletId: userContext.data.selectedOutlet.id, customerIds: customers.map(i => i.id) }
		);
	};

	const onMergeCustomers = (newCustomer, customer, updateData) => {
		apiCall(
			'POST',
			'mergeCustomers',
			() => {
				addSuccessNotification('Customers have been merged successfuly');

				updateData([newCustomer.id], [customer.id]);

				closeModal();
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ customer, newCustomer }
		);
	};

	useEffect(() => {
		if (printContent !== '' && printButtonRef.current) {
			printButtonRef.current.click();
			setTimeout(() => {
				setPrintContent('');
			}, 1000);
		}
	}, [printContent]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.crm.default.text, path: pages.crm.dashboard.path },
			{ title: pages.crm.customers.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.crm.customers.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ListContainer
				route='customers'
				history={history}
				title={pages.crm.customers.text}
				customActions={{
					printStatement: customer => {
						onPrintStatement([customer]);
					},
					sendStatement: customer => {
						onSendStatement([customer]);
					},
					syncCustomer: customer => {
						onSyncCustomer([customer]);
					},
					sendAchSignUpEmail: customer => onSendAchSignUpEmail([customer]),
					mergeCustomers: (newCustomer, updateData) =>
						openModal({ open: modals.CUSTOMER, newCustomer, updateData }),
				}}
				customMultiActions={{
					printStatement: {
						func: customers => onPrintStatement(customers),
						component: (
							<Button
								label='primary'
								text='Print Statement(s)'
								key='printStatement'
							/>
						),
					},
					sendStatement: {
						func: customers => onSendStatement(customers),
						component: (
							<Button label='primary' text='Email Statement(s)' key='sendStatement' />
						),
					},
					syncCustomers: {
						func: customers => onSyncCustomer(customers),
						component: (
							<Button label='primary' text='Sync Accounting(s)' key='syncCustomer' />
						),
					},
				}}
				customFilters={[
					{
						component: filterComponents.SELECTS,
						fieldName: 'isActive',
						label: 'Customer Status',
						placeholder: 'Customer Status',
						displayKey: 'value',
						options: [
							{ id: true, value: 'Active' },
							{ id: false, value: 'Inactive' },
						],
						summaryWithLabel: true,
					},
					{
						component: filterComponents.SELECTS,
						fieldName: 'has_default_payment_method',
						label: 'Has Default Payment Method',
						placeholder: 'Has Default Payment Method',
						displayKey: 'value',
						options: [
							{ id: true, value: 'Yes' },
							{ id: false, value: 'No' },
						],
						summaryWithLabel: true,
					},
					{
						component: filterComponents.SELECTS,
						fieldName: 'has_accounting_error',
						label: 'Has Accounting Error',
						placeholder: 'Has Accounting Error',
						displayKey: 'value',
						options: [
							{ id: true, value: 'Yes' },
							{ id: false, value: 'No' },
						],
						summaryWithLabel: true,
					},
				]}
				forms={forms.crm.customers}>
				<List withCheckBox fluid='fluid' responsive='scroll'>
					<List.Col
						label='Id'
						cellData='customerId'
						isLinkColumn={userContext.hasPermission('view_customers')}
						sortable='customerId'
					/>
					<List.Col
						label='Name'
						cellData='displayName'
						isLinkColumn={userContext.hasPermission('view_customers')}
						sortable='displayName'
					/>
					<List.Col label='Email' cellComponent={<CustomerMailCell />} sortable='email' />
					<List.Col
						label='Address'
						cellComponent={<CustomerAddressCell />}
						sortable='addressLineOne'
					/>
					<List.Col
						label='Phone'
						cellComponent={<CustomerPhoneCell />}
						sortable='phone'
					/>
					<List.Col
						label='Balance'
						cellComponent={<CustomerBalanceCell pages={pages} />}
					/>
					<List.Col align='right' onlyHover width={150}>
						<Button
							className='sdms-margin-r-15'
							design='link'
							text='Edit'
							icon='Edit'
							size='sm'
							elevate
							key='edit'
							noPermission={!userContext.hasPermission('edit_customers')}
						/>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item
								itemsColor='danger'
								icon='Trash'
								key='delete'
								noPermission={!userContext.hasPermission('delete_customers')}>
								Delete
							</Dropdown.Item>
							<Dropdown.Item
								icon='Printer'
								text='Print Statement'
								key='printStatement'>
								Print Statement
							</Dropdown.Item>
							<Dropdown.Item
								icon='Mail-box'
								text='Email Statement'
								key='sendStatement'>
								Email Statement
							</Dropdown.Item>
							<Dropdown.Item
								icon='Mail-box'
								text='Send ACH Form'
								key='sendAchSignUpEmail'>
								Send ACH Form
							</Dropdown.Item>
							<Dropdown.Item
								icon='Sign-in'
								key='syncCustomer'
								noPermission={!userContext.hasPermission('sync_customers')}>
								Re-Sync Accounting
							</Dropdown.Item>
							<Dropdown.Item
								icon='User'
								key='mergeCustomers'
								noPermission={!userContext.hasPermission('merge_customers')}>
								Merge Customer
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			<Portal>
				<div
					className='sdms--only-print'
					ref={printContentRef}
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: printContent }}
				/>
			</Portal>
			<ReactToPrint
				trigger={() => (
					<input
						type='button'
						id='printButton'
						style={{ display: 'none' }}
						ref={printButtonRef}
					/>
				)}
				content={() => printContentRef.current}
				pageStyle='@page { size: auto; } @media print { body { -webkit-print-color-adjust: exact; } }'
			/>
			<CustomerModal
				onClose={closeModal}
				setSelectedCustomer={customer => {
					if (customer.id.toString() === modal.newCustomer.id.toString()) {
						addErrorNotification(
							'You cannot select same customer. Please select different customer to merge.'
						);
						return;
					}

					openModal({ open: modals.CUSTOMER_MERGE_DIALOG, customer });
				}}
				isOpen={modal.open === modals.CUSTOMER}
				closeOnSelect={false}
			/>
			<DialogBox
				open={modal.open === modals.CUSTOMER_MERGE_DIALOG}
				title=''
				content='Customers will merge. Are you sure?'
				type='question'>
				<Button
					className='sdms-font-transform-c'
					text='Yes, Merge'
					label='success'
					icon='Edit'
					onClick={() =>
						onMergeCustomers(modal.newCustomer, modal.customer, modal.updateData)
					}
				/>
				<Button
					className='sdms-font-transform-c'
					text='No'
					design='clean'
					icon='Edit'
					onClick={closeModal}
				/>
			</DialogBox>
		</>
	);
};
CustomerList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default CustomerList;
