import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Badge from './Badge';
import SVGIcon from './SVGIcon';

export const BookBy = ({ data }) => {
	return <span>{data.bookBy.value}</span>;
};
BookBy.propTypes = {
	data: PropTypes.shape({
		bookBy: PropTypes.shape({
			value: PropTypes.string,
		}),
	}),
};

BookBy.defaultProps = {
	data: {
		bookBy: { value: '#' },
	},
};

export const InactiveCell = ({ data }) => (data.inactive ? <Badge.No /> : <Badge.Yes />);

InactiveCell.propTypes = {
	data: PropTypes.shape({
		inactive: PropTypes.bool,
	}),
};

InactiveCell.defaultProps = {
	data: {
		inactive: false,
	},
};

export const ShowOnlineCell = ({ data }) => (data.showOnline ? <Badge.Yes /> : <Badge.No />);

ShowOnlineCell.propTypes = {
	data: PropTypes.shape({
		showOnline: PropTypes.bool,
	}),
};

ShowOnlineCell.defaultProps = {
	data: {
		showOnline: false,
	},
};

export const Transient = ({ data }) => {
	return (
		<>
			<SVGIcon
				toolTip={`The nightly state is ${!data.nightly ? 'not' : ''} active`}
				name='Moon'
				fill={data.nightly ? 'var(--info)' : 'var(--gray)'}
				className={classNames({ 'sdms-opacity-2': !data.nightly })}
			/>
			<SVGIcon
				toolTip={`The daily state is ${!data.daily ? 'not' : ''} active`}
				name='Sun'
				fill={data.daily ? 'var(--warning)' : 'var(--gray)'}
				className={classNames({ 'sdms-opacity-2': !data.daily })}
			/>
			<SVGIcon
				toolTip={`The hourly state is ${!data.hourly ? 'not' : ''} active`}
				name='Clock'
				fill={data.hourly ? 'var(--success)' : 'var(--gray)'}
				className={classNames({ 'sdms-opacity-2': !data.hourly })}
			/>
			<SVGIcon
				toolTip={`The seasonal state is ${!data.seasonal ? 'not' : ''} active`}
				name='Layout-4-blocks'
				fill={data.seasonal ? 'var(--indigo)' : 'var(--gray)'}
				className={classNames({ 'sdms-opacity-2': !data.seasonal })}
			/>
			<SVGIcon
				toolTip={`The long term state is ${!data.longTerm ? 'not' : ''} active`}
				name='Clipboard-check'
				fill={data.longTerm ? 'var(--primary)' : 'var(--gray)'}
				className={classNames({ 'sdms-opacity-2': !data.longTerm })}
			/>
		</>
	);
};
Transient.propTypes = {
	data: PropTypes.shape({
		nightly: PropTypes.bool,
		daily: PropTypes.bool,
		hourly: PropTypes.bool,
		seasonal: PropTypes.bool,
		longTerm: PropTypes.bool,
	}),
};

Transient.defaultProps = {
	data: {
		nightly: false,
		daily: false,
		hourly: false,
		seasonal: false,
		longTerm: false,
	},
};
