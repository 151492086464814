import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { bookingCalculations } from '../../../utils/constants/constants';
import {
	getBookingCalculationText,
	getItemTaxCode,
	priceFormatter,
} from '../../../utils/helpers/helper';

import Input from '../field/Input';
import Toggle from '../field/Toggle';
import Button from './Button';
import SVGIcon from './SVGIcon';

export const ExtraFeeHead = ({ settingsEnable, displayQuantity }) => {
	return (
		<>
			<colgroup>
				<col style={{ width: '60px' }} />
				<col style={{ width: '180px' }} />
				{displayQuantity && <col />}
				<col />
				<col />
				<col />
				<col />
				<col />
				{settingsEnable && <col />}
			</colgroup>
			<thead>
				<tr>
					<th colSpan={2}>Name</th>
					<th>Price</th>
					{displayQuantity && <th>Quantity</th>}
					<th>Per</th>
					<th>Subtotal</th>
					<th>Tax</th>
					<th>Total</th>
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					{settingsEnable && <th />}
				</tr>
			</thead>
		</>
	);
};

ExtraFeeHead.propTypes = {
	settingsEnable: PropTypes.bool,
	displayQuantity: PropTypes.bool,
};

ExtraFeeHead.defaultProps = {
	settingsEnable: false,
	displayQuantity: true,
};

export const ExtraFeeRow = ({
	data,
	extraCharge,
	onUpdate,
	onFormChange,
	disabled,
	customer,
	onSettingsClick,
	isValid,
	displayQuantity,
	max,
}) => {
	const checkPrice = price => {
		if (
			!price ||
			Number.isNaN(parseFloat(price)) ||
			price > 0 ||
			extraCharge.bookingCalculation.value !== bookingCalculations.FIXED
		)
			return price;

		const newPrice = max !== null ? Math.min(max, parseFloat(price) * -1) * -1 : price;

		return Number.isNaN(parseFloat(newPrice)) ? price : newPrice;
	};

	const [price, setPrice] = useState(
		checkPrice(data.amount === undefined ? extraCharge.price : data.amount)
	);

	const [quantity, setQuantity] = useState(data.quantity || 1);

	const [isChecked, setIsChecked] = useState(data.isChecked || data.id > 0);

	const initPrice = useRef(false);

	const ref = useRef();

	const getQuantity = (_quantity = null) => parseFloat(_quantity === null ? quantity : _quantity);

	const onChange = (_isChecked, _price, _quantity) =>
		onUpdate({
			id: data.id,
			isChecked: _isChecked,
			extraCharge,
			amount: _price && !Number.isNaN(parseFloat(_price)) ? parseFloat(_price) : 0,
			quantity: _quantity,
			taxCode: getItemTaxCode(customer ? customer.taxCode : null, extraCharge),
			multiSelectQuantity: extraCharge.hasMultiSelectQuantity ? _quantity : undefined,
		});

	useEffect(() => {
		if (data.id > 0 && !initPrice.current) {
			setPrice(data.amount);
			initPrice.current = true;
		}

		if (data.quantity && data.quantity !== quantity) setQuantity(data.quantity);

		if (data.isChecked !== undefined && data.isChecked !== isChecked)
			setIsChecked(data.isChecked);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<tr ref={ref}>
			<td>
				<Toggle
					color='success'
					size='sm'
					outline
					onChange={() => {
						onFormChange();
						setIsChecked(!isChecked);
						if (!isChecked && max !== null) setPrice(checkPrice(price));
						onChange(!isChecked, checkPrice(price), getQuantity());
					}}
					value={isChecked}
					disabled={disabled}
				/>
			</td>
			<td>{extraCharge.name}</td>
			<td className={classNames({ 'sdms-disable': !isChecked })}>
				<Input
					type='text'
					value={price}
					onChange={e => {
						const _price = checkPrice(e.target.value);
						setPrice(_price);
						onChange(isChecked, _price, getQuantity());
						onFormChange();
					}}
					pattern={process.env.REACT_APP_NEGATIVE_PRICE_PATTERN}
					disabled={
						(extraCharge['@type'] !== 'SdmsProductPos' &&
							extraCharge.bookingCalculation.value === bookingCalculations.PERCENT) ||
						!isChecked ||
						disabled
					}
				/>
			</td>
			{displayQuantity && (
				<td className={classNames({ 'sdms-disable': !isChecked })}>
					<Input
						type='text'
						value={quantity}
						onChange={e => {
							setQuantity(e.target.value);
							onChange(isChecked, price, getQuantity(e.target.value));
							onFormChange();
						}}
						disabled={
							(extraCharge['@type'] !== 'SdmsProductPos' &&
								extraCharge.bookingCalculation.value !==
									bookingCalculations.PER_QUANTITY &&
								extraCharge.bookingCalculation.value !==
									bookingCalculations.PER_QUANTITY_PER_PERIOD) ||
							!isChecked ||
							disabled
						}
						pattern={process.env.REACT_APP_PRICE_PATTERN}
					/>
				</td>
			)}
			<td className={classNames({ 'sdms-disable': !isChecked })}>
				{getBookingCalculationText(
					extraCharge.bookingPeriod,
					extraCharge.bookingCalculation
				)}
			</td>
			<td className={classNames({ 'sdms-disable': !isChecked })}>
				{priceFormatter(data.total ? data.total - data.tax : 0)}
			</td>
			<td className={classNames({ 'sdms-disable': !isChecked })}>
				{priceFormatter(data.tax || 0)}
			</td>
			<td className={classNames({ 'sdms-disable': !isChecked })}>
				{priceFormatter(data.total || 0)}
			</td>
			{onSettingsClick && (
				<td className={classNames({ 'sdms-disable': !isChecked })}>
					<Button
						btnIcon
						icon='Settings#1'
						size='sm'
						elevate
						key='settings'
						onClick={onSettingsClick}
						disabled={disabled}
					/>
					{!isValid && <SVGIcon name='Info-circle' fill='var(--danger)' />}
				</td>
			)}
		</tr>
	);
};
ExtraFeeRow.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		amount: PropTypes.number,
		subtotal: PropTypes.number,
		tax: PropTypes.number,
		total: PropTypes.number,
		isChecked: PropTypes.bool,
	}),
	extraCharge: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		price: PropTypes.number,
		additionalTaxes: PropTypes.arrayOf(PropTypes.object),
		bookingCalculation: PropTypes.object,
		extraChargeType: PropTypes.object,
		externalDescription: PropTypes.string,
		bookingPeriod: PropTypes.object,
		'@type': PropTypes.string,
		hasMultiSelectQuantity: PropTypes.bool,
	}),
	onUpdate: PropTypes.func,
	onFormChange: PropTypes.func,
	disabled: PropTypes.bool,
	onSettingsClick: PropTypes.func,
	isValid: PropTypes.bool,
	displayQuantity: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	max: PropTypes.number,
};
ExtraFeeRow.defaultProps = {
	data: { id: 0 },
	extraCharge: { id: 0 },
	onUpdate: () => {},
	onFormChange: () => {},
	disabled: false,
	onSettingsClick: null,
	isValid: true,
	displayQuantity: true,
	customer: null,
	max: null,
};
