import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import IconBox from '../../reusables/layout/IconBox';
import { invoiceStatuses } from '../../../utils/constants/constants';
import { priceFormatter } from '../../../utils/helpers/helper';

const Dashboard = ({ data, steps, setActiveStep, isActive }) => {
	if (!isActive) return null;

	return (
		<div className='row sdms-iconbox--container'>
			<div className='col-12 col-lg-6 col-xl-4'>
				<IconBox
					animate={null}
					icon='Clipboard-list'
					title='Open Invoices'
					onClick={() =>
						setActiveStep({
							index: steps.INVOICES.index,
							status: {
								value: 'open',
								id: 1,
							},
						})
					}>
					<div className='text-center'>
						<h2>
							{
								data.invoices.filter(i => i.status.value === invoiceStatuses.OPEN)
									.length
							}
						</h2>
					</div>
				</IconBox>
			</div>
			<div className='col-12 col-lg-6 col-xl-4'>
				<IconBox
					animate={null}
					icon='Clipboard-list'
					title='Overdue Invoices'
					onClick={() =>
						setActiveStep({
							index: steps.INVOICES.index,
							status: {
								value: 'overdue',
								id: 'overdue',
							},
						})
					}>
					<div className='sdms-overdue-text text-center'>
						<h2>
							{
								data.invoices.filter(i => {
									return (
										moment(i.dueDate).isBefore() &&
										i.status.value === invoiceStatuses.OPEN
									);
								}).length
							}
						</h2>
					</div>
				</IconBox>
			</div>
			<div className='col-12 col-lg-6 col-xl-4'>
				<IconBox
					icon='Wallet'
					title='Balance Due'
					onClick={() =>
						setActiveStep({
							index: steps.PAYMENTS.index,
							amount: data.customer.customerBalance,
						})
					}
					animate={null}
					noPermission={data.customer.customerBalance <= 0}>
					<div className='text-center'>
						<h2>{priceFormatter(parseFloat(data.customer.customerBalance))}</h2>
					</div>
				</IconBox>
			</div>
			<div className='col-12 col-lg-6 col-xl-4'>
				<IconBox
					icon={steps.MARINA.icon}
					title={`${steps.MARINA.title} spaces`}
					onClick={() => setActiveStep({ index: steps.MARINA.index })}
					animate={null}>
					<div className='text-center'>
						<h2>{data.marinaSpaces}</h2>
					</div>
				</IconBox>
			</div>
			<div className='col-12 col-lg-6 col-xl-4'>
				<IconBox
					icon={steps.CAMPGROUND.icon}
					title={`${steps.CAMPGROUND.title} spaces`}
					onClick={() => setActiveStep({ index: steps.CAMPGROUND.index })}
					animate={null}>
					<div className='text-center'>
						<h2>{data.campgroundSpaces}</h2>
					</div>
				</IconBox>
			</div>
			<div className='col-12 col-lg-6 col-xl-4'>
				<IconBox
					icon='Building'
					title='Upcoming Bookings'
					onClick={() => setActiveStep({ index: steps.BOOKING.index })}
					animate={null}>
					<div className='text-center'>
						<h2>{data.upComingBookings}</h2>
					</div>
				</IconBox>
			</div>
			<div className='col-12 col-lg-6 col-xl-4'>
				<IconBox
					icon='User'
					title='Edit Profile'
					onClick={() => setActiveStep({ index: steps.MY_ACCOUNT.index })}
					animate={null}
				/>
			</div>
		</div>
	);
};

Dashboard.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	steps: PropTypes.object.isRequired,
	setActiveStep: PropTypes.func.isRequired,
	isActive: PropTypes.bool.isRequired,
};

export default Dashboard;
