import PropTypes from 'prop-types';
import React from 'react';

import useNumPad, { actions as numPadActions } from '../../../../utils/hooks/useNumPad';
import Button from '../../element/Button';
import { getTenderedValues, numberFormat, priceFormatter } from '../../../../utils/helpers/helper';

const NumPad = ({
	value,
	setValue,
	initValue,
	max,
	disable,
	disableTenderedButtons,
	disableExact,
	onCloseNumpad,
	disableCloseNumpad,
}) => {
	const [applyInput] = useNumPad(value, setValue, initValue, max, 0, 2, null, null, disable);

	return (
		<div className='row'>
			<div className='col-md-9 col-12 sdms-mb-15-mobile'>
				<div className='row sdms-mb-10'>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							text='1'
							bold='bold'
							outline
							block
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '1');
							}}
							disabled={disable}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							text='2'
							bold='bold'
							outline
							block
							disabled={disable}
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '2');
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							text='3'
							bold='bold'
							outline
							block
							disabled={disable}
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '3');
							}}
						/>
					</div>
				</div>
				<div className='row sdms-mb-10'>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='4'
							disabled={disable}
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '4');
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='5'
							disabled={disable}
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '5');
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='6'
							disabled={disable}
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '6');
							}}
						/>
					</div>
				</div>
				<div className='row sdms-mb-10'>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='7'
							disabled={disable}
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '7');
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							sdms-portlet__body
							sdms-discount__wrapper
							design='info'
							bold='bold'
							outline
							block
							text='8'
							disabled={disable}
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '8');
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='9'
							disabled={disable}
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '9');
							}}
						/>
					</div>
				</div>
				<div className='row'>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='.'
							disabled={disable}
							onClick={() => {
								applyInput(numPadActions.DECIMAL);
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='0'
							disabled={disable}
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '0');
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='btn-only-icon sdms-pt0 sdms-pb-0 h-100'
							label='danger'
							block
							icon='Backspace'
							iconSize={38}
							disabled={disable}
							onClick={() => {
								applyInput(numPadActions.SLICE);
							}}
						/>
					</div>
				</div>
			</div>
			<div className='col-md-3 col-12'>
				<div className='row h-100 align-content-end'>
					{!disableCloseNumpad && (
						<div className='col-12 d-flex'>
							<Button
								className='sdms-mb-10'
								label='info'
								outline
								block
								icon='Done-circle'
								onClick={() => {
									onCloseNumpad();
								}}
								disabled={disableCloseNumpad}
							/>
						</div>
					)}

					{!disableTenderedButtons &&
						getTenderedValues(initValue).map(v => (
							<div className='col-12' key={v}>
								<Button
									className='sdms-mb-10 sdms-font-lg'
									label='success'
									bold='bold'
									outline
									block
									text={priceFormatter(v)}
									onClick={() => setValue(v)} // assuming presets are already string
									disabled={disable}
								/>
							</div>
						))}

					<div className='col-12 d-flex'>
						<Button
							className='sdms-font-lg'
							label='success'
							bold='boldest'
							outline
							block
							text='EXACT'
							disabled={disable || disableExact}
							onClick={() => setValue(numberFormat(initValue).toString())} // assuming presets are already string
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
NumPad.propTypes = {
	value: PropTypes.string.isRequired,
	setValue: PropTypes.func.isRequired,
	initValue: PropTypes.number.isRequired,
	// eslint-disable-next-line react/require-default-props
	max: PropTypes.number,
	disableTenderedButtons: PropTypes.bool,
	disable: PropTypes.bool,
	disableExact: PropTypes.bool,
	onCloseNumpad: PropTypes.func,
	disableCloseNumpad: PropTypes.bool,
};
NumPad.defaultProps = {
	disable: false,
	disableTenderedButtons: true,
	disableExact: false,
	onCloseNumpad: () => {},
	disableCloseNumpad: false,
};

export default NumPad;
