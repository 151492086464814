import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';

import useField from '../../../../../../utils/hooks/useField';
import { required } from '../../../../../../utils/helpers/validation';
import { searchResultViews } from '../../constants';
import { onlineDateFormatter } from '../../../../../../utils/helpers/basicReservationHelper';

import Portlet from '../../../../layout/Portlet';
import FormGroup from '../../../../layout/FormGroup';
import FormField from '../../../../template/FormField';
import DatePicker from '../../../../field/DatePicker';
import Section from '../../../../layout/Section';
import Toggle from '../../../../field/Toggle';
import Button from '../../../Button';
import SearchFilters from './SearchFilters';

const SearchBar = ({
	isOnline,
	refSearchBar,
	onFormChange,
	bookingTypes,
	isLoading,
	overrideConstraints,
	onSearch,
	setIsHourlyOnly,
	updateSearchData,
	searchData,
	resultView,
	setResultView,
	initProducts,
	onReset,
	onSearchChange,
}) => {
	const init = useRef(false);

	const [bookingType, bookingTypeOnChange, bookingTypeValRes] = useField(
		{},
		'bookingType',
		onFormChange,
		[required],
		searchData.bookingType
	);

	const [
		fromDate,
		fromDateOnChange,
		fromDateValRes,
		fromDateShowVal,
		setFromDateShowVal,
	] = useField({}, 'fromDate', onFormChange, [required], searchData.fromDate || null);

	const [toDate, toDateOnChange] = useField(
		{},
		'toDate',
		onFormChange,
		[],
		searchData.toDate || null
	);

	const [minDateFrom, setMinDateFrom] = useState(new Date());

	const [ignoreRules, setIgnoreRules] = useState(searchData.ignoreRules);

	const [isFiltersValid, setIsFiltersValid] = useState(false);

	const [canSearch, setCanSearch] = useState(false);

	const doNotUpdateSearchData = useRef(false);

	const onBookingTypeChange = _bookingType => {
		if (!_bookingType) return;

		const { daily, nightly } = _bookingType;

		const tomorrow = moment().add(1, 'day');

		const _fromDate = tomorrow.toDate();

		const _toDate = (nightly ? tomorrow.add(2, 'day') : tomorrow).toDate();

		fromDateOnChange({
			target: { value: _fromDate },
		});

		toDateOnChange({
			target: { value: _toDate },
		});

		setIsHourlyOnly(!daily && !nightly);

		bookingTypeOnChange({ target: { value: _bookingType } });

		if (_bookingType.onlineBookingTemplate) {
			onReset();

			return;
		}

		onSearch({ bookingType: _bookingType, fromDate: _fromDate, toDate: _toDate });
	};

	const getTabContent = _bookingType => {
		const isHourlyOnly = !_bookingType.daily && !_bookingType.nightly;

		let dateValue = fromDate;

		if (!isHourlyOnly) dateValue = fromDate ? { startDate: fromDate, endDate: toDate } : null;

		return (
			<Portlet.TabItem isDisabled={isLoading} title={_bookingType.name} key={_bookingType.id}>
				<form className='sdms-form sdms-online-booking-search-bar'>
					<FormGroup row>
						<FormField
							isLast
							name='fromDate'
							label={isHourlyOnly ? 'Date' : 'Dates'}
							id={0}
							valRes={fromDateValRes}
							showValidation={fromDateShowVal}
							col={12}
							colMd={isOnline ? 4 : 6}
							colLg={3}
							colXl={isOnline ? null : 2}
							inFormDesign={false}
							className='sdms-pt15-mobile'>
							<DatePicker
								id='fromDate'
								placeholder={isHourlyOnly ? 'Date' : 'Date Range'}
								type={isHourlyOnly ? 'calendar' : 'dateRange'}
								value={dateValue}
								onChange={val => {
									if (isHourlyOnly) {
										fromDateOnChange(val);
										toDateOnChange(val);
									} else {
										fromDateOnChange({
											target: {
												value: val.startDate,
											},
										});
										toDateOnChange({
											target: {
												value: val.endDate,
											},
										});
									}
									onSearchChange();
								}}
								onBlur={setFromDateShowVal}
								minDate={minDateFrom}
								disabled={!bookingTypeValRes.isValid || isLoading}
								forceNextDay={
									bookingType && !bookingType.daily && !bookingType.hourly
								}
								formatter={onlineDateFormatter}
							/>
						</FormField>
						{bookingType && (
							<SearchFilters
								key={bookingType ? bookingType.id : 0}
								isLoading={isLoading}
								isOnline={isOnline}
								bookingType={bookingType}
								onChange={filterData => {
									updateSearchData({ ...searchData, ...filterData });
									onSearchChange();
								}}
								setIsValid={setIsFiltersValid}
								searchData={searchData}
							/>
						)}
						<div
							className={classNames(
								'col-lg-auto',
								'col',
								'ml-auto',
								'd-flex',
								'align-items-end',
								{
									'sdms-pt15-tablet-and-mobile': overrideConstraints,
									'sdms-pt15-mobile': !overrideConstraints,
								}
							)}>
							<Section className='w-100'>
								<Section.Body className='row'>
									{overrideConstraints && (
										<FormField
											className='sdms-ch-64'
											isLast
											name='ignoreRules'
											label='No Rules'
											id={0}
											inFormDesign={false}
											col>
											<Toggle
												value={ignoreRules}
												onChange={event => {
													setIgnoreRules(event.target.value);
													if (event.target.value)
														setMinDateFrom(undefined);
													else {
														setMinDateFrom(new Date());
														if (fromDate < new Date()) {
															fromDateOnChange({
																target: {
																	value: undefined,
																},
															});
															toDateOnChange({
																target: {
																	value: undefined,
																},
															});
														}
													}
												}}
												disabled={isLoading}
											/>
										</FormField>
									)}
									<div
										className={classNames('d-flex', 'align-items-end', {
											'col-auto': overrideConstraints,
											col: !overrideConstraints,
										})}>
										<Button
											className={classNames({
												'sdms-fading-dots': isLoading,
											})}
											design='info'
											text={isLoading ? 'Searching' : 'Search'}
											icon='Search'
											block
											size='sm'
											wide='widest'
											elevate
											disabled={!canSearch}
											onClick={() => onSearch()}
											isSubmitting={isLoading}
										/>
									</div>
								</Section.Body>
							</Section>
						</div>
					</FormGroup>
				</form>
			</Portlet.TabItem>
		);
	};

	// Effect to enable/disable the search button
	useEffect(() => {
		setCanSearch(bookingTypeValRes.isValid && fromDateValRes.isValid && isFiltersValid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bookingTypeValRes.isValid, fromDateValRes.isValid, isFiltersValid]);

	useEffect(() => {
		if (bookingTypes.length && !searchData.bookingType) onBookingTypeChange(bookingTypes[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bookingTypes]);

	useEffect(() => {
		if (!doNotUpdateSearchData.current)
			updateSearchData({
				...searchData,
				bookingType,
				fromDate,
				toDate,
				ignoreRules,
			});

		doNotUpdateSearchData.current = false;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bookingType, fromDate, toDate, ignoreRules]);

	useEffect(() => {
		if (searchData.alternateDateSearch && searchData.fromDate && searchData.toDate) {
			fromDateOnChange({ target: { value: searchData.fromDate } });
			toDateOnChange({ target: { value: searchData.toDate } });
			doNotUpdateSearchData.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchData.alternateDateSearch]);

	useEffect(() => {
		if (bookingType && (init.current || initProducts.length === 0)) {
			const { defaultSearchResultView } = bookingType;

			let view = defaultSearchResultView?.value || resultView;

			if (view === searchResultViews.MAP && !bookingType.unitMap)
				view = searchResultViews.LIST;

			setResultView(view);
		}

		init.current = true;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bookingType]);

	return (
		<Portlet
			ref={refSearchBar}
			className='flex-grow-0 sdms-portlet--tabs sdms-online-booking-search-bar'>
			<Portlet.Tabs
				design='info'
				activeTab={
					searchData.bookingType && bookingTypes.length > 0
						? bookingTypes.findIndex(abt => abt.id === searchData.bookingType.id)
						: 0
				}
				onTabChange={index => onBookingTypeChange(bookingTypes[index])}>
				{bookingTypes.length > 0
					? bookingTypes.map(getTabContent)
					: getTabContent({ id: 0, name: 'Loading' })}
			</Portlet.Tabs>
		</Portlet>
	);
};

SearchBar.propTypes = {
	isOnline: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	refSearchBar: PropTypes.object,
	onFormChange: PropTypes.func.isRequired,
	bookingTypes: PropTypes.arrayOf(PropTypes.object),
	isLoading: PropTypes.bool.isRequired,
	overrideConstraints: PropTypes.bool.isRequired,
	onSearch: PropTypes.func.isRequired, // eslint-disable-next-line react/forbid-prop-types
	setIsHourlyOnly: PropTypes.func.isRequired,
	updateSearchData: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	searchData: PropTypes.object.isRequired,
	resultView: PropTypes.string.isRequired,
	setResultView: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initProducts: PropTypes.array,
	onReset: PropTypes.func.isRequired,
	onSearchChange: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
	refSearchBar: null,
	bookingTypes: [],
	initProducts: [],
};

export default SearchBar;
