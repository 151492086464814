import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import useDate from '../../../../../utils/hooks/useDate';
import { paymentStatuses, paymentTypes } from '../../../../../utils/constants/constants';
import { PaymentStatusCell } from '../../../element/PaymentListCells';
import { customerPaymentMethodIcon, priceFormatter } from '../../../../../utils/helpers/helper';

import Badge from '../../../element/Badge';
import Dropdown from '../../../element/Dropdown';

const PaymentItem = ({
	payment,
	isLoading,
	onPrint,
	onEmail,
	onRefund,
	onScheduledPaymentCapture,
	onVoid,
	onAuthorizedPaymentEdit,
	onAuthorizedPaymentCapture,
	onAuthorizedPaymentVoid,
}) => {
	const [dateFormatter] = useDate();

	const date = useMemo(() => {
		if (payment.remittanceDate) return payment.remittanceDate;

		if (payment.refundDate) return payment.refundDate;

		return payment.timeCreated;
	}, [payment]);

	const serviceFee = useMemo(() => {
		if (
			!payment.originalAmount ||
			payment.amount === payment.originalAmount - payment.serviceFee
		)
			return payment.serviceFee;

		return (
			payment.amount * (payment.serviceFee / (payment.originalAmount - payment.serviceFee))
		);
	}, [payment]);

	return (
		<>
			<tr className='sdms-t-orders--payment'>
				<td colSpan={2} className='sdms-font-monospace'>
					{customerPaymentMethodIcon(payment)} {` - ${dateFormatter(date)}`}
					{!payment.isAuthorizedPayment &&
						payment?.status?.value === paymentStatuses.PROCESSING &&
						' - Processing'}
					{payment?.status?.value === paymentStatuses.SCHEDULED && (
						<>
							{' '}
							<PaymentStatusCell data={payment} />
						</>
					)}
					{payment.isAuthorizedPayment && (
						<>
							{' '}
							<Badge
								design='info'
								isInline
								isUnified
								fontWeight='bold'
								size='lg'
								className='sdms-text-overflow'>
								<span className='sdms-text-overflow sdms-font-transform-c'>
									Authorized
								</span>
							</Badge>
						</>
					)}
				</td>
				<td className='sdms-t-orders--item__price sdms-font-monospace'>
					{priceFormatter(payment.amount)}
				</td>
				<td className='sdms-t-orders--item__actions'>
					<Dropdown
						icon='Other#1'
						size='sm'
						color={null}
						inline
						aligned='right'
						arrow={false}
						outline={false}
						disabled={isLoading || payment.paymentMethod.name === paymentTypes.CREDIT}>
						<Dropdown.Header>Payment Item Actions</Dropdown.Header>
						{onPrint && (
							<Dropdown.Item icon='Printer' onClick={onPrint}>
								Print
							</Dropdown.Item>
						)}
						{onEmail && (
							<Dropdown.Item icon='Mail-box' onClick={onEmail}>
								Email Receipt
							</Dropdown.Item>
						)}
						{onRefund && (
							<Dropdown.Item icon='Undo' onClick={onRefund}>
								Refund
							</Dropdown.Item>
						)}
						{onScheduledPaymentCapture && (
							<Dropdown.Item icon='Undo' onClick={onScheduledPaymentCapture}>
								Capture Now
							</Dropdown.Item>
						)}
						{onVoid && (
							<Dropdown.Item icon='Subtract' onClick={onVoid}>
								Void
							</Dropdown.Item>
						)}
						{onAuthorizedPaymentEdit && (
							<Dropdown.Item icon='Edit' onClick={onAuthorizedPaymentEdit}>
								Edit
							</Dropdown.Item>
						)}
						{onAuthorizedPaymentCapture && (
							<Dropdown.Item icon='Undo' onClick={onAuthorizedPaymentCapture}>
								Capture
							</Dropdown.Item>
						)}
						{onAuthorizedPaymentVoid && (
							<Dropdown.Item icon='Subtract' onClick={onAuthorizedPaymentVoid}>
								Release
							</Dropdown.Item>
						)}
					</Dropdown>
				</td>
			</tr>
			{payment.paymentMethod.paymentType.value === paymentTypes.HOUSE_ACCOUNT && (
				<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
					<td />
					<td colSpan={99}>Customer: {payment.customer.displayName}</td>
				</tr>
			)}
			{payment.paymentMethod.paymentType.value === paymentTypes.BOOKING && (
				<>
					<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
						<td />
						<td colSpan={99}>Customer: {payment.customer.displayName}</td>
					</tr>
					<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
						<td />
						<td colSpan={99}>
							Booking:{' '}
							{`${payment.reservationItem.id} - ${payment.reservationItem.product.name} (${payment.reservationItem.unit.name})`}
						</td>
					</tr>
				</>
			)}
			{payment.paymentMethod.paymentType.value === paymentTypes.CHECK &&
				(payment.number || payment.bankName) && (
					<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
						{payment.bankName ? (
							<td colSpan={2} className='sdms-text-overflow'>
								ACH:
								{`${payment.bankName} - ${payment.accountName}${
									payment.accountHolderName
										? ` - ${payment.accountHolderName}`
										: ''
								}`}
							</td>
						) : (
							<td colSpan={2} className='sdms-text-overflow'>
								Number:
								{payment.number}
							</td>
						)}
						{payment.serviceFee ? (
							<td colSpan={1} className='sdms-t-orders--item__price'>
								Fee: {priceFormatter(serviceFee)}
							</td>
						) : (
							''
						)}
					</tr>
				)}
			{payment.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD &&
				payment.cardNumber && (
					<tr className='sdms-t-orders--payment__info sdms-font-md sdms-font-monospace'>
						<td colSpan={2} className='sdms-text-overflow'>
							Number:
							{payment.cardNumber}
						</td>
						{payment.serviceFee ? (
							<td colSpan={1} className='sdms-t-orders--item__price'>
								Fee: {priceFormatter(serviceFee)}
							</td>
						) : (
							''
						)}
					</tr>
				)}
		</>
	);
};

PaymentItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	payment: PropTypes.object,
	isLoading: PropTypes.bool,
	onPrint: PropTypes.func,
	onEmail: PropTypes.func,
	onRefund: PropTypes.func,
	onScheduledPaymentCapture: PropTypes.func,
	onVoid: PropTypes.func,
	onAuthorizedPaymentEdit: PropTypes.func,
	onAuthorizedPaymentCapture: PropTypes.func,
	onAuthorizedPaymentVoid: PropTypes.func,
};

PaymentItem.defaultProps = {
	payment: null,
	isLoading: false,
	onPrint: null,
	onEmail: null,
	onRefund: null,
	onScheduledPaymentCapture: null,
	onVoid: null,
	onAuthorizedPaymentEdit: null,
	onAuthorizedPaymentCapture: null,
	onAuthorizedPaymentVoid: null,
};

export default PaymentItem;
