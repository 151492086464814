import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../../../app/contexts/UserContext';
import useField from '../../../../../utils/hooks/useField';
import {
	dateFormatterWithoutYear,
	filterInvoicingFrequency,
	getNextInvoice,
	getNextInvoiceOptions,
	isInvoicingFrequencyBigger,
	parseDatePickerChange,
	parseDatePickerValue,
} from '../../../../../utils/helpers/helper';
import {
	bookingPeriods,
	firstInvoiceOptions,
	invoicingFrequencies as _invoicingFrequencies,
	nextInvoiceOptions,
	reservationStatuses,
} from '../../../../../utils/constants/constants';
import {
	getNextInvoiceDate,
	getNextInvoiceDateMin,
} from '../../../../../utils/helpers/reservationHelper';
import { required } from '../../../../../utils/helpers/validation';

import FormSection from '../../../layout/FormSection';
import FormField from '../../../template/FormField';
import Radio from '../../../field/Radio';
import Selects from '../../../field/Selects';
import Toggle from '../../../field/Toggle';
import DatePicker from '../../../field/DatePicker';

const Accounting = ({
	reservation,
	reservationItem,
	onFormChange,
	disabled,
	sectionRef,
	isItemSubmitted,
	setSectionIsValid,
	onInvoicingChange,
	invoicingFrequencies,
	invoiceNextOptions,
	paymentTerms,
	invoiceFirstOptions,
	accounts,
}) => {
	const bookingPeriod = useMemo(() => reservationItem?.product?.bookingPeriod?.value, [
		reservationItem,
	]);

	const userContext = useContext(UserContext);

	const invoicingFrequencyRequired = (value, setValRes) => {
		if (bookingPeriod === bookingPeriods.SEASONAL || bookingPeriod === bookingPeriods.LONG_TERM)
			return required(value, setValRes);

		return true;
	};

	const firstInvoiceRequired = (value, setValRes) => {
		if (invoicingFrequency && invoicingFrequency.value !== _invoicingFrequencies.UPFRONT)
			return required(value, setValRes);

		return true;
	};

	const nextInvoiceRequired = (value, setValRes) => {
		if (invoicingFrequency && firstInvoice) return required(value, setValRes);

		return true;
	};

	const proratedFrequencyRequired = (value, setValRes) => {
		if (firstInvoice && firstInvoice.value === firstInvoiceOptions.PRO_RATE)
			return required(value, setValRes);
		return true;
	};

	const coTermRequired = (value, setValRes) => {
		if (nextInvoice && nextInvoice.value === nextInvoiceOptions.CO_TERM)
			return required(value, setValRes);
		return true;
	};

	// conditionally required for deferred fields.
	const deferredRequired = (value, setValRes) => {
		if (enableDeferredIncome) return required(value, setValRes);
		return true;
	};

	const [invoiceGenerationAutomatedBatch, invoiceGenerationAutomatedBatchOnChange] = useField(
		reservationItem,
		'invoiceGenerationAutomatedBatch',
		onFormChange,
		[],
		true
	);

	const [
		invoicingFrequency,
		invoicingFrequencyOnChange,
		invoicingFrequencyValRes,
		invoicingFrequencyShowVal,
		setInvoicingFrequencyShowVal,
	] = useField(
		reservationItem,
		'invoicingFrequency',
		onFormChange,
		[invoicingFrequencyRequired],
		null
	);

	const [paymentTerm, paymentTermOnChange] = useField(
		reservationItem,
		'paymentTerm',
		onFormChange,
		[],
		null
	);

	const [
		firstInvoice,
		firstInvoiceOnChange,
		firstInvoiceValRes,
		firstInvoiceShowVal,
		setFirstInvoiceShowVal,
		validateFirstInvoice,
	] = useField(reservationItem, 'firstInvoice', onFormChange, [firstInvoiceRequired], null);

	const [
		nextInvoice,
		nextInvoiceOnChange,
		nextInvoiceValRes,
		nextInvoiceShowVal,
		setNextInvoiceShowVal,
		validateNextInvoice,
	] = useField(reservationItem, 'nextInvoice', onFormChange, [nextInvoiceRequired], null);

	const [
		proratedFrequency,
		proratedFrequencyOnChange,
		proratedFrequencyValRes,
		proratedFrequencyShowVal,
		setProratedFrequencyShowVal,
		validateProratedFrequency,
	] = useField(
		reservationItem,
		'proratedFrequency',
		onFormChange,
		[proratedFrequencyRequired],
		null
	);

	const [
		coTermDate,
		coTermDateOnChange,
		coTermDateValRes,
		coTermDateShowVal,
		setCoTermDateShowVal,
		validateCoTermDate,
	] = useField(reservationItem, 'coTermDate', onFormChange, [coTermRequired], null);

	const [autoPay, autoPayOnChange] = useField(reservation, 'autoPay', onFormChange, [], false);

	const [prevInvoiceDate, prevInvoiceDateOnChange] = useField(
		reservationItem,
		'prevInvoiceDate',
		onFormChange,
		[],
		null
	);

	const [nextInvoiceDate, nextInvoiceDateOnChange] = useField(
		reservationItem,
		'nextInvoiceDate',
		onFormChange,
		[],
		null
	);

	const [enableDeferredIncome, enableDeferredIncomeOnChange] = useField(
		reservationItem,
		'enableDeferredIncome',
		onFormChange,
		[],
		false
	);

	const [
		deferredIncomeFrequency,
		deferredIncomeFrequencyOnChange,
		deferredIncomeFrequencyValRes,
		deferredIncomeFrequencyShowVal,
		setDeferredIncomeFrequencyShowVal,
		validateDeferredIncomeFrequency,
	] = useField(
		reservationItem,
		'deferredIncomeFrequency',
		onFormChange,
		[deferredRequired],
		null
	);

	const [
		deferredIncomeSalesAccount,
		deferredIncomeSalesAccountOnChange,
		deferredIncomeSalesAccountValRes,
		deferredIncomeSalesAccountShowVal,
		setDeferredIncomeSalesAccountShowVal,
		validateDeferredIncomeSalesAccount,
	] = useField(
		reservationItem,
		'deferredIncomeSalesAccount',
		onFormChange,
		[deferredRequired],
		null
	);

	useEffect(() => {
		if (isItemSubmitted) {
			setInvoicingFrequencyShowVal();
			setFirstInvoiceShowVal();
			setNextInvoiceShowVal();
			setProratedFrequencyShowVal();
			setCoTermDateShowVal();
			setDeferredIncomeFrequencyShowVal();
			setDeferredIncomeSalesAccountShowVal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isItemSubmitted]);

	useEffect(() => {
		setSectionIsValid(
			invoicingFrequencyValRes.isValid &&
				firstInvoiceValRes.isValid &&
				nextInvoiceValRes.isValid &&
				proratedFrequencyValRes.isValid &&
				coTermDateValRes.isValid &&
				deferredIncomeFrequencyValRes.isValid &&
				deferredIncomeSalesAccountValRes.isValid
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		invoicingFrequencyValRes.isValid,
		firstInvoiceValRes.isValid,
		nextInvoiceValRes.isValid,
		proratedFrequencyValRes.isValid,
		coTermDateValRes.isValid,
		deferredIncomeFrequencyValRes.isValid,
		deferredIncomeSalesAccountValRes.isValid,
	]);

	useEffect(() => {
		// re-validate deferred field on enableDeferredIncome change.
		validateDeferredIncomeFrequency(deferredIncomeFrequency);
		validateDeferredIncomeSalesAccount(deferredIncomeSalesAccount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enableDeferredIncome]);

	useEffect(() => {
		validateFirstInvoice(firstInvoice);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoicingFrequency]);

	useEffect(() => {
		validateProratedFrequency(proratedFrequency);
		validateNextInvoice(nextInvoice);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstInvoice]);

	useEffect(() => {
		validateCoTermDate(coTermDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextInvoice]);

	useEffect(() => {
		// re-validate deferred field on enableDeferredIncome change.
		validateDeferredIncomeFrequency(deferredIncomeFrequency);
		validateDeferredIncomeSalesAccount(deferredIncomeSalesAccount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enableDeferredIncome]);

	// when reservation item next invoice date change update same as reservation fee's next invoice date.
	useEffect(() => {
		if (
			reservationItem.extraCharges.length &&
			reservationItem.extraCharges.some(
				sec =>
					sec.invoicingFrequency &&
					sec.invoicingFrequency.value === _invoicingFrequencies.SAME_AS_RESERVATION
			)
		) {
			onInvoicingChange(
				reservationItem.extraCharges.map(sec => {
					if (
						sec.invoicingFrequency &&
						sec.invoicingFrequency.value === _invoicingFrequencies.SAME_AS_RESERVATION
					) {
						return {
							...sec,
							nextInvoiceDate,
							firstInvoice,
							nextInvoice,
							proratedFrequency,
							coTermDate,
							paymentTerm,
						};
					}

					return sec;
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextInvoiceDate, firstInvoice, nextInvoice, proratedFrequency, coTermDate, paymentTerm]);

	useEffect(() => {
		if (
			reservationItem.id === 0 &&
			reservationItem.status.value !== reservationStatuses.WAITLIST
		) {
			nextInvoiceDateOnChange({
				target: {
					value: getNextInvoiceDate(
						reservationItem.fromDate,
						firstInvoice,
						nextInvoice,
						coTermDate
					),
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservationItem.fromDate, firstInvoice, nextInvoice, coTermDate]);

	return (
		<FormSection sectionRef={sectionRef} title='ACCOUNTING' icon='Wallet' isAccordion>
			<div className='sdms-advanced-reservation-item-form-accounting'>
				{(bookingPeriod === bookingPeriods.SEASONAL ||
					bookingPeriod === bookingPeriods.LONG_TERM) && (
					<>
						<div className='row sdms-mb-10'>
							<h5>Invoice Settings</h5>
						</div>
						<div className='row sdms-mb-15'>
							<FormField
								name='invoiceGenerationAutomatedBatch'
								label='Invoice Generation'
								inFormDesign={false}
								colMd={3}>
								<Radio.Container isInline>
									<Radio
										checked={invoiceGenerationAutomatedBatch}
										id='invoiceGenerationAutomatedBatchYes'
										name='invoiceGenerationAutomatedBatch'
										content='Automatic'
										className='sdms-radio--primary'
										onChange={() =>
											invoiceGenerationAutomatedBatchOnChange({
												target: {
													value: true,
												},
											})
										}
										disabled={disabled}
									/>
									<Radio
										checked={!invoiceGenerationAutomatedBatch}
										id='invoiceGenerationAutomatedBatchNo'
										name='invoiceGenerationAutomatedBatch'
										content='Manual'
										className='sdms-radio--primary'
										onChange={() =>
											invoiceGenerationAutomatedBatchOnChange({
												target: {
													value: false,
												},
											})
										}
										disabled={disabled}
									/>
								</Radio.Container>
							</FormField>
							<FormField
								name='invoicingFrequency'
								label='Frequency'
								inFormDesign={false}
								colMd={3}
								showValidation={invoicingFrequencyShowVal}
								valRes={invoicingFrequencyValRes}>
								<Selects
									options={filterInvoicingFrequency(
										invoicingFrequencies,
										bookingPeriod,
										true,
										null,
										true
									)}
									placeholder='Frequency'
									value={invoicingFrequency}
									onChange={e => {
										invoicingFrequencyOnChange(e);

										nextInvoiceOnChange({
											target: {
												value: getNextInvoice(
													e.target.value,
													firstInvoice,
													invoiceNextOptions
												),
											},
										});

										if (
											bookingPeriod === bookingPeriods.SEASONAL &&
											(!e.target.value ||
												e.target.value.value ===
													_invoicingFrequencies.UPFRONT)
										) {
											firstInvoiceOnChange({
												target: {
													value: null,
												},
											});

											proratedFrequencyOnChange({
												target: {
													value: null,
												},
											});

											coTermDateOnChange({
												target: {
													value: null,
												},
											});

											autoPayOnChange({
												target: {
													value: false,
												},
											});
										}

										// check deferred income frequency against to invoicing frequency.
										if (
											e.target.value &&
											deferredIncomeFrequency &&
											isInvoicingFrequencyBigger(
												deferredIncomeFrequency.value,
												e.target.value.value
											)
										)
											deferredIncomeFrequencyOnChange({
												target: { value: null },
											});
									}}
									disabled={disabled}
									displayKey='value'
									onBlur={setInvoicingFrequencyShowVal}
								/>
							</FormField>
							<FormField
								name='paymentTerm'
								label='Payment Term'
								inFormDesign={false}
								colMd={3}>
								<Selects
									options={paymentTerms}
									placeholder='Payment Term'
									value={paymentTerm}
									onChange={paymentTermOnChange}
									disabled={disabled}
								/>
							</FormField>
							<FormField
								name='autoPay'
								label='Auto Pay'
								inFormDesign={false}
								colMd={3}>
								<Toggle
									value={autoPay}
									onChange={autoPayOnChange}
									disabled={
										disabled ||
										(bookingPeriod === bookingPeriods.SEASONAL &&
											invoicingFrequency &&
											invoicingFrequency.value ===
												_invoicingFrequencies.UPFRONT)
									}
								/>
							</FormField>
							<FormField
								name='firstInvoice'
								label='First Invoice'
								inFormDesign={false}
								colMd={3}
								showValidation={firstInvoiceShowVal}
								valRes={firstInvoiceValRes}>
								<Selects
									options={invoiceFirstOptions}
									placeholder={`First Invoice${
										firstInvoiceValRes.isValid ? '' : ' (Required)'
									} `}
									value={firstInvoice}
									onChange={e => {
										firstInvoiceOnChange(e);

										nextInvoiceOnChange({
											target: {
												value: getNextInvoice(
													invoicingFrequency,
													e.target.value,
													invoiceNextOptions
												),
											},
										});

										proratedFrequencyOnChange({
											target: { value: null },
										});

										coTermDateOnChange({
											target: { value: null },
										});
									}}
									displayKey='value'
									disabled={
										disabled ||
										(bookingPeriod === bookingPeriods.LONG_TERM
											? reservationItem.prevInvoiceDate
											: !invoicingFrequency ||
											  invoicingFrequency.value ===
													_invoicingFrequencies.UPFRONT ||
											  reservationItem.prevInvoiceDate)
									}
									onBlur={setFirstInvoiceShowVal}
									disableClearable
								/>
							</FormField>
							<FormField
								name='nextInvoice'
								label='Next Invoice'
								inFormDesign={false}
								colMd={3}
								showValidation={nextInvoiceShowVal}
								valRes={nextInvoiceValRes}>
								<Selects
									placeholder={`Next Invoice${
										nextInvoiceValRes.isValid ? '' : ' (Required)'
									} `}
									value={nextInvoice}
									options={getNextInvoiceOptions(
										bookingPeriod,
										invoicingFrequency,
										firstInvoice,
										invoiceNextOptions
									)}
									onChange={e => {
										nextInvoiceOnChange(e);

										if (
											!e.target.value ||
											e.target.value.value !== nextInvoiceOptions.CO_TERM
										) {
											coTermDateOnChange({
												target: { value: null },
											});
										}
									}}
									displayKey='value'
									disabled={
										disabled ||
										reservationItem.prevInvoiceDate ||
										(bookingPeriod === bookingPeriods.LONG_TERM &&
											!firstInvoice)
									}
									onBlur={setNextInvoiceShowVal}
								/>
							</FormField>
							<FormField
								name='proratedFrequency'
								label='Prorated Frequency'
								inFormDesign={false}
								colMd={3}
								showValidation={proratedFrequencyShowVal}
								valRes={proratedFrequencyValRes}>
								<Selects
									placeholder={`Prorated Frequency${
										proratedFrequencyValRes.isValid ? '' : ' (Required)'
									}`}
									value={proratedFrequency}
									options={filterInvoicingFrequency(
										invoicingFrequencies,
										bookingPeriods.LONG_TERM,
										false,
										invoicingFrequency ? invoicingFrequency.value : null
									)}
									onChange={proratedFrequencyOnChange}
									displayKey='value'
									disabled={
										disabled ||
										firstInvoice?.value !== firstInvoiceOptions.PRO_RATE ||
										reservationItem.prevInvoiceDate
									}
									onBlur={setProratedFrequencyShowVal}
								/>
							</FormField>
							<FormField
								name='coTermDate'
								label='Co-Term Date'
								inFormDesign={false}
								colMd={3}
								showValidation={coTermDateShowVal}
								valRes={coTermDateValRes}>
								<DatePicker
									id='coTermDate'
									type='calendar'
									placeholder={`Co-Term Date${
										coTermDateValRes.isValid ? '' : ' (Required)'
									}`}
									place='top'
									value={parseDatePickerValue(coTermDate)}
									onChange={e =>
										coTermDateOnChange({
											target: {
												value: parseDatePickerChange(
													e.target.value,
													coTermDate
												),
											},
										})
									}
									disabled={
										disabled ||
										nextInvoice?.value !== nextInvoiceOptions.CO_TERM ||
										reservationItem.prevInvoiceDate
									}
									formatter={dateFormatterWithoutYear}
									onBlur={setCoTermDateShowVal}
								/>
							</FormField>
							<FormField
								name='prevInvoiceDate'
								label='Prev Invoice Date'
								inFormDesign={false}
								colMd={3}>
								<DatePicker
									disabled={
										disabled ||
										userContext.data.selectedOutlet.settings
											.preventEditPrevInvoiceDate !== false
									}
									id='prevInvoiceDate'
									type='calendar'
									place='top'
									placeholder='Prev Invoice Date'
									value={parseDatePickerValue(prevInvoiceDate)}
									onChange={e =>
										prevInvoiceDateOnChange({
											target: {
												value: parseDatePickerChange(
													e.target.value,
													prevInvoiceDate
												),
											},
										})
									}
								/>
							</FormField>
							<FormField
								name='nextInvoiceDate'
								label='Next Invoice Date'
								inFormDesign={false}
								colMd={3}>
								<DatePicker
									disabled={
										disabled ||
										(invoicingFrequency &&
											invoicingFrequency.value ===
												_invoicingFrequencies.UPFRONT)
									}
									id='nextInvoiceDate'
									type='calendar'
									placeholder='Next Invoice Date'
									minDate={getNextInvoiceDateMin(reservationItem)}
									place='top'
									value={parseDatePickerValue(nextInvoiceDate)}
									onChange={e =>
										nextInvoiceDateOnChange({
											target: {
												value: parseDatePickerChange(
													e.target.value,
													nextInvoiceDate
												),
											},
										})
									}
								/>
							</FormField>
						</div>
					</>
				)}
				<div className='row sdms-mb-10 sdms-font-boldest'>
					<h5>Deferred Income</h5>
				</div>
				<div className='row sdms-mb-15'>
					<FormField
						name='enableDeferredIncome'
						label='Enable Deferred Income'
						inFormDesign={false}
						colMd={3}>
						<Toggle
							spaceLess
							value={enableDeferredIncome}
							onChange={enableDeferredIncomeOnChange}
							disabled={
								disabled ||
								(reservationItem.product &&
									!reservationItem.product.enableDeferredIncome)
							}
						/>
					</FormField>
					<FormField
						name='deferredIncomeFrequency'
						label='Frequency'
						valRes={deferredIncomeFrequencyValRes}
						showValidation={deferredIncomeFrequencyShowVal}
						inFormDesign={false}
						colMd={3}>
						<Selects
							options={filterInvoicingFrequency(
								invoicingFrequencies,
								bookingPeriod,
								false,
								invoicingFrequency ? invoicingFrequency.value : null
							)}
							placeholder={`Frequency${enableDeferredIncome ? ' (Required)' : ''}`}
							value={deferredIncomeFrequency}
							onChange={deferredIncomeFrequencyOnChange}
							onBlur={setDeferredIncomeFrequencyShowVal}
							displayKey='value'
							disabled={!enableDeferredIncome || disabled}
							disableClearable
						/>
					</FormField>
					<FormField
						name='deferredIncomeSalesAccount'
						label='Sales Income Account'
						valRes={deferredIncomeSalesAccountValRes}
						showValidation={deferredIncomeSalesAccountShowVal}
						inFormDesign={false}
						colMd={3}>
						<Selects
							options={accounts}
							placeholder={`Sales Income Account${
								enableDeferredIncome ? ' (Required)' : ''
							}`}
							value={deferredIncomeSalesAccount}
							onChange={deferredIncomeSalesAccountOnChange}
							onBlur={setDeferredIncomeSalesAccountShowVal}
							disabled={!enableDeferredIncome || disabled}
							disableClearable
						/>
					</FormField>
				</div>
			</div>
		</FormSection>
	);
};

Accounting.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	onFormChange: PropTypes.func,
	disabled: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	sectionRef: PropTypes.object,
	isItemSubmitted: PropTypes.bool,
	setSectionIsValid: PropTypes.func,
	onInvoicingChange: PropTypes.func,
	invoicingFrequencies: PropTypes.arrayOf(PropTypes.object),
	invoiceNextOptions: PropTypes.arrayOf(PropTypes.object),
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	invoiceFirstOptions: PropTypes.arrayOf(PropTypes.object),
	accounts: PropTypes.arrayOf(PropTypes.object),
};

Accounting.defaultProps = {
	reservation: null,
	reservationItem: null,
	onFormChange: () => {},
	disabled: false,
	sectionRef: null,
	isItemSubmitted: false,
	setSectionIsValid: () => {},
	onInvoicingChange: () => {},
	invoicingFrequencies: [],
	invoiceNextOptions: [],
	paymentTerms: [],
	invoiceFirstOptions: [],
	accounts: [],
};

export default Accounting;
