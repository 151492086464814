import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import classNames from 'classnames';

import {
	calculateServiceFee,
	dateView,
	getServiceFeeText,
	numberFormat,
	priceFormatter,
} from '../../../../../utils/helpers/helper';
import {
	getItemTotal,
	getTaxTotal,
	getTotalIsTaxExtraCharges,
} from '../../../../../utils/helpers/basicReservationHelper';

import Portlet from '../../../layout/Portlet';
import Badge from '../../Badge';
import Button from '../../Button';
import Section from '../../../layout/Section';
import Alert from '../../Alert';
import Separator from '../../../layout/Separator';
import ExtraChargeSummaryItem from './ExtraChargeSummaryItem';
import ItemMultiImage from './ItemMultiImage';

const BookingSummaryItem = ({
	reservationItem,
	onEdit,
	onDelete,
	disableActions,
	isLoading,
	isOnline,
	inConfirmation,
	serviceFeeAmount,
	outlet,
}) => {
	const from = useMemo(
		() =>
			moment(reservationItem.fromDate)
				.hour(reservationItem.isHourlyOnly ? reservationItem.startTime.split(':')[0] : 0)
				.minute(reservationItem.isHourlyOnly ? reservationItem.startTime.split(':')[1] : 0)
				.second(0)
				.millisecond(0),
		[reservationItem]
	);

	const to = useMemo(
		() =>
			moment(reservationItem.toDate || reservationItem.fromDate)
				.hour(reservationItem.isHourlyOnly ? reservationItem.endTime.split(':')[0] : 0)
				.minute(reservationItem.isHourlyOnly ? reservationItem.endTime.split(':')[1] : 0)
				.second(0)
				.millisecond(0),
		[reservationItem]
	);

	const serviceFeeTotal = useMemo(
		() => calculateServiceFee(reservationItem.deposit, serviceFeeAmount),
		[reservationItem, serviceFeeAmount]
	);

	const itemTotal = useMemo(
		() =>
			getItemTotal(
				reservationItem.price,
				reservationItem.extraCharge,
				reservationItem.tax,
				reservationItem.extraTax
			),
		[reservationItem]
	);

	const depositDue = useMemo(
		() =>
			reservationItem.product.isSecurityDeposit
				? reservationItem.deposit
				: Math.min(reservationItem.deposit, itemTotal),
		[reservationItem, itemTotal]
	);

	const _Content = (
		<>
			<Portlet.Head wrapMaxSize={disableActions ? null : 'md'}>
				<Portlet.HeadLabel
					className={classNames({ 'sdms-reservation__summary': inConfirmation })}>
					<h3 className='sdms-portlet__head-title'>
						{reservationItem.product.name}
						{reservationItem.product.categoryName && (
							<small>{reservationItem.product.categoryName}</small>
						)}
					</h3>
					{inConfirmation && (
						<>
							<Portlet.Separator />
							<div className='sdms-portlet__head-desc'>
								Confirmation {reservationItem.reservationItemId}
							</div>
						</>
					)}
					{!reservationItem.isHourlyOnly && (
						<>
							<Portlet.Separator />
							<Badge design='info' isInline isUnified>
								{reservationItem.intervalText}
							</Badge>
						</>
					)}
				</Portlet.HeadLabel>
				{!disableActions && (
					<Portlet.HeadToolbarActions>
						<Button
							className={classNames('sdms-mr-10', {
								'sdms-fading-dots': isLoading,
							})}
							label='danger'
							text='Delete Booking'
							icon={isLoading ? 'Other#2' : 'Trash'}
							size='sm'
							onClick={onDelete}
							disabled={isLoading}
						/>
						<Button
							label='info'
							text='Edit Options'
							icon='Edit'
							size='sm'
							onClick={onEdit}
							disabled={isLoading}
						/>
					</Portlet.HeadToolbarActions>
				)}
			</Portlet.Head>
			<Portlet.Body>
				<Section>
					<Section.Body className='row'>
						<div className='col-md-4 sdms-pb-15-tablet-and-mobile'>
							<ItemMultiImage images={reservationItem.product.images} />
						</div>
						<div className='col'>
							<Alert icon='Info-circle' design='dark' solid marginLess={false}>
								<div className='row sdms-font-bolder'>
									<div className='col'>Date</div>
									<div className='col-auto'>
										{dateView(
											from,
											to,
											reservationItem.isHourlyOnly,
											!reservationItem.isHourlyOnly,
											'-'
										)}
									</div>
								</div>
								<div className='row sdms-font-bolder'>
									<div className='col'>Price</div>
									<div className='col-auto'>
										{priceFormatter(reservationItem.price)}
									</div>
								</div>
								{reservationItem.extra.length > 0 && (
									<>
										{reservationItem.extra.map((category, c) =>
											category.map((ext, e) => (
												<ExtraChargeSummaryItem
													key={`${reservationItem.id}-${ext.id}`}
													charge={reservationItem.extraCharge[c][e]}
													extra={ext}
												/>
											))
										)}
									</>
								)}
							</Alert>
							{reservationItem.note && (
								<Alert icon='Write' design='dark' solid marginLess={false}>
									<div className='row'>
										<div className='col sdms-font-bolder'>Special Request</div>
										<div className='col-auto'>{reservationItem.note}</div>
									</div>
								</Alert>
							)}
							<Alert icon='Sale#1' design='dark' solid marginLess={false}>
								<div className='row sdms-font-bolder'>
									<div className='col'>TAX</div>
									<div className='col-auto'>
										{priceFormatter(
											getTaxTotal(
												reservationItem.tax,
												reservationItem.extraTax
											) +
												numberFormat(
													getTotalIsTaxExtraCharges(
														reservationItem.extra,
														reservationItem.extraCharge
													)
												)
										)}
									</div>
								</div>
							</Alert>
							<Separator size='sm' type='border-dashed' className='w-100' />
							<Alert icon='Dollar' design='dark' solid marginLess={false}>
								<div className='row sdms-font-bolder'>
									<div className='col'>Deposit Due</div>
									<div className='col-auto'>{priceFormatter(depositDue)}</div>
								</div>
							</Alert>
							{serviceFeeTotal !== 0 && (
								<>
									<Alert icon='Dollar' design='dark' solid marginLess={false}>
										<div className='row sdms-font-bolder'>
											<div className='col'>
												{getServiceFeeText(null, outlet)}
											</div>
											<div className='col-auto'>
												{priceFormatter(serviceFeeTotal)}
											</div>
										</div>
									</Alert>
									<Separator size='sm' type='border-dashed' className='w-100' />
								</>
							)}
							<div className='row sdms-fitText--xl sdms-font-bolder'>
								<div className='col'>Total</div>
								<div className='col-auto text-right'>
									{priceFormatter(itemTotal + serviceFeeTotal)}
								</div>
							</div>
						</div>
					</Section.Body>
				</Section>
			</Portlet.Body>
		</>
	);

	if (inConfirmation) return <Portlet border>{_Content}</Portlet>;

	if (isOnline) return <Portlet hasFrame>{_Content}</Portlet>;

	return (
		<div className='col-xl-6 col-lg-12'>
			<Portlet style={{ height: 'calc(100% - 20px)' }}>{_Content}</Portlet>
		</div>
	);
};

BookingSummaryItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object.isRequired,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func,
	disableActions: PropTypes.bool,
	isLoading: PropTypes.bool,
	isOnline: PropTypes.bool.isRequired,
	inConfirmation: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	serviceFeeAmount: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
};

BookingSummaryItem.defaultProps = {
	disableActions: false,
	isLoading: false,
	inConfirmation: false,
	onEdit: () => {},
	onDelete: () => {},
	serviceFeeAmount: { amount: 0, tax: 0 },
};

export default BookingSummaryItem;
