/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Builder, Query, Utils as QbUtils } from 'react-awesome-query-builder';
import CsvDownloader from 'react-csv-downloader';
import ReactExport from 'react-export-excel';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import UserContext from '../../../app/contexts/UserContext';
import apiCall, {
	filters,
	moduleRelatedEndpoints,
	outletRelatedEndpoints,
} from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	getQueryBuildConfig,
	hasReportOldField,
	reportCheckChildren,
} from '../../../utils/helpers/helper';

import ContentInner from './ContentInner';
import BreadcrumbContainer from './BreadcrumbContainer';
import Button from '../element/Button';
import QuickPanel from '../../QuickPanel';
import Portlet from '../layout/Portlet';
import FormGroup from '../layout/FormGroup';
import FormField from './FormField';
import Selects from '../field/Selects';
import List from './List';
import Alert from '../element/Alert';

import logoLoading from '../../../assets/img/logoLoading.svg';

const { ExcelFile } = ReactExport;

const { ExcelSheet } = ReactExport.ExcelFile;

const ReportContainer = ({
	title,
	columnOptions,
	lists,
	config,
	initTree,
	reportFileName,
	reportId,
	reportRender,
	route,
	groupRowRender,
}) => {
	const userContext = useContext(UserContext);

	const [queryBuilder, setQueryBuilder] = useState({
		tree: QbUtils.checkTree(QbUtils.loadTree(reportCheckChildren(initTree)), {}),
		config: {},
	});

	const [listState, setListState] = useState({});

	const [isLoading, setIsLoading] = useState(true);

	const [isQuerying, setIsQuerying] = useState(false);

	const [listData, setListData] = useState([]);

	const [csvData, setCsvData] = useState([]);

	const [excelData, setExcelData] = useState([]);

	const [groupBy, setGroupBy] = useState(null);

	const [quickPanel, setQuickPanel] = useState(false);

	const [queryPortlet, setQueryPortlet] = useState(true);

	const [response, setResponse] = useState([]);

	const [columns, setColumns] = useState(columnOptions);

	const [warning, setWarning] = useState(false);

	const groupByOptions = useRef(columnOptions.filter(co => co.groupLabel));

	const applyGroupBy = () => {
		if (!groupBy) return response;

		// if selected group by it is not in columns add it.
		if (columns.findIndex(c => c.id === groupBy.id) === -1)
			setColumns([...columns, groupBy].sort((a, b) => (a.sortNum > b.sortNum ? 1 : -1)));

		const newListData = [];

		const groupByValues = [
			...new Set(
				response
					.sort((a, b) => {
						if (a[groupBy.id] === null) return -1;

						if (b[groupBy.id] === null) return 1;

						return a[groupBy.id].toString().localeCompare(b[groupBy.id].toString());
					})
					.map(data => {
						if (groupBy.type === 'date')
							return new Date(data[groupBy.id]).toDateString();
						return data[groupBy.id];
					})
			),
		];

		groupByValues.forEach(value => {
			newListData.push(
				groupRowRender
					? groupRowRender(response, groupBy.id, value)
					: {
							id: value,
							titleLine: true,
							[groupBy.id]: value,
					  },
				...response.filter(d => {
					if (groupBy.type === 'date')
						return new Date(d[groupBy.id]).toDateString() === value;
					return d[groupBy.id] === value;
				})
			);
		});

		return newListData;
	};

	const onChange = (immutableTree, configuration) =>
		setQueryBuilder({ tree: immutableTree, config: configuration });

	const sendQuery = () => {
		setIsQuerying(true);

		apiCall(
			'POST',
			route,
			res => {
				setResponse(res);

				if (columns.find(a => a.subtotal != null))
					setGroupBy(columns.find(a => a.subtotal != null));

				if (res.length === 0) addErrorNotification('No Results Found');

				setQueryPortlet(res.length === 0);

				setTimeout(() => setIsQuerying(false), 250);
			},
			() => {
				setIsQuerying(false);

				addErrorNotification('Query failed.');
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				query: QbUtils.sqlFormat(queryBuilder.tree, queryBuilder.config),
				reportId,
			}
		);
	};

	const LoadingContent = ({ logoLoading, listData }) => (
		<Portlet className='flex-grow-0' fluid={!listData.length && 'fluid'}>
			<Portlet.Body>
				<ReactSVG src={logoLoading} className='svg-container logo-loading' />
			</Portlet.Body>
		</Portlet>
	);

	const QueryBuilder = () => {
		if (!queryPortlet) return null;

		return (
			<Portlet
				className={classNames('flex-grow-0')}
				fluid={listData.length === 0 ? 'fluid' : null}>
				<Portlet.Body>
					{warning ? (
						<Alert solid icon='Info-circle' design='danger' bold>
							Report has invalid field(s). Please rebuild query.
						</Alert>
					) : (
						<Query
							{...queryBuilder.config}
							value={queryBuilder.tree}
							onChange={onChange}
							renderBuilder={props => (
								<div className='query-builder-container'>
									<div className='query-builder qb-lite'>
										<Builder {...props} />
									</div>
								</div>
							)}
						/>
					)}
				</Portlet.Body>
				<Portlet.Foot tall='sm'>
					<div className='col' />
					<div className='col-auto'>
						<Button
							icon='Hard-drive'
							size='sm'
							label='brand'
							text='Submit Report'
							disabled={isQuerying || warning}
							onClick={sendQuery}
						/>
					</div>
				</Portlet.Foot>
			</Portlet>
		);
	};

	const ReportList = () => {
		if (listData.length === 0) return null;

		return (
			<List data={listData} fluid='fluid' isLoading={isQuerying} hasPagination={false}>
				{columns.map(column => (
					<List.Col
						key={column.id}
						label={column.displayName}
						cellData={column.id}
						cellComponent={column.cellComponent}
					/>
				))}
			</List>
		);
	};

	const OutputContent = () => {
		if (isLoading) return <Portlet.Placeholder />;

		if (isQuerying) return <LoadingContent logoLoading={logoLoading} listData={listData} />;

		return (
			<>
				<QueryBuilder />
				<ReportList />
			</>
		);
	};

	// Effect to populate drop-downs
	useEffect(() => {
		lists.forEach(name => {
			const _filters = { pagination: false };

			// if list is related outlet add filter.
			if (outletRelatedEndpoints.includes(name))
				_filters['outlet.id'] = userContext.data.selectedOutlet.id;

			if (module && moduleRelatedEndpoints.includes(name)) _filters['module.value'] = module;

			if ((((filters[name] || {}).groups || {}).list || {}).read)
				_filters['groups[]'] = filters[name].groups.list.read;

			apiCall(
				'GET',
				name,
				res => {
					setListState(oldListState => ({
						...oldListState,
						[name]: res,
					}));
				},
				err => {
					addErrorNotification(err.toString());
					setIsLoading(false);
				},
				'',
				null,
				_filters
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userContext.data.selectedOutlet.id]);

	// Effect to initialize the query builder
	useEffect(() => {
		if (Object.keys(listState).length) {
			if (hasReportOldField(initTree, config.fields)) {
				setWarning(true);
			} else {
				const initConfig = {
					...getQueryBuildConfig(),
					...config,
				};

				Object.keys(initConfig.fields).forEach(k => {
					if (
						initConfig.fields[k].type === 'select' &&
						listState[initConfig.fields[k].source]
					)
						initConfig.fields[k].listValues = listState[
							initConfig.fields[k].source
						].map(d => {
							return {
								value: d[initConfig.fields[k].key],
								title: d[initConfig.fields[k].key],
							};
						});
				});

				setQueryBuilder({
					tree: QbUtils.checkTree(
						QbUtils.loadTree(reportCheckChildren(initTree)),
						initConfig
					),
					config: initConfig,
				});
			}
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listState]);

	// Effect to manipulate the list data and column selection after group by
	useEffect(() => {
		setListData(applyGroupBy(response));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupBy, response]);

	useEffect(() => {
		if (listData.length === 0) {
			setExcelData([]);

			setCsvData([]);

			return;
		}

		const reportColumns = columns.filter(c => !c.hideInReport);

		setCsvData(
			listData.map(data => {
				const row = {};
				reportColumns.forEach(c => {
					row[c.id] = JSON.stringify(reportRender(data, c.id));
				});
				return row;
			})
		);

		const dataSet = {
			columns: [],
			data: [],
		};

		dataSet.columns.push(...reportColumns.map(c => c.displayName));

		listData.forEach(row => {
			if (row.titleLine) {
				// add empty row.
				if (dataSet.data.length !== 0)
					dataSet.data.push(
						columns.map(() => {
							return {};
						})
					);

				dataSet.data.push(
					reportColumns.map(c => {
						return {
							value: reportRender(row, c.id),
							style: {
								font: { sz: '12', bold: true },
								fill: { patternType: 'solid', fgColor: { rgb: 'C0C0C0' } },
							},
						};
					})
				);
			} else {
				dataSet.data.push(
					reportColumns.map(c => {
						return {
							value: reportRender(row, c.id),
						};
					})
				);
			}
		});

		setExcelData([dataSet]);
	}, [listData, columns, reportRender]);

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title={title} />
					<BreadcrumbContainer />
					{!!listData.length && (
						<>
							<ContentInner.SubHeaderSeparator />
							<ContentInner.SubHeaderGroup>
								<Button
									design='info'
									icon='Filter'
									onClick={() => setQuickPanel(true)}
									btnIcon
									circle
								/>
								<QuickPanel
									status={quickPanel}
									setStatus={setQuickPanel}
									icon='Filter'
									title='Filters'>
									<Portlet.Body>
										<FormGroup>
											<FormField name='columns' label='Columns' col={12}>
												<Selects
													options={columnOptions}
													placeholder='Select Columns'
													value={columns}
													onChange={e => setColumns(e.target.value)}
													displayKey='displayName'
													sortKey='sortNum'
													multiple
												/>
											</FormField>
											{groupByOptions.current.length > 0 && (
												<FormField name='groupBy' label='Subtotal' col={12}>
													<Selects
														options={groupByOptions.current}
														placeholder='Select a field to subtotal by'
														value={groupBy}
														onChange={e => setGroupBy(e.target.value)}
														displayKey='groupLabel'
													/>
												</FormField>
											)}
										</FormGroup>
									</Portlet.Body>
								</QuickPanel>
							</ContentInner.SubHeaderGroup>
						</>
					)}
				</ContentInner.SubHeaderItem>
				<ContentInner.SubHeaderItem type='toolbar'>
					<CsvDownloader
						filename={`${reportFileName}.csv`}
						datas={csvData}
						columns={columns.filter(c => !c.hideInReport)}
						prefix=''
						suffix=''>
						<Button
							design='brand'
							outline
							text='CSV Export'
							icon='Export'
							size='sm'
							disabled={!listData.length}
						/>
					</CsvDownloader>
					<ExcelFile
						element={
							<Button
								design='brand'
								outline
								text='Excel Export'
								icon='Downloaded-File'
								size='sm'
								disabled={!excelData.length}
							/>
						}
						filename={reportFileName}>
						<ExcelSheet dataSet={excelData} name='Sharper Report' />
					</ExcelFile>
					{queryPortlet ? (
						<Button
							icon='Hard-drive'
							size='sm'
							label='brand'
							text='Submit Report'
							disabled={isQuerying || warning}
							onClick={sendQuery}
						/>
					) : (
						<Button
							icon='Hard-drive'
							size='sm'
							label='brand'
							text='Edit Query'
							onClick={() => setQueryPortlet(true)}
						/>
					)}
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container isFluid={false} hasFrame title={title}>
				<OutputContent />
			</ContentInner.Container>
		</>
	);
};
ReportContainer.propTypes = {
	title: PropTypes.string,
	route: PropTypes.string.isRequired,
	columnOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
	lists: PropTypes.arrayOf(PropTypes.string),
	// eslint-disable-next-line react/forbid-prop-types
	config: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initTree: PropTypes.object,
	reportFileName: PropTypes.string,
	reportId: PropTypes.number.isRequired,
	reportRender: PropTypes.func,
	groupRowRender: PropTypes.func,
};
ReportContainer.defaultProps = {
	title: 'Report',
	lists: [],
	initTree: null,
	reportFileName: 'report',
	reportRender: (data, id) => data[id] || '',
	groupRowRender: () => {},
};

export default ReportContainer;
