import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Utils as QbUtils } from 'react-awesome-query-builder';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';
import {
	addErrorNotification,
	addSuccessNotification,
	getQueryBuildConfig,
	getReportQueryFields,
	reportCheckChildren,
} from '../../../utils/helpers/helper';
import apiCall, {
	filters,
	moduleRelatedEndpoints,
	modules,
	outletRelatedEndpoints,
} from '../../../utils/helpers/apiCall';

import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import Toggle from '../../reusables/field/Toggle';
import Selects from '../../reusables/field/Selects';

const MarketingEmail = ({ onCancel, afterSubmit }) => {
	const userContext = useContext(UserContext);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [isValid, setIsValid] = useState(false);

	const [
		marketingTemplate,
		marketingTemplateOnChange,
		marketingTemplateValRes,
		marketingTemplateShowVal,
		setMarketingTemplateShowVal,
	] = useField({}, 'marketingTemplate', () => {}, [required], null);

	const [customers, customersOnChange] = useField({}, 'customers', () => {}, [], []);

	const [report, reportOnChange] = useField({}, 'report', () => {}, [], null);

	const [includeBcc, includeBccOnChange] = useField({}, 'includeBcc', () => {}, [], false);

	const lists = ['templates', 'reports'];

	const [listState, setListState] = useState({
		templates: [],
		reports: [],
	});

	const _isMounted = useRef(false);

	useEffect(() => {
		_isMounted.current = true;

		return () => {
			_isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		lists.forEach(name => {
			const _filters = {
				pagination: false,
			};

			// if list is related outlet add filter.
			if (outletRelatedEndpoints.includes(name))
				_filters['outlet.id'] = userContext.data.selectedOutlet.id;

			if ((((filters[name] || {}).groups || {}).list || {}).read)
				_filters['groups[]'] = filters[name].groups.list.read;

			if (moduleRelatedEndpoints.includes(name)) _filters['module.value'] = modules.MARINA;

			apiCall(
				'GET',
				name,
				fetchedData => {
					if (!_isMounted.current) return;
					setListState(oldListState => ({
						...oldListState,
						[name]: fetchedData,
					}));
				},
				err => {
					addErrorNotification(err.toString());
				},
				'',
				null,
				_filters
			);
		});
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [userContext.data.selectedOutlet.id]);

	useEffect(() => {
		if (isSubmitted) {
			setMarketingTemplateShowVal();
		}
	}, [isSubmitted, setMarketingTemplateShowVal]);

	useEffect(() => {
		setIsValid(marketingTemplateValRes.isValid);
	}, [marketingTemplateValRes.isValid, setIsValid]);

	const onSubmit = () => {
		let query = '';

		if (report) {
			const config = { ...getQueryBuildConfig(), fields: getReportQueryFields(report.type) };

			const tree = QbUtils.checkTree(
				QbUtils.loadTree(reportCheckChildren(JSON.parse(report.queryJSON))),
				config
			);

			query = QbUtils.sqlFormat(tree, config);
		}

		setIsSubmitted(true);

		if (!isValid) return;

		setIsSubmitting(true);

		apiCall(
			'POST',
			'sendCustomerMarketingEmail',
			() => {
				setIsSubmitting(false);
				addSuccessNotification('Successfully emailed');
				afterSubmit(true);
			},
			e => {
				setIsSubmitting(false);
				addErrorNotification(e.toString());
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				templateId: marketingTemplate.id,
				customerIds: customers.map(c => c.id),
				reportId: report ? report.id : null,
				query,
				includeBcc,
			}
		);
	};

	return (
		<Portlet
			fluid='fluid'
			className='sdms-bg-transparent sdms-portlet--unelevate sdms-portlet--portletception sdms-mb-0'>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Clipboard-list'>
					Marketing Emails
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<FormGroup isLast>
					<FormField
						name='marketingTemplate'
						label='Marketing Template'
						valRes={marketingTemplateValRes}
						showValidation={marketingTemplateShowVal}
						colLg={12}>
						<Selects
							options={listState.templates.filter(
								t => t.type.value === 'Customer Marketing Email'
							)}
							placeholder='Marketing Template'
							value={marketingTemplate}
							onChange={marketingTemplateOnChange}
							onBlur={setMarketingTemplateShowVal}
						/>
					</FormField>
					<FormField name='customer' label='Customer' id='customer' colLg={12}>
						<AsyncSelect
							options={[]}
							placeholder='Search and select customer'
							value={customers}
							onChange={customersOnChange}
							route='customers'
							field='displayName'
							displayKey='displayName'
							onBlur={() => {}}
							isMulti
						/>
					</FormField>
					<FormField name='report' label='Customer List Report' colLg={12}>
						<Selects
							options={listState.reports.filter(
								t => t.type.value === 'crm_customers'
							)}
							placeholder='Customer List Report'
							value={report}
							onChange={reportOnChange}
						/>
					</FormField>
					<FormField name='includeBcc' label='Include Bcc' id='includeBcc' colLg={12}>
						<Toggle spaceLess value={includeBcc} onChange={includeBccOnChange} />
					</FormField>
				</FormGroup>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						icon='Error-circle'
						size='sm'
						elevate
						text='Cancel'
						onClick={onCancel}
						disabled={isSubmitting}
					/>
				</div>
				<div className='col-auto'>
					<Button
						className={classNames(' sdms-mw-100', {
							'sdms-fading-dots': isSubmitting,
						})}
						label='info'
						icon={
							isSubmitting
								? process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON
								: 'Clipboard-list'
						}
						text={isSubmitting ? 'Sending' : 'Send'}
						size='sm'
						elevate
						onClick={onSubmit}
						disabled={isSubmitting}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};

MarketingEmail.propTypes = {
	onCancel: PropTypes.func,
	afterSubmit: PropTypes.func,
};

MarketingEmail.defaultProps = {
	onCancel: () => {},
	afterSubmit: () => {},
};

export default MarketingEmail;
