import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../element/Button';
import DialogBox from '../../../element/DialogBox';

const ScheduledPaymentCapture = ({ open, isSubmitting, onCapture, onClose }) => {
	return (
		<DialogBox
			open={open}
			title='Capture'
			content='Are you sure you want to capture this payment?'
			type='question'
			onClose={onClose}>
			<Button
				className='sdms-font-transform-c'
				design='clean'
				icon='Error-circle'
				text={`No, Don't Capture!`}
				onClick={onClose}
				disabled={isSubmitting}
			/>
			<Button
				className='sdms-font-transform-c'
				label='info'
				icon='Trash'
				text={isSubmitting ? 'Capturing' : 'Yes, Capture!'}
				onClick={onCapture}
				disabled={isSubmitting}
				isSubmitting={isSubmitting}
			/>
		</DialogBox>
	);
};

ScheduledPaymentCapture.propTypes = {
	open: PropTypes.bool,
	isSubmitting: PropTypes.bool,
	onCapture: PropTypes.func,
	onClose: PropTypes.func,
};

ScheduledPaymentCapture.defaultProps = {
	open: false,
	isSubmitting: false,
	onCapture: () => {},
	onClose: () => {},
};

export default ScheduledPaymentCapture;
