import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useComponentSize from '@rehooks/component-size';
import Popup from 'reactjs-popup';

import Portal from '../../../layout/Portal';
import Portlet from '../../../layout/Portlet';
import BookingSummaryItem from '../elements/BookingSummaryItem';
import Button from '../../Button';

const ConfirmationModal = ({
	isOpen,
	isOnline,
	onClose,
	reservationItems,
	reservationCustomId,
	serviceFeeAmount,
}) => {
	const refConfirmationModalHead = useRef();

	const sizeConfirmationModalHead = useComponentSize(refConfirmationModalHead);

	const refConfirmationModalFoot = useRef();

	const sizeConfirmationModalFoot = useComponentSize(refConfirmationModalFoot);

	const confirmationModalRef = useRef();

	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				closeOnEscape={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<Portlet>
					<Portlet.Head
						className='sdms-analytics-confirmation'
						ref={refConfirmationModalHead}>
						<Portlet.HeadLabelTitle portletIcon='Write'>
							{`Reservation ${reservationCustomId}`}
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body
						ref={confirmationModalRef}
						style={{
							maxHeight: `calc(100vh - (${sizeConfirmationModalHead.height}px + ${sizeConfirmationModalFoot.height}px))`,
						}}>
						{reservationItems.map(reservationItem => (
							<BookingSummaryItem
								key={reservationItem.id}
								reservationItem={reservationItem}
								disableActions
								isOnline={isOnline}
								inConfirmation
								serviceFeeAmount={serviceFeeAmount}
							/>
						))}
					</Portlet.Body>
					<Portlet.Foot tall='sm' ref={refConfirmationModalFoot}>
						<div className='col' />
						<div className='col-auto'>
							<Button
								design='brand'
								text='Close'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};

ConfirmationModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	isOnline: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	reservationItems: PropTypes.arrayOf(PropTypes.object).isRequired,
	reservationCustomId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	serviceFeeAmount: PropTypes.number.isRequired,
};

export default ConfirmationModal;
