import React, { useContext, useEffect } from 'react';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import useOutlet from '../../../utils/hooks/useOutlet';

import FormContainer from '../../reusables/template/FormContainer';
import ReservationSettingsForm from './ReservationSettingsForm';

const ReservationSettingsEdit = () => {
	const pages = usePages();

	// Use current user to get company data.
	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const [outletKey] = useOutlet();

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.companySettings.default.text,
				path: pages.companySettings.dashboard.path,
			},
			{
				title: pages.companySettings.reservationSettings.text,
				path: pages.companySettings.reservationSettings.path,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(pages.companySettings.reservationSettings.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<FormContainer
			key={outletKey}
			dataId={
				userContext.data.selectedOutlet.settings
					? userContext.data.selectedOutlet.settings.id.toString()
					: '0'
			}
			pageTitle={pages.companySettings.reservationSettings.text}
			icon={pages.companySettings.reservationSettings.icon}
			dataName={pages.companySettings.reservationSettings.route}
			fields={<ReservationSettingsForm />}
			lists={['templates']}
			showBackButton={false}
			afterSubmit={outletSetting => userContext.updateOutlet(outletSetting, true)}
		/>
	);
};

export default ReservationSettingsEdit;
