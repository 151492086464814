import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import update from 'immutability-helper';
import moment from 'moment';

import HeaderContext from '../../../app/contexts/HeaderContext';
import UserContext from '../../../app/contexts/UserContext';
import usePages from '../../../utils/hooks/usePages';
import useDate from '../../../utils/hooks/useDate';
import useOutlet from '../../../utils/hooks/useOutlet';
import {
	invoiceStatuses,
	paymentGateWays,
	paymentStatuses,
	paymentTypes as _paymentTypes,
	serviceFeeModules,
} from '../../../utils/constants/constants';
import useField from '../../../utils/hooks/useField';
import {
	required,
	creditCardRequired as creditCardValidation,
	checkValidation,
	zero,
} from '../../../utils/helpers/validation';
import {
	addErrorNotification,
	calculateServiceFee,
	isBeforeAccountingClosedPeriod,
	getAccountingClosedDate,
	getSettlementMaxPaymentAmount,
	numberParser,
	priceFormatter,
	getJournalAmount,
	getJournalAmountPaid,
	filterCustomerPaymentMethods,
	getPaymentProcessorServiceFeeAmount,
	isScheduledPaymentWithoutToken,
	isScheduledPayment,
} from '../../../utils/helpers/helper';
import { getPaymentProcessor } from '../../../utils/hooks/usePaymentGateway';
import { getInvoiceAmountPaid } from '../../../utils/helpers/invoiceHelper';
import apiCall from '../../../utils/helpers/apiCall';
import { isDepositPayment } from '../../../utils/helpers/paymentHelper';

import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Alert from '../../reusables/element/Alert';
import Loading from '../../reusables/template/Loading';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import DatePicker from '../../reusables/field/DatePicker';
import Toggle from '../../reusables/field/Toggle';
import Radio from '../../reusables/field/Radio';
import SVGIcon from '../../reusables/element/SVGIcon';
import FormGroup from '../../reusables/layout/FormGroup';
import CreditCardForm from '../../reusables/element/CreditCardForm';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import { ListBody, ListTable } from '../../reusables/template/List';
import DialogBox from '../../reusables/element/DialogBox';
import CheckForm from '../../reusables/element/CheckForm';
import Selects from '../../reusables/field/Selects';

const SettlementItem = ({
	index,
	data,
	settlements,
	paymentAmount,
	disabled,
	onAmountChange,
	displayOutlet,
}) => {
	const maxPaymentAmount = getSettlementMaxPaymentAmount(settlements, index, paymentAmount);

	const [dateFormatter] = useDate();

	return (
		<tr>
			<td className='sdms-vertical-middle sdms-align-center'>
				<input
					id='checkbox-head'
					type='checkbox'
					onChange={() => {
						onAmountChange(data.amount ? '' : maxPaymentAmount);
					}}
					checked={data.amount !== '' && parseFloat(data.amount) > 0}
					disabled={maxPaymentAmount <= 0 || disabled}
				/>
			</td>
			<td className='sdms-vertical-middle sdms-align-center'>
				{dateFormatter(data.transactionDate, false)}
			</td>
			<td className='sdms-vertical-middle sdms-align-center'>
				<a href={data.link} target='_blank' rel='noopener noreferrer'>
					{data.customId}
				</a>
			</td>
			<td className='sdms-vertical-middle sdms-align-center'>{data.type}</td>
			{displayOutlet && (
				<td className='sdms-vertical-middle sdms-align-center'>{data.outlet}</td>
			)}
			<td className='sdms-vertical-middle sdms-align-center'>
				{priceFormatter(data.originalAmount)}
			</td>
			<td className='sdms-vertical-middle sdms-align-center'>
				{priceFormatter(data.balanceDue)}
			</td>
			<td>
				<FormGroup row isLast className='sdms-flex-center'>
					<FormField name='name' id={data.id} inFormDesign={false} isLast colLg={4}>
						<Input
							placeholder='0.00'
							value={data.amount}
							onChange={e => {
								if (e.target.value && !Number.isNaN(e.target.value)) {
									const maxAmount = getSettlementMaxPaymentAmount(
										settlements,
										index,
										paymentAmount
									);

									const _amount =
										maxAmount <= parseFloat(e.target.value)
											? maxAmount
											: e.target.value;

									onAmountChange(_amount);
								} else onAmountChange(e.target.value);
							}}
							disabled={disabled}
							pattern={process.env.REACT_APP_PRICE_PATTERN}
						/>
					</FormField>
				</FormGroup>
			</td>
		</tr>
	);
};

SettlementItem.propTypes = {
	index: PropTypes.number,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	settlements: PropTypes.arrayOf(PropTypes.object),
	paymentAmount: PropTypes.number,
	disabled: PropTypes.bool,
	onAmountChange: PropTypes.func,
	displayOutlet: PropTypes.bool,
};

SettlementItem.defaultProps = {
	index: 0,
	data: null,
	settlements: [],
	paymentAmount: 0,
	disabled: false,
	onAmountChange: () => {},
	displayOutlet: false,
};

const PaymentForm = ({
	data,
	isLoading,
	onFormChange,
	isSubmitted,
	setIsValid,
	submitButtonAttr,
	submit,
	isSubmitting,
	paymentTypes,
	openOverrideModal,
	closeOverrideModal,
	isEditable,
	accounts,
}) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const [isReadOnly, setIsReadOnly] = useState(false);

	const [isAchEnabled, setIsAchEnabled] = useState(false);

	const [, , parseDatePickerValue, parseDatePickerChange] = useDate();

	const [, , displayOutlet] = useOutlet();

	const creditCardProcessor = useMemo(
		() => getPaymentProcessor(userContext.data.selectedOutlet, _paymentTypes.CREDIT_CARD),
		[userContext.data.selectedOutlet]
	);

	const checkProcessor = useMemo(
		() => getPaymentProcessor(userContext.data.selectedOutlet, _paymentTypes.CHECK),
		[userContext.data.selectedOutlet]
	);

	const creditCardRequired = () => {
		return (value, setValRes) => {
			if (
				(userContext.hasPermission('schedule_payment_without_token') && scheduled) ||
				creditCardProcessor?.gateway?.value === paymentGateWays.SIDE_TERMINAL_CREDIT_CARD ||
				type !== _paymentTypes.CREDIT_CARD
			)
				return true;

			return creditCardValidation(value, setValRes);
		};
	};

	const checkNumberRequired = () => {
		return (value, setValRes) => {
			if (
				(userContext.hasPermission('schedule_payment_without_token') && scheduled) ||
				type !== _paymentTypes.CHECK
			)
				return true;

			if (type === _paymentTypes.CHECK && !isAchEnabled) {
				return required(value, setValRes);
			}
			return true;
		};
	};

	const achRequired = () => {
		return (value, setValRes) => {
			if (
				(userContext.hasPermission('schedule_payment_without_token') && scheduled) ||
				type !== _paymentTypes.CHECK
			)
				return true;

			if (type === _paymentTypes.CHECK && checkNumber === '')
				return checkValidation(value, setValRes);

			return true;
		};
	};

	const referenceRequired = () => {
		return (value, setValRes) => {
			if (type === _paymentTypes.WIRE) return required(value, setValRes);

			return true;
		};
	};

	const [
		customer,
		customerOnChange,
		customerValRes,
		customerShowVal,
		setCustomerShowVal,
	] = useField(data, 'customer', onFormChange, [required], null);

	const [
		remittanceDate,
		remittanceDateOnChange,
		remittanceDateValRes,
		remittanceDateShowVal,
		setRemittanceDateShowVal,
	] = useField(data, 'remittanceDate', onFormChange, [required]);

	const [scheduled, scheduledOnChange] = useField(data, 'scheduled', onFormChange, [], false);

	const [type, typeOnChange, typeValRes, typeShowVal, setTypeShowVal] = useField(
		data,
		'type',
		onFormChange,
		[required]
	);

	const [amount, amountOnChange, amountValRes, amountShowVal, setAmountShowVal] = useField(
		data,
		'amountWithoutFee',
		onFormChange,
		[required, zero],
		'',
		numberParser(true)
	);

	const [serviceFee, serviceFeeOnChange] = useField(
		data,
		'serviceFee',
		onFormChange,
		[],
		'',
		numberParser(true)
	);

	const [
		reference,
		referenceOnChange,
		referenceValRes,
		referenceShowVal,
		setReferenceShowVal,
		validateReference,
	] = useField(data, 'reference', onFormChange, [referenceRequired()]);

	const [automaticallySettle, automaticallySettleOnChange] = useField(
		data,
		'automaticallySettle',
		onFormChange,
		[],
		userContext.data.selectedOutlet?.settings?.automaticallySettle || false
	);

	const [
		checkNumber,
		checkNumberOnChange,
		checkNumberValRes,
		checkNumberShowVal,
		setCheckNumberShowVal,
		validateCheckNumber,
	] = useField(data, 'number', onFormChange, [checkNumberRequired()]);

	const [
		,
		creditCardOnChange,
		creditCardValRes,
		,
		setCreditCardShowVal,
		validateCreditCard,
	] = useField(data, 'creditCard', onFormChange, [creditCardRequired()], null);

	const [
		achAccount,
		achAccountOnChange,
		achAccountValRes,
		,
		setAchAccountShowVal,
		validateAchAccount,
	] = useField(data, 'achAccount', onFormChange, [achRequired()], null);

	const [
		accountReceivableAccount,
		accountReceivableAccountOnChange,
		accountReceivableAccountValRes,
		accountReceivableAccountShowVal,
		setAccountReceivableAccountShowVal,
	] = useField(data, 'accountReceivableAccount', onFormChange, [required], null);

	const [settlements, setSettlements] = useState([]);

	const [isSettlementsLoading, setIsSettlementsLoading] = useState(false);

	const isAccountingClosed = useMemo(
		() =>
			data.id !== 0 &&
			isBeforeAccountingClosedPeriod(data.remittanceDate || data.timeCreated, userContext),
		[data, userContext]
	);

	const BANNER = useMemo(() => {
		if (data.status && data.status.value === paymentStatuses.DECLINED)
			return (
				<div className='col-12 sdms-mb-20'>
					<Alert solid icon='Error-circle' design='danger'>
						{data.message}
					</Alert>
				</div>
			);

		if (data.status && data.status.value === paymentStatuses.VOIDED)
			return (
				<div className='col-12 sdms-mb-20'>
					<Alert solid icon='Error-circle' design='danger'>
						Payment Voided
					</Alert>
				</div>
			);

		if (isAccountingClosed)
			return (
				<div className='col-12 sdms-mb-20'>
					<Alert solid icon='Error-circle' design='danger'>
						Accounting Period Closed
					</Alert>
				</div>
			);

		return null;
	}, [data, isAccountingClosed]);

	const serviceFeeAmount = useMemo(
		() =>
			getPaymentProcessorServiceFeeAmount(
				userContext.data.selectedOutlet,
				serviceFeeModules.CRM,
				getPaymentProcessor(userContext.data.selectedOutlet, type)
			),
		[userContext.data.selectedOutlet, type]
	);

	const getPaymentInfo = () => {
		if (data.id === 0 || !type || type === _paymentTypes.CASH || !data.token) return null;

		let content = '';

		if (type === _paymentTypes.CHECK) {
			if (data.bankName && data.accountName)
				content = `ACH Account: ${data.bankName} - ${
					data.accountName
				} - ${data.routingNumber || '#'} - ${data.accountNumber || '#'}`;
			else content = `Check Number: ${data.number}`;
		}

		if (type === _paymentTypes.CREDIT_CARD) content = `Card Number: ${data.cardNumber || '#'}`;

		return <div className='col-lg-6 sdms-font-bold'>{content}</div>;
	};

	// const autoSettle = (_settlements, paymentAmount) => {
	// 	if (_settlements.length === 0 || data.id !== 0) return;
	//
	// 	_settlements = _settlements.map(s => {
	// 		s.amount = 0;
	// 		return s;
	// 	});
	//
	// 	_settlements.forEach((s, index) => {
	// 		s.amount = getSettlementMaxPaymentAmount(_settlements, index, paymentAmount);
	// 	});
	// };

	const getTotalPayment = () => {
		const _settlements = settlements
			.filter(s => !Number.isNaN(parseFloat(s.amount)))
			.map(s => parseFloat(s.amount));

		return _settlements.length ? _settlements.reduce((a, b) => a + b) : 0;
	};

	const getDepositPaymentMessage = () => {
		const depositSettlements = data.customerSettlements.filter(
			cs => cs.isDeposit || cs.isSecurityDeposit
		);

		if (depositSettlements.length > 0 && depositSettlements[0].reservationItem)
			return `This is deposit payment of booking ${depositSettlements[0].reservationItem.reservationItemId}`;

		return 'This is a deposit payment.';
	};

	const getTransactionData = transaction => {
		if (transaction.invoice) {
			return {
				invoice: transaction.invoice,
				customId: transaction.invoice.invoiceId,
				transactionDate: transaction.invoice.invoiceDate,
				originalAmount: transaction.invoice.total,
				balanceDue:
					transaction.invoice.total -
					getInvoiceAmountPaid(transaction.invoice, transaction) +
					transaction.amount,
				timeModified: transaction.invoice.timeModified,
				type: 'Invoice',
				link: `${window.location.origin}${pages.crm.invoices.path}/${transaction.invoice.id}`,
				outlet: transaction.invoice.outlet?.name || '#',
			};
		}

		if (transaction.invoiceId) {
			return {
				invoice: transaction,
				customId: transaction.invoiceId,
				transactionDate: transaction.invoiceDate,
				originalAmount: transaction.total,
				balanceDue: transaction.total - getInvoiceAmountPaid(transaction, null),
				timeModified: transaction.timeModified,
				type: 'Invoice',
				link: `${window.location.origin}${pages.crm.invoices.path}/${transaction.id}`,
				outlet: transaction.outlet?.name || '#',
			};
		}

		if (transaction.statementCharge) {
			return {
				statementCharge: transaction.statementCharge,
				customId: transaction.statementCharge.statementChargeId,
				transactionDate: transaction.statementCharge.statementChargeDate,
				originalAmount: transaction.statementCharge.amount,
				balanceDue:
					transaction.statementCharge.amount -
					getInvoiceAmountPaid(transaction.statementCharge, transaction) +
					transaction.amount,
				timeModified: transaction.statementCharge.timeModified,
				type: 'Statement Charge',
				link: `${window.location.origin}${pages.crm.statementCharges.path}/${transaction.statementCharge.id}`,
				outlet: transaction.statementCharge.outlet?.name || '#',
			};
		}

		if (transaction.statementChargeId) {
			return {
				statementCharge: transaction,
				customId: transaction.statementChargeId,
				transactionDate: transaction.statementChargeDate,
				originalAmount: transaction.amount,
				balanceDue: transaction.amount - getInvoiceAmountPaid(transaction, null),
				timeModified: transaction.timeModified,
				type: 'Statement Charge',
				link: `${window.location.origin}${pages.crm.statementCharges.path}/${transaction.id}`,
				outlet: transaction.outlet?.id || '#',
			};
		}

		if (transaction.journal) {
			const journalAmount = getJournalAmount(transaction.journal);
			return {
				journal: transaction.journal,
				customId: transaction.journal.id,
				transactionDate: transaction.journal.timeCreated,
				originalAmount: journalAmount,
				balanceDue:
					journalAmount -
					getJournalAmountPaid(transaction.journal, transaction) +
					transaction.amount,
				timeModified: transaction.journal.timeModified,
				type: 'Journal Entry',
				link: `${window.location.origin}${pages.accounting.journals.path}/${transaction.journal.id}`,
				outlet: transaction.journal.outlet?.name || '#',
			};
		}

		if (transaction.journalLines) {
			const journalAmount = getJournalAmount(transaction);
			return {
				journal: transaction,
				customId: transaction.id,
				transactionDate: transaction.timeCreated,
				originalAmount: journalAmount,
				balanceDue: journalAmount - getJournalAmountPaid(transaction, transaction),
				timeModified: transaction.timeModified,
				type: 'Journal Entry',
				link: `${window.location.origin}${pages.accounting.journals.path}/${transaction.id}`,
				outlet: '#',
			};
		}

		if (transaction.refund) {
			return {
				refund: transaction.refund,
				customId: transaction.refund.refundId,
				transactionDate: transaction.refund.refundDate || transaction.refund.timeCreated,
				originalAmount: transaction.refund.amount,
				balanceDue: transaction.refund.amount,
				timeModified: transaction.refund.timeModified,
				type: 'Refund',
				link: `${window.location.origin}${pages.crm.refunds.path}/${transaction.refund.id}`,
				outlet: transaction.refund.outlet?.name || '#',
			};
		}

		return {
			refund: transaction,
			customId: transaction.refundId,
			transactionDate: transaction.refundDate || transaction.timeCreated,
			originalAmount: transaction.amount,
			balanceDue: transaction.amount,
			timeModified: transaction.timeModified,
			type: 'Refund',
			link: `${window.location.origin}${pages.crm.refunds.path}/${transaction.id}`,
			outlet: transaction.outlet?.name || '#',
		};
	};

	const getSettlementItems = (paymentSettlements, transactions) => {
		const _settlements = [];

		if (paymentSettlements)
			_settlements.push(
				...paymentSettlements
					.filter(
						cs =>
							(cs.invoice && cs.invoice.status.value !== invoiceStatuses.VOIDED) ||
							(cs.statementCharge &&
								cs.statementCharge.status.value !== invoiceStatuses.VOIDED) ||
							cs.refund ||
							cs.journal
					)
					.map(cs => ({
						id: cs.id,
						amount: cs.amount,
						isDepositPayment: cs.isDeposit,
						isSecurityDeposit: cs.isSecurityDeposit,
						...getTransactionData(cs),
					}))
			);

		_settlements.push(
			...transactions
				.filter(
					t =>
						_settlements.findIndex(
							s =>
								(s.invoice && s.invoice.id === t.id) ||
								(s.statementCharge && s.statementCharge.id === t.id) ||
								(s.journal && s.journal.id === t.id)
						) === -1
				)
				.map(t => ({
					id: 0,
					amount: '',
					isDepositPayment: false,
					isSecurityDeposit: false,
					...getTransactionData(t),
				}))
		);

		return _settlements;
	};

	const loadTransactions = () => {
		apiCall(
			'POST',
			'getCustomerOpenTransactions',
			res => {
				setSettlements(
					getSettlementItems(data.customerSettlements, [
						...res.invoices,
						...res.statementCharges,
						...res.journals,
					])
				);
				setIsSettlementsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsSettlementsLoading(false);
			},
			'',
			{
				customerId: customer.id,
				outletId: userContext.data.selectedOutlet.id,
			}
		);
	};

	const onReload = () => {
		setIsSettlementsLoading(true);

		closeOverrideModal();

		if (data.id === 0) loadTransactions();
		else {
			apiCall(
				'GET',
				'payments',
				() => {
					loadTransactions();
				},
				err => {
					addErrorNotification(err.toString());
					setIsSettlementsLoading(false);
				},
				data.id
			);
		}
	};

	useEffect(() => {
		if (isSubmitted) {
			setCustomerShowVal();
			setRemittanceDateShowVal();
			setAmountShowVal();
			setTypeShowVal();
			setCheckNumberShowVal();
			setCreditCardShowVal();
			setAchAccountShowVal();
			setReferenceShowVal();
			setAccountReceivableAccountShowVal();
		}
	}, [
		isSubmitted,
		setCustomerShowVal,
		setRemittanceDateShowVal,
		setAmountShowVal,
		setTypeShowVal,
		setCheckNumberShowVal,
		setCreditCardShowVal,
		setAchAccountShowVal,
		setReferenceShowVal,
		setAccountReceivableAccountShowVal,
	]);

	useEffect(() => {
		setIsValid(
			customerValRes.isValid &&
				remittanceDateValRes.isValid &&
				amountValRes.isValid &&
				typeValRes.isValid &&
				checkNumberValRes.isValid &&
				achAccountValRes.isValid &&
				creditCardValRes.isValid &&
				referenceValRes.isValid &&
				accountReceivableAccountValRes.isValid &&
				!isReadOnly
		);
	}, [
		setIsValid,
		customerValRes.isValid,
		remittanceDateValRes.isValid,
		amountValRes.isValid,
		typeValRes.isValid,
		checkNumberValRes.isValid,
		achAccountValRes.isValid,
		creditCardValRes.isValid,
		referenceValRes.isValid,
		accountReceivableAccountValRes.isValid,
		isReadOnly,
	]);

	useEffect(() => {
		if (data.id === 0) {
			validateCheckNumber();
			validateCreditCard();
			validateAchAccount();
			validateReference();
			setIsAchEnabled(false);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [type, scheduled]);

	useEffect(() => {
		if (data.id === 0) validateCheckNumber(checkNumber);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isAchEnabled]);

	useEffect(() => {
		if (data.id === 0) validateAchAccount(achAccount);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [checkNumber]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.crm.dashboard.text,
				path: pages.crm.dashboard.path,
			},
			{
				title: pages.crm.payments.text,
				path: pages.crm.payments.path,
			},

			{ title: `Payment ${data.remittanceId || 'New'}`, isActive: true },
		]);

		headerContext.setPageTitle(data.id ? data.id.toString() : `New ${pages.crm.payments.text}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.id]);

	useEffect(() => {
		if (data.id === 0) {
			let _remittanceDate = moment().utc(false);

			const accountingClosedDate = getAccountingClosedDate(userContext, false);

			// if new payment set remittanceDate now.
			if (accountingClosedDate && accountingClosedDate.isAfter(_remittanceDate)) {
				accountingClosedDate.add(1, 'days');

				_remittanceDate = moment(accountingClosedDate)
					.utc(false)
					.set('hours', 12);
			}

			remittanceDateOnChange({
				target: {
					value: _remittanceDate.toISOString(false),
				},
			});

			// if new payment set type cash as default.
			typeOnChange({ target: { value: _paymentTypes.CASH } });

			data.outlet = userContext.data.selectedOutlet;

			if (!data.accountReceivableAccount)
				accountReceivableAccountOnChange({
					target: { value: userContext.getDefaultAccountReceivableAccount() },
				});
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	useEffect(() => {
		if (data.id !== 0 && !isLoading) {
			setIsReadOnly(
				data.amount === 0 ||
					data.customerSettlements.filter(
						cs =>
							cs.isDeposit ||
							cs.isSecurityDeposit ||
							(cs.invoice && cs.invoice.isSalesReceipt)
					).length > 0 ||
					data.status.value === paymentStatuses.DECLINED ||
					data.status.value === paymentStatuses.VOIDED ||
					isAccountingClosed
			);

			if (data.paymentMethod)
				typeOnChange({ target: { value: data.paymentMethod.paymentType.value } }, false);
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [paymentTypes, isLoading]);

	useEffect(() => {
		if (data.id === 0) {
			serviceFeeOnChange({
				target: {
					value:
						checkNumber || scheduled
							? 0
							: calculateServiceFee(amount, serviceFeeAmount),
				},
			});
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [amount, type, achAccount, checkNumber, scheduled]);

	useEffect(() => {
		if (!customer || isLoading) {
			setSettlements([]);
			return;
		}

		setIsSettlementsLoading(true);

		loadTransactions();

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [customer, isLoading]);

	useEffect(() => {
		data.settlements = [...settlements];
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [settlements]);

	useEffect(() => {
		if (data.id !== 0 && !isLoading && data.status.value === paymentStatuses.SCHEDULED)
			scheduledOnChange({ target: { value: true } }, false);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	useEffect(() => {
		if (type === _paymentTypes.CASH && scheduled)
			scheduledOnChange({ target: { value: false } });
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [type]);

	useEffect(() => {
		data.amount = (amount ? parseFloat(amount) : 0) + (serviceFee ? parseFloat(serviceFee) : 0);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [amount, serviceFee]);

	useEffect(() => {
		if (isSubmitted && !isSubmitting && data.id !== 0) loadTransactions();
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isSubmitting, isSubmitted]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						{BANNER}
						<Loading isLoading={isLoading}>
							<FormField
								name='customer'
								label='Customer'
								id={data.id}
								colLg={2}
								showValidation={customerShowVal}
								valRes={customerValRes}>
								<AsyncSelect
									options={data.customer ? [data.customer] : []}
									placeholder='Search and select customer'
									value={customer}
									onChange={customerOnChange}
									onBlur={() => setCustomerShowVal()}
									route='customers'
									field='displayName'
									displayKey='displayName'
									disabled={data.id !== 0 || !isEditable}
									customFilters={{ isActive: true }}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='remittanceDate'
								label='Payment Date'
								id={data.id}
								loadingContainer
								colLg={2}
								valRes={remittanceDateValRes}
								showValidation={remittanceDateShowVal}>
								<DatePicker
									id='remittanceDate'
									type='calendar'
									placeholder='Payment Date'
									value={parseDatePickerValue(remittanceDate)}
									onChange={e => {
										remittanceDateOnChange({
											target: {
												value: parseDatePickerChange(e.target.value),
											},
										});
										setRemittanceDateShowVal();
									}}
									disabled={
										(data.id !== 0 && !isScheduledPayment(data)) || !isEditable
									}
									minDate={getAccountingClosedDate(userContext)}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='scheduled' label='Scheduled' id={data.id} colLg={1}>
								<Toggle
									onChange={scheduledOnChange}
									value={scheduled}
									disabled={data.id !== 0 || !isEditable}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='amount'
								label='Amount'
								id={data.id}
								colLg={2}
								showValidation={amountShowVal}
								valRes={amountValRes}>
								<Input
									type='text'
									value={
										data.id === 0 ||
										userContext.hasPermission('edit_payment_amounts')
											? amount.toString()
											: priceFormatter(amount)
									}
									placeholder='Amount'
									onChange={amountOnChange}
									onBlur={() => setAmountShowVal()}
									disabled={
										(data.id !== 0 &&
											!userContext.hasPermission('edit_payment_amounts')) ||
										isAccountingClosed ||
										!isEditable
									}
									pattern={process.env.REACT_APP_PRICE_PATTERN}
									prependIcon='Dollar'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='serviceFee' label='Service Fee' id={data.id} colLg={2}>
								<Input
									type='text'
									value={
										data.id === 0 ||
										userContext.hasPermission('edit_payment_amounts')
											? serviceFee.toString()
											: priceFormatter(serviceFee)
									}
									placeholder='Service Fee'
									onChange={serviceFeeOnChange}
									disabled={
										data.id !== 0 ||
										serviceFeeAmount === 0 ||
										!userContext.hasPermission('edit_payment_amounts') ||
										isAccountingClosed ||
										scheduled ||
										!isEditable
									}
									prependIcon='Dollar'
									pattern={process.env.REACT_APP_PRICE_PATTERN}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='total'
								label='Total'
								id={data.id}
								colLg={2}
								showValidation={amountShowVal}
								valRes={amountValRes}>
								<Input
									type='text'
									value={priceFormatter(
										parseFloat(amount || 0) + parseFloat(serviceFee || 0)
									)}
									placeholder='Total'
									onChange={() => {}}
									disabled
									prependIcon='Dollar'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='revenueAccount'
								label='Revenue Account'
								id={data.id}
								valRes={accountReceivableAccountValRes}
								showValidation={accountReceivableAccountShowVal}
								colLg={2}>
								<Selects
									options={accounts.filter(
										a => a.accountType.value === 'AccountsReceivable'
									)}
									placeholder='Revenue Account'
									value={accountReceivableAccount}
									onChange={accountReceivableAccountOnChange}
									onBlur={setAccountReceivableAccountShowVal}
									disabled={data.id !== 0 || !isEditable}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='reference'
								label='Reference'
								id={data.id}
								colLg={2}
								valRes={referenceValRes}
								showValidation={referenceShowVal}>
								<Input
									type='text'
									value={reference}
									placeholder='Reference'
									onChange={referenceOnChange}
									onBlur={setReferenceShowVal}
									disabled={!isEditable || isAccountingClosed}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='automaticallySettle'
								label='Auto Settle'
								id={data.id}
								colLg={1}>
								<Toggle
									onChange={automaticallySettleOnChange}
									value={automaticallySettle}
									disabled={data.id !== 0 || !isEditable}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='paymentType'
								id={data.id}
								loadingContainer
								colLg={3}
								valRes={typeValRes}
								showValidation={typeShowVal}>
								<Radio.Container isInline>
									{paymentTypes
										.filter(
											pt =>
												pt.value !== _paymentTypes.HOUSE_ACCOUNT &&
												pt.value !== _paymentTypes.BOOKING
										)
										.map(pt => (
											<Radio
												key={pt.value}
												checked={type === pt.value}
												id={pt.value}
												name='paymentTypes'
												content={
													<>
														<SVGIcon
															name={
																(pt.value === _paymentTypes.CASH &&
																	'Dollar') ||
																(pt.value === _paymentTypes.CHECK &&
																	'Ticket') ||
																(pt.value ===
																	_paymentTypes.CREDIT_CARD &&
																	'Credit-card') ||
																(pt.value === _paymentTypes.WIRE &&
																	'Exchange')
															}
														/>
														<div>{pt.value}</div>
													</>
												}
												className='sdms-radio--primary'
												onChange={() => {
													typeOnChange({
														target: {
															value: pt.value,
														},
													});

													if (
														pt.value === _paymentTypes.CASH ||
														pt.value === _paymentTypes.CHECK
													)
														data.changeDue = 0;
												}}
												disabled={data.id !== 0 || !isEditable}
											/>
										))}
								</Radio.Container>
							</FormField>
						</Loading>
						{type === _paymentTypes.CHECK &&
							(data.id === 0 || isScheduledPaymentWithoutToken(data)) && (
								<FormField name='checkForm' colLg={6}>
									<CheckForm
										onChange={_achAccount => {
											achAccountOnChange({
												target: { value: _achAccount },
											});
											checkNumberOnChange({ target: { value: '' } });
										}}
										isDisabled={isSubmitting || !isEditable}
										achAccounts={
											customer
												? filterCustomerPaymentMethods(
														customer.paymentMethods,
														checkProcessor
												  )
												: []
										}
										setIsAchEnabled={setIsAchEnabled}
										hasAch={!!checkProcessor}
										checkNumberProps={{
											isLoading,
											id: data.id,
											data: checkNumber,
											onChange: checkNumberOnChange,
											showVal: checkNumberShowVal,
											valRes: checkNumberValRes,
											setShowVal: setCheckNumberShowVal,
										}}
										isSubmitted={isSubmitted}
										withLabel={false}
										displayCorporateToggle
										displaySaveCardToggle={
											customer?.canSavePaymentMethod !== false
										}
										halfInputs
									/>
								</FormField>
							)}
						{type === _paymentTypes.CREDIT_CARD &&
							(data.id === 0 || isScheduledPaymentWithoutToken(data)) && (
								<Loading isLoading={isLoading}>
									<FormField name='creditCardForm' colLg={6}>
										<CreditCardForm
											onChange={_creditCard =>
												creditCardOnChange({
													target: { value: _creditCard },
												})
											}
											isDisabled={isSubmitting || !isEditable}
											isAuthorizing={isSubmitting}
											creditCards={
												customer
													? filterCustomerPaymentMethods(
															customer.paymentMethods,
															creditCardProcessor
													  )
													: []
											}
											isSubmitted={isSubmitted}
											withLabel={false}
											displayDefaultToggle
											displaySaveCardToggle={
												customer?.canSavePaymentMethod !== false
											}
										/>
									</FormField>
								</Loading>
							)}
						{getPaymentInfo()}
					</FormGroup>
					{isDepositPayment(data) && (
						<Alert bold solid icon='Bookings'>
							{getDepositPaymentMessage()}
						</Alert>
					)}
				</form>
				{settlements.length > 0 && (
					<ListBody
						className='table--everytime--scroll sdms-portlet__body--fit'
						responsive='scroll'>
						<ListTable isLoading={isSettlementsLoading}>
							<colgroup>
								<col width={40} />
								<col />
								<col />
								<col />
								<col />
								<col />
								<col />
							</colgroup>
							<thead>
								<tr>
									<th className='sdms-align-center'>
										<input
											id='checkbox-head'
											type='checkbox'
											onClick={() => {}}
											defaultChecked={isEditable}
											readOnly
											disabled={isReadOnly || !isEditable}
										/>
									</th>
									<th className='sdms-align-center'>Date</th>
									<th className='sdms-align-center'>Number</th>
									<th className='sdms-align-center'>Type</th>
									{displayOutlet && (
										<th className='sdms-align-center'>Location</th>
									)}
									<th className='sdms-align-center'>Original Amount</th>
									<th className='sdms-align-center'>Amount Due</th>
									<th className='sdms-align-center'>Payment</th>
								</tr>
							</thead>
							<tbody>
								{settlements.map((s, index) => (
									<SettlementItem
										key={s.customId}
										index={index}
										data={s}
										settlements={settlements}
										paymentAmount={
											amount + (data.id && serviceFee ? serviceFee : 0)
										}
										disabled={
											amount === '' ||
											isSubmitting ||
											s.refund ||
											isReadOnly ||
											!isEditable
										}
										onAmountChange={_amount =>
											setSettlements(
												update(settlements, {
													[index]: { amount: { $set: _amount } },
												})
											)
										}
										displayOutlet={displayOutlet}
									/>
								))}
							</tbody>
							<tfoot>
								<tr>
									<th colSpan={displayOutlet ? 5 : 4}>Totals</th>
									<th className='sdms-align-center'>
										{priceFormatter(
											settlements.length
												? settlements
														.map(s => s.originalAmount)
														.reduce((a, b) => a + b)
												: 0
										)}
									</th>
									<th className='sdms-align-center'>
										{priceFormatter(
											settlements.length
												? settlements
														.map(s => s.balanceDue)
														.reduce((a, b) => a + b)
												: 0
										)}
									</th>
									<th className='sdms-align-center'>
										{priceFormatter(getTotalPayment())}
									</th>
								</tr>
							</tfoot>
						</ListTable>
					</ListBody>
				)}
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
					disabled={isReadOnly || !isEditable}
				/>
			</Portlet.Foot>
			<DialogBox
				open={openOverrideModal}
				title=''
				content='Some of invoices have been changed since you opened it.'
				type='question'
				onClose={closeOverrideModal}>
				<Button label='success' text='Reload' onClick={onReload} />
			</DialogBox>
		</Portlet>
	);
};
PaymentForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setIsValid: PropTypes.func,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
	paymentTypes: PropTypes.arrayOf(PropTypes.object),
	openOverrideModal: PropTypes.bool,
	closeOverrideModal: PropTypes.func,
	isSubmitting: PropTypes.bool,
	isEditable: PropTypes.bool,
	accounts: PropTypes.arrayOf(PropTypes.object),
};
PaymentForm.defaultProps = {
	data: null,
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
	paymentTypes: [],
	openOverrideModal: false,
	closeOverrideModal: () => {},
	isSubmitting: false,
	isEditable: true,
	accounts: [],
};

export default PaymentForm;
