import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import RegisterTypeForm from './RegisterTypeForm';
import pages from '../../pages';

const RegisterTypeEdit = ({ match }) => {
	return (
		<FormContainer
			dataId={match.params.id}
			dataName='registerTypes'
			fields={<RegisterTypeForm />}
			pageTitle={pages.pos.settings.registerTypes.text}
			icon={pages.pos.settings.registerTypes.icon}
		/>
	);
};

RegisterTypeEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default RegisterTypeEdit;
