import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SVGIcon from './SVGIcon';

const Item = ({ className, children, icon, title, desc, bodyWidth }) => {
	// const [transformTitle, setTransformTitle] = useState(title);
	// useEffect(() => {
	//	if (children.props && children.props.type === 'formField') {
	//		setTransformTitle(children.props.children.props.label);
	//	}
	// }, [children.props]);

	const transformTitle =
		title ||
		(children.props &&
			children.props.type === 'formField' &&
			children.props.children.props.label);

	return (
		<div className={classNames('sdms-notes__item', className)}>
			<div className='sdms-notes__media'>
				<span className='sdms-notes__icon'>
					{icon && <SVGIcon name={icon} />}
					<span />
				</span>
			</div>
			<div className='sdms-notes__content'>
				{(transformTitle || desc) && (
					<div className='sdms-notes__section'>
						<div className='sdms-notes__info'>
							{transformTitle && (
								<div className='sdms-notes__title'>{transformTitle}</div>
							)}
							{desc && <span className='sdms-notes__desc'>desc</span>}
						</div>
					</div>
				)}
				<div className='sdms-notes__body' style={{ width: bodyWidth }}>
					{children}
				</div>
			</div>
		</div>
	);
};
Item.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	icon: PropTypes.string,
	title: PropTypes.string,
	desc: PropTypes.string,
	bodyWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Item.defaultProps = {
	className: null,
	icon: null,
	title: null,
	desc: null,
	bodyWidth: null,
};

const Notes = ({ className, children }) => {
	return (
		<div className={classNames('sdms-notes', className)}>
			<div className='sdms-notes__items'>{children}</div>
		</div>
	);
};
Notes.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};
Notes.defaultProps = {
	className: null,
};

Notes.Item = Item;

export default Notes;
