import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import pages from '../../pages';

import FormContainer from '../../reusables/template/FormContainer';
import TableMapForm from './TableMapForm';
import { modules } from '../../../utils/helpers/apiCall';
import HeaderContext from '../../../app/contexts/HeaderContext';
import useOutlet from '../../../utils/hooks/useOutlet';

const TableMapEdit = ({ match }) => {
	const headerContext = useContext(HeaderContext);
	const [outletKey] = useOutlet(true);

	return (
		<FormContainer
			key={outletKey}
			dataId={match.params.id.toString()}
			dataName='unitMaps'
			fields={<TableMapForm />}
			pageTitle={headerContext.pageTitle || pages.pos.settings.tableMaps.text}
			icon={pages.pos.settings.tableMaps.icon}
			lists={['icons', 'units']}
			module={modules.POS}
		/>
	);
};

TableMapEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default TableMapEdit;
