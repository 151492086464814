import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';
import { extraChargeTypes } from '../../../utils/constants/constants';
import forms from '../../forms';
import { priceFormatter } from '../../../utils/helpers/helper';

import Button from '../element/Button';
import Dropdown from '../element/Dropdown';
import List from '../template/List';
import ListContainer from '../template/ListContainer';
import Badge from '../element/Badge';

const AmountCell = ({ data }) => {
	return (
		<Badge design='info' isInline isUnified size='lg' fontWeight='boldest'>
			{data.bookingCalculation.value === 'percent'
				? `${data.price * -1}%`
				: priceFormatter(data.price * -1)}
		</Badge>
	);
};
AmountCell.propTypes = {
	data: PropTypes.shape({
		bookingCalculation: PropTypes.object,
		price: PropTypes.number,
	}),
};
AmountCell.defaultProps = {
	data: {
		type: { value: 'percent' },
		price: 0,
	},
};

const AllOrdersCell = ({ data }) => {
	return data.extraChargeType?.value === extraChargeTypes.ALL_ORDERS ? (
		<Badge.Yes />
	) : (
		<Badge.No />
	);
};
AllOrdersCell.propTypes = {
	data: PropTypes.shape({
		extraChargeType: PropTypes.object,
	}),
};
AllOrdersCell.defaultProps = {
	data: {
		extraChargeType: null,
	},
};

const UsesCell = ({ data }) => {
	return data.uses || '#';
};

const UsedCell = ({ data }) => {
	return data.used || '#';
};

const PromoCodeList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	const location = useLocation(); // React Hook

	const pages = usePages();

	const data = useMemo(() => {
		if (location.pathname.indexOf('marina') > -1)
			return {
				text: pages.marina.settings.maps.text,
				breadcrumb: [
					{ title: pages.marina.default.text, path: pages.marina.dashboard.path },
					{ title: pages.marina.settings.text, path: pages.marina.settings.path },
					{ title: pages.marina.settings.promoCodes.text, isActive: true },
				],
				title: pages.marina.settings.promoCodes.text,
				icon: pages.marina.settings.promoCodes.icon,
				forms: forms.marina.settings.promoCodes,
				module: modules.MARINA,
			};

		if (location.pathname.indexOf('campground') > -1)
			return {
				text: pages.campground.settings.maps.text,
				breadcrumb: [
					{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
					{ title: pages.campground.settings.text, path: pages.campground.settings.path },
					{ title: pages.campground.settings.promoCodes.text, isActive: true },
				],
				title: pages.campground.settings.promoCodes.text,
				icon: pages.campground.settings.promoCodes.icon,
				forms: forms.campground.settings.promoCodes,
				module: modules.CAMPGROUND,
			};

		return {
			text: pages.booking.settings.maps.text,
			breadcrumb: [
				{ title: pages.booking.default.text, path: pages.booking.dashboard.path },
				{ title: pages.booking.settings.text, path: pages.booking.settings.path },
				{ title: pages.booking.settings.promoCodes.text, isActive: true },
			],
			title: pages.booking.settings.promoCodes.text,
			icon: pages.booking.settings.promoCodes.icon,
			forms: forms.booking.settings.promoCodes,
			module: modules.BOOKINGS,
		};
	}, [location, pages]);

	useEffect(() => {
		headerContext.setBreadcrumbs(data.breadcrumb);

		headerContext.setPageTitle(data.text);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [data]);

	return (
		<ListContainer
			route='promoCodes'
			title={data.title}
			history={history}
			icon={data.icon}
			forms={data.forms}
			module={data.module}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col label='Code' cellData='code' sortable='code' />
				<List.Col label='Amount' cellComponent={<AmountCell />} />
				<List.Col label='All Orders' cellComponent={<AllOrdersCell />} />
				<List.Col label='Number of Uses' cellComponent={<UsesCell />} sortable='uses' />
				<List.Col label='Number of Used' cellComponent={<UsedCell />} />
				<List.Col align='right' onlyHover>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

PromoCodeList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default PromoCodeList;
