import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Popup from 'reactjs-popup';
import apiCall, { pathToUrl } from '../../../utils/helpers/apiCall';
import { addErrorNotification, addSuccessNotification } from '../../../utils/helpers/helper';
import Button from './Button';
import SVGIcon from './SVGIcon';
import Portlet from '../layout/Portlet';
import TablePagination from './TablePagination';
import Search from './Search';
import DialogBox from './DialogBox';
import Loading from '../template/Loading';
import Portal from '../layout/Portal';
import FormField from '../template/FormField';
import Selects from '../field/Selects';
import Input from '../field/Input';
import { alignments, widths } from '../../../utils/constants/mediaElement';

const Media = ({ setMedia, isOpen, close, enableAdditionalSettings, defaultSelectedMedia }) => {
	const [items, setItems] = useState([]);
	const [selectedMedia, setSelectedMedia] = useState(defaultSelectedMedia);
	const [isUploading, setIsUploading] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [totalItems, setTotalItems] = useState(0);
	const [searchText, setSearchText] = useState('');
	const [willDeleteItem, setWillDeleteItem] = useState(null);
	const [error, setError] = useState(false);
	const [itemsPerPage, setItemsPerPage] = useState(
		parseInt(process.env.REACT_APP_MEDIA_ITEMS_PER_PAGE, 10)
	);
	const [isAdditionalSettingsOpen, setIsAdditionalSettingsOpen] = useState(false);
	const [additionalSettings, setAdditionalSettings] = useState({
		width: '24%',
		title: '',
		alignment: 'center',
	});

	const _isMounted = useRef(false);

	useEffect(() => {
		_isMounted.current = true;

		return () => {
			_isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		setIsLoading(true);

		const filters = {
			pagination: true,
			itemsPerPage,
			page: currentPage,
		};

		if (searchText.length) filters.name = searchText;

		apiCall(
			'GET',
			'media',
			res => {
				if (!_isMounted.current) return;
				setItems(res['hydra:member']);
				setTotalItems(res['hydra:totalItems']);
				setIsLoading(false);
			},
			err => {
				if (!_isMounted.current) return;
				addErrorNotification(err.toString());
				setError(err.toString);
				setIsLoading(false);
			},
			'',
			null,
			filters
		);
	}, [currentPage, searchText, itemsPerPage]);

	useEffect(() => setTotalPage(Math.ceil(totalItems / itemsPerPage)), [totalItems, itemsPerPage]);

	useEffect(() => {
		setSelectedMedia(defaultSelectedMedia);
	}, [defaultSelectedMedia]);

	const handleImageUpload = ({ target }) => {
		setIsUploading(true);

		const formData = new FormData();
		formData.append('image', target.files[0]);

		apiCall(
			'POST',
			'media',
			data => {
				if (!_isMounted.current) return;
				setCurrentPage(1);
				setItems(oldItems => [data, ...oldItems]);
				setTotalItems(totalItems + 1);
				setIsUploading(false);
				setSelectedMedia(data);
				if (enableAdditionalSettings) setIsAdditionalSettingsOpen(true);
				addSuccessNotification('Media successfully added.');
			},
			err => {
				if (!_isMounted.current) return;
				addErrorNotification(err.toString());
				setIsUploading(false);
			},
			'',
			formData
		);
	};

	const deleteImage = () => {
		if (willDeleteItem === null) return;

		apiCall(
			'DELETE',
			'media',
			() => {
				if (!_isMounted.current) return;
				setItems(oldItems => oldItems.filter(item => item.id !== willDeleteItem.id));
				setTotalItems(totalItems - 1);
				setWillDeleteItem(null);
				addSuccessNotification('Media successfully deleted.');
			},
			err => {
				if (!_isMounted.current) return;
				addErrorNotification(err.toString());
				setWillDeleteItem(null);
			},
			willDeleteItem.id
		);
	};

	if (error) return <div>{error}</div>;

	return (
		<Portal>
			<Popup
				contentStyle={{ padding: 0, background: 'unset', border: 'unset' }}
				closeOnDocumentClick={false}
				modal
				lockScroll
				open={isOpen}
				onClose={() => {
					close();
				}}>
				<>
					<Portlet>
						<Portlet.Head>
							<Portlet.HeadLabel portletIcon='Picture'>
								<h3 className='sdms-portlet__head-title'>Media</h3>

								{!isAdditionalSettingsOpen && (
									<>
										<Portlet.Separator />
										<Search
											inPortlet
											searchText={searchText}
											setSearchText={setSearchText}
											isSearching={isLoading}
											setCurrentPage={setCurrentPage}
										/>
									</>
								)}
							</Portlet.HeadLabel>
							<Portlet.HeadToolbarActions>
								{isAdditionalSettingsOpen ? (
									<Button
										label='brand'
										text='Back to Images'
										icon='Backspace'
										size='sm'
										onClick={() => {
											setIsAdditionalSettingsOpen(false);
										}}
									/>
								) : (
									<label
										htmlFor='add_image'
										className={classNames(
											'btn',
											'btn-elevate',
											'btn-label-brand',
											'btn-hover-brand',
											'btn-sm',
											'sdms-image__upload--button',
											{ disabled: isUploading }
										)}
										title={!isUploading ? 'Add Image' : 'Uploading Image!..'}>
										<SVGIcon name='Upload' />
										<span>
											{!isUploading ? 'Upload Image' : 'Uploading Image!..'}
										</span>
										<input
											id='add_image'
											type='file'
											name='file'
											multiple
											accept='.png, .jpg, .jpeg;capture'
											onChange={handleImageUpload}
										/>
									</label>
								)}
							</Portlet.HeadToolbarActions>
						</Portlet.Head>
						<Portlet.Body>
							<div className='row'>
								<Loading isLoading={isUploading} type='media' />
								{isAdditionalSettingsOpen ? (
									<>
										<FormField name='title' col={6} label='Title'>
											<Input
												onChange={e =>
													setAdditionalSettings({
														...additionalSettings,
														title: e.target.value,
													})
												}
												value={additionalSettings.title}
												placeholder='Title'
											/>
										</FormField>
										<FormField name='alignment' col={6} label='Title Alignment'>
											<Selects
												onChange={e =>
													setAdditionalSettings({
														...additionalSettings,
														alignment: e.target.value.value,
													})
												}
												options={alignments}
												valueKey='value'
												displayKey='label'
												value={alignments.find(
													a => a.value === additionalSettings.alignment
												)}
												disableClearable
											/>
										</FormField>
										<FormField name='width' col={6} label='Width'>
											<Selects
												onChange={e =>
													setAdditionalSettings({
														...additionalSettings,
														width: e.target.value.value,
													})
												}
												options={widths}
												valueKey='value'
												displayKey='label'
												value={widths.find(
													w => w.value === additionalSettings.width
												)}
												disableClearable
											/>
										</FormField>
									</>
								) : (
									items.slice(0, itemsPerPage).map(item => {
										return (
											<div
												key={item.id}
												className='sdms-image sdms-image--item sdms-image--outline sdms-image--changed col-3'>
												<div className='sdms-image--name'>{item.name}</div>
												<div
													role='presentation'
													className={classNames('sdms-image__holder', {
														'sdms-image__holder--selected':
															selectedMedia &&
															item.id === selectedMedia.id,
													})}
													onClick={() => {
														setSelectedMedia(item);
														if (enableAdditionalSettings)
															setIsAdditionalSettingsOpen(true);
													}}
													title={item.name}
													style={{
														backgroundImage: `url(${pathToUrl(
															item.thumb
														)})`,
													}}
												/>
												{item.name !== 'default_table_map_background' && (
													<span
														className='sdms-image__cancel'
														title='Delete Image'>
														<SVGIcon
															name='Error-circle'
															size={30}
															onClick={() => setWillDeleteItem(item)}
														/>
													</span>
												)}
											</div>
										);
									})
								)}
							</div>
						</Portlet.Body>
						{!isAdditionalSettingsOpen && (
							<Portlet.Foot tall='sm'>
								<TablePagination
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									totalPage={totalPage}
									itemsPerPage={itemsPerPage}
									setItemsPerPage={setItemsPerPage}
									totalItems={totalItems}
								/>
							</Portlet.Foot>
						)}
						<Portlet.Foot tall='sm'>
							<div className='col'>
								<Button
									design='clean'
									text='Cancel'
									icon='Error-circle'
									size='sm'
									elevate
									onClick={() => {
										setIsAdditionalSettingsOpen(false);
										setAdditionalSettings({ ...additionalSettings, title: '' });
										close();
									}}
								/>
							</div>
							<div className='col-auto'>
								<Button
									design='brand'
									text={isAdditionalSettingsOpen ? 'Insert' : 'Select'}
									icon='Done-circle'
									size='sm'
									elevate
									disabled={selectedMedia === null || isUploading}
									onClick={() => {
										setIsAdditionalSettingsOpen(false);
										setAdditionalSettings({ ...additionalSettings, title: '' });
										setMedia({
											...selectedMedia,
											additionalSettings,
										});
										close();
									}}
								/>
							</div>
						</Portlet.Foot>
					</Portlet>
					<DialogBox
						open={willDeleteItem !== null}
						title='Delete'
						content={
							willDeleteItem ? (
								<span>
									Are you sure about delete <strong>{willDeleteItem.name}</strong>
									?
								</span>
							) : (
								''
							)
						}
						type='question'
						onClose={() => setWillDeleteItem(null)}>
						<Button
							className='sdms-font-transform-c'
							design='clean'
							icon='Error-circle'
							text={`No, Don't delete!`}
							onClick={() => setWillDeleteItem(null)}
						/>
						<Button
							className='sdms-font-transform-c'
							label='danger'
							icon='Trash'
							text='Yes, Delete!'
							onClick={deleteImage}
						/>
					</DialogBox>
				</>
			</Popup>
		</Portal>
	);
};

Media.propTypes = {
	setMedia: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	enableAdditionalSettings: PropTypes.bool,
	defaultSelectedMedia: PropTypes.object,
};

Media.defaultProps = {
	enableAdditionalSettings: false,
	defaultSelectedMedia: null,
};

export default Media;
