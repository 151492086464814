import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import {
	addErrorNotification,
	addSuccessNotification,
	canDeleteRefund,
	canVoidRefund,
	priceFormatter,
} from '../../../utils/helpers/helper';

import List from '../../reusables/template/List';
import Dropdown from '../../reusables/element/Dropdown';
import ListContainer from '../../reusables/template/ListContainer';
import NullBadge from '../../reusables/design/NullBadge';
import Badge from '../../reusables/element/Badge';
import forms from '../../forms';
import apiCall from '../../../utils/helpers/apiCall';
import useModal from '../../../utils/hooks/useModal';
import Button from '../../reusables/element/Button';
import DialogBox from '../../reusables/element/DialogBox';
import useDate from '../../../utils/hooks/useDate';

const CustomerCell = ({ data }) => {
	return data.customer.displayName;
};

const DateCell = ({ data }) => {
	const [dateFormatter] = useDate();

	return dateFormatter(data.refundDate || data.timeCreated, false);
};

const AmountCell = ({ data }) => {
	return priceFormatter(data.amount);
};

const PaymentTypeCell = ({ data }) => {
	if (data.paymentMethod === null) return <NullBadge />;
	return (
		<>
			<Badge design='brand' isDot>
				{data.paymentMethod ? data.paymentMethod.paymentType.value : 'Credit'}
			</Badge>
		</>
	);
};
PaymentTypeCell.propTypes = {
	data: PropTypes.shape({
		paymentMethod: PropTypes.object,
	}),
};
PaymentTypeCell.defaultProps = {
	data: { paymentMethod: null },
};

const modals = {
	VOID: 'void',
};

const RefundList = ({ history }) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const [isLoading, setIsLoading] = useState(false);

	const [modal, openModal, closeModal] = useModal();

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.crm.default.text, path: pages.crm.dashboard.path },
			{ title: pages.crm.refunds.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.crm.refunds.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	const onVoid = () => {
		setIsLoading(true);
		apiCall(
			'POST',
			'voidRefund',
			res => {
				addSuccessNotification('Refund successfully voided.');

				if (modal.updateData) modal.updateData([res]);
				closeModal();
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ id: modal.refund.id, outletId: userContext.data.selectedOutlet.id }
		);
	};

	return (
		<>
			<ListContainer
				route='refunds'
				title={pages.crm.refunds.text}
				history={history}
				checkEditable={() => []}
				forms={forms.crm.refunds}
				fluid='fluid'
				customActions={{
					void: (refund, updateData) =>
						openModal({ open: modals.VOID, refund, updateData }),
				}}>
				<List
					withCheckBox
					fluid='fluid'
					responsive='scroll'
					checkEditable={refund => {
						const actions = ['edit'];

						if (canDeleteRefund(refund)) actions.push('delete');

						if (canVoidRefund(refund)) actions.push('void');

						return actions;
					}}>
					<List.Col
						label='Refund'
						cellData='refundId'
						isLinkColumn={userContext.hasPermission('view_refunds')}
						sortable='refundId'
					/>
					<List.Col label='Customer' cellComponent={<CustomerCell />} />
					<List.Col label='Date' cellComponent={<DateCell />} sortable='refundDate' />
					<List.Col label='Amount' cellComponent={<AmountCell />} />
					<List.Col label='Reference' cellData='reference' />
					<List.Col label='Type' cellComponent={<PaymentTypeCell />} />
					<List.Col align='right' onlyHover width={150}>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item
								icon='Edit'
								text='Edit'
								key='edit'
								noPermission={!userContext.hasPermission('edit_refunds')}>
								Edit
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='danger'
								icon='Error-circle'
								key='void'
								noPermission={!userContext.hasPermission('edit_refunds')}>
								Void
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='danger'
								icon='Trash'
								text='Delete'
								key='delete'
								noPermission={!userContext.hasPermission('delete_refunds')}>
								Delete
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			<DialogBox
				open={modal.open === modals.VOID}
				title='Void'
				content='Are you sure you want to void this refund?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't Void!`}
					onClick={closeModal}
					disabled={isLoading}
				/>
				<Button
					className='sdms-font-transform-c'
					label='danger'
					icon='Trash'
					text='Yes, Void!'
					onClick={onVoid}
					disabled={isLoading}
				/>
			</DialogBox>
		</>
	);
};
RefundList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default RefundList;
