import React, { useContext, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import tinyColor from 'tinycolor2';
import classNames from 'classnames';
import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';
import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import useField from '../../../utils/hooks/useField';
import Loading from '../../reusables/template/Loading';
import ThemeContext from '../../../app/contexts/ThemeContext';
import Badge from '../../reusables/element/Badge';
import { setTheme, numberParser } from '../../../utils/helpers/helper';
import Button from '../../reusables/element/Button';
import Selects from '../../reusables/field/Selects';

const ColorCell = ({ text, color, fillColor }) => {
	return (
		<div
			className={classNames(
				'col-lg',
				'col-md-3',
				'sdms-font-sm',
				'd-flex',
				'align-items-center',
				'justify-content-center',
				{ [`sdms-bg-fill-${fillColor}`]: fillColor }
			)}
			style={{
				height: 50,
				backgroundColor: tinyColor(`${color}`)
					.lighten(text)
					.toString(),
				color: tinyColor(`${color}`)
					.lighten(text)
					.isLight()
					? '#000'
					: '#fff',
			}}>
			<span>
				{fillColor ||
					`rgb(${
						tinyColor(`${color}`)
							.lighten(text)
							.toRgb().r
					}, ${
						tinyColor(`${color}`)
							.lighten(text)
							.toRgb().g
					}, ${
						tinyColor(`${color}`)
							.lighten(text)
							.toRgb().b
					})`}
			</span>
		</div>
	);
};
ColorCell.propTypes = {
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	color: PropTypes.string,
	fillColor: PropTypes.string,
};
ColorCell.defaultProps = {
	text: 0,
	color: '#000',
	fillColor: null,
};

const PersonalizationForm = ({
	data,
	onFormChange,
	isLoading,
	setIsValid,
	submit,
	submitButtonAttr,
	enumThemes,
}) => {
	const headerContext = useContext(HeaderContext);
	const themeContext = useContext(ThemeContext);

	const [brightness, brightnessOnChange] = useField(
		data,
		'brightness',
		onFormChange,
		[],
		themeContext.data.brightness,
		numberParser(true)
	);

	const [contrast, contrastOnChange] = useField(
		data,
		'contrast',
		onFormChange,
		[],
		themeContext.data.contrast,
		numberParser(true)
	);

	const [theme, themeOnChange] = useField(
		data,
		'theme',
		onFormChange,
		[],
		enumThemes[enumThemes.findIndex(et => et.value === themeContext.data.theme)]
	);

	useEffect(() => {
		setIsValid(!isLoading);
	}, [isLoading, setIsValid]);

	useLayoutEffect(() => {
		if (brightness !== 100 || contrast !== 100) {
			document.documentElement.style.filter = `brightness(${brightness}%) contrast(${contrast}%)`;
		}
	});

	useEffect(() => {
		setTheme(
			theme
				? theme.value
				: document.documentElement.attributes.theme &&
						document.documentElement.attributes.theme.value
		);
	}, [theme]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.systemSettings.default.text,
				path: pages.systemSettings.dashboard.path,
			},
			{ title: 'Personalization', isActive: true },
		]);

		headerContext.setPageTitle('Personalization');
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	const getColorDarkWhiteCells = () => {
		const cells = [];
		let i = 0;
		do {
			i += 10;
			cells.push(<ColorCell key={`dark${i}`} text={i} />);
		} while (i < 100);

		return cells;
	};

	const getColorWhiteCells = () => {
		const cells = [];
		let i = 90;
		do {
			i += 1;
			cells.push(<ColorCell key={`white${i}`} text={i} />);
		} while (i < 100);

		return cells;
	};

	return (
		<Portlet className='sdms-form' fluid='fluid' hasFrame>
			<Portlet.Body>
				<form>
					<Section title='Black to White'>
						<Section.Body>
							<div className='row sdms-marginless'>{getColorDarkWhiteCells()}</div>
						</Section.Body>
					</Section>
					<Section title='90% White to 100% White'>
						<Section.Body>
							<div className='row sdms-marginless'>{getColorWhiteCells()}</div>
						</Section.Body>
					</Section>
					<Section title='Theme Colors'>
						<Section.Body>
							<div className='row sdms-marginless'>
								<ColorCell fillColor='brand' />
								<ColorCell fillColor='primary' />
								<ColorCell fillColor='dark' />
								<ColorCell fillColor='light' />
								<ColorCell fillColor='info' />
								<ColorCell fillColor='success' />
								<ColorCell fillColor='warning' />
								<ColorCell fillColor='danger' />
							</div>
							<div className='sdms-mb-20' />
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='theme'
										label='Theme'
										id={data.id}
										colMd={3}
										description='sharper or modern'>
										<Selects
											options={enumThemes}
											placeholder='Theme'
											value={theme}
											onChange={themeOnChange}
											displayKey='value'
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='General'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='brightness'
										label={
											<>
												Brightness
												<Badge
													className='sdms-ml-10'
													design='info'
													isInline
													isUnified>
													{brightness - 100}%
												</Badge>
											</>
										}
										description='-50% to +50%'
										id={data.id}
										colMd={6}>
										<Input
											type='range'
											min={50}
											max={150}
											value={brightness.toString()}
											onChange={brightnessOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='contrast'
										label={
											<>
												Contrast
												<Badge
													className='sdms-ml-10'
													design='info'
													isInline
													isUnified>
													{contrast - 100}%
												</Badge>
											</>
										}
										description='-50% to +50%'
										id={data.id}
										colMd={6}>
										<Input
											type='range'
											min={50}
											max={150}
											value={contrast.toString()}
											onChange={contrastOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
PersonalizationForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		brightness: PropTypes.number,
		contrast: PropTypes.number,
	}),
	onFormChange: PropTypes.func,
	isLoading: PropTypes.bool,
	setIsValid: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
	enumThemes: PropTypes.arrayOf(PropTypes.object),
};
PersonalizationForm.defaultProps = {
	data: {
		id: 0,
	},
	onFormChange: () => {},
	isLoading: false,
	setIsValid: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
	enumThemes: [],
};

export default PersonalizationForm;
