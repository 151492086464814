import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const BadgeInner = ({
	children,
	onClick,
	onMouseDown,
	design,
	size,
	fontWeight,
	className,
	isDot,
	isUnified,
	isRounded,
	isInline,
	isElevate,
	isOutline,
	isSpaced,
	style,
}) => (
	<span
		onClick={e => {
			e.preventDefault();
			onClick();
		}}
		onMouseDown={e => {
			e.preventDefault();
			onMouseDown();
		}}
		role='presentation'
		className={classNames(
			{ 'sdms-font-bold': isDot },
			isDot && !isUnified ? `sdms-font-${design}` : `sdms-badge--${design}`,
			{ 'sdms-badge': !isDot },
			{ 'sdms-badge--rounded': isRounded },
			{ 'sdms-badge--inline': isInline },
			{ 'sdms-badge--elevate': isElevate },
			{ 'sdms-badge--outline': isOutline },
			{ 'sdms-badge--spaced': isSpaced },
			{ 'sdms-badge--pill': isInline && !isRounded },
			{ [`sdms-badge--unified-${design}`]: isUnified },
			{ [`sdms-badge--${size}`]: size },
			{ [`sdms-badge--${fontWeight}`]: fontWeight },
			{ [`${className}`]: !isDot && className }
		)}
		style={style}>
		{children}
	</span>
);

BadgeInner.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	design: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'info',
		'primary',
		'secondary',
		'success',
		'warning',
	]),
	isDot: PropTypes.bool,
	isRounded: PropTypes.bool,
	isInline: PropTypes.bool,
	isUnified: PropTypes.bool,
	size: PropTypes.oneOf(['sm', 'lg', 'xl']),
	fontWeight: PropTypes.oneOf(['bold', 'bolder', 'boldest']),
	isElevate: PropTypes.bool,
	isOutline: PropTypes.bool,
	isSpaced: PropTypes.bool,
	onClick: PropTypes.func,
	onMouseDown: PropTypes.func,
};
BadgeInner.defaultProps = {
	className: null,
	children: null,
	design: 'info',
	isDot: false,
	isRounded: false,
	isInline: false,
	isUnified: false,
	size: null,
	fontWeight: null,
	isElevate: false,
	isOutline: false,
	isSpaced: false,
	onClick: () => {},
	onMouseDown: () => {},
};

const Badge = ({
	className,
	children,
	design,
	isDot,
	size,
	isRounded,
	isInline,
	isUnified,
	fontWeight,
	isElevate,
	isOutline,
	onClick,
	onMouseDown,
	isSpaced,
	style,
}) => {
	if (isDot && children) {
		return (
			<span className={className} onClick={onClick} role='presentation'>
				<span
					className={classNames(
						'sdms-badge',
						'sdms-badge--dot',
						`sdms-badge--${design}`,
						{ [`sdms-badge--${size}`]: size }
					)}
				/>
				<BadgeInner
					onClick={onClick}
					onMouseDown={onMouseDown}
					design={design}
					size={size}
					fontWeight={fontWeight}
					className={className}
					isDot={isDot}
					isUnified={isUnified}
					isRounded={isRounded}
					isInline={isInline}
					isElevate={isElevate}
					isOutline={isOutline}
					isSpaced={isSpaced}
					style={style}>
					{children}
				</BadgeInner>
			</span>
		);
	}
	if (isDot) {
		return (
			<span
				className={classNames('sdms-badge', 'sdms-badge--dot', `sdms-badge--${design}`, {
					[`sdms-badge--${size}`]: size,
				})}
				style={style}
			/>
		);
	}
	return (
		<BadgeInner
			onClick={onClick}
			onMouseDown={onMouseDown}
			design={design}
			size={size}
			fontWeight={fontWeight}
			className={className}
			isDot={isDot}
			isUnified={isUnified}
			isRounded={isRounded}
			isInline={isInline}
			isElevate={isElevate}
			isOutline={isOutline}
			isSpaced={isSpaced}
			style={style}>
			{children}
		</BadgeInner>
	);
};
Badge.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	design: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'info',
		'primary',
		'secondary',
		'success',
		'warning',
		'orange',
		'light-danger',
		'light-success',
		'light-warning',
		'alert',
	]),
	isDot: PropTypes.bool,
	isRounded: PropTypes.bool,
	isInline: PropTypes.bool,
	isUnified: PropTypes.bool,
	size: PropTypes.oneOf(['sm', 'lg', 'xl']),
	fontWeight: PropTypes.oneOf(['bold', 'bolder', 'boldest']),
	isElevate: PropTypes.bool,
	isOutline: PropTypes.bool,
	isSpaced: PropTypes.bool,
	onClick: PropTypes.func,
	onMouseDown: PropTypes.func,
};
Badge.defaultProps = {
	className: null,
	children: null,
	design: 'info',
	isDot: false,
	isRounded: false,
	isInline: false,
	isUnified: false,
	size: null,
	fontWeight: null,
	isElevate: false,
	isOutline: false,
	isSpaced: false,
	onClick: () => {},
	onMouseDown: () => {},
};

const Yes = () => (
	<Badge design='success' isInline isUnified size='lg' fontWeight='boldest'>
		Yes
	</Badge>
);

const No = () => (
	<Badge design='danger' isInline isUnified size='lg' fontWeight='boldest'>
		No
	</Badge>
);

Badge.Yes = Yes;
Badge.No = No;

export default Badge;
