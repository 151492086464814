import React, { useContext, useRef } from 'react';

import { themes } from '../../../utils/constants/constants';
import pages from '../../pages';
import UserContext from '../../../app/contexts/UserContext';
import ThemeContext from '../../../app/contexts/ThemeContext';

import PersonalizationForm from './PersonalizationForm';
import FormContainer from '../../reusables/template/FormContainer';

const PersonalizationEdit = () => {
	const userContext = useContext(UserContext);

	const themeContext = useContext(ThemeContext);

	const themeContextBackup = useRef(themeContext.data);

	return (
		<FormContainer
			dataId={
				userContext.data.user.company.systemSettings
					? userContext.data.user.company.systemSettings.id.toString()
					: 0
			}
			pageTitle={pages.systemSettings.personalization.text}
			dataName={pages.systemSettings.personalization.route}
			fields={<PersonalizationForm />}
			showBackButton={false}
			lists={['enumThemes']}
			afterSubmit={data => {
				themeContext.setData({
					brightness: data.brightness || 100,
					contrast: data.contrast || 100,
					theme: data.theme.value || themes.SHARPER,
				});
				themeContextBackup.current = null;
			}}
		/>
	);
};
export default PersonalizationEdit;
