import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import PayrollHoursForm from './PayrollHoursForm';

const PayrollHoursEdit = ({ id, afterSubmit, onCancel }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={id.toString()}
			dataName='userShifts'
			fields={<PayrollHoursForm />}
			lists={['users']}
			pageTitle={pages.accounting.payrollHours.text}
			icon={pages.accounting.payrollHours.icon}
			isQuickPanel
			afterSubmit={afterSubmit}
			onCancel={onCancel}
		/>
	);
};

PayrollHoursEdit.propTypes = {
	id: PropTypes.number.isRequired,
	afterSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export default PayrollHoursEdit;
