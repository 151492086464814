import React from 'react';
import {
	AccumulationChartComponent,
	AccumulationSeriesCollectionDirective,
	AccumulationSeriesDirective,
	Inject,
	AccumulationLegend,
	PieSeries,
	AccumulationTooltip,
	AccumulationDataLabel,
} from '@syncfusion/ej2-react-charts';

import PropTypes from 'prop-types';

const CumulativeChart = ({ data, type }) => {
	return (
		<AccumulationChartComponent
			style={{ height: '100%' }}
			enableSmartLabels
			center={{ x: '50%', y: '50%' }}
			enableBorderOnMouseMove={false}
			tooltip={{
				// eslint-disable-next-line no-template-curly-in-string
				format: '${point.x}: ${point.y}%',
				enable: true,
			}}
			width='100%'
			height='100%'
			legendSettings={{ visible: false }}>
			<Inject
				services={[
					AccumulationLegend,
					PieSeries,
					AccumulationTooltip,
					AccumulationDataLabel,
				]}
			/>
			<AccumulationSeriesCollectionDirective>
				<AccumulationSeriesDirective
					dataSource={data}
					xName='x'
					yName='y'
					startAngle={60}
					dataLabel={{
						visible: true,
						position: 'Outside',
						name: 'text',
						font: { fontWeight: '600' },
						connectorStyle: { length: '20px', type: 'Straight' },
					}}
					innerRadius={type === 'Doughnut' ? '50%' : '0%'}
					radius='75%'
					legendShape='Circle'
				/>
			</AccumulationSeriesCollectionDirective>
		</AccumulationChartComponent>
	);
};
export default CumulativeChart;

CumulativeChart.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object),
	type: PropTypes.string,
};

CumulativeChart.defaultProps = {
	data: [],
	type: 'Line',
};
