import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { pathToUrl } from '../../../../../utils/helpers/apiCall';
import { _defaultImage } from '../../../field/ImageUpload';

const ItemMultiImage = ({ images }) => {
	const [imageIndex, setImageIndex] = useState(0);

	const handleClick = () => setImageIndex(imageIndex + 1 >= images.length ? 0 : imageIndex + 1);

	const image = images.length > imageIndex ? images[imageIndex] : null;

	return (
		<div
			className='sdms-booking-item__image sdms-booking-item__image-multi'
			style={{
				backgroundImage: image ? `url(${pathToUrl(image)})` : `${_defaultImage}`,
				backgroundSize: image ? null : '120%',
			}}
			onClick={handleClick}
			role='presentation'>
			{images.length > 1 && (
				<div className='sdms-booking-item__image-multi-indicator'>
					{`${imageIndex + 1}/${images.length}`}
				</div>
			)}
		</div>
	);
};
ItemMultiImage.propTypes = {
	images: PropTypes.array,
};
ItemMultiImage.defaultProps = {
	images: [],
};

export default ItemMultiImage;
