import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import Popup from 'reactjs-popup';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment/moment';

import UserContext from '../../../../../app/contexts/UserContext';
import HeaderContext from '../../../../../app/contexts/HeaderContext';
import {
	paymentTypes as _paymentTypes,
	bookingPeriods,
	mediaBreakpoint,
	reservationReceiptTypes,
	reservationStatuses,
} from '../../../../../utils/constants/constants';
import pages from '../../../../pages';
import {
	addErrorNotification,
	addSuccessNotification,
	generateId,
	getItemTaxCode,
	useWindowSize,
} from '../../../../../utils/helpers/helper';
import apiCall, { modules, parseData } from '../../../../../utils/helpers/apiCall';
// eslint-disable-next-line import/namespace,import/default,import/no-named-as-default,import/no-named-as-default-member
import WebPrint from '../../../print/WebPrint';
import usePrint from '../../../../../utils/hooks/usePrint';
import useModal from '../../../../../utils/hooks/useModal';
import useSendInvoice from '../../../../../utils/hooks/useSendInvoice';
import {
	getDefaultCreditCard,
	getDefaultPaymentMethod,
	getDefaultReservation,
	getItemRefundableAmount,
	getPaymentTypes,
	getRefundableAmount,
	getReservationTotals,
	onExtraChargeItemUpdate,
	printReservationReceipt,
	updateItemTaxCode,
	getReservationSettlements,
	hasReservationInvoice,
} from '../../../../../utils/helpers/reservationHelper';
import {
	bookingPeriodPageKeys,
	getBookingPeriodByPath,
	getModuleByPath,
	getReservationGridCustomFilters,
	getServiceFeeModule,
	modulePageKeys,
} from '../../../../../utils/helpers/reusable';
import ContentInner from '../../../template/ContentInner';
import BreadcrumbContainer from '../../../template/BreadcrumbContainer';
import Portlet from '../../../layout/Portlet';
import Button from '../../../element/Button';
import ReservationAdvanceGrid from '../../../element/ReservationAdvanceGrid';
import CustomerModal from '../../../modals/CustomerModal';
import PayModal from '../../../modals/PayModal';
import Portal from '../../../layout/Portal';
import DialogBox from '../../../element/DialogBox';
import PrinterModal from '../../../modals/PrinterModal';
import SVGIcon from '../../../element/SVGIcon';
import QuantityModal from '../../../modals/QuantityModal';

import Reservation from '../../../element/Reservation';
import Contract from '../modals/Contract';
import Checkout from '../modals/Checkout';
import ReservationExtraCharge from '../modals/ReservationExtraCharge';

import ReservationAdvancedTransientItemForm from './ReservationAdvancedTransientItemForm';
import ReservationAdvancedNonTransientItemForm from './ReservationAdvancedNonTransientItemForm';

import ReservationAdvancedTransientItemFormLoading from './ReservationAdvancedTransientItemFormLoading';
import ReservationAdvancedNonTransientItemFormLoading from './ReservationAdvancedNonTransientItemFormLoading';
import InvoiceList from '../InvoiceList';
import PaymentList from '../PaymentList';

import PaymentScheduleSelectModal from '../../../modals/PaymentScheduleSelect';
import CustomerPaymentMethodSelect from '../../../modals/CustomerPaymentMethodSelect';
import MessagesButton from '../../../element/MessagesButton';
import Messages from '../../../modals/Messages';
import AttachmentModal from '../../../modals/AttachmentModal';
import MessageContext from '../../../../../app/contexts/MessageContext';
import AuthorizePaymentModal from '../../../modals/AuthorizePaymentModal';
import Textarea from '../../../field/Textarea';
import {
	hasEditLockedReservationPermission,
	hasRefundReservationPaymentPermission,
} from '../../../../../utils/helpers/permission';

const modals = {
	CANCEL: 'cancel',
	CANCELANDREFUND: 'cancel&refund',
	ITEM_CANCEL: 'itemCancel',
	ITEM_ONLY_REFUND: 'itemOnlyRefund',
	ITEM_SELECT: 'itemSelect',
	CUSTOMER: 'customer',
	BACK: 'back',
	PAY: 'pay',
	DEPOSIT: 'deposit',
	NOTE: 'note',
	UNSAVED: 'unsaved',
	REFUND: 'refund',
	NON_TRANSIENT_REFUND: 'non_transient_refund',
	ITEM_REFUND: 'itemRefund',
	PRINTER: 'printer',
	PAYMENT_REFUND: 'paymentRefund',
	PAYMENT_VOID_DIALOG: 'paymentVoidDialog',
	PAYMENT_CAPTURE_DIALOG: 'paymentCaptureDialog',
	OVERPAYMENT_REFUND: 'overPaymentRefund',
	RETAIN_DIALOG: 'retainDialog',
	RETAIN: 'retain',
	RELOAD: 'reload',
	CHECKOUT: 'checkout',
	CONTRACT: 'contract',
	PAYMENT_REFUND_VOID: 'payment_refund_void',
	EXTRA_CHARGE: 'extra_charges',
	PAYMENT_SCHEDULE: 'payment_schedule',
	CUSTOMER_PAYMENT_METHOD: 'customer_payment_method',
	MESSAGES: 'messages',
	ATTACHMENTS: 'attachments',
	SEND_EMAIL: 'send_email',
	AUTHORIZE_PAYMENT: 'authorize_payment',
	MANUALLY_EDITED_INVOICE_WARNING: 'manually_edited_invoice_warning',
	CAPTURE_AUTHORIZED_PAYMENT: 'capture_authorized_payment',
	VOID_AUTHORIZED_PAYMENT: 'void_authorized_payment',
};

const extraContents = {
	INVOICES: 'invoices',
	REMITTANCES: 'remittances',
};

const ReservationAdvanceForm = ({
	data,
	isLoading,
	isListsLoading,
	itemId,
	policies,
	paymentTypes,
	ratePlans,
	enumReservationStatuses,
	contracts,
	from,
	paymentTerms,
	countries,
	states,
	enumBoatTypes,
	enumBoatMakes,
	enumRvTypes,
	enumVehicleMakes,
	invoiceFirstOptions,
	invoiceNextOptions,
	bookingExtraCharges,
	invoicingFrequencies,
	printers,
	printerLogActions,
	searchData,
	enumBookingCalculations,
	accounts,
	seasons,
	taxCodes,
	paymentSchedules,
	paymentMethods,
	customReservationStatuses,
}) => {
	const windowSize = useWindowSize();

	const [innerPageNavStatus, setInnerPageNavStatus] = useState(false);

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const messageContext = useContext(MessageContext);

	const history = useHistory();

	const location = useLocation();

	const defaultReservation = useRef();

	const { sendInvoice } = useSendInvoice();

	const form = useMemo(() => {
		const { pathname } = location;

		const module = userContext.data.user.company.modules.find(
			m => m.value === getModuleByPath(pathname)
		);

		const bookingPeriod = getBookingPeriodByPath(pathname);

		defaultReservation.current = getDefaultReservation(
			userContext.data,
			module,
			userContext.data.selectedOutlet
		);

		return {
			isBooking: module.value === modules.BOOKINGS,
			isMarina: module.value === modules.MARINA,
			isCampground: module.value === modules.CAMPGROUND,
			isLongTerm: bookingPeriod === bookingPeriods.LONG_TERM,
			isSeasonal: bookingPeriod === bookingPeriods.SEASONAL,
			isTransient: !(
				bookingPeriod === bookingPeriods.LONG_TERM ||
				bookingPeriod === bookingPeriods.SEASONAL
			),
			module,
			bookingPeriod,
			modulePage: pages[modulePageKeys[module.value]],
			page: pages[modulePageKeys[module.value]][bookingPeriodPageKeys[bookingPeriod]],
		};
	}, [location, userContext.data]);

	const isItemLoading = useRef(true);

	const webPrint = useRef({
		deposit: () => {},
		reservationRefund: () => {},
		reservationPayment: () => {},
		printers: [],
	});

	const taxRateRef = useRef(userContext.data.selectedOutlet.settings.taxRate);

	const [activeReservationItem, setActiveReservationItem] = useState(null);

	const [reservation, setReservation] = useState(defaultReservation.current);

	const [reservationHasChange, setReservationHasChange] = useState(false);

	const [reservationItemHasChange, setReservationItemHasChange] = useState(false);

	const [isPaying, setIsPaying] = useState(false);

	const [selectedItems, setSelectedItems] = useState([]);

	const currentExtraChargeItemProductId = useRef(0);

	const [modal, openModal, closeModal] = useModal();

	const [onPrint, PRINT_COMPONENT] = usePrint();

	const [isExtraChargeSubmitting, setIsExtraChargeSubmitting] = useState(false);

	const [extraContent, setExtraContent] = useState('');

	const [cancellationReason, setCancellationReason] = useState('');

	const onFormChange = () => setReservationHasChange(true);

	const onBack = () => {
		if (from === 'availability') history.push(form.modulePage.availability.path);
		else if (from === 'dashboard') history.push(form.modulePage.dashboard.path);
		else history.push(form.modulePage.reservations.path);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	const addPosExtraChargeItem = (_itemId, product, _reservation = null) => {
		const itemIndex = reservation.items.findIndex(i => i.id === _itemId);

		const productExtraChargesItemIndex = reservation.items[
			itemIndex
		].product.productExtraCharges.findIndex(pec => pec.id === product.id);

		if (!_reservation) _reservation = { ...reservation };

		if (productExtraChargesItemIndex === -1)
			_reservation = update(_reservation, {
				items: {
					[itemIndex]: {
						product: {
							productExtraCharges: {
								$push: [product],
							},
						},
					},
				},
			});

		currentExtraChargeItemProductId.current = product.id;

		setReservation(_reservation);

		setActiveReservationItem(_reservation.items.find(i => i.id === _itemId));

		onFormChange();
	};

	const onAddItem = (product, _reservation = null) => {
		if (product['@type'] === 'SdmsProductTransient') {
			const _data = {
				id: generateId(reservation.items),
				product,
				status: enumReservationStatuses.find(
					ers => ers.value === reservationStatuses.RESERVED
				),
				enableDeferredIncome: product.enableDeferredIncome,
				deferredIncomeFrequency: product.deferredIncomeFrequency,
				deferredIncomeSalesAccount: product.deferredIncomeSalesAccount,
				taxCode: getItemTaxCode(
					reservation.customer ? reservation.customer.taxCode : null,
					product
				),
			};

			setActiveReservationItem(
				form.isBooking
					? _data
					: {
							..._data,
							vehicle: searchData && searchData.vehicle ? searchData.vehicle : null,
							vessel: searchData && searchData.vessel ? searchData.vessel : null,
							loa: searchData && searchData.loa ? searchData.loa : null,
							beam: searchData && searchData.beam ? searchData.beam : null,
							draft: searchData && searchData.draft ? searchData.draft : null,
							height: searchData && searchData.height ? searchData.height : null,
							weight: searchData && searchData.weight ? searchData.weight : null,
							fromDate:
								searchData && searchData.startDate ? searchData.startDate : null,
							toDate: searchData && searchData.endDate ? searchData.endDate : null,
							fixedPrice: false,
							invoiceGenerationAutomatedBatch:
								product.invoiceGenerationAutomatedBatch === null
									? true
									: product.invoiceGenerationAutomatedBatch,
							invoicingFrequency: product.invoicingFrequency,
							paymentTerm: product.paymentTerm,
							proratedFrequency: product.proratedFrequency,
							coTermDate: product.coTermDate,
							autoPay:
								userContext.data.selectedOutlet.settings &&
								userContext.data.selectedOutlet.settings.autoPayCampground,
							firstInvoice: product.firstInvoice,
							nextInvoice: product.nextInvoice,
							ignoredCapacity: searchData !== null,
							unit: searchData && searchData.unit ? searchData.unit : null,
							season: searchData && searchData.season ? searchData.season : null,

							id: generateId(reservation.items),
							product,
							status: enumReservationStatuses.find(
								ers => ers.value === reservationStatuses.RESERVED
							),
							taxCode: getItemTaxCode(
								reservation.customer ? reservation.customer.taxCode : null,
								product
							),
							enableDeferredIncome: product.enableDeferredIncome,
							deferredIncomeFrequency: product.deferredIncomeFrequency,
							deferredIncomeSalesAccount: product.deferredIncomeSalesAccount,
					  }
			);
			return;
		}

		if (
			reservation.items.length === 0 ||
			reservation.items.filter(
				ri =>
					ri.status.value !== reservationStatuses.CANCELLED &&
					ri.status.value !== reservationStatuses.CHECKED_OUT
			).length === 0
		)
			return;

		if (
			reservation.items.filter(
				ri =>
					ri.status.value !== reservationStatuses.CANCELLED &&
					ri.status.value !== reservationStatuses.CHECKED_OUT
			).length > 1
		) {
			openModal({ open: modals.ITEM_SELECT, product });
			return;
		}

		addPosExtraChargeItem(
			reservation.items.filter(
				ri =>
					ri.status.value !== reservationStatuses.CANCELLED &&
					ri.status.value !== reservationStatuses.CHECKED_OUT
			)[0].id,
			product,
			_reservation
		);

		onFormChange();
	};

	const onItemUpdate = _reservation => {
		setReservation(_reservation);
		setActiveReservationItem(form.isTransient ? null : _reservation.items[0]);
		setReservationHasChange(false);
		setReservationItemHasChange(false);
		currentExtraChargeItemProductId.current = 0;
		onFormChange();
	};

	const openPrinterModal = (_reservation, printData, type) =>
		openModal({
			open: modals.PRINTER,
			reservation: _reservation,
			printData,
			type,
		});

	const getCustomerWithCreditCards = () => {
		if (modal.open === modals.PAYMENT_REFUND && modal.payment) {
			if (modal.payment.paymentMethod.paymentType.value !== _paymentTypes.CREDIT_CARD)
				return reservation.customer;

			if (
				reservation.customer.paymentMethods.findIndex(
					cc => cc.cardNumber === modal.payment.cardNumber
				) !== -1 ||
				!modal.payment.processor
			)
				return reservation.customer;

			const _customer = { ...reservation.customer };

			_customer.paymentMethods.push({
				...modal.payment,
				id: 0,
			});

			return _customer;
		}

		const customer = { ...reservation.customer };

		getReservationSettlements(reservation)
			.filter(
				cs =>
					cs.remittance.processor &&
					cs.remittance.paymentMethod &&
					cs.remittance.paymentMethod.paymentType.value === _paymentTypes.CREDIT_CARD &&
					cs.remittance.token
			)
			.forEach(cs => {
				if (
					customer.paymentMethods.findIndex(
						cc => cc.cardNumber === cs.remittance.cardNumber
					) === -1
				) {
					customer.paymentMethods.push({
						...cs.remittance,
						id: 0,
					});
				}
			});

		return customer;
	};

	const onSave = (onSuccess = null, _reservation = null, onError = null) => {
		if (!reservation.customer) {
			addErrorNotification('Please select a customer');
			openModal({ open: modals.CUSTOMER });
			return;
		}

		const __reservation = _reservation || reservation;

		const formData = JSON.parse(
			JSON.stringify({ ...__reservation, items: __reservation.items.filter(i => i.id > 0) })
		);

		formData.module = form.module;

		apiCall(
			reservation.id ? 'PUT' : 'POST',
			'reservations',
			res => {
				if (onSuccess) onSuccess(res);
				else addSuccessNotification('Reservation successfully saved!');
				setReservation(res);
				setReservationHasChange(false);
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				if (onError) onError(e);
			},
			reservation.id || '',
			parseData(formData)
		);
	};

	const checkForOverride = (onSuccess = null, onError = null) => {
		if (!reservation.id) {
			if (onSuccess) onSuccess();

			return;
		}
		setIsPaying(true);

		apiCall(
			'POST',
			'reservationCheckForOverride',
			res => {
				setIsPaying(false);
				if (onSuccess) onSuccess(res);
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				if (onError) onError(e);
				setIsPaying(false);
			},
			'',
			{
				reservationId: reservation.id,
				timeModified: reservation.timeModified,
			}
		);
	};

	const onCancel = (refund = null) => {
		setIsPaying(true);
		apiCall(
			'POST',
			'advancedReservationCancel',
			() => {
				addSuccessNotification('Reservation successfully cancelled');
				onReload(true);
				setCancellationReason('');
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsPaying(false);
			},
			'',
			{
				reservation,
				refund,
				cancellationReason,
			}
		);
	};

	const onItemCancel = (reservationItemIds, refund) => {
		setIsPaying(true);
		apiCall(
			'POST',
			'advancedReservationCancelItem',
			res => {
				addSuccessNotification('Booking successfully cancelled');
				onReload(false);
				closeModal();
				setCancellationReason('');

				if (res.refunds.length)
					printReservationReceipt(
						webPrint,
						res.reservations[0],
						res.refunds.map(_refund => {
							if (!_refund.paymentMethod)
								_refund.paymentMethod = {
									name: 'Credit',
									paymentType: { value: 'Credit' },
								};
							return _refund;
						}),
						reservationReceiptTypes.REFUND,
						null,
						openPrinterModal
					);
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsPaying(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reservationItems: reservationItemIds,
				reservations: [reservation],
				isAdvanced: true,
				refund,
				cancellationReason,
			}
		);
	};

	const onCheckIn = reservationItemIds => {
		apiCall(
			'POST',
			'reservationCheckIn',
			res => {
				setActiveReservationItem(null);
				if (res.errors && res.errors.length) {
					addErrorNotification(res.errors.join('\n'));
				} else {
					addSuccessNotification('Successfully checked-in');
					onReload(false);
					setSelectedItems([]);
				}

				if (Object.keys(res.contracts).length)
					openModal({ open: modals.CONTRACT, contracts: res.contracts });
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsPaying(false);
			},
			'',
			{
				reservationItems: reservationItemIds,
				outletId: userContext.data.selectedOutlet.id,
				isAdvance: true,
				reservations: [reservation],
			}
		);
	};

	const onCheckOut = reservationItemIds => {
		apiCall(
			'POST',
			'reservationCheckOut',
			res => {
				setActiveReservationItem(null);
				if (res.errors && res.errors.length) {
					addErrorNotification(res.errors.join('\n'));
				} else {
					setReservation(res.reservations[0]);
					addSuccessNotification('Successfully checked-out');
					setSelectedItems([]);
				}

				if (Object.keys(res.contracts).length)
					openModal({ open: modals.CONTRACT, contracts: res.contracts });
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsPaying(false);
			},
			'',
			{
				reservationItems: reservationItemIds,
				outletId: userContext.data.selectedOutlet.id,
				isAdvance: true,
				reservations: [reservation],
			}
		);
	};

	const onItemUpdateStatus = (reservationItemIds, status) => {
		setIsPaying(true);
		apiCall(
			'POST',
			'advancedReservationUpdateStatus',
			res => {
				closeModal();
				setReservation(res.reservation);
				setActiveReservationItem(form.isTransient ? null : res.reservation.items[0]);
				setSelectedItems([]);
				onFormChange();
				addSuccessNotification('Booking(s) successfully updated');
				setIsPaying(false);

				if (Object.keys(res.contracts).length)
					openModal({ open: modals.CONTRACT, contracts: res.contracts });
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsPaying(false);
			},
			'',
			{
				reservationItems: reservationItemIds,
				reservation,
				status,
			}
		);
	};

	const onItemUpdateCustomStatus = ({
		reservationItemIds,
		statusId,
		sendStatusEmail = null,
	} = {}) => {
		const customStatus = customReservationStatuses.find(item => item.id === statusId);

		if (
			!customStatus.enableAutoSend &&
			customStatus.emailTemplate &&
			sendStatusEmail === null
		) {
			openModal({ open: modals.SEND_EMAIL, params: { reservationItemIds, statusId } });
			return;
		}

		setIsPaying(true);
		apiCall(
			'POST',
			'advancedReservationUpdateCustomStatus',
			res => {
				closeModal();
				setReservation(res.reservation);
				setActiveReservationItem(form.isTransient ? null : res.reservation.items[0]);
				setSelectedItems([]);
				onFormChange();
				addSuccessNotification('Booking(s) successfully updated');
				setIsPaying(false);

				if (Object.keys(res.contracts).length)
					openModal({ open: modals.CONTRACT, contracts: res.contracts });
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsPaying(false);
			},
			'',
			{
				reservationItems: reservationItemIds,
				reservation,
				statusId,
				sendStatusEmail,
			}
		);
	};

	const onDepositPay = payment => {
		setIsPaying(true);
		apiCall(
			'POST',
			'payDeposit',
			res => {
				onReload();

				if (res.payment && payment.printReceipt)
					printReservationReceipt(
						webPrint,
						res.reservation,
						{
							...res.payment,
							amount: res.payment.amount - (res.payment.serviceFee || 0),
						},
						reservationReceiptTypes.DEPOSIT,
						null,
						openPrinterModal
					);
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsPaying(false);
				closeModal();
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reservationId: reservation.id,
				customerId: reservation.customer.id,
				payment,
			}
		);
	};

	const onPay = paymentData => {
		setIsPaying(true);
		const { isTransient } = form;
		apiCall(
			'POST',
			isTransient ? 'payReservation' : 'payNonTransientReservation',
			res => {
				onReload();
				if (res.payment && paymentData.printReceipt)
					printReservationReceipt(
						webPrint,
						res.reservation,
						{
							...res.payment,
							amount: res.payment.amount - (res.payment.serviceFee || 0),
						},
						reservationReceiptTypes.PAYMENT,
						null,
						openPrinterModal
					);
			},
			err => {
				setIsPaying(false);
				addErrorNotification(err.toString());
				closeModal();
			},
			'',
			isTransient
				? {
						outletId: userContext.data.selectedOutlet.id,
						reservationId: reservation.id,
						customerId: reservation.customer.id,
						payment: paymentData,
				  }
				: {
						payment: paymentData,
						reservation,
						reservationItem: reservation.items[0].id,
				  }
		);
	};

	const onPaymentRefund = (payment, refund = null) => {
		setIsPaying(true);

		apiCall(
			'POST',
			'advancedReservationRefund',
			res => {
				onReload(false);
				closeModal();

				if (res.refunds.length > 0)
					printReservationReceipt(
						webPrint,
						res.reservation,
						res.refunds.map(_refund => {
							if (!_refund.paymentMethod)
								_refund.paymentMethod = {
									name: 'Credit',
									paymentType: { value: 'Credit' },
								};
							return _refund;
						}),
						reservationReceiptTypes.REFUND,
						null,
						openPrinterModal
					);
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsPaying(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reservation,
				payment,
				refund,
			}
		);
	};

	const onOverPaymentRefund = refund => {
		setIsPaying(true);

		const reservationTotals = getReservationTotals(reservation, taxRateRef.current);

		apiCall(
			'POST',
			'advancedReservationOverPaymentRefund',
			res => {
				onReload(false);

				closeModal();

				if (res.refunds.length > 0) {
					printReservationReceipt(
						webPrint,
						res.reservation,
						res.refunds.map(_refund => {
							if (!_refund.paymentMethod)
								_refund.paymentMethod = {
									name: 'Credit',
									paymentType: { value: 'Credit' },
								};
							return _refund;
						}),
						reservationReceiptTypes.REFUND,
						null,
						openPrinterModal
					);
				}
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsPaying(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reservation,
				refund,
				lock:
					Math.abs(reservationTotals.remainingAmount).toString() ===
					refund.amount.toString(),
			}
		);
	};

	const onRetain = amount => {
		const reservationTotals = getReservationTotals(reservation, taxRateRef.current);

		setIsPaying(true);

		apiCall(
			'POST',
			'advancedReservationRetain',
			() => {
				onReload();
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsPaying(false);
			},
			'',
			{
				reservation,
				amount,
				lock: Math.abs(reservationTotals.remainingAmount).toString() === amount,
			}
		);
	};

	const onPaymentRefundVoid = refund => {
		setIsPaying(true);
		apiCall(
			'POST',
			'voidRefund',
			() => {
				onReload();
				setIsPaying(false);
			},
			err => {
				setIsPaying(false);
				addErrorNotification(err.toString());
			},
			'',
			{
				id: refund.id,
				reservationId: reservation.id,
			}
		);
	};

	const onReload = (_closeModal = true) => {
		setIsPaying(true);
		apiCall(
			'GET',
			'reservations',
			res => {
				setIsPaying(false);
				setReservation(res);
				setReservationHasChange(false);
				setActiveReservationItem(form.isTransient ? null : res.items[0]);

				if (_closeModal) closeModal();
			},
			err => {
				setIsPaying(false);
				addErrorNotification(err.toString());
				closeModal();
			},
			reservation.id
		);
	};

	const onPrintReservationInvoice = (invoiceId = null) => {
		apiCall(
			'POST',
			'getPrintInvoiceContent',
			res => {
				onPrint(res.invoices.join('<p style="page-break-before: always">'));
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reservationId: invoiceId ? null : data.id,
				invoiceIds: invoiceId ? [invoiceId] : null,
			}
		);
	};

	const onSendInvoiceEmail = (invoiceId = null) => {
		sendInvoice({
			outletId: userContext.data.selectedOutlet.id,
			reservationId: invoiceId ? null : data.id,
			invoiceIds: invoiceId ? [invoiceId] : null,
		});
	};

	const onConfirmationEmailSend = _reservation => {
		apiCall(
			'POST',
			'sendConfirmationEmail',
			res => {
				addSuccessNotification(res.message);
			},
			err => addErrorNotification(err.toString()),
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reservationId: _reservation.id,
			}
		);
	};

	const onAutoPayChange = () => {
		setIsPaying(true);
		apiCall(
			'POST',
			'reservationChangeAutoPay',
			res => {
				addSuccessNotification('Auto Pay successfully updated.');
				setReservation(res);
				setIsPaying(false);
			},
			err => {
				setIsPaying(false);
				addErrorNotification(err.toString());
			},
			'',
			{
				id: reservation.id,
				autoPay: !reservation.autoPay,
			}
		);
	};

	const onCustomerChange = customer => {
		if (reservation?.customer?.id === customer?.id) return;

		const _reservation = {
			...reservation,
			customer,
			items: reservation.items.map(i => {
				return updateItemTaxCode(i, customer, taxRateRef.current, reservation);
			}),
		};

		if (reservation.id) {
			setIsPaying(true);
			onSave(() => {
				if (activeReservationItem) {
					setActiveReservationItem({
						..._reservation.items.find(i => i.id === activeReservationItem.id),
					});
				}
				if (!form.isTransient && reservation.invoices.length) {
					apiCall(
						'POST',
						'updateReservationInvoice',
						() => {
							setIsPaying(false);
						},
						err => {
							setIsPaying(false);
							addErrorNotification(err.toString());
						},
						'',
						{
							id: reservation.id,
						}
					);
				} else setIsPaying(false);
			}, _reservation);
		} else {
			setReservation(_reservation);

			if (activeReservationItem) {
				setActiveReservationItem({
					...updateItemTaxCode(
						activeReservationItem,
						customer,
						taxRateRef.current,
						reservation
					),
				});
			}
		}
	};

	const onPaymentCapture = () => {
		setIsPaying(true);

		apiCall(
			'POST',
			'captureSchedulePayment',
			res => {
				if (res.success) addSuccessNotification('Payment successfully captured.');
				else addErrorNotification(res.message);
				onReload();
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsPaying(false);
			},
			'',
			{ payment: modal.payment, outletId: userContext.data.selectedOutlet.id }
		);
	};

	const onPaymentScheduleChange = paymentSchedule => {
		setIsPaying(true);
		apiCall(
			'POST',
			'reservationChangePaymentSchedule',
			res => {
				addSuccessNotification('Payment schedule successfully updated.');
				setReservation(res);
				setIsPaying(false);
				closeModal();
			},
			err => {
				setIsPaying(false);
				addErrorNotification(err.toString());
				closeModal();
			},
			'',
			{
				id: reservation.id,
				paymentSchedule,
			}
		);
	};

	const onCustomerPaymentMethodChange = customerPaymentMethod => {
		setIsPaying(true);
		apiCall(
			'POST',
			'reservationChangeCustomerPaymentMethod',
			res => {
				addSuccessNotification('Payment method successfully updated.');
				setReservation(res);
				setIsPaying(false);
				closeModal();
			},
			err => {
				setIsPaying(false);
				addErrorNotification(err.toString());
				closeModal();
			},
			'',
			{
				id: reservation.id,
				customerPaymentMethod,
			}
		);
	};

	const onEmailReceipt = payment => {
		apiCall(
			'POST',
			'sendPaymentEmail',
			() => {
				addSuccessNotification('Receipt has been emailed.');
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{
				paymentIds: [payment.id],
				outletId: userContext.data.selectedOutlet.id,
			}
		);
	};

	const onVoidAuthorization = payment => {
		setIsPaying(true);

		apiCall(
			'POST',
			'voidAuthorizedPayment',
			() => {
				addSuccessNotification('Successfully voided');
				onReload(true);
				setIsPaying(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsPaying(false);
			},
			'',
			{
				id: payment.id,
				entityId: reservation.id,
				entityType: 'reservation',
			}
		);
	};

	const navigateInvoices = () => {
		if (activeReservationItem && reservationItemHasChange)
			openModal({
				open: modals.UNSAVED,
				onSubmit: () => {
					setActiveReservationItem(null);
					setExtraContent(extraContents.INVOICES);
				},
			});
		else {
			setActiveReservationItem(null);
			setExtraContent(extraContents.INVOICES);
		}
	};

	useEffect(() => {
		if (!isLoading) {
			const _reservation = { ...(data.id ? data : defaultReservation.current) };

			setReservation(_reservation);

			let _itemId = parseInt(itemId, 10);

			if (
				_reservation.items.length &&
				!_reservation.items.some(i => i.d === _itemId) &&
				!form.isTransient
			) {
				_itemId = _reservation.items[0].id;
			}

			// get item by query string.
			const reservationItem = _reservation.items.find(i => i.id === _itemId);

			if (reservationItem) {
				setActiveReservationItem({
					...reservationItem,
				});

				// to fix losing history state on page reload.
				history.replace(
					() => {
						if (form.isBooking)
							return `${form.modulePage.reservations.advanced.path}/${_reservation.id}`;

						if (form.isLongTerm)
							return `${form.modulePage.reservations.longTerm.path}/${_reservation.id}`;

						if (form.isSeasonal)
							return `${form.modulePage.reservations.seasonal.path}/${_reservation.id}`;

						return `${form.modulePage.reservations.transient.path}/${_reservation.id}`;
					},
					{
						itemId: _itemId,
						from,
					}
				);
			}

			isItemLoading.current = false;
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: form.modulePage.default.text,
				path: form.modulePage.dashboard.path,
			},
			{
				title: form.modulePage.reservations.text,
				path: form.modulePage.reservations.path,
			},
			{
				title: `Reservation ${reservation.reservationId || 'New'}`,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(`Reservation ${reservation.reservationId || 'New'}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservation.reservationId, form]);

	useEffect(() => {
		if (!isListsLoading && searchData && searchData.product) {
			apiCall(
				'GET',
				'productBookings',
				res => {
					onAddItem(res);
				},
				() => {},
				searchData.product.id
			);
		}

		if (!isListsLoading) {
			webPrint.current = new WebPrint({
				user: userContext.data.user,
				outlet: userContext.data.selectedOutlet,
				printers,
				enumPrinterLogActions: printerLogActions,
				onStart: () => addSuccessNotification('Printing...'),
				onFinish: () => {},
				onFail: (printItem, err) => addErrorNotification(err.toString()),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isListsLoading]);

	useEffect(() => {
		if (!isLoading && data.hasManuallyEditedInvoice)
			openModal({ open: modals.MANUALLY_EDITED_INVOICE_WARNING });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	const getContent = () => {
		if (activeReservationItem)
			return form.isTransient ? (
				<ReservationAdvancedTransientItemForm
					form={form}
					key={`${activeReservationItem.id}${activeReservationItem.timeModified}`}
					data={activeReservationItem}
					reservation={reservation}
					product={activeReservationItem.product}
					taxRate={taxRateRef.current}
					policies={policies.filter(
						p => p.bookingPeriod.id === activeReservationItem.product.bookingPeriod.id
					)}
					ratePlans={ratePlans}
					onClose={() => {
						setActiveReservationItem(null);
						currentExtraChargeItemProductId.current = 0;
					}}
					onUpdate={onItemUpdate}
					onFormChange={() => setReservationItemHasChange(true)}
					disabled={
						(activeReservationItem.status &&
							activeReservationItem.status.value === reservationStatuses.CANCELLED) ||
						(reservation.isLocked && !hasEditLockedReservationPermission(userContext))
					}
					contracts={contracts}
					responsive='scroll'
					mediaBreakpoint='xl'
					currentExtraChargeItemProductId={currentExtraChargeItemProductId.current}
					onOverride={() => openModal({ open: modals.RELOAD })}
					modals={modals}
					enumRvTypes={enumRvTypes}
					enumVehicleMakes={enumVehicleMakes}
					enumBoatTypes={enumBoatTypes}
					enumBoatMakes={enumBoatMakes}
					invoicingFrequencies={invoicingFrequencies}
					accounts={accounts}
					taxCodes={taxCodes}
				/>
			) : (
				<ReservationAdvancedNonTransientItemForm
					form={form}
					key={`${activeReservationItem.id}${activeReservationItem.timeModified}`}
					disabled={
						(activeReservationItem.status &&
							activeReservationItem.status.value === reservationStatuses.CANCELLED) ||
						(reservation.isLocked && !hasEditLockedReservationPermission(userContext))
					}
					responsive='scroll'
					mediaBreakpoint='xl'
					data={activeReservationItem}
					reservation={reservation}
					product={activeReservationItem.product}
					taxRate={taxRateRef.current}
					policies={policies.filter(
						p => p.bookingPeriod.id === activeReservationItem.product.bookingPeriod.id
					)}
					invoiceFirstOptions={invoiceFirstOptions}
					invoiceNextOptions={invoiceNextOptions}
					invoicingFrequencies={invoicingFrequencies}
					ratePlans={ratePlans}
					contracts={contracts}
					paymentTerms={paymentTerms}
					modals={modals}
					enumBoatTypes={enumBoatTypes}
					enumBoatMakes={enumBoatMakes}
					enumRvTypes={enumRvTypes}
					enumVehicleMakes={enumVehicleMakes}
					onClose={() => {
						setActiveReservationItem(null);
						currentExtraChargeItemProductId.current = 0;
					}}
					onUpdate={onItemUpdate}
					onFormChange={() => {}}
					currentExtraChargeItemProductId={currentExtraChargeItemProductId.current}
					onOverride={() => openModal({ open: modals.RELOAD })}
					openPayModal={(_modal, amount, balanceDue) =>
						checkForOverride(() =>
							openModal({
								open: _modal,
								amount,
								balanceDue,
							})
						)
					}
					openCustomerModal={() => openModal({ open: modals.CUSTOMER })}
					onCheckIn={() => onCheckIn([activeReservationItem.id])}
					onCheckOut={() => openModal({ open: modals.CHECKOUT })}
					onInvoiceChange={() => onReload(false)}
					onItemUpdateStatus={onItemUpdateStatus}
					onSendConfirmationEmail={() => checkForOverride(onConfirmationEmailSend)}
					onPrintReservationInvoice={invoiceId =>
						checkForOverride(() => onPrintReservationInvoice(invoiceId))
					}
					onSendInvoiceEmail={invoiceId =>
						checkForOverride(() => onSendInvoiceEmail(invoiceId))
					}
					onCancel={() =>
						checkForOverride(() =>
							openModal({
								open: modals.CANCEL,
							})
						)
					}
					innerPageNavStatus={innerPageNavStatus}
					setInnerPageNavStatus={setInnerPageNavStatus}
					onPaymentPrint={payment =>
						printReservationReceipt(
							webPrint,
							reservation,
							payment,
							reservationReceiptTypes.PAYMENT,
							null,
							openPrinterModal
						)
					}
					paymentTypes={paymentTypes}
					webPrint={webPrint}
					enumBookingCalculations={enumBookingCalculations}
					parentModals={modals}
					onItemUpdateCustomStatus={onItemUpdateCustomStatus}
					accounts={accounts}
					seasons={seasons}
					taxCodes={taxCodes}
					onPaymentScheduleChange={
						paymentSchedules.length > 0
							? () =>
									checkForOverride(() =>
										openModal({ open: modals.PAYMENT_SCHEDULE })
									)
							: null
					}
					onCustomerPaymentMethodChange={
						paymentSchedules.length > 0
							? () =>
									checkForOverride(() =>
										openModal({
											open: modals.CUSTOMER_PAYMENT_METHOD,
										})
									)
							: null
					}
					onAuthorizePayment={(payment = null) => {
						checkForOverride(() => {
							openModal({
								open: modals.AUTHORIZE_PAYMENT,
								payment,
							});
						});
					}}
					onCaptureAuthorizedPayment={payment => {
						checkForOverride(() => {
							openModal({
								open: modals.CAPTURE_AUTHORIZED_PAYMENT,
								payment,
								isCapture: true,
							});
						});
					}}
					onVoidAuthorizedPayment={payment => {
						checkForOverride(() => {
							openModal({
								open: modals.VOID_AUTHORIZED_PAYMENT,
								payment,
							});
						});
					}}
					cancellationReason={activeReservationItem.cancellationReason}
					cancellationTime={activeReservationItem.cancellationTime}
				/>
			);

		if (itemId && isItemLoading.current)
			return form.isTransient ? (
				<ReservationAdvancedTransientItemFormLoading />
			) : (
				<ReservationAdvancedNonTransientItemFormLoading />
			);

		if (extraContent === extraContents.INVOICES)
			return (
				<InvoiceList
					filters={{ reservationId: reservation.id }}
					presetData={{
						customer: reservation.customer,
						reservation,
						paymentTerm: null,
						invoiceDate: moment()
							.utc(false)
							.toISOString(),
						dueDate: null,
					}}
					onChange={() => {}}
					disabled
					onPaymentPrint={() => {}}
					onPrint={onPrintReservationInvoice}
					onSend={onSendInvoiceEmail}
					headerActions={[
						{
							text: 'Back to Grid',
							design: 'default',
							onClick: () => setExtraContent(''),
							outline: false,
						},
					]}
					openInCrm
				/>
			);

		if (extraContent === extraContents.REMITTANCES)
			return (
				<PaymentList
					isReservation
					filters={{
						reservation: reservation.id,
					}}
					presetData={{
						customer: reservation.customer,
						timeCreated: moment().toISOString(),
						outlet: userContext.data.selectedOutlet,
						automaticallySettle: true,
						changeDue: 0,
						reservation,
					}}
					onChange={() => {}}
					disabled
					paymentTypes={paymentTypes}
					webPrint={webPrint}
					headerActions={[
						{
							text: 'Back to Grid',
							design: 'default',
							onClick: () => setExtraContent(''),
							outline: false,
						},
					]}
					openInCrm
				/>
			);

		return (
			<ReservationAdvanceGrid
				onClick={product => {
					if (reservation.id)
						checkForOverride(_reservation => onAddItem(product, _reservation));
					else onAddItem(product);
				}}
				module={form.module.value}
				onlyGrid
				customFilters={getReservationGridCustomFilters(form.bookingPeriod)}
			/>
		);
	};

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					{!form.isTransient && windowSize.width < mediaBreakpoint.LG && (
						<SVGIcon
							name='Toggle'
							className='sdms-mr-15'
							onClick={() => setInnerPageNavStatus(true)}
						/>
					)}
					<ContentInner.SubHeaderTitle title='Reservation' />
					<BreadcrumbContainer />
				</ContentInner.SubHeaderItem>
				<ContentInner.SubHeaderItem type='toolbar'>
					<>
						{form.isTransient && (
							<>
								<Button
									design={
										extraContent === extraContents.INVOICES &&
										!activeReservationItem
											? 'brand'
											: 'default'
									}
									text='Invoices'
									icon='Clipboard-list'
									onClick={navigateInvoices}
									disabled={reservation.id === 0}
								/>
								<Button
									design={
										extraContent === extraContents.REMITTANCES &&
										!activeReservationItem
											? 'brand'
											: 'default'
									}
									text='Remittances'
									icon='Wallet'
									onClick={() => {
										if (activeReservationItem && reservationItemHasChange)
											openModal({
												open: modals.UNSAVED,
												onSubmit: () => {
													setActiveReservationItem(null);
													setExtraContent(extraContents.REMITTANCES);
												},
											});
										else {
											setActiveReservationItem(null);
											setExtraContent(extraContents.REMITTANCES);
										}
									}}
									disabled={reservation.id === 0}
								/>
							</>
						)}
						{messageContext.isEnabled && (
							<MessagesButton
								onClick={() => openModal({ open: modals.MESSAGES })}
								disabled={isPaying || isLoading || reservation.id === 0}
							/>
						)}
						<Button
							design='default'
							text='Attachments'
							onClick={() => openModal({ open: modals.ATTACHMENTS })}
							disabled={isPaying || isLoading || reservation.id === 0}
						/>
						<Button
							design='default'
							text='Back'
							onClick={() => {
								if (reservationHasChange) openModal({ open: modals.BACK });
								else onBack();
							}}
						/>
					</>
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container title={`Reservation ${data.reservationId || 'New'}`} hasFrame>
				<div id='sdms_reservation_list' className='row h-100'>
					<div
						className={
							form.isTransient ? 'col-xl-8 col-lg-7 col-md-12 col-12' : 'col-12'
						}>
						{getContent()}
					</div>
					{form.isTransient && (
						<div className='col-xl-4 col-lg-5 col-md-12 col-12'>
							<Reservation
								data={reservation}
								totals={getReservationTotals(reservation, taxRateRef.current)}
								isLoading={
									form.isBooking
										? isLoading || isExtraChargeSubmitting
										: isLoading
								}
								openGrid={() => {
									if (reservationItemHasChange)
										openModal({
											open: modals.UNSAVED,
											onSubmit: () => {
												setActiveReservationItem(null);
												setExtraContent('');
											},
										});
									else {
										setActiveReservationItem(null);
										setExtraContent('');
									}
								}}
								onItemEdit={_itemId => {
									if (reservationItemHasChange)
										openModal({
											open: modals.UNSAVED,
											onSubmit: () =>
												setActiveReservationItem(
													reservation.items.find(i => i.id === _itemId)
												),
										});
									else
										setActiveReservationItem(
											reservation.items.find(i => i.id === _itemId)
										);
								}}
								onItemCancel={reservationItemIds =>
									checkForOverride(() =>
										openModal({
											open: modals.ITEM_CANCEL,
											reservationItemIds,
										})
									)
								}
								onItemRefund={reservationItemIds =>
									checkForOverride(() =>
										openModal({
											open: modals.ITEM_ONLY_REFUND,
											reservationItemIds,
										})
									)
								}
								addNewUnitButtonDisabled={
									!activeReservationItem && extraContent === ''
								}
								activeItemId={
									activeReservationItem ? activeReservationItem.id : null
								}
								openNoteModal={item => openModal({ open: modals.NOTE, item })}
								openCustomerModal={() => openModal({ open: modals.CUSTOMER })}
								openPayModal={(type, amount) =>
									checkForOverride(() => openModal({ open: type, amount }))
								}
								onSave={() => onSave()}
								onSendConfirmationEmail={() =>
									checkForOverride(onConfirmationEmailSend)
								}
								onPrintReservationInvoice={() =>
									checkForOverride(() => onPrintReservationInvoice(null))
								}
								onSendInvoiceEmail={() => checkForOverride(onSendInvoiceEmail)}
								onCancel={withRefund =>
									checkForOverride(() =>
										openModal({
											open: withRefund
												? modals.CANCELANDREFUND
												: modals.CANCEL,
										})
									)
								}
								onCheckIn={onCheckIn}
								onCheckOut={onCheckOut}
								refundableAmount={getRefundableAmount(
									reservation,
									taxRateRef.current
								)}
								selectedItems={selectedItems}
								onItemSelect={selectedItemId =>
									setSelectedItems(
										selectedItems.includes(selectedItemId)
											? selectedItems.filter(i => i !== selectedItemId)
											: [...selectedItems, selectedItemId]
									)
								}
								onItemSelectAll={() =>
									setSelectedItems(
										selectedItems.length < reservation.items.length
											? reservation.items.map(i => i.id)
											: []
									)
								}
								onPaymentPrint={(printData, isRefund) => {
									let type = reservationReceiptTypes.PAYMENT;

									if (isRefund) type = reservationReceiptTypes.REFUND;

									if (printData.isDeposit) type = reservationReceiptTypes.DEPOSIT;

									printReservationReceipt(
										webPrint,
										reservation,
										printData,
										type,
										null,
										openPrinterModal
									);
								}}
								onPaymentRefund={(payment, amount) =>
									checkForOverride(() =>
										openModal({
											open: modals.PAYMENT_REFUND,
											payment,
											amount,
										})
									)
								}
								onPaymentVoid={payment =>
									checkForOverride(() =>
										openModal({ open: modals.PAYMENT_VOID_DIALOG, payment })
									)
								}
								onPaymentCapture={payment =>
									checkForOverride(() =>
										openModal({ open: modals.PAYMENT_CAPTURE_DIALOG, payment })
									)
								}
								onRetain={amount =>
									checkForOverride(() =>
										openModal({ open: modals.RETAIN, amount })
									)
								}
								onItemUpdateStatus={onItemUpdateStatus}
								onItemUpdateCustomStatus={onItemUpdateCustomStatus}
								modals={modals}
								onAutoPayChange={() => checkForOverride(onAutoPayChange)}
								onPaymentRefundVoid={refund =>
									checkForOverride(() =>
										openModal({
											open: modals.PAYMENT_REFUND_VOID,
											refund,
										})
									)
								}
								onExtraChargeClick={() => openModal({ open: modals.EXTRA_CHARGE })}
								onExtraChargeRemove={extraCharge => {
									setIsExtraChargeSubmitting(true);
									checkForOverride(
										() => setIsExtraChargeSubmitting(false),
										{
											...reservation,
											extraCharges: reservation.extraCharges.filter(
												ec => ec.id !== extraCharge.id
											),
										},
										() => setIsExtraChargeSubmitting(false)
									);
								}}
								onPaymentScheduleChange={
									paymentSchedules.length > 0
										? () =>
												checkForOverride(() =>
													openModal({ open: modals.PAYMENT_SCHEDULE })
												)
										: null
								}
								onCustomerPaymentMethodChange={
									paymentSchedules.length > 0
										? () =>
												checkForOverride(() =>
													openModal({
														open: modals.CUSTOMER_PAYMENT_METHOD,
													})
												)
										: null
								}
								onEmailReceipt={onEmailReceipt}
								onAuthorizePayment={(payment = null) => {
									checkForOverride(() => {
										openModal({
											open: modals.AUTHORIZE_PAYMENT,
											payment,
										});
									});
								}}
								onCaptureAuthorizedPayment={payment => {
									checkForOverride(() => {
										openModal({
											open: modals.CAPTURE_AUTHORIZED_PAYMENT,
											payment,
											isCapture: true,
										});
									});
								}}
								onVoidAuthorizedPayment={payment => {
									checkForOverride(() => {
										openModal({
											open: modals.VOID_AUTHORIZED_PAYMENT,
											payment,
										});
									});
								}}
								navigateInvoices={navigateInvoices}
							/>
						</div>
					)}
				</div>
			</ContentInner.Container>
			<CustomerModal
				isOpen={modal.open === modals.CUSTOMER}
				setSelectedCustomer={customer => {
					onCustomerChange(customer);
					if (modal.after) modal.after();
					closeModal();
				}}
				defaultCustomer={reservation.customer}
				onClose={closeModal}
				hasRegisterForm
				paymentTerms={paymentTerms}
				countries={countries}
				defaultCountry={userContext.data.selectedOutlet.country}
				states={states}
				enableHouseAccount={userContext.hasPermission('enable_house_account')}
				saveCreditCards={userContext.hasPermission('save_credit_cards')}
				passwordSettings={userContext.data.user.company.systemSettings}
				filterIsActive
				disabled={
					reservation.id !== 0 &&
					userContext.data.selectedOutlet.settings.defaultCustomer.id !==
						reservation.customer?.id
				}
			/>
			<PayModal
				isOpen={
					modal.open === modals.PAY ||
					modal.open === modals.DEPOSIT ||
					modal.open === modals.REFUND ||
					modal.open === modals.ITEM_REFUND ||
					modal.open === modals.PAYMENT_REFUND ||
					modal.open === modals.OVERPAYMENT_REFUND ||
					modal.open === modals.NON_TRANSIENT_REFUND
				}
				onClose={closeModal}
				paymentTypes={getPaymentTypes(
					modal.open === modals.REFUND ||
						modal.open === modals.ITEM_REFUND ||
						modal.open === modals.PAYMENT_REFUND ||
						modal.open === modals.OVERPAYMENT_REFUND ||
						modal.open === modals.NON_TRANSIENT_REFUND,
					paymentTypes,
					userContext.hasPermission('cash_payments')
				)}
				amount={
					modal.amount ||
					(form.isTransient ? 0 : parseFloat(process.env.REACT_APP_PRICE_UPPER_LIMIT))
				}
				customer={getCustomerWithCreditCards()}
				balanceDue={modal.amount ? 0 : modal.balanceDue}
				onPay={paymentData => {
					switch (modal.open) {
						case modals.PAY:
							if (hasReservationInvoice(reservation)) onPay(paymentData);
							else
								onDepositPay({
									...paymentData,
									isDeposit: false,
								});

							break;
						case modals.DEPOSIT:
							onDepositPay(paymentData);
							break;
						case modals.REFUND:
							onCancel(paymentData);
							break;
						case modals.ITEM_REFUND:
							onItemCancel(modal.reservationItemIds, paymentData);
							break;
						case modals.PAYMENT_REFUND:
							onPaymentRefund(modal.payment, paymentData);
							break;
						case modals.OVERPAYMENT_REFUND:
						case modals.NON_TRANSIENT_REFUND:
							onOverPaymentRefund(paymentData);
							break;
						default:
							addErrorNotification('Invalid action');
							break;
					}
				}}
				isPaying={isPaying}
				canSavePaymentMethod={userContext.hasPermission('save_customer_payment_methods')}
				canEnterCreditCard={userContext.hasPermission('key_in_credit_cards')}
				isRefund={
					modal.open === modals.REFUND ||
					modal.open === modals.ITEM_REFUND ||
					modal.open === modals.PAYMENT_REFUND ||
					modal.open === modals.OVERPAYMENT_REFUND ||
					modal.open === modals.NON_TRANSIENT_REFUND
				}
				refundAmountChangeable
				max={modal.amount}
				defaultCreditCard={
					modal.open === modals.PAYMENT_REFUND ? getDefaultCreditCard(modal) : null
				}
				activePaymentMethod={getDefaultPaymentMethod(modal)}
				paymentGateway={userContext.data.selectedOutlet.settings.paymentGateway}
				serviceFeeModule={getServiceFeeModule(form.module.value, form.bookingPeriod)}
				disableServiceFee={
					modal.open === modals.REFUND ||
					modal.open === modals.ITEM_REFUND ||
					modal.open === modals.PAYMENT_REFUND ||
					modal.open === modals.OVERPAYMENT_REFUND ||
					modal.open === modals.NON_TRANSIENT_REFUND
				}
				authorizedPayment={reservation.authorizedPayment}
				outlet={userContext.data.selectedOutlet}
			/>
			<Portal>
				<Popup
					open={modal.open === modals.ITEM_SELECT}
					closeOnDocumentClick={false}
					lockScroll
					modal
					onClose={closeModal}
					contentStyle={{
						padding: 0,
						background: 'unset',
						border: 'unset',
					}}>
					<Portlet>
						<Portlet.Head>
							<Portlet.HeadLabelTitle portletIcon='Write'>
								Items
							</Portlet.HeadLabelTitle>
						</Portlet.Head>
						<Portlet.Body>
							{reservation.items
								.filter(i => {
									if (form.isTransient)
										return (
											i.status.value !== reservationStatuses.CANCELLED &&
											i.status.value !== reservationStatuses.CHECKED_OUT
										);

									return i.status.value !== reservationStatuses.CANCELLED;
								})
								.map(i => (
									<Button
										key={i.id}
										text={`${i.product.name} - ${i.unit.name}`}
										onClick={() => {
											addPosExtraChargeItem(i.id, modal.product);
											closeModal();
										}}
									/>
								))}
						</Portlet.Body>
						<Portlet.Foot className='sdms-align-left' tall='sm'>
							<div className='col'>
								<Button
									design='clean'
									text='Cancel'
									icon='Error-circle'
									size='sm'
									elevate
									onClick={closeModal}
								/>
							</div>
						</Portlet.Foot>
					</Portlet>
				</Popup>
			</Portal>
			{PRINT_COMPONENT}
			<DialogBox
				open={modal.open === modals.BACK}
				title=''
				content='You changed something in the form. Would you like to go back?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					text='Go back without saving'
					label='danger'
					icon='Angle-left-circle'
					onClick={onBack}
				/>
				<Button
					className='sdms-font-transform-c'
					text='Continue editing'
					design='clean'
					icon='Edit'
					onClick={closeModal}
				/>
			</DialogBox>
			<DialogBox
				open={modal.open === modals.CANCEL || modal.open === modals.CANCELANDREFUND}
				title=''
				content='Are you sure about cancelling?'
				type='question'
				onClose={() => {}}>
				<Textarea
					className='mb-3'
					placeholder='Cancellation Reason (Optional)'
					value={cancellationReason}
					onChange={e => setCancellationReason(e.target.value)}
					textRow={2}
					disabled={isLoading}
				/>
				<Button
					className='sdms-font-transform-c'
					text='No'
					label='danger'
					icon='Angle-left-circle'
					onClick={closeModal}
				/>
				<Button
					className='sdms-font-transform-c'
					text='Yes, Cancel'
					design='clean'
					icon='Edit'
					onClick={() => {
						if (modal.open === modals.CANCELANDREFUND)
							openModal({
								open: modals.REFUND,
								amount: getRefundableAmount(reservation, taxRateRef.current),
							});
						else {
							onCancel();
							closeModal();
						}
					}}
				/>
			</DialogBox>
			<DialogBox
				open={modal.open === modals.ITEM_CANCEL}
				title=''
				content='Are you sure about cancelling?'
				type='question'
				onClose={() => {}}>
				<Textarea
					className='mb-3'
					placeholder='Cancellation Reason (Optional)'
					value={cancellationReason}
					onChange={e => setCancellationReason(e.target.value)}
					textRow={2}
					disabled={isLoading}
				/>
				<Button
					className='sdms-font-transform-c'
					text='No'
					label='danger'
					icon='Angle-left-circle'
					onClick={closeModal}
				/>
				{reservation &&
					modal.reservationItemIds &&
					getItemRefundableAmount(reservation, modal.reservationItemIds) > 0 && (
						<Button
							noPermission={!hasRefundReservationPaymentPermission(userContext)}
							className='sdms-font-transform-c'
							text='Yes, Cancel & Refund'
							design='clean'
							icon='Edit'
							onClick={() =>
								openModal({
									open: modals.ITEM_REFUND,
									amount: getItemRefundableAmount(
										reservation,
										modal.reservationItemIds
									),
								})
							}
						/>
					)}
				<Button
					className='sdms-font-transform-c'
					text='Yes, Cancel'
					design='clean'
					icon='Edit'
					onClick={() => {
						onItemCancel(modal.reservationItemIds || []);
						closeModal();
					}}
				/>
			</DialogBox>
			<DialogBox
				open={modal.open === modals.ITEM_ONLY_REFUND}
				title=''
				content='Are you sure about refunding?'
				type='question'
				onClose={() => {}}>
				<Button
					className='sdms-font-transform-c'
					text='No'
					label='danger'
					icon='Angle-left-circle'
					onClick={closeModal}
				/>
				<Button
					className='sdms-font-transform-c'
					text='Yes, Refund'
					design='clean'
					icon='Edit'
					onClick={() =>
						openModal({
							open: modals.ITEM_REFUND,
							amount: getItemRefundableAmount(reservation, modal.reservationItemIds),
						})
					}
				/>
			</DialogBox>
			<DialogBox
				open={modal.open === modals.UNSAVED}
				title=''
				content='There are unsaved changes on this booking.'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					label='danger'
					text='Proceed Without Saving'
					icon='Angle-right-circle'
					onClick={() => {
						setReservationItemHasChange(false);
						if (modal.onSubmit) modal.onSubmit();
						closeModal();
					}}
				/>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					text='Return to Item'
					icon='Clipboard-list'
					onClick={closeModal}
				/>
			</DialogBox>
			<DialogBox
				open={modal.open === modals.PAYMENT_VOID_DIALOG}
				title=''
				content='Are you sure?'
				type='question'
				onClose={() => {}}>
				<Button
					className='sdms-font-transform-c'
					text='No'
					label='danger'
					icon='Angle-left-circle'
					onClick={closeModal}
				/>
				<Button
					className='sdms-font-transform-c'
					text='Yes, Void'
					design='clean'
					icon='Edit'
					disabled={form.isBooking ? isPaying : false}
					onClick={() => onPaymentRefund(modal.payment)}
				/>
			</DialogBox>
			<QuantityModal
				modalTitle='Retain'
				initValue={modal.amount}
				defaultValue={modal.amount}
				isOpen={modal.open === modals.RETAIN}
				decimalLimit={2}
				lowerLimit={0}
				upperLimit={modal.amount}
				onClose={closeModal}
				setDashValue={amount => onRetain(amount)}
				submitButtonText='Retain'
				isLoading={isPaying}
			/>
			<PrinterModal
				onClose={closeModal}
				printers={webPrint.current.printers}
				isOpen={modal.open === modals.PRINTER}
				enableRemember
				onSubmit={printer => {
					let { printData, type } = modal;

					if (modal.payment) {
						printData = modal.payment;

						type = modal.payment.isDeposit
							? reservationReceiptTypes.DEPOSIT
							: reservationReceiptTypes.PAYMENT;
					} else if (modal.refund) {
						printData = modal.refund;

						type = reservationReceiptTypes.REFUND;
					}

					printReservationReceipt(
						webPrint,
						modal.reservation,
						printData,
						type,
						printer.id,
						openPrinterModal
					);
					closeModal();
				}}
			/>
			<DialogBox
				open={modal.open === modals.RELOAD}
				title=''
				content={`Reservation ${
					reservation ? '#' : reservation.reservationId
				} has been changed since you opened it.`}
				type='question'
				onClose={closeModal}>
				<Button label='success' text='Reload' onClick={onReload} />
			</DialogBox>
			<Contract
				isOpen={modal.open === modals.CONTRACT}
				onClose={closeModal}
				contract={modal.contracts ? Object.values(modal.contracts)[0] : null}
				reservationItem={
					modal.contracts
						? reservation.items.find(
								i => i.id.toString() === Object.keys(modal.contracts)[0]
						  )
						: null
				}
				reservation={reservation}
				module={form.module.value}
			/>
			<DialogBox
				open={modal.open === modals.PAYMENT_REFUND_VOID}
				title=''
				content='Are you sure?'
				type='question'
				onClose={() => {}}>
				<Button
					className='sdms-font-transform-c'
					text='No'
					label='danger'
					icon='Angle-left-circle'
					onClick={closeModal}
				/>
				<Button
					className='sdms-font-transform-c'
					text='Yes, Void'
					design='clean'
					icon='Edit'
					disabled={isPaying}
					onClick={() => onPaymentRefundVoid(modal.refund)}
				/>
			</DialogBox>
			<DialogBox
				open={modal.open === modals.PAYMENT_CAPTURE_DIALOG}
				title='Capture'
				content='Are you sure you want to capture this payment?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't Capture!`}
					onClick={closeModal}
					disabled={isPaying}
				/>
				<Button
					className='sdms-font-transform-c'
					label='info'
					icon='Trash'
					text='Yes, Capture!'
					onClick={onPaymentCapture}
					disabled={isPaying}
				/>
			</DialogBox>
			<DialogBox
				open={modal.open === modals.SEND_EMAIL}
				title=''
				content='Auto-send email is not enabled. Do you want to send e-mail?'
				type='question'
				onClose={() => {}}>
				<Button
					className='sdms-font-transform-c'
					text='No'
					label='danger'
					icon='Error-circle'
					onClick={() => {
						onItemUpdateCustomStatus({ ...modal.params, sendStatusEmail: false });
						closeModal();
					}}
				/>
				<Button
					className='sdms-font-transform-c'
					text='Yes'
					design='clean'
					icon='Angle-right-circle'
					onClick={() => {
						onItemUpdateCustomStatus({ ...modal.params, sendStatusEmail: true });
						closeModal();
					}}
				/>
			</DialogBox>
			{!form.isTransient &&
				activeReservationItem &&
				activeReservationItem.id !== 0 &&
				activeReservationItem.status.value === reservationStatuses.CHECKED_IN && (
					<Checkout
						status={modal.open === modals.CHECKOUT}
						onClose={closeModal}
						afterCheckout={(_reservation, _contracts) => {
							setReservation(_reservation);
							setActiveReservationItem(_reservation.items[0]);
							if (Object.keys(_contracts).length)
								openModal({ open: modals.CONTRACT, _contracts });
						}}
						reservationItem={activeReservationItem}
					/>
				)}
			{form.isTransient && (
				<ReservationExtraCharge
					isOpen={modal.open === modals.EXTRA_CHARGE}
					onClose={closeModal}
					extraCharges={bookingExtraCharges}
					reservationExtraCharges={reservation.extraCharges || []}
					customer={reservation?.customer}
					reservation={reservation}
					taxRate={taxRateRef.current}
					onChange={extraChargeItem => {
						onExtraChargeItemUpdate(
							extraChargeItem,
							reservation.extraCharges || [],
							_extraCharges =>
								setReservation({ ...reservation, extraCharges: _extraCharges }),
							{
								bookingPeriod: null,
								quantity: 1,
								subtotal: getReservationTotals(
									reservation,
									taxRateRef.current,
									true
								).subtotalWithoutExtraCharges,
								taxCode: data.taxCode,
							},
							taxRateRef.current
						);
					}}
					onSave={() => {
						setIsExtraChargeSubmitting(true);
						onSave(
							() => {
								closeModal();
								setIsExtraChargeSubmitting(false);
							},
							null,
							() => setIsExtraChargeSubmitting(false)
						);
					}}
					isSubmitting={isExtraChargeSubmitting}
				/>
			)}
			<PaymentScheduleSelectModal
				selectedPaymentSchedule={reservation.paymentSchedule}
				paymentSchedules={paymentSchedules}
				onClose={closeModal}
				isOpen={modal.open === modals.PAYMENT_SCHEDULE}
				onSubmit={onPaymentScheduleChange}
				isSubmitting={isPaying}
			/>
			{modal.open === modals.CUSTOMER_PAYMENT_METHOD && (
				<CustomerPaymentMethodSelect
					selectedPaymentMethod={reservation.customerPaymentMethod}
					customer={reservation.customer}
					onClose={closeModal}
					isOpen={modal.open === modals.CUSTOMER_PAYMENT_METHOD}
					onSubmit={onCustomerPaymentMethodChange}
					paymentMethods={paymentMethods}
					isSubmitting={isPaying}
				/>
			)}
			<AuthorizePaymentModal
				customer={reservation.customer}
				entityId={reservation.id}
				entityType='reservation'
				onSubmit={() => onReload(true)}
				onClose={closeModal}
				isOpen={
					modal.open === modals.AUTHORIZE_PAYMENT ||
					modal.open === modals.CAPTURE_AUTHORIZED_PAYMENT
				}
				payment={modal.payment}
				isCapture={modal.isCapture}
				serviceFeeModule={getServiceFeeModule(form.module.value, form.bookingPeriod)}
				defaultAmount={modal.payment?.amount}
				outlet={userContext.data.selectedOutlet}
			/>
			{modal.open === modals.MESSAGES && (
				<Messages
					onClose={closeModal}
					inquiryObject={reservation}
					onNewInquiry={inquiry => setReservation({ ...reservation, inquiry })}
				/>
			)}
			{modal.open === modals.ATTACHMENTS && (
				<AttachmentModal onClose={closeModal} entity={reservation} isOpen />
			)}
			<DialogBox
				open={modal.open === modals.MANUALLY_EDITED_INVOICE_WARNING}
				title=''
				content='Reservation has manually edited invoice!'
				type='warning'
				onClose={closeModal}>
				<Button label='success' text='Ok' onClick={closeModal} />
			</DialogBox>
			<DialogBox
				open={modal.open === modals.VOID_AUTHORIZED_PAYMENT}
				title=''
				content='Are you sure you want to release authorization?'
				type='question'
				onClose={() => {}}>
				<Button
					className='sdms-font-transform-c'
					text='No'
					label='danger'
					icon='Angle-left-circle'
					disabled={isPaying}
					onClick={closeModal}
				/>
				<Button
					className='sdms-font-transform-c'
					text='Yes, Release'
					design='clean'
					icon='Edit'
					disabled={isPaying}
					onClick={() => onVoidAuthorization(modal.payment)}
				/>
			</DialogBox>
		</>
	);
};
ReservationAdvanceForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		customer: PropTypes.object,
		note: PropTypes.string,
		module: PropTypes.object,
		items: PropTypes.arrayOf(PropTypes.object),
		deposits: PropTypes.arrayOf(PropTypes.object),
		invoices: PropTypes.arrayOf(PropTypes.object),
		reservationId: PropTypes.string,
		extraCharges: PropTypes.arrayOf(PropTypes.object),
		taxCode: PropTypes.object,
		authorizedPayment: PropTypes.object,
		hasManuallyEditedInvoice: PropTypes.bool,
	}),
	isLoading: PropTypes.bool,
	isListsLoading: PropTypes.bool,
	itemId: PropTypes.string,
	policies: PropTypes.arrayOf(PropTypes.object),
	paymentTypes: PropTypes.arrayOf(PropTypes.object),
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	enumReservationStatuses: PropTypes.arrayOf(PropTypes.object),
	contracts: PropTypes.arrayOf(PropTypes.object),
	from: PropTypes.string,
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	countries: PropTypes.arrayOf(PropTypes.object),
	states: PropTypes.arrayOf(PropTypes.object),
	enumBoatTypes: PropTypes.arrayOf(PropTypes.object),
	enumBoatMakes: PropTypes.arrayOf(PropTypes.object),
	enumRvTypes: PropTypes.arrayOf(PropTypes.object),
	enumVehicleMakes: PropTypes.arrayOf(PropTypes.object),
	bookingExtraCharges: PropTypes.arrayOf(PropTypes.object),
	invoicingFrequencies: PropTypes.arrayOf(PropTypes.object),
	invoiceFirstOptions: PropTypes.arrayOf(PropTypes.object),
	invoiceNextOptions: PropTypes.arrayOf(PropTypes.object),
	printers: PropTypes.arrayOf(PropTypes.object),
	printerLogActions: PropTypes.arrayOf(PropTypes.object),
	enumBookingCalculations: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	searchData: PropTypes.object,
	accounts: PropTypes.arrayOf(PropTypes.object),
	seasons: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	paymentSchedules: PropTypes.arrayOf(PropTypes.object),
	paymentMethods: PropTypes.arrayOf(PropTypes.object),
	customReservationStatuses: PropTypes.arrayOf(PropTypes.object),
};
ReservationAdvanceForm.defaultProps = {
	data: {
		id: 0,
		hasManuallyEditedInvoice: false,
	},
	isLoading: true,
	isListsLoading: true,
	itemId: null,
	policies: [],
	paymentTypes: [],
	ratePlans: [],
	enumReservationStatuses: [],
	contracts: [],
	from: '',
	paymentTerms: [],
	countries: [],
	states: [],
	enumBoatTypes: [],
	enumBoatMakes: [],
	enumRvTypes: [],
	enumVehicleMakes: [],
	bookingExtraCharges: [],
	invoicingFrequencies: [],
	invoiceFirstOptions: [],
	invoiceNextOptions: [],
	printers: [],
	printerLogActions: [],
	enumBookingCalculations: [],
	searchData: null,
	accounts: [],
	seasons: [],
	taxCodes: [],
	paymentSchedules: [],
	paymentMethods: [],
	customReservationStatuses: [],
};

export default ReservationAdvanceForm;
