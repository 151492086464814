import React from 'react';
import PropTypes from 'prop-types';

import pages from '../../pages';

import FormContainer from '../../reusables/template/FormContainer';
import AttachmentTypesForm from './AttachmentTypesForm';

const AttachmentTypesEdit = ({ match }) => {
	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle={pages.systemSettings.attachmentTypes.text}
			dataName={pages.systemSettings.attachmentTypes.route}
			fields={<AttachmentTypesForm />}
			lists={['enumAttachmentEntities']}
		/>
	);
};

AttachmentTypesEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default AttachmentTypesEdit;
