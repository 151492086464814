import React from 'react';
import PropTypes from 'prop-types';
import RatePlanForm from './RatePlanForm';
import usePages from '../../../utils/hooks/usePages';
import FormContainer from '../../reusables/template/FormContainer';
import { modules } from '../../../utils/helpers/apiCall';

const RatePlanEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id.toString()}
			dataName='ratePlans'
			fields={<RatePlanForm />}
			pageTitle={pages.campground.settings.ratePlans.text}
			icon={pages.campground.settings.ratePlans.icon}
			lists={['enumBookingPeriods', 'policies', 'enumPricingTypes']}
			module={modules.CAMPGROUND}
		/>
	);
};

RatePlanEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default RatePlanEdit;
