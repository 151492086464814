import React, { useContext, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import DocumentTitle from 'react-document-title';
import { addErrorNotification } from '../utils/helpers/helper';
import UserContext from '../app/contexts/UserContext';
import apiCall from '../utils/helpers/apiCall';
import Button from './reusables/element/Button';
import Input from './reusables/field/Input';
import FormGroup from './reusables/layout/FormGroup';
import background from '../assets/img/login.jpg';
import logo from '../assets/img/logo.svg';

const Login = () => {
	const [email, setEmail] = useState('');
	const [pass, setPass] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const userContext = useContext(UserContext);

	const login = () => {
		if (email === '' || pass === '') {
			addErrorNotification('Please fill missing fields.');
			return;
		}
		setIsLoading(true);
		apiCall(
			'POST',
			'userLogin',
			res => {
				setIsLoading(false);
				userContext.login(res);
			},
			err => {
				setIsLoading(false);
				addErrorNotification(err.toString());
			},
			'',
			{
				email,
				pass,
			}
		);
	};

	const getKeyPress = event => {
		if (event.keyCode === 13) login();
	};

	useEffect(() => {
		document.addEventListener('keydown', getKeyPress);

		return () => document.removeEventListener('keydown', getKeyPress);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [email, pass]);

	return (
		<DocumentTitle title='Log In | Sharper MMS'>
			<div className='sdms-grid sdms-grid--ver sdms-grid--root'>
				<div className='sdms-grid sdms-grid--hor sdms-grid--root sdms-login sdms-login--signin'>
					<div
						className='sdms-grid__item sdms-grid__item--fluid sdms-grid sdms-login--background d-flex align-items-center justify-content-center'
						style={{
							backgroundImage: `url(${background})`,
						}}>
						<div className='sdms-login__right'>
							<div className='sdms-login__wrapper'>
								<div className='sdms-login__signin'>
									<div className='sdms-login__content'>
										<a className='sdms-login__logo' href='/'>
											<ReactSVG src={logo} className='svg-container' />
										</a>
									</div>
									<div className='sdms-login__head'>
										<h3 className='sdms-login__title'>Login To Your Account</h3>
									</div>
									<div className='sdms-login__form'>
										<form className='sdms-form'>
											<FormGroup row={false}>
												<Input
													placeholder='Email'
													type='text'
													name='username'
													value={email}
													onChange={({ target }) =>
														setEmail(target.value)
													}
												/>
											</FormGroup>
											<FormGroup row={false}>
												<Input
													className={classNames('form-control-last', {
														'form-control--empty': pass === '',
													})}
													type='password'
													placeholder='Password'
													name='pass'
													value={pass}
													togglePassword
													onChange={({ target }) => setPass(target.value)}
												/>
											</FormGroup>
										</form>
										<div className='sdms-login__actions'>
											<Button
												text='Log In'
												label='success'
												icon={
													isLoading
														? process.env
																.REACT_APP_SUBMIT_BUTTON_SAVING_ICON
														: 'Sign-in'
												}
												iconSize={36}
												bold='bold'
												className={classNames('sdms-p5', 'sdms-font-lg', {
													'sdms-fading-dots': isLoading,
												})}
												block
												onClick={login}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</DocumentTitle>
	);
};

export default Login;
