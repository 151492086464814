import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Loading from '../../reusables/template/Loading';
import useField from '../../../utils/hooks/useField';
import { numeric, required } from '../../../utils/helpers/validation';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import { numberParser } from '../../../utils/helpers/helper';

const PowerMeterTypeForm = ({
	data,
	isLoading,
	setTitle,
	onFormChange,
	isSubmitted,
	setIsValid,
	submitButtonAttr,
	submit,
}) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [
		amperage,
		amperageOnChange,
		amperageValRes,
		amperageShowVal,
		setAmperageShowVal,
	] = useField(data, 'amperage', onFormChange, [required, numeric], null, numberParser(false));

	const [voltage, voltageOnChange, voltageValRes, voltageShowVal, setVoltageShowVal] = useField(
		data,
		'voltage',
		onFormChange,
		[required, numeric],
		null,
		numberParser(false)
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setAmperageShowVal();
			setVoltageShowVal();
		}
	}, [isSubmitted, setNameShowVal, setAmperageShowVal, setVoltageShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && amperageValRes.isValid && voltageValRes.isValid);
	}, [nameValRes.isValid, amperageValRes.isValid, voltageValRes.isValid, setIsValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.campground.default.text,
				path: pages.campground.dashboard.path,
			},
			{
				title: pages.campground.settings.text,
				path: pages.campground.settings.path,
			},
			{
				title: pages.campground.settings.powerMeterTypes.text,
				path: pages.campground.settings.powerMeterTypes.path,
			},
			{
				title: name || `New ${pages.campground.settings.powerMeterTypes.text}`,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(name || `New ${pages.campground.settings.powerMeterTypes.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup row>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colLg={6}>
								<Input
									type='text'
									placeholder='Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='amperage'
								label='Amperage'
								id={data.id}
								valRes={amperageValRes}
								showValidation={amperageShowVal}
								colLg={3}>
								<Input
									type='number'
									placeholder='Amperage (Required)'
									value={amperage}
									onChange={amperageOnChange}
									onBlur={setAmperageShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='voltage'
								label='Voltage'
								id={data.id}
								valRes={voltageValRes}
								showValidation={voltageShowVal}
								colLg={3}>
								<Input
									type='number'
									placeholder='Voltage (Required)'
									value={voltage}
									onChange={voltageOnChange}
									onBlur={setVoltageShowVal}
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
PowerMeterTypeForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		amperage: PropTypes.number,
		voltage: PropTypes.object,
	}),
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setIsValid: PropTypes.func,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
PowerMeterTypeForm.defaultProps = {
	data: {
		id: 0,
	},
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default PowerMeterTypeForm;
