import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SlideDown } from 'react-slidedown';

import useModal from '../../../../../utils/hooks/useModal';
import useField from '../../../../../utils/hooks/useField';
import useFeet from '../../../../../utils/hooks/useFeet';
import apiCall, { parseData } from '../../../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	addSuccessNotification,
	numberParser,
	parseDatePickerChange,
	parseDatePickerValue,
	phoneNumberParser,
} from '../../../../../utils/helpers/helper';
import {
	email as emailValidation,
	maxLength,
	phoneNumber,
	required,
} from '../../../../../utils/helpers/validation';

import FormSection from '../../../layout/FormSection';
import FormSectionMoreLink from '../../../element/FormSectionMoreLink';
import Button from '../../../element/Button';
import FormField from '../../../template/FormField';
import Input from '../../../field/Input';
import LengthInputGroup from '../../../field/LengthInputGroup';
import Selects from '../../../field/Selects';
import DatePicker from '../../../field/DatePicker';
import Toggle from '../../../field/Toggle';
import VesselModal from '../../../modals/VesselModal';

const modals = {
	VESSEL: 'vessel',
};

const Vessel = ({
	sectionRef,
	reservationItem,
	customer,
	vessel,
	disabled,
	enumBoatMakes,
	enumBoatTypes,
	enumFuelTypes,
	onFormChange,
	onVesselChange,
	setSectionIsValid,
}) => {
	const data = useRef(vessel);

	const [showMore, setShowMore] = useState(false);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [isValid, setIsValid] = useState(false);

	const [modal, openModal, closeModal] = useModal();

	const [isEditing, setIsEditing] = useState(false);

	const fieldsDisabled = useMemo(() => {
		if (isEditing) return false;

		return disabled || !!data.current.id;
	}, [disabled, isEditing]);

	const display = useMemo(() => {
		return {
			clear: !isEditing && !!data.current.id,
			create: !isEditing,
			edit: !!data.current.id && !isEditing,
			save: isEditing,
			cancel: isEditing,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditing]);

	useEffect(() => {
		setShowMore(!data.current.id || isEditing);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditing]);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data.current,
		'name',
		onFormChange,
		[required]
	);

	const [loa, loaOnChange] = useField(data.current, 'loa', onFormChange, [], 0, numberParser());

	const [loaFt, setLoaFt, loaIn, setLoaIn, , setLoa] = useFeet(
		data.current.loa,
		loaOnChange,
		false,
		data.current.loa
	);

	const [beam, beamOnChange] = useField(
		data.current,
		'beam',
		onFormChange,
		[],
		0,
		numberParser()
	);

	const [beamFt, setBeamFt, beamIn, setBeamIn, , setBeam] = useFeet(
		data.current.beam,
		beamOnChange,
		false,
		data.current.beam
	);

	const [draft, draftOnChange] = useField(
		data.current,
		'draft',
		onFormChange,
		[],
		0,
		numberParser()
	);

	const [draftFt, setDraftFt, draftIn, setDraftIn, , setDraft] = useFeet(
		data.current.draft,
		draftOnChange,
		false,
		data.current.beam
	);

	const [height, heightOnChange] = useField(
		data.current,
		'height',
		onFormChange,
		[],
		0,
		numberParser()
	);

	const [heightFt, setHeightFt, heightIn, setHeightIn, , setHeight] = useFeet(
		data.current.height,
		heightOnChange,
		false,
		data.current.height
	);

	const [weight, weightOnChange] = useField(
		data.current,
		'weight',
		onFormChange,
		[],
		0,
		numberParser()
	);

	const [
		boatType,
		boatTypeOnChange,
		boatTypeValRes,
		boatTypeShowVal,
		setBoatTypeShowVal,
	] = useField(data.current, 'boatType', onFormChange, [required], null);

	const [make, makeOnChange] = useField(data.current, 'make', onFormChange, [], null);

	const [model, modelOnChange] = useField(data.current, 'model', onFormChange);

	const [year, yearOnChange] = useField(data.current, 'year', onFormChange);

	const [hullColor, hullColorOnChange] = useField(data.current, 'hullColor', onFormChange);

	const [registration, registrationOnChange] = useField(
		data.current,
		'registration',
		onFormChange
	);

	const [serialNumber, serialNumberOnChange] = useField(
		data.current,
		'serialNumber',
		onFormChange
	);

	const [trailerTagNumber, trailerTagNumberOnChange] = useField(
		data.current,
		'trailerTagNumber',
		onFormChange
	);

	const [trailerDescription, trailerDescriptionOnChange] = useField(
		data.current,
		'trailerDescription',
		onFormChange
	);

	const [fuelType, fuelTypeOnChange] = useField(data.current, 'fuelType', onFormChange, [], null);

	const [insuranceCompany, insuranceCompanyOnChange] = useField(
		data.current,
		'insuranceCompany',
		onFormChange
	);

	const [insurancePolicyNumber, insurancePolicyNumberOnChange] = useField(
		data.current,
		'insurancePolicyNumber',
		onFormChange
	);

	const [insuranceExpirationDate, insuranceExpirationDateOnChange] = useField(
		data.current,
		'insuranceExpirationDate',
		onFormChange,
		[],
		null
	);

	const [additionalInsurance, additionalInsuranceOnChange] = useField(
		data.current,
		'additionalInsurance',
		() => {},
		[],
		false
	);

	const [coiReceived, coiReceivedOnChange] = useField(
		data.current,
		'coiReceived',
		() => {},
		[],
		false
	);

	const [agentName, agentNameOnChange] = useField(data.current, 'agentName', () => {});

	const [
		agentPhone,
		agentPhoneOnChange,
		agentPhoneValRes,
		agentPhoneShowVal,
		setAgentPhoneShowVal,
	] = useField(
		data.current,
		'agentPhone',
		() => {},
		[maxLength(), phoneNumber],
		'',
		phoneNumberParser
	);

	const [
		agentEmail,
		agentEmailOnChange,
		agentEmailValRes,
		agentEmailShowVal,
		setAgentEmailShowVal,
	] = useField(data.current, 'agentEmail', () => {}, [maxLength(), emailValidation]);

	const [isFullyImported, isFullyImportedOnChange] = useField(
		data.current,
		'isFullyImported',
		() => {},
		[],
		false
	);

	const [importExpirationDate, importExpirationDateOnChange] = useField(
		data.current,
		'importExpirationDate',
		onFormChange,
		[],
		null
	);

	const onReset = (_vessel = null) => {
		data.current.id = _vessel?.id || 0;
		nameOnChange({ target: { value: _vessel?.name || '' } });
		setLoa(_vessel?.loa || 0);
		setBeam(_vessel?.beam || 0);
		setDraft(_vessel?.draft || 0);
		setHeight(_vessel?.height || 0);
		weightOnChange({ target: { value: _vessel?.weight || 0 } });
		makeOnChange({ target: { value: _vessel?.make || null } });
		modelOnChange({ target: { value: _vessel?.model || '' } });
		yearOnChange({ target: { value: _vessel?.year || '' } });
		hullColorOnChange({ target: { value: _vessel?.hullColor || '' } });
		registrationOnChange({ target: { value: _vessel?.registration || '' } });
		serialNumberOnChange({ target: { value: _vessel?.serialNumber || '' } });
		trailerTagNumberOnChange({ target: { value: _vessel?.trailerTagNumber || '' } });
		trailerDescriptionOnChange({ target: { value: _vessel?.trailerDescription || '' } });
		fuelTypeOnChange({ target: { value: _vessel?.fuelType || null } });
		insuranceCompanyOnChange({ target: { value: _vessel?.insuranceCompany || '' } });
		insurancePolicyNumberOnChange({ target: { value: _vessel?.insurancePolicyNumber || '' } });
		insuranceExpirationDateOnChange({
			target: { value: _vessel?.insuranceExpirationDate || null },
		});
		additionalInsuranceOnChange({ target: { value: _vessel?.additionalInsurance || false } });
		coiReceivedOnChange({ target: { value: _vessel?.coiReceived || false } });
		agentNameOnChange({ target: { value: _vessel?.agentName || '' } });
		agentPhoneOnChange({ target: { value: _vessel?.agentPhone || '' } });
		agentEmailOnChange({ target: { value: _vessel?.agentEmail || '' } });
		isFullyImportedOnChange({ target: { value: _vessel?.isFullyImported || false } });
		importExpirationDateOnChange({
			target: { value: _vessel?.importExpirationDate || null },
		});
	};

	const onCreate = () => {
		if (data.current.id) {
			onReset();
			setIsEditing(true);
		} else onSave();
	};

	const onSave = () => {
		setIsSubmitted(true);

		if (!isValid) return;

		setIsSubmitting(true);

		const formData = JSON.parse(
			JSON.stringify({
				customer,
				name,
				loa,
				beam,
				draft,
				height,
				weight,
				boatType,
				make,
				model,
				year,
				hullColor,
				registration,
				serialNumber,
				trailerTagNumber,
				trailerDescription,
				fuelType,
				insuranceCompany,
				insurancePolicyNumber,
				insuranceExpirationDate,
				additionalInsurance,
				coiReceived,
				agentName,
				agentPhone,
				agentEmail,
				isFullyImported,
				importExpirationDate,
			})
		);

		// Parse vessel for api call.
		Object.keys(formData).forEach(field => {
			formData[field] = parseData(formData[field]);
		});

		apiCall(
			vessel?.id ? 'PUT' : 'POST',
			'vessels',
			res => {
				addSuccessNotification(`Vessel successfully ${vessel?.id ? 'updated' : 'created'}`);
				setIsSubmitting(false);
				onVesselChange(res);
				setIsEditing(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsSubmitting(false);
			},
			vessel?.id || '',
			formData
		);
	};

	const onCancel = () => {
		if (reservationItem?.vessel?.id) onReset(reservationItem.vessel);

		setIsEditing(false);
	};

	useEffect(() => setIsValid(nameValRes.isValid), [nameValRes.isValid]);

	useEffect(() => {
		if (isSubmitted) setNameShowVal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setSectionIsValid(!isEditing), [isEditing]);

	return (
		<>
			<FormSection
				sectionRef={sectionRef}
				title='VESSEL'
				icon='Marina'
				headerActionLabel={disabled ? '' : 'Existing Vessel'}
				onHeaderActionClick={() => openModal({ open: modals.VESSEL })}>
				<div className='sdms-advanced-reservation-item-form-vessel'>
					<div className='row sdms-mb-15'>
						<FormField
							name='name'
							label='Vessel Name'
							valRes={nameValRes}
							showValidation={nameShowVal}
							inFormDesign={false}
							colMd={4}>
							<Input
								type='text'
								placeholder='Name (Required)'
								value={name}
								onChange={nameOnChange}
								onBlur={setNameShowVal}
								disabled={fieldsDisabled}
							/>
						</FormField>
						<FormField
							name='loaFt'
							label='Length Overall'
							inFormDesign={false}
							colMd={4}>
							<LengthInputGroup
								ft={loaFt}
								ftOnChange={setLoaFt}
								inch={loaIn}
								inchOnChange={setLoaIn}
								placeHolder='Length Overall'
								disabled={fieldsDisabled}
							/>
						</FormField>
						<FormField name='beamFt' label='Beam' inFormDesign={false} colMd={4}>
							<LengthInputGroup
								ft={beamFt}
								ftOnChange={setBeamFt}
								inch={beamIn}
								inchOnChange={setBeamIn}
								placeHolder='Beam'
								disabled={fieldsDisabled}
							/>
						</FormField>
						<FormField name='draftFt' label='Draft' inFormDesign={false} colMd={4}>
							<LengthInputGroup
								ft={draftFt}
								ftOnChange={setDraftFt}
								inch={draftIn}
								inchOnChange={setDraftIn}
								placeHolder='Draft'
								disabled={fieldsDisabled}
							/>
						</FormField>
						<FormField name='heightFt' label='Height' inFormDesign={false} colMd={4}>
							<LengthInputGroup
								ft={heightFt}
								ftOnChange={setHeightFt}
								inch={heightIn}
								inchOnChange={setHeightIn}
								placeHolder='Height'
								disabled={fieldsDisabled}
							/>
						</FormField>
						<FormField name='weight' label='Weight' inFormDesign={false} colMd={4}>
							<Input
								type='number'
								placeholder='Weight'
								value={weight}
								onChange={e => {
									weightOnChange({
										target: {
											value: parseInt(e.target.value, 10),
										},
									});
								}}
								append='lbs'
								disabled={fieldsDisabled}
							/>
						</FormField>
					</div>
					<SlideDown>
						{showMore ? (
							<div className='row sdms-mb-15'>
								<FormField
									name='boatType'
									label='Boat Type'
									inFormDesign={false}
									colMd={4}
									valRes={boatTypeValRes}
									showValidation={boatTypeShowVal}>
									<Selects
										options={enumBoatTypes}
										placeholder='Boat type (Required)'
										value={boatType}
										onChange={boatTypeOnChange}
										onBlur={setBoatTypeShowVal}
										displayKey='value'
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField name='make' label='Make' inFormDesign={false} colMd={4}>
									<Selects
										options={enumBoatMakes}
										placeholder='Make'
										value={make}
										onChange={makeOnChange}
										displayKey='value'
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='model'
									label='Model'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Model'
										value={model}
										onChange={modelOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField name='year' label='Year' inFormDesign={false} colMd={4}>
									<Input
										type='number'
										withOutSpin
										placeholder='Year'
										value={year}
										onChange={yearOnChange}
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='hullColor'
									label='Hull Color'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Hull Color'
										value={hullColor}
										onChange={hullColorOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='registration'
									label='Registration'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Registration'
										value={registration}
										onChange={registrationOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='serialNumber'
									label='Serial Number'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Serial Number'
										value={serialNumber}
										onChange={serialNumberOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='trailerTagNumber'
									label='Trailer Tag #'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Trailer Tag #'
										value={trailerTagNumber}
										onChange={trailerTagNumberOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='trailerDescription'
									label='Trailer Description'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Trailer Description'
										value={trailerDescription}
										onChange={trailerDescriptionOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='fuelType'
									label='Fuel Type'
									inFormDesign={false}
									colMd={4}>
									<Selects
										options={enumFuelTypes}
										placeholder='Fuel Type'
										value={fuelType}
										onChange={fuelTypeOnChange}
										displayKey='value'
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='insuranceCompany'
									label='Insurance Company'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Insurance Company'
										value={insuranceCompany}
										onChange={insuranceCompanyOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='policyNumber'
									label='Insurance Policy Number'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Insurance Policy Number'
										value={insurancePolicyNumber}
										onChange={insurancePolicyNumberOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='insuranceExpirationDate'
									label='Insurance Expiration Date'
									inFormDesign={false}
									colMd={4}>
									<DatePicker
										id='insuranceExpirationDate'
										type='calendar'
										placeholder='Insurance Expiration Date'
										value={parseDatePickerValue(insuranceExpirationDate)}
										onChange={e =>
											insuranceExpirationDateOnChange({
												target: {
													value: parseDatePickerChange(
														e.target.value,
														insuranceExpirationDate
													),
												},
											})
										}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='agentName'
									label='Agent Name'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Agent Name'
										value={agentName}
										onChange={agentNameOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='agentPhone'
									label='Agent Phone'
									inFormDesign={false}
									colMd={4}
									valRes={agentPhoneValRes}
									showValidation={agentPhoneShowVal}>
									<Input
										type='text'
										placeholder='(555) 555-5555'
										value={agentPhone}
										onChange={agentPhoneOnChange}
										onBlur={setAgentPhoneShowVal}
										mask={process.env.REACT_APP_PHONE_FORMAT}
										xAutoCompleteType='phone-national'
										autoComplete
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='agentEmail'
									label='Agent Email'
									valRes={agentEmailValRes}
									showValidation={agentEmailShowVal}
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Agent Email'
										value={agentEmail}
										onChange={agentEmailOnChange}
										onBlur={setAgentEmailShowVal}
										xAutoCompleteType='email'
										autoComplete
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='coiReceived'
									label='COI Received'
									inFormDesign={false}
									colMd={2}>
									<Toggle
										value={coiReceived}
										onChange={coiReceivedOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='additionalInsurance'
									label='Additional Insured'
									inFormDesign={false}
									colMd={2}>
									<Toggle
										value={additionalInsurance}
										onChange={additionalInsuranceOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='isFullyImported'
									label='Fully Imported'
									inFormDesign={false}
									colMd={4}>
									<Toggle
										value={isFullyImported}
										onChange={e => {
											isFullyImportedOnChange(e);

											importExpirationDateOnChange({
												target: { value: null },
											});
										}}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='importExpirationDate'
									label='Temp Import Expiration'
									inFormDesign={false}
									colMd={4}>
									<DatePicker
										id='importExpirationDate'
										type='calendar'
										placeholder='Temp Import Expiration Date'
										value={parseDatePickerValue(importExpirationDate)}
										disabled={data.current.isFullyImported || fieldsDisabled}
										onChange={e =>
											importExpirationDateOnChange({
												target: {
													value: parseDatePickerChange(
														e.target.value,
														importExpirationDate
													),
												},
											})
										}
									/>
								</FormField>
							</div>
						) : null}
					</SlideDown>
					<div className='row sdms-mb-15 sdms-row-align-items'>
						<div className='col-auto'>
							<FormSectionMoreLink
								text='Type, Make, Model & More'
								isOpen={showMore}
								onClick={() => setShowMore(!showMore)}
							/>
						</div>
						<div className='col sdms-flex-end sdms-flex'>
							{display.clear && (
								<Button
									className='sdms-mr-10'
									design='default'
									icon='Error-circle'
									size='sm'
									text='Clear'
									onClick={() => onVesselChange(null)}
									disabled={disabled || isSubmitting}
								/>
							)}
							{display.edit && (
								<Button
									design='default'
									icon='Edit'
									size='sm'
									text='Edit'
									onClick={() => setIsEditing(true)}
									disabled={disabled || isSubmitting}
									isSubmitting={isSubmitting}
								/>
							)}
							{display.create && (
								<Button
									className='sdms-ml-10'
									design='default'
									icon='Marina'
									size='sm'
									text={isSubmitting ? 'Creating' : 'Create New Vessel'}
									onClick={onCreate}
									disabled={disabled || isSubmitting}
									isSubmitting={isSubmitting}
								/>
							)}
							{display.cancel && (
								<Button
									className='sdms-mr-10'
									design='default'
									icon='Error-circle'
									size='sm'
									text='Cancel'
									onClick={onCancel}
									disabled={isSubmitting}
								/>
							)}
							{display.save && (
								<Button
									design='info'
									icon='Save'
									size='sm'
									text={isSubmitting ? 'Saving' : 'Save'}
									onClick={onSave}
									disabled={isSubmitting}
									isSubmitting={isSubmitting}
								/>
							)}
						</div>
					</div>
				</div>
			</FormSection>
			<VesselModal
				isOpen={modal.open === modals.VESSEL}
				onClose={closeModal}
				defaultVessel={vessel.id ? vessel : null}
				onSelect={v => {
					onVesselChange(v);
					closeModal();
				}}
				defaultSearchText={customer?.displayName || ''}
				hasForm={false}
			/>
		</>
	);
};

Vessel.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	sectionRef: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	vessel: PropTypes.object,
	disabled: PropTypes.bool,
	enumBoatMakes: PropTypes.arrayOf(PropTypes.object),
	enumBoatTypes: PropTypes.arrayOf(PropTypes.object),
	enumFuelTypes: PropTypes.arrayOf(PropTypes.object),
	onFormChange: PropTypes.func,
	onVesselChange: PropTypes.func,
	setSectionIsValid: PropTypes.func,
};

Vessel.defaultProps = {
	sectionRef: null,
	reservationItem: null,
	customer: null,
	vessel: {},
	disabled: false,
	enumBoatMakes: [],
	enumBoatTypes: [],
	enumFuelTypes: [],
	onFormChange: () => {},
	onVesselChange: () => {},
	setSectionIsValid: () => {},
};

export default Vessel;
