import React from 'react';
import PropTypes from 'prop-types';

import { pathToUrl } from '../../../../../utils/helpers/apiCall';
import { _defaultImage } from '../../../field/ImageUpload';

const ItemImage = ({ image }) => {
	return (
		<div
			className='sdms-booking-item__image'
			style={{
				backgroundImage: image ? `url(${pathToUrl(image)})` : `${_defaultImage}`,
				backgroundSize: image ? null : '120%',
			}}
		/>
	);
};
ItemImage.propTypes = {
	image: PropTypes.string,
};
ItemImage.defaultProps = {
	image: null,
};

export default ItemImage;
