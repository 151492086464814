import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import { extraChargeTypes } from '../../../../../utils/constants/constants';
import { getReservationTotals } from '../../../../../utils/helpers/reservationHelper';

import Portlet from '../../../layout/Portlet';
import Button from '../../../element/Button';
import Portal from '../../../layout/Portal';
import { ExtraFeeHead, ExtraFeeRow } from '../../../element/ExtraFee';

const ReservationExtraChargeContent = ({
	onClose,
	extraCharges,
	reservationExtraCharges,
	customer,
	onChange,
	onSave,
	isSubmitting,
	reservation,
	taxRate,
}) => {
	const getMax = extraChargeId => {
		return getReservationTotals(reservation, taxRate, true, extraChargeId).subtotal;
	};

	return (
		<Portlet className='sdms-list-layout' fluid='fluid' isLast everyTimeFluid>
			<Portlet.Head wrapMaxSize='md'>
				<Portlet.HeadLabel portletIcon='Component'>
					<h3 className='sdms-portlet__head-title'>Reservation Price Adjustment</h3>
				</Portlet.HeadLabel>
			</Portlet.Head>
			<Portlet.Body>
				<table className='table table--responsive-col--6 sdms-reservation-extra-charge-table'>
					<ExtraFeeHead displayQuantity={false} />
					{extraCharges
						.filter(ec => ec.extraChargeType.value !== extraChargeTypes.PER_UNIT)
						.map((extraCharge, index) => (
							<ExtraFeeRow
								key={extraCharge.id}
								index={index}
								data={reservationExtraCharges.find(
									sec => sec.extraCharge.id === extraCharge.id
								)}
								extraCharge={extraCharge}
								onUpdate={onChange}
								onFormChange={() => {}}
								currentExtraChargeItemProductId={null}
								addByDefault={!extraCharge.optional}
								customer={customer}
								disabled={isSubmitting}
								displayQuantity={false}
								max={getMax(extraCharge.id)}
							/>
						))}
				</table>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Close'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
						disabled={isSubmitting}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='brand'
						text={
							isSubmitting
								? process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT
								: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT
						}
						size='sm'
						onClick={onSave}
						icon={
							isSubmitting
								? process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON
								: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON
						}
						disabled={isSubmitting}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};

ReservationExtraChargeContent.propTypes = {
	onClose: PropTypes.func.isRequired,
	extraCharges: PropTypes.arrayOf(PropTypes.object).isRequired,
	reservationExtraCharges: PropTypes.arrayOf(PropTypes.object).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	taxRate: PropTypes.object,
};

ReservationExtraChargeContent.defaultProps = {
	customer: null,
	reservation: null,
	taxRate: null,
};

const ReservationExtraCharge = ({
	isOpen,
	onClose,
	extraCharges,
	reservationExtraCharges,
	customer,
	onChange,
	onSave,
	isSubmitting,
	reservation,
	taxRate,
}) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				closeOnEscape={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '780px',
					minHeight: '480px',
					height: '50%',
				}}>
				<ReservationExtraChargeContent
					onClose={onClose}
					extraCharges={extraCharges}
					reservationExtraCharges={reservationExtraCharges}
					customer={customer}
					onChange={onChange}
					onSave={onSave}
					isSubmitting={isSubmitting}
					reservation={reservation}
					taxRate={taxRate}
				/>
			</Popup>
		</Portal>
	);
};

ReservationExtraCharge.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	extraCharges: PropTypes.arrayOf(PropTypes.object),
	reservationExtraCharges: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	onChange: PropTypes.func,
	onSave: PropTypes.func,
	isSubmitting: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	taxRate: PropTypes.object,
};

ReservationExtraCharge.defaultProps = {
	isOpen: false,
	onClose: () => {},
	extraCharges: [],
	reservationExtraCharges: [],
	customer: null,
	onChange: () => {},
	onSave: () => {},
	isSubmitting: false,
	reservation: null,
	taxRate: null,
};

export default ReservationExtraCharge;
