import React, { useState, useContext } from 'react';
import { PropTypes } from 'prop-types';
import { NavLink, withRouter, useRouteMatch, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { noPermissionStatus } from '../utils/helpers/helper';
import MessageContext from '../app/contexts/MessageContext';
import SVGIcon from './reusables/element/SVGIcon';

const Item = ({
	children,
	className,
	icon,
	text,
	path,
	location,
	parentText,
	selectedText,
	setSelectedText,
	noPermission,
	isLoading,
}) => {
	const handleNavItem = isMain => {
		const checkText = isMain ? text : parentText;
		if (checkText === selectedText) setSelectedText('');
		else setSelectedText(checkText);
	};
	const match = useRouteMatch(path);
	// const messageCount = useMessageStore(state => state.messageCount);
	const history = useHistory();

	// if text is messages, return noFlex
	const isMessages = moduleText => {
		if (moduleText === 'Messages') return 'sdms-message-menu__link-text';
		return '';
	};
	const goPath = () => history.push(`${path}/dashboard`);

	if (children) {
		return (
			<li
				className={classNames(
					'sdms-menu__item',
					'sdms-menu__item--submenu',
					{ 'sdms-menu__item--open': selectedText === text },
					{ 'sdms-menu__item--here': match },
					{ 'is-loading': isLoading },
					className
				)}
				title={text}>
				<span
					className={classNames(
						'sdms-menu__link',
						'sdms-menu__toggle',
						noPermissionStatus(noPermission)
					)}
					aria-hidden='true'>
					<span
						className='sdms-menu__link-icon'
						onClick={!noPermission && goPath}
						aria-hidden='true'>
						<SVGIcon name={icon} />
					</span>
					<span
						className={classNames(`sdms-menu__link-text`, isMessages(text))}
						onClick={!noPermission && goPath}
						aria-hidden='true'>
						{text}
					</span>
					{text === 'Message Center' && <MessageItem />}
					<SVGIcon
						name='Angle-right'
						className='sdms-menu__ver-arrow'
						size={16}
						onClick={() => handleNavItem(true)}
					/>
				</span>
				<div className='sdms-menu__submenu'>
					<span className='sdms-menu__arrow' />
					<ul className='sdms-menu__subnav'>{children}</ul>
				</div>
			</li>
		);
	}
	return (
		<li
			className={classNames(
				'sdms-menu__item',
				{
					'sdms-menu__item--active': location.pathname.includes(path),
				},
				{ 'is-loading': isLoading }
			)}>
			<NavLink
				strict
				to={noPermission ? '#' : path}
				activeClassName='selected'
				className={classNames('sdms-menu__link', noPermissionStatus(noPermission))}
				onClick={selectedText ? () => handleNavItem(false) : null}>
				<span className='sdms-menu__link-icon'>
					<SVGIcon name={icon} />
				</span>
				<span className='sdms-menu__link-text'>{text}</span>
			</NavLink>
		</li>
	);
};
Item.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	icon: PropTypes.string,
	text: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	location: PropTypes.shape({
		pathname: PropTypes.string,
	}),
	parentText: PropTypes.string,
	selectedText: PropTypes.string,
	setSelectedText: PropTypes.func,
	noPermission: PropTypes.bool,
	isLoading: PropTypes.bool,
};
Item.defaultProps = {
	children: null,
	className: null,
	icon: 'Layers',
	location: null,
	parentText: '',
	selectedText: '',
	setSelectedText: () => {},
	noPermission: null,
	isLoading: false,
};

const MessageItem = () => {
	const messageContext = useContext(MessageContext);

	if (!messageContext || !messageContext.count || messageContext.count === '0') return null;

	return (
		<span className={classNames(`sdms-menu__link-notification`)}>{messageContext.count}</span>
	);
};

const Section = ({ className, text }) => {
	return (
		<li className={classNames('sdms-menu__section', className)}>
			<h4 className='sdms-menu__section-text'>{text}</h4>
			<i className='sdms-menu__section-icon sdms-menu__link-icon'>
				<SVGIcon name='Other#2' />
			</i>
		</li>
	);
};
Section.propTypes = {
	className: PropTypes.string,
	text: PropTypes.string.isRequired,
};
Section.defaultProps = {
	className: null,
};

const More = ({ className, children }) => {
	const [moreStatus, setMoreStatus] = useState(false);
	const moreClick = () => {
		setMoreStatus(!moreStatus);
	};
	const moreClose = () => {
		setMoreStatus(false);
	};
	return (
		<li
			className={classNames(
				'sdms-menu__item',
				'sdms-menu__item--submenu',
				{ 'sdms-menu__item--open-dropdown': moreStatus },
				{ 'sdms-menu__item--hover': moreStatus },
				{ 'sdms-menu__item--rel': moreStatus },
				{ 'sdms-menu__item--here': moreStatus },
				className
			)}
			role='presentation'
			onClick={moreStatus ? moreClose : null}
			onMouseLeave={moreClose}>
			<span
				className='sdms-menu__link sdms-menu__toggle'
				aria-hidden='true'
				onClick={moreClick}>
				<span className='sdms-menu__link-icon' aria-hidden='true'>
					<SVGIcon name='Other#2' />
				</span>
				<span className='sdms-menu__link-text' aria-hidden='true'>
					More
				</span>
				<SVGIcon name='Angle-right' className='sdms-menu__ver-arrow' size={16} />
			</span>
			<div className='sdms-menu__submenu sdms-menu__submenu--classic sdms-menu__submenu--right'>
				<span className='sdms-menu__arrow' />
				<ul className='sdms-menu__subnav'>{children}</ul>
			</div>
		</li>
	);
};
More.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};
More.defaultProps = {
	className: null,
};

const TerItem = ({
	className,
	isActive,
	isDisable,
	children,
	text,
	icon,
	onClick,
	noPermission,
}) => {
	return (
		<li
			className={classNames(
				'sdms-menu__item',
				{
					'sdms-menu__item--active': isActive,
				},
				{
					'sdms-menu__item--icon-only': !text && !children,
				},
				className
			)}>
			<span
				className={classNames(
					'sdms-menu__link',
					{ disabled: isDisable },
					noPermissionStatus(noPermission)
				)}
				aria-hidden='true'
				onClick={isDisable || noPermission ? null : onClick}>
				<span className='sdms-menu__link-icon'>
					<SVGIcon name={icon} />
				</span>
				<span className='sdms-menu__link-text'>{children || text}</span>
			</span>
		</li>
	);
};
TerItem.propTypes = {
	className: PropTypes.string,
	isActive: PropTypes.bool,
	isDisable: PropTypes.bool,
	text: PropTypes.string,
	icon: PropTypes.string,
	children: PropTypes.node,
	onClick: PropTypes.func,
	noPermission: PropTypes.bool,
};
TerItem.defaultProps = {
	className: null,
	isActive: false,
	isDisable: false,
	text: null,
	icon: 'Layers',
	children: null,
	onClick: () => {},
	noPermission: null,
};

const Nav = ({ children, className }) => {
	return <ul className={classNames('sdms-menu__nav', className)}>{children}</ul>;
};
Nav.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
Nav.defaultProps = {
	className: null,
};

Nav.Section = Section;
Nav.More = More;
Nav.Item = withRouter(Item);
Nav.TerItem = TerItem;

export default withRouter(Nav);
