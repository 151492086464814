import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import useField from '../../../utils/hooks/useField';
import usePages from '../../../utils/hooks/usePages';
import { numeric, required, uniqueFnc } from '../../../utils/helpers/validation';
import { modules } from '../../../utils/helpers/apiCall';
import { bookingCalculations, extraChargeTypes } from '../../../utils/constants/constants';
import {
	negativeNumberParser,
	numberParser,
	parseDatePickerChange,
	parseDatePickerValue,
} from '../../../utils/helpers/helper';

import Portlet from '../layout/Portlet';
import FormGroup from '../layout/FormGroup';
import FormField from '../template/FormField';
import Input from '../field/Input';
import Button from '../element/Button';
import Loading from '../template/Loading';
import Selects from '../field/Selects';
import Toggle from '../field/Toggle';
import DatePicker from '../field/DatePicker';
import MultiSelect from '../element/MultiSelect';
import Section from '../layout/Section';
import AsyncSelect from '../field/AsyncSelect';

const PromoCodeForm = ({
	data,
	setIsValid,
	submit,
	isSubmitted,
	isLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
	itemTypes,
	taxCodes,
	accounts,
	enumBookingCalculations,
	enumDays,
	enumExtraChargeTypes,
}) => {
	const location = useLocation(); // React Hook

	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const pages = usePages();

	const maxDurationValidation = (value, setValRes) => {
		if (!value) return true;
		if (minDuration > value) {
			setValRes({
				isValid: false,
				status: 'invalidMaxDuration',
				message: 'Max duration time must be equal or bigger than min duration',
			});
			return false;
		}

		return true;
	};

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [
		itemType,
		itemTypeOnChange,
		itemTypeValRes,
		itemTypeShowVal,
		setItemTypeShowVal,
	] = useField(data, 'itemType', onFormChange, [required], null);

	const [account, accountOnChange, accountValRes, accountShowVal, setAccountShowVal] = useField(
		data,
		'account',
		onFormChange,
		[required],
		null
	);

	const [
		incomeAccount,
		incomeAccountOnChange,
		incomeAccountValRes,
		incomeAccountShowVal,
		setIncomeAccountShowVal,
	] = useField(data, 'incomeAccount', onFormChange, [required], null);

	const [
		cogsAccount,
		cogsAccountOnChange,
		cogsAccountValRes,
		cogsAccountShowVal,
		setCogsAccountShowVal,
	] = useField(data, 'cogsAccount', onFormChange, [required], null);

	const [
		assetsAccount,
		assetsAccountOnChange,
		assetsAccountValRes,
		assetsAccountShowVal,
		setAssetsAccountShowVal,
	] = useField(data, 'assetsAccount', onFormChange, [required], null);

	const [taxCode, taxCodeOnChange, taxCodeValRes, taxCodeShowVal, setTaxCodeShowVal] = useField(
		data,
		'taxCode',
		onFormChange,
		[required],
		null
	);

	const [amount, amountOnChange, amountValRes, amountShowVal, setAmountShowVal] = useField(
		{},
		'amount',
		onFormChange,
		[required, numeric],
		'',
		negativeNumberParser
	);

	const [
		bookingCalculation,
		bookingCalculationOnChange,
		bookingCalculationValRes,
		bookingCalculationShowVal,
		setBookingCalculationShowVal,
	] = useField(data, 'bookingCalculation', onFormChange, [required], null);

	const [enabled, enabledOnChange] = useField(data, 'enabled', onFormChange, [], false);

	const [code, codeOnChange, codeValRes, codeShowVal, setCodeShowVal] = useField(
		data,
		'code',
		onFormChange,
		[required, uniqueFnc('promoCodes', 'code', data.id, true)]
	);

	const [creationStartDate, creationStartDateOnChange] = useField(
		data,
		'creationStartDate',
		onFormChange,
		[],
		null
	);

	const [creationEndDate, creationEndDateOnChange] = useField(
		data,
		'creationEndDate',
		onFormChange,
		[],
		null
	);

	const [bookableStartDate, bookableStartDateOnChange] = useField(
		data,
		'bookableStartDate',
		onFormChange,
		[],
		null
	);

	const [bookableEndDate, bookableEndDateOnChange] = useField(
		data,
		'bookableEndDate',
		onFormChange,
		[],
		null
	);

	const [days, daysOnChange] = useField(data, 'days', onFormChange, [], []);

	const [extraChargeType, extraChargeTypeOnChange] = useField(
		data,
		'extraChargeType',
		onFormChange,
		[],
		null
	);

	const [
		applicableMinPrice,
		applicableMinPriceOnChange,
		applicableMinPriceValRes,
		applicableMinPriceShowVal,
		setApplicableMinPriceShowVal,
	] = useField(data, 'applicableMinPrice', onFormChange, [numeric], 0, negativeNumberParser);

	const [
		applicableMaxPrice,
		applicableMaxPriceOnChange,
		applicableMaxPriceValRes,
		applicableMaxPriceShowVal,
		setApplicableMaxPriceShowVal,
	] = useField(data, 'applicableMaxPrice', onFormChange, [numeric], 0, negativeNumberParser);

	const [minDuration, minDurationOnChange] = useField(
		data,
		'minDuration',
		onFormChange,
		[],
		0,
		numberParser(false, 0)
	);

	const [
		maxDuration,
		maxDurationOnChange,
		maxDurationValRes,
		maxDurationShowVal,
		setMaxDurationShowVal,
		validateMaxDuration,
	] = useField(
		data,
		'maxDuration',
		onFormChange,
		[maxDurationValidation],
		0,
		numberParser(false, 0)
	);

	const [
		minBookInAdvance,
		minBookInAdvanceOnChange,
		minBookInAdvanceValRes,
		minBookInAdvanceShowVal,
		setMinBookInAdvanceShowVal,
	] = useField(data, 'minBookInAdvance', onFormChange, [numeric], 0, numberParser(true, 0));

	const [
		maxBookInAdvance,
		maxBookInAdvanceOnChange,
		maxBookInAdvanceValRes,
		maxBookInAdvanceShowVal,
		setMaxBookInAdvanceShowVal,
	] = useField(data, 'maxBookInAdvance', onFormChange, [numeric], 0, numberParser(true, 0));

	const [uses, usesOnChange] = useField(
		data,
		'uses',
		onFormChange,
		[],
		0,
		numberParser(false, 0)
	);

	const [requiredProducts, requiredProductsOnChange] = useField(
		data,
		'requiredProducts',
		onFormChange,
		[],
		[]
	);

	const [availableProducts, availableProductsOnChange] = useField(
		data,
		'availableProducts',
		onFormChange,
		[],
		[]
	);

	const [allOrders, setAllOrders] = useState(false);

	const moduleData = useMemo(() => {
		if (location.pathname.indexOf('marina') > -1)
			return {
				text: pages.marina.settings.maps.text,
				breadcrumb: [
					{ title: pages.marina.default.text, path: pages.marina.dashboard.path },
					{ title: pages.marina.settings.text, path: pages.marina.settings.path },
					{
						title: pages.marina.settings.promoCodes.text,
						path: pages.marina.settings.promoCodes.path,
					},
				],
				title: pages.marina.settings.promoCodes.text,
				icon: pages.marina.settings.promoCodes.icon,
				module: userContext.data.user.company.modules.find(m => m.value === modules.MARINA),
			};

		if (location.pathname.indexOf('campground') > -1)
			return {
				text: pages.campground.settings.maps.text,
				breadcrumb: [
					{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
					{ title: pages.campground.settings.text, path: pages.campground.settings.path },
					{
						title: pages.campground.settings.promoCodes.text,
						path: pages.campground.settings.promoCodes.path,
					},
				],
				title: pages.campground.settings.promoCodes.text,
				icon: pages.campground.settings.promoCodes.icon,
				module: userContext.data.user.company.modules.find(
					m => m.value === modules.CAMPGROUND
				),
			};

		return {
			text: pages.booking.settings.maps.text,
			breadcrumb: [
				{ title: pages.booking.default.text, path: pages.booking.dashboard.path },
				{ title: pages.booking.settings.text, path: pages.booking.settings.path },
				{
					title: pages.booking.settings.promoCodes.text,
					path: pages.booking.settings.promoCodes.path,
				},
			],
			title: pages.booking.settings.promoCodes.text,
			icon: pages.booking.settings.promoCodes.icon,
			module: userContext.data.user.company.modules.find(m => m.value === modules.BOOKINGS),
		};
	}, [location.pathname, userContext.data.user.company.modules, pages]);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setBookingCalculationShowVal();
			setAmountShowVal();
			setTaxCodeShowVal();
			setItemTypeShowVal();
			setAccountShowVal();
			setIncomeAccountShowVal();
			setCogsAccountShowVal();
			setAssetsAccountShowVal();
			setCodeShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setAmountShowVal,
		setBookingCalculationShowVal,
		setTaxCodeShowVal,
		setItemTypeShowVal,
		setAccountShowVal,
		setIncomeAccountShowVal,
		setCogsAccountShowVal,
		setAssetsAccountShowVal,
		setCodeShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				amountValRes.isValid &&
				taxCodeValRes.isValid &&
				itemTypeValRes.isValid &&
				bookingCalculationValRes.isValid &&
				itemType &&
				(itemType.value === 'inventory'
					? cogsAccountValRes.isValid &&
					  incomeAccountValRes.isValid &&
					  assetsAccountValRes.isValid
					: accountValRes.isValid) &&
				codeValRes.isValid
		);
	}, [
		nameValRes.isValid,
		amountValRes.isValid,
		taxCodeValRes.isValid,
		itemTypeValRes.isValid,
		accountValRes.isValid,
		bookingCalculationValRes.isValid,
		incomeAccountValRes.isValid,
		cogsAccountValRes.isValid,
		assetsAccountValRes.isValid,
		itemType,
		codeValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			...moduleData.breadcrumb,
			{ title: name || `New ${moduleData.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${moduleData.text}`);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		if (!isLoading && data.id === 0 && enumExtraChargeTypes.length) {
			data.module = moduleData.module;
			data.optional = true;
			data.extraChargeType = enumExtraChargeTypes.find(
				exct => exct.value === extraChargeTypes.PER_UNIT
			);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading, moduleData, enumExtraChargeTypes]);

	useEffect(() => {
		if (!isLoading && data.id !== 0) {
			amountOnChange({ target: { value: parseFloat(data.price) * -1 } });
			setAllOrders(data.extraChargeType?.value === extraChargeTypes.ALL_ORDERS);
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						<div className='col-12'>
							<Section title='General'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='name'
												label='Name'
												id={data.id}
												valRes={nameValRes}
												showValidation={nameShowVal}
												colMd={4}>
												<Input
													type='text'
													placeholder='Name (Required)'
													value={name}
													onChange={nameOnChange}
													onBlur={setNameShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='code'
												label='Code'
												id={data.id}
												colMd={4}
												valRes={codeValRes}
												showValidation={codeShowVal}>
												<Input
													type='text'
													placeholder='Code'
													value={code}
													onChange={codeOnChange}
													onBlur={setCodeShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='enabled'
												label='Enabled'
												id={data.id}
												colMd={2}>
												<Toggle
													onChange={enabledOnChange}
													value={enabled}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='allOrders'
												label='All Orders'
												id={data.id}
												colMd={2}>
												<Toggle
													onChange={e => {
														extraChargeTypeOnChange({
															target: {
																value: enumExtraChargeTypes.find(
																	ect =>
																		ect.value ===
																		(e.target.value
																			? extraChargeTypes.ALL_ORDERS
																			: extraChargeTypes.PER_UNIT)
																),
															},
														});
														setAllOrders(e.target.value);
													}}
													value={
														extraChargeType?.value ===
														extraChargeTypes.ALL_ORDERS
													}
												/>
											</FormField>
										</Loading>
									</FormGroup>
								</Section.Body>
							</Section>
							<Section title='Accounting'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='itemType'
												label='Item Type'
												valRes={itemTypeValRes}
												showValidation={itemTypeShowVal}
												id={data.id}
												colMd={4}>
												<Selects
													options={itemTypes.filter(it => !it.isSystem)}
													placeholder='Type (Required)'
													value={itemType}
													onChange={({ target }) => {
														itemTypeOnChange({ target });
														accountOnChange({
															target: {
																name: 'account',
																value: null,
															},
														});
														incomeAccountOnChange({
															target: {
																name: 'incomeAccount',
																value: null,
															},
														});
														cogsAccountOnChange({
															target: {
																name: 'cogsAccount',
																value: null,
															},
														});
														assetsAccountOnChange({
															target: {
																name: 'assetsAccount',
																value: null,
															},
														});
													}}
													onBlur={setItemTypeShowVal}
													displayKey='value'
													disabled={data.id !== 0}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='taxCode'
												label='Tax Code'
												id={data.id}
												valRes={taxCodeValRes}
												showValidation={taxCodeShowVal}
												colMd={4}>
												<Selects
													options={taxCodes}
													placeholder='Tax Code (Required)'
													value={taxCode}
													onChange={taxCodeOnChange}
													onBlur={setTaxCodeShowVal}
												/>
											</FormField>
										</Loading>
										{(itemType || {}).value === 'inventory' ? (
											<>
												<Loading isLoading={isLoading}>
													<FormField
														name='incomeAccount'
														label='Income Account'
														id={data.id}
														valRes={incomeAccountValRes}
														showValidation={incomeAccountShowVal}
														colMd={4}>
														<Selects
															options={accounts}
															placeholder='Income Account (Required)'
															value={incomeAccount}
															onChange={incomeAccountOnChange}
															onBlur={setIncomeAccountShowVal}
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='cogsAccount'
														label='COGS Account'
														id={data.id}
														valRes={cogsAccountValRes}
														showValidation={cogsAccountShowVal}
														colMd={4}>
														<Selects
															options={accounts}
															placeholder='COGS Account (Required)'
															value={cogsAccount}
															onChange={cogsAccountOnChange}
															onBlur={setCogsAccountShowVal}
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='assetsAccount'
														label='Assets Account'
														id={data.id}
														valRes={assetsAccountValRes}
														showValidation={assetsAccountShowVal}
														colMd={4}>
														<Selects
															options={accounts}
															placeholder='Assets Account (Required)'
															value={assetsAccount}
															onChange={assetsAccountOnChange}
															onBlur={setAssetsAccountShowVal}
														/>
													</FormField>
												</Loading>
											</>
										) : (
											<Loading isLoading={isLoading}>
												<FormField
													name='account'
													label='Account'
													id={data.id}
													valRes={accountValRes}
													showValidation={accountShowVal}
													colMd={4}>
													<Selects
														options={accounts}
														placeholder='Account (Required)'
														value={account}
														onChange={accountOnChange}
														onBlur={setAccountShowVal}
													/>
												</FormField>
											</Loading>
										)}
									</FormGroup>
								</Section.Body>
							</Section>
							<Section title='Base Pricing'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='price'
												label='Amount'
												id={data.id}
												valRes={amountValRes}
												showValidation={amountShowVal}
												colMd={4}>
												<Input
													type='text'
													placeholder='Amount (Required)'
													value={amount}
													onChange={e => {
														amountOnChange(e);

														if (e.target.value)
															data.price =
																parseFloat(e.target.value) * -1;
													}}
													onBlur={setAmountShowVal}
													pattern={process.env.REACT_APP_PRICE_PATTERN}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='bookingCalculation'
												label='Calculation'
												id={data.id}
												valRes={bookingCalculationValRes}
												showValidation={bookingCalculationShowVal}
												colMd={4}>
												<Selects
													options={enumBookingCalculations.filter(
														ebc =>
															ebc.value ===
																bookingCalculations.FIXED ||
															ebc.value ===
																bookingCalculations.PERCENT
													)}
													placeholder='Calculation (Required)'
													value={bookingCalculation}
													onChange={bookingCalculationOnChange}
													onBlur={setBookingCalculationShowVal}
													displayKey='value'
												/>
											</FormField>
										</Loading>
									</FormGroup>
								</Section.Body>
							</Section>
							<Section title='Rules'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='creationStartDate'
												label='Creation Start Date'
												id={data.id}
												colMd={3}>
												<DatePicker
													id='creationStartDate'
													type='calendar'
													placeholder='Creation Start Date'
													value={parseDatePickerValue(creationStartDate)}
													onChange={e =>
														creationStartDateOnChange({
															target: {
																value: parseDatePickerChange(
																	e.target.value,
																	creationStartDate,
																	true
																),
															},
														})
													}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='creationEndDate'
												label='Creation End Date'
												id={data.id}
												colMd={3}>
												<DatePicker
													id='creationEndDate'
													type='calendar'
													placeholder='End Date'
													value={parseDatePickerValue(creationEndDate)}
													onChange={e =>
														creationEndDateOnChange({
															target: {
																value: parseDatePickerChange(
																	e.target.value,
																	creationEndDate,
																	true
																),
															},
														})
													}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='bookableStartDate'
												label='Bookable Start Date'
												id={data.id}
												colMd={3}>
												<DatePicker
													id='bookableStartDate'
													type='calendar'
													placeholder='Bookable Start Date'
													value={parseDatePickerValue(bookableStartDate)}
													onChange={e =>
														bookableStartDateOnChange({
															target: {
																value: parseDatePickerChange(
																	e.target.value,
																	bookableStartDate,
																	true
																),
															},
														})
													}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='bookableEndDate'
												label='Bookable End Date'
												id={data.id}
												colMd={3}>
												<DatePicker
													id='bookableEndDate'
													type='calendar'
													placeholder='End Date'
													value={parseDatePickerValue(bookableEndDate)}
													onChange={e =>
														bookableEndDateOnChange({
															target: {
																value: parseDatePickerChange(
																	e.target.value,
																	bookableEndDate,
																	true
																),
															},
														})
													}
												/>
											</FormField>
										</Loading>
										<div className='col-md-12'>
											<div className='row'>
												<Loading isLoading={isLoading}>
													<FormField
														name='uses'
														label='Number of Uses'
														id={data.id}
														colMd={4}>
														<Input
															type='number'
															placeholder='Number of Uses'
															value={uses}
															onChange={usesOnChange}
															pattern={
																process.env
																	.REACT_APP_INTEGER_PATTERN
															}
														/>
													</FormField>
												</Loading>
											</div>
										</div>
										<div className='col-md-12'>
											<div className='row'>
												<Loading isLoading={isLoading}>
													<FormField
														name='minPrice'
														label='Minimum Price'
														id={data.id}
														valRes={applicableMinPriceValRes}
														showValidation={applicableMinPriceShowVal}
														colMd={4}>
														<Input
															type='text'
															placeholder='Minimum Price'
															value={applicableMinPrice.toString()}
															onChange={applicableMinPriceOnChange}
															onBlur={setApplicableMinPriceShowVal}
															appendIcon='Dollar'
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='maxPrice'
														label='Maximum Price'
														id={data.id}
														valRes={applicableMaxPriceValRes}
														showValidation={applicableMaxPriceShowVal}
														colMd={4}>
														<Input
															type='text'
															placeholder='Maximum Price'
															value={applicableMaxPrice.toString()}
															onChange={applicableMaxPriceOnChange}
															onBlur={setApplicableMaxPriceShowVal}
															appendIcon='Dollar'
														/>
													</FormField>
												</Loading>
											</div>
										</div>
										<Loading isLoading={isLoading}>
											<FormField
												name='days'
												label='Days'
												id={data.id}
												col={12}>
												<MultiSelect
													data={enumDays}
													titleProp='value'
													value={days}
													onChange={daysOnChange}
													name='days'
													disabled={allOrders}
												/>
											</FormField>
										</Loading>
										<div className='col-md-12'>
											<div className='row'>
												<Loading isLoading={isLoading}>
													<FormField
														name='minDuration'
														label='Minimum Duration'
														id={data.id}
														colMd={4}>
														<Input
															type='text'
															placeholder='Min Duration'
															value={minDuration}
															onChange={e => {
																minDurationOnChange(e);
																validateMaxDuration(maxDuration);
															}}
															append='Days'
															disabled={allOrders}
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='maxDuration'
														label='Maximum Duration'
														id={data.id}
														valRes={maxDurationValRes}
														showValidation={maxDurationShowVal}
														colMd={4}>
														<Input
															type='text'
															placeholder='Max Duration'
															value={maxDuration}
															onChange={maxDurationOnChange}
															append='Days'
															onBlur={setMaxDurationShowVal}
															disabled={allOrders}
														/>
													</FormField>
												</Loading>
											</div>
										</div>
										<div className='col-md-12'>
											<div className='row'>
												<Loading isLoading={isLoading}>
													<FormField
														name='minBookInAdvance'
														label='Minimum Book in Advance'
														description='in days'
														id={data.id}
														valRes={minBookInAdvanceValRes}
														showValidation={minBookInAdvanceShowVal}
														colMd={4}>
														<Input
															type='text'
															placeholder='Minimum Book in Advance duration'
															value={minBookInAdvance}
															onChange={minBookInAdvanceOnChange}
															onBlur={setMinBookInAdvanceShowVal}
															append='Days'
															disabled={allOrders}
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='maxBookInAdvance'
														label='Maximum Book in Advance'
														description='in days'
														id={data.id}
														valRes={maxBookInAdvanceValRes}
														showValidation={maxBookInAdvanceShowVal}
														colMd={4}>
														<Input
															type='text'
															placeholder='Maximum Book in Advance duration'
															value={maxBookInAdvance}
															onChange={maxBookInAdvanceOnChange}
															onBlur={setMaxBookInAdvanceShowVal}
															append='Days'
															disabled={allOrders}
														/>
													</FormField>
												</Loading>
											</div>
										</div>
										<div className='col-md-12'>
											<div className='row'>
												<Loading isLoading={isLoading}>
													<FormField
														name='requiredProducts'
														label='Required Products'
														id={data.id}
														colMd={4}>
														<AsyncSelect
															options={data.item ? [data.item] : []}
															placeholder='Search and select item'
															value={requiredProducts}
															onChange={requiredProductsOnChange}
															route='products'
															displayKey='name'
															isMulti
															disabled={allOrders}
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='availableProducts'
														label='Available Products'
														id={data.id}
														colMd={4}>
														<AsyncSelect
															options={data.item ? [data.item] : []}
															placeholder='Search and select item'
															value={availableProducts}
															onChange={availableProductsOnChange}
															route='products'
															displayKey='name'
															isMulti
															disabled={allOrders}
														/>
													</FormField>
												</Loading>
											</div>
										</div>
									</FormGroup>
								</Section.Body>
							</Section>
						</div>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};

PromoCodeForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
	itemTypes: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	accounts: PropTypes.arrayOf(PropTypes.object),
	enumBookingCalculations: PropTypes.arrayOf(PropTypes.object),
	enumDays: PropTypes.arrayOf(PropTypes.object),
	enumExtraChargeTypes: PropTypes.arrayOf(PropTypes.object),
};

PromoCodeForm.defaultProps = {
	data: {
		id: 0,
		name: '',
	},
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
	itemTypes: [],
	taxCodes: [],
	accounts: [],
	enumBookingCalculations: [],
	enumDays: [],
	enumExtraChargeTypes: [],
};

export default PromoCodeForm;
