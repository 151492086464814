import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UserContext from '../../../../app/contexts/UserContext';
import HeaderContext from '../../../../app/contexts/HeaderContext';
import useField from '../../../../utils/hooks/useField';
import { required } from '../../../../utils/helpers/validation';
import { modules } from '../../../../utils/helpers/apiCall';
import pages from '../../../pages';
import { numberParser } from '../../../../utils/helpers/helper';

import FormField from '../../template/FormField';
import FormGroup from '../../layout/FormGroup';
import Input from '../../field/Input';
import Loading from '../../template/Loading';
import Portlet from '../../layout/Portlet';
import Button from '../../element/Button';

const UnitGroupsForm = ({
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	isLoading,
	onFormChange,
	submitButtonAttr,
	submit,
	module,
}) => {
	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const moduleData = useMemo(() => {
		if (module === modules.MARINA)
			return {
				breadcrumb: [
					{ title: pages.marina.default.text, path: pages.marina.dashboard.path },
					{ title: pages.marina.settings.text, path: pages.marina.settings.path },
					{
						title: pages.marina.settings.spaceGroups.text,
						path: pages.marina.settings.spaceGroups.path,
					},
				],
				title: pages.marina.settings.spaceGroups.text,
				icon: pages.marina.settings.spaceGroups.icon,
			};

		if (module === modules.CAMPGROUND)
			return {
				breadcrumb: [
					{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
					{ title: pages.campground.settings.text, path: pages.campground.settings.path },
					{
						title: pages.campground.settings.spaceGroups.text,
						path: pages.campground.settings.spaceGroups.path,
					},
				],
				title: pages.campground.settings.spaceGroups.text,
				icon: pages.campground.settings.spaceGroups.icon,
			};

		return {
			breadcrumb: [
				{ title: pages.booking.default.text, path: pages.booking.dashboard.path },
				{ title: pages.booking.settings.text, path: pages.booking.settings.path },
				{
					title: pages.booking.settings.unitGroups.text,
					path: pages.booking.settings.unitGroups.path,
				},
			],
			title: pages.booking.settings.unitGroups.text,
			icon: pages.booking.settings.unitGroups.icon,
		};
	}, [module]);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [sortOrder, sortOrderOnChange] = useField(
		data,
		'sortOrder',
		onFormChange,
		[],
		'',
		numberParser(false)
	);

	useEffect(() => {
		if (!isLoading)
			data.module = userContext.data.user.company.modules.find(m => m.value === module);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
		}
	}, [isSubmitted, setNameShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid);
	}, [nameValRes.isValid, setIsValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			...moduleData.breadcrumb,
			{ title: name || `New ${moduleData.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${moduleData.text}`);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='Unit Group name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='sortOrder' label='Sort order' id={data.id} colMd={6}>
								<Input
									type='number'
									placeholder='Sort Order'
									value={sortOrder}
									onChange={sortOrderOnChange}
									pattern={process.env.REACT_APP_INTEGER_PATTERN}
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
UnitGroupsForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		module: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
	module: PropTypes.string,
};
UnitGroupsForm.defaultProps = {
	data: {
		id: 0,
		name: '',
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
	module: modules.BOOKINGS,
};

export default UnitGroupsForm;
