import PropTypes from 'prop-types';
import React from 'react';

import Input from '../../field/Input';
import { priceFormatter } from '../../../../utils/helpers/helper';
import ModalInput from '../ModalInput';
import Separator from '../../layout/Separator';

const Paid = ({
	children,
	amountInputFocus,
	amount,
	paid,
	onClick,
	changeDue,
	isPayBill,
	hasServiceFee,
	serviceFee,
	onServiceFeeClick,
	serviceFeeInputFocus,
}) => {
	return (
		<>
			<div className='row align-items-baseline sdms-d-block-mobile'>
				<div className='col-md-3 col-12'>
					<h5>{isPayBill ? 'Amount to Apply' : 'Balance Due'}:</h5>
				</div>
				<div className='col-md col-12'>
					<Input
						className='form-control-lg sdms-font-xl sdms-font-boldest form-control--transparent'
						value={priceFormatter(amount)}
						onChange={() => {}}
						type='text'
						disabled
					/>
				</div>
				{changeDue !== null && (
					<>
						<div className='col-md-3 col-12'>
							<h5>Change Due</h5>
						</div>
						<div className='col-md-3 col-12'>
							<Input
								className='form-control-lg'
								value={changeDue}
								onChange={() => {}}
								type='text'
								disabled
								pattern={process.env.REACT_APP_PRICE_PATTERN}
							/>
						</div>
					</>
				)}
				{hasServiceFee && (
					<>
						<div className='col-md-2 col-12'>
							<h5 className='sdms-service-fee-text'>Fee:</h5>
						</div>
						<div className='col-md-2 col-12 px-0 sdms-service-fee-input'>
							<ModalInput
								value={serviceFee.toString()}
								isFocused={serviceFeeInputFocus}
								onClick={onServiceFeeClick}
							/>
						</div>
						<div className='col-md-1 col-12'>
							<h5>Total:</h5>
						</div>
						<div className='col-md-2 col-12'>
							{priceFormatter(
								(paid ? parseFloat(paid) : 0) +
									(serviceFee ? parseFloat(serviceFee) : 0)
							)}
						</div>
					</>
				)}
			</div>
			<Separator />
			<div className='row align-items-stretch sdms-d-block-mobile'>
				<div className='col-md-6 col-12'>
					<div className='row align-items-baseline'>
						<div className='col-md-6 col-12'>
							<h5>Amount Paid:</h5>
						</div>
						<div className='col-md-6 col-12'>
							<ModalInput
								value={paid}
								isFocused={amountInputFocus}
								onClick={onClick}
							/>
						</div>
					</div>
				</div>
				{children && <div className='col-md-6 col-12 sdms-mt-15-mobile'>{children}</div>}
			</div>
			<Separator />
		</>
	);
};
Paid.propTypes = {
	children: PropTypes.node,
	amountInputFocus: PropTypes.bool,
	amount: PropTypes.number,
	paid: PropTypes.string,
	onClick: PropTypes.func,
	changeDue: PropTypes.string,
	isPayBill: PropTypes.bool,
	hasServiceFee: PropTypes.bool,
	serviceFee: PropTypes.number,
	onServiceFeeClick: PropTypes.func,
	serviceFeeInputFocus: PropTypes.bool,
};
Paid.defaultProps = {
	children: null,
	amountInputFocus: true,
	amount: 0,
	paid: '',
	onClick: () => {},
	changeDue: null,
	isPayBill: false,
	hasServiceFee: false,
	serviceFee: 0,
	onServiceFeeClick: () => {},
	serviceFeeInputFocus: false,
};

export default Paid;
