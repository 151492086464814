import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../element/Button';
import DialogBox from '../../../element/DialogBox';

const SendEmail = ({ open, onSubmit }) => {
	return (
		<DialogBox
			open={open}
			title=''
			content='Auto-send email is not enabled. Do you want to send e-mail?'
			type='question'
			onClose={() => {}}>
			<Button
				className='sdms-font-transform-c'
				text='No'
				label='danger'
				icon='Error-circle'
				onClick={() => onSubmit(false)}
			/>
			<Button
				className='sdms-font-transform-c'
				text='Yes'
				design='clean'
				icon='Angle-right-circle'
				onClick={() => onSubmit(true)}
			/>
		</DialogBox>
	);
};

SendEmail.propTypes = {
	open: PropTypes.bool,
	onSubmit: PropTypes.func,
};

SendEmail.defaultProps = {
	open: false,
	onSubmit: () => {},
};

export default SendEmail;
