import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// Import TinyMCE
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';

// Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default';

// A theme is also required
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';

// Any plugins you want to use has to be imported;
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/code';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/print';
import 'tinymce/plugins/table';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/pagebreak';
import { Editor } from '@tinymce/tinymce-react';

import { htmlTemplateVariables } from '../../../utils/constants/constants';

const Wysiwyg = ({
	value,
	onChange,
	onBlur,
	customOptions,
	isProduct,
	disabled,
	isEmail,
	autoFocus,
}) => {
	const defaultToolbar = useRef(
		'undo redo | bold italic | alignleft aligncenter alignright | code | preview | print | table | searchreplace | bullist | numlist | link | openlink | unlink | pagebreak | btnVariables | btnInputs'
	);

	const [toolbar, setToolbar] = useState(defaultToolbar.current);

	useEffect(() => {
		let _toolbar = defaultToolbar.current;

		customOptions.forEach(co => {
			_toolbar = `${_toolbar} | ${co.value}`;
		});

		setToolbar(_toolbar);
	}, [customOptions]);

	return (
		<Editor
			value={value}
			disabled={disabled}
			onEditorChange={v => onChange({ target: { value: v } })}
			key={toolbar}
			init={
				isEmail
					? {
							min_height: 450,
							branding: false,
							auto_focus: autoFocus,
					  }
					: {
							plugins:
								'advlist autolink link image lists charmap print preview hr anchor pagebreak spellchecker searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking table emoticons template paste help',
							toolbar,
							toolbar_mode: 'wrap',
							min_height: isProduct ? 300 : 600,
							branding: false,
							setup: editor => {
								if (customOptions && customOptions.length > 0) {
									const variableButtons = [];
									const inputButtons = [];
									customOptions.forEach(co => {
										if (htmlTemplateVariables.includes(co.value))
											inputButtons.push({
												type: 'menuitem',
												text: co.value,
												onAction: () => {
													editor.insertContent(`{{${co.value}}}`);
												},
											});
										else
											variableButtons.push({
												type: 'menuitem',
												text: co.value,
												onAction: () => {
													editor.insertContent(`{{${co.value}}}`);
												},
											});
									});

									editor.ui.registry.addMenuButton('btnVariables', {
										text: 'Variables',
										fetch: callback => {
											callback(variableButtons);
										},
									});

									editor.ui.registry.addMenuButton('btnInputs', {
										text: 'Inputs',
										fetch: callback => {
											callback(inputButtons);
										},
									});
								}
							},
					  }
			}
			onBlur={onBlur}
		/>
	);
};

Wysiwyg.propTypes = {
	value: PropTypes.string,
	isProduct: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	customOptions: PropTypes.arrayOf(PropTypes.object),
	disabled: PropTypes.bool,
	isEmail: PropTypes.bool,
	autoFocus: PropTypes.bool,
};

Wysiwyg.defaultProps = {
	value: '',
	isProduct: true,
	onChange: () => {},
	onBlur: () => {},
	customOptions: [],
	disabled: false,
	isEmail: false,
	autoFocus: false,
};

export default Wysiwyg;
