import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children, isTooltip }) => {
	return createPortal(
		children,
		document.getElementById(isTooltip ? 'tooltip-root' : 'portal-root')
	);
};
Portal.propTypes = {
	children: PropTypes.node.isRequired,
	isTooltip: PropTypes.bool,
};
Portal.defaultProps = {
	isTooltip: false,
};

export default Portal;
