import React from 'react';
import PropTypes from 'prop-types';

import pages from '../../pages';

import FormContainer from '../../reusables/template/FormContainer';
import EmailAddressesForm from './EmailAddressesForm';

const EmailAddressesEdit = ({ match }) => {
	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle={pages.systemSettings.emailAddresses.text}
			dataName={pages.systemSettings.emailAddresses.route}
			lists={['enumMailAccountTypes', 'enumMailEncryptions']}
			fields={<EmailAddressesForm />}
		/>
	);
};

EmailAddressesEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default EmailAddressesEdit;
