import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { required, maxLength } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import Loading from '../../reusables/template/Loading';
import { numberParser } from '../../../utils/helpers/helper';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';

const TaxRateGroupForm = ({
	data,
	setIsValid,
	isSubmitted,
	enumCalculations,
	taxRates,
	setTitle,
	isLoading,
	onFormChange,
	submitButtonAttr,
	submit,
}) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [
		description,
		descriptionOnChange,
		descriptionValRes,
		descriptionShowVal,
		setDescriptionShowVal,
	] = useField(data, 'description', onFormChange, [maxLength()]);

	const [amount, amountOnChange] = useField(
		data,
		'amount',
		onFormChange,
		[],
		0,
		numberParser(true)
	);

	const [
		taxRateItems,
		taxRatesOnChange,
		taxRatesValRes,
		taxRatesShowVal,
		setTaxRatesShowVal,
	] = useField(data, 'taxRates', onFormChange, [required], []);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setDescriptionShowVal();
			setTaxRatesShowVal();
		}
	}, [isSubmitted, setNameShowVal, setDescriptionShowVal, setTaxRatesShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && descriptionValRes.isValid && taxRatesValRes.isValid);
	}, [nameValRes.isValid, descriptionValRes.isValid, taxRatesValRes.isValid, setIsValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.accounting.default.text,
				path: pages.accounting.dashboard.path,
			},
			{
				title: pages.accounting.settings.text,
				path: pages.accounting.settings.path,
			},
			{
				title: pages.accounting.settings.taxRateGroup.text,
				path: pages.accounting.settings.taxRateGroup.path,
			},
			{ title: name || `New ${pages.accounting.settings.taxRateGroup.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.accounting.settings.taxRateGroup.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		if (!isLoading && data.id === 0 && enumCalculations.length) {
			data.isGroup = true;

			data.type = enumCalculations.find(ec => ec.value === 'percent');
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading, enumCalculations]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='Tax Rate Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='description'
								label='Description'
								id={data.id}
								colMd={6}
								valRes={descriptionValRes}
								showValidation={descriptionShowVal}>
								<Input
									type='text'
									placeholder='Description'
									value={description}
									onChange={descriptionOnChange}
									onBlur={setDescriptionShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='amount' label='Amount' id={data.id} colMd={6}>
								<Input
									type='text'
									placeholder='Amount'
									value={amount}
									onChange={() => {}}
									disabled
									append='%'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='taxRates'
								label='Tax Rates'
								id={data.id}
								colLg={6}
								showValidation={taxRatesShowVal}
								valRes={taxRatesValRes}>
								<Selects
									options={taxRates.filter(tr => !tr.isGroup)}
									multiple
									placeholder='Tax Rates(Required)'
									value={taxRateItems}
									onChange={e => {
										amountOnChange({
											target: {
												value: e.target.value
													.map(tr => tr.amount)
													.reduce(
														(partialSum, amount) => partialSum + amount,
														0
													),
											},
										});
										taxRatesOnChange(e);
									}}
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
TaxRateGroupForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		description: PropTypes.string,
		amount: PropTypes.number,
		type: PropTypes.object,
		taxAgency: PropTypes.object,
	}),
	enumCalculations: PropTypes.arrayOf(PropTypes.object),
	taxRates: PropTypes.arrayOf(PropTypes.object),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
TaxRateGroupForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		description: '',
		amount: 0,
	},
	enumCalculations: [],
	taxRates: [],
	setIsValid: () => {},
	isSubmitted: false,
	setTitle: () => {},
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default TaxRateGroupForm;
