import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import ReportContainer from '../../reusables/template/ReportContainer';
import {
	addErrorNotification,
	addSuccessNotification,
	dateFormatter,
	durationFormatter,
	getReportQueryFields,
	priceFormatter,
} from '../../../utils/helpers/helper';
import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';
import Button from '../../reusables/element/Button';
import PrinterModal from '../../reusables/modals/PrinterModal';
import apiCall from '../../../utils/helpers/apiCall';
// eslint-disable-next-line import/namespace,import/default,import/no-named-as-default,import/no-named-as-default-member
import WebPrint from '../../reusables/print/WebPrint';
import UserContext from '../../../app/contexts/UserContext';

const TimeCell = ({ data, field }) => {
	if (data.titleLine && data[field]) return data[field];
	if (data[field]) return dateFormatter(data[field]);
	return '';
};

const DurationCell = ({ data, field }) => {
	return data[field] ? durationFormatter(data[field]) : '';
};

const PriceCell = ({ data, field }) => {
	if (data[field]) return priceFormatter(data[field]);
	return '';
};

const ZOutCell = ({ data, onPrint }) => {
	if (data.titleLine) return '';
	return (
		<Button
			design='link'
			text='Edit'
			icon='Edit'
			size='sm'
			elevate
			key='edit'
			onClick={() => onPrint(data)}
			disabled={!data.rb_z_out}>
			Print
		</Button>
	);
};

ZOutCell.propTypes = {
	data: PropTypes.shape({
		titleLine: PropTypes.bool,
	}),
	onPrint: PropTypes.func.isRequired,
};

ZOutCell.defaultProps = {
	data: {
		titleLine: true,
	},
};

const PosZout = () => {
	const historyLocation = useLocation();
	const { report } = historyLocation.state ? historyLocation.state : { report: null };

	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const webPrint = useRef({ print: () => {}, printers: [] });

	const [isPrinterModalOpen, setIsPrinterModalOpen] = useState(false);

	const selectedRegisterBatch = useRef(null);

	const closeData = useRef(null);

	const fieldFormat = fieldName => {
		const field = report.reportFields.find(a => a.dataSetField.alias === fieldName);
		return field && field.dataSetField.fieldFormat ? field.dataSetField.fieldFormat.value : '';
	};

	const reportRender = (data, id) => {
		switch (fieldFormat(id)) {
			case 'datetime':
				return TimeCell({ data, field: id });
			case 'duration':
				return DurationCell({ data, field: id });
			case 'price':
				return PriceCell({ data, field: id });
			default:
				return data[id] || '';
		}
	};

	const onPrint = data => {
		selectedRegisterBatch.current = data;

		if (data.rb_z_out) setIsPrinterModalOpen(true);
		else {
			apiCall(
				'POST',
				'registerGetCloseData',
				res => {
					closeData.current = res;
					setIsPrinterModalOpen(true);
				},
				err => {
					addErrorNotification(err.toString());
				},
				'',
				{
					registerBatchId: data.rb_id,
				}
			);
		}
	};

	const columnOptions = () => {
		const returnColumns = report.reportFields
			.sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : 1))
			.map(a => {
				return {
					sortNum: a.sortOrder,
					displayName: a.dataSetField.label,
					id: a.dataSetField.alias,
					...(!a.dataSetField.fieldGroup.canSum && { groupLabel: a.dataSetField.label }),
					...(a.dataSetField.fieldFormat &&
						a.dataSetField.fieldFormat.value === 'datetime' && {
							cellComponent: <TimeCell field={a.dataSetField.alias} />,
						}),
					...(a.dataSetField.fieldFormat &&
						a.dataSetField.fieldFormat.value === 'datetime' && { type: 'date' }),
					...(a.dataSetField.fieldFormat &&
						a.dataSetField.fieldFormat.value === 'duration' && {
							cellComponent: <DurationCell field={a.dataSetField.alias} />,
						}),
					...(a.dataSetField.fieldFormat &&
						a.dataSetField.fieldFormat.value === 'price' && {
							cellComponent: <PriceCell field={a.dataSetField.alias} />,
						}),
				};
			});

		returnColumns.push({
			sortNum: returnColumns.length,
			displayName: 'Z-Out',
			id: 'z-out',
			cellComponent: <ZOutCell onPrint={onPrint} />,
			hideInReport: true,
		});

		return returnColumns;
	};

	const groupRowRender = (list, field, value) => {
		const fieldFormatValue = fieldFormat(field);

		return {
			id: value,
			titleLine: true,
			...(fieldFormatValue === 'datetime' && { [field]: dateFormatter(value, false) }),
			...(fieldFormatValue === 'duration' && { [field]: durationFormatter(value, false) }),
			...(fieldFormatValue === 'price' && { [field]: priceFormatter(value, false) }),
			...(fieldFormatValue === '' && { [field]: value }),
			duration: list
				.filter(data => {
					switch (fieldFormatValue) {
						case 'datetime':
							return new Date(data[field]).toDateString() === value;
						default:
							return data[field] === value;
					}
				})
				.map(data => {
					// if (data.duration) return parseInt(data.duration, 10);
					return 0;
				})
				.reduce((a, b) => a + b),
		};
	};

	// Effect for breadcrumbs
	useEffect(() => {
		if (headerContext)
			headerContext.setBreadcrumbs([
				{
					title: pages.reports.default.text,
					path: pages.reports.dashboard.path,
				},
				{ title: pages.reports.pos.text, path: pages.reports.pos.path },
				{ title: report ? report.name : '', isActive: true },
			]);

		webPrint.current = new WebPrint({
			user: userContext.data.user,
			outlet: userContext.data.selectedOutlet,
			onStart: () => addSuccessNotification('Printing...'),
			onFinish: () => {},
			onFail: (printItem, err) => addErrorNotification(err.toString()),
		});
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ReportContainer
				columnOptions={columnOptions()}
				route='reportGeneric'
				config={{ fields: report ? getReportQueryFields(report.type) : {} }}
				reportRender={reportRender}
				reportFileName='Z Out'
				reportId={report.id}
				groupRowRender={groupRowRender}
				initTree={report ? JSON.parse(report.queryJSON) : null}
				lists={['registers']}
				title={pages.reports.text}
			/>
			<PrinterModal
				onSubmit={printer => {
					if (selectedRegisterBatch.current.rb_z_out)
						webPrint.current.print(selectedRegisterBatch.current.rb_z_out, printer.id);
					else webPrint.current.registerCloseOut([closeData.current], printer.id);

					selectedRegisterBatch.current = null;
					closeData.current = null;
					setIsPrinterModalOpen(false);
				}}
				onClose={() => setIsPrinterModalOpen(false)}
				isOpen={isPrinterModalOpen}
				printers={webPrint.current.printers}
			/>
		</>
	);
};

export default PosZout;
