import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { required } from '../../../utils/helpers/validation';
import useDate from '../../../utils/hooks/useDate';
import useField from '../../../utils/hooks/useField';
import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';

import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Button from '../../reusables/element/Button';
import Loading from '../../reusables/template/Loading';
import DatePicker from '../../reusables/field/DatePicker';

const SeasonForm = ({
	data,
	setIsValid,
	submit,
	isSubmitted,
	isLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
}) => {
	const [, , parseDatePickerValue, parseDatePickerChange] = useDate();

	const endDateValidation = (value, setValRes) => {
		if (!startDate || !value) return true;

		if (moment(startDate).isSameOrAfter(moment(value))) {
			setValRes({
				isValid: false,
				status: 'invalidDate',
				message: 'End date cannot be before start date',
			});
			return false;
		}
		return true;
	};

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [
		startDate,
		startDateOnChange,
		startDateValRes,
		startDateShowVal,
		setStartDateShowVal,
	] = useField(data, 'startDate', onFormChange, [required], null);

	const [
		endDate,
		endDateOnChange,
		endDateValRes,
		endDateShowVal,
		setEndDateShowVal,
		validateEndDate,
	] = useField(data, 'endDate', onFormChange, [required, endDateValidation], null);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setStartDateShowVal();
			setEndDateShowVal();
		}
	}, [isSubmitted, setNameShowVal, setStartDateShowVal, setEndDateShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && startDateValRes.isValid && endDateValRes.isValid);
	}, [setIsValid, nameValRes.isValid, startDateValRes.isValid, endDateValRes.isValid]);

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.systemSettings.default.text,
				path: pages.systemSettings.dashboard.path,
			},
			{
				title: pages.systemSettings.seasons.text,
				path: pages.systemSettings.seasons.path,
			},
			{ title: name || `New ${pages.systemSettings.seasons.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.systemSettings.seasons.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		validateEndDate(endDate);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [startDate]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form>
					<Section title='General'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='name'
										label='Name'
										id={data.id}
										valRes={nameValRes}
										showValidation={nameShowVal}
										colMd={4}>
										<Input
											type='text'
											placeholder='Name (Required)'
											value={name}
											onChange={nameOnChange}
											onBlur={setNameShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='startDate'
										label='Start Date'
										id={data.id}
										valRes={startDateValRes}
										showValidation={startDateShowVal}
										loadingContainer
										colMd={4}>
										<DatePicker
											id='startDate'
											type='calendar'
											placeholder='Select Start Date'
											value={parseDatePickerValue(startDate)}
											onChange={e =>
												startDateOnChange({
													target: {
														value: parseDatePickerChange(
															e.target.value
														),
													},
												})
											}
											onBlur={setStartDateShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='endDate'
										label='End Date'
										id={data.id}
										valRes={endDateValRes}
										showValidation={endDateShowVal}
										loadingContainer
										colMd={4}>
										<DatePicker
											id='endDate'
											type='calendar'
											placeholder='Select End Date'
											value={parseDatePickerValue(endDate)}
											onChange={e =>
												endDateOnChange({
													target: {
														value: parseDatePickerChange(
															e.target.value
														),
													},
												})
											}
											onBlur={setEndDateShowVal}
											minDate={startDate ? new Date(startDate) : null}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
SeasonForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		startDate: PropTypes.object,
		endDate: PropTypes.string,
	}),
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
};
SeasonForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		startDate: null,
		endDate: null,
	},
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
};

export default SeasonForm;
