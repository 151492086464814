import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import PivotTable from '../template/PivotTable';

const WidgetPivot = ({ data, widget, configRef }) => {
	const fieldMapping = useMemo(() => {
		const result = widget.report.reportFields
			.sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : 1))
			.map(a => ({
				name: a.dataSetField.alias,
				caption: a.dataSetField.label,
			}));

		return result;
	}, [widget]);

	return <PivotTable dataSource={data} fieldMapping={fieldMapping} configRef={configRef} />;
};

export default WidgetPivot;

WidgetPivot.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})),
	widget: PropTypes.shape({
		id: PropTypes.number,
		report: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
			queryJSON: PropTypes.string,
			reportFields: PropTypes.arrayOf(PropTypes.shape({})),
		}),
		sortOrder: PropTypes.number,
		width: PropTypes.number,
		title: PropTypes.string,
		config: PropTypes.string,
		type: PropTypes.shape({
			id: PropTypes.number,
			value: PropTypes.string,
		}),
		col: PropTypes.number,
		row: PropTypes.number,
		sizeX: PropTypes.number,
		sizeY: PropTypes.number,
	}),
	configRef: PropTypes.shape({}),
};

WidgetPivot.defaultProps = {
	data: [],
	widget: {
		id: 0,
		report: {
			id: 0,
			name: '',
			queryJSON: '',
			reportFields: [],
		},
		sortOrder: 0,
		width: 100,
		title: '',
		config: null,
		type: {
			id: 0,
			value: null,
		},
		col: 0,
		row: 0,
		sizeX: 0,
		sizeY: 0,
	},
	configRef: null,
};
