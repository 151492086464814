import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import useComponentSize from '@rehooks/component-size';

import UserContext from '../../../../../app/contexts/UserContext';
import useField from '../../../../../utils/hooks/useField';
import { min, numeric, required } from '../../../../../utils/helpers/validation';
import apiCall from '../../../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	addSuccessNotification,
	calculateSqft,
	convertDateToUTC,
	filterInvoicingFrequency,
	getItemTaxCode,
	numberFormat,
	numberParser,
	priceFormatter,
} from '../../../../../utils/helpers/helper';
import DatePicker from '../../../field/DatePicker';
import {
	bookBies,
	bookingPeriods,
	reservationStatuses,
	searchTypes,
} from '../../../../../utils/constants/constants';
import {
	calculateExtraFeeTotals,
	calculateItemTotals,
	calculateSubtotalAndQuantity,
	getCapacity,
	getPolicy,
	getPrices,
	getRatePlan,
	onExtraChargeItemUpdate,
} from '../../../../../utils/helpers/reservationHelper';
import { getAvailableUnits, getBookingHourlyStep } from '../../../../../utils/helpers/reusable';
import useModal from '../../../../../utils/hooks/useModal';

import Portlet from '../../../layout/Portlet';
import Button from '../../../element/Button';
import FormGroup from '../../../layout/FormGroup';
import FormField from '../../../template/FormField';
import Input from '../../../field/Input';
import TimePickerInput from '../../../field/TimePickerInput';
import Toggle from '../../../field/Toggle';
import Selects from '../../../field/Selects';
import Textarea from '../../../field/Textarea';
import Section from '../../../layout/Section';
import ReservationStatusCell from '../../../element/ReservationStatusCell';
import Badge from '../../../element/Badge';
import Alert from '../../../element/Alert';
import Separator from '../../../layout/Separator';
import { ExtraFeeHead, ExtraFeeRow } from '../../../element/ExtraFee';
import useFeet from '../../../../../utils/hooks/useFeet';
import VehicleModal from '../../../modals/VehicleModal';
import VesselModal from '../../../modals/VesselModal';
import LengthInputGroup from '../../../field/LengthInputGroup';
import ContractList from '../ContractList';
import WaitListIgnoreCapacityToggle from '../../../element/WaitListIgnoreCapacityToggle';
import ExtraFeeSettings from '../../../modals/ExtraFeeSettings';
import {
	hasOverrideConstraintPermission,
	hasEditReservationDatesPermission,
	hasEditReservationUnitPermission,
	hasSwapReservationUnitPermission,
	hasEditReservationCancellationPolicyPermission,
	hasEditReservationProductContractsPermission,
	hasEditReservationSpecialRequestsPermission,
	hasEditReservationFeesPermission,
	hasEditReservationPricePermission,
} from '../../../../../utils/helpers/permission';

const modals = {
	VEHICLE_SELECT: 'vehicleSelect',
	VEHICLE_FORM: 'vehicleForm',
	VESSEL_SELECT: 'vesselSelect',
	VESSEL_FORM: 'vesselForm',
	EXTRA_FEE_SETTINGS: 'extraFeeSettings',
};

const ReservationAdvancedTransientItemForm = ({
	form,
	data,
	taxRate,
	ratePlans,
	policies,
	onClose,
	onUpdate,
	disabled,
	reservation,
	onFormChange,
	contracts,
	responsive,
	mediaBreakpoint,
	currentExtraChargeItemProductId,
	onOverride,
	enumBoatMakes,
	enumBoatTypes,
	enumVehicleMakes,
	enumRvTypes,
	invoicingFrequencies,
	accounts,
	taxCodes,
}) => {
	const userContext = useContext(UserContext);

	const [modal, openModal, closeModal] = useModal();

	const refForTimePicker = useRef(null);

	const sizeTimePicker = useComponentSize(refForTimePicker);

	const refHead = useRef(null);

	const sizeHead = useComponentSize(refHead);

	const [submitButtonAttr, setSubmitButtonAttr] = useState({
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	});

	const timePickerStep = useRef(getBookingHourlyStep(userContext));

	const updateVesselData = useRef(false);

	const updateVehicleData = useRef(false);

	const isFieldEnabled = field =>
		data.product.bookingType[field] &&
		(data.product.bookingType[field].value === searchTypes.OPTIONAL ||
			data.product.bookingType[field].value === searchTypes.REQUIRED);

	const vesselVehicleRequired = field =>
		data.product.bookingType[field]?.value === searchTypes.REQUIRED ? required : () => true;

	// conditionally required for deferred fields.
	const deferredRequired = (value, setValRes) => {
		if (enableDeferredIncome) return required(value, setValRes);
		return true;
	};

	const reservationItemFromDate = data.fromDate ? moment(data.fromDate).utcOffset(0) : moment();

	const reservationItemToDate = data.toDate ? moment(data.toDate).utcOffset(0) : moment();

	// add one day default toDate if product has nightly period
	if (!data.toDate && data.product.bookingPeriod.value === bookingPeriods.NIGHTLY)
		reservationItemToDate.add(1, 'day');

	const productCheckInTime = moment(data.product.checkInTime).utcOffset(0);

	const productCheckOutTime =
		data.product.bookingPeriod.value === bookingPeriods.HOURLY
			? moment(data.product.checkInTime)
					.utcOffset(0)
					.add(parseInt((data.product.bookingInterval / 60).toString(), 10), 'hour')
					.add(data.product.bookingInterval % 60, 'minute')
			: moment(data.product.checkOutTime).utcOffset(0);

	const [isValid, setIsValid] = useState(false);

	const [isDateChanged, setIsDateChanged] = useState(!data.id);

	const [isUnitSelectDisable, setIsUnitSelectDisable] = useState(true);

	const [vessel, setVessel] = useState(data.vessel);

	const [vehicle, setVehicle] = useState(data.vehicle);

	const [loa, loaOnChange, loaValRes, loaShowVal, setLoaShowVal] = useField(
		data,
		'loa',
		onFormChange,
		[vesselVehicleRequired('searchLoa')],
		data.loa,
		numberParser(false)
	);

	const [loaFt, setLoaFt, loaIn, setLoaIn, , setLoa] = useFeet(
		data.loa,
		loaOnChange,
		false,
		data.loa
	);

	const [beam, beamOnChange, beamValRes, beamShowVal, setBeamShowVal] = useField(
		data,
		'beam',
		onFormChange,
		[vesselVehicleRequired('searchBeam')],
		data.beam,
		numberParser(false)
	);

	const [beamFt, setBeamFt, beamIn, setBeamIn, , setBeam] = useFeet(
		data.beam,
		beamOnChange,
		false,
		data.beam
	);

	const [draft, draftOnChange, draftValRes, draftShowVal, setDraftShowVal] = useField(
		data,
		'draft',
		onFormChange,
		[vesselVehicleRequired('searchDraft')],
		data.draft,
		numberParser(false)
	);

	const [draftFt, setDraftFt, draftIn, setDraftIn, , setDraft] = useFeet(
		data.draft,
		draftOnChange,
		false,
		data.draft
	);

	const [height, heightOnChange, heightValRes, heightShowVal, setHeightShowVal] = useField(
		data,
		'height',
		onFormChange,
		[vesselVehicleRequired('searchHeight')],
		data.height,
		numberParser(false)
	);

	const [heightFt, setHeightFt, heightIn, setHeightIn, , setHeight] = useFeet(
		data.height,
		heightOnChange,
		false,
		data.height
	);

	const [weight, weightOnChange, weightValRes, weightShowVal, setWeightShowVal] = useField(
		data,
		'weight',
		onFormChange,
		[vesselVehicleRequired('searchWeight')],
		data.weight,
		numberParser(false)
	);

	const [fromDate, setFromDate] = useState(
		moment()
			.utc(false)
			.year(reservationItemFromDate.year())
			.month(reservationItemFromDate.month())
			.date(reservationItemFromDate.date())
			.hour(data.fromDate ? reservationItemFromDate.hour() : productCheckInTime.hour())
			.minute(data.fromDate ? reservationItemFromDate.minute() : productCheckInTime.minute())
			.second(0)
			.millisecond(0)
	);

	const toDateValidation = (value, setValRes) => {
		if (value <= fromDate) {
			setValRes({
				isValid: false,
				status: 'invalidToDate',
				message: 'Invalid to date',
			});
			return false;
		}

		return true;
	};

	const [toDate, toDateOnChange, toDateValRes, toDateShowVal, setToDateShowVal] = useField(
		{},
		'toDate',
		onFormChange,
		[toDateValidation],
		moment()
			.utc(false)
			.year(reservationItemToDate.year())
			.month(reservationItemToDate.month())
			.date(reservationItemToDate.date())
			.hour(data.toDate ? reservationItemToDate.hour() : productCheckOutTime.hour())
			.minute(data.toDate ? reservationItemToDate.minute() : productCheckOutTime.minute())
			.second(0)
			.millisecond(0)
	);

	const [availableUnits, setAvailableUnits] = useState([]);

	const [unit, unitOnChange, unitValRes, unitShowVal, setUnitShowVal] = useField(
		data,
		'unit',
		onFormChange,
		[required],
		data.unit || null
	);

	const [ignoreRules, setIgnoreRules] = useState(data.ignoredRules || false);

	const [ignoreCapacity, setIgnoreCapacity] = useState(data.ignoredCapacity || false);

	const [isWaitList, setIsWaitList] = useState(false);

	const [availableRatePlans, setAvailableRatePlans] = useState([]);

	const [ratePlan, ratePlanOnChange] = useField(
		data,
		'ratePlan',
		onFormChange,
		[],
		data.ratePlan || null
	);

	const [policy, policyOnChange] = useField(
		data,
		'policy',
		onFormChange,
		[],
		data.policy || null
	);

	const [
		depositAmount,
		depositAmountOnChange,
		depositAmountValRes,
		depositAmountShowVal,
		setDepositAmountShowVal,
	] = useField(
		{},
		'depositAmount',
		onFormChange,
		[required, numeric, min(0)],
		data.depositAmount ? parseFloat(data.depositAmount).toFixed(2) : '0.00'
	);

	const [productContracts, productContractsOnChange] = useField(
		data,
		'productContracts',
		onFormChange,
		[],
		data.productContracts || data.product.productContracts
	);

	const quantityRef = useRef(data.quantity || 1);

	const [subtotal, subtotalOnChange] = useField(
		{},
		'subtotal',
		onFormChange,
		[],
		data.subtotal ? parseFloat(data.subtotal).toFixed(2) : '0.00'
	);

	const [totals, setTotals] = useState(
		calculateItemTotals(data, null, [], null, null, null, null, null, null, reservation)
	);

	const [pricing, setPricing] = useState({});

	const [selectedExtraCharges, setSelectedExtraCharges] = useState(data.extraCharges || []);

	const [extraCharges, setExtraCharges] = useState({});

	const [note, noteOnChange] = useField(data, 'note', onFormChange, [], data.note || '');

	const [enableDeferredIncome, enableDeferredIncomeOnChange] = useField(
		data,
		'enableDeferredIncome',
		onFormChange,
		[],
		false
	);

	const [
		deferredIncomeFrequency,
		deferredIncomeFrequencyOnChange,
		deferredIncomeFrequencyValRes,
		deferredIncomeFrequencyShowVal,
		setDeferredIncomeFrequencyShowVal,
		validateDeferredIncomeFrequency,
	] = useField(data, 'deferredIncomeFrequency', onFormChange, [deferredRequired], null);

	const [
		deferredIncomeSalesAccount,
		deferredIncomeSalesAccountOnChange,
		deferredIncomeSalesAccountValRes,
		deferredIncomeSalesAccountShowVal,
		setDeferredIncomeSalesAccountShowVal,
		validateDeferredIncomeSalesAccount,
	] = useField(data, 'deferredIncomeSalesAccount', onFormChange, [deferredRequired], null);

	const [taxCode, taxCodeOnChange, taxCodeValRes, taxCodeShowVal, setTaxCodeShowVal] = useField(
		data,
		'taxCode',
		onFormChange,
		[required],
		data.taxCode
	);

	const [isSearching, setIsSearching] = useState(false);

	const [swapUnits, setSwapUnits] = useState(false);

	const [swappableUnits, setSwappableUnits] = useState([]);

	const unitBackup = useRef(null);

	const extrasRef = useRef();

	const onSearchFieldChange = _isWaitList => {
		// named onDateChange in Booking
		if (_isWaitList) return;
		setIsDateChanged(true);
		setIsUnitSelectDisable(true);
		setSwapUnits(false);
	};

	const onDateChanged = _isWaitList => {
		if (_isWaitList) return;
		setIsDateChanged(true);
		setIsUnitSelectDisable(true);
		setSwapUnits(false);
	};

	const areFieldsDisabled = () =>
		!unit ||
		disabled ||
		isSearching ||
		isWaitList ||
		((data.status.value === reservationStatuses.WAITLIST || data.id === 0) &&
			Object.keys(pricing).length === 0);

	const onFromDateChanged = () => {
		if (toDate.isSameOrBefore(fromDate)) {
			const _toDate =
				data.product.bookingPeriod.value === bookingPeriods.NIGHTLY
					? moment(fromDate)
							.date(fromDate.date() + 1)
							.hour(toDate.hour())
							.minute(toDate.minute())
					: moment(fromDate)
							.hour(toDate.hour())
							.minute(toDate.minute());

			if (_toDate.isSameOrBefore(fromDate)) {
				_toDate
					.hour(fromDate.hour())
					.add(
						data.product.bookingPeriod.value === bookingPeriods.HOURLY
							? data.product.bookingInterval
							: 60,
						'minute'
					);
			}

			toDateOnChange({
				target: {
					value: _toDate,
				},
			});
		}
	};

	const onPricingChange = (_pricing, _availableRatePlans, isInit = false) => {
		try {
			if (!_pricing.units) return;

			const _availableUnits = getAvailableUnits(data.product, _pricing, form.module.value);

			setAvailableUnits(_availableUnits);

			if (!unit || !_availableUnits.find(u => u.id === unit.id))
				unitOnChange({ target: { value: _availableUnits[0] } });

			if (!isInit) {
				const _ratePlan = getRatePlan(_availableRatePlans, ignoreRules, _pricing);

				ratePlanOnChange({ target: { value: _ratePlan } });

				policyOnChange({
					target: { value: getPolicy(_pricing, _ratePlan, data.product, policies) },
				});

				const subtotalAndQuantity = calculateSubtotalAndQuantity(
					data.product,
					ratePlan,
					_pricing,
					fromDate,
					toDate
				);

				quantityRef.current = subtotalAndQuantity.quantity;

				subtotalOnChange({
					target: {
						value: numberFormat(subtotalAndQuantity.subtotal),
					},
				});
			} else updateTotals();
		} catch (error) {
			console.error(error);
		}
	};

	const updateTotals = () => {
		const _totals = calculateItemTotals(
			data,
			subtotal,
			selectedExtraCharges,
			taxRate,
			policy,
			pricing,
			ratePlan,
			depositAmount,
			fromDate,
			reservation
		);

		setTotals(_totals);

		if (
			data.status.value === reservationStatuses.RESERVED ||
			data.status.value === reservationStatuses.RESERVED_ONLINE
		)
			depositAmountOnChange({
				target: {
					value: _totals.requiredDepositAmount,
				},
			});
	};

	const onCheckAvailability = (
		_ignoreRules = false,
		_ignoreCapacity = false,
		_isWaitList = false,
		isInit = false
	) => {
		setIsSearching(true);
		setIsUnitSelectDisable(true);

		const { isMarina, isCampground } = form;

		const _data = {
			outletId: userContext.data.selectedOutlet.id,
			fromDate: fromDate.toISOString(),
			toDate: toDate.toISOString(),
			ignoreRules: _ignoreRules,
			ignoreCapacity: _ignoreCapacity,
			ignoredReservationItemId: data.id,
			productId: data.product.id,
			capacity: quantityRef.current,
			isWaitList: _isWaitList,
		};

		if (isMarina || isCampground) {
			_data.capacity = getCapacity(data.product, quantityRef.current, loa, beam);
			_data.loa = loa;
			_data.beam = beam;
			_data.height = height;
			_data.sqft = calculateSqft(loa, beam);

			if (isMarina) {
				_data.weight = weight;
				_data.draft = draft;
			}
		}

		apiCall(
			'POST',
			'advancedReservationIsAvailable',
			res => {
				setIsSearching(false);
				if (!res.units) {
					setPricing({});
					addErrorNotification(res.message || 'There are no available units');
					return;
				}

				if (!_isWaitList && isWaitList) setIsWaitList(false);

				if (Object.keys(res.ratePlans).length === 0) {
					setPricing({});
					addErrorNotification(res.message || 'There are no matching rate plan');
					return;
				}

				const _availableRatePlans = res.ratePlans
					? ratePlans.filter(rp => res.ratePlans[rp.id])
					: [];

				setAvailableRatePlans(_availableRatePlans);

				if (!isInit) {
					setIsUnitSelectDisable(false);
					setIsDateChanged(false);
				}

				setPricing(res);

				onPricingChange(res, _availableRatePlans, isInit);
			},
			err => {
				addErrorNotification(err.toString().replace('Error:', ''));
				setIsSearching(false);
			},
			'',
			_data
		);
	};

	const onItemUpdate = itemData => {
		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});

		apiCall(
			'POST',
			'advancedReservationUpdateItem',
			res => {
				addSuccessNotification(`Booking successfully ${data.id ? 'updated' : 'added'}.`);
				onUpdate(res.reservation);
			},
			e => {
				if (e.toString().includes('override')) onOverride();
				else addErrorNotification(e.toString());
				setSubmitButtonAttr({
					text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
					icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
					color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
				});
			},
			'',
			{
				reservation,
				reservationItem: itemData,
				outletId: userContext.data.selectedOutlet.id,
				swap: swapUnits,
				isWaitList,
			}
		);
	};

	useEffect(() => {
		setIsValid(
			unitValRes.isValid &&
				depositAmountValRes.isValid &&
				toDateValRes.isValid &&
				deferredIncomeFrequencyValRes.isValid &&
				deferredIncomeSalesAccountValRes.isValid &&
				loaValRes.isValid &&
				beamValRes.isValid &&
				draftValRes.isValid &&
				heightValRes.isValid &&
				weightValRes.isValid &&
				taxCodeValRes.isValid
		);
	}, [
		setIsValid,
		unitValRes.isValid,
		depositAmountValRes.isValid,
		toDateValRes.isValid,
		loaValRes.isValid,
		beamValRes.isValid,
		draftValRes.isValid,
		heightValRes.isValid,
		weightValRes.isValid,
		deferredIncomeFrequencyValRes.isValid,
		deferredIncomeSalesAccountValRes.isValid,
		taxCodeValRes.isValid,
	]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(onFromDateChanged, [fromDate]);

	// update totals
	useEffect(() => {
		if (Object.keys(pricing).length) updateTotals();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedExtraCharges]);

	// update selected extra charges or totals.
	useEffect(() => {
		if (Object.keys(pricing).length) {
			if (selectedExtraCharges.length) {
				const { isMarina, isCampground } = form;

				setSelectedExtraCharges(
					selectedExtraCharges.map(sec => {
						const _data = {
							bookingPeriod: data.product.bookingPeriod.value,
							quantity: quantityRef.current,
							subtotal: subtotal === '' ? 0 : parseFloat(subtotal),
							taxCode,
							fromDate,
							product: data.product,
						};
						if (isMarina || isCampground) {
							_data.loa = loa;
							_data.beam = beam;
						}
						return {
							...sec,
							...calculateExtraFeeTotals(
								sec,
								_data,
								taxRate,
								getPrices(pricing, ratePlan),
								selectedExtraCharges,
								null,
								ignoreRules
							),
						};
					})
				);
			} else updateTotals();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subtotal, policy, fromDate, toDate, taxCode]);

	// get extra charges from data.
	// get swappable units.
	// check availability for waitlist reservations
	useEffect(() => {
		data.product.productExtraCharges.forEach(p => {
			if (!p.productCategory) {
				if (!extraCharges['Other Options']) extraCharges['Other Options'] = [];

				extraCharges['Other Options'].push(p);
			}
		});

		data.product.productExtraCharges.forEach(p => {
			if (p.productCategory) {
				if (!extraCharges[p.productCategory.name])
					extraCharges[p.productCategory.name] = [];

				extraCharges[p.productCategory.name].push(p);
			}
		});

		if (data.extraCharges)
			data.extraCharges
				.filter(
					ec =>
						data.product.productExtraCharges.findIndex(
							pec => ec.extraCharge.id === pec.id
						) === -1
				)
				.forEach(ec => {
					const categoryName = ec.extraCharge.productCategory
						? ec.extraCharge.productCategory.name
						: 'Other Options';

					if (!extraCharges[categoryName]) extraCharges[categoryName] = [];

					extraCharges[categoryName].push(ec.extraCharge);
				});

		// set default extra charges.
		if (data.id === 0 && Object.entries(extraCharges).length > 0) {
			let defaultExtraCharges = [];

			Object.entries(extraCharges).forEach(exc => {
				exc[1]
					.filter(ec => !ec.optional && !ec.enabled)
					.forEach((extraCharge, index) => {
						onExtraChargeItemUpdate(
							{
								id: index * (index ? -1 : 1),
								isChecked: true,
								extraCharge,
								amount: extraCharge.price,
								quantity: 1,
								taxCode: getItemTaxCode(taxCode, {
									taxCode: extraCharge.taxCode || taxCode,
								}),
							},
							defaultExtraCharges,
							_defaultExtraCharges => {
								defaultExtraCharges = _defaultExtraCharges;
							},
							{
								bookingPeriod: data.product.bookingPeriod.value,
								quantity: quantityRef.current,
								subtotal: 0,
								taxCode,
								fromDate,
								product: data.product,
							},
							taxRate
						);
					});
			});

			setSelectedExtraCharges(defaultExtraCharges);
		}

		setExtraCharges({ ...extraCharges });

		if (data.id) {
			if (
				data.status.value !== reservationStatuses.CANCELLED &&
				data.status.value !== reservationStatuses.WAITLIST
			) {
				apiCall(
					'POST',
					'advancedReservationGetSwappableUnits',
					res => {
						setSwappableUnits(res.swappableUnits);
					},
					() => {},
					'',
					{ outletId: userContext.data.selectedOutlet.id, reservationItemId: data.id }
				);
			}

			if (data.status.value === reservationStatuses.WAITLIST) {
				setIsWaitList(true);
			}

			onCheckAvailability(
				true,
				true,
				data.status.value === reservationStatuses.WAITLIST,
				true
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (currentExtraChargeItemProductId && extrasRef.current)
			extrasRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [extraCharges]);

	useEffect(() => {
		if (updateVesselData.current) {
			if (vessel) {
				setLoa(vessel.loa);
				setBeam(vessel.beam);
				setDraft(vessel.draft);
				setHeight(vessel.height);
				weightOnChange({ target: { value: vessel.weight } });
			}

			onSearchFieldChange(isWaitList);
		}

		if (updateVehicleData.current) {
			if (vehicle) {
				setLoa(vehicle.loa);
				setBeam(vehicle.beam);
				setHeight(vehicle.height);
			}

			onSearchFieldChange(isWaitList);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vessel, vehicle]);

	useEffect(() => {
		// re-validate deferred field on enableDeferredIncome change.
		validateDeferredIncomeFrequency(deferredIncomeFrequency);
		validateDeferredIncomeSalesAccount(deferredIncomeSalesAccount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enableDeferredIncome]);

	useEffect(() => {
		if (data.taxCode && taxCode && taxCode.id !== data.taxCode.id)
			taxCodeOnChange({ target: { value: data.taxCode } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		if (isWaitList) onCheckAvailability(ignoreRules, ignoreCapacity, isWaitList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromDate, toDate]);

	return (
		<Portlet
			fluid='fluid'
			className='sdms-bg-transparent sdms-portlet--unelevate sdms-t-modifier__container'>
			<Portlet.Body className='sdms-last-margin sdms-p0'>
				<Portlet stickyTop={0} className='flex-shrink-0' ref={refHead}>
					<Portlet.Head>
						<Portlet.HeadLabel>
							<h3 className='sdms-portlet__head-title'>{`Booking ${data.reservationItemId ||
								'New'}`}</h3>
							<Portlet.Separator />
							<h3 className='sdms-portlet__head-title'>
								{data.product.name}
								<small>
									Total <b>{priceFormatter(totals.total + totals.extraTotal)}</b>
								</small>
							</h3>
							{totals.extraTotal > 0 && (
								<>
									<Portlet.Separator />
									<div className='sdms-portlet__head-desc'>
										{totals.extraTotal > 0 && (
											<>
												Extra <b>{priceFormatter(totals.extraTotal)}</b>
											</>
										)}
									</div>
								</>
							)}
						</Portlet.HeadLabel>
						{data.id !== 0 && (
							<Portlet.HeadToolbarActions>
								<ReservationStatusCell data={data} />
							</Portlet.HeadToolbarActions>
						)}
					</Portlet.Head>
				</Portlet>
				{(form.isMarina || form.isCampground) && (
					<Portlet className='flex-grow-0 sdms-min-h-fit-content'>
						<Portlet.Head>
							<Portlet.HeadLabelTitle>Vehicle</Portlet.HeadLabelTitle>
							<Portlet.HeadToolbarActions>
								{form.isMarina ? (
									<Button
										label='info'
										icon='Ship'
										size='sm'
										onClick={() => openModal({ open: modals.VESSEL_FORM })}>
										Add New Vessel
									</Button>
								) : (
									<Button
										label='info'
										icon='Caravan'
										size='sm'
										onClick={() => openModal({ open: modals.VEHICLE_FORM })}>
										Add New Vehicle
									</Button>
								)}
							</Portlet.HeadToolbarActions>
						</Portlet.Head>
						<Portlet.Body>
							<Section>
								<Section.Body>
									<FormGroup isLast>
										{form.isMarina ? (
											<FormField
												label='Vessel'
												name='vessel'
												id={data.id}
												colMd={4}
												inFormDesign={false}>
												<Button
													className='h-100 sdms-fitText'
													label='dark'
													icon='Marina'
													block
													onClick={() =>
														openModal({ open: modals.VESSEL_SELECT })
													}>
													{vessel && vessel.name}
												</Button>
											</FormField>
										) : (
											<FormField
												label='Vehicle'
												name='vehicle'
												id={data.id}
												colMd={4}
												inFormDesign={false}>
												<Button
													className='h-100 sdms-fitText'
													label='dark'
													icon='Caravan'
													block
													onClick={() =>
														openModal({ open: modals.VEHICLE_SELECT })
													}>
													{vehicle && vehicle.name}
												</Button>
											</FormField>
										)}
										{isFieldEnabled('searchLoa') && (
											<FormField
												name='loa'
												label='Length Overall'
												id={data.id}
												colMd={4}
												inFormDesign={false}
												showValidation={loaShowVal}
												valRes={loaValRes}>
												<LengthInputGroup
													ft={loaFt}
													ftOnChange={setLoaFt}
													inch={loaIn}
													inchOnChange={setLoaIn}
													placeHolder='Length Overall'
													onBlur={setLoaShowVal}
													onAnyChange={() =>
														onSearchFieldChange(isWaitList)
													}
												/>
											</FormField>
										)}
										{isFieldEnabled('searchBeam') && (
											<FormField
												name='beam'
												label='Beam'
												id={data.id}
												colMd={4}
												inFormDesign={false}
												showValidation={beamShowVal}
												valRes={beamValRes}>
												<LengthInputGroup
													ft={beamFt}
													ftOnChange={setBeamFt}
													inch={beamIn}
													inchOnChange={setBeamIn}
													placeHolder='Beam'
													onBlur={setBeamShowVal}
													onAnyChange={() =>
														onSearchFieldChange(isWaitList)
													}
												/>
											</FormField>
										)}
										{isFieldEnabled('searchDraft') && (
											<FormField
												name='draft'
												label='Draft'
												id={data.id}
												colMd={3}
												inFormDesign={false}
												showValidation={draftShowVal}
												valRes={draftValRes}>
												<LengthInputGroup
													ft={draftFt}
													ftOnChange={setDraftFt}
													inch={draftIn}
													inchOnChange={setDraftIn}
													placeHolder='Draft'
													onBlur={setDraftShowVal}
													onAnyChange={() =>
														onSearchFieldChange(isWaitList)
													}
												/>
											</FormField>
										)}
										{isFieldEnabled('searchHeight') && (
											<FormField
												name='height'
												label='Height'
												id={data.id}
												colMd={3}
												inFormDesign={false}
												showValidation={heightShowVal}
												valRes={heightValRes}>
												<LengthInputGroup
													ft={heightFt}
													ftOnChange={setHeightFt}
													inch={heightIn}
													inchOnChange={setHeightIn}
													placeHolder='Height'
													onBlur={setHeightShowVal}
													onAnyChange={() =>
														onSearchFieldChange(isWaitList)
													}
												/>
											</FormField>
										)}
										{isFieldEnabled('searchWeight') && (
											<FormField
												name='weight'
												label='Weight'
												id={data.id}
												colMd={3}
												inFormDesign={false}
												showValidation={weightShowVal}
												valRes={weightValRes}>
												<Input
													type='number'
													withOutSpin
													min={0}
													placeholder='Weight'
													value={weight}
													onChange={e => {
														onSearchFieldChange(isWaitList);
														weightOnChange(e);
													}}
													pattern={process.env.REACT_APP_INTEGER_PATTERN}
													append='lbs'
													onBlur={setWeightShowVal}
												/>
											</FormField>
										)}
									</FormGroup>
								</Section.Body>
							</Section>
						</Portlet.Body>
					</Portlet>
				)}

				<Portlet className='flex-grow-0 sdms-portlet__modifiers sdms-min-h-fit-content'>
					<Portlet.Body>
						<FormGroup isLast>
							<FormField name='fromDate' label='Start date' id={data.id} colMd={3}>
								<DatePicker
									id='fromDate'
									type='calendar'
									value={convertDateToUTC(fromDate.toDate())}
									onChange={e => {
										setFromDate(
											moment(fromDate)
												.year(moment(e.target.value).year())
												.month(moment(e.target.value).month())
												.date(moment(e.target.value).date())
										);
										onDateChanged(isWaitList);
										onFromDateChanged();
										onFormChange();
									}}
									disabled={
										isSearching ||
										disabled ||
										!hasEditReservationDatesPermission(userContext)
									}
								/>
							</FormField>
							<FormField
								ref={refForTimePicker}
								name='fromDate'
								label='Start time'
								id={data.id}
								colMd={3}>
								<TimePickerInput
									showSecond={false}
									value={fromDate}
									defaultValue={moment()}
									onChange={target => {
										setFromDate(
											target === null
												? moment(fromDate)
														.hour(0)
														.minute(0)
												: moment(fromDate)
														.hour(target.hour())
														.minute(target.minute())
										);

										onDateChanged(isWaitList);
										onFormChange();
									}}
									size={sizeTimePicker}
									use12Hours
									minuteStep={timePickerStep.current}
									disabled={
										isSearching ||
										disabled ||
										!hasEditReservationDatesPermission(userContext)
									}
								/>
							</FormField>
							<FormField
								name='toDate'
								label='End date'
								id={data.id}
								colMd={3}
								showValidation={toDateShowVal}
								valRes={toDateValRes}>
								<DatePicker
									id='toDate'
									type='calendar'
									value={convertDateToUTC(toDate.toDate())}
									onChange={e => {
										toDateOnChange({
											target: {
												value: moment(toDate)
													.year(moment(e.target.value).year())
													.month(moment(e.target.value).month())
													.date(moment(e.target.value).date()),
											},
										});

										onDateChanged(isWaitList);
									}}
									onBlur={setToDateShowVal}
									minDate={convertDateToUTC(fromDate.toDate())}
									disabled={
										isSearching ||
										disabled ||
										!hasEditReservationDatesPermission(userContext)
									}
								/>
							</FormField>
							<FormField
								name='toDate'
								label='End time'
								id={data.id}
								colMd={3}
								showValidation={toDateShowVal}
								valRes={toDateValRes}>
								<TimePickerInput
									showSecond={false}
									defaultValue={moment()}
									value={toDate}
									onChange={target => {
										toDateOnChange({
											target: {
												value:
													target === null
														? moment(toDate)
																.hour(0)
																.minute(0)
														: moment(toDate)
																.hour(target.hour())
																.minute(target.minute()),
											},
										});
										onDateChanged(isWaitList);
									}}
									size={sizeTimePicker}
									use12Hours
									minuteStep={timePickerStep.current}
									onClose={() => setToDateShowVal()}
									disabled={
										isSearching ||
										disabled ||
										!hasEditReservationDatesPermission(userContext)
									}
								/>
							</FormField>
							<FormField name='ignoreRules' label='No rules' id={data.id} colMd={1}>
								<Toggle
									value={ignoreRules}
									onChange={e => {
										setIgnoreRules(e.target.value);
										if (isWaitList)
											onCheckAvailability(e.target.value, isWaitList);

										onDateChanged(false);
									}}
									noPermission={!hasOverrideConstraintPermission(userContext)}
									disabled={isSearching || disabled}
								/>
							</FormField>
							<WaitListIgnoreCapacityToggle
								onIgnoreCapacityChange={setIgnoreCapacity}
								ignoreCapacity={ignoreCapacity}
								isWaitList={isWaitList}
								onIsWaitListChange={v => {
									setIsWaitList(v);
									if (v) onCheckAvailability(ignoreRules, ignoreCapacity, true);
									else {
										onSearchFieldChange(v);
										setPricing({});
									}
								}}
								disabled={isSearching || disabled}
								disableWaitList={
									data.id !== 0 &&
									data.status.value !== reservationStatuses.WAITLIST
								}
								disableIgnoreCapacity={
									data.product.bookingType.bookBy.value === bookBies.UNIT
								}
								col={2}
							/>
							<FormField
								name='searchAvailability'
								label='Search Availability'
								id={data.id}
								colMd={3}>
								<Button
									label='brand'
									text='Search Availability'
									icon='Search'
									size='sm'
									block
									disabled={isSearching || disabled || !toDateValRes.isValid}
									isSubmitting={isSearching}
									onClick={() => {
										setLoaShowVal();
										setBeamShowVal();
										setHeightShowVal();
										setDraftShowVal();
										setWeightShowVal();

										if (
											!loaValRes.isValid ||
											!beamValRes.isValid ||
											!heightValRes.isValid ||
											!draftValRes.isValid ||
											!weightValRes.isValid
										) {
											addErrorNotification('Please fill all fields');
											return;
										}

										onCheckAvailability(ignoreRules);
									}}
								/>
							</FormField>
							<FormField
								name='unit'
								label='Unit'
								id={data.id}
								valRes={unitValRes}
								showValidation={unitShowVal}
								colMd={3}
								noPermission={!hasEditReservationUnitPermission(userContext)}>
								<Selects
									options={availableUnits}
									placeholder='Auto Assign'
									value={swapUnits ? unitBackup.current : unit}
									onChange={unitOnChange}
									onBlur={setUnitShowVal}
									disabled={isUnitSelectDisable || disabled || swapUnits}
								/>
							</FormField>
							<FormField
								name='swapUnits'
								label={
									swapUnits ? (
										<>
											Swap
											<Badge
												className='sdms-cursor--pointer sdms-ml-10'
												design='warning'
												isInline
												isElevate
												onMouseDown={() => {
													if (unitBackup.current)
														unitOnChange({
															target: {
																value: unitBackup.current,
															},
														});
													setSwapUnits(false);
												}}>
												Use current unit
											</Badge>
										</>
									) : (
										'Swap'
									)
								}
								id={data.id}
								description={swapUnits ? 'Select unit for swap' : ''}
								colMd={3}
								noPermission={!hasSwapReservationUnitPermission(userContext)}>
								{swapUnits ? (
									<Selects
										options={swappableUnits}
										placeholder='Auto Assign'
										value={unit}
										onChange={unitOnChange}
										onBlur={setUnitShowVal}
										disabled={isUnitSelectDisable || disabled}
									/>
								) : (
									<Button
										label='brand'
										text='Swap Units'
										icon='Route'
										block
										size='sm'
										onClick={() => {
											if (!isDateChanged) {
												unitBackup.current = unit;
												setSwapUnits(true);
												setIsUnitSelectDisable(false);
											}
										}}
										disabled={
											isSearching ||
											disabled ||
											swappableUnits.filter(u => unit && unit.id !== u.id)
												.length === 0
										}
									/>
								)}
							</FormField>
						</FormGroup>
					</Portlet.Body>
				</Portlet>
				<Portlet className='flex-grow-0 sdms-portlet__modifiers sdms-min-h-fit-content'>
					<Portlet.Body>
						<div className='row'>
							<div className='col-md-6'>
								<Section title='Plans'>
									<Section.Body>
										<FormGroup>
											<FormField
												name='ratePlan'
												label='Rate Plan'
												id={data.id}
												col={12}
												description={
													data.id ? 'Search Availability to update' : ''
												}>
												<Selects
													options={availableRatePlans}
													placeholder='Rate plan'
													value={ratePlan}
													onChange={e => {
														ratePlanOnChange(e);
														policyOnChange({
															target: {
																value: getPolicy(
																	pricing,
																	e.target.value,
																	data.product,
																	policies
																),
															},
														});
														subtotalOnChange({
															target: {
																value: numberFormat(
																	pricing.ratePlans[
																		e.target.value.id
																	].reduce((a, b) => a + b)
																),
															},
														});
													}}
													displayKey='internalName'
													disabled={areFieldsDisabled()}
													disableClearable
													noPermission={
														!hasOverrideConstraintPermission(
															userContext
														)
													}
												/>
											</FormField>
											<FormField
												name='policy'
												label='Cancellation Policy'
												id={data.id}
												col={12}
												noPermission={
													!hasEditReservationCancellationPolicyPermission(
														userContext
													)
												}>
												<Selects
													options={policies}
													placeholder='Cancellation Policy'
													value={policy}
													onChange={policyOnChange}
													disabled={areFieldsDisabled()}
												/>
											</FormField>
											<FormField
												name='depositAmount'
												label='Deposit Required'
												id={data.id}
												valRes={depositAmountValRes}
												showValidation={depositAmountShowVal}
												col={12}>
												<Input
													type='text'
													placeholder='Deposit amount'
													value={depositAmount}
													onChange={depositAmountOnChange}
													onBlur={setDepositAmountShowVal}
													disabled={areFieldsDisabled()}
													pattern={process.env.REACT_APP_PRICE_PATTERN}
												/>
											</FormField>
											<FormField
												name='productContracts'
												label='Product Contracts'
												id={data.id}
												col={12}
												noPermission={
													!hasEditReservationProductContractsPermission(
														userContext
													)
												}>
												<Selects
													options={contracts}
													placeholder='Product Contracts'
													value={productContracts}
													onChange={productContractsOnChange}
													disabled={areFieldsDisabled()}
													multiple
												/>
											</FormField>
										</FormGroup>
									</Section.Body>
								</Section>
							</div>
							<div className='col-md-6'>
								<Alert
									outline
									title='Pricing'
									design='dark'
									marginLess={false}
									className={classNames({
										'sdms-disable': areFieldsDisabled(),
									})}>
									<div className='row align-items-baseline sdms-fitText--md sdms-mb-5'>
										<div className='col-4'>Subtotal</div>
										<div className='col-8 text-right'>
											<Input
												className='text-right sdms-fitText--lg sdms-font-inherit'
												type='text'
												placeholder='Subtotal'
												value={subtotal.toString()}
												pattern={process.env.REACT_APP_PRICE_PATTERN}
												onChange={subtotalOnChange}
												disabled={areFieldsDisabled()}
												prependIcon='Dollar'
												prependIconColor='var(--dark)'
												noPermission={
													!hasEditReservationPricePermission(userContext)
												}
											/>
										</div>
									</div>
									<div className='row sdms-fitText--md sdms-mb-10'>
										<div className='col'>Extras</div>
										<div className='col-auto text-right'>
											<span className='sdms-mr-15'>
												{priceFormatter(totals.extraSubtotal)}
											</span>
										</div>
									</div>
									<div className='row sdms-fitText--md sdms-mb-10'>
										<div className='col'>Tax</div>
										<div className='col-auto text-right'>
											<span className='sdms-mr-15'>
												{priceFormatter(totals.tax + totals.extraTax)}
											</span>
										</div>
									</div>
									<Separator space='sm' />
									<div className='row sdms-fitText--lg sdms-font-bolder'>
										<div className='col'>Total</div>
										<div className='col-auto text-right'>
											<span className='sdms-mr-15'>
												{priceFormatter(totals.total + totals.extraTotal)}
											</span>
										</div>
									</div>
									<div className='sdms-mb-20' />
									<div className='row sdms-fitText--md sdms-mb-10'>
										<div className='col'>Deposit Required</div>
										<div className='col-auto text-right'>
											<span className='sdms-mr-15'>
												{priceFormatter(depositAmount)}
											</span>
										</div>
									</div>
									<Separator space='sm' />
									<div className='row sdms-fitText--md sdms-mb-10'>
										<div className='col'>Amount Paid</div>
										<div className='col-auto text-right'>
											<span className='sdms-mr-15'>
												{priceFormatter(totals.amountPaid)}
											</span>
										</div>
									</div>
									<div className='sdms-mb-20' />
									<div className='row sdms-fitText--lg sdms-font-bolder'>
										<div className='col'>Balance</div>
										<div className='col-auto text-right'>
											<span className='sdms-mr-15'>
												{priceFormatter(totals.balanceDue)}
											</span>
										</div>
									</div>
								</Alert>
							</div>
						</div>
						{Object.keys(extraCharges).length > 0 && (
							<Section title='Price Adjustments'>
								<Section.Body>
									<FormGroup>
										<div
											className='col-12'
											ref={extrasRef}
											style={{ scrollMarginTop: sizeHead.height }}>
											{Object.entries(extraCharges).map(exc => {
												return (
													<Portlet
														noPermission={
															!hasEditReservationFeesPermission(
																userContext
															)
														}
														key={`fragment${exc[0]}`}
														border
														className={classNames('sdms-list-layout', {
															[`sdms-portlet__table--wrap-${mediaBreakpoint}`]: mediaBreakpoint,
														})}>
														<Portlet.Head>
															<Portlet.HeadLabelTitle>
																{exc[0]}
															</Portlet.HeadLabelTitle>
														</Portlet.Head>
														<Portlet.Head
															className={classNames(
																'sdms-list-layout__head',
																{
																	[`table--responsive--${responsive}`]: responsive,
																}
															)}>
															<Portlet.HeadToolbar>
																<table className='table'>
																	<ExtraFeeHead settingsEnable />
																</table>
															</Portlet.HeadToolbar>
														</Portlet.Head>
														<Portlet.Body
															className={classNames({
																[`table--responsive--${responsive}`]: responsive,
															})}>
															<table
																className={classNames(
																	'table',
																	`table--responsive-col--6`
																)}>
																<ExtraFeeHead />
																<tbody>
																	{exc[1].map(extraCharge => {
																		const extraChargeItem = selectedExtraCharges.find(
																			sec =>
																				sec.extraCharge
																					.id ===
																				extraCharge.id
																		);

																		return (
																			<ExtraFeeRow
																				key={extraCharge.id}
																				data={
																					extraChargeItem
																				}
																				extraCharge={{
																					...extraCharge,
																					taxCode: getItemTaxCode(
																						taxCode,
																						{
																							taxCode:
																								extraCharge.taxCode ||
																								taxCode,
																						}
																					),
																				}}
																				onUpdate={_extraChargeItem => {
																					if (
																						Object.keys(
																							pricing
																						).length
																					) {
																						onExtraChargeItemUpdate(
																							_extraChargeItem,
																							selectedExtraCharges,
																							setSelectedExtraCharges,
																							{
																								bookingPeriod:
																									data
																										.product
																										.bookingPeriod
																										.value,
																								quantity:
																									quantityRef.current,
																								subtotal:
																									subtotal ===
																									''
																										? 0
																										: parseFloat(
																												subtotal
																										  ),
																								taxCode,
																								fromDate,
																								product:
																									data.product,
																							},
																							taxRate,
																							getPrices(
																								pricing,
																								ratePlan
																							),
																							ignoreRules
																						);
																					}
																				}}
																				onFormChange={
																					onFormChange
																				}
																				disabled={areFieldsDisabled()}
																				currentExtraChargeItemProductId={
																					currentExtraChargeItemProductId
																				}
																				customer={
																					reservation.customer
																				}
																				onSettingsClick={() => {
																					openModal({
																						open:
																							modals.EXTRA_FEE_SETTINGS,
																						id:
																							extraChargeItem.id,
																					});
																				}}
																			/>
																		);
																	})}
																</tbody>
															</table>
														</Portlet.Body>
													</Portlet>
												);
											})}
										</div>
									</FormGroup>
								</Section.Body>
							</Section>
						)}
						<Section>
							<Section.Body>
								<FormGroup>
									<FormField
										name='note'
										label='Special Requests'
										id={data.id}
										col={12}
										noPermission={
											!hasEditReservationSpecialRequestsPermission(
												userContext
											)
										}>
										<Textarea
											onChange={noteOnChange}
											value={note}
											disabled={!unit || isSearching}
										/>
									</FormField>
								</FormGroup>
							</Section.Body>
						</Section>
					</Portlet.Body>
				</Portlet>
				<Portlet className='flex-grow-0 sdms-portlet__modifiers sdms-min-h-fit-content'>
					<Portlet.Body>
						<Section title='Deferred Income'>
							<Section.Body>
								<FormGroup row>
									<FormField
										name='enableDeferredIncome'
										label='Enable Deferred Income'
										id={data.id}
										colMd={3}>
										<Toggle
											spaceLess
											value={enableDeferredIncome}
											onChange={enableDeferredIncomeOnChange}
											disabled={
												areFieldsDisabled() ||
												(data.product && !data.product.enableDeferredIncome)
											}
										/>
									</FormField>
									<FormField
										name='deferredIncomeFrequency'
										label='Frequency'
										id={data.id}
										valRes={deferredIncomeFrequencyValRes}
										showValidation={deferredIncomeFrequencyShowVal}
										colMd={3}>
										<Selects
											options={filterInvoicingFrequency(
												invoicingFrequencies,
												data.product.bookingPeriod.value
											)}
											placeholder={`Frequency${
												enableDeferredIncome ? ' (Required)' : ''
											}`}
											value={deferredIncomeFrequency}
											onChange={deferredIncomeFrequencyOnChange}
											onBlur={setDeferredIncomeFrequencyShowVal}
											displayKey='value'
											disabled={!enableDeferredIncome || areFieldsDisabled()}
											disableClearable
										/>
									</FormField>
									<FormField
										name='deferredIncomeSalesAccount'
										label='Sales Income Account'
										id={data.id}
										valRes={deferredIncomeSalesAccountValRes}
										showValidation={deferredIncomeSalesAccountShowVal}
										colMd={3}>
										<Selects
											options={accounts}
											placeholder={`Sales Income Account${
												enableDeferredIncome ? ' (Required)' : ''
											}`}
											value={deferredIncomeSalesAccount}
											onChange={deferredIncomeSalesAccountOnChange}
											onBlur={setDeferredIncomeSalesAccountShowVal}
											disabled={!enableDeferredIncome || areFieldsDisabled()}
											disableClearable
										/>
									</FormField>
								</FormGroup>
							</Section.Body>
						</Section>
						<Section title='Accounting'>
							<Section.Body>
								<FormField
									name='taxCode'
									label='Tax Code'
									id={data.id}
									valRes={taxCodeValRes}
									showValidation={taxCodeShowVal}
									colLg={3}>
									<Selects
										disabled={areFieldsDisabled()}
										options={taxCodes}
										placeholder='Tax code'
										value={taxCode}
										onChange={taxCodeOnChange}
										onBlur={setTaxCodeShowVal}
									/>
								</FormField>
							</Section.Body>
						</Section>
					</Portlet.Body>
				</Portlet>
				<ContractList
					module={form.module.value}
					reservationItem={data}
					reservation={reservation}
				/>
				<Portlet stickyBottom={0} className='flex-shrink-0'>
					<Portlet.Foot
						tall='sm'
						className='sdms-align-left'
						subClassName='justify-content-between'>
						<div className='col-auto'>
							<Button design='clean' text='Back' size='sm' onClick={onClose} />
						</div>
						<div className='col-auto'>
							<Button
								className={classNames('sdms-mw-105 justify-content-center', {
									'sdms-fading-dots':
										submitButtonAttr.text ===
										process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
								})}
								label={submitButtonAttr.color}
								text={submitButtonAttr.text}
								icon={submitButtonAttr.icon}
								size='sm'
								disabled={
									submitButtonAttr.text ===
									process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT
								}
								onClick={() => {
									setUnitShowVal();
									setDepositAmountShowVal();
									setToDateShowVal();
									setLoaShowVal();
									setBeamShowVal();
									setHeightShowVal();
									setDraftShowVal();
									setWeightShowVal();

									if (isDateChanged) {
										addErrorNotification('Please check availability');
										return;
									}

									if (!reservation.customer) {
										addErrorNotification('Please select customer first');
										return;
									}

									if (isValid) {
										const _updateData = {
											id: data.id,
											unit,
											product: data.product,
											fromDate: fromDate.toISOString(),
											toDate: toDate.toISOString(),
											subtotal,
											tax: totals.tax,
											total: totals.total,
											depositAmount: parseFloat(depositAmount),
											ratePlan,
											policy,
											extraCharges: selectedExtraCharges,
											quantity: quantityRef.current,
											note: note === '' ? null : note,
											ignoredRules: ignoreRules,
											productContracts,
											capacity: getCapacity(
												data.product,
												quantityRef.current,
												loa,
												beam
											),
											taxCode,
											enableDeferredIncome,
											deferredIncomeFrequency,
											deferredIncomeSalesAccount,
											ignoreCapacity,
											vessel,
											vehicle,
											loa,
											beam,
											height,
											draft,
											weight,
										};

										onItemUpdate(_updateData);
									}
								}}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Portlet.Body>
			<VehicleModal
				isOpen={modal.open === modals.VEHICLE_SELECT || modal.open === modals.VEHICLE_FORM}
				onClose={closeModal}
				defaultVehicle={vehicle}
				onSelect={v => {
					updateVehicleData.current = true;
					setVehicle(v);
					closeModal();
				}}
				hasForm
				openForm={modal.open === modals.VEHICLE_FORM}
				enumRvTypes={enumRvTypes}
				enumVehicleMakes={enumVehicleMakes}
				defaultSearchText={reservation?.customer?.displayName || ''}
			/>
			<VesselModal
				isOpen={modal.open === modals.VESSEL_SELECT || modal.open === modals.VESSEL_FORM}
				onClose={closeModal}
				defaultVessel={vessel}
				onSelect={v => {
					updateVesselData.current = true;
					setVessel(v);
					closeModal();
				}}
				hasForm
				openForm={modal.open === modals.VESSEL_FORM}
				enumBoatTypes={enumBoatTypes}
				enumBoatMakes={enumBoatMakes}
				defaultSearchText={reservation?.customer?.displayName || ''}
			/>
			{modal.open === modals.EXTRA_FEE_SETTINGS &&
				selectedExtraCharges.find(sec => sec.id === modal.id) && (
					<ExtraFeeSettings
						data={selectedExtraCharges.find(sec => sec.id === modal.id)}
						onClose={closeModal}
						invoicingFrequencies={invoicingFrequencies}
						onFormChange={onFormChange}
						accounts={accounts}
						reservationItem={{
							fromDate,
						}}
						disableInvoicing
					/>
				)}
		</Portlet>
	);
};
ReservationAdvancedTransientItemForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	form: PropTypes.object.isRequired,
	data: PropTypes.shape({
		id: PropTypes.number,
		unit: PropTypes.object,
		fromDate: PropTypes.string,
		toDate: PropTypes.string,
		depositAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		quantity: PropTypes.number,
		product: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
			bookingPeriod: PropTypes.object,
			bookingType: PropTypes.object,
			checkInTime: PropTypes.string,
			checkOutTime: PropTypes.string,
			module: PropTypes.object,
			unitMarinas: PropTypes.arrayOf(PropTypes.object),
			unitCampgrounds: PropTypes.arrayOf(PropTypes.object),
			unitBookings: PropTypes.arrayOf(PropTypes.object),
			additionalTaxes: PropTypes.arrayOf(PropTypes.object),
			productExtraCharges: PropTypes.arrayOf(PropTypes.object),
			policy: PropTypes.object,
			bookingInterval: PropTypes.number,
			bookingCalculation: PropTypes.object,
			productContracts: PropTypes.arrayOf(PropTypes.object),
			enableDeferredIncome: PropTypes.bool,
		}),
		subtotal: PropTypes.number,
		tax: PropTypes.number,
		total: PropTypes.number,
		ratePlan: PropTypes.object,
		extraCharges: PropTypes.arrayOf(PropTypes.object),
		policy: PropTypes.object,
		note: PropTypes.string,
		status: PropTypes.object,
		ignoredRules: PropTypes.bool,
		ignoredCapacity: PropTypes.bool,
		productContracts: PropTypes.arrayOf(PropTypes.object),
		customerSettlements: PropTypes.arrayOf(PropTypes.object),
		reservationItemId: PropTypes.string,
		vessel: PropTypes.object,
		vehicle: PropTypes.object,
		loa: PropTypes.number,
		beam: PropTypes.number,
		draft: PropTypes.number,
		height: PropTypes.number,
		weight: PropTypes.number,
		taxCode: PropTypes.object,
	}),
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	taxRate: PropTypes.shape({
		amount: PropTypes.number,
		type: PropTypes.object,
	}),
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	policies: PropTypes.arrayOf(PropTypes.object),
	onUpdate: PropTypes.func,
	onClose: PropTypes.func,
	disabled: PropTypes.bool,
	onFormChange: PropTypes.func,
	contracts: PropTypes.arrayOf(PropTypes.object),
	responsive: PropTypes.oneOf([null, 'scroll', 'grid']),
	mediaBreakpoint: PropTypes.oneOf([null, 'sm', 'md', 'lg', 'xl']),
	currentExtraChargeItemProductId: PropTypes.number,
	onOverride: PropTypes.func,
	enumBoatMakes: PropTypes.arrayOf(PropTypes.object),
	enumBoatTypes: PropTypes.arrayOf(PropTypes.object),
	enumVehicleMakes: PropTypes.arrayOf(PropTypes.object),
	enumRvTypes: PropTypes.arrayOf(PropTypes.object),
	invoicingFrequencies: PropTypes.arrayOf(PropTypes.object),
	accounts: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
};
ReservationAdvancedTransientItemForm.defaultProps = {
	data: {
		id: 0,
		unit: {},
		fromDate: {},
		toDate: {},
		depositAmount: 0,
		product: {},
		subtotal: 0,
		tax: 0,
		total: 0,
		extraCharges: [],
		note: '',
		ignoredRules: false,
		vessel: null,
		vehicle: null,
		loa: null,
		beam: null,
		draft: null,
		height: null,
		weight: null,
	},
	reservation: {},
	taxRate: {},
	ratePlans: [],
	policies: [],
	onClose: () => {},
	onUpdate: () => {},
	disabled: false,
	onFormChange: () => {},
	contracts: [],
	responsive: 'scroll',
	mediaBreakpoint: null,
	currentExtraChargeItemProductId: 0,
	onOverride: () => {},
	enumBoatMakes: [],
	enumBoatTypes: [],
	enumVehicleMakes: [],
	enumRvTypes: [],
	invoicingFrequencies: [],
	accounts: [],
	taxCodes: [],
};

export default ReservationAdvancedTransientItemForm;
