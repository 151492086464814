import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { mapDisplayOptions, mapItemColors } from '../../../utils/constants/constants';

import Badge from './Badge';

const MapColorsTooltip = ({ type }) => {
	return (
		<>
			<div
				data-tip='tooltip'
				data-for='mapColors'
				className='register-item__table--draggable-parent'
				data-event='touchstart focus mouseover'
				data-event-off='mouseout'
				globalEventOff='touchstart'
				onClick={() => {}}
				style={{
					cursor: 'pointer',
				}}
				role='presentation'>
				<span className='sdms-font-info sdms-font-size-10-px sdms-font-bold sdms-cursor--pointer'>
					Colors
				</span>
			</div>
			<ReactTooltip
				delayShow={250}
				delayHide={250}
				id='mapColors'
				type='light'
				place='bottom'
				className='sdms-date-range-picker'>
				<div className='sdms-map-item-tooltip'>
					{Object.values(mapItemColors)
						.filter(c => c.type === type)
						.map(c => (
							<Badge
								className='sdms-cursor--pointer sdms-mw-100 sdms-margin-t-10'
								design={c.color}
								isInline
								isElevate
								fontWeight='bold'>
								{c.label}
							</Badge>
						))}
				</div>
			</ReactTooltip>
		</>
	);
};

MapColorsTooltip.propTypes = {
	type: PropTypes.string,
};
MapColorsTooltip.defaultProps = {
	type: mapDisplayOptions.OCCUPANCY,
};

export default MapColorsTooltip;
