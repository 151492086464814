import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SlideDown } from 'react-slidedown';

import UserContext from '../../../../../app/contexts/UserContext';
import useField from '../../../../../utils/hooks/useField';
import useModal from '../../../../../utils/hooks/useModal';
import {
	email as emailValidation,
	maxLength,
	phoneNumber,
	phoneNumberRequired,
	required,
	uniqueFnc,
} from '../../../../../utils/helpers/validation';
import {
	addErrorNotification,
	addSuccessNotification,
	phoneNumberParser,
} from '../../../../../utils/helpers/helper';
import apiCall, { parseData } from '../../../../../utils/helpers/apiCall';

import FormSection from '../../../layout/FormSection';
import FormSectionMoreLink from '../../../element/FormSectionMoreLink';
import FormField from '../../../template/FormField';
import Input from '../../../field/Input';
import Selects from '../../../field/Selects';
import Button from '../../../element/Button';
import Toggle from '../../../field/Toggle';
import CustomerModal from '../../../modals/CustomerModal';

const modals = {
	CUSTOMER: 'customer',
};

const Customer = ({
	sectionRef,
	reservation,
	customer,
	disabled,
	countries,
	states,
	onFormChange,
	onCustomerChange,
	setSectionIsValid,
}) => {
	const userContext = useContext(UserContext);

	const data = useRef(customer);

	const [showMore, setShowMore] = useState(false);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [isValid, setIsValid] = useState(false);

	const [modal, openModal, closeModal] = useModal();

	const [isEditing, setIsEditing] = useState(false);

	const fieldsDisabled = useMemo(() => {
		if (isEditing) return false;

		return disabled || !!data.current.id;
	}, [disabled, isEditing]);

	const display = useMemo(() => {
		return {
			create:
				(!reservation.id ||
					reservation?.customer?.id ===
						userContext.data.selectedOutlet.settings.defaultCustomer.id) &&
				!isEditing,
			edit: !!data.current.id && !isEditing,
			save: isEditing,
			cancel: isEditing,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservation, isEditing, customer]);

	useEffect(() => {
		setShowMore(!data.current.id || isEditing);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditing]);

	const [
		displayName,
		displayNameOnChange,
		displayNameValRes,
		displayNameShowVal,
		setDisplayNameShowVal,
		validateDisplayName,
	] = useField(data.current, 'displayName', onFormChange, [required, maxLength()]);

	const [
		email,
		emailOnChange,
		emailValRes,
		emailShowVal,
		setEmailShowVal,
		validateEmail,
	] = useField(data.current, 'email', onFormChange, [required, maxLength(), emailValidation]);

	const [
		firstName,
		firstNameOnChange,
		firstNameValRes,
		firstNameShowVal,
		setFirstNameShowVal,
	] = useField(data.current, 'firstName', onFormChange, [maxLength()]);

	const [
		lastName,
		lastNameOnChange,
		lastNameValRes,
		lastNameShowVal,
		setLastNameShowVal,
	] = useField(data.current, 'lastName', onFormChange, [maxLength()]);

	const [
		customerCompany,
		customerCompanyOnChange,
		customerCompanyValRes,
		customerCompanyShowVal,
		setCustomerCompanyShowVal,
	] = useField(data.current, 'customerCompany', onFormChange, [maxLength()]);

	const [phone, phoneOnChange, phoneValRes, phoneShowVal, setPhoneShowVal] = useField(
		data.current,
		'phone',
		onFormChange,
		[phoneNumberRequired(userContext?.data?.selectedOutlet), phoneNumber, maxLength()],
		'',
		phoneNumberParser
	);

	const [
		altPhone,
		altPhoneOnChange,
		altPhoneValRes,
		altPhoneShowVal,
		setAltPhoneShowVal,
	] = useField(
		data.current,
		'altPhone',
		onFormChange,
		[phoneNumber, maxLength()],
		'',
		phoneNumberParser
	);

	const [
		addressLineOne,
		addressLineOneOnChange,
		addressLineOneValRes,
		addressLineOneShowVal,
		setAddressLineOneShowVal,
	] = useField(data.current, 'addressLineOne', onFormChange, [maxLength()]);

	const [
		addressLineTwo,
		addressLineTwoOnChange,
		addressLineTwoValRes,
		addressLineTwoShowVal,
		setAddressLineTwoShowVal,
	] = useField(data.current, 'addressLineTwo', onFormChange, [maxLength()]);

	const [city, cityOnChange, cityValRes, cityShowVal, setCityShowVal] = useField(
		data.current,
		'city',
		onFormChange,
		[maxLength()]
	);

	const [state, stateOnChange] = useField(customer, 'state', onFormChange, [], null);

	const [zip, zipOnChange, zipValRes, zipShowVal, setZipShowVal] = useField(
		data.current,
		'zip',
		onFormChange,
		[maxLength()]
	);

	const [country, countryOnChange] = useField(
		data.current,
		'country',
		onFormChange,
		[],
		userContext.data.selectedOutlet.country
	);

	const [houseAccount, houseAccountOnChange] = useField(
		data.current,
		'houseAccount',
		onFormChange,
		[],
		true
	);

	const [canSavePaymentMethod, canSavePaymentMethodOnChange] = useField(
		data.current,
		'canSavePaymentMethod',
		onFormChange,
		[],
		true
	);

	const changeCountryState = ({ target }) => {
		stateOnChange({
			target: {
				name: 'state',
				value: {},
			},
		});

		countryOnChange({ target });
	};

	const onReset = (_customer = null) => {
		data.current.id = _customer?.id || 0;
		displayNameOnChange({ target: { value: _customer?.displayName || '' } });
		emailOnChange({ target: { value: _customer?.email || '' } });
		firstNameOnChange({ target: { value: _customer?.firstName || '' } });
		lastNameOnChange({ target: { value: _customer?.lastName || '' } });
		customerCompanyOnChange({ target: { value: _customer?.customerCompany || '' } });
		phoneOnChange({ target: { value: _customer?.phone || '' } });
		altPhoneOnChange({ target: { value: _customer?.altPhone || '' } });
		addressLineOneOnChange({ target: { value: _customer?.addressLineOne || '' } });
		addressLineTwoOnChange({ target: { value: _customer?.addressLineTwo || '' } });
		cityOnChange({ target: { value: _customer?.city || '' } });
		stateOnChange({ target: { value: _customer?.state || null } });
		zipOnChange({ target: { value: _customer?.zip || '' } });
		countryOnChange({
			target: { value: _customer?.country || userContext.data.selectedOutlet.country },
		});
		houseAccountOnChange({
			target: {
				value:
					typeof _customer?.houseAccount === 'undefined' ? true : _customer.houseAccount,
			},
		});
		canSavePaymentMethodOnChange({
			target: {
				value:
					typeof _customer?.canSavePaymentMethod === 'undefined'
						? true
						: _customer.canSavePaymentMethod,
			},
		});
	};

	const onCreate = () => {
		if (data.current.id) {
			onReset();
			setIsEditing(true);
		} else onSave();
	};

	const onSave = () => {
		setIsSubmitted(true);

		if (!isValid) return;

		setIsSubmitting(true);

		const formData = JSON.parse(
			JSON.stringify({
				displayName,
				email,
				firstName,
				lastName,
				customerCompany,
				phone,
				altPhone,
				addressLineOne,
				addressLineTwo,
				city,
				state,
				zip,
				country,
				houseAccount,
				canSavePaymentMethod,
				password: Math.floor(Math.random() * 1000000).toString(),
			})
		);

		// Parse data for api call.
		Object.keys(formData).forEach(field => {
			formData[field] = parseData(formData[field]);
		});

		formData.inActive = false;
		formData.isActive = true;

		apiCall(
			data.current.id ? 'PUT' : 'POST',
			'customers',
			res => {
				onCustomerChange(res);
				addSuccessNotification(
					`Customer ${displayName} successfully ${
						data.current.id ? 'updated' : 'created'
					}`
				);
				setIsSubmitting(false);
				setIsEditing(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsSubmitting(false);
				setIsEditing(false);
			},
			data.current.id || '',
			formData
		);
	};

	const onCancel = () => {
		if (reservation?.customer?.id) onReset(reservation.customer);

		setIsEditing(false);
	};

	useEffect(() => {
		if (isSubmitted) {
			setDisplayNameShowVal();
			setEmailShowVal();
			setPhoneShowVal();
			setAltPhoneShowVal();
			setFirstNameShowVal();
			setLastNameShowVal();
			setCustomerCompanyShowVal();
			setAddressLineOneShowVal();
			setAddressLineTwoShowVal();
			setCityShowVal();
			setZipShowVal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isSubmitted,
		setDisplayNameShowVal,
		setEmailShowVal,
		setPhoneShowVal,
		setAltPhoneShowVal,
		setFirstNameShowVal,
		setLastNameShowVal,
		setCustomerCompanyShowVal,
		setAddressLineOneShowVal,
		setAddressLineTwoShowVal,
		setCityShowVal,
		setZipShowVal,
	]);

	useEffect(() => {
		setIsValid(
			displayNameValRes.isValid &&
				emailValRes.isValid &&
				phoneValRes.isValid &&
				altPhoneValRes.isValid &&
				firstNameValRes.isValid &&
				lastNameValRes.isValid &&
				customerCompanyValRes.isValid &&
				addressLineOneValRes.isValid &&
				addressLineTwoValRes.isValid &&
				cityValRes.isValid &&
				zipValRes.isValid
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		displayNameValRes.isValid,
		emailValRes.isValid,
		phoneValRes.isValid,
		altPhoneValRes.isValid,
		firstNameValRes.isValid,
		customerCompanyValRes.isValid,
		lastNameValRes.isValid,
		addressLineOneValRes.isValid,
		addressLineTwoValRes.isValid,
		cityValRes.isValid,
		zipValRes.isValid,
	]);

	useEffect(() => {
		if (!data.current.id || isEditing)
			validateDisplayName(displayName, [
				uniqueFnc('customers', 'displayName', data.current.id),
			]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [displayName]);

	useEffect(() => {
		if (!data.current.id || isEditing)
			validateEmail(email, [uniqueFnc('customers', 'email', data.current.id)]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [email]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setSectionIsValid(!isEditing), [isEditing]);

	return (
		<>
			<FormSection
				sectionRef={sectionRef}
				title='CUSTOMER'
				icon='User'
				headerActionLabel={disabled ? '' : 'Existing Customer'}
				onHeaderActionClick={() => openModal({ open: modals.CUSTOMER })}>
				<div className='sdms-advanced-reservation-item-form-customer'>
					<div className='row sdms-mb-15'>
						<FormField
							name='displayName'
							label='Display Name'
							inFormDesign={false}
							valRes={displayNameValRes}
							showValidation={displayNameShowVal}
							loadingContainer
							colMd={6}>
							<Input
								type='text'
								placeholder='DisplayName (Required)'
								value={displayName}
								onChange={displayNameOnChange}
								onBlur={setDisplayNameShowVal}
								disabled={fieldsDisabled}
							/>
						</FormField>
						<FormField
							name='email'
							label='Email'
							inFormDesign={false}
							valRes={emailValRes}
							showValidation={emailShowVal}
							loadingContainer
							colMd={6}>
							<Input
								type='text'
								placeholder='Email (Required)'
								autoComplete={false}
								value={email}
								onChange={emailOnChange}
								onBlur={setEmailShowVal}
								disabled={fieldsDisabled}
							/>
						</FormField>
						<FormField
							name='phone'
							label='Phone'
							inFormDesign={false}
							valRes={phoneValRes}
							showValidation={phoneShowVal}
							colMd={6}>
							<Input
								type='text'
								placeholder='(555) 555-5555'
								value={phone}
								onChange={phoneOnChange}
								onBlur={setPhoneShowVal}
								mask={process.env.REACT_APP_PHONE_FORMAT}
								disabled={fieldsDisabled}
							/>
						</FormField>
						<FormField
							name='altPhone'
							label='Alt. Phone'
							inFormDesign={false}
							valRes={altPhoneValRes}
							showValidation={altPhoneShowVal}
							colMd={6}>
							<Input
								type='text'
								placeholder='(555) 555-5555'
								value={altPhone}
								onChange={altPhoneOnChange}
								onBlur={setAltPhoneShowVal}
								mask={process.env.REACT_APP_PHONE_FORMAT}
								disabled={fieldsDisabled}
							/>
						</FormField>
					</div>
					<SlideDown>
						{showMore ? (
							<div className='row sdms-mb-15'>
								<FormField
									name='firstName'
									label='First Name'
									inFormDesign={false}
									colMd={6}
									valRes={firstNameValRes}
									showValidation={firstNameShowVal}>
									<Input
										type='text'
										placeholder='First Name'
										autoComplete={false}
										value={firstName}
										onChange={firstNameOnChange}
										onBlur={setFirstNameShowVal}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='lastName'
									label='Last Name'
									inFormDesign={false}
									colMd={6}
									valRes={lastNameValRes}
									showValidation={lastNameShowVal}>
									<Input
										type='text'
										placeholder='Last Name'
										value={lastName}
										onChange={lastNameOnChange}
										onBlur={setLastNameShowVal}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='customerCompany'
									label='Company'
									inFormDesign={false}
									colMd={6}
									valRes={customerCompanyValRes}
									showValidation={customerCompanyShowVal}>
									<Input
										type='text'
										placeholder='Company'
										value={customerCompany}
										onChange={customerCompanyOnChange}
										onBlur={setCustomerCompanyShowVal}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='addressLineOne'
									label='Address 1'
									inFormDesign={false}
									colMd={6}
									valRes={addressLineOneValRes}
									showValidation={addressLineOneShowVal}>
									<Input
										type='text'
										placeholder='Address Line 1'
										value={addressLineOne}
										onChange={addressLineOneOnChange}
										onBlur={setAddressLineOneShowVal}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='addressLineTwo'
									label='Address 2'
									inFormDesign={false}
									colMd={6}
									valRes={addressLineTwoValRes}
									showValidation={addressLineTwoShowVal}>
									<Input
										type='text'
										placeholder='Address Line 2'
										value={addressLineTwo}
										onChange={addressLineTwoOnChange}
										onBlur={setAddressLineTwoShowVal}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='city'
									label='City'
									inFormDesign={false}
									colMd={6}
									valRes={cityValRes}
									showValidation={cityShowVal}>
									<Input
										type='text'
										placeholder='City'
										value={city}
										onChange={cityOnChange}
										onBlur={setCityShowVal}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='state'
									label='State'
									inFormDesign={false}
									colMd={6}>
									<Selects
										options={
											Object.keys(country || {}).length
												? states.filter(s => s.country.id === country.id)
												: []
										}
										placeholder='State'
										value={state}
										onChange={stateOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='zip'
									label='ZIP Code'
									inFormDesign={false}
									colMd={6}
									valRes={zipValRes}
									showValidation={zipShowVal}>
									<Input
										type='text'
										placeholder='ZIP Code'
										value={zip}
										onChange={zipOnChange}
										onBlur={setZipShowVal}
										mask={process.env.REACT_APP_ZIP_FORMAT}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='country'
									label='Country'
									inFormDesign={false}
									colMd={6}>
									<Selects
										options={countries}
										placeholder='Select Country'
										value={country}
										onChange={changeCountryState}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='houseAccount'
									label='House Account'
									inFormDesign={false}
									colMd={3}>
									<Toggle
										spaceLess
										value={houseAccount}
										onChange={houseAccountOnChange}
										noPermission={
											!userContext.hasPermission('enable_house_account')
										}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='canSavePaymentMethod'
									label='Can Save Payment Method'
									inFormDesign={false}
									colMd={3}>
									<Toggle
										spaceLess
										value={canSavePaymentMethod}
										onChange={canSavePaymentMethodOnChange}
										noPermission={
											!userContext.hasPermission('save_payment_methods')
										}
										disabled={fieldsDisabled}
									/>
								</FormField>
							</div>
						) : null}
					</SlideDown>
					<div className='row sdms-mb-15 sdms-row-align-items'>
						<div className='col-auto'>
							<FormSectionMoreLink
								text='Address & More'
								isOpen={showMore}
								onClick={() => setShowMore(!showMore)}
							/>
						</div>
						<div className='col sdms-flex-end sdms-flex'>
							{display.edit && (
								<Button
									design='default'
									icon='Edit'
									size='sm'
									text='Edit'
									onClick={() => setIsEditing(true)}
									disabled={isSubmitting}
									isSubmitting={isSubmitting}
								/>
							)}
							{display.cancel && (
								<Button
									className='sdms-mr-10'
									design='default'
									icon='Error-circle'
									size='sm'
									text='Cancel'
									onClick={onCancel}
									disabled={isSubmitting}
								/>
							)}
							{display.save && (
								<Button
									design='info'
									icon='Save'
									size='sm'
									text={isSubmitting ? 'Saving' : 'Save'}
									onClick={onSave}
									disabled={isSubmitting}
									isSubmitting={isSubmitting}
								/>
							)}
							{display.create && (
								<Button
									className='sdms-ml-10'
									design='default'
									icon='User'
									size='sm'
									text={isSubmitting ? 'Creating' : 'Create New Account'}
									onClick={onCreate}
									disabled={disabled || isSubmitting}
									isSubmitting={isSubmitting}
								/>
							)}
						</div>
					</div>
				</div>
			</FormSection>
			<CustomerModal
				isOpen={modal.open === modals.CUSTOMER}
				setSelectedCustomer={_customer => {
					onCustomerChange(_customer);
					closeModal();
				}}
				defaultCustomer={data.current.id ? data.current : null}
				onClose={closeModal}
				hasRegisterForm={false}
				paymentTerms={[]}
				countries={countries}
				defaultCountry={userContext.data.selectedOutlet.country}
				states={states}
				enableHouseAccount={userContext.hasPermission('enable_house_account')}
				saveCreditCards={userContext.hasPermission('save_credit_cards')}
				passwordSettings={userContext.data.user.company.systemSettings}
				filterIsActive
			/>
		</>
	);
};

Customer.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	sectionRef: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	disabled: PropTypes.bool,
	countries: PropTypes.arrayOf(PropTypes.object),
	states: PropTypes.arrayOf(PropTypes.object),
	onFormChange: PropTypes.func,
	onCustomerChange: PropTypes.func,
	setSectionIsValid: PropTypes.func,
};

Customer.defaultProps = {
	sectionRef: null,
	reservation: {},
	customer: {},
	disabled: false,
	countries: [],
	states: [],
	onFormChange: () => {},
	onCustomerChange: () => {},
	setSectionIsValid: () => {},
};

export default Customer;
