import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Builder, Query, Utils as QbUtils } from 'react-awesome-query-builder';

import UserContext from '../../../app/contexts/UserContext';
import apiCall, {
	filters,
	moduleRelatedEndpoints,
	outletRelatedEndpoints,
} from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	getQueryBuildConfig,
	hasReportOldField,
	reportCheckChildren,
} from '../../../utils/helpers/helper';

import Textarea from './Textarea';
import Alert from '../element/Alert';

const QueryBuilder = ({ fields, initTree, onChange }) => {
	const userContext = useContext(UserContext);

	const [listState, setListState] = useState({});

	const [queryBuilder, setQueryBuilder] = useState(null);

	const [isLoading, setIsLoading] = useState(true);

	const [warning, setWarning] = useState(false);

	const getLists = () => {
		const lists = [];

		Object.keys(fields).forEach(k => {
			if (fields[k].source && lists.indexOf(fields[k].source) === -1)
				lists.push(fields[k].source);
		});

		return lists;
	};

	const renderQueryBuilder = () => {
		if (Object.keys(fields).length) {
			const config = {
				...getQueryBuildConfig(),
				fields: JSON.parse(JSON.stringify(fields)),
			};

			Object.keys(config.fields).forEach(k => {
				if (config.fields[k].source && listState[config.fields[k].source])
					config.fields[k].listValues = listState[config.fields[k].source].map(d => {
						return {
							value: d[config.fields[k].key],
							title: d[config.fields[k].key],
						};
					});
			});

			const hasOldFields = hasReportOldField(initTree, fields);

			setWarning(hasOldFields);

			let _tree = reportCheckChildren(initTree);

			if (hasOldFields || !_tree) _tree = { id: QbUtils.uuid(), type: 'group' };

			setQueryBuilder({
				tree: QbUtils.checkTree(QbUtils.loadTree(_tree), config),
				config,
			});
		}
	};

	useEffect(() => {
		if (Object.keys(fields).length) {
			const lists = getLists();

			if (lists.length === 0) {
				setIsLoading(false);
			} else {
				lists
					.filter(n => !listState[n])
					.forEach(name => {
						const _filters = { pagination: false };

						// if list is related outlet add filter.
						if (outletRelatedEndpoints.includes(name))
							_filters['outlet.id'] = userContext.data.selectedOutlet.id;

						if (module && moduleRelatedEndpoints.includes(name))
							_filters['module.value'] = module;

						if ((((filters[name] || {}).groups || {}).list || {}).read)
							_filters['groups[]'] = filters[name].groups.list.read;

						apiCall(
							'GET',
							name,
							res => {
								setListState(oldListState => ({
									...oldListState,
									[name]: res,
								}));
							},
							err => {
								addErrorNotification(err.toString());
							},
							'',
							null,
							_filters
						);
					});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fields, userContext.data.selectedOutlet]);

	useEffect(() => {
		if (Object.keys(fields).length && !isLoading) renderQueryBuilder();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		if (Object.keys(fields).length)
			setIsLoading(getLists().filter(l => !listState[l]).length > 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listState]);

	if (queryBuilder)
		return (
			<>
				{warning && (
					<div className='row sdms-pr10 sdms-mb-10'>
						<div className='col-12'>
							<Alert solid icon='Info-circle' design='danger' bold>
								Report has invalid field(s). Please rebuild query.
							</Alert>
						</div>
					</div>
				)}
				<Query
					{...queryBuilder.config}
					value={queryBuilder.tree}
					onChange={(immutableTree, configuration) => {
						setQueryBuilder({ tree: immutableTree, config: configuration });
						setWarning(false);
						onChange(JSON.stringify(QbUtils.getTree(immutableTree)));
					}}
					renderBuilder={props => (
						<div className='query-builder-container'>
							<div className='query-builder qb-lite'>
								<Builder {...props} />
							</div>
						</div>
					)}
				/>
			</>
		);

	return <Textarea onChange={() => {}} className='is-loading' />;
};
QueryBuilder.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	fields: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initTree: PropTypes.object,
	onChange: PropTypes.func.isRequired,
};
QueryBuilder.defaultProps = {
	initTree: null,
};

export default QueryBuilder;
