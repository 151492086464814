import React, { useState, useEffect, useRef } from 'react';
import update from 'immutability-helper';
import ModifierForm from '../../components/modules/pos/ModifierForm';
import useField from './useField';
import { required } from '../helpers/validation';

const useModifierGroup = ({
	data,
	setTitle,
	setIsValid,
	onFormChange,
	isSubmitted,
	modifierPrefixes,
	prepStations,
	isDisabled,
}) => {
	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [modifiers, setModifiers] = useState([]);
	const [assignedModifierPrefixes, setAssignedModifierPrefixes] = useState([]);
	const [newModifierCount, setNewModifierCount] = useState(0);
	const newOverrideCount = useRef(0);
	const [invalidModifiers, setInvalidModifiers] = useState([]);
	const [selectPrefixOpen, setSelectPrefixOpen] = useState(false);

	useEffect(() => {
		if (data.modifiers) {
			const _modifiers = data.modifiers.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));

			_modifiers.forEach((modifier, index) => {
				data.modifierPrefixes.forEach(modifierPrefix => {
					const or = modifier.overrides.findIndex(
						override => override.modifierPrefix.id === modifierPrefix.id
					);
					if (or === -1) {
						newOverrideCount.current += 1;
						const newOverride = {
							id: -newOverrideCount.current,
							modifierPrefix,
							disabled: false,
							price: null,
						};
						modifier.overrides.push(newOverride);
					}
				});
				modifier.sortOrder = index;
			});
			setModifiers(_modifiers);
			setAssignedModifierPrefixes(
				data.modifierPrefixes.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
			);
		}
	}, [data]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && invalidModifiers.length === 0);
	}, [nameValRes.isValid, invalidModifiers, setIsValid]);

	useEffect(() => {
		if (isSubmitted) setNameShowVal();
	}, [isSubmitted, setNameShowVal]);

	const updateData = () => {
		data.modifiers = modifiers.map(m => {
			return {
				...m,
				overrides: m.overrides.filter(o => (o.price && o.price !== m.price) || o.disabled),
			};
		});
	};
	const addModifier = () => {
		const nmCount = newModifierCount + 1;
		const newOverride = modifierPrefix => {
			newOverrideCount.current += 1;
			return {
				id: -newOverrideCount.current,
				modifierPrefix,
				disabled: false,
				price: null,
			};
		};
		const modifier = {
			id: -nmCount,
			name: '',
			price: 0,
			prefixRequired: false,
			sortOrder: modifiers.length,
			prepStations: [],
			overrides: modifierPrefixes.map(modifierPrefix => newOverride(modifierPrefix)),
		};
		const newModifiers = update(modifiers, {
			$push: [modifier],
		});

		onFormChange();
		setModifiers(newModifiers);
		setNewModifierCount(nmCount + 1);
	};
	const deleteModifier = modifierId => {
		onFormChange();

		const _modifiers = update(modifiers, {
			$splice: [[modifiers.findIndex(item => item.id === modifierId), 1]],
		});

		setModifiers(
			_modifiers.map((modifier, index) => {
				modifier.sortOrder = index;
				return modifier;
			})
		);
		setInvalidModifiers(
			update(invalidModifiers, {
				$splice: [[invalidModifiers.indexOf(data.id), 1]],
			})
		);
	};
	const switchModifiers = (dragSortOrder, hoverSortOrder) => {
		onFormChange();
		setModifiers(
			update(modifiers, {
				[dragSortOrder]: {
					sortOrder: { $set: hoverSortOrder },
				},
				[hoverSortOrder]: {
					sortOrder: { $set: dragSortOrder },
				},
			}).sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
		);
	};

	// Draggable Item
	const renderModifier = modifier => {
		return (
			<ModifierForm
				key={modifier.id}
				index={modifiers.indexOf(modifier)}
				data={modifier}
				prepStations={prepStations}
				assignedModifierPrefixes={assignedModifierPrefixes.map(prefix => prefix.id)}
				deleteModifier={deleteModifier}
				moveModifier={switchModifiers}
				updateInvalidModifiers={updateInvalidModifiers}
				isSubmitted={isSubmitted}
				onFormChange={onFormChange}
				updateData={updateData}
				isDisabled={isDisabled}
			/>
		);
	};
	const updateAssignedPrefixes = selectedPrefixes => {
		onFormChange();

		modifiers.forEach(modifier => {
			selectedPrefixes.forEach(modifierPrefix => {
				const or = modifier.overrides.findIndex(
					override => override.modifierPrefix.id === modifierPrefix.id
				);
				if (or === -1) {
					newOverrideCount.current += 1;
					const newOverride = {
						id: -newOverrideCount.current,
						modifierPrefix,
						disabled: false,
						price: undefined,
					};
					modifier.overrides.push(newOverride);
				}
			});
		});

		setModifiers(modifiers.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1)));

		setAssignedModifierPrefixes(
			selectedPrefixes.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
		);

		data.modifierPrefixes = selectedPrefixes;
	};
	const updateInvalidModifiers = (action, item) => {
		const ind = invalidModifiers.indexOf(item);
		if (action === 'add' && ind === -1)
			setInvalidModifiers(update(invalidModifiers, { $push: [item] }));
		else if (action === 'remove' && ind > -1)
			setInvalidModifiers(
				update(invalidModifiers, {
					$splice: [[ind, 1]],
				})
			);
	};

	useEffect(updateData, [modifiers]);

	return [
		name,
		nameOnChange,
		nameValRes,
		nameShowVal,
		setNameShowVal,
		assignedModifierPrefixes,
		selectPrefixOpen,
		setSelectPrefixOpen,
		addModifier,
		modifiers,
		renderModifier,
		updateAssignedPrefixes,
		invalidModifiers,
	];
};

export default useModifierGroup;
