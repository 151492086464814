import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { getItemQuantity } from '../../../../../utils/helpers/reusable';
import { dateView } from '../../../../../utils/helpers/helper';
import { calculateItemTotals } from '../../../../../utils/helpers/reservationHelper';
import { bookingPeriods, reservationStatuses } from '../../../../../utils/constants/constants';

import Separator from '../../../layout/Separator';
import Badge from '../../../element/Badge';
import ReservationStatusCell, { reservationColor } from '../../../element/ReservationStatusCell';
import Button from '../../../element/Button';
import Dropdown from '../../../element/Dropdown';
import ItemImage from '../../../element/reservation_form/elements/ItemImage';
import Textarea from '../../../field/Textarea';
import CardPricingDetails from '../elements/CardPricingDetails';
import IncidentCharges from '../elements/IncidentCharges';
import StatusButton from '../elements/StatusButton';
import ReservationStatusDropdown from '../../../element/ReservationStatusDropdown';
import UnitButton from '../elements/UnitButton';

const Item = ({
	reservationItem,
	reservation,
	taxRate,
	isSubmitting,
	disabled,
	onEdit,
	onView,
	onItemUpdateStatus,
	onCheckIn,
	onCheckOut,
	onCancel,
	onItemUpdateCustomStatus,
	onIncidentalChargeAdd,
	onIncidentalChargeView,
	onUnitChange,
}) => {
	const totals = useMemo(
		() =>
			calculateItemTotals(
				reservationItem,
				reservationItem.subtotal,
				reservationItem.extraCharges,
				taxRate,
				reservationItem.policy,
				null,
				reservationItem.ratePlan,
				reservationItem.depositAmount,
				reservationItem.fromDate,
				reservation
			),
		[reservationItem, reservation, taxRate]
	);

	return (
		<div className='sdms-item-card col-xlg-6 col-12'>
			<div
				className={`sdms-item-card-inner sdms-item-card-inner--${reservationColor(
					reservationItem.status.value
				)}`}>
				<div className='row sdms-item-card-header'>
					<div className='col'>
						<div className='row'>
							<span className='sdms-item-card-title sdms-font-bold sdms-font-size-1-rem sdms-text-overflow'>
								{reservationItem.product.name}
							</span>
							<div className='sdms-vertical-separator' />
							{!(
								reservationItem?.product?.bookingPeriod?.value ===
									bookingPeriods.SEASONAL ||
								reservationItem?.product?.bookingPeriod?.value ===
									bookingPeriods.LONG_TERM
							) && (
								<Badge design='info' isInline isUnified>
									{getItemQuantity(reservationItem)}
								</Badge>
							)}
							<ReservationStatusCell data={reservationItem} />
						</div>
					</div>
					<div className='col-auto'>
						<div className='row'>
							<Button
								icon='Git#4'
								label='info'
								size='sm'
								text='View'
								onClick={() => onView(reservationItem)}
							/>
							<Button
								className='sdms-ml-10'
								label='info'
								icon='Edit'
								size='sm'
								text='Edit'
								onClick={() => onEdit(reservationItem)}
								disabled={disabled}
							/>
							<Dropdown
								className='sdms-ml-10'
								icon='Other#1'
								size='sm'
								label='info'
								inline
								aligned='right'
								arrow={false}
								outline={false}
								disabled={isSubmitting || disabled}>
								<ReservationStatusDropdown
									status={reservationItem.status}
									isRefundable
									onConfirm={() =>
										onItemUpdateStatus(
											[reservationItem.id],
											reservationStatuses.CONFIRMED
										)
									}
									onReserve={() =>
										onItemUpdateStatus(
											[reservationItem.id],
											reservationStatuses.RESERVED
										)
									}
									onWaitList={() =>
										onItemUpdateStatus(
											[reservationItem.id],
											reservationStatuses.WAITLIST
										)
									}
									onCheckIn={() => onCheckIn([reservationItem.id])}
									onCheckOut={() => onCheckOut(reservationItem)}
									onCancel={() => onCancel([reservationItem.id])}
									onItemUpdateCustomStatus={statusId =>
										onItemUpdateCustomStatus([reservationItem.id], statusId)
									}
									customReservationStatus={
										reservationItem.customReservationStatus
									}
									productCustomReservationStatuses={
										reservationItem.product.productCustomReservationStatuses
									}
								/>
							</Dropdown>
						</div>
					</div>
				</div>
				<Separator />
				<div className='row'>
					<div className='col-5'>
						<div className='sdms-mb-20'>
							<ItemImage
								image={
									(reservationItem?.product?.productImages || []).length > 0
										? reservationItem.product.productImages[0].path
										: null
								}
							/>
						</div>
						<div>
							<Textarea
								textRow={5}
								onChange={() => {}}
								value={reservationItem.note || ''}
								placeholder='No Special Request'
								disabled
							/>
						</div>
					</div>
					<div className='col-7 sdms-pr0 sdms-pl0'>
						<div className='row h-100'>
							<div className='col-12'>
								<div className='sdms-mb-10'>
									<CardPricingDetails
										date={dateView(
											reservationItem.fromDate,
											reservationItem.product.bookingPeriod.value ===
												bookingPeriods.LONG_TERM &&
												!reservationItem.hasCustomToDate
												? undefined
												: reservationItem.toDate,
											reservationItem.product.bookingPeriod.value ===
												bookingPeriods.HOURLY,
											reservationItem.product.bookingPeriod.value !==
												bookingPeriods.HOURLY,
											'-',
											true
										)}
										subtotal={reservationItem.subtotal}
										extras={totals.extraSubtotal}
										tax={totals.tax + totals.extraTax}
										total={totals.total + totals.extraTotal}
									/>
								</div>
							</div>
							<div className='col-12' style={{ marginTop: 'auto' }}>
								<div className='sdms-mb-10'>
									<IncidentCharges
										reservation={reservation}
										reservationItem={reservationItem}
										onAdd={onIncidentalChargeAdd}
										onView={onIncidentalChargeView}
										disabled={disabled}
									/>
								</div>
								<div className='sdms-mb-10'>
									<div className='row'>
										<div className='col sdms-paddingless'>
											<UnitButton
												reservation={reservation}
												reservationItem={reservationItem}
												disabled={disabled}
												onUnitChange={onUnitChange}
											/>
										</div>
										<div className='col-auto sdms-paddingless'>
											<StatusButton
												reservationItem={reservationItem}
												onConfirm={() =>
													onItemUpdateStatus(
														[reservationItem.id],
														reservationStatuses.CONFIRMED
													)
												}
												onCheckIn={() => onCheckIn([reservationItem.id])}
												onCheckOut={() => onCheckOut(reservationItem)}
												onItemUpdateCustomStatus={statusId =>
													onItemUpdateCustomStatus(
														[reservationItem.id],
														statusId
													)
												}
												disabled={isSubmitting}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Item.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	taxRate: PropTypes.object,
	isSubmitting: PropTypes.bool,
	disabled: PropTypes.bool,
	onEdit: PropTypes.func,
	onView: PropTypes.func,
	onItemUpdateStatus: PropTypes.func,
	onCheckIn: PropTypes.func,
	onCheckOut: PropTypes.func,
	onCancel: PropTypes.func,
	onItemUpdateCustomStatus: PropTypes.func,
	onIncidentalChargeAdd: PropTypes.func,
	onIncidentalChargeView: PropTypes.func,
	onUnitChange: PropTypes.func,
};

Item.defaultProps = {
	reservationItem: null,
	reservation: null,
	taxRate: null,
	isSubmitting: false,
	disabled: false,
	onEdit: () => {},
	onView: () => {},
	onItemUpdateStatus: () => {},
	onCheckIn: () => {},
	onCheckOut: () => {},
	onCancel: () => {},
	onItemUpdateCustomStatus: () => {},
	onIncidentalChargeAdd: () => {},
	onIncidentalChargeView: () => {},
	onUnitChange: () => {},
};

export default Item;
