import React from 'react';
import PropTypes from 'prop-types';

import { nonTransientAdvancedFormPages } from '../../../pages';
import { nonTransientAdvancedFormSteps } from '../../../../utils/constants/constants';

import FormContainer from '../../template/FormContainer';
import SpaceAssignmentForm from './SpaceAssignmentForm';

const SpaceAssignmentEdit = ({ id, afterSubmit, onCancel, presetData }) => {
	return (
		<FormContainer
			dataId={id.toString()}
			pageTitle={nonTransientAdvancedFormSteps.SPACE_ASSIGNMENT}
			icon={
				nonTransientAdvancedFormPages.find(
					s => s.title === nonTransientAdvancedFormSteps.SPACE_ASSIGNMENT
				).icon
			}
			dataName='spaceAssignments'
			lists={['enumSpaceAssignmentTypes', 'marinaUnits', 'campgroundUnits']}
			fields={<SpaceAssignmentForm presetData={presetData} />}
			isQuickPanel
			afterSubmit={afterSubmit}
			onCancel={onCancel}
			withHeader
			presetData={presetData}
		/>
	);
};
SpaceAssignmentEdit.propTypes = {
	id: PropTypes.number.isRequired,
	afterSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	presetData: PropTypes.object,
};
SpaceAssignmentEdit.defaultProps = {
	presetData: null,
};

export default SpaceAssignmentEdit;
