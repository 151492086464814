export const pageVariants = {
	initial: {
		opacity: 0.3,
		// transition: { duration: 1 },
	},
	in: {
		opacity: 1,
		// transition: { duration: 1 },
	},
	out: {
		opacity: 0.3,
		// transition: { duration: 1 },
	},
};

export const defaultVariants = {
	initial: {
		opacity: 1,
	},
	in: {
		opacity: 1,
	},
	out: {
		opacity: 1,
	},
};

export const pageTransition = {
	// type: 'tween',
	ease: 'easeInOut',
	duration: 0.3,
};
