import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../../../app/contexts/UserContext';
import useDate from '../../../../../utils/hooks/useDate';
import useModal from '../../../../../utils/hooks/useModal';
import { getItemQuantity } from '../../../../../utils/helpers/reusable';
import {
	dateView,
	getVesselVehicleInfo,
	inchToFeet,
	priceFormatter,
} from '../../../../../utils/helpers/helper';
import { calculateItemTotals } from '../../../../../utils/helpers/reservationHelper';
import { bookingPeriods, reservationStatuses } from '../../../../../utils/constants/constants';

import Separator from '../../../layout/Separator';
import Badge from '../../../element/Badge';
import ReservationStatusCell, { reservationColor } from '../../../element/ReservationStatusCell';
import Button from '../../../element/Button';
import Dropdown from '../../../element/Dropdown';
import ItemImage from '../../../element/reservation_form/elements/ItemImage';
import Textarea from '../../../field/Textarea';
import CardPricingDetails from '../elements/CardPricingDetails';
import IncidentCharges from '../elements/IncidentCharges';
import StatusButton from '../elements/StatusButton';
import ReservationStatusDropdown from '../../../element/ReservationStatusDropdown';
import CancellationReason from '../modals/CancellationReason';
import UnitButton from '../elements/UnitButton';

const modals = {
	CANCELLATION_REASON: 'cancellation_reason',
};

const Carousel = ({
	reservationItem,
	reservation,
	taxRate,
	isSubmitting,
	disabled,
	onEdit,
	onBack,
	onItemUpdateStatus,
	onCheckIn,
	onCheckOut,
	onCancel,
	onItemUpdateCustomStatus,
	onIncidentalChargeAdd,
	onIncidentalChargeView,
	onUnitChange,
	onDepartureDateSet,
}) => {
	const userContext = useContext(UserContext);

	const [dateFormatter] = useDate();

	const [modal, openModal, closeModal] = useModal();

	const totals = useMemo(
		() =>
			calculateItemTotals(
				reservationItem,
				reservationItem.subtotal,
				reservationItem.extraCharges,
				taxRate,
				reservationItem.policy,
				null,
				reservationItem.ratePlan,
				reservationItem.depositAmount,
				reservationItem.fromDate,
				reservation
			),
		[reservationItem, reservation, taxRate]
	);

	const vehicleInfo = useMemo(() => {
		const { vehicle } = reservationItem;

		if (vehicle)
			return `${vehicle.name} (${inchToFeet(vehicle.loa)} x ${inchToFeet(
				vehicle.beam
			)} x ${inchToFeet(vehicle.height)})`;

		return '';
	}, [reservationItem]);

	const vesselInfo = useMemo(() => {
		const { vessel } = reservationItem;

		return getVesselVehicleInfo(vessel);
	}, [reservationItem]);

	return (
		<>
			<div className='row sdms-row-align-items' style={{ justifyContent: 'center' }}>
				<div className='sdms-carousel-card col-xlg-9 col-12'>
					<div
						className={`sdms-carousel-card-inner sdms-carousel-card-inner--${reservationColor(
							reservationItem.status.value
						)}`}>
						<div className='row sdms-carousel-card-header'>
							<div className='col'>
								<div className='row'>
									<span className='sdms-item-carousel-title sdms-font-bold sdms-font-size-1-rem sdms-text-overflow'>
										{reservationItem.product.name}
									</span>
									<div className='sdms-vertical-separator' />
									{!(
										reservationItem?.product?.bookingPeriod?.value ===
											bookingPeriods.SEASONAL ||
										reservationItem?.product?.bookingPeriod?.value ===
											bookingPeriods.LONG_TERM
									) && (
										<Badge design='info' isInline isUnified>
											{getItemQuantity(reservationItem)}
										</Badge>
									)}
									<ReservationStatusCell data={reservationItem} />
								</div>
							</div>
							<div className='col-auto'>
								<div className='row'>
									{onBack && (
										<Button
											className='sdms-ml-10'
											design='default'
											icon='Subtract'
											size='sm'
											text='Back To Reservation'
											onClick={onBack}
										/>
									)}
									<Button
										className='sdms-ml-10'
										label='info'
										icon='Edit'
										size='sm'
										text='Edit'
										onClick={() => onEdit(reservationItem)}
										disabled={disabled}
									/>
									<Dropdown
										className='sdms-ml-10'
										icon='Other#1'
										size='sm'
										label='info'
										inline
										aligned='right'
										arrow={false}
										outline={false}
										disabled={isSubmitting || disabled}>
										<ReservationStatusDropdown
											reservationItem={reservationItem}
											status={reservationItem.status}
											isRefundable
											onConfirm={() =>
												onItemUpdateStatus(
													[reservationItem.id],
													reservationStatuses.CONFIRMED
												)
											}
											onReserve={() =>
												onItemUpdateStatus(
													[reservationItem.id],
													reservationStatuses.RESERVED
												)
											}
											onWaitList={() =>
												onItemUpdateStatus(
													[reservationItem.id],
													reservationStatuses.WAITLIST
												)
											}
											onCheckIn={() => onCheckIn([reservationItem.id])}
											onCheckOut={() => onCheckOut(reservationItem)}
											onCancel={() => onCancel([reservationItem.id])}
											onItemUpdateCustomStatus={statusId =>
												onItemUpdateCustomStatus(
													[reservationItem.id],
													statusId
												)
											}
											customReservationStatus={
												reservationItem.customReservationStatus
											}
											productCustomReservationStatuses={
												reservationItem.product
													.productCustomReservationStatuses
											}
											onDepartureDateSet={() =>
												onDepartureDateSet(reservationItem)
											}
										/>
									</Dropdown>
								</div>
							</div>
						</div>
						<Separator />
						<div className='row'>
							<div className='col-5'>
								<div className='sdms-mb-10'>
									<ItemImage
										image={
											(reservationItem?.product?.productImages || []).length >
											0
												? reservationItem.product.productImages[0].path
												: null
										}
									/>
								</div>
								<div className='sdms-mb-10 sdms-align-center sdms-carousel-card-description'>
									{reservationItem.product.description}
								</div>
								<div className='sdms-mb-10 sdms-align-left sdms-font-bold sdms-carousel-card-vessel-vehicle-info'>
									{vesselInfo && (
										<>
											<span className='sdms-font-boldest sdms-text-overflow'>
												Vessel:{' '}
											</span>{' '}
											{vesselInfo}
										</>
									)}
									{vehicleInfo && (
										<>
											<span className='sdms-font-boldest sdms-text-overflow'>
												Vehicle:{' '}
											</span>{' '}
											{vehicleInfo}
										</>
									)}
								</div>
								<div className='sdms-mb-10'>
									<Textarea
										textRow={5}
										onChange={() => {}}
										value={reservationItem.internalNote || ''}
										placeholder='No Internal Notes'
										disabled
									/>
								</div>
								<div>
									<Textarea
										textRow={5}
										onChange={() => {}}
										value={reservationItem.note || ''}
										placeholder='No Special Request'
										disabled
									/>
								</div>
							</div>
							<div className='col-7 sdms-pr0'>
								<div className='row h-100'>
									<div className='col-12'>
										<div className='sdms-mb-10'>
											<CardPricingDetails
												date={dateView(
													reservationItem.fromDate,
													reservationItem.product.bookingPeriod.value ===
														bookingPeriods.LONG_TERM &&
														!reservationItem.hasCustomToDate
														? undefined
														: reservationItem.toDate,
													reservationItem.product.bookingPeriod.value ===
														bookingPeriods.HOURLY,
													reservationItem.product.bookingPeriod.value !==
														bookingPeriods.HOURLY,
													'-',
													true
												)}
												subtotal={reservationItem.subtotal}
												extras={totals.extraSubtotal}
												tax={totals.tax + totals.extraTax}
												total={totals.total + totals.extraTotal}
											/>
										</div>
										<div className='sdms-mb-10 sdms-mt-20 sdms-align-left sdms-font-bold'>
											<span className='sdms-font-boldest'>Rate Plan:</span>{' '}
											{reservationItem?.ratePlan?.internalName || '#'}
										</div>
										<div className='sdms-mb-10 sdms-align-left sdms-font-bold'>
											<span className='sdms-font-boldest'>
												Cancellation Policy:
											</span>{' '}
											{reservationItem?.policy?.name || '#'}
										</div>
										<div className='sdms-mb-10 sdms-align-left sdms-font-bold'>
											<span className='sdms-font-boldest'>
												Deposit Required:
											</span>{' '}
											{priceFormatter(reservationItem?.depositAmount || 0)}
										</div>
										<div className='sdms-mb-10 sdms-align-left sdms-font-bold'>
											<span className='sdms-font-boldest'>Contracts:</span>{' '}
											{reservationItem?.statusContracts?.length
												? reservationItem.statusContracts.join(',')
												: '#'}
										</div>
										<div className='sdms-mb-10 sdms-align-left sdms-font-bold'>
											<span className='sdms-font-boldest'>
												Deferred Income:
											</span>{' '}
											{reservationItem?.enableDeferredIncome ? (
												<Badge.Yes />
											) : (
												<Badge.No />
											)}
										</div>
										<div className='sdms-mb-10 sdms-align-left sdms-font-bold'>
											<span className='sdms-font-boldest'>Taxable:</span>{' '}
											{reservationItem?.taxCode?.taxable ? (
												<Badge.Yes />
											) : (
												<Badge.No />
											)}
										</div>
										{reservationItem?.owner && (
											<div className='sdms-mb-10 sdms-align-left sdms-font-bold'>
												<span className='sdms-font-boldest'>Owner:</span>{' '}
												{reservationItem.owner?.displayName}
											</div>
										)}
										{reservationItem?.status?.value ===
											reservationStatuses.CANCELLED && (
											<>
												<div className='sdms-mb-10 sdms-align-left sdms-font-bold'>
													<span className='sdms-font-boldest'>
														Cancellation Date:
													</span>{' '}
													{dateFormatter(
														reservationItem.cancellationTime,
														false,
														'MMM, D YYYY h:mm a',
														userContext.data.selectedOutlet.timezone
															.value
													)}
												</div>
												{reservationItem.cancellationReason && (
													<div
														className='sdms-mb-10 sdms-align-left sdms-font-bold sdms-text-overflow sdms-cursor--pointer'
														role='presentation'
														onClick={() =>
															openModal({
																open: modals.CANCELLATION_REASON,
															})
														}>
														<span className='sdms-font-boldest'>
															Cancellation Reason:
														</span>{' '}
														{reservationItem.cancellationReason}
													</div>
												)}
											</>
										)}
										{reservationItem?.nextInvoiceDate && (
											<div className='sdms-mb-10 sdms-align-left sdms-font-bold'>
												<span className='sdms-font-boldest'>
													Next Invoice Date:
												</span>{' '}
												{dateFormatter(
													reservationItem.nextInvoiceDate,
													false,
													'MMM, D YYYY',
													'UTC'
												)}
											</div>
										)}
									</div>
									<div className='col-12' style={{ marginTop: 'auto' }}>
										<div className='sdms-mb-20 sdms-mt-10'>
											<IncidentCharges
												reservation={reservation}
												reservationItem={reservationItem}
												onAdd={onIncidentalChargeAdd}
												onView={onIncidentalChargeView}
												disabled={disabled}
											/>
										</div>
										<div className='row'>
											<div className='col sdms-paddingless sdms-align-left'>
												<UnitButton
													reservation={reservation}
													reservationItem={reservationItem}
													disabled={disabled}
													onUnitChange={onUnitChange}
												/>
											</div>
											<div className='col-auto sdms-paddingless'>
												<StatusButton
													reservationItem={reservationItem}
													onConfirm={() =>
														onItemUpdateStatus(
															[reservationItem.id],
															reservationStatuses.CONFIRMED
														)
													}
													onCheckIn={() =>
														onCheckIn([reservationItem.id])
													}
													onCheckOut={() => onCheckOut(reservationItem)}
													onItemUpdateCustomStatus={statusId =>
														onItemUpdateCustomStatus(
															[reservationItem.id],
															statusId
														)
													}
													disabled={isSubmitting}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<CancellationReason
				open={modal.open === modals.CANCELLATION_REASON}
				cancellationReason={reservationItem?.cancellationReason}
				onClose={closeModal}
			/>
		</>
	);
};

Carousel.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	taxRate: PropTypes.object,
	isSubmitting: PropTypes.bool,
	disabled: PropTypes.bool,
	onEdit: PropTypes.func,
	onBack: PropTypes.func,
	onItemUpdateStatus: PropTypes.func,
	onCheckIn: PropTypes.func,
	onCheckOut: PropTypes.func,
	onCancel: PropTypes.func,
	onItemUpdateCustomStatus: PropTypes.func,
	onIncidentalChargeAdd: PropTypes.func,
	onIncidentalChargeView: PropTypes.func,
	onUnitChange: PropTypes.func,
	onDepartureDateSet: PropTypes.func,
};

Carousel.defaultProps = {
	reservationItem: null,
	reservation: null,
	taxRate: null,
	isSubmitting: false,
	disabled: false,
	onEdit: () => {},
	onBack: () => {},
	onItemUpdateStatus: () => {},
	onCheckIn: () => {},
	onCheckOut: () => {},
	onCancel: () => {},
	onItemUpdateCustomStatus: () => {},
	onIncidentalChargeAdd: () => {},
	onIncidentalChargeView: () => {},
	onUnitChange: () => {},
	onDepartureDateSet: () => {},
};

export default Carousel;
