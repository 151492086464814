import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import useField from '../../../utils/hooks/useField';
import usePages from '../../../utils/hooks/usePages';
import { required, min } from '../../../utils/helpers/validation';
import { modules } from '../../../utils/helpers/apiCall';
import { numberParser } from '../../../utils/helpers/helper';
import { templateTypes } from '../../../utils/constants/constants';

import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Loading from '../../reusables/template/Loading';
import Toggle from '../../reusables/field/Toggle';
import Selects from '../../reusables/field/Selects';
import Section from '../../reusables/layout/Section';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import SVGIcon from '../../reusables/element/SVGIcon';

const BookingTypeForm = ({
	data,
	isLoading,
	setTitle,
	onFormChange,
	isSubmitted,
	setIsValid,
	enumBookBies,
	enumSearchTypes,
	submitButtonAttr,
	submit,
	unitMaps,
	productFilters,
	enumSearchResultViews,
	templates,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const campgroundModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.CAMPGROUND)
	);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [nightly, nightlyOncChange] = useField(data, 'nightly', onFormChange, [], false);

	const [daily, dailyOnChange] = useField(data, 'daily', onFormChange, [], false);

	const [hourly, hourlyOnChange] = useField(data, 'hourly', onFormChange, [], false);

	const [seasonal, seasonalOnChange] = useField(data, 'seasonal', onFormChange, [], false);

	const [longTerm, longTermOnChange] = useField(data, 'longTerm', onFormChange, [], false);

	const [bookBy, bookByOnChange, bookByValRes, bookByShowVal, setBookByShowVal] = useField(
		data,
		'bookBy',
		onFormChange,
		[required],
		null
	);

	const [inactive, inactiveOnChange] = useField(data, 'inactive', onFormChange, [], false);

	const [showOnline, showOnlineOnChange] = useField(data, 'showOnline', onFormChange, [], false);

	const [showFlexibleDates, showFlexibleDatesOnChange] = useField(
		data,
		'showFlexibleDates',
		onFormChange,
		[],
		false
	);

	const [unitMap, unitMapOnChange] = useField(data, 'unitMap', onFormChange, [], null);

	const [selectedProductFilters, selectedProductFiltersOnChange] = useField(
		data,
		'productFilters',
		onFormChange,
		[],
		[]
	);

	const [defaultSearchResultView, defaultSearchResultViewOnChange] = useField(
		data,
		'defaultSearchResultView',
		onFormChange,
		[],
		null
	);

	const [
		searchOffsetFrom,
		searchOffsetFromOnChange,
		searchOffsetFromValRes,
		searchOffsetFromShowVal,
		setSearchOffsetFromShowVal,
	] = useField(data, 'searchOffsetFrom', onFormChange, [min(0)], null, numberParser(false));

	const [
		searchOffsetDuration,
		searchOffsetDurationOnChange,
		searchOffsetDurationValRes,
		searchOffsetDurationShowVal,
		setSearchOffsetDurationShowVal,
	] = useField(data, 'searchOffsetDuration', onFormChange, [min(0)], null, numberParser(false));

	const [
		searchLoa,
		searchLoaOnChange,
		searchLoaValRes,
		searchLoaShowVal,
		setSearchLoaShowVal,
	] = useField(data, 'searchLoa', onFormChange, [required], null);

	const [
		searchBeam,
		searchBeamOnChange,
		searchBeamValRes,
		searchBeamShowVal,
		setSearchBeamShowVal,
	] = useField(data, 'searchBeam', onFormChange, [required], null);

	const [
		searchHeight,
		searchHeightOnChange,
		searchHeightValRes,
		searchHeightShowVal,
		setSearchHeightShowVal,
	] = useField(data, 'searchHeight', onFormChange, [required]);

	const [showDetailedSearch, showDetailedSearchOnChange] = useField(
		data,
		'showDetailedSearch',
		onFormChange,
		[],
		false
	);

	const [onlineBookingTemplate, onlineBookingTemplateOnChange] = useField(
		data,
		'onlineBookingTemplate',
		onFormChange,
		[],
		null
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setBookByShowVal();
			setSearchOffsetFromShowVal();
			setSearchOffsetDurationShowVal();
			setSearchLoaShowVal();
			setSearchBeamShowVal();
			setSearchHeightShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setSearchOffsetFromShowVal,
		setSearchOffsetDurationShowVal,
		setSearchLoaShowVal,
		setSearchBeamShowVal,
		setSearchHeightShowVal,
		setBookByShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				bookByValRes.isValid &&
				searchOffsetFromValRes.isValid &&
				searchOffsetDurationValRes.isValid &&
				searchLoaValRes.isValid &&
				searchBeamValRes.isValid &&
				searchHeightValRes.isValid
		);
	}, [
		nameValRes.isValid,
		bookByValRes.isValid,
		searchOffsetFromValRes.isValid,
		searchOffsetDurationValRes.isValid,
		searchLoaValRes.isValid,
		searchBeamValRes.isValid,
		searchHeightValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		if (!isLoading) data.module = campgroundModule.current;
	}, [data.module, isLoading]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.campground.default.text,
				path: pages.campground.dashboard.path,
			},
			{
				title: pages.campground.settings.text,
				path: pages.campground.settings.path,
			},
			{
				title: pages.campground.settings.bookingType.text,
				path: pages.campground.settings.bookingType.path,
			},
			{ title: name || `New ${pages.campground.settings.bookingType.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.campground.settings.bookingType.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup row>
						<div className='col-md-8'>
							<Section title='General'>
								<Section.Body className='row'>
									<Loading isLoading={isLoading}>
										<FormField
											name='name'
											label='Name'
											id={data.id}
											valRes={nameValRes}
											showValidation={nameShowVal}
											col={12}>
											<Input
												type='text'
												placeholder='Name (Required)'
												value={name}
												onChange={nameOnChange}
												onBlur={setNameShowVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='type'
											label='Book By'
											valRes={bookByValRes}
											showValidation={bookByShowVal}
											id={data.id}
											colMd={4}>
											<Selects
												options={enumBookBies}
												placeholder='Book By (Required)'
												value={bookBy}
												onChange={bookByOnChange}
												onBlur={setBookByShowVal}
												displayKey='value'
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='inactive'
											label='Inactive'
											id={data.id}
											colMd={2}>
											<Toggle onChange={inactiveOnChange} value={inactive} />
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='showOnline'
											label='Show Online'
											id={data.id}
											colMd={2}>
											<Toggle
												onChange={showOnlineOnChange}
												value={showOnline}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='showFlexibleDates'
											label='Show Flexible Dates'
											id={data.id}
											colMd={2}>
											<Toggle
												spaceLess
												value={showFlexibleDates}
												onChange={showFlexibleDatesOnChange}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='unitMap'
											label='Map'
											id={data.id}
											colMd={4}>
											<Selects
												options={unitMaps}
												placeholder='Map'
												value={unitMap}
												onChange={unitMapOnChange}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='productFilters'
											label='Product Filters'
											id={data.id}
											colMd={4}>
											<Selects
												options={productFilters}
												placeholder='Select Product Filter'
												value={selectedProductFilters}
												onChange={selectedProductFiltersOnChange}
												multiple
												displayKey='name'
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='defaultSearchResultView'
											label='Default View'
											id={data.id}
											colMd={4}>
											<Selects
												options={enumSearchResultViews}
												placeholder='Select a View'
												value={defaultSearchResultView}
												onChange={defaultSearchResultViewOnChange}
												displayKey='value'
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='onlineBookingTemplate'
											label='Online Booking Template'
											colMd={4}>
											<Selects
												options={templates.filter(
													t =>
														t.type.value ===
														templateTypes.ONLINE_BOOKING_TEMPLATE
												)}
												placeholder='Select a Template'
												value={onlineBookingTemplate}
												onChange={onlineBookingTemplateOnChange}
											/>
										</FormField>
									</Loading>
								</Section.Body>
							</Section>
						</div>
						<div className='col-md-4'>
							<Section title='Periods'>
								<Section.Body className='row'>
									<Loading isLoading={isLoading}>
										<FormField
											name='nightly'
											label={
												<>
													<SVGIcon name='Moon' className='sdms-mr-5' />
													Nightly
												</>
											}
											id={data.id}
											colMd={4}>
											<Toggle onChange={nightlyOncChange} value={nightly} />
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='daily'
											label={
												<>
													<SVGIcon name='Sun' className='sdms-mr-5' />
													Daily
												</>
											}
											id={data.id}
											colMd={4}>
											<Toggle onChange={dailyOnChange} value={daily} />
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='hourly'
											label={
												<>
													<SVGIcon name='Clock' className='sdms-mr-5' />
													Hourly
												</>
											}
											id={data.id}
											colMd={4}>
											<Toggle onChange={hourlyOnChange} value={hourly} />
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='seasonal'
											label={
												<>
													<SVGIcon
														name='Layout-4-blocks'
														className='sdms-mr-5'
													/>
													Seasonal
												</>
											}
											id={data.id}
											colMd={4}>
											<Toggle onChange={seasonalOnChange} value={seasonal} />
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='longTerm'
											label={
												<>
													<SVGIcon
														name='Clipboard-check'
														className='sdms-mr-5'
													/>
													Long Term
												</>
											}
											id={data.id}
											colMd={4}>
											<Toggle onChange={longTermOnChange} value={longTerm} />
										</FormField>
									</Loading>
								</Section.Body>
							</Section>
						</div>
						<div className='col-md-12'>
							<Section title='Search Fields'>
								<Section.Body className='row'>
									<Loading isLoading={isLoading}>
										<FormField
											name='searchOffsetFrom'
											label='Offset Days'
											id={data.id}
											colMd={6}
											valRes={searchOffsetFromValRes}
											showValidation={searchOffsetFromShowVal}>
											<Input
												type='number'
												onChange={searchOffsetFromOnChange}
												value={searchOffsetFrom}
												onBlur={setSearchOffsetFromShowVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='searchOffsetDuration'
											label='Offset Duration'
											id={data.id}
											colMd={6}
											valRes={searchOffsetDurationValRes}
											showValidation={searchOffsetDurationShowVal}>
											<Input
												type='number'
												onChange={searchOffsetDurationOnChange}
												value={searchOffsetDuration}
												onBlur={setSearchOffsetDurationShowVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='searchLoa'
											label='Length Overall'
											valRes={searchLoaValRes}
											showValidation={searchLoaShowVal}
											id={data.id}
											colMd={6}>
											<Selects
												options={enumSearchTypes}
												placeholder='Length Overall (Required)'
												value={searchLoa}
												onChange={searchLoaOnChange}
												onBlur={setSearchLoaShowVal}
												displayKey='value'
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='searchBeam'
											label='Beam'
											valRes={searchBeamValRes}
											showValidation={searchBeamShowVal}
											id={data.id}
											colMd={6}>
											<Selects
												options={enumSearchTypes}
												placeholder='Beam (Required)'
												value={searchBeam}
												onChange={searchBeamOnChange}
												onBlur={setSearchBeamShowVal}
												displayKey='value'
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='searchHeight'
											label='Height'
											valRes={searchHeightValRes}
											showValidation={searchHeightShowVal}
											id={data.id}
											colMd={6}>
											<Selects
												options={enumSearchTypes}
												placeholder='Height (Required)'
												value={searchHeight}
												onChange={searchHeightOnChange}
												onBlur={setSearchHeightShowVal}
												displayKey='value'
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='showDetailedSearch'
											label='Show Detailed Search'
											id={data.id}
											colMd={2}>
											<Toggle
												spaceLess
												value={showDetailedSearch}
												onChange={showDetailedSearchOnChange}
											/>
										</FormField>
									</Loading>
								</Section.Body>
							</Section>
						</div>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
BookingTypeForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		amount: PropTypes.number,
		type: PropTypes.object,
		module: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setIsValid: PropTypes.func,
	onFormChange: PropTypes.func,
	enumBookBies: PropTypes.arrayOf(PropTypes.object),
	enumSearchTypes: PropTypes.arrayOf(PropTypes.object),
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
	unitMaps: PropTypes.arrayOf(PropTypes.object),
	productFilters: PropTypes.arrayOf(PropTypes.object),
	enumSearchResultViews: PropTypes.arrayOf(PropTypes.object),
	templates: PropTypes.arrayOf(PropTypes.object),
};
BookingTypeForm.defaultProps = {
	data: {
		id: 0,
	},
	isLoading: false,
	onFormChange: () => {},
	enumBookBies: [],
	enumSearchTypes: [],
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
	unitMaps: [],
	productFilters: [],
	enumSearchResultViews: [],
	templates: [],
};

export default BookingTypeForm;
