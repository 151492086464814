import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import useField from '../../../utils/hooks/useField';
import {
	customerPaymentMethodOption,
	customerPaymentMethodOptionalShow,
} from '../../../utils/helpers/helper';
import { paymentTypes } from '../../../utils/constants/constants';

import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import Portal from '../layout/Portal';
import Selects from '../field/Selects';
import FormField from '../template/FormField';
import CustomerPaymentMethodForm from '../../modules/crm/CustomerPaymentMethodForm';

const CustomerPaymentMethodSelect = ({
	customer,
	selectedPaymentMethod,
	onSubmit,
	onClose,
	isOpen,
	paymentMethods,
	isSubmitting,
}) => {
	const [content, setContent] = useState('list');
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				{content === 'list' ? (
					<CustomerPaymentMethodSelectList
						customer={customer}
						selectedPaymentMethod={selectedPaymentMethod}
						onClose={onClose}
						onSubmit={onSubmit}
						onAddNew={type => setContent(type)}
						isSubmitting={isSubmitting}
					/>
				) : (
					<CustomerPaymentMethodForm
						type={content}
						data={{ id: 0 }}
						customer={customer}
						paymentMethods={paymentMethods}
						onAdd={onSubmit}
						onCancel={onClose}
						displayMakeDefault={false}
						icon={content === paymentTypes.CREDIT_CARD ? 'Credit-card' : 'Ticket'}
						title={content === paymentTypes.CREDIT_CARD ? 'New Credit Card' : 'New ACH'}
						onBack={() => setContent('list')}
					/>
				)}
			</Popup>
		</Portal>
	);
};
CustomerPaymentMethodSelect.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	selectedPaymentMethod: PropTypes.object,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	isOpen: PropTypes.bool,
	paymentMethods: PropTypes.arrayOf(PropTypes.object),
	isSubmitting: PropTypes.bool,
};
CustomerPaymentMethodSelect.defaultProps = {
	customer: null,
	selectedPaymentMethod: null,
	onSubmit: () => {},
	onClose: () => {},
	isOpen: false,
	paymentMethods: [],
	isSubmitting: false,
};

const CustomerPaymentMethodSelectList = ({
	customer,
	selectedPaymentMethod,
	onSubmit,
	onClose,
	onAddNew,
	isSubmitting,
}) => {
	const [paymentMethod, paymentMethodOnChange] = useField(
		{},
		'printer',
		() => {},
		[],
		selectedPaymentMethod
	);

	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Wallet#3'>
					Payment Method
				</Portlet.HeadLabelTitle>
				<Portlet.HeadToolbarActions>
					<Button
						design='brand'
						className='sdms-margin-r-15'
						text='New Credit Card'
						icon='Plus'
						size='sm'
						onClick={() => onAddNew(paymentTypes.CREDIT_CARD)}
					/>
					<Button
						design='brand'
						text=' New ACH '
						icon='Plus'
						size='sm'
						onClick={() => onAddNew(paymentTypes.CHECK)}
					/>
				</Portlet.HeadToolbarActions>
			</Portlet.Head>
			<Portlet.Body>
				<FormField name='paymentSchedule' id={0} inFormDesign={false}>
					<Selects
						options={customer.paymentMethods}
						placeholder='Select a Saved Payment Method'
						onChange={paymentMethodOnChange}
						value={paymentMethod}
						className='h-100'
						renderOption={customerPaymentMethodOption}
						optionalShow={customerPaymentMethodOptionalShow}
						displayKey='id'
					/>
				</FormField>
			</Portlet.Body>
			<Portlet.Foot
				tall='sm'
				className='sdms-align-left'
				subClassName='justify-content-between'>
				<div className='col-auto'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
						disabled={isSubmitting}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='brand'
						icon='Save'
						text={isSubmitting ? 'Saving' : 'Save'}
						size='sm'
						onClick={() => onSubmit(paymentMethod)}
						disabled={isSubmitting}
						isSubmitting={isSubmitting}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};

CustomerPaymentMethodSelectList.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	selectedPaymentMethod: PropTypes.object,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	onAddNew: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
};

CustomerPaymentMethodSelectList.defaultProps = {
	customer: null,
	selectedPaymentMethod: null,
};

export default CustomerPaymentMethodSelect;
