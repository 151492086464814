import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import ExtraChargePerUnitForm from './ExtraChargePerUnitForm';
import { modules } from '../../../utils/helpers/apiCall';

const ExtraChargePerUnitEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='bookingExtraCharges'
			lists={[
				'itemTypes',
				'bookingTypeMarinas',
				'enumBookingPeriods',
				'enumBookingCalculations',
				'enumExtraChargeTypes',
				'productCategoryExtraCharges',
				'taxCodes',
				'accounts',
				'unitMeasures',
				'taxRates',
				'paymentTerms',
				'invoiceFirstOptions',
				'invoiceNextOptions',
				'invoicingFrequencies',
				'taxAgencies',
			]}
			fields={<ExtraChargePerUnitForm />}
			pageTitle={pages.marina.settings.extraCharges.perUnit.text}
			icon={pages.marina.settings.extraCharges.perUnit.icon}
			listUrl={pages.marina.settings.extraCharges.path}
			module={modules.MARINA}
		/>
	);
};

ExtraChargePerUnitEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ExtraChargePerUnitEdit;
