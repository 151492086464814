import React, { useEffect } from 'react';
import SVGIcon from './reusables/element/SVGIcon';

const ScrollTop = () => {
	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	useEffect(() => {
		window.onscroll = () => {
			if (window.scrollY >= 10) {
				document.body.classList.add('sdms-scrolltop--on');
			} else {
				document.body.classList.remove('sdms-scrolltop--on');
			}
		};
	}, []);

	return (
		<div id='sdms_scrolltop' className='sdms-scrolltop' onClick={scrollTop} aria-hidden='true'>
			<SVGIcon name='Angle-up' />
		</div>
	);
};

export default ScrollTop;
