import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import apiCall from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	addSuccessNotification,
	convertDateToUTC,
} from '../../../utils/helpers/helper';
import UserContext from '../../../app/contexts/UserContext';
import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';

import Portlet from '../../reusables/layout/Portlet';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import DatePicker from '../../reusables/field/DatePicker';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import Toggle from '../../reusables/field/Toggle';
import Button from '../../reusables/element/Button';
import Selects from '../../reusables/field/Selects';

const RecognizeRevenue = ({ onCancel, afterSubmit }) => {
	const userContext = useContext(UserContext);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [invoices, setInvoices] = useState([]);

	const [isInvoicesLoading, setIsInvoicesLoading] = useState(false);

	const [date, dateOnChange, dateValRes, dateShowVal, setDateShowVal] = useField(
		{},
		'date',
		() => {},
		[required],
		moment()
			.utc(true)
			.hours(23)
			.minutes(59)
			.seconds(59)
			.milliseconds(999)
	);

	const [customer, customerOnChange] = useField({}, 'customers', () => {}, [], null);

	const [invoice, invoiceOnChange] = useField({}, 'invoice', () => {}, [], null);

	const [summarizeEntry, summarizeEntryOnChange] = useField(
		{},
		'summarizeEntry',
		() => {},
		[],
		false
	);

	const [overrideIncomeDate, overrideIncomeDateOnChange] = useField(
		{},
		'date',
		() => {},
		[],
		null
	);

	const onSubmit = () => {
		setDateShowVal();

		if (!dateValRes.isValid) return;

		setIsSubmitting(true);

		apiCall(
			'POST',
			'recognizeRevenue',
			res => {
				setIsSubmitting(false);
				addSuccessNotification(res.message);
				afterSubmit(true);
			},
			e => {
				setIsSubmitting(false);
				addErrorNotification(e.toString());
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				date: date.toISOString(),
				customer: customer ? customer.id : null,
				invoice: invoice ? invoice.id : null,
				summarizeEntry,
				overrideIncomeDate: overrideIncomeDate ? overrideIncomeDate.toISOString() : null,
			}
		);
	};

	useEffect(() => {
		if (customer) {
			setIsInvoicesLoading(true);
			apiCall(
				'GET',
				'invoices',
				res => {
					setInvoices(res);
					setIsInvoicesLoading(false);
				},
				err => {
					setInvoices([]);
					setIsInvoicesLoading(false);
					addErrorNotification(err.toString());
				},
				'',
				null,
				{ 'customer.id': customer.id, pagination: false }
			);
		} else setInvoices([]);
	}, [customer]);

	return (
		<Portlet
			fluid='fluid'
			className='sdms-bg-transparent sdms-portlet--unelevate sdms-portlet--portletception sdms-mb-0'>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Clipboard-list'>
					Recognize Revenue
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<FormGroup isLast>
					<FormField
						name='recognizeThrough'
						label='Recognize Through'
						id='recognizeThrough'
						colMd={12}
						showValidation={dateShowVal}
						valRes={dateValRes}>
						<DatePicker
							id='recognizeThrough'
							type='calendar'
							value={convertDateToUTC(date.toDate())}
							onChange={e =>
								dateOnChange({
									target: {
										value: moment(e.target.value)
											.utc(true)
											.endOf('day'),
									},
								})
							}
							onBlur={setDateShowVal}
							disableClear
						/>
					</FormField>
					<FormField name='customer' label='Customer' id='customer' colLg={12}>
						<AsyncSelect
							options={[]}
							placeholder='Search and select customer'
							value={customer}
							onChange={e => {
								invoiceOnChange({ target: { value: null } });
								setInvoices([]);
								customerOnChange(e);
							}}
							route='customers'
							field='displayName'
							displayKey='displayName'
							onBlur={() => {}}
						/>
					</FormField>
					<FormField name='invoice' label='Invoice' id='invoice' colLg={12}>
						<Selects
							options={invoices}
							placeholder='Select a invoice'
							value={invoice}
							onChange={invoiceOnChange}
							route='invoices'
							displayKey='invoiceId'
							disabled={customer === null || isInvoicesLoading}
						/>
					</FormField>
					<FormField
						name='summarizeEntry'
						label='Summarize Entry'
						id='summarizeEntry'
						colLg={12}>
						<Toggle onChange={summarizeEntryOnChange} value={summarizeEntry} />
					</FormField>
					<FormField
						name='overrideIncomeDate'
						label='Override Income Date'
						id='overrideIncomeDate'
						colMd={12}>
						<DatePicker
							id='overrideIncomeDate'
							type='calendar'
							value={
								overrideIncomeDate
									? convertDateToUTC(overrideIncomeDate.toDate())
									: null
							}
							onChange={e => {
								overrideIncomeDateOnChange({
									target: {
										value: e.target.value
											? moment(e.target.value)
													.utc(true)
													.startOf('day')
											: null,
									},
								});
							}}
							disabled={!userContext.hasPermission('override_deferred_income_date')}
							minDate={
								userContext.data.user.company.settings.accountingClosingDate
									? moment(
											userContext.data.user.company.settings
												.accountingClosingDate
									  ).toDate()
									: null
							}
						/>
					</FormField>
				</FormGroup>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						icon='Error-circle'
						size='sm'
						elevate
						text='Cancel'
						onClick={onCancel}
						disabled={isSubmitting}
					/>
				</div>
				<div className='col-auto'>
					<Button
						className={classNames(' sdms-mw-100', {
							'sdms-fading-dots': isSubmitting,
						})}
						label='info'
						icon={
							isSubmitting
								? process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON
								: 'Clipboard-list'
						}
						text={isSubmitting ? 'Recognizing' : 'Recognize'}
						size='sm'
						elevate
						onClick={onSubmit}
						disabled={isSubmitting}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};

RecognizeRevenue.propTypes = {
	onCancel: PropTypes.func,
	afterSubmit: PropTypes.func,
};

RecognizeRevenue.defaultProps = {
	onCancel: () => {},
	afterSubmit: () => {},
};

export default RecognizeRevenue;
