import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import useModal from '../../../../utils/hooks/useModal';
import usePrint from '../../../../utils/hooks/usePrint';
import useDate from '../../../../utils/hooks/useDate';
import { addErrorNotification, addSuccessNotification } from '../../../../utils/helpers/helper';
import apiCall from '../../../../utils/helpers/apiCall';

import Button from '../../element/Button';
import List from '../../template/List';
import Dropdown from '../../element/Dropdown';
import ListContainer from '../../template/ListContainer';
import Contract from './modals/Contract';

import '../../../../assets/reservationContractList.css';
import UserContext from '../../../../app/contexts/UserContext';
import { hasDeleteReservationItemStatusContractsPermission } from '../../../../utils/helpers/permission';

const DateCell = ({ data, field }) => {
	const [dateFormatter] = useDate();
	if (data[field]) return <span>{dateFormatter(new Date(data[field]), true, 'L', 'UTC')}</span>;
	return '';
};

const modals = {
	VIEW: 'view',
	NEW: 'new',
};
const ContractList = ({ reservation, reservationItem, module, isFluid, headerActions }) => {
	const userContext = useContext(UserContext);

	const [modal, openModal, closeModal] = useModal();

	const [onPrint, PRINT_COMPONENT] = usePrint();

	const [isLoading, setIsLoading] = useState(false);

	const getContracts = () => {
		const _contracts = [];

		if (reservationItem.product.productCustomReservationStatuses)
			_contracts.push(
				...reservationItem.product.productCustomReservationStatuses
					.filter(pcrs => pcrs.contract)
					.map(pcrs => pcrs.contract)
			);

		if (reservationItem.product.productContracts) {
			_contracts.push(
				...reservationItem.product.productContracts.filter(
					pc => !_contracts.some(_pc => _pc.id === pc.id)
				)
			);
		}

		if (reservationItem.productContracts)
			_contracts.push(
				...reservationItem.productContracts.filter(
					pc => !_contracts.some(_pc => _pc.id === pc.id)
				)
			);

		return _contracts;
	};

	const getContractTemplate = contract => {
		setIsLoading(true);

		apiCall(
			'POST',
			'getReservationItemStatusContractTemplate',
			response => {
				openModal({
					open: modals.NEW,
					contract: { ...contract, parsedTemplate: response },
				});
				setIsLoading(false);
			},
			error => {
				addErrorNotification(error.toString());
				setIsLoading(false);
			},
			'',
			{ id: contract.id, reservationItemId: reservationItem.id }
		);
	};

	const onSend = (reservationItemStatusContract, isESign = false) => {
		apiCall(
			'POST',
			'sendReservationItemStatusContractEmail',
			() => {
				addSuccessNotification('Email successfully sent.');
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{
				id: reservationItemStatusContract.id,
				isESign,
			}
		);
	};

	return (
		<>
			<ListContainer
				route='reservationItemStatusContracts'
				title='Contracts'
				staticFilters={{ 'reservationItem.id': reservationItem.id }}
				isTabList
				icon='Clipboard-check'
				hasSearch={false}
				hasPagination={false}
				forms={[]}
				presetData={{}}
				customActions={{
					edit: reservationStatusContract =>
						openModal({
							open: modals.VIEW,
							reservationStatusContract,
							contract: {
								...reservationStatusContract.contract,
								parsedTemplate: reservationStatusContract.text,
							},
						}),
					print: reservationStatusContract => onPrint(reservationStatusContract.text),
					sendEmail: reservationStatusContract => onSend(reservationStatusContract),
					sendESignEmail: reservationStatusContract =>
						onSend(reservationStatusContract, true),
				}}
				headerActions={
					getContracts().length > 0
						? [
								...headerActions,
								{
									component: (
										<Dropdown
											key='contracts'
											title='New Contract'
											label='brand'
											design='brand'
											aligned='right'
											icon='Plus'
											disabled={isLoading || reservationItem.id === 0}>
											{getContracts().map(c => (
												<Dropdown.Item
													key={`contract${c.id}`}
													icon='Plus'
													onClick={() => getContractTemplate(c)}
													isDisabled={isLoading}>
													{c.name}
												</Dropdown.Item>
											))}
										</Dropdown>
									),
								},
						  ]
						: headerActions
				}
				fluid={isFluid ? 'fluid' : null}>
				<List
					fluid='fluid'
					withCheckBox={false}
					withOutPortlet
					hasPagination={false}
					className='sdms-reservation-contract-list'
					checkEditable={() => {
						const actions = ['edit', 'print', 'sendEmail', 'sendESignEmail'];

						if (hasDeleteReservationItemStatusContractsPermission(userContext))
							actions.push('delete');

						return actions;
					}}>
					<List.Col label='Name' cellData='name' isLinkColumn width={200} />
					<List.Col label='Contract' cellDataObject='contract' cellData='name' />
					<List.Col label='Version' cellData='version' />
					<List.Col
						label='Expiration Date'
						cellComponent={<DateCell field='expirationDate' />}
					/>
					<List.Col label='E-sign Date' cellComponent={<DateCell field='esignDate' />} />
					<List.Col
						label='Created Date'
						cellComponent={<DateCell field='createdDate' />}
					/>
					<List.Col align='right' onlyHover width={150}>
						<Button
							className='sdms-margin-r-15'
							design='link'
							text='View'
							icon='Expand-arrows'
							size='sm'
							elevate
							key='edit'
						/>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item icon='Mail-box' text='Email' key='sendEmail'>
								Email
							</Dropdown.Item>
							<Dropdown.Item icon='Mail-box' text='E-Sign' key='sendESignEmail'>
								Send e-sign
							</Dropdown.Item>
							<Dropdown.Item icon='Printer' text='Print' key='print'>
								Print
							</Dropdown.Item>
							<Dropdown.Item
								icon='Trash'
								text='Delete'
								key='delete'
								itemsColor='danger'>
								Delete
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			<Contract
				data={{ ...modal.reservationStatusContract }}
				isOpen={modal.open === modals.VIEW || modal.open === modals.NEW}
				onClose={closeModal}
				contract={modal.contract}
				reservationItem={reservationItem}
				reservation={reservation}
				module={module}
				forView={modal.open === modals.VIEW}
			/>
			{PRINT_COMPONENT}
		</>
	);
};

ContractList.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object.isRequired,
	module: PropTypes.string.isRequired,
	isFluid: PropTypes.bool,
	headerActions: PropTypes.arrayOf(PropTypes.object),
};

ContractList.defaultProps = {
	isFluid: false,
	headerActions: [],
};

export default ContractList;
