import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { getReportPath, getReportText } from '../../../utils/helpers/helper';

import FormContainer from '../../reusables/template/FormContainer';
import ReportsForm from './ReportsForm';

const ReportsEdit = ({ match }) => {
	const historyLocation = useLocation();

	const { report } = historyLocation.state ? historyLocation.state : { report: null };

	return (
		<FormContainer
			dataId={match.params.id}
			lists={['enumReportTypes']}
			dataName='reports'
			fields={<ReportsForm report={report} />}
			pageTitle={getReportText(historyLocation.pathname)}
			listUrl={getReportPath(historyLocation.pathname)}
		/>
	);
};

ReportsEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ReportsEdit;
