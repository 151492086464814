import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { required } from '../../../utils/helpers/validation';
import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';
import useField from '../../../utils/hooks/useField';
import { cryptoEncrypt } from '../../../utils/helpers/crypto';
import { emailAddressPresetConfigurations } from '../../../utils/constants/constants';
import { addCustomNotification, addErrorNotification } from '../../../utils/helpers/helper';

import Input from '../../reusables/field/Input';
import FormGroup from '../../reusables/layout/FormGroup';
import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import FormField from '../../reusables/template/FormField';
import Toggle from '../../reusables/field/Toggle';
import Button from '../../reusables/element/Button';
import Loading from '../../reusables/template/Loading';
import Selects from '../../reusables/field/Selects';
import Badge from '../../reusables/element/Badge';
import Json from '../../reusables/field/Json';

const EmailAddressesForm = ({
	data,
	setIsValid,
	isLoading,
	isSubmitted,
	submit,
	submitButtonAttr,
	onFormChange,
	enumMailAccountTypes,
	enumMailEncryptions,
	isSubmitting,
}) => {
	const headerContext = useContext(HeaderContext);

	// New Password input & Button
	const [passwordInputShow, setPasswordInputShow] = useState(false);

	const passwordRef = useRef();

	const passwordRequiredValidation = (value, setValRes) =>
		passwordInputShow ? required(value, setValRes) : true;

	const outgoingRequired = (value, setValRes) => {
		if (outgoingMail) return required(value, setValRes);

		return true;
	};

	const incomingRequired = (value, setValRes) => {
		if (incomingMail) return required(value, setValRes);

		return true;
	};

	const [
		displayName,
		displayNameOnChange,
		displayNameValRes,
		displayNameShowVal,
		setDisplayNameShowVal,
	] = useField(data, 'displayName', onFormChange, [required]);

	const [
		emailAddress,
		emailAddressOnChange,
		emailAddressValRes,
		emailAddressShowVal,
		setEmailAddressShowVal,
	] = useField(data, 'emailAddress', onFormChange, [required]);

	const [
		username,
		usernameOnChange,
		usernameValRes,
		usernameShowVal,
		setUserNameShowVal,
	] = useField(data, 'username', onFormChange, [required]);

	const [
		password,
		passwordOnChange,
		passwordValRes,
		passwordShowVal,
		setPasswordShowVal,
		validatePassword,
	] = useField(data, 'password', onFormChange, [passwordRequiredValidation], '', value =>
		value ? cryptoEncrypt(value, 'sdms_email_address') : ''
	);

	const [incomingMail, incomingMailOnChange] = useField(
		data,
		'incomingMail',
		onFormChange,
		[],
		false
	);

	const [
		incomingMailAccountType,
		incomingMailAccountTypeOnChange,
		incomingMailAccountTypeValRes,
		incomingMailAccountTypeShowVal,
		setIncomingMailAccountTypeShowVal,
		validateIncomingMailAccountType,
	] = useField(data, 'incomingMailAccountType', onFormChange, [incomingRequired], null);

	const [
		incomingMailServerAddress,
		incomingMailServerAddressOnChange,
		incomingMailServerAddressValRes,
		incomingMailServerAddressShowVal,
		setIncomingMailServerAddressShowVal,
		validateIncomingMailServerAddress,
	] = useField(data, 'incomingMailServerAddress', onFormChange, [incomingRequired]);

	const [
		incomingMailServerPort,
		incomingMailServerPortOnChange,
		incomingMailServerPortValRes,
		incomingMailServerPortShowVal,
		setIncomingMailServerPortShowVal,
		validateIncomingMailServerPort,
	] = useField(data, 'incomingMailServerPort', onFormChange, [incomingRequired]);

	const [configurationString, configurationStringOnChange] = useField(
		data,
		'configurationString',
		onFormChange
	);

	const [outgoingMail, outgoingMailOnChange] = useField(
		data,
		'outgoingMail',
		onFormChange,
		[],
		false
	);

	const [
		outgoingMailAccountType,
		outgoingMailAccountTypeOnChange,
		outgoingMailAccountTypeValRes,
		outgoingMailAccountTypeShowVal,
		setOutgoingMailAccountTypeShowVal,
		validateOutgoingMailAccountType,
	] = useField(data, 'outgoingMailAccountType', onFormChange, [outgoingRequired], null);

	const [
		outgoingMailServerAddress,
		outgoingMailServerAddressOnChange,
		outgoingMailServerAddressValRes,
		outgoingMailServerAddressShowVal,
		setOutgoingMailServerAddressShowVal,
		validateOutgoingMailServerAddress,
	] = useField(data, 'outgoingMailServerAddress', onFormChange, [outgoingRequired]);

	const [
		outgoingMailServerPort,
		outgoingMailServerPortOnChange,
		outgoingMailServerPortValRes,
		outgoingMailServerPortShowVal,
		setOutgoingMailServerPortShowVal,
		validateOutgoingMailServerPort,
	] = useField(data, 'outgoingMailServerPort', onFormChange, [outgoingRequired]);

	const [
		outgoingMailEncryption,
		outgoingMailEncryptionOnChange,
		outgoingMailEncryptionValRes,
		outgoingMailEncryptionShowVal,
		setOutgoingMailEncryptionShowVal,
		validateOutgoingMailEncryption,
	] = useField(data, 'outgoingMailEncryption', onFormChange, [outgoingRequired], null);

	const onAuth = () => {
		if (data.id === 0) {
			addCustomNotification('', 'Please first save email address', '', 'info');
			return;
		}

		if (configurationString === '') {
			addErrorNotification('Configuration is empty');
			return;
		}

		if (incomingMailServerAddress === '') {
			addErrorNotification('Missing incoming server address');
			return;
		}

		const configuration = JSON.parse(configurationString);

		const availableServers = ['office365'];

		const serverIndex = availableServers.findIndex(
			server => incomingMailServerAddress.search(server) > -1
		);

		if (serverIndex === -1) {
			addErrorNotification('Unsupported mail server');
			return;
		}

		if (availableServers[serverIndex] === 'office365') {
			const url = `https://login.microsoftonline.com/${
				configuration.tenant
			}/oauth2/v2.0/authorize?client_id=${
				configuration.client_id
			}&scope=https://outlook.office365.com/IMAP.AccessAsUser.All&response_type=code&approval_prompt=auto&redirect_uri=${encodeURI(
				`${window.location.protocol}//${window.location.host}${pages.systemSettings.emailAddressAuth.path}`
			)}`;

			window.open(url, '_blank');
		}
	};

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.systemSettings.default.text, path: pages.systemSettings.dashboard.path },
			{
				title: pages.systemSettings.emailAddresses.text,
				path: pages.systemSettings.emailAddresses.path,
			},
			{
				title: displayName || `New ${pages.systemSettings.emailAddresses.text}`,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(displayName || pages.systemSettings.emailAddresses.text);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [displayName]);

	useEffect(() => {
		if (isSubmitted) {
			setDisplayNameShowVal();
			setEmailAddressShowVal();
			setUserNameShowVal();
			if (passwordInputShow) setPasswordShowVal();
			setIncomingMailAccountTypeShowVal();
			setIncomingMailServerAddressShowVal();
			setIncomingMailServerPortShowVal();
			setOutgoingMailAccountTypeShowVal();
			setOutgoingMailServerAddressShowVal();
			setOutgoingMailServerPortShowVal();
			setOutgoingMailEncryptionShowVal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isSubmitted,
		setDisplayNameShowVal,
		setEmailAddressShowVal,
		setUserNameShowVal,
		setPasswordShowVal,
		setOutgoingMailEncryptionShowVal,
		setIncomingMailAccountTypeShowVal,
		setIncomingMailServerAddressShowVal,
		setIncomingMailServerPortShowVal,
		setOutgoingMailAccountTypeShowVal,
		setOutgoingMailServerAddressShowVal,
		setOutgoingMailServerPortShowVal,
	]);

	useEffect(() => {
		setIsValid(
			displayNameValRes.isValid &&
				emailAddressValRes.isValid &&
				usernameValRes.isValid &&
				passwordValRes.isValid &&
				incomingMailAccountTypeValRes.isValid &&
				incomingMailServerAddressValRes.isValid &&
				incomingMailServerPortValRes.isValid &&
				outgoingMailAccountTypeValRes.isValid &&
				outgoingMailServerAddressValRes.isValid &&
				outgoingMailServerPortValRes.isValid &&
				outgoingMailEncryptionValRes.isValid
		);
	}, [
		setIsValid,
		displayNameValRes.isValid,
		emailAddressValRes.isValid,
		incomingMailAccountTypeValRes.isValid,
		incomingMailServerAddressValRes.isValid,
		incomingMailServerPortValRes.isValid,
		outgoingMailAccountTypeValRes.isValid,
		outgoingMailEncryptionValRes.isValid,
		outgoingMailServerAddressValRes.isValid,
		outgoingMailServerPortValRes.isValid,
		passwordValRes.isValid,
		usernameValRes.isValid,
	]);

	useEffect(() => {
		if (passwordInputShow && data.id) {
			passwordRef.current.focus();
		}
		validatePassword(password);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [passwordInputShow]);

	useEffect(() => {
		if (!isSubmitting) setPasswordInputShow(false);
	}, [isSubmitting]);

	useEffect(() => {
		if (!isLoading) setPasswordInputShow(data.id === 0);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		validateIncomingMailAccountType(incomingMailAccountType);
		validateIncomingMailServerAddress(incomingMailServerAddress);
		validateIncomingMailServerPort(incomingMailServerPort);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [incomingMail]);

	useEffect(() => {
		validateOutgoingMailAccountType(outgoingMailAccountType);
		validateOutgoingMailServerAddress(outgoingMailServerAddress);
		validateOutgoingMailServerPort(outgoingMailServerPort);
		validateOutgoingMailEncryption(outgoingMailEncryption);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [outgoingMail]);

	return (
		<Portlet className='sdms-form' fluid='fluid' hasFrame>
			<Portlet.Body>
				<Section title='General Settings'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='displayName'
									label='Display Name'
									id={data.id}
									colMd={6}
									valRes={displayNameValRes}
									showValidation={displayNameShowVal}>
									<Input
										placeholder='Display Name'
										onChange={displayNameOnChange}
										value={displayName}
										onBlur={setDisplayNameShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='emailAddress'
									label='Email Address'
									id={data.id}
									colMd={6}
									valRes={emailAddressValRes}
									showValidation={emailAddressShowVal}>
									<Input
										placeholder='Email Address'
										onChange={emailAddressOnChange}
										value={emailAddress}
										onBlur={setEmailAddressShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='username'
									label='User Name'
									id={data.id}
									colMd={6}
									valRes={usernameValRes}
									showValidation={usernameShowVal}>
									<Input
										placeholder='User Name'
										onChange={usernameOnChange}
										value={username}
										onBlur={setUserNameShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='password'
									label={
										passwordInputShow && data.id ? (
											<>
												Password
												<Badge
													className='sdms-cursor--pointer sdms-ml-10'
													design='info'
													isInline
													isElevate
													onMouseDown={() => {
														setPasswordInputShow(false);
														passwordOnChange({ target: { value: '' } });
														setPasswordShowVal(false);
														if (password === '') validatePassword('');
													}}>
													Use Current Password
												</Badge>
											</>
										) : (
											'Password'
										)
									}
									labelClassName='d-flex'
									id={data.id}
									valRes={passwordValRes}
									showValidation={passwordShowVal}
									colLg={6}>
									{passwordInputShow ? (
										<Input
											ref={passwordRef}
											type='password'
											placeholder='Password (Required)'
											autoComplete={false}
											value={password}
											onChange={passwordOnChange}
											onBlur={setPasswordShowVal}
											togglePassword
										/>
									) : (
										<Button
											label='primary'
											text='Change Password'
											block
											onClick={() => {
												setPasswordInputShow(true);
											}}
										/>
									)}
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='incomingMail'
									label='Incoming Mail'
									id={data.id}
									colMd={6}>
									<Toggle
										spaceLess
										value={incomingMail}
										onChange={incomingMailOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='incomingMailAccountType'
									label='Incoming Mail Account Type'
									id={data.id}
									valRes={incomingMailAccountTypeValRes}
									showValidation={incomingMailAccountTypeShowVal}
									colMd={6}>
									<Selects
										options={enumMailAccountTypes.filter(
											type => !type.isOutgoing
										)}
										placeholder='Select an item'
										value={incomingMailAccountType}
										onChange={incomingMailAccountTypeOnChange}
										onBlur={setIncomingMailAccountTypeShowVal}
										displayKey='value'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='incomingMailServerAddress'
									label='Incoming Mail Server Address'
									id={data.id}
									colMd={6}
									valRes={incomingMailServerAddressValRes}
									showValidation={incomingMailServerAddressShowVal}>
									<Input
										placeholder='Incoming Mail Server Address'
										onChange={incomingMailServerAddressOnChange}
										value={incomingMailServerAddress}
										onBlur={setIncomingMailServerAddressShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='incomingMailServerPort'
									label='Incoming Mail Server Port'
									id={data.id}
									colMd={6}
									valRes={incomingMailServerPortValRes}
									showValidation={incomingMailServerPortShowVal}>
									<Input
										placeholder='Incoming Mail Server Port'
										onChange={incomingMailServerPortOnChange}
										value={incomingMailServerPort}
										onBlur={setIncomingMailServerPortShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='configuration'
									label='Incoming Configuration String'
									id={data.id}
									colMd={10}>
									<Json
										value={configurationString}
										onChange={value => {
											configurationStringOnChange({ target: { value } });
										}}
										title='Configuration String'
										placeholder='Configuration String'
										presetConfigurations={emailAddressPresetConfigurations}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField name='auth' id={data.id} colMd={2} label='  '>
									<Button
										style={{ marginTop: 5 }}
										icon='Key'
										label='primary'
										text='Authorize'
										block
										onClick={onAuth}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='outgoingMail'
									label='Outgoing Mail'
									id={data.id}
									colMd={6}>
									<Toggle
										spaceLess
										value={outgoingMail}
										onChange={outgoingMailOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='outgoingMailAccountType'
									label='Outgoing Mail Account Type'
									id={data.id}
									valRes={outgoingMailAccountTypeValRes}
									showValidation={outgoingMailAccountTypeShowVal}
									colMd={6}>
									<Selects
										options={enumMailAccountTypes.filter(
											type => type.isOutgoing
										)}
										placeholder='Select an item'
										value={outgoingMailAccountType}
										onChange={outgoingMailAccountTypeOnChange}
										onBlur={setOutgoingMailAccountTypeShowVal}
										displayKey='value'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='outgoingMailServerAddress'
									label='Outgoing Mail Server Address'
									id={data.id}
									colMd={6}
									valRes={outgoingMailServerAddressValRes}
									showValidation={outgoingMailServerAddressShowVal}>
									<Input
										placeholder='Incoming Mail Server Address'
										onChange={outgoingMailServerAddressOnChange}
										value={outgoingMailServerAddress}
										onBlur={setOutgoingMailServerAddressShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='outgoingMailServerPort'
									label='Outgoing Mail Server Port'
									id={data.id}
									colMd={6}
									valRes={outgoingMailServerPortValRes}
									showValidation={outgoingMailServerPortShowVal}>
									<Input
										placeholder='Outgoing Mail Server Port'
										onChange={outgoingMailServerPortOnChange}
										value={outgoingMailServerPort}
										onBlur={setOutgoingMailServerPortShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='outgoingMailEncryption'
									label='Outgoing Mail Encryption'
									id={data.id}
									valRes={outgoingMailEncryptionValRes}
									showValidation={outgoingMailEncryptionShowVal}
									colMd={6}>
									<Selects
										options={enumMailEncryptions}
										placeholder='Select an item'
										value={outgoingMailEncryption}
										onChange={outgoingMailEncryptionOnChange}
										onBlur={setOutgoingMailEncryptionShowVal}
										displayKey='value'
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
EmailAddressesForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		taxId: PropTypes.string,
		duns: PropTypes.string,
		serviceFeeItem: PropTypes.object,
	}),
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	setIsValid: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
	enumMailAccountTypes: PropTypes.arrayOf(PropTypes.object),
	enumMailEncryptions: PropTypes.arrayOf(PropTypes.object),
	isSubmitting: PropTypes.bool,
};
EmailAddressesForm.defaultProps = {
	data: {
		id: 0,
	},
	submit: () => {},
	setIsValid: () => {},
	isLoading: false,
	isSubmitted: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
	enumMailAccountTypes: [],
	enumMailEncryptions: [],
	isSubmitting: false,
};

export default EmailAddressesForm;
