import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import apiCall from '../../../utils/helpers/apiCall';
import { addErrorNotification, addSuccessNotification } from '../../../utils/helpers/helper';

import SVGIcon from '../element/SVGIcon';
import WidgetPivot from './WidgetPivot';
import Portlet from '../layout/Portlet';
import WidgetChart from './WidgetChart';
import WidgetSingle from './WidgetSingle';
import Dropdown from '../element/Dropdown';
import Button from '../element/Button';

const WidgetWrapper = ({ widget, reload, onEditWidget, isGridLoading, data, readOnly }) => {
	const [isLoading, setIsLoading] = useState(false);

	const configRef = useRef(JSON.parse(widget.config));

	const handleEdit = () => onEditWidget(widget);

	const handleSaveConfiguration = () => {
		setIsLoading(true);

		apiCall(
			'PUT',
			'analyticsWidgets',
			() => {
				addSuccessNotification('Widget configuration saved.');

				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());

				setIsLoading(false);
			},
			widget.id,
			{
				config: JSON.stringify(configRef.current),
			}
		);
	};

	const handleDelete = () => {
		setIsLoading(true);

		apiCall(
			'DELETE',
			'analyticsWidgets',
			() => {
				addSuccessNotification('Widget deleted.');

				reload();

				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());

				setIsLoading(false);
			},
			widget.id
		);
	};

	if (isLoading || isGridLoading)
		return (
			<div className='d-flex justify-content-center align-items-center w-100 h-100'>
				<SVGIcon size={48} name='Loading' className='sdms-animate-spinner' />;
			</div>
		);

	const Content = () => {
		if (widget.type.value === 'Pivot')
			return <WidgetPivot data={data} widget={widget} configRef={configRef} />;

		if (widget.type.value === 'Chart') return <WidgetChart data={data} widget={widget} />;

		if (widget.type.value === 'Single') return <WidgetSingle data={data} widget={widget} />;

		return null;
	};

	return (
		<Portlet fluid='fluid'>
			<Portlet.Head>
				<Portlet.HeadLabelTitle>
					<span className='sdms-font-bolder'>{widget.title}</span>
				</Portlet.HeadLabelTitle>
				{!readOnly && (
					<Portlet.HeadToolbarActions>
						{widget.type.value === 'Pivot' && (
							<Button
								className='sdms-mr-10'
								label='brand'
								icon='Save'
								text='Save Configuration'
								size='sm'
								onClick={handleSaveConfiguration}
							/>
						)}
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Actions</Dropdown.Header>
							<Dropdown.Item itemsColor='brand' icon='Edit' onClick={handleEdit}>
								Edit
							</Dropdown.Item>
							<Dropdown.Item itemsColor='danger' icon='Trash' onClick={handleDelete}>
								Delete
							</Dropdown.Item>
						</Dropdown>
					</Portlet.HeadToolbarActions>
				)}
			</Portlet.Head>
			<Portlet.Body style={{ cursor: 'initial', position: 'relative' }}>
				<Content />
			</Portlet.Body>
		</Portlet>
	);
};

export default WidgetWrapper;

WidgetWrapper.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})),
	widget: PropTypes.shape({
		id: PropTypes.number,
		report: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
			queryJSON: PropTypes.string,
			reportFields: PropTypes.arrayOf(PropTypes.shape({})),
		}),
		sortOrder: PropTypes.number,
		width: PropTypes.number,
		title: PropTypes.string,
		config: PropTypes.string,
		type: PropTypes.shape({
			id: PropTypes.number,
			value: PropTypes.string,
		}),
		col: PropTypes.number,
		row: PropTypes.number,
		sizeX: PropTypes.number,
		sizeY: PropTypes.number,
	}),
	reload: PropTypes.func,
	onEditWidget: PropTypes.func,
	isGridLoading: PropTypes.bool,
	readOnly: PropTypes.bool,
};

WidgetWrapper.defaultProps = {
	data: [],
	widget: {
		id: 0,
		report: {
			id: 0,
			name: '',
			queryJSON: '',
			reportFields: [],
		},
		sortOrder: 0,
		width: 100,
		title: '',
		config: null,
		type: {
			id: 0,
			value: null,
		},
		col: 0,
		row: 0,
		sizeX: 0,
		sizeY: 0,
	},
	reload: () => {},
	onEditWidget: () => {},
	isGridLoading: false,
	readOnly: false,
};
