import React from 'react';
import PropTypes from 'prop-types';

import useOutlet from '../../../utils/hooks/useOutlet';
import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';
import { debugTypes } from '../../../utils/helpers/debugHelper';

import FormContainer from '../../reusables/template/FormContainer';
import ProductBookingNightlyForm from './ProductBookingNightlyForm';
import OutletInactive from '../../reusables/element/OutletInactive';

const ProductBookingNightlyEdit = ({ match, afterSubmit }) => {
	const [, outletConfigured] = useOutlet();

	const pages = usePages();

	if (!outletConfigured) return <OutletInactive />;

	return (
		<FormContainer
			dataId={match.params.id.toString()}
			pageTitle='Product Name'
			dataName='productBookings'
			lists={[
				'bookingTypeBookings',
				'productCategoryBookings',
				'taxCodes',
				'unitMeasures',
				'policies',
				'accounts',
				'contracts',
				'bookingUnits',
				'taxRates',
				'bookingExtraCharges',
				'itemTypes',
				'enumBookingCalculations',
				'enumBookingPeriods',
				'enumDays',
				'calendars',
				'ratePlans',
				'customReservationStatuses',
				'enumCalculations',
				'invoicingFrequencies',
				'paymentSchedules',
				'acctClasses',
			]}
			fields={<ProductBookingNightlyForm />}
			icon={pages.booking.products.icon}
			afterSubmit={afterSubmit}
			listUrl={pages.booking.products.path}
			module={modules.BOOKINGS}
			debugType={debugTypes.product}
		/>
	);
};

ProductBookingNightlyEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
	// eslint-disable-next-line react/require-default-props
	afterSubmit: PropTypes.func,
};

export default ProductBookingNightlyEdit;
