import React from 'react';
import PropTypes from 'prop-types';

import pages from '../../pages';

import FormContainer from '../../reusables/template/FormContainer';
import ContractForm from './ContractForm';

const ContractEdit = ({ match }) => {
	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle={pages.systemSettings.contracts.text}
			dataName={pages.systemSettings.contracts.route}
			lists={['templates']}
			fields={<ContractForm />}
		/>
	);
};

ContractEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ContractEdit;
