import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';

import FormContainer from '../template/FormContainer';
import MapForm from './MapForm';

const MapEdit = ({ match }) => {
	const location = useLocation();

	const pages = usePages();

	const data = useMemo(() => {
		if (location.pathname.indexOf('marina') > -1)
			return {
				pageTitle: pages.marina.settings.maps.text,
				icon: pages.marina.settings.maps.icon,
				lists: ['icons', 'marinaUnits', 'fontSizes'],
				module: modules.MARINA,
			};

		if (location.pathname.indexOf('campground') > -1)
			return {
				pageTitle: pages.campground.settings.maps.text,
				icon: pages.campground.settings.maps.icon,
				lists: ['icons', 'campgroundUnits', 'fontSizes'],
				module: modules.CAMPGROUND,
			};

		return {
			pageTitle: pages.booking.settings.maps.text,
			icon: pages.booking.settings.maps.icon,
			lists: ['icons', 'bookingUnits', 'fontSizes'],
			module: modules.BOOKINGS,
		};
	}, [location, pages]);

	return (
		<FormContainer
			dataId={match.params.id.toString()}
			dataName='unitMaps'
			fields={<MapForm />}
			pageTitle={data.pageTitle}
			icon={data.icon}
			lists={data.lists}
			module={data.module}
		/>
	);
};
MapEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default MapEdit;
