import React from 'react';
import {
	ChartComponent,
	SeriesCollectionDirective,
	SeriesDirective,
	Inject,
	Legend,
	Category,
	Tooltip,
	DataLabel,
	LineSeries,
	DateTime,
	ColumnSeries,
	BarSeries,
} from '@syncfusion/ej2-react-charts';

import PropTypes from 'prop-types';

const SeriesChart = ({ data, type, xTitle, yTitle, xType }) => {
	return (
		<ChartComponent
			style={{ textAlign: 'center', height: '100%' }}
			primaryXAxis={{
				title: xTitle,
				valueType: xType,
			}}
			primaryYAxis={{
				title: yTitle,
				valueType: 'Double',
			}}
			chartArea={{ border: { width: 0 } }}
			tooltip={{ enable: true }}
			legendSettings={{ enableHighlight: true }}
			width='100%'
			height='100%'>
			<Inject
				services={[
					LineSeries,
					ColumnSeries,
					BarSeries,
					Category,
					DateTime,
					Legend,
					Tooltip,
					DataLabel,
				]}
			/>
			<SeriesCollectionDirective>
				<SeriesDirective dataSource={data} xName='x' yName='y' width={2} type={type} />
			</SeriesCollectionDirective>
		</ChartComponent>
	);
};
export default SeriesChart;

SeriesChart.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object),
	type: PropTypes.string,
	xTitle: PropTypes.string,
	yTitle: PropTypes.string,
	xType: PropTypes.string,
};

SeriesChart.defaultProps = {
	data: [],
	type: 'Line',
	xTitle: '',
	yTitle: '',
	xType: 'Category',
};
