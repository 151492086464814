import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';
import forms from '../../forms';

import Button from '../element/Button';
import Dropdown from '../element/Dropdown';
import List from '../template/List';
import ListContainer from '../template/ListContainer';

const MapList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	const location = useLocation();

	const pages = usePages();

	const data = useMemo(() => {
		if (location.pathname.indexOf('marina') > -1)
			return {
				text: pages.marina.settings.maps.text,
				breadcrumb: [
					{ title: pages.marina.default.text, path: pages.marina.dashboard.path },
					{ title: pages.marina.settings.text, path: pages.marina.settings.path },
					{ title: pages.marina.settings.maps.text, isActive: true },
				],
				title: pages.marina.settings.maps.text,
				icon: pages.marina.settings.maps.icon,
				forms: forms.marina.settings.maps,
				module: modules.MARINA,
			};

		if (location.pathname.indexOf('campground') > -1)
			return {
				text: pages.campground.settings.maps.text,
				breadcrumb: [
					{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
					{ title: pages.campground.settings.text, path: pages.campground.settings.path },
					{ title: pages.campground.settings.maps.text, isActive: true },
				],
				title: pages.campground.settings.maps.text,
				icon: pages.campground.settings.maps.icon,
				forms: forms.campground.settings.maps,
				module: modules.CAMPGROUND,
			};

		return {
			text: pages.booking.settings.maps.text,
			breadcrumb: [
				{ title: pages.booking.default.text, path: pages.booking.dashboard.path },
				{ title: pages.booking.settings.text, path: pages.booking.settings.path },
				{ title: pages.booking.settings.maps.text, isActive: true },
			],
			title: pages.booking.settings.maps.text,
			icon: pages.booking.settings.maps.icon,
			forms: forms.booking.settings.maps,
			module: modules.BOOKINGS,
		};
	}, [location, pages]);

	useEffect(() => {
		headerContext.setBreadcrumbs(data.breadcrumb);

		headerContext.setPageTitle(data.text);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [data]);

	return (
		<ListContainer
			route='unitMaps'
			title={data.title}
			history={history}
			icon={data.icon}
			forms={data.forms}
			module={data.module}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col align='right' onlyHover>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

MapList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default MapList;
