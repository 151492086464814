import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import Checkbox from '../../reusables/field/Checkbox';
import Loading from '../../reusables/template/Loading';

import {
	dateFormatter,
	invoiceToPayment,
	numberFormat,
	priceFormatter,
	quantityFormatter,
	timeSince,
} from '../../../utils/helpers/helper';
import {
	ItemRow,
	ItemCellActions,
	ItemCellCheckBox,
	ItemCellName,
	ItemCellPrice,
	ItemCellQuantity,
	ItemRowDescription,
	ItemRowNote,
	ItemRowTax,
	ItemRowDiscount,
	OrderNote,
	OrderSeats,
	PaymentItem,
	PayButton,
	PaymentsTitle,
} from '../../reusables/element/Order';

import {
	afterSaveActions,
	terminalModals,
	orderStatuses,
	paymentStatuses,
	paymentTypes,
	printStatuses,
	creditCardPaymentStatuses,
} from '../../../utils/constants/constants';
import { StatusCell } from './RecallOrders';

export const OrderTable = ({ children }) => {
	return <table className='table sdms-t-table'>{children}</table>;
};
OrderTable.propTypes = {
	children: PropTypes.node.isRequired,
};

export const OrderHead = ({
	className,
	children,
	isAllSelected,
	isMultiActionsEnabled,
	onCheckAll,
	hasActions,
	hideCheckbox,
	disabled,
}) => {
	return (
		<thead className={className}>
			<tr>
				{!hideCheckbox && (
					<th className='sdms-t-orders--item__check'>
						<Checkbox
							onChange={() => onCheckAll(!isAllSelected)}
							value={isAllSelected}
							disabled={disabled}
						/>
					</th>
				)}
				{isMultiActionsEnabled ? (
					<th colSpan={hasActions ? 4 : 3} className='sdms-t-orders--item__all-actions'>
						{children}
					</th>
				) : (
					<>
						<th className='sdms-t-orders--item__name'>Items</th>
						<th className='sdms-t-orders--item__quantity'>Quantity</th>
						<th className='sdms-t-orders--item__price'>Price</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						{hasActions && <th className='sdms-t-orders--item__actions' />}
					</>
				)}
			</tr>
		</thead>
	);
};
OrderHead.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	isAllSelected: PropTypes.bool,
	isMultiActionsEnabled: PropTypes.bool,
	onCheckAll: PropTypes.func,
	hasActions: PropTypes.bool,
	hideCheckbox: PropTypes.bool,
	disabled: PropTypes.bool,
};
OrderHead.defaultProps = {
	className: null,
	children: null,
	isAllSelected: false,
	isMultiActionsEnabled: false,
	onCheckAll: () => {},
	hasActions: true,
	hideCheckbox: false,
	disabled: false,
};

export const OrderBody = ({ className, children }) => {
	return (
		<>
			<OrderHead />
			<tbody className={className}>{children}</tbody>
		</>
	);
};
OrderBody.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
OrderBody.defaultProps = {
	className: null,
};

export const OrderItem = ({
	className,
	id,
	description,
	printStatus,
	name,
	note,
	quantity,
	discountType,
	discountAmount,
	discount,
	taxRate,
	subtotal,
	taxCode,
	product,
	hasActions,
	isSelected,
	index,
	onSelect,
	isRowSelect,
	onEdit,
	isEditable,
	orderItemModifiers,
	deleteOrderItem,
	disabled,
	discountAllowed,
	hasDiscountPermission,
	hasEditPricePermission,
	isOrderOpen,
	orderTaxRate,
	openModal,
	disableActions,
	clearOrderItemDiscount,
	hideCheckbox,
	disableQuantity,
	userHasPermission,
	noPermission,
}) => {
	// This function computes the price - discount
	const getOrderItemTotal = () => {
		let modifierTotal = 0;
		orderItemModifiers.forEach(oim => {
			modifierTotal += numberFormat(oim.price * quantity);
		});
		let _subTotal = subtotal - modifierTotal;
		if (discount && !hasActions)
			_subTotal -=
				discountType.value === 'percent'
					? (subtotal / 100) * discountAmount
					: discountAmount;
		return _subTotal;
	};

	const getFormattedTaxRateAmount = () => {
		if (!orderTaxRate) return taxRate;

		if (taxRate === 0) return '-';

		return orderTaxRate.type.value === 'percent'
			? `${quantityFormatter(taxRate, 2)}%`
			: priceFormatter(taxRate);
	};

	return (
		<>
			<ItemRow
				className={className}
				disabled={disabled}
				printStatus={printStatus.value}
				onClick={() => {
					if (isRowSelect) onSelect(!isSelected, id);
				}}>
				<ItemCellCheckBox
					hideCheckbox={hideCheckbox}
					onChange={() => {
						if (!isRowSelect) onSelect(!isSelected, id);
					}}
					value={isSelected}
					disabled={disabled}
				/>
				<ItemCellName>{name}</ItemCellName>
				<ItemCellQuantity>
					{hasActions ? (
						<Button
							design='secondary'
							hoverOutline
							block
							disabled={disableQuantity}
							onClick={() => {
								if (
									!disableActions &&
									(printStatus.value === printStatuses.NEW ||
										printStatus.value === printStatuses.DONOTPRINT)
								)
									openModal({ open: terminalModals.QUANTITY, orderItems: [id] });
							}}
							noPermission={noPermission}>
							{quantityFormatter(quantity)}
						</Button>
					) : (
						quantityFormatter(quantity)
					)}
				</ItemCellQuantity>
				<ItemCellPrice
					onClick={() => {
						if (hasEditPricePermission && isOrderOpen && !noPermission)
							openModal({ open: terminalModals.PRICE, orderItems: [id] });
					}}>
					{priceFormatter(getOrderItemTotal())}
				</ItemCellPrice>
				{hasActions && (
					<ItemCellActions disabled={disableActions} title='Item Actions'>
						{discountAllowed && (
							<Dropdown.Item
								icon='Sale#2'
								noPermission={!hasDiscountPermission || noPermission}
								onClick={() =>
									openModal({ open: terminalModals.DISCOUNT, orderItems: [id] })
								}>
								{discount ? 'Edit Discount' : 'Add Discount'}
							</Dropdown.Item>
						)}
						{discount && (
							<Dropdown.Item
								icon='Sale#2'
								onClick={() => clearOrderItemDiscount(id)}
								noPermission={!hasDiscountPermission || noPermission}
								itemsColor='danger'>
								Clear Discount
							</Dropdown.Item>
						)}
						<Dropdown.Item
							icon='Sale#1'
							onClick={() =>
								openModal({ open: terminalModals.TAX, orderItems: [id] })
							}
							noPermission={!userHasPermission('edit_tax') || noPermission}>
							Change TAX
						</Dropdown.Item>
						<Dropdown.Item
							icon='Write'
							onClick={() =>
								openModal({ open: terminalModals.NOTE, orderItems: [id] })
							}
							noPermission={noPermission}>
							{note ? 'Edit Note' : 'Add Note'}
						</Dropdown.Item>
						{isEditable && (
							<Dropdown.Item
								icon='Edit'
								onClick={() => onEdit(product, index)}
								noPermission={noPermission}>
								Edit
							</Dropdown.Item>
						)}
						<Dropdown.Item
							icon='Dollar'
							noPermission={!hasEditPricePermission || noPermission}
							onClick={() =>
								openModal({ open: terminalModals.PRICE, orderItems: [id] })
							}>
							Edit Price
						</Dropdown.Item>
						{(printStatus.value === printStatuses.NEW ||
							printStatus.value === printStatuses.DONOTPRINT) && (
							<Dropdown.Item
								icon='Trash'
								itemsColor='danger'
								onClick={() => deleteOrderItem([id])}
								noPermission={noPermission}>
								Delete Item
							</Dropdown.Item>
						)}
					</ItemCellActions>
				)}
			</ItemRow>
			<ItemRowDescription
				description={description}
				disabled={disabled}
				isLast={!note && !(taxCode.id !== product.taxCode.id) && !discountAmount}
				printStatus={printStatus.value}
			/>
			<ItemRowNote
				disabled={disabled}
				isLast={!(taxCode.id !== product.taxCode.id) && !discountAmount}
				note={note}
				printStatus={printStatus.value}
			/>
			<ItemRowTax
				disabled={disabled}
				isLast={!discountAmount}
				name={taxCode.name}
				printStatus={printStatus.value}
				tax={taxCode.id !== product.taxCode.id}
				value={getFormattedTaxRateAmount()}
			/>
			<ItemRowDiscount
				disabled={disabled}
				discount={discount}
				discountAmount={discountAmount}
				discountType={discountType}
				printStatus={printStatus.value}
				subtotal={subtotal}
			/>
		</>
	);
};
OrderItem.propTypes = {
	className: PropTypes.string,
	value: PropTypes.bool,
	id: PropTypes.number.isRequired,
	description: PropTypes.string,
	printStatus: PropTypes.shape({
		value: PropTypes.string,
	}),
	name: PropTypes.string.isRequired,
	note: PropTypes.string,
	quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	discountType: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		value: PropTypes.string,
	}),
	discountAmount: PropTypes.number,
	discount: PropTypes.shape({
		name: PropTypes.string,
	}),
	taxRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	subtotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	taxCode: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		name: PropTypes.string,
	}),
	product: PropTypes.shape({
		taxCode: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
		serviceFee: PropTypes.object,
	}),
	hasActions: PropTypes.bool,
	isSelected: PropTypes.bool.isRequired,
	index: PropTypes.number.isRequired,
	onSelect: PropTypes.func,
	isRowSelect: PropTypes.bool,
	onEdit: PropTypes.func,
	isEditable: PropTypes.bool,
	orderItemModifiers: PropTypes.arrayOf(PropTypes.object),
	deleteOrderItem: PropTypes.func,
	disabled: PropTypes.bool,
	discountAllowed: PropTypes.bool,
	hasDiscountPermission: PropTypes.bool,
	hasEditPricePermission: PropTypes.bool,
	isOrderOpen: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	orderTaxRate: PropTypes.object,
	openModal: PropTypes.func,
	disableActions: PropTypes.bool,
	clearOrderItemDiscount: PropTypes.func,
	hideCheckbox: PropTypes.bool,
	disableQuantity: PropTypes.bool,
	userHasPermission: PropTypes.func.isRequired,
	noPermission: PropTypes.bool,
};
OrderItem.defaultProps = {
	className: null,
	value: false,
	description: null,
	printStatus: {
		value: null,
	},
	note: null,
	discountType: null,
	discountAmount: null,
	discount: null,
	taxRate: null,
	taxCode: { id: null, name: null },
	product: {
		taxCode: {
			id: null,
		},
		serviceFee: null,
	},
	hasActions: true,
	onSelect: () => {},
	isRowSelect: false,
	onEdit: () => {},
	isEditable: false,
	orderItemModifiers: [],
	deleteOrderItem: () => {},
	disabled: false,
	discountAllowed: false,
	hasDiscountPermission: false,
	hasEditPricePermission: false,
	isOrderOpen: false,
	orderTaxRate: null,
	disableActions: true,
	clearOrderItemDiscount: null,
	hideCheckbox: false,
	disableQuantity: false,
	openModal: () => {},
	noPermission: null,
};

const Order = ({
	data,
	orders,
	isLoading,
	openSplit,
	selectedOrderItems,
	onSelectAllOrderItems,
	saveOrder,
	changeActiveOrder,
	onSelectOrderItem,
	userHasPermission,
	onOrderItemEdit,
	orderPrint,
	servicePrint,
	deleteOrderItem,
	getSendableOrderItems,
	openModal,
	isCreditCardEnabled,
	isCashEnabled,
	defaultCreditCard,
	paymentType,
	paymentPrint,
	enableOverlay,
	onOverlayClick,
	discountAllowed,
	clearDiscount,
	clearOrderItemDiscount,
	hasOrderChange,
	closeModal,
	noPermission,
	hasSideCar,
	onPayWithBooking,
}) => {
	const hasCashPayment = () => {
		if (data.invoices.length === 0) return false;
		return (
			data.invoices.filter(
				i =>
					i.customerSettlements.filter(
						cs =>
							cs.remittance.paymentMethod &&
							!cs.remittance.fromPayBill &&
							cs.remittance.paymentMethod.paymentType.value === paymentTypes.CASH
					).length > 0
			).length > 0
		);
	};

	const getChangeDue = () => {
		if (data.invoices.length === 0) return 0;

		let changeDue = 0;

		data.invoices.forEach(i => {
			i.customerSettlements.forEach(cs => {
				if (
					cs.remittance.paymentMethod &&
					!cs.remittance.fromPayBill &&
					cs.remittance.paymentMethod.paymentType.value === paymentTypes.CASH
				)
					changeDue += cs.remittance.changeDue;
			});
		});

		return changeDue;
	};

	const getTotalLastRowText = () => {
		if (
			data.status.value === orderStatuses.OPEN ||
			data.status.value === orderStatuses.REFUNDED
		)
			return 'TAX';

		return hasCashPayment() ? 'Change Due' : 'Paid';
	};

	const getTotalLastRowPrice = () => {
		if (
			data.status.value === orderStatuses.OPEN ||
			data.status.value === orderStatuses.REFUNDED
		)
			return data.taxTotal;

		return hasCashPayment() ? getChangeDue() : data.amountInvoiced;
	};

	const getTipSum = () => {
		if (data.invoices.length === 0) return 0;

		let tipSum = 0;

		data.invoices.forEach(i => {
			tipSum += i.tip || 0;
		});

		return tipSum;
	};

	const hasProcessingPayment = () => {
		if (data.invoices.length === 0) return false;

		return (
			data.invoices.filter(
				i =>
					i.customerSettlements.filter(
						cs => cs.remittance.status.value === paymentStatuses.PROCESSING
					).length > 0
			).length > 0
		);
	};

	const customerData = useMemo(() => {
		if (data.vessel) return { label: data.vessel.name, icon: 'Ship' };

		return { label: data?.customer?.displayName || '', icon: 'User' };
	}, [data.customer, data.vessel]);

	return (
		<div
			className={classNames('col-xl-4', 'col-lg-5', 'col-md-12', 'col-12', {
				'sdms-h-50-tablet-and-mobile': !hasSideCar,
			})}>
			<Portlet
				className={classNames({
					'sdms-t-table__overlay__container': enableOverlay || noPermission,
				})}
				fluid='fluid'>
				{enableOverlay && !noPermission && (
					<div className='sdms-t-table__overlay'>
						<Loading isLoading={isLoading} type='button'>
							<Button
								design='info'
								icon='Done-circle'
								bold='bolder'
								size='lg'
								text='Select Order'
								noPermission={noPermission}
								onClick={onOverlayClick}
							/>
						</Loading>
					</div>
				)}
				<Portlet.Head>
					<Portlet.HeadLabel>
						<Loading
							isLoading={isLoading}
							type='button'
							props={{ label: 'success', icon: 'Clipboard', size: 'sm' }}>
							{orders.length ? (
								<Dropdown
									title={data.name}
									arrow
									icon='Clipboard'
									label='success'
									aligned='left'
									size='sm'
									fontWeight='boldest'
									isScroll
									outline
									menuSize='lg'>
									{orders
										.filter(o => o.status.value !== 'void')
										.sort((o1, o2) => o1.orderId.localeCompare(o2.orderId))
										.map(o => (
											<Dropdown.Item
												key={o.id}
												className='sdms-t-orders__dropdown-item'
												onClick={() => {
													if (hasOrderChange) {
														openModal({
															open: terminalModals.UNSAVED,
															onSubmit: () => {
																changeActiveOrder(o.id);
																closeModal();
															},
														});
														return;
													}
													changeActiveOrder(o.id);
												}}>
												<div className='row align-items-center'>
													<div className='col'>
														<div className='row align-items-center'>
															<div className='col-auto'>
																<h6 className='sdms-mb-0'>
																	{o.name}
																</h6>
															</div>
															<div className='col sdms-pl0'>
																<StatusCell data={o} size='sm' />
															</div>
														</div>

														<span className='sdms-font-md'>
															{timeSince(o.timeModified)}
														</span>
													</div>
													<div className='col-auto text-right'>
														<h3 className='sdms-mb-0'>
															{priceFormatter(o.total)}
														</h3>
													</div>
												</div>
											</Dropdown.Item>
										))}
								</Dropdown>
							) : (
								data.name !== '' && (
									<Button
										label='success'
										icon='Clipboard'
										text={
											data.status.value === orderStatuses.REFUNDED
												? 'Refund'
												: data.name
										}
										size='sm'
										fontWeight='boldest'
									/>
								)
							)}
						</Loading>
					</Portlet.HeadLabel>
					<Portlet.HeadToolbarActions>
						<Button
							label='info'
							icon='Sending'
							outline
							size='sm'
							noPermission={!userHasPermission('send') || noPermission}
							onClick={servicePrint}
							disabled={
								!(
									(data.status.value === orderStatuses.OPEN ||
										data.status.value === orderStatuses.PAID) &&
									!isLoading &&
									getSendableOrderItems(data, selectedOrderItems).length
								)
							}>
							{selectedOrderItems.length ? 'Send' : 'Send All'}
						</Button>
						{openSplit && (
							<Button
								label='info'
								icon='Git#4'
								outline
								size='sm'
								noPermission={!userHasPermission('split') || noPermission}
								onClick={openSplit}
								disabled={
									!(
										data.status.value === orderStatuses.OPEN &&
										data.orderItems.length > 0 &&
										userHasPermission('split') &&
										data.name !== '' &&
										!isLoading
									)
								}>
								Split
							</Button>
						)}
						<Dropdown
							icon='Other#1'
							size='sm'
							label='info'
							inline
							aligned='right'
							arrow={false}
							outline={false}
							disabled={isLoading || data.name === ''}>
							<Dropdown.Header>Order Actions</Dropdown.Header>
							{data.status.value === orderStatuses.OPEN &&
								data.orderItems.length > 0 && (
									<Dropdown.Item
										noPermission={noPermission}
										icon='Bookmark'
										onClick={() => saveOrder(afterSaveActions.HOLD)}>
										Hold
									</Dropdown.Item>
								)}
							{data.status.value === orderStatuses.OPEN &&
								data.orderItems.length > 0 && (
									<Dropdown.Item
										noPermission={noPermission}
										icon='Save'
										onClick={() => saveOrder()}>
										Save
									</Dropdown.Item>
								)}
							{data.status.value === orderStatuses.OPEN && (
								<Dropdown.Item
									noPermission={noPermission}
									icon='Wallet#3'
									onClick={() => saveOrder(afterSaveActions.AUTHORIZE_PAYMENT)}>
									Authorize Payment
								</Dropdown.Item>
							)}
							{data.orderItems.length !== 0 && (
								<Dropdown.Item
									noPermission={noPermission}
									icon='Printer'
									onClick={orderPrint}>
									Print
								</Dropdown.Item>
							)}
							{data.status.value === orderStatuses.OPEN && (
								<Dropdown.Item
									noPermission={noPermission}
									icon='Write'
									onClick={() =>
										openModal({ open: terminalModals.NOTE, isOrder: true })
									}>
									{data.note ? 'Edit Note' : 'Add Note'}
								</Dropdown.Item>
							)}
							{data.status.value === orderStatuses.OPEN && (
								<Dropdown.Item
									noPermission={noPermission}
									icon='Write'
									onClick={() =>
										openModal({
											open: terminalModals.EXTERNAL_NOTE,
											isOrder: true,
										})
									}>
									{data.externalNote ? 'Edit External Note' : 'Add External Note'}
								</Dropdown.Item>
							)}
							{data.status.value === orderStatuses.OPEN && (
								<Dropdown.Item
									noPermission={noPermission}
									icon='Chair2'
									onClick={() => openModal({ open: terminalModals.SEATS })}>
									{data.seats ? 'Edit Seats' : 'Add Seats'}
								</Dropdown.Item>
							)}
							{data.status.value === orderStatuses.OPEN &&
								data.orderItems.length !== 0 &&
								discountAllowed && (
									<Dropdown.Item
										noPermission={
											!userHasPermission('apply_discounts') || noPermission
										}
										icon='Sale#2'
										onClick={() =>
											openModal({
												open: terminalModals.DISCOUNT,
												isOrder: true,
											})
										}>
										{data.discount ? 'Edit Discount' : 'Add Discount'}
									</Dropdown.Item>
								)}
							{data.status.value === orderStatuses.OPEN && data.discount && (
								<Dropdown.Item
									noPermission={
										!userHasPermission('apply_discounts') || noPermission
									}
									icon='Sale#2'
									itemsColor='danger'
									onClick={() => clearDiscount()}>
									Clear Discount
								</Dropdown.Item>
							)}
							{data.status.value === orderStatuses.OPEN &&
								data.orderItems.length > 0 && (
									<Dropdown.Item
										noPermission={
											!userHasPermission('change_table') || noPermission
										}
										icon='Subtract'
										onClick={() => openModal({ open: terminalModals.TABLE })}>
										Change Table
									</Dropdown.Item>
								)}
							{data.status.value === orderStatuses.OPEN &&
								data.orderItems.length > 0 && (
									<Dropdown.Item
										noPermission={
											!userHasPermission('change_server') || noPermission
										}
										onClick={() => openModal({ open: terminalModals.USER })}
										icon='User'>
										Change Server
									</Dropdown.Item>
								)}
							{data.status.value === orderStatuses.OPEN && (
								<Dropdown.Item
									icon='Trash'
									noPermission={
										data.orderItems.length > 0
											? !userHasPermission('void') || noPermission
											: null
									}
									itemsColor='danger'
									onClick={() => openModal({ open: terminalModals.VOID_ORDER })}>
									Void Order
								</Dropdown.Item>
							)}
						</Dropdown>
					</Portlet.HeadToolbarActions>
				</Portlet.Head>
				<Portlet.Head className='sdms-portlet__head--fit align-items-center'>
					<OrderTable>
						<OrderHead
							isMultiActionsEnabled={selectedOrderItems.length > 0}
							isAllSelected={
								data.orderItems.length !== 0 &&
								data.orderItems.length === selectedOrderItems.length
							}
							onCheckAll={isCheck => {
								if (data.status.value === orderStatuses.OPEN)
									onSelectAllOrderItems(isCheck);
							}}>
							<Button
								icon='Sending'
								className='btn-icon'
								label='info'
								size='sm'
								onClick={servicePrint}
								disabled={
									isLoading ||
									!getSendableOrderItems(data, selectedOrderItems).length
								}
								noPermission={!userHasPermission('send') || noPermission}
							/>
							<Button
								icon='Sale#2'
								className='btn-icon'
								label='info'
								size='sm'
								onClick={() =>
									openModal({
										open: terminalModals.DISCOUNT,
										orderItems: selectedOrderItems,
									})
								}
								disabled={isLoading}
								noPermission={!userHasPermission('apply_discounts') || noPermission}
							/>
							<Button
								icon='Sale#1'
								className='btn-icon'
								label='info'
								size='sm'
								onClick={() =>
									openModal({
										open: terminalModals.TAX,
										orderItems: selectedOrderItems,
									})
								}
								disabled={isLoading}
								noPermission={!userHasPermission('edit_tax') || noPermission}
							/>
							<Button
								icon='Write'
								className='btn-icon'
								label='info'
								size='sm'
								onClick={() =>
									openModal({
										open: terminalModals.NOTE,
										orderItems: selectedOrderItems,
									})
								}
								disabled={isLoading}
								noPermission={noPermission}
							/>
							<Button
								icon='Trash'
								className='btn-icon'
								label='danger'
								size='sm'
								disabled={
									data.orderItems.filter(
										oi =>
											(oi.printStatus.value === printStatuses.NEW ||
												oi.printStatus.value ===
													printStatuses.DONOTPRINT) &&
											selectedOrderItems.includes(oi.id)
									).length === 0 || isLoading
								}
								onClick={() =>
									deleteOrderItem(
										data.orderItems
											.filter(
												oi =>
													(oi.printStatus.value === printStatuses.NEW ||
														oi.printStatus.value ===
															printStatuses.DONOTPRINT) &&
													selectedOrderItems.includes(oi.id)
											)
											.map(oi => oi.id)
									)
								}
								noPermission={noPermission}
							/>
						</OrderHead>
					</OrderTable>
				</Portlet.Head>
				<Portlet.Body className='sdms-portlet__body--fit'>
					<OrderTable>
						<OrderBody>
							{data.orderItems.map((item, index) => (
								<OrderItem
									key={item.id}
									id={item.id}
									index={index}
									description={item.description}
									printStatus={item.printStatus}
									name={item.name}
									note={item.note}
									quantity={item.quantity}
									discountType={item.discountType}
									discountAmount={item.discountAmount}
									discount={item.discount}
									taxRate={item.taxRate}
									subtotal={item.subtotal}
									taxCode={item.taxCode}
									product={item.product}
									isSelected={selectedOrderItems.includes(item.id)}
									onSelect={(isSelected, orderItemId) => {
										onSelectOrderItem(isSelected, orderItemId);
									}}
									disableActions={
										!(
											data.status.value === orderStatuses.OPEN ||
											data.status.value === orderStatuses.REFUNDED
										) || data.orderItems.length === 0
									}
									openModal={openModal}
									onEdit={onOrderItemEdit}
									isEditable={
										(item.product.modifierSections &&
											item.product.modifierSections.length !== 0) ||
										(item.orderItemModifiers.length > 0 &&
											(item.printStatus.value === printStatuses.NEW ||
												item.printStatus.value ===
													printStatuses.DONOTPRINT))
									}
									orderItemModifiers={item.orderItemModifiers}
									deleteOrderItem={deleteOrderItem}
									disabled={
										isLoading ||
										!(
											data.status.value === orderStatuses.OPEN ||
											data.status.value === orderStatuses.REFUNDED
										)
									}
									discountAllowed={discountAllowed}
									hasDiscountPermission={userHasPermission('apply_discounts')}
									hasEditPricePermission={userHasPermission('edit_price')}
									isOrderOpen={data.status.value === orderStatuses.OPEN}
									orderTaxRate={data.taxRate}
									clearOrderItemDiscount={clearOrderItemDiscount}
									disable={data.status.value === orderStatuses.OPEN}
									userHasPermission={userHasPermission}
									noPermission={noPermission}
								/>
							))}
							<PaymentsTitle
								isShow={data.invoices.length > 0 || data.authorizedPayment}
							/>
							{data.authorizedPayment && (
								<PaymentItem
									key={data.authorizedPayment.id}
									data={{
										...data.authorizedPayment,
										isAuthorizedPayment: true,
									}}
									onEditAuthorizedPayment={
										data.authorizedPayment.token
											? () =>
													saveOrder(
														afterSaveActions.AUTHORIZE_PAYMENT,
														null,
														data.authorizedPayment
													)
											: null
									}
									onCaptureAuthorizedPayment={() => {
										saveOrder(
											afterSaveActions.CAPTURE_AUTHORIZED_PAYMENT,
											null,
											data.authorizedPayment
										);
									}}
									onVoidAuthorizedPayment={() => {
										saveOrder(
											afterSaveActions.VOID_AUTHORIZED_PAYMENT,
											null,
											data.authorizedPayment
										);
									}}
								/>
							)}
							{data.invoices.map(i => {
								const payment = invoiceToPayment(i);
								return (
									<PaymentItem
										key={i.id}
										data={{
											...payment,
											amount: payment.amount - (payment.serviceFee || 0),
										}}
										onPrint={
											payment.status.value === paymentStatuses.PROCESSING
												? null
												: paymentPrint
										}
										onVoid={
											payment.isEditable ||
											payment.status.value === paymentStatuses.PROCESSING
												? () =>
														openModal({
															open: terminalModals.VOID_PAYMENT,
															invoice: i,
														})
												: null
										}
										onRefund={
											userHasPermission('issue_refunds') &&
											payment.paymentMethod.paymentType.value ===
												paymentTypes.CREDIT_CARD &&
											payment.creditCardPaymentStatus.value ===
												creditCardPaymentStatuses.CAPTURED
												? () =>
														openModal({
															open: terminalModals.REFUND_PAYMENT,
															invoice: i,
														})
												: null
										}
										onEdit={
											payment.isEditable
												? () =>
														openModal({
															open: terminalModals.PAYMENT,
															payment: i,
														})
												: null
										}
										isVoidable={userHasPermission('void_payments')}
										isEditable={userHasPermission('edit_finalized_payments')}
									/>
								);
							})}
						</OrderBody>
					</OrderTable>
				</Portlet.Body>
				<OrderNote note={data.note} />
				{data.seats !== null && data.seats !== 0 && (
					<Loading isLoading={isLoading} type='div'>
						<OrderSeats
							seats={data.seats}
							onClick={() => openModal({ open: terminalModals.SEATS })}
							disabled={!(data.status.value === orderStatuses.OPEN && !isLoading)}
						/>
					</Loading>
				)}
				<Portlet.Foot tall='sm' subClassName='align-items-stretch'>
					<div className='col-xl-6 col-lg-5 col-md-6 col-6'>
						<Loading isLoading={isLoading} type='button'>
							<Button
								className='h-100 sdms-fitText sdms-p0'
								label='dark'
								icon={customerData.icon}
								iconSize={36}
								block
								disabled={
									!(
										data.status.value === orderStatuses.OPEN ||
										data.status.value === orderStatuses.REFUNDED
									) ||
									data.id === null ||
									onPayWithBooking !== null
								}
								onClick={() =>
									openModal({
										open: data.vessel
											? terminalModals.VESSEL
											: terminalModals.CUSTOMER,
									})
								}
								noPermission={noPermission}>
								{customerData.label}
							</Button>
						</Loading>
					</div>
					<div className='col-xl-6 col-lg-7 col-md-6 col-6 sdms-t-orders__footer--info'>
						<div className='row sdms-fitText--lg sdms-font-bolder'>
							<div className='col'>
								{data.status.value === orderStatuses.OPEN ||
								data.status.value === orderStatuses.REFUNDED
									? 'Subtotal'
									: 'Total'}
							</div>
							<div className='col-auto text-right'>
								{data.status.value === orderStatuses.OPEN ||
								data.status.value === orderStatuses.REFUNDED
									? priceFormatter(data.subtotal)
									: priceFormatter(data.total)}
							</div>
						</div>
						{data.discount && (
							<div className='row sdms-fitText--lg sdms-font-bolder'>
								<div className='col'>Discount</div>
								<div className='col-auto text-right'>
									{priceFormatter(data.discountTotal)}
								</div>
							</div>
						)}
						<div className='row sdms-fitText--lg sdms-font-bolder'>
							<div className='col'>{getTotalLastRowText()}</div>
							<div className='col-auto text-right'>
								{priceFormatter(getTotalLastRowPrice())}
							</div>
						</div>
					</div>
				</Portlet.Foot>
				<Portlet.Foot border={false} tall='xs'>
					{(data.status.value === orderStatuses.OPEN ||
						data.status.value === orderStatuses.REFUNDED) && (
						<div className='col'>
							<Button
								className='btn-only-icon sdms-pl0-desktop sdms-pr0-desktop'
								label='info'
								icon='Printer'
								iconSize={36}
								block
								onClick={orderPrint}
								disabled={isLoading || data.orderItems.length === 0}
								noPermission={noPermission}
							/>
						</div>
					)}
					{isCreditCardEnabled &&
						!defaultCreditCard &&
						(data.status.value === orderStatuses.OPEN ||
							data.status.value === orderStatuses.REFUNDED) &&
						!onPayWithBooking && (
							<div className='col'>
								<Button
									className='btn-only-icon sdms-pl0-desktop sdms-pr0-desktop'
									design='success'
									icon='Credit-card'
									iconSize={36}
									block
									disabled={isLoading || data.total - data.amountInvoiced <= 0}
									onClick={() => saveOrder(afterSaveActions.CC_PAY)}
									noPermission={
										!userHasPermission('accept_payments') || noPermission
									}
								/>
							</div>
						)}
					{(data.status.value === orderStatuses.OPEN ||
						data.status.value === orderStatuses.REFUNDED) &&
						onPayWithBooking && (
							<div className='col'>
								<Button
									className='btn-only-icon sdms-pl0-desktop sdms-pr0-desktop'
									design='success'
									icon='Credit-card'
									iconSize={36}
									block
									disabled={isLoading || data.total - data.amountInvoiced <= 0}
									onClick={() => saveOrder(afterSaveActions.PAY)}
									noPermission={
										!userHasPermission('accept_payments') || noPermission
									}
								/>
							</div>
						)}
					{isCashEnabled &&
						defaultCreditCard &&
						(data.status.value === orderStatuses.OPEN ||
							data.status.value === orderStatuses.REFUNDED) && (
							<div className='col'>
								<Button
									className='btn-only-icon sdms-pl0-desktop sdms-pr0-desktop'
									design='success'
									icon='Dollar'
									iconSize={36}
									block
									disabled={isLoading || data.total - data.amountInvoiced <= 0}
									onClick={() => saveOrder(afterSaveActions.CASH_PAY)}
									noPermission={
										!userHasPermission('accept_payments') || noPermission
									}
								/>
							</div>
						)}

					{(data.status.value === orderStatuses.PARTIAL ||
						data.status.value === orderStatuses.PAID ||
						data.status.value === orderStatuses.FINALIZED) && (
						<PayButton
							className='col-xl-6 col-lg-5 col-md-6'
							color='info'
							priceAlign={false}
							text='Tip'
							icon='Piggy-bank'
							onClick={() => saveOrder(afterSaveActions.TIP_EDIT)}
							disabled={
								isLoading ||
								data.invoices.length === 0 ||
								(data.status.value === orderStatuses.FINALIZED &&
									!userHasPermission('edit_finalized_payments'))
							}
							price={priceFormatter(getTipSum())}
							isLoading={isLoading}
							noPermission={noPermission}
						/>
					)}
					{(data.status.value === orderStatuses.OPEN ||
						data.status.value === orderStatuses.PARTIAL) &&
						!onPayWithBooking && (
							<PayButton
								className='col-xl-6 col-lg-7 col-md-6'
								color='success'
								colorOpacity={false}
								text='Pay'
								icon={defaultCreditCard ? 'Credit-card' : 'Dollar'}
								onClick={() =>
									saveOrder(
										data.total - data.amountInvoiced <= 0
											? afterSaveActions.PAY_ZERO
											: afterSaveActions.PAY
									)
								}
								disabled={
									isLoading ||
									paymentType.length === 0 ||
									data.orderItems.length === 0
								}
								noPermission={!userHasPermission('accept_payments') || noPermission}
								price={priceFormatter(data.total - data.amountInvoiced)}
								isLoading={isLoading}
							/>
						)}
					{(data.status.value === orderStatuses.OPEN ||
						data.status.value === orderStatuses.PARTIAL) &&
						onPayWithBooking && (
							<PayButton
								className='col-xl-6 col-lg-7 col-md-6'
								color='success'
								colorOpacity={false}
								text='Add'
								icon='Bookings'
								onClick={onPayWithBooking}
								disabled={
									isLoading ||
									paymentType.length === 0 ||
									data.orderItems.length === 0
								}
								noPermission={!userHasPermission('accept_payments') || noPermission}
								price={priceFormatter(data.total - data.amountInvoiced)}
								isLoading={isLoading}
							/>
						)}
					{data.status.value === orderStatuses.PAID && (
						<PayButton
							className='col-xl-6 col-lg-7 col-md-6'
							color='success'
							text='Finalize'
							icon='Flag'
							onClick={() => saveOrder(afterSaveActions.FINALIZE)}
							disabled={isLoading || hasProcessingPayment()}
							noPermission={noPermission}
						/>
					)}
					{data.status.value === orderStatuses.FINALIZED && (
						<PayButton
							className='col-xl-6 col-lg-7 col-md-6'
							disabled
							onClick={() => {}}
							color='success'
							text='Finalized'
							icon='Flag'
							noPermission={noPermission}
						/>
					)}
					{data.status.value === orderStatuses.REFUNDED && (
						<PayButton
							color='success'
							text='Refund'
							icon='Undo'
							onClick={() => openModal({ open: terminalModals.PAY })}
							disabled={isLoading || data.total - data.amountInvoiced <= 0}
							price={priceFormatter(data.total)}
							isLoading={isLoading}
						/>
					)}
				</Portlet.Foot>
				<Portlet.Foot
					border={false}
					tall='xs'
					subClassName='justify-content-between sdms-fitText--sm'>
					<div className='col-auto'>
						Server
						<Loading isLoading={isLoading} type='bold'>
							<b className='sdms-ml-5'>{data.server && data.server.displayName}</b>
						</Loading>
					</div>
					{data.unit && (
						<div className='col-auto'>
							Table
							<Loading isLoading={isLoading} type='bold'>
								<b className='sdms-ml-5'>{data.unit.name}</b>
							</Loading>
						</div>
					)}
					<div className='col-auto'>
						Created
						<Loading isLoading={isLoading} type='bold' width={106}>
							<b className='sdms-ml-5'>{data && dateFormatter(data.timeCreated)}</b>
						</Loading>
					</div>
				</Portlet.Foot>
			</Portlet>
		</div>
	);
};
Order.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		parentOrder: PropTypes.object,
		orderItems: PropTypes.arrayOf(PropTypes.object),
		note: PropTypes.string,
		externalNote: PropTypes.string,
		seats: PropTypes.number,
		customer: PropTypes.object,
		discountTotal: PropTypes.number,
		subtotal: PropTypes.number,
		taxTotal: PropTypes.number,
		total: PropTypes.number,
		server: PropTypes.object,
		timeCreated: PropTypes.string,
		timeModified: PropTypes.string,
		unit: PropTypes.object,
		status: PropTypes.object,
		taxRate: PropTypes.object,
		amountInvoiced: PropTypes.number,
		discount: PropTypes.object,
		discountAmount: PropTypes.number,
		invoices: PropTypes.arrayOf(
			PropTypes.shape({
				customerSettlements: PropTypes.arrayOf(
					PropTypes.shape({
						remittance: PropTypes.shape({ paymentMethod: PropTypes.object }),
					})
				),
			})
		),
		authorizedPayment: PropTypes.object,
		vessel: PropTypes.object,
	}),
	orders: PropTypes.arrayOf(PropTypes.object).isRequired,
	openSplit: PropTypes.func,
	selectedOrderItems: PropTypes.arrayOf(PropTypes.number).isRequired,
	onSelectOrderItem: PropTypes.func,
	onSelectAllOrderItems: PropTypes.func,
	changeActiveOrder: PropTypes.func,
	saveOrder: PropTypes.func,
	isLoading: PropTypes.bool,
	userHasPermission: PropTypes.func,
	onOrderItemEdit: PropTypes.func,
	orderPrint: PropTypes.func,
	servicePrint: PropTypes.func,
	deleteOrderItem: PropTypes.func,
	getSendableOrderItems: PropTypes.func,
	openModal: PropTypes.func,
	isCreditCardEnabled: PropTypes.bool,
	isCashEnabled: PropTypes.bool,
	defaultCreditCard: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	paymentType: PropTypes.array,
	paymentPrint: PropTypes.func,
	enableOverlay: PropTypes.bool,
	onOverlayClick: PropTypes.func,
	discountAllowed: PropTypes.bool,
	clearDiscount: PropTypes.func,
	clearOrderItemDiscount: PropTypes.func,
	hasOrderChange: PropTypes.bool,
	closeModal: PropTypes.func,
	noPermission: PropTypes.bool,
	hasSideCar: PropTypes.bool,
	onPayWithBooking: PropTypes.func,
};
Order.defaultProps = {
	data: {
		id: null,
		name: '',
		parentOrder: null,
		orderItems: [],
		note: null,
		seats: 0,
		customer: null,
		discountTotal: null,
		subtotal: 0,
		taxTotal: 0,
		total: 0,
		server: null,
		timeCreated: null,
		timeModified: null,
		unit: null,
		status: { value: 'open' },
		taxRate: null,
		amountInvoiced: 0,
		invoices: [],
		authorizedPayment: null,
		discountAmount: 0,
		vessel: null,
	},
	openSplit: null,
	onSelectOrderItem: () => {},
	onSelectAllOrderItems: () => {},
	changeActiveOrder: () => {},
	saveOrder: () => {},
	isLoading: false,
	userHasPermission: () => false,
	onOrderItemEdit: () => {},
	orderPrint: () => {},
	servicePrint: () => {},
	deleteOrderItem: () => {},
	getSendableOrderItems: () => {},
	openModal: () => {},
	isCreditCardEnabled: false,
	isCashEnabled: false,
	defaultCreditCard: false,
	paymentType: [],
	paymentPrint: () => {},
	enableOverlay: false,
	onOverlayClick: () => {},
	discountAllowed: false,
	clearDiscount: null,
	clearOrderItemDiscount: null,
	hasOrderChange: false,
	closeModal: () => {},
	noPermission: null,
	hasSideCar: false,
	onPayWithBooking: null,
};

export default Order;
