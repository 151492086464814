import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import { required, uniqueFnc } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import apiCall, { modules, parseData } from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	addSuccessNotification,
	numberParser as _numberParser,
} from '../../../utils/helpers/helper';

import Input from '../../reusables/field/Input';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import Button from '../../reusables/element/Button';
import Radio from '../../reusables/field/Radio';
import SVGIcon from '../../reusables/element/SVGIcon';
import Toggle from '../../reusables/field/Toggle';

const TableForm = ({ data, dispatch, icons }) => {
	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isValid, setIsValid] = useState(false);

	const [submitButtonAttr, setSubmitButtonAttr] = useState({
		text: data.id ? process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT : 'Save & Add to Map',
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	});

	const tableIcons = ['Square', 'Circle', 'Hexagon'];

	const onFormChange = () => {
		setSubmitButtonAttr({
			text: data.id ? process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT : 'Save & Add to Map',
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});
	};

	const updateTableMap = useRef(false);

	const userContext = useContext(UserContext);

	const posModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.POS)
	);

	// custom number parser to handle '0.' value
	const numberParser = (isFloat, defaultValue = null) => {
		return v => {
			if (isFloat && v === '.') return 0.0;

			if (v !== 0 && (v === '' || !v)) return defaultValue;

			return isFloat ? parseFloat(v) : parseInt(v, 10);
		};
	};

	const numberFormat = value => {
		if (value === '.') return '0.';
		if (value === '' || !value) return '';

		const parts = value.toString().split('.');
		if (parts[1] && parts[1].length > 3) return parseFloat(value).toFixed(3);
		return value.toString();
	};

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[
			required,
			uniqueFnc('units', 'name', data.id, true, null, null, null, posModule.current.id),
		]
	);

	const [icon, iconOnChange, iconValRes, iconShowVal, setIconShowVal] = useField(
		data,
		'icon',
		onFormChange,
		[required],
		icons ? icons.filter(i => i.value === 'Square')[0] : {}
	);

	const [openNewOrderByDefault, openNewOrderByDefaultOnChange] = useField(
		data,
		'openNewOrderByDefault',
		onFormChange,
		[],
		false
	);

	const [priorityPrint, priorityPrintOnChange] = useField(
		data,
		'priorityPrint',
		onFormChange,
		[],
		false
	);

	const [posX, posXOnChange, posXValRes, posXShowVal, setPosXShowVal] = useField(
		data,
		'posX',
		onFormChange,
		[required],
		0,
		numberParser(true)
	);

	const [posY, posYOnChange, posYValRes, posYShowVal, setPosYShowVal] = useField(
		data,
		'posY',
		onFormChange,
		[required],
		0,
		numberParser(true)
	);

	const [width, widthOnChange, widthValRes, widthShowVal, setWidthShowVal] = useField(
		data,
		'width',
		onFormChange,
		[required],
		2,
		numberParser(true)
	);

	const [height, heightOnChange, heightValRes, heightShowVal, setHeightShowVal] = useField(
		data,
		'height',
		onFormChange,
		[required],
		1,
		numberParser(true)
	);

	const [rotation, rotationOnChange] = useField(
		data,
		'rotation',
		() => {},
		[],
		0,
		_numberParser(false)
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setIconShowVal();
			setPosXShowVal();
			setPosYShowVal();
			setWidthShowVal();
			setHeightShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setIconShowVal,
		setPosXShowVal,
		setPosYShowVal,
		setWidthShowVal,
		setHeightShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				iconValRes.isValid &&
				posXValRes.isValid &&
				posYValRes.isValid &&
				widthValRes.isValid &&
				heightValRes.isValid
		);
	}, [
		nameValRes.isValid,
		iconValRes.isValid,
		posXValRes.isValid,
		posYValRes.isValid,
		widthValRes.isValid,
		heightValRes.isValid,
		setIsValid,
	]);

	// update tableMapFrom on tableForm change.
	useEffect(() => {
		if (updateTableMap.current) {
			dispatch({
				type: 'change',
				payload: data,
			});
			updateTableMap.current = false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, icon, posX, posY, width, height, openNewOrderByDefault, priorityPrint, rotation]);

	const onFail = err => {
		addErrorNotification(err.toString());
		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});
	};

	const onSuccess = (res, isUpdate = false) => {
		addSuccessNotification(`${data.name} successfully ${isUpdate ? 'updated' : 'added'}.`);
		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVED_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVED_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVED_COLOR,
		});
		dispatch({
			type: 'save',
			payload: { ...res, posX, posY, width, height },
		});
	};

	const submit = () => {
		setIsSubmitted(true);

		if (!isValid) return;

		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});

		const formData = JSON.parse(JSON.stringify(data));

		// Parse data for api call.
		Object.keys(formData).forEach(field => {
			formData[field] = parseData(formData[field]);
		});

		// set outlet.
		formData.outlet = parseData(userContext.data.selectedOutlet, 'outlets');

		// set module.
		formData.module = parseData(posModule.current);

		if (data.id > 0) {
			apiCall('PUT', 'units', res => onSuccess(res, true), onFail, data.id, formData);
		} else {
			apiCall('POST', 'units', res => onSuccess(res), onFail, '', formData);
		}
	};

	const [settingsPositionToggle, setSettingsPositionToggle] = useState(true);

	useEffect(() => {
		if (data.isInMap) setSettingsPositionToggle(false);
	}, [data.isInMap]);

	return (
		<Portlet fluid='fluid'>
			<Portlet.Head>
				<Portlet.HeadLabelTitle
					portletIcon={data.icon && data.icon.value}
					smallTitle={settingsPositionToggle ? 'Settings' : 'Position'}>
					{name || 'New Table'}
				</Portlet.HeadLabelTitle>
				{!settingsPositionToggle && (
					<Portlet.HeadToolbarActions>
						<Button
							design='default'
							text='Edit'
							onClick={() => setSettingsPositionToggle(true)}
						/>
					</Portlet.HeadToolbarActions>
				)}
			</Portlet.Head>
			<Portlet.Body>
				<Section>
					<Section.Body>
						<FormGroup>
							{settingsPositionToggle ? (
								<>
									<FormField
										name='name'
										label='Name'
										id={data.id}
										valRes={nameValRes}
										showValidation={nameShowVal}
										col={12}
										loadingContainer>
										<Input
											type='text'
											placeholder='Name (Required)'
											value={name}
											onChange={e => {
												updateTableMap.current = true;
												nameOnChange(e);
											}}
											onBlur={setNameShowVal}
										/>
									</FormField>
									<FormField
										name='icon'
										label='Icon'
										id={data.id}
										valRes={iconValRes}
										showValidation={iconShowVal}
										col={12}>
										<Radio.Container
											isInline
											className='d-flex align-items-center sdms-ml-20'>
											{icons
												.filter(i => tableIcons.indexOf(i.value) > -1)
												.map(i => {
													return (
														<Radio
															key={i.id}
															checked={icon.id === i.id}
															id={i.id.toString()}
															name='icon'
															content={<SVGIcon name={i.value} />}
															className='sdms-radio--primary sdms-mt-5'
															onChange={() => {
																updateTableMap.current = true;
																iconOnChange({
																	target: {
																		name: 'icon',
																		value: i,
																	},
																});
															}}
														/>
													);
												})}
										</Radio.Container>
									</FormField>

									<FormField
										name='openNewOrderByDefault'
										label='Open New Order By Default'
										id={data.id}
										col={12}>
										<Toggle
											value={openNewOrderByDefault}
											onChange={e => {
												updateTableMap.current = true;
												openNewOrderByDefaultOnChange(e);
											}}
										/>
									</FormField>
									<FormField
										name='priorityPrint'
										label='Priority Print'
										id={data.id}
										col={12}>
										<Toggle
											value={priorityPrint}
											onChange={e => {
												updateTableMap.current = true;
												priorityPrintOnChange(e);
											}}
										/>
									</FormField>
								</>
							) : (
								<>
									<FormField
										name='posX'
										label='Pos X'
										id={data.id}
										valRes={posXValRes}
										showValidation={posXShowVal}
										col={6}>
										<Input
											type='text'
											withOutSpin
											min={0}
											placeholder='Pos X (Required)'
											value={numberFormat(posX)}
											onChange={({ target }) => {
												updateTableMap.current = true;
												posXOnChange({
													target: {
														name: 'posX',
														value:
															target.value !== '' &&
															parseFloat(target.value) > 100
																? 100
																: target.value,
													},
												});
											}}
											onBlur={setPosXShowVal}
											prependIcon='Arrow-from-left'
											append='%'
											pattern={process.env.REACT_APP_PRICE_PATTERN}
										/>
									</FormField>
									<FormField
										name='posY'
										label='Pos Y'
										id={data.id}
										valRes={posYValRes}
										showValidation={posYShowVal}
										col={6}>
										<Input
											type='text'
											withOutSpin
											min={0}
											placeholder='Pos Y (Required)'
											value={numberFormat(posY)}
											onChange={({ target }) => {
												updateTableMap.current = true;
												posYOnChange({
													target: {
														name: 'posY',
														value:
															target.value !== '' &&
															parseFloat(target.value) > 100
																? 100
																: target.value,
													},
												});
											}}
											onBlur={setPosYShowVal}
											prependIcon='Arrow-from-top'
											append='%'
											pattern={process.env.REACT_APP_PRICE_PATTERN}
										/>
									</FormField>
									<FormField
										name='width'
										label='Width'
										id={data.id}
										valRes={widthValRes}
										showValidation={widthShowVal}
										col={6}>
										<Input
											type='text'
											withOutSpin
											min={0}
											placeholder='Width (Required)'
											value={numberFormat(width)}
											onChange={({ target }) => {
												updateTableMap.current = true;
												widthOnChange({
													target: { name: 'width', value: target.value },
												});
											}}
											onBlur={setWidthShowVal}
											prependIcon='Arrows-h'
											appendIcon='Crop'
											appendIconColor='var(--gray)'
											pattern={process.env.REACT_APP_PRICE_PATTERN}
										/>
									</FormField>
									<FormField
										name='height'
										label='Height'
										id={data.id}
										valRes={heightValRes}
										showValidation={heightShowVal}
										col={6}>
										<Input
											type='text'
											withOutSpin
											min={0}
											placeholder='Height (Required)'
											value={numberFormat(height)}
											onChange={({ target }) => {
												updateTableMap.current = true;
												heightOnChange({
													target: { name: 'height', value: target.value },
												});
											}}
											onBlur={setHeightShowVal}
											prependIcon='Arrows-v'
											appendIcon='Crop'
											appendIconColor='var(--gray)'
											pattern={process.env.REACT_APP_PRICE_PATTERN}
										/>
									</FormField>
									<FormField
										name='rotation'
										label='Rotation'
										description={`${rotation || 0} with 360 deg.`}
										id={data.id}
										col={12}>
										<Input
											type='range'
											min={0}
											max={360}
											placeholder='rotation (Required)'
											value={rotation.toString()}
											onChange={e => {
												updateTableMap.current = true;
												rotationOnChange({
													target: { value: e.target.value },
												});
											}}
										/>
									</FormField>
								</>
							)}
						</FormGroup>
					</Section.Body>
				</Section>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='default'
						text='Cancel'
						size='sm'
						elevate
						onClick={() => dispatch({ type: 'cancel' })}
					/>
				</div>
				{data.isInMap && (
					<div className='col-auto'>
						<Button
							design='brand'
							outline
							icon='Undo'
							size='sm'
							elevate
							text='Remove'
							onClick={() => dispatch({ type: 'remove', payload: data.id })}
						/>
					</div>
				)}
				{settingsPositionToggle && (
					<div className='col-auto'>
						<Button
							label={submitButtonAttr.color}
							text={submitButtonAttr.text}
							icon={submitButtonAttr.icon}
							className={classNames({
								'sdms-fading-dots':
									submitButtonAttr.text ===
									process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
							})}
							size='sm'
							elevate
							onClick={submit}
						/>
					</div>
				)}
				{!settingsPositionToggle && (
					<div className='col-auto'>
						<Button
							label='brand'
							size='sm'
							elevate
							text='OK'
							onClick={() => dispatch({ type: 'closeEdit' })}
						/>
					</div>
				)}
			</Portlet.Foot>
		</Portlet>
	);
};
TableForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		posX: PropTypes.number,
		posY: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number,
		rotation: PropTypes.number,
		isInMap: PropTypes.bool,
		icon: PropTypes.object,
		openNewOrderByDefault: PropTypes.bool,
		priorityPrint: PropTypes.bool,
	}),
	icons: PropTypes.arrayOf(PropTypes.object).isRequired,
	dispatch: PropTypes.func.isRequired,
};
TableForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		posX: 0,
		posY: 0,
		width: 120,
		height: 60,
		rotation: 0,
		isInMap: false,
		icon: { value: 'Square' },
		openNewOrderByDefault: false,
		priorityPrint: false,
	},
};

export default TableForm;
