import React, { useContext, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { useLocation } from 'react-router-dom';

import UserContext from '../../../app/contexts/UserContext';
import useModal from '../../../utils/hooks/useModal';
import apiCall from '../../../utils/helpers/apiCall';
import { addErrorNotification } from '../../../utils/helpers/helper';

import Dropdown from './Dropdown';
import Portal from '../layout/Portal';
import Portlet from '../layout/Portlet';
import Button from './Button';
import DialogBox from './DialogBox';
import { getModuleByPath } from '../../../utils/helpers/reusable';

const modals = {
	IMPORT: 'import',
	IMPORT_RESULT: 'importResult',
};

const ImportButton = ({ route, text, reload, permission }) => {
	const userContext = useContext(UserContext);

	const inputRef = useRef();

	const [isGetting, setIsGetting] = useState(false);

	const [isImporting, setIsImporting] = useState(false);

	const [modal, openModal, closeModal] = useModal();

	const location = useLocation();

	const module = useMemo(() => {
		return getModuleByPath(location.pathname);
	}, [location]);

	const buttonText = useMemo(() => {
		if (isGetting) return 'Getting...';

		if (isImporting) return 'Importing...';

		return 'Import';
	}, [isGetting, isImporting]);

	const onGetTemplate = () => {
		setIsGetting(true);
		apiCall(
			'GET',
			route,
			() => {
				setIsGetting(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsGetting(false);
			}
		);
	};

	const onImport = ({ target }) => {
		setIsImporting(true);

		const formData = new FormData();

		formData.append('file', target.files[0]);

		formData.append('outletId', userContext.data.selectedOutlet.id);

		formData.append('module', module);

		apiCall(
			'POST',
			route,
			res => {
				openModal({ open: modals.IMPORT_RESULT, result: res });

				if (res.imported) reload();

				setIsImporting(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsImporting(false);
			},
			'',
			formData
		);
	};

	if (!userContext.hasPermission(permission)) return null;

	return (
		<>
			<Dropdown
				title={buttonText}
				icon='Upload'
				className='sdms-header__topbar-item align-items-center sdms-mr-5'
				arrow
				color='brand'
				size='sm'
				outline
				disabled={isGetting || isImporting}>
				<Dropdown.Item
					icon='Upload'
					onClick={() => inputRef.current.click()}
					disabled={isGetting || isImporting}>
					Import
				</Dropdown.Item>
				<Dropdown.Item
					icon='Files'
					onClick={onGetTemplate}
					disabled={isGetting || isImporting}>
					Get Template
				</Dropdown.Item>
			</Dropdown>
			<input
				ref={inputRef}
				type='file'
				name='file'
				accept='.xlsx'
				className='sdms-hidden'
				onChange={({ target }) => openModal({ open: modals.IMPORT, target })}
			/>
			<Portal>
				<Popup
					open={modal.open === modals.IMPORT_RESULT}
					closeOnDocumentClick={false}
					lockScroll
					modal
					onClose={closeModal}
					contentStyle={{
						padding: 0,
						background: 'unset',
						border: 'unset',
						maxWidth: '640px',
					}}>
					<Portlet>
						<Portlet.Head>
							<Portlet.HeadLabelTitle portletIcon='Upload'>
								{text} Import
							</Portlet.HeadLabelTitle>
						</Portlet.Head>
						<Portlet.Body>
							{modal?.result?.imported > 0 && (
								<h6>
									<span className='sdms-color-success sdms-font-boldest'>
										{modal.result.imported}
									</span>{' '}
									{text.toLowerCase()}(s) imported.
								</h6>
							)}
							{modal?.result?.alreadyExists > 0 && (
								<h6>
									<span className='sdms-color-warning sdms-font-boldest'>
										{modal.result.alreadyExists}
									</span>{' '}
									{text.toLowerCase()}(s) already exits.
								</h6>
							)}
							{(modal?.result?.errors || []).length > 0 && (
								<>
									<h6>
										<span className='sdms-color-danger sdms-font-boldest'>
											{modal.result.errors.length}
										</span>{' '}
										error(s) found:
									</h6>
									{modal.result.errors.map(e => (
										<div className='col-12'>{e}</div>
									))}
								</>
							)}
						</Portlet.Body>
						<Portlet.Foot className='sdms-align-left' tall='sm'>
							<div className='col'>
								<Button
									design='clean'
									text='Close'
									icon='Error-circle'
									size='sm'
									elevate
									onClick={closeModal}
								/>
							</div>
						</Portlet.Foot>
					</Portlet>
				</Popup>
			</Portal>
			<DialogBox
				open={modal.open === modals.IMPORT}
				title=''
				content='Are you sure about importing?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					text='No, cancel'
					design='clean'
					icon='Angle-left-circle'
					onClick={closeModal}
				/>
				<Button
					className='sdms-font-transform-c'
					text='Yes, Import'
					label='brand'
					icon='Upload'
					onClick={() => {
						onImport(modal);
						closeModal();
					}}
				/>
			</DialogBox>
		</>
	);
};

ImportButton.propTypes = {
	route: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	reload: PropTypes.func,
	permission: PropTypes.string,
};

ImportButton.defaultProps = {
	reload: () => {},
	permission: '',
};

export default ImportButton;
