import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import moment from 'moment';
import classNames from 'classnames';
import Decimal from 'decimal.js';
import SVGIcon from '../element/SVGIcon';

import {
	addCustomNotification,
	addErrorNotification,
	convertDateToUTC,
	generateId,
	priceFormatter,
	addFloats,
	subFloats,
	mulFloats,
	divFloats,
} from '../../../utils/helpers/helper';
import useField from '../../../utils/hooks/useField';
import UserContext from '../../../app/contexts/UserContext';

import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import { ListBody, ListTable } from '../template/List';
import Input from '../field/Input';
import Toggle from '../field/Toggle';
import Selects from '../field/Selects';
import FormField from '../template/FormField';
import DatePicker from '../field/DatePicker';

const InvoiceItemDeferredScheduleItem = ({ data, didPost, onChange, limit }) => {
	const userContext = useContext(UserContext);

	const [amount, amountOnChange] = useField(data, 'amount', () => {});

	const [posted, postedOnChange] = useField(data, 'posted', () => {}, [], false);

	const [isSettingNegative, setIsSettingNegative] = useState(false);

	return (
		<tr>
			<td className='sdms-deferred-schedule-col'>
				<FormField name='scheduledDate' id={data.id} inFormDesign={false} isLast>
					<DatePicker
						id='scheduledDate'
						type='calendar'
						placeholder='Scheduled Date'
						value={
							data.scheduledDate !== null
								? convertDateToUTC(moment(data.scheduledDate).toDate())
								: null
						}
						onChange={() => {}}
						disabled
					/>
				</FormField>
			</td>
			<td className='sdms-deferred-schedule-col'>
				<FormField name='amount' id={data.id} inFormDesign={false} isLast>
					<Input
						value={!isSettingNegative ? amount : '-'}
						onChange={e => {
							let _amount = e.target.value;

							if (_amount === '-') {
								_amount = '-1';

								setIsSettingNegative(true);
							} else {
								setIsSettingNegative(false);
							}

							if (
								_amount &&
								((limit > 0 && parseFloat(_amount) > limit) ||
									(limit < 0 && parseFloat(_amount) < limit))
							) {
								_amount = limit;
							}

							onChange({ amount: _amount });
							amountOnChange({ target: { value: _amount } });
						}}
						disabled={
							didPost
								? !userContext.hasPermission('edit_deferred_posted_transactions')
								: !userContext.hasPermission('maintain_deferred_schedule')
						}
						pattern={
							limit >= 0
								? process.env.REACT_APP_TWO_DECIMAL_PRICE_PATTERN
								: process.env.REACT_APP_TWO_DECIMAL_NEGATIVE_PRICE_PATTERN
						}
					/>
				</FormField>
			</td>
			<td className='sdms-deferred-schedule-col'>
				<FormField name='posted' id={data.id} inFormDesign={false} isLast>
					<Toggle onChange={postedOnChange} value={posted} disabled />
				</FormField>
			</td>
		</tr>
	);
};

InvoiceItemDeferredScheduleItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	didPost: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	limit: PropTypes.number.isRequired,
};

const InvoiceItemDeferredSchedule = ({ invoice, invoiceItem, onCancel, onSave, accounts }) => {
	const userContext = useContext(UserContext);

	const [invoiceItemDeferredIncomes, setInvoiceItemDeferredIncomes] = useState([]);

	const [deferredIncomeAccount, setDeferredIncomeAccount] = useState(
		invoiceItem.deferredIncomeAccount || null
	);

	const [enableDeferredIncome, setEnableDeferredIncome] = useState(
		invoiceItem.enableDeferredIncome || false
	);

	const [deferredIncomeSalesAccount, setDeferredIncomeSalesAccount] = useState(
		invoiceItem.deferredIncomeSalesAccount || null
	);

	const [isAccountOpen, setIsAccountOpen] = useState(
		!deferredIncomeAccount || !deferredIncomeSalesAccount
	);

	const [key, setKey] = useState(Date.now());

	const getLimit = (itemDeferredIncome = null) => {
		if (!invoiceItem) return 0;

		let remainingAmount = invoiceItem.subtotal;

		invoiceItemDeferredIncomes.forEach(iidi => {
			if (iidi.amount) remainingAmount = subFloats(remainingAmount, iidi.amount);
		});

		if (itemDeferredIncome)
			remainingAmount = addFloats(remainingAmount, itemDeferredIncome.amount || 0);

		return remainingAmount;
	};

	const onGenerateAmounts = () => {
		const numPeriods = invoiceItemDeferredIncomes.reduce(
			(sum, iidi) => sum + iidi.groupDayCount,
			0
		);

		if (
			invoiceItemDeferredIncomes.reduce(
				(partialSum, a) => addFloats(partialSum, a.amount ? parseFloat(a.amount) : 0),
				0
			) !== 0 ||
			!numPeriods
		)
			return;

		const periodAmount = new Decimal(divFloats(invoiceItem.subtotal, numPeriods))
			.toDecimalPlaces(2, Decimal.ROUND_FLOOR)
			.toNumber();

		setInvoiceItemDeferredIncomes(
			invoiceItemDeferredIncomes
				.sort((a, b) => {
					return moment(a.scheduledDate).isAfter(moment(b.scheduledDate)) ? 1 : -1;
				})
				.map((itemIncome, index) => {
					if (index === 0) {
						return {
							...itemIncome,
							amount: addFloats(
								mulFloats(periodAmount, itemIncome.groupDayCount),
								subFloats(invoiceItem.subtotal, mulFloats(periodAmount, numPeriods))
							),
						};
					}

					return {
						...itemIncome,
						amount: mulFloats(periodAmount, itemIncome.groupDayCount),
					};
				})
		);

		setKey(Date.now());
	};

	useEffect(() => {
		if (!invoice || !invoiceItem) {
			setInvoiceItemDeferredIncomes([]);
		} else {
			const _invoiceItemDeferredIncomes = [];

			if (invoiceItem.itemDeferredIncomes)
				invoiceItem.itemDeferredIncomes.forEach(idi => {
					const invoiceDeferredIncome = invoice.deferredIncomes.find(
						di => di.id === idi.invoiceDeferredIncome.id
					);

					if (invoiceDeferredIncome)
						_invoiceItemDeferredIncomes.push({
							...idi,
							scheduledDate: invoiceDeferredIncome.scheduledDate,
							posted: invoiceDeferredIncome.posted,
							deferredIncomeId: invoiceDeferredIncome.id,
						});
				});

			invoice.deferredIncomes
				.filter(
					di =>
						_invoiceItemDeferredIncomes.filter(
							iidi => iidi.scheduledDate === di.scheduledDate
						).length === 0
				)
				.forEach(di => {
					_invoiceItemDeferredIncomes.push({
						id: generateId(_invoiceItemDeferredIncomes),
						amount: '',
						scheduledDate: di.scheduledDate,
						posted: di.posted,
						deferredIncomeId: di.id,
						groupDayCount: di.groupDayCount,
					});
				});

			_invoiceItemDeferredIncomes.sort((a, b) => {
				return moment(a.scheduledDate).isAfter(moment(b.scheduledDate)) ? 1 : -1;
			});

			setInvoiceItemDeferredIncomes(_invoiceItemDeferredIncomes);
		}
	}, [invoice, invoiceItem]);

	return (
		<div className='sdms-quick-panel sdms-quick-panel--right sdms-quick-panel--on'>
			<Portlet hasFrame fluid='fluid' className='sdms-marginless h-100'>
				<Portlet.Head>
					<Portlet.HeadLabelTitle portletIcon='Timer'>
						Deferred Schedule - Item
					</Portlet.HeadLabelTitle>
					<Portlet.HeadToolbarActions>
						<Toggle
							value={enableDeferredIncome}
							onChange={e => setEnableDeferredIncome(e.target.value)}
							disabled={
								invoiceItemDeferredIncomes.filter(i => i.posted).length > 0 &&
								!userContext.hasPermission('edit_deferred_posted_transactions')
							}
						/>
					</Portlet.HeadToolbarActions>
				</Portlet.Head>
				<Portlet.Body>
					<Portlet
						isCollapse
						isOpen={isAccountOpen}
						hasFrame
						className='flex-grow-0 sdms-min-h-fit-content'>
						<Portlet.Head>
							<Portlet.HeadLabel>
								<h3 className='sdms-portlet__head-title'>Accounts</h3>
							</Portlet.HeadLabel>
							<Portlet.HeadToolbarActions>
								<SVGIcon
									name='Angle-right'
									className={classNames(
										'sdms-transition',
										'sdms-cursor--pointer',
										{
											'sdms-rotate--90': isAccountOpen,
										}
									)}
									onClick={() => setIsAccountOpen(!isAccountOpen)}
								/>
							</Portlet.HeadToolbarActions>
						</Portlet.Head>
						<Portlet.Body>
							<table className='table sdms-deferred-schedule-table'>
								<tr>
									<td className='sdms-deferred-schedule-col'>
										<Selects
											options={accounts}
											placeholder='Deferred Income Account'
											value={deferredIncomeAccount}
											onChange={e => setDeferredIncomeAccount(e.target.value)}
											disabled={
												invoiceItemDeferredIncomes.filter(i => i.posted)
													.length > 0 &&
												!userContext.hasPermission(
													'edit_deferred_posted_transactions'
												)
											}
										/>
									</td>
								</tr>
								<tr>
									<td className='sdms-deferred-schedule-col'>
										<Selects
											options={accounts}
											placeholder='Deferred Income Sales Account'
											value={deferredIncomeSalesAccount}
											onChange={e =>
												setDeferredIncomeSalesAccount(e.target.value)
											}
											disabled={
												invoiceItemDeferredIncomes.filter(i => i.posted)
													.length > 0 &&
												!userContext.hasPermission(
													'edit_deferred_posted_transactions'
												)
											}
										/>
									</td>
								</tr>
								<tr>
									<td className='sdms-deferred-schedule-col sdms-align-right'>
										<Button
											label='info'
											icon='Clipboard-list'
											text='Generate Amounts'
											size='sm'
											onClick={onGenerateAmounts}
											disabled={
												!userContext.hasPermission(
													'maintain_deferred_schedule'
												) ||
												invoiceItemDeferredIncomes.reduce(
													(partialSum, a) =>
														addFloats(
															partialSum,
															a.amount ? parseFloat(a.amount) : 0
														),
													0
												) !== 0
											}
										/>
									</td>
								</tr>
							</table>
						</Portlet.Body>
					</Portlet>
					<ListBody
						className='table--everytime--scroll sdms-portlet__body--fit'
						responsive='scroll'>
						<ListTable>
							<colgroup>
								<col />
								<col />
								<col />
							</colgroup>
							<thead>
								<tr>
									<th>Dates</th>
									<th>Amount</th>
									<th>Posted</th>
								</tr>
							</thead>
							<tbody>
								{invoiceItemDeferredIncomes.map((iidi, index) => (
									<InvoiceItemDeferredScheduleItem
										key={`${key}${iidi.id}`}
										data={iidi}
										didPost={iidi.posted}
										onChange={d => {
											setInvoiceItemDeferredIncomes(
												update(invoiceItemDeferredIncomes, {
													[index]: { $merge: d },
												})
											);
										}}
										limit={getLimit(iidi)}
									/>
								))}
							</tbody>
						</ListTable>
					</ListBody>
				</Portlet.Body>
				<Portlet.Foot tall='sm'>
					<table className='table sdms-deferred-schedule-table'>
						<tr>
							<td className='sdms-deferred-schedule-col'>
								<Button
									design='clean'
									text='Cancel'
									icon='Error-circle'
									size='sm'
									elevate
									onClick={onCancel}
								/>
							</td>
							<td className='sdms-deferred-schedule-col'>
								<Input
									value={priceFormatter(
										invoiceItemDeferredIncomes.reduce(
											(partialSum, a) =>
												partialSum + (a.amount ? parseFloat(a.amount) : 0),
											0
										)
									)}
									onChange={() => {}}
									disabled
								/>
							</td>
							<td className='sdms-deferred-schedule-col sdms-align-right'>
								<Button
									className='sdms-mw-100'
									label='brand'
									text='Update'
									icon='Save'
									size='sm'
									onClick={() => {
										if (
											invoiceItemDeferredIncomes.reduce(
												(partialSum, a) =>
													addFloats(
														partialSum,
														a.amount ? parseFloat(a.amount) : 0
													),
												0
											) !== invoiceItem.subtotal
										) {
											addErrorNotification(
												`Sum of deferred incomes must be equal to item subtotal ${priceFormatter(
													invoiceItem.subtotal
												)}`
											);
											return;
										}

										if (deferredIncomeAccount === null) {
											addErrorNotification(`Income Account is required`);
											return;
										}

										if (deferredIncomeSalesAccount === null) {
											addErrorNotification(
												`Income Sales Account is required`
											);
											return;
										}

										addCustomNotification(
											'',
											'Invoice must be saved to apply changes',
											'',
											'info'
										);

										onSave(
											enableDeferredIncome,
											deferredIncomeAccount,
											deferredIncomeSalesAccount,
											invoiceItemDeferredIncomes
										);
									}}
								/>
							</td>
						</tr>
					</table>
				</Portlet.Foot>
			</Portlet>
		</div>
	);
};
InvoiceItemDeferredSchedule.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	invoice: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	invoiceItem: PropTypes.object,
	onCancel: PropTypes.func,
	accounts: PropTypes.arrayOf(PropTypes.object),
	onSave: PropTypes.func,
};

InvoiceItemDeferredSchedule.defaultProps = {
	invoice: null,
	invoiceItem: null,
	onCancel: () => {},
	accounts: [],
	onSave: () => {},
};

export default InvoiceItemDeferredSchedule;
