import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Badge from './Badge';
import FormField from '../template/FormField';
import Toggle from '../field/Toggle';

const WaitListIgnoreCapacityToggle = ({
	isWaitList,
	ignoreCapacity,
	onIsWaitListChange,
	onIgnoreCapacityChange,
	col,
	disable,
	disableWaitList,
	disableIgnoreCapacity,
}) => {
	const [showIgnoreCapacityToggle, setShowIgnoreCapacityToggle] = useState(false);

	useEffect(() => {
		if (ignoreCapacity && !showIgnoreCapacityToggle) setShowIgnoreCapacityToggle(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ignoreCapacity]);

	useEffect(() => {
		setShowIgnoreCapacityToggle(!disableIgnoreCapacity && disableWaitList);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disableWaitList, disableIgnoreCapacity]);

	return (
		<FormField
			name='waitListIgnoreCapacity'
			label={
				<>
					{showIgnoreCapacityToggle ? 'Ignore Capacity' : 'Waitlist'}
					{!(disableIgnoreCapacity || disableWaitList) && (
						<Badge
							className='sdms-cursor--pointer sdms-ml-10'
							design={disableWaitList || disableIgnoreCapacity ? 'dark' : 'brand'}
							isInline
							isElevate
							onMouseDown={() => {
								if (disableWaitList || disableIgnoreCapacity) return;
								setShowIgnoreCapacityToggle(!showIgnoreCapacityToggle);
								onIsWaitListChange(false);
								onIgnoreCapacityChange(false);
							}}>
							{showIgnoreCapacityToggle ? 'Waitlist' : 'Capacity'}
						</Badge>
					)}
				</>
			}
			labelClassName='d-flex'
			id='waitListIgnoreCapacity'
			customFieldId='waitListIgnoreCapacityInput'
			colMd={col}>
			<Toggle
				value={showIgnoreCapacityToggle ? ignoreCapacity : isWaitList}
				onChange={e => {
					if (showIgnoreCapacityToggle) onIgnoreCapacityChange(e.target.value);
					else onIsWaitListChange(e.target.value);
				}}
				disabled={
					disable ||
					(!showIgnoreCapacityToggle && disableWaitList) ||
					(showIgnoreCapacityToggle && disableIgnoreCapacity)
				}
			/>
		</FormField>
	);
};

WaitListIgnoreCapacityToggle.propTypes = {
	isWaitList: PropTypes.bool.isRequired,
	ignoreCapacity: PropTypes.bool.isRequired,
	onIsWaitListChange: PropTypes.func.isRequired,
	onIgnoreCapacityChange: PropTypes.func.isRequired,
	col: PropTypes.number,
	disable: PropTypes.bool,
	disableWaitList: PropTypes.bool,
	disableIgnoreCapacity: PropTypes.bool,
};

WaitListIgnoreCapacityToggle.defaulProps = {
	col: 2,
	disable: false,
	disableWaitList: false,
	disableIgnoreCapacity: false,
};

export default WaitListIgnoreCapacityToggle;
