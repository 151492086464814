import { useContext } from 'react';
import moment from 'moment-timezone';
import UserContext from '../../app/contexts/UserContext';

const useDate = () => {
	const userContext = useContext(UserContext);

	const dateFormatter = (datetime, withTime = true, format = null, timezone = null) => {
		const m = moment.tz(datetime, timezone || userContext.data.selectedOutlet.timezone?.value);

		if (format) return m.format(format);

		// if (withTime)
		// 	return m.format(
		// 		moment().format('Z') === m.format('Z')
		// 			? process.env.REACT_APP_DATETIME_FORMAT
		// 			: process.env.REACT_APP_DATETIME_FORMAT_WITH_TIMEZONE
		// 	);

		return m.format(
			withTime ? process.env.REACT_APP_DATETIME_FORMAT : process.env.REACT_APP_DATE_FORMAT
		);
	};

	const utcDateFormatter = (datetime, withTime = true, format = null) =>
		dateFormatter(datetime, withTime, format, 'UTC');

	const parseDatePickerValue = (datetime, timezone = null) => {
		if (!datetime) return null;

		const m = moment.tz(datetime, timezone || userContext.data.selectedOutlet.timezone?.value);

		return new Date(
			m.year(),
			m.month(),
			m.date(),
			m.hours(),
			m.minutes(),
			m.seconds(),
			m.milliseconds()
		);
	};

	const parseDatePickerChange = (date, timezone = null, toISO = true) => {
		if (!date) return null;

		const local = moment()
			.utc(false)
			.year(date.getFullYear())
			.month(date.getMonth())
			.date(date.getDate());

		const outlet = moment.tz(
			local.toISOString(),
			timezone || userContext.data.selectedOutlet.timezone?.value
		);

		return toISO ? outlet.toISOString() : outlet.toString();
	};

	const parseReservationDatePickerValue = reservationDateTime =>
		parseDatePickerValue(reservationDateTime, 'UTC');

	const parseReservationDatePickerChange = (reservationDateTime, date) =>
		moment(reservationDateTime)
			.utc(false)
			.year(date.getFullYear())
			.month(date.getMonth())
			.date(date.getDate())
			.toISOString();

	const parseReservationTimePickerValue = reservationDateTime =>
		moment(reservationDateTime).utc(false);

	const parseReservationTimePickerChange = (reservationDateTime, time) => {
		const reservationDate = moment(reservationDateTime)
			.utc(false)
			.hour(0)
			.minute(0);

		if (time) reservationDate.hour(time.hour()).minute(time.minute());

		return reservationDate.toISOString();
	};

	return [
		dateFormatter,
		utcDateFormatter,
		parseDatePickerValue,
		parseDatePickerChange,
		parseReservationDatePickerValue,
		parseReservationDatePickerChange,
		parseReservationTimePickerValue,
		parseReservationTimePickerChange,
	];
};

export default useDate;
