import React from 'react';
import PropTypes from 'prop-types';

import useOutlet from '../../../utils/hooks/useOutlet';
import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';

import FormContainer from '../../reusables/template/FormContainer';
import UnitForm from './UnitForm';

const UnitEdit = ({ match }) => {
	const [outletKey] = useOutlet(true);

	const pages = usePages();

	return (
		<FormContainer
			key={outletKey}
			dataId={match.params.id}
			dataName='bookingUnits'
			fields={<UnitForm />}
			lists={['bookingTypeBookings', 'unitGroups']}
			pageTitle={pages.booking.settings.units.text}
			icon={pages.booking.settings.units.icon}
			module={modules.BOOKINGS}
		/>
	);
};

UnitEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default UnitEdit;
