import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Input from '../field/Input';

const TouchSpin = ({
	id,
	className,
	value,
	placeholder,
	onChange,
	minusOnClick,
	plusOnClick,
	disableInput,
}) => (
	<div
		id={id}
		className={classNames(
			className,
			'input-group',
			'bootstrap-touchspin',
			'bootstrap-touchspin-injected'
		)}>
		<span className='input-group-btn input-group-prepend'>
			<button
				className='btn btn-secondary bootstrap-touchspin-down'
				type='button'
				onClick={minusOnClick}>
				-
			</button>
		</span>
		<Input
			value={value}
			onChange={onChange}
			type='text'
			placeholder={placeholder}
			pattern={process.env.REACT_APP_INTEGER_PATTERN}
			withOutSpin
			className='text-center'
			disable={disableInput}
		/>
		<span className='input-group-btn input-group-append'>
			<button
				className='btn btn-secondary bootstrap-touchspin-up'
				type='button'
				onClick={plusOnClick}>
				+
			</button>
		</span>
	</div>
);

TouchSpin.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	minusOnClick: PropTypes.func.isRequired,
	plusOnClick: PropTypes.func.isRequired,
	disableInput: PropTypes.bool,
};

TouchSpin.defaultProps = {
	id: null,
	placeholder: null,
	className: null,
	disableInput: false,
};

export default TouchSpin;
