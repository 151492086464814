import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
	dateFormatter,
	durationFormatter,
	priceFormatter,
	getReportQueryFields,
	getReportPath,
	getReportText,
	numberFormat,
} from '../../../utils/helpers/helper';
import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';

import ReportContainer from '../../reusables/template/ReportContainer';

const DateCell = ({ data, field }) => {
	return data[field] ? dateFormatter(data[field], false) : '';
};

const TimeCell = ({ data, field }) => {
	if (data.titleLine && data[field]) return data[field];
	if (data[field]) return dateFormatter(data[field]);
	return '';
};

const DurationCell = ({ data, field }) => {
	return data[field] ? durationFormatter(data[field]) : '';
};

const PriceCell = ({ data, field }) => {
	if (data[field]) return priceFormatter(data[field]);
	return '';
};

const ReportsOutput = () => {
	const historyLocation = useLocation();
	const { report } = historyLocation.state ? historyLocation.state : { report: null };

	const headerContext = useContext(HeaderContext);

	const fieldFormat = fieldName => {
		const field = report.reportFields.find(a => a.dataSetField.alias === fieldName);
		return field && field.dataSetField.fieldFormat ? field.dataSetField.fieldFormat.value : '';
	};

	const reportRender = (data, id) => {
		switch (fieldFormat(id)) {
			case 'date':
				return DateCell({ data, field: id });
			case 'datetime':
				return TimeCell({ data, field: id });
			case 'duration':
				return DurationCell({ data, field: id });
			case 'price':
				return PriceCell({ data, field: id });
			default:
				return data[id] || '';
		}
	};

	const columnOptions = () => {
		return report.reportFields
			.sort((a, b) => ((a.sortOrder || 0) < (b.sortOrder || 0) ? -1 : 1))
			.map(a => {
				return {
					sortNum: a.sortOrder,
					displayName: a.dataSetField.label,
					id: a.dataSetField.alias,
					...(report.subtotal &&
						report.subtotal.alias === a.dataSetField.alias && {
							subtotal: report.subtotal.alias,
						}),
					...(!a.dataSetField.fieldGroup.canSum && { groupLabel: a.dataSetField.label }),
					...(a.dataSetField.fieldFormat &&
						a.dataSetField.fieldFormat.value === 'datetime' && {
							cellComponent: <TimeCell field={a.dataSetField.alias} />,
						}),
					...(a.dataSetField.fieldFormat &&
						(a.dataSetField.fieldFormat.value === 'datetime' ||
							a.dataSetField.fieldFormat.alias === 'date') && { type: 'date' }),
					...(a.dataSetField.fieldFormat &&
						a.dataSetField.fieldFormat.value === 'date' && {
							cellComponent: <DateCell field={a.dataSetField.alias} />,
						}),
					...(a.dataSetField.fieldFormat &&
						a.dataSetField.fieldFormat.value === 'duration' && {
							cellComponent: <DurationCell field={a.dataSetField.alias} />,
						}),
					...(a.dataSetField.fieldFormat &&
						a.dataSetField.fieldFormat.value === 'price' && {
							cellComponent: <PriceCell field={a.dataSetField.alias} />,
						}),
				};
			});
	};

	const groupRowRender = (list, field, value) => {
		const fieldFormatValue = fieldFormat(field);

		const groupRow = {
			id: value,
			titleLine: true,
			...(fieldFormatValue === 'date' && { [field]: dateFormatter(value, false) }),
			...(fieldFormatValue === 'datetime' && { [field]: dateFormatter(value, false) }),
			...(fieldFormatValue === 'duration' && { [field]: durationFormatter(value, false) }),
			...(fieldFormatValue === 'price' && { [field]: priceFormatter(value, false) }),
			...(fieldFormatValue === '' && { [field]: value }),
		};

		report.reportFields
			.filter(rf => rf.dataSetField.fieldGroup.canSum)
			.forEach(sumField => {
				groupRow[sumField.dataSetField.alias] = list
					.filter(data => {
						switch (fieldFormatValue) {
							case 'datetime':
								return new Date(data[field]).toDateString() === value;
							default:
								return data[field] === value;
						}
					})
					.map(data => numberFormat(parseFloat(data[sumField.dataSetField.alias])))
					.reduce((a, b) => a + b);
			});

		return groupRow;
	};

	useEffect(() => {
		if (headerContext)
			headerContext.setBreadcrumbs([
				{
					title: pages.reports.default.text,
					path: pages.reports.dashboard.path,
				},
				{
					title: getReportText(historyLocation.pathname),
					path: getReportPath(historyLocation.pathname),
				},
				{ title: report ? report.name : '', isActive: true },
			]);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ReportContainer
			columnOptions={columnOptions()}
			route='reportGeneric'
			config={{ fields: report ? getReportQueryFields(report.type) : {} }}
			reportRender={reportRender}
			reportFileName={report.name}
			reportId={report.id}
			groupRowRender={groupRowRender}
			initTree={report ? JSON.parse(report.queryJSON) : null}
			lists={report.type.dataSets
				.map(item => item.source)
				.filter((v, i, a) => v && a.indexOf(v) === i)}
			title={pages.reports.text}
		/>
	);
};

export default ReportsOutput;
