import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { required } from '../../../utils/helpers/validation';
import Portlet from '../../reusables/layout/Portlet';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import Button from '../../reusables/element/Button';
import useField from '../../../utils/hooks/useField';
import Loading from '../../reusables/template/Loading';
import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';
import { metaTypeOptionParser } from '../../../utils/helpers/helper';

const MetaForm = ({
	data,
	setIsValid,
	submit,
	isSubmitted,
	enumMetaTypes,
	isLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
}) => {
	const headerContext = useContext(HeaderContext);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [type, typeOnChange, typeValRes, typeShowVal, setTypeShowVal] = useField(
		data,
		'type',
		onFormChange,
		[required]
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setTypeShowVal();
		}
	}, [isSubmitted, setNameShowVal, setTypeShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && typeValRes.isValid);
	}, [setIsValid, nameValRes.isValid, typeValRes.isValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.systemSettings.default.text,
				path: pages.systemSettings.dashboard.path,
			},
			{
				title: pages.systemSettings.metas.text,
				path: pages.systemSettings.metas.path,
			},
			{ title: name || `New ${pages.systemSettings.metas.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.systemSettings.metas.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='type'
								label='Type'
								id={data.id}
								valRes={typeValRes}
								showValidation={typeShowVal}
								colMd={6}>
								<Selects
									options={enumMetaTypes.map(metaTypeOptionParser)}
									placeholder='Type (Required)'
									value={type ? metaTypeOptionParser(type) : null}
									displayKey='label'
									onChange={typeOnChange}
									onBlur={setTypeShowVal}
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};

MetaForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	enumMetaTypes: PropTypes.arrayOf(PropTypes.object),
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
};

MetaForm.defaultProps = {
	data: {
		id: 0,
		name: '',
	},
	enumMetaTypes: [],
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
};

export default MetaForm;
