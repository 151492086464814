import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import TextSignature from 'text-signature';

import {
	addErrorNotification,
	addSuccessNotification,
	getContractText,
	parseDatePickerChange,
	parseDatePickerValue,
} from '../../../../../utils/helpers/helper';
import { required, email as emailValidation } from '../../../../../utils/helpers/validation';
import useField from '../../../../../utils/hooks/useField';
import apiCall, { pathToUrl } from '../../../../../utils/helpers/apiCall';
import usePrint from '../../../../../utils/hooks/usePrint';

import Portal from '../../../layout/Portal';
import Portlet from '../../../layout/Portlet';
import Button from '../../../element/Button';
import Toggle from '../../../field/Toggle';
import FormGroup from '../../../layout/FormGroup';
import FormField from '../../../template/FormField';
import Input, { InputGroup } from '../../../field/Input';
import DatePicker from '../../../field/DatePicker';
import Media from '../../../element/Media';

import EsignModal from '../../../modals/EsignModal';

const Contract = ({
	data,
	isOpen,
	onClose,
	afterSubmit,
	contract,
	reservationItem,
	reservation,
	module,
	forView,
}) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '1024',
					width: '80%',
				}}>
				<ContractContent
					data={data}
					onClose={onClose}
					afterSubmit={afterSubmit}
					contract={contract}
					reservationItem={reservationItem}
					reservation={reservation}
					module={module}
					forView={forView}
				/>
			</Popup>
		</Portal>
	);
};

Contract.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	afterSubmit: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	contract: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	module: PropTypes.string.isRequired,
	forView: PropTypes.bool,
};

Contract.defaultProps = {
	data: {},
	isOpen: false,
	onClose: () => {},
	afterSubmit: () => {},
	contract: null,
	reservationItem: null,
	reservation: null,
	forView: false,
};

const ContractContent = ({
	onClose,
	afterSubmit,
	contract,
	reservationItem,
	reservation,
	module,
	forView,
	data,
}) => {
	const [isValid, setIsValid] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isMediaOpen, setIsMediaOpen] = useState(false);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		() => {},
		[required],
		contract?.name
	);

	const [email, emailOnChange, emailValRes, emailShowVal, setEmailShowVal] = useField(
		data,
		'email',
		() => {},
		[required, emailValidation],
		reservation?.customer?.email.toLocaleLowerCase()
	);

	const [
		expirationDate,
		expirationDateOnChange,
		expirationDateValRes,
		expirationDateShowVal,
		setExpirationDateShowVal,
	] = useField(
		data,
		'expirationDate',
		() => {},
		contract?.expirationDate ? [required] : [],
		data.expirationDate || ''
	);

	const [isReadOnly, setIsReadOnly] = useState(forView);

	const [sendEmail, setSendEmail] = useState(false);

	const [sendESignEmail, setSendESignEmail] = useState(false);

	const [print, setPrint] = useState(false);

	const [onPrint, PRINT_COMPONENT] = usePrint();

	const id = useRef(0);

	const textRef = useRef();

	const [signatureText, setSignatureText] = useState('');

	const [showSignatureTextInput, setShowSignatureTextInput] = useState(false);

	const [esignModalElement, setEsignModalElement] = useState(null);

	const getText = () => {
		if (data && data.text) return data.text;

		if (contract && contract.parsedTemplate) return contract.parsedTemplate;

		return '';
	};

	const isTemplateValid = () => {
		let _isValid = true;

		[...document.querySelectorAll('.product-contract-input')]
			.filter(el => el.hasAttribute('required'))
			.forEach(el => {
				if (!el.value) {
					_isValid = false;
					el.classList.add('product-contract-invalid');
				}
			});

		[...document.querySelectorAll('.product-contract-checkbox')]
			.filter(el => el.hasAttribute('required'))
			.forEach(el => {
				if (!el.getAttribute('checked')) {
					_isValid = false;
					el.classList.add('product-contract-invalid');
				}
			});

		[...document.querySelectorAll('.signature-input-container')]
			.filter(el => el.hasAttribute('required'))
			.forEach(el => {
				const children = [...el.childNodes];
				if (
					children.length > 0 &&
					[...children[children.length - 1].classList].indexOf(
						'signature-input-placeholder'
					) !== -1
				) {
					_isValid = false;
					el.classList.add('product-contract-invalid');
				}
			});

		return _isValid;
	};

	const onSave = (close = false) => {
		setIsSubmitted(true);

		if (!isValid) return;

		if (!isTemplateValid()) {
			addErrorNotification('Please fill all fields.');
			return;
		}
		setIsSubmitting(true);

		const textParentElements = document.getElementsByClassName('sdms-contract-text');

		if (textParentElements.length > 0) recursiveDisableFilledTextInputs(textParentElements[0]);

		disableFilledSignatureAreas();

		apiCall(
			'POST',
			'saveReservationItemStatusContract',
			response => {
				setIsSubmitting(false);
				id.current = response.id;
				afterSubmit(response);
				if (print) onPrint(response.text);
				if (close) onClose();
				addSuccessNotification('Contract successfully saved.');
			},
			err => {
				addErrorNotification(err.toString());
				setIsSubmitting(false);
			},
			'',
			{
				id: id.current,
				module,
				reservationItemId: reservationItem.id,
				statusId: reservationItem.status.id,
				customStatusId: reservationItem.customReservationStatus
					? reservationItem.customReservationStatus.id
					: null,
				contractId: contract.id,
				name,
				email,
				expirationDate,
				text: getContractText(textRef),
				sendEmail,
				sendESignEmail,
			}
		);
	};

	const onSend = (isESign = false) => {
		setIsSubmitting(true);

		apiCall(
			'POST',
			'sendReservationItemStatusContractEmail',
			() => {
				addSuccessNotification('Email successfully sent.');
				setIsSubmitting(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsSubmitting(false);
			},
			'',
			{
				id: data.id,
				isESign,
			}
		);
	};

	const onClearSignature = () => {
		[...document.querySelectorAll('.signature-clear')].forEach(el => {
			el.click();
		});
	};

	const recursiveDisableFilledTextInputs = (element, enable = false) => {
		const { childNodes } = element;

		if (childNodes.length === 0) return;

		childNodes.forEach(child => {
			const { tagName } = child;

			if (
				tagName === 'INPUT' &&
				((child.type === 'text' && child.value) ||
					(child.type === 'checkbox' && child.checked))
			)
				child.disabled = !enable;

			recursiveDisableFilledTextInputs(child, enable);
		});
	};

	const disableFilledSignatureAreas = () => {
		const signatureInputContainers = [
			...document.getElementsByClassName('signature-input-container'),
		];

		signatureInputContainers.forEach((container, i) => {
			const containerChildNodes = container.childNodes;

			containerChildNodes.forEach(child => {
				if (
					child.classList &&
					[...child.classList].indexOf('signature-input-placeholder') === -1
				) {
					const container = document.getElementsByClassName('signature-input-container')[
						i
					];

					if ([...container.classList].indexOf('signature-input-container-filled') === -1)
						container.classList.add('signature-input-container-filled');
				}
			});
		});
	};

	const bindContractInputEvents = () => {
		const inputs = document.getElementsByClassName('product-contract-input');

		if (inputs && inputs.length) {
			Object.keys(inputs).forEach(i => {
				inputs[i].addEventListener(
					'input',
					event => {
						if (inputs[i].hasAttribute('required') && event.target.value === '')
							inputs[i].classList.add('product-contract-invalid');
						else inputs[i].classList.remove('product-contract-invalid');
						inputs[i].setAttribute('value', event.target.value);
					},
					false
				);
			});
		}

		const checkboxes = document.getElementsByClassName('product-contract-checkbox');

		if (checkboxes && checkboxes.length) {
			Object.keys(checkboxes).forEach(i => {
				checkboxes[i].addEventListener(
					'change',
					event => {
						checkboxes[i].classList.remove('product-contract-invalid');
						if (event.currentTarget.checked)
							checkboxes[i].setAttribute('checked', 'checked');
						else checkboxes[i].removeAttribute('checked');
					},
					false
				);
			});
		}
	};

	const bindContractSignatureEvents = () => {
		[...document.getElementsByClassName('signature-input-container')].forEach(container => {
			if (!container.classList.contains('signature-input-container-filled')) {
				container.addEventListener('click', () => {
					setEsignModalElement(container);
					container.classList.remove('product-contract-invalid');
				});
			}
		});
	};

	useEffect(() => {
		const textParentElement = document.getElementsByClassName('sdms-contract-text');

		if (textParentElement.length > 0)
			recursiveDisableFilledTextInputs(textParentElement[0], !isReadOnly);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isReadOnly]);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setEmailShowVal();
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isSubmitted]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && emailValRes.isValid && expirationDateValRes.isValid);
	}, [setIsValid, nameValRes.isValid, emailValRes.isValid, expirationDateValRes.isValid]);

	useEffect(() => {
		bindContractInputEvents();
		bindContractSignatureEvents();
	}, []);

	return (
		<>
			<Portlet>
				<Portlet.Head>
					<Portlet.HeadLabelTitle>{name}</Portlet.HeadLabelTitle>
					<Portlet.HeadToolbarActions className='sdms-last-margin'>
						{contract &&
							contract.parsedTemplate &&
							contract.parsedTemplate.search('product-contract-images') > -1 && (
								<Button
									label='brand'
									icon='Picture'
									text='Insert Image'
									size='sm'
									onClick={() => setIsMediaOpen(true)}
								/>
							)}
					</Portlet.HeadToolbarActions>
				</Portlet.Head>
				<Portlet.Body
					onScroll={e => {
						const bottom =
							e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
						if (bottom) setShowSignatureTextInput(true);
					}}>
					<FormGroup>
						<FormField
							name='name'
							label='Name'
							id='name'
							valRes={nameValRes}
							showValidation={nameShowVal}
							loadingContainer
							colMd={4}>
							<Input
								type='text'
								placeholder='Name (Required)'
								value={name}
								onChange={nameOnChange}
								onBlur={setNameShowVal}
								disabled={isReadOnly}
							/>
						</FormField>
						<FormField
							name='email'
							label='Email'
							id='email'
							valRes={emailValRes}
							showValidation={emailShowVal}
							loadingContainer
							colMd={4}>
							<Input
								type='text'
								placeholder='Email (Required)'
								value={email.toLocaleLowerCase()}
								onChange={emailOnChange}
								onBlur={setEmailShowVal}
								disabled={isReadOnly}
							/>
						</FormField>
						{contract && contract.expirationDate && (
							<FormField
								name='expirationDate'
								label='Expiration Date'
								id='expirationDate'
								colMd={4}
								showValidation={expirationDateShowVal}
								valRes={expirationDateValRes}>
								<DatePicker
									id='readingDate'
									type='calendar'
									value={parseDatePickerValue(expirationDate, false)}
									onBlur={setExpirationDateShowVal}
									disabled={isReadOnly}
									onChange={e => {
										expirationDateOnChange({
											target: {
												value: parseDatePickerChange(
													e.target.value,
													expirationDate,
													true,
													true
												),
											},
										});
									}}
								/>
							</FormField>
						)}
					</FormGroup>
					<FormGroup>
						<div
							style={
								isReadOnly
									? {
											pointerEvents: 'none',
											boxShadow: '0px 0px 13px 0px rgba(82, 63, 105, 0.15)',
											padding: '0.5cm',
									  }
									: {
											boxShadow: '0px 0px 13px 0px rgba(82, 63, 105, 0.15)',
											padding: '0.5cm',
									  }
							}
							ref={textRef}
							className='col-12 sdms-contract-text'
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: getText(),
							}}
						/>
					</FormGroup>
				</Portlet.Body>
				<Portlet.Foot
					tall='sm'
					className='sdms-align-left'
					subClassName='justify-content-between'>
					{showSignatureTextInput &&
						contract &&
						contract.parsedTemplate &&
						contract.parsedTemplate.search('signature-area') > -1 && (
							<div
								className='col-md-12'
								style={{
									padding: 15,
									marginBottom: 10,
								}}>
								<div className='row'>
									<div
										className='col-md-2'
										style={{
											paddingTop: 10,
											paddingLeft: 15,
										}}>
										Sign with text:
									</div>
									<FormField
										name='signature'
										label=''
										id='signature'
										colMd={6}
										isLast
										className='sdms-m0'
										inFormDesign={false}>
										<InputGroup>
											<Input
												type='text'
												placeholder='Signature'
												value={signatureText}
												onChange={e => {
													setSignatureText(e.target.value);

													if (e.target.value) {
														const optionsParameter = {
															width: 400,
															height: 75,
															paddingX: 15,
															paddingY: 60,
															canvasTargetDom: '.signature-text',
															font: ['30px', "'Homemade Apple'"],
															color: 'black',
															textString: e.target.value,
															customFont: {
																name: "'Homemade Apple'",
																url: `${window.location.origin}/assets/fonts/signature.css`,
															},
														};

														TextSignature(optionsParameter);
													} else onClearSignature();
												}}
												disabled={isReadOnly}
											/>
											<div className='input-group-append'>
												<Button
													label='brand'
													text='Clear'
													icon='Backspace'
													size='sm'
													disabled={isReadOnly}
													onClick={() => {
														setSignatureText('');
														onClearSignature();
													}}
												/>
											</div>
										</InputGroup>
									</FormField>
								</div>
							</div>
						)}
					<div className='col-auto'>
						<Button
							design='clean'
							text='Close'
							icon='Error-circle'
							size='sm'
							elevate
							onClick={onClose}
							disabled={isSubmitting}
						/>
					</div>
					{!isReadOnly && (
						<div className='col-auto'>
							<div className='form-group align-items-center d-flex form-group-last sdms-last-margin--h'>
								<Toggle
									onChange={() => setSendEmail(!sendEmail)}
									value={sendEmail}
									disabled={isSubmitting}
								/>
								{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
								<label className='sdms-cursor--pointer sdms-mw-75'>Email</label>
								<Toggle
									onChange={() => setSendESignEmail(!sendESignEmail)}
									value={sendESignEmail}
									disabled={isSubmitting}
								/>
								{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
								<label className='sdms-cursor--pointer sdms-mw-75'>E-sign</label>
								<Toggle
									onChange={() => setPrint(!print)}
									value={print}
									disabled={isSubmitting}
								/>
								{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
								<label className='sdms-cursor--pointer sdms-mw-75'>Print</label>
							</div>
						</div>
					)}
					<div className='col-auto'>
						<div className='form-group align-items-center d-flex form-group-last sdms-last-margin--h'>
							{isReadOnly ? (
								<>
									<Button
										label='brand'
										text='Edit'
										size='sm'
										onClick={() => setIsReadOnly(false)}
										disabled={isSubmitting || data.esignDate}
									/>
									<Button
										label='brand'
										text='Print'
										size='sm'
										onClick={() => onPrint(data.text)}
										disabled={isSubmitting}
									/>
									<Button
										label='brand'
										text='Send Email'
										size='sm'
										onClick={() => onSend()}
										disabled={isSubmitting}
									/>
									<Button
										label='brand'
										text='Send E-Sign Email'
										size='sm'
										onClick={() => onSend(true)}
										disabled={isSubmitting}
									/>
								</>
							) : (
								<>
									<Button
										label='brand'
										text='Save'
										size='sm'
										onClick={() => onSave()}
										disabled={isSubmitting}
									/>
									<Button
										label='brand'
										text='Save & Close'
										size='sm'
										onClick={() => onSave(true)}
										disabled={isSubmitting}
									/>
								</>
							)}
						</div>
					</div>
				</Portlet.Foot>
			</Portlet>
			<Media
				close={() => setIsMediaOpen(false)}
				isOpen={isMediaOpen}
				enableAdditionalSettings
				setMedia={media => {
					const images = document.getElementsByClassName('product-contract-images');

					if (images && images.length) {
						Object.keys(images).forEach(i => {
							const container = document.createElement('div');
							container.style.marginRight = '0.5%';
							container.style.marginLeft = '0.5%';
							container.style.display = 'inline-block';
							container.style.width = media.additionalSettings.width;

							container.onclick = event => event.currentTarget.remove();

							const title = document.createElement('h4');
							title.innerText = media.additionalSettings.title;
							title.style.minHeight = '23.4px';
							title.style.textAlign = media.additionalSettings.alignment;

							container.appendChild(title);

							const image = document.createElement('img');
							image.src = pathToUrl(media.path);
							image.style.width = '100%';

							container.appendChild(image);
							images[i].appendChild(container);
						});
					}
				}}
			/>
			{PRINT_COMPONENT}
			<EsignModal element={esignModalElement} onClose={() => setEsignModalElement(null)} />
		</>
	);
};

ContractContent.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	onClose: PropTypes.func.isRequired,
	afterSubmit: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	contract: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object.isRequired,
	module: PropTypes.string.isRequired,
	forView: PropTypes.bool.isRequired,
};
ContractContent.defaultProps = {
	data: {},
	contract: null,
	reservationItem: null,
};

export default Contract;
