import React from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';

import FormContainer from '../../reusables/template/FormContainer';
import ProductCategoryBookingForm from './ProductCategoryBookingForm';

const ProductCategoryBookingEdit = ({ match }) => {
	const pages = usePages();
	return (
		<FormContainer
			dataId={match.params.id}
			dataName='productCategoryBookings'
			lists={['productCategoryBookings']}
			fields={<ProductCategoryBookingForm />}
			pageTitle={pages.booking.settings.productCategories.text}
			icon={pages.booking.settings.productCategories.icon}
		/>
	);
};

ProductCategoryBookingEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ProductCategoryBookingEdit;
