import React, { useContext, useEffect, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';

import { required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import UserContext from '../../../app/contexts/UserContext';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import Section from '../../reusables/layout/Section';
import Wizard from '../../reusables/template/Wizard';
import Loading from '../../reusables/template/Loading';
import MultiSelect from '../../reusables/element/MultiSelect';
import HeaderContext from '../../../app/contexts/HeaderContext';
import Separator from '../../reusables/layout/Separator';
import { getUserRoleLevel } from '../../../utils/helpers/helper';
import Toggle from '../../reusables/field/Toggle';
import pages from '../../pages';

const RoleForm = ({
	data,
	permissions,
	roleLevels,
	isValid,
	setIsValid,
	submit,
	isSubmitted,
	isLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
}) => {
	const userContext = useContext(UserContext);

	const currentUserRoleLevel = useRef(
		getUserRoleLevel(userContext.data.user, userContext.data.selectedOutlet)
	);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [
		roleLevel,
		roleLevelOnChange,
		roleLevelValRes,
		roleLevelShowVal,
		setRoleLevelShowVal,
	] = useField(data, 'roleLevel', onFormChange, [required], null);

	const [assignedPermissions, assignedPermissionsOnChange] = useField(
		data,
		'permissions',
		onFormChange,
		[],
		[]
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setRoleLevelShowVal();
		}
	}, [isSubmitted, setNameShowVal, setRoleLevelShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && roleLevelValRes.isValid);
	}, [nameValRes.isValid, roleLevelValRes.isValid, setIsValid]);

	const permissionOnChange = (permission, isEnabled) => {
		if (isEnabled)
			assignedPermissionsOnChange({
				target: { name: 'permissions', value: [permission, ...assignedPermissions] },
			});
		else
			assignedPermissionsOnChange({
				target: {
					name: 'permissions',
					value: assignedPermissions.filter(
						p =>
							!(
								p.id === permission.id ||
								(p.parent &&
									(p.parent.id === permission.id ||
										(p.parent.parent && p.parent.parent.id === permission.id)))
							)
					),
				},
			});
	};

	const getPermissionsByModule = moduleId => {
		const _permissions = {};

		permissions
			.filter(permission => permission.module.id === moduleId && permission.parent !== null)
			.forEach(p => {
				if (p.parent.parent) {
					if (_permissions[p.parent.id]) _permissions[p.parent.id].children.push(p);
					else _permissions[p.parent.id] = { ...p, children: [p] };
				} else if (!_permissions[p.id]) _permissions[p.id] = { ...p, children: [] };
			});

		return _permissions;
	};

	const steps = [
		<Wizard.Item
			icon='Shield-user'
			title='General'
			description='Assign Module Access'
			key='general'>
			<Section>
				<Section.Body>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								description='Role Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colLg={6}>
								<Input
									type='text'
									placeholder='Role Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='roleLevel'
								label='Role Level'
								description='Higher level roles can edit lower level roles'
								id={data.id}
								valRes={roleLevelValRes}
								showValidation={roleLevelShowVal}
								colLg={6}>
								<Selects
									placeholder='Role Level (Required)'
									options={roleLevels.filter(
										rl =>
											rl.level <= currentUserRoleLevel.current ||
											userContext.data.user.isOwner ||
											userContext.data.user.isInstallerUser
									)}
									value={roleLevel}
									onChange={roleLevelOnChange}
									onBlur={setRoleLevelShowVal}
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</Section.Body>
			</Section>
			<Section title='Accessibility'>
				<Section.Body>
					<FormGroup>
						{permissions
							.filter(
								permission =>
									permission.parent === null &&
									userContext.data.user.company.modules.filter(
										m => m.id === permission.module.id
									).length > 0
							)
							.sort((a, b) => a.name.localeCompare(b.name))
							.map(permission => (
								<FormField
									key={permission.id}
									name={permission.name}
									label={permission.name}
									colLg={3}>
									<Toggle
										id={`role_${permission.id}`}
										value={
											assignedPermissions.filter(
												ap => ap.id === permission.id
											).length > 0
										}
										onChange={({ target }) =>
											permissionOnChange(permission, target.value)
										}
									/>
								</FormField>
							))}
					</FormGroup>
				</Section.Body>
			</Section>
		</Wizard.Item>,
	];

	userContext.data.user.company.modules.forEach(module => {
		steps.push(
			<Wizard.Item
				title={module.value}
				key={module.id}
				icon={module.icon.value}
				description={module.description}
				disable={
					assignedPermissions.filter(
						permission =>
							permission.parent === null && permission.module.id === module.id
					).length === 0 || !isValid
				}>
				<Section>
					<Section.Body>
						<FormGroup>
							<FormField name='generalRole' col={12}>
								<MultiSelect.Container>
									{Object.values(getPermissionsByModule(module.id)).map(
										(permission, index) => (
											<Fragment key={permission.id}>
												{!permission.parent.parent && index > 0 && (
													<Separator className='col-12' size='sm' />
												)}
												<MultiSelect.Item
													id={`role_${permission.id}`}
													col={12}
													fontWeight='boldest'
													disabled={
														assignedPermissions.filter(
															ap => ap.id === permission.parent.id
														).length === 0
													}
													value={
														assignedPermissions.filter(
															ap => ap.id === permission.id
														).length > 0
													}
													onChange={({ target }) =>
														permissionOnChange(permission, target.value)
													}
													title={permission.name}
												/>
												{permission.children.map(childPermission => (
													<MultiSelect.Item
														id={`role_${childPermission.id}`}
														disabled={
															assignedPermissions.filter(
																ap =>
																	ap.id ===
																	childPermission.parent.id
															).length === 0
														}
														value={
															assignedPermissions.filter(
																ap => ap.id === childPermission.id
															).length > 0
														}
														onChange={({ target }) =>
															permissionOnChange(
																childPermission,
																target.value
															)
														}
														title={childPermission.name}
													/>
												))}
											</Fragment>
										)
									)}
								</MultiSelect.Container>
							</FormField>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
		);
	});

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.systemSettings.default.text,
				path: pages.systemSettings.dashboard.path,
			},
			{ title: pages.systemSettings.roles.text, path: pages.systemSettings.roles.path },
			{ title: name || `New ${pages.systemSettings.roles.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.systemSettings.roles.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Wizard onSubmit={submit} submitButtonAttr={submitButtonAttr} isLoading={isLoading}>
			{steps}
		</Wizard>
	);
};
RoleForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		roleLevel: PropTypes.object,
		permissions: PropTypes.array,
	}),
	permissions: PropTypes.arrayOf(PropTypes.object),
	roleLevels: PropTypes.arrayOf(PropTypes.object),
	isValid: PropTypes.bool,
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
};
RoleForm.defaultProps = {
	data: {
		id: 0,
	},
	permissions: [],
	roleLevels: [],
	isValid: false,
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
};

export default RoleForm;
