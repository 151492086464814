import React, { useContext, useLayoutEffect, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import UserContext from '../app/contexts/UserContext';
import { useWindowSize } from '../utils/helpers/helper';
import usePages from '../utils/hooks/usePages';
import Nav from './Nav';
import NavItems from './NavItems';
import SVGIcon from './reusables/element/SVGIcon';
import logo from '../assets/img/logo.svg';
import favicon from '../assets/img/favico.svg';

export const Logo = ({ isFavicon }) => (
	<a href='/'>
		<ReactSVG src={isFavicon ? favicon : logo} className='svg-container' />
	</a>
);
Logo.propTypes = {
	isFavicon: PropTypes.bool,
};
Logo.defaultProps = {
	isFavicon: false,
};

const Aside = ({ overlay }) => {
	const windowSize = useWindowSize();

	const userContext = useContext(UserContext);

	const pages = usePages();

	const [asideStatus, setAsideStatus] = useState(!(windowSize.width < 1600));

	const [asideSelectedText, setAsideSelectedText] = useState('');

	useEffect(() => {
		if (windowSize.width < 1600) setAsideStatus(false);
		else setAsideStatus(true);
	}, [windowSize]);

	useLayoutEffect(() => {
		if (asideStatus === false) {
			document.body.classList.add('sdms-aside--minimize');
		}
		document.body.classList.add('sdms-aside--enabled', 'sdms-aside--fixed');
		return () => {
			document.body.classList.remove('sdms-aside--enabled', 'sdms-aside--fixed');
			if (asideStatus === false) {
				document.body.classList.remove('sdms-aside--minimize');
			}
		};
	}, [asideStatus]);

	const minimizing = () => {
		document.body.classList.add('sdms-aside--minimizing');
		setTimeout(() => {
			document.body.classList.remove('sdms-aside--minimizing');
		}, 299);
	};
	const asideStatusToggle = () => {
		if (asideStatus === true) {
			document.body.classList.add('sdms-aside--minimize');
			minimizing();
		} else {
			document.body.classList.remove('sdms-aside--minimize');
		}
		document.body.classList.remove('sdms-aside--minimize-hover');
		setAsideStatus(!asideStatus);
	};
	const mouseEnterAside = () => {
		if (asideStatus === false) {
			document.body.classList.add('sdms-aside--minimize-hover');
			minimizing();
		}
		document.body.classList.remove('sdms-aside--minimize');
	};
	const mouseLeaveAside = () => {
		if (asideStatus === false) {
			document.body.classList.add('sdms-aside--minimize');
		} else {
			document.body.classList.remove('sdms-aside--minimize');
		}
		document.body.classList.remove('sdms-aside--minimize-hover');

		if (asideStatus === false && document.body.classList.contains('sdms-aside--minimize')) {
			minimizing();
		}
	};

	const getInUpperModules = modules => {
		const availableModules = {};
		modules
			.sort((a, b) => a.value.localeCompare(b.value))
			.forEach(m => {
				if (m.value === 'Accounting') availableModules.accounting = pages.accounting;
				else if (m.value === 'Bookings') availableModules.booking = pages.booking;
				else if (m.value === 'Campground') availableModules.campground = pages.campground;
				else if (m.value === 'CRM') availableModules.customers = pages.crm;
				else if (m.value === 'Marina') availableModules.marina = pages.marina;
				else if (m.value === 'Inventory') availableModules.inventory = pages.inventory;
				else if (m.value === 'Point of Sale') availableModules.pos = pages.pos;
				else if (m.value === 'Service') availableModules.service = pages.service;
				else if (m.value === 'Vendors') availableModules.vendors = pages.vendors;
			});

		return Object.values(availableModules).sort((a, b) =>
			a.default.text.localeCompare(b.default.text)
		);
	};

	const getInDownModules = modules => {
		const availableModules = {};

		if (modules.findIndex(m => m.value === 'Analytics') > -1)
			availableModules.analytics = pages.analytics;

		if (modules.findIndex(m => m.value === 'Reports') > -1)
			availableModules.reports = pages.reports;

		if (modules.findIndex(m => m.value === 'Messages') > -1)
			availableModules.messages = pages.messages;

		if (modules.findIndex(m => m.value === 'Company Settings') > -1)
			availableModules.companySettings = pages.companySettings;

		if (modules.findIndex(m => m.value === 'System') > -1)
			availableModules.systemSettings = pages.systemSettings;

		return Object.values(availableModules);
	};

	return (
		<div
			id='sdms_aside'
			className={classNames(
				'sdms-aside',
				'sdms-aside--fixed',
				'sdms-grid',
				'sdms-grid__item',
				'sdms-grid--desktop',
				'sdms-grid--hor-desktop',
				{ 'sdms-aside--on': overlay.aside }
			)}
			onMouseEnter={mouseEnterAside}
			onMouseLeave={mouseLeaveAside}>
			<div id='sdms_aside_brand' className='sdms-aside__brand sdms-grid__item'>
				<div className='sdms-aside__brand-logo'>
					<Logo />
				</div>
				<div className='sdms-aside__brand-tools'>
					<button
						type='button'
						id='sdms_aside_toggler'
						className={classNames('sdms-aside__brand-aside-toggler', {
							'sdms-aside__brand-aside-toggler--active': !asideStatus,
						})}
						onClick={windowSize.width < 1280 ? mouseEnterAside : asideStatusToggle}>
						{windowSize.width < 1280 ? (
							<>
								<span />
								<span>
									<SVGIcon name='Layers' size={24} />
								</span>
							</>
						) : (
							<>
								<span>
									<SVGIcon name='Angle-double-left' size={24} />
								</span>
								<span>
									<SVGIcon name='Angle-double-right' size={24} />
								</span>
							</>
						)}
					</button>
				</div>
			</div>
			<div
				id='sdms_aside_menu_wrapper'
				className='sdms-aside-menu-wrapper sdms-grid__item sdms-grid__item--fluid'>
				<div id='sdms_aside_menu' className='sdms-aside-menu'>
					<Nav>
						{userContext.data && userContext.data.access.includes('admin') ? (
							<NavItems
								menu={getInUpperModules(userContext.data.user.company.modules)}
								parentText=''
								selectedText={asideSelectedText}
								setSelectedText={setAsideSelectedText}
							/>
						) : (
							<>
								<Nav.Item text='loading' path='#' isLoading>
									<Nav.Item text='loading' path='#' isLoading />
								</Nav.Item>
								<Nav.Item text='loading' path='#' isLoading>
									<Nav.Item text='loading' path='#' isLoading />
								</Nav.Item>
								<Nav.Item text='loading' path='#' isLoading>
									<Nav.Item text='loading' path='#' isLoading />
								</Nav.Item>
								<Nav.Item text='loading' path='#' isLoading>
									<Nav.Item text='loading' path='#' isLoading />
								</Nav.Item>
								<Nav.Item text='loading' path='#' isLoading>
									<Nav.Item text='loading' path='#' isLoading />
								</Nav.Item>
								<Nav.Item text='loading' path='#' isLoading>
									<Nav.Item text='loading' path='#' isLoading />
								</Nav.Item>
								<Nav.Item text='loading' path='#' isLoading>
									<Nav.Item text='loading' path='#' isLoading />
								</Nav.Item>
								<Nav.Item text='loading' path='#' isLoading>
									<Nav.Item text='loading' path='#' isLoading />
								</Nav.Item>
								<Nav.Item text='loading' path='#' isLoading>
									<Nav.Item text='loading' path='#' isLoading />
								</Nav.Item>
							</>
						)}
					</Nav>
					<Nav className='sdms-menu__nav--accordion-disabled'>
						{userContext.data && userContext.data.access.includes('admin') ? (
							<NavItems
								menu={getInDownModules(userContext.data.user.company.modules)}
								parentText=''
								selectedText={asideSelectedText}
								setSelectedText={setAsideSelectedText}
							/>
						) : (
							<>
								<Nav.Item text='loading' path='#' isLoading />
								<Nav.Item text='loading' path='#' isLoading />
								<Nav.Item text='loading' path='#' isLoading />
								<Nav.Item text='loading' path='#' isLoading />
								<Nav.Item text='loading' path='#' isLoading />
							</>
						)}
					</Nav>
				</div>
			</div>
		</div>
	);
};
Aside.propTypes = {
	overlay: PropTypes.shape({
		aside: PropTypes.bool,
	}),
};
Aside.defaultProps = {
	overlay: {
		aside: false,
		header: false,
		topBar: false,
		order: false,
	},
};

export default withRouter(Aside);
