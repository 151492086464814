import React from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';

import FormContainer from '../../reusables/template/FormContainer';
import CompanySyncsForm from './CompanySyncsForm';

const CompanySyncsEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName={pages.companySettings.companySyncs.route}
			fields={<CompanySyncsForm />}
			pageTitle={pages.companySettings.companySyncs.text}
			icon={pages.companySettings.companySyncs.icon}
		/>
	);
};

CompanySyncsEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default CompanySyncsEdit;
