import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import { maxLength, numeric, required } from '../../../utils/helpers/validation';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import useField from '../../../utils/hooks/useField';
import {
	isBeforeAccountingClosedPeriod,
	convertDateToUTC,
	generateId,
	getAccountingClosedDate,
	negativeNumberParser,
	priceFormatter,
	addErrorNotification,
} from '../../../utils/helpers/helper';

import Portlet from '../../reusables/layout/Portlet';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Button from '../../reusables/element/Button';
import Loading from '../../reusables/template/Loading';
import DatePicker from '../../reusables/field/DatePicker';
import { ListBody, ListTable } from '../../reusables/template/List';
import Selects from '../../reusables/field/Selects';
import Input from '../../reusables/field/Input';
import Checkbox from '../../reusables/field/Checkbox';
import Alert from '../../reusables/element/Alert';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import apiCall from '../../../utils/helpers/apiCall';
import useDate from '../../../utils/hooks/useDate';

const JournalLine = ({
	data,
	accounts,
	onFormChange,
	isSubmitted,
	setIsValid,
	disabled,
	onDelete,
	onChange,
}) => {
	const [account, accountOnChange, accountValRes, accountShowVal, setAccountShowVal] = useField(
		data,
		'account',
		onFormChange,
		[required],
		null
	);

	const [amount, amountOnChange, amountValRes, amountShowVal, setAmountShowVal] = useField(
		data,
		'amount',
		onFormChange,
		[required, numeric],
		'',
		negativeNumberParser
	);

	const [customer, customerOnChange] = useField(data, 'customer', onFormChange, [], null);

	const [, utcDateFormatter] = useDate();

	const [isBookingsLoading, setIsBookingsLoading] = useState(false);

	const [bookings, setBookings] = useState([]);

	const [reservationItem, reservationItemOnChange] = useField(
		data,
		'reservationItem',
		onFormChange,
		[],
		null
	);

	const [taxAgency, taxAgencyOnChange] = useField(data, 'taxAgency', onFormChange, [], null);

	const [memo, memoOnChange] = useField(data, 'memo', onFormChange);

	const [isDebit, isDebitOnChange] = useField(data, 'isDebit', onFormChange, [], false);

	useEffect(() => {
		if (customer) {
			setIsBookingsLoading(true);
			apiCall(
				'GET',
				'reservationItems',
				res => {
					setBookings(res);
					setIsBookingsLoading(false);
				},
				err => {
					setBookings([]);
					setIsBookingsLoading(false);
					addErrorNotification(err.toString());
				},
				'',
				null,
				{
					'groups[]': 'journal:read',
					'reservation.customer.id': customer.id,
					pagination: false,
				}
			);
		} else setBookings([]);
	}, [customer]);

	useEffect(() => {
		if (isSubmitted) {
			setAccountShowVal();
			setAmountShowVal();
		}
	}, [isSubmitted, setAccountShowVal, setAmountShowVal]);

	useEffect(() => {
		setIsValid(accountValRes.isValid && amountValRes.isValid);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [accountValRes.isValid, amountValRes.isValid]);

	useEffect(onChange, [amount, isDebit]);

	const optionalShow = option =>
		`${option.unit.name} - ${option.reservation.reservationId} - ${option.reservationItemId}`;

	const renderOption = option =>
		`${option.reservation.reservationId} - ${option.id} - ${
			option.reservation.customer.displayName
		} -  ${option.product.name} - ${option.unit.name} - ${utcDateFormatter(
			option.fromDate
		)} - ${utcDateFormatter(option.toDate)} - ${option.status.value}`;

	return (
		<tr className={classNames({ disabled })}>
			<td>
				<FormField
					name='account'
					id={data.id}
					valRes={accountValRes}
					showValidation={accountShowVal}
					inFormDesign={false}
					isLast>
					<Selects
						options={accounts}
						placeholder='Select a Account'
						value={account}
						displayKey='name'
						onChange={accountOnChange}
						onBlur={setAccountShowVal}
						disabled={disabled}
					/>
				</FormField>
			</td>
			<td>
				<FormField
					name='amount'
					id={data.id}
					inFormDesign={false}
					valRes={amountValRes}
					showValidation={amountShowVal}
					isLast>
					<Input
						type='text'
						placeholder='Price'
						value={amount}
						onChange={amountOnChange}
						onBlur={setAmountShowVal}
						pattern={process.env.REACT_APP_NEGATIVE_PRICE_PATTERN}
						disabled={disabled}
					/>
				</FormField>
			</td>
			<td>
				<FormField name='memo' id={data.id} inFormDesign={false} isLast>
					<Input
						type='text'
						placeholder='Memo'
						value={memo}
						onChange={memoOnChange}
						disabled={disabled}
					/>
				</FormField>
			</td>
			<td>
				<FormField name='customer' id={data.id} inFormDesign={false} isLast>
					<AsyncSelect
						options={data.customer ? [data.customer] : []}
						placeholder='Search and select customer'
						value={customer}
						onChange={e => {
							reservationItemOnChange({ target: { value: null } });
							setBookings([]);
							customerOnChange(e);
						}}
						route='customers'
						field='displayName'
						displayKey='displayName'
						disabled={disabled}
					/>
				</FormField>
			</td>
			<td>
				<FormField name='reservationItem' id={data.id} inFormDesign={false} isLast>
					<Selects
						options={bookings}
						placeholder='Select a Booking'
						value={reservationItem}
						onChange={reservationItemOnChange}
						optionalShow={optionalShow}
						renderOption={renderOption}
						disabled={customer === null || isBookingsLoading}
					/>
				</FormField>
			</td>
			<td>
				<FormField name='taxAgency' id={data.id} inFormDesign={false} isLast>
					<AsyncSelect
						options={data.taxAgency ? [data.taxAgency] : []}
						placeholder='Search and select tax agency'
						value={taxAgency}
						onChange={taxAgencyOnChange}
						route='taxAgencies'
						disabled={disabled}
					/>
				</FormField>
			</td>
			<td>
				<FormField name='isDebit' id={data.id} inFormDesign={false} isLast>
					<Checkbox
						id={`${data.id}isDebit`}
						onChange={() => isDebitOnChange({ target: { value: !isDebit } })}
						value={isDebit}
						bold
						disabled={disabled}
					/>
				</FormField>
			</td>
			<td>
				<Button
					btnIcon
					label='danger'
					icon='Trash'
					size='sm'
					elevate
					key='delete'
					onClick={onDelete}
					disabled={disabled}
				/>
			</td>
		</tr>
	);
};

JournalLine.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	accounts: PropTypes.arrayOf(PropTypes.object),
	onFormChange: PropTypes.func,
	isSubmitted: PropTypes.bool,
	setIsValid: PropTypes.func,
	disabled: PropTypes.bool,
	onDelete: PropTypes.func,
	onChange: PropTypes.func,
};

JournalLine.defaultProps = {
	data: null,
	accounts: [],
	onFormChange: () => {},
	isSubmitted: false,
	setIsValid: () => {},
	disabled: false,
	onDelete: () => {},
	onChange: () => {},
};

const JournalForm = ({ data, setIsValid, isSubmitted, isLoading, onFormChange, accounts }) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const [
		transactionDate,
		transactionDateOnChange,
		transactionDateValRes,
		transactionDateShowVal,
		setTransactionDateShowVal,
	] = useField(data, 'transactionDate', onFormChange, [required], null);

	const [
		reference,
		referenceOnChange,
		referenceValRes,
		referenceShowVal,
		setReferenceShowVal,
	] = useField(data, 'reference', onFormChange, [maxLength(11)]);

	const [invalidLines, setInvalidLines] = useState([]);

	const [total, setTotal] = useState(0);

	const isClosed =
		!isLoading &&
		data.id !== 0 &&
		isBeforeAccountingClosedPeriod(data.transactionDate, userContext);

	const disabled = isClosed;

	const getTotal = () => {
		if (!data.journalLines) return 0;

		return (
			data.journalLines
				.filter(jl => jl.isDebit)
				.reduce((partialSum, a) => partialSum + (a.amount ? parseFloat(a.amount) : 0), 0) -
			data.journalLines
				.filter(jl => !jl.isDebit)
				.reduce((partialSum, a) => partialSum + (a.amount ? parseFloat(a.amount) : 0), 0)
		);
	};

	const getBanner = () => {
		if (isClosed)
			return (
				<div className='col-12 sdms-mb-20'>
					<Alert solid icon='Error-circle' design='danger'>
						Accounting Period Closed
					</Alert>
				</div>
			);

		return null;
	};

	useEffect(() => {
		if (isSubmitted && !disabled) {
			setTransactionDateShowVal();
			setReferenceShowVal();
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isSubmitted, setTransactionDateShowVal, setReferenceShowVal, data]);

	useEffect(() => {
		if (disabled) setIsValid(false);
		else
			setIsValid(
				transactionDateValRes.isValid &&
					referenceValRes.isValid &&
					invalidLines.length === 0 &&
					total === 0
			);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [
		setIsValid,
		transactionDateValRes.isValid,
		referenceValRes.isValid,
		invalidLines,
		total,
		data,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.accounting.default.text, path: pages.accounting.dashboard.path },
			{
				title: pages.accounting.journals.text,
				path: pages.accounting.journals.path,
			},
			{
				title: data.id === 0 ? `New Journal Entry` : `Journal Entry ${data.id}`,
				isActive: true,
			},
		]);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [data]);

	useEffect(() => {
		if (!isLoading && data.id === 0) data.outlet = userContext.data.selectedOutlet;
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	return (
		<Portlet
			fluid='fluid'
			className='sdms-bg-transparent sdms-portlet--unelevate sdms-portlet--portletception sdms-mb-0'>
			<Portlet.Body className='sdms-last-margin sdms-p0 overflow-hidden'>
				<Portlet className='flex-grow-0 sdms-portlet__modifiers sdms-min-h-fit-content'>
					<Portlet.Body>
						<FormGroup>
							{getBanner()}
							<Loading isLoading={isLoading}>
								<FormField
									name='transactionDate'
									label='Transaction Date'
									id={data.id}
									loadingContainer
									colMd={3}
									valRes={transactionDateValRes}
									showValidation={transactionDateShowVal}>
									<DatePicker
										id='transactionDate'
										type='calendar'
										placeholder='Transaction Date'
										value={
											transactionDate
												? convertDateToUTC(moment(transactionDate).toDate())
												: null
										}
										onChange={e => {
											transactionDateOnChange({
												target: {
													value: e.target.value
														? moment(transactionDate || undefined)
																.utc(false)
																.year(e.target.value.getFullYear())
																.month(e.target.value.getMonth())
																.date(e.target.value.getDate())
																.toISOString()
														: null,
												},
											});
										}}
										onBlur={setTransactionDateShowVal}
										disabled={disabled}
										minDate={getAccountingClosedDate(userContext)}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='reference'
									label='Reference'
									id={data.id}
									loadingContainer
									colMd={3}
									valRes={referenceValRes}
									showValidation={referenceShowVal}>
									<Input
										onChange={referenceOnChange}
										value={reference}
										onBlur={setReferenceShowVal}
										disabled={disabled}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Portlet.Body>
				</Portlet>
				<Portlet className='sdms-list-layout' fluid='fluid' border>
					<Portlet.Head>
						<Portlet.HeadLabelTitle>Lines</Portlet.HeadLabelTitle>
						<Portlet.HeadToolbarActions>
							<Button
								label='brand'
								icon='Plus'
								text='New Line'
								key='newLine'
								size='sm'
								onClick={() => {
									const lineId = generateId(data.journalLines || []);

									if (!data.journalLines) data.journalLines = [];

									data.journalLines.push({
										id: lineId,
										account: null,
										amount: '',
										memo: '',
										customer: null,
										reservationItem: null,
										taxAgency: null,
										isDebit: false,
									});

									setInvalidLines([...invalidLines, lineId]);
								}}
								disabled={disabled}
							/>
						</Portlet.HeadToolbarActions>
					</Portlet.Head>
					<ListBody
						className='table--everytime--scroll sdms-portlet__body--fit'
						responsive='scroll'>
						<ListTable>
							<colgroup>
								<col />
								<col style={{ width: 150 }} />
								<col />
								<col />
								<col />
								<col />
								<col style={{ width: 110 }} />
								<col style={{ width: 110 }} />
							</colgroup>
							<thead>
								<tr>
									<th>Account</th>
									<th>Amount</th>
									<th>Memo</th>
									<th>Customer</th>
									<th>Booking</th>
									<th>Tax Agency</th>
									<th>Is Debit?</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{data.journalLines &&
									data.journalLines.map(jl => (
										<JournalLine
											key={jl.id}
											data={jl}
											accounts={accounts}
											onFormChange={onFormChange}
											isSubmitted={isSubmitted}
											setIsValid={_isValid => {
												if (_isValid)
													setInvalidLines(
														invalidLines.filter(
															lineId => lineId !== jl.id
														)
													);

												if (!_isValid && invalidLines.indexOf(jl.id) === -1)
													setInvalidLines([...invalidLines, jl.id]);
											}}
											disabled={disabled || isLoading}
											onDelete={() => {
												data.journalLines = data.journalLines.filter(
													_jl => _jl.id !== jl.id
												);

												setInvalidLines(
													invalidLines.filter(lineId => lineId !== jl.id)
												);
											}}
											onChange={() => setTotal(getTotal())}
										/>
									))}
							</tbody>
						</ListTable>
					</ListBody>
				</Portlet>
				<Portlet className='flex-grow-0 sdms-portlet__modifiers sdms-min-h-fit-content'>
					<Portlet.Body>
						<div className='row' style={{ justifyContent: 'center' }}>
							<div
								className={classNames('col-4 sdms-align-center', {
									'sdms-color-danger': total !== 0,
								})}>
								<h5>Sum of Debit and Credit Lines : {priceFormatter(total)}</h5>
							</div>
						</div>
					</Portlet.Body>
				</Portlet>
			</Portlet.Body>
		</Portlet>
	);
};
JournalForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		transactionDate: PropTypes.string,
		references: PropTypes.string,
		journalLines: PropTypes.arrayOf(PropTypes.object),
		outlet: PropTypes.object,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
	accounts: PropTypes.arrayOf(PropTypes.object),
};
JournalForm.defaultProps = {
	data: {
		id: 0,
		transactionDate: null,
		references: null,
		journalLines: [],
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
	accounts: [],
};

export default JournalForm;
