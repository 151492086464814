import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import classNames from 'classnames';
import moment from 'moment';

import { addErrorNotification } from '../../../utils/helpers/helper';
import apiCall from '../../../utils/helpers/apiCall';

import 'react-calendar/dist/Calendar.css';
import '../../../assets/availabilityCalendar.css';

const AvailabilityCalendar = ({ products, outlet, inModal, showSingle, minStartDate }) => {
	const [isLoading, setIsLoading] = useState(true);

	const [availability, setAvailability] = useState({});

	const isAllNightly = useRef(false);

	const abortController = useRef();

	const today = useRef(moment());

	const onChange = currentDate => {
		setIsLoading(true);

		if (abortController.current) abortController.current.abort();

		abortController.current = new AbortController();

		apiCall(
			'POST',
			'availabilityCalendar',
			res => {
				isAllNightly.current = res.isAllNightly;
				setAvailability(res.availability);
				setIsLoading(false);
			},
			err => {
				if (err.toString().search('AbortError') === -1) {
					addErrorNotification(err.toString());
					setIsLoading(false);
				}
			},
			'',
			{
				products,
				startDate: moment(currentDate)
					.startOf('month')
					.add(-1, 'day')
					.format('YYYY-MM-DD'),
				endDate: moment(currentDate)
					.add(1, 'month')
					.endOf('month')
					.format('YYYY-MM-DD'),
				outlet,
			},
			null,
			abortController.current.signal
		);
	};

	useEffect(() => {
		onChange(
			minStartDate && new Date(minStartDate) > new Date()
				? new Date(minStartDate)
				: new Date()
		);

		return () => {
			if (abortController.current) abortController.current.abort();
		};
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<Calendar
			className={classNames('sdms-availability-calendar', {
				'sdms-availability-calendar-inline': !inModal,
				'sdms-availability-calendar-modal': inModal,
				'sdms-availability-calendar-nightly': isAllNightly.current,
			})}
			onChange={() => {}}
			value={null}
			showDoubleView={!showSingle}
			tileClassName={({ date }) => {
				if (isLoading) return 'sdms-availability-calendar-loading';

				const dateKey = moment(date).format('YYYY-MM-DD');

				if (today.current.format('YYYY-MM-DD') > dateKey)
					return 'sdms-availability-calendar-past';

				if (availability) {
					if (isAllNightly.current) {
						const previousDayKey = moment(date)
							.add(-1, 'day')
							.format('YYYY-MM-DD');

						if (!availability[dateKey] && !availability[previousDayKey])
							return 'sdms-availability-calendar-disable';

						if (!availability[dateKey] && availability[previousDayKey])
							return 'sdms-availability-calendar-nightly-disable-start';

						if (availability[dateKey] && !availability[previousDayKey])
							return 'sdms-availability-calendar-nightly-disable-end';

						if (!availability[dateKey]) return 'sdms-availability-calendar-disable';

						return 'sdms-availability-calendar-available';
					}

					if (!availability[dateKey]) return 'sdms-availability-calendar-disable';

					return 'sdms-availability-calendar-available';
				}

				return 'sdms-availability-calendar-disable';
			}}
			defaultActiveStartDate={
				minStartDate && new Date(minStartDate) > new Date()
					? new Date(minStartDate)
					: new Date()
			}
			onActiveStartDateChange={({ activeStartDate }) => {
				onChange(activeStartDate);
			}}
			calendarType='US'
			minDetail='month'
		/>
	);
};

AvailabilityCalendar.propTypes = {
	products: PropTypes.arrayOf(PropTypes.number),
	inModal: PropTypes.bool,
	outlet: PropTypes.number,
	showSingle: PropTypes.bool,
	minStartDate: PropTypes.string,
};

AvailabilityCalendar.defaultProps = {
	products: [],
	inModal: false,
	outlet: 0,
	showSingle: false,
	minStartDate: null,
};

export default AvailabilityCalendar;
