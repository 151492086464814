import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import useOutlet from '../../../utils/hooks/useOutlet';
import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';

import FormContainer from '../template/FormContainer';
import ReservationForm from './ReservationForm';
import OutletInactive from '../element/OutletInactive';
import { hasOverrideConstraintPermission } from '../../../utils/helpers/permission';

const ReservationEdit = ({ match }) => {
	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const history = useHistory();

	const location = useLocation(); // React Hook

	const [, outletConfigured] = useOutlet();

	const pages = usePages();

	const data = useMemo(() => {
		if (location.pathname.indexOf('marina') > -1)
			return {
				pageTitle: pages.marina.reservations.text,
				icon: pages.marina.reservations.icon,
				module: modules.MARINA,
				url: pages.marina.reservations.path,
			};

		if (location.pathname.indexOf('campground') > -1)
			return {
				pageTitle: pages.campground.reservations.text,
				icon: pages.campground.reservations.icon,
				module: modules.CAMPGROUND,
				url: pages.campground.reservations.path,
			};

		return {
			pageTitle: pages.booking.reservations.text,
			icon: pages.booking.reservations.icon,
			module: modules.BOOKINGS,
			url: pages.booking.reservations.path,
		};
	}, [location, pages]);

	if (!outletConfigured) return <OutletInactive />;

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='reservations'
			fields={
				<ReservationForm
					outlet={userContext.data.selectedOutlet}
					headerContext={headerContext}
					history={history}
					overrideConstraints={hasOverrideConstraintPermission(userContext)}
					enableHouseAccount={userContext.hasPermission('enable_house_account')}
					saveCreditCards={userContext.hasPermission('save_credit_cards')}
					passwordSettings={userContext.data.user.company.systemSettings}
					customOnBack={() => history.push(data.url)}
				/>
			}
			pageTitle='New Reservation'
			icon={pages.booking.reservations.icon}
			showSaveButton={false}
			withHeader={false}
			lists={[
				'bookingTypes',
				'paymentTerms',
				'paymentMethods',
				'countries',
				'states',
				'enumRvTypes',
				'enumVehicleMakes',
				'enumBoatTypes',
				'enumBoatMakes',
			]}
			module={data.module}
		/>
	);
};

ReservationEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ReservationEdit;
