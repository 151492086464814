import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';
import UserContext from '../../../app/contexts/UserContext';
import forms from '../../forms';
import Portal from '../../reusables/layout/Portal';
import DialogBox from '../../reusables/element/DialogBox';
import apiCall from '../../../utils/helpers/apiCall';
import { addErrorNotification, addSuccessNotification } from '../../../utils/helpers/helper';

const UsedSectionCell = ({ data }) => {
	return (
		<span data-tip='' data-for={data.id.toString()} data-event='click'>
			{data.modifierSections.length}
			<Portal>
				<ReactTooltip
					id={data.id.toString()}
					place='bottom'
					effect='solid'
					type='light'
					globalEventOff='click'
					clickable
					className='sdms-date-range-picker'>
					<table>
						<tbody>
							<tr>
								<td>Product</td>
								<td>Section</td>
							</tr>
							{data.modifierSections.map(ms => (
								<tr key={`modifier-sections-${ms.id}`}>
									<td>{ms.product.name}</td>
									<td>{ms.title}</td>
								</tr>
							))}
						</tbody>
					</table>
				</ReactTooltip>
			</Portal>
		</span>
	);
};

UsedSectionCell.propTypes = {
	data: {
		id: PropTypes.number,
		modifierSections: PropTypes.arrayOf(PropTypes.object),
	},
};

UsedSectionCell.defaultProps = {
	data: {
		id: 0,
		modifierSections: [],
	},
};

const ModifierGroupList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.pos.default.text, path: pages.pos.dashboard.path },
			{ title: pages.pos.modifierGroups.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.pos.modifierGroups.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	const [listKey, setListKey] = useState(Math.random());

	const clearUnusedGroups = () => {
		apiCall(
			'POST',
			'clearUnUsedModifierGroups',
			res => {
				if (res.removedGroups) {
					addSuccessNotification(
						`Groups deleted successfully: ${res.removedGroups.join(',')}`
					);
					setListKey(Math.random());
				}
				setIsDialogBoxOpen(false);
			},
			err => {
				addErrorNotification(err.toString());
			}
		);
	};

	return (
		<>
			<ListContainer
				key={listKey}
				route='modifierGroups'
				history={history}
				title='Modifier Groups'
				forms={forms.pos.modifierGroups}
				customActions={{
					clear: () => setIsDialogBoxOpen(true),
				}}>
				<List withCheckBox fluid='fluid'>
					<List.Col
						key={`modifierGroups-list-col-name-${listKey}`}
						label='Name'
						cellData='name'
						isLinkColumn={userContext.hasPermission('edit_modifiers_group')}
						sortable='name'
					/>
					<List.Col
						key={`modifierGroups-list-col-used-${listKey}`}
						label='Used Sections'
						cellComponent={<UsedSectionCell />}
					/>
					<List.Col align='right' onlyHover width={150}>
						<Button
							key='edit'
							className='sdms-margin-r-15'
							design='link'
							text='Edit'
							icon='Edit'
							size='sm'
							elevate
							noPermission={!userContext.hasPermission('edit_modifiers_group')}
						/>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item
								key='delete'
								itemsColor='danger'
								icon='Erase'
								noPermission={!userContext.hasPermission('delete_modifiers_group')}>
								Delete
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			<DialogBox
				open={isDialogBoxOpen}
				title=''
				content='Are you sure?'
				type='question'
				onClose={() => setIsDialogBoxOpen(false)}>
				<Button
					className='sdms-font-transform-c'
					label='danger'
					text='Clear'
					icon='Angle-right-circle'
					onClick={() => clearUnusedGroups()}
				/>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					text='Cancel'
					icon='Clipboard-list'
					onClick={() => setIsDialogBoxOpen(false)}
				/>
			</DialogBox>
		</>
	);
};
export default ModifierGroupList;

ModifierGroupList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};
