import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import useField from '../../../utils/hooks/useField';
import { maxLength, numeric, required, uniqueFnc } from '../../../utils/helpers/validation';
import HeaderContext from '../../../app/contexts/HeaderContext';
import { generateId, numberParser } from '../../../utils/helpers/helper';
import { enumItemTypes } from '../../../utils/constants/constants';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import ImageUpload from '../../reusables/field/ImageUpload';
import Input from '../../reusables/field/Input';
import ModifierSection from './ModifierSection';
import MultiSelect from '../../reusables/element/MultiSelect';
import Section from '../../reusables/layout/Section';
import Selects from '../../reusables/field/Selects';
import Toggle from '../../reusables/field/Toggle';
import Wizard from '../../reusables/template/Wizard';
import Loading from '../../reusables/template/Loading';
import Button from '../../reusables/element/Button';
import pages from '../../pages';
import UserContext from '../../../app/contexts/UserContext';
import { modules } from '../../../utils/helpers/apiCall';
import Textarea from '../../reusables/field/Textarea';
import Portlet from '../../reusables/layout/Portlet';
import { ListTable, ListBody } from '../../reusables/template/List';

const AlternativeSkuItem = ({ data, onFormChange, setIsValid, onDelete, isSubmitted }) => {
	const [sku, skuOnChange, skuValRes, skuShowVal, setSkuShowVal] = useField(
		data,
		'sku',
		onFormChange,
		[required],
		null
	);

	useEffect(() => {
		if (isSubmitted) setSkuShowVal();
	}, [isSubmitted, skuShowVal, setSkuShowVal]);

	useEffect(() => {
		setIsValid(skuValRes.isValid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [skuValRes.isValid]);

	return (
		<tr>
			<th>
				<FormField
					name='sku'
					id={data.id}
					inFormDesign={false}
					valRes={skuValRes}
					showValidation={skuShowVal}
					isLast>
					<Input
						type='text'
						placeholder='SKU'
						value={sku}
						onChange={skuOnChange}
						onBlur={setSkuShowVal}
					/>
				</FormField>
			</th>
			<th>
				<Button
					btnIcon
					label='danger'
					icon='Trash'
					size='sm'
					elevate
					key='delete'
					onClick={onDelete}
				/>
			</th>
		</tr>
	);
};

AlternativeSkuItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	onFormChange: PropTypes.func.isRequired,
	isSubmitted: PropTypes.bool.isRequired,
	setIsValid: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

const ProductForm = ({
	data,
	isValid,
	setIsValid,
	submit,
	isSubmitted,
	setTitle,
	accounts,
	itemTypes,
	taxRates,
	taxCodes,
	unitMeasures,
	productCategories,
	isLoading,
	prepStations,
	submitButtonAttr,
	onFormChange,
	modifierGroups,
	modifierPrefixes,
	isSubmitting,
	enumProductServiceFees,
}) => {
	const userContext = useContext(UserContext);

	const posModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.POS)
	);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [sku, skuOnChange, skuValRes, skuShowVal, setSkuShowVal] = useField(
		data,
		'sku',
		onFormChange,
		[maxLength(), uniqueFnc('products', 'sku', data.id, false)]
	);

	const [productImage, setProductImage] = useField(data, 'productImage', onFormChange, [], null);

	const [price, priceOnChange, priceValRes, priceShowVal, setPriceShowVal] = useField(
		data,
		'price',
		onFormChange,
		[required, numeric],
		0,
		numberParser(true, 0)
	);

	const [additionalTaxes, additionalTaxesOnChange] = useField(
		data,
		'additionalTaxes',
		onFormChange,
		[],
		[]
	);

	const [account, accountOnChange, accountValRes, accountShowVal, setAccountShowVal] = useField(
		data,
		'account',
		onFormChange,
		[required],
		null
	);

	const [
		assetsAccount,
		assetsAccountOnChange,
		assetsAccountValRes,
		assetsAccountShowVal,
		setAssetsAccountShowVal,
	] = useField(data, 'assetsAccount', onFormChange, [required], null);

	const [
		incomeAccount,
		incomeAccountOnChange,
		incomeAccountValRes,
		incomeAccountShowVal,
		setIncomeAccountShowVal,
	] = useField(data, 'incomeAccount', onFormChange, [required], null);

	const [
		cogsAccount,
		cogsAccountOnChange,
		cogsAccountValRes,
		cogsAccountShowVal,
		setCogsAccountShowVal,
	] = useField(data, 'cogsAccount', onFormChange, [required], null);

	const [serviceFee, serviceFeeOnChange] = useField(data, 'serviceFee', onFormChange, [], null);

	const [taxCode, taxCodeOnChange, taxCodeValRes, taxCodeShowVal, setTaxCodeShowVal] = useField(
		data,
		'taxCode',
		onFormChange,
		[required],
		null
	);

	const [unitMeasure, unitMeasureOnChange] = useField(
		data,
		'unitMeasure',
		onFormChange,
		[],
		null
	);

	const [
		itemType,
		itemTypeOnChange,
		itemTypeValRes,
		itemTypeShowVal,
		setItemTypeShowVal,
	] = useField(data, 'itemType', onFormChange, [required], null);

	const [productCategory, productCategoryOnChange] = useField(
		data,
		'productCategory',
		onFormChange,
		[],
		null
	);

	const [
		description,
		descriptionOnChange,
		descriptionValRes,
		descriptionShowVal,
		setDescriptionShowVal,
	] = useField(data, 'description', onFormChange, [maxLength(4095)], '');

	const [disableInPos, disableInPosOnChange] = useField(
		data,
		'disableInPos',
		onFormChange,
		[],
		false
	);

	const [disableSearchMore, disableSearchMoreOnChange] = useField(
		data,
		'disableSearchMore',
		onFormChange,
		[],
		false
	);

	const [addByPrice, addByPriceOnChange] = useField(data, 'addByPrice', onFormChange, [], false);

	const [addByQuantity, addByQuantityOnChange] = useField(
		data,
		'addByQuantity',
		onFormChange,
		[],
		false
	);

	const [calculateQuantity, calculateQuantityOnChange] = useField(
		data,
		'calculateQuantity',
		onFormChange,
		[],
		false
	);

	const [isDepositProduct, isDepositProductOnChange] = useField(
		data,
		'isDepositProduct',
		onFormChange,
		[],
		false
	);

	const [alwaysModify, alwaysModifyOnChange] = useField(
		data,
		'alwaysModify',
		onFormChange,
		[],
		false
	);

	const [assignedPrepStations, assignedPrepStationsOnChange] = useField(
		data,
		'prepStations',
		onFormChange,
		[],
		[]
	);

	const [inactive, inactiveOnChange] = useField(data, 'inactive', onFormChange, [], false);

	const [isDryStack, isDryStackOnChange] = useField(data, 'isDryStack', onFormChange, [], false);

	const headerContext = useContext(HeaderContext);

	const invalidSections = useRef([]);

	const updateInvalidSections = (sectionId, isSectionValid) => {
		const ind = invalidSections.current.indexOf(sectionId);
		if (!isSectionValid && ind === -1)
			invalidSections.current = update(invalidSections.current, { $push: [sectionId] });
		else if (isSectionValid && ind > -1)
			invalidSections.current = update(invalidSections.current, { $splice: [[ind, 1]] });

		setIsValid(
			nameValRes.isValid &&
				priceValRes.isValid &&
				taxCodeValRes.isValid &&
				itemTypeValRes.isValid &&
				(itemType.value === enumItemTypes.INVENTORY
					? cogsAccountValRes.isValid &&
					  incomeAccountValRes.isValid &&
					  assetsAccountValRes.isValid
					: accountValRes.isValid) &&
				skuValRes.isValid &&
				invalidSections.current.length === 0 &&
				descriptionValRes.isValid
		);
	};

	const [openSection, setOpenSection] = useState(null);

	const reducer = (state, action) => {
		const { type, payload } = action;

		if (type === 'set') {
			const modifierSections = payload
				? payload.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
				: [];

			return update(state, {
				$set: modifierSections.map((s, index) => {
					s.isLinked =
						modifierGroups.filter(
							mg => mg.id === s.modifierGroup.id && mg.modifierSections.length > 1
						).length > 0;

					s.sortOrder = index;

					return s;
				}),
			});
		}

		if (type === 'add') {
			onFormChange();
			const sectionId = generateId(state);
			state = update(state, {
				$push: [
					{
						id: sectionId,
						title: '',
						modifierGroup: {
							id: 0,
							modifierPrefixes: [],
							modifiers: [],
						},
						sortOrder: state.length,
					},
				],
			});
			setOpenSection(`modifiers_section_${sectionId}`);
			data.modifierSections = [...state];
		}

		if (type === 'update') {
			onFormChange();
			const sectionIndex = state.findIndex(ms => ms.id === payload.id);
			state = update(state, {
				[sectionIndex]: {
					$set: payload,
				},
			});
			data.modifierSections = [...state];

			if (data.modifierSections.findIndex(ms => ms.minQuantity > 0) !== -1) {
				alwaysModifyOnChange({ target: { name: 'alwaysModify', value: true } });
				disabledAlwaysModify.current = true;
			} else if (disabledAlwaysModify.current) {
				alwaysModifyOnChange({ target: { name: 'alwaysModify', value: false } });
				disabledAlwaysModify.current = false;
			}
		}

		if (type === 'linkGroup') {
			onFormChange();
			const modifierSectionIndex = state.findIndex(s => s.id === payload.id);

			state.map(s => {
				if (s.modifierGroup.id === payload.source.id) s.isLinked = true;
				return s;
			});

			const newGroup = update(payload.source, { $unset: ['modifierSections'] });

			state = update(state, {
				[modifierSectionIndex]: {
					modifierGroup: { $set: newGroup },
					isLinked: { $set: true },
				},
			});

			data.modifierSections = [...state];
		}

		if (type === 'copyGroup') {
			onFormChange();

			const sectionIndex = state.findIndex(s => s.id === payload.id);

			modifierGroups = modifierGroups.map(mg => {
				mg.modifierSections = mg.modifierSections.filter(ms => ms.id !== payload.id);
				return mg;
			});

			state = state.map(s => {
				s.isLinked =
					modifierGroups.filter(
						mg => mg.id === s.modifierGroup.id && mg.modifierSections.length > 0
					).length > 0;

				return s;
			});

			let newGroup = update(payload.source, {
				$unset: ['@id', '@type', 'modifierSections'],
				id: { $set: 0 },
				name: { $set: `${payload.source.name} - copy` },
			});

			// clear modifier and overrides for create new copy.
			newGroup.modifiers.forEach((m, i) => {
				newGroup = update(newGroup, {
					modifiers: {
						[i]: {
							$unset: ['@id', '@type'],
							id: { $set: generateId(newGroup.modifiers) },
							overrides: {
								$set: newGroup.modifiers[i].overrides.map(o => {
									delete o.id;
									delete o['@id'];
									delete o['@type'];
									return o;
								}),
							},
						},
					},
				});
			});

			state = update(state, {
				[sectionIndex]: {
					modifierGroup: { $set: newGroup },
				},
			});

			data.modifierSections = [...state];
		}

		if (type === 'delete') {
			onFormChange();

			const sectionIndex = state.findIndex(s => s.id === payload);

			modifierGroups = modifierGroups.map(mg => {
				mg.modifierSections = mg.modifierSections.filter(ms => ms.id !== payload);
				return mg;
			});

			state = state.map(s => {
				s.isLinked =
					modifierGroups.filter(
						mg => mg.id === s.modifierGroup.id && mg.modifierSections.length > 0
					).length > 0;

				return s;
			});

			state = update(state, { $splice: [[sectionIndex, 1]] });

			state = state.map((s, index) => {
				s.sortOrder = index;
				return s;
			});

			data.modifierSections = [...state];
		}

		if (type === 'clearModifierGroup') {
			onFormChange();

			const sectionIndex = state.findIndex(s => s.id === payload);

			modifierGroups = modifierGroups.map(mg => {
				mg.modifierSections = mg.modifierSections.filter(ms => ms.id !== payload);
				return mg;
			});

			state = state.map(s => {
				s.isLinked =
					modifierGroups.filter(
						mg => mg.id === s.modifierGroup.id && mg.modifierSections.length > 0
					).length > 0;

				return s;
			});

			state = update(state, {
				[sectionIndex]: {
					$merge: {
						modifierGroup: {
							id: 0,
							modifierPrefixes: [],
							modifiers: [],
						},
						isLinked: false,
					},
				},
			});

			data.modifierSections = [...state];
		}

		if (type === 'moveModifierSection') {
			state = update(state, {
				[payload.from]: {
					sortOrder: { $set: payload.to },
				},
				[payload.to]: {
					sortOrder: { $set: payload.from },
				},
			});

			state = state.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));

			data.modifierSections = [...state];
		}

		if (state.length === 0)
			alwaysModifyOnChange({ target: { name: 'alwaysModify', value: false } });

		return state;
	};

	const disabledAlwaysModify = useRef(false);

	const [modifierSections, dispatch] = useReducer(reducer, []);

	const alternativeSkusValidation = (value, setValRes) => {
		if (value.some(v => v.isValid === false)) {
			setValRes({
				isValid: false,
				status: 'required',
				message: 'Field is required',
			});

			return false;
		}

		return true;
	};

	const [alternativeSkus, alternativeSkusOnChange, alternativeSkusValRes] = useField(
		data,
		'alternativeSkus',
		onFormChange,
		[alternativeSkusValidation],
		[]
	);

	// useEffect to make validation errors visible
	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setPriceShowVal();
			setTaxCodeShowVal();
			setItemTypeShowVal();
			setAccountShowVal();
			setIncomeAccountShowVal();
			setCogsAccountShowVal();
			setAssetsAccountShowVal();
			setSkuShowVal();
			setDescriptionShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setPriceShowVal,
		setTaxCodeShowVal,
		setItemTypeShowVal,
		setAccountShowVal,
		setIncomeAccountShowVal,
		setCogsAccountShowVal,
		setAssetsAccountShowVal,
		setSkuShowVal,
		setDescriptionShowVal,
	]);
	// useEffect to update form validity
	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				priceValRes.isValid &&
				taxCodeValRes.isValid &&
				itemTypeValRes.isValid &&
				descriptionValRes.isValid &&
				(itemType.value === enumItemTypes.INVENTORY
					? cogsAccountValRes.isValid &&
					  incomeAccountValRes.isValid &&
					  assetsAccountValRes.isValid
					: accountValRes.isValid) &&
				skuValRes.isValid &&
				invalidSections.current.length === 0 &&
				alternativeSkusValRes.isValid
		);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [
		nameValRes.isValid,
		priceValRes.isValid,
		taxCodeValRes.isValid,
		itemTypeValRes.isValid,
		accountValRes.isValid,
		incomeAccountValRes.isValid,
		cogsAccountValRes.isValid,
		assetsAccountValRes.isValid,
		skuValRes.isValid,
		invalidSections.current,
		descriptionValRes.isValid,
		alternativeSkusValRes.isValid,
		setIsValid,
	]);
	// useEffect for modifierSection initialization
	useEffect(() => {
		if (!isLoading) dispatch({ type: 'set', payload: data.modifierSections });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, modifierGroups]);

	useEffect(() => {
		if (!isSubmitting && !isLoading) dispatch({ type: 'set', payload: data.modifierSections });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitting]);

	// useEffect for breadcrumbs
	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.pos.default.text, path: pages.pos.dashboard.path },
			{ title: pages.pos.products.text, path: pages.pos.products.path },
			{ title: name || `New ${pages.pos.products.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.pos.products.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		data.module = posModule.current;
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	const isGeneralStateValid = () => {
		if (!isSubmitted) return true;

		if (isValid) return true;

		return (
			nameValRes.isValid &&
			priceValRes.isValid &&
			taxCodeValRes.isValid &&
			itemTypeValRes.isValid &&
			(itemType.value === enumItemTypes.INVENTORY
				? cogsAccountValRes.isValid &&
				  incomeAccountValRes.isValid &&
				  assetsAccountValRes.isValid
				: accountValRes.isValid) &&
			skuValRes.isValid &&
			descriptionValRes.isValid
		);
	};

	return (
		<Wizard onSubmit={submit} submitButtonAttr={submitButtonAttr} isLoading={isLoading}>
			<Wizard.Item
				title='General'
				description='General Information'
				isValid={isGeneralStateValid()}>
				<Section>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='productId'
									label='Item ID'
									id={data.id}
									loadingContainer
									colLg={6}>
									<Input
										type='text'
										placeholder='Auto Generate'
										value={data.productId}
										disabled
										onChange={() => {}}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='name'
									label='Name'
									id={data.id}
									valRes={nameValRes}
									showValidation={nameShowVal}
									colLg={6}>
									<Input
										type='text'
										placeholder='Name (Required)'
										value={name}
										onChange={nameOnChange}
										onBlur={setNameShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='sku'
									label='Sku'
									id={data.id}
									valRes={skuValRes}
									showValidation={skuShowVal}
									loadingContainer
									colLg={6}>
									<Input
										type='text'
										placeholder='Sku'
										value={sku}
										onChange={skuOnChange}
										onBlur={setSkuShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='price'
									label='Price'
									id={data.id}
									valRes={priceValRes}
									showValidation={priceShowVal}
									colLg={6}>
									<Input
										type='text'
										placeholder='Price (Required)'
										value={price.toString()}
										onChange={priceOnChange}
										onBlur={setPriceShowVal}
										pattern={process.env.REACT_APP_PRICE_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='itemType'
									label='Item Type'
									id={data.id}
									valRes={itemTypeValRes}
									showValidation={itemTypeShowVal}
									colLg={6}>
									<Selects
										options={itemTypes.filter(it => !it.isSystem)}
										placeholder='Item Type (Required)'
										value={itemType}
										onChange={({ target }) => {
											itemTypeOnChange({ target });
											accountOnChange({
												target: { name: 'account', value: {} },
											});
											incomeAccountOnChange({
												target: {
													name: 'incomeAccount',
													value: {},
												},
											});
											cogsAccountOnChange({
												target: { name: 'cogsAccount', value: {} },
											});
											assetsAccountOnChange({
												target: {
													name: 'assetsAccount',
													value: {},
												},
											});
										}}
										onBlur={setItemTypeShowVal}
										displayKey='value'
										disabled={data.id !== 0}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='productCategory'
									label='Category'
									id={data.id}
									colLg={6}>
									<Selects
										options={productCategories}
										placeholder='Category'
										value={productCategory}
										onChange={productCategoryOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='unitMeasure'
									label='Unit of Measure'
									id={data.id}
									colLg={6}>
									<Selects
										options={unitMeasures}
										placeholder='Unit of Measure'
										value={unitMeasure}
										onChange={unitMeasureOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='productImage'
									label='Image'
									description='.png, .jpg or .jpeg only'
									colLg={6}>
									<ImageUpload
										media={productImage}
										setMedia={image =>
											setProductImage({
												target: {
													name: 'productImage',
													value: image,
												},
											})
										}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='description'
									label='Description'
									id={data.id}
									colXl={12}
									showValidation={descriptionShowVal}
									valRes={descriptionValRes}>
									<Textarea
										type='text'
										placeholder='Description'
										value={description}
										onChange={descriptionOnChange}
										textRow={4}
										onBlur={setDescriptionShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField name='inactive' label='Inactive' id={data.id} col={6}>
									<Toggle value={inactive} onChange={inactiveOnChange} />
								</FormField>
							</Loading>
							<div className='sdms-heading sdms-heading--md col-12'>Accounting</div>
							<Loading isLoading={isLoading}>
								<FormField
									name='taxCode'
									label='Tax Code'
									id={data.id}
									valRes={taxCodeValRes}
									showValidation={taxCodeShowVal}
									colLg={6}>
									<Selects
										options={taxCodes}
										placeholder='Tax Code (Required)'
										value={taxCode}
										onChange={taxCodeOnChange}
										onBlur={setTaxCodeShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='taxRate'
									label='Additional Taxes'
									id={data.id}
									colLg={6}
									noPermission={
										!userContext.hasPermission('edit_pos_additional_tax')
									}>
									<Selects
										options={taxRates.filter(
											tr =>
												tr.id !==
													userContext.data.selectedOutlet.settings.taxRate
														.id && !tr.isGroup
										)}
										multiple
										placeholder='Tax Rate'
										value={additionalTaxes}
										onChange={additionalTaxesOnChange}
										noPermission={
											!userContext.hasPermission('edit_pos_additional_tax')
										}
									/>
								</FormField>
							</Loading>
							{itemType && itemType.value === enumItemTypes.INVENTORY ? (
								<>
									<Loading isLoading={isLoading}>
										<FormField
											name='incomeAccount'
											label='Income Account'
											id={data.id}
											valRes={incomeAccountValRes}
											showValidation={incomeAccountShowVal}
											colLg={6}>
											<Selects
												options={accounts}
												placeholder='Income Account (Required)'
												value={incomeAccount}
												onChange={incomeAccountOnChange}
												onBlur={setIncomeAccountShowVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='cogsAccount'
											label='COGS Account'
											id={data.id}
											valRes={cogsAccountValRes}
											showValidation={cogsAccountShowVal}
											colLg={6}>
											<Selects
												options={accounts}
												placeholder='COGS Account (Required)'
												value={cogsAccount}
												onChange={cogsAccountOnChange}
												onBlur={setCogsAccountShowVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='assetsAccount'
											label='Assets Account'
											id={data.id}
											valRes={assetsAccountValRes}
											showValidation={assetsAccountShowVal}
											colLg={6}>
											<Selects
												options={accounts}
												placeholder='Assets Account (Required)'
												value={assetsAccount}
												onChange={assetsAccountOnChange}
												onBlur={setAssetsAccountShowVal}
											/>
										</FormField>
									</Loading>
								</>
							) : (
								<Loading isLoading={isLoading}>
									<FormField
										name='account'
										label='Account'
										id={data.id}
										valRes={accountValRes}
										showValidation={accountShowVal}
										colLg={6}>
										<Selects
											options={accounts}
											placeholder='Account (Required)'
											value={account}
											onChange={accountOnChange}
											onBlur={setAccountShowVal}
										/>
									</FormField>
								</Loading>
							)}
							<Loading isLoading={isLoading}>
								<FormField
									name='serviceFee'
									label='Service Fee'
									id={data.id}
									colLg={6}>
									<Selects
										options={enumProductServiceFees}
										placeholder='Service Fee'
										value={serviceFee}
										onChange={serviceFeeOnChange}
										displayKey='value'
									/>
								</FormField>
							</Loading>
							<div className='sdms-heading sdms-heading--md col-12'>
								Point of Sale
							</div>
							<Loading isLoading={isLoading}>
								<FormField
									name='addByPrice'
									label='Add By Price'
									id={data.id}
									col={3}>
									<Toggle
										value={addByPrice}
										onChange={e => {
											addByPriceOnChange(e);

											addByQuantityOnChange({
												target: { value: false },
											});
										}}
										disabled={addByQuantity}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='calculateQuantity'
									label='Calculate Quantity'
									id={data.id}
									col={3}>
									<Toggle
										value={calculateQuantity}
										onChange={e => {
											calculateQuantityOnChange(e);

											addByQuantityOnChange({
												target: { value: false },
											});
										}}
										disabled={addByQuantity}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='addByQuantity'
									label='Add By Quantity'
									id={data.id}
									col={6}>
									<Toggle
										value={addByQuantity}
										onChange={e => {
											addByQuantityOnChange(e);

											addByPriceOnChange({
												target: { value: false },
											});

											calculateQuantityOnChange({
												target: { value: false },
											});
										}}
										disabled={addByPrice || calculateQuantity}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='disableInPos'
									label='Disable In POS'
									id={data.id}
									col={6}>
									<Toggle value={disableInPos} onChange={disableInPosOnChange} />
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='disableSearchMore'
									label='Disable Search More'
									id={data.id}
									col={6}>
									<Toggle
										value={disableSearchMore}
										onChange={disableSearchMoreOnChange}
									/>
								</FormField>
							</Loading>
							<div className='sdms-heading sdms-heading--md col-12'>Bookings</div>
							<Loading isLoading={isLoading}>
								<FormField
									name='isDepositProduct'
									label='Deposit Product'
									id={data.id}
									col={6}>
									<Toggle
										value={isDepositProduct}
										onChange={isDepositProductOnChange}
									/>
								</FormField>
							</Loading>
							<div className='sdms-heading sdms-heading--md col-12'>Integrations</div>
							<Loading isLoading={isLoading}>
								<FormField
									name='isDryStack'
									label='Integrate to dry stack'
									id={data.id}
									col={6}>
									<Toggle value={isDryStack} onChange={isDryStackOnChange} />
								</FormField>
							</Loading>
							<Portlet className='sdms-list-layout' border>
								<Portlet.Head>
									<Portlet.HeadLabelTitle>
										Alternative Skus
									</Portlet.HeadLabelTitle>
									<Portlet.HeadToolbar>
										<Portlet.HeadActions className='sdms-last-margin--h d-flex'>
											<Button
												className='sdms-mr-0'
												label='brand'
												icon='Plus'
												text='Add New'
												size='sm'
												onClick={() =>
													alternativeSkusOnChange({
														target: {
															value: [
																...alternativeSkus,
																{ id: generateId(alternativeSkus) },
															],
														},
													})
												}
											/>
										</Portlet.HeadActions>
									</Portlet.HeadToolbar>
								</Portlet.Head>
								<ListBody
									className='table--everytime--scroll sdms-portlet__body--fit'
									responsive='scroll'>
									<ListTable childrenLength={1}>
										<colgroup>
											<col />
											<col width={180} />
										</colgroup>
										<tbody>
											{alternativeSkus.map(item => (
												<AlternativeSkuItem
													key={item.id}
													onDelete={() =>
														alternativeSkusOnChange({
															target: {
																value: alternativeSkus.filter(
																	i => i.id !== item.id
																),
															},
														})
													}
													setIsValid={_isValid => {
														const itemIndex = alternativeSkus.findIndex(
															i => i.id === item.id
														);

														alternativeSkusOnChange({
															target: {
																value: update(alternativeSkus, {
																	[itemIndex]: {
																		isValid: {
																			$set: _isValid,
																		},
																	},
																}),
															},
														});
													}}
													data={item}
													onFormChange={onFormChange}
													isSubmitted={isSubmitted}
												/>
											))}
										</tbody>
									</ListTable>
								</ListBody>
							</Portlet>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
			<Wizard.Item
				title='Modifiers'
				description='Product Modifiers'
				icon={pages.pos.modifierGroups.icon}
				isValid={isSubmitted ? invalidSections.current.length === 0 : true}>
				<Section>
					<Section.Body>
						<FormGroup row className='justify-content-between align-items-center'>
							<div className='col-lg'>
								<Button
									icon='Plus'
									label='brand'
									text='Add Modifier Section'
									size='sm'
									noPermission={
										!userContext.hasPermission('edit_modifiers_group')
									}
									onClick={() => dispatch({ type: 'add' })}
								/>
							</div>
							<div className='col-auto'>
								<FormField
									className='align-items-center d-flex form-group-last sdms-last-margin--h'
									name='alwaysModify'
									label='Always Modify'
									id={data.id}>
									<Toggle
										value={alwaysModify}
										onChange={alwaysModifyOnChange}
										disabled={
											modifierSections.length === 0 ||
											disabledAlwaysModify.current
										}
									/>
								</FormField>
							</div>
						</FormGroup>
						{modifierSections.map((ms, index) => (
							<ModifierSection
								key={ms.id}
								data={ms}
								onFormChange={onFormChange}
								isLoading={isLoading}
								setIsValid={setIsValid}
								isSubmitted={isSubmitted}
								prepStations={prepStations}
								modifierPrefixes={modifierPrefixes}
								setTitle={setTitle}
								updateInvalidSections={updateInvalidSections}
								dispatch={dispatch}
								isOpen={openSection === `modifiers_section_${ms.id}`}
								setOpenSection={setOpenSection}
								index={index}
							/>
						))}
					</Section.Body>
				</Section>
			</Wizard.Item>
			<Wizard.Item
				title='Prep Stations'
				description='Product Prep Stations'
				icon={pages.pos.settings.prepStations.icon}>
				<Section>
					<Section.Body>
						<FormGroup>
							<FormField
								name='prepStations'
								label='Select Prep Stations:'
								id={data.id}
								col={12}>
								<MultiSelect
									data={prepStations}
									titleProp='name'
									value={assignedPrepStations}
									onChange={assignedPrepStationsOnChange}
								/>
							</FormField>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
		</Wizard>
	);
};
ProductForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		productId: PropTypes.string,
		sku: PropTypes.string,
		productImage: PropTypes.object,
		price: PropTypes.number,
		additionalTaxes: PropTypes.arrayOf(PropTypes.object),
		account: PropTypes.object,
		serviceFee: PropTypes.object,
		assetsAccount: PropTypes.object,
		incomeAccount: PropTypes.object,
		cogsAccount: PropTypes.object,
		taxCode: PropTypes.object,
		unitMeasure: PropTypes.object,
		itemType: PropTypes.object,
		productCategory: PropTypes.object,
		disableInPos: PropTypes.bool,
		disableSearchMore: PropTypes.bool,
		addByPrice: PropTypes.bool,
		addByQuantity: PropTypes.bool,
		calculateQuantity: PropTypes.bool,
		isDepositProduct: PropTypes.bool,
		alwaysModify: PropTypes.bool,
		prepStations: PropTypes.array,
		modifierSections: PropTypes.array,
		module: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		alternativeSkus: PropTypes.arrayOf(PropTypes.object),
	}),
	accounts: PropTypes.arrayOf(PropTypes.object),
	taxRates: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	unitMeasures: PropTypes.arrayOf(PropTypes.object),
	productCategories: PropTypes.arrayOf(PropTypes.object),
	prepStations: PropTypes.arrayOf(PropTypes.object),
	itemTypes: PropTypes.arrayOf(PropTypes.object),
	modifierGroups: PropTypes.arrayOf(PropTypes.object),
	modifierPrefixes: PropTypes.arrayOf(PropTypes.object),
	isValid: PropTypes.bool,
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
	isSubmitting: PropTypes.bool,
	enumProductServiceFees: PropTypes.arrayOf(PropTypes.object),
};
ProductForm.defaultProps = {
	data: {
		id: 0,
	},
	accounts: [],
	taxRates: [],
	taxCodes: [],
	unitMeasures: [],
	productCategories: [],
	prepStations: [],
	itemTypes: [],
	modifierPrefixes: [],
	modifierGroups: [],
	isValid: false,
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
	isSubmitting: false,
	enumProductServiceFees: [],
};

export default ProductForm;
