import React, { useRef } from 'react';
import {
	PivotViewComponent,
	Inject,
	FieldList,
	CalculatedField,
	Toolbar,
	PDFExport,
	ExcelExport,
	ConditionalFormatting,
	NumberFormatting,
	GroupingBar,
	VirtualScroll,
	DrillThrough,
	Grouping,
} from '@syncfusion/ej2-react-pivotview';
import PropTypes from 'prop-types';

function PivotTable({ dataSource, fieldMapping, configRef }) {
	const pivotRef = useRef();

	function cellTemplate(args) {
		if (
			[...args.targetCell.classList].indexOf('e-rowsheader') === -1 &&
			args?.cellInfo?.value &&
			!Number.isNaN(parseFloat(args.cellInfo.value)) &&
			parseFloat(args.cellInfo.value) % 1 !== 0
		)
			args.targetCell.innerHTML = parseFloat(args.cellInfo.value).toFixed(2);

		return '';
	}

	const chartSeriesCreated = () => {
		pivotRef.current.chartSettings.chartSeries.legendShape =
			pivotRef.current.chartSettings.chartSeries.type === 'Polar'
				? 'Rectangle'
				: 'SeriesType';

		const {
			rows,
			columns,
			values,
			filters,
			filterSettings,
		} = pivotRef.current.properties.dataSourceSettings.properties;

		configRef.current = {
			rows: rows.map(item => ({
				...item.properties,
			})),
			columns: columns.map(item => ({
				...item.properties,
			})),
			values: values.map(item => ({
				...item.properties,
			})),
			filters: filters.map(item => ({
				...item.properties,
			})),
			filterSettings: filterSettings.map(item => ({
				...item.properties,
			})),
		};
	};

	const excelQueryCellInfo = args => {
		if (args?.cell.axis === 'value' && args?.cell.value === undefined)
			args.style.numberFormat = undefined;
	};

	return (
		<PivotViewComponent
			ref={pivotRef}
			dataSourceSettings={{
				dataSource,
				fieldMapping,
				columns: configRef?.current?.columns || [],
				rows: configRef?.current?.rows || [],
				values: configRef?.current?.values || [],
				filters: configRef?.current?.filters || [],
				enableSorting: true,
				expandAll: false,
				showHeaderWhenEmpty: false,
				formatSettings: [],
				filterSettings: configRef?.current?.filterSettings || [],
				groupSettings: [],
				conditionalFormatSettings: [],
				excludeFields: [],
				emptyCellsTextContent: '-',
			}}
			toolbar={['Grid', 'Chart', 'Export', 'SubTotal', 'GrandTotal', 'FieldList']}
			width='100%'
			height='100%'
			showFieldList
			exportAllPages={false}
			allowGrouping
			enableVirtualization
			enableValueSorting
			allowDeferLayoutUpdate
			allowDrillThrough
			gridSettings={{
				columnWidth: 110,
				allowSelection: true,
				rowHeight: 36,
				selectionSettings: {
					mode: 'Cell',
					type: 'Multiple',
					cellSelectionMode: 'Box',
				},
				excelQueryCellInfo,
			}}
			allowExcelExport
			allowPdfExport
			showToolbar
			displayOption={{ view: 'Both' }}
			chartSeriesCreated={chartSeriesCreated}
			cellTemplate={cellTemplate}>
			<Inject
				services={[
					FieldList,
					CalculatedField,
					Toolbar,
					PDFExport,
					ExcelExport,
					ConditionalFormatting,
					NumberFormatting,
					GroupingBar,
					Grouping,
					VirtualScroll,
					DrillThrough,
				]}
			/>
		</PivotViewComponent>
	);
}
export default PivotTable;

PivotTable.propTypes = {
	dataSource: PropTypes.arrayOf(PropTypes.shape({})),
	fieldMapping: PropTypes.arrayOf(PropTypes.shape({})),
	configRef: PropTypes.shape({
		current: null,
	}),
};

PivotTable.defaultProps = {
	dataSource: [],
	fieldMapping: [],
	configRef: {
		current: null,
	},
};
