import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Calendar } from 'react-yearly-calendar';
import update from 'immutability-helper';
import moment from 'moment';
import { motion } from 'framer-motion';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import Portlet from '../../reusables/layout/Portlet';
import FormGroup from '../../reusables/layout/FormGroup';
import Loading from '../../reusables/template/Loading';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Button from '../../reusables/element/Button';
import CalendarPeriodList from './CalendarPeriodList';
import CalendarPeriodForm from './CalendarPeriodForm';
import useField from '../../../utils/hooks/useField';
import { required, numeric } from '../../../utils/helpers/validation';
import {
	addErrorNotification,
	addSuccessNotification,
	generateId,
	numberParser,
} from '../../../utils/helpers/helper';
import { pageTransition, pageVariants } from '../../../utils/framer';

const CalendarForm = ({ data, setIsValid, isLoading, isSubmitted, onFormChange, setTitle }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const [willEditPeriodId, setWillEditPeriodId] = useState(null);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [year, yearOnChange, yearValRes, yearShowVal, setYearShowVal] = useField(
		data,
		'year',
		onFormChange,
		[required, numeric],
		new Date().getUTCFullYear(),
		numberParser(false)
	);

	const [calendarPeriods, calendarPeriodsOnChange] = useField(
		data,
		'calendarPeriods',
		onFormChange,
		[],
		[]
	);

	const onPeriodSave = period => {
		let _calendarPeriods = calendarPeriods;

		// if overwrite selected
		if (period.overwritePeriods) {
			_calendarPeriods = _calendarPeriods.map(cp => {
				if (cp.id === period.id) return cp;
				cp.calendarPeriodDays = cp.calendarPeriodDays.filter(
					cpd => period.days.findIndex(d => d.date === cpd.date) === -1
				);
				return cp;
			});
			addSuccessNotification(`${period.days.length} Period Days Added`);
		} else {
			const _periodDays = period.days.filter(
				pd =>
					calendarPeriods.filter(
						cp =>
							cp.calendarPeriodDays.filter(cpd => pd.date === cpd.date).length > 0 &&
							cp.id !== period.id
					).length === 0
			);

			const duplicateCount = period.days.length - _periodDays.length;

			// Printing Transaction Results
			if (duplicateCount > 0)
				addErrorNotification(`${duplicateCount} Period Day(s) already used`);
			if (_periodDays.length > 0)
				addSuccessNotification(`${_periodDays.length} Period Day(s) added`);

			period.days = _periodDays;
		}

		const _period = {
			id: period.id || generateId(calendarPeriods) - 1,
			name: period.name,
			color: period.color,
			calendarPeriodDays: period.days,
		};

		const periodIndex = _calendarPeriods.findIndex(cp => cp.id === period.id);

		// ADD PERIOD INTO CALENDAR
		calendarPeriodsOnChange({
			target: {
				value: update(
					_calendarPeriods,
					periodIndex === -1 ? { $push: [_period] } : { [periodIndex]: { $set: _period } }
				),
			},
		});
		setWillEditPeriodId(null);
	};

	const getCalendarData = () => {
		const _calendar = {
			weekend: 'Sat,Sun',
			bolder: 'Sat',
		};

		calendarPeriods.forEach(period => {
			const periodColorClass = `sdms-custom-colors--${period.color || 'primary'}`;
			period.calendarPeriodDays.forEach(day => {
				if (!_calendar[periodColorClass]) _calendar[periodColorClass] = [];

				_calendar[periodColorClass].push(
					moment(day.date)
						.utcOffset(0)
						.format('YYYY-MM-DD')
				);
			});
		});
		return _calendar;
	};

	const onDeletePeriod = id => {
		const periodIndex = calendarPeriods.findIndex(cp => cp.id === id);

		if (periodIndex === -1) {
			addErrorNotification("Period couldn't deleted");
		} else {
			calendarPeriods.splice(periodIndex, 1);
			calendarPeriodsOnChange({ target: { value: calendarPeriods } });
			addSuccessNotification('Period deleted');
		}
	};

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setYearShowVal();
		}
	}, [isSubmitted, setNameShowVal, setYearShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && yearValRes.isValid);
	}, [nameValRes.isValid, yearValRes.isValid, setIsValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.systemSettings.default.text,
				path: pages.systemSettings.dashboard.path,
			},
			{
				title: pages.systemSettings.calendars.text,
				path: pages.systemSettings.calendars.path,
			},
			{ title: name || `New ${pages.systemSettings.calendars.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.systemSettings.calendars.text}`);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<motion.div
			className='row h-100'
			initial='initial'
			animate='in'
			exit='out'
			variants={pageVariants}
			transition={pageTransition}>
			<div className='col-md-6 col-xl-4 sdms-portlet--fluid-container'>
				{willEditPeriodId !== null ? (
					<CalendarPeriodForm
						data={
							willEditPeriodId
								? { ...calendarPeriods.find(cp => cp.id === willEditPeriodId) }
								: { id: 0, color: 'random' }
						}
						onSave={onPeriodSave}
						onCancel={() => setWillEditPeriodId(null)}
					/>
				) : (
					<>
						<Portlet className='flex-grow-0' style={{ minHeight: 253.78 }}>
							<Portlet.Head>
								<Portlet.HeadLabelTitle
									portletIcon={pages.pos.settings.tableMaps.icon2}
									smallTitle={name || 'New Calendar'}>
									Settings
								</Portlet.HeadLabelTitle>
							</Portlet.Head>
							<Portlet.Body>
								<FormGroup isLast>
									<Loading isLoading={isLoading}>
										<FormField
											name='name'
											label='Name'
											id={data.id}
											valRes={nameValRes}
											showValidation={nameShowVal}
											col={12}>
											<Input
												type='text'
												placeholder='Name (Required)'
												value={name}
												onChange={nameOnChange}
												onBlur={setNameShowVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='year'
											label='Year'
											id={data.id}
											valRes={yearValRes}
											showValidation={yearShowVal}
											col={12}>
											<Input
												type='number'
												placeholder='Year (Required)'
												value={year}
												onChange={yearOnChange}
												onBlur={setYearShowVal}
												disabled={data.id !== 0}
											/>
										</FormField>
									</Loading>
								</FormGroup>
							</Portlet.Body>
						</Portlet>
						<CalendarPeriodList
							periods={calendarPeriods}
							onEdit={id => setWillEditPeriodId(id)}
							onDelete={id => onDeletePeriod(id)}
							addNewButton={
								<Button
									label='brand'
									text='New'
									icon='Plus'
									size='sm'
									onClick={() => setWillEditPeriodId(0)}
								/>
							}
						/>
					</>
				)}
			</div>
			<div className='col-md-6 col-xl-8'>
				<Portlet fluid='fluid' everyTimeFluid>
					<Portlet.Body>
						<div className='sdms-yearly-calendar sdms-yearly-calendar__body-container'>
							<div className='sdms-yearly-calendar__body'>
								<Calendar
									className='w-100'
									year={year}
									firstDayOfWeek={1}
									showWeekSeparators
									customClasses={getCalendarData()}
									onPickDate={() => {}}
								/>
							</div>
						</div>
					</Portlet.Body>
				</Portlet>
			</div>
		</motion.div>
	);
};
CalendarForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		year: PropTypes.number,
		calendarPeriods: PropTypes.arrayOf(PropTypes.object),
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	setTitle: PropTypes.func,
	onFormChange: PropTypes.func,
};
CalendarForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		year: new Date().getUTCFullYear(),
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	setTitle: () => {},
	onFormChange: () => {},
};

export default CalendarForm;
