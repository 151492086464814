import { useEffect, useRef, useState } from 'react';

export const displayCountDown = countDown =>
	`${Math.floor(countDown / 60)
		.toString()
		.padStart(2, '0')}:${parseInt((countDown % 60).toString(), 10)
		.toString()
		.padStart(2, '0')}`;

const useCountDown = (initialCountDown, onEnd, warningTime, onWarning) => {
	const [countDown, setCountDown] = useState(0);

	const timeoutId = useRef(0);

	const start = () => {
		setCountDown(initialCountDown - 1);
	};

	const stop = () => {
		clearTimeout(timeoutId.current);
		setCountDown(0);
	};

	const reset = () => {
		stop();
		start();
	};

	useEffect(() => {
		if (countDown) {
			if (countDown === 1) onEnd();
			else
				timeoutId.current = setTimeout(() => {
					setCountDown(countDown - 1);
				}, 1000);

			if (onWarning && warningTime && countDown === warningTime) onWarning();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countDown]);

	return [countDown, start, stop, reset];
};

export default useCountDown;
