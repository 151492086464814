import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Section = ({ children, className, last, title }) => (
	<div className={classNames('sdms-section', className, { 'sdms-section--last': last })}>
		{title && <h3 className='sdms-section__title'>{title}</h3>}
		{children}
	</div>
);
Section.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	className: PropTypes.string,
	last: PropTypes.bool,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
Section.defaultProps = {
	className: null,
	last: false,
	title: null,
};

const Body = ({ children, className }) => {
	return <div className={classNames('sdms-section__body', className)}>{children}</div>;
};
Body.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	className: PropTypes.string,
};
Body.defaultProps = {
	className: null,
};

const Sub = ({ border, children, className, fit, solid, type, xFit }) => {
	const sectionClass = classNames(
		'sdms-section',
		{ 'sdms-section__content--border': type === 'content' && border },
		{ 'sdms-section__content--fit': type === 'content' && fit },
		{ 'sdms-section__content--solid': type === 'content' && solid },
		{ 'sdms-section__content--x-fit': type === 'content' && xFit },
		`sdms-section__${type}`,
		className
	);

	return <div className={sectionClass}>{children}</div>;
};
Sub.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	className: PropTypes.string,
	type: PropTypes.oneOf(['content', 'heading', 'info', 'title']),
	border: PropTypes.bool,
	fit: PropTypes.bool,
	solid: PropTypes.bool,
	xFit: PropTypes.bool,
};
Sub.defaultProps = {
	border: false,
	className: null,
	fit: false,
	solid: false,
	type: 'content',
	xFit: false,
};

Section.Body = Body;
Section.Sub = Sub;

export default Section;
