import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import useModal from '../../../utils/hooks/useModal';
import usePrint from '../../../utils/hooks/usePrint';
import usePages from '../../../utils/hooks/usePages';
import Forms from '../../forms';
import apiCall, { modules } from '../../../utils/helpers/apiCall';
import { reservationStatuses } from '../../../utils/constants/constants';
import {
	addErrorNotification,
	addSuccessNotification,
	convertDateToUTC,
	dateFormatter,
} from '../../../utils/helpers/helper';
import {
	canDeleteReservationItem,
	getNextAndPrevReservationItemStatus,
	getReservationListCustomFilters,
	reservationItemStatusIcon,
	isCancellableStatus,
	isEarlyCheckout,
} from '../../../utils/helpers/reservationHelper';

import ListContainer from '../../reusables/template/ListContainer';
import List from '../../reusables/template/List';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import ReservationStatusCell, {
	RequestCell,
	reservationColor,
} from '../../reusables/element/ReservationStatusCell';
import DialogBox from '../../reusables/element/DialogBox';
import SVGIcon from '../../reusables/element/SVGIcon';
import NullBadge from '../../reusables/design/NullBadge';
import { hasEditLockedReservationPermission } from '../../../utils/helpers/permission';

const CustomerCell = ({ data, onClick }) => {
	return (
		<div onClick={onClick} role='presentation'>
			<span className='sdms-link sdms-link--dark'>
				{data.reservation.customer.displayName}
			</span>
		</div>
	);
};
CustomerCell.propTypes = {
	data: PropTypes.shape({
		reservation: PropTypes.shape({
			customer: PropTypes.shape({
				displayName: PropTypes.string,
			}),
		}),
	}),
	onClick: PropTypes.func,
};
CustomerCell.defaultProps = {
	data: {
		reservation: {
			customer: {
				displayName: '',
			},
		},
	},
	onClick: () => {},
};

const UnitCell = ({ data }) => {
	return data.unit?.name || <NullBadge />;
};

UnitCell.propTypes = {
	data: PropTypes.shape({
		unit: PropTypes.shape({
			name: PropTypes.string,
		}),
	}),
};
UnitCell.defaultProps = {
	data: {
		unit: {
			name: '',
		},
	},
};

const ArrivalCell = ({ data }) => {
	return <span>{dateFormatter(convertDateToUTC(new Date(data.fromDate)))}</span>;
};
ArrivalCell.propTypes = {
	data: PropTypes.shape({
		fromDate: PropTypes.string,
	}),
};
ArrivalCell.defaultProps = {
	data: {
		fromDate: '',
	},
};

const DepartureCell = ({ data }) => {
	return <span>{dateFormatter(convertDateToUTC(new Date(data.toDate)))}</span>;
};
DepartureCell.propTypes = {
	data: PropTypes.shape({
		toDate: PropTypes.string,
	}),
};
DepartureCell.defaultProps = {
	data: {
		toDate: '',
	},
};

const CustomStatusDropdownItems = ({ data, updateRef, onClick }) => {
	const [prevStatus, setPrevStatus] = useState(null);
	const [nextStatus, setNextStatus] = useState(null);

	const { status, customReservationStatus, product, reservation, id } = data;

	const onItemUpdateCustomStatus = statusId => {
		apiCall(
			'POST',
			'advancedReservationUpdateCustomStatus',
			res => {
				addSuccessNotification('Booking successfully updated');
				updateRef.current(res.reservationItems);
			},
			e => {
				addErrorNotification(e.toString());
			},
			'',
			{
				reservationItems: [id],
				reservation,
				statusId,
				sendStatusEmail: null,
			}
		);
	};

	useEffect(() => {
		const statuses = getNextAndPrevReservationItemStatus(
			status,
			customReservationStatus,
			product.productCustomReservationStatuses
		);

		setPrevStatus(statuses.prevStatus);
		setNextStatus(statuses.nextStatus);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<>
			{prevStatus && prevStatus.name && (
				<Dropdown.Item
					itemsColor={reservationColor(prevStatus.status.value)}
					icon={reservationItemStatusIcon(prevStatus.status.value)}
					onClick={() => {
						onClick();
						onItemUpdateCustomStatus(prevStatus.id);
					}}>
					{prevStatus.name}
				</Dropdown.Item>
			)}
			{nextStatus && nextStatus.name && (
				<Dropdown.Item
					itemsColor={reservationColor(nextStatus.status.value)}
					icon={reservationItemStatusIcon(nextStatus.status.value)}
					onClick={() => {
						onClick();
						onItemUpdateCustomStatus(nextStatus.id);
					}}>
					{nextStatus.name}
				</Dropdown.Item>
			)}
		</>
	);
};
CustomStatusDropdownItems.propTypes = {
	data: PropTypes.shape({
		status: PropTypes.shape({
			value: PropTypes.string,
		}),
		customReservationStatus: PropTypes.object,
		product: PropTypes.object,
		reservation: PropTypes.object,
		id: PropTypes.number,
	}),
	onClick: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	updateRef: PropTypes.object,
};
CustomStatusDropdownItems.defaultProps = {
	data: {
		status: {
			value: '',
		},
		customReservationStatus: {},
		product: {},
		reservation: {},
		id: -1,
	},
	onClick: () => {},
	updateRef: null,
};

const ContractStatusCell = ({ data }) => {
	if (data?.latestStatusContract?.esignCompleted)
		return (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<SVGIcon name='Verified' size={32} fill='#6a89ed' />
			</div>
		);
	return null;
};

ContractStatusCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

ContractStatusCell.defaultProps = {
	data: null,
};

const CheckInCheckOutButton = ({ data, onClick, isInDropDown }) => {
	const userContext = useContext(UserContext);

	return (
		(data.status.value === reservationStatuses.CHECKED_IN ||
			data.status.value === reservationStatuses.CONFIRMED) &&
		(isInDropDown ? (
			<Dropdown.Item
				key='checkInCheckOut'
				itemsColor={
					data.status.value === reservationStatuses.CHECKED_IN ? 'danger' : 'success'
				}
				icon={data.status.value === reservationStatuses.CHECKED_IN ? 'Sign-out' : 'Sign-in'}
				noPermission={
					!userContext.hasPermission(
						data.status.value === reservationStatuses.CHECKED_IN
							? 'bookings_check_out_reservations'
							: 'bookings_check_in_reservations'
					)
				}
				onClick={onClick}>
				{data.status.value === reservationStatuses.CHECKED_IN ? 'Check Out' : 'Check In'}
			</Dropdown.Item>
		) : (
			<Button
				key='checkInCheckOut'
				className='sdms-mr-15'
				label={data.status.value === reservationStatuses.CHECKED_IN ? 'danger' : 'success'}
				text={
					data.status.value === reservationStatuses.CHECKED_IN ? 'Check Out' : 'Check In'
				}
				icon={data.status.value === reservationStatuses.CHECKED_IN ? 'Sign-out' : 'Sign-in'}
				size='sm'
				elevate
				onClick={onClick}
				noPermission={
					!userContext.hasPermission(
						data.status.value === reservationStatuses.CHECKED_IN
							? 'bookings_check_out_reservations'
							: 'bookings_check_in_reservations'
					)
				}
			/>
		))
	);
};
CheckInCheckOutButton.propTypes = {
	data: PropTypes.shape({
		status: PropTypes.shape({
			value: PropTypes.string,
		}),
	}),
	onClick: PropTypes.func,
	isInDropDown: PropTypes.bool,
};
CheckInCheckOutButton.defaultProps = {
	data: {
		status: {
			value: '',
		},
	},
	onClick: () => {},
	isInDropDown: false,
};

const modals = {
	CANCEL: 'cancel',
	DELETE: 'delete',
};

const ReservationList = ({ history, type, bookingTypeId, reservationDate }) => {
	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const pages = usePages();

	const selectedReservations = useRef([]);

	const updateDataFunc = useRef();

	const error = useRef('');

	const [isLoading, setIsLoading] = useState(false);

	const [modal, openModal, closeModal] = useModal();

	const [onPrint, PRINT_COMPONENT] = usePrint();

	const staticFilters = useMemo(() => {
		const _staticFilters = {};

		if (bookingTypeId) _staticFilters['product.bookingType.id'] = bookingTypeId;

		if (type === 'checkIn') {
			_staticFilters['fromDate[after]'] = moment(reservationDate).format('YYYY-M-D');
			_staticFilters['fromDate[strictly_before]'] = moment(reservationDate)
				.add(1, 'days')
				.format('YYYY-M-D');
			_staticFilters['status.value[]'] = [
				reservationStatuses.RESERVED_ONLINE,
				reservationStatuses.RESERVED,
				reservationStatuses.CONFIRMED,
				reservationStatuses.CHECKED_IN,
				reservationStatuses.CHECKED_OUT,
			];
		}

		if (type === 'checkOut') {
			_staticFilters['toDate[after]'] = moment(reservationDate).format('YYYY-M-D');
			_staticFilters['toDate[strictly_before]'] = moment(reservationDate)
				.add(1, 'days')
				.format('YYYY-M-D');
			_staticFilters['status.value[]'] = [
				reservationStatuses.RESERVED_ONLINE,
				reservationStatuses.RESERVED,
				reservationStatuses.CONFIRMED,
				reservationStatuses.CHECKED_IN,
				reservationStatuses.CHECKED_OUT,
			];
		}

		return _staticFilters;
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [type, bookingTypeId, reservationDate]);

	const columns = useMemo(() => {
		if (type === 'all')
			return [
				<List.Col
					key={`reservation-${type}`}
					label='Reservation'
					cellData='reservationId'
					cellDataObject='reservation'
					isLinkColumn={userContext.hasPermission(
						'create_bookings_advanced_reservations'
					)}
					sortable='reservation.id'
				/>,
				<List.Col
					key={`booking-${type}`}
					label='Booking'
					cellData='reservationItemId'
					isLinkColumn={userContext.hasPermission(
						'create_bookings_advanced_reservations'
					)}
					sortable='id'
				/>,
				<List.Col
					key={`customer-${type}`}
					label='Customer'
					cellComponent={<CustomerCell />}
					isLinkColumn={userContext.hasPermission(
						'create_bookings_advanced_reservations'
					)}
					sortable='reservation.customer.displayName'
				/>,
				<List.Col
					key={`product-${type}`}
					label='Product'
					cellData='name'
					cellDataObject='product'
					sortable='product.name'
				/>,
				<List.Col
					key={`unit-${type}`}
					label='Unit'
					cellComponent={<UnitCell />}
					sortable='unit.name'
				/>,
				<List.Col
					key={`arrival-${type}`}
					label='Arrival'
					cellComponent={<ArrivalCell />}
					sortable='fromDate'
				/>,
				<List.Col
					key={`departure-${type}`}
					label='Departure'
					cellComponent={<DepartureCell />}
					sortable='toDate'
				/>,
				<List.Col
					key={`request-${type}`}
					label='Request'
					cellComponent={<RequestCell />}
					align='center'
				/>,
				<List.Col
					key={`status-${type}`}
					label='Status'
					cellComponent={<ReservationStatusCell />}
					sortable='status.value'
				/>,
				<List.Col key={`action-buttons-${type}`} width={190} align='right' onlyHover>
					<CheckInCheckOutButton key='checkInCheckOut' dataType='data' />
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<CustomStatusDropdownItems
							key='customStatus'
							dataType='data'
							updateRef={updateDataFunc}
						/>
						<Dropdown.Item
							key='edit'
							icon='Edit'
							text='Edit'
							noPermission={
								!userContext.hasPermission('create_bookings_advanced_reservations')
							}>
							Edit
						</Dropdown.Item>
						<Dropdown.Item key='printContract' icon='Printer' text='Print Contract'>
							Print Contract(s)
						</Dropdown.Item>
						<Dropdown.Item
							key='cancel'
							itemsColor='danger'
							icon='Error-circle'
							text='Cancel'
							noPermission={
								!userContext.hasPermission('bookings_check_out_reservations')
							}>
							Cancel
						</Dropdown.Item>
						<Dropdown.Item
							key='delete'
							itemsColor='danger'
							icon='Trash'
							text='Delete'
							noPermission={
								!userContext.hasPermission('delete_bookings_reservations')
							}>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>,
			];

		return [
			<List.Col
				key={`booking-${type}`}
				label='Booking'
				cellData='reservationItemId'
				isLinkColumn={userContext.hasPermission('create_bookings_advanced_reservations')}
				sortable='reservationItemId'
			/>,
			<List.Col
				key={`customer-${type}`}
				label='Customer'
				cellComponent={<CustomerCell />}
				isLinkColumn={userContext.hasPermission('create_bookings_advanced_reservations')}
				sortable='reservation.customer.displayName'
			/>,
			<List.Col
				key={`product-${type}`}
				label='Product'
				cellData='name'
				cellDataObject='product'
				sortable='product.name'
			/>,
			<List.Col
				key={`unit-${type}`}
				label='Unit'
				cellComponent={<UnitCell />}
				sortable='unit.name'
			/>,
			<List.Col
				key={`status-${type}`}
				label='Status'
				cellComponent={<ReservationStatusCell />}
				sortable='status.value'
			/>,
			<List.Col key={`request-cell-${type}`} width={50} cellComponent={<RequestCell />} />,
			<List.Col
				key={`contract-status-cell-${type}`}
				width={50}
				cellComponent={<ContractStatusCell />}
			/>,
			<List.Col key={`dropdown-${type}`} width={60} align='right' onlyHover>
				<Dropdown
					icon='Other#1'
					color='clean'
					inline
					aligned='right'
					circle
					outline={false}>
					<Dropdown.Header>Other Actions</Dropdown.Header>
					<CheckInCheckOutButton key='checkInCheckOut' dataType='data' isInDropDown />
					<CustomStatusDropdownItems
						key='customStatus'
						dataType='data'
						updateRef={updateDataFunc}
					/>
					<Dropdown.Item
						key='edit'
						icon='Edit'
						text='Edit'
						noPermission={
							!userContext.hasPermission('create_bookings_advanced_reservations')
						}>
						Edit
					</Dropdown.Item>
					<Dropdown.Item key='printContract' icon='Printer' text='Print Contract'>
						Print Contract(s)
					</Dropdown.Item>
				</Dropdown>
			</List.Col>,
		];
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [type]);

	const title = useMemo(() => {
		if (type === 'all') return pages.booking.reservations.text;

		if (type === 'checkIn')
			return reservationDate && !reservationDate.isSame(moment().startOf('day'))
				? `Arriving ${dateFormatter(reservationDate, false)}`
				: 'Arriving Today';

		return reservationDate && !reservationDate.isSame(moment().startOf('day'))
			? `Departing ${dateFormatter(reservationDate, false)}`
			: 'Departing Today';
	}, [type, reservationDate, pages]);

	const customFilters = useMemo(
		() =>
			getReservationListCustomFilters(
				type,
				userContext.data.selectedOutlet,
				modules.BOOKINGS
			),
		[type, userContext]
	);

	const onCheckIn = () => {
		if (isLoading || selectedReservations.current.length === 0) return;
		setIsLoading(true);

		apiCall(
			'POST',
			'reservationCheckIn',
			res => {
				setIsLoading(false);

				if (res.errors && res.errors.length) {
					error.current = res.errors.join('\n');
				} else {
					selectedReservations.current.forEach(r => {
						addSuccessNotification(` Reservation ${r.id} successfully checked in.`);
					});

					updateDataFunc.current(res.reservationItems);
				}
			},
			e => {
				addErrorNotification(
					e.toString().includes('override')
						? 'Some booking have been changed since you opened the list. Please reload page.'
						: e.toString()
				);
				setIsLoading(false);
			},
			'',
			{
				reservationItems: selectedReservations.current
					.filter(r => r.status.value === reservationStatuses.CONFIRMED)
					.map(r => r.id),
				outletId: userContext.data.selectedOutlet.id,
				reservations: selectedReservations.current.map(i => i.reservation),
			}
		);
	};

	const onCheckOut = () => {
		if (isLoading || selectedReservations.current.length === 0) return;

		addSuccessNotification('Checking Out...');

		apiCall(
			'POST',
			'reservationCheckOut',
			res => {
				setIsLoading(false);

				if (res.errors && res.errors.length) {
					error.current = res.errors.join('\n');
				} else {
					selectedReservations.current.forEach(r => {
						addSuccessNotification(` Reservation ${r.id} successfully checked out.`);
					});

					updateDataFunc.current(res.reservationItems);
				}
			},
			e => {
				addErrorNotification(
					e.toString().includes('override')
						? 'Some booking have been changed since you opened the list. Please reload page.'
						: e.toString()
				);
				setIsLoading(false);
			},
			'',
			{
				reservationItems: selectedReservations.current
					.filter(r => r.status.value === reservationStatuses.CHECKED_IN)
					.map(r => r.id),
				outletId: userContext.data.selectedOutlet.id,
				reservations: selectedReservations.current.map(i => i.reservation),
			}
		);
	};

	const onCancel = () => {
		if (isLoading || selectedReservations.current.length === 0) return;
		addSuccessNotification('Cancelling...');

		apiCall(
			'POST',
			'advancedReservationCancelItem',
			res => {
				setIsLoading(false);

				if (res.errors && res.errors.length) {
					error.current = res.errors.join('\n');
				} else {
					selectedReservations.current.forEach(r => {
						addSuccessNotification(` Reservation ${r.id} successfully cancelled.`);
					});
					updateDataFunc.current(res.reservationItems, res.deletedReservationItems);
				}
			},
			e => {
				addErrorNotification(
					e.toString().includes('override')
						? 'Some booking have been changed since you opened the list. Please reload page.'
						: e.toString()
				);
				setIsLoading(false);
			},
			'',
			{
				reservationItems: selectedReservations.current
					.filter(r => isCancellableStatus(r.status.value))
					.map(r => r.id),
				outletId: userContext.data.selectedOutlet.id,
				reservations: selectedReservations.current.map(i => i.reservation),
			}
		);
	};

	const onDelete = () => {
		if (isLoading || selectedReservations.current.length === 0) return;

		addSuccessNotification('Deleting...');

		apiCall(
			'POST',
			'advancedReservationDeleteItem',
			res => {
				setIsLoading(false);

				res.errors.forEach(e => addErrorNotification(e));

				res.deletedReservationItemIds.forEach(id =>
					addSuccessNotification(` Reservation ${id} successfully deleted.`)
				);

				updateDataFunc.current([], res.deletedReservationItemIds);
			},
			e => {
				addErrorNotification(
					e.toString().includes('override')
						? 'Some booking have been changed since you opened the list. Please reload page.'
						: e.toString()
				);
				setIsLoading(false);
			},
			'',
			{
				reservationItemIds: selectedReservations.current
					.filter(r => canDeleteReservationItem(r))
					.map(r => r.id),
				reservations: selectedReservations.current.map(i => i.reservation),
			}
		);
	};

	const onPrintContract = reservationItems => {
		apiCall(
			'POST',
			'getPrintContract',
			res => {
				onPrint(res.contracts.join('<p style="page-break-before: always">'));
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ reservationItemIds: reservationItems.map(i => i.id) }
		);
	};

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.booking.default.text, path: pages.booking.dashboard.path },
			{ title: pages.booking.reservations.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.booking.reservations.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ListContainer
				route='reservationItemBookings'
				title={title}
				fluid='fluid'
				history={history}
				forms={type === 'all' ? Forms.booking.reservations : []}
				staticFilters={staticFilters}
				customMultiActions={{
					checkIn: {
						func: (reservationItems, updateData) => {
							selectedReservations.current = reservationItems.filter(
								i => i.status.value === reservationStatuses.CONFIRMED
							);
							updateDataFunc.current = updateData;
							onCheckIn();
						},
						component: (
							<Button
								label='success'
								text='Check In'
								icon='Unlock'
								key='checkIn'
								noPermission={
									!userContext.hasPermission('bookings_check_in_reservations')
								}
							/>
						),
					},
					checkOut: {
						func: (reservationItems, updateData) => {
							updateDataFunc.current = updateData;
							selectedReservations.current = reservationItems.filter(
								i => i.status.value === reservationStatuses.CHECKED_IN
							);
							onCheckOut();
						},
						component: (
							<Button
								label='danger'
								text='Check Out'
								icon='Lock'
								key='checkOut'
								noPermission={
									!userContext.hasPermission('bookings_check_out_reservations')
								}
							/>
						),
					},
					cancel: {
						func: (reservationItems, updateData) => {
							updateDataFunc.current = updateData;
							selectedReservations.current = reservationItems.filter(i =>
								isCancellableStatus(i.status.value)
							);
							openModal({ open: modals.CANCEL });
						},
						component: (
							<Button
								label='danger'
								text='Cancel'
								key='cancel'
								noPermission={
									!userContext.hasPermission('bookings_check_out_reservations')
								}
							/>
						),
					},
					delete: {
						func: (reservationItems, updateData) => {
							updateDataFunc.current = updateData;
							selectedReservations.current = reservationItems.filter(r =>
								canDeleteReservationItem(r)
							);
							openModal({ open: modals.DELETE });
						},
						component: (
							<Button
								label='danger'
								text='Delete'
								key='delete'
								noPermission={
									!userContext.hasPermission('delete_bookings_reservations')
								}
							/>
						),
					},
					printContract: {
						func: onPrintContract,
						component: (
							<Button label='primary' text='Print Contract(s)' key='printContract' />
						),
					},
				}}
				checkEditable={reservationItems => {
					const actions = [];

					reservationItems.forEach(reservationItem => {
						if (reservationItem.status.value === reservationStatuses.CONFIRMED) {
							if (!actions.includes('checkIn')) actions.push('checkIn');
						} else if (
							reservationItem.status.value === reservationStatuses.CHECKED_IN &&
							!isEarlyCheckout(reservationItem)
						) {
							if (!actions.includes('checkOut')) actions.push('checkOut');
						}
						if (
							isCancellableStatus(reservationItem.status.value) &&
							!actions.includes('cancel')
						) {
							actions.push('cancel');
						}

						if (
							reservationItem.productContracts.length &&
							!actions.includes('printContract')
						)
							actions.push('printContract');

						if (
							canDeleteReservationItem(reservationItem) &&
							!actions.includes('delete')
						)
							actions.push('delete');
					});

					return actions;
				}}
				customActions={{
					advance: () => {
						history.push(
							`${pages.booking.reservations.advanced.path}/0${
								type === 'all' ? '' : '?from=dashboard'
							}`
						);
					},
					checkInCheckOut: (reservationItem, updateData) => {
						updateDataFunc.current = updateData;

						selectedReservations.current = [reservationItem];
						if (reservationItem.status.value === reservationStatuses.CONFIRMED)
							onCheckIn();
						else if (
							reservationItem.status.value === reservationStatuses.CHECKED_IN &&
							!isEarlyCheckout(reservationItem)
						)
							onCheckOut();
					},
					cancel: (reservationItem, updateData) => {
						updateDataFunc.current = updateData;
						selectedReservations.current = [reservationItem];
						openModal({ open: modals.CANCEL });
					},
					edit: (reservationItem, label) => {
						history.push({
							pathname: `${pages.booking.reservations.advanced.path}/${reservationItem.reservation.id}`,
							state:
								reservationItem.reservation.itemCount === 1 || label === 'Booking'
									? {
											itemId: reservationItem.id,
											from: type === 'all' ? '' : 'dashboard',
									  }
									: null,
						});
					},
					printContract: reservationItem => {
						onPrintContract([reservationItem]);
					},
					delete: (reservationItem, updateData) => {
						updateDataFunc.current = updateData;
						selectedReservations.current = [reservationItem];
						openModal({ open: modals.DELETE });
					},
					customStatus: (_, updateData) => {
						updateDataFunc.current = updateData;
					},
				}}
				isTabList={type !== 'all'}
				customFilters={customFilters}
				customFiltersInQuickPanel={false}>
				<List
					withCheckBox
					withOutPortlet={type === 'checkOut' || type === 'checkIn'}
					fluid='fluid'
					listName={`${type}-reservationItemBookings`}
					checkEditable={reservationItem => {
						const actions = ['edit'];

						if (
							reservationItem.reservation.isLocked &&
							!hasEditLockedReservationPermission(userContext)
						) {
							if (reservationItem.productContracts.length)
								actions.push('printContract');

							return actions;
						}

						if (
							reservationItem.status.value !== reservationStatuses.CANCELLED &&
							!(
								reservationItem.status.value === reservationStatuses.CHECKED_IN &&
								isEarlyCheckout(reservationItem)
							)
						) {
							actions.push('checkInCheckOut');
							actions.push('customStatus');
						}

						if (isCancellableStatus(reservationItem.status.value))
							actions.push('cancel');

						if (reservationItem.productContracts.length) actions.push('printContract');

						if (canDeleteReservationItem(reservationItem)) actions.push('delete');

						return actions;
					}}>
					{columns}
				</List>
			</ListContainer>
			{PRINT_COMPONENT}
			<DialogBox
				open={modal.open === modals.CANCEL}
				title=''
				content='Booking(s) will be cancelled. Are you sure?'
				type='question'>
				<Button
					className='sdms-font-transform-c'
					text='Yes, Cancel'
					label='danger'
					icon='Trash'
					onClick={() => {
						onCancel();
						closeModal();
					}}
				/>
				<Button
					className='sdms-font-transform-c'
					text='No'
					design='clean'
					icon='Edit'
					onClick={closeModal}
				/>
			</DialogBox>
			<DialogBox
				open={modal.open === modals.DELETE}
				title=''
				content='Booking will be deleted. Are you sure?'
				type='question'>
				<Button
					className='sdms-font-transform-c'
					text='Yes, Delete'
					label='danger'
					icon='Trash'
					onClick={() => {
						onDelete();
						closeModal();
					}}
				/>
				<Button
					className='sdms-font-transform-c'
					text='No'
					design='clean'
					icon='Edit'
					onClick={closeModal}
				/>
			</DialogBox>
		</>
	);
};
ReservationList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	type: PropTypes.oneOf(['all', 'checkIn', 'checkOut']),
	// eslint-disable-next-line react/forbid-prop-types
	reservationDate: PropTypes.object,
	bookingTypeId: PropTypes.number,
};
ReservationList.defaultProps = {
	type: 'all',
	reservationDate: null,
	bookingTypeId: 0,
};

export default ReservationList;
