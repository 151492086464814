import React from 'react';
import PropTypes from 'prop-types';

import Portlet from '../../../layout/Portlet';
import Checkbox from '../../../field/Checkbox';

const TermsAndConditions = ({ isOnline, outlet, termsAndConditions, setTermsAndConditions }) => {
	return (
		<>
			<Portlet isElevate={false} className='sdms-bg-transparent sdms-portlet--fit' isLast>
				<Portlet.Head>
					<Portlet.HeadLabelTitle portletIcon='File-done'>
						Terms & Conditions
					</Portlet.HeadLabelTitle>
				</Portlet.Head>
			</Portlet>
			<Portlet hasFrame>
				<Portlet.Body>
					<div
						style={{
							maxHeight: isOnline ? 200 : 180,
							overflow: 'scroll',
						}}
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{
							__html: outlet.settings.termsAndConditions,
						}}
					/>
				</Portlet.Body>
				<Portlet.Foot type='form' subClassName='sdms-pull-right' tall='sm'>
					<Checkbox
						onChange={() => setTermsAndConditions(!termsAndConditions)}
						value={termsAndConditions}
						content='I accept the Terms & Conditions'
						bold
						single={false}
					/>
				</Portlet.Foot>
			</Portlet>
		</>
	);
};

TermsAndConditions.propTypes = {
	isOnline: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	termsAndConditions: PropTypes.bool.isRequired,
	setTermsAndConditions: PropTypes.func.isRequired,
};

export default TermsAndConditions;
