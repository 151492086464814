import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useField from '../../../utils/hooks/useField';
import usePages from '../../../utils/hooks/usePages';
import { required } from '../../../utils/helpers/validation';
import { metaTypes } from '../../../utils/constants/constants';

import Button from './Button';
import { ListBody, ListTable } from '../template/List';
import FormField from '../template/FormField';
import AsyncSelect from '../field/AsyncSelect';
import Input from '../field/Input';
import Portlet from '../layout/Portlet';
import Toggle from '../field/Toggle';

const MetaItem = ({ data, disabled, onDelete, onChange, isSubmitted }) => {
	const [meta, metaOnChange, metaValRes, metaShowVal, setMetaShowVal] = useField(
		data,
		'meta',
		() => {},
		[required]
	);

	const [value, valueOnChange, valueValRes, valueShowVal, setValueShowVal] = useField(
		data,
		'value',
		() => {},
		[required]
	);

	const _ValueElement = useMemo(() => {
		if (!meta)
			return <Input type='text' placeholder='Value' value='' onChange={() => {}} disabled />;

		if (meta.type.value === metaTypes.STRING)
			return (
				<Input
					type='text'
					placeholder='Value'
					value={value}
					onChange={valueOnChange}
					onBlur={setValueShowVal}
					disabled={disabled}
				/>
			);

		if (meta.type.value === metaTypes.INTEGER)
			return (
				<Input
					type='number'
					placeholder='Value'
					value={value}
					onChange={valueOnChange}
					onBlur={setValueShowVal}
					disabled={disabled}
					pattern={process.env.REACT_APP_INTEGER_PATTERN}
				/>
			);

		if (meta.type.value === metaTypes.FLOAT)
			return (
				<Input
					type='text'
					placeholder='Value'
					value={value}
					onChange={valueOnChange}
					onBlur={setValueShowVal}
					disabled={disabled}
					pattern={process.env.REACT_APP_PRICE_PATTERN}
				/>
			);

		return (
			<Toggle
				onChange={e => valueOnChange({ target: { value: e.target.value ? '1' : '0' } })}
				value={value === '1'}
			/>
		);
	}, [disabled, meta, setValueShowVal, value, valueOnChange]);

	useEffect(() => {
		onChange({ id: data.id, value, meta, isValid: metaValRes.isValid && valueValRes.isValid });
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [value, meta, metaValRes.isValid, valueValRes.isValid]);

	useEffect(() => {
		if (isSubmitted) {
			setMetaShowVal();
			setValueShowVal();
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isSubmitted]);

	return (
		<tr className={classNames({ disabled })}>
			<td>
				<FormField
					name='meta'
					id={data.id}
					valRes={metaValRes}
					showValidation={metaShowVal}
					inFormDesign={false}
					isLast>
					<AsyncSelect
						options={data.meta ? [data.meta] : []}
						placeholder='Search and select meta from list'
						value={meta}
						onChange={metaOnChange}
						onBlur={setMetaShowVal}
						route='metas'
						disabled={disabled}
					/>
				</FormField>
			</td>
			<td>
				<FormField
					name='value'
					id={data.id}
					inFormDesign={false}
					isLast
					valRes={valueValRes}
					showValidation={valueShowVal}>
					{_ValueElement}
				</FormField>
			</td>
			<td>
				<Button
					btnIcon
					label='danger'
					icon='Trash'
					size='sm'
					elevate
					key='delete'
					onClick={onDelete}
					disabled={disabled}
				/>
			</td>
		</tr>
	);
};

MetaItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	onDelete: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	isSubmitted: PropTypes.bool.isRequired,
};

const MetaList = ({
	productMetas,
	onAdd,
	onChange,
	onDelete,
	disabled,
	isSubmitted,
	displayTitle,
}) => {
	const pages = usePages();

	return (
		<div className='col-12'>
			<Portlet className='sdms-list-layout sdms-portlet--section' border>
				<Portlet.Head wrapMaxSize='lg'>
					<Portlet.HeadLabel portletIcon={pages.systemSettings.metas.icon}>
						{displayTitle && (
							<h3 className='sdms-portlet__head-title'>
								{pages.systemSettings.metas.text}
							</h3>
						)}
					</Portlet.HeadLabel>
					<Portlet.HeadToolbar>
						<Portlet.HeadActions>
							<Button
								label='brand'
								text='Add New'
								icon='Plus'
								onClick={() => onAdd()}
							/>
						</Portlet.HeadActions>
					</Portlet.HeadToolbar>
				</Portlet.Head>
				<Portlet.Body>
					<ListBody
						className='table--everytime--scroll sdms-portlet__body--fit'
						responsive='scroll'>
						<ListTable>
							<colgroup>
								<col />
								<col />
								<col />
							</colgroup>
							<thead>
								<tr>
									<th className='sdms-bg-transparent'>Meta</th>
									<th className='sdms-bg-transparent'>Value</th>
									<th className='sdms-bg-transparent'>Actions</th>
								</tr>
							</thead>
							<tbody>
								{productMetas.map(pm => (
									<MetaItem
										key={pm.id}
										data={pm}
										onChange={onChange}
										onDelete={() => onDelete(pm.id)}
										disabled={disabled}
										isSubmitted={isSubmitted}
									/>
								))}
							</tbody>
						</ListTable>
					</ListBody>
				</Portlet.Body>
			</Portlet>
		</div>
	);
};

MetaList.propTypes = {
	productMetas: PropTypes.arrayOf(PropTypes.object),
	onAdd: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	isSubmitted: PropTypes.bool,
	displayTitle: PropTypes.bool,
};
MetaList.defaultProps = {
	productMetas: [],
	disabled: false,
	isSubmitted: false,
	displayTitle: false,
};

export default MetaList;
