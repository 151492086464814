import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Textarea from '../../../field/Textarea';
import Button from '../../../element/Button';
import DialogBox from '../../../element/DialogBox';

const Cancellation = ({ open, isSubmitting, onCancel, onClose }) => {
	const [cancellationReason, setCancellationReason] = useState('');

	useEffect(() => {
		if (!open) setCancellationReason('');
	}, [open]);

	return (
		<DialogBox
			open={open}
			title=''
			content='Are you sure about cancelling?'
			type='question'
			onClose={() => {}}>
			<Textarea
				className='mb-3'
				placeholder='Cancellation Reason (Optional)'
				value={cancellationReason}
				onChange={e => setCancellationReason(e.target.value)}
				textRow={2}
				disabled={isSubmitting}
			/>
			<Button
				className='sdms-font-transform-c'
				text='No'
				label='danger'
				icon='Angle-left-circle'
				onClick={onClose}
				disabled={isSubmitting}
			/>
			<Button
				className='sdms-font-transform-c'
				text={isSubmitting ? 'Cancelling' : 'Yes, Cancel'}
				design='clean'
				icon='Edit'
				onClick={() => onCancel(cancellationReason)}
				disabled={isSubmitting}
				isSubmitting={isSubmitting}
			/>
		</DialogBox>
	);
};

Cancellation.propTypes = {
	open: PropTypes.bool,
	isSubmitting: PropTypes.bool,
	onCancel: PropTypes.func,
	onClose: PropTypes.func,
};

Cancellation.defaultProps = {
	open: false,
	isSubmitting: false,
	onCancel: () => {},
	onClose: () => {},
};

export default Cancellation;
