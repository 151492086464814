import React, { useContext, useEffect, useState, useRef } from 'react';
import { Utils as QbUtils } from 'react-awesome-query-builder';
import PropTypes from 'prop-types';
import moment from 'moment';

import UserContext from '../../../app/contexts/UserContext';
import useField from '../../../utils/hooks/useField';
import {
	addErrorNotification,
	addSuccessNotification,
	convertDateToUTC,
	priceFormatter,
	getQueryBuildConfig,
	getReportQueryFields,
	reportCheckChildren,
} from '../../../utils/helpers/helper';
import apiCall, { filters, outletRelatedEndpoints } from '../../../utils/helpers/apiCall';

import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import FormField from '../../reusables/template/FormField';
import DatePicker from '../../reusables/field/DatePicker';
import FormGroup from '../../reusables/layout/FormGroup';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import Toggle from '../../reusables/field/Toggle';
import Radio from '../../reusables/field/Radio';
import Selects from '../../reusables/field/Selects';

const EmailDocuments = ({ onCancel, afterSubmit }) => {
	const userContext = useContext(UserContext);

	const [isLoading, setIsLoading] = useState(true);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [date, dateOnChange] = useField(
		{},
		'date',
		() => {},
		[],
		moment()
			.utc(true)
			.hours(23)
			.minutes(59)
			.seconds(59)
			.milliseconds(999)
	);

	const [emailInvoices, emailInvoicesOnChange] = useField(
		{},
		'emailInvoices',
		() => {},
		[],
		false
	);

	const [resendSentInvoices, resendSentInvoicesOnChange] = useField(
		{},
		'resendSentInvoices',
		() => {},
		[],
		false
	);

	const [whichDate, whichDateOnChange] = useField({}, 'whichDate', () => {}, [], 'dueDate');

	const [invoices, invoicesOnChange] = useField({}, 'invoices', () => {}, [], []);

	const [selectedBookingTypes, selectedBookingTypesOnChange] = useField(
		{},
		'selectedBookingTypes',
		() => {},
		[],
		[]
	);

	const [emailStatements, emailStatementsOnChange] = useField(
		{},
		'emailStatements',
		() => {},
		[],
		false
	);

	const [customers, customersOnChange] = useField({}, 'customers', () => {}, [], []);

	const [report, reportOnChange] = useField({}, 'report', () => {}, [], null);

	const lists = ['bookingTypes', 'reports'];

	const [listState, setListState] = useState({
		bookingTypes: [],
		reports: [],
	});

	const _isMounted = useRef(false);

	const onSubmit = () => {
		if (!emailInvoices && !emailStatements) {
			addErrorNotification('Please select invoice or statement');
			return;
		}

		let query = '';

		if (report) {
			const config = { ...getQueryBuildConfig(), fields: getReportQueryFields(report.type) };

			const tree = QbUtils.checkTree(
				QbUtils.loadTree(reportCheckChildren(JSON.parse(report.queryJSON))),
				config
			);

			query = QbUtils.sqlFormat(tree, config);
		}

		setIsSubmitting(true);

		apiCall(
			'POST',
			'emailDocuments',
			res => {
				setIsSubmitting(false);

				res.messages.forEach(message => {
					if (message.success) addSuccessNotification(message.message);
					else addErrorNotification(message.message);
				});
				if (!res.messages.some(m => !m.success)) afterSubmit(true);
			},
			e => {
				setIsSubmitting(false);
				addErrorNotification(e.toString());
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				date: date.toISOString(),
				customers: customers.map(c => c.id),
				invoices: invoices.map(i => i.id),
				bookingTypes: selectedBookingTypes.map(bt => bt.id),
				emailInvoices,
				emailStatements,
				resendSentInvoices,
				whichDate,
				reportId: report?.id || null,
				query,
			}
		);
	};

	useEffect(() => {
		_isMounted.current = true;

		return () => {
			_isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		lists.forEach(name => {
			const _filters = {
				pagination: false,
			};

			// if list is related outlet add filter.
			if (outletRelatedEndpoints.includes(name))
				_filters['outlet.id'] = userContext.data.selectedOutlet.id;

			if ((((filters[name] || {}).groups || {}).list || {}).read)
				_filters['groups[]'] = filters[name].groups.list.read;

			apiCall(
				'GET',
				name,
				fetchedData => {
					if (!_isMounted.current) return;
					setListState(oldListState => ({
						...oldListState,
						[name]: fetchedData,
					}));
				},
				err => {
					addErrorNotification(err.toString());
				},
				'',
				null,
				_filters
			);
		});
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [userContext.data.selectedOutlet.id]);

	useEffect(() => {
		setIsLoading(lists.filter(l => !listState[l]).length > 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listState]);

	return (
		<Portlet
			fluid='fluid'
			className='sdms-bg-transparent sdms-portlet--unelevate sdms-portlet--portletception sdms-mb-0'>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Send'>Email Documents</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<FormGroup isLast>
					<FormField name='customers' label='Customers' id='customers' colLg={12}>
						<AsyncSelect
							options={[]}
							placeholder='Search and select customer'
							value={customers}
							onChange={customersOnChange}
							route='customers'
							field='displayName'
							displayKey='displayName'
							onBlur={() => {}}
							isMulti
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
					<FormField name='report' label='Customer List Report' colLg={12}>
						<Selects
							options={listState.reports.filter(
								t => t.type.value === 'crm_customers'
							)}
							placeholder='Customer List Report'
							value={report}
							onChange={reportOnChange}
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
				</FormGroup>
				<FormGroup isLast>
					<FormField
						name='emailStatements'
						label='Email Statements'
						id='emailStatements'
						colLg={6}>
						<Toggle
							spaceLess
							value={emailStatements}
							onChange={emailStatementsOnChange}
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
				</FormGroup>
				<FormGroup isLast>
					<FormField
						name='emailInvoices'
						label='Email Invoices'
						id='emailInvoices'
						colLg={6}>
						<Toggle
							spaceLess
							value={emailInvoices}
							onChange={emailInvoicesOnChange}
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
					<FormField
						name='resendSentInvoices'
						label='Resend Sent Invoices'
						id='resendSentInvoices'
						colLg={6}>
						<Toggle
							spaceLess
							value={resendSentInvoices}
							onChange={resendSentInvoicesOnChange}
							disabled={isSubmitting || isLoading}
						/>
					</FormField>
					{emailInvoices && (
						<>
							<FormField name='invoices' label='Invoices' id='invoices' colLg={12}>
								<AsyncSelect
									options={[]}
									placeholder='Search and select invoice'
									value={invoices}
									onChange={invoicesOnChange}
									route='invoices'
									displayKey='invoiceId'
									isMulti
									renderOption={option =>
										`${option.invoiceId} - ${
											option.customer.displayName
										} - ${priceFormatter(option.total)}`
									}
									disabled={isSubmitting || isLoading}
								/>
							</FormField>
							<FormField
								name='selectedBookingTypes'
								label='Booking Type'
								id='selectedBookingType'
								colLg={12}>
								<Selects
									options={listState.bookingTypes}
									placeholder='Select a Booking Type'
									value={selectedBookingTypes}
									onChange={selectedBookingTypesOnChange}
									displayKey='name'
									multiple
									disabled={isSubmitting || isLoading}
								/>
							</FormField>
							<FormField
								name='whichDate'
								label='Date Selection'
								id='whichDate'
								colMd={12}>
								<Radio.Container isInline>
									<Radio
										checked={whichDate === 'dueDate'}
										id='whichDate'
										name='whichDate'
										content='Due Date'
										className='sdms-radio--primary'
										onChange={() =>
											whichDateOnChange({
												target: { value: 'dueDate' },
											})
										}
										disabled={isSubmitting || isLoading}
									/>
									<Radio
										checked={whichDate === 'invoiceDate'}
										id='invoiceDate'
										name='invoiceDate'
										content='Invoice Date'
										className='sdms-radio--primary'
										onChange={() =>
											whichDateOnChange({
												target: { value: 'invoiceDate' },
											})
										}
										disabled={isSubmitting || isLoading}
									/>
								</Radio.Container>
							</FormField>
							<FormField name='date' label='Email Through' id='date' colMd={12}>
								<DatePicker
									id='date'
									type='calendar'
									value={convertDateToUTC(date.toDate())}
									onChange={e =>
										dateOnChange({
											target: {
												value: moment(e.target.value)
													.utc(true)
													.endOf('day'),
											},
										})
									}
									disableClear
									disabled={isSubmitting || isLoading}
								/>
							</FormField>
						</>
					)}
				</FormGroup>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						icon='Error-circle'
						size='sm'
						elevate
						text='Cancel'
						onClick={onCancel}
						disabled={isSubmitting}
					/>
				</div>
				<div className='col-auto'>
					<Button
						className='sdms-mw-100'
						label='info'
						icon='Send'
						text={isSubmitting ? 'Sending' : 'Send'}
						size='sm'
						elevate
						onClick={onSubmit}
						disabled={isSubmitting || isLoading}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};

EmailDocuments.propTypes = {
	onCancel: PropTypes.func,
	afterSubmit: PropTypes.func,
};

EmailDocuments.defaultProps = {
	onCancel: () => {},
	afterSubmit: () => {},
};

export default EmailDocuments;
