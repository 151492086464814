import { enumItemTypes, invoiceStatuses } from '../constants/constants';
import { addFloats, numberFormat, subFloats } from './helper';

export const getInvoiceAmountPaid = (invoice, settlement) => {
	if (!invoice.customerSettlements) return invoice.amountPaid;

	const amountPaid = settlement ? settlement.amount : 0;

	return invoice.customerSettlements.reduce(
		(partialSum, cs) =>
			addFloats(partialSum, typeof cs === 'object' && cs.amount ? cs.amount : 0),
		amountPaid
	);
};

const getPreviousItems = (item, invoiceItems) => {
	const sortedArray = sortInvoiceItems(invoiceItems, 'desc');

	return sortedArray.filter(
		i => i.sortOrder < item.sortOrder || (i.sortOrder === item.sortOrder && i.id < item.id)
	);
};

export const getAppliedItem = (item, invoiceItems) => {
	let appliedItem = null;

	let hasSalesTax = false;

	let taxableAmount = 0;

	const sortedItems = [...invoiceItems].sort((a, b) => {
		return b.sortOrder - a.sortOrder || b.id - a.id;
	});

	sortedItems.every(ii => {
		if (appliedItem) return false;

		if (
			(ii.sortOrder < item.sortOrder ||
				(ii.sortOrder === item.sortOrder && ii.id < item.id)) &&
			ii.product
		) {
			taxableAmount += ii.taxableAmount;

			if (
				ii.product.itemType.value !== enumItemTypes.SALES_TAX &&
				(!ii.discountType || ii.discountType.value === 'fixed')
			)
				appliedItem = ii;

			if (ii.product && ii.product.itemType.value === enumItemTypes.SALES_TAX)
				hasSalesTax = true;
		}

		return true;
	});

	return [appliedItem, hasSalesTax, taxableAmount];
};

export const getPreviousItem = (item, invoiceItems, isSubtotal = false) => {
	let _items = [...invoiceItems].sort((a, b) => {
		return b.sortOrder - a.sortOrder || b.id - a.id;
	});

	if (isSubtotal)
		_items = _items.filter(
			ii => ii.product && ii.product.itemType.value === enumItemTypes.SUBTOTAL
		);

	const itemIndex = _items.findIndex(ii => ii.id === item.id);

	return _items[itemIndex + 1] || null;
};

const sortInvoiceItems = (invoiceItems, type = 'asc') => {
	const sortedArray = [...invoiceItems];

	sortedArray.sort((a, b) => {
		if (type === 'asc') return a.sortOrder - b.sortOrder;

		return b.sortOrder - a.sortOrder;
	});

	return sortedArray;
};

export const getSubtotalTaxableAmount = (item, items) => {
	const itemsLevels = {};

	const sortedItems = sortInvoiceItems(items).filter(ii => ii.product);

	sortedItems.forEach((ii, index) => {
		if (ii.product.itemType.value === enumItemTypes.SUBTOTAL) {
			if (
				sortedItems[index - 1] &&
				sortedItems[index - 1].product &&
				sortedItems[index - 1].product.itemType.value === enumItemTypes.SUBTOTAL &&
				itemsLevels[sortedItems[index - 1].id]
			) {
				itemsLevels[ii.id] = {
					level: itemsLevels[sortedItems[index - 1].id].level + 1,
					sumOfSubtotals: true,
				};
			} else {
				const previousSubtotalItem = getPreviousItem(ii, items, true);

				let withoutRegularItem = false;

				let taxableAmount = 0;

				if (previousSubtotalItem) {
					withoutRegularItem = true;
					const previousItems = getPreviousItems(ii, items);

					previousItems.every(i => {
						taxableAmount += i.taxableAmount;

						if (previousSubtotalItem.id === i.id) return false;

						if (
							i.product.itemType.value !== enumItemTypes.DISCOUNT &&
							i.product.itemType.value !== enumItemTypes.SALES_TAX
						)
							withoutRegularItem = false;

						return true;
					});

					if (itemsLevels[previousSubtotalItem.id] && withoutRegularItem)
						itemsLevels[previousSubtotalItem.id].duplicateTax = true;
				}

				itemsLevels[ii.id] = {
					level:
						previousSubtotalItem && itemsLevels[previousSubtotalItem.id]
							? itemsLevels[previousSubtotalItem.id].level
							: 1,
					sumOfSubtotals: false,
					taxableAmount: withoutRegularItem && previousSubtotalItem ? taxableAmount : 0,
					withoutRegularItem,
				};
			}
		}
	});

	if (itemsLevels[item.id] && itemsLevels[item.id].taxableAmount)
		return itemsLevels[item.id].taxableAmount;

	const previousItems = getPreviousItems(item, items);

	if (itemsLevels[item.id] && itemsLevels[item.id].sumOfSubtotals) {
		return previousItems
			.filter(
				i =>
					(i.sortOrder < item.sortOrder ||
						(i.sortOrder === item.sortOrder && i.id < item.id)) &&
					i.product &&
					i.product.itemType.value === enumItemTypes.SUBTOTAL &&
					itemsLevels[i.id] &&
					itemsLevels[i.id].level === itemsLevels[item.id].level - 1 &&
					!itemsLevels[i.id].duplicateTax
			)
			.reduce((a, b) => {
				return a + b.taxableAmount;
			}, 0);
	}

	let taxableAmount = 0;

	previousItems.every(ii => {
		if (ii.product.itemType.value === enumItemTypes.SUBTOTAL) return false;

		taxableAmount += ii.taxableAmount;

		return true;
	});

	return numberFormat(taxableAmount);
};

const calculateItemTaxTotal = (item, taxRate, subtotal) => {
	const { invoiceItemTaxRates } = item;

	if (!taxRate || !item.taxCode || !item.taxCode.taxable) return [0, 0];

	let taxTotal = 0;

	let additionalTaxTotal = 0;

	const taxRates = [...(taxRate.taxRates.length === 0 ? [taxRate] : taxRate.taxRates)];

	if (invoiceItemTaxRates) {
		taxRates.push(...invoiceItemTaxRates);

		invoiceItemTaxRates.forEach(invoiceItemTaxRate => {
			additionalTaxTotal = addFloats(
				additionalTaxTotal,
				(subtotal * invoiceItemTaxRate.taxRate.amount) / 100
			);
		});
	}

	taxRates.forEach(_taxRate => {
		taxTotal = addFloats(taxTotal, numberFormat((subtotal * _taxRate.amount) / 100));
	});

	return [taxTotal, additionalTaxTotal];
};

export const calculateInvoiceItemTotals = (item, invoiceItems, taxRate) => {
	const itemTotals = {
		price: 0,
		quantity: 0,
		subtotal: 0,
		discountAmount: 0,
		taxableAmount: 0,
		additionalTax: 0,
		taxRate: 0,
		taxTotal: 0,
		total: 0,
	};

	if (!item.taxCode || !item.product) return itemTotals;

	if (item.product.itemType.value === enumItemTypes.SUBTOTAL) {
		let totalOrder = false;
		let runningTotal = 0;
		let foundProduct = false;

		if (invoiceItems && invoiceItems.length)
			getPreviousItems(item, invoiceItems).some((ii, index) => {
				totalOrder =
					totalOrder ||
					(index === 0 && ii.product.itemType.value === enumItemTypes.SUBTOTAL);

				if (
					!totalOrder &&
					ii.product.itemType.value === enumItemTypes.SUBTOTAL &&
					foundProduct
				)
					return true;

				runningTotal += ii.subtotal;

				if (ii.product && !ii.product.itemType.isSystem) {
					itemTotals.price += runningTotal;
					foundProduct = true;
					runningTotal = 0;

					itemTotals.taxableAmount = getSubtotalTaxableAmount(item, invoiceItems);
				}

				return false;
			});

		itemTotals.price = numberFormat(itemTotals.price, 2);

		return itemTotals;
	}

	if (item.product.itemType.value === enumItemTypes.SALES_TAX) {
		const [appliedItem, , taxableAmount] = getAppliedItem(item, invoiceItems);

		if (!appliedItem) return itemTotals;

		const previousItem = getPreviousItem(item, invoiceItems);

		if (
			appliedItem.product.itemType.value !== enumItemTypes.SUBTOTAL &&
			previousItem &&
			previousItem.product.itemType.value === enumItemTypes.DISCOUNT
		)
			return itemTotals;

		const invoiceItemTax = item.invoiceItemTaxRates[0];

		itemTotals.price = invoiceItemTax ? numberFormat(invoiceItemTax.amount / 100, 5) : 0;

		let _taxableAmount = 0;

		if (appliedItem.product.itemType.value === enumItemTypes.SUBTOTAL)
			_taxableAmount = taxableAmount;
		else if (appliedItem.product.itemType.value === enumItemTypes.DISCOUNT)
			_taxableAmount = appliedItem.subtotal;
		else _taxableAmount = appliedItem.taxableAmount;

		itemTotals.quantity = numberFormat(_taxableAmount, 5);

		itemTotals.subtotal = numberFormat(itemTotals.price * itemTotals.quantity);

		itemTotals.taxableAmount = 0;

		itemTotals.total = itemTotals.subtotal;

		return itemTotals;
	}

	if (
		item.product.itemType.value === enumItemTypes.DISCOUNT &&
		item.discountType &&
		item.discountType.value === 'percent'
	) {
		const [appliedItem, hasSalesTax] = getAppliedItem(
			item,
			invoiceItems,
			enumItemTypes.DISCOUNT
		);

		if (!appliedItem) return itemTotals;

		const subtotal =
			appliedItem.product.itemType.value === enumItemTypes.SUBTOTAL
				? appliedItem.price
				: appliedItem.subtotal;

		itemTotals.price = numberFormat(item.discountAmount / 100, 5) * -1;

		itemTotals.subtotal = numberFormat(itemTotals.price * subtotal);

		if (
			appliedItem.product &&
			appliedItem.product.itemType.value === enumItemTypes.SUBTOTAL &&
			hasSalesTax
		)
			itemTotals.taxableAmount = 0;
		else if (
			appliedItem.product &&
			appliedItem.product.itemType.value === enumItemTypes.DISCOUNT
		)
			itemTotals.taxableAmount = itemTotals.subtotal;
		else
			itemTotals.taxableAmount = numberFormat(
				itemTotals.price * (item.taxCode.taxable ? appliedItem.taxableAmount : 0)
			);

		itemTotals.total = itemTotals.subtotal;

		return itemTotals;
	}

	itemTotals.price = item.price;

	itemTotals.quantity = item.quantity;

	itemTotals.subtotal = numberFormat(item.price * item.quantity);

	itemTotals.taxableAmount = item.taxCode.taxable ? itemTotals.subtotal : 0;

	itemTotals.taxRate = item.taxCode.taxable && taxRate ? taxRate.amount : 0;

	if (item.product.itemType && !item.product.itemType.isSystem) {
		if (item.discount && item.discountType) {
			itemTotals.discountAmount =
				item.discountType.value === 'percent'
					? numberFormat((itemTotals.subtotal * item.discountAmount) / 100)
					: item.discountAmount;

			if (itemTotals.discountAmount > itemTotals.subtotal)
				itemTotals.discountAmount = itemTotals.subtotal;

			if (item.discount.taxCode.taxable && itemTotals.taxableAmount > 0)
				itemTotals.taxableAmount -= itemTotals.discountAmount;
		}

		item.invoiceItemTaxRates.forEach(_taxRate => {
			itemTotals.taxRate += _taxRate.amount;
		});
	}

	const [taxTotal, additionalTax] = calculateItemTaxTotal(
		item,
		taxRate,
		itemTotals.taxableAmount
	);

	itemTotals.additionalTax = additionalTax;

	itemTotals.taxTotal = taxTotal;

	itemTotals.total = itemTotals.subtotal - itemTotals.discountAmount + itemTotals.taxTotal;

	return itemTotals;
};

export const calculateInvoiceTotals = (invoice, enumInvoiceStatuses) => {
	const _totals = {
		taxableAmount: 0,
		additionalTaxes: 0,
		subtotal: 0,
		taxTotal: 0,
		discountTotal: 0,
		total: 0,
		discountableSubtotal: 0,
		status: invoice.status,
	};

	let additionalTaxesDiscountAmount = 0;

	if (invoice.invoiceItems) {
		invoice.invoiceItems.forEach(i => {
			const itemTotals = calculateInvoiceItemTotals(i, invoice.invoiceItems, invoice.taxRate);

			if (i.product && i.product.itemType.isSystem) {
				i.quantity = itemTotals.quantity;

				i.taxableAmount = itemTotals.taxableAmount;

				i.price = itemTotals.price;
			}

			const itemSubtotalWitDiscount = subFloats(
				itemTotals.subtotal,
				itemTotals.discountAmount
			);

			_totals.subtotal = addFloats(_totals.subtotal, itemSubtotalWitDiscount);

			if (!i.isTipItem && !i.isFeeItem)
				_totals.discountableSubtotal = addFloats(
					_totals.discountableSubtotal,
					itemSubtotalWitDiscount
				);

			_totals.additionalTaxes += itemTotals.additionalTax;

			if (i.product && i.product.itemType.value !== enumItemTypes.SUBTOTAL) {
				_totals.taxableAmount = addFloats(_totals.taxableAmount, itemTotals.taxableAmount);
			}
		});
	}

	if (invoice.discount && _totals.discountableSubtotal) {
		if (
			invoice.discountType.value === 'fixed' &&
			invoice.discountAmount > _totals.discountableSubtotal
		)
			invoice.discountAmount = _totals.discountableSubtotal;

		const discountInPercent =
			invoice.discountType.value === 'percent'
				? invoice.discountAmount
				: (invoice.discountAmount / _totals.discountableSubtotal) * 100;

		_totals.discountTotal = numberFormat(
			(_totals.discountableSubtotal * discountInPercent) / 100
		);

		if (invoice.discount.taxCode.taxable) {
			_totals.taxableAmount -= numberFormat(
				(_totals.taxableAmount * discountInPercent) / 100
			);
		}

		if (invoice.discountType.value === 'percent') {
			additionalTaxesDiscountAmount = numberFormat(
				(_totals.additionalTaxes * discountInPercent) / 100
			);

			_totals.additionalTaxes -= additionalTaxesDiscountAmount;
		}
	}

	(invoice.taxRate.taxRates.length === 0 ? [invoice.taxRate] : invoice.taxRate.taxRates).forEach(
		_taxRate => {
			_totals.taxTotal = addFloats(
				_totals.taxTotal,
				numberFormat((_totals.taxableAmount * _taxRate.amount) / 100)
			);
		}
	);

	_totals.subtotal = numberFormat(_totals.subtotal || 0);

	_totals.taxTotal = numberFormat(addFloats(_totals.taxTotal, _totals.additionalTaxes));

	_totals.discountTotal = numberFormat(_totals.discountTotal + additionalTaxesDiscountAmount);

	_totals.total = numberFormat(
		_totals.subtotal + _totals.taxTotal - _totals.discountTotal + additionalTaxesDiscountAmount
	);

	if (
		enumInvoiceStatuses.length &&
		(!invoice.status || invoice.status.value !== invoiceStatuses.VOIDED)
	)
		_totals.status = enumInvoiceStatuses.find(
			eis =>
				eis.value ===
				(_totals.total > invoice.amountPaid ? invoiceStatuses.OPEN : invoiceStatuses.PAID)
		);

	return _totals;
};

export const fixInvoiceItems = invoice =>
	Array.isArray(invoice.invoiceItems)
		? invoice.invoiceItems
		: Object.values(invoice.invoiceItems);
