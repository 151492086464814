import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import Portlet from '../layout/Portlet';
import SVGIcon from './SVGIcon';
import Portal from '../layout/Portal';

const DialogBox = ({ children, open, title, content, type, onClose, maxWidth }) => {
	let _iconName = [];

	switch (type) {
		case 'success':
			_iconName = 'Done-circle';
			break;
		case 'error':
			_iconName = 'Error-circle';
			break;
		case 'warning':
			_iconName = 'Warning-2';
			break;
		case 'question':
			_iconName = 'Question-circle';
			break;
		default:
			// Info
			_iconName = 'Info-circle';
	}

	return (
		<Portal>
			<Popup
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: maxWidth ? `${maxWidth}px` : '480px',
				}}
				open={open}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}>
				<Portlet>
					<Portlet.Body>
						<div className='row'>
							<div className='col-12 d-flex justify-content-center sdms-mb-10'>
								<SVGIcon name={_iconName} size={96} />
							</div>
							<div className='col-12 d-flex justify-content-center'>
								<h1>{title}</h1>
							</div>
							<div className='col-12 d-flex justify-content-center'>
								<span>{content}</span>
							</div>
						</div>
					</Portlet.Body>
					<Portlet.Foot subClassName='justify-content-around'>{children}</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};

DialogBox.propTypes = {
	title: PropTypes.node,
	content: PropTypes.node,
	open: PropTypes.bool.isRequired,
	type: PropTypes.oneOf(['success', 'error', 'warning', 'info', 'question']),
	children: PropTypes.node.isRequired,
	onClose: PropTypes.func,
	maxWidth: PropTypes.number,
};

DialogBox.defaultProps = {
	title: 'Title',
	content: 'Content',
	type: 'info',
	onClose: () => {},
	maxWidth: 0,
};

export default DialogBox;
