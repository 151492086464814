import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../../app/contexts/UserContext';
import useModal from '../../../../utils/hooks/useModal';
import {
	addErrorNotification,
	addSuccessNotification,
	canVoidInvoice,
	numberFormat,
} from '../../../../utils/helpers/helper';
import {
	invoiceStatuses,
	nonTransientAdvancedFormSteps,
	paymentTypes,
	serviceFeeModules,
	invoiceListModals as modals,
} from '../../../../utils/constants/constants';
import { nonTransientAdvancedFormPages } from '../../../pages';

import List from '../../template/List';
import Dropdown from '../../element/Dropdown';
import Button from '../../element/Button';
import ListContainer from '../../template/ListContainer';
import DialogBox from '../../element/DialogBox';
import apiCall from '../../../../utils/helpers/apiCall';
import PayModal from '../../modals/PayModal';
import {
	InvoiceBalanceCell,
	InvoiceDateCell,
	InvoiceDueDateCell,
	InvoiceIdLinkCell,
	InvoicePaidCell,
	InvoiceStatusCell,
	InvoiceTotalCell,
	InvoiceTypeCell,
} from '../../element/InvoiceListCells';
import InvoiceDeferredSchedule from '../../modals/InvoiceDeferredSchedule';
import InvoiceEdit from '../../../modules/crm/InvoiceEdit';

const InvoiceList = ({
	filters,
	presetData,
	onChange,
	disabled,
	onPaymentPrint,
	onPrint,
	onSend,
	headerActions,
	openInCrm,
	isTransient,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [modal, openModal, closeModal] = useModal();
	const userContext = useContext(UserContext);

	const getPaymentTypes = () => {
		const _paymentTypes = [];

		if (userContext.hasPermission('cash_payments'))
			_paymentTypes.push({ value: paymentTypes.CASH });

		_paymentTypes.push(...[{ value: paymentTypes.CHECK }, { value: paymentTypes.CREDIT_CARD }]);

		return _paymentTypes;
	};

	const onVoid = () => {
		setIsLoading(true);
		apiCall(
			'POST',
			'invoiceVoid',
			res => {
				addSuccessNotification('Invoice successfully voided.');

				if (modal.updateData) modal.updateData(res);

				closeModal();
				setIsLoading(false);
				onChange();
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ invoiceId: modal.invoice.id }
		);
	};

	const onPay = payment => {
		setIsLoading(true);
		apiCall(
			'POST',
			'invoicePay',
			res => {
				if (modal.updateData) modal.updateData([res.invoice]);
				closeModal();
				onChange();
				setIsLoading(false);
				onPaymentPrint(res.payment);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{
				invoice: {
					...modal.invoice,
					customer: {
						id: presetData.customer.id,
					},
					outlet: {
						id: userContext.data.selectedOutlet.id,
					},
				},
				payment,
			}
		);
	};

	return (
		<>
			<ListContainer
				route='reservationInvoices'
				title={nonTransientAdvancedFormSteps.INVOICES}
				icon={
					nonTransientAdvancedFormPages.find(
						s => s.title === nonTransientAdvancedFormSteps.INVOICES
					).icon
				}
				staticFilters={filters}
				customActions={{
					pay: (invoice, updateData) => {
						openModal({
							open: modals.PAY,
							invoice,
							amount: numberFormat(invoice.total - invoice.amountPaid),
							updateData,
						});
					},
					void: (invoice, updateData) =>
						openModal({ open: modals.VOID_DIALOG, invoice, updateData }),
					print: invoice => onPrint(invoice.id),
					send: invoice => onSend(invoice.id),
					deferredSchedule: invoice =>
						openModal({ open: modals.DEFERRED_SCHEDULE, invoiceId: invoice.id }),
				}}
				isTabList
				largeQuickPanel
				forms={
					disabled || isTransient
						? []
						: [
								{
									name: 'add',
									component: InvoiceEdit,
									isQuickPanel: true,
									button: (
										<Button
											className='sdms-ml-10'
											icon='Plus'
											design='brand'
											text='Add New Invoice'
											size='sm'
										/>
									),
								},
						  ]
				}
				presetData={presetData}
				onChange={onChange}
				fluid='fluid'
				hasSearch={false}
				headerActions={headerActions}>
				<List
					fluid='fluid'
					withCheckBox={false}
					checkEditable={invoice => {
						const actions = disabled || isTransient ? ['print'] : ['edit', 'print'];

						if (invoice.status.value === invoiceStatuses.OPEN)
							actions.push('pay', 'send');

						if (canVoidInvoice(invoice, userContext)) actions.push('void');

						if (
							invoice.invoiceItems.filter(
								i => i.product && i.product.enableDeferredIncome
							).length > 0 ||
							(invoice.deferredIncomes && invoice.deferredIncomes.length > 0)
						)
							actions.push('deferredSchedule');

						return actions;
					}}
					withOutPortlet>
					<List.Col
						label='Invoice'
						cellData='invoiceId'
						cellComponent={openInCrm ? <InvoiceIdLinkCell /> : null}
						isLinkColumn
					/>
					<List.Col label='Type' cellComponent={<InvoiceTypeCell />} />
					<List.Col label='Date' cellComponent={<InvoiceDateCell />} />
					<List.Col label='Due Date' cellComponent={<InvoiceDueDateCell />} />
					<List.Col label='Total' cellComponent={<InvoiceTotalCell />} />
					<List.Col label='Paid' cellComponent={<InvoicePaidCell />} />
					<List.Col label='Balance' cellComponent={<InvoiceBalanceCell />} />
					<List.Col label='Status' cellComponent={<InvoiceStatusCell />} />
					<List.Col align='right' onlyHover width={150}>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item icon='Edit' text='Edit' key='edit'>
								Edit
							</Dropdown.Item>
							<Dropdown.Item icon='Edit' text='Pay' key='pay'>
								Pay
							</Dropdown.Item>
							<Dropdown.Item icon='Printer' text='Print Invoice' key='print'>
								Print Invoice
							</Dropdown.Item>
							<Dropdown.Item icon='Mail-box' text='Email Invoice' key='send'>
								Email Invoice
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='danger'
								icon='Error-circle'
								text='Void'
								key='void'>
								Void
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			<DialogBox
				open={modal.open === modals.VOID_DIALOG}
				title='Void'
				content='Are you sure you want to void this invoice?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't Void!`}
					onClick={closeModal}
					disabled={isLoading}
				/>
				<Button
					className='sdms-font-transform-c'
					label='danger'
					icon='Trash'
					text='Yes, Void!'
					onClick={onVoid}
					disabled={isLoading}
				/>
			</DialogBox>
			<PayModal
				isOpen={modal.open === modals.PAY}
				onClose={closeModal}
				paymentTypes={getPaymentTypes()}
				amount={modal.amount || 0}
				customer={presetData.customer}
				onPay={paymentData => {
					onPay(paymentData);
				}}
				isPaying={isLoading}
				canSavePaymentMethod={userContext.hasPermission('save_customer_payment_methods')}
				canEnterCreditCard={userContext.hasPermission('key_in_credit_cards')}
				max={modal.amount || 0}
				paymentGateway={userContext.data.selectedOutlet.settings.paymentGateway}
				serviceFeeModule={serviceFeeModules.SLIPS}
				outlet={userContext.data.selectedOutlet}
			/>
			{modal.open === modals.DEFERRED_SCHEDULE && (
				<InvoiceDeferredSchedule onCancel={closeModal} invoiceId={modal.invoiceId} />
			)}
		</>
	);
};

InvoiceList.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	filters: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	presetData: PropTypes.object,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	onPaymentPrint: PropTypes.func,
	onPrint: PropTypes.func,
	onSend: PropTypes.func,
	headerActions: PropTypes.arrayOf(PropTypes.object),
	openInCrm: PropTypes.bool,
	isTransient: PropTypes.bool,
};

InvoiceList.defaultProps = {
	filters: null,
	presetData: null,
	onChange: () => {},
	disabled: false,
	onPaymentPrint: () => {},
	onPrint: () => {},
	onSend: () => {},
	headerActions: [],
	openInCrm: false,
	isTransient: false,
};

export default InvoiceList;
