import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { EditorState, Modifier } from 'draft-js';
import classNames from 'classnames';
import ReactToPrint from 'react-to-print';

import { required } from '../../../utils/helpers/validation';
import {
	emailTemplateTypes,
	templateTypes as _templateTypes,
} from '../../../utils/constants/constants';
import pages from '../../pages';

import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import Button from '../../reusables/element/Button';
import useField from '../../../utils/hooks/useField';
import Loading from '../../reusables/template/Loading';
import HeaderContext from '../../../app/contexts/HeaderContext';
import Wysiwyg from '../../reusables/field/Wysiwyg';

const CustomOption = ({ editorState, onChange, value }) => {
	return (
		<Button
			className='sdms-toolbar-btn'
			label='brand'
			onClick={() => {
				const contentState = Modifier.replaceText(
					editorState.getCurrentContent(),
					editorState.getSelection(),
					`{{${value}}}`,
					editorState.getCurrentInlineStyle()
				);
				onChange(EditorState.push(editorState, contentState, 'insert-characters'));
			}}
			text={value}
		/>
	);
};
CustomOption.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	editorState: PropTypes.object,
	onChange: PropTypes.func,
	value: PropTypes.string.isRequired,
};
CustomOption.defaultProps = {
	editorState: {},
	onChange: () => {},
};

const TemplateForm = ({
	data,
	setIsValid,
	submit,
	isSubmitted,
	templateTypes,
	templates,
	isLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
	emailAddresses,
}) => {
	const headerContext = useContext(HeaderContext);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [subject, subjectOnChange] = useField(data, 'subject', onFormChange);

	const [fromEmail, fromEmailOnChange] = useField(data, 'fromEmail', onFormChange, [], null);

	const [text, textOnChange, textValRes, textShowVal, setTextShowVal] = useField(
		data,
		'text',
		onFormChange,
		[required]
	);

	const [type, typeOnChange, typeValRes, typeShowVal, setTypeShowVal] = useField(
		data,
		'type',
		onFormChange,
		[required],
		null
	);

	const [itemTemplate, itemTemplateOnChange] = useField(
		data,
		'itemTemplate',
		onFormChange,
		[],
		null
	);

	const [bccEmails, bccEmailsOnChange] = useField(data, 'bccEmails', onFormChange, [], null);

	const previewDiv = useRef();

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setTextShowVal();
			setTypeShowVal();
		}
	}, [isSubmitted, setNameShowVal, setTextShowVal, setTypeShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && textValRes.isValid && typeValRes.isValid);
	}, [setIsValid, nameValRes.isValid, textValRes.isValid, typeValRes.isValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.systemSettings.default.text,
				path: pages.systemSettings.dashboard.path,
			},
			{
				title: pages.systemSettings.templates.text,
				path: pages.systemSettings.templates.path,
			},
			{ title: name || `New ${pages.systemSettings.templates.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.systemSettings.templates.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form>
					<Section title='General'>
						<Section.Body>
							<FormGroup isLast>
								<Loading isLoading={isLoading}>
									<FormField
										name='name'
										label='Name'
										id={data.id}
										valRes={nameValRes}
										showValidation={nameShowVal}
										colMd={6}
										colLg={4}>
										<Input
											type='text'
											placeholder='Name (Required)'
											value={name}
											onChange={nameOnChange}
											onBlur={setNameShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='type'
										label='Type'
										id={data.id}
										valRes={typeValRes}
										showValidation={typeShowVal}
										colMd={6}
										colLg={4}>
										<Selects
											options={templateTypes}
											placeholder='Type (Required)'
											value={type}
											displayKey='value'
											onChange={typeOnChange}
											onBlur={setTypeShowVal}
										/>
									</FormField>
								</Loading>
								{type &&
									(type.value === _templateTypes.RESERVATION_CONFIRMATION ||
										type.value === _templateTypes.INVOICE ||
										type.value === _templateTypes.STATEMENT ||
										type.value === _templateTypes.STATEMENT_TRANSACTION_BLOCK ||
										type.value === _templateTypes.STATUS_UPDATE_EMAIL ||
										type.value === _templateTypes.TRANSIENT_FOLIO) && (
										<Loading isLoading={isLoading}>
											<FormField
												name='itemTemplate'
												label='Detail Template'
												description='Template to replace detail tag'
												id={data.id}
												colMd={6}
												colLg={4}>
												<Selects
													options={templates.filter(
														t =>
															(type.value ===
																_templateTypes.RESERVATION_CONFIRMATION &&
																t.type.value ===
																	'Reservation Item Block') ||
															(type.value ===
																_templateTypes.INVOICE &&
																t.type.value ===
																	'Invoice Item Block') ||
															(type.value ===
																_templateTypes.STATEMENT &&
																t.type.value ===
																	'Statement Transaction Block') ||
															(type.value ===
																_templateTypes.STATEMENT_TRANSACTION_BLOCK &&
																t.type.value ===
																	'Statement Transaction Detail Block') ||
															(type.value ===
																_templateTypes.STATUS_UPDATE_EMAIL &&
																t.type.value ===
																	'Status Update Email Item Block') ||
															(type.value ===
																_templateTypes.TRANSIENT_FOLIO &&
																t.type.value ===
																	_templateTypes.TRANSIENT_FOLIO_ITEM_BLOCK)
													)}
													placeholder='Detail Templates'
													value={itemTemplate}
													onChange={itemTemplateOnChange}
												/>
											</FormField>
										</Loading>
									)}
							</FormGroup>
							<FormGroup>
								{type && emailTemplateTypes.includes(type.value) && (
									<>
										<Loading isLoading={isLoading}>
											<FormField
												name='subject'
												label='Email Subject'
												id={data.id}
												colMd={6}
												colLg={4}>
												<Input
													type='text'
													placeholder='Email Subject'
													value={subject}
													onChange={subjectOnChange}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='fromEmail'
												label='Email From'
												id={data.id}
												colMd={6}
												colLg={4}>
												<Selects
													options={emailAddresses}
													placeholder='Select an item'
													value={fromEmail}
													onChange={fromEmailOnChange}
													displayKey='displayName'
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='bccEmails'
												label='Email BCC'
												id={data.id}
												colMd={6}
												colLg={4}>
												<Input
													type='text'
													placeholder='Email BCC'
													value={bccEmails}
													onChange={bccEmailsOnChange}
												/>
											</FormField>
										</Loading>
									</>
								)}
								<Loading isLoading={isLoading}>
									<FormField
										name='text'
										label='Content'
										id={data.id}
										col={12}
										showValidation={textShowVal}
										valRes={textValRes}>
										<Wysiwyg
											value={text}
											onChange={textOnChange}
											onBlur={setTextShowVal}
											isProduct={false}
											customOptions={
												type && type.variables
													? type.variables.sort((a, b) =>
															a.value.localeCompare(b.value)
													  )
													: []
											}
										/>
									</FormField>
								</Loading>
								<div style={{ display: 'none' }}>
									<div
										ref={previewDiv}
										dangerouslySetInnerHTML={{ __html: text }}
									/>
								</div>
								<div className='col-12 text-right'>
									<ReactToPrint
										trigger={() => (
											<Button
												className='sdms-mw-100'
												label='brand'
												text='Print Preview'
												size='sm'
												icon='Printer'
											/>
										)}
										content={() => previewDiv.current}
										pageStyle='@page { size: auto; } @media print { body { -webkit-print-color-adjust: exact; } }'
									/>
								</div>
							</FormGroup>
						</Section.Body>
					</Section>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
TemplateForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		subject: PropTypes.string,
		fromEmail: PropTypes.string,
		text: PropTypes.string,
		type: PropTypes.object,
		itemTemplate: PropTypes.object,
	}),
	templates: PropTypes.arrayOf(PropTypes.object),
	templateTypes: PropTypes.arrayOf(PropTypes.object),
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	emailAddresses: PropTypes.arrayOf(PropTypes.object),
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
};
TemplateForm.defaultProps = {
	data: {
		id: 0,
		name: '',
	},
	templates: [],
	templateTypes: [],
	setIsValid: () => {},
	submit: () => {},
	setTitle: () => {},
	isSubmitted: false,
	isLoading: false,
	emailAddresses: [],
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
};

export default TemplateForm;
