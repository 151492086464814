import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import ReactTooltip from 'react-tooltip';

import {
	paymentTypes as _paymentTypes,
	serviceFeeModules,
} from '../../../utils/constants/constants';
import {
	addErrorNotification,
	isBeforeAccountingClosedPeriod,
	dateFormatter,
	numberFormat,
	priceFormatter,
	addCustomNotification,
} from '../../../utils/helpers/helper';
import usePrint from '../../../utils/hooks/usePrint';
import useModal from '../../../utils/hooks/useModal';
import UserContext from '../../../app/contexts/UserContext';
import apiCall from '../../../utils/helpers/apiCall';
import useOutlet from '../../../utils/hooks/useOutlet';

import Portlet from '../../reusables/layout/Portlet';
import ContentInner from '../../reusables/template/ContentInner';
import Container from '../../reusables/layout/Container';
import Button from '../../reusables/element/Button';
import CustomerModal from '../../reusables/modals/CustomerModal';
import Input from '../../reusables/field/Input';
import InvoiceModal from '../../reusables/modals/InvoiceModal';
import Toggle from '../../reusables/field/Toggle';
import Radio from '../../reusables/field/Radio';
import PayModal from '../../reusables/modals/PayModal';
import Separator from '../../reusables/layout/Separator';
import { PayButton } from '../../reusables/element/Order';
import FormField from '../../reusables/template/FormField';
import Loading from '../../reusables/template/Loading';
import List from '../../reusables/template/List';
import DialogBox from '../../reusables/element/DialogBox';
import StatementChargeModal from '../../reusables/modals/StatementChargeModal';
import Portal from '../../reusables/layout/Portal';
import { InvoiceTypeCell } from '../../reusables/element/InvoiceListCells';

const IdCell = ({ data, onClick }) => {
	return (
		<span
			role='presentation'
			className='sdms-link sdms-link--dark'
			onClick={() => onClick(data)}>
			{data.invoiceId || data.statementChargeId}
		</span>
	);
};
IdCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	onClick: PropTypes.func.isRequired,
};
IdCell.defaultProps = {
	data: null,
};

const DateCell = ({ data }) => {
	if (data.statementChargeDate) return dateFormatter(data.statementChargeDate);

	return dateFormatter(data.invoiceDate || data.timeCreated);
};

const DueDateCell = ({ data }) => {
	return dateFormatter(data.dueDate, false);
};

const OriginalAmountCell = ({ data }) => {
	return priceFormatter(data.amount || data.total);
};

const PaidCell = ({ data }) => {
	return priceFormatter(data.amountPaid);
};

const AmountDueCell = ({ data }) => {
	return priceFormatter((data.amount || data.total) - data.amountPaid);
};

const AppliedCell = ({ data, updateAmount }) => {
	return (
		<Input
			className='ml-auto mr-auto sdms-w-100'
			onChange={e => updateAmount(data, e.target.value)}
			type='text'
			placeholder='Applied'
			value={data.appliedAmount.toString()}
			pattern={process.env.REACT_APP_PRICE_PATTERN}
		/>
	);
};
AppliedCell.propTypes = {
	data: PropTypes.shape({
		appliedAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}),
	updateAmount: PropTypes.func,
};
AppliedCell.defaultProps = {
	data: { appliedAmount: '' },
	updateAmount: null,
};

const BalanceCell = ({ data }) => {
	return priceFormatter((data.amount || data.total) - data.amountPaid - data.appliedAmount);
};

const modals = {
	CUSTOMER: 'customer',
	PAY: 'pay',
	RELOAD: 'reload',
	INVOICE: 'invoice',
	STATEMENT_CHARGE: 'statement_charge',
};

const PayBill = ({
	userHasPermission,
	paymentTypes,
	register,
	onClose,
	title,
	onChange,
	webPrint,
	customerFor,
}) => {
	const userContext = useContext(UserContext);

	const [, , displayOutlet] = useOutlet();

	const [modal, openModal, closeModal] = useModal({ open: customerFor ? '' : modals.CUSTOMER });

	const [isLoading, setIsLoading] = useState(false);

	const [customer, setCustomer] = useState(customerFor);

	const [remittances, setRemittances] = useState([]);

	const [transactions, setTransactions] = useState([]);

	const [useCredit, setUseCredit] = useState(false);

	const [selectedCredit, setSelectedCredit] = useState(null);

	const appliedAmount = useRef('');

	const [onPrint, PRINT_COMPONENT] = usePrint();

	const getCreditsTotal = () => {
		if (remittances.length === 0) return 0;

		let credits = 0;
		remittances.forEach(r => {
			credits += r.unAppliedAmount;
		});

		return credits;
	};

	const applyAmountToTransactions = (amount, updateAppliedAmount = false) => {
		transactions.forEach(t => {
			if (amount > 0) {
				const balanceDue = (t.amount || t.total) - t.amountPaid;
				t.appliedAmount = numberFormat(Math.min(balanceDue, amount));
				amount = numberFormat(amount - t.appliedAmount);
			} else {
				t.appliedAmount = '';
			}
		});

		if (updateAppliedAmount) appliedAmount.current = getAppliedAmount(transactions);

		setTransactions([...transactions]);
	};

	const getAppliedAmount = _transactions => {
		let _appliedAmount = 0;

		_transactions.forEach(i => {
			_appliedAmount += i.appliedAmount === '' ? 0 : parseFloat(i.appliedAmount);
		});

		return _appliedAmount > 0 ? _appliedAmount.toString() : '';
	};

	const getAmountDue = () => {
		let amountDue = 0;

		transactions.forEach(t => {
			amountDue += (t.amount || t.total) - t.amountPaid;
		});

		return amountDue;
	};

	const onReset = (_transactions, _remittances) => {
		setRemittances(
			_remittances
				.map(r => {
					r.unAppliedAmount = r.amount;

					r.customerSettlements.forEach(cs => {
						r.unAppliedAmount -= cs.amount;
					});

					r.unAppliedAmount = numberFormat(r.unAppliedAmount);

					return r;
				})
				.filter(r => r.unAppliedAmount > 0)
		);

		setTransactions(
			_transactions.map(i => {
				if (i.statementChargeId) i.type = 'Statement Charge';

				i.appliedAmount = '';
				return i;
			})
		);
		appliedAmount.current = '';
		setSelectedCredit(null);

		if (modal.open !== modals.CUSTOMER) closeModal();

		if (_remittances.length === 0 || _transactions.length === 0) setUseCredit(false);
	};

	const onPay = paymentData => {
		if (selectedCredit && selectedCredit.amount < appliedAmount.current) {
			addErrorNotification('You cannot pay more than amount of credit');
			return;
		}

		onChange(false);
		setIsLoading(true);

		apiCall(
			'POST',
			'payBill',
			res => {
				onReset([...res.invoices, ...res.statementCharges], res.remittances);
				setIsLoading(false);
				
				if (paymentData.printReceipt) {
					webPrint.payBillReceipt(
						{
							...res.payment,
							amount: useCredit
								? getAppliedAmount(transactions)
								: res.payment.amount - (res.payment.changeDue || 0),
							changeDue: paymentData.changeDue,
						},
						userContext.data.user,
						customer,
						transactions.length === 0,
						selectedCredit !== null,
						null,
						res.customerBalance
					);
				} else if (paymentData.type && paymentData.type === 'Cash')
					webPrint.openCashDrawer();

				if (paymentData.type && paymentData.type === 'Cash')
					addCustomNotification(
						'',
						`Change Due: ${priceFormatter(paymentData.changeDue)}`,
						'',
						'info',
						10000
					);
			},
			err => {
				if (
					err.toString().search('overrideInvoice') > -1 ||
					err.toString().search('overrideCredit') > -1
				) {
					openModal({
						open: modals.RELOAD,
						message:
							err.toString().search('overrideInvoice') > -1
								? 'Some of transactions have been changed since you opened it.'
								: 'Credit have been changed since you opened it.',
					});
				} else addErrorNotification(err.toString());

				setIsLoading(false);
			},
			'',
			{
				customer: customer.id,
				transactions,
				payment: paymentData,
				register: register.id,
				useCredit,
				credit: selectedCredit ? selectedCredit.id : null,
				creditTimeModified: selectedCredit ? selectedCredit.timeModified : null,
			}
		);
	};

	const onPrintStatement = customerIds => {
		apiCall(
			'POST',
			'getPrintStatement',
			res => {
				onPrint(res.statements.join('<p style="page-break-before: always">'));
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{
				outletId: register.outlet.id,
				customerIds,
			}
		);
	};

	useEffect(() => {
		if (customer) {
			setIsLoading(true);
			apiCall(
				'POST',
				'payBillGet',
				res => {
					onReset([...res.invoices, ...res.statementCharges], res.remittances);
					setIsLoading(false);
				},
				err => {
					addErrorNotification(err.toString());
					setIsLoading(false);
					setTransactions([]);
					setRemittances([]);
				},
				'',
				{ customer: customer.id, outletId: register.outlet.id }
			);
		} else onReset([], []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customer, register]);

	useEffect(() => {
		if (!customerFor) setCustomer(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(
		() => onChange(appliedAmount.current !== ''),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[appliedAmount.current]
	);

	const balance =
		getAmountDue() - (appliedAmount.current === '' ? 0 : parseFloat(appliedAmount.current));

	return (
		<Container.Content>
			<ContentInner.Container title={title}>
				<div className='row h-100'>
					<div className='col-xl-8 col-lg-7 col-md-12 col-12'>
						<Portlet fluid='fluid' className='sdms-list-layout sdms-t-paybill-list'>
							<Portlet.Head>
								<Portlet.HeadLabelTitle portletIcon='Clipboard-list'>
									Invoice List
								</Portlet.HeadLabelTitle>
								<Portlet.HeadToolbarActions>
									<Button
										design='default'
										text='Print Statement'
										icon='Printer'
										onClick={() => {
											onPrintStatement([customer.id]);
										}}
										disabled={isLoading || !customer}
									/>
									<Button
										label='dark'
										icon='User'
										onClick={() => openModal({ open: modals.CUSTOMER })}
										disabled={isLoading || customerFor}>
										{customer ? customer.displayName : 'Select A Customer'}
									</Button>
								</Portlet.HeadToolbarActions>
							</Portlet.Head>
							<List
								withOutPortlet
								hasPagination={false}
								data={transactions}
								isLoading={isLoading}
								withCheckbox={false}
								withActions={false}>
								<List.Col
									label='Invoice'
									width={60}
									cellComponent={
										<IdCell
											onClick={data => {
												openModal({
													open: data.invoiceId
														? modals.INVOICE
														: modals.STATEMENT_CHARGE,
													data,
												});
											}}
										/>
									}
									gridName='invoice'
								/>
								{displayOutlet && (
									<List.Col
										label='Location'
										width={140}
										cellData='name'
										cellDataObject='outlet'
										align='center'
										gridName='location'
									/>
								)}
								<List.Col
									label='Type'
									width={140}
									cellComponent={<InvoiceTypeCell />}
									align='center'
									gridName='type'
								/>
								<List.Col
									label='Date'
									width={140}
									cellComponent={<DateCell />}
									gridName='date'
									align='center'
								/>
								<List.Col
									label='Due Date'
									width={80}
									cellComponent={<DueDateCell />}
									gridName='dueDate'
									align='center'
								/>
								<List.Col
									label='Original Amount'
									width='100%'
									align='center'
									cellComponent={<OriginalAmountCell />}
									gridName='originalAmount'
								/>
								<List.Col
									label='Paid'
									width='100%'
									align='center'
									cellComponent={<PaidCell />}
									gridName='paid'
								/>
								<List.Col
									label='Amount Due'
									width='100%'
									align='center'
									cellComponent={<AmountDueCell />}
									gridName='amountDue'
								/>
								<List.Col
									label='Applied'
									cellData='index'
									align='center'
									width='100%'
									cellComponent={
										<AppliedCell
											updateAmount={(data, value) => {
												const transactionIndex = transactions.findIndex(
													i => i.id === data.id
												);

												const _transactions = update(transactions, {
													[transactionIndex]: {
														appliedAmount: {
															$set:
																(data.amount || data.total) -
																	data.amountPaid <=
																value
																	? numberFormat(
																			(data.amount ||
																				data.total) -
																				data.amountPaid
																	  )
																	: value,
														},
													},
												});

												setTransactions(_transactions);
												appliedAmount.current = getAppliedAmount(
													_transactions
												);
											}}
										/>
									}
									gridName='applied'
								/>
								<List.Col
									label='Balance'
									width={80}
									align='right'
									cellComponent={<BalanceCell />}
									gridName='balance'
								/>
							</List>
						</Portlet>
					</div>
					<div className='col-xl-4 col-lg-5 col-md-12 col-12'>
						<Portlet fluid='fluid'>
							<Portlet.Head>
								<Portlet.HeadLabelTitle portletIcon='Dollar'>
									Payment Info
								</Portlet.HeadLabelTitle>
							</Portlet.Head>
							<Portlet.Body>
								<div className='row'>
									<div className='col-12 sdms-mb-20'>
										<div className='row'>
											<Loading isLoading={isLoading}>
												<FormField
													name='creditToggle'
													label={`Credit(s): ${priceFormatter(
														getCreditsTotal()
													)}`}
													description='*Use credit(s) for payment'
													col={12}>
													<Toggle
														onChange={() => {
															setUseCredit(!useCredit);
															applyAmountToTransactions(0);
															appliedAmount.current = '';
															setSelectedCredit(null);
														}}
														value={useCredit}
														disabled={
															isLoading ||
															customer === null ||
															remittances.length === 0
														}
													/>
												</FormField>
											</Loading>
										</div>
									</div>
									<div className='col-12'>
										<div className='row'>
											{useCredit && (
												<FormField name='Credits' label='Credits' col={12}>
													<Radio.Container isInline>
														{remittances.map(r => (
															<>
																<span
																	data-for={`tooltip${r.id.toString()}`}
																	data-tip=''>
																	<Radio
																		key={r.id}
																		id={r.id.toString()}
																		name='credit'
																		checked={
																			(selectedCredit || {})
																				.id === r.id
																		}
																		onChange={() => {
																			setSelectedCredit(r);
																			applyAmountToTransactions(
																				r.unAppliedAmount,
																				true
																			);
																		}}
																		content={`${priceFormatter(
																			r.unAppliedAmount
																		)} - ${dateFormatter(
																			r.timeCreated
																		)}`}
																		disabled={isBeforeAccountingClosedPeriod(
																			r.timeCreated,
																			userContext
																		)}
																	/>
																</span>
																{isBeforeAccountingClosedPeriod(
																	r.timeCreated,
																	userContext
																) && (
																	<Portal>
																		<ReactTooltip
																			id={`tooltip${r.id.toString()}`}
																			place='left'
																			effect='solid'
																			type='light'
																			globalEventOff='click'
																			clickable
																			className='sdms-date-range-picker'>
																			Accounting Period Closed
																		</ReactTooltip>
																	</Portal>
																)}
															</>
														))}
													</Radio.Container>
												</FormField>
											)}

											{!useCredit && (
												<>
													<Loading isLoading={isLoading}>
														<FormField
															name='amountPaid'
															label='Amount to Apply'
															loadingContainer
															col={12}>
															<Input
																onChange={({ target }) => {
																	appliedAmount.current =
																		target.value;
																	applyAmountToTransactions(
																		target.value === ''
																			? 0
																			: parseFloat(
																					target.value
																			  )
																	);
																}}
																type='text'
																value={appliedAmount.current}
																placeholder='Amount to Apply'
																disabled={
																	isLoading || customer === null
																}
																pattern={
																	process.env
																		.REACT_APP_PRICE_PATTERN
																}
															/>
														</FormField>
													</Loading>
												</>
											)}
										</div>
									</div>
								</div>
								<Separator />
							</Portlet.Body>
							<Portlet.Foot>
								<div className='d-block col-12'>
									<div className='row sdms-fitText--xl sdms-font-bolder'>
										<div className='col-12'>Summary:</div>
									</div>
									<div className='row sdms-fitText--lg sdms-font-bolder'>
										<div className='col'>Amount Due</div>
										<div className='col-auto text-right'>
											{priceFormatter(getAmountDue())}
										</div>
									</div>
									<div className='row sdms-fitText--lg sdms-font-bolder'>
										<div className='col'>Amount to Apply</div>
										<div className='col-auto text-right'>
											{priceFormatter(
												appliedAmount.current === ''
													? 0
													: appliedAmount.current
											)}
										</div>
									</div>
									<div className='row sdms-fitText--lg sdms-font-bolder'>
										<div className='col'>
											{balance >= 0 ? 'Balance' : 'Overpayment'}
										</div>
										<div className='col-auto text-right'>
											{priceFormatter(
												balance >= 0 ? balance : Math.abs(balance)
											)}
										</div>
									</div>
								</div>
							</Portlet.Foot>
							<Portlet.Foot>
								<PayButton
									disabled={isLoading}
									onClick={() => {
										onChange(appliedAmount.current !== '');
										onClose();
									}}
									color='danger'
									text='Close'
									u
									icon='Error-circle'
								/>
								<PayButton
									disabled={isLoading || appliedAmount.current === ''}
									onClick={() => {
										if (useCredit) onPay({});
										else openModal({ open: modals.PAY });
									}}
									color='success'
									colorOpacity={false}
									text='Pay'
									icon={
										userContext.data.user.company.settings.defaultCreditCard
											? 'Credit-card'
											: 'Dollar'
									}
									price={priceFormatter(
										appliedAmount.current === '' ? 0 : appliedAmount.current
									)}
									isLoading={isLoading}
									priceAlign={false}
								/>
							</Portlet.Foot>
						</Portlet>
					</div>
				</div>
			</ContentInner.Container>
			<CustomerModal
				isOpen={modal.open === modals.CUSTOMER}
				setSelectedCustomer={_customer => setCustomer(_customer)}
				onClose={closeModal}
				filterIsActive
			/>
			{modal.open === modals.INVOICE && (
				<InvoiceModal
					isOpen={modal.open === modals.INVOICE}
					data={modal.data}
					customer={customer}
					onClose={closeModal}
					outlet={userContext?.data?.selectedOutlet}
					isOnline={false}
				/>
			)}
			{modal.open === modals.STATEMENT_CHARGE && (
				<StatementChargeModal
					isOpen={modal.open === modals.STATEMENT_CHARGE}
					data={modal.data}
					onClose={closeModal}
				/>
			)}
			<PayModal
				isOpen={modal.open === modals.PAY}
				onClose={closeModal}
				paymentTypes={
					userHasPermission('cash_payments')
						? paymentTypes
						: paymentTypes.filter(rt => rt.value !== _paymentTypes.CASH)
				}
				amount={numberFormat(appliedAmount.current === '' ? 0 : appliedAmount.current)}
				holdForSignatureDefault={false}
				printReceiptDefault
				onPay={onPay}
				isRefund={false}
				canEnterCreditCard={userHasPermission('key_in_credit_cards')}
				canSavePaymentMethod={userContext.hasPermission('save_customer_payment_methods')}
				isPaying={isLoading}
				isPayBill
				customer={customer}
				paymentGateway={register.outlet.settings.paymentGateway}
				serviceFeeModule={serviceFeeModules.POS_INVOICES}
				outlet={register.outlet}
				displayEmailReceipt
				emailReceiptDefault={register.outlet.settings.emailPaymentOnPos}
			/>
			<DialogBox
				open={modal.open === modals.RELOAD}
				title=''
				content={modal.message}
				type='question'
				onClose={closeModal}>
				<Button
					label='success'
					text='Reload'
					onClick={() => {
						closeModal();
						setCustomer({ ...customer });
					}}
				/>
			</DialogBox>
			{PRINT_COMPONENT}
		</Container.Content>
	);
};
PayBill.propTypes = {
	userHasPermission: PropTypes.func.isRequired,
	paymentTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	register: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	webPrint: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	customerFor: PropTypes.object,
};

PayBill.defaultProps = {
	customerFor: null,
};

export default PayBill;
