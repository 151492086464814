import { useRef } from 'react';

const useWebWorker = (workerFile, interval, postData = null) => {
	const worker = useRef(
		new Worker(URL.createObjectURL(new Blob([`(${workerFile.toString()})()`])))
	);

	const intervalId = useRef(0);

	const start = (onSuccess, _interval = 0, postInInit = false) => {
		if (intervalId) clearInterval(intervalId.current);

		intervalId.current = setInterval(() => {
			worker.current.postMessage(postData);
		}, (_interval || interval) * 1000);

		worker.current.addEventListener('message', onSuccess);

		if (postInInit) worker.current.postMessage(postData);
	};

	const stop = () => {
		if (intervalId.current) clearInterval(intervalId.current);
	};

	const terminate = () => {
		stop();
		if (worker) worker.current.terminate();
	};

	return [start, stop, terminate];
};

export default useWebWorker;
