import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import apiCall, { modules } from '../../../../../utils/helpers/apiCall';
import FormField from '../../../template/FormField';
import Button from '../../Button';
import useModal from '../../../../../utils/hooks/useModal';
import VesselModal from '../../../modals/VesselModal';
import VehicleModal from '../../../modals/VehicleModal';
import useField from '../../../../../utils/hooks/useField';
import { required } from '../../../../../utils/helpers/validation';
import Input from '../../../field/Input';
import Toggle from '../../../field/Toggle';
import Selects from '../../../field/Selects';
import LoginModal from '../../../modals/LoginModal';
import { searchTypes } from '../../../../../utils/constants/constants';

const modals = {
	VESSEL: 'vessel',
	VEHICLE: 'vehicle',
	LOGIN: 'login',
};

const VehicleSettings = ({
	data,
	isOnline,
	module,
	enumRvTypes,
	enumVehicleMakes,
	enumBoatTypes,
	enumBoatMakes,
	onChange,
	customer,
	onCustomerChange,
	outlet,
	countries,
	defaultCountry,
	states,
	passwordSettings,
	product,
	openCustomerModal,
}) => {
	const [modal, openModal, closeModal] = useModal();

	const moduleData = useMemo(() => {
		if (module === modules.MARINA)
			return {
				title: 'Vessel',
				icon: 'Ship',
			};

		return {
			title: 'Vehicle',
			icon: 'Caravan',
		};
	}, [module]);

	const isRequired = field => product[field] === searchTypes.REQUIRED;

	const displayField = field =>
		product[field] === searchTypes.REQUIRED || product[field] === searchTypes.OPTIONAL;

	const customRequired = field => (isRequired(field) ? required : () => {});

	const [vehicles, setVehicles] = useState([]);

	const [isVehiclesLoading, setIsVehiclesLoading] = useState(false);

	const [vehicle, setVehicle] = useState(null);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		() => {},
		[customRequired('vehicleInformationName')]
	);

	const [
		boatType,
		boatTypeOnChange,
		boatTypeValRes,
		boatTypeShowVal,
		setBoatTypeShowVal,
	] = useField(data, 'boatType', () => {}, [customRequired('vehicleInformationType')], null);

	const [make, makeOnChange, makeValRes, makeShowVal, setMakeShowVal] = useField(
		data,
		'make',
		() => {},
		[customRequired('vehicleInformationMake')],
		null
	);

	const [model, modelOnChange, modelValRes, modelShowVal, setModelShowVal] = useField(
		data,
		'model',
		() => {},
		[customRequired('vehicleInformationModel')]
	);

	const [year, yearOnChange, yearValRes, yearShowVal, setYearShowVal] = useField(
		data,
		'year',
		() => {},
		[customRequired('vehicleInformationYear')]
	);

	const [rvType, rvTypeOnChange, rvTypeValRes, rvTypeShowVal, setRvTypeShowVal] = useField(
		data,
		'rvType',
		() => {},
		[customRequired('vehicleInformationType')],
		null
	);

	const [
		licensePlateNumber,
		licensePlateNumberOnChange,
		licensePlateNumberValRes,
		licensePlateNumberShowVal,
		setLicensePlateNumberShowVal,
	] = useField(data, 'licensePlateNumber', () => {}, [
		customRequired('vehicleInformationLicensePlate'),
	]);

	const [isMotorized, isMotorizedOnChange, isMotorizedValRes, isMotorizedShowVal] = useField(
		data,
		'isMotorized',
		() => {},
		[customRequired('vehicleInformationIsMotorized')]
	);

	useEffect(() => {
		if (isOnline)
			onChange({
				...data,
				isValid:
					nameValRes.isValid &&
					(module === modules.MARINA ? boatTypeValRes.isValid : rvTypeValRes.isValid) &&
					makeValRes.isValid &&
					modelValRes.isValid &&
					yearValRes.isValid &&
					licensePlateNumberValRes.isValid &&
					isMotorizedValRes.isValid,
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		nameValRes.isValid,
		boatTypeValRes.isValid,
		rvTypeValRes.isValid,
		makeValRes.isValid,
		modelValRes.isValid,
		yearValRes.isValid,
		licensePlateNumberValRes.isValid,
		isMotorizedValRes.isValid,
		module,
	]);

	useEffect(() => {
		if (vehicle) {
			const {
				id,
				name: _name,
				boatType: _boatType,
				rvType: _rvType,
				make: _make,
				model: _model,
				year: _year,
				licensePlateNumber: _licensePlateNumber,
				isMotorized: _isMotorized,
			} = vehicle;

			if (_name) nameOnChange({ target: { value: _name } });
			if (_boatType) boatTypeOnChange({ target: { value: _boatType } });
			if (_rvType) rvTypeOnChange({ target: { value: _rvType } });
			if (_make) makeOnChange({ target: { value: _make } });
			if (_model) modelOnChange({ target: { value: _model } });
			if (_year) yearOnChange({ target: { value: _year } });
			if (_licensePlateNumber)
				licensePlateNumberOnChange({ target: { value: _licensePlateNumber } });
			if (_isMotorized) isMotorizedOnChange({ target: { value: _isMotorized } });

			data.id = id;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vehicle]);

	useEffect(() => {
		if (isOnline) {
			if (customer) {
				setIsVehiclesLoading(true);

				apiCall(
					'POST',
					module === modules.MARINA ? 'getCustomerVessels' : 'getCustomerVehicles',
					res => {
						setVehicles(res);
						setIsVehiclesLoading(false);
					},
					() => {
						setVehicles([]);
						setIsVehiclesLoading(false);
					},
					'',
					{
						id: customer.id,
						outletId: outlet.id,
						token: isOnline ? outlet.settings.onlineSiteToken : '',
					}
				);
			} else setVehicles([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customer]);

	return (
		<>
			<div
				className='sdms-heading sdms-heading--md col-12'
				style={{ marginTop: 0, marginBottom: 15 }}>
				{moduleData.title} Information
			</div>
			{isOnline && (
				<>
					<div className='col-12'>
						<div className='row'>
							{customer && (
								<FormField
									name='vehicle'
									label={moduleData.title}
									id={data?.id}
									colMd={6}>
									<Selects
										options={vehicles}
										placeholder={`Select a Saved ${moduleData.title}`}
										value={vehicle}
										displayKey='name'
										onChange={e => setVehicle(e.target.value)}
										disabled={isVehiclesLoading || vehicles.length === 0}
									/>
								</FormField>
							)}
							{!customer && (
								<div
									className='col-md-6'
									style={{ marginTop: 'auto', marginBottom: 'auto' }}>
									<div
										className='sdms-link'
										role='presentation'
										style={{ marginBottom: '3rem' }}
										onClick={() => openModal({ open: modals.LOGIN })}>
										Stayed with us before?
									</div>
								</div>
							)}
						</div>
					</div>
					<div className='col-12'>
						<div className='row'>
							{displayField('vehicleInformationName') && (
								<FormField
									name='name'
									label='Name'
									id={data?.id}
									valRes={nameValRes}
									showValidation={nameShowVal}
									colMd={6}>
									<Input
										type='text'
										placeholder={`Name${
											isRequired('vehicleInformationName')
												? ' (Required)'
												: ''
										}`}
										value={name}
										onChange={nameOnChange}
										onBlur={setNameShowVal}
									/>
								</FormField>
							)}
							{displayField('vehicleInformationType') && (
								<>
									{module === modules.CAMPGROUND ? (
										<FormField
											name='rvType'
											label='RV Type'
											id={data?.id}
											valRes={rvTypeValRes}
											showValidation={rvTypeShowVal}
											colMd={6}>
											<Selects
												options={enumRvTypes}
												placeholder={`RV type${
													isRequired('vehicleInformationType')
														? ' (Required)'
														: ''
												}`}
												value={rvType}
												onChange={rvTypeOnChange}
												onBlur={setRvTypeShowVal}
												displayKey='value'
												disableClearable
											/>
										</FormField>
									) : (
										<FormField
											name='boatType'
											label='Boat Type'
											id={data?.id}
											valRes={boatTypeValRes}
											showValidation={boatTypeShowVal}
											colMd={6}>
											<Selects
												options={enumBoatTypes}
												placeholder={`Boat type${
													isRequired('vehicleInformationType')
														? ' (Required)'
														: ''
												}`}
												value={boatType}
												onChange={boatTypeOnChange}
												onBlur={setBoatTypeShowVal}
												displayKey='value'
												disableClearable
											/>
										</FormField>
									)}
								</>
							)}

							{displayField('vehicleInformationMake') && (
								<FormField
									name='make'
									label='Make'
									id={data?.id}
									colMd={6}
									valRes={makeValRes}
									showValidation={makeShowVal}>
									<Selects
										options={
											module === modules.CAMPGROUND
												? enumVehicleMakes
												: enumBoatMakes
										}
										placeholder={`Make${
											isRequired('vehicleInformationMake')
												? ' (Required)'
												: ''
										}`}
										value={make}
										onChange={makeOnChange}
										displayKey='value'
										onBlur={setMakeShowVal}
									/>
								</FormField>
							)}
							{displayField('vehicleInformationModel') && (
								<FormField
									name='model'
									label='Model'
									id={data?.id}
									colMd={6}
									valRes={modelValRes}
									showValidation={modelShowVal}>
									<Input
										type='text'
										placeholder={`Model${
											isRequired('vehicleInformationModel')
												? ' (Required)'
												: ''
										}`}
										value={model}
										onChange={modelOnChange}
										onBlur={setModelShowVal}
									/>
								</FormField>
							)}
							{displayField('vehicleInformationYear') && (
								<FormField
									name='year'
									label='Year'
									id={data?.id}
									colMd={6}
									valRes={yearValRes}
									showValidation={yearShowVal}>
									<Input
										type='number'
										withOutSpin
										placeholder={`Year${
											isRequired('vehicleInformationYear')
												? ' (Required)'
												: ''
										}`}
										value={year}
										onChange={yearOnChange}
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
										onBlur={setYearShowVal}
									/>
								</FormField>
							)}
							{module === modules.CAMPGROUND &&
								displayField('vehicleInformationLicensePlate') && (
									<FormField
										name='licensePlateNumber'
										label='License Plate Number'
										id={data?.id}
										colMd={6}
										valRes={licensePlateNumberValRes}
										showValidation={licensePlateNumberShowVal}>
										<Input
											type='text'
											placeholder={`License Plate Number${
												isRequired('vehicleInformationLicensePlate')
													? ' (Required)'
													: ''
											}`}
											value={licensePlateNumber}
											onChange={licensePlateNumberOnChange}
											onBlur={setLicensePlateNumberShowVal}
										/>
									</FormField>
								)}
							{module === modules.CAMPGROUND &&
								displayField('vehicleInformationIsMotorized') && (
									<FormField
										name='isMotorized'
										label='Motorized'
										id={data?.id}
										colMd={6}
										valRes={isMotorizedValRes}
										showValidation={isMotorizedShowVal}>
										<Toggle
											onChange={isMotorizedOnChange}
											value={isMotorized}
										/>
									</FormField>
								)}
						</div>
					</div>
				</>
			)}
			{!isOnline && (module === modules.MARINA || module === modules.CAMPGROUND) && (
				<FormField label='Customer' name='customer' id={data?.id} colMd={4}>
					<Button
						className='h-100 sdms-fitText'
						label='dark'
						icon='User'
						block
						onClick={openCustomerModal}>
						{customer ? customer.displayName : 'Customer Name'}
					</Button>
				</FormField>
			)}
			{!isOnline && module === modules.MARINA && (
				<>
					<FormField label='Vessel' name='vessel' id={data?.id} colMd={4}>
						<Button
							className='h-100 sdms-fitText'
							label='dark'
							icon='Marina'
							block
							onClick={() =>
								openModal({
									open: modals.VESSEL,
								})
							}>
							{data.name || 'Select a Vessel'}
						</Button>
					</FormField>
					<VesselModal
						isOpen={modal.open === modals.VESSEL}
						onClose={closeModal}
						onSelect={v => {
							onChange({ ...v, isValid: true });
							closeModal();
						}}
						hasForm
						enumBoatTypes={enumBoatTypes}
						enumBoatMakes={enumBoatMakes}
					/>
				</>
			)}
			{!isOnline && module === modules.CAMPGROUND && (
				<>
					<FormField label='Vehicle' name='vehicle' id={data?.id} colMd={4}>
						<Button
							className='h-100 sdms-fitText'
							label='dark'
							icon='Caravan'
							block
							onClick={() =>
								openModal({
									open: modals.VEHICLE,
								})
							}>
							{data.name || 'Select a Vehicle'}
						</Button>
					</FormField>
					<VehicleModal
						isOpen={modal.open === modals.VEHICLE}
						onClose={closeModal}
						onSelect={v => {
							onChange({ ...v, isValid: true });
							closeModal();
						}}
						hasForm
						enumRvTypes={enumRvTypes}
						enumVehicleMakes={enumVehicleMakes}
					/>
				</>
			)}
			{isOnline && (
				<LoginModal
					isOpen={modal.open === modals.LOGIN}
					onLogin={_customer => {
						onCustomerChange(_customer);
						closeModal();
					}}
					onRegister={_customer => {
						onCustomerChange(_customer);
						closeModal();
					}}
					onClose={closeModal}
					countries={countries}
					defaultCountry={defaultCountry}
					states={states}
					outlet={outlet}
					passwordSettings={passwordSettings}
				/>
			)}
		</>
	);
};

VehicleSettings.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
	}),
	isOnline: PropTypes.bool.isRequired,
	module: PropTypes.string.isRequired,
	enumRvTypes: PropTypes.arrayOf(PropTypes.object),
	enumVehicleMakes: PropTypes.arrayOf(PropTypes.object),
	enumBoatTypes: PropTypes.arrayOf(PropTypes.object),
	enumBoatMakes: PropTypes.arrayOf(PropTypes.object),
	onChange: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	onCustomerChange: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	countries: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	defaultCountry: PropTypes.object,
	states: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	passwordSettings: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	product: PropTypes.object.isRequired,
	openCustomerModal: PropTypes.func,
};

VehicleSettings.defaultProps = {
	data: {
		id: 0,
		name: '',
	},
	enumRvTypes: [],
	enumVehicleMakes: [],
	enumBoatTypes: [],
	enumBoatMakes: [],
	onChange: [],
	customer: null,
	countries: [],
	defaultCountry: null,
	states: [],
	passwordSettings: null,
	openCustomerModal: () => {},
};

export default VehicleSettings;
