import React from 'react';
import PropTypes from 'prop-types';

import { priceFormatter } from '../../../../../utils/helpers/helper';

import Separator from '../../../layout/Separator';
import Loading from '../../../template/Loading';

const CardPricingDetails = ({ date, subtotal, extras, tax, total, isLoading }) => {
	return (
		<div className='sdms-item-card-details'>
			<Loading isLoading={isLoading} type='div'>
				<div className='row sdms-mb-5'>
					<div className='col-2 sdms-align-left'>Date</div>
					<div className='col-10 sdms-align-right'>{date}</div>
				</div>
			</Loading>
			<Loading isLoading={isLoading} type='div'>
				<div className='row sdms-mb-5'>
					<div className='col-4 sdms-align-left'>Price</div>
					<div className='col-8 sdms-align-right'>{priceFormatter(subtotal)}</div>
				</div>
			</Loading>
			<Loading isLoading={isLoading} type='div'>
				<div className='row sdms-mb-5'>
					<div className='col-4 sdms-align-left'>Extras</div>
					<div className='col-8 sdms-align-right'>{priceFormatter(extras)}</div>
				</div>
			</Loading>
			<Loading isLoading={isLoading} type='div'>
				<div className='row sdms-mb-5'>
					<div className='col-4 sdms-align-left'>Tax</div>
					<div className='col-8 sdms-align-right'>{priceFormatter(tax)}</div>
				</div>
			</Loading>
			<div className='sdms-item-card-details-separator'>
				<Separator />
			</div>
			<Loading isLoading={isLoading} type='div'>
				<div className='row sdms-mb-5'>
					<div className='col-4 sdms-align-left'>Total</div>
					<div className='col-8 sdms-align-right'>{priceFormatter(total)}</div>
				</div>
			</Loading>
		</div>
	);
};

CardPricingDetails.propTypes = {
	date: PropTypes.node,
	subtotal: PropTypes.number,
	extras: PropTypes.number,
	tax: PropTypes.number,
	total: PropTypes.number,
	isLoading: PropTypes.bool,
};

CardPricingDetails.defaultProps = {
	date: null,
	subtotal: 0,
	extras: 0,
	tax: 0,
	total: 0,
	isLoading: false,
};

export default CardPricingDetails;
