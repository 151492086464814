import React from 'react';
import PropTypes from 'prop-types';

import pages from '../../pages';

import FormContainer from '../../reusables/template/FormContainer';
import ProductFilterForm from './ProductFilterForm';

const ProductFilterEdit = ({ match }) => {
	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle={pages.systemSettings.productFilters.text}
			dataName={pages.systemSettings.productFilters.route}
			lists={['enumProductFilterTypes', 'enumProductFilterComparisons', 'icons']}
			fields={<ProductFilterForm />}
		/>
	);
};

ProductFilterEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ProductFilterEdit;
