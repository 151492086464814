import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import useField from '../../../utils/hooks/useField';
import {
	excludeDatesValidation,
	maxLength,
	numeric,
	required,
} from '../../../utils/helpers/validation';
import {
	bookingPeriodFinder,
	dateFormatterWithoutYear,
	filterInvoicingFrequency,
	getBookingCalculationText,
	getNextInvoice,
	getNextInvoiceOptions,
	isInvoicingFrequencyBigger,
	negativeNumberParser,
	numberParser,
	parseDatePickerChange,
	parseDatePickerValue,
} from '../../../utils/helpers/helper';
import { modules } from '../../../utils/helpers/apiCall';
import {
	bookingPeriods,
	enumItemTypes,
	extraChargeTypes,
	invoicingFrequencies as _invoicingFrequencies,
	firstInvoiceOptions,
	nextInvoiceOptions,
} from '../../../utils/constants/constants';
import usePages from '../../../utils/hooks/usePages';

import FormGroup from '../../reusables/layout/FormGroup';
import Section from '../../reusables/layout/Section';
import Loading from '../../reusables/template/Loading';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import ImageUpload from '../../reusables/field/ImageUpload';
import Toggle from '../../reusables/field/Toggle';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Radio from '../../reusables/field/Radio';
import DatePicker from '../../reusables/field/DatePicker';
import ExcludeDates from '../../reusables/element/ExcludeDates';

const ExtraChargePerUnitForm = ({
	data,
	isLoading,
	setTitle,
	onFormChange,
	isSubmitted,
	setIsValid,
	bookingTypeMarinas,
	enumBookingPeriods,
	enumBookingCalculations,
	enumExtraChargeTypes,
	itemTypes,
	productCategoryExtraCharges,
	taxCodes,
	taxAgencies,
	accounts,
	unitMeasures,
	taxRates,
	submitButtonAttr,
	submit,
	paymentTerms,
	invoiceFirstOptions,
	invoiceNextOptions,
	invoicingFrequencies,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const marinaModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.MARINA)
	);

	const invoicingFrequencyRequired = (value, setValRes) => {
		if (
			bookingPeriod &&
			(bookingPeriod.value === bookingPeriods.SEASONAL ||
				bookingPeriod.value === bookingPeriods.LONG_TERM)
		)
			return required(value, setValRes);
		return true;
	};

	const firstInvoiceRequired = (value, setValRes) => {
		if (
			invoicingFrequency &&
			invoicingFrequency.value !== _invoicingFrequencies.SAME_AS_RESERVATION &&
			invoicingFrequency.value !== _invoicingFrequencies.UPFRONT
		)
			return required(value, setValRes);

		return true;
	};

	const nextInvoiceRequired = (value, setValRes) => {
		if (
			invoicingFrequency &&
			invoicingFrequency.value !== _invoicingFrequencies.SAME_AS_RESERVATION &&
			invoicingFrequency.value !== _invoicingFrequencies.UPFRONT &&
			firstInvoice
		)
			return required(value, setValRes);

		return true;
	};

	const proratedFrequencyRequired = (value, setValRes) => {
		if (firstInvoice && firstInvoice.value === firstInvoiceOptions.PRO_RATE)
			return required(value, setValRes);
		return true;
	};

	const coTermRequired = (value, setValRes) => {
		if (nextInvoice && nextInvoice.value === nextInvoiceOptions.CO_TERM)
			return required(value, setValRes);
		return true;
	};

	// conditionally required for deferred fields.
	const deferredRequired = (value, setValRes) => {
		if (enableDeferredIncome) return required(value, setValRes);
		return true;
	};

	const maxDurationValidation = (value, setValRes) => {
		if (!value) return true;
		if (minDuration > value) {
			setValRes({
				isValid: false,
				status: 'invalidMaxDuration',
				message: 'Max duration time must be equal or bigger than min duration',
			});
			return false;
		}

		return true;
	};

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [
		bookingType,
		bookingTypeOnChange,
		bookingTypeValRes,
		bookingTypeShowVal,
		setBookingTypeShowVal,
	] = useField(data, 'bookingType', onFormChange, [required], null);

	const [
		bookingPeriod,
		bookingPeriodOnChange,
		bookingPeriodValRes,
		bookingPeriodShowVal,
		setBookingPeriodShowVal,
	] = useField(data, 'bookingPeriod', onFormChange, [required], null);

	const [
		itemType,
		itemTypeOnChange,
		itemTypeValRes,
		itemTypeShowVal,
		setItemTypeShowVal,
	] = useField(data, 'itemType', onFormChange, [required], null);

	const [productCategory, productCategoryOnChange] = useField(
		data,
		'productCategory',
		onFormChange,
		[],
		null
	);

	const [account, accountOnChange, accountValRes, accountShowVal, setAccountShowVal] = useField(
		data,
		'account',
		onFormChange,
		[required],
		null
	);

	const [
		incomeAccount,
		incomeAccountOnChange,
		incomeAccountValRes,
		incomeAccountShowVal,
		setIncomeAccountShowVal,
	] = useField(data, 'incomeAccount', onFormChange, [required], null);

	const [
		cogsAccount,
		cogsAccountOnChange,
		cogsAccountValRes,
		cogsAccountShowVal,
		setCogsAccountShowVal,
	] = useField(data, 'cogsAccount', onFormChange, [required], null);

	const [
		assetsAccount,
		assetsAccountOnChange,
		assetsAccountValRes,
		assetsAccountShowVal,
		setAssetsAccountShowVal,
	] = useField(data, 'assetsAccount', onFormChange, [required], null);

	const [
		description,
		descriptionOnChange,
		descriptionValRes,
		descriptionShowVal,
		setDescriptionShowVal,
	] = useField(data, 'description', onFormChange, [maxLength]);

	const [
		externalDescription,
		externalDescriptionOnChange,
		externalDescriptionValRes,
		externalDescriptionShowVal,
		setExternalDescriptionShowVal,
	] = useField(data, 'externalDescription', onFormChange, [maxLength]);

	const [productImage, setProductImage] = useField(data, 'productImage', onFormChange, [], null);

	const [taxCode, taxCodeOnChange, taxCodeValRes, taxCodeShowVal, setTaxCodeShowVal] = useField(
		data,
		'taxCode',
		onFormChange,
		[required],
		null
	);

	const [price, priceOnChange, priceValRes, priceShowVal, setPriceShowVal] = useField(
		data,
		'price',
		onFormChange,
		[required, numeric],
		0,
		negativeNumberParser
	);

	const [
		minPrice,
		minPriceOnChange,
		minPriceValRes,
		minPriceShowVal,
		setMinPriceShowVal,
	] = useField(data, 'minPrice', onFormChange, [numeric], 0, negativeNumberParser);

	const [
		maxPrice,
		maxPriceOnChange,
		maxPriceValRes,
		maxPriceShowVal,
		setMaxPriceShowVal,
	] = useField(data, 'maxPrice', onFormChange, [numeric], 0, negativeNumberParser);

	const [minDuration, minDurationOnChange] = useField(
		data,
		'minDuration',
		onFormChange,
		[],
		0,
		numberParser(false, 0)
	);

	const [
		maxDuration,
		maxDurationOnChange,
		maxDurationValRes,
		maxDurationShowVal,
		setMaxDurationShowVal,
		validateMaxDuration,
	] = useField(
		data,
		'maxDuration',
		onFormChange,
		[maxDurationValidation],
		0,
		numberParser(false, 0)
	);

	const [
		bookingCalculation,
		bookingCalculationOnChange,
		bookingCalculationValRes,
		bookingCalculationShowVal,
		setBookingCalculationShowVal,
	] = useField(data, 'bookingCalculation', onFormChange, [required], null);

	const [unitMeasure, unitMeasureOnChange] = useField(
		data,
		'unitMeasure',
		onFormChange,
		[],
		null
	);

	const [additionalTaxes, additionalTaxesOnChange] = useField(
		data,
		'additionalTaxes',
		onFormChange,
		[],
		[]
	);

	const [optional, optionalOnChange] = useField(data, 'optional', onFormChange, [], true);

	const [isTax, isTaxOnChange] = useField(data, 'isTax', onFormChange, [], false);

	const [applyTaxes, applyTaxesOnChange] = useField(data, 'applyTaxes', onFormChange, [], []);

	const [
		taxAgency,
		taxAgencyOnChange,
		taxAgencyValRes,
		taxAgencyShowVal,
		setTaxAgencyShowVal,
	] = useField(data, 'taxAgency', onFormChange, [], null);

	const [hideInResults, hideInResultsOnChange] = useField(
		data,
		'hideInResults',
		onFormChange,
		[],
		false
	);

	const [invoiceGenerationAutomatedBatch, invoiceGenerationAutomatedBatchOnChange] = useField(
		data,
		'invoiceGenerationAutomatedBatch',
		onFormChange,
		[],
		true
	);

	const [
		invoicingFrequency,
		invoicingFrequencyOnChange,
		invoicingFrequencyValRes,
		invoicingFrequencyShowVal,
		setInvoicingFrequencyShowVal,
		validateInvoicingFrequency,
	] = useField(data, 'invoicingFrequency', onFormChange, [invoicingFrequencyRequired], null);

	const [paymentTerm, paymentTermOnChange] = useField(
		data,
		'paymentTerm',
		onFormChange,
		[],
		null
	);

	const [
		firstInvoice,
		firstInvoiceOnChange,
		firstInvoiceValRes,
		firstInvoiceShowVal,
		setFirstInvoiceShowVal,
		validateFirstInvoice,
	] = useField(data, 'firstInvoice', onFormChange, [firstInvoiceRequired], null);

	const [
		nextInvoice,
		nextInvoiceOnChange,
		nextInvoiceValRes,
		nextInvoiceShowVal,
		setNextInvoiceShowVal,
		validateNextInvoice,
	] = useField(data, 'nextInvoice', onFormChange, [nextInvoiceRequired], null);

	const [
		proratedFrequency,
		proratedFrequencyOnChange,
		proratedFrequencyValRes,
		proratedFrequencyShowVal,
		setProratedFrequencyShowVal,
		validateProratedFrequency,
	] = useField(data, 'proratedFrequency', onFormChange, [proratedFrequencyRequired], null);

	const [
		coTermDate,
		coTermDateOnChange,
		coTermDateValRes,
		coTermDateShowVal,
		setCoTermDateShowVal,
		validateCoTermDate,
	] = useField(data, 'coTermDate', onFormChange, [coTermRequired], null);

	const [enableDeferredIncome, enableDeferredIncomeOnChange] = useField(
		data,
		'enableDeferredIncome',
		onFormChange,
		[],
		false
	);

	const [
		deferredIncomeFrequency,
		deferredIncomeFrequencyOnChange,
		deferredIncomeFrequencyValRes,
		deferredIncomeFrequencyShowVal,
		setDeferredIncomeFrequencyShowVal,
		validateDeferredIncomeFrequency,
	] = useField(data, 'deferredIncomeFrequency', onFormChange, [deferredRequired], null);

	const [
		deferredIncomeSalesAccount,
		deferredIncomeSalesAccountOnChange,
		deferredIncomeSalesAccountValRes,
		deferredIncomeSalesAccountShowVal,
		setDeferredIncomeSalesAccountShowVal,
		validateDeferredIncomeSalesAccount,
	] = useField(data, 'deferredIncomeSalesAccount', onFormChange, [deferredRequired], null);

	const [excludeDates, excludeDatesOnChange, excludeDatesValRes] = useField(
		data,
		'excludeDates',
		onFormChange,
		[excludeDatesValidation],
		[]
	);

	const parseBookingCalculationOptions = bc => {
		if (bc) {
			if (!bc.value) return bc;

			bc.label = getBookingCalculationText(bookingPeriod, bc);
		}

		return bc;
	};

	const filterBookingPeriods = () => {
		if (!bookingType) return enumBookingPeriods;

		return enumBookingPeriods.filter(
			ebp =>
				(ebp.value === bookingPeriods.HOURLY && bookingType.hourly) ||
				(ebp.value === bookingPeriods.DAILY && bookingType.daily) ||
				(ebp.value === bookingPeriods.NIGHTLY && bookingType.nightly) ||
				(ebp.value === bookingPeriods.LONG_TERM && bookingType.longTerm) ||
				(ebp.value === bookingPeriods.SEASONAL && bookingType.seasonal)
		);
	};

	const areInvoiceSettingFieldsDisabled = (isInvoicingFrequency = false) => {
		if (!bookingPeriod) return true;

		let isDisabled =
			bookingPeriod.value === bookingPeriods.HOURLY ||
			bookingPeriod.value === bookingPeriods.DAILY ||
			bookingPeriod.value === bookingPeriods.NIGHTLY;

		if (
			!isDisabled &&
			!isInvoicingFrequency &&
			invoicingFrequency &&
			invoicingFrequency.value === _invoicingFrequencies.SAME_AS_RESERVATION
		)
			isDisabled = true;

		return isDisabled;
	};

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.marina.default.text,
				path: pages.marina.default.path,
			},
			{
				title: pages.marina.settings.text,
				path: pages.marina.settings.path,
			},
			{
				title: pages.marina.settings.extraCharges.text,
				path: pages.marina.settings.extraCharges.path,
			},
			{
				title: pages.marina.settings.extraCharges.perUnit.text,
				path: pages.marina.settings.extraCharges.path,
			},
			{ title: name || `New ${pages.marina.settings.extraCharges.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.marina.settings.extraCharges.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setBookingTypeShowVal();
			setBookingPeriodShowVal();
			setBookingCalculationShowVal();
			setPriceShowVal();
			setTaxCodeShowVal();
			setTaxAgencyShowVal();
			setItemTypeShowVal();
			setAccountShowVal();
			setIncomeAccountShowVal();
			setCogsAccountShowVal();
			setAssetsAccountShowVal();
			setDescriptionShowVal();
			setExternalDescriptionShowVal();
			setFirstInvoiceShowVal();
			setInvoicingFrequencyShowVal();
			setNextInvoiceShowVal();
			setProratedFrequencyShowVal();
			setCoTermDateShowVal();
			setDeferredIncomeFrequencyShowVal();
			setDeferredIncomeSalesAccountShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setPriceShowVal,
		setBookingTypeShowVal,
		setBookingPeriodShowVal,
		setBookingCalculationShowVal,
		setTaxCodeShowVal,
		setTaxAgencyShowVal,
		setItemTypeShowVal,
		setAccountShowVal,
		setIncomeAccountShowVal,
		setCogsAccountShowVal,
		setAssetsAccountShowVal,
		setDescriptionShowVal,
		setExternalDescriptionShowVal,
		setFirstInvoiceShowVal,
		setInvoicingFrequencyShowVal,
		setNextInvoiceShowVal,
		setProratedFrequencyShowVal,
		setCoTermDateShowVal,
		setDeferredIncomeFrequencyShowVal,
		setDeferredIncomeSalesAccountShowVal,
	]);
	// useEffect to update form validity
	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				bookingPeriodValRes.isValid &&
				bookingTypeValRes.isValid &&
				priceValRes.isValid &&
				taxCodeValRes.isValid &&
				taxAgencyValRes.isValid &&
				itemTypeValRes.isValid &&
				(itemType.value === enumItemTypes.INVENTORY
					? cogsAccountValRes.isValid &&
					  incomeAccountValRes.isValid &&
					  assetsAccountValRes.isValid
					: accountValRes.isValid) &&
				bookingCalculationValRes.isValid &&
				descriptionValRes.isValid &&
				externalDescriptionValRes.isValid &&
				invoicingFrequencyValRes.isValid &&
				firstInvoiceValRes.isValid &&
				nextInvoiceValRes.isValid &&
				proratedFrequencyValRes.isValid &&
				coTermDateValRes.isValid &&
				deferredIncomeFrequencyValRes.isValid &&
				deferredIncomeSalesAccountValRes.isValid &&
				excludeDatesValRes.isValid
		);
	}, [
		nameValRes.isValid,
		priceValRes.isValid,
		taxCodeValRes.isValid,
		taxAgencyValRes.isValid,
		itemTypeValRes.isValid,
		accountValRes.isValid,
		bookingCalculationValRes.isValid,
		incomeAccountValRes.isValid,
		cogsAccountValRes.isValid,
		assetsAccountValRes.isValid,
		descriptionValRes.isValid,
		externalDescriptionValRes.isValid,
		setIsValid,
		bookingPeriodValRes.isValid,
		bookingTypeValRes.isValid,
		firstInvoiceValRes,
		itemType,
		bookingPeriod,
		invoicingFrequencyValRes.isValid,
		invoicingFrequency,
		nextInvoiceValRes.isValid,
		firstInvoice,
		nextInvoice,
		proratedFrequencyValRes.isValid,
		coTermDateValRes.isValid,
		deferredIncomeFrequencyValRes.isValid,
		deferredIncomeSalesAccountValRes.isValid,
		excludeDatesValRes.isValid,
	]);

	useEffect(() => {
		if (!isLoading) {
			data.module = marinaModule.current;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		if (enumExtraChargeTypes.length > 0)
			data.extraChargeType = enumExtraChargeTypes.find(
				ect => ect.value === extraChargeTypes.PER_UNIT
			);
	}, [data.extraChargeType, enumExtraChargeTypes]);

	useEffect(() => {
		validateInvoicingFrequency(invoicingFrequency);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bookingPeriod]);

	useEffect(() => {
		validateFirstInvoice(firstInvoice);
		validateNextInvoice(nextInvoice);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoicingFrequency]);

	useEffect(() => {
		validateProratedFrequency(proratedFrequency);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstInvoice]);

	useEffect(() => {
		validateCoTermDate(coTermDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextInvoice]);

	useEffect(() => {
		// re-validate deferred field on enableDeferredIncome change.
		validateDeferredIncomeFrequency(deferredIncomeFrequency);
		validateDeferredIncomeSalesAccount(deferredIncomeSalesAccount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enableDeferredIncome]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						<div className='col-12'>
							<Section title='General'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='productId'
												label='Item ID'
												id={data.id}
												colMd={6}>
												<Input
													type='text'
													placeholder='Auto Generate'
													value={data.productId}
													onChange={() => {}}
													disabled
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='name'
												label='Name'
												id={data.id}
												valRes={nameValRes}
												showValidation={nameShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Name (required)'
													value={name}
													onChange={nameOnChange}
													onBlur={setNameShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='bookingType'
												label='Booking Type'
												id={data.id}
												valRes={bookingTypeValRes}
												showValidation={bookingTypeShowVal}
												colMd={3}>
												<Selects
													options={bookingTypeMarinas}
													placeholder='Booking Types'
													value={bookingType}
													onChange={e => {
														bookingTypeOnChange(e);
														bookingPeriodOnChange({
															target: { value: {} },
														});
													}}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='bookingPeriod'
												label='Booking Period'
												id={data.id}
												valRes={bookingPeriodValRes}
												showValidation={bookingPeriodShowVal}
												colMd={3}>
												<Selects
													options={filterBookingPeriods()}
													placeholder='Booking Period'
													value={bookingPeriod}
													onChange={e => {
														bookingCalculationOnChange({
															target: {
																name: 'bookingCalculation',
																value: null,
															},
														});

														invoicingFrequencyOnChange({
															target: { value: null },
														});

														firstInvoiceOnChange({
															target: { value: null },
														});

														nextInvoiceOnChange({
															target: { value: null },
														});

														// clear deferred income frequency to force reselect.
														deferredIncomeFrequencyOnChange({
															target: { value: null },
														});

														// if booking period is not seasonal or long term clear deferred fields.
														if (
															e.target.value &&
															!(
																e.target.value ===
																	bookingPeriods.SEASONAL ||
																e.target.value ===
																	bookingPeriods.LONG_TERM
															) &&
															enableDeferredIncome
														) {
															enableDeferredIncomeOnChange({
																target: { value: false },
															});

															deferredIncomeSalesAccountOnChange({
																target: { value: false },
															});
														}

														bookingPeriodOnChange(e);
													}}
													displayKey='value'
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='itemType'
												label='Item Type'
												valRes={itemTypeValRes}
												showValidation={itemTypeShowVal}
												id={data.id}
												colMd={6}>
												<Selects
													options={itemTypes.filter(it => !it.isSystem)}
													placeholder='Type (Required)'
													value={itemType}
													onChange={({ target }) => {
														itemTypeOnChange({ target });
														accountOnChange({
															target: {
																name: 'account',
																value: null,
															},
														});
														incomeAccountOnChange({
															target: {
																name: 'incomeAccount',
																value: null,
															},
														});
														cogsAccountOnChange({
															target: {
																name: 'cogsAccount',
																value: null,
															},
														});

														assetsAccountOnChange({
															target: {
																name: 'assetsAccount',
																value: null,
															},
														});
													}}
													onBlur={setItemTypeShowVal}
													displayKey='value'
													disabled={data.id !== 0}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='productCategory'
												label='Group'
												description='Select a category to group Price Adjustments as an option'
												id={data.id}
												colMd={6}>
												<Selects
													options={productCategoryExtraCharges}
													placeholder='Group'
													value={productCategory}
													onChange={productCategoryOnChange}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='description'
												label='Description'
												id={data.id}
												valRes={descriptionValRes}
												showValidation={descriptionShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Description'
													value={description}
													onChange={descriptionOnChange}
													onBlur={setDescriptionShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='externalDescription'
												label='External Description'
												id={data.id}
												valRes={externalDescriptionValRes}
												showValidation={externalDescriptionShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='External Description'
													value={externalDescription}
													onChange={externalDescriptionOnChange}
													onBlur={setExternalDescriptionShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='productImage'
												label='Image'
												description='.png, .jpg or .jpeg only'
												colMd={3}>
												<ImageUpload
													media={productImage}
													setMedia={image =>
														setProductImage({
															target: {
																name: 'productImage',
																value: image,
															},
														})
													}
												/>
											</FormField>
										</Loading>
									</FormGroup>
								</Section.Body>
							</Section>
							<Section title='Accounting'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='taxCode'
												label='Tax Code'
												id={data.id}
												valRes={taxCodeValRes}
												showValidation={taxCodeShowVal}
												colMd={6}>
												<Selects
													options={taxCodes}
													placeholder='Tax Code (Required)'
													value={taxCode}
													onChange={taxCodeOnChange}
													onBlur={setTaxCodeShowVal}
												/>
											</FormField>
										</Loading>
										{(itemType || {}).value === 'inventory' ? (
											<>
												<Loading isLoading={isLoading}>
													<FormField
														name='incomeAccount'
														label='Income Account'
														id={data.id}
														valRes={incomeAccountValRes}
														showValidation={incomeAccountShowVal}
														colMd={6}>
														<Selects
															options={accounts}
															placeholder='Income Account (Required)'
															value={incomeAccount}
															onChange={incomeAccountOnChange}
															onBlur={setIncomeAccountShowVal}
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='cogsAccount'
														label='COGS Account'
														id={data.id}
														valRes={cogsAccountValRes}
														showValidation={cogsAccountShowVal}
														colMd={6}>
														<Selects
															options={accounts}
															placeholder='COGS Account (Required)'
															value={cogsAccount}
															onChange={cogsAccountOnChange}
															onBlur={setCogsAccountShowVal}
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='assetsAccount'
														label='Assets Account'
														id={data.id}
														valRes={assetsAccountValRes}
														showValidation={assetsAccountShowVal}
														colMd={6}>
														<Selects
															options={accounts}
															placeholder='Assets Account (Required)'
															value={assetsAccount}
															onChange={assetsAccountOnChange}
															onBlur={setAssetsAccountShowVal}
														/>
													</FormField>
												</Loading>
											</>
										) : (
											<Loading isLoading={isLoading}>
												<FormField
													name='account'
													label='Account'
													id={data.id}
													valRes={accountValRes}
													showValidation={accountShowVal}
													colMd={6}>
													<Selects
														options={accounts}
														placeholder='Account (Required)'
														value={account}
														onChange={accountOnChange}
														onBlur={setAccountShowVal}
													/>
												</FormField>
											</Loading>
										)}
									</FormGroup>
								</Section.Body>
							</Section>
							<Section title='Base Pricing'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='price'
												label='Price'
												id={data.id}
												valRes={priceValRes}
												showValidation={priceShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Price (Required)'
													value={price.toString()}
													onChange={priceOnChange}
													onBlur={setPriceShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='unitMeasure'
												label='Unit of Measure'
												id={data.id}
												colMd={6}>
												<Selects
													options={unitMeasures}
													placeholder='Unit of Measure'
													value={unitMeasure}
													onChange={unitMeasureOnChange}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='bookingCalculation'
												label='Calculation'
												id={data.id}
												valRes={bookingCalculationValRes}
												showValidation={bookingCalculationShowVal}
												colMd={6}>
												<Selects
													options={enumBookingCalculations
														.filter(
															ebc =>
																ebc.extraChargeTypes.findIndex(
																	ect =>
																		ect.value ===
																		extraChargeTypes.PER_UNIT
																) > -1 &&
																bookingPeriod &&
																ebc.bookingPeriods.filter(
																	bp =>
																		bp.value ===
																		bookingPeriod.value
																).length > 0
														)
														.map(ebc =>
															parseBookingCalculationOptions(ebc)
														)
														.filter(ebc => ebc.label !== 'per month')}
													placeholder='Calculation (Required)'
													value={parseBookingCalculationOptions(
														bookingCalculation
													)}
													onChange={bookingCalculationOnChange}
													onBlur={setBookingCalculationShowVal}
													displayKey='label'
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='additionalTaxes'
												label='Additional Taxes'
												id={data.id}
												colMd={6}>
												<Selects
													options={taxRates.filter(
														tr =>
															tr.id !==
																userContext.data.selectedOutlet
																	.settings.taxRate.id &&
															!tr.isGroup
													)}
													placeholder='Select Tax Rate'
													value={additionalTaxes}
													onChange={additionalTaxesOnChange}
													multiple
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='minPrice'
												label='Minimum Price'
												id={data.id}
												valRes={minPriceValRes}
												showValidation={minPriceShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Minimum Price'
													value={minPrice.toString()}
													onChange={minPriceOnChange}
													onBlur={setMinPriceShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='maxPrice'
												label='Maximum Price'
												id={data.id}
												valRes={maxPriceValRes}
												showValidation={maxPriceShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Maximum Price'
													value={maxPrice.toString()}
													onChange={maxPriceOnChange}
													onBlur={setMaxPriceShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='minDuration'
												label='Minimum Duration'
												id={data.id}
												colMd={6}>
												<Input
													type='text'
													placeholder='Min Duration'
													value={minDuration}
													onChange={e => {
														minDurationOnChange(e);
														validateMaxDuration(maxDuration);
													}}
													append={bookingPeriodFinder(bookingPeriod)}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='maxDuration'
												label='Maximum Duration'
												id={data.id}
												valRes={maxDurationValRes}
												showValidation={maxDurationShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Max Duration'
													value={maxDuration}
													onChange={maxDurationOnChange}
													append={bookingPeriodFinder(bookingPeriod)}
													onBlur={setMaxDurationShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='optional'
												label='Optional'
												id={data.id}
												colMd={2}>
												{productCategory ? (
													<Toggle
														onChange={() => {}}
														value={false}
														disabled
													/>
												) : (
													<Toggle
														onChange={optionalOnChange}
														value={optional}
													/>
												)}
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='hideInResults'
												label='Hide In Results'
												id={data.id}
												colMd={4}>
												<Toggle
													onChange={hideInResultsOnChange}
													value={hideInResults}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='isTax'
												label='Is Tax'
												id={data.id}
												colMd={1}>
												<Toggle
													value={isTax}
													onChange={e => {
														isTaxOnChange(e);

														taxAgencyOnChange({
															target: { value: null },
														});
													}}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='applyTaxes'
												label='Apply Taxes'
												id={data.id}
												colMd={2}>
												<Selects
													options={taxCodes}
													placeholder='Select Tax Codes'
													value={applyTaxes}
													onChange={applyTaxesOnChange}
													multiple
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='taxAgency'
												label='Tax Agency'
												id={data.id}
												valRes={taxAgencyValRes}
												showValidation={taxAgencyShowVal}
												colMd={3}>
												<Selects
													options={taxAgencies}
													placeholder='Tax Agency'
													value={taxAgency}
													onChange={taxAgencyOnChange}
													onBlur={setTaxAgencyShowVal}
													disabled={!data.isTax}
												/>
											</FormField>
										</Loading>
									</FormGroup>
								</Section.Body>
							</Section>
							<Section title='Invoice Settings'>
								<Section.Body>
									<FormGroup row isLast>
										<Loading isLoading={isLoading}>
											<FormField
												name='invoiceGeneration'
												label='Invoice Generation'
												id={data.id}
												colXl={3}>
												<Radio.Container isInline>
													<Radio
														checked={invoiceGenerationAutomatedBatch}
														id='invoiceGenerationYes'
														name='automatedBatch'
														content='Automatic'
														className='sdms-radio--primary'
														onChange={() =>
															invoiceGenerationAutomatedBatchOnChange(
																{
																	target: { value: true },
																}
															)
														}
														disabled={areInvoiceSettingFieldsDisabled(
															true
														)}
													/>
													<Radio
														checked={!invoiceGenerationAutomatedBatch}
														id='invoiceGenerationNo'
														name='manual'
														content='Manual'
														className='sdms-radio--primary'
														onChange={() =>
															invoiceGenerationAutomatedBatchOnChange(
																{
																	target: { value: false },
																}
															)
														}
														disabled={areInvoiceSettingFieldsDisabled(
															true
														)}
													/>
												</Radio.Container>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='frequency'
												label='Frequency'
												id={data.id}
												valRes={invoicingFrequencyValRes}
												showValidation={invoicingFrequencyShowVal}
												colMd={3}>
												<Selects
													options={filterInvoicingFrequency(
														invoicingFrequencies,
														bookingPeriod?.value
													)}
													placeholder={`Invoicing Frequency${
														invoicingFrequencyValRes.isValid
															? ''
															: ' (Required)'
													}`}
													value={invoicingFrequency}
													onChange={e => {
														invoicingFrequencyOnChange(e);

														firstInvoiceOnChange({
															target: { value: null },
														});

														proratedFrequencyOnChange({
															target: { value: null },
														});

														coTermDateOnChange({
															target: { value: null },
														});

														nextInvoiceOnChange({
															target: {
																value: null,
															},
														});

														if (e.target.value) {
															if (
																e.target.value.value ===
																_invoicingFrequencies.SAME_AS_RESERVATION
															)
																paymentTermOnChange({
																	target: { value: null },
																});

															if (
																deferredIncomeFrequency &&
																isInvoicingFrequencyBigger(
																	deferredIncomeFrequency.value,
																	e.target.value.value
																)
															)
																// check deferred income frequency against to invoicing frequency.
																deferredIncomeFrequencyOnChange({
																	target: { value: null },
																});
														}
													}}
													onBlur={setInvoicingFrequencyShowVal}
													displayKey='value'
													disabled={areInvoiceSettingFieldsDisabled(true)}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='paymentTerm'
												label='Payment Term'
												id={data.id}
												colMd={3}>
												<Selects
													options={paymentTerms}
													placeholder='Payment Term'
													value={paymentTerm}
													onChange={paymentTermOnChange}
													displayKey='name'
													disabled={areInvoiceSettingFieldsDisabled()}
												/>
											</FormField>
										</Loading>
									</FormGroup>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='firstInvoice'
												label='First Invoice'
												id={data.id}
												valRes={firstInvoiceValRes}
												showValidation={firstInvoiceShowVal}
												colMd={3}>
												<Selects
													options={invoiceFirstOptions}
													placeholder={`First Invoice${
														firstInvoiceValRes.isValid
															? ''
															: ' (Required)'
													}`}
													value={firstInvoice}
													onChange={e => {
														firstInvoiceOnChange(e);

														nextInvoiceOnChange({
															target: {
																value: getNextInvoice(
																	invoicingFrequency,
																	e.target.value,
																	invoiceNextOptions
																),
															},
														});

														proratedFrequencyOnChange({
															target: { value: null },
														});

														coTermDateOnChange({
															target: { value: null },
														});
													}}
													onBlur={setFirstInvoiceShowVal}
													displayKey='value'
													disabled={
														areInvoiceSettingFieldsDisabled() ||
														!invoicingFrequency ||
														invoicingFrequency.value ===
															_invoicingFrequencies.UPFRONT ||
														invoicingFrequency.value ===
															_invoicingFrequencies.SAME_AS_RESERVATION
													}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='nextInvoice'
												label='Next Invoice'
												id={data.id}
												colMd={3}
												valRes={nextInvoiceValRes}
												showValidation={nextInvoiceShowVal}>
												<Selects
													options={getNextInvoiceOptions(
														bookingPeriod?.value,
														invoicingFrequency,
														firstInvoice,
														invoiceNextOptions
													)}
													placeholder={`Next Invoice${
														nextInvoiceValRes.isValid
															? ''
															: ' (Required)'
													}`}
													value={nextInvoice}
													onChange={e => {
														nextInvoiceOnChange(e);

														if (
															!e.target.value ||
															e.target.value.value !==
																nextInvoiceOptions.CO_TERM
														) {
															coTermDateOnChange({
																target: { value: null },
															});
														}
													}}
													displayKey='value'
													disabled={
														areInvoiceSettingFieldsDisabled() ||
														!firstInvoice
													}
													onBlur={setNextInvoiceShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='proratedFrequency'
												label='Prorated Frequency'
												id={data.id}
												colMd={3}
												showValidation={proratedFrequencyShowVal}
												valRes={proratedFrequencyValRes}>
												<Selects
													placeholder={`Prorated Frequency${
														proratedFrequencyValRes.isValid
															? ''
															: ' (Required)'
													}`}
													value={proratedFrequency}
													options={filterInvoicingFrequency(
														invoicingFrequencies,
														bookingPeriod?.value,
														false,
														invoicingFrequency?.value
													)}
													onChange={proratedFrequencyOnChange}
													displayKey='value'
													disabled={
														areInvoiceSettingFieldsDisabled() ||
														firstInvoice?.value !==
															firstInvoiceOptions.PRO_RATE
													}
													onBlur={setProratedFrequencyShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='coTermDate'
												label='Co-Term Date'
												id={data.id}
												colMd={3}
												valRes={coTermDateValRes}
												showValidation={coTermDateShowVal}>
												<DatePicker
													id='coTermDate'
													type='calendar'
													placeholder={`Co-Term Date${
														coTermDateValRes.isValid
															? ''
															: ' (Required)'
													}`}
													place='top'
													value={parseDatePickerValue(coTermDate)}
													onChange={e =>
														coTermDateOnChange({
															target: {
																value: parseDatePickerChange(
																	e.target.value,
																	coTermDate
																),
															},
														})
													}
													disabled={
														areInvoiceSettingFieldsDisabled() ||
														nextInvoice?.value !==
															nextInvoiceOptions.CO_TERM
													}
													formatter={dateFormatterWithoutYear}
													onBlur={setCoTermDateShowVal}
												/>
											</FormField>
										</Loading>
										<ExcludeDates
											items={excludeDates}
											isSubmitted={isSubmitted}
											onChange={items =>
												excludeDatesOnChange({ target: { value: items } })
											}
											onFormChange={onFormChange}
										/>
									</FormGroup>
								</Section.Body>
							</Section>
							<Section title='Deferred Income'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='enableDeferredIncome'
												label='Enable Deferred Income'
												id={data.id}
												colMd={3}>
												<Toggle
													spaceLess
													value={enableDeferredIncome}
													onChange={enableDeferredIncomeOnChange}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='deferredIncomeFrequency'
												label='Frequency'
												id={data.id}
												valRes={deferredIncomeFrequencyValRes}
												showValidation={deferredIncomeFrequencyShowVal}
												colMd={3}>
												<Selects
													options={
														bookingPeriod
															? filterInvoicingFrequency(
																	invoicingFrequencies,
																	bookingPeriod.value,
																	false,
																	invoicingFrequency
																		? invoicingFrequency.value
																		: null
															  )
															: []
													}
													placeholder={`Frequency${
														enableDeferredIncome ? ' (Required)' : ''
													}`}
													value={deferredIncomeFrequency}
													onChange={deferredIncomeFrequencyOnChange}
													onBlur={setDeferredIncomeFrequencyShowVal}
													displayKey='value'
													disabled={!enableDeferredIncome}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='deferredIncomeSalesAccount'
												label='Sales Income Account'
												id={data.id}
												valRes={deferredIncomeSalesAccountValRes}
												showValidation={deferredIncomeSalesAccountShowVal}
												colMd={3}>
												<Selects
													options={accounts}
													placeholder={`Sales Income Account${
														enableDeferredIncome ? ' (Required)' : ''
													}`}
													value={deferredIncomeSalesAccount}
													onChange={deferredIncomeSalesAccountOnChange}
													onBlur={setDeferredIncomeSalesAccountShowVal}
													disabled={!enableDeferredIncome}
												/>
											</FormField>
										</Loading>
									</FormGroup>
								</Section.Body>
							</Section>
						</div>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
ExtraChargePerUnitForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		productId: PropTypes.string,
		name: PropTypes.string,
		description: PropTypes.string,
		// eslint-disable-next-line react/require-default-props
		module: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		extraChargeType: PropTypes.object,
		bookingCalculation: PropTypes.object,
		isTax: PropTypes.bool,
	}),
	isLoading: PropTypes.bool,
	itemTypes: PropTypes.arrayOf(PropTypes.object),
	productCategoryExtraCharges: PropTypes.arrayOf(PropTypes.object),
	enumBookingPeriods: PropTypes.arrayOf(PropTypes.object),
	bookingTypeMarinas: PropTypes.arrayOf(PropTypes.object),
	enumBookingCalculations: PropTypes.arrayOf(PropTypes.object),
	enumExtraChargeTypes: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	taxAgencies: PropTypes.arrayOf(PropTypes.object),
	unitMeasures: PropTypes.arrayOf(PropTypes.object),
	accounts: PropTypes.arrayOf(PropTypes.object),
	taxRates: PropTypes.arrayOf(PropTypes.object),
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	invoiceFirstOptions: PropTypes.arrayOf(PropTypes.object),
	invoiceNextOptions: PropTypes.arrayOf(PropTypes.object),
	invoicingFrequencies: PropTypes.arrayOf(PropTypes.object),
	setTitle: PropTypes.func,
	onFormChange: PropTypes.func,
	isSubmitted: PropTypes.bool,
	setIsValid: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
ExtraChargePerUnitForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		description: '',
		extraChargeType: {},
	},
	itemTypes: [],
	enumBookingPeriods: [],
	bookingTypeMarinas: [],
	productCategoryExtraCharges: [],
	enumBookingCalculations: [],
	enumExtraChargeTypes: [],
	taxCodes: [],
	taxAgencies: [],
	unitMeasures: [],
	accounts: [],
	taxRates: [],
	paymentTerms: [],
	invoiceFirstOptions: [],
	invoiceNextOptions: [],
	invoicingFrequencies: [],
	isLoading: false,
	setTitle: () => {},
	onFormChange: () => {},
	isSubmitted: false,
	setIsValid: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default ExtraChargePerUnitForm;
