import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import {
	addErrorNotification,
	addSuccessNotification,
	dateFormatter,
	getCreditRemainingAmount,
	priceFormatter,
} from '../../../utils/helpers/helper';
import UserContext from '../../../app/contexts/UserContext';

import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import apiCall from '../../../utils/helpers/apiCall';

const CreditGiveRefundModal = ({ credit, onClose, afterSubmit }) => {
	const userContext = useContext(UserContext);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const onSubmit = () => {
		setIsSubmitting(true);

		apiCall(
			'POST',
			'creditGiveRefund',
			res => {
				addSuccessNotification('Refund successfully created.');
				afterSubmit(res);
			},
			err => {
				let errText = err.toString();

				if (err.toString().search('overrideCredit') > -1)
					errText = 'Credit have been changed since you opened it.';

				addErrorNotification(errText);

				setIsSubmitting(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				id: credit.id,
				timeModified: credit.timeModified,
				amount: getCreditRemainingAmount(credit),
			}
		);
	};

	return (
		<div className='sdms-quick-panel sdms-quick-panel--right sdms-quick-panel--on'>
			<Portlet key={credit.id} hasFrame fluid='fluid' className='sdms-marginless h-100'>
				<Portlet.Head>
					<Portlet.HeadLabelTitle portletIcon='Undo'>Give Refund</Portlet.HeadLabelTitle>
				</Portlet.Head>
				<Portlet.Body>
					<div className='row' style={{ paddingLeft: 15, paddingRight: 15 }}>
						<div className='col-md-12'>
							<div className='row sdms-fitText--md sdms-mb-10 '>
								<span>
									<strong>Customer:</strong> {credit.customer.displayName}
								</span>
							</div>
							<div className='row sdms-fitText--md sdms-mb-10 '>
								<span>
									<strong>Ref. No:</strong> {credit.remittanceId}
								</span>
							</div>
							<div className='row sdms-fitText--md sdms-mb-10 '>
								<span>
									<strong>Date:</strong>{' '}
									{dateFormatter(credit.timeCreated, false)}
								</span>
							</div>
							<div className='row sdms-fitText--md sdms-mb-10 '>
								<span>
									<strong>Refund Amount:</strong>{' '}
									{priceFormatter(getCreditRemainingAmount(credit))}
								</span>
							</div>
						</div>
					</div>
				</Portlet.Body>
				<Portlet.Foot className='sdms-align-left' tall='sm'>
					<div className='col'>
						<Button
							design='clean'
							text='Cancel'
							icon='Error-circle'
							size='sm'
							elevate
							onClick={onClose}
							disabled={isSubmitting}
						/>
					</div>
					<div className='col-auto'>
						<Button
							label='brand'
							icon='Write'
							text={isSubmitting ? 'Giving' : 'Give'}
							size='sm'
							onClick={onSubmit}
							disabled={isSubmitting}
						/>
					</div>
				</Portlet.Foot>
			</Portlet>
		</div>
	);
};

CreditGiveRefundModal.propTypes = {
	credit: PropTypes.object,
	onClose: PropTypes.func,
	afterSubmit: PropTypes.func,
};

CreditGiveRefundModal.defautltProps = {
	credit: null,
	onClose: () => {},
	afterSubmit: () => {},
};

export default CreditGiveRefundModal;
