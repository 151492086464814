import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import tinyColor from 'tinycolor2';
import PropTypes from 'prop-types';
import { MapInteractionCSS } from 'react-map-interaction';
import useComponentSize from '@rehooks/component-size';
import { getThemeColor, getSizeVal } from '../../../../../../utils/helpers/helper';
import { pathToUrl } from '../../../../../../utils/helpers/apiCall';
import Portlet from '../../../../layout/Portlet';
import { _defaultImageSvg } from '../../../../field/ImageUpload';
import SVGIcon from '../../../SVGIcon';
import useModal from '../../../../../../utils/hooks/useModal';
import ProductModal from '../../modals/ProductModal';

const MapItem = ({ unitMapUnit, usedSize, onUnitClick }) => {
	const getColor = () => (unitMapUnit.isAvailable ? 'success' : 'danger');

	const icon = `url('data:image/svg+xml;utf8,${ReactDOMServer.renderToString(
		<SVGIcon
			name={unitMapUnit.unit.icon ? unitMapUnit.unit.icon.value : 'Square'}
			preserveAspectRatio='none'
			fill={tinyColor(getThemeColor('sharper')[getColor()])
				.setAlpha(unitMapUnit.unit.opacity ? unitMapUnit.unit.opacity / 100 : 1)
				.toRgbString()}
		/>
	)}')`;

	const sizes = {
		x: (unitMapUnit.posX * usedSize.width) / 100,
		y: (unitMapUnit.posY * usedSize.height) / 100,
		width: (unitMapUnit.width * usedSize.width) / 20,
		height: (unitMapUnit.height * usedSize.width) / 20,
		rotation: unitMapUnit.rotation,
	};

	const TableMapItemInner = (
		<div
			id={`unitMapItem-${unitMapUnit.id}`}
			className='register-item__table register-item__table--draggable'
			style={{
				width: sizes.width,
				height: sizes.height,
				top: sizes.y,
				left: sizes.x,
				transform: `rotate(${sizes.rotation}deg)`,
				backgroundImage: `${icon}`,
				position: 'absolute',
				cursor: unitMapUnit.isAvailable ? 'pointer' : 'not-allowed',
				fontSize:
					usedSize.height >= usedSize.width
						? `${usedSize.width *
								0.01618 *
								(unitMapUnit.fontSize ? unitMapUnit.fontSize.size : 1)}px`
						: `${usedSize.height *
								0.01618 *
								(unitMapUnit.fontSize ? unitMapUnit.fontSize.size : 1)}px`,
			}}>
			{unitMapUnit.hideCaption
				? ''
				: unitMapUnit.unit.mapCaption
				? unitMapUnit.unit.mapCaption
				: unitMapUnit.unit.name}
		</div>
	);

	return (
		<div
			data-for={`unitMapUnit-${unitMapUnit.id}`}
			className='register-item__table--draggable-parent'
			data-event='touchstart focus mouseover'
			data-event-off='mouseout'
			globalEventOff='touchstart'
			onClick={() => {
				if (unitMapUnit.isAvailable) onUnitClick();
			}}
			onTouchEnd={() => {
				if (unitMapUnit.isAvailable) onUnitClick();
			}}
			style={{
				display: 'flex',
				position: 'absolute',
			}}
			role='presentation'>
			{TableMapItemInner}
		</div>
	);
};
MapItem.propTypes = {
	unitMapUnit: PropTypes.shape({
		id: PropTypes.number,
		unit: PropTypes.object,
		posX: PropTypes.number,
		posY: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number,
		rotation: PropTypes.number,
		fontSize: PropTypes.object,
		hideCaption: PropTypes.bool,
		isAvailable: PropTypes.bool,
	}).isRequired,
	usedSize: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}).isRequired,
	onUnitClick: PropTypes.func.isRequired,
};

const modals = {
	PRODUCT: 'product',
};

const SearchResultMapView = ({
	bookingType,
	productCategories,
	selectProduct,
	isLoading,
	outlet,
	isOnline,
	selectedCategory,
}) => {
	const [modal, openModal, closeModal] = useModal();

	const tableMapContainer = useRef();

	const tableMapContainerSize = useComponentSize(tableMapContainer);

	const [tableMapImageSize, setTableMapImageSize] = useState({});

	const tableMapImageRef = useRef(null);

	const usedSize = useMemo(() => getSizeVal(tableMapContainerSize, tableMapImageSize), [
		tableMapContainerSize,
		tableMapImageSize,
	]);

	const [mapSize, setMapSize] = useState({ scale: 1, translation: { x: 0, y: 0 } });

	const getProductOfUnit = useCallback(
		unitId => {
			let product = null;
			Object.values(productCategories).forEach(productCategory => {
				Object.values(productCategory.products).forEach(_product => {
					if (
						Object.values(_product.pricing).filter(
							_p => _p.failedConstraints.filter(fc => fc.length > 0).length === 0
						).length > 0
					) {
						const { availableUnits } = _product;
						if (availableUnits && availableUnits.indexOf(unitId) > -1)
							product = _product;
					}
				});
			});
			return product;
		},
		[productCategories]
	);

	const unitMapUnits = useMemo(() => {
		if (!bookingType || !bookingType.unitMap) return [];

		const _unitMapUnits = bookingType.unitMap.unitMapUnits
			.filter(umu => umu.unit.inactive !== true)
			.map(umu => {
				const product = getProductOfUnit(umu.unit.id);

				return {
					...umu,
					product,
					isAvailable: !!product,
				};
			});

		if (selectedCategory !== 'all') return _unitMapUnits.filter(umu => umu.product);

		return _unitMapUnits;
	}, [bookingType, getProductOfUnit, selectedCategory]);

	const getTranslation = value => {
		const translation = { x: 0, y: 0 };

		if (value.translation.x <= 0)
			translation.x =
				value.translation.x < usedSize.width * -1 * (value.scale - 1)
					? usedSize.width * -1 * (value.scale - 1)
					: value.translation.x;

		if (value.translation.y <= 0)
			translation.y =
				value.translation.y < usedSize.height * -1 * (value.scale - 1)
					? usedSize.height * -1 * (value.scale - 1)
					: value.translation.y;

		return translation;
	};

	useEffect(() => {
		if (usedSize.width < tableMapContainerSize.width)
			setMapSize({
				scale: tableMapContainerSize.width / usedSize.width,
				translation: { x: 0, y: 0 },
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usedSize]);

	return (
		<>
			<Portlet
				fluid='fluid'
				everyTimeFluid
				className='flex-grow-1 sdms-portlet--map-container'>
				<Portlet.Body
					ref={tableMapContainer}
					style={{ minHeight: 400 }}
					className='sdms-portlet__body--fit overflow-hidden'>
					<MapInteractionCSS
						showControls
						minScale={1}
						maxScale={10}
						value={mapSize}
						onChange={value =>
							setMapSize({
								scale: value.scale,
								translation: getTranslation(value),
							})
						}>
						<img
							alt='Table Map'
							src={
								bookingType?.unitMap && bookingType.unitMap.background
									? pathToUrl(bookingType.unitMap.background.path)
									: _defaultImageSvg
							}
							ref={tableMapImageRef}
							style={{ position: 'absolute', width: '100%' }}
							onLoad={() =>
								setTableMapImageSize({
									width: tableMapImageRef.current.naturalWidth,
									height: tableMapImageRef.current.naturalHeight,
								})
							}
						/>
						<div style={usedSize}>
							{unitMapUnits.map(umu => (
								<MapItem
									key={umu.unit.id}
									unitMapUnit={umu}
									usedSize={usedSize}
									onUnitClick={() => {
										openModal({
											open: modals.PRODUCT,
											product: umu.product,
											unit: umu.unit,
										});
									}}
								/>
							))}
						</div>
					</MapInteractionCSS>
				</Portlet.Body>
			</Portlet>
			{modal.open === modals.PRODUCT && modal.product && (
				<ProductModal
					onClose={closeModal}
					product={modal.product}
					isOpen={modal.open === modals.PRODUCT}
					unit={modal.unit}
					selectProduct={selectProduct}
					isLoading={isLoading}
					outlet={outlet}
					productCategories={productCategories}
					isOnline={isOnline}
				/>
			)}
		</>
	);
};

SearchResultMapView.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	bookingType: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	productCategories: PropTypes.object,
	isOnline: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	selectProduct: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	selectedCategory: PropTypes.string,
};

SearchResultMapView.defaultProps = {
	bookingType: null,
	productCategories: null,
	selectedCategory: 'all',
};

export default SearchResultMapView;
