import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Loading from '../../reusables/template/Loading';
import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Selects from '../../reusables/field/Selects';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import Toggle from '../../reusables/field/Toggle';
import UserContext from '../../../app/contexts/UserContext';
import { modules } from '../../../utils/helpers/apiCall';

const PowerMeterForm = ({
	data,
	isLoading,
	setTitle,
	onFormChange,
	isSubmitted,
	setIsValid,
	submitButtonAttr,
	submit,
	powerMeterTypes,
	campgroundUnits,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [serialNumber, serialNumberOnChange] = useField(data, 'serialNumber', onFormChange);

	const [
		powerMeterType,
		powerMeterTypeOnChange,
		powerMeterTypeValRes,
		powerMeterTypeShowVal,
		setPowerMeterTypeShowVal,
	] = useField(data, 'powerMeterType', onFormChange, [required], null);

	const [space, spaceOnChange, spaceValRes, spaceShowVal, setSpaceShowVal] = useField(
		data,
		'campgroundSpace',
		onFormChange,
		[required],
		null
	);

	const [inactive, inactiveOnChange] = useField(data, 'inactive', onFormChange, [], false);

	const [product, productOnChange, productValRes, productShowVal, setProductShowVal] = useField(
		data,
		'product',
		onFormChange,
		[required],
		null
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setPowerMeterTypeShowVal();
			setSpaceShowVal();
			setProductShowVal();
		}
	}, [isSubmitted, setNameShowVal, setPowerMeterTypeShowVal, setSpaceShowVal, setProductShowVal]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				powerMeterTypeValRes.isValid &&
				spaceValRes.isValid &&
				productValRes.isValid
		);
	}, [
		nameValRes.isValid,
		powerMeterTypeValRes.isValid,
		spaceValRes.isValid,
		productValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.campground.default.text,
				path: pages.campground.dashboard.path,
			},
			{
				title: pages.campground.settings.text,
				path: pages.campground.settings.path,
			},
			{
				title: pages.campground.settings.powerMeters.text,
				path: pages.campground.settings.powerMeters.path,
			},
			{ title: name || `New ${pages.campground.settings.powerMeters.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.campground.settings.powerMeters.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		if (!isLoading)
			data.module = userContext.data.user.company.modules.find(
				m => m.value === modules.CAMPGROUND
			);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [data.module, isLoading]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup row>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colLg={6}>
								<Input
									type='text'
									placeholder='Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='serialNumber'
								label='Serial Number'
								id={data.id}
								colLg={6}>
								<Input
									type='text'
									placeholder='Serial Number'
									value={serialNumber}
									onChange={serialNumberOnChange}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='powerMeterType'
								label='Power Meter Type'
								valRes={powerMeterTypeValRes}
								showValidation={powerMeterTypeShowVal}
								id={data.id}
								colLg={6}>
								<Selects
									options={powerMeterTypes}
									placeholder='Power Meter Type (Required)'
									value={powerMeterType}
									onChange={powerMeterTypeOnChange}
									onBlur={setPowerMeterTypeShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='campgroundSpace'
								label='Space'
								valRes={spaceValRes}
								showValidation={spaceShowVal}
								id={data.id}
								colLg={6}>
								<Selects
									options={campgroundUnits}
									placeholder='Space (Required)'
									value={space}
									onChange={spaceOnChange}
									onBlur={setSpaceShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='product'
								label='Product'
								id={data.id}
								valRes={productValRes}
								showValidation={productShowVal}
								colLg={6}>
								<AsyncSelect
									options={data.product ? [data.product] : []}
									placeholder='Search and select product'
									value={product}
									onChange={productOnChange}
									onBlur={setProductShowVal}
									route='products'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='inactive' label='Inactive' id={data.id} colLg={6}>
								<Toggle value={inactive} onChange={inactiveOnChange} />
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
PowerMeterForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		amperage: PropTypes.number,
		voltage: PropTypes.object,
		product: PropTypes.object,
		module: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setIsValid: PropTypes.func,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
	powerMeterTypes: PropTypes.arrayOf(PropTypes.object),
	campgroundUnits: PropTypes.arrayOf(PropTypes.object),
};
PowerMeterForm.defaultProps = {
	data: {
		id: 0,
		inactive: false,
	},
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
	powerMeterTypes: [],
	campgroundUnits: [],
};

export default PowerMeterForm;
