import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import UnitMeasureForm from './UnitMeasureForm';

const UnitMeasureEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='unitMeasures'
			fields={<UnitMeasureForm />}
			icon={pages.accounting.settings.unitMeasures.icon}
			pageTitle={pages.accounting.settings.unitMeasures.text}
		/>
	);
};

UnitMeasureEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default UnitMeasureEdit;
