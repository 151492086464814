import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { bookingPeriods } from '../../../../../utils/constants/constants';
import { numberFormat, priceFormatter } from '../../../../../utils/helpers/helper';
import {
	getItemTotal,
	getTaxTotal,
	getTotalIsTaxExtraCharges,
} from '../../../../../utils/helpers/basicReservationHelper';

import Portlet from '../../../layout/Portlet';
import ExtraChargeSummaryItem from './ExtraChargeSummaryItem';

const PricingSummaryItem = ({
	period,
	quantity,
	price,
	tax,
	extra,
	extraCharge,
	extraTax,
	selectedProduct,
	index,
	isMulti,
	headerHeight,
}) => {
	const intervalName = useMemo(() => {
		let name = '';
		if (period === bookingPeriods.HOURLY) name = 'Hour';
		else if (period === bookingPeriods.NIGHTLY) name = 'Night';
		else if (period === bookingPeriods.DAILY) name = 'Day';
		if (quantity > 1) name = name.concat('s');
		return name;
	}, [period, quantity]);

	if (quantity) {
		return (
			<>
				<Portlet className='sdms-portlet--solid-info flex-grow-0' stickyTop={headerHeight}>
					<Portlet.Head>
						<Portlet.HeadLabelTitle>
							{isMulti && `${index + 1}. `}
							{selectedProduct.name}
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<div className='row sdms-fitText--lg sdms-font-bolder'>
							<div className='col'>{`${quantity} ${intervalName}:`}</div>
							<div className='col-auto text-right'>{priceFormatter(price)}</div>
						</div>
						{extra.map((category, c) =>
							category.map((ext, e) => (
								<ExtraChargeSummaryItem
									key={`${c}-${e}`}
									charge={extraCharge[c][e]}
									extra={ext}
								/>
							))
						)}
						<div className='row'>
							<div className='col'>Taxes:</div>
							<div className='col-auto text-right'>
								{priceFormatter(
									getTaxTotal(tax, extraTax) +
										numberFormat(getTotalIsTaxExtraCharges(extra, extraCharge))
								)}
							</div>
						</div>
					</Portlet.Body>
					<Portlet.Foot className='sdms-fitText--xl sdms-font-bolder' tall='sm'>
						<div className='col'>Total</div>
						<div className='col-auto text-right'>
							{priceFormatter(getItemTotal(price, extraCharge, tax, extraTax))}
						</div>
					</Portlet.Foot>
				</Portlet>
			</>
		);
	}

	return null;
};

PricingSummaryItem.propTypes = {
	period: PropTypes.string.isRequired,
	quantity: PropTypes.number.isRequired,
	price: PropTypes.number.isRequired,
	tax: PropTypes.number.isRequired,
	extra: PropTypes.arrayOf(PropTypes.array).isRequired,
	extraCharge: PropTypes.arrayOf(PropTypes.array).isRequired,
	extraTax: PropTypes.arrayOf(PropTypes.array).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	selectedProduct: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	isMulti: PropTypes.bool.isRequired,
	headerHeight: PropTypes.number.isRequired,
};

export default PricingSummaryItem;
