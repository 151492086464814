import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { paymentTypes as _paymentTypes } from '../../../../utils/constants/constants';
import Paid from './Paid';
import NumPad from './NumPad';

const Wire = ({ amount, payment, setPaymentData, balanceDue, disable }) => {
	useEffect(() => {
		setPaymentData({
			amount: '',
			type: _paymentTypes.WIRE,
			holdForSignature: false,
			serviceFee: 0,
			changeDue: 0,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Paid amount={balanceDue || amount} paid={payment.amount} amountInputFocus />
			<NumPad
				value={payment.amount}
				setValue={value => {
					setPaymentData({
						amount: value,
					});
				}}
				initValue={balanceDue || amount}
				max={amount}
				disable={disable}
				disableCloseNumpad
			/>
		</>
	);
};
Wire.propTypes = {
	amount: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	payment: PropTypes.object.isRequired,
	setPaymentData: PropTypes.func.isRequired,
	balanceDue: PropTypes.number.isRequired,
	disable: PropTypes.bool,
};

export default Wire;
