import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import SVGIcon from '../element/SVGIcon';
import { pageTransition, pageVariants } from '../../../utils/framer';
import { noPermissionStatus } from '../../../utils/helpers/helper';
import Loading from '../template/Loading';

const HeadToolbar = ({ children, className }) => (
	<div className={classNames('sdms-portlet__head-toolbar', className)}>{children}</div>
);
HeadToolbar.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.string,
	]).isRequired,
	className: PropTypes.string,
};
HeadToolbar.defaultProps = {
	className: null,
};

const HeadActions = ({ children, className }) => (
	<div className={classNames('sdms-portlet__head-actions', className)}>{children}</div>
);
HeadActions.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.string,
	]).isRequired,
	className: PropTypes.string,
};
HeadActions.defaultProps = {
	className: null,
};

const HeadToolbarActions = ({ children, toolbarClassName, actionsClassName }) => (
	<HeadToolbar className={toolbarClassName}>
		<HeadActions className={actionsClassName}>{children}</HeadActions>
	</HeadToolbar>
);
HeadToolbarActions.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.string,
	]).isRequired,
	toolbarClassName: PropTypes.string,
	actionsClassName: PropTypes.string,
};
HeadToolbarActions.defaultProps = {
	toolbarClassName: null,
	actionsClassName: null,
};

const Head = React.forwardRef(
	(
		{
			actions,
			children,
			className,
			portletIcon,
			smallTitle,
			title,
			noPermission,
			style,
			stickyTop,
			wrapMaxSize,
		},
		ref
	) => (
		<div
			ref={ref}
			className={classNames(
				'sdms-portlet__head',
				{ 'sdms-portlet__head--sticky': stickyTop !== null },
				{ [`sdms-portlet__head--wrap-${wrapMaxSize}`]: wrapMaxSize },
				noPermissionStatus(noPermission),
				className
			)}
			style={{ ...style, top: stickyTop !== null && stickyTop }}>
			{children ? (
				<>{children}</>
			) : (
				<>
					<div className='sdms-portlet__head-label'>
						{portletIcon ? ( // Portlet Icon
							<span className='sdms-portlet__head-icon'>
								<SVGIcon name={portletIcon} />
							</span>
						) : null}

						{title ? ( // Portlet Title
							<h3 className='sdms-portlet__head-title'>
								{title}
								{smallTitle ? <small>{smallTitle}</small> : null}
							</h3>
						) : null}
					</div>
					{actions ? ( // Portlet Actions
						<div className='sdms-portlet__head-toolbar'>
							<div className='sdms-portlet__head-actions'>{actions}</div>
						</div>
					) : null}
				</>
			)}
		</div>
	)
);
Head.propTypes = {
	actions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	className: PropTypes.string,
	portletIcon: PropTypes.string,
	smallTitle: PropTypes.string,
	title: PropTypes.string,
	noPermission: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	style: PropTypes.object,
	stickyTop: PropTypes.number,
	wrapMaxSize: PropTypes.oneOf([null, 'sm', 'md', 'lg', 'xl']),
};
Head.defaultProps = {
	actions: null,
	className: null,
	portletIcon: null,
	smallTitle: null,
	title: null,
	children: null,
	noPermission: null,
	style: null,
	stickyTop: null,
	wrapMaxSize: null,
};

const HeadLabel = ({ children, portletIcon, iconFill, className }) => (
	<div className={classNames('sdms-portlet__head-label', className)}>
		{portletIcon ? ( // Portlet Icon
			<span className='sdms-portlet__head-icon'>
				<SVGIcon name={portletIcon} fill={iconFill || '#000'} />
			</span>
		) : null}
		{children}
	</div>
);
HeadLabel.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	portletIcon: PropTypes.string,
	iconFill: PropTypes.string,
	className: PropTypes.string,
};
HeadLabel.defaultProps = {
	portletIcon: null,
	iconFill: null,
	className: null,
};

const HeadLabelTitle = ({ children, portletIcon, iconFill, smallTitle }) => (
	<HeadLabel portletIcon={portletIcon} iconFill={iconFill}>
		<h3 className='sdms-portlet__head-title'>
			{children}
			{smallTitle ? <small>{smallTitle}</small> : null}
		</h3>
	</HeadLabel>
);
HeadLabelTitle.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	portletIcon: PropTypes.string,
	iconFill: PropTypes.string,
	smallTitle: PropTypes.string,
};
HeadLabelTitle.defaultProps = {
	portletIcon: null,
	iconFill: null,
	smallTitle: null,
};

const Body = React.forwardRef(
	(
		{
			background,
			children,
			id,
			className,
			style,
			ariaLabel,
			role,
			noPermission,
			onScroll,
			noPadding,
		},
		ref
	) => {
		const portletBodyClass = classNames(
			'sdms-portlet__body',
			{ 'sdms-portlet__body--no-padding': noPadding },
			noPermissionStatus(noPermission),
			className
		);

		if (background) {
			return (
				<div
					ref={ref}
					id={id}
					className={portletBodyClass}
					aria-label={ariaLabel}
					role={role}
					style={{
						backgroundImage: `${background}`,
						...style,
					}}
					onScroll={onScroll}>
					{children}
				</div>
			);
		}
		return (
			<div
				ref={ref}
				id={id}
				className={portletBodyClass}
				aria-label={ariaLabel}
				role={role}
				style={style}
				onScroll={onScroll}>
				{children}
			</div>
		);
	}
);
Body.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	id: PropTypes.string,
	className: PropTypes.string,
	background: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	style: PropTypes.object,
	ariaLabel: PropTypes.string,
	role: PropTypes.string,
	noPermission: PropTypes.bool,
	onScroll: PropTypes.func,
	noPadding: PropTypes.bool,
};
Body.defaultProps = {
	id: null,
	className: null,
	background: null,
	style: null,
	ariaLabel: null,
	role: null,
	noPermission: null,
	onScroll: () => {},
	noPadding: false,
};

const Foot = React.forwardRef(({ border, children, className, subClassName, tall, type }, ref) => {
	const portletFootClass = classNames(
		!border ? 'sdms-portlet__foot--no-border' : '',
		'sdms-portlet__foot',
		className,
		tall ? `sdms-portlet__foot--${tall}` : ''
	);
	return (
		<div className={portletFootClass} ref={ref}>
			<div
				className={classNames(
					{ row: type !== 'form' },
					{ 'align-items-center': type !== 'form' },
					{ 'sdms-form__actions': type === 'form' },
					{ 'sdms-form__actions--right': type === 'form' },
					subClassName
				)}>
				{children}
			</div>
		</div>
	);
});
Foot.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.string,
	]).isRequired,
	className: PropTypes.string,
	subClassName: PropTypes.string,
	border: PropTypes.bool,
	tall: PropTypes.oneOf(['xs', 'sm', 'md']),
	type: PropTypes.oneOf(['default', 'form']),
};
Foot.defaultProps = {
	border: true,
	className: null,
	subClassName: null,
	tall: null,
	type: 'default',
};

const Separator = ({ className }) => {
	return <div className={classNames('sdms-portlet--separator', className)} />;
};
Separator.propTypes = {
	className: PropTypes.string,
};
Separator.defaultProps = {
	className: null,
};

// eslint-disable-next-line no-unused-vars
const TabItem = ({ title, icon }) => {
	throw new Error(
		`Title ${title}  component should be used as a child in the component Portlet Tabs.`
	);
};
TabItem.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.string,
};
TabItem.defaultProps = {
	icon: null,
};

const Tabs = ({ children, id, design, activeTab, noPermission, onTabChange }) => {
	const [active, setActive] = useState(activeTab);

	useEffect(() => setActive(activeTab), [activeTab]);

	useEffect(() => {
		onTabChange(active);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active]);
	return (
		<>
			<Head>
				<HeadToolbar>
					<ul
						className={classNames('nav nav-tabs nav-tabs-line', {
							[`nav-tabs-line-${design}`]: design,
						})}
						role='tablist'>
						{React.Children.map(children, (child, index) => (
							<li
								className={classNames('nav-item', {
									disabled: child.props.isDisabled,
								})}
								aria-hidden='true'
								aria-controls={`${id}--tab_${index}`}
								onClick={() => (!child.props.isDisabled ? setActive(index) : null)}>
								<div
									className={classNames(
										'nav-link',
										{ active: index === active },
										noPermissionStatus(noPermission)
									)}
									aria-selected={index === active ? 'true' : 'false'}>
									{child.props.icon && <SVGIcon name={child.props.icon} />}
									{child.props.title}
								</div>
							</li>
						))}
					</ul>
				</HeadToolbar>
			</Head>
			{React.Children.map(children, (child, index) => (
				<>
					{index === active && (
						<Body
							id={`${id}--tab_${index}`}
							ariaLabel={child.props.title}
							role='tabpanel'>
							{child.props.children}
						</Body>
					)}
				</>
			))}
		</>
	);
};
Tabs.propTypes = {
	children: PropTypes.node.isRequired,
	id: PropTypes.string.isRequired,
	design: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'info',
		'light',
		'primary',
		'secondary',
		'success',
		'warning',
	]),
	activeTab: PropTypes.number,
	noPermission: PropTypes.bool,
	onTabChange: PropTypes.func,
};
Tabs.defaultProps = {
	design: 'info',
	activeTab: 0,
	noPermission: null,
	onTabChange: () => {},
};

const Placeholder = () => {
	return (
		<Portlet fluid='fluid' hasFrame>
			<Head>
				<HeadLabelTitle portletIcon='Layers'>
					<Loading isLoading type='div'>
						<div>Loading</div>
					</Loading>
				</HeadLabelTitle>
			</Head>
			<Body>
				<div style={{ fontSize: 0 }}>Loading</div>
			</Body>
		</Portlet>
	);
};

const Portlet = React.forwardRef(
	(
		{
			className,
			border,
			children,
			id,
			fluid,
			everyTimeFluid,
			isLast,
			style,
			isCollapse,
			isOpen,
			isElevate,
			hasFrame,
			onClick,
			noPermission,
			overlay,
			stickyTop,
			stickyBottom,
		},
		ref
	) => {
		const portletClass = classNames(
			'sdms-portlet',
			className,
			{ 'sdms-portlet--bordered': border },
			{ 'sdms-portlet--last': isLast },
			{ [`sdms-portlet--height-${fluid}`]: fluid },
			{ 'sdms-portlet--height-fluid--everytime': everyTimeFluid },
			{ 'sdms-portlet--collapsed': isCollapse && !isOpen },
			{ 'sdms-portlet--unelevate': !isElevate },
			{ 'sdms-cursor--pointer': onClick },
			{ 'position-sticky': stickyTop !== null || stickyBottom !== null },
			noPermissionStatus(noPermission)
		);

		const _Overlay = overlay && <div className='sdms-portlet--overlay'>{overlay}</div>;

		const _top = stickyTop !== null ? stickyTop : style && style.top;
		const _bottom = stickyBottom !== null ? stickyBottom : style && style.bottom;

		if (hasFrame) {
			return (
				<motion.div
					id={id}
					ref={ref}
					style={{
						...style,
						top: _top,
						bottom: _bottom,
					}}
					className={portletClass}
					initial='initial'
					animate='in'
					exit='out'
					variants={pageVariants}
					transition={pageTransition}
					onClick={noPermission ? null : onClick}>
					{_Overlay}
					{children}
				</motion.div>
			);
		}
		return (
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
			<div
				role={onClick ? 'presentation' : null}
				id={id}
				ref={ref}
				style={{
					...style,
					top: _top,
					bottom: _bottom,
				}}
				className={portletClass}
				onClick={noPermission ? null : onClick}>
				{_Overlay}
				{children}
			</div>
		);
	}
);
Portlet.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	border: PropTypes.bool,
	className: PropTypes.string,
	id: PropTypes.string,
	fluid: PropTypes.oneOf(['fluid', 'fluid-half']),
	everyTimeFluid: PropTypes.bool,
	isLast: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	style: PropTypes.object,
	isCollapse: PropTypes.bool,
	isOpen: PropTypes.bool,
	isElevate: PropTypes.bool,
	hasFrame: PropTypes.bool,
	onClick: PropTypes.func,
	noPermission: PropTypes.bool,
	overlay: PropTypes.node,
	stickyTop: PropTypes.number,
	stickyBottom: PropTypes.number,
};
Portlet.defaultProps = {
	border: false,
	className: null,
	id: null,
	fluid: null,
	everyTimeFluid: false,
	isLast: false,
	style: null,
	isCollapse: false,
	isOpen: false,
	isElevate: true,
	hasFrame: false,
	onClick: null,
	noPermission: null,
	overlay: null,
	stickyTop: null,
	stickyBottom: null,
};

Portlet.HeadToolbar = HeadToolbar;
Portlet.HeadActions = HeadActions;
Portlet.HeadToolbarActions = HeadToolbarActions;
Portlet.Head = Head;
Portlet.HeadLabel = HeadLabel;
Portlet.HeadLabelTitle = HeadLabelTitle;
Portlet.Separator = Separator;
Portlet.Body = Body;
Portlet.Foot = Foot;
Portlet.Tabs = Tabs;
Portlet.TabItem = TabItem;
Portlet.Placeholder = Placeholder;

export default Portlet;
