import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../../app/contexts/UserContext';
import {
	addFloats,
	canVoidRefund,
	dateFormatter,
	getSettlementAmountWithoutFee,
	priceFormatter,
	subFloats,
} from '../../../../utils/helpers/helper';
import { canVoidPayment } from '../../../../utils/helpers/paymentHelper';
import { PayButton } from '../../element/Order';
import {
	getDepositDetails,
	getReservationSettlements,
	getReservationTotals,
	getReservationTotalsByInvoices,
	hasReservationInvoice,
} from '../../../../utils/helpers/reservationHelper';
import { paymentStatuses } from '../../../../utils/constants/constants';
import {
	hasEditLockedReservationPermission,
	hasRefundReservationPaymentPermission,
	hasVoidReservationPaymentPermission,
} from '../../../../utils/helpers/permission';
import { getReservationItemManualJournalLines } from '../../../../utils/helpers/journalHelper';

import SVGIcon from '../../element/SVGIcon';
import Portlet from '../../layout/Portlet';
import Loading from '../../template/Loading';
import SummarySection from './elements/SummarySection';
import PaymentItem from './elements/PaymentItem';
import Badge from '../../element/Badge';
import RefundItem from './elements/RefundItem';
import RetainItem from './elements/RetainItem';
import PriceAdjustmentItem from './elements/PriceAdjusmentItem';

const Summary = ({
	reservation,
	taxRate,
	isLoading,
	disabled,
	onPay,
	onDepositPay,
	onRefund,
	onRetain,
	onReceiptPrint,
	onReceiptEmail,
	onPaymentRefund,
	onScheduledPaymentCapture,
	onPaymentVoid,
	onAuthorizedPaymentEdit,
	onAuthorizedPaymentCapture,
	onAuthorizedPaymentVoid,
	onRefundVoid,
	onExtraChargeRemove,
	onDepositView,
	viewingDepositScreen,
	onAutoRefund,
}) => {
	const userContext = useContext(UserContext);

	const totals = useMemo(() => {
		const reservationTotals = getReservationTotals(reservation, taxRate, true);

		// if reservation has no invoice use totals by reservation.
		if (!hasReservationInvoice(reservation)) return reservationTotals;

		// if reservation has no invoice but has incidental charge sum reservation and incidental chargers totals.
		if (
			!hasReservationInvoice(reservation, 'reservation') &&
			hasReservationInvoice(reservation, 'other')
		) {
			const incidentalChargesTotals = getReservationTotalsByInvoices(reservation, true);

			Object.keys(reservationTotals).forEach(key => {
				if (incidentalChargesTotals[key])
					reservationTotals[key] += incidentalChargesTotals[key];
			});

			return reservationTotals;
		}

		const invoiceTotals = getReservationTotals(reservation, taxRate);

		// if reservation has invoice return sum of invoices with paid deposit amount
		return {
			...invoiceTotals,
			total: addFloats(invoiceTotals.total, reservationTotals.paidDepositAmount),
			paidDepositAmount: reservationTotals.paidDepositAmount,
			remainingDepositAmount: reservationTotals.remainingDepositAmount,
		};
	}, [reservation, taxRate]);

	const payments = useMemo(() => {
		// eslint-disable-next-line no-shadow
		const _payments = {};

		getReservationSettlements(reservation)
			.filter(settlement => !settlement.isRetain)
			.forEach(settlement => {
				const amount = getSettlementAmountWithoutFee(settlement);

				if (settlement.remittance?.status?.value !== paymentStatuses.DECLINED) {
					if (!_payments[settlement.remittance.id]) {
						_payments[settlement.remittance.id] = {
							...settlement.remittance,
							paymentMethod: settlement.remittance.paymentMethod || {
								name: 'Credit',
								paymentType: { value: 'Credit' },
							},
							originalAmount: settlement.remittance.amount,
							amount,
							amountWithFee: settlement.isDeposit ? amount : settlement.amount,
							refundableAmount: amount,
							isEditable: settlement.remittance.paymentMethod,
							isDeposit: settlement.isDeposit,
							remittance: settlement.remittance,
							inPayments: true,
							settlement,
							transaction: 'Payment',
						};
					} else {
						_payments[settlement.remittance.id].amount += amount;
						_payments[settlement.remittance.id].amountWithFee += settlement.isDeposit
							? amount
							: settlement.amount;
						_payments[settlement.remittance.id].refundableAmount += amount;
					}
				}
			});

		if (reservation.prePayments)
			reservation.prePayments.forEach(payment => {
				const amount = subFloats(payment.amount, payment.serviceFee || 0);
				if (!_payments[payment.id]) {
					_payments[payment.id] = {
						...payment,
						originalAmount: payment.amount,
						amount: subFloats(payment.amount, payment.serviceFee || 0),
						amountWithFee: amount,
						refundableAmount: amount,
						isEditable: payment.paymentMethod,
						isDeposit: false,
						remittance: payment,
						inPayments: true,
						settlement: null,
						transaction: 'Payment',
					};
				}
			});

		// add refund to amount.
		Object.entries(_payments).forEach(paymentEntry => {
			const [paymentId, payment] = [...paymentEntry];

			(payment.customerSettlements || []).forEach(cs => {
				if (cs.refund || cs.isRetain || cs.credit || cs.journal) {
					const refundAmount = getSettlementAmountWithoutFee({
						...cs,
						remittance: payment.remittance,
					});
					_payments[paymentId].amount += refundAmount;
					_payments[paymentId].amountWithFee += refundAmount;
				}
			});
		});

		if (reservation.authorizedPayment) {
			_payments[reservation.authorizedPayment.id] = {
				...reservation.authorizedPayment,
				isAuthorizedPayment: true,
				amountWithFee: reservation.authorizedPayment.amount,
				refundableAmount: 0,
				inPayments: true,
			};
		}

		const canEditReservation =
			!reservation.isLocked || hasEditLockedReservationPermission(userContext);

		Object.entries(_payments).forEach(paymentEntry => {
			const [paymentId, payment] = [...paymentEntry];

			_payments[paymentId].isRefundable =
				payment.refundableAmount > 0 &&
				payment?.status?.value !== paymentStatuses.SCHEDULED &&
				canEditReservation &&
				payment.isEditable &&
				hasRefundReservationPaymentPermission(userContext) &&
				payment.paymentMethod.name !== 'Journal';

			_payments[paymentId].isVoidable =
				canEditReservation &&
				hasVoidReservationPaymentPermission(userContext) &&
				canVoidPayment(payment) &&
				payment.paymentMethod.name !== 'Journal';
		});

		return Object.values(_payments);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totals]);

	const refunds = useMemo(() => {
		const _refunds = [];

		const journalSettlements = [];

		getReservationSettlements(reservation).forEach(ds => {
			(ds?.remittance?.customerSettlements || []).forEach(rs => {
				if (rs.refund && _refunds.findIndex(r => r.id === rs.refund.id) === -1) {
					const amount = getSettlementAmountWithoutFee({
						...rs,
						remittance: ds.remittance,
					});

					if (amount)
						_refunds.push({
							...rs.refund,
							amount,
							status: {
								value: paymentStatuses.SETTLED,
							},
							isVoidable: canVoidRefund(rs.refund),
							customer: ds.remittance.customer,
							isDeposit: ds.isDeposit,
							isRefund: true,
							isCredit: false,
							settlement: ds,
							transaction: 'Refund',
						});
				} else if (
					!rs.refund &&
					rs.credit &&
					_refunds.findIndex(r => r.id === rs.credit.id) === -1
				) {
					_refunds.push({
						...rs.credit,
						paymentMethod: {
							name: 'Credit',
							paymentType: { value: 'Credit' },
						},
						status: {
							value: paymentStatuses.SETTLED,
						},
						isVoidable: false,
						customer: ds.remittance.customer,
						isDeposit: ds.isDeposit,
						isRefund: false,
						isCredit: true,
						settlement: ds,
						transaction: 'Refund',
					});
				} else if (rs.journal && journalSettlements.indexOf(rs.id) === -1) {
					const journalIndex = _refunds.findIndex(r => r.id === rs.journal.id);

					journalSettlements.push(rs.id);

					if (journalIndex > -1)
						_refunds[journalIndex].amount = addFloats(
							_refunds[journalIndex].amount,
							rs.amount
						);
					else
						_refunds.push({
							...rs.journal,
							amount: rs.amount,
							paymentMethod: {
								name: 'Journal',
								paymentType: { value: 'Journal' },
							},
							status: {
								value: paymentStatuses.SETTLED,
							},
							isVoidable: false,
							customer: ds.remittance.customer,
							isDeposit: ds.isDeposit,
							isRefund: true,
							isCredit: false,
							settlement: ds,
							transaction: 'Journal',
						});
				}
			});
		});

		reservation.items.forEach(item => {
			getReservationItemManualJournalLines(item)
				.filter(journalLine => journalLine.isDebit)
				.forEach(journalLine => {
					const { journal, amount } = journalLine;

					const journalIndex = _refunds.findIndex(r => r.id === journal.id);

					if (journalIndex > -1)
						_refunds[journalIndex].amount = addFloats(
							_refunds[journalIndex].amount,
							amount
						);
					else
						_refunds.push({
							...journal,
							amount,
							paymentMethod: {
								name: 'Journal',
								paymentType: { value: 'Journal' },
							},
							status: {
								value: paymentStatuses.SETTLED,
							},
							isVoidable: false,
							customer: null,
							isDeposit: true,
							isRefund: true,
							isCredit: false,
							settlement: null,
							transaction: 'Journal',
						});
				});
		});

		return _refunds.sort((a, b) => a.id - b.id);
	}, [reservation]);

	const retains = useMemo(() => {
		const _retains = [];

		getReservationSettlements(reservation)
			.filter(settlement => settlement.isRetain)
			.forEach(settlement => {
				if (settlement.reservationItem) {
					const retainIndex = _retains.findIndex(
						r =>
							r.reservationItem &&
							r.reservationItem.id === settlement.reservationItem.id
					);

					if (retainIndex === -1)
						_retains.push({
							amount: settlement.amount,
							date: settlement.timeCreated,
							info: `${settlement.reservationItem.product.name} - ${settlement.reservationItem.unit.name}`,
							invoice: null,
							reservationItem: settlement.reservationItem,
							isDeposit: settlement.isDeposit,
							settlement,
							transaction: 'Retain',
						});
					else {
						_retains[retainIndex].amount += settlement.amount;
					}
				} else {
					const retainIndex = _retains.findIndex(
						r => r.invoice && r.invoice.id === settlement.invoice.id
					);

					if (retainIndex === -1)
						_retains.push({
							amount: settlement.amount,
							info: 'Retain',
							date: settlement.timeCreated,
							reservationItem: null,
							invoice: settlement.invoice,
							isDeposit: settlement.isDeposit,
							settlement,
							transaction: 'Retain',
						});
					else {
						_retains[retainIndex].amount += settlement.amount;
					}
				}
			});

		return _retains;
	}, [reservation]);

	const depositDetails = useMemo(
		() => ({
			...getDepositDetails(reservation),
			payments: payments.filter(item => item.isDeposit),
			refunds: refunds.filter(item => item.isDeposit && item.isRefund),
			retains: retains.filter(item => item.isDeposit),
			creditMemos: refunds.filter(item => item.isDeposit && item.isCredit),
		}),
		[reservation, payments, refunds, retains]
	);

	const depositPrice = useMemo(() => {
		if (!reservation.items.some(i => i.id !== 0)) return null;

		if (depositDetails.remaining < 0)
			return (
				<span className='sdms-font-success'>
					{priceFormatter(Math.abs(depositDetails.remaining))} Credit
				</span>
			);

		if (depositDetails.applicable === 0) {
			if (depositDetails.required > 0 && depositDetails.remaining <= 0)
				return (
					<Badge
						design='success'
						isInline
						isUnified
						fontWeight='bold'
						size='lg'
						className='sdms-text-overflow'>
						<span className='sdms-text-overflow'>Paid</span>
					</Badge>
				);

			return (
				<Badge
					design='secondary'
					isInline
					isUnified
					fontWeight='bold'
					size='lg'
					className='sdms-text-overflow'>
					<span className='sdms-text-overflow'>N/A</span>
				</Badge>
			);
		}

		if (depositDetails.remaining > 0)
			return (
				<span className='sdms-font-danger'>
					{priceFormatter(depositDetails.remaining)} Due
				</span>
			);

		return (
			<Badge
				design='success'
				isInline
				isUnified
				fontWeight='bold'
				size='lg'
				className='sdms-text-overflow'>
				<span className='sdms-text-overflow'>Paid</span>
			</Badge>
		);
	}, [reservation, depositDetails]);

	const refundableAmount = useMemo(
		() =>
			payments
				.filter(p => p.isRefundable)
				.reduce((partialSum, p) => partialSum + p.refundableAmount, 0),
		[payments]
	);

	useEffect(() => {
		if (viewingDepositScreen) onDepositView(depositDetails);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [depositDetails]);

	return (
		<Portlet fluid='fluid'>
			<Portlet.Head className='row sdms-row-align-items'>
				<div className='col-4 sdms-align-left sdms-mt-10'>
					<span className='sdms-font-size-1-point-5-rem sdms-font-bolder'>Total</span>
				</div>
				<div className='col-8 sdms-mt-10 sdms-align-right'>
					<Loading isLoading={isLoading} type='span'>
						<span className='sdms-font-size-1-point-5-rem sdms-font-bolder'>
							{priceFormatter(totals.total)}
						</span>
					</Loading>
				</div>
				<div className='col-12 sdms-align-right sdms-mb-10'>
					<span className='sdms-font-size-0-point-8-rem'>
						incl. taxes, extras & other charges
					</span>
				</div>
			</Portlet.Head>
			<Portlet.Body className='sdms-pt0'>
				<SummarySection
					title='Payments'
					price={priceFormatter(
						payments
							.filter(payment => payment.inPayments && !payment.isAuthorizedPayment)
							.reduce((partialSum, payment) => partialSum + payment.amountWithFee, 0)
					)}
					isLoading={isLoading}>
					<table className='table sdms-t-table sdms-mb-10 sdms-mt-10'>
						{payments
							.filter(payment => payment.inPayments)
							.map(payment => (
								<PaymentItem
									key={payment.id}
									payment={payment}
									isLoading={isLoading}
									onPrint={() => onReceiptPrint(payment)}
									onEmail={() => onReceiptEmail(payment)}
									onRefund={
										payment.isRefundable
											? () =>
													onPaymentRefund(
														payment,
														payment.refundableAmount
													)
											: null
									}
									onScheduledPaymentCapture={
										payment?.status?.value === paymentStatuses.SCHEDULED
											? () => onScheduledPaymentCapture(payment)
											: null
									}
									onVoid={
										payment.isVoidable ? () => onPaymentVoid(payment) : null
									}
									onAuthorizedPaymentEdit={
										payment.isAuthorizedPayment
											? () => onAuthorizedPaymentEdit(payment)
											: null
									}
									onAuthorizedPaymentCapture={
										payment.isAuthorizedPayment
											? () => onAuthorizedPaymentCapture(payment)
											: null
									}
									onAuthorizedPaymentVoid={
										payment.isAuthorizedPayment
											? () => onAuthorizedPaymentVoid(payment)
											: null
									}
								/>
							))}
					</table>
				</SummarySection>
				<SummarySection
					title='Refunds'
					price={priceFormatter(
						refunds.reduce((partialSum, refund) => partialSum + refund.amount, 0)
					)}
					isLoading={isLoading}
					customButton={{
						visible: refundableAmount > 0,
						disabled: disabled || isLoading,
						onClick: e => {
							e.preventDefault();
							e.stopPropagation();
							onAutoRefund(refundableAmount);
						},
						icon: 'Plus',
					}}>
					<table className='table sdms-t-table sdms-mb-10 sdms-mt-10'>
						{refunds.map(refund => (
							<RefundItem
								refund={refund}
								isLoading={isLoading}
								onPrint={() => onReceiptPrint([refund], true)}
								onVoid={refund.isVoidable ? () => onRefundVoid(refund) : null}
							/>
						))}
					</table>
				</SummarySection>
				{retains.length > 0 && (
					<SummarySection
						title='Retains'
						price={priceFormatter(
							retains.reduce((partialSum, retain) => partialSum + retain.amount, 0)
						)}
						isLoading={isLoading}>
						<table className='table sdms-mb-10 sdms-mt-10'>
							{retains.map(retain => (
								<RetainItem retain={retain} />
							))}
						</table>
					</SummarySection>
				)}
				<SummarySection
					title='Deposit Detail'
					price={depositPrice}
					isLoading={isLoading}
					customButton={{
						visible: reservation.id !== 0,
						disabled: disabled || isLoading,
						onClick: e => {
							e.preventDefault();
							e.stopPropagation();
							onDepositView(depositDetails);
						},
						icon: 'Export',
					}}>
					<div className='sdms-summary-deposit-detail'>
						<div className='row sdms-mt-10'>
							<div className='col-8 sdms-font-bolder'>Required:</div>
							<div className='col-4 sdms-font-bolder sdms-align-right'>
								{priceFormatter(depositDetails.required)}
							</div>
						</div>
						<div className='row sdms-mt-10'>
							<div className='col-8 sdms-font-bolder'>Payments:</div>
							<div className='col-4 sdms-font-bolder sdms-align-right'>
								{priceFormatter(depositDetails.paid)}
							</div>
						</div>
						{depositDetails.paid > 0 && (
							<>
								{depositDetails.applied > 0 && (
									<div className='row sdms-mt-10'>
										<div className='col-8 sdms-font-bolder'>
											Applied To Invoice:
										</div>
										<div className='col-4 sdms-font-bolder sdms-align-right'>
											({priceFormatter(depositDetails.applied)})
										</div>
									</div>
								)}
								{depositDetails.refunded > 0 && (
									<div className='row sdms-mt-10'>
										<div className='col-8 sdms-font-bolder'>Refunded:</div>
										<div className='col-4 sdms-font-bolder sdms-align-right'>
											({priceFormatter(depositDetails.refunded)})
										</div>
									</div>
								)}
								{depositDetails.retained > 0 && (
									<div className='row sdms-mt-10'>
										<div className='col-8 sdms-font-bolder'>Retained:</div>
										<div className='col-4 sdms-font-bolder sdms-align-right'>
											({priceFormatter(depositDetails.retained)})
										</div>
									</div>
								)}
								{depositDetails.credit > 0 && (
									<div className='row sdms-mt-10'>
										<div className='col-8 sdms-font-bolder'>Credit Memo:</div>
										<div className='col-4 sdms-font-bolder sdms-align-right'>
											({priceFormatter(depositDetails.credit)})
										</div>
									</div>
								)}
							</>
						)}
						<div className='row sdms-mt-10'>
							<div className='col-8 sdms-font-boldest'>Deposit Due Now:</div>
							<div className='col-4 sdms-font-boldest sdms-align-right'>
								{priceFormatter(
									Math.max(
										depositDetails.applicable !== depositDetails.required
											? depositDetails.applicable
											: depositDetails.remaining,
										0
									)
								)}
							</div>
						</div>
					</div>
				</SummarySection>
				{reservation?.extraCharges?.length > 0 && (
					<SummarySection
						title='Price Adjustments'
						isLoading={isLoading}
						price={priceFormatter(
							reservation.extraCharges.reduce(
								(partialSum, retain) => partialSum + retain.total,
								0
							)
						)}>
						<table className='table sdms-t-table sdms-mb-10 sdms-mt-10'>
							{reservation.extraCharges.map(extraChargeItem => (
								<PriceAdjustmentItem
									extraChargeItem={extraChargeItem}
									isLoading={isLoading}
									onRemove={() => onExtraChargeRemove(extraChargeItem)}
								/>
							))}
						</table>
					</SummarySection>
				)}
				<SummarySection title='Customer' isLoading={isLoading} defaultOpen>
					<div className='sdms-mt-10'>
						<div className='sdms-font-bolder sdms-font-size-1-point-2-rem'>
							{reservation?.customer?.displayName}
						</div>
						{reservation?.customer?.addressLineOne && (
							<div>{reservation.customer.addressLineOne}</div>
						)}
						{reservation?.customer?.addressLineTwo && (
							<div>{reservation.customer.addressLineTwo}</div>
						)}
						{(reservation?.customer?.city ||
							reservation?.customer?.state ||
							reservation?.customer?.zip) && (
							<div>
								{reservation?.customer?.city
									? `${reservation.customer.city}, `
									: ''}
								{reservation?.customer?.state?.code
									? `${reservation.customer.state.code} `
									: ''}
								{reservation?.customer?.zip}
							</div>
						)}
						{reservation?.customer?.phone && <div>{reservation.customer.phone}</div>}
					</div>
				</SummarySection>
			</Portlet.Body>
			<Portlet.Foot border={false} tall='xs' className='sdms-pb-15'>
				{totals.remainingAmount < 0 ? (
					<PayButton
						className='col-12 sdms-mb-10'
						text='Retain'
						onClick={() => onRetain(Math.abs(totals.remainingAmount))}
						color='info'
						icon='Dollar'
						colorOpacity={false}
						disabled={disabled || isLoading}
						price={priceFormatter(Math.abs(totals.remainingAmount))}
					/>
				) : (
					<PayButton
						className='col-12 sdms-mb-10'
						text='Deposit'
						onClick={() => onDepositPay(totals.remainingDepositAmount)}
						color='info'
						icon='Dollar'
						colorOpacity={false}
						disabled={
							disabled ||
							isLoading ||
							totals.remainingDepositAmount <= 0 ||
							(reservation.isLocked &&
								!hasEditLockedReservationPermission(userContext)) ||
							depositDetails.applicable === 0
						}
						price={priceFormatter(
							totals.remainingDepositAmount <= 0 ? 0 : totals.remainingDepositAmount
						)}
					/>
				)}
				<PayButton
					className='col-12'
					text={totals.remainingAmount < 0 ? 'Refund' : 'Pay'}
					onClick={() => {
						if (totals.remainingAmount < 0) onRefund(Math.abs(totals.remainingAmount));
						else onPay(totals.remainingAmount);
					}}
					color='info'
					icon='Dollar'
					colorOpacity={false}
					disabled={disabled || isLoading || totals.remainingAmount === 0}
					price={priceFormatter(Math.abs(totals.remainingAmount))}
				/>
			</Portlet.Foot>
			{reservation.customerReservationBalance > 0 && (
				<Portlet.Foot tall='sm' className='sdms-bg-fill-warning'>
					<Loading isLoading={isLoading} type='span'>
						<div className='col-12'>
							<SVGIcon
								name='Cart#1'
								fill='var(--dark)'
								size={20}
								className='sdms-mr-5'
							/>
							<b>Other Balance:</b>
							{` $${reservation.customerReservationBalance}`}
						</div>
					</Loading>
				</Portlet.Foot>
			)}
			<Portlet.Foot
				border={false}
				tall='xs'
				subClassName='justify-content-between sdms-fitText--sm'>
				<div className='col-auto'>
					Created By
					<Loading isLoading={isLoading} type='bold'>
						<b className='sdms-ml-5'>
							{reservation.createdBy && reservation.createdBy.displayName}
						</b>
					</Loading>
				</div>
				<div className='col-auto'>
					Created
					<Loading isLoading={isLoading} type='bold' width={106}>
						<b className='sdms-ml-5'>{dateFormatter(reservation.timeCreated)}</b>
					</Loading>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};

Summary.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	taxRate: PropTypes.object,
	isLoading: PropTypes.bool,
	disabled: PropTypes.bool,
	onPay: PropTypes.func,
	onDepositPay: PropTypes.func,
	onRefund: PropTypes.func,
	onRetain: PropTypes.func,
	onReceiptPrint: PropTypes.func,
	onReceiptEmail: PropTypes.func,
	onPaymentRefund: PropTypes.func,
	onScheduledPaymentCapture: PropTypes.func,
	onPaymentVoid: PropTypes.func,
	onAuthorizedPaymentEdit: PropTypes.func,
	onAuthorizedPaymentCapture: PropTypes.func,
	onAuthorizedPaymentVoid: PropTypes.func,
	onRefundVoid: PropTypes.func,
	onExtraChargeRemove: PropTypes.func,
	onDepositView: PropTypes.func,
	viewingDepositScreen: PropTypes.bool,
	onAutoRefund: PropTypes.func,
};

Summary.defaultProps = {
	reservation: null,
	taxRate: null,
	isLoading: true,
	disabled: false,
	onPay: () => {},
	onDepositPay: () => {},
	onRefund: () => {},
	onRetain: () => {},
	onReceiptPrint: () => {},
	onReceiptEmail: () => {},
	onPaymentRefund: () => {},
	onScheduledPaymentCapture: () => {},
	onPaymentVoid: () => {},
	onAuthorizedPaymentEdit: () => {},
	onAuthorizedPaymentCapture: () => {},
	onAuthorizedPaymentVoid: () => {},
	onRefundVoid: () => {},
	onExtraChargeRemove: () => {},
	onDepositView: () => {},
	viewingDepositScreen: false,
	onAutoRefund: () => {},
};
export default Summary;
