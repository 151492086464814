import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import TaxRateGroupForm from './TaxRateGroupForm';

const TaxRateGroupEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='taxRates'
			lists={['enumCalculations', 'taxRates']}
			fields={<TaxRateGroupForm />}
			pageTitle={pages.accounting.settings.taxRateGroup.text}
			icon={pages.accounting.settings.taxRateGroup.icon}
		/>
	);
};

TaxRateGroupEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default TaxRateGroupEdit;
