import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SlideDown } from 'react-slidedown';

import SVGIcon from '../../../element/SVGIcon';
import Loading from '../../../template/Loading';

const SummarySection = ({ title, price, isLoading, children, defaultOpen, customButton }) => {
	const [isOpen, setIsOpen] = useState(defaultOpen);

	const ref = useRef();

	const init = useRef(false);

	const timeoutId = useRef(0);

	useEffect(() => {
		if (timeoutId) clearTimeout(timeoutId.current);

		if (isOpen && ref.current && init.current)
			timeoutId.current = setTimeout(() => {
				ref.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}, 500);

		init.current = true;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	return (
		<div
			className='sdms-advanced-reservation-summary-section row sdms-pt15 sdms-pb-15'
			ref={ref}>
			<div
				className='col-6 d-flex align-items-center sdms-align-left sdms-font-bolder sdms-font-info sdms-font-size-1-point-2-rem sdms-cursor--pointer'
				onClick={() => setIsOpen(!isOpen)}
				role='presentation'>
				{title}
				{customButton.visible && (
					<SVGIcon
						size={20}
						className='ml-2'
						name={customButton.icon}
						onClick={customButton.onClick}
						disabled={customButton.disabled}
					/>
				)}
			</div>
			<div
				className='col-6 sdms-align-right sdms-font-bolder sdms-font-info sdms-font-size-1-point-2-rem sdms-cursor--pointer'
				onClick={() => setIsOpen(!isOpen)}
				role='presentation'>
				<Loading isLoading={isLoading} type='span'>
					<div>
						{price}
						<SVGIcon
							name='Angle-right'
							size={16}
							fill='var(--info)'
							className={classNames('sdms-transition sdms-ml-5', {
								'sdms-rotate--270': isOpen,
								'sdms-rotate--90': !isOpen,
							})}
						/>
					</div>
				</Loading>
			</div>
			<SlideDown className='col-12'>{isOpen ? children : null}</SlideDown>
		</div>
	);
};

SummarySection.propTypes = {
	title: PropTypes.string,
	price: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	isLoading: PropTypes.bool,
	children: PropTypes.node,
	defaultOpen: PropTypes.bool,
	customButton: PropTypes.shape({
		visible: PropTypes.bool,
		disabled: PropTypes.bool,
		onClick: PropTypes.func,
		icon: PropTypes.string,
	}),
};

SummarySection.defaultProps = {
	title: 'Title',
	price: null,
	isLoading: false,
	children: null,
	defaultOpen: false,
	customButton: {
		visible: false,
		disabled: false,
		onClick: () => {},
		icon: 'Plus',
	},
};

export default SummarySection;
