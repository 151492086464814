import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

const HeaderContext = createContext(null);

export const HeaderContextProvider = ({ children }) => {
	const [breadcrumbs, setBreadcrumbs] = useState([]);

	const [pageTitle, setPageTitle] = useState('');

	return (
		<HeaderContext.Provider value={{ breadcrumbs, setBreadcrumbs, pageTitle, setPageTitle }}>
			{children}
		</HeaderContext.Provider>
	);
};

HeaderContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default HeaderContext;
