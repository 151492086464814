import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { priceFormatter } from '../../../utils/helpers/helper';

const WidgetSingle = ({ data, widget }) => {
	const { singleField, singleCalculation } = widget;

	const getSingleDataValue = () => {
		let values = data
			.filter(a => a[singleField.alias] !== null && a[singleField.alias] !== undefined)
			.map(a => a[singleField.alias]);

		if (values.length === 0) return 0;

		if (['Total', 'Average', 'Max', 'Min'].indexOf(singleCalculation.value) > -1) {
			values = values.map(item => parseFloat(item));

			const total = values.reduce((partialSum, item) => partialSum + item, 0);

			if (singleCalculation.value === 'Total') return total;

			if (singleCalculation.value === 'Average')
				return parseFloat(total / data.length).toFixed(2);

			if (singleCalculation.value === 'Max') return Math.max(...values);

			if (singleCalculation.value === 'Min') return Math.min(...values);
		}

		if (singleCalculation.value === 'Distinct count') return new Set(values).size;

		return 0;
	};

	const singleData = useMemo(() => {
		const value = getSingleDataValue();

		if (singleField?.fieldFormat?.value === 'price') return priceFormatter(value);

		return value;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, widget]);

	return (
		<h4 className='h-100 d-flex align-items-center justify-content-center sdms-font-xxl sdms-font-bolder'>
			{singleData}
		</h4>
	);
};

export default WidgetSingle;

WidgetSingle.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})),
	widget: PropTypes.shape({
		id: PropTypes.number,
		report: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
			queryJSON: PropTypes.string,
			reportFields: PropTypes.arrayOf(PropTypes.shape({})),
		}),
		sortOrder: PropTypes.number,
		width: PropTypes.number,
		title: PropTypes.string,
		config: PropTypes.string,
		type: PropTypes.shape({
			id: PropTypes.number,
			value: PropTypes.string,
		}),
		col: PropTypes.number,
		row: PropTypes.number,
		sizeX: PropTypes.number,
		sizeY: PropTypes.number,
		singleCalculation: PropTypes.shape({
			id: PropTypes.number,
			value: PropTypes.string,
		}),
		singleField: PropTypes.shape({
			id: PropTypes.number,
			alias: PropTypes.string,
			fieldFormat: PropTypes.shape({
				value: PropTypes.string,
			}),
		}),
	}),
};

WidgetSingle.defaultProps = {
	data: [],
	widget: {
		id: 0,
		report: {
			id: 0,
			name: '',
			queryJSON: '',
			reportFields: [],
		},
		sortOrder: 0,
		width: 100,
		title: '',
		config: null,
		type: {
			id: 0,
			value: null,
		},
		col: 0,
		row: 0,
		sizeX: 0,
		sizeY: 0,
		singleCalculation: {
			id: 0,
			value: '',
		},
		singleField: {
			id: 0,
			alias: '',
			fieldFormat: {
				value: '',
			},
		},
	},
};
