import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import update from 'immutability-helper';
import UserContext from '../../../app/contexts/UserContext';
import apiCall, {
	filters,
	modules,
	outletRelatedEndpoints,
	parseData,
} from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	addSuccessNotification,
	dateFormatter,
	parseListContainerCustomFilter,
} from '../../../utils/helpers/helper';
import { filterComponents, USER_STORAGE_KEY } from '../../../utils/constants/constants';
import BreadcrumbContainer from './BreadcrumbContainer';
import Button from '../element/Button';
import ContentInner from './ContentInner';
import Search from '../element/Search';
import Portlet from '../layout/Portlet';
import DialogBox from '../element/DialogBox';
import Loading from './Loading';
import Portal from '../layout/Portal';
import Dropdown from '../element/Dropdown';
import Input from '../field/Input';
import Selects from '../field/Selects';
import Badge from '../element/Badge';
import QuickPanel from '../../QuickPanel';
import FormGroup from '../layout/FormGroup';
import FormField from './FormField';
import QuickPanelForm from './QuickPanelForm';
import Radio from '../field/Radio';
import AsyncSelect from '../field/AsyncSelect';
import DatePicker from '../field/DatePicker';

const Filter = ({
	customFilters,
	customListFilters,
	setCustomListFilters,
	inPortlet,
	inQuickPanel,
}) => {
	const [customFiltersSelectsOptions, setCustomFiltersSelectsOptions] = useState([]);
	const [quickPanel, setQuickPanel] = useState(false);
	const init = useRef(false);

	useEffect(() => {
		if (customFilters.length > 0)
			customFilters.forEach(cf => {
				customListFilters.push({
					component: cf.component,
					fieldName: cf.fieldName,
					placeholder: cf.placeholder,
					label: cf.label,
					displayKey: cf.displayKey || 'name',
					value: cf.default || '',
					default: cf.default,
					multiple: cf.multiple,
					summaryWithLabel: cf.summaryWithLabel,
				});

				if (cf.component === filterComponents.SELECTS && cf.dataName)
					apiCall(
						'GET',
						cf.dataName,
						res => {
							customFiltersSelectsOptions.push({
								fieldName: cf.fieldName,
								options: [...res, ...(cf.extraOptions || [])],
							});
							setCustomFiltersSelectsOptions([...customFiltersSelectsOptions]);
						},
						err => console.error(err)
					);
			});

		setCustomListFilters([...customListFilters]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getFilterValue = filterFieldName => {
		if (customListFilters.length === 0) return null;

		const filter =
			customListFilters[customListFilters.findIndex(cf => cf.fieldName === filterFieldName)];

		if (!filter) return null;
		if (filter.value) return filter.value;

		if (filter.default) {
			const defaultOption = customFiltersSelectsOptions.find(
				cfs => cfs.fieldName === filter.fieldName
			);

			if (defaultOption) {
				if (!init.current) {
					updateFilters(
						filterFieldName,
						defaultOption.options.find(o => o[filter.displayKey] === filter.default)
					);
					return defaultOption.options.find(o => o[filter.displayKey] === filter.default);
				}
			}
		}

		return null;
	};

	const updateFilters = (filterFieldName, newValue) => {
		const filterIndex = customListFilters.findIndex(cf => cf.fieldName === filterFieldName);

		if (filterIndex === -1) return;

		if (customListFilters.length > 0 && newValue)
			customListFilters[filterIndex].value = newValue;
		else if (customListFilters.length > 0 && !newValue) {
			customListFilters[filterIndex] = {
				component: customListFilters[filterIndex].component,
				multiple: customListFilters[filterIndex].multiple,
				fieldName: filterFieldName,
				placeholder: customListFilters[filterIndex].placeholder,
				summaryWithLabel: customListFilters[filterIndex].summaryWithLabel,
				label:
					customListFilters[filterIndex].label ||
					customListFilters[filterIndex].placeholder,
				displayKey: customFilters[filterIndex].displayKey || 'name',
				value: '',
			};
		}

		setCustomListFilters([...customListFilters]);
	};

	const getSelectsOptions = useCallback(
		optionsFieldName => {
			if (customFiltersSelectsOptions.length === 0) return [];
			const ind = customFiltersSelectsOptions.findIndex(
				cfso => cfso.fieldName === optionsFieldName
			);
			return ind !== -1 ? customFiltersSelectsOptions[ind].options : [];
		},
		[customFiltersSelectsOptions]
	);

	const SUMMARY = useMemo(() => {
		const _summary = [];

		customListFilters.forEach(clf => {
			const { component, value, label, summaryWithLabel } = clf;

			if (component === filterComponents.DATE_RANGE && value) {
				_summary.push(
					<Badge
						isInline
						isSpaced
						isUnified
						className='sdms-font-transform-c'
						key={`filterSummary${label}`}>
						<b className='sdms-ml-5'>
							{summaryWithLabel && `${label}: `}
							{dateFormatter(clf.value.startDate, false)} -
							{dateFormatter(clf.value.endDate, false)}
						</b>
					</Badge>
				);
			}

			if (
				component === filterComponents.SELECTS ||
				component === filterComponents.ASYNC_SELECTS
			) {
				if (clf.multiple)
					_summary.push(
						...clf.value.map(ee => (
							<Badge
								isInline
								isSpaced
								isUnified
								className='sdms-font-transform-c'
								key={`filterSummary${label}`}>
								<b className='sdms-ml-5'>
									{summaryWithLabel && `${label}: `}
									{ee[clf.displayKey] || clf.value}
								</b>
							</Badge>
						))
					);
				else if (clf.value && Object.keys(clf.value).length > 0)
					_summary.push(
						<Badge isInline isSpaced isUnified className='sdms-font-transform-c'>
							<b className='sdms-ml-5'>
								{summaryWithLabel && `${label}: `}
								{clf.value[clf.displayKey] || clf.value.value}
							</b>
						</Badge>
					);
			}
		});

		return _summary;
	}, [customListFilters]);

	const COMPONENTS = customFilters.map(cf => {
		let COMPONENT = null;

		if (cf.component === filterComponents.RADIO)
			COMPONENT = (
				<Radio.Container isInline>
					{cf.options.map(o => (
						<Radio
							key={o.display}
							checked={
								Array.isArray(o.value)
									? JSON.stringify(getFilterValue(cf.fieldName)) ===
									  JSON.stringify(o.value)
									: getFilterValue(cf.fieldName) === o.value
							}
							id={o.display}
							name={cf.name}
							content={o.display}
							className='sdms-radio--primary'
							onChange={() => updateFilters(cf.fieldName, o.value)}
						/>
					))}
				</Radio.Container>
			);

		if (cf.component === filterComponents.INPUT)
			COMPONENT = (
				<Input
					value={getFilterValue(cf.fieldName)}
					onChange={e => {
						updateFilters(cf.fieldName, e.target.value);
					}}
					placeholder={cf.placeholder}
					type='text'
				/>
			);

		if (cf.component === filterComponents.DATE_RANGE)
			COMPONENT = (
				<DatePicker
					id={cf.fieldName}
					type='dateRange'
					value={getFilterValue(cf.fieldName)}
					onChange={e => {
						updateFilters(cf.fieldName, e.startDate ? e : null);
					}}
				/>
			);

		if (cf.component === filterComponents.ASYNC_SELECTS)
			COMPONENT = (
				<AsyncSelect
					route={cf.dataName}
					field={cf.searchField}
					options={cf.options ? cf.options : getSelectsOptions(cf.fieldName)}
					value={getFilterValue(cf.fieldName)}
					onChange={e => {
						updateFilters(cf.fieldName, e.target.value);
					}}
					placeholder={cf.placeholder}
					displayKey={cf.displayKey || 'name'}
					isMulti={cf.multiple && customListFilters.length > 0}
				/>
			);

		if (cf.component === filterComponents.SELECTS)
			COMPONENT = (
				<Selects
					options={cf.options ? cf.options : getSelectsOptions(cf.fieldName)}
					value={getFilterValue(cf.fieldName)}
					onChange={e => {
						updateFilters(cf.fieldName, e.target.value);
					}}
					placeholder={cf.placeholder}
					displayKey={cf.displayKey || 'name'}
					multiple={cf.multiple && customListFilters.length > 0}
				/>
			);

		if (!COMPONENT) return null;

		if (inQuickPanel && COMPONENT)
			return (
				<FormField
					name={cf.name || ''}
					label={cf.label}
					col={12}
					key={`customFilter${cf.fieldName}`}>
					{COMPONENT}
				</FormField>
			);

		return (
			<div style={{ minWidth: 150 }} key={`customFilter${cf.fieldName}`}>
				{COMPONENT}
			</div>
		);
	});

	if (COMPONENTS.length === 0) return null;

	if (inQuickPanel)
		return (
			customFilters.length > 0 && (
				<>
					<Button
						design={
							customListFilters
								.map(e => Object.keys(e.value).length > 0)
								.find(f => f === true)
								? 'info'
								: 'clean'
						}
						icon='Filter'
						onClick={() => setQuickPanel(true)}
						btnIcon
						circle
					/>
					{inPortlet ? SUMMARY : <span className='sdms-subheader__desc'>{SUMMARY}</span>}
					<QuickPanel
						status={quickPanel}
						setStatus={setQuickPanel}
						icon='Filter'
						title='Filters'>
						<Portlet.Body>
							<FormGroup>{COMPONENTS}</FormGroup>
						</Portlet.Body>
					</QuickPanel>
				</>
			)
		);

	return COMPONENTS;
};

Filter.propTypes = {
	customFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
	customListFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
	setCustomListFilters: PropTypes.func.isRequired,
	inPortlet: PropTypes.bool,
	inQuickPanel: PropTypes.bool,
};

Filter.defaultProps = {
	inPortlet: false,
	inQuickPanel: true,
};

const ListContainer = ({
	children,
	title,
	route,
	history,
	hasPagination,
	customActions,
	customMultiActions,
	customFilters,
	customFiltersInQuickPanel,
	checkEditable,
	isTabList,
	largeQuickPanel,
	icon,
	fluid,
	style,
	forms,
	module,
	hasSearch,
	defaultSearchText,
	staticFilters,
	presetData,
	onChange,
	border,
	isLast,
	headerActions,
	showSubheader,
	noPaddingContainer,
	allowExport,
	onSelectedItemsChange,
}) => {
	const userCookie = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));

	const abortController = useRef(null);
	const [items, setItems] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const [isPageLoading, setIsPageLoading] = useState(true);
	const [isListLoading, setIsListLoading] = useState(true);
	const [error, setError] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [totalItems, setTotalItems] = useState(0);
	const [searchText, setSearchText] = useState(defaultSearchText);
	const [timeoutId, setTimeoutId] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(
		parseInt(
			userCookie && userCookie.lists[route]
				? userCookie.lists[route]
				: process.env.REACT_APP_ITEMS_PER_PAGE,
			10
		)
	);
	const [willDeleteItems, setWillDeleteItems] = useState([]);
	const [editModal, setEditModal] = useState({ id: null, form: '' });
	const [customListFilters, setCustomListFilters] = useState([]);
	const isOutletRelated = useRef(outletRelatedEndpoints.includes(route));
	const successfulDeleteNotification = useRef(false);
	const userContext = useContext(UserContext);
	const _isMounted = useRef(false);

	const getDeletePermission = () => {
		if (route === 'productBookings') {
			if (module === modules.MARINA) return 'delete_marina_products';

			if (module === modules.CAMPGROUND) return 'delete_campground_products';

			return 'delete_bookings_products';
		}

		const deletePermissions = {
			registers: 'delete_registers',
			posProducts: 'delete_pos_products',
			grids: 'delete_product_grids',
			modifierGroups: 'delete_modifiers_group',
			accounts: 'delete_chart_of_accounts',
			customers: 'delete_customers',
			users: 'delete_users',
			journals: 'delete_journal_entries',
		};

		return deletePermissions[route];
	};

	const getAvailableMultiActions = useCallback(() => {
		return checkEditable
			? checkEditable(items.filter(item => selectedItems.includes(item.id)))
			: [...Object.keys(customMultiActions), 'delete'];
	}, [selectedItems, checkEditable, customMultiActions, items]);

	useEffect(() => {
		_isMounted.current = true;

		return () => {
			_isMounted.current = false;
		};
	}, []);

	const handleChecked = ({ target }) => {
		const dataId = parseInt(target.attributes.getNamedItem('data-id').value, 10);
		if (target.checked) {
			setSelectedItems(oldSelectedItems => [...oldSelectedItems, dataId]);
		} else {
			setSelectedItems(oldSelectedItems => oldSelectedItems.filter(it => it !== dataId));
		}
	};

	const handleAllChecked = ({ target }) => {
		if (target.checked) {
			setSelectedItems(items.map(item => item.id));
		} else {
			setSelectedItems([]);
		}
	};

	const editModalRender = (_largeQuickPanel = false) => {
		if (editModal.id === null) return null;

		return (
			<Portal>
				{forms
					.filter(f => f.isModal || f.isQuickPanel)
					.map(f => {
						const FORM = f.component;

						if (f.isQuickPanel)
							return (
								<QuickPanelForm
									key={f.name}
									status={editModal.id !== null && editModal.form === f.name}
									setStatus={() => {}}
									onCancel={() => setEditModal({ id: null, form: '' })}
									largeQuickPanel={_largeQuickPanel}>
									<FORM
										id={editModal.id}
										afterSubmit={(item = null) => {
											if (item) getData();

											if (onChange) onChange(item);

											if (!f.canClose || f.canClose(item))
												setEditModal({ id: null, form: '' });
										}}
										onCancel={() => setEditModal({ id: null, form: '' })}
										presetData={presetData}
										isQuickPanel
									/>
								</QuickPanelForm>
							);

						return (
							<Popup
								key={f.name}
								open={editModal.id !== null && editModal.form === f.name}
								contentStyle={{
									padding: 0,
									background: 'unset',
									border: 'unset',
								}}
								closeOnDocumentClick={false}
								lockScroll
								modal
								onClose={() => setEditModal({ id: null, form: '' })}>
								<FORM
									id={editModal.id}
									afterSubmit={(item = null) => {
										if (item) getData();

										if (onChange) onChange(item);

										if (!f.canClose || f.canClose(item))
											setEditModal({ id: null, form: '' });
									}}
									onCancel={() => setEditModal({ id: null, form: '' })}
									presetData={presetData}
								/>
							</Popup>
						);
					})}
			</Portal>
		);
	};

	const modifyAddNewButton = form => {
		const buttonOnClick = () => {
			if (customActions[form.name]) customActions[form.name]();
			else if (form.isModal || form.isQuickPanel) setEditModal({ id: 0, form: form.name });
			else if (history) history.push(`${form.path}/0`);
		};

		const noPermission = !userContext.hasPermission(form.editPermission || form.permission);

		if (form.button) {
			return React.cloneElement(form.button, {
				onClick: buttonOnClick,
				noPermission,
				reload: () => getData(),
			});
		}

		return (
			<Button
				key={form.name}
				label='brand'
				text='Add New'
				icon='Plus'
				size='sm'
				onClick={buttonOnClick}
				noPermission={noPermission}
			/>
		);
	};

	const getAddNewButtons = () => {
		const inDropDownButtons = forms.filter(f => f.isInDropdown);

		const separateButtons = forms.filter(f => !f.hideButton && !f.isInDropdown);

		const buttons = [];

		if (forms.length) {
			if (inDropDownButtons.length) {
				if (separateButtons.length > 1)
					buttons.push(separateButtons.splice(1).map(f => modifyAddNewButton(f)));

				buttons.push(
					<Button.Group noPermission={false} key='buttonGroup'>
						{modifyAddNewButton(separateButtons[0])}
						<Dropdown label='brand' aligned='right' isOnlyContent key='d'>
							{inDropDownButtons.map(f => modifyAddNewButton(f))}
						</Dropdown>
					</Button.Group>
				);
			} else buttons.push(separateButtons.map(f => modifyAddNewButton(f)));

			return buttons;
		}

		return null;
	};

	const afterDelete = deletedItemCount => {
		const _totalItems = totalItems - deletedItemCount;

		if (_totalItems !== 0 && itemsPerPage * (currentPage - 1) + 1 > _totalItems)
			setCurrentPage(currentPage - 1);
		else getData();
	};

	const deleteItem = (id, isMulti = false) => {
		let body = null;

		if (isOutletRelated.current)
			body = { outlet: parseData(userContext.data.selectedOutlet, 'outlets') };

		apiCall(
			'DELETE',
			route,
			() => {
				afterDelete(isMulti ? selectedItems.length : 1);

				if (!successfulDeleteNotification.current) {
					successfulDeleteNotification.current = true;
					addSuccessNotification('Item(s) successfully deleted.');
				}
			},
			err => {
				const item = items.find(i => i.id === id);

				if (item.displayName || item.name)
					addErrorNotification(
						`"${item.displayName ||
							item.name}" couldn't be deleted. \n ${err.toString()}`
					);
				else addErrorNotification(err.toString());
			},
			id,
			body
		);
	};

	const deleteSelectedItems = () => {
		if (selectedItems.length === 0) return;
		successfulDeleteNotification.current = false;
		selectedItems.forEach(id => deleteItem(id, true));
	};

	const deleteDialog = (
		<DialogBox
			open={willDeleteItems.length > 0}
			title='Delete'
			content='Are you sure you want to delete these item(s)?'
			type='question'
			onClose={() => {
				setWillDeleteItems([]);
			}}>
			<Button
				className='sdms-font-transform-c'
				design='clean'
				icon='Error-circle'
				text={`No, Don't delete!`}
				onClick={() => {
					setWillDeleteItems([]);
				}}
			/>
			<Button
				className='sdms-font-transform-c'
				label='danger'
				icon='Trash'
				text='Yes, Delete!'
				onClick={() => {
					if (willDeleteItems.length === 0) return;

					if (willDeleteItems.length > 1) deleteSelectedItems();
					else deleteItem(willDeleteItems[0]);

					setWillDeleteItems([]);
				}}
			/>
		</DialogBox>
	);

	const updateData = (updatedItems, deletedItems = []) => {
		if (deletedItems.length) afterDelete(deletedItems.length);
		else {
			let _items = items;

			updatedItems.forEach(ui => {
				const index = items.findIndex(i => i.id === ui.id);
				if (index > -1) _items = update(_items, { [index]: { $set: ui } });
			});

			setItems(_items);
		}
	};

	const [sortData, setSortData] = useState({
		orderField:
			filters[route] && filters[route].orderField ? filters[route].orderField : 'name',
		sortOrder: filters[route] && filters[route].order ? filters[route].order : 'asc',
	});

	const sortDataOnChange = data => {
		setSortData({
			sortOrder: data.sortOrder ? data.sortOrder : 'asc',
			orderField: data.orderField ? data.orderField : 'name',
		});
	};

	const getData = () => {
		clearTimeout(timeoutId);
		setTimeoutId(
			setTimeout(
				() => {
					setIsListLoading(true);

					let _filters = {};

					if (hasPagination) {
						_filters.pagination = true;
						_filters.itemsPerPage = itemsPerPage;
						_filters.page = currentPage;
					} else _filters.pagination = false;

					if (isOutletRelated.current)
						_filters['outlet.id'] = userContext.data.selectedOutlet.id;

					if (module) _filters['module.value'] = module;

					if (searchText.length) {
						if ((filters[route] || {}).searchField) {
							if (Array.isArray(filters[route].searchField)) {
								_filters[
									`or[${filters[route].searchField.join('&')}]`
								] = searchText;
							} else _filters[filters[route].searchField] = searchText;
						} else _filters.name = searchText; // default search filter is name.
					}

					_filters[`order[${sortData.orderField}]`] = sortData.sortOrder;

					if ((((filters[route] || {}).groups || {}).list || {}).read)
						_filters['groups[]'] = filters[route].groups.list.read;

					customListFilters.forEach(clf => {
						_filters = { ..._filters, ...parseListContainerCustomFilter(clf) };
					});

					if (staticFilters) _filters = { ..._filters, ...staticFilters };

					if (abortController.current) abortController.current.abort();

					abortController.current = new AbortController();

					apiCall(
						'GET',
						route,
						res => {
							if (!_isMounted.current) return;

							setSelectedItems([]);
							setItems(hasPagination ? res['hydra:member'] : res);
							setTotalItems(
								typeof res['hydra:totalItems'] !== 'undefined'
									? res['hydra:totalItems']
									: res.length
							);
							setTotalPage(
								hasPagination
									? Math.ceil(res['hydra:totalItems'] / itemsPerPage)
									: 0
							);
							setIsPageLoading(false);
							setIsListLoading(false);
							setError(false);
						},
						err => {
							if (err.toString().search('AbortError') === -1) {
								setIsPageLoading(false);
								setIsListLoading(false);
								setError(err.toString());
								addErrorNotification(err.toString());
							}
						},
						'',
						null,
						_filters,
						abortController.current.signal
					);
				},
				searchText === '' ? 0 : 500
			)
		);
	};

	useEffect(() => {
		onSelectedItemsChange(selectedItems);
	}, [selectedItems, onSelectedItemsChange]);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		route,
		currentPage,
		hasPagination,
		itemsPerPage,
		searchText,
		staticFilters,
		customListFilters,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		isOutletRelated.current ? userContext.data.selectedOutlet : null,
		sortData,
	]);

	const getModifiedChildren = () => {
		return (Array.isArray(children) ? children : [children]).map(c => {
			if (c.type.defaultProps.name === 'List') {
				return React.cloneElement(c, {
					data: hasPagination ? items.slice(0, itemsPerPage) : items,
					handleChecked,
					handleAllChecked,
					selectedItems,
					isAllChecked: items.length > 0 && selectedItems.length === items.length,
					currentPage,
					setCurrentPage,
					totalPage,
					name: route,
					key: route,
					isLoading: isListLoading,
					isPageLoading,
					itemsPerPage,
					setItemsPerPage,
					totalItems,
					hasPagination,
					updateData,
					sortData,
					sortDataOnChange,
					actions: {
						edit: data => {
							let didFindForm = false;
							Object.entries(forms).every(f => {
								if (f[1].which && f[1].which(data)) {
									if (f[1].path) history.push(`${f[1].path}/${data.id}`);
									else setEditModal({ id: data.id, form: f[1].name });

									didFindForm = true;
									return false;
								}
								return true;
							});

							if (!didFindForm) {
								const addForm = forms.find(f => f.name === 'add');

								if (addForm)
									if (addForm.path) history.push(`${addForm.path}/${data.id}`);
									else setEditModal({ id: data.id, form: addForm.name });
							}
						},
						delete: data => setWillDeleteItems([data.id]),
						...customActions,
					},
				});
			}

			return c;
		});
	};

	const itemTotalInfo = () => {
		if (totalItems > 0) {
			return (
				<>
					<span>{totalItems}</span>
					<span> Total </span>
				</>
			);
		}
		return 'No Results';
	};

	if (error) return <div>{error}</div>;

	const availableMultiActions = getAvailableMultiActions();

	if (isTabList) {
		return (
			<Portlet
				style={style}
				placeholder={isPageLoading}
				fluid={fluid}
				className={`sdms-list-layout sdms-list-${title}`}
				hasFrame
				border={border}
				isLast={isLast}>
				<Portlet.Head wrapMaxSize='md'>
					<Portlet.HeadLabel portletIcon={icon}>
						<h3 className='sdms-portlet__head-title'>{title}</h3>
						{hasSearch && (
							<>
								<Portlet.Separator />
								{!selectedItems.length && (
									<>
										<div className='sdms-portlet__head-desc'>
											<Loading
												isLoading={isListLoading}
												width={70}
												type='title'>
												{itemTotalInfo()}
											</Loading>
										</div>
										<Search
											inPortlet
											searchText={searchText}
											setSearchText={setSearchText}
											isSearching={isListLoading}
											setCurrentPage={setCurrentPage}
										/>
									</>
								)}
								{selectedItems.length > 0 && availableMultiActions.length > 0 && (
									<>
										<div className='sdms-portlet__head-desc'>
											{selectedItems.length} selected
										</div>
										<div className='btn-toolbar'>
											{availableMultiActions.map(action => {
												if (
													action === 'delete' &&
													!customMultiActions.delete
												) {
													return (
														<Button
															key={`multiDelete-${route}`}
															text='Delete'
															label='danger'
															icon='Trash'
															onClick={() =>
																setWillDeleteItems(selectedItems)
															}
															noPermission={
																getDeletePermission(route) &&
																!userContext.hasPermission(
																	getDeletePermission(route)
																)
															}
														/>
													);
												}

												return React.cloneElement(
													customMultiActions[action].component,
													{
														onClick: () => {
															customMultiActions[action].func(
																items.filter(item =>
																	selectedItems.includes(item.id)
																),
																updateData
															);
														},
													}
												);
											})}
										</div>
									</>
								)}
							</>
						)}
						<Filter
							customFilters={customFilters}
							customListFilters={customListFilters}
							setCustomListFilters={setCustomListFilters}
							inPortlet
							inQuickPanel={customFiltersInQuickPanel}
						/>
					</Portlet.HeadLabel>

					<Portlet.HeadToolbar>
						<Portlet.HeadActions className='row'>
							{headerActions.map((ha, i) => {
								if (ha.component) return ha.component;

								return (
									<Button
										key={`headerActions${ha.text}`}
										className='sdms-font-transform-c'
										design={ha.design}
										icon={ha.icon}
										text={ha.text}
										onClick={ha.onClick}
										style={{
											marginRight: i === headerActions.length - 1 ? 0 : 15,
										}}
										outline={ha.outline}
										disabled={ha.disabled}
										size='sm'
									/>
								);
							})}
							{forms.length > 0 && getAddNewButtons()}
						</Portlet.HeadActions>
					</Portlet.HeadToolbar>
				</Portlet.Head>
				{getModifiedChildren()}
				{deleteDialog}
				{editModalRender(largeQuickPanel)}
			</Portlet>
		);
	}

	return (
		<>
			{showSubheader ? (
				<ContentInner.SubHeader>
					<ContentInner.SubHeaderItem>
						<ContentInner.SubHeaderTitle title={title} />
						<Loading isLoading={isPageLoading} type='breadcrumb'>
							<BreadcrumbContainer />
						</Loading>
						<ContentInner.SubHeaderSeparator />
						<ContentInner.SubHeaderGroup>
							<ContentInner.SubHeaderDesc>
								<Loading isLoading={isListLoading} width={70} type='title'>
									{itemTotalInfo(totalItems)}
								</Loading>
							</ContentInner.SubHeaderDesc>
							{hasSearch && (
								<>
									<Search
										className='sdms-subheader__search'
										searchText={searchText}
										setSearchText={setSearchText}
										isSearching={isListLoading}
										setCurrentPage={setCurrentPage}
									/>
									{selectedItems.length > 0 && availableMultiActions.length > 0 && (
										<>
											<ContentInner.SubHeaderDesc>
												{selectedItems.length} selected
											</ContentInner.SubHeaderDesc>
											<ContentInner.SubHeaderDesc>
												<div className='btn-toolbar'>
													{availableMultiActions.map(action => {
														if (
															action === 'delete' &&
															!customMultiActions.delete
														) {
															return (
																<Button
																	key={`multiDelete-${route}`}
																	text='Delete'
																	label='danger'
																	icon='Trash'
																	onClick={() =>
																		setWillDeleteItems(
																			selectedItems
																		)
																	}
																	noPermission={
																		getDeletePermission(
																			route
																		) &&
																		!userContext.hasPermission(
																			getDeletePermission(
																				route
																			)
																		)
																	}
																/>
															);
														}

														return React.cloneElement(
															customMultiActions[action].component,
															{
																onClick: () => {
																	customMultiActions[action].func(
																		items.filter(item =>
																			selectedItems.includes(
																				item.id
																			)
																		),
																		updateData
																	);
																},
															}
														);
													})}
													{allowExport && (
														<Button
															key={`export-${route}`}
															text='Export'
															label='primary'
															onClick={() =>
																document
																	.getElementById(
																		'sdms-list-export-button'
																	)
																	.click()
															}
														/>
													)}
												</div>
											</ContentInner.SubHeaderDesc>
										</>
									)}
								</>
							)}
							<Filter
								customFilters={customFilters}
								customListFilters={customListFilters}
								setCustomListFilters={setCustomListFilters}
								inQuickPanel={customFiltersInQuickPanel}
							/>
						</ContentInner.SubHeaderGroup>
					</ContentInner.SubHeaderItem>
					{headerActions.map((ha, i) => {
						if (ha.component) return ha.component;

						return (
							<Button
								key={`headerActions${ha.text}`}
								className='sdms-font-transform-c'
								design={ha.design}
								icon={ha.icon}
								text={ha.text}
								onClick={ha.onClick}
								style={{
									marginRight: i === headerActions.length - 1 ? 0 : 15,
								}}
								outline={ha.outline}
								disabled={ha.disabled}
								size='sm'
							/>
						);
					})}
					{forms.length > 0 && (
						<ContentInner.SubHeaderItem type='toolbar'>
							{getAddNewButtons()}
						</ContentInner.SubHeaderItem>
					)}
				</ContentInner.SubHeader>
			) : (
				<></>
			)}
			<ContentInner.Container title={title} noPadding={!!noPaddingContainer}>
				{getModifiedChildren()}
			</ContentInner.Container>
			{deleteDialog}
			{editModalRender()}
		</>
	);
};

ListContainer.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
	route: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types,react/require-default-props
	history: PropTypes.object,
	hasPagination: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	customActions: PropTypes.object,
	customFilters: PropTypes.arrayOf(PropTypes.object),
	customFiltersInQuickPanel: PropTypes.bool,
	isTabList: PropTypes.bool,
	largeQuickPanel: PropTypes.bool,
	icon: PropTypes.string,
	fluid: PropTypes.oneOf(['fluid', 'fluid-half']),
	// eslint-disable-next-line react/forbid-prop-types
	style: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	customMultiActions: PropTypes.object,
	// eslint-disable-next-line react/require-default-props
	checkEditable: PropTypes.func,
	forms: PropTypes.arrayOf(PropTypes.object),
	module: PropTypes.string,
	hasSearch: PropTypes.bool,
	defaultSearchText: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	staticFilters: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	presetData: PropTypes.object,
	onChange: PropTypes.func,
	border: PropTypes.bool,
	isLast: PropTypes.bool,
	headerActions: PropTypes.arrayOf(PropTypes.object),
	showSubheader: PropTypes.bool,
	noPaddingContainer: PropTypes.bool,
	allowExport: PropTypes.bool,
	onSelectedItemsChange: PropTypes.func,
};

ListContainer.defaultProps = {
	hasPagination: true,
	customActions: {},
	customFilters: [],
	customFiltersInQuickPanel: true,
	isTabList: false,
	largeQuickPanel: false,
	icon: 'Layers',
	fluid: null,
	style: null,
	customMultiActions: {},
	forms: [],
	module: null,
	hasSearch: true,
	defaultSearchText: '',
	staticFilters: null,
	presetData: null,
	onChange: null,
	border: false,
	isLast: false,
	headerActions: [],
	showSubheader: true,
	noPaddingContainer: false,
	allowExport: false,
	onSelectedItemsChange: () => {},
};

export default ListContainer;
