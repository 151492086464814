import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UserContext from '../../../../../app/contexts/UserContext';
import useField from '../../../../../utils/hooks/useField';
import useDate from '../../../../../utils/hooks/useDate';
import { bookingPeriods } from '../../../../../utils/constants/constants';
import { addErrorNotification, priceFormatter } from '../../../../../utils/helpers/helper';
import {
	getCurrentUnit,
	getItemApplicableDepositAmount,
} from '../../../../../utils/helpers/reservationHelper';
import { required } from '../../../../../utils/helpers/validation';
import apiCall, { modules } from '../../../../../utils/helpers/apiCall';
import {
	getAvailableUnits,
	getReservationGridCustomFilters,
} from '../../../../../utils/helpers/reusable';
import {
	hasEditReservationUnitPermission,
	hasReservationProductChangePermission,
} from '../../../../../utils/helpers/permission';

import Portlet from '../../../layout/Portlet';
import ItemImage from '../../../element/reservation_form/elements/ItemImage';
import Separator from '../../../layout/Separator';
import Selects from '../../../field/Selects';
import FormField from '../../../template/FormField';
import Button from '../../../element/Button';
import Badge from '../../../element/Badge';

const ItemSummary = ({
	reservation,
	reservationItem,
	module,
	disabled,
	isItemSubmitted,
	onFormChange,
	onProductChange,
	pricing,
	isSearching,
}) => {
	const userContext = useContext(UserContext);

	const [dateFormatter] = useDate();

	const [swapUnit, setSwapUnit] = useState(false);

	const [swappableUnits, setSwappableUnits] = useState([]);

	const [isGettingSwappableUnits, setIsGettingSwappableUnits] = useState(false);

	const unitRequired = (value, setValRes) => {
		if (reservationItem?.product?.isVirtual) return true;
		return required(value, setValRes);
	};

	const isTransient = useMemo(
		() =>
			reservationItem.product.bookingPeriod.value !== bookingPeriods.SEASONAL &&
			reservationItem.product.bookingPeriod.value !== bookingPeriods.LONG_TERM,
		[reservationItem]
	);

	const checkInOutInfo = useMemo(() => {
		if (reservationItem.checkOutTime)
			return { label: 'Check-out', time: reservationItem.checkOutTime };

		if (reservationItem.checkInTime)
			return { label: 'Check-in', time: reservationItem.checkInTime };

		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservationItem]);

	const originUnit = useRef(reservationItem.unit);

	const [unit, unitOnChange, unitValRes, unitShowVal, setUnitShowVal] = useField(
		reservationItem,
		'unit',
		onFormChange,
		[unitRequired]
	);

	const originProduct = useRef({ ...reservationItem.product });

	const [productChangeEnabled, setProductChangeEnabled] = useState(false);

	const [product, productOnChange] = useField(reservationItem, 'product', onFormChange);

	const [availableUnits, setAvailableUnits] = useState([]);

	const hasEditUnitPermission = useMemo(() => hasEditReservationUnitPermission(userContext), [
		userContext,
	]);

	const hasProductChangePermission = useMemo(
		() => hasReservationProductChangePermission(userContext),
		[userContext]
	);

	const [products, setProducts] = useState([]);

	const unitPlaceHolder = useMemo(() => {
		if (reservationItem?.product?.isVirtual)
			return `Select a ${module === modules.BOOKINGS ? 'Unit' : 'Space'}`;

		return 'Auto Assign Required';
	}, [module, reservationItem]);

	const getSwappableUnits = () => {
		setIsGettingSwappableUnits(true);
		apiCall(
			'POST',
			'advancedReservationGetSwappableUnits',
			res => {
				if (res.swappableUnits.length === 0)
					addErrorNotification(
						`No ${module === modules.BOOKINGS ? 'Unit' : 'Space'} to swap`
					);
				else {
					setSwapUnit(true);
					reservationItem.swap = true;
					setSwappableUnits(res.swappableUnits);
					unitOnChange({ target: { value: null } });
				}

				setIsGettingSwappableUnits(false);
			},
			() => {
				setIsGettingSwappableUnits(false);
			},
			'',
			{ outletId: userContext.data.selectedOutlet.id, reservationItemId: reservationItem.id }
		);
	};

	const getDepositDueNow = () => {
		if (
			reservationItem.remainingDepositAmount > 0 &&
			!getItemApplicableDepositAmount(reservationItem, reservation)
		)
			return (
				<Badge
					design='secondary'
					isInline
					isUnified
					fontWeight='bold'
					size='lg'
					className='sdms-text-overflow'>
					<span className='sdms-text-overflow'>N/A</span>
				</Badge>
			);

		return priceFormatter(reservationItem.remainingDepositAmount || 0);
	};

	useEffect(() => {
		if (isItemSubmitted) setUnitShowVal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			unit &&
			!pricing.isInit &&
			reservationItem?.availableUnits &&
			!reservationItem.availableUnits.some(u => u.id === unit.id)
		)
			unitOnChange({
				target: {
					value:
						reservationItem.id === 0 && reservationItem.availableUnits.length > 0
							? reservationItem.availableUnits[0]
							: null,
				},
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservationItem.availableUnits]);

	useEffect(() => {
		if (!isSearching) {
			const _availableUnits = pricing.units
				? getAvailableUnits(reservationItem.product, pricing, module)
				: [];

			if (
				_availableUnits.length &&
				(!unit || !_availableUnits.some(u => u.id === unit.id)) &&
				!reservationItem?.product?.isVirtual
			) {
				unitOnChange({ target: { value: _availableUnits[0] } });
				// eslint-disable-next-line prefer-destructuring
				reservationItem.unit = _availableUnits[0];
			}
			setAvailableUnits(_availableUnits);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pricing]);

	useEffect(() => {
		if (hasProductChangePermission && products.length === 0) {
			apiCall(
				'GET',
				'productBookings',
				res => setProducts(res),
				() => {},
				'',
				null,
				{
					'module.value': module,
					'bookingType.inactive': false,
					inactive: false,
					...getReservationGridCustomFilters(originProduct.current.bookingPeriod),
				}
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasProductChangePermission]);

	return (
		<Portlet
			className='sdms-bg-transparent sdms-no-shadow sdms-advanced-reservation-item-form-summary sdms-paddingless sdms-marginless'
			hasFrame={false}
			fluid='fluid'>
			<Portlet.Body style={{ justifyContent: 'center' }}>
				<div className='row sdms-bg-white sdms-mb-15 sdms-advanced-reservation-item-form-summary-section'>
					<div className='col-12 sdms-mb-10'>
						<ItemImage
							image={
								(reservationItem?.product?.productImages || []).length > 0
									? reservationItem.product.productImages[0].path
									: null
							}
						/>
					</div>
					{productChangeEnabled ? (
						<div className='col-12'>
							<div className='row'>
								<div className='col-8'>
									<Selects
										placeholder='Search and select product'
										options={products}
										value={product}
										onChange={e => {
											productOnChange(e);
											onProductChange();
										}}
										disableClearable
										disabled={disabled}
									/>
								</div>
								<div className='col-4 sdms-align-right'>
									<Button
										icon='Error-circle'
										size='sm'
										text='Cancel'
										design='default'
										onClick={() => {
											if (
												reservationItem.product.id !==
												originProduct.current.id
											)
												onProductChange();
											reservationItem.product = originProduct.current;
											setProductChangeEnabled(false);
										}}
										disabled={disabled}
									/>
								</div>
							</div>
						</div>
					) : (
						<div className='col-12 sdms-align-center'>
							<span
								role='presentation'
								className={classNames(
									'sdms-font-boldest sdms-font-size-1-point-2-rem',
									{
										'sdms-link': hasProductChangePermission,
										'sdms-disable': hasProductChangePermission && disabled,
									}
								)}
								onClick={() => {
									if (!disabled) setProductChangeEnabled(true);
								}}>
								{reservationItem.product.name}
							</span>
						</div>
					)}
					{checkInOutInfo && (
						<div className='col-12 sdms-mt-10 sdms-align-left sdms-font-bold'>
							<span className='sdms-font-boldest'>{checkInOutInfo.label}: </span>
							{dateFormatter(
								checkInOutInfo.time,
								false,
								isTransient ? 'ddd, MMM D h:mm a' : 'ddd, MMM D',
								userContext.data.selectedOutlet.timezone.value
							)}
						</div>
					)}
				</div>
				<div className='row sdms-bg-white sdms-mb-15 sdms-advanced-reservation-item-form-summary-section'>
					<div className='col-12 sdms-font-boldest sdms-font-size-1-point-2-rem'>
						<span>Price Details</span>
					</div>
					<div className='col-12'>
						<Separator />
					</div>
					<div className='col-12 sdms-mb-5 sdms-font-bold sdms-font-size-1-rem'>
						<div className='row'>
							<div className='col-4 sdms-align-left'>Base Rate</div>
							<div className='col-8 sdms-align-right'>
								{priceFormatter(reservationItem.subtotal || 0)}
							</div>
						</div>
					</div>
					<div className='col-12 sdms-mb-5 sdms-font-bold sdms-font-size-1-rem'>
						<div className='row '>
							<div className='col-4 sdms-align-left'>Extras</div>
							<div className='col-8 sdms-align-right'>
								{priceFormatter(reservationItem.extraSubtotal || 0)}
							</div>
						</div>
					</div>
					<div className='col-12 sdms-mb-5 sdms-font-bold sdms-font-size-1-rem'>
						<div className='row'>
							<div className='col-4 sdms-align-left'>Taxes</div>
							<div className='col-8 sdms-align-right'>
								{priceFormatter(
									(reservationItem.tax || 0) + (reservationItem.extraTax || 0)
								)}
							</div>
						</div>
					</div>
					<div className='col-12'>
						<Separator />
					</div>
					<div className='col-12 sdms-mb-5 sdms-font-boldest sdms-font-size-1-rem'>
						<div className='row '>
							<div className='col-8 sdms-align-left'>Total</div>
							<div className='col-4 sdms-align-right'>
								{priceFormatter(
									(reservationItem.total || 0) + (reservationItem.extraTotal || 0)
								)}
							</div>
						</div>
					</div>
					<div
						className={classNames(
							'col-12 sdms-mb-5 sdms-font-size-1-rem sdms-font-boldest ',
							{
								'sdms-font-success': reservationItem.remainingDepositAmount === 0,
								'sdms-font-danger':
									reservationItem.remainingDepositAmount > 0 &&
									getItemApplicableDepositAmount(reservationItem, reservation) >
										0,
							}
						)}>
						<div className='row'>
							<div className='col-7 sdms-align-left'>Deposit Due Now</div>
							<div className='col-5 sdms-align-right'>{getDepositDueNow()}</div>
						</div>
					</div>
				</div>
				<div className='row sdms-bg-white sdms-mb-15 sdms-advanced-reservation-item-form-summary-section'>
					<div className='col-12 sdms-mb-10 sdms-font-boldest sdms-font-size-1-point-2-rem'>
						<span>{module === modules.BOOKINGS ? 'Unit' : 'Space'}</span>
					</div>
					<div className='col-12 sdms-mb-10'>
						<div className='row'>
							<FormField
								name='unit'
								inFormDesign={false}
								valRes={unitValRes}
								showValidation={swapUnit ? false : unitShowVal}
								colMd={12}
								noPermission={!hasEditUnitPermission}>
								<Selects
									options={availableUnits || []}
									placeholder={unitPlaceHolder}
									value={
										swapUnit
											? originUnit.current
											: getCurrentUnit(reservationItem, unit)
									}
									onChange={unitOnChange}
									onBlur={setUnitShowVal}
									disabled={
										pricing.isInit ||
										disabled ||
										swapUnit ||
										(reservationItem.spaceAssignments || []).length > 0
									}
								/>
							</FormField>
						</div>
					</div>
					{unit?.owner && (
						<div className='col-12 sdms-font-size-1-rem sdms-mb-10'>
							<div className='row sdms-mb-5'>
								<div className='col-4 sdms-align-left sdms-font-boldest'>Owner</div>
								<div className='col-8 sdms-align-right sdms-font-bold'>
									{unit.owner?.displayName}
								</div>
							</div>
						</div>
					)}
					<div className='col-12 sdms-mb-10'>
						{swapUnit ? (
							<div className='row'>
								<div className='col-8'>
									<Selects
										options={swappableUnits}
										placeholder={`Select a ${
											module === modules.BOOKINGS ? 'Unit' : 'Space'
										} (Required)`}
										value={reservationItem.unit}
										onChange={unitOnChange}
										onBlur={setUnitShowVal}
										disabled={disabled}
									/>
								</div>
								<div className='col-4'>
									<Button
										icon='Error-circle'
										size='sm'
										text='Cancel'
										design='default'
										onClick={() => {
											reservationItem.swap = false;
											unitOnChange({ target: { value: originUnit.current } });
											setSwapUnit(false);
										}}
									/>
								</div>
							</div>
						) : (
							<Button
								icon='Route'
								size='sm'
								wide='widest'
								text={
									isGettingSwappableUnits
										? 'Checking'
										: `Swap ${module === modules.BOOKINGS ? 'Unit' : 'Space'}s`
								}
								design='default'
								onClick={getSwappableUnits}
								disabled={
									disabled ||
									reservationItem.id === 0 ||
									(reservationItem.spaceAssignments || []).length > 0 ||
									isGettingSwappableUnits ||
									reservationItem?.product?.isVirtual
								}
								noPermission={!hasEditUnitPermission}
								isSubmitting={isGettingSwappableUnits}
							/>
						)}
					</div>
				</div>
			</Portlet.Body>
		</Portlet>
	);
};

ItemSummary.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object.isRequired,
	module: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	isItemSubmitted: PropTypes.bool,
	onFormChange: PropTypes.func,
	onProductChange: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	pricing: PropTypes.object,
	isSearching: PropTypes.bool,
};

ItemSummary.defaultProps = {
	disabled: false,
	isItemSubmitted: false,
	onFormChange: () => {},
	onProductChange: () => {},
	pricing: null,
	isSearching: false,
};

export default ItemSummary;
