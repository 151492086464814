import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';

import useModal from '../../../utils/hooks/useModal';
import { filterComponents, invoiceStatuses } from '../../../utils/constants/constants';
import { addErrorNotification } from '../../../utils/helpers/helper';
import ListContainer from '../../reusables/template/ListContainer';
import List from '../../reusables/template/List';
import Dropdown from '../../reusables/element/Dropdown';
import Button from '../../reusables/element/Button';
import { getOnlinePortalDefaultFilters } from './functions';
import apiCall from '../../../utils/helpers/apiCall';
import Portal from '../../reusables/layout/Portal';
import {
	InvoiceBalanceCell,
	InvoiceDateCell,
	InvoiceDueDateCell,
	InvoicePaidCell,
	InvoiceStatusCell,
	InvoiceTotalCell,
	InvoiceTypeCell,
} from '../../reusables/element/InvoiceListCells';
import InvoiceModal from '../../reusables/modals/InvoiceModal';
import StatementChargeModal from '../../reusables/modals/StatementChargeModal';

const IdCell = ({ data, onClick }) => {
	return (
		<span
			role='presentation'
			className='sdms-link sdms-link--dark'
			onClick={() => onClick(data)}>
			{data.invoiceId || data.statementChargeId}
		</span>
	);
};
IdCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	onClick: PropTypes.func.isRequired,
};
IdCell.defaultProps = {
	data: null,
};

const Invoices = ({ data, isActive, setActiveStep, steps, outlet, status, isOnline }) => {
	const printButtonRef = useRef();

	const printContentRef = useRef();

	const [printContent, setPrintContent] = useState('');

	const [modal, openModal, closeModal] = useModal();

	const modals = {
		INVOICE: 'invoice',
		STATEMENT_CHARGE: 'statement_charge',
	};

	const onPrintStatement = () => {
		apiCall(
			'POST',
			'onlinePortalGetPrintStatementContent',
			res => {
				setPrintContent(res.statements.join('<p style="page-break-before: always">'));
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ outletId: outlet.id },
			getOnlinePortalDefaultFilters(data.customer, outlet)
		);
	};

	const onPrintInvoice = invoice => {
		apiCall(
			'POST',
			'onlinePortalGetPrintInvoiceContent',
			res => {
				setPrintContent(res);
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ invoiceId: invoice.id, outletId: outlet.id },
			getOnlinePortalDefaultFilters(data.customer, outlet)
		);
	};

	useEffect(() => {
		if (printContent !== '' && printButtonRef.current) {
			printButtonRef.current.click();
			setTimeout(() => {
				setPrintContent('');
			}, 1000);
		}
	}, [printContent]);

	if (!isActive) return null;

	return (
		<div className='sdms-online-portal-list-container'>
			<ListContainer
				route='onlinePortalGetInvoices'
				title='Invoices'
				icon='Clipboard-list'
				staticFilters={getOnlinePortalDefaultFilters(data.customer, outlet)}
				customActions={{
					pay: invoice => {
						setActiveStep({
							index: steps.PAYMENTS.index,
							invoice,
							amount: invoice.total - invoice.amountPaid,
						});
					},
					print: onPrintInvoice,
				}}
				isTabList
				largeQuickPanel
				forms={[]}
				fluid='fluid'
				hasSearch={false}
				customFilters={[
					{
						component: filterComponents.SELECTS,
						dataName: 'onlinePortalGetInvoiceStatuses',
						fieldName: 'status.id',
						placeholder: 'All Invoices',
						displayKey: 'value',
						default: status,
						extraOptions: [{ value: 'overdue', id: 'overdue' }],
					},
				]}
				customFiltersInQuickPanel={false}
				headerActions={[
					{
						text: 'Print Statement',
						icon: 'Printer',
						design: 'dark',
						onClick: onPrintStatement,
						outline: true,
					},
					{
						text: 'Pay Balance',
						icon: 'Wallet',
						design: 'brand',
						onClick: () => {
							setActiveStep({
								index: steps.PAYMENTS.index,
								amount: data.customer.customerBalance,
							});
						},
						outline: false,
						disabled: data.customer.customerBalance <= 0,
					},
				]}>
				<List
					fluid='fluid'
					withCheckBox={false}
					checkEditable={invoice => {
						const actions = ['print'];
						if (invoice.status.value === invoiceStatuses.OPEN) actions.push('pay');

						return actions;
					}}
					withOutPortlet
					responsive='scroll'>
					<List.Col
						label='Invoice'
						cellComponent={
							<IdCell
								onClick={invoice => {
									openModal({
										open: invoice.invoiceId
											? modals.INVOICE
											: modals.STATEMENT_CHARGE,
										invoice,
									});
								}}
							/>
						}
						gridName='invoice'
					/>
					<List.Col label='Type' cellComponent={<InvoiceTypeCell />} />
					<List.Col
						label='Date'
						cellComponent={<InvoiceDateCell />}
						sortable='timeCreated'
					/>
					<List.Col label='Due Date' cellComponent={<InvoiceDueDateCell />} />
					<List.Col label='Total' cellComponent={<InvoiceTotalCell />} />
					<List.Col label='Paid' cellComponent={<InvoicePaidCell />} />
					<List.Col label='Balance' cellComponent={<InvoiceBalanceCell />} />
					<List.Col label='Status' cellComponent={<InvoiceStatusCell />} />
					<List.Col align='right' width={150}>
						<Button
							className='sdms-margin-r-15'
							design='link'
							text='Pay'
							icon='Wallet'
							size='sm'
							key='pay'
							elevate
						/>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item icon='Printer' text='Print' key='print'>
								Print
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			{modal.open === modals.INVOICE && (
				<InvoiceModal
					isOpen={modal.open === modals.INVOICE}
					data={modal.invoice}
					customer={data.customer}
					onClose={closeModal}
					outlet={outlet}
					isOnline={isOnline}
				/>
			)}
			{modal.open === modals.STATEMENT_CHARGE && (
				<StatementChargeModal
					isOpen={modal.open === modals.STATEMENT_CHARGE}
					data={modal.invoice}
					onClose={closeModal}
				/>
			)}
			<Portal>
				<div
					className='sdms--only-print'
					ref={printContentRef}
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: printContent }}
				/>
			</Portal>
			<ReactToPrint
				trigger={() => (
					<input
						type='button'
						id='printButton'
						style={{ display: 'none' }}
						ref={printButtonRef}
					/>
				)}
				content={() => printContentRef.current}
				pageStyle='@page { size: auto; } @media print { body { -webkit-print-color-adjust: exact; } }'
			/>
		</div>
	);
};

Invoices.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	isActive: PropTypes.bool.isRequired,
	setActiveStep: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	steps: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	status: PropTypes.object.isRequired,
	isOnline: PropTypes.bool.isRequired,
};

export default Invoices;
