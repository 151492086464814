import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import ExtraChargeOrderForm from './ExtraChargeOrderForm';

const ExtraChargeOrderEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='bookingExtraCharges'
			lists={[
				'itemTypes',
				'enumExtraChargeTypes',
				'productCategoryExtraCharges',
				'taxCodes',
				'accounts',
				'unitMeasures',
				'taxRates',
				'enumBookingCalculations',
				'taxAgencies',
			]}
			fields={<ExtraChargeOrderForm />}
			pageTitle={pages.marina.settings.extraCharges.allOrders.text}
			icon={pages.marina.settings.extraCharges.allOrders.icon}
			listUrl={pages.marina.settings.extraCharges.path}
		/>
	);
};

ExtraChargeOrderEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ExtraChargeOrderEdit;
