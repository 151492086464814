import React from 'react';
import PropTypes from 'prop-types';
import QuickPanel from '../../QuickPanel';

const QuickPanelForm = ({ children, status, position, onCancel, largeQuickPanel }) => {
	return (
		<QuickPanel.Wrapper
			status={status}
			onClose={onCancel}
			position={position}
			largeQuickPanel={largeQuickPanel}>
			{children}
		</QuickPanel.Wrapper>
	);
};
QuickPanelForm.propTypes = {
	children: PropTypes.node.isRequired,
	status: PropTypes.bool.isRequired,
	position: PropTypes.oneOf(['right', 'left']),
	onCancel: PropTypes.func,
	largeQuickPanel: PropTypes.bool,
};
QuickPanelForm.defaultProps = {
	position: 'right',
	onCancel: () => {},
	largeQuickPanel: false,
};

export default QuickPanelForm;
