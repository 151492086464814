import React from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';

import PrinterForm from './PrinterForm';
import FormContainer from '../../reusables/template/FormContainer';

const PrinterEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle={pages.companySettings.printers.text}
			dataName={pages.companySettings.printers.route}
			lists={['printers', 'printerWidths', 'printerTypes', 'printerLibraries']}
			fields={<PrinterForm />}
		/>
	);
};

PrinterEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default PrinterEdit;
