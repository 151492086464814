import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useField from '../../../utils/hooks/useField';
import { max, maxLength, numeric } from '../../../utils/helpers/validation';
import Button from '../../reusables/element/Button';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import Selects from '../../reusables/field/Selects';
import Toggle from '../../reusables/field/Toggle';
import Input from '../../reusables/field/Input';
import Loading from '../../reusables/template/Loading';

const GeneralSettingsForm = ({
	data,
	printers,
	printSettings,
	setIsValid,
	submit,
	isLoading,
	isSubmitted,
	submitButtonAttr,
	onFormChange,
}) => {
	const [expediter, expediterOnChange] = useField(data, 'expediter', onFormChange, [], null);

	const [posPrintLog, postPrintLogOnChange] = useField(
		data,
		'posPrintLog',
		onFormChange,
		[],
		null
	);

	const [enableAssignServer, enableAssignServerOnChange] = useField(
		data,
		'enableAssignServer',
		onFormChange,
		[],
		false
	);

	const [posCreateZeroDollarInvoice, posCreateZeroDollarInvoiceOnChange] = useField(
		data,
		'posCreateZeroDollarInvoice',
		onFormChange,
		[],
		false
	);

	const [printProdNameWithModifiers, printProdNameWithModifiersOnChange] = useField(
		data,
		'printProdNameWithModifiers',
		onFormChange
	);

	const [servicePrintDoubleSpace, servicePrintDoubleSpaceOnChange] = useField(
		data,
		'servicePrintDoubleSpace',
		onFormChange
	);

	const [hideReceiptTipLine, hideReceiptTipLineOnChange] = useField(
		data,
		'hideReceiptTipLine',
		onFormChange
	);

	const [showSeatsInput, showSeatsInputOnChange] = useField(data, 'showSeatsInput', onFormChange);

	const [
		posDiscountPresetOne,
		posDiscountPresetOneOnChange,
		posDiscountPresetOneValRes,
		posDiscountPresetOneShowVal,
		setPosDiscountPresetOneShowVal,
	] = useField(data, 'posDiscountPresetOne', onFormChange, [numeric, maxLength(), max(100)]);

	const [
		posDiscountPresetTwo,
		posDiscountPresetTwoOnChange,
		posDiscountPresetTwoValRes,
		posDiscountPresetTwoShowVal,
		setPosDiscountPresetTwoShowVal,
	] = useField(data, 'posDiscountPresetTwo', onFormChange, [numeric, maxLength(), max(100)]);

	const [
		posDiscountPresetThree,
		posDiscountPresetThreeOnChange,
		posDiscountPresetThreeValRes,
		posDiscountPresetThreeShowVal,
		setPosDiscountPresetThreeShowVal,
	] = useField(data, 'posDiscountPresetThree', onFormChange, [numeric, maxLength(), max(100)]);

	const [
		posDiscountPresetFour,
		posDiscountPresetFourOnChange,
		posDiscountPresetFourValRes,
		posDiscountPresetFourShowVal,
		setPosDiscountPresetFourShowVal,
	] = useField(data, 'posDiscountPresetFour', onFormChange, [numeric, maxLength(), max(100)]);

	const [
		posTipPresetOne,
		posTipPresetOneOnChange,
		posTipPresetOneValRes,
		posTipPresetOneShowVal,
		setPosTipPresetOneShowVal,
	] = useField(data, 'posTipPresetOne', onFormChange, [numeric, maxLength(), max(100)]);

	const [
		posTipPresetTwo,
		posTipPresetTwoOnChange,
		posTipPresetTwoValRes,
		posTipPresetTwoShowVal,
		setPosTipPresetTwoShowVal,
	] = useField(data, 'posTipPresetTwo', onFormChange, [numeric, maxLength(), max(100)]);

	const [
		posTipPresetThree,
		posTipPresetThreeOnChange,
		posTipPresetThreeValRes,
		posTipPresetThreeShowVal,
		setPosTipPresetThreeShowVal,
	] = useField(data, 'posTipPresetThree', onFormChange, [numeric, maxLength(), max(100)]);

	const [
		posTipPresetFour,
		posTipPresetFourOnChange,
		posTipPresetFourValRes,
		posTipPresetFourShowVal,
		setPosTipPresetFourShowVal,
	] = useField(data, 'posTipPresetFour', onFormChange, [numeric, maxLength(), max(100)]);

	const [requireVoidReason, requireVoidReasonOnChange] = useField(
		data,
		'requireVoidReason',
		onFormChange
	);

	const [posPromptForReceipt, posPromptForReceiptOnChange] = useField(
		data,
		'posPromptForReceipt',
		onFormChange,
		[],
		false
	);

	const [posAdvancedSecurity, posAdvancedSecurityOnChange] = useField(
		data,
		'posAdvancedSecurity',
		onFormChange,
		[],
		false
	);

	useEffect(() => {
		if (isSubmitted) {
			setPosDiscountPresetOneShowVal();
			setPosDiscountPresetTwoShowVal();
			setPosDiscountPresetThreeShowVal();
			setPosDiscountPresetFourShowVal();
			setPosTipPresetOneShowVal();
			setPosTipPresetTwoShowVal();
			setPosTipPresetThreeShowVal();
			setPosTipPresetFourShowVal();
		}
	}, [
		isSubmitted,
		setPosDiscountPresetOneShowVal,
		setPosDiscountPresetTwoShowVal,
		setPosDiscountPresetThreeShowVal,
		setPosDiscountPresetFourShowVal,
		setPosTipPresetOneShowVal,
		setPosTipPresetTwoShowVal,
		setPosTipPresetThreeShowVal,
		setPosTipPresetFourShowVal,
	]);

	useEffect(() => {
		setIsValid(
			posDiscountPresetOneValRes.isValid &&
				posDiscountPresetTwoValRes.isValid &&
				posDiscountPresetThreeValRes.isValid &&
				posDiscountPresetFourValRes.isValid &&
				posTipPresetOneValRes.isValid &&
				posTipPresetTwoValRes.isValid &&
				posTipPresetThreeValRes.isValid &&
				posTipPresetFourValRes.isValid
		);
	}, [
		posDiscountPresetOneValRes.isValid,
		posDiscountPresetTwoValRes.isValid,
		posDiscountPresetThreeValRes.isValid,
		posDiscountPresetFourValRes.isValid,
		posTipPresetOneValRes.isValid,
		posTipPresetTwoValRes.isValid,
		posTipPresetThreeValRes.isValid,
		posTipPresetFourValRes.isValid,
		setIsValid,
	]);

	return (
		<Portlet className='sdms-form' fluid='fluid' hasFrame>
			<Portlet.Body>
				<form>
					<Section title='Printer'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='expediter'
										label='Expediter'
										id={data.id}
										colMd={6}>
										<Selects
											options={printers}
											placeholder='Select expediter printer'
											value={expediter}
											onChange={expediterOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='posPrintLog'
										label='Pos Print Log'
										id={data.id}
										colMd={6}>
										<Selects
											options={printSettings}
											placeholder='Select a setting'
											value={posPrintLog}
											onChange={postPrintLogOnChange}
											displayKey='value'
										/>
									</FormField>
								</Loading>
							</FormGroup>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='printProdNameWithModifiers'
										label='Product Name With Modifiers'
										id={data.id}
										colMd={3}>
										<Toggle
											value={printProdNameWithModifiers}
											onChange={printProdNameWithModifiersOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='servicePrintDoubleSpace'
										label='Kitchen Tickets Double Spaced'
										id={data.id}
										colMd={3}>
										<Toggle
											value={servicePrintDoubleSpace}
											onChange={servicePrintDoubleSpaceOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='hideReceiptTipLine'
										label='Hide Receipt Tip Line'
										id={data.id}
										colMd={3}>
										<Toggle
											value={hideReceiptTipLine}
											onChange={hideReceiptTipLineOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='showSeatsInput'
										label='Show Seats Input'
										id={data.id}
										colMd={3}>
										<Toggle
											value={showSeatsInput}
											onChange={showSeatsInputOnChange}
										/>
									</FormField>
								</Loading>

								<Loading isLoading={isLoading}>
									<FormField
										name='posPromptForReceipt'
										label='Prompt For Receipt'
										id={data.id}
										colMd={3}>
										<Toggle
											value={posPromptForReceipt}
											onChange={posPromptForReceiptOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Other'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='posCreateZeroDollarInvoice'
										label='Create Zero Dollar Invoices'
										id={data.id}
										colMd={3}>
										<Toggle
											value={posCreateZeroDollarInvoice}
											onChange={posCreateZeroDollarInvoiceOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='enableAssignServer'
										label='Assign Servers to Tables'
										id={data.id}
										colMd={3}>
										<Toggle
											value={enableAssignServer}
											onChange={enableAssignServerOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='requireVoidReason'
										label='Require Void Reason'
										id={data.id}
										colMd={3}>
										<Toggle
											value={requireVoidReason}
											onChange={requireVoidReasonOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='posAdvancedSecurity'
										label='Advanced Security'
										id={data.id}
										colMd={3}>
										<Toggle
											value={posAdvancedSecurity}
											onChange={posAdvancedSecurityOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Discount Presets'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='posDiscountPresetOne'
										id={data.id}
										valRes={posDiscountPresetOneValRes}
										showValidation={posDiscountPresetOneShowVal}
										colMd={3}>
										<Input
											type='number'
											withOutSpin
											max='100'
											value={posDiscountPresetOne}
											onChange={posDiscountPresetOneOnChange}
											onBlur={setPosDiscountPresetOneShowVal}
											prepend='Preset 1'
											append='%'
											pattern={process.env.REACT_APP_PRICE_PATTERN}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='posDiscountPresetTwo'
										id={data.id}
										valRes={posDiscountPresetTwoValRes}
										showValidation={posDiscountPresetTwoShowVal}
										colMd={3}>
										<Input
											type='number'
											withOutSpin
											max='100'
											value={posDiscountPresetTwo}
											onChange={posDiscountPresetTwoOnChange}
											onBlur={setPosDiscountPresetTwoShowVal}
											prepend='Preset 2'
											append='%'
											pattern={process.env.REACT_APP_PRICE_PATTERN}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='posDiscountPresetThree'
										id={data.id}
										valRes={posDiscountPresetThreeValRes}
										showValidation={posDiscountPresetThreeShowVal}
										colMd={3}>
										<Input
											type='number'
											withOutSpin
											max='100'
											value={posDiscountPresetThree}
											onChange={posDiscountPresetThreeOnChange}
											onBlur={setPosDiscountPresetThreeShowVal}
											prepend='Preset 3'
											append='%'
											pattern={process.env.REACT_APP_PRICE_PATTERN}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='posDiscountPresetFour'
										id={data.id}
										valRes={posDiscountPresetFourValRes}
										showValidation={posDiscountPresetFourShowVal}
										colMd={3}>
										<Input
											type='number'
											withOutSpin
											max='100'
											value={posDiscountPresetFour}
											onChange={posDiscountPresetFourOnChange}
											onBlur={setPosDiscountPresetFourShowVal}
											prepend='Preset 4'
											append='%'
											pattern={process.env.REACT_APP_PRICE_PATTERN}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Tip Presets'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='posTipPresetOne'
										id={data.id}
										valRes={posTipPresetOneValRes}
										showValidation={posTipPresetOneShowVal}
										colMd={3}>
										<Input
											type='number'
											withOutSpin
											max='100'
											value={posTipPresetOne}
											onChange={posTipPresetOneOnChange}
											onBlur={setPosTipPresetOneShowVal}
											prepend='Preset 1'
											append='%'
											pattern={process.env.REACT_APP_PRICE_PATTERN}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='posTipPresetTwo'
										id={data.id}
										valRes={posTipPresetTwoValRes}
										showValidation={posTipPresetTwoShowVal}
										colMd={3}>
										<Input
											type='number'
											withOutSpin
											max='100'
											value={posTipPresetTwo}
											onChange={posTipPresetTwoOnChange}
											onBlur={setPosTipPresetTwoShowVal}
											prepend='Preset 2'
											append='%'
											pattern={process.env.REACT_APP_PRICE_PATTERN}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='posTipPresetThree'
										id={data.id}
										valRes={posTipPresetThreeValRes}
										showValidation={posTipPresetThreeShowVal}
										colMd={3}>
										<Input
											type='number'
											withOutSpin
											max='100'
											value={posTipPresetThree}
											onChange={posTipPresetThreeOnChange}
											onBlur={setPosTipPresetThreeShowVal}
											prepend='Preset 3'
											append='%'
											pattern={process.env.REACT_APP_PRICE_PATTERN}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='posTipPresetFour'
										id={data.id}
										valRes={posTipPresetFourValRes}
										showValidation={posTipPresetFourShowVal}
										colMd={3}>
										<Input
											type='number'
											withOutSpin
											max='100'
											value={posTipPresetFour}
											onChange={posTipPresetFourOnChange}
											onBlur={setPosTipPresetFourShowVal}
											prepend='Preset 4'
											append='%'
											pattern={process.env.REACT_APP_PRICE_PATTERN}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
GeneralSettingsForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		taxRate: PropTypes.object,
		expediter: PropTypes.object,
		enableAssignServer: PropTypes.bool,
	}),
	printers: PropTypes.arrayOf(PropTypes.object),
	printSettings: PropTypes.arrayOf(PropTypes.object),
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
};
GeneralSettingsForm.defaultProps = {
	data: {
		id: 0,
		taxRate: {},
		expediter: {},
		enableAssignServer: false,
	},
	printers: [],
	printSettings: [],
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
};

export default GeneralSettingsForm;
