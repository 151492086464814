import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { max, min, numeric, required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Input from '../../reusables/field/Input';
import Loading from '../../reusables/template/Loading';
import { numberParser } from '../../../utils/helpers/helper';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Toggle from '../../reusables/field/Toggle';

const PaymentTermForm = ({
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	isLoading,
	onFormChange,
	submitButtonAttr,
	submit,
}) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [isDateDriven, isDateDrivenOnChange] = useField(
		data,
		'isDateDriven',
		onFormChange,
		[],
		false
	);

	const [
		discountPct,
		discountPctOnChange,
		discountPctValRes,
		discountPctShowVal,
		setDiscountPctShowVal,
	] = useField(
		data,
		'discountPct',
		onFormChange,
		[required, numeric, max(100)],
		'',
		numberParser(false)
	);

	const [
		discountDay,
		discountDayOnChange,
		discountDayValRes,
		discountDayShowVal,
		setDiscountDayShowVal,
	] = useField(data, 'discountDay', onFormChange, [required, numeric], '', numberParser(false));

	const [due, dueOnChange, dueValRes, dueShowVal, setDueShowVal] = useField(
		data,
		'due',
		onFormChange,
		[required, numeric],
		'',
		numberParser(false)
	);

	const [
		dayOfDueMonth,
		dayOfDueMonthOnChange,
		dayOfDueMonthValRes,
		dayOfDueMonthShowVal,
		setDayOfDueMonthShowVal,
	] = useField(
		data,
		'dayOfDueMonth',
		onFormChange,
		[required, numeric, min(1), max(31)],
		'',
		numberParser(false)
	);

	const [
		dueNextMonthDays,
		dueNextMonthDaysOnChange,
		dueNextMonthDaysValRes,
		dueNextMonthDaysShowVal,
		setDueNextMonthDayShowVal,
	] = useField(
		data,
		'dueNextMonthDays',
		onFormChange,
		[required, numeric],
		'',
		numberParser(false)
	);
	const [
		discountDayOfMonth,
		discountDayOfMonthOnChange,
		discountDayOfMonthValRes,
		discountDayOfMonthShowVal,
		setDiscountDayOfMonthShowVal,
	] = useField(
		data,
		'discountDayOfMonth',
		onFormChange,
		[required, numeric, min(1), max(31)],
		'',
		numberParser(false)
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setDiscountPctShowVal();
			setDiscountDayShowVal();
			setDueShowVal();
			setDayOfDueMonthShowVal();
			setDueNextMonthDayShowVal();
			setDiscountDayOfMonthShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setDiscountPctShowVal,
		setDiscountDayShowVal,
		setDueShowVal,
		setDayOfDueMonthShowVal,
		setDueNextMonthDayShowVal,
		setDiscountDayOfMonthShowVal,
	]);

	useEffect(() => {
		const _isValid = nameValRes.isValid && discountPctValRes.isValid;

		if (isDateDriven)
			setIsValid(
				_isValid &&
					dayOfDueMonthValRes.isValid &&
					dueNextMonthDaysValRes.isValid &&
					discountDayOfMonthValRes.isValid
			);
		else setIsValid(_isValid && discountDayValRes.isValid && dueValRes.isValid);
	}, [
		nameValRes.isValid,
		discountPctValRes.isValid,
		discountDayValRes.isValid,
		dueValRes.isValid,
		dayOfDueMonthValRes.isValid,
		dueNextMonthDaysValRes.isValid,
		discountDayOfMonthValRes.isValid,
		setIsValid,
		isDateDriven,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.accounting.default.text,
				path: pages.accounting.dashboard.path,
			},
			{
				title: pages.accounting.settings.text,
				path: pages.accounting.settings.path,
			},
			{
				title: pages.accounting.settings.paymentTerms.text,
				path: pages.accounting.settings.paymentTerms.path,
			},
			{ title: name || `New ${pages.accounting.settings.paymentTerms.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.accounting.settings.paymentTerms.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='Payment Terms Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='isDateDriven'
								label='Is Date Driven'
								id={data.id}
								colMd={6}>
								<Toggle
									value={isDateDriven}
									onChange={isDateDrivenOnChange}
									disabled={data.id !== 0 || !name}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='discountPct'
								label='Discount Percent'
								description="Discount applied when paid before 'Discount Day'"
								id={data.id}
								valRes={discountPctValRes}
								showValidation={discountPctShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='Discount Percent (Required)'
									value={discountPct.toString()}
									onChange={discountPctOnChange}
									onBlur={setDiscountPctShowVal}
									append='%'
								/>
							</FormField>
						</Loading>
						{isDateDriven ? (
							<>
								<Loading isLoading={isLoading}>
									<FormField
										name='dayOfDueMonth'
										label='Day of Due Month'
										id={data.id}
										valRes={dayOfDueMonthValRes}
										showValidation={dayOfDueMonthShowVal}
										colMd={6}>
										<Input
											type='text'
											placeholder='Day of Due Month (Required)'
											value={dayOfDueMonth.toString()}
											onChange={dayOfDueMonthOnChange}
											onBlur={setDayOfDueMonthShowVal}
											pattern={process.env.REACT_APP_INTEGER_PATTERN}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='dueNextMonthDays'
										label='Due Next Month Days'
										id={data.id}
										valRes={dueNextMonthDaysValRes}
										showValidation={dueNextMonthDaysShowVal}
										colMd={6}>
										<Input
											type='text'
											placeholder='Due Next Month Days (Required)'
											value={dueNextMonthDays.toString()}
											onChange={dueNextMonthDaysOnChange}
											onBlur={setDueNextMonthDayShowVal}
											pattern={process.env.REACT_APP_INTEGER_PATTERN}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='discountDayOfMonth'
										label='Discount Day of Month'
										id={data.id}
										valRes={discountDayOfMonthValRes}
										showValidation={discountDayOfMonthShowVal}
										colMd={6}>
										<Input
											type='text'
											placeholder='Discount Day of Month (Required)'
											value={discountDayOfMonth.toString()}
											onChange={discountDayOfMonthOnChange}
											onBlur={setDiscountDayOfMonthShowVal}
											pattern={process.env.REACT_APP_INTEGER_PATTERN}
										/>
									</FormField>
								</Loading>
							</>
						) : (
							<>
								<Loading isLoading={isLoading}>
									<FormField
										name='discountDay'
										label='Discount Day'
										description='Number of days until before Due Date to apply a discount'
										id={data.id}
										valRes={discountDayValRes}
										showValidation={discountDayShowVal}
										colMd={6}>
										<Input
											type='text'
											placeholder='Discount Day (Required)'
											value={discountDay.toString()}
											onChange={discountDayOnChange}
											onBlur={setDiscountDayShowVal}
											pattern={process.env.REACT_APP_INTEGER_PATTERN}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='due'
										label='Due'
										id={data.id}
										valRes={dueValRes}
										showValidation={dueShowVal}
										colMd={6}>
										<Input
											type='text'
											placeholder='Due (Required)'
											value={due.toString()}
											onChange={dueOnChange}
											onBlur={setDueShowVal}
											pattern={process.env.REACT_APP_INTEGER_PATTERN}
										/>
									</FormField>
								</Loading>
							</>
						)}
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
PaymentTermForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		discountPct: PropTypes.number,
		discountDay: PropTypes.number,
		due: PropTypes.number,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
PaymentTermForm.defaultProps = {
	data: {
		id: 0,
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default PaymentTermForm;
