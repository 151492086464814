import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import ModifierPrefixForm from './ModifierPrefixForm';
import pages from '../../pages';

const ModifierPrefixEdit = ({ match }) => {
	return (
		<FormContainer
			dataId={match.params.id}
			dataName='modifierPrefixes'
			fields={<ModifierPrefixForm />}
			pageTitle={pages.pos.modifierGroups.text}
			icon={pages.pos.modifierGroups.icon}
		/>
	);
};

ModifierPrefixEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ModifierPrefixEdit;
