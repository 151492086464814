import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import { useWindowSize } from '../../../utils/helpers/helper';
import usePages from '../../../utils/hooks/usePages';
import { mediaBreakpoint } from '../../../utils/constants/constants';

import Portlet from '../layout/Portlet';
import SelectableList from '../template/SelectableList';
import Button from '../element/Button';
import Portal from '../layout/Portal';
import QuickPanel from '../../QuickPanel';

const SpaceGroupModalContent = ({ icon, spaceGroups, onSelect, onClose }) => {
	return (
		<Portlet className='sdms-list-layout' fluid='fluid' isLast everyTimeFluid>
			<Portlet.Head wrapMaxSize='md'>
				<Portlet.HeadLabel portletIcon={icon}>
					<h3 className='sdms-portlet__head-title'>Select a Space Group</h3>
				</Portlet.HeadLabel>
			</Portlet.Head>
			<SelectableList withOutPortlet data={spaceGroups} onClick={onSelect} withImage>
				<SelectableList.Col
					width={65}
					className='sdms-cursor--pointer'
					label='#'
					cellData='id'
				/>
				<SelectableList.Col className='sdms-cursor--pointer' label='Name' cellData='name' />
			</SelectableList>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
SpaceGroupModalContent.propTypes = {
	icon: PropTypes.string.isRequired,
	spaceGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
	onClose: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
};

const SpaceGroupModal = ({ spaceGroups, isOpen, onClose, onSelect }) => {
	const windowSize = useWindowSize();

	const pages = usePages();

	if (windowSize.width < mediaBreakpoint.MD) {
		return (
			<QuickPanel
				status={isOpen}
				setStatus={onClose}
				title={pages.marina.settings.spaceGroups.text}
				icon={pages.marina.settings.spaceGroups.icon}
				portletClass='sdms-list-layout'>
				<SpaceGroupModalContent
					icon={pages.marina.settings.spaceGroups.icon}
					spaceGroups={spaceGroups}
					onSelect={onSelect}
					onClose={onClose}
				/>
			</QuickPanel>
		);
	}
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				closeOnEscape={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
					minHeight: '480px',
					height: '50%',
				}}>
				<SpaceGroupModalContent
					icon={pages.marina.settings.spaceGroups.icon}
					spaceGroups={spaceGroups}
					onSelect={onSelect}
					onClose={onClose}
				/>
			</Popup>
		</Portal>
	);
};

SpaceGroupModal.propTypes = {
	spaceGroups: PropTypes.arrayOf(PropTypes.object),
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onSelect: PropTypes.func,
};

SpaceGroupModal.defaultProps = {
	spaceGroups: [],
	isOpen: false,
	onClose: () => {},
	onSelect: () => {},
};

export default SpaceGroupModal;
