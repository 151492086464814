import { useEffect, useState } from 'react';

const useTooltipPlace = (defaultPlace, tooltipRef, tooltipHeight = 400) => {
	const [place, setPlace] = useState(defaultPlace);

	useEffect(() => {
		if (
			tooltipRef.current &&
			tooltipRef.current.getBoundingClientRect().top + tooltipHeight > window.innerHeight &&
			place === 'bottom'
		) {
			setPlace('top');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tooltipRef]);

	return place;
};

export default useTooltipPlace;
