import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

import Terminal from '../../modules/terminal/Terminal';
import Alert from '../element/Alert';
import apiCall from '../../../utils/helpers/apiCall';
import logoLoading from '../../../assets/img/logoLoading.svg';
import { addErrorNotification } from '../../../utils/helpers/helper';

const TerminalModal = ({ register, reservationItem, customer, vessel, productGrid, onBack }) => {
	const [isOpen, setIsOpen] = useState(false);

	const [isLoading, setIsLoading] = useState(true);

	const [error, setError] = useState('');

	const onOpen = () => {
		setIsLoading(true);
		apiCall(
			'POST',
			'registerOpen',
			() => {
				setIsOpen(true);
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{
				registers: [register.id],
				openingCashes: [0],
			}
		);
	};

	const getContent = () => {
		if (error)
			return (
				<div className='col-12 sdms-pt20'>
					<Alert solid icon='Error-circle' design='danger'>
						{error}
					</Alert>
				</div>
			);

		if (isLoading) return <ReactSVG src={logoLoading} className='svg-container logo-loading' />;

		if (!isOpen)
			return (
				<div className='col-12 sdms-pt20 sdms-font-size-1-rem sdms-font-bold'>
					<Alert solid icon='Warning-2' design='info'>
						Register{' '}
						<span className='sdms-font-bolder sdms-font-secondary'>
							{register.name}
						</span>{' '}
						closed.{' '}
						<span
							className='sdms-font-bolder sdms-link'
							role='presentation'
							onClick={onBack}>
							Back to reservation
						</span>{' '}
						or{' '}
						<span
							className='sdms-font-bolder sdms-link'
							role='presentation'
							onClick={onOpen}>
							open it
						</span>
						.
					</Alert>
				</div>
			);

		return (
			<Terminal
				id={register.id}
				reservationItem={reservationItem}
				onBackToReservation={onBack}
				customer={customer}
				vessel={vessel}
				productGrid={productGrid}
				inReservationForm
			/>
		);
	};

	useEffect(() => {
		apiCall(
			'GET',
			'registers',
			res => {
				setIsOpen(res.currentRegisterBatch !== null);
				setIsLoading(false);
			},
			err => {
				setError(err.toString());
				setIsLoading(false);
			},
			register.id
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='sdms-quick-panel sdms-quick-panel--right sdms-quick-panel--on sdms-quick-panel-x-large sdms-advanced-reservation-terminal-quick-panel'>
			{getContent()}
		</div>
	);
};

TerminalModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	register: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	vessel: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	productGrid: PropTypes.object,
	onBack: PropTypes.func,
};

TerminalModal.defaultProps = {
	register: null,
	reservationItem: null,
	customer: null,
	vessel: null,
	productGrid: null,
	onBack: () => {},
};

export default TerminalModal;
