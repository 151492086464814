import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SlideDown } from 'react-slidedown';

import useFeet from '../../../../../utils/hooks/useFeet';
import useModal from '../../../../../utils/hooks/useModal';
import useField from '../../../../../utils/hooks/useField';
import apiCall, { parseData } from '../../../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	addSuccessNotification,
	numberParser,
	parseDatePickerChange,
	parseDatePickerValue,
	phoneNumberParser,
} from '../../../../../utils/helpers/helper';
import {
	email as emailValidation,
	maxLength,
	phoneNumber,
	required,
} from '../../../../../utils/helpers/validation';

import FormSection from '../../../layout/FormSection';
import FormSectionMoreLink from '../../../element/FormSectionMoreLink';
import Button from '../../../element/Button';
import FormField from '../../../template/FormField';
import Input from '../../../field/Input';
import LengthInputGroup from '../../../field/LengthInputGroup';
import Selects from '../../../field/Selects';
import DatePicker from '../../../field/DatePicker';
import Toggle from '../../../field/Toggle';
import VehicleModal from '../../../modals/VehicleModal';

const modals = {
	VEHICLE: 'vehicle',
};

const Vehicle = ({
	sectionRef,
	reservationItem,
	customer,
	vehicle,
	disabled,
	enumRvTypes,
	enumVehicleMakes,
	onFormChange,
	onVehicleChange,
	setSectionIsValid,
}) => {
	const data = useRef(vehicle);

	const [showMore, setShowMore] = useState(false);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [isValid, setIsValid] = useState(false);

	const [modal, openModal, closeModal] = useModal();

	const [isEditing, setIsEditing] = useState(false);

	const fieldsDisabled = useMemo(() => {
		if (isEditing) return false;

		return disabled || !!data.current.id;
	}, [disabled, isEditing]);

	const display = useMemo(() => {
		return {
			clear: !isEditing && !!data.current.id,
			create: !isEditing,
			edit: !!data.current.id && !isEditing,
			save: isEditing,
			cancel: isEditing,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditing]);

	useEffect(() => {
		setShowMore(!data.current.id || isEditing);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditing]);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data.current,
		'name',
		onFormChange,
		[required]
	);

	const [loa, loaOnChange] = useField(data.current, 'loa', onFormChange, [], 0, numberParser());

	const [loaFt, setLoaFt, loaIn, setLoaIn] = useFeet(
		data.current.loa,
		loaOnChange,
		false,
		data.current.loa
	);

	const [beam, beamOnChange] = useField(
		data.current,
		'beam',
		onFormChange,
		[],
		0,
		numberParser()
	);

	const [beamFt, setBeamFt, beamIn, setBeamIn] = useFeet(
		data.current.beam,
		beamOnChange,
		false,
		data.current.beam
	);

	const [height, heightOnChange] = useField(
		data.current,
		'height',
		onFormChange,
		[],
		0,
		numberParser()
	);

	const [heightFt, setHeightFt, heightIn, setHeightIn] = useFeet(
		vehicle.height,
		heightOnChange,
		false,
		data.current.height
	);

	const [rvType, rvTypeOnChange] = useField(data.current, 'rvType', onFormChange, [], null);

	const [make, makeOnChange] = useField(data.current, 'make', onFormChange, [], null);

	const [model, modelOnChange] = useField(data.current, 'model', onFormChange);

	const [year, yearOnChange] = useField(data.current, 'year', onFormChange);

	const [hullColor, hullColorOnChange] = useField(data.current, 'hullColor', onFormChange);

	const [licensePlateNumber, licensePlateNumberOnChange] = useField(
		data.current,
		'licensePlateNumber',
		onFormChange
	);

	const [serialNumber, serialNumberOnChange] = useField(
		data.current,
		'serialNumber',
		onFormChange
	);

	const [isMotorized, isMotorizedOnChange] = useField(
		data.current,
		'isMotorized',
		onFormChange,
		[],
		false
	);

	const [insuranceCompany, insuranceCompanyOnChange] = useField(
		data.current,
		'insuranceCompany',
		onFormChange
	);

	const [insurancePolicyNumber, insurancePolicyNumberOnChange] = useField(
		data.current,
		'insurancePolicyNumber',
		onFormChange
	);

	const [insuranceExpirationDate, insuranceExpirationDateOnChange] = useField(
		data.current,
		'insuranceExpirationDate',
		onFormChange,
		[],
		null
	);

	const [additionalInsurance, additionalInsuranceOnChange] = useField(
		data.current,
		'additionalInsurance',
		onFormChange,
		[],
		false
	);

	const [coiReceived, coiReceivedOnChange] = useField(
		data.current,
		'coiReceived',
		onFormChange,
		[],
		false
	);

	const [agentName, agentNameOnChange] = useField(data.current, 'agentName', onFormChange);

	const [
		agentPhone,
		agentPhoneOnChange,
		agentPhoneValRes,
		agentPhoneShowVal,
		setAgentPhoneShowVal,
	] = useField(
		data.current,
		'agentPhone',
		onFormChange,
		[(maxLength(), phoneNumber)],
		'',
		phoneNumberParser
	);

	const [
		agentEmail,
		agentEmailOnChange,
		agentEmailValRes,
		agentEmailShowVal,
		setAgentEmailShowVal,
	] = useField(data.current, 'agentEmail', onFormChange, [maxLength(), emailValidation]);

	const onReset = (_vehicle = null) => {
		data.current.id = _vehicle?.id || 0;
		nameOnChange({ target: { value: _vehicle?.name || '' } });
		loaOnChange({ target: { value: _vehicle?.loa || 0 } });
		beamOnChange({ target: { value: _vehicle?.beam || 0 } });
		heightOnChange({ target: { value: _vehicle?.height || 0 } });
		rvTypeOnChange({ target: { value: _vehicle?.rvType || null } });
		makeOnChange({ target: { value: _vehicle?.make || null } });
		modelOnChange({ target: { value: _vehicle?.model || '' } });
		yearOnChange({ target: { value: _vehicle?.year || '' } });
		hullColorOnChange({ target: { value: _vehicle?.hullColor || '' } });
		licensePlateNumberOnChange({ target: { value: _vehicle?.licensePlateNumber || '' } });
		serialNumberOnChange({ target: { value: _vehicle?.serialNumber || '' } });
		isMotorizedOnChange({ target: { value: _vehicle?.isMotorized || false } });
		insuranceCompanyOnChange({ target: { value: _vehicle?.insuranceCompany || '' } });
		insurancePolicyNumberOnChange({ target: { value: _vehicle?.insurancePolicyNumber || '' } });
		insuranceExpirationDateOnChange({
			target: { value: _vehicle?.insuranceExpirationDate || null },
		});
		additionalInsuranceOnChange({ target: { value: _vehicle?.additionalInsurance || false } });
		coiReceivedOnChange({ target: { value: _vehicle?.coiReceived || false } });
		agentNameOnChange({ target: { value: _vehicle?.agentName || '' } });
		agentPhoneOnChange({ target: { value: _vehicle?.agentPhone || '' } });
		agentEmailOnChange({ target: { value: _vehicle?.agentEmail || '' } });
	};
	const onCreate = () => {
		if (data.current.id) {
			onReset();
			setIsEditing(true);
		} else onSave();
	};

	const onSave = () => {
		setIsSubmitted(true);

		if (!isValid) return;

		setIsSubmitting(true);

		const formData = JSON.parse(
			JSON.stringify({
				customer,
				name,
				loa,
				beam,
				height,
				rvType,
				make,
				model,
				year,
				hullColor,
				licensePlateNumber,
				serialNumber,
				isMotorized,
				insuranceCompany,
				insurancePolicyNumber,
				insuranceExpirationDate,
				additionalInsurance,
				coiReceived,
				agentName,
				agentPhone,
				agentEmail,
			})
		);

		// Parse vehicle for api call.
		Object.keys(formData).forEach(field => {
			formData[field] = parseData(formData[field]);
		});

		apiCall(
			data.current.id ? 'PUT' : 'POST',
			'vehicles',
			res => {
				addSuccessNotification(
					`Vehicle successfully ${data.current.id ? 'updated' : 'created'}`
				);
				setIsSubmitting(false);
				onVehicleChange(res);
				setIsEditing(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsSubmitting(false);
			},
			data.current.id || '',
			formData
		);
	};

	const onCancel = () => {
		if (reservationItem?.vehicle?.id) onReset(reservationItem.vehicle);

		setIsEditing(false);
	};

	useEffect(() => {
		if (isSubmitted) setNameShowVal();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setIsValid(nameValRes.isValid), [nameValRes]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setSectionIsValid(!isEditing), [isEditing]);

	return (
		<>
			<FormSection
				sectionRef={sectionRef}
				title='VEHICLE'
				icon='Caravan'
				headerActionLabel={disabled ? '' : 'Existing Vehicle'}
				onHeaderActionClick={() => openModal({ open: modals.VEHICLE })}>
				<div className='sdms-advanced-reservation-item-form-vehicle'>
					<div className='row sdms-mb-15'>
						<FormField
							name='name'
							label='Name'
							valRes={nameValRes}
							showValidation={nameShowVal}
							inFormDesign={false}
							colMd={4}>
							<Input
								type='text'
								placeholder='Name (Required)'
								value={name}
								onChange={nameOnChange}
								onBlur={setNameShowVal}
								disabled={fieldsDisabled}
							/>
						</FormField>
						<FormField
							name='loaFt'
							label='Length Overall'
							inFormDesign={false}
							colMd={4}>
							<LengthInputGroup
								ft={loaFt}
								ftOnChange={setLoaFt}
								inch={loaIn}
								inchOnChange={setLoaIn}
								placeHolder='Length Overall'
								disabled={fieldsDisabled}
							/>
						</FormField>
						<FormField name='beamFt' label='Beam' inFormDesign={false} colMd={4}>
							<LengthInputGroup
								ft={beamFt}
								ftOnChange={setBeamFt}
								inch={beamIn}
								inchOnChange={setBeamIn}
								placeHolder='Beam'
								disabled={fieldsDisabled}
							/>
						</FormField>
						<FormField name='heightFt' label='Height' inFormDesign={false} colMd={4}>
							<LengthInputGroup
								ft={heightFt}
								ftOnChange={setHeightFt}
								inch={heightIn}
								inchOnChange={setHeightIn}
								placeHolder='Height'
								disabled={fieldsDisabled}
							/>
						</FormField>
					</div>
					<SlideDown>
						{showMore ? (
							<div className='row sdms-mb-15'>
								<FormField
									name='rvType'
									label='RV Type'
									inFormDesign={false}
									colMd={4}>
									<Selects
										options={enumRvTypes}
										placeholder='RV type (Required)'
										value={rvType}
										onChange={rvTypeOnChange}
										displayKey='value'
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField name='make' label='Make' inFormDesign={false} colMd={4}>
									<Selects
										options={enumVehicleMakes}
										placeholder='Make'
										value={make}
										onChange={makeOnChange}
										displayKey='value'
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='model'
									label='Model'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Model'
										value={model}
										onChange={modelOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField name='year' label='Year' inFormDesign={false} colMd={4}>
									<Input
										type='number'
										withOutSpin
										placeholder='Year'
										value={year}
										onChange={yearOnChange}
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='hullColor'
									label='Hull Color'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Hull Color'
										value={hullColor}
										onChange={hullColorOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='licensePlateNumber'
									label='License Plate Number'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='License Plate Number'
										value={licensePlateNumber}
										onChange={licensePlateNumberOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='serialNumber'
									label='Serial Number'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Serial Number'
										value={serialNumber}
										onChange={serialNumberOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='isMotorized'
									label='Motorized'
									inFormDesign={false}
									colMd={4}>
									<Toggle
										value={isMotorized}
										onChange={isMotorizedOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='insuranceCompany'
									label='Insurance Company'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Insurance Company'
										value={insuranceCompany}
										onChange={insuranceCompanyOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='policyNumber'
									label='Insurance Policy Number'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Insurance Policy Number'
										value={insurancePolicyNumber}
										onChange={insurancePolicyNumberOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='insuranceExpirationDate'
									label='Insurance Expiration Date'
									inFormDesign={false}
									colMd={4}>
									<DatePicker
										id='insuranceExpirationDate'
										type='calendar'
										placeholder='Insurance Expiration Date'
										value={parseDatePickerValue(insuranceExpirationDate)}
										onChange={e =>
											insuranceExpirationDateOnChange({
												target: {
													value: parseDatePickerChange(
														e.target.value,
														insuranceExpirationDate
													),
												},
											})
										}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='agentName'
									label='Agent Name'
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Agent Name'
										value={agentName}
										onChange={agentNameOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='agentPhone'
									label='Agent Phone'
									inFormDesign={false}
									colMd={4}
									valRes={agentPhoneValRes}
									showValidation={agentPhoneShowVal}>
									<Input
										type='text'
										placeholder='(555) 555-5555'
										value={agentPhone}
										onChange={agentPhoneOnChange}
										onBlur={setAgentPhoneShowVal}
										mask={process.env.REACT_APP_PHONE_FORMAT}
										xAutoCompleteType='phone-national'
										autoComplete='phone-national'
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='agentEmail'
									label='Agent Email'
									valRes={agentEmailValRes}
									showValidation={agentEmailShowVal}
									inFormDesign={false}
									colMd={4}>
									<Input
										type='text'
										placeholder='Agent Email'
										value={agentEmail}
										onChange={agentEmailOnChange}
										onBlur={setAgentEmailShowVal}
										xAutoCompleteType='email'
										autoComplete='email'
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='coiReceived'
									label='COI Received'
									inFormDesign={false}
									colMd={2}>
									<Toggle
										value={coiReceived}
										onChange={coiReceivedOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
								<FormField
									name='additionalInsurance'
									label='Additional Insured'
									inFormDesign={false}
									colMd={2}>
									<Toggle
										value={additionalInsurance}
										onChange={additionalInsuranceOnChange}
										disabled={fieldsDisabled}
									/>
								</FormField>
							</div>
						) : null}
					</SlideDown>
					<div className='row sdms-mb-15 sdms-row-align-items'>
						<div className='col-auto'>
							<FormSectionMoreLink
								text='Type, Make, Model & More'
								isOpen={showMore}
								onClick={() => setShowMore(!showMore)}
							/>
						</div>
						<div className='col sdms-flex-end sdms-flex'>
							{display.clear && (
								<Button
									className='sdms-mr-10'
									design='default'
									icon='Error-circle'
									size='sm'
									text='Clear'
									onClick={() => onVehicleChange(null)}
									disabled={disabled || isSubmitting}
								/>
							)}
							{display.edit && (
								<Button
									design='default'
									icon='Edit'
									size='sm'
									text='Edit'
									onClick={() => setIsEditing(true)}
									disabled={disabled || isSubmitting}
									isSubmitting={isSubmitting}
								/>
							)}
							{display.create && (
								<Button
									className='sdms-ml-10'
									design='default'
									icon='Caravan'
									size='sm'
									text={isSubmitting ? 'Creating' : 'Create New Vehicle'}
									onClick={onCreate}
									disabled={disabled || isSubmitting}
									isSubmitting={isSubmitting}
								/>
							)}
							{display.cancel && (
								<Button
									className='sdms-mr-10'
									design='default'
									icon='Error-circle'
									size='sm'
									text='Cancel'
									onClick={onCancel}
									disabled={isSubmitting}
								/>
							)}
							{display.save && (
								<Button
									design='info'
									icon='Save'
									size='sm'
									text={isSubmitting ? 'Saving' : 'Save'}
									onClick={onSave}
									disabled={isSubmitting}
									isSubmitting={isSubmitting}
								/>
							)}
						</div>
					</div>
				</div>
			</FormSection>
			<VehicleModal
				isOpen={modal.open === modals.VEHICLE}
				onClose={closeModal}
				defaultVehicle={vehicle.id ? vehicle : null}
				onSelect={v => {
					onVehicleChange(v);
					closeModal();
				}}
				defaultSearchText={customer?.displayName || ''}
				hasForm={false}
			/>
		</>
	);
};

Vehicle.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	sectionRef: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	vehicle: PropTypes.object,
	disabled: PropTypes.bool,
	enumRvTypes: PropTypes.arrayOf(PropTypes.object),
	enumVehicleMakes: PropTypes.arrayOf(PropTypes.object),
	onFormChange: PropTypes.func,
	onVehicleChange: PropTypes.func,
	setSectionIsValid: PropTypes.func,
};

Vehicle.defaultProps = {
	sectionRef: null,
	reservationItem: null,
	customer: null,
	vehicle: {},
	disabled: false,
	enumRvTypes: [],
	enumVehicleMakes: [],
	onFormChange: () => {},
	onVehicleChange: () => {},
	setSectionIsValid: () => {},
};

export default Vehicle;
