import React, { createRef, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import update from 'immutability-helper';
import moment from 'moment';
import Timeline from 'react-calendar-timeline/lib/lib/Timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import { DateHeader, TimelineHeaders, SidebarHeader } from 'react-calendar-timeline';
import classNames from 'classnames';

import { convertDateToUTC, generateId, numberFormat } from '../../../utils/helpers/helper';
import useField from '../../../utils/hooks/useField';
import { required, numeric } from '../../../utils/helpers/validation';
import { pricingTypes } from '../../../utils/constants/constants';

import Button from '../../reusables/element/Button';
import Portal from '../../reusables/layout/Portal';
import Portlet from '../../reusables/layout/Portlet';
import Selects from '../../reusables/field/Selects';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Toggle from '../../reusables/field/Toggle';
import Dropdown from '../../reusables/element/Dropdown';
import FormGroup from '../../reusables/layout/FormGroup';
import DatePicker from '../../reusables/field/DatePicker';
import MultiSelect from '../../reusables/element/MultiSelect';
import Section from '../../reusables/layout/Section';
import Alert from '../../reusables/element/Alert';

const CalendarModal = ({ onClose, calendars, calendarYears, onSubmit, open }) => {
	const [customCalendar, setCustomCalendar] = useState(null);

	const year = calendarYears.length
		? calendarYears[calendarYears.length - 1].year + 1
		: new Date().getFullYear();

	return (
		<Portal>
			<Popup
				open={open}
				onClose={onClose}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
				}}>
				<Portlet>
					<Portlet.Head>
						<Portlet.HeadLabelTitle>Add Year to Calendar</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<FormGroup>
							<FormField name='year' label='Year' col={6}>
								<Input onChange={() => {}} type='text' value={year} disabled />
							</FormField>
							<FormField
								name='customCalendar'
								label='Custom Calendar'
								description='Select a custom calendar. Leave blank for standard calendar'
								col={6}>
								<Selects
									options={calendars.filter(c => c.year === year)}
									placeholder='Custom Calendar'
									value={customCalendar}
									onChange={e => setCustomCalendar(e.target.value)}
									displayKey='name'
								/>
							</FormField>
						</FormGroup>
					</Portlet.Body>
					<Portlet.Foot className='sdms-align-left' tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
							/>
						</div>
						<div className='col-auto'>
							<Button
								label='brand'
								icon='Done-circle'
								text='Ok'
								size='sm'
								onClick={() => {
									onSubmit({
										year,
										calendar: customCalendar,
									});
									setCustomCalendar({});
									onClose();
								}}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};
CalendarModal.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	calendars: PropTypes.arrayOf(PropTypes.object),
	calendarYears: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool,
};
CalendarModal.defaultProps = {
	onClose: () => {},
	onSubmit: () => {},
	calendars: [],
	calendarYears: [],
	open: false,
};

const RatePlanModal = ({
	onClose,
	onSubmit,
	ratePlans,
	selectedRatePlans,
	open,
	productBookingPeriodId,
}) => {
	const [ratePlan, setRatePlan] = useState(null);

	return (
		<Portal>
			<Popup
				open={open}
				onClose={onClose}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
				}}>
				<Portlet>
					<Portlet.Head>
						<Portlet.HeadLabelTitle>Add Rate Plan</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<FormField
							name='ratePlan'
							label='Rate Plan'
							description='Select Rate Plan'
							col={6}>
							<Selects
								options={ratePlans.filter(
									rp =>
										selectedRatePlans.findIndex(srp => srp.id === rp.id) ===
											-1 && rp.bookingPeriod.id === productBookingPeriodId
								)}
								placeholder='Rate Plan'
								value={ratePlan}
								onChange={e => setRatePlan(e.target.value)}
								displayKey='internalName'
							/>
						</FormField>
					</Portlet.Body>
					<Portlet.Foot className='sdms-align-left' tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
							/>
						</div>
						<div className='col-auto'>
							<Button
								label='brand'
								icon='Done-circle'
								text='Ok'
								size='sm'
								disabled={!ratePlan}
								onClick={() => {
									onSubmit(ratePlan);
									setRatePlan({});
									onClose();
								}}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};
RatePlanModal.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool,
	selectedRatePlans: PropTypes.arrayOf(PropTypes.object),
	productBookingPeriodId: PropTypes.number,
};
RatePlanModal.defaultProps = {
	onClose: () => {},
	onSubmit: () => {},
	ratePlans: [],
	open: false,
	selectedRatePlans: [],
	productBookingPeriodId: 0,
};

const EnableDisableRatePlanModal = ({ isOpen, enumDays, onClose, onSubmit, dateRange }) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				onClose={onClose}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
				}}>
				<EnableDisableRatePlanModalContent
					onClose={onClose}
					enumDays={enumDays}
					onSubmit={onSubmit}
					dateRange={dateRange}
				/>
			</Popup>
		</Portal>
	);
};
EnableDisableRatePlanModal.propTypes = {
	isOpen: PropTypes.bool,
	enumDays: PropTypes.arrayOf(PropTypes.object),
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	dateRange: PropTypes.shape({
		minDate: PropTypes.object,
		maxDate: PropTypes.object,
	}),
};
EnableDisableRatePlanModal.defaultProps = {
	isOpen: false,
	enumDays: [],
	onClose: () => {},
	onSubmit: () => {},
	dateRange: { minDate: null, maxDate: null },
};

const EnableDisableRatePlanModalContent = ({ enumDays, onClose, onSubmit, dateRange }) => {
	const [daysOfWeek, setDaysOfWeek] = useState([]);
	const [rateEnabled, setRateEnabled] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const [
		fromDate,
		fromDateOnChange,
		fromDateValRes,
		fromDateShowVal,
		setFromDateShowVal,
	] = useField({}, '', () => {}, [required], null, null);
	const [toDate, toDateOnChange, toDateValRes, toDateShowVal, setToDateShowVal] = useField(
		{},
		'',
		() => {},
		[required],
		null,
		null
	);

	useEffect(() => {
		if (isSubmitted) {
			setFromDateShowVal();
			setToDateShowVal();
		}
	}, [isSubmitted, setFromDateShowVal, setToDateShowVal]);

	useEffect(() => {
		setIsValid(fromDateValRes.isValid && toDateValRes.isValid);
	}, [fromDateValRes.isValid, toDateValRes.isValid, setIsValid]);
	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Settings#3'>
					Enable/Disable Rate Plan
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<FormGroup>
					<FormField
						name='fromDate'
						label='From Date'
						description='Select Date'
						valRes={fromDateValRes}
						showValidation={fromDateShowVal}
						col={6}>
						<DatePicker
							id='fromDate'
							type='calendar'
							value={fromDate}
							onChange={fromDateOnChange}
							onBlur={setFromDateShowVal}
							minDate={dateRange.minDate}
							maxDate={dateRange.maxDate}
						/>
					</FormField>
					<FormField
						name='toDate'
						label='To Date'
						description='Select Date'
						valRes={toDateValRes}
						showValidation={toDateShowVal}
						col={6}>
						<DatePicker
							id='toDate'
							type='calendar'
							value={toDate}
							onChange={toDateOnChange}
							onBlur={setToDateShowVal}
							minDate={fromDate || dateRange.minDate}
							maxDate={dateRange.maxDate}
						/>
					</FormField>
					<FormField name='rateEnabled' label='Rate Enabled' col={6}>
						<Toggle
							value={rateEnabled}
							onChange={() => {
								setRateEnabled(!rateEnabled);
							}}
						/>
					</FormField>
					<FormField name='daysOfWeek' label='Select Days of Week' col={12}>
						<MultiSelect
							data={enumDays}
							titleProp='value'
							value={daysOfWeek}
							onChange={e => {
								setDaysOfWeek(e.target.value);
							}}
							name='checkInDays'
						/>
					</FormField>
				</FormGroup>
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='brand'
						icon='Done-circle'
						text='Ok'
						size='sm'
						onClick={() => {
							setIsSubmitted(true);
							if (!isValid) return;
							onSubmit({
								daysOfWeek,
								rateEnabled,
								fromDate: moment(fromDate)
									.utcOffset(0)
									.hour(0)
									.minute(0)
									.seconds(0)
									.millisecond(0),
								toDate: moment(toDate)
									.utcOffset(0)
									.hour(23)
									.minute(59)
									.seconds(59)
									.millisecond(999),
							});
							setDaysOfWeek([]);
							setRateEnabled(false);
							onClose();
						}}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
EnableDisableRatePlanModalContent.propTypes = {
	enumDays: PropTypes.arrayOf(PropTypes.object),
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	dateRange: PropTypes.shape({
		minDate: PropTypes.object,
		maxDate: PropTypes.object,
	}),
};
EnableDisableRatePlanModalContent.defaultProps = {
	enumDays: [],
	onClose: () => {},
	onSubmit: () => {},
	dateRange: { minDate: null, maxDate: null },
};

const PriceDurationBookInAdvanceModal = ({
	isOpen,
	onClose,
	enumDays,
	groupRatePlanReference,
	ratePlans,
	onSubmit,
	dateRange,
}) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				onClose={onClose}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
				}}>
				<PriceDurationBookInAdvanceModalContent
					onClose={onClose}
					enumDays={enumDays}
					groupRatePlanReference={groupRatePlanReference}
					ratePlans={ratePlans}
					onSubmit={onSubmit}
					dateRange={dateRange}
				/>
			</Popup>
		</Portal>
	);
};
PriceDurationBookInAdvanceModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	enumDays: PropTypes.arrayOf(PropTypes.object),
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	groupRatePlanReference: PropTypes.shape({
		current: PropTypes.shape({
			row: PropTypes.number,
			ratePlan: PropTypes.string,
		}),
	}),
	onSubmit: PropTypes.func,
	dateRange: PropTypes.shape({
		minDate: PropTypes.object,
		maxDate: PropTypes.object,
	}),
};
PriceDurationBookInAdvanceModal.defaultProps = {
	isOpen: false,
	onClose: () => {},
	enumDays: [],
	ratePlans: [],
	groupRatePlanReference: {
		current: {
			row: 0,
			ratePlan: '',
		},
	},
	onSubmit: () => {},
	dateRange: { minDate: null, maxDate: null },
};

const PriceDurationBookInAdvanceModalContent = ({
	onClose,
	enumDays,
	groupRatePlanReference,
	ratePlans,
	onSubmit,
	dateRange,
}) => {
	const [daysOfWeek, setDaysOfWeek] = useState([]);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const ratePlan =
		ratePlans[ratePlans.findIndex(rp => rp['@id'] === groupRatePlanReference.current.ratePlan)];
	const [
		fromDate,
		fromDateOnChange,
		fromDateValRes,
		fromDateShowVal,
		setFromDateShowVal,
	] = useField({}, '', () => {}, [required], null, null);
	const [toDate, toDateOnChange, toDateValRes, toDateShowVal, setToDateShowVal] = useField(
		{},
		'',
		() => {},
		[required],
		null,
		null
	);
	const [input, inputOnChange, inputValRes, inputShowVal, setInputShowVal] = useField(
		{},
		'',
		() => {},
		[required, numeric],
		'',
		null
	);

	const titleHelper = () => {
		let title = '';
		if (groupRatePlanReference.current.row % 8 === 2) {
			if (ratePlan.costPer > 1 && ratePlan.bookingPeriod.value === 'Daily')
				title = `Price per ${ratePlan.costPer} Days`;
			else if (ratePlan.costPer > 1 && ratePlan.bookingPeriod.value === 'Hourly')
				title = `Price per ${ratePlan.costPer} Hours`;
			else if (ratePlan.costPer > 1 && ratePlan.bookingPeriod.value === 'Nightly')
				title = `Price per ${ratePlan.costPer} Nights`;
			else if (ratePlan.costPer === 1 && ratePlan.bookingPeriod.value === 'Daily')
				title = `Price per Day`;
			else if (ratePlan.costPer === 1 && ratePlan.bookingPeriod.value === 'Hourly')
				title = `Price per Hour`;
			else if (ratePlan.costPer === 1 && ratePlan.bookingPeriod.value === 'Nightly')
				title = `Price per Night`;
		} else if (groupRatePlanReference.current.row % 8 === 3) title = 'Minimum Length of Stay';
		else if (groupRatePlanReference.current.row % 8 === 4) title = 'Maximum Length of Stay';
		else if (groupRatePlanReference.current.row % 8 === 5) title = 'Minimum Book in Advance';
		else if (groupRatePlanReference.current.row % 8 === 6) title = 'Maximum Book in Advance';
		else if (groupRatePlanReference.current.row % 8 === 7) title = 'Minimum Units Available';
		else if (groupRatePlanReference.current.row % 8 === 0) title = 'Maximum Units Available';
		return title;
	};

	useEffect(() => {
		if (isSubmitted) {
			setFromDateShowVal();
			setToDateShowVal();
			setInputShowVal();
		}
	}, [isSubmitted, setFromDateShowVal, setToDateShowVal, setInputShowVal]);

	useEffect(() => {
		setIsValid(fromDateValRes.isValid && toDateValRes.isValid && inputValRes.isValid);
	}, [fromDateValRes.isValid, toDateValRes.isValid, setIsValid, inputValRes.isValid]);

	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Settings'>
					{titleHelper()}
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<FormGroup>
					<FormField
						name='fromDate'
						label='From Date'
						description='Select Date'
						valRes={fromDateValRes}
						showValidation={fromDateShowVal}
						col={6}>
						<DatePicker
							id='fromDate'
							type='calendar'
							value={fromDate}
							onChange={fromDateOnChange}
							onBlur={setFromDateShowVal}
							minDate={dateRange.minDate}
							maxDate={dateRange.maxDate}
						/>
					</FormField>
					<FormField
						name='toDate'
						label='To Date'
						description='Select Date'
						valRes={toDateValRes}
						showValidation={toDateShowVal}
						col={6}>
						<DatePicker
							id='toDate'
							type='calendar'
							value={toDate}
							onChange={toDateOnChange}
							onBlur={setToDateShowVal}
							minDate={fromDate || dateRange.minDate}
							maxDate={dateRange.maxDate}
						/>
					</FormField>
					<FormField
						label={titleHelper()}
						name='input'
						valRes={inputValRes}
						showValidation={inputShowVal}
						col={6}>
						<Input
							type='text'
							value={input}
							withOutSpin
							onChange={inputOnChange}
							onBlur={setInputShowVal}
							pattern={process.env.REACT_APP_PRICE_PATTERN}
						/>
					</FormField>
					<FormField name='daysOfWeek' label='Select Days of Week' col={12}>
						<MultiSelect
							data={enumDays}
							titleProp='value'
							value={daysOfWeek}
							onChange={e => {
								setDaysOfWeek(e.target.value);
							}}
							name='checkInDays'
						/>
					</FormField>
				</FormGroup>
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={() => {
							onClose();
							setDaysOfWeek([]);
						}}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='brand'
						icon='Done-circle'
						text='Ok'
						size='sm'
						onClick={() => {
							setIsSubmitted(true);
							if (!isValid) return;
							onSubmit({
								daysOfWeek,
								fromDate: moment(fromDate)
									.utcOffset(0)
									.hour(0)
									.minute(0)
									.seconds(0)
									.millisecond(0),
								toDate: moment(toDate)
									.utcOffset(0)
									.hour(23)
									.minute(59)
									.seconds(59)
									.millisecond(999),
								input,
								groupRatePlanReference,
							});
							setDaysOfWeek([]);
							onClose();
						}}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
PriceDurationBookInAdvanceModalContent.propTypes = {
	onClose: PropTypes.func,
	enumDays: PropTypes.arrayOf(PropTypes.object),
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	groupRatePlanReference: PropTypes.shape({
		current: PropTypes.shape({
			row: PropTypes.number,
			ratePlan: PropTypes.string,
		}),
	}),
	onSubmit: PropTypes.func,
	dateRange: PropTypes.shape({
		minDate: PropTypes.object,
		maxDate: PropTypes.object,
	}),
};
PriceDurationBookInAdvanceModalContent.defaultProps = {
	onClose: () => {},
	enumDays: [],
	ratePlans: [],
	groupRatePlanReference: {
		current: {
			row: 0,
			ratePlan: '',
		},
	},
	onSubmit: () => {},
	dateRange: { minDate: null, maxDate: null },
};

const BulkEditPeriodsModal = ({ isOpen, onClose, calendarYears, calendars, onSubmit }) => {
	const [year, setYear] = useState(null);
	const [price, setPrice] = useState({});
	const [minDuration, setMinDuration] = useState({});
	const [maxDuration, setMaxDuration] = useState({});
	const [minBookInAdvance, setMinBookInAdvance] = useState({});
	const [maxBookInAdvance, setMaxBookInAdvance] = useState({});
	const [minUnits, setMinUnits] = useState({});
	const [maxUnits, setMaxUnits] = useState({});
	const [enabled, setEnabled] = useState({});
	const inputRef = createRef();

	useEffect(() => {
		if (year === null) return;
		let enableObject = {};
		if (year.calendar) {
			const _calendar = calendars.find(c => c['@id'] === year.calendar);

			if (_calendar)
				_calendar.calendarPeriods.forEach(
					// eslint-disable-next-line no-return-assign
					p => (enableObject = { ...enableObject, [p.id]: false })
				);
			setEnabled(enableObject);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [year]);

	return (
		<Portal>
			<Popup
				open={isOpen}
				onClose={onClose}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
				}}>
				<Portlet>
					<Portlet.Head>
						<Portlet.HeadLabelTitle>Bulk Edit Periods</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<Section title='General'>
							<FormGroup>
								<FormField
									name='year'
									label='Year'
									description='Choose a year'
									col={6}>
									<Selects
										options={calendarYears.filter(cy => cy.calendar)}
										placeholder='Year'
										value={year}
										onChange={e =>
											e.target.value.calendar['@id']
												? setYear({
														...e.target.value,
														calendar: e.target.value.calendar['@id'],
												  })
												: setYear(e.target.value)
										}
										displayKey='year'
									/>
								</FormField>
							</FormGroup>
						</Section>
						{year &&
							year.calendar &&
							calendars[
								calendars.findIndex(c => c['@id'] === year.calendar)
							].calendarPeriods.map(cp => {
								return (
									<Section title={cp.name} key={cp.id}>
										<FormGroup>
											<FormField name='price' label='Price' col={4}>
												<Input
													type='text'
													ref={inputRef}
													value={price[cp.id]}
													withOutSpin
													onChange={e =>
														setPrice({
															...price,
															[cp.id]: e.target.value,
														})
													}
													pattern={process.env.REACT_APP_PRICE_PATTERN}
												/>
											</FormField>
											<FormField
												name='minLenStay'
												label='Minimum Length of Stay'
												col={4}>
												<Input
													type='number'
													ref={inputRef}
													value={minDuration[cp.id]}
													withOutSpin
													onChange={e =>
														setMinDuration({
															...minDuration,
															[cp.id]:
																e.target.value === ' '
																	? ' '
																	: parseInt(e.target.value, 10),
														})
													}
												/>
											</FormField>
											<FormField
												name='maxLenStay'
												label='Maximum Length of Stay'
												col={4}>
												<Input
													type='number'
													ref={inputRef}
													value={maxDuration[cp.id]}
													withOutSpin
													onChange={e =>
														setMaxDuration({
															...maxDuration,
															[cp.id]:
																e.target.value === ' '
																	? ' '
																	: parseInt(e.target.value, 10),
														})
													}
												/>
											</FormField>
											<FormField
												name='minBookInAdvance'
												label='Minimum Book in Advance'
												col={4}>
												<Input
													type='number'
													value={minBookInAdvance[cp.id]}
													ref={inputRef}
													withOutSpin
													onChange={e =>
														setMinBookInAdvance({
															...minBookInAdvance,
															[cp.id]:
																e.target.value === ' '
																	? ' '
																	: parseInt(e.target.value, 10),
														})
													}
												/>
											</FormField>
											<FormField
												name='maxBookInAdvance'
												label='Maximum Book in Advance'
												col={4}>
												<Input
													type='number'
													value={maxBookInAdvance[cp.id]}
													ref={inputRef}
													withOutSpin
													onChange={e =>
														setMaxBookInAdvance({
															...maxBookInAdvance,
															[cp.id]:
																e.target.value === ' '
																	? ' '
																	: parseInt(e.target.value, 10),
														})
													}
												/>
											</FormField>
											<FormField
												name='minUnits'
												label='Minimum Units'
												col={4}>
												<Input
													type='text'
													value={minUnits[cp.id]}
													ref={inputRef}
													withOutSpin
													onChange={e =>
														setMinUnits({
															...minUnits,
															[cp.id]:
																e.target.value === ' '
																	? ' '
																	: parseFloat(e.target.value),
														})
													}
												/>
											</FormField>
											<FormField
												name='maxUnits'
												label='Maximum Units'
												col={4}>
												<Input
													type='text'
													value={maxUnits[cp.id]}
													ref={inputRef}
													withOutSpin
													onChange={e =>
														setMaxUnits({
															...maxUnits,
															[cp.id]:
																e.target.value === ' '
																	? ' '
																	: parseFloat(e.target.value),
														})
													}
												/>
											</FormField>
											<FormField
												name='rateEnabled'
												label='Rate Status'
												col={4}>
												<Toggle
													value={enabled[cp.id] ? enabled[cp.id] : false}
													onChange={e =>
														setEnabled({
															...enabled,
															[cp.id]: e.target.value,
														})
													}
												/>
											</FormField>
										</FormGroup>
									</Section>
								);
							})}
					</Portlet.Body>
					<Portlet.Foot className='sdms-align-left' tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={() => {
									onClose();
									setYear({});
									setPrice({});
									setMinDuration({});
									setMaxDuration({});
									setMinBookInAdvance({});
									setMaxBookInAdvance({});
									setEnabled({});
									setMinUnits({});
									setMaxUnits({});
								}}
							/>
						</div>
						<div className='col-auto'>
							<Button
								label='brand'
								icon='Done-circle'
								text='Ok'
								size='sm'
								disabled={!year}
								onClick={() => {
									onSubmit({
										price,
										minDuration,
										maxDuration,
										minBookInAdvance,
										maxBookInAdvance,
										enabled,
										year,
										minUnits,
										maxUnits,
									});
									onClose();
									setYear({});
									setPrice({});
									setMinDuration({});
									setMaxDuration({});
									setMinBookInAdvance({});
									setMaxBookInAdvance({});
									setEnabled({});
									setMinUnits({});
									setMaxUnits({});
								}}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};

BulkEditPeriodsModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	calendars: PropTypes.arrayOf(PropTypes.object),
	calendarYears: PropTypes.arrayOf(PropTypes.object),
	onSubmit: PropTypes.func,
};
BulkEditPeriodsModal.defaultProps = {
	isOpen: false,
	onClose: () => {},
	calendars: [],
	calendarYears: [],
	onSubmit: () => {},
};

const getItemBgColor = itemContext =>
	itemContext.selected ? 'rgba(256, 256, 256, 0)' : 'transparent';

const getItemInput = (item, ref) => {
	if (item.row === 1)
		return (
			<Toggle
				onChange={e =>
					item.updateItem({
						id: item.id,
						row: item.row,
						productRatePlan: item.productRatePlan,
						value: e.target.value,
					})
				}
				value={item.value}
				size='sm'
				color='success'
				outline
				spaceLess
			/>
		);

	if (item.row < 9)
		return (
			<Input
				ref={ref}
				value={item.value}
				type={item.row === 2 ? 'text' : 'number'}
				withOutSpin
				tabIndex={item.row}
				onChange={e =>
					item.updateItem({
						id: item.id,
						row: item.row,
						productRatePlan: item.productRatePlan,
						value: e.target.value,
					})
				}
				pattern={
					item.row === 2
						? process.env.REACT_APP_PRICE_PATTERN
						: process.env.REACT_APP_INTEGER_PATTERN
				}
			/>
		);

	return (
		<Input
			ref={ref}
			type='number'
			withOutSpin
			value={item.value}
			min={0}
			tabIndex={item.row}
			pattern={process.env.REACT_APP_INTEGER_PATTERN}
			onChange={e =>
				item.updateItem({
					id: item.id,
					value: e.target.value,
					type: item.type,
					row: item.row,
				})
			}
			append={item.type}
		/>
	);
};

const ItemRenderer = ({ item, itemContext, getItemProps }) => {
	const inputRef = createRef();

	return (
		<div
			{...getItemProps({
				onMouseDown: () => {
					if (inputRef.current) inputRef.current.focus();
				},
				style: { backgroundColor: getItemBgColor(itemContext), border: 'none' },
				className: 'sdms-booking-advPrc__item',
			})}>
			{getItemInput(item, inputRef)}
		</div>
	);
};
ItemRenderer.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	item: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	itemContext: PropTypes.object,
	getItemProps: PropTypes.func,
};
ItemRenderer.defaultProps = {
	item: {},
	itemContext: {},
	getItemProps: () => {},
};

const GroupRenderer = ({ group }) => {
	return (
		<div
			className={classNames('sdms-booking-advPrc__group', {
				'sdms-booking-advPrc__group--title': group.isRatePlanGroup,
			})}>
			<span className='title' title={group.title}>
				{group.title}
			</span>
			{group.isRatePlanGroup && (
				<Dropdown
					icon='Other#1'
					color='clean'
					inline
					aligned='right'
					circle
					size='sm'
					outline={false}
					menuSize='sm'>
					<Dropdown.Header>Group Actions</Dropdown.Header>
					<Dropdown.Item
						onClick={() => {
							group.modal('enableDisableRatePlan');
							group.groupReference.current = group.id;
						}}
						icon='Settings#3'>
						Enable/Disable Rate Plan
					</Dropdown.Item>
					<Dropdown.Item
						onClick={() => {
							group.modal('bulkEditPeriods');
							group.groupReference.current = group.id;
						}}
						icon='Bullet-list'>
						Bulk Edit Periods
					</Dropdown.Item>
					<Dropdown.Item itemsColor='danger' icon='Trash' onClick={group.onClick}>
						Delete
					</Dropdown.Item>
				</Dropdown>
			)}
			{!group.isRatePlanGroup && group.modal && (
				<Button
					className='d-inline'
					btnIcon
					circle
					design='clean'
					icon='Settings'
					size='sm'
					onClick={() => {
						group.modal('priceDurationBookInAdvance');
						group.groupReference.current = group.id;
						group.groupRatePlanReference.current = {
							ratePlan: group.ratePlan,
							row: group.groupRow,
						};
					}}
				/>
			)}
		</div>
	);
};
GroupRenderer.propTypes = {
	group: PropTypes.shape({
		title: PropTypes.string,
		id: PropTypes.number,
		ratePlan: PropTypes.string,
		groupRow: PropTypes.number,
		isRatePlanGroup: PropTypes.bool,
		modal: PropTypes.func,
		onClick: PropTypes.func,
		groupReference: PropTypes.shape({
			current: PropTypes.number,
		}),
		groupRatePlanReference: PropTypes.shape({
			current: PropTypes.object,
		}),
	}).isRequired,
};

const rowToKey = {
	1: 'enabled',
	2: 'price',
	3: 'minDuration',
	4: 'maxDuration',
	5: 'minBookInAdvance',
	6: 'maxBookInAdvance',
	7: 'minUnits',
	8: 'maxUnits',
	9: 'minLoa',
	10: 'maxLoa',
	11: 'minBeam',
	12: 'maxBeam',
	13: 'minHeight',
	14: 'maxHeight',
	15: 'minWeight',
	16: 'maxWeight',
	17: 'minDraft',
	18: 'maxDraft',
	19: 'minSqft',
	20: 'maxSqft',
	21: 'minPricingLoa',
};

const inchItems = [9, 10, 11, 12, 13, 14, 17, 18, 21];

const ProductMarinaAdvancedPricingMarina = ({
	calendars,
	product,
	ratePlans,
	enumDays,
	onFormChange,
}) => {
	const [whichModalOpen, setWhichModalOpen] = useState('');
	const groupReference = useRef(0);
	const groupRatePlanReference = useRef({});

	const [startTime, setStartTime] = useState(
		moment()
			.startOf('day')
			.valueOf()
	);

	const [endTime, setEndTime] = useState(
		moment()
			.add(2, 'w')
			.endOf('day')
			.add(12, 'hour')
			.valueOf()
	);

	const getDateRange = () => {
		if (advancePricing.productCalendarYears.length === 0)
			return { minDate: null, maxDate: null };

		const minDate = moment()
			.utcOffset(0)
			.startOf('year');
		const maxDate = moment()
			.utcOffset(0)
			.endOf('year');

		advancePricing.productCalendarYears.forEach(pcy => {
			if (minDate.year() > pcy.year) minDate.year(pcy.year);

			if (maxDate.year() < pcy.year) maxDate.year(pcy.year);
		});

		return {
			minDate: convertDateToUTC(minDate.toDate()),
			maxDate: convertDateToUTC(maxDate.toDate()),
		};
	};

	const parseFt = useCallback(value => (value > 12 ? parseInt(value / 12, 10) : 0), []);

	const parseData = useCallback((data, item, value) => {
		if (item.row === 1) return value;

		if (item.row === 2) return value ? parseFloat(value) / item.costPer : null;

		if (inchItems.indexOf(item.row) > -1) {
			if (value === '' && !data[rowToKey[item.row]]) return null;

			const oldValue = data[rowToKey[item.row]] || 0;

			const _value = value || 0;

			if (item.type === 'ft') return parseFloat(_value) * 12 + (oldValue % 12);

			return oldValue - (oldValue % 12) + parseFloat(_value);
		}

		return parseFloat(value);
	}, []);

	const generateProductRatePlans = useCallback(
		(year, ratePlan) => {
			const productRatePlans = [];

			const currentMoment = moment()
				.set('year', year)
				.utcOffset(0)
				.startOf('year');

			const endOfYearMoment = moment()
				.set('year', year)
				.utcOffset(0)
				.endOf('year');

			while (currentMoment < endOfYearMoment) {
				productRatePlans.push({
					id: ratePlan.id * currentMoment.valueOf() * -1,
					ratePlan: ratePlan['@id'],
					date: currentMoment.format('YYYY-MM-DD'),
					enabled: true,
					price: product.price,
					minBookInAdvance: ratePlan.minBookInAdvance,
					maxBookInAdvance: ratePlan.maxBookInAdvance,
					minDuration: ratePlan.minDuration,
					maxDuration: ratePlan.maxDuration,
					minUnits: ratePlan.minUnits,
					maxUnits: ratePlan.maxUnits,
					minLoa: ratePlan.minLoa,
					maxLoa: ratePlan.maxLoa,
					minBeam: ratePlan.minBeam,
					maxBeam: ratePlan.maxBeam,
					minHeight: ratePlan.minHeight,
					maxHeight: ratePlan.maxHeight,
					minWeight: ratePlan.minWeight,
					maxWeight: ratePlan.maxWeight,
					minDraft: ratePlan.minDraft,
					maxDraft: ratePlan.maxDraft,
					minSqft: ratePlan.minSqft,
					maxSqft: ratePlan.maxSqft,
					minPricingLoa: ratePlan.minPricingLoa,
				});
				currentMoment.add(1, 'days');
			}
			return productRatePlans;
		},
		[product]
	);

	const pricePer = useCallback(ratePlan => {
		let unit = '';

		if (ratePlan.bookingPeriod.value === 'Daily')
			unit = ratePlan.costPer > 1 ? `${ratePlan.costPer} Days` : `Day`;

		if (ratePlan.bookingPeriod.value === 'Nightly')
			unit = ratePlan.costPer > 1 ? `${ratePlan.costPer} Nights` : `Night`;

		if (ratePlan.bookingPeriod.value === 'Hourly')
			unit = ratePlan.costPer > 1 ? `${ratePlan.costPer} Hours` : `Hour`;

		if (
			ratePlan.pricingType.value === pricingTypes.PER_UNIT ||
			ratePlan.pricingType.value === pricingTypes.NUMBER_OF_UNIT
		)
			return `Price per ${unit}`;

		if (ratePlan.pricingType.value === pricingTypes.PER_UNIT_PER_SQFT)
			return `Price per ${unit} per Sqft`;

		return `Price per ${unit} per LOA`;
	}, []);

	const getVesselConstraintsItemTimes = useCallback(
		type => {
			const middle = moment(startTime + (endTime - startTime) / 2);

			if (type === 'in') {
				return {
					start_time: middle.startOf('day').valueOf(),
					end_time: middle
						.add(1, 'day')
						.endOf('day')
						.valueOf(),
				};
			}

			if (type === 'ft')
				return {
					start_time: middle
						.add(-2, 'day')
						.startOf('day')
						.valueOf(),
					end_time: middle
						.add(1, 'day')
						.endOf('day')
						.valueOf(),
				};

			return {
				start_time: middle
					.add(-2, 'day')
					.startOf('day')
					.valueOf(),
				end_time: middle
					.add(3, 'day')
					.endOf('day')
					.valueOf(),
			};
		},
		[startTime, endTime]
	);

	const generateItems = useCallback(
		(productRatePlans, itemsGroups, ratePlanList, updateItem) => {
			const items = [];
			productRatePlans.forEach(prp => {
				const itemMoment = moment(convertDateToUTC(new Date(prp.date)).getTime());

				const ratePlanIndex = ratePlanList.findIndex(rp => rp['@id'] === prp.ratePlan);

				itemsGroups.forEach(ig => {
					if (ig.ratePlan === prp.ratePlan && ig.groupRow < 9) {
						items.push({
							id: `${ig.id}${prp.id}`,
							group: ig.id,
							start_time: itemMoment.startOf('day').valueOf(),
							end_time: itemMoment.endOf('day').valueOf(),
							productRatePlan: prp.id,
							ratePlan: prp.ratePlan,
							value:
								ig.groupRow === 2
									? numberFormat(
											prp[rowToKey[ig.groupRow]] *
												ratePlanList[ratePlanIndex].costPer
									  )
									: prp[rowToKey[ig.groupRow]] || '',
							updateItem,
							row: ig.groupRow,
							costPer: ratePlanList[ratePlanIndex].costPer,
							canMove: false,
							canResize: false,
							canChangeGroup: false,
						});
					}
				});
			});
			itemsGroups.forEach(ig => {
				if (ig.groupRow >= 9) {
					if (inchItems.indexOf(ig.groupRow) > -1) {
						items.push({
							id: `${ig.id}${ig.ratePlan}-1`,
							group: ig.id,
							ratePlan: productRatePlans[0].ratePlan,
							value: productRatePlans[0][rowToKey[ig.groupRow]]
								? parseFt(productRatePlans[0][rowToKey[ig.groupRow]])
								: '',
							updateItem,
							row: ig.groupRow,
							canMove: false,
							canResize: false,
							canChangeGroup: false,
							type: 'ft',
							...getVesselConstraintsItemTimes('ft'),
						});
						items.push({
							id: `${ig.id}${ig.ratePlan}-2`,
							group: ig.id,
							ratePlan: productRatePlans[0].ratePlan,
							value: productRatePlans[0][rowToKey[ig.groupRow]]
								? productRatePlans[0][rowToKey[ig.groupRow]] % 12
								: '',
							updateItem,
							row: ig.groupRow,
							canMove: false,
							canResize: false,
							canChangeGroup: false,
							type: 'in',
							...getVesselConstraintsItemTimes('in'),
						});
					} else {
						items.push({
							id: `${ig.id}${ig.ratePlan}`,
							group: ig.id,
							ratePlan: productRatePlans[0].ratePlan,
							value: productRatePlans[0][rowToKey[ig.groupRow]] || '',
							updateItem,
							row: ig.groupRow,
							canMove: false,
							canResize: false,
							canChangeGroup: false,
							type: ig.groupRow === 19 || ig.groupRow === 20 ? 'sqft' : 'lbs',
							...getVesselConstraintsItemTimes(
								ig.groupRow === 19 || ig.groupRow === 20 ? 'sqft' : 'lbs'
							),
						});
					}
				}
			});

			return items;
		},
		[parseFt, getVesselConstraintsItemTimes]
	);

	const generateGroups = useCallback(
		ratePlan => {
			const groups = [
				{
					id: ratePlan.id,
					groupRow: 1,
					title: ratePlan.internalName,
					isRatePlanGroup: true,
					modal: setWhichModalOpen,
					groupReference,
					onClick: () => dispatch({ type: 'removeRatePlan', payload: ratePlan }),
					ratePlan: ratePlan['@id'],
				},
				{
					id: ratePlan.id + 0.1,
					groupRow: 2,
					modal: setWhichModalOpen,
					groupReference,
					groupRatePlanReference,
					title: pricePer(ratePlan),
					ratePlan: ratePlan['@id'],
				},
			];

			if (ratePlan.minDuration)
				groups.push({
					id: ratePlan.id + 0.2,
					groupRow: 3,
					modal: setWhichModalOpen,
					groupReference,
					groupRatePlanReference,
					title: 'Minimum Length of Stay',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxDuration)
				groups.push({
					id: ratePlan.id + 0.3,
					groupRow: 4,
					groupRatePlanReference,
					modal: setWhichModalOpen,
					groupReference,
					title: 'Maximum Length of Stay',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minBookInAdvance)
				groups.push({
					id: ratePlan.id + 0.4,
					groupRow: 5,
					groupRatePlanReference,
					modal: setWhichModalOpen,
					groupReference,
					title: 'Minimum Book in Advance',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxBookInAdvance)
				groups.push({
					id: ratePlan.id + 0.5,
					groupRow: 6,
					groupRatePlanReference,
					modal: setWhichModalOpen,
					groupReference,
					title: 'Maximum Book in Advance',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minUnits)
				groups.push({
					id: ratePlan.id + 0.6,
					groupRow: 7,
					groupRatePlanReference,
					modal: setWhichModalOpen,
					groupReference,
					title: 'Minimum Units Available',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxUnits)
				groups.push({
					id: ratePlan.id + 0.7,
					groupRow: 8,
					groupRatePlanReference,
					modal: setWhichModalOpen,
					groupReference,
					title: 'Maximum Units Available',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minLoa)
				groups.push({
					id: ratePlan.id + 0.8,
					groupRow: 9,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum LOA',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxLoa)
				groups.push({
					id: ratePlan.id + 0.9,
					groupRow: 10,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum LOA',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minBeam)
				groups.push({
					id: ratePlan.id + 0.01,
					groupRow: 11,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum Beam',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxBeam)
				groups.push({
					id: ratePlan.id + 0.011,
					groupRow: 12,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Beam',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minHeight)
				groups.push({
					id: ratePlan.id + 0.012,
					groupRow: 13,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum Height',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxHeight)
				groups.push({
					id: ratePlan.id + 0.013,
					groupRow: 14,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Height',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minWeight)
				groups.push({
					id: ratePlan.id + 0.014,
					groupRow: 15,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum Weight',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxWeight)
				groups.push({
					id: ratePlan.id + 0.015,
					groupRow: 16,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Weight',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minDraft)
				groups.push({
					id: ratePlan.id + 0.016,
					groupRow: 17,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum Draft',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxDraft)
				groups.push({
					id: ratePlan.id + 0.017,
					groupRow: 18,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Draft',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minSqft)
				groups.push({
					id: ratePlan.id + 0.018,
					groupRow: 19,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum Sqft',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxSqft)
				groups.push({
					id: ratePlan.id + 0.019,
					groupRow: 20,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Sqft',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minPricingLoa)
				groups.push({
					id: ratePlan.id + 0.02,
					groupRow: 21,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum pricing LOA',
					ratePlan: ratePlan['@id'],
				});

			return groups;
		},
		[pricePer]
	);

	const reducer = (state, action) => {
		const { type, payload } = { ...action };

		if (type === 'set' && payload.productRatePlanMarinas) {
			state = update(state, {
				$merge: {
					productRatePlanMarinas: [],
					ratePlans: [],
					itemsGroups: [],
					items: [],
				},
			});

			payload.productRatePlanMarinas.forEach(prp => {
				const ratePlanObject = ratePlans.find(rp => rp['@id'] === prp.ratePlan);

				if (state.ratePlans.findIndex(_rp => _rp.id === ratePlanObject.id) === -1) {
					state = update(state, {
						ratePlans: {
							$push: [ratePlanObject],
						},
						itemsGroups: {
							$push: generateGroups(ratePlanObject),
						},
					});
				}
			});

			state = update(state, {
				$merge: {
					productCalendarYears: payload.calendarYears,
					productRatePlanMarinas: payload.productRatePlanMarinas,
				},
			});

			state = update(state, {
				items: {
					$push: generateItems(
						state.productRatePlanMarinas,
						state.itemsGroups,
						ratePlans,
						item => dispatch({ type: 'updateItem', payload: item })
					),
				},
			});

			return state;
		}

		if (type === 'reRenderVesselConstraintsItems') {
			state.items.forEach((item, index) => {
				if (item.row >= 9) {
					state = update(state, {
						items: {
							[index]: {
								$merge: getVesselConstraintsItemTimes(item.type),
							},
						},
					});
				}
			});

			return state;
		}

		if (type === 'addCalendarYear') {
			state = update(state, {
				productCalendarYears: {
					$push: [{ ...payload, id: generateId(state.productCalendarYears) }],
				},
			});

			product.calendarYears = [...state.productCalendarYears];

			if (state.ratePlans.length) {
				const newProductRatePlans = [];

				const newItems = [];

				state.ratePlans.forEach(rp => {
					const generatedProductRatePlans = generateProductRatePlans(payload.year, rp);

					newProductRatePlans.push(...generatedProductRatePlans);

					newItems.push(
						...generateItems(
							generatedProductRatePlans,
							state.itemsGroups,
							ratePlans,
							item => dispatch({ type: 'updateItem', payload: item })
						)
					);
				});

				state = update(state, {
					productRatePlanMarinas: {
						$push: newProductRatePlans,
					},
					items: {
						$push: newItems,
					},
				});

				product.productRatePlanMarinas = [...state.productRatePlanMarinas];
			}

			onFormChange();
		}

		if (type === 'addRatePlan') {
			if (state.productRatePlanMarinas.findIndex(prp => prp.ratePlan === payload['@id']) > -1)
				return state;

			const groups = generateGroups(payload);

			state = update(state, {
				ratePlans: {
					$push: [payload],
				},
				itemsGroups: {
					$push: groups,
				},
			});

			if (state.productCalendarYears.length > 0) {
				const newProductRatePlans = [];

				const newItems = [];

				state.productCalendarYears.forEach(pcy => {
					const generatedProductRatePlans = generateProductRatePlans(pcy.year, payload);

					newProductRatePlans.push(...generatedProductRatePlans);

					newItems.push(
						...generateItems(generatedProductRatePlans, groups, ratePlans, item =>
							dispatch({ type: 'updateItem', payload: item })
						)
					);
				});

				state = update(state, {
					productRatePlanMarinas: {
						$push: newProductRatePlans,
					},
					items: {
						$push: newItems,
					},
				});

				product.productRatePlanMarinas = [...state.productRatePlanMarinas];
			}

			onFormChange();
		}

		if (type === 'updateItem') {
			if (payload.row < 9) {
				const itemIndex = state.items.findIndex(i => i.id === payload.id);

				const productRatePlanIndex = state.productRatePlanMarinas.findIndex(
					prp => prp.id === payload.productRatePlan
				);

				state = update(state, {
					items: {
						[itemIndex]: { value: { $set: payload.value } },
					},
					productRatePlanMarinas: {
						[productRatePlanIndex]: {
							[rowToKey[payload.row]]: {
								$set: parseData(
									state.productRatePlanMarinas[productRatePlanIndex],
									state.items[itemIndex],
									payload.value
								),
							},
						},
					},
				});
			} else {
				const itemIndex = state.items.findIndex(i => i.id === payload.id);

				state = update(state, {
					items: {
						[itemIndex]: { value: { $set: payload.value } },
					},
					productRatePlanMarinas: {
						$set: state.productRatePlanMarinas.map(prp => {
							if (prp.ratePlan === state.items[itemIndex].ratePlan) {
								return {
									...prp,
									[rowToKey[payload.row]]: parseData(
										prp,
										state.items[itemIndex],
										payload.value
									),
								};
							}

							return prp;
						}),
					},
				});
			}

			product.productRatePlanMarinas = [...state.productRatePlanMarinas];

			onFormChange();
		}

		if (type === 'removeRatePlan') {
			state = update(state, {
				items: { $set: state.items.filter(i => i.ratePlan !== payload['@id']) },
				ratePlans: { $set: state.ratePlans.filter(rp => rp.id !== payload.id) },
				itemsGroups: {
					$set: state.itemsGroups.filter(ig => ig.ratePlan !== payload['@id']),
				},
				productRatePlanMarinas: {
					$set: state.productRatePlanMarinas.filter(
						prp => prp.ratePlan !== payload['@id']
					),
				},
			});

			product.productRatePlanMarinas = [...state.productRatePlanMarinas];

			onFormChange();
		}

		if (type === 'enableDisableRatePlan') {
			const _state = { ...state };

			_state.items.forEach(item => {
				if (
					item.group === groupReference.current ||
					item.group === groupReference.current + 0.1 ||
					item.group === groupReference.current + 0.2 ||
					item.group === groupReference.current + 0.3 ||
					item.group === groupReference.current + 0.4 ||
					item.group === groupReference.current + 0.5 ||
					item.group === groupReference.current + 0.6 ||
					item.group === groupReference.current + 0.7 ||
					item.group === groupReference.current + 0.8 ||
					item.group === groupReference.current + 0.9 ||
					item.group === groupReference.current + 1 ||
					item.group === groupReference.current + 1.1 ||
					item.group === groupReference.current + 1.2 ||
					item.group === groupReference.current + 1.3 ||
					item.group === groupReference.current + 1.4 ||
					item.group === groupReference.current + 1.5 ||
					item.group === groupReference.current + 1.6 ||
					item.group === groupReference.current + 1.7 ||
					item.group === groupReference.current + 1.8 ||
					item.group === groupReference.current + 1.9 ||
					item.group === groupReference.current + 2.0
				) {
					const itemMoment = moment(item.start_time);
					if (
						payload.fromDate.isSameOrBefore(itemMoment) &&
						payload.toDate.isSameOrAfter(itemMoment) &&
						payload.daysOfWeek.findIndex(
							dow => dow.value === moment(item.start_time).format('dddd')
						) !== -1 &&
						item.productRatePlan
					) {
						const itemIndex = state.items.findIndex(_item => _item.id === item.id);
						const productRatePlanIndex = state.productRatePlanMarinas.findIndex(
							prp => prp.id === item.productRatePlan
						);

						if (item.row === 1)
							state = update(state, {
								items: {
									[itemIndex]: {
										$merge: { value: payload.rateEnabled },
									},
								},
								productRatePlanMarinas: {
									[productRatePlanIndex]: {
										$merge: {
											enabled: payload.rateEnabled,
										},
									},
								},
							});

						product.productRatePlanMarinas = [...state.productRatePlanMarinas];
					}
				}
			});

			onFormChange();
		}

		if (type === 'priceDurationBookInAdvance') {
			const _state = { ...state };
			_state.items.forEach(item => {
				if (item.group === groupReference.current) {
					const itemMoment = moment(item.start_time);

					if (
						payload.fromDate.isSameOrBefore(itemMoment) &&
						payload.toDate.isSameOrAfter(itemMoment) &&
						payload.daysOfWeek.findIndex(
							dow => dow.value === moment(item.start_time).format('dddd')
						) !== -1
					) {
						const itemIndex = state.items.findIndex(_item => _item.id === item.id);

						const productRatePlanIndex = state.productRatePlanMarinas.findIndex(
							prp => prp.id === item.productRatePlan
						);

						const newValue = payload.input;

						let newRatePlan = {};

						if (payload.groupRatePlanReference.current.row % 20 === 2) {
							newRatePlan = {
								price: parseFloat(payload.input) / item.costPer,
							};
						} else if (payload.groupRatePlanReference.current.row % 20 === 3) {
							newRatePlan = {
								minDuration: parseInt(payload.input, 10),
							};
						} else if (payload.groupRatePlanReference.current.row % 20 === 4) {
							newRatePlan = {
								maxDuration: parseInt(payload.input, 10),
							};
						} else if (payload.groupRatePlanReference.current.row % 20 === 5) {
							newRatePlan = {
								minBookInAdvance: parseInt(payload.input, 10),
							};
						} else if (payload.groupRatePlanReference.current.row % 20 === 6) {
							newRatePlan = {
								maxBookInAdvance: parseInt(payload.input, 10),
							};
						} else if (payload.groupRatePlanReference.current.row % 20 === 7) {
							newRatePlan = {
								minUnits: parseInt(payload.input, 10),
							};
						} else if (payload.groupRatePlanReference.current.row % 20 === 8) {
							newRatePlan = {
								maxUnits: parseInt(payload.input, 10),
							};
						}

						state = update(state, {
							items: {
								[itemIndex]: {
									$merge: { value: newValue },
								},
							},
							productRatePlanMarinas: {
								[productRatePlanIndex]: {
									$merge: newRatePlan,
								},
							},
						});

						product.productRatePlanMarinas = [...state.productRatePlanMarinas];
					}
				}
			});

			onFormChange();
		}

		if (type === 'bulkEdit') {
			const _state = { ...state };
			_state.items.forEach(item => {
				if (
					item.group === groupReference.current ||
					item.group === groupReference.current + 0.1 ||
					item.group === groupReference.current + 0.2 ||
					item.group === groupReference.current + 0.3 ||
					item.group === groupReference.current + 0.4 ||
					item.group === groupReference.current + 0.5 ||
					item.group === groupReference.current + 0.6 ||
					item.group === groupReference.current + 0.7 ||
					item.group === groupReference.current + 0.8 ||
					item.group === groupReference.current + 0.9 ||
					item.group === groupReference.current + 1 ||
					item.group === groupReference.current + 1.1 ||
					item.group === groupReference.current + 1.2 ||
					item.group === groupReference.current + 1.3 ||
					item.group === groupReference.current + 1.4 ||
					item.group === groupReference.current + 1.5 ||
					item.group === groupReference.current + 1.6 ||
					item.group === groupReference.current + 1.7 ||
					item.group === groupReference.current + 1.8 ||
					item.group === groupReference.current + 1.9 ||
					item.group === groupReference.current + 2.0
				) {
					const itemStart = convertDateToUTC(new Date(item.start_time)).toDateString();

					const calendarIndex = calendars.findIndex(
						calendar => calendar['@id'] === payload.year.calendar
					);

					if (calendarIndex === -1) return;

					calendars[calendarIndex].calendarPeriods.forEach(period => {
						Object.keys(payload).forEach(payloadKey => {
							let row = Object.keys(rowToKey).find(
								rowKey => rowToKey[rowKey] === payloadKey
							);
							if (row) {
								row = parseInt(row, 10);

								Object.keys(payload[payloadKey]).forEach(key => {
									if (period.id.toString() === key) {
										period.calendarPeriodDays.forEach(calPer => {
											if (
												convertDateToUTC(
													new Date(calPer.date)
												).toDateString() === itemStart &&
												item.row === row
											) {
												item.updateItem({
													id: item.id,
													productRatePlan: item.productRatePlan,
													value: payload[payloadKey][key],
													row,
												});
											}
										});
									}
								});
							}
						});
					});
				}
			});

			onFormChange();
		}

		return state;
	};

	const [advancePricing, dispatch] = useReducer(reducer, {
		productCalendarYears: [],
		productRatePlanMarinas: [],
		ratePlans: [],
		itemsGroups: [],
		items: [],
	});

	useEffect(() => {
		if (ratePlans.length) dispatch({ type: 'set', payload: product });
	}, [product, ratePlans]);

	useEffect(() => {
		if (advancePricing.items.length > 0) dispatch({ type: 'reRenderVesselConstraintsItems' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startTime, endTime]);

	// package bug fix.
	useEffect(() => {
		if (advancePricing.items.length) window.dispatchEvent(new Event('resize'));
	}, [advancePricing]);

	return (
		<>
			<Portlet border>
				<Portlet.Head wrapMaxSize='lg'>
					<Portlet.HeadLabel portletIcon='Layout-left-panel-1'>
						<h3 className='sdms-portlet__head-title'>
							<small>
								{moment(startTime).format(
									`${
										moment(startTime).format('Y') ===
										moment(endTime).format('Y')
											? 'MMM DD'
											: 'MMM DD, YYYY'
									}`
								)}{' '}
								to {moment(endTime).format('MMM DD, YYYY')}
							</small>
						</h3>
						<Portlet.Separator />
						<div className='sdms-portlet__head-desc'>
							<Button
								className='sdms-margin-r-15'
								design='clean'
								icon='Angle-double-left'
								size='sm'
								text='Prev'
								onClick={() => {
									const zoom = endTime - startTime;
									if (
										advancePricing.productCalendarYears.findIndex(
											pcy => pcy.year === moment(endTime - zoom).year()
										) !== -1
									) {
										setStartTime(startTime - zoom);
										setEndTime(endTime - zoom);
									} else {
										setStartTime(startTime);
										setEndTime(endTime);
									}
								}}
							/>
							<Button
								className='sdms-margin-r-15'
								design='clean'
								icon='Angle-double-right'
								size='sm'
								text='Next'
								onClick={() => {
									const zoom = endTime - startTime;
									if (
										advancePricing.productCalendarYears.findIndex(
											pcy => pcy.year === moment(startTime + zoom).year()
										) !== -1
									) {
										setStartTime(startTime + zoom);
										setEndTime(endTime + zoom);
									} else {
										setStartTime(startTime);
										setEndTime(endTime);
									}
								}}
							/>
						</div>
					</Portlet.HeadLabel>
					<Portlet.HeadToolbarActions>
						<Button
							icon='Plus'
							label='brand'
							text='Add Year to Calendar'
							size='sm'
							disabled={advancePricing.productCalendarYears.length > 2}
							onClick={() => setWhichModalOpen('calendar')}
						/>
					</Portlet.HeadToolbarActions>
				</Portlet.Head>
				<Portlet.Body className='sdms-portlet__body--fit react-calendar-timeline--no-border sdms-product-advance-pricing-timeline-container'>
					<Timeline
						groups={advancePricing.itemsGroups}
						groupRenderer={GroupRenderer}
						items={advancePricing.items}
						itemRenderer={ItemRenderer}
						visibleTimeStart={startTime}
						visibleTimeEnd={endTime}
						sidebarWidth={250}
						lineHeight={40}
						showCursorLine
						canMove={false}
						canResize={false}
						onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
							if (
								(advancePricing.productCalendarYears.findIndex(
									pcy => pcy.year === moment(visibleTimeStart).year()
								) !== -1 ||
									advancePricing.productCalendarYears.findIndex(
										pcy => pcy.year === moment(visibleTimeEnd).year()
									) !== -1) &&
								visibleTimeEnd - visibleTimeStart <
									moment()
										.add(16, 'day')
										.valueOf() -
										moment().valueOf() &&
								visibleTimeEnd - visibleTimeStart >
									moment()
										.add(14, 'day')
										.valueOf() -
										moment().valueOf()
							) {
								updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
								setStartTime(visibleTimeStart);
								setEndTime(visibleTimeEnd);
							} else updateScrollCanvas(startTime.valueOf(), endTime.valueOf());
						}}>
						<TimelineHeaders className='sticky'>
							<SidebarHeader>
								{({ getRootProps }) => {
									return (
										// eslint-disable-next-line react/jsx-props-no-spreading
										<div {...getRootProps()} className='sdms-portlet__head'>
											<div className='sdms-portlet__head-label w-100'>
												<h3 className='sdms-portlet__head-title w-100 sdms-pr0'>
													<Button
														icon='Plus'
														label='brand'
														text='Add Rate Plan'
														size='sm'
														block
														disabled={
															advancePricing.productCalendarYears
																.length <= 0
														}
														onClick={() =>
															setWhichModalOpen('ratePlan')
														}
													/>
												</h3>
											</div>
										</div>
									);
								}}
							</SidebarHeader>
							{advancePricing.productCalendarYears.length <= 0 ? (
								<Alert solid icon='Info-circle' className='sdms-margin-10'>
									Please add a year to calendar!
								</Alert>
							) : (
								<>
									<DateHeader unit='primaryHeader' />
									<DateHeader />
								</>
							)}
						</TimelineHeaders>
					</Timeline>
				</Portlet.Body>
			</Portlet>
			<CalendarModal
				onClose={() => setWhichModalOpen('')}
				calendars={calendars}
				calendarYears={advancePricing.productCalendarYears}
				onSubmit={calendarYearData =>
					dispatch({ type: 'addCalendarYear', payload: calendarYearData })
				}
				open={whichModalOpen === 'calendar'}
			/>
			<RatePlanModal
				open={whichModalOpen === 'ratePlan'}
				ratePlans={ratePlans}
				selectedRatePlans={advancePricing.ratePlans}
				onClose={() => setWhichModalOpen('')}
				productBookingPeriodId={product.bookingPeriod.id}
				onSubmit={ratePlanData => dispatch({ type: 'addRatePlan', payload: ratePlanData })}
			/>
			<EnableDisableRatePlanModal
				isOpen={whichModalOpen === 'enableDisableRatePlan'}
				enumDays={enumDays}
				onClose={() => setWhichModalOpen('')}
				onSubmit={enableDisableFields =>
					dispatch({ type: 'enableDisableRatePlan', payload: enableDisableFields })
				}
				dateRange={getDateRange()}
			/>
			<BulkEditPeriodsModal
				isOpen={whichModalOpen === 'bulkEditPeriods'}
				onClose={() => setWhichModalOpen('')}
				calendarYears={product.calendarYears}
				calendars={calendars}
				onSubmit={bulkEditFields => dispatch({ type: 'bulkEdit', payload: bulkEditFields })}
				dateRange={getDateRange()}
			/>
			<PriceDurationBookInAdvanceModal
				isOpen={whichModalOpen === 'priceDurationBookInAdvance'}
				onClose={() => setWhichModalOpen('')}
				enumDays={enumDays}
				groupRatePlanReference={groupRatePlanReference}
				ratePlans={ratePlans}
				onSubmit={priceDurationBookInAdvanceFields =>
					dispatch({
						type: 'priceDurationBookInAdvance',
						payload: priceDurationBookInAdvanceFields,
					})
				}
				dateRange={getDateRange()}
			/>
		</>
	);
};
ProductMarinaAdvancedPricingMarina.propTypes = {
	calendars: PropTypes.arrayOf(PropTypes.object),
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	product: PropTypes.object,
	enumDays: PropTypes.arrayOf(PropTypes.object),
	onFormChange: PropTypes.func,
};
ProductMarinaAdvancedPricingMarina.defaultProps = {
	calendars: [],
	ratePlans: [],
	product: {},
	enumDays: [],
	onFormChange: () => {},
};

export default ProductMarinaAdvancedPricingMarina;
