import React, { useContext, useEffect, useReducer, useState, useRef, createRef } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import SortableTree, {
	addNodeUnderParent,
	getFlatDataFromTree,
	getTreeFromFlatData,
	removeNode,
	toggleExpandedForAll,
	walk,
} from 'react-sortable-tree';
import update from 'immutability-helper';

import useComponentSize from '@rehooks/component-size';
import classNames from 'classnames';
import { required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import apiCall, { parseData, pathToUrl } from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	generateId,
	priceFormatter,
	randomColor,
} from '../../../utils/helpers/helper';

import AsyncSelect from '../../reusables/field/AsyncSelect';
import Button from '../../reusables/element/Button';
import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Input from '../../reusables/field/Input';
import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import HeaderContext from '../../../app/contexts/HeaderContext';
import Loading from '../../reusables/template/Loading';
import pages from '../../pages';
import Badge from '../../reusables/element/Badge';
import Dropdown from '../../reusables/element/Dropdown';
import Media from '../../reusables/element/Media';
import Portal from '../../reusables/layout/Portal';
import SVGIcon from '../../reusables/element/SVGIcon';

const GroupFormModal = ({ data, isOpen, onSelect, onClose, parentGroup }) => {
	const [name, nameOnChange, nameValRes] = useField(data, 'name', () => {}, [required]);

	const [nameShowVal, setNameShowVal] = useState(false);

	useEffect(() => {
		setNameShowVal(false);
	}, [isOpen, setNameShowVal]);

	return (
		<Portal>
			<Popup
				open={isOpen}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
				}}
				closeOnDocumentClick={false}
				modal
				lockScroll
				onClose={onClose}>
				<Portlet>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon={pages.pos.productGrids.icon2}>
							{name ? `${name} Edit` : 'New Grid Group'}
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<FormGroup>
							<FormField
								name='name'
								label='Name'
								valRes={nameValRes}
								showValidation={nameShowVal}
								col={6}>
								<Input
									type='text'
									placeholder='Grid Group Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={() => setNameShowVal(true)}
								/>
							</FormField>
						</FormGroup>
					</Portlet.Body>
					<Portlet.Foot tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
							/>
						</div>
						<div className='col-auto'>
							<Button
								design='brand'
								text='OK'
								icon='Done-circle'
								size='sm'
								elevate
								key='saveGroup'
								onClick={() => {
									setNameShowVal(true);

									if (nameValRes.isValid) {
										// if grid group is exist wait for grid save to update it.
										if (data.id) {
											onSelect(data);
										} else {
											apiCall(
												'POST',
												'gridGroups',
												res => onSelect(res),
												err => {
													addErrorNotification(err.toString());
												},
												'',
												{
													name,
													sortOrder: 1,
													parentGridGroup: parseData(
														parentGroup,
														'gridGroups'
													),
												}
											);
										}
									}
								}}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};
GroupFormModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onSelect: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	parentGroup: PropTypes.object,
};

GroupFormModal.defaultProps = {
	parentGroup: null,
};

const SelectProductModal = ({ isOpen, onSelect, onClose, gridGroupProductId }) => {
	const [submitButtonText, setSubmitButtonText] = useState('OK');

	const [product, productOnChange, productValRes, productShowVal, setProductShowVal] = useField(
		{ product: null },
		'product',
		() => setSubmitButtonText(process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT),
		[required],
		undefined
	);

	return (
		<Portal>
			<Popup
				open={isOpen}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
				}}
				closeOnDocumentClick={false}
				modal
				lockScroll
				onClose={onClose}>
				<Portlet>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon={pages.pos.products.icon}>
							Add Product to Grid
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<FormGroup>
							<FormField
								name='name'
								label='Search Product'
								valRes={productValRes}
								showValidation={productShowVal}
								col={12}>
								<AsyncSelect
									options={[]}
									placeholder='Search and select product from list'
									value={product}
									onChange={productOnChange}
									onBlur={setProductShowVal}
									route='posProducts'
									optionalShow={option => {
										if (option.name)
											return `${option.name} - ${priceFormatter(
												option.price
											)}`;
										return '';
									}}
									renderOption={option => (
										<>
											{option.name} - {priceFormatter(option.price)}
										</>
									)}
									customFilters={{ inactive: false }}
								/>
							</FormField>
						</FormGroup>
					</Portlet.Body>
					<Portlet.Foot tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
							/>
						</div>
						<div className='col-auto'>
							<Button
								design='brand'
								text={submitButtonText}
								icon='Done-circle'
								size='sm'
								elevate
								key='addGroup'
								onClick={() => {
									setProductShowVal(true);
									setSubmitButtonText('Adding');

									if (productValRes.isValid) {
										setSubmitButtonText('Added');
										productOnChange({
											target: { name: 'product', value: {} },
										});

										setProductShowVal(false);

										onSelect({
											'@type': 'SdmsGridGroupProduct',
											'@id': `/api/sdms_grid_group_products/${gridGroupProductId}`,
											id: gridGroupProductId,
											product,
											sortOrder: 99,
										});
									}
								}}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};
SelectProductModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onSelect: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	gridGroupProductId: PropTypes.number.isRequired,
};

const GroupNode = ({
	group,
	onAddGroupClick,
	onAddProductClick,
	onEditGroupClick,
	onDeleteGroupClick,
	onImageClick,
}) => {
	return (
		<div className='sdms-sortable--item sdms-sortable--group'>
			<div className='sdms-sortable--item__left'>
				<div className='sdms-sortable--item__image'>
					{group.gridGroupImage ? (
						<>
							<img
								alt={group.name}
								src={
									group.gridGroupImage &&
									group.gridGroupImage.thumb &&
									pathToUrl(group.gridGroupImage.thumb)
								}
								role='presentation'
							/>
							<label className='sdms-image__upload'>
								<SVGIcon name='Edit' size={16} onClick={onImageClick} />
							</label>
						</>
					) : (
						<>
							<Badge
								design={randomColor(group.id)}
								className='sdms-font-transform-u'
								isUnified
								size='lg'
								isRounded
								fontWeight='bold'>
								{group.name.charAt(0)}
							</Badge>
							<label className='sdms-image__upload'>
								<SVGIcon name='Edit' size={16} onClick={onImageClick} />
							</label>
						</>
					)}
				</div>
				<div
					className='sdms-sortable--item__name'
					onClick={onEditGroupClick}
					role='presentation'>
					<Badge design='info' isInline isUnified size='lg' fontWeight='bolder'>
						{group.name}
					</Badge>
				</div>
			</div>
			<div className='sdms-sortable--item__right'>
				<div className='sdms-sortable--item__action'>
					<Button
						icon={pages.pos.productGrids.icon2}
						label='brand'
						text='Add Group'
						key='addGroup'
						size='sm'
						onClick={onAddGroupClick}
					/>
					<Button
						label='brand'
						icon={pages.pos.products.icon}
						text='Add Product'
						key='addProduct'
						size='sm'
						onClick={onAddProductClick}
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Product Group Actions</Dropdown.Header>
						<Dropdown.Item icon='Edit' key='eddGroup' onClick={onEditGroupClick}>
							Edit
						</Dropdown.Item>
						<Dropdown.Item
							itemsColor='danger'
							icon='Trash'
							key='deleteGroup'
							onClick={onDeleteGroupClick}>
							Remove
						</Dropdown.Item>
					</Dropdown>
				</div>
			</div>
		</div>
	);
};
GroupNode.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	group: PropTypes.object.isRequired,
	onAddGroupClick: PropTypes.func.isRequired,
	onAddProductClick: PropTypes.func.isRequired,
	onEditGroupClick: PropTypes.func.isRequired,
	onDeleteGroupClick: PropTypes.func.isRequired,
	onImageClick: PropTypes.func.isRequired,
};

const ProductNode = ({ gridGroupProduct, onDelete }) => {
	return (
		<div className='sdms-sortable--item sdms-sortable--product'>
			<div className='sdms-sortable--item__left'>
				<div className='sdms-sortable--item__image'>
					{gridGroupProduct.product.productImage ? (
						<img
							alt={gridGroupProduct.product.name}
							src={
								gridGroupProduct.product.productImage &&
								gridGroupProduct.product.productImage.thumb &&
								pathToUrl(gridGroupProduct.product.productImage.thumb)
							}
						/>
					) : (
						<Badge
							design={randomColor(gridGroupProduct.id)}
							className='sdms-font-transform-u'
							isUnified
							size='lg'
							isRounded
							fontWeight='bold'>
							{gridGroupProduct.product.name.charAt(0)}
						</Badge>
					)}
				</div>
				<div className='sdms-sortable--item__name'>{gridGroupProduct.product.name}</div>
			</div>
			<div className='sdms-sortable--item__right'>
				<div className='sdms-sortable--item__price'>
					{priceFormatter(gridGroupProduct.product.price)}
				</div>
				<div className='sdms-sortable--item__action'>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Product Item Actions</Dropdown.Header>
						<Dropdown.Item
							itemsColor='danger'
							icon='Trash'
							key='deleteGroup'
							onClick={onDelete}>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</div>
			</div>
		</div>
	);
};
ProductNode.propTypes = {
	gridGroupProduct: PropTypes.shape({
		id: PropTypes.number,
		product: PropTypes.object,
	}).isRequired,
	onDelete: PropTypes.func.isRequired,
};

const GridForm = ({
	data,
	setIsValid,
	submit,
	isSubmitted,
	setTitle,
	isLoading,
	onFormChange,
	submitButtonAttr,
	isSubmitting,
}) => {
	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const getGroupNode = _group => (
		<GroupNode
			group={_group}
			onAddGroupClick={() =>
				dispatch({
					type: 'openGroupModal',
					group: { id: 0 },
					parentGridGroup: _group,
				})
			}
			onEditGroupClick={() =>
				dispatch({
					type: 'openGroupModal',
					group: _group,
					parentGridGroup: _group.parentGridGroup,
				})
			}
			onAddProductClick={() =>
				dispatch({
					type: 'openProductModal',
					parentGridGroup: _group,
				})
			}
			onDeleteGroupClick={() => dispatch({ type: 'deleteGroup', payload: _group.id })}
			onImageClick={() =>
				dispatch({
					type: 'openMediaModal',
					payload: _group.id,
				})
			}
		/>
	);

	const getProductNode = product => (
		<ProductNode
			gridGroupProduct={product}
			onDelete={() => dispatch({ type: 'deleteProduct', payload: product.id })}
		/>
	);

	const parseGridGroups = gridGroups => {
		return JSON.parse(JSON.stringify(gridGroups)).map(gg => {
			if (gg.parentGridGroup) {
				delete gg.parentGridGroup.gridGroupProducts;
			}
			gg.gridGroupProducts = gg.gridGroupProducts.map(ggp => {
				delete ggp['@id'];
				delete ggp.id;
				return ggp;
			});

			return gg;
		});
	};

	const generateGridGroupProductId = gridGroup => {
		if (!gridGroup || !gridGroup.gridGroupProducts) return 0;
		return (generateId(gridGroup.gridGroupProducts) - 1) * Math.abs(gridGroup.id);
	};

	const reducer = (state, action) => {
		const { type, payload, group, parentGridGroup } = { ...action };

		const getKey = id => {
			let key = null;

			walk({
				treeData: state.treeData,
				getNodeKey: ({ treeIndex }) => treeIndex,
				callback: ({ node, path }) => {
					if (node['@id'] === id) key = path[path.length - 1];
				},
				ignoreCollapsed: false,
			});

			return key;
		};

		const getPath = (id, _type, treeData = null) => {
			let _path = null;

			walk({
				treeData: treeData || state.treeData,
				getNodeKey: ({ treeIndex }) => treeIndex,
				callback: ({ node, path }) => {
					if (node.id === id && node['@type'] === _type) _path = path;
				},
				ignoreCollapsed: false,
			});

			return _path;
		};

		const updateSortOrders = (treeData, gridGroups) => {
			const flatData = getFlatDataFromTree({
				treeData,
				getNodeKey: node => node['@id'],
				ignoreCollapsed: false,
			});

			flatData.forEach(item => {
				gridGroups = gridGroups.map(gg => {
					if (item.node['@id'] === gg['@id']) gg.sortOrder = item.treeIndex + 2;
					gg.gridGroupProducts = gg.gridGroupProducts.map(ggp => {
						if (ggp['@id'] === item.node['@id']) ggp.sortOrder = item.treeIndex + 2;
						return ggp;
					});

					return gg;
				});
			});

			return gridGroups;
		};

		if (type === 'init') {
			const flatData = [];

			state.gridGroups = payload.map(gg => {
				flatData.push(update(gg, { $unset: ['gridGroupProducts'] }));

				gg.gridGroupProducts.forEach(ggp =>
					flatData.push({
						parentGridGroup: { '@id': gg['@id'] },
						...ggp,
					})
				);

				return gg.parentGridGroup
					? update(gg, {
							parentGridGroup: { $unset: ['gridGroupImage', 'sortOrder', 'name'] },
					  })
					: gg;
			});

			const treeData = getTreeFromFlatData({
				flatData: flatData.sort((a, b) => a.sortOrder - b.sortOrder),
				getKey: node => node['@id'],
				getParentKey: node => {
					return node.parentGridGroup ? node.parentGridGroup['@id'] : 0;
				},
			});

			return update(state, {
				treeData: {
					$set: treeData[0] && treeData[0].children ? treeData[0].children : [],
				},
			});
		}

		if (type === 'openGroupModal') {
			return update(state, {
				willEditGroup: { $set: group },
				isGroupModalOpen: { $set: true },
				parentGridGroup: { $set: parentGridGroup },
			});
		}

		if (type === 'closeGroupModal') {
			return update(state, {
				willEditGroup: { $set: { id: 0 } },
				isGroupModalOpen: { $set: false },
				parentGridGroup: { $set: null },
			});
		}

		if (type === 'openProductModal') {
			return update(state, {
				parentGridGroup: { $set: parentGridGroup },
				isSelectProductModalOpen: { $set: true },
			});
		}

		if (type === 'closeProductModal') {
			return update(state, {
				parentGridGroup: { $set: null },
				isSelectProductModalOpen: { $set: false },
			});
		}

		if (type === 'openMediaModal') {
			return update(state, { willEditGroupImage: { $set: payload } });
		}

		if (type === 'closeMediaModal') {
			return update(state, { willEditGroupImage: { $set: null } });
		}

		if (type === 'updateGroupImage') {
			walk({
				treeData: state.treeData,
				getNodeKey: ({ treeIndex }) => treeIndex,
				callback: ({ node }) => {
					if (node.id === state.willEditGroupImage && node['@type'] === 'SdmsGridGroup')
						node.gridGroupImage = payload;
				},
				ignoreCollapsed: false,
			});

			state.gridGroups = state.gridGroups.map(gg => {
				if (gg.id === state.willEditGroupImage) gg.gridGroupImage = payload;
				return gg;
			});

			data.gridGroups = [...state.gridGroups];

			return update(state, {
				willEditGroupImage: { $set: null },
			});
		}

		if (type === 'addGroup') {
			onFormChange();

			const newTreeData = addNodeUnderParent({
				treeData: state.treeData,
				newNode: payload,
				parentKey: getKey(state.parentGridGroup['@id']),
				getNodeKey: ({ treeIndex }) => treeIndex,
				ignoreCollapsed: false,
				expandParent: true,
			});

			state = update(state, {
				gridGroups: {
					$push: [payload],
				},
				treeData: { $set: newTreeData.treeData },
				parentGridGroup: { $set: null },
				willEditGroup: { $set: { id: 0 } },
				isGroupModalOpen: { $set: false },
			});

			state.gridGroups = updateSortOrders(newTreeData.treeData, state.gridGroups);

			data.gridGroups = parseGridGroups(state.gridGroups);

			return state;
		}

		if (type === 'editGroup') {
			const gridGroupIndex = state.gridGroups.findIndex(gg => gg.id === payload.id);

			walk({
				treeData: state.treeData,
				getNodeKey: ({ treeIndex }) => treeIndex,
				callback: ({ node }) => {
					if (node.id === payload.id && node['@type'] === 'SdmsGridGroup')
						node.name = payload.name;
				},
				ignoreCollapsed: false,
			});

			state = update(state, {
				gridGroups: { [gridGroupIndex]: { name: { $set: payload.name } } },
			});

			data.gridGroups = [...state.gridGroups];

			return update(state, {
				parentGroup: { $set: null },
				willEditGroup: { $set: { id: 0 } },
				isGroupModalOpen: { $set: false },
			});
		}

		if (type === 'deleteGroup') {
			onFormChange();

			const newTreeData = removeNode({
				treeData: state.treeData,
				path: getPath(payload, 'SdmsGridGroup'),
				getNodeKey: ({ treeIndex }) => treeIndex,
				ignoreCollapsed: false,
			});

			state.gridGroups = state.gridGroups.filter(gg => gg.id !== payload);

			updateSortOrders(newTreeData.treeData, state.gridGroups);

			data.gridGroups = [...state.gridGroups];

			return update(state, {
				treeData: { $set: newTreeData.treeData },
			});
		}

		if (type === 'addProduct') {
			const gridGroupIndex = state.gridGroups.findIndex(
				gg => gg.id === state.parentGridGroup.id
			);

			if (
				state.gridGroups[gridGroupIndex].gridGroupProducts.findIndex(
					ggp => ggp.id === payload.id
				) > -1
			)
				return state;

			onFormChange();

			const newTreeData = addNodeUnderParent({
				treeData: state.treeData,
				newNode: {
					...payload,
					parentGridGroup: update(state.parentGridGroup, {
						$unset: [
							'parentGridGroup',
							'gridGroupImage',
							'sortOrder',
							'name',
							'gridGroupProducts',
							'children',
						],
					}),
				},
				parentKey: getKey(state.parentGridGroup['@id']),
				getNodeKey: ({ treeIndex }) => treeIndex,
				expandParent: true,
				ignoreCollapsed: false,
			});

			state = update(state, {
				gridGroups: {
					[gridGroupIndex]: {
						gridGroupProducts: {
							$push: [payload],
						},
					},
				},
			});

			updateSortOrders(newTreeData.treeData, state.gridGroups);

			data.gridGroups = parseGridGroups(state.gridGroups);

			return update(state, {
				treeData: { $set: newTreeData.treeData },
				parentGridGroup: { $set: null },
				isSelectProductModalOpen: { $set: false },
			});
		}

		if (type === 'deleteProduct') {
			onFormChange();

			if (!getPath(payload, 'SdmsGridGroupProduct')) return state;

			const newTreeData = removeNode({
				treeData: state.treeData,
				path: getPath(payload, 'SdmsGridGroupProduct'),
				getNodeKey: ({ treeIndex }) => treeIndex,
				ignoreCollapsed: false,
			});

			state.gridGroups = state.gridGroups.map(gg => {
				gg.gridGroupProducts = gg.gridGroupProducts.filter(ggp => ggp.id !== payload);
				return gg;
			});

			updateSortOrders(newTreeData.treeData, state.gridGroups);

			data.gridGroups = parseGridGroups(state.gridGroups);

			return update(state, {
				treeData: { $set: newTreeData.treeData },
			});
		}

		if (type === 'onTreeChange') {
			onFormChange();
			const flatData = getFlatDataFromTree({
				treeData: payload,
				getNodeKey: node => node['@id'],
				ignoreCollapsed: false,
			});

			const rootGridGroupIndex = state.gridGroups.findIndex(gg => gg.name === 'root');

			state.gridGroups = state.gridGroups.map(gg => {
				gg.gridGroupProducts = [];
				flatData.forEach(td => {
					if (
						td.node['@type'] === 'SdmsGridGroupProduct' &&
						((td.parentNode && td.parentNode.id === gg.id) ||
							(td.parentNode === null &&
								gg.id === state.gridGroups[rootGridGroupIndex].id))
					) {
						td.node.sortOrder = td.treeIndex + 2;

						gg.gridGroupProducts.push(
							update(td.node, {
								$unset: ['title', 'expanded', 'parentGridGroup'],
							})
						);
					}

					if (td.node['@type'] === 'SdmsGridGroup' && td.node.id === gg.id) {
						gg.sortOrder = td.treeIndex + 2;
						gg.parentGridGroup = update(
							td.parentNode ? td.parentNode : state.gridGroups[rootGridGroupIndex],
							{
								$unset: [
									'title',
									'expanded',
									'parentGridGroup',
									'children',
									'gridGroupProducts',
									'gridGroupImage',
									'sortOrder',
								],
							}
						);
					}
				});

				return gg;
			});

			data.gridGroups = parseGridGroups(state.gridGroups);

			return update(state, { treeData: { $set: payload } });
		}

		if (type === 'expandAll')
			return update(state, {
				treeData: {
					$set: toggleExpandedForAll({ treeData: state.treeData, expanded: true }),
				},
			});

		if (type === 'collapseAll')
			return update(state, {
				treeData: {
					$set: toggleExpandedForAll({ treeData: state.treeData, expanded: false }),
				},
			});

		return state;
	};

	const [grid, dispatch] = useReducer(reducer, {
		gridGroups: [],
		treeData: [],
		parentGridGroup: null,
		willEditGroup: { id: 0 },
		isGroupModalOpen: false,
		isSelectProductModalOpen: false,
		willEditGroupImage: null,
	});

	useEffect(() => {
		if (isSubmitted) setNameShowVal();
	}, [isSubmitted, setNameShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid);
	}, [nameValRes.isValid, setIsValid]);

	useEffect(() => {
		// update state after save.
		if (!isSubmitting && !isLoading && data.gridGroups)
			dispatch({ type: 'init', payload: data.gridGroups });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitting]);

	useEffect(() => {
		if (!isLoading) {
			if (data.gridGroups && data.gridGroups.length)
				dispatch({ type: 'init', payload: data.gridGroups });
			else {
				apiCall(
					'POST',
					'gridGroups',
					res => {
						dispatch({ type: 'init', payload: [res] });
					},
					err => {
						addErrorNotification(err.toString());
					},
					'',
					{
						name: 'root',
						sortOrder: 1,
						parentGridGroup: null,
						gridGroupImage: null,
					}
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.pos.default.text, path: pages.pos.dashboard.path },
			{ title: pages.pos.productGrids.text, path: pages.pos.productGrids.path },
			{ title: name, isActive: true },
		]);

		headerContext.setPageTitle(name);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	const portletRef = useRef();
	const portletSize = useComponentSize(portletRef);

	const gridBody = createRef();
	const gridBodySize = useComponentSize(gridBody);

	const isLastInGroup = (node, tree) => {
		if (!tree || tree.length < 1) return false;

		if (tree[tree.length - 1].id === node.id) return true;

		for (let i = 0; i < tree.length; i += 1) {
			if (tree[i].children) {
				if (isLastInGroup(node, tree[i].children)) return true;
			}
		}

		return false;
	};

	const getNodeClassName = node => {
		if (node['@type'] === 'SdmsGridGroup') return 'sdms-grid-group';

		if (isLastInGroup(node, grid.treeData))
			return 'sdms-grid-group-product sdms-grid-group-last-product';

		return 'sdms-grid-group-product';
	};

	return (
		<>
			<Portlet className='flex-grow-0' hasFrame style={{ minHeight: 166.89 }}>
				<Portlet.Body>
					<form>
						<Section title='Product Grid Settings' last>
							<Section.Body>
								<FormGroup isLast>
									<Loading isLoading={isLoading}>
										<FormField
											name='name'
											label='Name'
											id={data.id}
											valRes={nameValRes}
											showValidation={nameShowVal}
											colMd={6}
											isLast>
											<Input
												type='text'
												placeholder='Product Grid Name (Required)'
												value={name}
												onChange={nameOnChange}
												onBlur={setNameShowVal}
											/>
										</FormField>
									</Loading>
								</FormGroup>
							</Section.Body>
						</Section>
					</form>
				</Portlet.Body>
			</Portlet>
			<Portlet className='sdms-form' fluid='fluid' everyTimeFluid hasFrame>
				<Portlet.Head wrapMaxSize='md'>
					<Portlet.HeadLabel portletIcon={pages.pos.productGrids.icon}>
						<h3 className='sdms-portlet__head-title'>Product Grid</h3>
						<Portlet.Separator />
						<div className='sdms-portlet__head-desc'>
							<Button
								className='sdms-margin-r-15'
								design='clean'
								icon='Git#4'
								text='Expand All'
								key='expandAll'
								size='sm'
								onClick={() => dispatch({ type: 'expandAll' })}
							/>
							<Button
								design='clean'
								icon='Git#1'
								text='Collapse All'
								key='collapseAll'
								size='sm'
								onClick={() => dispatch({ type: 'collapseAll' })}
							/>
						</div>
					</Portlet.HeadLabel>
					<Portlet.HeadToolbar>
						<Portlet.HeadActions>
							<Button
								className='sdms-margin-r-15'
								icon={pages.pos.productGrids.icon2}
								label='brand'
								text='Add Group'
								key='addGroup'
								size='sm'
								onClick={() => {
									dispatch({
										type: 'openGroupModal',
										group: { id: 0 },
										parentGridGroup: grid.gridGroups.filter(
											gg => gg.name === 'root'
										)[0],
									});
								}}
							/>
							<Button
								label='brand'
								icon={pages.pos.products.icon}
								text='Add Product'
								key='addProduct'
								size='sm'
								onClick={() => {
									dispatch({
										type: 'openProductModal',
										parentGridGroup: grid.gridGroups.filter(
											gg => gg.name === 'root'
										)[0],
									});
								}}
							/>
						</Portlet.HeadActions>
					</Portlet.HeadToolbar>
				</Portlet.Head>
				<Portlet.Body
					className='sdms-portlet__body--fit sdms-pl20 overflow-hidden'
					ref={gridBody}>
					<form>
						<div style={{ height: gridBodySize.height - portletSize.height }}>
							{!isLoading && (
								<SortableTree
									treeData={grid.treeData}
									onChange={treeData => {
										dispatch({ type: 'onTreeChange', payload: treeData });
									}}
									canDrop={({ nextParent }) => {
										return nextParent
											? nextParent['@type'] === 'SdmsGridGroup'
											: true;
									}}
									canNodeHaveChildren={node => node['@type'] === 'SdmsGridGroup'}
									generateNodeProps={({ node }) => {
										return {
											className: getNodeClassName(node),
											title:
												node['@type'] === 'SdmsGridGroup'
													? getGroupNode(
															grid.gridGroups.find(
																gg => node.id === gg.id
															)
													  )
													: getProductNode(node),
										};
									}}
								/>
							)}
						</div>
					</form>
				</Portlet.Body>
				<Portlet.Foot type='form' tall='sm'>
					<Button
						label={submitButtonAttr.color}
						text={submitButtonAttr.text}
						icon={submitButtonAttr.icon}
						size='sm'
						className={classNames('sdms-mw-100', {
							'sdms-fading-dots':
								submitButtonAttr.text ===
								process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
						})}
						onClick={submit}
					/>
				</Portlet.Foot>
			</Portlet>
			<GroupFormModal
				key={grid.willEditGroup.id}
				onClose={() => dispatch({ type: 'closeGroupModal' })}
				onSelect={group => {
					dispatch({
						type: grid.willEditGroup.id ? 'editGroup' : 'addGroup',
						payload: group,
					});
				}}
				isOpen={grid.isGroupModalOpen}
				parentGroup={grid.parentGridGroup}
				data={grid.willEditGroup}
				treeData={grid.treeData}
			/>
			<SelectProductModal
				onClose={() => dispatch({ type: 'closeProductModal' })}
				gridGroup={grid.parentGridGroup}
				onSelect={gridGroupProduct =>
					dispatch({ type: 'addProduct', payload: gridGroupProduct })
				}
				isOpen={grid.isSelectProductModalOpen}
				treeData={grid.treeData}
				gridGroupProductId={generateGridGroupProductId(grid.parentGridGroup)}
			/>
			<Media
				close={() => dispatch({ type: 'closeMediaModal' })}
				setMedia={media => dispatch({ type: 'updateGroupImage', payload: media })}
				isOpen={grid.willEditGroupImage !== null}
			/>
		</>
	);
};
GridForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		gridGroups: PropTypes.array,
	}),
	isLoading: PropTypes.bool,
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
	isSubmitting: PropTypes.bool,
};
GridForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		gridGroups: [],
	},
	isLoading: true,
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
	isSubmitting: false,
};

export default GridForm;
