import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useField from '../../../utils/hooks/useField';
import { numeric, required } from '../../../utils/helpers/validation';
import { parseEnumCalculationLabel } from '../../../utils/helpers/helper';

import Button from './Button';
import { ListBody, ListTable } from '../template/List';
import FormField from '../template/FormField';
import Portlet from '../layout/Portlet';
import Input from '../field/Input';
import Selects from '../field/Selects';

const DynamicPriceItem = ({
	data,
	disabled,
	onDelete,
	onChange,
	isSubmitted,
	enumCalculations,
}) => {
	const [
		pricePoint,
		pricePointOnChange,
		pricePointValRes,
		pricePointShowVal,
		setPricePointShowVal,
	] = useField(data, 'pricePoint', () => {}, [required, numeric]);

	const [
		adjustmentAmount,
		adjustmentAmountOnChange,
		adjustmentAmountValRes,
		adjustmentAmountShowVal,
		setAdjustmentAmountShowVal,
	] = useField(data, 'adjustmentAmount', () => {}, [required, numeric]);

	const [type, typeOnChange, typeValRes, typeShowVal, setTypeShowVal] = useField(
		data,
		'type',
		() => {},
		[required]
	);

	useEffect(() => {
		onChange({
			id: data.id,
			pricePoint: pricePointValRes.isValid ? parseFloat(pricePoint) : pricePoint,
			adjustmentAmount: adjustmentAmountValRes.isValid
				? parseFloat(adjustmentAmount)
				: adjustmentAmount,
			type,
			isValid:
				pricePointValRes.isValid && adjustmentAmountValRes.isValid && typeValRes.isValid,
		});
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [
		pricePoint,
		adjustmentAmount,
		type,
		pricePointValRes.isValid,
		adjustmentAmountValRes.isValid,
		typeValRes.isValid,
	]);

	useEffect(() => {
		if (isSubmitted) {
			setPricePointShowVal();
			setAdjustmentAmountShowVal();
			setTypeShowVal();
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isSubmitted]);

	return (
		<tr className={classNames({ disabled })}>
			<td>
				<FormField
					name='pricePoint'
					id={data.id}
					valRes={pricePointValRes}
					showValidation={pricePointShowVal}
					inFormDesign={false}
					isLast>
					<Input
						placeholder='Price Point (Required)'
						value={pricePoint}
						onChange={pricePointOnChange}
						onBlur={setPricePointShowVal}
						disabled={disabled}
						pattern={process.env.REACT_APP_PRICE_PATTERN}
						append='Percent'
					/>
				</FormField>
			</td>
			<td>
				<FormField
					name='adjustmentAmount'
					id={data.id}
					valRes={adjustmentAmountValRes}
					showValidation={adjustmentAmountShowVal}
					inFormDesign={false}
					isLast>
					<Input
						placeholder='	Amount (Required)'
						value={adjustmentAmount}
						onChange={adjustmentAmountOnChange}
						onBlur={setAdjustmentAmountShowVal}
						disabled={disabled}
						pattern={process.env.REACT_APP_PRICE_PATTERN}
					/>
				</FormField>
			</td>
			<td>
				<FormField
					name='type'
					id={data.id}
					valRes={typeValRes}
					showValidation={typeShowVal}
					inFormDesign={false}
					isLast>
					<Selects
						options={enumCalculations.map(parseEnumCalculationLabel)}
						name='type'
						placeholder='Type'
						value={parseEnumCalculationLabel(type)}
						onChange={typeOnChange}
						onBlur={setTypeShowVal}
						displayKey='label'
					/>
				</FormField>
			</td>
			<td>
				<Button
					btnIcon
					label='danger'
					icon='Trash'
					size='sm'
					elevate
					key='delete'
					onClick={onDelete}
					disabled={disabled}
				/>
			</td>
		</tr>
	);
};

DynamicPriceItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
	onDelete: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	isSubmitted: PropTypes.bool.isRequired,
	enumCalculations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const DynamicPriceList = ({
	dynamicPrices,
	onAdd,
	onChange,
	onDelete,
	disabled,
	isSubmitted,
	displayTitle,
	enumCalculations,
}) => {
	return (
		<div className='col-12'>
			<Portlet className='sdms-list-layout sdms-portlet--section' border>
				<Portlet.Head wrapMaxSize='lg'>
					<Portlet.HeadLabel portletIcon='Dollar'>
						{displayTitle && (
							<h3 className='sdms-portlet__head-title'>Dynamic Prices</h3>
						)}
					</Portlet.HeadLabel>
					<Portlet.HeadToolbar>
						<Portlet.HeadActions>
							<Button
								label='brand'
								text='Add New'
								icon='Plus'
								onClick={() => onAdd()}
							/>
						</Portlet.HeadActions>
					</Portlet.HeadToolbar>
				</Portlet.Head>
				<Portlet.Body>
					<ListBody
						className='table--everytime--scroll sdms-portlet__body--fit'
						responsive='scroll'>
						<ListTable>
							<colgroup>
								<col />
								<col />
								<col />
								<col />
							</colgroup>
							<thead>
								<tr>
									<th className='sdms-bg-transparent'>Price Point</th>
									<th className='sdms-bg-transparent'>Amount</th>
									<th className='sdms-bg-transparent'>Type</th>
									<th className='sdms-bg-transparent'>Actions</th>
								</tr>
							</thead>
							<tbody>
								{dynamicPrices.map(pm => (
									<DynamicPriceItem
										key={pm.id}
										data={pm}
										onChange={onChange}
										onDelete={() => onDelete(pm.id)}
										disabled={disabled}
										isSubmitted={isSubmitted}
										enumCalculations={enumCalculations}
									/>
								))}
							</tbody>
						</ListTable>
					</ListBody>
				</Portlet.Body>
			</Portlet>
		</div>
	);
};

DynamicPriceList.propTypes = {
	dynamicPrices: PropTypes.arrayOf(PropTypes.object),
	onAdd: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	isSubmitted: PropTypes.bool,
	displayTitle: PropTypes.bool,
	enumCalculations: PropTypes.arrayOf(PropTypes.object),
};
DynamicPriceList.defaultProps = {
	dynamicPrices: [],
	disabled: false,
	isSubmitted: false,
	displayTitle: false,
	enumCalculations: [],
};

export default DynamicPriceList;
