import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { addErrorNotification, addSuccessNotification } from '../../../../utils/helpers/helper';
import {
	nonTransientAdvancedFormSteps,
	spaceAssigmentTypes,
} from '../../../../utils/constants/constants';
import useModal from '../../../../utils/hooks/useModal';
import apiCall from '../../../../utils/helpers/apiCall';
import { displaySpaceAssignmentTODate } from '../../../../utils/helpers/reservationHelper';
import { nonTransientAdvancedFormPages } from '../../../pages';
import useDate from '../../../../utils/hooks/useDate';

import List from '../../template/List';
import Dropdown from '../../element/Dropdown';
import Badge from '../../element/Badge';
import ListContainer from '../../template/ListContainer';
import Button from '../../element/Button';
import SpaceAssignmentEdit from './SpaceAssignmentEdit';
import DialogBox from '../../element/DialogBox';
import NullBadge from '../../design/NullBadge';

const modals = {
	DELETE_DIALOG: 'deleteDialog',
};

const DateCell = ({ data, field }) => {
	const [, utcDateFormatter] = useDate();

	if (field === 'fromDate' || displaySpaceAssignmentTODate(data, data.type))
		return utcDateFormatter(data[field]);

	return null;
};

const TypeCell = ({ data }) => {
	return (
		<Badge
			design={data.type.value === spaceAssigmentTypes.TEMPORARY_DEPARTED ? 'dark' : 'info'}
			isInline
			isUnified
			fontWeight='bold'
			size='lg'
			className='sdms-text-overflow'>
			<span className='sdms-text-overflow'>
				{data.type.value === spaceAssigmentTypes.TEMPORARY_DEPARTED
					? data.type.value
					: 'Movement'}
			</span>
		</Badge>
	);
};
TypeCell.propTypes = {
	data: PropTypes.shape({
		type: PropTypes.object,
	}),
};
TypeCell.defaultProps = {
	data: {
		type: null,
	},
};

const SpaceCell = ({ data }) => {
	return data.space && data.type.value !== spaceAssigmentTypes.TEMPORARY_DEPARTED ? (
		data.space.name
	) : (
		<NullBadge />
	);
};

SpaceCell.propTypes = {
	data: PropTypes.shape({
		space: PropTypes.object,
		type: PropTypes.object,
	}),
};

SpaceCell.defaultProps = {
	data: {
		space: null,
		type: null,
	},
};

const SpaceAssignmentList = ({ filters, presetData, onChange, disabled, headerActions }) => {
	const [isDeleting, setIsDeleting] = useState(false);

	const [modal, openModal, closeModal] = useModal();

	const onDelete = () => {
		setIsDeleting(true);
		apiCall(
			'POST',
			'deleteSpaceAssignment',
			() => {
				addSuccessNotification('Space assignment successfully deleted.');

				if (modal.updateData) modal.updateData([], [modal.spaceAssignment]);

				closeModal();
				setIsDeleting(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsDeleting(false);
			},
			'',
			{ id: modal.spaceAssignment.id }
		);
	};

	return (
		<>
			<ListContainer
				route='spaceAssignments'
				title={nonTransientAdvancedFormSteps.SPACE_ASSIGNMENT}
				icon={
					nonTransientAdvancedFormPages.find(
						s => s.title === nonTransientAdvancedFormSteps.SPACE_ASSIGNMENT
					).icon
				}
				staticFilters={filters}
				isTabList
				largeQuickPanel
				hasSearch={false}
				forms={
					disabled
						? []
						: [
								{
									name: 'add',
									component: SpaceAssignmentEdit,
									isQuickPanel: true,
									button: (
										<Button
											className='sdms-ml-10'
											icon='Plus'
											design='brand'
											text='New Space Assignment'
											size='sm'
										/>
									),
								},
						  ]
				}
				presetData={presetData}
				onChange={onChange}
				customActions={{
					delete: (spaceAssignment, updateData) => {
						openModal({ open: modals.DELETE_DIALOG, spaceAssignment, updateData });
					},
				}}
				headerActions={headerActions}
				fluid='fluid'>
				<List
					fluid='fluid'
					withCheckBox={false}
					withOutPortlet
					checkEditable={() => {
						return disabled ? [] : ['edit', 'delete'];
					}}>
					<List.Col label='id' cellData='id' isLinkColumn sortable='id' />
					<List.Col
						label='Start Date'
						cellComponent={<DateCell field='fromDate' />}
						sortable='fromDate'
					/>
					<List.Col
						label='End Date'
						cellComponent={<DateCell field='toDate' />}
						sortable='toDate'
					/>
					<List.Col label='Type' cellComponent={<TypeCell />} />
					<List.Col label='Space' cellComponent={<SpaceCell />} />
					<List.Col align='right' onlyHover width={150}>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}
							disabled={disabled}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item icon='Edit' text='Edit' key='edit'>
								Edit
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='danger'
								icon='Error-circle'
								text='Delete'
								key='delete'>
								Delete
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			<DialogBox
				open={modal.open === modals.DELETE_DIALOG}
				title='Void'
				content='Are you sure you want to delete'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't Delete!`}
					onClick={closeModal}
					disabled={isDeleting}
				/>
				<Button
					className='sdms-font-transform-c'
					label='danger'
					icon='Trash'
					text='Yes, Delete!'
					onClick={onDelete}
					disabled={isDeleting}
				/>
			</DialogBox>
		</>
	);
};
SpaceAssignmentList.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	filters: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	presetData: PropTypes.object,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	headerActions: PropTypes.arrayOf(PropTypes.object),
};
SpaceAssignmentList.defaultProps = {
	filters: null,
	presetData: null,
	onChange: () => {},
	disabled: false,
	headerActions: [],
};

export default SpaceAssignmentList;
