import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import forms from '../../forms';
import { modules } from '../../../utils/helpers/apiCall';

import ListContainer from '../../reusables/template/ListContainer';
import List from '../../reusables/template/List';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import { PeriodCell } from './ProductBookingList';

const RatePlanList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	const pages = usePages();

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.booking.default.text, path: pages.booking.dashboard.path },
			{ title: pages.booking.settings.text, path: pages.booking.settings.path },
			{ title: pages.booking.settings.ratePlans.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.booking.settings.ratePlans.text);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);
	return (
		<ListContainer
			route='ratePlans'
			title={pages.booking.settings.ratePlans.text}
			history={history}
			icon={pages.booking.settings.ratePlans.icon}
			forms={forms.booking.settings.ratePlans}
			module={modules.BOOKINGS}>
			<List withCheckBox fluid='fluid'>
				<List.Col
					label='Internal Name'
					cellData='internalName'
					isLinkColumn
					sortable='internalName'
				/>
				<List.Col
					label='External Name'
					cellData='externalName'
					isLinkColumn
					sortable='externalName'
				/>
				<List.Col
					label='Period'
					cellComponent={<PeriodCell />}
					sortable='bookingPeriod.value'
				/>
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

RatePlanList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default RatePlanList;
