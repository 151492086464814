import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Card from 'react-credit-cards';
import Payment from 'payment';
import Scanner from './Scanner';
import {
	addErrorNotification,
	creditCardOption,
	creditCardParser,
} from '../../../utils/helpers/helper';
import Input from '../field/Input';
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from '../modals/utils';
import useField from '../../../utils/hooks/useField';
import { maxLength, minLength, required } from '../../../utils/helpers/validation';
import FormGroup from '../layout/FormGroup';
import FormField from '../template/FormField';
import Selects from '../field/Selects';
import Toggle from '../field/Toggle';

const CreditCardForm = ({
	onChange,
	isDisabled,
	isAuthorizing,
	creditCards,
	withLabel,
	disableScanner,
	autoComplete,
	displayInputPrependIcon,
	isSubmitted,
	displaySaveCardToggle,
	displayDefaultToggle,
	disableDefault,
	customerName,
	fullWidth,
}) => {
	const [isReading, setIsReading] = useState(false);

	const [activeContent, setActiveContent] = useState(disableScanner ? 'creditCard' : 'swipe');

	const [focused, setFocused] = useState('');

	const [swipe, swipeOnChange] = useField({}, 'swipe', () => {}, [], null);

	const [isSearchingCreditCard, setIsSearchingCreditCard] = useState(false);

	const handleInputFocus = ({ target }) => setFocused(target.name);

	const customRequired = () => {
		return (value, setValRes) => {
			if (!creditCard && !isDisabled) {
				return required(value, setValRes);
			}
			return true;
		};
	};

	const [
		cardNumber,
		cardNumberOnChange,
		cardNumberValRes,
		cardNumberShowVal,
		setCardNumberShowVal,
		validateCardNumber,
	] = useField({}, 'cardNumber', () => {}, [
		customRequired(),
		minLength(12, false),
		maxLength(19, false),
	]);

	const [
		cardName,
		cardNameOnChange,
		cardNameValRes,
		cardNameShowVal,
		setCardNameShowVal,
		validateCardName,
	] = useField({}, 'cardName', () => {}, [customRequired()], customerName);

	const [
		expiry,
		expiryOnChange,
		expiryValRes,
		expiryShowVal,
		setExpiryShowVal,
		validateExpiry,
	] = useField({}, 'expiry', () => {}, [customRequired()]);

	const [cvc, cvcOnChange, cvcValRes, cvcShowVal, setCvcShowVal, validateCvc] = useField(
		{},
		'cvc',
		() => {},
		[customRequired()]
	);

	const [creditCard, creditCardOnChange] = useField({}, 'creditCard', () => {}, [], null);

	const [save, saveOnChange] = useField({}, 'save', () => {}, [], false);

	const [makeDefault, makeDefaultOnChange] = useField(
		{},
		'makeDefault',
		() => {},
		[],
		displayDefaultToggle
	);

	useEffect(() => {
		onChange({
			swipe,
			cardNumber,
			cardName,
			cvc,
			expiry,
			creditCard,
			save,
			makeDefault,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [swipe, cardNumber, cardName, cvc, expiry, creditCard, save, makeDefault]);

	useEffect(() => {
		if (isSubmitted) {
			setCardNumberShowVal();
			setCardNameShowVal();
			setCvcShowVal();
			setExpiryShowVal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	useEffect(() => {
		if (creditCard || isSubmitted) {
			validateCardNumber(cardNumber);
			validateCardName(cardName);
			validateExpiry(expiry);
			validateCvc(cvc);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [creditCard]);

	return (
		<form>
			<div
				className={classNames('row', 'sdms-d-block-mobile', {
					'sdms-cc-swipe--animation': isReading,
					'sdms-cc-swipe--reading': isReading,
					'sdms-cc-swipe--authorizing': isAuthorizing,
					'sdms-cc-swipe--swiped': !!swipe,
				})}>
				<div
					className={classNames(
						'sdms-cursor--pointer sdms-mb-10-mobile sdms-padding-l-15 sdms-padding-r-15',
						{
							'col-lg-6': !fullWidth || activeContent === 'creditCard',
							'col-lg-12': fullWidth && activeContent !== 'creditCard',
						}
					)}>
					{creditCards.length > 0 && (
						<FormField
							className='sdms-paddingless'
							name='savedCards'
							id='savedCards'
							col={12}
							label={withLabel ? 'Saved Credit Cards' : ''}
							inFormDesign={false}>
							<Selects
								value={
									creditCard ? creditCards.find(cc => cc.id === creditCard) : null
								}
								placeholder='Select a credit card'
								onChange={e =>
									creditCardOnChange({
										target: {
											value: e.target.value ? e.target.value.id : null,
										},
									})
								}
								options={creditCards}
								displayKey='cardNumber'
								renderOption={creditCardOption}
								onFocus={() => setIsSearchingCreditCard(true)}
								onBlur={() => setIsSearchingCreditCard(false)}
								disabled={isReading || isDisabled}
							/>
						</FormField>
					)}
					<div
						role='presentation'
						onClick={() => {
							if (!isDisabled && !disableScanner) {
								const content = activeContent === 'swipe' ? 'creditCard' : 'swipe';
								setActiveContent(content);
								swipeOnChange({ target: { value: null } });
								setFocused('');
							}
						}}>
						<Card
							number={(cardNumber && cardNumber.replace(/\d(?!(\d*)$)/g, '*')) || ''}
							preview
							issuer={(cardNumber && Payment.fns.cardType(cardNumber)) || ''}
							name={cardName}
							expiry={expiry}
							cvc={cvc}
							focused={focused}
							callback={() => {}}
						/>
					</div>
					{activeContent === 'swipe' && !isDisabled && (
						<div className='row sdms-mt-20 align-items-center justify-content-center'>
							{activeContent === 'swipe' && (
								<div className='col-auto sdms-font-bold'>
									{swipe
										? 'Card successfully read'
										: 'Insert card or click card above to key in'}
								</div>
							)}
						</div>
					)}
				</div>
				{activeContent === 'swipe' && !disableScanner && (
					<Scanner
						onScan={scan => {
							setIsReading(false);

							const _swipe = creditCardParser(scan);

							if (!_swipe.isRecognized) {
								addErrorNotification('Cannot recognize card');

								return;
							}

							swipeOnChange({
								target: { value: _swipe },
							});

							creditCardOnChange({ target: { value: null } });
						}}
						onScanDetect={() => setIsReading(true)}
						onError={() => setIsReading(false)}
						disabled={disableScanner || isSearchingCreditCard || isDisabled}
					/>
				)}
				{activeContent === 'creditCard' && (
					<div className='col-lg-6'>
						<FormGroup>
							<FormField
								label={withLabel ? 'Name' : ''}
								name='name'
								colLg={12}
								inFormDesign={false}
								showValidation={cardNameShowVal}
								valRes={{ ...cardNameValRes, message: '' }}>
								<Input
									type='text'
									name='name'
									placeholder='Name'
									className='form-control'
									value={cardName}
									onChange={e => {
										cardNameOnChange(e);
										creditCardOnChange({ target: { value: null } });
									}}
									onFocus={handleInputFocus}
									onBlur={setCardNameShowVal}
									disabled={isReading || isDisabled || customerName !== ''}
									autoComplete={autoComplete}
									xAutoCompleteType={autoComplete ? 'cc-full-name' : ''}
									prependIcon={displayInputPrependIcon ? 'User' : ''}
								/>
							</FormField>
							<FormField
								label={withLabel ? 'Credit Card Number' : ''}
								name='number'
								colLg={12}
								inFormDesign={false}
								showValidation={cardNumberShowVal}
								valRes={{ ...cardNumberValRes, message: '' }}>
								<Input
									type='tel'
									name='number'
									className='form-control'
									// Do not replace the placeholder for automatic filling
									placeholder='Credit Card Front Number'
									value={cardNumber}
									onChange={e => {
										cardNumberOnChange({
											target: {
												value: formatCreditCardNumber(e.target.value),
											},
										});
										creditCardOnChange({ target: { value: null } });
									}}
									onFocus={handleInputFocus}
									onBlur={setCardNumberShowVal}
									disabled={isReading || isDisabled}
									autoComplete={autoComplete}
									xAutoCompleteType={autoComplete ? 'cc-number' : ''}
									prependIcon={displayInputPrependIcon ? 'Credit-card' : ''}
								/>
							</FormField>
							<FormField
								label={withLabel ? 'Expiration Date' : ''}
								name='expiry'
								colLg={6}
								inFormDesign={false}
								showValidation={expiryShowVal}
								valRes={{ ...expiryValRes, message: '' }}>
								<Input
									className='form-control'
									type='tel'
									name='validThru'
									placeholder='Valid Thru'
									onChange={e => {
										expiryOnChange({
											target: {
												value: formatExpirationDate(e.target.value),
											},
										});
										creditCardOnChange({ target: { value: null } });
									}}
									onFocus={handleInputFocus}
									onBlur={setCvcShowVal}
									value={expiry}
									disabled={isReading || isDisabled}
									autoComplete={autoComplete}
									xAutoCompleteType={autoComplete ? 'cc-exp' : ''}
									prependIcon={
										displayInputPrependIcon ? 'Layout-left-panel-1' : ''
									}
								/>
							</FormField>
							<FormField
								label={withLabel ? 'CVC' : ''}
								name='cvc'
								colLg={6}
								inFormDesign={false}
								showValidation={cvcShowVal}
								valRes={{ ...cvcValRes, message: '' }}>
								<Input
									type='tel'
									name='cvc'
									className='form-control'
									placeholder='CVC'
									onChange={e => {
										cvcOnChange({
											target: {
												value: formatCVC(e.target.value),
											},
										});
										creditCardOnChange({ target: { value: null } });
									}}
									onFocus={handleInputFocus}
									onBlur={setExpiryShowVal}
									value={cvc}
									disabled={isReading || isDisabled}
									autoComplete={autoComplete}
									xAutoCompleteType={autoComplete ? 'cc-csc' : ''}
									prependIcon={displayInputPrependIcon ? 'Shield-user' : ''}
								/>
							</FormField>
							{displaySaveCardToggle && (
								<FormField
									label='Save Card'
									name='cvc'
									id='sdms-pay--save-cc'
									col={6}
									inFormDesign={false}>
									<Toggle
										id='sdms-pay--save-cc'
										spaceLess
										value={save}
										onChange={saveOnChange}
										disabled={isReading || isDisabled}
									/>
								</FormField>
							)}
							{displayDefaultToggle && (
								<FormField
									label='Default'
									name='default'
									id='sdms-pay--default-cc'
									col={6}
									inFormDesign={false}>
									<Toggle
										id='sdms-pay--default-cc'
										spaceLess
										value={makeDefault}
										onChange={makeDefaultOnChange}
										disabled={isReading || isDisabled || disableDefault}
									/>
								</FormField>
							)}
						</FormGroup>
					</div>
				)}
			</div>
		</form>
	);
};

CreditCardForm.propTypes = {
	onChange: PropTypes.func.isRequired,
	isAuthorizing: PropTypes.bool,
	isDisabled: PropTypes.bool,
	creditCards: PropTypes.arrayOf(PropTypes.object),
	withLabel: PropTypes.bool,
	disableScanner: PropTypes.bool,
	autoComplete: PropTypes.bool,
	displayInputPrependIcon: PropTypes.bool,
	isSubmitted: PropTypes.bool,
	displaySaveCardToggle: PropTypes.bool,
	displayDefaultToggle: PropTypes.bool,
	disableDefault: PropTypes.bool,
	customerName: PropTypes.string,
	fullWidth: PropTypes.bool,
};

CreditCardForm.defaultProps = {
	isAuthorizing: false,
	isDisabled: false,
	creditCards: [],
	withLabel: true,
	disableScanner: false,
	autoComplete: false,
	displayInputPrependIcon: true,
	isSubmitted: false,
	displaySaveCardToggle: true,
	displayDefaultToggle: false,
	disableDefault: false,
	customerName: '',
	fullWidth: false,
};

export default CreditCardForm;
