import React from 'react';
import PropTypes from 'prop-types';

import MultiSelect from './MultiSelect';

const SpaceSelect = ({ spaces, name, bookingType, value, onChange }) => {
	return (
		<MultiSelect
			data={spaces
				.filter(s => s?.bookingType?.name === bookingType?.name)
				.sort((a, b) => a.name.localeCompare(b.name))
				.map(s => {
					if (s.isComposite)
						return {
							...s,
							name: `${s.name} (${s.units.map(u => u.name).join(',')})`,
						};

					return s;
				})}
			titleProp='name'
			value={value}
			onChange={onChange}
			name={name}
		/>
	);
};

SpaceSelect.propTypes = {
	spaces: PropTypes.arrayOf(PropTypes.object),
	name: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	bookingType: PropTypes.object,
	value: PropTypes.arrayOf(PropTypes.object),
	onChange: PropTypes.func,
};

SpaceSelect.defaultProps = {
	spaces: [],
	name: 'spaces',
	bookingType: null,
	value: [],
	onChange: () => {},
};

export default SpaceSelect;
