import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';

import Portlet from '../../../layout/Portlet';
import Alert from '../../Alert';
import { Login } from '../../../modals/LoginModal';
import Button from '../../Button';

const SignIn = ({ customer, loginMsgStatus, onLogin, outlet, onNewAccount }) => {
	return (
		<>
			<Portlet isElevate={false} className='sdms-bg-transparent sdms-portlet--fit' isLast>
				<Portlet.Head>
					<Portlet.HeadLabelTitle portletIcon='User'>Sign In</Portlet.HeadLabelTitle>
				</Portlet.Head>
			</Portlet>
			{loginMsgStatus && !customer && (
				<Alert design='danger' icon='Warning-2' marginLess={false}>
					Please login to make a reservation!
				</Alert>
			)}
			<AnimatePresence exitBeforeEnter initial={false}>
				{customer ? (
					<Portlet hasFrame key='withCustomer'>
						<Portlet.Head>
							<Portlet.HeadLabelTitle>
								Welcome, {customer.displayName}
							</Portlet.HeadLabelTitle>
						</Portlet.Head>
					</Portlet>
				) : (
					<Portlet hasFrame key='withOutCustomer'>
						<Portlet.Body>
							<div className='row'>
								<div className='col-md-6'>
									<Portlet
										border={false}
										isLast
										isElevate={false}
										className='sdms-c-h100'>
										<Login onLogin={onLogin} outlet={outlet} inModal={false} />
									</Portlet>
								</div>
								<div className='col-md-6'>
									<Portlet
										border={false}
										isLast
										isElevate={false}
										className='sdms-c-h100'>
										<Portlet.Head className='sdms-portlet__head--noborder sdms-pl0 sdms-pr0'>
											<Portlet.HeadLabelTitle>
												Create a New Account
											</Portlet.HeadLabelTitle>
										</Portlet.Head>
										<Portlet.Body className='sdms-portlet__body--fit-x sdms-c-h100 sdms-pt0'>
											<h5 className='sdms-mb-0 sdms-font-bolder'>
												Book Online
											</h5>
											<p className='sdms-mb-0'>
												You can book everything online with 3 simple steps.
											</p>
											<h5 className='sdms-mb-0 sdms-font-bolder'>
												Email Confirmations
											</h5>
											<p className='sdms-mb-0'>
												Get instant booking confirmation to your email
											</p>
											<h5 className='sdms-mb-0 sdms-font-bolder'>
												Secure Login
											</h5>
											<p className='sdms-mb-0'>
												Secure, encrypted login system will protect your
												information
											</p>
										</Portlet.Body>
										<Portlet.Foot tall='sm' className='sdms-pl0 sdms-pr0'>
											<div className='col' />
											<div className='col-auto'>
												<Button
													design='info'
													outline
													text='Create New Account'
													icon='Sign-in'
													size='sm'
													onClick={onNewAccount}
												/>
											</div>
										</Portlet.Foot>
									</Portlet>
								</div>
							</div>
						</Portlet.Body>
					</Portlet>
				)}
			</AnimatePresence>
		</>
	);
};

SignIn.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object.isRequired,
	loginMsgStatus: PropTypes.bool.isRequired,
	onLogin: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	onNewAccount: PropTypes.func.isRequired,
};

export default SignIn;
