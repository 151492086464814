import React, { useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';

import UserContext from '../../../app/contexts/UserContext';
import useOutlet from '../../../utils/hooks/useOutlet';
import usePrint from '../../../utils/hooks/usePrint';
import { debugTypes } from '../../../utils/helpers/debugHelper';
import { addErrorNotification } from '../../../utils/helpers/helper';
import apiCall from '../../../utils/helpers/apiCall';
import useSendInvoice from '../../../utils/hooks/useSendInvoice';

import FormContainer from '../../reusables/template/FormContainer';
import InvoiceForm from './InvoiceForm';
import Button from '../../reusables/element/Button';
import InvoiceDeferredSchedule from '../../reusables/modals/InvoiceDeferredSchedule';

const InvoiceEdit = ({ match, id, presetData, afterSubmit, onCancel, isQuickPanel }) => {
	const pages = usePages();
	const userContext = useContext(UserContext);
	const [outletKey] = useOutlet(true);
	const { sendInvoice, isLoading } = useSendInvoice();

	const [deferredScheduleInvoiceId, setDeferredScheduleInvoiceId] = useState(null);
	const [isPrinting, setIsPrinting] = useState(false);
	const updateCallBack = useRef(() => {});
	const [onPrint, PRINT_COMPONENT] = usePrint();

	const onSendInvoiceEmail = invoiceId => {
		sendInvoice({
			outletId: userContext.data.selectedOutlet.id,
			invoiceIds: [invoiceId],
		});
	};

	return (
		<>
			<FormContainer
				key={outletKey}
				dataId={match ? match.params.id : id.toString()}
				pageTitle={pages.crm.invoices.text}
				dataName='invoices'
				presetData={presetData}
				lists={[
					'discounts',
					'taxRates',
					'paymentTerms',
					'enumCalculations',
					'taxCodes',
					'enumInvoiceStatuses',
					'accounts',
					'acctClasses',
					'invoiceTypes',
				]}
				fields={
					<InvoiceForm
						deferredScheduleInvoiceId={deferredScheduleInvoiceId}
						onDeferredScheduleCancel={() => setDeferredScheduleInvoiceId(null)}
						isQuickPanel={isQuickPanel}
						shouldCustomerInputDisabled={
							presetData?.customer &&
							Object.hasOwn(presetData.customer, 'displayName')
						}
					/>
				}
				customButtons={(data, callBack) => [
					<Button
						key='deferredSchedule'
						design='default'
						text='Deferred Schedule'
						onClick={() => {
							if (data.id === 0) {
								addErrorNotification('Please save invoice first');
								return;
							}
							updateCallBack.current = callBack;
							setDeferredScheduleInvoiceId(data.id);
						}}
						disabled={
							!data.invoiceItems ||
							data.invoiceItems.length === 0 ||
							(data.invoiceItems.filter(
								i => i.product && i.product.enableDeferredIncome
							).length === 0 &&
								!userContext.hasPermission('edit_deferred_all_items') &&
								!userContext.hasPermission('edit_invoices'))
						}
						noPermission={!userContext.hasPermission('maintain_deferred_schedule')}
					/>,
					<Button
						key='print'
						design='default'
						text='Print'
						onClick={() => {
							setIsPrinting(true);
							apiCall(
								'POST',
								'getPrintInvoiceContent',
								res => {
									setIsPrinting(false);
									onPrint(
										res.invoices.join('<p style="page-break-before: always">')
									);
								},
								err => {
									setIsPrinting(false);
									addErrorNotification(err.toString());
								},
								'',
								{
									outletId: userContext.data.selectedOutlet.id,
									invoiceIds: [data.id],
								}
							);
						}}
						disabled={isPrinting || data.id === 0}
					/>,
					<Button
						key='sendInvoice'
						design='default'
						text='Email Invoice'
						onClick={() => onSendInvoiceEmail(data.id)}
						disabled={isLoading || data.id === 0}
					/>,
				]}
				debugType={debugTypes.invoice}
				isEditable={userContext.hasPermission('edit_invoices')}
				afterSubmit={afterSubmit}
				onCancel={onCancel}
				isQuickPanel={isQuickPanel}
				withHeader
			/>
			{deferredScheduleInvoiceId !== null && (
				<InvoiceDeferredSchedule
					onCancel={() => setDeferredScheduleInvoiceId(null)}
					invoiceId={deferredScheduleInvoiceId}
					afterSubmit={invoice => {
						updateCallBack.current({
							renewalStartDate: invoice.renewalStartDate,
							renewalEndDate: invoice.renewalEndDate,
							deferredIncomes: invoice.deferredIncomes,
						});
					}}
				/>
			)}
			{PRINT_COMPONENT}
		</>
	);
};

InvoiceEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}),
	id: PropTypes.number,
	// eslint-disable-next-line react/forbid-prop-types
	presetData: PropTypes.object,
	afterSubmit: PropTypes.func,
	onCancel: PropTypes.func,
	isQuickPanel: PropTypes.bool,
};

InvoiceEdit.defaultProps = {
	match: null,
	id: 0,
	presetData: null,
	afterSubmit: () => {},
	onCancel: () => {},
	isQuickPanel: false,
};

export default InvoiceEdit;
