import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import SeriesChart from '../template/SeriesChart';
import CumulativeChart from '../template/CumulativeChart';

const SERIES_CHARTS = ['Line', 'Bar', 'Column'];

const CUMULATIVE_CHARTS = ['Pie', 'Doughnut'];

const WidgetChart = ({ data, widget }) => {
	const xType = useMemo(() => {
		if (widget.chartX.dataType?.value === 'datetime') return 'DateTime';

		if (widget.chartX.dataType?.value === 'number') return 'Double';

		return 'Category';
	}, [widget]);

	const tranformPoint = (value, type) => {
		if (type === 'DateTime') return new Date(value);

		if (type === 'Double') return parseFloat(value);

		return value;
	};

	const chartData = useMemo(() => {
		const sortedData = [...data].sort((a, b) => {
			if (xType === 'DateTime')
				return (
					new Date(a[widget.chartX.alias]).getTime() -
					new Date(b[widget.chartX.alias]).getTime()
				);

			return a[widget.chartX.alias] - b[widget.chartX.alias];
		});

		const categories = Array.from(new Set(sortedData.map(item => item[widget.chartX.alias])));

		const result = categories.map(category => {
			const filteredItems = sortedData.filter(item => item[widget.chartX.alias] === category);

			let y = 0;

			let text = null;

			if (CUMULATIVE_CHARTS.indexOf(widget.chartType.value) > -1) {
				y = parseFloat(100 * (filteredItems.length / sortedData.length)).toFixed(2);

				text = `${category}: ${y}%`;
			} else {
				const values = filteredItems.map(item => item[widget.chartY.alias]);

				const total = values.reduce((partialSum, a) => partialSum + parseFloat(a), 0);

				if (widget?.chartYCalculation?.value === 'Distinct count') y = filteredItems.length;
				else if (widget?.chartYCalculation?.value === 'Total') y = total;
				else if (widget?.chartYCalculation?.value === 'Average')
					y = parseFloat(total / filteredItems.length).toFixed(2);
				else if (widget?.chartYCalculation?.value === 'Max') y = Math.max(...values);
				else if (widget?.chartYCalculation?.value === 'Min') y = Math.min(...values);
			}

			return {
				x: tranformPoint(category, xType),
				y: tranformPoint(y, 'Double'),
				text,
			};
		});

		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, widget]);

	if (SERIES_CHARTS.indexOf(widget.chartType.value) > -1)
		return <SeriesChart data={chartData} type={widget.chartType.value} xType={xType} />;

	if (CUMULATIVE_CHARTS.indexOf(widget.chartType.value) > -1)
		return <CumulativeChart data={chartData} type={widget.chartType.value} />;

	return null;
};

export default WidgetChart;

WidgetChart.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})),
	widget: PropTypes.shape({
		id: PropTypes.number,
		report: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
			queryJSON: PropTypes.string,
			reportFields: PropTypes.arrayOf(PropTypes.shape({})),
		}),
		sortOrder: PropTypes.number,
		width: PropTypes.number,
		title: PropTypes.string,
		config: PropTypes.string,
		type: PropTypes.shape({
			id: PropTypes.number,
			value: PropTypes.string,
		}),
		col: PropTypes.number,
		row: PropTypes.number,
		sizeX: PropTypes.number,
		sizeY: PropTypes.number,
		chartType: PropTypes.shape({
			id: PropTypes.number,
			value: PropTypes.string,
		}),
		chartX: PropTypes.shape({
			id: PropTypes.number,
			alias: PropTypes.string,
			label: PropTypes.string,
			dataType: PropTypes.shape({
				value: PropTypes.string,
			}),
		}),
		chartY: PropTypes.shape({
			id: PropTypes.number,
			alias: PropTypes.string,
			label: PropTypes.string,
			dataType: PropTypes.shape({
				value: PropTypes.string,
			}),
		}),
		chartYCalculation: PropTypes.shape({
			id: PropTypes.number,
			value: PropTypes.string,
		}),
	}),
};

WidgetChart.defaultProps = {
	data: [],
	widget: {
		id: 0,
		report: {
			id: 0,
			name: '',
			queryJSON: '',
			reportFields: [],
		},
		sortOrder: 0,
		width: 100,
		title: '',
		config: null,
		type: {
			id: 0,
			value: null,
		},
		col: 0,
		row: 0,
		sizeX: 0,
		sizeY: 0,
		chartType: null,
		chartX: null,
		chartY: null,
		chartYCalculation: null,
	},
};
