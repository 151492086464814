import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import UserContext from '../../app/contexts/UserContext';

const useOutlet = (reload = false, onChange = () => {}) => {
	const userContext = useContext(UserContext);

	const init = useRef(false);

	const displayOutlet = useMemo(() => userContext.data.user.hasMultipleOutlet, [
		userContext.data,
	]);

	const checkConfiguration = () => {
		if (!userContext) return false;

		const { taxRate, defaultCustomer } = userContext.data.selectedOutlet.settings;

		return taxRate && defaultCustomer;
	};

	const [key, setKey] = useState(`outlet_${userContext.data.selectedOutlet.id}_${Date.now()}`);

	const [configured, setConfigured] = useState(checkConfiguration());

	useEffect(() => {
		if (!userContext.data.selectedOutlet) return;

		if (init.current) {
			setConfigured(checkConfiguration());

			onChange();

			if (reload) {
				const segments = window.location.href.split('/');

				if (segments[segments.length - 1] !== '0') {
					segments[segments.length - 1] = '0';

					window.location.href = segments.join('/');
				}
			}

			setKey(`outlet_${userContext.data.selectedOutlet.id}_${Date.now()}`);
		}

		init.current = true;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userContext.data.selectedOutlet]);

	return [key, configured, displayOutlet];
};

export default useOutlet;
