import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import { numberParser as _numberParser } from '../../../utils/helpers/helper';

import Button from '../element/Button';
import FormGroup from '../layout/FormGroup';
import FormField from '../template/FormField';
import Input from '../field/Input';
import Toggle from '../field/Toggle';
import Portlet from '../layout/Portlet';
import Section from '../layout/Section';
import Selects from '../field/Selects';

const MapUnitForm = ({ data, dispatch, fontSizes }) => {
	const updateTableMap = useRef(false);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isValid, setIsValid] = useState(false);

	// custom number parser to handle '0.' value
	const numberParser = (isFloat, defaultValue = null) => {
		return v => {
			if (isFloat && v === '.') return 0.0;

			if (v !== 0 && (v === '' || !v)) return defaultValue;

			return isFloat ? parseFloat(v) : parseInt(v, 10);
		};
	};

	const numberFormat = value => {
		if (value === '.') return '0.';
		if (value === '' || !value) return '';

		const parts = value.toString().split('.');
		if (parts[1] && parts[1].length > 3) return parseFloat(value).toFixed(3);
		return value.toString();
	};

	const [posX, posXOnChange, posXValRes, posXShowVal, setPosXShowVal] = useField(
		data,
		'posX',
		() => {},
		[required],
		0,
		numberParser(true)
	);

	const [posY, posYOnChange, posYValRes, posYShowVal, setPosYShowVal] = useField(
		data,
		'posY',
		() => {},
		[required],
		0,
		numberParser(true)
	);

	const [width, widthOnChange, widthValRes, widthShowVal, setWidthShowVal] = useField(
		data,
		'width',
		() => {},
		[required],
		2,
		numberParser(true)
	);

	const [height, heightOnChange, heightValRes, heightShowVal, setHeightShowVal] = useField(
		data,
		'height',
		() => {},
		[required],
		1,
		numberParser(true)
	);

	const [rotation, rotationOnChange] = useField(
		data,
		'rotation',
		() => {},
		[],
		0,
		_numberParser(false)
	);

	const [
		fontSize,
		fontSizeOnChange,
		fontSizeValRes,
		fontSizeShowVal,
		setFontSizeShowVal,
	] = useField(data, 'fontSize', () => {}, [required], null);

	const [hideCaption, hideCaptionOnChange] = useField(data, 'hideCaption', () => {}, [], false);

	useEffect(() => {
		if (isSubmitted) {
			setPosXShowVal();
			setPosYShowVal();
			setWidthShowVal();
			setHeightShowVal();
			setFontSizeShowVal();
		}
	}, [
		isSubmitted,
		setPosXShowVal,
		setPosYShowVal,
		setWidthShowVal,
		setHeightShowVal,
		setFontSizeShowVal,
	]);

	useEffect(() => {
		setIsValid(
			posXValRes.isValid &&
				posYValRes.isValid &&
				widthValRes.isValid &&
				heightValRes.isValid &&
				fontSizeValRes.isValid
		);
	}, [
		posXValRes.isValid,
		posYValRes.isValid,
		widthValRes.isValid,
		heightValRes.isValid,
		fontSizeValRes.isValid,
		setIsValid,
	]);

	// update tableMapFrom on tableForm change.
	useEffect(() => {
		if (updateTableMap.current) {
			dispatch({
				type: 'change',
				payload: data,
			});
			updateTableMap.current = false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [posX, posY, width, height, rotation, fontSize, hideCaption]);

	return (
		<Portlet fluid='fluid'>
			<Portlet.Head>
				<Portlet.HeadLabelTitle
					portletIcon={data.icon && data.icon.value}
					smallTitle='Position'>
					{data.name}
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<Section>
					<Section.Body>
						<FormGroup>
							<FormField
								name='posX'
								label='Pos X'
								id={data.id}
								valRes={posXValRes}
								showValidation={posXShowVal}
								col={6}>
								<Input
									type='text'
									withOutSpin
									min={0}
									placeholder='Pos X (Required)'
									value={numberFormat(posX)}
									onChange={e => {
										updateTableMap.current = true;
										posXOnChange({
											target: {
												value:
													e.target.value !== '' &&
													parseFloat(e.target.value) > 100
														? 100
														: e.target.value,
											},
										});
									}}
									onBlur={setPosXShowVal}
									prependIcon='Arrow-from-left'
									append='%'
									pattern={process.env.REACT_APP_PRICE_PATTERN}
								/>
							</FormField>
							<FormField
								name='posY'
								label='Pos Y'
								id={data.id}
								valRes={posYValRes}
								showValidation={posYShowVal}
								col={6}>
								<Input
									type='text'
									withOutSpin
									min={0}
									placeholder='Pos Y (Required)'
									value={numberFormat(posY)}
									onChange={e => {
										updateTableMap.current = true;
										posYOnChange({
											target: {
												value:
													e.target.value !== '' &&
													parseFloat(e.target.value) > 100
														? 100
														: e.target.value,
											},
										});
									}}
									onBlur={setPosYShowVal}
									prependIcon='Arrow-from-top'
									append='%'
									pattern={process.env.REACT_APP_PRICE_PATTERN}
								/>
							</FormField>
							<FormField
								name='width'
								label='Width'
								id={data.id}
								valRes={widthValRes}
								showValidation={widthShowVal}
								col={6}>
								<Input
									type='text'
									withOutSpin
									min={0}
									placeholder='Width (Required)'
									value={numberFormat(width)}
									onChange={e => {
										updateTableMap.current = true;
										widthOnChange({
											target: { value: e.target.value },
										});
									}}
									onBlur={setWidthShowVal}
									prependIcon='Arrows-h'
									appendIcon='Crop'
									appendIconColor='var(--gray)'
									pattern={process.env.REACT_APP_PRICE_PATTERN}
								/>
							</FormField>
							<FormField
								name='height'
								label='Height'
								id={data.id}
								valRes={heightValRes}
								showValidation={heightShowVal}
								col={6}>
								<Input
									type='text'
									withOutSpin
									min={0}
									placeholder='Height (Required)'
									value={numberFormat(height)}
									onChange={e => {
										updateTableMap.current = true;
										heightOnChange({
											target: { value: e.target.value },
										});
									}}
									onBlur={setHeightShowVal}
									prependIcon='Arrows-v'
									appendIcon='Crop'
									appendIconColor='var(--gray)'
									pattern={process.env.REACT_APP_PRICE_PATTERN}
								/>
							</FormField>
							<FormField
								name='rotation'
								label='Rotation'
								description={`${rotation || 0} with 360 deg.`}
								id={data.id}
								col={12}>
								<Input
									type='range'
									min={0}
									max={360}
									placeholder='rotation (Required)'
									value={rotation.toString()}
									onChange={e => {
										updateTableMap.current = true;
										rotationOnChange({
											target: { value: e.target.value },
										});
									}}
								/>
							</FormField>
							<FormField
								name='fontSize'
								label='Font Size'
								id={data.id}
								valRes={fontSizeValRes}
								showValidation={fontSizeShowVal}
								colMd={6}>
								<Selects
									options={fontSizes}
									placeholder='Font Sizes (Required)'
									value={fontSize}
									onChange={e => {
										updateTableMap.current = true;
										fontSizeOnChange(e);
									}}
									onBlur={setFontSizeShowVal}
									displayKey='value'
									sortKey='size'
								/>
							</FormField>
							<FormField
								name='hideCaption'
								label='Hide Caption'
								id={data.id}
								colMd={6}>
								<Toggle
									value={hideCaption}
									onChange={e => {
										updateTableMap.current = true;
										hideCaptionOnChange({
											target: { value: e.target.value },
										});
									}}
								/>
							</FormField>
						</FormGroup>
					</Section.Body>
				</Section>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='default'
						text='Cancel'
						size='sm'
						elevate
						onClick={() => dispatch({ type: 'cancel' })}
					/>
				</div>
				{data.isInMap && (
					<div className='col-auto'>
						<Button
							design='brand'
							outline
							icon='Undo'
							size='sm'
							elevate
							text='Remove'
							onClick={() => dispatch({ type: 'remove', payload: data.id })}
						/>
					</div>
				)}
				<div className='col-auto'>
					<Button
						label='brand'
						size='sm'
						elevate
						text='OK'
						onClick={() => {
							setIsSubmitted(true);
							if (isValid) dispatch({ type: 'closeEdit' });
						}}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
MapUnitForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		posX: PropTypes.number,
		posY: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number,
		rotation: PropTypes.number,
		hideCaption: PropTypes.bool,
		isInMap: PropTypes.bool,
		icon: PropTypes.object,
		openNewOrderByDefault: PropTypes.bool,
		priorityPrint: PropTypes.bool,
	}),
	dispatch: PropTypes.func.isRequired,
	fontSizes: PropTypes.arrayOf(PropTypes.object),
};
MapUnitForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		posX: 0,
		posY: 0,
		width: 120,
		height: 60,
		rotation: 0,
		hideCaption: false,
		isInMap: false,
		icon: { value: 'Square' },
		openNewOrderByDefault: false,
		priorityPrint: false,
	},
	fontSizes: [],
};

export default MapUnitForm;
