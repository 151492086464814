import React, { useContext, useEffect } from 'react';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';

import FormContainer from '../../reusables/template/FormContainer';
import OutletDefaultsForm from './OutletDefaultsForm';
import useOutlet from '../../../utils/hooks/useOutlet';

const OutletDefaultsEdit = () => {
	const pages = usePages();

	// Use current user to get company data.
	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const [outletKey] = useOutlet();

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.companySettings.default.text,
				path: pages.companySettings.dashboard.path,
			},
			{
				title: pages.companySettings.outletDefaults.text,
				path: pages.companySettings.outletDefaults.path,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(pages.companySettings.outletDefaults.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<FormContainer
			key={outletKey}
			dataId={
				userContext.data.selectedOutlet.settings
					? userContext.data.selectedOutlet.settings.id.toString()
					: '0'
			}
			pageTitle={pages.companySettings.outletDefaults.text}
			icon={pages.companySettings.outletDefaults.icon}
			dataName='outletSettings'
			fields={<OutletDefaultsForm />}
			lists={[
				'templates',
				'unitMaps',
				'taxRates',
				'acctAccounts',
				'emailAddresses',
				'paymentTerms',
				'accounts',
			]}
			showBackButton={false}
			afterSubmit={outletSetting => userContext.updateOutlet(outletSetting, true)}
		/>
	);
};

export default OutletDefaultsEdit;
