import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SlideDown } from 'react-slidedown';
import classNames from 'classnames';

import SVGIcon from '../element/SVGIcon';

const FormSection = ({
	sectionRef,
	title,
	icon,
	headerActionLabel,
	onHeaderActionClick,
	children,
	isAccordion,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const timeoutId = useRef(0);

	useEffect(() => {
		if (timeoutId) clearTimeout(timeoutId.current);

		if (isOpen && sectionRef.current)
			timeoutId.current = setTimeout(() => {
				sectionRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}, 500);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const CONTENT = (
		<div className='sdms-form-section-content row sdms-bg-white'>
			<div className='col-12'>{children}</div>
		</div>
	);

	return (
		<div ref={sectionRef} className='sdms-form-section sdms-mb-15'>
			<div className='sdms-form-section-header row'>
				<div className='col'>
					<div className='row sdms-row-align-items'>
						<div>
							<SVGIcon name={icon} size={16} />
						</div>
						<div
							className={classNames(
								'sdms-font-size-1-point-2-rem sdms-font-boldest sdms-ml-5',
								{ 'sdms-cursor--pointer': isAccordion }
							)}
							role='presentation'
							onClick={isAccordion ? () => setIsOpen(!isOpen) : null}>
							{title}
						</div>
						{headerActionLabel && (
							<div
								role='presentation'
								className='sdms-font-info sdms-font-size-10-px sdms-font-bold sdms-cursor--pointer sdms-ml-10'
								onClick={onHeaderActionClick}>
								{headerActionLabel}
							</div>
						)}
					</div>
				</div>
				{isAccordion && (
					<div className='col-auto'>
						<SVGIcon
							name='Angle-double-right'
							size={16}
							className={classNames('sdms-transition', 'sdms-cursor--pointer', {
								'sdms-rotate--270': isOpen,
								'sdms-rotate--90': !isOpen,
							})}
							onClick={() => setIsOpen(!isOpen)}
						/>
					</div>
				)}
			</div>
			{isAccordion ? <SlideDown>{isOpen ? CONTENT : null}</SlideDown> : CONTENT}
		</div>
	);
};

FormSection.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	sectionRef: PropTypes.object,
	title: PropTypes.string,
	icon: PropTypes.string,
	headerActionLabel: PropTypes.string,
	onHeaderActionClick: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
	isAccordion: PropTypes.bool,
};

FormSection.defaultProps = {
	sectionRef: null,
	title: '',
	icon: 'Dollar',
	headerActionLabel: '',
	onHeaderActionClick: () => {},
	children: null,
	isAccordion: false,
};

export default FormSection;
