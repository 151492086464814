import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import pages from '../../pages';
import MessageForm from './MessageForm';

const MessageEdit = ({ match }) => {
	return (
		<FormContainer
			dataId={match.params.id}
			dataName='messages'
			lists={['emailAddresses']}
			fields={<MessageForm />}
			pageTitle={pages.messages.default.text}
			icon={pages.messages.default.icon}
			showSaveButton={false}
		/>
	);
};

MessageEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default MessageEdit;
