import React from 'react';
import PropTypes from 'prop-types';

import CategoryItem from '../../elements/CategoryItem';

const SearchResultListView = ({
	productCategories,
	isOnline,
	isLoading,
	selectProduct,
	multipleSelected,
	setMultipleSelected,
	headerHeight,
	outlet,
	initProducts,
	displayHeader,
	searchData,
}) => {
	return Object.keys(productCategories).map(productCategoryKey => (
		<CategoryItem
			key={productCategoryKey}
			productCategories={productCategories}
			productCategoryKey={productCategoryKey}
			selectProduct={selectProduct}
			isLoading={isLoading}
			multipleSelected={multipleSelected}
			setMultipleSelected={setMultipleSelected}
			headerHeight={headerHeight}
			outlet={outlet}
			isOnline={isOnline}
			initProducts={initProducts}
			displayHeader={displayHeader}
			searchData={searchData}
		/>
	));
};

SearchResultListView.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	productCategories: PropTypes.object.isRequired,
	isOnline: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	selectProduct: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	multipleSelected: PropTypes.object,
	setMultipleSelected: PropTypes.func.isRequired,
	headerHeight: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initProducts: PropTypes.array,
	displayHeader: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	searchData: PropTypes.object,
};

SearchResultListView.defaultProps = {
	initProducts: [],
	multipleSelected: null,
	displayHeader: true,
	searchData: null,
};

export default SearchResultListView;
