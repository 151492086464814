import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useComponentSize from '@rehooks/component-size';
import moment from 'moment-timezone';

import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';
import Loading from '../../reusables/template/Loading';
import Input from '../../reusables/field/Input';
import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Selects from '../../reusables/field/Selects';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import DatePicker from '../../reusables/field/DatePicker';
import TimePickerInput from '../../reusables/field/TimePickerInput';
import { convertDateToUTC, numberParser, getBlockoutColorList } from '../../../utils/helpers/helper';
import MultiSelect from '../../reusables/element/MultiSelect';
import UserContext from '../../../app/contexts/UserContext';
import { modules } from '../../../utils/helpers/apiCall';
import { bookBies } from '../../../utils/constants/constants';
import Alert from '../../reusables/element/Alert';
import Badge from '../../reusables/element/Badge';

const SpaceBlockoutForm = ({
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	isLoading,
	onFormChange,
	submitButtonAttr,
	submit,
	marinaUnits,
	enumUnitBlockoutTypes,
	marinaBookingTypes,
}) => {
	const pages = usePages();

	const refForTimePicker = useRef(null);

	const sizeTimePicker = useComponentSize(refForTimePicker);

	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const marinaModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.MARINA)
	);

	const colorList = useRef(getBlockoutColorList());

	const capacityValidation = (value, setValRes) => {
		if (units[0] && value !== '' && units[0].inventory < parseInt(value, 10)) {
			setValRes({
				isValid: false,
				status: 'invalid',
				message: `Block out capacity cannot be larger than unit inventory ${units[0].inventory}`,
			});
			return false;
		}

		return true;
	};

	const endTimeValidation = (value, setValRes) => {
		if (startTime && value && moment(startTime).isSameOrAfter(moment(value))) {
			setValRes({
				isValid: false,
				status: 'invalidToDate',
				message: 'End time cannot be earlier from start time.',
			});
			return false;
		}

		return true;
	};

	const isBookByUnit = () => bookingType && bookingType.bookBy.value === bookBies.UNIT;

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [
		bookingType,
		bookingTypeOnChange,
		bookingTypeValRes,
		bookingTypeShowVal,
		setBookingTypeShowVal,
	] = useField(data, 'bookingType', onFormChange, [required], null);

	const [units, unitsOnChange, unitsValRes, unitsShowVal, setUnitsShowVal] = useField(
		data,
		'units',
		onFormChange,
		[required],
		[]
	);

	const [
		startTime,
		startTimeOnChange,
		startTimeValRes,
		startTimeShowVal,
		setStartTimeShowVal,
	] = useField(data, 'startTime', onFormChange, [required], null);

	const [
		endTime,
		endTimeOnChange,
		endTimeValRes,
		endTimeShowVal,
		setEndTimeShowVal,
		validateEndTime,
	] = useField(data, 'endTime', onFormChange, [required, endTimeValidation], null);

	const [type, typeOnChange, typeValRes, typeShowVal, setTypeShowVal] = useField(
		data,
		'type',
		onFormChange,
		[required],
		null
	);

	const [
		capacity,
		capacityOnChange,
		capacityValRes,
		capacityShowVal,
		setCapacityShowVal,
	] = useField(
		data,
		'capacity',
		onFormChange,
		[required, capacityValidation],
		'',
		numberParser(false)
	);

	const [mapColor, mapColorOnChange] = useField(data, 'mapColor', onFormChange, [], null);

	const getSpacesField = () => {
		if (!bookingType)
			return (
				<Alert
					icon='Info-circle'
					iconSize={26}
					className='sdms-pt5 sdms-pb-5'
					outline
					design='info'>
					Please select booking type first
				</Alert>
			);

		if (bookingType.bookBy.value === bookBies.UNIT)
			return (
				<MultiSelect
					data={marinaUnits
						.filter(bu => bu.bookingType.id === bookingType.id)
						.sort((a, b) => a.name.localeCompare(b.name))}
					titleProp='name'
					value={units}
					onChange={e => {
						unitsOnChange(e);
						setUnitsShowVal();
					}}
					name='units'
				/>
			);

		return (
			<Selects
				placeholder='Select a Unit'
				onChange={e => {
					capacityOnChange({ target: { value: 0 } });
					unitsOnChange({ target: { value: [e.target.value] } });
				}}
				options={marinaUnits
					.filter(bu => bu.bookingType.id === bookingType.id)
					.sort((a, b) => a.name.localeCompare(b.name))}
				value={units[0] || null}
				onBlur={setUnitsShowVal}
			/>
		);
	};

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setBookingTypeShowVal();
			setUnitsShowVal();
			setStartTimeShowVal();
			setEndTimeShowVal();
			setTypeShowVal();
			setCapacityShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setBookingTypeShowVal,
		setUnitsShowVal,
		setStartTimeShowVal,
		setEndTimeShowVal,
		setTypeShowVal,
		setCapacityShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				bookingTypeValRes.isValid &&
				unitsValRes.isValid &&
				startTimeValRes.isValid &&
				endTimeValRes.isValid &&
				typeValRes.isValid &&
				(capacityValRes.isValid || isBookByUnit())
		);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [
		nameValRes.isValid,
		bookingTypeValRes.isValid,
		unitsValRes.isValid,
		startTimeValRes.isValid,
		endTimeValRes.isValid,
		typeValRes.isValid,
		capacityValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.marina.default.text,
				path: pages.marina.dashboard.path,
			},
			{
				title: pages.marina.settings.text,
				path: pages.marina.settings.path,
			},
			{
				title: pages.marina.settings.spaceBlockouts.text,
				path: pages.marina.settings.spaceBlockouts.path,
			},
			{ title: name || `New ${pages.marina.settings.spaceBlockouts.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.marina.settings.spaceBlockouts.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	// set module
	useEffect(() => {
		if (!isLoading) {
			data.module = marinaModule.current;
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	useEffect(() => {
		validateEndTime(endTime);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [startTime]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								loadingContainer
								colMd={6}>
								<Input
									type='text'
									placeholder='Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='bookingType'
								label='Booking Type'
								id={data.id}
								valRes={bookingTypeValRes}
								showValidation={bookingTypeShowVal}
								loadingContainer
								colMd={6}>
								<Selects
									options={marinaBookingTypes}
									placeholder='Booking Type (Required)'
									value={bookingType}
									onChange={e => {
										bookingTypeOnChange(e);
										unitsOnChange({ target: { value: [] } });
										capacityOnChange({ target: { value: 0 } });
									}}
									onBlur={setBookingTypeShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='bookingUnits'
								label='Booking Units'
								id={data.id}
								colMd={isBookByUnit() ? 12 : 6}
								showValidation={unitsShowVal}
								valRes={unitsValRes}>
								{getSpacesField()}
							</FormField>
						</Loading>
						{!isBookByUnit() && (
							<Loading isLoading={isLoading}>
								<FormField
									name='capacity'
									label='Capacity'
									id={data.id}
									loadingContainer
									colMd={6}
									showValidation={capacityShowVal}
									valRes={capacityValRes}>
									<Input
										type='number'
										withOutSpin
										placeholder='Capacity'
										value={capacity}
										onChange={capacityOnChange}
										onBlur={setCapacityShowVal}
										min={0}
									/>
								</FormField>
							</Loading>
						)}
						<Loading isLoading={isLoading}>
							<FormField
								ref={refForTimePicker}
								name='startDate'
								label='Start Date'
								id={data.id}
								valRes={startTimeValRes}
								showValidation={startTimeShowVal}
								loadingContainer
								colLg={3}
								colMd={6}>
								<DatePicker
									id='startDate'
									type='calendar'
									value={startTime ? convertDateToUTC(new Date(startTime)) : null}
									place='top'
									onChange={e => {
										if (e.target.value) {
											const _startTime = startTime
												? moment.tz(startTime, 'UTC')
												: moment
														.tz('UTC')
														.hour(0)
														.minute(0)
														.second(0)
														.millisecond(0);

											_startTime
												.year(e.target.value.getFullYear())
												.month(e.target.value.getMonth())
												.date(e.target.value.getDate());

											startTimeOnChange({
												target: {
													value: _startTime.toISOString(),
												},
											});
										} else {
											startTimeOnChange({
												target: {
													value: null,
												},
											});
										}
									}}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								ref={refForTimePicker}
								name='startTime'
								label='Start Time'
								id={data.id}
								valRes={startTimeValRes}
								showValidation={startTimeShowVal}
								loadingContainer
								colLg={3}
								colMd={6}>
								<TimePickerInput
									showSecond={false}
									placeholder='Select Start Time'
									value={startTime ? moment.tz(startTime, 'UTC') : null}
									defaultValue={moment()}
									placement='topLeft'
									onChange={e => {
										if (e) {
											const _startTime = startTime
												? moment.tz(startTime, 'UTC')
												: moment
														.tz('UTC')
														.second(0)
														.millisecond(0);

											_startTime.hour(e.hour()).minute(e.minute());

											startTimeOnChange({
												target: {
													value: _startTime.toISOString(),
												},
											});
										} else
											startTimeOnChange({
												target: {
													value: null,
												},
											});
										onFormChange();
									}}
									size={sizeTimePicker}
									use12Hours
									minuteStep={1}
									onClose={setStartTimeShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								ref={refForTimePicker}
								name='endDate'
								label='End Date'
								id={data.id}
								loadingContainer
								colLg={3}
								colMd={6}
								valRes={endTimeValRes}
								showValidation={endTimeShowVal}>
								<DatePicker
									id='endDate'
									type='calendar'
									place='top'
									value={endTime ? convertDateToUTC(new Date(endTime)) : null}
									onBlur={setEndTimeShowVal}
									onChange={e => {
										if (e.target.value) {
											const _endTime = endTime
												? moment.tz(endTime, 'UTC')
												: moment
														.tz('UTC')
														.hour(23)
														.minute(59)
														.second(59)
														.millisecond(999);

											_endTime
												.year(e.target.value.getFullYear())
												.month(e.target.value.getMonth())
												.date(e.target.value.getDate());

											endTimeOnChange({
												target: {
													value: _endTime.toISOString(),
												},
											});
										} else
											endTimeOnChange({
												target: {
													value: null,
												},
											});
									}}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								ref={refForTimePicker}
								name='endTime'
								label='End Time'
								id={data.id}
								loadingContainer
								colLg={3}
								colMd={6}
								valRes={endTimeValRes}
								showValidation={endTimeShowVal}>
								<TimePickerInput
									showSecond={false}
									placeholder='Select End Time'
									value={endTime ? moment.tz(endTime, 'UTC') : null}
									defaultValue={moment()}
									placement='topLeft'
									onChange={e => {
										if (e) {
											const _endTime = endTime
												? moment.tz(endTime, 'UTC')
												: moment
														.tz('UTC')
														.second(59)
														.millisecond(999);

											_endTime.hour(e.hour()).minute(e.minute());

											endTimeOnChange({
												target: {
													value: _endTime.toISOString(),
												},
											});
										} else
											endTimeOnChange({
												target: {
													value: null,
												},
											});
										onFormChange();
									}}
									size={sizeTimePicker}
									use12Hours
									minuteStep={1}
									onClose={setEndTimeShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='type'
								label='Type'
								id={data.id}
								valRes={typeValRes}
								showValidation={typeShowVal}
								colMd={3}>
								<Selects
									options={enumUnitBlockoutTypes}
									placeholder='Type (Required)'
									value={type}
									onChange={typeOnChange}
									onBlur={setTypeShowVal}
									displayKey='value'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='mapColor' label='Map Color' id={data.id} colMd={3}>
								<Selects
									className='sdms-font-transform-c'
									placeholder='Select a Color'
									options={colorList.current}
									value={
										mapColor
											? colorList.current.find(c => c.class === mapColor)
											: null
									}
									onChange={c =>
										mapColorOnChange({
											target: {
												value: c.target.value ? c.target.value.class : null,
											},
										})
									}
									displayKey='name'
									valueKey='class'
									renderOption={option => (
										<Badge
											className={`sdms-custom-colors--${option.class} sdms-font-transform-c`}
											isInline>
											<span>{option.name}</span>
										</Badge>
									)}
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
SpaceBlockoutForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		bookingType: PropTypes.object,
		unitGroup: PropTypes.object,
		description: PropTypes.string,
		module: PropTypes.object,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	marinaUnits: PropTypes.arrayOf(PropTypes.object),
	enumUnitBlockoutTypes: PropTypes.arrayOf(PropTypes.object),
	marinaBookingTypes: PropTypes.arrayOf(PropTypes.object),
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
SpaceBlockoutForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		bookingType: {},
		unitGroup: {},
		description: '',
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	marinaUnits: [],
	enumUnitBlockoutTypes: [],
	marinaBookingTypes: [],
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default SpaceBlockoutForm;
