import React from 'react';
import PropTypes from 'prop-types';
import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';
import PowerMeterForm from './PowerMeterForm';

const PowerMeterEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='powerMeters'
			fields={<PowerMeterForm />}
			lists={['powerMeterTypes', 'marinaUnits']}
			pageTitle={pages.marina.settings.powerMeters.text}
			icon={pages.marina.settings.powerMeters.icon}
			module={modules.MARINA}
		/>
	);
};

PowerMeterEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default PowerMeterEdit;
