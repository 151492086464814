import React, { useContext, useEffect } from 'react';
import { ReactSVG } from 'react-svg';

import HeaderContext from '../app/contexts/HeaderContext';

import ContentInner from './reusables/template/ContentInner';
import BreadcrumbContainer from './reusables/template/BreadcrumbContainer';

import bg from '../assets/img/bg.svg';

const NoPermission = () => {
	const headerContext = useContext(HeaderContext);
	useEffect(() => {
		headerContext.setBreadcrumbs([{ title: 'No Permission Page', isActive: true }]);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title='No Permission Page' />
					<BreadcrumbContainer />
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title='No Permission Page'>
				<div className='row'>
					<div className='col-3' />
					<div className='col-6 sdms-align-center'>
						<h1>Access Denied</h1>
						<div className='sdms-font-lg sdms-m15'>
							You don’t have permission to access this page
						</div>
						<ReactSVG src={bg} className='svg-container' />
					</div>
					<div className='col-3' />
				</div>
			</ContentInner.Container>
		</>
	);
};

export default NoPermission;
