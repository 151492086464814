import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import ContentInner from '../../reusables/template/ContentInner';
import IconBox from '../../reusables/layout/IconBox';
import pages from '../../pages';
import UserContext from '../../../app/contexts/UserContext';
import logo from '../../../assets/img/logo.svg';

const _Dashboard = () => {
	const history = useHistory();
	const userContext = useContext(UserContext);

	const _colClass = 'col-xl-4 col-md-6';

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title={pages.systemSettings.dashboard.text} />
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title={pages.systemSettings.dashboard.text}>
				<div className='row'>
					<div className='col-3' />
					<div className='col-6 sdms-align-center'>
						<ReactSVG
							src={logo}
							className='svg-container sdms-block-center sdms-w-35p-desktop sdms-margin-t-30'
						/>
					</div>
					<div className='offset-xl-2 col-xl-8 offset-lg-1 col-lg-10 col-md-12 sdms-mt-50'>
						<div className='row sdms-iconbox--container'>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.accountingIntegration.icon}
									title={pages.systemSettings.accountingIntegration.text}
									onClick={() =>
										history.push(
											pages.systemSettings.accountingIntegration.path
										)
									}
									animate={null}>
									Edit Account Integration
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.attachmentTypes.icon}
									title={pages.systemSettings.attachmentTypes.text}
									onClick={() =>
										history.push(pages.systemSettings.attachmentTypes.path)
									}
									animate={null}>
									Attachment Settings
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.auditLogs.icon}
									title={pages.systemSettings.auditLogs.text}
									onClick={() =>
										history.push(pages.systemSettings.auditLogs.path)
									}
									animate={null}
									noPermission={
										!userContext.hasPermission(
											pages.systemSettings.auditLogs.permission
										)
									}>
									View Audit Logs
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.batchJobs.icon}
									title={pages.systemSettings.batchJobs.text}
									onClick={() =>
										history.push(pages.systemSettings.batchJobs.path)
									}
									noPermission={!userContext.hasPermission(null, true)}
									animate={null}>
									Edit Batch Jobs
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.calendars.icon}
									title={pages.systemSettings.calendars.text}
									onClick={() =>
										history.push(pages.systemSettings.calendars.path)
									}
									animate={null}
									noPermission={
										!userContext.hasPermission(
											pages.systemSettings.calendars.permission
										)
									}>
									View, Add & Edit Calendars and Periods.
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.contracts.icon}
									title={pages.systemSettings.contracts.text}
									onClick={() =>
										history.push(pages.systemSettings.contracts.path)
									}
									animate={null}>
									View,Add & Edit Contracts
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.emailAddresses.icon}
									title={pages.systemSettings.emailAddresses.text}
									onClick={() =>
										history.push(pages.systemSettings.emailAddresses.path)
									}
									animate={null}>
									View, Add & Edit Emails
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.metas.icon}
									title={pages.systemSettings.metas.text}
									onClick={() => history.push(pages.systemSettings.metas.path)}
									animate={null}>
									View, Add & Edit Metas
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.passwordSettings.icon}
									title={pages.systemSettings.passwordSettings.text}
									onClick={() =>
										history.push(pages.systemSettings.passwordSettings.path)
									}
									animate={null}>
									Edit Password Settings
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.personalization.icon}
									title={pages.systemSettings.personalization.text}
									onClick={() =>
										history.push(pages.systemSettings.personalization.path)
									}
									animate={null}>
									Customize Colors & Contrast
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.productFilters.icon}
									title={pages.systemSettings.productFilters.text}
									onClick={() =>
										history.push(pages.systemSettings.productFilters.path)
									}
									animate={null}>
									View, Add & Edit Product Filters
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.roles.icon}
									title={pages.systemSettings.roles.text}
									onClick={() => history.push(pages.systemSettings.roles.path)}
									animate={null}
									noPermission={
										!userContext.hasPermission(
											pages.systemSettings.roles.permission
										)
									}>
									View, Add & Edit Roles
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.seasons.icon}
									title={pages.systemSettings.seasons.text}
									onClick={() => history.push(pages.systemSettings.seasons.path)}
									animate={null}
									noPermission={false}>
									View, Add & Edit Seasons.
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.templates.icon}
									title={pages.systemSettings.templates.text}
									onClick={() =>
										history.push(pages.systemSettings.templates.path)
									}
									animate={null}>
									View,Add & Edit Templates
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.systemSettings.users.icon}
									title={pages.systemSettings.users.text}
									onClick={() => history.push(pages.systemSettings.users.path)}
									animate={null}
									noPermission={
										!userContext.hasPermission(
											pages.systemSettings.users.permission
										)
									}>
									View, Add & Edit Users
								</IconBox>
							</div>
						</div>
					</div>
				</div>
			</ContentInner.Container>
		</>
	);
};

export default _Dashboard;
