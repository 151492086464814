import React, { useContext, useEffect } from 'react';
import update from 'immutability-helper';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';

import FormContainer from '../../reusables/template/FormContainer';
import BatchJobsForm from './BatchJobsForm';

const BatchJobsEdit = () => {
	// Use current user to get company data.
	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.systemSettings.default.text, path: pages.systemSettings.dashboard.path },
			{
				title: pages.systemSettings.batchJobs.text,
				path: pages.systemSettings.batchJobs.path,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(pages.systemSettings.batchJobs.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<FormContainer
			dataId={
				userContext.data.user.company.settings
					? userContext.data.user.company.settings.id.toString()
					: 0
			}
			pageTitle={pages.systemSettings.batchJobs.text}
			icon={pages.systemSettings.batchJobs.icon}
			dataName={pages.systemSettings.batchJobs.route}
			fields={<BatchJobsForm />}
			lists={['emailAddresses']}
			showBackButton={false}
			afterSubmit={data => {
				userContext.setData(
					update(userContext.data, {
						user: { company: { settings: { $set: data } } },
					})
				);
			}}
		/>
	);
};

export default BatchJobsEdit;
