import React from 'react';
import ContentInner from '../template/ContentInner';
import Loading from '../template/Loading';
import BreadcrumbContainer from '../template/BreadcrumbContainer';

const OutletInactive = () => {
	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<Loading isLoading={false} width={150} type='title'>
						<ContentInner.SubHeaderTitle title='Unconfigured Outlet' />
					</Loading>
					<Loading isLoading={false} type='breadcrumb'>
						<BreadcrumbContainer />
					</Loading>
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container title='Unconfigured Outlet' hasFrame>
				<h4 className='text-center my-5'>
					Outlet settings and reservation settings are not set. Please configure before
					proceeding.
				</h4>
			</ContentInner.Container>
		</>
	);
};

export default OutletInactive;
