import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import {
	paymentTypes as _paymentTypes,
	reservationStatuses,
} from '../../../../utils/constants/constants';
import apiCall from '../../../../utils/helpers/apiCall';
import { addErrorNotification } from '../../../../utils/helpers/helper';
import Search from '../../element/Search';
import Button from '../../element/Button';
import Portlet from '../../layout/Portlet';
import SelectableList from '../../template/SelectableList';
import Paid from './Paid';
import NumPad from './NumPad';

const BookingCustomerCell = ({ data }) => {
	return <span>{data.reservation.customer.displayName}</span>;
};
BookingCustomerCell.propTypes = {
	data: PropTypes.shape({
		reservation: PropTypes.object,
	}),
};
BookingCustomerCell.defaultProps = {
	data: null,
};

const Booking = ({ amount, payment, setPaymentData, balanceDue }) => {
	const [activeContent, setActiveContent] = useState('search');

	const [data, setData] = useState([]);

	const [searchText, setSearchText] = useState('');

	const [isLoading, setIsLoading] = useState(true);

	const totalItems = useRef(0);

	const searchInputRef = useRef();

	const abortController = useRef(null);

	useEffect(() => {
		if (abortController.current) abortController.current.abort();

		abortController.current = new AbortController();

		if (searchText.length > 0) {
			setIsLoading(true);

			const filters = {
				'order[id]': 'asc',
				'or[reservation.customer.displayName&reservationItemId&unit.name]': searchText,
				'status.value[]': [reservationStatuses.CONFIRMED, reservationStatuses.CHECKED_IN],
			};

			apiCall(
				'GET',
				'reservationItems',
				res => {
					if (activeContent !== 'search') return;
					totalItems.current = res['hydra:totalItems'] || res.length;
					setData(res);
					setIsLoading(false);
				},
				err => {
					if (err.toString().search('AbortError') === -1) {
						addErrorNotification(err.toString());
						setIsLoading(false);
					}
				},
				'',
				null,
				filters,
				abortController.current.signal
			);
		} else {
			totalItems.current = 0;
			setData([]);
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText]);

	useEffect(() => {
		if (activeContent === 'search') searchInputRef.current.focus();
	}, [activeContent]);

	useEffect(() => {
		setPaymentData({
			type: _paymentTypes.BOOKING,
			amount: payment.amount > amount || !payment.amount ? amount.toFixed(2) : payment.amount,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Paid
				amountInputFocus={activeContent === 'numpad'}
				amount={balanceDue || amount}
				paid={payment.amount}
				onClick={() => {
					setActiveContent(activeContent === 'numpad' ? 'booking' : 'numpad');
					if (activeContent === 'search') setActiveContent('booking');
				}}>
				{activeContent === 'search' ? (
					<Search
						inPortlet
						searchText={searchText}
						setSearchText={setSearchText}
						isSearching={isLoading}
						ref={searchInputRef}
						placeHolder='Search...'
						fluid
					/>
				) : (
					<Button
						className='h-100 sdms-p0'
						label='dark'
						icon='User'
						iconSize={36}
						block
						onClick={() =>
							setActiveContent(
								activeContent === 'booking' || activeContent === 'numpad'
									? 'search'
									: 'booking'
							)
						}>
						{payment.customer.displayName}
					</Button>
				)}
			</Paid>

			{activeContent === 'booking' && (
				<div className='row'>
					{payment.booking ? (
						<>
							<div className='col-6'>
								<div className='row'>
									<div className='col-4 sdms-font-boldest'>First Name :</div>
									<div className='col-8'>{payment.customer.firstName}</div>
								</div>
								<div className='row'>
									<div className='col-4 sdms-font-boldest'>Last Name :</div>
									<div className='col-8'>{payment.customer.lastName}</div>
								</div>
								<div className='row'>
									<div className='col-4 sdms-font-boldest'>Phone :</div>
									<div className='col-8'> {payment.customer.phone}</div>
								</div>
								<div className='row'>
									<div className='col-4 sdms-font-boldest'>Alt. Phone :</div>
									<div className='col-8'>{payment.customer.altPhone}</div>
								</div>
								<div className='row'>
									<div className='col-4 sdms-font-boldest'>Email :</div>
									<div className='col-8'>{payment.customer.email}</div>
								</div>
							</div>
							<div className='col-6'>
								<div className='row'>
									<div className='col-4 sdms-font-boldest'>Booking:</div>
									<div className='col-8'>{payment.booking.id}</div>
								</div>
								<div className='row'>
									<div className='col-4 sdms-font-boldest'>Product :</div>
									<div className='col-8'>{payment.booking.product.name}</div>
								</div>
								<div className='row'>
									<div className='col-4 sdms-font-boldest'>Unit :</div>
									<div className='col-8'>
										{payment.booking.unit?.name || 'N/A'}
									</div>
								</div>
							</div>
						</>
					) : (
						<p>Please search for booking</p>
					)}
				</div>
			)}

			{activeContent === 'search' && (
				<Portlet className='sdms-list-layout' fluid='fluid' border>
					<SelectableList
						data={data}
						withOutPortlet
						onClick={booking => {
							setPaymentData({ booking, customer: booking.reservation.customer });
							setActiveContent('booking');
						}}
						isLoading={isLoading}>
						<SelectableList.Col
							width='30%'
							label='Customer'
							cellComponent={<BookingCustomerCell />}
						/>
						<SelectableList.Col label='Booking' cellData='reservationItemId' />
						<SelectableList.Col
							label='Product'
							cellData='name'
							cellDataObject='product'
						/>
						<SelectableList.Col label='Unit' cellData='name' cellDataObject='unit' />
					</SelectableList>
				</Portlet>
			)}

			{activeContent === 'numpad' && (
				<NumPad
					value={payment.amount}
					setValue={value => setPaymentData({ amount: value })}
					initValue={balanceDue || amount}
					max={amount}
					onCloseNumpad={() => setActiveContent('customer')}
				/>
			)}
		</>
	);
};
Booking.propTypes = {
	amount: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	payment: PropTypes.object.isRequired,
	setPaymentData: PropTypes.func.isRequired,
	balanceDue: PropTypes.number.isRequired,
};

export default Booking;
