import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SVGIcon from './reusables/element/SVGIcon';
import { Logo } from './Aside';
import Loading from './reusables/template/Loading';
import Toggle from './reusables/field/Toggle';
import { screens } from '../utils/constants/constants';

const HeaderMobile = ({
	overlay,
	setOverlay,
	isAside,
	isHeader,
	isTopBar,
	isSideCar,
	screen,
	sideCar,
	setSideCar,
	register,
	isLoading,
	isOrderLoading,
}) => {
	const changeStatus = (change, setChange, className) => {
		if (!change) {
			document.body.classList.add(className);
		} else {
			document.body.classList.remove(className);
		}
		setChange(!change);
	};
	useEffect(() => {
		if (Object.entries(overlay).length === 0 && overlay.constructor === Object) {
			document.body.classList.remove('sdms-aside--on');
		}
		return () => {};
	}, [overlay]);

	useEffect(() => {
		if (sideCar) {
			document.body.classList.add('sdms-t-sidecar--on');
		}
		return () => {
			document.body.classList.remove('sdms-t-sidecar--on');
		};
	}, [sideCar]);

	return (
		<div id='sdms_header_mobile' className='sdms-header-mobile sdms-header-mobile--fixed'>
			<div className='sdms-header-mobile__logo'>
				<Logo isFavicon />
			</div>
			{register && (
				<div className='sdms-header-mobile__desc row align-items-center justify-content-center w-50 sdms-ml-5'>
					<div className='col-auto '>
						<Loading isLoading={isLoading} type='span'>
							<span className='sdms-font-lg'>
								{!isLoading && register.outlet.name}
							</span>
						</Loading>
						<span> - </span>
						<Loading isLoading={isLoading} type='span'>
							<span className='sdms-font-xl sdms-font-boldest'>
								{!isLoading && register.name}
							</span>
						</Loading>
					</div>
				</div>
			)}
			<div className='sdms-header-mobile__toolbar'>
				{isSideCar && (
					<Toggle
						className='sdms-mb-0'
						type='text'
						value={sideCar}
						onChange={() => changeStatus(sideCar, setSideCar, 'sdms-t-sidecar--on')}
						textOne={
							(screen === screens.RECALL && 'List') ||
							(screen === screens.GRID && 'Products')
						}
						textTwo='Order'
						disabled={isLoading || isOrderLoading}
					/>
				)}
				{isAside && (
					<button
						type='button'
						className='sdms-header-mobile__toggler sdms-header-mobile__toggler--left'
						id='sdms_aside_mobile_toggler'
						onClick={() =>
							setOverlay({
								aside: true,
								header: false,
								topBar: false,
								order: false,
							})
						}>
						<span />
					</button>
				)}
				{isHeader && (
					<button
						type='button'
						className='sdms-header-mobile__toggler'
						id='sdms_header_mobile_toggler'
						onClick={() =>
							setOverlay({
								aside: false,
								header: true,
								topBar: false,
								order: false,
							})
						}>
						<span />
					</button>
				)}
				{isTopBar && (
					<button
						type='button'
						className='sdms-header-mobile__topbar-toggler'
						id='sdms_header_mobile_topbar_toggler'
						onClick={() =>
							setOverlay({
								aside: false,
								header: false,
								topBar: true,
								order: false,
							})
						}>
						<SVGIcon name='Other#1' />
					</button>
				)}
			</div>
		</div>
	);
};
HeaderMobile.propTypes = {
	isAside: PropTypes.bool,
	isHeader: PropTypes.bool,
	isTopBar: PropTypes.bool,
	isSideCar: PropTypes.bool,
	overlay: PropTypes.shape({
		aside: PropTypes.bool,
		header: PropTypes.bool,
		topBar: PropTypes.bool,
		order: PropTypes.bool,
	}),
	setOverlay: PropTypes.func,
	sideCar: PropTypes.bool,
	setSideCar: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	register: PropTypes.object,
	isLoading: PropTypes.bool,
	isOrderLoading: PropTypes.bool,
	screen: PropTypes.string,
};
HeaderMobile.defaultProps = {
	isAside: false,
	isHeader: false,
	isTopBar: false,
	isSideCar: false,
	overlay: {
		aside: false,
		header: false,
		topBar: false,
		order: false,
	},
	setOverlay: () => {},
	sideCar: false,
	setSideCar: () => {},
	register: null,
	isLoading: true,
	isOrderLoading: true,
	screen: null,
};

export default HeaderMobile;
