import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import classNames from 'classnames';
import pages from '../../pages';
import { convertDateToUTC } from '../../../utils/helpers/helper';
import useField from '../../../utils/hooks/useField';
import HeaderContext from '../../../app/contexts/HeaderContext';
import UserContext from '../../../app/contexts/UserContext';

import FormGroup from '../../reusables/layout/FormGroup';
import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import FormField from '../../reusables/template/FormField';
import Toggle from '../../reusables/field/Toggle';
import Button from '../../reusables/element/Button';
import Radio from '../../reusables/field/Radio';
import Loading from '../../reusables/template/Loading';
import Selects from '../../reusables/field/Selects';
import DatePicker from '../../reusables/field/DatePicker';

const AccountingIntegrationForm = ({
	data,
	submit,
	isLoading,
	submitButtonAttr,
	onFormChange,
	acctAccounts,
	acctSubtotals,
	acctProducts,
	enumPaymentMethods,
	taxRates,
	taxCodes,
	accounts,
}) => {
	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const productOptionsRef = useRef([]);

	const accountOptionsRef = useRef([]);

	const findOption = (options, value) => (value ? options.find(o => o.listId === value) : null);

	const parseValue = e => (e.target.value ? { target: { value: e.target.value.listId } } : null);

	const parseOption = (option, key) => {
		return option ? { ...option, name: option[key].name } : null;
	};

	const [subtotalItem, subtotalItemOnChange] = useField(
		data,
		'quickbooksSubtotalItem',
		onFormChange,
		[],
		null
	);

	const [tipItem, tipItemOnChange] = useField(data, 'quickbooksTipItem', onFormChange, [], null);

	const [roundingItem, roundingItemOnChange] = useField(
		data,
		'quickbooksRoundingItem',
		onFormChange,
		[],
		null
	);

	const [refundExpenseLineAccount, refundExpenseLineAccountOnChange] = useField(
		data,
		'quickbooksRefundExpenseLineAccount',
		onFormChange,
		[],
		null
	);

	const [defaultAccountsReceivableAccount, defaultAccountsReceivableAccountOnChange] = useField(
		data,
		'quickbooksDefaultAccountsReceivableAccount',
		onFormChange,
		[],
		null
	);

	const [
		quickbooksDefaultTaxLiabilityAccount,
		quickbooksDefaultTaxLiabilityAccountOnChange,
	] = useField(data, 'quickbooksDefaultTaxLiabilityAccount', onFormChange, [], null);

	const [defaultPaymentMethod, defaultPaymentMethodOnChange] = useField(
		data,
		'defaultPaymentMethod',
		onFormChange,
		[],
		null
	);

	const [defaultTaxRate, defaultTaxRateOnChange] = useField(
		data,
		'defaultTaxRate',
		onFormChange,
		[],
		null
	);

	const [defaultNonTaxCode, defaultNonTaxCodeOnChange] = useField(
		data,
		'defaultNonTaxCode',
		onFormChange,
		[],
		null
	);

	const [defaultIncomeAccount, defaultIncomeAccountOnChange] = useField(
		data,
		'quickbooksDefaultIncomeAccount',
		onFormChange,
		[],
		null
	);

	const [reverseDisplayName, reverseDisplayNameOnChange] = useField(
		data,
		'reverseDisplayName',
		onFormChange,
		[],
		false
	);

	const [defaultCreditCard, defaultCreditCardOnChange] = useField(
		data,
		'defaultCreditCard',
		onFormChange,
		[],
		false
	);

	const [combineCustomerBalance, combineCustomerBalanceOnChange] = useField(
		data,
		'combineCustomerBalance',
		onFormChange,
		[],
		false
	);

	const [detailedDailyDeferred, detailedDailyDeferredOnChange] = useField(
		data,
		'detailedDailyDeferred',
		onFormChange,
		[],
		false
	);

	const [defaultTaxCode, defaultTaxCodeOnChange] = useField(
		data,
		'defaultTaxCode',
		onFormChange,
		[],
		null
	);

	const [manualClosingDate, manualClosingDateOnChange] = useField(
		data,
		'manualClosingDate',
		onFormChange,
		[],
		false
	);

	const [closingDate, closingDateOnChange] = useField(
		data,
		'accountingClosingDate',
		onFormChange
	);

	const [bouncedCheckJournalDebitAccount, bouncedCheckJournalDebitAccountOnChange] = useField(
		data,
		'bouncedCheckJournalDebitAccount',
		onFormChange
	);

	const [bouncedCheckJournalCreditAccount, bouncedCheckJournalCreditAccountOnChange] = useField(
		data,
		'bouncedCheckJournalCreditAccount',
		onFormChange
	);

	const [enableAccounting, enableAccountingOnChange] = useField(
		data,
		'enableAccounting',
		onFormChange,
		[],
		false
	);

	const [useDefaultOutlet, useDefaultOutletOnChange] = useField(
		data,
		'useDefaultOutlet',
		onFormChange,
		[],
		false
	);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.systemSettings.default.text, path: pages.systemSettings.dashboard.path },
			{ title: pages.systemSettings.accountingIntegration.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.systemSettings.accountingIntegration.text);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	useEffect(() => {
		productOptionsRef.current = acctProducts.map(ap => parseOption(ap, 'sdmsProduct'));
	}, [acctProducts]);

	useEffect(() => {
		accountOptionsRef.current = acctAccounts.map(aa => parseOption(aa, 'sdmsAccount'));
	}, [acctAccounts]);

	return (
		<Portlet className='sdms-form' fluid='fluid' hasFrame>
			<Portlet.Body>
				<Section title='General Settings'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='subtotalItem'
									label='Subtotal Item'
									id={data.id}
									colMd={6}>
									<Selects
										options={acctSubtotals}
										placeholder='Select a item'
										value={findOption(acctSubtotals, subtotalItem)}
										onChange={e => subtotalItemOnChange(parseValue(e))}
										valueKey='listId'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField name='tipItem' label='Tip Item' id={data.id} colMd={6}>
									<Selects
										options={productOptionsRef.current}
										placeholder='Select a item'
										value={findOption(productOptionsRef.current, tipItem)}
										onChange={e => tipItemOnChange(parseValue(e))}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='roundingItem'
									label='Rounding Item'
									id={data.id}
									colMd={6}>
									<Selects
										options={productOptionsRef.current}
										placeholder='Select a item'
										value={findOption(productOptionsRef.current, roundingItem)}
										onChange={e => roundingItemOnChange(parseValue(e))}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='refundExpenseLineAccount'
									label='Refund Expense Line Account'
									id={data.id}
									colMd={6}>
									<Selects
										options={accountOptionsRef.current}
										placeholder='Select a account'
										value={findOption(
											accountOptionsRef.current,
											refundExpenseLineAccount
										)}
										onChange={e =>
											refundExpenseLineAccountOnChange(parseValue(e))
										}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='defaultAccountsReceivableAccount'
									label='Default Accounts Receivable Account'
									id={data.id}
									colMd={6}>
									<Selects
										options={accountOptionsRef.current}
										placeholder='Select a account'
										value={findOption(
											accountOptionsRef.current,
											defaultAccountsReceivableAccount
										)}
										onChange={e =>
											defaultAccountsReceivableAccountOnChange(parseValue(e))
										}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='defaultIncomeAccount'
									label='Default Income Account'
									id={data.id}
									colMd={6}>
									<Selects
										options={accountOptionsRef.current}
										placeholder='Select an account'
										value={findOption(
											accountOptionsRef.current,
											defaultIncomeAccount
										)}
										onChange={e => defaultIncomeAccountOnChange(parseValue(e))}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='quickbooksDefaultTaxLiabilityAccount'
									label='Default Tax Liability Account'
									id={data.id}
									colMd={6}>
									<Selects
										options={accountOptionsRef.current}
										placeholder='Select an account'
										value={findOption(
											accountOptionsRef.current,
											quickbooksDefaultTaxLiabilityAccount
										)}
										onChange={e =>
											quickbooksDefaultTaxLiabilityAccountOnChange(
												parseValue(e)
											)
										}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='defaultTaxRate'
									label='Default Sales Tax Rate'
									id={data.id}
									colMd={6}>
									<Selects
										options={taxRates}
										placeholder='Default Tax Rate'
										value={defaultTaxRate}
										onChange={defaultTaxRateOnChange}
										displayKey='name'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='defaultPaymentMethod'
									label='Default Payment Method'
									id={data.id}
									colMd={6}>
									<Selects
										options={enumPaymentMethods}
										placeholder='Default Payment Method'
										value={defaultPaymentMethod}
										onChange={defaultPaymentMethodOnChange}
										displayKey='name'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='defaultTaxCode'
									label='Default Tax Code'
									id={data.id}
									colMd={6}>
									<Selects
										options={taxCodes}
										placeholder='Default Tax Code'
										value={defaultTaxCode}
										onChange={defaultTaxCodeOnChange}
										displayKey='name'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='defaultNonTaxCode'
									label='Default Non-Tax Code'
									id={data.id}
									colMd={6}>
									<Selects
										options={taxCodes}
										placeholder='Default Non-Tax Code'
										value={defaultNonTaxCode}
										onChange={defaultNonTaxCodeOnChange}
										displayKey='name'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='bouncedCheckJournalDebitAccount'
									label='Bounced Check Journal Debit Account'
									id={data.id}
									colMd={6}>
									<Selects
										options={accounts}
										placeholder='Select an account'
										value={bouncedCheckJournalDebitAccount}
										onChange={bouncedCheckJournalDebitAccountOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='bouncedCheckJournalCreditAccount'
									label='Bounced Check Journal Credit Account'
									id={data.id}
									colMd={6}>
									<Selects
										options={accounts}
										placeholder='Select an account'
										value={bouncedCheckJournalCreditAccount}
										onChange={bouncedCheckJournalCreditAccountOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='useDefaultOutlet'
									label='Use Default Outlet'
									id='useDefaultOutlet'
									colXl={6}
									noPermission={!userContext.hasPermission(null, true)}>
									<Toggle
										spaceLess
										value={useDefaultOutlet}
										onChange={useDefaultOutletOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='reverseDisplayName'
									label='Display Name'
									id={data.id}
									colXl={6}>
									<Radio.Container isInline>
										<Radio
											checked={!reverseDisplayName}
											id='yes'
											name='reverseDisplayName'
											content='First Last'
											className='sdms-radio--primary'
											onChange={() =>
												reverseDisplayNameOnChange({
													target: { value: false },
												})
											}
										/>
										<Radio
											checked={reverseDisplayName}
											id='no'
											name='reverseDisplayName'
											content='Last, First'
											className='sdms-radio--primary'
											onChange={() =>
												reverseDisplayNameOnChange({
													target: { value: true },
												})
											}
										/>
									</Radio.Container>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='defaultCreditCard'
									label='Default Payment Type'
									id={data.id}
									colXl={6}>
									<Radio.Container isInline>
										<Radio
											checked={!defaultCreditCard}
											id='cash_yes'
											name='defaultCreditCard'
											content='Cash'
											className='sdms-radio--primary'
											onChange={() =>
												defaultCreditCardOnChange({
													target: { value: false },
												})
											}
										/>
										<Radio
											checked={defaultCreditCard}
											id='cash_no'
											name='defaultCreditCard'
											content='Credit Card'
											className='sdms-radio--primary'
											onChange={() =>
												defaultCreditCardOnChange({
													target: { value: true },
												})
											}
										/>
									</Radio.Container>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='combineCustomerBalance'
									label='Customer Balance'
									id={data.id}
									colXl={6}>
									<Radio.Container isInline>
										<Radio
											checked={!combineCustomerBalance}
											id='customerBalanceNo'
											name='combineCustomerBalanceNo'
											content='Balance By Customer/Outlet'
											className='sdms-radio--primary'
											onChange={() =>
												combineCustomerBalanceOnChange({
													target: { value: false },
												})
											}
										/>
										<Radio
											checked={combineCustomerBalance}
											id='customerBalanceYes'
											name='combineCustomerBalanceYes'
											content='Balance By Customer'
											className='sdms-radio--primary'
											onChange={() =>
												combineCustomerBalanceOnChange({
													target: { value: true },
												})
											}
										/>
									</Radio.Container>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='detailedDailyDeferred'
									label='Daily Deferred'
									id={data.id}
									colXl={6}>
									<Radio.Container isInline>
										<Radio
											checked={!detailedDailyDeferred}
											id='detailedDailyNo'
											name='detailedDailyDeferredNo'
											content='Group By Month'
											className='sdms-radio--primary'
											onChange={() =>
												detailedDailyDeferredOnChange({
													target: { value: false },
												})
											}
										/>
										<Radio
											checked={detailedDailyDeferred}
											id='detailDailyYes'
											name='detailedDailyDeferredYes'
											content='Detail By Day'
											className='sdms-radio--primary'
											onChange={() =>
												detailedDailyDeferredOnChange({
													target: { value: true },
												})
											}
										/>
									</Radio.Container>
								</FormField>
							</Loading>
						</FormGroup>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='manualClosingDate'
									label='Manual Closing Date'
									id='manualClosingDate'
									colXl={6}>
									<Toggle
										spaceLess
										value={manualClosingDate}
										onChange={manualClosingDateOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='closingDate'
									label='Closing Date'
									id={data.id}
									loadingContainer
									colXl={6}>
									<DatePicker
										id='closingDate'
										type='calendar'
										placeholder='Closing Date'
										value={
											closingDate
												? convertDateToUTC(moment(closingDate).toDate())
												: null
										}
										onChange={e => {
											if (e.target.value) {
												const _closingDate =
													closingDate !== null
														? moment(closingDate)
																.utc(false)
																.year(e.target.value.getFullYear())
																.month(e.target.value.getMonth())
																.date(e.target.value.getDate())
														: moment(e.target.value).utc(false);

												closingDateOnChange({
													target: {
														value: _closingDate
															.endOf('day')
															.toISOString(),
													},
												});
											} else {
												closingDateOnChange({
													target: {
														value: null,
													},
												});
											}
										}}
									/>
								</FormField>
							</Loading>
						</FormGroup>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableAccounting'
									label='Enable Sharper Accounting'
									id='enableAccounting'
									colXl={6}
									noPermission={!userContext.hasPermission(null, true)}>
									<Toggle
										spaceLess
										value={enableAccounting}
										onChange={enableAccountingOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
AccountingIntegrationForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		taxId: PropTypes.string,
		duns: PropTypes.string,
		serviceFeeItem: PropTypes.object,
	}),
	submit: PropTypes.func,
	isLoading: PropTypes.bool,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
	acctAccounts: PropTypes.arrayOf(PropTypes.object),
	acctSubtotals: PropTypes.arrayOf(PropTypes.object),
	acctProducts: PropTypes.arrayOf(PropTypes.object),
	enumPaymentMethods: PropTypes.arrayOf(PropTypes.object),
	taxRates: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	accounts: PropTypes.arrayOf(PropTypes.object),
};
AccountingIntegrationForm.defaultProps = {
	data: {
		id: 0,
	},
	submit: () => {},
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
	acctAccounts: [],
	acctSubtotals: [],
	acctProducts: [],
	enumPaymentMethods: [],
	taxRates: [],
	taxCodes: [],
	accounts: [],
};

export default AccountingIntegrationForm;
