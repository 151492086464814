import React, { createRef, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import update from 'immutability-helper';
import moment from 'moment';
import Timeline from 'react-calendar-timeline/lib/lib/Timeline';
import { TimelineHeaders } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import { dateView } from '../../../../utils/helpers/helper';
import { pricingTypes } from '../../../../utils/constants/constants';
import useModal from '../../../../utils/hooks/useModal';

import Button from '../../element/Button';
import Portal from '../../layout/Portal';
import Portlet from '../../layout/Portlet';
import Selects from '../../field/Selects';
import FormField from '../../template/FormField';
import Input, { InputPend } from '../../field/Input';
import Toggle from '../../field/Toggle';
import Dropdown from '../../element/Dropdown';

const RatePlanModal = ({
	onClose,
	onSubmit,
	ratePlans,
	selectedRatePlans,
	open,
	productBookingPeriodId,
}) => {
	const [ratePlan, setRatePlan] = useState(null);

	return (
		<Portal>
			<Popup
				open={open}
				onClose={onClose}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
				}}>
				<Portlet>
					<Portlet.Head>
						<Portlet.HeadLabelTitle>Add Rate Plan</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<FormField
							name='ratePlan'
							label='Rate Plan'
							description='Select Rate Plan'
							col={6}>
							<Selects
								options={ratePlans.filter(
									rp =>
										selectedRatePlans.findIndex(srp => srp.id === rp.id) ===
											-1 && rp.bookingPeriod.id === productBookingPeriodId
								)}
								placeholder='Rate Plan'
								value={ratePlan}
								onChange={e => setRatePlan(e.target.value)}
								displayKey='internalName'
							/>
						</FormField>
					</Portlet.Body>
					<Portlet.Foot className='sdms-align-left' tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
							/>
						</div>
						<div className='col-auto'>
							<Button
								label='brand'
								icon='Done-circle'
								text='Ok'
								size='sm'
								disabled={!ratePlan}
								onClick={() => {
									onSubmit(ratePlan);
									setRatePlan({});
									onClose();
								}}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};

RatePlanModal.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool,
	selectedRatePlans: PropTypes.arrayOf(PropTypes.object),
	productBookingPeriodId: PropTypes.number,
};

RatePlanModal.defaultProps = {
	onClose: () => {},
	onSubmit: () => {},
	ratePlans: [],
	open: false,
	selectedRatePlans: [],
	productBookingPeriodId: 0,
};

const SeasonModal = ({ onClose, onSubmit, seasons, selectedSeasons, open }) => {
	const [season, setSeason] = useState(null);

	return (
		<Portal>
			<Popup
				open={open}
				onClose={onClose}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
				}}>
				<Portlet>
					<Portlet.Head>
						<Portlet.HeadLabelTitle>Add Season</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<FormField name='season' label='Season' description='Select Season' col={6}>
							<Selects
								options={seasons.filter(
									_season => !selectedSeasons.some(s => s.id === _season.id)
								)}
								placeholder='Select a Season'
								value={season}
								onChange={e => setSeason(e.target.value)}
							/>
						</FormField>
					</Portlet.Body>
					<Portlet.Foot className='sdms-align-left' tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
							/>
						</div>
						<div className='col-auto'>
							<Button
								label='brand'
								icon='Done-circle'
								text='Add'
								size='sm'
								disabled={!season}
								onClick={() => {
									onSubmit(season);
									setSeason({});
									onClose();
								}}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};

SeasonModal.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	seasons: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool,
	selectedSeasons: PropTypes.arrayOf(PropTypes.object),
};

SeasonModal.defaultProps = {
	onClose: () => {},
	onSubmit: () => {},
	seasons: [],
	open: false,
	selectedSeasons: [],
};

const getItemBgColor = itemContext =>
	itemContext.selected ? 'rgba(256, 256, 256, 0)' : 'transparent';

const getItemInput = (item, ref) => {
	if (item.group === 0) {
		return (
			<>
				<span
					data-tip={`seasonTooltip${item.season.id}`}
					data-for={`seasonTooltip${item.season.id}`}
					className='sdms-text-overflow'
					style={{
						color: 'black',
						width: '100%',
						background: '#fff',
						textAlign: 'center',
					}}>
					{item.season.name}
				</span>
				<Portal>
					<ReactTooltip id={`seasonTooltip${item.season.id}`} effect='solid'>
						<span>
							Season: {item.season.name}{' '}
							{dateView(item.season.startDate, item.season.endDate, false)}
						</span>
					</ReactTooltip>
				</Portal>
			</>
		);
	}

	if (item.row === 1)
		return (
			<Toggle
				onChange={e =>
					item.updateItem({
						id: item.id,
						row: item.row,
						productRatePlan: item.productRatePlan,
						value: e.target.value,
					})
				}
				value={item.value}
				size='sm'
				color='success'
				outline
				spaceLess
			/>
		);

	if (item.row < 7)
		return (
			<Input
				ref={ref}
				value={item.value}
				type={item.row === 2 ? 'text' : 'number'}
				withOutSpin
				tabIndex={item.row}
				onChange={e =>
					item.updateItem({
						id: item.id,
						row: item.row,
						productRatePlan: item.productRatePlan,
						value: e.target.value,
					})
				}
				pattern={
					item.row === 2
						? process.env.REACT_APP_PRICE_PATTERN
						: process.env.REACT_APP_INTEGER_PATTERN
				}
			/>
		);

	return (
		<>
			<Input
				ref={ref}
				type='number'
				withOutSpin
				value={item.value}
				min={0}
				tabIndex={item.row}
				pattern={process.env.REACT_APP_INTEGER_PATTERN}
				onChange={e =>
					item.updateItem({
						id: item.id,
						productRatePlan: item.productRatePlan,
						value: e.target.value,
						type: item.type,
						row: item.row,
					})
				}
			/>
			<InputPend text={item.type} isAppend />
		</>
	);
};

const ItemRenderer = ({ item, itemContext, getItemProps }) => {
	const inputRef = createRef();

	return (
		<div
			{...getItemProps({
				onMouseDown: () => {
					if (inputRef.current) inputRef.current.focus();
				},
				style: { backgroundColor: getItemBgColor(itemContext), border: 'none' },
				className: 'sdms-booking-advPrc__item',
			})}>
			{getItemInput(item, inputRef)}
		</div>
	);
};

ItemRenderer.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	item: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	itemContext: PropTypes.object,
	getItemProps: PropTypes.func,
};
ItemRenderer.defaultProps = {
	item: {},
	itemContext: {},
	getItemProps: () => {},
};

const GroupRenderer = ({ group }) => {
	return (
		<div
			className={classNames('sdms-booking-advPrc__group', {
				'sdms-booking-advPrc__group--title': group.isRatePlanGroup,
			})}>
			<span className='title' title={group.title}>
				{group.title}
			</span>
			{group.isRatePlanGroup && (
				<Dropdown
					icon='Other#1'
					color='clean'
					inline
					aligned='right'
					circle
					size='sm'
					outline={false}
					menuSize='sm'>
					<Dropdown.Header>Group Actions</Dropdown.Header>
					<Dropdown.Item itemsColor='danger' icon='Trash' onClick={group.onClick}>
						Delete
					</Dropdown.Item>
				</Dropdown>
			)}
		</div>
	);
};

GroupRenderer.propTypes = {
	group: PropTypes.shape({
		title: PropTypes.string,
		id: PropTypes.number,
		ratePlan: PropTypes.string,
		groupRow: PropTypes.number,
		isRatePlanGroup: PropTypes.bool,
		modal: PropTypes.func,
		onClick: PropTypes.func,
		groupReference: PropTypes.shape({
			current: PropTypes.number,
		}),
		groupRatePlanReference: PropTypes.shape({
			current: PropTypes.object,
		}),
	}).isRequired,
};

const rowToKey = {
	0: 'season',
	1: 'enabled',
	2: 'price',
	3: 'minDuration',
	4: 'maxDuration',
	5: 'minBookInAdvance',
	6: 'maxBookInAdvance',
	7: 'minLoa',
	8: 'maxLoa',
	9: 'minBeam',
	10: 'maxBeam',
	11: 'minHeight',
	12: 'maxHeight',
	13: 'minWeight',
	14: 'maxWeight',
	15: 'minDraft',
	16: 'maxDraft',
	17: 'minSqft',
	18: 'maxSqft',
	19: 'minPricingLoa',
};

const inchItems = [7, 8, 9, 10, 11, 12, 15, 16, 19];

const modals = {
	RATE_PLAN: 'rate_plan',
	SEASON: 'season',
};

const Seasonal = ({ product, ratePlans, onFormChange, seasons }) => {
	const [modal, openModal, closeModal] = useModal();

	const groupReference = useRef(0);

	const groupRatePlanReference = useRef({});

	const [startTime, setStartTime] = useState(
		moment()
			.startOf('day')
			.valueOf()
	);

	const [endTime, setEndTime] = useState(
		moment()
			.add(2, 'week')
			.endOf('day')
			.valueOf()
	);

	const parseFt = useCallback(value => (value > 12 ? parseInt(value / 12, 10) : 0), []);

	const parseData = useCallback((data, item, value) => {
		if (item.row === 1) return value;

		if (item.row === 2) return value ? parseFloat(value) / item.costPer : null;

		if (inchItems.indexOf(item.row) > -1) {
			if (value === '' && !data[rowToKey[item.row]]) return null;

			const oldValue = data[rowToKey[item.row]] || 0;

			const _value = value || 0;

			if (item.type === 'ft') return parseFloat(_value) * 12 + (oldValue % 12);

			return oldValue - (oldValue % 12) + parseFloat(_value);
		}

		return parseFloat(value);
	}, []);

	const generateProductRatePlan = useCallback(
		(ratePlan, season) => ({
			id: ratePlan.id * season.id * -1,
			ratePlan: ratePlan['@id'],
			season,
			enabled: true,
			price: product.price,
			minBookInAdvance: ratePlan.minBookInAdvance,
			maxBookInAdvance: ratePlan.maxBookInAdvance,
			minDuration: ratePlan.minDuration,
			maxDuration: ratePlan.maxDuration,
			minLoa: ratePlan.minLoa,
			maxLoa: ratePlan.maxLoa,
			minBeam: ratePlan.minBeam,
			maxBeam: ratePlan.maxBeam,
			minHeight: ratePlan.minHeight,
			maxHeight: ratePlan.maxHeight,
			minWeight: ratePlan.minWeight,
			maxWeight: ratePlan.maxWeight,
			minDraft: ratePlan.minDraft,
			maxDraft: ratePlan.maxDraft,
			minSqft: ratePlan.minSqft,
			maxSqft: ratePlan.maxSqft,
			minPricingLoa: ratePlan.minPricingLoa,
		}),
		[product]
	);

	const pricePer = useCallback(ratePlan => {
		let unit = '';

		if (ratePlan.pricingType.value.includes('month'))
			unit = ratePlan.costPer > 1 ? `${ratePlan.costPer} Months` : 'Month';

		if (ratePlan.pricingType.value.includes('unit'))
			unit = ratePlan.costPer > 1 ? `${ratePlan.costPer} Seasons` : 'Season';

		if (
			ratePlan.pricingType.value === pricingTypes.PER_UNIT ||
			ratePlan.pricingType.value === pricingTypes.PER_YEAR
		)
			return `Price per ${unit}`;

		if (ratePlan.pricingType.value.includes('Sqft')) return `Price per ${unit} per Sqft`;

		return `Price per ${unit} per LOA`;
	}, []);

	const getVesselConstraintsItemTimes = useCallback((type, _startTime) => {
		const start = moment(_startTime);

		if (type === 'in') {
			return {
				start_time: start
					.add(3, 'day')
					.startOf('day')
					.valueOf(),
				end_time: start
					.add(2, 'day')
					.endOf('day')
					.valueOf(),
			};
		}

		if (type === 'ft')
			return {
				start_time: start.valueOf(),
				end_time: start
					.add(2, 'day')
					.endOf('day')
					.valueOf(),
			};

		return {
			start_time: start.startOf('day').valueOf(),
			end_time: start
				.add(3, 'day')
				.endOf('day')
				.valueOf(),
		};
	}, []);

	const generateItems = useCallback(
		(
			season,
			index,
			productRatePlans,
			itemsGroups,
			ratePlanList,
			updateItem,
			hasVesselInput
		) => {
			const items = [];

			const range = hasVesselInput ? 5 : 3;

			const start = moment()
				.startOf('day')
				.add(range * index + index, 'day');

			const end = moment(start)
				.endOf('day')
				.add(range, 'day');

			productRatePlans
				.filter(prp => prp.season.id === season.id)
				.forEach(prp => {
					itemsGroups
						.filter(ig => ig.ratePlan === prp.ratePlan)
						.forEach(ig => {
							const ratePlan = ratePlanList.find(rp => rp['@id'] === prp.ratePlan);

							if (ig.groupRow < 7)
								items.push({
									id: `${ig.id}${prp.id}`,
									group: ig.id,
									start_time: start,
									end_time: end,
									productRatePlan: prp.id,
									ratePlan: prp.ratePlan,
									value: prp[rowToKey[ig.groupRow]] || '',
									updateItem,
									row: ig.groupRow,
									costPer: ratePlan.costPer,
									canMove: false,
									canResize: false,
									canChangeGroup: false,
									season: prp.season,
								});
							else if (inchItems.indexOf(ig.groupRow) > -1) {
								items.push({
									id: `${ig.id}${prp.id}-1`,
									group: ig.id,
									start_time: start.valueOf(),
									end_time: end.valueOf(),
									ratePlan: prp.ratePlan,
									value: prp[rowToKey[ig.groupRow]]
										? parseFt(prp[rowToKey[ig.groupRow]])
										: '',
									updateItem,
									row: ig.groupRow,
									canMove: false,
									canResize: false,
									canChangeGroup: false,
									type: 'ft',
									...getVesselConstraintsItemTimes('ft', start),
									season: prp.season,
								});
								items.push({
									id: `${ig.id}${prp.id}-2`,
									group: ig.id,
									start_time: start.valueOf(),
									end_time: end.valueOf(),
									ratePlan: prp.ratePlan,
									value: prp[rowToKey[ig.groupRow]]
										? prp[rowToKey[ig.groupRow]] % 12
										: '',
									updateItem,
									row: ig.groupRow,
									canMove: false,
									canResize: false,
									canChangeGroup: false,
									type: 'in',
									...getVesselConstraintsItemTimes('in', start),
									season: prp.season,
								});
							} else {
								items.push({
									id: `${ig.id}${prp.id}`,
									group: ig.id,
									start_time: start.valueOf(),
									end_time: end.valueOf(),
									ratePlan: prp.ratePlan,
									value: prp[rowToKey[ig.groupRow]] || '',
									updateItem,
									row: ig.groupRow,
									canMove: false,
									canResize: false,
									canChangeGroup: false,
									type: ig.groupRow === 13 || ig.groupRow === 14 ? 'lbs' : 'sqft',
									...getVesselConstraintsItemTimes(
										ig.groupRow === 13 || ig.groupRow === 14 ? 'lbs' : 'sqft',
										start
									),
									season: prp.season,
								});
							}
						});
				});

			return items;
		},
		[getVesselConstraintsItemTimes, parseFt]
	);

	const generateGroups = useCallback(
		ratePlan => {
			const groups = [
				{
					id: ratePlan.id,
					groupRow: 1,
					title: ratePlan.internalName,
					isRatePlanGroup: true,
					groupReference,
					onClick: () => dispatch({ type: 'removeRatePlan', payload: ratePlan }),
					ratePlan: ratePlan['@id'],
				},
				{
					id: ratePlan.id + 0.1,
					groupRow: 2,
					groupReference,
					groupRatePlanReference,
					title: pricePer(ratePlan),
					ratePlan: ratePlan['@id'],
				},
			];

			if (ratePlan.minDuration)
				groups.push({
					id: ratePlan.id + 0.2,
					groupRow: 3,
					groupReference,
					groupRatePlanReference,
					title: 'Minimum Length of Stay',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxDuration)
				groups.push({
					id: ratePlan.id + 0.3,
					groupRow: 4,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Length of Stay',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minBookInAdvance)
				groups.push({
					id: ratePlan.id + 0.4,
					groupRow: 5,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum Book in Advance',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxBookInAdvance)
				groups.push({
					id: ratePlan.id + 0.5,
					groupRow: 6,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Book in Advance',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minLoa)
				groups.push({
					id: ratePlan.id + 0.6,
					groupRow: 7,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum LOA',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxLoa)
				groups.push({
					id: ratePlan.id + 0.7,
					groupRow: 8,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum LOA',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minBeam)
				groups.push({
					id: ratePlan.id + 0.8,
					groupRow: 9,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum Beam',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxBeam)
				groups.push({
					id: ratePlan.id + 0.9,
					groupRow: 10,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Beam',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minHeight)
				groups.push({
					id: ratePlan.id + 0.01,
					groupRow: 11,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum Height',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxHeight)
				groups.push({
					id: ratePlan.id + 0.011,
					groupRow: 12,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Height',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minWeight)
				groups.push({
					id: ratePlan.id + 0.012,
					groupRow: 13,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum Weight',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxWeight)
				groups.push({
					id: ratePlan.id + 0.013,
					groupRow: 14,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Weight',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minDraft)
				groups.push({
					id: ratePlan.id + 0.014,
					groupRow: 15,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum Draft',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxDraft)
				groups.push({
					id: ratePlan.id + 0.015,
					groupRow: 16,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Draft',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minSqft)
				groups.push({
					id: ratePlan.id + 0.016,
					groupRow: 17,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum Sqft',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxSqft)
				groups.push({
					id: ratePlan.id + 0.017,
					groupRow: 18,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Sqft',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minPricingLoa)
				groups.push({
					id: ratePlan.id + 0.018,
					groupRow: 19,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum pricing LOA',
					ratePlan: ratePlan['@id'],
				});

			groups.unshift({
				id: 0,
				groupRow: 0,
				groupRatePlanReference,
				groupReference,
				title: '',
				ratePlan: ratePlan['@id'],
			});

			return groups;
		},
		[pricePer]
	);

	const reducer = (state, action) => {
		const { type, payload } = { ...action };

		if (type === 'set') {
			state = update(state, {
				$merge: {
					productRatePlanSeasonals: [],
					ratePlans: [],
					itemsGroups: [],
					items: [],
					seasons: [],
					reRenderGroups: false,
				},
			});

			payload.productRatePlanSeasonals.forEach(prp => {
				const ratePlanObject = ratePlans.find(rp => rp['@id'] === prp.ratePlan);

				if (!state.seasons.some(s => s.id === prp.season.id))
					state = update(state, {
						seasons: { $push: [prp.season] },
					});

				if (!state.ratePlans.some(_rp => _rp.id === ratePlanObject.id)) {
					state = update(state, {
						ratePlans: {
							$push: [ratePlanObject],
						},
						itemsGroups: {
							$push: generateGroups(ratePlanObject),
						},
					});
				}
			});

			state = update(state, {
				itemsGroups: {
					$set: state.itemsGroups.filter((ig, index) => {
						return ig.id !== 0 || (ig.id === 0 && index === 0);
					}),
				},
			});

			state = update(state, {
				$merge: {
					productRatePlanSeasonals: payload.productRatePlanSeasonals,
				},
			});

			state.seasons.forEach((season, index) => {
				state = update(state, {
					items: {
						$push: generateItems(
							season,
							index,
							state.productRatePlanSeasonals,
							state.itemsGroups,
							ratePlans,
							item => dispatch({ type: 'updateItem', payload: item }),
							state.itemsGroups.some(ig => ig.groupRow > 6)
						),
					},
				});
			});

			return state;
		}

		if (type === 'addSeason') {
			if (state.seasons.some(s => s.id === payload.id)) return state;

			state = update(state, {
				seasons: {
					$push: [payload],
				},
			});

			const generatedItems = [];

			const generatedProductRatePlans = [];

			state.ratePlans.forEach(ratePlan => {
				const seasonGeneratedProductRatePlan = generateProductRatePlan(ratePlan, payload);

				generatedProductRatePlans.push(seasonGeneratedProductRatePlan);

				const seasonGeneratedItems = generateItems(
					payload,
					state.seasons.length - 1,
					[seasonGeneratedProductRatePlan],
					state.itemsGroups,
					state.ratePlans,
					item => dispatch({ type: 'updateItem', payload: item })
				);

				generatedItems.push(...seasonGeneratedItems);
			});

			state = update(state, {
				productRatePlanSeasonals: {
					$push: generatedProductRatePlans,
				},
				items: {
					$push: generatedItems,
				},
			});

			product.productRatePlanSeasonals = [...state.productRatePlanSeasonals];

			onFormChange();
		}

		if (type === 'addRatePlan') {
			if (state.productRatePlanSeasonals.some(prp => prp.ratePlan === payload['@id']))
				return state;

			const groups = generateGroups(payload);

			state = update(state, {
				ratePlans: {
					$push: [payload],
				},
				itemsGroups: {
					$push: groups,
				},
				reRenderGroups: {
					$set:
						state.itemsGroups.some(ig => ig.groupRow > 6) !==
						groups.some(ig => ig.groupRow > 6),
				},
			});

			state = update(state, {
				itemsGroups: {
					$set: state.itemsGroups.filter((ig, index) => {
						return ig.id !== 0 || (ig.id === 0 && index === 0);
					}),
				},
			});

			const generatedItems = [];

			const generatedProductRatePlans = [];

			state.seasons.forEach((season, index) => {
				const seasonGeneratedProductRatePlan = generateProductRatePlan(payload, season);

				generatedProductRatePlans.push(seasonGeneratedProductRatePlan);

				const seasonGeneratedItems = generateItems(
					season,
					index,
					[seasonGeneratedProductRatePlan],
					groups,
					state.ratePlans,
					item => dispatch({ type: 'updateItem', payload: item }),
					state.itemsGroups.some(ig => ig.groupRow > 6)
				);

				generatedItems.push(...seasonGeneratedItems);
			});

			state = update(state, {
				productRatePlanSeasonals: {
					$push: generatedProductRatePlans,
				},
				items: {
					$push: generatedItems,
				},
			});

			product.productRatePlanSeasonals = [...state.productRatePlanSeasonals];

			onFormChange();
		}

		if (type === 'updateItem') {
			const itemIndex = state.items.findIndex(i => i.id === payload.id);

			const productRatePlanIndex = state.productRatePlanSeasonals.findIndex(
				prp =>
					prp.ratePlan === state.items[itemIndex].ratePlan &&
					prp.season.id === state.items[itemIndex].season.id
			);

			state = update(state, {
				items: {
					[itemIndex]: { value: { $set: payload.value } },
				},
				productRatePlanSeasonals: {
					[productRatePlanIndex]: {
						$set: {
							...state.productRatePlanSeasonals[productRatePlanIndex],
							[rowToKey[payload.row]]: parseData(
								state.productRatePlanSeasonals[productRatePlanIndex],
								state.items[itemIndex],
								payload.value
							),
						},
					},
				},
			});

			product.productRatePlanSeasonals = [...state.productRatePlanSeasonals];

			onFormChange();
		}

		if (type === 'removeRatePlan') {
			const groups = state.itemsGroups.filter(ig => ig.ratePlan !== payload['@id']);

			state = update(state, {
				items: { $set: state.items.filter(i => i.ratePlan !== payload['@id']) },
				ratePlans: { $set: state.ratePlans.filter(rp => rp.id !== payload.id) },
				itemsGroups: {
					$set: groups,
				},
				productRatePlanSeasonals: {
					$set: state.productRatePlanSeasonals.filter(
						prp => prp.ratePlan !== payload['@id']
					),
				},
				reRenderGroups: {
					$set:
						state.itemsGroups.some(ig => ig.groupRow > 6) !==
						groups.some(ig => ig.groupRow > 6),
				},
			});

			product.productRatePlanSeasonals = [...state.productRatePlanSeasonals];

			onFormChange();
		}

		return state;
	};

	const [advancePricing, dispatch] = useReducer(reducer, {
		productRatePlanSeasonals: [],
		ratePlans: [],
		itemsGroups: [],
		items: [],
		seasons: [],
		reRenderGroups: false,
	});

	useEffect(() => {
		if (
			ratePlans.length &&
			product.productRatePlanSeasonals &&
			product.productRatePlanSeasonals.length
		)
			dispatch({ type: 'set', payload: product });
	}, [product, ratePlans]);

	// package bug fix.
	useEffect(() => {
		if (advancePricing.items.length) window.dispatchEvent(new Event('resize'));

		if (advancePricing.reRenderGroups) dispatch({ type: 'set', payload: product });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [advancePricing]);

	return (
		<>
			<Portlet border>
				<Portlet.Head wrapMaxSize='lg'>
					<Portlet.HeadLabel>
						<h3 className='sdms-portlet__head-title'>
							<small>Seasonal Reservation</small>
						</h3>
						<Portlet.Separator />
						<div className='sdms-portlet__head-desc'>
							<Button
								className='sdms-margin-r-15'
								design='clean'
								icon='Angle-double-left'
								size='sm'
								text='Prev'
								onClick={() => {
									const zoom = (endTime - startTime) / 2;
									setStartTime(startTime - zoom);
									setEndTime(endTime - zoom);
								}}
								disabled={moment()
									.startOf('day')
									.isSame(startTime)}
							/>
							<Button
								className='sdms-margin-r-15'
								design='clean'
								icon='Angle-double-right'
								size='sm'
								text='Next'
								onClick={() => {
									const zoom = (endTime - startTime) / 2;
									setStartTime(startTime + zoom);
									setEndTime(endTime + zoom);
								}}
							/>
						</div>
					</Portlet.HeadLabel>
					<Portlet.HeadToolbarActions>
						<div>
							<Button
								icon='Plus'
								label='brand'
								text='Add Season'
								size='sm'
								className='sdms-margin-r-5'
								onClick={() => openModal({ open: modals.SEASON })}
							/>
							<Button
								icon='Plus'
								label='brand'
								text='Add Rate Plan'
								size='sm'
								disabled={advancePricing.seasons.length === 0}
								onClick={() => openModal({ open: modals.RATE_PLAN })}
							/>
						</div>
					</Portlet.HeadToolbarActions>
				</Portlet.Head>
				<Portlet.Body className='sdms-portlet__body--fit react-calendar-timeline--no-border react-calendar-timeline--no-weekend sdms-product-advance-pricing-timeline-container'>
					<Timeline
						groups={advancePricing.itemsGroups}
						groupRenderer={GroupRenderer}
						items={advancePricing.items}
						itemRenderer={ItemRenderer}
						visibleTimeStart={startTime}
						visibleTimeEnd={endTime}
						sidebarWidth={250}
						lineHeight={40}
						showCursorLine
						canMove={false}
						canResize={false}>
						<TimelineHeaders className='sticky'> </TimelineHeaders>
					</Timeline>
				</Portlet.Body>
			</Portlet>
			<RatePlanModal
				open={modal.open === modals.RATE_PLAN}
				ratePlans={ratePlans}
				selectedRatePlans={advancePricing.ratePlans}
				onClose={closeModal}
				productBookingPeriodId={product.bookingPeriod.id}
				onSubmit={ratePlanData => dispatch({ type: 'addRatePlan', payload: ratePlanData })}
			/>
			<SeasonModal
				open={modal.open === modals.SEASON}
				seasons={seasons}
				selectedSeasons={advancePricing.seasons}
				onClose={closeModal}
				onSubmit={ratePlanData => dispatch({ type: 'addSeason', payload: ratePlanData })}
			/>
		</>
	);
};
Seasonal.propTypes = {
	seasons: PropTypes.arrayOf(PropTypes.object),
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	product: PropTypes.object,
	onFormChange: PropTypes.func,
};
Seasonal.defaultProps = {
	seasons: [],
	ratePlans: [],
	product: {},
	onFormChange: () => {},
};

export default Seasonal;
