import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import apiCall, { modules } from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	addSuccessNotification,
	convertDateToUTC,
	dateFormatter,
	inchToFeet,
} from '../../../utils/helpers/helper';
import { bookingPeriods, reservationStatuses } from '../../../utils/constants/constants';
import forms from '../../forms';
import {
	canDeleteReservationItem,
	getCurrentUnit,
	getNextAndPrevReservationItemStatus,
	getReservationListCustomFilters,
	isEarlyCheckout,
	reservationItemStatusIcon,
} from '../../../utils/helpers/reservationHelper';

import ListContainer from '../../reusables/template/ListContainer';
import List from '../../reusables/template/List';
import Button from '../../reusables/element/Button';
import Badge from '../../reusables/element/Badge';
import ReservationStatusCell, {
	RequestCell,
	reservationColor,
} from '../../reusables/element/ReservationStatusCell';
import SVGIcon from '../../reusables/element/SVGIcon';
import Dropdown from '../../reusables/element/Dropdown';
import UserContext from '../../../app/contexts/UserContext';
import DialogBox from '../../reusables/element/DialogBox';
import useModal from '../../../utils/hooks/useModal';
import usePrint from '../../../utils/hooks/usePrint';
import NullBadge from '../../reusables/design/NullBadge';

const CustomerCell = ({ data, onClick }) => {
	return (
		<div onClick={onClick} role='presentation'>
			<span className='sdms-font-bold sdms-link sdms-link--dark'>
				{data.reservation.customer.displayName}
			</span>
			<div className='sdms-font-sm sdms-list-layout__small-text'>
				{data.vehicle
					? data.vehicle.name
					: `${inchToFeet(data.loa)} x ${inchToFeet(data.beam)} x ${inchToFeet(
							data.height
					  )}`}
			</div>
		</div>
	);
};
CustomerCell.propTypes = {
	data: PropTypes.shape({
		reservation: PropTypes.shape({
			customer: PropTypes.shape({
				displayName: PropTypes.string,
			}),
		}),
		vehicle: PropTypes.shape({
			name: PropTypes.string,
		}),

		height: PropTypes.number,
		beam: PropTypes.number,
		loa: PropTypes.number,
	}),
	onClick: PropTypes.func,
};
CustomerCell.defaultProps = {
	data: {
		reservation: {
			customer: {
				displayName: '',
			},
			vehicle: {
				name: '',
			},
		},
		height: 0,
		beam: 0,
		loa: 0,
	},
	onClick: () => {},
};

export const PeriodCell = ({ data }) => {
	const pages = usePages();

	const getIcon = periodValue => {
		switch (periodValue) {
			// Hourly
			case bookingPeriods.HOURLY:
				return {
					icon: pages.campground.products.period.hourly.icon,
					color: pages.campground.products.period.hourly.color,
				};
			// Daily
			case bookingPeriods.DAILY:
				return {
					icon: pages.campground.products.period.daily.icon,
					color: pages.campground.products.period.daily.color,
				};
			// Nightly
			case bookingPeriods.NIGHTLY:
				return {
					icon: pages.campground.products.period.nightly.icon,
					color: pages.campground.products.period.nightly.color,
				};
			case bookingPeriods.LONG_TERM:
				return {
					icon: pages.campground.products.period.longTerm.icon,
					color: pages.campground.products.period.longTerm.color,
				};
			case bookingPeriods.SEASONAL:
				return {
					icon: pages.campground.products.period.seasonal.icon,
					color: pages.campground.products.period.seasonal.color,
				};
			default:
				return 'Layers';
		}
	};
	return (
		<Badge
			design={getIcon(data.product.bookingPeriod.value).color}
			isInline
			isUnified
			size='xl'
			className='sdms-font-transform-c sdms-text-overflow'>
			<SVGIcon
				name={getIcon(data.product.bookingPeriod.value).icon}
				fill={`var(--${getIcon(data.product.bookingPeriod.value).color})`}
				className='sdms-mr-5'
			/>
			<span className='sdms-text-overflow' title={data.product.bookingPeriod.value}>
				{data.product.bookingPeriod.value}
			</span>
		</Badge>
	);
};
PeriodCell.propTypes = {
	data: PropTypes.shape({
		product: PropTypes.shape({
			bookingPeriod: PropTypes.shape({ id: PropTypes.number, value: PropTypes.string }),
		}),
	}),
};
PeriodCell.defaultProps = {
	data: {
		product: {
			bookingPeriod: {
				id: null,
				value: null,
			},
		},
	},
};

const ArrivalCell = ({ data }) => {
	return <span>{dateFormatter(convertDateToUTC(new Date(data.fromDate)))}</span>;
};
ArrivalCell.propTypes = {
	data: PropTypes.shape({
		fromDate: PropTypes.string,
	}),
};
ArrivalCell.defaultProps = {
	data: {
		fromDate: '',
	},
};

const DepartureCell = ({ data }) => {
	if (
		data.product.bookingPeriod.value === bookingPeriods.LONG_TERM &&
		data.status.value !== reservationStatuses.CHECKED_OUT &&
		!data.hasCustomToDate
	)
		return null;
	return <span>{dateFormatter(convertDateToUTC(new Date(data.toDate)))}</span>;
};
DepartureCell.propTypes = {
	data: PropTypes.shape({
		toDate: PropTypes.string,
		product: PropTypes.object,
		status: PropTypes.object,
		hasCustomToDate: PropTypes.bool,
	}),
};
DepartureCell.defaultProps = {
	data: {
		toDate: '',
	},
};

const UnitCell = ({ data }) => {
	const unit = getCurrentUnit(data);
	return unit ? unit.name : <NullBadge />;
};

UnitCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

UnitCell.defaultProps = {
	data: null,
};

const CustomStatusDropdownItems = ({ data, updateRef, onClick }) => {
	const [prevStatus, setPrevStatus] = useState(null);
	const [nextStatus, setNextStatus] = useState(null);

	const { status, customReservationStatus, product, reservation, id } = data;

	const onItemUpdateCustomStatus = statusId => {
		apiCall(
			'POST',
			'advancedReservationUpdateCustomStatus',
			res => {
				addSuccessNotification('Booking successfully updated');
				updateRef.current(res.reservationItems);
			},
			e => {
				addErrorNotification(e.toString());
			},
			'',
			{
				reservationItems: [id],
				reservation,
				statusId,
				sendStatusEmail: null,
			}
		);
	};

	useEffect(() => {
		const statuses = getNextAndPrevReservationItemStatus(
			status,
			customReservationStatus,
			product.productCustomReservationStatuses
		);

		setPrevStatus(statuses.prevStatus);
		setNextStatus(statuses.nextStatus);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<>
			{prevStatus && prevStatus.name && (
				<Dropdown.Item
					itemsColor={reservationColor(prevStatus.status.value)}
					icon={reservationItemStatusIcon(prevStatus.status.value)}
					onClick={() => {
						onClick();
						onItemUpdateCustomStatus(prevStatus.id);
					}}>
					{prevStatus.name}
				</Dropdown.Item>
			)}
			{nextStatus && nextStatus.name && (
				<Dropdown.Item
					itemsColor={reservationColor(nextStatus.status.value)}
					icon={reservationItemStatusIcon(nextStatus.status.value)}
					onClick={() => {
						onClick();
						onItemUpdateCustomStatus(nextStatus.id);
					}}>
					{nextStatus.name}
				</Dropdown.Item>
			)}
		</>
	);
};
CustomStatusDropdownItems.propTypes = {
	data: PropTypes.shape({
		status: PropTypes.shape({
			value: PropTypes.string,
		}),
		customReservationStatus: PropTypes.object,
		product: PropTypes.object,
		reservation: PropTypes.object,
		id: PropTypes.number,
	}),
	onClick: PropTypes.func,
	updateRef: PropTypes.func,
};
CustomStatusDropdownItems.defaultProps = {
	data: {
		status: {
			value: '',
		},
		customReservationStatus: {},
		product: {},
		reservation: {},
		id: -1,
	},
	onClick: () => {},
	updateRef: null,
};
const ContractStatusCell = ({ data }) => {
	if (data?.latestStatusContract?.esignCompleted)
		return (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<SVGIcon name='Verified' size={32} fill='#6a89ed' />
			</div>
		);
	return null;
};

ContractStatusCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

ContractStatusCell.defaultProps = {
	data: null,
};

const CheckInCheckOutButton = ({ data, onClick, isInDropDown }) => {
	const userContext = useContext(UserContext);

	return (
		(data.status.value === reservationStatuses.CHECKED_IN ||
			data.status.value === reservationStatuses.CONFIRMED) &&
		(isInDropDown ? (
			<Dropdown.Item
				key='checkInCheckOut'
				itemsColor={
					data.status.value === reservationStatuses.CHECKED_IN ? 'danger' : 'success'
				}
				icon={data.status.value === reservationStatuses.CHECKED_IN ? 'Sign-out' : 'Sign-in'}
				noPermission={
					!userContext.hasPermission(
						data.status.value === reservationStatuses.CHECKED_IN
							? 'campground_check_out_reservations'
							: 'campground_check_in_reservations'
					)
				}
				onClick={onClick}>
				{data.status.value === reservationStatuses.CHECKED_IN ? 'Check Out' : 'Check In'}
			</Dropdown.Item>
		) : (
			<Button
				key='checkInCheckOut'
				className='sdms-mr-15'
				label={data.status.value === reservationStatuses.CHECKED_IN ? 'danger' : 'success'}
				text={
					data.status.value === reservationStatuses.CHECKED_IN ? 'Check Out' : 'Check In'
				}
				icon={data.status.value === reservationStatuses.CHECKED_IN ? 'Sign-out' : 'Sign-in'}
				size='sm'
				elevate
				onClick={onClick}
				noPermission={
					!userContext.hasPermission(
						data.status.value === reservationStatuses.CHECKED_IN
							? 'campground_check_out_reservations'
							: 'campground_check_in_reservations'
					)
				}
			/>
		))
	);
};
CheckInCheckOutButton.propTypes = {
	data: PropTypes.shape({
		status: PropTypes.shape({
			value: PropTypes.string,
		}),
	}),
	onClick: PropTypes.func,
	isInDropDown: PropTypes.bool,
};
CheckInCheckOutButton.defaultProps = {
	data: {
		status: {
			value: '',
		},
	},
	onClick: () => {},
	isInDropDown: false,
};

const modals = {
	DELETE: 'delete',
};

const ReservationList = ({ history, type, bookingTypeId, reservationDate }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const historyLocation = useLocation();

	const { date, reservationItemIds } = historyLocation.state
		? historyLocation.state
		: { unit: 0, date: '', reservationItemIds: [] };

	const selectedReservations = useRef([]);

	const updateDataFunc = useRef();

	const error = useRef('');

	const [isLoading, setIsLoading] = useState(false);

	const [modal, openModal, closeModal] = useModal();

	const [onPrint, PRINT_COMPONENT] = usePrint();

	const staticFilters = useMemo(() => {
		const _staticFilters = {};

		if (bookingTypeId) _staticFilters['product.bookingType.id'] = bookingTypeId;

		if (reservationItemIds.length) _staticFilters['id[]'] = reservationItemIds;

		if (date) {
			_staticFilters['fromDate[before]'] = date;
			_staticFilters['toDate[after]'] = date;
		}

		if (type === 'checkIn') {
			_staticFilters['fromDate[after]'] = moment(reservationDate).format('YYYY-M-D');
			_staticFilters['fromDate[strictly_before]'] = moment(reservationDate)
				.add(1, 'days')
				.format('YYYY-M-D');
			_staticFilters['status.value[]'] = [
				reservationStatuses.RESERVED_ONLINE,
				reservationStatuses.RESERVED,
				reservationStatuses.CONFIRMED,
				reservationStatuses.CHECKED_IN,
				reservationStatuses.CHECKED_OUT,
			];
		}

		if (type === 'checkOut') {
			_staticFilters['toDate[after]'] = moment(reservationDate).format('YYYY-M-D');
			_staticFilters['toDate[strictly_before]'] = moment(reservationDate)
				.add(1, 'days')
				.format('YYYY-M-D');
			_staticFilters['status.value[]'] = [
				reservationStatuses.RESERVED_ONLINE,
				reservationStatuses.RESERVED,
				reservationStatuses.CONFIRMED,
				reservationStatuses.CHECKED_IN,
				reservationStatuses.CHECKED_OUT,
			];
		}

		window.history.replaceState(null, '');

		return _staticFilters;
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [type, bookingTypeId, reservationDate]);

	const columns = useMemo(() => {
		if (type === 'all')
			return [
				<List.Col
					key={`reservation-${type}`}
					label='Reservation'
					cellData='reservationId'
					cellDataObject='reservation'
					isLinkColumn
					sortable='reservation.id'
				/>,
				<List.Col
					key={`booking-${type}`}
					label='Booking'
					cellData='reservationItemId'
					isLinkColumn
					sortable='id'
				/>,
				<List.Col
					key={`customer-${type}`}
					label='Customer'
					cellComponent={<CustomerCell />}
					sortable='reservation.customer.displayName'
					isLinkColumn
				/>,
				<List.Col
					key={`period-${type}`}
					label='Period'
					cellComponent={<PeriodCell />}
					sortable='product.bookingPeriod.value'
				/>,
				<List.Col
					key={`product-${type}`}
					label='Product'
					cellData='name'
					cellDataObject='product'
					sortable='product.name'
				/>,
				<List.Col key={`space-${type}`} label='Space' cellComponent={<UnitCell />} />,
				<List.Col
					key={`arrival-${type}`}
					label='Arrival'
					cellComponent={<ArrivalCell />}
					sortable='fromDate'
				/>,
				<List.Col
					key={`departure-${type}`}
					label='Departure'
					cellComponent={<DepartureCell />}
					sortable='toDate'
				/>,
				<List.Col
					key={`request-${type}`}
					label='Request'
					cellComponent={<RequestCell />}
				/>,
				<List.Col
					key={`status-${type}`}
					label='Status'
					cellComponent={<ReservationStatusCell />}
					sortable='status.value'
				/>,
				<List.Col key={`action-buttons-${type}`} width={150} align='right' onlyHover>
					<Button
						key='edit'
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<CustomStatusDropdownItems
							key='customStatus'
							dataType='data'
							updateRef={updateDataFunc}
						/>
						<Dropdown.Item key='printContract' icon='Printer' text='Print Contract'>
							Print Contract(s)
						</Dropdown.Item>
						<Dropdown.Item
							key='delete'
							itemsColor='danger'
							icon='Trash'
							text='Delete'
							noPermission={
								!userContext.hasPermission('delete_campground_reservations')
							}>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>,
			];

		return [
			<List.Col
				key={`booking-${type}`}
				label='Booking'
				cellData='reservationItemId'
				isLinkColumn={userContext.hasPermission('create_campground_advanced_reservations')}
				sortable='reservationItemId'
			/>,
			<List.Col
				key={`customer-${type}`}
				label='Customer'
				cellComponent={<CustomerCell />}
				isLinkColumn={userContext.hasPermission('create_campground_advanced_reservations')}
				sortable='reservation.customer.displayName'
			/>,
			<List.Col
				key={`product-${type}`}
				label='Product'
				cellData='name'
				cellDataObject='product'
				sortable='product.name'
			/>,
			<List.Col
				key={`space-${type}`}
				label='Space'
				cellComponent={<UnitCell />}
				sortable='unit.name'
			/>,
			<List.Col
				key={`status-${type}`}
				label='Status'
				cellComponent={<ReservationStatusCell />}
				sortable='status.value'
			/>,
			<List.Col width={50} cellComponent={<RequestCell />} />,
			<List.Col width={50} cellComponent={<ContractStatusCell />} />,
			<List.Col width={60} align='right' onlyHover>
				<Dropdown
					icon='Other#1'
					color='clean'
					inline
					aligned='right'
					circle
					outline={false}>
					<Dropdown.Header>Other Actions</Dropdown.Header>
					<CheckInCheckOutButton key='checkInCheckOut' dataType='data' isInDropDown />
					<CustomStatusDropdownItems
						key='customStatus'
						dataType='data'
						updateRef={updateDataFunc}
					/>
					<Dropdown.Item
						key='edit'
						icon='Edit'
						text='Edit'
						noPermission={
							!userContext.hasPermission('create_campground_advanced_reservations')
						}>
						Edit
					</Dropdown.Item>
					<Dropdown.Item key='printContract' icon='Printer' text='Print Contract'>
						Print Contract(s)
					</Dropdown.Item>
				</Dropdown>
			</List.Col>,
		];
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [type]);

	const title = useMemo(() => {
		if (type === 'all') return pages.campground.reservations.text;

		if (type === 'checkIn')
			return reservationDate && !reservationDate.isSame(moment().startOf('day'))
				? `Arriving ${dateFormatter(reservationDate, false)}`
				: 'Arriving Today';

		return reservationDate && !reservationDate.isSame(moment().startOf('day'))
			? `Departing ${dateFormatter(reservationDate, false)}`
			: 'Departing Today';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, reservationDate]);

	const customFilters = useMemo(
		() =>
			getReservationListCustomFilters(
				type,
				userContext.data.selectedOutlet,
				modules.CAMPGROUND
			),
		[type, userContext]
	);

	const onCheckIn = () => {
		if (isLoading || selectedReservations.current.length === 0) return;
		setIsLoading(true);

		apiCall(
			'POST',
			'reservationCheckIn',
			res => {
				setIsLoading(false);
				if (res.errors && res.errors.length) {
					error.current = res.errors.join('\n');
				} else {
					selectedReservations.current.forEach(r => {
						addSuccessNotification(` Reservation ${r.id} successfully checked in.`);
					});

					updateDataFunc.current(res.reservationItems);
				}
			},
			e => {
				addErrorNotification(
					e.toString().includes('override')
						? 'Some booking have been changed since you opened the list. Please reload page.'
						: e.toString()
				);
				setIsLoading(false);
			},
			'',
			{
				reservationItems: selectedReservations.current
					.filter(r => r.status.value === reservationStatuses.CONFIRMED)
					.map(r => r.id),
				outletId: userContext.data.selectedOutlet.id,
				reservations: selectedReservations.current.map(i => i.reservation),
			}
		);
	};

	const onCheckOut = () => {
		if (isLoading || selectedReservations.current.length === 0) return;
		addSuccessNotification('Checking Out...');

		apiCall(
			'POST',
			'reservationCheckOut',
			res => {
				setIsLoading(false);

				if (res.errors && res.errors.length) {
					error.current = res.errors.join('\n');
				} else {
					selectedReservations.current.forEach(r => {
						addSuccessNotification(` Reservation ${r.id} successfully checked out.`);
					});

					updateDataFunc.current(res.reservationItems);
				}
			},
			e => {
				addErrorNotification(
					e.toString().includes('override')
						? 'Some booking have been changed since you opened the list. Please reload page.'
						: e.toString()
				);
				setIsLoading(false);
			},
			'',
			{
				reservationItems: selectedReservations.current
					.filter(r => r.status.value === reservationStatuses.CHECKED_IN)
					.map(r => r.id),
				outletId: userContext.data.selectedOutlet.id,
				reservations: selectedReservations.current.map(i => i.reservation),
			}
		);
	};

	const onDelete = () => {
		if (isLoading || selectedReservations.current.length === 0) return;

		addSuccessNotification('Deleting...');

		apiCall(
			'POST',
			'advancedReservationDeleteItem',
			res => {
				setIsLoading(false);

				res.errors.forEach(e => addErrorNotification(e));

				res.deletedReservationItemIds.forEach(id =>
					addSuccessNotification(` Reservation ${id} successfully deleted.`)
				);

				updateDataFunc.current([], res.deletedReservationItemIds);
			},
			e => {
				addErrorNotification(
					e.toString().includes('override')
						? 'Some booking have been changed since you opened the list. Please reload page.'
						: e.toString()
				);
				setIsLoading(false);
			},
			'',
			{
				reservationItemIds: selectedReservations.current
					.filter(r => canDeleteReservationItem(r))
					.map(r => r.id),
				reservations: selectedReservations.current.map(i => i.reservation),
			}
		);
	};

	const onPrintContract = reservationItems => {
		apiCall(
			'POST',
			'getPrintContract',
			res => {
				onPrint(res.contracts.join('<p style="page-break-before: always">'));
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ reservationItemIds: reservationItems.map(i => i.id) }
		);
	};

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
			{ title: pages.campground.reservations.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.campground.reservations.text);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ListContainer
				route='reservationItemCampgrounds'
				title={title}
				fluid='fluid'
				history={history}
				module={modules.CAMPGROUND}
				forms={type === 'all' ? forms.campground.reservations : []}
				staticFilters={staticFilters}
				customMultiActions={{
					printContract: {
						func: onPrintContract,
						component: (
							<Button key='printContract' label='primary' text='Print Contract(s)' />
						),
					},
					delete: {
						func: (reservationItems, updateData) => {
							updateDataFunc.current = updateData;
							selectedReservations.current = reservationItems.filter(r =>
								canDeleteReservationItem(r)
							);
							openModal({ open: modals.DELETE });
						},
						component: (
							<Button
								key='delete'
								label='danger'
								text='Delete'
								noPermission={
									!userContext.hasPermission('delete_campground_reservations')
								}
							/>
						),
					},
				}}
				checkEditable={reservationItems => {
					const actions = [];

					reservationItems.forEach(reservationItem => {
						if (
							reservationItem.productContracts.length &&
							!actions.includes('printContract')
						)
							actions.push('printContract');

						if (
							canDeleteReservationItem(reservationItem) &&
							!actions.includes('delete')
						)
							actions.push('delete');
					});

					return actions;
				}}
				customActions={{
					edit: (reservationItem, label) => {
						if (reservationItem.product.bookingPeriod.value === bookingPeriods.SEASONAL)
							history.push({
								pathname: `${pages.campground.reservations.seasonal.path}/${reservationItem.reservation.id}`,
								state: {
									itemId: reservationItem.id,
									from: type === 'all' ? '' : 'dashboard',
								},
							});
						else if (
							reservationItem.product.bookingPeriod.value === bookingPeriods.LONG_TERM
						)
							history.push({
								pathname: `${pages.campground.reservations.longTerm.path}/${reservationItem.reservation.id}`,
								state: {
									itemId: reservationItem.id,
									from: type === 'all' ? '' : 'dashboard',
								},
							});
						else
							history.push({
								pathname: `${pages.campground.reservations.transient.path}/${reservationItem.reservation.id}`,
								state:
									reservationItem.reservation.itemCount === 1 ||
									label === 'Booking'
										? {
												itemId: reservationItem.id,
												from: type === 'all' ? '' : 'dashboard',
										  }
										: null,
							});
					},
					printContract: reservationItem => {
						onPrintContract([reservationItem]);
					},
					checkInCheckOut: (reservationItem, updateData) => {
						updateDataFunc.current = updateData;

						selectedReservations.current = [reservationItem];
						if (reservationItem.status.value === reservationStatuses.CONFIRMED)
							onCheckIn();
						else if (reservationItem.status.value === reservationStatuses.CHECKED_IN)
							onCheckOut();
					},
					delete: (reservationItem, updateData) => {
						updateDataFunc.current = updateData;
						selectedReservations.current = [reservationItem];
						openModal({ open: modals.DELETE });
					},
					customStatus: (reservationItem, updateData) => {
						updateDataFunc.current = updateData;
					},
				}}
				isTabList={type !== 'all'}
				customFilters={customFilters}
				customFiltersInQuickPanel={false}>
				<List
					withCheckBox
					withOutPortlet={type === 'checkOut' || type === 'checkIn'}
					fluid='fluid'
					listName={`${type}-reservationItemCampgrounds`}
					checkEditable={reservationItem => {
						const actions = ['edit', 'availability'];

						if (
							reservationItem.status.value !== reservationStatuses.CANCELLED &&
							reservationItem.product.bookingPeriod.value !==
								bookingPeriods.LONG_TERM &&
							reservationItem.product.bookingPeriod.value !==
								bookingPeriods.SEASONAL &&
							!(
								reservationItem.status.value === reservationStatuses.CHECKED_IN &&
								isEarlyCheckout(reservationItem)
							)
						) {
							actions.push('checkInCheckOut');
							actions.push('customStatus');
						}

						if (reservationItem.productContracts.length) actions.push('printContract');

						if (canDeleteReservationItem(reservationItem)) actions.push('delete');

						return actions;
					}}>
					{columns}
				</List>
			</ListContainer>
			{PRINT_COMPONENT}
			<DialogBox
				open={modal.open === modals.DELETE}
				title=''
				content='Booking will be deleted. Are you sure?'
				type='question'>
				<Button
					className='sdms-font-transform-c'
					text='Yes, Delete'
					label='danger'
					icon='Trash'
					onClick={() => {
						onDelete();
						closeModal();
					}}
				/>
				<Button
					className='sdms-font-transform-c'
					text='No'
					design='clean'
					icon='Edit'
					onClick={closeModal}
				/>
			</DialogBox>
		</>
	);
};
ReservationList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	type: PropTypes.oneOf(['all', 'checkIn', 'checkOut']),
	// eslint-disable-next-line react/forbid-prop-types
	reservationDate: PropTypes.object,
	bookingTypeId: PropTypes.number,
};
ReservationList.defaultProps = {
	type: 'all',
	reservationDate: null,
	bookingTypeId: 0,
};

export default ReservationList;
