import React, { useRef } from 'react';
import classNames from 'classnames';

import { nonTransientAdvancedFormPages } from '../../../../pages';
import { mediaBreakpoint, reservationStatuses } from '../../../../../utils/constants/constants';
import { priceFormatter, useWindowSize } from '../../../../../utils/helpers/helper';

import InnerPage from '../../../template/InnerPage';
import Portlet from '../../../layout/Portlet';
import SVGIcon from '../../../element/SVGIcon';
import Section from '../../../layout/Section';
import Button from '../../../element/Button';
import FormGroup from '../../../layout/FormGroup';
import FormField from '../../../template/FormField';
import Input, { InputGroup, InputPend } from '../../../field/Input';
import Toggle from '../../../field/Toggle';
import Selects from '../../../field/Selects';
import Alert from '../../../element/Alert';
import Radio from '../../../field/Radio';
import Separator from '../../../layout/Separator';
import Textarea from '../../../field/Textarea';
import ReservationStatusCell, { reservationColor } from '../../../element/ReservationStatusCell';
import Dropdown from '../../../element/Dropdown';
import Loading from '../../../template/Loading';

const ReservationAdvancedNonTransientItemFormLoading = () => {
	const windowSize = useWindowSize();

	const refHead = useRef(null);

	const submitButtonAttr = {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	};

	const _Input = (
		<Input type='text' placeholder='Placeholder' value='' onChange={() => {}} disabled />
	);

	const _Toggle = <Toggle value={false} onChange={() => {}} disabled />;

	const _Select = (
		<Selects options={[]} placeholder='Placeholder' value={null} onChange={() => {}} disabled />
	);

	const _TextArea = <Textarea onChange={() => {}} value='' disabled />;

	return (
		<>
			<InnerPage>
				<InnerPage.NavWrapper
					offCanvasStatus
					setOffCanvasStatus={() => {}}
					offCanvasTitle='Reservation Loading'>
					{nonTransientAdvancedFormPages.map((step, index) => (
						<InnerPage.NavItem
							key={step.title}
							onClick={() => {}}
							icon={step.icon}
							title={step.title}
							description={step.description}
							isActive={index === 0}
							isDisabled={index > 0}
							isLoading
						/>
					))}
				</InnerPage.NavWrapper>
				<InnerPage.Wrapper>
					<InnerPage.Item>
						<Portlet
							stickyTop={
								windowSize.width < mediaBreakpoint.LG
									? document.getElementById('sdms_content').offsetTop
									: 0
							}
							className='flex-shrink-0'
							ref={refHead}>
							<Portlet.Head>
								<Portlet.HeadLabel>
									<Loading isLoading type='div'>
										<>
											<h3 className='sdms-portlet__head-title'>
												Booking Loading
											</h3>
											<Portlet.Separator />
											<h3 className='sdms-portlet__head-title'>
												Motel
												<small>
													Total <b>{priceFormatter(0)}</b>
												</small>
											</h3>
										</>
									</Loading>
								</Portlet.HeadLabel>
								<Portlet.HeadToolbarActions actionsClassName='d-flex align-items-center'>
									<Loading isLoading type='div'>
										<ReservationStatusCell
											data={{
												status: { value: reservationStatuses.RESERVED },
											}}
										/>
									</Loading>
									<Dropdown
										icon='Other#1'
										size='sm'
										label='info'
										inline
										aligned='right'
										arrow={false}
										outline={false}
										disabled>
										<Dropdown.Item
											iconColor={`var(--${reservationColor(
												reservationStatuses.CONFIRMED
											)})`}
											icon='Check'
											onClick={() => {}}>
											Confirm
										</Dropdown.Item>
										<Dropdown.Divider />
										<Dropdown.Item icon='Mail-box' onClick={() => {}}>
											Send Confirmation Mail
										</Dropdown.Item>
									</Dropdown>
								</Portlet.HeadToolbarActions>
							</Portlet.Head>
						</Portlet>
						<Portlet
							isCollapse
							isOpen={false}
							hasFrame
							className='flex-grow-0 sdms-min-h-fit-content'>
							<Portlet.Head>
								<Portlet.HeadLabel>
									<h3 className='sdms-portlet__head-title'>Reservation</h3>
								</Portlet.HeadLabel>
								<Portlet.HeadToolbarActions>
									<SVGIcon
										name='Angle-right'
										className={classNames(
											'sdms-transition',
											'sdms-cursor--pointer',
											{
												'sdms-rotate--90': true,
											}
										)}
										onClick={() => {}}
									/>
								</Portlet.HeadToolbarActions>
							</Portlet.Head>
							<Portlet.Body>
								<Section
									title={
										<>
											<span className='sdms-mr-15'>Vessel</span>
											<Loading isLoading type='button'>
												<Button
													label='info'
													icon='Ship'
													size='sm'
													pill
													onClick={() => {}}
													disabled>
													Add New Vessel
												</Button>
											</Loading>
										</>
									}>
									<Section.Body>
										<FormGroup isLast>
											<Loading isLoading>
												<FormField
													label='Vessel'
													name='vessel'
													id={0}
													colMd={4}>
													<Loading isLoading type='button'>
														<Button
															className='h-100 sdms-fitText'
															label='dark'
															icon='Marina'
															block
															onClick={() => {}}
															disabled>
															Select a Vessel
														</Button>
													</Loading>
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='loa'
													label='Length Overall'
													id={0}
													colMd={4}>
													{_Input}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='beam'
													label='Beam'
													id={0}
													colMd={4}>
													{_Input}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='draft'
													label='Draft'
													id={0}
													colMd={4}>
													{_Input}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='height'
													label='Height'
													id={0}
													colMd={4}>
													{_Input}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='weight'
													label='Weight'
													id={0}
													colMd={4}>
													{_Input}
												</FormField>
											</Loading>
										</FormGroup>
									</Section.Body>
								</Section>
								<Portlet border isLast>
									<Portlet.Head>
										<Portlet.HeadLabelTitle>Search</Portlet.HeadLabelTitle>
									</Portlet.Head>
									<Portlet.Body>
										<Section>
											<Section.Body>
												<FormGroup isLast>
													<Loading isLoading>
														<FormField
															name='fromDate'
															label='Start date'
															id={0}
															colMd={3}>
															{_Input}
														</FormField>
													</Loading>
													<Loading isLoading>
														<FormField
															name='fromDate'
															label='Start time'
															id={0}
															colMd={3}>
															{_Input}
														</FormField>
													</Loading>
													<Loading isLoading>
														<FormField
															name='toDate'
															label='End date'
															id={0}
															colMd={3}>
															{_Input}
														</FormField>
													</Loading>
													<Loading isLoading>
														<FormField
															name='toDate'
															label='End time'
															id={0}
															colMd={3}>
															{_Input}
														</FormField>
													</Loading>
													<Loading isLoading>
														<FormField
															name='ignoreRules'
															label='No rules'
															id={0}
															colMd={2}>
															{_Toggle}
														</FormField>
													</Loading>
													<Loading isLoading>
														<FormField
															name='ignoreCapacity'
															label='Ignore Capacity'
															id={0}
															colMd={2}>
															{_Toggle}
														</FormField>
													</Loading>
													<Loading isLoading>
														<FormField
															name='searchAvailability'
															label='Search Availability'
															id={0}
															colMd={3}>
															<Loading isLoading type='button'>
																<Button
																	label='brand'
																	text='Search Availability'
																	icon='Search'
																	size='sm'
																	block
																	disabled
																	onClick={() => {}}
																	isSubmitting
																/>
															</Loading>
														</FormField>
													</Loading>
													<Loading isLoading>
														<FormField
															name='unit'
															label='Unit'
															id={0}
															colMd={3}>
															{_Select}
														</FormField>
													</Loading>
												</FormGroup>
											</Section.Body>
										</Section>
									</Portlet.Body>
								</Portlet>
							</Portlet.Body>
						</Portlet>
						<Portlet hasFrame className='flex-grow-0 sdms-min-h-fit-content'>
							<Portlet.Body>
								<div className='row'>
									<div className='col-md-6'>
										<Section title='Plans'>
											<Section.Body>
												<FormGroup>
													<Loading isLoading>
														<FormField
															name='ratePlan'
															label='Rate Plan'
															id={0}
															colMd={12}
															description='Search Availability to update'>
															{_Select}
														</FormField>
													</Loading>
													<Loading isLoading>
														<FormField
															name='policy'
															label='Cancellation Policy'
															id={0}
															colMd={12}>
															{_Select}
														</FormField>
													</Loading>
													<Loading isLoading>
														<FormField
															name='depositAmount'
															label='Deposit Required'
															id={0}
															colMd={12}>
															{_Input}
														</FormField>
													</Loading>
													<Loading isLoading>
														<FormField
															name='productContracts'
															label='Product Contracts'
															id={0}
															colMd={12}>
															{_Select}
														</FormField>
													</Loading>
												</FormGroup>
											</Section.Body>
										</Section>
									</div>
									<div className='col-md-6'>
										<Alert
											outline
											title='Pricing'
											design='dark'
											marginLess={false}>
											<div className='row align-items-baseline sdms-fitText--md sdms-mb-5'>
												<div className='col-12 text-right'>
													<Radio.Container isInline>
														<Radio
															checked
															id='fixedPriceNo'
															name='fixedPriceInput'
															content='Product Pricing'
															className='sdms-radio--primary'
															onChange={() => {}}
														/>
														<Radio
															checked={false}
															id='fixedPriceYes'
															name='fixedPriceInput'
															content='Fixed Price'
															className='sdms-radio--primary'
															onChange={() => {}}
														/>
													</Radio.Container>
												</div>
												<div className='col-4'>Rate</div>
												<Loading isLoading type='div'>
													<div className='col-8 text-right'>
														<InputGroup>
															<InputPend
																icon='Dollar'
																iconColor='var(--dark)'
															/>
															{_Input}
															<div className='input-group-append'>
																{_Select}
															</div>
														</InputGroup>
													</div>
												</Loading>
											</div>
											<div className='row sdms-fitText--md sdms-mb-10'>
												<div className='col'>Extras</div>
												<Loading isLoading type='div'>
													<div className='col-auto text-right'>
														<span className='sdms-mr-15'>
															{priceFormatter(0)}
														</span>
													</div>
												</Loading>
											</div>
											<div className='row sdms-fitText--md sdms-mb-10'>
												<div className='col'>Tax</div>
												<Loading isLoading type='div'>
													<div className='col-auto text-right'>
														<span className='sdms-mr-15'>
															{priceFormatter(0)}
														</span>
													</div>
												</Loading>
											</div>
											<Separator space='sm' />
											<div className='row sdms-fitText--lg sdms-font-bolder'>
												<div className='col'>Total</div>
												<Loading isLoading type='div'>
													<div className='col-auto text-right'>
														<span className='sdms-mr-15'>
															{priceFormatter(0)}
														</span>
													</div>
												</Loading>
											</div>
											<div className='sdms-mb-20' />
											<div className='row sdms-fitText--md sdms-mb-10'>
												<div className='col'>Deposit Required</div>
												<Loading isLoading type='div'>
													<div className='col-auto text-right'>
														<span className='sdms-mr-15'>
															{priceFormatter(0)}
														</span>
													</div>
												</Loading>
											</div>
											<Separator space='sm' />
											<div className='row sdms-fitText--md sdms-mb-10'>
												<div className='col'>Amount Paid</div>
												<Loading isLoading type='div'>
													<div className='col-auto text-right'>
														<span className='sdms-mr-15'>
															{priceFormatter(0)}
														</span>
													</div>
												</Loading>
											</div>
											<div className='sdms-mb-20' />
											<div className='row sdms-fitText--lg sdms-font-bolder'>
												<div className='col'>Balance</div>
												<Loading isLoading type='div'>
													<div className='col-auto text-right'>
														<span className='sdms-mr-15'>
															{priceFormatter(0)}
														</span>
													</div>
												</Loading>
											</div>
										</Alert>
									</div>
								</div>
								<Section>
									<Section.Body>
										<FormGroup>
											<Loading isLoading>
												<FormField
													name='note'
													label='Special Requests'
													id={0}
													colMd={12}>
													{_TextArea}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='internalNote'
													label='Internal Notes'
													id={0}
													colMd={12}>
													{_TextArea}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='externalNote'
													label='External Notes'
													id={0}
													colMd={12}>
													{_TextArea}
												</FormField>
											</Loading>
										</FormGroup>
									</Section.Body>
								</Section>
								<Section title='Invoice Settings'>
									<Section.Body>
										<FormGroup>
											<Loading isLoading>
												<FormField
													name='invoiceGenerationAutomatedBatch'
													label='Invoice Generation'
													id={0}
													inFormDesign={false}
													colMd={3}>
													<Radio.Container isInline>
														<Radio
															checked
															id='invoiceGenerationAutomatedBatchYes'
															name='invoiceGenerationAutomatedBatch'
															content='Automatic'
															className='sdms-radio--primary'
															onChange={() => () => {}}
															disabled
														/>
														<Radio
															checked={false}
															id='invoiceGenerationAutomatedBatchNo'
															name='invoiceGenerationAutomatedBatch'
															content='Manual'
															className='sdms-radio--primary'
															onChange={() => {}}
															disabled
														/>
													</Radio.Container>
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='invoicingFrequency'
													label='Frequency'
													id={0}
													colMd={3}>
													{_Select}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='paymentTerm'
													label='Payment Term'
													id={0}
													colMd={3}>
													{_Select}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='autoPay'
													label='Auto Pay'
													id={0}
													colMd={3}>
													{_Toggle}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='firstInvoice'
													label='First Invoice'
													id={0}
													colMd={3}>
													{_Select}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='nextInvoice'
													label='Next Invoice'
													id={0}
													colMd={3}>
													{_Select}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='proratedFrequency'
													label='Prorated Frequency'
													id={0}
													colMd={3}>
													{_Input}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='coTermDate'
													label='Co-Term Date'
													id={0}
													colMd={3}>
													{_Input}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='prevInvoiceDate'
													label='Prev Invoice Date'
													id={0}
													colMd={3}>
													{_Input}
												</FormField>
											</Loading>
											<Loading isLoading>
												<FormField
													name='nextInvoiceDate'
													label='Next Invoice Date'
													id={0}
													colMd={3}>
													{_Input}
												</FormField>
											</Loading>
										</FormGroup>
									</Section.Body>
								</Section>
							</Portlet.Body>
						</Portlet>
						<Portlet hasFrame stickyBottom={0} className='flex-shrink-0'>
							<Portlet.Foot
								tall='sm'
								className='sdms-align-left'
								subClassName='justify-content-between'>
								<div className='col-auto'>
									<Loading isLoading type='button'>
										<Button
											className='sdms-mw-105 justify-content-center'
											label='dark'
											icon='User'
											block
											disabled
											onClick={() => {}}>
											Select Customer
										</Button>
									</Loading>
								</div>
								<div className='col-auto'>
									<Loading isLoading type='div'>
										<h5>Balance Due: {priceFormatter(0)}</h5>
									</Loading>
								</div>
								<div className='col-auto sdms-last-margin--h'>
									<Loading isLoading type='button'>
										<Button
											className='sdms-mw-105 justify-content-center'
											label={submitButtonAttr.color}
											text={submitButtonAttr.text}
											icon={submitButtonAttr.icon}
											size='sm'
											disabled
											onClick={() => {}}
											isSubmitting
										/>
									</Loading>
								</div>
							</Portlet.Foot>
						</Portlet>
					</InnerPage.Item>
				</InnerPage.Wrapper>
			</InnerPage>
		</>
	);
};

export default ReservationAdvancedNonTransientItemFormLoading;
