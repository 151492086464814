import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { InputGroup, InputPend } from './Input';
import { noPermissionStatus } from '../../../utils/helpers/helper';

const Selects = ({
	options,
	multiple,
	value,
	displayKey,
	valueKey,
	sortKey,
	onChange,
	onBlur,
	id,
	name,
	isValid,
	showValidation,
	placeholder,
	className,
	inPortlet,
	disabled,
	groupClassName,
	prepend,
	append,
	prependIcon,
	appendIcon,
	prependIconColor,
	appendIconColor,
	noPermission,
	renderInput,
	renderOption,
	optionalShow,
	disableClearable,
	autoComplete,
	mobileUI,
	onFocus,
	noSort,
}) => {
	const changeHandler = (_event, _newValue) => {
		onChange({ target: { name, value: _newValue } });
		onBlur();
	};
	const defaultRenderInput = params => (
		<TextField {...params} placeholder={placeholder} variant='outlined' />
	);
	const getSelectOptions = () => {
		const selectOptions = options.map(o => {
			return { value: o[valueKey], name: o[displayKey] };
		});
		if (placeholder) selectOptions.unshift({ value: 'placeholder', name: placeholder });
		return selectOptions;
	};
	const _Select = (
		<>
			<Autocomplete
				id={id}
				options={
					noSort
						? options
						: options.sort((a, b) => {
								if (!sortKey) sortKey = displayKey;
								return a[sortKey]?.toString().localeCompare(b[sortKey].toString());
						  })
				}
				multiple={multiple}
				disableClearable={disableClearable}
				value={value}
				getOptionLabel={option => {
					if (optionalShow) return `${optionalShow(option)}`;
					if (!option[displayKey]) return '';
					return option[displayKey].toString();
				}}
				getOptionSelected={(option, _value) =>
					option && option[valueKey] === _value[valueKey]
				}
				onChange={disabled ? null : changeHandler}
				blurOnSelect
				onClose={() => onBlur()}
				onBlur={() => onBlur()}
				className={classNames(
					'sdms-select-container',
					{
						'is-invalid': showValidation && !isValid,
					},
					{ 'sdms-portlet__select': inPortlet },
					{ 'sdms-select__multiple': multiple },
					{ 'sdms-hidden-tablet-and-mobile': mobileUI },
					noPermissionStatus(noPermission),
					className
				)}
				disabled={disabled}
				renderInput={renderInput || defaultRenderInput}
				renderOption={renderOption}
				openOnFocus
				autoComplete={autoComplete}
				onFocus={onFocus}
			/>
			{mobileUI && (
				<select
					name={name}
					value={value ? value[valueKey] : 'placeholder'}
					onChange={e => {
						changeHandler(
							e,
							e.target.value === 'placeholder'
								? null
								: options.find(op => op[valueKey].toString() === e.target.value)
						);
					}}
					className={classNames(
						'sdms-hidden-desktop',
						'form-control',
						'sdms-select-container',
						{
							'is-invalid': showValidation && !isValid,
						},
						{ 'sdms-portlet__select': inPortlet },
						{ 'sdms-select__multiple': multiple },
						noPermissionStatus(noPermission),
						className
					)}
					onFocus={onFocus}>
					{getSelectOptions().map(option => (
						<option
							key={option.value}
							value={option.value}
							disabled={option.value === 'placeholder'}
							hidden={option.value === 'placeholder'}>
							{option.name}
						</option>
					))}
				</select>
			)}
		</>
	);

	if (prepend || prependIcon || append || appendIcon) {
		return (
			<InputGroup className={groupClassName} noPermission={noPermission}>
				<InputPend text={prepend} icon={prependIcon} iconColor={prependIconColor} />
				{_Select}
				<InputPend text={append} icon={appendIcon} iconColor={appendIconColor} isAppend />
			</InputGroup>
		);
	}
	return _Select;
};
Selects.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]),
	multiple: PropTypes.bool,
	displayKey: PropTypes.string,
	sortKey: PropTypes.string,
	valueKey: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	showValidation: PropTypes.bool,
	isValid: PropTypes.bool,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	inPortlet: PropTypes.bool,
	disabled: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	components: PropTypes.object,
	noPermission: PropTypes.bool,
	disableClearable: PropTypes.bool,
	renderInput: PropTypes.func,
	renderOption: PropTypes.func,
	optionalShow: PropTypes.func,
	autoComplete: PropTypes.string,
	mobileUI: PropTypes.bool,
	onFocus: PropTypes.func,
	noSort: PropTypes.bool,
};
Selects.defaultProps = {
	id: undefined,
	name: undefined,
	onBlur: () => {},
	value: {},
	multiple: false,
	showValidation: false,
	isValid: true,
	valueKey: 'id',
	displayKey: 'name',
	sortKey: '',
	placeholder: null,
	className: null,
	inPortlet: false,
	disabled: false,
	components: null,
	noPermission: null,
	disableClearable: false,
	renderInput: null,
	renderOption: null,
	optionalShow: null,
	autoComplete: null,
	mobileUI: false,
	onFocus: () => {},
	noSort: false,
};

export default Selects;
