import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { DateRangePicker, DateRange, Calendar } from 'react-date-range';
import classNames from 'classnames';

import useTooltipPlace from '../../../utils/hooks/useTooltipPlace';
import { dateFormatter } from '../../../utils/helpers/helper';

import Portal from '../layout/Portal';
import Input from './Input';
import SVGIcon from '../element/SVGIcon';

const DatePicker = ({
	id,
	type,
	value,
	onChange,
	onBlur,
	place,
	disabled,
	minDate,
	maxDate,
	afterShow,
	afterHide,
	scrollHide,
	forceNextDay,
	className,
	status,
	showValidation,
	isValid,
	leftIcon,
	placeholder,
	formatter,
	disableClear,
}) => {
	const pickerClasses = {
		dateRangePicker: DateRangePicker,
		dateRange: DateRange,
		calendar: Calendar,
	};
	const [displayText, setDisplayText] = useState(
		type === 'calendar' ? 'Select date' : 'Select range'
	);
	const [focusedStep, setFocusedStep] = useState(0);

	const tooltipRef = useRef(null);

	const inputRef = useRef();

	const tooltipPlace = useTooltipPlace(place, inputRef);

	const hideTooltip = useCallback(() => {
		const { current } = tooltipRef;
		current.tooltipRef = null;
		ReactTooltip.hide();
	}, []);

	const [ranges, setRanges] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: 'selection',
		},
	]);

	const updateTooltip = () => {
		const tooltip = tooltipRef.current;

		if (!scrollHide && tooltip && tooltip.state && tooltip.state.show) tooltip.updatePosition();
	};

	const changeHandler = useCallback(
		val => {
			// implemented according to useField - onChange
			if (type === 'calendar') {
				onChange({ target: { value: val } });
				hideTooltip();
			}
			// implemented according to useState - e.g. setRange
			else if (val) {
				if (
					forceNextDay &&
					val.selection.startDate.getFullYear() === val.selection.endDate.getFullYear() &&
					val.selection.startDate.getMonth() === val.selection.endDate.getMonth() &&
					val.selection.startDate.getDate() === val.selection.endDate.getDate()
				) {
					const newDate = new Date(
						val.selection.startDate.getFullYear(),
						val.selection.startDate.getMonth(),
						val.selection.startDate.getDate() + 1
					);
					onChange({
						startDate: val.selection.startDate,
						endDate: newDate,
					});
				} else {
					const lastDate = new Date(val.selection.endDate.getTime());
					lastDate.setHours(23, 59, 59, 999);
					onChange({
						startDate: val.selection.startDate,
						endDate: lastDate,
					});
				}
				if (focusedStep === 1) {
					hideTooltip();
				}
			} else {
				onChange({
					startDate: null,
					endDate: null,
				});
				hideTooltip();
			}
		},
		[focusedStep, forceNextDay, hideTooltip, onChange, type]
	);

	useEffect(() => {
		if (
			type !== 'calendar' &&
			(!value ||
				!value.startDate ||
				!value.endDate ||
				value.startDate.toDateString() !== ranges[0].startDate.toDateString() ||
				value.endDate.toDateString() !== ranges[0].endDate.toDateString())
		) {
			setRanges([
				{
					startDate: value?.startDate || new Date(),
					endDate: value?.endDate || new Date(),
					key: 'selection',
				},
			]);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [value]);

	useEffect(() => {
		let text;

		const _formatter = formatter || dateFormatter;

		if (type === 'calendar') {
			if (value) text = _formatter(value.getTime(), false);
			else text = placeholder || 'Select date';
		} else if (value && value.startDate)
			text = value.endDate
				? `${_formatter(value.startDate.getTime(), false)} - ${_formatter(
						value.endDate.getTime(),
						false
				  )}`
				: `${_formatter(value.startDate.getTime(), false)} -`;
		else text = placeholder || 'Select range';
		setDisplayText(text);
	}, [value, type, placeholder, formatter]);

	useEffect(() => {
		window.addEventListener('scroll', updateTooltip);
		return () => window.removeEventListener('scroll', updateTooltip);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<div
			className={classNames(
				'sdms-input-icon',
				{ 'sdms-input-icon--left': leftIcon },
				'sdms-input-icon--right',
				'sdms-date-range-input',
				{ 'sdms-date-range-input--disable': disabled },
				className
			)}>
			{leftIcon && (
				<span className='sdms-input-icon__icon sdms-input-icon__icon--left'>
					<span>
						<SVGIcon name={leftIcon} fill='var(--primary)' />
					</span>
				</span>
			)}
			<span data-tip='' data-for={id} data-event='click'>
				<Input
					ref={inputRef}
					className='sdms-cursor--pointer'
					onChange={() => {}}
					type='text'
					placeholder={displayText}
					value={displayText !== placeholder ? displayText : ''}
					readOnly
					onBlur={() => onBlur()}
					disabled={disabled}
					status={status}
					showValidation={showValidation}
					isValid={isValid}
				/>
				{!disabled && (
					<Portal isTooltip>
						<ReactTooltip
							key={id}
							id={id}
							ref={tooltipRef}
							place={tooltipPlace}
							effect='solid'
							type='light'
							clickable
							globalEventOff={scrollHide ? '' : 'click'}
							isCapture
							afterHide={afterHide}
							afterShow={() => {
								if (scrollHide && tooltipRef)
									tooltipRef.current.mouseOnToolTip = () => false;

								afterShow();
							}}
							scrollHide={scrollHide}
							className='sdms-date-range-picker'>
							{React.createElement(
								pickerClasses[type],
								type === 'calendar'
									? {
											date: value || new Date(),
											onChange: changeHandler,
											minDate,
											maxDate,
											onClick: e => {
												e.stopPropagation();
											},
									  }
									: {
											ranges,
											onChange: changeHandler,
											minDate,
											maxDate,
											onClick: e => {
												e.stopPropagation();
											},
											onRangeFocusChange: focusedRange =>
												setFocusedStep(focusedRange[1]),
									  }
							)}
						</ReactTooltip>
					</Portal>
				)}
			</span>
			{value && !disableClear && (
				<span className='sdms-input-icon__icon sdms-input-icon__icon--right'>
					<span>
						<SVGIcon
							name='Backspace'
							className='sdms-cursor--pointer'
							onClick={() => {
								changeHandler(undefined);
							}}
						/>
					</span>
				</span>
			)}
		</div>
	);
};
DatePicker.propTypes = {
	id: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['dateRangePicker', 'dateRange', 'calendar']),
	value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	place: PropTypes.oneOf(['top', 'bottom']),
	disabled: PropTypes.bool,
	minDate: PropTypes.objectOf(PropTypes.object),
	maxDate: PropTypes.objectOf(PropTypes.object),
	afterShow: PropTypes.func,
	afterHide: PropTypes.func,
	scrollHide: PropTypes.bool,
	forceNextDay: PropTypes.bool,
	className: PropTypes.string,
	showValidation: PropTypes.bool,
	isValid: PropTypes.bool,
	status: PropTypes.string,
	leftIcon: PropTypes.string,
	placeholder: PropTypes.string,
	formatter: PropTypes.func,
	disableClear: PropTypes.bool,
};
DatePicker.defaultProps = {
	value: undefined,
	place: 'bottom',
	type: 'dateRangePicker',
	onChange: () => {},
	onBlur: () => {},
	disabled: false,
	minDate: undefined,
	maxDate: undefined,
	afterShow: () => {},
	afterHide: () => {},
	scrollHide: true,
	forceNextDay: false,
	className: null,
	showValidation: false,
	isValid: false,
	status: null,
	leftIcon: null,
	placeholder: null,
	formatter: null,
	disableClear: false,
};

export default DatePicker;
