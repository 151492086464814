import React, { useCallback, useEffect, useRef, createRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { addErrorNotification, priceFormatter } from '../../../utils/helpers/helper';
import apiCall from '../../../utils/helpers/apiCall';

import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Search from '../../reusables/element/Search';
import { Item, Back, SearchMore } from '../../reusables/element/ProductGridElements';

const Group = ({ order, text, count, media, onClick }) => (
	<Item order={order} text={text} qty={count} media={media} onClick={onClick} icon='Folder' />
);
Group.propTypes = {
	order: PropTypes.number.isRequired,
	text: PropTypes.string.isRequired,
	count: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types,react/require-default-props
	media: PropTypes.string,
	onClick: PropTypes.func.isRequired,
};

const Product = ({
	order,
	text,
	price,
	isModifier,
	alwaysModify,
	onClick,
	media,
	isDisabled,
	addByPrice,
	addByQuantity,
}) => (
	<Item
		order={order}
		text={text}
		price={price}
		isModifier={isModifier}
		alwaysModify={alwaysModify}
		onClick={onClick}
		media={media}
		isDisabled={isDisabled}
		addByPrice={addByPrice}
		addByQuantity={addByQuantity}
	/>
);
Product.propTypes = {
	order: PropTypes.number.isRequired,
	text: PropTypes.string.isRequired,
	price: PropTypes.string.isRequired,
	// eslint-disable-next-line react/require-default-props
	isModifier: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	alwaysModify: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	media: PropTypes.string,
	onClick: PropTypes.func,
	isDisabled: PropTypes.bool,
	addByPrice: PropTypes.bool,
	addByQuantity: PropTypes.bool,
};
Product.defaultProps = {
	onClick: () => {},
	isDisabled: false,
	addByPrice: false,
	addByQuantity: false,
};

const ProductGrid = ({
	data,
	hasSearchMorePermission,
	openModifier,
	openPriceModal,
	openQuantityModal,
	addOrderItem,
	isOrderLoading,
	isDisable,
	hasSideCar,
}) => {
	const [searchText, setSearchText] = useState('');

	const modifyRef = createRef();
	const [forceModifier, setForceModifier] = useState(false);
	useEffect(() => {
		if (modifyRef.current) modifyRef.current.blur();
	}, [modifyRef]);

	const doNotPrintRef = createRef();
	const [doNotPrint, setDoNotPrint] = useState(false);
	useEffect(() => {
		if (doNotPrintRef.current) doNotPrintRef.current.blur();
	}, [doNotPrintRef]);

	const rootGroup = useRef(data.gridGroups.filter(gg => gg.name === 'root')[0]);

	const [currentGroup, setCurrentGroup] = useState(rootGroup.current);

	const [items, setItems] = useState([]);

	const showSearchMore = useRef(true);

	const searchMore = useCallback(() => {
		if (searchText === '') return;

		showSearchMore.current = false;

		apiCall(
			'GET',
			'posProducts',
			res => {
				setItems(
					res.map(p => {
						return {
							product: p,
							'@id': p['@id'],
							'@type': 'SdmsGridGroupProduct',
						};
					})
				);
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			null,
			{
				product_search: searchText,
				disableSearchMore: false,
				pagination: false,
				inactive: false,
			}
		);
	}, [searchText]);

	const getGroupProductCount = group => {
		let count = group.gridGroupProducts.length;

		data.gridGroups.forEach(gg => {
			if (gg.parentGridGroup && gg.parentGridGroup.id === group.id)
				count += getGroupProductCount(gg);
		});

		return count;
	};

	// update items when group or search text change.
	useEffect(() => {
		showSearchMore.current = true;

		if (searchText === '') {
			setItems(
				[
					...data.gridGroups.filter(
						gg => gg.parentGridGroup && currentGroup.id === gg.parentGridGroup.id
					),
					...currentGroup.gridGroupProducts,
				].sort((a, b) => a.sortOrder - b.sortOrder)
			);
		} else {
			const products = [];

			data.gridGroups.forEach(gg => {
				gg.gridGroupProducts.forEach(ggp => {
					if (
						ggp.product.name.toLowerCase().search(searchText.toLowerCase()) > -1 &&
						products.findIndex(p => p.product.id === ggp.product.id) === -1
					)
						products.push(ggp);
				});
			});

			setItems(products);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentGroup, searchText]);

	return (
		<div
			className={classNames('col-xl-8', 'col-lg-7', 'col-md-12', 'col-12', {
				'sdms-h-50-tablet-and-mobile': !hasSideCar,
			})}>
			<Portlet fluid='fluid'>
				<Portlet.Head>
					<Portlet.HeadToolbarActions>
						<Button
							ref={modifyRef}
							label={!forceModifier ? 'info' : null}
							design={forceModifier ? 'info' : null}
							size='sm'
							outline={!forceModifier}
							icon='Clipboard-list'
							text='Modify'
							onClick={() => setForceModifier(!forceModifier)}
							disabled={
								!items.filter(
									i =>
										i['@type'] === 'SdmsGridGroupProduct' &&
										i.product &&
										i.product.modifierSections &&
										i.product.modifierSections.length
								).length || isDisable
							}
						/>
						<Button
							ref={doNotPrintRef}
							label={!doNotPrint ? 'info' : null}
							design={doNotPrint ? 'info' : null}
							size='sm'
							outline={!doNotPrint}
							icon='Stop'
							text='Do Not Print'
							onClick={() => setDoNotPrint(!doNotPrint)}
							disabled={
								!items.filter(
									i =>
										i['@type'] === 'SdmsGridGroupProduct' &&
										i.product &&
										i.product.prepStations &&
										i.product.prepStations.length
								).length || isDisable
							}
						/>
					</Portlet.HeadToolbarActions>
					<Portlet.HeadLabel>
						<Search
							className='sdms-mr-0'
							inPortlet
							setSearchText={setSearchText}
							searchText={searchText}
						/>
					</Portlet.HeadLabel>
				</Portlet.Head>
				<Portlet.Body>
					<div
						className={classNames('row', 'sdms-t-product-grid', {
							'sdms-t-product-grid--disabled': isOrderLoading || isDisable,
						})}>
						{(currentGroup.parentGridGroup || searchText !== '') && (
							<Back
								isAfterSearch={searchText !== ''}
								onClick={() => {
									if (searchText !== '') {
										setSearchText('');
										setCurrentGroup(rootGroup.current);
									} else {
										setCurrentGroup(currentGroup.parentGridGroup);
									}
								}}
							/>
						)}

						{items.map((_item, order) => {
							if (_item['@type'] === 'SdmsGridGroup')
								return (
									<Group
										order={1 + order}
										key={_item['@id']}
										count={getGroupProductCount(_item)}
										onClick={() => setCurrentGroup(_item)}
										text={_item.name}
										media={_item.gridGroupImage && _item.gridGroupImage.thumb}
									/>
								);

							return (
								<Product
									order={1 + order}
									key={_item['@id']}
									text={_item.product.name}
									isModifier={
										_item.product &&
										_item.product.modifierSections &&
										_item.product.modifierSections.length !== 0
									}
									alwaysModify={_item.product.alwaysModify}
									isDisabled={
										_item.product.disableInPos ||
										_item.product.inactive ||
										(forceModifier &&
											_item.product.modifierSections.length === 0) ||
										isDisable
									}
									addByPrice={_item.product.addByPrice}
									addByQuantity={_item.product.addByQuantity}
									onClick={() => {
										if (isDisable) return;

										if (
											_item.product &&
											_item.product.modifierSections &&
											_item.product.modifierSections.length &&
											(_item.product.alwaysModify || forceModifier)
										) {
											openModifier(_item.product, doNotPrint);

											setForceModifier(false);
										} else if (_item.product.addByPrice) {
											openPriceModal(_item.product);
										} else if (_item.product.addByQuantity) {
											openQuantityModal(_item.product);
										} else
											addOrderItem(
												_item.product,
												_item.product.price,
												1,
												doNotPrint
											);

										setDoNotPrint(false);
									}}
									price={priceFormatter(_item.product.price)}
									media={
										_item.product.productImage &&
										_item.product.productImage.thumb
									}
								/>
							);
						})}

						{searchText !== '' && showSearchMore.current && (
							<SearchMore
								noPermission={!hasSearchMorePermission}
								onClick={searchMore}
							/>
						)}
					</div>
				</Portlet.Body>
			</Portlet>
		</div>
	);
};
ProductGrid.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	openModifier: PropTypes.func.isRequired,
	openPriceModal: PropTypes.func.isRequired,
	openQuantityModal: PropTypes.func.isRequired,
	addOrderItem: PropTypes.func.isRequired,
	hasSearchMorePermission: PropTypes.bool,
	isOrderLoading: PropTypes.bool,
	isDisable: PropTypes.bool,
	hasSideCar: PropTypes.bool,
};
ProductGrid.defaultProps = {
	hasSearchMorePermission: false,
	isOrderLoading: false,
	isDisable: true,
	hasSideCar: false,
};

export default ProductGrid;
