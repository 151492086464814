import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import update from 'immutability-helper';

import UserContext from '../../../app/contexts/UserContext';
import useDate from '../../../utils/hooks/useDate';
import { mediaBreakpoint, paymentStatuses, paymentTypes } from '../../../utils/constants/constants';
import { checkInvoiceIsEditable, numberFormat, useWindowSize } from '../../../utils/helpers/helper';

import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import Portal from '../layout/Portal';
import Input from '../field/Input';

import QuickPanel from '../../QuickPanel';
import { ListBody, ListHead, ListTable } from '../template/List';

const PaymentModal = ({ invoices, onSubmit, onClose, isOpen, isUpdating, isPayment }) => {
	const userContext = useContext(UserContext);

	const windowSize = useWindowSize();

	const [payments, setPayments] = useState([]);

	const [dateFormatter] = useDate();

	const getPaymentMethod = invoice => {
		if (invoice.reservationItem) return paymentTypes.BOOKING;

		if (invoice.fromHouseAccount) return paymentTypes.HOUSE_ACCOUNT;

		if (
			invoice.customerSettlements.length &&
			invoice.customerSettlements[0].remittance.paymentMethod
		)
			return invoice.customerSettlements[0].remittance.paymentMethod.name;

		return 'Unknown';
	};

	const getServiceFee = invoice => {
		if (
			invoice.customerSettlements.length &&
			invoice.customerSettlements[0].remittance.paymentMethod &&
			(invoice.customerSettlements[0].remittance.paymentMethod.paymentType.value ===
				paymentTypes.CREDIT_CARD ||
				invoice.customerSettlements[0].remittance.paymentMethod.paymentType.value ===
					paymentTypes.CHECK)
		)
			return invoice.customerSettlements[0].remittance.serviceFee || '';

		return '';
	};

	useEffect(() => {
		setPayments(
			invoices.map(i => {
				return {
					id: i.id,
					amount: numberFormat(i.total - (i.tip || 0) - getServiceFee(i)),
					tip: i.tip || '',
					serviceFee: getServiceFee(i),
					method: getPaymentMethod(i),
					date: i.timeCreated,
					isEditable: checkInvoiceIsEditable(i),
					isProcessing:
						!i.fromHouseAccount &&
						i.customerSettlements[0].remittance.status.value ===
							paymentStatuses.PROCESSING,
				};
			})
		);
	}, [invoices]);

	const _icon = 'Money';
	const _title = isPayment ? 'Payments' : 'Tips';

	const _tableHead = (
		<thead>
			<tr>
				<th>Method</th>
				<th>Date</th>
				<th>Amount</th>
				<th>Tip</th>
				<th>Service Fee</th>
				<th>Total</th>
			</tr>
		</thead>
	);

	const _Inner = (
		<>
			<ListHead responsive='grid'>
				<Portlet.HeadToolbar>
					<ListTable childrenLength={5}>{_tableHead}</ListTable>
				</Portlet.HeadToolbar>
			</ListHead>
			<ListBody responsive='grid'>
				<ListTable childrenLength={5}>
					{_tableHead}
					<tbody>
						{payments.map((p, index) => (
							<tr key={p.id}>
								<td data-grid-name='col-base' data-label='Method'>
									{p.method}
									{p.isProcessing ? ' - Processing' : ''}
								</td>
								<td data-grid-name='col-0' data-label='Date'>
									{dateFormatter(p.date)}
								</td>
								<td data-grid-name='col-1' data-label='Amount'>
									<Input
										onChange={e =>
											setPayments(
												update(payments, {
													[index]: {
														amount: {
															$set: e.target.value,
														},
													},
												})
											)
										}
										type='text'
										value={p.amount.toString()}
										disabled={!p.isEditable}
										pattern={process.env.REACT_APP_TWO_DECIMAL_PRICE_PATTERN}
									/>
								</td>
								<td data-grid-name='col-2' data-label='Tip'>
									<Input
										onChange={e =>
											setPayments(
												update(payments, {
													[index]: {
														tip: {
															$set: e.target.value,
														},
													},
												})
											)
										}
										type='text'
										value={p.tip.toString()}
										disabled={!p.isEditable}
										pattern={process.env.REACT_APP_TWO_DECIMAL_PRICE_PATTERN}
									/>
								</td>
								<td data-grid-name='col-2' data-label='Service Fee'>
									<Input
										onChange={e =>
											setPayments(
												update(payments, {
													[index]: {
														serviceFee: {
															$set: e.target.value,
														},
													},
												})
											)
										}
										type='text'
										value={p.serviceFee.toString()}
										disabled={
											!(
												p.isEditable &&
												userContext.hasPermission('edit_cc_service_fee')
											)
										}
										pattern={process.env.REACT_APP_TWO_DECIMAL_PRICE_PATTERN}
									/>
								</td>
								<td data-grid-name='col-3' data-label='Total'>
									<Input
										onChange={() => {}}
										type='text'
										value={numberFormat(
											(p.amount ? parseFloat(p.amount) : 0) +
												(p.tip ? parseFloat(p.tip) : 0) +
												(p.serviceFee ? parseFloat(p.serviceFee) : 0)
										).toString()}
										disabled
									/>
								</td>
							</tr>
						))}
					</tbody>
				</ListTable>
			</ListBody>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
						disabled={isUpdating}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='brand'
						icon='Write'
						text={isUpdating ? 'Updating...' : 'Update'}
						size='sm'
						disabled={
							payments.filter(p => p.amount === '' || !p.amount).length > 0 ||
							isUpdating
						}
						onClick={() => {
							onSubmit(
								payments.map(p => {
									if (p.tip === '') p.tip = 0;
									p.amount = parseFloat(p.amount);
									return p;
								})
							);
						}}
					/>
				</div>
			</Portlet.Foot>
		</>
	);

	if (windowSize.width < mediaBreakpoint.MD) {
		return (
			<QuickPanel
				status={isOpen}
				setStatus={onClose}
				title={_title}
				icon={_icon}
				portletClass='sdms-list-layout'>
				{_Inner}
			</QuickPanel>
		);
	}
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '670px',
					minHeight: '480px',
					height: '50%',
				}}>
				<Portlet className='sdms-list-layout' fluid='fluid'>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon={_icon}>
							{_title}
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					{_Inner}
				</Portlet>
			</Popup>
		</Portal>
	);
};
PaymentModal.propTypes = {
	invoices: PropTypes.arrayOf(PropTypes.object).isRequired,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	isUpdating: PropTypes.bool.isRequired,
	isPayment: PropTypes.bool.isRequired,
};

export default PaymentModal;
