import React from 'react';
import PropTypes from 'prop-types';

import Portlet from '../../layout/Portlet';
import Item from './cards/Item';
import New from './cards/New';
import { reservationStatuses } from '../../../../utils/constants/constants';
import ItemLoading from './cards/ItemLoading';

const ItemList = ({
	reservation,
	taxRate,
	isLoading,
	isSubmitting,
	disabled,
	onEdit,
	onView,
	onAdd,
	onItemUpdateStatus,
	onCheckIn,
	onCheckOut,
	onCancel,
	onItemUpdateCustomStatus,
	onIncidentalChargeAdd,
	onIncidentalChargeView,
	onUnitChange,
}) => {
	return (
		<Portlet
			className='sdms-bg-transparent sdms-no-shadow sdms-advanced-reservation-item-list'
			hasFrame={false}
			fluid='fluid'>
			<Portlet.Body>
				<div className='row'>
					{isLoading ? (
						<ItemLoading />
					) : (
						reservation.items.map(item => (
							<Item
								key={item.id}
								reservationItem={item}
								reservation={reservation}
								taxRate={taxRate}
								isSubmitting={isSubmitting}
								disabled={
									disabled ||
									item.status.value === reservationStatuses.CANCELLED ||
									isSubmitting
								}
								onEdit={onEdit}
								onView={onView}
								onItemUpdateStatus={onItemUpdateStatus}
								onCheckIn={onCheckIn}
								onCheckOut={onCheckOut}
								onCancel={onCancel}
								onItemUpdateCustomStatus={onItemUpdateCustomStatus}
								onIncidentalChargeAdd={onIncidentalChargeAdd}
								onIncidentalChargeView={onIncidentalChargeView}
								onUnitChange={onUnitChange}
							/>
						))
					)}
					{!isLoading && onAdd && <New onClick={onAdd} />}
				</div>
			</Portlet.Body>
		</Portlet>
	);
};

ItemList.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	taxRate: PropTypes.object,
	isLoading: PropTypes.bool,
	isSubmitting: PropTypes.bool,
	disabled: PropTypes.bool,
	onEdit: PropTypes.func,
	onView: PropTypes.func,
	onAdd: PropTypes.func,
	onItemUpdateStatus: PropTypes.func,
	onCheckIn: PropTypes.func,
	onCheckOut: PropTypes.func,
	onCancel: PropTypes.func,
	onItemUpdateCustomStatus: PropTypes.func,
	onIncidentalChargeAdd: PropTypes.func,
	onIncidentalChargeView: PropTypes.func,
	onUnitChange: PropTypes.func,
};

ItemList.defaultProps = {
	reservation: {
		items: [],
	},
	taxRate: null,
	isLoading: false,
	isSubmitting: false,
	disabled: false,
	onEdit: () => {},
	onView: () => {},
	onAdd: () => {},
	onItemUpdateStatus: () => {},
	onCheckIn: () => {},
	onCheckOut: () => {},
	onCancel: () => {},
	onItemUpdateCustomStatus: () => {},
	onIncidentalChargeAdd: () => {},
	onIncidentalChargeView: () => {},
	onUnitChange: () => {},
};

export default ItemList;
