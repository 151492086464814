import React from 'react';
import PropTypes from 'prop-types';

import DialogBox from '../../../element/DialogBox';
import Button from '../../../element/Button';

const PaymentVoid = ({ open, isSubmitting, onVoid, onClose }) => {
	return (
		<DialogBox open={open} title='' content='Are you sure?' type='question' onClose={onClose}>
			<Button
				className='sdms-font-transform-c'
				text='No'
				label='danger'
				icon='Angle-left-circle'
				onClick={onClose}
			/>
			<Button
				className='sdms-font-transform-c'
				text={isSubmitting ? 'Voiding' : 'Yes, Void'}
				design='clean'
				icon='Edit'
				disabled={isSubmitting}
				onClick={onVoid}
				isSubmitting={isSubmitting}
			/>
		</DialogBox>
	);
};

PaymentVoid.propTypes = {
	open: PropTypes.bool,
	isSubmitting: PropTypes.bool,
	onVoid: PropTypes.func,
	onClose: PropTypes.func,
};

PaymentVoid.defaultProps = {
	open: false,
	isSubmitting: false,
	onVoid: () => {},
	onClose: () => {},
};

export default PaymentVoid;
