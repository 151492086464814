export default () => {
	// eslint-disable-next-line no-restricted-globals
	self.addEventListener('message', e => {
		fetch(`${process.env.REACT_APP_BASE_URL}/anonymous/check_register`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/ld+json;charset=UTF-8',
				Accept: 'application/ld+json',
			},
			body: JSON.stringify({
				registerId: e.data.registerId.current
					? e.data.registerId.current
					: e.data.registerId,
			}),
		})
			.then(response => {
				return response.json();
			})
			.then(res => {
				postMessage(res);
			})
			.catch(reason => {
				console.log(reason);
			});
	});
};
