import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
	email as emailValidation,
	maxLength,
	password as passwordValidation,
	required,
	uniqueFnc,
} from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import UserContext from '../../../app/contexts/UserContext';
import { getOutletsFromUserRoleOutlets, getUserRoleLevel } from '../../../utils/helpers/helper';
import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import ImageUpload from '../../reusables/field/ImageUpload';
import Input from '../../reusables/field/Input';
import MultiSelect from '../../reusables/element/MultiSelect';
import Selects from '../../reusables/field/Selects';
import Section from '../../reusables/layout/Section';
import Wizard from '../../reusables/template/Wizard';
import Loading from '../../reusables/template/Loading';
import Button from '../../reusables/element/Button';
import Badge from '../../reusables/element/Badge';
import Toggle from '../../reusables/field/Toggle';
import { modules } from '../../../utils/helpers/apiCall';
import { generateApiKey } from 'generate-api-key';

const UserForm = ({
	data,
	roles,
	isValid,
	setIsValid,
	submit,
	isSubmitted,
	isLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
	units,
	outlets,
	isSubmitting,
}) => {
	const userContext = useContext(UserContext);

	const posModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.POS)
	);

	// New Password input & Button
	const [passwordInputShow, setPasswordInputShow] = useState(data.id === 0);

	const passwordRef = useRef();

	const passwordRequiredValidation = (value, setValRes) => {
		if (passwordInputShow) return required(value, setValRes);

		setValRes({
			isValid: true,
			status: 'valid',
			message: '',
		});

		return false;
	};

	const [
		firstName,
		firstNameOnChange,
		firstNameValRes,
		firstNameShowVal,
		setFirstNameShowVal,
	] = useField(data, 'firstName', onFormChange, [required, maxLength()]);

	const [
		lastName,
		lastNameOnChange,
		lastNameValRes,
		lastNameShowVal,
		setLastNameShowVal,
	] = useField(data, 'lastName', onFormChange, [required, maxLength()]);

	const [email, emailOnChange, emailValRes, emailShowVal, setEmailShowVal] = useField(
		data,
		'email',
		onFormChange,
		[required, maxLength(), emailValidation, uniqueFnc('users', 'email', data.id)]
	);

	const [
		password,
		passwordOnChange,
		passwordValRes,
		passwordShowVal,
		setPasswordShowVal,
		validatePassword,
	] = useField(data, 'password', onFormChange, [
		passwordRequiredValidation,
		passwordValidation(userContext.data.user.company.systemSettings),
		maxLength(),
	]);

	const [pin, pinOnChange, pinValRes, pinShowVal, setPinShowVal] = useField(
		data,
		'pin',
		onFormChange,
		[maxLength(), uniqueFnc('users', 'pin', data.id, false)]
	);

	const [token, tokenOnChange, tokenValRes, tokenShowVal, setTokenShowVal] = useField(
		data,
		'token',
		onFormChange,
		[uniqueFnc('users', 'token', data.id, false)]
	);

	const [
		displayName,
		displayNameOnChange,
		displayNameValRes,
		displayNameShowVal,
		setDisplayNameShowVal,
	] = useField(data, 'displayName', onFormChange, [required, maxLength()], '', null, setTitle);

	const [profilePicture, profilePictureOnChange] = useField(
		data,
		'profilePicture',
		onFormChange,
		[],
		null
	);

	const [
		defaultOutlet,
		defaultOutletOnChange,
		defaultOutletValRes,
		defaultOutletShowVal,
		setDefaultOutletShowVal,
	] = useField(data, 'defaultOutlet', onFormChange, [required], null);

	const [isInstallerUser, isInstallerUserOnChange] = useField(
		data,
		'isInstallerUser',
		onFormChange,
		[],
		false
	);

	const [userRoleOutlets, userRoleOutletsOnChange] = useField(
		data,
		'userRoleOutlets',
		onFormChange,
		[],
		[]
	);

	const [assignedUnits, assignedUnitsOnChange] = useField(data, 'units', onFormChange, [], []);

	useEffect(() => {
		if (isSubmitted) {
			setFirstNameShowVal();
			setLastNameShowVal();
			setEmailShowVal();
			if (passwordInputShow) setPasswordShowVal();
			setDisplayNameShowVal();
			setDefaultOutletShowVal();
			setPinShowVal();
			setTokenShowVal();
		}
	}, [
		isSubmitted,
		passwordInputShow,
		setDefaultOutletShowVal,
		setDisplayNameShowVal,
		setEmailShowVal,
		setFirstNameShowVal,
		setLastNameShowVal,
		setPasswordShowVal,
		setPinShowVal,
		setTokenShowVal,
	]);

	useEffect(() => {
		if (!isSubmitting && isSubmitted) setPasswordInputShow(false);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isSubmitting]);

	useEffect(() => {
		setIsValid(
			firstNameValRes.isValid &&
				lastNameValRes.isValid &&
				emailValRes.isValid &&
				passwordValRes.isValid &&
				displayNameValRes.isValid &&
				defaultOutletValRes.isValid &&
				pinValRes.isValid &&
				tokenValRes.isValid
		);
	}, [
		firstNameValRes.isValid,
		lastNameValRes.isValid,
		emailValRes.isValid,
		passwordValRes.isValid,
		displayNameValRes.isValid,
		defaultOutletValRes.isValid,
		pinValRes.isValid,
		tokenValRes.isValid,
		setIsValid,
	]);

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.systemSettings.default.text,
				path: pages.systemSettings.dashboard.path,
			},
			{ title: pages.systemSettings.users.text, path: pages.systemSettings.users.path },
			{ title: displayName || `New ${pages.systemSettings.users.text}`, isActive: true },
		]);

		headerContext.setPageTitle(displayName || `New ${pages.systemSettings.users.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [displayName]);

	const showTablesStep = useRef(
		userContext.data.user.company.modules.findIndex(m => m.value === modules.POS_TERMINAL) > -1
	);

	useEffect(() => {
		if (passwordInputShow) {
			passwordRef.current.focus();
		}
		validatePassword(password);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [passwordInputShow]);

	const steps = [
		<Wizard.Item
			key='general'
			title='General'
			description='General Information'
			isValid={isSubmitted ? isValid : true}>
			<Section>
				<Section.Body>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='firstName'
								label='First Name'
								id={data.id}
								valRes={firstNameValRes}
								showValidation={firstNameShowVal}
								colLg={6}>
								<Input
									type='text'
									placeholder='First Name (Required)'
									value={firstName}
									onChange={firstNameOnChange}
									onBlur={setFirstNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='lastName'
								label='Last Name'
								id={data.id}
								valRes={lastNameValRes}
								showValidation={lastNameShowVal}
								colLg={6}>
								<Input
									type='text'
									placeholder='Last Name (Required)'
									value={lastName}
									onChange={lastNameOnChange}
									onBlur={setLastNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='displayName'
								label='Display Name'
								description='How user will be displayed in the system'
								id={data.id}
								valRes={displayNameValRes}
								showValidation={displayNameShowVal}
								colLg={6}>
								<Input
									type='text'
									placeholder='Display Name (Required)'
									value={displayName}
									onChange={displayNameOnChange}
									onBlur={setDisplayNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='email'
								label='Email'
								description="User's login and contact email "
								id={data.id}
								valRes={emailValRes}
								showValidation={emailShowVal}
								loadingContainer
								colLg={6}>
								<Input
									type='text'
									placeholder='Email (Required)'
									autoComplete={false}
									value={email}
									onChange={emailOnChange}
									onBlur={setEmailShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='password'
								label={
									passwordInputShow && data.id ? (
										<>
											Password
											<Badge
												className='sdms-cursor--pointer sdms-ml-10'
												design='info'
												isInline
												isElevate
												onMouseDown={() => {
													setPasswordInputShow(false);
													passwordOnChange({ target: { value: '' } });
													setPasswordShowVal(false);
													if (password === '') validatePassword('');
												}}>
												Use Current Password
											</Badge>
										</>
									) : (
										'Password'
									)
								}
								labelClassName='d-flex'
								description='To login to the system'
								id={data.id}
								valRes={passwordValRes}
								showValidation={passwordShowVal}
								colLg={6}>
								{passwordInputShow ? (
									<Input
										ref={passwordRef}
										type='password'
										placeholder='Password (Required)'
										autoComplete={false}
										value={password}
										onChange={passwordOnChange}
										onBlur={setPasswordShowVal}
										togglePassword
									/>
								) : (
									<Button
										label='primary'
										text='Reset Password'
										block
										noPermission={!userContext.hasPermission('change_password')}
										onClick={() => {
											setPasswordInputShow(true);
										}}
									/>
								)}
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='pin'
								label='Pin'
								description='To clock in/out & access terminal'
								id={data.id}
								colLg={6}
								valRes={pinValRes}
								showValidation={pinShowVal}>
								<Input
									type='password'
									placeholder='Pin'
									autoComplete={false}
									value={pin}
									onChange={pinOnChange}
									onBlur={setPinShowVal}
									togglePassword
									noPermission={!userContext.hasPermission('change_pin')}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='token'
								label={
									userContext.hasPermission('change_token') ? (
										<>
											Token
											<Badge
												className='sdms-cursor--pointer sdms-ml-10'
												design='info'
												isInline
												isElevate
												onMouseDown={() => {
													tokenOnChange({
														target: {
															value: generateApiKey({
																method: 'base32',
															}),
														},
													});
												}}>
												Generate Token
											</Badge>
										</>
									) : (
										'Token'
									)
								}
								labelClassName='d-flex'
								description='API Key Security Validation'
								id={data.id}
								valRes={tokenValRes}
								showValidation={tokenShowVal}
								colLg={6}
								noPermission={!userContext.hasPermission('change_token')}>
								<Input
									type='password'
									placeholder='API Key'
									autoComplete={false}
									value={token}
									onChange={tokenOnChange}
									onBlur={setTokenShowVal}
									togglePassword
									noPermission={!userContext.hasPermission('change_token')}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='defaultOutlet'
								label='Default Location'
								description="User's default location when they login"
								id={data.id}
								valRes={defaultOutletValRes}
								showValidation={defaultOutletShowVal}
								colLg={6}>
								<Selects
									options={
										userContext.data.user.isOwner ||
										userContext.data.user.isInstallerUser
											? outlets
											: getOutletsFromUserRoleOutlets(
													userContext.data.user.userRoleOutlets
											  )
									}
									placeholder='Select Default Location (Required)'
									value={defaultOutlet}
									onChange={defaultOutletOnChange}
									onBlur={setDefaultOutletShowVal}
									noPermission={
										!userContext.hasPermission('change_default_location')
									}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='profilePicture'
								label='Image'
								description='.png, .jpg or .jpeg files only'
								colLg={6}>
								<ImageUpload
									media={profilePicture}
									setMedia={image =>
										profilePictureOnChange({
											target: {
												name: 'productImage',
												value: image,
											},
										})
									}
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</Section.Body>
			</Section>
		</Wizard.Item>,
		<Wizard.Item
			key='roles'
			title={pages.systemSettings.roles.text}
			icon={pages.systemSettings.roles.icon}
			description='Assigned Roles'>
			<Section>
				<Section.Body>
					{userContext.hasPermission(null, true) ? (
						<>
							<FormGroup>
								<FormField
									name='isInstallerUser'
									label='Installer User'
									id={data.id}
									noPermission={!userContext.hasPermission(null, true)}
									colMd={6}>
									<Toggle
										spaceLess
										value={isInstallerUser}
										onChange={isInstallerUserOnChange}
									/>
								</FormField>
							</FormGroup>
						</>
					) : null}
					<FormGroup>
						<FormField
							name='userRoleOutlets'
							label='Select Roles to Assign:'
							id={data.id}
							col={12}>
							<MultiSelect
								data={
									userContext.data.user.isOwner ||
									userContext.data.user.isInstallerUser
										? roles
										: roles.filter(
												r =>
													r.roleLevel.level <=
													getUserRoleLevel(
														userContext.data.user,
														userContext.data.selectedOutlet
													)
										  )
								}
								titleProp='name'
								value={userRoleOutlets
									.filter(
										uro => uro.outlet.id === userContext.data.selectedOutlet.id
									)
									.map(uro => uro.role)}
								onChange={({ target }) => {
									const _userRoleOutlets = userRoleOutlets.filter(
										uro => uro.outlet.id !== userContext.data.selectedOutlet.id
									);

									target.value.forEach(role => {
										_userRoleOutlets.push({
											outlet: userContext.data.selectedOutlet,
											role,
										});
									});

									userRoleOutletsOnChange({
										target: {
											name: 'userRoleOutlets',
											value: _userRoleOutlets,
										},
									});
								}}
								noPermission={!userContext.hasPermission('assign_roles')}
							/>
						</FormField>
					</FormGroup>
				</Section.Body>
			</Section>
		</Wizard.Item>,
	];

	if (showTablesStep.current)
		steps.push(
			<Wizard.Item
				key='tables'
				title='Tables'
				icon={pages.pos.settings.tableMaps.icon}
				description='Assigned Tables'>
				<Section>
					<Section.Body>
						<FormGroup>
							<FormField
								name='units'
								label='Select Tables to Assign:'
								id={data.id}
								col={12}>
								<MultiSelect
									data={units
										.filter(
											u => u.module && u.module.id === posModule.current.id
										)
										.sort((a, b) => {
											return a.name.localeCompare(b.name);
										})}
									titleProp='name'
									value={assignedUnits.filter(
										unit =>
											unit.outlet.id === userContext.data.selectedOutlet.id
									)}
									onChange={({ target }) => {
										assignedUnitsOnChange({
											target: {
												name: 'units',
												value: [
													...assignedUnits.filter(
														unit =>
															unit.outlet.id !==
															userContext.data.selectedOutlet.id
													),
													...target.value,
												],
											},
										});
									}}
									noPermission={!userContext.hasPermission('assign_tables')}
								/>
							</FormField>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
		);

	return (
		<Wizard onSubmit={submit} submitButtonAttr={submitButtonAttr} isLoading={isLoading}>
			{steps}
		</Wizard>
	);
};
UserForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		displayName: PropTypes.string,
		email: PropTypes.string,
		password: PropTypes.string,
		pin: PropTypes.string,
		token: PropTypes.string,
		profilePicture: PropTypes.object,
		defaultOutlet: PropTypes.object,
		userRoleOutlets: PropTypes.array,
		tables: PropTypes.array,
	}),
	roles: PropTypes.arrayOf(PropTypes.object),
	isValid: PropTypes.bool,
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isSubmitting: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
	units: PropTypes.arrayOf(PropTypes.object),
	outlets: PropTypes.arrayOf(PropTypes.object),
};
UserForm.defaultProps = {
	data: {
		id: 0,
	},
	roles: [],
	isValid: false,
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isSubmitting: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
	units: [],
	outlets: [],
};

export default UserForm;
