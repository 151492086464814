import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Carousel as RNCarousel } from 'react-responsive-carousel';

import Portlet from '../../layout/Portlet';
import Carousel from './cards/Carousel';
import CarouselLoading from './cards/CarouselLoading';

import carouselNavigationImage from '../../../../assets/img/carouselNavigationImage.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { reservationStatuses } from '../../../../utils/constants/constants';

const ItemCarousel = ({
	reservation,
	initReservationItemIndex,
	taxRate,
	isLoading,
	isSubmitting,
	disabled,
	onEdit,
	onAdd,
	onBack,
	onItemUpdateStatus,
	onCheckIn,
	onCheckOut,
	onCancel,
	onItemUpdateCustomStatus,
	onIncidentalChargeAdd,
	onIncidentalChargeView,
	onUnitChange,
	onCardChange,
	onDepartureDateSet,
}) => {
	const [currentItem, setCurrentItem] = useState(initReservationItemIndex);

	useEffect(() => {
		onCardChange(currentItem);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentItem]);

	return (
		<Portlet
			className='sdms-bg-transparent sdms-no-shadow sdms-advanced-reservation-item-carousel'
			hasFrame={false}
			fluid='fluid'>
			<Portlet.Body>
				<RNCarousel
					selectedItem={currentItem}
					showIndicators={false}
					showArrows={false}
					showStatus={false}
					onChange={index => setCurrentItem(index)}>
					{isLoading ? (
						<CarouselLoading />
					) : (
						reservation.items.map(reservationItem => (
							<Carousel
								key={reservationItem.id}
								reservationItem={reservationItem}
								reservation={reservation}
								taxRate={taxRate}
								isSubmitting={isSubmitting}
								disabled={
									disabled ||
									reservationItem.status.value === reservationStatuses.CANCELLED
								}
								onEdit={onEdit}
								onBack={onBack}
								onItemUpdateStatus={onItemUpdateStatus}
								onCheckIn={onCheckIn}
								onCheckOut={onCheckOut}
								onCancel={onCancel}
								onItemUpdateCustomStatus={onItemUpdateCustomStatus}
								onIncidentalChargeAdd={onIncidentalChargeAdd}
								onIncidentalChargeView={onIncidentalChargeView}
								onUnitChange={onUnitChange}
								onDepartureDateSet={onDepartureDateSet}
							/>
						))
					)}
				</RNCarousel>
				{!isLoading && reservation.items.length > 0 && (
					<div className='row sdms-row-align-items sdms-carousel-navigation'>
						<div className='col-auto'>
							<div className='row sdms-carousel-navigation-items'>
								{reservation.items.map((reservationItem, index) => (
									<div
										key={reservationItem.id}
										role='presentation'
										onClick={() => setCurrentItem(index)}
										className={classNames('sdms-carousel-navigation-item', {
											'sdms-carousel-navigation-item-active':
												index === currentItem,
										})}>
										<img src={carouselNavigationImage} alt={index} />
									</div>
								))}
								{onAdd && (
									<div
										className='sdms-carousel-navigation-item sdms-carousel-navigation-item-plus'
										role='presentation'
										onClick={onAdd}>
										+
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</Portlet.Body>
		</Portlet>
	);
};

ItemCarousel.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	initReservationItemIndex: PropTypes.number,
	// eslint-disable-next-line react/forbid-prop-types
	taxRate: PropTypes.object,
	isLoading: PropTypes.bool,
	isSubmitting: PropTypes.bool,
	disabled: PropTypes.bool,
	onEdit: PropTypes.func,
	onAdd: PropTypes.func,
	onBack: PropTypes.func,
	onItemUpdateStatus: PropTypes.func,
	onCheckIn: PropTypes.func,
	onCheckOut: PropTypes.func,
	onCancel: PropTypes.func,
	onItemUpdateCustomStatus: PropTypes.func,
	onIncidentalChargeAdd: PropTypes.func,
	onIncidentalChargeView: PropTypes.func,
	onUnitChange: PropTypes.func,
	onCardChange: PropTypes.func,
	onDepartureDateSet: PropTypes.func,
};

ItemCarousel.defaultProps = {
	reservation: null,
	initReservationItemIndex: 0,
	taxRate: null,
	isLoading: false,
	isSubmitting: false,
	disabled: false,
	onEdit: () => {},
	onAdd: null,
	onBack: null,
	onItemUpdateStatus: () => {},
	onCheckIn: () => {},
	onCheckOut: () => {},
	onCancel: () => {},
	onItemUpdateCustomStatus: () => {},
	onIncidentalChargeAdd: () => {},
	onIncidentalChargeView: () => {},
	onUnitChange: () => {},
	onCardChange: () => {},
	onDepartureDateSet: () => {},
};

export default ItemCarousel;
