import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
	productFilterComparisons,
	productFilterTypes,
} from '../../../../../../utils/constants/constants';

import SearchBar from './SearchBar';
import SearchResult from './SearchResult';
import Portlet from '../../../../layout/Portlet';
import SearchSidebar from './SearchSidebar';

import '../../../../../../assets/onlineBooking.css';

const Search = ({
	isOnline,
	refSearchBar,
	data,
	onFormChange,
	bookingTypes,
	isLoading,
	overrideConstraints,
	onSearch,
	productCategories,
	searched,
	selectProduct,
	multipleSelected,
	setMultipleSelected,
	headerHeight,
	outlet,
	initProducts,
	setIsHourlyOnly,
	updateSearchData,
	searchData,
	resultView,
	setResultView,
	onReset,
	onSearchChange,
}) => {
	const [selectedCategory, setSelectedCategory] = useState('all');

	const [filters, setFilters] = useState({});

	const [filtersKey, setFiltersKey] = useState(Date.now());

	const isSuitable = (productMetas, filter) => {
		try {
			const {
				value,
				productFilter: { meta, type, comparison },
			} = filter;

			let filterValue = value;

			if (Array.isArray(value)) filterValue = value.map(v => v.value);
			else if (typeof value === 'object') filterValue = value?.value || '';
			else if (type.value === productFilterTypes.TOGGLE) {
				if (filterValue) filterValue = '1';
				else return true;
			}

			if (!filterValue || filterValue.length === 0) return true;

			const productMeta = productMetas.find(m => m.meta.id === meta.id);

			if (!productMeta) return false;

			if (Array.isArray(value)) return value.indexOf(productMeta.value) > -1;

			if (comparison.value === productFilterComparisons.EXACT)
				return productMeta.value === filterValue;

			if (comparison.value === productFilterComparisons.SEARCH)
				return productMeta.value.toLowerCase().search(filterValue.toLowerCase()) > -1;

			if (comparison.value === productFilterComparisons.LESS)
				return parseFloat(productMeta.value) < parseFloat(filterValue);

			if (comparison.value === productFilterComparisons.LESS_OR_EQUAL)
				return parseFloat(productMeta.value) <= parseFloat(filterValue);

			if (comparison.value === productFilterComparisons.GREATER)
				return parseFloat(productMeta.value) > parseFloat(filterValue);

			if (comparison.value === productFilterComparisons.GREATER_OR_EQUAL)
				return parseFloat(productMeta.value) >= parseFloat(filterValue);

			return true;
		} catch (error) {
			return false;
		}
	};

	const filteredProductCategories = useMemo(() => {
		const categories = {};

		Object.keys(productCategories).forEach(categoryId => {
			if (selectedCategory === 'all' || categoryId === selectedCategory) {
				const products = {};
				Object.keys(productCategories[categoryId].products).forEach(productId => {
					let suitable = true;
					Object.keys(filters).forEach(productFilterId => {
						if (
							!isSuitable(
								productCategories[categoryId].products[productId].metas,
								filters[productFilterId]
							)
						)
							suitable = false;
					});

					if (suitable)
						products[productId] = JSON.parse(
							JSON.stringify(productCategories[categoryId].products[productId])
						);
				});

				if (Object.keys(products).length)
					categories[categoryId] = { ...productCategories[categoryId], products };
			}
		});

		return categories;
	}, [productCategories, filters, selectedCategory]);

	const onFilterUpdate = (productFilter, value) => {
		const _filters = {};

		Object.keys(filters).forEach(filterId => {
			if (filterId !== productFilter.id.toString()) _filters[filterId] = filters[filterId];
			if (filterId === productFilter.id.toString() && value !== null)
				_filters[filterId] = { productFilter, value };
		});

		if (!_filters[productFilter.id] && value !== null)
			_filters[productFilter.id] = { productFilter, value };

		setFilters(_filters);
	};

	return (
		<Portlet
			className={classNames(
				'sdms-wizard sdms-bg-transparent sdms-online-booking-search sdms-analytics-search',
				{
					'is-online': isOnline,
				}
			)}
			hasFrame={false}
			fluid='fluid'>
			<Portlet.Body className='sdms-online-booking-search-container'>
				<div
					className='sdms-grid sdms-wizard-v2 '
					id='sdms_wizard_v2'
					data-sdmswizard-state='first'>
					<div className='sdms-grid__item sdms-wizard-v2__aside sdms-online-booking-search-sidebar'>
						<div className='sdms-wizard-v2__nav'>
							<div className='sdms-wizard-v2__nav-items sdms-wizard-v2__nav-items--sticky'>
								<SearchSidebar
									key={filtersKey}
									resultView={resultView}
									setResultView={setResultView}
									productCategories={productCategories}
									selectedCategory={selectedCategory}
									setSelectedCategory={setSelectedCategory}
									bookingType={searchData.bookingType}
									filters={filters}
									onFilterUpdate={onFilterUpdate}
									onFilterReset={() => {
										setFilters({});
										setSelectedCategory('all');
										setFiltersKey(Date.now());
									}}
								/>
							</div>
						</div>
					</div>
					<div
						className='sdms-grid__item sdms-grid__item--fluid sdms-wizard-v2__wrapper sdms-bg-transparent'
						style={{ overflow: 'unset' }}>
						<SearchBar
							isOnline={isOnline}
							refSearchBar={refSearchBar}
							data={data}
							onFormChange={onFormChange}
							bookingTypes={bookingTypes}
							isLoading={isLoading}
							overrideConstraints={overrideConstraints}
							onSearch={onSearch}
							setIsHourlyOnly={setIsHourlyOnly}
							updateSearchData={updateSearchData}
							searchData={searchData}
							resultView={resultView}
							setResultView={setResultView}
							initProducts={initProducts}
							onReset={onReset}
							onSearchChange={onSearchChange}
						/>
						<SearchResult
							searched={searched}
							isLoading={isLoading}
							productCategories={filteredProductCategories}
							multipleSelected={multipleSelected}
							setMultipleSelected={setMultipleSelected}
							isOnline={isOnline}
							headerHeight={headerHeight}
							resultView={resultView}
							outlet={outlet}
							selectProduct={selectProduct}
							initProducts={initProducts}
							selectedCategory={selectedCategory}
							updateSearchData={_searchData =>
								updateSearchData({ ...searchData, ..._searchData })
							}
							bookingType={searchData.bookingType}
							searchData={searchData}
						/>
					</div>
				</div>
			</Portlet.Body>
		</Portlet>
	);
};

Search.propTypes = {
	isOnline: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	refSearchBar: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	onFormChange: PropTypes.func.isRequired,
	bookingTypes: PropTypes.arrayOf(PropTypes.object),
	isLoading: PropTypes.bool.isRequired,
	overrideConstraints: PropTypes.bool.isRequired,
	onSearch: PropTypes.func.isRequired, // eslint-disable-next-line react/forbid-prop-types
	// eslint-disable-next-line react/forbid-prop-types
	productCategories: PropTypes.object.isRequired,
	searched: PropTypes.bool.isRequired,
	selectProduct: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	multipleSelected: PropTypes.object,
	setMultipleSelected: PropTypes.func.isRequired,
	headerHeight: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initProducts: PropTypes.array,
	setIsHourlyOnly: PropTypes.func.isRequired,
	updateSearchData: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	searchData: PropTypes.object,
	resultView: PropTypes.string.isRequired,
	setResultView: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	onSearchChange: PropTypes.func.isRequired,
};

Search.defaultProps = {
	refSearchBar: null,
	data: {},
	bookingTypes: [],
	initProducts: [],
	searchData: {},
	multipleSelected: null,
};

export default Search;
