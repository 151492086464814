import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVGIcon from './reusables/element/SVGIcon';
import Portal from './reusables/layout/Portal';
import Portlet from './reusables/layout/Portlet';

const Wrapper = ({ children, status, onClose, position, largeQuickPanel }) => {
	return (
		<Portal>
			<div
				className={classNames('sdms-quick-panel', {
					'sdms-quick-panel--right': position === 'right',
					'sdms-quick-panel--on': status,
					'sdms-quick-panel--large': largeQuickPanel,
				})}>
				{children}
			</div>
			{status && (
				<div className='sdms-quick-panel-overlay' onClick={onClose} aria-hidden='true' />
			)}
		</Portal>
	);
};

Wrapper.propTypes = {
	children: PropTypes.node.isRequired,
	onClose: PropTypes.func.isRequired,
	status: PropTypes.bool.isRequired,
	position: PropTypes.oneOf(['right', 'left']),
	largeQuickPanel: PropTypes.bool,
};
Wrapper.defaultProps = {
	position: 'right',
	largeQuickPanel: false,
};

const QuickPanel = ({
	title,
	icon,
	children,
	status,
	setStatus,
	position,
	portletClass,
	largeQuickPanel,
}) => {
	return (
		<Wrapper
			status={status}
			onClose={() => setStatus(false)}
			position={position}
			largeQuickPanel={largeQuickPanel}>
			<Portlet fluid='fluid' border={false} className={classNames('sdms-mb-0', portletClass)}>
				<Portlet.Head>
					<Portlet.HeadLabelTitle portletIcon={icon}>{title}</Portlet.HeadLabelTitle>
					<Portlet.HeadToolbarActions>
						<span
							className='sdms-quick-panel__close'
							onClick={() => setStatus(false)}
							aria-hidden='true'>
							<SVGIcon name='Close' size={18} />
						</span>
					</Portlet.HeadToolbarActions>
				</Portlet.Head>
				{children}
			</Portlet>
		</Wrapper>
	);
};
QuickPanel.propTypes = {
	children: PropTypes.node.isRequired,
	icon: PropTypes.string,
	setStatus: PropTypes.func.isRequired,
	status: PropTypes.bool.isRequired,
	title: PropTypes.string,
	position: PropTypes.oneOf(['right', 'left']),
	portletClass: PropTypes.string,
	largeQuickPanel: PropTypes.bool,
};
QuickPanel.defaultProps = {
	icon: null,
	title: null,
	position: 'right',
	portletClass: null,
	largeQuickPanel: false,
};

QuickPanel.Wrapper = Wrapper;

export default QuickPanel;
