import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink, Link } from 'react-router-dom';
import SVGIcon from './SVGIcon';
import { noPermissionStatus } from '../../../utils/helpers/helper';

const Dropdown = ({
	aligned,
	arrow,
	block,
	bold,
	children,
	circle,
	className,
	color,
	disabled,
	dropVariation,
	elevate,
	elevateHover,
	fontWeight,
	glow,
	hoverColor,
	icon,
	id,
	inline,
	label,
	labelO2,
	outline,
	pill,
	size,
	square,
	tall,
	title,
	wide,
	isOnlyContent,
	focusColor,
	isScroll,
	menuSize,
	menuIsFit,
}) => {
	const getClass = () =>
		classNames(`${dropVariation}`, { 'dropdown-inline': inline }, className, {
			[`dropdown-focus--${focusColor}`]: focusColor,
		});

	const getBtnClass = () =>
		classNames(
			'btn',
			{
				'btn-block': block,
				'btn-circle': circle,
				'btn-elevate-hover': elevateHover,
				'btn-elevate': elevate,
				'btn-glow': glow,
				'btn-icon': !title,
				'btn-pill': pill,
				'btn-square': square,
				'dropdown-toggle': !circle && arrow,
				disabled,
			},
			(color && (outline ? `btn-outline-${color}` : `btn-${color}`)) || '',
			(label && (labelO2 ? `btn-label-${label}-o2` : `btn-label-${label}`)) || '',
			bold ? `btn-${bold}` : '',
			hoverColor ? `btn-hover-${hoverColor}` : '',
			size ? `btn-${size}` : '',
			tall ? `btn-${tall}` : '',
			wide ? `btn-${wide}` : '',
			{ 'dropdown-toggle-split': isOnlyContent },
			{ [`sdms-font-${fontWeight}`]: fontWeight }
		);

	const getMenuClass = () =>
		classNames(
			'dropdown-menu',
			{ 'dropdown-menu-left': aligned === 'left' },
			{ 'dropdown-menu-right': aligned === 'right' },
			{ 'dropdown-scroll': isScroll },
			{ [`dropdown-menu-${menuSize}`]: menuSize },
			{ 'dropdown-menu-fit': menuIsFit }
		);

	const getSize = () => {
		switch (size) {
			case 'sm':
				return 20;
			case 'lg':
				return 24;
			default:
				return 22;
		}
	};

	const _Dropdown = (
		<>
			<button
				type='button'
				className={getBtnClass()}
				data-toggle='dropdown'
				aria-haspopup='true'
				aria-expanded='false'>
				{icon !== null ? <SVGIcon name={icon} size={getSize()} /> : null}
				{title || null}
				{arrow && !circle ? (
					<SVGIcon className='dropdown-toggle__icon' name='Angle-down' size={14} />
				) : (
					''
				)}
			</button>
			<div className={getMenuClass()}>{children}</div>
		</>
	);

	if (isOnlyContent) {
		return <>{_Dropdown}</>;
	}
	return (
		<div id={id} className={getClass()}>
			{_Dropdown}
		</div>
	);
};
Dropdown.propTypes = {
	aligned: PropTypes.oneOf(['left', 'right']),
	arrow: PropTypes.bool,
	block: PropTypes.bool,
	bold: PropTypes.oneOf(['bold', 'bolder', 'boldest']),
	circle: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
	color: PropTypes.oneOf([
		'brand',
		'clean',
		'danger',
		'dark',
		'default',
		'info',
		'light',
		'primary',
		'success',
		'warning',
		null,
	]),
	dropVariation: PropTypes.oneOf(['dropdown', 'dropup', 'dropleft', 'dropright']),
	elevate: PropTypes.bool,
	elevateHover: PropTypes.bool,
	fontWeight: PropTypes.oneOf(['bold', 'bolder', 'boldest']),
	glow: PropTypes.bool,
	hoverColor: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'info',
		'light',
		'primary',
		'success',
		'warning',
	]),
	icon: PropTypes.string,
	id: PropTypes.string,
	inline: PropTypes.bool,
	label: PropTypes.oneOf([
		'default',
		'brand',
		'danger',
		'dark',
		'facebook',
		'google',
		'info',
		'instagram',
		'light',
		'linkedin',
		'primary',
		'success',
		'twitter',
		'warning',
		'youtube',
	]),
	focusColor: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'info',
		'light',
		'primary',
		'secondary',
		'success',
		'warning',
	]),
	labelO2: PropTypes.bool,
	outline: PropTypes.bool,
	pill: PropTypes.bool,
	size: PropTypes.oneOf(['sm', 'lg']),
	square: PropTypes.bool,
	tall: PropTypes.oneOf(['tall', 'taller', 'tallest']),
	title: PropTypes.string,
	wide: PropTypes.oneOf(['wide', 'wider', 'widest']),
	isOnlyContent: PropTypes.bool,
	isScroll: PropTypes.bool,
	disabled: PropTypes.bool,
	menuSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', null]),
	menuIsFit: PropTypes.bool,
};
Dropdown.defaultProps = {
	aligned: 'left',
	arrow: true,
	block: false,
	bold: null,
	circle: null,
	className: null,
	children: null,
	color: null,
	dropVariation: 'dropdown',
	elevate: true,
	elevateHover: null,
	fontWeight: null,
	glow: false,
	hoverColor: null,
	icon: null,
	id: null,
	inline: false,
	label: null,
	labelO2: false,
	outline: true,
	pill: false,
	size: null,
	square: false,
	tall: null,
	title: null,
	wide: null,
	isOnlyContent: false,
	focusColor: 'brand',
	isScroll: false,
	disabled: false,
	menuSize: null,
	menuIsFit: false,
};

const Item = ({
	id,
	children,
	className,
	icon,
	iconColor,
	onClick,
	onKeyPress,
	size,
	to,
	itemsColor,
	type,
	target,
	isDisabled,
	noPermission,
}) => {
	const itemClass = classNames(
		'dropdown-item',
		className,
		{
			[`items-label--${itemsColor}`]: itemsColor,
		},
		{ disabled: isDisabled },
		noPermissionStatus(noPermission)
	);

	const _LinkItems = (
		<>
			{icon && (
				<SVGIcon name={icon} size={size} fill={iconColor || '#000'} className='sdms-mr-5' />
			)}
			{children}
		</>
	);

	if (type === 'NavLink')
		return (
			<NavLink
				target={target}
				to={to}
				activeClassName='active'
				id={id}
				className={itemClass}
				rel={target === '_blank' ? 'noopener' : ''}>
				{_LinkItems}
			</NavLink>
		);
	if (type === 'Link')
		return (
			<Link
				target={target}
				to={to}
				id={id}
				className={itemClass}
				rel={target === '_blank' ? 'noopener' : ''}>
				{_LinkItems}
			</Link>
		);
	return (
		<div
			id={id}
			className={itemClass}
			onClick={noPermission ? null : onClick}
			onKeyPress={onKeyPress}
			role='presentation'>
			{_LinkItems}
		</div>
	);
};
Item.propTypes = {
	size: PropTypes.number,
	id: PropTypes.string,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	icon: PropTypes.string,
	iconColor: PropTypes.string,
	onClick: PropTypes.func,
	onKeyPress: PropTypes.func,
	to: PropTypes.string,
	itemsColor: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'info',
		'light',
		'primary',
		'secondary',
		'success',
		'warning',
	]),
	type: PropTypes.oneOf(['NavLink', 'Link']),
	target: PropTypes.oneOf(['_blank', '_self']),
	isDisabled: PropTypes.bool,
	noPermission: PropTypes.bool,
};
Item.defaultProps = {
	size: 20,
	id: null,
	className: null,
	icon: null,
	iconColor: null,
	onClick: () => {},
	onKeyPress: () => {},
	to: null,
	itemsColor: null,
	type: null,
	target: '_self',
	isDisabled: false,
	noPermission: null,
};

const Header = ({ children }) => {
	return <h6 className='dropdown-header text-uppercase text-black-50'>{children}</h6>;
};
Header.propTypes = {
	children: PropTypes.node.isRequired,
};

const Divider = () => {
	return <div className='dropdown-divider' />;
};

Dropdown.Item = Item;
Dropdown.Header = Header;
Dropdown.Divider = Divider;

export default Dropdown;
