import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import apiCall, { filters as apiCallFilters } from '../../../utils/helpers/apiCall';
import { addErrorNotification, randomColor, useWindowSize } from '../../../utils/helpers/helper';

import Portlet from '../layout/Portlet';
import Loading from '../template/Loading';
import Search from '../element/Search';
import SelectableList from '../template/SelectableList';
import Button from '../element/Button';
import Badge from '../element/Badge';
import Portal from '../layout/Portal';
import NullBadge from '../design/NullBadge';
import { Register } from './LoginModal';
import { mediaBreakpoint } from '../../../utils/constants/constants';
import QuickPanel from '../../QuickPanel';

const _title = 'Select a Customer';
const _icon = 'User';

export const CustomerCell = ({ data }) => {
	const _randomColor = useRef(randomColor());
	return (
		<Badge
			design={_randomColor.current}
			className='sdms-badge--username'
			isUnified
			size='lg'
			isRounded
			fontWeight='bold'>
			{data.firstName.charAt(0) + data.lastName.charAt(0)}
		</Badge>
	);
};
CustomerCell.propTypes = {
	data: PropTypes.shape({
		profilePicture: PropTypes.object,
		displayName: PropTypes.string,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
	}),
};
CustomerCell.defaultProps = {
	data: {
		profilePicture: null,
		displayName: null,
		firstName: null,
		lastName: null,
	},
};

export const NameCell = ({ data, onClick }) => {
	return (
		<span onClick={onClick} role='presentation'>
			<span className='sdms-font-bold sdms-link sdms-link--dark'>{data.displayName}</span>
			<div className='sdms-font-sm sdms-list-layout__small-text'>{`${data.firstName} ${data.lastName}`}</div>
		</span>
	);
};
NameCell.propTypes = {
	data: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		displayName: PropTypes.string,
	}),
	onClick: PropTypes.func,
};
NameCell.defaultProps = {
	data: {
		firstName: null,
		lastName: null,
		displayName: null,
	},
	onClick: () => {},
};

export const CustomerAddressCell = ({ data }) => {
	if (
		data.addressLineOne === '' &&
		data.addressLineTwo === '' &&
		data.city === '' &&
		data.state === null &&
		data.zip === '' &&
		data.phone === '' &&
		data.email === ''
	)
		return <NullBadge />;
	return (
		<div>
			<div>{data.addressLineOne}</div>
			<div>{data.addressLineTwo}</div>
			<div>
				{data.city} {data.state ? data.state.code : ''} {data.zip}{' '}
			</div>
			<div>{data.phone}</div>
			<div>{data.email}</div>
		</div>
	);
};
CustomerAddressCell.propTypes = {
	data: PropTypes.shape({
		addressLineOne: PropTypes.string,
		addressLineTwo: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.shape({
			code: PropTypes.string,
		}),
		zip: PropTypes.string,
		country: PropTypes.shape({
			code: PropTypes.string,
		}),
		phone: PropTypes.string,
		email: PropTypes.string,
	}),
};
CustomerAddressCell.defaultProps = {
	data: {
		addressLineOne: null,
		addressLineTwo: null,
		city: null,
		state: {
			code: null,
		},
		zip: null,
		country: {
			code: null,
		},
		phone: null,
		email: null,
	},
};

const CustomerModal = ({
	defaultCustomer,
	setSelectedCustomer,
	isOpen,
	onClose,
	isHouseAccount,
	hasRegisterForm,
	paymentTerms,
	countries,
	defaultCountry,
	states,
	enableHouseAccount,
	saveCreditCards,
	passwordSettings,
	closeOnSelect,
	filterIsActive,
	disabled,
	onVesselSelect,
}) => {
	const windowSize = useWindowSize();

	if (windowSize.width < mediaBreakpoint.MD) {
		return (
			<QuickPanel
				status={isOpen}
				setStatus={onClose}
				title={_title}
				icon={_icon}
				portletClass='sdms-list-layout'>
				<CustomerModalContent
					defaultCustomer={defaultCustomer}
					setSelectedCustomer={setSelectedCustomer}
					onClose={onClose}
					isHouseAccount={isHouseAccount}
					hasRegisterForm={hasRegisterForm}
					paymentTerms={paymentTerms}
					countries={countries}
					defaultCountry={defaultCountry}
					states={states}
					enableHouseAccount={enableHouseAccount}
					saveCreditCards={saveCreditCards}
					passwordSettings={passwordSettings}
					closeOnSelect={closeOnSelect}
					withOutPortlet
					filterIsActive={filterIsActive}
				/>
			</QuickPanel>
		);
	}
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				closeOnEscape={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
					minHeight: '480px',
					height: '50%',
				}}>
				<CustomerModalContent
					defaultCustomer={defaultCustomer}
					setSelectedCustomer={setSelectedCustomer}
					onClose={onClose}
					isHouseAccount={isHouseAccount}
					hasRegisterForm={hasRegisterForm}
					paymentTerms={paymentTerms}
					countries={countries}
					defaultCountry={defaultCountry}
					states={states}
					enableHouseAccount={enableHouseAccount}
					saveCreditCards={saveCreditCards}
					passwordSettings={passwordSettings}
					closeOnSelect={closeOnSelect}
					filterIsActive={filterIsActive}
					disabled={disabled}
					onVesselSelect={onVesselSelect}
				/>
			</Popup>
		</Portal>
	);
};
CustomerModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	defaultCustomer: PropTypes.object,
	setSelectedCustomer: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	isHouseAccount: PropTypes.bool,
	hasRegisterForm: PropTypes.bool,
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	countries: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	defaultCountry: PropTypes.object,
	states: PropTypes.arrayOf(PropTypes.object),
	enableHouseAccount: PropTypes.bool,
	saveCreditCards: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	passwordSettings: PropTypes.object,
	closeOnSelect: PropTypes.bool,
	filterIsActive: PropTypes.bool,
	disabled: PropTypes.bool,
	onVesselSelect: PropTypes.func,
};
CustomerModal.defaultProps = {
	defaultCustomer: null,
	isHouseAccount: false,
	hasRegisterForm: false,
	paymentTerms: [],
	countries: [],
	defaultCountry: null,
	states: [],
	enableHouseAccount: false,
	saveCreditCards: false,
	passwordSettings: null,
	closeOnSelect: true,
	filterIsActive: false,
	disabled: false,
	onVesselSelect: null,
};

const CustomerModalContent = ({
	defaultCustomer,
	setSelectedCustomer,
	onClose,
	isHouseAccount,
	hasRegisterForm,
	paymentTerms,
	countries,
	defaultCountry,
	states,
	enableHouseAccount,
	saveCreditCards,
	passwordSettings,
	closeOnSelect,
	withOutPortlet,
	filterIsActive,
	disabled,
	onVesselSelect,
}) => {
	const [data, setData] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [isListLoading, setIsListLoading] = useState(true);
	const [error, setError] = useState('');
	const [isRegisterFormOpen, setIsRegisterFormOpen] = useState(false);

	const _isMounted = useRef(false);
	const totalItems = useRef(0);

	const inputRef = useRef();

	const itemTotalInfo = () => {
		if (totalItems.current > 0) {
			return (
				<>
					<span>{totalItems.current}</span>
					<span>{totalItems.current === 1 ? ' Total' : ' Totals'}</span>
				</>
			);
		}
		return 'No Results';
	};

	useEffect(() => {
		_isMounted.current = true;

		inputRef.current.focus();

		return () => {
			_isMounted.current = false;
		};
	}, []);

	const abortController = useRef(null);

	useEffect(() => {
		if (searchText === '') {
			totalItems.current = 0;
			setData(defaultCustomer ? [defaultCustomer] : []);
			setIsListLoading(false);
			return;
		}

		if (abortController.current) abortController.current.abort();

		abortController.current = new AbortController();

		setIsListLoading(true);

		const filters = {
			'order[displayName]': 'asc',
			'or[displayName]': searchText,
			...(filterIsActive && { isActive: true }),
		};

		if (apiCallFilters.customers) {
			if (apiCallFilters.customers.pagination)
				filters.pagination = apiCallFilters.customers.pagination;

			if (apiCallFilters.customers.itemsPerPage)
				filters.itemsPerPage = apiCallFilters.customers.itemsPerPage;
		}

		if (isHouseAccount) filters.houseAccount = true;

		apiCall(
			'GET',
			'customers',
			res => {
				if (!_isMounted.current) return;
				const _customers = filters.pagination ? res['hydra:member'] : res;
				totalItems.current = _customers.length;
				setData(_customers);
				setIsListLoading(false);
				setError('');
			},
			err => {
				if (err.toString().search('AbortError') === -1) {
					setError(err.toString());
					addErrorNotification(err.toString());
					setIsListLoading(false);
				}
			},
			'',
			null,
			filters,
			abortController.current.signal
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText]);

	if (error !== '') return <div>{error}</div>;

	if (isRegisterFormOpen) {
		return (
			<Register
				inCustomerModal
				onRegister={_customer => setSelectedCustomer(_customer)}
				onCancel={() => setIsRegisterFormOpen(false)}
				onClose={onClose}
				paymentTerms={paymentTerms}
				countries={countries}
				defaultCountry={defaultCountry}
				states={states}
				enableHouseAccount={enableHouseAccount}
				saveCreditCards={saveCreditCards}
				passwordSettings={passwordSettings}
			/>
		);
	}

	const _Inner = (
		<>
			<Portlet.Head wrapMaxSize='md'>
				<Portlet.HeadLabel portletIcon={!withOutPortlet && _icon}>
					{!withOutPortlet && (
						<>
							<h3 className='sdms-portlet__head-title'>Select a Customer</h3>
							<Portlet.Separator />
						</>
					)}
					<div className='sdms-portlet__head-desc'>
						<Loading isLoading={isListLoading} width={70} type='title'>
							{itemTotalInfo()}
						</Loading>
					</div>
				</Portlet.HeadLabel>
				<Portlet.HeadToolbarActions className='sdms-last-margin'>
					<Search
						inPortlet
						searchText={searchText}
						setSearchText={setSearchText}
						isSearching={isListLoading}
						ref={inputRef}
						disabled={disabled}
					/>
					{onVesselSelect && (
						<Button
							label='brand'
							icon='Ship'
							size='sm'
							onClick={onVesselSelect}
							disabled={disabled}
						/>
					)}
					{hasRegisterForm && (
						<Button
							label='brand'
							icon='Add-user'
							text='New'
							size='sm'
							onClick={() => setIsRegisterFormOpen(true)}
							disabled={disabled}
						/>
					)}
				</Portlet.HeadToolbarActions>
			</Portlet.Head>
			<SelectableList
				withOutPortlet
				data={data}
				onClick={item => {
					setSelectedCustomer(item);
					if (closeOnSelect) onClose();
				}}
				isLoading={isListLoading}
				withImage>
				<SelectableList.Col
					width={65}
					className='sdms-cursor--pointer'
					label='Image'
					cellComponent={<CustomerCell />}
				/>
				<SelectableList.Col
					className='sdms-cursor--pointer'
					label='Name'
					cellComponent={<NameCell />}
				/>
				<SelectableList.Col
					className='sdms-cursor--pointer'
					label='Contact'
					cellComponent={<CustomerAddressCell />}
				/>
			</SelectableList>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
			</Portlet.Foot>
		</>
	);

	if (withOutPortlet) {
		return _Inner;
	}
	return (
		<Portlet className='sdms-list-layout' fluid='fluid' isLast everyTimeFluid>
			{_Inner}
		</Portlet>
	);
};
CustomerModalContent.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	defaultCustomer: PropTypes.object,
	setSelectedCustomer: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	isHouseAccount: PropTypes.bool,
	hasRegisterForm: PropTypes.bool,
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	countries: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	defaultCountry: PropTypes.object,
	states: PropTypes.arrayOf(PropTypes.object),
	enableHouseAccount: PropTypes.bool,
	saveCreditCards: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	passwordSettings: PropTypes.object,
	closeOnSelect: PropTypes.bool.isRequired,
	withOutPortlet: PropTypes.bool,
	filterIsActive: PropTypes.bool,
	disabled: PropTypes.bool,
	onVesselSelect: PropTypes.func,
};
CustomerModalContent.defaultProps = {
	defaultCustomer: null,
	isHouseAccount: false,
	hasRegisterForm: false,
	paymentTerms: [],
	countries: [],
	defaultCountry: null,
	states: [],
	enableHouseAccount: false,
	saveCreditCards: false,
	passwordSettings: null,
	withOutPortlet: false,
	filterIsActive: false,
	disabled: false,
	onVesselSelect: null,
};

export default CustomerModal;
