import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import moment from 'moment/moment';

import UserContext from '../../../app/contexts/UserContext';
import useField from '../../../utils/hooks/useField';
import useDate from '../../../utils/hooks/useDate';
import { accountingExports } from '../../../utils/constants/constants';
import apiCall from '../../../utils/helpers/apiCall';
import { addErrorNotification, downloadCsv } from '../../../utils/helpers/helper';

import Portal from '../layout/Portal';
import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import FormGroup from '../layout/FormGroup';
import DatePicker from '../field/DatePicker';
import FormField from '../template/FormField';
import Radio from '../field/Radio';
import Toggle from '../field/Toggle';

const AccountingExport = ({ isOpen, type, onClose }) => {
	const userContext = useContext(UserContext);

	const [
		dateFormatter,
		,
		,
		,
		parseReservationDatePickerValue,
		parseReservationDatePickerChange,
	] = useDate();

	const [whichDate, whichDateOnChange] = useField(
		{},
		'whichDate',
		() => {},
		[],
		'transactionDate'
	);

	const [isExporting, setIsExporting] = useState(false);

	const endDateValidation = (value, setValRes) => {
		if (value <= start) {
			setValRes({
				isValid: false,
				status: 'invalidToDate',
				message: 'Invalid to date',
			});
			return false;
		}

		return true;
	};

	const [start, startOnChange] = useField(
		{},
		'start',
		() => {},
		[],
		moment()
			.utc(false)
			.startOf('month')
			.toISOString()
	);

	const [end, endOnChange, endValRes, endShowVal, setEndShowVal, validateEnd] = useField(
		{},
		'startDate',
		() => {},
		[endDateValidation],
		moment()
			.utc(false)
			.endOf('day')
			.toISOString()
	);

	const [resend, resendOnChange] = useField({}, 'resend', () => {}, [], false);

	const onExport = () => {
		setEndShowVal();

		if (!endValRes.isValid) return;

		setIsExporting(true);

		const fileName =
			type?.value === accountingExports.SAGE_INTACCT_CUSTOMER
				? `${userContext.data.user.company.name}_Customer_${dateFormatter(
						moment(),
						false
				  )}.csv`
				: `${userContext.data.user.company.name}_GL_${dateFormatter(
						start,
						false
				  )}_${dateFormatter(end, false)}.csv`;

		apiCall(
			'POST',
			'accountingExport',
			res => {
				downloadCsv(res, fileName, () => setIsExporting(false));
			},
			err => {
				addErrorNotification(err.toString());
				setIsExporting(false);
			},
			'',
			{
				start,
				end,
				whichDate,
				resend,
				typeId: type.id,
				outletId: userContext.data.selectedOutlet.id,
			}
		);
	};

	useEffect(() => {
		validateEnd(end);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [start]);

	return (
		<Portal>
			<Popup
				open={isOpen}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<Portlet>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon='Export'>
							{type?.value}
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						{(type?.value === accountingExports.SAGE_300 ||
							type?.value === accountingExports.SAGE_INTACCT_GL) && (
							<FormGroup>
								<FormField name='start' label='Start' colMd={6}>
									<DatePicker
										id='start'
										type='calendar'
										value={parseReservationDatePickerValue(start)}
										onChange={e =>
											startOnChange({
												target: {
													value: parseReservationDatePickerChange(
														start,
														e.target.value
													),
												},
											})
										}
										disableClear
										disabled={isExporting}
									/>
								</FormField>
								<FormField
									name='end'
									label='End'
									colMd={6}
									showValidation={endShowVal}
									valRes={endValRes}>
									<DatePicker
										id='endDate'
										type='calendar'
										value={parseReservationDatePickerValue(end)}
										onChange={e =>
											endOnChange({
												target: {
													value: parseReservationDatePickerChange(
														end,
														e.target.value
													),
												},
											})
										}
										onBlur={setEndShowVal}
										minDate={start ? moment(start).toDate() : null}
										disableClear
										disabled={isExporting}
									/>
								</FormField>

								<FormField
									name='whichDate'
									label='Filter By'
									id='whichDate'
									colMd={12}>
									<Radio.Container isInline>
										<Radio
											checked={whichDate === 'transactionDate'}
											id='transactionDate'
											name='transactionDate'
											content='Transaction Date'
											className='sdms-radio--primary'
											onChange={() =>
												whichDateOnChange({
													target: { value: 'transactionDate' },
												})
											}
										/>
										<Radio
											checked={whichDate === 'ledgerCreatedDate'}
											id='ledgerCreatedDate'
											name='ledgerCreatedDate'
											content='Ledger Created Date'
											className='sdms-radio--primary'
											onChange={() =>
												whichDateOnChange({
													target: { value: 'ledgerCreatedDate' },
												})
											}
										/>
									</Radio.Container>
								</FormField>
							</FormGroup>
						)}
						<FormGroup>
							<FormField
								label='Resend'
								name='resend'
								id='resend'
								col={4}
								inFormDesign={false}>
								<Toggle spaceLess value={resend} onChange={resendOnChange} />
							</FormField>
						</FormGroup>
					</Portlet.Body>
					<Portlet.Foot className='sdms-align-left' tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
								disabled={isExporting}
							/>
						</div>
						<div className='col-auto'>
							<Button
								label='brand'
								icon='Export'
								text={isExporting ? 'Exporting' : 'Export'}
								size='sm'
								onClick={onExport}
								disabled={isExporting}
								isSubmitting={isExporting}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};

AccountingExport.propTypes = {
	isOpen: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	type: PropTypes.object,
	onClose: PropTypes.func,
};
AccountingExport.defaultProps = {
	isOpen: false,
	type: null,
	onClose: () => {},
};

export default AccountingExport;
