import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import moment from 'moment';
import FormGroup from '../layout/FormGroup';
import Section from '../layout/Section';
import FormField from '../template/FormField';
import Textarea from '../field/Textarea';
import MultiSelect from '../element/MultiSelect';
import Portlet from '../layout/Portlet';
import Badge from '../element/Badge';
import FlagStatus, { getInfoStatus } from '../element/FlagStatus';
import Selects from '../field/Selects';
import { bookingCalculations } from '../../../utils/constants/constants';
import Alert from '../element/Alert';
import VehicleSettings from '../element/reservation_form/elements/VehicleSettings';
import { modules } from '../../../utils/helpers/apiCall';
import { modals } from '../element/reservation_form/constants';

const ExtraCategoryItem = ({
	id,
	category,
	index,
	ind,
	extras,
	updateExtras,
	extrasVal,
	extrasShowVal,
	isLoading,
	min,
	max,
	selected,
	isLast,
	fromDate,
	selectedProduct,
}) => {
	// if category item has quantity, quantity field is added
	category.items.forEach(item => {
		if (
			(item.bookingCalculation === bookingCalculations.PER_QUANTITY ||
				item.bookingCalculation === bookingCalculations.PER_QUANTITY_PER_PERIOD) &&
			typeof item.multiSelectQuantity === 'undefined'
		) {
			item.multiSelectQuantity = 1;
		}
	});

	const _Inner = (
		<FormField
			name={`${category.name}-${index}-${ind}`}
			valRes={extrasVal[index][ind]}
			showValidation={extrasShowVal[index][ind]}
			id={`${category.name}-${index}-${ind}`}
			key={`${category.name}-${index}-${ind}`}
			col={12}
			inFormDesign={false}
			isLast={id !== '0'}>
			<MultiSelect
				titleProp='externalDescription'
				key={`${category.name}-${index}-${ind}`}
				itemsCol={4}
				data={category.items}
				withoutSelectAll
				withoutSeparator
				value={extras && extras[index] && extras[index][ind] ? extras[index][ind] : []}
				onChange={e => {
					const valueWithoutTax = e.target.value.filter(v => !v.isTax);
					updateExtras(
						e.target.value,
						// eslint-disable-next-line no-nested-ternary
						category.min && valueWithoutTax.length < category.min
							? {
									isValid: false,
									status: 'minSelection',
									message: `At least ${category.min} item(s) should be selected!`,
							  }
							: category.max && valueWithoutTax.length > category.max
							? {
									isValid: false,
									status: 'maxSelection',
									message: `At most ${category.max} item(s) can be selected!`,
							  }
							: {
									isValid: true,
									status: 'valid',
									message: '',
							  }
					);
				}}
				maxSelection={category.max}
				disabled={isLoading}
				fromDate={fromDate}
				selectedProduct={selectedProduct}
			/>
		</FormField>
	);
	if (id === '0') {
		return _Inner;
	}
	return (
		<div className='col-12' key={`${index}-${ind}`}>
			<Portlet border isLast={isLast}>
				<Portlet.Head>
					<Portlet.HeadLabelTitle
						portletIcon={
							getInfoStatus(min, max, selected) === 'danger' ? 'Info-circle' : ''
						}
						iconFill='var(--danger)'>
						{category.name}
					</Portlet.HeadLabelTitle>
					<Portlet.HeadLabel>
						<div className='sdms-portlet__head-desc sdms-mr-0 sdms-mt-10'>
							<FlagStatus min={min} max={max} selected={selected} />
						</div>
					</Portlet.HeadLabel>
				</Portlet.Head>
				<Portlet.Body>
					<div className='row'>{_Inner}</div>
				</Portlet.Body>
			</Portlet>
		</div>
	);
};
ExtraCategoryItem.propTypes = {
	id: PropTypes.string.isRequired,
	category: PropTypes.shape({
		name: PropTypes.string.isRequired,
		items: PropTypes.arrayOf(PropTypes.object).isRequired,
		min: PropTypes.number.isRequired,
		max: PropTypes.number.isRequired,
	}).isRequired,
	index: PropTypes.number.isRequired,
	ind: PropTypes.number.isRequired,
	extras: PropTypes.arrayOf(PropTypes.object).isRequired,
	updateExtras: PropTypes.func.isRequired,
	extrasVal: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
	extrasShowVal: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.bool)).isRequired,
	isLoading: PropTypes.bool.isRequired,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	selected: PropTypes.number.isRequired,
	isLast: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	fromDate: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	selectedProduct: PropTypes.object,
};

ExtraCategoryItem.defaultProps = {
	fromDate: null,
	selectedProduct: null,
};

const HourlyProductHoursItem = ({
	selectedProduct,
	index,
	startTimes,
	setStartTimes,
	startTimesValRes,
	setStartTimesValRes,
	startTimesShowVal,
	setStartTimesShowVal,
	endTimes,
	setEndTimes,
	endTimesValRes,
	setEndTimesValRes,
	endTimesShowVal,
	setEndTimesShowVal,
	useSameTimes,
	isItemReserved,
}) => {
	const convertTime24To12 = time => {
		return moment()
			.hour(time.split(':')[0])
			.minute(time.split(':')[1])
			.format('hh:mm a');
	};

	const getAvailableTimes = () => {
		const availability = JSON.parse(JSON.stringify(selectedProduct.pricing.availability));
		if (useSameTimes) {
			Object.keys(availability).forEach(st => {
				Object.keys(availability[st]).forEach(endTime => {
					if (availability[st][endTime] < startTimes.length)
						delete availability[st][endTime];
				});
			});
			Object.keys(availability).forEach(st => {
				if (Object.keys(availability[st]).length === 0) delete availability[st];
			});
		} else {
			Object.keys(availability).forEach(st => {
				Object.keys(availability[st]).forEach(endTime => {
					startTimes.forEach((val, ind) => {
						if (val && endTimes[ind]) {
							const endTimeMoment = moment(endTimes[ind], 'HH:mm');

							if (selectedProduct.buffer)
								endTimeMoment.add(selectedProduct.buffer, 'minutes');

							if (
								!(
									(moment(st, 'HH:mm').isBefore(moment(val, 'HH:mm')) &&
										endTimeMoment.isSameOrBefore(moment(val, 'HH:mm'))) ||
									moment(st, 'HH:mm').isSameOrAfter(endTimeMoment)
								)
							)
								availability[st][endTime] -= 1;
						}

						if (!availability[st][endTime] || availability[st][endTime] === 0)
							delete availability[st][endTime];
					});
				});
			});
			Object.keys(availability).forEach(st => {
				if (Object.keys(availability[st]).length === 0) delete availability[st];
			});
		}

		return availability;
	};

	const getEndTimeOptions = startTime => {
		const availability = getAvailableTimes();

		if (!startTime || !availability[startTimes[index]]) return [];

		return Object.keys(availability[startTimes[index]]).map(time => {
			return { id: time, value: time, display: convertTime24To12(time) };
		});
	};

	const getStartTimeOptions = () => {
		const availability = getAvailableTimes();

		return Object.keys(availability).map(time => {
			return { id: time, value: time, display: convertTime24To12(time) };
		});
	};

	const updateTimes = (time, isEnd = false) => {
		const valRes = {
			isValid: time !== null,
			status: time ? 'valid' : 'required',
			message: time ? '' : 'Field is required',
		};

		if (useSameTimes) {
			if (isEnd) {
				setEndTimes(endTimes.map(() => time));
				setEndTimesValRes(endTimes.map(() => valRes));
				setEndTimesShowVal(endTimes.map(() => true));
			} else {
				setStartTimes(startTimes.map(() => time));
				setStartTimesValRes(startTimes.map(() => valRes));
				setStartTimesShowVal(startTimes.map(() => true));
				setEndTimes(endTimes.map(() => null));
				setEndTimesValRes(
					endTimes.map(() => {
						return {
							isValid: false,
							status: 'required',
							message: 'Field is required',
						};
					})
				);
			}
		} else if (isEnd) {
			setEndTimes(update(endTimes, { [index]: { $set: time } }));
			setEndTimesValRes(update(endTimesValRes, { [index]: { $set: valRes } }));
			setEndTimesShowVal(update(endTimesShowVal, { [index]: { $set: true } }));
		} else {
			setStartTimes(update(startTimes, { [index]: { $set: time } }));
			setStartTimesValRes(update(startTimesValRes, { [index]: { $set: valRes } }));
			setStartTimesShowVal(update(startTimesShowVal, { [index]: { $set: true } }));
			setEndTimes(update(endTimes, { [index]: { $set: null } }));
			setEndTimesValRes(
				update(endTimesValRes, {
					[index]: {
						$set: {
							isValid: false,
							status: 'required',
							message: 'Field is required',
						},
					},
				})
			);
		}
	};

	useEffect(() => {
		const endTimeOptions = getEndTimeOptions(startTimes[index]);

		if (endTimeOptions.length === 1) updateTimes(endTimeOptions[0].value, true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startTimes, index]);

	return (
		<>
			<FormField
				name='start-time'
				label='Start Time'
				description='Start time description'
				id={index}
				col={6}
				colMd={3}
				showValidation={startTimesShowVal[index]}
				valRes={startTimesValRes[index]}>
				<Selects
					value={
						startTimes[index]
							? {
									id: startTimes[index],
									value: startTimes[index],
									display: convertTime24To12(startTimes[index]),
							  }
							: null
					}
					onChange={e => updateTimes(e.target.value ? e.target.value.value : null)}
					options={getStartTimeOptions()}
					displayKey='display'
					disabled={isItemReserved}
					mobileUI
					placeholder='Select start time'
					sortKey='value'
				/>
			</FormField>
			<FormField
				name='end-time'
				label='End Time'
				description='End time description'
				id={index}
				col={6}
				colMd={3}
				showValidation={endTimesShowVal[index]}
				valRes={endTimesValRes[index]}>
				<Selects
					value={
						endTimes[index]
							? {
									id: endTimes[index],
									value: endTimes[index],
									display: convertTime24To12(endTimes[index]),
							  }
							: null
					}
					onChange={e => updateTimes(e.target.value ? e.target.value.value : null, true)}
					options={getEndTimeOptions(startTimes[index])}
					displayKey='display'
					disabled={isItemReserved || getEndTimeOptions(startTimes[index]).length < 2}
					mobileUI
					placeholder='Select end time'
					sortKey='value'
				/>
			</FormField>
		</>
	);
};
HourlyProductHoursItem.propTypes = {
	selectedProduct: PropTypes.shape({
		pricing: PropTypes.shape({
			availability: PropTypes.object,
		}),
		bookingInterval: PropTypes.number,
		buffer: PropTypes.number,
	}).isRequired,
	index: PropTypes.number.isRequired,
	startTimes: PropTypes.arrayOf(PropTypes.string).isRequired,
	setStartTimes: PropTypes.func.isRequired,
	startTimesValRes: PropTypes.arrayOf(PropTypes.object).isRequired,
	setStartTimesValRes: PropTypes.func.isRequired,
	startTimesShowVal: PropTypes.arrayOf(PropTypes.bool).isRequired,
	setStartTimesShowVal: PropTypes.func.isRequired,
	endTimes: PropTypes.arrayOf(PropTypes.string).isRequired,
	endTimesValRes: PropTypes.arrayOf(PropTypes.object).isRequired,
	setEndTimesValRes: PropTypes.func.isRequired,
	endTimesShowVal: PropTypes.arrayOf(PropTypes.bool).isRequired,
	setEndTimesShowVal: PropTypes.func.isRequired,
	setEndTimes: PropTypes.func.isRequired,
	useSameTimes: PropTypes.bool.isRequired,
	isItemReserved: PropTypes.bool.isRequired,
};

const ReservationItemForm = ({
	selectedProduct,
	index,
	startTimes,
	setStartTimes,
	startTimesValRes,
	setStartTimesValRes,
	startTimesShowVal,
	setStartTimesShowVal,
	endTimes,
	setEndTimes,
	endTimesValRes,
	setEndTimesValRes,
	endTimesShowVal,
	setEndTimesShowVal,
	notes,
	setNotes,
	extras,
	setExtras,
	extrasVal,
	setExtrasVal,
	extrasShowVal,
	setExtrasShowVal,
	useSameTimes,
	isMulti,
	isHourlyOnly,
	intervalText,
	isLoading,
	isItemReserved,
	vehicles,
	setVehicles,
	isOnline,
	enumRvTypes,
	enumVehicleMakes,
	enumBoatTypes,
	enumBoatMakes,
	customer,
	onCustomerChange,
	outlet,
	countries,
	defaultCountry,
	states,
	passwordSettings,
	openModal,
	fromDate,
}) => {
	const updateNotes = note => {
		const newNotes = [...notes];
		newNotes[index] = note.target.value;
		setNotes(newNotes);
	};
	const updateExtras = (value, valRes, ind) => {
		setExtras(update(extras, { [index]: { [ind]: { $set: value } } }));
		setExtrasVal(update(extrasVal, { [index]: { [ind]: { $set: valRes } } }));
		setExtrasShowVal(update(extrasShowVal, { [index]: { [ind]: { $set: true } } }));
	};

	const updateVehicle = vehicle => {
		const newVehicles = [...vehicles];
		newVehicles[index] = vehicle;
		setVehicles(newVehicles);
	};

	const visibleExtraCharges = {};

	Object.keys(selectedProduct.extraCharges).forEach(categoryId => {
		const items = selectedProduct.extraCharges[categoryId].items.filter(
			e => !e.code && !e.isTax
		);

		if (items.length)
			visibleExtraCharges[categoryId] = {
				...selectedProduct.extraCharges[categoryId],
				items,
			};
	});

	return (
		<Portlet className='flex-grow-0 sdms-min-h-fit-content'>
			<Portlet.Head
				wrapMaxSize={
					extrasVal[index].filter(valRes => !valRes.isValid).length > 0 ? 'md' : null
				}>
				<Portlet.HeadLabel>
					<h3 className='sdms-portlet__head-title'>
						{isMulti && `${index + 1}. `}
						{selectedProduct.name}
						{selectedProduct.categoryName && (
							<small>{selectedProduct.categoryName}</small>
						)}
					</h3>
					{selectedProduct && !isHourlyOnly && (
						<>
							<Portlet.Separator />
							<Badge design='info' isInline isUnified>
								{intervalText}
							</Badge>
						</>
					)}
				</Portlet.HeadLabel>
				{extrasVal[index].filter(valRes => !valRes.isValid).length > 0 && (
					<Portlet.HeadToolbarActions>
						<Alert icon='Info-circle' design='danger' solid className='sdms-p10' bold>
							Please fill in the required options
						</Alert>
					</Portlet.HeadToolbarActions>
				)}
			</Portlet.Head>
			<Portlet.Body>
				<FormGroup row={false} isLast>
					<Section>
						<Section.Body className='row'>
							{selectedProduct.bookingPeriod === 'Hourly' && (
								<HourlyProductHoursItem
									selectedProduct={selectedProduct}
									index={index}
									startTimes={startTimes}
									setStartTimes={setStartTimes}
									startTimesValRes={startTimesValRes}
									setStartTimesValRes={setStartTimesValRes}
									startTimesShowVal={startTimesShowVal}
									setStartTimesShowVal={setStartTimesShowVal}
									endTimes={endTimes}
									setEndTimes={setEndTimes}
									endTimesValRes={endTimesValRes}
									setEndTimesValRes={setEndTimesValRes}
									endTimesShowVal={endTimesShowVal}
									setEndTimesShowVal={setEndTimesShowVal}
									useSameTimes={useSameTimes}
									isItemReserved={isItemReserved}
								/>
							)}
							{(selectedProduct.module === modules.CAMPGROUND ||
								selectedProduct.module === modules.MARINA) && (
								<VehicleSettings
									product={selectedProduct}
									key={`${selectedProduct.id}-${index}`}
									data={vehicles[index] || undefined}
									isOnline={isOnline}
									module={selectedProduct.module}
									enumRvTypes={enumRvTypes}
									enumVehicleMakes={enumVehicleMakes}
									enumBoatTypes={enumBoatTypes}
									enumBoatMakes={enumBoatMakes}
									onChange={updateVehicle}
									customer={customer}
									onCustomerChange={onCustomerChange}
									outlet={outlet}
									countries={countries}
									defaultCountry={defaultCountry}
									states={states}
									passwordSettings={passwordSettings}
									openCustomerModal={() =>
										openModal({
											open: modals.CUSTOMER,
										})
									}
								/>
							)}
							<FormField
								name='specReq'
								label='Special Request'
								description='This is the special request'
								id={index}
								col={12}>
								<Textarea
									placeholder='Special Request'
									value={notes[index]}
									onChange={updateNotes}
									textRow={2}
									disabled={isLoading}
								/>
							</FormField>
							{Object.keys(visibleExtraCharges)
								.sort()
								.map((key, ind) =>
									ExtraCategoryItem({
										key: `${key}-${ind}`,
										id: key,
										category: visibleExtraCharges[key],
										index,
										ind,
										extras,
										updateExtras: (value, val) => updateExtras(value, val, ind),
										extrasVal,
										extrasShowVal,
										isLoading,
										min: visibleExtraCharges[key].min,
										max: visibleExtraCharges[key].max,
										selected: extras[index][ind]
											? extras[index][ind].length
											: 0,
										isLast: Object.keys(visibleExtraCharges).length === ind + 1,
										fromDate,
										selectedProduct,
									})
								)}
						</Section.Body>
					</Section>
				</FormGroup>
			</Portlet.Body>
		</Portlet>
	);
};
ReservationItemForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	selectedProduct: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	startTimes: PropTypes.arrayOf(PropTypes.string).isRequired,
	setStartTimes: PropTypes.func.isRequired,
	startTimesValRes: PropTypes.arrayOf(PropTypes.object).isRequired,
	setStartTimesValRes: PropTypes.func.isRequired,
	startTimesShowVal: PropTypes.arrayOf(PropTypes.bool).isRequired,
	setStartTimesShowVal: PropTypes.func.isRequired,
	endTimes: PropTypes.arrayOf(PropTypes.string).isRequired,
	setEndTimes: PropTypes.func.isRequired,
	endTimesValRes: PropTypes.arrayOf(PropTypes.object).isRequired,
	setEndTimesValRes: PropTypes.func.isRequired,
	endTimesShowVal: PropTypes.arrayOf(PropTypes.bool).isRequired,
	setEndTimesShowVal: PropTypes.func.isRequired,
	notes: PropTypes.arrayOf(PropTypes.string).isRequired,
	setNotes: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	extras: PropTypes.array.isRequired,
	setExtras: PropTypes.func.isRequired,
	extrasVal: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
	setExtrasVal: PropTypes.func.isRequired,
	extrasShowVal: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.bool)).isRequired,
	setExtrasShowVal: PropTypes.func.isRequired,
	useSameTimes: PropTypes.bool.isRequired,
	isMulti: PropTypes.bool.isRequired,
	isHourlyOnly: PropTypes.bool.isRequired,
	intervalText: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isItemReserved: PropTypes.bool.isRequired,
	vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
	setVehicles: PropTypes.func.isRequired,
	isOnline: PropTypes.bool.isRequired,
	enumRvTypes: PropTypes.arrayOf(PropTypes.object),
	enumVehicleMakes: PropTypes.arrayOf(PropTypes.object),
	enumBoatTypes: PropTypes.arrayOf(PropTypes.object),
	enumBoatMakes: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	onCustomerChange: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	countries: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	defaultCountry: PropTypes.object,
	states: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	passwordSettings: PropTypes.object,
	openModal: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	fromDate: PropTypes.object,
};

ReservationItemForm.defaultProps = {
	enumRvTypes: [],
	enumVehicleMakes: [],
	enumBoatTypes: [],
	enumBoatMakes: [],
	customer: null,
	countries: [],
	defaultCountry: null,
	states: [],
	passwordSettings: null,
	openModal: () => {},
	fromDate: null,
};

export default ReservationItemForm;
