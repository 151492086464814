import React, { useContext, useEffect } from 'react';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';

import FormContainer from '../../reusables/template/FormContainer';
import TemplateSettingsForm from './TemplateSettingsForm';
import useOutlet from '../../../utils/hooks/useOutlet';

const TemplateSettingsEdit = () => {
	const pages = usePages();

	// Use current user to get company data.
	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const [outletKey] = useOutlet();

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.companySettings.default.text,
				path: pages.companySettings.dashboard.path,
			},
			{
				title: pages.companySettings.templateSettings.text,
				path: pages.companySettings.templateSettings.path,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(pages.companySettings.templateSettings.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<FormContainer
			key={outletKey}
			dataId={
				userContext.data.selectedOutlet.settings
					? userContext.data.selectedOutlet.settings.id.toString()
					: '0'
			}
			pageTitle={pages.companySettings.templateSettings.text}
			icon={pages.companySettings.templateSettings.icon}
			dataName={pages.companySettings.templateSettings.route}
			fields={<TemplateSettingsForm />}
			lists={['templates']}
			showBackButton={false}
			afterSubmit={outletSetting => userContext.updateOutlet(outletSetting, true)}
		/>
	);
};

export default TemplateSettingsEdit;
