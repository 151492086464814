import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import { addErrorNotification, priceFormatter } from '../../../utils/helpers/helper';
import {
	creditCardPaymentStatuses,
	filterComponents,
	paymentStatuses,
} from '../../../utils/constants/constants';
import useDate from '../../../utils/hooks/useDate';
import apiCall from '../../../utils/helpers/apiCall';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import ListContainer from '../../reusables/template/ListContainer';
import List from '../../reusables/template/List';

const AmountCell = ({ data }) => {
	return priceFormatter(data.amount);
};

const MessageCell = ({ data }) => {
	if (!data.message) return '';

	return data.message.search('https://utgapi.shift4test.com/api/rest/v1/transactions/invoice') >
		-1 || data.message.search('https://utg.shift4api.net/api/rest/v1/transactions/invoice') > -1
		? 'Connection Error'
		: data.message;
};

const DateTimeCell = ({ data }) => {
	const [dateFormatter] = useDate();

	return <span>{dateFormatter(data.timeCreated)}</span>;
};

DateTimeCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

DateTimeCell.defaultProps = {
	data: {
		timeCreated: null,
	},
};

const CreditCardErrorLog = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.accounting.default.text, path: pages.accounting.dashboard.path },
			{
				title: pages.accounting.ccErrorLogs.text,
				path: pages.accounting.ccErrorLogs.path,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(pages.accounting.ccErrorLogs.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	const onRequeue = (payment, updateData) => {
		apiCall(
			'POST',
			'requeueCreditCardPayment',
			() => {
				updateData([], [payment.id]);
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ paymentId: payment.id }
		);
	};

	const onIgnore = (payment, updateData) => {
		apiCall(
			'PUT',
			'paymentCreditCards',
			() => {
				updateData([], [payment.id]);
			},
			err => {
				addErrorNotification(err.toString());
			},
			payment.id,
			{ ignored: true }
		);
	};

	return (
		<ListContainer
			route='paymentCreditCards'
			title={pages.accounting.ccErrorLogs.text}
			history={history}
			icon={pages.accounting.ccErrorLogs.icon}
			customActions={{
				requeue: onRequeue,
				ignore: onIgnore,
			}}
			staticFilters={{ 'exists[ignored]': false }}
			customFilters={[
				{
					name: 'statusFilter',
					component: filterComponents.RADIO,
					fieldName: 'creditCardPaymentStatus.value[]',
					default: [
						creditCardPaymentStatuses.CAPTURE_ERROR,
						creditCardPaymentStatuses.AUTH_ERROR,
						creditCardPaymentStatuses.CAPTURED_ONLY_AUTH_AMOUNT,
					],
					options: [
						{
							display: 'All',
							value: [
								creditCardPaymentStatuses.CAPTURE_ERROR,
								creditCardPaymentStatuses.AUTH_ERROR,
								creditCardPaymentStatuses.CAPTURED_ONLY_AUTH_AMOUNT,
							],
						},
						{
							display: 'Capture Errors',
							value: [creditCardPaymentStatuses.CAPTURE_ERROR],
						},
						{
							display: 'Auth Errors',
							value: [creditCardPaymentStatuses.AUTH_ERROR],
						},
						{
							display: 'Captured Only Auth Amount',
							value: [creditCardPaymentStatuses.CAPTURED_ONLY_AUTH_AMOUNT],
						},
					],
				},
			]}
			customFiltersInQuickPanel={false}>
			<List
				withCheckBox
				fluid='fluid'
				checkEditable={creditCard => {
					const actions = ['ignore'];

					if (
						creditCard.status.value !== paymentStatuses.VOIDED &&
						creditCard.status.value !== paymentStatuses.DECLINED &&
						creditCard.creditCardPaymentStatus.value ===
							creditCardPaymentStatuses.CAPTURE_ERROR &&
						creditCard.authCode !== '#' &&
						(!creditCard.message ||
							creditCard.message.toLowerCase().search('declined') < 0)
					)
						actions.push('requeue');

					return actions;
				}}>
				<List.Col label='#' cellData='remittanceId' width={120} sortable='remittanceId' />
				<List.Col
					label='Customer'
					cellDataObject='customer'
					cellData='displayName'
					sortable='customer.displayName'
				/>
				<List.Col
					label='Status'
					cellDataObject='creditCardPaymentStatus'
					cellData='value'
					sortable='creditCardPaymentStatus.value'
				/>
				<List.Col label='Transaction Id' cellData='gatewayTxnId' />
				<List.Col label='Auth Code' cellData='authCode' />
				<List.Col label='Card Number' cellData='cardNumber' />
				<List.Col label='Message' cellComponent={<MessageCell />} width={200} />
				<List.Col label='Amount' cellComponent={<AmountCell />} sortable='amount' />
				<List.Col label='Date' cellComponent={<DateTimeCell />} sortable='timeCreated' />
				<List.Col align='right' onlyHover width={180}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Requeue'
						icon='Repeat'
						size='sm'
						elevate
						key='requeue'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Spy' key='ignore'>
							Ignore
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

CreditCardErrorLog.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default CreditCardErrorLog;
