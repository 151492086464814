import React from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';

import FormContainer from '../../reusables/template/FormContainer';
import PaymentScheduleForm from './PaymentScheduleForm';

const PaymentScheduleEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='paymentSchedules'
			fields={<PaymentScheduleForm />}
			pageTitle={pages.accounting.settings.paymentSchedules.text}
			icon={pages.accounting.settings.paymentSchedules.icon}
			lists={['enumCalculations']}
		/>
	);
};

PaymentScheduleEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default PaymentScheduleEdit;
