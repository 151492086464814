import React from 'react';
import PropTypes from 'prop-types';
import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';
import PowerMeterTypeForm from './PowerMeterTypeForm';

const PowerMeterTypeEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='powerMeterTypes'
			fields={<PowerMeterTypeForm />}
			lists={[]}
			pageTitle={pages.campground.settings.powerMeterTypes.text}
			icon={pages.campground.settings.powerMeterTypes.icon}
			module={modules.CAMPGROUND}
		/>
	);
};

PowerMeterTypeEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default PowerMeterTypeEdit;
