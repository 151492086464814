import React from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';

import CalendarForm from './CalendarForm';
import FormContainer from '../../reusables/template/FormContainer';

const CalendarEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id.toString()}
			dataName={pages.systemSettings.calendars.route}
			fields={<CalendarForm />}
			pageTitle={pages.systemSettings.calendars.text}
			icon={pages.systemSettings.calendars.icon}
		/>
	);
};

CalendarEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default CalendarEdit;
