import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';
import Portlet from '../layout/Portlet';

import Button from '../element/Button';
import Radio from '../field/Radio';
import Portal from '../layout/Portal';
import QuickPanel from '../../QuickPanel';
import { mediaBreakpoint } from '../../../utils/constants/constants';
import { useWindowSize } from '../../../utils/helpers/helper';

const _title = 'Sales Tax Code';
const _icon = 'Sale#1';

const TaxCodeModal = ({ taxCodes, selectedTaxCode, setSelectedTaxCode, onClose, isOpen }) => {
	const windowSize = useWindowSize();

	if (windowSize.width < mediaBreakpoint.MD) {
		return (
			<QuickPanel status={isOpen} setStatus={onClose} title={_title} icon={_icon}>
				<TaxCodeModalContent
					taxCodes={taxCodes}
					selectedTaxCode={selectedTaxCode}
					setSelectedTaxCode={setSelectedTaxCode}
					onClose={onClose}
					withOutPortlet
				/>
			</QuickPanel>
		);
	}
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<TaxCodeModalContent
					taxCodes={taxCodes}
					selectedTaxCode={selectedTaxCode}
					setSelectedTaxCode={setSelectedTaxCode}
					onClose={onClose}
				/>
			</Popup>
		</Portal>
	);
};
TaxCodeModal.propTypes = {
	taxCodes: PropTypes.arrayOf(PropTypes.object).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	selectedTaxCode: PropTypes.object,
	setSelectedTaxCode: PropTypes.func.isRequired,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
};
TaxCodeModal.defaultProps = {
	selectedTaxCode: null,
	isOpen: false,
};

const TaxCodeModalContent = ({
	taxCodes,
	selectedTaxCode,
	setSelectedTaxCode,
	onClose,
	withOutPortlet,
}) => {
	const [taxCode, setTaxCode] = useState(selectedTaxCode);

	const _Inner = (
		<>
			<Portlet.Body>
				{taxCodes.map(t => {
					return (
						<Radio
							color='primary'
							key={t.id}
							id={t.id.toString()}
							name='tax'
							content={t.name}
							checked={taxCode === null ? false : taxCode.id === t.id}
							onChange={() => {
								setTaxCode(t);
							}}
						/>
					);
				})}
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Close'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={() => {
							onClose();
						}}
					/>
				</div>
				<div className='col-auto'>
					<Button
						design='brand'
						icon='Done-circle'
						text='Change'
						size='sm'
						onClick={() => setSelectedTaxCode(taxCode)}
					/>
				</div>
			</Portlet.Foot>
		</>
	);

	if (withOutPortlet) {
		return _Inner;
	}
	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon={_icon}>{_title}</Portlet.HeadLabelTitle>
			</Portlet.Head>
			{_Inner}
		</Portlet>
	);
};
TaxCodeModalContent.propTypes = {
	taxCodes: PropTypes.arrayOf(PropTypes.object).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	selectedTaxCode: PropTypes.object,
	setSelectedTaxCode: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	withOutPortlet: PropTypes.bool,
};
TaxCodeModalContent.defaultProps = {
	selectedTaxCode: null,
	withOutPortlet: false,
};

export default TaxCodeModal;
