import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getReportPath, getReportText } from '../../../utils/helpers/helper';
import HeaderContext from '../../../app/contexts/HeaderContext';
import Badge from '../../reusables/element/Badge';
import NullBadge from '../../reusables/design/NullBadge';
import pages from '../../pages';
import forms from '../../forms';
import { reportTypes } from '../../../utils/constants/constants';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';

const TypeCell = ({ data }) => {
	if (data.type === null) return <NullBadge />;
	return (
		<>
			<Badge design='brand' isDot>
				{data.type.value}
			</Badge>
		</>
	);
};
TypeCell.propTypes = {
	data: PropTypes.shape({
		type: PropTypes.object,
		value: PropTypes.string,
	}),
};
TypeCell.defaultProps = {
	data: {
		type: null,
		value: null,
	},
};

const ReportsList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	const customActions = {
		get: report => {
			history.push({
				pathname: `${getReportPath(history.location.pathname, report.type)}/get`,
				state: {
					report,
				},
			});
		},
		copy: report => {
			history.push({
				pathname: `${getReportPath(history.location.pathname, report.type)}/0`,
				state: {
					report,
				},
			});
		},
	};

	const getReportForms = () => {
		// newreporttype
		const reportModule = history.location.pathname.toString().split('/');
		switch (reportModule[2]) {
			case pages.reports.accounting.path.toString().split('/')[2]:
				return forms.reports.accounting;
			case pages.reports.campground.path.toString().split('/')[2]:
				return forms.reports.campground;
			case pages.reports.crm.path.toString().split('/')[2]:
				return forms.reports.crm;
			case pages.reports.pos.path.toString().split('/')[2]:
				return forms.reports.pos;
			case pages.reports.booking.path.toString().split('/')[2]:
				return forms.reports.booking;
			case pages.reports.marina.path.toString().split('/')[2]:
				return forms.reports.marina;
			default:
				return [];
		}
	};

	const getStaticFilters = () => {
		// newreporttype
		switch (history.location.pathname) {
			case pages.reports.accounting.path:
				return {
					'type.value[]': [
						reportTypes.DEFERRED_INCOME,
						reportTypes.GENERAL_LEDGER,
						reportTypes.PAYROLL,
						reportTypes.SETTLEMENT_DETAILS,
						reportTypes.DEPOSIT_BALANCES,
						reportTypes.RESERVATION_DAILY_REVENUE,
					],
				};
			case pages.reports.campground.path:
				return {
					'type.value[]': [
						reportTypes.CAMPGROUND_RESERVATIONS,
						reportTypes.CAMPGROUND_RENT_ROLL,
					],
				};
			case pages.reports.crm.path:
				return {
					'type.value[]': [
						reportTypes.CRM_CUSTOMERS,
						reportTypes.CRM_BALANCE_DETAILS,
						reportTypes.CRM_INVOICES,
						reportTypes.CRM_INVOICE_DETAILS,
						reportTypes.CRM_PAYMENTS,
					],
				};
			case pages.reports.pos.path:
				return {
					'type.value[]': [
						reportTypes.ZOUT,
						reportTypes.POS_ORDERS,
						reportTypes.POS_ORDERDETAILS,
						reportTypes.POS_ORDERMODIFIERS,
						reportTypes.POS_TIPS,
					],
				};
			case pages.reports.booking.path:
				return {
					'type.value[]': [reportTypes.BOOKING_RESERVATIONS],
				};
			case pages.reports.marina.path:
				return {
					'type.value[]': [
						reportTypes.MARINA_RESERVATIONS,
						reportTypes.MARINA_RENT_ROLL,
						reportTypes.MARINA_VESSELS,
					],
				};
			default:
				return null;
		}
	};

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.reports.default.text, path: pages.reports.dashboard.path },
			{ title: getReportText(history.location.pathname), isActive: true },
		]);

		headerContext.setPageTitle(getReportText(history.location.pathname));
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='reports'
			title={getReportText(history.location.pathname)}
			history={history}
			forms={getReportForms()}
			staticFilters={getStaticFilters()}
			customActions={customActions}
			checkEditable={reports => {
				if (reports.filter(r => r.isSystem).length > 0) return [];

				return ['delete'];
			}}>
			<List
				withCheckBox
				fluid='fluid'
				checkEditable={report => {
					if (report.isSystem) return ['get', 'copy'];

					return ['edit', 'delete', 'get', 'copy'];
				}}>
				<List.Col label='Name' cellData='name' sortable='name' isLinkColumn />
				<List.Col label='Type' cellComponent={<TypeCell />} sortable='type.value' />
				<List.Col
					label='Created By'
					cellData='displayName'
					cellDataObject='createdBy'
					sortable='createdBy.displayName'
					isLinkColumn
				/>
				<List.Col align='right' width={200}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Get Report'
						icon='Export'
						size='sm'
						elevate
						key='get'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item icon='Edit' key='edit'>
							Edit
						</Dropdown.Item>
						<Dropdown.Item icon='Duplicate' key='copy'>
							Copy
						</Dropdown.Item>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

ReportsList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
		location: PropTypes.shape({
			pathname: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
};

export default ReportsList;
