import React, { useContext } from 'react';
import CompanyForm from './CompanyForm';
import FormContainer from '../../reusables/template/FormContainer';
import UserContext from '../../../app/contexts/UserContext';

const CompanyEdit = () => {
	// Use current user to get company data.
	const userContext = useContext(UserContext);

	return (
		<FormContainer
			dataId={userContext.data.user.company.id.toString()}
			pageTitle='Company Name'
			dataName='companies'
			fields={<CompanyForm />}
			showSaveButton={false}
		/>
	);
};

export default CompanyEdit;
