import React from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';

import FormContainer from '../../reusables/template/FormContainer';
import BookingTypeForm from './BookingTypeForm';

const BookingTypeEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='bookingTypeMarinas'
			lists={[
				'enumBookBies',
				'enumSearchTypes',
				'unitMaps',
				'productFilters',
				'enumSearchResultViews',
				'templates',
			]}
			fields={<BookingTypeForm />}
			pageTitle={pages.marina.settings.bookingType.text}
			icon={pages.marina.settings.bookingType.icon}
			module={modules.MARINA}
		/>
	);
};

BookingTypeEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default BookingTypeEdit;
