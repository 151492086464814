import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import {
	addErrorNotification,
	addSuccessNotification,
	dateFormatter,
} from '../../../utils/helpers/helper';
import apiCall, { parseData } from '../../../utils/helpers/apiCall';
import MessageForm from '../../modules/messages/MessageForm';
import { messageStatuses } from '../../../utils/constants/constants';
import logoLoading from '../../../assets/img/logoLoading.svg';

const Message = ({ data, onClick }) => {
	return (
		<div
			role='presentation'
			className={classNames('sdms-row sdms-message', {
				'sdms-message-incoming': data.isIncoming,
			})}
			onClick={onClick}>
			<div className='sdms-message-content'>
				<span className='sdms-message-subject'>
					{data.fromEmail} - {data.subject}
				</span>
				<br />
				{data.preview && (
					<div>
						<span className='sdms-message-preview'>{data.preview}</span>
						<br />
					</div>
				)}
				<span className='sdms-message-date'>{dateFormatter(data.date)}</span>
			</div>
		</div>
	);
};

Message.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
};

const Messages = ({ onClose, inquiryObject, onNewInquiry }) => {
	const [isLoading, setIsLoading] = useState(true);

	const [isLoadingMessage, setIsLoadingMessage] = useState(true);

	const [selectedMessage, setSelectedMessage] = useState(null);

	const [emailAddresses, setEmailAddresses] = useState([]);

	const [isValid, setIsValid] = useState(false);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [inquiry, setInquiry] = useState(inquiryObject.inquiry);

	const title = useMemo(() => {
		if (selectedMessage)
			return `${selectedMessage?.subject || 'New Message'} - ${dateFormatter(
				selectedMessage.date
			)}`;

		if (inquiry) return inquiry.subject;

		return 'Messages';
	}, [inquiry, selectedMessage]);

	const onSelect = messageId => {
		setIsLoadingMessage(true);
		apiCall(
			'GET',
			'messages',
			res => {
				setSelectedMessage(res);
				setIsLoadingMessage(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoadingMessage(false);
			},
			messageId,
			null,
			{ 'groups[]': 'message:read' }
		);
	};
	const onSend = () => {
		setIsLoadingMessage(true);

		const formData = JSON.parse(JSON.stringify(selectedMessage));

		Object.keys(formData).forEach(field => {
			formData[field] = parseData(formData[field], null, true);
		});

		apiCall(
			'POST',
			'sendMessage',
			res => {
				addSuccessNotification('Message successfully sent');
				setSelectedMessage(res);
				if (!inquiryObject.inquiry) onNewInquiry(res.inquiry);
				onReload(res.inquiry.id);
				setSelectedMessage(null);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoadingMessage(false);
			},
			'',
			formData
		);
	};
	const onReload = id => {
		apiCall(
			'GET',
			'inquiries',
			res => {
				setInquiry(res);
				setIsLoading(false);
				setIsLoadingMessage(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
				setIsLoadingMessage(false);
			},
			id
		);
	};

	useEffect(() => {
		apiCall(
			'GET',
			'emailAddresses',
			res => {
				setEmailAddresses(res);
				setIsLoadingMessage(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoadingMessage(false);
			}
		);

		if (inquiryObject.inquiry) onReload(inquiryObject.inquiry.id);
		else {
			setIsLoading(false);
			setIsLoadingMessage(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='sdms-quick-panel sdms-quick-panel--right sdms-quick-panel--on sdms-quick-panel-large'>
			{isLoading ? (
				<Portlet className={classNames('flex-grow-0')} fluid='fluid'>
					<Portlet.Body>
						<ReactSVG src={logoLoading} className='svg-container logo-loading' />
					</Portlet.Body>
				</Portlet>
			) : (
				<Portlet hasFrame fluid='fluid' className='sdms-marginless h-100'>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon='Mail-box'>
							{title}
						</Portlet.HeadLabelTitle>
						<Portlet.HeadToolbarActions>
							{selectedMessage ? (
								<Button
									design='primary'
									text='Back'
									icon='Undo'
									size='sm'
									elevate
									onClick={() => setSelectedMessage(null)}
									disabled={isLoadingMessage}
								/>
							) : (
								<Button
									design='primary'
									text='New'
									icon='Plus'
									size='sm'
									elevate
									onClick={() => {
										setIsSubmitted(false);
										setSelectedMessage({
											id: 0,
											key: Date.now() * -1,
											status: { value: messageStatuses.UNREAD },
											inquiryId: inquiry?.id || 0,
											inquiryObject,
										});
									}}
									disabled={isLoadingMessage}
								/>
							)}
						</Portlet.HeadToolbarActions>
					</Portlet.Head>
					<Portlet.Body>
						{selectedMessage ? (
							<MessageForm
								key={selectedMessage.key || selectedMessage.id}
								data={selectedMessage}
								isSubmitted={isSubmitted}
								isLoading={isLoadingMessage}
								isInPanel
								emailAddresses={emailAddresses}
								setIsValid={setIsValid}
							/>
						) : (
							(inquiry?.messages || []).map(m => (
								<Message
									data={m}
									onClick={() => {
										if (!isLoadingMessage) onSelect(m.id);
									}}
								/>
							))
						)}
					</Portlet.Body>
					<Portlet.Foot tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Close'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
								disabled={isLoadingMessage}
							/>
						</div>
						{selectedMessage && selectedMessage.isIncoming && (
							<div className='col-auto'>
								<Button
									label='brand'
									text='Reply'
									icon='Send'
									size='sm'
									onClick={() => {
										setIsSubmitted(false);
										setSelectedMessage({
											id: 0,
											subject: selectedMessage.subject,
											key: selectedMessage.id * Date.now() * -1,
											replyMessage: selectedMessage,
											status: { value: messageStatuses.UNREAD },
											inquiryId: inquiry.id,
										});
									}}
								/>
							</div>
						)}
						{selectedMessage && selectedMessage.id === 0 && (
							<div className='col-auto'>
								<Button
									label='brand'
									text='Send'
									icon='Send'
									size='sm'
									onClick={() => {
										setIsSubmitted(true);
										if (isValid) onSend();
									}}
								/>
							</div>
						)}
					</Portlet.Foot>
				</Portlet>
			)}
		</div>
	);
};

Messages.propTypes = {
	onClose: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	inquiryObject: PropTypes.object,
	onNewInquiry: PropTypes.func,
};

Messages.defaultProps = {
	inquiryObject: null,
	onNewInquiry: () => {},
};

export default Messages;
