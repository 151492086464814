import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { pathToUrl } from '../../../utils/helpers/apiCall';
import { getUserRoleLevel, randomColor } from '../../../utils/helpers/helper';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import Badge from '../../reusables/element/Badge';
import NullBadge from '../../reusables/design/NullBadge';
import pages from '../../pages';
import forms from '../../forms';

const UserCell = ({ data }) => {
	if (data.profilePicture) {
		return (
			<img
				alt={data.displayName}
				src={
					data.profilePicture &&
					data.profilePicture.thumb &&
					pathToUrl(data.profilePicture.thumb)
				}
			/>
		);
	}
	return (
		<Badge
			design={randomColor(data.id)}
			className='sdms-badge--username'
			isUnified
			size='lg'
			isRounded
			fontWeight='bold'>
			{data.firstName.charAt(0) + data.lastName.charAt(0)}
		</Badge>
	);
};
UserCell.propTypes = {
	data: PropTypes.shape({
		profilePicture: PropTypes.object,
		displayName: PropTypes.string,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		id: PropTypes.number,
	}),
};
UserCell.defaultProps = {
	data: {
		profilePicture: null,
		displayName: null,
		firstName: null,
		lastName: null,
		id: 0,
	},
};

const NameCell = ({ data, onClick, hasPermission }) => {
	return (
		<span onClick={hasPermission ? onClick : null} role='presentation'>
			<span
				className={classNames('sdms-font-bold', {
					'sdms-link sdms-link--dark': hasPermission,
				})}>
				{data.displayName}
			</span>
			<div className='sdms-font-sm sdms-list-layout__small-text'>{`${data.firstName} ${data.lastName}`}</div>
		</span>
	);
};
NameCell.propTypes = {
	data: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		displayName: PropTypes.string,
	}),
	onClick: PropTypes.func,
	hasPermission: PropTypes.bool,
};
NameCell.defaultProps = {
	data: {
		firstName: null,
		lastName: null,
		displayName: null,
	},
	onClick: () => {},
	hasPermission: null,
};

const MailCell = ({ data }) => {
	if (data.email === null) return <NullBadge />;
	return <a href={`mailto:${data.email}`} className='sdms-link'>{`${data.email}`}</a>;
};
MailCell.propTypes = {
	data: PropTypes.shape({
		email: PropTypes.string,
	}),
};
MailCell.defaultProps = {
	data: {
		email: null,
	},
};

const RolesCell = ({ data }) => {
	const userContext = useContext(UserContext);

	if (data.isOwner)
		return (
			<Badge
				key={`owner${data.id}`}
				design='warning'
				isInline
				isUnified
				size='lg'
				fontWeight='bold'>
				Owner
			</Badge>
		);

	if (data.isInstallerUser)
		return (
			<Badge
				key={`installer${data.id}`}
				design='warning'
				isInline
				isUnified
				size='lg'
				fontWeight='bold'>
				Installer
			</Badge>
		);

	return data.userRoleOutlets
		.filter(uro => uro.outlet.id === userContext.data.selectedOutlet.id)
		.map(uro => {
			return (
				<Badge
					key={uro.role.name}
					design='info'
					isInline
					isUnified
					size='lg'
					fontWeight='bold'>
					{uro.role.name}
				</Badge>
			);
		})
		.sort();
};
RolesCell.defaultProps = {
	data: PropTypes.shape({
		userRoleOutlets: PropTypes.array,
		isOwner: PropTypes.bool,
		isInstallerUser: PropTypes.bool,
	}),
};
RolesCell.defaultProps = {
	data: {
		userRoleOutlets: [],
		isOwner: false,
		isInstallerUser: false,
	},
};

const UserList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const currentUserRoleLevel = useRef(
		getUserRoleLevel(userContext.data.user, userContext.data.selectedOutlet)
	);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.systemSettings.default.text,
				path: pages.systemSettings.dashboard.path,
			},
			{ title: pages.systemSettings.users.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.systemSettings.users.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='users'
			history={history}
			title={pages.systemSettings.users.text}
			checkEditable={users => {
				let actions = ['delete'];

				if (userContext.data.user.isOwner || userContext.data.user.isInstallerUser)
					return actions;

				users.every(user => {
					if (
						!currentUserRoleLevel.current ||
						user.id === userContext.data.user.id ||
						currentUserRoleLevel.current <
							getUserRoleLevel(user, userContext.data.selectedOutlet)
					) {
						actions = [];
						return false;
					}
					return true;
				});

				return actions;
			}}
			forms={forms.systemSettings.users}>
			<List
				withCheckBox
				checkEditable={user => {
					if (userContext.data.user.id === user.id) return ['edit'];

					if (userContext.data.user.isOwner || userContext.data.user.isInstallerUser)
						return ['edit', 'delete'];

					return currentUserRoleLevel.current &&
						currentUserRoleLevel.current >=
							getUserRoleLevel(user, userContext.data.selectedOutlet)
						? ['edit', 'delete']
						: [];
				}}
				fluid='fluid'
				withImage>
				<List.Col label='Image' cellComponent={<UserCell />} width={70} />
				<List.Col
					label='Name'
					cellComponent={
						<NameCell hasPermission={userContext.hasPermission('edit_users')} />
					}
					isLinkColumn
					sortable='displayName'
				/>
				<List.Col label='Email' cellComponent={<MailCell />} sortable='email' />
				<List.Col label='Roles' cellComponent={<RolesCell />} />
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
						noPermission={!userContext.hasPermission('edit_users')}
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item
							itemsColor='danger'
							icon='Trash'
							key='delete'
							noPermission={!userContext.hasPermission('delete_users')}>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
UserList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default UserList;
