import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import useField from '../../../utils/hooks/useField';
import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';
import { maxLength, numeric, required } from '../../../utils/helpers/validation';
import { numberParser } from '../../../utils/helpers/helper';
import { bookingPeriods, pricingTypes } from '../../../utils/constants/constants';

import Portlet from '../../reusables/layout/Portlet';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Loading from '../../reusables/template/Loading';
import Input from '../../reusables/field/Input';
import Radio from '../../reusables/field/Radio';
import Notes from '../../reusables/element/Notes';
import MultiSelect from '../../reusables/element/MultiSelect';
import Selects from '../../reusables/field/Selects';
import Button from '../../reusables/element/Button';
import Toggle from '../../reusables/field/Toggle';

const RatePlanForm = ({
	isLoading,
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	onFormChange,
	enumBookingPeriods,
	enumPricingTypes,
	enumDays,
	policies,
	submit,
	submitButtonAttr,
}) => {
	const headerContext = useContext(HeaderContext);

	const pages = usePages();

	const [showMinDurationInput, setShowMinDurationInput] = useState(false);

	const [showMaxDurationInput, setShowMaxDurationInput] = useState(false);

	const [showMinBookingInAdvanceInput, setShowMinBookingInAdvanceInput] = useState(false);

	const [showMaxBookingInAdvanceInput, setShowMaxBookingInAdvanceInput] = useState(false);

	const [showCheckInDaysInput, setShowCheckInDaysInput] = useState(false);

	const [showCheckOutDaysInput, setShowCheckOutDaysInput] = useState(false);

	const [showMinUnitsInput, setShowMinUnitsInput] = useState(false);

	const [showMaxUnitsInput, setShowMaxUnitsInput] = useState(false);

	const userContext = useContext(UserContext);

	const bookingModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.BOOKINGS)
	);

	const [
		internalName,
		internalNameOnChange,
		internalNameValRes,
		internalNameShowVal,
		setInternalNameShowVal,
	] = useField(data, 'internalName', onFormChange, [required, maxLength()], '', null, setTitle);

	const [
		externalName,
		externalNameOnChange,
		externalNameValRes,
		externalNameShowVal,
		setExternalNameShowVal,
	] = useField(data, 'externalName', onFormChange, [required, maxLength()], '', null);
	const [
		bookingPeriod,
		bookingPeriodOnChange,
		bookingPeriodValRes,
		bookingPeriodShowVal,
		setBookingPeriodShowVal,
	] = useField(data, 'bookingPeriod', onFormChange, [required], null);

	const [policy, policyOnChange, policyValRes, policyShowVal, setPolicyShowVal] = useField(
		data,
		'policy',
		onFormChange,
		[],
		{}
	);

	const [pricingType, pricingTypeOnChange] = useField(data, 'pricingType', onFormChange);

	const [costPer, costPerOnChange, costPerValRes, costPerShowVal, setCostPerShowVal] = useField(
		data,
		'costPer',
		onFormChange,
		[numeric, required],
		1,
		numberParser(true, '')
	);

	const [
		minDuration,
		minDurationOnChange,
		minDurationValRes,
		minDurationShowVal,
		setMinDurationShowVal,
	] = useField(data, 'minDuration', onFormChange, [numeric], '', numberParser(false));

	const [
		maxDuration,
		maxDurationOnChange,
		maxDurationValRes,
		maxDurationShowVal,
		setMaxDurationShowVal,
	] = useField(data, 'maxDuration', onFormChange, [numeric], '', numberParser(false));

	const [
		minBookInAdvance,
		minBookInAdvanceOnChange,
		minBookInAdvanceValRes,
		minBookInAdvanceShowVal,
		setMinBookInAdvanceShowVal,
	] = useField(data, 'minBookInAdvance', onFormChange, [numeric], '', numberParser(false));

	const [
		maxBookInAdvance,
		maxBookInAdvanceOnChange,
		maxBookInAdvanceValRes,
		maxBookInAdvanceShowVal,
		setMaxBookInAdvanceShowVal,
	] = useField(data, 'maxBookInAdvance', onFormChange, [numeric], '', numberParser(false));

	const [checkInDays, checkInDaysOnChange] = useField(data, 'checkInDays', onFormChange, [], []);

	const [checkOutDays, checkOutDaysOnChange] = useField(
		data,
		'checkOutDays',
		onFormChange,
		[],
		[]
	);

	const [isDiscountRatePlan, isDiscountRatePlanOnChange] = useField(
		data,
		'isDiscountRatePlan',
		onFormChange,
		[],
		false
	);

	const [minUnits, minUnitsOnChange] = useField(
		data,
		'minUnits',
		onFormChange,
		[],
		'',
		numberParser(true)
	);

	const [maxUnits, maxUnitsOnChange] = useField(
		data,
		'maxUnits',
		onFormChange,
		[],
		'',
		numberParser(true)
	);

	const bookingPeriodFinder = () => {
		if (bookingPeriod) {
			if (bookingPeriod.value === bookingPeriods.HOURLY) return 'Hour(s)';
			if (bookingPeriod.value === bookingPeriods.NIGHTLY) return 'Night(s)';
		}
		return 'Day(s)';
	};

	const getPricingTypeLabel = _pricingType => {
		return _pricingType.value === pricingTypes.PER_UNIT
			? `Cost Per 1 ${bookingPeriodFinder()}`
			: `Cost based on number of ${bookingPeriodFinder()}`;
	};

	useEffect(() => {
		if (isSubmitted) {
			setInternalNameShowVal();
			setExternalNameShowVal();
			setBookingPeriodShowVal();
			setCostPerShowVal();
			setMinDurationShowVal();
			setMaxDurationShowVal();
			setMinBookInAdvanceShowVal();
			setMaxBookInAdvanceShowVal();
		}
	}, [
		isSubmitted,
		setInternalNameShowVal,
		setExternalNameShowVal,
		setBookingPeriodShowVal,
		setCostPerShowVal,
		setMinDurationShowVal,
		setMaxDurationShowVal,
		setMinBookInAdvanceShowVal,
		setMaxBookInAdvanceShowVal,
	]);

	useEffect(() => {
		setIsValid(
			internalNameValRes.isValid &&
				externalNameValRes.isValid &&
				bookingPeriodValRes.isValid &&
				costPerValRes.isValid &&
				minDurationValRes.isValid &&
				maxDurationValRes.isValid &&
				minBookInAdvanceValRes.isValid &&
				maxBookInAdvanceValRes.isValid
		);
	}, [
		internalNameValRes.isValid,
		externalNameValRes.isValid,
		bookingPeriodValRes.isValid,
		costPerValRes.isValid,
		minDurationValRes.isValid,
		maxDurationValRes.isValid,
		minBookInAdvanceValRes.isValid,
		maxBookInAdvanceValRes.isValid,
		setIsValid,
	]);
	useEffect(() => {
		data.module = bookingModule.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!isLoading) {
			if (data.minDuration) setShowMinDurationInput(true);
			if (data.maxDuration) setShowMaxDurationInput(true);
			if (data.minBookInAdvance) setShowMinBookingInAdvanceInput(true);
			if (data.maxBookInAdvance) setShowMaxBookingInAdvanceInput(true);
			if (data.checkInDays && data.checkInDays.length > 0) setShowCheckInDaysInput(true);
			if (data.checkOutDays && data.checkOutDays.length > 0) setShowCheckOutDaysInput(true);
			if (data.minUnits !== null) setShowMinUnitsInput(true);
			if (data.maxUnits !== null) setShowMaxUnitsInput(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.booking.default.text, path: pages.booking.dashboard.path },
			{ title: pages.booking.settings.text, path: pages.booking.settings.path },
			{
				title: pages.booking.settings.ratePlans.text,
				path: pages.booking.settings.ratePlans.path,
			},
			{
				title: internalName || `New ${pages.booking.settings.ratePlans.text}`,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(internalName || `New ${pages.booking.settings.ratePlans.text}`);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [internalName]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<FormGroup isLast>
					<div className='col-12'>
						<Notes>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='internalName'
										label='What do you want to call this rate plan?'
										description={`This name is just for you. We won't show it to customers using Online Booking.`}
										id={data.id}
										valRes={internalNameValRes}
										showValidation={internalNameShowVal}
										inFormDesign={false}
										colLg={6}>
										<Input
											type='text'
											value={internalName}
											onChange={internalNameOnChange}
											onBlur={setInternalNameShowVal}
										/>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='externalName'
										label='How do you want customers to see this rate plan?'
										description='This name will be visible to users when using Online Booking.'
										id={data.id}
										valRes={externalNameValRes}
										showValidation={externalNameShowVal}
										inFormDesign={false}
										colLg={6}>
										<Input
											type='text'
											value={externalName}
											onChange={externalNameOnChange}
											onBlur={setExternalNameShowVal}
										/>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='bookingPeriod'
										label='How do you want to price this rate plan?'
										description='This will create cost break points. For example, $200 for 4 hours will calculate cost at $50/hour.'
										id={data.id}
										valRes={bookingPeriodValRes}
										showValidation={bookingPeriodShowVal}
										inFormDesign={false}
										colLg={6}>
										<Selects
											options={enumBookingPeriods}
											placeholder='Booking Periods (Required)'
											value={bookingPeriod}
											onChange={e => {
												policyOnChange({
													target: { value: null },
												});

												costPerOnChange({ target: { value: 1 } });

												maxDurationOnChange({
													target: { value: null },
												});
												minDurationOnChange({
													target: { value: null },
												});
												pricingTypeOnChange({
													target: {
														value: e.target.value
															? enumPricingTypes.filter(
																	ept =>
																		e.target.value &&
																		ept.bookingPeriods.findIndex(
																			bp =>
																				bp.value ===
																				e.target.value.value
																		) > -1
															  )[0]
															: null,
													},
												});

												bookingPeriodOnChange(e);
											}}
											onBlur={setBookingPeriodShowVal}
											displayKey='value'
										/>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='policy'
										label='Which policy do you want to use for this rate plan?'
										id={data.id}
										valRes={policyValRes}
										showValidation={policyShowVal}
										inFormDesign={false}
										colLg={6}>
										<Selects
											options={policies.filter(
												pol =>
													bookingPeriod &&
													pol.bookingPeriod.value === bookingPeriod.value
											)}
											placeholder='Booking Policy'
											value={policy}
											onChange={e => {
												policyOnChange({
													target: { value: e.target.value },
												});
											}}
											onBlur={setPolicyShowVal}
											displayKey='name'
										/>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='costPer'
										label='How do you want to set the price for this rate plan?'
										description='This will create cost break points. For example, $200 for 4 hours will calculate cost at $50/hour.'
										id={data.id}
										valRes={costPerValRes}
										showValidation={costPerShowVal}
										inFormDesign={false}>
										<Radio.Container isInline>
											{enumPricingTypes.map(ept => (
												<Radio
													key={ept.id}
													checked={
														pricingType && ept.id === pricingType.id
													}
													id={ept.id.toString()}
													name='pricingType'
													content={getPricingTypeLabel(ept)}
													className='sdms-radio--primary'
													onChange={() => {
														pricingTypeOnChange({
															target: { value: ept },
														});
														if (ept.value === pricingTypes.PER_UNIT)
															costPerOnChange({
																target: { value: 1 },
															});
													}}
												/>
											))}
											{pricingType &&
												pricingType.value ===
													pricingTypes.NUMBER_OF_UNIT && (
													<div className='row'>
														<div className='col-lg-12'>
															<Input
																type='text'
																value={costPer}
																onChange={costPerOnChange}
																onBlur={setCostPerShowVal}
																prepend='Total Price for'
																append={bookingPeriodFinder()}
																pattern={
																	process.env
																		.REACT_APP_INTEGER_PATTERN
																}
															/>
														</div>
													</div>
												)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='minDuration'
										label='Want to set a minimum rental length of time for this rate plan?'
										description='This is the minimum amount of time an item with this rate plan can be rented'
										id={data.id}
										valRes={minDurationValRes}
										showValidation={minDurationShowVal}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMinDurationInput}
												id='showMinDurationInputNo'
												name='showMinDurationInput'
												content='No'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMinDurationInput(false);
													minDurationOnChange({
														target: {
															value: null,
														},
													});
												}}
											/>
											<Radio
												checked={showMinDurationInput}
												id='showMinDurationInputYes'
												name='showMinDurationInput'
												content='Yes'
												className='sdms-radio--primary'
												onChange={() => setShowMinDurationInput(true)}
											/>
											{showMinDurationInput && (
												<div className='row'>
													{bookingPeriod &&
													bookingPeriod.value === 'Hourly' ? (
														<>
															<div className='col-lg-6'>
																<Input
																	type='text'
																	value={
																		minDuration &&
																		minDuration !== ''
																			? parseInt(
																					parseFloat(
																						minDuration
																					) / 60,
																					10
																			  ).toString()
																			: 0
																	}
																	onChange={e => {
																		minDurationOnChange({
																			target: {
																				value:
																					e.target.value *
																						60 +
																					(minDuration ===
																					''
																						? 0
																						: minDuration %
																						  60),
																			},
																		});
																	}}
																	onBlur={setMinDurationShowVal}
																	append={bookingPeriodFinder()}
																	pattern={
																		process.env
																			.REACT_APP_INTEGER_PATTERN
																	}
																/>
															</div>
															<div className='col-lg-6'>
																<Selects
																	value={
																		minDuration === ''
																			? { id: 0, name: 0 }
																			: {
																					id:
																						minDuration %
																						60,
																					name:
																						minDuration %
																						60,
																			  }
																	}
																	onChange={e =>
																		minDurationOnChange({
																			target: {
																				value:
																					minDuration -
																					(minDuration %
																						60) +
																					parseFloat(
																						e.target
																							.value
																							? e
																									.target
																									.value
																									.id
																							: 0
																					),
																			},
																		})
																	}
																	options={[
																		{ id: 0, name: 0 },
																		{ id: 15, name: 15 },
																		{ id: 30, name: 30 },
																		{ id: 45, name: 45 },
																	]}
																	append='Minutes'
																/>
															</div>
														</>
													) : (
														<div className='col-lg-6'>
															<Input
																type='text'
																value={minDuration}
																onChange={minDurationOnChange}
																onBlur={setMinDurationShowVal}
																append={bookingPeriodFinder()}
															/>
														</div>
													)}
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='maxDuration'
										label='Want to set a maximum rental length of time for this rate plan?'
										description='This is the maximum amount of time an item with this rate plan can be rented'
										id={data.id}
										valRes={maxDurationValRes}
										showValidation={maxDurationShowVal}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMaxDurationInput}
												id='showMaxDurationInputNo'
												name='showMaxDurationInput'
												content='No'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMaxDurationInput(false);
													maxDurationOnChange({
														target: {
															value: null,
														},
													});
												}}
											/>
											<Radio
												checked={showMaxDurationInput}
												id='showMaxDurationInputYes'
												name='showMaxDurationInput'
												content='Yes'
												className='sdms-radio--primary'
												onChange={() => setShowMaxDurationInput(true)}
											/>
											{showMaxDurationInput && (
												<div className='row'>
													{bookingPeriod &&
													bookingPeriod.value === 'Hourly' ? (
														<>
															<div className='col-lg-6'>
																<Input
																	type='text'
																	value={
																		maxDuration &&
																		maxDuration !== ''
																			? parseInt(
																					parseFloat(
																						maxDuration
																					) / 60,
																					10
																			  ).toString()
																			: ''
																	}
																	onChange={e => {
																		maxDurationOnChange({
																			target: {
																				value:
																					e.target.value *
																						60 +
																					(maxDuration ===
																					''
																						? 0
																						: maxDuration %
																						  60),
																			},
																		});
																	}}
																	onBlur={setMaxDurationShowVal}
																	append='Hours'
																	pattern={
																		process.env
																			.REACT_APP_INTEGER_PATTERN
																	}
																/>
															</div>
															<div className='col-lg-6'>
																<Selects
																	value={
																		maxDuration === ''
																			? { id: 0, name: 0 }
																			: {
																					id:
																						maxDuration %
																						60,
																					name:
																						maxDuration %
																						60,
																			  }
																	}
																	onChange={e =>
																		maxDurationOnChange({
																			target: {
																				value:
																					maxDuration -
																					(maxDuration %
																						60) +
																					parseFloat(
																						e.target
																							.value
																							? e
																									.target
																									.value
																									.id
																							: 0
																					),
																			},
																		})
																	}
																	options={[
																		{ id: 0, name: 0 },
																		{ id: 15, name: 15 },
																		{ id: 30, name: 30 },
																		{ id: 45, name: 45 },
																	]}
																	append='Minutes'
																/>
															</div>
														</>
													) : (
														<div className='col-lg-6'>
															<Input
																type='text'
																value={maxDuration}
																onChange={maxDurationOnChange}
																onBlur={setMaxDurationShowVal}
																append='Days'
															/>
														</div>
													)}
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='minBookInAdvance'
										label='What is the minimum number of days before check-in can guests book this rate plan?'
										id={data.id}
										valRes={minBookInAdvanceValRes}
										showValidation={minBookInAdvanceShowVal}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMinBookingInAdvanceInput}
												id='showMinBookingInAdvanceInputNo'
												name='showMinBookingInAdvanceInput'
												content='Any Time'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMinBookingInAdvanceInput(false);
													minBookInAdvanceOnChange({
														target: { value: null },
													});
												}}
											/>
											<Radio
												checked={showMinBookingInAdvanceInput}
												id='showMinBookingInAdvanceInputYes'
												name='showMinBookingInAdvanceInput'
												content='Set number of days before check-in'
												className='sdms-radio--primary'
												onChange={() =>
													setShowMinBookingInAdvanceInput(true)
												}
											/>
											{showMinBookingInAdvanceInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<Input
															type='text'
															value={minBookInAdvance}
															onChange={minBookInAdvanceOnChange}
															onBlur={setMinBookInAdvanceShowVal}
															append='day(s) or less before check-in'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='maxBookInAdvance'
										label='What is the maximum number of days before check-in can guests book this rate plan?'
										id={data.id}
										valRes={maxBookInAdvanceValRes}
										showValidation={maxBookInAdvanceShowVal}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMaxBookingInAdvanceInput}
												id='showMaxBookingInAdvanceInputNo'
												name='showMaxBookingInAdvanceInput'
												content='Any Time'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMaxBookingInAdvanceInput(false);
													maxBookInAdvanceOnChange({
														target: { value: null },
													});
												}}
											/>
											<Radio
												checked={showMaxBookingInAdvanceInput}
												id='showMaxBookingInAdvanceInputYes'
												name='showMaxBookingInAdvanceInput'
												content='Set number of days before check-in'
												className='sdms-radio--primary'
												onChange={() =>
													setShowMaxBookingInAdvanceInput(true)
												}
											/>
											{showMaxBookingInAdvanceInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<Input
															type='text'
															value={maxBookInAdvance}
															onChange={maxBookInAdvanceOnChange}
															onBlur={setMaxBookInAdvanceShowVal}
															append='day(s) or less before check-in'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='checkInDay'
										label='Do you want to set check-in days for this rate plan?'
										id={data.id}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showCheckInDaysInput}
												id='showCheckInDaysInputNo'
												name='showCheckInDaysInput'
												content='Any Day'
												className='sdms-radio--primary'
												onChange={() => {
													setShowCheckInDaysInput(false);
													checkInDaysOnChange({
														target: { value: null },
													});
												}}
											/>
											<Radio
												checked={showCheckInDaysInput}
												id='showCheckInDaysInputYes'
												name='showCheckInDaysInput'
												content='Set days available for check-in'
												className='sdms-radio--primary'
												onChange={() => setShowCheckInDaysInput(true)}
											/>
											{showCheckInDaysInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<MultiSelect
															data={enumDays}
															titleProp='value'
															value={checkInDays}
															onChange={checkInDaysOnChange}
															name='checkInDays'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='checkOutDay'
										label='Do you want to set check-out days for this rate plan?'
										id={data.id}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showCheckOutDaysInput}
												id='showCheckOutDaysInputNo'
												name='showCheckOutDaysInput'
												content='Any Day'
												className='sdms-radio--primary'
												onChange={() => {
													setShowCheckOutDaysInput(false);
													checkOutDaysOnChange({
														target: { value: null },
													});
												}}
											/>
											<Radio
												checked={showCheckOutDaysInput}
												id='showCheckOutDaysInputYes'
												name='showCheckOutDaysInput'
												content='Set days available for check-out'
												className='sdms-radio--primary'
												onChange={() => setShowCheckOutDaysInput(true)}
											/>
											{showCheckOutDaysInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<MultiSelect
															data={enumDays}
															titleProp='value'
															value={checkOutDays}
															onChange={checkOutDaysOnChange}
															name='checkOutDays'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='minUnits'
										label='Want to set minimum units available?'
										id={data.id}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMinUnitsInput}
												id='showMinUnitsInputNo'
												name='showMinUnitsInput'
												content='No'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMinUnitsInput(false);
													minUnitsOnChange({
														target: { value: null },
													});
												}}
											/>
											<Radio
												checked={showMinUnitsInput}
												id='showMinUnitsInputYes'
												name='showMinUnitsInput'
												content='Yes'
												className='sdms-radio--primary'
												onChange={() => setShowMinUnitsInput(true)}
											/>
											{showMinUnitsInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<Input
															type='text'
															value={minUnits}
															onChange={minUnitsOnChange}
															append='Unit(s)'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item>
								<Loading isLoading={isLoading}>
									<FormField
										name='maxUnits'
										label='Want to set maximum units available?'
										id={data.id}
										inFormDesign={false}>
										<Radio.Container isInline>
											<Radio
												checked={!showMaxUnitsInput}
												id='showMaxUnitsInputNo'
												name='showMaxUnitsInput'
												content='No'
												className='sdms-radio--primary'
												onChange={() => {
													setShowMaxUnitsInput(false);
													maxUnitsOnChange({
														target: { value: null },
													});
												}}
											/>
											<Radio
												checked={showMaxUnitsInput}
												id='showMaxUnitsInputYes'
												name='showMaxUnitsInput'
												content='Yes'
												className='sdms-radio--primary'
												onChange={() => setShowMaxUnitsInput(true)}
											/>
											{showMaxUnitsInput && (
												<div className='row'>
													<div className='col-lg-6'>
														<Input
															type='text'
															value={maxUnits}
															onChange={maxUnitsOnChange}
															append='Unit(s)'
														/>
													</div>
												</div>
											)}
										</Radio.Container>
									</FormField>
								</Loading>
							</Notes.Item>
							<Notes.Item icon='Flag'>
								<Loading isLoading={isLoading}>
									<FormField
										name='isDiscountRatePlan'
										label='Is this a Discount Rate Plan?'
										description='If you check this box, this rate plan won’t show on search results unless conditions are met.'
										id={data.id}
										inFormDesign={false}>
										<Toggle
											onChange={isDiscountRatePlanOnChange}
											value={isDiscountRatePlan}
										/>
									</FormField>
								</Loading>
							</Notes.Item>
						</Notes>
					</div>
				</FormGroup>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
RatePlanForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		internalName: PropTypes.string,
		externalName: PropTypes.string,
		bookingPeriod: PropTypes.shape({
			id: PropTypes.number,
			value: PropTypes.string,
		}),
		policy: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
		}),
		costPer: PropTypes.number,
		minDuration: PropTypes.number,
		maxDuration: PropTypes.number,
		minBookInAdvance: PropTypes.number,
		maxBookInAdvance: PropTypes.number,
		module: PropTypes.object,
		checkInDays: PropTypes.array,
		checkOutDays: PropTypes.array,
		minUnits: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
		maxUnits: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
	}),
	isLoading: PropTypes.bool,
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	setTitle: PropTypes.func,
	onFormChange: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	policies: PropTypes.arrayOf(PropTypes.object),
	enumBookingPeriods: PropTypes.arrayOf(PropTypes.object),
	enumPricingTypes: PropTypes.arrayOf(PropTypes.object),
	enumDays: PropTypes.arrayOf(PropTypes.object),
	submit: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
};
RatePlanForm.defaultProps = {
	data: {
		id: 0,
		internalName: '',
		externalName: '',
		bookingPeriod: {
			id: 0,
			value: '',
		},
		policy: {
			id: 0,
			name: '',
		},
		costPer: 1,
		minDuration: 0,
		maxDuration: 0,
		minBookInAdvance: 0,
		maxBookInAdvance: 0,
		module: {},
		checkInDays: [],
		checkOutDays: [],
		minUnits: null,
		maxUnits: null,
	},
	isLoading: false,
	setIsValid: () => {},
	isSubmitted: false,
	setTitle: () => {},
	onFormChange: () => {},
	policies: [],
	enumBookingPeriods: [],
	enumPricingTypes: [],
	enumDays: [],
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default RatePlanForm;
