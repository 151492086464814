import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import useComponentSize from '@rehooks/component-size';
import classNames from 'classnames';

import useNumPad, { actions as numPadActions } from '../../../utils/hooks/useNumPad';
import { discountCalculations, mediaBreakpoint } from '../../../utils/constants/constants';
import { numberFormat, priceFormatter, useWindowSize } from '../../../utils/helpers/helper';

import Button from '../element/Button';
import Portlet from '../layout/Portlet';
import Radio from '../field/Radio';
import Input from '../field/Input';
import SVGIcon from '../element/SVGIcon';
import Portal from '../layout/Portal';
import ModalInput from './ModalInput';
import QuickPanel from '../../QuickPanel';

// this is for encapsulating the modal because of popup component.
// modal component can't see its child's refs (which we need to focus at popup modal initially)

const _title = 'Discount';
const _icon = 'Sale#2';

const DiscountModal = ({
	usedDiscount,
	updateDiscount,
	discounts,
	total,
	decimalLimit,
	onClose,
	discountPresets,
	calculations,
	isOpen,
	disableFixed,
	orderItems,
}) => {
	const windowSize = useWindowSize();

	if (windowSize.width < mediaBreakpoint.MD) {
		return (
			<QuickPanel status={isOpen} setStatus={onClose} title={_title} icon={_icon}>
				<DiscountModalContent
					usedDiscount={usedDiscount}
					updateDiscount={updateDiscount}
					discounts={discounts}
					total={total}
					decimalLimit={decimalLimit}
					onClose={onClose}
					discountPresets={discountPresets}
					calculations={calculations}
					disableFixed={disableFixed}
					withOutPortlet
					orderItems={orderItems}
				/>
			</QuickPanel>
		);
	}
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<DiscountModalContent
					usedDiscount={usedDiscount}
					updateDiscount={updateDiscount}
					discounts={discounts}
					total={total}
					decimalLimit={decimalLimit}
					onClose={onClose}
					discountPresets={discountPresets}
					calculations={calculations}
					disableFixed={disableFixed}
					orderItems={orderItems}
				/>
			</Popup>
		</Portal>
	);
};
DiscountModal.propTypes = {
	total: PropTypes.number,
	decimalLimit: PropTypes.number,
	onClose: PropTypes.func,
	updateDiscount: PropTypes.func,
	discounts: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	usedDiscount: PropTypes.object,
	discountPresets: PropTypes.arrayOf(PropTypes.string),
	calculations: PropTypes.arrayOf(PropTypes.object),
	isOpen: PropTypes.bool,
	disableFixed: PropTypes.bool,
	orderItems: PropTypes.arrayOf(PropTypes.object),
};
DiscountModal.defaultProps = {
	total: 1000,
	decimalLimit: 2,
	updateDiscount: () => '',
	onClose: () => '',
	discounts: [],
	discountPresets: [],
	calculations: [],
	isOpen: false,
	disableFixed: false,
	usedDiscount: null,
	orderItems: [],
};

const DiscountModalContent = ({
	usedDiscount,
	updateDiscount,
	discounts,
	total,
	decimalLimit,
	onClose,
	discountPresets,
	calculations,
	disableFixed,
	withOutPortlet,
	orderItems,
}) => {
	const refRadioContainer = useRef(null);
	const sizeRadioContainer = useComponentSize(refRadioContainer);

	const windowSize = useWindowSize();

	const availableDiscounts = useMemo(() => {
		if (orderItems.length !== 1)
			return discounts.filter(
				d => d.calculation.value !== discountCalculations.PER_QUANTITY && !d.inactive
			);

		return discounts.filter(d => {
			if (d.calculation.value === discountCalculations.PER_QUANTITY)
				return (
					d.eligibleProducts.some(p => p.id === orderItems[0].product.id) && !d.inactive
				);

			return !d.inactive;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderItems]);

	const availableCalculations = useMemo(() => {
		if (
			!availableDiscounts.some(d => d.calculation.value === discountCalculations.PER_QUANTITY)
		)
			return calculations.filter(c => c.value !== discountCalculations.PER_QUANTITY);

		return calculations;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availableDiscounts]);

	const defaultDiscount = useMemo(() => {
		if (disableFixed) {
			const percentDiscounts = availableDiscounts.filter(
				d => d.calculation.value === 'percent'
			);

			if (percentDiscounts.length) return availableDiscounts[0];
		} else if (availableDiscounts.length) return availableDiscounts[0];

		return null;
	}, [availableDiscounts, disableFixed]);

	const validate = (amount, type = discount.calculation.value) => {
		// decimalLimit will be prop, upperLimit will be your input amount
		if (amount === '') return '0';

		if (type === discountCalculations.PERCENT) {
			// if discount is in percentage type, it should be between 0 <= x <= 100
			if (parseFloat(amount) > 100) {
				return '100';
			}
			if (parseFloat(amount) < 0) return '0';
		}
		if (type === discountCalculations.FIXED || type === discountCalculations.PER_QUANTITY) {
			// if discount is in just integer number type. it shall not exceed 'total' value.
			if (parseFloat(amount) > total) return total.toString();
			if (parseFloat(amount) < 0) return '0';
		}

		if (amount.indexOf('.') !== -1 && amount.split('.')[1].length > decimalLimit)
			return discount.amount;

		return amount;
	};

	const validateDefaultFixedDiscount = (subTotal, discount) => {
		if (
			discount?.calculation?.value === discountCalculations.FIXED ||
			discount?.calculation?.value === discountCalculations.PER_QUANTITY
		) {
			return discount.amount >= subTotal ? subTotal.toString() : discount.amount.toString();
		}
		return discount?.amount.toString();
	};

	const [discount, setDiscount] = useState(
		usedDiscount
			? { ...usedDiscount, amount: usedDiscount.amount.toString() }
			: {
					...defaultDiscount,
					amount: validateDefaultFixedDiscount(total, defaultDiscount),
			  }
	);

	const [isSelected, setIsSelected] = useState(true);

	const [changed, setChanged] = useState(false);

	const generatedOffAmount = () => {
		if (discount.calculation.value === discountCalculations.PERCENT)
			return priceFormatter((parseFloat(discount.amount) / 100) * total);

		if (discount.calculation.value === discountCalculations.FIXED)
			return parseFloat(discount.amount) === 0 || total.amount === 0
				? '$0.00'
				: `${numberFormat((parseFloat(discount.amount) * 100) / total)}%`;

		return parseFloat(discount.amount) === 0 || total.amount === 0
			? '$0.00'
			: `${numberFormat(
					(parseFloat(discount.amount) * (orderItems[0]?.quantity || 1) * 100) / total
			  )}%`;
	};

	const applyPresetDiscount = value => {
		setDiscount({
			...discount,
			type: calculations.find(c => c.value === 'percent'),
			amount: validate(value, 'percent'),
		});
	};

	const [applyInput] = useNumPad(
		discount.amount,
		newValue => {
			setIsSelected(false);
			setChanged(true);
			setDiscount({ ...discount, amount: newValue });
		},
		0,
		total,
		0,
		2,
		() => {
			updateDiscount({ ...discount, amount: parseFloat(discount.amount) });
		},
		validate
	);

	const getTypeContent = calculation => {
		if (calculation.value === discountCalculations.PERCENT)
			return <SVGIcon name='Sale#1' size={20} fill='var(--gray)' />;

		if (calculation.value === discountCalculations.FIXED)
			return <SVGIcon name='Dollar' size={20} fill='var(--gray)' />;

		return <>Q/$</>;
	};

	const _Inner = (
		<>
			<Portlet.Head
				style={{ minHeight: sizeRadioContainer.height }}
				className='d-flex justify-content-start align-items-center'>
				<h3 className='sdms-font-lg sdms-marginless'>Reason: </h3>
				<Radio.Container
					ref={refRadioContainer}
					isInline
					className='sdms-ml-20 d-flex flex-wrap'>
					{availableDiscounts.map(d => {
						return (
							<Radio
								key={d.id}
								checked={discount.id === d.id}
								id={`discount_${d.id}`}
								name='discount-reason'
								content={d.name}
								className='sdms-radio--primary sdms-mt-5'
								onChange={() =>
									setDiscount({
										...d,
										amount: validate(d.amount.toString(), d.calculation.value),
									})
								}
								disabled={disableFixed && d.calculation.value === 'fixed'}
								textToTooltip
							/>
						);
					})}
				</Radio.Container>
			</Portlet.Head>
			<Portlet.Body>
				<div
					className={classNames('row', {
						'd-block': windowSize.width <= mediaBreakpoint.SM,
					})}>
					<div className='col-md-9 col-lg-8'>
						<div className='row sdms-mb-15'>
							<div
								className={classNames('sdms-mb-15-mobile', {
									'col-md-6': availableCalculations.length > 2,
									'col-md-4': availableCalculations.length < 3,
								})}>
								<h3 className='sdms-font-lg text-left sdms-font-bold'>
									Discount Type
								</h3>
								<div className='text-left'>
									<Radio.Container isInline>
										{availableCalculations.map(c => {
											return (
												<Radio
													key={c.id}
													checked={discount.calculation.id === c.id}
													id={`calculation_${c.id}`}
													name='discount-type'
													content={getTypeContent(c)}
													className='sdms-radio--primary sdms-font-boldest'
													onChange={() =>
														setDiscount({
															...discount,
															calculation: c,
															amount: validate(
																discount.amount,
																c.value
															),
														})
													}
													disabled={
														(disableFixed && c.value === 'fixed') ||
														discount.calculation.value ===
															discountCalculations.PER_QUANTITY ||
														(discount.calculation.value !==
															discountCalculations.PER_QUANTITY &&
															c.value ===
																discountCalculations.PER_QUANTITY)
													}
												/>
											);
										})}
									</Radio.Container>
								</div>
							</div>
							<div
								className={classNames({
									'col-md-6': availableCalculations.length > 2,
									'col-md-8': availableCalculations.length < 3,
								})}>
								<ModalInput
									value={discount.amount.toString()}
									onCancel={() => setDiscount({ ...discount, amount: '0' })}
									isSelected={isSelected}
									isFocused
								/>
							</div>
						</div>
					</div>
					<div className='col-md-3 col-lg-4 sdms-mb-15-mobile'>
						<Input
							className='form-control-lg'
							value={generatedOffAmount()}
							onChange={() => {}}
							disabled
							type='text'
							append='off'
						/>
					</div>
				</div>
				<div className='row'>
					<div
						className={classNames('sdms-mb-15-mobile', {
							'col-md-9 col-lg-8': discountPresets.length > 0,
							'col-md-12 col-lg-12': discountPresets.length === 0,
						})}>
						<div className='row sdms-mb-10'>
							<div className='col-4'>
								<Button
									className='sdms-font-lg'
									design='info'
									text='1'
									bold='bold'
									outline
									block
									onClick={() => {
										applyInput(numPadActions.CONCATENATE, '1', changed);
									}}
								/>
							</div>
							<div className='col-4'>
								<Button
									className='sdms-font-lg'
									design='info'
									text='2'
									bold='bold'
									outline
									block
									onClick={() => {
										applyInput(numPadActions.CONCATENATE, '2', changed);
									}}
								/>
							</div>
							<div className='col-4'>
								<Button
									className='sdms-font-lg'
									design='info'
									text='3'
									bold='bold'
									outline
									block
									onClick={() => {
										applyInput(numPadActions.CONCATENATE, '3', changed);
									}}
								/>
							</div>
						</div>
						<div className='row sdms-mb-10'>
							<div className='col-4'>
								<Button
									className='sdms-font-lg'
									design='info'
									bold='bold'
									outline
									block
									text='4'
									onClick={() => {
										applyInput(numPadActions.CONCATENATE, '4', changed);
									}}
								/>
							</div>
							<div className='col-4'>
								<Button
									className='sdms-font-lg'
									design='info'
									bold='bold'
									outline
									block
									text='5'
									onClick={() => {
										applyInput(numPadActions.CONCATENATE, '5', changed);
									}}
								/>
							</div>
							<div className='col-4'>
								<Button
									className='sdms-font-lg'
									design='info'
									bold='bold'
									outline
									block
									text='6'
									onClick={() => {
										applyInput(numPadActions.CONCATENATE, '6', changed);
									}}
								/>
							</div>
						</div>
						<div className='row sdms-mb-10'>
							<div className='col-4'>
								<Button
									className='sdms-font-lg'
									design='info'
									bold='bold'
									outline
									block
									text='7'
									onClick={() => {
										applyInput(numPadActions.CONCATENATE, '7', changed);
									}}
								/>
							</div>
							<div className='col-4'>
								<Button
									className='sdms-font-lg'
									sdms-portlet__body
									sdms-discount__wrapper
									design='info'
									bold='bold'
									outline
									block
									text='8'
									onClick={() => {
										applyInput(numPadActions.CONCATENATE, '8', changed);
									}}
								/>
							</div>
							<div className='col-4'>
								<Button
									className='sdms-font-lg'
									design='info'
									bold='bold'
									outline
									block
									text='9'
									onClick={() => {
										applyInput(numPadActions.CONCATENATE, '9', changed);
									}}
								/>
							</div>
						</div>
						<div className='row'>
							<div className='col-4'>
								<Button
									className='sdms-font-lg'
									design='info'
									bold='bold'
									outline
									block
									text='.'
									onClick={() => {
										applyInput(numPadActions.DECIMAL);
									}}
								/>
							</div>
							<div className='col-4'>
								<Button
									className='sdms-font-lg'
									design='info'
									bold='bold'
									outline
									block
									text='0'
									onClick={() => {
										applyInput(numPadActions.CONCATENATE, '0', changed);
									}}
								/>
							</div>
							<div className='col-4'>
								<Button
									className='btn-only-icon sdms-pt0 sdms-pb-0 h-100'
									label='danger'
									block
									icon='Backspace'
									iconSize={38}
									onClick={() => {
										applyInput(numPadActions.SLICE, null, changed);
									}}
								/>
							</div>
						</div>
					</div>
					{discountPresets.length > 0 && (
						<div className='col-md-3 col-lg-4'>
							<div className='row'>
								{discountPresets.slice(0, 4).map(value => {
									return (
										<div className='col-12' key={value}>
											<Button
												className='sdms-mb-10 sdms-font-lg'
												label='success'
												bold='bold'
												outline
												block
												text={`${value}%`}
												key={value}
												onClick={() => {
													setIsSelected(true);
													setChanged(false);
													applyPresetDiscount(value);
												}} // assuming presets are already string
											/>
										</div>
									);
								})}
							</div>
						</div>
					)}
				</div>
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						icon='Error-circle'
						size='sm'
						elevate
						text='Close'
						onClick={onClose}
					/>
				</div>
				<div className='col-auto'>
					<Button
						design='brand'
						size='sm'
						elevate
						icon='Sale#2'
						text={usedDiscount ? 'Update' : 'Add'}
						onClick={() =>
							updateDiscount({ ...discount, amount: parseFloat(discount.amount) })
						}
					/>
				</div>
			</Portlet.Foot>
		</>
	);

	if (withOutPortlet) {
		return _Inner;
	}
	return (
		<div className='sdms-portlet' tabIndex='-1'>
			<Portlet.Head>
				<Portlet.HeadLabel portletIcon={_icon}>
					<h3 className='sdms-portlet__head-title'>{_title}</h3>
				</Portlet.HeadLabel>
			</Portlet.Head>
			{_Inner}
		</div>
	);
};
DiscountModalContent.propTypes = {
	total: PropTypes.number.isRequired,
	decimalLimit: PropTypes.number,
	onClose: PropTypes.func.isRequired,
	updateDiscount: PropTypes.func.isRequired,
	discounts: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	usedDiscount: PropTypes.object,
	discountPresets: PropTypes.arrayOf(PropTypes.string),
	calculations: PropTypes.arrayOf(PropTypes.object),
	disableFixed: PropTypes.bool,
	withOutPortlet: PropTypes.bool,
	orderItems: PropTypes.arrayOf(PropTypes.object),
};
DiscountModalContent.defaultProps = {
	decimalLimit: 2,
	discounts: [],
	usedDiscount: null,
	discountPresets: [],
	calculations: [],
	disableFixed: false,
	withOutPortlet: false,
	orderItems: [],
};

export default DiscountModal;
