import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import ProductCategoryMarinaForm from './ProductCategoryMarinaForm';
import usePages from '../../../utils/hooks/usePages';

const ProductCategoryMarinaEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='productCategoryMarinas'
			lists={['productCategoryMarinas']}
			fields={<ProductCategoryMarinaForm />}
			pageTitle={pages.marina.settings.productCategories.text}
			icon={pages.marina.settings.productCategories.icon}
		/>
	);
};

ProductCategoryMarinaEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ProductCategoryMarinaEdit;
