import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import Portal from '../layout/Portal';
import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import SVGIcon from '../element/SVGIcon';

const VersionCountDownModal = ({ isOpen, counter, onUpdate, onPostPone }) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				closeOnEscape={false}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '480px',
				}}>
				<VersionCountDownModalContent
					counter={counter}
					onUpdate={onUpdate}
					onPostPone={onPostPone}
				/>
			</Popup>
		</Portal>
	);
};

VersionCountDownModal.propTypes = {
	isOpen: PropTypes.bool,
	counter: PropTypes.number,
	onUpdate: PropTypes.func,
	onPostPone: PropTypes.func,
};

VersionCountDownModal.defaultProps = {
	isOpen: false,
	counter: 0,
	onUpdate: () => {},
	onPostPone: () => {},
};

const VersionCountDownModalContent = ({ counter, onUpdate, onPostPone }) => {
	const [count, setCount] = useState(counter);

	const timeOutId = useRef(0);

	useEffect(() => {
		if (count > 1)
			timeOutId.current = setTimeout(() => {
				setCount(count - 1);
			}, 1000);
		else {
			onUpdate();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [count]);

	return (
		<Portlet>
			<Portlet.Body>
				<div className='col-12 d-flex justify-content-center sdms-mb-10'>
					<SVGIcon name='Warning-2' size={96} />
				</div>
				<div className='col-12 d-flex justify-content-center'>
					<h3>Update Required - {count} second(s)</h3>
				</div>
				<div className='sdms-progress sdms-progress--info'>
					<progress
						max={counter}
						value={count.toString()}
						className='sdms-progress-block'
					/>
				</div>
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='success'
						text='Update'
						icon='Sign-out'
						size='sm'
						elevate
						onClick={() => {
							clearTimeout(timeOutId.current);
							onUpdate();
						}}
					/>
				</div>
				<div className='col-auto'>
					<Button
						design='brand'
						icon='Done-circle'
						text='Postpone'
						size='sm'
						onClick={() => {
							clearTimeout(timeOutId.current);
							onPostPone();
						}}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};

VersionCountDownModalContent.propTypes = {
	counter: PropTypes.number.isRequired,
	onUpdate: PropTypes.func.isRequired,
	onPostPone: PropTypes.func.isRequired,
};

export default VersionCountDownModal;
