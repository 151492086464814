import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import usePages from '../../../utils/hooks/usePages';
import SVGIcon from '../../reusables/element/SVGIcon';
import NullBadge from '../../reusables/design/NullBadge';
import forms from '../../forms';
import UserContext from '../../../app/contexts/UserContext';

const Address = ({ data }) => {
	if (data.state === null) return <NullBadge />;
	return (
		<div>
			<div>{data.addressLineOne}</div>
			<div>{data.addressLineTwo}</div>
			<div>
				{data.city} {data.state.code} {data.zip}
			</div>
		</div>
	);
};
Address.propTypes = {
	data: PropTypes.shape({
		addressLineOne: PropTypes.string,
		addressLineTwo: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.object,
		zip: PropTypes.string,
	}),
};
Address.defaultProps = {
	data: {
		addressLineOne: null,
		addressLineTwo: null,
		city: null,
		state: null,
		zip: null,
	},
};

const Country = ({ data }) => {
	if (data.country === null) return <NullBadge />;

	switch (data.country.code) {
		case 'US':
			return <SVGIcon name='USA' className='sdms-svg-icon--fill' size={36} />;
		case 'CA':
			return <SVGIcon name='CA' className='sdms-svg-icon--fill' size={36} />;
		case 'TR':
			return <SVGIcon name='TR' className='sdms-svg-icon--fill' size={36} />;
		default:
			return <>{data.country.code}</>;
	}
};
Country.propTypes = {
	data: PropTypes.shape({
		country: PropTypes.object,
	}),
};
Country.defaultProps = {
	data: {
		country: null,
	},
};

const Phone = ({ data }) => {
	if (data.contactPhone === null) return null;
	return (
		<a href={`tel:${data.contactPhone}`} className='sdms-link'>
			{data.contactPhone}
		</a>
	);
};
Phone.propTypes = {
	data: PropTypes.shape({
		contactPhone: PropTypes.string,
	}),
};
Phone.defaultProps = {
	data: {
		contactPhone: null,
	},
};

const OutletList = ({ history, style }) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	return (
		<ListContainer
			route='outlets'
			history={history}
			title='Location'
			icon={pages.companySettings.company.location.icon}
			isTabList
			fluid='fluid'
			style={style}
			forms={forms.companySettings.company.location}>
			<List withCheckBox withOutPortlet>
				<List.Col
					label='Name'
					cellData='name'
					isLinkColumn={userContext.hasPermission('maintain_company')}
					sortable='name'
				/>
				<List.Col label='Address' cellComponent={<Address />} sortable='addressLineOne' />
				<List.Col
					label='Country'
					cellComponent={<Country />}
					width={100}
					sortable='country.name'
				/>
				<List.Col
					label='Phone'
					cellComponent={<Phone />}
					width={150}
					sortable='contactPhone'
				/>
				<List.Col
					label='Timezone'
					cellData='name'
					cellDataObject='timezone'
					sortable='timezone.value'
				/>
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
						noPermission={!userContext.hasPermission('maintain_company')}
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item
							itemsColor='danger'
							icon='Trash'
							key='delete'
							noPermission={!userContext.hasPermission('maintain_company')}>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
OutletList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	style: PropTypes.object,
};
OutletList.defaultProps = {
	style: null,
};

export default OutletList;
