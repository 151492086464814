import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { resolveDebugData, debugTypes } from '../../../utils/helpers/debugHelper';

const ANCHOR_ID = 'sdms-debug-anchor';

const FormDebugger = ({ data, debugType }) => {
	const downloadDebugData = () => {
		if (!data.name && !data.id) return;

		const debugData = resolveDebugData(data, debugType);

		const dataString = `data:text/json;charset=utf-8,${encodeURIComponent(
			JSON.stringify(debugData)
		)}`;

		const filename = `${data.name || data.id}(${data['@type']}).json`;

		const existingButton = document.getElementById(ANCHOR_ID);

		if (existingButton || Object.keys(debugData).length <= 1) return;

		const anchorElement = document.createElement('a');

		anchorElement.setAttribute('id', ANCHOR_ID);

		anchorElement.setAttribute('href', dataString);

		anchorElement.setAttribute('download', filename);

		anchorElement.click();

		anchorElement.remove();
	};

	useEffect(() => {
		const onKeyPress = event => {
			if (event.shiftKey && event.altKey && event.key === 'D') downloadDebugData();
		};

		document.addEventListener('keydown', onKeyPress, false);

		return () => {
			document.removeEventListener('keydown', onKeyPress, false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return null;
};

FormDebugger.propTypes = {
	debugType: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};
FormDebugger.defaultProps = {
	debugType: debugTypes.generic,
	data: {},
};

export default FormDebugger;
