import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import UserContext from '../app/contexts/UserContext';
import usePages from '../utils/hooks/usePages';
import { pathToUrl } from '../utils/helpers/apiCall';
import SVGIcon from './reusables/element/SVGIcon';
import Button from './reusables/element/Button';

const User = () => {
	const userContext = useContext(UserContext);

	const pages = usePages();

	return (
		<div className='sdms-header__topbar-item sdms-header__topbar-item--user'>
			<div
				className='sdms-header__topbar-wrapper'
				data-toggle='dropdown'
				aria-expanded='true'>
				<div className='sdms-header__topbar-user'>
					<span className='sdms-header__topbar-welcome sdms-hidden-mobile'>Hi,</span>
					<span className='sdms-header__topbar-username sdms-hidden-mobile'>
						{userContext.data.user.firstName}
					</span>
					{userContext.data.user.profilePicture ? (
						<img
							alt='Avatar'
							src={pathToUrl(userContext.data.user.profilePicture.thumb)}
						/>
					) : (
						<span className='sdms-badge sdms-badge--username sdms-badge--unified-info sdms-badge--lg sdms-badge--rounded sdms-badge--bold'>
							{userContext.data.user.firstName.charAt(0) +
								userContext.data.user.lastName.charAt(0)}
						</span>
					)}
				</div>
			</div>

			<div
				className='dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl'
				x-placement='bottom-end'>
				<div className='sdms-user-card sdms-user-card--skin-dark sdms-notification-item-padding-x'>
					<div className='sdms-user-card__avatar'>
						{userContext.data.user.profilePicture ? (
							<img
								alt='Avatar'
								src={pathToUrl(userContext.data.user.profilePicture.thumb)}
							/>
						) : (
							<span className='sdms-badge sdms-badge--lg sdms-badge--rounded sdms-badge--bold sdms-font-info'>
								{userContext.data.user.firstName.charAt(0) +
									userContext.data.user.lastName.charAt(0)}
							</span>
						)}
					</div>
					<div className='sdms-user-card__name'>
						{`${userContext.data.user.firstName} ${userContext.data.user.lastName}`}
					</div>
					{/* User notification number */}
					{/* <div className='sdms-user-card__badge'> */}
					{/*	<span className='btn btn-success btn-sm btn-bold btn-font-md'>1</span> */}
					{/* </div> */}
				</div>
				<div className='sdms-notification'>
					<NavLink
						to={`${pages.systemSettings.users.path}/${userContext.data.user.id}`}
						className='sdms-notification__item'>
						<div className='sdms-notification__item-icon'>
							<SVGIcon name='User' />
						</div>
						<div className='sdms-notification__item-details'>
							<div className='sdms-notification__item-title sdms-font-bold'>
								My Profile
							</div>
							<div className='sdms-notification__item-time'>Profile Settings</div>
						</div>
						<SVGIcon name='Angle-right' />
					</NavLink>
					<div className='sdms-notification__custom sdms-space-between'>
						<Button
							label='brand'
							size='sm'
							bold='bold'
							onClick={() => {
								userContext.logout('admin');
							}}>
							Sign Out
						</Button>
						{/* <a href='/' className='btn btn-clean btn-sm btn-bold'> */}
						{/* Other Button */}
						{/* </a> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default User;
