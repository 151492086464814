import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useField from '../../../utils/hooks/useField';
import { maxLength, numeric, required } from '../../../utils/helpers/validation';
import { negativeNumberParser } from '../../../utils/helpers/helper';
import FormGroup from '../../reusables/layout/FormGroup';
import Section from '../../reusables/layout/Section';
import Loading from '../../reusables/template/Loading';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import Toggle from '../../reusables/field/Toggle';
import ImageUpload from '../../reusables/field/ImageUpload';
import UserContext from '../../../app/contexts/UserContext';
import { extraChargeTypes } from '../../../utils/constants/constants';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import { modules } from '../../../utils/helpers/apiCall';

const ExtraChargeOrderForm = ({
	data,
	isLoading,
	setTitle,
	onFormChange,
	isSubmitted,
	setIsValid,
	itemTypes,
	productCategoryExtraCharges,
	enumExtraChargeTypes,
	taxCodes,
	taxAgencies,
	accounts,
	unitMeasures,
	taxRates,
	enumBookingCalculations,
	submitButtonAttr,
	submit,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const campgroundModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.CAMPGROUND)
	);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [
		itemType,
		itemTypeOnChange,
		itemTypeValRes,
		itemTypeShowVal,
		setItemTypeShowVal,
	] = useField(data, 'itemType', onFormChange, [required], null);

	const [productCategory, productCategoryOnChange] = useField(
		data,
		'productCategory',
		onFormChange,
		[],
		null
	);

	const [account, accountOnChange, accountValRes, accountShowVal, setAccountShowVal] = useField(
		data,
		'account',
		onFormChange,
		[required],
		null
	);

	const [
		incomeAccount,
		incomeAccountOnChange,
		incomeAccountValRes,
		incomeAccountShowVal,
		setIncomeAccountShowVal,
	] = useField(data, 'incomeAccount', onFormChange, [required], null);

	const [
		cogsAccount,
		cogsAccountOnChange,
		cogsAccountValRes,
		cogsAccountShowVal,
		setCogsAccountShowVal,
	] = useField(data, 'cogsAccount', onFormChange, [required], null);

	const [
		assetsAccount,
		assetsAccountOnChange,
		assetsAccountValRes,
		assetsAccountShowVal,
		setAssetsAccountShowVal,
	] = useField(data, 'assetsAccount', onFormChange, [required], null);

	const [
		description,
		descriptionOnChange,
		descriptionValRes,
		descriptionShowVal,
		setDescriptionShowVal,
	] = useField(data, 'description', onFormChange, [maxLength]);

	const [
		externalDescription,
		externalDescriptionOnChange,
		externalDescriptionValRes,
		externalDescriptionShowVal,
		setExternalDescriptionShowVal,
	] = useField(data, 'externalDescription', onFormChange, [maxLength]);

	const [productImage, setProductImage] = useField(data, 'productImage', onFormChange, [], null);

	const [taxCode, taxCodeOnChange, taxCodeValRes, taxCodeShowVal, setTaxCodeShowVal] = useField(
		data,
		'taxCode',
		onFormChange,
		[required],
		null
	);

	const [price, priceOnChange, priceValRes, priceShowVal, setPriceShowVal] = useField(
		data,
		'price',
		onFormChange,
		[required, numeric],
		0,
		negativeNumberParser
	);

	const [
		minPrice,
		minPriceOnChange,
		minPriceValRes,
		minPriceShowVal,
		setMinPriceShowVal,
	] = useField(data, 'minPrice', onFormChange, [numeric], 0, negativeNumberParser);

	const [
		maxPrice,
		maxPriceOnChange,
		maxPriceValRes,
		maxPriceShowVal,
		setMaxPriceShowVal,
	] = useField(data, 'maxPrice', onFormChange, [numeric], 0, negativeNumberParser);

	const [
		bookingCalculation,
		bookingCalculationOnChange,
		bookingCalculationValRes,
		bookingCalculationShowVal,
		setBookingCalculationShowVal,
	] = useField(data, 'bookingCalculation', onFormChange, [required], null);

	const [unitMeasure, unitMeasureOnChange] = useField(data, 'unitMeasure', onFormChange, [], {});

	const [additionalTaxes, additionalTaxesOnChange] = useField(
		data,
		'additionalTaxes',
		onFormChange,
		[],
		[]
	);

	const [optional, optionalOnChange] = useField(data, 'optional', onFormChange, [], true);

	const [isTax, isTaxOnChange] = useField(data, 'isTax', onFormChange, [], false);

	const [applyTaxes, applyTaxesOnChange] = useField(data, 'applyTaxes', onFormChange, [], []);

	const [
		taxAgency,
		taxAgencyOnChange,
		taxAgencyValRes,
		taxAgencyShowVal,
		setTaxAgencyShowVal,
	] = useField(data, 'taxAgency', onFormChange, [], null);

	const [hideInResults, hideInResultsOnChange] = useField(
		data,
		'hideInResults',
		onFormChange,
		[],
		false
	);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.campground.default.text,
				path: pages.campground.default.path,
			},
			{
				title: pages.campground.settings.text,
				path: pages.campground.settings.path,
			},
			{
				title: pages.campground.settings.extraCharges.text,
				path: pages.campground.settings.extraCharges.path,
			},
			{
				title: pages.campground.settings.extraCharges.allOrders.text,
				path: pages.campground.settings.extraCharges.path,
			},
			{
				title: name || `New ${pages.campground.settings.extraCharges.text}`,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(name || `New ${pages.campground.settings.extraCharges.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setPriceShowVal();
			setTaxCodeShowVal();
			setTaxAgencyShowVal();
			setItemTypeShowVal();
			setAccountShowVal();
			setIncomeAccountShowVal();
			setCogsAccountShowVal();
			setAssetsAccountShowVal();
			setBookingCalculationShowVal();
			setDescriptionShowVal();
			setExternalDescriptionShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setPriceShowVal,
		setTaxCodeShowVal,
		setTaxAgencyShowVal,
		setItemTypeShowVal,
		setAccountShowVal,
		setIncomeAccountShowVal,
		setCogsAccountShowVal,
		setAssetsAccountShowVal,
		setBookingCalculationShowVal,
		setDescriptionShowVal,
		setExternalDescriptionShowVal,
	]);
	// useEffect to update form validity
	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				priceValRes.isValid &&
				taxCodeValRes.isValid &&
				taxAgencyValRes.isValid &&
				itemTypeValRes.isValid &&
				bookingCalculationValRes.isValid &&
				descriptionValRes.isValid &&
				externalDescriptionValRes.isValid &&
				itemType &&
				(itemType.value === 'inventory'
					? cogsAccountValRes.isValid &&
					  incomeAccountValRes.isValid &&
					  assetsAccountValRes.isValid
					: accountValRes.isValid)
		);
	}, [
		nameValRes.isValid,
		priceValRes.isValid,
		bookingCalculationValRes.isValid,
		taxCodeValRes.isValid,
		taxAgencyValRes.isValid,
		itemTypeValRes.isValid,
		accountValRes.isValid,
		incomeAccountValRes.isValid,
		cogsAccountValRes.isValid,
		assetsAccountValRes.isValid,
		descriptionValRes.isValid,
		externalDescriptionValRes.isValid,
		setIsValid,
		itemType,
	]);

	useEffect(() => {
		if (!isLoading) data.module = campgroundModule.current;
	}, [data.module, isLoading]);

	useEffect(() => {
		if (enumExtraChargeTypes.length > 0)
			data.extraChargeType = enumExtraChargeTypes.find(
				ect => ect.value === extraChargeTypes.ALL_ORDERS
			);
	}, [data.extraChargeType, enumExtraChargeTypes]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup row>
						<div className='col-12'>
							<Section title='General'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='productId'
												label='Item ID'
												id={data.id}
												colMd={6}>
												<Input
													type='text'
													placeholder='Auto Generate'
													value={data.productId}
													onChange={() => {}}
													disabled
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='name'
												label='Name'
												id={data.id}
												valRes={nameValRes}
												showValidation={nameShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Name (required)'
													value={name}
													onChange={nameOnChange}
													onBlur={setNameShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='itemType'
												label='Item Type'
												valRes={itemTypeValRes}
												showValidation={itemTypeShowVal}
												id={data.id}
												colMd={6}>
												<Selects
													options={itemTypes.filter(it => !it.isSystem)}
													placeholder='Type (Required)'
													value={itemType}
													onChange={({ target }) => {
														itemTypeOnChange({ target });
														accountOnChange({
															target: {
																name: 'account',
																value: null,
															},
														});
														incomeAccountOnChange({
															target: {
																name: 'incomeAccount',
																value: null,
															},
														});
														cogsAccountOnChange({
															target: {
																name: 'cogsAccount',
																value: null,
															},
														});
														assetsAccountOnChange({
															target: {
																name: 'assetsAccount',
																value: null,
															},
														});
													}}
													onBlur={setItemTypeShowVal}
													displayKey='value'
													disabled={data.id !== 0}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='productCategory'
												label='Group'
												description='Select a category to group Price Adjustments as an option'
												id={data.id}
												colMd={6}>
												<Selects
													options={productCategoryExtraCharges}
													placeholder='Group'
													value={productCategory}
													onChange={productCategoryOnChange}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='description'
												label='Description'
												id={data.id}
												valRes={descriptionValRes}
												showValidation={descriptionShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Description'
													value={description}
													onChange={descriptionOnChange}
													onBlur={setDescriptionShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='externalDescription'
												label='External Description'
												id={data.id}
												valRes={externalDescriptionValRes}
												showValidation={externalDescriptionShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='External Description'
													value={externalDescription}
													onChange={externalDescriptionOnChange}
													onBlur={setExternalDescriptionShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='productImage'
												label='Image'
												description='.png, .jpg or .jpeg only'
												colMd={6}>
												<ImageUpload
													media={productImage}
													setMedia={image =>
														setProductImage({
															target: {
																name: 'productImage',
																value: image,
															},
														})
													}
												/>
											</FormField>
										</Loading>
									</FormGroup>
								</Section.Body>
							</Section>
							<Section title='Accounting'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='taxCode'
												label='Tax Code'
												id={data.id}
												valRes={taxCodeValRes}
												showValidation={taxCodeShowVal}
												colMd={6}>
												<Selects
													options={taxCodes}
													placeholder='Tax Code (Required)'
													value={taxCode}
													onChange={taxCodeOnChange}
													onBlur={setTaxCodeShowVal}
												/>
											</FormField>
										</Loading>
										{(itemType || {}).value === 'inventory' ? (
											<>
												<Loading isLoading={isLoading}>
													<FormField
														name='incomeAccount'
														label='Income Account'
														id={data.id}
														valRes={incomeAccountValRes}
														showValidation={incomeAccountShowVal}
														colMd={6}>
														<Selects
															options={accounts}
															placeholder='Income Account (Required)'
															value={incomeAccount}
															onChange={incomeAccountOnChange}
															onBlur={setIncomeAccountShowVal}
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='cogsAccount'
														label='COGS Account'
														id={data.id}
														valRes={cogsAccountValRes}
														showValidation={cogsAccountShowVal}
														colMd={6}>
														<Selects
															options={accounts}
															placeholder='COGS Account (Required)'
															value={cogsAccount}
															onChange={cogsAccountOnChange}
															onBlur={setCogsAccountShowVal}
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='assetsAccount'
														label='Assets Account'
														id={data.id}
														valRes={assetsAccountValRes}
														showValidation={assetsAccountShowVal}
														colMd={6}>
														<Selects
															options={accounts}
															placeholder='Assets Account (Required)'
															value={assetsAccount}
															onChange={assetsAccountOnChange}
															onBlur={setAssetsAccountShowVal}
														/>
													</FormField>
												</Loading>
											</>
										) : (
											<Loading isLoading={isLoading}>
												<FormField
													name='account'
													label='Account'
													id={data.id}
													valRes={accountValRes}
													showValidation={accountShowVal}
													colMd={6}>
													<Selects
														options={accounts}
														placeholder='Account (Required)'
														value={account}
														onChange={accountOnChange}
														onBlur={setAccountShowVal}
													/>
												</FormField>
											</Loading>
										)}
									</FormGroup>
								</Section.Body>
							</Section>
							<Section title='Base Pricing'>
								<Section.Body>
									<FormGroup row>
										<Loading isLoading={isLoading}>
											<FormField
												name='price'
												label='Price'
												id={data.id}
												valRes={priceValRes}
												showValidation={priceShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Price (Required)'
													value={price.toString()}
													onChange={priceOnChange}
													onBlur={setPriceShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='unitMeasure'
												label='Unit of Measure'
												id={data.id}
												colMd={6}>
												<Selects
													options={unitMeasures}
													placeholder='Unit of Measure'
													value={unitMeasure}
													onChange={unitMeasureOnChange}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='bookingCalculation'
												label='Calculation'
												id={data.id}
												showValidation={bookingCalculationShowVal}
												valRes={bookingCalculationValRes}
												colMd={6}>
												<Selects
													options={enumBookingCalculations.filter(
														ebc =>
															ebc.extraChargeTypes.findIndex(
																ect =>
																	ect.value ===
																	extraChargeTypes.ALL_ORDERS
															) > -1
													)}
													placeholder='Calculation (Required)'
													value={bookingCalculation}
													onChange={bookingCalculationOnChange}
													onBlur={setBookingCalculationShowVal}
													displayKey='value'
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='additionalTaxes'
												label='Additional Taxes'
												id={data.id}
												colMd={6}>
												<Selects
													options={taxRates.filter(
														tr =>
															tr.id !==
																userContext.data.selectedOutlet
																	.settings.taxRate.id &&
															!tr.isGroup
													)}
													placeholder='Select Tax Rate'
													value={additionalTaxes}
													onChange={additionalTaxesOnChange}
													multiple
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='minPrice'
												label='Minimum Price'
												id={data.id}
												valRes={minPriceValRes}
												showValidation={minPriceShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Minimum Price'
													value={minPrice.toString()}
													onChange={minPriceOnChange}
													onBlur={setMinPriceShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='maxPrice'
												label='Maximum Price'
												id={data.id}
												valRes={maxPriceValRes}
												showValidation={maxPriceShowVal}
												colMd={6}>
												<Input
													type='text'
													placeholder='Maximum Price'
													value={maxPrice.toString()}
													onChange={maxPriceOnChange}
													onBlur={setMaxPriceShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='optional'
												label='Optional'
												id={data.id}
												valRes={{}}
												showValidation={false}
												colMd={2}>
												{productCategory ? (
													<Toggle
														onChange={() => {}}
														value={false}
														disabled
													/>
												) : (
													<Toggle
														onChange={optionalOnChange}
														value={optional}
													/>
												)}
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='hideInResults'
												label='Hide In Results'
												id={data.id}
												valRes={{}}
												showValidation={false}
												colMd={4}>
												<Toggle
													onChange={hideInResultsOnChange}
													value={hideInResults}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='isTax'
												label='Is Tax'
												id={data.id}
												valRes={{}}
												showValidation={false}
												colMd={1}>
												<Toggle
													value={isTax}
													onChange={e => {
														isTaxOnChange(e);

														taxAgencyOnChange({
															target: { value: null },
														});
													}}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='applyTaxes'
												label='Apply Taxes'
												id={data.id}
												colMd={2}>
												<Selects
													options={taxCodes}
													placeholder='Select Tax Codes'
													value={applyTaxes}
													onChange={applyTaxesOnChange}
													multiple
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='taxAgency'
												label='Tax Agency'
												id={data.id}
												valRes={taxAgencyValRes}
												showValidation={taxAgencyShowVal}
												colMd={3}>
												<Selects
													options={taxAgencies}
													placeholder='Tax Agency'
													value={taxAgency}
													onChange={taxAgencyOnChange}
													onBlur={setTaxAgencyShowVal}
													disabled={!data.isTax}
												/>
											</FormField>
										</Loading>
									</FormGroup>
								</Section.Body>
							</Section>
						</div>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
ExtraChargeOrderForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		productId: PropTypes.string,
		name: PropTypes.string,
		description: PropTypes.string,
		// eslint-disable-next-line react/require-default-props
		module: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		extraChargeType: PropTypes.object,
	}),
	isLoading: PropTypes.bool,
	itemTypes: PropTypes.arrayOf(PropTypes.object),
	productCategoryExtraCharges: PropTypes.arrayOf(PropTypes.object),
	enumExtraChargeTypes: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	taxAgencies: PropTypes.arrayOf(PropTypes.object),
	unitMeasures: PropTypes.arrayOf(PropTypes.object),
	accounts: PropTypes.arrayOf(PropTypes.object),
	taxRates: PropTypes.arrayOf(PropTypes.object),
	enumBookingCalculations: PropTypes.arrayOf(PropTypes.object),
	setTitle: PropTypes.func,
	onFormChange: PropTypes.func,
	isSubmitted: PropTypes.bool,
	setIsValid: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
ExtraChargeOrderForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		description: '',
		extraChargeType: {},
	},
	itemTypes: [],
	productCategoryExtraCharges: [],
	enumExtraChargeTypes: [],
	taxCodes: [],
	taxAgencies: [],
	unitMeasures: [],
	accounts: [],
	taxRates: [],
	enumBookingCalculations: [],
	isLoading: false,
	setTitle: () => {},
	onFormChange: () => {},
	isSubmitted: false,
	setIsValid: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default ExtraChargeOrderForm;
