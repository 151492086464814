import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';
import { messageStatuses } from '../../../utils/constants/constants';
import apiCall from '../../../utils/helpers/apiCall';
import MessageContext from '../../../app/contexts/MessageContext';
import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';

import Portlet from '../../reusables/layout/Portlet';
import Wysiwyg from '../../reusables/field/Wysiwyg';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Loading from '../../reusables/template/Loading';
import Button from '../../reusables/element/Button';
import Selects from '../../reusables/field/Selects';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import MultiEmailInput from '../../reusables/field/MultiEmailInput';
import useDate from '../../../utils/hooks/useDate';

const MessageForm = ({
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	onFormChange,
	isLoading,
	emailAddresses,
	submit,
	isInPanel,
}) => {
	const headerContext = useContext(HeaderContext);

	const messageContext = useContext(MessageContext);

	const [dateFormatter] = useDate();

	const history = useHistory();

	const location = useLocation();

	const [customer, customerOnChange] = useField(data, 'inquiryObject', onFormChange, [], null);

	const [emailAddress, emailAddressOnChange] = useField(data, 'emailAddress', onFormChange);

	const [from, fromOnChange, fromValRes, fromShowVal, setFromShowVal] = useField(
		data,
		'fromEmail',
		onFormChange,
		[required]
	);

	const [to, toOnChange, toValRes, toShowVal, setToShowVal] = useField(
		data,
		'toEmail',
		onFormChange,
		[required]
	);

	const [cc, ccOnChange] = useField(data, 'cc', onFormChange);

	const [subject, subjectOnChange, subjectValRes, subjectShowVal, setSubjectShowVal] = useField(
		data,
		'subject',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [
		content,
		contentOnChange,
		contentValRes,
		contentShowVal,
		setContentShowVal,
	] = useField(data, 'content', onFormChange, [required]);

	const replyMessage = useMemo(() => {
		if (data.replyMessage) return data.replyMessage;
		return location?.state?.replyMessage;
	}, [data.replyMessage, location]);

	useEffect(() => {
		if (isSubmitted) {
			setFromShowVal();
			setToShowVal();
			setSubjectShowVal();
			setContentShowVal();
		}
	}, [isSubmitted, setFromShowVal, setToShowVal, setSubjectShowVal, setContentShowVal]);

	useEffect(() => {
		setIsValid(
			fromValRes.isValid && toValRes.isValid && subjectValRes.isValid && contentValRes.isValid
		);
	}, [
		setIsValid,
		fromValRes.isValid,
		toValRes.isValid,
		subjectValRes.isValid,
		contentValRes.isValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.messages.default.text, path: pages.messages.dashboard.path },
			{ title: subject || `New ${pages.messages.default.text}`, isActive: true },
		]);

		headerContext.setPageTitle(subject || `New ${pages.messages.default.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [subject]);

	useEffect(() => {
		if (!isLoading && data.id !== 0 && data.status.value === messageStatuses.UNREAD) {
			apiCall(
				'POST',
				'changeMessageStatus',
				() => {
					messageContext.setEntityCount(Math.max(messageContext.entityCount - 1, 0));
					messageContext.setCount(Math.max(messageContext.count - 1, 0));
				},
				() => {},
				'',
				{ id: data.id, status: messageStatuses.READ }
			);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	useEffect(() => {
		if (replyMessage) {
			fromOnChange({ target: { value: replyMessage.toEmail } });
			toOnChange({ target: { value: replyMessage.fromEmail } });
			subjectOnChange({ target: { value: replyMessage.subject } });
			contentOnChange({
				target: {
					value: `<br>On ${dateFormatter(
						replyMessage.date,
						true,
						'MMM D, YYYY'
					)} at ${dateFormatter(replyMessage.date, true, 'h:m A')} ${
						replyMessage.fromEmail
					} wrote:<br>${replyMessage.content}`,
				},
			});

			data.replyMessageId = replyMessage.id;
		} /* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [replyMessage]);

	useEffect(() => {
		if (data.inquiryObject) {
			let toEmail = null;
			if (data.inquiryObject.customer?.email) toEmail = data.inquiryObject.customer?.email;
			else if (data.inquiryObject.email) toEmail = data.inquiryObject.email;

			if (toEmail) toOnChange({ target: { value: toEmail } });
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<Portlet
			className={classNames('sdms-form', 'sdms-message-form', {
				'sdms-message-panel-form': isInPanel,
			})}
			fluid='fluid'
			hasFrame={!isInPanel}>
			<Portlet.Body>
				<form>
					{data.id === 0 && !replyMessage && !data.inquiryObject && (
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='customer'
									label='Customer'
									id={data.id}
									col={4}
									inFormDesign={false}>
									<AsyncSelect
										options={[]}
										placeholder='Search and select customer'
										value={customer}
										onChange={e => {
											customerOnChange(e);
											toOnChange({
												target: { value: e.target.value?.email },
											});
										}}
										route='customers'
										field='displayName'
										displayKey='displayName'
									/>
								</FormField>
							</Loading>
						</FormGroup>
					)}
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='fromAddress'
								id={data.id}
								valRes={fromValRes}
								showValidation={fromShowVal}
								colMd={12}
								inFormDesign={false}>
								{data.id || replyMessage ? (
									<Input
										prepend='From'
										type='text'
										value={from}
										disabled
										onChange={() => {}}
									/>
								) : (
									<Selects
										prepend='From'
										placeholder='Select a Email Address'
										options={emailAddresses}
										onChange={e => {
											emailAddressOnChange(e);
											fromOnChange({
												target: { value: e.target.value?.emailAddress },
											});
										}}
										value={emailAddress}
										renderOption={option =>
											`${option.displayName} - ${option.emailAddress}`
										}
										optionalShow={option => {
											if (option)
												return `${option.displayName} - ${option.emailAddress}`;
											return '';
										}}
										displayKey='displayName'
										onBlur={setFromShowVal}
									/>
								)}
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='toAddress'
								id={data.id}
								valRes={toValRes}
								showValidation={toShowVal}
								colMd={12}
								inFormDesign={false}>
								<Input
									prepend='To'
									type='text'
									placeholder='To (Required)'
									value={to}
									onChange={toOnChange}
									onBlur={setToShowVal}
									disabled={data.id !== 0 || replyMessage}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='cc' id={data.id} colMd={12} inFormDesign={false}>
								<MultiEmailInput
									onChange={ccOnChange}
									value={cc}
									prepend='CC'
									placeholder='CC'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='subject'
								id={data.id}
								valRes={subjectValRes}
								showValidation={subjectShowVal}
								colMd={12}
								inFormDesign={false}>
								<Input
									prepend='Subject'
									type='text'
									placeholder='Subject (Required)'
									value={subject}
									onChange={subjectOnChange}
									onBlur={setSubjectShowVal}
									disabled={data.id !== 0 || replyMessage}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='content'
								id={data.id}
								col={12}
								showValidation={contentShowVal}
								valRes={contentValRes}>
								<Wysiwyg
									value={content}
									onChange={contentOnChange}
									onBlur={setContentShowVal}
									isProduct={false}
									disabled={data.id !== 0}
									isEmail
									autoFocus={!!replyMessage}
								/>
							</FormField>
						</Loading>
						{data.attachments && data.attachments.length > 0 && (
							<div className='col-md-12'>
								<span className='sdms-message-attachment-title'>Attachments:</span>
								{data.attachments.map(attachment => (
									<a
										className='sdms-message-attachment-link'
										href={attachment.content || '#'}
										target='_blank'
										rel='noopener noreferrer'>
										{attachment.fileName}
									</a>
								))}
							</div>
						)}
					</FormGroup>
				</form>
			</Portlet.Body>
			{(data.id === 0 || data.isIncoming) && !isInPanel && (
				<Portlet.Foot type='form' tall='sm'>
					{data.id ? (
						<Button
							label='brand'
							text='Reply'
							icon='Send'
							onClick={() =>
								history.push({
									pathname: `${pages.messages.default.path}/0`,
									state: {
										replyMessage: data,
									},
								})
							}
						/>
					) : (
						<Button label='brand' text='Send' icon='Send' onClick={submit} />
					)}
				</Portlet.Foot>
			)}
		</Portlet>
	);
};
MessageForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
	isLoading: PropTypes.bool,
	emailAddresses: PropTypes.arrayOf(PropTypes.object),
	submit: PropTypes.func,
	isInPanel: PropTypes.bool,
};
MessageForm.defaultProps = {
	data: {
		id: 0,
		attachments: [],
	},
	setIsValid: () => {},
	isLoading: true,
	isSubmitted: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
	emailAddresses: [],
	submit: () => {},
	isInPanel: false,
};

export default MessageForm;
