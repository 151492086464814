import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Input from '../../reusables/field/Input';
import Toggle from '../../reusables/field/Toggle';
import Loading from '../../reusables/template/Loading';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';

const TaxCodeForm = ({
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	isLoading,
	onFormChange,
	submitButtonAttr,
	submit,
}) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);
	const [taxable, taxableOnChange] = useField(data, 'taxable', onFormChange, [], true);

	useEffect(() => {
		if (isSubmitted) setNameShowVal();
	}, [isSubmitted, setNameShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid);
	}, [nameValRes.isValid, setIsValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.accounting.default.text,
				path: pages.accounting.dashboard.path,
			},
			{
				title: pages.accounting.settings.text,
				path: pages.accounting.settings.path,
			},
			{
				title: pages.accounting.settings.taxCode.text,
				path: pages.accounting.settings.taxCode.path,
			},
			{ title: name || `New ${pages.accounting.settings.taxCode.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.accounting.settings.taxCode.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form' onSubmit={e => e.preventDefault()}>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='Tax Code Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='taxable' label='Taxable' id={data.id} colMd={6}>
								<Toggle value={taxable} onChange={taxableOnChange} />
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
TaxCodeForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		taxable: PropTypes.bool,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
TaxCodeForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		taxable: true,
	},
	setIsValid: () => {},
	isSubmitted: false,
	setTitle: () => {},
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default TaxCodeForm;
