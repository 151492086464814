import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Timeline, {
	TimelineMarkers,
	SidebarHeader,
	TodayMarker,
	CursorMarker,
	TimelineHeaders,
	DateHeader,
} from 'react-calendar-timeline';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import ThemeContext from '../../../app/contexts/ThemeContext';
import usePages from '../../../utils/hooks/usePages';
import {
	addErrorNotification,
	dateFormatter,
	getStateColorToRBG,
	getTimelineLoadingGroups,
	hasTouchScreen,
} from '../../../utils/helpers/helper';
import apiCall, { modules } from '../../../utils/helpers/apiCall';
import { bookingPeriods, reservationStatuses } from '../../../utils/constants/constants';
import ContentInner from '../../reusables/template/ContentInner';
import BreadcrumbContainer from '../../reusables/template/BreadcrumbContainer';
import Portlet from '../../reusables/layout/Portlet';
import Portal from '../../reusables/layout/Portal';
import SVGIcon from '../../reusables/element/SVGIcon';
import IconBox from '../../reusables/layout/IconBox';
import Dropdown from '../../reusables/element/Dropdown';
import Button from '../../reusables/element/Button';
import DatePicker from '../../reusables/field/DatePicker';
import ReservationStatusCell, {
	reservationColor,
} from '../../reusables/element/ReservationStatusCell';

const Availability = () => {
	const themeContext = useContext(ThemeContext);
	const headerContext = useContext(HeaderContext);
	const userContext = useContext(UserContext);

	const history = useHistory();
	const pages = usePages();

	const abortController = useRef(null);
	const bookingsModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.BOOKINGS)
	);

	const [isLoading, setIsLoading] = useState(true);
	const [groups, setGroups] = useState(getTimelineLoadingGroups());
	const [items, setItems] = useState([]);
	const [calendarStartTime, setCalendarStartTime] = useState(
		moment()
			.startOf('day')
			.add(-7, 'day')
	);
	const [calendarEndTime, setCalendarEndTime] = useState(
		moment()
			.endOf('day')
			.add(23, 'day')
	);
	const [dataStartTime, setDataStartTime] = useState(
		moment()
			.startOf('day')
			.add(-1, 'month')
			.add(-7, 'day')
	);
	const [dataEndTime, setDataEndTime] = useState(
		moment()
			.endOf('day')
			.add(1, 'month')
			.add(23, 'day')
	);
	const [currentDay, setCurrentDay] = useState(moment().startOf('day'));

	const goTo = useCallback(
		isPrev => {
			const diff = calendarEndTime.diff(calendarStartTime) * 0.85;

			setCalendarStartTime(
				moment(calendarStartTime.valueOf()).add(isPrev ? -diff : diff, 'milliseconds')
			);
			setCalendarEndTime(
				moment(calendarEndTime.valueOf()).add(isPrev ? -diff : diff, 'milliseconds')
			);
		},
		[calendarEndTime, calendarStartTime]
	);

	const goPath = useCallback(
		item => {
			if (item.unitBlockoutId)
				history.push(`${pages.booking.settings.unitBlackouts.path}/${item.unitBlockoutId}`);

			if (item.reservationId && item.reservationItemId)
				history.push({
					pathname: `${pages.booking.reservations.advanced.path}/${item.reservationId}`,
					state: {
						itemId: item.reservationItemId,
						form: 'availability',
					},
				});
		},
		[history, pages]
	);

	const hideShowGroups = useCallback(
		(id, type) => {
			if (type === 'unitGroup') {
				setGroups(
					groups.map(g => {
						if (g.id === id) g.isOpen = !g.isOpen;

						if (g.unitGroup === id) g.hide = !g.hide;

						return g;
					})
				);
			}

			if (type === 'bookingType') {
				const { isOpen } = groups.find(g => g.id === id);

				setGroups(
					groups.map(g => {
						if (g.id === id) g.isOpen = !isOpen;

						if (g.bookingType === id) {
							if (g.type === 'unitGroup') g.hide = isOpen;

							if (g.type === 'unit') {
								if (isOpen) g.hide = true;
								else {
									const unitGroup = groups.find(
										_g => _g.id === g.unitGroup && _g.type === 'unitGroup'
									);
									g.hide = !unitGroup?.isOpen;
								}
							}
						}

						return g;
					})
				);
			}
			setGroups([...groups]);
		},
		[groups]
	);

	const canAddEventToItems = useCallback(event => {
		return event.state > 0 || event.state === -4;
	}, []);

	const parseDataToCalendar = useCallback(
		data => {
			const tempGroups = [];
			const tempItems = [];

			const collapseUnitGroups =
				userContext.data.selectedOutlet.settings.bookingCollapseUnitGroups;

			Object.entries(data).forEach(bookingTypeEntry => {
				const [bookingTypeId, bookingType] = [...bookingTypeEntry];
				const bookingTypeGroupId = bookingTypeId;
				tempGroups.push({
					id: bookingTypeGroupId,
					title: bookingType.name,
					bookBy: bookingType.bookBy,
					type: 'bookingType',
					isOpen: true,
				});

				if (bookingType.groups) {
					Object.values(bookingType.groups)
						.sort((a, b) => (a.sortOrder || 0) > (b.sortOrder || 0))
						.filter(group => group.id !== 0)
						.forEach(group => {
							const unitGroupGroupId = `${group.name}${bookingTypeGroupId}-${group.id}`;

							const unitGroupGroup = groups.find(g => g.id === unitGroupGroupId);

							tempGroups.push({
								id: unitGroupGroupId,
								title: group.name,
								type: 'unitGroup',
								bookingType: bookingTypeGroupId,
								hide: false,
								isOpen: unitGroupGroup
									? unitGroupGroup.isOpen
									: !collapseUnitGroups,
							});
							const units = [];
							if (group.units) {
								Object.entries(group.units).forEach(unitEntry => {
									const [unitId, unit] = [...unitEntry];

									const unitGroupId = `${unitGroupGroupId}-${unitId}`;

									const unitGroup = groups.find(g => g.id === unitGroupId);

									units.push({
										id: unitGroupId,
										title: unit.name,
										type: 'unit',
										unitGroup: unitGroupGroupId,
										bookingType: bookingTypeGroupId,
										hide: unitGroup ? unitGroup.hide : collapseUnitGroups,
									});

									unit.events.forEach((event, index) => {
										if (canAddEventToItems(event)) {
											tempItems.push({
												id: `${unitGroupId}-${index}-${event.reservationItemId}`,
												reservationId: event.reservationId,
												reservationItemId: event.reservationItemId,
												group: unitGroupId,
												title:
													event.state === -4
														? event.unitBlockoutName
														: event.customerName,
												start_time: moment(event.start.date).valueOf(),
												end_time: moment(event.end.date).valueOf(),
												timeZone: 'UTC',
												canMove: false,
												canResize: false,
												canChangeGroup: false,
												reservationStatus: event.reservationStatus,
												customReservationStatus:
													event.customReservationStatus,
												itemProps: {
													'aria-hidden': true,
													className: classNames('sdms-booking__item'),
												},
												dataPeriod: event.bookingPeriod,
												selectedBgColor: 'rgba(225, 166, 244, 1)',
												state: event.state,
												unitBlockoutId: event.unitBlockoutId,
												reservationItems: event.reservationItems,
												isPaid: event.isPaid,
											});
										}
									});
								});
							}
							units.sort((a, b) => (a.title > b.title ? 1 : -1));
							tempGroups.push(...units);
						});

					if (bookingType.groups[0]) {
						const group = bookingType.groups[0];
						const groupId = 0;
						const unitGroupGroupId = `${group.name}${bookingTypeGroupId}-${groupId}`;

						const unitGroupGroup = groups.find(g => g.id === unitGroupGroupId);

						tempGroups.push({
							id: unitGroupGroupId,
							title: group.name,
							type: 'unitGroup',
							bookingType: bookingTypeGroupId,
							hide: false,
							isOpen: unitGroupGroup ? unitGroupGroup.isOpen : !collapseUnitGroups,
						});
						const units = [];
						if (group.units) {
							Object.entries(group.units).forEach(unitEntry => {
								const [unitId, unit] = [...unitEntry];
								const unitGroupId = `${unitGroupGroupId}-${unitId}`;
								const unitGroup = groups.find(g => g.id === unitGroupId);

								units.push({
									id: unitGroupId,
									title: unit.name,
									type: 'unit',
									unitGroup: unitGroupGroupId,
									bookingType: bookingTypeGroupId,
									hide: unitGroup ? unitGroup.hide : collapseUnitGroups,
								});
								unit.events.forEach((event, index) => {
									if (canAddEventToItems(event)) {
										tempItems.push({
											id: `${unitGroupId}-${index}-${event.reservationItemId}`,
											reservationId: event.reservationId,
											reservationItemId: event.reservationItemId,
											group: unitGroupId,
											title:
												event.state === -4
													? event.unitBlockoutName
													: event.customerName,
											start_time: moment(event.start.date).valueOf(),
											end_time: moment(event.end.date).valueOf(),
											timeZone: 'UTC',
											canMove: false,
											canResize: false,
											canChangeGroup: false,
											reservationStatus: event.reservationStatus,
											customReservationStatus: event.customReservationStatus,
											itemProps: {
												'aria-hidden': true,
												className: classNames('sdms-booking__item'),
											},
											dataPeriod: event.bookingPeriod,
											selectedBgColor: 'rgba(225, 166, 244, 1)',
											state: event.state,
											unitBlockoutId: event.unitBlockoutId,
											reservationItems: event.reservationItems,
											isPaid: event.isPaid,
										});
									}
								});
							});
						}
						units.sort((a, b) => (a.title > b.title ? 1 : -1));
						tempGroups.push(...units);
					}
				}
			});
			setGroups(tempGroups.length ? [...tempGroups] : []);
			if (tempItems.length) setItems(tempItems);
			setIsLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[canAddEventToItems, groups]
	);

	const getUnitStates = useCallback(() => {
		if (abortController.current) abortController.current.abort();

		abortController.current = new AbortController();

		apiCall(
			'POST',
			'reservationGetUnitStates',
			res => {
				parseDataToCalendar(res);
			},
			err => {
				if (err.toString().search('AbortError') === -1) {
					addErrorNotification(err.toString());
					setIsLoading(false);
				}
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				moduleId: bookingsModule.current.id,
				dateFrom: dataStartTime.toISOString(),
				dateTo: dataEndTime.toISOString(),
			},
			null,
			abortController.current.signal
		);
	}, [dataEndTime, dataStartTime, parseDataToCalendar, userContext.data.selectedOutlet.id]);

	const Item = ({ item, itemContext, getItemProps, getResizeProps }) => {
		const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

		const resolveReservationStatusForColor = item => {
			if (item.reservationStatus === reservationStatuses.CHECKED_OUT && !item.isPaid) {
				return reservationStatuses.CANCELLED;
			}

			return item.reservationStatus;
		};

		const itemIcon = () => {
			if (item.dataPeriod === bookingPeriods.NIGHTLY)
				return {
					icon: pages.booking.products.period.nightly.icon,
					design: pages.booking.products.period.nightly.color,
				};
			if (item.dataPeriod === bookingPeriods.DAILY)
				return {
					icon: pages.booking.products.period.daily.icon,
					design: pages.booking.products.period.daily.color,
				};
			if (item.dataPeriod === bookingPeriods.HOURLY)
				return {
					icon: pages.booking.products.period.hourly.icon,
					design: pages.booking.products.period.hourly.color,
				};

			// maintenance
			if (item.state === -4)
				return {
					icon: 'Screwdriver',
					design: 'brand',
				};

			// Default
			return {
				icon: 'Screwdriver',
				design: 'brand',
			};
		};

		return (
			<>
				<div
					role='presentation'
					data-status={item.reservationStatus}
					data-period={item.dataPeriod}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...getItemProps(
						Object.assign(item.itemProps, {
							style: {
								backgroundColor: getStateColorToRBG(
									reservationColor(resolveReservationStatusForColor(item)),
									itemContext.selected ? 1 : 0.7,
									false,
									themeContext.data.theme
								),
								borderColor: getStateColorToRBG(
									reservationColor(resolveReservationStatusForColor(item)),
									itemContext.selected ? 1 : 0.7,
									false,
									themeContext.data.theme
								),
								color: getStateColorToRBG(
									reservationColor(resolveReservationStatusForColor(item)),
									1,
									true,
									themeContext.data.theme
								),
							},
						})
					)}
					data-tip='tooltip'
					data-for={`booking-item_${item.id}`}
					title={null}
					onClick={() => goPath(item)}>
					{itemContext.useResizeHandle && <div {...leftResizeProps} />}
					<div
						{...item.itemProps}
						className='rct-item-content'
						style={{ maxHeight: `${itemContext.dimensions.height}` }}>
						{itemContext.title}
					</div>
					{itemContext.useResizeHandle && <div {...rightResizeProps} />}
				</div>
				<Portal>
					<ReactTooltip id={`booking-item_${item.id}`} type='light' className='sdms-p0'>
						<IconBox
							icon={itemIcon().icon}
							design={itemIcon().design}
							title={item.title}
							className='sdms-marginless sdms-portlet--fit'>
							<div>Start: {item && dateFormatter(item.start_time)}</div>
							<div>End: {item && dateFormatter(item.end_time)}</div>
							{item.reservationStatus && (
								<div>
									<ReservationStatusCell
										data={{
											status: { value: item.reservationStatus },
											customReservationStatus: {
												name: item.customReservationStatus,
											},
										}}
									/>
								</div>
							)}
						</IconBox>
					</ReactTooltip>
				</Portal>
			</>
		);
	};
	Item.propTypes = {
		// eslint-disable-next-line react/forbid-prop-types
		getItemProps: PropTypes.object.isRequired,
		// eslint-disable-next-line react/forbid-prop-types
		getResizeProps: PropTypes.object.isRequired,
		// eslint-disable-next-line react/forbid-prop-types
		item: PropTypes.object.isRequired,
		// eslint-disable-next-line react/forbid-prop-types
		itemContext: PropTypes.object.isRequired,
	};

	const Group = ({ group }) => {
		return (
			<div
				role='presentation'
				onClick={() => hideShowGroups(group.id, group.type)}
				className={classNames({
					'sdms-booking__booking-type': group.type === 'bookingType',
					'sdms-booking__unit-group': group.type === 'unitGroup',
					'sdms-booking__unit': group.type === 'unit',
				})}>
				<span className='title' title={group.title}>
					{group.title}
				</span>
				{group.type !== 'unit' && (
					<SVGIcon
						name='Angle-right'
						size={16}
						className={classNames('sdms-booking__unit-group-icon', {
							'sdms-booking__unit-group-icon--active': group.isOpen,
						})}
					/>
				)}
			</div>
		);
	};
	Group.propTypes = {
		group: PropTypes.shape({
			id: PropTypes.number,
			type: PropTypes.string,
			title: PropTypes.string,
			isOpen: PropTypes.bool,
			onClick: PropTypes.func,
		}).isRequired,
	};

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.booking.default.text, path: pages.booking.dashboard.path },
			{ title: pages.booking.availability.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.booking.availability.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	useEffect(() => {
		if (calendarEndTime.diff(calendarStartTime, 'days') <= 1)
			setCurrentDay(moment(calendarStartTime.valueOf()));
		else setCurrentDay(moment(calendarStartTime.valueOf()).add(7, 'day'));

		if (
			moment(calendarStartTime.valueOf())
				.add(-1, 'month')
				.valueOf() < dataStartTime.valueOf()
		)
			setDataStartTime(moment(calendarStartTime.valueOf()).add(-1, 'month'));

		if (
			moment(calendarEndTime.valueOf())
				.add(1, 'month')
				.valueOf() > dataEndTime.valueOf()
		)
			setDataEndTime(moment(calendarEndTime.valueOf()).add(1, 'month'));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [calendarStartTime, calendarEndTime]);

	useEffect(getUnitStates, [userContext.data.selectedOutlet, dataStartTime, dataEndTime]);

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title='Availability' />
					<BreadcrumbContainer />
					<ContentInner.SubHeaderSeparator />
					<ContentInner.SubHeaderGroup>
						<ContentInner.SubHeaderDesc>
							<DatePicker
								placeholder={`${dateFormatter(currentDay, false)} Today`}
								className='sdms-subheader__search'
								id='goToDate'
								type='calendar'
								value={
									moment(currentDay).format('ll') === moment().format('ll')
										? null
										: currentDay.toDate()
								}
								onChange={e => {
									const _currentDay = moment(currentDay.valueOf())
										.year(moment(e.target.value).year())
										.month(moment(e.target.value).month())
										.date(moment(e.target.value).date());

									setCurrentDay(_currentDay);

									// if visible range is a day.
									if (calendarEndTime.diff(calendarStartTime, 'days') <= 1) {
										setCalendarStartTime(
											moment(_currentDay.valueOf()).startOf('day')
										);
										setCalendarEndTime(
											moment(_currentDay.valueOf())
												.hour(23)
												.endOf('day')
										);
									} else {
										setCalendarStartTime(
											moment(_currentDay.valueOf())
												.add(-7, 'day')
												.startOf('day')
										);
										setCalendarEndTime(
											moment(_currentDay.valueOf())
												.add(23, 'day')
												.endOf('day')
										);
									}
								}}
								leftIcon='Layout-left-panel-1'
							/>
						</ContentInner.SubHeaderDesc>
						<ContentInner.SubHeaderDesc>
							<Button
								label='info'
								icon='Angle-double-left'
								size='sm'
								text='Prev'
								onClick={() => goTo(true)}
							/>
							<Button
								label='info'
								icon='Angle-double-right'
								size='sm'
								text='Next'
								onClick={() => goTo(false)}
							/>
						</ContentInner.SubHeaderDesc>
					</ContentInner.SubHeaderGroup>
				</ContentInner.SubHeaderItem>
				<ContentInner.SubHeaderItem type='toolbar'>
					<Button.Group key='buttonGroup'>
						<Button
							size='sm'
							key='add'
							label='brand'
							text='Add New'
							icon='Plus'
							onClick={() => history.push(`${pages.booking.reservations.path}/0`)}
						/>
						<Dropdown label='brand' aligned='right' isOnlyContent key='d'>
							<Dropdown.Item
								icon='Plus'
								key='advanceReservation'
								onClick={() =>
									history.push(`${pages.booking.reservations.advanced.path}/0`)
								}>
								Advanced Reservation
							</Dropdown.Item>
						</Dropdown>
					</Button.Group>
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title='Availability'>
				<Portlet
					className='sdms-form sdms-portlet--fit sdms-portlet--large-scrollbar'
					fluid='fluid'>
					<Portlet.Body
						className={classNames({
							'react-calendar-timeline--is-loading': isLoading,
						})}>
						<Timeline
							groups={groups.filter(g => !g.hide)}
							groupRenderer={Group}
							items={items}
							itemRenderer={Item}
							visibleTimeStart={calendarStartTime.valueOf()}
							visibleTimeEnd={calendarEndTime.valueOf()}
							sidebarWidth={250}
							lineHeight={40}
							onTimeChange={(
								visibleTimeStart,
								visibleTimeEnd,
								updateScrollCanvas
							) => {
								if (hasTouchScreen()) return;

								if (
									moment(visibleTimeEnd).diff(
										moment(visibleTimeStart),
										'months'
									) < 3
								) {
									updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
									setCalendarStartTime(moment(visibleTimeStart));
									setCalendarEndTime(moment(visibleTimeEnd));
								} else {
									updateScrollCanvas(
										calendarStartTime.valueOf(),
										calendarEndTime.valueOf()
									);
								}
							}}>
							<TimelineMarkers>
								<TodayMarker />
								<CursorMarker />
							</TimelineMarkers>
							<TimelineHeaders className='sticky'>
								<SidebarHeader>
									{({ getRootProps }) => {
										// eslint-disable-next-line react/jsx-props-no-spreading
										return (
											<div
												{...getRootProps()}
												className='sdms-portlet__head sdms-pl20'>
												<Portlet.HeadLabel
													className='w-100'
													portletIcon='Layout-left-panel-1'>
													<h3 className='sdms-portlet__head-title w-100'>
														<small>
															{moment(calendarStartTime).format(
																`${
																	moment(
																		calendarStartTime
																	).format('Y') ===
																	moment(calendarEndTime).format(
																		'Y'
																	)
																		? 'MMM DD'
																		: 'MMM DD, YYYY'
																}`
															)}{' '}
															to{' '}
															{moment(calendarEndTime).format(
																'MMM DD, YYYY'
															)}
														</small>
													</h3>
												</Portlet.HeadLabel>
											</div>
										);
									}}
								</SidebarHeader>
								<DateHeader unit='primaryHeader' />
								<DateHeader />
							</TimelineHeaders>
						</Timeline>
					</Portlet.Body>
				</Portlet>
			</ContentInner.Container>
		</>
	);
};

export default Availability;
