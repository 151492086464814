import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import HeaderContext from '../../../app/contexts/HeaderContext';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import Badge from '../../reusables/element/Badge';
import NullBadge from '../../reusables/design/NullBadge';
import { getUserRoleLevel } from '../../../utils/helpers/helper';
import UserContext from '../../../app/contexts/UserContext';
import pages from '../../pages';
import forms from '../../forms';

const RoleLevel = ({ data }) => {
	if (data.roleLevel === null) return <NullBadge />;
	return (
		<Badge design='primary' isDot>
			{data.roleLevel.name}
		</Badge>
	);
};

RoleLevel.propTypes = {
	data: PropTypes.shape({
		roleLevel: PropTypes.object,
	}),
};

RoleLevel.defaultProps = {
	data: {
		roleLevel: null,
	},
};

const RoleList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const currentUserRoleLevel = useRef(
		getUserRoleLevel(userContext.data.user, userContext.data.selectedOutlet)
	);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.systemSettings.default.text,
				path: pages.systemSettings.dashboard.path,
			},
			{ title: pages.systemSettings.roles.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.systemSettings.roles.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='roles'
			history={history}
			title='Roles'
			forms={forms.systemSettings.roles}>
			<List
				withCheckBox
				checkEditable={role =>
					userContext.data.user.isOwner ||
					userContext.data.user.isInstallerUser ||
					(currentUserRoleLevel.current &&
						currentUserRoleLevel.current >= role.roleLevel.level)
						? ['edit', 'delete']
						: []
				}
				fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col
					label='Role Level'
					cellComponent={<RoleLevel />}
					sortable='roleLevel.level'
				/>
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

RoleList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default RoleList;
