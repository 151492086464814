import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SVGIcon from './SVGIcon';

const Alert = ({
	design,
	solid,
	outline,
	icon,
	iconSize,
	title,
	content,
	children,
	className,
	bold,
	marginLess,
	elevate,
}) => {
	return (
		<div
			className={classNames(
				'alert',
				{ [`alert-${design}`]: !solid && !outline },
				{ [`alert-solid-${design}`]: solid },
				{ [`alert-outline-${design}`]: outline },
				{ 'alert-bold': bold },
				{ 'alert--marginless': marginLess },
				{ 'alert-elevate': elevate },
				className
			)}
			role='alert'>
			{icon && (
				<div className='alert-icon'>
					<SVGIcon
						name={icon}
						size={iconSize}
						fill={solid || outline ? `var(--${design})` : '#000'}
					/>
				</div>
			)}
			<div className='alert-text'>
				{title && <h4 className='alert-heading'>{title}</h4>}
				{title ? <div>{content || children}</div> : content || children}
			</div>
		</div>
	);
};

Alert.propTypes = {
	design: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'info',
		'primary',
		'secondary',
		'success',
		'warning',
	]),
	solid: PropTypes.bool,
	outline: PropTypes.bool,
	icon: PropTypes.string,
	iconSize: PropTypes.number,
	title: PropTypes.string,
	content: PropTypes.node,
	children: PropTypes.node,
	className: PropTypes.string,
	bold: PropTypes.bool,
	marginLess: PropTypes.bool,
	elevate: PropTypes.bool,
};
Alert.defaultProps = {
	design: 'info',
	solid: false,
	outline: false,
	icon: null,
	iconSize: null,
	title: null,
	content: null,
	className: null,
	children: null,
	bold: false,
	marginLess: true,
	elevate: true,
};

export default Alert;
