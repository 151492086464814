import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderContext from '../../../app/contexts/HeaderContext';

import usePages from '../../../utils/hooks/usePages';
import { getModuleByPath, getModuleKeyByPath } from '../../../utils/helpers/reusable';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import Badge from '../../reusables/element/Badge';

import forms from '../../forms';

const DefaultCell = ({ data }) => (data.isDefault ? <Badge.Yes /> : <Badge.No />);

DefaultCell.propTypes = {
	data: PropTypes.shape({
		isDefault: PropTypes.bool,
	}),
};

DefaultCell.defaultProps = {
	data: {
		isDefault: false,
	},
};

const AnalyticsDashboardList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const moduleKey = getModuleKeyByPath();

	const page = pages.analytics[moduleKey];

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.analytics.default.text, path: pages.analytics.dashboard.path },
			{ title: page.text, path: page.path, isActive: true },
		]);

		headerContext.setPageTitle(page.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='analyticsDashboards'
			title={`${page.text} Analytics`}
			history={history}
			icon={page.icon}
			forms={forms.analytics[moduleKey]}
			customActions={{
				get: row => {
					history.push(
						`${pages.analytics.default.path}/${moduleKey}/dashboard/${row.id}`
					);
				},
			}}
			staticFilters={{
				'module.value': getModuleByPath(),
			}}>
			<List fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn />
				<List.Col label='Default' cellComponent={<DefaultCell />} />
				<List.Col align='right' width={300}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='View Dashboard'
						icon='Chart-line#1'
						size='sm'
						key='get'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='link' icon='Edit' key='edit'>
							Edit
						</Dropdown.Item>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

AnalyticsDashboardList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default AnalyticsDashboardList;
