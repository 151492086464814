import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import usePages from '../../../utils/hooks/usePages';
import UserContext from '../../../app/contexts/UserContext';

import ContentInner from '../../reusables/template/ContentInner';
import IconBox from '../../reusables/layout/IconBox';

import logo from '../../../assets/img/logo.svg';

const _Dashboard = () => {
	const pages = usePages();

	const history = useHistory();

	const userContext = useContext(UserContext);

	const _colClass = 'col-xl-4 col-md-6';

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title={pages.companySettings.dashboard.text} />
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title={pages.companySettings.dashboard.text}>
				<div className='row'>
					<div className='col-3' />
					<div className='col-6 sdms-align-center'>
						<ReactSVG
							src={logo}
							className='svg-container sdms-block-center sdms-w-35p-desktop sdms-margin-t-30'
						/>
					</div>
					<div className='offset-xl-2 col-xl-8 offset-lg-1 col-lg-10 col-md-12 sdms-mt-50'>
						<div className='row sdms-iconbox--container'>
							<div className={_colClass}>
								<IconBox
									icon={pages.companySettings.company.icon}
									title={pages.companySettings.company.text}
									onClick={() => history.push(pages.companySettings.company.path)}
									animate={null}
									noPermission={
										!userContext.hasPermission(
											pages.companySettings.company.permission
										)
									}>
									Edit Company Details. View, Add & Edit Locations.
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.companySettings.module.icon}
									title={pages.companySettings.module.text}
									onClick={() => history.push(pages.companySettings.module.path)}
									animate={null}
									noPermission={false}>
									Edit module defaults
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.companySettings.outletDefaults.icon}
									title={pages.companySettings.outletDefaults.text}
									onClick={() =>
										history.push(pages.companySettings.outletDefaults.path)
									}
									animate={null}>
									Edit Outlet Defaults
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.companySettings.paymentProcessors.icon}
									title={pages.companySettings.paymentProcessors.text}
									onClick={() =>
										history.push(pages.companySettings.paymentProcessors.path)
									}
									animate={null}
									noPermission={!userContext.hasPermission(null, true)}>
									Edit Payment Processors
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.companySettings.printers.icon}
									title={pages.companySettings.printers.text}
									onClick={() =>
										history.push(pages.companySettings.printers.path)
									}
									animate={null}
									noPermission={
										!userContext.hasPermission(
											pages.companySettings.printers.permission
										)
									}>
									View, Add & Edit Printers
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.companySettings.reservationSettings.icon}
									title={pages.companySettings.reservationSettings.text}
									onClick={() =>
										history.push(pages.companySettings.reservationSettings.path)
									}
									animate={null}>
									Reservation Settings
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.companySettings.companySyncs.icon}
									title={pages.companySettings.companySyncs.text}
									onClick={() =>
										history.push(pages.companySettings.companySyncs.path)
									}
									animate={null}
									noPermission={false}>
									View, Add & Edit Syncs.
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.companySettings.templateSettings.icon}
									title={pages.companySettings.templateSettings.text}
									onClick={() =>
										history.push(pages.companySettings.templateSettings.path)
									}
									animate={null}>
									Edit Template Settings
								</IconBox>
							</div>
						</div>
					</div>
				</div>
			</ContentInner.Container>
		</>
	);
};

export default _Dashboard;
