import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import Portal from '../layout/Portal';

import { noPermissionStatus } from '../../../utils/helpers/helper';
import { pathToUrl } from '../../../utils/helpers/apiCall';

import SVGIcon, { iconToUrl } from './SVGIcon';
import Badge from './Badge';

const ItemWrapper = ({
	order,
	text,
	media,
	icon,
	children,
	onClick,
	isDisabled,
	subClassName,
	hiddenPrice,
	className,
	onlyGrid,
	showTooltip,
}) => {
	const textRef = useRef();

	const [displayTooltip, setDisplayToolTip] = useState(false);

	const onResize = useCallback(() => {
		setDisplayToolTip(
			showTooltip && textRef.current
				? textRef.current.offsetWidth < textRef.current.scrollWidth
				: false
		);
	}, [showTooltip, textRef]);

	useEffect(() => {
		onResize();

		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, [onResize]);

	return (
		<>
			<div
				role='presentation'
				data-tip='tooltip'
				data-for={`sdms-t-product-grid__item_${order}`}
				data-order={order}
				className={classNames(
					'sdms-t-product-grid__item-wrapper',
					{
						'col-xl-2': !onlyGrid,
						'col-lg-3': !onlyGrid,
						'col-md-4': !onlyGrid,
						'col-6': !onlyGrid,
					},
					{
						'col-xl-2': onlyGrid,
						'col-lg-2': onlyGrid,
						'col-md-3': onlyGrid,
						'col-6': onlyGrid,
					},
					className
				)}>
				<div
					className={classNames(
						'sdms-t-product-grid__inner',
						{ disable: isDisabled },
						subClassName
					)}
					aria-hidden='true'
					onClick={onClick}>
					<div className='sdms-t-product-grid__title'>
						<span ref={textRef}>{text}</span>
					</div>
					<div
						className='sdms-t-product-grid__image'
						style={{
							backgroundImage: media
								? `url(${pathToUrl(media)})`
								: iconToUrl(`${icon || null}`),
						}}
					/>
					<div
						className={classNames('sdms-t-product-grid__info', {
							'justify-content-center':
								children && typeof children.props.children !== 'object',
							'justify-content-end': hiddenPrice,
						})}>
						{children}
					</div>
				</div>
			</div>
			{displayTooltip && (
				<Portal>
					<ReactTooltip
						id={`sdms-t-product-grid__item_${order}`}
						type='light'
						place='bottom'>
						{text}
					</ReactTooltip>
				</Portal>
			)}
		</>
	);
};
ItemWrapper.propTypes = {
	order: PropTypes.number,
	children: PropTypes.node,
	media: PropTypes.string,
	icon: PropTypes.string,
	text: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	isDisabled: PropTypes.bool,
	subClassName: PropTypes.string,
	hiddenPrice: PropTypes.bool,
	className: PropTypes.string,
	onlyGrid: PropTypes.bool,
	showTooltip: PropTypes.bool,
};
ItemWrapper.defaultProps = {
	order: 1,
	children: null,
	media: null,
	icon: null,
	onClick: () => {},
	isDisabled: false,
	subClassName: null,
	hiddenPrice: false,
	className: null,
	onlyGrid: false,
	showTooltip: false,
};

export const Item = ({
	order,
	text,
	media,
	qty,
	price,
	isModifier,
	alwaysModify,
	addByPrice,
	addByQuantity,
	onClick,
	isDisabled,
	className,
	icon,
	onlyGrid,
	showTooltip,
}) => {
	return (
		<ItemWrapper
			order={order}
			text={text}
			media={media}
			isDisabled={isDisabled || (!price && qty === 0)}
			onClick={onClick}
			hiddenPrice={addByPrice}
			className={className}
			icon={icon}
			onlyGrid={onlyGrid}
			showTooltip={showTooltip}>
			{price ? (
				<>
					{!addByPrice && <span className='sdms-font-bold'>{price}</span>}
					{isModifier && !alwaysModify && <SVGIcon name='Clipboard-check' />}
					{alwaysModify && <SVGIcon name='Clipboard-list' fill='var(--warning)' />}
					{addByPrice && <SVGIcon name='Dollar' />}
					{!addByPrice && addByQuantity && <SVGIcon name='Cart#1' />}
				</>
			) : (
				<Badge design='warning' isElevate isInline fontWeight='bold'>
					{qty > 0 ? `${qty} Items` : 'Empty'}
				</Badge>
			)}
		</ItemWrapper>
	);
};
Item.propTypes = {
	order: PropTypes.number.isRequired,
	text: PropTypes.string.isRequired,
	media: PropTypes.string,
	qty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isModifier: PropTypes.bool,
	alwaysModify: PropTypes.bool,
	addByPrice: PropTypes.bool,
	addByQuantity: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool,
	className: PropTypes.string,
	icon: PropTypes.string,
	onlyGrid: PropTypes.bool,
	showTooltip: PropTypes.bool,
};
Item.defaultProps = {
	media: null,
	qty: null,
	price: null,
	isModifier: false,
	alwaysModify: false,
	addByPrice: false,
	addByQuantity: false,
	isDisabled: false,
	className: null,
	icon: null,
	onlyGrid: false,
	showTooltip: false,
};

export const Back = ({ isAfterSearch, onClick, onlyGrid }) => {
	return (
		<ItemWrapper
			text='Back'
			icon={isAfterSearch ? 'Backspace' : 'Undo'}
			onClick={onClick}
			onlyGrid={onlyGrid}
			showTooltip={false}>
			<Badge design='info' isElevate isInline fontWeight='bold'>
				{isAfterSearch ? 'Clear Search' : 'Up Folder'}
			</Badge>
		</ItemWrapper>
	);
};
Back.propTypes = {
	isAfterSearch: PropTypes.bool,
	onClick: PropTypes.func,
	onlyGrid: PropTypes.bool,
};
Back.defaultProps = {
	isAfterSearch: false,
	onClick: () => {},
	onlyGrid: false,
};

export const SearchMore = ({ onClick, noPermission, onlyGrid }) => {
	return (
		<ItemWrapper
			subClassName={classNames(noPermissionStatus(noPermission))}
			text='Search More'
			icon='Search'
			onClick={noPermission || onClick}
			onlyGrid={onlyGrid}
			showTooltip={false}
		/>
	);
};
SearchMore.propTypes = {
	onClick: PropTypes.func.isRequired,
	noPermission: PropTypes.bool,
	onlyGrid: PropTypes.bool,
};
SearchMore.defaultProps = {
	noPermission: null,
	onlyGrid: false,
};

export default Item;
