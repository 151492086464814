import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import useDate from '../../../utils/hooks/useDate';
import usePages from '../../../utils/hooks/usePages';
import useField from '../../../utils/hooks/useField';
import {
	isBeforeAccountingClosedPeriod,
	generateId,
	getAccountingClosedDate,
	getCreditRemainingAmount,
	getItemTaxCode,
	negativeNumberParser,
	numberFormat,
	priceFormatter,
} from '../../../utils/helpers/helper';
import { getOrderItemTaxRate, calculateOrderTotals } from '../../../utils/helpers/orderHelper';
import { getPaymentRefundedAmount } from '../../../utils/helpers/paymentHelper';
import { required } from '../../../utils/helpers/validation';
import { discountCalculations, paymentStatuses } from '../../../utils/constants/constants';

import Portlet from '../../reusables/layout/Portlet';
import FormGroup from '../../reusables/layout/FormGroup';
import Button from '../../reusables/element/Button';
import { ListBody, ListTable } from '../../reusables/template/List';
import Alert from '../../reusables/element/Alert';
import Separator from '../../reusables/layout/Separator';
import FormField from '../../reusables/template/FormField';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import Loading from '../../reusables/template/Loading';
import Selects from '../../reusables/field/Selects';
import DatePicker from '../../reusables/field/DatePicker';
import Input from '../../reusables/field/Input';

const calculateCreditMemoItemTotals = (item, taxRateAmount) => {
	const itemTotals = {
		price: 0,
		quantity: 0,
		subtotal: 0,
		discountAmount: 0,
		taxableAmount: 0,
		additionalTax: 0,
		taxRate: 0,
		taxTotal: 0,
		total: 0,
	};

	itemTotals.price = item.price;

	itemTotals.quantity = item.quantity;

	itemTotals.subtotal = numberFormat(item.price * item.quantity);

	itemTotals.taxableAmount = item.taxCode && item.taxCode.taxable ? itemTotals.subtotal : 0;

	itemTotals.taxRate = item.taxCode.taxable ? taxRateAmount : 0;

	if (item.discount && item.discountType) {
		itemTotals.discountAmount =
			item.discountType.value === 'percent'
				? numberFormat((itemTotals.subtotal * item.discountAmount) / 100)
				: item.discountAmount;

		if (itemTotals.discountAmount > itemTotals.subtotal)
			itemTotals.discountAmount = itemTotals.subtotal;

		if (item.discount.taxCode.taxable && itemTotals.taxableAmount > 0)
			itemTotals.taxableAmount -= itemTotals.discountAmount;
	}

	itemTotals.taxTotal =
		numberFormat((itemTotals.taxableAmount * taxRateAmount) / 100) + itemTotals.additionalTax;

	itemTotals.total = itemTotals.subtotal - itemTotals.discountAmount + itemTotals.taxTotal;

	return itemTotals;
};

const CreditMemoItem = ({
	data,
	discounts,
	taxCodes,
	taxRates,
	enumCalculations,
	onFormChange,
	taxRate,
	onChange,
	isSubmitted,
	setIsValid,
	onDelete,
	disabled,
	customerTaxCode,
	updateTaxCode,
}) => {
	const [product, productOnChange, productValRes, productShowVal, setProductShowVal] = useField(
		data,
		'product',
		onFormChange,
		[required],
		null
	);

	const [discount, discountOnChange] = useField(data, 'discount', onFormChange, [], null);

	const [taxCode, taxCodeOnChange, taxCodeValRes, taxCodeShowVal, setTaxCodeShowVal] = useField(
		data,
		'taxCode',
		onFormChange,
		[required],
		null
	);

	const [
		quantity,
		quantityOnChange,
		quantityValRes,
		quantityShowVal,
		setQuantityShowVal,
	] = useField(data, 'quantity', onFormChange, [required], '', negativeNumberParser);

	const [price, priceOnChange, priceValRes, priceShowVal, setPriceShowVal] = useField(
		data,
		'price',
		onFormChange,
		[required],
		'',
		negativeNumberParser
	);

	const [totals, setTotals] = useState({
		price: 0,
		quantity: 0,
		subtotal: 0,
		discountAmount: 0,
		taxableAmount: 0,
		additionalTax: 0,
		taxRate: 0,
		taxTotal: 0,
		total: 0,
	});

	const calculateTotals = () => {
		if (!product) return;

		const _totals = calculateCreditMemoItemTotals(data, data.taxRate);

		data.subtotal = _totals.subtotal;

		setTotals(_totals);

		onChange();
	};

	useEffect(
		calculateTotals,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[quantity, price, product, taxCode, discount, taxRate]
	);

	useEffect(() => {
		if (isSubmitted) {
			setProductShowVal();
			setTaxCodeShowVal();
			setQuantityShowVal();
			setPriceShowVal();
		}
	}, [isSubmitted, setProductShowVal, setTaxCodeShowVal, setQuantityShowVal, setPriceShowVal]);

	useEffect(() => {
		setIsValid(
			productValRes.isValid &&
				taxCodeValRes.isValid &&
				quantityValRes.isValid &&
				priceValRes.isValid
		);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [productValRes.isValid, taxCodeValRes.isValid, quantityValRes.isValid, priceValRes.isValid]);

	useEffect(() => {
		if (product && updateTaxCode.current) {
			taxCodeOnChange({ target: { value: getItemTaxCode(customerTaxCode, product) } });
			updateTaxCode.current = false;
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [customerTaxCode]);

	return (
		<tr className={classNames({ disabled })}>
			<td>
				<FormField
					name='product'
					id={data.id}
					valRes={productValRes}
					showValidation={productShowVal}
					inFormDesign={false}
					isLast>
					<AsyncSelect
						options={data.product ? [data.product] : []}
						placeholder='Search and select product from list'
						value={product}
						onChange={e => {
							const _taxCode = getItemTaxCode(customerTaxCode, e.target.value);

							// update order item tax rate amount.
							data.taxRate =
								e.target.value && _taxCode.taxable
									? getOrderItemTaxRate(
											e.target.value,
											e.target.value.price,
											taxRate.amount,
											_taxCode
									  )
									: 0;

							data.name = e.target.value ? e.target.value.name : '';

							priceOnChange({
								target: { value: e.target.value ? e.target.value.price : '' },
							});

							taxCodeOnChange({
								target: {
									value: _taxCode,
								},
							});

							if (!quantity) quantityOnChange({ target: { value: 1 } });

							productOnChange(e);
						}}
						onBlur={setProductShowVal}
						route='products'
						renderOption={option => (
							<>
								{option.name} - {priceFormatter(option.price)}
							</>
						)}
						disabled={disabled}
					/>
				</FormField>
			</td>
			<td>
				<FormField name='discount' id={data.id} inFormDesign={false} isLast>
					<Selects
						value={discount}
						options={discounts.filter(
							d =>
								d.calculation.value !== discountCalculations.PER_QUANTITY ||
								d.eligibleProducts.some(p => p.id === product?.id)
						)}
						onChange={e => {
							data.discountType = e.target.value
								? enumCalculations.find(
										ec =>
											ec.value ===
											(e.target.value.calculation.value ===
											discountCalculations.PERCENT
												? discountCalculations.PERCENT
												: discountCalculations.FIXED)
								  )
								: null;

							data.discountAmount = 0;

							if (e.target.value)
								data.discountAmount =
									e.target.value.calculation.value ===
									discountCalculations.PER_QUANTITY
										? (quantity || 1) * e.target.value.amount
										: e.target.value.amount;

							discountOnChange(e);
						}}
						disabled={(data.product && data.product.itemType.isSystem) || disabled}
					/>
				</FormField>
			</td>
			<td>
				<FormField name='itemTaxRates' id={data.id} inFormDesign={false} isLast>
					<Selects
						options={taxRates}
						multiple
						value={product && product.additionalTaxes ? product.additionalTaxes : []}
						valueKey='name'
						onChange={() => {}}
						disabled
					/>
				</FormField>
			</td>
			<td>
				<FormField
					name='quantity'
					id={data.id}
					inFormDesign={false}
					showValidation={quantityShowVal}
					valRes={quantityValRes}
					isLast>
					<Input
						type='text'
						placeholder='Qty'
						value={quantity}
						onChange={e => {
							if (
								data.discount?.calculation?.value ===
								discountCalculations.PER_QUANTITY
							) {
								data.discountAmount = data.discount.amount * (e.target.value || 1);
							}

							quantityOnChange(e);
						}}
						onBlur={setQuantityShowVal}
						disabled={(data.product && data.product.itemType.isSystem) || disabled}
					/>
				</FormField>
			</td>
			<td>
				<FormField
					name='price'
					id={data.id}
					inFormDesign={false}
					showValidation={priceShowVal}
					valRes={priceValRes}
					isLast>
					<Input
						type='text'
						placeholder='Price'
						value={price}
						onChange={priceOnChange}
						onBlur={setPriceShowVal}
						pattern={process.env.REACT_APP_NEGATIVE_PRICE_PATTERN}
						disabled={(data.product && data.product.itemType.isSystem) || disabled}
					/>
				</FormField>
			</td>
			<td>
				<FormField
					name='taxCode'
					id={data.id}
					inFormDesign={false}
					showValidation={taxCodeShowVal}
					valRes={taxCodeValRes}
					isLast>
					<Selects
						value={taxCode}
						options={taxCodes}
						onChange={e => {
							if (e.target.value && product && price)
								data.taxRate = getOrderItemTaxRate(
									product,
									price,
									taxRate.amount,
									e.target.value
								);

							taxCodeOnChange(e);
						}}
						onBlur={setTaxCodeShowVal}
						disabled={(data.product && data.product.itemType.isSystem) || disabled}
						disableClearable
					/>
				</FormField>
			</td>
			<td>{priceFormatter(totals.subtotal)}</td>
			<td>{priceFormatter(totals.taxTotal)}</td>
			<td>{priceFormatter(totals.total)}</td>
			<td>
				<div className='row'>
					<div className='col' style={{ paddingTop: 8 }}>
						<Button
							btnIcon
							label='danger'
							icon='Trash'
							size='sm'
							elevate
							key='delete'
							onClick={onDelete}
							disabled={disabled}
						/>
					</div>
				</div>
			</td>
		</tr>
	);
};

CreditMemoItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	discounts: PropTypes.arrayOf(PropTypes.object),
	taxRates: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	enumCalculations: PropTypes.arrayOf(PropTypes.object),
	onFormChange: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	taxRate: PropTypes.object,
	onChange: PropTypes.func,
	isSubmitted: PropTypes.bool,
	setIsValid: PropTypes.func,
	onDelete: PropTypes.func,
	disabled: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	customerTaxCode: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	updateTaxCode: PropTypes.object,
};
CreditMemoItem.defaultProps = {
	data: {
		id: 0,
	},
	discounts: [],
	taxCodes: [],
	taxRates: [],
	enumCalculations: [],
	onFormChange: () => {},
	taxRate: null,
	onChange: () => {},
	isSubmitted: false,
	setIsValid: () => {},
	onDelete: () => {},
	disabled: false,
	customerTaxCode: null,
	updateTaxCode: null,
};

/**
 * Some fields like paymentTerm or taxRate getting and setting to sdmsOrder because we need refund order to create credit memo on api and qb.
 */
const CreditMemoForm = ({
	data,
	onFormChange,
	isLoading,
	isSubmitted,
	setIsValid,
	taxCodes,
	discounts,
	enumCalculations,
	taxRates,
	paymentTerms,
	isEditable,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const [, , parseDatePickerValue, parseDatePickerChange] = useDate();

	const updateItemTaxCode = useRef(false);

	// set true when getting data from order done.
	const init = useRef(false);

	const isClosed =
		!isLoading &&
		data.id !== 0 &&
		isBeforeAccountingClosedPeriod(data.remittanceDate || data.timeCreated, userContext);

	// if credit has any settlement or voided do not allow to edit.
	const disabled =
		!data.sdmsOrder ||
		(data.customerSettlements && data.customerSettlements.length > 0) ||
		(data.status && data.status.value === paymentStatuses.VOIDED) ||
		isClosed ||
		!isEditable;

	// do not trigger onFormChange until getting data from order done.
	const customOnFromChange = () => {
		if (init.current) onFormChange();
	};

	const [invalidItems, setInvalidItems] = useState([]);

	const [
		customer,
		customerOnChange,
		customerValRes,
		customerShowVal,
		setCustomerShowVal,
	] = useField(data, 'customer', customOnFromChange, [required], '', null);

	const [
		remittanceDate,
		remittanceDateOnChange,
		remittanceDateValRes,
		remittanceDateShowVal,
		setRemittanceDateShowVal,
	] = useField(data, 'remittanceDate', customOnFromChange, [required], null);

	const [paymentTerm, paymentTermOnChange] = useField(
		data,
		'paymentTerm',
		customOnFromChange,
		[],
		null
	);

	const [taxRate, taxRateOnChange, taxRateValRes, taxRateShowVal, setTaxRateShowVal] = useField(
		data,
		'taxRate',
		customOnFromChange,
		[required],
		userContext.data.selectedOutlet.settings.taxRate
	);

	const [
		customerTaxCode,
		customerTaxCodeOnChange,
		customerTaxCodeValRes,
		customerTaxCodeShowVal,
		setCustomerTaxCodeShowVal,
	] = useField(
		data,
		'customerTaxCode',
		customOnFromChange,
		[required],
		userContext.data.user.company.settings
			? userContext.data.user.company.settings.defaultTaxCode
			: null
	);

	const [reference, referenceOnChange] = useField(data, 'reference', customOnFromChange, [], '');

	const [totals, setTotals] = useState({
		subtotal: 0,
		taxTotal: 0,
		discountTotal: 0,
		total: 0,
	});

	const [remainingAmount, setRemainingAmount] = useState(0);

	const getBanner = () => {
		if (isClosed)
			return (
				<div className='col-12 sdms-mb-20'>
					<Alert solid icon='Error-circle' design='danger'>
						Accounting Period Closed
					</Alert>
				</div>
			);

		if (data.customerSettlements && getPaymentRefundedAmount(data) > 0)
			return (
				<div className='col-12 sdms-mb-20'>
					<Alert solid icon='Check' design='success' bold>
						Refunded
					</Alert>
				</div>
			);

		return null;
	};

	const calculateTotals = () => {
		if (!data.sdmsOrder) {
			setTotals({
				subtotal: 0,
				taxTotal: 0,
				total: data.amount,
				discountTotal: 0,
				taxDiscount: 0,
			});
			return;
		}

		const _totals = { ...totals, ...calculateOrderTotals(data.sdmsOrder) };

		data.sdmsOrder = {
			...data.sdmsOrder,
			..._totals,
		};

		data.amount = _totals.total;

		setRemainingAmount(getCreditRemainingAmount(data));

		setTotals(_totals);
	};

	const onItemDelete = itemId => {
		data.sdmsOrder.orderItems = data.sdmsOrder.orderItems.filter(i => i.id !== itemId);

		setInvalidItems(invalidItems.filter(i => i !== itemId));

		calculateTotals();

		onFormChange();
	};

	useEffect(() => {
		if (isSubmitted) {
			setCustomerShowVal();
			setRemittanceDateShowVal();
			setTaxRateShowVal();
			setCustomerTaxCodeShowVal();
		}
	}, [
		isSubmitted,
		setCustomerShowVal,
		setRemittanceDateShowVal,
		setTaxRateShowVal,
		setCustomerTaxCodeShowVal,
	]);

	useEffect(() => {
		if (disabled) setIsValid(false);
		else
			setIsValid(
				customerValRes.isValid &&
					remittanceDateValRes.isValid &&
					taxRateValRes.isValid &&
					customerTaxCodeValRes.isValid &&
					invalidItems.length === 0
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		data,
		setIsValid,
		customerValRes.isValid,
		remittanceDateValRes.isValid,
		taxRateValRes.isValid,
		customerTaxCodeValRes.isValid,
		invalidItems,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.crm.default.text, path: pages.crm.dashboard.path },
			{ title: pages.crm.creditMemos.text, path: pages.crm.creditMemos.path },
			{ title: `Credit Memo ${data.remittanceId || 'New'}`, isActive: true },
		]);

		headerContext.setPageTitle(`Invoice ${data.invoiceId || 'New'}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.id]);

	// if credit is not new get data from order and set init true.
	useEffect(() => {
		if (!isLoading) {
			if (data.id === 0) {
				data.outlet = userContext.data.selectedOutlet;

				data.sdmsOrder = {
					orderItems: [],
					taxRate: userContext.data.selectedOutlet.settings.taxRate,
				};
			} else if (data.sdmsOrder) {
				paymentTermOnChange({
					target: { value: data.sdmsOrder.paymentTerm },
				});
				taxRateOnChange({
					target: { value: data.sdmsOrder.taxRate },
				});
				customerTaxCodeOnChange({
					target: { value: data.customer.taxCode },
				});
			}

			init.current = true;

			calculateTotals();
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	useEffect(() => {
		if (!isLoading && init.current) calculateTotals();

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [taxRate]);

	useEffect(() => {
		setRemainingAmount(getCreditRemainingAmount(data));
	}, [data]);

	return (
		<Portlet
			fluid='fluid'
			className='sdms-bg-transparent sdms-portlet--unelevate sdms-portlet--portletception sdms-mb-0'>
			<Portlet.Body className='sdms-last-margin sdms-p0 overflow-hidden'>
				<Portlet className='flex-grow-0 sdms-portlet__modifiers sdms-min-h-fit-content'>
					<Portlet.Body>
						<FormGroup>
							{getBanner()}
							<Loading isLoading={isLoading}>
								<FormField
									name='customer'
									label='Customer'
									id={data.id}
									colMd={3}
									valRes={customerValRes}
									showValidation={customerShowVal}>
									<AsyncSelect
										options={data.customer ? [data.customer] : []}
										placeholder='Search and select customer'
										value={customer}
										onChange={e => {
											customerOnChange(e);

											if (e.target.value && e.target.value.taxCode)
												customerTaxCodeOnChange({
													target: { value: e.target.value.taxCode },
												});

											updateItemTaxCode.current = true;
										}}
										route='customers'
										field='displayName'
										displayKey='displayName'
										onBlur={setCustomerShowVal}
										disabled={data.id !== 0 || disabled}
										customFilters={{ isActive: true }}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='remittanceDate'
									label='Date'
									id={data.id}
									loadingContainer
									colMd={2}
									valRes={remittanceDateValRes}
									showValidation={remittanceDateShowVal}>
									<DatePicker
										id='remittanceDate'
										type='calendar'
										placeholder='Date'
										value={parseDatePickerValue(remittanceDate)}
										onChange={e => {
											remittanceDateOnChange({
												target: {
													value: parseDatePickerChange(e.target.value),
												},
											});
										}}
										onBlur={setRemittanceDateShowVal}
										disabled={data.id !== 0 || disabled}
										disableClear
										minDate={getAccountingClosedDate(userContext)}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='paymentTerm'
									label='Payment Term'
									id={data.id}
									colMd={3}>
									<Selects
										options={paymentTerms}
										placeholder='Payment Term'
										value={paymentTerm}
										onChange={e => {
											data.sdmsOrder.paymentTerm = e.target.value;
											paymentTermOnChange(e);
										}}
										disabled={disabled}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='referemce'
									label='Reference'
									id={data.id}
									colLg={4}>
									<Input
										type='text'
										placeholder='Reference'
										value={reference}
										onChange={referenceOnChange}
										disabled={disabled}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Portlet.Body>
				</Portlet>
				<Portlet className='sdms-list-layout' fluid='fluid' border>
					<Portlet.Head>
						<Portlet.HeadLabelTitle>Items</Portlet.HeadLabelTitle>
						<Portlet.HeadToolbarActions>
							<Button
								label='brand'
								icon='Plus'
								text='New Item'
								key='newItem'
								size='sm'
								onClick={() => {
									const itemId = generateId(data.sdmsOrder.orderItems);

									data.sdmsOrder.orderItems.push({
										id: itemId,
									});

									setInvalidItems([...invalidItems, itemId]);

									onFormChange();
								}}
								disabled={disabled}
							/>
						</Portlet.HeadToolbarActions>
					</Portlet.Head>
					<ListBody
						className='table--everytime--scroll sdms-portlet__body--fit'
						responsive='scroll'>
						<ListTable childrenLength={10} width={1530}>
							<colgroup>
								<col />
								<col />
								<col />
								<col style={{ width: 110 }} />
								<col style={{ width: 110 }} />
								<col style={{ width: 110 }} />
								<col style={{ width: 110 }} />
								<col style={{ width: 110 }} />
								<col style={{ width: 110 }} />
								<col style={{ width: 180 }} />
							</colgroup>
							<thead>
								<tr>
									<th>Product</th>
									<th>Discount</th>
									<th>Additional Taxes</th>
									<th>Qty</th>
									<th>Price</th>
									<th>Tax Code</th>
									<th>Amount</th>
									<th>Tax</th>
									<th>Total</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{data.sdmsOrder &&
									data.sdmsOrder.orderItems.map(orderItem => (
										<CreditMemoItem
											key={orderItem.id}
											data={orderItem}
											enumCalculations={enumCalculations}
											discounts={discounts}
											onFormChange={customOnFromChange}
											taxCodes={taxCodes}
											taxRates={
												taxRate
													? taxRates.filter(tr => tr.id !== taxRate.id)
													: []
											}
											taxRate={taxRate}
											onChange={() => {
												calculateTotals();
											}}
											setIsValid={_isValid => {
												setInvalidItems(
													_isValid
														? invalidItems.filter(
																itemId => itemId !== orderItem.id
														  )
														: [...invalidItems, orderItem.id]
												);
											}}
											isSubmitted={isSubmitted}
											onDelete={() => onItemDelete(orderItem.id)}
											disabled={disabled || isLoading}
											customerTaxCode={customerTaxCode}
											updateTaxCode={updateItemTaxCode}
										/>
									))}
							</tbody>
						</ListTable>
					</ListBody>
				</Portlet>
				<Portlet className='flex-grow-0 sdms-portlet__modifiers sdms-min-h-fit-content'>
					<Portlet.Body>
						<div className='row'>
							<div className='col-md-8 ml-md-auto'>
								<div className='row'>
									<Loading isLoading={isLoading}>
										<FormField
											name='taxRate'
											id={data.id}
											inFormDesign={false}
											label='Tax Rate'
											colMd={4}
											valRes={taxRateValRes}
											showValidation={taxRateShowVal}>
											<Selects
												value={taxRate}
												options={taxRates}
												onChange={e => {
													// update order tax rate for calculation
													data.sdmsOrder.taxRate = e.target.value;
													taxRateOnChange(e);
												}}
												disabled={disabled}
												onBlur={setTaxRateShowVal}
												disableClearable
												append={`${taxRate ? taxRate.amount : 0}%`}
											/>
										</FormField>
									</Loading>
								</div>
								<div className='row'>
									<Loading isLoading={isLoading}>
										<FormField
											name='customerTaxCode'
											id={data.id}
											inFormDesign={false}
											label='Customer Tax Code'
											colMd={4}
											valRes={customerTaxCodeValRes}
											showValidation={customerTaxCodeShowVal}>
											<Selects
												value={customerTaxCode}
												options={taxCodes}
												onChange={customerTaxCodeOnChange}
												disabled
												onBlur={setCustomerTaxCodeShowVal}
												disableClearable
											/>
										</FormField>
									</Loading>
								</div>
							</div>
							<div className='col-md-4 ml-md-auto'>
								<Alert outline design='dark' marginLess>
									<div className='row sdms-fitText--md sdms-mb-10 '>
										<div className='col'>Subtotal</div>
										<div className='col-auto text-right'>
											<span>{priceFormatter(totals.subtotal)}</span>
										</div>
									</div>
									<div className='row sdms-fitText--md sdms-mb-10'>
										<div className='col'>Tax</div>
										<div className='col-auto text-right'>
											<span>{priceFormatter(totals.taxTotal)}</span>
										</div>
									</div>
									{totals.discountTotal ? (
										<div className='row sdms-fitText--md sdms-mb-10'>
											<div className='col'>Discount</div>
											<div className='col-auto text-right'>
												<span>{priceFormatter(totals.discountTotal)}</span>
											</div>
										</div>
									) : null}
									<div className='row sdms-fitText--md sdms-mb-10'>
										<div className='col'>Total</div>
										<div className='col-auto text-right'>
											<span>{priceFormatter(totals.total)}</span>
										</div>
									</div>

									<Separator space='sm' />
									<div className='row sdms-fitText--lg sdms-font-bolder'>
										<div className='col'>Remaining Credit</div>
										<div className='col-auto text-right'>
											<span>{priceFormatter(remainingAmount)}</span>
										</div>
									</div>
								</Alert>
							</div>
						</div>
					</Portlet.Body>
				</Portlet>
			</Portlet.Body>
		</Portlet>
	);
};

CreditMemoForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	onFormChange: PropTypes.func,
	isLoading: PropTypes.bool,
	isSubmitted: PropTypes.bool,
	setIsValid: PropTypes.func,
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	discounts: PropTypes.arrayOf(PropTypes.object),
	enumCalculations: PropTypes.arrayOf(PropTypes.object),
	taxRates: PropTypes.arrayOf(PropTypes.object),
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	isEditable: PropTypes.bool,
};

CreditMemoForm.defaultProps = {
	data: { id: 0 },
	onFormChange: () => {},
	isLoading: false,
	isSubmitted: false,
	setIsValid: () => {},
	taxCodes: [],
	discounts: [],
	enumCalculations: [],
	taxRates: [],
	paymentTerms: [],
	isEditable: true,
};

export default CreditMemoForm;
