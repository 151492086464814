import React from 'react';
import PropTypes from 'prop-types';

import { modules } from '../../../utils/helpers/apiCall';
import usePages from '../../../utils/hooks/usePages';

import FormContainer from '../../reusables/template/FormContainer';
import BookingTypeForm from './BookingTypeForm';

const BookingTypeEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='bookingTypeCampgrounds'
			lists={[
				'enumBookBies',
				'enumSearchTypes',
				'unitMaps',
				'productFilters',
				'enumSearchResultViews',
				'templates',
			]}
			fields={<BookingTypeForm />}
			pageTitle={pages.campground.settings.bookingType.text}
			icon={pages.campground.settings.bookingType.icon}
			module={modules.CAMPGROUND}
		/>
	);
};

BookingTypeEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default BookingTypeEdit;
