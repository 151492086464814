import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormGroup from '../../reusables/layout/FormGroup';
import Loading from '../../reusables/template/Loading';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import useField from '../../../utils/hooks/useField';
import { numeric, required } from '../../../utils/helpers/validation';
import { numberParser } from '../../../utils/helpers/helper';
import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import { modules } from '../../../utils/helpers/apiCall';

const ProductCategoryExtraChargeForm = ({
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	onFormChange,
	isLoading,
	submitButtonAttr,
	submit,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const campgroundModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.CAMPGROUND)
	);

	const maxChargeQuantityValidation = (value, setValRes) => {
		if (minChargeQuantity > value) {
			setValRes({
				isValid: false,
				status: 'minMaxValidation',
				message: 'Maximum charge quantity must be higher than minimum charge quantity!',
			});
			return false;
		}
		return true;
	};

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [
		minChargeQuantity,
		minChargeQuantityOnChange,
		minChargeQuantityValRes,
		minChargeQuantityShowVal,
		setMinChargeQuantityShowVal,
	] = useField(data, 'minChargeQuantity', onFormChange, [numeric], 0, numberParser(false, 0));

	const [
		maxChargeQuantity,
		maxChargeQuantityOnChange,
		maxChargeQuantityValRes,
		maxChargeQuantityShowVal,
		setMaxChargeQuantityShowVal,
		validateMaxCharge,
	] = useField(
		data,
		'maxChargeQuantity',
		onFormChange,
		[numeric, maxChargeQuantityValidation],
		'',
		numberParser(false)
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setMinChargeQuantityShowVal();
			setMaxChargeQuantityShowVal();
		}
	}, [isSubmitted, setNameShowVal, setMinChargeQuantityShowVal, setMaxChargeQuantityShowVal]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid && minChargeQuantityValRes.isValid && maxChargeQuantityValRes.isValid
		);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [
		nameValRes.isValid,
		minChargeQuantityValRes.isValid,
		maxChargeQuantityValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		if (!isLoading) data.module = campgroundModule.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		validateMaxCharge(maxChargeQuantity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [minChargeQuantity]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.campground.default.text,
				path: pages.campground.dashboard.path,
			},
			{
				title: pages.campground.settings.text,
				path: pages.campground.settings.path,
			},
			{
				title: pages.campground.settings.extraChargeGroups.text,
				path: pages.campground.settings.extraChargeGroups.path,
			},
			{
				title: name || `New ${pages.campground.settings.extraChargeGroups.text}`,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(
			name || `New ${pages.campground.settings.extraChargeGroups.text}`
		);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='Product Category Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='minChargeQuantity'
								label='Min. Quantity'
								id={data.id}
								valRes={minChargeQuantityValRes}
								showValidation={minChargeQuantityShowVal}
								colMd={3}>
								<Input
									type='number'
									withOutSpin
									placeholder='Min Charge Quantity'
									min={0}
									value={minChargeQuantity.toString()}
									onChange={minChargeQuantityOnChange}
									onBlur={setMinChargeQuantityShowVal}
									pattern={process.env.REACT_APP_INTEGER_PATTERN}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='maxChargeQuantity'
								label='Max. Quantity'
								id={data.id}
								valRes={maxChargeQuantityValRes}
								showValidation={maxChargeQuantityShowVal}
								colMd={3}>
								<Input
									type='number'
									withOutSpin
									min={0}
									placeholder='Max Charge Quantity'
									value={maxChargeQuantity.toString()}
									onChange={maxChargeQuantityOnChange}
									onBlur={setMaxChargeQuantityShowVal}
									pattern={process.env.REACT_APP_INTEGER_PATTERN}
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
ProductCategoryExtraChargeForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		module: PropTypes.object,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
ProductCategoryExtraChargeForm.defaultProps = {
	data: {
		id: 0,
		module: null,
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default ProductCategoryExtraChargeForm;
