import React, { createRef, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import update from 'immutability-helper';
import moment from 'moment';
import Timeline from 'react-calendar-timeline/lib/lib/Timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import { SidebarHeader, TimelineHeaders } from 'react-calendar-timeline';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import { pricingTypes } from '../../../utils/constants/constants';
import useDate from '../../../utils/hooks/useDate';

import Button from '../../reusables/element/Button';
import Portal from '../../reusables/layout/Portal';
import Portlet from '../../reusables/layout/Portlet';
import Selects from '../../reusables/field/Selects';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Toggle from '../../reusables/field/Toggle';
import Dropdown from '../../reusables/element/Dropdown';
import FormGroup from '../../reusables/layout/FormGroup';
import DatePicker from '../../reusables/field/DatePicker';

const DateModal = ({ onClose, onSubmit, open }) => {
	const [customDate, setCustomDate] = useState(
		moment()
			.hour(0)
			.minute(0)
			.second(0)
			.millisecond(0)
	);
	return (
		<Portal>
			<Popup
				open={open}
				onClose={onClose}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
				}}>
				<Portlet>
					<Portlet.Head>
						<Portlet.HeadLabelTitle>
							Add Effective Date to Calendar
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<FormGroup>
							<FormField
								name='customCalendar'
								label='Custom Calendar'
								description='Select a custom calendar. Leave blank for standard calendar'
								col={6}>
								<DatePicker
									id='toDate'
									type='calendar'
									value={customDate.toDate()}
									onChange={e => {
										setCustomDate(
											moment(customDate)
												.year(moment(e.target.value).year())
												.month(moment(e.target.value).month())
												.date(moment(e.target.value).date())
										);
									}}
								/>
							</FormField>
						</FormGroup>
					</Portlet.Body>
					<Portlet.Foot className='sdms-align-left' tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
							/>
						</div>
						<div className='col-auto'>
							<Button
								label='brand'
								icon='Done-circle'
								text='Ok'
								size='sm'
								onClick={() => {
									onSubmit({
										date: `${customDate.format('YYYY-MM-DD')}T00:00:00+00:00`,
									});
									setCustomDate(
										moment()
											.hour(0)
											.minute(0)
											.second(0)
											.millisecond(0)
									);
									onClose();
								}}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};
DateModal.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	open: PropTypes.bool,
};
DateModal.defaultProps = {
	onClose: () => {},
	onSubmit: () => {},
	open: false,
};

const RatePlanModal = ({
	onClose,
	onSubmit,
	ratePlans,
	selectedRatePlans,
	open,
	productBookingPeriodId,
}) => {
	const [ratePlan, setRatePlan] = useState(null);

	return (
		<Portal>
			<Popup
				open={open}
				onClose={onClose}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
				}}>
				<Portlet>
					<Portlet.Head>
						<Portlet.HeadLabelTitle>Add Rate Plan</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<FormField
							name='ratePlan'
							label='Rate Plan'
							description='Select Rate Plan'
							col={6}>
							<Selects
								options={ratePlans.filter(
									rp =>
										selectedRatePlans.findIndex(srp => srp.id === rp.id) ===
											-1 && rp.bookingPeriod.id === productBookingPeriodId
								)}
								placeholder='Rate Plan'
								value={ratePlan}
								onChange={e => setRatePlan(e.target.value)}
								displayKey='internalName'
							/>
						</FormField>
					</Portlet.Body>
					<Portlet.Foot className='sdms-align-left' tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
							/>
						</div>
						<div className='col-auto'>
							<Button
								label='brand'
								icon='Done-circle'
								text='Ok'
								size='sm'
								disabled={!ratePlan}
								onClick={() => {
									onSubmit(ratePlan);
									setRatePlan({});
									onClose();
								}}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};
RatePlanModal.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool,
	selectedRatePlans: PropTypes.arrayOf(PropTypes.object),
	productBookingPeriodId: PropTypes.number,
};
RatePlanModal.defaultProps = {
	onClose: () => {},
	onSubmit: () => {},
	ratePlans: [],
	open: false,
	selectedRatePlans: [],
	productBookingPeriodId: 0,
};

const getItemBgColor = itemContext =>
	itemContext.selected ? 'rgba(256, 256, 256, 0)' : 'transparent';

const getItemInput = (item, ref) => {
	if (item.group === 0) {
		return (
			<>
				<span
					data-tip={item.effectiveDate}
					data-for={item.effectiveDate}
					className='sdms-text-overflow'
					style={{ color: 'black' }}>
					{item.effectiveDate}
				</span>
				<Portal>
					<ReactTooltip id={item.effectiveDate} effect='solid'>
						<span>{item.effectiveDate}</span>
					</ReactTooltip>
				</Portal>
			</>
		);
	}
	if (item.row === 1)
		return (
			<Toggle
				onChange={e =>
					item.updateItem({
						id: item.id,
						row: item.row,
						productRatePlan: item.productRatePlan,
						value: e.target.value,
					})
				}
				value={item.value}
				size='sm'
				color='success'
				outline
				spaceLess
			/>
		);

	if (item.row < 7)
		return (
			<Input
				ref={ref}
				value={item.value}
				type={item.row === 2 ? 'text' : 'number'}
				withOutSpin
				tabIndex={item.row}
				onChange={e =>
					item.updateItem({
						id: item.id,
						row: item.row,
						productRatePlan: item.productRatePlan,
						value: e.target.value,
					})
				}
				pattern={
					item.row === 2
						? process.env.REACT_APP_PRICE_PATTERN
						: process.env.REACT_APP_INTEGER_PATTERN
				}
			/>
		);

	return (
		<Input
			ref={ref}
			type='number'
			withOutSpin
			value={item.value}
			min={0}
			tabIndex={item.row}
			pattern={process.env.REACT_APP_INTEGER_PATTERN}
			onChange={e =>
				item.updateItem({
					id: item.id,
					productRatePlan: item.productRatePlan,
					value: e.target.value,
					type: item.type,
					row: item.row,
				})
			}
			append={item.type}
		/>
	);
};

const ItemRenderer = ({ item, itemContext, getItemProps }) => {
	const inputRef = createRef();

	return (
		<div
			{...getItemProps({
				onMouseDown: () => {
					if (inputRef.current) inputRef.current.focus();
				},
				style: { backgroundColor: getItemBgColor(itemContext), border: 'none' },
				className: 'sdms-booking-advPrc__item',
			})}>
			{getItemInput(item, inputRef)}
		</div>
	);
};
ItemRenderer.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	item: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	itemContext: PropTypes.object,
	getItemProps: PropTypes.func,
};
ItemRenderer.defaultProps = {
	item: {},
	itemContext: {},
	getItemProps: () => {},
};

const GroupRenderer = ({ group }) => {
	return (
		<div
			className={classNames('sdms-booking-advPrc__group', {
				'sdms-booking-advPrc__group--title': group.isRatePlanGroup,
			})}>
			<span className='title' title={group.title}>
				{group.title}
			</span>
			{group.isRatePlanGroup && (
				<Dropdown
					icon='Other#1'
					color='clean'
					inline
					aligned='right'
					circle
					size='sm'
					outline={false}
					menuSize='sm'>
					<Dropdown.Header>Group Actions</Dropdown.Header>
					<Dropdown.Item itemsColor='danger' icon='Trash' onClick={group.onClick}>
						Delete
					</Dropdown.Item>
				</Dropdown>
			)}
		</div>
	);
};
GroupRenderer.propTypes = {
	group: PropTypes.shape({
		title: PropTypes.string,
		id: PropTypes.number,
		ratePlan: PropTypes.string,
		groupRow: PropTypes.number,
		isRatePlanGroup: PropTypes.bool,
		modal: PropTypes.func,
		onClick: PropTypes.func,
		groupReference: PropTypes.shape({
			current: PropTypes.number,
		}),
		groupRatePlanReference: PropTypes.shape({
			current: PropTypes.object,
		}),
	}).isRequired,
};

const rowToKey = {
	0: 'effectiveDate',
	1: 'enabled',
	2: 'price',
	3: 'minDuration',
	4: 'maxDuration',
	5: 'minBookInAdvance',
	6: 'maxBookIndAdvance',
	7: 'minLoa',
	8: 'maxLoa',
	9: 'minBeam',
	10: 'maxBeam',
	11: 'minHeight',
	12: 'maxHeight',
	17: 'minSqft',
	18: 'maxSqft',
	19: 'minPricingLoa',
};

const inchItems = [7, 8, 9, 10, 11, 12, 15, 16, 19];

const ProductCampgroundAdvancedPricingLongTerm = ({ product, ratePlans, onFormChange }) => {
	const [whichModalOpen, setWhichModalOpen] = useState('');
	const groupReference = useRef(0);
	const groupRatePlanReference = useRef({});

	const numberOfSelectedRatePlans = useRef(0);

	const [, utcDateFormatter] = useDate();

	const [startTime, setStartTime] = useState(
		moment()
			.startOf('day')
			.valueOf()
	);

	const [endTime, setEndTime] = useState(
		moment()
			.add(1, 'w')
			.endOf('day')
			.add(12, 'hour')
			.valueOf()
	);

	const parseFt = value => (value > 12 ? parseInt(value / 12, 10) : 0);

	const parseData = (data, item, value) => {
		if (item.row === 1) return value;

		if (item.row === 2) return value ? parseFloat(value) / item.costPer : null;

		if (inchItems.indexOf(item.row) > -1) {
			if (value === '' && !data[rowToKey[item.row]]) return null;

			const oldValue = data[rowToKey[item.row]] || 0;

			const _value = value || 0;

			if (item.type === 'ft') return parseFloat(_value) * 12 + (oldValue % 12);

			return oldValue - (oldValue % 12) + parseFloat(_value);
		}

		return parseFloat(value);
	};

	const generateProductRatePlans = useCallback(
		(ratePlan, date) => {
			const productRatePlans = [];

			productRatePlans.push({
				id: ratePlan.id,
				ratePlan: ratePlan['@id'],
				enabled: true,
				price: product.price,
				minBookInAdvance: ratePlan.minBookInAdvance,
				maxBookInAdvance: ratePlan.maxBookInAdvance,
				minDuration: ratePlan.minDuration,
				maxDuration: ratePlan.maxDuration,
				minLoa: ratePlan.minLoa,
				maxLoa: ratePlan.maxLoa,
				minBeam: ratePlan.minBeam,
				maxBeam: ratePlan.maxBeam,
				minHeight: ratePlan.minHeight,
				maxHeight: ratePlan.maxHeight,
				minSqft: ratePlan.minSqft,
				maxSqft: ratePlan.maxSqft,
				minPricingLoa: ratePlan.minPricingLoa,
				effectiveDate: date,
			});

			return productRatePlans;
		},
		[product]
	);

	const getVehicleConstraintsItemTimes = useCallback(
		type => {
			const middle = moment(startTime + (endTime - startTime) / 2);

			if (type === 'in') {
				return {
					start_time: middle.startOf('day').valueOf(),
					end_time: middle
						.add(1, 'day')
						.endOf('day')
						.valueOf(),
				};
			}

			if (type === 'ft')
				return {
					start_time: middle
						.add(-2, 'day')
						.startOf('day')
						.valueOf(),
					end_time: middle
						.add(1, 'day')
						.endOf('day')
						.valueOf(),
				};

			return {
				start_time: middle
					.add(-2, 'day')
					.startOf('day')
					.valueOf(),
				end_time: middle
					.add(3, 'day')
					.endOf('day')
					.valueOf(),
			};
		},
		[startTime, endTime]
	);

	const generateItems = useCallback(
		(date, whichDate, productRatePlans, itemsGroups, ratePlanList, updateItem) => {
			const items = [];
			productRatePlans
				.sort((a, b) => new Date(a.effectiveDate) - new Date(b.effectiveDate))
				.forEach(prp => {
					if (prp.effectiveDate === date) {
						const ratePlanIndex = ratePlanList.findIndex(
							rp => rp['@id'] === prp.ratePlan
						);

						itemsGroups.forEach(ig => {
							if (ig.ratePlan === prp.ratePlan && ig.groupRow < 7) {
								let isInItems = false;
								items.forEach(i => {
									if (i.id === `${ig.id}${prp.id}${whichDate}`) isInItems = true;
								});
								if (!isInItems)
									items.push({
										id: `${ig.id}${prp.id}${whichDate}`,
										group: ig.id,
										start_time: moment(startTime)
											.add(whichDate, 'day')
											.valueOf(),
										end_time: moment(startTime)
											.add(whichDate + 1, 'day')
											.valueOf(),
										productRatePlan: prp.id,
										ratePlan: prp.ratePlan,
										value: prp[rowToKey[ig.groupRow]] || '',
										updateItem,
										row: ig.groupRow,
										costPer: ratePlanList[ratePlanIndex].costPer,
										canMove: false,
										canResize: false,
										canChangeGroup: false,
										effectiveDate: utcDateFormatter(date),
									});
							}
						});
					}
				});
			return items;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const generateVehicleItems = useCallback(
		(productRatePlans, itemsGroups, updateItem, stateItems) => {
			const items = [];
			const uniqueRatePlans = [];
			productRatePlans.forEach(prp => {
				let isIn = false;
				uniqueRatePlans.forEach(urp => {
					if (urp.ratePlan === prp.ratePlan) isIn = true;
				});
				if (!isIn) uniqueRatePlans.push(prp);
			});
			uniqueRatePlans.forEach(uprp => {
				itemsGroups.forEach(ig => {
					if (ig.ratePlan === uprp.ratePlan && ig.groupRow >= 7) {
						if (inchItems.indexOf(ig.groupRow) > -1) {
							if (
								stateItems.findIndex(
									item => item.id === `${ig.id}${ig.ratePlan}-1`
								) === -1
							)
								items.push({
									id: `${ig.id}${ig.ratePlan}-1`,
									group: ig.id,
									ratePlan: uprp.ratePlan,
									value: uprp[rowToKey[ig.groupRow]]
										? parseFt(uprp[rowToKey[ig.groupRow]])
										: '',
									updateItem,
									row: ig.groupRow,
									canMove: false,
									canResize: false,
									canChangeGroup: false,
									type: 'ft',
									...getVehicleConstraintsItemTimes('ft'),
								});
							if (
								stateItems.findIndex(
									item => item.id === `${ig.id}${ig.ratePlan}-2`
								) === -1
							)
								items.push({
									id: `${ig.id}${ig.ratePlan}-2`,
									group: ig.id,
									ratePlan: uprp.ratePlan,
									value: uprp[rowToKey[ig.groupRow]]
										? uprp[rowToKey[ig.groupRow]] % 12
										: '',
									updateItem,
									row: ig.groupRow,
									canMove: false,
									canResize: false,
									canChangeGroup: false,
									type: 'in',
									...getVehicleConstraintsItemTimes('in'),
								});
						} else if (
							stateItems.findIndex(item => item.id === `${ig.id}${ig.ratePlan}`) ===
							-1
						)
							items.push({
								id: `${ig.id}${ig.ratePlan}`,
								group: ig.id,
								ratePlan: uprp.ratePlan,
								value: uprp[rowToKey[ig.groupRow]] || '',
								updateItem,
								row: ig.groupRow,
								canMove: false,
								canResize: false,
								canChangeGroup: false,
								type: ig.groupRow === 13 || ig.groupRow === 14 ? 'lbs' : 'sqft',
								...getVehicleConstraintsItemTimes(
									ig.groupRow === 13 || ig.groupRow === 14 ? 'lbs' : 'sqft'
								),
							});
					}
				});
			});
			return items;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const pricePer = useCallback(ratePlan => {
		let unit = '';

		if (ratePlan.pricingType.value.includes('month'))
			unit = ratePlan.costPer > 1 ? `${ratePlan.costPer} Months` : 'Month';

		if (ratePlan.pricingType.value.includes('year'))
			unit = ratePlan.costPer > 1 ? `${ratePlan.costPer} Years` : 'Year';

		if (
			ratePlan.pricingType.value === pricingTypes.PER_MONTH ||
			ratePlan.pricingType.value === pricingTypes.PER_YEAR
		)
			return `Price per ${unit}`;

		if (ratePlan.pricingType.value.includes('Sqft')) return `Price per ${unit} per Sqft`;

		return `Price per ${unit} per LOA`;
	}, []);

	const generateGroups = useCallback(
		ratePlan => {
			const groups = [
				{
					id: ratePlan.id,
					groupRow: 1,
					title: ratePlan.internalName,
					isRatePlanGroup: true,
					modal: setWhichModalOpen,
					groupReference,
					onClick: () => dispatch({ type: 'removeRatePlan', payload: ratePlan }),
					ratePlan: ratePlan['@id'],
				},
				{
					id: ratePlan.id + 0.1,
					groupRow: 2,
					modal: setWhichModalOpen,
					groupReference,
					groupRatePlanReference,
					title: pricePer(ratePlan),
					ratePlan: ratePlan['@id'],
				},
			];

			if (ratePlan.minDuration)
				groups.push({
					id: ratePlan.id + 0.2,
					groupRow: 3,
					modal: setWhichModalOpen,
					groupReference,
					groupRatePlanReference,
					title: 'Minimum Length of Stay',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxDuration)
				groups.push({
					id: ratePlan.id + 0.3,
					groupRow: 4,
					groupRatePlanReference,
					modal: setWhichModalOpen,
					groupReference,
					title: 'Maximum Length of Stay',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minBookInAdvance)
				groups.push({
					id: ratePlan.id + 0.4,
					groupRow: 5,
					groupRatePlanReference,
					modal: setWhichModalOpen,
					groupReference,
					title: 'Minimum Book in Advance',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxBookInAdvance)
				groups.push({
					id: ratePlan.id + 0.5,
					groupRow: 6,
					groupRatePlanReference,
					modal: setWhichModalOpen,
					groupReference,
					title: 'Maximum Book in Advance',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minLoa)
				groups.push({
					id: ratePlan.id + 0.6,
					groupRow: 7,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum LOA',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxLoa)
				groups.push({
					id: ratePlan.id + 0.7,
					groupRow: 8,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum LOA',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minBeam)
				groups.push({
					id: ratePlan.id + 0.8,
					groupRow: 9,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum Beam',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxBeam)
				groups.push({
					id: ratePlan.id + 0.9,
					groupRow: 10,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Beam',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minHeight)
				groups.push({
					id: ratePlan.id + 0.01,
					groupRow: 11,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum Height',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxHeight)
				groups.push({
					id: ratePlan.id + 0.011,
					groupRow: 12,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Height',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minSqft)
				groups.push({
					id: ratePlan.id + 0.016,
					groupRow: 17,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum Sqft',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.maxSqft)
				groups.push({
					id: ratePlan.id + 0.017,
					groupRow: 18,
					groupRatePlanReference,
					groupReference,
					title: 'Maximum Sqft',
					ratePlan: ratePlan['@id'],
				});

			if (ratePlan.minPricingLoa)
				groups.push({
					id: ratePlan.id + 0.018,
					groupRow: 19,
					groupRatePlanReference,
					groupReference,
					title: 'Minimum pricing LOA',
					ratePlan: ratePlan['@id'],
				});

			groups.unshift({
				id: 0,
				groupRow: 0,
				groupRatePlanReference,
				groupReference,
				title: '',
				ratePlan: ratePlan['@id'],
			});
			return groups;
		},
		[pricePer]
	);

	const reducer = (state, action) => {
		const { type, payload } = { ...action };

		if (type === 'set' && payload.productRatePlanLongTerms) {
			state = update(state, {
				$merge: {
					effectiveDates: [],
					productRatePlanLongTerms: payload.productRatePlanLongTerms,
					ratePlans: [],
					itemsGroups: [],
					items: [],
				},
			});

			payload.productRatePlanLongTerms.forEach(prp => {
				const ratePlanObject = ratePlans.find(rp => rp['@id'] === prp.ratePlan);

				if (state.effectiveDates.findIndex(ed => ed === prp.effectiveDate) === -1)
					state = update(state, {
						effectiveDates: { $push: [prp.effectiveDate] },
					});

				if (state.ratePlans.findIndex(_rp => _rp.id === ratePlanObject.id) === -1) {
					state = update(state, {
						ratePlans: {
							$push: [ratePlanObject],
						},
						itemsGroups: {
							$push: generateGroups(ratePlanObject),
						},
					});
				}
			});

			state = update(state, {
				itemsGroups: {
					$set: state.itemsGroups.filter((ig, index) => {
						return ig.id !== 0 || (ig.id === 0 && index === 0);
					}),
				},
			});

			state.effectiveDates.forEach((effectiveDate, index) => {
				state = update(state, {
					items: {
						$push: generateItems(
							effectiveDate,
							index,
							state.productRatePlanLongTerms,
							state.itemsGroups,
							ratePlans,
							item =>
								dispatch({
									type: 'updateItem',
									payload: { ...item, effectiveDate },
								})
						),
					},
				});
			});
			state = update(state, {
				items: {
					$push: generateVehicleItems(
						state.productRatePlanLongTerms,
						state.itemsGroups,
						item => dispatch({ type: 'updateItem', payload: item }),
						state.items
					),
				},
			});

			return state;
		}

		if (type === 'reRenderVehicleConstraintsItems') {
			state.items.forEach((item, index) => {
				if (item.row >= 7) {
					state = update(state, {
						items: {
							[index]: {
								$merge: getVehicleConstraintsItemTimes(item.type),
							},
						},
					});
				}
			});

			return state;
		}

		if (type === 'addDateToCalendar') {
			if (
				state.effectiveDates.findIndex(effectiveDate => effectiveDate === payload.date) > -1
			)
				return state;

			state = update(state, {
				effectiveDates: {
					$push: [payload.date],
				},
			});

			if (state.itemsGroups.length !== 0) {
				const uniqueRatePlanIds = [];
				state.itemsGroups.forEach(ig => {
					if (!uniqueRatePlanIds.includes(ig.ratePlan))
						uniqueRatePlanIds.push(ig.ratePlan);
				});
				const newProductRatePlans = [];
				uniqueRatePlanIds.forEach(urp => {
					const ratePlanIndex = ratePlans.findIndex(rp => rp['@id'] === urp);
					const generatedProductRatePlans = generateProductRatePlans(
						ratePlans[ratePlanIndex],
						payload.date
					);
					newProductRatePlans.push(...generatedProductRatePlans);
				});
				state = update(state, {
					productRatePlanLongTerms: {
						$push: newProductRatePlans,
					},
				});
				state.itemsGroups.forEach((ig, index) => {
					if (ig.id === 0 && index !== 0)
						state = update(state, {
							itemsGroups: {
								$push: state.itemsGroups.splice(index, 1),
							},
						});
				});
				state = update(state, {
					items: {
						$push: generateItems(
							payload.date,
							state.effectiveDates.length - 1,
							state.productRatePlanLongTerms,
							state.itemsGroups,
							ratePlans,
							item =>
								dispatch({
									type: 'updateItem',
									payload: { ...item, effectiveDate: payload.date },
								})
						),
					},
				});
				state = update(state, {
					items: {
						$push: generateVehicleItems(
							state.productRatePlanLongTerms,
							state.itemsGroups,
							item => dispatch({ type: 'updateItem', payload: item }),
							state.items
						),
					},
				});
				product.productRatePlanLongTerms = [...state.productRatePlanLongTerms];
			}

			onFormChange();

			return state;
		}

		if (type === 'addRatePlan') {
			if (state.effectiveDates.length === 0) return state;

			if (
				state.productRatePlanLongTerms.findIndex(prp => prp.ratePlan === payload['@id']) >
				-1
			)
				return state;
			const groups = generateGroups(payload);

			state = update(state, {
				ratePlans: {
					$push: [payload],
				},
				itemsGroups: {
					$push: groups,
				},
			});
			state.itemsGroups.forEach((ig, index) => {
				if (ig.id === 0 && index !== 0)
					state = update(state, {
						itemsGroups: {
							$push: state.itemsGroups.splice(index, 1),
						},
					});
			});
			const newProductRatePlans = [];

			const newItems = [];

			let generatedProductRatePlans = [];
			state.effectiveDates.forEach((date, index) => {
				generatedProductRatePlans = generateProductRatePlans(payload, date);
				newProductRatePlans.push(...generatedProductRatePlans);
				newItems.push(
					...generateItems(
						date,
						index,
						generatedProductRatePlans,
						groups,
						ratePlans,
						item =>
							dispatch({
								type: 'updateItem',
								payload: { ...item, effectiveDate: date },
							})
					)
				);
			});

			state = update(state, {
				productRatePlanLongTerms: {
					$push: newProductRatePlans,
				},
				items: {
					$push: newItems,
				},
			});
			const newItemGroups = [];
			state.itemsGroups.forEach((ig, index) => {
				if (ig.id !== 0 || (ig.id === 0 && index === 0)) newItemGroups.push(ig);
			});
			state = update(state, {
				itemsGroups: {
					$set: newItemGroups,
				},
			});
			state = update(state, {
				items: {
					$push: generateVehicleItems(
						state.productRatePlanLongTerms,
						state.itemsGroups,
						item => dispatch({ type: 'updateItem', payload: item }),
						state.items
					),
				},
			});

			numberOfSelectedRatePlans.current += 1;
			product.productRatePlanLongTerms = [...state.productRatePlanLongTerms];
			onFormChange();
		}

		if (type === 'updateItem') {
			if (payload.row < 7) {
				const itemIndex = state.items.findIndex(i => i.id === payload.id);

				const productRatePlanIndex = state.productRatePlanLongTerms.findIndex(
					prp =>
						prp.id === payload.productRatePlan &&
						payload.effectiveDate === prp.effectiveDate
				);

				state = update(state, {
					items: {
						[itemIndex]: { value: { $set: payload.value } },
					},
					productRatePlanLongTerms: {
						[productRatePlanIndex]: {
							[rowToKey[payload.row]]: {
								$set: parseData(
									state.productRatePlanLongTerms[productRatePlanIndex],
									state.items[itemIndex],
									payload.value
								),
							},
						},
					},
				});
			} else {
				const itemIndex = state.items.findIndex(i => i.id === payload.id);

				state = update(state, {
					items: {
						[itemIndex]: { value: { $set: payload.value } },
					},
					productRatePlanLongTerms: {
						$set: state.productRatePlanLongTerms.map(prp => {
							if (prp.ratePlan === state.items[itemIndex].ratePlan) {
								return {
									...prp,
									[rowToKey[payload.row]]: parseData(
										prp,
										state.items[itemIndex],
										payload.value
									),
								};
							}

							return prp;
						}),
					},
				});
			}

			product.productRatePlanLongTerms = [...state.productRatePlanLongTerms];

			onFormChange();
		}

		if (type === 'removeRatePlan') {
			state = update(state, {
				items: {
					$set: state.items.filter(i => i.group === 0 || i.ratePlan !== payload['@id']),
				},
				ratePlans: { $set: state.ratePlans.filter(rp => rp.id !== payload.id) },
				itemsGroups: {
					$set: state.itemsGroups
						.filter(ig => ig.ratePlan !== payload['@id'])
						.sort((a, b) => {
							return a.id - b.id;
						}),
				},
				productRatePlanLongTerms: {
					$set: state.productRatePlanLongTerms.filter(
						prp => prp.ratePlan !== payload['@id']
					),
				},
			});
			product.productRatePlanLongTerms = [...state.productRatePlanLongTerms];

			onFormChange();
		}

		if (type === 'priceDurationBookInAdvance') {
			const _state = { ...state };
			_state.items.forEach(item => {
				if (item.group === groupReference.current) {
					const itemMoment = moment(item.start_time);

					if (
						payload.fromDate.isSameOrBefore(itemMoment) &&
						payload.toDate.isSameOrAfter(itemMoment) &&
						payload.daysOfWeek.findIndex(
							dow => dow.value === moment(item.start_time).format('dddd')
						) !== -1
					) {
						const itemIndex = state.items.findIndex(_item => _item.id === item.id);
						const productRatePlanIndex = state.productRatePlanLongTerms.findIndex(
							prp => prp.id === item.productRatePlan
						);
						const productRatePlan =
							state.productRatePlanLongTerms[productRatePlanIndex];
						if (payload.groupRatePlanReference.current.row % 6 === 2) {
							state = update(state, {
								items: {
									[itemIndex]: {
										$merge: { ...item, price: payload.input / item.costPer },
									},
								},
								productRatePlanLongTerms: {
									[productRatePlanIndex]: {
										$merge: {
											...productRatePlan,
											price: parseFloat(payload.input) / item.costPer,
										},
									},
								},
							});
						} else if (payload.groupRatePlanReference.current.row % 6 === 3) {
							state = update(state, {
								items: {
									[itemIndex]: {
										$merge: { ...item, minDuration: payload.input },
									},
								},
								productRatePlanLongTerms: {
									[productRatePlanIndex]: {
										$merge: {
											...productRatePlan,
											minDuration: parseInt(payload.input, 10),
										},
									},
								},
							});
						} else if (payload.groupRatePlanReference.current.row % 6 === 4) {
							state = update(state, {
								items: {
									[itemIndex]: {
										$merge: { ...item, maxDuration: payload.input },
									},
								},
								productRatePlanLongTerms: {
									[productRatePlanIndex]: {
										$merge: {
											...productRatePlan,
											maxDuration: parseInt(payload.input, 10),
										},
									},
								},
							});
						} else if (payload.groupRatePlanReference.current.row % 6 === 5) {
							state = update(state, {
								items: {
									[itemIndex]: {
										$merge: { ...item, minBookInAdvance: payload.input },
									},
								},
								productRatePlanLongTerms: {
									[productRatePlanIndex]: {
										$merge: {
											...productRatePlan,
											minBookInAdvance: parseInt(payload.input, 10),
										},
									},
								},
							});
						} else if (payload.groupRatePlanReference.current.row % 6 === 0) {
							state = update(state, {
								items: {
									[itemIndex]: {
										$merge: { ...item, maxBookInAdvance: payload.input },
									},
								},
								productRatePlanLongTerms: {
									[productRatePlanIndex]: {
										$merge: {
											...productRatePlan,
											maxBookInAdvance: parseInt(payload.input, 10),
										},
									},
								},
							});
						}
						product.productRatePlanLongTerms = [...state.productRatePlanLongTerms];
					}
				}
			});

			onFormChange();
		}

		return state;
	};

	const [advancePricing, dispatch] = useReducer(reducer, {
		effectiveDates: [],
		productRatePlanLongTerms: [],
		ratePlans: [],
		itemsGroups: [],
		items: [],
	});

	useEffect(() => {
		if (ratePlans.length) dispatch({ type: 'set', payload: product });
	}, [product, ratePlans]);

	useEffect(() => {
		if (advancePricing.items.length > 0) dispatch({ type: 'reRenderVehicleConstraintsItems' });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startTime, endTime]);

	// package bug fix.
	useEffect(() => {
		if (advancePricing.items.length) window.dispatchEvent(new Event('resize'));
	}, [advancePricing]);

	return (
		<>
			<Portlet border>
				<Portlet.Head wrapMaxSize='lg'>
					<Portlet.HeadLabel>
						<h3 className='sdms-portlet__head-title'>
							<small>Long Term Reservation</small>
						</h3>
						<Portlet.Separator />
						<div className='sdms-portlet__head-desc'>
							<Button
								className='sdms-margin-r-15'
								design='clean'
								icon='Angle-double-left'
								size='sm'
								text='Prev'
								onClick={() => {
									const zoom = endTime - startTime;
									setStartTime(startTime - zoom);
									setEndTime(endTime - zoom);
								}}
							/>
							<Button
								className='sdms-margin-r-15'
								design='clean'
								icon='Angle-double-right'
								size='sm'
								text='Next'
								onClick={() => {
									const zoom = endTime - startTime;
									setStartTime(startTime + zoom);
									setEndTime(endTime + zoom);
								}}
							/>
						</div>
					</Portlet.HeadLabel>
					<Portlet.HeadToolbarActions>
						<Button
							icon='Plus'
							label='brand'
							text='Add Effective Date'
							size='sm'
							onClick={() => setWhichModalOpen('date')}
						/>
					</Portlet.HeadToolbarActions>
				</Portlet.Head>
				<Portlet.Body className='sdms-portlet__body--fit react-calendar-timeline--no-border react-calendar-timeline--no-weekend sdms-product-advance-pricing-timeline-container'>
					<Timeline
						groups={advancePricing.itemsGroups}
						groupRenderer={GroupRenderer}
						items={advancePricing.items}
						itemRenderer={ItemRenderer}
						visibleTimeStart={startTime}
						visibleTimeEnd={endTime}
						sidebarWidth={250}
						lineHeight={40}
						showCursorLine
						canMove={false}
						canResize={false}
						onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
							if (
								visibleTimeEnd - visibleTimeStart <
									moment()
										.add(16, 'day')
										.valueOf() -
										moment().valueOf() &&
								visibleTimeEnd - visibleTimeStart >
									moment()
										.add(14, 'day')
										.valueOf() -
										moment().valueOf()
							) {
								updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
								setStartTime(visibleTimeStart);
								setEndTime(visibleTimeEnd);
							} else updateScrollCanvas(startTime.valueOf(), endTime.valueOf());
						}}>
						<TimelineHeaders className='sticky align-items-center position-relative'>
							<SidebarHeader>
								{({ getRootProps }) => {
									return (
										// eslint-disable-next-line react/jsx-props-no-spreading
										<div {...getRootProps()} className='sdms-portlet__head'>
											<div className='sdms-portlet__head-label w-100'>
												<h3 className='sdms-portlet__head-title w-100 sdms-pr0'>
													<Button
														icon='Plus'
														label='brand'
														text='Add Rate Plan'
														size='sm'
														block
														disabled={
															advancePricing.effectiveDates.length <=
															0
														}
														onClick={() =>
															setWhichModalOpen('ratePlan')
														}
													/>
												</h3>
											</div>
										</div>
									);
								}}
							</SidebarHeader>
							<div
								className='sdms-pl15'
								style={{ position: 'absolute', left: 250, top: 20.5 }}>
								<span>Effective Date(s)</span>
							</div>
						</TimelineHeaders>
					</Timeline>
				</Portlet.Body>
			</Portlet>
			<DateModal
				onClose={() => setWhichModalOpen('')}
				onSubmit={calendarDateData =>
					dispatch({ type: 'addDateToCalendar', payload: calendarDateData })
				}
				open={whichModalOpen === 'date'}
				dates={advancePricing.effectiveDates}
			/>
			<RatePlanModal
				open={whichModalOpen === 'ratePlan'}
				ratePlans={ratePlans}
				selectedRatePlans={advancePricing.ratePlans}
				onClose={() => setWhichModalOpen('')}
				productBookingPeriodId={product.bookingPeriod.id}
				onSubmit={ratePlanData => dispatch({ type: 'addRatePlan', payload: ratePlanData })}
			/>
		</>
	);
};
ProductCampgroundAdvancedPricingLongTerm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	product: PropTypes.object,
	enumDays: PropTypes.arrayOf(PropTypes.object),
	onFormChange: PropTypes.func,
};
ProductCampgroundAdvancedPricingLongTerm.defaultProps = {
	ratePlans: [],
	product: {},
	enumDays: [],
	onFormChange: () => {},
};

export default ProductCampgroundAdvancedPricingLongTerm;
