import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import VesselForm from './VesselForm';

const VesselEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='vessels'
			fields={<VesselForm />}
			lists={['enumBoatTypes', 'enumBoatMakes', 'enumFuelTypes']}
			pageTitle={pages.marina.vessels.text}
		/>
	);
};

VesselEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default VesselEdit;
