import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useField from '../../../utils/hooks/useField';
import { required, uniqueFnc } from '../../../utils/helpers/validation';
import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';
import { getCampgroundColorList, numberParser } from '../../../utils/helpers/helper';
import { bookBies } from '../../../utils/constants/constants';

import Loading from '../../reusables/template/Loading';
import Input from '../../reusables/field/Input';
import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Selects from '../../reusables/field/Selects';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Radio from '../../reusables/field/Radio';
import SVGIcon from '../../reusables/element/SVGIcon';
import Badge from '../../reusables/element/Badge';
import Section from '../../reusables/layout/Section';
import useFeet from '../../../utils/hooks/useFeet';
import LengthInputGroup from '../../reusables/field/LengthInputGroup';
import Toggle from '../../reusables/field/Toggle';
import AsyncSelect from '../../reusables/field/AsyncSelect';

const SpaceForm = ({
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	isLoading,
	onFormChange,
	campgroundBookingTypes,
	unitGroups,
	icons,
	submitButtonAttr,
	submit,
	isComposite,
	campgroundUnits,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const spaceIcons = useRef(['Square', 'Circle', 'Hexagon']);

	const campgroundModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.CAMPGROUND)
	);

	const colorList = useRef(getCampgroundColorList());

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[
			required,
			uniqueFnc(
				'campgroundUnits',
				'name',
				data.id,
				true,
				null,
				null,
				null,
				null,
				userContext.data.selectedOutlet.id
			),
		],
		'',
		null,
		setTitle
	);

	const [units, unitsOnChange, unitsValRes, unitsShowVal, setUnitsShowVal] = useField(
		data,
		'units',
		onFormChange,
		[isComposite ? required : () => true],
		[]
	);

	const [
		bookingType,
		bookingTypeOnChange,
		bookingTypeValRes,
		bookingTypeShowVal,
		setBookingTypeShowVal,
	] = useField(data, 'bookingType', onFormChange, [required], null);

	const [
		unitGroup,
		unitGroupOnChange,
		unitGroupValRes,
		unitGroupShowVal,
		setUnitGroupShowVal,
	] = useField(data, 'unitGroup', onFormChange, [required], null);

	const [opacity, opacityOnChange] = useField(
		data,
		'opacity',
		onFormChange,
		[],
		100,
		numberParser(false)
	);

	const [description, descriptionOnChange] = useField(data, 'description', onFormChange);

	const [capacity, capacityOnChange] = useField(
		data,
		'capacity',
		onFormChange,
		[],
		'',
		numberParser()
	);

	const [capacityFt, setCapacityFt, capacityIn, setCapacityIn] = useFeet(
		data.capacity,
		capacityOnChange,
		isLoading
	);

	const [icon, iconOnChange] = useField(data, 'icon', onFormChange, [], null);

	const [mapColor, mapColorOnChange] = useField(data, 'mapColor', onFormChange, [], null);

	const [mapCaption, mapCaptionOnChange] = useField(data, 'mapCaption', onFormChange);

	const [owner, ownerOnChange] = useField(data, 'owner', onFormChange, [], null);

	const [inactive, inactiveOnChange] = useField(data, 'inactive', onFormChange, [], false);

	const [, minLoaOnChange] = useField(data, 'minLoa', onFormChange, [], '', numberParser());

	const [minLoaFt, setMinLoaFt, minLoaIn, setMinLoaIn] = useFeet(
		data.minLoa,
		minLoaOnChange,
		isLoading
	);

	const [, warnLoaOnChange] = useField(data, 'warnLoa', onFormChange, [], '', numberParser());

	const [warnLoaFt, setWarnLoaFt, warnLoaIn, setWarnLoaIn] = useFeet(
		data.warnLoa,
		warnLoaOnChange,
		isLoading
	);

	const [, maxLoaOnChange] = useField(data, 'maxLoa', onFormChange, [], '', numberParser());

	const [maxLoaFt, setMaxLoaFt, maxLoaIn, setMaxLoaIn] = useFeet(
		data.maxLoa,
		maxLoaOnChange,
		isLoading
	);

	const [, minBeamOnChange] = useField(data, 'minBeam', onFormChange, [], '', numberParser());

	const [minBeamFt, setMinBeamFt, minBeamIn, setMinBeamIn] = useFeet(
		data.minBeam,
		minBeamOnChange,
		isLoading
	);

	const [, warnBeamOnChange] = useField(data, 'warnBeam', onFormChange, [], '', numberParser());

	const [warnBeamFt, setWarnBeamFt, warnBeamIn, setWarnBeamIn] = useFeet(
		data.warnBeam,
		warnBeamOnChange,
		isLoading
	);

	const [, maxBeamOnChange] = useField(data, 'maxBeam', onFormChange, [], '', numberParser());

	const [maxBeamFt, setMaxBeamFt, maxBeamIn, setMaxBeamIn] = useFeet(
		data.maxBeam,
		maxBeamOnChange,
		isLoading
	);

	const [minSqft, minSqftOnChange] = useField(
		data,
		'minSqft',
		onFormChange,
		[],
		'',
		numberParser()
	);

	const [warnSqft, warnSqftOnChange] = useField(
		data,
		'warnSqft',
		onFormChange,
		[],
		'',
		numberParser()
	);

	const [maxSqft, maxSqftOnChange] = useField(
		data,
		'maxSqft',
		onFormChange,
		[],
		'',
		numberParser()
	);

	const [, minHeightOnChange] = useField(data, 'minHeight', onFormChange, [], '', numberParser());

	const [minHeightFt, setMinHeightFt, minHeightIn, setMinHeightIn] = useFeet(
		data.minHeight,
		minHeightOnChange,
		isLoading
	);

	const [, warnHeightOnChange] = useField(
		data,
		'warnHeight',
		onFormChange,
		[],
		'',
		numberParser()
	);

	const [warnHeightFt, setWarnHeightFt, warnHeightIn, setWarnHeightIn] = useFeet(
		data.warnHeight,
		warnHeightOnChange,
		isLoading
	);

	const [, maxHeightOnChange] = useField(data, 'maxHeight', onFormChange, [], '', numberParser());

	const [maxHeightFt, setMaxHeightFt, maxHeightIn, setMaxHeightIn] = useFeet(
		data.maxHeight,
		maxHeightOnChange,
		isLoading
	);

	const [notes, notesOnChange] = useField(data, 'notes', onFormChange);

	const [alert, alertOnChange] = useField(data, 'alert', onFormChange);

	const getCapacityInput = () => {
		if (!bookingType || bookingType.bookBy.value === bookBies.UNIT)
			return (
				<Input
					type='number'
					withOutSpin
					min={0}
					placeholder='Capacity'
					value={capacity}
					onChange={capacityOnChange}
					append='Unit'
					pattern={process.env.REACT_APP_INTEGER_PATTERN}
					disabled
				/>
			);

		if (bookingType.bookBy.value === bookBies.SQ_FEET)
			return (
				<Input
					type='number'
					withOutSpin
					min={0}
					placeholder='Capacity'
					value={capacity}
					onChange={capacityOnChange}
					append='sqft'
					pattern={process.env.REACT_APP_INTEGER_PATTERN}
				/>
			);

		return (
			<LengthInputGroup
				ft={capacityFt}
				ftOnChange={setCapacityFt}
				inch={capacityIn}
				inchOnChange={setCapacityIn}
				placeHolder='Capacity'
			/>
		);
	};

	useEffect(() => {
		if (!isLoading) {
			data.module = campgroundModule.current;
			data.isComposite = isComposite;
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setUnitsShowVal();
			setBookingTypeShowVal();
			setUnitGroupShowVal();
		}
	}, [isSubmitted, setNameShowVal, setUnitsShowVal, setBookingTypeShowVal, setUnitGroupShowVal]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				unitsValRes.isValid &&
				bookingTypeValRes.isValid &&
				unitGroupValRes.isValid
		);
	}, [
		nameValRes.isValid,
		unitsValRes.isValid,
		bookingTypeValRes.isValid,
		unitGroupValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.campground.default.text,
				path: pages.campground.dashboard.path,
			},
			{
				title: pages.campground.settings.text,
				path: pages.campground.settings.path,
			},
			{
				title: pages.campground.settings.spaces.text,
				path: pages.campground.settings.spaces.path,
			},
			{ title: name || `New ${pages.campground.settings.spaces.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.campground.settings.spaces.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<Section title='General Settings'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='name'
										label='Name'
										id={data.id}
										valRes={nameValRes}
										showValidation={nameShowVal}
										loadingContainer
										colMd={6}>
										<Input
											type='text'
											placeholder='Name (Required)'
											value={name}
											onChange={nameOnChange}
											onBlur={setNameShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='bookingType'
										label='Booking Type'
										id={data.id}
										valRes={bookingTypeValRes}
										showValidation={bookingTypeShowVal}
										colMd={6}>
										<Selects
											options={campgroundBookingTypes.sort((a, b) =>
												a.name.localeCompare(b.name)
											)}
											placeholder='Select a Booking Type (Required)'
											value={bookingType}
											onChange={e => {
												bookingTypeOnChange(e);

												if (
													e.target.value &&
													e.target.value.bookBy.value === bookBies.UNIT
												)
													capacityOnChange({ target: { value: 1 } });

												unitsOnChange({ target: { value: [] } });
											}}
											onBlur={setBookingTypeShowVal}
											disabled={data.id !== 0}
										/>
									</FormField>
								</Loading>
								{isComposite && (
									<Loading isLoading={isLoading}>
										<FormField
											name='units'
											label='Spaces'
											id={data.id}
											valRes={unitsValRes}
											showValidation={unitsShowVal}
											colMd={6}>
											<Selects
												options={campgroundUnits
													.filter(
														mu =>
															mu.bookingType.bookBy.value ===
																bookBies.UNIT &&
															!mu.isComposite &&
															bookingType?.id === mu.bookingType.id
													)
													.sort((a, b) => a.name.localeCompare(b.name))}
												placeholder='Select Spaces (Required)'
												value={units}
												onChange={unitsOnChange}
												onBlur={setUnitsShowVal}
												multiple
												disabled={data.id !== 0}
											/>
										</FormField>
									</Loading>
								)}
								<Loading isLoading={isLoading}>
									<FormField
										name='unitGroup'
										label='Space Group'
										id={data.id}
										valRes={unitGroupValRes}
										showValidation={unitGroupShowVal}
										colMd={6}>
										<Selects
											options={unitGroups}
											placeholder='Select a Unit Group (Required)'
											value={unitGroup}
											onChange={unitGroupOnChange}
											onBlur={setUnitGroupShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='description'
										label='Description'
										id={data.id}
										colMd={6}>
										<Input
											type='text'
											placeholder='Description'
											value={description}
											onChange={descriptionOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='capacity'
										label='Capacity'
										id={data.id}
										colMd={6}>
										{getCapacityInput()}
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField name='icon' label='Icon' id={data.id} colMd={2}>
										<Radio.Container
											isInline
											className={classNames('sdms-mt-5', {
												'sdms-bg-fill-dark': mapColor === 'white',
											})}>
											{icons
												.filter(
													i => spaceIcons.current.indexOf(i.value) > -1
												)
												.map(i => {
													return (
														<Radio
															key={i.id}
															checked={icon && icon.id === i.id}
															id={i.id.toString()}
															name='icon'
															content={
																<SVGIcon
																	name={i.value}
																	className={classNames({
																		[`sdms-custom-colors--fill--${
																			mapColor
																				? mapColor + opacity
																				: 'info'
																		}`]: mapColor,
																	})}
																/>
															}
															className='sdms-radio--primary sdms-mt-5'
															onChange={() => {
																iconOnChange({
																	target: {
																		name: 'icon',
																		value: i,
																	},
																});
															}}
														/>
													);
												})}
										</Radio.Container>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='mapColor'
										label='Map Color'
										id={data.id}
										colMd={2}>
										<Selects
											className='sdms-font-transform-c'
											placeholder='Select a Color'
											options={colorList.current}
											value={
												mapColor
													? colorList.current.find(
															c => c.class === mapColor
													  )
													: null
											}
											onChange={c =>
												mapColorOnChange({
													target: {
														value: c.target.value
															? c.target.value.class
															: null,
													},
												})
											}
											displayKey='name'
											valueKey='class'
											renderOption={option => (
												<Badge
													className={`sdms-custom-colors--${option.class} sdms-font-transform-c`}
													isInline>
													<span>{option.name}</span>
												</Badge>
											)}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='opacity'
										label={
											<>
												Opacity
												<Badge
													className='sdms-ml-10'
													design='info'
													isInline
													isUnified>
													{opacity}%
												</Badge>
											</>
										}
										id={data.id}
										colMd={2}>
										<Input
											className='sdms-mt-10'
											type='range'
											min={1}
											max={100}
											value={opacity}
											onChange={opacityOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='mapCaption'
										label='Map Caption'
										id={data.id}
										colMd={6}>
										<Input
											type='text'
											placeholder='Map Caption'
											value={mapCaption}
											onChange={mapCaptionOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField name='owner' label='Owner' id='owner' colLg={6}>
										<AsyncSelect
											options={data.owner ? [data.owner] : []}
											placeholder='Search and select customer'
											value={owner}
											onChange={ownerOnChange}
											route='customers'
											field='displayName'
											displayKey='displayName'
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField name='notes' label='Notes' id={data.id} colMd={6}>
										<Input
											type='text'
											placeholder='Notes'
											value={notes}
											onChange={notesOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField name='alert' label='Alert' id={data.id} colMd={6}>
										<Input
											type='text'
											placeholder='Alert'
											value={alert}
											onChange={alertOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='inactive'
										label='Inactive'
										id={data.id}
										colMd={6}>
										<Toggle
											spaceLess
											value={inactive}
											onChange={inactiveOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Vehicle Constraints'>
						<Section.Body>
							<FormGroup row>
								<div className='col-md-6'>
									<Portlet border className='sdms-portlet--head-noborder'>
										<Portlet.Head>
											<Portlet.HeadLabelTitle>
												Length Overall
											</Portlet.HeadLabelTitle>
										</Portlet.Head>
										<Portlet.Body>
											<div className='row'>
												<Loading isLoading={isLoading}>
													<FormField
														name='minLengthOverAllFt'
														label='Minimum Length Overall'
														id={data.id}
														colLg={4}
														inFormDesign={false}>
														<LengthInputGroup
															ft={minLoaFt}
															ftOnChange={setMinLoaFt}
															inch={minLoaIn}
															inchOnChange={setMinLoaIn}
															placeHolder='Minimum Length Overall'
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='warnLengthOverAllFt'
														label='Warn Length Overall'
														id={data.id}
														colLg={4}
														inFormDesign={false}>
														<LengthInputGroup
															ft={warnLoaFt}
															ftOnChange={setWarnLoaFt}
															inch={warnLoaIn}
															inchOnChange={setWarnLoaIn}
															placeHolder='Warn Length Overall'
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='maxLengthOverAllFt'
														label='Maximum Length Overall'
														id={data.id}
														colLg={4}
														inFormDesign={false}>
														<LengthInputGroup
															ft={maxLoaFt}
															ftOnChange={setMaxLoaFt}
															inch={maxLoaIn}
															inchOnChange={setMaxLoaIn}
															placeHolder='Maximum Length Overall'
														/>
													</FormField>
												</Loading>
											</div>
										</Portlet.Body>
									</Portlet>
								</div>
								<div className='col-md-6'>
									<Portlet border className='sdms-portlet--head-noborder'>
										<Portlet.Head>
											<Portlet.HeadLabelTitle>Beam</Portlet.HeadLabelTitle>
										</Portlet.Head>
										<Portlet.Body>
											<div className='row'>
												<Loading isLoading={isLoading}>
													<FormField
														name='minBeamFt'
														label='Minimum Beam'
														id={data.id}
														colLg={4}
														inFormDesign={false}>
														<LengthInputGroup
															ft={minBeamFt}
															ftOnChange={setMinBeamFt}
															inch={minBeamIn}
															inchOnChange={setMinBeamIn}
															placeHolder='Minimum Beam'
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='warnBeamFt'
														label='Warn Beam'
														id={data.id}
														colLg={4}
														inFormDesign={false}>
														<LengthInputGroup
															ft={warnBeamFt}
															ftOnChange={setWarnBeamFt}
															inch={warnBeamIn}
															inchOnChange={setWarnBeamIn}
															placeHolder='Warn Beam'
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='maxBeamFt'
														label='Maximum Beam'
														id={data.id}
														colLg={4}
														inFormDesign={false}>
														<LengthInputGroup
															ft={maxBeamFt}
															ftOnChange={setMaxBeamFt}
															inch={maxBeamIn}
															inchOnChange={setMaxBeamIn}
															placeHolder='Maximum Beam'
														/>
													</FormField>
												</Loading>
											</div>
										</Portlet.Body>
									</Portlet>
								</div>
								<div className='col-md-6'>
									<Portlet border className='sdms-portlet--head-noborder'>
										<Portlet.Head>
											<Portlet.HeadLabelTitle>Height</Portlet.HeadLabelTitle>
										</Portlet.Head>
										<Portlet.Body>
											<div className='row'>
												<Loading isLoading={isLoading}>
													<FormField
														name='minHeightFt'
														label='Minimum Height'
														id={data.id}
														colLg={4}
														inFormDesign={false}>
														<LengthInputGroup
															ft={minHeightFt}
															ftOnChange={setMinHeightFt}
															inch={minHeightIn}
															inchOnChange={setMinHeightIn}
															placeHolder='Minimum Height'
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='warnHeightFt'
														label='Warn Height'
														id={data.id}
														colLg={4}
														inFormDesign={false}>
														<LengthInputGroup
															ft={warnHeightFt}
															ftOnChange={setWarnHeightFt}
															inch={warnHeightIn}
															inchOnChange={setWarnHeightIn}
															placeHolder='Warn Height'
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='maxHeightFt'
														label='Maximum Height'
														id={data.id}
														colLg={4}
														inFormDesign={false}>
														<LengthInputGroup
															ft={maxHeightFt}
															ftOnChange={setMaxHeightFt}
															inch={maxHeightIn}
															inchOnChange={setMaxHeightIn}
															placeHolder='Maximum Height'
														/>
													</FormField>
												</Loading>
											</div>
										</Portlet.Body>
									</Portlet>
								</div>
								<div className='col-md-6'>
									<Portlet border className='sdms-portlet--head-noborder'>
										<Portlet.Head>
											<Portlet.HeadLabelTitle>
												Square Feet
											</Portlet.HeadLabelTitle>
										</Portlet.Head>
										<Portlet.Body>
											<div className='row'>
												<Loading isLoading={isLoading}>
													<FormField
														name='minSqft'
														label='Minimum Square Feet'
														id={data.id}
														colLg={4}
														inFormDesign={false}>
														<Input
															type='number'
															withOutSpin
															min={0}
															placeholder='Minimum Square Feet'
															value={minSqft}
															onChange={minSqftOnChange}
															pattern={
																process.env
																	.REACT_APP_INTEGER_PATTERN
															}
															append='sqft'
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='warnSqft'
														label='Warn Square Feet'
														id={data.id}
														colLg={4}
														inFormDesign={false}>
														<Input
															type='number'
															withOutSpin
															min={0}
															placeholder='Warn Square Feet'
															value={warnSqft}
															onChange={warnSqftOnChange}
															pattern={
																process.env
																	.REACT_APP_INTEGER_PATTERN
															}
															append='sqft'
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='maxSqft'
														label='Maximum Square Feet'
														id={data.id}
														colLg={4}
														inFormDesign={false}>
														<Input
															type='number'
															withOutSpin
															min={0}
															placeholder='Maximum Square Feet'
															value={maxSqft}
															onChange={maxSqftOnChange}
															pattern={
																process.env
																	.REACT_APP_INTEGER_PATTERN
															}
															append='sqft'
														/>
													</FormField>
												</Loading>
											</div>
										</Portlet.Body>
									</Portlet>
								</div>
							</FormGroup>
						</Section.Body>
					</Section>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
SpaceForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		bookingType: PropTypes.object,
		unitGroup: PropTypes.object,
		description: PropTypes.string,
		mapCaption: PropTypes.string,
		module: PropTypes.object,
		minLoa: PropTypes.number,
		maxLoa: PropTypes.number,
		warnLoa: PropTypes.number,
		minBeam: PropTypes.number,
		warnBeam: PropTypes.number,
		maxBeam: PropTypes.number,
		minHeight: PropTypes.number,
		maxHeight: PropTypes.number,
		warnHeight: PropTypes.number,
		minSqft: PropTypes.number,
		warnSqft: PropTypes.number,
		maxSqft: PropTypes.number,
		capacity: PropTypes.number,
		isComposite: PropTypes.bool,
		owner: PropTypes.object,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	campgroundBookingTypes: PropTypes.arrayOf(PropTypes.object),
	unitGroups: PropTypes.arrayOf(PropTypes.object),
	icons: PropTypes.arrayOf(PropTypes.object),
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
	isComposite: PropTypes.bool,
	campgroundUnits: PropTypes.arrayOf(PropTypes.object),
};
SpaceForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		bookingType: {},
		unitGroup: {},
		description: '',
		mapCaption: '',
		minLengthOverAll: 0,
		maxLengthOverAll: 0,
		warnLengthOverAll: 0,
		minBeam: 0,
		warnBeam: 0,
		maxBeam: 0,
		minHeight: 0,
		maxHeight: 0,
		warnHeight: 0,
		minSqft: 0,
		maxSqft: 0,
		warnSqft: 0,
		capacity: 1,
		isComposite: false,
		owner: null,
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	campgroundBookingTypes: [],
	unitGroups: [],
	icons: [],
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
	isComposite: false,
	campgroundUnits: [],
};

export default SpaceForm;
