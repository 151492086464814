import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import DialogBox from '../../DialogBox';

const ContinueDialog = ({ isOpen, onContinue, onCancel }) => {
	return (
		<DialogBox open={isOpen} title='' content='Would you like to continue?' type='question'>
			<Button
				className='sdms-font-transform-c'
				text='Cancel reservation'
				label='danger'
				icon='Error-circle'
				onClick={onCancel}
			/>
			<Button
				className='sdms-font-transform-c'
				text='Continue'
				design='clean'
				icon='Edit'
				onClick={onContinue}
			/>
		</DialogBox>
	);
};

ContinueDialog.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onContinue: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
};

export default ContinueDialog;
