import React, { useContext } from 'react';

import usePages from '../../../utils/hooks/usePages';
import UserContext from '../../../app/contexts/UserContext';
import useOutlet from '../../../utils/hooks/useOutlet';

import FormContainer from '../../reusables/template/FormContainer';
import ModuleForm from './ModuleForm';

const ModuleEdit = () => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const [outletKey] = useOutlet();

	return (
		<FormContainer
			key={outletKey}
			dataId={
				userContext.data.selectedOutlet.settings
					? userContext.data.selectedOutlet.settings.id.toString()
					: '0'
			}
			dataName='outletSettings'
			fields={<ModuleForm />}
			lists={['templates', 'unitMaps', 'grids', 'paymentTerms', 'contracts']}
			showBackButton={false}
			pageTitle={pages.companySettings.module.text}
			icon={pages.companySettings.module.icon}
			afterSubmit={outletSetting => userContext.updateOutlet(outletSetting, true)}
		/>
	);
};

export default ModuleEdit;
