import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../app/contexts/UserContext';
import pages from '../../pages';

import FormContainer from '../../reusables/template/FormContainer';
import UserForm from './UserForm';

const UserEdit = ({ match }) => {
	const userContext = useContext(UserContext);
	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle={pages.systemSettings.users.text}
			dataName={pages.systemSettings.users.route}
			lists={['roles', 'units', 'outlets']}
			fields={<UserForm />}
			afterSubmit={data => {
				if (userContext.data.user.id === data.id)
					userContext.updatePermissions(data.userRoleOutlets, 'roles');
			}}
		/>
	);
};

UserEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default UserEdit;
