import React, { useContext } from 'react';
import UserContext from '../../../app/contexts/UserContext';
import usePages from '../../../utils/hooks/usePages';
import OnlinePortal from '../online_portal/OnlinePortal';
import ContentInner from '../../reusables/template/ContentInner';

const OnlinePortalContainer = () => {
	const pages = usePages();
	const userContext = useContext(UserContext);

	return (
		<ContentInner.Container hasFrame title={pages.crm.dashboard.text}>
			<OnlinePortal
				outletId={userContext.data.selectedOutlet.id}
				token={
					userContext.data.selectedOutlet.settings.onlineSiteToken || userContext.token
				}
				isOnline={false}
			/>
		</ContentInner.Container>
	);
};

export default OnlinePortalContainer;
