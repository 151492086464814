import React, { Children, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useComponentSize from '@rehooks/component-size';
import ReactDOMServer from 'react-dom/server';
import Portlet from '../layout/Portlet';
import Loading from './Loading';
import { ListBody, ListHead, ListTable } from './List';

const Col = ({
	label,
	cellData,
	head,
	children,
	className,
	align,
	cellComponent,
	data,
	cellStatus,
}) => {
	const colClass = classNames({ [`text-${align}`]: align }, className);

	if (head && !children) return <th className={colClass}>{label}</th>;

	if (!head && !children) {
		return (
			<td
				className={colClass}
				data-status-color={
					cellStatus &&
					ReactDOMServer.renderToString(React.cloneElement(cellStatus, { data }))
				}>
				{cellComponent ? React.cloneElement(cellComponent, { data }) : cellData}
			</td>
		);
	}

	return <td className={colClass}>{children}</td>;
};
Col.propTypes = {
	label: PropTypes.string,
	cellData: PropTypes.node,
	head: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
	align: PropTypes.oneOf(['center', 'right']),
	// eslint-disable-next-line react/require-default-props
	cellComponent: PropTypes.node,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	cellStatus: PropTypes.node,
};
Col.defaultProps = {
	label: null,
	cellData: null,
	head: false,
	children: null,
	className: null,
	align: null,
	data: {},
	cellStatus: null,
};

const SelectableList = ({
	children,
	data,
	onClick,
	selectedItem,
	disableItem,
	name,
	withOutPortlet,
	withImage,
	withCheckbox,
	withActions,
	isLoading,
	isFit,
	isElevate,
	fluid,
	className,
	responsive,
	minWidth,
}) => {
	const refBody = useRef(null);
	const sizeBody = useComponentSize(refBody);

	const getCellDataValue = (dataItem, item) => {
		if (item.props.cellDataObject)
			return dataItem[item.props.cellDataObject]
				? dataItem[item.props.cellDataObject][item.props.cellData]
				: 'N/A';

		return dataItem[item.props.cellData];
	};

	const _children = Children.toArray(children).filter(c => c);

	const _ListHead = (
		<>
			<colgroup>
				{_children.map(child => (
					<col
						key={`${name}-colgroup-${child.props.label}`}
						style={{ width: child.props.width }}
						className={child.props.className}
					/>
				))}
			</colgroup>
			<thead>
				<tr>
					{_children.map(child => (
						<Col
							head
							key={`${name}-portletHeadCol-${child.props.label}`}
							label={child.props.label}
							align={child.props.align}
							className={child.props.className}
						/>
					))}
				</tr>
			</thead>
		</>
	);

	const _List = (
		<>
			<ListHead
				responsive={responsive}
				className={classNames({ 'table--everytime--scroll': sizeBody.width <= minWidth })}>
				<Portlet.HeadToolbar>
					<table className='table'>{_ListHead}</table>
				</Portlet.HeadToolbar>
			</ListHead>
			<ListBody
				ref={refBody}
				responsive={responsive}
				className={classNames({ 'table--everytime--scroll': sizeBody.width <= minWidth })}>
				<ListTable
					isLoading={isLoading}
					childrenLength={children.length}
					minWidth={minWidth}>
					{_ListHead}
					<Loading
						isLoading={isLoading}
						type='list'
						colCount={children.length}
						withCheckbox={withCheckbox}
						withActions={withActions}
						withImage={withImage}>
						{data.length === 0 ? (
							<caption>No results to show.</caption>
						) : (
							<tbody>
								{data.map(dataItem => (
									<tr
										onClick={
											!(disableItem && disableItem(dataItem))
												? () => onClick(dataItem)
												: null
										}
										role='presentation'
										key={dataItem.id}
										className={classNames(
											{
												selected:
													selectedItem != null
														? dataItem.id === selectedItem.id
														: false,
											},
											{
												disable: disableItem && disableItem(dataItem),
											}
										)}>
										{_children.map(child => {
											if (!child) return null;
											return (
												<Col
													key={`${name}-${child.props.label}-${dataItem.id}`}
													cellData={getCellDataValue(dataItem, child)}
													id={dataItem.id}
													className={classNames(
														{
															'sdms-list-layout--only-hover':
																child.props.onlyHover,
														},
														'sdms-cursor--pointer',
														child.props.className
													)}
													align={child.props.align}
													data={dataItem}
													cellComponent={child.props.cellComponent}
													isLinkColumn={child.props.isLinkColumn}
													cellStatus={child.props.cellStatus}>
													{child.props.children}
												</Col>
											);
										})}
									</tr>
								))}
							</tbody>
						)}
					</Loading>
				</ListTable>
			</ListBody>
		</>
	);

	if (withOutPortlet) {
		return _List;
	}
	return (
		<Portlet
			className={classNames('sdms-list-layout', { 'sdms-portlet--fit': isFit }, className)}
			isElevate={isElevate}
			fluid={fluid}>
			{_List}
		</Portlet>
	);
};
SelectableList.propTypes = {
	children: PropTypes.node,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.array,
	name: PropTypes.string,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	selectedItem: PropTypes.object,
	disableItem: PropTypes.func,
	// eslint-disable-next-line react/require-default-props
	withOutPortlet: PropTypes.bool,
	withImage: PropTypes.bool,
	withCheckbox: PropTypes.bool,
	withActions: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	isFit: PropTypes.bool,
	isElevate: PropTypes.bool,
	className: PropTypes.string,
	fluid: PropTypes.oneOf(['fluid', 'fluid-half']),
	responsive: PropTypes.oneOf([null, 'scroll', 'grid']),
	minWidth: PropTypes.number,
};
SelectableList.defaultProps = {
	children: [],
	data: [],
	name: 'List',
	isLoading: false,
	withOutPortlet: false,
	withImage: false,
	withCheckbox: false,
	withActions: false,
	selectedItem: null,
	disableItem: null,
	isFit: false,
	isElevate: false,
	className: null,
	fluid: null,
	responsive: null,
	minWidth: null,
};

SelectableList.Col = Col;

export default SelectableList;
