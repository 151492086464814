import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../element/Button';
import SVGIcon from '../element/SVGIcon';
import Portlet from '../layout/Portlet';

export const ContentItem = ({ title, children, wizardType, wizardState }) => {
	return (
		<div
			className='sdms-wizard-v2__content'
			data-sdmswizard-type={wizardType}
			data-sdmswizard-state={wizardState}>
			<div className='sdms-heading sdms-heading--md'>{title}</div>
			<div className='sdms-form__section'>{children}</div>
		</div>
	);
};
ContentItem.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node.isRequired,
	wizardType: PropTypes.oneOf([
		'action-prev',
		'action-next',
		'step',
		'step-info',
		'step-content',
		'action-submit',
	]),
	wizardState: PropTypes.oneOf(['current']),
	// wizardState: PropTypes.oneOf(['current', 'done', 'pending', 'first', 'between', 'last']),
};
ContentItem.defaultProps = {
	title: null,
	wizardType: 'step-content',
	wizardState: null,
};

export const NavItem = ({
	title,
	description,
	icon,
	wizardType,
	wizardState,
	clickAction,
	disabled,
	isValid,
	isLoading,
}) => {
	return (
		<div
			onClick={clickAction}
			role='presentation'
			className={classNames(
				'sdms-wizard-v2__nav-item',
				{
					'sdms-wizard-v2__nav-item--disabled': disabled,
				},
				{ 'sdms-wizard-v2__nav-item--loading': isLoading }
			)}
			data-sdmswizard-type={wizardType}
			data-sdmswizard-state={wizardState}>
			<div className='sdms-wizard-v2__nav-body'>
				<div className='sdms-wizard-v2__nav-icon'>
					<SVGIcon
						name={icon}
						className={classNames({ 'sdms-svg-icon--danger': !isValid })}
					/>
				</div>

				<div className='sdms-wizard-v2__nav-label'>
					<div
						className={classNames('sdms-wizard-v2__nav-label-title', {
							'sdms-font-danger': !isValid,
						})}>
						{title}
					</div>
					<div
						className={classNames('sdms-wizard-v2__nav-label-desc', {
							'sdms-font-danger': !isValid,
						})}>
						{isValid ? description : 'There are required fields here'}
					</div>
				</div>
			</div>
		</div>
	);
};
NavItem.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	icon: PropTypes.string,
	wizardType: PropTypes.oneOf(['step']),
	wizardState: PropTypes.oneOf(['current', 'done', 'pending']),
	clickAction: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	isValid: PropTypes.bool,
	isLoading: PropTypes.bool,
};
NavItem.defaultProps = {
	description: null,
	icon: 'Layers',
	wizardType: 'step',
	wizardState: 'pending',
	disabled: false,
	isValid: true,
	isLoading: false,
};

const Item = ({ title, description, isValid }) => {
	throw new Error(`Title ${title}  component should be used as a child in the component Wizard.
	Description: "${description}"`);
};
Item.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	isValid: PropTypes.bool,
};
Item.defaultProps = {
	description: null,
	isValid: true,
};

const Wizard = ({ children, onSubmit, submitButtonAttr, isLoading, submitDisabled }) => {
	const [active, setActive] = useState(0);

	const availablePrevStep = useRef(0);

	const availableNextStep = useRef(0);

	const setAvailableData = useCallback(() => {
		const availableSteps = [];

		(Array.isArray(children) ? children : [children]).forEach((child, index) => {
			if (!child.props.disable) {
				availableSteps.push(index);
			}
		});

		const availablePrevSteps = availableSteps.filter(s => s < active);
		availablePrevStep.current = availablePrevSteps.length ? Math.max(...availablePrevSteps) : 0;

		const availableNextSteps = availableSteps.filter(s => s > active);
		availableNextStep.current = availableNextSteps.length
			? Math.min(...availableNextSteps)
			: active;
	}, [active, children]);

	setAvailableData();

	return (
		<Portlet className='sdms-wizard' fluid='fluid' hasFrame>
			<Portlet.Body>
				<div
					className='sdms-grid sdms-wizard-v2 sdms-wizard-v2--white'
					id='sdms_wizard_v2'
					data-sdmswizard-state={
						active === 0
							? 'first'
							: (active === children.length - 1 && 'last') || 'between'
					}>
					<div className='sdms-grid__item sdms-wizard-v2__aside'>
						<div className='sdms-wizard-v2__nav'>
							<div className='sdms-wizard-v2__nav-items sdms-wizard-v2__nav-items--sticky'>
								{children.map((item, index) => (
									<NavItem
										key={item.props.title}
										title={item.props.title}
										description={item.props.description}
										icon={item.props.icon}
										disabled={item.props.disable}
										isValid={item.props.isValid}
										wizardState={
											index === active
												? 'current'
												: (active < index && 'pending') || 'done'
										}
										clickAction={() => setActive(index)}
										isLoading={index !== 0 && isLoading}
									/>
								))}
							</div>
						</div>
					</div>
					<div className='sdms-grid__item sdms-grid__item--fluid sdms-wizard-v2__wrapper'>
						<form className='sdms-form' noValidate>
							{children.map((item, index) => {
								if (index === active) {
									return (
										<ContentItem
											key={item.props.title}
											title={item.props.title}
											wizardState='current'>
											{item.props.children}
										</ContentItem>
									);
								}
								return null;
							})}
						</form>
					</div>
				</div>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col-12 sdms-form__actions'>
					{availablePrevStep.current !== active && (
						<Button
							design='clean'
							icon='Angle-left-circle'
							size='sm'
							elevate
							wizardType='action-prev'
							onClick={() => setActive(availablePrevStep.current)}>
							Previous
						</Button>
					)}
					{availableNextStep.current === active && (
						<Button
							label={submitButtonAttr.color}
							text={submitButtonAttr.text}
							icon={submitButtonAttr.icon}
							size='sm'
							className={classNames(' sdms-mw-100', {
								'sdms-fading-dots':
									submitButtonAttr.text ===
									process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
							})}
							elevate
							wizardType='action-submit'
							onClick={onSubmit}
							disabled={submitDisabled}
						/>
					)}
					{availableNextStep.current !== active && (
						<Button
							label='brand'
							icon='Angle-right-circle'
							size='sm'
							wizardType='action-next'
							onClick={() => setActive(availableNextStep.current)}>
							Next Step
						</Button>
					)}
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
Wizard.propTypes = {
	children: PropTypes.node.isRequired,
	onSubmit: PropTypes.func.isRequired,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	isLoading: PropTypes.bool,
	submitDisabled: PropTypes.bool,
};
Wizard.defaultProps = {
	submitButtonAttr: {
		text: process.env.submitButtonAttr,
		icon: process.env.submitButtonAttr,
		color: process.env.submitButtonAttr,
	},
	isLoading: false,
	submitDisabled: false,
};

Wizard.Item = Item;

export default Wizard;
