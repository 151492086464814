import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import forms from '../../forms';

const Address = ({ data }) => {
	if (data.state === null) return null;
	return (
		<div>
			<div>{data.addressLineOne}</div>
			<div>{data.addressLineTwo}</div>
			<div>
				{data.city} {data.state.code} {data.zip}
			</div>
		</div>
	);
};
Address.propTypes = {
	data: PropTypes.shape({
		addressLineOne: PropTypes.string,
		addressLineTwo: PropTypes.string,
		city: PropTypes.string,
		state: PropTypes.object,
		zip: PropTypes.string,
	}),
};
Address.defaultProps = {
	data: {
		addressLineOne: null,
		addressLineTwo: null,
		city: null,
		state: null,
		zip: null,
	},
};

const Phone = ({ data }) => {
	if (data.phone === null) return null;
	return (
		<a href={`tel:${data.phone}`} className='sdms-link'>
			{data.phone}
		</a>
	);
};
Phone.propTypes = {
	data: PropTypes.shape({
		phone: PropTypes.string,
	}),
};
Phone.defaultProps = {
	data: {
		phone: null,
	},
};

const TaxAgencyList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.accounting.default.text, path: pages.accounting.dashboard.path },
			{ title: pages.accounting.settings.text, path: pages.accounting.settings.path },
			{ title: pages.accounting.settings.taxAgency.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.accounting.settings.taxAgency.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='taxAgencies'
			title={pages.accounting.settings.taxAgency.text}
			history={history}
			icon={pages.accounting.settings.taxAgency.icon}
			forms={forms.accounting.settings.taxAgency}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col label='Address' cellComponent={<Address />} sortable='addressLineOne' />
				<List.Col label='Phone' cellComponent={<Phone />} sortable='phone' />
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
TaxAgencyList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default TaxAgencyList;
