import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './Button';
import MessageContext from '../../../app/contexts/MessageContext';

const MessagesButton = ({ onClick, disabled }) => {
	const messageContext = useContext(MessageContext);
	return (
		<Button design='default' text='Messages' onClick={onClick} disabled={disabled}>
			Messages
			{messageContext.entityCount !== 0 && (
				<span className={classNames(`sdms-inquiry-notification`)}>
					{messageContext.entityCount}
				</span>
			)}
		</Button>
	);
};

MessagesButton.propTypes = {
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
};

MessagesButton.defaultProps = {
	onClick: () => {},
	disabled: false,
};

export default MessagesButton;
