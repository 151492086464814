// noinspection JSIgnoredPromiseFromCall
import React, { useEffect, useRef, useState, useCallback, useLayoutEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useComponentSize from '@rehooks/component-size';
import { useLocation } from 'react-router-dom';

import usePages from '../../../utils/hooks/usePages';
import apiCall, { modules } from '../../../utils/helpers/apiCall';
import {
	dateFormatter,
	dateView,
	addSuccessNotification,
	addErrorNotification,
	numberFormat,
	setCookie,
	getCookie,
	removeCookie,
	calculateServiceFee,
	addFloats,
	getItemTaxCode,
	getPaymentProcessorServiceFeeAmount,
	convertDateToUTC,
	calculateSqft,
} from '../../../utils/helpers/helper';
import { validatePaymentFields } from '../../../utils/helpers/paymentHelper';
import {
	bookingCalculations,
	bookingPeriods,
	extraChargeTypes,
	paymentTypes,
	serviceFeeModules,
} from '../../../utils/constants/constants';
import {
	getReservationExtraChargePeriod,
	addExtraChargeToItem,
	addReservationExtraCharge,
	getItemDepositAmount,
	itemToExtras,
	calculateExtraChargePrice,
	getPerUnitPromoCodeAmount,
	getHeaderHeight,
	getTotals,
} from '../../../utils/helpers/basicReservationHelper';
import usePaymentGateway, {
	customerPaymentForms,
	getPaymentProcessor,
	parsePaymentData,
} from '../../../utils/hooks/usePaymentGateway';
import {
	modals,
	reservationPayStatuses,
	searchResultViews,
} from '../element/reservation_form/constants';
import useCountDown from '../../../utils/hooks/useCountDown';
import { getModuleByPath, modulePageKeys } from '../../../utils/helpers/reusable';
import { getCapacity } from '../../../utils/helpers/reservationHelper';

import Button from '../element/Button';
import WizardTop from '../template/WizardTop';
import ContentInner from '../template/ContentInner';
import BreadcrumbContainer from '../template/BreadcrumbContainer';
import CustomerModal from '../modals/CustomerModal';
import LoginModal from '../modals/LoginModal';
import Footer from '../element/reservation_form/elements/Footer';
import useModal from '../../../utils/hooks/useModal';
import CancelDialog from '../element/reservation_form/modals/CancelDialog';
import ContinueDialog from '../element/reservation_form/modals/ContinueDialog';
import DeleteDialog from '../element/reservation_form/modals/DeleteDialog';
import SignOutDialog from '../element/reservation_form/modals/SignOutDialog';
import ConfirmationModal from '../element/reservation_form/modals/ConfirmationModal';
import SignIn from '../element/reservation_form/elements/SignIn';
import TermsAndConditions from '../element/reservation_form/elements/TermsAndConditions';
import Search from '../element/reservation_form/steps/search/Search';
import Payment from '../element/reservation_form/steps/payment/Payment';
import Options from '../element/reservation_form/steps/options/Options';
import Summary from '../element/reservation_form/steps/summary/Summary';
import SimpleOnlineConfirmationModal from '../modals/SimpleOnlineConfirmationModal';

const ReservationForm = ({
	data,
	onFormChange,
	bookingTypes,
	paymentTerms,
	countries,
	states,
	paymentMethods,
	isOnline,
	outlet,
	headerContext,
	history,
	overrideConstraints,
	enableHouseAccount,
	saveCreditCards,
	initBookingType,
	initFromDate,
	initToDate,
	initAdults,
	initChildren,
	passwordSettings,
	initProducts,
	enumRvTypes,
	enumVehicleMakes,
	enumBoatTypes,
	enumBoatMakes,
	customOnBack,
	initLoa,
	initBeam,
	initHeight,
	initDraft,
	initWeight,
}) => {
	const [reservationId, setReservationId] = useState(0);

	const [reservationCustomId, setReservationCustomId] = useState(0);

	const [reservationTimeModified, setReservationTimeModified] = useState('');

	const [searchBarKey, setSearchBarKey] = useState(Date.now());

	const [isHourlyOnly, setIsHourlyOnly] = useState(false);

	const [searchData, setSearchData] = useState({
		bookingType: initBookingType,
		fromDate: initFromDate ? convertDateToUTC(new Date(initFromDate)) : undefined,
		toDate: initToDate ? convertDateToUTC(new Date(initToDate)) : undefined,
		adults: initAdults,
		children: initChildren,
		loa: initLoa ? parseFloat(initLoa) : '',
		beam: initBeam ? parseFloat(initBeam) : '',
		height: initHeight ? parseFloat(initHeight) : '',
		draft: initDraft ? parseFloat(initDraft) : '',
		weight: initWeight ? parseFloat(initWeight) : '',
		ignoreRules: false,
		alternateDateSearch: false,
	});

	const initSearch = useRef(false);

	const pages = usePages(outlet?.settings);

	const [searched, setSearched] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [productCategories, setProductCategories] = useState({});
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [selectedUnit, setSelectedUnit] = useState(0);
	const [numberOfUnits, setNumberOfUnits] = useState(1);
	const [activeStep, setActiveStep] = useState(0);
	const [intervalText, setIntervalText] = useState('');
	const [extras, setExtras] = useState([]);
	const [extrasVal, setExtrasVal] = useState([]);
	const [extrasShowVal, setExtrasShowVal] = useState([]);
	const [notes, setNotes] = useState([]);
	const [quantities, setQuantities] = useState([]);
	const [prices, setPrices] = useState([]);
	const [extraCharges, setExtraCharges] = useState([]);
	const [extraTaxes, setExtraTaxes] = useState([]);
	const [extraTaxCodes, setExtraTaxCodes] = useState([]);
	const [taxes, setTaxes] = useState([]);
	const [taxCodes, setTaxCodes] = useState([]);
	const [startTimes, setStartTimes] = useState([]);
	const [vehicles, setVehicles] = useState([]);
	const [endTimes, setEndTimes] = useState([]);
	const [useSameTimes, setUseSameTimes] = useState(false);
	const [isItemReserved, setIsItemReserved] = useState(false);
	const [editedReservationItemId, setEditedReservationItemId] = useState(0);
	const [isBackClicked, setIsBackClicked] = useState(false);
	const [reserveButtonEnabled, setReserveButtonEnabled] = useState(true);
	const [allPerOrderExtras, setAllPerOrderExtras] = useState({});
	const [searchBarMin, setSearchBarMin] = useState(false);
	const timerId = useRef(0);
	const reservationItems = useRef([]);
	const refSearchBar = useRef(null);
	const sizeSearchBar = useComponentSize(refSearchBar);
	const [modal, openModal, closeModal] = useModal();
	const [payStatus, setPayStatus] = useState(reservationPayStatuses.DEFAULT);
	const [multipleSelected, setMultipleSelected] = useState(null);
	const [headerHeight, setHeaderHeight] = useState(getHeaderHeight());
	const [startTimesValRes, setStartTimesValRes] = useState([]);
	const [startTimesShowVal, setStartTimesShowVal] = useState([]);
	const [endTimesValRes, setEndTimesValRes] = useState([]);
	const [endTimesShowVal, setEndTimesShowVal] = useState([]);
	const [customer, setCustomer] = useState(null);
	const [payment, setPayment] = useState({
		type: paymentTypes.CREDIT_CARD,
		creditCard: null,
		cardNumber: '',
		cardName: '',
		expiry: '',
		cvc: '',
		saveCreditCard: false,
	});

	const [loginMsgStatus, setLoginMsgStatus] = useState(false);

	const [termsAndConditions, setTermsAndConditions] = useState(false);

	const [promoCode, setPromoCode] = useState('');

	const [isCheckingPromoCode, setIsCheckingPromoCode] = useState(false);

	const appliedPromoCode = useRef(null);

	const customerCheckIntervalId = useRef(0);

	const [tokenizePayment] = usePaymentGateway(outlet, paymentMethods);

	const perUnitPromoCodeAmount = getPerUnitPromoCodeAmount(
		reservationItems.current,
		appliedPromoCode.current
	);

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [resultView, setResultView] = useState(searchResultViews.LIST);

	const [needSearch, setNeedSearch] = useState(true);

	const serviceFeeAmount = useMemo(
		() =>
			getPaymentProcessorServiceFeeAmount(
				outlet,
				serviceFeeModules.ONLINE_BOOKINGS,
				getPaymentProcessor(
					outlet,
					selectedPaymentMethod,
					customerPaymentForms.RESERVATION_DEPOSITS
				)
			),
		[outlet, selectedPaymentMethod]
	);

	const availableBookingTypes = useMemo(() => {
		return bookingTypes
			.filter(bt => !bt.inactive && (!isOnline || bt.showOnline))
			.sort((a, b) => a.name.localeCompare(b.name));
	}, [bookingTypes, isOnline]);

	const location = useLocation();

	const currentPage = useMemo(() => pages[modulePageKeys[getModuleByPath(location.pathname)]], [
		location,
		pages,
	]);

	const calculateTax = useCallback(
		(additionalTaxes, productTotal) => {
			const productTaxRate = outlet.settings.taxRate;
			let productTax = 0;

			if (productTaxRate.type.value === 'percent')
				productTax = (productTotal * productTaxRate.amount) / 100;
			else if (productTaxRate.type.value === 'fixed') productTax = productTaxRate.amount;

			additionalTaxes.forEach(additionalTax => {
				if (additionalTax.type === 'percent')
					productTax = addFloats(
						productTax,
						numberFormat((productTotal * additionalTax.amount) / 100)
					);
				else if (additionalTax.type === 'fixed')
					productTax = addFloats(productTax, additionalTax.amount);
			});

			return productTax;
		},
		[outlet]
	);

	const calculateDeposit = useCallback(
		ind =>
			getItemDepositAmount(
				selectedProduct,
				prices[ind],
				taxes[ind],
				extras[ind],
				extraCharges[ind],
				extraTaxes[ind],
				appliedPromoCode.current
			),
		[selectedProduct, prices, extraCharges, taxes, extraTaxes, extras]
	);

	const parseExtras = useCallback(
		() =>
			extras.map((item, index) => {
				let retVal = [];
				item.forEach((category, c) => {
					retVal = retVal.concat(
						category
							.map((extra, e) => {
								return extraCharges[index][c][e] !== -1
									? {
											id: extra.id,
											quantity:
												extra.bookingCalculation === 'percent' ||
												extra.price === 0
													? 1
													: extraCharges[index][c][e] /
													  extra.price /
													  (extra.bookingCalculation ===
															bookingCalculations.PER_PERIOD ||
													  extra.bookingCalculation ===
															bookingCalculations.PER_QUANTITY_PER_PERIOD
															? getReservationExtraChargePeriod(
																	quantities[index],
																	selectedProduct.bookingPeriod,
																	extra.bookingCalculation,
																	extra.bookingPeriod
															  )
															: 1),
											tax: extraTaxes[index][c][e],
											taxCode: extraTaxCodes[index][c][e],
											total:
												extraCharges[index][c][e] + extraTaxes[index][c][e],
									  }
									: null;
							})
							.filter(extra => extra !== null)
					);
				});
				return retVal;
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[extras, extraCharges, extraTaxes]
	);

	const onSearch = useCallback(
		(_searchData = {}) => {
			setIsLoading(true);

			const {
				bookingType,
				adults,
				children,
				fromDate,
				toDate,
				ignoreRules,
				loa,
				beam,
				draft,
				height,
				weight,
			} = { ...searchData, ..._searchData };

			apiCall(
				'POST',
				'reservationSearchAvailability',
				res => {
					setMultipleSelected(null);
					setProductCategories(res);
					setIsLoading(false);
					setSearched(true);
					setNeedSearch(false);
				},
				() => {
					setIsLoading(false);
					addErrorNotification('Search failed.');
				},
				'',
				{
					outletId: outlet.id,
					token: isOnline ? outlet.settings.onlineSiteToken : '',
					moduleId: bookingType.module.id,
					bookingTypeId: bookingType.id,
					fromDate: dateFormatter(fromDate, false),
					toDate: toDate ? dateFormatter(toDate, false) : dateFormatter(fromDate, false),
					adults: adults ? adults.id : 0,
					children: children ? children.id : 0,
					count: 1,
					ignoreRules,
					ignoredReservationItemId: 0,
					loa,
					beam,
					draft,
					height,
					weight,
					sqft: calculateSqft(loa, beam),
				}
			);
		},
		[isOnline, outlet.id, outlet.settings.onlineSiteToken, searchData]
	);

	const reset = (cancel = false) => {
		if (cancel) {
			setSearchData({
				bookingType: null,
				fromDate: undefined,
				toDate: undefined,
				adults: null,
				children: null,
				loa: null,
				beam: null,
				height: null,
				draft: null,
				weight: null,
				ignoreRules: false,
				alternateDateSearch: false,
			});
			setSearchBarKey(Date.now());
			setTermsAndConditions(false);
		}
		setSearched(false);
		setIsLoading(false);
		setProductCategories({});
		setSelectedProduct(null);
		setSelectedUnit(0);
		setNumberOfUnits(1);
		setActiveStep(0);
		setReservationId(0);
		setReservationCustomId(0);
		setPayStatus(reservationPayStatuses.DEFAULT);
		setNeedSearch(true);
	};

	const selectProduct = useCallback(
		(product, numUnits, numPeriod, totalText, unitId = 0) => {
			if (needSearch) return;

			setSelectedProduct(product);
			setSelectedUnit(unitId);
			setNumberOfUnits(numUnits);
			setIntervalText(`${numPeriod} ${totalText}`);
			setIsItemReserved(false);
		},
		[needSearch]
	);

	const onReserve = useCallback(() => {
		const {
			fromDate,
			toDate,
			ignoreRules,
			bookingType,
			loa,
			beam,
			draft,
			height,
			weight,
		} = searchData;

		if (
			(bookingType.module.value === modules.MARINA ||
				bookingType.module.value === modules.CAMPGROUND) &&
			vehicles.some(v => !v || !v.isValid)
		) {
			addErrorNotification(
				`Please fill out ${
					bookingType.module.value === modules.MARINA ? 'Vessel' : 'Vehicle'
				} information`
			);
			return;
		}

		setIsLoading(true);

		apiCall(
			'POST',
			'reservationReserveItem',
			res => {
				const {
					reservationId: _reservationId,
					reservationCustomId: _reservationCustomId,
					reservationItemCustomIds,
					reservationModifiedTime,
				} = res;

				setIsLoading(false);
				setIsItemReserved(true);
				setActiveStep(2);
				setReservationId(_reservationId);
				setReservationCustomId(_reservationCustomId);
				setCookie('sdms_reservation_id', _reservationId, 0);
				const newAllPerOrderExtras = { ...allPerOrderExtras };
				setReservationTimeModified(reservationModifiedTime);
				res.reservationItemIds.forEach((id, ind) => {
					reservationItems.current.push({
						id,
						product: selectedProduct,
						unit: selectedUnit,
						fromDate,
						toDate,
						startTime: startTimes[ind],
						endTime: endTimes[ind],
						quantity: quantities[ind],
						price: prices[ind],
						tax: taxes[ind],
						extra: extras[ind],
						extraCharge: extraCharges[ind],
						extraTax: extraTaxes[ind],
						extraTaxCode: extraTaxCodes[ind],
						note: notes[ind],
						deposit: calculateDeposit(ind),
						intervalText,
						isHourlyOnly,
						reservationItemId: reservationItemCustomIds[ind],
						taxCode: taxCodes[ind],
						module: bookingType.module,
						vehicle: { ...vehicles[ind], id: res.vehicleIds[ind] },
					});
					extras[ind].forEach(cat => {
						cat.forEach(extraItem => {
							if (extraItem.type !== 'Per Unit') {
								if (newAllPerOrderExtras[extraItem.id])
									newAllPerOrderExtras[extraItem.id] += 1;
								else newAllPerOrderExtras[extraItem.id] = 1;
							}
						});
					});
				});
				setAllPerOrderExtras(newAllPerOrderExtras);
				clearTimeout(timerId.current);
			},
			() => {
				setIsLoading(false);
				addErrorNotification('Bookings could not be reserved, please search again.');
			},
			'',
			{
				outletId: outlet.id,
				token: isOnline ? outlet.settings.onlineSiteToken : '',
				moduleId: bookingType.module.id,
				customerId: customer ? customer.id : outlet.settings.defaultCustomer.id,
				reservationId,
				productId: selectedProduct.id,
				ratePlanId: selectedProduct.pricing.ratePlanId
					? selectedProduct.pricing.ratePlanId
					: null,
				fromDateTimes: startTimes.map(time => {
					const dateTime = moment();

					dateTime
						.utc(false)
						.year(fromDate.getFullYear())
						.month(fromDate.getMonth())
						.date(fromDate.getDate())
						.hour(parseInt(time.split(':')[0], 10))
						.minute(parseInt(time.split(':')[1], 10))
						.second(0)
						.millisecond(0);

					return dateTime;
				}),
				toDateTimes: endTimes.map(time => {
					const dateTime = moment();

					dateTime
						.utc(false)
						.year((toDate || fromDate).getFullYear())
						.month((toDate || fromDate).getMonth())
						.date((toDate || fromDate).getDate())
						.hour(parseInt(time.split(':')[0], 10))
						.minute(parseInt(time.split(':')[1], 10))
						.second(0)
						.millisecond(0);
					return dateTime;
				}),
				extras: parseExtras(),
				quantities,
				capacities: quantities.map(q => getCapacity({ bookingType }, q, loa, beam)),
				counts: prices.map(() => 1),
				subtotals: prices,
				taxes,
				totals: prices.map((price, ind) => price + taxes[ind]),
				depositAmounts: prices.map((price, ind) => calculateDeposit(ind)),
				notes,
				ignoreRules,
				isOnline,
				taxCodes,
				unit: selectedUnit,
				loa,
				beam,
				draft,
				height,
				weight,
				vehicles,
				customer,
			}
		);
	}, [
		selectedProduct,
		selectedUnit,
		quantities,
		prices,
		taxes,
		parseExtras,
		extras,
		extraCharges,
		extraTaxes,
		searchData,
		startTimes,
		endTimes,
		notes,
		intervalText,
		isHourlyOnly,
		reservationId,
		calculateDeposit,
		allPerOrderExtras,
		outlet,
		isOnline,
		customer,
		extraTaxCodes,
		taxCodes,
		vehicles,
	]);

	const onReservationItemEdit = useCallback(reservationItemId => {
		const reservationItem = reservationItems.current.find(
			item => item.id === reservationItemId
		);
		setSelectedProduct(reservationItem.product);
		setSelectedUnit(reservationItem.unit);
		setNumberOfUnits(1);
		setStartTimes([reservationItem.startTime]);
		setEndTimes([reservationItem.endTime]);
		setQuantities([reservationItem.quantity]);
		setPrices([reservationItem.price]);
		setTaxes([reservationItem.tax]);
		setTaxCodes([reservationItem.taxCode]);
		setExtras([reservationItem.extra]);
		setExtraCharges([reservationItem.extraCharge]);
		setExtraTaxes([reservationItem.extraTax]);
		setExtraTaxCodes([reservationItem.extraTaxCode]);
		setVehicles([reservationItem.vehicle || null]);
		setNotes([reservationItem.note]);
		setEditedReservationItemId(reservationItemId);
		setIsItemReserved(true);
		setActiveStep(1);
	}, []);

	const onReservationItemUpdate = useCallback(() => {
		setIsLoading(true);
		apiCall(
			'POST',
			'reservationUpdateItem',
			res => {
				const { reservationModifiedTime } = res;
				setReservationTimeModified(reservationModifiedTime);
				const idx = reservationItems.current.findIndex(
					item => item.id === editedReservationItemId
				);
				const newAllPerOrderExtras = { ...allPerOrderExtras };
				reservationItems.current[idx].extra.forEach(cat => {
					cat.forEach(extraItem => {
						if (extraItem.type !== 'Per Unit') newAllPerOrderExtras[extraItem.id] -= 1;
					});
				});
				extras[0].forEach(cat => {
					cat.forEach(extraItem => {
						if (extraItem.type !== 'Per Unit') {
							if (newAllPerOrderExtras[extraItem.id])
								newAllPerOrderExtras[extraItem.id] += 1;
							else newAllPerOrderExtras[extraItem.id] = 1;
						}
					});
				});
				setAllPerOrderExtras(newAllPerOrderExtras);
				[reservationItems.current[idx].extraCharge] = extraCharges;
				[reservationItems.current[idx].extra] = extras;
				[reservationItems.current[idx].extraTax] = extraTaxes;
				[reservationItems.current[idx].tax] = taxes;
				[reservationItems.current[idx].note] = notes;
				[reservationItems.current[idx].vehicle] = vehicles;
				reservationItems.current[idx].deposit = calculateDeposit(0);
				addSuccessNotification('Booking is updated.');
				setIsLoading(false);
				setActiveStep(2);
			},
			() => {
				setIsLoading(false);
				addErrorNotification('Bookings could not be updated.');
			},
			'',
			{
				outletId: outlet.id,
				token: isOnline ? outlet.settings.onlineSiteToken : '',
				reservationItemId: editedReservationItemId,
				extra: parseExtras()[0],
				note: notes[0],
				vehicle: vehicles[0],
				customer,
			}
		);
	}, [
		customer,
		editedReservationItemId,
		parseExtras,
		calculateDeposit,
		extras,
		notes,
		allPerOrderExtras,
		outlet,
		isOnline,
		extraCharges,
		taxes,
		extraTaxes,
		vehicles,
	]);

	const onReservationCancel = (signOut = false) => {
		clearTimeout(timerId.current);
		setReservationTimeModified('');

		if (reservationId) {
			setIsLoading(true);
			apiCall(
				'POST',
				'reservationCancel',
				() => {
					data.extraCharges = [];

					appliedPromoCode.current = null;

					setPromoCode('');

					setIsLoading(false);
					closeModal();

					if (isBackClicked && currentPage) history.push(currentPage.reservations.path);
					else {
						reservationItems.current = [];
						reset(true);
					}
					if (!isOnline) addSuccessNotification('Reservation is cancelled.');

					if (signOut) {
						onCustomerChange(null);
					}
				},
				() => {
					setIsLoading(false);
					closeModal();
					if (!isOnline) addErrorNotification('Reservation could not be cancelled.');
				},
				'',
				{
					outletId: outlet.id,
					token: isOnline ? outlet.settings.onlineSiteToken : '',
					reservationId,
				}
			);
		} else {
			reset();
			closeModal();
		}
	};

	const onReservationItemDelete = () => {
		setIsLoading(true);

		const { itemIdToBeDeleted } = modal;

		closeModal();

		apiCall(
			'POST',
			'reservationCancelItem',
			() => {
				const newAllPerOrderExtras = { ...allPerOrderExtras };
				const deletedItem = reservationItems.current.find(
					item => item.id === itemIdToBeDeleted
				);

				deletedItem.extra.forEach(cat => {
					cat.forEach(extraItem => {
						if (extraItem.type !== 'Per Unit') newAllPerOrderExtras[extraItem.id] -= 1;
					});
				});

				setAllPerOrderExtras(newAllPerOrderExtras);

				reservationItems.current = reservationItems.current.filter(
					item => item.id !== itemIdToBeDeleted
				);

				// does this make sense if multiple items?
				if (
					appliedPromoCode.current &&
					!reservationItems.current.some(
						reservationItem =>
							reservationItem.extra[0] &&
							reservationItem.extra[0].some(e => e.id === appliedPromoCode.current.id)
					)
				)
					appliedPromoCode.current = null;

				setIsLoading(false);

				if (reservationItems.current.length === 0) onReservationCancel();

				if (!isOnline) addSuccessNotification('Booking is deleted.');
			},
			() => {
				setIsLoading(false);

				if (!isOnline) addErrorNotification('Booking could not be deleted.');
			},
			'',
			{
				outletId: outlet.id,
				token: isOnline ? outlet.settings.onlineSiteToken : '',
				reservationItemId: itemIdToBeDeleted,
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	const pay = paymentData => {
		apiCall(
			'POST',
			'payDeposit',
			() => {
				data.extraCharges = [];

				appliedPromoCode.current = null;

				setPromoCode('');

				setPayStatus(reservationPayStatuses.PAID);
				if (!isOnline) setCustomer(null);
				else removeCookie('sdms_reservation_id');

				openModal({
					open:
						outlet.settings.simpleOnlineConfirmation && isOnline
							? modals.SIMPLE_ONLINE_CONFIRMATION
							: modals.CONFIRMATION,
				});

				setReservationTimeModified('');
				setPayment({
					type: paymentTypes.CREDIT_CARD,
					creditCard: null,
					cardNumber: '',
					cardName: '',
					expiry: '',
					cvc: '',
					saveCreditCard: false,
				});
			},
			err => {
				addErrorNotification(err.toString());
				setPayStatus(reservationPayStatuses.NOT_PAID);
			},

			'',
			{
				outletId: outlet.id,
				token: isOnline ? outlet.settings.onlineSiteToken : '',
				reservationId,
				customerId: customer.id,
				payment: paymentData,
				sendConfirmationMail: true,
			}
		);
	};

	const onPay = () => {
		if (selectedPaymentMethod === '') return;

		setIsSubmitted(true);

		// check customer.
		if (!customer) {
			if (isOnline) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
				setLoginMsgStatus(true);
			} else {
				addErrorNotification('Please select customer');
			}

			return;
		}

		if (customer.id === outlet.settings.defaultCustomer.id) {
			addErrorNotification('Cannot confirm with default customer');
			return;
		}

		const totals = getTotals(data, reservationItems);

		if (totals.totalDueToday !== 0 && !validatePaymentFields(selectedPaymentMethod, payment)) {
			addErrorNotification('Please fill all payment fields.');
			return;
		}

		if (!termsAndConditions && isOnline && outlet.settings.termsAndConditions) {
			addErrorNotification('Please accept the Terms & Conditions');
			return;
		}

		setPayStatus(reservationPayStatuses.PAYING);

		let paymentData = {
			...payment,
			amount: totals.totalDueToday,
			serviceFee: calculateServiceFee(totals.totalDueToday, serviceFeeAmount),
		};

		if (totals.totalDueToday) {
			tokenizePayment(
				payment.type,
				parsePaymentData(payment.type, payment),
				tokenizedPayment => {
					if (tokenizedPayment) paymentData = { ...paymentData, ...tokenizedPayment };

					pay(paymentData);
				},
				err => {
					addErrorNotification(err);
					setPayStatus(reservationPayStatuses.NOT_PAID);
				}
			);
		} else pay(paymentData);
	};

	const onContinue = () => {
		closeModal();
		// stop time until update reservation.
		const oldReservationTimeModified = reservationTimeModified;
		setReservationTimeModified('');
		if (reservationId) {
			apiCall(
				'POST',
				'reservationUpdateTimeModified',
				res => {
					const { reservationModifiedTime } = res;
					setReservationTimeModified(reservationModifiedTime);
				},
				err => {
					addErrorNotification(err.toString());
					setReservationTimeModified(oldReservationTimeModified);
				},
				'',
				{
					outletId: outlet.id,
					token: isOnline ? outlet.settings.onlineSiteToken : '',
					reservationId,
				}
			);
		}
	};

	const onCustomerUpdate = () => {
		if (reservationId) {
			apiCall(
				'POST',
				'reservationUpdateCustomer',
				() => {},
				err => {
					addErrorNotification(err.toString());
				},
				'',
				{
					outletId: outlet.id,
					token: isOnline ? outlet.settings.onlineSiteToken : '',
					reservationId,
					customer,
				}
			);
		}
	};

	const getCustomerData = customerId => {
		apiCall(
			'POST',
			'onlineGetCustomerData',
			res => {
				onCustomerChange(res);
			},
			() => {
				removeCookie('sdms_customer_id');
			},
			'',
			{
				outletId: outlet.id,
				token: outlet.settings.onlineSiteToken,
				id: customerId,
			}
		);
	};

	const onCustomerChange = _customer => {
		if (_customer) {
			setCustomer(_customer);
			if (isOnline) setCookie('sdms_customer_id', _customer.id);
		} else if (customer) {
			reservationItems.current = [];
			setReservationTimeModified('');
			setCustomer(null);
			setActiveStep(0);
			if (isOnline) {
				removeCookie('sdms_customer_id');
				removeCookie('sdms_reservation_id');
			}
		}
	};

	const onPromoCodeApply = () => {
		setIsCheckingPromoCode(true);

		apiCall(
			'POST',
			'onlineCheckPromoCode',
			response => {
				const { promoCode: _promoCode, allOrders, suitableItemIds } = response;

				if (!allOrders && suitableItemIds.length === 0) {
					addErrorNotification('No suitable item');
					setIsCheckingPromoCode(false);
					return;
				}

				if (allOrders) {
					const _extraCharges = addReservationExtraCharge(
						[...(data.extraCharges || [])],
						_promoCode,
						customer,
						outlet.settings.taxRate,
						getTotals(data, reservationItems)
					);

					if (_extraCharges) {
						apiCall(
							'POST',
							'onlineUpdateReservationExtraCharges',
							res => {
								data.extraCharges = res.extraCharges;

								appliedPromoCode.current = _promoCode;

								addSuccessNotification(
									'Promo code successfully applied to reservation'
								);

								setIsCheckingPromoCode(false);
							},
							err => {
								addErrorNotification(err.toString());
								setIsCheckingPromoCode(false);
							},
							'',
							{
								outletId: outlet.id,
								token: isOnline ? outlet.settings.onlineSiteToken : '',
								extraCharges: _extraCharges,
								reservationId,
							}
						);
					} else setIsCheckingPromoCode(false);
				} else {
					let applied = false;

					reservationItems.current = reservationItems.current.map(reservationItem => {
						if (suitableItemIds.indexOf(reservationItem.id) === -1)
							return reservationItem;

						const _reservationItem = addExtraChargeToItem(
							reservationItem,
							_promoCode,
							customer,
							outlet.settings.taxRate,
							reservationItem.extra
						);

						if (_reservationItem) {
							applied = true;

							appliedPromoCode.current = _promoCode;

							_reservationItem.deposit = getItemDepositAmount(
								_reservationItem.product,
								_reservationItem.price,
								_reservationItem.tax,
								_reservationItem.extra,
								_reservationItem.extraCharge,
								_reservationItem.extraTax,
								appliedPromoCode.current
							);

							apiCall(
								'POST',
								'reservationUpdateItem',
								() => {},
								() => {},
								'',
								{
									outletId: outlet.id,
									token: isOnline ? outlet.settings.onlineSiteToken : '',
									reservationItemId: _reservationItem.id,
									deposit: _reservationItem.deposit,
									extra: itemToExtras(_reservationItem),
									note: _reservationItem.note,
								}
							);
						}

						return _reservationItem || reservationItem;
					});

					setIsCheckingPromoCode(false);

					if (applied)
						addSuccessNotification('Promo code successfully applied to items.');
					else addErrorNotification('No suitable item');
				}
			},
			err => {
				addErrorNotification(err.toString());
				setIsCheckingPromoCode(false);
			},
			'',
			{
				outletId: outlet.id,
				code: promoCode,
				token: isOnline ? outlet.settings.onlineSiteToken : '',
				reservationItems: reservationItems.current.map(reservationItem => ({
					id: reservationItem.id,
					productId: reservationItem.product.id,
					price: reservationItem.price,
					duration: reservationItem.quantity,
					fromDate: reservationItem.fromDate,
					toDate: reservationItem.toDate,
				})),
			}
		);
	};

	const onPromoCodeRemove = () => {
		if (appliedPromoCode.current.extraChargeType === extraChargeTypes.PER_UNIT) {
			// clear from reservation items.
			reservationItems.current = reservationItems.current.map(reservationItem => {
				let extraChargeIndex = -1;
				let categoryIndex = -1;

				reservationItem.extra = reservationItem.extra.map((extra, e) => {
					extraChargeIndex = extra.findIndex(_e => _e.id === appliedPromoCode.current.id);

					if (extraChargeIndex > -1) categoryIndex = e;

					return extra.filter(_e => _e.id !== appliedPromoCode.current.id);
				});

				reservationItem.extra = reservationItem.extra.filter(e => e.length !== 0);

				if (extraChargeIndex > -1 && categoryIndex > -1) {
					reservationItem.extraCharge[categoryIndex].splice(extraChargeIndex, 1);

					reservationItem.extraCharge = reservationItem.extraCharge.filter(
						e => e.length !== 0
					);

					reservationItem.extraTax[categoryIndex].splice(extraChargeIndex, 1);

					reservationItem.extraTax = reservationItem.extraTax.filter(e => e.length !== 0);

					reservationItem.extraTaxCode[categoryIndex].splice(extraChargeIndex, 1);

					reservationItem.extraTaxCode = reservationItem.extraTaxCode.filter(
						e => e.length !== 0
					);

					reservationItem.deposit = getItemDepositAmount(
						reservationItem.product,
						reservationItem.price,
						reservationItem.tax,
						reservationItem.extra,
						reservationItem.extraCharge,
						reservationItem.extraTax,
						null
					);

					apiCall(
						'POST',
						'reservationUpdateItem',
						() => {},
						() => {},
						'',
						{
							outletId: outlet.id,
							token: isOnline ? outlet.settings.onlineSiteToken : '',
							reservationItemId: reservationItem.id,
							extra: itemToExtras(reservationItem),
							note: reservationItem.note,
						}
					);
				}

				return reservationItem;
			});
		} else {
			apiCall(
				'POST',
				'onlineUpdateReservationExtraCharges',
				() => {},
				() => {},
				'',
				{
					outletId: outlet.id,
					token: isOnline ? outlet.settings.onlineSiteToken : '',
					extraCharges: [],
					reservationId,
				}
			);
		}

		// clear reservation extra charge no need any control.
		data.extraCharges = [];

		appliedPromoCode.current = null;

		setPromoCode('');
	};

	const onSearchReset = useCallback(() => {
		setProductCategories({});
		setSearched(false);
	}, []);

	const onSearchChange = useCallback(() => {
		if (searched) reset();
	}, [searched]);

	// Effect to initialize states for each item and go to next step after a product is selected
	useEffect(() => {
		if (selectedProduct && !isItemReserved) {
			const emptyExtras = Array.from({ length: numberOfUnits }, () =>
				Array.from(Array(Object.keys(selectedProduct.extraCharges).length), () => [])
			);
			const emptyExtraCharges = Array.from({ length: numberOfUnits }, () =>
				Array.from(Array(Object.keys(selectedProduct.extraCharges).length), () => [0])
			);
			const emptyExtraTaxes = Array.from({ length: numberOfUnits }, () =>
				Array.from(Array(Object.keys(selectedProduct.extraCharges).length), () => [0])
			);

			setExtras(emptyExtras);
			setExtraCharges(emptyExtraCharges);
			setExtraTaxes(emptyExtraTaxes);

			Object.values(selectedProduct.extraCharges).forEach(extraChargeGroup => {
				extraChargeGroup.items.forEach(item => {
					if (!item.isOptional && !item.enabled)
						emptyExtras.forEach(charges => charges[0].push(item));
				});
			});

			const falseExtrasShowVal = Array(numberOfUnits)
				.fill([])
				.map(() => Array(Object.keys(selectedProduct.extraCharges).length).fill(false));
			const initExtrasVal = Array(numberOfUnits)
				.fill([])
				.map(() =>
					Object.keys(selectedProduct.extraCharges).map(key =>
						selectedProduct.extraCharges[key].min
							? {
									isValid: false,
									status: 'minSelection',
									message: `At least ${selectedProduct.extraCharges[key].min} item(s) should be selected!`,
							  }
							: {
									isValid: true,
									status: 'valid',
									message: '',
							  }
					)
				);

			if (selectedProduct.bookingPeriod !== bookingPeriods.HOURLY) {
				setQuantities(Array(numberOfUnits).fill(selectedProduct.pricing.prices[1].length));
				setStartTimes(Array(numberOfUnits).fill(selectedProduct.checkIn));
				setStartTimesValRes([]);
				setStartTimesShowVal([]);
				setEndTimes(Array(numberOfUnits).fill(selectedProduct.checkOut));
				setEndTimesValRes([]);
				setEndTimesShowVal([]);
				const taxCode = getItemTaxCode(customer ? customer.taxCode : null, selectedProduct);

				const productTotals = [];

				const productTaxes = [];

				Array(numberOfUnits)
					.fill(0)
					.forEach((v, i) => {
						const _prices = selectedProduct.pricing.prices[i + 1];

						const productTotal =
							selectedProduct.bookingCalculation === bookingCalculations.FIXED
								? _prices[0]
								: _prices.reduce((a, b) => a + b, 0);
						productTotals.push(productTotal);

						productTaxes.push(
							taxCode.taxable && selectedProduct.isTaxable
								? calculateTax(selectedProduct.additionalTaxes, productTotal)
								: 0
						);
					});

				setPrices(productTotals);
				setTaxes(productTaxes);
				setTaxCodes(Array(numberOfUnits).fill(taxCode.id));
			} else {
				const initTimesValRes = Array(numberOfUnits)
					.fill([])
					.map(() => {
						return {
							isValid: false,
							status: 'required',
							message: 'Field is required',
						};
					});
				const initTimesShowVal = Array(numberOfUnits)
					.fill([])
					.map(() => false);
				setQuantities(Array(numberOfUnits).fill(0));
				setStartTimes(Array(numberOfUnits).fill(null));
				setEndTimes(Array(numberOfUnits).fill(null));
				setStartTimesValRes(initTimesValRes);
				setStartTimesShowVal(initTimesShowVal);
				setEndTimesValRes(initTimesValRes);
				setEndTimesShowVal(initTimesShowVal);
				setPrices(Array(numberOfUnits).fill(0));
				setTaxes(Array(numberOfUnits).fill(0));
				const taxCode = getItemTaxCode(customer ? customer.taxCode : null, selectedProduct);
				setTaxCodes(Array(numberOfUnits).fill(taxCode.id));
			}

			setNotes(Array(numberOfUnits).fill(''));
			setVehicles(Array(numberOfUnits).fill(null));
			setExtrasShowVal(falseExtrasShowVal);
			setExtrasVal(initExtrasVal);
			setActiveStep(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedProduct]);

	// Effect to update quantities, prices and taxes for hourly products
	useEffect(() => {
		if (selectedProduct && selectedProduct.bookingPeriod === bookingPeriods.HOURLY) {
			const newQuantities = [...quantities];
			const newPrices = [...prices];
			const newTaxes = [...taxes];
			const newTaxCodes = [...taxCodes];

			quantities.forEach((v, i) => {
				const startTime = moment(startTimes[i], 'HH:mm');
				const endTime = moment(endTimes[i], 'HH:mm');
				const duration = moment.duration(endTime.diff(startTime)).asMinutes();
				// not divided by selectedProduct.bookingInterval
				newQuantities[i] = Math.ceil(duration / 60);
				newPrices[i] =
					selectedProduct.bookingCalculation === bookingCalculations.FIXED
						? selectedProduct.pricing.prices[i + 1][0]
						: selectedProduct.pricing.prices[i + 1][0] * newQuantities[i];

				const taxCode = getItemTaxCode(customer ? customer.taxCode : null, selectedProduct);

				newTaxCodes[i] = taxCode.id;

				newTaxes[i] =
					taxCode.taxable && selectedProduct.isTaxable
						? calculateTax(selectedProduct.additionalTaxes, newPrices[i])
						: 0;
			});
			setQuantities(newQuantities);
			setPrices(newPrices);
			setTaxes(newTaxes);
			setTaxCodes(newTaxCodes);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startTimes, endTimes]);

	// Effect to update Price Adjustments and taxes as extras change
	useEffect(() => {
		if (selectedProduct) {
			const newExtraCharges = Array.from({ length: numberOfUnits }, () =>
				Array.from(Array(Object.keys(selectedProduct.extraCharges).length), () => [0])
			);
			const newExtraTaxes = Array.from({ length: numberOfUnits }, () =>
				Array.from(Array(Object.keys(selectedProduct.extraCharges).length), () => [0])
			);

			const newExtraTaxCodes = Array.from({ length: numberOfUnits }, () =>
				Array.from(Array(Object.keys(selectedProduct.extraCharges).length), () => [0])
			);

			const perOrderExtras = {};

			extras.forEach(itemExtras => {
				itemExtras.forEach(category => {
					category.forEach(extra => {
						if (extra.type !== 'Per Unit') {
							if (perOrderExtras[extra.id]) perOrderExtras[extra.id] += 1;
							else perOrderExtras[extra.id] = 1;
						}
					});
				});
			});

			extras.forEach((itemExtras, index) => {
				itemExtras.forEach((category, c) => {
					category.forEach((extra, e) => {
						newExtraCharges[index][c][e] = calculateExtraChargePrice(
							selectedProduct,
							extra,
							extras
						);

						const { minPrice, maxPrice } = extra;

						if (
							minPrice !== null &&
							minPrice !== '' &&
							minPrice !== 0 &&
							newExtraCharges[index][c][e] < minPrice
						)
							newExtraCharges[index][c][e] = minPrice;

						if (
							maxPrice !== null &&
							maxPrice !== '' &&
							maxPrice !== 0 &&
							newExtraCharges[index][c][e] > maxPrice
						)
							newExtraCharges[index][c][e] = maxPrice;

						const taxCode = getItemTaxCode(customer ? customer.taxCode : null, extra);

						newExtraTaxCodes[index][c][e] = taxCode.id;

						if (taxCode.taxable && extra.taxCode && extra.taxCode.taxable)
							newExtraTaxes[index][c][e] = calculateTax(
								extra.additionalTaxes,
								newExtraCharges[index][c][e]
							);
						else newExtraTaxes[index][c][e] = 0;
					});
				});
			});

			setExtraCharges(newExtraCharges);
			setExtraTaxes(newExtraTaxes);
			setExtraTaxCodes(newExtraTaxCodes);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [extras]);

	// Effect to keep per order Price Adjustments updated
	useEffect(() => {
		reservationItems.current.forEach(item => {
			item.extra.forEach((category, c) =>
				category.forEach((extra, e) => {
					if (extra.type !== extraChargeTypes.PER_UNIT) {
						const splitNumber = allPerOrderExtras[extra.id];
						if (extra.bookingCalculation === bookingCalculations.FIXED)
							item.extraCharge[c][e] = extra.price / splitNumber;
						else if (extra.bookingCalculation === bookingCalculations.PERCENT) {
							// percent of what exactly? split evenly or in proportion to item cost?
							item.extraCharge[c][e] = (item.price * extra.price) / 100;
						}
					}
				})
			);
		});
	}, [allPerOrderExtras]);

	// Effect to enable/disable the reserve button
	useEffect(() => {
		let _reservationButtonEnabled = true;

		extrasVal.forEach(item =>
			item.forEach(categoryVal => {
				if (!categoryVal.isValid) _reservationButtonEnabled = false;
			})
		);

		if (_reservationButtonEnabled)
			_reservationButtonEnabled =
				[...startTimesValRes, ...endTimesValRes].filter(valRes => !valRes.isValid)
					.length === 0;

		setReserveButtonEnabled(_reservationButtonEnabled);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [extrasVal, startTimesValRes, endTimesValRes, customer]);

	useEffect(() => {
		const customerCookie = getCookie('sdms_customer_id');

		if (customerCookie !== null && customerCookie !== '0' && isOnline)
			getCustomerData(customerCookie);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	// Effect for Scroll Top
	useLayoutEffect(() => {
		const sharperSearch = document.getElementById('sdms-search');

		if (sharperSearch && isOnline) window.scrollTo({ top: sharperSearch.offsetTop });

		if (activeStep === 0) setMultipleSelected(null);

		if (headerContext && currentPage) {
			if (activeStep === 0) {
				headerContext.setBreadcrumbs([
					{
						title: currentPage.default.text,
						path: currentPage.dashboard.path,
					},
					{ title: currentPage.reservations.new.text, isActive: true },
				]);
			} else {
				headerContext.setBreadcrumbs([
					{
						title: currentPage.default.text,
						path: currentPage.dashboard.path,
					},
					{
						title: currentPage.reservations.new.text,
						path: currentPage.reservations.new.path,
					},
					{
						title:
							(activeStep === 1 && 'Options') ||
							(activeStep === 2 && 'Summary') ||
							(activeStep === 3 && 'Payment'),
						isActive: true,
					},
				]);
			}
		}

		setIsSubmitted(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeStep, currentPage]);

	// For Select Credit Card
	useEffect(() => {
		if (payment.creditCard) {
			setPayment({
				...payment,
				cardNumber: '',
				cardName: '',
				expiry: '',
				cvc: '',
				saveCreditCard: false,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payment.creditCard]);

	useEffect(() => {
		clearInterval(customerCheckIntervalId.current);

		if (isOnline) {
			customerCheckIntervalId.current = setInterval(() => {
				const customerId = getCookie('sdms_customer_id');

				if (
					customerId &&
					customerId !== '0' &&
					(!customer || customer.id.toString() !== customerId)
				)
					getCustomerData(customerId);

				if (!customerId) onCustomerChange(null);
			}, 500);

			if (customer) onCustomerUpdate();
		}

		let hasChange = false;

		reservationItems.current = reservationItems.current.map(i => {
			i.extra.forEach((itemExtras, index) => {
				itemExtras.forEach((extra, e) => {
					const extraTaxCode = getItemTaxCode(customer ? customer.taxCode : null, extra);

					if (extraTaxCode.id !== i.extraTaxCode[index][e]) {
						hasChange = true;
						i.extraTaxCode[index][e] = extraTaxCode.id;
						if (extraTaxCode.taxable && extra.taxCode && extra.taxCode.taxable)
							i.extraTax[index][e] = calculateTax(
								extra.additionalTaxes,
								i.extraCharge[index][e]
							);
						else i.extraTax[index][e] = 0;
					}
				});
			});

			const taxCode = getItemTaxCode(customer ? customer.taxCode : null, i.product);

			if (taxCode.id !== i.taxCode) {
				hasChange = true;

				i.tax = taxCode.taxable ? calculateTax(i.product.additionalTaxes, i.price) : 0;
				i.taxCode = taxCode.id;
			}

			return i;
		});

		if (hasChange)
			apiCall(
				'POST',
				'updateReservationTaxCode',
				() => {},
				err => {
					addErrorNotification(err.toString());
				},
				'',
				{
					outletId: outlet.id,
					token: isOnline ? outlet.settings.onlineSiteToken : '',
					reservationId,
					items: reservationItems.current,
				}
			);

		setPayment({
			...payment,
			creditCard: null,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customer]);

	// make init search.
	useEffect(() => {
		if (!initSearch.current && searchData.bookingType && searchData.fromDate) onSearch();
		initSearch.current = true;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (searchData.alternateDateSearch && searchData.bookingType && searchData.fromDate) {
			onSearch();
			setSearchData({ ...searchData, alternateDateSearch: false });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchData.alternateDateSearch]);

	//	For Sticky Portlet Header
	window.onscroll = () => {
		setHeaderHeight(getHeaderHeight());
		// For Search Bar minimize and Scroll Top
		if (!isOnline)
			if (window.scrollY >= sizeSearchBar.height + 20) {
				document.body.classList.add('sdms-scrolltop--on');
				setSearchBarMin(true);
			} else {
				setSearchBarMin(false);
				document.body.classList.remove('sdms-scrolltop--on');
			}
	};

	const [countDown, , stopCountDown, resetCountDown] = useCountDown(
		((outlet.settings || { reservationHoldPeriod: 15 }).reservationHoldPeriod || 15) * 60,
		onReservationCancel,
		30,
		() => openModal({ open: modals.CONTINUE })
	);

	useEffect(() => {
		if (reservationTimeModified) resetCountDown();
		else stopCountDown();

		return () => stopCountDown();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservationTimeModified]);

	const _Footer = (
		<Footer
			activeStep={activeStep}
			numberOfUnits={numberOfUnits}
			reservationItems={reservationItems}
			setActiveStep={setActiveStep}
			isItemReserved={isItemReserved}
			reserveButtonEnabled={reserveButtonEnabled}
			setExtrasShowVal={setExtrasShowVal}
			selectedProduct={selectedProduct}
			updateReservationItem={onReservationItemUpdate}
			reserveReservationItems={onReserve}
			resetSearch={() => {
				setSearched(false);
				setIsLoading(false);
				setProductCategories({});
				setSelectedProduct(null);
				setNumberOfUnits(1);
				setActiveStep(0);
			}}
			onPay={onPay}
			payStatus={payStatus}
			isLoading={isLoading}
			isOnline={isOnline}
			setLoginMsgStatus={setLoginMsgStatus}
			disabled={selectedPaymentMethod === ''}
			countDown={countDown}
		/>
	);

	if (activeStep === 0) {
		return (
			<>
				{!isOnline && (
					<ContentInner.SubHeader>
						<ContentInner.SubHeaderItem>
							<ContentInner.SubHeaderTitle
								title={pages.booking.reservations.new.text}
							/>
							{searchBarMin ? (
								<>
									<ContentInner.SubHeaderSeparator />
									<ContentInner.SubHeaderGroup>
										<ContentInner.SubHeaderDesc className='d-flex align-items-center sdms-reservation__subheader'>
											<span>Booking Type:</span>
											{searchData.bookingType && (
												<b>{searchData.bookingType.name}</b>
											)}
											<span>Date:</span>
											<b>
												{dateView(
													searchData.fromDate,
													searchData.toDate,
													false
												)}
											</b>
											<Button
												icon='Edit'
												design='clean'
												text='Change'
												onClick={() =>
													window.scrollTo({ top: 0, behavior: 'smooth' })
												}
											/>
										</ContentInner.SubHeaderDesc>
									</ContentInner.SubHeaderGroup>
								</>
							) : (
								<BreadcrumbContainer />
							)}
						</ContentInner.SubHeaderItem>
						<ContentInner.SubHeaderItem type='toolbar'>
							<Button
								design='default'
								text='Back'
								onClick={
									reservationId
										? () => {
												openModal({ open: modals.CANCEL });
												setIsBackClicked(true);
										  }
										: customOnBack
								}
							/>
						</ContentInner.SubHeaderItem>
					</ContentInner.SubHeader>
				)}
				<ContentInner.Container
					isFluid={false}
					hasFrame={!isOnline}
					title={pages.booking.reservations.new.text}>
					<Search
						setMultipleSelected={setMultipleSelected}
						overrideConstraints={overrideConstraints}
						onFormChange={onFormChange}
						onSearch={onSearch}
						isOnline={isOnline}
						isLoading={isLoading}
						searched={searched}
						productCategories={productCategories}
						multipleSelected={multipleSelected}
						headerHeight={headerHeight}
						outlet={outlet}
						selectProduct={selectProduct}
						refSearchBar={refSearchBar}
						data={data}
						bookingTypes={availableBookingTypes}
						initProducts={initProducts}
						key={searchBarKey}
						setIsHourlyOnly={setIsHourlyOnly}
						updateSearchData={setSearchData}
						searchData={searchData}
						resultView={resultView}
						setResultView={setResultView}
						sizeSearchBar={sizeSearchBar}
						onReset={onSearchReset}
						onSearchChange={onSearchChange}
					/>
					{reservationItems.current.length > 0 && isOnline && _Footer}
					<CancelDialog
						isOpen={modal.open === modals.CANCEL}
						onClose={closeModal}
						onCancel={() => onReservationCancel()}
					/>
					<ContinueDialog
						isOpen={modal.open === modals.CONTINUE}
						onCancel={() => onReservationCancel()}
						onContinue={onContinue}
					/>
				</ContentInner.Container>
				{reservationItems.current.length > 0 && !isOnline && _Footer}
			</>
		);
	}

	return (
		<>
			{!isOnline && (
				<ContentInner.SubHeader>
					<ContentInner.SubHeaderItem>
						<ContentInner.SubHeaderTitle
							title={
								(activeStep === 1 && 'Options') ||
								(activeStep === 2 && 'Summary') ||
								(activeStep === 3 && 'Payment')
							}
						/>
						<BreadcrumbContainer />
					</ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderItem type='toolbar'>
						<Button
							design='default'
							text='Back'
							onClick={
								reservationId
									? () => {
											setIsBackClicked(true);
											openModal({ open: modals.CANCEL });
									  }
									: () => history.push(currentPage?.reservations?.path)
							}
						/>
					</ContentInner.SubHeaderItem>
				</ContentInner.SubHeader>
			)}
			<ContentInner.Container isFluid={false} hasFrame>
				<WizardTop
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					documentTitle='New Reservation'
					hideNav
					hideTitle={!isOnline}>
					<WizardTop.Child title='Search' icon='Search' />
					<WizardTop.Child
						title='Options'
						icon='Settings#1'
						disabled={activeStep > 1}
						footer={isOnline && _Footer}>
						<Options
							notes={notes}
							selectedProduct={selectedProduct}
							setStartTimes={setStartTimes}
							endTimesValRes={endTimesValRes}
							quantities={quantities}
							extras={extras}
							setExtrasVal={setExtrasVal}
							taxes={taxes}
							extrasVal={extrasVal}
							isOnline={isOnline}
							startTimesValRes={startTimesValRes}
							setEndTimesShowVal={setEndTimesShowVal}
							setNotes={setNotes}
							setStartTimesShowVal={setStartTimesShowVal}
							endTimes={endTimes}
							setExtras={setExtras}
							extrasShowVal={extrasShowVal}
							isItemReserved={isItemReserved}
							setEndTimesValRes={setEndTimesValRes}
							extraTaxes={extraTaxes}
							prices={prices}
							startTimes={startTimes}
							startTimesShowVal={startTimesShowVal}
							setEndTimes={setEndTimes}
							useSameTimes={useSameTimes}
							setStartTimesValRes={setStartTimesValRes}
							extraCharges={extraCharges}
							setUseSameTimes={setUseSameTimes}
							isLoading={isLoading}
							isHourlyOnly={isHourlyOnly}
							numberOfUnits={numberOfUnits}
							setExtrasShowVal={setExtrasShowVal}
							endTimesShowVal={endTimesShowVal}
							headerHeight={headerHeight}
							intervalText={intervalText}
							vehicles={vehicles}
							setVehicles={setVehicles}
							enumRvTypes={enumRvTypes}
							enumVehicleMakes={enumVehicleMakes}
							enumBoatTypes={enumBoatTypes}
							enumBoatMakes={enumBoatMakes}
							customer={customer}
							onCustomerChange={onCustomerChange}
							outlet={outlet}
							countries={countries}
							defaultCountry={outlet.country}
							states={states}
							passwordSettings={passwordSettings}
							openModal={openModal}
							fromDate={searchData.fromDate}
						/>
					</WizardTop.Child>
					<WizardTop.Child
						title='Summary'
						icon='Clipboard-check'
						disabled={!reservationTimeModified}
						footer={isOnline && _Footer}>
						<Summary
							reservationItems={reservationItems.current}
							isLoading={isLoading}
							onReservationItemEdit={onReservationItemEdit}
							openModal={openModal}
							isOnline={isOnline}
							headerHeight={headerHeight}
							modal={modal}
							serviceFeeAmount={serviceFeeAmount}
							outlet={outlet}
						/>
					</WizardTop.Child>
					<WizardTop.Child
						title='Payment'
						icon='Dollar'
						disabled={!reservationTimeModified}
						prependChildren={
							isOnline && (
								<SignIn
									onLogin={_customer => {
										onCustomerChange(_customer);
										closeModal();
									}}
									outlet={outlet}
									loginMsgStatus={loginMsgStatus}
									customer={customer}
									onNewAccount={() => openModal({ open: modals.LOGIN })}
								/>
							)
						}
						appendChildren={
							isOnline &&
							outlet.settings.termsAndConditions && (
								<TermsAndConditions
									setTermsAndConditions={setTermsAndConditions}
									isOnline={isOnline}
									outlet={outlet}
									termsAndConditions={termsAndConditions}
								/>
							)
						}
						footer={isOnline && _Footer}>
						<Payment
							data={data}
							onPromoCodeRemove={onPromoCodeRemove}
							openModal={openModal}
							isOnline={isOnline}
							setPayStatus={setPayStatus}
							setSelectedPaymentMethod={setSelectedPaymentMethod}
							isSubmitted={isSubmitted}
							selectedPaymentMethod={selectedPaymentMethod}
							payment={payment}
							isCheckingPromoCode={isCheckingPromoCode}
							onPromoCodeApply={onPromoCodeApply}
							outlet={outlet}
							setPromoCode={setPromoCode}
							setPayment={setPayment}
							customer={customer}
							perUnitPromoCodeAmount={perUnitPromoCodeAmount}
							appliedPromoCode={appliedPromoCode.current}
							reservationItems={reservationItems}
							promoCode={promoCode}
							serviceFeeAmount={serviceFeeAmount}
							needPayment={getTotals(data, reservationItems).totalDueToday > 0}
						/>
					</WizardTop.Child>
				</WizardTop>
				<CustomerModal
					isOpen={modal.open === modals.CUSTOMER}
					defaultCustomer={customer}
					setSelectedCustomer={_customer => {
						setCustomer(_customer);
						closeModal();
					}}
					onClose={closeModal}
					hasRegisterForm
					paymentTerms={paymentTerms}
					countries={countries}
					defaultCountry={outlet.country}
					states={states}
					enableHouseAccount={enableHouseAccount}
					saveCreditCards={saveCreditCards}
					passwordSettings={passwordSettings}
					filterIsActive
				/>
				<LoginModal
					isOpen={modal.open === modals.LOGIN}
					onLogin={_customer => {
						onCustomerChange(_customer);
						closeModal();
					}}
					onRegister={_customer => {
						onCustomerChange(_customer);
						closeModal();
					}}
					onClose={closeModal}
					countries={countries}
					defaultCountry={outlet.country}
					states={states}
					outlet={outlet}
					passwordSettings={passwordSettings}
					onlySignUp
				/>
				<ConfirmationModal
					reservationItems={reservationItems.current}
					serviceFeeAmount={serviceFeeAmount}
					onClose={() => {
						reservationItems.current = [];
						reset(true);
						closeModal();
					}}
					isOpen={modal.open === modals.CONFIRMATION}
					isOnline={isOnline}
					reservationCustomId={reservationCustomId}
				/>
				<SimpleOnlineConfirmationModal
					isOpen={modal.open === modals.SIMPLE_ONLINE_CONFIRMATION}
					onClose={() => {
						reservationItems.current = [];
						reset(true);
						closeModal();
					}}
				/>
				<CancelDialog
					isOpen={modal.open === modals.CANCEL}
					onClose={closeModal}
					onCancel={() => onReservationCancel()}
				/>
				<ContinueDialog
					isOpen={modal.open === modals.CONTINUE}
					onCancel={() => onReservationCancel()}
					onContinue={onContinue}
				/>
				<DeleteDialog
					isOpen={modal.open === modals.DELETE}
					onDelete={onReservationItemDelete}
					onClose={closeModal}
				/>
				<SignOutDialog
					onClose={closeModal}
					isOpen={modal.open === modals.SIGN_OUT}
					onSignOut={() => onReservationCancel(true)}
				/>
			</ContentInner.Container>
			{!isOnline && _Footer}
		</>
	);
};
ReservationForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		extraCharges: PropTypes.arrayOf(PropTypes.object),
	}),
	onFormChange: PropTypes.func,
	bookingTypes: PropTypes.arrayOf(PropTypes.object),
	isOnline: PropTypes.bool,
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	countries: PropTypes.arrayOf(PropTypes.object),
	states: PropTypes.arrayOf(PropTypes.object),
	paymentMethods: PropTypes.arrayOf(PropTypes.object),
	outlet: PropTypes.shape({
		id: PropTypes.number,
		taxRate: PropTypes.object,
		settings: PropTypes.shape({
			paymentGateway: PropTypes.object,
			taxRate: PropTypes.object,
			onlineSiteToken: PropTypes.string,
			defaultCustomer: PropTypes.object,
			termsAndConditions: PropTypes.string,
			checkoutTotalText: PropTypes.string,
			simpleOnlineConfirmation: PropTypes.bool,
		}),
		country: PropTypes.object,
		paymentProcessors: PropTypes.arrayOf(PropTypes.object),
	}).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	headerContext: PropTypes.object,
	overrideConstraints: PropTypes.bool,
	enableHouseAccount: PropTypes.bool,
	saveCreditCards: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	history: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	initBookingType: PropTypes.object,
	initFromDate: PropTypes.string,
	initToDate: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	initAdults: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	initChildren: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	passwordSettings: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	initProducts: PropTypes.array,
	enumRvTypes: PropTypes.arrayOf(PropTypes.object),
	enumVehicleMakes: PropTypes.arrayOf(PropTypes.object),
	enumBoatTypes: PropTypes.arrayOf(PropTypes.object),
	enumBoatMakes: PropTypes.arrayOf(PropTypes.object),
	customOnBack: PropTypes.func,
	initLoa: PropTypes.string,
	initBeam: PropTypes.string,
	initHeight: PropTypes.string,
	initDraft: PropTypes.string,
	initWeight: PropTypes.string,
};

ReservationForm.defaultProps = {
	data: {
		id: 0,
		extraCharges: [],
	},
	bookingTypes: [],
	onFormChange: () => {},
	isOnline: false,
	paymentTerms: [],
	countries: [],
	states: [],
	paymentMethods: [],
	overrideConstraints: false,
	enableHouseAccount: false,
	saveCreditCards: false,
	headerContext: null,
	history: null,
	initBookingType: null,
	initFromDate: null,
	initToDate: null,
	initAdults: null,
	initChildren: null,
	passwordSettings: null,
	initProducts: [],
	enumRvTypes: [],
	enumVehicleMakes: [],
	enumBoatTypes: [],
	enumBoatMakes: [],
	customOnBack: () => {},
	initLoa: null,
	initBeam: null,
	initHeight: null,
	initDraft: null,
	initWeight: null,
};

export default ReservationForm;
