import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { reservationColor } from './ReservationStatusCell';
import { bookingPeriods, reservationStatuses } from '../../../utils/constants/constants';
import Dropdown from './Dropdown';
import UserContext from '../../../app/contexts/UserContext';
import {
	getNextAndPrevReservationItemStatus,
	reservationItemStatusIcon,
} from '../../../utils/helpers/reservationHelper';
import {
	hasCancelReservationPermission,
	hasChangeReservationStatusPermission,
	hasCheckInReservationPermission,
	hasCheckOutReservationPermission,
	hasRefundReservationPaymentPermission,
} from '../../../utils/helpers/permission';

const ReservationStatusDropdown = ({
	reservationItem,
	status,
	isRefundable,
	onEdit,
	onConfirm,
	onReserve,
	onCheckIn,
	onCheckOut,
	onCancel,
	onRefund,
	onItemUpdateCustomStatus,
	customReservationStatus,
	productCustomReservationStatuses,
	onWaitList,
	onDepartureDateSet,
}) => {
	const userContext = useContext(UserContext);

	const userHasChangeStatusPermission = hasChangeReservationStatusPermission(userContext);

	const { prevStatus, nextStatus } = getNextAndPrevReservationItemStatus(
		status,
		customReservationStatus,
		productCustomReservationStatuses
	);

	const displayItem = _status =>
		((prevStatus && prevStatus.status && prevStatus.status === _status) ||
			(nextStatus && nextStatus.status && nextStatus.status === _status)) &&
		userHasChangeStatusPermission;

	return (
		<>
			{status.value !== reservationStatuses.CANCELLED && onEdit && (
				<Dropdown.Item icon='Edit' onClick={onEdit}>
					Edit
				</Dropdown.Item>
			)}

			{prevStatus && prevStatus.name && onItemUpdateCustomStatus && (
				<Dropdown.Item
					itemsColor={reservationColor(prevStatus.status.value)}
					icon={reservationItemStatusIcon(prevStatus.status.value)}
					onClick={() => onItemUpdateCustomStatus(prevStatus.id)}>
					{prevStatus.name}
				</Dropdown.Item>
			)}

			{displayItem(reservationStatuses.WAITLIST) && onWaitList && (
				<Dropdown.Item
					itemsColor={reservationColor(reservationStatuses.WAITLIST)}
					icon={reservationItemStatusIcon(reservationStatuses.WAITLIST)}
					onClick={onWaitList}>
					Waitlist
				</Dropdown.Item>
			)}

			{displayItem(reservationStatuses.RESERVED) && onReserve && (
				<Dropdown.Item
					itemsColor={reservationColor(reservationStatuses.RESERVED)}
					icon={reservationItemStatusIcon(reservationStatuses.RESERVED)}
					onClick={onReserve}>
					Reserve
				</Dropdown.Item>
			)}

			{displayItem(reservationStatuses.CONFIRMED) && onConfirm && (
				<Dropdown.Item
					itemsColor={reservationColor(reservationStatuses.CONFIRMED)}
					icon={reservationItemStatusIcon(reservationStatuses.CONFIRMED)}
					onClick={onConfirm}>
					Confirm
				</Dropdown.Item>
			)}

			{displayItem(reservationStatuses.CHECKED_IN) && onCheckIn && (
				<Dropdown.Item
					itemsColor={reservationColor(reservationStatuses.CHECKED_IN)}
					icon={reservationItemStatusIcon(reservationStatuses.CHECKED_IN)}
					onClick={onCheckIn}
					noPermission={!hasCheckInReservationPermission(userContext)}>
					Check-In
				</Dropdown.Item>
			)}

			{displayItem(reservationStatuses.CHECKED_OUT) && onCheckOut && (
				<Dropdown.Item
					itemsColor={reservationColor(reservationStatuses.CHECKED_OUT)}
					icon={reservationItemStatusIcon(reservationStatuses.CHECKED_OUT)}
					onClick={onCheckOut}
					noPermission={!hasCheckOutReservationPermission(userContext)}>
					Check-Out
				</Dropdown.Item>
			)}

			{reservationItem &&
				(reservationItem.product.bookingPeriod.value === bookingPeriods.SEASONAL ||
					reservationItem.product.bookingPeriod.value === bookingPeriods.LONG_TERM) &&
				reservationItem.status.value === reservationStatuses.CHECKED_IN &&
				onDepartureDateSet && (
					<Dropdown.Item
						itemsColor={reservationColor(reservationStatuses.CHECKED_OUT)}
						icon={reservationItemStatusIcon(reservationStatuses.CHECKED_OUT)}
						onClick={onDepartureDateSet}>
						Set Departure Date
					</Dropdown.Item>
				)}

			{nextStatus && nextStatus.name && onItemUpdateCustomStatus && (
				<Dropdown.Item
					itemsColor={reservationColor(nextStatus.status.value)}
					icon={reservationItemStatusIcon(nextStatus.status.value)}
					onClick={() => onItemUpdateCustomStatus(nextStatus.id)}>
					{nextStatus.name}
				</Dropdown.Item>
			)}

			{status.value !== reservationStatuses.CANCELLED && onCancel && (
				<Dropdown.Item
					itemsColor={reservationColor(reservationStatuses.CANCELLED)}
					icon={reservationItemStatusIcon(reservationStatuses.CANCELLED)}
					onClick={onCancel}
					noPermission={!hasCancelReservationPermission(userContext)}>
					Cancel
				</Dropdown.Item>
			)}

			{status.value === reservationStatuses.CANCELLED && isRefundable && onRefund && (
				<Dropdown.Item
					itemsColor={reservationColor(reservationStatuses.CANCELLED)}
					icon={reservationItemStatusIcon(reservationStatuses.CANCELLED)}
					onClick={onRefund}
					noPermission={!hasRefundReservationPaymentPermission(userContext)}>
					Refund
				</Dropdown.Item>
			)}
		</>
	);
};
ReservationStatusDropdown.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	status: PropTypes.object,
	isRefundable: PropTypes.bool,
	onEdit: PropTypes.func,
	onCheckIn: PropTypes.func,
	onCheckOut: PropTypes.func,
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func,
	onReserve: PropTypes.func,
	onRefund: PropTypes.func,
	onWaitList: PropTypes.func,
	onItemUpdateCustomStatus: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	customReservationStatus: PropTypes.object,
	productCustomReservationStatuses: PropTypes.arrayOf(PropTypes.object),
	onDepartureDateSet: PropTypes.func,
};
ReservationStatusDropdown.defaultProps = {
	reservationItem: null,
	status: {
		id: 0,
		value: reservationStatuses.RESERVED,
	},
	isRefundable: false,
	onEdit: null,
	onCheckIn: () => {},
	onCheckOut: () => {},
	onCancel: () => {},
	onConfirm: () => {},
	onReserve: () => {},
	onRefund: () => {},
	onWaitList: () => {},
	onItemUpdateCustomStatus: () => {},
	customReservationStatus: null,
	productCustomReservationStatuses: [],
	onDepartureDateSet: () => {},
};

export default ReservationStatusDropdown;
