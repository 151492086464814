import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import useField from '../../../utils/hooks/useField';
import usePages from '../../../utils/hooks/usePages';

import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import Portal from '../layout/Portal';
import Selects from '../field/Selects';
import FormField from '../template/FormField';

const PaymentScheduleSelectModal = ({
	selectedPaymentSchedule,
	paymentSchedules,
	onSubmit,
	onClose,
	isOpen,
	isSubmitting,
}) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<PaymentScheduleSelectModalContent
					selectedPaymentSchedule={selectedPaymentSchedule}
					paymentSchedules={paymentSchedules}
					onClose={onClose}
					onSubmit={onSubmit}
					isSubmitting={isSubmitting}
				/>
			</Popup>
		</Portal>
	);
};
PaymentScheduleSelectModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	selectedPaymentSchedule: PropTypes.object,
	paymentSchedules: PropTypes.arrayOf(PropTypes.object),
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	isOpen: PropTypes.bool,
	isSubmitting: PropTypes.bool,
};
PaymentScheduleSelectModal.defaultProps = {
	selectedPaymentSchedule: null,
	paymentSchedules: [],
	onSubmit: () => {},
	onClose: () => {},
	isOpen: false,
	isSubmitting: false,
};

const PaymentScheduleSelectModalContent = ({
	selectedPaymentSchedule,
	paymentSchedules,
	onSubmit,
	onClose,
	isSubmitting,
}) => {
	const pages = usePages();

	const [paymentSchedule, paymentScheduleOnChange] = useField(
		{},
		'printer',
		() => {},
		[],
		selectedPaymentSchedule
	);

	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle
					portletIcon={pages.accounting.settings.paymentSchedules.icon}>
					Payment Schedule
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<FormField name='paymentSchedule' id={0} inFormDesign={false}>
					<Selects
						value={paymentSchedule}
						options={paymentSchedules}
						onChange={paymentScheduleOnChange}
						placeholder='Select a Payment Schedule'
					/>
				</FormField>
			</Portlet.Body>
			<Portlet.Foot
				tall='sm'
				className='sdms-align-left'
				subClassName='justify-content-between'>
				<div className='col-auto'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
						disabled={isSubmitting}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='brand'
						icon='Save'
						text={isSubmitting ? 'Saving' : 'Save'}
						size='sm'
						onClick={() => onSubmit(paymentSchedule)}
						disabled={isSubmitting}
						isSubmitting={isSubmitting}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
PaymentScheduleSelectModalContent.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	selectedPaymentSchedule: PropTypes.object,
	paymentSchedules: PropTypes.arrayOf(PropTypes.object).isRequired,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	isSubmitting: PropTypes.bool,
};

PaymentScheduleSelectModalContent.defaultProps = {
	selectedPaymentSchedule: null,
	isSubmitting: false,
};

export default PaymentScheduleSelectModal;
