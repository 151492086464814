import {
	achPaymentStatues,
	creditCardPaymentStatuses,
	paymentStatuses,
	paymentTypes,
} from '../constants/constants';
import { isBeforeAccountingClosedPeriod, numberFormat } from './helper';

export const isDepositPayment = payment =>
	(payment?.customerSettlements || []).some(cs => cs.isDeposit || cs.isSecurityDeposit);

export const canVoidPayment = payment => {
	if (
		(payment?.status?.value !== paymentStatuses.SETTLED &&
			payment?.status?.value !== paymentStatuses.UNSETTLED &&
			payment?.status?.value !== paymentStatuses.SCHEDULED) ||
		isDepositPayment(payment)
	)
		return false;

	if (
		payment.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD &&
		(!payment.creditCardPaymentStatus ||
			payment?.creditCardPaymentStatus?.value === creditCardPaymentStatuses.CAPTURED)
	)
		return false;

	if (
		payment.paymentMethod.paymentType.value === paymentTypes.CHECK &&
		payment.bankName &&
		(!payment.achPaymentStatus ||
			payment?.achPaymentStatus?.value !== achPaymentStatues.PROCESSING)
	)
		return false;

	if (getPaymentRefundableAmount(payment) < payment.amount)
		return payment?.status?.value === paymentStatuses.SCHEDULED;

	return true;
};

export const canRefundPayment = payment => {
	if (
		payment.status.value !== paymentStatuses.SETTLED &&
		payment.status.value !== paymentStatuses.UNSETTLED
	)
		return false;

	if (
		payment.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD &&
		(!payment.creditCardPaymentStatus ||
			payment?.creditCardPaymentStatus?.value !== creditCardPaymentStatuses.CAPTURED)
	)
		return false;

	if (
		payment.paymentMethod.paymentType.value === paymentTypes.CHECK &&
		payment.bankName &&
		(!payment.achPaymentStatus ||
			payment?.achPaymentStatus?.value !== achPaymentStatues.CAPTURED)
	)
		return false;

	return getPaymentRefundableAmount(payment) > 0;
};

export const getPaymentRefundableAmount = payment => {
	let refundableAmount = 0;

	if (
		payment.status.value === paymentStatuses.SETTLED ||
		payment.status.value === paymentStatuses.UNSETTLED
	) {
		refundableAmount = payment.amount;

		payment.customerSettlements.forEach(cs => {
			if (cs.refund || cs.isRetain || (cs.invoice && cs.invoice.isSalesReceipt))
				refundableAmount -= cs.amount;
		});
	}

	return numberFormat(refundableAmount);
};

export const canBounceCheck = payment =>
	payment?.paymentMethod?.paymentType?.value === paymentTypes.CHECK &&
	!payment.bouncedCheckJournal &&
	payment?.status?.value !== paymentStatuses.VOIDED &&
	payment?.status?.value !== paymentStatuses.DECLINED;

export const getPaymentRefundedAmount = payment => {
	let refundedAmount = 0;

	payment.customerSettlements.forEach(cs => {
		if (cs.refund) refundedAmount += cs.amount;
	});

	return numberFormat(refundedAmount);
};

export const validatePaymentFields = (type, payment) => {
	if (
		type === paymentTypes.CREDIT_CARD &&
		!payment.creditCard &&
		(payment.cardNumber === '' ||
			payment.cardName === '' ||
			payment.expiry === '' ||
			payment.cvc === '')
	)
		return false;

	if (
		type === paymentTypes.CHECK &&
		!payment.achAccount &&
		(payment.bankName === '' ||
			payment.accountName === '' ||
			payment.routingNumber === '' ||
			payment.accountNumber === '')
	)
		return false;

	return true;
};

export const canApplyToInvoice = (payment, userContext) =>
	!isDepositPayment(payment) &&
	isBeforeAccountingClosedPeriod(payment.remittanceDate || payment.timeCreated, userContext) &&
	payment?.status?.value === paymentStatuses.UNSETTLED;
