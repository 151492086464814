import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useField from '../../../utils/hooks/useField';
import { maxLength, percentage, required, uniqueFnc } from '../../../utils/helpers/validation';
import {
	paymentProcessorGatewayPresetConfigurations,
	paymentTypes,
} from '../../../utils/constants/constants';
import { numberParser } from '../../../utils/helpers/helper';
import usePages from '../../../utils/hooks/usePages';
import HeaderContext from '../../../app/contexts/HeaderContext';

import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Loading from '../../reusables/template/Loading';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import Toggle from '../../reusables/field/Toggle';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import MultiSelect from '../../reusables/element/MultiSelect';
import Json from '../../reusables/field/Json';
import UserContext from '../../../app/contexts/UserContext';
import Wysiwyg from '../../reusables/field/Wysiwyg';

const PaymentProcessorForm = ({
	data,
	setIsValid,
	isLoading,
	isSubmitted,
	onFormChange,
	submit,
	submitButtonAttr,
	enumPaymentTypes,
	paymentGateways,
	enumCustomerPaymentForms,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[
			required,
			maxLength(),
			uniqueFnc(
				'paymentProcessors',
				'name',
				data.id,
				true,
				null,
				null,
				null,
				null,
				userContext.data.selectedOutlet.id
			),
		]
	);

	const [
		paymentType,
		paymentTypeOnChange,
		paymentTypeValRes,
		paymentTypeShowVal,
		setPaymentTypeShowVal,
	] = useField(data, 'paymentType', onFormChange, [required], null);

	const [enabled, enabledOnChange] = useField(data, 'enabled', onFormChange, [], false);

	const [testMode, testModeOnChange] = useField(data, 'testMode', onFormChange, [], false);

	const [gateway, gatewayOnChange, gatewayValRes, gatewayShowVal, setGatewayShowVal] = useField(
		data,
		'gateway',
		onFormChange,
		[required],
		null
	);

	const [configurationString, configurationStringOnChange] = useField(
		data,
		'configurationString',
		onFormChange
	);

	const [serviceFeeItem, serviceFeeItemOnChange] = useField(
		data,
		'serviceFeeItem',
		onFormChange,
		[],
		null
	);

	const [bookingServiceFeeItem, bookingServiceFeeItemOnChange] = useField(
		data,
		'bookingServiceFeeItem',
		onFormChange,
		[],
		null
	);

	const [marinaServiceFeeItem, marinaServiceFeeItemOnChange] = useField(
		data,
		'marinaServiceFeeItem',
		onFormChange,
		[],
		null
	);

	const [campgroundServiceFeeItem, campgroundServiceFeeItemOnChange] = useField(
		data,
		'campgroundServiceFeeItem',
		onFormChange,
		[],
		null
	);

	const [
		serviceFeeAmount,
		serviceFeeAmountOnChange,
		serviceFeeAmountValRes,
		serviceFeeAmountShowVal,
		setServiceFeeAmountShowVal,
	] = useField(data, 'serviceFeeAmount', onFormChange, [percentage], 0, numberParser(true));

	const [clientSort, clientSortOnChange] = useField(
		data,
		'clientSort',
		onFormChange,
		[],
		0,
		numberParser()
	);

	const [customerPaymentForms, customerPaymentFormsOnChange] = useField(
		data,
		'customerPaymentForms',
		onFormChange,
		[],
		[]
	);

	const [termsOfService, termsOfServiceOnChange] = useField(data, 'termsOfService', onFormChange);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setPaymentTypeShowVal();
			setGatewayShowVal();
			setServiceFeeAmountShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setPaymentTypeShowVal,
		setGatewayShowVal,
		setServiceFeeAmountShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				paymentTypeValRes.isValid &&
				gatewayValRes.isValid &&
				serviceFeeAmountValRes.isValid
		);
	}, [
		setIsValid,
		nameValRes.isValid,
		paymentTypeValRes.isValid,
		gatewayValRes.isValid,
		serviceFeeAmountValRes.isValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.companySettings.default.text,
				path: pages.companySettings.dashboard.path,
			},
			{
				title: pages.companySettings.paymentProcessors.text,
				path: pages.companySettings.paymentProcessors.path,
			},
			{
				title: name || `New ${pages.companySettings.paymentProcessors.text}`,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(name || `New ${pages.companySettings.paymentProcessors.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid' hasFrame>
			<Portlet.Body>
				<FormGroup>
					<Loading isLoading={isLoading}>
						<FormField
							name='name'
							label='Name'
							id={data.id}
							valRes={nameValRes}
							showValidation={nameShowVal}
							loadingContainer
							colLg={6}>
							<Input
								type='text'
								placeholder='Name (Required)'
								value={name}
								onChange={nameOnChange}
								onBlur={setNameShowVal}
							/>
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField
							name='paymentType'
							label='Payment Type'
							id={data.id}
							valRes={paymentTypeValRes}
							showValidation={paymentTypeShowVal}
							colLg={6}>
							<Selects
								options={enumPaymentTypes.filter(
									ept =>
										ept.value === paymentTypes.CREDIT_CARD ||
										ept.value === paymentTypes.CHECK
								)}
								placeholder='Payment Type (Required)'
								value={paymentType}
								onChange={e => {
									paymentTypeOnChange(e);

									if (
										!e.target.value ||
										(gateway && gateway.paymentType.id !== e.target.value.id)
									)
										gatewayOnChange({ target: { value: null } });
								}}
								onBlur={setPaymentTypeShowVal}
								displayKey='value'
							/>
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField name='enabled' label='Enabled' id={data.id} colLg={1}>
							<Toggle value={enabled} onChange={enabledOnChange} />
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField name='testMode' label='Test Mode' id={data.id} colLg={1}>
							<Toggle value={testMode} onChange={testModeOnChange} />
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField
							name='gateway'
							label='Gateway'
							id={data.id}
							valRes={gatewayValRes}
							showValidation={gatewayShowVal}
							description='Select Payment Type to enable it.'
							colLg={3}>
							<Selects
								options={
									paymentType
										? paymentGateways.filter(
												pg => pg.paymentType.id === paymentType.id
										  )
										: []
								}
								placeholder='Gateway (Required)'
								value={gateway}
								onChange={gatewayOnChange}
								onBlur={setGatewayShowVal}
								displayKey='value'
								disabled={!paymentType}
							/>
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField
							name='configuration'
							label='Configuration String'
							id={data.id}
							col={6}>
							<Json
								value={configurationString}
								onChange={value => {
									configurationStringOnChange({ target: { value } });
								}}
								title='Configuration String'
								placeholder='Configuration String'
								presetConfigurations={paymentProcessorGatewayPresetConfigurations}
							/>
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField
							name='serviceFeeItem'
							label='Default service Fee Item'
							id={data.id}
							colLg={3}>
							<AsyncSelect
								options={data.serviceFeeItem ? [data.serviceFeeItem] : []}
								placeholder='Search and select item'
								value={serviceFeeItem}
								onChange={serviceFeeItemOnChange}
								route='products'
							/>
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField
							name='bookingServiceFeeItem'
							label='Booking service Fee Item'
							id={data.id}
							colLg={3}>
							<AsyncSelect
								options={
									data.bookingServiceFeeItem ? [data.bookingServiceFeeItem] : []
								}
								placeholder='Search and select item'
								value={bookingServiceFeeItem}
								onChange={bookingServiceFeeItemOnChange}
								route='products'
							/>
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField
							name='marinaServiceFeeItem'
							label='Marina service Fee Item'
							id={data.id}
							colLg={3}>
							<AsyncSelect
								options={
									data.marinaServiceFeeItem ? [data.marinaServiceFeeItem] : []
								}
								placeholder='Search and select item'
								value={marinaServiceFeeItem}
								onChange={marinaServiceFeeItemOnChange}
								route='products'
							/>
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField
							name='campgroundServiceFeeItem'
							label='Campground service Fee Item'
							id={data.id}
							colLg={3}>
							<AsyncSelect
								options={
									data.campgroundServiceFeeItem
										? [data.campgroundServiceFeeItem]
										: []
								}
								placeholder='Search and select item'
								value={campgroundServiceFeeItem}
								onChange={campgroundServiceFeeItemOnChange}
								route='products'
							/>
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField
							name='serviceFeeAmount'
							label='Service Fee Amount'
							id={data.id}
							colLg={2}
							showValidation={serviceFeeAmountShowVal}
							valRes={serviceFeeAmountValRes}>
							<Input
								onChange={serviceFeeAmountOnChange}
								value={serviceFeeAmount}
								pattern={process.env.REACT_APP_PRICE_PATTERN}
								onBlur={setServiceFeeAmountShowVal}
							/>
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField name='clientSort' label='Client Sort' id={data.id} colLg={2}>
							<Input
								onChange={clientSortOnChange}
								value={clientSort}
								pattern={process.env.REACT_APP_INTEGER_PATTERN}
							/>
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField
							name='customerPaymentForms'
							label='Client Enabled Payments'
							id={data.id}
							col={12}>
							<MultiSelect
								data={enumCustomerPaymentForms}
								titleProp='value'
								value={customerPaymentForms}
								onChange={customerPaymentFormsOnChange}
								name='customerPaymentForms'
								withoutSelectAll
								withoutSeparator
							/>
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField
							name='termsOfService'
							label='Terms of Service'
							id={data.id}
							col={12}>
							<Wysiwyg value={termsOfService} onChange={termsOfServiceOnChange} />
						</FormField>
					</Loading>
				</FormGroup>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};

PaymentProcessorForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		paymentType: PropTypes.object,
		enabled: PropTypes.bool,
		testMode: PropTypes.bool,
		gateway: PropTypes.object,
		clientId: PropTypes.string,
		clientToken: PropTypes.string,
		serviceFeeItem: PropTypes.object,
		serviceFeeAmount: PropTypes.number,
		clientSort: PropTypes.number,
		customerPaymentForms: PropTypes.arrayOf(PropTypes.object),
		bookingServiceFeeItem: PropTypes.object,
		marinaServiceFeeItem: PropTypes.object,
		campgroundServiceFeeItem: PropTypes.object,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submit: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	enumPaymentTypes: PropTypes.arrayOf(PropTypes.object),
	paymentGateways: PropTypes.arrayOf(PropTypes.object),
	enumCustomerPaymentForms: PropTypes.arrayOf(PropTypes.object),
};
PaymentProcessorForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		paymentType: null,
		enabled: false,
		testMode: false,
		gateway: null,
		clientId: '',
		clientToken: '',
		serviceFeeItem: null,
		serviceFeeAmount: 0,
		clientSort: 0,
		customerPaymentForms: [],
		bookingServiceFeeItem: null,
		marinaServiceFeeItem: null,
		campgroundServiceFeeItem: null,
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	submit: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	enumPaymentTypes: [],
	paymentGateways: [],
	enumCustomerPaymentForms: [],
};

export default PaymentProcessorForm;
