import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePrint from '../../../utils/hooks/usePrint';
import useModal from '../../../utils/hooks/useModal';
import {
	addErrorNotification,
	addSuccessNotification,
	isBeforeAccountingClosedPeriod,
} from '../../../utils/helpers/helper';
import {
	filterComponents,
	paymentStatuses,
	SELECTED_PRINTER_STORAGE_KEY,
	serviceFeeModules,
} from '../../../utils/constants/constants';
import usePages from '../../../utils/hooks/usePages';
import apiCall from '../../../utils/helpers/apiCall';
import {
	canVoidPayment,
	canRefundPayment,
	getPaymentRefundableAmount,
	canBounceCheck,
	canApplyToInvoice,
} from '../../../utils/helpers/paymentHelper';
// eslint-disable-next-line import/namespace,import/default,import/no-named-as-default,import/no-named-as-default-member
import WebPrint from '../../reusables/print/WebPrint';
import {
	getDefaultCreditCard,
	getDefaultPaymentMethod,
	getPaymentTypes,
} from '../../../utils/helpers/reservationHelper';
import forms from '../../forms';

import List from '../../reusables/template/List';
import Dropdown from '../../reusables/element/Dropdown';
import ListContainer from '../../reusables/template/ListContainer';
import Button from '../../reusables/element/Button';
import PrinterModal from '../../reusables/modals/PrinterModal';
import PayModal from '../../reusables/modals/PayModal';
import DialogBox from '../../reusables/element/DialogBox';
import {
	CustomerCell,
	PaymentAmountCell,
	PaymentDateCell,
	PaymentTypeCell,
	PaymentStatusCell,
	SettledCell,
	BalanceCell,
} from '../../reusables/element/PaymentListCells';
import ApplyInvoiceModal from '../../reusables/modals/ApplyInvoiceModal';

const modals = {
	PRINTER: 'printer',
	REFUND: 'refund',
	VOID: 'void',
	CAPTURE: 'capture',
	BOUNCE_CHECK: 'bounceCheck',
	APPLY_INVOICE: 'apply_invoice',
};

const PaymentList = ({ history }) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const webPrint = useRef({
		deposit: () => {},
		reservationRefund: () => {},
		reservationPayment: () => {},
		printers: [],
	});

	const [modal, openModal, closeModal] = useModal();

	const [isLoading, setIsLoading] = useState(false);

	const [paymentTypes, setPaymentTypes] = useState([]);

	const [onPrintPdf, PRINT_COMPONENT] = usePrint();

	const onPrint = (payment, printerId = null) => {
		const _printerId = printerId || localStorage.getItem(SELECTED_PRINTER_STORAGE_KEY);
		if (
			_printerId &&
			webPrint.current.printers.findIndex(p => p.id.toString() === _printerId.toString()) > -1
		) {
			webPrint.current.paymentPrint(payment, _printerId);
		} else openModal({ open: modals.PRINTER, payment });
	};

	const onVoid = () => {
		setIsLoading(true);
		apiCall(
			'POST',
			'voidPayment',
			res => {
				addSuccessNotification('Payment successfully voided.');

				if (modal.updateData) modal.updateData([res]);
				closeModal();
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ payment: modal.payment, outletId: userContext.data.selectedOutlet.id }
		);
	};

	const onRefund = (payment, refund) => {
		setIsLoading(true);

		apiCall(
			'POST',
			'refundPayment',
			res => {
				if (modal.updateData && res.payment) modal.updateData([res.payment]);
				closeModal();
				setIsLoading(false);
			},
			e => {
				addErrorNotification(e.toString());
				setIsLoading(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				payment,
				refund,
			}
		);
	};

	const onSendEmail = paymentIds => {
		setIsLoading(true);
		apiCall(
			'POST',
			'sendPaymentEmail',
			() => {
				addSuccessNotification('Email(s) successfully sent');
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				paymentIds,
			}
		);
	};

	const onPrintReceipt = paymentIds => {
		setIsLoading(true);
		apiCall(
			'POST',
			'getPrintPaymentContent',
			res => {
				onPrintPdf(res.payments.join('<p style="page-break-before: always">'));
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ outletId: userContext.data.selectedOutlet.id, paymentIds }
		);
	};

	const onCapture = () => {
		setIsLoading(true);
		apiCall(
			'POST',
			'captureSchedulePayment',
			res => {
				if (res.success) addSuccessNotification('Payment successfully captured.');
				else addErrorNotification(res.message);
				if (modal.updateData) modal.updateData([res.payment]);
				closeModal();
				setIsLoading(false);
			},
			err => {
				closeModal();
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ payment: modal.payment, outletId: userContext.data.selectedOutlet.id }
		);
	};

	const onBounce = () => {
		setIsLoading(true);

		apiCall(
			'POST',
			'bounceCheck',
			res => {
				addSuccessNotification('Check successfully bounced.');
				if (modal.updateData) modal.updateData([res]);
				closeModal();
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
				closeModal();
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				payment: modal.payment,
			}
		);
	};

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.crm.default.text, path: pages.crm.dashboard.path },
			{ title: pages.crm.payments.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.crm.payments.text);

		webPrint.current = new WebPrint({
			user: userContext.data.user,
			outlet: userContext.data.selectedOutlet,
			onStart: () => addSuccessNotification('Printing...'),
			onFinish: () => {},
			onFail: (printItem, err) => addErrorNotification(err.toString()),
		});

		apiCall(
			'GET',
			'paymentTypes',
			res => setPaymentTypes(res),
			err => {
				addErrorNotification(err.toString());
			},
			'',
			null,
			{ pagination: false }
		);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ListContainer
				route='payments'
				title={pages.crm.payments.text}
				history={history}
				icon={pages.crm.payments.icon}
				customActions={{
					void: (payment, updateData) =>
						openModal({
							open: modals.VOID,
							payment,
							updateData,
						}),
					print: payment => onPrint(payment),
					sendEmail: payment => onSendEmail([payment.id]),
					printReceipt: payment => onPrintReceipt([payment.id]),
					refund: (payment, updateData) =>
						openModal({
							open: modals.REFUND,
							payment,
							updateData,
						}),
					captureScheduledPayment: (payment, updateData) =>
						openModal({
							open: modals.CAPTURE,
							payment,
							updateData,
						}),
					bounceCheck: (payment, updateData) =>
						openModal({
							open: modals.BOUNCE_CHECK,
							payment,
							updateData,
						}),
					applyToInvoices: (payment, updateData) =>
						openModal({ open: modals.APPLY_INVOICE, payment, updateData }),
				}}
				checkEditable={() => []}
				customFilters={[
					{
						component: filterComponents.ASYNC_SELECTS,
						dataName: 'customers',
						searchField: 'displayName',
						fieldName: 'customer.id',
						label: 'Customer',
						placeholder: 'Search a Customer',
						displayKey: 'displayName',
						default: [],
						multiple: true,
					},
					{
						component: filterComponents.SELECTS,
						dataName: 'enumRemittanceStatuses',
						fieldName: 'status.id',
						label: 'Status',
						placeholder: 'Status',
						displayKey: 'value',
						default: [],
						multiple: true,
					},
					{
						component: filterComponents.DATE_RANGE,
						fieldName: 'remittanceDate',
						label: 'Date',
						placeholder: 'Select Date Range',
						displayKey: 'name',
						default: null,
					},
				]}
				forms={forms.crm.payments}>
				<List
					withCheckBox
					fluid='fluid'
					checkEditable={payment => {
						if (!payment.paymentMethod) return [];

						const actions = ['print', 'edit'];

						if (
							payment.status.value === paymentStatuses.UNSETTLED ||
							payment.status.value === paymentStatuses.SETTLED
						) {
							actions.push('sendEmail', 'printReceipt');
						}

						const isClosed = isBeforeAccountingClosedPeriod(
							payment.remittanceDate,
							userContext
						);

						const isScheduled = payment.status.value === paymentStatuses.SCHEDULED;

						if (!isClosed) {
							if (canVoidPayment(payment)) actions.push('void');

							if (canRefundPayment(payment)) actions.push('refund');
						}

						if (isScheduled && payment.token) actions.push('captureScheduledPayment');

						if (canBounceCheck(payment)) actions.push('bounceCheck');

						if (canApplyToInvoice(payment, userContext))
							actions.push('applyToInvoices');

						return actions;
					}}>
					<List.Col
						label='Payment'
						cellData='remittanceId'
						isLinkColumn
						sortable='remittanceId'
					/>
					<List.Col
						label='Customer'
						isLinkColumn
						cellComponent={<CustomerCell />}
						sortable='customer.displayName'
					/>
					<List.Col
						label='Date'
						cellComponent={<PaymentDateCell />}
						sortable='remittanceDate'
					/>
					<List.Col label='Type' cellComponent={<PaymentTypeCell />} />
					<List.Col
						label='Amount'
						cellComponent={<PaymentAmountCell />}
						sortable='amount'
					/>
					<List.Col label='Reference' cellData='reference' />
					<List.Col label='Settled' cellComponent={<SettledCell />} />
					<List.Col label='Balance' cellComponent={<BalanceCell />} />
					<List.Col label='Status' cellComponent={<PaymentStatusCell />} />
					<List.Col align='right' onlyHover width={150}>
						<Button
							className='sdms-margin-r-15'
							design='link'
							text='Edit'
							icon='Edit'
							size='sm'
							elevate
							key='edit'
						/>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item icon='Printer' key='print'>
								Print
							</Dropdown.Item>
							<Dropdown.Item icon='Undo' key='captureScheduledPayment'>
								Capture Now
							</Dropdown.Item>
							<Dropdown.Item icon='Redo' key='refund'>
								Refund
							</Dropdown.Item>
							<Dropdown.Item
								icon='Clipboard-list'
								text='Apply to Invoices'
								key='applyToInvoices'
								noPermission={!userContext.hasPermission('edit_payments')}>
								Apply to Invoices
							</Dropdown.Item>
							<Dropdown.Item icon='Printer' key='printReceipt'>
								Print Receipt
							</Dropdown.Item>
							<Dropdown.Item icon='Mail-box' key='sendEmail'>
								Email Receipt
							</Dropdown.Item>
							<Dropdown.Item itemsColor='danger' icon='Error-circle' key='void'>
								Void
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='danger'
								icon='Error-circle'
								key='bounceCheck'>
								Bounce Check
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			{PRINT_COMPONENT}
			<PrinterModal
				onClose={closeModal}
				printers={webPrint.current.printers}
				isOpen={modal.open === modals.PRINTER}
				enableRemember
				onSubmit={printer => {
					onPrint(modal.payment, printer.id);
					closeModal();
				}}
			/>
			<PayModal
				isOpen={modal.open === modals.REFUND}
				onClose={closeModal}
				paymentTypes={getPaymentTypes(
					true,
					paymentTypes,
					userContext.hasPermission('cash_payments')
				)}
				amount={modal.payment ? getPaymentRefundableAmount(modal.payment) : 0}
				customer={modal.payment?.customer}
				onPay={paymentData => {
					onRefund(modal.payment, paymentData);
				}}
				isPaying={isLoading}
				canSavePaymentMethod={userContext.hasPermission('save_customer_payment_methods')}
				canEnterCreditCard={userContext.hasPermission('key_in_credit_cards')}
				isRefund={modal.open === modals.REFUND}
				refundAmountChangeable
				max={modal.payment ? modal.payment.amount : 0}
				defaultCreditCard={modal.payment ? getDefaultCreditCard(modal) : null}
				activePaymentMethod={modal.payment ? getDefaultPaymentMethod(modal) : null}
				paymentGateway={userContext.data.selectedOutlet.settings.paymentGateway}
				serviceFeeModule={serviceFeeModules.CRM}
				disableServiceFee
				outlet={userContext.data.selectedOutlet}
			/>
			<DialogBox
				open={modal.open === modals.VOID}
				title='Void'
				content='Are you sure you want to void this payment?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't Void!`}
					onClick={closeModal}
					disabled={isLoading}
				/>
				<Button
					className='sdms-font-transform-c'
					label='danger'
					icon='Trash'
					text='Yes, Void!'
					onClick={onVoid}
					disabled={isLoading}
				/>
			</DialogBox>
			<DialogBox
				open={modal.open === modals.CAPTURE}
				title='Capture'
				content='Are you sure you want to capture this payment?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't Capture!`}
					onClick={closeModal}
					disabled={isLoading}
				/>
				<Button
					className='sdms-font-transform-c'
					label='info'
					icon='Trash'
					text='Yes, Capture!'
					onClick={onCapture}
					disabled={isLoading}
				/>
			</DialogBox>
			<DialogBox
				open={modal.open === modals.BOUNCE_CHECK}
				title='Bounce check'
				content='Are you sure you want to bounce this check?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't bounce!`}
					onClick={closeModal}
					disabled={isLoading}
				/>
				<Button
					className='sdms-font-transform-c'
					label='info'
					icon='Ticket'
					text='Yes, bounce!'
					onClick={onBounce}
					disabled={isLoading}
				/>
			</DialogBox>
			{modal.open === modals.APPLY_INVOICE && modal.payment && (
				<ApplyInvoiceModal
					remittance={modal.payment}
					onClose={closeModal}
					afterSubmit={payment => {
						modal.updateData([payment]);
						closeModal();
					}}
				/>
			)}
		</>
	);
};
PaymentList.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	history: PropTypes.object,
};
PaymentList.defaultProps = {
	history: null,
};

export default PaymentList;
