import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import HeaderContext from '../../../../app/contexts/HeaderContext';
import pages from '../../../pages';
import forms from '../../../forms';
import { modules } from '../../../../utils/helpers/apiCall';
import { getModuleByPath } from '../../../../utils/helpers/reusable';

import Button from '../../element/Button';
import Dropdown from '../../element/Dropdown';
import List from '../../template/List';
import ListContainer from '../../template/ListContainer';

const UnitGroupsList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	const location = useLocation(); // React Hook

	const data = useMemo(() => {
		const module = getModuleByPath(location.pathname);

		if (module === modules.MARINA)
			return {
				text: pages.marina.settings.spaceGroups.text,
				breadcrumb: [
					{ title: pages.marina.default.text, path: pages.marina.dashboard.path },
					{ title: pages.marina.settings.text, path: pages.marina.settings.path },
					{ title: pages.marina.settings.spaceGroups.text, isActive: true },
				],
				title: pages.marina.settings.spaceGroups.text,
				icon: pages.marina.settings.spaceGroups.icon,
				forms: forms.marina.settings.spaceGroups,
				module: modules.MARINA,
			};

		if (module === modules.CAMPGROUND)
			return {
				text: pages.campground.settings.spaceGroups.text,
				breadcrumb: [
					{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
					{ title: pages.campground.settings.text, path: pages.campground.settings.path },
					{ title: pages.campground.settings.spaceGroups.text, isActive: true },
				],
				title: pages.campground.settings.spaceGroups.text,
				icon: pages.campground.settings.spaceGroups.icon,
				forms: forms.campground.settings.spaceGroups,
				module: modules.CAMPGROUND,
			};

		return {
			text: pages.booking.settings.unitGroups.text,
			breadcrumb: [
				{ title: pages.booking.default.text, path: pages.booking.dashboard.path },
				{ title: pages.booking.settings.text, path: pages.booking.settings.path },
				{ title: pages.booking.settings.unitGroups.text, isActive: true },
			],
			title: pages.booking.settings.unitGroups.text,
			icon: pages.booking.settings.unitGroups.icon,
			forms: forms.booking.settings.unitGroups,
			module: modules.BOOKINGS,
		};
	}, [location]);

	useEffect(() => {
		headerContext.setBreadcrumbs(data.breadcrumb);

		headerContext.setPageTitle(data.text);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [data]);

	return (
		<ListContainer
			route='unitGroups'
			title={data.title}
			history={history}
			icon={data.icon}
			forms={data.forms}
			module={data.module}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col label='Order' cellData='sortOrder' sortable='sortOrder' />
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
UnitGroupsList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default UnitGroupsList;
