import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

import usePages from '../../../utils/hooks/usePages';
import HeaderContext from '../../../app/contexts/HeaderContext';
import UserContext from '../../../app/contexts/UserContext';

import BreadcrumbContainer from '../../reusables/template/BreadcrumbContainer';
import ContentInner from '../../reusables/template/ContentInner';
import IconBox from '../../reusables/layout/IconBox';
import logo from '../../../assets/img/logo.svg';

const _Settings = ({ history }) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.marina.default.text,
				path: pages.marina.dashboard.path,
			},
			{
				title: pages.marina.settings.text,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(pages.marina.settings.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	const _colClass = 'col-xl-4 col-md-6';

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title={pages.marina.settings.text} />
					<BreadcrumbContainer />
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title={pages.marina.settings.text}>
				<div className='row'>
					<div className='col-3' />
					<div className='col-6 sdms-align-center'>
						<ReactSVG
							src={logo}
							className='svg-container sdms-block-center sdms-w-35p-desktop sdms-margin-t-30'
						/>
					</div>
					<div className='offset-xl-2 col-xl-8 offset-lg-1 col-lg-10 col-md-12 sdms-mt-50'>
						<div className='row sdms-iconbox--container'>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.bookingType.icon}
									title={pages.marina.settings.bookingType.text}
									onClick={() =>
										history.push(pages.marina.settings.bookingType.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.bookingType.permission
										)
									}>
									Setup Booking Types
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.customReservationStatus.icon}
									title={pages.marina.settings.customReservationStatus.text}
									onClick={() =>
										history.push(
											pages.marina.settings.customReservationStatus.path
										)
									}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.customReservationStatus.permission
										)
									}>
									Setup Custom Reservation Statuses
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.maps.icon}
									title={pages.marina.settings.maps.text}
									onClick={() => history.push(pages.marina.settings.maps.path)}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.maps.permission
										)
									}>
									Design Marina Maps
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.policies.icon}
									title={pages.marina.settings.policies.text}
									onClick={() =>
										history.push(pages.marina.settings.policies.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.policies.permission
										)
									}>
									View, Add & Edit Policies
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.powerMeterTypes.icon}
									title={pages.marina.settings.powerMeterTypes.text}
									onClick={() =>
										history.push(pages.marina.settings.powerMeterTypes.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.powerMeterTypes.permission
										)
									}>
									View, Add & Edit Power Meter Types
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.powerMeters.icon}
									title={pages.marina.settings.powerMeters.text}
									onClick={() =>
										history.push(pages.marina.settings.powerMeters.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.powerMeters.permission
										)
									}>
									View, Add & Edit Power Meters
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.extraCharges.icon}
									title={pages.marina.settings.extraCharges.text}
									onClick={() =>
										history.push(pages.marina.settings.extraCharges.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.extraCharges.permission
										)
									}>
									Manage Fees and Discounts
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.extraChargeGroups.icon}
									title={pages.marina.settings.extraChargeGroups.text}
									onClick={() =>
										history.push(pages.marina.settings.extraChargeGroups.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.extraChargeGroups.permission
										)
									}>
									Group Fees & Discounts
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.productCategories.icon}
									title={pages.marina.settings.productCategories.text}
									onClick={() =>
										history.push(pages.marina.settings.productCategories.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.productCategories.permission
										)
									}>
									Manage Categories
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.promoCodes.icon}
									title={pages.marina.settings.promoCodes.text}
									onClick={() =>
										history.push(pages.marina.settings.promoCodes.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.promoCodes.permission
										)
									}>
									View, Add & Edit Promo Codes
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.ratePlans.icon}
									title={pages.marina.settings.ratePlans.text}
									onClick={() =>
										history.push(pages.marina.settings.ratePlans.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.ratePlans.permission
										)
									}>
									View, Add & Edit Rate Plans
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.spaces.icon}
									title={pages.marina.settings.spaces.text}
									onClick={() => history.push(pages.marina.settings.spaces.path)}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.spaces.permission
										)
									}>
									Configure Marina Spaces
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.spaceBlockouts.icon}
									title={pages.marina.settings.spaceBlockouts.text}
									onClick={() =>
										history.push(pages.marina.settings.spaceBlockouts.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.spaceBlockouts.permission
										)
									}>
									Setup Space Blackouts
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.compositeSpaces.icon}
									title={pages.marina.settings.compositeSpaces.text}
									onClick={() =>
										history.push(pages.marina.settings.compositeSpaces.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.compositeSpaces.permission
										)
									}>
									Configure Marina Space Collections
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.settings.spaceGroups.icon}
									title={pages.marina.settings.spaceGroups.text}
									onClick={() =>
										history.push(pages.marina.settings.spaceGroups.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.marina.settings.spaceGroups.permission
										)
									}>
									Group Marina Spaces
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.marina.vessels.icon}
									title={pages.marina.vessels.text}
									onClick={() => history.push(pages.marina.vessels.path)}
									noPermission={
										!userContext.hasPermission(pages.marina.vessels.permission)
									}>
									View, Add & Edit Vessels
								</IconBox>
							</div>
						</div>
					</div>
				</div>
			</ContentInner.Container>
		</>
	);
};
_Settings.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default _Settings;
