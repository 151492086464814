import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import Button from '../element/Button';
import Portal from '../layout/Portal';
import Portlet from '../layout/Portlet';
import { mediaBreakpoint } from '../../../utils/constants/constants';
import QuickPanel from '../../QuickPanel';
import { useWindowSize } from '../../../utils/helpers/helper';

const _title = 'Move Table';
const _icon = 'Subtract';

const TableModal = ({ isOpen, tables, onSelect, onClose }) => {
	const windowSize = useWindowSize();
	const _Inner = (
		<>
			<Portlet.Body>
				<div className='row sdms-padding-t-15-mobile'>
					{tables.map(t => (
						<div className='col-md-4 col-6 sdms-pb-10' key={t.id}>
							<Button
								className='sdms-font-lg'
								design='info'
								bold='bold'
								outline
								block
								text={t.name}
								onClick={() => onSelect(t)}
							/>
						</div>
					))}
				</div>
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Close'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
			</Portlet.Foot>
		</>
	);

	if (windowSize.width < mediaBreakpoint.MD) {
		return (
			<QuickPanel
				status={isOpen}
				setStatus={onClose}
				title={_title}
				icon={_icon}
				portletClass='sdms-list-layout'>
				{_Inner}
			</QuickPanel>
		);
	}
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
					minHeight: '480px',
					height: '50%',
				}}>
				<Portlet fluid='fluid'>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon='Subtract'>
							{_title}
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					{_Inner}
				</Portlet>
			</Popup>
		</Portal>
	);
};
TableModal.propTypes = {
	isOpen: PropTypes.bool,
	tables: PropTypes.arrayOf(PropTypes.object),
	onSelect: PropTypes.func,
	onClose: PropTypes.func,
};
TableModal.defaultProps = {
	isOpen: false,
	tables: [],
	onSelect: () => {},
	onClose: () => {},
};

export default TableModal;
