import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { searchTypes } from '../../../../../../utils/constants/constants';
import FormField from '../../../../template/FormField';
import Selects from '../../../../field/Selects';
import useField from '../../../../../../utils/hooks/useField';
import { required } from '../../../../../../utils/helpers/validation';
import LengthInputGroup from '../../../../field/LengthInputGroup';
import useFeet from '../../../../../../utils/hooks/useFeet';
import { numberParser } from '../../../../../../utils/helpers/helper';
import Input from '../../../../field/Input';

const fields = {
	ADULTS: 'adults',
	CHILDREN: 'children',
	LOA: 'loa',
	BEAM: 'beam',
	HEIGHT: 'height',
	WEIGHT: 'weight',
	DRAFT: 'draft',
};

const SearchFilters = ({ bookingType, isOnline, isLoading, onChange, setIsValid, searchData }) => {
	const numberPeople = useRef(
		new Array(19).fill(0).map((value, index) => ({
			sortKey: index < 10 ? `0${index}` : index,
			id: index,
			label: index,
		}))
	);

	const requiredByBookingType = field =>
		bookingType[field]?.value === searchTypes.REQUIRED ? required : () => true;

	const filters = useMemo(() => {
		const _filters = [];

		if (!bookingType) return _filters;

		const {
			searchPeople,
			searchLoa,
			searchBeam,
			searchHeight,
			searchWeight,
			searchDraft,
		} = bookingType;

		if (searchPeople) {
			if (searchPeople.value !== searchTypes.HIDDEN) _filters.push(fields.ADULTS);
			if (
				searchPeople.value === searchTypes.ADULTS_OPTIONAL ||
				searchPeople.value === searchTypes.ADULTS_REQUIRED
			)
				_filters.push(fields.CHILDREN);
		}

		if (searchLoa && searchLoa.value !== searchTypes.HIDDEN) _filters.push(fields.LOA);

		if (searchBeam && searchBeam.value !== searchTypes.HIDDEN) _filters.push(fields.BEAM);

		if (searchHeight && searchHeight.value !== searchTypes.HIDDEN) _filters.push(fields.HEIGHT);

		if (searchWeight && searchWeight.value !== searchTypes.HIDDEN) _filters.push(fields.WEIGHT);

		if (searchDraft && searchDraft.value !== searchTypes.HIDDEN) _filters.push(fields.DRAFT);

		return _filters;
	}, [bookingType]);

	const disabled = useMemo(() => !bookingType || isLoading, [bookingType, isLoading]);

	const [adults, adultsOnChange, adultsValRes, adultsShowVal, setAdultsShowVal] = useField(
		{},
		'adults',
		() => {},
		[
			bookingType?.searchPeople?.value === searchTypes.PEOPLE_REQUIRED ||
			bookingType?.searchPeople?.value === searchTypes.ADULTS_REQUIRED
				? required
				: () => true,
		],
		null
	);

	const [children, childrenOnChange] = useField({}, 'children', () => {}, [], null);

	const [loa, loaOnChange, loaValRes, loaShowVal, setLoaShowVal] = useField(
		{},
		'loa',
		() => {},
		[requiredByBookingType('searchLoa')],
		'',
		numberParser()
	);

	const [loaFt, setLoaFt, loaIn, setLoaIn] = useFeet(searchData.loa, loaOnChange, false);

	const [beam, beamOnChange, beamValRes, beamShowVal, setBeamShowVal] = useField(
		{},
		'beam',
		() => {},
		[requiredByBookingType('searchBeam')],
		'',
		numberParser()
	);

	const [beamFt, setBeamFt, beamIn, setBeamIn] = useFeet(searchData.beam, beamOnChange, false);

	const [height, heightOnChange, heightValRes, heightShowVal, setHeightShowVal] = useField(
		{},
		'height',
		() => {},
		[requiredByBookingType('searchHeight')],
		'',
		numberParser()
	);

	const [heightFt, setHeightFt, heightIn, setHeightIn] = useFeet(
		searchData.height,
		heightOnChange,
		false
	);

	const [draft, draftOnChange, draftValRes, draftShowVal, setDraftShowVal] = useField(
		{},
		'draft',
		() => {},
		[requiredByBookingType('searchDraft')],
		'',
		numberParser()
	);

	const [draftFt, setDraftFt, draftIn, setDraftIn] = useFeet(
		searchData.draft,
		draftOnChange,
		false
	);

	const [weight, weightOnChange, weightValRes, weightShowVal, setWeightShowVal] = useField(
		{},
		'weight',
		() => {},
		[requiredByBookingType('searchWeight')],
		searchData.weight,
		numberParser()
	);

	const displayField = fieldName => filters.indexOf(fieldName) > -1;

	useEffect(() => {
		onChange({ adults, children, loa, beam, height, weight, draft });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adults, children, loa, beam, height, weight, draft]);

	useEffect(() => {
		setIsValid(
			adultsValRes.isValid &&
				loaValRes.isValid &&
				beamValRes.isValid &&
				heightValRes.isValid &&
				weightValRes.isValid &&
				draftValRes.isValid
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		adultsValRes.isValid,
		loaValRes.isValid,
		beamValRes.isValid,
		heightValRes.isValid,
		weightValRes.isValid,
		draftValRes.isValid,
	]);

	return (
		<>
			{displayField(fields.ADULTS) && (
				<FormField
					isLast
					name='adults'
					label={displayField(fields.CHILDREN) ? 'Adults' : 'People'}
					id={0}
					valRes={adultsValRes}
					showValidation={adultsShowVal}
					col={isOnline ? 6 : 12}
					colMd={isOnline ? 4 : 6}
					colLg={isOnline ? 2 : 3}
					colXl={isOnline ? null : 2}
					inFormDesign={false}>
					<Selects
						options={numberPeople.current}
						placeholder={`${displayField(fields.CHILDREN) ? 'Adults' : 'People'} ${
							!adultsValRes.isValid ? ' (Required)' : ''
						}`}
						value={adults}
						onChange={adultsOnChange}
						onBlur={setAdultsShowVal}
						disabled={disabled}
						mobileUI
						sortKey='sortKey'
						displayKey='label'
					/>
				</FormField>
			)}
			{displayField(fields.CHILDREN) && (
				<FormField
					isLast
					name='children'
					label='Children'
					id={0}
					col={isOnline ? 6 : 12}
					colMd={isOnline ? 4 : 6}
					colLg={isOnline ? 2 : 3}
					colXl={isOnline ? null : 2}
					inFormDesign={false}>
					<Selects
						options={numberPeople.current}
						placeholder='Children'
						value={children}
						onChange={childrenOnChange}
						disabled={disabled}
						mobileUI
						sortKey='sortKey'
						displayKey='label'
					/>
				</FormField>
			)}
			{displayField(fields.LOA) && (
				<FormField
					isLast
					name='loa'
					label='Length Overall'
					id={0}
					valRes={loaValRes}
					showValidation={loaShowVal}
					col={isOnline ? 6 : 12}
					colMd={isOnline ? 4 : 6}
					colLg={isOnline ? 2 : 3}
					colXl={isOnline ? null : 2}
					inFormDesign={false}>
					<LengthInputGroup
						ft={loaFt}
						ftOnChange={setLoaFt}
						inch={loaIn}
						inchOnChange={setLoaIn}
						placeHolder='Loa'
						onBlur={setLoaShowVal}
						disabled={disabled}
						withInch={bookingType.showDetailedSearch}
					/>
				</FormField>
			)}
			{displayField(fields.BEAM) && (
				<FormField
					isLast
					name='beam'
					label='Beam'
					id={0}
					valRes={beamValRes}
					showValidation={beamShowVal}
					col={isOnline ? 6 : 12}
					colMd={isOnline ? 4 : 6}
					colLg={isOnline ? 2 : 3}
					colXl={isOnline ? null : 2}
					inFormDesign={false}>
					<LengthInputGroup
						ft={beamFt}
						ftOnChange={setBeamFt}
						inch={beamIn}
						inchOnChange={setBeamIn}
						placeHolder='Beam'
						onBlur={setBeamShowVal}
						disabled={disabled}
						withInch={bookingType.showDetailedSearch}
					/>
				</FormField>
			)}
			{displayField(fields.HEIGHT) && (
				<FormField
					isLast
					name='height'
					label='Height'
					id={0}
					valRes={heightValRes}
					showValidation={heightShowVal}
					col={isOnline ? 6 : 12}
					colMd={isOnline ? 4 : 6}
					colLg={isOnline ? 2 : 3}
					colXl={isOnline ? null : 2}
					inFormDesign={false}>
					<LengthInputGroup
						ft={heightFt}
						ftOnChange={setHeightFt}
						inch={heightIn}
						inchOnChange={setHeightIn}
						placeHolder='Height'
						onBlur={setHeightShowVal}
						disabled={disabled}
						withInch={bookingType.showDetailedSearch}
					/>
				</FormField>
			)}
			{displayField(fields.DRAFT) && (
				<FormField
					isLast
					name='draft'
					label='Draft'
					id={0}
					valRes={draftValRes}
					showValidation={draftShowVal}
					col={isOnline ? 6 : 12}
					colMd={isOnline ? 4 : 6}
					colLg={isOnline ? 2 : 3}
					colXl={isOnline ? null : 2}
					inFormDesign={false}>
					<LengthInputGroup
						ft={draftFt}
						ftOnChange={setDraftFt}
						inch={draftIn}
						inchOnChange={setDraftIn}
						placeHolder='Draft'
						onBlur={setDraftShowVal}
						disabled={disabled}
						withInch={bookingType.showDetailedSearch}
					/>
				</FormField>
			)}
			{displayField(fields.WEIGHT) && (
				<FormField
					isLast
					name='weight'
					label='Weight'
					id={0}
					valRes={weightValRes}
					showValidation={weightShowVal}
					col={isOnline ? 6 : 12}
					colMd={isOnline ? 4 : 6}
					colLg={isOnline ? 2 : 3}
					colXl={isOnline ? null : 2}
					inFormDesign={false}>
					<Input
						type='number'
						withOutSpin
						min={0}
						placeholder='Weight'
						value={weight}
						onChange={weightOnChange}
						pattern={process.env.REACT_APP_INTEGER_PATTERN}
						append='lbs'
						onBlur={setWeightShowVal}
						disabled={disabled}
					/>
				</FormField>
			)}
		</>
	);
};

SearchFilters.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	bookingType: PropTypes.object,
	isOnline: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	setIsValid: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	searchData: PropTypes.object,
};

SearchFilters.defaultProps = {
	bookingType: null,
	searchData: {},
};

export default SearchFilters;
