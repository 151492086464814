import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useComponentSize from '@rehooks/component-size';

import UserContext from '../../../../../app/contexts/UserContext';
import useField from '../../../../../utils/hooks/useField';
import useDate from '../../../../../utils/hooks/useDate';
import { required } from '../../../../../utils/helpers/validation';
import { addErrorNotification, addSuccessNotification } from '../../../../../utils/helpers/helper';
import { reservationColor } from '../../../element/ReservationStatusCell';
import { reservationStatuses } from '../../../../../utils/constants/constants';
import apiCall from '../../../../../utils/helpers/apiCall';
import { getBookingHourlyStep } from '../../../../../utils/helpers/reusable';

import QuickPanelForm from '../../../template/QuickPanelForm';
import FormGroup from '../../../layout/FormGroup';
import FormField from '../../../template/FormField';
import DatePicker from '../../../field/DatePicker';
import TimePickerInput from '../../../field/TimePickerInput';
import Portal from '../../../layout/Portal';
import Portlet from '../../../layout/Portlet';
import Button from '../../../element/Button';
import Toggle from '../../../field/Toggle';

const Checkout = ({
	status,
	onClose,
	afterCheckout,
	reservation,
	reservationItem,
	isTransient,
	defaultToDate,
}) => {
	const userContext = useContext(UserContext);

	const refForTimePicker = useRef(null);

	const sizeTimePicker = useComponentSize(refForTimePicker);

	const timePickerStep = useRef(getBookingHourlyStep(userContext));

	const [isCheckingOut, setIsCheckingOut] = useState(false);

	const [
		,
		,
		,
		,
		parseReservationDatePickerValue,
		parseReservationDatePickerChange,
		parseReservationTimePickerValue,
		parseReservationTimePickerChange,
	] = useDate();

	const [toDate, toDateOnChange, toDateValRes, toDateShowVal, setToDateShowVal] = useField(
		{},
		'toDate',
		() => {},
		[required],
		null
	);

	const [invoiceNow, invoiceNowOnChange] = useField({}, 'invoiceNow', () => {}, [], false);

	const dateConstraints = useMemo(() => {
		if (!reservationItem)
			return {
				defaultDate: moment(),
				minDate: moment().toDate(),
				maxDate: moment().toDate(),
			};

		let defaultDate = defaultToDate || moment();

		const minDate = moment(reservationItem.fromDate).utc(false);

		const maxDate = moment(reservationItem.toDate).utc(false);

		if (defaultDate.isBefore(minDate)) defaultDate = minDate;

		if (defaultDate.isAfter(maxDate)) defaultDate = maxDate;

		return { defaultDate, minDate: minDate.toDate(), maxDate: maxDate.toDate() };
	}, [reservationItem, defaultToDate]);

	const onCheckout = () => {
		setToDateShowVal();

		if (!toDateValRes.isValid) return;

		setIsCheckingOut(true);

		apiCall(
			'POST',
			'advancedReservationCheckout',
			res => {
				setIsCheckingOut(false);
				addSuccessNotification('Successfully checked-out');
				onClose();
				afterCheckout(res.reservations[0], res.contracts);
			},
			e => {
				setIsCheckingOut(false);
				addErrorNotification(e.toString());
			},
			'',
			{
				reservationItems: [reservationItem.id],
				outletId: userContext.data.selectedOutlet.id,
				isAdvance: true,
				reservations: [reservation],
				toDate,
				invoiceNow,
			}
		);
	};

	useEffect(() => {
		if (dateConstraints.defaultDate)
			toDateOnChange({ target: { value: dateConstraints.defaultDate.toISOString() } });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dateConstraints]);

	return (
		<Portal>
			<QuickPanelForm status={status} onCancel={onClose}>
				<Portlet hasFrame fluid='fluid' className='sdms-marginles h-100'>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon='Sign-out'>
							Checkout
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<FormGroup isLast>
							<FormField
								name='toDate'
								label='End date'
								id='toDate'
								colMd={6}
								showValidation={toDateShowVal}
								valRes={toDateValRes}>
								<DatePicker
									id='toDate'
									type='calendar'
									value={parseReservationDatePickerValue(toDate)}
									onChange={e => {
										toDateOnChange({
											target: {
												value: parseReservationDatePickerChange(
													toDate,
													e.target.value
												),
											},
										});
									}}
									onBlur={setToDateShowVal}
									minDate={dateConstraints.minDate}
									maxDate={dateConstraints.maxDate}
									disabled={isCheckingOut}
								/>
							</FormField>
							<FormField
								name='toDate'
								label='End time'
								id='toDateTime'
								colMd={6}
								showValidation={toDateShowVal}
								valRes={toDateValRes}>
								<TimePickerInput
									showSecond={false}
									defaultValue={dateConstraints.defaultDate}
									value={parseReservationTimePickerValue(toDate)}
									onChange={target => {
										toDateOnChange({
											target: {
												value: parseReservationTimePickerChange(
													toDate,
													target
												),
											},
										});
									}}
									size={sizeTimePicker}
									use12Hours
									minuteStep={timePickerStep.current}
									onClose={() => setToDateShowVal()}
									disabled={isCheckingOut}
								/>
							</FormField>
							{!isTransient && (
								<FormField
									name='invoiceNow'
									label='Invoice Now'
									id='invoiceNow'
									colMd={6}>
									<Toggle
										onChange={invoiceNowOnChange}
										value={invoiceNow}
										disabled={isCheckingOut}
									/>
								</FormField>
							)}
						</FormGroup>
					</Portlet.Body>
					<Portlet.Foot tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
								disabled={isCheckingOut}
							/>
						</div>
						<div className='col-auto'>
							<Button
								className='sdms-mw-100'
								label={reservationColor(reservationStatuses.CHECKED_OUT)}
								text={isCheckingOut ? 'Checking-Out' : 'Check-Out'}
								icon='Sign-out'
								size='sm'
								onClick={onCheckout}
								disabled={isCheckingOut}
								isSubmitting={isCheckingOut}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</QuickPanelForm>
		</Portal>
	);
};

Checkout.propTypes = {
	status: PropTypes.bool,
	onClose: PropTypes.func,
	afterCheckout: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	isTransient: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	defaultToDate: PropTypes.object,
};

Checkout.defaultProps = {
	status: false,
	onClose: () => {},
	afterCheckout: () => {},
	reservation: null,
	reservationItem: null,
	isTransient: false,
	defaultToDate: null,
};

export default Checkout;
