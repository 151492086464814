import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { payButtonAttributes, reservationPayStatuses } from '../constants';
import { displayCountDown } from '../../../../../utils/hooks/useCountDown';

import Button from '../../Button';
import Portlet from '../../../layout/Portlet';
import ContentInner from '../../../template/ContentInner';

const Footer = ({
	activeStep,
	reservationItems,
	setActiveStep,
	isItemReserved,
	reserveButtonEnabled,
	updateReservationItem,
	reserveReservationItems,
	resetSearch,
	onPay,
	payStatus,
	isLoading,
	isOnline,
	countDown,
}) => {
	const isPaying = useMemo(() => payStatus === reservationPayStatuses.PAYING, [payStatus]);

	// Effect to update the timer

	const _Content = (
		<div className='row align-items-center'>
			<div className={classNames('col', 'col-md-4', 'order-md-0')}>
				{(activeStep === 1 || activeStep === 3) && (
					<Button
						className='sdms-p5'
						design='clean'
						text={`Back to ${(activeStep === 1 && 'Search') ||
							(activeStep === 2 && 'Options') ||
							(activeStep === 3 && 'Summary')}`}
						icon='Angle-left-circle'
						size='sm'
						onClick={() => setActiveStep(activeStep - 1)}
					/>
				)}
			</div>
			{reservationItems.current.length > 0 && (
				<div
					className={classNames(
						'col-md-4',
						'order-md-1',
						'order-last',
						'sdms-pt5-tablet-and-mobile',
						'd-flex',
						'justify-content-center',
						'align-items-center'
					)}>
					<div>{`${reservationItems.current.length} Booking(s) Reserved for: `}</div>
					<div
						className='sdms-font-lg sdms-font-boldest sdms-ml-5'
						style={{ width: '45px' }}>
						{displayCountDown(countDown)}
					</div>
				</div>
			)}
			<div
				className={classNames(
					'col-auto',
					'col-md-4',
					'order-md-2',
					'text-right',
					'ml-auto'
				)}>
				{activeStep === 0 && (
					<Button
						label='brand'
						icon='Clipboard-check'
						text='Go to Summary'
						size='sm'
						onClick={() => setActiveStep(2)}
					/>
				)}
				{activeStep === 1 && (
					<Button
						className={classNames({
							'sdms-fading-dots': isLoading,
						})}
						design='brand'
						text={isItemReserved ? 'Update' : 'Reserve'}
						icon={isLoading ? 'Other#2' : 'Angle-right-circle'}
						size='sm'
						onClick={isItemReserved ? updateReservationItem : reserveReservationItems}
						disabled={!reserveButtonEnabled || isLoading}
					/>
				)}
				{activeStep === 2 && (
					<>
						<Button
							className='sdms-mr-10'
							label='brand'
							outline
							text='Book More'
							icon='Clipboard'
							size='sm'
							onClick={() => resetSearch()}
							disabled={isLoading}
						/>
						<Button
							design='brand'
							text='Pay Now'
							icon='Angle-right-circle'
							size='sm'
							onClick={() => {
								setActiveStep(3);
							}}
							disabled={isLoading}
						/>
					</>
				)}
				{activeStep === 3 && (
					<Button
						className={classNames({
							'sdms-fading-dots': isPaying,
						})}
						label={payButtonAttributes[payStatus].label}
						text={payButtonAttributes[payStatus].text}
						icon={payButtonAttributes[payStatus].icon}
						size='sm'
						onClick={onPay}
						disabled={isPaying}
					/>
				)}
			</div>
		</div>
	);

	if (isOnline)
		return (
			<Portlet className='flex-grow-0' style={{ zIndex: 98 }} stickyBottom={0}>
				<Portlet.Body>{_Content}</Portlet.Body>
			</Portlet>
		);

	return <ContentInner.SubFooter>{_Content}</ContentInner.SubFooter>;
};

Footer.propTypes = {
	activeStep: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItems: PropTypes.object.isRequired,
	setActiveStep: PropTypes.func.isRequired,
	isItemReserved: PropTypes.bool.isRequired,
	reserveButtonEnabled: PropTypes.bool.isRequired,
	updateReservationItem: PropTypes.func.isRequired,
	reserveReservationItems: PropTypes.func.isRequired,
	resetSearch: PropTypes.func.isRequired,
	onPay: PropTypes.func.isRequired,
	payStatus: PropTypes.string,
	isLoading: PropTypes.bool.isRequired,
	isOnline: PropTypes.bool.isRequired,
	countDown: PropTypes.number,
};

Footer.defaultProps = {
	payStatus: reservationPayStatuses.DEFAULT,
	countDown: 0,
};

export default Footer;
