import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import useField from '../../../../../utils/hooks/useField';
import { required } from '../../../../../utils/helpers/validation';
import { modules } from '../../../../../utils/helpers/apiCall';

import Portal from '../../../layout/Portal';
import Portlet from '../../../layout/Portlet';
import Button from '../../../element/Button';
import FormField from '../../../template/FormField';
import Selects from '../../../field/Selects';

const Unit = ({ open, isSubmitting, module, availableUnits, onChange, onClose }) => {
	const [unit, unitOnChange, unitValRes, unitShowVal, setUnitShowVal] = useField(
		{},
		'unit',
		() => {},
		[required],
		null
	);

	return (
		<Portal>
			<Popup
				open={open}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<Portlet>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon={module}>
							{module === modules.BOOKINGS ? 'Unit' : 'Space'}
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						<FormField
							name='paymentSchedule'
							id={0}
							inFormDesign={false}
							valRes={unitValRes}
							showValidation={unitShowVal}>
							<Selects
								options={availableUnits}
								placeholder={`Select a ${
									module === modules.BOOKINGS ? 'Unit' : 'Space'
								}`}
								onChange={unitOnChange}
								value={unit}
								className='h-100'
							/>
						</FormField>
					</Portlet.Body>
					<Portlet.Foot
						tall='sm'
						className='sdms-align-left'
						subClassName='justify-content-between'>
						<div className='col-auto'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
								disabled={isSubmitting}
							/>
						</div>
						<div className='col-auto'>
							<Button
								label='brand'
								icon='Save'
								text={isSubmitting ? 'Changing' : 'Change'}
								size='sm'
								onClick={() => {
									setUnitShowVal();

									if (!unitValRes.isValid) return;

									onChange(unit);
								}}
								disabled={isSubmitting}
								isSubmitting={isSubmitting}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};

Unit.propTypes = {
	open: PropTypes.bool,
	isSubmitting: PropTypes.bool,
	module: PropTypes.string,
	availableUnits: PropTypes.arrayOf(PropTypes.object),
	onChange: PropTypes.func,
	onClose: PropTypes.func,
};

Unit.defaultProps = {
	open: false,
	isSubmitting: false,
	module: '',
	availableUnits: [],
	onChange: () => {},
	onClose: () => {},
};

export default Unit;
