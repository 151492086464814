import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import useComponentSize from '@rehooks/component-size';
import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';
import Loading from '../../reusables/template/Loading';
import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import TimePickerInput from '../../reusables/field/TimePickerInput';
import Selects from '../../reusables/field/Selects';
import DatePicker from '../../reusables/field/DatePicker';

const PayrollHoursForm = ({ data, setIsValid, isSubmitted, isLoading, onFormChange, users }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const refForTimePicker = useRef(null);

	const sizeTimePicker = useComponentSize(refForTimePicker);

	const timePickerStep = 1;

	const endTimeValidation = (value, setValRes) => {
		if (!startTime || !value) return true;

		if (moment(startTime).isSameOrAfter(moment(value))) {
			setValRes({
				isValid: false,
				status: 'invalidDate',
				message: 'End time cannot be before start time',
			});

			return false;
		}

		return true;
	};

	const [user, userOnChange, userValRes, userShowVal, setUserShowVal] = useField(
		data,
		'user',
		onFormChange,
		[required],
		null
	);

	const [
		startTime,
		startTimeOnChange,
		startTimeValRes,
		startTimeShowVal,
		setStartTimeShowVal,
	] = useField(data, 'startTime', onFormChange, [required], null);

	const [
		endTime,
		endTimeOnChange,
		endTimeValRes,
		endTimeShowVal,
		setEndTimeShowVal,
		validateEndTime,
	] = useField(data, 'endTime', onFormChange, [endTimeValidation], null);

	useEffect(() => {
		if (isSubmitted) {
			setUserShowVal();
			setStartTimeShowVal();
			setEndTimeShowVal();
		}
	}, [isSubmitted, setUserShowVal, setStartTimeShowVal, setEndTimeShowVal]);

	useEffect(() => {
		setIsValid(userValRes.isValid && startTimeValRes.isValid && endTimeValRes.isValid);
	}, [userValRes.isValid, startTimeValRes.isValid, endTimeValRes.isValid, setIsValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.accounting.default.text,
				path: pages.accounting.dashboard.path,
			},
			{
				title: pages.accounting.payrollHours.text,
				path: pages.accounting.payrollHours.path,
			},
			{
				title: user ? user.displayName : `New ${pages.accounting.payrollHours.text}`,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(
			user ? user.displayName : `New ${pages.accounting.payrollHours.text}`
		);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [user]);

	return (
		<form className='sdms-form'>
			<FormGroup>
				<Loading isLoading={isLoading}>
					<FormField
						name='userName'
						label='User Name'
						id={data.id}
						valRes={userValRes}
						showValidation={userShowVal}
						loadingContainer
						col={12}>
						<Selects
							options={users}
							placeholder='User (Required)'
							value={user}
							displayKey='displayName'
							onChange={userOnChange}
							onBlur={setUserShowVal}
						/>
					</FormField>
				</Loading>
				<Loading isLoading={isLoading}>
					<FormField
						ref={refForTimePicker}
						name='startDate'
						label='Start Date'
						id={data.id}
						valRes={startTimeValRes}
						showValidation={startTimeShowVal}
						loadingContainer
						col={12}>
						<DatePicker
							id='startDate'
							type='calendar'
							value={startTime !== null ? moment(startTime).toDate() : null}
							onChange={e => {
								if (e !== null) {
									startTimeOnChange({
										target: {
											name: 'startTime',
											value:
												startTime !== null
													? moment(startTime)
															.year(moment(e.target.value).year())
															.month(moment(e.target.value).month())
															.date(moment(e.target.value).date())
															.toISOString()
													: moment(e.target.value).toISOString(),
										},
									});
								} else {
									startTimeOnChange({
										target: {
											name: 'startTime',
											value: null,
										},
									});
								}
								validateEndTime(endTime);
								onFormChange();
							}}
							onBlur={setStartTimeShowVal}
						/>
					</FormField>
				</Loading>
				<Loading isLoading={isLoading}>
					<FormField
						ref={refForTimePicker}
						name='startTime'
						label='Start Time'
						id={data.id}
						valRes={startTimeValRes}
						showValidation={startTimeShowVal}
						loadingContainer
						col={12}>
						<TimePickerInput
							showSecond={false}
							placeholder='Select Start Time'
							value={startTime !== null ? moment(startTime) : null}
							defaultValue={moment()}
							onChange={e => {
								if (e !== null) {
									startTimeOnChange({
										target: {
											name: 'startTime',
											value:
												startTime !== null
													? moment(startTime)
															.hour(e.hour())
															.minute(e.minute())
															.toISOString()
													: e.toISOString(),
										},
									});
								} else
									startTimeOnChange({
										target: {
											name: 'startTime',
											value: null,
										},
									});
								onFormChange();
								setStartTimeShowVal();
							}}
							size={sizeTimePicker}
							minuteStep={timePickerStep}
							use12Hours
						/>
					</FormField>
				</Loading>
				<Loading isLoading={isLoading}>
					<FormField
						ref={refForTimePicker}
						name='endDate'
						label='End Date'
						id={data.id}
						loadingContainer
						valRes={endTimeValRes}
						showValidation={endTimeShowVal}
						col={12}>
						<DatePicker
							id='endDate'
							type='calendar'
							value={endTime !== null ? moment(endTime).toDate() : null}
							onChange={e => {
								if (e !== null) {
									endTimeOnChange({
										target: {
											name: 'endTime',
											value:
												endTime !== null
													? moment(endTime)
															.year(moment(e.target.value).year())
															.month(moment(e.target.value).month())
															.date(moment(e.target.value).date())
															.toISOString()
													: moment(e.target.value).toISOString(),
										},
									});
								} else {
									endTimeOnChange({
										target: {
											name: 'endTime',
											value: null,
										},
									});
								}
								onFormChange();
							}}
							onBlur={setEndTimeShowVal}
							minDate={startTime ? new Date(startTime) : null}
						/>
					</FormField>
				</Loading>
				<Loading isLoading={isLoading}>
					<FormField
						ref={refForTimePicker}
						name='endTime'
						label='End Time'
						id={data.id}
						loadingContainer
						valRes={endTimeValRes}
						showValidation={endTimeShowVal}
						col={12}>
						<TimePickerInput
							showSecond={false}
							placeholder='Select End Time'
							value={endTime !== null ? moment(endTime) : null}
							defaultValue={moment()}
							onChange={e => {
								if (e !== null) {
									endTimeOnChange({
										target: {
											name: 'endTime',
											value:
												endTime !== null
													? moment(endTime)
															.hour(e.hour())
															.minute(e.minute())
															.toISOString()
													: e.toISOString(),
										},
									});
								} else
									endTimeOnChange({
										target: {
											name: 'endTime',
											value: null,
										},
									});
								onFormChange();
								setEndTimeShowVal();
							}}
							size={sizeTimePicker}
							use12Hours
							minuteStep={timePickerStep}
						/>
					</FormField>
				</Loading>
			</FormGroup>
		</form>
	);
};
PayrollHoursForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		startTime: PropTypes.string,
		endTime: PropTypes.string,
		user: PropTypes.object,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	users: PropTypes.arrayOf(PropTypes.object),
};
PayrollHoursForm.defaultProps = {
	data: {
		id: 0,
		startTime: null,
		endTime: null,
		user: {},
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	users: [],
};

export default PayrollHoursForm;
