import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import update from 'immutability-helper';
import SVGIcon from '../element/SVGIcon';

import {
	addErrorNotification,
	convertDateToUTC,
	generateId,
	priceFormatter,
	getInvoicingFrequencyValue,
} from '../../../utils/helpers/helper';

import { invoicingFrequencies as _invoicingFrequencies } from '../../../utils/constants/constants';

import apiCall from '../../../utils/helpers/apiCall';
import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';

import Portlet from '../layout/Portlet';
import FormField from '../template/FormField';
import DatePicker from '../field/DatePicker';
import Toggle from '../field/Toggle';
import Button from '../element/Button';
import Input from '../field/Input';
import Selects from '../field/Selects';
import { ListBody, ListTable } from '../template/List';

import '../../../assets/deferredSchedule.css';
import UserContext from '../../../app/contexts/UserContext';

const InvoiceDeferredScheduleItem = ({
	data,
	onFormChange,
	onDelete,
	minScheduledDate,
	setIsValid,
	isSubmitted,
}) => {
	const userContext = useContext(UserContext);

	const [
		scheduledDate,
		scheduledDateOnChange,
		scheduledDateValRes,
		scheduleDateShowVal,
		setScheduledDateShowVal,
	] = useField(data, 'scheduledDate', onFormChange, [required], null);

	const [posted, postedOnChange] = useField(data, 'posted', onFormChange, [], false);

	useEffect(() => {
		setIsValid(scheduledDateValRes.isValid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scheduledDateValRes.isValid]);

	useEffect(() => {
		if (isSubmitted) setScheduledDateShowVal();
	}, [isSubmitted, setScheduledDateShowVal]);

	return (
		<tr>
			<td className='sdms-deferred-schedule-col'>
				<FormField
					name='scheduledDate'
					id={data.id}
					inFormDesign={false}
					isLast
					valRes={scheduledDateValRes}
					showValidation={scheduleDateShowVal}>
					<DatePicker
						id='scheduledDate'
						type='calendar'
						placeholder='Scheduled Date'
						value={
							scheduledDate !== null
								? convertDateToUTC(moment(scheduledDate).toDate())
								: null
						}
						onChange={e => {
							if (e.target.value) {
								scheduledDateOnChange({
									target: {
										value: moment(scheduledDate || undefined)
											.utc(false)
											.year(e.target.value.getFullYear())
											.month(e.target.value.getMonth())
											.date(e.target.value.getDate())
											.startOf('day')
											.toISOString(),
									},
								});
							} else {
								scheduledDateOnChange({
									target: {
										value: null,
									},
								});
							}
						}}
						onBlur={setScheduledDateShowVal}
						minDate={
							minScheduledDate &&
							!userContext.hasPermission('edit_deferred_posted_transactions')
								? moment(minScheduledDate).toDate()
								: undefined
						}
						disabled={
							posted
								? !userContext.hasPermission('edit_deferred_posted_transactions')
								: !userContext.hasPermission('maintain_deferred_schedule')
						}
					/>
				</FormField>
			</td>
			<td className='sdms-deferred-schedule-col'>
				<FormField name='amount' id={data.id} inFormDesign={false} isLast>
					<Input
						value={priceFormatter(
							data.itemDeferredIncomes
								? data.itemDeferredIncomes.reduce(
										(partialSum, a) => partialSum + a.amount,
										0
								  )
								: 0
						)}
						onChange={() => {}}
						disabled
					/>
				</FormField>
			</td>
			<td className='sdms-deferred-schedule-col'>
				<FormField name='posted' id={data.id} inFormDesign={false} isLast>
					<Toggle
						onChange={postedOnChange}
						value={posted}
						disabled={
							posted
								? !userContext.hasPermission('edit_deferred_posted_transactions')
								: !userContext.hasPermission('maintain_deferred_schedule')
						}
						noPermission={
							!userContext.hasPermission('edit_deferred_posted_transactions')
						}
					/>
				</FormField>
			</td>
			{data.id <= 0 && (
				<td className='sdms-deferred-schedule-col'>
					<Button
						btnIcon
						label='danger'
						icon='Trash'
						size='sm'
						elevate
						key='delete'
						onClick={onDelete}
					/>
				</td>
			)}
		</tr>
	);
};

InvoiceDeferredScheduleItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	onFormChange: PropTypes.func,
	onDelete: PropTypes.func,
	minScheduledDate: PropTypes.string,
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
};
InvoiceDeferredScheduleItem.defaultProps = {
	data: null,
	onFormChange: () => {},
	onDelete: () => {},
	minScheduledDate: null,
	setIsValid: () => {},
	isSubmitted: false,
};

const InvoiceDeferredSchedule = ({ invoiceId, onCancel, afterSubmit }) => {
	const userContext = useContext(UserContext);

	const [invoice, setInvoice] = useState(null);

	const [deferredIncomes, setDeferredIncomes] = useState([]);

	const [invalidDeferredIncomes, setInvalidDeferredIncomes] = useState([]);

	const [renewalStartDate, setRenewalStartDate] = useState(null);

	const [renewalEndDate, setRenewalEndDate] = useState(null);

	const [deferredFrequency, setDeferredFrequency] = useState(null);

	const [invoicingFrequencies, setInvoicingFrequencies] = useState([]);

	const [isContractOpen, setIsContractOpen] = useState(false);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [submitButtonAttr, setSubmitButtonAttr] = useState({
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	});

	const [isLoading, setIsLoading] = useState(true);

	const onFormChange = () => {
		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});
	};

	const onGenerateDates = () => {
		if (!renewalStartDate || !renewalEndDate || !deferredFrequency) return;

		const fromDate = moment(renewalStartDate)
			.utcOffset(0)
			.hour(0)
			.minute(0)
			.seconds(0)
			.millisecond(0);

		const toDate = moment(renewalEndDate)
			.utcOffset(0)
			.hour(0)
			.minute(0)
			.seconds(0)
			.millisecond(0);

		const numMonths = moment(toDate).diff(moment(fromDate), 'months');

		const numPeriods =
			deferredFrequency.value === _invoicingFrequencies.DAILY
				? moment(toDate).diff(moment(fromDate), 'days') - 1
				: numMonths / getInvoicingFrequencyValue(deferredFrequency.value);

		let dates = [];

		for (let i = 0; i <= numPeriods; i += 1) {
			dates.push(
				moment(fromDate).add(
					deferredFrequency.value === _invoicingFrequencies.DAILY
						? i
						: i * getInvoicingFrequencyValue(deferredFrequency.value),
					deferredFrequency.value === _invoicingFrequencies.DAILY ? 'day' : 'month'
				)
			);
		}

		const groupDayCount = {};

		if (
			deferredFrequency.value === _invoicingFrequencies.DAILY &&
			!userContext.data.user.company.settings.detailedDailyDeferred
		) {
			const groupedByMonth = {};

			dates.forEach(date => {
				if (!groupedByMonth[date.format('YYYY-MM')])
					groupedByMonth[date.format('YYYY-MM')] = date;

				if (!groupDayCount[date.format('YYYY-MM')])
					groupDayCount[date.format('YYYY-MM')] = 0;

				groupDayCount[date.format('YYYY-MM')] += 1;
			});

			dates = Object.values(groupedByMonth);
		}

		setDeferredIncomes(
			dates.map(scheduledDate => ({
				id: generateId(deferredIncomes),
				scheduledDate,
				itemDeferredIncomes: [],
				posted: false,
				groupDayCount: groupDayCount[scheduledDate.format('YYYY-MM')] || 1,
			}))
		);
	};

	const onAdd = () => {
		setDeferredIncomes(
			update(deferredIncomes, {
				$push: [
					{
						id: generateId(deferredIncomes),
						scheduledDate: null,
						itemDeferredIncomes: [],
						posted: false,
					},
				],
			})
		);
	};

	const onSave = () => {
		setIsSubmitted(true);

		if (invalidDeferredIncomes.length) return;

		setIsSubmitting(true);

		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_COLOR,
		});

		apiCall(
			'POST',
			'saveInvoiceDeferredIncomes',
			res => {
				afterSubmit(res);
				setInvoice(res);
				setRenewalStartDate(res.renewalStartDate);
				setRenewalEndDate(res.renewalEndDate);
				setDeferredIncomes(res.deferredIncomes);
				setIsLoading(false);
				setIsSubmitting(false);
				setSubmitButtonAttr({
					text: process.env.REACT_APP_SUBMIT_BUTTON_SAVED_TEXT,
					icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVED_ICON,
					color: process.env.REACT_APP_SUBMIT_BUTTON_SAVED_COLOR,
				});
			},
			err => {
				addErrorNotification(err.toString());
				setIsSubmitting(false);
				setSubmitButtonAttr({
					text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
					icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
					color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
				});
			},
			'',
			{
				invoiceId,
				renewalStartDate,
				renewalEndDate,
				deferredIncomes,
			}
		);
	};

	useEffect(() => {
		apiCall(
			'GET',
			'invoices',
			res => {
				setInvoice(res);
				setRenewalStartDate(res.renewalStartDate);
				setRenewalEndDate(res.renewalEndDate);
				setIsContractOpen(!res.renewalStartDate || !res.renewalEndDate);
				setDeferredIncomes(res.deferredIncomes);
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			invoiceId
		);
	}, [invoiceId]);

	useEffect(() => {
		apiCall(
			'GET',
			'invoicingFrequencies',
			res => {
				setInvoicingFrequencies(res);
			},
			err => {
				addErrorNotification(err.toString());
			},
			''
		);
	}, []);

	return (
		<div className='sdms-quick-panel sdms-quick-panel--right sdms-quick-panel--on'>
			<Portlet key={invoiceId} hasFrame fluid='fluid' className='sdms-marginless h-100'>
				<Portlet.Head>
					<Portlet.HeadLabelTitle portletIcon='Timer'>
						Deferred Schedule - Invoice
					</Portlet.HeadLabelTitle>
					<Portlet.HeadToolbarActions>
						<Button
							design='primary'
							text='New Date'
							onClick={onAdd}
							disabled={
								isLoading ||
								submitButtonAttr.text ===
									process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT ||
								!userContext.hasPermission('maintain_deferred_schedule')
							}
						/>
					</Portlet.HeadToolbarActions>
				</Portlet.Head>
				<Portlet.Body>
					<Portlet
						isCollapse
						isOpen={isContractOpen}
						hasFrame
						className='flex-grow-0 sdms-min-h-fit-content'>
						<Portlet.Head>
							<Portlet.HeadLabel>
								<h3 className='sdms-portlet__head-title'>Contract</h3>
							</Portlet.HeadLabel>
							<Portlet.HeadToolbarActions>
								<SVGIcon
									name='Angle-right'
									className={classNames(
										'sdms-transition',
										'sdms-cursor--pointer',
										{
											'sdms-rotate--90': isContractOpen,
										}
									)}
									onClick={() => setIsContractOpen(!isContractOpen)}
								/>
							</Portlet.HeadToolbarActions>
						</Portlet.Head>
						<Portlet.Body>
							<table className='table sdms-deferred-schedule-table'>
								<tr>
									<td className='sdms-deferred-schedule-col'>
										<DatePicker
											id='renewalStartDate'
											type='calendar'
											placeholder='Start Date'
											value={
												renewalStartDate !== null
													? convertDateToUTC(
															moment(renewalStartDate).toDate()
													  )
													: null
											}
											onChange={e => {
												if (e.target.value) {
													setRenewalStartDate(
														moment(renewalStartDate || undefined)
															.utc(false)
															.year(e.target.value.getFullYear())
															.month(e.target.value.getMonth())
															.date(e.target.value.getDate())
															.startOf('day')
															.toISOString()
													);
												} else {
													setRenewalStartDate(null);
												}
												setRenewalEndDate(null);
											}}
											noPermission={
												!userContext.hasPermission(
													'maintain_deferred_schedule'
												)
											}
										/>
									</td>
								</tr>
								<tr>
									<td className='sdms-deferred-schedule-col'>
										<DatePicker
											id='renewalEndDate'
											type='calendar'
											placeholder='End Date'
											value={
												renewalEndDate !== null
													? convertDateToUTC(
															moment(renewalEndDate).toDate()
													  )
													: null
											}
											onChange={e => {
												if (e.target.value) {
													setRenewalEndDate(
														moment(renewalEndDate || undefined)
															.utc(false)
															.year(e.target.value.getFullYear())
															.month(e.target.value.getMonth())
															.date(e.target.value.getDate())
															.startOf('day')
															.toISOString()
													);
												} else {
													setRenewalEndDate(null);
												}
											}}
											disabled={!renewalStartDate}
											minDate={
												renewalStartDate ? new Date(renewalStartDate) : null
											}
											noPermission={
												!userContext.hasPermission(
													'maintain_deferred_schedule'
												)
											}
										/>
									</td>
								</tr>
							</table>
							<table className='table sdms-deferred-schedule-table'>
								<tr>
									<td className='sdms-deferred-schedule-col'>
										<Selects
											options={invoicingFrequencies.filter(
												fr =>
													fr.value !== _invoicingFrequencies.UPFRONT &&
													fr.value !==
														_invoicingFrequencies.SAME_AS_RESERVATION
											)}
											placeholder='Frequency'
											value={deferredFrequency}
											onChange={e => setDeferredFrequency(e.target.value)}
											displayKey='value'
											disabled={
												isLoading ||
												submitButtonAttr.text ===
													process.env
														.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT ||
												!userContext.hasPermission(
													'maintain_deferred_schedule'
												) ||
												deferredIncomes.length ||
												!renewalStartDate ||
												!renewalEndDate
											}
										/>
									</td>
									<td className='sdms-deferred-schedule-col sdms-align-right'>
										<Button
											className={classNames(' sdms-mw-100', {
												'sdms-fading-dots': isSubmitting,
											})}
											label='info'
											icon='Clipboard-list'
											text='Generate Dates'
											size='sm'
											onClick={onGenerateDates}
											disabled={
												isLoading ||
												submitButtonAttr.text ===
													process.env
														.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT ||
												!userContext.hasPermission(
													'maintain_deferred_schedule'
												) ||
												deferredIncomes.length ||
												!renewalStartDate ||
												!renewalEndDate ||
												!deferredFrequency
											}
										/>
									</td>
								</tr>
							</table>
						</Portlet.Body>
					</Portlet>
					<ListBody
						className='table--everytime--scroll sdms-portlet__body--fit'
						responsive='scroll'>
						<ListTable>
							<colgroup>
								<col />
								<col />
								<col />
							</colgroup>
							<thead>
								<tr>
									<th>Dates</th>
									<th>Amount</th>
									<th>Posted</th>
								</tr>
							</thead>
							<tbody>
								{deferredIncomes
									.sort((a, b) => {
										if (a.scheduledDate === null) {
											return 1;
										}

										if (b.scheduledDate === null) {
											return -1;
										}

										if (a.scheduledDate === b.scheduledDate) {
											return 0;
										}

										return moment(a.scheduledDate).isAfter(
											moment(b.scheduledDate)
										)
											? 1
											: -1;
									})
									.map(di => (
										<InvoiceDeferredScheduleItem
											data={di}
											invoiceDate={invoice.invoiceDate || invoice.timeCreated}
											onFormChange={onFormChange}
											onDelete={() => {
												setDeferredIncomes(
													deferredIncomes.filter(_di => di.id !== _di.id)
												);
												setInvalidDeferredIncomes(
													invalidDeferredIncomes.filter(
														idi => idi !== di.id
													)
												);
											}}
											minScheduledDate={
												userContext.data.user.company.settings
													.accountingClosingDate
											}
											setIsValid={isValid => {
												if (isValid)
													setInvalidDeferredIncomes(
														invalidDeferredIncomes.filter(
															idi => idi !== di.id
														)
													);
												else
													setInvalidDeferredIncomes(
														update(invalidDeferredIncomes, {
															$push: [di.id],
														})
													);
											}}
											isSubmitted={isSubmitted}
										/>
									))}
							</tbody>
						</ListTable>
					</ListBody>
				</Portlet.Body>
				<Portlet.Foot tall='sm'>
					<table className='table sdms-deferred-schedule-table'>
						<tr>
							<td className='sdms-deferred-schedule-col'>
								<Button
									design='clean'
									text='Close'
									icon='Error-circle'
									size='sm'
									elevate
									onClick={onCancel}
									disabled={
										isLoading ||
										submitButtonAttr.text ===
											process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT
									}
								/>
							</td>
							<td className='sdms-deferred-schedule-col'>
								<Input
									value={priceFormatter(
										deferredIncomes.reduce(
											(partialSum, di) =>
												partialSum +
												di.itemDeferredIncomes.reduce(
													(ps, a) => ps + a.amount,
													0
												),
											0
										)
									)}
									onChange={() => {}}
									disabled
								/>
							</td>
							<td className='sdms-deferred-schedule-col sdms-align-right'>
								<Button
									className={classNames(' sdms-mw-100', {
										'sdms-fading-dots': isSubmitting,
									})}
									label={submitButtonAttr.color}
									text={submitButtonAttr.text}
									icon={submitButtonAttr.icon}
									size='sm'
									onClick={onSave}
									disabled={isLoading || isSubmitting}
								/>
							</td>
						</tr>
					</table>
				</Portlet.Foot>
			</Portlet>
		</div>
	);
};

InvoiceDeferredSchedule.propTypes = {
	invoiceId: PropTypes.number.isRequired,
	onCancel: PropTypes.func.isRequired,
	afterSubmit: PropTypes.func,
};

InvoiceDeferredSchedule.defaultProps = {
	afterSubmit: () => {},
};

export default InvoiceDeferredSchedule;
