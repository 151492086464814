import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
	required,
	phoneNumber,
	maxLength,
	email as emailValidation,
} from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import {
	numberParser,
	parseDatePickerChange,
	parseDatePickerValue,
	phoneNumberParser,
} from '../../../utils/helpers/helper';
import { modules } from '../../../utils/helpers/apiCall';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Input from '../../reusables/field/Input';
import Toggle from '../../reusables/field/Toggle';
import Loading from '../../reusables/template/Loading';
import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import ImageUpload from '../../reusables/field/ImageUpload';
import Selects from '../../reusables/field/Selects';
import Section from '../../reusables/layout/Section';
import useFeet from '../../../utils/hooks/useFeet';
import LengthInputGroup from '../../reusables/field/LengthInputGroup';
import DatePicker from '../../reusables/field/DatePicker';

const VehicleForm = ({
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	isLoading,
	onFormChange,
	submitButtonAttr,
	submit,
	enumRvTypes,
	enumVehicleMakes,
}) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [
		customer,
		customerOnChange,
		customerValRes,
		customerShowVal,
		setCustomerShowVal,
	] = useField(data, 'customer', onFormChange, [required], '', null);

	const [currentSpace, currentSpaceOnChange] = useField(data, 'currentSpace', onFormChange);

	const [images, imagesOnChange] = useField(data, 'images', onFormChange, [], []);

	const [rvType, rvTypeOnChange, rvTypeValRes, rvTypeShowVal, setRvTypeShowVal] = useField(
		data,
		'rvType',
		onFormChange,
		[required],
		'',
		null
	);

	const [make, makeOnChange] = useField(data, 'make', onFormChange, [], {}, null);

	const [model, modelOnChange] = useField(data, 'model', onFormChange);

	const [year, yearOnChange] = useField(data, 'year', onFormChange);

	const [hullColor, hullColorOnChange] = useField(data, 'hullColor', onFormChange);

	const [licensePlateNumber, licensePlateNumberOnChange] = useField(
		data,
		'licensePlateNumber',
		onFormChange
	);

	const [serialNumber, serialNumberOnChange] = useField(data, 'serialNumber', onFormChange);

	const [isMotorized, isMotorizedOnChange] = useField(
		data,
		'isMotorized',
		onFormChange,
		[],
		false
	);

	const [, loaOnChange] = useField(data, 'loa', onFormChange, [], 0, numberParser());

	const [loaFt, setLoaFt, loaIn, setLoaIn] = useFeet(data.loa, loaOnChange, isLoading);

	const [, beamOnChange] = useField(data, 'beam', onFormChange, [], 0, null);

	const [beamFt, setBeamFt, beamIn, setBeamIn] = useFeet(data.beam, beamOnChange, isLoading);

	const [, heightOnChange] = useField(data, 'height', onFormChange, [], 0, null);

	const [heightFt, setHeightFt, heightIn, setHeightIn] = useFeet(
		data.height,
		heightOnChange,
		isLoading
	);

	const [insuranceCompany, insuranceCompanyOnChange] = useField(
		data,
		'insuranceCompany',
		onFormChange
	);

	const [insurancePolicyNumber, insurancePolicyNumberOnChange] = useField(
		data,
		'insurancePolicyNumber',
		onFormChange
	);

	const [insuranceExpirationDate, insuranceExpirationDateOnChange] = useField(
		data,
		'insuranceExpirationDate',
		onFormChange,
		[],
		null
	);

	const [additionalInsurance, additionalInsuranceOnChange] = useField(
		data,
		'additionalInsurance',
		() => {},
		[],
		false
	);

	const [coiReceived, coiReceivedOnChange] = useField(data, 'coiReceived', () => {}, [], false);

	const [agentName, agentNameOnChange] = useField(data, 'agentName', () => {});

	const [
		agentPhone,
		agentPhoneOnChange,
		agentPhoneValRes,
		agentPhoneShowVal,
		setAgentPhoneShowVal,
	] = useField(data, 'agentPhone', () => {}, [maxLength(), phoneNumber], '', phoneNumberParser);

	const [
		agentEmail,
		agentEmailOnChange,
		agentEmailValRes,
		agentEmailShowVal,
		setAgentEmailShowVal,
	] = useField(data, 'agentEmail', () => {}, [maxLength(), emailValidation], null);

	const [inactive, inactiveOnChange] = useField(data, 'inactive', () => {}, [], false);

	const userContext = useContext(UserContext);

	const campgroundModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.CAMPGROUND)
	);

	useEffect(() => {
		if (!isLoading) data.module = campgroundModule.current;
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setCustomerShowVal();
			setRvTypeShowVal();
			setAgentPhoneShowVal();
			setAgentEmailShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setCustomerShowVal,
		setRvTypeShowVal,
		setAgentPhoneShowVal,
		setAgentEmailShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				customerValRes.isValid &&
				rvTypeValRes.isValid &&
				agentPhoneValRes.isValid &&
				agentEmailValRes.isValid
		);
	}, [
		nameValRes.isValid,
		customerValRes.isValid,
		rvTypeValRes.isValid,
		agentPhoneValRes.isValid,
		agentEmailValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.campground.default.text,
				path: pages.campground.dashboard.path,
			},
			{ title: pages.campground.text, path: pages.campground.path },
			{
				title: pages.campground.vehicles.text,
				path: pages.campground.vehicles.path,
			},
			{ title: name || `New ${pages.campground.vehicles.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.campground.vehicles.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<Section title='General Settings'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='name'
										label='Vehicle Name'
										id={data.id}
										valRes={nameValRes}
										showValidation={nameShowVal}
										colMd={6}>
										<Input
											type='text'
											placeholder='Name (Required)'
											value={name}
											onChange={nameOnChange}
											onBlur={setNameShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='customer'
										label='Customer'
										id={data.id}
										valRes={customerValRes}
										showValidation={customerShowVal}
										colMd={6}>
										<AsyncSelect
											options={data.customer ? [data.customer] : []}
											placeholder='Search and select customer (Required)'
											value={customer}
											onChange={customerOnChange}
											onBlur={setCustomerShowVal}
											route='customers'
											field='displayName'
											displayKey='displayName'
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='currentSpace'
										label='Current Space'
										id={data.id}
										colMd={6}>
										<Selects
											options={data.currentSpace ? [data.currentSpace] : []}
											placeholder='Current Space'
											value={currentSpace}
											onChange={currentSpaceOnChange}
											disabled
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField name='images' label='Images' id={data.id} colMd={6}>
										<ImageUpload
											media={images}
											multiple
											setMedia={media => {
												imagesOnChange({
													target: {
														value: media,
													},
												});
											}}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Vehicle Information'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='rvType'
										label='RV Type'
										id={data.id}
										valRes={rvTypeValRes}
										showValidation={rvTypeShowVal}
										colMd={6}>
										<Selects
											options={enumRvTypes}
											placeholder='RV type (Required)'
											value={rvType}
											onChange={rvTypeOnChange}
											onBlur={setRvTypeShowVal}
											displayKey='value'
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField name='make' label='Make' id={data.id} colMd={6}>
										<Selects
											options={enumVehicleMakes}
											placeholder='Make'
											value={make}
											onChange={makeOnChange}
											displayKey='value'
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField name='model' label='Model' id={data.id} colMd={6}>
										<Input
											type='text'
											placeholder='Model'
											value={model}
											onChange={modelOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField name='year' label='Year' id={data.id} colMd={6}>
										<Input
											type='number'
											withOutSpin
											placeholder='Year'
											value={year}
											onChange={yearOnChange}
											pattern={process.env.REACT_APP_INTEGER_PATTERN}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='hullColor'
										label='Color'
										id={data.id}
										colMd={6}>
										<Input
											type='text'
											placeholder='Color'
											value={hullColor}
											onChange={hullColorOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='licensePlateNumber'
										label='License Plate Number'
										id={data.id}
										colMd={6}>
										<Input
											type='text'
											placeholder='License Plate Number'
											value={licensePlateNumber}
											onChange={licensePlateNumberOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='serialNumber'
										label='Serial Number'
										id={data.id}
										colMd={6}>
										<Input
											type='text'
											placeholder='Serial Number'
											value={serialNumber}
											onChange={serialNumberOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='isMotorized'
										label='Motorized'
										id={data.id}
										colMd={6}>
										<Toggle
											value={isMotorized}
											onChange={isMotorizedOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Dimension Settings'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='loaFt'
										label='Length Overall'
										id={data.id}
										colLg={2}
										colMd={6}
										inFormDesign={false}>
										<LengthInputGroup
											ft={loaFt}
											ftOnChange={setLoaFt}
											inch={loaIn}
											inchOnChange={setLoaIn}
											placeHolder='Length Overall'
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='beamFt'
										label='Beam'
										id={data.id}
										colLg={2}
										colMd={6}
										inFormDesign={false}>
										<LengthInputGroup
											ft={beamFt}
											ftOnChange={setBeamFt}
											inch={beamIn}
											inchOnChange={setBeamIn}
											placeHolder='Beam'
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='heightFt'
										label='Height'
										id={data.id}
										colLg={2}
										colMd={6}
										inFormDesign={false}>
										<LengthInputGroup
											ft={heightFt}
											ftOnChange={setHeightFt}
											inch={heightIn}
											inchOnChange={setHeightIn}
											placeHolder='Height'
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Insurance Settings'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='insuranceCompany'
										label='Insurance Company'
										id={data.id}
										colMd={6}>
										<Input
											type='text'
											placeholder='Insurance Company'
											value={insuranceCompany}
											onChange={insuranceCompanyOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='policyNumber'
										label='Insurance Policy Number'
										id={data.id}
										colMd={6}>
										<Input
											type='text'
											placeholder='Insurance Policy Number'
											value={insurancePolicyNumber}
											onChange={insurancePolicyNumberOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='expirationDate'
										label='Insurance Expiration Date'
										id={data.id}
										colMd={6}>
										<DatePicker
											id='expirationDate'
											type='calendar'
											placeholder='Insurance Expiration Date'
											value={parseDatePickerValue(insuranceExpirationDate)}
											onChange={e =>
												insuranceExpirationDateOnChange({
													target: {
														value: parseDatePickerChange(
															e.target.value,
															insuranceExpirationDate
														),
													},
												})
											}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='agentName'
										label='Agent Name'
										id={data.id}
										colMd={6}>
										<Input
											type='text'
											placeholder='Agent Name'
											value={agentName}
											onChange={agentNameOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='agentPhone'
										label='Agent Phone'
										id={data.id}
										valRes={agentPhoneValRes}
										showValidation={agentPhoneShowVal}
										colMd={6}>
										<Input
											type='text'
											placeholder='(555) 555-5555'
											value={agentPhone}
											onChange={agentPhoneOnChange}
											onBlur={setAgentPhoneShowVal}
											mask={process.env.REACT_APP_PHONE_FORMAT}
											xAutoCompleteType='phone-national'
											autoComplete='phone-national'
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='agentEmail'
										label='Agent Email'
										valRes={agentEmailValRes}
										showValidation={agentEmailShowVal}
										id={data.id}
										colMd={6}>
										<Input
											type='text'
											placeholder='Agent Email'
											value={agentEmail}
											onChange={agentEmailOnChange}
											onBlur={setAgentEmailShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='isCoiReceived'
										label='COI Received'
										id={data.id}
										colMd={6}>
										<Toggle
											value={coiReceived}
											onChange={coiReceivedOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='isAdditionalInsurance'
										label='Additional Insured'
										id={data.id}
										colMd={6}>
										<Toggle
											value={additionalInsurance}
											onChange={additionalInsuranceOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Vehicle status'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='inactive'
										label='Inactive'
										id={data.id}
										colMd={6}>
										<Toggle value={inactive} onChange={inactiveOnChange} />
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};

VehicleForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		customer: PropTypes.object,
		currentSpace: PropTypes.object,
		image: PropTypes.arrayOf(PropTypes.object),
		rvType: PropTypes.object,
		make: PropTypes.object,
		model: PropTypes.string,
		year: PropTypes.string,
		hullColor: PropTypes.string,
		licensePlateNumber: PropTypes.string,
		serialNumber: PropTypes.string,
		isMotorized: PropTypes.bool,
		loa: PropTypes.number,
		beam: PropTypes.number,
		height: PropTypes.number,
		insuranceCompany: PropTypes.string,
		insurancePolicyNumber: PropTypes.string,
		insuranceExpirationDate: PropTypes.string,
		module: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	enumRvTypes: PropTypes.arrayOf(PropTypes.object),
	enumVehicleMakes: PropTypes.arrayOf(PropTypes.object),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
VehicleForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		customer: {},
		currentSpace: {},
		images: [],
		rvType: {},
		make: {},
		model: '',
		year: '',
		hullColor: '',
		licensePlateNumber: '',
		serialNumber: '',
		isMotorized: false,
		loa: 0,
		beam: 0,
		height: 0,
		insuranceCompany: '',
		insurancePolicyNumber: '',
		insuranceExpirationDate: '',
	},
	enumRvTypes: [],
	enumVehicleMakes: [],
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default VehicleForm;
