import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';

import useField from '../../../utils/hooks/useField';
import { maxLength, numeric, required } from '../../../utils/helpers/validation';

import Input from '../../reusables/field/Input';
import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Loading from '../../reusables/template/Loading';
import Toggle from '../../reusables/field/Toggle';
import useModifierGroup from '../../../utils/hooks/useModifierGroup';
import Section from '../../reusables/layout/Section';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import MultiSelect from '../../reusables/element/MultiSelect';
import { TableHead } from './ModifierGroupForm';
import Selects from '../../reusables/field/Selects';
import Portal from '../../reusables/layout/Portal';
import SVGIcon from '../../reusables/element/SVGIcon';
import { addErrorNotification, numberParser, useWindowSize } from '../../../utils/helpers/helper';
import UserContext from '../../../app/contexts/UserContext';
import apiCall from '../../../utils/helpers/apiCall';

const ModifierSection = ({
	data,
	onFormChange,
	isLoading,
	setIsValid,
	isSubmitted,
	prepStations,
	modifierPrefixes,
	updateInvalidSections,
	dispatch,
	isOpen,
	setOpenSection,
	index,
}) => {
	const windowSize = useWindowSize();

	const [
		name,
		nameOnChange,
		nameValRes,
		nameShowVal,
		setNameShowVal,
		assignedModifierPrefixes,
		selectPrefixOpen,
		setSelectPrefixOpen,
		addModifier,
		modifiers,
		renderModifier,
		updateAssignedPrefixes,
		invalidModifiers,
	] = useModifierGroup({
		data: data.modifierGroup,
		setIsValid,
		isSubmitted,
		prepStations,
		modifierPrefixes,
		setTitle: () => {},
		onFormChange,
		isLoading,
		isDisabled: data.isLinked,
	});

	const userContext = useContext(UserContext);
	const [title, titleOnChange, titleValRes, titleShowVal, setTitleShowVal] = useField(
		data,
		'title',
		onFormChange,
		[required, maxLength()]
	);

	const [
		abbreviation,
		abbreviationOnChange,
		abbreviationValRes,
		abbreviationShowVal,
		setAbbreviationShowVal,
	] = useField(data, 'abbreviation', onFormChange, [maxLength()]);

	const minQuantityNumberValidator = () => {
		return (value, setValRes) => {
			if (modifiers.length < minQuantity) {
				setValRes({
					isValid: false,
					status: 'notEnoughQuantityNumber',
					message: 'Min Quantity cannot exceed number of modifiers',
				});
				return false;
			}
			if (maxQuantity === '') return true;
			if (minQuantity > maxQuantity) {
				setValRes({
					isValid: false,
					status: 'higherMinQuantityNumber',
					message: 'Min Quantity Number must not be greater than Max Quantity!',
				});
				return false;
			}
			return true;
		};
	};

	const maxQuantityNumberValidator = () => {
		return (value, setValRes) => {
			if (maxQuantity === '') {
				validateMinQuantity(minQuantity);
				return true;
			}
			if (minQuantity > maxQuantity) {
				setValRes({
					isValid: false,
					status: 'lowerMinQuantityNumber',
					message: 'Max Quantity Number must not be lower than Min Quantity!',
				});
				return false;
			}
			return true;
		};
	};

	const [
		minQuantity,
		minQuantityOnChange,
		minQuantityValRes,
		minQuantityShowVal,
		setMinQuantityShowVal,
		validateMinQuantity,
	] = useField(
		data,
		'minQuantity',
		onFormChange,
		[required, numeric, minQuantityNumberValidator()],
		0,
		numberParser(false, 0)
	);

	const [
		maxQuantity,
		maxQuantityOnChange,
		maxQuantityValRes,
		maxQuantityShowVal,
		setMaxQuantityShowVal,
		validateMaxQuantity,
	] = useField(
		data,
		'maxQuantity',
		onFormChange,
		[numeric, maxQuantityNumberValidator()],
		'',
		numberParser(false)
	);

	const [allowCustom, allowCustomOnChange] = useField(
		data,
		'allowCustom',
		onFormChange,
		[],
		false
	);

	const [canReuse, canReuseOnChange] = useField(data, 'canReuse', onFormChange, [], false);

	const minQuantityRef = useRef();
	// update product modifier section data
	useEffect(() => {
		validateMaxQuantity(maxQuantity);
		validateMinQuantity(minQuantity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [maxQuantityValRes.isValid, minQuantityValRes.isValid]);

	useEffect(() => {
		dispatch({ type: 'update', payload: data });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [title, minQuantity]);

	useEffect(() => {
		validateMinQuantity(minQuantity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modifiers]);

	useEffect(() => {
		if (data.modifierGroup.name !== name)
			nameOnChange({ target: { name, value: data.modifierGroup.name } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.modifierGroup]);

	// update invalid sections
	useEffect(() => {
		const isValid =
			titleValRes.isValid &&
			abbreviationValRes.isValid &&
			minQuantityValRes.isValid &&
			maxQuantityValRes.isValid &&
			nameValRes.isValid &&
			invalidModifiers.length === 0;
		updateInvalidSections(data.id, isValid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		titleValRes.isValid,
		abbreviationValRes.isValid,
		minQuantityValRes.isValid,
		maxQuantityValRes.isValid,
		nameValRes.isValid,
		invalidModifiers,
	]);
	// update validation visibility
	useEffect(() => {
		if (isSubmitted) {
			setTitleShowVal();
			setMinQuantityShowVal();
			setMaxQuantityShowVal();
			setAbbreviationShowVal();
		}
	}, [
		isSubmitted,
		setTitleShowVal,
		setMinQuantityShowVal,
		setMaxQuantityShowVal,
		setAbbreviationShowVal,
	]);

	const [modifierGroups, setModifierGroups] = useState([]);

	const [isSelectModifierGroupModalOpen, setIsSelectModifierGroupModalOpen] = useState(null);

	const [isModifierGroupsLoading, setIsModifierGroupsLoading] = useState(false);

	useEffect(() => {
		if (isSelectModifierGroupModalOpen) {
			setIsModifierGroupsLoading(true);
			apiCall(
				'GET',
				'modifierGroups',
				res => {
					setModifierGroups(res);
					setIsModifierGroupsLoading(false);
				},
				err => {
					addErrorNotification(err.toString());

					setIsModifierGroupsLoading(false);
				},
				'',
				null,
				{
					pagination: false,
				}
			);
		}
	}, [isSelectModifierGroupModalOpen]);

	const [
		selectedModifierGroup,
		selectedModifierGroupOnChange,
		selectedModifierGroupValRes,
	] = useField({}, 'selectedModifierGroup', () => {}, [required], null);

	const [selectedModifierGroupShowVal, setSelectedModifierGroupShowVal] = useState(false);

	const ref = useRef(null);
	const [allowDrag, setAllowDrag] = useState(false);
	const [, drop] = useDrop({
		accept: 'ModifierSection',
		hover(item, monitor) {
			if (!ref.current) return;
			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) return;
			const hoverBoundingRect = ref.current.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			const dragBoundingRect = item.ref.current.getBoundingClientRect();
			const dragUp = dragBoundingRect.top > hoverBoundingRect.top;
			const dragDown = dragBoundingRect.bottom < hoverBoundingRect.bottom;

			if (dragUp && dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
			if (dragDown && dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
			dispatch({ type: 'moveModifierSection', payload: { from: dragIndex, to: hoverIndex } });
			item.index = hoverIndex;
		},
	});
	const [{ isDragging }, drag] = useDrag({
		item: { type: 'ModifierSection', id: data.id, index, ref },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
		canDrag: () => allowDrag,
		end: () => {
			setAllowDrag(false);
		},
	});
	drag(drop(ref));

	const [cursorGrabStatus, setCursorGrabStatus] = useState(false);

	return (
		<Portlet
			ref={ref}
			key={data.id}
			className={classNames('sdms-portlet--section', 'sdms-modifier--draggable', {
				'sdms-modifier--dragging': isDragging,
			})}
			id={`modifiers_section_${data.id.toString()}`}
			isCollapse
			isOpen={isOpen}
			border>
			<Portlet.Head
				noPermission={!userContext.hasPermission('view_modifiers_groups')}
				wrapMaxSize='lg'>
				<Portlet.HeadLabel>
					<SVGIcon
						className={classNames(
							'sdms-cursor--grab',
							{
								'sdms-cursor--grabbing': cursorGrabStatus,
							},
							'sdms-hidden-tablet-and-mobile'
						)}
						name='Menu'
						size={32}
						onMouseDown={() => {
							setAllowDrag(true);
							setCursorGrabStatus(true);
						}}
						onBlur={() => setCursorGrabStatus(false)}
					/>
					<Portlet.Separator className='sdms-hidden-tablet-and-mobile' />
					<h3
						className='sdms-portlet__head-title sdms-link sdms-link--dark sdms-pr0'
						role='presentation'
						onClick={() => {
							if (userContext.hasPermission('view_modifiers_groups'))
								setOpenSection(isOpen ? null : `modifiers_section_${data.id}`);
						}}>
						{title || 'New Modifier Section'}
					</h3>
				</Portlet.HeadLabel>
				<Portlet.HeadToolbarActions>
					<Button
						className='sdms-margin-r-15'
						icon='Clipboard-list'
						design='brand'
						outline
						text='Delete'
						key='deleteSection'
						size='sm'
						noPermission={!userContext.hasPermission('delete_modifiers_group')}
						onClick={() => {
							updateInvalidSections(data.id, true);
							dispatch({ type: 'delete', payload: data.id });
						}}
					/>
					<SVGIcon
						name='Angle-double-right'
						className={classNames('sdms-transition', 'sdms-cursor--pointer', {
							'sdms-rotate--90': isOpen,
						})}
						onClick={() => {
							if (userContext.hasPermission('view_modifiers_groups'))
								setOpenSection(isOpen ? null : `modifiers_section_${data.id}`);
						}}
					/>
				</Portlet.HeadToolbarActions>
			</Portlet.Head>
			<Portlet.Body
				className='sdms-pb-0'
				noPermission={!userContext.hasPermission('edit_modifiers_group')}>
				<Section last>
					<Section.Body>
						<FormGroup row>
							<Loading isLoading={isLoading}>
								<FormField
									name='title'
									label='Title'
									id={data.id}
									valRes={titleValRes}
									showValidation={titleShowVal}
									colXl={2}
									isLast
									inFormDesign={false}>
									<Input
										type='text'
										placeholder='Modifier Section Name (Required)'
										value={title}
										onChange={titleOnChange}
										onBlur={setTitleShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='abbreviation'
									label='Kitchen Abbreviation'
									id={data.id}
									colXl={2}
									valRes={abbreviationValRes}
									showValidation={abbreviationShowVal}
									isLast
									inFormDesign={false}>
									<Input
										type='text'
										placeholder='Kitchen Abbreviation'
										value={abbreviation}
										onChange={abbreviationOnChange}
										onBlur={setAbbreviationShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='allowCustom'
									label='Allow Custom'
									id={data.id}
									colXl={2}
									isLast
									inFormDesign={false}>
									<Toggle value={allowCustom} onChange={allowCustomOnChange} />
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='canReuse'
									label='Can Reuse'
									id={data.id}
									colXl={2}
									isLast
									inFormDesign={false}>
									<Toggle value={canReuse} onChange={canReuseOnChange} />
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='minQuantity'
									label='Min Qty'
									id={data.id}
									valRes={minQuantityValRes}
									showValidation={minQuantityShowVal}
									colXl={2}
									isLast
									inFormDesign={false}>
									<Input
										type='text'
										ref={minQuantityRef}
										placeholder='Min Quantity (Required)'
										value={minQuantity.toString()}
										onChange={minQuantityOnChange}
										onBlur={setMinQuantityShowVal}
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='maxQuantity'
									label='Max Qty'
									id={data.id}
									valRes={maxQuantityValRes}
									showValidation={maxQuantityShowVal}
									colXl={2}
									isLast
									inFormDesign={false}>
									<Input
										type='text'
										placeholder='Max Quantity'
										value={maxQuantity.toString()}
										onChange={maxQuantityOnChange}
										onBlur={setMaxQuantityShowVal}
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Portlet
					style={{
						height:
							modifiers.length * 57 +
							60 +
							64 +
							20 +
							(windowSize.width < 1400 ? 94 : 0),
					}}
					className='sdms-list-layout sdms-modifier sdms-list-scroll'
					fluid='fluid'
					border>
					<Portlet.Head wrapMaxSize='xl'>
						<Portlet.HeadLabel>
							<div className='row align-items-center'>
								<Loading isLoading={isLoading}>
									<FormField
										className='sdms-marginless sdms-pl0'
										name='name'
										id={data.id}
										valRes={nameValRes}
										showValidation={nameShowVal}
										inFormDesign={false}
										colLg
										isLast>
										<Input
											type='text'
											placeholder='Modifier Group Name (Required)'
											value={name}
											onChange={nameOnChange}
											onBlur={setNameShowVal}
											prepend='Name'
											disabled={data.isLinked}
										/>
									</FormField>
								</Loading>
								<div className='sdms-portlet__head-desc col sdms-pt15-tablet-and-mobile sdms-mr-0-tablet-and-mobile'>
									<div className='row'>
										<Button
											className='col'
											icon='Duplicate'
											design='clean'
											text='Load Group'
											toolTip='Copy Existing Group'
											key='copyGroup'
											size='sm'
											disabled={data.isLinked}
											noPermission={
												!userContext.hasPermission('edit_modifiers_group')
											}
											onClick={() => {
												setIsSelectModifierGroupModalOpen('copy');
											}}
										/>
										{data.isLinked ? (
											<Button
												className='sdms-mr-0 col'
												icon='Attachment#2'
												label='danger'
												text='UnLink'
												toolTip='Unlink Group'
												size='sm'
												onClick={() => {
													dispatch({
														type: 'clearModifierGroup',
														payload: data.id,
													});
												}}
											/>
										) : (
											<Button
												className='sdms-mr-0 col'
												icon='Attachment#1'
												design='clean'
												text='Link Group'
												toolTip='Link Existing Group'
												key='linkGroup'
												size='sm'
												// active={data.isLinked}
												noPermission={
													!userContext.hasPermission(
														'edit_modifiers_group'
													)
												}
												onClick={() => {
													dispatch({
														type: 'clearModifierGroup',
														payload: data.id,
													});
													setIsSelectModifierGroupModalOpen('link');
												}}
											/>
										)}
									</div>
								</div>
							</div>
						</Portlet.HeadLabel>
						<Portlet.HeadToolbar>
							<Portlet.HeadActions className='sdms-last-margin--h d-flex'>
								<Button
									icon='Clipboard-list'
									design='info'
									outline
									text='Prefixes'
									key='selectPrefixes'
									size='sm'
									disabled={data.isLinked}
									onClick={() => setSelectPrefixOpen(true)}
								/>
								<Button
									className='sdms-mr-0'
									label='brand'
									icon='Plus'
									text='New Modifier'
									key='newModifier'
									size='sm'
									disabled={data.isLinked}
									noPermission={
										!userContext.hasPermission('edit_modifiers_group')
									}
									onClick={() => addModifier()}
								/>
							</Portlet.HeadActions>
						</Portlet.HeadToolbar>
					</Portlet.Head>
					<Portlet.Body className='sdms-portlet__body--fit'>
						<div className='sdms-modifier--container'>
							{/* Title */}
							<table className='table'>
								<TableHead data={assignedModifierPrefixes} />
								{/* Row */}
								<tbody>{modifiers.map(modifier => renderModifier(modifier))}</tbody>
							</table>
						</div>
					</Portlet.Body>
				</Portlet>
				{/* Select Prefix */}
				<Portal>
					<Popup
						contentStyle={{
							padding: 0,
							background: 'unset',
							border: 'unset',
						}}
						closeOnDocumentClick={false}
						modal
						lockScroll
						open={selectPrefixOpen}
						onClose={() => setSelectPrefixOpen(false)}>
						<Portlet>
							<Portlet.Head>
								<Portlet.HeadLabelTitle portletIcon='Clipboard-list'>
									Select Modifier
								</Portlet.HeadLabelTitle>
							</Portlet.Head>
							<Portlet.Body>
								<MultiSelect
									data={
										modifierPrefixes &&
										modifierPrefixes.sort((a, b) =>
											a.sortOrder > b.sortOrder ? 1 : -1
										)
									}
									titleProp='name'
									value={assignedModifierPrefixes}
									onChange={e => updateAssignedPrefixes(e.target.value)}
								/>
							</Portlet.Body>
							<Portlet.Foot tall='sm'>
								<div className='col'>
									<Button
										design='clean'
										text='Cancel'
										icon='Error-circle'
										size='sm'
										elevate
										onClick={() => {
											setSelectPrefixOpen(false);
										}}
									/>
								</div>
								<div className='col-auto'>
									<Button
										design='brand'
										icon='Save'
										size='sm'
										elevate
										text='Select'
										onClick={() => {
											setSelectPrefixOpen(false);
										}}
									/>
								</div>
							</Portlet.Foot>
						</Portlet>
					</Popup>
				</Portal>
			</Portlet.Body>
			<Portal>
				<Popup
					contentStyle={{
						padding: 0,
						background: 'unset',
						border: 'unset',
					}}
					closeOnDocumentClick={false}
					modal
					lockScroll
					open={isSelectModifierGroupModalOpen !== null}
					onClose={() => {
						setIsSelectModifierGroupModalOpen(null);
						setSelectedModifierGroupShowVal(false);
					}}>
					<Portlet>
						<Portlet.Head>
							<Portlet.HeadLabelTitle portletIcon='Clipboard-list'>
								Select Modifier Group
							</Portlet.HeadLabelTitle>
						</Portlet.Head>
						<Portlet.Body>
							<FormField
								name='selectedModifierGroup'
								label='Selected Modifier Group'
								id={data.id}
								valRes={selectedModifierGroupValRes}
								showValidation={selectedModifierGroupShowVal}
								col={6}
								isLast>
								<Selects
									options={modifierGroups.filter(
										mg => mg.id !== data.modifierGroup.id
									)}
									placeholder='Select Modifier Group'
									value={selectedModifierGroup}
									onChange={selectedModifierGroupOnChange}
									onBlur={() => setSelectedModifierGroupShowVal(true)}
									disabled={isModifierGroupsLoading}
								/>
							</FormField>
						</Portlet.Body>
						<Portlet.Foot tall='sm'>
							<div className='col'>
								<Button
									design='clean'
									text='Cancel'
									icon='Error-circle'
									size='sm'
									elevate
									onClick={() => {
										setIsSelectModifierGroupModalOpen(null);
										setSelectedModifierGroupShowVal(false);
									}}
								/>
							</div>
							<div className='col-auto'>
								<Button
									design='brand'
									icon='Save'
									size='sm'
									elevate
									text='Select'
									onClick={() => {
										if (!selectedModifierGroupValRes.isValid) {
											setSelectedModifierGroupShowVal(true);
											return;
										}

										dispatch({
											type:
												isSelectModifierGroupModalOpen === 'link'
													? 'linkGroup'
													: 'copyGroup',
											payload: {
												id: data.id,
												source: selectedModifierGroup,
											},
										});

										selectedModifierGroupOnChange({
											target: {
												name: 'selectedModifierGroup',
												value: null,
											},
										});

										setIsSelectModifierGroupModalOpen(null);

										setSelectedModifierGroupShowVal(false);
									}}
								/>
							</div>
						</Portlet.Foot>
					</Portlet>
				</Popup>
			</Portal>
		</Portlet>
	);
};
ModifierSection.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		title: PropTypes.string,
		abbreviation: PropTypes.string,
		minQuantity: PropTypes.number,
		maxQuantity: PropTypes.number,
		modifierGroup: PropTypes.object,
		allowCustom: PropTypes.bool,
		sortOrder: PropTypes.number,
		isLinked: PropTypes.bool,
	}),
	onFormChange: PropTypes.func,
	isLoading: PropTypes.bool,
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	prepStations: PropTypes.arrayOf(PropTypes.object),
	modifierPrefixes: PropTypes.arrayOf(PropTypes.object),
	updateInvalidSections: PropTypes.func.isRequired,
	dispatch: PropTypes.func.isRequired,
	isOpen: PropTypes.bool,
	setOpenSection: PropTypes.func,
	index: PropTypes.number,
};
ModifierSection.defaultProps = {
	data: { id: 0 },
	onFormChange: () => {},
	isLoading: false,
	setIsValid: () => {},
	isSubmitted: false,
	prepStations: [],
	modifierPrefixes: [],
	isOpen: false,
	setOpenSection: () => {},
	index: 0,
};

export default ModifierSection;
