import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SVGIcon from './SVGIcon';

export const getInfoStatus = (_min, _max, _value) => {
	if (_max) {
		if (_max === _min) {
			if (_value === _max) return 'success';
			return 'danger';
		}
		if (_min === 0) return 'info';
		if (_min <= _value) return 'success';
		return 'danger';
	}
	if (_min === 0) return null;
	if (_min <= _value) return 'success';
	return 'danger';
};

const FlagStatus = ({ min, max, selected }) => {
	if (max) {
		if (max === min)
			return (
				<div>
					<div
						className={classNames('sdms-progress', [
							`sdms-progress--${getInfoStatus(min, max, selected)}`,
						])}>
						<progress max={min} value={selected} />
						<SVGIcon
							name='Flag'
							fill={`var(--${getInfoStatus(min, max, selected)})`}
							style={{
								transform: `translateX(calc(${(10 * min) / max}em - 5px))`,
							}}
						/>
					</div>
					<span>{`Choose ${min}`}</span>
				</div>
			);
		if (min === 0)
			return (
				<div>
					<div className={classNames('sdms-progress', [`sdms-progress--success`])}>
						<progress max={max} value={selected} />
						<SVGIcon name='Flag' fill='var(--success)' />
					</div>
					<span>{`Choose up to ${max}`}</span>
				</div>
			);
		return (
			<div>
				<div
					className={classNames('sdms-progress', [
						`sdms-progress--${getInfoStatus(min, max, selected)}`,
					])}>
					<progress max={max} value={selected} />
					<SVGIcon
						name='Flag'
						fill={`var(--${getInfoStatus(min, max, selected)})`}
						style={{
							transform: `translateX(calc(${(10 * min) / max}em - 5px))`,
						}}
					/>
				</div>
				<span>{`Choose ${min} to ${max}`}</span>
			</div>
		);
	}

	if (min === 0) return '';

	return (
		<div>
			<div
				className={classNames('sdms-progress', [
					`sdms-progress--${getInfoStatus(min, max, selected)}`,
				])}>
				<progress max={selected + 1} value={selected} />
				<SVGIcon
					name='Flag'
					fill={`var(--${getInfoStatus(min, max, selected)})`}
					style={{
						transform: `translateX(calc(${(10 * min) / (min + 1)}em - 5px))`,
					}}
				/>
			</div>
			<span>{`Choose ${min}`}</span>
		</div>
	);
};
FlagStatus.propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
	selected: PropTypes.number,
};
FlagStatus.defaultProps = {
	min: null,
	max: null,
	selected: null,
};

export default FlagStatus;
