import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { addErrorNotification, priceFormatter } from '../../../utils/helpers/helper';
import apiCall from '../../../utils/helpers/apiCall';

import Portlet from '../layout/Portlet';
import Search from './Search';
import { Item, Back, SearchMore } from './ProductGridElements';
import Loading from '../template/Loading';
import Button from './Button';

const Product = ({ order, text, price, onClick, media, isDisabled, onlyGrid }) => (
	<Item
		order={order}
		text={text}
		price={price}
		onClick={onClick}
		media={media}
		isDisabled={isDisabled}
		onlyGrid={onlyGrid}
		showTooltip
	/>
);
Product.propTypes = {
	order: PropTypes.number.isRequired,
	text: PropTypes.string.isRequired,
	price: PropTypes.string.isRequired,
	media: PropTypes.string,
	onClick: PropTypes.func,
	isDisabled: PropTypes.bool,
	onlyGrid: PropTypes.bool,
};
Product.defaultProps = {
	onClick: () => {},
	isDisabled: false,
	media: null,
	onlyGrid: false,
};

const ReservationAdvanceGrid = ({
	isDisable,
	onClick,
	onlyGrid,
	module,
	customFilters,
	enableSearchMore,
	onBack,
}) => {
	const [searchText, setSearchText] = useState('');

	const [items, setItems] = useState([]);

	const [isLoading, setIsLoading] = useState(false);

	const [isProductLoading, setIsProductLoading] = useState(false);

	const [products, setProducts] = useState([]);

	const showSearchMore = useRef(true);

	const abortController = useRef();

	// search and get pos products.
	const searchMore = useCallback(() => {
		if (searchText === '') return;

		showSearchMore.current = false;

		apiCall(
			'GET',
			'posProducts',
			res => {
				setItems(res);
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			null,
			{
				product_search: searchText,
				disableSearchMore: false,
				pagination: false,
				inactive: false,
			}
		);
	}, [searchText]);

	const loadProduct = product => {
		if (product['@type'] === 'SdmsProductPos') {
			onClick(product);
		} else {
			setIsProductLoading(true);
			apiCall(
				'GET',
				'productBookings',
				res => {
					onClick(res);
				},
				err => {
					addErrorNotification(err.toString());
					setIsProductLoading(false);
				},
				product.id,
				null
			);
		}
	};

	useEffect(() => {
		setIsLoading(true);
		abortController.current = new AbortController();
		apiCall(
			'GET',
			'productBookings',
			res => {
				setProducts(res);
				setIsLoading(false);
			},
			err => {
				if (err.toString().search('AbortError') === -1) {
					addErrorNotification(err.toString());
					setIsLoading(false);
				}
			},
			'',
			null,
			{
				pagination: false,
				'groups[]': 'product-transient:grid',
				'module.value': module,
				'bookingType.inactive': false,
				inactive: false,
				...customFilters,
			},
			abortController.current.signal
		);

		return () => {
			if (abortController.current) abortController.current.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setItems(products);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [products]);

	useEffect(() => {
		setItems(
			searchText === ''
				? products
				: products.filter(p => p.name.toLowerCase().search(searchText.toLowerCase()) > -1)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText]);

	return (
		<Portlet fluid='fluid'>
			<Portlet.Head>
				<Portlet.HeadLabel>
					<Search
						className='sdms-mr-0'
						inPortlet
						setSearchText={text => {
							showSearchMore.current = true;
							setSearchText(text);
						}}
						searchText={searchText}
					/>
				</Portlet.HeadLabel>
				{onBack && (
					<Portlet.HeadToolbarActions>
						<Button
							className='sdms-ml-10'
							design='default'
							icon='Subtract'
							size='sm'
							text='Back To Reservation'
							onClick={onBack}
						/>
					</Portlet.HeadToolbarActions>
				)}
			</Portlet.Head>
			<Portlet.Body>
				<div
					className={classNames('row', 'sdms-t-product-grid', {
						'sdms-t-product-grid--disabled': isLoading,
					})}>
					{searchText !== '' && (
						<Back
							isAfterSearch={searchText !== ''}
							onClick={() => {
								setSearchText('');
								setItems(products);
								showSearchMore.current = true;
							}}
							onlyGrid={onlyGrid}
						/>
					)}
					{isLoading ? (
						<Loading isLoading type='product' onlyGrid={onlyGrid} />
					) : (
						items
							.sort((a, b) => a.name.localeCompare(b.name))
							.map((i, index) => (
								<Product
									order={1 + index}
									key={i.id}
									text={i.name}
									onClick={() => loadProduct(i)}
									price={priceFormatter(i.price)}
									media={
										(i?.productImages || []).length > 0
											? i.productImages[0].thumb
											: null
									}
									isDisabled={isDisable || isProductLoading}
									onlyGrid={onlyGrid}
								/>
							))
					)}
					{searchText !== '' && showSearchMore.current && enableSearchMore && (
						<SearchMore onClick={() => searchMore()} onlyGrid={onlyGrid} />
					)}
				</div>
			</Portlet.Body>
		</Portlet>
	);
};
ReservationAdvanceGrid.propTypes = {
	onClick: PropTypes.func,
	isDisable: PropTypes.bool,
	onlyGrid: PropTypes.bool,
	module: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	customFilters: PropTypes.object,
	enableSearchMore: PropTypes.bool,
	onBack: PropTypes.func,
};
ReservationAdvanceGrid.defaultProps = {
	onClick: () => {},
	isDisable: false,
	onlyGrid: false,
	customFilters: {},
	enableSearchMore: true,
	onBack: null,
};

export default ReservationAdvanceGrid;
