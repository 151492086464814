import React from 'react';
import Badge from '../element/Badge';

const NullBadge = () => {
	return (
		<Badge design='danger' isDot>
			N/A
		</Badge>
	);
};

export default NullBadge;
