import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import Portlet from '../layout/Portlet';

import Button from '../element/Button';
import Portal from '../layout/Portal';
import Textarea from '../field/Textarea';

import DialogBox from '../element/DialogBox';
import FormField from '../template/FormField';

const OrderVoidModal = ({ isOpen, onVoid, onClose, withNote }) => {
	if (withNote)
		return (
			<Portal>
				<Popup
					open={isOpen}
					closeOnDocumentClick={false}
					lockScroll
					modal
					onClose={onClose}
					contentStyle={{
						padding: 0,
						background: 'unset',
						border: 'unset',
						maxWidth: '640px',
					}}>
					<OrderVoidModalContent onVoid={onVoid} onClose={onClose} />
				</Popup>
			</Portal>
		);

	return (
		<DialogBox
			open={isOpen}
			title='Are you sure?'
			content='You can not undo this action'
			type='question'
			onClose={onClose}>
			<Button
				className='sdms-font-transform-c'
				label='danger'
				icon='Trash'
				text='Yes, Void!'
				onClick={() => {
					onVoid('');
				}}
			/>
			<Button
				className='sdms-font-transform-c'
				design='clean'
				icon='Error-circle'
				text={`No, Don't void!`}
				onClick={onClose}
			/>
		</DialogBox>
	);
};
OrderVoidModal.propTypes = {
	isOpen: PropTypes.bool,
	onVoid: PropTypes.func,
	onClose: PropTypes.func,
	withNote: PropTypes.bool,
};
OrderVoidModal.defaultProps = {
	isOpen: false,
	onVoid: () => {},
	onClose: () => {},
	withNote: () => {},
};

const OrderVoidModalContent = ({ onVoid, onClose }) => {
	const [note, setNote] = useState('');

	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Trash'>Void Order</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<FormField name='voidReason' label='Reason'>
					<Textarea
						value={note}
						onChange={event => {
							setNote(event.target.value);
						}}
						className='sdms-h-100-mobile'
					/>
				</FormField>
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='danger'
						icon='Trash'
						text='Void'
						size='sm'
						onClick={() => onVoid(note)}
						disabled={!note}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
OrderVoidModalContent.propTypes = {
	onVoid: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default OrderVoidModal;
