import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TimePicker from 'rc-time-picker/lib/TimePicker';
import SVGIcon from '../element/SVGIcon';

const TimePickerInput = ({
	className,
	popupClassName,
	size,
	format,
	minuteStep,
	defaultValue,
	showSecond,
	value,
	onChange,
	use12Hours,
	onClose,
	placeholder,
	disabled,
	disabledHours,
	disabledMinutes,
	hideDisabledOptions,
	placement,
	allowEmpty,
}) => {
	return (
		<TimePicker
			className={classNames(className, 'form-control')}
			popupClassName={classNames(popupClassName)}
			popupStyle={{ width: size.width - 20 }}
			minuteStep={minuteStep}
			format={format}
			clearIcon={<SVGIcon name='Close#2' className='sdms-svg-icon--fill' />}
			defaultValue={defaultValue}
			showSecond={showSecond}
			value={value}
			onChange={onChange}
			use12Hours={use12Hours}
			onClose={() => onClose()}
			placeholder={placeholder}
			disabled={disabled}
			disabledHours={disabledHours}
			disabledMinutes={disabledMinutes}
			hideDisabledOptions={hideDisabledOptions}
			placement={placement}
			allowEmpty={allowEmpty}
		/>
	);
};
TimePickerInput.propTypes = {
	className: PropTypes.string,
	popupClassName: PropTypes.string,
	size: PropTypes.shape({
		width: PropTypes.number,
	}),
	format: PropTypes.string,
	minuteStep: PropTypes.number,
	// eslint-disable-next-line react/forbid-prop-types
	defaultValue: PropTypes.object,
	showSecond: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	value: PropTypes.object,
	onChange: PropTypes.func,
	use12Hours: PropTypes.bool,
	onClose: PropTypes.func,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	disabledHours: PropTypes.func,
	disabledMinutes: PropTypes.func,
	hideDisabledOptions: PropTypes.bool,
	placement: PropTypes.oneOf(['topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
	allowEmpty: PropTypes.bool,
};
TimePickerInput.defaultProps = {
	className: '',
	popupClassName: '',
	size: {
		width: 140,
	},
	format: 'h:mm a',
	minuteStep: 15,
	defaultValue: null,
	showSecond: true,
	value: null,
	onChange: () => {},
	use12Hours: false,
	onClose: () => {},
	placeholder: '',
	disabled: false,
	disabledHours: () => {},
	disabledMinutes: () => {},
	hideDisabledOptions: false,
	placement: 'bottomLeft',
	allowEmpty: true,
};

export default TimePickerInput;
