import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import SignatureCanvas from 'react-signature-canvas';
import TextSignature from 'text-signature';

import Button from '../element/Button';
import Portal from '../layout/Portal';
import Portlet from '../layout/Portlet';
import Input from '../field/Input';

const tabs = {
	drawing: 0,
	typing: 1,
};

const SIGNATURE_WIDTH = 605;

const SIGNATURE_HEIGHT = 250;

const TEXT_SIGNATURE_OPTIONS = {
	width: SIGNATURE_WIDTH,
	height: SIGNATURE_HEIGHT,
	paddingX: 0,
	paddingY: (SIGNATURE_HEIGHT * 3) / 5,
	font: ['36px', "'Homemade Apple'"],
	color: 'black',
	textString: ' ',
	customFont: {
		name: "'Homemade Apple'",
		url: `${window.location.origin}/assets/fonts/signature.css`,
	},
	canvasTargetDom: '#signature-canvas',
};

const EsignModal = ({ element, onClose }) => {
	const [tab, setTab] = useState(tabs.drawing);

	const [signatureText, setSignatureText] = useState('');

	const [signatureTextSrc, setSignatureTextSrc] = useState(null);

	const [canSubmit, setCanSubmit] = useState(false);

	const drawingRef = useRef(null);

	const textSignature = useRef(new TextSignature(TEXT_SIGNATURE_OPTIONS));

	const removeAllChildNodes = parent => {
		while (parent.firstChild) parent.removeChild(parent.firstChild);
	};

	const resetContainer = () => {
		removeAllChildNodes(element);

		const placeholderNode = document.createElement('p');

		placeholderNode.innerText = 'Signature area';

		placeholderNode.classList.add('signature-input-placeholder');

		element.appendChild(placeholderNode);
	};

	const clearDrawing = () => {
		if (drawingRef.current) drawingRef.current.clear();
	};

	const clearTyping = () => {
		setSignatureText('');

		setSignatureTextSrc(null);
	};

	const resetModal = () => {
		setTab(tabs.drawing);

		clearTyping();

		clearDrawing();
	};

	const onClear = () => {
		resetContainer();

		clearDrawing();

		clearTyping();

		setCanSubmit(false);
	};

	const onApply = () => {
		const imageNode = document.createElement('img');

		if (tab === tabs.drawing) {
			imageNode.setAttribute('src', drawingRef.current.toDataURL('base64'));

			imageNode.setAttribute('type', tabs.drawing);
		} else if (tab === tabs.typing) {
			imageNode.setAttribute('src', signatureTextSrc);

			imageNode.setAttribute('type', tabs.typing);

			imageNode.setAttribute('value', signatureText);
		}

		imageNode.setAttribute('style', `width: ${(SIGNATURE_WIDTH * 3) / 5}px;`);

		removeAllChildNodes(element);

		element.appendChild(imageNode);

		resetModal();

		onClose();
	};

	const init = () => {
		if (!element) return;

		const { firstChild } = element;

		if (firstChild.tagName !== 'IMG') return;

		const type = firstChild.getAttribute('type');

		const value = firstChild.getAttribute('value');

		const src = firstChild.getAttribute('src');

		if (
			type === tabs.drawing.toString() &&
			src &&
			drawingRef.current &&
			drawingRef.current.isEmpty()
		) {
			drawingRef.current.fromDataURL(src);

			setCanSubmit(true);
		} else if (type === tabs.typing.toString() && value && !signatureTextSrc) {
			setSignatureText(value);

			setSignatureTextSrc(src);

			setTab(tabs.typing);
		}
	};

	const inputOnChange = value => {
		setSignatureText(value);

		setCanSubmit(!!value.length);

		if (value) {
			textSignature.current.generateImage({
				...TEXT_SIGNATURE_OPTIONS,
				paddingX: SIGNATURE_WIDTH / 2 - 10.3 * value.length,
				textString: value,
			});

			setSignatureTextSrc(textSignature.current.getImageData());
		} else {
			setSignatureTextSrc(null);
		}
	};

	useEffect(() => {
		setCanSubmit(
			(tab === tabs.drawing && drawingRef.current && !drawingRef.current.isEmpty()) ||
				(tab === tabs.typing && signatureText && signatureTextSrc)
		);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [tab, element]);

	useEffect(() => {
		if (element) init();
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [element]);

	return (
		<Portal>
			<Popup
				open={element !== null}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={() => {
					resetModal();
					onClose();
				}}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '650px',
					minHeight: '488px',
					height: '50%',
				}}>
				<Portlet fluid='fluid'>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon='Subtract'>
							Signature
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Tabs id='esign-tabs' design='info' onTabChange={setTab}>
						<Portlet.TabItem title='Sign by drawing' id={tabs.drawing}>
							<div
								style={{
									border: '1px dashed #645ca1',
									borderRadius: 4,
									width: SIGNATURE_WIDTH,
									height: SIGNATURE_HEIGHT,
								}}>
								<SignatureCanvas
									penColor='black'
									canvasProps={{
										width: SIGNATURE_WIDTH,
										height: SIGNATURE_HEIGHT,
									}}
									ref={ref => {
										drawingRef.current = ref;
										init();
									}}
									onEnd={() => {
										setCanSubmit(true);
										setTab(tabs.drawing);
									}}
									style={{ backgroundColor: 'red' }}
								/>
							</div>
						</Portlet.TabItem>
						<Portlet.TabItem title='Sign by typing' id={tabs.typing}>
							<>
								<Input
									type='text'
									placeholder='Your name'
									value={signatureText}
									onChange={e => inputOnChange(e.target.value)}
								/>
								{signatureTextSrc && (
									<img
										src={signatureTextSrc}
										alt='signature'
										style={{ width: '75%', margin: '0 auto' }}
									/>
								)}
							</>
							<canvas id='signature-canvas' width={0} height={0} />
						</Portlet.TabItem>
					</Portlet.Tabs>
					<Portlet.Foot className='sdms-align-left' tall='sm'>
						<Button
							design='clean'
							text='Close'
							icon='Error-circle'
							size='sm'
							onClick={onClose}
						/>
						<div style={{ flex: 1 }} />
						<Button
							className='mr-3'
							design='clean'
							text='Clear'
							icon='Erase'
							size='sm'
							onClick={onClear}
						/>
						<Button
							label='brand'
							text='Apply'
							icon='Done-circle'
							size='sm'
							onClick={onApply}
							disabled={!canSubmit}
						/>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};

EsignModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	element: PropTypes.object,
	onClose: PropTypes.func,
};

EsignModal.defaultProps = {
	element: null,
	onClose: () => {},
};

export default EsignModal;
