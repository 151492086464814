import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { paymentTypes as _paymentTypes } from '../../../../utils/constants/constants';
import { priceFormatter } from '../../../../utils/helpers/helper';
import Paid from './Paid';
import NumPad from './NumPad';

const Cash = ({
	amount,
	payment,
	setPaymentData,
	isRefund,
	isPayBill,
	refundAmountChangeable,
	max,
	balanceDue,
	disable
}) => {
	const getMaxAmount = () => {
		if (isRefund && max) return max;

		return process.env.REACT_APP_PRICE_UPPER_LIMIT;
	};
	useEffect(() => {
		setPaymentData({
			amount: '',
			type: _paymentTypes.CASH,
			holdForSignature: false,
			serviceFee: 0,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Paid
				amount={balanceDue || amount}
				paid={payment.amount}
				amountInputFocus
				changeDue={payment.changeDue ? priceFormatter(payment.changeDue) : '0'}
				isPayBill={isPayBill}
			/>
			<NumPad
				value={payment.amount}
				setValue={value => {
					setPaymentData({
						amount: value,
						changeDue: value - amount > 0 ? value - amount : 0,
					});
				}}
				initValue={balanceDue || amount}
				max={parseFloat(getMaxAmount())}
				disableTenderedButtons={isRefund}
				disable={(isRefund && !refundAmountChangeable) || disable}
				disableCloseNumpad
			/>
		</>
	);
};
Cash.propTypes = {
	amount: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	payment: PropTypes.object.isRequired,
	setPaymentData: PropTypes.func.isRequired,
	isRefund: PropTypes.bool.isRequired,
	isPayBill: PropTypes.bool.isRequired,
	refundAmountChangeable: PropTypes.bool.isRequired,
	max: PropTypes.number.isRequired,
	balanceDue: PropTypes.number.isRequired,
	disable: PropTypes.bool,
};

export default Cash;
