import React from 'react';
import PropTypes from 'prop-types';

import { modules } from '../../../utils/helpers/apiCall';
import usePages from '../../../utils/hooks/usePages';
import useOutlet from '../../../utils/hooks/useOutlet';

import FormContainer from '../../reusables/template/FormContainer';
import SpaceForm from './SpaceForm';

const CompositeSpaceEdit = ({ match }) => {
	const pages = usePages();

	const [outletKey] = useOutlet(true);

	return (
		<FormContainer
			key={outletKey}
			dataId={match.params.id}
			dataName='marinaUnits'
			fields={<SpaceForm isComposite />}
			lists={['marinaUnits', 'marinaBookingTypes', 'unitGroups', 'icons']}
			pageTitle={pages.marina.settings.spaces.text}
			icon={pages.marina.settings.spaces.icon}
			module={modules.MARINA}
		/>
	);
};

CompositeSpaceEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default CompositeSpaceEdit;
