import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SVGIcon from '../element/SVGIcon';
import { noPermissionStatus } from '../../../utils/helpers/helper';

const ToggleType = ({ type, value, size, textOne, textTwo }) => {
	switch (type) {
		case 'check':
			return <SVGIcon name={value ? 'CheckboxFull' : 'CheckboxEmpty'} size={size || 16} />;
		case 'stop':
			return <SVGIcon name='Stop' size={size || 16} />;
		case 'done-error':
			return (
				<SVGIcon
					className='sdms-cursor--pointer'
					name={!value ? 'Done-circle' : 'Error-circle'}
					fill={!value ? 'var(--gray)' : 'var(--danger)'}
					size={size || 16}
				/>
			);
		case 'text':
			return (
				<div className='sdms-switch--text d-flex align-items-center justify-content-center'>
					<div
						className={classNames(
							'btn btn-sm',
							!value ? 'sdms-bg-fill-info' : 'sdms-bg-fill-dark sdms-font-light'
						)}>
						{textOne}
					</div>
					<div
						className={classNames(
							'btn btn-sm',
							value ? 'sdms-bg-fill-info' : 'sdms-bg-fill-dark sdms-font-light'
						)}>
						{textTwo}
					</div>
				</div>
			);
		default:
			return <span />;
	}
};
ToggleType.propTypes = {
	type: PropTypes.oneOf(['check', 'stop', 'done-error', 'text']),
	value: PropTypes.bool.isRequired,
	size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['sm', 'lg'])]),
	textOne: PropTypes.string,
	textTwo: PropTypes.string,
};
ToggleType.defaultProps = {
	type: null,
	size: null,
	textOne: null,
	textTwo: null,
};

const Toggle = ({
	value,
	disabled,
	onChange,
	id,
	name,
	className,
	outline,
	size,
	color,
	type,
	textOne,
	textTwo,
	spaceLess,
	noPermission,
}) => {
	const toggleClass = classNames(
		'sdms-switch',
		{ 'sdms-switch--outline': outline },
		{ 'sdms-switch--disabled': disabled },
		{ [`sdms-switch--${size}`]: size },
		{ [`sdms-switch--${color}`]: color },
		{ [`sdms-switch--${type}`]: type },
		{ 'sdms-switch--spaceless': spaceLess },
		noPermissionStatus(noPermission),
		className
	);

	const changeHandler = () => {
		onChange({ target: { name, value: !value } });
	};

	return (
		<span className={toggleClass}>
			{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
			<label className={classNames({ 'sdms-mb-0': type === 'text' })}>
				<input
					id={id}
					name={name}
					type='checkbox'
					disabled={disabled}
					className={classNames({ checked: value })}
					onClick={disabled || noPermission ? null : changeHandler}
				/>
				<ToggleType
					type={type}
					value={value}
					size={size}
					textOne={textOne}
					textTwo={textTwo}
				/>
			</label>
		</span>
	);
};
Toggle.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.bool.isRequired,
	className: PropTypes.string,
	outline: PropTypes.bool,
	disabled: PropTypes.bool,
	size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['sm', 'lg'])]),
	color: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'info',
		'light',
		'primary',
		'secondary',
		'success',
		'warning',
	]),
	type: PropTypes.oneOf(['check', 'stop', 'done-error', 'text']),
	onChange: PropTypes.func.isRequired,
	textOne: PropTypes.string,
	textTwo: PropTypes.string,
	spaceLess: PropTypes.bool,
	noPermission: PropTypes.bool,
};
Toggle.defaultProps = {
	id: undefined,
	name: undefined,
	outline: false,
	disabled: false,
	size: null,
	color: null,
	type: null,
	className: null,
	textOne: null,
	textTwo: null,
	spaceLess: false,
	noPermission: null,
};

export default Toggle;
