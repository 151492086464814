import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
	addErrorNotification,
	addSuccessNotification,
	canVoidInvoice,
	canDeleteInvoice,
	numberFormat,
	printReceipt,
	isModuleEnabled,
	dateFormatter,
} from '../../../utils/helpers/helper';
import useSendInvoice from '../../../utils/hooks/useSendInvoice';
import {
	filterComponents,
	invoiceStatuses,
	paymentTypes,
	serviceFeeModules,
} from '../../../utils/constants/constants';
import UserContext from '../../../app/contexts/UserContext';
import useModal from '../../../utils/hooks/useModal';
import usePrint from '../../../utils/hooks/usePrint';
// eslint-disable-next-line import/namespace,import/default,import/no-named-as-default,import/no-named-as-default-member
import WebPrint from '../../reusables/print/WebPrint';
import forms from '../../forms';
import usePages from '../../../utils/hooks/usePages';
import HeaderContext from '../../../app/contexts/HeaderContext';

import List from '../../reusables/template/List';
import Dropdown from '../../reusables/element/Dropdown';
import Button from '../../reusables/element/Button';
import ListContainer from '../../reusables/template/ListContainer';
import DialogBox from '../../reusables/element/DialogBox';
import apiCall, { modules } from '../../../utils/helpers/apiCall';
import PayModal from '../../reusables/modals/PayModal';
import PrinterModal from '../../reusables/modals/PrinterModal';
import {
	CustomerCell,
	getInvoiceStatusCellText,
	InvoiceBalanceCell,
	InvoiceDateCell,
	InvoiceDueDateCell,
	InvoicePaidCell,
	InvoiceStatusCell,
	InvoiceTotalCell,
	InvoiceTypeCell,
	InvoiceVesselCell,
} from '../../reusables/element/InvoiceListCells';
import InvoiceDeferredSchedule from '../../reusables/modals/InvoiceDeferredSchedule';

const modals = {
	VOID_DIALOG: 'voidDialog',
	PRINTER: 'printers',
	PAY: 'pay',
	DEFERRED_SCHEDULE: 'deferred_schedule',
	DELETE_DIALOG: 'delete_dialog',
};

const InvoiceList = ({ history }) => {
	const pages = usePages();
	const headerContext = useContext(HeaderContext);
	const userContext = useContext(UserContext);

	const { sendInvoice } = useSendInvoice();

	useEffect(() => {
		webPrint.current = new WebPrint({
			user: userContext.data.user,
			outlet: userContext.data.selectedOutlet,
			onStart: () => addSuccessNotification('Printing...'),
			onFinish: () => {},
			onFail: (printItem, err) => addErrorNotification(err.toString()),
		});

		headerContext.setBreadcrumbs([
			{ title: pages.crm.default.text, path: pages.crm.dashboard.path },
			{ title: pages.crm.invoices.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.crm.invoices.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	const [isLoading, setIsLoading] = useState(false);
	const [modal, openModal, closeModal] = useModal();
	const [onPrint, PRINT_COMPONENT] = usePrint();

	const getPaymentTypes = () => {
		const _paymentTypes = [];

		if (userContext.hasPermission('cash_payments'))
			_paymentTypes.push({ value: paymentTypes.CASH });

		_paymentTypes.push(...[{ value: paymentTypes.CHECK }, { value: paymentTypes.CREDIT_CARD }]);

		return _paymentTypes;
	};

	const openPrinterModal = (payment, user, customer) =>
		openModal({
			open: modals.PRINTER,
			payment,
			user,
			customer,
		});

	const webPrint = useRef({
		payBillReceipt: () => {},
		printers: [],
	});

	const onVoid = () => {
		setIsLoading(true);
		apiCall(
			'POST',
			'invoiceVoid',
			res => {
				addSuccessNotification('Invoice successfully voided.');

				if (modal.updateData) modal.updateData(res);

				closeModal();
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ invoiceId: modal.invoice.id }
		);
	};

	const onPay = payment => {
		setIsLoading(true);
		apiCall(
			'POST',
			'invoicePay',
			res => {
				if (modal.updateData) modal.updateData([res.invoice]);
				closeModal();
				setIsLoading(false);

				if (payment.printReceipt) {
					printReceipt(
						webPrint,
						res.payment,
						userContext.data.user,
						res.invoice.customer,
						null,
						openPrinterModal
					);
				}
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ invoice: modal.invoice, payment }
		);
	};

	const onPrintInvoice = invoiceIds => {
		setIsLoading(true);
		apiCall(
			'POST',
			'getPrintInvoiceContent',
			res => {
				onPrint(res.invoices.join('<p style="page-break-before: always">'));
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ outletId: userContext.data.selectedOutlet.id, invoiceIds }
		);
	};

	const onSendInvoice = (invoiceIds, updateData) => {
		setIsLoading(true);
		sendInvoice(
			{
				outletId: userContext.data.selectedOutlet.id,
				invoiceIds,
			},
			res => {
				updateData(res);
				setIsLoading(false);
			},
			() => {
				setIsLoading(false);
			}
		);
	};

	const onDelete = (invoiceIds, updateData) => {
		setIsLoading(true);
		apiCall(
			'POST',
			'invoiceDelete',
			res => {
				updateData([], res);
				addSuccessNotification('Invoice(s) successfully deleted');
				setIsLoading(false);
				closeModal();
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{
				invoiceIds,
			}
		);
	};

	return (
		<>
			<ListContainer
				route='invoices'
				title={pages.crm.invoices.text}
				history={history}
				customActions={{
					pay: (invoice, updateData) => {
						openModal({
							open: modals.PAY,
							invoice,
							amount: numberFormat(invoice.total - invoice.amountPaid),
							updateData,
						});
					},
					void: (invoice, updateData) =>
						openModal({ open: modals.VOID_DIALOG, invoice, updateData }),
					send: (invoice, updateData) => onSendInvoice([invoice.id], updateData),
					print: invoice => {
						onPrintInvoice([invoice.id]);
					},
					deferredSchedule: invoice =>
						openModal({ open: modals.DEFERRED_SCHEDULE, invoiceId: invoice.id }),
					deleteInvoice: (invoice, updateData) =>
						openModal({
							open: modals.DELETE_DIALOG,
							invoiceIds: [invoice.id],
							updateData,
						}),
				}}
				customMultiActions={{
					print: {
						func: invoices => onPrintInvoice(invoices.map(i => i.id)),
						component: (
							<Button label='primary' text='Print Invoice(s)' key='printInvoice' />
						),
					},
					send: {
						func: (invoices, updateData) =>
							onSendInvoice(
								invoices
									.filter(i => i.status.value !== invoiceStatuses.VOIDED)
									.map(i => i.id),
								updateData
							),
						component: (
							<Button
								label='primary'
								text='Email Invoice(s)'
								key='sendInvoice'
								disabled={isLoading}
							/>
						),
					},
					deleteInvoices: {
						func: (invoices, updateData) =>
							openModal({
								open: modals.DELETE_DIALOG,
								invoiceIds: invoices.map(i => i.id),
								updateData,
							}),
						component: (
							<Button
								text='Delete'
								label='danger'
								icon='Trash'
								key='deleteInvoices'
							/>
						),
					},
				}}
				checkEditable={invoices => {
					const actions = ['print', 'send'];

					if (
						userContext.hasPermission('delete_invoices') &&
						!actions.includes('delete') &&
						invoices.some(invoice => canDeleteInvoice(invoice, userContext))
					)
						actions.push('deleteInvoices');

					return actions;
				}}
				customFilters={[
					{
						component: filterComponents.SELECTS,
						dataName: 'invoiceTypes',
						fieldName: 'type.id',
						label: 'Invoice Type',
						placeholder: 'All Invoice Types',
						displayKey: 'value',
						default: [],
						multiple: true,
					},
					{
						component: filterComponents.SELECTS,
						dataName: 'bookingTypes',
						fieldName: 'bookingType.id',
						label: 'Booking Type',
						placeholder: 'All Booking Types',
						displayKey: 'name',
						default: [],
						multiple: true,
					},
					{
						component: filterComponents.ASYNC_SELECTS,
						dataName: 'customers',
						searchField: 'displayName',
						fieldName: 'customer.id',
						label: 'Customer',
						placeholder: 'Search a Customer',
						displayKey: 'displayName',
						default: [],
						multiple: true,
					},
					{
						component: filterComponents.SELECTS,
						dataName: 'enumInvoiceStatuses',
						fieldName: 'status.id',
						label: 'Status',
						placeholder: 'Status',
						displayKey: 'value',
						default: [],
						multiple: true,
					},
					{
						component: filterComponents.DATE_RANGE,
						fieldName: 'invoiceDate',
						label: 'Date',
						placeholder: 'Select Date Range',
						displayKey: 'name',
						default: null,
					},
					{
						component: filterComponents.SELECTS,
						fieldName: 'exists[inquiry]',
						label: 'Invoice Sent',
						placeholder: 'Invoice Sent',
						displayKey: 'value',
						options: [
							{ id: true, value: 'Yes' },
							{ id: false, value: 'No' },
						],
						summaryWithLabel: true,
					},
					{
						component: filterComponents.SELECTS,
						fieldName: 'hasVirtualProduct',
						label: 'Has Virtual Product',
						placeholder: 'Has Virtual Product',
						displayKey: 'value',
						options: [
							{ id: true, value: 'Yes' },
							{ id: false, value: 'No' },
						],
						summaryWithLabel: true,
					},
				]}
				forms={forms.crm.invoices}
				fluid='fluid'
				allowExport>
				<List
					withCheckBox
					fluid='fluid'
					responsive='scroll'
					checkEditable={invoice => {
						const actions = ['edit', 'print'];
						if (invoice.status.value === invoiceStatuses.OPEN) actions.push('pay');

						if (invoice.status.value !== invoiceStatuses.VOIDED) actions.push('send');

						if (canVoidInvoice(invoice, userContext)) actions.push('void');

						if (
							userContext.hasPermission('maintain_deferred_schedule') &&
							invoice.invoiceItems.filter(
								i => i.product && i.product.enableDeferredIncome
							).length > 0
						)
							actions.push('deferredSchedule');

						if (canDeleteInvoice(invoice, userContext)) actions.push('deleteInvoice');

						return actions;
					}}
					exportName='sharper_invoice_export'>
					<List.Col
						label='Invoice'
						cellData='invoiceId'
						isLinkColumn
						sortable='invoiceId'
						exportValue={col => col?.invoiceId}
					/>
					<List.Col
						label='Type'
						cellComponent={<InvoiceTypeCell />}
						exportValue={col => col?.type?.value}
					/>
					<List.Col
						label='Customer'
						isLinkColumn
						cellComponent={<CustomerCell />}
						exportValue={col => col?.customer?.displayName}
					/>
					{isModuleEnabled(userContext, modules.MARINA) && (
						<List.Col
							label='Vessel'
							cellComponent={<InvoiceVesselCell />}
							exportValue={col => col?.vessel?.name}
						/>
					)}
					<List.Col
						label='Date'
						cellComponent={<InvoiceDateCell />}
						exportValue={col => dateFormatter(col?.invoiceDate, false)}
					/>
					<List.Col
						label='Due Date'
						cellComponent={<InvoiceDueDateCell />}
						exportValue={col => dateFormatter(col?.dueDate, false)}
					/>
					<List.Col
						label='Total'
						cellComponent={<InvoiceTotalCell />}
						exportValue={col => col?.total}
					/>
					<List.Col
						label='Paid'
						cellComponent={<InvoicePaidCell />}
						exportValue={col => col?.amountPaid}
					/>
					<List.Col
						label='Balance'
						cellComponent={<InvoiceBalanceCell />}
						exportValue={col =>
							Math.max(col?.isSalesReceipt ? 0 : col?.total - col?.amountPaid, 0)
						}
					/>
					<List.Col
						label='Status'
						cellComponent={<InvoiceStatusCell />}
						exportValue={getInvoiceStatusCellText}
					/>
					<List.Col align='right' onlyHover width={150}>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item icon='Edit' text='Edit' key='edit'>
								Edit
							</Dropdown.Item>
							<Dropdown.Item icon='Dollar' text='Pay' key='pay'>
								Pay
							</Dropdown.Item>
							<Dropdown.Item icon='Printer' text='Print' key='print'>
								Print
							</Dropdown.Item>
							<Dropdown.Item icon='Mail-box' text='Email Invoice' key='send'>
								Email Invoice
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='danger'
								icon='Error-circle'
								text='Void'
								key='void'>
								Void
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='danger'
								icon='Error-circle'
								text='Delete'
								key='deleteInvoice'>
								Delete
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			{PRINT_COMPONENT}
			<DialogBox
				open={modal.open === modals.VOID_DIALOG}
				title='Void'
				content='Are you sure you want to void this invoice?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't Void!`}
					onClick={closeModal}
					disabled={isLoading}
				/>
				<Button
					className='sdms-font-transform-c'
					label='danger'
					icon='Trash'
					text='Yes, Void!'
					onClick={onVoid}
					disabled={isLoading}
				/>
			</DialogBox>
			<PayModal
				isOpen={modal.open === modals.PAY}
				onClose={closeModal}
				paymentTypes={getPaymentTypes()}
				amount={modal.amount || 0}
				customer={modal.invoice ? modal.invoice.customer : null}
				onPay={paymentData => {
					onPay(paymentData);
				}}
				isPaying={isLoading}
				canSavePaymentMethod={userContext.hasPermission('save_customer_payment_methods')}
				canEnterCreditCard={userContext.hasPermission('key_in_credit_cards')}
				max={modal.amount || 0}
				paymentGateway={userContext.data.selectedOutlet.settings.paymentGateway}
				serviceFeeModule={serviceFeeModules.CRM}
				outlet={userContext.data.selectedOutlet}
				displayEmailReceipt
				emailReceiptDefault={userContext.data.selectedOutlet.settings.emailPaymentOnCrm}
			/>
			<PrinterModal
				onClose={closeModal}
				printers={webPrint ? webPrint.current.printers : []}
				isOpen={modal.open === modals.PRINTER}
				enableRemember
				onSubmit={printer => {
					printReceipt(
						webPrint,
						modal.payment,
						modal.user,
						modal.customer,
						printer.id,
						openPrinterModal
					);

					closeModal();
				}}
			/>
			{modal.open === modals.DEFERRED_SCHEDULE && (
				<InvoiceDeferredSchedule onCancel={closeModal} invoiceId={modal.invoiceId} />
			)}
			<DialogBox
				open={modal.open === modals.DELETE_DIALOG}
				title='Delete'
				content='Are you sure you want to delete these item(s)?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't delete!`}
					onClick={closeModal}
					disabled={isLoading}
				/>
				<Button
					className='sdms-font-transform-c'
					label='danger'
					icon='Trash'
					text='Yes, Delete!'
					onClick={() => onDelete(modal.invoiceIds, modal.updateData)}
					disabled={isLoading}
				/>
			</DialogBox>
		</>
	);
};
InvoiceList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default InvoiceList;
