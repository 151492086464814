import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Nav from './Nav';

import UserContext from '../app/contexts/UserContext';
import pages from './pages';

const NavItems = ({ menu, parentText, selectedText, setSelectedText }) => {
	const userContext = useContext(UserContext);

	const filterNavItem = item =>
		item.path === pages.booking.map.path &&
		userContext.data.selectedOutlet.settings &&
		!userContext.data.selectedOutlet.settings.defaultBookingMap;

	return (Array.isArray(menu) ? menu : Object.values(menu)).map(item => {
		const { default: _, ...submenu } = item;
		if (item.default) {
			return (
				<Nav.Item
					key={`${item.default.text}-${item.default.path}`}
					text={item.default.text}
					icon={item.default.icon}
					path={item.default.path}
					parentText={parentText}
					selectedText={selectedText}
					setSelectedText={setSelectedText}
					noPermission={
						item.default.permission
							? !userContext.hasPermission(
									item.default.permission,
									false,
									item.default.disabled
							  )
							: false
					}>
					<NavItems
						menu={submenu}
						parentText={item.default.text}
						selectedText={selectedText}
						setSelectedText={setSelectedText}
					/>
				</Nav.Item>
			);
		}
		if (filterNavItem(item)) return null;
		return (
			<Nav.Item
				key={`${item.text}-${item.path}`}
				text={item.text}
				icon={item.icon}
				noPermission={
					item.permission || item.installerRequired
						? !userContext.hasPermission(item.permission, item.installerRequired)
						: false
				}
				path={item.path}
				parentText={parentText}
				selectedText={selectedText}
				setSelectedText={setSelectedText}
			/>
		);
	});
};
NavItems.propTypes = {
	menu: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
	parentText: PropTypes.string,
	selectedText: PropTypes.string,
	setSelectedText: PropTypes.func,
};

NavItems.defaultProps = {
	parentText: '',
	selectedText: '',
};

export default NavItems;
