import React from 'react';
import PropTypes from 'prop-types';

import { bookingPeriods } from '../../../../../../utils/constants/constants';

import Portlet from '../../../../layout/Portlet';
import Loading from '../../../../template/Loading';
import FormField from '../../../../template/FormField';
import Toggle from '../../../../field/Toggle';
import ReservationItemForm from '../../../../forms/ReservationItemForm';
import PricingSummaryItem from '../../elements/PricingSummaryItem';

const Options = ({
	selectedProduct,
	isItemReserved,
	isLoading,
	useSameTimes,
	setUseSameTimes,
	numberOfUnits,
	startTimes,
	setStartTimes,
	startTimesValRes,
	setStartTimesValRes,
	startTimesShowVal,
	setStartTimesShowVal,
	endTimes,
	setEndTimes,
	endTimesValRes,
	setEndTimesValRes,
	endTimesShowVal,
	setEndTimesShowVal,
	notes,
	setNotes,
	extras,
	setExtras,
	extrasVal,
	setExtrasVal,
	extrasShowVal,
	setExtrasShowVal,
	isHourlyOnly,
	intervalText,
	headerHeight,
	quantities,
	prices,
	taxes,
	extraCharges,
	extraTaxes,
	isOnline,
	vehicles,
	setVehicles,
	enumRvTypes,
	enumVehicleMakes,
	enumBoatTypes,
	enumBoatMakes,
	customer,
	onCustomerChange,
	outlet,
	countries,
	defaultCountry,
	states,
	passwordSettings,
	openModal,
	fromDate,
}) => {
	return (
		<>
			{selectedProduct &&
				selectedProduct.bookingPeriod === bookingPeriods.HOURLY &&
				!isItemReserved &&
				numberOfUnits > 1 && (
					<Portlet className='flex-grow-0'>
						<Portlet.Head>
							<Portlet.HeadToolbarActions>
								<Loading isLoading={isLoading}>
									<FormField
										className='align-items-center d-flex form-group-last sdms-last-margin--h'
										name='sameTime'
										label='Use same time for all'
										id={0}
										inFormDesign={false}>
										<Toggle
											onChange={e => setUseSameTimes(e.target.value)}
											value={useSameTimes}
										/>
									</FormField>
								</Loading>
							</Portlet.HeadToolbarActions>
						</Portlet.Head>
					</Portlet>
				)}
			{selectedProduct &&
				[...Array(numberOfUnits).keys()].map(index => {
					const content = (
						<div className='row' key={index}>
							<div className='col-lg-8'>
								<ReservationItemForm
									key={index}
									notes={notes}
									setStartTimes={setStartTimes}
									endTimesValRes={endTimesValRes}
									extras={extras}
									setExtrasVal={setExtrasVal}
									extrasVal={extrasVal}
									startTimesValRes={startTimesValRes}
									setEndTimesShowVal={setEndTimesShowVal}
									setNotes={setNotes}
									setStartTimesShowVal={setStartTimesShowVal}
									endTimes={endTimes}
									setExtras={setExtras}
									extrasShowVal={extrasShowVal}
									isItemReserved={isItemReserved}
									setEndTimesValRes={setEndTimesValRes}
									startTimes={startTimes}
									startTimesShowVal={startTimesShowVal}
									setEndTimes={setEndTimes}
									useSameTimes={useSameTimes}
									setStartTimesValRes={setStartTimesValRes}
									index={index}
									isMulti={[...Array(numberOfUnits).keys()].length > 1}
									isHourlyOnly={isHourlyOnly}
									isLoading={isLoading}
									setExtrasShowVal={setExtrasShowVal}
									endTimesShowVal={endTimesShowVal}
									headerHeight={headerHeight}
									intervalText={intervalText}
									selectedProduct={selectedProduct}
									vehicles={vehicles}
									setVehicles={setVehicles}
									isOnline={isOnline}
									enumRvTypes={enumRvTypes}
									enumVehicleMakes={enumVehicleMakes}
									enumBoatTypes={enumBoatTypes}
									enumBoatMakes={enumBoatMakes}
									customer={customer}
									onCustomerChange={onCustomerChange}
									outlet={outlet}
									countries={countries}
									defaultCountry={defaultCountry}
									states={states}
									passwordSettings={passwordSettings}
									openModal={openModal}
									fromDate={fromDate}
								/>
							</div>
							<div className='col-lg-4'>
								<PricingSummaryItem
									key={index}
									period={selectedProduct.bookingPeriod}
									quantity={quantities[index]}
									price={prices[index]}
									tax={taxes[index]}
									extra={extras[index]}
									extraCharge={extraCharges[index]}
									extraTax={extraTaxes[index]}
									selectedProduct={selectedProduct}
									index={index}
									isMulti={[...Array(numberOfUnits).keys()].length > 1}
									headerHeight={headerHeight}
								/>
							</div>
						</div>
					);

					if (isOnline) return <div className='sdms-analytics-options'>{content}</div>;

					return content;
				})}
		</>
	);
};

Options.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	selectedProduct: PropTypes.object.isRequired,
	startTimes: PropTypes.arrayOf(PropTypes.string).isRequired,
	setStartTimes: PropTypes.func.isRequired,
	startTimesValRes: PropTypes.arrayOf(PropTypes.object).isRequired,
	setStartTimesValRes: PropTypes.func.isRequired,
	startTimesShowVal: PropTypes.arrayOf(PropTypes.bool).isRequired,
	setStartTimesShowVal: PropTypes.func.isRequired,
	endTimes: PropTypes.arrayOf(PropTypes.string).isRequired,
	setEndTimes: PropTypes.func.isRequired,
	endTimesValRes: PropTypes.arrayOf(PropTypes.object).isRequired,
	setEndTimesValRes: PropTypes.func.isRequired,
	endTimesShowVal: PropTypes.arrayOf(PropTypes.bool).isRequired,
	setEndTimesShowVal: PropTypes.func.isRequired,
	notes: PropTypes.arrayOf(PropTypes.string).isRequired,
	setNotes: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	extras: PropTypes.array.isRequired,
	setExtras: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	extrasVal: PropTypes.array.isRequired,
	setExtrasVal: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	extrasShowVal: PropTypes.array.isRequired,
	setExtrasShowVal: PropTypes.func.isRequired,
	useSameTimes: PropTypes.bool.isRequired,
	isHourlyOnly: PropTypes.bool.isRequired,
	intervalText: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
	headerHeight: PropTypes.number.isRequired,
	isItemReserved: PropTypes.bool.isRequired,
	setUseSameTimes: PropTypes.func.isRequired,
	numberOfUnits: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	quantities: PropTypes.array.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	prices: PropTypes.array.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	taxes: PropTypes.array.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	extraCharges: PropTypes.array.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	extraTaxes: PropTypes.array.isRequired,
	isOnline: PropTypes.bool.isRequired,
	vehicles: PropTypes.arrayOf(PropTypes.object).isRequired,
	setVehicles: PropTypes.func.isRequired,
	enumRvTypes: PropTypes.arrayOf(PropTypes.object),
	enumVehicleMakes: PropTypes.arrayOf(PropTypes.object),
	enumBoatTypes: PropTypes.arrayOf(PropTypes.object),
	enumBoatMakes: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	onCustomerChange: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	countries: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	defaultCountry: PropTypes.object,
	states: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	passwordSettings: PropTypes.object,
	openModal: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	fromDate: PropTypes.object,
};

Options.defaultProps = {
	enumRvTypes: [],
	enumVehicleMakes: [],
	enumBoatTypes: [],
	enumBoatMakes: [],
	customer: null,
	countries: [],
	defaultCountry: null,
	states: [],
	passwordSettings: null,
	openModal: () => {},
	fromDate: null,
};

export default Options;
