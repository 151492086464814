import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../element/Button';
import DialogBox from '../../../element/DialogBox';

const ManuallyEditInvoice = ({ open, onClose }) => {
	return (
		<DialogBox
			open={open}
			title=''
			content='Reservation has manually edited invoice!'
			type='warning'
			onClose={onClose}>
			<Button label='success' text='Ok' onClick={onClose} />
		</DialogBox>
	);
};
ManuallyEditInvoice.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
};

ManuallyEditInvoice.defaultProps = {
	open: false,
	onClose: () => {},
};

export default ManuallyEditInvoice;
