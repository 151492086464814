import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import pages from '../../pages';
import { numeric, required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';

import Input from '../../reusables/field/Input';
import FormGroup from '../../reusables/layout/FormGroup';
import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import FormField from '../../reusables/template/FormField';
import Button from '../../reusables/element/Button';
import Loading from '../../reusables/template/Loading';
import { numberParser } from '../../../utils/helpers/helper';
import Toggle from '../../reusables/field/Toggle';
import HeaderContext from '../../../app/contexts/HeaderContext';

const PasswordSettingsForm = ({
	data,
	setIsValid,
	submit,
	isSubmitted,
	isLoading,
	submitButtonAttr,
	onFormChange,
}) => {
	const headerContext = useContext(HeaderContext);

	const [
		passwordMinLength,
		passwordMinLengthOnChange,
		passwordMinLengthValRes,
		passwordMinLengthShowVal,
		setPasswordMinLengthShowVal,
	] = useField(
		data,
		'passwordMinLength',
		onFormChange,
		[required, numeric],
		'',
		numberParser(false)
	);

	const [passwordRequiredUpperCase, passwordRequiredUpperCaseOnChange] = useField(
		data,
		'passwordRequiredUpperCase',
		onFormChange,
		[],
		false
	);

	const [passwordRequiredNumber, passwordRequiredNumberOnChange] = useField(
		data,
		'passwordRequiredNumber',
		onFormChange,
		[],
		false
	);

	const [passwordRequiredSpecialCharacter, passwordRequiredSpecialCharacterOnChange] = useField(
		data,
		'passwordRequiredSpecialCharacter',
		onFormChange,
		[],
		false
	);

	useEffect(() => {
		if (isSubmitted) setPasswordMinLengthShowVal();
	}, [isSubmitted, setPasswordMinLengthShowVal]);

	useEffect(() => {
		setIsValid(passwordMinLengthValRes.isValid);
	}, [passwordMinLengthValRes.isValid, setIsValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.systemSettings.default.text, path: pages.systemSettings.dashboard.path },
			{ title: pages.systemSettings.passwordSettings.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.systemSettings.passwordSettings.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<Portlet className='sdms-form' fluid='fluid' hasFrame>
			<Portlet.Body>
				<Section title='General Settings'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='passwordMinLength'
									label='Min Length'
									id={data.id}
									valRes={passwordMinLengthValRes}
									showValidation={passwordMinLengthShowVal}
									loadingContainer
									colMd={6}
									description='To disable enter 0'>
									<Input
										type='number'
										placeholder='Password Min Length (Required)'
										value={passwordMinLength}
										onChange={passwordMinLengthOnChange}
										onBlur={setPasswordMinLengthShowVal}
										min={0}
										withOutSpin
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='passwordRequiredUpperCase'
									label='Require at least 1 uppercase letter'
									id={data.id}
									colMd={6}>
									<Toggle
										value={passwordRequiredUpperCase}
										onChange={passwordRequiredUpperCaseOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='passwordRequiredNumber'
									label='Require at least 1 number'
									id={data.id}
									colMd={6}>
									<Toggle
										value={passwordRequiredNumber}
										onChange={passwordRequiredNumberOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='passwordRequiredSpecialCharacter'
									label='Require at least 1 special character'
									id={data.id}
									colMd={6}>
									<Toggle
										value={passwordRequiredSpecialCharacter}
										onChange={passwordRequiredSpecialCharacterOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
PasswordSettingsForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		taxId: PropTypes.string,
		duns: PropTypes.string,
	}),
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
};
PasswordSettingsForm.defaultProps = {
	data: {
		id: 0,
	},
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
};

export default PasswordSettingsForm;
