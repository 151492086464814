import React from 'react';
import PropTypes from 'prop-types';

import ListContainer from '../../reusables/template/ListContainer';
import List from '../../reusables/template/List';
import ReservationStatusCell, { RequestCell } from '../../reusables/element/ReservationStatusCell';
import { convertDateToUTC, dateFormatter } from '../../../utils/helpers/helper';
import Dropdown from '../../reusables/element/Dropdown';
import { getOnlinePortalDefaultFilters } from './functions';

const ArrivalCell = ({ data }) => {
	return <span>{dateFormatter(convertDateToUTC(new Date(data.fromDate)))}</span>;
};
ArrivalCell.propTypes = {
	data: PropTypes.shape({
		fromDate: PropTypes.string,
	}),
};
ArrivalCell.defaultProps = {
	data: {
		fromDate: '',
	},
};

const DepartureCell = ({ data }) => {
	return <span>{dateFormatter(convertDateToUTC(new Date(data.toDate)))}</span>;
};
DepartureCell.propTypes = {
	data: PropTypes.shape({
		toDate: PropTypes.string,
	}),
};
DepartureCell.defaultProps = {
	data: {
		toDate: '',
	},
};
const Booking = ({ data, outlet, isActive, title, icon }) => {
	if (!isActive) return null;
	return (
		<div className='sdms-online-portal-list-container'>
			<ListContainer
				route='onlinePortalGetBookings'
				title={title}
				icon={icon}
				staticFilters={getOnlinePortalDefaultFilters(data.customer, outlet)}
				isTabList
				largeQuickPanel
				forms={[]}
				fluid='fluid'
				hasSearch={false}>
				<List ffluid='fluid' withCheckBox={false} checkEditable={() => []} withOutPortlet>
					<List.Col label='Booking' cellData='reservationItemId' key='booking' />
					<List.Col
						label='Product'
						cellData='name'
						cellDataObject='product'
						key='product'
					/>
					<List.Col
						label='Arrival'
						cellComponent={<ArrivalCell />}
						sortable='fromDate'
						key='arrival'
					/>
					<List.Col
						label='Departure'
						cellComponent={<DepartureCell />}
						sortable='toDate'
						key='departure'
					/>
					<List.Col
						label='Request'
						cellComponent={<RequestCell />}
						key='request'
						align='center'
					/>
					<List.Col
						label='Status'
						cellComponent={<ReservationStatusCell />}
						key='status'
					/>
					<List.Col width={150} align='right' onlyHover>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							disabled
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
		</div>
	);
};

Booking.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	isActive: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
};

export default Booking;
