import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import classNames from 'classnames';

import Button from '../../reusables/element/Button';
import List from '../../reusables/template/List';
import Portlet from '../../reusables/layout/Portlet';
import DialogBox from '../../reusables/element/DialogBox';
import SVGIcon from '../../reusables/element/SVGIcon';
import { addErrorNotification, paymentTypeIcon } from '../../../utils/helpers/helper';
import Portal from '../../reusables/layout/Portal';
import UserContext from '../../../app/contexts/UserContext';
import Badge from '../../reusables/element/Badge';
import useModal from '../../../utils/hooks/useModal';
import { paymentTypes } from '../../../utils/constants/constants';
import CustomerPaymentMethodForm from './CustomerPaymentMethodForm';
import { getPaymentProcessor } from '../../../utils/hooks/usePaymentGateway';

const PaymentMethod = ({ data }) => {
	if (!data.paymentMethod) return null;
	return (
		<>
			<SVGIcon
				name={paymentTypeIcon(data.paymentMethod.icon.value).name}
				className={classNames('sdms-mr-5', {
					'sdms-svg-icon--fill': paymentTypeIcon(data.paymentMethod.icon.value).fill,
				})}
				size={36}
			/>
			{data.paymentMethod.name}
		</>
	);
};

PaymentMethod.propTypes = {
	data: PropTypes.shape({
		paymentMethod: PropTypes.shape({
			name: PropTypes.string,
			icon: PropTypes.object,
		}),
	}),
};

PaymentMethod.defaultProps = {
	data: {
		paymentMethod: {
			name: '',
			icon: null,
		},
	},
};

const ExpirationCell = ({ data }) => {
	return (
		<>
			{data.expMonth}/{data.expYear}
		</>
	);
};

ExpirationCell.propTypes = {
	data: PropTypes.shape({
		expMonth: PropTypes.string,
		expYear: PropTypes.string,
	}),
};

ExpirationCell.defaultProps = {
	data: {
		expMonth: '01',
		expYear: '01',
	},
};

const IsDefaultCell = ({ data }) => {
	if (data.isDefault)
		return (
			<Badge isUnified>
				<SVGIcon name='Check' fill='#ffffff' />
			</Badge>
		);

	return null;
};

IsDefaultCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

IsDefaultCell.defaultProps = {
	data: null,
};

const DefaultButton = ({ data, onDefaultSelect }) => {
	return (
		<Button
			label='brand'
			text={data.isDefault ? 'Unmake Default' : 'Make Default'}
			size='sm'
			onClick={() => {
				if (onDefaultSelect) onDefaultSelect(data.isDefault ? null : data);
				else addErrorNotification('Please save customer.');
			}}
		/>
	);
};

DefaultButton.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	onDefaultSelect: PropTypes.func.isRequired,
};

DefaultButton.defaultProps = {
	data: null,
};

const modals = {
	ADD: 'add',
	DELETE: 'delete',
};

const CustomerPaymentMethodList = ({
	type,
	title,
	formTitle,
	icon,
	data,
	onAdd,
	onDelete,
	paymentMethods,
	customer,
	onDefaultSelect,
	disabled,
}) => {
	const [modal, openModal, closeModal] = useModal();

	const userContext = useContext(UserContext);

	const COLUMNS = useMemo(() => {
		const _columns = [
			<List.Col label='Payment Method' cellData='name' cellComponent={<PaymentMethod />} />,
		];

		if (type === paymentTypes.CREDIT_CARD)
			_columns.push(
				<List.Col label='Number' cellData='cardNumber' className='sdms-font-monospace' />,
				<List.Col
					label='Expiration'
					width={100}
					cellComponent={<ExpirationCell />}
					align='center'
				/>
			);

		if (type === paymentTypes.CHECK)
			_columns.push(
				<List.Col label='Bank Name' cellData='bankName' className='sdms-font-monospace' />,
				<List.Col
					label='Account Name'
					cellData='accountName'
					className='sdms-font-monospace'
				/>,
				<List.Col
					label='Account Holder Name'
					cellData='accountHolderName'
					className='sdms-font-monospace'
				/>
			);

		_columns.push(
			<List.Col
				label='Gateway'
				cellDataObject='paymentGateway'
				cellData='value'
				width={150}
				align='center'
			/>,
			<List.Col
				label='Default'
				width={50}
				cellComponent={<IsDefaultCell />}
				align='center'
			/>,
			<List.Col align='right' onlyHover width={240}>
				<DefaultButton onDefaultSelect={onDefaultSelect} />
				<span className='sdms-ml-5'>
					<Button
						design='link'
						text='Delete'
						icon='Trash'
						size='sm'
						elevate
						key='delete'
					/>
				</span>
			</List.Col>
		);

		return _columns;
	}, [onDefaultSelect, type]);

	const paymentProcessor = getPaymentProcessor(userContext.data.selectedOutlet, type);

	return (
		<div className='col-12'>
			<Portlet className='sdms-list-layout sdms-portlet--section' border>
				<Portlet.Head wrapMaxSize='lg'>
					<Portlet.HeadLabel portletIcon={icon}>
						<h3 className='sdms-portlet__head-title'>{title}</h3>
					</Portlet.HeadLabel>
					<Portlet.HeadToolbar>
						<Portlet.HeadActions>
							<Button
								label='brand'
								text='Add New'
								icon='Plus'
								onClick={() =>
									openModal({ open: modals.ADD, paymentMethod: { id: 0 } })
								}
								noPermission={
									!userContext.hasPermission(
										'accounting_save_customer_payment_methods'
									)
								}
								disabled={!paymentProcessor || disabled}
							/>
						</Portlet.HeadActions>
					</Portlet.HeadToolbar>
				</Portlet.Head>
				<List
					data={
						paymentProcessor
							? data.filter(
									cpm =>
										cpm.paymentMethod.paymentType.value === type &&
										cpm.processor.id === paymentProcessor.id
							  )
							: []
					}
					withCheckBox={false}
					hasPagination={false}
					actions={{
						delete: paymentMethod => openModal({ open: modals.DELETE, paymentMethod }),
					}}
					withOutPortlet>
					{COLUMNS}
				</List>
				<DialogBox
					open={modal.open === modals.DELETE}
					title='Delete'
					content='Are you sure about delete?'
					type='question'
					onClose={closeModal}>
					<Button
						className='sdms-font-transform-c'
						design='clean'
						icon='Error-circle'
						text={`No, Don't delete!`}
						onClick={closeModal}
					/>
					<Button
						className='sdms-font-transform-c'
						label='danger'
						icon='Trash'
						text='Yes, Delete!'
						onClick={() => {
							onDelete(modal.paymentMethod.id);
							closeModal();
						}}
					/>
				</DialogBox>
				<Portal>
					<Popup
						open={modal.open === modals.ADD}
						contentStyle={{
							padding: 0,
							background: 'unset',
							border: 'unset',
						}}
						closeOnDocumentClick={false}
						lockScroll
						modal
						onClose={closeModal}>
						<CustomerPaymentMethodForm
							type={type}
							data={modal.paymentMethod}
							customer={customer}
							paymentMethods={paymentMethods}
							onAdd={paymentMethod => {
								onAdd(paymentMethod);
								closeModal();
							}}
							onCancel={closeModal}
							displayMakeDefault={onDefaultSelect !== null}
							icon={icon}
							title={formTitle}
						/>
					</Popup>
				</Portal>
			</Portlet>
		</div>
	);
};
CustomerPaymentMethodList.propTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	formTitle: PropTypes.string.isRequired,
	icon: PropTypes.string,
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	onAdd: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	paymentMethods: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	onDefaultSelect: PropTypes.func,
	disabled: PropTypes.bool,
};
CustomerPaymentMethodList.defaultProps = {
	paymentMethods: [],
	customer: null,
	onDefaultSelect: () => {},
	icon: '',
	disabled: false,
};

export default CustomerPaymentMethodList;
