import React from 'react';
import PropTypes from 'prop-types';

import useDate from '../../../../../utils/hooks/useDate';
import { priceFormatter } from '../../../../../utils/helpers/helper';

const RetainItem = ({ retain }) => {
	const [dateFormatter] = useDate();

	return (
		<tr className='sdms-t-orders--payment'>
			<td colSpan={3} className='sdms-font-monospace'>
				<b>{retain.info}</b> {` - ${dateFormatter(retain.date)}`}
			</td>
			<td className='sdms-t-orders--item__price sdms-font-monospace'>
				{priceFormatter(retain.amount)}
			</td>
			<td />
		</tr>
	);
};

RetainItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	retain: PropTypes.object,
};

RetainItem.defaultProps = {
	retain: null,
};

export default RetainItem;
