import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import Portlet from '../layout/Portlet';

import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';

import Portal from '../layout/Portal';
import FormField from '../template/FormField';
import Button from '../element/Button';
import MultiEmailInput from '../field/MultiEmailInput';

const ReservationConfirmation = ({ isOpen, defaultEmail, onSend, onClose }) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<ReservationConfirmationContent
					defaultEmail={defaultEmail}
					onSend={onSend}
					onClose={onClose}
				/>
			</Popup>
		</Portal>
	);
};
ReservationConfirmation.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	defaultEmail: PropTypes.string,
	onSend: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};
ReservationConfirmation.defaultProps = {
	defaultEmail: '',
};

const ReservationConfirmationContent = ({ defaultEmail, onSend, onClose }) => {
	const [email, emailOnChange, emailValRes, emailShowVal, setEmailShowVal] = useField(
		{},
		'email',
		() => {},
		[required],
		defaultEmail
	);
	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Mail-box'>
					Send Confirmation Mail
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<FormField
					name='email'
					label='Email(s)'
					valRes={emailValRes}
					showValidation={emailShowVal}
					loadingContainer
					colLg={12}>
					<MultiEmailInput
						onChange={emailOnChange}
						value={email}
						placeholder='Email(s)'
					/>
				</FormField>
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='brand'
						icon='Mail-box'
						text='Send'
						size='sm'
						onClick={() => {
							setEmailShowVal();
							if (emailValRes.isValid) onSend(email);
						}}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
ReservationConfirmationContent.propTypes = {
	defaultEmail: PropTypes.string,
	onSend: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};
ReservationConfirmationContent.defaultProps = {
	defaultEmail: '',
};

export default ReservationConfirmation;
