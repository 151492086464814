import React from 'react';
import PropTypes from 'prop-types';

import pages from '../../pages';

import FormContainer from '../../reusables/template/FormContainer';
import TemplateForm from './TemplateForm';

const TemplateEdit = ({ match }) => {
	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle={pages.systemSettings.templates.text}
			dataName={pages.systemSettings.templates.route}
			lists={['templates', 'templateTypes', 'emailAddresses']}
			fields={<TemplateForm />}
		/>
	);
};

TemplateEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default TemplateEdit;
