import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SVGIcon from './SVGIcon';

const FormSectionMoreLink = ({ text, isOpen, onClick }) => {
	return (
		<div
			className='row sdms-font-bold sdms-font-size-12-px sdms-font-info sdms-cursor--pointer'
			role='presentation'
			onClick={onClick}>
			<div className='sdms-mr-5'>
				<SVGIcon
					name='Angle-double-right'
					size={16}
					fill='var(--info)'
					className={classNames('sdms-transition', {
						'sdms-rotate--270': isOpen,
						'sdms-rotate--90': !isOpen,
					})}
				/>
			</div>
			<div>{text}</div>
		</div>
	);
};

FormSectionMoreLink.propTypes = {
	text: PropTypes.string,
	isOpen: PropTypes.bool,
	onClick: PropTypes.func,
};

FormSectionMoreLink.defaultProps = {
	text: 'More',
	isOpen: false,
	onClick: () => {},
};

export default FormSectionMoreLink;
