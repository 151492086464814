import React, { useContext } from 'react';
import update from 'immutability-helper';

import FormContainer from '../../reusables/template/FormContainer';
import UserContext from '../../../app/contexts/UserContext';
import pages from '../../pages';
import AccountingIntegrationForm from './AccountingIntegrationForm';

const AccountingIntegrationEdit = () => {
	const userContext = useContext(UserContext);

	return (
		<FormContainer
			dataId={
				userContext.data.user.company.settings
					? userContext.data.user.company.settings.id.toString()
					: 0
			}
			pageTitle={pages.systemSettings.accountingIntegration.text}
			dataName={pages.systemSettings.accountingIntegration.route}
			lists={[
				'acctAccounts',
				'acctSubtotals',
				'acctProducts',
				'enumPaymentMethods',
				'taxRates',
				'taxCodes',
				'accounts',
			]}
			fields={<AccountingIntegrationForm />}
			showBackButton={false}
			afterSubmit={data => {
				userContext.setData(
					update(userContext.data, {
						user: { company: { settings: { $set: data } } },
					})
				);
			}}
		/>
	);
};

export default AccountingIntegrationEdit;
