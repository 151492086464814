import { useEffect, useRef, useState } from 'react';

const useFeet = (data, onChange, isLoading = false, initValue = null) => {
	const [ft, setFt] = useState('');

	const [inch, setInch] = useState('');

	const init = useRef(false);

	const reset = () => {
		setFt('');
		setInch('');
	};

	const set = value => {
		if (!value) {
			reset();
			return;
		}

		const _inch = value ? value % 12 : '';

		const _ft = value ? parseInt(value / 12, 10) : '';

		setInch(_inch);

		setFt(_ft);
	};

	useEffect(() => {
		if (!isLoading) {
			const _inch = data ? data % 12 : '';

			const _ft = data ? parseInt(data / 12, 10) : '';

			setInch(_inch);

			setFt(_ft);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		const defaultValue = init.current ? null : initValue;

		init.current = true;

		const newValue =
			ft === '' && inch === ''
				? defaultValue
				: parseFloat(ft || 0) * 12 + parseFloat(inch || 0);

		if (typeof data !== 'undefined' && newValue !== data) {
			onChange({
				target: {
					value: newValue,
				},
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ft, inch]);

	return [ft, setFt, inch, setInch, reset, set];
};

export default useFeet;
