import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

import UserContext from '../../../../app/contexts/UserContext';
import HeaderContext from '../../../../app/contexts/HeaderContext';
import MessageContext from '../../../../app/contexts/MessageContext';
import useModal from '../../../../utils/hooks/useModal';
import usePrint from '../../../../utils/hooks/usePrint';
import apiCall, { modules, parseData } from '../../../../utils/helpers/apiCall';
import useSendInvoice from '../../../../utils/hooks/useSendInvoice';
import {
	bookingPeriods,
	invoiceStatuses,
	paymentTypes as _paymentTypes,
	reservationReceiptTypes,
	reservationStatuses,
	invoiceTypes as constInvoiceTypes,
} from '../../../../utils/constants/constants';
import {
	bookingPeriodPageKeys,
	getBookingPeriodByPath,
	getModuleByPath,
	getReservationGridCustomFilters,
	getServiceFeeModule,
	modulePageKeys,
} from '../../../../utils/helpers/reusable';
import {
	getDefaultCreditCard,
	getDefaultPaymentMethod,
	getDefaultReservation,
	getPaymentTypes,
	getReservationSettlements,
	getReservationTotals,
	hasReservationInvoice,
	isEarlyCheckout,
	onExtraChargeItemUpdate,
	printReservationReceipt,
} from '../../../../utils/helpers/reservationHelper';
import pages from '../../../pages';
import {
	addErrorNotification,
	addSuccessNotification,
	generateId,
	getItemTaxCode,
} from '../../../../utils/helpers/helper';
// eslint-disable-next-line import/namespace,import/default,import/no-named-as-default,import/no-named-as-default-member
import WebPrint from '../../print/WebPrint';

import ContentInner from '../../template/ContentInner';
import BreadcrumbContainer from '../../template/BreadcrumbContainer';
import Button from '../../element/Button';
import MessagesButton from '../../element/MessagesButton';
import ReservationAdvanceGrid from '../../element/ReservationAdvanceGrid';
import Summary from './Summary';
import ItemList from './ItemList';
import ItemCarousel from './ItemCarousel';
import ItemForm from './ItemForm';
import Cancellation from './modals/Cancellation';
import SendEmail from './modals/SendEmail';
import Contract from './modals/Contract';
import Checkout from './modals/Checkout';
import Override from './modals/Override';
import PayModal from '../../modals/PayModal';
import PrinterModal from '../../modals/PrinterModal';

import InvoiceList from './InvoiceList';
import PaymentList from './PaymentList';
import ScheduledPaymentCapture from './modals/ScheduledPaymentCapture';
import PaymentVoid from './modals/PaymentVoid';
import AuthorizePaymentModal from '../../modals/AuthorizePaymentModal';
import AuthorizedPaymentVoid from './modals/AuthorizedPaymentVoid';
import RefundVoid from './modals/RefundVoid';
import ReservationExtraCharge from './modals/ReservationExtraCharge';
import QuantityModal from '../../modals/QuantityModal';
import Messages from '../../modals/Messages';
import AttachmentModal from '../../modals/AttachmentModal';
import ManuallyEditInvoice from './elements/ManuallyEditInvoice';
import Dropdown from '../../element/Dropdown';
import PaymentScheduleSelectModal from '../../modals/PaymentScheduleSelect';
import CustomerPaymentMethodSelect from '../../modals/CustomerPaymentMethodSelect';
import TerminalModal from '../../modals/TerminalModal';
import SpaceAssignmentList from './SpaceAssignmentList';
import ContractList from './ContractList';
import { hasEditLockedReservationPermission } from '../../../../utils/helpers/permission';
import DepartureDate from './modals/DepartureDate';
import DepositDetails from './DepositDetails';
import ReservationConfirmation from '../../modals/ReservationConfirmation';

const screens = {
	GRID: 'grid',
	TERMINAL: 'terminal',
	ITEM_LIST: 'item_list',
	ITEM_CAROUSEL: 'item_carousel',
	ITEM_FORM: 'item_form',
	INVOICES: 'invoices',
	PAYMENTS: 'payments',
	DEPOSITS: 'deposits',
	SPACE_ASSIGNMENTS: 'space_assignments',
	CONTRACTS: 'contracts',
};

const modals = {
	CANCEL: 'cancel',
	CANCELANDREFUND: 'cancel&refund',
	ITEM_CANCEL: 'itemCancel',
	ITEM_ONLY_REFUND: 'itemOnlyRefund',
	ITEM_SELECT: 'itemSelect',
	CUSTOMER: 'customer',
	BACK: 'back',
	PAY: 'pay',
	DEPOSIT: 'deposit',
	NOTE: 'note',
	UNSAVED: 'unsaved',
	REFUND: 'refund',
	AUTO_REFUND: 'auto_refund',
	NON_TRANSIENT_REFUND: 'non_transient_refund',
	ITEM_REFUND: 'itemRefund',
	PRINTER: 'printer',
	PAYMENT_REFUND: 'paymentRefund',
	PAYMENT_VOID_DIALOG: 'paymentVoidDialog',
	PAYMENT_CAPTURE_DIALOG: 'paymentCaptureDialog',
	OVERPAYMENT_REFUND: 'overPaymentRefund',
	RETAIN_DIALOG: 'retainDialog',
	RETAIN: 'retain',
	RELOAD: 'reload',
	CHECKOUT: 'checkout',
	CONTRACT: 'contract',
	PAYMENT_REFUND_VOID: 'payment_refund_void',
	EXTRA_CHARGE: 'extra_charges',
	PAYMENT_SCHEDULE: 'payment_schedule',
	CUSTOMER_PAYMENT_METHOD: 'customer_payment_method',
	MESSAGES: 'messages',
	ATTACHMENTS: 'attachments',
	SEND_EMAIL: 'send_email',
	AUTHORIZE_PAYMENT: 'authorize_payment',
	MANUALLY_EDITED_INVOICE_WARNING: 'manually_edited_invoice_warning',
	CAPTURE_AUTHORIZED_PAYMENT: 'capture_authorized_payment',
	VOID_AUTHORIZED_PAYMENT: 'void_authorized_payment',
	TERMINAL: 'terminal',
	DEPARTURE_DATE: 'departure_date',
	OVERPAYMENT_APPLY_INVOICE: 'overpayment_apply_invoice',
	DEPOSIT_CHECK_REFUND: 'deposit_check_refund',
	CONFIRMATION_MAIL: 'confirmation_mail',
};

const quantityModalProps = {
	retain: { title: 'Retain', buttonText: 'Retain' },
	overpayment_apply_invoice: { title: 'Apply To Invoice', buttonText: 'Apply' },
	deposit_check_refund: { title: 'Check Refund', buttonText: 'Refund' },
};

const Form = ({
	id,
	itemId,
	data,
	isLoading,
	isListsLoading,
	policies,
	paymentTypes,
	ratePlans,
	enumReservationStatuses,
	contracts,
	from,
	paymentTerms,
	countries,
	states,
	enumBoatTypes,
	enumBoatMakes,
	enumFuelTypes,
	enumRvTypes,
	enumVehicleMakes,
	invoiceFirstOptions,
	invoiceNextOptions,
	bookingExtraCharges,
	invoicingFrequencies,
	printers,
	printerLogActions,
	searchData,
	enumBookingCalculations,
	accounts,
	seasons,
	taxCodes,
	paymentSchedules,
	paymentMethods,
	customReservationStatuses,
	invoiceTypes,
}) => {
	const headerContext = useContext(HeaderContext);

	const messageContext = useContext(MessageContext);

	const userContext = useContext(UserContext);

	const location = useLocation();

	const history = useHistory();

	const { sendInvoice } = useSendInvoice();

	const form = useMemo(() => {
		const { pathname } = location;

		const module = userContext.data.user.company.modules.find(
			m => m.value === getModuleByPath(pathname)
		);

		const bookingPeriod = getBookingPeriodByPath(pathname);

		const productGridSettings = {
			Bookings: 'bookingProductGrid',
			Marina: 'marinaProductGrid',
			Campground: 'campgroundProductGrid',
		};

		return {
			isBooking: module.value === modules.BOOKINGS,
			isMarina: module.value === modules.MARINA,
			isCampground: module.value === modules.CAMPGROUND,
			isLongTerm: bookingPeriod === bookingPeriods.LONG_TERM,
			isSeasonal: bookingPeriod === bookingPeriods.SEASONAL,
			isNightly: bookingPeriod === bookingPeriods.NIGHTLY,
			isHourly: bookingPeriod === bookingPeriods.HOURLY,
			isTransient: !(
				bookingPeriod === bookingPeriods.LONG_TERM ||
				bookingPeriod === bookingPeriods.SEASONAL
			),
			module,
			bookingPeriod,
			modulePage: pages[modulePageKeys[module.value]],
			page: pages[modulePageKeys[module.value]][bookingPeriodPageKeys[bookingPeriod]],
			productGrid:
				userContext.data.selectedOutlet.settings[productGridSettings[module.value]],
		};
	}, [location, userContext.data]);

	const [productGrid, setProductGrid] = useState(null);

	const getInitScreen = () => {
		if (id && itemId) return { current: screens.ITEM_CAROUSEL, isLoading: true };

		if (id) return { current: screens.ITEM_LIST, isLoading: true };

		return { current: screens.GRID };
	};

	const [screen, setScreen] = useModal(getInitScreen());

	const previousScreen = useRef(null);

	const [modal, openModal, closeModal] = useModal();

	const [onPrint, PRINT_COMPONENT] = usePrint();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [reservation, setReservation] = useState(
		getDefaultReservation(userContext.data.user, form.module, userContext.data.selectedOutlet)
	);

	const [isPrinting, setIsPrinting] = useState(false);

	const hasNotCancelledItems = useMemo(
		() =>
			(reservation.items || []).filter(i => i.status.value !== reservationStatuses.CANCELLED)
				.length > 0,
		[reservation]
	);

	const taxRateRef = useRef(userContext.data.selectedOutlet.settings.taxRate);

	const webPrint = useRef({
		deposit: () => {},
		reservationRefund: () => {},
		reservationPayment: () => {},
		printers: [],
	});

	const onScreenChange = screenData => {
		previousScreen.current = { ...screen };
		setScreen(screenData);
	};

	const onBack = () => {
		if (from === 'availability') history.push(form.modulePage.availability.path);
		else if (from === 'dashboard') history.push(form.modulePage.dashboard.path);
		else history.push(form.modulePage.reservations.path);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	const getCustomerWithCreditCards = () => {
		if (modal.open === modals.PAYMENT_REFUND && modal.payment) {
			if (modal.payment.paymentMethod.paymentType.value !== _paymentTypes.CREDIT_CARD)
				return reservation.customer;

			if (
				reservation.customer.paymentMethods.findIndex(
					cc => cc.cardNumber === modal.payment.cardNumber
				) !== -1 ||
				!modal.payment.processor
			)
				return reservation.customer;

			const _customer = { ...reservation.customer };

			_customer.paymentMethods.push({
				...modal.payment,
				id: 0,
			});

			return _customer;
		}

		const customer = { ...reservation.customer };

		getReservationSettlements(reservation)
			.filter(
				cs =>
					cs.remittance.processor &&
					cs.remittance.paymentMethod &&
					cs.remittance.paymentMethod.paymentType.value === _paymentTypes.CREDIT_CARD &&
					cs.remittance.token
			)
			.forEach(cs => {
				if (
					customer.paymentMethods.findIndex(
						cc => cc.cardNumber === cs.remittance.cardNumber
					) === -1
				) {
					customer.paymentMethods.push({
						...cs.remittance,
						id: 0,
					});
				}
			});

		return customer;
	};

	const openPrinterModal = (_reservation, printData, type) =>
		openModal({
			open: modals.PRINTER,
			reservation: _reservation,
			printData,
			type,
		});

	const onBackToReservation = () => {
		if (reservationBeforeItemEdit.current) setReservation(reservationBeforeItemEdit.current);

		if (!reservation.id) {
			onScreenChange({ current: screens.GRID });
			return;
		}

		let _screen = { current: screens.ITEM_LIST };

		if (previousScreen.current?.current === screens.ITEM_FORM)
			_screen = { ...previousScreen.current };
		else if (!form.isTransient || previousScreen.current?.current === screens.ITEM_CAROUSEL)
			_screen = {
				current: screens.ITEM_CAROUSEL,
				reservationItemIndex: previousScreen.current?.reservationItemIndex || 0,
			};

		previousScreen.current = null;

		setScreen(_screen);
	};

	const isRefundModal = () =>
		modal.open === modals.REFUND ||
		modal.open === modals.PAYMENT_REFUND ||
		modal.open === modals.AUTO_REFUND;

	const onAddItem = product => {
		onScreenChange({
			current: screens.ITEM_FORM,
			reservationItem: {
				id: generateId(reservation.items),
				product,
				quantity: 1,
				status: enumReservationStatuses.find(
					ers => ers.value === reservationStatuses.RESERVED
				),
				enableDeferredIncome: product.enableDeferredIncome,
				deferredIncomeFrequency: product.deferredIncomeFrequency,
				deferredIncomeSalesAccount: product.deferredIncomeSalesAccount,
				taxCode: getItemTaxCode(
					reservation.customer ? reservation.customer.taxCode : null,
					product
				),
				extraCharges: [],
				vehicle: searchData && searchData.vehicle ? searchData.vehicle : null,
				vessel: searchData && searchData.vessel ? searchData.vessel : null,
				loa: searchData && searchData.loa ? searchData.loa : null,
				beam: searchData && searchData.beam ? searchData.beam : null,
				draft: searchData && searchData.draft ? searchData.draft : null,
				height: searchData && searchData.height ? searchData.height : null,
				weight: searchData && searchData.weight ? searchData.weight : null,
				fromDate: searchData && searchData.startDate ? searchData.startDate : null,
				toDate: searchData && searchData.endDate ? searchData.endDate : null,
				fixedPrice: false,
				invoiceGenerationAutomatedBatch:
					product.invoiceGenerationAutomatedBatch === null
						? true
						: product.invoiceGenerationAutomatedBatch,
				invoicingFrequency: product.invoicingFrequency,
				paymentTerm: product.paymentTerm,
				proratedFrequency: product.proratedFrequency,
				coTermDate: product.coTermDate,
				firstInvoice: product.firstInvoice,
				nextInvoice: product.nextInvoice,
				ignoredCapacity: searchData !== null,
				unit: searchData && searchData.unit ? searchData.unit : null,
				season: searchData && searchData.season ? searchData.season : null,
				note: '',
			},
		});
	};

	const onSave = (onSuccess = null, _reservation = null, onError = null) => {
		if (!reservation.customer) {
			addErrorNotification('Please select a customer');
			return;
		}

		setIsSubmitting(true);

		const __reservation = _reservation || reservation;

		const formData = JSON.parse(
			JSON.stringify({ ...__reservation, items: __reservation.items.filter(i => i.id > 0) })
		);

		formData.module = form.module;

		apiCall(
			reservation.id ? 'PUT' : 'POST',
			'reservations',
			res => {
				setIsSubmitting(false);
				if (onSuccess) onSuccess(res);
				else addSuccessNotification('Reservation successfully saved!');
				setReservation(res);
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				if (onError) onError(e);
				setIsSubmitting(false);
			},
			reservation.id || '',
			parseData(formData)
		);
	};

	const checkForOverride = (onSuccess = null, onError = null) => {
		if (!reservation.id) {
			if (onSuccess) onSuccess();

			return;
		}
		setIsSubmitting(true);

		apiCall(
			'POST',
			'reservationCheckForOverride',
			res => {
				setIsSubmitting(false);
				if (onSuccess) onSuccess(res);
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				if (onError) onError(e);
				setIsSubmitting(false);
			},
			'',
			{
				reservationId: reservation.id,
				timeModified: reservation.timeModified,
			}
		);
	};

	const onReload = (_closeModal = true) => {
		setIsSubmitting(true);
		apiCall(
			'GET',
			'reservations',
			res => {
				setIsSubmitting(false);
				setReservation(res);

				if (screen.current === screens.ITEM_FORM) onBackToReservation();

				if (_closeModal) closeModal();
			},
			err => {
				setIsSubmitting(false);
				addErrorNotification(err.toString());
				closeModal();
			},
			reservation.id
		);
	};

	const onItemUpdate = _reservation => {
		setReservation(_reservation);
		reservationBeforeItemEdit.current = null;
		onScreenChange({
			current: form.isTransient ? screens.ITEM_LIST : screens.ITEM_CAROUSEL,
			reservationItemIndex: 0,
		});
	};

	const onIncidentalChargeAdd = reservationItem => {
		if (!form.productGrid) addErrorNotification('Module is missing product grid');
		else if (!userContext.data.selectedRegister)
			addErrorNotification('Please select a register');
		else openModal({ open: modals.TERMINAL, reservationItem });
	};

	const onItemUpdateStatus = (reservationItemIds, status) => {
		setIsSubmitting(true);
		apiCall(
			'POST',
			'advancedReservationUpdateStatus',
			res => {
				closeModal();
				setReservation(res.reservation);
				addSuccessNotification('Booking(s) successfully updated');
				setIsSubmitting(false);

				if (Object.keys(res.contracts).length)
					openModal({ open: modals.CONTRACT, contracts: res.contracts });
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{
				reservationItems: reservationItemIds,
				reservation,
				status,
			}
		);
	};

	const onCheckIn = reservationItemIds => {
		setIsSubmitting(true);

		apiCall(
			'POST',
			'reservationCheckIn',
			res => {
				if (res.errors && res.errors.length) addErrorNotification(res.errors.join('\n'));
				else {
					addSuccessNotification('Successfully checked-in');

					onReload(false);
				}

				if (Object.keys(res.contracts).length)
					openModal({ open: modals.CONTRACT, contracts: res.contracts });
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{
				reservationItems: reservationItemIds,
				outletId: userContext.data.selectedOutlet.id,
				isAdvance: true,
				reservations: [reservation],
			}
		);
	};

	const onCheckOut = reservationItemIds => {
		setIsSubmitting(true);
		apiCall(
			'POST',
			'reservationCheckOut',
			res => {
				if (res.errors && res.errors.length) addErrorNotification(res.errors.join('\n'));
				else {
					setReservation(res.reservations.find(r => r.id === reservation.id));
					addSuccessNotification('Successfully checked-out');
				}

				setIsSubmitting(false);
				if (Object.keys(res.contracts).length)
					openModal({ open: modals.CONTRACT, contracts: res.contracts });
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{
				reservationItems: reservationItemIds,
				outletId: userContext.data.selectedOutlet.id,
				isAdvance: true,
				reservations: [reservation],
			}
		);
	};

	const onItemCancel = (reservationItemIds, cancellationReason) => {
		setIsSubmitting(true);
		apiCall(
			'POST',
			'advancedReservationCancelItem',
			() => {
				onReload();
				addSuccessNotification('Booking successfully cancelled');
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reservationItems: reservationItemIds,
				reservations: [reservation],
				isAdvanced: true,
				cancellationReason,
			}
		);
	};

	const onItemUpdateCustomStatus = (reservationItemIds, statusId, sendStatusEmail = null) => {
		const customStatus = customReservationStatuses.find(item => item.id === statusId);

		if (
			!customStatus.enableAutoSend &&
			customStatus.emailTemplate &&
			sendStatusEmail === null
		) {
			openModal({ open: modals.SEND_EMAIL, reservationItemIds, statusId });
			return;
		}

		setIsSubmitting(true);

		apiCall(
			'POST',
			'advancedReservationUpdateCustomStatus',
			res => {
				closeModal();
				setReservation(res.reservation);
				addSuccessNotification('Booking(s) successfully updated');
				setIsSubmitting(false);

				if (Object.keys(res.contracts).length)
					openModal({ open: modals.CONTRACT, contracts: res.contracts });
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{
				reservationItems: reservationItemIds,
				reservation,
				statusId,
				sendStatusEmail,
			}
		);
	};

	const onDepositPay = payment => {
		setIsSubmitting(true);
		apiCall(
			'POST',
			'payDeposit',
			res => {
				setReservation(res.reservation);

				closeModal();

				setIsSubmitting(false);

				if (res.payment && payment.printReceipt)
					printReservationReceipt(
						webPrint,
						res.reservation,
						{
							...res.payment,
							amount: res.payment.amount - (res.payment.serviceFee || 0),
						},
						reservationReceiptTypes.DEPOSIT,
						null,
						openPrinterModal
					);
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reservationId: reservation.id,
				customerId: reservation.customer.id,
				payment,
				isAdvanced: true,
			}
		);
	};

	const onPay = paymentData => {
		setIsSubmitting(true);
		const { isTransient } = form;
		apiCall(
			'POST',
			isTransient ? 'payReservation' : 'payNonTransientReservation',
			res => {
				setReservation(res.reservation);

				closeModal();

				setIsSubmitting(false);

				if (res.payment && paymentData.printReceipt)
					printReservationReceipt(
						webPrint,
						res.reservation,
						{
							...res.payment,
							amount: res.payment.amount - (res.payment.serviceFee || 0),
						},
						reservationReceiptTypes.PAYMENT,
						null,
						openPrinterModal
					);
			},
			err => {
				setIsSubmitting(false);
				addErrorNotification(err.toString());
			},
			'',
			isTransient
				? {
						outletId: userContext.data.selectedOutlet.id,
						reservationId: reservation.id,
						customerId: reservation.customer.id,
						payment: paymentData,
				  }
				: {
						payment: paymentData,
						reservation,
						reservationItem: reservation.items[0].id,
				  }
		);
	};

	const onRefund = refund => {
		setIsSubmitting(true);

		const reservationTotals = getReservationTotals(reservation, taxRateRef.current);

		apiCall(
			'POST',
			'advancedReservationOverPaymentRefund',
			res => {
				onReload(false);

				closeModal();

				if (res.refunds.length > 0) {
					printReservationReceipt(
						webPrint,
						res.reservation,
						res.refunds.map(_refund => {
							if (!_refund.paymentMethod)
								_refund.paymentMethod = {
									name: 'Credit',
									paymentType: { value: 'Credit' },
								};
							return _refund;
						}),
						reservationReceiptTypes.REFUND,
						null,
						openPrinterModal
					);
				}
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reservation,
				refund,
				lock:
					Math.abs(reservationTotals.remainingAmount).toString() ===
					refund.amount.toString(),
			}
		);
	};

	const onRetain = (amount, isDeposit = false) => {
		const reservationTotals = getReservationTotals(reservation, taxRateRef.current);

		setIsSubmitting(true);

		apiCall(
			'POST',
			'advancedReservationRetain',
			() => onReload(),
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{
				reservation,
				amount,
				isDeposit,
				lock: Math.abs(reservationTotals.remainingAmount).toString() === amount,
			}
		);
	};

	const onDepositOverPaymentApplyInvoice = (amount, invoiceIds) => {
		setIsSubmitting(true);

		apiCall(
			'POST',
			'advancedReservationDepositOverPaymentApplyInvoice',
			() => onReload(),
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{
				reservation,
				amount,
				invoiceIds,
			}
		);
	};

	const onDepositCheckRefund = amount => {
		setIsSubmitting(true);

		apiCall(
			'POST',
			'advancedReservationDepositCheckRefund',
			() => onReload(),
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{
				reservation,
				amount,
			}
		);
	};

	const onAutoRefund = refund => {
		setIsSubmitting(true);

		apiCall(
			'POST',
			'advancedReservationAutoRefund',
			res => {
				onReload(false);

				closeModal();

				if (res.refunds.length > 0) {
					printReservationReceipt(
						webPrint,
						res.reservation,
						res.refunds.map(_refund => {
							if (!_refund.paymentMethod)
								_refund.paymentMethod = {
									name: 'Credit',
									paymentType: { value: 'Credit' },
								};
							return _refund;
						}),
						reservationReceiptTypes.REFUND,
						null,
						openPrinterModal
					);
				}
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{
				reservation,
				refund,
			}
		);
	};

	const onConfirmationEmailSend = mail => {
		apiCall(
			'POST',
			'sendConfirmationEmail',
			res => {
				addSuccessNotification(res.message);
			},
			err => addErrorNotification(err.toString()),
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reservationId: reservation.id,
				mail,
			}
		);
	};

	const onAutoPayChange = () => {
		setIsSubmitting(true);
		apiCall(
			'POST',
			'reservationChangeAutoPay',
			res => {
				addSuccessNotification('Auto Pay successfully updated.');
				setReservation(res);
				setIsSubmitting(false);
			},
			err => {
				setIsSubmitting(false);
				addErrorNotification(err.toString());
			},
			'',
			{
				id: reservation.id,
				autoPay: !reservation.autoPay,
			}
		);
	};

	const onPaymentScheduleChange = paymentSchedule => {
		setIsSubmitting(true);
		apiCall(
			'POST',
			'reservationChangePaymentSchedule',
			res => {
				addSuccessNotification('Payment schedule successfully updated.');
				setReservation(res);
				setIsSubmitting(false);
				closeModal();
			},
			err => {
				setIsSubmitting(false);
				addErrorNotification(err.toString());
				closeModal();
			},
			'',
			{
				id: reservation.id,
				paymentSchedule,
			}
		);
	};

	const onCustomerPaymentMethodChange = customerPaymentMethod => {
		setIsSubmitting(true);
		apiCall(
			'POST',
			'reservationChangeCustomerPaymentMethod',
			res => {
				addSuccessNotification('Payment method successfully updated.');
				setReservation(res);
				setIsSubmitting(false);
				closeModal();
			},
			err => {
				setIsSubmitting(false);
				addErrorNotification(err.toString());
				closeModal();
			},
			'',
			{
				id: reservation.id,
				customerPaymentMethod,
			}
		);
	};

	const onInvoicePrint = (invoiceId = null) => {
		apiCall(
			'POST',
			'getPrintInvoiceContent',
			res => {
				onPrint(res.invoices.join('<p style="page-break-before: always">'));
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reservationId: invoiceId ? null : data.id,
				invoiceIds: invoiceId ? [invoiceId] : null,
			}
		);
	};

	const onInvoiceEmailSend = (invoiceId = null) => {
		sendInvoice({
			outletId: userContext.data.selectedOutlet.id,
			reservationId: invoiceId ? null : data.id,
			invoiceIds: invoiceId ? [invoiceId] : null,
		});
	};

	const onTransientFolioPrint = () => {
		setIsPrinting(true);
		apiCall(
			'POST',
			'getPrintTransientFolioContent',
			res => {
				setIsPrinting(false);
				onPrint(res.transientFolio);
			},
			err => {
				setIsPrinting(false);
				addErrorNotification(err.toString());
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reservationId: reservation.id,
			}
		);
	};

	const onTransientFolioEmailSend = () => {
		setIsPrinting(true);
		apiCall(
			'POST',
			'sendTransientFolioEmail',
			() => {
				addSuccessNotification('Email successfully sent');
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reservationId: reservation.id,
			}
		);
	};

	const onCancel = cancellationReason => {
		setIsSubmitting(true);
		apiCall(
			'POST',
			'advancedReservationCancel',
			() => {
				addSuccessNotification('Reservation successfully cancelled');
				onReload(true);
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{
				reservation,
				cancellationReason,
			}
		);
	};

	const onReceiptPrint = (printData, isRefund = false) => {
		let type = reservationReceiptTypes.PAYMENT;

		if (isRefund) type = reservationReceiptTypes.REFUND;

		if (printData.isDeposit) type = reservationReceiptTypes.DEPOSIT;

		printReservationReceipt(webPrint, reservation, printData, type, null, openPrinterModal);
	};

	const onReceiptEmail = payment => {
		apiCall(
			'POST',
			'sendPaymentEmail',
			() => {
				addSuccessNotification('Receipt has been emailed.');
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{
				paymentIds: [payment.id],
				outletId: userContext.data.selectedOutlet.id,
			}
		);
	};

	const onPaymentRefund = (payment, refund = null) => {
		setIsSubmitting(true);

		apiCall(
			'POST',
			'advancedReservationRefund',
			res => {
				setReservation(res.reservation);

				closeModal();

				setIsSubmitting(false);

				if (res.refunds.length > 0)
					printReservationReceipt(
						webPrint,
						res.reservation,
						res.refunds.map(_refund => {
							if (!_refund.paymentMethod)
								_refund.paymentMethod = {
									name: 'Credit',
									paymentType: { value: 'Credit' },
								};
							return _refund;
						}),
						reservationReceiptTypes.REFUND,
						null,
						openPrinterModal
					);
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reservation,
				payment,
				refund,
			}
		);
	};

	const onScheduledPaymentCapture = () => {
		setIsSubmitting(true);

		apiCall(
			'POST',
			'captureSchedulePayment',
			res => {
				onReload();

				if (res.success) addSuccessNotification('Payment successfully captured.');
				else addErrorNotification(res.message);
			},
			e => {
				if (e.toString().includes('override')) openModal({ open: modals.RELOAD });
				else addErrorNotification(e.toString());
				setIsSubmitting(false);
			},
			'',
			{ payment: modal.payment, outletId: userContext.data.selectedOutlet.id }
		);
	};

	const onAuthorizedPaymentVoid = payment => {
		setIsSubmitting(true);

		apiCall(
			'POST',
			'voidAuthorizedPayment',
			() => {
				addSuccessNotification('Successfully voided');
				onReload(true);
			},
			err => {
				addErrorNotification(err.toString());
				setIsSubmitting(false);
			},
			'',
			{
				id: payment.id,
				entityId: reservation.id,
				entityType: 'reservation',
			}
		);
	};

	const onRefundVoid = refund => {
		setIsSubmitting(true);
		apiCall(
			'POST',
			'voidRefund',
			() => onReload(),
			err => {
				setIsSubmitting(false);
				addErrorNotification(err.toString());
			},
			'',
			{
				id: refund.id,
				reservationId: reservation.id,
			}
		);
	};

	const reservationBeforeItemEdit = useRef(null);

	const getContent = () => {
		if (screen.current === screens.ITEM_LIST)
			return (
				<ItemList
					reservation={reservation}
					taxRate={taxRateRef.current}
					isLoading={screen.isLoading}
					isSubmitting={isSubmitting}
					disabled={
						reservation.isLocked && !hasEditLockedReservationPermission(userContext)
					}
					onEdit={reservationItem => {
						reservationBeforeItemEdit.current = JSON.parse(JSON.stringify(reservation));
						onScreenChange({ current: screens.ITEM_FORM, reservationItem });
					}}
					onView={reservationItem =>
						onScreenChange({
							current: screens.ITEM_CAROUSEL,
							reservationItemIndex: reservation.items.findIndex(
								ri => ri.id === reservationItem.id
							),
						})
					}
					onAdd={
						form.isTransient ? () => onScreenChange({ current: screens.GRID }) : null
					}
					onItemUpdateStatus={onItemUpdateStatus}
					onCheckIn={onCheckIn}
					onCheckOut={reservationItem => {
						if (!form.isTransient || isEarlyCheckout(reservationItem))
							openModal({ open: modals.CHECKOUT, reservationItem });
						else onCheckOut([reservationItem.id]);
					}}
					onCancel={reservationItemIds =>
						openModal({ open: modals.ITEM_CANCEL, reservationItemIds })
					}
					onItemUpdateCustomStatus={onItemUpdateCustomStatus}
					onIncidentalChargeAdd={onIncidentalChargeAdd}
					onIncidentalChargeView={() => onScreenChange({ current: screens.INVOICES })}
					onUnitChange={() => onReload()}
				/>
			);

		if (screen.current === screens.ITEM_CAROUSEL)
			return (
				<ItemCarousel
					key={screen.reservationItemIndex || 0}
					reservation={reservation}
					initReservationItemIndex={screen.reservationItemIndex || 0}
					taxRate={taxRateRef.current}
					isLoading={screen.isLoading}
					isSubmitting={isSubmitting}
					disabled={
						reservation.isLocked && !hasEditLockedReservationPermission(userContext)
					}
					onEdit={reservationItem => {
						reservationBeforeItemEdit.current = JSON.parse(JSON.stringify(reservation));
						onScreenChange({ current: screens.ITEM_FORM, reservationItem });
					}}
					onAdd={
						form.isTransient ? () => onScreenChange({ current: screens.GRID }) : null
					}
					onBack={
						form.isTransient
							? () => onScreenChange({ current: screens.ITEM_LIST })
							: null
					}
					onItemUpdateStatus={onItemUpdateStatus}
					onCheckIn={onCheckIn}
					onCheckOut={reservationItem => {
						if (!form.isTransient || isEarlyCheckout(reservationItem))
							openModal({ open: modals.CHECKOUT, reservationItem });
						else onCheckOut([reservationItem.id]);
					}}
					onCancel={reservationItemIds =>
						openModal({ open: modals.ITEM_CANCEL, reservationItemIds })
					}
					onItemUpdateCustomStatus={onItemUpdateCustomStatus}
					onIncidentalChargeAdd={onIncidentalChargeAdd}
					onIncidentalChargeView={() => onScreenChange({ current: screens.INVOICES })}
					onUnitChange={() => onReload()}
					onCardChange={reservationItemIndex => {
						screen.reservationItemIndex = reservationItemIndex;
					}}
					onDepartureDateSet={reservationItem =>
						openModal({ open: modals.DEPARTURE_DATE, reservationItem })
					}
				/>
			);

		if (screen.current === screens.ITEM_FORM && screen.reservationItem)
			return (
				<ItemForm
					reservation={reservation}
					module={form?.module?.value}
					reservationItem={screen.reservationItem}
					onBack={onBackToReservation}
					onFormChange={() => {}}
					taxRate={taxRateRef.current}
					seasons={seasons}
					enumBookingCalculations={enumBookingCalculations}
					policies={policies}
					ratePlans={ratePlans}
					taxCodes={taxCodes}
					invoicingFrequencies={invoicingFrequencies}
					invoiceFirstOptions={invoiceFirstOptions}
					invoiceNextOptions={invoiceNextOptions}
					paymentTerms={paymentTerms}
					accounts={accounts}
					states={states}
					countries={countries}
					contracts={contracts}
					onCustomerChange={customer => setReservation({ ...reservation, customer })}
					onFromDateChange={fromDate =>
						setScreen({
							...screen,
							reservationItem: {
								...screen.reservationItem,
								fromDate,
							},
						})
					}
					onTotalsChange={totals =>
						setScreen({
							...screen,
							reservationItem: {
								...screen.reservationItem,
								...totals,
							},
						})
					}
					onVesselChange={vessel =>
						setScreen({
							...screen,
							reservationItem: {
								...screen.reservationItem,
								vessel,
								loa: vessel?.loa || screen.reservationItem.loa,
								beam: vessel?.beam || screen.reservationItem.beam,
								draft: vessel?.draft || screen.reservationItem.draft,
								height: vessel?.height || screen.reservationItem.height,
								weight: vessel?.weight || screen.reservationItem.weight,
							},
						})
					}
					onVehicleChange={vehicle =>
						setScreen({
							...screen,
							reservationItem: {
								...screen.reservationItem,
								vehicle,
								loa: vehicle?.loa || screen.reservationItem.loa,
								beam: vehicle?.beam || screen.reservationItem.beam,
								height: vehicle?.height || screen.reservationItem.height,
							},
						})
					}
					onInvoicingChange={extraCharges =>
						setScreen({
							...screen,
							reservationItem: {
								...screen.reservationItem,
								extraCharges,
							},
						})
					}
					enumBoatMakes={enumBoatMakes}
					enumBoatTypes={enumBoatTypes}
					enumFuelTypes={enumFuelTypes}
					enumRvTypes={enumRvTypes}
					enumVehicleMakes={enumVehicleMakes}
					onUpdate={onItemUpdate}
					onOverride={() => openModal({ open: modals.RELOAD })}
				/>
			);

		if (screen.current === screens.INVOICES)
			return (
				<InvoiceList
					filters={{ reservationId: reservation.id }}
					presetData={{
						customer: reservation.customer,
						reservation,
						paymentTerm: null,
						invoiceDate: moment()
							.utc(false)
							.toISOString(),
						dueDate: null,
						vessel:
							reservation?.items.length && !form.isTransient
								? reservation.items[0].vessel
								: null,
						type: invoiceTypes.find(it => it.value === constInvoiceTypes.RESERVATION),
					}}
					disabled={reservation.id === 0}
					openInCrm={form.isTransient}
					isTransient={form.isTransient}
					headerActions={[
						{
							text: 'Back to Reservation',
							design: 'default',
							onClick: onBackToReservation,
							outline: false,
						},
					]}
					onChange={() => onReload(false)}
					onPaymentPrint={onReceiptPrint}
					onPrint={onInvoicePrint}
					onSend={onInvoiceEmailSend}
				/>
			);

		if (screen.current === screens.PAYMENTS)
			return (
				<PaymentList
					isReservation
					paymentTypes={paymentTypes}
					filters={{
						reservation: reservation.id,
					}}
					presetData={{
						customer: reservation.customer,
						timeCreated: moment().toISOString(),
						outlet: userContext.data.selectedOutlet,
						automaticallySettle: true,
						changeDue: 0,
						reservation,
					}}
					disabled
					webPrint={webPrint}
					headerActions={[
						{
							text: 'Back to Reservation',
							design: 'default',
							onClick: onBackToReservation,
							outline: false,
						},
					]}
					openInCrm
					onChange={() => onReload(false)}
					onEditAuthorizedPayment={(payment = null) => {
						checkForOverride(() => {
							openModal({
								open: modals.AUTHORIZE_PAYMENT,
								payment,
							});
						});
					}}
					onCaptureAuthorizedPayment={(payment = null) => {
						checkForOverride(() => {
							openModal({
								open: modals.CAPTURE_AUTHORIZED_PAYMENT,
								payment,
								isCapture: true,
							});
						});
					}}
					onVoidAuthorizedPayment={payment => {
						checkForOverride(() => {
							openModal({
								open: modals.VOID_AUTHORIZED_PAYMENT,
								payment,
							});
						});
					}}
				/>
			);

		if (screen.current === screens.SPACE_ASSIGNMENTS)
			return (
				<SpaceAssignmentList
					filters={{
						'reservationItem.id': reservation.items[0].id,
					}}
					presetData={{
						loa: reservation.items[0].loa,
						beam: reservation.items[0].beam,
						height: reservation.items[0].height,
						draft: reservation.items[0].draft,
						weight: reservation.items[0].weight,
						reservationItem: reservation.items[0],
						minDate: reservation.items[0].fromDate,
						maxDate: reservation.items[0].toDate,
						vessel: reservation.items[0].vessel,
						vehicle: reservation.items[0].vehicle,
					}}
					onChange={() => onReload(false)}
					disabled={reservation.items[0].status.value === reservationStatuses.CANCELLED}
					headerActions={[
						{
							text: 'Back to Reservation',
							design: 'default',
							onClick: onBackToReservation,
							outline: false,
						},
					]}
				/>
			);

		if (screen.current === screens.CONTRACTS)
			return (
				<ContractList
					module={form.module.value}
					reservation={reservation}
					reservationItem={
						screen.reservationItem || reservation.items[screen.reservationItemIndex]
					}
					headerActions={[
						{
							text: 'Back to Reservation',
							design: 'default',
							onClick: onBackToReservation,
							outline: false,
						},
					]}
					isFluid
				/>
			);

		if (screen.current === screens.DEPOSITS)
			return (
				<DepositDetails
					reservation={reservation}
					depositDetails={screen.depositDetails}
					onDepositPay={amount =>
						checkForOverride(() =>
							openModal({
								open: modals.DEPOSIT,
								amount,
							})
						)
					}
					onApplyInvoice={amount =>
						checkForOverride(() =>
							openModal({ open: modals.OVERPAYMENT_APPLY_INVOICE, amount })
						)
					}
					onRefund={(amount, defaultPaymentMethod = null) =>
						checkForOverride(() =>
							openModal({
								open: modals.REFUND,
								amount,
								defaultPaymentMethod,
								isDeposit: true,
							})
						)
					}
					onRetain={amount =>
						checkForOverride(() =>
							openModal({ open: modals.RETAIN, amount, isDeposit: true })
						)
					}
					onCheckRefund={amount =>
						checkForOverride(() =>
							openModal({ open: modals.DEPOSIT_CHECK_REFUND, amount })
						)
					}
					onBack={onBackToReservation}
				/>
			);

		return (
			<ReservationAdvanceGrid
				onClick={product => {
					if (reservation.id)
						checkForOverride(_reservation => onAddItem(product, _reservation));
					else onAddItem(product);
				}}
				module={form.module.value}
				onlyGrid
				customFilters={getReservationGridCustomFilters(form.bookingPeriod)}
				enableSearchMore={false}
				onBack={data.id === 0 ? null : onBackToReservation}
			/>
		);
	};

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: form.modulePage.default.text,
				path: form.modulePage.dashboard.path,
			},
			{
				title: form.modulePage.reservations.text,
				path: form.modulePage.reservations.path,
			},
			{
				title: `Reservation ${reservation.reservationId || 'New'}`,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(`Reservation ${reservation.reservationId || 'New'}`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservation.reservationId, form]);

	useEffect(() => {
		if (data.id && !isLoading) {
			setReservation(data);

			let _itemId = itemId;

			if (data.items.length && !data.items.some(i => i.d === _itemId) && !form.isTransient) {
				_itemId = data.items[0].id;
			}

			// get item by query string.
			const reservationItem = data.items.find(i => i.id === _itemId);

			if (reservationItem) {
				setScreen({
					current: screens.ITEM_CAROUSEL,
					reservationItemIndex: data.items.findIndex(i => i.id === reservationItem.id),
					isLoading: false,
				});

				// to fix losing history state on page reload.
				history.replace(
					() => {
						if (form.isBooking)
							return `${form.modulePage.reservations.advanced.path}/${data.id}`;

						if (form.isLongTerm)
							return `${form.modulePage.reservations.longTerm.path}/${data.id}`;

						if (form.isSeasonal)
							return `${form.modulePage.reservations.seasonal.path}/${data.id}`;

						return `${form.modulePage.reservations.transient.path}/${data.id}`;
					},
					{
						itemId: _itemId,
						from,
					}
				);
			} else setScreen({ current: screens.ITEM_LIST, isLoading: false });

			if (data.hasManuallyEditedInvoice)
				openModal({ open: modals.MANUALLY_EDITED_INVOICE_WARNING });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		if (!isListsLoading) {
			webPrint.current = new WebPrint({
				user: userContext.data.user,
				outlet: userContext.data.selectedOutlet,
				printers,
				enumPrinterLogActions: printerLogActions,
				onStart: () => addSuccessNotification('Printing...'),
				onFinish: () => {},
				onFail: (printItem, err) => addErrorNotification(err.toString()),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isListsLoading]);

	useEffect(() => {
		if (form.productGrid && !isLoading) {
			apiCall(
				'GET',
				'grids',
				res => {
					setProductGrid(res);
				},
				() => {},
				form.productGrid.id,
				null,
				{ 'groups[]': 'terminal:read' }
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.productGrid, isLoading]);

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title='Reservation' />
					<BreadcrumbContainer />
				</ContentInner.SubHeaderItem>
				<ContentInner.SubHeaderItem type='toolbar'>
					<>
						<Dropdown
							title='Transactions'
							label='default'
							color='default'
							aligned='right'
							outline={false}
							arrow={false}
							disabled={reservation.id === 0}>
							<Dropdown.Item
								icon='Clipboard-list'
								onClick={() => onScreenChange({ current: screens.INVOICES })}>
								Invoices
							</Dropdown.Item>
							<Dropdown.Item
								icon='Wallet'
								onClick={() => onScreenChange({ current: screens.PAYMENTS })}>
								Remittances
							</Dropdown.Item>
							<Dropdown.Item icon='Clock' isDisabled>
								Activity
							</Dropdown.Item>
						</Dropdown>
						{!form.isTransient && (
							<Button
								design='default'
								text='Space Assignments'
								onClick={() =>
									onScreenChange({ current: screens.SPACE_ASSIGNMENTS })
								}
								disabled={
									reservation.id === 0 ||
									reservation.items.some(i => i.product.isVirtual)
								}
							/>
						)}
						<Button
							design='default'
							text='Contracts'
							onClick={() => onScreenChange({ current: screens.CONTRACTS })}
							disabled={
								reservation.id === 0 ||
								!(
									screen.current === screens.ITEM_CAROUSEL ||
									screen.current === screens.ITEM_FORM
								)
							}
						/>
						{messageContext.isEnabled && (
							<MessagesButton
								onClick={() => openModal({ open: modals.MESSAGES })}
								disabled={reservation.id === 0}
							/>
						)}
						<Button
							design='default'
							text='Attachments'
							onClick={() => openModal({ open: modals.ATTACHMENTS })}
							disabled={reservation.id === 0}
						/>
						<Dropdown
							title='Options'
							label='default'
							color='default'
							aligned='right'
							outline={false}
							arrow={false}
							disabled={reservation.id === 0}>
							{(hasNotCancelledItems || reservation?.extraCharges?.length > 0) &&
								form.isTransient && (
									<Dropdown.Item
										icon='Component'
										onClick={() =>
											checkForOverride(
												openModal({ open: modals.EXTRA_CHARGE })
											)
										}>
										Price Adjustment
									</Dropdown.Item>
								)}
							<Dropdown.Item
								icon='Mail-box'
								onClick={() =>
									checkForOverride(() =>
										openModal({
											open: modals.CONFIRMATION_MAIL,
										})
									)
								}>
								Send Confirmation Mail
							</Dropdown.Item>
							{hasNotCancelledItems &&
								form.isTransient &&
								(reservation.invoices || []).filter(
									i => i.status.value === invoiceStatuses.PAID
								).length === 0 && (
									<Dropdown.Item
										icon='Money'
										onClick={() => checkForOverride(onAutoPayChange)}>
										Turn Auto Pay {reservation.autoPay ? 'Off' : 'On'}
									</Dropdown.Item>
								)}
							{hasNotCancelledItems && paymentSchedules.length > 0 && (
								<>
									<Dropdown.Item
										icon='Wallet'
										onClick={() =>
											checkForOverride(() =>
												openModal({ open: modals.PAYMENT_SCHEDULE })
											)
										}>
										Payment Schedule
									</Dropdown.Item>{' '}
									<Dropdown.Item
										icon='Wallet#3'
										onClick={() =>
											checkForOverride(() =>
												openModal({
													open: modals.CUSTOMER_PAYMENT_METHOD,
												})
											)
										}>
										Payment Method
									</Dropdown.Item>
								</>
							)}
							{hasNotCancelledItems && (
								<Dropdown.Item
									icon='Wallet#3'
									onClick={() =>
										checkForOverride(() => {
											openModal({
												open: modals.AUTHORIZE_PAYMENT,
											});
										})
									}>
									Authorize Payment
								</Dropdown.Item>
							)}
							{hasReservationInvoice(reservation) && (
								<>
									<Dropdown.Item
										icon='Printer'
										text='Print Invoice'
										key='printInvoice'
										onClick={() => checkForOverride(() => onInvoicePrint())}>
										Print Invoice
									</Dropdown.Item>
									<Dropdown.Item
										icon='Mail-box'
										text='Email Invoice'
										key='emailInvoice'
										onClick={() =>
											checkForOverride(() => onInvoiceEmailSend())
										}>
										Email Invoice
									</Dropdown.Item>
								</>
							)}
							<Dropdown.Item
								icon='Printer'
								disabled={isPrinting || reservation.id === 0}
								onClick={() => checkForOverride(() => onTransientFolioPrint())}>
								Print Transient Folio
							</Dropdown.Item>
							<Dropdown.Item
								icon='Mail-box'
								disabled={isPrinting || reservation.id === 0}
								onClick={() => checkForOverride(() => onTransientFolioEmailSend())}>
								Email Transient Folio
							</Dropdown.Item>
							{hasNotCancelledItems && (
								<Dropdown.Item
									icon='Error-circle'
									itemsColor='danger'
									onClick={() =>
										checkForOverride(() =>
											openModal({
												open: modals.CANCEL,
											})
										)
									}>
									Cancel
								</Dropdown.Item>
							)}
						</Dropdown>
						<Button design='default' text='Back' onClick={onBack} />
					</>
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container title={`Reservation ${data.reservationId || 'New'}`} hasFrame>
				<div id='sdms_reservation_list' className='row h-100'>
					<div
						className={classNames('col-md-12 col-12', {
							'col-lg-9 sdms-advanced-reservation-content':
								screen.current !== screens.ITEM_FORM,
							'col-lg-12': screen.current === screens.ITEM_FORM,
						})}>
						{getContent()}
					</div>
					{screen.current !== screens.ITEM_FORM && (
						<div className='col-lg-3 col-md-12 col-12 sdms-advanced-reservation-summary'>
							<Summary
								reservation={reservation}
								taxRate={taxRateRef.current}
								isLoading={isLoading}
								disabled={isSubmitting}
								onDepositPay={amount =>
									checkForOverride(() =>
										openModal({
											open: modals.DEPOSIT,
											amount,
										})
									)
								}
								onPay={amount =>
									checkForOverride(() =>
										openModal({
											open: modals.PAY,
											amount,
										})
									)
								}
								onRefund={amount =>
									checkForOverride(() =>
										openModal({
											open: modals.REFUND,
											amount,
										})
									)
								}
								onRetain={amount =>
									checkForOverride(() =>
										openModal({ open: modals.RETAIN, amount })
									)
								}
								onReceiptPrint={onReceiptPrint}
								onReceiptEmail={onReceiptEmail}
								onPaymentRefund={(payment, amount) =>
									checkForOverride(() =>
										openModal({
											open: modals.PAYMENT_REFUND,
											payment,
											amount,
										})
									)
								}
								onScheduledPaymentCapture={payment =>
									checkForOverride(() =>
										openModal({ open: modals.PAYMENT_CAPTURE_DIALOG, payment })
									)
								}
								onPaymentVoid={payment =>
									checkForOverride(() =>
										openModal({ open: modals.PAYMENT_VOID_DIALOG, payment })
									)
								}
								onAuthorizedPaymentEdit={(payment = null) => {
									checkForOverride(() => {
										openModal({
											open: modals.AUTHORIZE_PAYMENT,
											payment,
										});
									});
								}}
								onAuthorizedPaymentCapture={(payment = null) => {
									checkForOverride(() => {
										openModal({
											open: modals.CAPTURE_AUTHORIZED_PAYMENT,
											payment,
											isCapture: true,
										});
									});
								}}
								onAuthorizedPaymentVoid={payment => {
									checkForOverride(() => {
										openModal({
											open: modals.VOID_AUTHORIZED_PAYMENT,
											payment,
										});
									});
								}}
								onRefundVoid={refund =>
									checkForOverride(() =>
										openModal({
											open: modals.PAYMENT_REFUND_VOID,
											refund,
										})
									)
								}
								onExtraChargeRemove={extraChargeItem => {
									onExtraChargeItemUpdate(
										{ ...extraChargeItem, isChecked: false },
										reservation.extraCharges || [],
										_extraCharges => {
											const _reservation = {
												...reservation,
												extraCharges: _extraCharges,
											};
											onSave(null, _reservation);
										},
										{
											bookingPeriod: null,
											quantity: 1,
											subtotal: getReservationTotals(
												reservation,
												taxRateRef.current,
												true
											).subtotalWithoutExtraCharges,
											taxCode: data.taxCode,
										},
										taxRateRef.current
									);
								}}
								onDepositView={depositDetails =>
									onScreenChange({ current: screens.DEPOSITS, depositDetails })
								}
								viewingDepositScreen={screens.DEPOSITS === screen.current}
								onAutoRefund={amount =>
									checkForOverride(() =>
										openModal({
											open: modals.AUTO_REFUND,
											amount,
										})
									)
								}
							/>
						</div>
					)}
				</div>
			</ContentInner.Container>
			<Cancellation
				open={modal.open === modals.CANCEL || modal.open === modals.ITEM_CANCEL}
				isSubmitting={isSubmitting}
				onCancel={cancellationReason => {
					if (modal.open === modals.CANCEL) onCancel(cancellationReason);
					else onItemCancel(modal.reservationItemIds, cancellationReason);
				}}
				onClose={closeModal}
			/>
			<SendEmail
				open={modal.open === modals.SEND_EMAIL}
				onSubmit={sendEmail =>
					onItemUpdateCustomStatus(modal.reservationItemIds, modal.statusId, sendEmail)
				}
			/>
			<Contract
				isOpen={modal.open === modals.CONTRACT}
				onClose={closeModal}
				contract={modal.contracts ? Object.values(modal.contracts)[0] : null}
				reservationItem={
					modal.contracts
						? reservation.items.find(
								i => i.id.toString() === Object.keys(modal.contracts)[0]
						  )
						: null
				}
				reservation={reservation}
				module={form.module.value}
			/>
			{modal.open === modals.CHECKOUT && (
				<Checkout
					key={`checkout${modal.reservationItem?.id || 0}`}
					status={modal.open === modals.CHECKOUT}
					onClose={closeModal}
					afterCheckout={(_reservation, _contracts) => {
						setReservation(_reservation);
						if (Object.keys(_contracts).length)
							openModal({ open: modals.CONTRACT, _contracts });
					}}
					reservation={reservation}
					reservationItem={modal.reservationItem}
					isTransient={form.isTransient}
					defaultToDate={
						form.isTransient ? moment(modal?.reservationItem?.toDate).utc(false) : null
					}
				/>
			)}
			{modal.open === modals.DEPARTURE_DATE && (
				<DepartureDate
					key={`departureDate${modal.reservationItem?.id || 0}`}
					status={modal.open === modals.DEPARTURE_DATE}
					onClose={closeModal}
					afterSubmit={(_reservation, _contracts) => {
						setReservation(_reservation);

						if (Object.keys(_contracts).length)
							openModal({ open: modals.CONTRACT, _contracts });
					}}
					reservationItem={modal.reservationItem}
					defaultDepartureDate={
						modal.reservationItem?.hasCustomToDate
							? moment(modal.reservationItem.toDate).utc(false)
							: null
					}
					defaultAutoCheckout={modal?.reservationItem?.autoCheckout}
				/>
			)}
			<Override
				reservation={reservation}
				open={modal.open === modals.RELOAD}
				onReload={onReload}
				onClose={closeModal}
			/>
			<PayModal
				isOpen={
					modal.open === modals.PAY ||
					modal.open === modals.DEPOSIT ||
					modal.open === modals.REFUND ||
					modal.open === modals.PAYMENT_REFUND ||
					modal.open === modals.AUTO_REFUND
				}
				onClose={() => {
					if (
						modal.open === modals.PAY ||
						modal.open === modals.DEPOSIT ||
						modal.open === modals.REFUND ||
						modal.open === modals.PAYMENT_REFUND ||
						modal.open === modals.AUTO_REFUND
					)
						closeModal();
				}}
				paymentTypes={getPaymentTypes(
					isRefundModal(),
					paymentTypes,
					userContext.hasPermission('cash_payments')
				)}
				amount={
					modal.amount ||
					(form.isTransient ? 0 : parseFloat(process.env.REACT_APP_PRICE_UPPER_LIMIT))
				}
				customer={getCustomerWithCreditCards()}
				balanceDue={modal.amount ? 0 : modal.balanceDue}
				onPay={paymentData => {
					switch (modal.open) {
						case modals.PAY:
							if (
								hasReservationInvoice(reservation) ||
								(userContext.data.selectedOutlet.settings
									.enablePrePaymentForNonTransient &&
									!form.isTransient)
							)
								onPay(paymentData);
							else
								onDepositPay({
									...paymentData,
									isDeposit: false,
								});

							break;
						case modals.DEPOSIT:
							onDepositPay(paymentData);
							break;
						case modals.REFUND:
							onRefund({ ...paymentData, isDeposit: modal.isDeposit || false });
							break;
						case modals.AUTO_REFUND:
							onAutoRefund(paymentData);
							break;
						case modals.PAYMENT_REFUND:
							onPaymentRefund(modal.payment, paymentData);
							break;
						default:
							addErrorNotification('Invalid action');
							break;
					}
				}}
				isPaying={isSubmitting}
				canSavePaymentMethod={userContext.hasPermission('save_customer_payment_methods')}
				canEnterCreditCard={userContext.hasPermission('key_in_credit_cards')}
				isRefund={isRefundModal()}
				isDeposit={modal.open === modals.DEPOSIT}
				refundAmountChangeable
				max={modal.amount}
				defaultCreditCard={isRefundModal() ? getDefaultCreditCard(modal) : null}
				activePaymentMethod={getDefaultPaymentMethod(modal)}
				paymentGateway={userContext.data.selectedOutlet.settings.paymentGateway}
				serviceFeeModule={getServiceFeeModule(form.module.value, form.bookingPeriod)}
				disableServiceFee={isRefundModal()}
				authorizedPayment={reservation.authorizedPayment}
				outlet={userContext.data.selectedOutlet}
				isPrePayment={
					!hasReservationInvoice(reservation) &&
					userContext.data.selectedOutlet.settings.enablePrePaymentForNonTransient &&
					!form.isTransient
				}
				automaticallySettleDefault={
					userContext.data.selectedOutlet?.settings?.automaticallySettle || false
				}
			/>
			<PrinterModal
				onClose={closeModal}
				printers={webPrint.current.printers}
				isOpen={modal.open === modals.PRINTER}
				enableRemember
				onSubmit={printer => {
					let { printData, type } = modal;

					if (modal.payment) {
						printData = modal.payment;

						type = modal.payment.isDeposit
							? reservationReceiptTypes.DEPOSIT
							: reservationReceiptTypes.PAYMENT;
					} else if (modal.refund) {
						printData = modal.refund;

						type = reservationReceiptTypes.REFUND;
					}

					printReservationReceipt(
						webPrint,
						modal.reservation,
						printData,
						type,
						printer.id,
						openPrinterModal
					);
					closeModal();
				}}
			/>
			<ScheduledPaymentCapture
				open={modal.open === modals.PAYMENT_CAPTURE_DIALOG}
				isSubmitting={isSubmitting}
				onCapture={onScheduledPaymentCapture}
				onClose={closeModal}
			/>
			<PaymentVoid
				open={modal.open === modals.PAYMENT_VOID_DIALOG}
				isSubmitting={isSubmitting}
				onVoid={() => onPaymentRefund(modal.payment)}
				onClose={closeModal}
			/>
			<AuthorizePaymentModal
				customer={reservation.customer}
				entityId={reservation.id}
				entityType='reservation'
				onSubmit={() => onReload(true)}
				onClose={closeModal}
				isOpen={
					modal.open === modals.AUTHORIZE_PAYMENT ||
					modal.open === modals.CAPTURE_AUTHORIZED_PAYMENT
				}
				payment={modal.payment}
				isCapture={modal.isCapture}
				serviceFeeModule={getServiceFeeModule(form.module.value, form.bookingPeriod)}
				defaultAmount={modal.payment?.amount}
				outlet={userContext.data.selectedOutlet}
			/>
			<AuthorizedPaymentVoid
				open={modal.open === modals.VOID_AUTHORIZED_PAYMENT}
				isSubmitting={isSubmitting}
				onVoid={() => onAuthorizedPaymentVoid(modal.payment)}
				onClose={closeModal}
			/>
			<RefundVoid
				open={modal.open === modals.PAYMENT_REFUND_VOID}
				isSubmitting={isSubmitting}
				onVoid={() => onRefundVoid(modal.refund)}
				onClose={closeModal}
			/>
			<QuantityModal
				modalTitle={quantityModalProps[modal.open]?.title || ''}
				initValue={modal.amount}
				defaultValue={modal.amount}
				isOpen={
					modal.open === modals.RETAIN ||
					modal.open === modals.OVERPAYMENT_APPLY_INVOICE ||
					modal.open === modals.DEPOSIT_CHECK_REFUND
				}
				decimalLimit={2}
				lowerLimit={0}
				upperLimit={modal.amount}
				onClose={closeModal}
				setDashValue={(amount, invoiceIds = []) => {
					switch (modal.open) {
						case modals.OVERPAYMENT_APPLY_INVOICE:
							onDepositOverPaymentApplyInvoice(amount, invoiceIds);
							break;
						case modals.RETAIN:
							onRetain(amount, modal.isDeposit || false);
							break;
						case modals.DEPOSIT_CHECK_REFUND:
							onDepositCheckRefund(amount);
							break;
						default:
							addErrorNotification('Unknown action');
							break;
					}
				}}
				isLoading={isSubmitting}
				submitButtonText={quantityModalProps[modal.open]?.buttonText || ''}
				pickInvoices={modal.open === modals.OVERPAYMENT_APPLY_INVOICE}
				invoiceFilters={{ reservationId: reservation.id, onlyOpen: true }}
			/>
			{modal.open === modals.TERMINAL && (
				<TerminalModal
					register={userContext.data.selectedRegister}
					reservationItem={modal.reservationItem}
					customer={reservation.customer}
					vessel={modal.reservationItem.vessel}
					productGrid={productGrid}
					onBack={() => {
						onReload();
						closeModal();
					}}
				/>
			)}
			{modal.open === modals.MESSAGES && (
				<Messages
					onClose={closeModal}
					inquiryObject={reservation}
					onNewInquiry={inquiry => setReservation({ ...reservation, inquiry })}
				/>
			)}
			{modal.open === modals.ATTACHMENTS && (
				<AttachmentModal onClose={closeModal} entity={reservation} isOpen />
			)}
			<ManuallyEditInvoice
				open={modal.open === modals.MANUALLY_EDITED_INVOICE_WARNING}
				onClose={closeModal}
			/>
			<ReservationExtraCharge
				isOpen={modal.open === modals.EXTRA_CHARGE}
				onClose={closeModal}
				extraCharges={bookingExtraCharges}
				reservationExtraCharges={reservation.extraCharges || []}
				customer={reservation?.customer}
				reservation={reservation}
				taxRate={taxRateRef.current}
				onChange={extraChargeItem => {
					onExtraChargeItemUpdate(
						extraChargeItem,
						reservation.extraCharges || [],
						_extraCharges =>
							setReservation({ ...reservation, extraCharges: _extraCharges }),
						{
							bookingPeriod: null,
							quantity: 1,
							subtotal: getReservationTotals(reservation, taxRateRef.current, true)
								.subtotalWithoutExtraCharges,
							taxCode: data.taxCode,
						},
						taxRateRef.current
					);
				}}
				onSave={() => onSave(() => closeModal())}
				isSubmitting={isSubmitting}
			/>
			<PaymentScheduleSelectModal
				selectedPaymentSchedule={reservation.paymentSchedule}
				paymentSchedules={paymentSchedules}
				onClose={closeModal}
				isOpen={modal.open === modals.PAYMENT_SCHEDULE}
				onSubmit={onPaymentScheduleChange}
				isSubmitting={isSubmitting}
			/>
			{modal.open === modals.CUSTOMER_PAYMENT_METHOD && (
				<CustomerPaymentMethodSelect
					selectedPaymentMethod={reservation.customerPaymentMethod}
					customer={reservation.customer}
					onClose={closeModal}
					isOpen={modal.open === modals.CUSTOMER_PAYMENT_METHOD}
					onSubmit={onCustomerPaymentMethodChange}
					paymentMethods={paymentMethods}
					isSubmitting={isSubmitting}
				/>
			)}
			<ReservationConfirmation
				isOpen={modal.open === modals.CONFIRMATION_MAIL}
				defaultEmail={reservation?.customer?.email}
				onClose={closeModal}
				onSend={mail => {
					onConfirmationEmailSend(mail);
					closeModal();
				}}
			/>
			{PRINT_COMPONENT}
		</>
	);
};

Form.propTypes = {
	id: PropTypes.number,
	itemId: PropTypes.number,
	data: PropTypes.shape({
		id: PropTypes.number,
		customer: PropTypes.object,
		note: PropTypes.string,
		module: PropTypes.object,
		items: PropTypes.arrayOf(PropTypes.object),
		deposits: PropTypes.arrayOf(PropTypes.object),
		invoices: PropTypes.arrayOf(PropTypes.object),
		reservationId: PropTypes.string,
		extraCharges: PropTypes.arrayOf(PropTypes.object),
		taxCode: PropTypes.object,
		authorizedPayment: PropTypes.object,
		hasManuallyEditedInvoice: PropTypes.bool,
	}),
	isLoading: PropTypes.bool,
	isListsLoading: PropTypes.bool,
	policies: PropTypes.arrayOf(PropTypes.object),
	paymentTypes: PropTypes.arrayOf(PropTypes.object),
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	enumReservationStatuses: PropTypes.arrayOf(PropTypes.object),
	contracts: PropTypes.arrayOf(PropTypes.object),
	from: PropTypes.string,
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	countries: PropTypes.arrayOf(PropTypes.object),
	states: PropTypes.arrayOf(PropTypes.object),
	enumBoatTypes: PropTypes.arrayOf(PropTypes.object),
	enumBoatMakes: PropTypes.arrayOf(PropTypes.object),
	enumFuelTypes: PropTypes.arrayOf(PropTypes.object),
	enumRvTypes: PropTypes.arrayOf(PropTypes.object),
	enumVehicleMakes: PropTypes.arrayOf(PropTypes.object),
	bookingExtraCharges: PropTypes.arrayOf(PropTypes.object),
	invoicingFrequencies: PropTypes.arrayOf(PropTypes.object),
	invoiceFirstOptions: PropTypes.arrayOf(PropTypes.object),
	invoiceNextOptions: PropTypes.arrayOf(PropTypes.object),
	printers: PropTypes.arrayOf(PropTypes.object),
	printerLogActions: PropTypes.arrayOf(PropTypes.object),
	enumBookingCalculations: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	searchData: PropTypes.object,
	accounts: PropTypes.arrayOf(PropTypes.object),
	seasons: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	paymentSchedules: PropTypes.arrayOf(PropTypes.object),
	paymentMethods: PropTypes.arrayOf(PropTypes.object),
	customReservationStatuses: PropTypes.arrayOf(PropTypes.object),
	invoiceTypes: PropTypes.arrayOf(PropTypes.object),
};
Form.defaultProps = {
	id: 0,
	itemId: 0,
	data: {
		id: 0,
		hasManuallyEditedInvoice: false,
	},
	isLoading: true,
	isListsLoading: true,
	policies: [],
	paymentTypes: [],
	ratePlans: [],
	enumReservationStatuses: [],
	contracts: [],
	from: '',
	paymentTerms: [],
	countries: [],
	states: [],
	enumBoatTypes: [],
	enumBoatMakes: [],
	enumFuelTypes: [],
	enumRvTypes: [],
	enumVehicleMakes: [],
	bookingExtraCharges: [],
	invoicingFrequencies: [],
	invoiceFirstOptions: [],
	invoiceNextOptions: [],
	printers: [],
	printerLogActions: [],
	enumBookingCalculations: [],
	searchData: null,
	accounts: [],
	seasons: [],
	taxCodes: [],
	paymentSchedules: [],
	paymentMethods: [],
	customReservationStatuses: [],
	invoiceTypes: [],
};

export default Form;
