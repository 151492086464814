import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../../../app/contexts/UserContext';
import {
	getNextAndPrevReservationItemStatus,
	reservationItemStatusIcon,
} from '../../../../../utils/helpers/reservationHelper';
import { reservationColor } from '../../../element/ReservationStatusCell';

import Button from '../../../element/Button';
import { reservationStatuses } from '../../../../../utils/constants/constants';
import { addErrorNotification } from '../../../../../utils/helpers/helper';
import { hasChangeReservationStatusPermission } from '../../../../../utils/helpers/permission';

const StatusButton = ({
	reservationItem,
	disabled,
	onConfirm,
	onCheckIn,
	onCheckOut,
	onItemUpdateCustomStatus,
}) => {
	const userContext = useContext(UserContext);

	const nextStatus = useMemo(() => {
		const { nextStatus: _nextStatus } = getNextAndPrevReservationItemStatus(
			reservationItem.status,
			reservationItem.customReservationStatus,
			reservationItem.product.productCustomReservationStatuses,
			false
		);

		return _nextStatus;
	}, [reservationItem]);

	if (!nextStatus) return null;

	return (
		<Button
			design={reservationColor(nextStatus.status.value || nextStatus.status)}
			icon={reservationItemStatusIcon(nextStatus.status.value || nextStatus.status)}
			size='sm'
			text={nextStatus.name || nextStatus.label}
			onClick={() => {
				if (nextStatus.id) onItemUpdateCustomStatus(nextStatus.id);
				else if (nextStatus.status === reservationStatuses.CONFIRMED) onConfirm();
				else if (nextStatus.status === reservationStatuses.CHECKED_IN) onCheckIn();
				else if (nextStatus.status === reservationStatuses.CHECKED_OUT) onCheckOut();
				else addErrorNotification('Unknown status actions');
			}}
			noPermission={!hasChangeReservationStatusPermission(userContext)}
			disabled={disabled}
		/>
	);
};

StatusButton.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	disabled: PropTypes.bool,
	onConfirm: PropTypes.func,
	onCheckIn: PropTypes.func,
	onCheckOut: PropTypes.func,
	onItemUpdateCustomStatus: PropTypes.func,
};

StatusButton.defaultProps = {
	reservationItem: null,
	disabled: false,
	onConfirm: () => {},
	onCheckIn: () => {},
	onCheckOut: () => {},
	onItemUpdateCustomStatus: () => {},
};

export default StatusButton;
