import React, { useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';
import Portal from '../../reusables/layout/Portal';
import Portlet from '../../reusables/layout/Portlet';
import pages from '../../pages';
import FormField from '../../reusables/template/FormField';
import Radio from '../../reusables/field/Radio';
import Toggle from '../../reusables/field/Toggle';
import Selects from '../../reusables/field/Selects';
import Button from '../../reusables/element/Button';
import FormGroup from '../../reusables/layout/FormGroup';
import { priceFormatter } from '../../../utils/helpers/helper';

const PrintBarcodeModal = ({ data, isOpen, onClose }) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
				}}>
				<BarcodePrintForm
					productId={data.productId}
					sku={data.sku}
					name={data.name}
					price={data.price}
					onClose={onClose}
				/>
			</Popup>
		</Portal>
	);
};

const RadioText = ({ radioTitle, radioData }) => {
	return (
		<div className='row'>
			<div className='col-4 sdms-font-bolder'>{radioTitle}</div>
			<div className='col-8'>{radioData}</div>
		</div>
	);
};

RadioText.propTypes = {
	radioTitle: PropTypes.string.isRequired,
	radioData: PropTypes.string.isRequired,
};

PrintBarcodeModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

const BarcodePrintForm = ({ productId, sku = '', name, price, onClose }) => {
	const formats = [
		{
			id: 1,
			name: 'CODE128',
			value: 'CODE128',
		},
		{
			id: 2,
			name: 'CODE39',
			value: 'CODE39',
		},
	];

	const fontSizes = [
		{
			id: 1,
			name: '15',
			value: 15,
		},
		{
			id: 2,
			name: '16',
			value: 16,
		},
		{
			id: 3,
			name: '17',
			value: 17,
		},
		{
			id: 4,
			name: '18',
			value: 18,
		},
		{
			id: 5,
			name: '19',
			value: 19,
		},
		{
			id: 6,
			name: '20',
			value: 20,
		},
	];

	const toPrint = useRef();
	const refPrintPreview = useRef(null);
	const [selectedValue, setSelectedValue] = useState(productId);
	const [nameToggle, setNameToggle] = useState(false);
	const [priceToggle, setPriceToggle] = useState(false);
	const [selectedFormat, setSelectedFormat] = useState(formats[0]);
	const [selectedFontSize, setSelectedFontSize] = useState(fontSizes[1]);

	return (
		<Portlet hasFrame>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon={pages.pos.products.icon}>
					{name}
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						<FormField name='itemId' label='Select Barcode Text' col={6}>
							<Radio.Container>
								<Radio
									checked={selectedValue === productId}
									content={
										<RadioText radioTitle='Product ID:' radioData={productId} />
									}
									onChange={e => {
										if (e.target.value === 'on') setSelectedValue(productId);
									}}
									className='sdms-radio--primary sdms-mt-5'
									id={productId}
									name={productId}
								/>
								<Radio
									checked={selectedValue === sku}
									content={<RadioText radioTitle='SKU:' radioData={sku} />}
									onChange={e => {
										if (e.target.value === 'on') {
											setSelectedValue(sku);
										}
									}}
									className='sdms-radio--primary sdms-mt-5'
									id={sku}
									name={sku}
								/>
							</Radio.Container>
						</FormField>
						<FormField name='barcode' label='Barcode' col={6}>
							<div ref={refPrintPreview} className='text-center'>
								<div style={{ width: 'fit-content' }}>
									<Barcode
										value={selectedValue}
										format={selectedFormat ? selectedFormat.name : 'CODE128'}
										font='monospace'
										fontSize={selectedFontSize ? selectedFontSize.name : 16}
										ref={toPrint}
										key={selectedValue}
										textAlign='center'
										margin={0}
									/>
									{nameToggle && (
										<div
											style={{
												font: `400 ${
													selectedFontSize
														? selectedFontSize.name
														: fontSizes[1].name
												}px monospace`,
												color: '#000',
											}}>
											{name}
										</div>
									)}
									{priceToggle && (
										<div
											style={{
												font: `400 ${
													selectedFontSize
														? selectedFontSize.name
														: fontSizes[1].name
												}px monospace`,
												color: '#000',
											}}>
											{price === 0 ? 'FREE' : priceFormatter(price)}
										</div>
									)}
								</div>
							</div>
						</FormField>
						<FormField name='nameToggle' label='Print Name' col={6}>
							<Toggle
								value={nameToggle}
								onChange={e => {
									setNameToggle(e.target.value);
								}}
							/>
						</FormField>
						<FormField name='priceToggle' label='Print Price' col={6}>
							<Toggle
								value={priceToggle}
								onChange={e => {
									setPriceToggle(e.target.value);
								}}
							/>
						</FormField>
						<FormField name='selectedFormat' label='Format' col={6}>
							<Selects
								options={formats}
								value={selectedFormat}
								onChange={e => {
									setSelectedFormat(e.target.value);
								}}
								placeholder='Format (Default CODE128)'
							/>
						</FormField>
						<FormField name='selectedFontSize' label='Font Size' col={6}>
							<Selects
								options={fontSizes}
								value={selectedFontSize}
								onChange={e => setSelectedFontSize(e.target.value)}
								placeholder='Font Size (Default 16)'
							/>
						</FormField>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
				<div className='col-auto'>
					<ReactToPrint
						trigger={() => (
							<Button
								label={process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR}
								text='Print'
								icon='Printer'
								size='sm'
							/>
						)}
						content={() => refPrintPreview.current}
						pageStyle='@page { size: auto; } @media print { body { -webkit-print-color-adjust: exact; } }'
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
BarcodePrintForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	productId: PropTypes.string.isRequired,
	sku: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	price: PropTypes.number.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default PrintBarcodeModal;
