import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';

import useField from '../../../utils/hooks/useField';
import usePages from '../../../utils/hooks/usePages';
import { max, min, required } from '../../../utils/helpers/validation';
import { getModuleByPath, getModuleKeyByPath } from '../../../utils/helpers/reusable';
import { numberParser } from '../../../utils/helpers/helper';

import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Loading from '../../reusables/template/Loading';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Toggle from '../../reusables/field/Toggle';

const AnalyticsDashboardForm = ({
	data,
	isLoading,
	setTitle,
	onFormChange,
	isSubmitted,
	setIsValid,
	submitButtonAttr,
	submit,
}) => {
	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const pages = usePages();

	const module = useRef(
		userContext.data.user.company.modules.find(m => m.value === getModuleByPath())
	);

	const moduleKey = getModuleKeyByPath();

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [columns, columnsOnChange, columnsValRes, columnsShowVal, setColumnsShowVal] = useField(
		data,
		'columns',
		onFormChange,
		[required, min(1), max(12)],
		4,
		numberParser(false)
	);

	const [isDefault, isDefaultOnChange] = useField(data, 'isDefault', onFormChange, [], true);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();

			setColumnsShowVal();
		}
	}, [isSubmitted, setNameShowVal, setColumnsShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && columnsValRes.isValid);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nameValRes.isValid, columnsValRes.isValid]);

	useEffect(() => {
		if (!isLoading) data.module = module.current;
	}, [data.module, isLoading]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.analytics.default.text,
				path: pages.analytics.dashboard.path,
			},
			{
				title: pages.analytics[moduleKey].text,
				path: pages.analytics[moduleKey].path,
			},
			{ title: name || `New ${pages.analytics[moduleKey].text} Dashboard`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.analytics[moduleKey].text} Dashboard`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup row>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colMd={4}>
								<Input
									type='text'
									placeholder='Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='columns'
								label='Columns'
								id={data.id}
								valRes={columnsValRes}
								showValidation={columnsShowVal}
								colMd={2}>
								<Input
									type='text'
									placeholder='Columns (Required)'
									value={columns}
									onChange={columnsOnChange}
									onBlur={setColumnsShowVal}
									disabled={data.id}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='isDefault' label='Default' id={data.id} colMd={2}>
								<Toggle onChange={isDefaultOnChange} value={isDefault} />
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
AnalyticsDashboardForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		module: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	isLoading: PropTypes.bool,
	setTitle: PropTypes.func,
	onFormChange: PropTypes.func,
	isSubmitted: PropTypes.bool,
	setIsValid: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
AnalyticsDashboardForm.defaultProps = {
	data: {
		id: 0,
	},
	isLoading: false,
	setTitle: () => {},
	onFormChange: () => {},
	isSubmitted: false,
	setIsValid: false,

	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default AnalyticsDashboardForm;
