import update from 'immutability-helper';

import StarWebPrintTrader from './StarWebPrintTrader';
import StarWebPrintBuilder from './StarWebPrintBuilder';
import {
	dateFormatter,
	getModifierPrepStations,
	getServiceFeeText,
	numberFormat,
	parsePrint,
	priceFormatter,
	quantityFormatter,
} from '../../../utils/helpers/helper';
import { orderItemPrintable } from '../../../utils/helpers/orderHelper';
import {
	creditCardPaymentStatuses,
	orderStatuses,
	paymentTypes,
	printStatuses,
} from '../../../utils/constants/constants';
import apiCall, { parseData } from '../../../utils/helpers/apiCall';

export default class WebPrint {
	orderInServicePrint;

	queues;

	outlet;

	user;

	register;

	onStart;

	onFinish;

	onFail;

	onServicePrintFinish;

	printers;

	actions = {
		SERVICE: 'service',
		ORDER: 'order',
		RECEIPT: 'receipt',
		OPEN_CASH_DRAWER: 'open_cash_drawer',
		REFUND: 'refund',
		SERVER_CLOSE_OUT: 'server_close_out',
		REGISTER_CLOSE: 'register_close',
		PAYBILL_RECEIPT: 'pay_bill_receipt',
		INIT: 'init',
		PRINT: 'print',
		PAYMENT_PRINT: 'payment',
	};

	builder = new StarWebPrintBuilder();

	receiptPrinterId;

	expediter;

	constructor(options) {
		const defaults = {
			user: {},
			outlet: {},
			register: {},
			onStart: () => {},
			onFinish: () => {},
			onFail: () => {},
			onServicePrintFinish: () => {},
			printers: [],
			orderInServicePrint: {},
			queues: [],
			receiptPrinterId: null,
			enumPrinterLogActions: [],
			log: 'disable',
		};
		const populated = Object.assign(defaults, options);

		// eslint-disable-next-line no-restricted-syntax
		for (const key in populated) {
			// eslint-disable-next-line no-prototype-builtins
			if (populated.hasOwnProperty(key)) {
				this[key] = populated[key];
			}
		}

		if (options.outlet && options.outlet.settings)
			this.expediter = options.outlet.settings.expediter;

		if (options.register) this.receiptPrinterId = options.register.printer.id;

		if (this.outlet.settings && this.outlet.settings.posPrintLog)
			this.log = this.outlet.settings.posPrintLog.value;

		if (this.printers.length === 0 || this.enumPrinterLogActions.length === 0) {
			apiCall(
				'POST',
				'customGetPrintData',
				res => {
					this.printers = res.printers;
					this.enumPrinterLogActions = res.enumPrinterLogActions;
					this.init();
				},
				err => console.error(err),
				'',
				{ outletId: this.outlet.id }
			);
		} else this.init();
	}

	saveLog(printer, item, errorMessage = null) {
		apiCall(
			'POST',
			'printLogs',
			() => {},
			err => {
				console.error(err.toString());
			},
			'',
			parseData({
				outlet: this.outlet,
				printer,
				success: errorMessage === null,
				message: errorMessage,
				content: item.request,
				action: this.enumPrinterLogActions.find(epla => epla.value === item.action),
				sdmsOrder: parseData(item.order, 'saleOrders'),
			})
		);
	}

	removeItemFromArray(array, index) {
		return update(array, { $splice: [[index, 1]] });
	}

	getRepeatChar(string, length, char = ' ') {
		return char.repeat(Math.max(0, length - string.toString().length));
	}

	wrapSentence(sentence, length, stringInLine = '') {
		length -= stringInLine.toString().length;

		if (sentence.toString().length <= length) return [sentence];

		const sentences = [];

		for (let i = 0; i < sentence.toString().length; i += length) {
			sentences.push(sentence.toString().substring(i, i + length));
		}

		return sentences;
	}

	groupOrderItemsByPrinter(orderItems) {
		const printers = {};

		let expoPrepStation = null;

		let expediter = null;

		if (this.expediter)
			orderItems.forEach(oi => {
				const expo = oi.product.prepStations.find(
					ps => ps.printer.id === this.expediter.id
				);
				if (expo) expoPrepStation = expo;
			});

		if (this.expediter)
			expediter = expoPrepStation ?? {
				id: 0,
				name: this.expediter.name,
				printer: this.expediter,
			};

		const { excludePrepStations } = this.register;

		orderItems.every(orderItem => {
			const orderItemPrepStations = orderItem.product.prepStations.filter(
				ps => excludePrepStations.findIndex(eps => eps.id === ps.id) === -1
			);

			if (
				orderItemPrepStations.length === 0 ||
				orderItem.printStatus.value === printStatuses.DONOTPRINT
			) {
				if (!printers.fake) printers.fake = { 0: { name: '', items: [] } };

				printers.fake[0].items.push({
					id: orderItem.id,
					name: orderItem.name,
					quantity: orderItem.quantity,
					note: orderItem.note,
				});

				return true;
			}

			const rebelModifiers = [];

			orderItemPrepStations.forEach(prepStation => {
				if (!printers[prepStation.printer.id]) printers[prepStation.printer.id] = {};

				if (!printers[prepStation.printer.id][prepStation.id])
					printers[prepStation.printer.id][prepStation.id] = {
						name: prepStation.name,
						items: [],
					};

				const printerItem = {
					id: orderItem.id,
					name: orderItem.name,
					quantity: orderItem.quantity,
					note: orderItem.note,
				};

				if (orderItem.orderItemModifiers.length > 0) {
					const modifiers = [];

					orderItem.orderItemModifiers
						.sort((a, b) =>
							a.modifierSection.sortOrder > b.modifierSection.sortOrder ? 1 : -1
						)
						.forEach(orderItemModifier => {
							const orderItemModifierPrepStations = getModifierPrepStations(
								orderItemModifier
							);

							const modifierName = orderItemModifier.modifierSection.abbreviation
								? `${orderItemModifier.modifierSection.abbreviation}-${
										orderItemModifier.modifier.isCustom ? '*' : ''
								  }${orderItemModifier.name}`
								: `${orderItemModifier.modifier.isCustom ? '*' : ''}${
										orderItemModifier.name
								  }`;

							modifiers.push(modifierName);

							orderItemModifierPrepStations.forEach(p => {
								if (
									p.id !== prepStation.id &&
									orderItemPrintable(orderItemModifier)
								) {
									if (!rebelModifiers[p.id]) rebelModifiers[p.id] = [];

									if (!printers[p.printer.id]) printers[p.printer.id] = {};

									if (!printers[p.printer.id][p.id])
										printers[p.printer.id][p.id] = {
											name: p.name,
											items: [],
										};

									if (
										rebelModifiers[p.id].indexOf(modifierName) === -1 &&
										orderItemPrepStations.findIndex(_p => _p.id === p.id) === -1
									) {
										if (this.outlet.settings.printProdNameWithModifiers) {
											const printItemIndex = printers[p.printer.id][
												p.id
											].items.findIndex(i => i.id === orderItem.id);

											if (printItemIndex > -1) {
												printers[p.printer.id][p.id].items[
													printItemIndex
												].modifiers.push(modifierName);
											} else {
												printers[p.printer.id][p.id].items.push({
													...printerItem,
													modifiers: [modifierName],
												});
											}
										} else {
											printers[p.printer.id][p.id].items.push({
												id: orderItemModifier.id,
												name: modifierName,
												quantity: orderItem.quantity,
												type: 'modifier',
												orderItemId: orderItem.id,
											});
										}

										rebelModifiers[p.id].push(modifierName);

										if (
											p.sendToExpo &&
											!prepStation.sendToExpo &&
											expediter &&
											orderItemPrepStations.filter(
												ps => ps.printer.id === this.expediter.id
											).length === 0
										) {
											if (!printers[expediter.printer.id])
												printers[expediter.printer.id] = {};

											if (!printers[expediter.printer.id][expediter.id])
												printers[expediter.printer.id][expediter.id] = {
													name: expediter.name,
													items: [],
												};

											// check for duplicate item.
											if (
												printers[expediter.printer.id][
													expediter.id
												].items.findIndex(
													i => i.id === orderItemModifier.id
												) === -1
											)
												printers[expediter.printer.id][
													expediter.id
												].items.push({
													id: orderItemModifier.id,
													name: modifierName,
													quantity: orderItem.quantity,
												});
										}
									}
								}
							});
						});

					printerItem.modifiers = modifiers;
				}

				if (orderItemPrintable(orderItem))
					printers[prepStation.printer.id][prepStation.id].items.push(printerItem);

				// if register has a expediter and prepStation sendToExpo is true add item to expediter.
				if (
					prepStation.sendToExpo &&
					expediter &&
					orderItemPrepStations.filter(ps => ps.printer.id === this.expediter.id)
						.length === 0
				) {
					if (!printers[expediter.printer.id]) printers[expediter.printer.id] = {};

					if (!printers[expediter.printer.id][expediter.id])
						printers[expediter.printer.id][expediter.id] = {
							name: expediter.name,
							items: [],
						};

					// check for duplicate item.
					if (
						printers[expediter.printer.id][expediter.id].items.findIndex(
							i => i.id === orderItem.id
						) === -1
					)
						printers[expediter.printer.id][expediter.id].items.push(printerItem);
				}

				if (printers[prepStation.printer.id][prepStation.id].items.length === 0) {
					delete printers[prepStation.printer.id][prepStation.id];

					if (Object.keys(printers[prepStation.printer.id]).length === 0)
						delete printers[prepStation.printer.id];
				}
			});

			return true;
		});

		return printers;
	}

	hasOrderInServicePrintQueue(orderId) {
		return Object.values(this.orderInServicePrint).some(
			orderIds => orderIds.indexOf(orderId) > -1
		);
	}

	init() {
		this.printers.forEach(printer => {
			this.orderInServicePrint[printer.id] = [];

			this.queues[printer.id] = {
				name: printer.name,
				type: printer.type.value,
				characterPerLine: printer.width.value,
				onProgress: false,
				items: [],
				backupPrinter: printer.backupPrinter,
				trader: new StarWebPrintTrader({
					papertype: 'normal',
					url: printer.url,
					timeout: process.env.REACT_APP_PRINTER_TIMEOUT
						? parseInt(process.env.REACT_APP_PRINTER_TIMEOUT, 10)
						: 20000,
				}),
			};

			this.queues[printer.id].trader.onReceive = response => {
				const item = this.queues[printer.id].items[0];

				if (response.traderSuccess === 'false' && item.action !== this.actions.INIT) {
					// Busy printer
					if (response.traderCode === '2001') {
						console.log(`WebPrint:queue:${printer.name}:busy:resend`);

						this.queues[printer.id].trader.sendMessage({
							request: item.request,
						});

						return;
					}

					// Offline
					if (response.traderCode === '1100') {
						response.responseText = 'Please check printer.';
						this.queues[printer.id].trader.onError(response);
						return;
					}
				}

				console.log(parsePrint(item.request));

				if (this.log === 'enable' && item.request) {
					this.saveLog(printer, item);
				}

				if (item.action !== this.actions.INIT) this.afterPrint(printer.id, item);

				this.queues[printer.id].items = this.removeItemFromArray(
					this.queues[printer.id].items,
					0
				);

				if (this.queues[printer.id].items.length === 0) {
					console.log(`WebPrint:queue:${printer.name}:finish`);

					this.queues[printer.id].onProgress = false;
				} else {
					console.log(`WebPrint:queue:${printer.name}:continue`);

					this.queues[printer.id].trader.sendMessage({
						request: this.queues[printer.id].items[0].request,
					});
				}
			};

			this.queues[printer.id].trader.onError = response => {
				console.log(`WebPrint:queue:${printer.name}:error:${response.responseText}`);

				const item = this.queues[printer.id].items[0];

				console.log(parsePrint(item.request));

				// if printer has backup printer and it's not backup print send print to backup printer.
				if (
					!item.isBackup &&
					this.queues[printer.id].backupPrinter &&
					item.action !== this.actions.INIT
				) {
					this.sendBackupPrinter(
						this.queues[printer.id].backupPrinter.id,
						this.queues[printer.id].items
					);

					if (item.action === this.actions.SERVICE)
						this.orderInServicePrint[printer.id] = this.orderInServicePrint[
							printer.id
						].filter(orderId => orderId !== item.order.id);

					this.queues[printer.id].items = this.removeItemFromArray(
						this.queues[printer.id].items,
						0
					);

					this.queues[printer.id].onProgress = false;

					this.queues[printer.id].items = [];

					return;
				}

				this.queues[printer.id].items.forEach(i => {
					if (this.log !== 'disable' && item.request)
						this.saveLog(printer, i, response.responseText);

					if (i.action === this.actions.SERVICE) {
						this.onFail(i, `${printer.name} : ${response.responseText}`);

						this.orderInServicePrint[printer.id] = this.orderInServicePrint[
							printer.id
						].filter(orderId => orderId !== i.order.id);

						if (!this.hasOrderInServicePrintQueue(i.order.id))
							this.onServicePrintFinish();
					} else if (i.action !== this.actions.INIT) {
						this.onFail(item, `${printer.name} : ${response.responseText}`);
					}
				});

				this.queues[printer.id].onProgress = false;

				this.queues[printer.id].items = [];
			};
		});

		this.queues.fake = {
			name: 'Fake',
			items: [],
		};
	}

	startQueue(printerId) {
		if (!this.queues[printerId] || this.queues[printerId].onProgress || printerId === 'fake')
			return;

		console.log(`WebPrint:queue:${this.queues[printerId].name}:start`);

		this.queues[printerId].onProgress = true;

		this.queues[printerId].trader.sendMessage({
			request: this.queues[printerId].items[0].request,
		});
	}

	afterPrint(printerId, queueItem) {
		if (
			queueItem.order &&
			this.orderInServicePrint[printerId].indexOf(queueItem.order.id) > -1
		) {
			this.onFinish(queueItem);

			this.orderInServicePrint[printerId] = this.orderInServicePrint[printerId].filter(
				orderId => orderId !== queueItem.order.id
			);

			if (!this.hasOrderInServicePrintQueue(queueItem.order.id))
				this.onServicePrintFinish(queueItem);

			return;
		}

		this.onFinish(queueItem);
	}

	sendBackupPrinter(printerId, items) {
		let failedRequestHead = '';

		failedRequestHead += this.builder.createInitializationElement();

		failedRequestHead += this.builder.createTextElement({ characterspace: 0 });

		failedRequestHead += this.builder.createAlignmentElement({ position: 'center' });

		failedRequestHead += this.builder.createTextElement({
			data: '=== FAILED PRINT ===\n\n',
		});

		// { ...queueItem, request, isBackup: true }

		// add modified queue item to queue.
		this.queues[printerId].items.push(
			...items.map(item => ({
				...item,
				request: `${failedRequestHead}${item.request}`,
				isBackup: true,
			}))
		);

		// if its is service_print order service_print_all add to orderInServicePrint array.
		if (items[0].action === this.actions.SERVICE)
			this.orderInServicePrint[printerId].push(items[0].order.id);

		// start queue for backup printer.
		this.startQueue(printerId);
	}

	getServiceFeeLine(serviceFeeAmount, characterPerLine) {
		const serviceFeeAmountFormatted = priceFormatter(serviceFeeAmount);

		const serviceFeeText = getServiceFeeText(null, this.outlet);

		return this.builder.createTextElement({
			data:
				`${serviceFeeText}:` +
				this.getRepeatChar(
					`${serviceFeeText}:` + serviceFeeAmountFormatted,
					characterPerLine
				) +
				serviceFeeAmountFormatted +
				'\n',
		});
	}

	getServicePrint(order, prepStation, printerId) {
		const characterPerLine = this.queues[printerId].characterPerLine / 2 - 1;

		let request = '';

		request += this.builder.createInitializationElement();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.builder.createAlignmentElement({ position: 'center' });

		request += this.builder.createTextElement({ width: 2 });

		if (order.unit && order.unit.priorityPrint) {
			const unitName = this.wrapSentence(
				'=== ' + order.unit.name + ' ===',
				characterPerLine - 6
			);

			unitName.forEach(un => {
				request += this.builder.createTextElement({
					data: un + '\n',
					emphasis: true,
				});
			});

			request += this.builder.createTextElement({ data: '\n', emphasis: false });
		}

		request += this.builder.createTextElement({ data: this.outlet.name + '\n' });

		request += this.builder.createTextElement({ data: dateFormatter() + '\n' });

		request += this.builder.createTextElement({ data: prepStation.name + '\n' });

		if (order.unit) request += this.builder.createTextElement({ data: order.unit.name + '\n' });

		request += this.builder.createTextElement({ data: order.name + '\n' });

		request += this.builder.createTextElement({ data: this.user.displayName + '\n' });

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createAlignmentElement({ position: 'left' });

		request += this.builder.createTextElement({
			data:
				'Qty  Description' +
				this.getRepeatChar('Qty  Description', characterPerLine) +
				'\n',
			underline: true,
		});

		request += this.builder.createTextElement({ underline: false });

		request += this.builder.createTextElement({ data: '\n' });

		prepStation.items.forEach(item => {
			const name = this.wrapSentence(item.name, characterPerLine - 5);

			const quantity = quantityFormatter(item.quantity, 2);

			// order item.
			name.forEach((n, i) => {
				if (i === 0) {
					request += this.builder.createTextElement({
						data: quantity + this.getRepeatChar(quantity, 5) + n + '\n',
					});
				} else {
					request += this.builder.createTextElement({
						data: this.getRepeatChar('', 5) + n + '\n',
					});
				}
			});
			// modifiers
			if (item.modifiers) {
				item.modifiers.forEach(modifier => {
					const modifierName = this.wrapSentence('=' + modifier, characterPerLine - 5);

					modifierName.forEach(mn => {
						request += this.builder.createTextElement({
							data: this.getRepeatChar('', 5) + mn + '\n',
						});
					});
				});
			}

			// note
			if (item.note && item.note.length > 0) {
				const note = this.wrapSentence('!' + item.note, characterPerLine - 5);

				note.forEach(n => {
					request += this.builder.createTextElement({
						data: this.getRepeatChar('', 5) + n + '\n',
					});
				});
			}

			if (this.outlet.settings.servicePrintDoubleSpace) {
				request += this.builder.createTextElement({ data: '\n' });
			}
		});

		if (order.unit && order.unit.priorityPrint) {
			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createAlignmentElement({ position: 'center' });

			request += this.builder.createTextElement({ width: 2 });

			const unitName = this.wrapSentence(
				'=== ' + order.unit.name + ' ===',
				characterPerLine - 6
			);

			unitName.forEach(un => {
				request += this.builder.createTextElement({
					data: un + '\n',
					emphasis: true,
				});
			});

			request += this.builder.createTextElement({ data: '\n', emphasis: false });

			request += this.builder.createAlignmentElement({ position: 'left' });
		}

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.builder.createTextElement({ width: 1 });

		request += this.builder.createCutPaperElement({ feed: true });

		return request;
	}

	getOutletDetails() {
		if (!this.outlet) return '';

		let request = '';

		if (this.outlet.name)
			request += this.builder.createTextElement({ data: this.outlet.name + '\n' });

		if (this.outlet.addressLineOne)
			request += this.builder.createTextElement({ data: this.outlet.addressLineOne + '\n' });

		if (this.outlet.addressLineTwo)
			request += this.builder.createTextElement({ data: this.outlet.addressLineTwo + '\n' });

		let addressCSZ = '';

		if (this.outlet.city)
			addressCSZ = `${addressCSZ}${addressCSZ === '' ? '' : ', '}${this.outlet.city}`;

		if (this.outlet.state)
			addressCSZ = `${addressCSZ}${addressCSZ === '' ? '' : ', '}${this.outlet.state.code}`;

		if (this.outlet.zip)
			addressCSZ = `${addressCSZ}${addressCSZ === '' ? '' : ' '}${this.outlet.zip}`;

		if (addressCSZ !== '')
			request += this.builder.createTextElement({ data: addressCSZ + '\n' });

		if (this.outlet.contactPhone)
			request += this.builder.createTextElement({ data: this.outlet.contactPhone + '\n' });

		if (this.outlet.website)
			request += this.builder.createTextElement({ data: this.outlet.website + '\n' });

		return request;
	}

	getReceiptHead(order, customer = null, refundTitle = '') {
		let request = '';

		request += this.builder.createAlignmentElement({ position: 'center' });

		if (refundTitle !== '')
			request += this.builder.createTextElement({ data: refundTitle + '\n' });

		request += this.getOutletDetails();

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createAlignmentElement({ position: 'left' });

		request += this.builder.createTextElement({
			data: 'Receipt: ' + order.name + '\n',
		});

		if ((order?.invoices || []).length > 0) {
			const invoices = order.invoices.sort(
				(a, b) => new Date(b.timeCreated).getTime() - new Date(a.timeCreated).getTime()
			);

			request += this.builder.createTextElement({
				data: 'Invoice: ' + invoices[0].invoiceId + '\n',
			});
		}

		request += this.builder.createTextElement({
			data: 'Date: ' + dateFormatter(order.timeCreated) + '\n',
		});

		request += this.builder.createTextElement({
			data:
				'Cashier: ' +
				this.user.displayName +
				'    ' +
				'Station: ' +
				this.register.name +
				'\n',
		});

		request += this.builder.createTextElement({
			data:
				'Customer: ' +
				(customer ? customer.displayName : order.customer.displayName) +
				'\n',
		});

		if (order.vessel)
			request += this.builder.createTextElement({
				data: 'Vessel: ' + order.vessel.name + '\n',
			});

		if (order.externalNote)
			request += this.builder.createTextElement({
				data: 'Note: ' + order.externalNote + '\n',
			});

		return request;
	}

	getOrderDetails(order, characterPerLine) {
		let request = '';

		request += this.builder.createTextElement({
			data: `Qty    Desc${this.getRepeatChar('Qty    DescPrice', characterPerLine)}Price\n`,
		});

		order.orderItems.forEach(orderItem => {
			let modifierExtraCost = 0;

			let modifierDetails = '';

			if (orderItem.orderItemModifiers) {
				orderItem.orderItemModifiers.every(orderItemModifiers => {
					if (!orderItemModifiers.price) return true;

					modifierExtraCost += numberFormat(
						orderItemModifiers.price * orderItem.quantity
					);

					const orderItemModifierPrice = priceFormatter(
						orderItemModifiers.price * orderItem.quantity
					);

					const modifierName = this.wrapSentence(
						orderItemModifiers.name,
						characterPerLine - 10,
						orderItemModifierPrice
					);

					modifierName.forEach((n, i) => {
						if (i === 0)
							modifierDetails += this.builder.createTextElement({
								data:
									this.getRepeatChar('', 9) +
									n +
									this.getRepeatChar(
										n + orderItemModifierPrice,
										characterPerLine - 9
									) +
									orderItemModifierPrice +
									'\n',
							});
						else
							modifierDetails += this.builder.createTextElement({
								data: this.getRepeatChar('', 9) + n + '\n',
							});
					});

					return true;
				});
			}

			const orderItemPrice = priceFormatter(orderItem.subtotal - modifierExtraCost);

			const orderItemName = this.wrapSentence(
				orderItem.name,
				characterPerLine - 8,
				orderItemPrice
			);

			const orderItemQuantity = quantityFormatter(orderItem.quantity, 2);

			orderItemName.forEach((n, i) => {
				if (i === 0)
					request += this.builder.createTextElement({
						data:
							orderItemQuantity +
							this.getRepeatChar(orderItemQuantity, 7) +
							n +
							this.getRepeatChar(n + orderItemPrice, characterPerLine - 7) +
							orderItemPrice +
							'\n',
					});
				else
					request += this.builder.createTextElement({
						data: this.getRepeatChar('', 7) + n + '\n',
					});
			});

			request += modifierDetails;

			if (orderItem.discountAmount) {
				const discountTotal = priceFormatter(
					(orderItem.discountType.value === 'percent'
						? (orderItem.subtotal * orderItem.discountAmount) / 100
						: orderItem.discountAmount) * -1
				);

				const discountName = this.wrapSentence(
					orderItem.discount.name,
					characterPerLine - 10,
					discountTotal
				);

				request += this.builder.createTextElement({
					data:
						this.getRepeatChar('', 9) +
						discountName[0] +
						this.getRepeatChar(discountName[0] + discountTotal, characterPerLine - 9) +
						discountTotal +
						'\n',
				});
			}
		});

		return request;
	}

	getOrderTotals(order, characterPerLine) {
		let request = '';

		const subtotal = priceFormatter(order.subtotal);

		request += this.builder.createTextElement({
			data:
				'SubTotal:' +
				this.getRepeatChar('SubTotal:' + subtotal, characterPerLine) +
				subtotal +
				'\n',
		});

		if (order.discountAmount) {
			const discount = priceFormatter(order.discountTotal);

			request += this.builder.createTextElement({
				data:
					'Discount:' +
					this.getRepeatChar('Discount:' + discount, characterPerLine) +
					discount +
					'\n',
			});
		}

		const tax = priceFormatter(order.taxTotal);

		request += this.builder.createTextElement({
			data: 'Tax:' + this.getRepeatChar('Tax:' + tax, characterPerLine) + tax + '\n',
		});

		const total = priceFormatter(order.total);

		request += this.builder.createTextElement({
			data: 'Total:' + this.getRepeatChar('Total:' + total, characterPerLine) + total + '\n',
		});

		// if order has multiple payment.
		if (order.invoices && order.invoices.length > 1) {
			const amountInvoiced = priceFormatter(order.amountInvoiced);

			request += this.builder.createTextElement({
				data:
					'Paid:' +
					this.getRepeatChar('Paid:' + amountInvoiced, characterPerLine) +
					amountInvoiced +
					'\n',
			});

			const balance = priceFormatter(order.total - order.amountInvoiced);

			request += this.builder.createTextElement({
				data:
					'Balance:' +
					this.getRepeatChar('Balance:' + balance, characterPerLine) +
					balance +
					'\n',
			});
		}

		return request;
	}

	getOrderFinalMessage() {
		let request = '';

		request += this.builder.createTextElement({ data: 'ALL SALES ARE FINAL\n' });

		request += this.builder.createTextElement({ data: 'THANK YOU AND COME AGAIN\n' });

		return request;
	}

	getOrderPrint(order, characterPerLine) {
		let request = '';

		request += this.getReceiptHead(order);

		request += this.builder.createTextElement({ data: '\n' });

		request += this.getOrderDetails(order, characterPerLine);

		request += this.builder.createTextElement({ data: '\n' });

		request += this.getOrderTotals(order, characterPerLine);

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createCutPaperElement({ feed: true });

		return request;
	}

	getCashReceipt(order, payment, characterPerLine) {
		let request = '';

		request += this.builder.createInitializationElement();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.getReceiptHead(order);

		request += this.builder.createTextElement({ data: '\n' });

		request += this.getOrderDetails(order, characterPerLine);

		request += this.builder.createTextElement({ data: '\n' });

		request += this.getOrderTotals(order, characterPerLine);

		request += this.builder.createTextElement({ data: '\n' });

		const paymentAmount = priceFormatter(payment.amount + payment.changeDue);

		request += this.builder.createTextElement({
			data:
				'Cash Payment:' +
				this.getRepeatChar('Cash Payment:' + paymentAmount, characterPerLine) +
				paymentAmount +
				'\n',
		});

		const changeDue = priceFormatter(payment.changeDue);

		request += this.builder.createTextElement({
			data:
				'Change:' +
				this.getRepeatChar('Change:' + changeDue, characterPerLine) +
				changeDue +
				'\n',
		});

		request += this.builder.createTextElement({ data: '\n' });

		if (order.status.value === orderStatuses.PAID) {
			request += this.getOrderFinalMessage();

			request += this.builder.createTextElement({ data: '\n' });
		}

		request += this.builder.createCutPaperElement({ feed: true });

		// Second copy
		request += request;

		// Open cash drawer
		request += this.builder.createPeripheralElement({ channel: 1, on: 200, off: 200 });

		return request;
	}

	getCheckReceipt(order, payment, characterPerLine) {
		let request = '';

		request += this.builder.createInitializationElement();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.getReceiptHead(order);

		request += this.builder.createTextElement({ data: '\n' });

		request += this.getOrderDetails(order, characterPerLine);

		request += this.builder.createTextElement({ data: '\n' });

		request += this.getOrderTotals(order, characterPerLine);

		request += this.builder.createTextElement({ data: '\n' });

		const paymentAmount = priceFormatter(payment.amount);

		request += this.builder.createTextElement({
			data:
				'Check Payment:' +
				this.getRepeatChar('Check Payment:' + paymentAmount, characterPerLine) +
				paymentAmount +
				'\n',
		});

		request += this.builder.createTextElement({ data: '\n' });

		if (order.status.value === orderStatuses.PAID) {
			request += this.getOrderFinalMessage();

			request += this.builder.createTextElement({ data: '\n' });
		}

		request += this.builder.createCutPaperElement({ feed: true });

		// Second copy
		request += request;

		if (this.outlet.settings.openCashDrawerOnCheck) {
			// Open cash drawer
			request += this.builder.createPeripheralElement({ channel: 1, on: 200, off: 200 });
		}

		return request;
	}

	getCreditCardReceipt(order, payment, characterPerLine) {
		let request = '';

		let customerCopy = '';

		request += this.builder.createInitializationElement();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.getReceiptHead(order);

		request += this.builder.createTextElement({ data: '\n' });

		if (!payment.holdForSignature) {
			request += this.getOrderDetails(order, characterPerLine);

			request += this.builder.createTextElement({ data: '\n' });

			request += this.getOrderTotals(order, characterPerLine);

			request += this.builder.createTextElement({ data: '\n' });
		}

		if (
			payment.creditCardPaymentStatus.value !== creditCardPaymentStatuses.CAPTURED &&
			order.status.value !== orderStatuses.FINALIZED
		) {
			const paymentAmount = priceFormatter(payment.amount);

			if (payment.serviceFee)
				request += this.getServiceFeeLine(payment.serviceFee, characterPerLine);

			request += this.builder.createTextElement({
				data:
					'Auth. Payment:' +
					this.getRepeatChar('Auth. Payment:' + paymentAmount, characterPerLine) +
					paymentAmount +
					'\n',
			});

			if (!this.outlet.settings.hideReceiptTipLine) {
				request += this.builder.createTextElement({ data: '\n' });

				request += this.builder.createTextElement({
					data:
						'TIP:' +
						this.getRepeatChar('TIP:', parseInt(characterPerLine / 2, 10), '_') +
						'\n',
				});

				request += this.builder.createTextElement({ data: '\n' });

				request += this.builder.createTextElement({
					data:
						'TOTAL:' +
						this.getRepeatChar('TOTAL:', parseInt(characterPerLine / 2, 10), '_') +
						'\n',
				});
			}

			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data: 'x' + this.getRepeatChar('x', characterPerLine, '_') + '\n',
			});

			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data: 'I agree to pay above total amount according to card issuer agreement\n',
			});
		} else {
			const tip = priceFormatter(payment.tip || 0);

			if (tip)
				request += this.builder.createTextElement({
					data: 'Tip:' + this.getRepeatChar('Tip:' + tip, characterPerLine) + tip + '\n',
				});

			if (payment.serviceFee)
				request += this.getServiceFeeLine(payment.serviceFee, characterPerLine);

			const paymentAmount = priceFormatter(payment.amount);

			request += this.builder.createTextElement({
				data:
					'Credit Card Payment:' +
					this.getRepeatChar('Credit Card Payment:' + paymentAmount, characterPerLine) +
					paymentAmount +
					'\n',
			});

			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data: 'You agree to pay for the above listed signature\n',
			});
		}

		request += this.builder.createTextElement({ data: payment.paymentMethod.name + '\n' });

		request += this.builder.createTextElement({ data: payment.cardNumber + '\n' });

		request += this.builder.createTextElement({ data: payment.authCode + '\n' });

		request += this.builder.createTextElement({ data: '\n' });

		if (
			payment.creditCardPaymentStatus.value !== creditCardPaymentStatuses.CAPTURED &&
			payment.holdForSignature
		) {
			customerCopy = request;

			customerCopy += this.builder.createTextElement({ data: '** CUSTOMER COPY **\n' });

			customerCopy += this.builder.createCutPaperElement({ feed: true });
		}

		request += this.builder.createCutPaperElement({ feed: true });

		request += customerCopy;

		return request;
	}

	getHouseAccountReceipt(
		order,
		payment,
		customerBalance = null,
		type = 'House Account',
		characterPerLine
	) {
		let request = '';

		let customerCopy = '';

		request += this.builder.createInitializationElement();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.getReceiptHead(order, payment.customer);

		request += this.builder.createTextElement({ data: '\n' });

		if (!payment.holdForSignature) {
			request += this.getOrderDetails(order, characterPerLine);

			request += this.builder.createTextElement({ data: '\n' });

			request += this.getOrderTotals(order, characterPerLine);

			request += this.builder.createTextElement({ data: '\n' });
		}

		if (order.status.value !== orderStatuses.FINALIZED) {
			const paymentAmount = priceFormatter(payment.amount);

			request += this.builder.createTextElement({
				data:
					`${type} Payment:` +
					this.getRepeatChar(`${type} Payment:` + paymentAmount, characterPerLine) +
					paymentAmount,
			});

			if (customerBalance !== null)
				request += this.builder.createTextElement({
					data:
						'\nCustomer Balance:' +
						this.getRepeatChar(
							'Customer Balance:' + priceFormatter(customerBalance),
							characterPerLine
						) +
						priceFormatter(customerBalance),
				});

			request += this.builder.createTextElement({ data: '\n' });

			if (!this.outlet.settings.hideReceiptTipLine) {
				request += this.builder.createTextElement({ data: '\n' });

				request += this.builder.createTextElement({
					data:
						'TIP:' +
						this.getRepeatChar('TIP:', parseInt(characterPerLine / 2, 10), '_') +
						'\n',
				});

				request += this.builder.createTextElement({ data: '\n' });

				request += this.builder.createTextElement({
					data:
						'TOTAL:' +
						this.getRepeatChar('TOTAL:', parseInt(characterPerLine / 2, 10), '_') +
						'\n',
				});
			}

			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data: 'x' + this.getRepeatChar('x', characterPerLine, '_') + '\n',
			});

			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data: 'I agree to pay above total amount according to card issuer agreement\n',
			});
		} else {
			const tip = priceFormatter(payment.tip || 0);

			if (tip)
				request += this.builder.createTextElement({
					data: 'Tip:' + this.getRepeatChar('Tip:' + tip, characterPerLine) + tip + '\n',
				});

			const paymentAmount = priceFormatter(payment.amount + (payment.tip || 0));

			request += this.builder.createTextElement({
				data:
					`${type} Payment:` +
					this.getRepeatChar(`${type} Payment:` + paymentAmount, characterPerLine) +
					paymentAmount +
					'\n',
			});

			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data: 'You agree to pay for the above listed signature\n',
			});
		}

		request += this.builder.createTextElement({ data: '\n' });

		if (payment.holdForSignature) {
			customerCopy = request;

			customerCopy += this.builder.createTextElement({ data: '** CUSTOMER COPY **\n' });

			customerCopy += this.builder.createCutPaperElement({ feed: true });
		}

		request += this.builder.createCutPaperElement({ feed: true });

		request += customerCopy;

		return request;
	}

	getPayBillReceipt(
		payment,
		user,
		customer,
		isCredit,
		fromCredit,
		customerBalance = null,
		characterPerLine
	) {
		let request = '';

		request += this.builder.createInitializationElement();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.getOutletDetails();

		request += this.builder.createAlignmentElement({ position: 'left' });

		request += this.builder.createTextElement({
			data: 'Receipt: ' + payment.remittanceId + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Date: ' + dateFormatter(payment.remittanceDate || payment.timeModified) + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Cashier: ' + user.displayName,
		});

		if (this.register.name)
			request += this.builder.createTextElement({
				data: '    Station: ' + this.register.name + '\n',
			});
		else request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({
			data: 'Customer: ' + customer.displayName + '\n',
		});

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({
			data:
				'Qty  Description' +
				this.getRepeatChar('Qty  Description', characterPerLine) +
				'\n',
		});

		const formattedPriceWithoutServiceFee = priceFormatter(
			payment.amount - (payment.serviceFee || 0)
		);

		const formattedPrice = priceFormatter(payment.amount);

		const description = isCredit ? 'Credit' : 'Bill Pay';

		request += this.builder.createTextElement({
			data:
				'1    ' +
				description +
				this.getRepeatChar(
					'1    ' + description + formattedPriceWithoutServiceFee,
					characterPerLine
				) +
				formattedPriceWithoutServiceFee +
				'\n',
		});

		if (fromCredit) {
			request += this.builder.createTextElement({
				data:
					'     ' +
					paymentTypes.CREDIT +
					this.getRepeatChar(
						paymentTypes.CREDIT + formattedPriceWithoutServiceFee,
						characterPerLine - 5
					) +
					formattedPriceWithoutServiceFee +
					'\n',
			});
		} else if (
			payment.paymentMethod &&
			payment.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD &&
			payment.cardNumber
		) {
			if (payment.serviceFee)
				request += this.getServiceFeeLine(payment.serviceFee, characterPerLine);

			request += this.builder.createTextElement({
				data:
					'     ' +
					payment.paymentMethod.name +
					this.getRepeatChar(
						payment.paymentMethod.name + formattedPrice,
						characterPerLine - 5
					) +
					formattedPrice +
					'\n',
			});

			request += this.builder.createTextElement({
				data: this.getRepeatChar('', 5) + payment.cardNumber + '\n',
			});
		}

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({
			data:
				'Total:' +
				this.getRepeatChar('Total:' + formattedPrice, characterPerLine) +
				formattedPrice +
				'\n',
		});

		if (
			payment.paymentMethod &&
			payment.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD &&
			!fromCredit
		) {
			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data: 'x' + this.getRepeatChar('x', characterPerLine, '_') + '\n',
			});
		}

		if (payment.changeDue) {
			request += this.builder.createTextElement({
				data:
					'Payment:' +
					this.getRepeatChar(
						'Payment:' + priceFormatter(payment.amount + payment.changeDue),
						characterPerLine
					) +
					priceFormatter(payment.amount + payment.changeDue, true) +
					'\n',
			});

			request += this.builder.createTextElement({
				data:
					'Change:' +
					this.getRepeatChar(
						'Change:' + priceFormatter(payment.changeDue, true),
						characterPerLine
					) +
					priceFormatter(payment.changeDue, true) +
					'\n',
			});
		}

		if (customerBalance !== null)
			request += this.builder.createTextElement({
				data:
					'Customer Balance:' +
					this.getRepeatChar(
						'Customer Balance:' + priceFormatter(customerBalance),
						characterPerLine
					) +
					priceFormatter(customerBalance) +
					'\n',
			});

		request += this.builder.createTextElement({ data: '\n' });

		request += this.getOrderFinalMessage();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.builder.createCutPaperElement({ feed: true });

		// Second copy
		request += request;

		if (
			payment.paymentMethod &&
			(payment.paymentMethod.paymentType.value === paymentTypes.CASH ||
				(this.outlet.settings.openCashDrawerOnCheck &&
					payment.paymentMethod.paymentType.value === paymentTypes.CHECK))
		) {
			// Open cash drawer
			request += this.builder.createPeripheralElement({ channel: 1, on: 200, off: 200 });
		}

		return request;
	}

	getRefundReceipt(order, refund = null, characterPerLine) {
		let request = '';

		request += this.builder.createInitializationElement();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.getReceiptHead(
			order,
			null,
			'*** ' + (refund ? refund.paymentMethod.name : 'Credit Memo') + ' REFUND ***'
		);

		request += this.builder.createTextElement({ data: '\n' });

		request += this.getOrderDetails(order, characterPerLine);

		request += this.builder.createTextElement({ data: '\n' });

		request += this.getOrderTotals(order, characterPerLine);

		request += this.builder.createTextElement({ data: '\n' });

		if (refund && refund.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD) {
			request += this.builder.createTextElement({ data: refund.paymentMethod.name + '\n' });

			request += this.builder.createTextElement({ data: refund.cardNumber + '\n' });

			request += this.builder.createTextElement({ data: refund.authCode + '\n' });

			request += this.builder.createTextElement({ data: '\n' });
		}

		request += this.builder.createTextElement({ data: '\n' });

		request += this.getOrderFinalMessage();

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createCutPaperElement({ feed: true });

		// Second copy
		request += request;

		return request;
	}

	getPaymentData(invoices, payments = []) {
		const paymentData = {
			methods: {},
			total: 0,
			tip: 0,
			nonCashTip: 0,
			count: 0,
		};

		paymentData.methods[paymentTypes.HOUSE_ACCOUNT] = {
			count: 0,
			price: 0,
			tip: 0,
			label: paymentTypes.HOUSE_ACCOUNT,
		};

		const addByInvoices = [];

		invoices.forEach(i => {
			const tip = i.tip || 0;

			const totalWithoutTip = i.total - tip;

			if (i.fromHouseAccount) {
				paymentData.methods[paymentTypes.HOUSE_ACCOUNT].count++;

				paymentData.methods[paymentTypes.HOUSE_ACCOUNT].price += totalWithoutTip;

				paymentData.methods[paymentTypes.HOUSE_ACCOUNT].tip += tip;

				paymentData.nonCashTip += tip;
			} else {
				i.customerSettlements.forEach(cs => {
					if (cs.remittance) addByInvoices.push(cs.remittance.id);

					const paymentMethodName = cs.remittance.paymentMethod
						? cs.remittance.paymentMethod.name
						: paymentTypes.CREDIT;

					if (!paymentData.methods[paymentMethodName])
						paymentData.methods[paymentMethodName] = {
							count: 0,
							price: 0,
							tip: 0,
							label: paymentMethodName,
						};

					paymentData.methods[paymentMethodName].count++;

					paymentData.methods[paymentMethodName].price += totalWithoutTip;

					paymentData.methods[paymentMethodName].tip += tip;

					if (paymentMethodName !== paymentTypes.CASH) paymentData.nonCashTip += tip;
				});

				paymentData.total += totalWithoutTip;

				paymentData.count++;
			}

			paymentData.tip += tip;
		});

		payments
			.filter(p => addByInvoices.indexOf(p.id) === -1)
			.forEach(payment => {
				const paymentMethodName = payment.paymentMethod.name;

				if (!paymentData.methods[paymentMethodName])
					paymentData.methods[paymentMethodName] = {
						count: 0,
						price: 0,
						tip: 0,
						label: paymentMethodName,
					};

				paymentData.methods[paymentMethodName].count++;

				paymentData.methods[paymentMethodName].price += payment.amount;
			});

		return paymentData;
	}

	getRefundData(refunds) {
		const refundData = {
			methods: {},
			total: 0,
			count: 0,
		};

		refunds.forEach(r => {
			const refund = r.refunds.length ? r.refunds.pop() : null;

			if (refund) {
				if (!refundData.methods[refund.paymentMethod.name])
					refundData.methods[refund.paymentMethod.name] = {
						count: 0,
						price: 0,
						label: refund.paymentMethod.name,
					};

				refundData.methods[refund.paymentMethod.name].count++;

				refundData.methods[refund.paymentMethod.name].price += r.total;
			} else {
				if (!refundData.methods[paymentTypes.HOUSE_ACCOUNT])
					refundData.methods[paymentTypes.HOUSE_ACCOUNT] = {
						count: 0,
						price: 0,
						label: paymentTypes.HOUSE_ACCOUNT,
					};

				refundData.methods[paymentTypes.HOUSE_ACCOUNT].count++;

				refundData.methods[paymentTypes.HOUSE_ACCOUNT].price += r.total;
			}

			refundData.total += r.total;

			refundData.count++;
		});

		return refundData;
	}

	getSalesData(invoices, defaultIncomeAccount = null) {
		const sales = {
			accounts: {},
			subtotal: 0,
			discount: 0,
			tax: 0,
			total: 0,
			count: 0,
		};

		invoices.forEach(i => {
			i.invoiceItems.forEach(ii => {
				let account = ii.product.account || ii.product.incomeAccount;

				if (!account) account = defaultIncomeAccount || { id: 0, name: 'Unknown Income' };

				let discountInPercent = 0;

				if (ii.discountAmount) {
					discountInPercent =
						ii.discountType.value === 'fixed'
							? (ii.discountAmount / ii.subtotal) * 100
							: ii.discountAmount;
				}

				const subtotalWithDiscount =
					ii.subtotal - (discountInPercent ? (ii.subtotal * discountInPercent) / 100 : 0);

				if (!sales.accounts[account.id])
					sales.accounts[account.id] = {
						methods: {},
						count: 0,
						amount: 0,
						label: account.name,
					};

				if (i.fromHouseAccount === 0) {
					if (!sales.accounts[account.id].methods[paymentTypes.HOUSE_ACCOUNT])
						sales.accounts[account.id].methods[paymentTypes.HOUSE_ACCOUNT] = {
							amount: 0,
							count: 0,
							label: paymentTypes.HOUSE_ACCOUNT,
						};

					sales.accounts[account.id].methods[
						paymentTypes.HOUSE_ACCOUNT
					].amount += subtotalWithDiscount;

					sales.accounts[account.id].methods[paymentTypes.HOUSE_ACCOUNT].count++;
				} else {
					i.customerSettlements.forEach(cs => {
						const paymentMethodName = cs.remittance.paymentMethod
							? cs.remittance.paymentMethod.name
							: paymentTypes.CREDIT;

						if (!sales.accounts[account.id].methods[paymentMethodName])
							sales.accounts[account.id].methods[paymentMethodName] = {
								amount: 0,
								count: 0,
								label: paymentMethodName,
							};

						sales.accounts[account.id].methods[
							paymentMethodName
						].amount += subtotalWithDiscount;

						sales.accounts[account.id].methods[paymentMethodName].count++;
					});
				}

				sales.accounts[account.id].amount += subtotalWithDiscount;

				sales.accounts[account.id].count++;
			});

			sales.subtotal += i.subtotal;

			sales.discount += i.discountTotal;

			sales.tax += i.taxTotal;

			sales.total += i.total - (i.tip || 0);

			sales.count++;
		});

		return sales;
	}

	getBillPaymentData(payments) {
		const paymentData = {
			methods: {},
			total: 0,
			tip: 0,
			count: 0,
		};

		payments.forEach(p => {
			if (!paymentData.methods[p.paymentMethod.name])
				paymentData.methods[p.paymentMethod.name] = {
					count: 0,
					price: 0,
					label: p.paymentMethod.name,
				};

			paymentData.methods[p.paymentMethod.name].count++;

			paymentData.methods[p.paymentMethod.name].price += p.amount;

			paymentData.total += p.amount;

			paymentData.count++;
		});

		return paymentData;
	}

	getServerCloseOutPrint(
		server,
		orders,
		refunds,
		register,
		cashTip,
		defaultIncomeAccount,
		characterPerLine
	) {
		let request = '';

		const invoices = [];

		orders.forEach(o => {
			invoices.push(...o.invoices);
		});

		const paymentData = this.getPaymentData(invoices);

		const salesData = this.getSalesData(invoices, defaultIncomeAccount);

		const refundData = this.getRefundData(refunds);

		let closingCash = paymentData.methods[paymentTypes.CASH]
			? paymentData.methods[paymentTypes.CASH].price +
			  paymentData.methods[paymentTypes.CASH].tip
			: 0;

		if (refundData.methods[paymentTypes.CASH])
			closingCash -= refundData.methods[paymentTypes.CASH].price;

		request += this.builder.createInitializationElement();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.builder.createAlignmentElement({ position: 'center' });

		request += this.getOutletDetails();

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createAlignmentElement({ position: 'left' });

		request += this.builder.createTextElement({ data: 'Server Close Out\n' });

		request += this.builder.createTextElement({ data: server.displayName + '\n' });

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({ data: 'Station: ' + register.name + '\n' });

		if (server.currentShift)
			request += this.builder.createTextElement({
				data: 'Shift: ' + server.currentShift.id + '\n',
			});

		request += this.builder.createTextElement({
			data: 'Open Date: ' + dateFormatter(null, false) + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Close Date: ' + dateFormatter() + '\n',
		});

		// sfc 2020-05-27 future enhancement to allow server entering starting cash
		request += this.builder.createTextElement({
			data: 'Opening Cash: ' + priceFormatter(0) + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Closing Cash: ' + priceFormatter(closingCash) + '\n',
		});

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({
			data: `Qty    Desc${this.getRepeatChar('Qty    DescPrice', characterPerLine)}Price\n`,
		});

		// Payments and Tips
		if (Object.keys(paymentData.methods).length > 0) {
			// Payments
			request += this.builder.createTextElement({
				data:
					this.getRepeatChar('', (characterPerLine - 7) / 2, '*') +
					'PAYMENT' +
					this.getRepeatChar('', (characterPerLine - 7) / 2, '*') +
					'\n',
			});

			Object.keys(paymentData.methods).forEach(name => {
				if (name !== paymentTypes.HOUSE_ACCOUNT) {
					const formattedPrice = priceFormatter(paymentData.methods[name].price);

					request += this.builder.createTextElement({
						data:
							paymentData.methods[name].count +
							this.getRepeatChar(paymentData.methods[name].count, 7) +
							paymentData.methods[name].label +
							this.getRepeatChar(
								paymentData.methods[name].label + formattedPrice,
								characterPerLine - 7
							) +
							formattedPrice +
							'\n',
					});
				}
			});

			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data:
					paymentData.count +
					this.getRepeatChar(paymentData.count, 7) +
					'Total' +
					this.getRepeatChar(
						'Total' + priceFormatter(paymentData.total),
						characterPerLine - 7
					) +
					priceFormatter(paymentData.total) +
					'\n',
			});

			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data:
					this.getRepeatChar('', (characterPerLine - 7) / 2, '*') +
					'CHARGES' +
					this.getRepeatChar('', (characterPerLine - 7) / 2, '*') +
					'\n',
			});

			// how do we handle booking payments?
			request += this.builder.createTextElement({
				data:
					paymentData.methods[paymentTypes.HOUSE_ACCOUNT].count +
					this.getRepeatChar(paymentData.methods[paymentTypes.HOUSE_ACCOUNT].count, 7) +
					'House Account Charge' +
					this.getRepeatChar(
						'House Account Charge' +
							priceFormatter(paymentData.methods[paymentTypes.HOUSE_ACCOUNT].price),
						characterPerLine - 7
					) +
					priceFormatter(paymentData.methods[paymentTypes.HOUSE_ACCOUNT].price) +
					'\n',
			});

			// Tips
			if (paymentData.tip) {
				request += this.builder.createTextElement({ data: '\n' });

				request += this.builder.createTextElement({
					data:
						this.getRepeatChar('', (characterPerLine - 4) / 2, '*') +
						'TIPS' +
						this.getRepeatChar('', (characterPerLine - 4) / 2, '*') +
						'\n',
				});

				Object.keys(paymentData.methods).forEach(name => {
					if (paymentData.methods[name].tip) {
						request += this.builder.createTextElement({
							data:
								this.getRepeatChar('', 7) +
								paymentData.methods[name].label +
								this.getRepeatChar(
									paymentData.methods[name].label +
										priceFormatter(paymentData.methods[name].tip),
									characterPerLine - 7
								) +
								priceFormatter(paymentData.methods[name].tip) +
								'\n',
						});
					}
				});
			}
		}

		// Sales
		if (Object.keys(salesData.accounts).length > 0) {
			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data:
					this.getRepeatChar('', (characterPerLine - 5) / 2, '*') +
					'SALES' +
					this.getRepeatChar('', (characterPerLine - 5) / 2, '*') +
					'\n',
			});

			Object.keys(salesData.accounts).forEach(accountId => {
				request += this.builder.createTextElement({
					data:
						salesData.accounts[accountId].count +
						this.getRepeatChar(salesData.accounts[accountId].count, 7) +
						salesData.accounts[accountId].label +
						this.getRepeatChar(
							salesData.accounts[accountId].label +
								priceFormatter(salesData.accounts[accountId].amount),
							characterPerLine - 7
						) +
						priceFormatter(salesData.accounts[accountId].amount) +
						'\n',
				});

				Object.keys(salesData.accounts[accountId].methods).forEach(name => {
					request += this.builder.createTextElement({
						data:
							this.getRepeatChar('', 12) +
							'->' +
							salesData.accounts[accountId].methods[name].label +
							':' +
							this.getRepeatChar(
								salesData.accounts[accountId].methods[name].label +
									priceFormatter(
										salesData.accounts[accountId].methods[name].amount
									),
								characterPerLine - 15
							) +
							priceFormatter(salesData.accounts[accountId].methods[name].amount) +
							'\n',
					});
				});
			});

			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data:
					this.getRepeatChar('', 7) +
					'Subtotal' +
					this.getRepeatChar(
						'Subtotal' + priceFormatter(salesData.subtotal),
						characterPerLine - 7
					) +
					priceFormatter(salesData.subtotal) +
					'\n',
			});

			if (salesData.discount) {
				request += this.builder.createTextElement({
					data:
						this.getRepeatChar('', 7) +
						'Discount' +
						this.getRepeatChar(
							'Discount' + priceFormatter(salesData.discount),
							characterPerLine - 7
						) +
						priceFormatter(salesData.discount) +
						'\n',
				});
			}

			request += this.builder.createTextElement({
				data:
					this.getRepeatChar('', 7) +
					'Tax' +
					this.getRepeatChar(
						'Tax' + priceFormatter(salesData.tax),
						characterPerLine - 7
					) +
					priceFormatter(salesData.tax) +
					'\n',
			});

			request += this.builder.createTextElement({
				data:
					salesData.count +
					this.getRepeatChar(salesData.count, 7) +
					'Total' +
					this.getRepeatChar(
						'Total' + priceFormatter(salesData.total),
						characterPerLine - 7
					) +
					priceFormatter(salesData.total) +
					'\n',
			});

			request += this.builder.createTextElement({ data: '\n' });
		}

		request += this.builder.createTextElement({ data: '\n' });

		// Refunds
		request += this.builder.createTextElement({
			data:
				this.getRepeatChar('', (characterPerLine - 7) / 2, '*') +
				'REFUNDS' +
				this.getRepeatChar('', (characterPerLine - 7) / 2, '*') +
				'\n',
		});

		request += this.builder.createTextElement({
			data:
				refundData.count +
				this.getRepeatChar(refundData.count, 7) +
				'Refunds' +
				this.getRepeatChar(
					'Refunds' + priceFormatter(refundData.total),
					characterPerLine - 7
				) +
				priceFormatter(refundData.total) +
				'\n',
		});

		if (Object.keys(refundData.methods).length > 0) {
			Object.keys(refundData.methods).forEach(name => {
				request += this.builder.createTextElement({
					data:
						this.getRepeatChar('', 12) +
						'->' +
						refundData.methods[name].label +
						':' +
						this.getRepeatChar(
							refundData.methods[name].label +
								priceFormatter(refundData.methods[name].price),
							characterPerLine - 15
						) +
						priceFormatter(refundData.methods[name].price) +
						'\n',
				});
			});
		}

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({
			data:
				this.getRepeatChar('', 7) +
				'Closing Cash:' +
				this.getRepeatChar(
					'Closing Cash:' + priceFormatter(closingCash),
					characterPerLine - 7
				) +
				priceFormatter(closingCash) +
				'\n',
		});

		request += this.builder.createTextElement({
			data:
				this.getRepeatChar('', 7) +
				'TIPS:' +
				this.getRepeatChar(
					'TIPS:' + priceFormatter(paymentData.tip),
					characterPerLine - 7
				) +
				priceFormatter(paymentData.tip) +
				'\n',
		});

		request += this.builder.createTextElement({
			data:
				this.getRepeatChar('', 7) +
				'Employee Reported Cash Tip:' +
				this.getRepeatChar(
					'Employee Reported Cash Tip:' + priceFormatter(cashTip, true),
					characterPerLine - 7
				) +
				priceFormatter(cashTip, true) +
				'\n',
		});

		request += this.builder.createTextElement({
			data: this.getRepeatChar('', characterPerLine) + '\n',
		});

		const formattedPrice = priceFormatter(
			(paymentData.methods[paymentTypes.CASH] || { price: 0 }).price - paymentData.nonCashTip
		);

		request += this.builder.createTextElement({
			data:
				this.getRepeatChar('', 7) +
				'Cash - (non-cash tips):' +
				this.getRepeatChar(
					'Cash - (non-cash tips):' + formattedPrice,
					characterPerLine - 7
				) +
				formattedPrice +
				'\n',
		});

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.builder.createCutPaperElement({ feed: true });

		return request;
	}

	getRegisterClosePrint(register, characterPerLine) {
		let request = '';

		const paymentData = this.getPaymentData(register.invoices, register.payments);

		const billPaymentData = this.getBillPaymentData(
			register.payments.filter(p => p.fromPayBill)
		);

		const refundData = this.getRefundData(register.refunds);

		const salesData = this.getSalesData(register.invoices, register.defaultIncomeAccount);

		request += this.builder.createInitializationElement();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.builder.createAlignmentElement({ position: 'center' });

		request += this.getOutletDetails();

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createAlignmentElement({ position: 'left' });

		request += this.builder.createTextElement({ data: 'Register Close Out\n' });

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({ data: 'Station: ' + register.data.id + '\n' });

		request += this.builder.createTextElement({
			data: 'Batch: ' + register.batch.id + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Open Date: ' + dateFormatter(register.batch.startTime, false) + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Close Date: ' + dateFormatter(register.batch.endTime) + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Opening Cash: ' + priceFormatter(register.batch.openingCash, true) + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Closing Cash: ' + priceFormatter(register.batch.closingCash) + '\n',
		});

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({
			data: `Qty    Desc${this.getRepeatChar('Qty    DescPrice', characterPerLine)}Price\n`,
		});

		// Sales Payments and Tips
		if (Object.keys(paymentData.methods).length > 0) {
			// Payments
			request += this.builder.createTextElement({
				data:
					this.getRepeatChar('', (characterPerLine - 12) / 2, '*') +
					'SALE PAYMENT' +
					this.getRepeatChar('', (characterPerLine - 12) / 2, '*') +
					'\n',
			});

			Object.keys(paymentData.methods).forEach(name => {
				if (name !== paymentTypes.HOUSE_ACCOUNT) {
					request += this.builder.createTextElement({
						data:
							paymentData.methods[name].count +
							this.getRepeatChar(paymentData.methods[name].count, 7) +
							paymentData.methods[name].label +
							this.getRepeatChar(
								paymentData.methods[name].label +
									priceFormatter(paymentData.methods[name].price),
								characterPerLine - 7
							) +
							priceFormatter(paymentData.methods[name].price) +
							'\n',
					});
				}
			});

			request += this.builder.createTextElement({
				data: this.getRepeatChar('', characterPerLine, '-') + '\n',
			});

			request += this.builder.createTextElement({
				data:
					paymentData.count +
					this.getRepeatChar(paymentData.count, 7) +
					'Total' +
					this.getRepeatChar(
						'Total' + priceFormatter(paymentData.total),
						characterPerLine - 7
					) +
					priceFormatter(paymentData.total) +
					'\n',
			});

			request += this.builder.createTextElement({ data: '\n' });

			// Tips
			if (paymentData.tip) {
				request += this.builder.createTextElement({ data: '\n' });

				request += this.builder.createTextElement({
					data:
						this.getRepeatChar('', (characterPerLine - 4) / 2, '*') +
						'TIPS' +
						this.getRepeatChar('', (characterPerLine - 4) / 2, '*') +
						'\n',
				});

				Object.keys(paymentData.methods).forEach(name => {
					if (paymentData.methods[name].tip) {
						request += this.builder.createTextElement({
							data:
								this.getRepeatChar('', 7) +
								paymentData.methods[name].label +
								this.getRepeatChar(
									paymentData.methods[name].label +
										priceFormatter(paymentData.methods[name].tip),
									characterPerLine - 7
								) +
								priceFormatter(paymentData.methods[name].tip) +
								'\n',
						});
					}
				});
			}

			request += this.builder.createTextElement({
				data: '\n',
			});
		}

		// Bill Payments
		if (Object.keys(billPaymentData.methods).length > 0) {
			// Payments
			request += this.builder.createTextElement({
				data:
					this.getRepeatChar('', (characterPerLine - 12) / 2, '*') +
					'BILL PAYMENT' +
					this.getRepeatChar('', (characterPerLine - 12) / 2, '*') +
					'\n',
			});

			// Customers
			register.payments
				.filter(p => p.fromPayBill)
				.forEach(bp => {
					const paymentLabel =
						bp.paymentMethod.name.length > 6
							? bp.paymentMethod.name.slice(0, 6)
							: bp.paymentMethod.name;

					const formattedPrice = priceFormatter(bp.amount);

					const customerName =
						bp.customer.displayName.length >
						characterPerLine - (7 + formattedPrice.length)
							? bp.customer.displayName.slice(
									0,
									characterPerLine - (7 + formattedPrice.length + 1)
							  )
							: bp.customer.displayName;

					request += this.builder.createTextElement({
						data:
							paymentLabel +
							this.getRepeatChar(paymentLabel, 7) +
							customerName +
							this.getRepeatChar(
								customerName + formattedPrice,
								characterPerLine - 7
							) +
							formattedPrice +
							'\n',
					});
				});

			request += this.builder.createTextElement({
				data: this.getRepeatChar('', characterPerLine, '-') + '\n',
			});

			Object.keys(billPaymentData.methods).forEach(name => {
				if (name !== paymentTypes.HOUSE_ACCOUNT) {
					request += this.builder.createTextElement({
						data:
							billPaymentData.methods[name].count +
							this.getRepeatChar(billPaymentData.methods[name].count, 7) +
							billPaymentData.methods[name].label +
							this.getRepeatChar(
								billPaymentData.methods[name].label +
									priceFormatter(billPaymentData.methods[name].price),
								characterPerLine - 7
							) +
							priceFormatter(billPaymentData.methods[name].price) +
							'\n',
					});
				}
			});

			request += this.builder.createTextElement({
				data: this.getRepeatChar('', characterPerLine, '-') + '\n',
			});

			request += this.builder.createTextElement({
				data:
					paymentData.count +
					this.getRepeatChar(billPaymentData.count, 7) +
					'Total' +
					this.getRepeatChar(
						'Total' + priceFormatter(billPaymentData.total),
						characterPerLine - 7
					) +
					priceFormatter(billPaymentData.total) +
					'\n',
			});

			request += this.builder.createTextElement({
				data: '\n',
			});
		}

		request += this.builder.createTextElement({
			data:
				this.getRepeatChar('', (characterPerLine - 7) / 2, '*') +
				'CHARGES' +
				this.getRepeatChar('', (characterPerLine - 7) / 2, '*') +
				'\n',
		});

		request += this.builder.createTextElement({
			data:
				paymentData.methods[paymentTypes.HOUSE_ACCOUNT].count +
				this.getRepeatChar(paymentData.methods[paymentTypes.HOUSE_ACCOUNT].count, 7) +
				'House Account Charge' +
				this.getRepeatChar(
					'House Account Charge' +
						priceFormatter(paymentData.methods[paymentTypes.HOUSE_ACCOUNT].price),
					characterPerLine - 7
				) +
				priceFormatter(paymentData.methods[paymentTypes.HOUSE_ACCOUNT].price) +
				'\n',
		});

		request += this.builder.createTextElement({ data: '\n' });

		// Sales
		if (Object.keys(salesData.accounts).length > 0) {
			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data:
					this.getRepeatChar('', (characterPerLine - 9) / 2, '*') +
					'SALE INFO' +
					this.getRepeatChar('', (characterPerLine - 9) / 2, '*') +
					'\n',
			});

			Object.keys(salesData.accounts).forEach(accountId => {
				request += this.builder.createTextElement({
					data:
						salesData.accounts[accountId].count +
						this.getRepeatChar(salesData.accounts[accountId].count, 7) +
						salesData.accounts[accountId].label +
						this.getRepeatChar(
							salesData.accounts[accountId].label +
								priceFormatter(salesData.accounts[accountId].amount),
							characterPerLine - 7
						) +
						priceFormatter(salesData.accounts[accountId].amount) +
						'\n',
				});

				Object.keys(salesData.accounts[accountId].methods).forEach(name => {
					request += this.builder.createTextElement({
						data:
							this.getRepeatChar('', 12) +
							'->' +
							salesData.accounts[accountId].methods[name].label +
							':' +
							this.getRepeatChar(
								salesData.accounts[accountId].methods[name].label +
									priceFormatter(
										salesData.accounts[accountId].methods[name].amount
									),
								characterPerLine - 15
							) +
							priceFormatter(salesData.accounts[accountId].methods[name].amount) +
							'\n',
					});
				});
			});

			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data:
					this.getRepeatChar('', 7) +
					'Subtotal' +
					this.getRepeatChar(
						'Subtotal' + priceFormatter(salesData.subtotal),
						characterPerLine - 7
					) +
					priceFormatter(salesData.subtotal) +
					'\n',
			});

			if (salesData.discount) {
				request += this.builder.createTextElement({
					data:
						this.getRepeatChar('', 7) +
						'Discount' +
						this.getRepeatChar(
							'Discount' + priceFormatter(salesData.discount),
							characterPerLine - 7
						) +
						priceFormatter(salesData.discount) +
						'\n',
				});
			}

			request += this.builder.createTextElement({
				data:
					this.getRepeatChar('', 7) +
					'Tax' +
					this.getRepeatChar(
						'Tax' + priceFormatter(salesData.tax),
						characterPerLine - 7
					) +
					priceFormatter(salesData.tax) +
					'\n',
			});

			request += this.builder.createTextElement({
				data:
					salesData.count +
					this.getRepeatChar(salesData.count, 7) +
					'Total' +
					this.getRepeatChar(
						'Total' + priceFormatter(salesData.total),
						characterPerLine - 7
					) +
					priceFormatter(salesData.total) +
					'\n',
			});

			request += this.builder.createTextElement({ data: '\n' });
		}

		request += this.builder.createTextElement({
			data:
				this.getRepeatChar('', (characterPerLine - 7) / 2, '*') +
				'REFUNDS' +
				this.getRepeatChar('', (characterPerLine - 7) / 2, '*') +
				'\n',
		});

		request += this.builder.createTextElement({
			data:
				refundData.count +
				this.getRepeatChar(refundData.count, 7) +
				'Refunds' +
				this.getRepeatChar(
					'Refunds' + priceFormatter(refundData.total),
					characterPerLine - 7
				) +
				priceFormatter(refundData.total) +
				'\n',
		});

		// Refunds
		if (Object.keys(refundData.methods).length > 0) {
			Object.keys(refundData.methods).forEach(name => {
				request += this.builder.createTextElement({
					data:
						this.getRepeatChar('', 12) +
						'->' +
						refundData.methods[name].label +
						':' +
						this.getRepeatChar(
							refundData.methods[name].label +
								priceFormatter(refundData.methods[name].price),
							characterPerLine - 15
						) +
						priceFormatter(refundData.methods[name].price) +
						'\n',
				});
			});

			request += this.builder.createTextElement({ data: '\n' });
		}

		request += this.builder.createTextElement({ data: '\n' });

		// Grand Total
		request += this.builder.createTextElement({
			data:
				this.getRepeatChar('', (characterPerLine - 11) / 2, '*') +
				'GRAND TOTAL' +
				this.getRepeatChar('', (characterPerLine - 11) / 2, '*') +
				'\n',
		});

		const methods = Array.from(
			new Set(Object.keys(paymentData.methods).concat(Object.keys(billPaymentData.methods)))
		);

		methods.forEach(m => {
			let price = 0;

			let count = 0;

			let label = '';

			if (paymentData.methods[m]) {
				price += paymentData.methods[m].price;

				count += paymentData.methods[m].count;

				label = paymentData.methods[m].label;
			}

			if (billPaymentData.methods[m]) {
				price += billPaymentData.methods[m].price;

				count += billPaymentData.methods[m].count;

				label = billPaymentData.methods[m].label;
			}

			if (price)
				request += this.builder.createTextElement({
					data:
						count +
						this.getRepeatChar(count, 7) +
						label +
						this.getRepeatChar(label + priceFormatter(price), characterPerLine - 7) +
						priceFormatter(price) +
						'\n',
				});
		});

		request += this.builder.createTextElement({ data: '\n' });

		const methodsIncludeRefunds = Array.from(
			new Set(methods.concat(Object.keys(refundData.methods)))
		);

		// Grand total refunds included
		request += this.builder.createTextElement({
			data:
				this.getRepeatChar('', (characterPerLine - 11) / 2, '*') +
				'GRAND TOTAL' +
				this.getRepeatChar('', (characterPerLine - 11) / 2, '*') +
				'\n',
		});

		request += this.builder.createTextElement({
			data:
				this.getRepeatChar('', (characterPerLine - 17) / 2, '*') +
				'(Include Refunds)' +
				this.getRepeatChar('', (characterPerLine - 17) / 2, '*') +
				'\n',
		});

		let grandTotal = 0;

		methodsIncludeRefunds.forEach(m => {
			let price = 0;

			let count = 0;

			let label = '';

			if (paymentData.methods[m]) {
				price += paymentData.methods[m].price;

				count += paymentData.methods[m].count;

				label = paymentData.methods[m].label;
			}

			if (billPaymentData.methods[m]) {
				price += billPaymentData.methods[m].price;

				count += billPaymentData.methods[m].count;

				label = billPaymentData.methods[m].label;
			}

			if (refundData.methods[m]) {
				price -= refundData.methods[m].price;

				count += refundData.methods[m].count;

				label = refundData.methods[m].label;
			}

			grandTotal += price;

			if (price)
				request += this.builder.createTextElement({
					data:
						count +
						this.getRepeatChar(count, 7) +
						label +
						this.getRepeatChar(label + priceFormatter(price), characterPerLine - 7) +
						priceFormatter(price) +
						'\n',
				});
		});

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({
			data: this.getRepeatChar('', characterPerLine, '-') + '\n',
		});

		request += this.builder.createTextElement({
			data:
				this.getRepeatChar('', 7) +
				'Grand Total' +
				this.getRepeatChar(
					'Grand Total' + priceFormatter(grandTotal),
					characterPerLine - 7
				) +
				priceFormatter(grandTotal) +
				'\n',
		});

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.builder.createCutPaperElement({ feed: true });

		return request;
	}

	getDepositPrint(reservation, payment, characterPerLine) {
		let request = '';

		request += this.builder.createInitializationElement();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.getOutletDetails();

		request += this.builder.createAlignmentElement({ position: 'left' });

		request += this.builder.createTextElement({
			data: 'Receipt: ' + payment.remittanceId + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Date: ' + dateFormatter(payment.remittanceDate || payment.timeCreated) + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Cashier: ' + this.user.displayName + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Customer: ' + payment.customer.displayName + '\n',
		});

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({
			data:
				'Qty  Description' +
				this.getRepeatChar('Qty  Description', characterPerLine) +
				'\n',
		});

		request += this.builder.createTextElement({
			data: this.getRepeatChar('', 5) + 'Rental #: ' + reservation.reservationId + '\n',
		});

		const formattedPriceWithoutServiceFee = priceFormatter(
			payment.amount - (payment.serviceFee || 0)
		);

		const formattedPrice = priceFormatter(payment.amount);

		const description = `Deposit`;

		request += this.builder.createTextElement({
			data:
				'1    ' +
				description +
				this.getRepeatChar(
					'1    ' + description + formattedPriceWithoutServiceFee,
					characterPerLine
				) +
				formattedPriceWithoutServiceFee +
				'\n',
		});

		if (
			payment.paymentMethod &&
			payment.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD &&
			payment.cardNumber
		) {
			if (payment.serviceFee)
				request += this.getServiceFeeLine(payment.serviceFee, characterPerLine);

			request += this.builder.createTextElement({
				data:
					'     ' +
					payment.paymentMethod.name +
					this.getRepeatChar(
						payment.paymentMethod.name + formattedPrice,
						characterPerLine - 5
					) +
					formattedPrice +
					'\n',
			});

			request += this.builder.createTextElement({
				data: this.getRepeatChar('', 5) + payment.cardNumber + '\n',
			});
		}

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({
			data:
				'Total:' +
				this.getRepeatChar('Total:' + formattedPrice, characterPerLine) +
				formattedPrice +
				'\n',
		});

		if (payment.changeDue) {
			request += this.builder.createTextElement({
				data:
					'Payment:' +
					this.getRepeatChar(
						'Payment:' + priceFormatter(payment.amount + payment.changeDue),
						characterPerLine
					) +
					priceFormatter(payment.amount + payment.changeDue, true) +
					'\n',
			});

			request += this.builder.createTextElement({
				data:
					'Change:' +
					this.getRepeatChar(
						'Change:' + priceFormatter(payment.changeDue, true),
						characterPerLine
					) +
					priceFormatter(payment.changeDue, true) +
					'\n',
			});
		}

		request += this.builder.createTextElement({ data: '\n' });

		if (
			payment.paymentMethod &&
			payment.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD
		) {
			request += this.builder.createTextElement({
				data: 'x' + this.getRepeatChar('x', characterPerLine, '_') + '\n',
			});

			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data: 'I agree to pay above total amount according to card issuer agreement\n',
			});

			request += this.builder.createTextElement({ data: '\n' });
		}

		request += this.getOrderFinalMessage();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.builder.createCutPaperElement({ feed: true });

		request += request;

		return request;
	}

	getReservationPaymentPrint(reservation, payment, characterPerLine) {
		let request = '';

		request += this.builder.createInitializationElement();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.getOutletDetails();

		request += this.builder.createAlignmentElement({ position: 'left' });

		request += this.builder.createTextElement({
			data: 'Receipt: ' + payment.remittanceId + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Date: ' + dateFormatter(payment.remittanceDate || payment.timeCreated) + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Cashier: ' + this.user.displayName + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Customer: ' + payment.customer.displayName + '\n',
		});

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({
			data:
				'Qty  Description' +
				this.getRepeatChar('Qty  Description', characterPerLine) +
				'\n',
		});

		request += this.builder.createTextElement({
			data: this.getRepeatChar('', 5) + 'Rental #: ' + reservation.reservationId + '\n',
		});

		const formattedPriceWithoutServiceFee = priceFormatter(
			payment.amount - (payment.serviceFee || 0)
		);

		const formattedPrice = priceFormatter(payment.amount);

		const description = `Payment`;

		request += this.builder.createTextElement({
			data:
				'1    ' +
				description +
				this.getRepeatChar(
					'1    ' + description + formattedPriceWithoutServiceFee,
					characterPerLine
				) +
				formattedPriceWithoutServiceFee +
				'\n',
		});

		if (
			payment.paymentMethod &&
			payment.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD &&
			payment.cardNumber
		) {
			if (payment.serviceFee)
				request += this.getServiceFeeLine(payment.serviceFee, characterPerLine);

			request += this.builder.createTextElement({
				data:
					'     ' +
					payment.paymentMethod.name +
					this.getRepeatChar(
						payment.paymentMethod.name + formattedPrice,
						characterPerLine - 5
					) +
					formattedPrice +
					'\n',
			});

			request += this.builder.createTextElement({
				data: this.getRepeatChar('', 5) + payment.cardNumber + '\n',
			});
		}

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({
			data:
				'Total:' +
				this.getRepeatChar('Total:' + formattedPrice, characterPerLine) +
				formattedPrice +
				'\n',
		});

		if (payment.changeDue) {
			request += this.builder.createTextElement({
				data:
					'Payment:' +
					this.getRepeatChar(
						'Payment:' + priceFormatter(payment.amount + payment.changeDue),
						characterPerLine
					) +
					priceFormatter(payment.amount + payment.changeDue, true) +
					'\n',
			});

			request += this.builder.createTextElement({
				data:
					'Change:' +
					this.getRepeatChar(
						'Change:' + priceFormatter(payment.changeDue, true),
						characterPerLine
					) +
					priceFormatter(payment.changeDue, true) +
					'\n',
			});
		}

		request += this.builder.createTextElement({ data: '\n' });

		if (
			payment.paymentMethod &&
			payment.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD
		) {
			request += this.builder.createTextElement({
				data: 'x' + this.getRepeatChar('x', characterPerLine, '_') + '\n',
			});

			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data: 'I agree to pay above total amount according to card issuer agreement\n',
			});

			request += this.builder.createTextElement({ data: '\n' });
		}

		request += this.getOrderFinalMessage();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.builder.createCutPaperElement({ feed: true });

		request += request;

		return request;
	}

	getReservationRefundPrint(reservation, refund, characterPerLine) {
		let request = '';

		request += this.builder.createInitializationElement();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.builder.createAlignmentElement({ position: 'center' });

		request += this.builder.createTextElement({
			data: '*** ' + refund.paymentMethod.name + ' REFUND ***\n',
		});

		request += this.getOutletDetails();

		request += this.builder.createAlignmentElement({ position: 'left' });

		request += this.builder.createTextElement({
			data: 'Receipt: ' + refund.refundId + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Date: ' + dateFormatter(refund.refundDate || refund.timeCreated) + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Cashier: ' + this.user.displayName + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Customer: ' + refund.customer.displayName + '\n',
		});

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({
			data:
				'Qty  Description' +
				this.getRepeatChar('Qty  Description', characterPerLine) +
				'\n',
		});

		request += this.builder.createTextElement({
			data: this.getRepeatChar('', 5) + 'Rental #: ' + reservation.id + '\n',
		});

		const formattedPriceWithoutServiceFee = priceFormatter(
			refund.amount - (refund.serviceFee || 0)
		);

		const formattedPrice = priceFormatter(refund.amount);

		const description = `Refund`;

		request += this.builder.createTextElement({
			data:
				'1    ' +
				description +
				this.getRepeatChar(
					'1    ' + description + formattedPriceWithoutServiceFee,
					characterPerLine
				) +
				formattedPriceWithoutServiceFee +
				'\n',
		});

		if (
			refund.paymentMethod &&
			refund.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD &&
			refund.cardNumber
		) {
			if (refund.serviceFee)
				request += this.getServiceFeeLine(refund.serviceFee, characterPerLine);

			request += this.builder.createTextElement({
				data:
					'     ' +
					refund.paymentMethod.name +
					this.getRepeatChar(
						refund.paymentMethod.name + formattedPrice,
						characterPerLine - 5
					) +
					formattedPrice +
					'\n',
			});

			request += this.builder.createTextElement({
				data: this.getRepeatChar('', 5) + refund.cardNumber + '\n',
			});
		}

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({
			data:
				'Total:' +
				this.getRepeatChar('Total:' + formattedPrice, characterPerLine) +
				formattedPrice +
				'\n',
		});

		if (refund.changeDue) {
			request += this.builder.createTextElement({
				data:
					'Payment:' +
					this.getRepeatChar(
						'Payment:' + priceFormatter(refund.amount + refund.changeDue),
						characterPerLine
					) +
					priceFormatter(refund.amount + refund.changeDue, true) +
					'\n',
			});

			request += this.builder.createTextElement({
				data:
					'Change:' +
					this.getRepeatChar(
						'Change:' + priceFormatter(refund.changeDue, true),
						characterPerLine
					) +
					priceFormatter(refund.changeDue, true) +
					'\n',
			});
		}

		request += this.builder.createTextElement({ data: '\n' });

		if (
			refund.paymentMethod &&
			refund.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD
		) {
			request += this.builder.createTextElement({
				data: 'x' + this.getRepeatChar('x', characterPerLine, '_') + '\n',
			});

			request += this.builder.createTextElement({ data: '\n' });

			request += this.builder.createTextElement({
				data: 'I agree to pay above total amount according to card issuer agreement\n',
			});

			request += this.builder.createTextElement({ data: '\n' });
		}

		request += this.getOrderFinalMessage();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.builder.createCutPaperElement({ feed: true });

		request += request;

		return request;
	}

	getPaymentPrint(payment, characterPerLine) {
		let request = '';

		request += this.builder.createInitializationElement();

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.getOutletDetails();

		request += this.builder.createAlignmentElement({ position: 'left' });

		request += this.builder.createTextElement({
			data: 'Receipt: ' + payment.remittanceId + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Date: ' + dateFormatter(payment.remittanceDate || payment.timeCreated) + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Cashier: ' + this.user.displayName + '\n',
		});

		request += this.builder.createTextElement({
			data: 'Customer: ' + payment.customer.displayName + '\n',
		});

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({ data: '\n' });

		const formattedPriceWithoutServiceFee = priceFormatter(
			payment.amount - (payment.serviceFee || 0)
		);

		const formattedPrice = priceFormatter(payment.amount);

		const description = `1    ${payment.paymentMethod.paymentType.value} Payment`;

		request += this.builder.createTextElement({
			data:
				description +
				this.getRepeatChar(
					description + formattedPriceWithoutServiceFee,
					characterPerLine
				) +
				formattedPriceWithoutServiceFee +
				'\n',
		});

		if (
			payment.paymentMethod &&
			payment.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD &&
			payment.cardNumber
		) {
			if (payment.serviceFee)
				request += this.getServiceFeeLine(payment.serviceFee, characterPerLine);

			request += this.builder.createTextElement({
				data:
					payment.paymentMethod.name +
					this.getRepeatChar(
						payment.paymentMethod.name + formattedPrice,
						characterPerLine
					) +
					formattedPrice +
					'\n',
			});

			request += this.builder.createTextElement({
				data: payment.cardNumber + '\n',
			});
		}

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({
			data:
				'Total:' +
				this.getRepeatChar('Total:' + formattedPrice, characterPerLine) +
				formattedPrice +
				'\n',
		});

		if (payment.changeDue) {
			request += this.builder.createTextElement({
				data:
					'Payment:' +
					this.getRepeatChar(
						'Payment:' + priceFormatter(payment.amount + payment.changeDue),
						characterPerLine
					) +
					priceFormatter(payment.amount + payment.changeDue, true) +
					'\n',
			});

			request += this.builder.createTextElement({
				data:
					'Change:' +
					this.getRepeatChar(
						'Change:' + priceFormatter(payment.changeDue, true),
						characterPerLine
					) +
					priceFormatter(payment.changeDue, true) +
					'\n',
			});
		}

		request += this.builder.createTextElement({ data: '\n' });

		request += this.builder.createTextElement({ characterspace: 0 });

		request += this.builder.createCutPaperElement({ feed: true });

		request += request;

		return request;
	}

	orderPrint(orders, printerId = null) {
		const _printerId = printerId || this.receiptPrinterId;

		if (!this.queues[_printerId]) throw Error('Cannot find given printer.');

		orders.forEach(order => {
			this.queues[_printerId].items.push({
				request: this.getOrderPrint(order, this.queues[_printerId].characterPerLine - 1),
				order,
				action: this.actions.ORDER,
			});
		});

		this.startQueue(_printerId);

		this.onStart();
	}

	servicePrint(order, orderItems) {
		if (!order) throw Error('Cannot find order.');

		const groupedItems = this.groupOrderItemsByPrinter(orderItems);

		if (groupedItems.fake) {
			this.onFinish({
				items: groupedItems.fake[0].items,
				action: this.actions.SERVICE,
				order,
			});
		}

		if (Object.keys(groupedItems).length === 1 && groupedItems.fake) {
			this.onServicePrintFinish();
			return;
		}

		// do not add fake printer items to queue.
		delete groupedItems.fake;

		Object.keys(groupedItems).forEach(printerId => {
			this.orderInServicePrint[printerId].push(order.id);
		});

		Object.entries(groupedItems).forEach(printer => {
			Object.entries(printer[1]).forEach(prepStation => {
				this.queues[printer[0]].items.push({
					request: this.getServicePrint(order, prepStation[1], printer[0]),
					items: prepStation[1].items,
					order,
					action: this.actions.SERVICE,
				});
			});

			this.startQueue(printer[0]);
		});

		this.onStart();
	}

	receiptPrint(order, payment, printerId = null, customerBalance = null) {
		const _printerId = printerId || this.receiptPrinterId;

		if (!this.queues[_printerId]) throw Error('Cannot find given printer.');

		if (!order) throw Error('Cannot find order.');

		switch (payment.paymentMethod.paymentType.value) {
			case paymentTypes.HOUSE_ACCOUNT:
			case paymentTypes.BOOKING:
				this.queues[_printerId].items.push({
					request: this.getHouseAccountReceipt(
						order,
						payment,
						customerBalance,
						payment.paymentMethod.paymentType.value,
						this.queues[_printerId].characterPerLine - 1
					),
					action: this.actions.RECEIPT,
					order,
				});
				break;
			case paymentTypes.CASH:
				this.queues[_printerId].items.push({
					request: this.getCashReceipt(
						order,
						payment,
						this.queues[_printerId].characterPerLine - 1
					),
					action: this.actions.RECEIPT,
					order,
				});
				break;
			case paymentTypes.CHECK:
				this.queues[_printerId].items.push({
					request: this.getCheckReceipt(
						order,
						payment,
						this.queues[_printerId].characterPerLine - 1
					),
					action: this.actions.RECEIPT,
					order,
				});
				break;
			default:
				this.queues[_printerId].items.push({
					request: this.getCreditCardReceipt(
						order,
						payment,
						this.queues[_printerId].characterPerLine - 1
					),
					action: this.actions.RECEIPT,
					order,
				});
				break;
		}

		this.startQueue(_printerId);

		this.onStart();
	}

	refundPrint(order, refund, printerId = null) {
		const _printerId = printerId || this.receiptPrinterId;

		if (!this.queues[_printerId]) throw Error('Cannot find given printer.');

		if (!order) throw Error('Cannot find order.');

		this.queues[_printerId].items.push({
			request: this.getRefundReceipt(
				order,
				refund,
				this.queues[_printerId].characterPerLine - 1
			),
			action: this.actions.REFUND,
			order,
		});

		this.startQueue(_printerId);

		this.onStart();
	}

	openCashDrawer() {
		if (!this.queues[this.receiptPrinterId]) throw Error('Cannot find given printer.');

		let request = '';

		request += this.builder.createInitializationElement();

		request += this.builder.createPeripheralElement({ channel: 1, on: 200, off: 200 });

		this.queues[this.receiptPrinterId].items.push({
			request,
			action: this.actions.OPEN_CASH_DRAWER,
		});

		this.startQueue(this.receiptPrinterId);
	}

	serverCloseOut(
		server,
		orders,
		refunds,
		register,
		cashTip,
		defaultIncomeAccount,
		printerId = null
	) {
		const _printerId = printerId || this.receiptPrinterId;

		if (!this.queues[_printerId]) throw Error('Cannot find given printer.');

		this.queues[_printerId].items.push({
			request: this.getServerCloseOutPrint(
				server,
				orders,
				refunds,
				register,
				cashTip,
				defaultIncomeAccount,
				this.queues[_printerId].characterPerLine - 1
			),
			action: this.actions.SERVER_CLOSE_OUT,
			server,
		});

		this.startQueue(_printerId);

		this.onStart();
	}

	registerCloseOut(registers, printerId) {
		if (!this.queues[printerId]) throw Error('Cannot find given printer.');

		let request = '';

		registers.forEach(register => {
			register.zOut = this.getRegisterClosePrint(
				register,
				this.queues[printerId].characterPerLine - 1
			);

			request += register.zOut;
		});

		this.queues[printerId].items.push({
			request,
			registers,
			action: this.actions.REGISTER_CLOSE,
		});

		this.startQueue(printerId);

		this.onStart();
	}

	payBillReceipt(
		payment,
		user,
		customer,
		isCredit,
		fromCredit,
		printerId,
		customerBalance = null
	) {
		if (printerId) this.receiptPrinterId = printerId;

		if (!this.receiptPrinterId) throw Error('Cannot find printer.');

		this.queues[this.receiptPrinterId].items.push({
			request: this.getPayBillReceipt(
				payment,
				user,
				customer,
				isCredit,
				fromCredit,
				customerBalance,
				this.queues[this.receiptPrinterId].characterPerLine - 1
			),
			action: this.actions.PAYBILL_RECEIPT,
		});

		this.startQueue(this.receiptPrinterId);

		this.onStart();
	}

	deposit(reservation, payment, printerId) {
		if (!this.queues[printerId]) throw Error('Cannot find given printer.');

		this.queues[printerId].items.push({
			request: this.getDepositPrint(
				reservation,
				{
					...payment,
					amount: payment.amount + (payment.serviceFee || 0),
				},
				this.queues[printerId].characterPerLine - 1
			),
			action: this.actions.REGISTER_CLOSE,
		});

		this.startQueue(printerId);

		this.onStart();
	}

	reservationPayment(reservation, payment, printerId) {
		if (!this.queues[printerId]) throw Error('Cannot find given printer.');

		this.queues[printerId].items.push({
			request: this.getReservationPaymentPrint(
				reservation,
				{
					...payment,
					amount: payment.amount + (payment.serviceFee || 0),
				},
				this.queues[printerId].characterPerLine - 1
			),
			action: this.actions.REGISTER_CLOSE,
		});

		this.startQueue(printerId);

		this.onStart();
	}

	reservationRefund(reservation, refunds, printerId) {
		if (!this.queues[printerId]) throw Error('Cannot find given printer.');

		this.queues[printerId].items.push({
			request: refunds
				.map(refund =>
					this.getReservationRefundPrint(
						reservation,
						refund,
						this.queues[printerId].characterPerLine - 1
					)
				)
				.join(this.builder.createCutPaperElement({ feed: true })),
			action: this.actions.REGISTER_CLOSE,
		});

		this.startQueue(printerId);

		this.onStart();
	}

	print(content, printerId) {
		if (!this.queues[printerId]) throw Error('Cannot find given printer.');

		this.queues[printerId].items.push({
			request: content,
			action: this.actions.REGISTER_CLOSE,
		});

		this.startQueue(printerId);

		this.onStart();
	}

	paymentPrint(payment, printerId) {
		if (!this.queues[printerId]) throw Error('Cannot find given printer.');

		this.queues[printerId].items.push({
			request: this.getPaymentPrint(payment, this.queues[printerId].characterPerLine - 1),
			action: this.actions.PAYMENT_PRINT,
		});

		this.startQueue(printerId);

		this.onStart();
	}
}
