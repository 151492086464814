import React, { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';

const usePrint = () => {
	const [content, setContent] = useState('');
	const buttonRef = useRef();
	const contentRef = useRef();
	const triggerRef = useRef(false);

	const onPrint = _content => {
		triggerRef.current = true;
		setContent(_content);
	};

	useEffect(() => {
		if (triggerRef.current) {
			buttonRef.current.click();
			triggerRef.current = false;
		}
	}, [content]);

	const COMPONENT = (
		<>
			<div
				ref={contentRef}
				className='sdms--only-print'
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: content,
				}}
			/>
			<ReactToPrint
				trigger={() => (
					<input
						type='button'
						id='printButton'
						style={{ display: 'none' }}
						ref={buttonRef}
					/>
				)}
				content={() => contentRef.current}
				pageStyle='@page { size: auto; } @media print { body { -webkit-print-color-adjust: exact; } }'
				onAfterPrint={() => setContent('')}
			/>
		</>
	);

	return [onPrint, COMPONENT];
};

export default usePrint;
