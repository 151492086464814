import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import usePages from '../../../utils/hooks/usePages';
import MarinaProductCategoryExtraChargeForm from './ProductCategoryExtraChargeForm';

const ProductCategoryExtraChargeEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='productCategoryExtraCharges'
			lists={['productCategoryExtraCharges']}
			fields={<MarinaProductCategoryExtraChargeForm />}
			pageTitle={pages.marina.settings.extraChargeGroups.text}
			icon={pages.marina.settings.extraChargeGroups.icon}
		/>
	);
};

ProductCategoryExtraChargeEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ProductCategoryExtraChargeEdit;
