import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import useComponentSize from '@rehooks/component-size';

import UserContext from '../../../../../app/contexts/UserContext';
import useField from '../../../../../utils/hooks/useField';
import { min, numeric, required } from '../../../../../utils/helpers/validation';
import apiCall from '../../../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	addSuccessNotification,
	calculateSqft,
	convertDateToUTC,
	dateFormatterWithoutYear,
	filterInvoicingFrequency,
	getItemTaxCode,
	getNextInvoice,
	getNextInvoiceOptions,
	isInvoicingFrequencyBigger,
	numberFormat,
	numberParser,
	parseDatePickerChange,
	parseDatePickerValue,
	priceFormatter,
	useWindowSize,
} from '../../../../../utils/helpers/helper';
import DatePicker from '../../../field/DatePicker';
import {
	bookBies,
	bookingPeriods,
	reservationStatuses,
	searchTypes,
	invoicingFrequencies as _invoicingFrequencies,
	invoiceStatuses,
	mediaBreakpoint,
	nextInvoiceOptions,
	firstInvoiceOptions,
	bookingCalculations,
} from '../../../../../utils/constants/constants';
import { nonTransientAdvancedFormPages } from '../../../../pages';
import {
	calculateExtraFeeTotals,
	calculateItemTotals,
	displayDepositButton,
	getCapacity,
	getCurrentUnit,
	getNextInvoiceDate,
	getNextInvoiceDateMin,
	getPolicy,
	getPrices,
	getRatePlan,
	getRefundableSecurityDepositAmount,
	getRemainingDepositAmount,
	getReservationTotalsByInvoices,
	hasReservationInvoice,
	onExtraChargeItemUpdate,
} from '../../../../../utils/helpers/reservationHelper';
import useModal from '../../../../../utils/hooks/useModal';
import useFeet from '../../../../../utils/hooks/useFeet';
import { getAvailableUnits, getBookingHourlyStep } from '../../../../../utils/helpers/reusable';

import VesselModal from '../../../modals/VesselModal';
import Portlet from '../../../layout/Portlet';
import Button from '../../../element/Button';
import FormGroup from '../../../layout/FormGroup';
import FormField from '../../../template/FormField';
import Input, { InputGroup, InputPend } from '../../../field/Input';
import TimePickerInput from '../../../field/TimePickerInput';
import Toggle from '../../../field/Toggle';
import Selects from '../../../field/Selects';
import Textarea from '../../../field/Textarea';
import Section from '../../../layout/Section';
import ReservationStatusCell from '../../../element/ReservationStatusCell';
import Alert from '../../../element/Alert';
import Badge from '../../../element/Badge';
import Separator from '../../../layout/Separator';
import { ExtraFeeHead, ExtraFeeRow } from '../../../element/ExtraFee';
import VehicleModal from '../../../modals/VehicleModal';
import LengthInputGroup from '../../../field/LengthInputGroup';
import Radio from '../../../field/Radio';
import Dropdown from '../../../element/Dropdown';
import InvoiceList from '../InvoiceList';
import PaymentList from '../PaymentList';
import SpaceAssignmentList from '../SpaceAssignmentList';
import ContractList from '../ContractList';
import InnerPage from '../../../template/InnerPage';
import SVGIcon from '../../../element/SVGIcon';
import ReservationStatusDropdown from '../../../element/ReservationStatusDropdown';
import WaitListIgnoreCapacityToggle from '../../../element/WaitListIgnoreCapacityToggle';
import ExtraFeeSettings from '../../../modals/ExtraFeeSettings';
import {
	hasOverrideConstraintPermission,
	hasEditLockedReservationPermission,
	hasEditReservationDatesPermission,
	hasEditReservationUnitPermission,
	hasSwapReservationUnitPermission,
	hasEditReservationCancellationPolicyPermission,
	hasEditReservationProductContractsPermission,
	hasEditReservationSpecialRequestsPermission,
	hasEditReservationFeesPermission,
	hasEditReservationPricePermission,
	hasEditSeasonDatesPermission,
	hasReservationEditExternalNotesPermission,
} from '../../../../../utils/helpers/permission';

const modals = {
	VESSEL_SELECT: 'vesselSelect',
	VESSEL_FORM: 'vesselForm',
	VEHICLE_SELECT: 'vehicleSelect',
	VEHICLE_FORM: 'vehicleForm',
	EXTRA_FEE_SETTINGS: 'extraFeeSettings',
};

const ReservationAdvancedNonTransientItemForm = ({
	form,
	disabled,
	responsive,
	listMediaBreakpoint,
	data,
	taxRate,
	reservation,
	onFormChange,
	ratePlans,
	policies,
	enumBoatMakes,
	enumBoatTypes,
	enumVehicleMakes,
	enumRvTypes,
	seasons,
	contracts,
	invoiceFirstOptions,
	invoiceNextOptions,
	invoicingFrequencies,
	paymentTerms,
	currentExtraChargeItemProductId,
	onOverride,
	onClose,
	onUpdate,
	openPayModal,
	openCustomerModal,
	onCheckIn,
	onCheckOut,
	onInvoiceChange,
	onItemUpdateStatus,
	onSendConfirmationEmail,
	onPrintReservationInvoice,
	onSendInvoiceEmail,
	onCancel,
	innerPageNavStatus,
	setInnerPageNavStatus,
	onPaymentPrint,
	paymentTypes,
	webPrint,
	enumBookingCalculations,
	parentModals,
	onItemUpdateCustomStatus,
	accounts,
	taxCodes,
	onPaymentScheduleChange,
	onCustomerPaymentMethodChange,
	onAuthorizePayment,
	onCaptureAuthorizedPayment,
	onVoidAuthorizedPayment,
	cancellationReason,
	cancellationTime,
}) => {
	const windowSize = useWindowSize();

	const userContext = useContext(UserContext);

	const refForTimePicker = useRef(null);

	const sizeTimePicker = useComponentSize(refForTimePicker);

	const refHead = useRef(null);

	const sizeHead = useComponentSize(refHead);

	const [activeStep, setActiveStep] = useState(0);

	const [submitButtonAttr, setSubmitButtonAttr] = useState({
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	});

	const timePickerStep = useRef(getBookingHourlyStep(userContext));

	const longTermToDate = useRef(
		data.product.maxBookInAdvance || process.env.REACT_APP_LONG_TERM_TO_DATE
	);

	const updateVehicleData = useRef(false);

	const updateVesselData = useRef(false);

	const vesselVehicleRequired = field =>
		data.product.bookingType[field]?.value === searchTypes.REQUIRED ? required : () => true;

	const seasonRequired = (value, setValRes) => {
		if (form.isSeasonal) return required(value, setValRes);
		return true;
	};

	const firstInvoiceRequired = (value, setValRes) => {
		if (invoicingFrequency && invoicingFrequency.value !== _invoicingFrequencies.UPFRONT)
			return required(value, setValRes);

		return true;
	};

	const nextInvoiceRequired = (value, setValRes) => {
		if (invoicingFrequency && firstInvoice) return required(value, setValRes);

		return true;
	};

	const proratedFrequencyRequired = (value, setValRes) => {
		if (firstInvoice && firstInvoice.value === firstInvoiceOptions.PRO_RATE)
			return required(value, setValRes);
		return true;
	};

	const coTermRequired = (value, setValRes) => {
		if (nextInvoice && nextInvoice.value === nextInvoiceOptions.CO_TERM)
			return required(value, setValRes);
		return true;
	};

	// conditionally required for deferred fields.
	const deferredRequired = (value, setValRes) => {
		if (enableDeferredIncome) return required(value, setValRes);
		return true;
	};

	const [isValid, setIsValid] = useState(false);

	const [isDateChanged, setIsDateChanged] = useState(!data.id);

	const [isUnitSelectDisable, setIsUnitSelectDisable] = useState(true);

	const [isReservationSectionOpen, setIsReservationSectionOpen] = useState(
		!data.id || data.status.value === reservationStatuses.WAITLIST
	);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [vehicle, setVehicle] = useState(data.vehicle);

	const [vessel, setVessel] = useState(data.vessel);

	const getSelectVehicleVesselButtonText = () => {
		if (vehicle) return vehicle.name;

		if (vessel) return vessel.name;

		if (form.isCampground) return 'Select a Vehicle';

		return 'Select a Vessel';
	};

	const [loa, loaOnChange, loaValRes, loaShowVal, setLoaShowVal] = useField(
		data,
		'loa',
		onFormChange,
		[vesselVehicleRequired('searchLoa')],
		null,
		numberParser()
	);

	const [loaFt, setLoaFt, loaIn, setLoaIn, , setLoa] = useFeet(
		data.loa,
		loaOnChange,
		false,
		data.loa
	);

	const [beam, beamOnChange, beamValRes, beamShowVal, setBeamShowVal] = useField(
		data,
		'beam',
		onFormChange,
		[vesselVehicleRequired('searchBeam')],
		null,
		numberParser()
	);

	const [beamFt, setBeamFt, beamIn, setBeamIn, , setBeam] = useFeet(
		data.beam,
		beamOnChange,
		false,
		data.beam
	);

	const [draft, draftOnChange, draftValRes, draftShowVal, setDraftShowVal] = useField(
		data,
		'draft',
		onFormChange,
		[vesselVehicleRequired('searchDraft')],
		null,
		numberParser()
	);
	const [draftFt, setDraftFt, draftIn, setDraftIn, , setDraft] = useFeet(
		data.draft,
		draftOnChange,
		false,
		data.draft
	);

	const [height, heightOnChange, heightValRes, heightShowVal, setHeightShowVal] = useField(
		data,
		'height',
		onFormChange,
		[vesselVehicleRequired('searchHeight')],
		null,
		numberParser()
	);

	const [heightFt, setHeightFt, heightIn, setHeightIn, , setHeight] = useFeet(
		data.height,
		heightOnChange,
		false,
		data.height
	);

	const [season, seasonOnChange, seasonValRes, seasonShowVal, setSeasonShowVal] = useField(
		data,
		'season',
		onFormChange,
		[seasonRequired],
		data.season
	);

	const [weight, weightOnChange, weightValRes, weightShowVal, setWeightShowVal] = useField(
		data,
		'weight',
		onFormChange,
		[vesselVehicleRequired('searchWeight')],
		null,
		numberParser()
	);

	const getDefaultDates = (isFrom = true) => {
		if (isFrom)
			return data.fromDate
				? moment(data.fromDate).utc(false)
				: moment()
						.utc(false)
						.startOf('day');

		if (data.toDate) return moment(data.toDate).utc(false);

		const _toDate = moment()
			.utc(false)
			.endOf('day');

		if (form.isLongTerm) _toDate.add(longTermToDate.current, 'day');

		return _toDate;
	};

	const [fromDate, setFromDate] = useState(getDefaultDates());

	const [toDate, setToDate] = useState(getDefaultDates(false));

	const [availableUnits, setAvailableUnits] = useState([]);

	const [unit, unitOnChange, unitValRes, unitShowVal, setUnitShowVal] = useField(
		data,
		'unit',
		onFormChange,
		[required],
		data.unit || null
	);

	const [ignoreRules, setIgnoreRules] = useState(data.ignoredRules || false);

	const [ignoreCapacity, setIgnoreCapacity] = useState(data.ignoredCapacity || false);

	const [isWaitList, setIsWaitList] = useState(false);

	const [availableRatePlans, setAvailableRatePlans] = useState([]);

	const [ratePlan, ratePlanOnChange] = useField(
		data,
		'ratePlan',
		onFormChange,
		[],
		data.ratePlan || null
	);

	const [policy, policyOnChange] = useField(
		data,
		'policy',
		onFormChange,
		[],
		data.policy || null
	);

	const [
		depositAmount,
		depositAmountOnChange,
		depositAmountValRes,
		depositAmountShowVal,
		setDepositAmountShowVal,
	] = useField(
		{},
		'depositAmount',
		onFormChange,
		[required, numeric, min(0)],
		data.depositAmount ? parseFloat(data.depositAmount).toFixed(2) : '0.00'
	);

	const [productContracts, productContractsOnChange] = useField(
		data,
		'productContracts',
		onFormChange,
		[],
		data.productContracts || data.product.productContracts
	);

	const quantityRef = useRef(data.quantity || 1);

	const [subtotal, subtotalOnChange] = useField(
		{},
		'subtotal',
		onFormChange,
		[],
		data.subtotal ? parseFloat(data.subtotal).toFixed(2) : '0.00'
	);

	const [fixedPrice, fixedPriceOnChange] = useField(
		data,
		'fixedPrice',
		onFormChange,
		[],
		data.fixedPrice
	);

	const [bookingCalculation, bookingCalculationOnChange] = useField(
		data,
		'bookingCalculation',
		onFormChange,
		[],
		data.product.bookingCalculation
	);

	const [totals, setTotals] = useState(
		calculateItemTotals(data, null, [], null, null, null, null, null, null, reservation)
	);

	const [pricing, setPricing] = useState({});

	const [selectedExtraCharges, setSelectedExtraCharges] = useState(data.extraCharges || []);

	const [extraCharges, setExtraCharges] = useState({});

	const [note, noteOnChange] = useField(data, 'note', onFormChange, [], data.note || '');

	const [internalNote, internalNoteOnChange] = useField(
		data,
		'internalNote',
		onFormChange,
		[],
		data.internalNote || ''
	);

	const [externalNote, externalNoteOnChange] = useField(
		data,
		'externalNote',
		onFormChange,
		[],
		data.externalNote || ''
	);

	const [invoiceGenerationAutomatedBatch, invoiceGenerationAutomatedBatchOnChange] = useField(
		data,
		'invoiceGenerationAutomatedBatch',
		onFormChange,
		[],
		true
	);

	const [
		invoicingFrequency,
		invoicingFrequencyOnChange,
		invoicingFrequencyValRes,
		invoicingFrequencyShowVal,
		setInvoicingFrequencyShowVal,
	] = useField(data, 'invoicingFrequency', onFormChange, [required], null);

	const [paymentTerm, paymentTermOnChange] = useField(
		data,
		'paymentTerm',
		onFormChange,
		[],
		null
	);

	const [
		firstInvoice,
		firstInvoiceOnChange,
		firstInvoiceValRes,
		firstInvoiceShowVal,
		setFirstInvoiceShowVal,
		validateFirstInvoice,
	] = useField(data, 'firstInvoice', onFormChange, [firstInvoiceRequired], data.firstInvoice);

	const [
		nextInvoice,
		nextInvoiceOnChange,
		nextInvoiceValRes,
		nextInvoiceShowVal,
		setNextInvoiceShowVal,
		validateNextInvoice,
	] = useField(data, 'nextInvoice', onFormChange, [nextInvoiceRequired], data.nextInvoice);

	const [
		proratedFrequency,
		proratedFrequencyOnChange,
		proratedFrequencyValRes,
		proratedFrequencyShowVal,
		setProratedFrequencyShowVal,
		validateProratedFrequency,
	] = useField(data, 'proratedFrequency', onFormChange, [proratedFrequencyRequired], null);

	const [
		coTermDate,
		coTermDateOnChange,
		coTermDateValRes,
		coTermDateShowVal,
		setCoTermDateShowVal,
		validateCoTermDate,
	] = useField(data, 'coTermDate', onFormChange, [coTermRequired], null);

	const [autoPay, autoPayOnChange] = useField(
		data,
		'autoPay',
		onFormChange,
		[],
		reservation.autoPay
	);

	const [prevInvoiceDate, prevInvoiceDateOnChange] = useField(
		data,
		'prevInvoiceDate',
		onFormChange,
		[],
		null
	);

	const [nextInvoiceDate, nextInvoiceDateOnChange] = useField(
		data,
		'nextInvoiceDate',
		onFormChange,
		[],
		null
	);

	const [enableDeferredIncome, enableDeferredIncomeOnChange] = useField(
		data,
		'enableDeferredIncome',
		onFormChange,
		[],
		false
	);

	const [
		deferredIncomeFrequency,
		deferredIncomeFrequencyOnChange,
		deferredIncomeFrequencyValRes,
		deferredIncomeFrequencyShowVal,
		setDeferredIncomeFrequencyShowVal,
		validateDeferredIncomeFrequency,
	] = useField(data, 'deferredIncomeFrequency', onFormChange, [deferredRequired], null);

	const [
		deferredIncomeSalesAccount,
		deferredIncomeSalesAccountOnChange,
		deferredIncomeSalesAccountValRes,
		deferredIncomeSalesAccountShowVal,
		setDeferredIncomeSalesAccountShowVal,
		validateDeferredIncomeSalesAccount,
	] = useField(data, 'deferredIncomeSalesAccount', onFormChange, [deferredRequired], null);

	const [taxCode, taxCodeOnChange, taxCodeValRes, taxCodeShowVal, setTaxCodeShowVal] = useField(
		data,
		'taxCode',
		onFormChange,
		[required],
		data.taxCode
	);

	const [isSearching, setIsSearching] = useState(false);

	const [swapUnits, setSwapUnits] = useState(false);

	const [swappableUnits, setSwappableUnits] = useState([]);

	const unitBackup = useRef(null);

	const extrasRef = useRef();

	const [modal, openModal, closeModal] = useModal();

	const balanceDue = useMemo(() => {
		const invoiceTotals = getReservationTotalsByInvoices(reservation);
		if (hasReservationInvoice(reservation)) return invoiceTotals.remainingAmount;
		return getRemainingDepositAmount(reservation);
	}, [reservation]);

	const remainingDepositAmount = getRemainingDepositAmount(reservation);

	const refundableSecurityDepositAmount = getRefundableSecurityDepositAmount(data);

	const [invalidExtraCharges, setInvalidExtraCharges] = useState([]);

	const dateConstraints = useMemo(() => {
		const constraints = {
			minFromDate: undefined,
			maxFromDate: undefined,
			minToDate: undefined,
			maxToDate: undefined,
		};

		if (form.isLongTerm) {
			constraints.maxFromDate = moment()
				.add(
					data.product.maxBookInAdvance || process.env.REACT_APP_LONG_TERM_MAX_BIA,
					'day'
				)
				.toDate();
			return constraints;
		}

		if (!season) return constraints;

		const seasonStart = moment(season.startDate);

		const seasonEnd = moment(season.endDate);

		constraints.minFromDate = seasonStart.toDate();

		constraints.maxFromDate = moment(seasonEnd)
			.add(-1, 'day')
			.toDate();

		constraints.minToDate = (fromDate
			? moment(fromDate)
			: moment(seasonStart).add(1, 'day')
		).toDate();

		constraints.maxToDate = seasonEnd.toDate();

		return constraints;
	}, [data, form, season, fromDate]);

	const areFieldsDisabled = () =>
		!unit ||
		disabled ||
		isSearching ||
		isWaitList ||
		((data.status.value === reservationStatuses.WAITLIST || data.id === 0) &&
			Object.keys(pricing).length === 0);

	const onFromDateChanged = _fromDate => {
		if (toDate.isSameOrBefore(_fromDate))
			setToDate(
				moment(_fromDate)
					.add(1, 'day')
					.endOf('day')
			);
	};

	const areSearchFieldsDisabled = (isIgnoreCapacity = false) => {
		if (data.spaceAssignments && data.spaceAssignments.length > 0) return true;

		if (isIgnoreCapacity)
			return (
				isSearching || disabled || data.product.bookingType.bookBy.value === bookBies.UNIT
			);

		return isSearching || disabled;
	};

	const areVesselVehicleFieldsDisabled = () => (form.isCampground ? vehicle : vessel) !== null;

	const isNextInvoiceDateDisable = () =>
		areFieldsDisabled() ||
		(invoicingFrequency && invoicingFrequency.value === _invoicingFrequencies.UPFRONT);

	const onSearchFieldChange = _isWaitList => {
		if (_isWaitList) return;
		setIsDateChanged(true);
		setIsUnitSelectDisable(true);
		setSwapUnits(false);
	};

	const onPricingChange = (_pricing, _availableRatePlans, isInit = false) => {
		try {
			if (_pricing.units) {
				const _availableUnits = getAvailableUnits(
					data.product,
					_pricing,
					form.module.value
				);

				setAvailableUnits(_availableUnits);

				if (!unit || !_availableUnits.find(u => u.id === unit.id))
					unitOnChange({ target: { value: _availableUnits[0] } });

				if (!isInit) {
					const _ratePlan = getRatePlan(_availableRatePlans, ignoreRules, _pricing);

					ratePlanOnChange({ target: { value: _ratePlan } });

					policyOnChange({
						target: { value: getPolicy(_pricing, _ratePlan, data.product, policies) },
					});

					let _subtotal = 0;

					const prices = getPrices(_pricing, _ratePlan);

					if (fixedPrice) _subtotal = data.subtotal || subtotal;
					else if (prices) _subtotal = numberFormat(prices[0]);

					subtotalOnChange({
						target: {
							value: _subtotal,
						},
					});

					quantityRef.current = prices ? prices.length : 1;
				} else updateTotals();
			}
		} catch (error) {
			console.error(error);
		}
	};

	const updateTotals = () => {
		const _totals = calculateItemTotals(
			data,
			subtotal,
			selectedExtraCharges,
			taxRate,
			policy,
			pricing,
			ratePlan,
			depositAmount,
			fromDate,
			reservation
		);

		setTotals(_totals);

		if (
			data.status.value === reservationStatuses.RESERVED ||
			data.status.value === reservationStatuses.RESERVED_ONLINE
		)
			depositAmountOnChange({
				target: {
					value: _totals.requiredDepositAmount,
				},
			});
	};

	const onCheckAvailability = (
		_ignoreRules = false,
		_ignoreCapacity = false,
		_isWaitList = false,
		isInit = false
	) => {
		setIsSearching(true);
		setIsUnitSelectDisable(true);

		apiCall(
			'POST',
			'advancedReservationIsAvailable',
			res => {
				setIsSearching(false);

				if (!res.units) {
					setPricing({});
					addErrorNotification(res.message || 'There are no available units');
					return;
				}

				if (!_isWaitList && isWaitList) setIsWaitList(false);

				if (Object.keys(res.ratePlans).length === 0) {
					setPricing({});
					addErrorNotification(res.message || 'There are no matching rate plan');
					return;
				}

				const _availableRatePlans = res.ratePlans
					? ratePlans.filter(rp => res.ratePlans[rp.id])
					: [];

				setAvailableRatePlans(_availableRatePlans);

				if (!isInit) {
					setIsUnitSelectDisable(false);
					setIsDateChanged(false);
				}

				setPricing(res);

				onPricingChange(res, _availableRatePlans, isInit);
			},
			err => {
				addErrorNotification(err.toString().replace('Error:', ''));
				setIsSearching(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				fromDate: fromDate.toISOString(),
				toDate: toDate.toISOString(),
				ignoreRules: _ignoreRules,
				ignoreCapacity: _ignoreCapacity,
				ignoredReservationItemId: data.id,
				productId: data.product.id,
				capacity: getCapacity(data.product, quantityRef.current, loa, beam),
				loa,
				beam,
				height,
				draft,
				weight,
				sqft: calculateSqft(loa, beam),
				isWaitList: _isWaitList,
				season,
			}
		);
	};

	const onItemUpdate = itemData => {
		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});

		apiCall(
			'POST',
			'advancedReservationUpdateItem',
			res => {
				addSuccessNotification(`Booking successfully ${data.id ? 'updated' : 'added'}.`);
				onUpdate(res.reservation);
			},
			e => {
				if (e.toString().includes('override')) onOverride();
				else addErrorNotification(e.toString());
				setSubmitButtonAttr({
					text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
					icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
					color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
				});
			},
			'',
			{
				reservation,
				reservationItem: itemData,
				outletId: userContext.data.selectedOutlet.id,
				swap: swapUnits,
				isWaitList,
			}
		);
	};

	const getNonTransientProductPrice = () => {
		if (fixedPrice) return;

		if (pricing.ratePlans) {
			const prices =
				ratePlan && pricing.ratePlans[ratePlan.id]
					? pricing.ratePlans[ratePlan.id]
					: Object.values(pricing.ratePlans)[0];

			subtotalOnChange({
				target: {
					value: prices ? parseFloat(prices[0]).toFixed(2) : 0,
				},
			});

			// TODO: add period to isAvailable api
			if (data.product)
				bookingCalculationOnChange({
					target: {
						value: data.product.bookingCalculation,
					},
				});

			quantityRef.current = prices ? prices.length : 1;
		} else if (ratePlan) {
			const _data = {
				season: data.season,
				outletId: userContext.data.selectedOutlet.id,
				productId: data.product.id,
				fromDate: prevInvoiceDate ? prevInvoiceDate.toString() : fromDate.toISOString(),
				toDate: nextInvoiceDate ? nextInvoiceDate.toString() : toDate.toISOString(),
				ratePlanId: ratePlan ? ratePlan.id : null,
				loa,
				beam,
				height,
				draft,
				weight,
				sqft: calculateSqft(loa, beam),
			};

			apiCall(
				'POST',
				'advancedReservationGetNonTransientProductPrice',
				res => {
					subtotalOnChange({
						target: {
							value: parseFloat(res.price).toFixed(2),
						},
					});

					bookingCalculationOnChange({
						target: {
							value: data.product.bookingCalculation,
						},
					});
				},
				e => {
					addErrorNotification(e.toString());
				},
				'',
				_data
			);
		} else {
			subtotalOnChange({
				target: {
					value: parseFloat(data.product.price).toFixed(2),
				},
			});

			bookingCalculationOnChange({
				target: {
					value: data.product.bookingCalculation,
				},
			});
		}
	};

	const setBookingCalculationLabel = enumBookingCalculation => {
		if (!enumBookingCalculation) return enumBookingCalculation;

		if (enumBookingCalculation.value === bookingCalculations.PER_PERIOD)
			enumBookingCalculation.label = 'Year';
		else enumBookingCalculation.label = 'Month';

		return enumBookingCalculation;
	};

	useEffect(() => {
		setIsValid(
			unitValRes.isValid &&
				depositAmountValRes.isValid &&
				loaValRes.isValid &&
				beamValRes.isValid &&
				heightValRes.isValid &&
				firstInvoiceValRes.isValid &&
				nextInvoiceValRes.isValid &&
				invoicingFrequencyValRes.isValid &&
				deferredIncomeFrequencyValRes.isValid &&
				deferredIncomeSalesAccountValRes.isValid &&
				proratedFrequencyValRes.isValid &&
				coTermDateValRes.isValid &&
				invalidExtraCharges.length === 0 &&
				seasonValRes.isValid &&
				draftValRes.isValid &&
				weightValRes.isValid
		);
	}, [
		unitValRes.isValid,
		depositAmountValRes.isValid,
		loaValRes.isValid,
		beamValRes.isValid,
		draftValRes.isValid,
		heightValRes.isValid,
		weightValRes.isValid,
		invoicingFrequencyValRes.isValid,
		firstInvoiceValRes.isValid,
		nextInvoiceValRes.isValid,
		proratedFrequencyValRes.isValid,
		coTermDateValRes.isValid,
		deferredIncomeFrequencyValRes.isValid,
		deferredIncomeSalesAccountValRes.isValid,
		invalidExtraCharges,
		setIsValid,
		seasonValRes.isValid,
		invoicingFrequency,
	]);

	useEffect(() => {
		if (Object.keys(pricing).length) updateTotals();

		// remove unchecked extra charges from invalid extra charges
		setInvalidExtraCharges(
			invalidExtraCharges.filter(iec => {
				return selectedExtraCharges.findIndex(sec => sec.id === iec) > -1;
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedExtraCharges]);

	useEffect(() => {
		if (Object.keys(pricing).length) {
			if (selectedExtraCharges.length) {
				setSelectedExtraCharges(
					selectedExtraCharges.map(sec => {
						return {
							...sec,
							...calculateExtraFeeTotals(
								sec,
								{
									bookingPeriod: data.product.bookingPeriod.value,
									quantity: quantityRef.current,
									subtotal: subtotal === '' ? 0 : parseFloat(subtotal),
									loa,
									beam,
									taxCode,
									fromDate,
									product: data.product,
								},
								taxRate,
								getPrices(pricing, ratePlan),
								selectedExtraCharges,
								null,
								ignoreRules
							),
						};
					})
				);
			} else updateTotals();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subtotal, data.customerSettlements, bookingCalculation, policy, fromDate, toDate, taxCode]);

	useEffect(() => {
		data.product.productExtraCharges.forEach(p => {
			if (!p.productCategory) {
				if (!extraCharges['Other Options']) extraCharges['Other Options'] = [];

				extraCharges['Other Options'].push(p);
			}
		});

		data.product.productExtraCharges.forEach(p => {
			if (p.productCategory) {
				if (!extraCharges[p.productCategory.name])
					extraCharges[p.productCategory.name] = [];

				extraCharges[p.productCategory.name].push(p);
			}
		});

		if (data.extraCharges)
			data.extraCharges
				.filter(
					ec =>
						data.product.productExtraCharges.findIndex(
							pec => ec.extraCharge.id === pec.id
						) === -1
				)
				.forEach(ec => {
					const categoryName = ec.extraCharge.productCategory
						? ec.extraCharge.productCategory.name
						: 'Other Options';

					if (!extraCharges[categoryName]) extraCharges[categoryName] = [];

					extraCharges[categoryName].push(ec.extraCharge);
				});

		// set default extra charges.
		if (data.id === 0 && Object.entries(extraCharges).length > 0) {
			let defaultExtraCharges = [];

			Object.entries(extraCharges).forEach(exc => {
				exc[1]
					.filter(ec => !ec.optional && !ec.enabled)
					.forEach((extraCharge, index) => {
						onExtraChargeItemUpdate(
							{
								id: index * (index ? -1 : 1),
								isChecked: true,
								extraCharge,
								amount: extraCharge.price,
								quantity: 1,
								taxCode: getItemTaxCode(taxCode, {
									taxCode: extraCharge.taxCode || taxCode,
								}),
							},
							defaultExtraCharges,
							_defaultExtraCharges => {
								defaultExtraCharges = _defaultExtraCharges;
							},
							{
								bookingPeriod: data.product.bookingPeriod.value,
								quantity: quantityRef.current,
								subtotal: 0,
								taxCode,
								fromDate,
								product: data.product,
							},
							taxRate
						);
					});
			});

			setSelectedExtraCharges(defaultExtraCharges);
		}

		setExtraCharges({ ...extraCharges });

		if (data.id) {
			setIsWaitList(data.status.value === reservationStatuses.WAITLIST);

			if (
				form.isSeasonal &&
				data.status.value !== reservationStatuses.CANCELLED &&
				data.status.value !== reservationStatuses.WAITLIST
			) {
				apiCall(
					'POST',
					'advancedReservationGetSwappableUnits',
					res => {
						setSwappableUnits(res.swappableUnits);
					},
					() => {},
					'',
					{ outletId: userContext.data.selectedOutlet.id, reservationItemId: data.id }
				);
			}

			onCheckAvailability(
				true,
				true,
				data.status.value === reservationStatuses.WAITLIST,
				true
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (currentExtraChargeItemProductId && extrasRef.current)
			extrasRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [extraCharges]);

	useEffect(() => {
		if (!fixedPrice) getNonTransientProductPrice();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fixedPrice]);

	useEffect(() => {
		if (data.id === 0 && !isWaitList) {
			nextInvoiceDateOnChange({
				target: {
					value: getNextInvoiceDate(fromDate, firstInvoice, nextInvoice, coTermDate),
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromDate, firstInvoice, nextInvoice, coTermDate]);

	useEffect(() => {
		if (updateVehicleData.current) {
			if (vehicle) {
				setLoa(vehicle.loa);
				setBeam(vehicle.beam);
				setHeight(vehicle.height);
			}

			onSearchFieldChange(isWaitList);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vehicle]);

	useEffect(() => {
		if (updateVesselData.current) {
			if (vessel) {
				setLoa(vessel.loa);
				setBeam(vessel.beam);
				setDraft(vessel.draft);
				setHeight(vessel.height);
				weightOnChange({ target: { value: vessel.weight } });
			}

			onSearchFieldChange(isWaitList);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vessel]);

	useEffect(() => {
		if (data.id === 0 && !isWaitList)
			nextInvoiceDateOnChange({
				target: {
					value: getNextInvoiceDate(fromDate, firstInvoice, nextInvoice, coTermDate),
				},
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromDate, firstInvoice, nextInvoice, coTermDate]);

	// when reservation item next invoice date change update same as reservation fee's next invoice date.
	useEffect(() => {
		if (selectedExtraCharges.length) {
			setSelectedExtraCharges(
				selectedExtraCharges.map(sec => {
					if (
						sec.invoicingFrequency &&
						sec.invoicingFrequency.value === _invoicingFrequencies.SAME_AS_RESERVATION
					) {
						return {
							...sec,
							nextInvoiceDate,
							firstInvoice,
							nextInvoice,
							proratedFrequency,
							coTermDate,
							paymentTerm,
						};
					}

					return sec;
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextInvoiceDate, firstInvoice, nextInvoice, proratedFrequency, coTermDate, paymentTerm]);

	// when reservation from date change update not same as reservation fee's next invoice dates.
	useEffect(() => {
		if (data.id === 0 && !isWaitList && selectedExtraCharges.length) {
			setSelectedExtraCharges(
				selectedExtraCharges.map(sec => {
					if (
						sec.invoicingFrequency &&
						sec.invoicingFrequency.value !== _invoicingFrequencies.SAME_AS_RESERVATION
					)
						sec.nextInvoiceDate = getNextInvoiceDate(
							fromDate,
							sec.firstInvoice,
							sec.nextInvoice,
							sec.coTermDate
						);

					return sec;
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromDate]);

	useEffect(() => {
		// re-validate deferred field on enableDeferredIncome change.
		validateDeferredIncomeFrequency(deferredIncomeFrequency);
		validateDeferredIncomeSalesAccount(deferredIncomeSalesAccount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enableDeferredIncome]);

	useEffect(() => {
		validateFirstInvoice(firstInvoice);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoicingFrequency]);

	useEffect(() => {
		validateProratedFrequency(proratedFrequency);
		validateNextInvoice(nextInvoice);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstInvoice]);

	useEffect(() => {
		validateCoTermDate(coTermDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextInvoice]);

	useEffect(() => {
		// re-validate deferred field on enableDeferredIncome change.
		validateDeferredIncomeFrequency(deferredIncomeFrequency);
		validateDeferredIncomeSalesAccount(deferredIncomeSalesAccount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enableDeferredIncome]);

	useEffect(() => {
		if (isSubmitted) {
			setUnitShowVal();
			setDepositAmountShowVal();
			setLoaShowVal();
			setBeamShowVal();
			setDraftShowVal();
			setHeightShowVal();
			setWeightShowVal();
			setInvoicingFrequencyShowVal();
			setFirstInvoiceShowVal();
			setNextInvoiceShowVal();
			setProratedFrequencyShowVal();
			setCoTermDateShowVal();
			setDeferredIncomeFrequencyShowVal();
			setDeferredIncomeSalesAccountShowVal();
			setSeasonShowVal();
		}
	}, [
		isSubmitted,
		setUnitShowVal,
		setDepositAmountShowVal,
		setLoaShowVal,
		setBeamShowVal,
		setDraftShowVal,
		setHeightShowVal,
		setWeightShowVal,
		setInvoicingFrequencyShowVal,
		setFirstInvoiceShowVal,
		setNextInvoiceShowVal,
		setProratedFrequencyShowVal,
		setCoTermDateShowVal,
		setDeferredIncomeFrequencyShowVal,
		setDeferredIncomeSalesAccountShowVal,
		setSeasonShowVal,
	]);

	useEffect(() => {
		if (data.taxCode && taxCode && taxCode.id !== data.taxCode.id)
			taxCodeOnChange({ target: { value: data.taxCode } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const _Header = (
		<Portlet
			stickyTop={
				windowSize.width < mediaBreakpoint.LG
					? document.getElementById('sdms_content').offsetTop
					: 0
			}
			className='flex-shrink-0 flex-grow-0'
			ref={refHead}>
			<Portlet.Head>
				<Portlet.HeadLabel>
					<h3 className='sdms-portlet__head-title'>{`Booking ${data.reservationItemId ||
						'New'}`}</h3>
					<Portlet.Separator />
					<h3 className='sdms-portlet__head-title'>
						{data.product.name}
						<small>
							Total <b>{priceFormatter(totals.total + totals.extraTotal)}</b>
						</small>
					</h3>
					{totals.extraTotal > 0 && (
						<>
							<Portlet.Separator />
							<div className='sdms-portlet__head-desc'>
								{totals.extraTotal > 0 && (
									<>
										Extra <b>{priceFormatter(totals.extraTotal)}</b>
									</>
								)}
							</div>
						</>
					)}
				</Portlet.HeadLabel>
				{data.id !== 0 && (
					<Portlet.HeadToolbarActions actionsClassName='d-flex align-items-center'>
						<ReservationStatusCell
							data={data}
							tooltip={{
								text: cancellationReason,
								time: cancellationTime,
							}}
						/>
						<Dropdown
							icon='Other#1'
							size='sm'
							label='info'
							inline
							aligned='right'
							arrow={false}
							outline={false}
							disabled={disabled || data.id === 0 || isSearching}>
							<ReservationStatusDropdown
								status={data.status}
								onEdit={null}
								onConfirm={() =>
									onItemUpdateStatus([data.id], reservationStatuses.CONFIRMED)
								}
								onReserve={() =>
									onItemUpdateStatus([data.id], reservationStatuses.RESERVED)
								}
								onCheckIn={onCheckIn}
								onCheckOut={onCheckOut}
								onCancel={onCancel}
								onRefund={null}
								onItemUpdateCustomStatus={statusId =>
									onItemUpdateCustomStatus({
										reservationItemIds: [data.id],
										statusId,
									})
								}
								customReservationStatus={data.customReservationStatus}
								productCustomReservationStatuses={
									data.product.productCustomReservationStatuses
								}
							/>
							<Dropdown.Divider />
							<Dropdown.Item icon='Mail-box' onClick={onSendConfirmationEmail}>
								Send Confirmation Mail
							</Dropdown.Item>
							{reservation.invoices.length > 0 && (
								<Dropdown.Item
									icon='Printer'
									text='Print Invoice'
									key='printInvoice'
									onClick={() => onPrintReservationInvoice()}>
									Print Invoice
								</Dropdown.Item>
							)}
							{reservation.invoices.filter(
								i => i.status.value === invoiceStatuses.OPEN
							).length > 0 && (
								<Dropdown.Item
									icon='Mail-box'
									text='Email Invoice'
									key='emailInvoice'
									onClick={() => onSendInvoiceEmail()}>
									Email Invoice
								</Dropdown.Item>
							)}
							{onPaymentScheduleChange && (
								<Dropdown.Item icon='Wallet' onClick={onPaymentScheduleChange}>
									Payment Schedule
								</Dropdown.Item>
							)}
							{onPaymentScheduleChange && (
								<Dropdown.Item
									icon='Wallet#3'
									onClick={onCustomerPaymentMethodChange}>
									Payment Method
								</Dropdown.Item>
							)}
							{onAuthorizePayment && (
								<Dropdown.Item icon='Wallet#3' onClick={() => onAuthorizePayment()}>
									Payment Authorize
								</Dropdown.Item>
							)}
						</Dropdown>
					</Portlet.HeadToolbarActions>
				)}
			</Portlet.Head>
		</Portlet>
	);

	return (
		<>
			<InnerPage>
				<InnerPage.NavWrapper
					offCanvasStatus={innerPageNavStatus}
					setOffCanvasStatus={setInnerPageNavStatus}
					offCanvasTitle={
						reservation.reservationId
							? `Reservation ${reservation.reservationId}`
							: 'New Reservation'
					}>
					{nonTransientAdvancedFormPages.map((step, index) => (
						<InnerPage.NavItem
							key={step.title}
							onClick={() => {
								setActiveStep(index);
								setInnerPageNavStatus(false);
							}}
							icon={step.icon}
							title={step.title}
							description={step.description}
							isActive={index === activeStep}
							isDisabled={
								index > 0 &&
								(data.id === 0 ||
									data.status.value === reservationStatuses.WAITLIST)
							}
							isLoading={isSearching}
						/>
					))}
				</InnerPage.NavWrapper>
				<InnerPage.Wrapper>
					{// General
					activeStep === 0 && (
						<InnerPage.Item>
							{_Header}
							<Portlet
								isCollapse
								isOpen={isReservationSectionOpen}
								hasFrame
								className='flex-grow-0 sdms-min-h-fit-content'>
								<Portlet.Head>
									<Portlet.HeadLabel>
										<h3 className='sdms-portlet__head-title'>Reservation</h3>
									</Portlet.HeadLabel>
									<Portlet.HeadToolbarActions>
										<SVGIcon
											name='Angle-right'
											className={classNames(
												'sdms-transition',
												'sdms-cursor--pointer',
												{
													'sdms-rotate--90': isReservationSectionOpen,
												}
											)}
											onClick={() =>
												setIsReservationSectionOpen(
													!isReservationSectionOpen
												)
											}
										/>
									</Portlet.HeadToolbarActions>
								</Portlet.Head>
								<Portlet.Body>
									<Section
										title={
											<>
												<span className='sdms-mr-15'>
													{form.isCampground ? 'Vehicle' : 'Vessel'}
												</span>
												<Button
													label='info'
													icon={form.isCampground ? 'Caravan' : 'Ship'}
													size='sm'
													pill
													onClick={() =>
														openModal({
															open: form.isCampground
																? modals.VEHICLE_FORM
																: modals.VESSEL_FORM,
														})
													}
													disabled={areSearchFieldsDisabled()}>
													Add New{' '}
													{form.isCampground ? 'Vehicle' : 'Vessel'}
												</Button>
											</>
										}>
										<Section.Body>
											<FormGroup isLast>
												<FormField
													label={form.isCampground ? 'Vehicle' : 'Vessel'}
													name={form.isCampground ? 'vehicle' : 'vessel'}
													id={data.id}
													colMd={4}>
													<Button
														className='h-100 sdms-fitText'
														label='dark'
														icon={
															form.isCampground ? 'Caravan' : 'Marina'
														}
														block
														onClick={() =>
															openModal({
																open: form.isCampground
																	? modals.VEHICLE_SELECT
																	: modals.VESSEL_SELECT,
															})
														}
														disabled={areSearchFieldsDisabled()}>
														{getSelectVehicleVesselButtonText()}
													</Button>
												</FormField>
												<FormField
													name='loa'
													label='Length Overall'
													id={data.id}
													colMd={4}
													showValidation={loaShowVal}
													valRes={loaValRes}>
													<LengthInputGroup
														ft={loaFt}
														ftOnChange={setLoaFt}
														inch={loaIn}
														inchOnChange={setLoaIn}
														placeHolder='Length Overall'
														onBlur={setLoaShowVal}
														onAnyChange={() =>
															onSearchFieldChange(isWaitList)
														}
														disabled={
															areVesselVehicleFieldsDisabled() ||
															areSearchFieldsDisabled()
														}
													/>
												</FormField>
												<FormField
													name='beam'
													label='Beam'
													id={data.id}
													colMd={4}
													showValidation={beamShowVal}
													valRes={beamValRes}>
													<LengthInputGroup
														ft={beamFt}
														ftOnChange={setBeamFt}
														inch={beamIn}
														inchOnChange={setBeamIn}
														placeHolder='Beam'
														onBlur={setBeamShowVal}
														onAnyChange={() =>
															onSearchFieldChange(isWaitList)
														}
														disabled={
															areVesselVehicleFieldsDisabled() ||
															areSearchFieldsDisabled()
														}
													/>
												</FormField>
												{form.isMarina && (
													<FormField
														name='draft'
														label='Draft'
														id={data.id}
														colMd={4}
														showValidation={draftShowVal}
														valRes={draftValRes}>
														<LengthInputGroup
															ft={draftFt}
															ftOnChange={setDraftFt}
															inch={draftIn}
															inchOnChange={setDraftIn}
															placeHolder='Draft'
															onBlur={setDraftShowVal}
															onAnyChange={() =>
																onSearchFieldChange(isWaitList)
															}
															disabled={
																areVesselVehicleFieldsDisabled() ||
																areSearchFieldsDisabled()
															}
														/>
													</FormField>
												)}
												<FormField
													name='height'
													label='Height'
													id={data.id}
													colMd={4}
													showValidation={heightShowVal}
													valRes={heightValRes}>
													<LengthInputGroup
														ft={heightFt}
														ftOnChange={setHeightFt}
														inch={heightIn}
														inchOnChange={setHeightIn}
														placeHolder='Height'
														onBlur={setHeightShowVal}
														onAnyChange={() =>
															onSearchFieldChange(isWaitList)
														}
														disabled={
															areVesselVehicleFieldsDisabled() ||
															areSearchFieldsDisabled()
														}
													/>
												</FormField>
												{form.isMarina && (
													<FormField
														name='weight'
														label='Weight'
														id={data.id}
														colMd={4}
														showValidation={weightShowVal}
														valRes={weightValRes}>
														<Input
															type='number'
															withOutSpin
															min={0}
															placeholder='Weight'
															value={weight || ''}
															onChange={e => {
																onSearchFieldChange(isWaitList);
																weightOnChange(e);
															}}
															pattern={
																process.env
																	.REACT_APP_INTEGER_PATTERN
															}
															append='lbs'
															onBlur={setWeightShowVal}
															disabled={
																areVesselVehicleFieldsDisabled() ||
																areSearchFieldsDisabled()
															}
														/>
													</FormField>
												)}
											</FormGroup>
										</Section.Body>
									</Section>
									<Portlet border isLast>
										<Portlet.Head>
											{form.isLongTerm && (
												<Portlet.HeadLabelTitle>
													Search
												</Portlet.HeadLabelTitle>
											)}
											{form.isSeasonal && (
												<Portlet.HeadLabel>
													<h3 className='sdms-portlet__head-title'>
														Season
													</h3>
													<Portlet.Separator />
													<FormField
														name='season'
														className='sdms-mw-250'
														inFormDesign={false}
														id={data.id}
														colMd={3}
														isLast
														showValidation={seasonShowVal}
														valRes={seasonValRes}>
														<Selects
															onChange={e => {
																seasonOnChange(e);
																onSearchFieldChange(isWaitList);
																if (e.target.value) {
																	setFromDate(
																		moment(
																			e.target.value.startDate
																		)
																			.utc(false)
																			.startOf('day')
																	);
																	setToDate(
																		moment(
																			e.target.value.endDate
																		)
																			.utc(false)
																			.endOf('day')
																	);
																} else {
																	setFromDate(null);
																	setToDate(null);
																}
															}}
															options={seasons}
															value={season}
															onBlur={setSeasonShowVal}
															placeholder='Select a Season (Required)'
															disabled={areSearchFieldsDisabled()}
														/>
													</FormField>
												</Portlet.HeadLabel>
											)}
										</Portlet.Head>
										<Portlet.Body>
											<Section>
												<Section.Body>
													<FormGroup isLast>
														<FormField
															name='fromDate'
															label='Start date'
															id={data.id}
															colMd={3}>
															<DatePicker
																id='fromDate'
																type='calendar'
																value={parseDatePickerValue(
																	fromDate
																)}
																onChange={e => {
																	const _fromDate = moment(
																		fromDate
																	)
																		.year(
																			moment(
																				e.target.value
																			).year()
																		)
																		.month(
																			moment(
																				e.target.value
																			).month()
																		)
																		.date(
																			moment(
																				e.target.value
																			).date()
																		);
																	setFromDate(_fromDate);
																	onFromDateChanged(_fromDate);
																	onSearchFieldChange(isWaitList);
																	onFormChange();
																}}
																disabled={
																	areSearchFieldsDisabled() ||
																	(form.isSeasonal &&
																		(!hasEditSeasonDatesPermission(
																			userContext
																		) ||
																			!season))
																}
																minDate={
																	dateConstraints.minFromDate
																}
																maxDate={
																	dateConstraints.maxFromDate
																}
																disableClear
															/>
														</FormField>
														<FormField
															ref={refForTimePicker}
															name='fromDate'
															label='Start time'
															id={data.id}
															colMd={3}>
															<TimePickerInput
																showSecond={false}
																value={fromDate}
																defaultValue={moment()}
																onChange={target => {
																	const _fromDate = moment(
																		fromDate || undefined
																	)
																		.hour(target.hour())
																		.minute(target.minute());

																	setFromDate(_fromDate);
																	onSearchFieldChange(isWaitList);
																	onFormChange();
																}}
																size={sizeTimePicker}
																use12Hours
																minuteStep={timePickerStep.current}
																disabled={
																	isSearching ||
																	disabled ||
																	!hasEditReservationDatesPermission(
																		userContext
																	) ||
																	(data.spaceAssignments &&
																		data.spaceAssignments
																			.length > 0) ||
																	(form.isSeasonal &&
																		(!hasEditSeasonDatesPermission(
																			userContext
																		) ||
																			!season))
																}
															/>
														</FormField>
														<FormField
															name='toDate'
															label='End date'
															id={data.id}
															colMd={3}>
															<DatePicker
																id='toDate'
																type='calendar'
																value={
																	form.isSeasonal ||
																	data.status.value ===
																		reservationStatuses.CHECKED_OUT
																		? convertDateToUTC(
																				toDate.toDate()
																		  )
																		: null
																}
																onChange={e => {
																	setToDate(
																		moment(toDate)
																			.year(
																				moment(
																					e.target.value
																				).year()
																			)
																			.month(
																				moment(
																					e.target.value
																				).month()
																			)
																			.date(
																				moment(
																					e.target.value
																				).date()
																			)
																	);
																	onSearchFieldChange(isWaitList);
																	onFormChange();
																}}
																disabled={
																	areSearchFieldsDisabled() ||
																	form.isLongTerm ||
																	(form.isSeasonal &&
																		(!hasEditSeasonDatesPermission(
																			userContext
																		) ||
																			!season))
																}
																minDate={dateConstraints.minToDate}
																maxDate={dateConstraints.maxToDate}
																disableClear
															/>
														</FormField>
														<FormField
															name='toDate'
															label='End time'
															id={data.id}
															colMd={3}>
															<TimePickerInput
																showSecond={false}
																defaultValue={moment()}
																value={
																	form.isSeasonal ||
																	data.status.value ===
																		reservationStatuses.CHECKED_OUT
																		? toDate
																		: null
																}
																onChange={target => {
																	const _toDate = moment(
																		toDate || undefined
																	)
																		.hour(target.hour())
																		.minute(target.minute());

																	setToDate(_toDate);
																	onSearchFieldChange(isWaitList);
																	onFormChange();
																}}
																size={sizeTimePicker}
																use12Hours
																disabled={
																	areSearchFieldsDisabled() ||
																	form.isLongTerm ||
																	(form.isSeasonal &&
																		(!hasEditSeasonDatesPermission(
																			userContext
																		) ||
																			!season))
																}
															/>
														</FormField>
														<FormField
															name='ignoreRules'
															label='No rules'
															id={data.id}
															colMd={1}>
															<Toggle
																value={ignoreRules}
																onChange={e => {
																	setIgnoreRules(e.target.value);
																	if (isWaitList)
																		onCheckAvailability(
																			e.target.value,
																			ignoreCapacity,
																			isWaitList
																		);

																	onSearchFieldChange(false);
																}}
																noPermission={
																	!hasOverrideConstraintPermission(
																		userContext
																	)
																}
																disabled={areSearchFieldsDisabled()}
															/>
														</FormField>
														<WaitListIgnoreCapacityToggle
															onIgnoreCapacityChange={
																setIgnoreCapacity
															}
															ignoreCapacity={ignoreCapacity}
															isWaitList={isWaitList}
															onIsWaitListChange={v => {
																setIsWaitList(v);
																if (v)
																	onCheckAvailability(
																		ignoreRules,
																		ignoreCapacity,
																		true
																	);
																else {
																	setPricing({});
																	onSearchFieldChange(v);
																}

																nextInvoiceDateOnChange({
																	target: {
																		value: v
																			? null
																			: getNextInvoiceDate(
																					fromDate,
																					firstInvoice,
																					nextInvoice,
																					coTermDate
																			  ),
																	},
																});
															}}
															col={2}
															disabled={isSearching || disabled}
															disableWaitList={
																data.id !== 0 &&
																data.status.value !==
																	reservationStatuses.WAITLIST
															}
															disableIgnoreCapacity={
																data.product.bookingType.bookBy
																	.value === bookBies.UNIT
															}
														/>
														<FormField
															name='searchAvailability'
															label='Search Availability'
															id={data.id}
															colMd={3}>
															<Button
																label='brand'
																text='Search Availability'
																icon='Search'
																size='sm'
																block
																disabled={areSearchFieldsDisabled()}
																isSubmitting={isSearching}
																onClick={() => {
																	setLoaShowVal();
																	setBeamShowVal();
																	setHeightShowVal();
																	setDraftShowVal();
																	setWeightShowVal();
																	setSeasonShowVal();

																	if (
																		!loaValRes.isValid ||
																		!beamValRes.isValid ||
																		!heightValRes.isValid ||
																		!draftValRes.isValid ||
																		!weightValRes.isValid ||
																		!seasonValRes.isValid
																	) {
																		addErrorNotification(
																			'Please fill all fields'
																		);
																		return;
																	}

																	onCheckAvailability(
																		ignoreRules,
																		ignoreCapacity
																	);
																}}
															/>
														</FormField>
														<FormField
															name='unit'
															label='Unit'
															id={data.id}
															valRes={unitValRes}
															showValidation={unitShowVal}
															colMd={3}
															noPermission={
																!hasEditReservationUnitPermission(
																	userContext
																)
															}>
															<Selects
																options={availableUnits}
																placeholder='Auto Assign (Required)'
																value={
																	swapUnits
																		? unitBackup.current
																		: getCurrentUnit(data, unit)
																}
																onChange={unitOnChange}
																onBlur={setUnitShowVal}
																disabled={
																	isUnitSelectDisable ||
																	areSearchFieldsDisabled() ||
																	swapUnits
																}
															/>
														</FormField>
														{form.isSeasonal && (
															<FormField
																name='swapUnits'
																label={
																	swapUnits ? (
																		<>
																			Swap
																			<Badge
																				className='sdms-cursor--pointer sdms-ml-10'
																				design='warning'
																				isInline
																				isElevate
																				onMouseDown={() => {
																					if (
																						unitBackup.current
																					)
																						unitOnChange(
																							{
																								target: {
																									value:
																										unitBackup.current,
																								},
																							}
																						);
																					setSwapUnits(
																						false
																					);
																				}}>
																				Use current unit
																			</Badge>
																		</>
																	) : (
																		'Swap'
																	)
																}
																id={data.id}
																description={
																	swapUnits
																		? 'Select unit for swap'
																		: ''
																}
																colMd={3}
																noPermission={
																	!hasSwapReservationUnitPermission(
																		userContext
																	)
																}>
																{swapUnits ? (
																	<Selects
																		options={swappableUnits}
																		placeholder='Auto Assign'
																		value={unit}
																		onChange={unitOnChange}
																		onBlur={setUnitShowVal}
																		disabled={
																			isUnitSelectDisable ||
																			disabled
																		}
																	/>
																) : (
																	<Button
																		label='brand'
																		text='Swap Units'
																		icon='Route'
																		block
																		size='sm'
																		onClick={() => {
																			if (!isDateChanged) {
																				unitBackup.current = unit;
																				setSwapUnits(true);
																				setIsUnitSelectDisable(
																					false
																				);
																			}
																		}}
																		disabled={
																			isSearching ||
																			disabled ||
																			swappableUnits.filter(
																				u =>
																					unit &&
																					unit.id !== u.id
																			).length === 0 ||
																			(data.spaceAssignments &&
																				data
																					.spaceAssignments
																					.length > 0)
																		}
																	/>
																)}
															</FormField>
														)}
													</FormGroup>
												</Section.Body>
											</Section>
										</Portlet.Body>
									</Portlet>
								</Portlet.Body>
							</Portlet>
							<Portlet hasFrame className='flex-grow-0 sdms-min-h-fit-content'>
								<Portlet.Body>
									<div className='row'>
										<div className='col-md-6'>
											<Section title='Plans'>
												<Section.Body>
													<FormGroup>
														<FormField
															name='ratePlan'
															label='Rate Plan'
															id={data.id}
															colMd={12}
															description={
																data.id
																	? 'Search Availability to update'
																	: ''
															}>
															<Selects
																options={availableRatePlans}
																placeholder='Rate plan'
																value={ratePlan}
																onChange={e => {
																	ratePlanOnChange(e);
																	policyOnChange({
																		target: {
																			value: getPolicy(
																				pricing,
																				e.target.value,
																				data.product,
																				policies
																			),
																		},
																	});
																	subtotalOnChange({
																		target: {
																			value: numberFormat(
																				pricing.ratePlans[
																					e.target.value
																						.id
																				].reduce(
																					(a, b) => a + b
																				)
																			),
																		},
																	});
																}}
																displayKey='internalName'
																disabled={areFieldsDisabled()}
																disableClearable
																noPermission={
																	!hasOverrideConstraintPermission(
																		userContext
																	)
																}
															/>
														</FormField>
														<FormField
															name='policy'
															label='Cancellation Policy'
															id={data.id}
															colMd={12}
															noPermission={
																!hasEditReservationCancellationPolicyPermission(
																	userContext
																)
															}>
															<Selects
																options={policies}
																placeholder='Cancellation Policy'
																value={policy}
																onChange={policyOnChange}
																disabled={areFieldsDisabled()}
															/>
														</FormField>
														<FormField
															name='depositAmount'
															label='Deposit Required'
															id={data.id}
															valRes={depositAmountValRes}
															showValidation={depositAmountShowVal}
															colMd={12}>
															<Input
																type='text'
																placeholder='Deposit amount'
																value={depositAmount}
																onChange={depositAmountOnChange}
																onBlur={setDepositAmountShowVal}
																disabled={areFieldsDisabled()}
																pattern={
																	process.env
																		.REACT_APP_PRICE_PATTERN
																}
															/>
														</FormField>
														<FormField
															name='productContracts'
															label='Product Contracts'
															id={data.id}
															colMd={12}
															noPermission={
																!hasEditReservationProductContractsPermission(
																	userContext
																)
															}>
															<Selects
																options={contracts}
																placeholder='Product Contracts'
																value={productContracts}
																onChange={productContractsOnChange}
																disabled={areFieldsDisabled()}
																multiple
															/>
														</FormField>
													</FormGroup>
												</Section.Body>
											</Section>
										</div>
										<div className='col-md-6'>
											<Alert
												outline
												title='Pricing'
												design='dark'
												marginLess={false}
												className={classNames({
													'sdms-disable': areFieldsDisabled(),
												})}>
												<div className='row align-items-baseline sdms-fitText--md sdms-mb-5'>
													<div className='col-12 text-right'>
														<Radio.Container isInline>
															<Radio
																checked={!fixedPrice}
																id='fixedPriceNo'
																name='fixedPriceInput'
																content='Product Pricing'
																className='sdms-radio--primary'
																onChange={() =>
																	fixedPriceOnChange({
																		target: {
																			value: false,
																		},
																	})
																}
															/>
															<Radio
																checked={fixedPrice || false}
																id='fixedPriceYes'
																name='fixedPriceInput'
																content='Fixed Price'
																className='sdms-radio--primary'
																onChange={() =>
																	fixedPriceOnChange({
																		target: {
																			value: true,
																		},
																	})
																}
															/>
														</Radio.Container>
													</div>
													<div className='col-4'>Rate</div>
													<div className='col-8 text-right'>
														<InputGroup>
															<InputPend
																icon='Dollar'
																iconColor='var(--dark)'
															/>
															<Input
																className='text-right sdms-fitText--lg sdms-font-inherit'
																type='text'
																placeholder='Subtotal'
																value={
																	subtotal
																		? subtotal.toString()
																		: ''
																}
																pattern={
																	process.env
																		.REACT_APP_PRICE_PATTERN
																}
																onChange={subtotalOnChange}
																disabled={
																	areFieldsDisabled() ||
																	!fixedPrice
																}
																noPermission={
																	!hasEditReservationPricePermission(
																		userContext
																	)
																}
															/>
															{form.isLongTerm && (
																<div className='input-group-append'>
																	<Selects
																		options={enumBookingCalculations
																			.filter(
																				ebc =>
																					ebc.value ===
																						bookingCalculations.PER_MONTH ||
																					ebc.value ===
																						bookingCalculations.PER_PERIOD
																			)
																			.map(
																				setBookingCalculationLabel
																			)}
																		name='bookingCalculation'
																		value={setBookingCalculationLabel(
																			bookingCalculation
																		)}
																		onChange={
																			bookingCalculationOnChange
																		}
																		displayKey='label'
																		disabled={
																			areFieldsDisabled() ||
																			!fixedPrice
																		}
																	/>
																</div>
															)}
															{form.isSeasonal && (
																<div className='input-group-append'>
																	<Selects
																		options={[
																			{
																				name: 'Season',
																				id: 1,
																			},
																		]}
																		name='priceType'
																		value={{
																			id: 1,
																			name: 'Season',
																		}}
																		onChange={() => {}}
																		disabled
																	/>
																</div>
															)}
														</InputGroup>
													</div>
												</div>
												<div className='row sdms-fitText--md sdms-mb-10'>
													<div className='col'>Extras</div>
													<div className='col-auto text-right'>
														<span className='sdms-mr-15'>
															{priceFormatter(totals.extraSubtotal)}
														</span>
													</div>
												</div>
												<div className='row sdms-fitText--md sdms-mb-10'>
													<div className='col'>Tax</div>
													<div className='col-auto text-right'>
														<span className='sdms-mr-15'>
															{priceFormatter(
																totals.tax + totals.extraTax
															)}
														</span>
													</div>
												</div>
												<Separator space='sm' />
												<div className='row sdms-fitText--lg sdms-font-bolder'>
													<div className='col'>Total</div>
													<div className='col-auto text-right'>
														<span className='sdms-mr-15'>
															{priceFormatter(
																totals.total + totals.extraTotal
															)}
														</span>
													</div>
												</div>
												<div className='sdms-mb-20' />
												<div className='row sdms-fitText--md sdms-mb-10'>
													<div className='col'>Deposit Required</div>
													<div className='col-auto text-right'>
														<span className='sdms-mr-15'>
															{priceFormatter(depositAmount)}
														</span>
													</div>
												</div>
												<Separator space='sm' />
												<div className='row sdms-fitText--md sdms-mb-10'>
													<div className='col'>Amount Paid</div>
													<div className='col-auto text-right'>
														<span className='sdms-mr-15'>
															{priceFormatter(totals.amountPaid)}
														</span>
													</div>
												</div>
												<div className='sdms-mb-20' />
												<div className='row sdms-fitText--lg sdms-font-bolder'>
													<div className='col'>Balance</div>
													<div className='col-auto text-right'>
														<span className='sdms-mr-15'>
															{priceFormatter(totals.balanceDue)}
														</span>
													</div>
												</div>
											</Alert>
										</div>
									</div>
									{Object.keys(extraCharges).length > 0 && (
										<Section title='Price Adjustments'>
											<Section.Body>
												<FormGroup>
													<div
														className='col-12'
														ref={extrasRef}
														style={{
															scrollMarginTop: sizeHead.height,
														}}>
														{Object.entries(extraCharges).map(exc => {
															return (
																<Portlet
																	noPermission={
																		!hasEditReservationFeesPermission(
																			userContext
																		)
																	}
																	key={`fragment${exc[0]}`}
																	border
																	className={classNames(
																		'sdms-list-layout',
																		{
																			[`sdms-portlet__table--wrap-${listMediaBreakpoint}`]: listMediaBreakpoint,
																		}
																	)}>
																	<Portlet.Head>
																		<Portlet.HeadLabelTitle>
																			{exc[0]}
																		</Portlet.HeadLabelTitle>
																	</Portlet.Head>
																	<Portlet.Head
																		className={classNames(
																			'sdms-list-layout__head',
																			{
																				[`table--responsive--${responsive}`]: responsive,
																			}
																		)}>
																		<Portlet.HeadToolbar>
																			<table className='table'>
																				<ExtraFeeHead
																					settingsEnable
																				/>
																			</table>
																		</Portlet.HeadToolbar>
																	</Portlet.Head>
																	<Portlet.Body
																		className={classNames({
																			[`table--responsive--${responsive}`]: responsive,
																		})}>
																		<table
																			className={classNames(
																				'table',
																				`table--responsive-col--6`
																			)}>
																			<ExtraFeeHead />
																			<tbody>
																				{exc[1].map(
																					extraCharge => {
																						const extraChargeItem = selectedExtraCharges.find(
																							sec =>
																								sec
																									.extraCharge
																									.id ===
																								extraCharge.id
																						);

																						return (
																							<ExtraFeeRow
																								key={
																									extraCharge.id
																								}
																								data={
																									extraChargeItem
																								}
																								extraCharge={{
																									...extraCharge,
																									taxCode: getItemTaxCode(
																										taxCode,
																										{
																											taxCode:
																												extraCharge.taxCode ||
																												taxCode,
																										}
																									),
																								}}
																								onUpdate={_extraChargeItem => {
																									// if removed extra charge item's settings modal is open close it.
																									if (
																										!_extraChargeItem.isChecked &&
																										modal.open ===
																											modals.EXTRA_FEE_SETTINGS &&
																										modal.id ===
																											_extraChargeItem.id
																									)
																										closeModal();

																									if (
																										Object.keys(
																											pricing
																										)
																											.length
																									)
																										onExtraChargeItemUpdate(
																											_extraChargeItem,
																											selectedExtraCharges,
																											setSelectedExtraCharges,
																											{
																												bookingPeriod:
																													data
																														.product
																														.bookingPeriod
																														.value,
																												quantity:
																													quantityRef.current,
																												subtotal:
																													subtotal ===
																													''
																														? 0
																														: parseFloat(
																																subtotal
																														  ),
																												loa,
																												beam,
																												fromDate,
																												invoicingFrequency,
																												paymentTerm,
																												firstInvoice,
																												nextInvoice,
																												proratedFrequency,
																												coTermDate,
																												nextInvoiceDate,
																												product:
																													data.product,
																											},
																											taxRate,
																											getPrices(
																												pricing,
																												ratePlan
																											),
																											ignoreRules
																										);
																								}}
																								onFormChange={
																									onFormChange
																								}
																								disabled={
																									disabled ||
																									areFieldsDisabled()
																								}
																								currentExtraChargeItemProductId={
																									currentExtraChargeItemProductId
																								}
																								customer={
																									reservation.customer
																								}
																								onSettingsClick={() =>
																									openModal(
																										{
																											open:
																												modals.EXTRA_FEE_SETTINGS,
																											id:
																												extraChargeItem.id,
																										}
																									)
																								}
																								isValid={
																									extraChargeItem &&
																									invalidExtraCharges.indexOf(
																										extraChargeItem.id
																									) ===
																										-1
																								}
																							/>
																						);
																					}
																				)}
																			</tbody>
																		</table>
																	</Portlet.Body>
																</Portlet>
															);
														})}
													</div>
												</FormGroup>
											</Section.Body>
										</Section>
									)}
									<Section>
										<Section.Body>
											<FormGroup>
												<FormField
													name='note'
													label='Special Requests'
													id={data.id}
													colMd={12}
													noPermission={
														!hasEditReservationSpecialRequestsPermission(
															userContext
														)
													}>
													<Textarea
														onChange={noteOnChange}
														value={note}
														disabled={!unit || isSearching}
													/>
												</FormField>
												<FormField
													name='internalNote'
													label='Internal Notes'
													id={data.id}
													colMd={12}>
													<Textarea
														onChange={internalNoteOnChange}
														value={internalNote}
														disabled={!unit || disabled || isSearching}
													/>
												</FormField>
												<FormField
													name='externalNote'
													label='External Notes'
													id={data.id}
													colMd={12}
													noPermission={
														!hasReservationEditExternalNotesPermission(
															userContext
														)
													}>
													<Textarea
														onChange={externalNoteOnChange}
														value={externalNote}
														disabled={!unit || disabled || isSearching}
													/>
												</FormField>
											</FormGroup>
										</Section.Body>
									</Section>
									<Section title='Invoice Settings'>
										<Section.Body>
											<FormGroup>
												<FormField
													name='invoiceGenerationAutomatedBatch'
													label='Invoice Generation'
													id={data.id}
													inFormDesign={false}
													colMd={3}>
													<Radio.Container isInline>
														<Radio
															checked={
																invoiceGenerationAutomatedBatch
															}
															id='invoiceGenerationAutomatedBatchYes'
															name='invoiceGenerationAutomatedBatch'
															content='Automatic'
															className='sdms-radio--primary'
															onChange={() =>
																invoiceGenerationAutomatedBatchOnChange(
																	{
																		target: {
																			value: true,
																		},
																	}
																)
															}
															disabled={areFieldsDisabled()}
														/>
														<Radio
															checked={
																!invoiceGenerationAutomatedBatch
															}
															id='invoiceGenerationAutomatedBatchNo'
															name='invoiceGenerationAutomatedBatch'
															content='Manual'
															className='sdms-radio--primary'
															onChange={() =>
																invoiceGenerationAutomatedBatchOnChange(
																	{
																		target: {
																			value: false,
																		},
																	}
																)
															}
															disabled={areFieldsDisabled()}
														/>
													</Radio.Container>
												</FormField>
												<FormField
													name='invoicingFrequency'
													label='Frequency'
													id={data.id}
													colMd={3}
													showValidation={invoicingFrequencyShowVal}
													valRes={invoicingFrequencyValRes}>
													<Selects
														options={filterInvoicingFrequency(
															invoicingFrequencies,
															form.bookingPeriod,
															true,
															null,
															true
														)}
														placeholder='Frequency'
														value={invoicingFrequency}
														onChange={e => {
															invoicingFrequencyOnChange(e);

															nextInvoiceOnChange({
																target: {
																	value: getNextInvoice(
																		e.target.value,
																		firstInvoice,
																		invoiceNextOptions
																	),
																},
															});

															if (
																form.isSeasonal &&
																(!e.target.value ||
																	e.target.value.value ===
																		_invoicingFrequencies.UPFRONT)
															) {
																firstInvoiceOnChange({
																	target: {
																		value: null,
																	},
																});

																proratedFrequencyOnChange({
																	target: {
																		value: null,
																	},
																});

																coTermDateOnChange({
																	target: {
																		value: null,
																	},
																});

																autoPayOnChange({
																	target: {
																		value: false,
																	},
																});
															}

															// check deferred income frequency against to invoicing frequency.
															if (
																e.target.value &&
																deferredIncomeFrequency &&
																isInvoicingFrequencyBigger(
																	deferredIncomeFrequency.value,
																	e.target.value.value
																)
															)
																deferredIncomeFrequencyOnChange({
																	target: { value: null },
																});
														}}
														disabled={areFieldsDisabled()}
														displayKey='value'
														onBlur={setInvoicingFrequencyShowVal}
													/>
												</FormField>
												<FormField
													name='paymentTerm'
													label='Payment Term'
													id={data.id}
													colMd={3}>
													<Selects
														options={paymentTerms}
														placeholder='Payment Term'
														value={paymentTerm}
														onChange={paymentTermOnChange}
														disabled={areFieldsDisabled()}
													/>
												</FormField>
												<FormField
													name='autoPay'
													label='Auto Pay'
													id={data.id}
													colMd={3}>
													<Toggle
														value={autoPay}
														onChange={autoPayOnChange}
														disabled={
															areFieldsDisabled() ||
															(form.isSeasonal &&
																invoicingFrequency &&
																invoicingFrequency.value ===
																	_invoicingFrequencies.UPFRONT)
														}
													/>
												</FormField>
												<FormField
													name='firstInvoice'
													label='First Invoice'
													id={data.id}
													colMd={3}
													showValidation={firstInvoiceShowVal}
													valRes={firstInvoiceValRes}>
													<Selects
														options={invoiceFirstOptions}
														placeholder={`First Invoice${
															firstInvoiceValRes.isValid
																? ''
																: ' (Required)'
														} `}
														value={firstInvoice}
														onChange={e => {
															firstInvoiceOnChange(e);

															nextInvoiceOnChange({
																target: {
																	value: getNextInvoice(
																		invoicingFrequency,
																		e.target.value,
																		invoiceNextOptions
																	),
																},
															});

															proratedFrequencyOnChange({
																target: { value: null },
															});

															coTermDateOnChange({
																target: { value: null },
															});
														}}
														displayKey='value'
														disabled={
															areFieldsDisabled() ||
															(form.isLongTerm
																? data.prevInvoiceDate
																: !invoicingFrequency ||
																  invoicingFrequency.value ===
																		_invoicingFrequencies.UPFRONT ||
																  data.prevInvoiceDate)
														}
														onBlur={setFirstInvoiceShowVal}
														disableClearable
													/>
												</FormField>
												<FormField
													name='nextInvoice'
													label='Next Invoice'
													id={data.id}
													colMd={3}
													showValidation={nextInvoiceShowVal}
													valRes={nextInvoiceValRes}>
													<Selects
														placeholder={`Next Invoice${
															nextInvoiceValRes.isValid
																? ''
																: ' (Required)'
														} `}
														value={nextInvoice}
														options={getNextInvoiceOptions(
															form.bookingPeriod,
															invoicingFrequency,
															firstInvoice,
															invoiceNextOptions
														)}
														onChange={e => {
															nextInvoiceOnChange(e);

															if (
																!e.target.value ||
																e.target.value.value !==
																	nextInvoiceOptions.CO_TERM
															) {
																coTermDateOnChange({
																	target: { value: null },
																});
															}
														}}
														displayKey='value'
														disabled={
															areFieldsDisabled() ||
															data.prevInvoiceDate ||
															(form.isLongTerm && !firstInvoice)
														}
														onBlur={setNextInvoiceShowVal}
													/>
												</FormField>
												<FormField
													name='proratedFrequency'
													label='Prorated Frequency'
													id={data.id}
													colMd={3}
													showValidation={proratedFrequencyShowVal}
													valRes={proratedFrequencyValRes}>
													<Selects
														placeholder={`Prorated Frequency${
															proratedFrequencyValRes.isValid
																? ''
																: ' (Required)'
														}`}
														value={proratedFrequency}
														options={filterInvoicingFrequency(
															invoicingFrequencies,
															bookingPeriods.LONG_TERM,
															false,
															invoicingFrequency
																? invoicingFrequency.value
																: null
														)}
														onChange={proratedFrequencyOnChange}
														displayKey='value'
														disabled={
															areFieldsDisabled() ||
															firstInvoice?.value !==
																firstInvoiceOptions.PRO_RATE ||
															data.prevInvoiceDate
														}
														onBlur={setProratedFrequencyShowVal}
													/>
												</FormField>
												<FormField
													name='coTermDate'
													label='Co-Term Date'
													id={data.id}
													colMd={3}
													showValidation={coTermDateShowVal}
													valRes={coTermDateValRes}>
													<DatePicker
														id='coTermDate'
														type='calendar'
														placeholder={`Co-Term Date${
															coTermDateValRes.isValid
																? ''
																: ' (Required)'
														}`}
														place='top'
														value={parseDatePickerValue(coTermDate)}
														onChange={e =>
															coTermDateOnChange({
																target: {
																	value: parseDatePickerChange(
																		e.target.value,
																		coTermDate
																	),
																},
															})
														}
														disabled={
															areFieldsDisabled() ||
															nextInvoice?.value !==
																nextInvoiceOptions.CO_TERM ||
															data.prevInvoiceDate
														}
														formatter={dateFormatterWithoutYear}
														onBlur={setCoTermDateShowVal}
													/>
												</FormField>
												<FormField
													name='prevInvoiceDate'
													label='Prev Invoice Date'
													id={data.id}
													colMd={3}>
													<DatePicker
														disabled={
															areFieldsDisabled() ||
															userContext.data.selectedOutlet.settings
																.preventEditPrevInvoiceDate !==
																false
														}
														id='prevInvoiceDate'
														type='calendar'
														place='top'
														placeholder='Prev Invoice Date'
														value={parseDatePickerValue(
															prevInvoiceDate
														)}
														onChange={e =>
															prevInvoiceDateOnChange({
																target: {
																	value: parseDatePickerChange(
																		e.target.value,
																		prevInvoiceDate
																	),
																},
															})
														}
													/>
												</FormField>
												<FormField
													name='nextInvoiceDate'
													label='Next Invoice Date'
													id={data.id}
													colMd={3}>
													<DatePicker
														disabled={isNextInvoiceDateDisable()}
														id='nextInvoiceDate'
														type='calendar'
														placeholder='Next Invoice Date'
														minDate={getNextInvoiceDateMin(data)}
														place='top'
														value={parseDatePickerValue(
															nextInvoiceDate
														)}
														onChange={e =>
															nextInvoiceDateOnChange({
																target: {
																	value: parseDatePickerChange(
																		e.target.value,
																		nextInvoiceDate
																	),
																},
															})
														}
													/>
												</FormField>
											</FormGroup>
										</Section.Body>
									</Section>
									<Section title='Deferred Income'>
										<Section.Body>
											<FormGroup row>
												<FormField
													name='enableDeferredIncome'
													label='Enable Deferred Income'
													id={data.id}
													colMd={3}>
													<Toggle
														spaceLess
														value={enableDeferredIncome}
														onChange={enableDeferredIncomeOnChange}
														disabled={
															areFieldsDisabled() ||
															(data.product &&
																!data.product.enableDeferredIncome)
														}
													/>
												</FormField>
												<FormField
													name='deferredIncomeFrequency'
													label='Frequency'
													id={data.id}
													valRes={deferredIncomeFrequencyValRes}
													showValidation={deferredIncomeFrequencyShowVal}
													colMd={3}>
													<Selects
														options={filterInvoicingFrequency(
															invoicingFrequencies,
															form.bookingPeriod,
															false,
															invoicingFrequency
																? invoicingFrequency.value
																: null
														)}
														placeholder={`Frequency${
															enableDeferredIncome
																? ' (Required)'
																: ''
														}`}
														value={deferredIncomeFrequency}
														onChange={deferredIncomeFrequencyOnChange}
														onBlur={setDeferredIncomeFrequencyShowVal}
														displayKey='value'
														disabled={
															!enableDeferredIncome ||
															areFieldsDisabled()
														}
														disableClearable
													/>
												</FormField>
												<FormField
													name='deferredIncomeSalesAccount'
													label='Sales Income Account'
													id={data.id}
													valRes={deferredIncomeSalesAccountValRes}
													showValidation={
														deferredIncomeSalesAccountShowVal
													}
													colMd={3}>
													<Selects
														options={accounts}
														placeholder={`Sales Income Account${
															enableDeferredIncome
																? ' (Required)'
																: ''
														}`}
														value={deferredIncomeSalesAccount}
														onChange={
															deferredIncomeSalesAccountOnChange
														}
														onBlur={
															setDeferredIncomeSalesAccountShowVal
														}
														disabled={
															!enableDeferredIncome ||
															areFieldsDisabled()
														}
														disableClearable
													/>
												</FormField>
											</FormGroup>
										</Section.Body>
									</Section>
									<Section title='Accounting'>
										<Section.Body>
											<FormField
												name='taxCode'
												label='Tax Code'
												id={data.id}
												valRes={taxCodeValRes}
												showValidation={taxCodeShowVal}
												colLg={3}>
												<Selects
													disabled={areFieldsDisabled()}
													options={taxCodes}
													placeholder='Tax code'
													value={taxCode}
													onChange={taxCodeOnChange}
													onBlur={setTaxCodeShowVal}
												/>
											</FormField>
										</Section.Body>
									</Section>
								</Portlet.Body>
							</Portlet>
							<Portlet hasFrame stickyBottom={0} className='flex-shrink-0'>
								<Portlet.Foot
									tall='sm'
									className='sdms-align-left'
									subClassName='justify-content-between'>
									<div className='col-auto'>
										<Button
											className='sdms-mw-105 justify-content-center'
											label='dark'
											icon='User'
											block
											disabled={
												(reservation.isLocked &&
													!hasEditLockedReservationPermission(
														userContext
													)) ||
												isSearching ||
												disabled
											}
											onClick={openCustomerModal}>
											{reservation.customer
												? reservation.customer.displayName
												: 'Select Customer'}
										</Button>
									</div>
									<div className='col-auto'>
										<h5>Balance Due: {priceFormatter(balanceDue)}</h5>
									</div>
									<div className='col-auto sdms-last-margin--h'>
										{data.id === 0 && (
											<Button
												design='clean'
												text='Back'
												size='sm'
												onClick={onClose}
											/>
										)}
										<Button
											className={classNames(
												'sdms-mw-105 justify-content-center',
												{
													'sdms-fading-dots':
														submitButtonAttr.text ===
														process.env
															.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
												}
											)}
											label={submitButtonAttr.color}
											text={submitButtonAttr.text}
											icon={submitButtonAttr.icon}
											size='sm'
											disabled={
												isSearching ||
												submitButtonAttr.text ===
													process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT
											}
											onClick={() => {
												setIsSubmitted(true);

												if (isDateChanged) {
													addErrorNotification(
														'Please check availability'
													);
													return;
												}

												if (!reservation.customer) {
													addErrorNotification(
														'Please select customer first'
													);
													return;
												}

												if (isValid) {
													const _data = {
														id: data.id,
														unit,
														product: data.product,
														fromDate: fromDate.toISOString(),
														toDate: toDate.toISOString(),
														subtotal,
														tax: totals.tax,
														total: totals.total,
														depositAmount: parseFloat(depositAmount),
														ratePlan,
														policy,
														extraCharges: selectedExtraCharges,
														quantity: quantityRef.current,
														note,
														internalNote,
														externalNote,
														ignoredRules: ignoreRules,
														ignoredCapacity: ignoreCapacity,
														productContracts,
														capacity: getCapacity(
															data.product,
															quantityRef.current,
															loa,
															beam
														),
														loa,
														beam,
														height,
														weight,
														draft,
														fixedPrice,
														invoiceGenerationAutomatedBatch,
														invoicingFrequency,
														paymentTerm,
														firstInvoice,
														nextInvoice,
														proratedFrequency,
														coTermDate,
														autoPay,
														nextInvoiceDate,
														prevInvoiceDate: data.prevInvoiceDate,
														bookingCalculation,
														taxCode,
														enableDeferredIncome,
														deferredIncomeFrequency,
														deferredIncomeSalesAccount,
														vessel,
														season,
														vehicle,
													};

													onItemUpdate(_data);
												}
											}}
										/>
										{displayDepositButton(
											reservation,
											data,
											remainingDepositAmount
										) && (
											<Button
												className='sdms-mw-105 justify-content-center'
												icon='Angle-right-circle'
												label='success'
												text='Deposit'
												size='sm'
												disabled={disabled || isSearching}
												onClick={() => {
													openPayModal(
														parentModals.DEPOSIT,
														remainingDepositAmount,
														null
													);
												}}
											/>
										)}
										{balanceDue > 0 && data.id !== 0 && (
											<Button
												className='sdms-mw-105 justify-content-center'
												icon='Angle-right-circle'
												label='success'
												text='Pay now'
												size='sm'
												disabled={disabled || isSearching}
												onClick={() =>
													openPayModal(parentModals.PAY, null, balanceDue)
												}
											/>
										)}
										{refundableSecurityDepositAmount > 0 && (
											<>
												<Button
													className='sdms-mw-105 justify-content-center'
													icon='Angle-right-circle'
													label='success'
													text='Retain'
													size='sm'
													disabled={isSearching}
													onClick={() =>
														openPayModal(
															parentModals.RETAIN,
															refundableSecurityDepositAmount,
															null
														)
													}
												/>
												<Button
													className='sdms-mw-105 justify-content-center'
													icon='Angle-right-circle'
													label='success'
													text='Refund'
													size='sm'
													disabled={isSearching}
													onClick={() =>
														openPayModal(
															parentModals.NON_TRANSIENT_REFUND,
															refundableSecurityDepositAmount,
															null
														)
													}
												/>
											</>
										)}
									</div>
								</Portlet.Foot>
							</Portlet>
						</InnerPage.Item>
					)}
					{// Invoices
					activeStep === 1 && (
						<InnerPage.Item>
							{_Header}
							<InvoiceList
								filters={{ reservationId: reservation.id }}
								presetData={{
									customer: reservation.customer,
									reservation,
									paymentTerm: data.paymentTerm,
									invoiceDate: moment()
										.utc(false)
										.toISOString(),
									dueDate: data.paymentTerm
										? moment()
												.utc(false)
												.add(data.paymentTerm.due, 'day')
												.toISOString()
										: null,
								}}
								onChange={onInvoiceChange}
								disabled={disabled || isWaitList}
								onPaymentPrint={onPaymentPrint}
								onPrint={onPrintReservationInvoice}
								onSend={onSendInvoiceEmail}
							/>
						</InnerPage.Item>
					)}
					{// Payments
					activeStep === 2 && (
						<InnerPage.Item>
							{_Header}
							<PaymentList
								filters={{
									reservation: reservation.id,
									reservationItem: data.id,
								}}
								presetData={{
									customer: reservation.customer,
									timeCreated: moment().toISOString(),
									outlet: userContext.data.selectedOutlet,
									automaticallySettle: true,
									changeDue: 0,
									reservation,
									reservationItem: data.id,
								}}
								onChange={onInvoiceChange}
								disabled={disabled || isWaitList}
								paymentTypes={paymentTypes}
								webPrint={webPrint}
								onEditAuthorizedPayment={onAuthorizePayment}
								onCaptureAuthorizedPayment={onCaptureAuthorizedPayment}
								onVoidAuthorizedPayment={onVoidAuthorizedPayment}
							/>
						</InnerPage.Item>
					)}
					{//	Space Assignments
					activeStep === 3 && (
						<InnerPage.Item>
							{_Header}
							<SpaceAssignmentList
								filters={{
									'reservationItem.id': data.id,
								}}
								presetData={{
									loa: data.loa,
									beam: data.beam,
									height: data.height,
									draft: data.draft,
									weight: data.weight,
									reservationItem: data,
									minDate: data.fromDate.toString(),
									maxDate: data.toDate.toString(),
									vessel: reservation.vessel,
									vehicle: reservation.vehicle,
								}}
								onChange={onInvoiceChange}
								disabled={disabled || isWaitList}
							/>
						</InnerPage.Item>
					)}
					{// Contracts
					activeStep === 4 && (
						<InnerPage.Item>
							{_Header}
							<ContractList
								module={form.module.value}
								reservation={reservation}
								reservationItem={data}
								isFluid
							/>
						</InnerPage.Item>
					)}
				</InnerPage.Wrapper>
			</InnerPage>
			<VehicleModal
				isOpen={modal.open === modals.VEHICLE_SELECT || modal.open === modals.VEHICLE_FORM}
				onClose={closeModal}
				defaultVehicle={vehicle}
				onSelect={v => {
					updateVehicleData.current = true;
					setVehicle(v);
					closeModal();
				}}
				hasForm
				openForm={modal.open === modals.VEHICLE_FORM}
				enumRvTypes={enumRvTypes}
				enumVehicleMakes={enumVehicleMakes}
				defaultSearchText={reservation?.customer?.displayName || ''}
			/>
			<VesselModal
				isOpen={modal.open === modals.VESSEL_SELECT || modal.open === modals.VESSEL_FORM}
				onClose={closeModal}
				defaultVessel={vessel}
				onSelect={v => {
					updateVesselData.current = true;
					setVessel(v);
					closeModal();
				}}
				hasForm
				openForm={modal.open === modals.VESSEL_FORM}
				enumBoatTypes={enumBoatTypes}
				enumBoatMakes={enumBoatMakes}
				defaultSearchText={reservation?.customer?.displayName || ''}
			/>
			{modal.open === modals.EXTRA_FEE_SETTINGS &&
				selectedExtraCharges.find(sec => sec.id === modal.id) && (
					<ExtraFeeSettings
						data={selectedExtraCharges.find(sec => sec.id === modal.id)}
						onClose={closeModal}
						invoicingFrequencies={invoicingFrequencies}
						invoiceFirstOptions={invoiceFirstOptions}
						invoiceNextOptions={invoiceNextOptions}
						paymentTerms={paymentTerms}
						onFormChange={onFormChange}
						accounts={accounts}
						reservationItem={{
							fromDate,
							paymentTerm,
							firstInvoice,
							nextInvoice,
							proratedFrequency,
							coTermDate,
							nextInvoiceDate,
						}}
						updateNextInvoiceDate={data.id === 0 && !isWaitList}
						isSubmitted={isSubmitted}
						setIsValid={_isValid => {
							setInvalidExtraCharges(
								_isValid
									? invalidExtraCharges.filter(iec => iec !== modal.id)
									: [...invalidExtraCharges, modal.id]
							);
						}}
					/>
				)}
		</>
	);
};
ReservationAdvancedNonTransientItemForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	form: PropTypes.object.isRequired,
	data: PropTypes.shape({
		id: PropTypes.number,
		unit: PropTypes.object,
		fromDate: PropTypes.string,
		toDate: PropTypes.string,
		depositAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		quantity: PropTypes.number,
		product: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
			bookingPeriod: PropTypes.object,
			bookingType: PropTypes.object,
			checkInTime: PropTypes.string,
			checkOutTime: PropTypes.string,
			module: PropTypes.object,
			unitCampgrounds: PropTypes.arrayOf(PropTypes.object),
			unitMarinas: PropTypes.arrayOf(PropTypes.object),
			additionalTaxes: PropTypes.arrayOf(PropTypes.object),
			productExtraCharges: PropTypes.arrayOf(PropTypes.object),
			policy: PropTypes.object,
			bookingInterval: PropTypes.number,
			bookingCalculation: PropTypes.object,
			productContracts: PropTypes.arrayOf(PropTypes.object),
			maxBookInAdvance: PropTypes.number,
			price: PropTypes.number,
			productCustomReservationStatuses: PropTypes.arrayOf(PropTypes.object),
			enableDeferredIncome: PropTypes.bool,
		}),
		subtotal: PropTypes.number,
		tax: PropTypes.number,
		total: PropTypes.number,
		ratePlan: PropTypes.object,
		extraCharges: PropTypes.arrayOf(PropTypes.object),
		policy: PropTypes.object,
		note: PropTypes.string,
		internalNote: PropTypes.string,
		externalNote: PropTypes.string,
		invoiceGenerationAutomatedBatch: PropTypes.bool,
		invoicingFrequency: PropTypes.object,
		paymentTerm: PropTypes.object,
		proratedFrequency: PropTypes.object,
		coTermDate: PropTypes.string,
		status: PropTypes.object,
		ignoredRules: PropTypes.bool,
		ignoredCapacity: PropTypes.bool,
		productContracts: PropTypes.arrayOf(PropTypes.object),
		customerSettlements: PropTypes.arrayOf(PropTypes.object),
		reservationItemId: PropTypes.string,
		vessel: PropTypes.object,
		vehicle: PropTypes.object,
		loa: PropTypes.number,
		beam: PropTypes.number,
		draft: PropTypes.number,
		height: PropTypes.number,
		weight: PropTypes.number,
		season: PropTypes.object,
		fixedPrice: PropTypes.bool,
		timeCreated: PropTypes.string,
		firstInvoice: PropTypes.object,
		nextInvoice: PropTypes.object,
		nextInvoiceDate: PropTypes.string,
		prevInvoiceDate: PropTypes.string,
		spaceAssignments: PropTypes.arrayOf(PropTypes.object),
		bookingCalculation: PropTypes.object,
		taxCode: PropTypes.object,
		customReservationStatus: PropTypes.object,
	}),
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	taxRate: PropTypes.shape({
		amount: PropTypes.number,
		type: PropTypes.object,
	}),
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	policies: PropTypes.arrayOf(PropTypes.object),
	onUpdate: PropTypes.func,
	onClose: PropTypes.func,
	disabled: PropTypes.bool,
	onFormChange: PropTypes.func,
	contracts: PropTypes.arrayOf(PropTypes.object),
	responsive: PropTypes.oneOf([null, 'scroll', 'grid']),
	listMediaBreakpoint: PropTypes.oneOf([null, 'sm', 'md', 'lg', 'xl']),
	currentExtraChargeItemProductId: PropTypes.number,
	onOverride: PropTypes.func,
	enumBoatMakes: PropTypes.arrayOf(PropTypes.object),
	enumBoatTypes: PropTypes.arrayOf(PropTypes.object),
	enumVehicleMakes: PropTypes.arrayOf(PropTypes.object),
	enumRvTypes: PropTypes.arrayOf(PropTypes.object),
	openPayModal: PropTypes.func,
	openCustomerModal: PropTypes.func,
	onCheckIn: PropTypes.func,
	onCheckOut: PropTypes.func,
	invoiceFirstOptions: PropTypes.arrayOf(PropTypes.object),
	invoiceNextOptions: PropTypes.arrayOf(PropTypes.object),
	invoicingFrequencies: PropTypes.arrayOf(PropTypes.object),
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	onInvoiceChange: PropTypes.func,
	onItemUpdateStatus: PropTypes.func,
	onSendConfirmationEmail: PropTypes.func,
	onPrintReservationInvoice: PropTypes.func,
	onSendInvoiceEmail: PropTypes.func,
	onCancel: PropTypes.func,
	innerPageNavStatus: PropTypes.bool.isRequired,
	setInnerPageNavStatus: PropTypes.func.isRequired,
	onPaymentPrint: PropTypes.func,
	paymentTypes: PropTypes.arrayOf(PropTypes.object),
	seasons: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	webPrint: PropTypes.object,
	enumBookingCalculations: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	parentModals: PropTypes.object.isRequired,
	onItemUpdateCustomStatus: PropTypes.func,
	accounts: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	onPaymentScheduleChange: PropTypes.func,
	onCustomerPaymentMethodChange: PropTypes.func,
	onAuthorizePayment: PropTypes.func,
	onCaptureAuthorizedPayment: PropTypes.func,
	onVoidAuthorizedPayment: PropTypes.func,
	cancellationReason: PropTypes.string,
	cancellationTime: PropTypes.string,
};
ReservationAdvancedNonTransientItemForm.defaultProps = {
	data: {
		id: 0,
		unit: {},
		fromDate: {},
		toDate: {},
		depositAmount: 0,
		product: {},
		subtotal: 0,
		tax: 0,
		total: 0,
		extraCharges: [],
		note: '',
		internalNote: '',
		externalNote: '',
		ignoredRules: false,
		ignoredCapacity: false,
		invoiceGenerationAutomatedBatch: true,
		invoicingFrequency: null,
		paymentTerm: null,
		proratedFrequency: null,
		coTermDate: '',
		vessel: null,
		vehicle: null,
		loa: null,
		beam: null,
		draft: null,
		height: null,
		weight: null,
		season: null,
		fixedPrice: false,
		firstInvoice: null,
		nextInvoice: null,
		nextInvoiceDate: null,
		spaceAssignments: [],
		bookingCalculation: null,
	},
	reservation: {},
	taxRate: {},
	ratePlans: [],
	policies: [],
	onClose: () => {},
	onUpdate: () => {},
	disabled: false,
	onFormChange: () => {},
	contracts: [],
	responsive: 'scroll',
	listMediaBreakpoint: null,
	currentExtraChargeItemProductId: 0,
	onOverride: () => {},
	enumBoatMakes: [],
	enumBoatTypes: [],
	enumVehicleMakes: [],
	enumRvTypes: [],
	openPayModal: () => {},
	openCustomerModal: () => {},
	onCheckIn: () => {},
	onCheckOut: () => {},
	invoiceFirstOptions: [],
	invoiceNextOptions: [],
	invoicingFrequencies: [],
	paymentTerms: [],
	onInvoiceChange: () => {},
	onItemUpdateStatus: () => {},
	onSendConfirmationEmail: () => {},
	onPrintReservationInvoice: () => {},
	onSendInvoiceEmail: () => {},
	onCancel: () => {},
	onPaymentPrint: () => {},
	paymentTypes: [],
	seasons: [],
	webPrint: null,
	enumBookingCalculations: [],
	onItemUpdateCustomStatus: () => {},
	accounts: [],
	taxCodes: [],
	onPaymentScheduleChange: null,
	onCustomerPaymentMethodChange: null,
	onAuthorizePayment: null,
	onCaptureAuthorizedPayment: null,
	onVoidAuthorizedPayment: null,
	cancellationReason: null,
	cancellationTime: null,
};

export default ReservationAdvancedNonTransientItemForm;
