import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FormGroup from '../../reusables/layout/FormGroup';
import Loading from '../../reusables/template/Loading';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';
import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import { modules } from '../../../utils/helpers/apiCall';

const ProductCategoryMarinaForm = ({
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	onFormChange,
	isLoading,
	productCategoryMarinas,
	submitButtonAttr,
	submit,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const marinaModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.MARINA)
	);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [parentCategory, parentCategoryOnChange] = useField(
		data,
		'parentCategory',
		onFormChange,
		[],
		null
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
		}
	}, [isSubmitted, setNameShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid);
	}, [nameValRes.isValid, setIsValid]);

	useEffect(() => {
		if (!isLoading) data.module = marinaModule.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.marina.default.text,
				path: pages.marina.dashboard.path,
			},
			{
				title: pages.marina.settings.text,
				path: pages.marina.settings.path,
			},
			{
				title: pages.marina.settings.productCategories.text,
				path: pages.marina.settings.productCategories.path,
			},
			{
				title: name || `New ${pages.marina.settings.productCategories.text}`,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(name || `New ${pages.marina.settings.productCategories.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='parentCategory'
								label='Parent Category'
								id={data.id}
								colMd={6}>
								<Selects
									options={productCategoryMarinas.filter(pc => pc.id !== data.id)}
									placeholder='Select a Category'
									value={parentCategory}
									onChange={parentCategoryOnChange}
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
ProductCategoryMarinaForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		module: PropTypes.object,
	}),
	productCategoryMarinas: PropTypes.arrayOf(PropTypes.object),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
ProductCategoryMarinaForm.defaultProps = {
	data: {
		id: 0,
		module: null,
	},
	productCategoryMarinas: [],
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default ProductCategoryMarinaForm;
