import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Scanner = ({
	onScan,
	onError,
	onReceive,
	onScanDetect,
	timeBeforeScanTest,
	avgTimeByChar,
	minLength,
	endChar,
	startChar,
	scanButtonKeyCode,
	stopPropagation,
	preventDefault,
	testCode,
	onScanButtonLongPressed,
	scanButtonLongPressThreshold,
	disabled,
}) => {
	const scan = useRef('');

	const testTimer = useRef(0);

	let firstCharTime = 0;
	let lastCharTime = 0;
	let callIsScanner = false;
	let scanButtonCounter = 0;

	const initScannerDetection = () => {
		firstCharTime = 0;
		scan.current = '';
		scanButtonCounter = 0;
	};

	const scannerDetectionTest = () => {
		if (!scanButtonCounter) {
			scanButtonCounter = 1;
		}

		if (
			scan.current.length >= minLength &&
			lastCharTime - firstCharTime < scan.current.length * avgTimeByChar
		) {
			if (onScanButtonLongPressed && scanButtonCounter > scanButtonLongPressThreshold)
				onScanButtonLongPressed(scan.current, scanButtonCounter);
			else if (onScan) onScan(scan.current);

			initScannerDetection();
			return true;
		}

		let errorMsg = '';
		if (scan.current.length < minLength) {
			errorMsg = `String length should be greater or equal ${minLength}`;
		} else if (lastCharTime - firstCharTime > scan.current.length * avgTimeByChar) {
			errorMsg = `Average key character time should be less or equal ${avgTimeByChar}ms`;
		}

		if (onError) onError(scan.current, errorMsg);
		initScannerDetection();
		return false;
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleKeyPress = e => {
		if (disabled) return;

		// If it's just the button of the scanner, ignore it and wait for the real input
		if (scanButtonKeyCode && e.which === scanButtonKeyCode) {
			scanButtonCounter += 1;
			// Cancel default
			e.preventDefault();
			e.stopImmediatePropagation();
		}

		if (e.ctrlKey) {
			e.preventDefault();
			return;
		}

		if (stopPropagation) e.stopImmediatePropagation();
		if (preventDefault) e.preventDefault();

		if (firstCharTime && endChar.indexOf(e.which) !== -1) {
			e.preventDefault();
			e.stopImmediatePropagation();
			callIsScanner = true;
		} else if (!firstCharTime && startChar.indexOf(e.which) !== -1) {
			e.preventDefault();
			e.stopImmediatePropagation();
			callIsScanner = false;
		} else {
			if (typeof e.which !== 'undefined') {
				scan.current += String.fromCharCode(e.which);
			}
			callIsScanner = false;
		}

		if (!firstCharTime) {
			firstCharTime = Date.now();
		}
		lastCharTime = Date.now();

		if (
			onScanDetect &&
			scan.current.length >= minLength &&
			lastCharTime - firstCharTime < scan.current.length * avgTimeByChar
		) {
			onScanDetect();
		}

		if (testTimer.current) clearTimeout(testTimer.current);

		if (callIsScanner) {
			scannerDetectionTest();
			testTimer.current = 0;
		} else {
			testTimer.current = setTimeout(scannerDetectionTest, timeBeforeScanTest);
		}

		if (onReceive) onReceive(e);
	};

	useEffect(() => {
		window.document.addEventListener('keypress', handleKeyPress);

		return () => window.document.removeEventListener('keypress', handleKeyPress);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleKeyPress, scan.current, testTimer.current]);

	if (testCode) return scannerDetectionTest(testCode);

	return null;
};

Scanner.propTypes = {
	onScan: PropTypes.func, // Callback after detection of a successfull scanning (scanned string in parameter)
	onError: PropTypes.func, // Callback after detection of a unsuccessfull scanning (scanned string in parameter)
	onReceive: PropTypes.func, // Callback after receiving and processing a char (scanned char in parameter)
	onScanDetect: PropTypes.func, // Callback after detecting a keyDown (key char in parameter) - in contrast to onReceive, this fires for non-character keys like tab, arrows, etc. too!
	timeBeforeScanTest: PropTypes.number, // Wait duration (ms) after keypress event to check if scanning is finished
	avgTimeByChar: PropTypes.number, // Average time (ms) between 2 chars. Used to do difference between keyboard typing and scanning
	minLength: PropTypes.number, // Minimum length for a scanning
	endChar: PropTypes.arrayOf(PropTypes.number), // Chars to remove and means end of scanning
	startChar: PropTypes.arrayOf(PropTypes.number), // Chars to remove and means start of scanning
	scanButtonKeyCode: PropTypes.number, // Key code of the scanner hardware button (if the scanner button a acts as a key itself)
	stopPropagation: PropTypes.bool, // Stop immediate propagation on keypress event
	preventDefault: PropTypes.bool, // Prevent default action on keypress event
	testCode: PropTypes.string, // Test string for simulating
	scanButtonLongPressThreshold: PropTypes.number, // How many times the hardware button should issue a pressed event before a barcode is read to detect a longpress
	onScanButtonLongPressed: PropTypes.func, // Callback after detection of a successfull scan while the scan button was pressed and held down
	disabled: PropTypes.bool,
};

Scanner.defaultProps = {
	timeBeforeScanTest: 5000,
	avgTimeByChar: 50,
	minLength: 21,
	endChar: [9, 13],
	startChar: [],
	stopPropagation: false,
	preventDefault: false,
	scanButtonLongPressThreshold: 3,
	disabled: false,
};

export default Scanner;
