import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DatePicker } from 'react-horizontal-datepicker';

const FlexibleDates = ({ dates, nightly }) => {
	const [key, setKey] = useState(moment().toISOString());

	const [startDate, setStartDate] = useState(moment(Object.keys(dates)[0], 'YYYY-MM-DD'));

	const [endDate, setEndDate] = useState(
		moment(Object.keys(dates)[Object.keys(dates).length - 1], 'YYYY-MM-DD')
	);

	useEffect(() => {
		setStartDate(moment(Object.keys(dates)[0], 'YYYY-MM-DD'));
		setEndDate(moment(Object.keys(dates)[Object.keys(dates).length - 1], 'YYYY-MM-DD'));
		setKey(moment().toISOString());
	}, [dates]);

	return (
		<div className='sdms-flexible-dates-calendar'>
			<DatePicker
				key={key}
				getSelectedDay={() => {}}
				startDate={startDate.toDate()}
				endDate={endDate.toDate()}
				selectDate={null}
				labelFormat='MMMM'
				disableSelectDay
				getDayClass={day => {
					const dayKey = moment(day).format('YYYY-MM-DD');

					if (nightly) {
						const previousDayKey = moment(day)
							.add(-1, 'day')
							.format('YYYY-MM-DD');

						if (dates[dayKey] === false && dates[previousDayKey] === false)
							return 'sdms-flexible-calendar-disable';

						if (dates[dayKey] === false && dates[previousDayKey])
							return 'sdms-flexible-calendar-nightly-disable-start';

						if (dates[dayKey] && dates[previousDayKey] === false)
							return 'sdms-flexible-calendar-nightly-disable-end';

						if (dates[dayKey] === false) return 'sdms-flexible-calendar-disable';

						return 'sdms-flexible-calendar-available';
					}

					if (dates[dayKey] === false) return 'sdms-flexible-calendar-disable';

					return 'sdms-flexible-calendar-available';
				}}
				displayNavigation={endDate.diff(startDate, 'days') > 6}
			/>
		</div>
	);
};

FlexibleDates.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	dates: PropTypes.object.isRequired,
	nightly: PropTypes.bool.isRequired,
};

export default FlexibleDates;
