import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import HeaderContext from '../../../app/contexts/HeaderContext';

import usePages from '../../../utils/hooks/usePages';
import { getModuleKeyByPath } from '../../../utils/helpers/reusable';
import useModal from '../../../utils/hooks/useModal';
import {
	addErrorNotification,
	addSuccessNotification,
	getEntityId,
} from '../../../utils/helpers/helper';
import apiCall from '../../../utils/helpers/apiCall';

import WidgetGrid from '../../reusables/widgets/WidgetGrid';
import Portlet from '../../reusables/layout/Portlet';
import ContentInner from '../../reusables/template/ContentInner';
import BreadcrumbContainer from '../../reusables/template/BreadcrumbContainer';
import Button from '../../reusables/element/Button';
import AnalyticsWidgetModal from '../../reusables/modals/AnalyticsWidgetModal';
import Dropdown from '../../reusables/element/Dropdown';

const modals = {
	ADD_WIDGET: 'add_widget',
};

const AnalyticsDashboard = () => {
	const headerContext = useContext(HeaderContext);

	const moduleKey = getModuleKeyByPath();

	const pages = usePages();

	const [modal, openModal, closeModal] = useModal();

	const [isLoading, setIsLoading] = useState(false);

	const [data, setData] = useState({});

	const layoutRef = useRef([]);

	const page = pages.analytics[moduleKey];

	const dashboardId = getEntityId();

	const nextRow = useMemo(() => {
		if ((data?.widgets || []).length === 0) return 0;

		const list = data.widgets.map(item => item.row + item.sizeY);

		return Math.max(...list);
	}, [data]);

	const handleLayoutChange = layout => {
		const widgetsCopy = data.widgets.map(widget => {
			const layoutItem = layout.find(item => item.id === widget.id);

			if (!layoutItem) return widget;

			const { col, row, sizeX, sizeY } = layoutItem;

			return {
				...widget,
				col,
				row,
				sizeX,
				sizeY,
			};
		});

		layoutRef.current = widgetsCopy;
	};

	const handleLayoutSave = (reload = false) => {
		apiCall(
			'POST',
			'analyticsSaveLayout',
			() => {
				addSuccessNotification('Layout saved successfuly.');

				if (reload) load();
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{
				widgets: layoutRef.current,
			}
		);
	};

	const handleOpenModal = (params = {}) => openModal({ open: modals.ADD_WIDGET, ...params });

	const handleCloseModal = (flag = false) => {
		if (flag) load();

		closeModal();
	};

	const load = () => {
		setIsLoading(true);

		apiCall(
			'GET',
			'analyticsDashboards',
			fetchedData => {
				setData(fetchedData);

				layoutRef.current = fetchedData.widgets;

				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());

				setIsLoading(false);
			},
			dashboardId,
			null,
			{
				'groups[]': 'analytics-dashboard:read',
			}
		);
	};

	useEffect(load, []);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.analytics.default.text, path: pages.analytics.dashboard.path },
			{ title: page.text, path: page.path },
			{ title: data?.name, isActive: true },
		]);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [data]);

	if (isLoading) return <Portlet.Placeholder />;

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title={`${page.text} Analytics`} />
					<BreadcrumbContainer />
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title={`${page.text} Analytics`} className='pb-5'>
				<Portlet>
					<Portlet.Head>
						<Portlet.HeadLabelTitle>{data?.name || ''}</Portlet.HeadLabelTitle>
						<Portlet.HeadToolbar className='w-100'>
							<Portlet.HeadActions className='sdms-last-margin--h d-flex justify-content-end w-100'>
								<Button.Group className='sdms-mr-15'>
									<Button
										label='brand'
										icon='Save'
										text='Save Layout'
										size='sm'
										onClick={() => handleLayoutSave(false)}
										disabled={isLoading}
									/>
									<Dropdown label='brand' aligned='right' isOnlyContent>
										<Dropdown.Item
											icon='Save'
											onClick={() => handleLayoutSave(true)}
											disabled={isLoading}>
											Save Layout & Reload
										</Dropdown.Item>
									</Dropdown>
								</Button.Group>
								<Button
									className='sdms-mr-15'
									label='brand'
									icon='Plus'
									text='Add Widget'
									size='sm'
									onClick={handleOpenModal}
									disabled={isLoading}
								/>
							</Portlet.HeadActions>
						</Portlet.HeadToolbar>
					</Portlet.Head>
					<Portlet.Body>
						<WidgetGrid
							widgets={data.widgets}
							columns={data.columns}
							setLayout={handleLayoutChange}
							reload={load}
							onEditWidget={widget => handleOpenModal({ widget })}
						/>
					</Portlet.Body>
				</Portlet>
			</ContentInner.Container>
			<AnalyticsWidgetModal
				onClose={handleCloseModal}
				isOpen={modal.open === modals.ADD_WIDGET}
				dashboardId={dashboardId}
				nextRow={nextRow}
				data={modal.widget}
			/>
		</>
	);
};

export default AnalyticsDashboard;
