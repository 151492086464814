import React, { useContext, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Route, Switch, useLocation } from 'react-router-dom';
import forms from './forms';
import UserContext from '../app/contexts/UserContext';
import Container from './reusables/layout/Container';
import Dashboard from './modules/Dashboard';
//	Accounting
import _DashboardAccounting from './modules/accounting/_Dashboard';
import _SettingsAccounting from './modules/accounting/_Settings';
import AccountDetailTypeList from './modules/accounting/AccountDetailTypeList';
import AccountList from './modules/accounting/AccountList';
import JournalList from './modules/accounting/JournalList';
import CreditMemoReasonList from './modules/accounting/CreditMemoReasonList';
import DiscountList from './modules/accounting/DiscountList';
import ErrorLogList from './modules/accounting/ErrorLogList';
import CreditCardErrorLog from './modules/accounting/CreditCardErrorLog';
import PaymentTermList from './modules/accounting/PaymentTermList';
import PayrollHoursList from './modules/accounting/PayrollHoursList';
import UnitMeasureList from './modules/accounting/UnitMeasureList';
import TaxAgencyList from './modules/accounting/TaxAgencyList';
import TaxCodeList from './modules/accounting/TaxCodeList';
import TaxRateList from './modules/accounting/TaxRateList';
import TaxRateGroupList from './modules/accounting/TaxRateGroupList';
import PaymentScheduleList from './modules/accounting/PaymentScheduleList';
//	Bookings
import _DashboardBooking from './modules/booking/_Dashboard';
import _SettingsBooking from './modules/booking/_Settings';
import Availability from './modules/booking/Availability';
import BookingTypeList from './modules/booking/BookingTypeList';
import BookingMap from './modules/booking/Map';
import ExtraChargesList from './modules/booking/ExtraChargesList';
import PolicyList from './modules/booking/PolicyList';
import ProductBookingList from './modules/booking/ProductBookingList';
import ProductCategoryBookingList from './modules/booking/ProductCategoryBookingList';
import ProductCategoryExtraChargeList from './modules/booking/ProductCategoryExtraChargeList';
import RatePlanList from './modules/booking/RatePlanList';
import ReservationList from './modules/booking/ReservationList';
import UnitBlockoutList from './modules/booking/UnitBlockoutList';
import UnitGroupsList from './reusables/forms/unit_groups/UnitGroupsList';
import UnitList from './modules/booking/UnitList';
//	CRM
import CustomerList from './modules/crm/CustomerList';
import InvoiceList from './modules/crm/InvoiceList';
import PaymentList from './modules/crm/PaymentList';
import CreditMemoList from './modules/crm/CreditMemoList';
import RefundList from './modules/crm/RefundList';
import StatementChargeList from './modules/crm/StatementChargeList';
//	Marina
import _DashboardMarina from './modules/marina/_Dashboard';
import _SettingsMarina from './modules/marina/_Settings';
import AvailabilityMarina from './modules/marina/AvailabilityMarina';
import BookingTypeListMarina from './modules/marina/BookingTypeList';
import Map from './modules/marina/Map';
import MapList from './reusables/forms/MapList';
import MarinaExtraChargesList from './modules/marina/ExtraChargesList';
import MarinaPolicyList from './modules/marina/PolicyList';
import MarinaProductCategoryExtraChargeList from './modules/marina/ProductCategoryExtraChargeList';
import MarinaRatePlanList from './modules/marina/RatePlanList';
import MarinaReservationList from './modules/marina/ReservationList';
import ProductCategoryMarinaList from './modules/marina/ProductCategoryMarinaList';
import ProductMarinaList from './modules/marina/ProductMarinaList';
import SpaceBlockoutList from './modules/marina/SpaceBlockoutList';
import SpaceList from './modules/marina/SpaceList';
import VesselList from './modules/marina/VesselList';
import PowerMeterTypeList from './modules/marina/PowerMeterTypeList';
import PowerMeterList from './modules/marina/PowerMeterList';
import MeterReadingList from './modules/marina/MeterReadingList';
import MeterReadBatchEdit from './modules/marina/MeterReadBatchEdit';
//	Campground
import _DashboardCampground from './modules/campground/_Dashboard';
import _SettingsCampground from './modules/campground/_Settings';
import AvailabilityCampground from './modules/campground/AvailabilityCampground';
import BookingTypeListCampground from './modules/campground/BookingTypeList';
import CampgroundMap from './modules/campground/Map';
import CampgroundExtraChargesList from './modules/campground/ExtraChargesList';
import CampgroundPolicyList from './modules/campground/PolicyList';
import CampgroundProductCategoryExtraChargeList from './modules/campground/ProductCategoryExtraChargeList';
import CampgroundRatePlanList from './modules/campground/RatePlanList';
import CampgroundReservationList from './modules/campground/ReservationList';
import ProductCategoryCampgroundList from './modules/campground/ProductCategoryCampgroundList';
import ProductCampgroundList from './modules/campground/ProductCampgroundList';
import CampgroundSpaceBlockoutList from './modules/campground/SpaceBlockoutList';
import CampgroundSpaceList from './modules/campground/SpaceList';
import CampgroundCompositeSpaceList from './modules/campground/CompositeSpaceList';
import CampgroundVehicleList from './modules/campground/VehicleList';
import CampgroundPowerMeterTypeList from './modules/campground/PowerMeterTypeList';
import CampgroundPowerMeterList from './modules/campground/PowerMeterList';
import CampgroundMeterReadingList from './modules/campground/MeterReadingList';
import CampgroundMeterReadBatchEdit from './modules/campground/MeterReadBatchEdit';
//	Pos
import _DashboardPos from './modules/pos/_Dashboard';
import _SettingsPos from './modules/pos/_Settings';
import GeneralPosEdit from './modules/pos/GeneralSettingsEdit';
import GridList from './modules/pos/GridList';
import ModifierGroupList from './modules/pos/ModifierGroupList';
import ModifierPrefixList from './modules/pos/ModifierPrefixList';
import PrepStationList from './modules/pos/PrepStationList';
import PrintLogList from './modules/pos/PrintLogList';
import ProductCategoryList from './modules/pos/ProductCategoryList';
import ProductList from './modules/pos/ProductList';
import RegisterList from './modules/pos/RegisterList';
import RegisterTypeList from './modules/pos/RegisterTypeList';
import TableMapList from './modules/pos/TableMapList';
// Messages
import MessagesList from './modules/messages/MessagesList';
// Analytics
import DashboardAnalytics from './modules/analytics/_Dashboard';
import AnalyticsDashboardList from './modules/analytics/AnalyticsDashboardList';
// Reports
import DashboardReports from './modules/reports/_Dashboard';
import ReportsList from './modules/reports/ReportsList';
import ReportsGeneric from './modules/reports/ReportsGeneric';
import PosZout from './modules/reports/PosZout';
//	Company Settings
import _DashboardCompanySettings from './modules/company_settings/_Dashboard';
import CalendarList from './modules/company_settings/CalendarList';
import CompanyEdit from './modules/company_settings/CompanyEdit';
import OutletDefaultsEdit from './modules/company_settings/OutletDefaultsEdit';
import PaymentProcessorList from './modules/company_settings/PaymentProcessorList';
import PrinterList from './modules/company_settings/PrinterList';
import ReservationSettingsEdit from './modules/company_settings/ReservationSettingsEdit';
import TemplateSettingsEdit from './modules/company_settings/TemplateSettingsEdit';
//	System Settings
import _DashboardSystemSettings from './modules/system_settings/_Dashboard';
import AccountingIntegrationEdit from './modules/system_settings/AccountingIntegrationEdit';
import ContractList from './modules/system_settings/ContractList';
import PasswordSettingsEdit from './modules/system_settings/PasswordSettingsEdit';
import PersonalizationEdit from './modules/system_settings/PersonalizationEdit';
import RoleList from './modules/system_settings/RoleList';
import TemplateList from './modules/system_settings/TemplateList';
import UserList from './modules/system_settings/UserList';
import BatchJobsEdit from './modules/system_settings/BatchJobsEdit';
import EmailAddressesList from './modules/system_settings/EmailAddressesList';
import AttachmentTypesList from './modules/system_settings/AttachmentTypesList';
import AuditList from './modules/system_settings/AuditList';
import SeasonList from './modules/system_settings/SeasonList';
//	Other
import NoMatch from './NoMatch';
import NoPermission from './NoPermission';
import OnlinePortalContainer from './modules/crm/OnlinePortalContainer';
import AchErrorLog from './modules/accounting/AchErrorLog';
import ProductFilterList from './modules/system_settings/ProductFilterList';
import MetaList from './modules/system_settings/MetaList';
import PromoCodeList from './reusables/forms/PromoCodeList';
import EmailAddressAuthEdit from './modules/system_settings/EmailAddressAuthEdit';
import CustomReservationStatusList from './reusables/forms/CustomReservationStatusList';
import CompanySyncsList from './modules/company_settings/CompanySyncsList';
import ModuleEdit from './modules/company_settings/ModuleEdit';
import CompositeSpaceList from './modules/marina/CompositeSpaceList';
import usePages from '../utils/hooks/usePages';
import TransactionList from './modules/crm/TransactionList';
import AnalyticsDashboard from './modules/analytics/AnalyticsDashboard';

const Content = () => {
	const userContext = useContext(UserContext);
	const formsRef = useRef([]);
	const pages = usePages();
	const location = useLocation();

	const setFormsRef = obj => {
		if (Array.isArray(obj)) formsRef.current.push(...obj.filter(o => !o.isModal));
		else {
			Object.keys(obj).forEach(key => {
				if (typeof obj[key] === 'object') {
					setFormsRef(obj[key]);
				}
			});
		}
	};

	if (formsRef.current.length === 0) setFormsRef(forms);

	if (
		!userContext.data.user.isOwner &&
		!userContext.data.user.isInstallerUser &&
		userContext.data.user.userRoleOutlets.length === 0
	)
		return (
			<Container.Content>
				<AnimatePresence exitBeforeEnter initial={false}>
					<Switch location={location} key={location.pathname}>
						<Route component={NoPermission} />
					</Switch>
				</AnimatePresence>
			</Container.Content>
		);

	return (
		<Container.Content>
			<AnimatePresence exitBeforeEnter initial={false}>
				<Switch location={location} key={location.pathname}>
					<Route exact path='/' component={Dashboard} />

					{/*	BEGIN ACCOUNTING	*/}
					<Route
						exact
						path={pages.accounting.dashboard.path}
						component={
							userContext.hasPermission(pages.accounting.dashboard.permission)
								? _DashboardAccounting
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.accounting.chartOfAccounts.path}
						component={
							userContext.hasPermission(pages.accounting.chartOfAccounts.permission)
								? AccountList
								: NoPermission
						}
					/>
					<Route exact path={pages.accounting.journals.path} component={JournalList} />
					<Route
						exact
						path={pages.accounting.payrollHours.path}
						component={PayrollHoursList}
					/>
					<Route
						exact
						path={pages.accounting.qbErrorLogs.path}
						component={ErrorLogList}
					/>
					<Route
						exact
						path={pages.accounting.ccErrorLogs.path}
						component={CreditCardErrorLog}
					/>
					<Route
						exact
						path={pages.accounting.achErrorLogs.path}
						component={AchErrorLog}
					/>
					<Route
						exact
						path={pages.accounting.settings.path}
						component={
							userContext.hasPermission(pages.accounting.settings.permission)
								? _SettingsAccounting
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.accounting.settings.discount.path}
						component={
							userContext.hasPermission(pages.accounting.settings.permission)
								? DiscountList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.accounting.settings.creditMemoReasons.path}
						component={
							userContext.hasPermission(pages.accounting.settings.permission)
								? CreditMemoReasonList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.accounting.settings.paymentTerms.path}
						component={
							userContext.hasPermission(pages.accounting.settings.permission)
								? PaymentTermList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.accounting.settings.accountDetailTypes.path}
						component={
							userContext.hasPermission(pages.accounting.settings.permission)
								? AccountDetailTypeList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.accounting.settings.unitMeasures.path}
						component={
							userContext.hasPermission(pages.accounting.settings.permission)
								? UnitMeasureList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.accounting.settings.taxAgency.path}
						component={
							userContext.hasPermission(pages.accounting.settings.permission)
								? TaxAgencyList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.accounting.settings.taxRate.path}
						component={
							userContext.hasPermission(pages.accounting.settings.permission)
								? TaxRateList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.accounting.settings.taxRateGroup.path}
						component={
							userContext.hasPermission(pages.accounting.settings.permission)
								? TaxRateGroupList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.accounting.settings.taxCode.path}
						component={
							userContext.hasPermission(pages.accounting.settings.permission)
								? TaxCodeList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.accounting.settings.paymentSchedules.path}
						component={
							userContext.hasPermission(pages.accounting.settings.permission)
								? PaymentScheduleList
								: NoPermission
						}
					/>
					{/*	END ACCOUNTING	*/}

					{/*	BEGIN BOOKING	*/}
					<Route
						exact
						path={pages.booking.dashboard.path}
						component={
							userContext.hasPermission(pages.booking.dashboard.permission)
								? _DashboardBooking
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.availability.path}
						component={
							userContext.hasPermission(pages.booking.availability.permission)
								? Availability
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.reservations.path}
						component={
							userContext.hasPermission(pages.booking.reservations.permission)
								? ReservationList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.products.path}
						component={
							userContext.hasPermission(pages.booking.products.permission)
								? ProductBookingList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.settings.path}
						component={
							userContext.hasPermission(pages.booking.settings.permission)
								? _SettingsBooking
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.settings.bookingType.path}
						component={
							userContext.hasPermission(pages.booking.settings.bookingType.permission)
								? BookingTypeList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.settings.customReservationStatus.path}
						component={
							userContext.hasPermission(
								pages.booking.settings.customReservationStatus.permission
							)
								? CustomReservationStatusList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.settings.unitGroups.path}
						component={
							userContext.hasPermission(pages.booking.settings.unitGroups.permission)
								? UnitGroupsList
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.booking.settings.productCategories.path}
						component={
							userContext.hasPermission(
								pages.booking.settings.productCategories.permission
							)
								? ProductCategoryBookingList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.settings.productCategoriesExtraCharges.path}
						component={
							userContext.hasPermission(
								pages.booking.settings.productCategoriesExtraCharges.permission
							)
								? ProductCategoryExtraChargeList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.map.path}
						component={
							userContext.hasPermission(pages.booking.map.permission)
								? BookingMap
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.settings.units.path}
						component={
							userContext.hasPermission(pages.booking.settings.units.permission)
								? UnitList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.settings.unitBlackouts.path}
						component={
							userContext.hasPermission(
								pages.booking.settings.unitBlackouts.permission
							)
								? UnitBlockoutList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.settings.policies.path}
						component={
							userContext.hasPermission(pages.booking.settings.policies.permission)
								? PolicyList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.settings.ratePlans.path}
						component={
							userContext.hasPermission(pages.booking.settings.ratePlans.permission)
								? RatePlanList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.settings.extraCharges.path}
						component={
							userContext.hasPermission(
								pages.booking.settings.extraCharges.permission
							)
								? ExtraChargesList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.settings.maps.path}
						component={
							userContext.hasPermission(pages.booking.settings.maps.permission)
								? MapList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.booking.settings.promoCodes.path}
						component={
							userContext.hasPermission(pages.booking.settings.promoCodes.permission)
								? PromoCodeList
								: NoPermission
						}
					/>
					{/*	END BOOKING	*/}

					{/*	BEGIN CRM	*/}
					<Route
						exact
						path={pages.crm.dashboard.path}
						component={OnlinePortalContainer}
					/>

					<Route
						exact
						path={pages.crm.customers.path}
						component={
							userContext.hasPermission(pages.crm.customers.permission)
								? CustomerList
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.crm.invoices.path}
						component={
							userContext.hasPermission(pages.crm.invoices.permission)
								? InvoiceList
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.crm.transactions.path}
						component={
							userContext.hasPermission(pages.crm.transactions.permission)
								? TransactionList
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.crm.payments.path}
						component={
							userContext.hasPermission(pages.crm.payments.permission)
								? PaymentList
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.crm.creditMemos.path}
						component={
							userContext.hasPermission(pages.crm.creditMemos.permission)
								? CreditMemoList
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.crm.refunds.path}
						component={
							userContext.hasPermission(pages.crm.refunds.permission)
								? RefundList
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.crm.statementCharges.path}
						component={
							userContext.hasPermission(pages.crm.statementCharges.permission)
								? StatementChargeList
								: NoPermission
						}
					/>

					{/*	END CRM	*/}

					{/*	BEGIN MARINA	*/}
					<Route exact path={pages.marina.dashboard.path} component={_DashboardMarina} />

					<Route
						exact
						path={pages.marina.products.path}
						component={
							userContext.hasPermission(pages.marina.products.permission)
								? ProductMarinaList
								: NoPermission
						}
					/>
					<Route exact path={pages.marina.settings.path} component={_SettingsMarina} />
					<Route
						exact
						path={pages.marina.settings.bookingType.path}
						component={
							userContext.hasPermission(pages.marina.settings.bookingType.permission)
								? BookingTypeListMarina
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.settings.customReservationStatus.path}
						component={
							userContext.hasPermission(
								pages.marina.settings.customReservationStatus.permission
							)
								? CustomReservationStatusList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.settings.productCategories.path}
						component={
							userContext.hasPermission(
								pages.marina.settings.productCategories.permission
							)
								? ProductCategoryMarinaList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.map.path}
						component={
							userContext.hasPermission(pages.marina.map.permission)
								? Map
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.settings.maps.path}
						component={
							userContext.hasPermission(pages.marina.settings.maps.permission)
								? MapList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.settings.policies.path}
						component={
							userContext.hasPermission(pages.marina.settings.policies.permission)
								? MarinaPolicyList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.settings.spaceGroups.path}
						component={
							userContext.hasPermission(pages.marina.settings.spaceGroups.permission)
								? UnitGroupsList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.settings.extraChargeGroups.path}
						component={
							userContext.hasPermission(
								pages.marina.settings.extraChargeGroups.permission
							)
								? MarinaProductCategoryExtraChargeList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.settings.spaces.path}
						component={
							userContext.hasPermission(pages.marina.settings.spaces.permission)
								? SpaceList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.settings.compositeSpaces.path}
						component={
							userContext.hasPermission(
								pages.marina.settings.compositeSpaces.permission
							)
								? CompositeSpaceList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.settings.spaceBlockouts.path}
						component={
							userContext.hasPermission(
								pages.marina.settings.spaceBlockouts.permission
							)
								? SpaceBlockoutList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.settings.ratePlans.path}
						component={
							userContext.hasPermission(pages.marina.settings.ratePlans.permission)
								? MarinaRatePlanList
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.marina.reservations.path}
						component={
							userContext.hasPermission(pages.marina.reservations.permission)
								? MarinaReservationList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.availability.path}
						component={
							userContext.hasPermission(pages.marina.availability.permission)
								? AvailabilityMarina
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.settings.extraCharges.path}
						component={
							userContext.hasPermission(
								pages.booking.settings.extraCharges.permission
							)
								? MarinaExtraChargesList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.vessels.path}
						component={
							userContext.hasPermission(pages.marina.vessels.permission)
								? VesselList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.settings.powerMeterTypes.path}
						component={
							userContext.hasPermission(
								pages.marina.settings.powerMeterTypes.permission
							)
								? PowerMeterTypeList
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.marina.settings.powerMeters.path}
						component={
							userContext.hasPermission(pages.marina.settings.powerMeters.permission)
								? PowerMeterList
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.marina.meterReadings.path}
						component={
							userContext.hasPermission(pages.marina.meterReadings.permission)
								? MeterReadingList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.meterReadings.batch.path}
						component={
							userContext.hasPermission(pages.marina.meterReadings.batch.permission)
								? MeterReadBatchEdit
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.marina.settings.promoCodes.path}
						component={
							userContext.hasPermission(pages.marina.settings.promoCodes.permission)
								? PromoCodeList
								: NoPermission
						}
					/>
					{/*	END MARINA	*/}

					{/*	BEGIN CAMPGROUND	*/}
					<Route
						exact
						path={pages.campground.dashboard.path}
						component={_DashboardCampground}
					/>

					<Route
						exact
						path={pages.campground.products.path}
						component={
							userContext.hasPermission(pages.campground.products.permission)
								? ProductCampgroundList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.settings.path}
						component={_SettingsCampground}
					/>
					<Route
						exact
						path={pages.campground.settings.bookingType.path}
						component={
							userContext.hasPermission(
								pages.campground.settings.bookingType.permission
							)
								? BookingTypeListCampground
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.settings.customReservationStatus.path}
						component={
							userContext.hasPermission(
								pages.campground.settings.customReservationStatus.permission
							)
								? CustomReservationStatusList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.settings.productCategories.path}
						component={
							userContext.hasPermission(
								pages.campground.settings.productCategories.permission
							)
								? ProductCategoryCampgroundList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.map.path}
						component={
							userContext.hasPermission(pages.campground.map.permission)
								? CampgroundMap
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.settings.maps.path}
						component={
							userContext.hasPermission(pages.campground.settings.maps.permission)
								? MapList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.settings.policies.path}
						component={
							userContext.hasPermission(pages.campground.settings.policies.permission)
								? CampgroundPolicyList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.settings.spaceGroups.path}
						component={
							userContext.hasPermission(
								pages.campground.settings.spaceGroups.permission
							)
								? UnitGroupsList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.settings.extraChargeGroups.path}
						component={
							userContext.hasPermission(
								pages.campground.settings.extraChargeGroups.permission
							)
								? CampgroundProductCategoryExtraChargeList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.settings.spaces.path}
						component={
							userContext.hasPermission(pages.campground.settings.spaces.permission)
								? CampgroundSpaceList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.settings.compositeSpaces.path}
						component={
							userContext.hasPermission(
								pages.campground.settings.compositeSpaces.permission
							)
								? CampgroundCompositeSpaceList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.settings.spaceBlockouts.path}
						component={
							userContext.hasPermission(
								pages.campground.settings.spaceBlockouts.permission
							)
								? CampgroundSpaceBlockoutList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.settings.ratePlans.path}
						component={
							userContext.hasPermission(
								pages.campground.settings.ratePlans.permission
							)
								? CampgroundRatePlanList
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.campground.reservations.path}
						component={
							userContext.hasPermission(pages.campground.reservations.permission)
								? CampgroundReservationList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.availability.path}
						component={
							userContext.hasPermission(pages.campground.availability.permission)
								? AvailabilityCampground
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.settings.extraCharges.path}
						component={
							userContext.hasPermission(
								pages.booking.settings.extraCharges.permission
							)
								? CampgroundExtraChargesList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.vehicles.path}
						component={
							userContext.hasPermission(pages.campground.vehicles.permission)
								? CampgroundVehicleList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.settings.powerMeterTypes.path}
						component={
							userContext.hasPermission(
								pages.campground.settings.powerMeterTypes.permission
							)
								? CampgroundPowerMeterTypeList
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.campground.settings.powerMeters.path}
						component={
							userContext.hasPermission(
								pages.campground.settings.powerMeters.permission
							)
								? CampgroundPowerMeterList
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.campground.meterReadings.path}
						component={
							userContext.hasPermission(pages.campground.meterReadings.permission)
								? CampgroundMeterReadingList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.campground.meterReadings.batch.path}
						component={
							userContext.hasPermission(
								pages.campground.meterReadings.batch.permission
							)
								? CampgroundMeterReadBatchEdit
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.campground.settings.promoCodes.path}
						component={
							userContext.hasPermission(
								pages.campground.settings.promoCodes.permission
							)
								? PromoCodeList
								: NoPermission
						}
					/>
					{/*	END CAMPGROUND	*/}

					{/*	BEGIN POS	*/}
					<Route
						exact
						path={pages.pos.dashboard.path}
						component={
							userContext.hasPermission(pages.pos.dashboard.permission)
								? _DashboardPos
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.pos.registers.path}
						component={
							userContext.hasPermission(pages.pos.registers.permission)
								? RegisterList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.pos.products.path}
						component={
							userContext.hasPermission(pages.pos.products.permission)
								? ProductList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.pos.productGrids.path}
						component={
							userContext.hasPermission(pages.pos.productGrids.permission)
								? GridList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.pos.modifierGroups.path}
						component={
							userContext.hasPermission(pages.pos.modifierGroups.permission)
								? ModifierGroupList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.pos.settings.path}
						component={
							userContext.hasPermission(pages.pos.settings.permission)
								? _SettingsPos
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.pos.settings.posSettings.path}
						component={
							userContext.hasPermission(pages.pos.settings.permission)
								? GeneralPosEdit
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.pos.settings.registerTypes.path}
						component={
							userContext.hasPermission(pages.pos.settings.permission)
								? RegisterTypeList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.pos.settings.prepStations.path}
						component={
							userContext.hasPermission(pages.pos.settings.permission)
								? PrepStationList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.pos.settings.tableMaps.path}
						component={
							userContext.hasPermission(pages.pos.settings.permission)
								? TableMapList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.pos.settings.modifierPrefixes.path}
						component={
							userContext.hasPermission(pages.pos.settings.permission)
								? ModifierPrefixList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.pos.settings.productCategories.path}
						component={
							userContext.hasPermission(pages.pos.settings.permission)
								? ProductCategoryList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.pos.settings.printLogs.path}
						component={PrintLogList}
					/>
					{/*	END POS	*/}

					{/*	BEGIN MESSAGES	*/}
					<Route exact path={pages.messages.dashboard.path} component={MessagesList} />

					<Route exact path={pages.messages.read.path} component={MessagesList} />

					<Route exact path={pages.messages.unread.path} component={MessagesList} />

					<Route exact path={pages.messages.archived.path} component={MessagesList} />

					<Route exact path={pages.messages.sent.path} component={MessagesList} />

					{/*	END MESSAGES	*/}

					{/*	START ANALYTICS	*/}
					<Route
						exact
						path={pages.analytics.dashboard.path}
						component={DashboardAnalytics}
					/>
					<Route
						exact
						path={pages.analytics.accounting.path}
						component={AnalyticsDashboardList}
					/>
					<Route
						exact
						path={pages.analytics.accounting.dashboard.path}
						component={AnalyticsDashboard}
					/>
					<Route
						exact
						path={pages.analytics.booking.path}
						component={AnalyticsDashboardList}
					/>
					<Route
						exact
						path={pages.analytics.booking.dashboard.path}
						component={AnalyticsDashboard}
					/>
					<Route
						exact
						path={pages.analytics.campground.path}
						component={AnalyticsDashboardList}
					/>
					<Route
						exact
						path={pages.analytics.campground.dashboard.path}
						component={AnalyticsDashboard}
					/>
					<Route
						exact
						path={pages.analytics.crm.path}
						component={AnalyticsDashboardList}
					/>
					<Route
						exact
						path={pages.analytics.crm.dashboard.path}
						component={AnalyticsDashboard}
					/>
					<Route
						exact
						path={pages.analytics.marina.path}
						component={AnalyticsDashboardList}
					/>
					<Route
						exact
						path={pages.analytics.marina.dashboard.path}
						component={AnalyticsDashboard}
					/>
					<Route
						exact
						path={pages.analytics.pos.path}
						component={AnalyticsDashboardList}
					/>
					<Route
						exact
						path={pages.analytics.pos.dashboard.path}
						component={AnalyticsDashboard}
					/>
					{/*	END ANALYTICS	*/}

					{/*	BEGIN REPORTS	//newreporttype */}
					<Route exact path={pages.reports.dashboard.path} component={DashboardReports} />
					<Route exact path={pages.reports.accounting.path} component={ReportsList} />
					<Route
						exact
						path={`${pages.reports.accounting.deferred_income.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.accounting.general_ledger.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.accounting.payroll.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.accounting.settlement_details.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.accounting.deposit_balances.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.accounting.reservation_daily_revenue.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route exact path={pages.reports.booking.path} component={ReportsList} />
					<Route
						exact
						path={`${pages.reports.booking.reservations.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route exact path={pages.reports.campground.path} component={ReportsList} />
					<Route
						exact
						path={`${pages.reports.campground.reservations.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.campground.rent_roll.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route exact path={pages.reports.crm.path} component={ReportsList} />
					<Route
						exact
						path={`${pages.reports.crm.customers.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.crm.balance_details.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.crm.invoices.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.crm.invoice_details.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.crm.payments.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route exact path={pages.reports.marina.path} component={ReportsList} />
					<Route
						exact
						path={`${pages.reports.marina.reservations.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.marina.rent_roll.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.marina.vessels.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route exact path={pages.reports.pos.path} component={ReportsList} />
					<Route
						exact
						path={`${pages.reports.pos.zout.path}/get`}
						component={location.state ? PosZout : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.pos.orders.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.pos.orderdetails.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.pos.ordermodifiers.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					<Route
						exact
						path={`${pages.reports.pos.tips.path}/get`}
						component={location.state ? ReportsGeneric : NoMatch}
					/>
					{/*	END REPORTS	*/}

					{/*	BEGIN COMPANY SETTINGS	*/}
					<Route
						exact
						path={pages.companySettings.dashboard.path}
						component={_DashboardCompanySettings}
					/>
					<Route
						exact
						path={pages.companySettings.company.path}
						component={
							userContext.hasPermission(pages.companySettings.company.permission)
								? CompanyEdit
								: NoPermission
						}
					/>

					<Route
						exact
						path={pages.companySettings.companySyncs.path}
						component={
							userContext.hasPermission(pages.companySettings.companySyncs.permission)
								? CompanySyncsList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.companySettings.module.path}
						component={
							userContext.hasPermission(pages.companySettings.module.permission)
								? ModuleEdit
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.companySettings.outletDefaults.path}
						component={OutletDefaultsEdit}
					/>
					<Route
						exact
						path={pages.companySettings.paymentProcessors.path}
						component={
							userContext.hasPermission(
								null,
								pages.companySettings.paymentProcessors.installerRequired
							)
								? PaymentProcessorList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.companySettings.printers.path}
						component={
							userContext.hasPermission(pages.companySettings.printers.permission)
								? PrinterList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.companySettings.reservationSettings.path}
						component={ReservationSettingsEdit}
					/>
					<Route
						exact
						path={pages.companySettings.templateSettings.path}
						component={TemplateSettingsEdit}
					/>
					{/*	END COMPANY SETTINGS	*/}

					{/*	BEGIN SYSTEM SETTINGS	*/}
					<Route
						exact
						path={pages.systemSettings.dashboard.path}
						component={_DashboardSystemSettings}
					/>
					<Route
						exact
						path={pages.systemSettings.roles.path}
						component={
							userContext.hasPermission(pages.systemSettings.roles.permission)
								? RoleList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.systemSettings.users.path}
						component={
							userContext.hasPermission(pages.systemSettings.users.permission)
								? UserList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.systemSettings.personalization.path}
						component={PersonalizationEdit}
					/>
					<Route
						exact
						path={pages.systemSettings.calendars.path}
						component={
							userContext.hasPermission(pages.systemSettings.calendars.permission)
								? CalendarList
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.systemSettings.contracts.path}
						component={ContractList}
					/>
					<Route
						exact
						path={pages.systemSettings.templates.path}
						component={TemplateList}
					/>
					<Route
						exact
						path={pages.systemSettings.passwordSettings.path}
						component={PasswordSettingsEdit}
					/>
					<Route
						exact
						path={pages.systemSettings.accountingIntegration.path}
						component={AccountingIntegrationEdit}
					/>
					<Route
						exact
						path={pages.systemSettings.batchJobs.path}
						component={
							userContext.hasPermission(
								null,
								pages.systemSettings.batchJobs.installerRequired
							)
								? BatchJobsEdit
								: NoPermission
						}
					/>
					<Route
						exact
						path={pages.systemSettings.productFilters.path}
						component={ProductFilterList}
					/>
					<Route
						exact
						path={pages.systemSettings.emailAddresses.path}
						component={EmailAddressesList}
					/>
					<Route
						exact
						path={pages.systemSettings.emailAddressAuth.path}
						component={EmailAddressAuthEdit}
					/>
					<Route
						exact
						path={pages.systemSettings.attachmentTypes.path}
						component={AttachmentTypesList}
					/>

					<Route
						exact
						path={pages.systemSettings.auditLogs.path}
						component={
							userContext.hasPermission(pages.systemSettings.auditLogs.permission)
								? AuditList
								: NoPermission
						}
					/>

					<Route exact path={pages.systemSettings.metas.path} component={MetaList} />

					<Route exact path={pages.systemSettings.seasons.path} component={SeasonList} />

					{/*	END SYSTEM SETTINGS	*/}

					{/*	ALL FORMS	*/}
					{formsRef.current.map(f => {
						if (f.permission || f.installerRequired)
							return (
								<Route
									exact
									path={`${f.path}/:id(\\d+)`}
									component={
										userContext.hasPermission(f.permission, f.installerRequired)
											? f.component
											: NoPermission
									}
									key={`${f.path}form`}
								/>
							);
						return (
							<Route
								exact
								path={`${f.path}/:id(\\d+)`}
								component={f.component}
								key={`${f.path}form`}
							/>
						);
					})}

					{/*	NO MATCH	*/}
					<Route component={NoMatch} />
				</Switch>
			</AnimatePresence>
		</Container.Content>
	);
};

export default Content;
