import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';

import UserContext from '../../../../app/contexts/UserContext';
import useOutlet from '../../../../utils/hooks/useOutlet';
import { modules } from '../../../../utils/helpers/apiCall';
import { getBookingPeriodByPath, getModuleByPath } from '../../../../utils/helpers/reusable';
import { bookingPeriods } from '../../../../utils/constants/constants';
import { getParam } from '../../../../utils/helpers/helper';

import FormContainer from '../../template/FormContainer';
import OutletInactive from '../../element/OutletInactive';
import Form from './Form';
import ReservationAdvanceForm from './legacy/ReservationAdvanceForm';

const Edit = ({ match }) => {
	const userContext = useContext(UserContext);

	const location = useLocation();

	const history = useHistory();

	const [outletKey, outletConfigured] = useOutlet(true);

	const data = useMemo(() => {
		const { pathname } = location;

		const { itemId, from, searchData } = location.state
			? location.state
			: { itemId: 0, from: '', searchData: null };

		const module = getModuleByPath(pathname);

		const bookingPeriod = getBookingPeriodByPath(pathname);

		const _data = {
			lists: [
				'policies',
				'paymentTypes',
				'ratePlans',
				'enumReservationStatuses',
				'contracts',
				'paymentTerms',
				'countries',
				'states',
				'accounts',
				'invoicingFrequencies',
				'printers',
				'printerLogActions',
				'taxCodes',
				'paymentSchedules',
				'paymentMethods',
				'customReservationStatuses',
				'invoiceTypes',
			],
			module,
			itemId,
			from,
			searchData,
			legacyForm: false,
		};

		if (module === modules.BOOKINGS) {
			_data.lists.push('bookingExtraCharges');
			_data.legacyForm =
				userContext?.data?.selectedOutlet?.settings?.bookingUseLegacyReservationForm;
		}

		if (module === modules.CAMPGROUND) {
			_data.module = modules.CAMPGROUND;

			_data.lists.push('enumRvTypes', 'enumVehicleMakes');

			if (bookingPeriod === bookingPeriods.LONG_TERM)
				_data.lists.push(
					'invoiceFirstOptions',
					'invoiceNextOptions',
					'enumBookingCalculations',
					'paymentMethods'
				);
			else if (bookingPeriod === bookingPeriods.SEASONAL)
				_data.lists.push('seasons', 'invoiceFirstOptions', 'invoiceNextOptions');
			else _data.lists.push('bookingExtraCharges');

			_data.legacyForm =
				userContext?.data?.selectedOutlet?.settings?.campgroundUseLegacyReservationForm;
		}

		if (module === modules.MARINA) {
			_data.module = modules.MARINA;

			_data.lists.push('enumBoatTypes', 'enumBoatMakes', 'enumFuelTypes');

			if (bookingPeriod === bookingPeriods.LONG_TERM) {
				_data.lists.push(
					'invoiceFirstOptions',
					'invoiceNextOptions',
					'enumBookingCalculations',
					'paymentMethods'
				);
			} else if (bookingPeriod === bookingPeriods.SEASONAL)
				_data.lists.push('seasons', 'invoiceFirstOptions', 'invoiceNextOptions');
			else _data.lists.push('bookingExtraCharges');

			_data.legacyForm =
				userContext?.data?.selectedOutlet?.settings?.marinaUseLegacyReservationForm;
		}

		if (getParam('reservationId')) _data.lists = [];

		return _data;
	}, [location, userContext]);

	useEffect(() => {
		const paramId = getParam('reservationId');

		if (paramId) history.push(location.pathname.replace('0', paramId));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!outletConfigured) return <OutletInactive />;

	return (
		<FormContainer
			key={outletKey}
			dataId={match.params.id}
			dataName='reservations'
			lists={data.lists}
			fields={
				data.legacyForm ? (
					<ReservationAdvanceForm
						itemId={data.itemId.toString()}
						from={data.from}
						searchData={data.searchData}
					/>
				) : (
					<Form
						id={parseInt(match.params.id, 10)}
						itemId={data.itemId}
						from={data.from}
						searchData={data.searchData}
					/>
				)
			}
			withHeader={false}
			module={data.module}
		/>
	);
};

Edit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
	location: PropTypes.shape({
		search: PropTypes.string,
	}).isRequired,
};

export default Edit;
