import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import Portal from '../layout/Portal';
import Portlet from '../layout/Portlet';

const CountDownModal = ({ isOpen, counter, content }) => {
	const [count, setCount] = useState(0);

	const intervalId = useRef();
	useEffect(() => setCount(counter / 1000), [counter, isOpen]);
	useEffect(() => {
		clearInterval(intervalId.current);

		if (isOpen && !content) {
			intervalId.current = setInterval(() => {
				if (count <= 1) {
					clearInterval(intervalId.current);
				}
				setCount(count - 1);
			}, 1000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [count, isOpen, content]);

	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				closeOnEscape={false}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '320px',
				}}>
				<Portlet>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon='Sale#1'>
							Closing the register
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Body>
						{content || (
							<>
								Remaining {count} seconds.
								<div className='sdms-progress sdms-progress--info'>
									<progress
										max='35'
										value={count}
										className='sdms-progress-block'
									/>
								</div>
							</>
						)}
					</Portlet.Body>
				</Portlet>
			</Popup>
		</Portal>
	);
};

CountDownModal.propTypes = {
	isOpen: PropTypes.bool,
	counter: PropTypes.number,
	content: PropTypes.string,
};

CountDownModal.defaultProps = {
	isOpen: () => {},
	counter: 0,
	content: null,
};

export default CountDownModal;
