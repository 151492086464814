import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { maxLength, numeric, required, url } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import {
	bookingCalculations,
	bookingPeriods,
	enumItemTypes,
	firstInvoiceOptions,
	nextInvoiceOptions,
} from '../../../utils/constants/constants';
import { modules } from '../../../utils/helpers/apiCall';
import { bookingCalculationConverter } from '../../../utils/helpers/reservationHelper';
import useFeet from '../../../utils/hooks/useFeet';
import UserContext from '../../../app/contexts/UserContext';
import {
	dateFormatterWithoutYear,
	extraChargesToGroups,
	filterExtraChargesByTypeAndPeriod,
	getNextInvoice,
	getNextInvoiceOptions,
	numberParser,
	negativeNumberParser,
	isInvoicingFrequencyBigger,
	filterInvoicingFrequency,
	parseDatePickerValue,
	parseDatePickerChange,
} from '../../../utils/helpers/helper';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import Section from '../../reusables/layout/Section';
import Wizard from '../../reusables/template/Wizard';
import Loading from '../../reusables/template/Loading';
import Radio from '../../reusables/field/Radio';
import ImageUpload from '../../reusables/field/ImageUpload';
import MultiSelect from '../../reusables/element/MultiSelect';
import Wysiwyg from '../../reusables/field/Wysiwyg';
import Textarea from '../../reusables/field/Textarea';
import Portlet from '../../reusables/layout/Portlet';
import LengthInputGroup from '../../reusables/field/LengthInputGroup';
import ProductMarinaAdvancedPricingLongTerm from './ProductMarinaAdvancedPricingLongTerm';
import Toggle from '../../reusables/field/Toggle';
import DatePicker from '../../reusables/field/DatePicker';
import SpaceSelect from '../../reusables/element/SpaceSelect';

const ProductMarinaLongTermEdit = ({
	data,
	setIsValid,
	submit,
	isSubmitted,
	isLoading,
	isListsLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
	bookingTypeMarinas,
	productCategoryMarinas,
	taxCodes,
	policies,
	accounts,
	contracts,
	taxRates,
	itemTypes,
	enumBookingCalculations,
	enumBookingPeriods,
	enumDays,
	marinaUnits,
	bookingExtraCharges,
	ratePlans,
	invoiceFirstOptions,
	invoiceNextOptions,
	invoicingFrequencies,
	paymentTerms,
	customReservationStatuses,
	paymentSchedules,
	acctClasses,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const marinaModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.MARINA)
	);

	const [modifiedBookingCalculations, setModifiedBookingCalculations] = useState([]);

	const checkInTimeMoment = useRef(
		moment()
			.utcOffset(0)
			.hour(0)
			.minute(0)
	);

	const checkOutTimeMoment = useRef(
		moment()
			.utcOffset(0)
			.hour(0)
			.minute(0)
	);

	const refForTimePicker = useRef(null);

	const nextInvoiceRequired = (value, setValRes) => {
		if (invoicingFrequency && firstInvoice) return required(value, setValRes);

		return true;
	};

	const proratedFrequencyRequired = (value, setValRes) => {
		if (firstInvoice && firstInvoice.value === firstInvoiceOptions.PRO_RATE)
			return required(value, setValRes);
		return true;
	};

	const coTermRequired = (value, setValRes) => {
		if (nextInvoice && nextInvoice.value === nextInvoiceOptions.CO_TERM)
			return required(value, setValRes);
		return true;
	};

	// conditionally required for deferred fields.
	const deferredRequired = (value, setValRes) => {
		if (enableDeferredIncome) return required(value, setValRes);
		return true;
	};

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [productImages, setProductImages] = useField(data, 'productImages', onFormChange, [], []);

	const [price, priceOnChange, priceValRes, priceShowVal, setPriceShowVal] = useField(
		data,
		'price',
		onFormChange,
		[required, numeric],
		0,
		numberParser(true, 0)
	);

	const [
		itemType,
		itemTypeOnChange,
		itemTypeValRes,
		itemTypeShowVal,
		setItemTypeShowVal,
	] = useField(data, 'itemType', onFormChange, [required], null);

	const [account, accountOnChange, accountValRes, accountShowVal, setAccountShowVal] = useField(
		data,
		'account',
		onFormChange,
		[required],
		null
	);

	const [
		assetsAccount,
		assetsAccountOnChange,
		assetsAccountValRes,
		assetsAccountShowVal,
		setAssetsAccountShowVal,
	] = useField(data, 'assetsAccount', onFormChange, [required], null);

	const [
		incomeAccount,
		incomeAccountOnChange,
		incomeAccountValRes,
		incomeAccountShowVal,
		setIncomeAccountShowVal,
	] = useField(data, 'incomeAccount', onFormChange, [required], null);

	const [
		cogsAccount,
		cogsAccountOnChange,
		cogsAccountValRes,
		cogsAccountShowVal,
		setCogsAccountShowVal,
	] = useField(data, 'cogsAccount', onFormChange, [required], null);

	const [taxCode, taxCodeOnChange, taxCodeValRes, taxCodeShowVal, setTaxCodeShowVal] = useField(
		data,
		'taxCode',
		onFormChange,
		[required],
		null
	);

	const [accountingClass, accountingClassOnChange] = useField(
		data,
		'accountingClass',
		onFormChange,
		[],
		null
	);

	const [productCategory, productCategoryOnChange] = useField(
		data,
		'productCategory',
		onFormChange,
		[],
		null
	);

	const [
		description,
		descriptionOnChange,
		descriptionValRes,
		descriptionShowVal,
		setDescriptionShowVal,
	] = useField(data, 'description', onFormChange, [maxLength(4095)], '');

	const [productContracts, productContractsOnChange] = useField(
		data,
		'productContracts',
		onFormChange,
		[],
		[]
	);

	const [productCustomReservationStatuses, productCustomReservationStatusesOnChange] = useField(
		data,
		'productCustomReservationStatuses',
		onFormChange,
		[],
		[]
	);

	const [additionalTaxes, additionalTaxesOnChange] = useField(
		data,
		'additionalTaxes',
		onFormChange,
		[],
		[]
	);

	const [paymentSchedule, paymentScheduleOnChange] = useField(
		data,
		'paymentSchedule',
		onFormChange,
		[],
		null
	);

	const [
		bookingType,
		bookingTypeOnChange,
		bookingTypeValRes,
		bookingTypeShowVal,
		setBookingTypeShowVal,
	] = useField(data, 'bookingType', onFormChange, [required], null);

	const [bookingPeriod, bookingPeriodOnChange] = useField(
		data,
		'bookingPeriod',
		() => {},
		[],
		null
	);

	const [hideInResults, hideInResultsOnChange] = useField(
		data,
		'hideInResults',
		onFormChange,
		[],
		false
	);

	const [reservationCaption, reservationCaptionOnChange] = useField(
		data,
		'reservationCaption',
		onFormChange
	);

	const [confirmationBccEmails, confirmationBccEmailsOnChange] = useField(
		data,
		'confirmationBccEmails',
		onFormChange,
		[],
		null
	);

	const [longDescription, longDescriptionOnChange] = useField(
		data,
		'longDescription',
		onFormChange,
		[],
		''
	);

	const [
		minBookInAdvance,
		minBookInAdvanceOnChange,
		minBookInAdvanceValRes,
		minBookInAdvanceShowVal,
		setMinBookInAdvanceShowVal,
	] = useField(data, 'minBookInAdvance', onFormChange, [numeric], 0, numberParser(true, 0));

	const [
		maxBookInAdvance,
		maxBookInAdvanceOnChange,
		maxBookInAdvanceValRes,
		maxBookInAdvanceShowVal,
		setMaxBookInAdvanceShowVal,
	] = useField(data, 'maxBookInAdvance', onFormChange, [numeric], 0, numberParser(true, 0));

	const [advancedPricing, advancedPricingOnChange] = useField(
		data,
		'advancedPricing',
		onFormChange,
		[],
		false
	);

	const [policy, policyOnChange, policyValRes, policyShowVal, setPolicyShowVal] = useField(
		data,
		'policy',
		onFormChange,
		[],
		null
	);

	const [invoiceOffSetDays, invoiceOffSetDaysOnChange] = useField(
		data,
		'invoiceOffSetDays',
		onFormChange,
		[],
		null,
		negativeNumberParser
	);

	const [
		bookingCalculation,
		bookingCalculationOnChange,
		bookingCalculationValRes,
		bookingCalculationShowVal,
		setBookingCalculationShowVal,
	] = useField(data, 'bookingCalculation', onFormChange, [required], null);

	const [assignedUnitMarinas, assignedUnitMarinasOnChange] = useField(
		data,
		'unitMarinas',
		onFormChange,
		[],
		[]
	);

	const [sortOrder, sortOrderOnChange] = useField(
		data,
		'sortOrder',
		onFormChange,
		[],
		0,
		numberParser(false, 0)
	);

	const [extraCharges, setExtraCharges] = useState([]);

	const [selectedExtraCharges, setSelectedExtraCharges] = useState([]);

	const [, minLoaOnChange] = useField(data, 'minLoa', onFormChange, [], '', numberParser());

	const [minLoaFt, setMinLoaFt, minLoaIn, setMinLoaIn] = useFeet(
		data.minLoa,
		minLoaOnChange,
		isLoading
	);

	const [, maxLoaOnChange] = useField(data, 'maxLoa', onFormChange, [], '', numberParser());

	const [maxLoaFt, setMaxLoaFt, maxLoaIn, setMaxLoaIn] = useFeet(
		data.maxLoa,
		maxLoaOnChange,
		isLoading
	);

	const [, minBeamOnChange] = useField(data, 'minBeam', onFormChange, [], '', numberParser());

	const [minBeamFt, setMinBeamFt, minBeamIn, setMinBeamIn] = useFeet(
		data.minBeam,
		minBeamOnChange,
		isLoading
	);

	const [, maxBeamOnChange] = useField(data, 'maxBeam', onFormChange, [], '', numberParser());

	const [maxBeamFt, setMaxBeamFt, maxBeamIn, setMaxBeamIn] = useFeet(
		data.maxBeam,
		maxBeamOnChange,
		isLoading
	);

	const [minSqft, minSqftOnChange] = useField(
		data,
		'minSqft',
		onFormChange,
		[],
		'',
		numberParser()
	);

	const [maxSqft, maxSqftOnChange] = useField(
		data,
		'maxSquareFeet',
		onFormChange,
		[],
		'',
		numberParser()
	);

	const [, minHeightOnChange] = useField(data, 'minHeight', onFormChange, [], '', numberParser());

	const [minHeightFt, setMinHeightFt, minHeightIn, setMinHeightIn] = useFeet(
		data.minHeight,
		minHeightOnChange,
		isLoading
	);

	const [, maxHeightOnChange] = useField(data, 'maxHeight', onFormChange, [], '', numberParser());

	const [maxHeightFt, setMaxHeightFt, maxHeightIn, setMaxHeightIn] = useFeet(
		data.maxHeight,
		maxHeightOnChange,
		isLoading
	);

	const [minWeight, minWeightOnChange] = useField(
		data,
		'minWeight',
		onFormChange,
		[],
		'',
		numberParser()
	);

	const [maxWeight, maxWeightOnChange] = useField(
		data,
		'maxWeight',
		onFormChange,
		[],
		'',
		numberParser()
	);

	const [, minDraftOnChange] = useField(data, 'minDraft', onFormChange, [], '', numberParser());

	const [minDraftFt, setMinDraftFt, minDraftIn, setMinDraftIn] = useFeet(
		data.minDraft,
		minDraftOnChange,
		isLoading
	);

	const [, maxDraftOnChange] = useField(data, 'maxDraft', onFormChange, [], '', numberParser());

	const [maxDraftFt, setMaxDraftFt, maxDraftIn, setMaxDraftIn] = useFeet(
		data.maxDraft,
		maxDraftOnChange,
		isLoading
	);

	const [invoiceGenerationAutomatedBatch, invoiceGenerationAutomatedBatchOnChange] = useField(
		data,
		'invoiceGenerationAutomatedBatch',
		onFormChange,
		[],
		true
	);

	const [
		invoicingFrequency,
		invoicingFrequencyOnChange,
		invoicingFrequencyValRes,
		invoicingFrequencyShowVal,
		setInvoicingFrequencyShowVal,
	] = useField(data, 'invoicingFrequency', onFormChange, [required], null);

	const [paymentTerm, paymentTermOnChange] = useField(
		data,
		'paymentTerm',
		onFormChange,
		[],
		null
	);

	const [
		firstInvoice,
		firstInvoiceOnChange,
		firstInvoiceValRes,
		firstInvoiceShowVal,
		setFirstInvoiceShowVal,
	] = useField(data, 'firstInvoice', onFormChange, [required], null);

	const [
		nextInvoice,
		nextInvoiceOnChange,
		nextInvoiceValRes,
		nextInvoiceShowVal,
		setNextInvoiceShowVal,
		validateNextInvoice,
	] = useField(data, 'nextInvoice', onFormChange, [nextInvoiceRequired], null);

	const [
		proratedFrequency,
		proratedFrequencyOnChange,
		proratedFrequencyValRes,
		proratedFrequencyShowVal,
		setProratedFrequencyShowVal,
		validateProratedFrequency,
	] = useField(data, 'proratedFrequency', onFormChange, [proratedFrequencyRequired], null);

	const [
		coTermDate,
		coTermDateOnChange,
		coTermDateValRes,
		coTermDateShowVal,
		setCoTermDateShowVal,
		validateCoTermDate,
	] = useField(data, 'coTermDate', onFormChange, [coTermRequired], null);
	const [inactive, inactiveOnChange] = useField(data, 'inactive', onFormChange, [], false);

	const [
		onlineUrl,
		onlineUrlOnChange,
		onlineUrlValRes,
		onlineUrlShowVal,
		setOnlineUrlShowVal,
	] = useField(data, 'onlineUrl', onFormChange, [url]);

	const [enableDeferredIncome, enableDeferredIncomeOnChange] = useField(
		data,
		'enableDeferredIncome',
		onFormChange,
		[],
		false
	);

	const [
		deferredIncomeFrequency,
		deferredIncomeFrequencyOnChange,
		deferredIncomeFrequencyValRes,
		deferredIncomeFrequencyShowVal,
		setDeferredIncomeFrequencyShowVal,
		validateDeferredIncomeFrequency,
	] = useField(data, 'deferredIncomeFrequency', onFormChange, [deferredRequired], null);

	const [
		deferredIncomeSalesAccount,
		deferredIncomeSalesAccountOnChange,
		deferredIncomeSalesAccountValRes,
		deferredIncomeSalesAccountShowVal,
		setDeferredIncomeSalesAccountShowVal,
		validateDeferredIncomeSalesAccount,
	] = useField(data, 'deferredIncomeSalesAccount', onFormChange, [deferredRequired], null);

	const [isVirtual, isVirtualOnChange] = useField(data, 'isVirtual', onFormChange, [], false);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setItemTypeShowVal();
			setBookingTypeShowVal();
			setTaxCodeShowVal();
			setAccountShowVal();
			setPriceShowVal();
			setPolicyShowVal();
			setMinBookInAdvanceShowVal();
			setMaxBookInAdvanceShowVal();
			setDescriptionShowVal();
			setIncomeAccountShowVal();
			setCogsAccountShowVal();
			setAssetsAccountShowVal();
			setBookingCalculationShowVal();
			setOnlineUrlShowVal();
			setInvoicingFrequencyShowVal();
			setFirstInvoiceShowVal();
			setNextInvoiceShowVal();
			setProratedFrequencyShowVal();
			setCoTermDateShowVal();
			setDeferredIncomeFrequencyShowVal();
			setDeferredIncomeSalesAccountShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setItemTypeShowVal,
		setBookingTypeShowVal,
		setTaxCodeShowVal,
		setAccountShowVal,
		setPriceShowVal,
		setPolicyShowVal,
		setMinBookInAdvanceShowVal,
		setMaxBookInAdvanceShowVal,
		setDescriptionShowVal,
		setIncomeAccountShowVal,
		setCogsAccountShowVal,
		setAssetsAccountShowVal,
		setBookingCalculationShowVal,
		setOnlineUrlShowVal,
		setInvoicingFrequencyShowVal,
		setFirstInvoiceShowVal,
		setNextInvoiceShowVal,
		setProratedFrequencyShowVal,
		setCoTermDateShowVal,
		setDeferredIncomeFrequencyShowVal,
		setDeferredIncomeSalesAccountShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				bookingTypeValRes.isValid &&
				taxCodeValRes.isValid &&
				(itemType.value === enumItemTypes.INVENTORY
					? cogsAccountValRes.isValid &&
					  incomeAccountValRes.isValid &&
					  assetsAccountValRes.isValid
					: accountValRes.isValid) &&
				priceValRes.isValid &&
				minBookInAdvanceValRes.isValid &&
				maxBookInAdvanceValRes.isValid &&
				descriptionValRes.isValid &&
				invoicingFrequencyValRes.isValid &&
				firstInvoiceValRes.isValid &&
				bookingCalculationValRes.isValid &&
				onlineUrlValRes.isValid &&
				deferredIncomeFrequencyValRes.isValid &&
				deferredIncomeSalesAccountValRes.isValid &&
				proratedFrequencyValRes.isValid &&
				coTermDateValRes.isValid
		);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [
		nameValRes.isValid,
		bookingTypeValRes.isValid,
		taxCodeValRes.isValid,
		accountValRes.isValid,
		priceValRes.isValid,
		policyValRes.isValid,
		minBookInAdvanceValRes.isValid,
		maxBookInAdvanceValRes.isValid,
		descriptionValRes.isValid,
		cogsAccountValRes.isValid,
		assetsAccountValRes.isValid,
		incomeAccountValRes.isValid,
		invoicingFrequencyValRes.isValid,
		firstInvoiceValRes.isValid,
		bookingCalculationValRes.isValid,
		onlineUrlValRes.isValid,
		deferredIncomeFrequencyValRes.isValid,
		deferredIncomeSalesAccountValRes.isValid,
		proratedFrequencyValRes.isValid,
		coTermDateValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.marina.default.text, path: pages.marina.dashboard.path },
			{ title: pages.marina.products.text, path: pages.marina.products.path },
			{ title: name || `New ${pages.marina.products.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.marina.products.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		data.module = marinaModule.current;
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	useEffect(() => {
		if (!isListsLoading) {
			bookingPeriodOnChange({
				target: {
					value:
						enumBookingPeriods.find(ebp => ebp.value === bookingPeriods.LONG_TERM) ||
						null,
				},
			});

			setModifiedBookingCalculations(
				enumBookingCalculations.map(ebc =>
					bookingCalculationConverter(ebc, bookingPeriods.LONG_TERM)
				)
			);
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isListsLoading]);

	useEffect(() => {
		if (!isLoading) {
			if (data.checkInTime && data.checkInTime !== '')
				checkInTimeMoment.current = moment(data.checkInTime).utc(false);

			if (data.checkOutTime && data.checkOutTime !== '')
				checkOutTimeMoment.current = moment(data.checkOutTime).utc(false);

			if (data.productExtraCharges)
				setSelectedExtraCharges(
					data.productExtraCharges
						.filter(p => p.productCategory)
						.map(p => p.productCategory)
						.filter(
							(pc, index, self) => self.findIndex(_pc => _pc.id === pc.id) === index
						)
						.concat(data.productExtraCharges.filter(p => !p.productCategory))
				);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		setExtraCharges(extraChargesToGroups(bookingExtraCharges, bookingType, bookingPeriod));
	}, [bookingExtraCharges, bookingType, bookingPeriod]);

	useEffect(() => {
		validateProratedFrequency(proratedFrequency);
		validateNextInvoice(nextInvoice);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstInvoice]);

	useEffect(() => {
		validateCoTermDate(coTermDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextInvoice]);

	useEffect(() => {
		// re-validate deferred field on enableDeferredIncome change.
		validateDeferredIncomeFrequency(deferredIncomeFrequency);
		validateDeferredIncomeSalesAccount(deferredIncomeSalesAccount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enableDeferredIncome]);

	return (
		<Wizard onSubmit={submit} submitButtonAttr={submitButtonAttr} isLoading={isLoading}>
			<Wizard.Item
				title='General'
				description='General Information'
				isValid={
					isSubmitted
						? nameValRes.isValid &&
						  itemTypeValRes.isValid &&
						  bookingTypeValRes.isValid &&
						  taxCodeValRes.isValid &&
						  (itemType.value === enumItemTypes.INVENTORY
								? cogsAccountValRes.isValid &&
								  incomeAccountValRes.isValid &&
								  assetsAccountValRes.isValid
								: accountValRes.isValid) &&
						  priceValRes.isValid &&
						  bookingCalculationValRes.isValid
						: true
				}>
				<Section>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField name='id' label='Item id' id={data.id} colLg={6}>
									<Input
										type='text'
										placeholder='Auto generated'
										value={data.productId || ''}
										onChange={() => {}}
										disabled
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='name'
									label='Name'
									id={data.id}
									valRes={nameValRes}
									showValidation={nameShowVal}
									colLg={6}>
									<Input
										type='text'
										placeholder='Name (Required)'
										value={name}
										onChange={nameOnChange}
										onBlur={setNameShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='itemType'
									label='Item Type'
									id={data.id}
									valRes={itemTypeValRes}
									showValidation={itemTypeShowVal}
									colLg={6}
									colXl={3}>
									<Selects
										options={itemTypes.filter(it => !it.isSystem)}
										placeholder='Item Type (Required)'
										value={itemType}
										onChange={({ target }) => {
											itemTypeOnChange({ target });
											accountOnChange({
												target: { name: 'account', value: {} },
											});
											incomeAccountOnChange({
												target: {
													name: 'incomeAccount',
													value: {},
												},
											});
											cogsAccountOnChange({
												target: { name: 'cogsAccount', value: {} },
											});
											assetsAccountOnChange({
												target: {
													name: 'assetsAccount',
													value: {},
												},
											});
										}}
										onBlur={setItemTypeShowVal}
										displayKey='value'
										disabled={data.id !== 0}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									ref={refForTimePicker}
									name='bookingType'
									label='Booking Type'
									id={data.id}
									valRes={bookingTypeValRes}
									showValidation={bookingTypeShowVal}
									colLg={6}
									colXl={3}>
									<Selects
										options={bookingTypeMarinas.filter(bt => bt.longTerm)}
										placeholder='Booking Type'
										value={bookingType}
										onChange={e => {
											bookingTypeOnChange(e);
											assignedUnitMarinasOnChange({ target: { value: [] } });
										}}
										onBlur={setBookingTypeShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='bookingPeriod'
									label='Booking Period'
									id={data.id}
									colLg={6}
									colXl={3}>
									<Selects
										options={enumBookingPeriods}
										placeholder='Booking Period'
										value={bookingPeriod}
										onChange={() => {}}
										displayKey='value'
										disabled
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='productCategory'
									label='Product Category'
									id={data.id}
									colLg={6}
									colXl={3}>
									<Selects
										options={productCategoryMarinas}
										placeholder='Product Category'
										value={productCategory}
										onChange={productCategoryOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='description'
									label='Description'
									id={data.id}
									colXl={12}
									showValidation={descriptionShowVal}
									valRes={descriptionValRes}>
									<Textarea
										placeholder='Description'
										value={description}
										onChange={descriptionOnChange}
										textRow={4}
										onBlur={setDescriptionShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='productImages'
									label='Images'
									description='.png, .jpg or .jpeg only'
									id={data.id}
									colXl={12}>
									<ImageUpload
										media={productImages}
										setMedia={image =>
											setProductImages({
												target: {
													name: 'productImages',
													value: image,
												},
											})
										}
										multiple
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='longDescription'
									label='Description displayed in search results'
									id={data.id}
									col={12}>
									<Wysiwyg
										value={longDescription}
										onChange={longDescriptionOnChange}
									/>
								</FormField>
							</Loading>
							<div className='sdms-heading sdms-heading--md col-12'>Accounting</div>
							<Loading isLoading={isLoading}>
								<FormField
									name='taxCode'
									label='Tax Code'
									id={data.id}
									valRes={taxCodeValRes}
									showValidation={taxCodeShowVal}
									colLg={6}>
									<Selects
										options={taxCodes}
										placeholder='Tax code'
										value={taxCode}
										onChange={taxCodeOnChange}
										onBlur={setTaxCodeShowVal}
									/>
								</FormField>
							</Loading>
							{itemType && itemType.value === enumItemTypes.INVENTORY ? (
								<>
									<Loading isLoading={isLoading}>
										<FormField
											name='incomeAccount'
											label='Income Account'
											id={data.id}
											valRes={incomeAccountValRes}
											showValidation={incomeAccountShowVal}
											colLg={6}>
											<Selects
												options={accounts}
												placeholder='Income Account (Required)'
												value={incomeAccount}
												onChange={incomeAccountOnChange}
												onBlur={setIncomeAccountShowVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='cogsAccount'
											label='COGS Account'
											id={data.id}
											valRes={cogsAccountValRes}
											showValidation={cogsAccountShowVal}
											colLg={6}>
											<Selects
												options={accounts}
												placeholder='COGS Account (Required)'
												value={cogsAccount}
												onChange={cogsAccountOnChange}
												onBlur={setCogsAccountShowVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='assetsAccount'
											label='Assets Account'
											id={data.id}
											valRes={assetsAccountValRes}
											showValidation={assetsAccountShowVal}
											colLg={6}>
											<Selects
												options={accounts}
												placeholder='Assets Account (Required)'
												value={assetsAccount}
												onChange={assetsAccountOnChange}
												onBlur={setAssetsAccountShowVal}
											/>
										</FormField>
									</Loading>
								</>
							) : (
								<Loading isLoading={isLoading}>
									<FormField
										name='account'
										label='Account'
										id={data.id}
										valRes={accountValRes}
										showValidation={accountShowVal}
										colLg={6}>
										<Selects
											options={accounts}
											placeholder='Account (Required)'
											value={account}
											onChange={accountOnChange}
											onBlur={setAccountShowVal}
										/>
									</FormField>
								</Loading>
							)}
							<Loading isLoading={isLoading}>
								<FormField
									name='accountingClass'
									label='Accounting Class'
									id={data.id}
									colMd={6}>
									<Selects
										options={acctClasses}
										placeholder='Accounting class'
										value={accountingClass}
										onChange={accountingClassOnChange}
									/>
								</FormField>
							</Loading>
							<div className='sdms-heading sdms-heading--md col-12'>Base Pricing</div>
							<Loading isLoading={isLoading}>
								<FormField
									name='price'
									label='Price'
									id={data.id}
									valRes={priceValRes}
									showValidation={priceShowVal}
									colLg={6}
									colXl={3}>
									<Input
										type='text'
										placeholder='Price'
										value={price}
										onChange={priceOnChange}
										onBlur={setPriceShowVal}
										pattern={process.env.REACT_APP_PRICE_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='bookingCalculation'
									label='Price Calculation Method'
									id={data.id}
									colLg={6}
									colXl={3}
									showValidation={bookingCalculationShowVal}
									valRes={bookingCalculationValRes}>
									<Selects
										options={modifiedBookingCalculations.filter(
											mbc =>
												mbc.value === bookingCalculations.PER_PERIOD ||
												mbc.value === bookingCalculations.PER_MONTH
										)}
										placeholder='Price Calculation Method (Required)'
										value={
											bookingCalculation
												? bookingCalculationConverter(
														bookingCalculation,
														bookingPeriods.LONG_TERM
												  )
												: null
										}
										onChange={bookingCalculationOnChange}
										displayKey='label'
										onBlur={setBookingCalculationShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='additionalTaxes'
									label='Additional Taxes'
									id={data.id}
									colLg={6}
									colXl={3}>
									<Selects
										options={taxRates.filter(
											tr =>
												tr.id !==
													userContext.data.selectedOutlet.settings.taxRate
														.id && !tr.isGroup
										)}
										placeholder='Select Tax Rate'
										value={additionalTaxes}
										onChange={additionalTaxesOnChange}
										multiple
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='paymentSchedule'
									label='Payment Schedule'
									id={data.id}
									colLg={6}
									colXl={3}>
									<Selects
										options={paymentSchedules}
										placeholder='Select Payment Schedule'
										value={paymentSchedule}
										onChange={paymentScheduleOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
			<Wizard.Item title='Units' description='Assign Units' icon={pages.pos.registers.icon2}>
				<Section>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField name='spaces' label='Spaces:' id={data.id} col={12}>
									<SpaceSelect
										spaces={marinaUnits}
										bookingType={bookingType}
										value={assignedUnitMarinas}
										onChange={assignedUnitMarinasOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
			<Wizard.Item
				title='Booking Setup'
				description='Booking Rules'
				icon={pages.systemSettings.users.icon}
				isValid={
					isSubmitted
						? minBookInAdvanceValRes.isValid && maxBookInAdvanceValRes.isValid
						: true
				}>
				<Section>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField name='isVirtual' label='Virtual' id={data.id} colMd={4}>
									<Toggle
										value={isVirtual}
										onChange={isVirtualOnChange}
										disabled={data.id !== 0}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='hideInResults'
									label='Hide in search results'
									id={data.id}
									colMd={4}>
									<Toggle
										spaceLess
										value={hideInResults}
										onChange={hideInResultsOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField name='inactive' label='Inactive' id={data.id} colMd={4}>
									<Toggle
										spaceLess
										value={inactive}
										onChange={inactiveOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='onlineUrl'
									label='Online Url'
									id={data.id}
									colLg={6}
									showValidation={onlineUrlShowVal}
									valRes={onlineUrlValRes}>
									<Input
										type='text'
										placeholder='Online Url'
										value={onlineUrl}
										onChange={onlineUrlOnChange}
										onBlur={setOnlineUrlShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='confirmationBccEmails'
									label='Confirmation BCC Emails'
									id={data.id}
									colLg={6}>
									<Input
										type='text'
										placeholder='Confirmation BCC EMails'
										value={confirmationBccEmails}
										onChange={confirmationBccEmailsOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='reservationCaption'
									label='Reservation Caption'
									id={data.id}
									colLg={6}>
									<Input
										type='text'
										placeholder='Reservation Caption'
										value={reservationCaption}
										onChange={reservationCaptionOnChange}
									/>
								</FormField>
							</Loading>
							<div className='sdms-heading sdms-heading--md col-12'>
								Default Policies
							</div>
							<Loading isLoading={isLoading}>
								<FormField
									name='policy'
									label='Policy'
									id={data.id}
									valRes={policyValRes}
									showValidation={policyShowVal}
									colLg={6}>
									<Selects
										options={policies.filter(
											p => p.bookingPeriod.value === bookingPeriods.LONG_TERM
										)}
										placeholder='Deposit policy'
										value={policy}
										onChange={policyOnChange}
										onBlur={setPolicyShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='invoiceOffSetDays'
									label='Invoice Offset Days'
									id={data.id}
									colMd={6}
									colLg={3}>
									<Input
										onChange={invoiceOffSetDaysOnChange}
										value={invoiceOffSetDays}
									/>
								</FormField>
							</Loading>
							<div className='sdms-heading sdms-heading--md col-12'>
								Booking Constraints
							</div>
							<Loading isLoading={isLoading}>
								<FormField
									name='minBookInAdvance'
									label='Minimum Book in Advance'
									description='in days'
									id={data.id}
									valRes={minBookInAdvanceValRes}
									showValidation={minBookInAdvanceShowVal}
									colLg={6}
									colXl={3}>
									<Input
										type='text'
										placeholder='Minimum Book in Advance duration'
										value={minBookInAdvance}
										onChange={minBookInAdvanceOnChange}
										onBlur={setMinBookInAdvanceShowVal}
										append='Days'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='maxBookInAdvance'
									label='Maximum Book in Advance'
									description='in days'
									id={data.id}
									valRes={maxBookInAdvanceValRes}
									showValidation={maxBookInAdvanceShowVal}
									colLg={6}
									colXl={3}>
									<Input
										type='text'
										placeholder='Maximum Book in Advance duration'
										value={maxBookInAdvance}
										onChange={maxBookInAdvanceOnChange}
										onBlur={setMaxBookInAdvanceShowVal}
										append='Days'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='advancedPricing'
									label='Pricing modal'
									id={data.id}
									colXl={6}>
									<Radio.Container isInline>
										<Radio
											checked={!advancedPricing}
											id='yes'
											name='advancedPricing'
											content='Base Pricing/ No Constraints'
											className='sdms-radio--primary'
											onChange={() =>
												advancedPricingOnChange({
													target: { value: false },
												})
											}
										/>
										<Radio
											checked={advancedPricing}
											id='no'
											name='advancedPricing'
											content='Advanced Pricing/ Constraints'
											className='sdms-radio--primary'
											onChange={() =>
												advancedPricingOnChange({
													target: { value: true },
												})
											}
										/>
									</Radio.Container>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='productContracts'
									label='Product Contracts'
									id={data.id}
									colLg={6}>
									<Selects
										options={contracts}
										titleProp='Booking contract'
										itemsCol={6}
										value={productContracts}
										onChange={productContractsOnChange}
										multiple
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='productCustomReservationStatuses'
									label='Custom Reservation Status'
									id={data.id}
									colLg={6}>
									<Selects
										options={customReservationStatuses}
										itemsCol={6}
										value={productCustomReservationStatuses}
										onChange={productCustomReservationStatusesOnChange}
										multiple
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='extraCharges'
									label='Extras / Price Adjustments:'
									id={data.id}
									col={12}>
									<MultiSelect
										data={extraCharges}
										titleProp='name'
										value={selectedExtraCharges}
										onChange={e => {
											setSelectedExtraCharges(e.target.value);
											onFormChange();
											data.productExtraCharges = [];
											e.target.value.forEach(ec => {
												if (
													ec['@type'] === 'SdmsProductCategoryExtraCharge'
												) {
													data.productExtraCharges.push(
														...filterExtraChargesByTypeAndPeriod(
															bookingExtraCharges,
															bookingType,
															bookingPeriod
														).filter(
															p =>
																p.productCategory &&
																p.productCategory.id === ec.id
														)
													);
												} else data.productExtraCharges.push(ec);
											});
										}}
										name='extraFees'
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Vessel Constraints'>
					<Section.Body>
						<FormGroup row>
							<div className='col-md-6'>
								<Portlet border className='sdms-portlet--head-noborder'>
									<Portlet.Head>
										<Portlet.HeadLabelTitle>
											Length Overall
										</Portlet.HeadLabelTitle>
									</Portlet.Head>
									<Portlet.Body>
										<div className='row'>
											<Loading isLoading={isLoading}>
												<FormField
													name='minLengthOverAllFt'
													label='Minimum Length Overall'
													id={data.id}
													colLg={6}
													inFormDesign={false}>
													<LengthInputGroup
														ft={minLoaFt}
														ftOnChange={setMinLoaFt}
														inch={minLoaIn}
														inchOnChange={setMinLoaIn}
														placeHolder='Minimum Length Overall'
													/>
												</FormField>
											</Loading>
											<Loading isLoading={isLoading}>
												<FormField
													name='maxLengthOverAllFt'
													label='Maximum Length Overall'
													id={data.id}
													colLg={6}
													inFormDesign={false}>
													<LengthInputGroup
														ft={maxLoaFt}
														ftOnChange={setMaxLoaFt}
														inch={maxLoaIn}
														inchOnChange={setMaxLoaIn}
														placeHolder='Maximum Length Overall'
													/>
												</FormField>
											</Loading>
										</div>
									</Portlet.Body>
								</Portlet>
							</div>
							<div className='col-md-6'>
								<Portlet border className='sdms-portlet--head-noborder'>
									<Portlet.Head>
										<Portlet.HeadLabelTitle>Beam</Portlet.HeadLabelTitle>
									</Portlet.Head>
									<Portlet.Body>
										<div className='row'>
											<Loading isLoading={isLoading}>
												<FormField
													name='minBeamFt'
													label='Minimum Beam'
													id={data.id}
													colLg={6}
													inFormDesign={false}>
													<LengthInputGroup
														ft={minBeamFt}
														ftOnChange={setMinBeamFt}
														inch={minBeamIn}
														inchOnChange={setMinBeamIn}
														placeHolder='Minimum Beam'
													/>
												</FormField>
											</Loading>
											<Loading isLoading={isLoading}>
												<FormField
													name='maxBeamFt'
													label='Maximum Beam'
													id={data.id}
													colLg={6}
													inFormDesign={false}>
													<LengthInputGroup
														ft={maxBeamFt}
														ftOnChange={setMaxBeamFt}
														inch={maxBeamIn}
														inchOnChange={setMaxBeamIn}
														placeHolder='Maximum Beam'
													/>
												</FormField>
											</Loading>
										</div>
									</Portlet.Body>
								</Portlet>
							</div>
							<div className='col-md-6'>
								<Portlet border className='sdms-portlet--head-noborder'>
									<Portlet.Head>
										<Portlet.HeadLabelTitle>Height</Portlet.HeadLabelTitle>
									</Portlet.Head>
									<Portlet.Body>
										<div className='row'>
											<Loading isLoading={isLoading}>
												<FormField
													name='minHeightFt'
													label='Minimum Height'
													id={data.id}
													colLg={6}
													inFormDesign={false}>
													<LengthInputGroup
														ft={minHeightFt}
														ftOnChange={setMinHeightFt}
														inch={minHeightIn}
														inchOnChange={setMinHeightIn}
														placeHolder='Minimum Height'
													/>
												</FormField>
											</Loading>
											<Loading isLoading={isLoading}>
												<FormField
													name='maxHeightFt'
													label='Maximum Height'
													id={data.id}
													colLg={6}
													inFormDesign={false}>
													<LengthInputGroup
														ft={maxHeightFt}
														ftOnChange={setMaxHeightFt}
														inch={maxHeightIn}
														inchOnChange={setMaxHeightIn}
														placeHolder='Maximum Height'
													/>
												</FormField>
											</Loading>
										</div>
									</Portlet.Body>
								</Portlet>
							</div>
							<div className='col-md-6'>
								<Portlet border className='sdms-portlet--head-noborder'>
									<Portlet.Head>
										<Portlet.HeadLabelTitle>Weight</Portlet.HeadLabelTitle>
									</Portlet.Head>
									<Portlet.Body>
										<div className='row'>
											<Loading isLoading={isLoading}>
												<FormField
													name='minWeight'
													label='Minimum Weight'
													id={data.id}
													colLg={6}
													inFormDesign={false}>
													<Input
														type='number'
														withOutSpin
														min={0}
														placeholder='Minimum Weight'
														value={minWeight}
														onChange={minWeightOnChange}
														pattern={
															process.env.REACT_APP_INTEGER_PATTERN
														}
														append='lbs'
													/>
												</FormField>
											</Loading>
											<Loading isLoading={isLoading}>
												<FormField
													name='maxWeight'
													label='Maximum Weight'
													id={data.id}
													colLg={6}
													inFormDesign={false}>
													<Input
														type='number'
														withOutSpin
														min={0}
														placeholder='Maximum Weight'
														value={maxWeight}
														onChange={maxWeightOnChange}
														pattern={
															process.env.REACT_APP_INTEGER_PATTERN
														}
														append='lbs'
													/>
												</FormField>
											</Loading>
										</div>
									</Portlet.Body>
								</Portlet>
							</div>
							<div className='col-md-6'>
								<Portlet border className='sdms-portlet--head-noborder'>
									<Portlet.Head>
										<Portlet.HeadLabelTitle>Draft</Portlet.HeadLabelTitle>
									</Portlet.Head>
									<Portlet.Body>
										<div className='row'>
											<Loading isLoading={isLoading}>
												<FormField
													name='minDraftFt'
													label='Minimum Draft'
													id={data.id}
													colLg={6}
													inFormDesign={false}>
													<LengthInputGroup
														ft={minDraftFt}
														ftOnChange={setMinDraftFt}
														inch={minDraftIn}
														inchOnChange={setMinDraftIn}
														placeHolder='Minimum Draft'
													/>
												</FormField>
											</Loading>
											<Loading isLoading={isLoading}>
												<FormField
													name='maxDraftFt'
													label='Maximum Draft'
													id={data.id}
													colLg={6}
													inFormDesign={false}>
													<LengthInputGroup
														ft={maxDraftFt}
														ftOnChange={setMaxDraftFt}
														inch={maxDraftIn}
														inchOnChange={setMaxDraftIn}
														placeHolder='Maximum Draft'
													/>
												</FormField>
											</Loading>
										</div>
									</Portlet.Body>
								</Portlet>
							</div>
							<div className='col-md-6'>
								<Portlet border className='sdms-portlet--head-noborder'>
									<Portlet.Head>
										<Portlet.HeadLabelTitle>Square Feet</Portlet.HeadLabelTitle>
									</Portlet.Head>
									<Portlet.Body>
										<div className='row'>
											<Loading isLoading={isLoading}>
												<FormField
													name='minSquareFeet'
													label='Minimum Square Feet'
													id={data.id}
													colLg={6}
													inFormDesign={false}>
													<Input
														type='number'
														withOutSpin
														min={0}
														placeholder='Minimum Square Feet'
														value={minSqft}
														onChange={minSqftOnChange}
														pattern={
															process.env.REACT_APP_INTEGER_PATTERN
														}
														append='sqft'
													/>
												</FormField>
											</Loading>
											<Loading isLoading={isLoading}>
												<FormField
													name='maxSquareFeet'
													label='Maximum Square Feet'
													id={data.id}
													colLg={6}
													inFormDesign={false}>
													<Input
														type='number'
														withOutSpin
														min={0}
														placeholder='Maximum Square Feet'
														value={maxSqft}
														onChange={maxSqftOnChange}
														pattern={
															process.env.REACT_APP_INTEGER_PATTERN
														}
														append='sqft'
													/>
												</FormField>
											</Loading>
										</div>
									</Portlet.Body>
								</Portlet>
							</div>
							<Loading isLoading={isLoading}>
								<FormField
									name='sortOrder'
									label='Sort order'
									id={data.id}
									colLg={3}>
									<Input
										type='number'
										placeholder='Sort Order'
										value={sortOrder}
										onChange={sortOrderOnChange}
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Invoice Settings'>
					<Section.Body>
						<FormGroup row>
							<Loading isLoading={isLoading}>
								<FormField
									name='invoiceGeneration'
									label='Invoice Generation'
									id={data.id}
									colXl={3}>
									<Radio.Container isInline>
										<Radio
											checked={invoiceGenerationAutomatedBatch}
											id='invoiceGenerationYes'
											name='automatedBatch'
											content='Automatic'
											className='sdms-radio--primary'
											onChange={() =>
												invoiceGenerationAutomatedBatchOnChange({
													target: { value: true },
												})
											}
										/>
										<Radio
											checked={!invoiceGenerationAutomatedBatch}
											id='invoiceGenerationNo'
											name='manual'
											content='Manual'
											className='sdms-radio--primary'
											onChange={() =>
												invoiceGenerationAutomatedBatchOnChange({
													target: { value: false },
												})
											}
										/>
									</Radio.Container>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='frequency'
									label='Frequency'
									id={data.id}
									valRes={invoicingFrequencyValRes}
									showValidation={invoicingFrequencyShowVal}
									colMd={3}>
									<Selects
										options={filterInvoicingFrequency(
											invoicingFrequencies,
											bookingPeriods.LONG_TERM,
											true,
											null,
											true
										)}
										placeholder='Invoicing Frequency (Required)'
										value={invoicingFrequency}
										onChange={e => {
											invoicingFrequencyOnChange(e);
											nextInvoiceOnChange({
												target: {
													value: getNextInvoice(
														e.target.value,
														firstInvoice,
														invoiceNextOptions
													),
												},
											});

											// check deferred income frequency against to invoicing frequency.
											if (
												e.target.value &&
												deferredIncomeFrequency &&
												isInvoicingFrequencyBigger(
													deferredIncomeFrequency.value,
													e.target.value.value
												)
											)
												deferredIncomeFrequencyOnChange({
													target: { value: null },
												});
										}}
										onBlur={setInvoicingFrequencyShowVal}
										displayKey='value'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='paymentTerm'
									label='Payment Term'
									id={data.id}
									colMd={3}>
									<Selects
										options={paymentTerms}
										placeholder='Payment Term'
										value={paymentTerm}
										onChange={paymentTermOnChange}
										displayKey='name'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='firstInvoice'
									label='First Invoice'
									id={data.id}
									valRes={firstInvoiceValRes}
									showValidation={firstInvoiceShowVal}
									colMd={3}>
									<Selects
										options={invoiceFirstOptions}
										placeholder='First Invoice (Required)'
										value={firstInvoice}
										onChange={e => {
											firstInvoiceOnChange(e);

											nextInvoiceOnChange({
												target: {
													value: getNextInvoice(
														invoicingFrequency,
														e.target.value,
														invoiceNextOptions
													),
												},
											});

											proratedFrequencyOnChange({
												target: { value: null },
											});

											coTermDateOnChange({
												target: { value: null },
											});
										}}
										onBlur={setFirstInvoiceShowVal}
										displayKey='value'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='nextInvoice'
									label='Next Invoice'
									id={data.id}
									colMd={3}
									valRes={nextInvoiceValRes}
									showValidation={nextInvoiceShowVal}>
									<Selects
										options={getNextInvoiceOptions(
											bookingPeriods.LONG_TERM,
											invoicingFrequency,
											firstInvoice,
											invoiceNextOptions
										)}
										placeholder='Next Invoice (Required)'
										value={nextInvoice}
										onChange={e => {
											nextInvoiceOnChange(e);

											if (
												!e.target.value ||
												e.target.value.value !== nextInvoiceOptions.CO_TERM
											) {
												coTermDateOnChange({
													target: { value: null },
												});
											}
										}}
										onBlur={setNextInvoiceShowVal}
										displayKey='value'
										disabled={!firstInvoice}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='proratedFrequency'
									label='Prorated Frequency'
									id={data.id}
									colMd={3}
									valRes={proratedFrequencyValRes}
									showValidation={proratedFrequencyShowVal}>
									<Selects
										placeholder={`Prorated Frequency${
											proratedFrequencyValRes.isValid ? '' : ' (Required)'
										}`}
										value={proratedFrequency}
										options={filterInvoicingFrequency(
											invoicingFrequencies,
											bookingPeriods.LONG_TERM,
											false,
											invoicingFrequency?.value
										)}
										onChange={proratedFrequencyOnChange}
										onBlur={setProratedFrequencyShowVal}
										displayKey='value'
										disabled={
											firstInvoice?.value !== firstInvoiceOptions.PRO_RATE
										}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='coTermDate'
									label='Co-Term Date'
									id={data.id}
									colMd={3}
									valRes={coTermDateValRes}
									showValidation={coTermDateShowVal}>
									<DatePicker
										id='coTermDate'
										type='calendar'
										placeholder={`Co-Term Date${
											coTermDateValRes.isValid ? '' : ' (Required)'
										}`}
										value={parseDatePickerValue(coTermDate)}
										onChange={e =>
											coTermDateOnChange({
												target: {
													value: parseDatePickerChange(
														e.target.value,
														coTermDate
													),
												},
											})
										}
										onBlur={setCoTermDateShowVal}
										formatter={dateFormatterWithoutYear}
										disabled={nextInvoice?.value !== nextInvoiceOptions.CO_TERM}
										place='top'
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Deferred Income'>
					<Section.Body>
						<FormGroup row>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableDeferredIncome'
									label='Enable Deferred Income'
									id={data.id}
									colMd={3}>
									<Toggle
										spaceLess
										value={enableDeferredIncome}
										onChange={enableDeferredIncomeOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='deferredIncomeFrequency'
									label='Frequency'
									id={data.id}
									valRes={deferredIncomeFrequencyValRes}
									showValidation={deferredIncomeFrequencyShowVal}
									colMd={3}>
									<Selects
										options={filterInvoicingFrequency(
											invoicingFrequencies,
											bookingPeriods.LONG_TERM,
											false,
											invoicingFrequency ? invoicingFrequency.value : null
										)}
										placeholder={`Frequency${
											enableDeferredIncome ? ' (Required)' : ''
										}`}
										value={deferredIncomeFrequency}
										onChange={deferredIncomeFrequencyOnChange}
										onBlur={setDeferredIncomeFrequencyShowVal}
										displayKey='value'
										disabled={!enableDeferredIncome}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='deferredIncomeSalesAccount'
									label='Sales Income Account'
									id={data.id}
									valRes={deferredIncomeSalesAccountValRes}
									showValidation={deferredIncomeSalesAccountShowVal}
									colMd={3}>
									<Selects
										options={accounts}
										placeholder={`Sales Income Account${
											enableDeferredIncome ? ' (Required)' : ''
										}`}
										value={deferredIncomeSalesAccount}
										onChange={deferredIncomeSalesAccountOnChange}
										onBlur={setDeferredIncomeSalesAccountShowVal}
										disabled={!enableDeferredIncome}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
			<Wizard.Item
				title='Advanced Pricing & Constraints'
				description='Assign Pricing & Constraints per Date'
				icon={pages.systemSettings.users.icon}
				disable={!advancedPricing}>
				<ProductMarinaAdvancedPricingLongTerm
					ratePlans={ratePlans}
					product={data}
					enumDays={enumDays}
					onFormChange={onFormChange}
				/>
			</Wizard.Item>
		</Wizard>
	);
};
ProductMarinaLongTermEdit.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		bookingType: PropTypes.object,
		productCategory: PropTypes.object,
		description: PropTypes.string,
		longDescription: PropTypes.string,
		taxCode: PropTypes.object,
		account: PropTypes.object,
		price: PropTypes.number,
		additionalTaxes: PropTypes.arrayOf(PropTypes.object),
		checkInDays: PropTypes.arrayOf(PropTypes.object),
		checkOutDays: PropTypes.arrayOf(PropTypes.object),
		checkInTime: PropTypes.string,
		checkOutTime: PropTypes.string,
		bufferHour: PropTypes.number,
		bufferMinute: PropTypes.number,
		policy: PropTypes.object,
		invoiceOffSetDays: PropTypes.number,
		minDuration: PropTypes.number,
		maxDuration: PropTypes.number,
		minBookInAdvance: PropTypes.number,
		maxBookInAdvance: PropTypes.number,
		pricingModel: PropTypes.object,
		productContracts: PropTypes.arrayOf(PropTypes.object),
		reservationCaption: PropTypes.string,
		productExtraCharges: PropTypes.arrayOf(PropTypes.object),
		advancedPricing: PropTypes.bool,
		newTime: PropTypes.string,
		module: PropTypes.object,
		productId: PropTypes.string,
		itemType: PropTypes.object,
		bookingPeriod: PropTypes.object,
		bookingCalculation: PropTypes.object,
		minLoa: PropTypes.number,
		maxLoa: PropTypes.number,
		minBeam: PropTypes.number,
		maxBeam: PropTypes.number,
		minHeight: PropTypes.number,
		maxHeight: PropTypes.number,
		minDraft: PropTypes.number,
		maxDraft: PropTypes.number,
		minWeight: PropTypes.number,
		maxWeight: PropTypes.number,
		minSquareFeet: PropTypes.number,
		maxSquareFeet: PropTypes.number,
	}),
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	isListsLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
	bookingTypeMarinas: PropTypes.arrayOf(PropTypes.object),
	productCategoryMarinas: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	policies: PropTypes.arrayOf(PropTypes.object),
	accounts: PropTypes.arrayOf(PropTypes.object),
	contracts: PropTypes.arrayOf(PropTypes.object),
	taxRates: PropTypes.arrayOf(PropTypes.object),
	bookingExtraCharges: PropTypes.arrayOf(PropTypes.object),
	enumBookingCalculations: PropTypes.arrayOf(PropTypes.object),
	itemTypes: PropTypes.arrayOf(PropTypes.object),
	enumBookingPeriods: PropTypes.arrayOf(PropTypes.object),
	marinaUnits: PropTypes.arrayOf(PropTypes.object),
	enumDays: PropTypes.arrayOf(PropTypes.object),
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	invoiceFirstOptions: PropTypes.arrayOf(PropTypes.object),
	invoiceNextOptions: PropTypes.arrayOf(PropTypes.object),
	invoicingFrequencies: PropTypes.arrayOf(PropTypes.object),
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	customReservationStatuses: PropTypes.arrayOf(PropTypes.object),
	paymentSchedules: PropTypes.arrayOf(PropTypes.object),
	acctClasses: PropTypes.arrayOf(PropTypes.object),
};
ProductMarinaLongTermEdit.defaultProps = {
	data: {
		id: 0,
	},
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	isListsLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
	bookingTypeMarinas: [],
	productCategoryMarinas: [],
	taxCodes: [],
	policies: [],
	accounts: [],
	contracts: [],
	taxRates: [],
	bookingExtraCharges: [],
	enumBookingCalculations: [],
	itemTypes: [],
	enumBookingPeriods: [],
	marinaUnits: [],
	enumDays: [],
	ratePlans: [],
	invoiceFirstOptions: [],
	invoiceNextOptions: [],
	invoicingFrequencies: [],
	paymentTerms: [],
	customReservationStatuses: [],
	paymentSchedules: [],
	acctClasses: [],
};

export default ProductMarinaLongTermEdit;
