export const screens = {
	MAP: 'tableMap',
	GRID: 'grid',
	RECALL: 'recall',
	MODIFIER: 'modifiers',
	SPLIT: 'split',
	PAYBILL: 'payBill',
};

export const modals = {
	BACK: 'back',
	RELOAD: 'reload',
	ATTACHMENTS: 'attachment',
	MESSAGES: 'messages',
};

export const invoiceListModals = {
	VOID_DIALOG: 'voidDialog',
	PAY: 'pay',
	DEFERRED_SCHEDULE: 'deferred_schedule',
};

export const terminalModals = {
	PRICE: 'price',
	QUANTITY: 'quantity',
	DISCOUNT: 'discount',
	UNSAVED: 'unsaved',
	RELOAD: 'reload',
	NOTE: 'note',
	EXTERNAL_NOTE: 'external_note',
	SEATS: 'seats',
	TAX: 'tax_code',
	USER: 'user',
	CUSTOMER: 'customer',
	HOUSE_ACCOUNT_CUSTOMER: 'house_account_customer',
	TABLE: 'table',
	VOID_PAYMENT: 'void_payment',
	VOID_ORDER: 'void_order',
	PRODUCT_NOT_FOUND: 'not_found',
	PAY: 'pay',
	CC_PAY: 'cc_pay',
	CASH_PAY: 'cash_pay',
	PAYMENT: 'payment',
	TIP: 'tip',
	CASH_TIP: 'cash_tip',
	COUNT_DOWN: 'count_down',
	REFUND_PAYMENT: 'refund_payment',
	PAYBILL_QUIT: 'paybill_quit',
	TERMINAL_CLOSE: 'terminal_close',
	AUTHORIZE_PAYMENT: 'authorize_payment',
	VOID_AUTHORIZED_PAYMENT: 'void_authorized_payment',
	PRINT_RECEIPT_PROMPT: 'print_receipt_prompt',
	VESSEL: 'vessel',
};

export const printStatuses = {
	NEW: 'New',
	SENT: 'Sent',
	FAKE: 'Fake',
	BACKUP: 'Backup',
	ERROR: 'Error',
	DONOTPRINT: 'DoNotPrint',
};

export const afterSaveActions = {
	HOLD: 'hold',
	SERVICE: 'service',
	VOID: 'void',
	PAY: 'pay',
	PAY_ZERO: 'pay_zero',
	CC_PAY: 'cc_pay',
	CASH_PAY: 'cash_pay',
	FINALIZE: 'finalize',
	TIP_EDIT: 'tip_edit',
	AUTHORIZE_PAYMENT: 'authorize_payment',
	CAPTURE_AUTHORIZED_PAYMENT: 'capture_authorized_payment',
	VOID_AUTHORIZED_PAYMENT: 'void_authorized_payment',
	PAY_WITH_BOOKING: 'pay_with_booking',
	MAKE_PAYMENT: 'make_payment',
};

export const paymentTypes = {
	CASH: 'Cash',
	HOUSE_ACCOUNT: 'House Account',
	CREDIT_CARD: 'Credit Card',
	CHECK: 'Check',
	CREDIT: 'Credit',
	BOOKING: 'Booking',
	WIRE: 'Wire',
};

export const orderStatuses = {
	OPEN: 'open',
	PARTIAL: 'partial',
	PAID: 'paid',
	FINALIZED: 'finalized',
	VOIDED: 'void',
	REFUNDED: 'refunded',
	CLOSED: 'closed',
};

export const reservationStatuses = {
	WAITLIST: 'Waitlist',
	RESERVED: 'Reserved',
	RESERVED_ONLINE: 'Reserved Online',
	CONFIRMED: 'Confirmed',
	CHECKED_IN: 'Checked In',
	CHECKED_OUT: 'Checked Out',
	CANCELLED: 'Cancelled',
};

export const orderTypes = {
	SALE: 'Sale',
	REFUND: 'Refund',
	CREDIT_MEMO: 'Credit Memo',
};

export const paymentStatuses = {
	UNSETTLED: 'unsettled',
	SETTLED: 'settled',
	VOIDED: 'voided',
	PROCESSING: 'processing',
	DECLINED: 'declined',
	SCHEDULED: 'scheduled',
};

export const bookingPeriods = {
	HOURLY: 'Hourly',
	DAILY: 'Daily',
	NIGHTLY: 'Nightly',
	LONG_TERM: 'Long Term',
	SEASONAL: 'Seasonal',
};
export const extraChargeTypes = {
	ALL_ORDERS: 'All Orders',
	ONLINE_ORDERS: 'Online Orders',
	OFFLINE_ORDERS: 'Offline Orders',
	PER_UNIT: 'Per Unit',
};

export const bookingCalculations = {
	FIXED: 'fixed',
	PERCENT: 'percent',
	PER_QUANTITY: 'per quantity',
	PER_PERIOD: 'per period',
	PER_QUANTITY_PER_PERIOD: 'per quantity per period',
	PER_LOA_FOOT: 'per LOA foot',
	PER_LOA_FOOT_PER_PERIOD: 'per LOA foot per period',
	PER_SQFT: 'per sqft',
	PER_SQFT_PER_PERIOD: 'per sqft per period',
	PER_BEAM_FOOT: 'per beam foot',
	PER_BEAM_FOOT_PER_PERIOD: 'per beam foot per period',
	PER_MONTH: 'per month',
};

export const depositTypes = {
	FIXED: 'fixed',
	PERCENT: 'percent',
	PER_BLOCK: 'per_block',
};

export const templateTypes = {
	RESERVATION_CONFIRMATION: 'Reservation Email',
	RESERVATION_ITEM_BLOCK: 'Reservation Item Block',
	RESET_PASSWORD: 'Reset Password Email',
	INVOICE: 'Invoice',
	INVOICE_ITEM_BLOCK: 'Invoice Item Block',
	STATEMENT: 'Statement',
	STATEMENT_TRANSACTION_BLOCK: 'Statement Transaction Block',
	STATEMENT_TRANSACTION_DETAIL_BLOCK: 'Statement Transaction Detail Block',
	CUSTOMER_MARKETING_EMAIL: 'Customer Marketing Email',
	ACH_SIGNUP_EMAIL: 'ACH Sign-up Email',
	PAYMENT_EMAIL: 'Payment Email',
	PAYMENT_EMAIL_ATTACHMENT: 'Payment Email Attachment',
	PAYMENT_PRINT: 'Payment Print',
	PRODUCT_CONTRACT: 'Product Contract',
	PRODUCT_CONTRACT_EMAIL: 'Product Contract Email',
	ESIGN: 'E-sign',
	ESIGN_EMAIL: 'E-sign Email',
	STATUS_UPDATE_EMAIL: 'Status Update Email',
	STATUS_UPDATE_EMAIL_ITEM_BLOCK: 'Status Update Email Item Block',
	STATUS_UPDATE_EMAIL_ATTACHMENT: 'Status Update Email Attachment',
	ACH_DECLINED: 'ACH Declined',
	ACH_TERMS_AND_CONDITIONS: 'ACH Terms And Conditions',
	ONLINE_BOOKING_TEMPLATE: 'Online Booking Template',
	TRANSIENT_FOLIO: 'Transient Folio',
	TRANSIENT_FOLIO_ITEM_BLOCK: 'Transient Folio Item Block',
};

export const emailTemplateTypes = [
	'Reservation Email',
	'Reset Password Email',
	'Invoice',
	'Statement',
	'Customer Marketing Email',
	'ACH Sign-up Email',
	'Product Contract Email',
	'E-sign Email',
	'Payment Email',
	'Status Update Email',
	'ACH Declined',
	'Transient Folio',
	'Credit Memo Email',
];

export const bookBies = {
	UNIT: 'Unit',
	LENGTH: 'Length',
	BEAM: 'Beam',
	SQ_FEET: 'Sq Feet',
};

export const enumItemTypes = {
	INVENTORY: 'inventory',
	NON_INVENTORY: 'non-inventory',
	SERVICE: 'service',
	OTHER_CHARGE: 'other-charge',
	SUBTOTAL: 'subtotal',
	SALES_TAX: 'sales-tax',
	DISCOUNT: 'discount',
};

export const billingFrequencies = {
	ON_RESERVATION_ONLY: 'On Reservation Only',
	ON_RENEWAL_ONLY: 'On Renewal Only',
	RESERVATION_AND_RENEWAL: 'Reservation & Renewal',
	FIRST_OF_THE_MONTH: 'First of the month',
	FIFTH_OF_THE_MONTH: '5th of the month',
	FIFTEEN_OF_THE_MONTH: '15th of the month',
	END_OF_THE_MONTH: 'End of the month',
};

export const pricingTypes = {
	PER_UNIT: 'Price per unit',
	NUMBER_OF_UNIT: 'Price based on number of units',
	PER_UNIT_PER_LOA: 'Price per unit per foot LOA',
	PER_UNIT_PER_SQFT: 'Price per unit per Sqft',
	PER_MONTH: 'Price per month',
	PER_MONTH_PER_LOA: 'Price per month per foot LOA',
	PER_MONTH_PER_SQFT: 'Price per month per Sqft',
	PER_YEAR: 'Price per year',
	PER_YEAR_PER_LOA: 'Price per year per foot LOA',
	PER_YEAR_PER_SQFT: 'Price per year per Sqft',
};

export const themes = {
	SHARPER: 'sharper',
	MODERN: 'modern',
};

export const mediaBreakpoint = {
	// Extra small screen / phone
	XS: 0,
	// Small screen / phone
	SM: 576,
	// Medium screen / tablet
	MD: 768,
	// Large screen / desktop
	LG: 1024,
	XLG: 1279,
	// Extra large screen / wide desktop
	XL: 1399,
	XLX: 1499,
	XLXX: 1599,
	// Full HD screen
	XXL: 1919,
};

export const reportTypes = {
	DEFERRED_INCOME: 'deferred_income',
	GENERAL_LEDGER: 'general_ledger',
	PAYROLL: 'payroll',
	SETTLEMENT_DETAILS: 'settlement_details',
	DEPOSIT_BALANCES: 'deposit_balances',
	RESERVATION_DAILY_REVENUE: 'reservation_daily_revenue',
	BOOKING_RESERVATIONS: 'booking_reservations',
	CAMPGROUND_RESERVATIONS: 'campground_reservations',
	CAMPGROUND_RENT_ROLL: 'campground_rent_roll',
	CRM_CUSTOMERS: 'crm_customers',
	CRM_BALANCE_DETAILS: 'crm_balance_details',
	CRM_PAYMENTS: 'crm_payments',
	CRM_INVOICES: 'crm_invoices',
	CRM_INVOICE_DETAILS: 'crm_invoice_details',
	MARINA_RESERVATIONS: 'marina_reservations',
	MARINA_RENT_ROLL: 'marina_rent_roll',
	MARINA_VESSELS: 'marina_vessels',
	ZOUT: 'z_out',
	POS_ORDERS: 'pos_orders',
	POS_ORDERDETAILS: 'pos_orderdetails',
	POS_ORDERMODIFIERS: 'pos_ordermodifiers',
	POS_TIPS: 'pos_tips',
};

export const reportTypesByModule = {
	accounting: [
		reportTypes.DEFERRED_INCOME,
		reportTypes.GENERAL_LEDGER,
		reportTypes.PAYROLL,
		reportTypes.SETTLEMENT_DETAILS,
		reportTypes.DEPOSIT_BALANCES,
		reportTypes.RESERVATION_DAILY_REVENUE,
	],
	booking: [reportTypes.BOOKING_RESERVATIONS],
	campground: [reportTypes.CAMPGROUND_RESERVATIONS, reportTypes.CAMPGROUND_RENT_ROLL],
	crm: [
		reportTypes.CRM_CUSTOMERS,
		reportTypes.CRM_BALANCE_DETAILS,
		reportTypes.CRM_PAYMENTS,
		reportTypes.CRM_INVOICES,
		reportTypes.CRM_INVOICE_DETAILS,
	],
	marina: [
		reportTypes.MARINA_RESERVATIONS,
		reportTypes.MARINA_RENT_ROLL,
		reportTypes.MARINA_VESSELS,
	],
	pos: [
		reportTypes.ZOUT,
		reportTypes.POS_ORDERS,
		reportTypes.POS_ORDERDETAILS,
		reportTypes.POS_ORDERMODIFIERS,
		reportTypes.POS_TIPS,
	],
};

export const searchTypes = {
	HIDDEN: 'Hidden',
	OPTIONAL: 'Optional',
	REQUIRED: 'Required',
	ADULTS_OPTIONAL: 'Adult Optional, Child Optional',
	ADULTS_REQUIRED: 'Adult Required, Child Optional',
	PEOPLE_OPTIONAL: 'People Optional',
	PEOPLE_REQUIRED: 'People Required',
};

export const invoiceStatuses = {
	OPEN: 'open',
	PAID: 'paid',
	VOIDED: 'voided',
};

export const invoicingFrequencies = {
	UPFRONT: 'upfront',
	DAILY: 'daily',
	MONTHLY: 'monthly',
	QUARTERLY: 'quarterly',
	SEMI_ANNUALLY: 'semi-annually',
	ANNUALLY: 'annually',
	SAME_AS_RESERVATION: 'same as reservation',
};

export const spaceAssigmentTypes = {
	TEMPORARY_DEPARTED: 'Temporary Departed',
	TEMPORARY_MOVE: 'Temporary Move',
	PERMANENT_MOVE: 'Permanent Move',
};

export const firstInvoiceOptions = {
	NO_CHARGE: 'no-charge',
	FIXED: 'fixed',
	PRO_RATE: 'pro-rate',
};

export const nextInvoiceOptions = {
	NEXT_DAY: 'Next day',
	NEXT_MONTH: 'Next month',
	FIRST_OF_MONTH: 'First of the month',
	FIFTH_OF_MONTH: '5th of the month',
	FIFTEEN_OF_MONTH: '15th of the month',
	END_OF_MONTH: 'End of the month',
	CO_TERM: 'Co-term',
	NEXT_QUARTER: 'Next quarter',
	NEXT_SEMI_ANNUAL: 'Next 6 months',
	NEXT_YEAR: 'Next year',
	FIRST_OF_QUARTER: 'First of quarter',
	FIRST_OF_YEAR: 'First of year',
	END_OF_YEAR: 'End of year',
};

export const nonTransientAdvancedFormSteps = {
	GENERAL: 'General',
	INVOICES: 'Invoices',
	PAYMENTS: 'Payments',
	SPACE_ASSIGNMENT: 'Space Assignment',
	CONTRACTS: 'Contracts',
};

export const reservationReceiptTypes = {
	DEPOSIT: 'deposit',
	PAYMENT: 'payment',
	REFUND: 'refund',
};

export const paymentGateWays = {
	BLUEPAY: 'bluepay',
	SHIFT4: 'shift4',
	PAYA: 'paya',
	PAYROC_CREDIT_CARD: 'payroc_credit_card',
	PAYROC_ACH: 'payroc_ach',
	SIDE_TERMINAL_CREDIT_CARD: 'side_terminal_credit_card',
};

export const creditCardPaymentStatuses = {
	AUTH: 'authorized',
	AUTH_ERROR: 'auth-error',
	PENDING_CAPTURE: 'pending-capture',
	CAPTURING: 'capturing',
	CAPTURED: 'captured',
	CAPTURE_ERROR: 'capture-error',
	CAPTURED_ONLY_AUTH_AMOUNT: 'captured-only-auth-amount',
};

export const achPaymentStatues = {
	PROCESSING: 'processing',
	CAPTURED: 'captured',
	CAPTURE_ERROR: 'capture-error',
};

export const htmlTemplateVariables = ['signature', 'checkbox', 'blank', 'images'];

export const reservationListDefaultFilters = [
	{ display: 'All', value: 'all' },
	{ display: 'Active', value: 'active' },
];

export const serviceFeeModules = {
	AUTOPAY: 'enableServiceFeeForAutoPay',
	BOOKING: 'enableServiceFeeForBooking',
	CRM: 'enableServiceFeeForCrm',
	SLIPS: 'enableServiceFeeForSlips',
	TRANSIENT_SLIPS: 'enableServiceFeeForTransientSlips',
	POS: 'enableServiceFeeForPos',
	POS_INVOICES: 'enableServiceFeeForPosInvoices',
	ONLINE_BOOKINGS: 'enableServiceFeeForOnlineBookings',
	ONLINE_PAYMENTS: 'enableServiceFeeForOnlinePayments',
};

export const paymentProcessorGatewayPresetConfigurations = {
	Bluepay: ['account', 'secretKey'],
	Shift4: ['clientGuid', 'authToken'],
	Paya: [
		'username',
		'password',
		'webTerminal',
		'ppdTerminal',
		'ccdTerminal',
		'reportFtpUrl',
		'reportFtpUser',
		'reportFtpPassword',
		'reportFtpPath',
		'reportFtpArchivePath',
	],
	Payroc: ['merchantApiKey', 'partnerApiKey', 'terminal'],
};

export const productServiceFeeValues = {
	DEFAULT: 'Default',
	ON: 'On',
	OFF: 'Off',
};

export const productFilterTypes = {
	SELECT: 'Select',
	MULTI_SELECT: 'Multi Select',
	INPUT: 'Input',
	TOGGLE: 'Toggle',
};

export const productFilterComparisons = {
	EXACT: 'Exact',
	GREATER: 'Greater',
	GREATER_OR_EQUAL: 'Greater or Equal',
	LESS: 'Less',
	LESS_OR_EQUAL: 'Less or Equal',
	SEARCH: 'Search',
};

export const metaTypes = {
	STRING: 'String',
	INTEGER: 'Integer',
	FLOAT: 'Float',
	BOOLEAN: 'Boolean',
};

export const metaTypeLabels = {
	String: 'Text',
	Integer: 'Number',
	Float: 'Decimal Number',
	Boolean: 'True/False',
};

export const pagesWithAttachments = ['customers', 'vehicles', 'vessels', 'invoices'];

export const pagesWithMessages = ['customers', 'invoices', 'payments', 'credits'];

export const inquiryTypes = {
	RESERVATION: 'reservation',
	INVOICE: 'invoice',
	PAYMENT: 'payment',
};

export const messageStatuses = {
	UNREAD: 'unread',
	READ: 'read',
	ARCHIVED: 'archived',
	SENT: 'sent',
};

export const emailAddressPresetConfigurations = {
	Office365: ['client_id', 'client_secret', 'tenant'],
};

export const auditTables = [
	{ display: 'All', id: null },
	{ display: 'Invoice', id: 'sdms_invoice' },
	{ display: 'Invoice Item', id: 'sdms_invoice_item' },
	{ display: 'Reservation Item', id: 'sdms_reservation_item' },
	{ display: 'Reservation Item Marina', id: 'sdms_reservation_item_marina' },
	{ display: 'Reservation Item Campground', id: 'sdms_reservation_item_campground' },
	{ display: 'Remittance', id: 'sdms_remittance' },
	{ display: 'Payment', id: 'sdms_payment' },
	{ display: 'Payment Credit Card', id: 'sdms_payment_credit_card' },
	{ display: 'Payment Check', id: 'sdms_payment_check' },
	{ display: 'Settlement', id: 'sdms_customer_settlement' },
];

export const USER_STORAGE_KEY = 'user';

export const SELECTED_OUTLET_STORAGE_KEY = 'sdms_selected_outlet';

export const SELECTED_REGISTER_STORAGE_KEY = 'sdms_selected_register';

export const SELECTED_PRINTER_STORAGE_KEY = 'sdms_printer';
export const TERMINAL_TOKEN_STORAGE_KEY = 'sdms_terminal_token';

export const accountingExports = {
	SAGE_300: 'Sage 300',
	SAGE_INTACCT_GL: 'Sage Intacct GL',
	SAGE_INTACCT_CUSTOMER: 'Sage Intacct Customers',
};

export const invoiceTypes = {
	INVOICE: 'Invoice',
	RESERVATION: 'Reservation',
	ORDER_SALE: 'Order Sale',
	METER_READING: 'Meter Reading',
	SPEEDY_DOCK: 'Speedy Dock',
	SERVICE_FEE: 'Service fee',
};

export const discountCalculations = {
	PERCENT: 'percent',
	PER_QUANTITY: 'per quantity',
	FIXED: 'fixed',
};

export const filterComponents = {
	INPUT: 'input',
	SELECTS: 'selects',
	ASYNC_SELECTS: 'asyncSelects',
	DATE_RANGE: 'dateRange',
	RADIO: 'radio',
};

export const mapDisplayOptions = {
	OCCUPANCY: 'occupancy',
	AVAILABILITY: 'availability',
	CUSTOMER_BALANCE: 'customer_balance',
};

export const mapItemColors = {
	VACANT: { label: 'Vacant', color: 'success', type: mapDisplayOptions.OCCUPANCY },
	NEW_ARRIVAL: {
		label: 'Arriving Soon',
		color: 'light-success',
		type: mapDisplayOptions.OCCUPANCY,
	},
	TRANSIENT: { label: 'Transient', color: 'light-warning', type: mapDisplayOptions.OCCUPANCY },
	OCCUPIED: { label: 'Occupied', color: 'danger', type: mapDisplayOptions.OCCUPANCY },
	DEPARTING: { label: 'Departing', color: 'light-danger', type: mapDisplayOptions.OCCUPANCY },
	TEMPORARY_DEPARTED: {
		label: 'Temporary Departed',
		color: 'purple',
		type: mapDisplayOptions.OCCUPANCY,
	},
	ALERT: { label: 'Alert', color: 'alert', type: mapDisplayOptions.OCCUPANCY },
	WARNING: { label: 'Warning', color: 'warning', type: mapDisplayOptions.OCCUPANCY },
	PAID_IN_FULL: {
		label: 'Paid In Full',
		color: 'success',
		type: mapDisplayOptions.CUSTOMER_BALANCE,
	},
	OPEN_BALANCE: {
		label: 'Open Balance',
		color: 'warning',
		type: mapDisplayOptions.CUSTOMER_BALANCE,
	},
	PAST_DUE_BALANCE: {
		label: 'Past Due Balance',
		color: 'danger',
		type: mapDisplayOptions.CUSTOMER_BALANCE,
	},
};

export const paymentTypeIcons = {
	Cash: 'Dollar',
	Check: 'Ticket',
	'Credit Card': 'Credit-card',
	Booking: 'Bookings',
	'House Account': 'Clipboard-list',
	WIRE: 'Exchange',
};
