import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import usePages from '../../../utils/hooks/usePages';
import forms from '../../forms';
import HeaderContext from '../../../app/contexts/HeaderContext';
import Badge from '../../reusables/element/Badge';

const TypeCell = ({ data }) => <>{data.type.value === 'percent' ? '%' : '+'}</>;

TypeCell.propTypes = {
	data: PropTypes.shape({
		type: PropTypes.shape({
			value: PropTypes.string,
		}),
	}),
};
TypeCell.defaultProps = {
	data: {
		type: {
			value: 'percent',
		},
	},
};

const TaxRatesCell = ({ data }) => {
	return data.taxRates.map(tr => (
		<Badge
			design='info'
			isInline
			isUnified
			fontWeight='bold'
			size='lg'
			className='sdms-text-overflow'>
			<span className='sdms-text-overflow'>{tr.name}</span>
		</Badge>
	));
};

TaxRatesCell.propTypes = {
	data: PropTypes.shape({
		taxRates: PropTypes.arrayOf(PropTypes.object),
	}),
};
TypeCell.defaultProps = {
	data: {
		taxRates: [],
	},
};

const TaxRateGroupList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.accounting.default.text, path: pages.accounting.dashboard.path },
			{ title: pages.accounting.settings.text, path: pages.accounting.settings.path },
			{ title: pages.accounting.settings.taxRateGroup.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.accounting.settings.taxRateGroup.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='taxRates'
			title={pages.accounting.settings.taxRateGroup.text}
			history={history}
			icon={pages.accounting.settings.taxRateGroup.icon}
			forms={forms.accounting.settings.taxRateGroup}
			staticFilters={{ isGroup: true }}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col label='Description' cellData='description' sortable='description' />
				<List.Col label='Type' cellComponent={<TypeCell />} sortable='type.value' />
				<List.Col label='Amount' cellData='amount' sortable='amount' />
				<List.Col label='Tax Rates' cellComponent={<TaxRatesCell />} />
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
TaxRateGroupList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default TaxRateGroupList;
