import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SlideDown } from 'react-slidedown';
import moment from 'moment';

import UserContext from '../../../../../app/contexts/UserContext';
import useField from '../../../../../utils/hooks/useField';
import useModal from '../../../../../utils/hooks/useModal';
import { min, numeric, required } from '../../../../../utils/helpers/validation';
import {
	bookingCalculations,
	bookingPeriods,
	invoicingFrequencies as _invoicingFrequencies,
	reservationStatuses,
} from '../../../../../utils/constants/constants';
import {
	calculateExtraFeeTotals,
	calculateItemTotals,
	calculateSubtotalAndQuantity,
	getPolicy,
	getPrices,
	getRatePlan,
	onExtraChargeItemUpdate,
	setBookingCalculationLabel,
	getReservationTotals,
} from '../../../../../utils/helpers/reservationHelper';
import {
	addErrorNotification,
	calculateSqft,
	getItemTaxCode,
	numberFormat,
} from '../../../../../utils/helpers/helper';
import apiCall from '../../../../../utils/helpers/apiCall';

import FormSection from '../../../layout/FormSection';
import Radio from '../../../field/Radio';
import Input, { InputGroup, InputPend } from '../../../field/Input';
import Selects from '../../../field/Selects';
import Portlet from '../../../layout/Portlet';
import { ExtraFeeHead, ExtraFeeRow } from '../../../element/ExtraFee';
import FormSectionMoreLink from '../../../element/FormSectionMoreLink';
import FormField from '../../../template/FormField';
import ExtraFeeSettings from '../../../modals/ExtraFeeSettings';
import BasicExtraCharges from '../elements/BasicExtraCharges';
import {
	hasEditReservationCancellationPolicyPermission,
	hasEditReservationFeesPermission,
	hasEditReservationPricePermission,
	hasEditReservationProductContractsPermission,
	hasOverrideConstraintPermission,
	hasPriceAdjustmentGroupOverrideConstraintsPermission,
} from '../../../../../utils/helpers/permission';

const modals = {
	EXTRA_FEE_SETTINGS: 'extra_fee_settings',
};

const Pricing = ({
	sectionRef,
	reservation,
	reservationItem,
	customer,
	onFormChange,
	disabled,
	setIsValid,
	onTotalsChange,
	taxRate,
	pricing,
	enumBookingCalculations,
	ratePlans,
	policies,
	taxCodes,
	invoicingFrequencies,
	invoiceFirstOptions,
	invoiceNextOptions,
	paymentTerms,
	accounts,
	contracts,
}) => {
	const userContext = useContext(UserContext);

	const extraChargesRef = useRef();

	const [showMore, setShowMore] = useState(false);

	const [modal, openModal, closeModal] = useModal();

	const timeoutId = useRef(0);

	const init = useRef(false);

	const bookingPeriod = useMemo(() => reservationItem?.product?.bookingPeriod?.value, [
		reservationItem,
	]);

	const isTransient = useMemo(
		() =>
			bookingPeriod !== bookingPeriods.SEASONAL && bookingPeriod !== bookingPeriods.LONG_TERM,
		[bookingPeriod]
	);

	const [availableRatePlans, setAvailableRatePlans] = useState([]);

	const [subtotal, subtotalOnChange] = useField(
		reservationItem,
		'subtotal',
		onFormChange,
		[required],
		reservationItem.subtotal ? parseFloat(reservationItem.subtotal).toFixed(2) : '0.00'
	);

	const [fixedPrice, fixedPriceOnChange] = useField(reservationItem, 'fixedPrice', onFormChange);

	const [bookingCalculation, bookingCalculationOnChange] = useField(
		reservationItem,
		'bookingCalculation',
		onFormChange
	);

	const [extraCharges, setExtraCharges] = useState({});

	const [selectedExtraCharges, selectedExtraChargesOnChange] = useField(
		reservationItem,
		'extraCharges',
		onFormChange,
		[],
		[]
	);

	const [invalidExtraCharges, setInvalidExtraCharges] = useState([]);

	const [ratePlan, ratePlanOnChange] = useField(reservationItem, 'ratePlan', onFormChange);

	const [policy, policyOnChange] = useField(reservationItem, 'policy', onFormChange);

	const [
		depositAmount,
		depositAmountOnChange,
		depositAmountValRes,
		depositAmountShowVal,
		setDepositAmountShowVal,
	] = useField(
		reservationItem,
		'depositAmount',
		onFormChange,
		[required, numeric, min(0)],
		reservationItem.depositAmount
			? parseFloat(reservationItem.depositAmount).toFixed(2)
			: '0.00'
	);

	const [taxCode, taxCodeOnChange] = useField(reservationItem, 'taxCode', onFormChange);

	const [productContracts, productContractsOnChange] = useField(
		reservationItem,
		'productContracts',
		onFormChange,
		[],
		reservationItem.product.productContracts
	);

	const getNonTransientProductPrice = () => {
		if (fixedPrice) return;

		if (pricing.ratePlans) {
			const prices =
				ratePlan && pricing.ratePlans[ratePlan.id]
					? pricing.ratePlans[ratePlan.id]
					: Object.values(pricing.ratePlans)[0];

			subtotalOnChange({
				target: {
					value: prices ? parseFloat(prices[0]).toFixed(2) : 0,
				},
			});

			// TODO: add period to isAvailable api
			if (reservationItem.product)
				bookingCalculationOnChange({
					target: {
						value: reservationItem.product.bookingCalculation,
					},
				});

			reservationItem.quantity = prices ? prices.length : 1;
		} else if (ratePlan) {
			const _data = {
				...reservationItem,
				outletId: userContext.data.selectedOutlet.id,
				productId: reservationItem.product.id,
				fromDate: reservationItem.prevInvoiceDate
					? reservationItem.prevInvoiceDate.toString()
					: reservationItem.fromDate,
				toDate: reservationItem.nextInvoiceDate
					? reservationItem.nextInvoiceDate.toString()
					: reservationItem.toDate,
				ratePlanId: reservationItem.ratePlan ? reservationItem.ratePlan.id : null,
				sqft: calculateSqft(reservationItem.loa, reservationItem.beam),
			};

			apiCall(
				'POST',
				'advancedReservationGetNonTransientProductPrice',
				res => {
					subtotalOnChange({
						target: {
							value: parseFloat(res.price).toFixed(2),
						},
					});

					bookingCalculationOnChange({
						target: {
							value: reservationItem.product.bookingCalculation,
						},
					});
				},
				e => {
					addErrorNotification(e.toString());
				},
				'',
				_data
			);
		} else {
			subtotalOnChange({
				target: {
					value: parseFloat(reservationItem.product.price).toFixed(2),
				},
			});

			bookingCalculationOnChange({
				target: {
					value: reservationItem.product.bookingCalculation,
				},
			});
		}
	};

	const shouldUpdateExtraChargeInvoicingSettings = () => {
		const fields = [
			'nextInvoiceDate',
			'firstInvoice',
			'nextInvoice',
			'proratedFrequency',
			'coTermDate',
			'paymentTerm',
		];

		return selectedExtraCharges.some(
			sec =>
				sec.invoicingFrequency &&
				sec.invoicingFrequency.value === _invoicingFrequencies.SAME_AS_RESERVATION &&
				fields.some(f => {
					if (reservationItem[f]?.id) return reservationItem[f].id !== sec[f]?.id;

					return sec[f] !== reservationItem[f];
				})
		);
	};

	const canOverridePriceAdjustmentGroupConstraints = useMemo(
		() => hasPriceAdjustmentGroupOverrideConstraintsPermission(userContext),
		[userContext]
	);

	const extraChargeDisabled = extraCharge => {
		if (
			canOverridePriceAdjustmentGroupConstraints ||
			selectedExtraCharges.some(e => e.extraCharge.id === extraCharge.id) ||
			!extraCharge?.productCategory?.maxChargeQuantity
		)
			return false;

		return (
			selectedExtraCharges.filter(
				e => e.extraCharge.productCategory?.id === extraCharge.productCategory?.id
			).length >= extraCharge.productCategory?.maxChargeQuantity
		);
	};

	const isCancellationPolicyDisabled = useMemo(() => {
		const { paidDepositAmount } = getReservationTotals(reservation, taxRate);

		return paidDepositAmount > 0;
	}, [reservation, taxRate]);

	useEffect(() => {
		const { product } = reservationItem;

		const _extraCharges = {};

		product.productExtraCharges.forEach(p => {
			if (!p.productCategory) {
				if (!_extraCharges['Other Options'])
					_extraCharges['Other Options'] = { max: 0, extraCharges: [] };

				_extraCharges['Other Options'].extraCharges.push({
					...p,
					category: 'Other Options',
					hasMultiSelectQuantity:
						p.bookingCalculation.value === bookingCalculations.PER_QUANTITY ||
						p.bookingCalculation.value === bookingCalculations.PER_QUANTITY_PER_PERIOD,
				});
			}
		});

		product.productExtraCharges.forEach(p => {
			if (p.productCategory) {
				if (!_extraCharges[p.productCategory.name])
					_extraCharges[p.productCategory.name] = {
						max: p.productCategory.maxChargeQuantity,
						extraCharges: [],
					};

				_extraCharges[p.productCategory.name].extraCharges.push({
					...p,
					category: p.productCategory.name,
					hasMultiSelectQuantity:
						p.bookingCalculation.value === bookingCalculations.PER_QUANTITY ||
						p.bookingCalculation.value === bookingCalculations.PER_QUANTITY_PER_PERIOD,
				});
			}
		});

		if (reservationItem.extraCharges)
			reservationItem.extraCharges
				.filter(
					ec =>
						product.productExtraCharges.findIndex(
							pec => ec.extraCharge.id === pec.id
						) === -1
				)
				.forEach(ec => {
					const categoryName = ec.extraCharge.productCategory
						? ec.extraCharge.productCategory.name
						: 'Other Options';

					if (!_extraCharges[categoryName])
						_extraCharges[categoryName] = {
							max: ec.extraCharge.productCategory?.maxChargeQuantity || 0,
							extraCharges: [],
						};

					_extraCharges[categoryName].extraCharges.push({
						...ec.extraCharge,
						category: categoryName,
						hasMultiSelectQuantity:
							ec.bookingCalculation?.value === bookingCalculations.PER_QUANTITY ||
							ec.bookingCalculation?.value ===
								bookingCalculations.PER_QUANTITY_PER_PERIOD,
					});
				});

		setExtraCharges(_extraCharges);

		if (Object.entries(_extraCharges).length > 0) {
			// set default extra charges.
			if (reservationItem.id === 0) {
				let defaultExtraCharges = [];

				Object.entries(_extraCharges).forEach(exc => {
					exc[1].extraCharges

						.filter(ec => !ec.optional && !ec.enabled)
						.forEach((extraCharge, index) => {
							onExtraChargeItemUpdate(
								{
									id: index * (index ? -1 : 1),
									isChecked: true,
									extraCharge,
									amount: extraCharge.price,
									quantity: 1,
									taxCode: getItemTaxCode(taxCode, {
										taxCode: extraCharge.taxCode || taxCode,
									}),
									category: exc[0],
								},
								defaultExtraCharges,
								_defaultExtraCharges => {
									defaultExtraCharges = _defaultExtraCharges;
								},
								{
									...reservationItem,
									bookingPeriod: reservationItem.product.bookingPeriod.value,
								},
								taxRate
							);
						});
				});

				selectedExtraChargesOnChange({ target: { value: defaultExtraCharges } });
			}
			// set categories to selected extra charges.
			else if (selectedExtraCharges.length > 0) {
				selectedExtraChargesOnChange({
					target: {
						value: selectedExtraCharges.map(sec => {
							let category = null;
							Object.keys(_extraCharges).forEach(_category => {
								if (
									_extraCharges[_category].extraCharges.some(
										e => e.id === sec.extraCharge.id
									)
								)
									category = _category;
							});

							return { ...sec, category };
						}),
					},
				});
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservationItem.product]);

	useEffect(() => {
		setIsValid(invalidExtraCharges.length === 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invalidExtraCharges]);

	useEffect(() => {
		if (shouldUpdateExtraChargeInvoicingSettings()) {
			selectedExtraChargesOnChange({
				target: {
					value: selectedExtraCharges.map(sec => {
						if (
							sec.invoicingFrequency &&
							sec.invoicingFrequency.value ===
								_invoicingFrequencies.SAME_AS_RESERVATION
						) {
							return {
								...sec,
								nextInvoiceDate: reservationItem.nextInvoiceDate,
								firstInvoice: reservationItem.firstInvoice,
								nextInvoice: reservationItem.nextInvoice,
								proratedFrequency: reservationItem.proratedFrequency,
								coTermDate: reservationItem.coTermDate,
								paymentTerm: reservationItem.paymentTerm,
							};
						}

						return sec;
					}),
				},
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservationItem]);

	useEffect(() => {
		const _availableRatePlans = pricing.ratePlans
			? ratePlans.filter(rp => pricing.ratePlans[rp.id])
			: [];

		setAvailableRatePlans(_availableRatePlans);

		if (!pricing.isInit && pricing.available) {
			const { product, fromDate, toDate, ignoredRules } = reservationItem;

			let _ratePlan = getRatePlan(_availableRatePlans, ignoredRules, pricing);

			let _policy = getPolicy(pricing, _ratePlan, product, policies);

			if (
				!reservationItem.ratePlan ||
				!_availableRatePlans.some(rp => rp.id === reservationItem.ratePlan.id)
			) {
				ratePlanOnChange({ target: { value: _ratePlan } });

				policyOnChange({
					target: { value: _policy },
				});
			} else {
				_ratePlan = reservationItem.ratePlan;

				_policy = reservationItem.policy;
			}

			bookingCalculationOnChange({ target: { value: product.bookingCalculation } });

			const { subtotal: _subtotal, quantity } = calculateSubtotalAndQuantity(
				product,
				_ratePlan,
				pricing,
				moment(fromDate).utc(false),
				moment(toDate).utc(false)
			);

			reservationItem.quantity = quantity;

			if (!fixedPrice) {
				subtotalOnChange({
					target: {
						value: numberFormat(_subtotal, 2),
					},
				});

				if (
					reservationItem.status.value === reservationStatuses.RESERVED ||
					reservationItem.status.value === reservationStatuses.RESERVED_ONLINE
				) {
					const { requiredDepositAmount } = calculateItemTotals(
						reservationItem,
						_subtotal,
						selectedExtraCharges,
						taxRate,
						_policy,
						pricing,
						_ratePlan,
						0,
						reservationItem.fromDate
					);
					depositAmountOnChange({
						target: {
							value: requiredDepositAmount,
						},
					});
				}
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pricing]);

	useEffect(() => {
		if (pricing.available && selectedExtraCharges.length > 0) {
			const { fromDate } = reservationItem;

			const _selectedExtraCharges = selectedExtraCharges.map(sec => {
				// update selected extra charge according to reservation item tax code.
				const _selectedExtraCharge = {
					...sec,
					taxCode: getItemTaxCode(taxCode, sec.extraCharge),
				};
				return {
					..._selectedExtraCharge,
					...calculateExtraFeeTotals(
						_selectedExtraCharge,
						{
							...reservationItem,
							subtotal: subtotal === '' ? 0 : parseFloat(subtotal),
							taxCode,
							fromDate: moment(fromDate).utc(false),
						},
						taxRate,
						getPrices(pricing, ratePlan),
						selectedExtraCharges,
						null,
						reservationItem.ignoredRules
					),
				};
			});

			selectedExtraChargesOnChange({
				target: {
					value: _selectedExtraCharges,
				},
			});

			onTotalsChange(
				calculateItemTotals(
					reservationItem,
					subtotal,
					_selectedExtraCharges,
					taxRate,
					policy,
					pricing,
					ratePlans,
					depositAmount,
					reservationItem.fromDate,
					reservation
				)
			);
		} else
			onTotalsChange(
				calculateItemTotals(
					reservationItem,
					subtotal,
					selectedExtraCharges,
					taxRate,
					policy,
					pricing,
					ratePlans,
					depositAmount,
					reservationItem.fromDate,
					reservation
				)
			);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subtotal, taxCode, bookingCalculation]);

	useEffect(() => {
		onTotalsChange(
			calculateItemTotals(
				reservationItem,
				subtotal,
				selectedExtraCharges,
				taxRate,
				policy,
				pricing,
				ratePlans,
				depositAmount,
				reservationItem.fromDate,
				reservation
			),
			selectedExtraCharges
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedExtraCharges]);

	useEffect(() => {
		if (!fixedPrice && !isTransient) getNonTransientProductPrice();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fixedPrice]);

	useEffect(() => {
		if (timeoutId) clearTimeout(timeoutId.current);

		if (!showMore && init.current && sectionRef.current)
			timeoutId.current = setTimeout(() => {
				sectionRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}, 500);

		init.current = true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showMore]);

	useEffect(() => {
		if (
			parseFloat(reservationItem.requiredDepositAmount || 0) !==
			parseFloat(depositAmount || 0)
		) {
			onTotalsChange(
				calculateItemTotals(
					reservationItem,
					subtotal,
					selectedExtraCharges,
					taxRate,
					policy,
					pricing,
					ratePlans,
					depositAmount,
					reservationItem.fromDate,
					reservation,
					false
				)
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [depositAmount]);

	useEffect(() => {
		if (
			reservationItem.requiredDepositAmount !== undefined &&
			reservationItem.requiredDepositAmount !== depositAmount
		)
			depositAmountOnChange({ target: { value: reservationItem.requiredDepositAmount } });

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservationItem]);

	return (
		<>
			<FormSection sectionRef={sectionRef} title='PRICING' icon='Dollar'>
				<div className='sdms-advanced-reservation-item-form-pricing'>
					<div className='row sdms-mb-15 sdms-row-align-items'>
						{isTransient ? (
							<>
								<div className='col-9'>
									<span className='sdms-font-bold sdms-font-size-1-point-2-rem'>
										Base Rate
									</span>
								</div>
								<div className='col-3'>
									<Input
										className='text-right'
										type='text'
										placeholder='Subtotal'
										value={subtotal.toString()}
										pattern={process.env.REACT_APP_PRICE_PATTERN}
										onChange={subtotalOnChange}
										disabled={disabled}
										prependIcon='Dollar'
										prependIconColor='var(--dark)'
										noPermission={
											!hasEditReservationPricePermission(userContext)
										}
									/>
								</div>
							</>
						) : (
							<>
								<div className='col-8'>
									<div className='row sdms-row-align-items'>
										<div>
											<span className='sdms-font-bold sdms-font-size-1-point-2-rem'>
												Base Rate
											</span>
										</div>
										<div className='sdms-ml-10'>
											<Radio.Container isInline>
												<Radio
													checked={!fixedPrice}
													id='fixedPriceNo'
													name='fixedPriceInput'
													content='Product Pricing'
													className='sdms-radio--primary'
													onChange={() =>
														fixedPriceOnChange({
															target: {
																value: false,
															},
														})
													}
													disabled={disabled}
												/>
												<Radio
													checked={fixedPrice}
													id='fixedPriceYes'
													name='fixedPriceInput'
													content='Fixed Price'
													className='sdms-radio--primary'
													onChange={() =>
														fixedPriceOnChange({
															target: {
																value: true,
															},
														})
													}
													disabled={disabled}
												/>
											</Radio.Container>
										</div>
									</div>
								</div>
								<div className='col-4'>
									<InputGroup>
										<InputPend icon='Dollar' iconColor='var(--dark)' />
										<Input
											className='text-right'
											type='text'
											placeholder='Subtotal'
											value={subtotal ? subtotal.toString() : ''}
											pattern={process.env.REACT_APP_PRICE_PATTERN}
											onChange={subtotalOnChange}
											disabled={disabled || !fixedPrice}
											noPermission={
												!hasEditReservationPricePermission(userContext)
											}
										/>
										{bookingPeriod === bookingPeriods.LONG_TERM && (
											<div className='input-group-append'>
												<Selects
													options={enumBookingCalculations
														.filter(
															ebc =>
																ebc.value ===
																	bookingCalculations.PER_MONTH ||
																ebc.value ===
																	bookingCalculations.PER_PERIOD
														)
														.map(setBookingCalculationLabel)}
													name='bookingCalculation'
													value={setBookingCalculationLabel(
														bookingCalculation
													)}
													onChange={bookingCalculationOnChange}
													displayKey='label'
													disabled={disabled || !fixedPrice}
												/>
											</div>
										)}
										{bookingPeriod === bookingPeriods.SEASONAL && (
											<div className='input-group-append'>
												<Selects
													options={[
														{
															name: 'Season',
															id: 1,
														},
													]}
													name='priceType'
													value={{
														id: 1,
														name: 'Season',
													}}
													onChange={() => {}}
													disabled
												/>
											</div>
										)}
									</InputGroup>
								</div>
							</>
						)}
					</div>
					<div className='row sdms-mb-15'>
						<div className='col-9'>
							<span className='sdms-font-bold sdms-font-size-1-point-2-rem'>
								Deposit Required
							</span>
						</div>
						<div className='col-3'>
							<Input
								className='text-right'
								type='text'
								placeholder='Deposit amount'
								value={depositAmount}
								onChange={depositAmountOnChange}
								onBlur={setDepositAmountShowVal}
								disabled={disabled}
								pattern={process.env.REACT_APP_PRICE_PATTERN}
								prependIcon='Dollar'
								prependIconColor='var(--dark)'
							/>
						</div>
					</div>
					<div className='row sdms-mb-15'>
						<FormField inFormDesign={false} name='ratePlan' label='Rate Plan' colMd={4}>
							<Selects
								options={availableRatePlans}
								placeholder='Rate plan'
								value={ratePlan}
								onChange={e => {
									ratePlanOnChange(e);

									policyOnChange({
										target: {
											value: getPolicy(
												pricing,
												e.target.value,
												reservationItem.product,
												policies
											),
										},
									});
									if (!fixedPrice) {
										subtotalOnChange({
											target: {
												value: numberFormat(
													pricing.ratePlans[e.target.value.id].reduce(
														(a, b) => a + b
													)
												),
											},
										});
									}
								}}
								displayKey='internalName'
								disabled={disabled}
								disableClearable
								noPermission={!hasOverrideConstraintPermission(userContext)}
							/>
						</FormField>
					</div>
					{!showMore && (
						<div className='row sdms-mb-15'>
							{Object.entries(extraCharges).map(exc => (
								<BasicExtraCharges
									key={exc[0]}
									title={exc[0]}
									extraCharges={exc[1].extraCharges}
									selectedExtraCharges={selectedExtraCharges}
									customer={customer}
									disabled={disabled}
									maxSelection={
										canOverridePriceAdjustmentGroupConstraints ? 0 : exc[1].max
									}
									onChange={_extraChargeItem => {
										if (pricing.available) {
											onExtraChargeItemUpdate(
												{ ..._extraChargeItem, category: exc[0] },
												selectedExtraCharges,
												_selectedExtraCharges =>
													selectedExtraChargesOnChange({
														target: {
															value: _selectedExtraCharges,
														},
													}),
												{
													...reservationItem,
													bookingPeriod,
													subtotal:
														subtotal === '' ? 0 : parseFloat(subtotal),
													taxCode: reservationItem.taxCode,
													fromDate: moment(reservationItem.fromDate).utc(
														false
													),
												},
												taxRate,
												getPrices(pricing, ratePlan),
												reservationItem.ignoredRules
											);
										}
									}}
								/>
							))}
						</div>
					)}
					<SlideDown>
						{showMore ? (
							<>
								<div className='row sdms-mb-15'>
									<div className='col-12' ref={extraChargesRef}>
										{Object.entries(extraCharges).map(exc => {
											return (
												<Portlet
													noPermission={
														!hasEditReservationFeesPermission(
															userContext
														)
													}
													key={`fragment${exc[0]}`}
													border
													className={classNames(
														'sdms-list-layout',
														'sdms-portlet__table--wrap-xl'
													)}>
													<Portlet.Head>
														<Portlet.HeadLabelTitle>
															{exc[0]}
														</Portlet.HeadLabelTitle>
													</Portlet.Head>
													<Portlet.Head
														className={classNames(
															'sdms-list-layout__head',
															'table--responsive--scroll'
														)}>
														<Portlet.HeadToolbar>
															<table className='table'>
																<ExtraFeeHead settingsEnable />
															</table>
														</Portlet.HeadToolbar>
													</Portlet.Head>
													<Portlet.Body className='table--responsive--scroll'>
														<table
															className={classNames(
																'table',
																`table--responsive-col--6`
															)}>
															<ExtraFeeHead />
															<tbody>
																{exc[1].extraCharges.map(
																	extraCharge => {
																		const extraChargeItem = selectedExtraCharges.find(
																			sec =>
																				sec.extraCharge
																					.id ===
																				extraCharge.id
																		);

																		return (
																			<ExtraFeeRow
																				key={extraCharge.id}
																				data={
																					extraChargeItem
																				}
																				extraCharge={{
																					...extraCharge,
																					taxCode: getItemTaxCode(
																						taxCode,
																						{
																							taxCode:
																								extraCharge.taxCode ||
																								taxCode,
																						}
																					),
																				}}
																				onUpdate={_extraChargeItem => {
																					if (
																						pricing.available
																					) {
																						onExtraChargeItemUpdate(
																							{
																								..._extraChargeItem,
																								category:
																									exc[0],
																							},
																							selectedExtraCharges,
																							_selectedExtraCharges =>
																								selectedExtraChargesOnChange(
																									{
																										target: {
																											value: _selectedExtraCharges,
																										},
																									}
																								),
																							{
																								...reservationItem,
																								bookingPeriod,
																								subtotal:
																									subtotal ===
																									''
																										? 0
																										: parseFloat(
																												subtotal
																										  ),
																								taxCode:
																									reservationItem.taxCode,
																								fromDate: moment(
																									reservationItem.fromDate
																								).utc(
																									false
																								),
																							},
																							taxRate,
																							getPrices(
																								pricing,
																								ratePlan
																							),
																							reservationItem.ignoredRules
																						);
																					}
																				}}
																				onFormChange={
																					onFormChange
																				}
																				disabled={
																					disabled ||
																					extraChargeDisabled(
																						extraCharge
																					)
																				}
																				currentExtraChargeItemProductId={
																					null
																				}
																				customer={customer}
																				onSettingsClick={() =>
																					openModal({
																						open:
																							modals.EXTRA_FEE_SETTINGS,
																						id:
																							extraChargeItem.id,
																					})
																				}
																				isValid={
																					extraChargeItem &&
																					invalidExtraCharges.indexOf(
																						extraChargeItem.id
																					) === -1
																				}
																			/>
																		);
																	}
																)}
															</tbody>
														</table>
													</Portlet.Body>
												</Portlet>
											);
										})}
									</div>
								</div>
								<div className='row sdms-mb-15'>
									<FormField
										inFormDesign={false}
										name='policy'
										label='Cancellation Policy'
										colMd={4}
										noPermission={
											!hasEditReservationCancellationPolicyPermission(
												userContext
											)
										}>
										<Selects
											options={policies}
											placeholder='Cancellation Policy'
											value={policy}
											onChange={policyOnChange}
											disabled={disabled || isCancellationPolicyDisabled}
										/>
									</FormField>
									<FormField
										inFormDesign={false}
										name='depositAmount'
										label='Deposit Required'
										valRes={depositAmountValRes}
										showValidation={depositAmountShowVal}
										colMd={4}>
										<Input
											className='text-right'
											type='text'
											placeholder='Deposit amount'
											value={depositAmount}
											onChange={depositAmountOnChange}
											onBlur={setDepositAmountShowVal}
											disabled={disabled}
											pattern={process.env.REACT_APP_PRICE_PATTERN}
											prependIcon='Dollar'
											prependIconColor='var(--dark)'
										/>
									</FormField>
									<FormField name='taxCode' label='Tax Code' colMd={4}>
										<Selects
											disabled={disabled}
											options={taxCodes}
											placeholder='Tax code'
											value={taxCode}
											onChange={taxCodeOnChange}
											disableClearable
										/>
									</FormField>
									<FormField
										name='productContracts'
										label='Product Contracts'
										inFormDesign={false}
										colMd={4}
										noPermission={
											!hasEditReservationProductContractsPermission(
												userContext
											)
										}>
										<Selects
											options={contracts}
											placeholder='Product Contracts'
											value={productContracts}
											onChange={productContractsOnChange}
											disabled={disabled}
											multiple
										/>
									</FormField>
								</div>
							</>
						) : null}
					</SlideDown>
					<div className='row sdms-mb-15 sdms-row-align-items '>
						<div className='col-auto'>
							<FormSectionMoreLink
								text='Advanced Pricing(Rate Plans, Cancellation Policies, etc.)'
								isOpen={showMore}
								onClick={() => setShowMore(!showMore)}
							/>
						</div>
					</div>
				</div>
			</FormSection>
			{modal.open === modals.EXTRA_FEE_SETTINGS &&
				selectedExtraCharges.find(sec => sec.id === modal.id) && (
					<ExtraFeeSettings
						data={selectedExtraCharges.find(sec => sec.id === modal.id)}
						onClose={closeModal}
						onFormChange={onFormChange}
						invoicingFrequencies={invoicingFrequencies}
						invoiceFirstOptions={invoiceFirstOptions}
						invoiceNextOptions={invoiceNextOptions}
						paymentTerms={paymentTerms}
						accounts={accounts}
						reservationItem={{
							...reservationItem,
							fromDate: moment(reservationItem.fromDate).utc(false),
						}}
						updateNextInvoiceDate={
							reservationItem.id === 0 && reservationItem.isWaitList
						}
						isSubmitted={false}
						setIsValid={_isValid => {
							if (!isTransient)
								setInvalidExtraCharges(
									_isValid
										? invalidExtraCharges.filter(iec => iec !== modal.id)
										: [...invalidExtraCharges, modal.id]
								);
						}}
						disableInvoicing={isTransient}
					/>
				)}
		</>
	);
};

Pricing.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	sectionRef: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	onFormChange: PropTypes.func,
	disabled: PropTypes.bool,
	accounts: PropTypes.arrayOf(PropTypes.object),
	onTotalsChange: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	taxRate: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	pricing: PropTypes.object,
	setIsValid: PropTypes.func,
	enumBookingCalculations: PropTypes.arrayOf(PropTypes.object),
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	policies: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	invoicingFrequencies: PropTypes.arrayOf(PropTypes.object),
	invoiceFirstOptions: PropTypes.arrayOf(PropTypes.object),
	invoiceNextOptions: PropTypes.arrayOf(PropTypes.object),
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	contracts: PropTypes.arrayOf(PropTypes.object),
};

Pricing.defaultProps = {
	sectionRef: null,
	reservation: null,
	reservationItem: null,
	customer: null,
	onFormChange: () => {},
	disabled: false,
	accounts: [],
	onTotalsChange: () => {},
	taxRate: null,
	pricing: null,
	setIsValid: () => {},
	enumBookingCalculations: [],
	ratePlans: [],
	policies: [],
	taxCodes: [],
	invoicingFrequencies: [],
	invoiceFirstOptions: [],
	invoiceNextOptions: [],
	paymentTerms: [],
	contracts: [],
};

export default Pricing;
