import React from 'react';
import PropTypes from 'prop-types';

import AccountForm from './AccountForm';
import FormContainer from '../../reusables/template/FormContainer';

const AccountEdit = ({ match }) => {
	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle='Account Name'
			dataName='accounts'
			lists={['accounts', 'accountTypes', 'accountDetailTypes']}
			fields={<AccountForm />}
		/>
	);
};

AccountEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default AccountEdit;
