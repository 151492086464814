import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import HeaderContext from '../../../app/contexts/HeaderContext';
import Badge from '../../reusables/element/Badge';
import pages from '../../pages';
import forms from '../../forms';

const OrderCall = ({ data }) => (
	<Badge design='info' isInline isUnified size='lg' fontWeight='boldest'>
		{data.sortOrder}
	</Badge>
);
OrderCall.propTypes = {
	data: PropTypes.shape({
		sortOrder: PropTypes.number,
	}),
};
OrderCall.defaultProps = {
	data: {
		sortOrder: 0,
	},
};

const IsSuffixCell = ({ data }) => {
	if (data.isSuffix) {
		return <Badge.Yes />;
	}
	return <Badge.No />;
};
IsSuffixCell.propTypes = {
	data: PropTypes.shape({
		isSuffix: PropTypes.bool,
	}),
};
IsSuffixCell.defaultProps = {
	data: {
		isSuffix: false,
	},
};

const ModifierPrefixList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.pos.default.text, path: pages.pos.dashboard.path },
			{ title: pages.pos.settings.text, path: pages.pos.settings.path },
			{ title: pages.pos.settings.modifierPrefixes.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.pos.settings.modifierPrefixes.text);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='modifierPrefixes'
			title={pages.pos.settings.modifierPrefixes.text}
			history={history}
			icon={pages.pos.settings.modifierPrefixes.icon}
			forms={forms.pos.settings.modifierPrefixes}>
			<List withCheckBox fluid='fluid'>
				<List.Col
					label='Sort Order'
					width={130}
					cellComponent={<OrderCall />}
					sortable='sortOrder'
				/>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col label='Is Suffix?' cellComponent={<IsSuffixCell />} sortable='isSuffix' />
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

ModifierPrefixList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default ModifierPrefixList;
