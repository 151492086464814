import React from 'react';
import Breadcrumb from '../../Breadcrumb';
import HeaderContext from '../../../app/contexts/HeaderContext';

const BreadcrumbContainer = () => {
	return (
		<HeaderContext.Consumer>
			{value => {
				if (value.breadcrumbs) {
					return (
						<Breadcrumb>
							{value.breadcrumbs.map((breadcrumb, index) => {
								return (
									<Breadcrumb.Item
										path={breadcrumb.path}
										title={breadcrumb.title || ''}
										isActive={breadcrumb.isActive}
										/* eslint-disable-next-line react/no-array-index-key */
										key={index}
									/>
								);
							})}
						</Breadcrumb>
					);
				}
				return null;
			}}
		</HeaderContext.Consumer>
	);
};

export default BreadcrumbContainer;
