import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import apiCall from '../../../../utils/helpers/apiCall';
import { addErrorNotification } from '../../../../utils/helpers/helper';
import { paymentTypes as _paymentTypes } from '../../../../utils/constants/constants';
import Search from '../../element/Search';
import Button from '../../element/Button';
import Portlet from '../../layout/Portlet';
import SelectableList from '../../template/SelectableList';
import { CustomerAddressCell, CustomerCell, NameCell } from '../CustomerModal';
import Paid from './Paid';
import NumPad from './NumPad';

const HouseAccount = ({
	amount,
	payment,
	setPaymentData,
	isRefund,
	refundAmountChangeable,
	balanceDue,
}) => {
	const [activeContent, setActiveContent] = useState('customer');

	const [data, setData] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [isLoading, setIsLoading] = useState(true);

	const [timeoutId, setTimeoutId] = useState(0);

	const totalItems = useRef(0);

	const searchInputRef = useRef();

	useEffect(() => {
		clearTimeout(timeoutId);

		if (searchText.length > 0) {
			setTimeoutId(
				setTimeout(() => {
					// setData([]);
					setIsLoading(true);

					const filters = {
						'order[displayName]': 'asc',
						'or[displayName]': searchText,
						houseAccount: true,
						isActive: true,
					};

					apiCall(
						'GET',
						'customers',
						res => {
							if (activeContent !== 'search') return;
							totalItems.current = res['hydra:totalItems'] || res.length;
							setData(res);
							setIsLoading(false);
						},
						err => {
							addErrorNotification(err.toString());
							setIsLoading(false);
						},
						'',
						null,
						filters
					);
				}, 500)
			);
		} else {
			totalItems.current = 0;
			setData([]);
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchText]);

	useEffect(() => {
		if (activeContent === 'search') searchInputRef.current.focus();
	}, [activeContent]);

	useEffect(() => {
		setPaymentData({
			type: _paymentTypes.HOUSE_ACCOUNT,
			amount: payment.amount > amount || !payment.amount ? amount.toFixed(2) : payment.amount,
			changeDue: 0,
			serviceFee: 0,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Customer Select List Display Status
	const [paymentCustomer, setPaymentCustomer] = useState(payment && payment.customer.id);

	useEffect(() => {
		setPaymentCustomer(
			paymentCustomer !== (payment && payment.customer.id)
				? payment && payment.customer.id
				: paymentCustomer
		);
		setActiveContent(paymentCustomer !== payment.customer.id ? 'customer' : activeContent);
	}, [activeContent, payment, paymentCustomer]);

	return (
		<>
			<Paid
				amountInputFocus={activeContent === 'numpad'}
				amount={balanceDue || amount}
				paid={payment.amount}
				onClick={() => {
					if (!isRefund || (isRefund && refundAmountChangeable)) {
						setActiveContent(activeContent === 'numpad' ? 'customer' : 'numpad');
						return;
					}

					if (activeContent === 'search') setActiveContent('customer');
				}}>
				{activeContent === 'search' ? (
					<Search
						inPortlet
						searchText={searchText}
						setSearchText={setSearchText}
						isSearching={isLoading}
						ref={searchInputRef}
						placeHolder='Search...'
						fluid
					/>
				) : (
					<Button
						className='h-100 sdms-p0'
						label='dark'
						icon='User'
						iconSize={36}
						block
						onClick={() =>
							setActiveContent(
								activeContent === 'customer' || activeContent === 'numpad'
									? 'search'
									: 'customer'
							)
						}>
						{payment.customer.displayName}
					</Button>
				)}
			</Paid>

			{activeContent === 'customer' && (
				<div className='row'>
					<div className='col-md-6'>
						<div className='row'>
							<div className='col-md-4 sdms-font-boldest'>First Name :</div>
							<div className='col-md-8'>{payment.customer.firstName}</div>
						</div>
						<div className='row'>
							<div className='col-md-4 sdms-font-boldest'>Last Name :</div>
							<div className='col-md-8'>{payment.customer.lastName}</div>
						</div>
						<div className='row'>
							<div className='col-md-4 sdms-font-boldest'>Phone :</div>
							<div className='col-md-8'> {payment.customer.phone}</div>
						</div>
						<div className='row'>
							<div className='col-md-4 sdms-font-boldest'>Alt. Phone :</div>
							<div className='col-md-8'>{payment.customer.altPhone}</div>
						</div>
						<div className='row'>
							<div className='col-md-4 sdms-font-boldest'>Email :</div>
							<div className='col-md-8'>{payment.customer.email}</div>
						</div>
					</div>
					<div className='col-md-6'>
						<div className='row'>
							<div className='col-md-4 sdms-font-boldest'>Address 1 :</div>
							<div className='col-md-8'>{payment.customer.addressLineOne}</div>
						</div>
						<div className='row'>
							<div className='col-md-4 sdms-font-boldest'>Address 2 :</div>
							<div className='col-md-8'>{payment.customer.addressLineTwo}</div>
						</div>
						<div className='row'>
							<div className='col-md-4 sdms-font-boldest'>City :</div>
							<div className='col-md-8'>{payment.customer.city}</div>
						</div>
						<div className='row'>
							<div className='col-md-4 sdms-font-boldest'>ZIP Code :</div>
							<div className='col-md-8'>{payment.customer.zip}</div>
						</div>
						<div className='row'>
							<div className='col-md-4 sdms-font-boldest'>State :</div>
							<div className='col-md-8'>
								{payment.customer.state && payment.customer.state.code}
							</div>
						</div>
					</div>
				</div>
			)}

			{activeContent === 'search' && (
				<Portlet className='sdms-list-layout' fluid='fluid' border>
					<SelectableList
						data={data}
						withOutPortlet
						onClick={customer => {
							setPaymentData({ customer });
						}}
						isLoading={isLoading}
						withImage>
						<SelectableList.Col
							width={65}
							label='Image'
							cellComponent={<CustomerCell />}
						/>
						<SelectableList.Col width='30%' label='Name' cellComponent={<NameCell />} />
						<SelectableList.Col
							label='Address'
							cellComponent={<CustomerAddressCell />}
						/>
					</SelectableList>
				</Portlet>
			)}

			{activeContent === 'numpad' && (
				<NumPad
					value={payment.amount}
					setValue={value => setPaymentData({ amount: value })}
					initValue={balanceDue || amount}
					max={amount}
					onCloseNumpad={() => setActiveContent('customer')}
				/>
			)}
		</>
	);
};
HouseAccount.propTypes = {
	amount: PropTypes.number.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	payment: PropTypes.object.isRequired,
	setPaymentData: PropTypes.func.isRequired,
	isRefund: PropTypes.bool.isRequired,
	refundAmountChangeable: PropTypes.bool.isRequired,
	balanceDue: PropTypes.number.isRequired,
};

export default HouseAccount;
