import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../../../app/contexts/UserContext';
import useField from '../../../../../utils/hooks/useField';

import FormSection from '../../../layout/FormSection';
import FormField from '../../../template/FormField';
import Textarea from '../../../field/Textarea';
import {
	hasEditReservationSpecialRequestsPermission,
	hasReservationEditExternalNotesPermission,
} from '../../../../../utils/helpers/permission';

const Comments = ({ reservationItem, onFormChange, disabled }) => {
	const userContext = useContext(UserContext);

	const [note, noteOnChange] = useField(reservationItem, 'note', onFormChange);

	const [internalNote, internalNoteOnChange] = useField(
		reservationItem,
		'internalNote',
		onFormChange
	);

	const [externalNote, externalNoteOnChange] = useField(
		reservationItem,
		'externalNote',
		onFormChange
	);

	return (
		<FormSection title='COMMENTS' icon='Clipboard-list'>
			<div className='sdms-advanced-reservation-item-form-comments'>
				<FormField
					name='note'
					label='Special Requests'
					inFormDesign={false}
					colMd={12}
					noPermission={!hasEditReservationSpecialRequestsPermission(userContext)}>
					<Textarea onChange={noteOnChange} value={note} />
				</FormField>
				<FormField name='internalNote' label='Internal Notes' colMd={12}>
					<Textarea
						onChange={internalNoteOnChange}
						value={internalNote}
						disabled={disabled}
					/>
				</FormField>
				<FormField
					name='externalNote'
					label='External Notes'
					colMd={12}
					noPermission={!hasReservationEditExternalNotesPermission(userContext)}>
					<Textarea
						onChange={externalNoteOnChange}
						value={externalNote}
						disabled={disabled}
					/>
				</FormField>
			</div>
		</FormSection>
	);
};

Comments.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	onFormChange: PropTypes.func,
	disabled: PropTypes.bool,
};

Comments.defaultProps = {
	reservationItem: null,
	onFormChange: () => {},
	disabled: false,
};

export default Comments;
