import React from 'react';
import PropTypes from 'prop-types';

import PrepStationForm from './PrepStationForm';
import FormContainer from '../../reusables/template/FormContainer';
import pages from '../../pages';
import useOutlet from '../../../utils/hooks/useOutlet';

const PrepStationEdit = ({ match }) => {
	const [outletKey] = useOutlet(true);

	return (
		<FormContainer
			key={outletKey}
			dataId={match.params.id}
			dataName='prepStations'
			lists={['printers', 'outlets']}
			fields={<PrepStationForm />}
			pageTitle={pages.pos.settings.prepStations.text}
			icon={pages.pos.settings.prepStations.icon}
		/>
	);
};

PrepStationEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default PrepStationEdit;
