import React from 'react';
import PropTypes from 'prop-types';

import pages from '../../pages';

import FormContainer from '../../reusables/template/FormContainer';
import MetaForm from './MetaForm';

const MetaEdit = ({ match }) => {
	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle={pages.systemSettings.metas.text}
			dataName={pages.systemSettings.metas.route}
			lists={['enumMetaTypes']}
			fields={<MetaForm />}
		/>
	);
};

MetaEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default MetaEdit;
