import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Portlet from '../../reusables/layout/Portlet';
import IconBox from '../../reusables/layout/IconBox';
import pages from '../../pages';
import ContentInner from '../../reusables/template/ContentInner';
import HeaderContext from '../../../app/contexts/HeaderContext';
import BreadcrumbContainer from '../../reusables/template/BreadcrumbContainer';
import Button from '../../reusables/element/Button';

const CampgroundReportSelect = () => {
	const history = useHistory();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.reports.default.text, path: pages.reports.dashboard.path },
			{ title: pages.reports.campground.text, path: pages.reports.campground.path },
			{ title: 'Select Report Type', isActive: true },
		]);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title='Select Report Type' />
					<BreadcrumbContainer />
				</ContentInner.SubHeaderItem>
				<ContentInner.SubHeaderItem type='toolbar'>
					<Button
						design='default'
						text='Back To The List'
						onClick={() => history.push('/reports/campground')}
					/>
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title='Select Report Type'>
				<Portlet fluid='fluid'>
					<Portlet.Body>
						<div className='row sdms-iconbox--container'>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox // newreporttype
									icon={pages.reports.campground.reservations.icon} // .products.period.hourly.icon}
									title={pages.reports.campground.reservations.text} // campground.products.period.hourly.text}
									onClick={() =>
										history.push(
											`${pages.reports.campground.reservations.path}/0` // campground.products.period.hourly.path}/0`
										)
									}
									// design={pages.campground.products.period.hourly.color}
									animate='fast'>
									Build Reservation reports.
								</IconBox>
							</div>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox
									icon={pages.reports.campground.rent_roll.icon} // .products.period.hourly.icon}
									title={pages.reports.campground.rent_roll.text} // campground.products.period.hourly.text}
									onClick={() =>
										history.push(
											`${pages.reports.campground.rent_roll.path}/0` // campground.products.period.hourly.path}/0`
										)
									}
									// design={pages.campground.products.period.hourly.color}
									animate='fast'>
									Build Rent Roll reports.
								</IconBox>
							</div>
						</div>
					</Portlet.Body>
				</Portlet>
			</ContentInner.Container>
		</>
	);
};

export default CampgroundReportSelect;
