import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import usePages from '../../../utils/hooks/usePages';

import FormContainer from '../../reusables/template/FormContainer';
import PaymentProcessorForm from './PaymentProcessorForm';
import UserContext from '../../../app/contexts/UserContext';

const PaymentProcessorEdit = ({ match }) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle={pages.companySettings.paymentProcessors.text}
			dataName={pages.companySettings.paymentProcessors.route}
			lists={['enumPaymentTypes', 'paymentGateways', 'enumCustomerPaymentForms']}
			fields={<PaymentProcessorForm />}
			afterSubmit={paymentProcess => {
				const paymentProcessorIndex = userContext.data.selectedOutlet.paymentProcessors.findIndex(
					pp => pp.id === paymentProcess.id
				);

				userContext.updateOutlet({
					...userContext.data.selectedOutlet,
					paymentProcessors:
						paymentProcessorIndex > -1
							? update(userContext.data.selectedOutlet.paymentProcessors, {
									[paymentProcessorIndex]: { $set: paymentProcess },
							  })
							: update(userContext.data.selectedOutlet.paymentProcessors, {
									$push: [paymentProcess],
							  }),
				});
			}}
		/>
	);
};

PaymentProcessorEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default PaymentProcessorEdit;
