import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import classNames from 'classnames';

import {
	addErrorNotification,
	addSuccessNotification,
	calculateServiceFee,
	filterCustomerPaymentMethods,
	getPaymentProcessorServiceFeeAmount,
	numberParser,
} from '../../../utils/helpers/helper';
import apiCall from '../../../utils/helpers/apiCall';
import {
	creditCardRequired as creditCardValidation,
	numeric,
	required,
	zero,
} from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import UserContext from '../../../app/contexts/UserContext';
import { paymentTypes } from '../../../utils/constants/constants';
import usePaymentGateway, {
	getPaymentProcessor,
	parsePaymentData,
} from '../../../utils/hooks/usePaymentGateway';

import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import Portal from '../layout/Portal';
import CreditCardForm from '../element/CreditCardForm';
import FormGroup from '../layout/FormGroup';
import FormField from '../template/FormField';
import Input from '../field/Input';

const AuthorizePaymentModal = ({
	customer,
	onSubmit,
	onClose,
	isOpen,
	isSubmitting,
	entityId,
	entityType,
	payment,
	isCapture,
	serviceFeeModule,
	defaultAmount,
	outlet,
}) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<AuthorizePaymentModalContent
					isSubmitting={isSubmitting}
					onSubmit={onSubmit}
					onClose={onClose}
					customer={customer}
					entityId={entityId}
					entityType={entityType}
					payment={payment}
					isCapture={isCapture}
					serviceFeeModule={serviceFeeModule}
					defaultAmount={defaultAmount}
					outlet={outlet}
				/>
			</Popup>
		</Portal>
	);
};
AuthorizePaymentModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	isOpen: PropTypes.bool,
	isSubmitting: PropTypes.bool,
	entityId: PropTypes.number,
	entityType: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	payment: PropTypes.object,
	isCapture: PropTypes.bool,
	serviceFeeModule: PropTypes.string,
	defaultAmount: PropTypes.number,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
};
AuthorizePaymentModal.defaultProps = {
	customer: null,
	onSubmit: () => {},
	onClose: () => {},
	isOpen: false,
	isSubmitting: false,
	entityId: 0,
	entityType: 'order',
	payment: null,
	isCapture: false,
	serviceFeeModule: '',
	defaultAmount: 1,
};

const AuthorizePaymentModalContent = ({
	customer,
	onClose,
	onSubmit,
	entityId,
	entityType,
	payment,
	isCapture,
	serviceFeeModule,
	defaultAmount,
	outlet,
}) => {
	const userContext = useContext(UserContext);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [tokenizePayment] = usePaymentGateway(outlet);

	const amountRequired = () => {
		return (value, setValRes) => {
			if (isCapture) return required(value, setValRes) && zero(value, setValRes);

			return true;
		};
	};

	const [creditCard, creditCardOnChange, creditCardValRes] = useField(
		{},
		'creditCard',
		() => {},
		[creditCardValidation],
		null
	);

	const [amount, amountOnChange, amountValRes, amountShowVal, setAmountShowVal] = useField(
		{},
		'amount',
		() => {},
		[numeric, amountRequired()],
		defaultAmount,
		numberParser(true, 0)
	);

	const [
		serviceFee,
		serviceFeeOnChange,
		serviceFeeValRes,
		serviceFeeShowVal,
		setServiceFeeShowVal,
	] = useField(
		{},
		'serviceFee',
		() => {},
		[numeric],
		payment?.serviceFee || 0,
		numberParser(true, 0)
	);

	const paymentProcessor = useMemo(() => getPaymentProcessor(outlet, paymentTypes.CREDIT_CARD), [
		outlet,
	]);

	const serviceFeeAmount = useMemo(
		() => getPaymentProcessorServiceFeeAmount(outlet, serviceFeeModule, paymentProcessor),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[paymentProcessor]
	);

	const onAuthorize = () => {
		setIsSubmitted(true);

		if (!creditCardValRes.isValid) {
			addErrorNotification('Please swipe card or manually enter!');
			return;
		}

		setIsSubmitting(true);

		tokenizePayment(
			paymentTypes.CREDIT_CARD,
			parsePaymentData(paymentTypes.CREDIT_CARD, creditCard),
			tokenizedPayment => {
				const data = {
					outletId: outlet.id,
					customerId: customer.id,
					entityId,
					entityType,
					payment: { type: paymentTypes.CREDIT_CARD, amount, serviceFee: 0, creditCard },
				};

				if (tokenizedPayment)
					data.payment.creditCard = {
						...creditCard,
						...tokenizedPayment,
					};

				if (payment?.token) data.payment.creditCard.token = payment?.token;

				apiCall(
					'POST',
					'authorizePayment',
					result => {
						addSuccessNotification('Authorized payment successfully created');
						setIsSubmitting(false);
						onSubmit(result);
					},
					err => {
						addErrorNotification(err.toString());
						setIsSubmitting(false);
					},
					'',
					data
				);
			},
			err => {
				addErrorNotification(err.toString());
				setIsSubmitting(false);
			}
		);
	};

	const onCapture = () => {
		setIsSubmitted(true);

		if (!amountValRes.isValid) return;

		setIsSubmitting(true);

		apiCall(
			'POST',
			'captureAuthorizedPayment',
			response => {
				addSuccessNotification('Payment captured');
				setIsSubmitting(false);
				onSubmit(response);
			},
			err => {
				addErrorNotification(err.toString());
				setIsSubmitting(false);
			},
			'',
			{
				id: payment.id,
				entityId,
				entityType,
				amount,
				serviceFee,
			}
		);
	};

	const getButtonText = () => {
		if (isSubmitting) return isCapture ? 'Capturing' : 'Authorizing';

		return isCapture ? 'Capture' : 'Authorize';
	};

	useEffect(() => {
		if (payment) creditCardOnChange({ target: { value: { ...payment } } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payment]);

	useEffect(() => {
		if (amount && serviceFeeAmount)
			serviceFeeOnChange({
				target: { value: calculateServiceFee(amount, serviceFeeAmount) },
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [amount, serviceFeeAmount]);

	useEffect(() => {
		if (isSubmitted) {
			setAmountShowVal();
			setServiceFeeShowVal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	return (
		<Portlet className='sdms-form'>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Wallet#3'>
					{isCapture ? 'Capture Payment' : 'Authorize Payment'}
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<form>
					<FormGroup>
						{!payment && (
							<FormField name='authorizePaymentCreditCard' col={12}>
								<CreditCardForm
									onChange={_creditCard =>
										creditCardOnChange({ target: { value: _creditCard } })
									}
									isDisabled={isSubmitting}
									isAuthorizing={isSubmitting}
									creditCards={filterCustomerPaymentMethods(
										customer?.paymentMethods,
										paymentProcessor
									)}
									isSubmitted={isSubmitted}
									withLabel={false}
									fullWidth
									displaySaveCardToggle={customer?.canSavePaymentMethod !== false}
								/>
							</FormField>
						)}
						<FormField
							label='Amount'
							inFormDesign={false}
							name='amount'
							col={6}
							valRes={amountValRes}
							showValidation={amountShowVal}>
							<Input
								value={amount}
								onChange={amountOnChange}
								onBlur={setAmountShowVal}
								prependIcon='Dollar'
								pattern={process.env.REACT_APP_PRICE_PATTERN}
								disabled={isSubmitting}
							/>
						</FormField>
						{isCapture && (
							<FormField
								label='Fee'
								inFormDesign={false}
								name='serviceFee'
								col={6}
								valRes={serviceFeeValRes}
								showValidation={serviceFeeShowVal}>
								<Input
									value={serviceFee}
									onChange={serviceFeeOnChange}
									onBlur={setServiceFeeShowVal}
									prependIcon='Dollar'
									pattern={process.env.REACT_APP_PRICE_PATTERN}
									disabled={
										isSubmitting ||
										!userContext.hasPermission('edit_cc_service_fee')
									}
								/>
							</FormField>
						)}
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot
				tall='sm'
				className='sdms-align-left'
				subClassName='justify-content-between'>
				<div className='col-auto'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
						disabled={isSubmitting}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='brand'
						icon={isSubmitting ? 'Other#2' : 'Save'}
						text={getButtonText()}
						size='sm'
						onClick={() => {
							if (isCapture) onCapture();
							else onAuthorize();
						}}
						disabled={isSubmitting}
						className={classNames(' sdms-mw-100', {
							'sdms-fading-dots': isSubmitting,
						})}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};

AuthorizePaymentModalContent.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	entityId: PropTypes.number,
	entityType: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	payment: PropTypes.object,
	isCapture: PropTypes.bool,
	serviceFeeModule: PropTypes.string,
	defaultAmount: PropTypes.number,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
};
AuthorizePaymentModalContent.defaultProps = {
	customer: null,
	onSubmit: () => {},
	onClose: () => {},
	entityId: 0,
	entityType: 'order',
	payment: null,
	isCapture: false,
	serviceFeeModule: '',
	defaultAmount: 1,
};

export default AuthorizePaymentModal;
