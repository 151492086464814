import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { achOption } from '../../../utils/helpers/helper';
import Input from '../field/Input';
import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';
import FormGroup from '../layout/FormGroup';
import FormField from '../template/FormField';
import Selects from '../field/Selects';
import Toggle from '../field/Toggle';
import achImage from '../../../assets/img/ach.png';
import Radio from '../field/Radio';
import Loading from '../template/Loading';

const CheckForm = ({
	onChange,
	isDisabled,
	achAccounts,
	withLabel,
	setIsAchEnabled,
	onlyAch,
	checkNumberProps,
	hasAch,
	isSubmitted,
	displaySaveCardToggle,
	displayCorporateToggle,
	fullWidth,
	displayDefaultToggle,
	disableDefault,
	halfInputs,
}) => {
	const [activeContent, setActiveContent] = useState(onlyAch ? 'ach' : 'default');

	const [isChecking, isCheckingOnChange] = useField({}, 'isChecking', () => {}, [], true);

	const customRequired = () => {
		return (value, setValRes) => {
			if (!achAccount && !isDisabled) {
				return required(value, setValRes);
			}
			return true;
		};
	};

	const [
		bankName,
		bankNameOnChange,
		bankNameValRes,
		bankNameShowVal,
		setBankNameShowVal,
		validateBankName,
	] = useField({}, 'bankName', () => {}, [customRequired()]);

	const [
		accountName,
		accountNameOnChange,
		accountNameValRes,
		accountNameShowVal,
		setAccountNameShowVal,
		validateAccountName,
	] = useField({}, 'accountName', () => {}, [customRequired()]);

	const [
		accountHolderName,
		accountHolderNameOnChange,
		accountHolderNameValRes,
		accountHolderNameShowVal,
		setAccountHolderNameShowVal,
		validateAccountHolderName,
	] = useField({}, 'accountHolderName', () => {}, [customRequired()]);

	const [
		routingNumber,
		routingNumberOnChange,
		routingNumberValRes,
		routingNumberShowVal,
		setRoutingNumberShowVal,
		validateRoutingNumber,
	] = useField({}, 'routingNumber', () => {}, [customRequired()]);

	const [
		accountNumber,
		accountNumberOnChange,
		accountNumberValRes,
		accountNumberShowVal,
		setAccountNumberShowVal,
		validateAccountNumber,
	] = useField({}, 'accountNumber', () => {}, [customRequired()]);

	const [achAccount, achAccountOnChange] = useField({}, 'achAccount', () => {});

	const [save, saveOnChange] = useField({}, 'save', () => {}, [], false);

	const [isCorporate, isCorporateOnChange] = useField({}, 'isCorporate', () => {}, [], false);

	const [makeDefault, makeDefaultOnChange] = useField(
		{},
		'makeDefault',
		() => {},
		[],
		displayDefaultToggle
	);

	useEffect(() => {
		onChange({
			isChecking,
			bankName,
			accountName,
			accountHolderName,
			routingNumber,
			accountNumber,
			achAccount,
			save,
			isCorporate,
			makeDefault,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isChecking,
		bankName,
		accountName,
		accountHolderName,
		routingNumber,
		accountNumber,
		achAccount,
		save,
		isCorporate,
		makeDefault,
	]);

	useEffect(() => {
		setIsAchEnabled(activeContent === 'ach');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeContent]);

	useEffect(() => {
		if (isSubmitted) {
			setBankNameShowVal();
			setAccountNameShowVal();
			setAccountHolderNameShowVal();
			setRoutingNumberShowVal();
			setAccountNumberShowVal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	useEffect(() => {
		if (achAccount || isSubmitted) {
			validateBankName(bankName);
			validateAccountName(accountName);
			validateAccountHolderName(accountHolderName);
			validateRoutingNumber(routingNumber);
			validateAccountNumber(accountNumber);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [achAccount]);

	return (
		<div className={classNames('row', 'sdms-d-block-mobile')}>
			<div
				className={classNames(
					'sdms-mb-10-mobile',
					'sdms-padding-l-15',
					'sdms-padding-r-15',

					{
						'sdms-cursor--pointer': !onlyAch,
						'col-lg-6': !fullWidth || activeContent === 'ach',
						'col-lg-12': fullWidth && activeContent !== 'ach',
					}
				)}
				style={onlyAch ? { marginTop: 'auto', marginBottom: 'auto' } : null}>
				{hasAch && achAccounts.length > 0 && activeContent === 'ach' && (
					<FormField
						className='sdms-paddingless'
						name='savedAccounts'
						id='savedAccounts'
						colLg={12}
						label={withLabel ? 'Saved Accounts' : ''}
						inFormDesign={!!checkNumberProps}>
						<Selects
							value={achAccount ? achAccounts.find(aa => aa.id === achAccount) : null}
							placeholder='Select a saved account'
							onChange={e =>
								achAccountOnChange({
									target: {
										value: e.target.value ? e.target.value.id : null,
									},
								})
							}
							options={achAccounts}
							displayKey='routingNumber'
							renderOption={achOption}
							disabled={isDisabled}
							optionalShow={option => `${option.bankName} - ${option.accountName}`}
						/>
					</FormField>
				)}
				{activeContent === 'default' && checkNumberProps && (
					<Loading isLoading={checkNumberProps.isLoading}>
						<FormField
							name='checkNumber'
							label='Check Number'
							id={checkNumberProps.id}
							colLg={12}
							showValidation={checkNumberProps.showVal}
							valRes={checkNumberProps.valRes}>
							<Input
								type='text'
								value={checkNumberProps.data}
								placeholder='Number'
								onChange={checkNumberProps.onChange}
								onBlur={checkNumberProps.setShowVal}
								disabled={checkNumberProps.id !== 0}
								prependIcon='Ticket'
							/>
						</FormField>
					</Loading>
				)}
				{hasAch && (
					<>
						<div
							role='presentation'
							onClick={() => {
								if (!isDisabled && !onlyAch)
									setActiveContent(
										activeContent === 'default' ? 'ach' : 'default'
									);
							}}>
							<div className='sdms-align-center'>
								<img src={achImage} alt='ACH' />
							</div>
						</div>
						{activeContent === 'default' && !isDisabled && (
							<div className='row align-items-center justify-content-center'>
								<div className='col-auto sdms-font-bold'>
									Click Check above to enter ACH
								</div>
							</div>
						)}
					</>
				)}
			</div>
			{activeContent === 'ach' && (
				<div className='col-lg-6'>
					<FormGroup>
						<FormField label='' name='name' colLg={12} inFormDesign={false}>
							<Radio.Container isInline>
								<Radio
									checked={isChecking}
									id='checking'
									name='checking'
									content='Checking'
									className='sdms-radio--primary'
									onChange={() => isCheckingOnChange({ target: { value: true } })}
									disabled={!!achAccount}
								/>
								<Radio
									checked={!isChecking}
									id='savings'
									name='savings'
									content='Savings'
									className='sdms-radio--primary'
									onChange={() =>
										isCheckingOnChange({ target: { value: false } })
									}
									disabled={!!achAccount}
								/>
							</Radio.Container>
						</FormField>
						<FormField
							label={withLabel ? 'Account Holder Name' : ''}
							name='accountHolderName'
							colLg={12}
							inFormDesign={false}
							showValidation={accountHolderNameShowVal}
							valRes={{ ...accountHolderNameValRes, message: '' }}>
							<Input
								name='accountHolderName'
								value={accountHolderName}
								onChange={e => {
									accountHolderNameOnChange(e);
									achAccountOnChange({ target: { value: null } });
								}}
								placeholder='Account Holder Name'
								onBlur={setAccountHolderNameShowVal}
								disabled={!!achAccount}
							/>
						</FormField>
						<FormField
							label={withLabel ? 'Bank Name' : ''}
							name='bankName'
							colLg={halfInputs ? 6 : 12}
							inFormDesign={false}
							showValidation={bankNameShowVal}
							valRes={{ ...bankNameValRes, message: '' }}>
							<Input
								name='bankName'
								value={bankName}
								onChange={e => {
									bankNameOnChange(e);
									achAccountOnChange({ target: { value: null } });
								}}
								placeholder='Bank Name'
								onBlur={setBankNameShowVal}
								disabled={!!achAccount}
							/>
						</FormField>
						<FormField
							label={withLabel ? 'Account Name' : ''}
							name='accountName'
							colLg={halfInputs ? 6 : 12}
							inFormDesign={false}
							showValidation={accountNameShowVal}
							valRes={{ ...accountNameValRes, message: '' }}>
							<Input
								name='accountName'
								value={accountName}
								onChange={e => {
									accountNameOnChange(e);
									achAccountOnChange({ target: { value: null } });
								}}
								placeholder='Account Name'
								onBlur={setAccountNameShowVal}
								disabled={!!achAccount}
							/>
						</FormField>
						<FormField
							label={withLabel ? 'Routing Number' : ''}
							name='routingNumber'
							colLg={halfInputs ? 6 : 12}
							inFormDesign={false}
							showValidation={routingNumberShowVal}
							valRes={{ ...routingNumberValRes, message: '' }}>
							<Input
								name='routingNumber'
								value={routingNumber}
								onChange={e => {
									routingNumberOnChange(e);
									achAccountOnChange({ target: { value: null } });
								}}
								placeholder='Routing (9 digits)'
								onBlur={setRoutingNumberShowVal}
								pattern={process.env.REACT_APP_ACH_ROUTING_PATTERN}
								disabled={!!achAccount}
							/>
						</FormField>
						<FormField
							label={withLabel ? 'Account Number' : ''}
							name='accountNumber'
							colLg={halfInputs ? 6 : 12}
							inFormDesign={false}
							showValidation={accountNumberShowVal}
							valRes={{ ...accountNumberValRes, message: '' }}>
							<Input
								name='accountNumber'
								value={accountNumber}
								onChange={e => {
									accountNumberOnChange(e);
									achAccountOnChange({ target: { value: null } });
								}}
								placeholder='Account Number'
								onBlur={setAccountNumberShowVal}
								pattern={process.env.REACT_APP_ACH_ACCOUNT_PATTERN}
								disabled={!!achAccount}
							/>
						</FormField>

						{displayCorporateToggle && (
							<FormField
								label='Corporate'
								name='isCorporate'
								id='isCorporate'
								col={4}
								inFormDesign={false}>
								<Toggle
									spaceLess
									value={isCorporate}
									onChange={isCorporateOnChange}
									disabled={isDisabled || !!achAccount}
								/>
							</FormField>
						)}
						{displaySaveCardToggle && (
							<FormField
								label='Save Account'
								name='saveAccount'
								id='sdms-pay--save-account'
								col={4}
								inFormDesign={false}>
								<Toggle
									id='sdms--save-cc'
									spaceLess
									value={save}
									onChange={saveOnChange}
									disabled={isDisabled || !!achAccount}
								/>
							</FormField>
						)}
						{displayDefaultToggle && (
							<FormField
								label='Default'
								name='default'
								id='sdms-pay--default-cc'
								col={4}
								inFormDesign={false}>
								<Toggle
									id='sdms-pay--default-cc'
									spaceLess
									value={makeDefault}
									onChange={makeDefaultOnChange}
									disabled={isDisabled || disableDefault || !!achAccount}
								/>
							</FormField>
						)}
					</FormGroup>
				</div>
			)}
		</div>
	);
};

CheckForm.propTypes = {
	onChange: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool,
	achAccounts: PropTypes.arrayOf(PropTypes.object),
	withLabel: PropTypes.bool,
	setIsAchEnabled: PropTypes.func,
	onlyAch: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	checkNumberProps: PropTypes.object,
	hasAch: PropTypes.bool,
	isSubmitted: PropTypes.bool,
	displaySaveCardToggle: PropTypes.bool,
	displayCorporateToggle: PropTypes.bool,
	fullWidth: PropTypes.bool,
	displayDefaultToggle: PropTypes.bool,
	disableDefault: PropTypes.bool,
	halfInputs: PropTypes.bool,
};

CheckForm.defaultProps = {
	isDisabled: false,
	achAccounts: [],
	withLabel: true,
	setIsAchEnabled: () => {},
	onlyAch: false,
	checkNumberProps: null,
	hasAch: false,
	isSubmitted: false,
	displaySaveCardToggle: false,
	displayCorporateToggle: false,
	fullWidth: false,
	displayDefaultToggle: false,
	disableDefault: false,
	halfInputs: false,
};

export default CheckForm;
