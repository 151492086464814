import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useComponentSize from '@rehooks/component-size/index';
import { useLocation } from 'react-router-dom';

import { required } from '../../../../utils/helpers/validation';
import useField from '../../../../utils/hooks/useField';
import useFeet from '../../../../utils/hooks/useFeet';
import {
	addErrorNotification,
	calculateSqft,
	convertDateToUTC,
} from '../../../../utils/helpers/helper';
import UserContext from '../../../../app/contexts/UserContext';
import apiCall, { modules } from '../../../../utils/helpers/apiCall';
import { bookBies, spaceAssigmentTypes } from '../../../../utils/constants/constants';
import { displaySpaceAssignmentTODate } from '../../../../utils/helpers/reservationHelper';
import { getBookingHourlyStep, getModuleByPath } from '../../../../utils/helpers/reusable';

import Section from '../../layout/Section';
import Button from '../../element/Button';
import FormGroup from '../../layout/FormGroup';
import FormField from '../../template/FormField';
import LengthInputGroup from '../../field/LengthInputGroup';
import Input from '../../field/Input';
import DatePicker from '../../field/DatePicker';
import TimePickerInput from '../../field/TimePickerInput';
import Radio from '../../field/Radio';
import Loading from '../../template/Loading';
import Toggle from '../../field/Toggle';
import Selects from '../../field/Selects';
import { hasOverrideConstraintPermission } from '../../../../utils/helpers/permission';

const SpaceAssignmentForm = ({
	data,
	setIsValid,
	isSubmitted,
	isLoading,
	onFormChange,
	enumSpaceAssignmentTypes,
	marinaUnits,
	campgroundUnits,
	isListsLoading,
	presetData,
}) => {
	const userContext = useContext(UserContext);

	const location = useLocation();

	const module = useMemo(() => {
		return getModuleByPath(location.pathname);
	}, [location]);

	const [isSearching, setIsSearching] = useState(false);

	const refForTimePicker = useRef(null);

	const sizeTimePicker = useComponentSize(refForTimePicker);

	const timePickerStep = useRef(getBookingHourlyStep(userContext));

	const [haveDatesChange, setHaveDatesChange] = useState(false);

	const fromDateValidation = (value, setValRes) => {
		if (!fromDate || !value) return true;

		if (moment(fromDate).isSameOrAfter(moment(value))) {
			setValRes({
				isValid: false,
				status: 'invalidDate',
				message: 'End date cannot be before start date',
			});
			return false;
		}
		return true;
	};

	const dateChangeValidation = (value, setValRes) => {
		if (haveDatesChange) {
			setValRes({
				isValid: false,
				status: 'invalidSpace',
				message: 'Please search availability',
			});
			return false;
		}

		return false;
	};

	const getMinDateForToDate = () => {
		if (fromDate) return convertDateToUTC(moment(fromDate).toDate());

		if (presetData.minDate) return convertDateToUTC(moment(presetData.minDate).toDate());

		return null;
	};

	const [loaFt, , loaIn] = useFeet(data.loa, () => {}, isLoading, data.loa);

	const [beamFt, , beamIn] = useFeet(data.beam, () => {}, isLoading, data.beam);

	const [draftFt, , draftIn] = useFeet(data.draft, () => {}, isLoading, data.draft);

	const [heightFt, , heightIn] = useFeet(data.height, () => {}, isLoading, data.height);

	const [
		fromDate,
		fromDateOnChange,
		fromDateValRes,
		fromDateShowVal,
		setFromDateShowVal,
	] = useField(data, 'fromDate', onFormChange, [required], '');

	const [toDate, toDateOnChange, toDateValRes, toDateShowVal, setToDateShowVal] = useField(
		data,
		'toDate',
		onFormChange,
		[required, fromDateValidation],
		''
	);

	const [type, typeOnChange, typeValRes, typeShowVal, setTypeShowVal] = useField(
		data,
		'type',
		onFormChange,
		[required],
		null
	);

	const [ignoredRules, ignoredRulesOnChange] = useField(
		data,
		'ignoredRules',
		onFormChange,
		[],
		false
	);

	const [ignoredCapacity, ignoredCapacityOnChange] = useField(
		data,
		'ignoredCapacity',
		onFormChange,
		[],
		false
	);

	const [
		space,
		spaceOnChange,
		spaceValRes,
		spaceShowVal,
		setSpaceShowVal,
		validateSpace,
	] = useField(data, 'space', onFormChange, [required, dateChangeValidation], null);

	const [availableUnits, setAvailableUnits] = useState([]);

	const checkAvailability = (_ignoreRules = false, _ignoreCapacity = false) => {
		setIsSearching(true);

		apiCall(
			'POST',
			'advancedReservationGetUnitStatesForSpaceAssignment',
			res => {
				if (res.length === 0) addErrorNotification('There are no available units');

				let moduleUnits = [];

				if (module === modules.MARINA) moduleUnits = marinaUnits;
				else if (module === modules.CAMPGROUND) moduleUnits = campgroundUnits;

				setAvailableUnits(
					[...new Set(res)]
						.map(unitId => moduleUnits.find(mu => mu.id === unitId))
						.filter(item => item !== undefined)
				);

				setIsSearching(false);

				setHaveDatesChange(false);
			},
			err => {
				addErrorNotification(err.toString().replace('Error:', ''));

				setIsSearching(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				fromDate,
				toDate,
				ignoreRules: _ignoreRules,
				ignoreCapacity: _ignoreCapacity,
				capacity: 1,
				loa: data.loa,
				beam: data.beam,
				draft: data.draft,
				weight: data.weight,
				height: data.height,
				sqft: calculateSqft(data.loa, data.beam),
				productId: data.reservationItem.product.id,
				ignoredSpaceAssignmentId: data.id,
			}
		);
	};

	useEffect(() => {
		if (isSubmitted) {
			setFromDateShowVal();
			setToDateShowVal();
			setTypeShowVal();
			setSpaceShowVal();
		}
	}, [isSubmitted, setFromDateShowVal, setToDateShowVal, setTypeShowVal, setSpaceShowVal]);

	useEffect(() => {
		setIsValid(
			fromDateValRes.isValid &&
				toDateValRes.isValid &&
				typeValRes.isValid &&
				(type.value === spaceAssigmentTypes.TEMPORARY_DEPARTED || spaceValRes.isValid)
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		setIsValid,
		fromDateValRes.isValid,
		toDateValRes.isValid,
		typeValRes.isValid,
		spaceValRes.isValid,
		type,
	]);

	useEffect(() => {
		validateSpace(space);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [haveDatesChange]);

	return (
		<>
			<Section title='Vessel'>
				<Section.Body>
					<FormGroup isLast>
						<FormField label='Vessel' name='vessel' id={data.id} colMd={3}>
							<Button
								className='h-100 sdms-fitText'
								label='dark'
								icon='Marina'
								block
								disabled>
								{data.vessel ? data.vessel.name : 'Select a Vessel'}
							</Button>
						</FormField>
						<FormField name='loa' label='Length Overall' id={data.id} colMd={3}>
							<LengthInputGroup
								ft={loaFt}
								ftOnChange={() => {}}
								inch={loaIn}
								inchOnChange={() => {}}
								placeHolder='Length Overall'
								disabled
							/>
						</FormField>
						<FormField name='beam' label='Beam' id={data.id} colMd={3}>
							<LengthInputGroup
								ft={beamFt}
								ftOnChange={() => {}}
								inch={beamIn}
								inchOnChange={() => {}}
								placeHolder='Beam'
								disabled
							/>
						</FormField>
						{module === modules.MARINA && (
							<FormField name='draft' label='Draft' id={data.id} colMd={3}>
								<LengthInputGroup
									ft={draftFt}
									ftOnChange={() => {}}
									inch={draftIn}
									inchOnChange={() => {}}
									placeHolder='Draft'
									disabled
								/>
							</FormField>
						)}
						<FormField name='height' label='Height' id={data.id} colMd={3}>
							<LengthInputGroup
								ft={heightFt}
								ftOnChange={() => {}}
								inch={heightIn}
								inchOnChange={() => {}}
								placeHolder='Height'
								disabled
							/>
						</FormField>
						{module === modules.MARINA && (
							<FormField name='weight' label='Weight' id={data.id} colMd={3}>
								<Input
									type='number'
									withOutSpin
									min={0}
									placeholder='Weight'
									value={data.weight || ''}
									onChange={() => {}}
									append='lbs'
									disabled
								/>
							</FormField>
						)}
					</FormGroup>
				</Section.Body>
			</Section>
			<Section title='Dates'>
				<Section.Body>
					<FormGroup isLast>
						<Loading isLoading={isLoading}>
							<FormField
								name='fromDate'
								label='Start date'
								id={data.id}
								colMd={3}
								showValidation={fromDateShowVal}
								valRes={fromDateValRes}>
								<DatePicker
									id='fromDate'
									type='calendar'
									value={
										fromDate
											? convertDateToUTC(
													moment(fromDate)
														.utc(false)
														.toDate()
											  )
											: null
									}
									onChange={e => {
										const _fromDate = moment(fromDate || undefined)
											.utc(false)
											.year(moment(e.target.value).year())
											.month(moment(e.target.value).month())
											.date(moment(e.target.value).date());

										fromDateOnChange({
											target: {
												value: fromDate
													? _fromDate.toISOString()
													: _fromDate.startOf('day').toISOString(),
											},
										});

										setHaveDatesChange(true);
										onFormChange();
									}}
									disabled={isSearching}
									onBlur={setFromDateShowVal}
									minDate={
										presetData.minDate
											? convertDateToUTC(moment(presetData.minDate).toDate())
											: null
									}
									maxDate={
										presetData.maxDate
											? convertDateToUTC(moment(presetData.maxDate).toDate())
											: null
									}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								ref={refForTimePicker}
								name='fromDate'
								label='Start time'
								id={data.id}
								colMd={3}
								showValidation={fromDateShowVal}
								valRes={fromDateValRes}>
								<TimePickerInput
									showSecond={false}
									value={fromDate ? moment(fromDate).utc(false) : null}
									defaultValue={moment()}
									onChange={target => {
										fromDateOnChange({
											target: {
												value: moment(fromDate || undefined)
													.utc(false)
													.hour(target.hour())
													.minute(target.minute())
													.toISOString(),
											},
										});

										setHaveDatesChange(true);
										onFormChange();
									}}
									size={sizeTimePicker}
									use12Hours
									minuteStep={timePickerStep.current}
									disabled={isSearching}
									onClose={() => setFromDateShowVal()}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='toDate'
								label='End date'
								id={data.id}
								colMd={3}
								showValidation={toDateShowVal}
								valRes={toDateValRes}>
								<DatePicker
									id='toDate'
									type='calendar'
									value={
										toDate && displaySpaceAssignmentTODate(data, type)
											? convertDateToUTC(
													moment(toDate)
														.utc(false)
														.toDate()
											  )
											: null
									}
									onChange={e => {
										const _toDate = moment(toDate || undefined)
											.utc(false)
											.year(moment(e.target.value).year())
											.month(moment(e.target.value).month())
											.date(moment(e.target.value).date());
										toDateOnChange({
											target: {
												value: toDate
													? _toDate.toISOString()
													: _toDate.endOf('day').toISOString(),
											},
										});
										setHaveDatesChange(true);

										onFormChange();
									}}
									onBlur={setToDateShowVal}
									minDate={getMinDateForToDate()}
									maxDate={
										presetData.maxDate
											? convertDateToUTC(moment(presetData.maxDate).toDate())
											: null
									}
									disabled={
										isSearching ||
										(type && type.value === spaceAssigmentTypes.PERMANENT_MOVE)
									}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='toDate'
								label='End time'
								id={data.id}
								colMd={3}
								showValidation={toDateShowVal}
								valRes={toDateValRes}>
								<TimePickerInput
									showSecond={false}
									defaultValue={moment()}
									value={
										toDate && displaySpaceAssignmentTODate(data, type)
											? moment(toDate).utc(false)
											: null
									}
									onChange={target => {
										toDateOnChange({
											target: {
												value: moment(toDate || undefined)
													.utc(false)
													.hour(target.hour())
													.minute(target.minute())
													.toISOString(),
											},
										});
										setHaveDatesChange(true);
										onFormChange();
									}}
									size={sizeTimePicker}
									use12Hours
									minuteStep={timePickerStep.current}
									onClose={() => setToDateShowVal()}
									disabled={
										isSearching ||
										(type && type.value === spaceAssigmentTypes.PERMANENT_MOVE)
									}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='type'
								label='Movement Type'
								id={data.id}
								loadingContainer
								colLg={12}
								valRes={typeValRes}
								showValidation={typeShowVal}>
								<Radio.Container isInline>
									{enumSpaceAssignmentTypes.map(sap => (
										<Radio
											key={`spaceAssignmentType${sap.id}`}
											checked={(type && type.id === sap.id) || false}
											id={sap.id.toString()}
											name='type'
											content={sap.value}
											className='sdms-radio--primary'
											onChange={() => {
												typeOnChange({
													target: { value: sap },
												});

												if (
													sap.value ===
													spaceAssigmentTypes.TEMPORARY_DEPARTED
												) {
													ignoredRulesOnChange({
														target: { value: false },
													});
													ignoredCapacityOnChange({
														target: { value: false },
													});
													spaceOnChange({ target: { value: null } });
												}

												if (
													sap.value ===
														spaceAssigmentTypes.PERMANENT_MOVE &&
													presetData.maxDate
												) {
													toDateOnChange({
														target: {
															value: moment(presetData.maxDate).utc(
																false
															),
														},
													});
													setHaveDatesChange(true);
												}
											}}
											disabled={isSearching}
										/>
									))}
								</Radio.Container>
							</FormField>
						</Loading>
					</FormGroup>
				</Section.Body>
			</Section>
			<Section title='Search'>
				<Section.Body>
					<FormGroup isLast>
						<Loading isLoading={isLoading}>
							<FormField name='ignoredRules' label='No rules' id={data.id} colMd={3}>
								<Toggle
									value={ignoredRules}
									onChange={ignoredRulesOnChange}
									noPermission={!hasOverrideConstraintPermission(userContext)}
									disabled={
										isSearching ||
										(type &&
											type.value === spaceAssigmentTypes.TEMPORARY_DEPARTED)
									}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='ignoredCapacity'
								label='Ignore capacity'
								id={data.id}
								colMd={3}>
								<Toggle
									value={ignoredCapacity}
									onChange={ignoredCapacityOnChange}
									noPermission={!hasOverrideConstraintPermission(userContext)}
									disabled={
										isSearching ||
										(type &&
											type.value ===
												spaceAssigmentTypes.TEMPORARY_DEPARTED) ||
										(data.reservationItem &&
											data.reservationItem.product.bookingType.bookBy
												.value === bookBies.UNIT)
									}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading || isListsLoading}>
							<FormField
								name='searchAvailability'
								label='Search Availability'
								id={data.id}
								colMd={3}
								description={
									(type &&
										type.value === spaceAssigmentTypes.TEMPORARY_DEPARTED &&
										'Temporary Departed selected') ||
									''
								}>
								<Button
									label='brand'
									text='Search Availability'
									icon='Search'
									size='sm'
									block
									disabled={
										isSearching ||
										(type &&
											type.value ===
												spaceAssigmentTypes.TEMPORARY_DEPARTED) ||
										isListsLoading
									}
									onClick={() => {
										setFromDateShowVal();
										setToDateShowVal();

										if (fromDateValRes.isValid && toDateValRes.isValid)
											checkAvailability(ignoredRules, ignoredCapacity);
									}}
									isSubmitting={isSearching}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading || isListsLoading}>
							<FormField
								name='space'
								label='Space'
								id={data.id}
								valRes={spaceValRes}
								noPermission={isSearching}
								showValidation={
									spaceShowVal &&
									type &&
									type.value !== spaceAssigmentTypes.TEMPORARY_DEPARTED
								}
								colMd={3}>
								<Selects
									options={availableUnits}
									placeholder='Select a Space'
									value={space}
									onChange={spaceOnChange}
									onBlur={setSpaceShowVal}
									disabled={
										isSearching ||
										(type &&
											type.value ===
												spaceAssigmentTypes.TEMPORARY_DEPARTED) ||
										haveDatesChange ||
										isListsLoading
									}
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</Section.Body>
			</Section>
		</>
	);
};
SpaceAssignmentForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		loa: PropTypes.number,
		beam: PropTypes.number,
		height: PropTypes.number,
		weight: PropTypes.number,
		draft: PropTypes.number,
		vessel: PropTypes.object,
		minDate: PropTypes.string,
		maxDate: PropTypes.string,
		reservationItem: PropTypes.object,
	}),
	enumSpaceAssignmentTypes: PropTypes.arrayOf(PropTypes.object),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	isListsLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	marinaUnits: PropTypes.arrayOf(PropTypes.object),
	campgroundUnits: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	presetData: PropTypes.object,
};
SpaceAssignmentForm.defaultProps = {
	data: {
		id: 0,
	},
	enumSpaceAssignmentTypes: [],
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	isListsLoading: false,
	onFormChange: () => {},
	marinaUnits: [],
	campgroundUnits: [],
	presetData: null,
};

export default SpaceAssignmentForm;
