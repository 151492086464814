import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import Separator from '../../../layout/Separator';
import Portlet from '../../../layout/Portlet';
import ProductItem from './ProductItem';

/**
 * @param productCategories array of categories
 * @param productCategories.array_member[].products array of products
 * @param productCategories.array_member[].categoryName string
 * @param productCategories.array_member[].products.array_member[].depositType array of pricing plans
 * @param productCategories.array_member[].products.array_member[].pricing array of pricing plans
 * @param productCategories.array_member[].products.array_member[].pricing.prices array of prices
 * @param productCategories.array_member[].products.array_member[].pricing.ratePlan rate plan
 * @param productCategories.array_member[].products.array_member[].pricing.failedConstraints failed constraints
 * @param productCategories.array_member[].products.array_member[].pricing.ratePlanName rate plan name
 * @param productCategories.array_member[].products.array_member[].extraCharges.array_member[].isOptional
 * @param productCategories.array_member[].products.array_member[].extraCharges.array_member[].promoCodeEnabled
 * @param productCategories.array_member[].products.array_member[].extraCharges.array_member[].isTaxable
 * @param productCategories.array_member[].products.array_member[].extraCharges.array_member[].isTax
 * @param productCategoryKey productCategory id
 * @param setSelectedProduct function
 */
const CategoryItem = ({
	productCategories,
	productCategoryKey,
	selectProduct,
	isLoading,
	multipleSelected,
	setMultipleSelected,
	outlet,
	isOnline,
	initProducts,
	displayHeader,
	searchData,
}) => {
	const hasCategoryFlexibleDates = () =>
		Object.values(productCategories[productCategoryKey].products).filter(
			p => Object.values(p.pricing).filter(pricing => pricing.hasAnyAvailable).length > 0
		).length > 0;

	const inCategory = useMemo(
		() => productCategoryKey !== 'No Category' || hasCategoryFlexibleDates(),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[productCategoryKey]
	);

	const productKeys = Object.keys(productCategories[productCategoryKey].products).sort(
		(a, b) =>
			productCategories[productCategoryKey].products[a].sortOrder -
			productCategories[productCategoryKey].products[b].sortOrder
	);

	const _Products = productKeys.map((productKey, index) =>
		inCategory ? (
			<Fragment key={`${productCategoryKey}${productKey}`}>
				<ProductItem
					productCategories={productCategories}
					productCategoryKey={productCategoryKey}
					productKey={productKey}
					selectProduct={selectProduct}
					isLoading={isLoading}
					multipleSelected={multipleSelected}
					setMultipleSelected={setMultipleSelected}
					outlet={outlet}
					isOnline={isOnline}
					initProducts={initProducts}
					searchData={searchData}
				/>
				{index < productKeys.length - 1 && <Separator />}
			</Fragment>
		) : (
			<Portlet className='flex-grow-0' key={`${productCategoryKey}${productKey}`}>
				<Portlet.Body>
					<ProductItem
						productCategories={productCategories}
						productCategoryKey={productCategoryKey}
						productKey={productKey}
						selectProduct={selectProduct}
						isLoading={isLoading}
						multipleSelected={multipleSelected}
						setMultipleSelected={setMultipleSelected}
						outlet={outlet}
						isOnline={isOnline}
						initProducts={initProducts}
						searchData={searchData}
					/>
				</Portlet.Body>
			</Portlet>
		)
	);

	if (inCategory) {
		return (
			<Portlet className='flex-grow-0'>
				{displayHeader && (
					<Portlet.Head stickyTop={0}>
						<Portlet.HeadLabelTitle>
							{productCategoryKey !== 'No Category' &&
								productCategories[productCategoryKey].categoryName}
						</Portlet.HeadLabelTitle>
						{hasCategoryFlexibleDates() && (
							<Portlet.HeadToolbar>
								<div className='sdms-online-booking-info-box row'>
									<div className='sdms-online-booking-occupied-box col' />
									<div className='sdms-online-booking-info-box-text  sdms-margin-t-10'>
										Occupied
									</div>
								</div>
								<div className='sdms-online-booking-info-box row'>
									<div className='sdms-online-booking-available-box col' />
									<div className='sdms-online-booking-info-box-text  sdms-margin-t-10'>
										Available
									</div>
								</div>
							</Portlet.HeadToolbar>
						)}
					</Portlet.Head>
				)}
				<Portlet.Body>{_Products}</Portlet.Body>
			</Portlet>
		);
	}

	return _Products;
};
CategoryItem.propTypes = {
	productCategories: PropTypes.objectOf(PropTypes.object).isRequired,
	productCategoryKey: PropTypes.string.isRequired,
	selectProduct: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	multipleSelected: PropTypes.object,
	setMultipleSelected: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	isOnline: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initProducts: PropTypes.array.isRequired,
	displayHeader: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	searchData: PropTypes.object,
};
CategoryItem.defaultProps = {
	multipleSelected: null,
	displayHeader: true,
	searchData: null,
};

export default CategoryItem;
