import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../../../assets/paymentMethodSelect.css';
import achImage from '../../../assets/img/ach.png';
import ccImage from '../../../assets/img/cc.png';
import { paymentTypes } from '../../../utils/constants/constants';
import SVGIcon from './SVGIcon';

const PaymentMethodSelect = ({
	availablePaymentTypes,
	paymentProcessors,
	onSelect,
	selected,
	render,
	customerPaymentForm,
	disabled,
}) => {
	const [availablePaymentProcessors, setAvailablePaymentProcessors] = useState([]);

	const getImage = type => {
		if (type === paymentTypes.CREDIT_CARD) return ccImage;

		if (type === paymentTypes.CHECK) return achImage;

		return '';
	};

	const getItemContent = paymentProcessor => {
		return (
			<>
				<div>
					<img
						className={
							paymentProcessor.paymentType.value === paymentTypes.CHECK
								? 'sdms-payment-method-half-image'
								: ''
						}
						src={getImage(paymentProcessor.paymentType.value)}
						alt={paymentProcessors.name}
					/>
				</div>
				<div className='sdms-font-bold'>{paymentProcessor.name}</div>
				{paymentProcessor.serviceFeeAmount > 0 && (
					<div className='sdms-payment-method-select-fee-text'>
						<SVGIcon name='Info-circle' size={16} fill='var(--success)' />
						{`${paymentProcessor.serviceFeeAmount}% Fee`}
					</div>
				)}
			</>
		);
	};

	useEffect(() => {
		const _availablePaymentProcessors = [];

		availablePaymentTypes.forEach(type => {
			const paymentProcessor = paymentProcessors.find(
				pp =>
					pp.enabled &&
					pp.paymentType.value === type &&
					pp.customerPaymentForms.some(cpf => cpf.value === customerPaymentForm)
			);

			if (paymentProcessor) _availablePaymentProcessors.push(paymentProcessor);
		});

		const sortedPaymentProcessors = _availablePaymentProcessors.sort((a, b) =>
			a.clientSort > b.clientSort ? 1 : -1
		);

		setAvailablePaymentProcessors(sortedPaymentProcessors);

		if (sortedPaymentProcessors.length > 0)
			onSelect(sortedPaymentProcessors[0].paymentType.value);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (availablePaymentProcessors.length < 2) return null;

	return render(
		<div className='col-lg-12 col-md-12'>
			<div className='row'>
				{availablePaymentProcessors.map(app => (
					<div
						key={app.id}
						role='presentation'
						className={classNames(
							'col-md-3 sdms-payment-method-select-item sdms-align-center align-items-center justify-content-center sdms-cursor--pointer',
							{
								'sdms-payment-method-select-item-selected':
									selected === app.paymentType.value,
								'sdms-disabled': disabled,
							}
						)}
						onClick={() => {
							if (!disabled) onSelect(app.paymentType.value);
						}}>
						{getItemContent(app)}
					</div>
				))}
			</div>
		</div>
	);
};

PaymentMethodSelect.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	availablePaymentTypes: PropTypes.array,
	paymentProcessors: PropTypes.arrayOf(PropTypes.object),
	onSelect: PropTypes.func,
	selected: PropTypes.string,
	render: PropTypes.func.isRequired,
	customerPaymentForm: PropTypes.string,
	disabled: PropTypes.bool,
};

PaymentMethodSelect.defaultProps = {
	availablePaymentTypes: [],
	paymentProcessors: [],
	onSelect: () => {},
	selected: '',
	customerPaymentForm: null,
	disabled: false,
};

export default PaymentMethodSelect;
