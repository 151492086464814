import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
	getNextInvoiceDate,
	getNextInvoiceDateMin,
} from '../../../utils/helpers/reservationHelper';
import UserContext from '../../../app/contexts/UserContext';
import {
	firstInvoiceOptions,
	invoicingFrequencies as _invoicingFrequencies,
	nextInvoiceOptions,
} from '../../../utils/constants/constants';
import {
	dateFormatterWithoutYear,
	filterInvoicingFrequency,
	getNextInvoice,
	getNextInvoiceOptions,
	isInvoicingFrequencyBigger,
	parseDatePickerChange,
	parseDatePickerValue,
} from '../../../utils/helpers/helper';
import { required } from '../../../utils/helpers/validation';

import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import FormGroup from '../layout/FormGroup';
import FormField from '../template/FormField';
import Selects from '../field/Selects';
import Toggle from '../field/Toggle';
import DatePicker from '../field/DatePicker';
import useField from '../../../utils/hooks/useField';
import Section from '../layout/Section';

const ExtraFeeSettings = ({
	data,
	onClose,
	onFormChange,
	invoicingFrequencies,
	invoiceNextOptions,
	paymentTerms,
	invoiceFirstOptions,
	accounts,
	reservationItem,
	updateNextInvoiceDate,
	isSubmitted,
	setIsValid,
	disableInvoicing,
}) => {
	const userContext = useContext(UserContext);

	const areInvoicingFieldsDisabled = () =>
		data.prevInvoiceDate ||
		!invoicingFrequency ||
		invoicingFrequency.value === _invoicingFrequencies.SAME_AS_RESERVATION ||
		invoicingFrequency.value === _invoicingFrequencies.SAME_AS_RESERVATION;

	const firstInvoiceRequired = (value, setValRes) => {
		if (
			invoicingFrequency &&
			invoicingFrequency.value !== _invoicingFrequencies.SAME_AS_RESERVATION &&
			invoicingFrequency.value !== _invoicingFrequencies.UPFRONT
		)
			return required(value, setValRes);

		return true;
	};

	const nextInvoiceRequired = (value, setValRes) => {
		if (
			invoicingFrequency &&
			invoicingFrequency.value !== _invoicingFrequencies.SAME_AS_RESERVATION &&
			invoicingFrequency.value !== _invoicingFrequencies.UPFRONT &&
			firstInvoice
		)
			return required(value, setValRes);

		return true;
	};

	const proratedFrequencyRequired = (value, setValRes) => {
		if (firstInvoice && firstInvoice.value === firstInvoiceOptions.PRO_RATE)
			return required(value, setValRes);
		return true;
	};

	const coTermRequired = (value, setValRes) => {
		if (nextInvoice && nextInvoice.value === nextInvoiceOptions.CO_TERM)
			return required(value, setValRes);
		return true;
	};

	// conditionally required for deferred fields.
	const deferredRequired = (value, setValRes) => {
		if (enableDeferredIncome) return required(value, setValRes);
		return true;
	};

	const [
		invoicingFrequency,
		invoicingFrequencyOnChange,
		invoicingFrequencyValRes,
		invoicingFrequencyShowVal,
		setInvoicingFrequencyShowVal,
	] = useField(data, 'invoicingFrequency', onFormChange, [required], null);

	const [paymentTerm, paymentTermOnChange] = useField(
		data,
		'paymentTerm',
		onFormChange,
		[],
		null
	);

	const [
		firstInvoice,
		firstInvoiceOnChange,
		firstInvoiceValRes,
		firstInvoiceShowVal,
		setFirstInvoiceShowVal,
		validateFirstInvoice,
	] = useField(data, 'firstInvoice', onFormChange, [firstInvoiceRequired], null);

	const [
		nextInvoice,
		nextInvoiceOnChange,
		nextInvoiceValRes,
		nextInvoiceShowVal,
		setNextInvoiceShowVal,
		validateNextInvoice,
	] = useField(data, 'nextInvoice', onFormChange, [nextInvoiceRequired], null);

	const [
		proratedFrequency,
		proratedFrequencyOnChange,
		proratedFrequencyValRes,
		proratedFrequencyShowVal,
		setProratedFrequencyShowVal,
		validateProratedFrequency,
	] = useField(data, 'proratedFrequency', onFormChange, [proratedFrequencyRequired], null);

	const [
		coTermDate,
		coTermDateOnChange,
		coTermDateValRes,
		coTermDateShowVal,
		setCoTermDateShowVal,
		validateCoTermDate,
	] = useField(data, 'coTermDate', onFormChange, [coTermRequired], null);

	const [prevInvoiceDate, prevInvoiceDateOnChange] = useField(
		data,
		'prevInvoiceDate',
		onFormChange,
		[],
		null
	);

	const [nextInvoiceDate, nextInvoiceDateOnChange] = useField(
		data,
		'nextInvoiceDate',
		onFormChange,
		[],
		null
	);

	const [enableDeferredIncome, enableDeferredIncomeOnChange] = useField(
		data,
		'enableDeferredIncome',
		onFormChange,
		[],
		false
	);

	const [
		deferredIncomeFrequency,
		deferredIncomeFrequencyOnChange,
		deferredIncomeFrequencyValRes,
		deferredIncomeFrequencyShowVal,
		setDeferredIncomeFrequencyShowVal,
		validateDeferredIncomeFrequency,
	] = useField(data, 'deferredIncomeFrequency', onFormChange, [deferredRequired], null);

	const [
		deferredIncomeSalesAccount,
		deferredIncomeSalesAccountOnChange,
		deferredIncomeSalesAccountValRes,
		deferredIncomeSalesAccountShowVal,
		setDeferredIncomeSalesAccountShowVal,
		validateDeferredIncomeSalesAccount,
	] = useField(data, 'deferredIncomeSalesAccount', onFormChange, [deferredRequired], null);

	useEffect(() => {
		if (
			updateNextInvoiceDate &&
			invoicingFrequency &&
			invoicingFrequency.value !== _invoicingFrequencies.SAME_AS_RESERVATION
		) {
			nextInvoiceDateOnChange({
				target: {
					value: getNextInvoiceDate(
						reservationItem.nextInvoiceDate || reservationItem.fromDate,
						firstInvoice,
						nextInvoice,
						coTermDate
					),
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstInvoice, nextInvoice, coTermDate]);

	useEffect(() => {
		if (isSubmitted) {
			setInvoicingFrequencyShowVal();
			setFirstInvoiceShowVal();
			setNextInvoiceShowVal();
			setProratedFrequencyShowVal();
			setCoTermDateShowVal();
			setDeferredIncomeFrequencyShowVal();
			setDeferredIncomeSalesAccountShowVal();
		}
	}, [
		isSubmitted,
		setInvoicingFrequencyShowVal,
		setFirstInvoiceShowVal,
		setNextInvoiceShowVal,
		setProratedFrequencyShowVal,
		setCoTermDateShowVal,
		setDeferredIncomeFrequencyShowVal,
		setDeferredIncomeSalesAccountShowVal,
	]);

	useEffect(() => {
		setIsValid(
			invoicingFrequencyValRes.isValid &&
				firstInvoiceValRes.isValid &&
				nextInvoiceValRes.isValid &&
				proratedFrequencyValRes.isValid &&
				coTermDateValRes.isValid &&
				deferredIncomeFrequencyValRes.isValid &&
				deferredIncomeSalesAccountValRes.isValid
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		invoicingFrequencyValRes.isValid,
		firstInvoiceValRes.isValid,
		nextInvoiceValRes.isValid,
		proratedFrequencyValRes.isValid,
		coTermDateValRes.isValid,
		deferredIncomeFrequencyValRes.isValid,
		deferredIncomeSalesAccountValRes.isValid,
	]);

	useEffect(() => {
		validateFirstInvoice(firstInvoice);
		validateNextInvoice(nextInvoice);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoicingFrequency]);

	useEffect(() => {
		validateProratedFrequency(proratedFrequency);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstInvoice]);

	useEffect(() => {
		validateCoTermDate(coTermDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextInvoice]);

	useEffect(() => {
		// re-validate deferred field on enableDeferredIncome change.
		validateDeferredIncomeFrequency(deferredIncomeFrequency);
		validateDeferredIncomeSalesAccount(deferredIncomeSalesAccount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enableDeferredIncome]);

	return (
		<div className='sdms-quick-panel sdms-quick-panel--right sdms-quick-panel--on sdms-quick-panel-medium'>
			<Portlet hasFrame fluid='fluid' className='sdms-marginless h-100'>
				<Portlet.Head>
					<Portlet.HeadLabelTitle portletIcon='Component'>
						Price Adjustment Settings
					</Portlet.HeadLabelTitle>
				</Portlet.Head>
				<Portlet.Body>
					{!disableInvoicing && (
						<Section title='Invoice Settings'>
							<Section.Body>
								<FormGroup>
									<FormField
										name='invoicingFrequency'
										label='Frequency'
										id={data.id}
										colMd={6}
										showValidation={invoicingFrequencyShowVal}
										valRes={invoicingFrequencyValRes}>
										<Selects
											options={filterInvoicingFrequency(
												invoicingFrequencies,
												data.extraCharge.bookingPeriod.value
											)}
											placeholder='Frequency'
											value={invoicingFrequency}
											onChange={e => {
												invoicingFrequencyOnChange(e);

												// if invoicing frequency set same as reservation update all invoice settings with reservation invoice settings.
												if (
													e.target.value &&
													e.target.value.value ===
														_invoicingFrequencies.SAME_AS_RESERVATION
												) {
													paymentTermOnChange({
														target: {
															value: reservationItem.paymentTerm,
														},
													});
													firstInvoiceOnChange({
														target: {
															value: reservationItem.firstInvoice,
														},
													});
													nextInvoiceOnChange({
														target: {
															value: reservationItem.nextInvoice,
														},
													});

													proratedFrequencyOnChange({
														target: {
															value:
																reservationItem.proratedFrequency,
														},
													});

													coTermDateOnChange({
														target: {
															value: reservationItem.coTermDate,
														},
													});

													nextInvoiceDateOnChange({
														target: {
															value: reservationItem.nextInvoiceDate,
														},
													});
												} else {
													firstInvoiceOnChange({
														target: { value: null },
													});

													nextInvoiceOnChange({
														target: {
															value: null,
														},
													});

													proratedFrequencyOnChange({
														target: { value: null },
													});

													coTermDateOnChange({
														target: { value: null },
													});
												}

												// check deferred income frequency against to invoicing frequency.
												if (
													e.target.value &&
													deferredIncomeFrequency &&
													isInvoicingFrequencyBigger(
														deferredIncomeFrequency.value,
														e.target.value.value
													)
												)
													deferredIncomeFrequencyOnChange({
														target: { value: null },
													});
											}}
											displayKey='value'
											onBlur={setInvoicingFrequencyShowVal}
										/>
									</FormField>
									<FormField
										name='paymentTerm'
										label='Payment Term'
										id={data.id}
										colMd={6}>
										<Selects
											options={paymentTerms}
											placeholder='Payment Term'
											value={paymentTerm}
											onChange={paymentTermOnChange}
											disabled={
												invoicingFrequency &&
												invoicingFrequency.value ===
													_invoicingFrequencies.SAME_AS_RESERVATION
											}
										/>
									</FormField>
									<FormField
										name='firstInvoice'
										label='First Invoice'
										id={data.id}
										colMd={6}
										showValidation={firstInvoiceShowVal}
										valRes={firstInvoiceValRes}>
										<Selects
											options={invoiceFirstOptions}
											placeholder={`First Invoice${
												firstInvoiceValRes.isValid ? '' : ' (Required)'
											}`}
											value={firstInvoice}
											onChange={e => {
												firstInvoiceOnChange(e);

												nextInvoiceOnChange({
													target: {
														value: getNextInvoice(
															invoicingFrequency,
															e.target.value,
															invoiceNextOptions
														),
													},
												});

												proratedFrequencyOnChange({
													target: { value: null },
												});

												coTermDateOnChange({
													target: { value: null },
												});
											}}
											onBlur={setFirstInvoiceShowVal}
											displayKey='value'
											disabled={areInvoicingFieldsDisabled()}
										/>
									</FormField>
									<FormField
										name='nextInvoice'
										label='Next Invoice'
										id={data.id}
										colMd={6}
										showValidation={nextInvoiceShowVal}
										valRes={nextInvoiceValRes}>
										<Selects
											placeholder={`Next Invoice${
												nextInvoiceValRes.isValid ? '' : ' (Required)'
											}`}
											value={nextInvoice}
											options={getNextInvoiceOptions(
												data.extraCharge.bookingPeriod.value,
												invoicingFrequency,
												firstInvoice,
												invoiceNextOptions
											)}
											onChange={e => {
												nextInvoiceOnChange(e);

												if (
													!e.target.value ||
													e.target.value.value !==
														nextInvoiceOptions.CO_TERM
												) {
													coTermDateOnChange({
														target: { value: null },
													});
												}
											}}
											displayKey='value'
											disabled={areInvoicingFieldsDisabled() || !firstInvoice}
											onBlur={setNextInvoiceShowVal}
										/>
									</FormField>
									<FormField
										name='proratedFrequency'
										label='Prorated Frequency'
										id={data.id}
										colMd={6}
										showValidation={proratedFrequencyShowVal}
										valRes={proratedFrequencyValRes}>
										<Selects
											placeholder={`Prorated Frequency${
												proratedFrequencyValRes.isValid ? '' : ' (Required)'
											}`}
											value={proratedFrequency}
											options={filterInvoicingFrequency(
												invoicingFrequencies,
												data.extraCharge.bookingPeriod.value,
												false,
												invoicingFrequency ? invoicingFrequency.value : null
											)}
											onChange={proratedFrequencyOnChange}
											displayKey='value'
											disabled={
												areInvoicingFieldsDisabled() ||
												firstInvoice?.value !== firstInvoiceOptions.PRO_RATE
											}
											onBlur={setProratedFrequencyShowVal}
										/>
									</FormField>
									<FormField
										name='coTermDate'
										label='Co-Term Date'
										id={data.id}
										colMd={6}
										showValidation={coTermDateShowVal}
										valRes={coTermDateValRes}>
										<DatePicker
											id='coTermDate'
											type='calendar'
											placeholder={`Co-Term Date${
												coTermDateValRes.isValid ? '' : ' (Required)'
											}`}
											place='top'
											value={parseDatePickerValue(coTermDate)}
											onChange={e =>
												coTermDateOnChange({
													target: {
														value: parseDatePickerChange(
															e.target.value,
															coTermDate
														),
													},
												})
											}
											disabled={
												areInvoicingFieldsDisabled() ||
												nextInvoice?.value !== nextInvoiceOptions.CO_TERM
											}
											formatter={dateFormatterWithoutYear}
											onBlur={setCoTermDateShowVal}
										/>
									</FormField>
									<FormField
										name='prevInvoiceDate'
										label='Prev Invoice Date'
										id={data.id}
										colMd={6}>
										<DatePicker
											id='prevInvoiceDate'
											type='calendar'
											place='top'
											placeholder='Prev Invoice Date'
											value={parseDatePickerValue(prevInvoiceDate)}
											onChange={e =>
												prevInvoiceDateOnChange({
													target: {
														value: parseDatePickerChange(
															e.target.value,
															prevInvoiceDate
														),
													},
												})
											}
											disabled={
												userContext.data.selectedOutlet.settings
													.preventEditPrevInvoiceDate !== false ||
												(invoicingFrequency &&
													invoicingFrequency.value ===
														_invoicingFrequencies.SAME_AS_RESERVATION)
											}
										/>
									</FormField>
									<FormField
										name='nextInvoiceDate'
										label='Next Invoice Date'
										id={data.id}
										colMd={6}>
										<DatePicker
											disabled={
												invoicingFrequency &&
												(invoicingFrequency.value ===
													_invoicingFrequencies.UPFRONT ||
													invoicingFrequency.value ===
														_invoicingFrequencies.SAME_AS_RESERVATION)
											}
											id='nextInvoiceDate'
											type='calendar'
											placeholder='Next Invoice Date'
											minDate={getNextInvoiceDateMin(data)}
											place='top'
											value={parseDatePickerValue(nextInvoiceDate)}
											onChange={e =>
												nextInvoiceDateOnChange({
													target: {
														value: parseDatePickerChange(
															e.target.value,
															nextInvoiceDate
														),
													},
												})
											}
										/>
									</FormField>
								</FormGroup>
							</Section.Body>
						</Section>
					)}
					<Section title='Deferred Income'>
						<Section.Body>
							<FormGroup row>
								<FormField
									name='enableDeferredIncome'
									label='Enable Deferred Income'
									id={data.id}
									colMd={6}>
									<Toggle
										spaceLess
										value={enableDeferredIncome}
										onChange={enableDeferredIncomeOnChange}
										disabled={
											data.extraCharge &&
											!data.extraCharge.enableDeferredIncome
										}
									/>
								</FormField>
								<FormField
									name='deferredIncomeFrequency'
									label='Frequency'
									id={data.id}
									valRes={deferredIncomeFrequencyValRes}
									showValidation={deferredIncomeFrequencyShowVal}
									colMd={6}>
									<Selects
										options={filterInvoicingFrequency(
											invoicingFrequencies,
											data.extraCharge.bookingPeriod
												? data.extraCharge.bookingPeriod.value
												: null,
											false,
											invoicingFrequency ? invoicingFrequency.value : null
										)}
										placeholder={`Frequency${
											enableDeferredIncome ? ' (Required)' : ''
										}`}
										value={deferredIncomeFrequency}
										onChange={deferredIncomeFrequencyOnChange}
										onBlur={setDeferredIncomeFrequencyShowVal}
										displayKey='value'
										disabled={!enableDeferredIncome}
										disableClearable
									/>
								</FormField>
								<FormField
									name='deferredIncomeSalesAccount'
									label='Sales Income Account'
									id={data.id}
									valRes={deferredIncomeSalesAccountValRes}
									showValidation={deferredIncomeSalesAccountShowVal}
									colMd={6}>
									<Selects
										options={accounts}
										placeholder={`Sales Income Account${
											enableDeferredIncome ? ' (Required)' : ''
										}`}
										value={deferredIncomeSalesAccount}
										onChange={deferredIncomeSalesAccountOnChange}
										onBlur={setDeferredIncomeSalesAccountShowVal}
										disabled={!enableDeferredIncome}
										disableClearable
									/>
								</FormField>
							</FormGroup>
						</Section.Body>
					</Section>
				</Portlet.Body>
				<Portlet.Foot tall='sm'>
					<div className='col'>
						<Button
							design='clean'
							text='Close'
							icon='Error-circle'
							size='sm'
							elevate
							onClick={onClose}
						/>
					</div>
				</Portlet.Foot>
			</Portlet>
		</div>
	);
};

ExtraFeeSettings.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	onClose: PropTypes.func,
	onFormChange: PropTypes.func,
	invoicingFrequencies: PropTypes.arrayOf(PropTypes.object),
	invoiceNextOptions: PropTypes.arrayOf(PropTypes.object),
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	invoiceFirstOptions: PropTypes.arrayOf(PropTypes.object),
	accounts: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	updateNextInvoiceDate: PropTypes.bool,
	isSubmitted: PropTypes.bool,
	setIsValid: PropTypes.func,
	disableInvoicing: PropTypes.bool,
};

ExtraFeeSettings.defaultProps = {
	data: null,
	onClose: () => {},
	onFormChange: () => {},
	invoicingFrequencies: [],
	invoiceNextOptions: [],
	paymentTerms: [],
	invoiceFirstOptions: [],
	accounts: [],
	reservationItem: null,
	updateNextInvoiceDate: false,
	isSubmitted: false,
	setIsValid: () => {},
	disableInvoicing: false,
};

export default ExtraFeeSettings;
