export default () => {
	// eslint-disable-next-line no-restricted-globals
	self.addEventListener('message', () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/anonymous/message/get-unread-count`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/ld+json;charset=UTF-8',
				Accept: 'application/ld+json',
			},
		})
			.then(response => response.json())
			.then(res => postMessage(res))
			.catch(err => console.error(err));
	});
};
