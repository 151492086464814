import React, { createRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../../reusables/element/Button';
import Portlet from '../../reusables/layout/Portlet';
import { priceFormatter } from '../../../utils/helpers/helper';
import FlagStatus from '../../reusables/element/FlagStatus';

const ModifierPrefixButton = ({ data, selectedPrefix, setSelectedPrefix }) => {
	const selected = selectedPrefix ? selectedPrefix.id === data.id : false;

	return (
		<Button
			className='sdms-fitText'
			text={data.name}
			design='info'
			bold='bold'
			outline={!selected}
			wide='wide'
			pill
			onClick={() => {
				if (selected) {
					setSelectedPrefix(null);
				} else {
					setSelectedPrefix(data);
				}
			}}
		/>
	);
};
ModifierPrefixButton.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
	}).isRequired,
	selectedPrefix: PropTypes.shape({
		id: PropTypes.number,
	}),
	setSelectedPrefix: PropTypes.func.isRequired,
};
ModifierPrefixButton.defaultProps = {
	selectedPrefix: null,
};

const ModifierButton = ({ data, addModifier, discardModifier }) => {
	const modifierBtnRef = createRef();
	useEffect(() => {
		modifierBtnRef.current.blur();
	}, [modifierBtnRef]);
	return (
		<Button
			ref={modifierBtnRef}
			design={data.isSelected ? 'info' : ''}
			label={data.isSelected ? '' : 'info'}
			bold='bold'
			block
			disabled={data.isDisabled}
			className='sdms-pr5 sdms-pl5 sdms-fitText'
			onClick={() => {
				if (data.isSelected) {
					discardModifier(data.id);
				} else {
					addModifier({
						id: data.id,
						name: data.name,
						price: data.price,
						override: data.override,
					});
				}
			}}>
			{data.name}
			{data.price !== 0 && <>: {priceFormatter(data.price)}</>}
		</Button>
	);
};
ModifierButton.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		price: PropTypes.number,
		override: PropTypes.object,
		isDisabled: PropTypes.bool,
		isSelected: PropTypes.bool,
	}).isRequired,
	addModifier: PropTypes.func.isRequired,
	discardModifier: PropTypes.func.isRequired,
};

const ModifierSection = ({ data, selectedModifiers, onAdd, onRemove, onCustomModifierClick }) => {
	const [selectedPrefix, setSelectedPrefix] = useState(null);
	const modifiers = useRef(
		data.modifierGroup.modifiers.sort((a, b) => {
			return a.sortOrder - b.sortOrder;
		})
	);

	const prefixes = useRef(
		data.modifierGroup.modifierPrefixes.sort((a, b) => {
			return a.sortOrder - b.sortOrder;
		})
	);

	const addModifier = modifierData => {
		if (data.maxQuantity && data.maxQuantity === selectedModifiers.length) return;

		// add id and section object to input
		onAdd({
			name: modifierData.name,
			price: modifierData.price,
			modifier: data.modifierGroup.modifiers.find(m => m.id === modifierData.id),
			modifierOverride: modifierData.override,
			modifierSection: data,
			modifierPrefix: selectedPrefix,
		});

		setSelectedPrefix(null);
	};

	const discardModifier = modifierId => {
		onRemove({
			modifier: data.modifierGroup.modifiers.find(m => m.id === modifierId),
			modifierSection: data,
		});
	};

	const isSelected = modifier => {
		if (data.canReuse) return false;

		if (selectedModifiers.length > 0) {
			return (
				selectedModifiers.findIndex(
					sm =>
						(sm.modifier && sm.modifier.id === modifier.id) ||
						(sm.customModifier && sm.customModifier.id === modifier.id)
				) > -1
			);
		}
		return false;
	};

	const isDisabled = modifier => {
		if (selectedModifiers.length === data.maxQuantity) return true;

		if (selectedPrefix && modifier.overrides) {
			return (
				modifier.overrides.findIndex(
					o => o.modifierPrefix.id === selectedPrefix.id && o.disabled
				) !== -1
			);
		}
		return modifier.prefixRequired;
	};

	const getModifierData = modifier => {
		let _modifier = {
			price: modifier.price,
			name: modifier.name,
			override: null,
		};
		// finding an  existing override for selectedPrefix
		if (selectedPrefix && modifier.overrides) {
			modifier.overrides.every(o => {
				if (o.modifierPrefix.id === selectedPrefix.id) {
					_modifier = {
						price: o.price,
						override: o,
						name: modifier.name,
					};
					return false;
				}
				return true;
			});
		}

		const _isSelected = isSelected(modifier);

		return {
			..._modifier,
			id: modifier.id,
			isSelected: _isSelected,
			isDisabled: isDisabled(modifier) && !_isSelected,
		};
	};

	useEffect(() => {
		modifiers.current = data.modifierGroup.modifiers.sort((a, b) => {
			return a.sortOrder - b.sortOrder;
		});
	}, [data]);

	return (
		<Portlet
			className={classNames(
				'flex-grow-0',
				'sdms-portlet__modifiers',
				'sdms-min-h-fit-content',
				{
					'sdms-portlet__modifiers-with-prefix': Object.keys(prefixes.current).length > 0,
				}
			)}>
			<Portlet.Head className='sdms-portlet__head--sticky flex-column justify-content-center'>
				<div className='row align-items-center'>
					<div className='col-xl order-xl-1 col-lg-6 order-lg-1 col-md-12 sdms-pt10-mobile sdms-pb-10-mobile'>
						<Portlet.HeadLabel>
							<h3 className='sdms-portlet__head-title'>{data.title}</h3>
						</Portlet.HeadLabel>
					</div>
					{Object.keys(prefixes.current).length > 0 && (
						<div className='col-xl-auto order-xl-2 col-lg-12 order-lg-3 col-md-12 d-flex justify-content-center align-content-lg-center sdms-pb-10-mobile'>
							<Portlet.HeadToolbarActions>
								<Button.Group>
									{prefixes.current.map(p => {
										return (
											<ModifierPrefixButton
												data={p}
												key={p.id}
												selectedPrefix={selectedPrefix}
												setSelectedPrefix={setSelectedPrefix}
											/>
										);
									})}
								</Button.Group>
							</Portlet.HeadToolbarActions>
						</div>
					)}
					<div className='col-xl order-xl-3 col-lg-6 order-lg-2 col-md-12 d-flex justify-content-end sdms-pt10-mobile sdms-pb-10-mobile'>
						<Portlet.HeadLabel>
							<div className='sdms-portlet__head-desc sdms-mr-0'>
								<FlagStatus
									min={data.minQuantity}
									max={data.maxQuantity}
									selected={selectedModifiers.length}
								/>
							</div>
						</Portlet.HeadLabel>
					</div>
				</div>
			</Portlet.Head>
			<Portlet.Body>
				<div className='row'>
					{modifiers.current.map(m => (
						<div
							className='col-xl-2 col-lg-3 col-md-6 col-6 sdms-mb-10 d-flex align-items-stretch'
							key={m.id}>
							<ModifierButton
								data={getModifierData(m)}
								addModifier={addModifier}
								discardModifier={discardModifier}
							/>
						</div>
					))}
					<div className='col-xl-2 col-lg-3 col-md-6 col-6 sdms-mb-10'>
						{data.allowCustom && (
							<Button
								className='sdms-fitText'
								label='info'
								text='Custom'
								icon='Plus'
								outline
								block
								disabled={
									data.maxQuantity !== null
										? selectedModifiers.length === data.maxQuantity
										: false
								}
								onClick={onCustomModifierClick}
							/>
						)}
					</div>
				</div>
			</Portlet.Body>
		</Portlet>
	);
};
ModifierSection.propTypes = {
	data: PropTypes.shape({
		title: PropTypes.string,
		modifierGroup: PropTypes.shape({
			modifiers: PropTypes.arrayOf(PropTypes.object),
			modifierPrefixes: PropTypes.arrayOf(PropTypes.object),
		}),
		maxQuantity: PropTypes.number,
		minQuantity: PropTypes.number,
		allowCustom: PropTypes.bool,
	}).isRequired,
	selectedModifiers: PropTypes.arrayOf(PropTypes.object),
	onAdd: PropTypes.func,
	onRemove: PropTypes.func,
	onCustomModifierClick: PropTypes.func,
};
ModifierSection.defaultProps = {
	selectedModifiers: [],
	onAdd: () => {},
	onRemove: () => {},
	onCustomModifierClick: () => {},
};

export default ModifierSection;
