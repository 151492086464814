import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import UserContext from '../../../app/contexts/UserContext';

import { mediaBreakpoint, reportTypesByModule } from '../../../utils/constants/constants';
import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';
import { getModuleKeyByPath } from '../../../utils/helpers/reusable';
import apiCall, {
	filters,
	outletRelatedEndpoints,
	parseData,
} from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	addSuccessNotification,
	useWindowSize,
} from '../../../utils/helpers/helper';

import QuickPanel from '../../QuickPanel';
import Button from '../element/Button';
import Portlet from '../layout/Portlet';
import Portal from '../layout/Portal';
import FormGroup from '../layout/FormGroup';
import Loading from '../template/Loading';
import FormField from '../template/FormField';
import Selects from '../field/Selects';
import Input from '../field/Input';

const icon = 'Chart-line#1';

const AnalyticsWidgetModal = ({ isOpen, onClose, data, dashboardId, nextRow }) => {
	const windowSize = useWindowSize();

	const title = data?.id ? `Edit ${data?.title}` : 'New Widget';

	if (windowSize.width < mediaBreakpoint.MD) {
		return (
			<QuickPanel
				status={isOpen}
				setStatus={onClose}
				title={title}
				icon={icon}
				portletClass='sdms-list-layout'>
				<AnalyticsWidgetModalContent
					onClose={onClose}
					dashboardId={dashboardId}
					nextRow={nextRow}
					data={data}
					modalTitle={title}
				/>
			</QuickPanel>
		);
	}
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				closeOnEscape={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
					minHeight: '480px',
					height: '50%',
				}}>
				<AnalyticsWidgetModalContent
					onClose={onClose}
					dashboardId={dashboardId}
					nextRow={nextRow}
					data={data}
					modalTitle={title}
				/>
			</Popup>
		</Portal>
	);
};

AnalyticsWidgetModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	data: PropTypes.shape({
		id: PropTypes.number,

		title: PropTypes.string,
	}),
	dashboardId: PropTypes.number.isRequired,
	nextRow: PropTypes.number,
};

AnalyticsWidgetModal.defaultProps = {
	data: {
		id: 0,
		data: {
			id: 0,
			title: '',
		},
		sortOrder: 0,
		width: 100,
		title: '',
		config: null,
		type: {
			value: null,
		},
	},
	nextRow: 0,
};

const AnalyticsWidgetModalContent = ({ onClose, dashboardId, nextRow, data, modalTitle }) => {
	const singleValidation = (value, setValRes) => {
		if (type?.label !== 'Single') return true;

		return required(value, setValRes);
	};

	const chartValidation = (value, setValRes) => {
		if (type?.label !== 'Chart') return true;

		return required(value, setValRes);
	};

	const chartYValidation = (value, setValRes) => {
		if (
			type?.label !== 'Chart' ||
			chartType?.label === 'Pie' ||
			chartType?.label === 'Doughnut' ||
			chartYCalculation?.label === 'Distinct count'
		)
			return true;

		return required(value, setValRes);
	};

	const userContext = useContext(UserContext);

	const lists = useRef([
		'reports',
		'enumAnalyticsWidgetTypes',
		'enumAnalyticsWidgetCalculations',
		'enumChartTypes',
	]);

	const [listState, setListState] = useState({});

	const [isLoading, setIsLoading] = useState(true);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [isValid, setIsValid] = useState(false);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [title, titleOnChange, titleValRes, titleShowVal, setTitleShowVal] = useField(
		{},
		'title',
		() => {},
		[required],
		''
	);

	const [report, reportOnChange, reportValRes, reportShowVal, setReportShowVal] = useField(
		{},
		'report',
		() => {},
		[required],
		null
	);

	const [type, typeOnChange, typeValRes, typeShowVal, setTypeShowVal] = useField(
		{},
		'type',
		() => {},
		[required],
		null
	);

	const [
		singleCalculation,
		singleCalculationOnChange,
		singleCalculationValRes,
		singleCalculationShowVal,
		setSingleCalculationShowVal,
		validateSingleCalculation,
	] = useField({}, 'singleCalculation', () => {}, [singleValidation], null);

	const [
		singleField,
		singleFieldOnChange,
		singleFieldValRes,
		singleFieldShowVal,
		setSingleFieldShowVal,
		validateSingleField,
	] = useField({}, 'singleField', () => {}, [singleValidation], null);

	const [
		chartType,
		chartTypeOnChange,
		chartTypeValRes,
		chartTypeShowVal,
		setChartTypeShowVal,
		validateChartType,
	] = useField({}, 'chartType', () => {}, [chartValidation], null);

	const [
		chartX,
		chartXOnChange,
		chartXValRes,
		chartXShowVal,
		setChartXShowVal,
		validateChartX,
	] = useField({}, 'chartX', () => {}, [chartValidation], null);

	const [
		chartY,
		chartYOnChange,
		chartYValRes,
		chartYShowVal,
		setChartYShowVal,
		validateChartY,
	] = useField({}, 'chartY', () => {}, [chartYValidation], null);

	const [
		chartYCalculation,
		chartYCalculationOnChange,
		chartYCalculationValRes,
		chartYCalculationShowVal,
		setChartYCalculationShowVal,
		validateChartYCalculation,
	] = useField({}, 'chartYCalculation', () => {}, [chartYValidation], null);

	const fields = useMemo(() => {
		if (!report?.value) return [];

		const _report = listState.reports.find(item => item.id === report.value);

		return _report.reportFields.map(item => item.dataSetField);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [report]);

	const fieldOptions = useMemo(() => {
		const options = fields.map(field => ({
			label: field.label,
			value: field.id,
			type: field.dataType.value,
		}));

		singleFieldOnChange({
			target: {
				value: options.find(item => item.value === data?.singleField?.id),
			},
		});

		chartXOnChange({
			target: {
				value: options.find(item => item.value === data?.chartX?.id),
			},
		});

		chartYOnChange({
			target: {
				value: options.find(item => item.value === data?.chartY?.id),
			},
		});

		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fields]);

	const numericFieldOptions = useMemo(() => fieldOptions.filter(item => item.type === 'number'), [
		fieldOptions,
	]);

	const calculationOptions = useMemo(() => {
		if (Object.keys(listState).indexOf('enumAnalyticsWidgetCalculations') === -1) return [];

		const options = listState.enumAnalyticsWidgetCalculations.map(item => ({
			label: item.value,
			value: item.id,
		}));

		singleCalculationOnChange({
			target: {
				value: options.find(item => item.value === data?.singleCalculation?.id),
			},
		});

		chartYCalculationOnChange({
			target: {
				value: options.find(item => item.value === data?.chartYCalculation?.id),
			},
		});

		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listState]);

	const singleCalculationOptions = useMemo(() => {
		const localFilter = item => {
			if (!singleField) return false;

			if (singleField.type === 'number')
				return ['Total', 'Average', 'Min', 'Max'].indexOf(item.label) > -1;

			return item.label === 'Distinct count';
		};

		const options = (calculationOptions || []).filter(localFilter);

		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [calculationOptions, singleField]);

	const reportOptions = useMemo(() => {
		if (Object.keys(listState).indexOf('reports') === -1) return [];

		const moduleKey = getModuleKeyByPath();

		const allowedReportTypes = reportTypesByModule[moduleKey];

		const options = listState.reports
			.filter(item => allowedReportTypes.indexOf(item.type.value) > -1)
			.map(item => ({
				label: item.name,
				value: item.id,
			}));

		reportOnChange({
			target: {
				value: options.find(item => item.value === data?.report?.id),
			},
		});

		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listState]);

	const typeOptions = useMemo(() => {
		if (Object.keys(listState).indexOf('enumAnalyticsWidgetTypes') === -1) return [];

		const options = listState.enumAnalyticsWidgetTypes.map(item => ({
			label: item.value,
			value: item.id,
		}));

		typeOnChange({
			target: {
				value: options.find(item => item.value === data?.type?.id),
			},
		});

		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listState]);

	const chartTypeOptions = useMemo(() => {
		if (Object.keys(listState).indexOf('enumChartTypes') === -1) return [];

		const options = listState.enumChartTypes.map(item => ({
			label: item.value,
			value: item.id,
		}));

		chartTypeOnChange({
			target: {
				value: options.find(item => item.value === data?.chartType?.id),
			},
		});

		return options;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listState, data]);

	const submit = () => {
		setIsSubmitted(true);

		if (!isValid) return;

		setIsSubmitting(true);

		let method = 'POST';

		const payload = {
			title,
			dashboard: parseData({ id: dashboardId }, 'analyticsDashboards'),
			report: parseData({ id: report?.value }, 'reports'),
			type: parseData({ id: type?.value }, 'enumAnalyticsWidgetTypes'),
			singleField: parseData({ id: singleField?.value }, 'enumReportDataSets'),
			singleCalculation: parseData(
				{ id: singleCalculation?.value },
				'enumAnalyticsWidgetCalculations'
			),
			chartType: parseData({ id: chartType?.value }, 'enumChartTypes'),
			chartX: parseData({ id: chartX?.value }, 'enumReportDataSets'),
			chartY: parseData({ id: chartY?.value }, 'enumReportDataSets'),
			chartYCalculation: parseData(
				{ id: chartYCalculation?.value },
				'enumAnalyticsWidgetCalculations'
			),
		};

		if (data?.id) {
			method = 'PUT';
		} else {
			payload.col = 0;
			payload.row = nextRow;
			payload.sizeX = 1;
			payload.sizeY = 1;
		}

		apiCall(
			method,
			'analyticsWidgets',
			() => {
				onClose(!data?.id);

				addSuccessNotification('Widget has been added successfuly.');

				setIsSubmitting(false);
			},
			err => {
				addErrorNotification(err.toString());

				setIsSubmitting(false);
			},
			data?.id,
			payload
		);
	};

	const load = () => {
		setIsLoading(true);

		lists.current.forEach(name => {
			const _filters = {
				pagination: false,
			};

			if (outletRelatedEndpoints.includes(name))
				_filters['outlet.id'] = userContext.data.selectedOutlet.id;

			if ((((filters[name] || {}).groups || {}).list || {}).read)
				_filters['groups[]'] = filters[name].groups.list.read;

			apiCall(
				'GET',
				name,
				fetchedData => {
					setListState(oldListState => ({
						...oldListState,
						[name]: fetchedData,
					}));

					titleOnChange({
						target: {
							value: data?.title,
						},
					});

					setIsLoading(false);
				},
				err => {
					addErrorNotification(err.toString());

					setIsLoading(false);
				},
				'',
				null,
				_filters
			);
		});
	};

	useEffect(load, []);

	const showVal = () => {
		if (!isSubmitted) return;

		setTitleShowVal();
		setReportShowVal();
		setTypeShowVal();
		setSingleFieldShowVal();
		setSingleCalculationShowVal();
		setChartTypeShowVal();
		setChartXShowVal();
		setChartYShowVal();
		setChartYCalculationShowVal();
	};

	useEffect(showVal, [
		isSubmitted,
		setTitleShowVal,
		setReportShowVal,
		setTypeShowVal,
		setSingleFieldShowVal,
		setSingleCalculationShowVal,
		setChartTypeShowVal,
		setChartXShowVal,
		setChartYShowVal,
		setChartYCalculationShowVal,
	]);

	const setValid = () => {
		setIsValid(
			titleValRes.isValid &&
				reportValRes.isValid &&
				typeValRes.isValid &&
				singleFieldValRes.isValid &&
				singleCalculationValRes.isValid &&
				chartTypeValRes.isValid &&
				chartXValRes.isValid &&
				chartYValRes.isValid &&
				chartYCalculationValRes.isValid
		);
	};

	useEffect(setValid, [
		setIsValid,
		titleValRes.isValid,
		reportValRes.isValid,
		typeValRes.isValid,
		singleFieldValRes.isValid,
		singleCalculationValRes.isValid,
		chartTypeValRes.isValid,
		chartXValRes.isValid,
		chartYValRes.isValid,
		chartYCalculationValRes.isValid,
	]);

	const handleResets = () => {
		if (!report) {
			singleFieldOnChange({
				target: {
					value: null,
				},
			});

			chartXOnChange({
				target: {
					value: null,
				},
			});

			chartYOnChange({
				target: {
					value: null,
				},
			});
		}

		if (!singleField)
			singleCalculationOnChange({
				target: {
					value: null,
				},
			});

		if (!chartYCalculation || chartYCalculation.label === 'Distinct count') {
			chartYOnChange({
				target: {
					value: null,
				},
			});
		}
	};

	useEffect(handleResets, [report, singleField, chartYCalculation]);

	const handleTypeResets = () => {
		if (isLoading) return;

		if (type?.label !== 'Single') {
			singleFieldOnChange({
				target: {
					value: null,
				},
			});
			singleCalculationOnChange({
				target: {
					value: null,
				},
			});
		}

		if (type?.label !== 'Chart') {
			chartTypeOnChange({
				target: {
					value: null,
				},
			});
			chartXOnChange({
				target: {
					value: null,
				},
			});
			chartYOnChange({
				target: {
					value: null,
				},
			});
			chartYCalculationOnChange({
				target: {
					value: null,
				},
			});
		}
	};

	useEffect(handleTypeResets, [type]);

	const validate = () => {
		validateSingleField(singleField);
		validateSingleCalculation(singleCalculation);
		validateChartType(chartType);
		validateChartX(chartX);
		validateChartY(chartY);
		validateChartYCalculation(chartYCalculation);
	};

	useEffect(validate, [type, chartType, chartYCalculation]);

	const ChartWidgetFields = () => (
		<>
			<Loading isLoading={isLoading}>
				<FormField
					name='chartType'
					label='Chart Type'
					id={0}
					valRes={chartTypeValRes}
					showValidation={chartTypeShowVal}
					colMd={6}>
					<Selects
						options={chartTypeOptions}
						placeholder='Field'
						value={chartType}
						displayKey='label'
						valueKey='value'
						onChange={e => {
							chartTypeOnChange(e);
						}}
						onBlur={setChartTypeShowVal}
					/>
				</FormField>
			</Loading>
			<Loading isLoading={isLoading}>
				<FormField
					name='chartX'
					label='Chart X'
					id={0}
					valRes={chartXValRes}
					showValidation={chartXShowVal}
					colMd={6}>
					<Selects
						options={fieldOptions}
						placeholder='Chart X'
						value={chartX}
						displayKey='label'
						valueKey='value'
						onChange={chartXOnChange}
						onBlur={setChartXShowVal}
						disabled={!report}
					/>
				</FormField>
			</Loading>
			{chartType && chartType?.label !== 'Pie' && chartType?.label !== 'Doughnut' && (
				<>
					<Loading isLoading={isLoading}>
						<FormField
							name='chartYCalculation'
							label='Chart Y Calculation'
							id={0}
							valRes={chartYCalculationValRes}
							showValidation={chartYCalculationShowVal}
							colMd={6}>
							<Selects
								options={calculationOptions}
								placeholder='Chart Y Calculation'
								value={chartYCalculation}
								displayKey='label'
								valueKey='value'
								onChange={chartYCalculationOnChange}
								onBlur={setChartYCalculationShowVal}
							/>
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField
							name='chartY'
							label='Chart Y'
							id={0}
							valRes={chartYValRes}
							showValidation={chartYShowVal}
							colMd={6}>
							<Selects
								options={numericFieldOptions}
								placeholder='Chart Y'
								value={chartY}
								displayKey='label'
								valueKey='value'
								onChange={chartYOnChange}
								onBlur={setChartYShowVal}
								disabled={
									!report ||
									!chartYCalculation ||
									chartYCalculation?.label === 'Distinct count'
								}
							/>
						</FormField>
					</Loading>
				</>
			)}
		</>
	);

	const SingleWidgetFields = () => (
		<>
			<Loading isLoading={isLoading}>
				<FormField
					name='singleField'
					label='Field'
					id={0}
					valRes={singleFieldValRes}
					showValidation={singleFieldShowVal}
					colMd={6}>
					<Selects
						options={fieldOptions}
						placeholder='Field'
						value={singleField}
						displayKey='label'
						valueKey='value'
						onChange={singleFieldOnChange}
						onBlur={setSingleFieldShowVal}
						disabled={!report}
					/>
				</FormField>
			</Loading>
			<Loading isLoading={isLoading}>
				<FormField
					name='singleCalculation'
					label='Calculation'
					id={0}
					valRes={singleCalculationValRes}
					showValidation={singleCalculationShowVal}
					colMd={6}>
					<Selects
						options={singleCalculationOptions}
						placeholder='Calculation'
						value={singleCalculation}
						displayKey='label'
						valueKey='value'
						onChange={singleCalculationOnChange}
						onBlur={setSingleCalculationShowVal}
						disabled={!singleField}
					/>
				</FormField>
			</Loading>
		</>
	);

	const _Inner = (
		<>
			<Portlet.Head wrapMaxSize='md'>
				<Portlet.HeadLabel portletIcon={icon}>
					<h3 className='sdms-portlet__head-title'>{modalTitle}</h3>
				</Portlet.HeadLabel>
			</Portlet.Head>
			<Portlet.Body>
				<FormGroup className='sdms-mt-20'>
					<Loading isLoading={isLoading}>
						<FormField
							name='title'
							label='Title'
							id={0}
							valRes={titleValRes}
							showValidation={titleShowVal}
							col={12}>
							<Input
								type='text'
								placeholder='Title'
								value={title}
								onChange={titleOnChange}
								onBlur={setTitleShowVal}
							/>
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField
							name='report'
							label='Report'
							id={0}
							valRes={reportValRes}
							showValidation={reportShowVal}
							colMd={6}>
							<Selects
								options={reportOptions}
								placeholder='Report'
								value={report}
								displayKey='label'
								valueKey='value'
								onChange={reportOnChange}
								onBlur={setReportShowVal}
							/>
						</FormField>
					</Loading>
					<Loading isLoading={isLoading}>
						<FormField
							name='type'
							label='Widget Type'
							id={0}
							valRes={typeValRes}
							showValidation={typeShowVal}
							colMd={6}>
							<Selects
								options={typeOptions}
								placeholder='Widget Type'
								value={type}
								displayKey='label'
								valueKey='value'
								onChange={typeOnChange}
								onBlur={setTypeShowVal}
							/>
						</FormField>
					</Loading>
					{type?.label === 'Single' && <SingleWidgetFields />}
					{type?.label === 'Chart' && <ChartWidgetFields />}
				</FormGroup>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={() => onClose(false)}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='brand'
						icon='Save'
						text='Save'
						size='sm'
						onClick={submit}
						isSubmitting={isSubmitting}
					/>
				</div>
			</Portlet.Foot>
		</>
	);

	return (
		<Portlet className='sdms-list-layout' fluid='fluid' isLast everyTimeFluid>
			{_Inner}
		</Portlet>
	);
};

AnalyticsWidgetModalContent.propTypes = {
	onClose: PropTypes.func.isRequired,
	dashboardId: PropTypes.number.isRequired,
	data: PropTypes.shape({
		id: PropTypes.number,
		report: PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
			queryJSON: PropTypes.string,
			reportFields: PropTypes.arrayOf(PropTypes.shape({})),
		}),
		sortOrder: PropTypes.number,
		width: PropTypes.number,
		title: PropTypes.string,
		config: PropTypes.string,
		type: PropTypes.shape({
			id: PropTypes.number,
			value: PropTypes.string,
		}),
		col: PropTypes.number,
		row: PropTypes.number,
		sizeX: PropTypes.number,
		sizeY: PropTypes.number,
		singleCalculation: PropTypes.shape({
			id: PropTypes.number,
			value: PropTypes.string,
		}),
		singleField: PropTypes.shape({
			id: PropTypes.number,
			alias: PropTypes.string,
		}),
		chartType: PropTypes.shape({
			id: PropTypes.number,
			value: PropTypes.string,
		}),
		chartX: PropTypes.shape({
			id: PropTypes.number,
			alias: PropTypes.string,
		}),
		chartY: PropTypes.shape({
			id: PropTypes.number,
			alias: PropTypes.string,
		}),
		chartYCalculation: PropTypes.shape({
			id: PropTypes.number,
			value: PropTypes.string,
		}),
	}),
	nextRow: PropTypes.number,
	modalTitle: PropTypes.string,
};

AnalyticsWidgetModalContent.defaultProps = {
	data: {
		id: 0,
		report: {
			id: 0,
			name: '',
			queryJSON: '',
			reportFields: [],
		},
		sortOrder: 0,
		width: 100,
		title: '',
		config: null,
		type: {
			id: 0,
			value: null,
		},
		col: 0,
		row: 0,
		sizeX: 0,
		sizeY: 0,
		singleCalculation: null,
		singleField: null,
		chartType: null,
		chartX: null,
		chartY: null,
		chartYCalculation: null,
	},
	nextRow: 0,
	modalTitle: '',
};

export default AnalyticsWidgetModal;
