import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import Badge from '../../reusables/element/Badge';
import usePages from '../../../utils/hooks/usePages';
import forms from '../../forms';
import HeaderContext from '../../../app/contexts/HeaderContext';

const DiscountPercentCell = ({ data }) => (
	<Badge design='info' isInline isUnified size='lg' fontWeight='boldest'>
		{data.discountPct}%
	</Badge>
);
DiscountPercentCell.propTypes = {
	data: PropTypes.shape({
		discountPct: PropTypes.number,
	}),
};
DiscountPercentCell.defaultProps = {
	data: {
		discountPct: 0,
	},
};

const PaymentTermList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.accounting.default.text, path: pages.accounting.dashboard.path },
			{ title: pages.accounting.settings.text, path: pages.accounting.settings.path },
			{ title: pages.accounting.settings.paymentTerms.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.accounting.settings.paymentTerms.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='paymentTerms'
			title={pages.accounting.settings.paymentTerms.text}
			history={history}
			icon={pages.accounting.settings.paymentTerms.icon}
			forms={forms.accounting.settings.paymentTerms}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col
					label='Discount Percent'
					cellComponent={<DiscountPercentCell />}
					sortable='discountPct'
				/>
				<List.Col label='Discount Day' cellData='discountDay' sortable='discountDay' />
				<List.Col label='Due' cellData='due' sortable='due' />
				<List.Col
					label='Day of Due Month'
					cellData='dayOfDueMonth'
					sortable='dayOfDueMonth'
				/>
				<List.Col
					label='Due Next Month Days'
					cellData='dueNextMonthDays'
					sortable='dueNextMonthDays'
				/>
				<List.Col
					label='Discount Day of Month'
					cellData='discountDayOfMonth'
					sortable='discountDayOfMonth'
				/>
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
PaymentTermList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default PaymentTermList;
