import React, { useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import useField from '../../../utils/hooks/useField';
import usePages from '../../../utils/hooks/usePages';
import { modules } from '../../../utils/helpers/apiCall';
import { getCustomStatusColorList, numberParser } from '../../../utils/helpers/helper';
import { reservationStatuses, templateTypes } from '../../../utils/constants/constants';
import { required, numeric } from '../../../utils/helpers/validation';

import FormField from '../template/FormField';
import Input from '../field/Input';
import Loading from '../template/Loading';
import Selects from '../field/Selects';
import Section from '../layout/Section';
import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import Toggle from '../field/Toggle';
import Badge from '../element/Badge';

const CustomReservationStatusForm = ({
	data,
	isLoading,
	setTitle,
	onFormChange,
	isSubmitted,
	setIsValid,
	contracts,
	enumReservationStatuses,
	submitButtonAttr,
	submit,
	templates,
}) => {
	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const location = useLocation();

	const pages = usePages();

	const colorList = useRef(getCustomStatusColorList());

	const moduleData = useMemo(() => {
		if (location.pathname.indexOf('marina') > -1)
			return {
				text: pages.marina.settings.customReservationStatus.text,
				breadcrumb: [
					{ title: pages.marina.default.text, path: pages.marina.dashboard.path },
					{ title: pages.marina.settings.text, path: pages.marina.settings.path },
					{
						title: pages.marina.settings.customReservationStatus.text,
						path: pages.marina.settings.customReservationStatus.path,
					},
				],
				title: pages.marina.settings.customReservationStatus.text,
				icon: pages.marina.settings.customReservationStatus.icon,
				module: userContext.data.user.company.modules.find(m => m.value === modules.MARINA),
			};

		if (location.pathname.indexOf('campground') > -1)
			return {
				text: pages.campground.settings.maps.text,
				breadcrumb: [
					{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
					{ title: pages.campground.settings.text, path: pages.campground.settings.path },
					{
						title: pages.campground.settings.customReservationStatus.text,
						path: pages.campground.settings.customReservationStatus.path,
					},
				],
				title: pages.campground.settings.customReservationStatus.text,
				icon: pages.campground.settings.customReservationStatus.icon,
				module: userContext.data.user.company.modules.find(
					m => m.value === modules.CAMPGROUND
				),
			};

		return {
			text: pages.booking.settings.maps.text,
			breadcrumb: [
				{ title: pages.booking.default.text, path: pages.booking.dashboard.path },
				{ title: pages.booking.settings.text, path: pages.booking.settings.path },
				{
					title: pages.booking.settings.customReservationStatus.text,
					path: pages.booking.settings.customReservationStatus.path,
				},
			],
			title: pages.booking.settings.customReservationStatus.text,
			icon: pages.booking.settings.customReservationStatus.icon,
			module: userContext.data.user.company.modules.find(m => m.value === modules.BOOKINGS),
		};
	}, [location.pathname, userContext.data.user.company.modules, pages]);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [status, statusOnChange, statusValRes, statusShowVal, setStatusShowVal] = useField(
		data,
		'status',
		onFormChange,
		[required],
		[],
		null
	);

	const [
		sortOrder,
		sortOrderOnChange,
		sortOrderValRes,
		sortOrderShowVal,
		setSortOrderShowVal,
	] = useField(data, 'sortOrder', onFormChange, [required, numeric], '', numberParser(true));

	const [contract, contractOnChange] = useField(data, 'contract', onFormChange, [], null);

	const [emailTemplate, emailTemplateOnChange] = useField(
		data,
		'emailTemplate',
		onFormChange,
		[],
		null
	);
	const [emailAttachmentTemplate, emailAttachmentTemplateOnChange] = useField(
		data,
		'emailAttachmentTemplate',
		onFormChange,
		[],
		null
	);

	const [mapColor, mapColorOnChange] = useField(data, 'mapColor', onFormChange, [], null);

	const [manualSelection, manualSelectionOnChange] = useField(
		data,
		'manualSelection',
		onFormChange,
		[],
		false
	);

	const [enableBatchJob, enableBatchJobOnChange] = useField(
		data,
		'enableBatchJob',
		onFormChange,
		[],
		false
	);

	const [enableAutoSend, enableAutoSendOnChange] = useField(
		data,
		'enableAutoSend',
		onFormChange,
		[],
		false
	);

	const [batchJobMinBeforeArrival, batchJobMinBeforeArrivalOnChange] = useField(
		data,
		'batchJobMinBeforeArrival',
		onFormChange,
		[],
		null,
		numberParser(false)
	);

	const [batchJobMaxBeforeArrival, batchJobMaxBeforeArrivalOnChange] = useField(
		data,
		'batchJobMaxBeforeArrival',
		onFormChange,
		[],
		null,
		numberParser(false)
	);

	const [batchJobMinBeforeDeparture, batchJobMinBeforeDepartureOnChange] = useField(
		data,
		'batchJobMinBeforeDeparture',
		onFormChange,
		[],
		null,
		numberParser(false)
	);

	const [batchJobMaxBeforeDeparture, batchJobMaxBeforeDepartureOnChange] = useField(
		data,
		'batchJobMaxBeforeDeparture',
		onFormChange,
		[],
		null,
		numberParser(false)
	);

	const [batchJobMinAfterDeparture, batchJobMinAfterDepartureOnChange] = useField(
		data,
		'batchJobMinAfterDeparture',
		onFormChange,
		[],
		null,
		numberParser(false)
	);

	const [batchJobMaxAfterDeparture, batchJobMaxAfterDepartureOnChange] = useField(
		data,
		'batchJobMaxAfterDeparture',
		onFormChange,
		[],
		null,
		numberParser(false)
	);

	const [autoSendMinBeforeArrival, autoSendMinBeforeArrivalOnChange] = useField(
		data,
		'autoSendMinBeforeArrival',
		onFormChange,
		[],
		null,
		numberParser(false)
	);

	const [autoSendMaxBeforeArrival, autoSendMaxBeforeArrivalOnChange] = useField(
		data,
		'autoSendMaxBeforeArrival',
		onFormChange,
		[],
		null,
		numberParser(false)
	);

	const [autoSendMinBeforeDeparture, autoSendMinBeforeDepartureOnChange] = useField(
		data,
		'autoSendMinBeforeDeparture',
		onFormChange,
		[],
		null,
		numberParser(false)
	);

	const [autoSendMaxBeforeDeparture, autoSendMaxBeforeDepartureOnChange] = useField(
		data,
		'autoSendMaxBeforeDeparture',
		onFormChange,
		[],
		null,
		numberParser(false)
	);

	const [autoSendMinAfterDeparture, autoSendMinAfterDepartureOnChange] = useField(
		data,
		'autoSendMinAfterDeparture',
		onFormChange,
		[],
		null,
		numberParser(false)
	);

	const [autoSendMaxAfterDeparture, autoSendMaxAfterDepartureOnChange] = useField(
		data,
		'autoSendMaxAfterDeparture',
		onFormChange,
		[],
		null,
		numberParser(false)
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setStatusShowVal();
			setSortOrderShowVal();
		}
	}, [isSubmitted, setNameShowVal, setStatusShowVal, setSortOrderShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && statusValRes.isValid && sortOrderValRes.isValid);
	}, [nameValRes.isValid, statusValRes.isValid, sortOrderValRes.isValid, setIsValid]);

	useEffect(() => {
		data.module = moduleData.module;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [moduleData]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			...moduleData.breadcrumb,
			{ title: name || `New ${moduleData.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${moduleData.text}`);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<Section title='General'>
						<Section.Body className='row'>
							<Loading isLoading={isLoading}>
								<FormField
									name='name'
									label='Name'
									id={data.id}
									valRes={nameValRes}
									showValidation={nameShowVal}
									colLg={4}>
									<Input
										type='text'
										placeholder='Name (Required)'
										value={name}
										onChange={nameOnChange}
										onBlur={setNameShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='status'
									label='Reservation Status'
									id={data.id}
									valRes={statusValRes}
									showValidation={statusShowVal}
									colLg={4}>
									<Selects
										options={enumReservationStatuses.filter(
											ers => ers.value !== reservationStatuses.CANCELLED
										)}
										placeholder='Reservation Status'
										value={status}
										onChange={statusOnChange}
										onBlur={setStatusShowVal}
										displayKey='value'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='sortOrder'
									label='Sort Order'
									valRes={sortOrderValRes}
									showValidation={sortOrderShowVal}
									id={data.id}
									colLg={4}>
									<Input
										type='text'
										placeholder='Sort Order (Required)'
										value={sortOrder.toString()}
										onChange={sortOrderOnChange}
										onBlur={setSortOrderShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField name='contract' label='Contract' id={data.id} colLg={4}>
									<Selects
										options={contracts}
										placeholder='Contract'
										value={contract}
										onChange={contractOnChange}
										displayKey='name'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='template'
									label='Email Template'
									id={data.id}
									colLg={4}>
									<Selects
										options={templates.filter(
											t => t.type.value === templateTypes.STATUS_UPDATE_EMAIL
										)}
										placeholder='Email Template'
										value={emailTemplate}
										onChange={emailTemplateOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='emailAttachmentTemplate'
									label='Email Attachment Template'
									id={data.id}
									colLg={4}>
									<Selects
										options={templates.filter(
											t =>
												t.type.value ===
												templateTypes.STATUS_UPDATE_EMAIL_ATTACHMENT
										)}
										placeholder='Email Attachment Template'
										value={emailAttachmentTemplate}
										onChange={emailAttachmentTemplateOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField name='mapColor' label='Map Color' id={data.id} colMd={4}>
									<Selects
										className='sdms-font-transform-c'
										placeholder='Select a Color'
										options={colorList.current}
										value={
											mapColor
												? colorList.current.find(c => c.class === mapColor)
												: null
										}
										onChange={c =>
											mapColorOnChange({
												target: {
													value: c.target.value
														? c.target.value.class
														: null,
												},
											})
										}
										displayKey='name'
										valueKey='class'
										renderOption={option => (
											<Badge
												className={`sdms-custom-colors--${option.class} sdms-font-transform-c`}
												isInline>
												<span>{option.name}</span>
											</Badge>
										)}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='manualSelection'
									label='Manual Selection'
									id={data.id}
									colMd={4}>
									<Toggle
										value={manualSelection}
										onChange={manualSelectionOnChange}
									/>
								</FormField>
							</Loading>
						</Section.Body>
					</Section>
					<Section title='Batch Job'>
						<Section.Body className='row'>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableBatchJob'
									label='Enable Batch Job'
									id={data.id}
									colLg={12}>
									<Toggle
										value={enableBatchJob}
										onChange={enableBatchJobOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='batchJobMaxBeforeArrival'
									label='Maximum Before Arrival'
									id={data.id}
									colLg={2}>
									<Input
										type='number'
										value={batchJobMaxBeforeArrival}
										onChange={batchJobMaxBeforeArrivalOnChange}
										append='Days'
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='batchJobMinBeforeArrival'
									label='Minimum Before Arrival'
									id={data.id}
									colLg={2}>
									<Input
										type='number'
										value={batchJobMinBeforeArrival}
										onChange={batchJobMinBeforeArrivalOnChange}
										append='Days'
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='batchJobMinBeforeDeparture'
									label='Minimum Before Departure'
									id={data.id}
									colLg={2}>
									<Input
										type='number'
										value={batchJobMinBeforeDeparture}
										onChange={batchJobMinBeforeDepartureOnChange}
										append='Days'
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='batchJobMaxBeforeDeparture'
									label='Maximum Before Departure'
									id={data.id}
									colLg={2}>
									<Input
										type='number'
										value={batchJobMaxBeforeDeparture}
										onChange={batchJobMaxBeforeDepartureOnChange}
										append='Days'
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='batchJobMinAfterDeparture'
									label='Minimum After Departure'
									id={data.id}
									colLg={2}>
									<Input
										type='number'
										value={batchJobMinAfterDeparture}
										onChange={batchJobMinAfterDepartureOnChange}
										append='Days'
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='batchJobMaxAfterDeparture'
									label='Maximum After Departure'
									id={data.id}
									colLg={2}>
									<Input
										type='number'
										value={batchJobMaxAfterDeparture}
										onChange={batchJobMaxAfterDepartureOnChange}
										append='Days'
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
						</Section.Body>
					</Section>
					<Section title='Auto-Send'>
						<Section.Body className='row'>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableAutoSend'
									label='Enable Auto Send'
									id={data.id}
									colLg={12}>
									<Toggle
										value={enableAutoSend}
										onChange={enableAutoSendOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoSendMinBeforeArrival'
									label='Maximum Before Arrival'
									id={data.id}
									colLg={2}>
									<Input
										type='number'
										value={autoSendMaxBeforeArrival}
										onChange={autoSendMaxBeforeArrivalOnChange}
										append='Days'
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoSendMinBeforeArrival'
									label='Minimum Before Arrival'
									id={data.id}
									colLg={2}>
									<Input
										type='number'
										value={autoSendMinBeforeArrival}
										onChange={autoSendMinBeforeArrivalOnChange}
										append='Days'
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoSendMinBeforeDeparture'
									label='Minimum Before Departure'
									id={data.id}
									colLg={2}>
									<Input
										type='number'
										value={autoSendMinBeforeDeparture}
										onChange={autoSendMinBeforeDepartureOnChange}
										append='Days'
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoSendMaxBeforeDeparture'
									label='Maximum Before Departure'
									id={data.id}
									colLg={2}>
									<Input
										type='number'
										value={autoSendMaxBeforeDeparture}
										onChange={autoSendMaxBeforeDepartureOnChange}
										append='Days'
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoSendMinAfterDeparture'
									label='Minimum After Departure'
									id={data.id}
									colLg={2}>
									<Input
										type='number'
										value={autoSendMinAfterDeparture}
										onChange={autoSendMinAfterDepartureOnChange}
										append='Days'
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoSendMaxAfterDeparture'
									label='Maximum After Departure'
									id={data.id}
									colLg={2}>
									<Input
										type='number'
										value={autoSendMaxAfterDeparture}
										onChange={autoSendMaxAfterDepartureOnChange}
										append='Days'
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
						</Section.Body>
					</Section>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
CustomReservationStatusForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		status: PropTypes.object,
		sortOrder: PropTypes.number,
		contract: PropTypes.object,
		module: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setIsValid: PropTypes.func,
	onFormChange: PropTypes.func,
	contracts: PropTypes.arrayOf(PropTypes.object),
	enumReservationStatuses: PropTypes.arrayOf(PropTypes.object),
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
	templates: PropTypes.arrayOf(PropTypes.object),
};
CustomReservationStatusForm.defaultProps = {
	data: {
		id: 0,
	},
	isLoading: false,
	onFormChange: () => {},
	contracts: [],
	enumReservationStatuses: [],
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
	templates: [],
};

export default CustomReservationStatusForm;
