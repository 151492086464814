import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import update from 'immutability-helper';

import Input from './Input';
import Portal from '../layout/Portal';
import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import FormField from '../template/FormField';
import { ListBody, ListTable } from '../template/List';
import SVGIcon from '../element/SVGIcon';
import { addErrorNotification } from '../../../utils/helpers/helper';

const Field = ({ data, index, onChange, onDelete }) => {
	return (
		<tr>
			<td>
				<FormField name={`key${index}`} inFormDesign={false} isLast>
					<Input
						onChange={e => onChange({ ...data, key: e.target.value })}
						value={data.key}
					/>
				</FormField>
			</td>
			<td>
				<FormField name={`value${index}`} inFormDesign={false} isLast>
					<Input
						onChange={e => onChange({ ...data, value: e.target.value })}
						value={data.value}
					/>
				</FormField>
			</td>
			<td>
				<Button
					btnIcon
					label='danger'
					icon='Trash'
					size='sm'
					elevate
					key='delete'
					onClick={onDelete}
				/>
			</td>
		</tr>
	);
};

Field.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
};

const Json = ({
	value,
	onChange,
	title,
	placeholder,
	disabled,
	presetConfigurations,
	keyTitle,
	valueTitle,
	addFieldButtonText,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [fields, setFields] = useState([]);

	useEffect(() => {
		if (value) {
			try {
				const _parsedValue = JSON.parse(value);
				setFields(
					Object.keys(_parsedValue).map(key => ({ key, value: _parsedValue[key] }))
				);
			} catch (err) {
				addErrorNotification('Cannot parse json');
			}
		}
	}, [value, isModalOpen]);

	return (
		<>
			<Input
				value={value}
				onChange={e => onChange(e.target.value)}
				placeholder={placeholder}
				disabled={disabled}
			/>
			<div className='input-group--password'>
				<SVGIcon name='Settings#1' size={24} onClick={setIsModalOpen} />
			</div>
			<Portal>
				<Popup
					contentStyle={{
						padding: 0,
						background: 'unset',
						border: 'unset',
						maxWidth: '640px',
					}}
					closeOnDocumentClick={false}
					modal
					lockScroll
					open={isModalOpen}
					onClose={() => setIsModalOpen(false)}>
					<Portlet>
						<Portlet.Head>
							<Portlet.HeadLabel portletIcon='Picture'>
								<h3 className='sdms-portlet__head-title'>{title}</h3>
							</Portlet.HeadLabel>
							<Portlet.HeadToolbarActions>
								<Button
									label='brand'
									text={addFieldButtonText}
									icon='Plus'
									size='sm'
									onClick={() =>
										setFields(
											update(fields, { $push: [{ key: '', value: '' }] })
										)
									}
								/>
							</Portlet.HeadToolbarActions>
						</Portlet.Head>
						{presetConfigurations && (
							<Portlet.Head>
								<Portlet.HeadToolbarActions>
									{Object.keys(presetConfigurations).map(key => (
										<Button
											className='sdms-mr-10'
											label='brand'
											text={key}
											size='sm'
											onClick={() =>
												setFields(
													presetConfigurations[key].map(pc => ({
														key: pc,
														value: '',
													}))
												)
											}
										/>
									))}
								</Portlet.HeadToolbarActions>
							</Portlet.Head>
						)}
						<Portlet.Body>
							<ListBody
								className='table--everytime--scroll sdms-portlet__body--fit'
								responsive='scroll'>
								<ListTable childrenLength={3}>
									<colgroup>
										<col />
										<col />
										<col />
									</colgroup>
									<thead>
										<tr>
											<th>{keyTitle}</th>
											<th>{valueTitle}</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody>
										{fields.map((field, index) => (
											<Field
												data={field}
												index={index}
												onChange={data =>
													setFields(
														update(fields, { [index]: { $set: data } })
													)
												}
												onDelete={() =>
													setFields(
														update(fields, { $splice: [[index, 1]] })
													)
												}
											/>
										))}
									</tbody>
								</ListTable>
							</ListBody>
						</Portlet.Body>
						<Portlet.Foot className='sdms-align-left' tall='sm'>
							<div className='col'>
								<Button
									design='clean'
									text='Cancel'
									icon='Error-circle'
									size='sm'
									elevate
									onClick={() => setIsModalOpen(false)}
								/>
							</div>
							<div className='col-auto'>
								<Button
									label='brand'
									icon='Write'
									text='Update'
									size='sm'
									onClick={() => {
										const _json = {};

										fields.forEach(field => {
											_json[field.key] = field.value;
										});

										onChange(JSON.stringify(_json));
										setIsModalOpen(false);
									}}
								/>
							</div>
						</Portlet.Foot>
					</Portlet>
				</Popup>
			</Portal>
		</>
	);
};

Json.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	title: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	presetConfigurations: PropTypes.object,
	keyTitle: PropTypes.string,
	valueTitle: PropTypes.string,
	addFieldButtonText: PropTypes.string,
};

Json.defaultProps = {
	value: '',
	onChange: () => {},
	title: 'Configuration',
	placeholder: '',
	disabled: false,
	presetConfigurations: null,
	keyTitle: 'Key',
	valueTitle: 'Value',
	addFieldButtonText: 'Add Field',
};

export default Json;
