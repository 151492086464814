import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import useDate from '../../../utils/hooks/useDate';
import {
	getRemittanceRemainingAmount,
	numberFormat,
	priceFormatter,
} from '../../../utils/helpers/helper';
import { getPaymentRefundedAmount } from '../../../utils/helpers/paymentHelper';
import { paymentStatuses } from '../../../utils/constants/constants';

import NullBadge from '../design/NullBadge';
import Badge from './Badge';
import usePages from '../../../utils/hooks/usePages';
import SVGIcon from './SVGIcon';
import Portal from '../layout/Portal';

export const CustomerCell = ({ data, onClick }) => {
	return (
		<div onClick={onClick} role='presentation'>
			<span className='sdms-link sdms-link--dark'>{data.customer.displayName}</span>
		</div>
	);
};

CustomerCell.propTypes = {
	data: PropTypes.shape({
		customer: PropTypes.shape({
			displayName: PropTypes.string,
		}),
	}),
	onClick: PropTypes.func,
};
CustomerCell.defaultProps = {
	data: {
		customer: {
			displayName: '',
		},
	},
	onClick: () => {},
};

export const PaymentDateCell = ({ data }) => {
	const [dateFormatter] = useDate();

	return dateFormatter(data.remittanceDate);
};

export const PaymentTypeCell = ({ data }) => {
	if (data.paymentMethod === null) return <NullBadge />;
	return (
		<>
			<Badge design='brand' isDot>
				{data.paymentMethod ? data.paymentMethod.paymentType.value : 'Credit'}
			</Badge>
		</>
	);
};

PaymentTypeCell.propTypes = {
	data: PropTypes.shape({
		paymentMethod: PropTypes.object,
	}),
};
PaymentTypeCell.defaultProps = {
	data: { paymentMethod: null },
};
export const PaymentAmountCell = ({ data }) => priceFormatter(data.amount);

export const PaymentStatusCell = ({ data }) => {
	const [dateFormatter] = useDate();

	const refundedAmount = getPaymentRefundedAmount(data);

	const getColor = () => {
		if (data?.bouncedCheckJournal) return 'danger';

		if (data.amount !== 0 && refundedAmount === data.amount) return 'secondary';

		if (data.status.value === paymentStatuses.UNSETTLED) return 'warning';

		if (data.status.value === paymentStatuses.SETTLED) return 'success';

		if (
			data.status.value === paymentStatuses.PROCESSING ||
			data.status.value === paymentStatuses.SCHEDULED
		)
			return 'info';

		return 'danger';
	};

	const getValue = () => {
		if (data.amount !== 0 && refundedAmount === data.amount) return 'Refunded';
		else if (data?.bouncedCheckJournal) return 'Bounced';

		return data.status.value;
	};

	return (
		<div data-for={`invoice-email-${data.id}`} data-tip='tooltip'>
			<Badge
				design={getColor()}
				isInline
				isUnified
				fontWeight='bold'
				size='lg'
				className='sdms-text-overflow'>
				<span className='sdms-text-overflow sdms-font-transform-c'>{getValue()}</span>
			</Badge>
			{data.inquiry && (
				<>
					<SVGIcon className='sdms-ml-10' name='Send' fill='var(--success)' size={18} />
					{data.lastOutgoingMessageDate && (
						<Portal isTooltip>
							<ReactTooltip
								delayShow={250}
								delayHide={250}
								id={`invoice-email-${data.id}`}
								type='light'
								className='sdms-p3'
								place='bottom'>
								<span className='sdms-font-bold'>
									Email sent at: {dateFormatter(data.lastOutgoingMessageDate)}
								</span>
							</ReactTooltip>
						</Portal>
					)}
				</>
			)}
		</div>
	);
};

PaymentStatusCell.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		status: PropTypes.object,
		amount: PropTypes.number,
		inquiry: PropTypes.bool,
		lastOutgoingMessageDate: PropTypes.string,
	}),
};
PaymentStatusCell.defaultProps = {
	data: {
		status: null,
	},
};

export const PaymentIdLinkCell = ({ data }) => {
	const pages = usePages();

	return (
		<a
			target='_blank'
			rel='noopener noreferrer'
			href={`${window.location.origin}${
				data.paymentMethod ? pages.crm.payments.path : pages.crm.creditMemos.path
			}/${data.id}`}>
			{data.remittanceId}
		</a>
	);
};

PaymentIdLinkCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

PaymentIdLinkCell.defaultProps = {
	data: null,
};

export const SettledCell = ({ data }) =>
	priceFormatter(numberFormat(data.amount - getRemittanceRemainingAmount(data)));

SettledCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

SettledCell.defaultProps = {
	data: null,
};

export const BalanceCell = ({ data }) => priceFormatter(getRemittanceRemainingAmount(data));

BalanceCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

BalanceCell.defaultProps = {
	data: null,
};
