import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import moment from 'moment';

import { required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import useDate from '../../../utils/hooks/useDate';
import { generateId } from '../../../utils/helpers/helper';

import Button from './Button';
import Portlet from '../layout/Portlet';
import FormField from '../template/FormField';
import DatePicker from '../field/DatePicker';
import { ListBody, ListTable } from '../template/List';

const ExcludeDatesItem = ({ item, onFormChange, isSubmitted, onChange, onDelete }) => {
	const [, , parseDatePickerValue, parseDatePickerChange] = useDate();

	const endDateValidation = (value, setValRes) => {
		if (!value || !startDate) return true;

		if (moment(value).isBefore(moment(startDate))) {
			setValRes({
				isValid: false,
				status: 'invalid',
				message: 'End date must be after start date',
			});
			return false;
		}
		return true;
	};

	const [
		startDate,
		startDateOnChange,
		startDateValRes,
		startDateShowVal,
		setStartDateShowVal,
	] = useField({}, 'startDate', onFormChange, [required], item.startDate);

	const [
		endDate,
		endDateOnChange,
		endDateValRes,
		endDateShowVal,
		setEndDateShowVal,
		validateEnDate,
	] = useField({}, 'endDate', onFormChange, [required, endDateValidation], item.endDate);

	useEffect(() => {
		if (isSubmitted) {
			setStartDateShowVal();
			setEndDateShowVal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	useEffect(() => {
		onChange({
			...item,
			startDate,
			endDate,
			isValid: startDateValRes.isValid && endDateValRes.isValid,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate, startDateValRes.isValid, endDateValRes.isValid]);

	useEffect(() => {
		validateEnDate(endDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate]);

	return (
		<tr>
			<th>
				<FormField
					name={`startDate${item.id}`}
					valRes={startDateValRes}
					showValidation={startDateShowVal}
					inFormDesign={false}
					isLast>
					<DatePicker
						id={`startDate${item.id}`}
						type='calendar'
						value={parseDatePickerValue(startDate, 'UTC')}
						onChange={e =>
							startDateOnChange({
								target: { value: parseDatePickerChange(e.target.value) },
							})
						}
						onBlur={setStartDateShowVal}
						placeholder='Start Date'
					/>
				</FormField>
			</th>
			<th>
				<FormField
					name={`endDate${item.id}`}
					valRes={endDateValRes}
					showValidation={endDateShowVal}
					inFormDesign={false}
					isLast>
					<DatePicker
						id={`endDate${item.id}`}
						type='calendar'
						value={parseDatePickerValue(endDate, 'UTC')}
						onChange={e =>
							endDateOnChange({
								target: { value: parseDatePickerChange(e.target.value) },
							})
						}
						onBlur={setEndDateShowVal}
						placeholder='End Date'
					/>
				</FormField>
			</th>
			<th>
				<Button
					btnIcon
					label='danger'
					icon='Trash'
					size='sm'
					elevate
					key='delete'
					onClick={onDelete}
				/>
			</th>
		</tr>
	);
};

ExcludeDatesItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	item: PropTypes.object,
	onChange: PropTypes.func,
	isSubmitted: PropTypes.bool,
	onFormChange: PropTypes.func,
	onDelete: PropTypes.func,
};
ExcludeDatesItem.defaultProps = {
	item: {},
	onChange: () => {},
	isSubmitted: false,
	onFormChange: () => {},
	onDelete: () => {},
};

const ExcludeDates = ({ items, onChange, isSubmitted, onFormChange }) => {
	const onAdd = () => {
		onChange([
			...items,
			{ id: generateId(items), startDate: null, endDate: null, isValid: false },
		]);
	};
	return (
		<div style={{ padding: '0 10px' }}>
			<Portlet className='sdms-list-layout' border>
				<Portlet.Head>
					<Portlet.HeadLabelTitle>Exclude Dates</Portlet.HeadLabelTitle>
					<Portlet.HeadToolbar>
						<Portlet.HeadActions className='sdms-last-margin--h d-flex'>
							<Button
								className='sdms-mr-0'
								label='brand'
								icon='Plus'
								text='Add New'
								size='sm'
								onClick={onAdd}
							/>
						</Portlet.HeadActions>
					</Portlet.HeadToolbar>
				</Portlet.Head>
				<ListBody
					className='table--everytime--scroll sdms-portlet__body--fit'
					responsive='scroll'>
					<ListTable childrenLength={4}>
						<colgroup>
							<col />
							<col />
							<col width={180} />
						</colgroup>
						<thead>
							<tr>
								<th>Start Date</th>
								<th>End Date</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{items.map((item, index) => (
								<ExcludeDatesItem
									key={`excludeDates${item.id}`}
									item={item}
									isSubmitted={isSubmitted}
									onFormChange={onFormChange}
									onChange={i =>
										onChange(
											update(items, {
												[index]: {
													$set: i,
												},
											})
										)
									}
									onDelete={() =>
										onChange(
											update(items, {
												$splice: [[index, 1]],
											})
										)
									}
								/>
							))}
						</tbody>
					</ListTable>
				</ListBody>
			</Portlet>
		</div>
	);
};
ExcludeDates.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object),
	onChange: PropTypes.func,
	isSubmitted: PropTypes.symbol,
	onFormChange: PropTypes.func,
};

ExcludeDates.defaultProps = {
	items: [],
	onChange: () => {},
	isSubmitted: false,
	onFormChange: () => {},
};

export default ExcludeDates;
