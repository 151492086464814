import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import SVGIcon from '../element/SVGIcon';
import { noPermissionStatus } from '../../../utils/helpers/helper';

const Input = React.forwardRef(
	(
		{
			type,
			pattern,
			required,
			togglePassword,
			value,
			onChange,
			onBlur,
			onFocus,
			onKeyPress,
			id,
			name,
			isValid,
			status,
			showValidation,
			disabled,
			placeholder,
			className,
			groupClassName,
			prepend,
			append,
			prependIcon,
			appendIcon,
			prependIconColor,
			appendIconColor,
			mask,
			maskChar,
			formatChars,
			min,
			max,
			autoComplete,
			withOutSpin,
			readOnly,
			onClick,
			noPermission,
			tabIndex,
			xAutoCompleteType,
			step,
			borderLess,
			autoFocus,
		},
		ref
	) => {
		const [passwordShow, setPasswordShow] = useState(false);
		const togglePasswordShow = () => {
			setPasswordShow(!passwordShow);
		};

		const inputClass = classNames(
			'form-control',
			{ 'without-spin': withOutSpin },
			className,
			status === 'isLoading' ? null : showValidation && !isValid && 'is-invalid'
		);

		// For Auto complete
		const _ghostInput = (
			<input
				name={`${name}_autocomplete_off`}
				type={type}
				style={{ position: 'absolute', zIndex: -1, left: '-100vw', top: '-100vh' }}
				autoComplete={type === 'password' ? 'new-password' : null}
				disabled
			/>
		);

		const _Input = mask ? (
			<>
				{!autoComplete && _ghostInput}
				<InputMask
					mask={mask}
					maskChar={maskChar}
					id={id}
					name={name}
					type={type}
					pattern={pattern}
					required={required}
					disabled={disabled}
					min={min}
					max={max}
					step={step}
					value={value || ''}
					onChange={disabled || noPermission ? null : onChange}
					onKeyPress={disabled || noPermission ? null : onKeyPress}
					onBlur={disabled || noPermission ? null : () => onBlur()}
					placeholder={placeholder}
					formatChars={formatChars}
					autoComplete={!autoComplete ? 'off' : null}
					className={inputClass}
					readOnly={readOnly}
					onClick={onClick}
					onFocus={disabled || noPermission ? null : onFocus}
					tabIndex={tabIndex}
					style={borderLess ? { border: 'none', paddingLeft: 0 } : {}}
				/>
			</>
		) : (
			<>
				{!autoComplete && _ghostInput}
				<input
					ref={ref}
					id={id}
					name={name}
					type={type === 'password' && passwordShow ? 'text' : type}
					pattern={pattern}
					required={required}
					disabled={disabled}
					min={min}
					max={max}
					step={step}
					value={value || ''}
					onChange={e => {
						if (e.target.validity.valid) onChange(e);
					}}
					onKeyPress={disabled || noPermission ? null : onKeyPress}
					onBlur={disabled || noPermission ? null : () => onBlur()}
					placeholder={placeholder}
					autoComplete={autoComplete ? xAutoCompleteType : 'off'}
					className={inputClass}
					readOnly={readOnly}
					onClick={disabled || noPermission ? null : onClick}
					onFocus={disabled || noPermission ? null : onFocus}
					tabIndex={tabIndex}
					style={borderLess ? { border: 'none', paddingLeft: 0 } : {}}
					autoFocus={autoFocus}
				/>
			</>
		);

		if (noPermission) {
			return <InputGroup noPermission={noPermission}>{_Input}</InputGroup>;
		}
		if (togglePassword) {
			return (
				<>
					{_Input}
					<div className='input-group--password'>
						<SVGIcon
							name={passwordShow ? 'Hidden' : 'Visible'}
							size={24}
							onClick={togglePasswordShow}
						/>
					</div>
				</>
			);
		}
		if (prepend || prependIcon || append || appendIcon) {
			return (
				<InputGroup className={groupClassName}>
					<InputPend text={prepend} icon={prependIcon} iconColor={prependIconColor} />
					{_Input}
					<InputPend
						text={append}
						icon={appendIcon}
						iconColor={appendIconColor}
						isAppend
					/>
				</InputGroup>
			);
		}

		return <>{_Input}</>;
	}
);
Input.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.oneOf(['text', 'number', 'password', 'email', 'tel', 'url', 'range', 'color']),
	togglePassword: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	showValidation: PropTypes.bool,
	isValid: PropTypes.bool,
	status: PropTypes.string,
	className: PropTypes.string,
	groupClassName: PropTypes.string,
	prepend: PropTypes.string,
	prependIcon: PropTypes.string,
	prependIconColor: PropTypes.string,
	append: PropTypes.string,
	appendIcon: PropTypes.string,
	appendIconColor: PropTypes.string,
	// eslint-disable-next-line react/require-default-props
	mask: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types,react/require-default-props
	formatChars: PropTypes.object,
	maskChar: PropTypes.string,
	min: PropTypes.number,
	max: PropTypes.number,
	autoComplete: PropTypes.bool,
	withOutSpin: PropTypes.bool,
	readOnly: PropTypes.bool,
	onClick: PropTypes.func,
	noPermission: PropTypes.bool,
	tabIndex: PropTypes.number,
	xAutoCompleteType: PropTypes.string,
	step: PropTypes.number,
	borderLess: PropTypes.bool,
	autoFocus: PropTypes.bool,
};
Input.defaultProps = {
	type: 'text',
	id: undefined,
	name: undefined,
	togglePassword: false,
	value: '',
	placeholder: undefined,
	disabled: false,
	showValidation: false,
	isValid: true,
	status: null,
	className: null,
	groupClassName: null,
	onBlur: () => {},
	prepend: null,
	prependIcon: null,
	prependIconColor: null,
	append: null,
	appendIcon: null,
	appendIconColor: null,
	maskChar: '_',
	min: null,
	max: null,
	autoComplete: true,
	withOutSpin: false,
	readOnly: false,
	onClick: undefined,
	noPermission: null,
	tabIndex: 0,
	xAutoCompleteType: null,
	step: null,
	borderLess: false,
	autoFocus: false,
};

export const InputGroup = ({ children, className, noPermission }) => {
	return (
		<div className={classNames('input-group', noPermissionStatus(noPermission), className)}>
			{children}
		</div>
	);
};
InputGroup.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	noPermission: PropTypes.bool,
};
InputGroup.defaultProps = {
	className: null,
	noPermission: null,
};

export const InputPend = ({ text, icon, iconColor, isAppend }) => {
	if (text || icon) {
		return (
			<div className={`input-group-${isAppend ? 'append' : 'prepend'}`}>
				<span className='input-group-text'>
					{text || (
						<SVGIcon
							name={icon}
							className={classNames({
								'sdms-svg-icon--fill': iconColor,
							})}
							fill={iconColor || '#000'}
							size={18}
						/>
					)}
				</span>
			</div>
		);
	}
	return null;
};
InputPend.propTypes = {
	text: PropTypes.string,
	icon: PropTypes.string,
	iconColor: PropTypes.string,
	isAppend: PropTypes.bool,
};
InputPend.defaultProps = {
	text: null,
	icon: null,
	iconColor: null,
	isAppend: false,
};

export default Input;
