import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import UserContext from '../../../app/contexts/UserContext';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Input from '../../reusables/field/Input';
import Loading from '../../reusables/template/Loading';
import Selects from '../../reusables/field/Selects';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';

const UnitMeasureForm = ({
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	isLoading,
	onFormChange,
	submitButtonAttr,
	submit,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [module, moduleOnChange, moduleValRes, moduleShowVal, setModuleShowVal] = useField(
		data,
		'module',
		onFormChange,
		[required],
		null
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setModuleShowVal();
		}
	}, [isSubmitted, setNameShowVal, setModuleShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid && moduleValRes.isValid);
	}, [nameValRes.isValid, moduleValRes.isValid, setIsValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.accounting.default.text,
				path: pages.accounting.dashboard.path,
			},
			{
				title: pages.accounting.settings.text,
				path: pages.accounting.settings.path,
			},
			{
				title: pages.accounting.settings.unitMeasures.text,
				path: pages.accounting.settings.unitMeasures.path,
			},
			{ title: name || `New ${pages.accounting.settings.unitMeasures.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.accounting.settings.unitMeasures.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form' onSubmit={e => e.preventDefault()}>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='Unit Measure Reason Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='module'
								label='Module'
								id={data.id}
								colMd={6}
								valRes={moduleValRes}
								showValidation={moduleShowVal}>
								<Selects
									options={userContext.data.user.company.modules.filter(
										m =>
											m.value === 'Accounting' ||
											m.value === 'Bookings' ||
											m.value === 'Point of Sale'
									)}
									placeholder='Select Module(Required)'
									value={module}
									onChange={moduleOnChange}
									onBlur={setModuleShowVal}
									displayKey='value'
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
UnitMeasureForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
UnitMeasureForm.defaultProps = {
	data: {
		id: 0,
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default UnitMeasureForm;
