import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import pages from '../../pages';
import useField from '../../../utils/hooks/useField';
import { required } from '../../../utils/helpers/validation';
import HeaderContext from '../../../app/contexts/HeaderContext';

import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import FormField from '../../reusables/template/FormField';
import Selects from '../../reusables/field/Selects';
import Loading from '../../reusables/template/Loading';
import FormGroup from '../../reusables/layout/FormGroup';

const EmailAddressAuthForm = ({
	data,
	onFormChange,
	isLoading,
	setIsValid,
	submit,
	submitButtonAttr,
	emailAddresses,
	isSubmitted,
}) => {
	const headerContext = useContext(HeaderContext);

	const location = useLocation();

	const [
		emailAddress,
		emailAddressOnChange,
		emailAddressValRes,
		emailAddressShowVal,
		setEmailAddressShowVal,
	] = useField(data, 'emailAddress', onFormChange, [required], null);

	useEffect(() => {
		if (isSubmitted) setEmailAddressShowVal();
	}, [isSubmitted, setEmailAddressShowVal]);

	useEffect(() => {
		setIsValid(emailAddressValRes.isValid);
	}, [setIsValid, emailAddressValRes.isValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.systemSettings.default.text,
				path: pages.systemSettings.dashboard.path,
			},
			{ title: 'Email Address Auth', isActive: true },
		]);

		headerContext.setPageTitle('Email Address Auth');

		const query = new URLSearchParams(location.search);

		data.code = query.get('code');

		data.session_state = query.get('session_state');

		data.redirect_uri = `${window.location.protocol}//${window.location.host}${pages.systemSettings.emailAddressAuth.path}`;

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<Portlet className='sdms-form' fluid='fluid' hasFrame>
			<Portlet.Body>
				<FormGroup>
					<Loading isLoading={isLoading}>
						<FormField
							name='emailAddress'
							label='Email Address'
							id={data.id}
							valRes={emailAddressValRes}
							showValidation={emailAddressShowVal}
							colMd={6}>
							<Selects
								options={emailAddresses}
								placeholder='Select an Email Address'
								value={emailAddress}
								onChange={emailAddressOnChange}
								onBlur={setEmailAddressShowVal}
								displayKey='displayName'
							/>
						</FormField>
					</Loading>
				</FormGroup>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};

EmailAddressAuthForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	onFormChange: PropTypes.func,
	isLoading: PropTypes.bool,
	isSubmitted: PropTypes.bool,
	setIsValid: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
	emailAddresses: PropTypes.arrayOf(PropTypes.object),
};
EmailAddressAuthForm.defaultProps = {
	data: {
		id: 0,
	},
	onFormChange: () => {},
	isLoading: false,
	isSubmitted: false,
	setIsValid: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
	emailAddresses: [],
};

export default EmailAddressAuthForm;
