import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import ProductCategoryCampgroundForm from './ProductCategoryCampgroundForm';
import usePages from '../../../utils/hooks/usePages';

const ProductCategoryCampgroundEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='productCategoryCampgrounds'
			lists={['productCategoryCampgrounds']}
			fields={<ProductCategoryCampgroundForm />}
			pageTitle={pages.campground.settings.productCategories.text}
			icon={pages.campground.settings.productCategories.icon}
		/>
	);
};

ProductCategoryCampgroundEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default ProductCategoryCampgroundEdit;
