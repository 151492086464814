import React from 'react';
import SVGIcon from '../element/SVGIcon';
import Container from '../layout/Container';

const RotateContent = () => {
	return (
		<Container>
			<div className='row sdms-vh-100 align-items-center'>
				<div className='col-12 text-center'>
					<SVGIcon name='iPhone-X' className='sdms-device--portrait' size={100} />
					<div>Keep the device portrait on mobile devices.</div>
				</div>
			</div>
		</Container>
	);
};

export default RotateContent;
