import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../element/Button';

const New = ({ onClick }) => {
	return (
		<div className='sdms-new-card col-xlg-6 col-12'>
			<div className='sdms-new-card-inner'>
				<Button
					size='lg'
					icon='File-plus'
					label='info'
					onClick={onClick}
					iconSize={36}
					text='New Booking'
				/>
			</div>
		</div>
	);
};

New.propTypes = {
	onClick: PropTypes.func,
};

New.defaultProps = {
	onClick: () => {},
};

export default New;
