/* eslint-disable react/prop-types */
import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';

import UserContext from '../../../app/contexts/UserContext';

import { addErrorNotification } from '../../../utils/helpers/helper';
import apiCall from '../../../utils/helpers/apiCall';

import WidgetWrapper from './WidgetWrapper';

const WidgetGrid = ({ widgets, columns, setLayout, reload, onEditWidget, readOnly }) => {
	const userContext = useContext(UserContext);

	const dashboardRef = useRef(null);

	const [data, setData] = useState({});

	const handleChange = () => {
		if (!dashboardRef.current) return;

		const { contentTemplateChild } = dashboardRef.current;

		const layout = contentTemplateChild.map(item => ({
			id: parseInt(item?.attributes['data-id'].nodeValue, 10),
			col: parseInt(item?.attributes['data-col'].nodeValue, 10),
			row: parseInt(item?.attributes['data-row'].nodeValue, 10),
			sizeX: parseInt(item?.attributes['data-sizex'].nodeValue, 10),
			sizeY: parseInt(item.attributes['data-sizey'].nodeValue, 10),
		}));

		setLayout(layout);
	};

	const formatListData = listData => {
		return listData.map(row => {
			const rowCopy = { ...row };

			Object.keys(row).forEach(key => {
				if (row[key] === null) {
					rowCopy[key] = undefined;
				}
			});

			return rowCopy;
		});
	};

	const loadWidget = widget => {
		apiCall(
			'POST',
			'reportGeneric',
			fetchedData => {
				setData(oldListState => ({
					...oldListState,
					[widget.id]: formatListData(fetchedData),
				}));
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				reportId: widget.report.id,
			}
		);
	};

	const load = () => {
		widgets.forEach(widget => loadWidget(widget));
	};

	useEffect(load, []);

	const Content = memo(
		({ widget }) => (
			<WidgetWrapper
				widget={widget}
				reload={reload}
				onEditWidget={onEditWidget}
				isGridLoading={Object.keys(data).length !== widgets.length}
				data={data[widget.id]}
				readOnly={readOnly}
			/>
		),
		[data]
	);

	return (
		<div className='control-section'>
			<DashboardLayoutComponent
				columns={columns}
				ref={dashboardRef}
				cellSpacing={[16, 16]}
				allowResizing
				dragStop={handleChange}
				resizeStop={handleChange}
				cellAspectRatio={1.25}>
				{widgets.map(widget => (
					<div
						key={`widget-${widget.id}`}
						id={`widget-${widget.id}`}
						className='e-panel'
						data-id={widget.id}
						data-row={widget.row}
						data-col={widget.col}
						data-sizex={widget.sizeX}
						data-sizey={widget.sizeY}>
						<div className='e-panel-container'>
							<Content widget={widget} />
						</div>
					</div>
				))}
			</DashboardLayoutComponent>
		</div>
	);
};

export default WidgetGrid;

WidgetGrid.propTypes = {
	widgets: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			report: PropTypes.shape({
				id: PropTypes.number,
				name: PropTypes.string,
				queryJSON: PropTypes.string,
				reportFields: PropTypes.arrayOf(PropTypes.shape({})),
			}),
			sortOrder: PropTypes.number,
			width: PropTypes.number,
			title: PropTypes.string,
			config: PropTypes.string,
			type: PropTypes.shape({
				id: PropTypes.number,
				value: PropTypes.string,
			}),
			col: PropTypes.number,
			row: PropTypes.number,
			sizeX: PropTypes.number,
			sizeY: PropTypes.number,
		})
	),
	columns: PropTypes.number,
	setLayout: PropTypes.func,
	reload: PropTypes.func,
	onEditWidget: PropTypes.func,
	readOnly: PropTypes.bool,
};

WidgetGrid.defaultProps = {
	widgets: [],
	columns: 4,
	setLayout: () => {},
	reload: () => {},
	onEditWidget: () => {},
	readOnly: false,
};
