import React, { useMemo, useRef } from 'react';
import useComponentSize from '@rehooks/component-size';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';

import Portlet from '../../../layout/Portlet';
import Portal from '../../../layout/Portal';
import PricingItem from '../elements/PricingItem';
import Button from '../../Button';
import ItemMultiImage from '../elements/ItemMultiImage';

const ProductModal = ({
	isOpen,
	onClose,
	product,
	unit,
	productCategories,
	selectProduct,
	isLoading,
	outlet,
	isOnline,
}) => {
	const refModalFoot = useRef();

	const sizeModalFoot = useComponentSize(refModalFoot);

	const modalRef = useRef();

	const pricingKeys = Object.keys(product.pricing);

	const bookingButtonRef = useRef(null);

	const isMulti = useMemo(() => pricingKeys.length > 1, [pricingKeys]);

	return (
		<Portal>
			<Popup
				open={isOpen}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
					minHeight: '80%',
				}}>
				<Portlet
					style={{
						maxHeight: `calc(100vh - (${sizeModalFoot.height}px))`,
					}}>
					<Portlet.Body
						ref={modalRef}
						style={{
							maxHeight: `calc(100vh - (${sizeModalFoot.height}px))`,
						}}>
						<div className='sdms-online-booking-result-product-modal col-12'>
							<div className='sdms-online-booking-result-product-modal-inner'>
								<h5 className='sdms-section__title'>
									{product.name} - {unit.name}{' '}
								</h5>
								<ItemMultiImage images={product.images} />
								{pricingKeys.length > 0 && (
									<div className='sdms-online-booking-result-product-modal-pricing-container'>
										{pricingKeys.map(pricingKey => (
											<PricingItem
												key={`${product.id}${pricingKey}`}
												productCategories={productCategories}
												productCategoryKey={product.categoryId.toString()}
												productKey={product.id}
												pricingKey={pricingKey}
												selectProduct={(
													_product,
													numUnits,
													numPeriod,
													totalText
												) =>
													selectProduct(
														_product,
														numUnits,
														numPeriod,
														totalText,
														unit.id
													)
												}
												isMulti={isMulti}
												isLoading={isLoading}
												outlet={outlet}
												isOnline={isOnline}
												initProducts={[]}
												bookButtonRef={bookingButtonRef}
												hideBookButton={!isMulti}
											/>
										))}
									</div>
								)}
								<div className='sdms-booking-item__info col-lg'>
									<div
										dangerouslySetInnerHTML={{
											__html: product.longDescription,
										}}
									/>
								</div>
							</div>
						</div>
					</Portlet.Body>
					<Portlet.Foot className='sdms-align-left' tall='sm'>
						<div className='col'>
							<Button
								design='default'
								text='Close'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
							/>
							{product.onlineUrl && (
								<Button
									design='default'
									text='More Info'
									icon='Info-circle'
									size='sm'
									elevate
									style={{ marginLeft: 15 }}
									onClick={() => window.open(product.onlineUrl, '_blank')}
								/>
							)}
						</div>
						{!isMulti && (
							<div className='col-auto'>
								<Button
									label='brand'
									text='Book'
									icon='Angle-right-circle'
									size='sm'
									elevate
									onClick={() => {
										if (bookingButtonRef.current)
											bookingButtonRef.current.click();
									}}
								/>
							</div>
						)}
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};

ProductModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	product: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	unit: PropTypes.object.isRequired,
	productCategories: PropTypes.objectOf(PropTypes.object).isRequired,
	selectProduct: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	isOnline: PropTypes.bool.isRequired,
};

export default ProductModal;
