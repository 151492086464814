import React from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';
import { getModuleByPath, getModuleKeyByPath } from '../../../utils/helpers/reusable';

import FormContainer from '../../reusables/template/FormContainer';
import AnalyticsDashboardForm from './AnalyticsDashboardForm';

const AnalyticsDashboardEdit = ({ match }) => {
	const pages = usePages();

	const moduleKey = getModuleKeyByPath();

	const page = pages.analytics[moduleKey];

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='analyticsDashboards'
			lists={[]}
			fields={<AnalyticsDashboardForm />}
			pageTitle={page.text}
			icon={page.icon}
			module={getModuleByPath()}
		/>
	);
};

AnalyticsDashboardEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default AnalyticsDashboardEdit;
