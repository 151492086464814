import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import usePrint from '../../../utils/hooks/usePrint';
import { addErrorNotification, priceFormatter } from '../../../utils/helpers/helper';
import apiCall from '../../../utils/helpers/apiCall';
import { getOnlinePortalDefaultFilters } from '../../modules/online_portal/functions';

import { OrderTable, OrderHead, OrderBody, OrderItem } from '../../modules/terminal/Order';
import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import Portal from '../layout/Portal';

const InvoiceModal = ({ data, customer, onClose, isOpen, outlet, isOnline }) => {
	const [onPrint, PRINT_COMPONENT] = usePrint();

	const [isLoading, setIsLoading] = useState(false);

	const onPrintInvoice = invoiceIds => {
		setIsLoading(true);
		apiCall(
			'POST',
			isOnline ? 'onlinePortalGetPrintInvoiceContent' : 'getPrintInvoiceContent',
			res => {
				onPrint(
					isOnline ? res : res.invoices.join('<p style="page-break-before: always">')
				);
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ outletId: outlet.id, invoiceIds, invoiceId: invoiceIds[0] },
			getOnlinePortalDefaultFilters(customer, outlet)
		);
	};

	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<Portlet className='sdms-list-layout'>
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon='Sale#1'>
							Invoice Details
						</Portlet.HeadLabelTitle>
					</Portlet.Head>
					<Portlet.Head className='align-items-center'>
						<OrderTable>
							<OrderHead
								className='sdms-invoice-table__head'
								hasActions={false}
								isMultiActionsEnabled={false}
								isAllSelected={false}
								hideCheckbox
							/>
						</OrderTable>
					</Portlet.Head>
					<Portlet.Body>
						<OrderTable>
							<OrderBody>
								{data.invoiceItems.map(ii => (
									<OrderItem
										key={ii.id}
										id={ii.id}
										description={ii.description}
										orderItemModifiers={ii.orderItemModifiers}
										name={ii.name}
										note={ii.note}
										price={ii.price}
										quantity={ii.quantity}
										subtotal={ii.subtotal}
										hasActions={false}
										index={ii.id}
										disableActions={false}
										isSelected={false}
										isPriceEditable={false}
										hideCheckbox
										disableQuantity
										userHasPermission={() => true}
									/>
								))}
							</OrderBody>
						</OrderTable>
					</Portlet.Body>
					<Portlet.Foot>
						<div className='col-12'>
							<div className='row sdms-font-lg sdms-font-bolder'>
								<div className='col'>Subtotal</div>
								<div className='col-auto'>{priceFormatter(data.subtotal)}</div>
							</div>
							{data.discountTotal !== 0 && (
								<div className='row sdms-font-lg sdms-font-bolder'>
									<div className='col'>Discount</div>
									<div className='col-auto'>
										{priceFormatter(data.discountTotal)}
									</div>
								</div>
							)}
							<div className='row sdms-font-lg sdms-font-bolder'>
								<div className='col'>TAX</div>
								<div className='col-auto'>{priceFormatter(data.taxTotal)}</div>
							</div>
							<div className='row sdms-font-lg sdms-font-bolder'>
								<div className='col'>Total</div>
								<div className='col-auto'>{priceFormatter(data.total)}</div>
							</div>
						</div>
					</Portlet.Foot>
					<Portlet.Foot className='sdms-align-left' tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Cancel'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
							/>
						</div>
						<div className='col-auto'>
							<Button
								design='default'
								text='Print Invoice'
								icon='Printer'
								size='sm'
								elevate
								onClick={() => {
									onPrintInvoice([data.id]);
								}}
								disabled={isLoading}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
			{PRINT_COMPONENT}
		</Portal>
	);
};

InvoiceModal.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		invoiceItems: PropTypes.arrayOf(PropTypes.object),
		subtotal: PropTypes.number,
		taxTotal: PropTypes.number,
		total: PropTypes.number,
		discountTotal: PropTypes.number,
		tip: PropTypes.number,
	}),
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	isOnline: PropTypes.bool.isRequired,
};

InvoiceModal.defaultProps = {
	data: {
		id: 0,
		invoiceItems: [],
		subtotal: 0,
		taxTotal: 0,
		total: 0,
		discountTotal: 0,
		tip: 0,
	},
	customer: null,
};

export default InvoiceModal;
