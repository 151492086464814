import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';
import Popup from 'reactjs-popup';

import { max, required } from '../../../utils/helpers/validation';

import MultiSelect from '../../reusables/element/MultiSelect';
import Button from '../../reusables/element/Button';
import SVGIcon from '../../reusables/element/SVGIcon';
import Checkbox from '../../reusables/field/Checkbox';
import Input from '../../reusables/field/Input';
import FormField from '../../reusables/template/FormField';
import useField from '../../../utils/hooks/useField';
import Badge from '../../reusables/element/Badge';
import Portlet from '../../reusables/layout/Portlet';
import Toggle from '../../reusables/field/Toggle';
import Portal from '../../reusables/layout/Portal';
import { negativeNumberParser } from '../../../utils/helpers/helper';

const ModifierOverrideForm = ({
	id,
	data,
	modifierPrice,
	updateInvalidPrefixes,
	isSubmitted,
	onFormChange,
	updateData,
}) => {
	const [price, priceOnChange, priceValRes, priceShowVal, setPriceShowVal] = useField(
		data,
		'price',
		onFormChange,
		[max(process.env.REACT_APP_PRICE_UPPER_LIMIT)],
		null,
		negativeNumberParser
	);
	const [disabled, disabledOnChange] = useField(data, 'disabled', onFormChange);

	useEffect(() => {
		if (priceValRes.isValid) updateInvalidPrefixes('remove', data.id);
		else updateInvalidPrefixes('add', data.id);
	}, [data.id, priceValRes.isValid, updateInvalidPrefixes]);

	useEffect(() => {
		if (isSubmitted) setPriceShowVal();
	}, [isSubmitted, setPriceShowVal]);

	useEffect(updateData, [price, disabled]);

	return (
		<td className='sdms-modifier__prefix'>
			<div className='sdms-modifier__prefix-item'>
				{data.disabled ? (
					<span>
						<Badge design='dark' isInline isUnified fontWeight='bold'>
							Disabled
						</Badge>
					</span>
				) : (
					<FormField
						name='price'
						id={id}
						valRes={priceValRes}
						showValidation={priceShowVal}
						inFormDesign={false}>
						<Input
							type='text'
							placeholder={
								modifierPrice || modifierPrice === 0 ? modifierPrice.toString() : ''
							}
							value={price ? price.toString() : ''}
							onChange={priceOnChange}
							onBlur={setPriceShowVal}
							disabled={data.disabled}
							withOutSpin
							pattern={process.env.REACT_APP_NEGATIVE_PRICE_PATTERN}
						/>
					</FormField>
				)}
				<FormField name='disabled' id={id} inFormDesign={false}>
					<Toggle
						value={disabled}
						onChange={disabledOnChange}
						type='done-error'
						size={24}
					/>
				</FormField>
			</div>
		</td>
	);
};
ModifierOverrideForm.propTypes = {
	id: PropTypes.string.isRequired,
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		price: PropTypes.number,
		disabled: PropTypes.bool,
	}).isRequired,
	modifierPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	updateInvalidPrefixes: PropTypes.func.isRequired,
	isSubmitted: PropTypes.bool.isRequired,
	onFormChange: PropTypes.func.isRequired,
	updateData: PropTypes.func.isRequired,
};

const ModifierForm = ({
	index,
	data,
	prepStations,
	assignedModifierPrefixes,
	deleteModifier,
	moveModifier,
	updateInvalidModifiers,
	isSubmitted,
	onFormChange,
	updateData,
	isDisabled,
}) => {
	const ref = useRef(null);
	const [allowDrag, setAllowDrag] = useState(false);
	const [, drop] = useDrop({
		accept: 'ModifierForm',
		hover(item, monitor) {
			if (!ref.current) return;
			const dragIndex = item.index;
			const hoverIndex = index;

			if (dragIndex === hoverIndex) return;
			const hoverBoundingRect = ref.current.getBoundingClientRect();
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
			const clientOffset = monitor.getClientOffset();
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
			const dragBoundingRect = item.ref.current.getBoundingClientRect();
			const dragUp = dragBoundingRect.top > hoverBoundingRect.top;
			const dragDown = dragBoundingRect.bottom < hoverBoundingRect.bottom;

			if (dragUp && dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
			if (dragDown && dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
			moveModifier(dragIndex, hoverIndex);
			item.index = hoverIndex;
		},
	});
	const [{ isDragging }, drag] = useDrag({
		item: { type: 'ModifierForm', id: data.id, index, ref },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
		canDrag: () => allowDrag,
		end: () => {
			setAllowDrag(false);
		},
	});
	drag(drop(ref));

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		''
	);
	const [price, priceOnChange, priceValRes, priceShowVal, setPriceShowVal] = useField(
		data,
		'price',
		onFormChange,
		[required, max(process.env.REACT_APP_PRICE_UPPER_LIMIT)],
		'',
		negativeNumberParser
	);
	const [prefixRequired, prefixRequiredOnChange] = useField(
		data,
		'prefixRequired',
		onFormChange,
		[],
		false
	);
	const [assignedPrepStations, assignedPrepStationsOnChange] = useField(
		data,
		'prepStations',
		onFormChange
	);

	const [invalidPrefixes, setInvalidPrefixes] = useState([]);

	useEffect(() => {
		if (nameValRes.isValid && priceValRes.isValid && invalidPrefixes.length === 0)
			updateInvalidModifiers('remove', data.id);
		else updateInvalidModifiers('add', data.id);
	}, [data.id, nameValRes.isValid, priceValRes.isValid, invalidPrefixes, updateInvalidModifiers]);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setPriceShowVal();
		}
	}, [isSubmitted, setNameShowVal, setPriceShowVal]);

	useEffect(updateData, [name, price, prefixRequired, assignedPrepStations]);

	const updateInvalidPrefixes = (action, item) => {
		const ind = invalidPrefixes.indexOf(item);
		if (action === 'add' && ind === -1)
			setInvalidPrefixes(update(invalidPrefixes, { $push: [item] }));
		else if (action === 'remove' && ind > -1)
			setInvalidPrefixes(
				update(invalidPrefixes, {
					$splice: [[ind, 1]],
				})
			);
	};
	const renderOverride = ind => {
		const override = data.overrides[ind];
		return (
			<ModifierOverrideForm
				key={`${data.id}-${override.modifierPrefix.id}`}
				id={`${data.id}-${override.modifierPrefix.id}`}
				data={override}
				modifierPrice={data.price}
				updateInvalidPrefixes={updateInvalidPrefixes}
				onFormChange={onFormChange}
				isSubmitted={isSubmitted}
				updateData={updateData}
			/>
		);
	};

	const [selectPrinterOpen, setSelectPrinterOpen] = useState(false);
	const initialModifiers = useRef(null);

	return (
		<tr
			className={classNames(
				'sdms-modifier--cell',
				'sdms-modifier--draggable',
				{
					'sdms-modifier--dragging': isDragging,
				},
				{ disabled: isDisabled }
			)}
			ref={ref}>
			<th className='sdms-modifier__draggable'>
				<div
					onMouseDown={() => {
						setAllowDrag(true);
					}}
					role='presentation'>
					<SVGIcon name='Menu' size={32} />
				</div>
			</th>
			<th className='sdms-modifier__modifiers'>
				<FormField
					name='name'
					id={data.id}
					valRes={nameValRes}
					showValidation={nameShowVal}
					inFormDesign={false}>
					<Input
						type='text'
						value={name}
						placeholder='Name'
						onChange={nameOnChange}
						onBlur={setNameShowVal}
					/>
				</FormField>
			</th>
			<th className='sdms-modifier__price'>
				{data.prefixRequired ? (
					<Badge design='info' isInline isUnified>
						Prefix
					</Badge>
				) : (
					<FormField
						name='price'
						id={data.id}
						valRes={priceValRes}
						showValidation={priceShowVal}
						inFormDesign={false}>
						<Input
							type='text'
							value={price.toString()}
							onChange={priceOnChange}
							onBlur={setPriceShowVal}
							disabled={data.prefixRequired}
							pattern={process.env.REACT_APP_NEGATIVE_PRICE_PATTERN}
						/>
					</FormField>
				)}
			</th>
			<th className='sdms-modifier__prefix-req'>
				<FormField name='prefixRequired' id={data.id} inFormDesign={false}>
					<Checkbox
						value={prefixRequired}
						onChange={prefixRequiredOnChange}
						disabled={assignedModifierPrefixes.length < 1}
					/>
				</FormField>
			</th>
			<th className='sdms-modifier__printer'>
				<div className='d-flex align-items-center justify-content-center'>
					<Button
						circle
						icon='Printer'
						iconFill={assignedPrepStations.length > 0 ? 'success' : 'info'}
						size='sm'
						onClick={() => setSelectPrinterOpen(true)}
					/>
				</div>
				<Portal>
					<Popup
						contentStyle={{
							padding: 0,
							background: 'unset',
							border: 'unset',
						}}
						closeOnDocumentClick={false}
						modal
						lockScroll
						open={selectPrinterOpen}
						onOpen={() => {
							initialModifiers.current = assignedPrepStations;
						}}
						onClose={() => setSelectPrinterOpen(false)}>
						<Portlet>
							<Portlet.Head>
								<Portlet.HeadLabelTitle portletIcon='Printer'>
									Select Printer
								</Portlet.HeadLabelTitle>
							</Portlet.Head>
							<Portlet.Body>
								<MultiSelect
									data={prepStations}
									titleProp='name'
									value={assignedPrepStations}
									onChange={e => {
										assignedPrepStationsOnChange(e);
									}}
								/>
							</Portlet.Body>
							<Portlet.Foot tall='sm'>
								<div className='col'>
									<Button
										design='clean'
										icon='Error-circle'
										size='sm'
										elevate
										text='Cancel'
										onClick={() => {
											assignedPrepStationsOnChange({
												target: { value: initialModifiers.current },
											});
											setSelectPrinterOpen(false);
										}}
									/>
								</div>
								<div className='col-auto'>
									<Button
										label='brand'
										icon='Save'
										size='sm'
										elevate
										text='Select'
										onClick={() => {
											setSelectPrinterOpen(false);
										}}
									/>
								</div>
							</Portlet.Foot>
						</Portlet>
					</Popup>
				</Portal>
			</th>
			{assignedModifierPrefixes.map(amp => {
				const ind = data.overrides.findIndex(
					override => override.modifierPrefix.id === amp
				);
				return renderOverride(ind);
			})}
			<td className='sdms-modifier__actions'>
				<Button
					design='link'
					text='Delete'
					icon='Trash'
					size='sm'
					elevate
					key='delete'
					onClick={() => deleteModifier(data.id)}
				/>
			</td>
		</tr>
	);
};
ModifierForm.propTypes = {
	assignedModifierPrefixes: PropTypes.arrayOf(PropTypes.number),
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		prefixRequired: PropTypes.bool,
		prepStations: PropTypes.arrayOf(PropTypes.object),
		overrides: PropTypes.arrayOf(PropTypes.object),
	}).isRequired,
	deleteModifier: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
	isDisabled: PropTypes.bool,
	isSubmitted: PropTypes.bool.isRequired,
	moveModifier: PropTypes.func.isRequired,
	onFormChange: PropTypes.func.isRequired,
	prepStations: PropTypes.arrayOf(PropTypes.object),
	updateData: PropTypes.func.isRequired,
	updateInvalidModifiers: PropTypes.func.isRequired,
};
ModifierForm.defaultProps = {
	assignedModifierPrefixes: [],
	isDisabled: false,
	prepStations: [],
};

export default ModifierForm;
