import React from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';

import FormContainer from '../../reusables/template/FormContainer';
import DiscountForm from './DiscountForm';

const DiscountEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='discounts'
			lists={['enumDiscountCalculations', 'accounts', 'taxCodes']}
			fields={<DiscountForm />}
			pageTitle={pages.accounting.settings.discount.text}
			icon={pages.accounting.settings.discount.icon}
		/>
	);
};

DiscountEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default DiscountEdit;
