import React, { useEffect, useReducer, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import Popup from 'reactjs-popup';

import useField from '../../../utils/hooks/useField';
import { priceFormatter, generateId, negativeNumberParser } from '../../../utils/helpers/helper';
import { getOrderItemDescription } from '../../../utils/helpers/orderHelper';
import { printStatuses as enumPrintStatuses } from '../../../utils/constants/constants';
import { numeric, required, max } from '../../../utils/helpers/validation';
import apiCall from '../../../utils/helpers/apiCall';

import Button from '../../reusables/element/Button';
import ContentInner from '../../reusables/template/ContentInner';
import Container from '../../reusables/layout/Container';
import ModifierSection from './ModifierSection';
import Portlet from '../../reusables/layout/Portlet';
import Textarea from '../../reusables/field/Textarea';
import SVGIcon from '../../reusables/element/SVGIcon';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import FormField from '../../reusables/template/FormField';
import Section from '../../reusables/layout/Section';
import Separator from '../../reusables/layout/Separator';
import Badge from '../../reusables/element/Badge';
import Portal from '../../reusables/layout/Portal';
import Loading from '../../reusables/template/Loading';

const SummarySection = ({ data, orderItemModifiers, onRemove }) => {
	const getStatusIcon = data.minQuantity !== 0 && orderItemModifiers.length < data.minQuantity;

	if (orderItemModifiers.length === 0) return null;
	return (
		<Section className='sdms-modifier--item sdms-m0'>
			<div className='row sdms-section__head justify-content-between'>
				<div className='col-12 d-flex align-items-center'>
					<SVGIcon
						className='sdms-mr-10'
						name={getStatusIcon ? 'Error-circle' : 'Done-circle'}
						fill={getStatusIcon ? 'var(--danger)' : 'var(--success)'}
						size={24}
					/>
					<h4 className='sdms-modifier--item__title'>{data.title}</h4>
					<Badge className='sdms-ml-5' design='info' isInline isUnified>
						{`${
							data.maxQuantity
								? `${orderItemModifiers.length} of ${data.maxQuantity}`
								: orderItemModifiers.length !== 0 && orderItemModifiers.length
						} selected`}
					</Badge>
				</div>
			</div>
			<Section.Body className='sdms-margin-t-10'>
				{orderItemModifiers.map(oim => (
					<div className='row sdms-modifier--item__element' key={oim.id}>
						<div className='col d-flex align-items-center'>{oim.name}</div>
						<div className='col-auto d-flex align-items-center'>
							<div className='sdms-modifier--item__price'>
								{oim.price ? priceFormatter(oim.price) : '-'}
							</div>
							<Button
								icon='Trash'
								className='btn-icon sdms-ml-10'
								label='danger'
								size='sm'
								onClick={() => onRemove(oim)}
							/>
						</div>
					</div>
				))}
				<Separator type='border-dashed' className='w-100' />
			</Section.Body>
		</Section>
	);
};
SummarySection.propTypes = {
	data: PropTypes.shape({
		title: PropTypes.string,
		minQuantity: PropTypes.number,
		maxQuantity: PropTypes.number,
	}).isRequired,
	orderItemModifiers: PropTypes.arrayOf(PropTypes.object).isRequired,
	onRemove: PropTypes.func.isRequired,
};

const CustomModifierModalContainer = ({ isOpen, prepStations, onSubmit, onClose }) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<CustomModifierModal
					prepStations={prepStations}
					onSubmit={onSubmit}
					onClose={onClose}
				/>
			</Popup>
		</Portal>
	);
};
CustomModifierModalContainer.propTypes = {
	isOpen: PropTypes.bool,
	prepStations: PropTypes.arrayOf(PropTypes.object),
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};
CustomModifierModalContainer.defaultProps = {
	isOpen: false,
	prepStations: [],
	onSubmit: () => {},
	onClose: () => {},
};

const CustomModifierModal = ({ prepStations, onSubmit, onClose }) => {
	const data = useRef({
		name: '',
		price: '',
		prepStations: [],
	});

	const [name, nameOnChange, nameValRes, nameShowVal, showNameValidation] = useField(
		data.current,
		'name',
		() => {},
		[required],
		''
	);

	const [price, priceOnChange, priceValRes, priceShowVal, showPriceValidation] = useField(
		data.current,
		'price',
		() => {},
		[max(process.env.REACT_APP_PRICE_UPPER_LIMIT), numeric],
		0,
		negativeNumberParser
	);

	const [assignedPrepStations, assignedPrepStationsOnChange] = useField(
		data.current,
		'prepStations',
		() => {},
		[],
		[]
	);

	const nameInputRef = useRef(null);

	useEffect(() => {
		nameInputRef.current.focus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Layout-left-panel-2'>
					Custom Modifier
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<FormField
					label='Name'
					name='name'
					showValidation={nameShowVal}
					valRes={nameValRes}>
					<Input
						ref={nameInputRef}
						name='name'
						type='text'
						placeholder='Name'
						value={name}
						onChange={nameOnChange}
						onBlur={showNameValidation}
					/>
				</FormField>
				<FormField
					label='Price'
					name='price'
					showValidation={priceShowVal}
					valRes={priceValRes}>
					<Input
						name='price'
						type='text'
						placeholder='Price'
						value={price}
						onChange={priceOnChange}
						onBlur={showPriceValidation}
						pattern={process.env.REACT_APP_NEGATIVE_PRICE_PATTERN}
					/>
				</FormField>

				<FormField name='prepStations' label='Prep Stations'>
					<Selects
						name='prepStations'
						multiple
						value={assignedPrepStations}
						options={prepStations}
						onChange={assignedPrepStationsOnChange}
						placeholder='Select Prep Stations'
					/>
				</FormField>
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Close'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
				<div className='col-auto'>
					<Button
						design='brand'
						icon='Done-circle'
						text='Add'
						size='sm'
						onClick={() => {
							if (!nameValRes.isValid || !priceValRes.isValid) {
								showNameValidation();
								showPriceValidation();
								return;
							}

							onSubmit({
								name,
								price: price === '' ? 0 : parseFloat(price),
								prepStations: assignedPrepStations,
							});
						}}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
CustomModifierModal.propTypes = {
	prepStations: PropTypes.arrayOf(PropTypes.object).isRequired,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

const Modifiers = ({
	productData,
	orderItemData,
	onAdd,
	onCancel,
	prepStations,
	title,
	printStatuses,
}) => {
	const product = useRef(productData);

	const [isValid, setIsValid] = useState(false);

	const [isLoading, setIsLoading] = useState(true);

	const [error, setError] = useState('');

	const [customModifierModalData, setCustomModifierModalData] = useState({
		isOpen: false,
		modifierSection: null,
	});

	const reducer = (state, action) => {
		const { type, payload } = { ...action };

		const getOrderItemName = orderItemModifier => {
			if (!orderItemModifier.modifierPrefix) return orderItemModifier.name;

			return orderItemModifier.modifierPrefix.isSuffix
				? `${orderItemModifier.name} ${orderItemModifier.modifierPrefix.name}`
				: `${orderItemModifier.modifierPrefix.name} ${orderItemModifier.name}`;
		};

		if (type === 'set')
			return update(state, {
				$set: payload,
			});

		if (type === 'add') {
			state = update(state, {
				orderItemModifiers: {
					$push: [
						{
							id: generateId(state.orderItemModifiers),
							...payload,
							price: payload.price,
							name: getOrderItemName(payload),
							printStatus: printStatuses.find(
								ps => ps.value === enumPrintStatuses.NEW
							),
						},
					],
				},
				price: { $set: state.price + payload.price },
			});

			return update(state, { description: { $set: getOrderItemDescription(state) } });
		}

		if (type === 'addCustomModifier') {
			const modifierSectionIndex = product.current.modifierSections.findIndex(
				ms => ms.id === payload.modifierSection.id
			);

			const modifierData = {
				id: payload.id,
				...payload.modifier,
				sortOrder: 99,
				prefixRequired: false,
				isCustom: true,
			};

			state = update(state, {
				orderItemModifiers: {
					$push: [
						{
							id: generateId(state.orderItemModifiers),
							modifier: modifierData,
							name: payload.modifier.name,
							price: payload.modifier.price,
							modifierSection: payload.modifierSection,
						},
					],
				},
				price: {
					$set:
						state.price + payload.modifier.price > 0
							? state.price + payload.modifier.price
							: 0,
				},
			});

			// check for prevent duplicate add.
			if (
				product.current.modifierSections[
					modifierSectionIndex
				].modifierGroup.modifiers.findIndex(m => m.id === modifierData.id) === -1
			) {
				product.current = update(product.current, {
					modifierSections: {
						[modifierSectionIndex]: {
							modifierGroup: {
								modifiers: { $push: [modifierData] },
							},
						},
					},
				});
			}

			return update(state, { description: { $set: getOrderItemDescription(state) } });
		}

		if (type === 'remove') {
			const orderItemModifierIndex = state.orderItemModifiers.findIndex(
				oim =>
					oim.modifierSection.id === payload.modifierSection.id &&
					oim.modifier &&
					oim.modifier.id === payload.modifier.id
			);

			state = update(state, {
				orderItemModifiers: { $splice: [[orderItemModifierIndex, 1]] },
				price: {
					$set: state.price - state.orderItemModifiers[orderItemModifierIndex].price,
				},
			});

			return update(state, { description: { $set: getOrderItemDescription(state) } });
		}

		if (type === 'updateNote') return update(state, { note: { $set: payload } });

		return state;
	};

	const [orderItem, dispatch] = useReducer(reducer, {
		id: 0,
		name: product.current.name,
		price: product.current.price,
		quantity: 1,
		note: '',
		orderItemModifiers: [],
		description: '',
	});

	useEffect(() => {
		if (orderItemData) dispatch({ type: 'set', payload: orderItemData });
	}, [orderItemData]);

	// check min quantity of sections.
	useEffect(() => {
		if (!isLoading) {
			setIsValid(true);
			product.current.modifierSections.forEach(ms => {
				if (
					orderItem.orderItemModifiers.filter(oim => oim.modifierSection.id === ms.id)
						.length < ms.minQuantity
				)
					setIsValid(false);
			});
		}
	}, [orderItem, product, isLoading]);

	useEffect(() => {
		setIsLoading(true);
		apiCall(
			'GET',
			'posProducts',
			res => {
				product.current = res;
				setIsLoading(false);
			},
			err => {
				setError(err.toString());
				setIsLoading(false);
			},
			productData.id
		);
	}, [orderItemData, productData]);

	const modifierSectionLoading = () => {
		const _buttons = [];
		const _random = Math.floor(Math.random() * 5) + 1;
		for (let i = 0; i < _random; i += 1) {
			_buttons.push(
				<div className='col-xl-2 col-lg-3 col-md-6 col-6 sdms-mb-10 d-flex align-items-stretch'>
					<Loading isLoading type='button'>
						<Button
							label='info'
							bold='bold'
							block
							className='sdms-pr5 sdms-pl5 sdms-fitText'
							text='Loading'
						/>
					</Loading>
				</div>
			);
		}
		return _buttons;
	};

	// if (isLoading) return <p>Loading...</p>;

	if (error !== '') return <p>{error}</p>;

	return (
		<>
			<Container.Content>
				<ContentInner.SubHeader>
					<ContentInner.SubHeaderItem>
						<ContentInner.SubHeaderTitle
							title={product.current.name}
							icon='Clipboard'
						/>
						<ContentInner.SubHeaderSeparator />
						<ContentInner.SubHeaderGroup>
							<ContentInner.SubHeaderDesc>
								{priceFormatter(product.current.price)}
							</ContentInner.SubHeaderDesc>
						</ContentInner.SubHeaderGroup>
					</ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderItem type='toolbar'>
						<Button
							design='default'
							icon='Error-circle'
							text='Cancel'
							onClick={onCancel}
						/>
						<Button
							className={classNames({
								'sdms-fading-dots': false,
							})}
							design='primary'
							icon='Clipboard-list'
							text={orderItemData ? 'Update Order' : 'Add To Order'}
							disabled={!isValid}
							onClick={() => onAdd(orderItem)}
						/>
					</ContentInner.SubHeaderItem>
				</ContentInner.SubHeader>
				<ContentInner.Container title={title}>
					<div className='row sdms-t-split-container h-100'>
						<div className='col-xl-8 col-lg-7 col-md-12 col-12'>
							<Portlet
								fluid='fluid'
								className='sdms-bg-transparent sdms-portlet--unelevate sdms-t-modifier__container'>
								<Portlet.Body className='sdms-last-margin sdms-p0'>
									{isLoading ? (
										<Portlet className='flex-grow-0 sdms-portlet__modifiers sdms-min-h-fit-content'>
											<Portlet.Head>
												<Portlet.HeadLabelTitle portletIcon='Layers'>
													<Loading isLoading type='div'>
														<div>Loading</div>
													</Loading>
												</Portlet.HeadLabelTitle>
											</Portlet.Head>
											<Portlet.Body>
												<div className='row'>
													{modifierSectionLoading()}
												</div>
											</Portlet.Body>
										</Portlet>
									) : (
										product.current.modifierSections
											.sort((a, b) => {
												return a.sortOrder - b.sortOrder;
											})
											.map(ms => (
												<ModifierSection
													key={ms.id}
													data={ms}
													selectedModifiers={orderItem.orderItemModifiers.filter(
														oim => oim.modifierSection.id === ms.id
													)}
													onAdd={data =>
														dispatch({ type: 'add', payload: data })
													}
													onRemove={data =>
														dispatch({
															type: 'remove',
															payload: data,
														})
													}
													onCustomModifierClick={() =>
														setCustomModifierModalData({
															isOpen: true,
															modifierSection: ms,
														})
													}
												/>
											))
									)}
								</Portlet.Body>
							</Portlet>
						</div>
						<div className='col-xl-4 col-lg-5 col-md-12 col-12'>
							<Portlet fluid='fluid'>
								<Portlet.Head>
									<Portlet.HeadLabel portletIcon='Price1'>
										<h3 className='sdms-portlet__head-title'>Base Price</h3>
									</Portlet.HeadLabel>
									<Portlet.HeadLabel>
										<div className='sdms-font-lg sdms-font-bolder'>
											{priceFormatter(product.current.price)}
										</div>
									</Portlet.HeadLabel>
								</Portlet.Head>
								<Portlet.Body>
									{product.current.modifierSections.map(ms => (
										<SummarySection
											key={ms.id}
											orderItemModifiers={orderItem.orderItemModifiers.filter(
												oim => oim.modifierSection.id === ms.id
											)}
											data={ms}
											onRemove={data =>
												dispatch({ type: 'remove', payload: data })
											}
										/>
									))}
								</Portlet.Body>
								<Portlet.Foot tall='md'>
									<div className='col'>
										<Textarea
											placeholder='Item notes...'
											value={orderItem.note || ''}
											onChange={e =>
												dispatch({
													type: 'updateNote',
													payload: e.target.value,
												})
											}
										/>
									</div>
								</Portlet.Foot>
								<Portlet.Foot
									tall='md'
									subClassName='align-items-stretch sdms-font-lg sdms-font-bolder'>
									<div className='col'>Price</div>
									<div className='col-auto text-right'>
										{priceFormatter(orderItem.price)}
									</div>
								</Portlet.Foot>
							</Portlet>
						</div>
					</div>
				</ContentInner.Container>
			</Container.Content>
			<CustomModifierModalContainer
				isOpen={customModifierModalData.isOpen}
				prepStations={prepStations}
				onSubmit={data => {
					dispatch({
						type: 'addCustomModifier',
						payload: {
							modifier: data,
							modifierSection: customModifierModalData.modifierSection,
							id: generateId(
								customModifierModalData.modifierSection.modifierGroup.modifiers
							),
						},
					});
					setCustomModifierModalData({ isOpen: false, modifierSection: null });
				}}
				onClose={() => setCustomModifierModalData({ isOpen: false, modifierSection: null })}
			/>
		</>
	);
};
Modifiers.propTypes = {
	productData: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		price: PropTypes.number,
		modifierSections: PropTypes.arrayOf(PropTypes.object),
	}).isRequired,
	// eslint-disable-next-line react/require-default-props
	orderItemData: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		price: PropTypes.number,
		quantity: PropTypes.number,
		subtotal: PropTypes.number,
		note: PropTypes.string,
		orderItemModifiers: PropTypes.array,
		description: PropTypes.string,
	}),
	onAdd: PropTypes.func,
	onCancel: PropTypes.func,
	prepStations: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
	printStatuses: PropTypes.arrayOf(PropTypes.object),
};
Modifiers.defaultProps = {
	onAdd: () => {},
	onCancel: () => {},
	prepStations: [],
	printStatuses: [],
};

export default Modifiers;
