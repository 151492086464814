import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../Button';
import { bookingPeriods } from '../../../../../../utils/constants/constants';
import { convertDateToUTC, dateView } from '../../../../../../utils/helpers/helper';
import Badge from '../../../Badge';
import PricingItem from '../../elements/PricingItem';
import ItemMultiImage from '../../elements/ItemMultiImage';

const GridItem = ({
	product,
	productCategories,
	selectProduct,
	isLoading,
	multipleSelected,
	setMultipleSelected,
	outlet,
	isOnline,
	initProducts,
	updateSearchData,
	searchData,
}) => {
	const [showMore, setShowMore] = useState(false);

	const getAlternateDateButtonText = date => {
		const { bookingPeriod } = product;

		let period = '';
		if (bookingPeriod === bookingPeriods.DAILY) period = 'day';
		else if (bookingPeriod === bookingPeriods.NIGHTLY) period = 'night';

		const realQuantity = date.duration;

		return (
			<>
				{dateView(date.start, date.end, false)}
				{bookingPeriod === bookingPeriods.HOURLY
					? ''
					: ` ${realQuantity} ${period}${realQuantity > 1 ? 's' : ''}`}
			</>
		);
	};

	const onProductNameClick = () => {
		if (product.onlineUrl) window.open(product.onlineUrl, '_blank');
	};

	const onAlternateDateClick = date => {
		updateSearchData({
			fromDate: convertDateToUTC(new Date(date.start)),
			toDate: convertDateToUTC(new Date(date.end)),
			alternateDateSearch: true,
		});
	};

	const pricingKeys = Object.keys(product.pricing);

	const alternateDates = useMemo(() => {
		return product.pricing[0]?.alternateDates || [];
	}, [product]);

	return (
		<div className='sdms-online-booking-result-grid-view-item col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12'>
			<div className='sdms-online-booking-result-grid-view-item-inner'>
				<h6
					className={classNames('sdms-section__title', {
						'sdms-cursor--pointer': !!product.onlineUrl,
						'sdms-link': !!product.onlineUrl,
					})}
					role='presentation'
					onClick={onProductNameClick}>
					{product.name}
				</h6>
				<ItemMultiImage images={product.images} />
				<div className='sdms-booking-item__info col-lg'>
					{/* eslint-disable-next-line react/no-danger */}
					<div dangerouslySetInnerHTML={{ __html: product.longDescription }} />
				</div>
				{alternateDates.length === 0 && (
					<div className='sdms-online-booking-result-grid-view-item-pricing-container'>
						{pricingKeys.map(pricingKey => (
							<PricingItem
								key={`${product.id}${pricingKey}`}
								productCategories={productCategories}
								productCategoryKey={product.productCategoryKey}
								productKey={product.id}
								pricingKey={pricingKey}
								selectProduct={selectProduct}
								isMulti={pricingKeys.length > 1}
								isLoading={isLoading}
								multipleSelected={multipleSelected}
								setMultipleSelected={setMultipleSelected}
								outlet={outlet}
								isOnline={isOnline}
								initProducts={initProducts}
								isGrid
								searchData={searchData}
							/>
						))}
					</div>
				)}
				{alternateDates.length > 0 && (
					<div className='sdms-online-booking-result-grid-view-item-alternate-dates'>
						<div className='col-auto text-right sdms-padding-b-5'>
							<Badge className='sdms-mr-0' design='info' isInline>
								Alternate Dates
							</Badge>
						</div>
						{alternateDates.slice(0, 2).map(date => (
							<Button
								label='secondary'
								text={getAlternateDateButtonText(date)}
								block
								size='sm'
								elevate
								onClick={() => onAlternateDateClick(date)}
							/>
						))}
						{alternateDates.length > 2 && !showMore && (
							<div
								className='sdms-online-booking-result-grid-view-item-alternate-dates-show-more'
								onClick={() => setShowMore(true)}
								role='presentation'>
								View All Alternates
								<Badge
									design='success'
									isInline
									isUnified
									size='sm'
									fontWeight='bold'>
									{Math.min(alternateDates.length, 10) - 2}
								</Badge>
							</div>
						)}
						{showMore &&
							alternateDates
								.slice(2, 10)
								.map(date => (
									<Button
										label='secondary'
										text={getAlternateDateButtonText(date)}
										block
										size='sm'
										elevate
										onClick={() => onAlternateDateClick(date)}
									/>
								))}
					</div>
				)}
			</div>
		</div>
	);
};

GridItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	product: PropTypes.object.isRequired,
	productCategories: PropTypes.objectOf(PropTypes.object).isRequired,
	selectProduct: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	multipleSelected: PropTypes.object,
	setMultipleSelected: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	isOnline: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initProducts: PropTypes.array.isRequired,
	updateSearchData: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	searchData: PropTypes.object,
};

GridItem.defaultProps = {
	multipleSelected: null,
	searchData: null,
};

const SearchResultGridView = ({
	productCategories,
	isOnline,
	isLoading,
	selectProduct,
	multipleSelected,
	setMultipleSelected,
	outlet,
	initProducts,
	updateSearchData,
	searchData,
}) => {
	const products = useMemo(() => {
		const _products = [];

		if (!productCategories || Object.keys(productCategories).length === 0) return _products;

		Object.keys(productCategories).forEach(categoryId => {
			const productKeys = Object.keys(productCategories[categoryId].products).sort(
				(a, b) =>
					productCategories[categoryId].products[a].sortOrder -
					productCategories[categoryId].products[b].sortOrder
			);

			_products.push(
				...productKeys.map(pk => ({
					...productCategories[categoryId].products[pk],
					id: pk,
					categoryName: productCategories[categoryId].categoryName,
					productCategoryKey: categoryId,
				}))
			);
		});

		return _products;
	}, [productCategories]);

	return (
		<div className='sdms-online-booking-result-grid-view-item-container row'>
			{products.map(product => (
				<GridItem
					product={product}
					key={product.id}
					productCategories={productCategories}
					selectProduct={selectProduct}
					isLoading={isLoading}
					multipleSelected={multipleSelected}
					setMultipleSelected={setMultipleSelected}
					outlet={outlet}
					isOnline={isOnline}
					initProducts={initProducts}
					updateSearchData={updateSearchData}
					searchData={searchData}
				/>
			))}
		</div>
	);
};

SearchResultGridView.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	productCategories: PropTypes.object.isRequired,
	isOnline: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	selectProduct: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	multipleSelected: PropTypes.object,
	setMultipleSelected: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initProducts: PropTypes.array,
	updateSearchData: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	searchData: PropTypes.object,
};

SearchResultGridView.defaultProps = {
	initProducts: [],
	multipleSelected: null,
	searchData: null,
};

export default SearchResultGridView;
