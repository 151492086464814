import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import ListContainer from '../../reusables/template/ListContainer';
import List from '../../reusables/template/List';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import forms from '../../forms';
import { inchToFeet, priceFormatter } from '../../../utils/helpers/helper';
import Badge from '../../reusables/element/Badge';
import UserContext from '../../../app/contexts/UserContext';
import { modules } from '../../../utils/helpers/apiCall';

export const CustomerCell = ({ data, onClick }) => {
	return (
		<div onClick={onClick} role='presentation'>
			<span className='sdms-font-bold sdms-link sdms-link--dark'>
				{data.reservationItem.reservation.customer.displayName}
			</span>
			<div className='sdms-font-sm sdms-list-layout__small-text'>
				{data.reservationItem.vehicle
					? data.reservationItem.vehicle.name
					: `${inchToFeet(data.reservationItem.loa)} x ${inchToFeet(
							data.reservationItem.beam
					  )} x ${inchToFeet(data.reservationItem.height)}`}
			</div>
		</div>
	);
};

const SpaceCell = ({ data }) => data.reservationItem.unit.name;

const PowerMeterTypeCell = ({ data }) => {
	return data.powerMeter.powerMeterType.name;
};

PowerMeterTypeCell.propTypes = {
	data: PropTypes.object,
};

export const StatusCell = ({ data }) => {
	return (
		<Badge
			design={data.invoice ? 'success' : 'info'}
			isInline
			isUnified
			fontWeight='bold'
			className='sdms-font-transform-c'>
			{data.invoice ? 'Invoiced' : 'Open'}
		</Badge>
	);
};

export const ReadingCell = ({ data, field }) => {
	const userContext = useContext(UserContext);
	const unit =
		userContext.data.user.company.settings &&
		userContext.data.user.company.settings.powerMeterUnit
			? userContext.data.user.company.settings.powerMeterUnit
			: 'kWh';
	return `${data[field]} ${unit}`;
};

export const PriceCell = ({ data, field }) => {
	return priceFormatter(data[field]);
};

const MeterReadingList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
			{ title: pages.campground.meterReadings.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.campground.meterReadings.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='campgroundMeterReadings'
			title={pages.campground.meterReadings.text}
			history={history}
			icon={pages.campground.meterReadings.icon}
			forms={forms.campground.meterReadings}
			customActions={{ batch: () => history.push(pages.campground.meterReadings.batch.path) }}
			checkEditable={meterReadings =>
				meterReadings.filter(mr => mr.invoice).length > 0 ? [] : ['delete']
			}
			module={modules.CAMPGROUND}>
			<List
				withCheckBox
				fluid='fluid'
				checkEditable={meterReading =>
					meterReading.invoice ? ['edit'] : ['edit', 'delete']
				}>
				<List.Col label='Meter Read' cellData='id' isLinkColumn sortable='id' />
				<List.Col
					label='Booking'
					cellDataObject='reservationItem'
					cellData='id'
					sortable='reservationItem.id'
				/>
				<List.Col label='Customer' cellComponent={<CustomerCell />} isLinkColumn />
				<List.Col label='Space' cellComponent={<SpaceCell />} />
				<List.Col
					label='Meter'
					cellDataObject='powerMeter'
					cellData='name'
					sortable='powerMeter.name'
				/>
				<List.Col label='Meter Type' cellComponent={<PowerMeterTypeCell />} />
				<List.Col
					label='Previous Reading'
					cellComponent={<ReadingCell field='previousReading' />}
					sortable='previousReading'
				/>
				<List.Col
					label='Current Reading'
					cellComponent={<ReadingCell field='currentReading' />}
					sortable='currentReading'
				/>
				<List.Col
					label='Cost'
					cellComponent={<PriceCell field='costPerUnit' />}
					sortable='costPerUnit'
				/>
				<List.Col
					label='Total'
					cellComponent={<PriceCell field='totalCost' />}
					sortable='totalCost'
				/>
				<List.Col label='Status' cellComponent={<StatusCell />} />
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
MeterReadingList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default MeterReadingList;
