import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useField from '../../../utils/hooks/useField';
import {
	checkValidation,
	creditCardRequired as creditCardValidation,
} from '../../../utils/helpers/validation';
import { addErrorNotification } from '../../../utils/helpers/helper';
import { paymentTypes } from '../../../utils/constants/constants';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import Section from '../../reusables/layout/Section';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import usePaymentGateway, { parsePaymentData } from '../../../utils/hooks/usePaymentGateway';
import apiCall from '../../../utils/helpers/apiCall';
import CreditCardForm from '../../reusables/element/CreditCardForm';
import UserContext from '../../../app/contexts/UserContext';
import CheckForm from '../../reusables/element/CheckForm';

const CustomerPaymentMethodForm = ({
	type,
	data,
	paymentMethods,
	displayMakeDefault,
	onAdd,
	onCancel,
	icon,
	customer,
	title,
	onBack,
}) => {
	const userContext = useContext(UserContext);

	const [tokenizePayment] = usePaymentGateway(userContext.data.selectedOutlet, paymentMethods);

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isValid, setIsValid] = useState(true);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [submitButtonAttr, setSubmitButtonAttr] = useState({
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	});

	const onFormChange = () => {
		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});
	};

	const [creditCard, creditCardOnChange, creditCardValRes] = useField(
		data,
		'creditCard',
		onFormChange,
		[creditCardValidation],
		null
	);

	const [achAccount, achAccountOnChange, achAccountValRes] = useField(
		data,
		'achAccount',
		onFormChange,
		[checkValidation],
		null
	);

	const onFail = err => {
		setIsSubmitting(false);
		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});
		addErrorNotification(err.toString());
	};

	const onTokenize = () => {
		setIsSubmitted(true);

		if (!isValid) return;

		setIsSubmitting(true);

		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});

		tokenizePayment(
			type,
			parsePaymentData(type, type === paymentTypes.CREDIT_CARD ? creditCard : achAccount),
			tokenizedPayment => {
				onSave(
					tokenizedPayment
						? {
								...data,
								...tokenizedPayment,
								cvc: tokenizedPayment.cvc ? tokenizedPayment.cvc : '#',
								expMonth: tokenizedPayment.expMonth
									? tokenizedPayment.expMonth
									: '#',
								expYear: tokenizedPayment.expYear ? tokenizedPayment.expYear : '#',
						  }
						: data
				);
			},
			onFail
		);
	};

	const onSave = paymentMethod => {
		apiCall(
			'POST',
			'saveCustomerPaymentMethod',
			res => {
				onAdd({
					...res,
					makeDefault:
						type === paymentTypes.CREDIT_CARD
							? creditCard?.makeDefault
							: achAccount?.makeDefault,
				});
			},
			onFail,
			'',
			{
				...paymentMethod,
				outletId: userContext.data.selectedOutlet.id,
				customerId: customer.id,
				type,
			}
		);
	};

	useEffect(() => {
		setIsValid(
			type === paymentTypes.CREDIT_CARD ? creditCardValRes.isValid : achAccountValRes.isValid
		);
	}, [setIsValid, type, creditCardValRes.isValid, achAccountValRes.isValid]);

	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon={icon}>{title}</Portlet.HeadLabelTitle>

				{onBack && (
					<Portlet.HeadToolbarActions>
						<Button
							design='brand'
							text='Back'
							icon='Backspace'
							size='sm'
							onClick={onBack}
						/>
					</Portlet.HeadToolbarActions>
				)}
			</Portlet.Head>
			<Portlet.Body>
				<Section>
					<Section.Body>
						<FormGroup>
							{type === paymentTypes.CREDIT_CARD && (
								<FormField name='creditCardForm' colMd={12}>
									<CreditCardForm
										onChange={_creditCard =>
											creditCardOnChange({
												target: { value: _creditCard },
											})
										}
										isDisabled={isSubmitting}
										isAuthorizing={isSubmitting}
										creditCards={[]}
										isSubmitted={isSubmitted}
										withLabel={false}
										disableScanner
										displayInputPrependIcon={false}
										displaySaveCardToggle={false}
										displayDefaultToggle={displayMakeDefault}
										customerName={customer.displayName}
									/>
								</FormField>
							)}
							{type === paymentTypes.CHECK && (
								<FormField name='creditCardForm' colMd={12}>
									<CheckForm
										onChange={_achAccount => {
											achAccountOnChange({
												target: { value: _achAccount },
											});
										}}
										isDisabled={isSubmitting}
										setIsAchEnabled={() => {}}
										onlyAch
										hasAch
										isSubmitted={isSubmitted}
										withLabel
										displayCorporateToggle
										displaySaveCardToggle={false}
										displayDefaultToggle
										halfInputs
									/>
								</FormField>
							)}
						</FormGroup>
					</Section.Body>
				</Section>
			</Portlet.Body>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onCancel}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label={submitButtonAttr.color}
						text={submitButtonAttr.text}
						icon={submitButtonAttr.icon}
						className={classNames(' sdms-mw-100', {
							'sdms-fading-dots':
								submitButtonAttr.text ===
								process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
						})}
						size='sm'
						elevate
						onClick={onTokenize}
						disabled={isSubmitting}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
CustomerPaymentMethodForm.propTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string,
	icon: PropTypes.string,
	data: PropTypes.shape({
		id: PropTypes.number,
		cardNumber: PropTypes.string,
		paymentMethod: PropTypes.object,
		token: PropTypes.string,
		paymentGateway: PropTypes.object,
		ccv: PropTypes.string,
		expMonth: PropTypes.string,
		expYear: PropTypes.string,
		isChecking: PropTypes.bool,
		bankName: PropTypes.string,
		accountName: PropTypes.string,
		accountHolderName: PropTypes.string,
		routingNumber: PropTypes.string,
		accountNumber: PropTypes.string,
		makeDefault: PropTypes.bool,
		isCorporate: PropTypes.bool,
	}),
	paymentMethods: PropTypes.arrayOf(PropTypes.object),
	onAdd: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	displayMakeDefault: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object.isRequired,
	onBack: PropTypes.func,
};

CustomerPaymentMethodForm.defaultProps = {
	icon: 'Credit cards',
	title: 'New',
	data: {
		id: 0,
		cardNumber: '',
		paymentMethod: null,
		token: null,
		paymentGateway: null,
		ccv: '',
		expMonth: '',
		expYear: '',
		isChecking: true,
		bankName: '',
		accountName: '',
		accountHolderName: '',
		routingNumber: '',
		accountNumber: '',
		makeDefault: false,
		isCorporate: true,
	},
	paymentMethods: [],
	onCancel: () => {},
	displayMakeDefault: false,
	onBack: null,
};

export default CustomerPaymentMethodForm;
