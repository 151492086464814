import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import Portlet from '../layout/Portlet';

import Button from '../element/Button';
import Portal from '../layout/Portal';
import Textarea from '../field/Textarea';
import QuickPanel from '../../QuickPanel';
import { mediaBreakpoint } from '../../../utils/constants/constants';
import { useWindowSize } from '../../../utils/helpers/helper';

const NoteModal = ({ title, icon, initNote, setNoteValue, onClose, isOpen }) => {
	const windowSize = useWindowSize();

	if (windowSize.width < mediaBreakpoint.MD) {
		return (
			<QuickPanel status={isOpen} setStatus={onClose} title={title} icon={icon}>
				<NoteModalContent
					initNote={initNote}
					setNoteValue={setNoteValue}
					onClose={onClose}
					withOutPortlet
				/>
			</QuickPanel>
		);
	}
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<NoteModalContent
					title={title}
					icon={icon}
					initNote={initNote}
					setNoteValue={setNoteValue}
					onClose={onClose}
				/>
			</Popup>
		</Portal>
	);
};
NoteModal.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.string,
	initNote: PropTypes.string,
	setNoteValue: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
};
NoteModal.defaultProps = {
	title: 'Note',
	icon: 'Write',
	initNote: '',
};

const NoteModalContent = ({ title, icon, initNote, setNoteValue, onClose, withOutPortlet }) => {
	const [note, setNote] = useState('');
	const textareaRef = useRef(null);

	useEffect(() => {
		textareaRef.current.focus();
		// to get cursor at the end, we fill text area after rendering
		setNote(initNote === null ? '' : initNote);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const _Inner = (
		<>
			<Portlet.Body>
				<Textarea
					ref={textareaRef}
					value={note}
					onChange={event => {
						setNote(event.target.value);
					}}
					className='sdms-h-100-mobile'
				/>
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='brand'
						icon='Write'
						text={initNote ? 'Update' : 'Add'}
						size='sm'
						onClick={() => setNoteValue(note)}
					/>
				</div>
			</Portlet.Foot>
		</>
	);

	if (withOutPortlet) {
		return _Inner;
	}
	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon={icon}>{title}</Portlet.HeadLabelTitle>
			</Portlet.Head>
			{_Inner}
		</Portlet>
	);
};
NoteModalContent.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.string,
	initNote: PropTypes.string,
	setNoteValue: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	withOutPortlet: PropTypes.bool,
};
NoteModalContent.defaultProps = {
	title: 'Note',
	icon: 'Write',
	initNote: '',
	withOutPortlet: false,
};

export default NoteModal;
