import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import DocumentTitle from 'react-document-title';
import apiCall from '../../../utils/helpers/apiCall';
import useModal from '../../../utils/hooks/useModal';
import {
	addErrorNotification,
	addSuccessNotification,
	durationFormatter,
	useWindowSize,
} from '../../../utils/helpers/helper';
import UserContext from '../../../app/contexts/UserContext';
import Button from '../../reusables/element/Button';
import Input from '../../reusables/field/Input';
import DialogBox from '../../reusables/element/DialogBox';
import OpeningCashModal from '../../reusables/modals/OpeningCashModal';
import registerWorker from './registerWorker';
import { mediaBreakpoint, TERMINAL_TOKEN_STORAGE_KEY } from '../../../utils/constants/constants';
import RotateContent from '../../reusables/design/RotateContent';
import useWebWorker from '../../../utils/hooks/useWebWorker';
import TerminalActivation from './TerminalActivation';
import background from '../../../assets/img/login.jpg';
import logo from '../../../assets/img/logo.svg';
import logoLoading from '../../../assets/img/logoLoading.svg';
import Alert from '../../reusables/element/Alert';

const TerminalUnlockNumPadButton = ({
	text,
	onClick,
	design,
	disabled,
	pill,
	block,
	bold,
	outline,
	label,
	className,
}) => {
	return (
		<Button
			design={design}
			text={text}
			onClick={onClick}
			disabled={disabled}
			pill={pill}
			block={block}
			bold={bold}
			outline={outline}
			label={label}
			className={classNames('sdms-font-lg', className)}
		/>
	);
};
TerminalUnlockNumPadButton.propTypes = {
	text: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	design: PropTypes.string,
	disabled: PropTypes.bool,
	pill: PropTypes.bool,
	block: PropTypes.bool,
	bold: PropTypes.oneOf(['bold', 'bolder', 'boldest']),
	outline: PropTypes.bool,
	label: PropTypes.oneOf(['danger', 'success']),
	className: PropTypes.string,
};
TerminalUnlockNumPadButton.defaultProps = {
	design: 'info',
	disabled: false,
	pill: true,
	block: true,
	bold: 'bold',
	outline: true,
	label: null,
	className: null,
};

const modals = {
	REGISTER_OPEN: 'register_open',
	CLOCKIN: 'clock-in',
	MESSAGE: 'message',
};

const TerminalUnLock = () => {
	const windowSize = useWindowSize();

	const [pin, setPin] = useState('');

	const [isLoading, setIsLoading] = useState(true);

	const [isTerminalOpen, setIsTerminalOpen] = useState(true);

	const registerId = useRef(window.location.pathname.split('/').pop());

	const userContext = useContext(UserContext);

	const [modal, openModal, closeModal] = useModal();

	const [name, setName] = useState('');

	const [registerWorkerStart, , registerWorkerTerminate] = useWebWorker(registerWorker, 0, {
		registerId: registerId.current,
	});

	const [terminalToken, setTerminalToken] = useState(
		localStorage.getItem(TERMINAL_TOKEN_STORAGE_KEY)
	);

	const [advancedSecurity, setAdvancedSecurity] = useState(false);

	const [inactive, setInactive] = useState(false);

	const getKeyPress = event => {
		// blur all focused element to prevent enter conflict.
		if ('activeElement' in document) document.activeElement.blur();
		switch (event.keyCode) {
			// enter input
			case 13:
				login();
				break;
			// backspacing
			case 8:
				setPin(pin.length ? pin.slice(0, pin.length - 1) : pin);
				break;

			/* Numeric Inputs */
			case 48:
			case 96:
				setPin(`${pin}0`);
				break;
			case 49:
			case 97:
				setPin(`${pin}1`);
				break;
			case 50:
			case 98:
				setPin(`${pin}2`);
				break;
			case 51:
			case 99:
				setPin(`${pin}3`);
				break;
			case 52:
			case 100:
				setPin(`${pin}4`);
				break;
			case 53:
			case 101:
				setPin(`${pin}5`);
				break;
			case 54:
			case 102:
				setPin(`${pin}6`);
				break;
			case 55:
			case 103:
				setPin(`${pin}7`);
				break;
			case 56:
			case 104:
				setPin(`${pin}8`);
				break;
			case 57:
			case 105:
				setPin(`${pin}9`);
				break;
			default:
				break;
		}
	};

	const login = (withClockIn = null) => {
		if (pin === '') return;

		const formData = { pin, register: registerId.current, terminalToken };

		if (withClockIn !== null) formData.withClockIn = withClockIn;

		closeModal();

		apiCall(
			'POST',
			'terminalUnlock',
			res => {
				if (res.closing) {
					addErrorNotification('Cannot login when terminal is closing');
					setPin('');
				} else if (res.showOpenTerminal)
					openModal({ open: modals.REGISTER_OPEN, register: res.register });
				else userContext.login(res);
			},
			err => {
				if (err.toString() === 'Error: clockIn') openModal({ open: modals.CLOCKIN });
				else {
					addErrorNotification(err.toString());
					setPin('');
				}
			},
			'',
			formData
		);
	};

	const clockIn = () => {
		if (pin === '') return;

		apiCall(
			'POST',
			'clockIn',
			res => {
				setPin('');
				addSuccessNotification(res.message);
			},
			err => {
				setPin('');
				addErrorNotification(err.toString());
			},
			'',
			{ pin, registerId: registerId.current }
		);
	};

	const clockOut = () => {
		if (pin === '') return;

		apiCall(
			'POST',
			'clockOut',
			res => {
				setPin('');
				openModal({
					open: modals.MESSAGE,
					message: (
						<>
							<p>{res.name} clocked out</p>
							<p>Shift time: {durationFormatter(res.shiftTime)}</p>
						</>
					),
				});
			},
			err => {
				setPin('');
				addErrorNotification(err.toString());
			},
			'',
			{ pin, registerId: registerId.current }
		);
	};

	const openRegisterModalOnClose = () => {
		document.addEventListener('keydown', getKeyPress);
		closeModal();
		setPin('');
	};

	const openRegister = openingCashes => {
		if (pin === '') return;

		apiCall(
			'POST',
			'terminalOpen',
			res => {
				userContext.login(res);
			},
			err => {
				addErrorNotification(err.toString());
				openRegisterModalOnClose();
			},
			'',
			{ pin, registerId: registerId.current, openingCashes }
		);
	};

	useEffect(() => {
		if (modal.open === modals.REGISTER_OPEN)
			document.removeEventListener('keydown', getKeyPress);
		else document.addEventListener('keydown', getKeyPress);

		return () => {
			document.removeEventListener('keydown', getKeyPress);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pin, modal, terminalToken]);

	useEffect(() => {
		// Check register is open.
		apiCall(
			'POST',
			'registerCheck',
			res => {
				setIsLoading(false);
				setIsTerminalOpen(res.isOpen);
				setName(`${res.outletName} - ${res.registerName}`);
				setAdvancedSecurity(res.advancedSecurity);
				setInactive(res.inactive);

				if (res.checkInterval) {
					registerWorkerStart(e => setIsTerminalOpen(e.data.isOpen), res.checkInterval);
				}
			},
			err => {
				setIsLoading(false);
				addErrorNotification(err.toString());
			},
			'',
			{ pin: '', registerId: registerId.current }
		);

		return () => {
			registerWorkerTerminate();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoading) return <ReactSVG src={logoLoading} className='svg-container logo-loading' />;

	if (inactive)
		return (
			<DocumentTitle title='Log In | Sharper MMS'>
				<div className='row'>
					<div className='col-12 sdms-mb-20'>
						<Alert solid icon='Info-circle' design='danger' bold>
							Terminal is not active.
						</Alert>
					</div>
				</div>
			</DocumentTitle>
		);

	if (advancedSecurity && !terminalToken)
		return (
			<DocumentTitle title='Log In | Sharper MMS'>
				<TerminalActivation
					register={{ id: registerId.current, name }}
					onSuccess={token => setTerminalToken(token)}
				/>
			</DocumentTitle>
		);

	return (
		<DocumentTitle title='Log In | Sharper MMS'>
			{windowSize.width < mediaBreakpoint.MD &&
			windowSize.width > windowSize.height &&
			windowSize.landscape ? (
				<RotateContent />
			) : (
				<div className='sdms-grid sdms-grid--ver sdms-grid--root'>
					<div className='sdms-grid sdms-grid--hor sdms-grid--root sdms-login sdms-login--signin'>
						<div
							className='sdms-grid__item sdms-grid__item--fluid sdms-grid sdms-login--background d-flex align-items-center justify-content-center'
							style={{
								backgroundImage: `url(${background})`,
							}}>
							<div className='sdms-login__right sdms-login__terminal'>
								<div className='sdms-login__wrapper'>
									<div className='sdms-login__content'>
										<a className='sdms-login__logo' href='/'>
											<ReactSVG src={logo} className='svg-container' />
										</a>
										<div className='row'>
											<h2 className='sdms-login__title'>{name}</h2>
										</div>
										{!isTerminalOpen && (
											<div className='row'>
												<h3 className='sdms-login__title'>
													Terminal is not open yet!
												</h3>
											</div>
										)}

										<>
											<div className='row'>
												<div className='col-12'>
													<div className='sdms-login__form'>
														<form className='sdms-form row'>
															<div className='col-8'>
																<Input
																	className={classNames({
																		'form-control--empty':
																			pin === '',
																	})}
																	type='password'
																	placeholder='PIN Code'
																	name='pass'
																	value={pin}
																	autoComplete={false}
																	togglePassword
																	onChange={({ target }) =>
																		setPin(target.value)
																	}
																/>
															</div>
															<div className='col-4'>
																<Button
																	design='clean'
																	text='Clear'
																	onClick={() => setPin('')}
																/>
															</div>
														</form>
													</div>
												</div>
											</div>
											<div className='row sdms-mb-15'>
												<div className='col-4'>
													<TerminalUnlockNumPadButton
														onClick={() => setPin(`${pin}1`)}
														text='1'
													/>
												</div>
												<div className='col-4'>
													<TerminalUnlockNumPadButton
														onClick={() => setPin(`${pin}2`)}
														text='2'
													/>
												</div>
												<div className='col-4'>
													<TerminalUnlockNumPadButton
														onClick={() => setPin(`${pin}3`)}
														text='3'
													/>
												</div>
											</div>
											<div className='row sdms-mb-15'>
												<div className='col-4'>
													<TerminalUnlockNumPadButton
														onClick={() => setPin(`${pin}4`)}
														text='4'
													/>
												</div>
												<div className='col-4'>
													<TerminalUnlockNumPadButton
														onClick={() => setPin(`${pin}5`)}
														text='5'
													/>
												</div>
												<div className='col-4'>
													<TerminalUnlockNumPadButton
														onClick={() => setPin(`${pin}6`)}
														text='6'
													/>
												</div>
											</div>
											<div className='row sdms-mb-15'>
												<div className='col-4'>
													<TerminalUnlockNumPadButton
														onClick={() => setPin(`${pin}7`)}
														text='7'
													/>
												</div>
												<div className='col-4'>
													<TerminalUnlockNumPadButton
														onClick={() => setPin(`${pin}8`)}
														text='8'
													/>
												</div>
												<div className='col-4'>
													<TerminalUnlockNumPadButton
														onClick={() => setPin(`${pin}9`)}
														text='9'
													/>
												</div>
											</div>
											<div className='row sdms-mb-45'>
												<div className='col-4'>
													<Button
														onClick={clockIn}
														text='Clock In'
														label='info'
														bold='bold'
														className='sdms-p5'
														disabled={pin === ''}
														block
													/>
												</div>
												<div className='col-4'>
													<TerminalUnlockNumPadButton
														onClick={() => setPin(`${pin}0`)}
														text='0'
													/>
												</div>
												<div className='col-4'>
													<Button
														onClick={clockOut}
														text='Clock Out'
														label='info'
														bold='bold'
														className='sdms-p5'
														disabled={pin === ''}
														block
													/>
												</div>
											</div>
											<div className='row'>
												<div className='col-12'>
													<Button
														onClick={() => login()}
														text='Log In'
														label='success'
														icon='Sign-in'
														iconSize={36}
														bold='bold'
														disabled={pin === ''}
														pill={false}
														className='sdms-p5 sdms-font-lg'
														block
													/>
												</div>
											</div>
										</>
									</div>
								</div>
							</div>
						</div>
					</div>
					<OpeningCashModal
						isOpen={modal.open === modals.REGISTER_OPEN}
						onClose={openRegisterModalOnClose}
						onSubmit={openRegister}
						registers={modal.register ? [modal.register] : []}
					/>
					<DialogBox
						open={modal.open === modals.CLOCKIN}
						title=''
						content='You have not clocked in, would you like to clock in?'
						type='question'
						onClose={closeModal}>
						<Button
							className='sdms-font-transform-c'
							design='clean'
							icon='Error-circle'
							text={`No, Don't clock in!`}
							onClick={() => login(false)}
						/>
						<Button
							className='sdms-font-transform-c'
							label='success'
							icon='Sign-in'
							text='Yes, clock in'
							onClick={() => login(true)}
						/>
					</DialogBox>
					<DialogBox
						open={modal.open === modals.MESSAGE}
						title=''
						content={modal.message || ''}
						type='success'
						onClose={closeModal}>
						<Button label='success' text='Ok' onClick={closeModal} />
					</DialogBox>
				</div>
			)}
		</DocumentTitle>
	);
};

export default TerminalUnLock;
