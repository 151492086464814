import React, { useEffect, useMemo, useState } from 'react';
import Popup from 'reactjs-popup';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useNumPad, { actions as numPadActions } from '../../../utils/hooks/useNumPad';

import Button from '../element/Button';
import Portlet from '../layout/Portlet';
import Portal from '../layout/Portal';
import ModalInput from './ModalInput';
import QuickPanel from '../../QuickPanel';
import { mediaBreakpoint } from '../../../utils/constants/constants';
import { useWindowSize } from '../../../utils/helpers/helper';
import List from '../template/List';
import ListContainer from '../template/ListContainer';
import {
	InvoiceBalanceCell,
	InvoiceDateCell,
	InvoiceIdLinkCell,
	InvoicePaidCell,
	InvoiceStatusCell,
	InvoiceTotalCell,
} from '../element/InvoiceListCells';

const _icon = 'Weight#2';

const QuantityModal = ({
	modalTitle,
	initValue,
	defaultValue,
	setDashValue,
	decimalLimit,
	lowerLimit,
	upperLimit,
	onClose,
	isOpen,
	submitButtonText,
	content,
	isLoading,
	pickInvoices,
	invoiceFilters,
}) => {
	const windowSize = useWindowSize();

	const [showInvoiceSelection, setShowInvoiceSelection] = useState(false);

	const [retainAmount, setRetainAmount] = useState(initValue);

	const navigateToInvoiceSelection = value => {
		setRetainAmount(value);

		setShowInvoiceSelection(true);
	};

	useEffect(() => {
		if (!isOpen) setShowInvoiceSelection(false);
	}, [isOpen]);

	const _Content = showInvoiceSelection ? (
		<InvoiceSelectionModal
			modalTitle={modalTitle}
			onSubmit={setDashValue}
			onClose={() => {
				setShowInvoiceSelection(false);

				onClose();
			}}
			isLoading={isLoading}
			withOutPortlet={windowSize.width < mediaBreakpoint.MD}
			invoiceFilters={invoiceFilters}
			goBack={() => setShowInvoiceSelection(false)}
			amount={retainAmount}
		/>
	) : (
		<QuantityModalContent
			modalTitle={modalTitle}
			initValue={initValue}
			defaultValue={defaultValue}
			setDashValue={setDashValue}
			onClose={onClose}
			decimalLimit={decimalLimit}
			lowerLimit={lowerLimit}
			upperLimit={upperLimit}
			submitButtonText={submitButtonText}
			content={content}
			pickInvoices={pickInvoices}
			navigateToInvoiceSelection={navigateToInvoiceSelection}
			isLoading={isLoading}
			withOutPortlet={windowSize.width < mediaBreakpoint.MD}
		/>
	);

	if (windowSize.width < mediaBreakpoint.MD) {
		return (
			<QuickPanel status={isOpen} setStatus={onClose} title={modalTitle} icon={_icon}>
				{_Content}
			</QuickPanel>
		);
	}
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					border: 'unset',
					background: 'unset',
					maxWidth: showInvoiceSelection ? '968px' : '430px',
					height: showInvoiceSelection ? '600px' : 'auto',
				}}>
				{_Content}
			</Popup>
		</Portal>
	);
};
QuantityModal.propTypes = {
	modalTitle: PropTypes.string,
	initValue: PropTypes.number,
	defaultValue: PropTypes.number,
	setDashValue: PropTypes.func,
	decimalLimit: PropTypes.number,
	lowerLimit: PropTypes.number,
	upperLimit: PropTypes.number,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	submitButtonText: PropTypes.string,
	content: PropTypes.node,
	isLoading: PropTypes.bool,
	pickInvoices: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	invoiceFilters: PropTypes.object,
};
QuantityModal.defaultProps = {
	modalTitle: 'Quantity',
	defaultValue: 1,
	initValue: 1,
	setDashValue: () => '',
	decimalLimit: 5,
	lowerLimit: 0,
	upperLimit: 1000000,
	isOpen: false,
	onClose: () => {},
	submitButtonText: 'Update',
	content: null,
	isLoading: false,
	pickInvoices: false,
	invoiceFilters: {},
};

const QuantityModalContent = ({
	modalTitle,
	initValue,
	defaultValue,
	setDashValue,
	onClose,
	decimalLimit,
	lowerLimit,
	upperLimit,
	submitButtonText,
	content,
	withOutPortlet,
	isLoading,
	pickInvoices,
	navigateToInvoiceSelection,
}) => {
	const [value, setValue] = useState(initValue ? initValue.toString() : defaultValue.toString());

	const [applyInput] = useNumPad(
		value,
		setValue,
		initValue,
		upperLimit,
		lowerLimit,
		decimalLimit,
		() => {
			setDashValue(value);
		}
	);

	const handleSubmit = () => {
		if (pickInvoices) navigateToInvoiceSelection(value);
		else setDashValue(value);
	};

	const _Inner = (
		<>
			<Portlet.Body>
				{content && (
					<div className='row sdms-mb-15'>
						<div className='col-12'>{content}</div>
					</div>
				)}
				<div className='row sdms-mb-15'>
					<div className='col-2'>
						<Button
							className='sdms-font-lg h-100'
							label='info'
							text='-'
							bold='bold'
							outline
							block
							onClick={() => {
								applyInput(numPadActions.DECREMENT);
							}}
						/>
					</div>
					<div className='col-8'>
						<div
							className={classNames('sdms-input-icon', {
								'sdms-input-icon--right': value !== '0',
							})}>
							<ModalInput
								value={value}
								onCancel={() => {
									setValue('0');
								}}
							/>
						</div>
					</div>
					<div className='col-2'>
						<Button
							className='sdms-font-lg h-100'
							label='info'
							bold='bold'
							outline
							block
							text='+'
							onClick={() => {
								applyInput(numPadActions.INCREMENT);
							}}
						/>
					</div>
				</div>
				<div className='row sdms-mb-10'>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='1'
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '1');
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='2'
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '2');
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='3'
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '3');
							}}
						/>
					</div>
				</div>
				<div className='row sdms-mb-10'>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='4'
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '4');
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='5'
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '5');
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='6'
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '6');
							}}
						/>
					</div>
				</div>
				<div className='row sdms-mb-10'>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='7'
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '7');
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='8'
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '8');
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='9'
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '9');
							}}
						/>
					</div>
				</div>
				<div className='row'>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='.'
							onClick={() => {
								applyInput(numPadActions.DECIMAL);
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='sdms-font-lg'
							design='info'
							bold='bold'
							outline
							block
							text='0'
							onClick={() => {
								applyInput(numPadActions.CONCATENATE, '0');
							}}
						/>
					</div>
					<div className='col-4'>
						<Button
							className='btn-only-icon sdms-pt0 sdms-pb-0 h-100'
							label='danger'
							block
							icon='Backspace'
							iconSize={38}
							onClick={() => {
								applyInput(numPadActions.SLICE);
							}}
						/>
					</div>
				</div>
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						icon='Error-circle'
						size='sm'
						elevate
						text='Close'
						onClick={onClose}
						disabled={isLoading}
					/>
				</div>
				<div className='col-auto'>
					<Button
						design='brand'
						size='sm'
						elevate
						icon='Weight#2'
						text={submitButtonText}
						onClick={() => handleSubmit(value)}
						disabled={isLoading}
					/>
				</div>
			</Portlet.Foot>
		</>
	);

	if (withOutPortlet) {
		return _Inner;
	}
	return (
		<div id='modalPopUp' className='sdms-portlet' tabIndex='-1'>
			<Portlet.Head>
				<Portlet.HeadLabel portletIcon={_icon}>
					<h3 className='sdms-portlet__head-title'>{modalTitle}</h3>
				</Portlet.HeadLabel>
			</Portlet.Head>
			{_Inner}
		</div>
	);
};
QuantityModalContent.propTypes = {
	modalTitle: PropTypes.string,
	initValue: PropTypes.number,
	defaultValue: PropTypes.number,
	setDashValue: PropTypes.func,
	onClose: PropTypes.func,
	decimalLimit: PropTypes.number,
	lowerLimit: PropTypes.number,
	upperLimit: PropTypes.number,
	submitButtonText: PropTypes.string,
	content: PropTypes.node,
	withOutPortlet: PropTypes.bool,
	isLoading: PropTypes.bool,
	pickInvoices: PropTypes.bool,
	navigateToInvoiceSelection: PropTypes.func,
};
QuantityModalContent.defaultProps = {
	modalTitle: 'Quantity',
	defaultValue: 1,
	initValue: 1,
	setDashValue: () => '',
	onClose: () => '',
	decimalLimit: 5,
	lowerLimit: 0,
	upperLimit: 1000000,
	submitButtonText: 'Update',
	content: null,
	withOutPortlet: false,
	isLoading: false,
	pickInvoices: false,
	navigateToInvoiceSelection: () => {},
};

export default QuantityModal;

const InvoiceSelectionModal = ({
	modalTitle,
	onSubmit,
	onClose,
	isLoading,
	withOutPortlet,
	invoiceFilters,
	goBack,
	amount,
}) => {
	const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);

	const _List = useMemo(
		() => (
			<ListContainer
				title=''
				route='reservationInvoices'
				staticFilters={invoiceFilters}
				fluid='fluid'
				hasPagination={false}
				showSubheader={false}
				noPaddingContainer
				onSelectedItemsChange={setSelectedInvoiceIds}>
				<List fluid='fluid' withCheckBox>
					<List.Col
						label='Invoice'
						cellData='invoiceId'
						cellComponent={<InvoiceIdLinkCell />}
					/>
					<List.Col label='Date' cellComponent={<InvoiceDateCell />} />
					<List.Col label='Total' cellComponent={<InvoiceTotalCell />} />
					<List.Col label='Paid' cellComponent={<InvoicePaidCell />} />
					<List.Col label='Balance' cellComponent={<InvoiceBalanceCell />} />
					<List.Col label='Status' cellComponent={<InvoiceStatusCell />} />
				</List>
			</ListContainer>
		),
		[invoiceFilters]
	);

	const _Inner = (
		<>
			<Portlet.Body className='sdms-portlet__body--fit'>{_List}</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						icon='Error-circle'
						size='sm'
						elevate
						text='Close'
						onClick={onClose}
						disabled={isLoading}
					/>
				</div>
				<div className='col-auto'>
					<Button
						design='brand'
						size='sm'
						elevate
						icon='Weight#2'
						text='Apply'
						onClick={() => {
							onSubmit(amount, selectedInvoiceIds);
						}}
						disabled={isLoading || selectedInvoiceIds.length === 0}
					/>
				</div>
			</Portlet.Foot>
		</>
	);

	if (withOutPortlet) return _Inner;

	return (
		<Portlet fluid='fluid' isLast everyTimeFluid>
			<Portlet.Head>
				<Portlet.HeadLabel portletIcon={_icon}>
					<h3 className='sdms-portlet__head-title'>{modalTitle}</h3>
				</Portlet.HeadLabel>
				<Portlet.HeadToolbarActions className='sdms-last-margin'>
					<Button
						design='brand'
						size='sm'
						elevate
						text='Back'
						onClick={goBack}
						disabled={isLoading}
					/>
				</Portlet.HeadToolbarActions>
			</Portlet.Head>
			{_Inner}
		</Portlet>
	);
};

InvoiceSelectionModal.propTypes = {
	modalTitle: PropTypes.string,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
	isLoading: PropTypes.bool,
	withOutPortlet: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	invoiceFilters: PropTypes.object,
	goBack: PropTypes.func,
	amount: PropTypes.number,
};

InvoiceSelectionModal.defaultProps = {
	modalTitle: 'Apply To Invoice',
	onSubmit: () => {},
	onClose: () => {},
	isLoading: false,
	withOutPortlet: false,
	invoiceFilters: {},
	goBack: () => {},
	amount: 0,
};
