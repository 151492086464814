import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import useField from '../../../utils/hooks/useField';
import { numberParser } from '../../../utils/helpers/helper';
import { required, max, numeric, url } from '../../../utils/helpers/validation';
import { reservationListDefaultFilters, templateTypes } from '../../../utils/constants/constants';

import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Portlet from '../../reusables/layout/Portlet';
import Selects from '../../reusables/field/Selects';
import Loading from '../../reusables/template/Loading';
import Input from '../../reusables/field/Input';
import Section from '../../reusables/layout/Section';
import Button from '../../reusables/element/Button';
import Toggle from '../../reusables/field/Toggle';
import Wysiwyg from '../../reusables/field/Wysiwyg';
import UserContext from '../../../app/contexts/UserContext';

const ReservationSettingsForm = ({
	data,
	templates,
	setIsValid,
	isLoading,
	isSubmitted,
	onFormChange,
	submit,
	submitButtonAttr,
}) => {
	const userContext = useContext(UserContext);

	const [
		bookingHourlyStep,
		bookingHourlyStepOnChange,
		bookingHourlyStepValRes,
		bookingHourlyStepShowVal,
		setBookingHourlyStepShowVal,
	] = useField(
		data,
		'bookingHourlyStep',
		onFormChange,
		[numeric, max(60)],
		'',
		numberParser(false)
	);

	const [
		onlineSiteUrl,
		onlineSiteUrlOnChange,
		onlineSiteUrlValRes,
		onlineSiteUrlShowVal,
		setOnlineSiteUrlShowVal,
	] = useField(data, 'onlineSiteUrl', onFormChange, [url]);

	const [onlineDefaultHouseAccount, onlineDefaultHouseAccountOnChange] = useField(
		data,
		'onlineDefaultHouseAccount',
		onFormChange,
		[],
		false
	);

	const [onlineDefaultSaveCreditCard, onlineDefaultSaveCreditCardOnChange] = useField(
		data,
		'onlineDefaultSaveCreditCard',
		onFormChange,
		[],
		false
	);

	const [
		reservationHoldPeriod,
		reservationHoldPeriodOnChange,
		reservationHoldPeriodValRes,
		reservationHoldPeriodShowVal,
		setReservationHoldPeriodShowVal,
	] = useField(
		data,
		'reservationHoldPeriod',
		onFormChange,
		[required, numeric],
		'',
		numberParser()
	);

	const [
		resetPasswordEmailTemplate,
		resetPasswordEmailTemplateOnChange,
		resetPasswordEmailTemplateValRes,
		resetPasswordEmailTemplateShowVal,
		setResetPasswordEmailTemplateShowVal,
	] = useField(data, 'resetPasswordEmailTemplate', onFormChange, [required], null);

	const [
		maxMultipleUnitsOnline,
		maxMultipleUnitsOnlineOnChange,
		maxMultipleUnitsOnlineValRes,
		maxMultipleUnitsOnlineShowVal,
		setMaxMultipleUnitsOnlineShowVal,
	] = useField(data, 'maxMultipleUnitsOnline', onFormChange, [numeric], '', numberParser());

	const [
		showUnitsLeftBelow,
		showUnitsLeftBelowOnChange,
		showUnitsLefBlowValRes,
		showUnitsLeftBelowShowVal,
		setShowUnitLeftBlowShowVal,
	] = useField(data, 'showUnitsLeftBelow', onFormChange, [numeric], '', numberParser());

	const [checkoutTotalText, checkoutTotalTextOnChange] = useField(
		data,
		'checkoutTotalText',
		onFormChange,
		[],
		null
	);

	const [enableBookingConfirmationEmail, enableBookingConfirmationEmailOnChange] = useField(
		data,
		'enableBookingConfirmationEmail',
		onFormChange,
		[],
		true
	);

	const [autoDepositSalesReceipt, autoDepositSalesReceiptOnChange] = useField(
		data,
		'autoDepositSalesReceipt',
		onFormChange,
		[],
		true
	);

	const [preventEditPrevInvoiceDate, preventEditPrevInvoiceDateOnChange] = useField(
		data,
		'preventEditPrevInvoiceDate',
		onFormChange,
		[],
		true
	);

	const [generateInvoiceOnSave, generateInvoiceOnSaveOnChange] = useField(
		data,
		'generateInvoiceOnSave',
		onFormChange,
		[],
		false
	);

	const [showMapAvailability, showMapAvailabilityOnChange] = useField(
		data,
		'showMapAvailability',
		onFormChange,
		[],
		false
	);

	const [simpleOnlineConfirmation, simpleOnlineConfirmationOnChange] = useField(
		data,
		'simpleOnlineConfirmation',
		onFormChange,
		[],
		false
	);

	const [phoneNumberRequired, phoneNumberRequiredOnChange] = useField(
		data,
		'phoneNumberRequired',
		onFormChange,
		[],
		false
	);

	const [termsAndConditions, termsAndConditionsOnChange] = useField(
		data,
		'termsAndConditions',
		onFormChange
	);

	const [reservationListDefaultFilter, reservationListDefaultFilterOnChange] = useField(
		data,
		'reservationListDefaultFilter',
		onFormChange,
		[],
		'all'
	);

	const [speedyDockAccessKey, speedyDockAccessKeyOnChange] = useField(
		data,
		'speedyDockAccessKey',
		onFormChange,
		[],
		null
	);

	const [
		authorizationExpirationDays,
		authorizationExpirationDaysOnChange,
		authorizationExpirationDaysValRes,
		authorizationExpirationDaysShowVal,
		setAuthorizationExpirationDaysShowVal,
	] = useField(
		data,
		'authorizationExpirationDays',
		onFormChange,
		[numeric, max(180)],
		'',
		numberParser(false)
	);

	const [enablePrePaymentForNonTransient, enablePrePaymentForNonTransientOnChange] = useField(
		data,
		'enablePrePaymentForNonTransient',
		onFormChange,
		[],
		false
	);

	useEffect(() => {
		if (isSubmitted) {
			setBookingHourlyStepShowVal();
			setOnlineSiteUrlShowVal();
			setReservationHoldPeriodShowVal();
			setResetPasswordEmailTemplateShowVal();
			setMaxMultipleUnitsOnlineShowVal();
			setShowUnitLeftBlowShowVal();
			setAuthorizationExpirationDaysShowVal();
		}
	}, [
		isSubmitted,
		setBookingHourlyStepShowVal,
		setOnlineSiteUrlShowVal,
		setReservationHoldPeriodShowVal,
		setResetPasswordEmailTemplateShowVal,
		setMaxMultipleUnitsOnlineShowVal,
		setShowUnitLeftBlowShowVal,
		setAuthorizationExpirationDaysShowVal,
	]);

	useEffect(() => {
		setIsValid(
			bookingHourlyStepValRes.isValid &&
				onlineSiteUrlValRes.isValid &&
				reservationHoldPeriodValRes.isValid &&
				resetPasswordEmailTemplateValRes.isValid &&
				maxMultipleUnitsOnlineValRes.isValid &&
				showUnitsLefBlowValRes.isValid &&
				authorizationExpirationDaysValRes.isValid
		);
	}, [
		setIsValid,
		bookingHourlyStepValRes.isValid,
		onlineSiteUrlValRes.isValid,
		reservationHoldPeriodValRes.isValid,
		resetPasswordEmailTemplateValRes.isValid,
		maxMultipleUnitsOnlineValRes.isValid,
		showUnitsLefBlowValRes.isValid,
		authorizationExpirationDaysValRes.isValid,
	]);

	return (
		<Portlet className='sdms-form' fluid='fluid' hasFrame>
			<Portlet.Body>
				<Section title='General Settings'>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='resetPasswordEmailTemplate'
									label='Reset Password Email Template'
									id={data.id}
									colMd={4}
									showValidation={resetPasswordEmailTemplateShowVal}
									valRes={resetPasswordEmailTemplateValRes}>
									<Selects
										options={templates.filter(
											t => t.type.value === templateTypes.RESET_PASSWORD
										)}
										placeholder='Select template (Required)'
										value={resetPasswordEmailTemplate}
										onChange={resetPasswordEmailTemplateOnChange}
										onBlur={setResetPasswordEmailTemplateShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='bookingHourlyStep'
									label='Booking Hourly Step'
									description='Step size for hourly booking intervals'
									id={data.id}
									colMd={4}
									showValidation={bookingHourlyStepShowVal}
									valRes={bookingHourlyStepValRes}>
									<Input
										type='text'
										placeholder='Booking Hourly Step'
										value={bookingHourlyStep}
										onChange={bookingHourlyStepOnChange}
										onBlur={setBookingHourlyStepShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='onlineSiteUrl'
									label='Online Site Url'
									id={data.id}
									valRes={onlineSiteUrlValRes}
									showValidation={onlineSiteUrlShowVal}
									colMd={4}
									noPermission={!userContext.hasPermission(null, true)}>
									<Input
										type='text'
										placeholder='Online Site Url'
										value={onlineSiteUrl}
										onChange={onlineSiteUrlOnChange}
										onBlur={setOnlineSiteUrlShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='reservationHoldPeriod'
									label='Reservation Hold Period'
									id={data.id}
									colMd={4}
									showValidation={reservationHoldPeriodShowVal}
									valRes={reservationHoldPeriodValRes}>
									<Input
										type='text'
										placeholder='Reservation Hold Period (Required)'
										value={reservationHoldPeriod}
										onChange={reservationHoldPeriodOnChange}
										onBlur={setReservationHoldPeriodShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='maxMultipleUnitsOnline'
									label='Max Multiple Units Online'
									id={data.id}
									colMd={4}
									showValidation={maxMultipleUnitsOnlineShowVal}
									valRes={maxMultipleUnitsOnlineValRes}>
									<Input
										type='number'
										placeholder='Max Multiple Units Online'
										value={maxMultipleUnitsOnline}
										onChange={maxMultipleUnitsOnlineOnChange}
										onBlur={setMaxMultipleUnitsOnlineShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='showUnitsLeftBelow'
									label='Show Units Left Below'
									id={data.id}
									colMd={4}
									showValidation={showUnitsLeftBelowShowVal}
									valRes={showUnitsLefBlowValRes}>
									<Input
										type='number'
										placeholder='Show Units Left Below'
										value={showUnitsLeftBelow}
										onChange={showUnitsLeftBelowOnChange}
										onBlur={setShowUnitLeftBlowShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='checkoutTotalText'
									label='Checkout Total Text'
									id={data.id}
									colMd={4}>
									<Input
										type='text'
										placeholder='Checkout Text'
										value={checkoutTotalText}
										onChange={checkoutTotalTextOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='reservationListDefaultFilter'
									label='Reservation List Default Filter'
									id={data.id}
									colMd={4}>
									<Selects
										options={reservationListDefaultFilters}
										placeholder='Select a filter'
										value={
											reservationListDefaultFilter
												? reservationListDefaultFilters.find(
														rldf =>
															rldf.value ===
															reservationListDefaultFilter
												  )
												: null
										}
										onChange={e =>
											reservationListDefaultFilterOnChange({
												target: {
													value: e.target.value
														? e.target.value.value
														: null,
												},
											})
										}
										displayKey='display'
										valueKey='value'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='authorizationExpirationDays'
									label='Authorization Expiration'
									description='Days until payment authorization expires'
									id={data.id}
									colMd={4}
									showValidation={authorizationExpirationDaysShowVal}
									valRes={authorizationExpirationDaysValRes}>
									<Input
										type='text'
										placeholder='Authorization Expiration'
										append='Day(s)'
										value={authorizationExpirationDays}
										onChange={authorizationExpirationDaysOnChange}
										onBlur={setAuthorizationExpirationDaysShowVal}
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='speedyDockAccessKey'
									label='Speedy Dock Access Key'
									id={data.id}
									colMd={4}
									noPermission={!userContext.hasPermission(null, true)}>
									<Input
										type='text'
										placeholder='Speedy Dock Access Key'
										value={speedyDockAccessKey}
										onChange={speedyDockAccessKeyOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='onlineDefaultHouseAccount'
									label='Online Default House Account'
									id={data.id}
									colMd={4}>
									<Toggle
										spaceLess
										value={onlineDefaultHouseAccount}
										onChange={onlineDefaultHouseAccountOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='onlineDefaultSaveCreditCard'
									label='Online Default Save Credit Card'
									id={data.id}
									colMd={4}>
									<Toggle
										spaceLess
										value={onlineDefaultSaveCreditCard}
										onChange={onlineDefaultSaveCreditCardOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableBookingConfirmationEmail'
									label='Confirmation Email'
									id={data.id}
									colMd={4}>
									<Toggle
										spaceLess
										value={enableBookingConfirmationEmail}
										onChange={enableBookingConfirmationEmailOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='autoDepositSalesReceipt'
									label='Auto Deposit Sales Receipt'
									id={data.id}
									colMd={4}>
									<Toggle
										spaceLess
										value={autoDepositSalesReceipt}
										onChange={autoDepositSalesReceiptOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='preventEditPrevInvoiceDate'
									label='Prevent Edit Previous Invoice'
									id={data.id}
									colMd={4}>
									<Toggle
										spaceLess
										value={preventEditPrevInvoiceDate}
										onChange={preventEditPrevInvoiceDateOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='generateInvoiceOnSave'
									label='Generate Invoices On Save'
									id={data.id}
									colMd={4}>
									<Toggle
										spaceLess
										value={generateInvoiceOnSave}
										onChange={generateInvoiceOnSaveOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='showMapAvailability'
									label='Show Map Availability'
									id={data.id}
									colMd={4}>
									<Toggle
										spaceLess
										value={showMapAvailability}
										onChange={showMapAvailabilityOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='simpleOnlineConfirmation'
									label='Simple Online Confirmation'
									id={data.id}
									colMd={4}>
									<Toggle
										spaceLess
										value={simpleOnlineConfirmation}
										onChange={simpleOnlineConfirmationOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='phoneNumberRequired'
									label='Phone number is required'
									id={data.id}
									colMd={4}>
									<Toggle
										spaceLess
										value={phoneNumberRequired}
										onChange={phoneNumberRequiredOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='enablePrePaymentForNonTransient'
									label='Enable Pre-Payment for Non Transients'
									id={data.id}
									col={4}>
									<Toggle
										spaceLess
										value={enablePrePaymentForNonTransient}
										onChange={enablePrePaymentForNonTransientOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='termsAndConditions'
									label='Terms & Conditions'
									id={data.id}
									col={12}>
									<Wysiwyg
										value={termsAndConditions}
										onChange={termsAndConditionsOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
ReservationSettingsForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		reservationConfirmationEmailTemplate: PropTypes.object,
		marinaReservationConfirmationEmailTemplate: PropTypes.object,
		campgroundReservationConfirmationEmailTemplate: PropTypes.object,
		defaultCustomer: PropTypes.object,
		bookingHourlyStep: PropTypes.number,
	}),
	templates: PropTypes.arrayOf(PropTypes.object),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	submit: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
};
ReservationSettingsForm.defaultProps = {
	data: {
		id: 0,
		reservationConfirmationEmailTemplate: {},
		defaultCustomer: {},
		bookingHourlyStep: 15,
	},
	templates: [],
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	onFormChange: () => {},
	submit: () => {},
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
};

export default ReservationSettingsForm;
