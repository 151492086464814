import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import ListContainer from '../../reusables/template/ListContainer';
import List from '../../reusables/template/List';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import forms from '../../forms';
import { modules } from '../../../utils/helpers/apiCall';
import { bookingPeriods } from '../../../utils/constants/constants';
import Badge from '../../reusables/element/Badge';
import SVGIcon from '../../reusables/element/SVGIcon';

export const PeriodCell = ({ data }) => {
	const pages = usePages();

	const getIcon = periodValue => {
		switch (periodValue) {
			// Hourly
			case bookingPeriods.HOURLY:
				return {
					icon: pages.campground.products.period.hourly.icon,
					color: pages.campground.products.period.hourly.color,
				};
			// Daily
			case bookingPeriods.DAILY:
				return {
					icon: pages.campground.products.period.daily.icon,
					color: pages.campground.products.period.daily.color,
				};
			// Nightly
			case bookingPeriods.NIGHTLY:
				return {
					icon: pages.campground.products.period.nightly.icon,
					color: pages.campground.products.period.nightly.color,
				};
			// Seasonal
			case bookingPeriods.SEASONAL:
				return {
					icon: pages.campground.products.period.seasonal.icon,
					color: pages.campground.products.period.seasonal.color,
				};
			// LongTerm
			case bookingPeriods.LONG_TERM:
				return {
					icon: pages.campground.products.period.longTerm.icon,
					color: pages.campground.products.period.longTerm.color,
				};
			default:
				return 'Layers';
		}
	};
	return (
		<Badge
			design={getIcon(data.bookingPeriod.value).color}
			isInline
			isUnified
			size='xl'
			className='sdms-font-transform-c'>
			<SVGIcon
				name={getIcon(data.bookingPeriod.value).icon}
				fill={`var(--${getIcon(data.bookingPeriod.value).color})`}
				className='sdms-mr-5'
			/>
			{data.bookingPeriod.value}
		</Badge>
	);
};
PeriodCell.propTypes = {
	data: PropTypes.shape({
		bookingPeriod: PropTypes.shape({ id: PropTypes.number, value: PropTypes.string }),
	}),
};
PeriodCell.defaultProps = {
	data: {
		bookingPeriod: {
			id: null,
			value: null,
		},
	},
};

const RatePlanList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
			{ title: pages.campground.settings.text, path: pages.campground.settings.path },
			{ title: pages.campground.settings.ratePlans.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.campground.settings.ratePlans.text);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);
	return (
		<ListContainer
			route='ratePlans'
			title={pages.campground.settings.ratePlans.text}
			history={history}
			icon={pages.campground.settings.ratePlans.icon}
			forms={forms.campground.settings.ratePlans}
			module={modules.CAMPGROUND}>
			<List withCheckBox fluid='fluid'>
				<List.Col
					label='Internal Name'
					cellData='internalName'
					isLinkColumn
					sortable='internalName'
				/>
				<List.Col
					label='External Name'
					cellData='externalName'
					isLinkColumn
					sortable='externalName'
				/>
				<List.Col
					label='Period'
					cellComponent={<PeriodCell />}
					sortable='bookingPeriod.value'
				/>
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

RatePlanList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default RatePlanList;
