import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import '../../../assets/onlinePortal.css';

import usePages from '../../../utils/hooks/usePages';
import { getCookie, priceFormatter, removeCookie, setCookie } from '../../../utils/helpers/helper';
import apiCall, { modules } from '../../../utils/helpers/apiCall';
import { paymentTypes } from '../../../utils/constants/constants';
import { getPaymentProcessor } from '../../../utils/hooks/usePaymentGateway';

import Portlet from '../../reusables/layout/Portlet';
import { NavItem } from '../../reusables/template/Wizard';
import Dashboard from './Dashboard';
import Invoices from './Invoices';
import Payments from './Payments';
import Spaces from './Spaces';
import Booking from './Booking';
import MyAccount from './MyAccount';
import { Login } from '../../reusables/modals/LoginModal';
import Dropdown from '../../reusables/element/Dropdown';
import Badge from '../../reusables/element/Badge';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import FormField from '../../reusables/template/FormField';
import CustomerPaymentMethodList from './CustomerPaymentMethodList';

import crm from '../../../assets/img/crm.svg';
import logoLoading from '../../../assets/img/logoLoading.svg';
import CreditMemos from './CreditMemos';

export const defaultOnlinePortalSteps = {
	DASHBOARD: {
		title: 'Dashboard',
		description: 'Account Overview',
		icon: 'Layers',
		id: 'dashboard',
		index: 0,
	},
	INVOICES: {
		title: 'Invoices',
		description: 'View & Pay Invoices',
		icon: 'Clipboard-list',
		id: 'invoices',
		index: 1,
	},
	PAYMENTS: {
		title: 'Payments',
		description: 'View Payments',
		icon: 'Wallet',
		id: 'payments',
		index: 2,
	},
	CREDIT_MEMOS: {
		title: 'Credit Memos',
		description: 'View Credit Memos',
		icon: 'Ticket',
		id: 'credit_memos',
		index: 3,
	},
	MARINA: {
		title: 'Marina',
		description: 'View Marina Spaces',
		icon: 'Marina',
		id: 'marina',
		index: 4,
	},
	CAMPGROUND: {
		title: 'Campground',
		description: 'View Campground Spaces',
		icon: 'Caravan',
		id: 'campground',
		index: 5,
	},
	BOOKING: {
		title: 'Booking',
		description: 'Manage Reservations',
		icon: 'Building',
		id: 'booking',
		index: 6,
	},
	MY_ACCOUNT: {
		title: 'My Account',
		description: 'Edit Profile',
		icon: 'User',
		id: 'my_account',
		index: 7,
	},
	SAVED_CUSTOMER_PAYMENT_METHODS: {
		title: 'Saved Payment Methods',
		description: 'Manage Saved Payment Methods',
		icon: 'Wallet#3',
		id: 'saved_payment_methods',
		index: 8,
	},
};

const OnlinePortal = ({ outletId, token, isOnline }) => {
	const [isLoading, setIsLoading] = useState(true);

	const [activeStep, setActiveStep] = useState({
		index: defaultOnlinePortalSteps.DASHBOARD.index,
	});

	const [customerId, setCustomerId] = useState(null);

	const [customerData, setCustomerData] = useState(null);

	const [selectedCustomer, setSelectedCustomer] = useState(null);

	const customerCheckIntervalId = useRef();

	const [outlet, setOutlet] = useState(null);

	const [error, setError] = useState('');

	const pages = usePages(outlet?.settings);

	const onlinePortalSteps = useMemo(
		() => ({
			...defaultOnlinePortalSteps,
			BOOKING: {
				...defaultOnlinePortalSteps.BOOKING,
				title: pages.booking.default.text,
				icon: pages.booking.default.icon,
			},
			MARINA: {
				...defaultOnlinePortalSteps.MARINA,
				title: pages.marina.default.text,
				icon: pages.marina.default.icon,
				description: `View ${pages.marina.default.text} spaces`,
			},
			CAMPGROUND: {
				...defaultOnlinePortalSteps.CAMPGROUND,
				title: pages.campground.default.text,
				icon: pages.campground.default.icon,
				description: `View ${pages.campground.default.text} spaces`,
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[pages, outlet]
	);

	const getCustomerData = _customerId => {
		if (!outlet) return;

		setIsLoading(true);
		apiCall(
			'POST',
			'onlinePortalGetCustomerData',
			res => {
				setCustomerData(res);
				setIsLoading(false);
			},
			() => {
				removeCookie('sdms_customer_id');
				setIsLoading(false);
			},
			'',
			{
				outletId: outlet.id,
				token: outlet.settings.onlineSiteToken,
				id: _customerId,
			}
		);
	};

	useEffect(() => {
		// get customer data on customer id change.
		if (customerId && outlet) getCustomerData(customerId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerId, outlet]);

	useEffect(() => {
		if (!isLoading || !isOnline) return;

		clearInterval(customerCheckIntervalId.current);

		// After loading finished start checking customer cookie interval to sync other shortcodes.
		customerCheckIntervalId.current = setInterval(() => {
			const _customerId = getCookie('sdms_customer_id');

			if (
				_customerId &&
				_customerId !== '0' &&
				(!customerId || customerId.toString() !== _customerId)
			)
				setCustomerId(_customerId);

			if (!_customerId) {
				setCustomerId(null);
				setCustomerData(null);
			}
		}, 500);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		// first get outlet data like id, token and credit card settings.
		apiCall(
			'POST',
			'onlineGetData',
			res => {
				setOutlet(res.outlet);

				if (isOnline) {
					// if customer already logged in set customer id.
					const customerCookie = getCookie('sdms_customer_id');
					if (customerCookie !== null && customerCookie !== '0' && customerCookie !== '')
						setCustomerId(customerCookie);
					else setIsLoading(false);
				} else setIsLoading(false);
			},
			err => {
				setIsLoading(false);
				setError(err.toString());
			},
			'',
			{
				outletId,
				token,
				isOnlinePortal: true,
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [outletId]);

	if (isOnline && isLoading)
		return (
			<div className='sdms-online-portal-loading'>
				<h3>Loading...</h3>
			</div>
		);

	if (error) return <p>{error}</p>;

	if (isOnline && !customerData)
		return (
			<div className='sdms-online-portal-login-container'>
				<Portlet hasFrame className='flex-grow-0  col-12 col-md-8 col-lg-6 col-xl-4'>
					<Portlet.Body className='sdms-c-h100'>
						<Login
							onLogin={_customer => {
								setCookie('sdms_customer_id', _customer.id);
								setCustomerId(_customer.id);
							}}
							outlet={outlet}
							inModal={false}
						/>
					</Portlet.Body>
				</Portlet>
			</div>
		);

	return (
		<Portlet
			className={classNames('sdms-wizard sdms-bg-transparent sdms-online-portal', {
				'is-online': isOnline,
			})}
			hasFrame={false}
			fluid='fluid'>
			<Portlet.Head className='sdms-online-portal-bg-white' wrapMaxSize='md'>
				{isOnline ? (
					<>
						<Portlet.HeadLabel>
							<h3 className='sdms-portlet__head-title'>{`Welcome, ${customerData.customer.displayName}`}</h3>
							<Portlet.Separator />
							<h3 className='sdms-portlet__head-title'>
								Balance Due:
								<small>
									Total{' '}
									<b>{priceFormatter(customerData.customer.customerBalance)}</b>
								</small>
							</h3>
						</Portlet.HeadLabel>
						<Portlet.HeadToolbarActions>
							<Badge
								design='success'
								isInline
								isUnified
								size='lg'
								fontWeight='bolder'>
								Signed in
							</Badge>
							<Dropdown
								icon='Other#1'
								color='clean'
								inline
								aligned='right'
								outline={false}
								arrow={false}>
								<Dropdown.Item
									icon='Sign-out'
									onClick={() => {
										removeCookie('sdms_customer_id');
										setCustomerData(null);
										setCustomerId(null);
									}}>
									Sign Out
								</Dropdown.Item>
							</Dropdown>
						</Portlet.HeadToolbarActions>
					</>
				) : (
					<div className='sdms-online-portal-customer-select-container'>
						<FormField
							name='customerSelect'
							id='customerSelect'
							col={12}
							colMd={6}
							colLg={4}
							inFormDesign={false}>
							<AsyncSelect
								options={[]}
								placeholder='Search and select customer'
								value={selectedCustomer}
								onChange={e => {
									setActiveStep({ index: activeStep.index });
									setCustomerData(null);
									setSelectedCustomer(e.target.value);
									setCustomerId(e.target.value ? e.target.value.id : null);
								}}
								route='customers'
								field='displayName'
								displayKey='displayName'
							/>
						</FormField>
					</div>
				)}
			</Portlet.Head>
			<Portlet.Body className='sdms-online-portal-container'>
				{!isOnline && isLoading && (
					<ReactSVG src={logoLoading} className='svg-container logo-loading' />
				)}
				{!isOnline && !isLoading && !customerData && (
					<>
						<div className='row sdms-m50-desktop sdms-m20-tablet-and-mobile'>
							<div className='offset-lg-3 col-lg-6 sdms-align-center'>
								<h2>Please select a customer</h2>
							</div>
						</div>
						<div className='row'>
							<div className='offset-md-3 col-md-6'>
								<ReactSVG src={crm} className='svg-container' />
							</div>
						</div>
					</>
				)}
				{!isOnline && !isLoading && customerData && (
					<div
						className='sdms-grid sdms-wizard-v2 '
						id='sdms_wizard_v2'
						data-sdmswizard-state={
							activeStep.index === 0
								? 'first'
								: (activeStep.index === 6 && 'last') || 'between'
						}>
						<div className='sdms-grid__item sdms-wizard-v2__aside sdms-online-portal-bg-white'>
							<div className='sdms-wizard-v2__nav'>
								<div className='sdms-wizard-v2__nav-items sdms-wizard-v2__nav-items--sticky'>
									{Object.values(onlinePortalSteps)
										.filter(ops => {
											if (ops.id === 'ach_accounts')
												return !!getPaymentProcessor(
													outlet,
													paymentTypes.CHECK
												);

											if (ops.id === 'credit_cards')
												return !!getPaymentProcessor(
													outlet,
													paymentTypes.CREDIT_CARD
												);

											return true;
										})
										.map((s, index) => (
											<NavItem
												key={s.title}
												title={s.title}
												description={s.description}
												icon={s.icon}
												wizardState={
													index === activeStep.index
														? 'current'
														: (activeStep.index < index && 'pending') ||
														  'done'
												}
												clickAction={() => setActiveStep({ index })}
												isLoading={index !== 0 && isLoading}
											/>
										))}
								</div>
							</div>
						</div>
						<div className='sdms-grid__item sdms-grid__item--fluid sdms-wizard-v2__wrapper sdms-bg-transparent'>
							<Dashboard
								data={customerData}
								steps={onlinePortalSteps}
								setActiveStep={setActiveStep}
								isActive={activeStep.index === 0}
							/>
							<Invoices
								data={customerData}
								isActive={activeStep.index === 1}
								setActiveStep={setActiveStep}
								steps={onlinePortalSteps}
								outlet={outlet}
								status={activeStep.status}
								isOnline={isOnline}
							/>
							<Payments
								data={customerData}
								isActive={activeStep.index === 2}
								setActiveStep={setActiveStep}
								steps={onlinePortalSteps}
								params={activeStep}
								outlet={outlet}
								updateCustomer={({ customerBalance, creditCards, invoices }) => {
									setCustomerData({
										...customerData,
										customer: {
											...customerData.customer,
											creditCards,
											customerBalance,
										},
										invoices,
									});
								}}
								isOnline={isOnline}
							/>
							<CreditMemos
								data={customerData}
								isActive={activeStep.index === 3}
								outlet={outlet}
							/>
							<Spaces
								outlet={outlet}
								data={customerData}
								isActive={activeStep.index === 4}
								title={onlinePortalSteps.MARINA.title}
								icon={onlinePortalSteps.MARINA.icon}
								filters={{ module: modules.MARINA }}
							/>
							<Spaces
								outlet={outlet}
								data={customerData}
								isActive={activeStep.index === 5}
								title={onlinePortalSteps.CAMPGROUND.title}
								icon={onlinePortalSteps.CAMPGROUND.icon}
								filters={{ module: modules.CAMPGROUND }}
							/>
							<Booking
								outlet={outlet}
								data={customerData}
								isActive={activeStep.index === 6}
								title={onlinePortalSteps.BOOKING.title}
								icon={onlinePortalSteps.BOOKING.icon}
							/>
							<MyAccount
								outlet={outlet}
								data={customerData.customer}
								isActive={activeStep.index === 7}
								countries={customerData.countries}
								states={customerData.states}
								updateCustomer={data =>
									setCustomerData({
										...customerData,
										customer: { ...customerData.customer, ...data },
									})
								}
							/>
							<CustomerPaymentMethodList
								key={`${
									(customerData?.customer?.paymentMethods || []).length
								}${customerData?.customer?.defaultPaymentMethod?.id || 0}`}
								data={customerData}
								updateCustomer={customer => {
									setCustomerData({
										...customerData,
										customer: { ...customerData.customer, ...customer },
									});
								}}
								icon={onlinePortalSteps.SAVED_CUSTOMER_PAYMENT_METHODS.icon}
								formTitle='New Payment Method'
								isActive={activeStep.index === 8}
								outlet={outlet}
								type='all'
								title={onlinePortalSteps.SAVED_CUSTOMER_PAYMENT_METHODS.title}
								isOnline={isOnline}
								autoComplete={isOnline}
							/>
						</div>
					</div>
				)}
			</Portlet.Body>
		</Portlet>
	);
};

OnlinePortal.propTypes = {
	outletId: PropTypes.number.isRequired,
	token: PropTypes.string.isRequired,
	isOnline: PropTypes.bool,
};

OnlinePortal.defaultProps = {
	isOnline: true,
};

export default OnlinePortal;
