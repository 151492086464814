import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../app/contexts/UserContext';

import { addErrorNotification } from '../../../utils/helpers/helper';
import apiCall from '../../../utils/helpers/apiCall';

import WidgetGrid from './WidgetGrid';
import Portlet from '../layout/Portlet';
import { getModuleByPath } from '../../../utils/helpers/reusable';

const WidgetGridWrapper = ({ children }) => {
	const userContext = useContext(UserContext);

	const module = useRef(
		userContext.data.user.company.modules.find(m => m.value === getModuleByPath())
	);

	const [isLoading, setIsLoading] = useState(true);

	const [data, setData] = useState(null);

	const load = () => {
		apiCall(
			'GET',
			'analyticsDashboards',
			fetchedData => {
				setData(fetchedData.length > 0 ? fetchedData[0] : null);

				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());

				setIsLoading(false);
			},
			'',
			null,
			{
				'groups[]': 'analytics-dashboard:read',
				isDefault: true,
				'outlet.id': userContext.data.selectedOutlet.id,
				'module.value': module.current.value,
			}
		);
	};

	useEffect(load, []);

	if (isLoading) return null;

	if (!data) return children;

	return (
		<Portlet>
			<Portlet.Body>
				<WidgetGrid widgets={data?.widgets} columns={data?.columns} readOnly />;
			</Portlet.Body>
		</Portlet>
	);
};

export default WidgetGridWrapper;

WidgetGridWrapper.propTypes = {
	children: PropTypes.element,
};

WidgetGridWrapper.defaultProps = {
	children: null,
};
