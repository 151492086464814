import React, { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import { addErrorNotification, maskString, priceFormatter } from '../../../utils/helpers/helper';
import {
	getPaymentProcessor,
	getPaymentProcessorPhrase,
} from '../../../utils/hooks/usePaymentGateway';
import { achPaymentStatues, paymentTypes } from '../../../utils/constants/constants';
import { cryptoDecrypt } from '../../../utils/helpers/crypto';
import useDate from '../../../utils/hooks/useDate';
import ListContainer from '../../reusables/template/ListContainer';
import List from '../../reusables/template/List';
import apiCall from '../../../utils/helpers/apiCall';
import Button from '../../reusables/element/Button';

const AmountCell = ({ data }) => priceFormatter(data.amount);

const DateTimeCell = ({ data }) => {
	const [dateFormatter] = useDate();

	return <span>{dateFormatter(data.timeCreated)}</span>;
};

DateTimeCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

DateTimeCell.defaultProps = {
	data: {
		timeCreated: '',
	},
};

const RoutingNumberCell = ({ data, paymentProcessorPhrase }) => {
	if (!data.routingNumber) return '';

	return maskString(cryptoDecrypt(data.routingNumber, paymentProcessorPhrase));
};

const AccountNumberCell = ({ data, paymentProcessorPhrase }) => {
	if (!data.accountNumber) return '';

	return maskString(cryptoDecrypt(data.accountNumber, paymentProcessorPhrase));
};

const AchErrorLog = ({ history }) => {
	const pages = usePages();
	const userContext = useContext(UserContext);
	const headerContext = useContext(HeaderContext);

	const paymentProcessorPhrase = useMemo(() => {
		if (!userContext.data) return '';

		return getPaymentProcessorPhrase(
			getPaymentProcessor(userContext.data.selectedOutlet, paymentTypes.CHECK)
		);
	}, [userContext]);

	const onIgnore = (payment, updateData) => {
		apiCall(
			'PUT',
			'paymentChecks',
			() => {
				updateData([], [payment.id]);
			},
			err => {
				addErrorNotification(err.toString());
			},
			payment.id,
			{ ignored: true }
		);
	};

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.accounting.default.text, path: pages.accounting.dashboard.path },
			{
				title: pages.accounting.achErrorLogs.text,
				path: pages.accounting.achErrorLogs.path,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(pages.accounting.achErrorLogs.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='paymentChecks'
			title={pages.accounting.achErrorLogs.text}
			history={history}
			icon={pages.accounting.achErrorLogs.icon}
			staticFilters={{
				'exists[ignored]': false,
				'achPaymentStatus.value': achPaymentStatues.CAPTURE_ERROR,
			}}
			checkEditable={() => []}
			customActions={{
				ignore: onIgnore,
			}}>
			<List withCheckBox fluid='fluid' checkEditable={() => ['ignore']}>
				<List.Col label='#' cellData='remittanceId' width={120} sortable='remittanceId' />
				<List.Col
					label='Customer'
					cellDataObject='customer'
					cellData='displayName'
					sortable='customer.displayName'
				/>
				<List.Col label='Status' cellDataObject='status' cellData='value' />
				<List.Col label='Transaction Id' cellData='remittanceId' />
				<List.Col label='Bank Name' cellData='bankName' />
				<List.Col label='Account Name' cellData='accountName' />
				<List.Col
					label='Routing Number'
					cellComponent={
						<RoutingNumberCell paymentProcessorPhrase={paymentProcessorPhrase} />
					}
				/>
				<List.Col
					label='Account Number'
					cellComponent={
						<AccountNumberCell paymentProcessorPhrase={paymentProcessorPhrase} />
					}
				/>
				<List.Col label='Message' cellData='message' width={200} />
				<List.Col label='Amount' cellComponent={<AmountCell />} sortable='amount' />
				<List.Col label='Date' cellComponent={<DateTimeCell />} sortable='timeCreated' />
				<List.Col align='right' onlyHover width={180}>
					<Button
						className='sdms-margin-r-15'
						design='danger'
						text='Ignore'
						icon='Spy'
						size='sm'
						elevate
						key='ignore'
					/>
				</List.Col>
			</List>
		</ListContainer>
	);
};

AchErrorLog.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default AchErrorLog;
