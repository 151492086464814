import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import { reservationStatuses } from '../../../utils/constants/constants';
import { dateFormatter } from '../../../utils/helpers/helper';

import Badge from './Badge';
import Portal from '../layout/Portal';
import Textarea from '../field/Textarea';
import useTooltipPlace from '../../../utils/hooks/useTooltipPlace';

export const reservationColor = data => {
	let color = null;

	switch (data) {
		case reservationStatuses.WAITLIST:
			color = 'secondary';
			break;
		case reservationStatuses.RESERVED:
			color = 'warning';
			break;
		case reservationStatuses.RESERVED_ONLINE:
			color = 'warning';
			break;
		case reservationStatuses.CONFIRMED:
			color = 'info';
			break;
		case reservationStatuses.CHECKED_IN:
			color = 'success';
			break;
		case reservationStatuses.CHECKED_OUT:
			color = 'dark';
			break;
		case reservationStatuses.CANCELLED:
			color = 'danger';
			break;
		default:
			color = 'brand';
			break;
	}
	
	return color;
};

const ReservationStatusCell = ({ data, tooltip }) => {
	const { text: tooltipText, time: tooltipTime } = tooltip;

	return (
		<>
			<Badge
				design={reservationColor(data.status.value)}
				isInline
				isUnified
				fontWeight='bold'
				size='lg'
				className={classNames('sdms-text-overflow', {})}>
				<span
					data-for='reservation-status-tooltip'
					data-tip='reservation-status-tooltip'
					className='sdms-text-overflow'>
					{data.customReservationStatus && data.customReservationStatus.name
						? data.customReservationStatus.name
						: data.status.value}
				</span>
				{(tooltipText || tooltipTime) && (
					<Portal isTooltip>
						<ReactTooltip id='reservation-status-tooltip' type='light' place='bottom'>
							{tooltipTime && <div>Date: {dateFormatter(tooltipTime, false)}</div>}
							{tooltipText && <div>{tooltipText}</div>}
						</ReactTooltip>
					</Portal>
				)}
			</Badge>
		</>
	);
};
ReservationStatusCell.propTypes = {
	data: PropTypes.shape({
		status: PropTypes.shape({
			value: PropTypes.string,
			sortOrder: PropTypes.number,
		}),
		customReservationStatus: PropTypes.shape({
			name: PropTypes.string,
		}),
	}),
	tooltip: PropTypes.shape({
		text: PropTypes.string,
		time: PropTypes.string,
	}),
};
ReservationStatusCell.defaultProps = {
	data: {
		status: {
			value: '',
		},
		customReservationStatus: {
			name: '',
		},
	},
	tooltip: {
		text: null,
		time: null,
	},
};

export default ReservationStatusCell;

export const RequestCell = ({ data }) => {
	const tooltipRef = useRef();

	const tooltipPlace = useTooltipPlace('bottom', tooltipRef);

	if (!data.note) return null;

	return (
		<>
			<span
				ref={tooltipRef}
				data-tip='tooltip'
				data-for={`request${data.id.toString()}`}
				onClick={() => {}}
				role='presentation'>
				<Badge
					design='warning'
					isInline
					isUnified
					fontWeight='boldest'
					size='lg'
					className='sdms-text-overflow'>
					<span className='sdms-text-overflow'>!</span>
				</Badge>
			</span>
			<Portal isTooltip>
				<ReactTooltip
					id={`request${data.id.toString()}`}
					clickable
					type='light'
					place={tooltipPlace}
					className='sdms-date-range-picker sdms-p0'>
					<Textarea
						onChange={() => {}}
						value={data.note}
						disabled
						textCol={40}
						textRow={10}
					/>
				</ReactTooltip>
			</Portal>
		</>
	);
};

RequestCell.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		note: PropTypes.string,
	}),
};

RequestCell.defaultProps = {
	data: {
		id: 0,
		note: '',
	},
};
