import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import {
	generateId,
	numberFormat,
	negativeNumberParser,
	priceFormatter,
	getItemTaxCode,
	getInvoiceDueDate,
	addErrorNotification,
	isBeforeAccountingClosedPeriod,
	getAccountingClosedDate,
	isModuleEnabled,
} from '../../../utils/helpers/helper';
import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import useDate from '../../../utils/hooks/useDate';
import {
	calculateInvoiceItemTotals,
	calculateInvoiceTotals,
} from '../../../utils/helpers/invoiceHelper';

import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Loading from '../../reusables/template/Loading';
import Selects from '../../reusables/field/Selects';
import DatePicker from '../../reusables/field/DatePicker';
import Button from '../../reusables/element/Button';
import Input from '../../reusables/field/Input';
import Toggle from '../../reusables/field/Toggle';
import AsyncSelect from '../../reusables/field/AsyncSelect';
import {
	invoiceStatuses,
	enumItemTypes,
	invoiceTypes as constInvoiceTypes,
	discountCalculations,
} from '../../../utils/constants/constants';
import Portlet from '../../reusables/layout/Portlet';
import { ListBody, ListTable } from '../../reusables/template/List';
import Alert from '../../reusables/element/Alert';
import Separator from '../../reusables/layout/Separator';
import Checkbox from '../../reusables/field/Checkbox';
import InvoiceItemDeferredSchedule from '../../reusables/modals/InvoiceItemDeferredSchedule';
import { modules } from '../../../utils/helpers/apiCall';
import Textarea from '../../reusables/field/Textarea';

const InvoiceItem = ({
	data,
	invoiceItems,
	discounts,
	taxCodes,
	taxRates,
	enumCalculations,
	onFormChange,
	taxRate,
	onChange,
	isSubmitted,
	setIsValid,
	onDelete,
	disabled,
	customerTaxCode,
	updateTaxCode,
	onDeferredSchedule,
	hasPostedDeferredIncome,
}) => {
	const userContext = useContext(UserContext);
	const hasManualChange = useRef(false);

	const [product, productOnChange, productValRes, productShowVal, setProductShowVal] = useField(
		data,
		'product',
		onFormChange,
		[required],
		null
	);

	const [description, descriptionOnChange] = useField(data, 'description', onFormChange);
	const [discount, discountOnChange] = useField(data, 'discount', onFormChange, [], null);

	const [invoiceItemTaxRates, invoiceItemTaxRatesOnChange] = useField(
		data,
		'invoiceItemTaxRates',
		onFormChange,
		[],
		[]
	);

	const [taxCode, taxCodeOnChange, taxCodeValRes, taxCodeShowVal, setTaxCodeShowVal] = useField(
		data,
		'taxCode',
		onFormChange,
		[required],
		data.taxCode
	);

	const [
		quantity,
		quantityOnChange,
		quantityValRes,
		quantityShowVal,
		setQuantityShowVal,
	] = useField(data, 'quantity', onFormChange, [required], '', negativeNumberParser);

	const [price, priceOnChange, priceValRes, priceShowVal, setPriceShowVal] = useField(
		data,
		'price',
		onFormChange,
		[required],
		'',
		negativeNumberParser
	);

	const [isTipItem, isTipItemOnChange] = useField(data, 'isTipItem', onFormChange, [], false);
	const [isFeeItem, isFeeItemOnChange] = useField(data, 'isFeeItem', onFormChange, [], false);

	const [totals, setTotals] = useState({
		subtotal: data.subtotal,
		tax: 0,
		total: 0,
	});

	const updateTotals = () => {
		if (!taxRate) {
			setTotals({
				subtotal: 0,
				tax: 0,
				total: 0,
			});
			return;
		}

		const _totals = calculateInvoiceItemTotals(data, invoiceItems, taxRate);

		data.taxRate = _totals.taxRate;
		data.taxableAmount = _totals.taxableAmount;
		data.subtotal = _totals.subtotal - _totals.discountAmount;
		data.taxTotal = _totals.taxTotal;
		data.total = _totals.total;

		if (data.discountType?.value === 'fixed') data.discountAmount = _totals.discountAmount;

		if (data.product && data.product.itemType.isSystem) {
			data.quantity = _totals.quantity;
			data.price = _totals.price;
		}

		setTotals({
			taxableAmount: data.taxableAmount || 0,
			subtotal:
				data.product && data.product.itemType.value === enumItemTypes.SUBTOTAL
					? _totals.price
					: data.subtotal || 0,
			tax: data.taxTotal || 0,
			total:
				data.product && data.product.itemType.value === enumItemTypes.SUBTOTAL
					? _totals.price
					: data.total || 0,
		});

		onChange(hasManualChange.current);
	};

	useEffect(
		updateTotals,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			quantity,
			price,
			product,
			taxCode,
			discount,
			invoiceItemTaxRates,
			taxRate,
			isTipItem,
			isFeeItem,
		]
	);

	useEffect(() => {
		if (isSubmitted) {
			setProductShowVal();
			setTaxCodeShowVal();
			setQuantityShowVal();
			setPriceShowVal();
		}
	}, [isSubmitted, setProductShowVal, setTaxCodeShowVal, setQuantityShowVal, setPriceShowVal]);

	useEffect(() => {
		setIsValid(
			productValRes.isValid &&
				taxCodeValRes.isValid &&
				quantityValRes.isValid &&
				priceValRes.isValid
		);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [productValRes.isValid, taxCodeValRes.isValid, quantityValRes.isValid, priceValRes.isValid]);

	useEffect(() => {
		// update only manual changes.
		if (product && updateTaxCode.current) {
			taxCodeOnChange({ target: { value: getItemTaxCode(customerTaxCode, product) } });
			updateTaxCode.current = false;
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [customerTaxCode]);

	return (
		<tr className={classNames({ disabled })}>
			<td>
				<FormField
					name='product'
					id={data.id}
					valRes={productValRes}
					showValidation={productShowVal}
					inFormDesign={false}
					isLast>
					<AsyncSelect
						placeholder='Search and select product from list'
						value={product}
						onChange={e => {
							productOnChange(e);

							priceOnChange({
								target: { value: e.target.value ? e.target.value.price : '' },
							});

							taxCodeOnChange({
								target: {
									value: getItemTaxCode(customerTaxCode, e.target.value),
								},
							});

							data.name = e.target.value ? e.target.value.name : '';

							if (e.target.value) data.description = e.target.value.description;
							else data.description = '';

							if (
								e.target.value &&
								e.target.value.itemType &&
								e.target.value.itemType.value === 'discount' &&
								e.target.value.acctDiscount
							) {
								data.discountType = enumCalculations.find(
									ec => ec.id === e.target.value.acctDiscount.sdmsDiscount.type.id
								);

								data.discountAmount =
									e.target.value.acctDiscount.sdmsDiscount.amount;

								data.discount = e.target.value.acctDiscount.sdmsDiscount;
							} else {
								data.discountType = null;
								data.discountAmount = null;
								data.discount = null;
							}

							let invoiceItemTaxRate = [];

							if (e.target.value) {
								if (e.target.value.itemType.value === 'sales-tax') {
									invoiceItemTaxRate = [
										{
											'@type': 'SdmsInvoiceItemTaxRate',
											id: generateId(invoiceItemTaxRates),
											name: e.target.value.name,
											taxRate: e.target.value.acctTaxRate.sdmsTaxRate,
											amount: e.target.value.acctTaxRate.sdmsTaxRate.amount,
										},
									];
								} else {
									invoiceItemTaxRate = e.target.value.additionalTaxes.map(tr => {
										if (tr['@type'] === 'SdmsTaxRate')
											return {
												'@type': 'SdmsInvoiceItemTaxRate',
												id: generateId(invoiceItemTaxRates),
												name: tr.name,
												taxRate: tr,
												amount: tr.amount,
											};
										return tr;
									});
								}
							}

							invoiceItemTaxRatesOnChange({
								target: {
									value: invoiceItemTaxRate,
								},
							});

							if (!quantity) quantityOnChange({ target: { value: 1 } });

							if (e.target.value && !e.target.value.enableDeferredIncome)
								data.enableDeferredIncome = false;

							hasManualChange.current = true;
						}}
						onBlur={setProductShowVal}
						route='products'
						renderOption={option => (
							<>
								{option.name} - {priceFormatter(option.price)}
							</>
						)}
						disabled={disabled || hasPostedDeferredIncome}
						customFilters={{ inactive: false }}
					/>
				</FormField>
			</td>
			<td>
				<FormField name='description' id={data.id} inFormDesign={false} isLast>
					<Input
						type='text'
						placeholder='Description'
						value={description}
						onChange={descriptionOnChange}
						disabled={(data.product && data.product.itemType.isSystem) || disabled}
					/>
				</FormField>
			</td>

			<td>
				<FormField name='discount' id={data.id} inFormDesign={false} isLast>
					<Selects
						value={discount}
						options={discounts.filter(
							d =>
								d.calculation?.value !== discountCalculations.PER_QUANTITY ||
								d.eligibleProducts.some(p => p.id === product?.id)
						)}
						onChange={e => {
							data.discountType = e.target.value
								? enumCalculations.find(
										ec =>
											ec.value ===
											(e.target?.value?.calculation?.value ===
											discountCalculations.PERCENT
												? discountCalculations.PERCENT
												: discountCalculations.FIXED)
								  )
								: null;

							data.discountAmount = 0;

							if (e.target.value)
								data.discountAmount =
									e.target?.value?.calculation?.value ===
									discountCalculations.PER_QUANTITY
										? (quantity || 1) * e.target.value.amount
										: e.target.value.amount;

							discountOnChange(e);

							hasManualChange.current = true;
						}}
						disabled={(data.product && data.product.itemType.isSystem) || disabled}
					/>
				</FormField>
			</td>
			<td>
				<FormField name='invoiceItemTaxRates' id={data.id} inFormDesign={false} isLast>
					<Selects
						options={taxRates}
						multiple
						value={invoiceItemTaxRates}
						valueKey='name'
						onChange={e => {
							invoiceItemTaxRatesOnChange({
								target: {
									value: e.target.value
										? e.target.value.map(tr => {
												if (tr['@type'] === 'SdmsTaxRate')
													return {
														'@type': 'SdmsInvoiceItemTaxRate',
														id: generateId(invoiceItemTaxRates),
														name: tr.name,
														taxRate: tr,
														amount: tr.amount,
													};
												return tr;
										  })
										: [],
								},
							});
						}}
						disabled={(data.product && data.product.itemType.isSystem) || disabled}
					/>
				</FormField>
			</td>
			<td>
				<FormField
					name='quantity'
					id={data.id}
					inFormDesign={false}
					showValidation={quantityShowVal}
					valRes={quantityValRes}
					isLast>
					<Input
						type='text'
						placeholder='Qty'
						value={quantity}
						onChange={e => {
							if (
								data.discount?.calculation?.value ===
								discountCalculations.PER_QUANTITY
							) {
								data.discountAmount = data.discount.amount * (e.target.value || 1);
							}

							quantityOnChange(e);

							hasManualChange.current = true;
						}}
						onBlur={setQuantityShowVal}
						disabled={(data.product && data.product.itemType.isSystem) || disabled}
					/>
				</FormField>
			</td>
			<td>
				<FormField
					name='price'
					id={data.id}
					inFormDesign={false}
					showValidation={priceShowVal}
					valRes={priceValRes}
					isLast>
					<Input
						type='text'
						placeholder='Price'
						value={price}
						onChange={e => {
							priceOnChange(e);
							hasManualChange.current = true;
						}}
						onBlur={setPriceShowVal}
						pattern={process.env.REACT_APP_NEGATIVE_PRICE_PATTERN}
						disabled={(data.product && data.product.itemType.isSystem) || disabled}
					/>
				</FormField>
			</td>
			<td>
				<FormField
					name='taxCode'
					id={data.id}
					inFormDesign={false}
					showValidation={taxCodeShowVal}
					valRes={taxCodeValRes}
					isLast>
					<Selects
						value={taxCode}
						options={taxCodes}
						onChange={taxCodeOnChange}
						onBlur={setTaxCodeShowVal}
						disabled={(data.product && data.product.itemType.isSystem) || disabled}
					/>
				</FormField>
			</td>
			<td>{priceFormatter(totals.subtotal)}</td>
			<td>{priceFormatter(totals.tax)}</td>
			<td>{priceFormatter(totals.total)}</td>
			<td>
				<div className='row'>
					<div className='col'>
						<FormField name='isTipItem' id={data.id} inFormDesign={false} isLast>
							<Checkbox
								id={`${data.id}tipItem`}
								onChange={() => {
									isTipItemOnChange({ target: { value: !isTipItem } });
									isFeeItemOnChange({ target: { value: false } });
								}}
								value={isTipItem}
								bold
								single={false}
								content='Tip'
								disabled={disabled}
							/>
						</FormField>
						<FormField name='isFeeItem' id={data.id} inFormDesign={false} isLast>
							<Checkbox
								id={`${data.id}feeItem`}
								onChange={() => {
									isFeeItemOnChange({ target: { value: !isFeeItem } });
									isTipItemOnChange({ target: { value: false } });
								}}
								value={isFeeItem}
								bold
								single={false}
								content='Fee'
								disabled={disabled}
							/>
						</FormField>
					</div>
					<div className='col' style={{ paddingTop: 8 }}>
						<Button
							btnIcon
							label='danger'
							icon='Trash'
							size='sm'
							elevate
							key='delete'
							onClick={onDelete}
							disabled={disabled}
						/>
					</div>
					<div className='col-auto' style={{ paddingTop: 8 }}>
						<Button
							btnIcon
							iconFill={
								data.enableDeferredIncome &&
								data.itemDeferredIncomes &&
								data.itemDeferredIncomes.length
									? 'primary'
									: 'gray-dark'
							}
							icon='Timer'
							size='sm'
							elevate
							key='deferredSchedule'
							onClick={onDeferredSchedule}
							disabled={
								!data.product ||
								(!data.product.enableDeferredIncome &&
									!data.enableDeferredIncome &&
									!userContext.hasPermission('edit_deferred_all_items'))
							}
							noPermission={!userContext.hasPermission('maintain_deferred_schedule')}
						/>
					</div>
				</div>
			</td>
		</tr>
	);
};
InvoiceItem.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		product: PropTypes.object,
		name: PropTypes.string,
		description: PropTypes.string,
		discount: PropTypes.object,
		discountAmount: PropTypes.number,
		discountType: PropTypes.object,
		invoiceItemTaxRates: PropTypes.arrayOf(PropTypes.object),
		taxCode: PropTypes.object,
		quantity: PropTypes.number,
		subtotal: PropTypes.number,
		taxRate: PropTypes.number,
		taxTotal: PropTypes.number,
		total: PropTypes.number,
		price: PropTypes.number,
		taxableAmount: PropTypes.number,
		enableDeferredIncome: PropTypes.bool,
		itemDeferredIncomes: PropTypes.arrayOf(PropTypes.object),
	}),
	invoiceItems: PropTypes.arrayOf(PropTypes.object),
	discounts: PropTypes.arrayOf(PropTypes.object),
	taxRates: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	enumCalculations: PropTypes.arrayOf(PropTypes.object),
	onFormChange: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	taxRate: PropTypes.object,
	onChange: PropTypes.func,
	isSubmitted: PropTypes.bool,
	setIsValid: PropTypes.func,
	onDelete: PropTypes.func,
	disabled: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	customerTaxCode: PropTypes.object,
	onDeferredSchedule: PropTypes.func,
	hasPostedDeferredIncome: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	updateTaxCode: PropTypes.object,
};
InvoiceItem.defaultProps = {
	data: {
		id: 0,
		product: null,
		name: '',
		description: '',
		discount: null,
		discountAmount: 0,
		discountType: null,
		invoiceItemTaxRates: [],
		taxCode: null,
		quantity: '',
		subtotal: 0,
		taxableAmount: 0,
		taxRate: 0,
		taxTotal: 0,
		total: 0,
	},
	invoiceItems: [],
	discounts: [],
	taxCodes: [],
	taxRates: [],
	enumCalculations: [],
	onFormChange: () => {},
	taxRate: null,
	onChange: () => {},
	isSubmitted: false,
	setIsValid: () => {},
	onDelete: () => {},
	disabled: false,
	customerTaxCode: null,
	onDeferredSchedule: () => {},
	hasPostedDeferredIncome: false,
	updateTaxCode: null,
};

const InvoiceForm = ({
	data,
	setIsValid,
	isSubmitted,
	isLoading,
	isListLoading,
	onFormChange,
	discounts,
	paymentTerms,
	enumCalculations,
	taxCodes,
	taxRates,
	enumInvoiceStatuses,
	accounts,
	acctClasses,
	invoiceTypes,
	isEditable,
	shouldCustomerInputDisabled,
	isQuickPanel,
}) => {
	const pages = usePages();
	const userContext = useContext(UserContext);
	const headerContext = useContext(HeaderContext);
	const updateItemTaxCode = useRef(false);

	const [, , parseDatePickerValue, parseDatePickerChange] = useDate();

	const [
		customer,
		customerOnChange,
		customerValRes,
		customerShowVal,
		setCustomerShowVal,
	] = useField(data, 'customer', onFormChange, [required], null);

	const [vessel, vesselOnChange] = useField(data, 'vessel', onFormChange, [], null);

	const [autoPay, autoPayOnChange] = useField(
		data,
		'autoPay',
		onFormChange,
		[],
		data.autoPay || false
	);

	const [paymentTerm, paymentTermOnChange] = useField(
		data,
		'paymentTerm',
		onFormChange,
		[],
		data.paymentTerm || null
	);

	const [
		invoiceDate,
		invoiceDateOnChange,
		invoiceDateValRes,
		invoiceDateShowVal,
		setInvoiceDateShowVal,
	] = useField(
		data,
		'invoiceDate',
		onFormChange,
		[required],
		data.invoiceDate || data.timeCreated || null
	);

	const [dueDate, dueDateOnChange, dueDateValRes, dueDateShowVal, setDueDateShowVal] = useField(
		data,
		'dueDate',
		onFormChange,
		[required],
		data.dueDate || null
	);

	const [discount, discountOnChange] = useField(data, 'discount', onFormChange, [], null);

	const taxRateValidation = (value, setValRes) => {
		if (
			data.invoiceItems.filter(
				ii =>
					ii.invoiceItemTaxRates &&
					ii.invoiceItemTaxRates.filter(iitx => iitx.taxRate.id === value.id).length > 0
			).length > 0
		) {
			setValRes({
				isValid: false,
				status: 'invalid',
				message:
					'You cannot use the same tax item in both the line items and the invoice tax.',
			});

			return false;
		}

		return true;
	};

	const [
		taxRate,
		taxRateOnChange,
		taxRateValRes,
		taxRateShowVal,
		setTaxRateShowVal,
		validateTaxRate,
	] = useField(data, 'taxRate', onFormChange, [required, taxRateValidation], null);

	const [
		customerTaxCode,
		customerTaxCodeOnChange,
		customerTaxCodeValRes,
		customerTaxCodeShowVal,
		setCustomerTaxCodeShowVal,
	] = useField(
		data,
		'customerTaxCode',
		onFormChange,
		[required],
		userContext.data.user.company.settings
			? userContext.data.user.company.settings.defaultTaxCode
			: null
	);

	const [accountingClass, accountingClassOnChange] = useField(
		data,
		'accountingClass',
		onFormChange,
		[],
		null
	);

	const [
		accountReceivableAccount,
		accountReceivableAccountOnChange,
		accountReceivableAccountValRes,
		accountReceivableAccountShowVal,
		setAccountReceivableAccountShowVal,
	] = useField(data, 'accountReceivableAccount', onFormChange, [required], null);

	const [totals, setTotals] = useState({
		subtotal: 0,
		taxTotal: 0,
		total: 0,
	});

	const [invalidItems, setInvalidItems] = useState([]);

	const isClosed =
		!isLoading &&
		data.id !== 0 &&
		isBeforeAccountingClosedPeriod(data.invoiceDate, userContext);

	const disabled =
		(data.status && data.status.value === invoiceStatuses.VOIDED) ||
		(data.isSalesReceipt && !userContext.hasPermission('edit_sales_receipts')) ||
		!isEditable ||
		isClosed;

	const reCalculate = useRef(false);
	const [invoiceItemDeferredSchedule, setInvoiceItemDeferredSchedule] = useState(null);
	const [invoiceItemDeferredScheduleKey, setInvoiceItemDeferredScheduleKey] = useState(
		Date.now()
	);
	const [note, noteOnChange] = useField(data, 'note', onFormChange);
	const [externalNote, externalNoteOnChange] = useField(data, 'externalNote', onFormChange);

	const updateTotals = () => {
		const _totals = calculateInvoiceTotals(data, enumInvoiceStatuses);

		data.subtotal = _totals.subtotal;
		data.taxTotal = _totals.taxTotal;
		data.discountTotal = _totals.discountTotal;
		data.total = _totals.total;
		data.status = _totals.status;

		setTotals(_totals);
	};

	const checkItemDeferredItems = () => {
		data.invoiceItems.forEach((item, itemIndex) => {
			if (
				item.enableDeferredIncome &&
				item.itemDeferredIncomes &&
				item.itemDeferredIncomes.length > 0
			) {
				// calculate item deferred incomes total.
				let totalItemDeferredIncomes = item.itemDeferredIncomes.reduce(
					(partialSum, a) => partialSum + (a.amount ? parseFloat(a.amount) : 0),
					0
				);

				const itemSubtotal = parseFloat(item.subtotal);

				// if item deferred income total is not equal to item subtotal add diff to not posted item deferred incomes.
				if (totalItemDeferredIncomes !== itemSubtotal) {
					const notPostedItemDeferredIncomes = item.itemDeferredIncomes.filter(
						itemDeferredIncome => {
							const invoiceDeferredIncome = data.deferredIncomes.find(
								deferredIncome =>
									deferredIncome.itemDeferredIncomes.filter(
										idi => idi.id === itemDeferredIncome.id
									).length > 0
							);

							return invoiceDeferredIncome && !invoiceDeferredIncome.posted;
						}
					);

					if (notPostedItemDeferredIncomes.length > 0) {
						const diffPerItem =
							(itemSubtotal - totalItemDeferredIncomes) /
							notPostedItemDeferredIncomes.length;

						notPostedItemDeferredIncomes.forEach(notPostedItemDeferredIncome => {
							const notPostedItemDeferredIncomeIndex = item.itemDeferredIncomes.findIndex(
								itemDeferredIncome =>
									itemDeferredIncome.id === notPostedItemDeferredIncome.id
							);

							if (notPostedItemDeferredIncomeIndex > -1) {
								const newAmount = numberFormat(
									data.invoiceItems[itemIndex].itemDeferredIncomes[
										notPostedItemDeferredIncomeIndex
									].amount + diffPerItem,
									2
								);

								data.invoiceItems[itemIndex].itemDeferredIncomes[
									notPostedItemDeferredIncomeIndex
								].amount = newAmount > 0 ? newAmount : 0;
							}
						});

						totalItemDeferredIncomes = item.itemDeferredIncomes.reduce(
							(partialSum, a) => partialSum + (a.amount ? parseFloat(a.amount) : 0),
							0
						);

						// check item deferred income total again for rounding.
						// if item deferred income total has rounding diff add diff to first not posted item deferred income.
						if (totalItemDeferredIncomes !== itemSubtotal) {
							const notPostedItemDeferredIncomeIndex = item.itemDeferredIncomes.findIndex(
								itemDeferredIncome =>
									itemDeferredIncome.id === notPostedItemDeferredIncomes[0].id
							);

							const newAmount = numberFormat(
								data.invoiceItems[itemIndex].itemDeferredIncomes[
									notPostedItemDeferredIncomeIndex
								].amount +
									(itemSubtotal - totalItemDeferredIncomes),
								2
							);

							data.invoiceItems[itemIndex].itemDeferredIncomes[
								notPostedItemDeferredIncomeIndex
							].amount = newAmount > 0 ? newAmount : 0;
						}

						setInvoiceItemDeferredScheduleKey(Date.now());
					}
				}
			}
		});
	};

	const onItemDelete = itemId => {
		data.invoiceItems = data.invoiceItems.filter(i => i.id !== itemId);
		setInvalidItems(invalidItems.filter(i => i !== itemId));
		updateTotals();
	};

	const getBanner = () => {
		if (isClosed)
			return (
				<div className='col-12 sdms-mb-20'>
					<Alert solid icon='Error-circle' design='danger'>
						Accounting Period Closed
					</Alert>
				</div>
			);

		if (data.status && data.status.value === invoiceStatuses.VOIDED)
			return (
				<div className='col-12 sdms-mb-20'>
					<Alert solid icon='Error-circle' design='danger'>
						Invoice Voided
					</Alert>
				</div>
			);

		if (data.isSalesReceipt)
			return (
				<div className='col-12 sdms-mb-20'>
					<Alert solid icon='Error-circle' design='danger'>
						Invoice is Sales Receipt
					</Alert>
				</div>
			);

		return null;
	};

	const hasItemPostedDeferredIncome = item => {
		if (
			!data.deferredIncomes ||
			data.deferredIncomes.length === 0 ||
			!item.itemDeferredIncomes ||
			item.itemDeferredIncomes.length === 0
		)
			return false;

		return (
			item.itemDeferredIncomes.filter(itemDeferredIncome => {
				const invoiceDeferredIncome = data.deferredIncomes.find(
					deferredIncome =>
						deferredIncome.itemDeferredIncomes.filter(
							idi => idi.id === itemDeferredIncome.id
						).length > 0
				);

				if (!invoiceDeferredIncome) return false;

				return invoiceDeferredIncome.posted;
			}).length > 0
		);
	};

	useEffect(() => {
		if (isSubmitted) {
			setCustomerShowVal();
			setDueDateShowVal();
			setInvoiceDateShowVal();
			setTaxRateShowVal();
			setCustomerTaxCodeShowVal();
			setAccountReceivableAccountShowVal();
		}
	}, [
		isSubmitted,
		setCustomerShowVal,
		setDueDateShowVal,
		setInvoiceDateShowVal,
		setTaxRateShowVal,
		setCustomerTaxCodeShowVal,
		setAccountReceivableAccountShowVal,
	]);

	useEffect(() => {
		if (
			disabled &&
			(!data.deferredIncomes ||
				data.deferredIncomes.length === 0 ||
				!userContext.hasPermission('maintain_deferred_schedule'))
		)
			setIsValid(false);
		else
			setIsValid(
				customerValRes.isValid &&
					dueDateValRes.isValid &&
					invalidItems.length === 0 &&
					invoiceDateValRes.isValid &&
					taxRateValRes.isValid &&
					customerTaxCodeValRes.isValid &&
					accountReceivableAccountValRes.isValid &&
					data.invoiceItems.length > 0
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		data,
		customerValRes.isValid,
		dueDateValRes.isValid,
		invalidItems,
		setIsValid,
		invoiceDateValRes.isValid,
		taxRateValRes.isValid,
		customerTaxCodeValRes.isValid,
		accountReceivableAccountValRes.isValid,
	]);

	useEffect(() => {
		if (!isQuickPanel) {
			headerContext.setBreadcrumbs([
				{ title: pages.crm.default.text, path: pages.crm.dashboard.path },
				{ title: pages.crm.invoices.text, path: pages.crm.invoices.path },
				{ title: `Invoice ${data.invoiceId || 'New'}`, isActive: true },
			]);

			headerContext.setPageTitle(`Invoice ${data.invoiceId || 'New'}`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.id]);

	useEffect(() => {
		if (!isLoading) {
			if (data.id === 0) {
				setTotals({
					subtotal: data.subtotal || 0,
					taxTotal: data.taxTotal || 0,
					total: data.total || 0,
				});

				if (!data.status)
					data.status = enumInvoiceStatuses.find(
						eis => eis.value === invoiceStatuses.OPEN
					);

				if (!data.outlet) data.outlet = userContext.data.selectedOutlet;

				if (!data.taxRate)
					taxRateOnChange({
						target: { value: userContext.data.selectedOutlet.settings.taxRate },
					});

				if (!data.invoiceItems) data.invoiceItems = [];

				if (!data.amountPaid) data.amountPaid = 0;

				if (!data.accountReceivableAccount)
					accountReceivableAccountOnChange({
						target: { value: userContext.getDefaultAccountReceivableAccount() },
					});
			} else if (data.sdmsOrder) {
				// fix pos invoice timezone issue.
				invoiceDateOnChange({
					target: {
						value: moment(data.invoiceDate || data.timeCreated).toISOString(),
					},
				});
			}
		}

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	useEffect(() => {
		if (!isLoading && reCalculate.current) {
			updateTotals();
			reCalculate.current = false;
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [discount, taxRate]);

	// set outlet accounting class as default.
	useEffect(() => {
		if (!isLoading && !isListLoading && data.id === 0) {
			const outletAccountingClass = acctClasses.find(
				ac => ac.listId === userContext.data.selectedOutlet.settings.quickbooksClass
			);
			if (outletAccountingClass)
				accountingClassOnChange({ target: { value: outletAccountingClass } });
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading, isListLoading, acctClasses]);

	useEffect(() => {
		if (data.id === 0 && !isLoading && invoiceTypes.length && !data.type)
			data.type = invoiceTypes.find(it => it.value === constInvoiceTypes.INVOICE);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [invoiceTypes, isLoading]);

	return (
		<>
			<Portlet
				fluid='fluid'
				className='sdms-bg-transparent sdms-portlet--unelevate sdms-portlet--portletception sdms-mb-0'>
				<Portlet.Body className='sdms-last-margin sdms-p0'>
					<Portlet className='flex-grow-0 sdms-portlet__modifiers sdms-min-h-fit-content'>
						<Portlet.Body>
							<FormGroup>
								{getBanner()}
								<Loading isLoading={isLoading}>
									<FormField
										name='customer'
										label='Customer'
										id={data.id}
										colMd={2}
										valRes={customerValRes}
										showValidation={customerShowVal}>
										<AsyncSelect
											options={data.customer ? [data.customer] : []}
											placeholder='Search and select customer'
											value={customer}
											onChange={e => {
												customerOnChange(e);

												if (e.target.value && e.target.value.taxCode)
													customerTaxCodeOnChange({
														target: {
															value: e.target.value.taxCode,
														},
													});

												updateItemTaxCode.current = true;
											}}
											route='customers'
											field='displayName'
											displayKey='displayName'
											onBlur={setCustomerShowVal}
											disabled={data.id !== 0 || shouldCustomerInputDisabled}
											customFilters={{ isActive: true }}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='paymentTerm'
										label='Payment Term'
										id={data.id}
										colMd={2}>
										<Selects
											options={paymentTerms}
											placeholder='Payment Term'
											value={paymentTerm}
											onChange={e => {
												paymentTermOnChange(e);
												if (e.target.value && invoiceDate)
													dueDateOnChange({
														target: {
															value: getInvoiceDueDate(
																moment(invoiceDate),
																e.target.value
															),
														},
													});
											}}
											disabled={disabled}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='accountReceivableAccount'
										label='Revenue Account'
										id={data.id}
										valRes={accountReceivableAccountValRes}
										showValidation={accountReceivableAccountShowVal}
										colLg={2}>
										<Selects
											options={accounts.filter(
												a => a.accountType.value === 'AccountsReceivable'
											)}
											placeholder='Revenue Account'
											value={accountReceivableAccount}
											onChange={accountReceivableAccountOnChange}
											onBlur={setAccountReceivableAccountShowVal}
											disabled={disabled}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='invoiceDate'
										label='Invoice Date'
										id={data.id}
										loadingContainer
										colMd={2}
										valRes={invoiceDateValRes}
										showValidation={invoiceDateShowVal}>
										<DatePicker
											id='invoiceDate'
											type='calendar'
											placeholder='Invoice Date'
											value={parseDatePickerValue(invoiceDate)}
											onChange={e => {
												const _invoiceDate = parseDatePickerChange(
													e.target.value
												);

												invoiceDateOnChange({
													target: {
														value: _invoiceDate,
													},
												});

												dueDateOnChange({
													target: {
														value:
															paymentTerm && _invoiceDate
																? getInvoiceDueDate(
																		moment(_invoiceDate),
																		paymentTerm
																  ).toISOString()
																: null,
													},
												});
											}}
											onBlur={setInvoiceDateShowVal}
											disabled={disabled}
											minDate={getAccountingClosedDate(userContext)}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='dueDate'
										label='Due Date'
										id={data.id}
										valRes={dueDateValRes}
										showValidation={dueDateShowVal}
										loadingContainer
										colMd={2}>
										<DatePicker
											id='dueDate'
											type='calendar'
											placeholder='Due Date'
											value={parseDatePickerValue(dueDate)}
											onChange={e => {
												dueDateOnChange({
													target: {
														value: parseDatePickerChange(
															e.target.value
														),
													},
												});
											}}
											onBlur={setDueDateShowVal}
											minDate={moment(invoiceDate || undefined).toDate()}
											disabled={disabled}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='accountingClass'
										label='Class'
										id={data.id}
										colMd={1}>
										<Selects
											options={acctClasses}
											placeholder='Accounting class'
											value={accountingClass}
											onChange={accountingClassOnChange}
											disabled={disabled}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='autoPay'
										label='Auto Pay'
										id={data.id}
										colMd={1}>
										<Toggle
											value={autoPay}
											onChange={autoPayOnChange}
											disabled={disabled}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Portlet.Body>
					</Portlet>
					<Portlet className='sdms-list-layout' fluid='fluid' border>
						<Portlet.Head>
							<Portlet.HeadLabelTitle>Items</Portlet.HeadLabelTitle>
							<Portlet.HeadToolbarActions>
								<Button
									label='brand'
									icon='Plus'
									text='New Item'
									key='newItem'
									size='sm'
									onClick={() => {
										const itemId = generateId(data.invoiceItems || []);

										data.invoiceItems.push({
											id: itemId,
											sortOrder:
												data.invoiceItems && data.invoiceItems.length
													? Math.max(
															...data.invoiceItems.map(i =>
																i.id > i.sortOrder
																	? i.id
																	: i.sortOrder
															)
													  ) + 1
													: itemId,
											isTipItem: false,
											isFeeItem: false,
											itemDeferredIncomes: [],
											taxCode: null,
										});

										setInvalidItems([...invalidItems, itemId]);
									}}
									disabled={disabled}
								/>
							</Portlet.HeadToolbarActions>
						</Portlet.Head>
						<ListBody
							className='table--everytime--scroll sdms-portlet__body--fit'
							responsive='scroll'>
							<ListTable childrenLength={11} width={1530}>
								<colgroup>
									<col />
									<col />
									<col />
									<col />
									<col style={{ width: 110 }} />
									<col style={{ width: 110 }} />
									<col style={{ width: 110 }} />
									<col style={{ width: 110 }} />
									<col style={{ width: 110 }} />
									<col style={{ width: 110 }} />
									<col style={{ width: 180 }} />
								</colgroup>
								<thead>
									<tr>
										<th>Product</th>
										<th>Description</th>
										<th>Discount</th>
										<th>Additional Taxes</th>
										<th>Qty</th>
										<th>Price</th>
										<th>Tax Code</th>
										<th>Amount</th>
										<th>Tax</th>
										<th>Total</th>
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{data.invoiceItems &&
										[...data.invoiceItems]
											.sort(
												(a, b) => a.sortOrder - b.sortOrder || a.id - b.id
											)
											.map(item => (
												<InvoiceItem
													key={item.id}
													data={item}
													invoiceItems={data.invoiceItems}
													enumCalculations={enumCalculations}
													discounts={discounts}
													onFormChange={onFormChange}
													taxCodes={taxCodes}
													taxRates={
														taxRate
															? taxRates.filter(
																	tr => tr.id !== taxRate.id
															  )
															: []
													}
													taxRate={taxRate}
													onChange={init => {
														validateTaxRate(taxRate);
														updateTotals();
														if (init) checkItemDeferredItems();
													}}
													setIsValid={_isValid =>
														setInvalidItems(
															_isValid
																? invalidItems.filter(
																		itemId => itemId !== item.id
																  )
																: [...invalidItems, item.id]
														)
													}
													isSubmitted={isSubmitted}
													onDelete={() => onItemDelete(item.id)}
													disabled={disabled || isLoading}
													customerTaxCode={customerTaxCode}
													onDeferredSchedule={() => {
														if (
															!data.deferredIncomes ||
															data.deferredIncomes.length === 0
														)
															addErrorNotification(
																'Please enable deferred schedule on invoice first.'
															);
														else
															setInvoiceItemDeferredSchedule(item.id);
													}}
													hasPostedDeferredIncome={hasItemPostedDeferredIncome(
														item
													)}
													updateTaxCode={updateItemTaxCode}
													invoiceIsClosed={isClosed}
												/>
											))}
								</tbody>
							</ListTable>
						</ListBody>
					</Portlet>
					<Portlet className='flex-grow-0 sdms-portlet__modifiers sdms-min-h-fit-content'>
						<Portlet.Body>
							<div className='row'>
								<div className='col-md-8 ml-md-auto'>
									<div className='row'>
										<div className='col-md-4'>
											<div className='row'>
												{isModuleEnabled(userContext, modules.MARINA) && (
													<Loading isLoading={isLoading}>
														<FormField
															name='vessel'
															label='Vessel'
															id={data.id}
															colMd={12}
															inFormDesign={false}>
															<AsyncSelect
																options={
																	data.vessel ? [data.vessel] : []
																}
																placeholder='Search and select vessel'
																value={vessel}
																onChange={vesselOnChange}
																route='vessels'
																field='or[customer.displayName&name]'
																displayKey='name'
																disabled={data.id !== 0}
																customFilters={{ inactive: false }}
															/>
														</FormField>
													</Loading>
												)}
												<Loading isLoading={isLoading}>
													<FormField
														name='discount'
														id={data.id}
														inFormDesign={false}
														label='Invoice Discount'
														colMd={12}>
														<Selects
															value={discount}
															options={discounts.filter(
																d =>
																	d.calculation?.value !==
																	discountCalculations.PER_QUANTITY
															)}
															placeholder='Select a Discount'
															onChange={e => {
																reCalculate.current = true;

																data.discountType = e.target.value
																	? enumCalculations.find(
																			ec =>
																				ec.value ===
																				(e.target.value
																					.calculation
																					?.value ===
																				discountCalculations.PERCENT
																					? discountCalculations.PERCENT
																					: discountCalculations.FIXED)
																	  )
																	: null;

																data.discountAmount = e.target.value
																	? e.target.value.amount
																	: 0;

																discountOnChange(e);
															}}
															disabled={disabled}
														/>
													</FormField>
												</Loading>
											</div>
										</div>
										<div className='col-md-4'>
											<div className='row'>
												<Loading isLoading={isLoading}>
													<FormField
														name='note'
														label='Note'
														id={data.id}
														colMd={12}
														inFormDesign={false}>
														<Textarea
															type='text'
															placeholder='Note'
															value={note}
															onChange={noteOnChange}
															textRow={1}
															disabled={disabled}
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='externalNote'
														label='External Note'
														id={data.id}
														colMd={12}
														inFormDesign={false}>
														<Textarea
															type='text'
															placeholder='External Note'
															value={externalNote}
															onChange={externalNoteOnChange}
															textRow={1}
															disabled={disabled}
														/>
													</FormField>
												</Loading>
											</div>
										</div>
										<div className='col-md-4'>
											<div
												className='row'
												style={{ justifyContent: 'flex-end' }}>
												<Loading isLoading={isLoading}>
													<FormField
														name='taxRate'
														id={data.id}
														inFormDesign={false}
														label='Tax Rate'
														colMd={12}
														valRes={taxRateValRes}
														showValidation={taxRateShowVal}>
														<Selects
															value={taxRate}
															options={taxRates}
															onChange={e => {
																reCalculate.current = true;
																taxRateOnChange(e);
															}}
															disabled={disabled}
															onBlur={setTaxRateShowVal}
															disableClearable
															append={`${
																taxRate ? taxRate.amount : 0
															}%`}
														/>
													</FormField>
												</Loading>
												<Loading isLoading={isLoading}>
													<FormField
														name='customerTaxCode'
														id={data.id}
														inFormDesign={false}
														label='Customer Tax Code'
														colMd={12}
														valRes={customerTaxCodeValRes}
														showValidation={customerTaxCodeShowVal}>
														<Selects
															value={customerTaxCode}
															options={taxCodes}
															onChange={e => {
																customerTaxCodeOnChange(e);
																updateItemTaxCode.current = true;
															}}
															disabled={disabled}
															onBlur={setCustomerTaxCodeShowVal}
															disableClearable
														/>
													</FormField>
												</Loading>
											</div>
										</div>
									</div>
								</div>
								<div className='col-md-4 ml-md-auto'>
									<Alert outline design='dark' marginLess>
										<div className='row sdms-fitText--md sdms-mb-10 '>
											<div className='col'>Subtotal</div>
											<div className='col-auto text-right'>
												<span>{priceFormatter(totals.subtotal)}</span>
											</div>
										</div>
										<div className='row sdms-fitText--md sdms-mb-10'>
											<div className='col'>Tax</div>
											<div className='col-auto text-right'>
												<span>{priceFormatter(totals.taxTotal)}</span>
											</div>
										</div>
										{totals.discountTotal ? (
											<div className='row sdms-fitText--md sdms-mb-10'>
												<div className='col'>Discount</div>
												<div className='col-auto text-right'>
													<span>
														{priceFormatter(totals.discountTotal)}
													</span>
												</div>
											</div>
										) : null}
										<div className='row sdms-fitText--md sdms-mb-10'>
											<div className='col'>Total</div>
											<div className='col-auto text-right'>
												<span>{priceFormatter(totals.total)}</span>
											</div>
										</div>
										<div className='row sdms-fitText--md sdms-mb-10'>
											<div className='col'>Amount Paid</div>
											<div className='col-auto text-right'>
												<span>{priceFormatter(data.amountPaid || 0)}</span>
											</div>
										</div>
										<Separator space='sm' />
										<div className='row sdms-fitText--lg sdms-font-bolder'>
											<div className='col'>Balance Due</div>
											<div className='col-auto text-right'>
												<span>
													{priceFormatter(
														totals.total - (data.amountPaid || 0) > 0
															? totals.total - (data.amountPaid || 0)
															: 0
													)}
												</span>
											</div>
										</div>
									</Alert>
								</div>
							</div>
						</Portlet.Body>
					</Portlet>
				</Portlet.Body>
			</Portlet>
			{invoiceItemDeferredSchedule !== null && (
				<InvoiceItemDeferredSchedule
					key={invoiceItemDeferredScheduleKey}
					invoice={data}
					invoiceItem={data.invoiceItems.find(i => i.id === invoiceItemDeferredSchedule)}
					onCancel={() => setInvoiceItemDeferredSchedule(null)}
					onSave={(
						enableDeferredIncome,
						deferredIncomeAccount,
						deferredIncomeSalesAccount,
						invoiceItemDeferredIncomes
					) => {
						onFormChange();

						const invoiceItemIndex = data.invoiceItems.findIndex(
							i => i.id === invoiceItemDeferredSchedule
						);

						if (invoiceItemIndex === -1) return;

						data.invoiceItems[
							invoiceItemIndex
						].enableDeferredIncome = enableDeferredIncome;

						data.invoiceItems[
							invoiceItemIndex
						].deferredIncomeAccount = deferredIncomeAccount;

						data.invoiceItems[
							invoiceItemIndex
						].deferredIncomeSalesAccount = deferredIncomeSalesAccount;

						invoiceItemDeferredIncomes.forEach(iidi => {
							const itemDeferredIncomeIndex = data.invoiceItems[
								invoiceItemIndex
							].itemDeferredIncomes.findIndex(idi => idi.id === iidi.id);

							if (itemDeferredIncomeIndex > -1) {
								data.invoiceItems[invoiceItemIndex].itemDeferredIncomes[
									itemDeferredIncomeIndex
								].amount = iidi.amount === '' ? 0 : parseFloat(iidi.amount);
							} else if (iidi.amount) {
								const invoiceDeferredIncome = data.deferredIncomes.find(
									di => di.id === iidi.deferredIncomeId
								);

								if (invoiceDeferredIncome)
									data.invoiceItems[invoiceItemIndex].itemDeferredIncomes.push({
										...iidi,
										amount: parseFloat(iidi.amount),
										invoiceDeferredIncome,
									});
							}
						});

						setInvoiceItemDeferredSchedule(null);
					}}
					onFormChange={onFormChange}
					accounts={accounts}
				/>
			)}
		</>
	);
};

InvoiceForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		customer: PropTypes.object,
		discount: PropTypes.object,
		discountAmount: PropTypes.number,
		discountType: PropTypes.object,
		discountTotal: PropTypes.number,
		subtotal: PropTypes.number,
		taxTotal: PropTypes.number,
		total: PropTypes.number,
		amountPaid: PropTypes.number,
		note: PropTypes.string,
		externalNote: PropTypes.string,
		invoiceItems: PropTypes.arrayOf(PropTypes.object),
		taxRate: PropTypes.object,
		outlet: PropTypes.object,
		invoiceId: PropTypes.string,
		dueDate: PropTypes.string,
		paymentTerm: PropTypes.object,
		autoPay: PropTypes.bool,
		tip: PropTypes.number,
		status: PropTypes.object,
		isSalesReceipt: PropTypes.bool,
		holdForSignature: PropTypes.bool,
		registerBatch: PropTypes.object,
		reservation: PropTypes.object,
		fromHouseAccount: PropTypes.object,
		reservationItem: PropTypes.object,
		timeCreated: PropTypes.string,
		invoiceDate: PropTypes.string,
		sdmsOrder: PropTypes.object,
		deferredIncomes: PropTypes.arrayOf(PropTypes.object),
		vessel: PropTypes.object,
		type: PropTypes.object,
		accountReceivableAccount: PropTypes.object,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	isListLoading: PropTypes.bool,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
	discounts: PropTypes.arrayOf(PropTypes.object),
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	enumCalculations: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	taxRates: PropTypes.arrayOf(PropTypes.object),
	enumInvoiceStatuses: PropTypes.arrayOf(PropTypes.object),
	accounts: PropTypes.arrayOf(PropTypes.object),
	acctClasses: PropTypes.arrayOf(PropTypes.object),
	invoiceTypes: PropTypes.arrayOf(PropTypes.object),
	isEditable: PropTypes.bool,
	shouldCustomerInputDisabled: PropTypes.bool,
	isQuickPanel: PropTypes.bool,
};

InvoiceForm.defaultProps = {
	data: {
		id: 0,
		invoiceItems: [],
	},
	isSubmitted: false,
	setIsValid: () => {},
	isLoading: false,
	isListLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
	discounts: [],
	paymentTerms: [],
	enumCalculations: [],
	taxCodes: [],
	taxRates: [],
	enumInvoiceStatuses: [],
	accounts: [],
	acctClasses: [],
	invoiceTypes: [],
	isEditable: true,
	shouldCustomerInputDisabled: false,
	isQuickPanel: false,
};

export default InvoiceForm;
