import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from '../field/Checkbox';
import Dropdown from '../element/Dropdown';
import FormField from './FormField';
import Breadcrumb from '../../Breadcrumb';
import { randomColor } from '../../../utils/helpers/helper';
import Badge from '../element/Badge';
import Button from '../element/Button';
import SVGIcon from '../element/SVGIcon';
import { Item } from '../element/ProductGridElements';

const Loading = ({
	isLoading,
	type,
	colCount,
	children,
	width,
	withImage,
	withCheckbox,
	withActions,
	props,
	onlyGrid,
	height,
	borderRadius,
}) => {
	const tableCells = (_withCheckbox, _withImage, _withActions) => {
		const colExtra = [];
		if (_withImage) {
			colExtra.push('_withImage');
		}
		if (_withActions) {
			colExtra.push('_withActions');
		}

		const tableCellList = [];

		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < colCount - colExtra.length; i++) {
			tableCellList.push(
				<td key={i} data-grid-name={`col-${i === 0 ? 'base' : i}`}>
					<div className='is-loading'>
						<h4 className='is-loading--text'>Loading...</h4>
					</div>
				</td>
			);
		}
		return tableCellList;
	};

	const tableRows = (_withImage, _withCheckbox, _withActions) => {
		const tableRowList = [];

		for (let i = 0; i < 10; i += 1) {
			tableRowList.push(
				<tr key={i}>
					{_withCheckbox && (
						<td className='sdms-list-layout--select' data-grid-name='col-checkbox'>
							<Checkbox
								onChange={() => {}}
								value={false}
								className='sdms-checkbox--solid'
							/>
						</td>
					)}
					{_withImage && (
						<td key='image' data-grid-name={`col-${i}`}>
							<div className='is-loading'>
								<Badge
									design={randomColor()}
									className='sdms-font-transform-u'
									isUnified
									size='lg'
									isRounded
									fontWeight='bold'>
									{' '}
								</Badge>
							</div>
						</td>
					)}
					{tableCells(_withCheckbox, _withImage, _withActions)}
					{_withActions && (
						<td className='text-right' data-grid-name='col-action'>
							<Dropdown
								icon='Other#1'
								color='clean'
								inline
								aligned='right'
								circle
								outline={false}>
								<Dropdown.Header>Other Actions</Dropdown.Header>
								<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
									Delete
								</Dropdown.Item>
							</Dropdown>
						</td>
					)}
				</tr>
			);
		}
		return tableRowList;
	};

	const productGridItems = (c, _onlyGrid) => {
		const list = [];
		for (let i = 0; i < c; i += 1) {
			list.push(
				<Item
					className='is-loading'
					text='Loading'
					onClick={() => {}}
					order={i}
					price={i + 1}
					key={i}
					onlyGrid={_onlyGrid}
				/>
			);
		}
		return list;
	};

	if (isLoading) {
		switch (type) {
			case 'breadcrumb':
				return (
					<Breadcrumb className='is-loading'>
						<Breadcrumb.Item path='/' title='Loading' className='is-loading' />
						<Breadcrumb.Item
							path='/sample'
							title='Loading'
							className='is-loading'
							isActive
						/>
					</Breadcrumb>
				);
			case 'list':
				return <tbody>{tableRows(withImage, withCheckbox, withActions)}</tbody>;
			case 'formField':
				return (
					<FormField
						name='loading'
						label={children.props.label}
						col={children.props.col}
						colSm={children.props.colSm}
						colMd={children.props.colMd}
						colLg={children.props.colLg}
						colXl={children.props.colXl}
						className={classNames(children.props.className, 'is-loading')}
						description={children.props.description}
						isLast={children.props.isLast}
						inFormDesign={children.props.inFormDesign}>
						{children.props.children
							? React.cloneElement(
									React.cloneElement(children.props.children, {
										className: 'sdms-pointer-events--none',
										onClick: () => {},
										onChange: () => {},
									}),
									{
										onClick: () => {},
										onChange: () => {},
									}
							  )
							: null}
					</FormField>
				);
			case 'media':
				return (
					<div className='sdms-image sdms-image--item sdms-image--outline sdms-image--changed col-3 is-loading'>
						<div
							className='sdms-image--name is-loading--text'
							style={{ width: '50%' }}
						/>
						<div className='sdms-image__holder is-loading--image' title='Uploading' />
						<span className='sdms-image__cancel' title='Delete Image'>
							<SVGIcon name='Error-circle' size={30} />
						</span>
					</div>
				);
			case 'pagination':
				return (
					<div className='col-12 is-loading'>
						<div className='sdms-pagination'>
							<ul className='sdms-pagination__links'>
								<li>
									<span />
								</li>
								<li>
									<span />
								</li>
								<li>
									<span />
								</li>
								<li>
									<span />
								</li>
								<li>
									<span />
								</li>
								<li>
									<span />
								</li>
								<li>
									<span />
								</li>
								<li>
									<span />
								</li>
							</ul>
							<div className='sdms-pagination__toolbar'>
								<select disabled className='form-control sdms-font-brand'>
									<option>10</option>
								</select>
								<span
									style={{ width: 120 }}
									className='pagination__desc is-loading--span is-loading'>
									Loading
								</span>
							</div>
						</div>
					</div>
				);
			case 'button':
				return (
					<Button
						className={classNames('is-loading', children && children.props.className)}
						// eslint-disable-next-line react/prop-types
						design={(children && children.props.design) || props.design}
						// eslint-disable-next-line react/prop-types
						label={(children && children.props.label) || props.label}
						// eslint-disable-next-line react/prop-types
						icon={(children && children.props.icon) || props.icon}
						// eslint-disable-next-line react/prop-types
						size={(children && children.props.size) || props.size}
						iconSize={children ? children.props.iconSize : null}
						block={children && children.props.block}
						outline={children && children.props.outline}
						disabled>
						<span>Loading...</span>
					</Button>
				);
			case 'bold':
				return (
					<b
						style={{ width }}
						className={classNames(
							'is-loading--b',
							'is-loading',
							children.props.className
						)}>
						Loading
					</b>
				);
			case 'div':
				return (
					<div
						style={{ width, height, borderRadius }}
						className={classNames(
							'is-loading--div',
							'is-loading',
							children.props.className
						)}>
						Loading
					</div>
				);
			case 'span':
				return (
					<span
						style={{ width }}
						className={classNames(
							'is-loading--span',
							'is-loading',
							children.props.className
						)}>
						Loading
					</span>
				);
			case 'product':
				return productGridItems(12, onlyGrid);
			case 'initial':
			case 'title':
			default:
				return (
					<div className='is-loading'>
						<h4 className='is-loading--text' style={{ width }}>
							Loading...
						</h4>
					</div>
				);
		}
	}
	return <>{children}</>;
};
Loading.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	type: PropTypes.oneOf([
		'breadcrumb',
		'list',
		'formField',
		'media',
		'pagination',
		'button',
		'bold',
		'div',
		'span',
		'product',
		'title',
		'initial',
	]),
	colCount: PropTypes.number,
	children: PropTypes.node,
	width: PropTypes.number,
	withImage: PropTypes.bool,
	withCheckbox: PropTypes.bool,
	withActions: PropTypes.bool,
	props: PropTypes.shape({
		label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		icon: PropTypes.string,
		size: PropTypes.string,
	}),
	onlyGrid: PropTypes.bool,
	height: PropTypes.number,
	borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
Loading.defaultProps = {
	type: 'formField',
	colCount: 1,
	children: null,
	width: null,
	withImage: false,
	withCheckbox: true,
	withActions: true,
	props: { label: null, icon: null, size: null },
	onlyGrid: false,
	height: null,
	borderRadius: null,
};

export default Loading;
