import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { required } from '../../../utils/helpers/validation';
import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';

import Portlet from '../../reusables/layout/Portlet';
import Section from '../../reusables/layout/Section';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Button from '../../reusables/element/Button';
import useField from '../../../utils/hooks/useField';
import Loading from '../../reusables/template/Loading';
import Selects from '../../reusables/field/Selects';
import Toggle from '../../reusables/field/Toggle';
import { templateTypes } from '../../../utils/constants/constants';

const ContractForm = ({
	data,
	setIsValid,
	submit,
	isSubmitted,
	templates,
	isLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
}) => {
	const headerContext = useContext(HeaderContext);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [expirationDate, expirationDateOnChange] = useField(
		data,
		'expirationDate',
		onFormChange,
		[],
		false
	);

	const [emailTemplate, emailTemplateOnChange] = useField(
		data,
		'emailTemplate',
		onFormChange,
		[],
		null
	);

	const [pdfTemplate, pdfTemplateOnChange] = useField(
		data,
		'pdfTemplate',
		onFormChange,
		[],
		null
	);

	const [eSignEmailTemplate, eSignEmailTemplateOnChange] = useField(
		data,
		'eSignEmailTemplate',
		onFormChange,
		[],
		null
	);

	const [eSignTemplate, eSignTemplateOnChange] = useField(
		data,
		'eSignTemplate',
		onFormChange,
		[],
		null
	);

	const [autoSend, autoSendOnChange] = useField(data, 'autoSend', onFormChange, [], false);

	const [confirmationEsignTemplate, confirmationEsignTemplateOnChange] = useField(
		data,
		'confirmationEsignTemplate',
		onFormChange,
		[],
		null
	);

	const [confirmationAttachTemplate, confirmationAttachTemplateOnChange] = useField(
		data,
		'confirmationAttachTemplate',
		onFormChange,
		[],
		false
	);

	const [confirmationCopyCustomer, confirmationCopyCustomerOnChange] = useField(
		data,
		'confirmationCopyCustomer',
		onFormChange,
		[],
		false
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
		}
	}, [isSubmitted, setNameShowVal]);

	useEffect(() => {
		setIsValid(nameValRes.isValid);
	}, [setIsValid, nameValRes.isValid]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.systemSettings.default.text, path: pages.systemSettings.dashboard.path },
			{
				title: pages.systemSettings.contracts.text,
				path: pages.systemSettings.contracts.path,
			},
			{ title: name || `New ${pages.systemSettings.contracts.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.systemSettings.contracts.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid' hasFrame>
			<Portlet.Body>
				<form>
					<Section title='General'>
						<Section.Body>
							<FormGroup>
								<Loading isLoading={isLoading}>
									<FormField
										name='name'
										label='Name'
										id={data.id}
										valRes={nameValRes}
										showValidation={nameShowVal}
										loadingContainer
										colMd={6}>
										<Input
											type='text'
											placeholder='Name (Required)'
											value={name}
											onChange={nameOnChange}
											onBlur={setNameShowVal}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='expirationDate'
										label='Expiration Date'
										id={data.id}
										colMd={3}>
										<Toggle
											spaceLess
											value={expirationDate}
											onChange={expirationDateOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='autoSend'
										label='Auto Send'
										id={data.id}
										colMd={3}>
										<Toggle
											spaceLess
											value={autoSend}
											onChange={autoSendOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='emailTemplate'
										label='Email Template'
										id={data.id}
										colMd={6}>
										<Selects
											options={templates.filter(
												t =>
													t.type.value ===
													templateTypes.PRODUCT_CONTRACT_EMAIL
											)}
											placeholder='Email Template'
											value={emailTemplate}
											onChange={emailTemplateOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='pdfTemplate'
										label='PDF Template'
										id={data.id}
										colMd={6}>
										<Selects
											options={templates.filter(
												t => t.type.value === templateTypes.PRODUCT_CONTRACT
											)}
											placeholder='PDF Template'
											value={pdfTemplate}
											onChange={pdfTemplateOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='eSignEmailTemplate'
										label='E-sign Email Template'
										id={data.id}
										colMd={6}>
										<Selects
											options={templates.filter(
												t => t.type.value === templateTypes.ESIGN_EMAIL
											)}
											placeholder='E-Sign Email Template'
											value={eSignEmailTemplate}
											onChange={eSignEmailTemplateOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='eSignTemplate'
										label='E-sign Template'
										id={data.id}
										colMd={6}>
										<Selects
											options={templates.filter(
												t => t.type.value === templateTypes.ESIGN
											)}
											placeholder='E-Sign Template'
											value={eSignTemplate}
											onChange={eSignTemplateOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='confirmationEsignTemplate'
										label='Confirmation E-sign Template'
										id={data.id}
										colMd={6}>
										<Selects
											options={templates.filter(
												t => t.type.value === templateTypes.ESIGN_EMAIL
											)}
											placeholder='E-Sign Template'
											value={confirmationEsignTemplate}
											onChange={confirmationEsignTemplateOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='confirmationAttachTemplate'
										label='Confirmation Attach Template'
										id={data.id}
										colMd={3}>
										<Toggle
											spaceLess
											value={confirmationAttachTemplate}
											onChange={confirmationAttachTemplateOnChange}
										/>
									</FormField>
								</Loading>
								<Loading isLoading={isLoading}>
									<FormField
										name='confirmationCopyCustomer'
										label='Confirmation Copy Customer'
										id={data.id}
										colMd={3}>
										<Toggle
											spaceLess
											value={confirmationCopyCustomer}
											onChange={confirmationCopyCustomerOnChange}
										/>
									</FormField>
								</Loading>
							</FormGroup>
						</Section.Body>
					</Section>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames('sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
ContractForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		template: PropTypes.object,
	}),
	templates: PropTypes.arrayOf(PropTypes.object),
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
};
ContractForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		template: {},
	},
	templates: [],
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
};

export default ContractForm;
