import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import classNames from 'classnames';

import { required } from '../../../utils/helpers/validation';
import {
	addErrorNotification,
	addSuccessNotification,
	dateFormatter,
} from '../../../utils/helpers/helper';
import useField from '../../../utils/hooks/useField';
import apiCall from '../../../utils/helpers/apiCall';

import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import Portal from '../layout/Portal';
import Selects from '../field/Selects';
import FormField from '../template/FormField';
import List from '../template/List';
import Dropdown from '../element/Dropdown';
import FormGroup from '../layout/FormGroup';
import SVGIcon from '../element/SVGIcon';

const popupContentTypes = {
	LIST: 'list',
	ADD: 'add',
};

const AttachmentModal = ({ entity, onClose, isOpen }) => {
	const [content, setContent] = useState(popupContentTypes.LIST);

	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					width: '640px',
					minHeight: '480px',
					height: '50%',
				}}>
				{content === popupContentTypes.LIST ? (
					<AttachmentModalList
						onClose={onClose}
						onSubmit={onClose}
						onAdd={() => setContent(popupContentTypes.ADD)}
						entity={entity}
					/>
				) : (
					<AttachmentModalAdd
						onClose={onClose}
						onBack={() => setContent(popupContentTypes.LIST)}
						entity={entity}
					/>
				)}
			</Popup>
		</Portal>
	);
};

AttachmentModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	entity: PropTypes.object,
	onClose: PropTypes.func,
	isOpen: PropTypes.bool,
};
AttachmentModal.defaultProps = {
	onClose: () => {},
	isOpen: false,
	entity: null,
};

const AttachmentListNameCell = ({ data }) => {
	const [url, setUrl] = useState('#');

	useEffect(() => {
		fetch(data.content)
			.then(res => res.blob())
			.then(blob => setUrl(URL.createObjectURL(blob)));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<a href={url} target='_blank' rel='noopener noreferrer'>
			{data.fileName}
		</a>
	);
};

AttachmentListNameCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};
AttachmentListNameCell.defaultProps = {
	data: null,
};
const AttachmentListDateCell = ({ data }) => {
	return dateFormatter(data.timeCreated);
};

AttachmentListDateCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};
AttachmentListDateCell.defaultProps = {
	data: null,
};

const AttachmentModalList = ({ entity, onClose, onAdd }) => {
	const [isLoading, setIsLoading] = useState(true);

	const [attachments, setAttachments] = useState([]);

	const onLoad = async () => {
		setIsLoading(true);

		await apiCall(
			'GET',
			'attachments',
			res => {
				setAttachments(res);
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			null,
			{
				entityClass: `App\\Entity\\${entity['@type']}`,
				entityId: entity.id,
			}
		);
	};

	const onDelete = async ({ id, fileName }) => {
		await apiCall(
			'DELETE',
			'attachments',
			() => {
				addSuccessNotification(`${fileName} deleted successfully`);
			},
			err => {
				addErrorNotification(err.toString());
			},
			id
		);

		await onLoad();
	};

	useEffect(() => {
		if (entity['@type'] && entity.id) onLoad();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entity]);

	return (
		<Portlet className='sdms-list-layout' fluid='fluid' isLast everyTimeFluid>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Attachment#1'>
					Attachments
				</Portlet.HeadLabelTitle>
				<Portlet.HeadToolbarActions>
					<Button
						design='brand'
						text='Upload Attachment'
						icon='Upload'
						size='sm'
						onClick={onAdd}
					/>
				</Portlet.HeadToolbarActions>
			</Portlet.Head>
			<List
				data={attachments}
				isLoading={isLoading}
				fluid='fluid'
				listName='attachmentsListName'
				withOutPortlet
				hasPagination={false}
				actions={{
					onDelete,
				}}>
				<List.Col label='Name' cellComponent={<AttachmentListNameCell />} isLinkColumn />
				<List.Col label='Type' cellData='name' cellDataObject='type' />
				<List.Col label='Date' cellComponent={<AttachmentListDateCell />} />
				<List.Col onlyHover align='right' key='actions' width={50}>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item
							itemsColor='danger'
							icon='Trash'
							text='Delete'
							key='onDelete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
			<Portlet.Foot
				tall='sm'
				className='sdms-align-left'
				subClassName='justify-content-between'>
				<div className='col-auto'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};

AttachmentModalList.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	entity: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	onAdd: PropTypes.func.isRequired,
};

AttachmentModalList.defaultProps = {};

const AttachmentModalAdd = ({ entity, onClose, onBack }) => {
	const [attachmentTypes, setAttachmentTypes] = useState([]);

	const [isLoading, setIsLoading] = useState(true);

	const [isUploading, setIsUploading] = useState(false);

	const [type, typeOnChange] = useField({}, 'type', () => {}, [], null);

	const [file, fileOnChange] = useField({}, 'file', () => {}, [required], null);

	useEffect(() => {
		apiCall(
			'GET',
			'attachmentTypes',
			res => {
				setAttachmentTypes(res);
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			null
		);
	}, []);

	const onUpload = () => {
		if (!file) return;

		setIsUploading(true);

		const formData = new FormData();

		if (type) formData.append('type', type.name);
		formData.append('file', file);
		formData.append('entity', entity['@id']);

		apiCall(
			'POST',
			'attachments',
			() => {
				setIsUploading(false);
				onBack();
			},
			err => {
				addErrorNotification(err.toString());
				setIsUploading(false);
			},
			'',
			formData
		);
	};

	return (
		<Portlet fluid='fluid' isLast everyTimeFluid>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Attachment#1'>
					Add Attachment
				</Portlet.HeadLabelTitle>
				<Portlet.HeadToolbarActions>
					<Button
						design='brand'
						text='Back'
						icon='Arrow-left'
						size='sm'
						onClick={onBack}
					/>
				</Portlet.HeadToolbarActions>
			</Portlet.Head>
			<Portlet.Body>
				<FormGroup row>
					<FormField name='type' label='Type' id={1} colMd={6}>
						<Selects
							options={attachmentTypes.filter(
								at =>
									entity['@type'] &&
									entity['@type'].search(at.attachmentEntity.value) > -1
							)}
							placeholder='Type (Optional)'
							value={type}
							onChange={typeOnChange}
							displayKey='name'
							sortKey='name'
						/>
					</FormField>
					<div className='col-md-6'>
						<label
							style={{ marginTop: '25px' }}
							htmlFor='add_image'
							className={classNames(
								'btn',
								'btn-elevate',
								'btn-label-brand',
								'btn-hover-brand',
								'btn-sm',
								'sdms-image__upload--button',
								{ disabled: isUploading }
							)}>
							<SVGIcon name='Upload' />
							<span>{file ? file.name : 'Select'}</span>
							<input
								id='add_image'
								type='file'
								name='file'
								accept='.png, .jpg, .jpeg, .pdf, .doc, .docx, .xlsx'
								onChange={e =>
									fileOnChange({ target: { value: e.target.files[0] } })
								}
							/>
						</label>
					</div>
				</FormGroup>
			</Portlet.Body>
			<Portlet.Foot
				tall='sm'
				className='sdms-align-left'
				subClassName='justify-content-between'>
				<div className='col-auto'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
						disabled={isUploading || isLoading}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='brand'
						icon='Upload'
						text='Upload'
						size='sm'
						onClick={onUpload}
						disabled={isUploading || isLoading}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};

AttachmentModalAdd.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	entity: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	onBack: PropTypes.func.isRequired,
};

export default AttachmentModal;
