import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import forms from '../../forms';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import { dateFormatter } from '../../../utils/helpers/helper';

const UserShiftTimeCell = ({ data, type, onClick, noPermission }) => {
	const date = () => {
		if (type === 'startTime') return dateFormatter(data.startTime);
		if (type === 'endTime' && data.endTime !== null) return dateFormatter(data.endTime);
		return '';
	};
	return (
		<div onClick={noPermission ? onClick : null} role='presentation'>
			<span
				className={classNames({
					'sdms-link sdms-link--dark': noPermission,
				})}>
				{date()}
			</span>
		</div>
	);
};
UserShiftTimeCell.propTypes = {
	data: PropTypes.shape({
		startTime: PropTypes.string,
		endTime: PropTypes.string,
	}),
	type: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	noPermission: PropTypes.bool,
};
UserShiftTimeCell.defaultProps = {
	data: {
		startTime: '',
		endTime: '',
	},
	onClick: () => {},
	noPermission: null,
};

const PayrollHoursList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.accounting.default.text, path: pages.accounting.dashboard.path },
			{
				title: pages.accounting.payrollHours.text,
				path: pages.accounting.payrollHours.path,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(pages.accounting.payrollHours.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='userShifts'
			title={pages.accounting.payrollHours.text}
			history={history}
			forms={forms.accounting.payrollHours}
			icon={pages.accounting.payrollHours.icon}>
			<List withCheckBox fluid='fluid'>
				<List.Col
					label='User'
					cellDataObject='user'
					cellData='displayName'
					isLinkColumn
					sortable='user.displayName'
					responsiveBase
				/>
				<List.Col
					label='Start Time'
					cellComponent={<UserShiftTimeCell type='startTime' />}
					isLinkColumn
					sortable='startTime'
				/>
				<List.Col
					label='End Time'
					cellComponent={<UserShiftTimeCell type='endTime' />}
					isLinkColumn
					sortable='endTime'
				/>
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Erase' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
PayrollHoursList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default PayrollHoursList;
