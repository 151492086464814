import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import useField from '../../../utils/hooks/useField';
import { numeric } from '../../../utils/helpers/validation';
import { paymentTypes } from '../../../utils/constants/constants';
import usePages from '../../../utils/hooks/usePages';

import Portal from '../layout/Portal';
import Portlet from '../layout/Portlet';
import FormField from '../template/FormField';
import Input from '../field/Input';
import Button from '../element/Button';

const OpeningCashModal = ({ isOpen, onClose, onSubmit, registers }) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<OpeningCashModalContent
					onClose={onClose}
					onSubmit={onSubmit}
					registers={registers}
				/>
			</Popup>
		</Portal>
	);
};

OpeningCashModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	registers: PropTypes.arrayOf(PropTypes.object),
};

OpeningCashModal.defaultProps = {
	isOpen: false,
	onClose: () => {},
	onSubmit: () => {},
	registers: [],
};

const OpeningCashInput = ({ defaultValue, onChange, isFirst, submitted, id, label }) => {
	const [
		openingCash,
		openingCashOnChange,
		openingCashValRes,
		openingCashShowVal,
		setOpeningCashShowVal,
	] = useField({ openingCash: defaultValue }, 'openingCash', () => {}, [numeric]);

	const openingCashInputRef = createRef();

	useEffect(() => {
		if (openingCashInputRef.current && isFirst) openingCashInputRef.current.focus();
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [openingCashInputRef]);

	useEffect(() => {
		setOpeningCashShowVal(true);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [submitted]);

	useEffect(() => {
		onChange(id, openingCash, openingCashValRes.isValid);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [openingCash, openingCashValRes.isValid]);

	return (
		<FormField
			name='registerOpenCash'
			label={label}
			valRes={openingCashValRes}
			showValidation={openingCashShowVal}>
			<Input
				ref={openingCashInputRef}
				name='registerOpenCash'
				value={openingCash}
				onChange={openingCashOnChange}
				onBlur={setOpeningCashShowVal}
				type='text'
				prependIcon='Dollar'
				pattern={process.env.REACT_APP_PRICE_PATTERN}
			/>
		</FormField>
	);
};

OpeningCashInput.propTypes = {
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onChange: PropTypes.func.isRequired,
	isFirst: PropTypes.bool.isRequired,
	submitted: PropTypes.bool.isRequired,
	id: PropTypes.number.isRequired,
	label: PropTypes.string.isRequired,
};

const OpeningCashModalContent = ({ onClose, onSubmit, registers }) => {
	const pages = usePages();

	const [submitted, setSubmitted] = useState(false);

	const [openingCashes, setOpeningCashes] = useState({});

	const [invalidCashes, setInvalidCashes] = useState([]);

	const onChange = (id, value, isValid) => {
		const _openingCashes = { ...openingCashes };

		_openingCashes[id] = value;

		setOpeningCashes(_openingCashes);

		const i = invalidCashes.indexOf(id);

		if (isValid && i > -1) setInvalidCashes(invalidCashes.filter(_i => _i !== id));

		if (!isValid && i === -1) {
			const _invalidCashes = [...invalidCashes];
			_invalidCashes.push(id);
			setInvalidCashes(_invalidCashes);
		}
	};

	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon={pages.pos.registers.icon}>
					Open Register
				</Portlet.HeadLabelTitle>
			</Portlet.Head>
			{registers.filter(
				r => r.paymentTypes.findIndex(pt => pt.value === paymentTypes.CASH) > -1
			).length > 0 && (
				<Portlet.Body>
					{registers
						.filter(
							r => r.paymentTypes.findIndex(pt => pt.value === paymentTypes.CASH) > -1
						)
						.map((r, i) => (
							<OpeningCashInput
								key={r.id}
								label={r.name}
								onChange={onChange}
								submitted={submitted}
								defaultValue={r.defaultOpeningCash || ''}
								id={r.id}
								isFirst={i === 0}
							/>
						))}
				</Portlet.Body>
			)}
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
				<div className='col-auto'>
					<Button
						label='brand'
						icon={submitted ? 'Other#2' : 'Unlock'}
						text='Open'
						size='sm'
						disabled={submitted}
						onClick={() => {
							setSubmitted(true);
							if (invalidCashes.length === 0) onSubmit(openingCashes);
						}}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};

OpeningCashModalContent.propTypes = {
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	registers: PropTypes.arrayOf(PropTypes.object),
};

OpeningCashModalContent.defaultProps = {
	registers: [],
};

export default OpeningCashModal;
