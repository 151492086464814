import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Input, { InputGroup, InputPend } from './Input';

const LengthInputGroup = ({
	ft,
	ftOnChange,
	inch,
	inchOnChange,
	placeHolder,
	onBlur,
	onAnyChange,
	disabled,
	isValid,
	showValidation,
	withInch,
}) => {
	return (
		<InputGroup className={classNames({ 'is-invalid': showValidation && !isValid })}>
			<Input
				type='number'
				withOutSpin
				min={0}
				placeholder={placeHolder}
				value={ft || ''}
				onChange={e => {
					onAnyChange();
					ftOnChange(e.target.value);
				}}
				pattern={process.env.REACT_APP_INTEGER_PATTERN}
				onBlur={() => onBlur()}
				disabled={disabled}
			/>
			<InputPend text='ft' isAppend />
			{withInch && (
				<>
					<Input
						type='number'
						withOutSpin
						min={0}
						placeholder={placeHolder}
						value={inch || ''}
						onChange={e => {
							onAnyChange();
							inchOnChange(e.target.value);
						}}
						pattern={process.env.REACT_APP_INTEGER_PATTERN}
						onBlur={() => onBlur()}
						disabled={disabled}
					/>
					<InputPend text='in' isAppend />
				</>
			)}
		</InputGroup>
	);
};
LengthInputGroup.propTypes = {
	ft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	ftOnChange: PropTypes.func,
	inch: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	inchOnChange: PropTypes.func,
	placeHolder: PropTypes.string,
	onBlur: PropTypes.func,
	onAnyChange: PropTypes.func,
	disabled: PropTypes.bool,
	isValid: PropTypes.bool,
	showValidation: PropTypes.bool,
	withInch: PropTypes.bool,
};
LengthInputGroup.defaultProps = {
	ft: '',
	ftOnChange: () => {},
	inch: '',
	inchOnChange: () => {},
	placeHolder: '',
	onBlur: () => {},
	onAnyChange: () => {},
	disabled: false,
	isValid: false,
	showValidation: false,
	withInch: true,
};

export default LengthInputGroup;
