import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../field/Checkbox';
import UserContext from '../../../app/contexts/UserContext';
import {
	ItemCellActions,
	ItemCellCheckBox,
	ItemCellName,
	ItemCellPrice,
	ItemCellQuantity,
	ItemRow,
	ItemRowDescription,
	ItemRowNote,
	PayButton,
	PaymentItem,
	PaymentsTitle,
} from './Order';
import {
	canVoidRefund,
	dateFormatter,
	priceFormatter,
	getSettlementAmountWithoutFee,
} from '../../../utils/helpers/helper';
import { canVoidPayment } from '../../../utils/helpers/paymentHelper';
import {
	bookingPeriods,
	invoiceStatuses,
	paymentStatuses,
	reservationStatuses,
} from '../../../utils/constants/constants';
import Dropdown from './Dropdown';
import Portlet from '../layout/Portlet';
import Button from './Button';
import Loading from '../template/Loading';
import SVGIcon from './SVGIcon';
import ReservationStatusDropdown from './ReservationStatusDropdown';
import {
	getItemRefundableAmount,
	getReservationSettlements,
	getReservationTotalsByInvoices,
} from '../../../utils/helpers/reservationHelper';
import {
	hasEditLockedReservationPermission,
	hasRefundReservationPaymentPermission,
	hasVoidReservationPaymentPermission,
} from '../../../utils/helpers/permission';

const ReservationTable = ({ children }) => {
	return <table className='table sdms-t-table'>{children}</table>;
};
ReservationTable.propTypes = {
	children: PropTypes.node.isRequired,
};

const ReservationHead = ({
	className,
	children,
	isAllSelected,
	isMultiActionsEnabled,
	onCheckAll,
	hasActions,
	hideCheckbox,
}) => {
	return (
		<thead className={className}>
			<tr>
				{!hideCheckbox && (
					<th className='sdms-t-orders--item__check'>
						<Checkbox
							onChange={() => onCheckAll(!isAllSelected)}
							value={isAllSelected}
						/>
					</th>
				)}
				{isMultiActionsEnabled ? (
					<th colSpan={hasActions ? 4 : 3} className='sdms-t-orders--item__all-actions'>
						{children}
					</th>
				) : (
					<>
						<th className='sdms-t-orders--item__name'>Items</th>
						<th className='sdms-t-orders--item__quantity'>Quantity</th>
						<th className='sdms-t-orders--item__price'>Price</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						{hasActions && <th className='sdms-t-orders--item__actions' />}
					</>
				)}
			</tr>
		</thead>
	);
};
ReservationHead.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	isAllSelected: PropTypes.bool,
	isMultiActionsEnabled: PropTypes.bool,
	onCheckAll: PropTypes.func,
	hasActions: PropTypes.bool,
	hideCheckbox: PropTypes.bool,
};
ReservationHead.defaultProps = {
	className: null,
	children: null,
	isAllSelected: false,
	isMultiActionsEnabled: false,
	onCheckAll: () => {},
	hasActions: true,
	hideCheckbox: false,
};

const ReservationBody = ({ className, children }) => {
	return (
		<>
			<ReservationHead hideCheckbox />
			<tbody className={className}>{children}</tbody>
		</>
	);
};
ReservationBody.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
ReservationBody.defaultProps = {
	className: null,
};

const ReservationItem = ({
	className,
	description,
	disabled,
	isActive,
	name,
	unitName,
	price,
	quantity,
	onEdit,
	note,
	status,
	onCheckIn,
	onCheckOut,
	onCancel,
	isRefundable,
	isSelected,
	onSelect,
	hideCheckbox,
	isLocked,
	onConfirm,
	onReserve,
	onWaitList,
	onRefund,
	onItemUpdateCustomStatus,
	customReservationStatus,
	productCustomReservationStatuses,
	cancellationReason,
	cancellationTime,
}) => {
	return (
		<>
			<ItemRow
				className={className}
				disabled={disabled}
				isActive={isActive}
				printStatus={status.value}>
				<ItemCellCheckBox
					hideCheckbox={hideCheckbox}
					onChange={onSelect}
					value={isSelected}
					disabled={disabled || isLocked}
				/>
				<ItemCellName onClick={onEdit}>
					<span className='sdms-font-bold'>{name}</span>
					{unitName && (
						<div className='sdms-font-sm sdms-list-layout__small-text'>
							Unit: {unitName}
						</div>
					)}
				</ItemCellName>
				<ItemCellQuantity>{quantity}</ItemCellQuantity>
				<ItemCellPrice>{priceFormatter(price)}</ItemCellPrice>
				<ItemCellActions
					disabled={
						(status.value === reservationStatuses.CANCELLED && !isRefundable) ||
						isLocked
					}
					title='Item Actions'>
					<ReservationStatusDropdown
						status={status}
						isRefundable
						onEdit={onEdit}
						onConfirm={onConfirm}
						onReserve={onReserve}
						onCheckIn={onCheckIn}
						onCheckOut={onCheckOut}
						onCancel={onCancel}
						onRefund={onRefund}
						onWaitList={onWaitList}
						onItemUpdateCustomStatus={onItemUpdateCustomStatus}
						customReservationStatus={customReservationStatus}
						productCustomReservationStatuses={productCustomReservationStatuses}
					/>
				</ItemCellActions>
			</ItemRow>
			<ItemRowDescription
				description={description}
				isLast={!note}
				hideCheckbox={hideCheckbox}
				printStatus={status.value}
			/>
			{note ? (
				<ItemRowNote
					disabled={disabled}
					isLast
					note={note}
					label='Special Requests'
					hideCheckbox={hideCheckbox}
					printStatus={status.value}
				/>
			) : null}
			{cancellationTime ? (
				<ItemRowNote
					disabled={disabled}
					isLast
					note={dateFormatter(cancellationTime, false)}
					label='Cancellation Date'
					hideCheckbox={hideCheckbox}
					printStatus={status.value}
				/>
			) : null}
			{cancellationReason ? (
				<ItemRowNote
					disabled={disabled}
					isLast
					note={cancellationReason}
					label='Cancellation Reason'
					hideCheckbox={hideCheckbox}
					printStatus={status.value}
				/>
			) : null}
		</>
	);
};
ReservationItem.propTypes = {
	className: PropTypes.string,
	description: PropTypes.string,
	disabled: PropTypes.bool,
	isActive: PropTypes.bool,
	name: PropTypes.string.isRequired,
	unitName: PropTypes.string,
	price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onEdit: PropTypes.func,
	note: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	status: PropTypes.object,
	onCheckIn: PropTypes.func,
	onCheckOut: PropTypes.func,
	onCancel: PropTypes.func,
	isRefundable: PropTypes.bool,
	isSelected: PropTypes.bool,
	onSelect: PropTypes.func,
	hideCheckbox: PropTypes.bool,
	isLocked: PropTypes.bool,
	onConfirm: PropTypes.func,
	onReserve: PropTypes.func,
	onRefund: PropTypes.func,
	onItemUpdateCustomStatus: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	customReservationStatus: PropTypes.object,
	productCustomReservationStatuses: PropTypes.arrayOf(PropTypes.object),
	onWaitList: PropTypes.func,
	cancellationReason: PropTypes.string,
	cancellationTime: PropTypes.string,
};
ReservationItem.defaultProps = {
	className: null,
	description: null,
	disabled: false,
	isActive: false,
	unitName: null,
	onEdit: null,
	note: null,
	status: {
		id: 0,
		value: reservationStatuses.RESERVED,
	},
	onCheckIn: () => {},
	onCheckOut: () => {},
	onCancel: () => {},
	isRefundable: false,
	isSelected: false,
	onSelect: null,
	hideCheckbox: false,
	isLocked: false,
	onConfirm: () => {},
	onReserve: () => {},
	onRefund: () => {},
	onItemUpdateCustomStatus: () => {},
	customReservationStatus: null,
	productCustomReservationStatuses: [],
	onWaitList: () => {},
	cancellationReason: null,
	cancellationTime: null,
};

const Reservation = ({
	data,
	totals,
	isLoading,
	activeItemId,
	openGrid,
	openCustomerModal,
	openPayModal,
	onItemEdit,
	onCancel,
	onSendConfirmationEmail,
	onPrintReservationInvoice,
	onCheckIn,
	onCheckOut,
	onItemCancel,
	onItemRefund,
	refundableAmount,
	selectedItems,
	onItemSelect,
	onItemSelectAll,
	onPaymentPrint,
	onPaymentRefund,
	onPaymentVoid,
	onPaymentCapture,
	onRetain,
	onItemUpdateStatus,
	onItemUpdateCustomStatus,
	modals,
	onSendInvoiceEmail,
	onAutoPayChange,
	onPaymentRefundVoid,
	onExtraChargeClick,
	onExtraChargeRemove,
	addNewUnitButtonDisabled,
	onPaymentScheduleChange,
	onCustomerPaymentMethodChange,
	onAuthorizePayment,
	onEmailReceipt,
	onCaptureAuthorizedPayment,
	onVoidAuthorizedPayment,
	navigateInvoices,
}) => {
	const userContext = useContext(UserContext);

	const getItemQuantity = item => {
		let text = 'Hour';

		if (item.product.bookingPeriod.value === bookingPeriods.DAILY) text = 'Day';

		if (item.product.bookingPeriod.value === bookingPeriods.NIGHTLY) text = 'Night';

		return `${item.quantity} ${text}${item.quantity > 1 ? 's' : ''}`;
	};

	const getItemDescription = item => {
		let description = '';

		const sectionDescriptions = {};

		item.extraCharges.forEach(ec => {
			if (!ec.extraCharge.productCategory) {
				const categoryName = 'Other Options';

				if (!sectionDescriptions[categoryName])
					sectionDescriptions[categoryName] = {
						desc: [],
						total: 0,
					};

				sectionDescriptions[categoryName].desc.push(
					`${ec.extraCharge.externalDescription || ec.extraCharge.name}${
						ec.quantity > 1 ? ` x ${ec.quantity}` : ''
					}`
				);

				sectionDescriptions[categoryName].total += ec.total - ec.tax;
			}
		});

		item.extraCharges.forEach(ec => {
			if (ec.extraCharge.productCategory) {
				const categoryName = ec.extraCharge.productCategory.name;

				if (!sectionDescriptions[categoryName])
					sectionDescriptions[categoryName] = {
						desc: [],
						total: 0,
					};

				sectionDescriptions[categoryName].desc.push(
					`${ec.extraCharge.externalDescription || ec.extraCharge.name}${
						ec.quantity > 1 ? ` x ${ec.quantity}` : ''
					}`
				);

				sectionDescriptions[categoryName].total += ec.total - ec.tax;
			}
		});

		Object.keys(sectionDescriptions).forEach(abbreviation => {
			const t = abbreviation !== '' ? `<b>${abbreviation}:</b>` : '';
			description += `<div><div>${t}${sectionDescriptions[abbreviation].desc.join(
				', '
			)}</div><div>${
				sectionDescriptions[abbreviation].total === 0
					? '-'
					: priceFormatter(sectionDescriptions[abbreviation].total)
			}</div></div>`;
		});
		return description === '' ? null : description;
	};

	const hasNotCancelledItems = useMemo(
		() => data.items.filter(i => i.status.value !== reservationStatuses.CANCELLED).length > 0,
		[data]
	);
	const payments = useMemo(() => {
		// eslint-disable-next-line no-shadow
		const _payments = {};

		getReservationSettlements(data)
			.filter(settlement => !settlement.isRetain)
			.forEach(settlement => {
				const amount = getSettlementAmountWithoutFee(settlement);

				if (settlement.remittance.status.value !== paymentStatuses.DECLINED) {
					if (!_payments[settlement.remittance.id])
						_payments[settlement.remittance.id] = {
							...settlement.remittance,
							paymentMethod: settlement.remittance.paymentMethod || {
								name: 'Credit',
								paymentType: { value: 'Credit' },
							},
							originalAmount: settlement.remittance.amount,
							amount,
							refundableAmount: amount,
							isEditable: settlement.remittance.paymentMethod,
							isDeposit: settlement.isDeposit,
							remittance: settlement.remittance,
						};
					else {
						_payments[settlement.remittance.id].amount += amount;
						_payments[settlement.remittance.id].refundableAmount += amount;
					}
				}
			});

		// add refund to amount.
		Object.entries(_payments).forEach(paymentEntry => {
			const [paymentId, payment] = [...paymentEntry];
			payment.customerSettlements.forEach(cs => {
				if (cs.refund || cs.isRetain || cs.credit) {
					_payments[paymentId].amount += getSettlementAmountWithoutFee({
						...cs,
						remittance: payment.remittance,
					});
				}
			});
		});

		if (data.authorizedPayment) {
			_payments[data.authorizedPayment.id] = {
				...data.authorizedPayment,
				isAuthorizedPayment: true,
				refundableAmount: 0,
			};
		}

		return Object.values(_payments);
	}, [data]);

	const refunds = useMemo(() => {
		const _refunds = [];

		getReservationSettlements(data).forEach(ds => {
			ds.remittance.customerSettlements.forEach(rs => {
				if (rs.refund && _refunds.findIndex(r => r.id === rs.refund.id) === -1) {
					const amount = getSettlementAmountWithoutFee({
						...rs,
						remittance: ds.remittance,
					});

					if (amount)
						_refunds.push({
							...rs.refund,
							amount,
							status: {
								value: paymentStatuses.SETTLED,
							},
							canVoid: canVoidRefund(rs.refund),
							customer: ds.remittance.customer,
						});
				} else if (
					!rs.refund &&
					rs.credit &&
					_refunds.findIndex(r => r.id === rs.credit.id) === -1
				) {
					_refunds.push({
						...rs.credit,
						paymentMethod: {
							name: 'Credit',
							paymentType: { value: 'Credit' },
						},
						status: {
							value: paymentStatuses.SETTLED,
						},
						canVoid: false,
						customer: ds.remittance.customer,
					});
				}
			});
		});

		return _refunds.sort((a, b) => a.id - b.id);
	}, [data]);

	const retains = useMemo(() => {
		const _retains = [];

		getReservationSettlements(data)
			.filter(settlement => settlement.isRetain)
			.forEach(settlement => {
				if (settlement.reservationItem) {
					const retainIndex = _retains.findIndex(
						r =>
							r.reservationItem &&
							r.reservationItem.id === settlement.reservationItem.id
					);

					if (retainIndex === -1)
						_retains.push({
							...settlement.remittance,
							amount: settlement.amount,
							reservationItem: settlement.reservationItem,
							status: { value: paymentStatuses.SETTLED },
							paymentMethod: {
								name: `${settlement.reservationItem.product.name} - ${settlement.reservationItem.unit.name}`,
								paymentType: { value: 'Retain' },
							},
							invoice: null,
						});
					else {
						_retains[retainIndex].amount += settlement.amount;
					}
				} else {
					const retainIndex = _retains.findIndex(
						r => r.invoice && r.invoice.id === settlement.invoice.id
					);

					if (retainIndex === -1)
						_retains.push({
							...settlement.remittance,
							amount: settlement.amount,
							reservationItem: null,
							status: { value: paymentStatuses.SETTLED },
							invoice: settlement.invoice,
							paymentMethod: settlement.remittance?.paymentMethod
								? { ...settlement.remittance.paymentMethod }
								: {
										name: 'Retain',
										paymentType: { value: 'Retain' },
								  },
						});
					else {
						_retains[retainIndex].amount += settlement.amount;
					}
				}
			});

		return _retains;
	}, [data]);

	const otherInvoicesTotal = useMemo(() => getReservationTotalsByInvoices(data, true), [data]);

	return (
		<Portlet fluid='fluid'>
			<Portlet.Head>
				<Portlet.HeadLabelTitle>{`Reservation ${data.reservationId ||
					'New'}`}</Portlet.HeadLabelTitle>
				<Portlet.HeadToolbarActions>
					<Button
						label='info'
						icon='Git#4'
						size='sm'
						onClick={openGrid}
						disabled={addNewUnitButtonDisabled}>
						Add New Unit
					</Button>
					<Dropdown
						icon='Other#1'
						size='sm'
						label='info'
						inline
						aligned='right'
						arrow={false}
						outline={false}
						disabled={
							data.items.length === 0 ||
							(data.isLocked && !hasEditLockedReservationPermission(userContext))
						}>
						<Dropdown.Header>Order Actions</Dropdown.Header>
						{onExtraChargeClick && (
							<Dropdown.Item icon='Component' onClick={onExtraChargeClick}>
								Price Adjustment
							</Dropdown.Item>
						)}
						<Dropdown.Item icon='Mail-box' onClick={onSendConfirmationEmail}>
							Send Confirmation Mail
						</Dropdown.Item>
						{data.invoices.filter(i => i.status.value === invoiceStatuses.PAID)
							.length === 0 && (
							<Dropdown.Item icon='Money' onClick={onAutoPayChange}>
								{`Turn Auto Pay ${data.autoPay ? 'Off' : 'On'}`}
							</Dropdown.Item>
						)}
						{onPaymentScheduleChange && (
							<Dropdown.Item icon='Wallet' onClick={onPaymentScheduleChange}>
								Payment Schedule
							</Dropdown.Item>
						)}
						{onCustomerPaymentMethodChange && (
							<Dropdown.Item icon='Wallet#3' onClick={onCustomerPaymentMethodChange}>
								Payment Method
							</Dropdown.Item>
						)}
						{onAuthorizePayment && (
							<Dropdown.Item icon='Wallet#3' onClick={() => onAuthorizePayment()}>
								Authorize Payment
							</Dropdown.Item>
						)}
						{data.invoices.length > 0 && (
							<Dropdown.Item
								icon='Printer'
								text='Print Invoice'
								key='printInvoice'
								onClick={onPrintReservationInvoice}>
								Print Invoice
							</Dropdown.Item>
						)}
						{data.invoices.length > 0 && (
							<Dropdown.Item
								icon='Mail-box'
								text='Email Invoice'
								key='emailInvoice'
								onClick={onSendInvoiceEmail}>
								Email Invoice
							</Dropdown.Item>
						)}
						{hasNotCancelledItems && (
							<Dropdown.Item
								icon='Error-circle'
								itemsColor='danger'
								onClick={() => onCancel(false)}>
								Cancel
							</Dropdown.Item>
						)}
						{refundableAmount > 0 && (
							<Dropdown.Item
								icon='Error-circle'
								itemsColor='danger'
								onClick={() => onCancel(true)}>
								{hasNotCancelledItems ? 'Cancel & Refund' : 'Refund'}
							</Dropdown.Item>
						)}
					</Dropdown>
				</Portlet.HeadToolbarActions>
			</Portlet.Head>
			<Portlet.Head className='sdms-portlet__head--fit align-items-center'>
				<ReservationTable>
					<ReservationHead
						onCheckAll={onItemSelectAll}
						isAllSelected={
							data.items.length > 0 && data.items.length === selectedItems.length
						}
						isMultiActionsEnabled={
							selectedItems.length > 0 &&
							(!data.isLocked || hasEditLockedReservationPermission(userContext))
						}>
						<Button
							icon='Sign-in'
							className='btn-icon'
							label='success'
							size='sm'
							onClick={() =>
								onCheckIn(
									selectedItems.filter(ri =>
										data.items.find(
											i =>
												i.id === ri &&
												i.status.value === reservationStatuses.CONFIRMED
										)
									)
								)
							}
							disabled={
								isLoading ||
								data.items.filter(
									i =>
										selectedItems.includes(i.id) &&
										i.status.value === reservationStatuses.CONFIRMED
								).length === 0
							}
						/>
						<Button
							icon='Sign-out'
							className='btn-icon'
							label='danger'
							size='sm'
							onClick={() =>
								onCheckOut(
									selectedItems.filter(ri =>
										data.items.find(
											i =>
												i.id === ri &&
												i.status.value === reservationStatuses.CHECKED_IN
										)
									)
								)
							}
							disabled={
								isLoading ||
								data.items.filter(
									i =>
										selectedItems.includes(i.id) &&
										i.status.value === reservationStatuses.CHECKED_IN
								).length === 0
							}
						/>
						<Button
							icon='Error-circle'
							className='btn-icon'
							label='danger'
							size='sm'
							onClick={() =>
								onItemCancel(
									selectedItems.filter(ri =>
										data.items.find(
											i =>
												i.id === ri &&
												i.status.value !== reservationStatuses.CANCELLED
										)
									)
								)
							}
							disabled={
								isLoading ||
								data.items.filter(
									i =>
										selectedItems.includes(i.id) &&
										i.status.value !== reservationStatuses.CANCELLED
								).length === 0
							}
						/>
					</ReservationHead>
				</ReservationTable>
			</Portlet.Head>
			<Portlet.Body className='sdms-portlet__body--fit'>
				<ReservationTable>
					<ReservationBody>
						{data.items.map(i => (
							<ReservationItem
								key={i.id}
								name={i.product.name}
								unitName={i.unit.name}
								quantity={getItemQuantity(i)}
								price={i.subtotal}
								onClick={() => onItemEdit(i)}
								description={getItemDescription(i)}
								product={i.product}
								taxValue={0}
								isActive={i.id === activeItemId}
								onEdit={() => onItemEdit(i.id)}
								note={i.note}
								cancellationReason={i.cancellationReason}
								cancellationTime={i.cancellationTime}
								status={i.status}
								onCheckIn={() => onCheckIn([i.id])}
								onCheckOut={() => onCheckOut([i.id])}
								onCancel={() => onItemCancel([i.id])}
								onRefund={() => onItemRefund([i.id])}
								isRefundable={getItemRefundableAmount(data, [i.id]) > 0}
								isSelected={selectedItems.includes(i.id)}
								onSelect={() => onItemSelect(i.id)}
								isLocked={
									data.isLocked &&
									!hasEditLockedReservationPermission(userContext)
								}
								onConfirm={() =>
									onItemUpdateStatus([i.id], reservationStatuses.CONFIRMED)
								}
								onReserve={() =>
									onItemUpdateStatus([i.id], reservationStatuses.RESERVED)
								}
								onWaitList={() =>
									onItemUpdateStatus([i.id], reservationStatuses.WAITLIST)
								}
								onItemUpdateCustomStatus={statusId =>
									onItemUpdateCustomStatus({
										reservationItemIds: [i.id],
										statusId,
									})
								}
								customReservationStatus={i.customReservationStatus}
								productCustomReservationStatuses={
									i.product.productCustomReservationStatuses
								}
							/>
						))}
						{payments.length > 0 && (
							<>
								<PaymentsTitle isShow title='Payments' hideCheckbox />
								{payments.map(payment => (
									<PaymentItem
										key={payment.id}
										data={payment}
										isLoading={isLoading}
										hideCheckbox
										onPrint={() => onPaymentPrint(payment, false)}
										onRefund={
											payment.refundableAmount > 0 &&
											payment.status.value !== paymentStatuses.SCHEDULED &&
											(!data.isLocked ||
												hasEditLockedReservationPermission(userContext)) &&
											payment.isEditable
												? () =>
														onPaymentRefund(
															payment,
															payment.refundableAmount
														)
												: null
										}
										onVoid={
											(!data.isLocked ||
												hasEditLockedReservationPermission(userContext)) &&
											canVoidPayment(payment)
												? () => onPaymentVoid(payment)
												: null
										}
										isRefundable={hasRefundReservationPaymentPermission(
											userContext
										)}
										isVoidable={hasVoidReservationPaymentPermission(
											userContext
										)}
										refundedAmount={payment.amount - payment.refundableAmount}
										onCapture={
											payment.status.value === paymentStatuses.SCHEDULED
												? () => onPaymentCapture(payment)
												: null
										}
										onEmail={() => {
											onEmailReceipt(payment);
										}}
										onEditAuthorizedPayment={
											payment.isAuthorizedPayment
												? () => onAuthorizePayment(payment)
												: null
										}
										onCaptureAuthorizedPayment={
											payment.isAuthorizedPayment
												? () => onCaptureAuthorizedPayment(payment)
												: null
										}
										onVoidAuthorizedPayment={
											payment.isAuthorizedPayment
												? () => onVoidAuthorizedPayment(payment)
												: null
										}
									/>
								))}
							</>
						)}
						{refunds.length > 0 && (
							<>
								<PaymentsTitle isShow title='Refunds' hideCheckbox />
								{refunds.map(dr => (
									<PaymentItem
										key={dr.id}
										data={dr}
										isLoading={isLoading}
										hideCheckbox
										onPrint={() => onPaymentPrint([dr], true)}
										onPaymentRefundVoid={
											dr.canVoid ? () => onPaymentRefundVoid(dr) : null
										}
									/>
								))}
							</>
						)}
						{retains.length > 0 && (
							<>
								<PaymentsTitle isShow title='Retain' hideCheckbox />
								{retains.map(r => (
									<PaymentItem
										key={r.id}
										data={r}
										isLoading={isLoading}
										hideCheckbox
										hasActions={false}
									/>
								))}
							</>
						)}
						{data.deltaCredits && data.deltaCredits.length > 0 && (
							<>
								<PaymentsTitle isShow title='Delta Credits' hideCheckbox />
								{data.deltaCredits.map(dr => (
									<PaymentItem
										key={dr.id}
										data={{
											...dr,
											paymentMethod: {
												name: 'Credit',
												paymentType: { value: 'Credit' },
											},
											amount:
												dr.status.value === paymentStatuses.VOIDED
													? 0
													: dr.amount,
										}}
										isLoading={isLoading}
										hideCheckbox
										onPrint={() => onPaymentPrint([dr], true)}
										onPaymentRefundVoid={
											dr.canVoid ? () => onPaymentRefundVoid(dr) : null
										}
									/>
								))}
							</>
						)}
					</ReservationBody>
				</ReservationTable>
			</Portlet.Body>
			{data.extraCharges &&
				data.extraCharges.map(extraCharge => (
					<Portlet.Foot tall='sm' className='sdms-bg-fill-info'>
						<div className='col-10'>
							<SVGIcon
								name='Component'
								fill='var(--dark)'
								size={20}
								className='sdms-mr-5'
							/>
							<b>{extraCharge.extraCharge.name}</b>{' '}
							{priceFormatter(extraCharge.total)}
						</div>
						{onExtraChargeRemove && (
							<div className='col-2 sdms-align-right'>
								<b
									style={{ cursor: 'pointer' }}
									onClick={() => {
										if (!isLoading) onExtraChargeRemove(extraCharge);
									}}>
									X
								</b>
							</div>
						)}
					</Portlet.Foot>
				))}
			{data.customerReservationBalance > 0 && (
				<Loading isLoading={isLoading} type='span'>
					<Portlet.Foot tall='sm' className='sdms-bg-fill-warning'>
						<div className='col-12'>
							<SVGIcon
								name='Cart#1'
								fill='var(--dark)'
								size={20}
								className='sdms-mr-5'
							/>
							<b>Other Balance:</b>
							{` $${data.customerReservationBalance}`}
						</div>
					</Portlet.Foot>
				</Loading>
			)}
			{otherInvoicesTotal.total > 0 && (
				<Loading isLoading={isLoading} type='span'>
					<Portlet.Foot tall='sm' className='sdms-bg-fill-info'>
						<div
							className='col-12 sdms-cursor--pointer'
							role='presentation'
							onClick={navigateInvoices}>
							<SVGIcon
								name='Clipboard'
								fill='var(--white)'
								size={20}
								className='sdms-mr-5'
							/>
							<b>Invoice Totals: </b>
							{priceFormatter(otherInvoicesTotal.total)}
						</div>
					</Portlet.Foot>
				</Loading>
			)}
			<Portlet.Foot tall='sm' subClassName='align-items-stretch'>
				<div className='col-xl-6 col-lg-5 col-md-6 col-6'>
					<Loading isLoading={isLoading} type='button'>
						<Button
							className='h-100 sdms-fitText sdms-p0'
							label='dark'
							icon='User'
							iconSize={36}
							block
							disabled={
								data.isLocked && !hasEditLockedReservationPermission(userContext)
							}
							onClick={openCustomerModal}>
							{data.customer ? data.customer.displayName : 'Select Customer'}
						</Button>
					</Loading>
				</div>
				<div className='col-xl-6 col-lg-7 col-md-6 col-6 sdms-pr40'>
					<div className='row sdms-fitText--lg sdms-font-bolder'>
						<div className='col'>Subtotal</div>
						<Loading isLoading={isLoading} type='div'>
							<div className='col-auto text-right'>
								{priceFormatter(totals.subtotal)}
							</div>
						</Loading>
					</div>
					<div className='row sdms-fitText--lg sdms-font-bolder'>
						<div className='col'>TAX</div>
						<Loading isLoading={isLoading} type='div' width={60}>
							<div className='col-auto text-right'>
								{priceFormatter(totals.taxTotal)}
							</div>
						</Loading>
					</div>
				</div>
			</Portlet.Foot>

			<Portlet.Foot border={false} tall='xs'>
				{totals.remainingAmount < 0 && (
					<PayButton
						text='Retain'
						onClick={() => onRetain(Math.abs(totals.remainingAmount))}
						color='success'
						colorOpacity={false}
						icon='Sign-in'
						price={priceFormatter(Math.abs(totals.remainingAmount))}
						disabled={false}
					/>
				)}

				{totals.remainingAmount >= 0 && (
					<PayButton
						text='Deposit'
						onClick={() => openPayModal(modals.DEPOSIT, totals.remainingDepositAmount)}
						color='success'
						colorOpacity={false}
						icon={
							userContext.data.user.company.settings.defaultCreditCard
								? 'Credit-card'
								: 'Dollar'
						}
						disabled={
							totals.remainingDepositAmount <= 0 ||
							(data.isLocked && !hasEditLockedReservationPermission(userContext))
						}
						price={priceFormatter(
							totals.remainingDepositAmount <= 0 ? 0 : totals.remainingDepositAmount
						)}
					/>
				)}
				<PayButton
					text={totals.remainingAmount < 0 ? 'Refund' : 'Pay'}
					onClick={() =>
						openPayModal(
							totals.remainingAmount >= 0 ? modals.PAY : modals.OVERPAYMENT_REFUND,
							Math.abs(totals.remainingAmount)
						)
					}
					color='success'
					colorOpacity={false}
					icon={
						userContext.data.user.company.settings.defaultCreditCard
							? 'Credit-card'
							: 'Dollar'
					}
					disabled={totals.remainingAmount === 0}
					price={priceFormatter(Math.abs(totals.remainingAmount))}
				/>
			</Portlet.Foot>

			<Portlet.Foot
				border={false}
				tall='xs'
				subClassName='justify-content-between sdms-fitText--sm'>
				<div className='col-auto'>
					Created By
					<Loading isLoading={isLoading} type='bold'>
						<b className='sdms-ml-5'>{data.createdBy && data.createdBy.displayName}</b>
					</Loading>
				</div>
				<div className='col-auto'>
					Created
					<Loading isLoading={isLoading} type='bold' width={106}>
						<b className='sdms-ml-5'>{dateFormatter(data.timeCreated)}</b>
					</Loading>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};
Reservation.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		customer: PropTypes.object,
		module: PropTypes.object,
		items: PropTypes.arrayOf(PropTypes.object),
		deposits: PropTypes.arrayOf(PropTypes.object),
		invoices: PropTypes.arrayOf(PropTypes.object),
		timeCreated: PropTypes.string,
		createdBy: PropTypes.object,
		isLocked: PropTypes.bool,
		retains: PropTypes.arrayOf(PropTypes.object),
		reservationId: PropTypes.string,
		autoPay: PropTypes.bool,
		extraCharges: PropTypes.arrayOf(PropTypes.object),
		customerReservationBalance: PropTypes.number,
		paymentSchedule: PropTypes.object,
		customerPaymentMethod: PropTypes.object,
		authorizedPayment: PropTypes.object,
		deltaCredits: PropTypes.arrayOf(PropTypes.object),
	}),
	isLoading: PropTypes.bool,
	openGrid: PropTypes.func,
	openCustomerModal: PropTypes.func,
	onCancel: PropTypes.func,
	onItemEdit: PropTypes.func,
	activeItemId: PropTypes.number,
	openPayModal: PropTypes.func,
	totals: PropTypes.shape({
		subtotal: PropTypes.number,
		taxTotal: PropTypes.number,
		total: PropTypes.number,
		depositTotal: PropTypes.number,
		remainingAmount: PropTypes.number,
		remainingDepositAmount: PropTypes.number,
	}),
	onSendConfirmationEmail: PropTypes.func,
	onPrintReservationInvoice: PropTypes.func,
	onCheckIn: PropTypes.func,
	onCheckOut: PropTypes.func,
	onItemCancel: PropTypes.func,
	onItemRefund: PropTypes.func,
	refundableAmount: PropTypes.number,
	selectedItems: PropTypes.arrayOf(PropTypes.number),
	onItemSelect: PropTypes.func,
	onItemSelectAll: PropTypes.func,
	onPaymentPrint: PropTypes.func,
	onPaymentRefund: PropTypes.func,
	onPaymentVoid: PropTypes.func,
	onPaymentCapture: PropTypes.func,
	onRetain: PropTypes.func,
	onItemUpdateStatus: PropTypes.func,
	onItemUpdateCustomStatus: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	modals: PropTypes.object,
	onSendInvoiceEmail: PropTypes.func,
	onAutoPayChange: PropTypes.func,
	onExtraChargeClick: PropTypes.func,
	onExtraChargeRemove: PropTypes.func,
	onPaymentRefundVoid: PropTypes.func,
	addNewUnitButtonDisabled: PropTypes.bool,
	onPaymentScheduleChange: PropTypes.func,
	onCustomerPaymentMethodChange: PropTypes.func,
	onAuthorizePayment: PropTypes.func,
	onEmailReceipt: PropTypes.func,
	onCaptureAuthorizedPayment: PropTypes.func,
	onVoidAuthorizedPayment: PropTypes.func,
	navigateInvoices: PropTypes.func,
};
Reservation.defaultProps = {
	data: {
		id: 0,
		authorizedPayment: null,
		deltaCredits: [],
	},
	isLoading: false,
	openGrid: () => {},
	onCancel: () => {},
	onItemEdit: () => {},
	activeItemId: null,
	openCustomerModal: () => {},
	openPayModal: () => {},
	totals: {
		subtotal: 0,
		taxTotal: 0,
		total: 0,
		depositTotal: 0,
		remainingAmount: 0,
		dueNow: 0,
	},
	onSendConfirmationEmail: () => {},
	onPrintReservationInvoice: () => {},
	onCheckIn: () => {},
	onCheckOut: () => {},
	onItemCancel: () => {},
	onItemRefund: () => {},
	onPaymentRefundVoid: () => {},
	refundableAmount: 0,
	selectedItems: [],
	onItemSelect: () => {},
	onItemSelectAll: () => {},
	onPaymentPrint: null,
	onPaymentRefund: null,
	onPaymentVoid: null,
	onPaymentCapture: null,
	onRetain: () => {},
	onItemUpdateStatus: () => {},
	onItemUpdateCustomStatus: () => {},
	modals: {},
	onSendInvoiceEmail: () => {},
	onAutoPayChange: () => {},
	onExtraChargeClick: null,
	onExtraChargeRemove: null,
	addNewUnitButtonDisabled: false,
	onPaymentScheduleChange: null,
	onCustomerPaymentMethodChange: null,
	onAuthorizePayment: null,
	onEmailReceipt: () => {},
	onCaptureAuthorizedPayment: () => {},
	onVoidAuthorizedPayment: () => {},
	navigateInvoices: () => {},
};

export default Reservation;
