import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import usePages from '../../../utils/hooks/usePages';
import UserContext from '../../../app/contexts/UserContext';

import OutletForm from './OutletForm';
import FormContainer from '../../reusables/template/FormContainer';

const OutletEdit = ({ match }) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle='Outlet Name'
			dataName='outlets'
			lists={['countries', 'states', 'timezones', 'taxRates', 'paymentGateways']}
			fields={<OutletForm />}
			listUrl={pages.companySettings.company.path}
			afterSubmit={outlet => userContext.updateOutlet(outlet)}
		/>
	);
};

OutletEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default OutletEdit;
