import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import TouchSpin from '../../TouchSpin';
import Loading from '../../../template/Loading';

const NumberOfUnitsInput = ({
	value,
	setValue,
	maxValue,
	isLoading,
	setMultipleSelected,
	disabled,
	isGrid,
}) => {
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		if (!isLoading) {
			setIsActive(false);
			setValue(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	if (isActive)
		return (
			<div className='row'>
				<div className={isGrid ? 'col-2 sdms-pr0' : 'col sdms-pr0'}>
					<Button
						label='danger'
						btnIcon
						icon='Error-circle'
						onClick={() => {
							setIsActive(false);
							setValue(1);
							setMultipleSelected(true);
						}}
					/>
				</div>
				<div className={isGrid ? 'col-10' : 'col-auto'}>
					<TouchSpin
						className='sdms-booking-result-item__reserve-btn'
						onChange={() => {}}
						value={value}
						minusOnClick={() => {
							setValue(value - 1 >= 1 ? value - 1 : value);
						}}
						plusOnClick={() => {
							setValue(value + 1 <= maxValue ? value + 1 : value);
						}}
						disableInput
					/>
				</div>
			</div>
		);

	return (
		<Loading isLoading={isLoading} type='button'>
			<Button
				className='sdms-booking-result-item__reserve-btn'
				design='brand'
				outline
				text='Book Multiple'
				icon='Plus'
				size='sm'
				elevate
				onClick={() => {
					setIsActive(true);
					setValue(2);
					setMultipleSelected();
				}}
				disabled={disabled}
			/>
		</Loading>
	);
};

NumberOfUnitsInput.propTypes = {
	value: PropTypes.number.isRequired,
	setValue: PropTypes.func.isRequired,
	maxValue: PropTypes.number.isRequired,
	isLoading: PropTypes.bool.isRequired,
	setMultipleSelected: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
	isGrid: PropTypes.bool,
};

NumberOfUnitsInput.defaultProps = {
	isGrid: false,
};

export default NumberOfUnitsInput;
