import moment from 'moment';
import update from 'immutability-helper';

import {
	bookBies,
	bookingCalculations,
	bookingPeriods,
	depositTypes,
	filterComponents,
	firstInvoiceOptions,
	invoiceStatuses,
	invoicingFrequencies,
	nextInvoiceOptions,
	paymentStatuses,
	paymentTypes as _paymentTypes,
	reservationReceiptTypes,
	reservationStatuses,
	SELECTED_PRINTER_STORAGE_KEY,
	spaceAssigmentTypes,
} from '../constants/constants';
import {
	addFloats,
	calculateSqft,
	generateId,
	getItemTaxCode,
	getItemTotalTaxAmount,
	getSettlementAmountWithoutFee,
	hasSettlementServiceFee,
	numberFormat,
	subFloats,
	ucEachWordFirst,
} from './helper';
import { modules } from './apiCall';
import { fixInvoiceItems } from './invoiceHelper';
import { getReservationItemManualJournalAmounts } from './journalHelper';

export const getReservationSettlements = reservation => {
	const settlements = [];

	reservation.items.forEach(item => {
		settlements.push(...item.customerSettlements.map(cs => ({ ...cs, reservationItem: item })));
	});

	getReservationInvoices(reservation).forEach(invoice => {
		settlements.push(
			...invoice.customerSettlements
				.filter(cs => !cs.reservationItem)
				.map(cs => {
					if (cs.journal && !cs.remittance)
						return {
							...cs,
							remittance: {
								...cs.journal,
								invoice,
								paymentMethod: {
									name: 'Journal',
									paymentType: { value: 'Journal' },
									icon: { value: 'journal' },
								},
							},
							status: {
								value: paymentStatuses.SETTLED,
							},
						};

					return { ...cs, remittance: { ...cs.remittance, invoice } };
				})
		);
	});

	return settlements;
};

export const getReservationInvoices = (reservation, type = 'all') => {
	if (type === 'reservation') return [...(reservation?.invoices || [])];

	if (type === 'other')
		return [
			...(reservation?.meterReadingInvoices || []),
			...(reservation?.orderSaleInvoices || []),
		];

	return [
		...(reservation?.invoices || []),
		...(reservation?.meterReadingInvoices || []),
		...(reservation?.orderSaleInvoices || []),
	];
};

export const hasReservationInvoice = (reservation, type = 'all') =>
	getReservationInvoices(reservation, type).filter(i => i.status.value !== invoiceStatuses.VOIDED)
		.length > 0;

export const getReservationTotalsByInvoices = (reservation, other = false) => {
	const totals = {
		subtotalWithoutExtraCharges: 0,
		subtotal: 0,
		taxTotal: 0,
		total: 0,
		depositTotal: 0,
		remainingAmount: 0,
		remainingAmountWithFee: 0,
		remainingDepositAmount: 0,
	};

	const invoices = getReservationInvoices(reservation, other ? 'other' : 'all');

	invoices
		.filter(i => i.status.value !== invoiceStatuses.VOIDED)
		.forEach(invoice => {
			const { subtotal, taxTotal, total } = invoice;

			totals.subtotal = addFloats(totals.subtotal, subtotal);

			totals.taxTotal = addFloats(totals.taxTotal, taxTotal);

			fixInvoiceItems(invoice).forEach(invoiceItem => {
				if (invoiceItem.product.isTax) {
					totals.subtotal = subFloats(totals.subtotal, invoiceItem.subtotal);

					totals.taxTotal = addFloats(totals.taxTotal, invoiceItem.subtotal);
				} else if (
					invoiceItem.isDeposit ||
					invoiceItem.product.isDepositProduct ||
					invoiceItem.isFeeItem
				)
					totals.subtotal = subFloats(totals.subtotal, invoiceItem.subtotal);
			});

			totals.total = addFloats(totals.total, total);

			const amountPaid = invoice.customerSettlements.reduce(
				(partialSum, customerSettlement) =>
					addFloats(customerSettlement.amount, partialSum),
				0
			);

			totals.remainingAmount = addFloats(
				totals.remainingAmount,
				subFloats(total, amountPaid)
			);
		});

	let paidSecurityDepositAmount = 0;

	if (!other && totals.remainingAmount <= 0) {
		reservation.items
			.filter(
				i =>
					i.status.value === reservationStatuses.CHECKED_OUT ||
					i.status.value === reservationStatuses.CANCELLED
			)
			.forEach(item => {
				item.customerSettlements
					.filter(cs => cs.isSecurityDeposit && !cs.isRetain)
					.forEach(customerSettlement => {
						paidSecurityDepositAmount = addFloats(
							paidSecurityDepositAmount,
							getSettlementAmountWithoutFee(customerSettlement)
						);
					});

				if (item?.policy?.isSecurityDeposit)
					paidSecurityDepositAmount = subFloats(
						paidSecurityDepositAmount,
						getReservationItemManualJournalAmounts(item).refunded
					);
			});
	}

	totals.subtotal = numberFormat(totals.subtotal);

	totals.taxTotal = numberFormat(totals.taxTotal);

	totals.remainingAmount = numberFormat(
		subFloats(totals.remainingAmount, paidSecurityDepositAmount)
	);

	return totals;
};

export const getDepositDetails = reservation => {
	const details = {
		inapplicable: reservation.invoices.length > 0,
		required: 0,
		paid: 0,
		refunded: 0,
		retained: 0,
		remaining: 0,
		applied: 0,
		applicable: 0,
		credit: 0,
	};

	const hasInvoice = hasReservationInvoice(reservation);

	reservation.items
		.filter(
			i =>
				i.status.value !== reservationStatuses.CANCELLED &&
				i.status.value !== reservationStatuses.WAITLIST
		)
		.forEach(item => {
			const { status, depositAmount } = item;

			const isSecurityDeposit = item?.policy?.isSecurityDeposit;

			const isCheckedOut = status.value === reservationStatuses.CHECKED_OUT;

			if (isSecurityDeposit && !isCheckedOut) {
				details.applicable += depositAmount;

				details.required += depositAmount;
			}

			if (!isSecurityDeposit) {
				if (!hasInvoice) details.applicable += depositAmount;

				details.required += depositAmount;
			}
		});

	const checkedRemittances = [];

	reservation.items.forEach(item => {
		item.customerSettlements
			.filter(settlement => !settlement.isFee && settlement.isDeposit)
			.forEach(settlement => {
				const settlementAmount = getSettlementAmountWithoutFee(settlement);

				details.paid += settlementAmount;

				if (settlement.isRetain) details.retained += settlementAmount;

				if (
					settlement?.remittance?.customerSettlements &&
					checkedRemittances.indexOf(settlement.remittance.id) === -1
				) {
					settlement.remittance.customerSettlements
						.filter(
							_settlement =>
								!_settlement.isFee &&
								(_settlement.credit || _settlement.refund || _settlement.journal)
						)
						.forEach(_settlement => {
							if (_settlement.refund || _settlement.journal)
								details.refunded += _settlement.amount;
							else if (_settlement.credit) details.credit += _settlement.amount;
							details.paid += _settlement.amount;
						});
					checkedRemittances.push(settlement.remittance.id);
				}
			});

		details.refunded = addFloats(
			getReservationItemManualJournalAmounts(item).refunded,
			details.refunded
		);
	});

	getReservationInvoices(reservation).forEach(invoice => {
		details.applied += fixInvoiceItems(invoice)
			.filter(invoiceItem => invoiceItem.isDepositItem && !invoiceItem.isRetainItem)
			.reduce((partialSum, invoiceItem) => partialSum + invoiceItem.subtotal * -1, 0);
	});

	details.remaining = numberFormat(
		details.required +
			details.retained +
			details.refunded +
			details.credit +
			(details.required ? 0 : details.applied) -
			details.paid,
		2
	);

	return details;
};

export const isTransientReservationItem = reservationItem =>
	reservationItem?.product?.bookingPeriod?.value !== bookingPeriods.LONG_TERM &&
	reservationItem?.product?.bookingPeriod?.value !== bookingPeriods.SEASONAL;

export const getReservationTotals = (
	reservation,
	taxRate,
	forceReservation = false,
	excludeExtraChargeId = null
) => {
	const totals = {
		subtotalWithoutExtraCharges: 0,
		subtotal: 0,
		taxTotal: 0,
		total: 0,
		depositTotal: 0,
		remainingAmount: 0,
		remainingAmountWithFee: 0,
		remainingDepositAmount: 0,
		paidDepositAmount: 0,
	};

	if (!reservation || reservation.items.length === 0) return totals;

	if (hasReservationInvoice(reservation) && !forceReservation)
		return getReservationTotalsByInvoices(reservation);

	let taxableAmount = 0;

	let remainingDepositAmount = 0;

	let remainingSecurityDepositAmount = 0;

	reservation.items
		.filter(
			i =>
				i.status.value !== reservationStatuses.CANCELLED &&
				i.status.value !== reservationStatuses.WAITLIST &&
				(isTransientReservationItem(i) ||
					i.status.value !== reservationStatuses.CHECKED_OUT)
		)
		.forEach(item => {
			totals.subtotal += item.subtotal;

			if (item.status.value !== reservationStatuses.CHECKED_OUT) {
				if (item.policy && item.policy.isSecurityDeposit)
					remainingSecurityDepositAmount += item.depositAmount;
				else if (!hasReservationInvoice(reservation)) {
					remainingDepositAmount += item.depositAmount;
				}

				totals.depositTotal += item.depositAmount;
			}

			const itemTaxRate =
				item.taxCode && !item.taxCode.taxable
					? 0
					: getItemTotalTaxAmount(
							{ ...item.product, taxCode: item.taxCode },
							taxRate,
							item.subtotal
					  );

			if (itemTaxRate) {
				// calculate extra tax as separate line.
				if (itemTaxRate > taxRate.amount) {
					totals.taxTotal += numberFormat(
						(item.subtotal * (itemTaxRate - taxRate.amount)) / 100
					);
				}

				taxableAmount = addFloats(taxableAmount, item.subtotal);
			}

			item.extraCharges.forEach(ec => {
				if (ec.extraCharge.isTax) {
					totals.taxTotal += numberFormat(ec.total);
				} else {
					const extraChargeSubtotal = numberFormat(ec.total - ec.tax);

					totals.subtotal += extraChargeSubtotal;

					totals.taxTotal += numberFormat(ec.tax);
				}
			});
		});

	totals.subtotalWithoutExtraCharges = totals.subtotal;

	reservation.items.forEach(reservationItem => {
		const { refunded } = getReservationItemManualJournalAmounts(reservationItem);

		if (reservationItem.policy?.isSecurityDeposit) {
			remainingSecurityDepositAmount = addFloats(remainingSecurityDepositAmount, refunded);
		} else if (!hasReservationInvoice(reservation))
			remainingDepositAmount = addFloats(remainingDepositAmount, refunded);

		totals.paidDepositAmount = subFloats(totals.paidDepositAmount, refunded);
	});

	if (reservation.extraCharges) {
		reservation.extraCharges.forEach(ec => {
			if (excludeExtraChargeId === null || ec.extraCharge.id !== excludeExtraChargeId) {
				const extraChargeSubtotal = numberFormat(ec.total - ec.tax);

				totals.subtotal += extraChargeSubtotal;

				totals.taxTotal += numberFormat(ec.tax);
			}
		});
	}

	(taxRate.taxRates.length === 0 ? [taxRate] : taxRate.taxRates).forEach(_taxRate => {
		totals.taxTotal += numberFormat((taxableAmount * _taxRate.amount) / 100);
	});

	totals.total = numberFormat(totals.subtotal + totals.taxTotal);

	totals.remainingAmount = Math.max(totals.total, remainingDepositAmount);

	reservation.deltaCredits
		.filter(credit => credit.status.value !== paymentStatuses.VOIDED)
		.forEach(credit => {
			totals.remainingAmount = addFloats(totals.remainingAmount, credit.amount);
		});

	getReservationSettlements(reservation)
		.filter(settlement => !settlement.isRetain && !settlement.isFee)
		.forEach(settlement => {
			let settlementAmount = getSettlementAmountWithoutFee(settlement);

			if (settlement.isDeposit) {
				const depositRetainAmount = getDepositRetainAmount(
					settlement.reservationItem,
					settlement.remittance?.id
				);

				if (depositRetainAmount && hasSettlementServiceFee(settlement)) {
					const serviceFee =
						(addFloats(settlement.amount, depositRetainAmount) /
							settlement.remittance.amount) *
						settlement.remittance.serviceFee;

					settlementAmount = subFloats(settlement.amount, serviceFee);
				}

				totals.paidDepositAmount += settlementAmount;
			}

			if (settlement.remittance?.status?.value === paymentStatuses.SCHEDULED)
				settlementAmount = 0;

			if (settlement.reservationItem) {
				if (
					!settlement.isSecurityDeposit ||
					settlement.reservationItem.status.value === reservationStatuses.CHECKED_OUT ||
					settlement.reservationItem.status.value === reservationStatuses.CANCELLED
				) {
					totals.remainingAmount = subFloats(totals.remainingAmount, settlementAmount);
					remainingDepositAmount = subFloats(remainingDepositAmount, settlementAmount);
				}

				if (settlement.isSecurityDeposit)
					remainingSecurityDepositAmount = subFloats(
						remainingSecurityDepositAmount,
						settlementAmount
					);

				if (
					settlement.isDeposit &&
					settlement.remittance &&
					settlement.remittance.customerSettlements.filter(cs => cs.isRetain).length > 0
				) {
					settlement.remittance.customerSettlements
						.filter(cs => cs.isRetain)
						.forEach(cs => {
							remainingDepositAmount = subFloats(
								remainingDepositAmount,
								getSettlementAmountWithoutFee(cs)
							);
						});
				}
			}
		});

	totals.remainingAmount = numberFormat(
		subFloats(totals.remainingAmount, getReservationPrePaidAmount(reservation))
	);

	remainingDepositAmount = Math.min(totals.remainingAmount, remainingDepositAmount);

	totals.remainingDepositAmount = numberFormat(
		Math.max(remainingDepositAmount, 0) + Math.max(remainingSecurityDepositAmount, 0)
	);

	return totals;
};

export const getDepositRetainAmount = (reservationItem, remittanceId) => {
	let retainAmount = 0;

	if (!remittanceId) return retainAmount;

	reservationItem.customerSettlements
		.filter(cs => cs.isRetain && cs.remittance?.id === remittanceId)
		.forEach(cs => {
			retainAmount += cs.amount;
		});

	return retainAmount;
};

export const getItemRefundableAmount = (reservation, reservationItemIds) => {
	if (!reservation) return 0;

	let refundableAmount = 0;

	reservation.items.forEach(i => {
		if (reservationItemIds.includes(i.id)) {
			refundableAmount += i.customerSettlements
				.filter(cs => !cs.isFee && !cs.isRetain)
				.reduce((partialSum, cs) => partialSum + getSettlementAmountWithoutFee(cs), 0);
		}
	});

	return refundableAmount;
};

export const getPaymentTypes = (isRefund, paymentTypes, hasCashPayment) => {
	let __paymentTypes = [...paymentTypes.filter(pt => pt.value !== _paymentTypes.BOOKING)];

	if (!hasCashPayment)
		__paymentTypes = __paymentTypes.filter(pt => pt.value !== _paymentTypes.CASH);

	if (!isRefund)
		__paymentTypes = __paymentTypes.filter(pt => pt.value !== _paymentTypes.HOUSE_ACCOUNT);

	return __paymentTypes;
};

export const getRefundableAmount = (reservation, taxRate) => {
	const totals = getReservationTotals(reservation, taxRate);

	if (totals.remainingAmount < 0) return Math.abs(totals.remainingAmount);

	return totals.depositTotal - totals.dueNow;
};

export const printReservationReceipt = (
	webPrint,
	reservation,
	printData,
	type,
	printerId = null,
	openPrinterModal
) => {
	const _printerId = printerId || localStorage.getItem(SELECTED_PRINTER_STORAGE_KEY);
	if (
		_printerId &&
		webPrint.current.printers.findIndex(p => p.id.toString() === _printerId.toString()) > -1
	) {
		if (type === reservationReceiptTypes.DEPOSIT)
			webPrint.current.deposit(reservation, printData, _printerId);
		else if (type === reservationReceiptTypes.PAYMENT)
			webPrint.current.reservationPayment(reservation, printData, _printerId);
		else webPrint.current.reservationRefund(reservation, printData, _printerId);
	} else openPrinterModal(reservation, printData, type);
};

export const getDefaultAutoPay = (outlet, module) => {
	if (!outlet.settings) return false;

	if (module === modules.BOOKINGS) return outlet.settings.autoPayBooking;

	if (module === modules.MARINA) return outlet.settings.autoPayMarina;

	return outlet.settings.autoPayCampground;
};

export const getDefaultReservation = (user, module, outlet) => {
	return {
		id: 0,
		customer: null,
		module,
		items: [],
		deposits: [],
		invoices: [],
		meterReadingInvoices: [],
		orderSaleInvoices: [],
		retains: [],
		timeCreated: new Date().toISOString(),
		createdBy: {
			id: user.id,
			displayName: user.displayName,
		},
		outlet,
		autoPay: getDefaultAutoPay(outlet, module.value),
	};
};

export const getNextInvoiceDate = (fromDate, firstInvoice, nextInvoice, coTermDate) => {
	if (!firstInvoice) return null;

	if (firstInvoice.value !== firstInvoiceOptions.NO_CHARGE || !nextInvoice)
		return moment(fromDate).toISOString();

	let nextInvoiceDate = moment(fromDate);

	switch (nextInvoice.value) {
		case nextInvoiceOptions.FIFTH_OF_MONTH:
			nextInvoiceDate.day(5);
			if (nextInvoiceDate.isBefore(moment(fromDate))) nextInvoiceDate.add(1, 'month');
			break;
		case nextInvoiceOptions.FIFTEEN_OF_MONTH:
			nextInvoiceDate.day(15);
			if (nextInvoiceDate.isBefore(moment(fromDate))) nextInvoiceDate.add(1, 'month');
			break;
		case nextInvoiceOptions.END_OF_MONTH:
			nextInvoiceDate = nextInvoiceDate.endOf('month');
			break;
		case nextInvoiceOptions.CO_TERM:
			if (coTermDate) {
				nextInvoiceDate = moment(coTermDate);
				if (nextInvoiceDate.isBefore(moment(fromDate))) nextInvoiceDate.add(1, 'year');
			}
			break;
		case nextInvoiceOptions.FIRST_OF_MONTH:
			const firstDayOfMonth = moment(fromDate).startOf('month');

			if (
				firstDayOfMonth.isSameOrBefore(nextInvoiceDate) &&
				firstDayOfMonth.add(1, 'day').isAfter(nextInvoiceDate)
			)
				nextInvoiceDate = nextInvoiceDate.startOf('month');
			else nextInvoiceDate = nextInvoiceDate.add(1, 'month').startOf('month');
			break;
		case nextInvoiceOptions.FIRST_OF_YEAR:
			const firstDayOfYear = moment(fromDate).startOf('year');
			if (
				firstDayOfYear.isSameOrBefore(nextInvoiceDate) &&
				firstDayOfYear.add(1, 'day').isAfter(nextInvoiceDate)
			)
				nextInvoiceDate = nextInvoiceDate.startOf('year');
			else nextInvoiceDate = nextInvoiceDate.add(1, 'year').startOf('year');

			break;
		case nextInvoiceOptions.FIRST_OF_QUARTER:
			const firstDayOfQuarter = moment(fromDate).startOf('quarter');
			if (
				firstDayOfQuarter.isSameOrBefore(nextInvoiceDate) &&
				firstDayOfQuarter.add(1, 'day').isAfter(nextInvoiceDate)
			)
				nextInvoiceDate = nextInvoiceDate.startOf('quarter');
			else nextInvoiceDate = nextInvoiceDate.add(3, 'months').startOf('quarter');

			break;
		default:
			nextInvoiceDate = nextInvoiceDate.endOf('quarter');
			break;
	}

	return nextInvoiceDate.toISOString();
};

export const bookingCalculationConverter = (bookingCalculation, bookingPeriod) => {
	if (bookingCalculation && bookingPeriod) {
		const _bookingCalculation = { ...bookingCalculation };

		let periodText = 'period';

		if (bookingCalculation.value.indexOf('period') > -1 && bookingPeriod) {
			if (bookingPeriod === bookingPeriods.HOURLY) periodText = 'Hour';
			else if (bookingPeriod === bookingPeriods.DAILY) periodText = 'Day';
			else if (bookingPeriod === bookingPeriods.NIGHTLY) periodText = 'Night';
			else if (bookingPeriod === bookingPeriods.LONG_TERM) periodText = 'Year';
			else periodText = 'season';
		}

		_bookingCalculation.label = ucEachWordFirst(
			_bookingCalculation.value.replace('period', periodText)
		);

		return _bookingCalculation;
	}

	if (bookingCalculation)
		return { ...bookingCalculation, label: ucEachWordFirst(bookingCalculation.value) };

	return bookingCalculation;
};

export const getPolicy = (pricing, ratePlan, product, policies) => {
	let _policy = null;
	if (ratePlan && ratePlan.policy) _policy = policies.find(p => p.id === ratePlan.policy.id);
	else if (product.policy) _policy = policies.find(p => p.id === product.policy.id);
	// else if (policies.length) _policy = { ...policies[0] };

	return _policy;
};

export const getRatePlan = (availableRatePlans, ignoreRules, pricing) => {
	let _ratePlan = null;

	if (availableRatePlans.length) {
		if (ignoreRules) _ratePlan = { ...availableRatePlans.sort((a, b) => a.id - b.id)[0] };
		else {
			_ratePlan = { ...availableRatePlans[0] };

			availableRatePlans.forEach(arp => {
				if (
					pricing.ratePlans[arp.id].reduce((partialSum, a) => partialSum + a, 0) <
					pricing.ratePlans[_ratePlan.id].reduce((partialSum, a) => partialSum + a, 0)
				)
					_ratePlan = arp;
			});
		}
	}

	return _ratePlan;
};

export const getCapacity = (product, quantity, loa, beam) => {
	switch (product.bookingType.bookBy.value) {
		case bookBies.UNIT:
			return quantity;
		case bookBies.LENGTH:
			return loa || 1;
		case bookBies.BEAM:
			return beam || 1;
		case bookBies.SQ_FEET:
			return calculateSqft(loa, beam);
		default:
			return 1;
	}
};

export const getNextInvoiceDateMin = data => {
	if (data.prevInvoiceDate) return moment(data.prevInvoiceDate).toDate();

	if (data.timeCreated) return moment(data.timeCreated).toDate();

	return moment().toDate();
};

export const getRemainingDepositAmount = reservation => {
	let remainingDepositAmount = 0;

	reservation.items
		.filter(i => i.status.value !== reservationStatuses.WAITLIST)
		.forEach(item => {
			remainingDepositAmount += item.depositAmount;

			item.customerSettlements
				.filter(cs => !cs.isRetain)
				.forEach(cs => {
					if (
						(item.policy && !item.policy.isSecurityDeposit) ||
						(item.policy && item.policy.isSecurityDeposit && cs.isSecurityDeposit) ||
						!item.policy
					)
						remainingDepositAmount -= getSettlementAmountWithoutFee(cs);
				});
		});

	return remainingDepositAmount;
};

export const getItemApplicableDepositAmount = (reservationItem, reservation) => {
	if (!hasReservationInvoice(reservation) || reservationItem?.policy?.isSecurityDeposit)
		return reservationItem.remainingDepositAmount;
	return 0;
};

export const getReservationPrePaidAmount = reservation => {
	if (!reservation?.prePayments) return 0;

	let paidAmount = 0;

	reservation.prePayments.forEach(payment => {
		paidAmount = addFloats(payment.amount, paidAmount);

		paidAmount = subFloats(
			paidAmount,
			payment.customerSettlements.reduce(
				(partialSum, customerSettlement) => partialSum + customerSettlement.amount,
				0
			)
		);
	});

	return paidAmount;
};

const calculateItemTaxTotal = (item, taxRate, subtotal) => {
	if (!taxRate || !item.taxCode || !item.taxCode.taxable) return 0;

	const { product } = item;

	let taxTotal = 0;

	const taxRates = taxRate.taxRates.length === 0 ? [taxRate] : taxRate.taxRates;

	if (product.additionalTaxes) taxRates.push(...product.additionalTaxes);

	taxRates.forEach(_taxRate => {
		taxTotal = addFloats(taxTotal, numberFormat((subtotal * _taxRate.amount) / 100));
	});

	return taxTotal;
};

export const calculateItemTotals = (
	item,
	subtotal,
	selectedExtraCharges,
	taxRate,
	policy,
	pricing,
	ratePlan,
	defaultDepositAmount,
	fromDate,
	reservation = null,
	reCalculateDeposit = true
) => {
	const totals = {
		extraSubtotal: 0,
		extraTax: 0,
		extraTotal: 0,
		tax: 0,
		total: 0,
		amountPaid: 0,
		balanceDue: 0,
		requiredDepositAmount: defaultDepositAmount || 0,
		remainingDepositAmount: defaultDepositAmount || 0,
		paidDepositAmount: 0,
	};

	if ((!subtotal || subtotal === '0.00') && selectedExtraCharges.length === 0) return totals;

	let _subtotal = parseFloat(subtotal);

	if (Number.isNaN(_subtotal)) _subtotal = 0;

	selectedExtraCharges.forEach(sec => {
		if (sec.extraCharge.isTax) totals.extraTax += sec.total;
		else {
			totals.extraSubtotal += sec.total - sec.tax;
			totals.extraTax += sec.tax;
		}
		totals.extraTotal += sec.total;
	});

	totals.tax = calculateItemTaxTotal(item, taxRate, _subtotal);

	totals.total = _subtotal + totals.tax;

	if (reCalculateDeposit)
		totals.requiredDepositAmount = calculateDeposit(
			policy,
			totals.total + totals.extraTotal,
			pricing,
			ratePlan,
			defaultDepositAmount,
			fromDate,
			selectedExtraCharges
		);

	let grandTotal = totals.total + totals.extraTotal;

	if (policy && policy.isSecurityDeposit && item.status.value !== reservationStatuses.CHECKED_OUT)
		grandTotal += totals.requiredDepositAmount;

	totals.amountPaid = item.customerSettlements
		? item.customerSettlements
				.filter(cs => !cs.isRetain && !cs.refund && !cs.isFee)
				.reduce((partialSum, cs) => {
					let settlementAmount = getSettlementAmountWithoutFee(cs);

					if (
						cs.remittance &&
						cs.remittance.status &&
						cs.remittance.status.value === paymentStatuses.SCHEDULED
					)
						settlementAmount = 0;

					return partialSum + settlementAmount;
				}, 0)
		: 0;

	totals.paidDepositAmount = item.customerSettlements
		? item.customerSettlements
				.filter(cs => !cs.isRetain && !cs.refund && !cs.isFee && cs.isDeposit)
				.reduce((partialSum, cs) => {
					let settlementAmount = getSettlementAmountWithoutFee(cs);

					if (
						cs.remittance &&
						cs.remittance.status &&
						cs.remittance.status.value === paymentStatuses.SCHEDULED
					)
						settlementAmount = 0;

					return partialSum + settlementAmount;
				}, 0)
		: 0;

	totals.remainingDepositAmount = Math.max(
		0,
		subFloats(totals.requiredDepositAmount, totals.paidDepositAmount)
	);

	totals.balanceDue = grandTotal > totals.amountPaid ? grandTotal - totals.amountPaid : 0;

	if (reservation) {
		const itemIndex = reservation.items.findIndex(i => i.id === item.id);

		if (itemIndex > -1)
			reservation.items[itemIndex] = {
				...item,
				subtotal: parseFloat(subtotal),
			};

		const invoicePayments = splitInvoicePaymentsToReservationItems(reservation, taxRate, {
			...item,
			subtotal,
			selectedExtraCharges,
			taxRate,
			policy,
			pricing,
			ratePlan,
			defaultDepositAmount,
			fromDate,
		});

		if (invoicePayments[item.id]) {
			totals.amountPaid = invoicePayments[item.id].amountPaid;

			totals.balanceDue = invoicePayments[item.id].balanceDue;
		}
	}

	return totals;
};

export const getItemBookingDays = (fromDate, prices) => {
	if (!fromDate) return [];

	const days = [];

	const daysOfWeek = [];

	prices.forEach((price, index) => {
		const day = moment(fromDate)
			.utc(true)
			.add(index, 'day');
		daysOfWeek.push(day.format('dddd'));
		days.push(day);
	});
	return [daysOfWeek, days];
};

export const getPricesWithExtraCharges = (
	extras,
	prices,
	includeFees,
	includePromoCode,
	appliedPromoCode,
	fromDate
) => {
	try {
		const [bookingDays, bookingDates] = getItemBookingDays(fromDate, prices);

		return prices.map((price, index) => {
			let extraChargeTotal = 0;

			extras.forEach(extra => {
				if (
					canApplyExtraCharge(
						extra.extraCharge,
						bookingDates[index],
						bookingDays[index],
						includeFees,
						includePromoCode,
						index
					)
				) {
					switch (extra.extraCharge.bookingCalculation.value) {
						case bookingCalculations.FIXED:
							extraChargeTotal += extra.amount / prices.length;
							break;
						case bookingCalculations.PERCENT:
							extraChargeTotal += (price * extra.amount) / 100;
							break;
						case bookingCalculations.PER_PERIOD:
							extraChargeTotal += extra.amount;
							break;
						case bookingCalculations.PER_QUANTITY_PER_PERIOD:
							extraChargeTotal += extra.quantity * extra.amount;
							break;
						default:
							extraChargeTotal += extra.amount;
							break;
					}
				}
			});

			return Math.max(price + extraChargeTotal, 0);
		});
	} catch (e) {
		return prices;
	}
};

export const calculateDeposit = (
	policy,
	total,
	pricing,
	ratePlan,
	defaultDepositAmount,
	fromDate,
	extras
) => {
	try {
		if (total === 0 || !pricing || !pricing.ratePlans || !policy)
			return parseFloat(defaultDepositAmount);

		const prices = getPricesWithExtraCharges(
			extras,
			getPrices(pricing, ratePlan),
			policy.includeFees,
			policy.includePromoCode,
			null,
			fromDate
		);

		let _depositAmount = 0;

		const today = moment();

		if (
			policy.fullPaymentDaysPrior &&
			fromDate &&
			fromDate.diff(today, 'days') < policy.fullPaymentDaysPrior
		)
			_depositAmount = total;
		else if (policy.depositType.value === depositTypes.PERCENT)
			_depositAmount = (total * policy.depositAmount) / 100;
		else if (policy.depositType.value === depositTypes.FIXED)
			_depositAmount = policy.depositAmount;
		else if (policy.depositType.value === depositTypes.PER_BLOCK)
			for (let i = 0; i < Math.min(policy.depositAmount, prices.length); i += 1) {
				_depositAmount += prices[i];
			}

		if (policy && !policy.isSecurityDeposit && _depositAmount > total)
			return numberFormat(total);

		return numberFormat(_depositAmount);
	} catch (error) {
		return parseFloat(defaultDepositAmount);
	}
};

export const displaySpaceAssignmentTODate = (data, type) => {
	if (!data.reservationItem || !type) return false;

	if (type.value !== spaceAssigmentTypes.PERMANENT_MOVE) return true;

	return data.reservationItem.product.bookingPeriod.value !== bookingPeriods.LONG_TERM;
};

export const getCurrentUnit = (reservationItem, defaultUnit = null) => {
	if (!reservationItem || reservationItem.id === 0 || !reservationItem.spaceAssignments)
		return defaultUnit;

	if (reservationItem.spaceAssignments.length === 0) return reservationItem.unit;

	let { unit } = reservationItem;

	const today = moment();

	reservationItem.spaceAssignments.forEach(assignment => {
		if (
			today.isSameOrAfter(moment(assignment.fromDate)) &&
			today.isSameOrBefore(moment(assignment.toDate))
		) {
			unit = assignment.space;
		}
	});

	return unit;
};

export const displayDepositButton = (reservation, reservationItem, remainingDepositAmount) => {
	if (
		reservationItem.id === 0 ||
		remainingDepositAmount <= 0 ||
		reservationItem.status.value === reservationStatuses.CHECKED_OUT ||
		reservationItem.status.value === reservationStatuses.CANCELLED
	)
		return false;

	if (reservationItem.policy && reservationItem.policy.isSecurityDeposit) return true;

	return reservation.invoices.filter(i => i.status.value === invoiceStatuses.OPEN).length === 0;
};

export const getRefundableSecurityDepositAmount = reservationItem => {
	if (
		reservationItem.status &&
		reservationItem.customerSettlements &&
		(reservationItem.status.value === reservationStatuses.CHECKED_OUT ||
			reservationItem.status.value === reservationStatuses.CANCELLED)
	)
		return reservationItem.customerSettlements
			.filter(cs => cs.isSecurityDeposit && !cs.isRetain)
			.reduce((partialSum, cs) => partialSum + cs.amount, 0);

	return 0;
};

export const getDefaultCreditCard = data => {
	if (!data.payment) return null;

	if (data.payment.paymentMethod.paymentType.value !== _paymentTypes.CREDIT_CARD) return null;

	return data.payment;
};

export const getDefaultPaymentMethod = data =>
	data?.defaultPaymentMethod || data?.payment?.paymentMethod?.paymentType?.value;

export const getReservationExtraChargePeriod = (
	reservationItemQuantity,
	reservationItemBookingPeriod,
	extraChargeBookingCalculation,
	extraChargeBookingPeriod
) => {
	if (!extraChargeBookingPeriod) return reservationItemQuantity;

	let period = reservationItemQuantity;

	if (
		extraChargeBookingCalculation === bookingCalculations.PER_PERIOD ||
		extraChargeBookingCalculation === bookingCalculations.PER_QUANTITY_PER_PERIOD
	) {
		if (
			reservationItemBookingPeriod === bookingPeriods.DAILY &&
			extraChargeBookingPeriod === bookingPeriods.NIGHTLY
		)
			period -= 1;
		else if (
			reservationItemBookingPeriod === bookingPeriods.NIGHTLY &&
			extraChargeBookingPeriod === bookingPeriods.DAILY
		)
			period += 1;
	}

	return period;
};

export const getPrices = (pricing, ratePlan) => {
	if (!pricing.ratePlans) return null;

	return ratePlan && pricing.ratePlans[ratePlan.id]
		? pricing.ratePlans[ratePlan.id]
		: pricing.ratePlans[0];
};

export const getExtraChargeApplicablePrice = (
	extraChargeItem,
	reservationItem,
	taxRate,
	prices,
	extraChargeItems,
	productPrice,
	priceIndex
) => {
	let applicablePrice = 0;

	const { extraCharge } = extraChargeItem;

	const { taxCode } = reservationItem;

	if (extraCharge.applyTaxes.some(tc => tc.id === taxCode.id)) applicablePrice += productPrice;

	extraChargeItems
		.filter(
			_extraChargeItem =>
				!_extraChargeItem.extraCharge.isTax &&
				_extraChargeItem.id !== extraChargeItem.id &&
				extraCharge.applyTaxes.some(tc => tc.id === _extraChargeItem.extraCharge.taxCode.id)
		)
		.forEach(_extraChargeItem => {
			const totals = calculateExtraFeeTotals(
				_extraChargeItem,
				reservationItem,
				taxRate,
				prices,
				extraChargeItems,
				priceIndex
			);

			if (
				_extraChargeItem.extraCharge.bookingCalculation &&
				_extraChargeItem.extraCharge.bookingCalculation.value === bookingCalculations.FIXED
			)
				applicablePrice += totals.subtotal / prices.length;
			else applicablePrice += totals.subtotal;
		});

	return applicablePrice;
};

export const calculateExtraFeeTotals = (
	extraChargeItem,
	reservationItem,
	taxRate,
	prices = null,
	extraChargeItems,
	priceIndex = null,
	ignoreRules = false
) => {
	const _totals = {
		subtotal: 0,
		tax: 0,
		total: 0,
	};

	if (!extraChargeItem.amount && !extraChargeItem.quantity) return _totals;

	const reservationFeeQuantity = extraChargeItem.extraCharge.bookingCalculation
		? getReservationExtraChargePeriod(
				reservationItem.quantity,
				reservationItem.bookingPeriod,
				extraChargeItem.extraCharge.bookingCalculation.value,
				extraChargeItem.extraCharge.bookingPeriod?.value
		  )
		: 1;

	if (prices && reservationItem.fromDate) {
		const [bookingItemDays, bookingItemDates] = getItemBookingDays(
			reservationItem.fromDate,
			prices
		);

		// check for custom subtotal.
		if (
			reservationItem.subtotal &&
			reservationItem.subtotal !== prices.reduce((partialSum, a) => partialSum + a, 0)
		) {
			prices = Array(reservationItem.quantity).fill(
				reservationItem.subtotal / reservationItem.quantity
			);
		}

		prices.forEach((price, index) => {
			if (
				(ignoreRules ||
					canApplyExtraCharge(
						extraChargeItem.extraCharge,
						bookingItemDates[index],
						bookingItemDays[index],
						true,
						true,
						index
					)) &&
				index < reservationFeeQuantity &&
				(priceIndex === null || index === priceIndex)
			) {
				const applicablePrice =
					reservationItem.product && extraChargeItem.extraCharge.isTax
						? getExtraChargeApplicablePrice(
								extraChargeItem,
								reservationItem,
								taxRate,
								prices,
								extraChargeItems,
								price,
								index
						  )
						: price;

				const bookingCalculation =
					extraChargeItem.extraCharge.bookingCalculation?.value ||
					bookingCalculations.PER_QUANTITY_PER_PERIOD;

				switch (bookingCalculation) {
					case bookingCalculations.PERCENT:
						_totals.subtotal += (applicablePrice * extraChargeItem.amount) / 100;
						break;
					case bookingCalculations.PER_QUANTITY:
						_totals.subtotal = extraChargeItem.amount * extraChargeItem.quantity;
						break;
					case bookingCalculations.PER_PERIOD:
						_totals.subtotal += extraChargeItem.amount;
						break;
					case bookingCalculations.PER_QUANTITY_PER_PERIOD:
						_totals.subtotal += extraChargeItem.amount * extraChargeItem.quantity;
						break;
					case bookingCalculations.FIXED:
						_totals.subtotal = extraChargeItem.amount;
						break;
					case bookingCalculations.PER_BEAM_FOOT:
						_totals.subtotal = reservationItem.beam
							? numberFormat((reservationItem.beam / 12) * extraChargeItem.amount)
							: 0;
						break;
					case bookingCalculations.PER_BEAM_FOOT_PER_PERIOD:
						_totals.subtotal += reservationItem.beam
							? (reservationItem.beam / 12) * extraChargeItem.amount
							: 0;
						break;

					case bookingCalculations.PER_LOA_FOOT:
						_totals.subtotal = reservationItem.loa
							? numberFormat((reservationItem.loa / 12) * extraChargeItem.amount)
							: 0;
						break;

					case bookingCalculations.PER_LOA_FOOT_PER_PERIOD:
						_totals.subtotal += reservationItem.loa
							? (reservationItem.loa / 12) * extraChargeItem.amount
							: 0;
						break;
					case bookingCalculations.PER_SQFT:
						_totals.subtotal = calculateSqft(reservationItem.loa, reservationItem.beam)
							? numberFormat(
									calculateSqft(reservationItem.loa, reservationItem.beam) *
										extraChargeItem.amount
							  )
							: 0;
						break;
					case bookingCalculations.PER_SQFT_PER_PERIOD:
						_totals.subtotal += calculateSqft(reservationItem.loa, reservationItem.beam)
							? calculateSqft(reservationItem.loa, reservationItem.beam) *
							  extraChargeItem.amount
							: 0;
						break;

					default:
						_totals.subtotal = 0;
						break;
				}
			}
		});
	} else if (extraChargeItem.extraCharge.bookingCalculation) {
		switch (extraChargeItem.extraCharge.bookingCalculation.value) {
			case bookingCalculations.PERCENT:
				_totals.subtotal = numberFormat(
					(reservationItem.subtotal * extraChargeItem.amount) / 100
				);
				break;
			case bookingCalculations.PER_QUANTITY:
				_totals.subtotal = numberFormat(extraChargeItem.amount * extraChargeItem.quantity);
				break;

			case bookingCalculations.PER_PERIOD:
				_totals.subtotal = numberFormat(extraChargeItem.amount * reservationFeeQuantity);
				break;
			case bookingCalculations.PER_QUANTITY_PER_PERIOD:
				_totals.subtotal = numberFormat(
					extraChargeItem.amount * extraChargeItem.quantity * reservationFeeQuantity
				);
				break;
			case bookingCalculations.FIXED:
				_totals.subtotal = numberFormat(extraChargeItem.amount);
				break;
			case bookingCalculations.PER_BEAM_FOOT:
				_totals.subtotal = reservationItem.beam
					? numberFormat((reservationItem.beam / 12) * extraChargeItem.amount)
					: 0;
				break;
			case bookingCalculations.PER_BEAM_FOOT_PER_PERIOD:
				_totals.subtotal = reservationItem.beam
					? numberFormat(
							(reservationItem.beam / 12) *
								extraChargeItem.amount *
								reservationFeeQuantity
					  )
					: 0;
				break;

			case bookingCalculations.PER_LOA_FOOT:
				_totals.subtotal = reservationItem.loa
					? numberFormat((reservationItem.loa / 12) * extraChargeItem.amount)
					: 0;
				break;

			case bookingCalculations.PER_LOA_FOOT_PER_PERIOD:
				_totals.subtotal = reservationItem.loa
					? numberFormat(
							(reservationItem.loa / 12) *
								extraChargeItem.amount *
								reservationFeeQuantity
					  )
					: 0;
				break;
			case bookingCalculations.PER_SQFT:
				_totals.subtotal = calculateSqft(reservationItem.loa, reservationItem.beam)
					? numberFormat(
							calculateSqft(reservationItem.loa, reservationItem.beam) *
								extraChargeItem.amount
					  )
					: 0;
				break;
			case bookingCalculations.PER_SQFT_PER_PERIOD:
				_totals.subtotal = calculateSqft(reservationItem.loa, reservationItem.beam)
					? numberFormat(
							calculateSqft(reservationItem.loa, reservationItem.beam) *
								extraChargeItem.amount *
								reservationFeeQuantity
					  )
					: 0;
				break;

			default:
				_totals.subtotal = 0;
				break;
		}
	} else {
		_totals.subtotal = extraChargeItem.amount * extraChargeItem.quantity;
	}

	_totals.subtotal = numberFormat(_totals.subtotal);

	if (
		extraChargeItem.extraCharge.minPrice !== null &&
		extraChargeItem.extraCharge.minPrice !== '' &&
		extraChargeItem.extraCharge.minPrice !== 0 &&
		_totals.subtotal < extraChargeItem.extraCharge.minPrice
	)
		_totals.subtotal = extraChargeItem.extraCharge.minPrice;

	if (
		extraChargeItem.extraCharge.maxPrice !== null &&
		extraChargeItem.extraCharge.maxPrice !== '' &&
		extraChargeItem.extraCharge.maxPrice !== 0 &&
		_totals.subtotal > extraChargeItem.extraCharge.maxPrice
	)
		_totals.subtotal = extraChargeItem.extraCharge.maxPrice;

	const taxAmount =
		extraChargeItem.taxCode && !extraChargeItem.taxCode.taxable
			? 0
			: getItemTotalTaxAmount(extraChargeItem.extraCharge, taxRate, _totals.subtotal);

	_totals.tax = numberFormat(taxAmount ? (_totals.subtotal * taxAmount) / 100 : 0, 2);

	_totals.total = numberFormat(_totals.subtotal + _totals.tax);

	return _totals;
};

export const onExtraChargeItemUpdate = (
	extraChargeItemData,
	selectedExtraCharges,
	setSelectedExtraCharges,
	reservationItem,
	taxRate,
	prices = null,
	ignoreRules = false
) => {
	extraChargeItemData = {
		...extraChargeItemData,
		...calculateExtraFeeTotals(
			extraChargeItemData,
			reservationItem,
			taxRate,
			prices,
			selectedExtraCharges,
			null,
			ignoreRules
		),
	};

	const extraChargeItemIndex = selectedExtraCharges.findIndex(
		sec => sec.extraCharge.id === extraChargeItemData.extraCharge.id
	);

	let _selectedExtraCharges = [];

	if (extraChargeItemIndex > -1) {
		_selectedExtraCharges = update(
			selectedExtraCharges,
			extraChargeItemData.isChecked
				? { [extraChargeItemIndex]: { $merge: extraChargeItemData } }
				: { $splice: [[extraChargeItemIndex, 1]] }
		);
	} else if (extraChargeItemData.isChecked) {
		const { invoicingFrequency } = extraChargeItemData.extraCharge;

		_selectedExtraCharges = update(selectedExtraCharges, {
			$push: [
				{
					...extraChargeItemData,
					id: generateId(selectedExtraCharges) - 1,
					invoicingFrequency: extraChargeItemData.extraCharge.invoicingFrequency,
					paymentTerm:
						invoicingFrequency &&
						invoicingFrequency.value === invoicingFrequencies.SAME_AS_RESERVATION
							? reservationItem.paymentTerm
							: extraChargeItemData.extraCharge.paymentTerm,
					firstInvoice:
						invoicingFrequency &&
						invoicingFrequency.value === invoicingFrequencies.SAME_AS_RESERVATION
							? reservationItem.firstInvoice
							: extraChargeItemData.extraCharge.firstInvoice,
					nextInvoice:
						invoicingFrequency &&
						invoicingFrequency.value === invoicingFrequencies.SAME_AS_RESERVATION
							? reservationItem.nextInvoice
							: extraChargeItemData.extraCharge.nextInvoice,
					proratedFrequency:
						invoicingFrequency &&
						invoicingFrequency.value === invoicingFrequencies.SAME_AS_RESERVATION
							? reservationItem.proratedFrequency
							: extraChargeItemData.extraCharge.proratedFrequency,
					coTermDate:
						invoicingFrequency &&
						invoicingFrequency.value === invoicingFrequencies.SAME_AS_RESERVATION
							? reservationItem.coTermDate
							: extraChargeItemData.extraCharge.coTermDate,
					nextInvoiceDate:
						invoicingFrequency &&
						invoicingFrequency.value === invoicingFrequencies.SAME_AS_RESERVATION
							? reservationItem.nextInvoiceDate
							: getNextInvoiceDate(
									reservationItem?.nextInvoiceDate || reservationItem.fromDate,
									extraChargeItemData.extraCharge.firstInvoice,
									extraChargeItemData.extraCharge.nextInvoice,
									extraChargeItemData.extraCharge.coTermDate
							  ),
					enableDeferredIncome: extraChargeItemData.extraCharge.enableDeferredIncome,
					deferredIncomeFrequency:
						extraChargeItemData.extraCharge.deferredIncomeFrequency,
					deferredIncomeSalesAccount:
						extraChargeItemData.extraCharge.deferredIncomeSalesAccount,
				},
			],
		});
	}

	// update tax extra charges
	if (!extraChargeItemData.extraCharge.isTax) {
		_selectedExtraCharges
			.filter(
				sec =>
					sec.extraCharge.isTax &&
					sec.extraCharge.applyTaxes.some(
						tc => tc.id === extraChargeItemData.extraCharge.taxCode.id
					)
			)
			.forEach(taxExtraChargeItem => {
				const taxExtraChargeItemData = {
					...taxExtraChargeItem,
					...calculateExtraFeeTotals(
						taxExtraChargeItem,
						reservationItem,
						taxRate,
						prices,
						_selectedExtraCharges,
						null,
						ignoreRules
					),
				};

				const taxExtraChargeItemIndex = _selectedExtraCharges.findIndex(
					sec => sec.extraCharge.id === taxExtraChargeItem.extraCharge.id
				);

				_selectedExtraCharges = update(_selectedExtraCharges, {
					[taxExtraChargeItemIndex]: { $merge: taxExtraChargeItemData },
				});
			});
	}

	setSelectedExtraCharges(_selectedExtraCharges);
};

export const getNextAndPrevReservationItemStatus = (
	status,
	customStatus,
	customStatuses,
	showManualSelection = true
) => {
	const statusMap = [
		{
			status: reservationStatuses.WAITLIST,
			prev: null,
			next: null,
			customStatuses: customStatuses
				.filter(
					cs =>
						cs.status.value === reservationStatuses.WAITLIST &&
						cs.sortOrder !== 0 &&
						(!cs.manualSelection || showManualSelection)
				)
				.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1)),
			order: 0,
		},
		{
			status: reservationStatuses.RESERVED,
			prev: null,
			next: reservationStatuses.CONFIRMED,
			customStatuses: customStatuses
				.filter(
					cs =>
						cs.status.value === reservationStatuses.RESERVED &&
						cs.sortOrder !== 0 &&
						(!cs.manualSelection || showManualSelection)
				)
				.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1)),
			order: 0,
		},
		{
			status: reservationStatuses.RESERVED_ONLINE,
			prev: null,
			next: reservationStatuses.CONFIRMED,
			customStatuses: customStatuses
				.filter(
					cs =>
						cs.status.value === reservationStatuses.RESERVED_ONLINE &&
						cs.sortOrder !== 0 &&
						(!cs.manualSelection || showManualSelection)
				)
				.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1)),
			order: 0,
		},
		{
			status: reservationStatuses.CONFIRMED,
			prev: reservationStatuses.RESERVED,
			next: reservationStatuses.CHECKED_IN,
			customStatuses: customStatuses
				.filter(
					cs =>
						cs.status.value === reservationStatuses.CONFIRMED &&
						cs.sortOrder !== 0 &&
						(!cs.manualSelection || showManualSelection)
				)
				.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1)),
			order: 1,
			label: 'Confirm',
		},
		{
			status: reservationStatuses.CHECKED_IN,
			prev: reservationStatuses.CONFIRMED,
			next: reservationStatuses.CHECKED_OUT,
			customStatuses: customStatuses
				.filter(
					cs =>
						cs.status.value === reservationStatuses.CHECKED_IN &&
						cs.sortOrder !== 0 &&
						(!cs.manualSelection || showManualSelection)
				)
				.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1)),
			order: 2,

			label: 'Check In',
		},
		{
			status: reservationStatuses.CHECKED_OUT,
			prev: reservationStatuses.CHECKED_IN,
			next: null,
			customStatuses: customStatuses
				.filter(
					cs =>
						cs.status.value === reservationStatuses.CHECKED_OUT &&
						cs.sortOrder !== 0 &&
						(!cs.manualSelection || showManualSelection)
				)
				.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1)),
			order: 3,
			label: 'Check Out',
		},
		{
			status: reservationStatuses.CANCELLED,
			prev: null,
			next: null,
			customStatuses: customStatuses
				.filter(
					cs =>
						cs.status.value === reservationStatuses.CANCELLED &&
						cs.sortOrder !== 0 &&
						(!cs.manualSelection || showManualSelection)
				)
				.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1)),
			order: 4,
		},
	];

	const currentStatusValue = customStatus ? customStatus.status.value : status.value;

	const currentStatus = statusMap.find(sm => sm.status === currentStatusValue);

	let nextStatus;

	let prevStatus;

	const enumPrevStatus = currentStatus.prev
		? statusMap.find(sm => sm.status === currentStatus.prev)
		: null;

	const enumNextStatus = currentStatus.next
		? statusMap.find(sm => sm.status === currentStatus.next)
		: null;

	if (currentStatus.customStatuses.length === 0) {
		prevStatus =
			enumPrevStatus && enumPrevStatus.customStatuses.length > 0
				? enumPrevStatus.customStatuses[enumPrevStatus.customStatuses.length - 1]
				: enumPrevStatus;

		if (prevStatus && prevStatus.name && prevStatus.sortOrder === 0)
			prevStatus = enumPrevStatus;

		nextStatus = enumNextStatus;
	} else if (customStatus) {
		const customPrevStatuses = currentStatus.customStatuses.filter(
			cs => cs.sortOrder < customStatus.sortOrder
		);

		const customNextStatuses = currentStatus.customStatuses.filter(
			cs => cs.sortOrder > customStatus.sortOrder
		);

		prevStatus =
			customPrevStatuses.length > 0
				? customPrevStatuses[customPrevStatuses.length - 1]
				: currentStatus;

		nextStatus = customNextStatuses.length > 0 ? customNextStatuses[0] : enumNextStatus;
	} else {
		prevStatus =
			enumPrevStatus && enumPrevStatus.customStatuses.length > 0
				? enumPrevStatus.customStatuses[enumPrevStatus.customStatuses.length - 1]
				: enumPrevStatus;
		// eslint-disable-next-line prefer-destructuring
		nextStatus = currentStatus.customStatuses[0];
	}

	return { prevStatus, nextStatus };
};

export const reservationItemStatusIcon = status => {
	switch (status) {
		case reservationStatuses.CONFIRMED:
			return 'Check';
		case reservationStatuses.CHECKED_IN:
			return 'Sign-in';
		case reservationStatuses.CHECKED_OUT:
			return 'Sign-out';
		case reservationStatuses.CANCELLED:
			return 'Error-circle';
		case reservationStatuses.WAITLIST:
			return 'Clock';
		default:
			return 'Bookings';
	}
};

export const isCancellableStatus = status =>
	[
		reservationStatuses.RESERVED_ONLINE,
		reservationStatuses.RESERVED,
		reservationStatuses.CONFIRMED,
		reservationStatuses.WAITLIST,
	].includes(status);

export const getReservationListCustomFilters = (type, outlet, module) => {
	if (type !== 'all') return [];

	let bookingTypeRoute = 'bookingTypeBookings';

	if (module === modules.MARINA) bookingTypeRoute = 'bookingTypeMarinas';

	if (module === modules.CAMPGROUND) bookingTypeRoute = 'bookingTypeCampgrounds';

	return [
		{
			name: 'statusFilter',
			component: filterComponents.RADIO,
			fieldName: 'status.value[]',
			default:
				outlet.settings && outlet.settings.reservationListDefaultFilter === 'active'
					? [
							reservationStatuses.RESERVED_ONLINE,
							reservationStatuses.RESERVED,
							reservationStatuses.CONFIRMED,
							reservationStatuses.CHECKED_IN,
					  ]
					: Object.values(reservationStatuses),
			options: [
				{
					display: 'All',
					value: Object.values(reservationStatuses),
				},
				{
					display: 'Active',
					value: [
						reservationStatuses.RESERVED_ONLINE,
						reservationStatuses.RESERVED,
						reservationStatuses.CONFIRMED,
						reservationStatuses.CHECKED_IN,
					],
				},
				{
					display: 'Waitlist',
					value: [reservationStatuses.WAITLIST],
				},
			],
		},
		{
			component: filterComponents.SELECTS,
			dataName: bookingTypeRoute,
			fieldName: 'product.bookingType.id',
			placeholder: 'Booking Type',
			displayKey: 'name',
			default: null,
		},
	];
};

export const calculateSubtotalAndQuantity = (product, ratePlan, pricing, fromDate, toDate) => {
	const prices =
		ratePlan && pricing.ratePlans[ratePlan.id]
			? pricing.ratePlans[ratePlan.id]
			: Object.values(pricing.ratePlans)[0];

	const data = {
		quantity: prices ? prices.length : 1,
		subtotal: prices
			? numberFormat(
					product.bookingCalculation.value === bookingCalculations.FIXED
						? prices[0]
						: prices.reduce((a, b) => a + b)
			  )
			: 0,
	};

	if (product.bookingPeriod.value === bookingPeriods.HOURLY) {
		data.quantity = Math.ceil(moment.duration(toDate.diff(fromDate)).asMinutes() / 60);

		if (product.bookingCalculation.value === bookingCalculations.PER_PERIOD)
			data.subtotal = product.price * data.quantity;
	}

	return data;
};

export const updateItemTaxCode = (item, customer, taxRate, reservation = null) => {
	if (item.extraCharges)
		item.extraCharges.map(ec => {
			ec.taxCode = getItemTaxCode(customer ? customer.taxCode : null, ec.extraCharge);

			const extraChargeTotals = calculateExtraFeeTotals(ec, item, taxRate, item.extraCharges);

			ec.tax = extraChargeTotals.tax;

			ec.total = extraChargeTotals.total;

			return ec;
		});

	item.taxCode = getItemTaxCode(customer ? customer.taxCode : null, item.product);

	const totals = calculateItemTotals(
		item,
		item.subtotal,
		item.extraCharges,
		taxRate,
		item.policy,
		null,
		null,
		0,
		null,
		reservation
	);

	item.tax = totals.tax;

	item.total = totals.total;

	return item;
};

export const splitInvoicePaymentsToReservationItems = (reservation, taxRate, currentItem) => {
	if (reservation.invoices && reservation.invoices.length === 0) return {};

	const invoicePayments = {};

	reservation.items
		.filter(i => i.status.value !== reservationStatuses.CANCELLED)
		.forEach(item => {
			const totals =
				item.id === currentItem.id
					? calculateItemTotals(
							currentItem,
							currentItem.subtotal,
							currentItem.selectedExtraCharges,
							currentItem.taxRate,
							currentItem.policy,
							currentItem.pricing,
							currentItem.ratePlan,
							currentItem.defaultDepositAmount,
							currentItem.fromDate
					  )
					: calculateItemTotals(
							item,
							item.subtotal,
							item.extraCharges,
							taxRate,
							item.policy,
							null,
							null,
							0,
							null
					  );

			invoicePayments[item.id] = {
				amountPaid: totals.amountPaid,
				balanceDue: totals.balanceDue,
			};
		});

	let invoicePaymentAmount = 0;

	reservation.invoices.forEach(invoice => {
		invoicePaymentAmount += invoice.customerSettlements
			.filter(cs => !cs.isRetain && !cs.reservationItem)
			.reduce((partialSum, cs) => {
				let settlementAmount = getSettlementAmountWithoutFee(cs);

				if (
					cs.remittance &&
					cs.remittance.status &&
					cs.remittance.status.value === paymentStatuses.SCHEDULED
				)
					settlementAmount = 0;

				return partialSum + settlementAmount;
			}, 0);
	});

	Object.keys(invoicePayments).forEach(reservationItemId => {
		const change = Math.min(
			invoicePayments[reservationItemId].balanceDue,
			invoicePaymentAmount
		);
		invoicePayments[reservationItemId].amountPaid += change;
		invoicePayments[reservationItemId].balanceDue -= change;
		invoicePaymentAmount -= change;
	});

	return invoicePayments;
};

export const canApplyExtraCharge = (
	extraCharge,
	date,
	day,
	includeFee = true,
	includePromoCode = true,
	index = 0
) => {
	if (isDayInExtraChargeExcludeDates(extraCharge, date)) return false;

	if (!extraCharge.code) {
		if (
			(extraCharge.minDuration && extraCharge.minDuration > index + 1) ||
			(extraCharge.maxDuration && extraCharge.maxDuration < index + 1)
		) {
			return false;
		}

		return includeFee;
	}

	if (extraCharge.code && !extraCharge.enabled) return false;

	const promoCodeBookingDays = extraCharge.days
		? extraCharge.days.map(pcdb => (typeof pcdb === 'object' ? pcdb.value : pcdb))
		: [];

	if (
		promoCodeBookingDays.length > 0 &&
		!promoCodeBookingDays.some(pcbd => {
			if (typeof pcbd === 'object') return pcbd.value === day;
			return pcbd === day;
		})
	)
		return false;

	return includePromoCode;
};

export const canDeleteReservationItem = reservationItem => {
	if (reservationItem.status.value !== reservationStatuses.CANCELLED) return false;

	if (
		(reservationItem.product.bookingPeriod.value === bookingPeriods.LONG_TERM ||
			reservationItem.product.bookingPeriod.value === bookingPeriods.SEASONAL) &&
		reservationItem.reservation.invoices.length > 0
	)
		return false;

	return reservationItem.customerSettlements.filter(cs => cs.amount).length === 0;
};

export const isDayInExtraChargeExcludeDates = (extraCharge, day) => {
	if (!extraCharge.excludeDates) return false;

	const dayMoment = moment(day).startOf('day');

	return extraCharge.excludeDates.some(excludeDate => {
		const excludeStartDate = moment(excludeDate.startDate)
			.utc(true)
			.startOf('day');
		const excludeEnDate = moment(excludeDate.endDate)
			.utc(true)
			.startOf('day');

		return excludeStartDate.isSameOrBefore(dayMoment) && excludeEnDate.isSameOrAfter(dayMoment);
	});
};

export const isItemInExtraChargeExcludeDates = (selectedProduct, fromDate, extraCharge) => {
	if (!selectedProduct || !fromDate || !extraCharge.excludeDates) return false;

	const [, bookingDays] = getItemBookingDays(fromDate, selectedProduct.pricing.prices[1]);

	return !bookingDays.some(day => !isDayInExtraChargeExcludeDates(extraCharge, day));
};

export const getExtraChargeExcludeText = (selectedProduct, fromDate, extraCharge) => {
	if (!selectedProduct || !fromDate || !extraCharge.excludeDates) return '';

	const [, bookingDates] = getItemBookingDays(fromDate, selectedProduct.pricing.prices[1]);

	const appliedDays = bookingDates.filter(
		date => !isDayInExtraChargeExcludeDates(extraCharge, date)
	);

	let _totalText = '';
	if (selectedProduct.bookingPeriod === bookingPeriods.DAILY) _totalText = 'day';
	else if (selectedProduct.bookingPeriod === bookingPeriods.NIGHTLY) _totalText = 'night';

	return appliedDays.length > 0 && appliedDays.length !== bookingDates.length
		? `(Available: ${appliedDays.length}/${bookingDates.length} ${
				appliedDays.length > 1 ? _totalText.concat('s') : _totalText
		  })`
		: '';
};

export const getExtraChargeExcludeDates = (selectedProduct, fromDate, extraCharge) => {
	if (!selectedProduct || !fromDate || !extraCharge.excludeDates) return [];

	const [, bookingDates] = getItemBookingDays(fromDate, selectedProduct.pricing.prices[1]);

	return bookingDates.filter(date => isDayInExtraChargeExcludeDates(extraCharge, date));
};

export const setBookingCalculationLabel = enumBookingCalculation => {
	if (!enumBookingCalculation) return enumBookingCalculation;

	if (enumBookingCalculation.value === bookingCalculations.PER_PERIOD)
		enumBookingCalculation.label = 'Year';
	else enumBookingCalculation.label = 'Month';

	return enumBookingCalculation;
};

export const isEarlyCheckout = reservationItem => {
	const toDate = moment(reservationItem.toDate)
		.hours(0)
		.minute(0)
		.seconds(0)
		.milliseconds(0);

	const today = moment()
		.hours(0)
		.minute(0)
		.seconds(0)
		.milliseconds(0);

	return today.isBefore(toDate);
};
