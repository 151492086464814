import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import moment from 'moment';
import useComponentSize from '@rehooks/component-size';
import { ReactSVG } from 'react-svg';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import useField from '../../../utils/hooks/useField';
import useDate from '../../../utils/hooks/useDate';
import useModal from '../../../utils/hooks/useModal';
import { numeric, required } from '../../../utils/helpers/validation';
import {
	addErrorNotification,
	calculateMeterReadingTotal,
	getAccountingClosedDate,
	numberParser,
	parseDatePickerChange,
	parseDatePickerValue,
	priceFormatter,
} from '../../../utils/helpers/helper';
import { getBookingHourlyStep } from '../../../utils/helpers/reusable';
import apiCall, { modules } from '../../../utils/helpers/apiCall';

import Portlet from '../../reusables/layout/Portlet';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Selects from '../../reusables/field/Selects';
import DatePicker from '../../reusables/field/DatePicker';
import TimePickerInput from '../../reusables/field/TimePickerInput';
import Toggle from '../../reusables/field/Toggle';
import Input from '../../reusables/field/Input';
import { ListBody, ListTable } from '../../reusables/template/List';
import SpaceGroupModal from '../../reusables/modals/SpaceGroupModal';
import Button from '../../reusables/element/Button';
import DialogBox from '../../reusables/element/DialogBox';
import AsyncSelect from '../../reusables/field/AsyncSelect';

import logoLoading from '../../../assets/img/logoLoading.svg';

const MeterReadingItem = ({
	data,
	onFormChange,
	powerMeterUnit,
	isSubmitted,
	setIsValid,
	serviceCharge,
}) => {
	const userContext = useContext(UserContext);

	const init = useRef(false);

	const [, utcDateFormatter] = useDate();

	const alwaysCharge = userContext.data.selectedOutlet.settings.alwaysChargeMeterReading;

	const currentReadingValidate = (value, setValRes) => {
		if (value === '' || previousReading === '') return true;

		if (alwaysCharge && parseFloat(value) < parseFloat(previousReading)) {
			setValRes({
				isValid: false,
				status: 'invalidCurrentReading',
				message: `Current reading must be equal or greater than previous reading`,
			});
			return false;
		}

		if (!alwaysCharge && parseFloat(value) <= parseFloat(previousReading)) {
			setValRes({
				isValid: false,
				status: 'invalidCurrentReading',
				message: `Current reading must be greater than previous reading`,
			});
			return false;
		}

		return false;
	};

	const optionalShow = option =>
		`${option.reservation.customer.displayName} - ${option.unit.name} - ${option.reservation.reservationId}`;

	const renderOption = option =>
		`${option.reservation.reservationId} - ${option.id} - ${
			option.reservation.customer.displayName
		} -  ${option.product.name} - ${option.unit.name} - ${utcDateFormatter(
			option.fromDate
		)} - ${utcDateFormatter(option.toDate)} - ${option.status.value}`;

	const serviceChargeItem = userContext.data.selectedOutlet.settings
		? userContext.data.selectedOutlet.settings.meterReadServiceCharge
		: null;

	const [
		reservationItem,
		reservationItemOnChange,
		reservationItemValRes,
		reservationItemShowVal,
		setReservationItemShowVal,
	] = useField(data, 'reservationItem', onFormChange, [required], null);

	const [
		previousReading,
		previousReadingOnChange,
		previousReadingValRes,
		previousReadingShowVal,
		setPreviousReadingShowVal,
	] = useField(
		data,
		'previousReading',
		onFormChange,
		[required, numeric],
		'',
		numberParser(true)
	);

	const [
		currentReading,
		currentReadingOnChange,
		currentReadingValRes,
		currentReadingShowVal,
		setCurrentReadingShowVal,
		validateCurrentReading,
	] = useField(
		data,
		'currentReading',
		onFormChange,
		[numeric, currentReadingValidate],
		'',
		numberParser(true)
	);

	const [
		costPerUnit,
		costPerUnitOnChange,
		costPerUnitValRes,
		costPerUnitShowVal,
		setCostPerUnitShowVal,
	] = useField(data, 'costPerUnit', onFormChange, [required, numeric], '', numberParser(true));

	const [totalCost, totalCostOnChange] = useField(data, 'totalCost', onFormChange, [], 0);

	const getUsage = useCallback(() => {
		if (previousReading === '' || currentReading === '') return 0;

		const usage = parseFloat(currentReading) - parseFloat(previousReading);

		return Math.max(usage, 0);
	}, [previousReading, currentReading]);

	useEffect(() => {
		if (!init.current) return;

		const totals = calculateMeterReadingTotal(
			previousReading,
			currentReading,
			costPerUnit,
			data.powerMeter,
			serviceCharge,
			userContext.data.selectedOutlet.settings.taxRate,
			serviceChargeItem,
			alwaysCharge
		);

		totalCostOnChange({
			target: {
				value: totals.total,
			},
		});
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [previousReading, currentReading, costPerUnit, serviceCharge]);

	useEffect(() => {
		validateCurrentReading(currentReading);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [previousReading]);

	useEffect(() => {
		init.current = true;
	}, []);

	useEffect(() => {
		if (isSubmitted) {
			setReservationItemShowVal();
			setPreviousReadingShowVal();
			setCurrentReadingShowVal();
			setCostPerUnitShowVal();
		}
	}, [
		isSubmitted,
		setReservationItemShowVal,
		setPreviousReadingShowVal,
		setCurrentReadingShowVal,
		setCostPerUnitShowVal,
	]);

	useEffect(() => {
		setIsValid(
			currentReading === ''
				? true
				: reservationItemValRes.isValid &&
						previousReadingValRes.isValid &&
						currentReadingValRes.isValid &&
						costPerUnitValRes.isValid
		);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [
		reservationItemValRes.isValid,
		previousReadingValRes.isValid,
		currentReadingValRes.isValid,
		costPerUnitValRes.isValid,
		currentReading,
	]);

	return (
		<tr>
			<td>{data.powerMeter.space.unitGroup.name}</td>
			<td>{data.powerMeter.space.name}</td>
			<td>{data.powerMeter.name}</td>
			<td>{data.powerMeter.powerMeterType.name}</td>
			<td>
				<FormField
					name='reservationItem'
					id={data.id}
					valRes={reservationItemValRes}
					showValidation={reservationItemShowVal && currentReading !== ''}
					inFormDesign={false}
					isLast>
					<AsyncSelect
						options={reservationItem ? [reservationItem] : []}
						placeholder='Select a Booking'
						value={reservationItem}
						onChange={reservationItemOnChange}
						onBlur={setReservationItemShowVal}
						route='searchPowerMeterBookings'
						field='search'
						optionalShow={optionalShow}
						renderOption={renderOption}
						presetFilters={data.powerMeter ? { powerMeterId: data.powerMeter.id } : {}}
						displayKey='id'
						valueKey='id'
						minSearchChar={3}
						notFoundText={`Cannot found booking for ${
							data.powerMeter ? data.powerMeter.name : ''
						} power meter.`}
					/>
				</FormField>
			</td>
			<td>
				<FormField
					name='previousReading'
					id={data.id}
					valRes={previousReadingValRes}
					showValidation={previousReadingShowVal && currentReading !== ''}
					inFormDesign={false}
					isLast>
					<Input
						placeholder='Previous Reading'
						value={previousReading}
						onChange={previousReadingOnChange}
						onBlur={setPreviousReadingShowVal}
						pattern={process.env.REACT_APP_PRICE_PATTERN}
						disabled={!userContext.hasPermission('marina_enable_previous_reading')}
					/>
				</FormField>
			</td>
			<td>
				<FormField
					name='currentReading'
					id={data.id}
					valRes={currentReadingValRes}
					showValidation={currentReadingShowVal && currentReading !== ''}
					inFormDesign={false}
					isLast>
					<Input
						placeholder='Current Reading'
						value={currentReading}
						onChange={currentReadingOnChange}
						onBlur={setCurrentReadingShowVal}
						pattern={process.env.REACT_APP_PRICE_PATTERN}
					/>
				</FormField>
			</td>
			<td>
				<Input
					placeholder='Usage'
					value={`${getUsage()} ${powerMeterUnit}`}
					onChange={() => {}}
					readOnly
					borderLess
				/>
			</td>
			<td>
				<FormField
					name='currentReading'
					id={data.id}
					valRes={costPerUnitValRes}
					showValidation={costPerUnitShowVal && currentReading !== ''}
					inFormDesign={false}
					isLast>
					<Input
						placeholder='Cost Per'
						value={costPerUnit}
						onChange={costPerUnitOnChange}
						onBlur={setCostPerUnitShowVal}
					/>
				</FormField>
			</td>
			<td>
				<Input
					placeholder='Total'
					value={priceFormatter(totalCost)}
					onChange={() => {}}
					readOnly
					borderLess
				/>
			</td>
		</tr>
	);
};

MeterReadingItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	onFormChange: PropTypes.func,
	powerMeterUnit: PropTypes.string,
	isSubmitted: PropTypes.bool,
	setIsValid: PropTypes.func,
	serviceCharge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

MeterReadingItem.defaultProps = {
	data: {},
	onFormChange: () => {},
	powerMeterUnit: 'kWh',
	isSubmitted: false,
	setIsValid: () => {},
	serviceCharge: 0,
};

const modals = {
	SPACE_GROUPS: 'spaceGroups',
	DIALOG: 'dialog',
};

const MeterReadBatchForm = ({
	isListsLoading,
	unitGroups,
	paymentTerms,
	onFormChange,
	isSubmitted,
	setIsValid,
	isSubmitting,
	data,
}) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const refForTimePicker = useRef(null);

	const sizeTimePicker = useComponentSize(refForTimePicker);

	const [modal, openModal, closeModal] = useModal();

	const [itemKey, setItemKey] = useState(Date.now());

	const [isLoading, setIsLoading] = useState(true);

	// I am not sure about using booking hourly step for reading time.
	const timePickerStep = useRef(getBookingHourlyStep(userContext));

	const powerMeterUnit = useRef(
		userContext.data.selectedOutlet.settings &&
			userContext.data.selectedOutlet.settings.powerMeterUnit
			? userContext.data.selectedOutlet.settings.powerMeterUnit
			: 'kWh'
	);

	const [spaceGroup, spaceGroupOnChange] = useField(data, 'spaceGroup', onFormChange, [], null);

	const serviceChargeItem = userContext.data.selectedOutlet.settings
		? userContext.data.selectedOutlet.settings.meterReadServiceCharge
		: null;

	const [
		readingDate,
		readingDateOnChange,
		readingDateValRes,
		readingDateShowVal,
		setReadingDateShowVal,
	] = useField(data, 'readingDate', onFormChange, [required], null);

	const [
		readingTime,
		readingTimeOnChange,
		readingTimeValRes,
		readingTimeShowVal,
		setReadingTimeShowVal,
	] = useField(data, 'readingTime', onFormChange, [required], null);

	const [invoiceDate, invoiceDateOnChange] = useField(
		data,
		'invoiceDate',
		onFormChange,
		[],
		null
	);

	const [invoiceNow, invoiceNowOnChange] = useField(data, 'invoiceNow', onFormChange, [], false);

	const [
		serviceCharge,
		serviceChargeOnChange,
		serviceChargeValRes,
		serviceChargeShowVal,
		setServiceChargeShowVal,
	] = useField(data, 'serviceCharge', onFormChange, [numeric], '', numberParser(true));

	const [autoPay, autoPayOnChange] = useField(
		data,
		'autoPay',
		onFormChange,
		[],
		(userContext.data.selectedOutlet.settings &&
			userContext.data.selectedOutlet.settings.autoPayMarina) ||
			false
	);

	const [paymentTerm, paymentTermOnChange] = useField(
		data,
		'paymentTerm',
		onFormChange,
		[],
		null
	);

	// using useField making loop.
	const [items, setItems] = useState([]);

	const parsePowerMeter = _powerMeters => {
		if (!_powerMeters || _powerMeters.length === 0) return [];

		const parsedReadings = [..._powerMeters].map(powerMeter => ({
			powerMeter,
			isValid: true,
			previousReading: powerMeter.previousReading || 0,
			reservationItem: powerMeter.reservationItem,
			costPerUnit: powerMeter.product.price,
			currentReading: '',
			totalCost: 0,
		}));

		const spaceGroupsNames = [
			...new Set(parsedReadings.map(r => r.powerMeter.space.unitGroup.name)),
		].sort((a, b) => (a > b ? 1 : -1));

		const spaces = [...new Set(parsedReadings.map(r => r.powerMeter.space.name))].sort((a, b) =>
			a > b ? 1 : -1
		);

		const powerMetersNames = [
			...new Set(parsedReadings.map(r => r.powerMeter.name)),
		].sort((a, b) => (a > b ? 1 : -1));

		const powerMeterTypes = [
			...new Set(parsedReadings.map(r => r.powerMeter.powerMeterType.name)),
		].sort((a, b) => (a > b ? 1 : -1));

		const sortedReadings = [];

		spaceGroupsNames.forEach(sg => {
			spaces.forEach(s => {
				powerMetersNames.forEach(pm => {
					powerMeterTypes.forEach(pmt => {
						const r = parsedReadings.find(
							fr =>
								fr.powerMeter.space.unitGroup.name === sg &&
								fr.powerMeter.space.name === s &&
								fr.powerMeter.name === pm &&
								fr.powerMeter.powerMeterType.name === pmt &&
								fr.powerMeter.inactive === false
						);

						if (r) sortedReadings.push(r);
					});
				});
			});
		});

		return sortedReadings;
	};

	const getPowerMeters = () => {
		setIsLoading(true);

		setItems([]);

		apiCall(
			'GET',
			'powerMeters',
			res => {
				setItems(parsePowerMeter(res));
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			null,
			{
				pagination: false,
				'module.value': modules.MARINA,
				'space.unitGroup.id': spaceGroup.id,
			}
		);
	};

	useEffect(() => {
		if (isSubmitted) {
			setReadingDateShowVal();
			setReadingTimeShowVal();
			setServiceChargeShowVal();
		}
	}, [isSubmitted, setReadingDateShowVal, setReadingTimeShowVal, setServiceChargeShowVal]);

	useEffect(() => {
		setIsValid(
			readingDateValRes.isValid &&
				readingTimeValRes.isValid &&
				serviceChargeValRes.isValid &&
				items.filter(i => i.isValid === false).length === 0 &&
				items.filter(i => i.currentReading).length > 0
		);
	}, [
		setIsValid,
		readingDateValRes.isValid,
		readingTimeValRes.isValid,
		serviceChargeValRes.isValid,
		items,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.marina.default.text,
				path: pages.marina.dashboard.path,
			},
			{
				title: pages.marina.meterReadings.text,
				path: pages.marina.meterReadings.path,
			},
			{ title: pages.marina.meterReadings.batch.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.marina.meterReadings.batch.text);

		readingDateOnChange({
			target: {
				value: moment()
					.utc(true)
					.toISOString(),
			},
		});

		readingTimeOnChange({
			target: {
				value: moment()
					.utc(true)
					.toISOString(),
			},
		});

		if (serviceChargeItem)
			serviceChargeOnChange({
				target: {
					value: serviceChargeItem.price,
				},
			});

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	useEffect(() => {
		if (!isListsLoading && unitGroups.length > 0)
			spaceGroupOnChange({ target: { value: unitGroups[0] } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isListsLoading]);

	useEffect(() => {
		data.items = [...items];
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [items]);

	useEffect(() => {
		// create new items with new power meter after submit.
		if (!isSubmitting && isSubmitted && data.powerMeters) {
			setItems(parsePowerMeter(data.powerMeters));
			setItemKey(Date.now());
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isSubmitting]);

	useEffect(() => {
		if (spaceGroup) getPowerMeters();
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [spaceGroup]);

	return (
		<>
			<Portlet className='flex-grow-0' hasFrame style={{ minHeight: 216.89 }}>
				<Portlet.Body>
					<FormGroup>
						<FormField name='spaceGroup' label='Space Group' id='spaceGroup' colLg={3}>
							<Button
								label='dark'
								icon='Search'
								size='sm'
								block
								disabled={isLoading}
								onClick={() => openModal({ open: modals.SPACE_GROUPS })}>
								{spaceGroup ? spaceGroup.name : 'All Space Groups'}
							</Button>
						</FormField>
						<FormField
							name='readingDate'
							label='Reading Date'
							valRes={readingDateValRes}
							showValidation={readingDateShowVal}
							id='readingDate'
							colLg={3}>
							<DatePicker
								id='readingDate'
								type='calendar'
								value={parseDatePickerValue(readingDate)}
								onChange={e => {
									readingDateOnChange({
										target: {
											value: parseDatePickerChange(
												e.target.value,
												readingDate
											),
										},
									});
								}}
								onBlur={setReadingDateShowVal}
								disabled={isLoading}
							/>
						</FormField>
						<FormField
							name='readingTime'
							label='Reading Time'
							valRes={readingTimeValRes}
							showValidation={readingTimeShowVal}
							id='readingTime'
							colLg={3}>
							<TimePickerInput
								showSecond={false}
								value={moment(readingTime).utc(false)}
								defaultValue={moment()}
								onChange={target => {
									readingTimeOnChange({
										target: {
											value: target
												? moment(readingTime)
														.utc(false)
														.hour(target.hour())
														.minute(target.minute())
														.toISOString()
												: null,
										},
									});
								}}
								size={sizeTimePicker}
								use12Hours
								minuteStep={timePickerStep.current}
								onClose={setReadingTimeShowVal}
								disabled={isLoading}
							/>
						</FormField>
						<FormField
							name='invoiceDate'
							label='Invoice Date'
							id='invoiceDate'
							colLg={3}>
							<DatePicker
								id='invoiceDate'
								type='calendar'
								value={parseDatePickerValue(invoiceDate)}
								onChange={e => {
									invoiceDateOnChange({
										target: {
											value: parseDatePickerChange(
												e.target.value,
												invoiceDate
											),
										},
									});
								}}
								minDate={getAccountingClosedDate(userContext)}
								disabled={isLoading}
							/>
						</FormField>
						<FormField
							name='serviceCharge'
							label='Service Charge'
							id='serviceCharge'
							valRes={serviceChargeValRes}
							showValidation={serviceChargeShowVal}
							colLg={3}>
							<Input
								placeholder='Service Charge'
								value={serviceCharge}
								onChange={serviceChargeOnChange}
								onBlur={setServiceChargeShowVal}
								prependIcon='Dollar'
								disabled={!serviceChargeItem || isLoading}
							/>
						</FormField>
						<FormField name='invoiceNow' label='Invoice Now' id='invoiceNow' colLg={3}>
							<Toggle
								value={invoiceNow}
								onChange={invoiceNowOnChange}
								disabled={isLoading}
							/>
						</FormField>

						<FormField name='autoPay' label='Auto Pay' id='autoPay' colLg={3}>
							<Toggle
								value={autoPay}
								onChange={autoPayOnChange}
								disabled={isLoading}
							/>
						</FormField>
						<FormField
							name='paymentTerm'
							label='Payment Term'
							id='paymentTerm'
							colLg={3}>
							<Selects
								options={paymentTerms}
								placeholder='Select a Payment Term'
								value={paymentTerm}
								onChange={paymentTermOnChange}
								disabled={isLoading}
							/>
						</FormField>
					</FormGroup>
				</Portlet.Body>
			</Portlet>
			<Portlet className='sdms-form sdms-list-layout sdms-list-scroll' fluid='fluid' hasFrame>
				<Portlet.Body>
					<ListBody
						className='table--everytime--scroll sdms-portlet__body--fit'
						responsive='scroll'>
						<ListTable childrenLength={11} width={1488}>
							<colgroup>
								<col />
								<col />
								<col />
								<col />
								<col width={240} />
								<col />
								<col />
								<col />
								<col />
								<col />
							</colgroup>
							<thead>
								<tr>
									<th>Space Group</th>
									<th>Space</th>
									<th>Meter</th>
									<th>Meter Type</th>
									<th>Booking</th>
									<th>Previous Reading</th>
									<th>Current Reading</th>
									<th>Usage</th>
									<th>Cost Per</th>
									<th>Total</th>
								</tr>
							</thead>
							<tbody>
								{isLoading ? (
									<tr>
										<td colSpan={10}>
											<ReactSVG
												src={logoLoading}
												className='svg-container logo-loading sdms-table-row-logo-loading'
											/>
										</td>
									</tr>
								) : (
									items.map((item, index) => (
										<MeterReadingItem
											key={`${item.powerMeter.id}-${itemKey}`}
											data={item}
											onFormChange={onFormChange}
											isLoading={isLoading}
											powerMeterUnit={powerMeterUnit.current}
											isSubmitted={isSubmitted}
											setIsValid={_isValid => {
												setItems([
													...update(items, {
														[index]: { $merge: { isValid: _isValid } },
													}),
												]);
											}}
											serviceCharge={
												serviceCharge ? parseFloat(serviceCharge) : ''
											}
										/>
									))
								)}
							</tbody>
						</ListTable>
					</ListBody>
				</Portlet.Body>
			</Portlet>
			<SpaceGroupModal
				isOpen={modal.open === modals.SPACE_GROUPS}
				spaceGroups={unitGroups}
				onClose={closeModal}
				onSelect={_spaceGroup => {
					openModal({ open: modals.DIALOG, spaceGroup: _spaceGroup });
				}}
			/>
			<DialogBox
				open={modal.open === modals.DIALOG}
				title=''
				content='You will lose all changes.Are you sure about continue?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					text='Cancel'
					label='danger'
					onClick={closeModal}
				/>
				<Button
					className='sdms-font-transform-c'
					text='Continue'
					design='clean'
					onClick={() => {
						spaceGroupOnChange({ target: { value: modal.spaceGroup } });
						closeModal();
					}}
				/>
			</DialogBox>
		</>
	);
};

MeterReadBatchForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	isListsLoading: PropTypes.bool,
	unitGroups: PropTypes.arrayOf(PropTypes.object),
	paymentTerms: PropTypes.arrayOf(PropTypes.object),
	onFormChange: PropTypes.func,
	isSubmitted: PropTypes.bool,
	setIsValid: PropTypes.func,
	isSubmitting: PropTypes.bool,
};

MeterReadBatchForm.defaultProps = {
	data: {},
	isListsLoading: true,
	unitGroups: [],
	paymentTerms: [],
	onFormChange: () => {},
	isSubmitted: false,
	setIsValid: () => {},
	isSubmitting: false,
};

export default MeterReadBatchForm;
