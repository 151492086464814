import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import Portal from '../layout/Portal';
import SVGIcon from '../element/SVGIcon';

const SimpleOnlineConfirmationModal = ({ onClose, isOpen }) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<Portlet className='sdms-list-layout'>
					<Portlet.Body>
						<div className='sdms-align-center'>
							<SVGIcon
								className='x-mt-30 x-mb-30'
								name='Done-circle'
								size={120}
								fill='#000'
							/>
							<div className='col-12'>
								<div className='column sdms-font-lg sdms-font-bolder sdms-align-center'>
									<div className='col sdms-align-center'>
										We appreciate your business.
									</div>
									<div className='col sdms-align-center'>
										Check your email for confirmation.
									</div>
								</div>
							</div>
						</div>
					</Portlet.Body>
					<Portlet.Foot className='sdms-align-center' tall='sm'>
						<div className='col'>
							<Button
								design='clean'
								text='Close'
								icon='Error-circle'
								size='sm'
								elevate
								onClick={onClose}
							/>
						</div>
					</Portlet.Foot>
				</Portlet>
			</Popup>
		</Portal>
	);
};

SimpleOnlineConfirmationModal.propTypes = {
	onClose: PropTypes.func,
	isOpen: PropTypes.bool,
};
SimpleOnlineConfirmationModal.defaultProps = {
	onClose: () => {},
	isOpen: false,
};

export default SimpleOnlineConfirmationModal;
