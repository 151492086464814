import React from 'react';
import { useHistory } from 'react-router-dom';

import apiCall from '../../../utils/helpers/apiCall';
import pages from '../../pages';
import { addSuccessNotification } from '../../../utils/helpers/helper';

import FormContainer from '../../reusables/template/FormContainer';
import EmailAddressAuthForm from './EmailAddressAuthForm';

const EmailAddressAuthEdit = () => {
	const history = useHistory();

	return (
		<FormContainer
			dataId='0'
			pageTitle={pages.systemSettings.emailAddresses.text}
			dataName='emailAddresses'
			fields={<EmailAddressAuthForm />}
			showBackButton={false}
			lists={['emailAddresses']}
			customSubmit={(data, formData, onSuccess, onFail) => {
				apiCall(
					'POST',
					'emailAddressAuth',
					res => {
						addSuccessNotification('Authorization successfully completed');
						history.push(`${pages.systemSettings.emailAddresses.path}/${res}`);
					},
					err => onFail(err, true),
					data.current.id || '',
					formData
				);
			}}
		/>
	);
};
export default EmailAddressAuthEdit;
