import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { SlideDown } from 'react-slidedown';
import useComponentSize from '@rehooks/component-size';
import classNames from 'classnames';

import UserContext from '../../../../../app/contexts/UserContext';
import useField from '../../../../../utils/hooks/useField';
import useDate from '../../../../../utils/hooks/useDate';
import useFeet from '../../../../../utils/hooks/useFeet';
import useModal from '../../../../../utils/hooks/useModal';
import { required } from '../../../../../utils/helpers/validation';
import {
	bookBies,
	bookingPeriods,
	reservationStatuses,
	searchTypes,
} from '../../../../../utils/constants/constants';
import { numberParser } from '../../../../../utils/helpers/helper';
import { getBookingHourlyStep } from '../../../../../utils/helpers/reusable';
import {
	hasEditSeasonDatesPermission,
	hasOverrideConstraintPermission,
} from '../../../../../utils/helpers/permission';
import { modules } from '../../../../../utils/helpers/apiCall';

import FormSection from '../../../layout/FormSection';
import DatePicker from '../../../field/DatePicker';
import FormField from '../../../template/FormField';
import Button from '../../../element/Button';
import FormSectionMoreLink from '../../../element/FormSectionMoreLink';
import LengthInputGroup from '../../../field/LengthInputGroup';
import Input from '../../../field/Input';
import Toggle from '../../../field/Toggle';
import Selects from '../../../field/Selects';
import TimePickerInput from '../../../field/TimePickerInput';
import VesselModal from '../../../modals/VesselModal';
import VehicleModal from '../../../modals/VehicleModal';

const modals = {
	VESSEL: 'vessel',
	VEHICLE: 'vehicle',
};

const Search = ({
	sectionRef,
	module,
	customer,
	reservationItem,
	vessel,
	onVesselChange,
	vehicle,
	onVehicleChange,
	onFormChange,
	seasons,
	disabled,
	onSearch,
	isSearching,
	onNeedSearch,
	onFromDateChange,
}) => {
	const userContext = useContext(UserContext);

	const refForTimePicker = useRef(null);

	const sizeTimePicker = useComponentSize(refForTimePicker);

	const timePickerStep = useRef(getBookingHourlyStep(userContext));

	const [modal, openModal, closeModal] = useModal();

	const [
		,
		,
		,
		,
		parseReservationDatePickerValue,
		parseReservationDatePickerChange,
		parseReservationTimePickerValue,
		parseReservationTimePickerChange,
	] = useDate();

	const isFieldEnabled = useCallback(
		field =>
			reservationItem.product.bookingType[field] &&
			(reservationItem.product.bookingType[field].value === searchTypes.OPTIONAL ||
				reservationItem.product.bookingType[field].value === searchTypes.REQUIRED),
		[reservationItem]
	);

	const bookingPeriod = useMemo(() => reservationItem?.product?.bookingPeriod?.value, [
		reservationItem,
	]);

	const [showMore, setShowMore] = useState(false);

	const vesselVehicleRequired = field =>
		reservationItem.product.bookingType[field]?.value === searchTypes.REQUIRED
			? [required]
			: [];

	const seasonRequired = (value, setValRes) => {
		if (bookingPeriod === bookingPeriods.SEASONAL) return required(value, setValRes);
		return true;
	};

	const toDateValidation = (value, setValRes) => {
		if (value <= fromDate) {
			setValRes({
				isValid: false,
				status: 'invalidToDate',
				message: 'Invalid to date',
			});
			return false;
		}

		return true;
	};

	const [
		season,
		seasonOnChange,
		seasonValRes,
		seasonShowVal,
		setSeasonShowVal,
	] = useField(reservationItem, 'season', onFormChange, [seasonRequired]);

	const [
		fromDate,
		fromDateOnChange,
		fromDateValRes,
		fromDateShowVal,
		setFromDateShowVal,
	] = useField(reservationItem, 'fromDate', onFormChange, [required]);

	const [
		toDate,
		toDateOnChange,
		toDateValRes,
		toDateShowVal,
		setToDateShowVal,
		validateToDate,
	] = useField(reservationItem, 'toDate', onFormChange, [toDateValidation]);

	const [loa, loaOnChange, loaValRes, loaShowVal, setLoaShowVal] = useField(
		reservationItem,
		'loa',
		onFormChange,
		[...vesselVehicleRequired('searchLoa')],
		null,
		numberParser(false)
	);

	const [loaFt, setLoaFt, loaIn, setLoaIn, , setLoa] = useFeet(
		reservationItem.loa,
		loaOnChange,
		false,
		reservationItem.loa
	);

	const [beam, beamOnChange, beamValRes, beamShowVal, setBeamShowVal] = useField(
		reservationItem,
		'beam',
		onFormChange,
		[...vesselVehicleRequired('searchBeam')],
		null,
		numberParser(false)
	);

	const [beamFt, setBeamFt, beamIn, setBeamIn, , setBeam] = useFeet(
		reservationItem.beam,
		beamOnChange,
		false,
		reservationItem.beam
	);

	const [draft, draftOnChange, draftValRes, draftShowVal, setDraftShowVal] = useField(
		reservationItem,
		'draft',
		onFormChange,
		[...vesselVehicleRequired('searchDraft')],
		null,
		numberParser(false)
	);

	const [draftFt, setDraftFt, draftIn, setDraftIn, , setDraft] = useFeet(
		reservationItem.draft,
		draftOnChange,
		false,
		reservationItem.draft
	);

	const [height, heightOnChange, heightValRes, heightShowVal, setHeightShowVal] = useField(
		reservationItem,
		'height',
		onFormChange,
		[...vesselVehicleRequired('searchHeight')],
		null,
		numberParser(false)
	);

	const [heightFt, setHeightFt, heightIn, setHeightIn, , setHeight] = useFeet(
		reservationItem.height,
		heightOnChange,
		false,
		reservationItem.height
	);

	const [weight, weightOnChange, weightValRes, weightShowVal, setWeightShowVal] = useField(
		reservationItem,
		'weight',
		onFormChange,
		[...vesselVehicleRequired('searchWeight')],
		null,
		numberParser(false)
	);

	const [ignoreRules, ignoreRulesOnChange] = useField(
		reservationItem,
		'ignoredRules',
		onFormChange,
		[],
		reservationItem.ignoreRules === true
	);

	const [waitlist, waitlistOnChange] = useField(
		reservationItem,
		'isWaitList',
		onFormChange,
		[],
		reservationItem?.status?.value === reservationStatuses.WAITLIST
	);

	const [ignoreCapacity, ignoreCapacityOnChange] = useField(
		reservationItem,
		'ignoredCapacity',
		onFormChange,
		[],
		reservationItem.ignoreCapacity === true
	);

	const constraints = useMemo(() => {
		const { product } = reservationItem;

		let productCheckInTime = moment()
			.utc(false)
			.startOf('day');

		if (product.checkInTime) productCheckInTime = moment(product.checkInTime).utc(false);

		let productCheckOutTime = moment()
			.utc(false)
			.endOf('day');

		if (bookingPeriod === bookingPeriods.HOURLY)
			productCheckOutTime = moment(product.checkInTime)
				.utc(false)
				.add(parseInt((product.bookingInterval / 60).toString(), 10), 'hour')
				.add(product.bookingInterval % 60, 'minute');
		else if (product.checkOutTime)
			productCheckOutTime = moment(product.checkOutTime).utc(false);

		const defaultFromDate = moment()
			.utc(false)
			.hour(productCheckInTime.hour())
			.minute(productCheckInTime.minute())
			.second(0)
			.millisecond(0);

		const defaultToDate = moment()
			.utc(false)
			.hour(productCheckOutTime.hour())
			.minute(productCheckOutTime.minute())
			.second(0)
			.millisecond(0);

		const forceNextDay =
			bookingPeriod === bookingPeriods.NIGHTLY || bookingPeriod === bookingPeriods.SEASONAL;

		// add one day default toDate if product has nightly or seasonal period
		if (forceNextDay) defaultToDate.add(1, 'day');

		if (bookingPeriod === bookingPeriods.LONG_TERM)
			defaultToDate.add(
				product.maxBookInAdvance || process.env.REACT_APP_LONG_TERM_TO_DATE,
				'day'
			);

		const _dateConstraints = {
			min: undefined,
			max: undefined,
		};

		if (bookingPeriod === bookingPeriods.LONG_TERM) {
			_dateConstraints.max = moment()
				.add(
					(reservationItem.product.maxBookInAdvance ||
						process.env.REACT_APP_LONG_TERM_MAX_BIA) - 1,
					'day'
				)
				.toDate();
		}

		if (bookingPeriod === bookingPeriods.SEASONAL && season) {
			_dateConstraints.min = moment(season.startDate).toDate();

			_dateConstraints.max = moment(season.endDate).toDate();
		}

		return {
			showDetailedSearch: product?.bookingType?.showDetailedSearch === true,
			onlyFromDate: bookingPeriod === bookingPeriods.LONG_TERM,
			forceNextDay,
			datePickerDisabled:
				bookingPeriod === bookingPeriods.SEASONAL &&
				(!hasEditSeasonDatesPermission(userContext) || !season),
			timePickerDisabled:
				bookingPeriod === bookingPeriods.SEASONAL &&
				(!hasEditSeasonDatesPermission(userContext) || !season),
			dateConstraints: _dateConstraints,
			defaultDates: {
				fromDate: defaultFromDate.toISOString(),
				toDate: defaultToDate.toISOString(),
			},
		};
	}, [reservationItem, bookingPeriod, userContext, season]);

	const headerAction = useMemo(() => {
		if (module === modules.MARINA)
			return {
				label: 'Existing Vessel',
				onClick: () => openModal({ open: modals.VESSEL }),
			};

		if (module === modules.CAMPGROUND)
			return {
				label: 'Existing Vehicle',
				onClick: () => openModal({ open: modals.VEHICLE }),
			};

		return { label: '', onClick: null };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [module]);

	useEffect(() => {
		// if its new item set default dates.
		if (reservationItem.id === 0) {
			fromDateOnChange({ target: { value: constraints.defaultDates.fromDate } });
			toDateOnChange({ target: { value: constraints.defaultDates.toDate } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		validateToDate(toDate);
		onFromDateChange(fromDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fromDate]);

	useEffect(() => {
		// update vessel fields on vessel change and trigger need search
		let needSearch = false;

		const bookingType = reservationItem?.product?.bookingType;

		if (reservationItem.loa !== loa) {
			setLoa(reservationItem.loa);
			loaOnChange({ target: { value: reservationItem.loa } });
			needSearch = bookingType.searchLoa?.value === searchTypes.REQUIRED;
		}

		if (reservationItem.beam !== beam) {
			setBeam(reservationItem.beam);
			beamOnChange({ target: { value: reservationItem.beam } });
			needSearch = bookingType.searchBeam?.value === searchTypes.REQUIRED;
		}

		if (reservationItem.draft !== draft) {
			setDraft(reservationItem.draft);
			draftOnChange({ target: { value: reservationItem.draft } });
			needSearch = bookingType.searchDraft?.value === searchTypes.REQUIRED;
		}

		if (reservationItem.height !== height) {
			setHeight(reservationItem.height);
			heightOnChange({ target: { value: reservationItem.height } });
			needSearch = bookingType.searchHeight?.value === searchTypes.REQUIRED;
		}

		if (reservationItem.weight !== weight) {
			weightOnChange({ target: { value: reservationItem.weight } });
			needSearch = bookingType.searchWeight?.value === searchTypes.REQUIRED;
		}

		if (needSearch) onSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reservationItem]);

	const TIME_PICKERS = (
		<>
			<FormField
				inFormDesign={false}
				ref={refForTimePicker}
				name='startTime'
				label='Start time'
				colMd={2}>
				<TimePickerInput
					showSecond={false}
					value={parseReservationTimePickerValue(fromDate)}
					defaultValue={moment()}
					onChange={target => {
						fromDateOnChange({
							target: {
								value: parseReservationTimePickerChange(fromDate, target),
							},
						});
						onNeedSearch();
					}}
					size={sizeTimePicker}
					use12Hours
					minuteStep={timePickerStep.current}
					disabled={disabled || constraints.timePickerDisabled}
					allowEmpty={false}
				/>
			</FormField>
			{!constraints.onlyFromDate && (
				<FormField
					inFormDesign={false}
					name='toDate'
					label='End time'
					colMd={2}
					showValidation={toDateShowVal}
					valRes={toDateValRes}>
					<TimePickerInput
						showSecond={false}
						defaultValue={moment()}
						value={parseReservationTimePickerValue(toDate)}
						onChange={target => {
							toDateOnChange({
								target: {
									value: parseReservationTimePickerChange(toDate, target),
								},
							});
							onNeedSearch();
						}}
						size={sizeTimePicker}
						use12Hours
						minuteStep={timePickerStep.current}
						onClose={() => setToDateShowVal()}
						disabled={disabled || constraints.timePickerDisabled}
						allowEmpty={false}
					/>
				</FormField>
			)}
		</>
	);

	return (
		<>
			<FormSection
				sectionRef={sectionRef}
				title='SEARCH'
				icon='Search'
				headerActionLabel={disabled ? '' : headerAction.label}
				onHeaderActionClick={headerAction.onClick}>
				<div className='sdms-advanced-reservation-item-form-search'>
					<div className='row'>
						{bookingPeriod === bookingPeriods.SEASONAL && (
							<FormField
								label='Season'
								name='season'
								inFormDesign={false}
								colMd={3}
								showValidation={seasonShowVal}
								valRes={seasonValRes}>
								<Selects
									onChange={e => {
										seasonOnChange(e);

										fromDateOnChange({
											target: {
												value: e.target.value
													? moment(e.target.value.startDate)
															.utc(false)
															.startOf('day')
															.toISOString()
													: null,
											},
										});

										toDateOnChange({
											target: {
												value: e.target.value
													? moment(e.target.value.endDate)
															.utc(false)
															.endOf('day')
															.toISOString()
													: null,
											},
										});

										onNeedSearch();
									}}
									options={seasons}
									value={season}
									onBlur={setSeasonShowVal}
									placeholder='Select a Season (Required)'
									disabled={disabled}
								/>
							</FormField>
						)}
						<FormField
							name='fromDate'
							label={constraints.onlyFromDate ? 'From Date' : 'Dates'}
							valRes={fromDateValRes}
							showValidation={fromDateShowVal}
							colMd={3}
							inFormDesign={false}>
							<DatePicker
								id='fromDate'
								placeholder={constraints.onlyFromDate ? 'From Date' : 'Dates'}
								type={constraints.onlyFromDate ? 'calendar' : 'dateRange'}
								value={
									constraints.onlyFromDate
										? parseReservationDatePickerValue(fromDate)
										: {
												startDate: parseReservationDatePickerValue(
													fromDate
												),
												endDate: parseReservationDatePickerValue(toDate),
										  }
								}
								onChange={e => {
									if (constraints.onlyFromDate) {
										fromDateOnChange({
											target: {
												value: parseReservationDatePickerChange(
													fromDate,
													e.target.value
												),
											},
										});
									} else {
										fromDateOnChange({
											target: {
												value: parseReservationDatePickerChange(
													fromDate,
													e.startDate
												),
											},
										});
										toDateOnChange({
											target: {
												value: parseReservationDatePickerChange(
													toDate,
													e.endDate
												),
											},
										});
									}
									onNeedSearch();
								}}
								minDate={constraints.dateConstraints.min}
								maxDate={constraints.dateConstraints.max}
								onBlur={setFromDateShowVal}
								disabled={disabled || constraints.datePickerDisabled}
								forceNextDay={constraints.forceNextDay}
								disableClear
							/>
						</FormField>
						{TIME_PICKERS}
						{isFieldEnabled('searchLoa') && (
							<FormField
								name='loa'
								label='Length Overall'
								colMd={constraints.showDetailedSearch ? 3 : 2}
								inFormDesign={false}
								showValidation={loaShowVal}
								valRes={loaValRes}>
								<LengthInputGroup
									ft={loaFt}
									ftOnChange={setLoaFt}
									inch={loaIn}
									inchOnChange={setLoaIn}
									placeHolder='Length Overall'
									onBlur={setLoaShowVal}
									withInch={constraints.showDetailedSearch}
									disabled={disabled}
									onAnyChange={onNeedSearch}
								/>
							</FormField>
						)}
						{isFieldEnabled('searchBeam') && (
							<FormField
								name='beam'
								label='Beam'
								colMd={constraints.showDetailedSearch ? 3 : 2}
								inFormDesign={false}
								showValidation={beamShowVal}
								valRes={beamValRes}>
								<LengthInputGroup
									ft={beamFt}
									ftOnChange={setBeamFt}
									inch={beamIn}
									inchOnChange={setBeamIn}
									placeHolder='Beam'
									onBlur={setBeamShowVal}
									withInch={constraints.showDetailedSearch}
									disabled={disabled}
									onAnyChange={onNeedSearch}
								/>
							</FormField>
						)}
						{isFieldEnabled('searchDraft') && (
							<FormField
								name='draft'
								label='Draft'
								colMd={constraints.showDetailedSearch ? 3 : 2}
								inFormDesign={false}
								showValidation={draftShowVal}
								valRes={draftValRes}>
								<LengthInputGroup
									ft={draftFt}
									ftOnChange={setDraftFt}
									inch={draftIn}
									inchOnChange={setDraftIn}
									placeHolder='Draft'
									onBlur={setDraftShowVal}
									withInch={constraints.showDetailedSearch}
									disabled={disabled}
									onAnyChange={onNeedSearch}
								/>
							</FormField>
						)}
						{isFieldEnabled('searchHeight') && (
							<FormField
								name='height'
								label='Height'
								colMd={constraints.showDetailedSearch ? 3 : 2}
								inFormDesign={false}
								showValidation={heightShowVal}
								valRes={heightValRes}>
								<LengthInputGroup
									ft={heightFt}
									ftOnChange={setHeightFt}
									inch={heightIn}
									inchOnChange={setHeightIn}
									placeHolder='Height'
									onBlur={setHeightShowVal}
									withInch={constraints.showDetailedSearch}
									disabled={disabled}
									onAnyChange={onNeedSearch}
								/>
							</FormField>
						)}
						{isFieldEnabled('searchWeight') && (
							<FormField
								name='weight'
								label='Weight'
								colMd={2}
								inFormDesign={false}
								showValidation={weightShowVal}
								valRes={weightValRes}>
								<Input
									type='number'
									withOutSpin
									min={0}
									placeholder='Weight'
									value={weight}
									onChange={e => {
										weightOnChange(e);
										onNeedSearch();
									}}
									pattern={process.env.REACT_APP_INTEGER_PATTERN}
									append='lbs'
									onBlur={setWeightShowVal}
									disabled={disabled}
								/>
							</FormField>
						)}
						<div
							className={classNames(
								'sdms-advanced-reservation-search-button-container',
								'col-lg-auto',
								'col',
								'ml-auto',
								'd-flex',
								'align-items-center'
							)}>
							<Button
								className={classNames(
									'col-auto',
									'd-flex',
									'align-items-center',
									'sdms-mb-5'
								)}
								design='info'
								icon='Search'
								wide='wider'
								block
								elevate
								text={isSearching ? 'Searching' : 'Search'}
								onClick={() => {
									setToDateShowVal();
									setSeasonShowVal();
									setLoaShowVal();
									setBeamShowVal();
									setDraftShowVal();
									setHeightShowVal();
									setWeightShowVal();
									if (
										toDateValRes.isValid &&
										seasonValRes.isValid &&
										loaValRes.isValid &&
										beamValRes.isValid &&
										draftValRes.isValid &&
										heightValRes.isValid &&
										weightValRes.isValid
									)
										onSearch();
								}}
								disabled={disabled}
								isSubmitting={isSearching}
							/>
						</div>
					</div>
					<SlideDown>
						{showMore ? (
							<div className='row'>
								<FormField
									inFormDesign={false}
									name='waitlist'
									label='Waitlist'
									colXl={1}
									colMd={2}>
									<Toggle
										value={waitlist}
										onChange={e => {
											onNeedSearch();
											waitlistOnChange(e);
										}}
										disabled={
											disabled ||
											(reservationItem.id !== 0 &&
												reservationItem.status.value !==
													reservationStatuses.WAITLIST)
										}
									/>
								</FormField>
								<FormField
									inFormDesign={false}
									name='ignoreRules'
									label='No rules'
									colXl={1}
									colMd={2}>
									<Toggle
										value={ignoreRules}
										onChange={ignoreRulesOnChange}
										noPermission={!hasOverrideConstraintPermission(userContext)}
										disabled={disabled}
									/>
								</FormField>
								{reservationItem.product.bookingType.bookBy.value !==
									bookBies.UNIT && (
									<FormField
										inFormDesign={false}
										name='ignoreCapacity'
										label='Ignore Capacity'
										col={2}>
										<Toggle
											value={ignoreCapacity}
											onChange={ignoreCapacityOnChange}
											noPermission={
												!hasOverrideConstraintPermission(userContext)
											}
											disabled={disabled}
										/>
									</FormField>
								)}
							</div>
						) : null}
					</SlideDown>
					<div className='row sdms-mb-15 sdms-row-align-items'>
						<div className='col-auto'>
							<FormSectionMoreLink
								text='Advanced Search'
								isOpen={showMore}
								onClick={() => setShowMore(!showMore)}
							/>
						</div>
					</div>
				</div>
			</FormSection>
			<VesselModal
				isOpen={modal.open === modals.VESSEL}
				onClose={closeModal}
				defaultVessel={vessel}
				onSelect={v => {
					onVesselChange(v);
					closeModal();
				}}
				defaultSearchText={customer?.displayName || ''}
				hasForm={false}
			/>
			<VehicleModal
				isOpen={modal.open === modals.VEHICLE}
				onClose={closeModal}
				defaultVehicle={vehicle}
				onSelect={v => {
					onVehicleChange(v);
					closeModal();
				}}
				defaultSearchText={customer?.displayName || ''}
				hasForm={false}
			/>
		</>
	);
};

Search.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	sectionRef: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	reservationItem: PropTypes.object,
	onFormChange: PropTypes.func,
	disabled: PropTypes.bool,
	seasons: PropTypes.arrayOf(PropTypes.object),
	onSearch: PropTypes.func,
	isSearching: PropTypes.bool,
	onNeedSearch: PropTypes.func,
	onFromDateChange: PropTypes.func,
	module: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	customer: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	vessel: PropTypes.object,
	onVesselChange: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	vehicle: PropTypes.object,
	onVehicleChange: PropTypes.func,
};

Search.defaultProps = {
	sectionRef: null,
	reservationItem: null,
	onFormChange: () => {},
	disabled: false,
	seasons: [],
	onSearch: () => {},
	isSearching: false,
	onNeedSearch: () => {},
	onFromDateChange: () => {},
	module: '',
	customer: null,
	vessel: null,
	onVesselChange: () => {},
	vehicle: null,
	onVehicleChange: null,
};

export default Search;
