import { bookingPeriods, serviceFeeModules } from '../constants/constants';

import { modules } from './apiCall';

export const bookingPeriodPaths = {
	Hourly: 'hourly',
	Daily: 'daily',
	Nightly: 'nightly',
	'Long Term': 'long-term',
	Seasonal: 'seasonal',
};
export const modulePageKeys = {
	Bookings: 'booking',
	Marina: 'marina',
	Campground: 'campground',
};

export const bookingPeriodPageKeys = {
	Hourly: 'hourly',
	Daily: 'daily',
	Nightly: 'nightly',
	'Long Term': 'longTerm',
	Seasonal: 'seasonal',
};

export const getBookingPeriodByPath = path => {
	const _bookingPeriods = Object.keys(bookingPeriodPaths).filter(
		bpp => path.indexOf(bookingPeriodPaths[bpp]) > -1
	);

	return _bookingPeriods.length ? _bookingPeriods[0] : '';
};

export const getModuleByPath = (path = null) => {
	if (!path) path = window.location.pathname;

	if (path.indexOf('accounting') > -1) return modules.ACCOUNTING;

	if (path.indexOf('booking') > -1) return modules.BOOKINGS;

	if (path.indexOf('crm') > -1) return modules.CRM;

	if (path.indexOf('marina') > -1) return modules.MARINA;

	if (path.indexOf('campground') > -1) return modules.CAMPGROUND;

	if (path.indexOf('pos') > -1) return modules.POS;

	return modules.BOOKINGS;
};

export const getModuleKeyByPath = (path = null) => {
	if (!path) path = window.location.pathname;

	if (path.indexOf('accounting') > -1) return 'accounting';

	if (path.indexOf('booking') > -1) return 'booking';

	if (path.indexOf('crm') > -1) return 'crm';

	if (path.indexOf('marina') > -1) return 'marina';

	if (path.indexOf('campground') > -1) return 'campground';

	if (path.indexOf('pos') > -1) return 'pos';

	return 'booking';
};

export const filterBookingTypeOptions = (bt, period) =>
	(period === bookingPeriods.DAILY && bt.daily) ||
	(period === bookingPeriods.NIGHTLY && bt.nightly) ||
	(period === bookingPeriods.HOURLY && bt.hourly) ||
	(period === bookingPeriods.LONG_TERM && bt.longTerm) ||
	(period === bookingPeriods.SEASONAL && bt.seasonal);

export const getBookingHourlyStep = userContext =>
	userContext.data.selectedOutlet.settings &&
	userContext.data.selectedOutlet.settings.bookingHourlyStep
		? userContext.data.selectedOutlet.settings.bookingHourlyStep
		: 15;

export const getAvailableUnits = (product, pricing, module) => {
	const { unitBookings, unitMarinas, unitCampgrounds } = product;

	let _availableUnits = unitBookings;

	if (module === modules.MARINA) _availableUnits = unitMarinas;
	else if (module === modules.CAMPGROUND) _availableUnits = unitCampgrounds;

	return _availableUnits
		.filter(ub => pricing.units.includes(ub.id))
		.sort((a, b) => a.name.localeCompare(b.name));
};

export const getReservationGridCustomFilters = bookingPeriod => {
	if (bookingPeriod === bookingPeriods.LONG_TERM)
		return { 'bookingPeriod.value': bookingPeriods.LONG_TERM };

	if (bookingPeriod === bookingPeriods.SEASONAL)
		return { 'bookingPeriod.value': bookingPeriods.SEASONAL };

	return {
		'bookingPeriod.value[]': [
			bookingPeriods.HOURLY,
			bookingPeriods.DAILY,
			bookingPeriods.NIGHTLY,
		],
	};
};

export const getServiceFeeModule = (module, bookingPeriod) => {
	if (module === modules.BOOKINGS) return serviceFeeModules.BOOKING;
	if (bookingPeriod === bookingPeriods.LONG_TERM || bookingPeriod === bookingPeriods.SEASONAL)
		return serviceFeeModules.SLIPS;
	return serviceFeeModules.TRANSIENT_SLIPS;
};

export const getItemQuantity = item => {
	let text = 'Hour';

	if (item.product.bookingPeriod.value === bookingPeriods.DAILY) text = 'Day';

	if (item.product.bookingPeriod.value === bookingPeriods.NIGHTLY) text = 'Night';

	return `${item.quantity} ${text}${item.quantity > 1 ? 's' : ''}`;
};
