import React, { useLayoutEffect } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import DocumentTitle from 'react-document-title';
import { motion } from 'framer-motion';
import SVGIcon from '../element/SVGIcon';
import { pageTransition, pageVariants } from '../../../utils/framer';

const SubHeaderTitle = ({ title, icon }) => {
	return (
		<>
			{icon && <SVGIcon name={icon} className='sdms-mr-5' />}
			<h3 className='sdms-subheader__title'>{title}</h3>
		</>
	);
};
SubHeaderTitle.propTypes = {
	title: PropTypes.string,
	icon: PropTypes.string,
};
SubHeaderTitle.defaultProps = {
	title: null,
	icon: null,
};

const SubHeaderSeparator = ({ className }) => {
	return (
		<div
			className={classNames(
				'sdms-subheader__separator',
				'sdms-subheader__separator--v',
				className
			)}
		/>
	);
};
SubHeaderSeparator.propTypes = {
	className: PropTypes.string,
};
SubHeaderSeparator.defaultProps = {
	className: null,
};

const SubHeaderGroup = ({ children }) => {
	return <div className='sdms-subheader__group'>{children}</div>;
};
SubHeaderGroup.propTypes = {
	children: PropTypes.node.isRequired,
};

const SubHeaderDesc = ({ children, className }) => {
	return <span className={classNames('sdms-subheader__desc', className)}>{children}</span>;
};
SubHeaderDesc.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
SubHeaderDesc.defaultProps = {
	className: null,
};

const SubHeaderItem = ({ children, type }) => {
	return (
		<motion.div
			className={classNames({ [`sdms-subheader__${type}`]: type })}
			initial='initial'
			animate='in'
			exit='out'
			variants={pageVariants}
			transition={pageTransition}>
			{children}
		</motion.div>
	);
};
SubHeaderItem.propTypes = {
	children: PropTypes.node.isRequired,
	type: PropTypes.oneOf(['main', 'toolbar']),
};
SubHeaderItem.defaultProps = {
	type: 'main',
};

const SubHeader = ({ children }) => {
	useLayoutEffect(() => {
		document.body.classList.add(
			'sdms-subheader--fixed',
			'sdms-subheader--enabled',
			'sdms-subheader--solid'
		);
		return () => {
			document.body.classList.remove('sdms-subheader--enabled');
		};
	}, []);
	return (
		<div id='sdms_subheader' className='sdms-subheader sdms-grid__item'>
			{children}
		</div>
	);
};
SubHeader.propTypes = {
	children: PropTypes.node.isRequired,
};

const SubFooter = ({ children, className }) => {
	useLayoutEffect(() => {
		document.body.classList.add('sdms-container__footer--enabled');
		return () => {
			document.body.classList.remove('sdms-container__footer--enabled');
		};
	});
	return <div className={classNames('sdms-container__footer', className)}>{children}</div>;
};
SubFooter.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
SubFooter.defaultProps = {
	className: null,
};

const Container = ({ children, title, hasFrame, isFluid, hasDocTitle, noPadding }) => {
	const containerClass = classNames(
		'sdms-container',
		{ 'sdms-container--fluid': isFluid },
		{ 'sdms-container--no-padding': noPadding },
		'sdms-grid__item',
		'sdms-grid__item--fluid',
		'sdms-grid sdms-grid--hor'
	);
	const _Content = (
		<>
			{hasFrame ? (
				<motion.div
					id='sdms_container'
					className={containerClass}
					initial='initial'
					animate='in'
					exit='out'
					variants={pageVariants}
					transition={pageTransition}>
					{children}
				</motion.div>
			) : (
				<div id='sdms_container' className={containerClass}>
					{children}
				</div>
			)}
		</>
	);
	if (hasDocTitle) {
		return (
			<DocumentTitle title={`${title || 'Loading...'} | Sharper MMS`}>
				{_Content}
			</DocumentTitle>
		);
	}
	return _Content;
};
Container.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	hasFrame: PropTypes.bool,
	isFluid: PropTypes.bool,
	hasDocTitle: PropTypes.bool,
	noPadding: PropTypes.bool,
};
Container.defaultProps = {
	title: 'Dashboard',
	hasFrame: false,
	isFluid: true,
	hasDocTitle: true,
	noPadding: false,
};

const ContentInner = () => {
	throw new Error(`Uyarı`);
};

ContentInner.SubHeaderTitle = SubHeaderTitle;
ContentInner.SubHeaderSeparator = SubHeaderSeparator;
ContentInner.SubHeaderGroup = SubHeaderGroup;
ContentInner.SubHeaderDesc = SubHeaderDesc;
ContentInner.SubHeaderItem = SubHeaderItem;
ContentInner.SubHeader = SubHeader;
ContentInner.SubFooter = SubFooter;
ContentInner.Container = Container;

export default ContentInner;
