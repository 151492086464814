import { useEffect, useState, useRef, useCallback } from 'react';
import { maxLength } from '../helpers/validation';

const useField = (
	data,
	field,
	onFormChange,
	valFunctions = [],
	defaultValue = '',
	parser = null,
	setTitle = null
) => {
	const [value, setValue] = useState(
		typeof data[field] === 'undefined' || data[field] === null ? defaultValue : data[field]
	);
	const [valRes, setValRes] = useState({ message: '', isValid: false });
	const [showVal, setShowVal] = useState(false);
	const [timeoutId, setTimeoutId] = useState(0);
	const updateValue = useRef(false);

	const validate = (val, customValFunctions = []) => {
		setValRes({
			isValid: true,
			status: 'valid',
			message: '',
		});

		if (field === 'name') valFunctions.unshift(maxLength());

		[...valFunctions, ...customValFunctions].every(valFunction => {
			if (!valFunction(val, setValRes, timeoutId, setTimeoutId)) {
				clearTimeout(timeoutId);
				return false;
			}
			return true;
		});
	};

	useEffect(() => {
		if (updateValue.current)
			setValue(
				typeof data[field] === 'undefined' || data[field] === null
					? defaultValue
					: data[field]
			);
		updateValue.current = true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data[field]]);

	useEffect(() => {
		validate(value);
		if (setTitle) setTitle(value);
		data[field] = parser ? parser(value) : value;
		updateValue.current = false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	useEffect(() => {
		if (valFunctions.length && valRes.isValid && value === defaultValue) {
			data[field] = parser ? parser(value) : value;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valRes.isValid]);

	const onChange = (event, triggerOnFormChange = true) => {
		setValue(event.target.value);
		if (onFormChange && triggerOnFormChange) onFormChange();
	};

	const showValidation = useCallback((val = true) => setShowVal(val), [setShowVal]);

	return [value, onChange, valRes, showVal, showValidation, validate];
};

export default useField;
