import { addFloats } from './helper';

export const getReservationItemItemAutoJournalIds = reservationItem => {
	const autoJournalIds = [];

	reservationItem.customerSettlements.forEach(customerSettlement => {
		autoJournalIds.push(
			...(customerSettlement?.remittance?.customerSettlements
				? customerSettlement.remittance.customerSettlements
						.filter(cs => cs.journal)
						.map(cs => cs.journal.id)
				: [])
		);
	});

	return autoJournalIds;
};

export const getReservationItemManualJournalLines = reservationItem => {
	if (
		(reservationItem?.journalLines || []).length === 0 ||
		!reservationItem?.depositProductAccountId
	)
		return [];

	const autoJournalIds = getReservationItemItemAutoJournalIds(reservationItem);

	return reservationItem.journalLines.filter(
		journalLine =>
			journalLine.account.id === reservationItem.depositProductAccountId &&
			autoJournalIds.indexOf(journalLine.journal.id) === -1
	);
};

export const getReservationItemManualJournalAmounts = reservationItem => {
	let refunded = 0;

	let paid = 0;

	getReservationItemManualJournalLines(reservationItem).forEach(journalLine => {
		if (journalLine.isDebit) refunded = addFloats(refunded, journalLine.amount);
		else paid = addFloats(paid, journalLine.amount);
	});

	return {
		refunded,
		paid,
	};
};

export const getReservationManualJournalAmounts = reservation => {
	let refunded = 0;

	let paid = 0;

	reservation.items.forEach(reservationItem => {
		getReservationItemManualJournalLines(reservationItem).forEach(journalLine => {
			if (journalLine.isDebit) refunded = addFloats(refunded, journalLine.amount);
			else paid = addFloats(paid, journalLine.amount);
		});
	});

	return {
		refunded,
		paid,
	};
};
