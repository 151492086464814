import React from 'react';
import PropTypes from 'prop-types';

import { priceFormatter } from '../../../../../utils/helpers/helper';

import Dropdown from '../../../element/Dropdown';

const PriceAdjustmentItem = ({ extraChargeItem, isLoading, onRemove }) => {
	return (
		<tr className='sdms-t-orders--payment'>
			<td colSpan={2} className='sdms-font-monospace'>
				{extraChargeItem?.extraCharge?.name}
			</td>
			<td className='sdms-t-orders--item__price sdms-font-monospace'>
				{priceFormatter(extraChargeItem.total)}
			</td>
			<td className='sdms-t-orders--item__actions'>
				<Dropdown
					icon='Other#1'
					size='sm'
					color={null}
					inline
					aligned='right'
					arrow={false}
					outline={false}
					disabled={isLoading}>
					<Dropdown.Item icon='Trash' type='danger' onClick={onRemove}>
						Remove
					</Dropdown.Item>
				</Dropdown>
			</td>
		</tr>
	);
};

PriceAdjustmentItem.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	extraChargeItem: PropTypes.object,
	isLoading: PropTypes.bool,
	onRemove: PropTypes.func,
};

PriceAdjustmentItem.defaultProps = {
	extraChargeItem: null,
	isLoading: false,
	onRemove: () => {},
};

export default PriceAdjustmentItem;
