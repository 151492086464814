import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import update from 'immutability-helper';
import ReactTooltip from 'react-tooltip';

import {
	getExtraChargeExcludeDates,
	getExtraChargeExcludeText,
	isItemInExtraChargeExcludeDates,
} from '../../../utils/helpers/reservationHelper';

import Checkbox from '../field/Checkbox';
import Separator from '../layout/Separator';
import { noPermissionStatus } from '../../../utils/helpers/helper';
import TouchSpin from './TouchSpin';
import Portal from '../layout/Portal';

const Item = ({
	id,
	className,
	title,
	subtitle,
	color,
	value,
	onChange,
	col,
	disabled,
	fontWeight,
	noPermission,
	multiSelectQuantity,
	setMultiSelectQuantity,
	tooltipId,
}) => {
	const [quantity, setQuantity] = useState(multiSelectQuantity);

	useEffect(() => {
		if (multiSelectQuantity !== quantity && !Number.isNaN(multiSelectQuantity))
			setQuantity(multiSelectQuantity);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [multiSelectQuantity]);

	return (
		<label
			data-tip='tooltip'
			data-for={tooltipId}
			htmlFor={id}
			className={classNames(
				'sdms-multi-select__item',
				{
					'sdms-multi-select__item--disable': disabled,
				},
				(col && [`col-md-${col}`]) || 'col-lg-4 col-xl-3',
				{ [`sdms-multi-select__item--${color}`]: color },
				className
			)}>
			<div className='sdms-multi-select__checkbox'>
				<Checkbox
					id={id}
					color='primary'
					onChange={noPermission ? () => {} : onChange}
					value={value}
					bold
					disabled={disabled}
				/>
			</div>
			<div className='sdms-multi-select__info'>
				<span
					className={classNames('sdms-multi-select__title', {
						[`sdms-font-${fontWeight}`]: fontWeight,
					})}>
					{title}
				</span>
				{subtitle && <span className='sdms-multi-select__subtitle'>{subtitle}</span>}
				{value && typeof multiSelectQuantity !== 'undefined' && (
					<span className='sdms-multi-select__subtitle'>
						<TouchSpin
							onChange={e => {
								const val = parseInt(e.target.value, 10);
								if (!Number.isNaN(val)) setQuantity(val === 0 ? 1 : val);
							}}
							value={quantity}
							minusOnClick={() => {
								if (quantity > 1) {
									setMultiSelectQuantity(quantity - 1);
									setQuantity(quantity - 1);
								}
							}}
							plusOnClick={() => {
								setMultiSelectQuantity(quantity + 1);
								setQuantity(quantity + 1);
							}}
						/>
					</span>
				)}
			</div>
			<div className='sdms-multi-select__actions'>
				{/* <Button text='Edit' design='link' onClick={() => {}} /> */}
			</div>
		</label>
	);
};
Item.propTypes = {
	id: PropTypes.string.isRequired,
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	color: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'info',
		'light',
		'primary',
		'secondary',
		'success',
		'warning',
	]),
	onChange: PropTypes.func.isRequired,
	value: PropTypes.bool,
	col: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	disabled: PropTypes.bool,
	fontWeight: PropTypes.oneOf(['bold', 'bolder', 'boldest']),
	noPermission: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	multiSelectQuantity: PropTypes.number,
	setMultiSelectQuantity: PropTypes.func,
	tooltipId: PropTypes.string,
};
Item.defaultProps = {
	className: null,
	subtitle: null,
	color: null,
	value: false,
	col: null,
	disabled: false,
	fontWeight: null,
	noPermission: null,
	setMultiSelectQuantity: () => {},
	tooltipId: '',
};

const Container = ({ children, className, noPermission }) => {
	return (
		<div
			className={classNames(
				'sdms-multi-select',
				'row',
				noPermissionStatus(noPermission),
				className
			)}>
			{children}
		</div>
	);
};
Container.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	noPermission: PropTypes.bool,
};
Container.defaultProps = {
	className: null,
	noPermission: null,
};
const MultiSelect = ({
	className,
	data,
	value,
	onChange,
	titleProp,
	subtitleProp,
	itemsCol,
	disabled,
	name,
	noPermission,
	withoutSelectAll,
	withoutSeparator,
	maxSelection,
	fromDate,
	selectedProduct,
	displayTaxes,
}) => {
	const valuesWithoutTax = displayTaxes ? value : value.filter(v => !v.isTax);

	return (
		<Container className={className} noPermission={noPermission}>
			{!withoutSelectAll && (
				<Item
					id={`selectAll${name}`}
					className='sdms-mb-0'
					title='Select all'
					value={value.length > 0 && value.length === data.length}
					onChange={e =>
						onChange({ target: { value: e.target.value ? update(data, {}) : [] } })
					}
					col={12}
					disabled={
						disabled || (maxSelection !== 0 && valuesWithoutTax.length === maxSelection)
					}
					hasMultiSelectQuantity={false}
					setMultiSelectQuantity={() => {}}
				/>
			)}
			{!withoutSeparator && <Separator type='border-dashed' space='sm' className='w-100' />}
			{data
				.filter(
					itemProp =>
						!withoutSelectAll ||
						typeof itemProp.isTax === 'undefined' ||
						!itemProp.isTax ||
						displayTaxes
				)
				.map(item => {
					const excludeExtraChargeText = getExtraChargeExcludeText(
						selectedProduct,
						fromDate,
						item
					);

					return (
						<Fragment key={`item-${name}-${item.id}`}>
							<Item
								key={`item-${name}-${item.id}`}
								id={`item-${name}-${item.id}`}
								title={`${item[titleProp] ||
									item.name ||
									''} ${excludeExtraChargeText}`}
								subtitle={item[subtitleProp] || ''}
								value={value.filter(v => v.id === item.id).length > 0}
								onChange={e =>
									onChange({
										target: {
											value: e.target.value
												? value.concat(
														data.filter(
															d =>
																`item-${name}-${d.id}` ===
																e.target.id
														)
												  )
												: value.filter(
														d => `item-${name}-${d.id}` !== e.target.id
												  ),
										},
									})
								}
								col={itemsCol}
								disabled={
									disabled ||
									(maxSelection &&
										valuesWithoutTax.length === maxSelection &&
										!value.filter(v => v.id === item.id).length > 0) ||
									(typeof item.isOptional !== 'undefined' && !item.isOptional) ||
									isItemInExtraChargeExcludeDates(selectedProduct, fromDate, item)
								}
								noPermission={noPermission}
								multiSelectQuantity={item.multiSelectQuantity}
								setMultiSelectQuantity={q => {
									const newValue = [...value];
									newValue.find(d => d.id === item.id).multiSelectQuantity = q;
									onChange({ target: { value: newValue } });
								}}
								tooltipId={`exclude_dates_${item.id}`}
							/>
							{excludeExtraChargeText !== '' && (
								<Portal>
									<ReactTooltip
										id={`exclude_dates_${item.id}`}
										type='light'
										place='bottom'>
										<h5 className='sdms-iconbox__title'>Exclude Dates:</h5>
										{getExtraChargeExcludeDates(
											selectedProduct,
											fromDate,
											item
										).map(date => (
											<div style={{ fontWeight: 400 }}>
												{date.format('ddd, MMM DD')}
											</div>
										))}
									</ReactTooltip>
								</Portal>
							)}
						</Fragment>
					);
				})}
		</Container>
	);
};
MultiSelect.propTypes = {
	className: PropTypes.string,
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
	titleProp: PropTypes.string.isRequired,
	subtitleProp: PropTypes.string,
	value: PropTypes.arrayOf(PropTypes.object).isRequired,
	onChange: PropTypes.func.isRequired,
	itemsCol: PropTypes.number,
	disabled: PropTypes.bool,
	name: PropTypes.string,
	noPermission: PropTypes.bool,
	withoutSelectAll: PropTypes.bool,
	withoutSeparator: PropTypes.bool,
	maxSelection: PropTypes.number,
	// eslint-disable-next-line react/forbid-prop-types
	fromDate: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	selectedProduct: PropTypes.object,
	displayTaxes: PropTypes.bool,
};
MultiSelect.defaultProps = {
	className: null,
	subtitleProp: null,
	itemsCol: null,
	disabled: false,
	name: '',
	noPermission: null,
	withoutSelectAll: false,
	withoutSeparator: false,
	maxSelection: 0,
	fromDate: null,
	selectedProduct: null,
	displayTaxes: false,
};

MultiSelect.Item = Item;
MultiSelect.Container = Container;

export default MultiSelect;
