import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import List from '../../reusables/template/List';
import ListContainer from '../../reusables/template/ListContainer';
import Badge from '../../reusables/element/Badge';
import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';
import forms from '../../forms';

const SendAllProductsCell = ({ data }) => {
	if (data.sendAnyProduct) {
		return <Badge.Yes />;
	}
	return <Badge.No />;
};

SendAllProductsCell.propTypes = {
	data: PropTypes.shape({
		sendAnyProduct: PropTypes.bool,
	}),
};

SendAllProductsCell.defaultProps = {
	data: {
		sendAnyProduct: false,
	},
};

const HoldForSignatureCell = ({ data }) => {
	if (data.holdForSignature) {
		return <Badge.Yes />;
	}
	return (
		<Badge design='danger' isInline isUnified size='lg' fontWeight='boldest'>
			No
		</Badge>
	);
};

HoldForSignatureCell.propTypes = {
	data: PropTypes.shape({
		holdForSignature: PropTypes.bool,
	}),
};

HoldForSignatureCell.defaultProps = {
	data: {
		holdForSignature: false,
	},
};

const RegisterTypeList = ({ history }) => {
	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.pos.default.text, path: pages.pos.dashboard.path },
			{ title: pages.pos.settings.text, path: pages.pos.settings.path },
			{ title: pages.pos.settings.registerTypes.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.pos.settings.registerTypes.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='registerTypes'
			title={pages.pos.settings.registerTypes.text}
			history={history}
			icon={pages.pos.settings.registerTypes.icon}
			forms={forms.pos.settings.registerTypes}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col
					label='Send All Products'
					cellComponent={<SendAllProductsCell />}
					sortable='sendAnyProduct'
				/>
				<List.Col
					label='Hold For Signature'
					cellComponent={<HoldForSignatureCell />}
					sortable='holdForSignature'
				/>
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};

RegisterTypeList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default RegisterTypeList;
