import React from 'react';
import { ReactSVG } from 'react-svg';
import ContentInner from '../../reusables/template/ContentInner';
import logo from '../../../assets/img/logo.svg';
import pos from '../../../assets/img/pos.svg';
import pages from '../../pages';
import WidgetGridWrapper from '../../reusables/widgets/WidgetGridWrapper';

const _Dashboard = () => {
	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title={pages.accounting.dashboard.text} />
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title={pages.accounting.dashboard.text}>
				<WidgetGridWrapper>
					<div className='row'>
						<div className='col-3' />
						<div className='col-6 sdms-align-center'>
							<ReactSVG
								src={logo}
								className='svg-container sdms-block-center sdms-w-35p-desktop sdms-margin-t-30'
							/>
							<ReactSVG
								src={pos}
								className='svg-container sdms-block-center sdms-w-70p-desktop sdms-margin-t-60'
							/>
						</div>
						<div className='col-3' />
					</div>
				</WidgetGridWrapper>
			</ContentInner.Container>
		</>
	);
};

export default _Dashboard;
