import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';

import { randomColor, useWindowSize } from '../../../utils/helpers/helper';
import { pathToUrl } from '../../../utils/helpers/apiCall';

import Badge from '../element/Badge';
import Button from '../element/Button';
import SelectableList from '../template/SelectableList';
import Search from '../element/Search';
import Portal from '../layout/Portal';
import Portlet from '../layout/Portlet';
import { mediaBreakpoint } from '../../../utils/constants/constants';
import QuickPanel from '../../QuickPanel';

const _title = 'Select a Server';
const _icon = 'User';

const UserCell = ({ data }) => {
	const _randomColor = useRef(randomColor());

	if (data.profilePicture) {
		return (
			<img
				alt={data.displayName}
				src={
					data.profilePicture &&
					data.profilePicture.thumb &&
					pathToUrl(data.profilePicture.thumb)
				}
			/>
		);
	}
	return (
		<Badge
			design={_randomColor.current}
			className='sdms-badge--username'
			isUnified
			size='lg'
			isRounded
			fontWeight='bold'>
			{data.firstName.charAt(0) + data.lastName.charAt(0)}
		</Badge>
	);
};
UserCell.propTypes = {
	data: PropTypes.shape({
		profilePicture: PropTypes.object,
		displayName: PropTypes.string,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
	}),
};
UserCell.defaultProps = {
	data: {
		profilePicture: null,
		displayName: null,
		firstName: null,
		lastName: null,
	},
};

const NameCell = ({ data, onClick }) => {
	return (
		<span onClick={onClick} role='presentation'>
			<span className='sdms-font-bold sdms-link sdms-link--dark'>{data.displayName}</span>
			<div className='sdms-font-sm sdms-list-layout__small-text'>{`${data.firstName} ${data.lastName}`}</div>
		</span>
	);
};
NameCell.propTypes = {
	data: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		displayName: PropTypes.string,
	}),
	onClick: PropTypes.func,
};
NameCell.defaultProps = {
	data: {
		firstName: null,
		lastName: null,
		displayName: null,
	},
	onClick: () => {},
};

const UserModal = ({ data, selectedItem, setSelectedItem, isOpen, onClose }) => {
	const windowSize = useWindowSize();

	if (windowSize.width < mediaBreakpoint.MD) {
		return (
			<QuickPanel
				status={isOpen}
				setStatus={onClose}
				title={_title}
				icon={_icon}
				portletClass='sdms-list-layout'>
				<UserModalContent
					data={data}
					selectedItem={selectedItem}
					setSelectedItem={setSelectedItem}
					onClose={onClose}
					withOutPortlet
				/>
			</QuickPanel>
		);
	}
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
					minHeight: '480px',
					height: '50%',
				}}>
				<UserModalContent
					data={data}
					selectedItem={selectedItem}
					setSelectedItem={setSelectedItem}
					onClose={onClose}
				/>
			</Popup>
		</Portal>
	);
};
UserModal.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.array.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	selectedItem: PropTypes.object,
	setSelectedItem: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
};
UserModal.defaultProps = {
	selectedItem: null,
};

const UserModalContent = ({ data, selectedItem, setSelectedItem, onClose, withOutPortlet }) => {
	const [searchText, setSearchText] = useState('');

	const inputRef = useRef();

	const itemTotalInfo = () => {
		const totalItems = (searchText === ''
			? data
			: data.filter(item => item.displayName.indexOf(searchText) > -1)
		).length;
		if (totalItems > 0) {
			return (
				<>
					<span>{totalItems}</span>
					<span> Total </span>
				</>
			);
		}
		return 'No Results';
	};

	useEffect(() => inputRef.current.focus(), []);

	const _Inner = (
		<>
			<Portlet.Head>
				<Portlet.HeadLabel portletIcon={!withOutPortlet && _icon}>
					{!withOutPortlet && (
						<>
							<h3 className='sdms-portlet__head-title'>{_title}</h3>
							<Portlet.Separator />
						</>
					)}
					<div className='sdms-portlet__head-desc'>{itemTotalInfo()}</div>
				</Portlet.HeadLabel>
				<Portlet.HeadLabel className='sdms-last-margin'>
					<Search
						className='sdms-mr-0'
						inPortlet
						searchText={searchText}
						setSearchText={setSearchText}
						ref={inputRef}
					/>
				</Portlet.HeadLabel>
			</Portlet.Head>
			<SelectableList
				data={
					searchText === ''
						? data
						: data.filter(
								item =>
									item.displayName
										.toLowerCase()
										.indexOf(searchText.toLowerCase()) > -1
						  )
				}
				selectedItem={selectedItem}
				onClick={setSelectedItem}
				withOutPortlet>
				<SelectableList.Col
					width={65}
					className='sdms-cursor--pointer'
					label='Image'
					cellComponent={<UserCell />}
				/>
				<SelectableList.Col
					className='sdms-cursor--pointer'
					label='Name'
					cellComponent={<NameCell />}
				/>
			</SelectableList>
			<Portlet.Foot tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Cancel'
						icon='Error-circle'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
			</Portlet.Foot>
		</>
	);

	if (withOutPortlet) {
		return _Inner;
	}
	return (
		<Portlet className='sdms-list-layout' fluid='fluid'>
			{_Inner}
		</Portlet>
	);
};
UserModalContent.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.array.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	selectedItem: PropTypes.object,
	setSelectedItem: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	withOutPortlet: PropTypes.bool,
};
UserModalContent.defaultProps = {
	selectedItem: null,
	withOutPortlet: false,
};

export default UserModal;
