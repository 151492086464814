import React from 'react';
import PropTypes from 'prop-types';
import usePages from '../../../utils/hooks/usePages';

import FormContainer from '../../reusables/template/FormContainer';
import UnitBlockoutForm from './UnitBlockoutForm';

const UnitBlockoutEdit = ({ match }) => {
	const pages = usePages();

	return (
		<FormContainer
			dataId={match.params.id}
			dataName='unitBlackouts'
			fields={<UnitBlockoutForm />}
			lists={['bookingUnits', 'enumUnitBlockoutTypes', 'bookingTypeBookings']}
			pageTitle={pages.booking.settings.unitBlackouts.text}
			icon={pages.booking.settings.unitBlackouts.icon}
		/>
	);
};

UnitBlockoutEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default UnitBlockoutEdit;
