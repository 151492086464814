import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import List from '../../reusables/template/List';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import ListContainer from '../../reusables/template/ListContainer';
import Badge from '../../reusables/element/Badge';
import forms from '../../forms';

const CalendarPeriodCell = ({ data }) => {
	if (data.calendarPeriods) {
		return (
			<>
				{data.calendarPeriods.map(p => (
					<Badge
						className={`sdms-custom-colors--${p.color}`}
						isInline
						isUnified
						size='lg'
						fontWeight='bold'
						isSpaced>
						{p.name}
					</Badge>
				))}
			</>
		);
	}
	return null;
};
CalendarPeriodCell.propTypes = {
	data: PropTypes.shape({
		calendarPeriods: PropTypes.arrayOf(
			PropTypes.shape({ name: PropTypes.string, color: PropTypes.string })
		),
	}),
};
CalendarPeriodCell.defaultProps = {
	data: null,
};

const CalendarList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.systemSettings.default.text,
				path: pages.systemSettings.dashboard.path,
			},
			{ title: pages.systemSettings.calendars.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.systemSettings.calendars.text);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='calendars'
			title={pages.systemSettings.calendars.text}
			history={history}
			icon={pages.systemSettings.calendars.icon}
			forms={forms.systemSettings.calendar}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col label='Year' cellData='year' sortable='year' />
				<List.Col label='Periods' cellComponent={<CalendarPeriodCell />} />
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
CalendarList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default CalendarList;
