import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';
import update from 'immutability-helper';

import { pathToUrl } from '../../../utils/helpers/apiCall';

import Media from '../element/Media';
import SVGIcon from '../element/SVGIcon';

export const _defaultImageSvg = `data:image/svg+xml;utf8,${ReactDOMServer.renderToString(
	<SVGIcon name='Picture' fill='rgb(24, 116, 201)' preserveAspectRatio='none' />
)}`;

export const _defaultImage = `url('data:image/svg+xml;utf8,${ReactDOMServer.renderToString(
	<SVGIcon name='Picture' fill='rgb(24, 116, 201)' preserveAspectRatio='none' />
)}')`;

const ImageUpload = ({ media, setMedia, className, defaultImage, multiple }) => {
	const [isMediaOpen, setIsMediaOpen] = useState(false);

	const editIndex = useRef(null);

	if (!multiple || !media)
		return (
			<div
				className={classNames('sdms-image', 'sdms-image--outline', className, {
					'sdms-image--changed': media,
				})}>
				<div
					className='sdms-image__holder'
					style={{
						backgroundImage: media
							? `url(${pathToUrl(media.thumb)})`
							: `${_defaultImage}`,
					}}
				/>
				<label className='sdms-image__upload' title={media ? 'Edit Image' : 'Add Image'}>
					<SVGIcon name={media ? 'Edit' : 'Plus'} onClick={() => setIsMediaOpen(true)} />
				</label>

				<Media
					close={() => setIsMediaOpen(false)}
					setMedia={setMedia}
					isOpen={isMediaOpen}
				/>

				{media ? (
					<span className='sdms-image__cancel' title='Delete Image'>
						<SVGIcon
							name='Error-circle'
							size={30}
							onClick={() => setMedia(defaultImage)}
						/>
					</span>
				) : null}
			</div>
		);

	return (
		<>
			{media.map((m, i) => (
				<div
					className={classNames(
						'sdms-image',
						'sdms-image--outline',
						'sdms-image--changed',
						'sdms-mr-20',
						'sdms-mb-20'
					)}>
					<div
						className='sdms-image__holder'
						style={{
							backgroundImage: `url(${pathToUrl(m.thumb)})`,
						}}
					/>
					<label className='sdms-image__upload' title='Edit Image'>
						<SVGIcon
							name='Edit'
							onClick={() => {
								editIndex.current = i;
								setIsMediaOpen(true);
							}}
							size={18}
						/>
					</label>
					<span className='sdms-image__cancel' title='Delete Image'>
						<SVGIcon
							name='Error-circle'
							size={30}
							onClick={() => setMedia(update(media, { $splice: [[i, 1]] }))}
						/>
					</span>
				</div>
			))}
			<div
				className={classNames(
					'sdms-image',
					'sdms-image--outline',
					'sdms-mr-20',
					'sdms-mb-20'
				)}>
				<div
					className='sdms-image__holder'
					style={{
						backgroundImage: `${_defaultImage}`,
					}}
				/>
				<label className='sdms-image__upload' title='Add Image'>
					<SVGIcon
						name='Plus'
						onClick={() => {
							editIndex.current = null;
							setIsMediaOpen(true);
						}}
					/>
				</label>
			</div>
			<Media
				close={() => setIsMediaOpen(false)}
				isOpen={isMediaOpen}
				setMedia={image => {
					setMedia(
						update(
							media,
							editIndex.current !== null
								? { [editIndex.current]: { $set: image } }
								: { $push: [image] }
						)
					);
				}}
				defaultSelectedMedia={media[editIndex.current] || null}
			/>
		</>
	);
};

ImageUpload.propTypes = {
	media: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	setMedia: PropTypes.func.isRequired,
	className: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	defaultImage: PropTypes.object,
	multiple: PropTypes.bool,
};

ImageUpload.defaultProps = {
	media: null,
	className: null,
	defaultImage: null,
	multiple: false,
};

export default ImageUpload;
