import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../app/contexts/UserContext';
import useOutlet from '../../../utils/hooks/useOutlet';
import usePages from '../../../utils/hooks/usePages';
import useModal from '../../../utils/hooks/useModal';
import usePaymentGateway, { parsePaymentData } from '../../../utils/hooks/usePaymentGateway';
import { paymentStatuses, paymentTypes as _paymentTypes } from '../../../utils/constants/constants';
import apiCall, { customSubmits } from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	getRemittanceRemainingAmount,
	isScheduledPaymentWithoutToken,
} from '../../../utils/helpers/helper';
import { canApplyToInvoice } from '../../../utils/helpers/paymentHelper';

import FormContainer from '../../reusables/template/FormContainer';
import PaymentForm from './PaymentForm';
import Button from '../../reusables/element/Button';
import ApplyInvoiceModal from '../../reusables/modals/ApplyInvoiceModal';

const modals = {
	APPLY_INVOICE: 'apply_invoice',
};

const PaymentEdit = ({ match }) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const [outletKey] = useOutlet(true);

	const [key, setKey] = useState(`${outletKey}${Date.now()}`);

	const [openOverrideModal, setOpenOverrideModal] = useState(false);

	const [tokenizePayment] = usePaymentGateway(userContext.data.selectedOutlet);

	const [modal, openModal, closeModal] = useModal();

	const updateCallBack = useRef(() => {});

	const submit = (data, formData, onSuccess, onFail, filters, lists, e) => {
		apiCall(
			data.current.id ? 'PUT' : 'POST',
			customSubmits.payments || 'payments',
			res => {
				if (res.status.value === paymentStatuses.DECLINED)
					addErrorNotification(res.message);

				onSuccess(res, e, data.current.id, res.status.value !== paymentStatuses.DECLINED);
			},
			err => onFail(err, true),
			data.current.id || '',
			formData,
			filters
		);
	};

	const customSubmit = (data, formData, onSuccess, onFail, filters, lists, e) => {
		// if its new payment tokenize payment.
		if (data.current.id === 0 || isScheduledPaymentWithoutToken(data.current)) {
			tokenizePayment(
				data.current.type,
				parsePaymentData(
					data.current.type,
					data.current.type === _paymentTypes.CHECK
						? formData.achAccount
						: formData.creditCard
				),
				tokenizedPayment => {
					if (tokenizedPayment) {
						if (data.current.type === _paymentTypes.CREDIT_CARD)
							formData.creditCard = { ...formData.creditCard, ...tokenizedPayment };

						if (data.current.type === _paymentTypes.CHECK)
							formData.achAccount = { ...formData.achAccount, ...tokenizedPayment };
					}

					submit(data, formData, onSuccess, onFail, filters, lists, e);
				},
				err => {
					onFail(err.toString(), true);
				},
				lists.paymentMethods
			);
		} else submit(data, formData, onSuccess, onFail, filters, lists, e);
	};

	return (
		<>
			<FormContainer
				key={key}
				dataId={match.params.id}
				pageTitle={pages.crm.payments.text}
				dataName='payments'
				lists={['paymentTypes', 'paymentMethods', 'accounts']}
				fields={
					<PaymentForm
						openOverrideModal={openOverrideModal}
						closeOverrideModal={() => setOpenOverrideModal(false)}
					/>
				}
				customSubmit={customSubmit}
				customOnFail={err => {
					if (err.toString().search('override') > -1) setOpenOverrideModal(true);
					else addErrorNotification(err.toString());
				}}
				isEditable={userContext.hasPermission('edit_payments')}
				customButtons={(data, callBack, hasChange) => {
					if (!canApplyToInvoice(data, userContext)) return [];

					updateCallBack.current = callBack;

					return [
						<Button
							design='default'
							text='Apply Invoice'
							onClick={() => {
								if (data.id === 0 || hasChange) {
									addErrorNotification('Please save payment first');
									return;
								}

								openModal({ open: modals.APPLY_INVOICE, payment: data });
							}}
							disabled={getRemittanceRemainingAmount(data) <= 0}
						/>,
					];
				}}
			/>
			{modal.open === modals.APPLY_INVOICE && modal.payment && (
				<ApplyInvoiceModal
					remittance={modal.payment}
					onClose={closeModal}
					afterSubmit={() => {
						setKey(`${outletKey}${Date.now()}`);
						closeModal();
					}}
				/>
			)}
		</>
	);
};
PaymentEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default PaymentEdit;
