import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Portlet from '../../reusables/layout/Portlet';
import IconBox from '../../reusables/layout/IconBox';
import usePages from '../../../utils/hooks/usePages';
import ContentInner from '../../reusables/template/ContentInner';
import HeaderContext from '../../../app/contexts/HeaderContext';
import BreadcrumbContainer from '../../reusables/template/BreadcrumbContainer';
import Button from '../../reusables/element/Button';

const ProductCampgroundSelect = () => {
	const pages = usePages();

	const history = useHistory();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.campground.default.text, path: pages.campground.dashboard.path },
			{ title: pages.campground.products.text, path: pages.campground.products.path },
			{ title: 'Select Period', isActive: true },
		]);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title='Select Period' />
					<BreadcrumbContainer />
				</ContentInner.SubHeaderItem>
				<ContentInner.SubHeaderItem type='toolbar'>
					<Button
						design='default'
						text='Back To The List'
						onClick={() => history.push('/campground/products')}
					/>
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title='Select Period'>
				<Portlet fluid='fluid'>
					<Portlet.Body>
						<div className='row sdms-iconbox--container'>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox
									icon={pages.campground.products.period.hourly.icon}
									title={pages.campground.products.period.hourly.text}
									onClick={() =>
										history.push(
											`${pages.campground.products.period.hourly.path}/0`
										)
									}
									design={pages.campground.products.period.hourly.color}
									animate='fast'>
									Booked hourly, within a day.
								</IconBox>
							</div>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox
									icon={pages.campground.products.period.daily.icon}
									title={pages.campground.products.period.daily.text}
									onClick={() =>
										history.push(
											`${pages.campground.products.period.daily.path}/0`
										)
									}
									design={pages.campground.products.period.daily.color}>
									Booked daily, can return or check-out same day.
								</IconBox>
							</div>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox
									icon={pages.campground.products.period.nightly.icon}
									title={pages.campground.products.period.nightly.text}
									onClick={() =>
										history.push(
											`${pages.campground.products.period.nightly.path}/0`
										)
									}
									design={pages.campground.products.period.nightly.color}>
									Booked overnight, return or check-out next day.
								</IconBox>
							</div>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox
									icon={pages.campground.products.period.seasonal.icon}
									title={pages.campground.products.period.seasonal.text}
									onClick={() =>
										history.push(
											`${pages.campground.products.period.seasonal.path}/0`
										)
									}
									design={pages.campground.products.period.seasonal.color}>
									Booked seasonal
								</IconBox>
							</div>
							<div className='col-12 col-lg-6 col-xl-4'>
								<IconBox
									icon={pages.campground.products.period.longTerm.icon}
									title={pages.campground.products.period.longTerm.text}
									onClick={() =>
										history.push(
											`${pages.campground.products.period.longTerm.path}/0`
										)
									}
									design={pages.campground.products.period.longTerm.color}>
									Booked long term
								</IconBox>
							</div>
						</div>
					</Portlet.Body>
				</Portlet>
			</ContentInner.Container>
		</>
	);
};

export default ProductCampgroundSelect;
