import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { required } from '../../../utils/helpers/validation';
import HeaderContext from '../../../app/contexts/HeaderContext';
import pages from '../../pages';
import useField from '../../../utils/hooks/useField';
import {
	metaTypes,
	productFilterComparisons,
	productFilterTypes,
} from '../../../utils/constants/constants';

import Portlet from '../../reusables/layout/Portlet';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Button from '../../reusables/element/Button';
import Loading from '../../reusables/template/Loading';
import Selects from '../../reusables/field/Selects';
import Json from '../../reusables/field/Json';
import SVGIcon from '../../reusables/element/SVGIcon';
import { numberParser, paymentTypeIcon } from '../../../utils/helpers/helper';
import AsyncSelect from '../../reusables/field/AsyncSelect';

const ProductFilterForm = ({
	data,
	setIsValid,
	submit,
	isSubmitted,
	isLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
	enumProductFilterTypes,
	enumProductFilterComparisons,
	icons,
}) => {
	const headerContext = useContext(HeaderContext);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [icon, iconOnChange] = useField(data, 'icon', onFormChange, [], null);

	const [type, typeOnChange, typeValRes, typeShowVal, setTypeShowVal] = useField(
		data,
		'type',
		onFormChange,
		[required],
		null
	);

	const [
		comparison,
		comparisonOnChange,
		comparisonValRes,
		comparisonShowVal,
		setComparisonShowVal,
	] = useField(data, 'comparison', onFormChange, [required], 'exact');

	const [meta, metaOnChange, metaValRes, metaShowVal, setMetaShowVal] = useField(
		data,
		'meta',
		onFormChange,
		[required]
	);

	const [options, optionsOnChange] = useField(data, 'options', onFormChange);

	const [sortOrder, sortOrderOnChange] = useField(
		data,
		'sortOrder',
		onFormChange,
		[],
		0,
		numberParser(false, 0)
	);

	const [placeholder, placeholderOnChange] = useField(data, 'placeholder', onFormChange);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setTypeShowVal();
			setComparisonShowVal();
			setMetaShowVal();
		}
	}, [isSubmitted, setNameShowVal, setTypeShowVal, setComparisonShowVal, setMetaShowVal]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				typeValRes.isValid &&
				comparisonValRes.isValid &&
				metaValRes.isValid
		);
	}, [
		setIsValid,
		nameValRes.isValid,
		typeValRes.isValid,
		comparisonValRes.isValid,
		metaValRes.isValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.systemSettings.default.text,
				path: pages.systemSettings.dashboard.path,
			},
			{
				title: pages.systemSettings.productFilters.text,
				path: pages.systemSettings.productFilters.path,
			},
			{ title: name || `New ${pages.systemSettings.productFilters.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.systemSettings.productFilters.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colMd={4}>
								<Input
									type='text'
									placeholder='Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='meta'
								label='Meta'
								id={data.id}
								valRes={metaValRes}
								showValidation={metaShowVal}
								colMd={4}
								description={`Use {{${pages.systemSettings.metas.path}/0|Meta Form}} to add meta`}>
								<AsyncSelect
									options={meta ? [meta] : []}
									placeholder='Search and select meta from list'
									value={meta}
									onChange={e => {
										metaOnChange(e);
										typeOnChange({ target: { value: null } });
									}}
									onBlur={setMetaShowVal}
									route='metas'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='placeholder'
								label='Placeholder'
								id={data.id}
								colMd={4}>
								<Input
									type='text'
									placeholder='Placeholder'
									value={placeholder}
									onChange={placeholderOnChange}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='icon' label='Icon' id={data.id} colMd={4}>
								<Selects
									options={icons}
									placeholder='Select a Icon'
									value={icon}
									onChange={iconOnChange}
									displayKey='value'
									renderOption={option => (
										<SVGIcon
											name={option.value}
											className={classNames('sdms-mr-5', {
												'sdms-svg-icon--fill': paymentTypeIcon(option.value)
													.fill,
											})}
											size={24}
										/>
									)}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='type'
								label='Type'
								id={data.id}
								valRes={typeValRes}
								showValidation={typeShowVal}
								colMd={4}>
								<Selects
									options={
										meta && meta.type.value === metaTypes.BOOLEAN
											? enumProductFilterTypes.filter(
													epft => epft.value === productFilterTypes.TOGGLE
											  )
											: enumProductFilterTypes
									}
									placeholder='Type (Required)'
									value={type}
									onChange={e => {
										const { value } = e.target;

										typeOnChange({ target: { value } });

										if (
											value &&
											(value.value === productFilterTypes.MULTI_SELECT ||
												value.value === productFilterTypes.TOGGLE)
										) {
											comparisonOnChange({
												target: {
													value: enumProductFilterComparisons.find(
														epfc =>
															epfc.value ===
															productFilterComparisons.EXACT
													),
												},
											});
										}
									}}
									onBlur={setTypeShowVal}
									displayKey='value'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='comparison'
								label='Comparison'
								id={data.id}
								valRes={comparisonValRes}
								showValidation={comparisonShowVal}
								colMd={4}>
								<Selects
									options={enumProductFilterComparisons}
									placeholder='Comparison (Required)'
									value={comparison}
									onChange={comparisonOnChange}
									onBlur={setComparisonShowVal}
									displayKey='value'
									disabled={
										!type ||
										type.value === productFilterTypes.MULTI_SELECT ||
										type.value === productFilterTypes.TOGGLE
									}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='options' label='Options' id={data.id} colMd={4}>
								<Json
									value={options}
									onChange={value => {
										optionsOnChange({ target: { value } });
									}}
									title='Options'
									placeholder='Options'
									keyTitle='Label'
									addFieldButtonText='Add Option'
									disabled={
										!type ||
										type.value === productFilterTypes.INPUT ||
										type.value === productFilterTypes.TOGGLE
									}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField name='sortOrder' label='Sort Order' id={data.id} colMd={4}>
								<Input
									type='number'
									placeholder='Sort order'
									value={sortOrder}
									onChange={sortOrderOnChange}
									pattern={process.env.REACT_APP_INTEGER_PATTERN}
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};

ProductFilterForm.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
	enumProductFilterTypes: PropTypes.arrayOf(PropTypes.object),
	enumProductFilterComparisons: PropTypes.arrayOf(PropTypes.object),
	icons: PropTypes.arrayOf(PropTypes.object),
};

ProductFilterForm.defaultProps = {
	data: {
		id: 0,
		name: '',
	},
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
	enumProductFilterTypes: [],
	enumProductFilterComparisons: [],
	icons: [],
};

export default ProductFilterForm;
