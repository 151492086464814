import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import UserContext from '../../../app/contexts/UserContext';
import usePages from '../../../utils/hooks/usePages';
import useDate from '../../../utils/hooks/useDate';
import apiCall from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	addSuccessNotification,
	getRemittanceRemainingAmount,
	getSettlementMaxPaymentAmount,
	priceFormatter,
} from '../../../utils/helpers/helper';
import { invoiceStatuses, paymentStatuses } from '../../../utils/constants/constants';

import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import Alert from '../element/Alert';
import { ListBody, ListTable } from '../template/List';
import FormGroup from '../layout/FormGroup';
import FormField from '../template/FormField';
import Input from '../field/Input';

const SettlementItem = ({ index, data, settlements, paymentAmount, disabled, onAmountChange }) => {
	const pages = usePages();

	const [dateFormatter] = useDate();

	const maxPaymentAmount = getSettlementMaxPaymentAmount(settlements, index, paymentAmount);

	return (
		<tr>
			<td className='sdms-vertical-middle sdms-align-center'>
				<input
					id='checkbox-head'
					type='checkbox'
					onChange={() => {
						onAmountChange(data.amount ? '' : maxPaymentAmount);
					}}
					checked={data.amount !== '' && parseFloat(data.amount) > 0}
				/>
			</td>
			<td className='sdms-vertical-middle sdms-align-center'>
				{dateFormatter(data.invoice.invoiceDate, false)}
			</td>
			<td className='sdms-vertical-middle sdms-align-center'>
				<a
					href={`${window.location.origin}${pages.crm.invoices.path}/${data.invoice.id}`}
					target='_blank'
					rel='noopener noreferrer'>
					{data.invoice.invoiceId}
				</a>
			</td>
			<td className='sdms-vertical-middle sdms-align-center'>
				{priceFormatter(data.originalAmount)}
			</td>
			<td className='sdms-vertical-middle sdms-align-center'>
				{priceFormatter(data.balanceDue)}
			</td>
			<td>
				<FormGroup row isLast className='sdms-flex-center'>
					<FormField name='name' id={data.id} inFormDesign={false} colMd={6}>
						<Input
							placeholder='0.00'
							value={data.amount}
							onChange={e => {
								if (e.target.value && !Number.isNaN(e.target.value)) {
									const maxAmount = getSettlementMaxPaymentAmount(
										settlements,
										index,
										paymentAmount
									);

									const _amount =
										maxAmount <= parseFloat(e.target.value)
											? maxAmount
											: e.target.value;

									onAmountChange(_amount);
								} else onAmountChange(e.target.value);
							}}
							disabled={disabled}
							pattern={process.env.REACT_APP_PRICE_PATTERN}
						/>
					</FormField>
				</FormGroup>
			</td>
		</tr>
	);
};

SettlementItem.propTypes = {
	index: PropTypes.number,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	settlements: PropTypes.arrayOf(PropTypes.object),
	paymentAmount: PropTypes.number,
	disabled: PropTypes.bool,
	onAmountChange: PropTypes.bool,
};
SettlementItem.defaultProps = {
	index: 0,
	data: null,
	settlements: [],
	paymentAmount: 0,
	disabled: false,
	onAmountChange: () => {},
};

const ApplyInvoiceModal = ({ remittance, onClose, afterSubmit }) => {
	const userContext = useContext(UserContext);

	const [dateFormatter] = useDate();

	const [isLoading, setIsLoading] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const [settlements, setSettlements] = useState([]);

	const onSubmit = () => {
		setIsSubmitting(true);

		apiCall(
			'POST',
			'remittanceApplyToInvoice',
			res => {
				addSuccessNotification('Successfully applied.');
				afterSubmit(res);
			},
			err => {
				let errText = err.toString();

				if (err.toString().search('overrideInvoice') > -1)
					errText = 'Some of invoices have been changed since you opened it.';
				else if (err.toString().search('overrideRemittance') > -1)
					errText = 'Credit have been changed since you opened it.';

				addErrorNotification(errText);

				setIsSubmitting(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				id: remittance.id,
				timeModified: remittance.timeModified,
				settlements: settlements.map(s => ({
					amount: s.amount,
					invoiceId: s.invoice.id,
					invoiceTimeModified: s.invoice.timeModified,
				})),
			}
		);
	};

	useEffect(() => {
		setIsLoading(true);
		apiCall(
			'GET',
			'invoices',
			res => {
				setSettlements(
					res.map(invoice => ({
						invoice,
						amount: 0,
						originalAmount: invoice.total,
						balanceDue: invoice.total - invoice.amountPaid,
					}))
				);
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			null,
			{
				'customer.id': remittance.customer.id,
				'status.value': invoiceStatuses.OPEN,
				pagination: false,
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='sdms-quick-panel sdms-quick-panel--right sdms-quick-panel--on sdms-quick-panel-large'>
			<Portlet key={remittance.id} hasFrame fluid='fluid' className='sdms-marginless h-100'>
				<Portlet.Head>
					<Portlet.HeadLabelTitle portletIcon='Clipboard-list'>
						Apply Credit To Invoices
					</Portlet.HeadLabelTitle>
				</Portlet.Head>
				<Portlet.Body>
					<div className='row' style={{ paddingLeft: 15, paddingRight: 15 }}>
						<div className='col-md-6'>
							<div className='row sdms-fitText--md sdms-mb-10 '>
								<span>
									<strong>Customer:</strong> {remittance.customer.displayName}
								</span>
							</div>
							<div className='row sdms-fitText--md sdms-mb-10 '>
								<span>
									<strong>Ref. No:</strong> {remittance.remittanceId}
								</span>
							</div>
							<div className='row sdms-fitText--md sdms-mb-10 '>
								<span>
									<strong>Date:</strong>{' '}
									{dateFormatter(
										remittance.remittanceDate || remittance.timeCreated,
										false
									)}
								</span>
							</div>
						</div>
						<div className='col-md-6'>
							<div className='row sdms-fitText--md sdms-mb-10 '>
								<span>
									<strong>Original Amount:</strong>{' '}
									{priceFormatter(remittance.amount)}
								</span>
							</div>
							<div className='row sdms-fitText--md sdms-mb-10 '>
								<span>
									<strong>Remaining Credit:</strong>{' '}
									{priceFormatter(getRemittanceRemainingAmount(remittance))}
								</span>
							</div>
						</div>
					</div>
					{!isLoading && settlements.length === 0 && (
						<div className='row'>
							<div className='col-12 sdms-mb-20'>
								<Alert solid icon='Info-circle' design='info'>
									This customer has no open invoices.
								</Alert>
							</div>
						</div>
					)}
					{settlements.length > 0 && (
						<ListBody
							className='table--everytime--scroll sdms-portlet__body--fit'
							responsive='scroll'>
							<ListTable isLoading={isLoading}>
								<colgroup>
									<col width={40} />
									<col />
									<col />
									<col />
									<col />
									<col />
									<col />
								</colgroup>
								<thead>
									<tr>
										<th className='sdms-align-center' />
										<th className='sdms-align-center'>Date</th>
										<th className='sdms-align-center'>Number</th>
										<th className='sdms-align-center'>Original Amount</th>
										<th className='sdms-align-center'>Amount Due</th>
										<th className='sdms-align-center'>Payment</th>
									</tr>
								</thead>
								<tbody>
									{settlements.map((s, index) => (
										<SettlementItem
											key={s.invoice.id}
											index={index}
											data={s}
											settlements={settlements}
											paymentAmount={getRemittanceRemainingAmount(remittance)}
											disabled={
												isSubmitting ||
												(remittance.status &&
													remittance.status.value !==
														paymentStatuses.UNSETTLED)
											}
											onAmountChange={_amount =>
												setSettlements(
													update(settlements, {
														[index]: { amount: { $set: _amount } },
													})
												)
											}
										/>
									))}
								</tbody>
								<tfoot>
									<tr>
										<th colSpan={3}>Totals</th>
										<th className='sdms-align-center'>
											{priceFormatter(
												settlements.reduce(
													(partialSum, settlement) =>
														partialSum + settlement.originalAmount,
													0
												)
											)}
										</th>
										<th className='sdms-align-center'>
											{priceFormatter(
												settlements.reduce(
													(partialSum, settlement) =>
														partialSum + settlement.balanceDue,
													0
												) -
													settlements.reduce(
														(partialSum, settlement) =>
															partialSum +
															(settlement.amount
																? parseFloat(settlement.amount)
																: 0),
														0
													)
											)}
										</th>
										<th className='sdms-align-center'>
											{priceFormatter(
												settlements.reduce(
													(partialSum, settlement) =>
														partialSum +
														(settlement.amount
															? parseFloat(settlement.amount)
															: 0),
													0
												)
											)}
										</th>
									</tr>
								</tfoot>
							</ListTable>
						</ListBody>
					)}
				</Portlet.Body>
				<Portlet.Foot className='sdms-align-left' tall='sm'>
					<div className='col'>
						<Button
							design='clean'
							text='Cancel'
							icon='Error-circle'
							size='sm'
							elevate
							onClick={onClose}
						/>
					</div>
					<div className='col-auto'>
						<Button
							label='brand'
							text={isSubmitting ? 'Applying' : 'Apply'}
							icon='Done-circle'
							size='sm'
							onClick={onSubmit}
							disabled={
								isLoading ||
								isSubmitting ||
								settlements.length === 0 ||
								settlements.filter(settlement => settlement.amount).length === 0
							}
						/>
					</div>
				</Portlet.Foot>
			</Portlet>
		</div>
	);
};

ApplyInvoiceModal.propTypes = {
	onClose: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	remittance: PropTypes.object,
	afterSubmit: PropTypes.func,
};

ApplyInvoiceModal.defaultProps = {
	onClose: () => {},
	remittance: null,
	afterSubmit: () => {},
};

export default ApplyInvoiceModal;
