import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../element/Button';
import DialogBox from '../../../element/DialogBox';

const CancellationReason = ({ open, cancellationReason, onClose }) => {
	return (
		<DialogBox open={open} title='' content={cancellationReason} type='info' onClose={onClose}>
			<Button label='success' text='Ok' onClick={onClose} />
		</DialogBox>
	);
};

CancellationReason.propTypes = {
	open: PropTypes.bool,
	cancellationReason: PropTypes.string,
	onClose: PropTypes.func,
};

CancellationReason.defaultProps = {
	open: false,
	cancellationReason: '',
	onClose: () => {},
};

export default CancellationReason;
