import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import ContentInner from '../../reusables/template/ContentInner';
import IconBox from '../../reusables/layout/IconBox';
import UserContext from '../../../app/contexts/UserContext';
import logo from '../../../assets/img/logo.svg';
import usePages from '../../../utils/hooks/usePages';

const _Dashboard = () => {
	const history = useHistory();

	const userContext = useContext(UserContext);

	const pages = usePages();

	const _colClass = 'col-xl-4 col-md-6';

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title={pages.reports.dashboard.text} />
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title={pages.reports.dashboard.text}>
				<div className='row'>
					<div className='col-3' />
					<div className='col-6 sdms-align-center'>
						<ReactSVG
							src={logo}
							className='svg-container sdms-block-center sdms-w-35p-desktop sdms-margin-t-30'
						/>
					</div>
					<div className='offset-xl-2 col-xl-8 offset-lg-1 col-lg-10 col-md-12 sdms-mt-50'>
						<div className='row sdms-iconbox--container'>
							<div className={_colClass}>
								<IconBox
									icon={pages.accounting.default.icon}
									title={pages.accounting.default.text}
									onClick={() => history.push(pages.reports.accounting.path)}
									animate={null}
									noPermission={
										!userContext.hasPermission(pages.accounting.permission)
									}>
									Run Accounting Reports
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.booking.default.icon}
									title={pages.booking.default.text}
									onClick={() => history.push(pages.reports.booking.path)}
									animate={null}
									noPermission={
										!userContext.hasPermission(pages.booking.permission)
									}>
									{`Run ${pages.booking.default.text} Reports`}
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.campground.default.icon}
									title={pages.campground.default.text}
									onClick={() => history.push(pages.reports.campground.path)}
									animate={null}
									noPermission={
										!userContext.hasPermission(pages.campground.permission)
									}>
									{`Run ${pages.campground.default.text} Reports`}
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.crm.default.icon}
									title={pages.crm.default.text}
									onClick={() => history.push(pages.reports.crm.path)}
									animate={null}
									noPermission={!userContext.hasPermission(pages.crm.permission)}>
									Run Customer Reports
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.marina.default.icon}
									title={pages.marina.default.text}
									onClick={() => history.push(pages.reports.marina.path)}
									animate={null}
									noPermission={
										!userContext.hasPermission(pages.marina.permission)
									}>
									{`Run ${pages.marina.default.text} Reports`}
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									icon={pages.pos.default.icon}
									title={pages.pos.default.text}
									onClick={() => history.push(pages.reports.pos.path)}
									animate={null}
									noPermission={!userContext.hasPermission(pages.pos.permission)}>
									Run Point of Sale Reports
								</IconBox>
							</div>
						</div>
					</div>
				</div>
			</ContentInner.Container>
		</>
	);
};

export default _Dashboard;
