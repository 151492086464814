import React from 'react';
import PropTypes from 'prop-types';

import { modules } from '../../../utils/helpers/apiCall';
import usePages from '../../../utils/hooks/usePages';
import { debugTypes } from '../../../utils/helpers/debugHelper';
import useOutlet from '../../../utils/hooks/useOutlet';

import FormContainer from '../../reusables/template/FormContainer';
import ProductMarinaHourlyForm from './ProductMarinaHourlyForm';
import OutletInactive from '../../reusables/element/OutletInactive';

const ProductMarinaHourlyEdit = ({ match, afterSubmit }) => {
	const pages = usePages();

	const [, outletConfigured] = useOutlet();

	if (!outletConfigured) return <OutletInactive />;

	return (
		<FormContainer
			dataId={match.params.id.toString()}
			pageTitle='Product Name'
			dataName='productBookings'
			lists={[
				'bookingTypeMarinas',
				'productCategoryMarinas',
				'taxCodes',
				'unitMeasures',
				'policies',
				'accounts',
				'contracts',
				'marinaUnits',
				'taxRates',
				'bookingExtraCharges',
				'itemTypes',
				'enumBookingCalculations',
				'enumBookingPeriods',
				'enumDays',
				'calendars',
				'ratePlans',
				'customReservationStatuses',
				'enumCalculations',
				'invoicingFrequencies',
				'enumSearchTypes',
				'paymentSchedules',
				'acctClasses',
			]}
			fields={<ProductMarinaHourlyForm />}
			icon={pages.marina.products.icon}
			listUrl={pages.marina.products.path}
			afterSubmit={afterSubmit}
			module={modules.MARINA}
			debugType={debugTypes.product}
		/>
	);
};

ProductMarinaHourlyEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
	// eslint-disable-next-line react/require-default-props
	afterSubmit: PropTypes.func,
};

export default ProductMarinaHourlyEdit;
