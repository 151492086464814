import React from 'react';
import PropTypes from 'prop-types';

import BookingSummaryItem from '../../elements/BookingSummaryItem';
import { modals } from '../../constants';

const Summary = ({
	reservationItems,
	onReservationItemEdit,
	openModal,
	isLoading,
	modal,
	isOnline,
	headerHeight,
	serviceFeeAmount,
	outlet,
}) => {
	const content = reservationItems.map(reservationItem => (
		<BookingSummaryItem
			key={reservationItem.id}
			reservationItem={reservationItem}
			onEdit={() => onReservationItemEdit(reservationItem.id)}
			onDelete={() =>
				openModal({ open: modals.DELETE, itemIdToBeDeleted: reservationItem.id })
			}
			isLoading={isLoading || reservationItem.id === modal.itemIdToBeDeleted}
			isOnline={isOnline}
			headerHeight={headerHeight}
			serviceFeeAmount={serviceFeeAmount}
			outlet={outlet}
		/>
	));

	if (isOnline) return <div className='sdms-analytics-summary'>{content}</div>;

	return <div className='row'>{content}</div>;
};

Summary.propTypes = {
	reservationItems: PropTypes.arrayOf(PropTypes.object).isRequired,
	onReservationItemEdit: PropTypes.func.isRequired,
	openModal: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	modal: PropTypes.object.isRequired,
	isOnline: PropTypes.bool.isRequired,
	headerHeight: PropTypes.number.isRequired,
	serviceFeeAmount: PropTypes.number,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
};

Summary.defaultProps = {
	serviceFeeAmount: 0,
};

export default Summary;
