import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ReactTooltip from 'react-tooltip';
import SVGIcon from './SVGIcon';
import Portal from '../layout/Portal';
import { noPermissionStatus } from '../../../utils/helpers/helper';

const Button = React.forwardRef(
	(
		{
			block,
			bold,
			children,
			circle,
			className,
			design,
			elevate,
			fontWeight,
			glow,
			hoverDesign,
			hoverOutline,
			icon,
			iconFill,
			iconSize,
			id,
			label,
			labelO2,
			onClick,
			onKeyPress,
			outline,
			pill,
			size,
			square,
			tall,
			text,
			wide,
			wizardType,
			disabled,
			toolTip,
			active,
			noPermission,
			btnIcon,
			customPermission,
			style,
			isSubmitting,
		},
		ref
	) => {
		const getClass = () =>
			classNames(
				'btn',
				{
					'btn-block': block,
					'btn-circle btn-icon': circle,
					'btn-elevate': elevate,
					'btn-glow': glow,
					'btn-pill': pill,
					'btn-square': square,
					'sdms-fading-dots': isSubmitting,
					active,
				},
				(design !== null && (outline ? `btn-outline-${design}` : `btn-${design}`)) || '',
				noPermissionStatus(noPermission),
				(hoverDesign !== null &&
					(hoverOutline
						? `btn-outline-hover-${hoverDesign}`
						: `btn-hover-${hoverDesign}`)) ||
					'',
				(label && (labelO2 ? `btn-label-${label}-o2` : `btn-label-${label}`)) || '',
				bold !== null ? `btn-${bold}` : '',
				className || '',
				size !== null ? `btn-${size}` : '',
				tall ? `btn-${tall}` : '',
				wide ? `btn-${wide}` : '',
				{ [`sdms-font-${fontWeight}`]: fontWeight },
				{ 'btn-icon': btnIcon && text === null && children === null }
			);

		const getSize = () => {
			switch (size) {
				case 'sm':
					return 20;
				case 'lg':
					return 24;
				default:
					return 22;
			}
		};

		const _icon = useMemo(() => {
			if (!icon) return null;

			return isSubmitting ? 'Other#2' : icon;
		}, [icon, isSubmitting]);

		return (
			<>
				<button
					style={style}
					ref={ref}
					data-tip={toolTip}
					data-for={toolTip}
					className={getClass()}
					id={id}
					onClick={disabled || noPermission ? null : onClick}
					onKeyPress={disabled || noPermission ? null : onKeyPress}
					disabled={disabled || noPermission}
					data-sdmswizard-type={wizardType}
					data-permission-message={
						customPermission.status ? customPermission.message : null
					}
					type='button'>
					{_icon && (
						<SVGIcon
							name={_icon}
							size={iconSize || getSize()}
							fill={iconFill ? `var(--${iconFill})` : '#000'}
						/>
					)}
					{children || text}
				</button>
				{toolTip && (
					<Portal>
						<ReactTooltip id={toolTip} effect='solid' delayShow={1000}>
							<span>{toolTip}</span>
						</ReactTooltip>
					</Portal>
				)}
			</>
		);
	}
);
Button.propTypes = {
	block: PropTypes.bool,
	bold: PropTypes.oneOf(['bold', 'bolder', 'boldest']),
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	circle: PropTypes.bool,
	className: PropTypes.string,
	design: PropTypes.oneOf([
		'default',
		'brand',
		'clean',
		'danger',
		'dark',
		'info',
		'light',
		'primary',
		'secondary',
		'success',
		'warning',
		'link',
		'',
	]),
	elevate: PropTypes.bool,
	fontWeight: PropTypes.oneOf(['bold', 'bolder', 'boldest']),
	glow: PropTypes.bool,
	hoverDesign: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'info',
		'light',
		'primary',
		'secondary',
		'success',
		'warning',
	]),
	hoverOutline: PropTypes.bool,
	icon: PropTypes.string,
	iconFill: PropTypes.oneOf([
		'blue',
		'indigo',
		'purple',
		'pink',
		'red',
		'orange',
		'yellow',
		'green',
		'teal',
		'cyan',
		'white',
		'gray',
		'gray-dark',
		'primary',
		'secondary',
		'success',
		'info',
		'warning',
		'danger',
		'light',
		'dark',
	]),
	iconSize: PropTypes.number,
	id: PropTypes.string,
	label: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'facebook',
		'google',
		'info',
		'instagram',
		'light',
		'linkedin',
		'primary',
		'success',
		'twitter',
		'warning',
		'youtube',
		'secondary',
		'',
	]),
	labelO2: PropTypes.bool,
	onClick: PropTypes.func,
	onKeyPress: PropTypes.func,
	outline: PropTypes.bool,
	pill: PropTypes.bool,
	size: PropTypes.oneOf(['sm', 'lg']),
	square: PropTypes.bool,
	tall: PropTypes.oneOf(['tall', 'taller', 'tallest']),
	text: PropTypes.string,
	wide: PropTypes.oneOf(['wide', 'wider', 'widest']),
	wizardType: PropTypes.oneOf(['action-next', 'action-prev', 'action-submit']),
	disabled: PropTypes.bool,
	active: PropTypes.bool,
	toolTip: PropTypes.string,
	btnIcon: PropTypes.bool,
	noPermission: PropTypes.bool,
	customPermission: PropTypes.shape({
		status: PropTypes.bool,
		message: PropTypes.string,
	}),
	isSubmitting: PropTypes.bool,
};
Button.defaultProps = {
	block: false,
	bold: null,
	children: null,
	circle: false,
	className: null,
	design: null,
	elevate: false,
	fontWeight: null,
	glow: false,
	hoverDesign: null,
	hoverOutline: false,
	icon: null,
	iconFill: null,
	iconSize: null,
	id: null,
	label: null,
	labelO2: false,
	onClick: () => '',
	onKeyPress: () => '',
	outline: false,
	pill: false,
	size: null,
	square: false,
	tall: null,
	text: null,
	wide: null,
	wizardType: null,
	disabled: false,
	active: false,
	toolTip: null,
	btnIcon: false,
	noPermission: null,
	customPermission: {
		status: false,
		message: null,
	},
	isSubmitting: false,
};

const Group = ({ children, className, noPermission }) => {
	return (
		<div className={classNames('btn-group', noPermissionStatus(noPermission), className)}>
			{children}
		</div>
	);
};
Group.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	noPermission: PropTypes.bool,
};
Group.defaultProps = {
	className: null,
	noPermission: null,
};

Button.Group = Group;

export default Button;
