import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../app/contexts/UserContext';
import usePages from '../../../utils/hooks/usePages';
import { addErrorNotification, getCreditRemainingAmount } from '../../../utils/helpers/helper';
import useModal from '../../../utils/hooks/useModal';
import useOutlet from '../../../utils/hooks/useOutlet';

import FormContainer from '../../reusables/template/FormContainer';
import CreditMemoForm from './CreditMemoForm';
import Button from '../../reusables/element/Button';
import CreditGiveRefundModal from '../../reusables/modals/CreditGiveRefundModal';
import DialogBox from '../../reusables/element/DialogBox';
import ApplyInvoiceModal from '../../reusables/modals/ApplyInvoiceModal';

const modals = {
	APPLY_INVOICE: 'apply_invoice',
	GIVE_REFUND: 'give_refund',
	ACTION_SELECT: 'action_select',
};

const CreditMemoEdit = ({ match }) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const [outletKey] = useOutlet(true);

	const [modal, openModal, closeModal] = useModal();

	const updateCallBack = useRef(() => {});

	return (
		<>
			<FormContainer
				key={outletKey}
				dataId={match.params.id}
				pageTitle={pages.crm.creditMemos.text}
				dataName='credits'
				lists={[
					'discounts',
					'taxRates',
					'paymentTerms',
					'enumCalculations',
					'taxCodes',
					'enumRemittanceStatuses',
				]}
				fields={<CreditMemoForm />}
				customButtons={(data, callBack, hasChange) => {
					updateCallBack.current = callBack;
					return [
						<Button
							design='default'
							text='Give Refund'
							onClick={() => {
								if (data.id === 0 || hasChange) {
									addErrorNotification('Please save credit memo first');
									return;
								}

								openModal({ open: modals.GIVE_REFUND, credit: data });
							}}
							disabled={
								getCreditRemainingAmount(data) <= 0 ||
								!userContext.hasPermission('credit_memo_apply_to_invoice')
							}
							noPermission={
								!userContext.hasPermission('credit_memo_apply_to_invoice')
							}
						/>,
						<Button
							design='default'
							text='Apply Invoice'
							onClick={() => {
								if (data.id === 0 || hasChange) {
									addErrorNotification('Please save credit memo first');
									return;
								}

								openModal({ open: modals.APPLY_INVOICE, credit: data });
							}}
							disabled={
								getCreditRemainingAmount(data) <= 0 ||
								!userContext.hasPermission('credit_memo_give_a_refund')
							}
							noPermission={!userContext.hasPermission('credit_memo_give_a_refund')}
						/>,
					];
				}}
				afterSubmit={(creditMemo, oldCreditMemo) => {
					// if it is new display action dialog box.
					if (oldCreditMemo.id === 0)
						openModal({ open: modals.ACTION_SELECT, credit: creditMemo });
				}}
				isEditable={userContext.hasPermission('edit_credit_memos')}
			/>
			{modal.open === modals.APPLY_INVOICE && modal.credit && (
				<ApplyInvoiceModal
					credit={modal.credit}
					onClose={closeModal}
					afterSubmit={creditMemo => {
						updateCallBack.current({
							status: creditMemo.status,
							customerSettlements: creditMemo.customerSettlements,
							timeModified: creditMemo.timeModified,
						});
						closeModal();
					}}
				/>
			)}
			{modal.open === modals.GIVE_REFUND && modal.credit && (
				<CreditGiveRefundModal
					credit={modal.credit}
					onClose={closeModal}
					afterSubmit={creditMemo => {
						updateCallBack.current({
							status: creditMemo.status,
							customerSettlements: creditMemo.customerSettlements,
							timeModified: creditMemo.timeModified,
						});
						closeModal();
					}}
				/>
			)}
			<DialogBox
				open={modal.open === modals.ACTION_SELECT}
				title='Available Credit'
				content='What would you like to do 	with this credit?'
				type='question'
				onClose={() => {}}
				maxWidth={600}>
				<Button
					className='sdms-font-transform-c'
					label='brand'
					icon='Ticket'
					text='Retains as an available credit'
					onClick={closeModal}
				/>
				<Button
					className='sdms-font-transform-c'
					label='brand'
					icon='Undo'
					text='Give a refund'
					onClick={() => openModal({ open: modals.GIVE_REFUND, credit: modal.credit })}
				/>
				<Button
					className='sdms-font-transform-c'
					label='brand'
					icon='Clipboard-list'
					text='Apply to invoice'
					onClick={() => openModal({ open: modals.APPLY_INVOICE, credit: modal.credit })}
				/>
			</DialogBox>
		</>
	);
};
CreditMemoEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default CreditMemoEdit;
