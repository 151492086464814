import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useComponentSize from '@rehooks/component-size';

import { pathToUrl } from '../../../utils/helpers/apiCall';
import { _defaultImage } from '../../reusables/field/ImageUpload';
import { getSizeVal } from '../../../utils/helpers/helper';
import UserContext from '../../../app/contexts/UserContext';

import Portlet from '../../reusables/layout/Portlet';
import ContentInner from '../../reusables/template/ContentInner';
import Container from '../../reusables/layout/Container';
import { TableMapItem } from '../pos/TableMapForm';

const TableMap = ({ data, onChange, title, enableAssignServer }) => {
	const tableMapContainer = useRef();
	const tableMapContainerSize = useComponentSize(tableMapContainer);

	const [tableMapImageSize, setTableMapImageSize] = useState({});
	const tableMapImageRef = useRef(null);

	const usedSize = getSizeVal(tableMapContainerSize, tableMapImageSize);

	const userContext = useContext(UserContext);

	return (
		<Container.Content>
			<ContentInner.Container title={title}>
				<Portlet id='register-table' fluid='fluid' everyTimeFluid className='flex-grow-1'>
					<Portlet.Body
						ref={tableMapContainer}
						className='sdms-portlet__body--fit overflow-hidden'
						background={
							data.background
								? `url(${pathToUrl(data.background.path)})`
								: `${_defaultImage}`
						}>
						{data.background && (
							<img
								alt='Table Map'
								src={pathToUrl(data.background.path)}
								ref={tableMapImageRef}
								style={{ visibility: 'hidden', display: 'none' }}
								onLoad={() =>
									setTableMapImageSize({
										width: tableMapImageRef.current.naturalWidth,
										height: tableMapImageRef.current.naturalHeight,
									})
								}
							/>
						)}
						<div style={usedSize}>
							{data.unitMapUnits.map(umu => (
								<TableMapItem
									key={umu.unit.id}
									unitMapUnit={umu}
									onClick={() => onChange(umu.unit)}
									usedSize={usedSize}
									isDisabled={
										enableAssignServer &&
										userContext.data.user.units.findIndex(
											t => t.id === umu.unit.id
										) === -1
									}
								/>
							))}
						</div>
					</Portlet.Body>
				</Portlet>
			</ContentInner.Container>
		</Container.Content>
	);
};

TableMap.propTypes = {
	data: PropTypes.shape({
		background: PropTypes.object,
		unitMapUnits: PropTypes.array,
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	enableAssignServer: PropTypes.bool.isRequired,
};

export default TableMap;
