import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../reusables/template/FormContainer';
import GridForm from './GridForm';
import useOutlet from '../../../utils/hooks/useOutlet';

const GridEdit = ({ match }) => {
	const [outletKey] = useOutlet(true);

	return (
		<FormContainer
			key={outletKey}
			dataId={match.params.id}
			dataName='grids'
			fields={<GridForm />}
		/>
	);
};

GridEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default GridEdit;
