import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import SVGIcon from './reusables/element/SVGIcon';

const Item = ({ path, title, isActive, className }) => {
	return (
		<>
			<span className='sdms-subheader__breadcrumbs-separator' />
			{!isActive ? (
				<Link
					to={path}
					className={classNames('sdms-subheader__breadcrumbs-link', className)}>
					{title}
				</Link>
			) : (
				<span
					className={classNames(
						'sdms-subheader__breadcrumbs-link',
						'sdms-subheader__breadcrumbs-link--active',
						className
					)}>
					{title}
				</span>
			)}
		</>
	);
};
Item.propTypes = {
	path: PropTypes.string,
	title: PropTypes.string.isRequired,
	isActive: PropTypes.bool,
	className: PropTypes.string,
};
Item.defaultProps = {
	path: '/',
	isActive: false,
	className: null,
};

const Breadcrumb = ({ children, className }) => {
	return (
		<div className={classNames('sdms-subheader__breadcrumbs', className)}>
			<Link to='/' className='sdms-subheader__breadcrumbs-home'>
				<SVGIcon name='Home' />
			</Link>
			{children.length > 0 &&
				children.map((item, index) => {
					return (
						<Item
							key={`breadcrumbItem${item.props.title}${index}`}
							path={item.props.path}
							title={item.props.title}
							className={item.props.className}
							isActive={item.props.isActive}
						/>
					);
				})}
		</div>
	);
};
Breadcrumb.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
Breadcrumb.defaultProps = {
	className: null,
};

Breadcrumb.Item = Item;

export default Breadcrumb;
