import React from 'react';
import PropTypes from 'prop-types';

import pages from '../../pages';

import SeasonForm from './SeasonForm';
import FormContainer from '../../reusables/template/FormContainer';

const SeasonEdit = ({ match }) => {
	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle={pages.systemSettings.seasons.text}
			dataName={pages.systemSettings.seasons.route}
			fields={<SeasonForm />}
		/>
	);
};

SeasonEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default SeasonEdit;
