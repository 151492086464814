import React from 'react';
import PropTypes from 'prop-types';

import apiCall, { parseData } from '../../../utils/helpers/apiCall';

import FormContainer from '../../reusables/template/FormContainer';
import JournalForm from './JournalForm';

const JournalEdit = ({ match }) => {
	return (
		<FormContainer
			dataId={match.params.id}
			pageTitle='Journal Entry'
			dataName='journals'
			lists={['accounts', 'taxAgencies']}
			fields={<JournalForm />}
			customSubmit={(data, formData, onSuccess, onFail, filters, lists, e) => {
				apiCall(
					data.current.id ? 'PUT' : 'POST',
					'journals',
					res => {
						onSuccess(res, e, data.current.id);
					},
					err => onFail(err, true),
					data.current.id || '',
					{
						...formData,
						journalLines: formData.journalLines.map(line => {
							line.reservationItem = parseData(line.reservationItem, null, true);

							return line;
						}),
					},
					filters
				);
			}}
		/>
	);
};

JournalEdit.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.object.isRequired,
	}).isRequired,
};

export default JournalEdit;
