import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useComponentSize from '@rehooks/component-size/index';
import classNames from 'classnames';
import ReactDOMServer from 'react-dom/server';
import { MapInteractionCSS } from 'react-map-interaction';
import tinyColor from 'tinycolor2';
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import UserContext from '../../../app/contexts/UserContext';
import ThemeContext from '../../../app/contexts/ThemeContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import useModal from '../../../utils/hooks/useModal';
import useDate from '../../../utils/hooks/useDate';
import useField from '../../../utils/hooks/useField';
import useFeet from '../../../utils/hooks/useFeet';
import usePages from '../../../utils/hooks/usePages';
import { required } from '../../../utils/helpers/validation';
import {
	addErrorNotification,
	calculateSqft,
	convertDateToUTC,
	getThemeColor,
	numberParser,
	priceFormatter,
	getSizeVal,
	inchToFeet,
	getVesselVehicleInfo,
	getMapItemColor,
} from '../../../utils/helpers/helper';
import apiCall, {
	filters,
	modules,
	outletRelatedEndpoints,
	pathToUrl,
} from '../../../utils/helpers/apiCall';
import {
	bookBies,
	bookingPeriods,
	mapItemColors,
	searchTypes,
	mapDisplayOptions,
} from '../../../utils/constants/constants';
import { _defaultImageSvg } from '../../reusables/field/ImageUpload';
import { getBookingHourlyStep } from '../../../utils/helpers/reusable';

import ContentInner from '../../reusables/template/ContentInner';
import Portlet from '../../reusables/layout/Portlet';
import FormGroup from '../../reusables/layout/FormGroup';
import Radio from '../../reusables/field/Radio';
import FormField from '../../reusables/template/FormField';
import Loading from '../../reusables/template/Loading';
import DatePicker from '../../reusables/field/DatePicker';
import TimePickerInput from '../../reusables/field/TimePickerInput';
import Button from '../../reusables/element/Button';
import Selects from '../../reusables/field/Selects';
import LengthInputGroup from '../../reusables/field/LengthInputGroup';
import Input from '../../reusables/field/Input';
import VesselModal from '../../reusables/modals/VesselModal';
import Dropdown from '../../reusables/element/Dropdown';
import SVGIcon from '../../reusables/element/SVGIcon';
import IconBox from '../../reusables/layout/IconBox';
import ReservationStatusCell from '../../reusables/element/ReservationStatusCell';
import Portal from '../../reusables/layout/Portal';
import BreadcrumbContainer from '../../reusables/template/BreadcrumbContainer';
import MapColorsTooltip from '../../reusables/element/MapColorsTooltip';
import Badge from '../../reusables/element/Badge';

import logoLoading from '../../../assets/img/logoLoading.svg';

const modals = {
	VESSEL_SELECT: 'vesselSelect',
	INFO: 'info',
};

const MapItem = ({
	unitMapUnit,
	usedSize,
	unitState,
	displayOption,
	availability,
	searchData,
	isSearched,
	products,
}) => {
	const pages = usePages();

	const themeContext = useContext(ThemeContext);

	const colors = useMemo(() => getThemeColor(themeContext.data.theme), [themeContext]);

	const history = useHistory();

	const [, utcDateFormatter] = useDate();

	const availableProducts = useMemo(() => {
		if (Object.keys(availability).length === 0) return [];

		const _availableProducts = [];

		Object.keys(availability).forEach(productId => {
			if (typeof availability[productId][unitMapUnit.unit.id] !== 'undefined') {
				const capacity =
					unitMapUnit.unit.bookingType.bookBy.value === bookBies.UNIT
						? 1
						: unitMapUnit.unit.capacity;

				if (capacity > availability[productId][unitMapUnit.unit.id])
					_availableProducts.push(products.find(p => p.id.toString() === productId));
			}
		});

		return _availableProducts;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availability, products]);

	const icon = `url('data:image/svg+xml;utf8,${ReactDOMServer.renderToString(
		<SVGIcon
			name={unitMapUnit.unit.icon ? unitMapUnit.unit.icon.value : 'Square'}
			preserveAspectRatio='none'
			fill={tinyColor(
				colors[
					getMapItemColor(
						unitMapUnit,
						unitState,
						displayOption,
						mapDisplayOptions,
						searchData,
						availableProducts,
						isSearched,
						availability,
						modules.MARINA
					)
				]
			)
				.setAlpha(unitMapUnit.unit.opacity ? unitMapUnit.unit.opacity / 100 : 1)
				.toRgbString()}
		/>
	)}')`;

	const sizes = {
		x: (unitMapUnit.posX * usedSize.width) / 100,
		y: (unitMapUnit.posY * usedSize.height) / 100,
		width: (unitMapUnit.width * usedSize.width) / 20,
		height: (unitMapUnit.height * usedSize.width) / 20,
		rotation: unitMapUnit.rotation,
	};

	const goPath = useCallback(
		item => {
			if (item.unitBlockoutId)
				history.push(`${pages.marina.settings.spaceBlockouts.path}/${item.unitBlockoutId}`);
			else if (item.bookingPeriod === bookingPeriods.LONG_TERM) {
				history.push({
					pathname: `${pages.marina.reservations.longTerm.path}/${item.reservationId}`,
					state: {
						itemId: item.reservationItemId,
						form: 'map',
					},
				});
			} else if (item.bookingPeriod === bookingPeriods.SEASONAL) {
				history.push({
					pathname: `${pages.marina.reservations.seasonal.path}/${item.reservationId}`,
					state: {
						itemId: item.reservationItemId,
						form: 'map',
					},
				});
			} else
				history.push({
					pathname: `${pages.marina.reservations.transient.path}/${item.reservationId}`,
					state: {
						itemId: item.reservationItemId,
						form: 'map',
					},
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[history]
	);

	const goToForm = product => {
		if (product.bookingPeriod.value === bookingPeriods.LONG_TERM) {
			history.push({
				pathname: `${pages.marina.reservations.longTerm.path}/0`,
				state: {
					itemId: 0,
					form: 'map',
					searchData: { ...searchData, product, unit: unitMapUnit.unit },
				},
			});
		} else if (product.bookingPeriod.value === bookingPeriods.SEASONAL) {
			history.push({
				pathname: `${pages.marina.reservations.seasonal.path}/0`,
				state: {
					itemId: 0,
					form: 'map',
					searchData: { ...searchData, product, unit: unitMapUnit.unit },
				},
			});
		} else
			history.push({
				pathname: `${pages.marina.reservations.transient.path}/0`,
				state: {
					itemId: 0,
					form: 'map',
					searchData: { ...searchData, product, unit: unitMapUnit.unit },
				},
			});
	};

	const TableMapItemInner = (
		<div
			id={`unitMapItem-${unitMapUnit.id}`}
			className={classNames(
				'register-item__table register-item__table--draggable',
				unitMapUnit?.unit?.alert &&
					displayOption === mapDisplayOptions.OCCUPANCY &&
					'sdms-blink'
			)}
			style={{
				width: sizes.width,
				height: sizes.height,
				top: sizes.y,
				left: sizes.x,
				transform: `rotate(${sizes.rotation}deg)`,
				backgroundImage: `${icon}`,
				position: 'absolute',
				fontSize:
					usedSize.height >= usedSize.width
						? `${usedSize.width *
								0.01618 *
								(unitMapUnit.fontSize ? unitMapUnit.fontSize.size : 1)}px`
						: `${usedSize.height *
								0.01618 *
								(unitMapUnit.fontSize ? unitMapUnit.fontSize.size : 1)}px`,
			}}>
			{unitMapUnit.hideCaption ? '' : unitMapUnit.unit.mapCaption || unitMapUnit.unit.name}
		</div>
	);

	const UnitInfo = useMemo(() => {
		const info = [];

		if (unitState?.loa || unitState?.beam || unitState?.height)
			info.push(
				<div key={`space${unitMapUnit.id}`}>
					<b>Space size: </b>
					{`${inchToFeet(unitState.loa)} x ${inchToFeet(unitState.beam)} x ${inchToFeet(
						unitState.height
					)}`}
				</div>
			);

		if (
			unitState?.beam !== unitState?.availableBeam &&
			unitState?.reservationItems?.length === 0
		)
			info.push(
				<div key={`availableBeam${unitMapUnit.id}`}>
					<b>Available Beam: </b>
					{inchToFeet(unitState.availableBeam)}
				</div>
			);

		if (unitState?.stolenBeam)
			info.push(
				<div key={`remainingSpaceSize${unitMapUnit.id}`}>
					<b>Used Up: </b>
					{inchToFeet(unitState.stolenBeam)}
				</div>
			);

		if (unitState?.powerMeter)
			info.push(
				<div key={`powerMeter${unitMapUnit.id}`}>
					<b>Power Meter: </b>
					{unitState.powerMeter.name} ({unitState.powerMeter.type}/
					{unitState.powerMeter.amperage}Amp {unitState.powerMeter.voltage}V)
				</div>
			);

		if (unitState?.notes)
			info.push(
				<div key={`notes${unitMapUnit.id}`}>
					<b>Space notes: </b>
					{unitState?.notes}
				</div>
			);

		if (unitState?.alert)
			info.push(
				<div key={`alert${unitMapUnit.id}`}>
					<b>Alert: </b>
					{unitState.alert}
				</div>
			);

		return info;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unitState]);

	return (
		<>
			<div
				data-tip='tooltip'
				data-for={`unitMapUnit-${unitMapUnit.id}`}
				className='register-item__table--draggable-parent'
				data-event='touchstart focus mouseover'
				data-event-off='mouseout'
				globalEventOff='touchstart'
				onClick={() => {}}
				style={{
					display: 'flex',
					position: 'absolute',
				}}
				role='presentation'>
				{TableMapItemInner}
			</div>
			{((displayOption === mapDisplayOptions.OCCUPANCY &&
				(UnitInfo.length || unitState.reservationItems.length)) ||
				(displayOption !== mapDisplayOptions.AVAILABILITY && availableProducts.length) ||
				displayOption === mapDisplayOptions.CUSTOMER_BALANCE) && (
				<Portal isTooltip>
					<ReactTooltip
						delayShow={250}
						delayHide={250}
						id={`unitMapUnit-${unitMapUnit.id}`}
						type='light'
						className='sdms-p0'
						clickable>
						<IconBox
							icon={
								unitState.reservationItems.some(i => i.unitBlockoutId)
									? 'Screwdriver'
									: 'Marina'
							}
							design='brand'
							title={
								unitMapUnit.unit.mapCaption
									? unitMapUnit.unit.mapCaption
									: unitMapUnit.unit.name
							}
							className='sdms-marginless sdms-portlet--fit sdms-map-item-tooltip'>
							{displayOption === mapDisplayOptions.OCCUPANCY && (
								<div>
									{unitState.reservationItems.map((item, index) => (
										<div
											onClick={() => goPath(item)}
											role='presentation'
											key={
												item.reservationItemId.toString() + index.toString()
											}
											className='sdms-cursor--pointer'>
											<div key={`customer${unitMapUnit.id}${item.id}`}>
												<b>
													{item.unitBlockoutId
														? 'Blockout: '
														: 'Customer: '}
												</b>
												{item.customerName}
											</div>
											<div key={`start${unitMapUnit.id}${item.id}`}>
												<b>Start: </b>
												{utcDateFormatter(item.start.date)}
											</div>
											{(item?.bookingPeriod !== bookingPeriods.LONG_TERM ||
												item.hasDeparture) && (
												<div key={`end${unitMapUnit.id}${item.id}`}>
													<b>End: </b>
													{utcDateFormatter(item.end.date)}
												</div>
											)}
											{(item?.loa || item?.beam || item?.height) && (
												<div key={`itemOccupied${unitMapUnit.id}`}>
													<b>Occupied: </b>
													{`${inchToFeet(item.loa)} x ${inchToFeet(
														item.beam
													)} x ${inchToFeet(item.height)}`}
												</div>
											)}
											{item?.vessel && (
												<div key={`vessel${unitMapUnit.id}${item.id}`}>
													<b>Vessel: </b>
													{getVesselVehicleInfo(item.vessel)}
												</div>
											)}
											{item?.itemInternalNotes && (
												<div
													key={`internalNotes${unitMapUnit.id}${item.id}`}>
													<b>Internal notes: </b>
													{item.itemInternalNotes}
												</div>
											)}
											{item.reservationStatus && (
												<div key={`status${unitMapUnit.id}${item.id}`}>
													<ReservationStatusCell
														data={{
															status: {
																value: item.reservationStatus,
															},
															customReservationStatus: {
																name: item.customReservationStatus,
															},
														}}
													/>
													{item.temporaryDeparted && (
														<Badge
															design={
																mapItemColors.TEMPORARY_DEPARTED
																	.color
															}
															isInline
															isUnified
															fontWeight='bold'
															size='lg'
															className={classNames(
																'sdms-text-overflow'
															)}>
															<span
																data-for='reservation-status-tooltip'
																data-tip='reservation-status-tooltip'
																className='sdms-text-overflow'>
																Temporary Departed
															</span>
														</Badge>
													)}
												</div>
											)}
										</div>
									))}
									{unitState.reservationItems.length > 1 && (
										<Button
											icon='Mail-box'
											className='sdms-mt-10'
											label='info'
											size='sm'
											onClick={() =>
												history.push({
													pathname: pages.marina.reservations.path,
													state: {
														reservationItemIds: unitState.reservationItem.map(
															r => r.id
														),
														date: searchData.startDate,
													},
												})
											}>
											See all
										</Button>
									)}
									{UnitInfo.length > 0 &&
										unitState.reservationItems.length > 0 && (
											<div className='sdms-separator' />
										)}
									{UnitInfo}
								</div>
							)}
							{displayOption === mapDisplayOptions.AVAILABILITY && (
								<div>
									{availableProducts.map(ap => (
										<div
											onClick={() => goToForm(ap)}
											role='presentation'
											key={ap.id}>
											<div>{ap.name}</div>
										</div>
									))}
								</div>
							)}
							{displayOption === mapDisplayOptions.CUSTOMER_BALANCE && (
								<div>
									{unitState.reservationItems.map((item, index) => (
										<div
											onClick={() => goPath(item)}
											role='presentation'
											key={
												item.reservationItemId.toString() + index.toString()
											}>
											<div>
												<b>Customer: </b> {item.customerName}
											</div>
											<div>
												<b>Open Balance: </b>
												{priceFormatter(item.customerBalance || 0)}
											</div>
											<div>
												<b>Over Due Balance: </b>
												{priceFormatter(item.customerOverDueBalance || 0)}
											</div>
											{item.reservationStatus && (
												<div>
													<ReservationStatusCell
														data={{
															status: {
																value: item.reservationStatus,
															},
															customReservationStatus: {
																name: item.customReservationStatus,
															},
														}}
													/>
												</div>
											)}
										</div>
									))}
									{unitState.reservationItems.length > 1 && (
										<Button
											icon='Mail-box'
											className='sdms-mt-10'
											label='info'
											size='sm'
											onClick={() =>
												history.push({
													pathname: pages.marina.reservations.path,
													state: {
														reservationItemIds: unitState.reservationItems.map(
															r => r.id
														),
														date: searchData.startDate,
													},
												})
											}>
											See all
										</Button>
									)}
								</div>
							)}
						</IconBox>
					</ReactTooltip>
				</Portal>
			)}
		</>
	);
};
MapItem.propTypes = {
	unitMapUnit: PropTypes.shape({
		id: PropTypes.number,
		unit: PropTypes.object,
		posX: PropTypes.number,
		posY: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number,
		rotation: PropTypes.number,
		fontSize: PropTypes.object,
		hideCaption: PropTypes.bool,
	}).isRequired,
	usedSize: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	unitState: PropTypes.object,
	displayOption: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	availability: PropTypes.object,
	// eslint-disable-next-line react/forbid-prop-types
	searchData: PropTypes.object,
	isSearched: PropTypes.bool,
	products: PropTypes.arrayOf(PropTypes.object),
};
MapItem.defaultProps = {
	unitState: {
		reservationItems: [],
		alert: '',
		note: '',
	},
	displayOption: mapDisplayOptions.OCCUPANCY,
	availability: null,
	searchData: null,
	isSearched: false,
	products: [],
};

const Map = () => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.marina.default.text, path: pages.marina.dashboard.path },
			{ title: pages.marina.map.text, isActive: true },
		]);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	const refForTimePicker = useRef(null);

	const sizeTimePicker = useComponentSize(refForTimePicker);

	const timePickerStep = useRef(getBookingHourlyStep(userContext));

	const [isMapOptionsOpen, setIsMapOptionsOpen] = useState(true);

	const lists = useRef(['seasons', 'bookingTypeMarinas', 'productBookings', 'unitMaps']);

	const [listState, setListState] = useState({});

	const [isLoading, setIsLoading] = useState(true);

	const [displayOption, setDisplayOption] = useState(mapDisplayOptions.OCCUPANCY);

	const [isSearching, setIsSearching] = useState(false);

	const [isSearched, setIsSearched] = useState(false);

	const [modal, openModal, closeModal] = useModal();

	const tableMapContainer = useRef();

	const tableMapContainerSize = useComponentSize(tableMapContainer);

	const [tableMapImageSize, setTableMapImageSize] = useState({});

	const tableMapImageRef = useRef(null);

	const usedSize = useMemo(() => getSizeVal(tableMapContainerSize, tableMapImageSize), [
		tableMapContainerSize,
		tableMapImageSize,
	]);

	const [mapSize, setMapSize] = useState({ scale: 1, translation: { x: 0, y: 0 } });

	const [map, setMap] = useState(
		userContext?.data?.selectedOutlet?.settings?.defaultMarinaMap || null
	);

	const [unitStates, setUnitStates] = useState({});

	const [availability, setAvailability] = useState({});

	const useDefaultTimes = useRef(true);

	const periodOptions = useRef([
		{
			id: 'Transient',
			value: 'Transient',
		},
		{
			id: bookingPeriods.SEASONAL,
			value: bookingPeriods.SEASONAL,
		},
		{
			id: bookingPeriods.LONG_TERM,
			value: bookingPeriods.LONG_TERM,
		},
	]);

	const checkButtonText = useMemo(() => {
		if (displayOption === mapDisplayOptions.OCCUPANCY) return 'Check Occupancy';

		if (displayOption === mapDisplayOptions.AVAILABILITY) return 'Check Availability';

		return 'Check Customer Balance';
	}, [displayOption]);

	const [ocDate, ocDateOnChange, ocDateValRes, ocDateShowVal, setOcDateShowVal] = useField(
		{},
		'ocDate',
		() => {},
		[required],
		moment()
	);

	const [period, periodOnChange, periodValRes, periodShowVal, setPeriodShowVal] = useField(
		{},
		'period',
		() => {},
		[required],
		null
	);

	const [bookingType, bookingTypeOnChange] = useField({}, 'bookingType', () => {}, [], null);

	const [product, productOnChange] = useField({}, 'product', () => {}, [], null);

	const [season, seasonOnChange, seasonValRes, seasonShowVal, setSeasonShowVal] = useField(
		{},
		'season',
		() => {},
		[required],
		null
	);

	const [
		startDate,
		startDateOnChange,
		startDateValRes,
		startDateShowVal,
		setStartDateShowVal,
	] = useField({}, 'startDate', () => {}, [required], null);

	const [endDate, endDateOnChange, endDateValRes, endDateShowVal, setEndDateShowVal] = useField(
		{},
		'endDate',
		() => {},
		[required],
		null
	);

	const [vessel, vesselOnChange] = useField({}, 'vessel', () => {}, [required], null);

	const [loa, loaOnChange, loaValRes, loaShowVal, setLoaShowVal] = useField(
		{},
		'loa',
		() => {},
		[required],
		'',
		numberParser()
	);

	const [loaFt, setLoaFt, loaIn, setLoaIn, , setLoa] = useFeet('', loaOnChange, false);

	const [beam, beamOnChange, beamValRes, beamShowVal, setBeamShowVal] = useField(
		{},
		'beam',
		() => {},
		[required],
		'',
		numberParser()
	);

	const [beamFt, setBeamFt, beamIn, setBeamIn, , setBeam] = useFeet('', beamOnChange, false);

	const [draft, draftOnChange, draftValRes, draftShowVal, setDraftShowVal] = useField(
		{},
		'draft',
		() => {},
		[required],
		'',
		numberParser()
	);

	const [draftFt, setDraftFt, draftIn, setDraftIn, , setDraft] = useFeet(
		'',
		draftOnChange,
		false
	);

	const [height, heightOnChange, heightValRes, heightShowVal, setHeightShowVal] = useField(
		{},
		'height',
		() => {},
		[required],
		'',
		numberParser()
	);

	const [heightFt, setHeightFt, heightIn, setHeightIn, , setHeight] = useFeet(
		'',
		heightOnChange,
		false
	);

	const [weight, weightOnChange, weightValRes, weightShowVal, setWeightShowVal] = useField(
		{},
		'weight',
		() => {},
		[required],
		null,
		numberParser()
	);

	const vesselSearchFields = {
		searchLoa: loaValRes.isValid,
		searchBeam: beamValRes.isValid,
		searchDraft: draftValRes.isValid,
		searchHeight: heightValRes.isValid,
		searchWeight: weightValRes.isValid,
	};

	const isVesselSearchRequired = field =>
		bookingType && bookingType[field].value === searchTypes.REQUIRED;

	const getBookingTypeOptions = () => {
		if (!listState.bookingTypeMarinas || !period) return [];

		return listState.bookingTypeMarinas.filter(btm => {
			if (period.value === 'Transient' && (btm.hourly || btm.daily || btm.nightly))
				return true;

			if (period.value === bookingPeriods.SEASONAL && btm.seasonal) return true;

			return period.value === bookingPeriods.LONG_TERM && btm.longTerm;
		});
	};

	const getProductOptions = () => {
		if (!listState.productBookings || !bookingType) return [];

		return listState.productBookings.filter(pb => pb.bookingType.id === bookingType.id);
	};

	const mapOptions = useMemo(
		() =>
			listState.unitMaps
				? listState.unitMaps.filter(
						um =>
							um.id !== map?.id && um.outlet.id === userContext.data.selectedOutlet.id
				  )
				: [],
		[userContext.data.selectedOutlet, listState, map]
	);

	const checkOccupancy = () => {
		setIsSearching(true);

		const _ocDate = ocDate || moment().startOf('day');

		apiCall(
			'POST',
			'reservationGetUnitStatesForMap',
			res => {
				setUnitStates(res);
				setIsSearching(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsSearching(false);
			},
			'',
			{
				map: map.id,
				date: _ocDate.toString(),
			}
		);
	};

	const checkAvailability = () => {
		setIsSearching(true);

		apiCall(
			'POST',
			'reservationSearchAvailabilityForMap',
			res => {
				setIsSearching(false);
				setAvailability(Array.isArray(res) ? {} : res);
				setIsSearched(true);
			},
			err => {
				addErrorNotification(err.toString());
				setIsSearching(false);
			},
			'',
			{
				map: map.id,
				outletId: userContext.data.selectedOutlet.id,
				bookingPeriod: period.id,
				bookingType: bookingType ? bookingType.id : null,
				product: product ? product.id : null,
				fromDate: startDate.toISOString(),
				toDate: endDate.toISOString(),
				loa,
				beam,
				draft,
				height,
				weight,
				sqft: calculateSqft(loa, beam),
				useDefaultTimes: useDefaultTimes.current,
				module: modules.MARINA,
			}
		);
	};

	const checkCustomerBalance = () => {
		setIsSearching(true);

		apiCall(
			'POST',
			'reservationGetCustomerBalancesForMap',
			res => {
				setUnitStates(res);
				setIsSearching(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsSearching(false);
			},
			'',
			{
				map: map.id,
			}
		);
	};

	useEffect(() => {
		lists.current.forEach(name => {
			const _filters = {
				pagination: false,
				'module.value': modules.MARINA,
			};

			// if list is related outlet add filter.
			if (outletRelatedEndpoints.includes(name))
				_filters['outlet.id'] = userContext.data.selectedOutlet.id;

			if ((((filters[name] || {}).groups || {}).list || {}).read)
				_filters['groups[]'] = filters[name].groups.list.read;

			apiCall(
				'GET',
				name,
				fetchedData => {
					setListState(oldListState => ({
						...oldListState,
						[name]: fetchedData,
					}));
				},
				err => {
					addErrorNotification(err.toString());
					setIsLoading(false);
				},
				'',
				null,
				_filters
			);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setIsLoading(lists.current.filter(l => !listState[l]).length > 0);

		if (!map && listState.unitMaps && listState.unitMaps.length) setMap(listState.unitMaps[0]);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listState]);

	useEffect(() => {
		if (map && displayOption === mapDisplayOptions.OCCUPANCY) checkOccupancy();

		if (map && displayOption === mapDisplayOptions.CUSTOMER_BALANCE) checkCustomerBalance();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [map]);

	useEffect(() => {
		if (usedSize.width < tableMapContainerSize.width)
			setMapSize({
				scale: tableMapContainerSize.width / usedSize.width,
				translation: { x: 0, y: 0 },
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usedSize]);

	useEffect(() => {
		const defaultMap = userContext?.data?.selectedOutlet?.settings?.defaultMarinaMap;

		const outletMaps = listState.unitMaps
			? listState.unitMaps.filter(um => um.outlet.id === userContext.data.selectedOutlet.id)
			: [];

		setMap(!defaultMap && outletMaps.length > 0 ? outletMaps[0] : defaultMap);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userContext.data.selectedOutlet]);

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title={pages.marina.map.text} />
					<BreadcrumbContainer />
				</ContentInner.SubHeaderItem>
				<ContentInner.SubHeaderItem type='toolbar'>
					{map ? (
						<Dropdown
							title={map.name}
							className='sdms-header__topbar-item align-items-center'
							arrow
							icon='Subtract'
							color='primary'
							aligned='right'
							size='sm'
							outline>
							<Dropdown.Header>Other Maps</Dropdown.Header>
							{mapOptions.map(um => (
								<Dropdown.Item
									icon='Subtract'
									key={um.id}
									onClick={() => setMap(um)}>
									{um.name}
								</Dropdown.Item>
							))}
						</Dropdown>
					) : (
						<Loading isLoading type='button'>
							<Button label='brand' text='Loading' icon='Plus' />
						</Loading>
					)}
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container title='Map' hasFrame>
				<Portlet
					isCollapse
					isOpen={isMapOptionsOpen}
					hasFrame
					className='flex-grow-0 sdms-min-h-fit-content'>
					<Portlet.Head>
						<Portlet.HeadLabel>
							<h3 className='sdms-portlet__head-title'>Map Options</h3>
							{displayOption === mapDisplayOptions.OCCUPANCY && <MapColorsTooltip />}
						</Portlet.HeadLabel>
						<Portlet.HeadToolbarActions>
							<SVGIcon
								name='Angle-right'
								className={classNames('sdms-transition', 'sdms-cursor--pointer', {
									'sdms-rotate--90': isMapOptionsOpen,
								})}
								onClick={() => setIsMapOptionsOpen(!isMapOptionsOpen)}
							/>
						</Portlet.HeadToolbarActions>
					</Portlet.Head>
					<Portlet.Body>
						<form className='sdms-form'>
							<FormGroup isLast>
								<Loading isLoading={isLoading}>
									<FormField
										name='displayOption'
										label='Map View'
										id='displayOption'
										inFormDesign={false}
										col={12}
										colMd={8}
										colLg={4}
										colXl={3}>
										<Radio.Container isInline>
											<Radio
												checked={
													displayOption === mapDisplayOptions.OCCUPANCY
												}
												id='isOccupancy'
												name='isOccupancy'
												content='Occupancy'
												className='sdms-radio--primary'
												onChange={() => {
													setDisplayOption(mapDisplayOptions.OCCUPANCY);
												}}
												disabled={isSearching}
											/>
											{userContext.data.selectedOutlet.settings &&
												userContext.data.selectedOutlet.settings
													.showMapAvailability && (
													<Radio
														checked={
															displayOption ===
															mapDisplayOptions.AVAILABILITY
														}
														id='isAvailability'
														name='isAvailability'
														content='Availability'
														className='sdms-radio--primary'
														onChange={() => {
															setDisplayOption(
																mapDisplayOptions.AVAILABILITY
															);
														}}
														disabled={isSearching}
													/>
												)}
											<Radio
												checked={
													displayOption ===
													mapDisplayOptions.CUSTOMER_BALANCE
												}
												id='isCustomerBalance'
												name='isCustomerBalance'
												content='A/R Balance'
												className='sdms-radio--primary'
												onChange={() => {
													setDisplayOption(
														mapDisplayOptions.CUSTOMER_BALANCE
													);
												}}
												disabled={isSearching}
											/>
										</Radio.Container>
									</FormField>
								</Loading>
								{displayOption === mapDisplayOptions.OCCUPANCY && (
									<>
										<Loading isLoading={isLoading}>
											<FormField
												name='startDate'
												label='Date'
												id='startDate'
												col={12}
												colMd={6}
												colLg={3}
												colXl={2}
												valRes={ocDateValRes}
												showValidation={ocDateShowVal}
												inFormDesign={false}>
												<DatePicker
													id='fromDate'
													type='calendar'
													value={ocDate.toDate()}
													onChange={e =>
														ocDateOnChange({
															target: {
																value: moment(ocDate || undefined)
																	.year(
																		moment(
																			e.target.value
																		).year()
																	)
																	.month(
																		moment(
																			e.target.value
																		).month()
																	)
																	.date(
																		moment(
																			e.target.value
																		).date()
																	),
															},
														})
													}
													disabled={isSearching}
													onBlur={setOcDateShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												ref={refForTimePicker}
												name='startTime'
												label='Time'
												id='startTime'
												col={12}
												colMd={6}
												colLg={3}
												colXl={2}
												valRes={ocDateValRes}
												showValidation={ocDateShowVal}
												inFormDesign={false}>
												<TimePickerInput
													showSecond={false}
													value={ocDate}
													defaultValue={moment()}
													onChange={target => {
														ocDateOnChange({
															target: {
																value: moment(ocDate || undefined)
																	.hour(
																		target ? target.hour() : 0
																	)
																	.minute(
																		target ? target.minute() : 0
																	),
															},
														});
													}}
													size={sizeTimePicker}
													use12Hours
													minuteStep={timePickerStep.current}
													disabled={isSearching}
													onClose={setOcDateShowVal}
												/>
											</FormField>
										</Loading>
									</>
								)}
								{displayOption === mapDisplayOptions.AVAILABILITY && (
									<>
										<Loading isLoading={isLoading}>
											<FormField
												name='period'
												label='Period'
												id='period'
												col={12}
												colMd={6}
												colLg={3}
												colXl
												showValidation={periodShowVal}
												valRes={periodValRes}
												inFormDesign={false}>
												<Selects
													options={periodOptions.current}
													value={period}
													onChange={e => {
														periodOnChange(e);

														bookingTypeOnChange({
															target: { value: null },
														});
														productOnChange({
															target: { value: null },
														});

														startDateOnChange({
															target: { value: null },
														});

														endDateOnChange({
															target: { value: null },
														});

														seasonOnChange({
															target: { value: null },
														});

														setIsSearched(false);
														setStartDateShowVal(false);
														setEndDateShowVal(false);
														setSeasonShowVal(false);
														useDefaultTimes.current = true;
													}}
													onBlur={setPeriodShowVal}
													displayKey='value'
													placeholder='Period (Required)'
													disabled={isSearching}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='bookingType'
												label='Booking Type'
												id='bookingType'
												col={12}
												colMd={6}
												colLg={3}
												colXl
												inFormDesign={false}>
												<Selects
													options={getBookingTypeOptions()}
													value={bookingType}
													onChange={e => {
														bookingTypeOnChange(e);
														productOnChange({
															target: { value: null },
														});
														setIsSearched(false);
													}}
													placeholder='Booking Type'
													disabled={isSearching}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												name='product'
												label='Product'
												id='product'
												col={12}
												colMd={6}
												colLg={3}
												colXl
												inFormDesign={false}>
												<Selects
													options={getProductOptions()}
													value={product}
													onChange={e => {
														productOnChange(e);
														setIsSearched(false);
													}}
													placeholder='Product'
													disabled={isSearching}
												/>
											</FormField>
										</Loading>
										{(!period || period.value === 'Transient') && (
											<Loading isLoading={isLoading}>
												<FormField
													name='dates'
													label='Dates'
													id='dates'
													col={12}
													colMd={6}
													colLg={3}
													colXl
													valRes={startDateValRes}
													showValidation={startDateShowVal}
													inFormDesign={false}>
													<DatePicker
														id='dates'
														placeholder='Date Range'
														type='dateRange'
														value={{
															startDate: startDate
																? convertDateToUTC(
																		startDate.toDate()
																  )
																: null,
															endDate: endDate
																? convertDateToUTC(endDate.toDate())
																: null,
														}}
														onChange={val => {
															const now = new Date();

															let _startDate = null;

															let _endDate = null;

															if (val.startDate) {
																_startDate = moment(
																	startDate || undefined
																)
																	.utc(false)
																	.year(
																		moment(val.startDate).year()
																	)
																	.month(
																		moment(
																			val.startDate
																		).month()
																	)
																	.date(
																		moment(val.startDate).date()
																	)
																	.second(0)
																	.millisecond(0);

																if (!startDate) {
																	_startDate.hour(now.getHours());

																	_startDate.minute(
																		now.getMinutes()
																	);
																}
															}

															if (val.endDate) {
																_endDate = moment(
																	endDate || undefined
																)
																	.utc(false)
																	.year(
																		moment(val.endDate).year()
																	)
																	.month(
																		moment(val.endDate).month()
																	)
																	.date(
																		moment(val.endDate).date()
																	)
																	.millisecond(0);

																if (!startDate) {
																	_endDate.hour(now.getHours());

																	_endDate.minute(
																		now.getMinutes()
																	);
																}
															}

															startDateOnChange({
																target: {
																	value: _startDate,
																},
															});

															endDateOnChange({
																target: {
																	value: _endDate,
																},
															});

															setIsSearched(false);
														}}
														onBlur={setStartDateShowVal}
														disabled={isSearching}
													/>
												</FormField>
											</Loading>
										)}
										{period && period.value === bookingPeriods.SEASONAL && (
											<Loading isLoading={isLoading}>
												<FormField
													name='seasons'
													label='Season'
													id='season'
													col={12}
													colMd={6}
													colLg={3}
													colXl
													showValidation={seasonShowVal}
													valRes={seasonValRes}
													inFormDesign={false}>
													<Selects
														options={listState.seasons || []}
														value={season}
														onChange={e => {
															seasonOnChange(e);
															startDateOnChange({
																target: {
																	value: e.target.value
																		? moment(
																				e.target.value
																					.startDate
																		  )
																				.utc(false)
																				.startOf('day')
																		: null,
																},
															});
															endDateOnChange({
																target: {
																	value: e.target.value
																		? moment(
																				e.target.value
																					.endDate
																		  )
																				.utc(false)
																				.endOf('day')
																		: null,
																},
															});
															setIsSearched(false);
														}}
														onBlur={setSeasonShowVal}
														placeholder='Season (Required)'
														disabled={isSearching}
													/>
												</FormField>
											</Loading>
										)}
										{period && period.value === bookingPeriods.LONG_TERM && (
											<Loading isLoading={isLoading}>
												<FormField
													name='startDate'
													label='Date'
													id='startDate'
													col={12}
													colMd={6}
													colLg={3}
													colXl
													valRes={startDateValRes}
													showValidation={startDateShowVal}
													inFormDesign={false}>
													<DatePicker
														id='fromDate'
														type='calendar'
														value={
															startDate
																? convertDateToUTC(
																		startDate.toDate()
																  )
																: null
														}
														onChange={e => {
															const _startDate = moment(
																startDate || undefined
															)
																.utc(false)
																.year(moment(e.target.value).year())
																.month(
																	moment(e.target.value).month()
																)
																.date(moment(e.target.value).date())
																.startOf('day');

															startDateOnChange({
																target: { value: _startDate },
															});

															endDateOnChange({
																target: {
																	value: moment(_startDate).add(
																		process.env
																			.REACT_APP_LONG_TERM_TO_DATE,
																		'day'
																	),
																},
															});
														}}
														disabled={isSearching}
														onBlur={setStartDateShowVal}
													/>
												</FormField>
											</Loading>
										)}
										<Loading isLoading={isLoading}>
											<FormField
												ref={refForTimePicker}
												name='startTime'
												label='Start time'
												id='startTime'
												col={12}
												colMd={6}
												colLg={3}
												colXl
												valRes={startDateValRes}
												showValidation={
													!period || period.value === 'Transient'
														? startDateShowVal
														: false
												}
												inFormDesign={false}>
												<TimePickerInput
													showSecond={false}
													value={
														(!period || period.value === 'Transient') &&
														!useDefaultTimes.current
															? startDate
															: null
													}
													defaultValue={moment()}
													onChange={target => {
														useDefaultTimes.current = !target;

														startDateOnChange({
															target: {
																value: moment(
																	startDate || undefined
																)
																	.hour(
																		target ? target.hour() : 0
																	)
																	.minute(
																		target ? target.minute() : 0
																	)
																	.second(0)
																	.millisecond(0),
															},
														});
														setIsSearched(false);
													}}
													size={sizeTimePicker}
													use12Hours
													minuteStep={timePickerStep.current}
													disabled={
														isSearching ||
														(period && period.value !== 'Transient')
													}
													onClose={setStartDateShowVal}
												/>
											</FormField>
										</Loading>
										<Loading isLoading={isLoading}>
											<FormField
												ref={refForTimePicker}
												name='endTime'
												label='End time'
												id='endTime'
												col={12}
												colMd={6}
												colLg={3}
												colXl
												valRes={endDateValRes}
												showValidation={
													(!period || period.value === 'Transient') &&
													!useDefaultTimes.current
														? endDateShowVal
														: false
												}
												inFormDesign={false}>
												<TimePickerInput
													showSecond={false}
													value={
														(!period || period.value === 'Transient') &&
														!useDefaultTimes.current
															? endDate
															: null
													}
													defaultValue={moment()}
													onChange={target => {
														useDefaultTimes.current = !target;
														endDateOnChange({
															target: {
																value: moment(endDate || undefined)
																	.hour(
																		target ? target.hour() : 23
																	)
																	.minute(
																		target
																			? target.minute()
																			: 59
																	)
																	.second(0)
																	.millisecond(0),
															},
														});
														setIsSearched(false);
													}}
													size={sizeTimePicker}
													use12Hours
													minuteStep={timePickerStep.current}
													disabled={
														isSearching ||
														(period && period.value !== 'Transient')
													}
													onClose={setEndDateShowVal}
												/>
											</FormField>
										</Loading>
									</>
								)}
								{displayOption === mapDisplayOptions.CUSTOMER_BALANCE && (
									<>
										<Loading isLoading={isLoading}>
											<FormField
												className='sdms-cursor--pointer sdms-mw-500'
												name='balanceStatus'
												label='Customer Balance'
												id='balanceStatus'
												col={12}
												colMd={6}
												colLg={3}
												colXl={2}
												inFormDesign={false}>
												<Badge
													className='sdms-cursor--pointer sdms-mw-100 sdms-margin-t-10'
													design='success'
													isInline
													isElevate>
													Paid in Full
												</Badge>
												<Badge
													className='sdms-cursor--pointer sdms-mw-100 sdms-margin-t-10'
													design='warning'
													isInline
													isElevate>
													Open Balance
												</Badge>
												<Badge
													className='sdms-cursor--pointer sdms-mw-100 sdms-margin-t-10'
													design='danger'
													isInline
													isElevate>
													Past Due Balance
												</Badge>
											</FormField>
										</Loading>
									</>
								)}
								<div className='d-flex align-items-end col-12 col-md-6 ml-md-auto col-lg-3 col-xl-2'>
									<Loading isLoading={isLoading} type='button'>
										<Button
											className={classNames('sdms-mb-15', {
												'sdms-fading-dots': isSearching,
											})}
											label='brand'
											text={checkButtonText}
											icon={isSearching ? 'Other#2' : 'Search'}
											size='sm'
											block
											disabled={isSearching}
											onClick={() => {
												if (displayOption === mapDisplayOptions.OCCUPANCY) {
													setOcDateShowVal();
													if (ocDateValRes.isValid) checkOccupancy();
												} else if (
													displayOption === mapDisplayOptions.AVAILABILITY
												) {
													setPeriodShowVal();
													setStartDateShowVal();
													setSeasonShowVal();
													setEndDateShowVal();
													setLoaShowVal();
													setBeamShowVal();
													setDraftShowVal();
													setHeightShowVal();
													setWeightShowVal();

													let isValid =
														periodValRes.isValid &&
														startDateValRes.isValid &&
														endDateValRes.isValid;

													// if period is seasonal check season field.
													if (
														isValid &&
														period &&
														period.value === bookingPeriods.SEASONAL
													)
														isValid = seasonValRes.isValid;

													if (isValid && bookingType)
														Object.keys(vesselSearchFields).forEach(
															field => {
																if (
																	isValid &&
																	isVesselSearchRequired(field)
																)
																	isValid =
																		vesselSearchFields[field];
															}
														);

													if (isValid) checkAvailability();
												} else if (
													displayOption ===
													mapDisplayOptions.CUSTOMER_BALANCE
												)
													checkCustomerBalance();
											}}
										/>
									</Loading>
								</div>
							</FormGroup>
							{displayOption === mapDisplayOptions.AVAILABILITY && (
								<FormGroup isLast>
									<Loading isLoading={isLoading}>
										<FormField
											label='Vessel'
											name='vessel'
											id='vessel'
											col={12}
											colMd={6}
											colLg={3}
											colXl={2}
											inFormDesign={false}>
											<Button
												className='h-100 sdms-fitText'
												label='dark'
												icon='Marina'
												block
												onClick={() =>
													openModal({
														open: modals.VESSEL_SELECT,
													})
												}
												disabled={isSearching}>
												{vessel ? vessel.name : 'Select a Vessel'}
											</Button>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='loa'
											label='Length Overall'
											id='loa'
											col={12}
											colMd={6}
											colLg={3}
											colXl={2}
											inFormDesign={false}
											showValidation={
												isVesselSearchRequired('searchLoa')
													? loaShowVal
													: false
											}
											valRes={loaValRes}>
											<LengthInputGroup
												ft={loaFt}
												ftOnChange={setLoaFt}
												inch={loaIn}
												inchOnChange={setLoaIn}
												placeHolder='Length Overall'
												onBlur={setLoaShowVal}
												disabled={vessel !== null || isSearching}
												onAnyChange={() => setIsSearched(false)}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='beam'
											label='Beam'
											id='beam'
											col={12}
											colMd={6}
											colLg={3}
											colXl={2}
											inFormDesign={false}
											showValidation={
												isVesselSearchRequired('searchBeam')
													? beamShowVal
													: false
											}
											valRes={beamValRes}>
											<LengthInputGroup
												ft={beamFt}
												ftOnChange={setBeamFt}
												inch={beamIn}
												inchOnChange={setBeamIn}
												placeHolder='Beam'
												onBlur={setBeamShowVal}
												disabled={vessel !== null || isSearching}
												onAnyChange={() => setIsSearched(false)}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='draft'
											label='Draft'
											id='draft'
											col={12}
											colMd={6}
											colLg={3}
											colXl={2}
											inFormDesign={false}
											showValidation={
												isVesselSearchRequired('searchDraft')
													? draftShowVal
													: false
											}
											valRes={draftValRes}>
											<LengthInputGroup
												ft={draftFt}
												ftOnChange={setDraftFt}
												inch={draftIn}
												inchOnChange={setDraftIn}
												placeHolder='Draft'
												onBlur={setDraftShowVal}
												disabled={vessel !== null || isSearching}
												onAnyChange={() => setIsSearched(false)}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='height'
											label='Height'
											id='height'
											col={12}
											colMd={6}
											colLg={3}
											colXl={2}
											inFormDesign={false}
											showValidation={
												isVesselSearchRequired('searchHeight')
													? heightShowVal
													: false
											}
											valRes={heightValRes}>
											<LengthInputGroup
												ft={heightFt}
												ftOnChange={setHeightFt}
												inch={heightIn}
												inchOnChange={setHeightIn}
												placeHolder='Height'
												onBlur={setHeightShowVal}
												disabled={vessel !== null || isSearching}
												onAnyChange={() => setIsSearched(false)}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='weight'
											label='Weight'
											id='weight'
											col={12}
											colMd={6}
											colLg={3}
											colXl={2}
											inFormDesign={false}
											showValidation={
												isVesselSearchRequired('searchWeight')
													? weightShowVal
													: false
											}
											valRes={weightValRes}>
											<Input
												type='number'
												withOutSpin
												min={0}
												placeholder='Weight'
												value={weight || ''}
												onChange={e => {
													weightOnChange(e);

													setIsSearched(false);
												}}
												pattern={process.env.REACT_APP_INTEGER_PATTERN}
												append='lbs'
												onBlur={setWeightShowVal}
												disabled={vessel !== null || isSearching}
											/>
										</FormField>
									</Loading>
								</FormGroup>
							)}
						</form>
					</Portlet.Body>
				</Portlet>
				{isSearching && (
					<Portlet className='flex-grow-0' fluid='fluid'>
						<Portlet.Body>
							<ReactSVG src={logoLoading} className='svg-container logo-loading' />
						</Portlet.Body>
					</Portlet>
				)}
				<Portlet
					fluid='fluid'
					everyTimeFluid
					className={classNames('flex-grow-1 sdms-portlet--map-container', {
						'sdms-hidden': isSearching,
					})}>
					<Portlet.Body
						ref={tableMapContainer}
						className='sdms-portlet__body--fit overflow-hidden'>
						<MapInteractionCSS
							showControls
							minScale={1}
							maxScale={10}
							value={mapSize}
							onChange={value =>
								setMapSize({
									scale: value.scale,
									translation: {
										x:
											value.translation.x > 0
												? 0
												: value.translation.x <
												  usedSize.width * -1 * (value.scale - 1)
												? usedSize.width * -1 * (value.scale - 1)
												: value.translation.x,
										y:
											value.translation.y > 0
												? 0
												: value.translation.y <
												  usedSize.height * -1 * (value.scale - 1)
												? usedSize.height * -1 * (value.scale - 1)
												: value.translation.y,
									},
								})
							}>
							<img
								alt='Table Map'
								src={
									map && map.background
										? pathToUrl(map.background.path)
										: _defaultImageSvg
								}
								ref={tableMapImageRef}
								style={{ position: 'absolute', width: '100%' }}
								onLoad={() =>
									setTableMapImageSize({
										width: tableMapImageRef.current.naturalWidth,
										height: tableMapImageRef.current.naturalHeight,
									})
								}
							/>
							<div style={usedSize}>
								{map &&
									map.unitMapUnits
										.filter(
											umu =>
												!umu.unit.isComposite ||
												(unitStates[umu.unit.id]?.reservationItems?.length >
													0 &&
													displayOption === mapDisplayOptions.OCCUPANCY)
										)
										.map(umu => (
											<MapItem
												key={umu.unit.id}
												unitMapUnit={umu}
												usedSize={usedSize}
												unitState={unitStates[umu.unit.id]}
												displayOption={displayOption}
												availability={availability}
												searchData={{
													startDate: startDate
														? startDate.toISOString()
														: null,
													endDate: endDate ? endDate.toISOString() : null,
													loa,
													beam,
													draft,
													height,
													weight,
													sqft: calculateSqft(loa, beam),
													season,
													vessel,
												}}
												isSearched={isSearched}
												products={listState.productBookings || []}
											/>
										))}
							</div>
						</MapInteractionCSS>
					</Portlet.Body>
				</Portlet>
			</ContentInner.Container>
			<VesselModal
				isOpen={modal.open === modals.VESSEL_SELECT}
				onClose={closeModal}
				defaultVessel={vessel}
				onSelect={v => {
					setIsSearched(false);
					vesselOnChange({ target: { value: v } });
					setLoa(v.loa);
					setBeam(v.beam);
					setDraft(v.draft);
					setHeight(v.height);
					weightOnChange({ target: { value: v.weight } });
					closeModal();
				}}
				hasForm
			/>
		</>
	);
};

export default Map;
