import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';
import ListContainer from '../../reusables/template/ListContainer';
import List from '../../reusables/template/List';
import Button from '../../reusables/element/Button';
import Dropdown from '../../reusables/element/Dropdown';
import forms from '../../forms';

const PowerMeterTypeList = ({ history }) => {
	const pages = usePages();

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.marina.default.text, path: pages.marina.dashboard.path },
			{ title: pages.marina.settings.text, path: pages.marina.settings.path },
			{ title: pages.marina.settings.powerMeterTypes.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.marina.settings.powerMeterTypes.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return (
		<ListContainer
			route='powerMeterTypes'
			title={pages.marina.settings.powerMeterTypes.text}
			history={history}
			icon={pages.marina.settings.powerMeterTypes.icon}
			forms={forms.marina.settings.powerMeterTypes}>
			<List withCheckBox fluid='fluid'>
				<List.Col label='Name' cellData='name' isLinkColumn sortable='name' />
				<List.Col label='Amperage' cellData='amperage' isLinkColumn sortable='amperage' />
				<List.Col label='Voltage' cellData='voltage' isLinkColumn sortable='voltage' />
				<List.Col align='right' onlyHover width={150}>
					<Button
						className='sdms-margin-r-15'
						design='link'
						text='Edit'
						icon='Edit'
						size='sm'
						elevate
						key='edit'
					/>
					<Dropdown
						icon='Other#1'
						color='clean'
						inline
						aligned='right'
						circle
						outline={false}>
						<Dropdown.Header>Other Actions</Dropdown.Header>
						<Dropdown.Item itemsColor='danger' icon='Trash' key='delete'>
							Delete
						</Dropdown.Item>
					</Dropdown>
				</List.Col>
			</List>
		</ListContainer>
	);
};
PowerMeterTypeList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default PowerMeterTypeList;
