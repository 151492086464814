import React from 'react';
import PropTypes from 'prop-types';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import SVGIcon from '../element/SVGIcon';

const DualListbox = ({ options, selected, onChange, disabled }) => {
	return (
		<div className='dual-listbox-container'>
			<div className='dual-listbox'>
				<DualListBox
					options={options}
					selected={selected}
					icons={{
						moveLeft: (
							<span>
								<SVGIcon name='Angle-left' />
							</span>
						),
						moveAllLeft: (
							<span>
								<SVGIcon name='Angle-double-left' />
							</span>
						),
						moveRight: (
							<span>
								<SVGIcon name='Angle-right' />
							</span>
						),
						moveAllRight: (
							<span>
								<SVGIcon name='Angle-double-right' />
							</span>
						),
						moveDown: (
							<span>
								<SVGIcon name='Angle-down' />
							</span>
						),
						moveUp: (
							<span>
								<SVGIcon name='Angle-up' />
							</span>
						),
						moveTop: (
							<span>
								<SVGIcon name='Angle-double-up' />
							</span>
						),
						moveBottom: (
							<span>
								<SVGIcon name='Angle-double-down' />
							</span>
						),
					}}
					onChange={onChange}
					preserveSelectOrder
					showOrderButtons
					showHeaderLabels
					disabled={disabled}
				/>
			</div>
		</div>
	);
};

DualListbox.propTypes = {
	options: PropTypes.arrayOf(PropTypes.object),
	selected: PropTypes.arrayOf(PropTypes.object),
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

DualListbox.defaultProps = {
	options: [],
	selected: [],
	disabed: false,
};

export default DualListbox;
