import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

import pages from '../../pages';
import HeaderContext from '../../../app/contexts/HeaderContext';
import UserContext from '../../../app/contexts/UserContext';

import BreadcrumbContainer from '../../reusables/template/BreadcrumbContainer';
import ContentInner from '../../reusables/template/ContentInner';
import IconBox from '../../reusables/layout/IconBox';
import logo from '../../../assets/img/logo.svg';

const _Settings = ({ history }) => {
	const userContext = useContext(UserContext);
	const headerContext = useContext(HeaderContext);
	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.pos.default.text,
				path: pages.pos.dashboard.path,
			},
			{
				title: pages.pos.settings.text,
				isActive: true,
			},
		]);

		headerContext.setPageTitle(pages.pos.settings.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	const _colClass = 'col-xl-4 col-md-6';

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title={pages.pos.settings.text} />
					<BreadcrumbContainer />
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title={pages.pos.settings.text}>
				<div className='row'>
					<div className='col-3' />
					<div className='col-6 sdms-align-center'>
						<ReactSVG
							src={logo}
							className='svg-container sdms-block-center sdms-w-35p-desktop sdms-margin-t-30'
						/>
					</div>
					<div className='offset-xl-2 col-xl-8 offset-lg-1 col-lg-10 col-md-12 sdms-mt-50'>
						<div className='row sdms-iconbox--container'>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.pos.settings.posSettings.icon}
									title={pages.pos.settings.posSettings.text}
									onClick={() =>
										history.push(pages.pos.settings.posSettings.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.pos.settings.posSettings.permission
										)
									}>
									View, Add & Edit POS
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.pos.settings.registerTypes.icon}
									title={pages.pos.settings.registerTypes.text}
									onClick={() =>
										history.push(pages.pos.settings.registerTypes.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.pos.settings.registerTypes.permission
										)
									}>
									View, Add & Edit Register Types
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.pos.settings.prepStations.icon}
									title={pages.pos.settings.prepStations.text}
									onClick={() =>
										history.push(pages.pos.settings.prepStations.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.pos.settings.prepStations.permission
										)
									}>
									View, Add & Edit Prep Stations
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.pos.settings.tableMaps.icon}
									title={pages.pos.settings.tableMaps.text}
									onClick={() => history.push(pages.pos.settings.tableMaps.path)}
									noPermission={
										!userContext.hasPermission(
											pages.pos.settings.tableMaps.permission
										)
									}>
									View, Add & Edit Table Maps
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.pos.settings.modifierPrefixes.icon}
									title={pages.pos.settings.modifierPrefixes.text}
									onClick={() =>
										history.push(pages.pos.settings.modifierPrefixes.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.pos.settings.modifierPrefixes.permission
										)
									}>
									View, Add & Edit Modifier Prefixes
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.pos.settings.productCategories.icon}
									title={pages.pos.settings.productCategories.text}
									onClick={() =>
										history.push(pages.pos.settings.productCategories.path)
									}
									noPermission={
										!userContext.hasPermission(
											pages.pos.settings.productCategories.permission
										)
									}>
									View, Add & Edit Product Categories
								</IconBox>
							</div>
							<div className={_colClass}>
								<IconBox
									animate={null}
									icon={pages.pos.settings.printLogs.icon}
									title={pages.pos.settings.printLogs.text}
									onClick={() => history.push(pages.pos.settings.printLogs.path)}>
									View Print Logs
								</IconBox>
							</div>
						</div>
					</div>
				</div>
			</ContentInner.Container>
		</>
	);
};
_Settings.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default _Settings;
