import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Portlet from '../layout/Portlet';
import SVGIcon from '../element/SVGIcon';
import { useWindowSize } from '../../../utils/helpers/helper';
import { mediaBreakpoint } from '../../../utils/constants/constants';
import QuickPanel from '../../QuickPanel';

const NavWrapper = ({ children, offCanvasStatus, setOffCanvasStatus, offCanvasTitle }) => {
	const windowSize = useWindowSize();
	const _Inner = (
		<Portlet.Body className='sdms-inner-page__nav'>
			<div className='sdms-inner-page__nav-items'>{children}</div>
		</Portlet.Body>
	);
	if (windowSize.width < mediaBreakpoint.LG) {
		return (
			<QuickPanel
				status={offCanvasStatus}
				setStatus={setOffCanvasStatus}
				position='left'
				icon='Mail-box'
				title={offCanvasTitle}>
				{_Inner}
			</QuickPanel>
		);
	}
	return (
		<div className='col sdms-inner-page__aside'>
			<Portlet fluid='fluid'>{_Inner}</Portlet>
		</div>
	);
};
NavWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	offCanvasStatus: PropTypes.bool.isRequired,
	setOffCanvasStatus: PropTypes.func.isRequired,
	offCanvasTitle: PropTypes.string,
};
NavWrapper.defaultProps = {
	offCanvasTitle: null,
};

const NavItem = ({ onClick, icon, title, description, isActive, isDisabled, isLoading }) => {
	return (
		<div
			role='presentation'
			className={classNames('sdms-inner-page__nav-item', {
				'sdms-inner-page__nav-item--active': isActive,
				'sdms-inner-page__nav-item--disabled': isDisabled,
				'sdms-inner-page__nav-item--loading': isLoading,
			})}
			onClick={!isDisabled ? onClick : null}>
			<div className='sdms-inner-page__nav-body'>
				<div className='sdms-inner-page__nav-icon'>
					<SVGIcon name={icon} />
				</div>
				<div className='sdms-inner-page__nav-label'>
					<div className='sdms-inner-page__nav-label-title'>{title}</div>
					<div className='sdms-inner-page__nav-label-desc'>{description}</div>
				</div>
			</div>
		</div>
	);
};
NavItem.propTypes = {
	onClick: PropTypes.func.isRequired,
	icon: PropTypes.string,
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	isActive: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isLoading: PropTypes.bool,
};
NavItem.defaultProps = {
	icon: null,
	description: null,
	isActive: false,
	isDisabled: false,
	isLoading: false,
};

const Wrapper = ({ children }) => {
	return (
		<div className='col sdms-inner-page__wrapper'>
			<Portlet
				fluid='fluid'
				className='sdms-bg-transparent sdms-portlet--unelevate sdms-portlet--portletception'>
				<Portlet.Body className='sdms-last-margin sdms-p0'>{children}</Portlet.Body>
			</Portlet>
		</div>
	);
};
Wrapper.propTypes = {
	children: PropTypes.node.isRequired,
};

const Item = ({ children }) => {
	return children;
};
Item.propTypes = {
	children: PropTypes.node.isRequired,
};

const InnerPage = ({ children }) => {
	return <div className='row h-100 sdms-inner-page'>{children}</div>;
};
InnerPage.propTypes = {
	children: PropTypes.node.isRequired,
};

InnerPage.NavWrapper = NavWrapper;
InnerPage.NavItem = NavItem;
InnerPage.Wrapper = Wrapper;
InnerPage.Item = Item;

export default InnerPage;
