import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import Portal from '../layout/Portal';
import Portlet from '../layout/Portlet';
import Button from '../element/Button';
import AvailabilityCalendar from '../element/AvailabilityCalendar';

const AvailabilityCalendarModalContent = ({
	products,
	title,
	onClose,
	outlet,
	showSingle,
	minStartDate,
}) => {
	return (
		<Portlet>
			<Portlet.Head>
				<Portlet.HeadLabelTitle portletIcon='Done-circle'>{title}</Portlet.HeadLabelTitle>
			</Portlet.Head>
			<Portlet.Body>
				<AvailabilityCalendar
					products={products}
					inModal
					outlet={outlet}
					showSingle={showSingle}
					minStartDate={minStartDate}
				/>
			</Portlet.Body>
			<Portlet.Foot className='sdms-align-left' tall='sm'>
				<div className='col'>
					<Button
						design='clean'
						text='Close'
						icon='Close'
						size='sm'
						elevate
						onClick={onClose}
					/>
				</div>
			</Portlet.Foot>
		</Portlet>
	);
};

AvailabilityCalendarModalContent.propTypes = {
	products: PropTypes.arrayOf(PropTypes.number).isRequired,
	title: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	outlet: PropTypes.number.isRequired,
	showSingle: PropTypes.bool.isRequired,
	minStartDate: PropTypes.string,
};

const AvailabilityCalendarModal = ({
	isOpen,
	onClose,
	title,
	products,
	outlet,
	showSingle,
	minStartDate,
}) => {
	return (
		<Portal>
			<Popup
				open={isOpen}
				closeOnDocumentClick={false}
				lockScroll
				modal
				onClose={onClose}
				contentStyle={{
					padding: 0,
					background: 'unset',
					border: 'unset',
					maxWidth: '640px',
				}}>
				<AvailabilityCalendarModalContent
					products={products}
					title={title}
					onClose={onClose}
					outlet={outlet}
					showSingle={showSingle}
					minStartDate={minStartDate}
				/>
			</Popup>
		</Portal>
	);
};

AvailabilityCalendarModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	title: PropTypes.string,
	products: PropTypes.arrayOf(PropTypes.object),
	outlet: PropTypes.number,
	showSingle: PropTypes.bool,
	minStartDate: PropTypes.string,
};

AvailabilityCalendarModal.defaultProps = {
	isOpen: false,
	onClose: () => {},
	title: 'Availability',
	products: [],
	outlet: 0,
	showSingle: false,
	minStartDate: null,
};

export default AvailabilityCalendarModal;
