import React, { useState, useEffect, useRef, useContext, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

import UserContext from '../../../app/contexts/UserContext';
import MessageContext from '../../../app/contexts/MessageContext';
import useModal from '../../../utils/hooks/useModal';
import apiCall, {
	customGets,
	customSubmits,
	filters,
	moduleRelatedEndpoints,
	outletRelatedEndpoints,
	parseData,
} from '../../../utils/helpers/apiCall';
import { addErrorNotification, addSuccessNotification } from '../../../utils/helpers/helper';
import { debugTypes } from '../../../utils/helpers/debugHelper';
import {
	pagesWithAttachments,
	pagesWithMessages,
	modals,
} from '../../../utils/constants/constants';

import ContentInner from './ContentInner';
import BreadcrumbContainer from './BreadcrumbContainer';
import Button from '../element/Button';
import Dropdown from '../element/Dropdown';
import Portlet from '../layout/Portlet';
import Loading from './Loading';
import DialogBox from '../element/DialogBox';
import FormDebugger from '../element/FormDebugger';
import AttachmentModal from '../modals/AttachmentModal';
import MessagesButton from '../element/MessagesButton';
import Messages from '../modals/Messages';

const FormContainer = ({
	dataId,
	dataName,
	lists,
	fields,
	showSaveButton,
	showBackButton,
	isModal,
	isQuickPanel,
	quickPanelToolbarActions,
	afterSubmit,
	pageTitle,
	withHeader,
	icon,
	onBack,
	onNew,
	listUrl,
	onChange,
	module,
	hasFrame,
	onCancel,
	presetData,
	customSubmit,
	parseSubmitData,
	customOnFail,
	customButtons,
	clearPassword,
	debugType,
	overrideShowSaveButton,
	isEditable,
	forceIri,
}) => {
	const history = useHistory();
	const userContext = useContext(UserContext);
	const messageContext = useContext(MessageContext);
	const [modal, openModal, closeModal] = useModal();

	const [isLoading, setIsLoading] = useState(true);
	const [isListsLoading, setIsListsLoading] = useState(true);
	const [error, setError] = useState(false);
	const [listState, setListState] = useState({});
	const [isValid, setIsValid] = useState(true);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [title, setTitle] = useState(pageTitle);
	const [submitButtonAttr, setSubmitButtonAttr] = useState({
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	});

	const data = useRef({});
	const changeForBackButton = useRef(false); // if there is any change on the form, open a dialog box when back button is clicked

	const onFormChange = useCallback(() => {
		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});
		changeForBackButton.current = true;

		onChange(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const _isMounted = useRef(false);

	const resetForm = () => {
		setIsSubmitted(false);

		setIsValid(true);

		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});
	};

	const onFail = (err, isNotification = false) => {
		if (!_isMounted.current) return;

		setIsLoading(false);
		setIsSubmitting(false);
		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});

		if (customOnFail) customOnFail(err);
		else if (err.toString().search('override') > -1) openModal({ open: modals.RELOAD });
		else if (isNotification) addErrorNotification(err.toString());
		else setError(err.toString());
	};

	const onSuccess = (res, saveType, isUpdate = false, displayNotification = true) => {
		if (!_isMounted.current) return;

		// for modal forms send new data to list.
		if (afterSubmit) afterSubmit(res, data.current);

		data.current = { ...data.current, ...res };

		if (data.current.password && clearPassword) data.current.password = '';

		setIsSubmitting(false);

		if (displayNotification)
			addSuccessNotification(`${title} successfully ${isUpdate ? 'updated' : 'added'}.`);

		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVED_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVED_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVED_COLOR,
		});

		if (saveType === 'back') {
			back();
		} else if (saveType === 'new') {
			if (!data.id) loadLists();
			if (onNew) onNew();
			else history.push(`${listPath}/0`);

			data.current = { id: 0, key: `new_item${Math.floor(Math.random() * 1000)}` };

			if (presetData) data.current = { ...data.current, ...presetData };

			resetForm();
		}
	};

	const loadData = () => {
		resetForm();

		const _filters = {};

		if ((((filters[dataName] || {}).groups || {}).form || {}).read)
			_filters['groups[]'] = filters[dataName].groups.form.read;

		if (dataId !== '0') {
			apiCall(
				'GET',
				customGets[dataName] || dataName,
				fetchedData => {
					data.current = { ...fetchedData };
					setIsLoading(false);
					messageContext.updateEntityCount(fetchedData);
				},
				onFail,
				dataId,
				null,
				_filters
			);
		} else {
			data.current = { id: 0, key: `new_item${Math.floor(Math.random() * 1000)}` };
			if (presetData) data.current = { ...data.current, ...presetData };

			setIsLoading(false);
			messageContext.updateEntityCount(null);
		}
	};

	const loadLists = () => {
		lists.forEach(name => {
			const _filters = {
				pagination: false,
			};

			// if list is related outlet add filter.
			if (outletRelatedEndpoints.includes(name))
				_filters['outlet.id'] = userContext.data.selectedOutlet.id;

			if (module && moduleRelatedEndpoints.includes(name)) _filters['module.value'] = module;

			if ((((filters[name] || {}).groups || {}).list || {}).read)
				_filters['groups[]'] = filters[name].groups.list.read;

			apiCall(
				'GET',
				name,
				fetchedData => {
					if (!_isMounted.current) return;
					setListState(oldListState => ({
						...oldListState,
						[name]: fetchedData,
					}));
				},
				onFail,
				'',
				null,
				_filters
			);
		});
	};

	const listPath = useRouteMatch().url.substr(0, useRouteMatch().url.lastIndexOf('/'));

	const back = () => {
		onChange(true);

		if (onBack) onBack();
		else history.push(listUrl || listPath);
	};

	/* any change on the form, view the dialog box */
	const backClick = () => {
		if (changeForBackButton.current) openModal({ open: modals.BACK });
		else back();
	};

	const submit = e => {
		setIsSubmitted(true);
		// if form is submitted, now don't ask the modal dialog when back button is clicked
		// setShowBackButtonDialog(false);

		changeForBackButton.current = false;
		onChange(true);

		if (!isValid) return;

		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});

		const formData = JSON.parse(
			JSON.stringify(parseSubmitData ? parseSubmitData(data.current) : data.current)
		);
		// Parse data for api call.
		Object.keys(formData).forEach(field => {
			formData[field] = parseData(formData[field], null, forceIri);
		});

		// if entity is related to outlet set user selected outlet.
		if (outletRelatedEndpoints.includes(dataName))
			formData.outlet = parseData(userContext.data.selectedOutlet, 'outlets');

		const _filters = {};

		if ((((filters[dataName] || {}).groups || {}).form || {}).write)
			_filters['groups[]'] = [
				filters[dataName].groups.form.write,
				filters[dataName].groups.form.read,
			];

		setIsSubmitting(true);

		if (customSubmit) {
			customSubmit(data, formData, onSuccess, onFail, _filters, listState, e);
		} else
			apiCall(
				data.current.id ? 'PUT' : 'POST',
				customSubmits[dataName] || dataName,
				res => onSuccess(res, e, data.current.id),
				err => onFail(err, true),
				data.current.id || '',
				formData,
				_filters
			);
	};

	// get list data.
	useEffect(() => {
		loadLists();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userContext.data.selectedOutlet.id]);

	useEffect(() => {
		setIsListsLoading(lists.filter(l => !listState[l]).length > 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listState]);

	// get data.
	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataId, dataName]);

	useEffect(() => {
		_isMounted.current = true;

		return () => {
			_isMounted.current = false;
		};
	}, []);

	const modifiedFields = React.cloneElement(fields, {
		data: data.current,
		isValid,
		setIsValid,
		submit,
		isSubmitted,
		isLoading,
		isListsLoading,
		...listState,
		setTitle,
		submitButtonAttr,
		onFormChange,
		onBack: backClick,
		isSubmitting,
		isEditable,
	});

	const _SaveButton = (
		<Button
			className={classNames('sdms-mw-105 justify-content-center', {
				'sdms-fading-dots':
					submitButtonAttr.text === process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
			})}
			label={submitButtonAttr.color}
			text={submitButtonAttr.text}
			icon={submitButtonAttr.icon}
			disabled={isSubmitting || !isEditable}
			onClick={submit}
		/>
	);

	if (error) return <p> {error} </p>;

	if (isModal || isQuickPanel) {
		return (
			<Portlet
				key={data.current.key ? data.current.key : ''}
				hasFrame
				fluid={isQuickPanel ? 'fluid' : null}
				className={classNames({ 'sdms-marginless': isQuickPanel, 'h-100': isQuickPanel })}>
				{withHeader && (
					<Portlet.Head>
						<Portlet.HeadLabelTitle portletIcon={icon}>
							{title || pageTitle}
						</Portlet.HeadLabelTitle>
						{quickPanelToolbarActions && (
							<Portlet.HeadToolbarActions>
								{quickPanelToolbarActions}
							</Portlet.HeadToolbarActions>
						)}
					</Portlet.Head>
				)}
				<Portlet.Body>{modifiedFields}</Portlet.Body>
				<Portlet.Foot tall='sm'>
					<div className='col'>
						<Button
							design='clean'
							text='Cancel'
							icon='Error-circle'
							size='sm'
							elevate
							onClick={onCancel}
						/>
					</div>
					<div className='col-auto'>
						{(showSaveButton || overrideShowSaveButton(data.current)) && (
							<Button
								className={classNames(' sdms-mw-100', {
									'sdms-fading-dots':
										submitButtonAttr.text ===
										process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
								})}
								label={submitButtonAttr.color}
								text={submitButtonAttr.text}
								icon={submitButtonAttr.icon}
								size='sm'
								onClick={submit}
							/>
						)}
					</div>
				</Portlet.Foot>
			</Portlet>
		);
	}

	return (
		<>
			<FormDebugger debugType={debugType} data={data.current} />
			{withHeader ? (
				<>
					<ContentInner.SubHeader>
						<ContentInner.SubHeaderItem>
							<Loading isLoading={isLoading} width={150} type='title'>
								<ContentInner.SubHeaderTitle title={title || pageTitle} />
							</Loading>
							<Loading isLoading={isLoading} type='breadcrumb'>
								<BreadcrumbContainer />
							</Loading>
						</ContentInner.SubHeaderItem>
						{showSaveButton && (
							<ContentInner.SubHeaderItem type='toolbar'>
								{messageContext.isEnabled &&
									pagesWithMessages.indexOf(dataName) > -1 && (
										<MessagesButton
											onClick={() => openModal({ open: modals.MESSAGES })}
											disabled={
												dataId.toString() === '0' ||
												isLoading ||
												isSubmitting ||
												!isEditable
											}
										/>
									)}
								{pagesWithAttachments.indexOf(dataName) > -1 && (
									<Button
										design='default'
										text='Attachments'
										onClick={() => openModal({ open: modals.ATTACHMENTS })}
										disabled={
											dataId.toString() === '0' ||
											isLoading ||
											isSubmitting ||
											!isEditable
										}
									/>
								)}
								{showBackButton ? (
									<>
										{customButtons
											? customButtons(
													data.current,
													d => {
														data.current = { ...data.current, ...d };
													},
													changeForBackButton.current
											  )
											: null}

										<Button
											design='default'
											disabled={
												submitButtonAttr.text ===
												process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT
											}
											text='Back'
											onClick={backClick}
										/>
										<Button.Group noPermission={isSubmitting}>
											{_SaveButton}
											<Dropdown
												label={submitButtonAttr.color}
												aligned='right'
												disabled={!isEditable}
												isOnlyContent>
												<Dropdown.Item
													icon='Save'
													onClick={() => submit('back')}>
													Save & Back
												</Dropdown.Item>
												<Dropdown.Item
													icon='Plus'
													onClick={() => submit('new')}>
													Save & New Item
												</Dropdown.Item>
											</Dropdown>
										</Button.Group>
									</>
								) : (
									_SaveButton
								)}
							</ContentInner.SubHeaderItem>
						)}
					</ContentInner.SubHeader>
					<ContentInner.Container
						title={title || pageTitle}
						hasFrame={hasFrame}
						key={data.current.key ? data.current.key : ''}>
						{modifiedFields}
					</ContentInner.Container>
				</>
			) : (
				<Fragment key={data.current.key ? data.current.key : ''}>{modifiedFields}</Fragment>
			)}
			<DialogBox
				open={modal.open === modals.BACK}
				title=''
				content='You changed something in the form. Would you like to go back?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					text='Go back without saving'
					label='danger'
					icon='Angle-left-circle'
					onClick={back}
				/>
				<Button
					className='sdms-font-transform-c'
					text='Continue editing'
					design='clean'
					icon='Edit'
					onClick={closeModal}
				/>
			</DialogBox>
			<DialogBox
				open={modal.open === modals.RELOAD}
				title=''
				content={`${
					title ? title.toString().substring(0, title.length - 1) : ''
				} have been changed since you opened it.`}
				type='question'
				onClose={closeModal}>
				<Button
					label='success'
					text='Reload'
					onClick={() => {
						closeModal();
						setIsLoading(true);
						loadData();
					}}
				/>
			</DialogBox>
			{modal.open === modals.MESSAGES && (
				<Messages
					onClose={closeModal}
					inquiryObject={data.current}
					onNewInquiry={_inquiry => {
						data.current.inquiry = _inquiry;
					}}
				/>
			)}
			{modal.open === modals.ATTACHMENTS && (
				<AttachmentModal
					entity={data.current}
					onClose={closeModal}
					isOpen={modal.open === modals.ATTACHMENTS}
				/>
			)}
		</>
	);
};
FormContainer.propTypes = {
	dataId: PropTypes.string.isRequired,
	dataName: PropTypes.string.isRequired,
	lists: PropTypes.arrayOf(PropTypes.string),
	fields: PropTypes.node.isRequired,
	showSaveButton: PropTypes.bool,
	showBackButton: PropTypes.bool,
	isModal: PropTypes.bool,
	isQuickPanel: PropTypes.bool,
	quickPanelToolbarActions: PropTypes.node,
	// eslint-disable-next-line react/require-default-props
	afterSubmit: PropTypes.func,
	withHeader: PropTypes.bool,
	pageTitle: PropTypes.string,
	icon: PropTypes.string,
	onBack: PropTypes.func,
	// eslint-disable-next-line react/require-default-props
	onNew: PropTypes.func,
	// eslint-disable-next-line react/require-default-props
	listUrl: PropTypes.string,
	onChange: PropTypes.func,
	module: PropTypes.string,
	hasFrame: PropTypes.bool,
	onCancel: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	presetData: PropTypes.object,
	customSubmit: PropTypes.func,
	parseSubmitData: PropTypes.func,
	customOnFail: PropTypes.func,
	customButtons: PropTypes.func,
	clearPassword: PropTypes.bool,
	debugType: PropTypes.string,
	overrideShowSaveButton: PropTypes.func,
	isEditable: PropTypes.bool,
	forceIri: PropTypes.bool,
};
FormContainer.defaultProps = {
	lists: [],
	showSaveButton: true,
	showBackButton: true,
	isModal: false,
	isQuickPanel: false,
	quickPanelToolbarActions: null,
	withHeader: true,
	pageTitle: null,
	icon: 'Layers',
	onBack: null,
	onChange: () => {},
	module: null,
	hasFrame: true,
	onCancel: () => {},
	presetData: null,
	customSubmit: null,
	parseSubmitData: null,
	customOnFail: null,
	customButtons: null,
	clearPassword: true,
	debugType: debugTypes.generic,
	overrideShowSaveButton: () => false,
	isEditable: true,
	forceIri: false,
};

export default FormContainer;
