import React from 'react';
import PropTypes from 'prop-types';

import { convertDateToUTC, dateFormatter, inchToFeet } from '../../../utils/helpers/helper';
import { getCurrentUnit } from '../../../utils/helpers/reservationHelper';
import { getOnlinePortalDefaultFilters } from './functions';
import { bookingPeriods, reservationStatuses } from '../../../utils/constants/constants';

import ListContainer from '../../reusables/template/ListContainer';
import List from '../../reusables/template/List';
import ReservationStatusCell, { RequestCell } from '../../reusables/element/ReservationStatusCell';
import Dropdown from '../../reusables/element/Dropdown';

const CustomerCell = ({ data, onClick }) => {
	return (
		<div onClick={onClick} role='presentation'>
			<span className='sdms-font-bold sdms-link sdms-link--dark'>
				{data.reservation.customer.displayName}
			</span>
			<div className='sdms-font-sm sdms-list-layout__small-text'>
				{data.vessel
					? data.vessel.name
					: `${inchToFeet(data.loa)} x ${inchToFeet(data.beam)} x ${inchToFeet(
							data.height
					  )}`}
			</div>
		</div>
	);
};
CustomerCell.propTypes = {
	data: PropTypes.shape({
		reservation: PropTypes.shape({
			customer: PropTypes.shape({
				displayName: PropTypes.string,
			}),
		}),
		vessel: PropTypes.shape({
			name: PropTypes.string,
		}),

		height: PropTypes.number,
		beam: PropTypes.number,
		loa: PropTypes.number,
	}),
	onClick: PropTypes.func,
};
CustomerCell.defaultProps = {
	data: {
		reservation: {
			customer: {
				displayName: '',
			},
			vessel: {
				name: '',
			},
		},
		height: 0,
		beam: 0,
		loa: 0,
	},
	onClick: () => {},
};

const ArrivalCell = ({ data }) => {
	return <span>{dateFormatter(convertDateToUTC(new Date(data.fromDate)))}</span>;
};
ArrivalCell.propTypes = {
	data: PropTypes.shape({
		fromDate: PropTypes.string,
	}),
};
ArrivalCell.defaultProps = {
	data: {
		fromDate: '',
	},
};

const DepartureCell = ({ data }) => {
	if (
		data.product.bookingPeriod.value === bookingPeriods.LONG_TERM &&
		data.status.value !== reservationStatuses.CHECKED_OUT
	)
		return null;
	return <span>{dateFormatter(convertDateToUTC(new Date(data.toDate)))}</span>;
};
DepartureCell.propTypes = {
	data: PropTypes.shape({
		toDate: PropTypes.string,
		product: PropTypes.object,
		status: PropTypes.object,
	}),
};
DepartureCell.defaultProps = {
	data: {
		toDate: '',
	},
};

const UnitCell = ({ data }) => {
	const unit = getCurrentUnit(data);
	return unit ? unit.name : '';
};

UnitCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

UnitCell.defaultProps = {
	data: null,
};

const Spaces = ({ data, outlet, isActive, title, icon, filters }) => {
	if (!isActive) return null;

	return (
		<div className='sdms-online-portal-list-container'>
			<ListContainer
				route='onlinePortalGetSpaces'
				title={title}
				icon={icon}
				staticFilters={{
					...getOnlinePortalDefaultFilters(data.customer, outlet),
					...filters,
				}}
				isTabList
				largeQuickPanel
				forms={[]}
				fluid='fluid'
				hasSearch={false}>
				<List fluid='fluid' withCheckBox={false} withOutPortlet>
					<List.Col label='Booking' cellData='reservationItemId' />
					<List.Col label='Product' cellData='name' cellDataObject='product' />
					<List.Col label='Space' cellComponent={<UnitCell />} />
					<List.Col label='Arrival' cellComponent={<ArrivalCell />} sortable='fromDate' />
					<List.Col
						label='Departure'
						cellComponent={<DepartureCell />}
						sortable='toDate'
					/>
					<List.Col label='Request' cellComponent={<RequestCell />} />
					<List.Col label='Status' cellComponent={<ReservationStatusCell />} />
					<List.Col width={150} align='right' onlyHover>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
		</div>
	);
};

Spaces.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	isActive: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	filters: PropTypes.object.isRequired,
};

export default Spaces;
