import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import apiCall from '../../../utils/helpers/apiCall';
import { addErrorNotification } from '../../../utils/helpers/helper';
import { TERMINAL_TOKEN_STORAGE_KEY } from '../../../utils/constants/constants';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Button from '../../reusables/element/Button';
import logo from '../../../assets/img/logo.svg';

const Row = ({ className, children }) => {
	return (
		<div
			className={`row sdms-row-align-items ${className}`}
			style={{ justifyContent: 'center' }}>
			{children}
		</div>
	);
};

Row.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
};

Row.defaultProps = {
	className: '',
	children: null,
};

const TerminalActivation = ({ register, onSuccess }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [email, setEmail] = useState('');

	const [pass, setPass] = useState('');

	const onValidate = () => {
		setIsSubmitting(true);

		apiCall(
			'POST',
			'activateTerminal',
			res => {
				localStorage.setItem(TERMINAL_TOKEN_STORAGE_KEY, res.token);
				setIsSubmitting(false);
				onSuccess(res.token);
			},
			err => {
				addErrorNotification(err.toString());
				setIsSubmitting(false);
			},
			'',
			{ registerId: register.id, email, pass }
		);
	};

	return (
		<div
			className='row'
			style={{
				alignItems: 'center',
				justifyContent: 'center',
				flex: 1,
			}}>
			<div className='col-12'>
				<Row className='sdms-mb-30'>
					<ReactSVG src={logo} className='svg-container' />
				</Row>
				<Row className='sdms-mb-30'>
					<h3>{register.name}</h3>
				</Row>
				<Row>
					<FormField name='terminalToken' id='token' colMd={4} inFormDesign={false}>
						<Input
							placeholder='Email'
							type='text'
							name='username'
							value={email}
							onChange={({ target }) => setEmail(target.value)}
						/>
					</FormField>
				</Row>
				<Row>
					<FormField name='terminalToken' id='token' colMd={4} inFormDesign={false}>
						<Input
							className={classNames('form-control-last', {
								'form-control--empty': pass === '',
							})}
							type='password'
							placeholder='Password'
							name='pass'
							value={pass}
							togglePassword
							onChange={({ target }) => setPass(target.value)}
						/>
					</FormField>
				</Row>
				<Row>
					<Button
						design='info'
						onClick={onValidate}
						wide='widest'
						isSubmitting={isSubmitting}
						disabled={isSubmitting}
						text={isSubmitting ? 'Activating' : 'Active'}
					/>
				</Row>
			</div>
		</div>
	);
};

TerminalActivation.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	register: PropTypes.object.isRequired,
	onSuccess: PropTypes.func.isRequired,
};

export default TerminalActivation;
