import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import update from 'immutability-helper';
import useComponentSize from '@rehooks/component-size';
import classNames from 'classnames';
import Moveable from 'react-moveable';

import tinyColor from 'tinycolor2';
import HeaderContext from '../../../app/contexts/HeaderContext';
import UserContext from '../../../app/contexts/UserContext';
import { required } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import pages from '../../pages';
import apiCall, { modules, pathToUrl } from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	generateId,
	getThemeColor,
	getSizeVal,
} from '../../../utils/helpers/helper';

import Button from '../../reusables/element/Button';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import ImageUpload, { _defaultImage } from '../../reusables/field/ImageUpload';
import Loading from '../../reusables/template/Loading';
import Portlet from '../../reusables/layout/Portlet';
import SVGIcon from '../../reusables/element/SVGIcon';
import TableForm from './TableForm';
import TableList from './TableList';
import ThemeContext from '../../../app/contexts/ThemeContext';

export const TableMapItem = ({
	unitMapUnit,
	dispatch,
	usedSize,
	editItem,
	onClick,
	isDisabled,
}) => {
	const themeContext = useContext(ThemeContext);
	const moveAbleRef = useRef();

	const icon = `url('data:image/svg+xml;utf8,${ReactDOMServer.renderToString(
		<SVGIcon
			name={unitMapUnit.unit.icon ? unitMapUnit.unit.icon.value : 'Square'}
			fill={
				unitMapUnit.unit.id
					? tinyColor(getThemeColor(themeContext.data.theme).info).toRgbString()
					: tinyColor(getThemeColor(themeContext.data.theme).danger).toRgbString()
			}
			preserveAspectRatio='none'
		/>
	)}')`;

	const sizes = {
		x: (unitMapUnit.posX * usedSize.width) / 100,
		y: (unitMapUnit.posY * usedSize.height) / 100,
		width: (unitMapUnit.width * usedSize.width) / 20,
		height: (unitMapUnit.height * usedSize.width) / 20,
		rotation: unitMapUnit.rotation,
	};

	const TableMapItemInner = (
		<div
			id={`unitMapItem-${unitMapUnit.id}`}
			className='register-item__table register-item__table--draggable'
			style={{
				width: sizes.width,
				height: sizes.height,
				top: sizes.y,
				left: sizes.x,
				transform: `rotate(${sizes.rotation}deg)`,
				backgroundImage: `${icon}`,
				position: 'absolute',
				fontSize:
					usedSize.height >= usedSize.width
						? `${usedSize.width * 0.01618}px`
						: `${usedSize.height * 0.01618}px`,
			}}>
			{unitMapUnit.hideCaption
				? ''
				: unitMapUnit.unit.mapCaption
				? unitMapUnit.unit.mapCaption
				: unitMapUnit.unit.name}
		</div>
	);

	const onEventStart = () => {
		dispatch({
			type: 'edit',
			payload: unitMapUnit.unit.id,
		});
	};

	useEffect(() => {
		if (moveAbleRef.current) moveAbleRef.current.updateRect();
	}, [unitMapUnit, usedSize]);

	if (editItem) {
		return (
			<>
				{TableMapItemInner}
				<Moveable
					target={document.getElementById(`unitMapItem-${unitMapUnit.id}`)}
					ref={moveAbleRef}
					draggable
					throttleDrag={0}
					resizable
					throttleResize={0}
					rotatable
					rotationPosition='top'
					throttleRotate={0}
					origin={false}
					snappable
					bounds={{
						left: 0,
						top: 0,
						right: usedSize.width,
						bottom: usedSize.height,
					}}
					onDragStart={onEventStart}
					onResizeStart={onEventStart}
					onRotateStart={onEventStart}
					onDrag={({ top, left }) => {
						dispatch({
							type: 'change',
							payload: {
								...unitMapUnit.unit,
								width: unitMapUnit.width,
								height: unitMapUnit.height,
								posX: (left / usedSize.width) * 100,
								posY: (top / usedSize.height) * 100,
								rotation: unitMapUnit.rotation,
							},
						});
					}}
					onResize={({ width, height }) => {
						dispatch({
							type: 'change',
							payload: {
								...unitMapUnit.unit,
								width: (width / usedSize.width) * 20,
								height: (height / usedSize.width) * 20,
								posX: unitMapUnit.posX,
								posY: unitMapUnit.posY,
								rotation: unitMapUnit.rotation,
							},
						});
					}}
					onRotate={({ beforeDelta }) => {
						dispatch({
							type: 'change',
							payload: {
								...unitMapUnit.unit,
								width: unitMapUnit.width,
								height: unitMapUnit.height,
								posX: unitMapUnit.posX,
								posY: unitMapUnit.posY,
								rotation: parseInt(unitMapUnit.rotation + beforeDelta, 10) % 360,
							},
						});
					}}
				/>
			</>
		);
	}

	return (
		<div
			className={classNames('register-item__table--draggable-parent', {
				disabled: isDisabled,
			})}
			onClick={() => {
				if (!isDisabled) onClick();
			}}
			style={{
				display: 'flex',
				position: 'absolute',
			}}
			role='presentation'>
			{TableMapItemInner}
		</div>
	);
};
TableMapItem.propTypes = {
	dispatch: PropTypes.func,
	editItem: PropTypes.bool,
	onClick: PropTypes.func,
	unitMapUnit: PropTypes.shape({
		id: PropTypes.number,
		unit: PropTypes.object,
		posX: PropTypes.number,
		posY: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number,
		rotation: PropTypes.number,
	}).isRequired,
	usedSize: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}).isRequired,
	isDisabled: PropTypes.bool,
};
TableMapItem.defaultProps = {
	dispatch: () => {},
	editItem: false,
	onClick: () => {},
	isDisabled: false,
};

const TableMapForm = ({
	data,
	setIsValid,
	isLoading,
	isSubmitted,
	onFormChange,
	setTitle,
	icons,
	units,
	isSubmitting,
}) => {
	const headerContext = useContext(HeaderContext);

	const userContext = useContext(UserContext);

	const posModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.POS)
	);

	const unitMapContainer = useRef();
	const unitMapContainerSize = useComponentSize(unitMapContainer);

	const [unitMapImageSize, setUnitMapImageSize] = useState({});
	const unitMapImageRef = useRef(null);

	const usedSize = getSizeVal(unitMapContainerSize, unitMapImageSize);

	const backup = useRef({});

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [defaultBackground, setDefaultBackground] = useState(null);

	const [
		background,
		backgroundOnChange,
		backgroundValRes,
		backgroundShowVal,
		setBackgroundShowVal,
	] = useField(data, 'background', onFormChange, [required], null);

	const defaultUnit = {
		posX: 0,
		posY: 0,
		width: 2,
		height: 1,
		rotation: 0,
		isInMap: false,
	};

	const reducer = (state, action) => {
		const { type, payload } = action;

		if (type === 'set') return update(state, { $merge: payload });

		if (type === 'add') {
			if (state.unitMapUnits.findIndex(umu => umu.unit.id === payload.id) > -1) return state;

			onFormChange();

			const unitIndex = state.units.findIndex(u => u.id === payload.id);

			const newUnitMapUnitId = generateId(state.unitMapUnits);

			return update(state, {
				units: {
					[unitIndex]: {
						unitMapUnits: {
							$push: [
								{
									id: newUnitMapUnitId,
									unitMap: update(data, { $unset: ['unitMapUnits'] }),
									posX: defaultUnit.posX,
									posY: defaultUnit.posY,
									width: defaultUnit.width,
									height: defaultUnit.height,
									rotation: defaultUnit.rotation,
								},
							],
						},
					},
				},
				unitMapUnits: {
					$push: [
						{
							unit: update(payload, {
								$unset: [
									'unitMapUnits',
									'posX',
									'posY',
									'width',
									'height',
									'rotation',
								],
							}),
							id: newUnitMapUnitId,
							posX: defaultUnit.posX,
							posY: defaultUnit.posY,
							width: defaultUnit.width,
							height: defaultUnit.height,
							rotation: defaultUnit.rotation,
							module: posModule.current,
						},
					],
				},
			});
		}

		if (type === 'edit') {
			if (state.editUnit && state.editUnit.id === payload) return state;

			backup.current = { ...state };

			// add new unit , unitMap and open unit form.
			if (payload === 0) {
				const newUnitMapUnitId = generateId(state.unitMapUnits);

				const newUnitId = generateId(state.units);

				const unit = {
					id: newUnitId,
					name: '',
					icon: null,
					openNewOrderByDefault: false,
					priorityPrint: false,
					unitMapUnits: [
						{
							...defaultUnit,
							isInMap: true,
							id: newUnitMapUnitId,
							unitMap: update(data, { $unset: ['unitMapUnits'] }),
						},
					],
				};

				return update(state, {
					units: {
						$push: [unit],
					},
					unitMapUnits: {
						$push: [
							{
								unit: { id: newUnitId, icon: null, name: '' },
								id: newUnitMapUnitId,
								posX: defaultUnit.posX,
								posY: defaultUnit.posY,
								width: defaultUnit.width,
								height: defaultUnit.height,
								rotation: defaultUnit.rotation,
								module: posModule.current,
							},
						],
					},
					editUnit: { $set: update(unit, { $unset: ['unitMapUnits'] }) },
				});
			}

			// get unit form data and open it.
			const unit = update(
				state.units.find(t => t.id === payload),
				{ $unset: ['unitMapUnits'] }
			);

			const unitMapUnit = state.unitMapUnits.find(umu => umu.unit.id === payload);

			return update(state, {
				editUnit: {
					$set: {
						id: unit.id,
						name: unit.name,
						icon: unit.icon,
						openNewOrderByDefault: unit.openNewOrderByDefault,
						priorityPrint: unit.priorityPrint,
						posX: (unitMapUnit || {}).posX,
						posY: (unitMapUnit || {}).posY,
						width: (unitMapUnit || {}).width,
						height: (unitMapUnit || {}).height,
						rotation: (unitMapUnit || {}).rotation,
						isInMap: typeof unitMapUnit !== 'undefined',
					},
				},
			});
		}

		if (type === 'remove') {
			const unitMapUnit = state.unitMapUnits.filter(umu => umu.unit.id === payload)[0];

			if (!unitMapUnit) return state;

			onFormChange();

			return update(state, {
				units: {
					$set: state.units.map(t => {
						if (t.id === payload) {
							t.unitMapUnits = t.unitMapUnits.filter(
								umu => umu.id !== unitMapUnit.id
							);
						}

						return t;
					}),
				},
				unitMapUnits: {
					$set: state.unitMapUnits.filter(umu => umu.unit.id !== payload),
				},
				editUnit: { $set: null },
			});
		}

		if (type === 'delete') {
			onFormChange();

			return update(state, {
				units: {
					$set: state.units.filter(t => t.id !== payload),
				},
				unitMapUnits: {
					$set: state.unitMapUnits.filter(umu => umu.unit.id !== payload),
				},
			});
		}

		if (type === 'cancel') {
			return update(state, {
				units: {
					$set: backup.current.units,
				},
				unitMapUnits: {
					$set: backup.current.unitMapUnits,
				},
				isTableListOpen: { $set: true },
				editUnit: { $set: null },
			});
		}

		if (type === 'save') {
			const unitIndex = state.units.findIndex(t => t.id === state.editUnit.id);

			const unitMapUnitIndex = state.unitMapUnits.findIndex(
				umu => umu.unit.id === state.editUnit.id
			);

			state = update(state, {
				editUnit: { $set: null },
				units: { [unitIndex]: { id: { $set: payload.id } } },
			});

			if (unitMapUnitIndex > -1) {
				state = update(state, {
					unitMapUnits: {
						[unitMapUnitIndex]: {
							unit: {
								id: { $set: payload.id },
								'@id': { $set: payload['@id'] },
								'@type': { $set: payload['@type'] },
							},
						},
					},
				});
			}
			return state;
		}

		if (type === 'change') {
			const unitIndex = state.units.findIndex(u => u.id === payload.id);

			if (unitIndex === -1) return state;

			const unitItemUnitMapUnitIndex = state.units[unitIndex].unitMapUnits.findIndex(
				umu => umu.unitMap.id === data.id
			);

			const unitMapUnitIndex = state.unitMapUnits.findIndex(
				umu => umu.unit.id === payload.id
			);

			if (unitMapUnitIndex === -1 || unitItemUnitMapUnitIndex === -1) return state;

			onFormChange();

			// add/update unitMapUnit
			state = update(state, {
				unitMapUnits: {
					[unitMapUnitIndex]: {
						$merge: {
							posX: payload.posX,
							posY: payload.posY,
							width: payload.width,
							height: payload.height,
							rotation: payload.rotation,
						},
						unit: {
							$merge: {
								name: payload.name,
								icon: payload.icon,
								openNewOrderByDefault: payload.openNewOrderByDefault,
								priorityPrint: payload.priorityPrint,
							},
						},
					},
				},
				units: {
					[unitIndex]: {
						name: { $set: payload.name },
						icon: { $set: payload.icon },
						openNewOrderByDefault: { $set: payload.openNewOrderByDefault },
						priorityPrint: { $set: payload.priorityPrint },
						unitMapUnits: {
							[unitItemUnitMapUnitIndex]: {
								$merge: {
									posX: payload.posX,
									posY: payload.posY,
									width: payload.width,
									height: payload.height,
									rotation: payload.rotation,
								},
							},
						},
					},
				},
			});

			if (state.editUnit !== null) {
				return update(state, {
					editUnit: {
						$merge: {
							posX: payload.posX,
							posY: payload.posY,
							width: payload.width,
							height: payload.height,
							rotation: payload.rotation,
						},
					},
				});
			}
		}

		if (type === 'closeEdit') {
			return update(state, {
				isTableListOpen: { $set: true },
				editUnit: { $set: null },
			});
		}

		if (type === 'afterSave') {
			payload.forEach(umu => {
				const unitIndex = state.units.findIndex(u => u.id === umu.unit.id);

				if (unitIndex > -1) {
					const unitUnitMapUnitIndex = state.units[unitIndex].unitMapUnits.findIndex(
						uumu => uumu.id === 0
					);

					if (unitUnitMapUnitIndex > -1) {
						state = update(state, {
							units: {
								[unitIndex]: {
									unitMapUnits: {
										[unitUnitMapUnitIndex]: { id: { $set: umu.id } },
									},
								},
							},
						});
					}
				}

				const unitMapUnitIndex = state.unitMapUnits.findIndex(
					_umu => _umu.unit.id === umu.unit.id
				);

				if (unitMapUnitIndex > -1)
					state = update(state, {
						unitMapUnits: { [unitMapUnitIndex]: { id: { $set: umu.id } } },
					});
			});
		}

		return state;
	};

	const [unitMap, dispatch] = useReducer(reducer, {
		units,
		unitMapUnits: data.unitMapUnits ? [...data.unitMapUnits] : [],
		editUnit: null,
		isTableListOpen: false,
	});

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setBackgroundShowVal();
		}
	}, [isSubmitted, setNameShowVal, setBackgroundShowVal]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid && backgroundValRes.isValid && unitMap.units.filter(t => t.id !== 0)
		);
	}, [nameValRes.isValid, backgroundValRes.isValid, unitMap, setIsValid]);

	useEffect(() => {
		if (!isLoading)
			dispatch({
				type: 'set',
				payload: { unitMapUnits: data.unitMapUnits || [] },
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		dispatch({
			type: 'set',
			payload: { units },
		});
	}, [units]);

	useEffect(() => {
		data.unitMapUnits = [...unitMap.unitMapUnits];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unitMap.unitMapUnits]);

	useEffect(() => {
		data.module = posModule.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!isSubmitting && !isLoading)
			dispatch({ type: 'afterSave', payload: data.unitMapUnits });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitting]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.pos.default.text, path: pages.pos.dashboard.path },
			{ title: pages.pos.settings.text, path: pages.pos.settings.path },
			{
				title: pages.pos.settings.tableMaps.text,
				path: pages.pos.settings.tableMaps.path,
			},
			{ title: name || `New ${pages.pos.settings.tableMaps.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.pos.settings.tableMaps.text}`);

		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		if (!isLoading) {
			apiCall(
				'GET',
				'media',
				res => {
					if (!data.background) {
						backgroundOnChange({
							target: {
								name: 'productImage',
								value: res[0],
							},
						});
					}
					setDefaultBackground(res[0]);
				},
				err => {
					addErrorNotification(err.toString());
				},
				'',
				null,
				{ name: 'default_table_map_background' }
			);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	return (
		<div className='row h-100'>
			<div className='col-md-6 col-xl-4 sdms-portlet--fluid-container'>
				{unitMap.editUnit === null ? (
					<>
						{/* Table Map Settings */}
						<Portlet className='sdms-form flex-grow-0' style={{ minHeight: 330.64 }}>
							<Portlet.Head>
								<Portlet.HeadLabelTitle
									portletIcon={pages.pos.settings.tableMaps.icon2}
									smallTitle={name || 'New Table Map'}>
									Settings
								</Portlet.HeadLabelTitle>
							</Portlet.Head>
							<Portlet.Body>
								<FormGroup isLast>
									<Loading isLoading={isLoading}>
										<FormField
											name='name'
											label='Name'
											id={data.id}
											valRes={nameValRes}
											showValidation={nameShowVal}
											col={12}>
											<Input
												type='text'
												placeholder='Name (Required)'
												value={name}
												onChange={nameOnChange}
												onBlur={setNameShowVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='background'
											label='Background'
											description='.png, .jpg or .jpeg only!'
											valRes={backgroundValRes}
											showValidation={backgroundShowVal}
											col={12}>
											<ImageUpload
												media={background}
												setMedia={image =>
													backgroundOnChange({
														target: {
															name: 'productImage',
															value: image,
														},
													})
												}
												defaultImage={defaultBackground}
											/>
										</FormField>
									</Loading>
								</FormGroup>
							</Portlet.Body>
						</Portlet>
						{/* Table List */}
						<TableList
							units={unitMap.units.sort((a, b) => a.name.localeCompare(b.name))}
							dispatch={dispatch}
							unitMapId={data.id}
							addNewButton={
								<Button
									label='brand'
									text='New'
									icon='Plus'
									size='sm'
									onClick={() =>
										dispatch({
											type: 'edit',
											payload: 0,
										})
									}
								/>
							}
						/>
					</>
				) : (
					<TableForm
						key={unitMap.editUnit.id}
						data={unitMap.editUnit}
						dispatch={dispatch}
						unitMapId={data.id}
						icons={icons}
					/>
				)}
			</div>
			<div className='col-md-6 col-xl-8'>
				<Portlet id='register-table' fluid='fluid' everyTimeFluid>
					<Portlet.Body
						ref={unitMapContainer}
						className='sdms-portlet__body--fit overflow-hidden'
						background={
							background ? `url(${pathToUrl(background.path)})` : `${_defaultImage}`
						}>
						{background && (
							<img
								alt='Table Map'
								src={pathToUrl(background.path)}
								ref={unitMapImageRef}
								style={{ visibility: 'hidden', display: 'none' }}
								onLoad={() =>
									setUnitMapImageSize({
										width: unitMapImageRef.current.naturalWidth,
										height: unitMapImageRef.current.naturalHeight,
									})
								}
							/>
						)}
						<div
							role='presentation'
							style={{ ...usedSize, transform: 'translateX(0px)' }}>
							{unitMap.unitMapUnits
								.filter(umu => umu.unit.id)
								.map(umu =>
									unitMapImageSize.width ? (
										<TableMapItem
											key={umu.unit.id}
											unitMapUnit={umu}
											dispatch={dispatch}
											units={unitMap.units}
											mapSize={unitMapImageSize}
											usedSize={usedSize}
											editItem
										/>
									) : null
								)}
						</div>
					</Portlet.Body>
				</Portlet>
			</div>
		</div>
	);
};
TableMapForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		unitMapUnits: PropTypes.arrayOf(PropTypes.object),
		module: PropTypes.object,
		background: PropTypes.object,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isSubmitting: PropTypes.bool,
	isLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	icons: PropTypes.arrayOf(PropTypes.object),
	units: PropTypes.arrayOf(PropTypes.object),
};
TableMapForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		unitMapUnits: [],
		module: null,
	},
	setIsValid: () => {},
	isSubmitted: false,
	isSubmitting: false,
	isLoading: false,
	onFormChange: () => {},
	icons: [],
	units: [],
};

export default TableMapForm;
