import { useState } from 'react';

const useModal = (initialData = null) => {
	const [modal, setModal] = useState(initialData || { open: '' });

	const openModal = data => setModal({ ...modal, ...data });

	const closeModal = () => setModal({ open: '' });

	return [modal, openModal, closeModal];
};

export default useModal;
