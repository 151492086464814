import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Section from '../../reusables/layout/Section';
import FormGroup from '../../reusables/layout/FormGroup';
import FormField from '../../reusables/template/FormField';
import Input from '../../reusables/field/Input';
import Badge from '../../reusables/element/Badge';
import Button from '../../reusables/element/Button';
import Selects from '../../reusables/field/Selects';
import {
	email as emailValidation,
	maxLength,
	password as passwordValidation,
	phoneNumber,
	phoneNumberRequired,
	required,
	uniqueFnc,
} from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import Portlet from '../../reusables/layout/Portlet';
import apiCall from '../../../utils/helpers/apiCall';
import { addErrorNotification, phoneNumberParser } from '../../../utils/helpers/helper';
import { getOnlinePortalDefaultFilters } from './functions';
import Toggle from '../../reusables/field/Toggle';

const MyAccount = ({ outlet, data, isActive, countries, states, updateCustomer }) => {
	const [isValid, setIsValid] = useState(false);

	const [submitButtonAttr, setSubmitButtonAttr] = useState({
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	});

	const [isSubmitted, setIsSubmitted] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const onFormChange = useCallback(() => {
		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// New Password input & Button
	const [passwordInputShow, setPasswordInputShow] = useState(false);

	const passwordRef = useRef();

	const passwordRequiredValidation = (value, setValRes) =>
		passwordInputShow ? required(value, setValRes) : true;

	const [
		displayName,
		displayNameOnChange,
		displayNameValRes,
		displayNameShowVal,
		setDisplayNameShowVal,
	] = useField(
		data,
		'displayName',
		onFormChange,
		[
			required,
			maxLength(),
			uniqueFnc(
				'onlinePortalCustomerUniqueFieldControl',
				'displayName',
				data.id,
				true,
				null,
				null,
				getOnlinePortalDefaultFilters(data, outlet)
			),
		],
		'',
		null
	);

	const [email, emailOnChange, emailValRes, emailShowVal, setEmailShowVal] = useField(
		data,
		'email',
		onFormChange,
		[
			required,
			maxLength(),
			emailValidation,
			uniqueFnc(
				'onlinePortalCustomerUniqueFieldControl',
				'email',
				data.id,
				true,
				null,
				null,
				getOnlinePortalDefaultFilters(data, outlet)
			),
		]
	);

	const [
		password,
		passwordOnChange,
		passwordValRes,
		passwordShowVal,
		setPasswordShowVal,
		validatePassword,
	] = useField(data, 'password', onFormChange, [
		maxLength(),
		passwordRequiredValidation,
		passwordValidation,
	]);

	const [
		firstName,
		firstNameOnChange,
		firstNameValRes,
		firstNameShowVal,
		setFirstNameShowVal,
	] = useField(data, 'firstName', onFormChange, [maxLength()]);

	const [
		lastName,
		lastNameOnChange,
		lastNameValRes,
		lastNameShowVal,
		setLastNameShowVal,
	] = useField(data, 'lastName', onFormChange, [maxLength()]);

	const [
		customerCompany,
		customerCompanyOnChange,
		customerCompanyValRes,
		customerCompanyShowVal,
		setCustomerCompanyShowVal,
	] = useField(data, 'customerCompany', onFormChange, [maxLength()]);

	const [phone, phoneOnChange, phoneValRes, phoneShowVal, setPhoneShowVal] = useField(
		data,
		'phone',
		onFormChange,
		[phoneNumberRequired(outlet), phoneNumber, maxLength()],
		'',
		phoneNumberParser
	);

	const [
		altPhone,
		altPhoneOnChange,
		altPhoneValRes,
		altPhoneShowVal,
		setAltPhoneShowVal,
	] = useField(data, 'altPhone', onFormChange, [phoneNumber, maxLength()], '', phoneNumberParser);

	const [
		addressLineOne,
		addressLineOneOnChange,
		addressLineOneValRes,
		addressLineOneShowVal,
		setAddressLineOneShowVal,
	] = useField(data, 'addressLineOne', onFormChange, [maxLength()]);

	const [
		addressLineTwo,
		addressLineTwoOnChange,
		addressLineTwoValRes,
		addressLineTwoShowVal,
		setAddressLineTwoShowVal,
	] = useField(data, 'addressLineTwo', onFormChange, [maxLength()]);

	const [city, cityOnChange, cityValRes, cityShowVal, setCityShowVal] = useField(
		data,
		'city',
		onFormChange,
		[maxLength()]
	);

	const [state, stateOnChange] = useField(data, 'state', onFormChange, [], null);

	const [zip, zipOnChange, zipValRes, zipShowVal, setZipShowVal] = useField(
		data,
		'zip',
		onFormChange,
		[maxLength()]
	);

	const [country, countryOnChange] = useField(data, 'country', onFormChange, [], outlet.country);

	// update states and reset selected state.
	const changeCountryState = ({ target }) => {
		stateOnChange({
			target: {
				name: 'state',
				value: {},
			},
		});

		countryOnChange({ target });
	};

	const onSubmit = useCallback(() => {
		setIsSubmitting(true);

		setSubmitButtonAttr({
			text: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
			icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVING_ICON,
			color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
		});

		apiCall(
			'POST',
			'onlinePortalUpdateCustomer',
			() => {
				setSubmitButtonAttr({
					text: process.env.REACT_APP_SUBMIT_BUTTON_SAVED_TEXT,
					icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVED_ICON,
					color: process.env.REACT_APP_SUBMIT_BUTTON_SAVED_COLOR,
				});
				setIsSubmitting(false);
				updateCustomer(data);
			},
			err => {
				setSubmitButtonAttr({
					text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
					icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
					color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
				});
				setIsSubmitting(false);
				addErrorNotification(err.toString());
			},
			'',
			data,
			getOnlinePortalDefaultFilters(data, outlet)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const [doNotEmail, doNotEmailOnChange] = useField(data, 'doNotEmail', onFormChange, [], false);

	useEffect(() => {
		if (isSubmitted) {
			setDisplayNameShowVal();
			if (passwordInputShow) setPasswordShowVal();
			setEmailShowVal();
			setPhoneShowVal();
			setAltPhoneShowVal();
			setFirstNameShowVal();
			setLastNameShowVal();
			setCustomerCompanyShowVal();
			setAddressLineOneShowVal();
			setAddressLineTwoShowVal();
			setCityShowVal();
			setZipShowVal();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		isSubmitted,
		setDisplayNameShowVal,
		setPasswordShowVal,
		setEmailShowVal,
		setPhoneShowVal,
		setAltPhoneShowVal,
		setFirstNameShowVal,
		setLastNameShowVal,
		setCustomerCompanyShowVal,
		setAddressLineOneShowVal,
		setAddressLineTwoShowVal,
		setCityShowVal,
		setZipShowVal,
	]);

	useEffect(() => {
		setIsValid(
			displayNameValRes.isValid &&
				passwordValRes.isValid &&
				emailValRes.isValid &&
				phoneValRes.isValid &&
				altPhoneValRes.isValid &&
				firstNameValRes.isValid &&
				lastNameValRes.isValid &&
				customerCompanyValRes.isValid &&
				addressLineOneValRes.isValid &&
				addressLineTwoValRes.isValid &&
				cityValRes.isValid &&
				zipValRes.isValid
		);
	}, [
		displayNameValRes.isValid,
		passwordValRes.isValid,
		emailValRes.isValid,
		phoneValRes.isValid,
		altPhoneValRes.isValid,
		firstNameValRes.isValid,
		customerCompanyValRes.isValid,
		lastNameValRes.isValid,
		addressLineOneValRes.isValid,
		addressLineTwoValRes.isValid,
		cityValRes.isValid,
		zipValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		if (passwordInputShow && data.id) {
			passwordRef.current.focus();
		}
		validatePassword(password);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [passwordInputShow]);

	useEffect(() => {
		if (!isSubmitting) setPasswordInputShow(false);
	}, [isSubmitting]);

	if (!isActive) return null;

	return (
		<div className='sdms-online-portal-list-container sdms-online-portal-my-account-form'>
			<Portlet
				fluid='fluid'
				className='sdms-bg-transparent sdms-online-portal-not-shadow'
				hasFrame={false}>
				<Portlet.Body className='sdms-online-portal-bg-white'>
					<Section title='General'>
						<Section.Body>
							<FormGroup>
								<FormField
									name='customerId'
									label='Customer ID'
									id={data.id}
									colLg={6}>
									<Input
										type='text'
										placeholder='Auto Generate'
										value={data.customerId}
										onChange={() => {}}
										disabled
									/>
								</FormField>
								<FormField
									name='displayName'
									label='Display Name'
									description='Display Name to be used everywhere in the system'
									id={data.id}
									valRes={displayNameValRes}
									showValidation={displayNameShowVal}
									loadingContainer
									colLg={6}>
									<Input
										type='text'
										placeholder='DisplayName (Required)'
										value={displayName}
										onChange={displayNameOnChange}
										onBlur={setDisplayNameShowVal}
									/>
								</FormField>

								<FormField
									name='email'
									label='Email'
									id={data.id}
									valRes={emailValRes}
									showValidation={emailShowVal}
									loadingContainer
									colLg={6}>
									<Input
										type='text'
										placeholder='Email'
										autoComplete={false}
										value={email}
										onChange={emailOnChange}
										onBlur={setEmailShowVal}
									/>
								</FormField>

								<FormField
									name='password'
									label={
										passwordInputShow && data.id ? (
											<>
												Password
												<Badge
													className='sdms-cursor--pointer sdms-ml-10'
													design='info'
													isInline
													isElevate
													onMouseDown={() => {
														setPasswordInputShow(false);
														passwordOnChange({
															target: { value: '' },
														});
														setPasswordShowVal(false);
														if (password === '') validatePassword('');
													}}>
													Use Current Password
												</Badge>
											</>
										) : (
											'Password'
										)
									}
									labelClassName='d-flex'
									id={data.id}
									valRes={passwordValRes}
									showValidation={passwordShowVal}
									colLg={6}>
									{passwordInputShow ? (
										<Input
											ref={passwordRef}
											type='password'
											placeholder='Password (Required)'
											autoComplete={false}
											value={password}
											onChange={passwordOnChange}
											onBlur={setPasswordShowVal}
											togglePassword
										/>
									) : (
										<Button
											label='primary'
											text='Reset Password'
											block
											onClick={() => {
												setPasswordInputShow(true);
											}}
										/>
									)}
								</FormField>

								<FormField
									name='firstName'
									label='First Name'
									id={data.id}
									colLg={6}
									valRes={firstNameValRes}
									showValidation={firstNameShowVal}>
									<Input
										type='text'
										placeholder='First Name'
										autoComplete={false}
										value={firstName}
										onChange={firstNameOnChange}
										onBlur={setFirstNameShowVal}
									/>
								</FormField>

								<FormField
									name='lastName'
									label='Last Name'
									id={data.id}
									colLg={6}
									valRes={lastNameValRes}
									showValidation={lastNameShowVal}>
									<Input
										type='text'
										placeholder='Last Name'
										value={lastName}
										onChange={lastNameOnChange}
										onBlur={setLastNameShowVal}
									/>
								</FormField>
								<FormField
									name='customerCompany'
									label='Company'
									id={data.id}
									colLg={6}
									valRes={customerCompanyValRes}
									showValidation={customerCompanyShowVal}>
									<Input
										type='text'
										placeholder='Company'
										value={customerCompany}
										onChange={customerCompanyOnChange}
										onBlur={setCustomerCompanyShowVal}
									/>
								</FormField>
								<FormField
									name='phone'
									label='Phone'
									id={data.id}
									valRes={phoneValRes}
									showValidation={phoneShowVal}
									colLg={6}>
									<Input
										type='text'
										placeholder='(555) 555-5555'
										value={phone}
										onChange={phoneOnChange}
										onBlur={setPhoneShowVal}
										mask={process.env.REACT_APP_PHONE_FORMAT}
									/>
								</FormField>
								<FormField
									name='altPhone'
									label='Alt. Phone'
									id={data.id}
									valRes={altPhoneValRes}
									showValidation={altPhoneShowVal}
									colLg={6}>
									<Input
										type='text'
										placeholder='(555) 555-5555'
										value={altPhone}
										onChange={altPhoneOnChange}
										onBlur={setAltPhoneShowVal}
										mask={process.env.REACT_APP_PHONE_FORMAT}
									/>
								</FormField>
								<FormField
									name='doNotEmail'
									label='Do Not Email'
									id={data.id}
									colMd={3}
									colLg={2}>
									<Toggle value={doNotEmail} onChange={doNotEmailOnChange} />
								</FormField>
							</FormGroup>
						</Section.Body>
					</Section>
					<Section title='Address'>
						<Section.Body>
							<FormGroup>
								<FormField
									name='addressLineOne'
									label='Address 1'
									id={data.id}
									colLg={6}
									valRes={addressLineOneValRes}
									showValidation={addressLineOneShowVal}>
									<Input
										type='text'
										placeholder='Address Line 1'
										value={addressLineOne}
										onChange={addressLineOneOnChange}
										onBlur={setAddressLineOneShowVal}
									/>
								</FormField>
								<FormField
									name='addressLineTwo'
									label='Address 2'
									id={data.id}
									colLg={6}
									valRes={addressLineTwoValRes}
									showValidation={addressLineTwoShowVal}>
									<Input
										type='text'
										placeholder='Address Line 2'
										value={addressLineTwo}
										onChange={addressLineTwoOnChange}
										onBlur={setAddressLineTwoShowVal}
									/>
								</FormField>
								<FormField
									name='city'
									label='City'
									id={data.id}
									colLg={3}
									valRes={cityValRes}
									showValidation={cityShowVal}>
									<Input
										type='text'
										placeholder='City'
										value={city}
										onChange={cityOnChange}
										onBlur={setCityShowVal}
									/>
								</FormField>
								<FormField name='state' label='State' id={data.id} colLg={3}>
									<Selects
										options={
											Object.keys(country).length
												? states.filter(s => s.country.id === country.id)
												: []
										}
										placeholder='State'
										value={state}
										onChange={stateOnChange}
									/>
								</FormField>
								<FormField
									name='zip'
									label='ZIP Code'
									id={data.id}
									colLg={3}
									valRes={zipValRes}
									showValidation={zipShowVal}>
									<Input
										type='text'
										placeholder='ZIP Code'
										value={zip}
										onChange={zipOnChange}
										onBlur={setZipShowVal}
										mask={process.env.REACT_APP_ZIP_FORMAT}
									/>
								</FormField>
								<FormField name='country' label='Country' id={data.id} colLg={3}>
									<Selects
										options={countries}
										placeholder='Select Country'
										value={country}
										onChange={changeCountryState}
									/>
								</FormField>
							</FormGroup>
						</Section.Body>
					</Section>
				</Portlet.Body>
				<Portlet.Foot tall='sm' className='sdms-online-portal-bg-white'>
					<div className='col' />
					<div className='col-auto'>
						<Button
							className={classNames(' sdms-mw-100', {
								'sdms-fading-dots':
									submitButtonAttr.text ===
									process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
							})}
							label={submitButtonAttr.color}
							text={submitButtonAttr.text}
							icon={submitButtonAttr.icon}
							size='sm'
							onClick={() => {
								setIsSubmitted(true);

								if (isValid) onSubmit();
							}}
						/>
					</div>
				</Portlet.Foot>
			</Portlet>
		</div>
	);
};

MyAccount.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	isActive: PropTypes.bool.isRequired,
	countries: PropTypes.arrayOf(PropTypes.object).isRequired,
	states: PropTypes.arrayOf(PropTypes.object).isRequired,
	updateCustomer: PropTypes.func.isRequired,
};

export default MyAccount;
