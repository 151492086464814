import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';
import useModal from '../../../utils/hooks/useModal';
import usePages from '../../../utils/hooks/usePages';
import forms from '../../forms';
import apiCall from '../../../utils/helpers/apiCall';
import {
	addErrorNotification,
	addSuccessNotification,
	isBeforeAccountingClosedPeriod,
	getCreditRemainingAmount,
	priceFormatter,
} from '../../../utils/helpers/helper';
import { getPaymentRefundedAmount } from '../../../utils/helpers/paymentHelper';

import List from '../../reusables/template/List';
import Dropdown from '../../reusables/element/Dropdown';
import Button from '../../reusables/element/Button';
import ListContainer from '../../reusables/template/ListContainer';
import DialogBox from '../../reusables/element/DialogBox';
import { paymentStatuses } from '../../../utils/constants/constants';
import CreditGiveRefundModal from '../../reusables/modals/CreditGiveRefundModal';
import { PaymentDateCell, PaymentStatusCell } from '../../reusables/element/PaymentListCells';
import ApplyInvoiceModal from '../../reusables/modals/ApplyInvoiceModal';
import usePrint from '../../../utils/hooks/usePrint';

const modals = {
	VOID_DIALOG: 'voidDialog',
	APPLY_INVOICE: 'apply_invoice',
	GIVE_REFUND: 'give_refund',
};

const CustomerCell = ({ data }) => {
	return data.customer.displayName;
};

const TotalCell = ({ data }) => {
	return priceFormatter(data.amount);
};

const RemainingCreditCell = ({ data }) => {
	return priceFormatter(getCreditRemainingAmount(data));
};

const CreditMemoList = ({ history }) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.crm.default.text, path: pages.crm.dashboard.path },
			{ title: pages.crm.creditMemos.text, isActive: true },
		]);

		headerContext.setPageTitle(pages.crm.creditMemos.text);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	const [isLoading, setIsLoading] = useState(false);

	const [modal, openModal, closeModal] = useModal();

	const [onPrintPdf, PRINT_COMPONENT] = usePrint();

	const onVoid = () => {
		setIsLoading(true);

		apiCall(
			'POST',
			'voidCredit',
			res => {
				addSuccessNotification('Credit Memo successfully voided.');

				if (modal.updateData) modal.updateData(res);

				closeModal();

				setIsLoading(false);
			},
			err => {
				setIsLoading(false);
				addErrorNotification(err.toString());
			},
			'',
			{ outletId: userContext.data.selectedOutlet.id, id: modal.creditMemo.id }
		);
	};

	const onSendEmail = creditMemoIds => {
		setIsLoading(true);
		apiCall(
			'POST',
			'sendCreditMemoEmail',
			() => {
				addSuccessNotification('Email(s) successfully sent');
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{
				outletId: userContext.data.selectedOutlet.id,
				creditMemoIds,
			}
		);
	};

	const onPrintReceipt = creditMemoIds => {
		setIsLoading(true);
		apiCall(
			'POST',
			'getPrintCreditMemoContent',
			res => {
				onPrintPdf(res.creditMemos.join('<p style="page-break-before: always">'));
				setIsLoading(false);
			},
			err => {
				addErrorNotification(err.toString());
				setIsLoading(false);
			},
			'',
			{ outletId: userContext.data.selectedOutlet.id, creditMemoIds }
		);
	};

	return (
		<>
			<ListContainer
				route='credits'
				title={pages.crm.creditMemos.text}
				history={history}
				customActions={{
					void: (creditMemo, updateData) =>
						openModal({ open: modals.VOID_DIALOG, creditMemo, updateData }),
					applyToInvoices: (creditMemo, updateData) =>
						openModal({ open: modals.APPLY_INVOICE, creditMemo, updateData }),
					giveRefund: (creditMemo, updateData) =>
						openModal({ open: modals.GIVE_REFUND, creditMemo, updateData }),
					printReceipt: creditMemo => onPrintReceipt([creditMemo.id]),
					sendEmail: creditMemo => onSendEmail([creditMemo.id]),
				}}
				checkEditable={credits =>
					credits.filter(
						c =>
							c.customerSettlements.length > 0 ||
							isBeforeAccountingClosedPeriod(
								c.remittanceDate || c.timeCreated,
								userContext
							)
					).length > 0 || !userContext.hasPermission('delete_credit_memos')
						? []
						: ['delete']
				}
				forms={forms.crm.creditMemos}
				fluid='fluid'>
				<List
					withCheckBox
					fluid='fluid'
					responsive='scroll'
					checkEditable={credit => {
						// always allow edit but in some cases like voided or empty remaining credit form will be readonly.
						const actions = ['edit'];

						if (
							credit.status.value === paymentStatuses.UNSETTLED ||
							credit.status.value === paymentStatuses.SETTLED
						) {
							actions.push('sendEmail', 'printReceipt');
						}

						// if credit has remaining amount and is not voided allow to apply and give refund.
						if (
							getCreditRemainingAmount(credit) > 0 &&
							credit.status.value !== paymentStatuses.VOIDED
						)
							actions.push('applyToInvoices', 'giveRefund');

						// if credit created before accounting closed date only allow edit and apply to invoice.
						if (
							isBeforeAccountingClosedPeriod(
								credit.remittanceDate || credit.timeCreated,
								userContext
							)
						)
							return actions;

						// if customer voided or has refund do not allow to void.
						if (
							credit.status.value !== paymentStatuses.VOIDED &&
							getPaymentRefundedAmount(credit) === 0
						)
							actions.push('void');

						// if credit has any settlement do not allow to delete.
						if (credit.customerSettlements.length === 0) actions.push('delete');

						return actions;
					}}>
					<List.Col
						label='Credit Memo'
						cellData='id'
						isLinkColumn={userContext.hasPermission('view_credit_memos')}
						sortable='id'
					/>
					<List.Col label='Customer' cellComponent={<CustomerCell />} />
					<List.Col
						label='Date'
						cellComponent={<PaymentDateCell />}
						sortable='remittanceDate'
					/>
					<List.Col label='Total' cellComponent={<TotalCell />} />
					<List.Col label='Remaining Credit' cellComponent={<RemainingCreditCell />} />
					<List.Col label='Status' cellComponent={<PaymentStatusCell />} />
					<List.Col align='right' onlyHover width={150}>
						<Dropdown
							icon='Other#1'
							color='clean'
							inline
							aligned='right'
							circle
							outline={false}>
							<Dropdown.Header>Other Actions</Dropdown.Header>
							<Dropdown.Item
								icon='Edit'
								text='Edit'
								key='edit'
								noPermission={!userContext.hasPermission('edit_credit_memos')}>
								Edit
							</Dropdown.Item>
							<Dropdown.Item
								icon='Clipboard-list'
								text='Apply to Invoices'
								key='applyToInvoices'
								noPermission={
									!userContext.hasPermission('credit_memo_apply_to_invoice')
								}>
								Apply to Invoices
							</Dropdown.Item>
							<Dropdown.Item
								icon='Undo'
								text='Give Refund'
								key='giveRefund'
								noPermission={
									!userContext.hasPermission('credit_memo_give_a_refund')
								}>
								Give Refund
							</Dropdown.Item>
							<Dropdown.Item icon='Printer' key='printReceipt'>
								Print Receipt
							</Dropdown.Item>
							<Dropdown.Item icon='Mail-box' key='sendEmail'>
								Email Receipt
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='danger'
								icon='Error-circle'
								text='Void'
								key='void'
								noPermission={!userContext.hasPermission('edit_credit_memos')}>
								Void
							</Dropdown.Item>
							<Dropdown.Item
								itemsColor='danger'
								icon='Trash'
								text='Delete'
								key='delete'
								noPermission={!userContext.hasPermission('delete_credit_memos')}>
								Delete
							</Dropdown.Item>
						</Dropdown>
					</List.Col>
				</List>
			</ListContainer>
			<DialogBox
				open={modal.open === modals.VOID_DIALOG}
				title='Void'
				content='Are you sure you want to void this invoice?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't Void!`}
					onClick={closeModal}
					disabled={isLoading}
				/>
				<Button
					className='sdms-font-transform-c'
					label='danger'
					icon='Trash'
					text='Yes, Void!'
					onClick={onVoid}
					disabled={isLoading}
				/>
			</DialogBox>
			{modal.open === modals.APPLY_INVOICE && modal.creditMemo && (
				<ApplyInvoiceModal
					remittance={modal.creditMemo}
					onClose={closeModal}
					afterSubmit={creditMemo => {
						modal.updateData([creditMemo]);
						closeModal();
					}}
				/>
			)}
			{modal.open === modals.GIVE_REFUND && modal.creditMemo && (
				<CreditGiveRefundModal
					credit={modal.creditMemo}
					onClose={closeModal}
					afterSubmit={creditMemo => {
						modal.updateData([creditMemo]);
						closeModal();
					}}
				/>
			)}
			{PRINT_COMPONENT}
		</>
	);
};
CreditMemoList.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default CreditMemoList;
