import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import update from 'immutability-helper';
import useComponentSize from '@rehooks/component-size';

import UserContext from '../../../app/contexts/UserContext';
import HeaderContext from '../../../app/contexts/HeaderContext';

import { maxLength, numeric, required, url } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import usePages from '../../../utils/hooks/usePages';
import {
	extraChargesToGroups,
	filterExtraChargesByTypeAndPeriod,
	numberParser,
	negativeNumberParser,
	generateId,
	filterInvoicingFrequency,
} from '../../../utils/helpers/helper';
import {
	bookingCalculations,
	bookingPeriods,
	enumItemTypes,
} from '../../../utils/constants/constants';
import { modules } from '../../../utils/helpers/apiCall';
import { bookingCalculationConverter } from '../../../utils/helpers/reservationHelper';
import { getBookingHourlyStep } from '../../../utils/helpers/reusable';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Input from '../../reusables/field/Input';
import Selects from '../../reusables/field/Selects';
import Section from '../../reusables/layout/Section';
import Wizard from '../../reusables/template/Wizard';
import Loading from '../../reusables/template/Loading';
import Radio from '../../reusables/field/Radio';
import Toggle from '../../reusables/field/Toggle';
import ImageUpload from '../../reusables/field/ImageUpload';
import MultiSelect from '../../reusables/element/MultiSelect';
import Wysiwyg from '../../reusables/field/Wysiwyg';
import Textarea from '../../reusables/field/Textarea';
import TimePickerInput from '../../reusables/field/TimePickerInput';
import ProductBookingAdvancedPricing from './ProductBookingAdvancedPricing';
import MetaList from '../../reusables/element/MetaList';
import DynamicPriceList from '../../reusables/element/DynamicPriceList';

const ProductBookingNightlyForm = ({
	data,
	setIsValid,
	submit,
	isSubmitted,
	isLoading,
	isListsLoading,
	setTitle,
	submitButtonAttr,
	onFormChange,
	bookingTypeBookings,
	productCategoryBookings,
	taxCodes,
	policies,
	accounts,
	contracts,
	taxRates,
	itemTypes,
	enumBookingCalculations,
	enumBookingPeriods,
	enumDays,
	bookingUnits,
	bookingExtraCharges,
	calendars,
	ratePlans,
	customReservationStatuses,
	enumCalculations,
	invoicingFrequencies,
	paymentSchedules,
	acctClasses,
}) => {
	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const pages = usePages();

	const bookingModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.BOOKINGS)
	);

	const timePickerStep = useRef(getBookingHourlyStep(userContext));

	const checkInTimeMoment = useRef(
		moment()
			.utcOffset(0)
			.hour(0)
			.minute(0)
	);

	const checkOutTimeMoment = useRef(
		moment()
			.utcOffset(0)
			.hour(0)
			.minute(0)
	);

	const checkOutTimeValidation = (value, setValRes) => {
		if (
			checkInTimeMoment.current.format('HH:mm').toString() <=
			checkOutTimeMoment.current.format('HH:mm').toString()
		) {
			setValRes({
				isValid: false,
				status: 'checkInCheckOut',
				message: 'Check-out time must be earlier than check-in time',
			});
			return false;
		}
		return true;
	};

	const maxDurationValidation = (value, setValRes) => {
		if (!value) return true;
		if (minDuration > value) {
			setValRes({
				isValid: false,
				status: 'invalidMaxDuration',
				message: 'Max duration time must be equal or bigger than min duration',
			});
			return false;
		}

		return true;
	};

	const refForTimePicker = useRef(null);

	// conditionally required for deferred fields.
	const deferredRequired = (value, setValRes) => {
		if (enableDeferredIncome) return required(value, setValRes);
		return true;
	};

	const sizeTimePicker = useComponentSize(refForTimePicker);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);

	const [productImages, setProductImages] = useField(data, 'productImages', onFormChange, [], []);

	const [price, priceOnChange, priceValRes, priceShowVal, setPriceShowVal] = useField(
		data,
		'price',
		onFormChange,
		[required, numeric],
		0,
		numberParser(true, 0)
	);

	const [
		itemType,
		itemTypeOnChange,
		itemTypeValRes,
		itemTypeShowVal,
		setItemTypeShowVal,
	] = useField(data, 'itemType', onFormChange, [required], null);

	const [account, accountOnChange, accountValRes, accountShowVal, setAccountShowVal] = useField(
		data,
		'account',
		onFormChange,
		[required],
		null
	);

	const [
		assetsAccount,
		assetsAccountOnChange,
		assetsAccountValRes,
		assetsAccountShowVal,
		setAssetsAccountShowVal,
	] = useField(data, 'assetsAccount', onFormChange, [required], null);

	const [
		incomeAccount,
		incomeAccountOnChange,
		incomeAccountValRes,
		incomeAccountShowVal,
		setIncomeAccountShowVal,
	] = useField(data, 'incomeAccount', onFormChange, [required], null);

	const [
		cogsAccount,
		cogsAccountOnChange,
		cogsAccountValRes,
		cogsAccountShowVal,
		setCogsAccountShowVal,
	] = useField(data, 'cogsAccount', onFormChange, [required], null);

	const [taxCode, taxCodeOnChange, taxCodeValRes, taxCodeShowVal, setTaxCodeShowVal] = useField(
		data,
		'taxCode',
		onFormChange,
		[required],
		null
	);

	const [accountingClass, accountingClassOnChange] = useField(
		data,
		'accountingClass',
		onFormChange,
		[],
		null
	);

	const [productCategory, productCategoryOnChange] = useField(
		data,
		'productCategory',
		onFormChange,
		[],
		null
	);

	const [
		description,
		descriptionOnChange,
		descriptionValRes,
		descriptionShowVal,
		setDescriptionShowVal,
	] = useField(data, 'description', onFormChange, [maxLength(4095)], '');

	const [productContracts, productContractsOnChange] = useField(
		data,
		'productContracts',
		onFormChange,
		[],
		[]
	);

	const [productCustomReservationStatuses, productCustomReservationStatusesOnChange] = useField(
		data,
		'productCustomReservationStatuses',
		onFormChange,
		[],
		[]
	);

	const [additionalTaxes, additionalTaxesOnChange] = useField(
		data,
		'additionalTaxes',
		onFormChange,
		[],
		[]
	);

	const [paymentSchedule, paymentScheduleOnChange] = useField(
		data,
		'paymentSchedule',
		onFormChange,
		[],
		null
	);

	const [
		bookingType,
		bookingTypeOnChange,
		bookingTypeValRes,
		bookingTypeShowVal,
		setBookingTypeShowVal,
	] = useField(data, 'bookingType', onFormChange, [required], null);

	const [bookingPeriod, bookingPeriodOnChange] = useField(
		data,
		'bookingPeriod',
		() => {},
		[],
		null
	);

	const [longDescription, longDescriptionOnChange] = useField(
		data,
		'longDescription',
		onFormChange,
		[],
		''
	);

	const [hideInResults, hideInResultsOnChange] = useField(
		data,
		'hideInResults',
		onFormChange,
		[],
		false
	);

	const [reservationCaption, reservationCaptionOnChange] = useField(
		data,
		'reservationCaption',
		onFormChange
	);

	const [confirmationBccEmails, confirmationBccEmailsOnChange] = useField(
		data,
		'confirmationBccEmails',
		onFormChange,
		[],
		null
	);

	const [, checkInTimeOnChange] = useField(data, 'checkInTime', onFormChange, [], null);

	const [
		checkOutTime,
		checkOutTimeOnChange,
		checkOutTimeValRes,
		checkOutTimeShowVal,
		setCheckOutTimeShowVal,
		validateCheckOutTime,
	] = useField(data, 'checkOutTime', onFormChange, [checkOutTimeValidation], null);

	const [minDuration, minDurationOnChange] = useField(
		data,
		'minDuration',
		onFormChange,
		[],
		0,
		numberParser(false, 0)
	);

	const [
		maxDuration,
		maxDurationOnChange,
		maxDurationValRes,
		maxDurationShowVal,
		setMaxDurationShowVal,
		validateMaxDuration,
	] = useField(
		data,
		'maxDuration',
		onFormChange,
		[maxDurationValidation],
		0,
		numberParser(false, 0)
	);

	const [
		minBookInAdvance,
		minBookInAdvanceOnChange,
		minBookInAdvanceValRes,
		minBookInAdvanceShowVal,
		setMinBookInAdvanceShowVal,
	] = useField(data, 'minBookInAdvance', onFormChange, [numeric], 0, numberParser(true, 0));

	const [
		maxBookInAdvance,
		maxBookInAdvanceOnChange,
		maxBookInAdvanceValRes,
		maxBookInAdvanceShowVal,
		setMaxBookInAdvanceShowVal,
	] = useField(data, 'maxBookInAdvance', onFormChange, [numeric], 0, numberParser(true, 0));

	const [
		minPeople,
		minPeopleOnChange,
		minPeopleValRes,
		minPeopleShowVal,
		setMinPeopleShowVal,
	] = useField(data, 'minPeople', onFormChange, [numeric], 0, numberParser(true, 0));

	const [
		maxPeople,
		maxPeopleOnChange,
		maxPeopleValRes,
		maxPeopleShowVal,
		setMaxPeopleShowVal,
	] = useField(data, 'maxPeople', onFormChange, [numeric], 0, numberParser(true, 0));

	const [advancedPricing, advancedPricingOnChange] = useField(
		data,
		'advancedPricing',
		onFormChange,
		[],
		false
	);

	const [policy, policyOnChange, policyValRes, policyShowVal, setPolicyShowVal] = useField(
		data,
		'policy',
		onFormChange,
		[],
		null
	);

	const [invoiceOffSetDays, invoiceOffSetDaysOnChange] = useField(
		data,
		'invoiceOffSetDays',
		onFormChange,
		[],
		null,
		negativeNumberParser
	);

	const [bookingCalculation, bookingCalculationOnChange] = useField(
		data,
		'bookingCalculation',
		() => {},
		[],
		null
	);

	const [assignedUnitBookings, assignedUnitBookingsOnChange] = useField(
		data,
		'unitBookings',
		onFormChange,
		[],
		[]
	);

	const [checkInDays, checkInDaysOnChange] = useField(data, 'checkInDays', onFormChange, [], []);

	const [checkOutDays, checkOutDaysOnChange] = useField(
		data,
		'checkOutDays',
		onFormChange,
		[],
		[]
	);

	const [sortOrder, sortOrderOnChange] = useField(
		data,
		'sortOrder',
		onFormChange,
		[],
		0,
		numberParser(false, 0)
	);

	const [inactive, inactiveOnChange] = useField(data, 'inactive', onFormChange, [], false);

	const [
		onlineUrl,
		onlineUrlOnChange,
		onlineUrlValRes,
		onlineUrlShowVal,
		setOnlineUrlShowVal,
	] = useField(data, 'onlineUrl', onFormChange, [url]);

	const [dynamicPrices, dynamicPricesOnChange] = useField(
		data,
		'dynamicPrices',
		onFormChange,
		[],
		[]
	);

	const [metas, metasOnChange] = useField(data, 'metas', onFormChange, [], []);

	const [extraCharges, setExtraCharges] = useState([]);

	const [selectedExtraCharges, setSelectedExtraCharges] = useState([]);

	const [enableDeferredIncome, enableDeferredIncomeOnChange] = useField(
		data,
		'enableDeferredIncome',
		onFormChange,
		[],
		false
	);

	const [
		deferredIncomeFrequency,
		deferredIncomeFrequencyOnChange,
		deferredIncomeFrequencyValRes,
		deferredIncomeFrequencyShowVal,
		setDeferredIncomeFrequencyShowVal,
		validateDeferredIncomeFrequency,
	] = useField(data, 'deferredIncomeFrequency', onFormChange, [deferredRequired], null);

	const [
		deferredIncomeSalesAccount,
		deferredIncomeSalesAccountOnChange,
		deferredIncomeSalesAccountValRes,
		deferredIncomeSalesAccountShowVal,
		setDeferredIncomeSalesAccountShowVal,
		validateDeferredIncomeSalesAccount,
	] = useField(data, 'deferredIncomeSalesAccount', onFormChange, [deferredRequired], null);

	const [isVirtual, isVirtualOnChange] = useField(data, 'isVirtual', onFormChange, [], false);

	useEffect(() => {
		if (!isLoading && data.productExtraCharges) {
			setSelectedExtraCharges(
				data.productExtraCharges
					.filter(p => p.productCategory)
					.map(p => p.productCategory)
					.filter((pc, index, self) => self.findIndex(_pc => _pc.id === pc.id) === index)
					.concat(data.productExtraCharges.filter(p => !p.productCategory))
			);
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isLoading]);

	useEffect(() => {
		setExtraCharges(extraChargesToGroups(bookingExtraCharges, bookingType, bookingPeriod));
	}, [bookingExtraCharges, bookingType, bookingPeriod]);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setBookingTypeShowVal();
			setTaxCodeShowVal();
			setAccountShowVal();
			setPriceShowVal();
			setPolicyShowVal();
			setMaxDurationShowVal();
			setMinBookInAdvanceShowVal();
			setMaxBookInAdvanceShowVal();
			setMinPeopleShowVal();
			setMaxPeopleShowVal();
			setCheckOutTimeShowVal();
			setDescriptionShowVal();
			setIncomeAccountShowVal();
			setCogsAccountShowVal();
			setAssetsAccountShowVal();
			setItemTypeShowVal();
			setOnlineUrlShowVal();
			setDeferredIncomeFrequencyShowVal();
			setDeferredIncomeSalesAccountShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setBookingTypeShowVal,
		setTaxCodeShowVal,
		setAccountShowVal,
		setPriceShowVal,
		setPolicyShowVal,
		setMaxDurationShowVal,
		setMinBookInAdvanceShowVal,
		setMaxBookInAdvanceShowVal,
		setMinPeopleShowVal,
		setMaxPeopleShowVal,
		setCheckOutTimeShowVal,
		setDescriptionShowVal,
		setIncomeAccountShowVal,
		setCogsAccountShowVal,
		setAssetsAccountShowVal,
		setItemTypeShowVal,
		setOnlineUrlShowVal,
		setDeferredIncomeFrequencyShowVal,
		setDeferredIncomeSalesAccountShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				bookingTypeValRes.isValid &&
				taxCodeValRes.isValid &&
				itemTypeValRes.isValid &&
				(itemType.value === enumItemTypes.INVENTORY
					? cogsAccountValRes.isValid &&
					  incomeAccountValRes.isValid &&
					  assetsAccountValRes.isValid
					: accountValRes.isValid) &&
				priceValRes.isValid &&
				maxDurationValRes.isValid &&
				minBookInAdvanceValRes.isValid &&
				maxBookInAdvanceValRes.isValid &&
				minPeopleValRes.isValid &&
				maxPeopleValRes.isValid &&
				checkOutTimeValRes.isValid &&
				descriptionValRes.isValid &&
				onlineUrlValRes.isValid &&
				deferredIncomeFrequencyValRes.isValid &&
				deferredIncomeSalesAccountValRes.isValid &&
				metas.filter(m => m.isValid === false).length === 0 &&
				dynamicPrices.filter(dp => dp.isValid === false).length === 0
		);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [
		nameValRes.isValid,
		bookingTypeValRes.isValid,
		taxCodeValRes.isValid,
		accountValRes.isValid,
		priceValRes.isValid,
		policyValRes.isValid,
		maxDurationValRes.isValid,
		minBookInAdvanceValRes.isValid,
		maxBookInAdvanceValRes.isValid,
		minPeopleValRes.isValid,
		maxPeopleValRes.isValid,
		checkOutTimeValRes.isValid,
		descriptionValRes.isValid,
		cogsAccountValRes.isValid,
		assetsAccountValRes.isValid,
		incomeAccountValRes.isValid,
		itemTypeValRes.isValid,
		onlineUrlValRes.isValid,
		metas,
		dynamicPrices,
		deferredIncomeFrequencyValRes.isValid,
		deferredIncomeSalesAccountValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{ title: pages.booking.default.text, path: pages.booking.dashboard.path },
			{ title: pages.booking.products.text, path: pages.booking.products.path },
			{ title: name || `New ${pages.booking.products.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.booking.products.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		data.module = bookingModule.current;
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	useEffect(() => {
		if (!isListsLoading) {
			bookingPeriodOnChange({
				target: {
					value:
						enumBookingPeriods.find(ebp => ebp.value === bookingPeriods.NIGHTLY) ||
						null,
				},
			});

			bookingCalculationOnChange({
				target: {
					value: enumBookingCalculations.find(
						ebc => ebc.value === bookingCalculations.PER_PERIOD
					),
				},
			});
		}
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [isListsLoading]);

	useEffect(() => {
		if (!isLoading) {
			if (data.checkInTime && data.checkInTime !== '')
				checkInTimeMoment.current = moment(data.checkInTime).utc(false);

			if (data.checkOutTime && data.checkOutTime !== '')
				checkOutTimeMoment.current = moment(data.checkOutTime).utc(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		// re-validate deferred field on enableDeferredIncome change.
		validateDeferredIncomeFrequency(deferredIncomeFrequency);
		validateDeferredIncomeSalesAccount(deferredIncomeSalesAccount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enableDeferredIncome]);

	return (
		<Wizard onSubmit={submit} submitButtonAttr={submitButtonAttr} isLoading={isLoading}>
			<Wizard.Item
				title='General'
				description='General Information'
				isValid={
					isSubmitted
						? nameValRes.isValid &&
						  bookingTypeValRes.isValid &&
						  taxCodeValRes.isValid &&
						  itemTypeValRes.isValid &&
						  (itemType.value === enumItemTypes.INVENTORY
								? cogsAccountValRes.isValid &&
								  incomeAccountValRes.isValid &&
								  assetsAccountValRes.isValid
								: accountValRes.isValid) &&
						  priceValRes.isValid
						: true
				}>
				<Section>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField name='id' label='Item id' id={data.id} colLg={6}>
									<Input
										type='text'
										placeholder='Auto generated'
										value={data.productId || ''}
										onChange={() => {}}
										disabled
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='name'
									label='Name'
									id={data.id}
									valRes={nameValRes}
									showValidation={nameShowVal}
									colLg={6}>
									<Input
										type='text'
										placeholder='Name (Required)'
										value={name}
										onChange={nameOnChange}
										onBlur={setNameShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='itemType'
									label='Item Type'
									id={data.id}
									valRes={itemTypeValRes}
									showValidation={itemTypeShowVal}
									colLg={6}
									colXl={3}>
									<Selects
										options={itemTypes.filter(it => !it.isSystem)}
										placeholder='Item Type (Required)'
										value={itemType}
										onChange={({ target }) => {
											itemTypeOnChange({ target });
											accountOnChange({
												target: { name: 'account', value: {} },
											});
											incomeAccountOnChange({
												target: {
													name: 'incomeAccount',
													value: {},
												},
											});
											cogsAccountOnChange({
												target: { name: 'cogsAccount', value: {} },
											});
											assetsAccountOnChange({
												target: {
													name: 'assetsAccount',
													value: {},
												},
											});
										}}
										onBlur={setItemTypeShowVal}
										displayKey='value'
										disabled={data.id !== 0}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									ref={refForTimePicker}
									name='bookingType'
									label='Booking Type'
									id={data.id}
									valRes={bookingTypeValRes}
									showValidation={bookingTypeShowVal}
									colLg={6}
									colXl={3}>
									<Selects
										options={bookingTypeBookings.filter(bt => bt.nightly)}
										placeholder='Booking Type'
										value={bookingType}
										onChange={e => {
											bookingTypeOnChange(e);
											assignedUnitBookingsOnChange({ target: { value: [] } });
										}}
										onBlur={setBookingTypeShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='bookingPeriod'
									label='Booking Period'
									id={data.id}
									colLg={6}
									colXl={3}>
									<Selects
										options={enumBookingPeriods}
										placeholder='Booking Period'
										value={bookingPeriod}
										onChange={() => {}}
										displayKey='value'
										disabled
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='productCategory'
									label='Product Category'
									id={data.id}
									colLg={6}
									colXl={3}>
									<Selects
										options={productCategoryBookings}
										placeholder='Product Category'
										value={productCategory}
										onChange={productCategoryOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='description'
									label='Description'
									id={data.id}
									colXl={12}
									showValidation={descriptionShowVal}
									valRes={descriptionValRes}>
									<Textarea
										placeholder='Description'
										value={description}
										onChange={descriptionOnChange}
										textRow={4}
										onBlur={setDescriptionShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='productImages'
									label='Images'
									description='.png, .jpg or .jpeg only'
									id={data.id}
									colXl={12}>
									<ImageUpload
										media={productImages}
										setMedia={image =>
											setProductImages({
												target: {
													name: 'productImages',
													value: image,
												},
											})
										}
										multiple
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='longDescription'
									label='Description displayed in search results'
									id={data.id}
									col={12}>
									<Wysiwyg
										value={longDescription}
										onChange={longDescriptionOnChange}
									/>
								</FormField>
							</Loading>
							<div className='sdms-heading sdms-heading--md col-12'>Accounting</div>
							<Loading isLoading={isLoading}>
								<FormField
									name='taxCode'
									label='Tax Code'
									id={data.id}
									valRes={taxCodeValRes}
									showValidation={taxCodeShowVal}
									colLg={6}>
									<Selects
										options={taxCodes}
										placeholder='Tax code'
										value={taxCode}
										onChange={taxCodeOnChange}
										onBlur={setTaxCodeShowVal}
									/>
								</FormField>
							</Loading>
							{itemType && itemType.value === enumItemTypes.INVENTORY ? (
								<>
									<Loading isLoading={isLoading}>
										<FormField
											name='incomeAccount'
											label='Income Account'
											id={data.id}
											valRes={incomeAccountValRes}
											showValidation={incomeAccountShowVal}
											colLg={6}>
											<Selects
												options={accounts}
												placeholder='Income Account (Required)'
												value={incomeAccount}
												onChange={incomeAccountOnChange}
												onBlur={setIncomeAccountShowVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='cogsAccount'
											label='COGS Account'
											id={data.id}
											valRes={cogsAccountValRes}
											showValidation={cogsAccountShowVal}
											colLg={6}>
											<Selects
												options={accounts}
												placeholder='COGS Account (Required)'
												value={cogsAccount}
												onChange={cogsAccountOnChange}
												onBlur={setCogsAccountShowVal}
											/>
										</FormField>
									</Loading>
									<Loading isLoading={isLoading}>
										<FormField
											name='assetsAccount'
											label='Assets Account'
											id={data.id}
											valRes={assetsAccountValRes}
											showValidation={assetsAccountShowVal}
											colLg={6}>
											<Selects
												options={accounts}
												placeholder='Assets Account (Required)'
												value={assetsAccount}
												onChange={assetsAccountOnChange}
												onBlur={setAssetsAccountShowVal}
											/>
										</FormField>
									</Loading>
								</>
							) : (
								<Loading isLoading={isLoading}>
									<FormField
										name='account'
										label='Account'
										id={data.id}
										valRes={accountValRes}
										showValidation={accountShowVal}
										colLg={6}>
										<Selects
											options={accounts}
											placeholder='Account (Required)'
											value={account}
											onChange={accountOnChange}
											onBlur={setAccountShowVal}
										/>
									</FormField>
								</Loading>
							)}
							<Loading isLoading={isLoading}>
								<FormField
									name='accountingClass'
									label='Accounting Class'
									id={data.id}
									colMd={6}>
									<Selects
										options={acctClasses}
										placeholder='Accounting class'
										value={accountingClass}
										onChange={accountingClassOnChange}
									/>
								</FormField>
							</Loading>
							<div className='sdms-heading sdms-heading--md col-12'>Base Pricing</div>
							<Loading isLoading={isLoading}>
								<FormField
									name='price'
									label='Price'
									id={data.id}
									valRes={priceValRes}
									showValidation={priceShowVal}
									colLg={6}
									colXl={3}>
									<Input
										type='text'
										placeholder='Price'
										value={price}
										onChange={priceOnChange}
										onBlur={setPriceShowVal}
										pattern={process.env.REACT_APP_PRICE_PATTERN}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='bookingCalculation'
									label='Price Calculation Method'
									id={data.id}
									colLg={6}
									colXl={3}>
									<Selects
										options={[]}
										placeholder='Price Calculation Method (Required)'
										value={
											bookingCalculation
												? bookingCalculationConverter(
														bookingCalculation,
														bookingPeriods.NIGHTLY
												  )
												: null
										}
										onChange={() => {}}
										displayKey='label'
										disabled
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='additionalTaxes'
									label='Additional Taxes'
									id={data.id}
									colLg={6}
									colXl={3}>
									<Selects
										options={taxRates.filter(
											tr =>
												tr.id !==
													userContext.data.selectedOutlet.settings.taxRate
														.id && !tr.isGroup
										)}
										placeholder='Select Tax Rate'
										value={additionalTaxes}
										onChange={additionalTaxesOnChange}
										multiple
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='paymentSchedule'
									label='Payment Schedule'
									id={data.id}
									colLg={6}
									colXl={3}>
									<Selects
										options={paymentSchedules}
										placeholder='Select Payment Schedule'
										value={paymentSchedule}
										onChange={paymentScheduleOnChange}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
			<Wizard.Item title='Units' description='Assign Units' icon={pages.pos.registers.icon2}>
				<Section>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField
									name='bookingUnits'
									label='Booking Units:'
									id={data.id}
									col={12}>
									<MultiSelect
										data={bookingUnits
											.filter(
												bu =>
													bu.bookingType &&
													bookingType &&
													bu.bookingType.name === bookingType.name
											)
											.sort((a, b) => a.name.localeCompare(b.name))}
										titleProp='name'
										value={assignedUnitBookings}
										onChange={assignedUnitBookingsOnChange}
										name='units'
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
			<Wizard.Item
				title='Booking Setup'
				description='Booking Rules'
				icon={pages.systemSettings.users.icon}
				isValid={
					isSubmitted
						? maxDurationValRes.isValid &&
						  minBookInAdvanceValRes.isValid &&
						  maxBookInAdvanceValRes.isValid &&
						  minPeopleValRes.isValid &&
						  maxPeopleValRes.isValid &&
						  checkOutTimeValRes.isValid
						: true
				}>
				<Section>
					<Section.Body>
						<FormGroup>
							<Loading isLoading={isLoading}>
								<FormField name='isVirtual' label='Virtual' id={data.id} colMd={4}>
									<Toggle
										value={isVirtual}
										onChange={isVirtualOnChange}
										disabled={data.id !== 0}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='hideInResults'
									label='Hide in search results'
									id={data.id}
									colMd={4}>
									<Toggle
										spaceLess
										value={hideInResults}
										onChange={hideInResultsOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField name='inactive' label='Inactive' id={data.id} colMd={4}>
									<Toggle
										spaceLess
										value={inactive}
										onChange={inactiveOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='onlineUrl'
									label='Online Url'
									id={data.id}
									colLg={6}
									showValidation={onlineUrlShowVal}
									valRes={onlineUrlValRes}>
									<Input
										type='text'
										placeholder='Online Url'
										value={onlineUrl}
										onChange={onlineUrlOnChange}
										onBlur={setOnlineUrlShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='confirmationBccEmails'
									label='Confirmation BCC Emails'
									id={data.id}
									colLg={6}>
									<Input
										type='text'
										placeholder='Confirmation BCC EMails'
										value={confirmationBccEmails}
										onChange={confirmationBccEmailsOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='reservationCaption'
									label='Reservation Caption'
									id={data.id}
									colLg={6}>
									<Input
										type='text'
										placeholder='Reservation Caption'
										value={reservationCaption}
										onChange={reservationCaptionOnChange}
									/>
								</FormField>
							</Loading>
							<div className='sdms-heading sdms-heading--md col-12'>
								Check In / Check Out
							</div>
							<Loading isLoading={isLoading}>
								<FormField
									name='checkInDay'
									label='Check-in Days'
									id={data.id}
									col={12}>
									<MultiSelect
										data={enumDays}
										titleProp='value'
										value={checkInDays}
										onChange={checkInDaysOnChange}
										name='checkInDays'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='checkOutDays'
									label='Check-out Days'
									id={data.id}
									col={12}>
									<MultiSelect
										data={enumDays}
										titleProp='value'
										value={checkOutDays}
										onChange={checkOutDaysOnChange}
										name='checkOutDays'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='checkInTime'
									label='Check-In'
									id={data.id}
									colLg={6}
									colXl={3}>
									<TimePickerInput
										defaultValue={checkInTimeMoment.current}
										showSecond={false}
										value={checkInTimeMoment.current}
										onChange={target => {
											checkInTimeOnChange({
												target: {
													value: target
														? target.format('h:mm a')
														: '12:00 am',
												},
											});

											checkInTimeMoment.current =
												target ||
												checkInTimeMoment.current.hour(0).minute(0);
											validateCheckOutTime(checkOutTime);
										}}
										size={sizeTimePicker}
										use12Hours
										minuteStep={timePickerStep.current}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='checkOutTime'
									label='Check-Out'
									id={data.id}
									valRes={checkOutTimeValRes}
									showValidation={checkOutTimeShowVal}
									colLg={6}
									colXl={3}>
									<TimePickerInput
										defaultValue={checkOutTimeMoment.current}
										showSecond={false}
										value={checkOutTimeMoment.current}
										onChange={target => {
											checkOutTimeOnChange({
												target: {
													value: target
														? target.format('h:mm a')
														: '12:00 am',
												},
											});
											checkOutTimeMoment.current =
												target ||
												checkOutTimeMoment.current.hour(0).minute(0);
										}}
										onClose={setCheckOutTimeShowVal}
										size={sizeTimePicker}
										use12Hours
										minuteStep={timePickerStep.current}
									/>
								</FormField>
							</Loading>
							<div className='sdms-heading sdms-heading--md col-12'>
								Default Policies
							</div>
							<Loading isLoading={isLoading}>
								<FormField
									name='policy'
									label='Policy'
									id={data.id}
									valRes={policyValRes}
									showValidation={policyShowVal}
									colLg={6}>
									<Selects
										options={policies.filter(
											p => p.bookingPeriod.value === bookingPeriods.NIGHTLY
										)}
										placeholder='Deposit policy'
										value={policy}
										onChange={policyOnChange}
										onBlur={setPolicyShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='invoiceOffSetDays'
									label='Invoice Offset Days'
									id={data.id}
									colMd={6}
									colLg={3}>
									<Input
										onChange={invoiceOffSetDaysOnChange}
										value={invoiceOffSetDays}
									/>
								</FormField>
							</Loading>
							<div className='sdms-heading sdms-heading--md col-12'>Constraints</div>
							<Loading isLoading={isLoading}>
								<FormField
									name='minDuration'
									label='Minimum Duration'
									id={data.id}
									colLg={6}
									colXl={3}>
									<Input
										type='text'
										placeholder='Min Duration'
										value={minDuration}
										onChange={e => {
											minDurationOnChange(e);
											validateMaxDuration(maxDuration);
										}}
										append='Nights'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='maxDuration'
									label='Maximum Duration'
									id={data.id}
									valRes={maxDurationValRes}
									showValidation={maxDurationShowVal}
									colLg={6}
									colXl={3}>
									<Input
										type='text'
										placeholder='Max Duration'
										value={maxDuration}
										onChange={maxDurationOnChange}
										append='Nights'
										onBlur={setMaxDurationShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='minBookInAdvance'
									label='Minimum Book in Advance'
									description='in days'
									id={data.id}
									valRes={minBookInAdvanceValRes}
									showValidation={minBookInAdvanceShowVal}
									colLg={6}
									colXl={3}>
									<Input
										type='text'
										placeholder='Minimum Book in Advance duration'
										value={minBookInAdvance}
										onChange={minBookInAdvanceOnChange}
										onBlur={setMinBookInAdvanceShowVal}
										append='Days'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='maxBookInAdvance'
									label='Maximum Book in Advance'
									description='in days'
									id={data.id}
									valRes={maxBookInAdvanceValRes}
									showValidation={maxBookInAdvanceShowVal}
									colLg={6}
									colXl={3}>
									<Input
										type='text'
										placeholder='Maximum Book in Advance duration'
										value={maxBookInAdvance}
										onChange={maxBookInAdvanceOnChange}
										onBlur={setMaxBookInAdvanceShowVal}
										append='Days'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='minPeople'
									label='Minimum People'
									id={data.id}
									valRes={minPeopleValRes}
									showValidation={minPeopleShowVal}
									colLg={6}
									colXl={3}>
									<Input
										type='text'
										placeholder='Minimum People'
										value={minPeople}
										onChange={minPeopleOnChange}
										onBlur={setMinPeopleShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='maxPeople'
									label='Maximum People'
									id={data.id}
									valRes={maxPeopleValRes}
									showValidation={maxPeopleShowVal}
									colLg={6}
									colXl={3}>
									<Input
										type='text'
										placeholder='Maximum People'
										value={maxPeople}
										onChange={maxPeopleOnChange}
										onBlur={setMaxPeopleShowVal}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='advancedPricing'
									label='Pricing modal'
									id={data.id}
									colXl={6}>
									<Radio.Container isInline>
										<Radio
											checked={!advancedPricing}
											id='yes'
											name='advancedPricing'
											content='Base Pricing/ No Constraints'
											className='sdms-radio--primary'
											onChange={() =>
												advancedPricingOnChange({
													target: { value: false },
												})
											}
										/>
										<Radio
											checked={advancedPricing}
											id='no'
											name='advancedPricing'
											content='Advanced Pricing/ Constraints'
											className='sdms-radio--primary'
											onChange={() =>
												advancedPricingOnChange({
													target: { value: true },
												})
											}
										/>
									</Radio.Container>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='productContracts'
									label='Product Contracts'
									id={data.id}
									colLg={6}>
									<Selects
										options={contracts}
										titleProp='Booking contract'
										itemsCol={6}
										value={productContracts}
										onChange={productContractsOnChange}
										multiple
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='productCustomReservationStatuses'
									label='Custom Reservation Status'
									id={data.id}
									colLg={6}>
									<Selects
										options={customReservationStatuses}
										itemsCol={6}
										value={productCustomReservationStatuses}
										onChange={productCustomReservationStatusesOnChange}
										multiple
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='extraCharges'
									label='Extras / Price Adjustments:'
									id={data.id}
									col={12}>
									<MultiSelect
										data={extraCharges}
										titleProp='name'
										value={selectedExtraCharges}
										onChange={e => {
											setSelectedExtraCharges(e.target.value);
											onFormChange();
											data.productExtraCharges = [];
											e.target.value.forEach(ec => {
												if (
													ec['@type'] === 'SdmsProductCategoryExtraCharge'
												)
													data.productExtraCharges.push(
														...filterExtraChargesByTypeAndPeriod(
															bookingExtraCharges,
															bookingType,
															bookingPeriod
														).filter(
															p =>
																p.productCategory &&
																p.productCategory.id === ec.id
														)
													);
												else data.productExtraCharges.push(ec);
											});
										}}
										name='extraFees'
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='sortOrder'
									label='Sort order'
									id={data.id}
									colLg={3}>
									<Input
										type='number'
										placeholder='Sort Order'
										value={sortOrder}
										onChange={sortOrderOnChange}
										pattern={process.env.REACT_APP_INTEGER_PATTERN}
									/>
								</FormField>
							</Loading>
							<div className='sdms-heading sdms-heading--md col-12'>
								Dynamic Prices
							</div>
							<DynamicPriceList
								onAdd={() => {
									dynamicPricesOnChange({
										target: {
											value: update(dynamicPrices, {
												$push: [
													{
														id: generateId(dynamicPrices),
														pricePoint: '',
														adjustmentAmount: '',
														type: null,
														isValid: false,
													},
												],
											}),
										},
									});
								}}
								onDelete={dynamicPriceId => {
									const dynamicPriceIndex = dynamicPrices.findIndex(
										dp => dp.id === dynamicPriceId
									);

									if (dynamicPriceIndex > -1)
										dynamicPricesOnChange({
											target: {
												value: update(dynamicPrices, {
													$splice: [[dynamicPriceIndex, 1]],
												}),
											},
										});
								}}
								onChange={dynamicPriceData => {
									const dynamicPriceIndex = dynamicPrices.findIndex(
										dp => dp.id === dynamicPriceData.id
									);

									if (dynamicPriceIndex > -1)
										dynamicPricesOnChange({
											target: {
												value: update(dynamicPrices, {
													[dynamicPriceIndex]: { $set: dynamicPriceData },
												}),
											},
										});
								}}
								dynamicPrices={dynamicPrices}
								disabled={isLoading}
								isSubmitted={isSubmitted}
								enumCalculations={enumCalculations}
							/>
							<div className='sdms-heading sdms-heading--md col-12'>Metas</div>
							<MetaList
								onAdd={() => {
									metasOnChange({
										target: {
											value: update(metas, {
												$push: [
													{
														id: generateId(metas),
														meta: null,
														value: '',
														isValid: false,
													},
												],
											}),
										},
									});
								}}
								onDelete={metaId => {
									const metaIndex = metas.findIndex(m => m.id === metaId);

									if (metaIndex > -1)
										metasOnChange({
											target: {
												value: update(metas, {
													$splice: [[metaIndex, 1]],
												}),
											},
										});
								}}
								onChange={metaData => {
									const metaIndex = metas.findIndex(m => m.id === metaData.id);

									if (metaIndex > -1)
										metasOnChange({
											target: {
												value: update(metas, {
													[metaIndex]: { $set: metaData },
												}),
											},
										});
								}}
								productMetas={metas}
								disabled={isLoading}
								isSubmitted={isSubmitted}
							/>
						</FormGroup>
					</Section.Body>
				</Section>
				<Section title='Deferred Income'>
					<Section.Body>
						<FormGroup row>
							<Loading isLoading={isLoading}>
								<FormField
									name='enableDeferredIncome'
									label='Enable Deferred Income'
									id={data.id}
									colMd={3}>
									<Toggle
										spaceLess
										value={enableDeferredIncome}
										onChange={enableDeferredIncomeOnChange}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='deferredIncomeFrequency'
									label='Frequency'
									id={data.id}
									valRes={deferredIncomeFrequencyValRes}
									showValidation={deferredIncomeFrequencyShowVal}
									colMd={3}>
									<Selects
										options={
											bookingPeriod
												? filterInvoicingFrequency(
														invoicingFrequencies,
														bookingPeriod.value,
														true
												  )
												: []
										}
										placeholder={`Frequency${
											enableDeferredIncome ? ' (Required)' : ''
										}`}
										value={deferredIncomeFrequency}
										onChange={deferredIncomeFrequencyOnChange}
										onBlur={setDeferredIncomeFrequencyShowVal}
										displayKey='value'
										disabled={!enableDeferredIncome}
									/>
								</FormField>
							</Loading>
							<Loading isLoading={isLoading}>
								<FormField
									name='deferredIncomeSalesAccount'
									label='Sales Income Account'
									id={data.id}
									valRes={deferredIncomeSalesAccountValRes}
									showValidation={deferredIncomeSalesAccountShowVal}
									colMd={3}>
									<Selects
										options={accounts}
										placeholder={`Sales Income Account${
											enableDeferredIncome ? ' (Required)' : ''
										}`}
										value={deferredIncomeSalesAccount}
										onChange={deferredIncomeSalesAccountOnChange}
										onBlur={setDeferredIncomeSalesAccountShowVal}
										disabled={!enableDeferredIncome}
									/>
								</FormField>
							</Loading>
						</FormGroup>
					</Section.Body>
				</Section>
			</Wizard.Item>
			<Wizard.Item
				title='Advanced Pricing & Constraints'
				description='Assign Custom Pricing & Constraints per Date'
				icon={pages.systemSettings.users.icon}
				disable={!advancedPricing}>
				<ProductBookingAdvancedPricing
					calendars={calendars}
					ratePlans={ratePlans}
					product={data}
					enumDays={enumDays}
					onFormChange={onFormChange}
				/>
			</Wizard.Item>
		</Wizard>
	);
};
ProductBookingNightlyForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		bookingType: PropTypes.object,
		productCategory: PropTypes.object,
		description: PropTypes.string,
		longDescription: PropTypes.string,
		taxCode: PropTypes.object,
		account: PropTypes.object,
		price: PropTypes.number,
		additionalTaxes: PropTypes.arrayOf(PropTypes.object),
		checkInDays: PropTypes.arrayOf(PropTypes.object),
		checkOutDays: PropTypes.arrayOf(PropTypes.object),
		checkInTime: PropTypes.string,
		checkOutTime: PropTypes.string,
		bufferHour: PropTypes.number,
		bufferMinute: PropTypes.number,
		policy: PropTypes.object,
		invoiceOffSetDays: PropTypes.number,
		minDuration: PropTypes.number,
		maxDuration: PropTypes.number,
		minBookInAdvance: PropTypes.number,
		maxBookInAdvance: PropTypes.number,
		pricingModel: PropTypes.object,
		productContracts: PropTypes.arrayOf(PropTypes.object),
		reservationCaption: PropTypes.string,
		productExtraCharges: PropTypes.arrayOf(PropTypes.object),
		advancedPricing: PropTypes.bool,
		newTime: PropTypes.string,
		module: PropTypes.object,
		productId: PropTypes.string,
		itemType: PropTypes.object,
		bookingPeriod: PropTypes.object,
		bookingCalculation: PropTypes.object,
	}),
	setIsValid: PropTypes.func,
	submit: PropTypes.func,
	isSubmitted: PropTypes.bool,
	isLoading: PropTypes.bool,
	isListsLoading: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	onFormChange: PropTypes.func,
	bookingTypeBookings: PropTypes.arrayOf(PropTypes.object),
	productCategoryBookings: PropTypes.arrayOf(PropTypes.object),
	taxCodes: PropTypes.arrayOf(PropTypes.object),
	policies: PropTypes.arrayOf(PropTypes.object),
	accounts: PropTypes.arrayOf(PropTypes.object),
	contracts: PropTypes.arrayOf(PropTypes.object),
	taxRates: PropTypes.arrayOf(PropTypes.object),
	bookingExtraCharges: PropTypes.arrayOf(PropTypes.object),
	enumBookingCalculations: PropTypes.arrayOf(PropTypes.object),
	itemTypes: PropTypes.arrayOf(PropTypes.object),
	enumBookingPeriods: PropTypes.arrayOf(PropTypes.object),
	bookingUnits: PropTypes.arrayOf(PropTypes.object),
	enumDays: PropTypes.arrayOf(PropTypes.object),
	calendars: PropTypes.arrayOf(PropTypes.object),
	ratePlans: PropTypes.arrayOf(PropTypes.object),
	customReservationStatuses: PropTypes.arrayOf(PropTypes.object),
	enumCalculations: PropTypes.arrayOf(PropTypes.object),
	invoicingFrequencies: PropTypes.arrayOf(PropTypes.object),
	paymentSchedules: PropTypes.arrayOf(PropTypes.object),
	acctClasses: PropTypes.arrayOf(PropTypes.object),
};
ProductBookingNightlyForm.defaultProps = {
	data: {
		id: 0,
	},
	setIsValid: () => {},
	submit: () => {},
	isSubmitted: false,
	isLoading: false,
	isListsLoading: false,
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	onFormChange: () => {},
	bookingTypeBookings: [],
	productCategoryBookings: [],
	taxCodes: [],
	policies: [],
	accounts: [],
	contracts: [],
	taxRates: [],
	bookingExtraCharges: [],
	enumBookingCalculations: [],
	itemTypes: [],
	enumBookingPeriods: [],
	bookingUnits: [],
	enumDays: [],
	calendars: [],
	ratePlans: [],
	customReservationStatuses: [],
	enumCalculations: [],
	invoicingFrequencies: [],
	paymentSchedules: [],
	acctClasses: [],
};

export default ProductBookingNightlyForm;
