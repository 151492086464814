import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactExport from 'react-export-excel';

import Portlet from '../layout/Portlet';
import TablePagination from '../element/TablePagination';
import Loading from './Loading';
import NullBadge from '../design/NullBadge';
import SVGIcon from '../element/SVGIcon';

const { ExcelFile } = ReactExport;

const { ExcelSheet, ExcelColumn } = ReactExport.ExcelFile;

const LinkColumn = ({ onClick, cellData }) => {
	return (
		<span
			className='sdms-link sdms-link--dark sdms-text-overflow'
			onClick={onClick}
			role='presentation'>
			{cellData}
		</span>
	);
};
LinkColumn.propTypes = {
	cellData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onClick: PropTypes.func.isRequired,
};

const setActions = (children, actions, availableActions, data, updateData) => {
	return React.Children.map(children, child => {
		if (typeof child !== 'object') return child;

		const modifiedProps = {
			data,
		};

		if (actions[child.key]) {
			// if child is action node but its not available for current item.
			if (!availableActions.includes(child.key)) return null;

			modifiedProps.onClick = () => {
				actions[child.key](data, updateData);
			};
		}
		if (child.props && child.props.children && typeof child.props.children === 'object') {
			modifiedProps.children = setActions(
				child.props.children,
				actions,
				availableActions,
				data,
				updateData
			);
		}

		return React.cloneElement(child, modifiedProps);
	});
};

const Col = ({
	label,
	cellData,
	head,
	children,
	// eslint-disable-next-line no-unused-vars
	id,
	className,
	align,
	actions,
	cellComponent,
	data,
	// eslint-disable-next-line no-unused-vars
	width,
	// eslint-disable-next-line no-unused-vars
	onlyHover,
	checkEditable,
	isLinkColumn,
	action,
	updateData,
	gridName,
	noPermission,
	sortData,
	sortDataOnChange,
	sortable,
	// eslint-disable-next-line no-unused-vars
	responsiveBase,
	// eslint-disable-next-line no-unused-vars
	exportValue,
}) => {
	const colClass = classNames(
		{ [`text-${align}`]: align },
		{ 'sdms-cursor--pointer': sortable },
		className
	);

	const availableActions = checkEditable ? checkEditable(data) : Object.keys(actions);

	if (head && !children) {
		return (
			<th
				className={colClass || null}
				onClick={() => {
					if (sortable) {
						sortDataOnChange({
							sortOrder:
								sortData.sortOrder === 'desc' || sortData.orderField !== sortable
									? 'asc'
									: 'desc',
							orderField: sortable,
						});
					}
				}}>
				{label}
				{sortable && (
					<SVGIcon
						name='Sort#1'
						size={16}
						fill={`var(--${sortData.orderField === sortable ? 'info' : 'gray'})`}
						className={classNames('sdms-ml-5', {
							'sdms-transform--rotate180':
								sortData.orderField === sortable && sortData.sortOrder === 'asc',
						})}
					/>
				)}
			</th>
		);
	}

	if (isLinkColumn && availableActions.includes(action) && !noPermission) {
		return (
			<td className={colClass || null} data-grid-name={gridName} data-label={label}>
				{cellComponent ? (
					React.cloneElement(cellComponent, {
						data,
						onClick: () => actions[action](data),
					})
				) : (
					<LinkColumn onClick={() => actions[action](data, label)} cellData={cellData} />
				)}
			</td>
		);
	}
	if (!head && !children) {
		return (
			<td className={colClass || null} data-grid-name={gridName} data-label={label}>
				{cellComponent ? React.cloneElement(cellComponent, { data }) : cellData}
			</td>
		);
	}

	return (
		<td className={colClass || null} data-grid-name={gridName} data-label={label}>
			{setActions(children, actions, availableActions, data, updateData)}
		</td>
	);
};
Col.propTypes = {
	label: PropTypes.string,
	cellData: PropTypes.node,
	head: PropTypes.bool,
	children: PropTypes.node,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	className: PropTypes.string,
	align: PropTypes.oneOf(['center', 'right']),
	actions: PropTypes.objectOf(PropTypes.func),
	// eslint-disable-next-line react/require-default-props
	cellComponent: PropTypes.node,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onlyHover: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	checkEditable: PropTypes.func,
	isLinkColumn: PropTypes.bool,
	action: PropTypes.string,
	updateData: PropTypes.func,
	gridName: PropTypes.string,
	noPermission: PropTypes.bool,
	sortable: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	sortData: PropTypes.object,
	sortDataOnChange: PropTypes.func,
	responsiveBase: PropTypes.bool,
	exportValue: PropTypes.func,
};
Col.defaultProps = {
	label: null,
	cellData: null,
	head: false,
	children: null,
	id: 0,
	className: null,
	align: null,
	actions: {},
	data: {},
	width: null,
	onlyHover: false,
	isLinkColumn: false,
	action: 'edit',
	updateData: () => {},
	gridName: null,
	noPermission: null,
	sortable: null,
	sortData: { orderField: '', sortOrder: '' },
	sortDataOnChange: () => {},
	responsiveBase: false,
	exportValue: null,
};

const CheckBoxContainer = ({ children, head }) => {
	if (head) {
		return (
			<th className='sdms-list-layout--select' data-grid-name='col-checkbox'>
				{children}
			</th>
		);
	}
	return (
		<td className='sdms-list-layout--select' data-grid-name='col-checkbox'>
			{children}
		</td>
	);
};
CheckBoxContainer.propTypes = {
	head: PropTypes.bool,
	children: PropTypes.node.isRequired,
};
CheckBoxContainer.defaultProps = {
	head: false,
};

const CheckBox = ({ id, head, onClick, checked, listName, disabled }) => {
	return (
		<CheckBoxContainer head={head}>
			<label
				htmlFor={`list_item_${listName}_${id}`}
				className={classNames('sdms-checkbox sdms-checkbox--single sdms-checkbox--solid', {
					'sdms-checkbox--disabled': disabled,
				})}>
				<input
					data-id={id}
					id={`list_item_${listName}_${id}`}
					type='checkbox'
					onClick={onClick}
					checked={checked}
					readOnly
				/>
				<span />
			</label>
		</CheckBoxContainer>
	);
};
CheckBox.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	head: PropTypes.bool,
	checked: PropTypes.bool,
	onClick: PropTypes.func,
	listName: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};
CheckBox.defaultProps = {
	id: 'all',
	head: false,
	onClick: undefined,
	checked: undefined,
	disabled: false,
};

export const ListHead = ({ className, responsive, children }) => {
	return (
		<Portlet.Head
			className={classNames(
				'sdms-list-layout__head',
				{
					[`table--responsive--${responsive}`]: responsive,
				},
				className
			)}>
			{children}
		</Portlet.Head>
	);
};
ListHead.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	responsive: PropTypes.oneOf([null, 'scroll', 'grid']),
};
ListHead.defaultProps = {
	className: null,
	responsive: null,
};

export const ListBody = React.forwardRef(({ className, responsive, children }, ref) => {
	return (
		<Portlet.Body
			ref={ref}
			className={classNames(
				{
					[`table--responsive--${responsive}`]: responsive,
				},
				className
			)}>
			{children}
		</Portlet.Body>
	);
});
ListBody.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	responsive: PropTypes.oneOf([null, 'scroll', 'grid']),
};
ListBody.defaultProps = {
	className: null,
	responsive: null,
};

export const ListTable = ({ className, childrenLength, minWidth, width, children, isLoading }) => {
	return (
		<table
			className={classNames(
				'table',
				{ [`table--responsive-col--${childrenLength}`]: !minWidth },
				{
					'is-loading': isLoading,
				},
				className
			)}
			style={{ minWidth, width }}>
			{children}
		</table>
	);
};
ListTable.propTypes = {
	className: PropTypes.string,
	childrenLength: PropTypes.number,
	minWidth: PropTypes.number,
	width: PropTypes.number,
	children: PropTypes.node.isRequired,
	isLoading: PropTypes.bool,
};
ListTable.defaultProps = {
	className: null,
	childrenLength: null,
	minWidth: null,
	width: null,
	isLoading: false,
};

const List = ({
	children,
	className,
	data,
	withCheckBox,
	handleAllChecked,
	handleChecked,
	selectedItems,
	isAllChecked,
	currentPage,
	totalPage,
	setCurrentPage,
	actions,
	name,
	isLoading,
	isLoadingOverride,
	isPageLoading,
	itemsPerPage,
	setItemsPerPage,
	totalItems,
	hasPagination,
	isFit,
	isElevate,
	checkEditable,
	fluid,
	withOutPortlet,
	withImage,
	withActions,
	withCheckbox,
	updateData,
	sortData,
	sortDataOnChange,
	responsive,
	listName,
	noMarginBottom,
	exportName,
}) => {
	const getCellDataValue = (dataItem, item) => {
		if (item.props.cellDataObject)
			return dataItem[item.props.cellDataObject] ? (
				dataItem[item.props.cellDataObject][item.props.cellData]
			) : (
				<NullBadge />
			);

		return dataItem[item.props.cellData];
	};

	const getExportColumns = () =>
		(children || [])
			.filter(col => col?.props?.exportValue)
			.map(col => {
				const { exportValue, label } = col.props;

				return <ExcelColumn key={label} label={label} value={exportValue} />;
			});

	const _children = children.filter(c => !!c);

	const ColGroup = () => {
		return (
			<colgroup>
				{withCheckBox && <col />}
				{_children.map((child, index) => {
					return (
						<col
							key={`${name}-colgroup-${child.props.label || index}`}
							style={{ width: child.props.width }}
						/>
					);
				})}
			</colgroup>
		);
	};

	const _Thead = (
		<>
			<ColGroup />
			<thead>
				<tr>
					{withCheckBox && (
						<CheckBox
							head
							onClick={handleAllChecked}
							checked={isAllChecked}
							listName={listName || name}
							disabled={isLoadingOverride}
						/>
					)}
					{_children.map((child, index) => (
						<Col
							head
							key={`${listName || name}-portletHeadCol-${child.props.label || index}`}
							label={child.props.label}
							align={child.props.align}
							sortData={sortData}
							sortDataOnChange={sortDataOnChange}
							sortable={child.props.sortable}
							cellData={child.props.cellData}
						/>
					))}
				</tr>
			</thead>
		</>
	);

	const _List = (
		<>
			<ListHead responsive={responsive}>
				<Portlet.HeadToolbar>
					<table
						className={classNames(
							'table',
							`table--responsive-col--${children.length}`
						)}>
						{_Thead}
					</table>
				</Portlet.HeadToolbar>
			</ListHead>
			<ListBody responsive={responsive}>
				<ListTable childrenLength={_children.length} isLoading={isLoading}>
					{_Thead}
					<Loading
						isLoading={isLoading}
						type='list'
						colCount={_children.length}
						withImage={withImage}
						withActions={withActions}
						withCheckbox={withCheckbox}>
						{(data.length === 0 && <caption>No results to show.</caption>) || (
							<tbody>
								{data.map((dataItem, i) => (
									<tr
										key={`${listName || name}-listItem-${dataItem?.id || i}`}
										className={
											classNames({
												selected: selectedItems.includes(dataItem.id),
											}) || null
										}>
										{withCheckBox && (
											<CheckBox
												id={dataItem.id}
												onClick={handleChecked}
												checked={selectedItems.includes(dataItem.id)}
												listName={listName || name}
												disabled={isLoadingOverride}
											/>
										)}
										{_children.map((child, index) => (
											<Col
												key={`${listName || name}-${child.props.label}-${
													dataItem.id
												}`}
												cellData={getCellDataValue(dataItem, child)}
												id={dataItem.id}
												className={classNames(
													{
														'sdms-list-layout--only-hover':
															child.props.onlyHover,
													},
													child.props.className
												)}
												align={child.props.align}
												actions={actions}
												data={dataItem}
												cellComponent={child.props.cellComponent}
												checkEditable={checkEditable}
												isLinkColumn={child.props.isLinkColumn}
												action={child.props.action}
												updateData={updateData}
												gridName={
													child.props.onlyHover
														? 'col-action'
														: (child.props.responsiveBase &&
																'col-base') ||
														  (child.props.label === 'Name' &&
																'col-base') ||
														  child.props.gridName ||
														  `col-${index}`
												}
												label={child.props.label}
												noPermission={child.props.noPermission}
												exportValue={child.props.exportValue}>
												{child.props.children}
											</Col>
										))}
									</tr>
								))}
							</tbody>
						)}
					</Loading>
				</ListTable>
			</ListBody>
			{hasPagination && (
				<Portlet.Foot tall='sm'>
					{hasPagination && (
						<Loading isLoading={isPageLoading} type='pagination'>
							<TablePagination
								listName={name}
								currentPage={currentPage}
								totalPage={totalPage}
								setCurrentPage={setCurrentPage}
								itemsPerPage={itemsPerPage}
								setItemsPerPage={setItemsPerPage}
								totalItems={totalItems}
							/>
						</Loading>
					)}
				</Portlet.Foot>
			)}
			<ExcelFile
				element={<button type='submit' id='sdms-list-export-button' className='d-none' />}
				filename={exportName}>
				<ExcelSheet
					data={data.filter(item => selectedItems.includes(item.id))}
					name={exportName}>
					{getExportColumns()}
				</ExcelSheet>
			</ExcelFile>
		</>
	);

	if (withOutPortlet) {
		return _List;
	}
	return (
		<Portlet
			className={classNames(
				'sdms-list-layout',
				{ 'sdms-portlet--fit': isFit },
				{ 'sdms-portlet--unelevate': !isElevate },
				{ 'sdms-portlet--no-margin-bottom': noMarginBottom },
				className
			)}
			fluid={fluid}
			hasFrame>
			{_List}
		</Portlet>
	);
};
List.propTypes = {
	children: PropTypes.node,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.array,
	withCheckBox: PropTypes.bool,
	handleAllChecked: PropTypes.func,
	handleChecked: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	selectedItems: PropTypes.array,
	isAllChecked: PropTypes.bool,
	currentPage: PropTypes.number,
	totalPage: PropTypes.number,
	setCurrentPage: PropTypes.func,
	className: PropTypes.string,
	actions: PropTypes.objectOf(PropTypes.func),
	name: PropTypes.string,
	isLoading: PropTypes.bool,
	isLoadingOverride: PropTypes.bool,
	isPageLoading: PropTypes.bool,
	itemsPerPage: PropTypes.number,
	setItemsPerPage: PropTypes.func,
	totalItems: PropTypes.number,
	hasPagination: PropTypes.bool,
	isFit: PropTypes.bool,
	isElevate: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	checkEditable: PropTypes.func,
	fluid: PropTypes.oneOf(['fluid', 'fluid-half']),
	withOutPortlet: PropTypes.bool,
	withImage: PropTypes.bool,
	withActions: PropTypes.bool,
	withCheckbox: PropTypes.bool,
	updateData: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	sortData: PropTypes.object,
	sortDataOnChange: PropTypes.func,
	responsive: PropTypes.oneOf([null, 'scroll', 'grid']),
	listName: PropTypes.string,
	noMarginBottom: PropTypes.bool,
	exportName: PropTypes.string,
};
List.defaultProps = {
	children: [],
	data: [],
	handleChecked: () => {},
	handleAllChecked: () => {},
	withCheckBox: false,
	selectedItems: [],
	isAllChecked: false,
	currentPage: 1,
	totalPage: 1,
	setCurrentPage: () => {},
	className: '',
	actions: {},
	name: 'List',
	isLoading: false,
	isLoadingOverride: false,
	isPageLoading: false,
	itemsPerPage: parseInt(process.env.REACT_APP_ITEMS_PER_PAGE, 10),
	setItemsPerPage: () => {},
	totalItems: 0,
	hasPagination: true,
	isFit: false,
	isElevate: true,
	fluid: null,
	withOutPortlet: false,
	withImage: false,
	withActions: true,
	withCheckbox: true,
	updateData: () => {},
	sortData: { orderField: '', sortOrder: '' },
	sortDataOnChange: () => {},
	responsive: 'scroll',
	listName: '',
	noMarginBottom: false,
	exportName: 'sharper_export',
};

List.Col = Col;

export default List;
