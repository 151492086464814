import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../element/Button';
import DialogBox from '../../../element/DialogBox';

const Override = ({ reservation, open, onReload, onClose }) => {
	return (
		<DialogBox
			open={open}
			title=''
			content={`Reservation ${reservation?.reservationId ||
				'#'} has been changed since you opened it.`}
			type='question'
			onClose={onClose}>
			<Button label='success' text='Reload' onClick={onReload} />
		</DialogBox>
	);
};

Override.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	reservation: PropTypes.object,
	open: PropTypes.bool,
	onReload: PropTypes.func,
	onClose: PropTypes.func,
};

Override.defaultProps = {
	reservation: null,
	open: false,
	onReload: () => {},
	onClose: () => {},
};

export default Override;
