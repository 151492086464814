import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { required, numeric } from '../../../utils/helpers/validation';
import useField from '../../../utils/hooks/useField';
import { modules } from '../../../utils/helpers/apiCall';
import UserContext from '../../../app/contexts/UserContext';
import { enumDepositTypeConverter, numberParser } from '../../../utils/helpers/helper';
import HeaderContext from '../../../app/contexts/HeaderContext';
import usePages from '../../../utils/hooks/usePages';

import FormField from '../../reusables/template/FormField';
import FormGroup from '../../reusables/layout/FormGroup';
import Input, { InputGroup, InputPend } from '../../reusables/field/Input';
import Loading from '../../reusables/template/Loading';
import Selects from '../../reusables/field/Selects';
import Toggle from '../../reusables/field/Toggle';
import Portlet from '../../reusables/layout/Portlet';
import Button from '../../reusables/element/Button';
import AsyncSelect from '../../reusables/field/AsyncSelect';

const PolicyForm = ({
	data,
	setIsValid,
	isSubmitted,
	setTitle,
	isLoading,
	isListsLoading,
	onFormChange,
	enumBookingPeriods,
	enumBookingGuarantee,
	enumDepositTypes,
	submitButtonAttr,
	submit,
}) => {
	const pages = usePages();

	const userContext = useContext(UserContext);

	const headerContext = useContext(HeaderContext);

	const marinaModule = useRef(
		userContext.data.user.company.modules.find(m => m.value === modules.MARINA)
	);

	const [modifiedEnumDepositTypes, setModifiedEnumDepositTypes] = useState([]);

	const [name, nameOnChange, nameValRes, nameShowVal, setNameShowVal] = useField(
		data,
		'name',
		onFormChange,
		[required],
		'',
		null,
		setTitle
	);
	const [
		bookingPeriod,
		bookingPeriodOnChange,
		bookingPeriodValRes,
		bookingPeriodShowVal,
		setBookingPeriodShowVal,
	] = useField(data, 'bookingPeriod', onFormChange, [required], null);

	const [
		refundableDaysPrior,
		refundableDaysPriorOnChange,
		refundableDaysPriorValRes,
		refundableDaysPriorShowVal,
		setRefundableDaysPriorShowVal,
	] = useField(data, 'refundableDaysPrior', onFormChange, [numeric], '', numberParser(false));

	const [
		fullPaymentDaysPrior,
		fullPaymentDaysPriorOnChange,
		fullPaymentDaysPriorValRes,
		fullPaymentDaysPriorShowVal,
		setFullPaymentDaysPriorShowVal,
	] = useField(data, 'fullPaymentDaysPrior', onFormChange, [numeric], '', numberParser(false));

	const [includeFees, includeFeesOnChange] = useField(
		data,
		'includeFees',
		onFormChange,
		[],
		false
	);

	const [includePromoCode, includePromoCodeOnChange] = useField(
		data,
		'includePromoCode',
		onFormChange,
		[],
		false
	);

	const [cancelOnline, cancelOnlineOnChange] = useField(
		data,
		'cancelOnline',
		onFormChange,
		[],
		false
	);

	const [
		depositType,
		depositTypeOnChange,
		depositTypeValRes,
		depositTypeShowVal,
		setDepositTypeShowVal,
	] = useField(data, 'depositType', onFormChange, [required], null);

	const [
		depositAmount,
		depositAmountOnChange,
		depositAmountValRes,
		depositAmountShowVal,
		setDepositAmountShowVal,
	] = useField(data, 'depositAmount', onFormChange, [required, numeric], '', numberParser());

	const [depositProduct, depositProductOnChange] = useField(
		data,
		'depositProduct',
		onFormChange,
		[],
		null
	);

	const [isSecurityDeposit, isSecurityDepositOnChange] = useField(
		data,
		'isSecurityDeposit',
		onFormChange,
		[],
		false
	);

	const [
		bookingGuarantee,
		bookingGuaranteeOnChange,
		bookingGuaranteeValRes,
		bookingGuaranteeShowVal,
		setBookingGuaranteeShowVal,
	] = useField(data, 'bookingGuarantee', onFormChange, [required], null);

	const [includeTax, includeTaxOnChange] = useField(
		data,
		'includeTax',
		onFormChange,
		[required],
		false
	);

	useEffect(() => {
		if (isSubmitted) {
			setNameShowVal();
			setBookingGuaranteeShowVal();
			setBookingPeriodShowVal();
			setRefundableDaysPriorShowVal();
			setFullPaymentDaysPriorShowVal();
			setDepositAmountShowVal();
			setDepositTypeShowVal();
		}
	}, [
		isSubmitted,
		setNameShowVal,
		setBookingGuaranteeShowVal,
		setBookingPeriodShowVal,
		setRefundableDaysPriorShowVal,
		setFullPaymentDaysPriorShowVal,
		setDepositAmountShowVal,
		setDepositTypeShowVal,
	]);

	useEffect(() => {
		setIsValid(
			nameValRes.isValid &&
				bookingGuaranteeValRes.isValid &&
				bookingPeriodValRes.isValid &&
				refundableDaysPriorValRes.isValid &&
				depositAmountValRes.isValid &&
				depositTypeValRes.isValid
		);
	}, [
		nameValRes.isValid,
		depositAmountValRes.isValid,
		bookingPeriodValRes.isValid,
		refundableDaysPriorValRes.isValid,
		bookingGuaranteeValRes.isValid,
		depositTypeValRes.isValid,
		setIsValid,
	]);

	useEffect(() => {
		headerContext.setBreadcrumbs([
			{
				title: pages.marina.default.text,
				path: pages.marina.dashboard.path,
			},
			{
				title: pages.marina.settings.text,
				path: pages.marina.settings.path,
			},
			{
				title: pages.marina.settings.policies.text,
				path: pages.marina.settings.policies.path,
			},
			{ title: name || `New ${pages.marina.settings.policies.text}`, isActive: true },
		]);

		headerContext.setPageTitle(name || `New ${pages.marina.settings.policies.text}`);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, [name]);

	useEffect(() => {
		if (!isLoading) data.module = marinaModule.current;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);

	useEffect(() => {
		if (!isListsLoading) {
			setModifiedEnumDepositTypes(
				enumDepositTypes.map(edt => enumDepositTypeConverter(edt, bookingPeriod))
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isListsLoading]);

	return (
		<Portlet className='sdms-form' fluid='fluid'>
			<Portlet.Body>
				<form className='sdms-form'>
					<FormGroup>
						<Loading isLoading={isLoading}>
							<FormField
								name='name'
								label='Name'
								description='This is the name description'
								id={data.id}
								valRes={nameValRes}
								showValidation={nameShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='Name (Required)'
									value={name}
									onChange={nameOnChange}
									onBlur={setNameShowVal}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='bookingPeriod'
								label='Booking Period'
								description='This is the booking period description'
								id={data.id}
								valRes={bookingPeriodValRes}
								showValidation={bookingPeriodShowVal}
								colMd={6}>
								<Selects
									options={enumBookingPeriods}
									placeholder='Booking Periods (Required)'
									value={bookingPeriod}
									onChange={e => {
										bookingPeriodOnChange(e);

										setModifiedEnumDepositTypes(
											enumDepositTypes.map(edt =>
												enumDepositTypeConverter(edt, e.target.value)
											)
										);
									}}
									onBlur={setBookingPeriodShowVal}
									displayKey='value'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='bookingGuarantee'
								label='Booking Guarantee'
								description='This is the booking guarantee description'
								id={data.id}
								valRes={bookingGuaranteeValRes}
								showValidation={bookingGuaranteeShowVal}
								colMd={6}>
								<Selects
									options={enumBookingGuarantee}
									placeholder='Booking Guarantee (Required)'
									value={bookingGuarantee}
									onChange={bookingGuaranteeOnChange}
									onBlur={setBookingGuaranteeShowVal}
									displayKey='value'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='deposit'
								label='Deposit'
								description='This is the deposit description'
								valRes={
									depositAmountValRes.isValid
										? depositTypeValRes
										: depositAmountValRes
								}
								showValidation={depositAmountShowVal || depositTypeShowVal}
								id={data.id}
								colMd={6}>
								<InputGroup>
									<InputPend
										text={
											depositType && depositType.value === 'per_block'
												? 'First'
												: ''
										}
									/>
									<Input
										type='text'
										placeholder=''
										value={depositAmount}
										onChange={depositAmountOnChange}
										onBlur={setDepositAmountShowVal}
									/>
									<div className='input-group-append'>
										<Selects
											options={modifiedEnumDepositTypes}
											name='depositType'
											placeholder='Deposit Type (Required)'
											value={enumDepositTypeConverter(
												depositType,
												bookingPeriod
											)}
											onChange={depositTypeOnChange}
											displayKey='label'
											onBlur={setDepositTypeShowVal}
										/>
									</div>
								</InputGroup>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='depositProduct'
								label='Deposit Product'
								id={data.id}
								colMd={6}>
								<AsyncSelect
									placeholder='Search and select product'
									value={depositProduct}
									onChange={depositProductOnChange}
									route='products'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='refundableDaysPrior'
								label='Refundable'
								description='This is the refundable description'
								id={data.id}
								valRes={refundableDaysPriorValRes}
								showValidation={refundableDaysPriorShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='Days'
									value={refundableDaysPrior}
									onChange={refundableDaysPriorOnChange}
									onBlur={setRefundableDaysPriorShowVal}
									append='Days before arrival'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='fullPaymentDaysPrior'
								label='Full Payment'
								description='This is the full payment description'
								id={data.id}
								valRes={fullPaymentDaysPriorValRes}
								showValidation={fullPaymentDaysPriorShowVal}
								colMd={6}>
								<Input
									type='text'
									placeholder='Days'
									value={fullPaymentDaysPrior}
									onChange={fullPaymentDaysPriorOnChange}
									onBlur={setFullPaymentDaysPriorShowVal}
									append='Days before arrival'
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='includeFees'
								label='Include All Fees'
								description='Include all price adjustments in deposit'
								id={data.id}
								colMd={6}>
								<Toggle onChange={includeFeesOnChange} value={includeFees} />
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='includePromoCode'
								label='Include Promo Code'
								description='Include promo code in deposit calculation'
								id={data.id}
								colMd={6}>
								<Toggle
									onChange={includePromoCodeOnChange}
									value={includePromoCode}
								/>
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='canCancelOnline'
								label='Cancel online'
								description='This is the can customer cancel online toggle description'
								id={data.id}
								colMd={6}>
								<Toggle onChange={cancelOnlineOnChange} value={cancelOnline} />
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='includeTax'
								label='Tax inclusion'
								description='This is the tax inclusion toggle description'
								id={data.id}
								colMd={6}>
								<Toggle onChange={includeTaxOnChange} value={includeTax} />
							</FormField>
						</Loading>
						<Loading isLoading={isLoading}>
							<FormField
								name='isSecurityDeposit'
								label='Security Deposit'
								id={data.id}
								colMd={6}>
								<Toggle
									onChange={isSecurityDepositOnChange}
									value={isSecurityDeposit}
								/>
							</FormField>
						</Loading>
					</FormGroup>
				</form>
			</Portlet.Body>
			<Portlet.Foot type='form' tall='sm'>
				<Button
					label={submitButtonAttr.color}
					text={submitButtonAttr.text}
					icon={submitButtonAttr.icon}
					size='sm'
					className={classNames(' sdms-mw-100', {
						'sdms-fading-dots':
							submitButtonAttr.text ===
							process.env.REACT_APP_SUBMIT_BUTTON_SAVING_TEXT,
					})}
					onClick={submit}
				/>
			</Portlet.Foot>
		</Portlet>
	);
};
PolicyForm.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.number,
		name: PropTypes.string,
		module: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
		bookingPeriod: PropTypes.object,
		cancellationWindow: PropTypes.number,
		includeFees: PropTypes.bool,
		includePromoCode: PropTypes.bool,
		cancelOnline: PropTypes.bool,
		includeTax: PropTypes.bool,
		deposit: PropTypes.number,
		refundable: PropTypes.bool,
	}),
	setIsValid: PropTypes.func,
	isSubmitted: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	setTitle: PropTypes.func,
	isLoading: PropTypes.bool,
	isListsLoading: PropTypes.bool,
	onFormChange: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	enumBookingPeriods: PropTypes.arrayOf(PropTypes.object),
	enumBookingGuarantee: PropTypes.arrayOf(PropTypes.object),
	// eslint-disable-next-line react/no-unused-prop-types
	enumDepositTypes: PropTypes.arrayOf(PropTypes.object),
	submitButtonAttr: PropTypes.shape({
		text: PropTypes.string,
		icon: PropTypes.string,
		color: PropTypes.string,
	}),
	submit: PropTypes.func,
};
PolicyForm.defaultProps = {
	data: {
		id: 0,
		name: '',
		bookingPeriod: null,
		cancellationWindow: 0,
		includeFees: false,
		includePromoCode: false,
		cancelOnline: false,
		includeTax: false,
		deposit: 0,
		refundable: false,
	},
	setIsValid: () => {},
	isSubmitted: false,
	isLoading: false,
	isListsLoading: false,
	onFormChange: () => {},
	enumBookingPeriods: [],
	enumDepositTypes: [],
	enumBookingGuarantee: [],
	submitButtonAttr: {
		text: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_TEXT,
		icon: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_ICON,
		color: process.env.REACT_APP_SUBMIT_BUTTON_SAVE_COLOR,
	},
	submit: () => {},
};

export default PolicyForm;
