import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import apiCall, { filters as apiCallFilters } from '../../../utils/helpers/apiCall';

const AsyncSelect = ({
	isMulti,
	value,
	displayKey,
	valueKey,
	onChange,
	onBlur,
	id,
	name,
	isValid,
	showValidation,
	route,
	field,
	placeholder,
	renderInput,
	renderOption,
	optionalShow,
	disabled,
	presetFilters,
	minSearchChar,
	notFoundText,
	onFocus,
	customFilters,
	disableClearable,
}) => {
	const getDefaultOptions = () => {
		if (value && ((Array.isArray(value) && value.length) || Object.keys(value).length))
			return Array.isArray(value) ? value : [value];

		return [];
	};

	const [options, setOptions] = useState(getDefaultOptions());
	const [open, setOpen] = React.useState(false);
	const loading = useRef(false);
	const [noOptionText, setNoOptionText] = useState('Type to search!');

	const abortController = useRef();

	const timeOutId = useRef();

	const loadOptions = inputValue => {
		clearTimeout(timeOutId.current);

		timeOutId.current = setTimeout(() => {
			if (abortController.current) abortController.current.abort();

			const filters = {
				...customFilters,
				pagination: false,
			};

			if (apiCallFilters[route]) {
				if ((apiCallFilters[route] || {}).searchField && !field) {
					if (Array.isArray(apiCallFilters[route].searchField)) {
						filters[`or[${apiCallFilters[route].searchField.join('&')}]`] = inputValue;
					} else filters[apiCallFilters[route].searchField] = inputValue;
				} else filters[field || 'name'] = inputValue; // default search filter is name.

				if (apiCallFilters[route].groups && apiCallFilters[route].groups.search)
					filters['groups[]'] = apiCallFilters[route].groups.search;

				if (apiCallFilters[route].pagination)
					filters.pagination = apiCallFilters[route].pagination;

				if (apiCallFilters[route].itemsPerPage)
					filters.itemsPerPage = apiCallFilters[route].itemsPerPage;
			} else filters[field || 'name'] = inputValue;

			abortController.current = new AbortController();

			apiCall(
				'GET',
				route,
				res => {
					loading.current = false;
					const _options = filters.pagination ? res['hydra:member'] : res;
					setOptions(_options);
					if (_options.length === 0) setNoOptionText(notFoundText || 'No Options!');
				},
				() => {
					loading.current = false;
					setOptions([]);
				},
				'',
				null,
				{ ...presetFilters, ...filters },
				abortController.current.signal
			);
		}, 700);
	};

	useEffect(() => {
		if (!value || (isMulti && value.length === 0) || Object.keys(value).length === 0) {
			if (abortController.current) abortController.current.abort();
			setOptions([]);
		}

		return () => {
			if (timeOutId.current) clearTimeout(timeOutId.current);
			if (abortController.current) abortController.current.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const changeHandler = (_event, _newValue) => {
		onChange({ target: { name, value: _newValue } });
		onBlur();
	};

	const defaultRenderInput = params => (
		<TextField
			{...params}
			placeholder={placeholder}
			variant='outlined'
			InputProps={{
				...params.InputProps,
				endAdornment: (
					<>
						{loading.current ? <CircularProgress color='inherit' size={20} /> : null}
						{params.InputProps.endAdornment}
					</>
				),
			}}
		/>
	);

	return (
		<Autocomplete
			id={id}
			multiple={isMulti}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				onBlur();
				setOpen(false);
				setNoOptionText('Type to search!');
			}}
			value={value}
			getOptionSelected={(option, _value) => option[valueKey] === _value[valueKey]}
			getOptionLabel={option => {
				if (optionalShow) return `${optionalShow(option)}`;
				return option[displayKey] || '';
			}}
			options={(options.length ? options : getDefaultOptions()).sort((a, b) =>
				a[displayKey].toString().localeCompare(b[displayKey].toString())
			)}
			loading={loading.current}
			noOptionsText={noOptionText}
			onChange={changeHandler}
			disabled={disabled}
			blurOnSelect
			className={classNames('sdms-select-container', {
				'is-invalid': showValidation && !isValid,
			})}
			filterOptions={_options => _options}
			onInputChange={(event, _value, reason) => {
				if (reason === 'input') {
					if (_value) {
						if (minSearchChar && _value.length < minSearchChar) {
							const missingCharCount = minSearchChar - _value.length;
							setNoOptionText(
								`Type ${missingCharCount} more character${
									missingCharCount > 1 ? 's' : ''
								} to search!`
							);
							setOptions([]);
							loading.current = false;
						} else {
							setNoOptionText('');
							loading.current = true;
							loadOptions(_value);
						}
					} else {
						setNoOptionText('Type to search!');
						setOptions([]);
						loading.current = false;
					}
				} else if (reason === 'clear') {
					onChange({
						target: {
							name,
							value: null,
							action: () => {},
						},
					});
				}
			}}
			renderInput={renderInput || defaultRenderInput}
			renderOption={renderOption}
			onFocus={onFocus}
			disableClearable={disableClearable}
		/>
	);
};

AsyncSelect.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.node]),
	isMulti: PropTypes.bool,
	displayKey: PropTypes.string,
	valueKey: PropTypes.string,
	showValidation: PropTypes.bool,
	isValid: PropTypes.bool,
	route: PropTypes.string.isRequired,
	field: PropTypes.string,
	placeholder: PropTypes.string,
	renderInput: PropTypes.func,
	renderOption: PropTypes.func,
	optionalShow: PropTypes.func,
	disabled: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	presetFilters: PropTypes.object,
	minSearchChar: PropTypes.number,
	notFoundText: PropTypes.string,
	onFocus: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	customFilters: PropTypes.object,
	disableClearable: PropTypes.bool,
};

AsyncSelect.defaultProps = {
	id: undefined,
	name: undefined,
	value: {},
	onBlur: () => {},
	isMulti: false,
	showValidation: false,
	isValid: true,
	valueKey: 'id',
	displayKey: 'name',
	field: null,
	placeholder: 'Search...',
	renderInput: null,
	renderOption: null,
	optionalShow: null,
	disabled: false,
	presetFilters: {},
	minSearchChar: 0,
	notFoundText: '',
	onFocus: () => {},
	customFilters: {},
	disableClearable: false,
};

export default AsyncSelect;
