import React from 'react';
import * as classNames from 'classnames';
import PropTypes from 'prop-types';

const Checkbox = ({
	value,
	disabled,
	onChange,
	id,
	name,
	className,
	color,
	bold,
	content,
	single,
	style,
}) => {
	const checkboxClass = classNames(
		'sdms-checkbox ',
		{ 'sdms-checkbox--single': single },
		{ [`sdms-checkbox--${color}`]: color },
		{ 'sdms-checkbox--bold': bold },
		className
	);
	const changeHandler = e => {
		e.stopPropagation();
		onChange({ target: { name, value: !value, id } });
	};
	return (
		<label htmlFor={id} className={checkboxClass} style={style}>
			<input
				id={id}
				name={name}
				type='checkbox'
				disabled={disabled}
				className={classNames({ checked: value })}
				checked={value}
				readOnly
				onClick={changeHandler}
			/>

			<span />
			{content}
		</label>
	);
};

Checkbox.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.bool.isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	color: PropTypes.oneOf([
		'brand',
		'danger',
		'dark',
		'info',
		'light',
		'primary',
		'secondary',
		'success',
		'warning',
	]),
	onChange: PropTypes.func.isRequired,
	bold: PropTypes.bool,
	// eslint-disable-next-line react/require-default-props
	content: PropTypes.node,
	single: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	style: PropTypes.object,
};

Checkbox.defaultProps = {
	id: undefined,
	name: undefined,
	disabled: false,
	color: null,
	className: null,
	bold: false,
	single: true,
	style: null,
};

export default Checkbox;
