import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import classNames from 'classnames';

import ListContainer from '../../reusables/template/ListContainer';
import { getOnlinePortalDefaultFilters } from './functions';
import List from '../../reusables/template/List';
import Dropdown from '../../reusables/element/Dropdown';
import DialogBox from '../../reusables/element/DialogBox';
import Button from '../../reusables/element/Button';
import Portal from '../../reusables/layout/Portal';
import apiCall from '../../../utils/helpers/apiCall';
import { addErrorNotification, maskString, paymentTypeIcon } from '../../../utils/helpers/helper';
import useModal from '../../../utils/hooks/useModal';

import CustomerPaymentMethodForm from './CustomerPaymentMethodForm';
import { paymentTypes } from '../../../utils/constants/constants';
import SVGIcon from '../../reusables/element/SVGIcon';
import Badge from '../../reusables/element/Badge';

const modals = {
	ADD: 'add',
	DELETE: 'delete',
};

const PaymentMethod = ({ data }) => {
	if (!data.paymentMethod) return null;
	return (
		<>
			<SVGIcon
				name={paymentTypeIcon(data.paymentMethod.icon.value).name}
				className={classNames('sdms-mr-5', {
					'sdms-svg-icon--fill': paymentTypeIcon(data.paymentMethod.icon.value).fill,
				})}
				size={36}
			/>
			{data.paymentMethod.name}
		</>
	);
};

PaymentMethod.propTypes = {
	data: PropTypes.shape({
		paymentMethod: PropTypes.shape({
			name: PropTypes.string,
			icon: PropTypes.object,
		}),
	}),
};

PaymentMethod.defaultProps = {
	data: {
		paymentMethod: {
			name: '',
			icon: null,
		},
	},
};

const ExpirationCell = ({ data }) => {
	return (
		<>
			{data.expMonth}/{data.expYear}
		</>
	);
};

ExpirationCell.propTypes = {
	data: PropTypes.shape({
		expMonth: PropTypes.string,
		expYear: PropTypes.string,
	}),
};

ExpirationCell.defaultProps = {
	data: {
		expMonth: '01',
		expYear: '01',
	},
};

const CardNumberCell = ({ data }) => {
	if (data.cardNumber.includes('*') || data.cardNumber.includes('x')) return data.cardNumber;

	return maskString(data.cardNumber, 4);
};

CardNumberCell.propTypes = {
	data: PropTypes.shape({
		cardNumber: PropTypes.string,
	}),
};

CardNumberCell.defaultProps = {
	data: { cardNumber: '' },
};

const IsDefaultCell = ({ data }) => {
	if (data.isDefault)
		return (
			<Badge isUnified>
				<SVGIcon name='Check' fill='#ffffff' />
			</Badge>
		);

	return null;
};

IsDefaultCell.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
};

IsDefaultCell.defaultProps = {
	data: { id: 0 },
};

const DefaultButton = ({ data, onClick }) => {
	return (
		<Button
			label='brand'
			text={data.isDefault ? 'Unmake Default' : 'Make Default'}
			size='sm'
			onClick={onClick}
		/>
	);
};

DefaultButton.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object,
	onClick: PropTypes.func,
};

DefaultButton.defaultProps = {
	data: {
		id: 0,
	},
	onClick: () => {},
};

const PaymentMethodInfoCell = ({ data }) => {
	if (data.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD)
		return (
			<>
				<CardNumberCell data={data} /> - <ExpirationCell data={data} />
			</>
		);

	return `${data.bankName} - ${data.accountName} - ${data.accountHolderName}`;
};

const CustomerPaymentMethodList = ({
	data,
	isActive,
	outlet,
	updateCustomer,
	type,
	icon,
	title,
	formTitle,
	autoComplete,
}) => {
	const [modal, openModal, closeModal] = useModal();

	const staticFilters = useMemo(() => {
		return {
			...getOnlinePortalDefaultFilters(data.customer, outlet),
			type,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const onDelete = () => {
		if (!modal.customerPaymentMethod) return;

		apiCall(
			'POST',
			'onlinePortalDeleteCustomerPaymentMethod',
			res => {
				updateCustomer(res);
				closeModal();
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			{ id: modal.customerPaymentMethod.id },
			getOnlinePortalDefaultFilters(data.customer, outlet)
		);
	};

	const onDefaultSelect = defaultPaymentMethod => {
		apiCall(
			'POST',
			'onlinePortalChangeCustomerDefaultPaymentMethod',
			() => {
				updateCustomer({
					defaultPaymentMethod: defaultPaymentMethod.isDefault
						? defaultPaymentMethod
						: null,
				});
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			defaultPaymentMethod,
			getOnlinePortalDefaultFilters(data.customer, outlet)
		);
	};

	const COLUMNS = useMemo(() => {
		const columns = [
			<List.Col label='Payment Method' cellData='name' cellComponent={<PaymentMethod />} />,
		];

		if (type === 'all')
			columns.push(<List.Col label='Details' cellComponent={<PaymentMethodInfoCell />} />);

		if (type === paymentTypes.CREDIT_CARD)
			columns.push(
				<List.Col
					label='Number'
					cellComponent={<CardNumberCell />}
					className='sdms-font-monospace'
				/>,
				<List.Col label='Expiration' cellComponent={<ExpirationCell />} />
			);

		if (type === paymentTypes.CHECK)
			columns.push(
				<List.Col label='Bank Name' cellData='bankName' align='center' />,
				<List.Col label='Account Name' cellData='accountName' align='center' />,
				<List.Col label='Account Holder Name' cellData='accountHolderName' align='center' />
			);

		columns.push(
			<List.Col
				label='Default'
				width={50}
				cellComponent={<IsDefaultCell />}
				align='center'
			/>,
			<List.Col align='right' onlyHover width={200}>
				<DefaultButton key='changeDefault' />
				<Dropdown
					icon='Other#1'
					color='clean'
					inline
					aligned='right'
					circle
					outline={false}>
					<Dropdown.Header>Other Actions</Dropdown.Header>
					<Dropdown.Item
						itemsColor='danger'
						icon='Error-circle'
						text='Delete'
						key='delete'>
						Delete
					</Dropdown.Item>
				</Dropdown>
			</List.Col>
		);

		return columns;
	}, [type]);

	if (!isActive) return null;

	return (
		<div className='sdms-online-portal-list-container'>
			<ListContainer
				route='onlinePortalGetCustomerPaymentMethods'
				title={title}
				icon={icon}
				staticFilters={staticFilters}
				customActions={{
					delete: customerPaymentMethod =>
						openModal({ open: modals.DELETE, customerPaymentMethod }),
					changeDefault: customerPaymentMethod => {
						onDefaultSelect({
							...customerPaymentMethod,
							isDefault: !customerPaymentMethod.isDefault,
						});
					},
				}}
				isTabList
				forms={[]}
				fluid='fluid'
				hasSearch={false}
				headerActions={[
					{
						text: 'Add New',
						icon: 'Plus',
						design: 'brand',
						onClick: () =>
							openModal({ open: modals.ADD, customerPaymentMethod: { id: 0 } }),
						outline: false,
					},
				]}>
				<List fluid='fluid' withCheckBox={false} withOutPortlet>
					{COLUMNS}
				</List>
			</ListContainer>
			<DialogBox
				open={modal.open === modals.DELETE}
				title='Delete'
				content='Are you sure about delete?'
				type='question'
				onClose={closeModal}>
				<Button
					className='sdms-font-transform-c'
					design='clean'
					icon='Error-circle'
					text={`No, Don't delete!`}
					onClick={closeModal}
				/>
				<Button
					className='sdms-font-transform-c'
					label='danger'
					icon='Trash'
					text='Yes, Delete!'
					onClick={onDelete}
				/>
			</DialogBox>
			<Portal>
				<Popup
					open={modal.open === modals.ADD}
					contentStyle={{
						padding: 0,
						background: 'unset',
						border: 'unset',
					}}
					closeOnDocumentClick={false}
					lockScroll
					modal
					onClose={closeModal}>
					<CustomerPaymentMethodForm
						type={type}
						data={modal.customerPaymentMethod}
						paymentMethods={data.paymentMethods}
						displayMakeDefault
						onAdd={_customer => {
							updateCustomer(_customer);
							closeModal();
						}}
						onCancel={closeModal}
						icon={icon}
						title={formTitle}
						customer={data.customer}
						outlet={outlet}
						autoComplete={autoComplete}
					/>
				</Popup>
			</Portal>
		</div>
	);
};

CustomerPaymentMethodList.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.object.isRequired,
	isActive: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	updateCustomer: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	formTitle: PropTypes.string.isRequired,
	autoComplete: PropTypes.bool.isRequired,
};

export default CustomerPaymentMethodList;
