import React, { useState, createContext, useEffect, useCallback, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import apiCall from '../../utils/helpers/apiCall';
import ThemeContext from './ThemeContext';
import {
	SELECTED_OUTLET_STORAGE_KEY,
	SELECTED_PRINTER_STORAGE_KEY,
	SELECTED_REGISTER_STORAGE_KEY,
	themes,
	USER_STORAGE_KEY,
} from '../../utils/constants/constants';

const UserContext = createContext(null);

export const UserContextProvider = ({ children }) => {
	const userCookie = useRef(JSON.parse(localStorage.getItem(USER_STORAGE_KEY)));

	const themeContext = useContext(ThemeContext);

	const [data, setData] = useState(null);

	const [token, setToken] = useState(userCookie.current ? userCookie.current.token : null);

	const setLocalStorage = loginData => {
		userCookie.current = loginData;
		localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(userCookie.current));
	};

	const login = loginData => {
		if (loginData && userCookie.current && loginData.id !== userCookie.current.id) {
			localStorage.removeItem(USER_STORAGE_KEY);
			userCookie.current = null;
			setData(null);
			setToken(null);
		}
		setLocalStorage(
			userCookie.current
				? {
						...userCookie.current,
						access: userCookie.current.access.concat(loginData.access),
				  }
				: { ...loginData, lists: {} }
		);
		setToken(loginData.token);
	};

	const logout = (access = null) => {
		userCookie.current.access = userCookie.current.access.filter(a => a !== access);
		if (access === null || userCookie.current.access.length === 0) {
			localStorage.removeItem(USER_STORAGE_KEY);
			localStorage.removeItem(SELECTED_PRINTER_STORAGE_KEY);
			userCookie.current = null;
			setData(null);
			setToken(null);
		} else {
			setLocalStorage(userCookie.current);
			setData({ ...data, access: userCookie.current.access });
			if (access === 'admin') localStorage.removeItem(SELECTED_PRINTER_STORAGE_KEY);
		}
	};

	const hasPermission = (permission, installerRequired = false, disabled = false) => {
		if (disabled) return false;

		if (installerRequired) return data.user.isInstallerUser;

		if (!permission) return true;

		return (
			data.user.isOwner ||
			data.user.isInstallerUser ||
			data.user.userRoleOutlets.filter(
				uro =>
					uro.outlet.id === data.selectedOutlet.id &&
					uro.role.permissions.filter(p => p.value === permission).length > 0
			).length > 0
		);
	};

	const updatePermissions = (changes, changeType) => {
		if (changeType === 'permissions' && data.user.userRoleOutlets.length)
			data.user.userRoleOutlets[
				data.user.userRoleOutlets.findIndex(
					ro => ro.outlet.id === data.selectedOutlet.id && ro.role.id === changes.id
				)
			].role.permissions = changes.permissions;

		if (changeType === 'roles') data.user.userRoleOutlets = changes;

		setData({ ...data });
	};

	const updateOutlet = (outletData, isSettings = false) => {
		const _userContextData = { ...data };

		const outletIndex = _userContextData.outlets.findIndex(o => o.id === outletData.id);

		if (isSettings) {
			if (outletIndex > -1) _userContextData.outlets[outletIndex].settings = outletData;

			if (_userContextData.selectedOutlet.id === outletData.id)
				_userContextData.selectedOutlet.settings = outletData;

			if ((_userContextData.user.defaultOutlet || {}).id === outletData.id)
				_userContextData.user.defaultOutlet.settings = outletData;
		} else {
			if (outletIndex > -1) _userContextData.outlets[outletIndex] = outletData;

			if (_userContextData.selectedOutlet.id === outletData.id)
				_userContextData.selectedOutlet = outletData;

			if ((_userContextData.user.defaultOutlet || {}).id === outletData.id)
				_userContextData.user.defaultOutlet = outletData;
		}

		setData(_userContextData);
	};

	const updateRegister = registers => {
		if (!data.selectedOutlet) return;

		setData({
			...data,
			selectedOutlet: {
				...data.selectedOutlet,
				registers: data.selectedOutlet.registers.map(register => {
					const registerIndex = registers.findIndex(r => r.id === register.id);

					if (registerIndex > -1) return { ...register, ...registers[registerIndex] };
					return register;
				}),
			},
		});
	};

	const clearSelectedRegister = () => {
		setData({
			...data,
			selectedRegister: null,
		});

		localStorage.removeItem(SELECTED_REGISTER_STORAGE_KEY);
	};

	const getDefaultOutlet = (user, outlets = null) => {
		const localStorageOutletId = localStorage.getItem(SELECTED_OUTLET_STORAGE_KEY);

		if (localStorageOutletId) {
			let outlet;

			if (user.userRoleOutlets.length) {
				const userRoleOutlet = user.userRoleOutlets.find(
					o => o.outlet.id === parseInt(localStorageOutletId, 10)
				);

				if (userRoleOutlet) return userRoleOutlet.outlet;
			}
			if (outlets) outlet = outlets.find(o => o.id === parseInt(localStorageOutletId, 10));

			if (outlet) return outlet;
		}

		if (user.defaultOutlet) return user.defaultOutlet;

		return user.userRoleOutlets.length ? user.userRoleOutlets[0].outlet : {};
	};

	const getDefaultRegister = registers => {
		if (registers.length === 0) return null;

		const localStorageRegisterId = localStorage.getItem(SELECTED_REGISTER_STORAGE_KEY);

		if (localStorageRegisterId) {
			const register = registers.find(r => r.id === parseInt(localStorageRegisterId, 10));

			if (register) return register;
		}

		return null;
	};

	const getDefaultAccountReceivableAccount = () =>
		data?.selectedOutlet?.settings?.defaultAccountReceivableAccount ||
		data?.user?.company?.settings.defaultAccountReceivableAccount;

	const setUserFromCookie = useCallback(() => {
		if (userCookie.current && userCookie.current.id && !themeContext.isLoading) {
			apiCall(
				'GET',
				'users',
				res => {
					let defaultOutlet = getDefaultOutlet(res);

					const _data = {
						user: res,
						selectedOutlet: defaultOutlet,
						selectedRegister: getDefaultRegister(defaultOutlet?.registers || []),
						outlets: [],
						access: userCookie.current.access,
					};

					if (res.company.systemSettings)
						themeContext.setData({
							brightness: res.company.systemSettings.brightness || 100,
							contrast: res.company.systemSettings.contrast || 100,
							theme: res.company.systemSettings.theme
								? res.company.systemSettings.theme.value
								: themes.SHARPER,
						});

					// if current user is owner get all outlets of company.
					if (res.isOwner || res.isInstallerUser) {
						apiCall(
							'GET',
							'outlets',
							_res => {
								defaultOutlet = getDefaultOutlet(res, _res);
								setData({
									..._data,
									outlets: _res,
									selectedOutlet: defaultOutlet,
									selectedRegister: getDefaultRegister(
										defaultOutlet?.registers || []
									),
								});
							},
							() => {}
						);
					} else setData(_data);
				},
				() => logout(),
				userCookie.current.id,
				null,
				{ 'groups[]': 'user-app:read' }
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, themeContext.isLoading]);

	useEffect(() => {
		setUserFromCookie();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token, themeContext.isLoading]);

	useEffect(() => {
		window.addEventListener('storage', () => {
			// When local storage changes, dump the list to
			// the console.
			userCookie.current = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));

			if (userCookie.current === null) {
				setData(null);
				setToken(null);
			}
		});
	}, []);

	useEffect(() => {
		if (data?.selectedOutlet)
			localStorage.setItem(SELECTED_OUTLET_STORAGE_KEY, data.selectedOutlet.id);

		if (data?.selectedRegister)
			localStorage.setItem(SELECTED_REGISTER_STORAGE_KEY, data.selectedRegister.id);
	}, [data]);

	return (
		<UserContext.Provider
			value={{
				data,
				setData,
				token,
				setToken,
				logout,
				login,
				hasPermission,
				updatePermissions,
				updateOutlet,
				updateRegister,
				clearSelectedRegister,
				getDefaultAccountReceivableAccount,
			}}>
			{children}
		</UserContext.Provider>
	);
};

UserContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default UserContext;
