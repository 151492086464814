import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import ContentInner from '../../reusables/template/ContentInner';
import ReservationList from './ReservationList';
import usePages from '../../../utils/hooks/usePages';
import Button from '../../reusables/element/Button';
import DatePicker from '../../reusables/field/DatePicker';
import { addErrorNotification, dateFormatter } from '../../../utils/helpers/helper';
import apiCall from '../../../utils/helpers/apiCall';
import useField from '../../../utils/hooks/useField';
import Selects from '../../reusables/field/Selects';

const _Dashboard = ({ history }) => {
	const pages = usePages();

	const [currentDay, setCurrentDay] = useState(moment().startOf('day'));

	const [bookingTypes, setBookingTypes] = useState([]);

	const [bookingType, bookingTypeOnChange] = useField(
		bookingTypes,
		'bookingPeriod',
		() => {},
		[],
		null
	);

	const goTo = useCallback(
		isPrev => {
			setCurrentDay(moment(currentDay.valueOf()).add(isPrev ? -1 : 1, 'day'));
		},
		[currentDay]
	);

	useEffect(() => {
		apiCall(
			'GET',
			'bookingTypes',
			res => {
				setBookingTypes(res);
			},
			err => {
				addErrorNotification(err.toString());
			},
			'',
			null,
			{
				'groups[]': 'booking-type:read',
				'module.value': 'Marina',
			}
		);
	}, []);

	return (
		<>
			<ContentInner.SubHeader>
				<ContentInner.SubHeaderItem>
					<ContentInner.SubHeaderTitle title={pages.marina.dashboard.text} />
					<ContentInner.SubHeaderSeparator />
					<ContentInner.SubHeaderGroup>
						<ContentInner.SubHeaderDesc>
							<DatePicker
								placeholder={`${dateFormatter(currentDay, false)} Today`}
								className='sdms-subheader__search'
								id='goToDate'
								type='calendar'
								value={
									moment(currentDay).format('ll') === moment().format('ll')
										? null
										: currentDay.toDate()
								}
								onChange={e => {
									const _currentDay = moment(currentDay.valueOf())
										.year(moment(e.target.value).year())
										.month(moment(e.target.value).month())
										.date(moment(e.target.value).date());

									setCurrentDay(_currentDay);
								}}
								leftIcon='Layout-left-panel-1'
							/>
						</ContentInner.SubHeaderDesc>
						<ContentInner.SubHeaderDesc>
							<Button
								label='info'
								icon='Angle-double-left'
								size='sm'
								text='Prev'
								onClick={() => goTo(true)}
							/>
							<Button
								label='info'
								icon='Angle-double-right'
								size='sm'
								text='Next'
								onClick={() => goTo(false)}
							/>
						</ContentInner.SubHeaderDesc>
						<ContentInner.SubHeaderDesc>
							<Selects
								options={bookingTypes}
								placeholder='Booking Type'
								value={bookingType}
								onChange={bookingTypeOnChange}
							/>
						</ContentInner.SubHeaderDesc>
					</ContentInner.SubHeaderGroup>
				</ContentInner.SubHeaderItem>
			</ContentInner.SubHeader>
			<ContentInner.Container hasFrame title={pages.marina.dashboard.text}>
				<div className='row h-100'>
					<div className='col-xl-6'>
						<ReservationList
							history={history}
							type='checkIn'
							bookingTypeId={bookingType?.id}
							reservationDate={currentDay}
						/>
					</div>
					<div className='col-xl-6'>
						<ReservationList
							history={history}
							type='checkOut'
							bookingTypeId={bookingType?.id}
							reservationDate={currentDay}
						/>
					</div>
				</div>
			</ContentInner.Container>
		</>
	);
};
_Dashboard.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

export default _Dashboard;
