import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Section from '../../../layout/Section';
import PricingItem from './PricingItem';
import ItemMultiImage from './ItemMultiImage';

const ProductItem = ({
	productCategories,
	productCategoryKey,
	productKey,
	selectProduct,
	isLoading,
	multipleSelected,
	setMultipleSelected,
	outlet,
	isOnline,
	initProducts,
	searchData,
}) => {
	const product = productCategories[productCategoryKey].products[productKey];

	const pricingKeys = Object.keys(product.pricing);

	const onProductNameClick = () => {
		if (product.onlineUrl) window.open(product.onlineUrl, '_blank');
	};

	return (
		<Section
			className={classNames('sdms-booking-item', {
				'sdms-booking-item--disabled':
					// eslint-disable-next-line no-prototype-builtins
					multipleSelected && !multipleSelected.hasOwnProperty(productKey),
			})}>
			<Section.Body className='row'>
				<div className='col-lg-3 sdms-pb-15-tablet-and-mobile'>
					<h3
						className={classNames(
							'sdms-section__title sdms-visible-tablet-and-mobile',
							{
								'sdms-cursor--pointer': !!product.onlineUrl,
								'sdms-link': !!product.onlineUrl,
							}
						)}
						role='presentation'
						onClick={onProductNameClick}>
						{product.name}
					</h3>
					<ItemMultiImage images={product.images} />
				</div>
				<div className='sdms-booking-item__info col-lg sdms-pb-15-tablet-and-mobile'>
					<h3
						className={classNames('sdms-section__title sdms-visible-desktop', {
							'sdms-cursor--pointer': !!product.onlineUrl,
							'sdms-link': !!product.onlineUrl,
						})}
						role='presentation'
						onClick={onProductNameClick}>
						{product.name}
					</h3>
					{/* eslint-disable-next-line react/no-danger */}
					<div dangerouslySetInnerHTML={{ __html: product.longDescription }} />
				</div>
				<div className='sdms-booking-item__details col-lg-4'>
					{pricingKeys.map(pricingKey => (
						<PricingItem
							key={pricingKey}
							productCategories={productCategories}
							productCategoryKey={productCategoryKey}
							productKey={productKey}
							pricingKey={pricingKey}
							selectProduct={selectProduct}
							isMulti={pricingKeys.length > 1}
							isLoading={isLoading}
							multipleSelected={multipleSelected}
							setMultipleSelected={setMultipleSelected}
							outlet={outlet}
							isOnline={isOnline}
							initProducts={initProducts}
							searchData={searchData}
						/>
					))}
				</div>
			</Section.Body>
		</Section>
	);
};
ProductItem.propTypes = {
	productCategories: PropTypes.objectOf(PropTypes.object).isRequired,
	productCategoryKey: PropTypes.string.isRequired,
	productKey: PropTypes.string.isRequired,
	selectProduct: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	multipleSelected: PropTypes.object,
	setMultipleSelected: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	outlet: PropTypes.object.isRequired,
	isOnline: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	initProducts: PropTypes.array.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	searchData: PropTypes.object,
};
ProductItem.defaultProps = {
	multipleSelected: null,
	searchData: null,
};

export default ProductItem;
