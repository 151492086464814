import React, { useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import { Link } from 'react-router-dom';
import Decimal from 'decimal.js';
import moment from 'moment-timezone';
import classNames from 'classnames';
import tinyColor from 'tinycolor2';
import { toCsv } from 'react-csv-downloader';
import FileSaver from 'file-saver';
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';

import pages from '../../components/pages';
import { modules, parseData } from './apiCall';
import {
	bookingPeriods,
	themes,
	paymentStatuses,
	paymentTypes,
	reportTypes,
	firstInvoiceOptions,
	invoicingFrequencies as _invoicingFrequencies,
	nextInvoiceOptions,
	creditCardPaymentStatuses,
	invoicingFrequencies,
	invoiceStatuses,
	extraChargeTypes,
	metaTypeLabels,
	serviceFeeModules,
	productServiceFeeValues,
	SELECTED_PRINTER_STORAGE_KEY,
	filterComponents,
	bookBies,
	mapItemColors,
} from '../constants/constants';

import SVGIcon from '../../components/reusables/element/SVGIcon';

// added extra parameter to return date in format with time or not
export const dateFormatter = (datetime = null, withTime = true) => {
	if (withTime) {
		return new Intl.DateTimeFormat('en-US', {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
		})
			.format(datetime ? new Date(datetime) : new Date())
			.replace(',', '');
	}
	return new Intl.DateTimeFormat('en-US', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	})
		.format(datetime ? new Date(datetime) : new Date())
		.replace(',', '');
};

export const dateView = (
	from,
	to,
	withHours = true,
	isDaily = false,
	separator = 'to',
	avoidTimezoneConversion = false
) => {
	const momentFunc = avoidTimezoneConversion ? moment.utc : moment;

	if (to === undefined) {
		return (
			<>{momentFunc(from).format(`${withHours ? 'MMM DD, YYYY, h:mm a' : 'MMM DD, YYYY'}`)}</>
		);
	}
	if (withHours) {
		if (momentFunc(from).format('LL') === momentFunc(to).format('LL'))
			return (
				<>
					{momentFunc(from).format('MMM DD, YYYY h:mm a')}
					{momentFunc(to).isValid() && ` ${separator} ${momentFunc(to).format('h:mm a')}`}
				</>
			);

		return (
			<>
				{momentFunc(from).format('MMM DD, YYYY, h:mm a')}
				{momentFunc(to).isValid() &&
					` ${separator} ${momentFunc(to).format('MMM DD, YYYY, h:mm a')}`}
			</>
		);
	}
	if (isDaily) {
		return (
			<>
				{momentFunc(from).format('MMM DD, YYYY')}
				{momentFunc(to).isValid() &&
					` ${separator} ${momentFunc(to).format('MMM DD, YYYY')}`}
			</>
		);
	}

	if (momentFunc(from).format('LL') === momentFunc(to).format('LL')) {
		return <>{momentFunc(from).format('MMM DD, YYYY')}</>;
	}

	return (
		<>
			{momentFunc(from).format(
				`${
					momentFunc(from).format('Y') === momentFunc(to).format('Y')
						? 'MMM DD'
						: 'MMM DD, YYYY'
				}`
			)}
			{momentFunc(to).isValid() &&
				` ${separator} ${momentFunc(to).format(
					`${
						momentFunc(from).format('MM') === momentFunc(to).format('MM')
							? 'DD, YYYY'
							: 'MMM DD, YYYY'
					}`
				)}`}
		</>
	);
};

export const priceFormatter = price => {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(price);
};

export const addSuccessNotification = (message, width = 300) => {
	store.addNotification({
		message,
		type: 'success',
		insert: 'top',
		container: 'bottom-right',
		animationIn: ['animated', 'fadeIn'],
		animationOut: ['animated', 'fadeOut'],
		dismiss: {
			duration: 5000,
			pauseOnHover: true,
			showIcon: true,
		},
		width,
	});
};

export const addErrorNotification = (message, width = 300) => {
	store.addNotification({
		message,
		type: 'danger',
		insert: 'top',
		container: 'bottom-right',
		animationIn: ['animated', 'fadeIn'],
		animationOut: ['animated', 'fadeOut'],
		dismiss: {
			duration: 5000,
			pauseOnHover: true,
			showIcon: true,
		},
		width,
	});
};

export const addCustomNotification = (
	title,
	message,
	content,
	type,
	duration = 5000,
	width = 300
) => {
	store.addNotification({
		title,
		message,
		content,
		type,
		insert: 'top',
		container: 'bottom-right',
		animationIn: ['animated', 'fadeIn'],
		animationOut: ['animated', 'fadeOut'],
		dismiss: {
			duration,
			pauseOnHover: true,
			showIcon: true,
		},
		width,
	});
};

export const getDescriptionLink = (description, error = false) => {
	const matches = description.toString().match('{{(.*)}}');

	if (matches) {
		const linkParts = matches[1].split('|');

		const descriptionParts = description.split(matches[0]);

		return (
			<span className={error ? '' : 'form-text text-muted'}>
				{descriptionParts[0]}
				<Link to={linkParts[0]} target='_blank' rel='noopener' className='sdms-link'>
					{linkParts[1]}
				</Link>
				{descriptionParts[1]}
			</span>
		);
	}

	return <span className={error ? '' : 'form-text text-muted'}>{description}</span>;
};

export const getCreditCardType = cardNumber => {
	const res = {
		electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
		maestro: /^(5[06-8]\d{4}|6\d)/gi,
		dankort: /^(5019)\d+$/,
		interpayment: /^(636)\d+$/,
		unionpay: /^(62|88)\d+$/,
		VISA: /^4[0-9]/gi,
		MC: /^5[1-5][0-9]/gi,
		AMEX: /^3[47][0-9]/gi,
		DC: /^3(?:0[0-5]|[68][0-9])[0-9]$/,
		DICS: /^6(?:011|5[0-9]{2})[0-9]$/,
		JCB: /^(?:2131|1800|35\d{3})\d$/,
	};

	let cardType = 'unknown';

	cardNumber = cardNumber.replace(/[^0-9]/g, '');

	Object.keys(res).forEach(key => {
		if (res[key].test(cardNumber)) cardType = key;
	});

	return cardType;
};

export const getCompaniesFromUserRoleOutlets = userRoleOutlets => {
	if (!userRoleOutlets) return [];

	const companies = [];

	const map = new Map();

	userRoleOutlets.forEach(userRoleOutlet => {
		if (userRoleOutlet.company)
			if (!map.has(userRoleOutlet.company.id)) {
				map.set(userRoleOutlet.company.id, true);
				companies.push(userRoleOutlet.company);
			}
	});

	return companies;
};

export const getOutletsFromUserRoleOutlets = userRoleOutlets => {
	if (!userRoleOutlets) return [];

	const outlets = [];

	const map = new Map();

	userRoleOutlets.forEach(userRoleOutlet => {
		if (!map.has(userRoleOutlet.outlet.id)) {
			map.set(userRoleOutlet.outlet.id, true);
			outlets.push(userRoleOutlet.outlet);
		}
	});

	return outlets;
};

export const getUserRoleLevel = (user, outlet) => {
	if (user.userRoleOutlets.length === 0) return 0;
	return Math.max(
		...user.userRoleOutlets
			.filter(uro => uro.outlet.id === outlet.id)
			.map(uro => uro.role.roleLevel.level)
	);
};

export const randomColor = number => {
	let random = null;
	if (number) {
		random = number % 7;
	} else {
		random = Math.floor(Math.random() * 7);
	}

	let color = null;
	switch (random) {
		case 0:
			color = 'brand';
			break;
		case 1:
			color = 'danger';
			break;
		case 2:
			color = 'dark';
			break;
		case 3:
			color = 'info';
			break;
		case 4:
			color = 'primary';
			break;
		case 5:
			color = 'success';
			break;
		case 6:
			color = 'warning';
			break;
		default:
			break;
	}
	return color;
};

export const paymentTypeIcon = (type = '') => {
	let icon = {};
	switch (type.toLowerCase()) {
		case 'money':
		case 'cash':
			icon = { name: 'Cash', fill: true };
			break;
		case 'check':
		case 'bankcheck':
			icon = { name: 'BankCheck', fill: true };
			break;
		case 'ticket':
			icon = { name: 'Ticket', fill: false };
			break;
		case 'visa':
		case 'electron':
			icon = { name: 'VISA', fill: true };
			break;
		case 'house':
		case 'house account':
		case 'houseaccount':
			icon = { name: 'Home', fill: false };
			break;
		case 'booking':
			icon = { name: 'Home', fill: false };
			break;
		case 'mastercard':
		case 'master card':
		case 'mc':
			icon = { name: 'MasterCard', fill: true };
			break;
		case 'maestro':
			icon = { name: 'Maestro', fill: true };
			break;
		case 'american express':
		case 'americanexpress':
		case 'amex':
			icon = { name: 'AMEX', fill: true };
			break;
		case 'unionpay':
			icon = { name: 'Union', fill: true };
			break;
		case 'diners':
		case 'dc':
			icon = { name: 'Diners', fill: true };
			break;
		case 'discover':
		case 'disc':
		case 'dics':
			icon = { name: 'Discover', fill: true };
			break;
		case 'jcb':
			icon = { name: 'JCB', fill: true };
			break;
		case 'credit-card':
			icon = { name: 'Credit-card', fill: true };
			break;
		case 'wire':
			icon = { name: 'Arrow-h', fill: false };
			break;
		case 'journal':
			icon = { name: 'Clipboard-list', fill: false };
			break;
		default:
			icon = { name: 'Exchange', fill: false };
	}
	return icon;
};

export const timeSince = time => {
	switch (typeof time) {
		case 'number':
			break;
		case 'string':
			time = +new Date(time);
			break;
		case 'object':
			if (time.constructor === Date) time = time.getTime();
			break;
		default:
			time = +new Date();
	}
	const timeFormats = [
		[60, 'seconds', 1], // 60
		[120, '1 minute ago', '1 minute from now'], // 60*2
		[3600, 'minutes', 60], // 60*60, 60
		[7200, '1 hour ago', '1 hour from now'], // 60*60*2
		[86400, 'hours', 3600], // 60*60*24, 60*60
		[172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
		[604800, 'days', 86400], // 60*60*24*7, 60*60*24
		[1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
		[2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
		[4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
		[29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
		[58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
		[2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
		[5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
		[58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
	];
	let seconds = (+new Date() - time) / 1000;
	let token = 'ago';
	let listChoice = 1;

	if (seconds < 60) {
		return 'Just now';
	}
	if (seconds < 0) {
		seconds = Math.abs(seconds);
		token = 'from now';
		listChoice = 2;
	}
	let i = 0;
	let format;

	// eslint-disable-next-line no-cond-assign,no-plusplus
	while ((format = timeFormats[i++]))
		if (seconds < format[0]) {
			if (typeof format[2] === 'string') return format[listChoice];

			return `${Math.floor(seconds / format[2])} ${format[1]} ${token}`;
		}
	return time;
};

export const generateId = items => {
	const id = items.length ? Math.min(...items.map(i => i.id)) - 1 : 0;
	return id > 0 ? 0 : id;
};

export const quantityFormatter = (value, length = 5) => {
	if (parseInt(value, 10) === parseFloat(value)) return value;

	return parseFloat(value.toFixed(length));
};

export const parsePrint = request =>
	request
		.toString()
		.replace(/<\/?[^>]+(>|$)/g, '')
		.replace(/(?:\\x[\da-fA-F]{2})+/g, m => decodeURIComponent(m.replace(/\\x/g, '%')));

export const numberFormat = (num, scale = 2) => {
	if (num < 0) return +`${Math.round(`${num * -1}e+${scale}`)}e-${scale}` * -1;

	return +`${Math.round(`${num}e+${scale}`)}e-${scale}`;
};

export const addFloats = (float1, float2) => {
	return new Decimal(float1).add(new Decimal(float2)).toNumber();
};

export const subFloats = (float1, float2) => {
	return new Decimal(float1).sub(new Decimal(float2)).toNumber();
};

export const mulFloats = (float1, float2) => {
	return new Decimal(float1).mul(new Decimal(float2)).toNumber();
};

export const divFloats = (float1, float2) => {
	return new Decimal(float1).div(new Decimal(float2)).toNumber();
};

export const getPaymentTermByModule = (outlet, customer, module = modules.POS) => {
	const fields = {
		'Point of Sale': 'paymentTermPos',
		Bookings: 'paymentTermBooking',
		Marina: 'paymentTermMarina',
		Campground: 'paymentTermCampground',
	};

	if (customer) {
		if (customer[fields[module]]) return customer[fields[module]];

		if (customer.paymentTerm) return customer.paymentTerm;
	}

	if (outlet.settings) {
		if (outlet.settings[fields[module]]) return outlet.settings[fields[module]];

		return outlet.settings.paymentTerm;
	}

	return null;
};

export const parseOrderItemModifiersData = orderItemModifiers => {
	return orderItemModifiers.map(oim => {
		// if its new modifier do not parse it.
		if (oim.modifier && oim.modifier.id > 0)
			oim.modifier = parseData(oim.modifier, 'modifiers');

		oim.modifierSection = parseData(oim.modifierSection, 'modifierSections');
		oim.modifierPrefix = parseData(oim.modifierPrefix, 'modifierPrefixes');
		oim.modifierOverride = parseData(oim.modifierOverride, 'modifierOverrides');
		return oim;
	});
};

export const creditCardParser = rawData => {
	let cardData = {};

	/**
	 * Mask Card Number xxxxxxxxxxxx1111
	 * @module maskCardNumber
	 * @param {string} cardNumberMatch
	 * @param {method} callback
	 */
	const maskCardNumber = (cardNumberMatch, callback) => {
		const cardNumber =
			new Array(cardNumberMatch.length - 3).join('x') +
			cardNumberMatch.substr(cardNumberMatch.length - 4, 4);

		if (callback && typeof callback === 'function') {
			callback(cardNumber);
		}
	};

	/**
	 * Split full name array after 1st space to match logic on backend
	 * @module splitNameNoDelimiter
	 * @param {string} name - firstName|lastName
	 * @param {array} match
	 */
	const splitNameNoDelimiter = (name, match) => {
		//
		const fullName = match[2].trim().split(' ');
		const lastName = fullName.slice(1).join(' ');
		const firstName = fullName[0];

		if (name === 'lastName') return lastName;

		return firstName;
	};

	/**
	 * Set Base Card Data
	 * @module setCardData
	 * @param {string} cardNumber - masked number from @module maskCardNumber
	 * @param {array} match
	 * @param cardDataType
	 * @param callback
	 */
	const setCardData = (cardNumber, match, cardDataType, callback) => {
		let _cardData = {};

		switch (cardDataType) {
			case 'standard':
				_cardData = {
					cardNumber,
					firstName: match[3].trim(),
					lastName: match[2].trim(),
					expMonth: match[5].trim(),
					expYear: match[4].trim(),
					swipe: rawData.trim(),
					isRecognized: true,
					isEmv: false,
				};
				break;
			case 'nonStandard':
				_cardData = {
					cardNumber,
					firstName: splitNameNoDelimiter('firstName', match),
					lastName: splitNameNoDelimiter('lastName', match),
					expMonth: match[4].trim(),
					expYear: match[3].trim(),
					emv: rawData.trim(),
					isRecognized: true,
					isEmv: false,
				};
				break;
			case 'keyedEntryAndDebit':
				_cardData = {
					cardNumber,
					expMonth: match[3].trim(),
					expYear: match[2].trim(),
					swipe: rawData.trim(),
					isRecognized: true,
					isEmv: false,
				};
				break;
			case 'keyedEntryCvv':
				_cardData = {
					cardNumber,
					expMonth: match[3].trim(),
					expYear: match[2].trim(),
					cvv: match[4].trim().replace(/./g, 'x'),
					swipe: rawData.trim(),
					isRecognized: true,
					isEmv: false,
				};
				break;
			case 'cardNumberOnly':
				_cardData = {
					cardNumber,
					expMonth: '',
					expYear: '',
					cvv: '',
					swipe: rawData,
					isRecognized: true,
					isEmv: false,
				};
				break;
			default:
				break;
		}

		if (callback && typeof callback === 'function') {
			callback(_cardData);
		}
	};

	/**
	 * Test RegExp Pattern
	 * @module isMatch
	 * @param {RegExp} pattern
	 */
	const isMatch = pattern => {
		return rawData.match(pattern);
	};

	/**
	 * Test for EMV Fallback Swipe On Pin Error
	 * @module isEmvFallbackSwipePinError
	 * @return {boolean}
	 * @param _rawData
	 */
	const isEmvPinErrorSwipeFallback = _rawData => {
		return /^9F390180DFEE238/.test(_rawData);
	};

	/**
	 * RegExp Pattern Match For Supported Devices
	 * @module supportedDevices
	 * @return match
	 */
	const supportedDevices = {
		// 1. MAGTEK & SRED RegExp to extract the first & last name, credit card and expiration date info from the raw data
		standard: () => {
			// eslint-disable-next-line no-useless-escape
			const pattern = /%[B\*]([0-9\* ]{13,19})\^([A-Z ]+)\/([A-Z ]+)\^([0-9]{2})([0-9]{2}).*/;
			const match = isMatch(pattern);
			const cardDataType = 'standard';

			if (!match) return null;

			return {
				match,
				cardDataType,
			};
		},
		// 2. MAGTEK & SRED No delimiter between first & last name or after full name
		nonStandard: () => {
			// eslint-disable-next-line no-useless-escape
			const pattern = /%[B\*]([0-9\* ]{13,19})\^([A-Z ]+).?\^([0-9]{2})([0-9]{2}).*/;
			const match = isMatch(pattern);
			const cardDataType = 'nonStandard';

			if (!match) return null;

			return {
				match,
				cardDataType,
			};
		},
		// 3. MAGTEK & SRED standard no expiration date
		standardNoExp: () => {
			// eslint-disable-next-line no-useless-escape
			const pattern = /%[B\*]([0-9\* ]{13,19})\^([A-Z ]+)\/([A-Z ]+).*/;
			const match = isMatch(pattern);
			const cardDataType = 'standard';

			if (!match) return null;

			match[5] = '';
			match[4] = '';

			return {
				match,
				cardDataType,
			};
		},
		// 4. MAGTEK & SRED non standard no expiration date
		nonStandardNoExp: () => {
			// eslint-disable-next-line no-useless-escape
			const pattern = /%[B\*]([0-9\* ]{13,19})\^([A-Z ]+).?\^.*/;
			const match = isMatch(pattern);
			const cardDataType = 'nonStandard';

			if (!match) return null;

			match[4] = '';
			match[3] = '';
			return {
				match,
				cardDataType,
			};
		},
		// 5. SRED Keyed Entry CVV (mode 6)
		keyPadMode6Cvv: () => {
			// eslint-disable-next-line no-useless-escape
			const pattern = /;([0-9\* ]{13,19})\=([0-9]{2})([0-9]{2}):(\*{3,4})\?/;
			const match = isMatch(pattern);
			const cardDataType = 'keyedEntryCvv';

			if (!match) return null;

			return {
				match,
				cardDataType,
			};
		},
		// 6. SRED Keyed Entry (mode 1) & Debit
		keyPadMode1AndDebit: () => {
			// eslint-disable-next-line no-useless-escape
			const pattern = /;([0-9\* ]{13,19})\=([0-9]{2})([0-9]{2}).*/;
			const match = isMatch(pattern);
			const cardDataType = 'keyedEntryAndDebit';

			if (!match) return null;

			return {
				match,
				cardDataType,
			};
		},
		// 7.0. ID TECH Debit Number Only
		cardNumberOnly: () => {
			// eslint-disable-next-line no-useless-escape
			const pattern = /;([0-9\* ]{13,19})\=.*/;
			const match = isMatch(pattern);
			const cardDataType = 'cardNumberOnly';

			if (!match) return null;

			return {
				match,
				cardDataType,
			};
		},
		// 7.1. IDTECH SWIPE FALLBACK
		swipeFallback: () => {
			const match = rawData.includes('DFEE23');

			if (match && rawData.length > 200) {
				return {
					rawData,
					swipeFallback: true,
				};
			}
			return null;
		},
		// 7.2. IDTECH EMV
		emv: () => {
			const match = /^[0-9A-Fa-f]+?$/.test(rawData);
			if (match && rawData.length > 400) {
				return {
					rawData,
					emv: true,
				};
			}
			return null;
		},
		// 8. Card And/Or Reader Not Recognized
		cardOrDeviceNotRecognized: () => {
			return {
				cardNotRecognized: true,
			};
		},
	};
	/**
	 * Parses Data from Obj example @supportedDevices
	 * @module parseData
	 * @param callback
	 */
	const parse = callback => {
		let _cardData = {};

		Object.keys(supportedDevices).every(device => {
			const parser = supportedDevices[device]();
			if (parser && callback && typeof callback === 'function') {
				_cardData = callback(parser);
				return false;
			}
			return true;
		});

		return _cardData;
	};

	// Check RegExp
	parse(parser => {
		// bypass card parsing if emv
		if (parser.emv) {
			cardData = {
				isRecognized: true,
				emv: parser.rawData,
				isEmv: true,
			};
		} else {
			const isEmv = isEmvPinErrorSwipeFallback(rawData);
			if (parser.cardNotRecognized) {
				cardData = {
					isRecognized: false,
					isEmv,
				};
			} else if (parser.swipeFallback) {
				cardData = {
					isRecognized: true,
					swipe: parser.rawData,
					isEmv: false,
				};
			} else {
				// set card icon before masking card number
				// mask credit card number
				maskCardNumber(parser.match[1], cardNumber => {
					// set global cardData
					setCardData(cardNumber, parser.match, parser.cardDataType, _cardData => {
						cardData = _cardData;
					});
				});
			}
		}
	});

	return cardData;
};

export const getTenderedValues = amount => {
	let t1 = amount.toFixed(2);
	let t2 = '';
	let t3 = '';
	let t4 = '';

	if (amount % 1 === 0) {
		t2 = Math.ceil(amount / 5) * 5;
		t3 = `${t2 + 5}.00`;
		t4 = `${t2 + 10}.00`;
		t1 = `${t1}.00`;
		t2 = `${t2}.00`;
	} else {
		const vExpl = t1.split('.');
		let c = parseInt(vExpl[0], 10);
		let d = parseInt(vExpl[1], 10);

		if (vExpl[1].length === 1) {
			t1 += '0';
			d *= 10;
		}

		if (d < 50) {
			d = Math.ceil(d / 50) * 50;
			t2 = `${c}.${d}`;
		} else {
			c += 1;
			t2 = `${c}.00`;
		}
		const _c = Math.ceil(c / 5) * 5;
		if (_c === c) {
			c = _c + 5;
		} else {
			c = _c;
		}
		t3 = `${c}.00`;
		t4 = `${c + 5}.00`;
	}

	return [t2, t3, t4];
};

export const numberParser = (isFloat = true, defaultValue = null) => {
	return v => {
		const returnSelf = ['0.', '0.0'];

		if (v !== 0 && (v === '' || !v)) return defaultValue;

		if (isFloat && returnSelf.indexOf(v) > -1) return v;

		if (isFloat && v === '.') return '0.';

		return isFloat ? parseFloat(v) : parseInt(v, 10);
	};
};

export const negativeNumberParser = (v, defaultValue = null) => {
	const returnSelf = ['-', '-0', '-0.', '-0.0', '0.', '0.0'];

	if (v !== 0 && (v === '' || !v)) return defaultValue;

	if (returnSelf.indexOf(v) > -1) return v;

	if (v === '.') return '0.';

	return parseFloat(v);
};

export const noPermissionStatus = data => {
	if (data === null) {
		return '';
	}
	if (data) {
		return 'sdms-permission-denied';
	}
	return 'sdms-permission-allowed';
};

export const range = (start, stop, step) => {
	if (typeof stop === 'undefined') {
		// one param defined
		stop = start;
		start = 0;
	}

	if (typeof step === 'undefined') {
		step = 1;
	}

	if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
		return [];
	}

	const result = [];
	for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
		result.push(i);
	}

	return result;
};

export const checkInvoiceIsEditable = invoice => {
	// if invoice house account and has payment from pay bill disable it.
	if (invoice.fromHouseAccount) return invoice.customerSettlements.length === 0;

	// check for processing payment.
	if (
		invoice.customerSettlements.length > 0 &&
		invoice.customerSettlements[0].remittance.status.value === paymentStatuses.PROCESSING
	)
		return false;

	// check for captured cc payment.
	return !(
		invoice.customerSettlements.length > 0 &&
		invoice.customerSettlements[0].remittance.paymentMethod &&
		invoice.customerSettlements[0].remittance.paymentMethod.paymentType &&
		invoice.customerSettlements[0].remittance.paymentMethod.paymentType.value ===
			paymentTypes.CREDIT_CARD &&
		invoice.customerSettlements[0].remittance.creditCardPaymentStatus.value ===
			creditCardPaymentStatuses.CAPTURED
	);
};

export const invoiceToPayment = invoice => {
	if (invoice.reservationItem)
		return {
			id: invoice.id,
			amount: invoice.total - (invoice.tip || 0),
			status: { value: paymentStatuses.SETTLED },
			customer: invoice.customer,
			timeCreated: invoice.timeCreated,
			remittanceDate: invoice.timeCreated,
			tip: invoice.tip,
			holdForSignature: invoice.holdForSignature,
			paymentMethod: {
				name: paymentTypes.BOOKING,
				paymentType: {
					value: paymentTypes.BOOKING,
				},
			},
			isEditable: checkInvoiceIsEditable(invoice),
			reservationItem: invoice.reservationItem,
		};

	if (invoice.fromHouseAccount)
		return {
			id: invoice.id,
			amount: invoice.total - (invoice.tip || 0),
			status: { value: paymentStatuses.SETTLED },
			customer: invoice.customer,
			timeCreated: invoice.timeCreated,
			remittanceDate: invoice.timeCreated,
			tip: invoice.tip,
			holdForSignature: invoice.holdForSignature,
			paymentMethod: {
				name: paymentTypes.HOUSE_ACCOUNT,
				paymentType: {
					value: paymentTypes.HOUSE_ACCOUNT,
				},
			},
			isEditable: checkInvoiceIsEditable(invoice),
		};

	return {
		...invoice.customerSettlements[0].remittance,
		tip: invoice.tip,
		holdForSignature: invoice.holdForSignature,
		paymentMethod: invoice.customerSettlements[0].remittance.paymentMethod || {
			name: paymentTypes.CREDIT,
			paymentType: {
				value: paymentTypes.CREDIT,
			},
		},
		isEditable: checkInvoiceIsEditable(invoice),
	};
};

export const convertDateToUTC = date => new Date(date.getTime() + date.getTimezoneOffset() * 60000);

export const getItemTotalTaxAmount = (product, taxRate, subtotal) => {
	if (!product || !product.taxCode || !product.taxCode.taxable) return 0;

	let taxAmount = 0;

	[taxRate, ...(product.additionalTaxes || [])].forEach(tr => {
		taxAmount += tr?.type?.value === 'fixed' ? (taxRate.amount / subtotal) * 100 : tr.amount;
	});

	return taxAmount;
};

export const durationFormatter = duration => {
	const hours = Math.floor(duration / 60);
	const minutes = duration % 60;
	if (hours && minutes)
		return `${hours} Hour${hours > 1 ? 's' : ''} ${minutes} Minute${minutes > 1 ? 's' : ''}`;
	if (hours) return `${hours} Hour${hours > 1 ? 's' : ''}`;
	return `${minutes} Minute${minutes > 1 ? 's' : ''}`;
};

export const makeId = (length = Math.floor(Math.random() * 20) + 5) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i += 1) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const getStateColorToRBG = (colorName, alpha = 1, inverse = false, theme = 'sharper') => {
	let color;

	switch (colorName) {
		case 'brand':
			color = tinyColor(!inverse ? getThemeColor(theme).brand : '#ffffff');
			break;
		case 'light':
			color = tinyColor(!inverse ? getThemeColor(theme).light : '#092A4A');
			break;
		case 'dark':
			color = tinyColor(!inverse ? getThemeColor(theme).dark : '#eeeff4');
			break;
		case 'primary':
			color = tinyColor(!inverse ? getThemeColor(theme).primary : '#ffffff');
			break;
		case 'success':
			color = tinyColor(!inverse ? getThemeColor(theme).success : '#ffffff');
			break;
		case 'info':
			color = tinyColor(!inverse ? getThemeColor(theme).info : '#ffffff');
			break;
		case 'warning':
			color = tinyColor(!inverse ? getThemeColor(theme).warning : '#111111');
			break;
		case 'danger':
			color = tinyColor(!inverse ? getThemeColor(theme).danger : '#ffffff');
			break;
		case 'secondary':
			color = tinyColor(!inverse ? getThemeColor(theme).secondary : '#ffffff');
			break;
		default:
			break;
	}

	return color.setAlpha(alpha).toRgbString();
};

export const getStateColorToNickName = colorName => {
	let color;
	switch (colorName) {
		case 'brand':
			color = 'Brand';
			break;
		case 'light':
			color = 'Light';
			break;
		case 'dark':
			color = 'Dark';
			break;
		case 'primary':
			color = 'Primary';
			break;
		case 'success':
			color = 'Green';
			break;
		case 'info':
			color = 'Blue';
			break;
		case 'warning':
			color = 'Yellow';
			break;
		case 'danger':
			color = 'Red';
			break;
		//	Custom color :: BEGIN
		case 'purple':
			color = 'Purple';
			break;
		case 'orange':
			color = 'Orange';
			break;
		case 'black':
			color = 'Black';
			break;
		case 'white':
			color = 'White';
			break;
		case 'brown':
			color = 'Brown';
			break;
		case 'light-danger':
			color = 'Light red';
			break;
		case 'light-success':
			color = 'Light green';
			break;
		case 'light-warning':
			color = 'Light orange';
			break;
		//	Custom color :: END
		default:
			break;
	}
	return color;
};

export const compareTime = (str1, str2) => {
	if (str1 === str2) return 0;

	const time1 = str1.split(':');
	const time2 = str2.split(':');

	if (parseInt(time1[0], 10) > parseInt(time2[0], 10)) return 1;

	if (
		parseInt(time1[0], 10) === parseInt(time2[0], 10) &&
		parseInt(time1[1], 10) > parseInt(time2[1], 10)
	)
		return 1;

	return -1;
};

export const filterExtraChargesByTypeAndPeriod = (
	bookingExtraCharges,
	bookingType,
	bookingPeriod
) =>
	bookingExtraCharges.filter(
		p =>
			(p.code && p.extraChargeType.value === extraChargeTypes.PER_UNIT) ||
			(((bookingType && p.bookingType && p.bookingType.name === bookingType.name) ||
				!p.bookingType) &&
				p.bookingPeriod &&
				bookingPeriod &&
				(p.bookingPeriod.value === bookingPeriod.value ||
					(p.bookingPeriod.value === bookingPeriods.DAILY &&
						bookingPeriod.value === bookingPeriods.NIGHTLY) ||
					(p.bookingPeriod.value === bookingPeriods.NIGHTLY &&
						bookingPeriod.value === bookingPeriods.DAILY) ||
					!p.bookingPeriod))
	);

export const extraChargesToGroups = (bookingExtraCharges, bookingType, bookingPeriod) => {
	const filteredBookingExtraCharges = filterExtraChargesByTypeAndPeriod(
		bookingExtraCharges,
		bookingType,
		bookingPeriod
	);

	return filteredBookingExtraCharges
		.filter(p => p.productCategory)
		.map(p => p.productCategory)
		.filter((pc, index, self) => self.findIndex(_pc => _pc.id === pc.id) === index)
		.concat(filteredBookingExtraCharges.filter(p => !p.productCategory));
};

export const setCookie = (name, value, exDays = 1) => {
	if (exDays === 0) document.cookie = `${name}=${value};path=/`;
	else {
		const d = new Date();
		d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
		document.cookie = `${name}=${value};expires=${d.toUTCString()};path=/`;
	}
};

export const getCookie = cname => {
	const name = `${cname}=`;
	const ca = document.cookie.split(';');
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return null;
};

export const removeCookie = cname => setCookie(cname, '', 0);

export const getColorList = () => {
	const colorList = [
		{ name: getStateColorToNickName('brand'), class: 'brand' },
		{ name: getStateColorToNickName('danger'), class: 'danger' },
		{ name: getStateColorToNickName('dark'), class: 'dark' },
		{ name: getStateColorToNickName('info'), class: 'info' },
		{ name: getStateColorToNickName('primary'), class: 'primary' },
		{ name: getStateColorToNickName('success'), class: 'success' },
		{ name: getStateColorToNickName('warning'), class: 'warning' },
	];

	// filling colorList
	['brand', 'danger', 'dark', 'info', 'primary', 'success', 'warning'].forEach(c =>
		['10', '20', '30', '40', '50', '60', '70', '80', '90'].forEach(n =>
			colorList.push({
				name: `${getStateColorToNickName(c)}, Alpha: ${n}`,
				class: c + n,
			})
		)
	);

	return colorList;
};

export const getMarinaColorList = () => {
	return [
		{ name: getStateColorToNickName('brand'), class: 'brand' },
		{ name: getStateColorToNickName('dark'), class: 'dark' },
		{ name: getStateColorToNickName('info'), class: 'info' },
		{ name: getStateColorToNickName('primary'), class: 'primary' },
		{ name: getStateColorToNickName('purple'), class: 'purple' },
		{ name: getStateColorToNickName('black'), class: 'black' },
		{ name: getStateColorToNickName('white'), class: 'white' },
		{ name: getStateColorToNickName('brown'), class: 'brown' },
	];
};

export const getCampgroundColorList = () => {
	return [
		{ name: getStateColorToNickName('brand'), class: 'brand' },
		{ name: getStateColorToNickName('dark'), class: 'dark' },
		{ name: getStateColorToNickName('info'), class: 'info' },
		{ name: getStateColorToNickName('primary'), class: 'primary' },
		{ name: getStateColorToNickName('purple'), class: 'purple' },
		{ name: getStateColorToNickName('black'), class: 'black' },
		{ name: getStateColorToNickName('white'), class: 'white' },
		{ name: getStateColorToNickName('brown'), class: 'brown' },
	];
};

export const getBlockoutColorList = () => {
	return [
		{ name: getStateColorToNickName('orange'), class: 'orange' },
		{ name: getStateColorToNickName('light-danger'), class: 'light-danger' },
		{ name: getStateColorToNickName('light-success'), class: 'light-success' },
		{ name: getStateColorToNickName('light-warning'), class: 'light-warning' },
		{ name: getStateColorToNickName('purple'), class: 'purple' },
		{ name: getStateColorToNickName('black'), class: 'black' },
		{ name: getStateColorToNickName('white'), class: 'white' },
		{ name: getStateColorToNickName('brown'), class: 'brown' },
	];
};

export const getCustomStatusColorList = () => {
	return [
		{ name: getStateColorToNickName('brand'), class: 'brand' },
		{ name: getStateColorToNickName('dark'), class: 'dark' },
		{ name: getStateColorToNickName('info'), class: 'info' },
		{ name: getStateColorToNickName('primary'), class: 'primary' },
		{ name: getStateColorToNickName('purple'), class: 'purple' },
		{ name: getStateColorToNickName('black'), class: 'black' },
		{ name: getStateColorToNickName('white'), class: 'white' },
		{ name: getStateColorToNickName('brown'), class: 'brown' },
		{ name: getStateColorToNickName('orange'), class: 'orange' },
		{ name: getStateColorToNickName('light-danger'), class: 'light-danger' },
		{ name: getStateColorToNickName('light-success'), class: 'light-success' },
		{ name: getStateColorToNickName('light-warning'), class: 'light-warning' },
	];
};

export const customerPaymentMethodIcon = option => (
	<>
		<SVGIcon
			name={paymentTypeIcon(option.paymentMethod?.icon?.value).name}
			className={classNames('sdms-mr-5', {
				'sdms-svg-icon--fill': paymentTypeIcon(option.paymentMethod?.icon?.value).fill,
			})}
			size={36}
		/>
	</>
);

export const customerPaymentMethodOption = option => (
	<>
		<SVGIcon
			name={paymentTypeIcon(option.paymentMethod?.icon?.value).name}
			className={classNames('sdms-mr-5', {
				'sdms-svg-icon--fill': paymentTypeIcon(option.paymentMethod?.icon?.value).fill,
			})}
			size={36}
		/>
		{option.cardNumber && option.cardNumber}
		{option.expMonth && `- ${option.expMonth}/${option.expYear}`}
		{option.bankName && `${option.bankName} - ${option.accountName}`}
	</>
);

export const customerPaymentMethodOptionalShow = option => {
	if (option.bankName) return `${option.bankName} - ${option.accountName}`;

	return option.cardNumber;
};

export const creditCardOption = option => (
	<>
		<SVGIcon
			name={paymentTypeIcon(option.paymentMethod?.icon?.value).name}
			className={classNames('sdms-mr-5', {
				'sdms-svg-icon--fill': paymentTypeIcon(option.paymentMethod?.icon?.value).fill,
			})}
			size={36}
		/>
		{option.cardNumber}
		{option.expMonth && `- ${option.expMonth}/${option.expYear}`}
	</>
);

export const achOption = option => (
	<>
		<SVGIcon
			name={paymentTypeIcon(option.paymentMethod?.icon?.value).name}
			className={classNames('sdms-mr-5', {
				'sdms-svg-icon--fill': paymentTypeIcon(option.paymentMethod?.icon?.value).fill,
			})}
			size={36}
		/>
		{option.bankName} - {option.accountName}
	</>
);

// Dynamic Windows Size and Orientation
export function useWindowSize() {
	const isClient = typeof window === 'object';

	function getSize() {
		return {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined,
			portrait: isClient ? window.matchMedia('(orientation: portrait)').matches : undefined,
			landscape: isClient ? window.matchMedia('(orientation: landscape)').matches : undefined,
		};
	}

	const [windowSize, setWindowSize] = useState(getSize);

	useEffect(() => {
		if (!isClient) {
			return false;
		}

		function handleResize() {
			setWindowSize(getSize());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
		/* eslint-disable-next-line react-hooks/exhaustive-deps */
	}, []);

	return windowSize;
}

export const getReportQueryFields = reportType => {
	const fields = {};

	if (!reportType) return fields;

	reportType.dataSets.forEach(ds => {
		let field = ds.value;

		if (ds.isUtc) field = `CONVERT_TZ(${field},'UTC','@LTZ')`;

		if (
			ds.dataType.value === 'date' &&
			(ds.fieldFormat.value === 'datetime' || ds.fieldFormat.value === 'date')
		)
			field = `DATE_FORMAT(${field}, '%Y-%m-%d')`;

		fields[field] = {
			label: ds.label,
			type: ds.dataType.value,
			valueSources: ['value'],
			source: ds.source,
			key: ds.displayKey,
		};
		if (ds.dataType.value === 'date')
			fields[field].fieldSettings = {
				dateFormat: 'M/D/YYYY',
			};
	});

	return fields;
};

export const getReportPath = (urlPath, reportType = null) => {
	// newreporttype
	const reportModule = urlPath.toString().split('/');

	if (reportType) {
		switch (reportType.value) {
			case reportTypes.CAMPGROUND_RESERVATIONS:
				return pages.reports.campground.reservations.path;
			case reportTypes.CAMPGROUND_RENT_ROLL:
				return pages.reports.campground.rent_roll.path;
			case reportTypes.CRM_CUSTOMERS:
				return pages.reports.crm.customers.path;
			case reportTypes.CRM_BALANCE_DETAILS:
				return pages.reports.crm.balance_details.path;
			case reportTypes.CRM_PAYMENTS:
				return pages.reports.crm.payments.path;
			case reportTypes.CRM_INVOICES:
				return pages.reports.crm.invoices.path;
			case reportTypes.CRM_INVOICE_DETAILS:
				return pages.reports.crm.invoice_details.path;
			case reportTypes.DEFERRED_INCOME:
				return pages.reports.accounting.deferred_income.path;
			case reportTypes.GENERAL_LEDGER:
				return pages.reports.accounting.general_ledger.path;
			case reportTypes.PAYROLL:
				return pages.reports.accounting.payroll.path;
			case reportTypes.SETTLEMENT_DETAILS:
				return pages.reports.accounting.settlement_details.path;
			case reportTypes.DEPOSIT_BALANCES:
				return pages.reports.accounting.deposit_balances.path;
			case reportTypes.RESERVATION_DAILY_REVENUE:
				return pages.reports.accounting.reservation_daily_revenue.path;
			case reportTypes.ZOUT:
				return pages.reports.pos.zout.path;
			case reportTypes.POS_ORDERS:
				return pages.reports.pos.orders.path;
			case reportTypes.POS_ORDERDETAILS:
				return pages.reports.pos.orderdetails.path;
			case reportTypes.POS_ORDERMODIFIERS:
				return pages.reports.pos.ordermodifiers.path;
			case reportTypes.POS_TIPS:
				return pages.reports.pos.tips.path;
			case reportTypes.BOOKING_RESERVATIONS:
				return pages.reports.booking.reservations.path;
			case reportTypes.MARINA_RESERVATIONS:
				return pages.reports.marina.reservations.path;
			case reportTypes.MARINA_RENT_ROLL:
				return pages.reports.marina.rent_roll.path;
			case reportTypes.MARINA_VESSELS:
				return pages.reports.marina.vessels.path;
			default:
				break;
		}
	}

	// newreporttype
	switch (reportModule[2]) {
		case pages.reports.accounting.path.toString().split('/')[2]:
			return pages.reports.accounting.path;
		case pages.reports.campground.path.toString().split('/')[2]:
			return pages.reports.campground.path;
		case pages.reports.crm.path.toString().split('/')[2]:
			return pages.reports.crm.path;
		case pages.reports.pos.path.toString().split('/')[2]:
			return pages.reports.pos.path;
		case pages.reports.marina.path.toString().split('/')[2]:
			return pages.reports.marina.path;
		case pages.reports.booking.path.toString().split('/')[2]:
			return pages.reports.booking.path;
		default:
			return '';
	}
};

export const getReportText = urlPath => {
	// newreporttype
	const reportModule = urlPath.toString().split('/');

	switch (reportModule[2]) {
		case pages.reports.accounting.path.toString().split('/')[2]:
			return pages.reports.accounting.text;
		case pages.reports.campground.path.toString().split('/')[2]:
			return pages.reports.campground.text;
		case pages.reports.crm.path.toString().split('/')[2]:
			return pages.reports.crm.text;
		case pages.reports.pos.path.toString().split('/')[2]:
			return pages.reports.pos.text;
		case pages.reports.marina.path.toString().split('/')[2]:
			return pages.reports.marina.text;
		case pages.reports.booking.path.toString().split('/')[2]:
			return pages.reports.booking.text;
		default:
			return '';
	}
};

export const clearTree = tree => {
	if (tree) {
		const keys = Object.keys(tree);
		// eslint-disable-next-line func-names
		keys.forEach(function(key) {
			const value = tree[key];
			if (key === 'value') {
				tree[key] = [];
			} else if (typeof value === 'object') {
				clearTree(tree[key]);
			}
		});
		return tree;
	}
	return {};
};

export const setTheme = data => {
	if (data !== themes.SHARPER) {
		document.documentElement.setAttribute('theme', data);
	} else {
		document.documentElement.removeAttribute('theme');
	}
};

export const getThemeColor = data => {
	let color = null;
	switch (data) {
		case 'sharper':
			color = {
				brand: '#001154',
				light: '#eeeff4',
				dark: '#092a4a',
				primary: '#0f487d',
				success: '#46b4a4',
				info: '#1874c9',
				warning: '#ffb43d',
				danger: '#ee6352',
				black: 'black',
				brown: 'brown',
				purple: 'purple',
				white: 'white',
				orange: 'orange',
				secondary: '#6c757d',
				'light-danger': '#FD88AF',
				'light-success': '#32CD32',
				'light-warning': '#FF4500',
				alert: '#f8f53c',
			};
			break;
		case 'modern':
			color = {
				brand: '#505ae2',
				light: '#ffffff',
				dark: '#645ca1',
				primary: '#5867dd',
				success: '#1dc9b7',
				info: '#5578eb',
				warning: '#ffb822',
				danger: '#fd397a',
				black: 'black',
				brown: 'brown',
				purple: 'purple',
				white: 'white',
				orange: 'orange',
				secondary: '#6c757d',
				'light-danger': '#FD88AF',
				'light-success': '#32CD32',
				'light-warning': '#FF4500',
				alert: '#f8f53c',
			};
			break;
		default:
			break;
	}
	return color;
};

export const inchToFeet = inch => {
	if (!inch) return `0'`;

	if (inch < 12) return `${inch}"`;

	const ft = Math.floor(inch / 12).toString();

	const _inch = inch % 12;

	return _inch > 0 ? `${ft}'${_inch}"` : `${ft}'`;
};
export const calculateSqft = (loa, beam) => {
	if (!loa || !beam) return null;

	return (loa * beam) / 144;
};

export const getBookingCalculationText = (bookingPeriod, bookingCalculation) => {
	if (!bookingCalculation || !bookingCalculation.value) return '';

	let periodText = 'period';

	if (bookingCalculation.value.indexOf('period') > -1 && bookingPeriod && bookingPeriod.value) {
		if (bookingPeriod.value === bookingPeriods.HOURLY) periodText = 'hour';
		else if (bookingPeriod.value === bookingPeriods.DAILY) periodText = 'day';
		else if (bookingPeriod.value === bookingPeriods.NIGHTLY) periodText = 'night';
		else if (bookingPeriod.value === bookingPeriods.LONG_TERM) periodText = 'long term';
		else periodText = 'seasonal';
	}

	return bookingCalculation.value.replace('period', periodText);
};

export const ratioFormatter = ratio => {
	let formattedRatio = '';
	if (ratio !== -1) {
		formattedRatio = `${ratio * 100}%`;
	} else {
		formattedRatio = '-';
	}
	return formattedRatio;
};

export const getTimelineLoadingGroups = () => {
	const loadingData = [];
	for (let i = 0; i < 50; i += 1) {
		if (i % 5 === 0) {
			loadingData.push({
				bookBy: 'Unit',
				id: i,
				isOpen: true,
				title: makeId(),
				type: 'bookingType',
			});
		} else if (i % 5 === 1) {
			loadingData.push({
				bookingType: Math.ceil(i / 5) - 1,
				hide: false,
				id: i,
				isOpen: true,
				title: makeId(),
				type: 'unitGroup',
			});
		} else {
			loadingData.push({
				bookingType: Math.ceil(i / 5) - 1,
				hide: false,
				id: i,
				title: makeId(),
				type: 'unit',
				unitGroup: Math.ceil(i / 5),
			});
		}
	}
	return loadingData;
};

export const dateFormatterWithoutYear = datetime => moment(datetime).format('Do MMMM');

export const getNextInvoice = (invoicingFrequency, firstInvoice, invoiceNextOptions) => {
	if (firstInvoice && firstInvoice.value === firstInvoiceOptions.FIXED && invoicingFrequency) {
		if (invoicingFrequency.value === _invoicingFrequencies.ANNUALLY)
			return invoiceNextOptions.find(ino => ino.value === nextInvoiceOptions.NEXT_YEAR);

		if (invoicingFrequency.value === _invoicingFrequencies.SEMI_ANNUALLY)
			return invoiceNextOptions.find(
				ino => ino.value === nextInvoiceOptions.NEXT_SEMI_ANNUAL
			);

		if (invoicingFrequency.value === _invoicingFrequencies.QUARTERLY)
			return invoiceNextOptions.find(ino => ino.value === nextInvoiceOptions.NEXT_QUARTER);

		if (invoicingFrequency.value === _invoicingFrequencies.MONTHLY)
			return invoiceNextOptions.find(ino => ino.value === nextInvoiceOptions.NEXT_MONTH);

		if (invoicingFrequency.value === _invoicingFrequencies.DAILY)
			return invoiceNextOptions.find(ino => ino.value === nextInvoiceOptions.NEXT_DAY);
	}

	return null;
};

export const getNextInvoiceOptions = (
	bookingPeriod,
	invoicingFrequency,
	firstInvoice,
	invoiceNextOptions
) => {
	if (!firstInvoice || !invoicingFrequency || !bookingPeriod) return [];

	if (firstInvoice.value === firstInvoiceOptions.FIXED) {
		if (invoicingFrequency.value === _invoicingFrequencies.DAILY)
			return invoiceNextOptions.filter(ino => ino.value === nextInvoiceOptions.NEXT_DAY);

		if (invoicingFrequency.value === _invoicingFrequencies.MONTHLY)
			return invoiceNextOptions.filter(ino => ino.value === nextInvoiceOptions.NEXT_MONTH);

		if (invoicingFrequency.value === _invoicingFrequencies.QUARTERLY)
			return invoiceNextOptions.filter(ino => ino.value === nextInvoiceOptions.NEXT_QUARTER);

		if (invoicingFrequency.value === _invoicingFrequencies.SEMI_ANNUALLY)
			return invoiceNextOptions.filter(
				ino => ino.value === nextInvoiceOptions.NEXT_SEMI_ANNUAL
			);

		return invoiceNextOptions.filter(ino => ino.value === nextInvoiceOptions.NEXT_YEAR);
	}

	return invoiceNextOptions.filter(
		ino =>
			ino.bookingPeriods.filter(bp => bp.value === bookingPeriod).length > 0 &&
			ino.firstInvoiceOptions.filter(fi => fi.id === firstInvoice.id).length > 0
	);
};

export const printReceipt = (
	webPrint,
	payment,
	user,
	customer,
	printerId = null,
	openPrinterModal
) => {
	const _printerId = printerId || localStorage.getItem(SELECTED_PRINTER_STORAGE_KEY);
	if (
		_printerId &&
		webPrint.current.printers.findIndex(p => p.id.toString() === _printerId.toString()) > -1
	) {
		webPrint.current.payBillReceipt(payment, user, customer, false, false, _printerId, null);
	} else openPrinterModal(payment, user, customer);
};

export const enumDepositTypeConverter = (depositType, bookingPeriod) => {
	if (depositType && bookingPeriod) {
		const _depositType = { ...depositType };

		if (_depositType.value === 'percent') _depositType.label = '%';

		if (_depositType.value === 'fixed') _depositType.label = '$';

		if (bookingPeriod && depositType.value === 'per_block') {
			if (bookingPeriod.value === bookingPeriods.HOURLY) _depositType.label = 'Hour(s)';
			else if (bookingPeriod.value === bookingPeriods.DAILY) _depositType.label = 'Day(s)';
			else if (bookingPeriod.value === bookingPeriods.NIGHTLY)
				_depositType.label = 'Night(s)';
			else _depositType.label = 'Month(s)';
		}

		return _depositType;
	}

	if (depositType) return { ...depositType, label: depositType.value };

	return depositType;
};

export const ucEachWordFirst = sentence =>
	sentence
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');

export const calculateServiceFee = (amount, serviceFeeAmount) => {
	if (!amount) return 0;

	const serviceFeeTotal = numberFormat((amount * serviceFeeAmount) / 100);

	return Number.isNaN(serviceFeeTotal) ? 0 : numberFormat(serviceFeeTotal);
};

export const calculateMeterReadingTotal = (
	previousReading,
	currentReading,
	costPerUnit,
	powerMeter,
	serviceCharge,
	taxRate,
	serviceChargeProduct,
	alwaysCharge = false
) => {
	const totals = {
		subtotal: 0,
		tax: 0,
		total: 0,
	};

	if (
		previousReading === '' ||
		currentReading === '' ||
		costPerUnit === '' ||
		!powerMeter ||
		(alwaysCharge && parseFloat(currentReading) < parseFloat(previousReading)) ||
		(!alwaysCharge && parseFloat(currentReading) <= parseFloat(previousReading))
	)
		return totals;

	let readingTaxableAmount = 0;

	let additionalTax = 0;

	const itemSubtotal = numberFormat(
		(parseFloat(currentReading) - parseFloat(previousReading)) * parseFloat(costPerUnit)
	);

	totals.subtotal += itemSubtotal;

	const itemTaxRate = getItemTotalTaxAmount(powerMeter.product, taxRate, itemSubtotal);

	if (itemTaxRate) {
		if (itemTaxRate > taxRate.amount)
			additionalTax += numberFormat((itemSubtotal * (itemTaxRate - taxRate.amount)) / 100);

		readingTaxableAmount += itemSubtotal;
	}

	if (serviceCharge && serviceChargeProduct) {
		totals.subtotal += parseFloat(serviceCharge);

		const serviceChargeTaxRate = getItemTotalTaxAmount(
			serviceChargeProduct,
			taxRate,
			parseFloat(serviceCharge)
		);

		if (serviceChargeTaxRate) {
			if (serviceChargeTaxRate > taxRate.amount)
				additionalTax += numberFormat(
					(parseFloat(serviceCharge) * (serviceChargeTaxRate - taxRate.amount)) / 100
				);

			readingTaxableAmount += parseFloat(serviceCharge);
		}
	}

	totals.tax = numberFormat((readingTaxableAmount * taxRate.amount) / 100) + additionalTax;

	totals.total = totals.subtotal + totals.tax;

	return totals;
};

export const isMobile = () => {
	const UA = navigator.userAgent;

	return (
		/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
		/\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
	);
};

export const hasTouchScreen = () => {
	let _hasTouchScreen;
	if ('maxTouchPoints' in navigator) {
		_hasTouchScreen = navigator.maxTouchPoints > 0;
	} else if ('msMaxTouchPoints' in navigator) {
		_hasTouchScreen = navigator.msMaxTouchPoints > 0;
	} else {
		const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
		if (mQ && mQ.media === '(pointer:coarse)') {
			_hasTouchScreen = !!mQ.matches;
		} else if ('orientation' in window) {
			_hasTouchScreen = true; // deprecated, but good fallback
		} else {
			// Only as a last resort, fall back to user agent sniffing
			const UA = navigator.userAgent;
			_hasTouchScreen =
				/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
				/\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
		}
	}

	return _hasTouchScreen;
};

export const getItemTaxCode = (taxCode, product) => {
	if (!product) return null;

	if (taxCode) {
		if (!taxCode.taxable) return taxCode;

		return product.taxCode.taxable ? taxCode : product.taxCode;
	}

	return product.taxCode;
};

export const getInvoiceDueDate = (invoiceDate, paymentTerm) => {
	if (!paymentTerm || !invoiceDate) return null;

	const dueDate = moment(invoiceDate);

	if (paymentTerm.isDateDriven) {
		dueDate.set('date', paymentTerm.dayOfDueMonth);

		if (
			dueDate.isBefore(invoiceDate) ||
			invoiceDate.isAfter(moment(dueDate).add(paymentTerm.dueNextMonthDays * -1, 'days'))
		)
			dueDate.add(1, 'month');

		return dueDate;
	}

	dueDate.add(paymentTerm.due, 'days');

	return dueDate;
};

export const getInvoicingFrequencyValue = frequency => {
	switch (frequency) {
		case invoicingFrequencies.DAILY:
			return 0;
		case invoicingFrequencies.MONTHLY:
			return 1;
		case invoicingFrequencies.QUARTERLY:
			return 3;
		case invoicingFrequencies.SEMI_ANNUALLY:
			return 6;
		case invoicingFrequencies.ANNUALLY:
			return 12;
		default:
			return 99;
	}
};

export const isInvoicingFrequencyBigger = (frequency1, frequency2) => {
	return getInvoicingFrequencyValue(frequency1) > getInvoicingFrequencyValue(frequency2);
};

export const filterInvoicingFrequency = (
	frequencies,
	bookingPeriod = null,
	invoicingFrequency = true,
	minFrequency = null,
	isReservationForm = false
) => {
	if (!frequencies || !bookingPeriod) return [];

	let _frequencies = [...frequencies];

	if (bookingPeriod)
		_frequencies = _frequencies.filter(fr =>
			fr.bookingPeriods.some(bp => bp.value === bookingPeriod)
		);

	if (!invoicingFrequency)
		_frequencies = _frequencies.filter(
			fr =>
				fr.value !== invoicingFrequencies.UPFRONT &&
				fr.value !== invoicingFrequencies.SAME_AS_RESERVATION
		);

	if (minFrequency)
		_frequencies = _frequencies.filter(
			fr => getInvoicingFrequencyValue(fr.value) <= getInvoicingFrequencyValue(minFrequency)
		);

	if (isReservationForm)
		_frequencies = _frequencies.filter(
			fr => fr.value !== invoicingFrequencies.SAME_AS_RESERVATION
		);

	return _frequencies;
};

export const isBeforeAccountingClosedPeriod = (datetime, userContext) => {
	if (!userContext.data.user.company.settings.accountingClosingDate) return false;

	return moment(datetime).isBefore(
		moment(userContext.data.user.company.settings.accountingClosingDate)
	);
};

export const getAccountingClosedDate = (userContext, convertDate = true) => {
	if (!userContext.data.user.company.settings.accountingClosingDate) return undefined;

	const closedDate = moment(userContext.data.user.company.settings.accountingClosingDate);

	return convertDate ? closedDate.toDate() : closedDate;
};

export const canVoidInvoice = (invoice, userContext) => {
	if (
		invoice.status.value === invoiceStatuses.VOIDED ||
		isBeforeAccountingClosedPeriod(invoice.invoiceDate, userContext)
	)
		return false;

	if (invoice.deferredIncomes && invoice.deferredIncomes.filter(di => di.posted).length > 0)
		return false;

	return true;
};

export const canDeleteInvoice = (invoice, userContext) => {
	if (isBeforeAccountingClosedPeriod(invoice.invoiceDate, userContext)) return false;

	if (invoice.deferredIncomes && invoice.deferredIncomes.filter(di => di.posted).length > 0)
		return false;

	return !invoice.customerSettlements || invoice.customerSettlements.length === 0;
};

export const canVoidStatementCharge = (statementCharge, userContext) => {
	return !(
		statementCharge.status.value === invoiceStatuses.VOIDED ||
		isBeforeAccountingClosedPeriod(statementCharge.statementChargeDate, userContext)
	);
};

export const canVoidRefund = refund => {
	if (!refund.amount) return false;

	if (refund.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD) return false;

	return !(refund.paymentMethod.paymentType.value === paymentTypes.CHECK && refund.routingNumber);
};

export const canDeleteRefund = refund => {
	if (refund.customerSettlements && refund.customerSettlements.length > 0) return false;

	if (refund.paymentMethod.paymentType.value === paymentTypes.CREDIT_CARD) return false;

	return !(refund.paymentMethod.paymentType.value === paymentTypes.CHECK && refund.routingNumber);
};

export const parseDatePickerValue = (value, convertUtc = true) => {
	if (value)
		return convertUtc ? convertDateToUTC(moment(value).toDate()) : moment(value).toDate();

	return null;
};

export const parseDatePickerChange = (value, previousValue, toISO = true, isUtc = false) => {
	if (!value) return null;

	const newValue = moment(previousValue || undefined)
		.utc(isUtc)
		.year(value.getFullYear())
		.month(value.getMonth())
		.date(value.getDate());

	return toISO ? newValue.toISOString() : newValue;
};

export const getCreditRemainingAmount = credit => {
	if (!credit.customerSettlements) return 0;

	return (
		credit.amount -
		credit.customerSettlements
			.filter(cs => cs.invoice || cs.refund)
			.reduce((partialSum, customerSettlement) => partialSum + customerSettlement.amount, 0)
	);
};

export const getRemittanceRemainingAmount = remittance => {
	if (!remittance.customerSettlements) return 0;

	return subFloats(
		remittance.amount,
		remittance.customerSettlements.reduce(
			(partialSum, customerSettlement) =>
				addFloats(partialSum, customerSettlement.amount || 0),
			0
		)
	);
};

export const getSettlementMaxPaymentAmount = (settlements, index, amount) => {
	const otherSettlements = settlements
		.filter((s, i) => i !== index && !Number.isNaN(parseFloat(s.amount)))
		.map(s => parseFloat(s.amount));

	const otherSettlementAmountTotal = otherSettlements.length
		? otherSettlements.reduce((a, b) => a + b)
		: 0;

	const remainingPaymentAmount = amount - otherSettlementAmountTotal;

	return numberFormat(
		Number.isNaN(remainingPaymentAmount) ||
			remainingPaymentAmount > settlements[index].balanceDue
			? settlements[index].balanceDue
			: remainingPaymentAmount
	);
};

export const getJournalAmount = journal => {
	let amount = 0;

	journal.journalLines.forEach(journalLine => {
		if (journalLine.account.accountType.value === 'AccountsReceivable') {
			if (journalLine.isDebit) amount += journalLine.amount;
			else amount -= journalLine.amount;
		}
	});

	return amount;
};

export const getJournalAmountPaid = (journal, settlement) => {
	if (!journal.customerSettlements) return 0;

	const amountPaid = settlement && settlement.amount ? settlement.amount : 0;

	return journal.customerSettlements.reduce(
		(partialSum, cs) => partialSum + (typeof cs === 'object' && cs.amount ? cs.amount : 0),
		amountPaid
	);
};

export const filterCustomerPaymentMethods = (
	customerPaymentMethods,
	paymentProcessor,
	authorizedPayment = null
) => {
	const paymentMethods =
		paymentProcessor && customerPaymentMethods
			? customerPaymentMethods.filter(pm => paymentProcessor.id === pm.processor.id)
			: [];

	if (
		!authorizedPayment ||
		paymentMethods.some(pm => pm.token === authorizedPayment.token) ||
		authorizedPayment.paymentMethod.paymentType.value !== paymentProcessor.paymentType.value
	)
		return paymentMethods;

	return [...paymentMethods, { ...authorizedPayment, id: 0 }];
};

export const getPaymentProcessorServiceFeeAmount = (
	outlet,
	serviceFeeModule,
	paymentProcessor,
	disabled = false
) => {
	if (!paymentProcessor || disabled) return 0;

	if (
		outlet.settings &&
		outlet.settings[serviceFeeModule] &&
		paymentProcessor.serviceFeeItem &&
		paymentProcessor.serviceFeeAmount
	)
		return paymentProcessor.serviceFeeAmount;

	// todo: are we taking into account service fee taxes?
	return 0;
};

export const canApplyServiceFeeToOrderItem = (orderItem, outlet) => {
	if (
		!orderItem.product.serviceFee ||
		orderItem.product.serviceFee.value === productServiceFeeValues.DEFAULT
	)
		return outlet.settings[serviceFeeModules.POS];

	return orderItem.product.serviceFee.value === productServiceFeeValues.ON;
};

export const getSizeVal = (container, image) => {
	if (container.width / container.height > image.width / image.height) {
		return {
			height: container.height,
			width: (image.width * container.height) / image.height,
		};
	}
	if (container.width / container.height < image.width / image.height) {
		return {
			height: (container.width * image.height) / image.width,
			width: container.width,
		};
	}
	return {
		height: container.height,
		width: container.width,
	};
};

export const parseEnumCalculationLabel = enumCalculation => {
	if (!enumCalculation) return enumCalculation;
	return { ...enumCalculation, label: enumCalculation.value === 'fixed' ? '$' : '%' };
};

export const metaTypeOptionParser = meta => ({ ...meta, label: metaTypeLabels[meta.value] });

export const bookingPeriodFinder = bookingPeriod => {
	if (bookingPeriod) {
		if (bookingPeriod.value === bookingPeriods.HOURLY) return 'Hour(s)';
		if (bookingPeriod.value === bookingPeriods.NIGHTLY) return 'Night(s)';
	}
	return 'Day(s)';
};

export const isLegacyServiceFeeSettlement = settlement => settlement.isLegacyServiceFeeSettlement;

export const isServiceFeeSettlement = settlement =>
	(settlement.isLegacyServiceFeeSettlement &&
		settlement.isDeposit &&
		settlement.remittance &&
		settlement.remittance.serviceFee &&
		settlement.amount <= settlement.remittance.serviceFee) ||
	settlement.isFee;

export const getContractText = ref =>
	ref.current
		? ref.current.innerHTML
				.replaceAll(
					'display: none;border-bottom: 1px solid black;',
					'display: inline;border-bottom: 1px solid black;'
				)
				.replaceAll(
					'display: none; border-bottom: 1px solid black;',
					'display: inline;border-bottom: 1px solid black;'
				)
		: '';

export const isScheduledPaymentWithoutToken = payment =>
	payment.id !== 0 &&
	payment.status &&
	payment.status.value === paymentStatuses.SCHEDULED &&
	!payment.token;

export const isScheduledPayment = payment =>
	payment.id !== 0 && payment.status && payment.status.value === paymentStatuses.SCHEDULED;

export const hasSettlementServiceFee = settlement =>
	settlement.isLegacyServiceFeeSettlement || settlement.hasServiceFee;

export const hasMultipleServiceFeeSettlement = settlement =>
	settlement.remittance?.customerSettlements &&
	settlement.remittance.customerSettlements.filter(cs => hasSettlementServiceFee(cs)).length > 1;

export const getSettlementAmountWithoutFee = settlement => {
	if (settlement.isFee) return 0;

	if (hasSettlementServiceFee(settlement) && settlement.remittance?.serviceFee) {
		// if payment has one service fee settlement it means all service fee in this settlement.
		if (hasMultipleServiceFeeSettlement(settlement)) {
			return numberFormat(
				settlement.amount -
					(settlement.amount / settlement.remittance.amount) *
						settlement.remittance.serviceFee,
				2
			);
		}

		return subFloats(settlement.amount, settlement.remittance.serviceFee);
	}

	return settlement.amount;
};

export const getServiceFeeText = (userContext, outlet, defaultText = 'Service Fee') => {
	if (userContext)
		return userContext.data?.selectedOutlet?.settings?.serviceFeeText || defaultText;

	return outlet?.settings?.serviceFeeText || defaultText;
};

export const getModifierPrepStations = orderItemModifier => {
	let prepStations = [];

	if (orderItemModifier.customModifier)
		prepStations = orderItemModifier.customModifier.prepStations;

	if (orderItemModifier.modifierOverride)
		prepStations = orderItemModifier.modifierOverride.modifier.prepStations;

	if (orderItemModifier.modifier) prepStations = orderItemModifier.modifier.prepStations;

	return prepStations;
};

export const isRebelModifier = (orderItem, orderItemModifier, excludePrepStations) => {
	const orderItemPrepStations = orderItem.product.prepStations.filter(
		ps => excludePrepStations.findIndex(eps => eps.id === ps.id) === -1
	);

	const orderItemModifierPrepStations = getModifierPrepStations(orderItemModifier);

	return orderItemModifierPrepStations.some(
		modifierPrepStation =>
			!orderItemPrepStations.some(oips => oips.id === modifierPrepStation.id)
	);
};

export const downloadCsv = (rows, fileName, onSuccess = () => {}) => {
	const datas = rows.slice(1).map(row =>
		row.map(column => {
			if (column.toString().search(',') > -1) return `"${column}"`;
			return column;
		})
	);

	toCsv({
		columns: rows[0],
		datas,
	}).then(csv => {
		const blob = new Blob([csv], {
			type: 'text/csv;charset=utf-8',
		});
		FileSaver.saveAs(blob, fileName);
		onSuccess();
	});
};

export const isModuleEnabled = (userContext, module) =>
	!!(userContext?.data?.user?.company?.modules || []).find(m => m.value === module);

export const getQueryBuildConfig = () => ({
	...AntdConfig,
	settings: { ...AntdConfig.settings, removeEmptyGroupsOnLoad: false },
});

export const reportCheckChildren = tree => {
	if (!tree) return null;

	if (tree.children1 && !Array.isArray(tree.children1))
		return {
			...tree,
			children1: Object.keys(tree.children1).map(id => {
				const item = { id, ...tree.children1[id] };

				if (item.children1) return reportCheckChildren(item);

				return item;
			}),
		};

	return tree;
};

export const hasReportOldField = (tree, fields) => {
	if (!tree || !tree.children1) return false;

	if (!Array.isArray(tree.children1)) tree = reportCheckChildren(tree);

	return tree.children1.some(child => {
		if (child.children1) return hasReportOldField(child, fields);
		return !fields[child.properties.field];
	});
};

export const parseListContainerCustomFilter = customFilter => {
	const filter = {};

	const { component, fieldName, value, multiple, valueKey } = customFilter;

	if (component === filterComponents.DATE_RANGE && value) {
		const start = dateFormatter(value.startDate, false);

		const end = dateFormatter(value.endDate, false);

		if (start === end) {
			filter[fieldName] = moment(value.startDate).format('YYYY-MM-DD');

			return filter;
		}

		filter[`${fieldName}[after]`] = dateFormatter(value.startDate, false);

		filter[`${fieldName}[before]`] = dateFormatter(value.endDate, false);

		return filter;
	}

	if (component === filterComponents.SELECTS || component === filterComponents.ASYNC_SELECTS) {
		const _valueKey = valueKey || 'id';

		if (multiple && value.length > 0) filter[`${fieldName}[]`] = value.map(v => v[_valueKey]);

		if (!multiple && value && value[_valueKey] !== 0) filter[fieldName] = value[_valueKey];

		return filter;
	}

	if (component === filterComponents.RADIO && value) {
		filter[fieldName] = value;
	}

	return filter;
};

export const maskString = (string, show = 4) => {
	if (string.length <= 4) return string;
	return `${'x'.repeat(string.length - show)}${string.substring(string.length - show)}`;
};

export const getVesselVehicleInfo = data => {
	return data
		? `${data.name} (${inchToFeet(data.loa)} x ${inchToFeet(data.beam)} x ${inchToFeet(
				data.height
		  )})`
		: '';
};

export const getMapItemColor = (
	unitMapUnit,
	unitState,
	displayOption,
	displayOptions,
	searchData,
	availableProducts,
	isSearched,
	availability,
	module
) => {
	let warnValue;
	let maxValue;
	let minValue;
	let searchValue;

	const { reservationItems, alert } = unitState;

	switch (unitMapUnit.unit.bookingType.bookBy.value) {
		case bookBies.LENGTH:
			minValue = unitMapUnit.unit.minLoa;
			warnValue = unitMapUnit.unit.warnLoa;
			maxValue = unitMapUnit.unit.maxLoa;
			searchValue = searchData.loa;
			break;
		case bookBies.BEAM:
			minValue = unitMapUnit.unit.minBeam;
			warnValue = unitMapUnit.unit.warnBeam;
			maxValue = unitMapUnit.unit.maxBeam;
			searchValue = searchData.beam;
			break;
		case bookBies.SQ_FEET:
			minValue = unitMapUnit.unit.minSqft;
			warnValue = unitMapUnit.unit.warnSqft;
			maxValue = unitMapUnit.unit.maxSqft;
			searchValue = searchData.sqft;
			break;
		default:
			minValue = 0;
			warnValue = 2;
			maxValue = 2;
			searchValue = 1;
	}

	if (displayOption === displayOptions.OCCUPANCY) {
		if (alert) return mapItemColors.ALERT.color;

		if (
			reservationItems.length > 0 &&
			reservationItems[0].customMapColor &&
			(reservationItems[0].unitBlockoutId || reservationItems[0].customReservationStatus) &&
			displayOption === displayOptions.OCCUPANCY
		)
			return reservationItems[0].customMapColor;

		const reservedCapacity = reservationItems.reduce(
			(partialSum, item) => partialSum + item.capacity,
			0
		);

		const unitCapacity =
			unitMapUnit.unit.bookingType.bookBy.value === bookBies.UNIT
				? 1
				: unitMapUnit.unit.capacity;

		if (unitCapacity <= reservedCapacity) {
			if (reservationItems.some(i => i.temporaryDeparted))
				return mapItemColors.TEMPORARY_DEPARTED.color;

			if (reservationItems.some(i => i.isNext)) return mapItemColors.NEW_ARRIVAL.color;

			if (
				unitMapUnit.unit.bookingType.bookBy.value === bookBies.UNIT &&
				module !== modules.BOOKINGS &&
				(reservationItems[0].bookingPeriod === bookingPeriods.HOURLY ||
					reservationItems[0].bookingPeriod === bookingPeriods.NIGHTLY ||
					reservationItems[0].bookingPeriod === bookingPeriods.DAILY)
			)
				return mapItemColors.TRANSIENT.color;

			return reservationItems.some(i => i.hasDeparture)
				? mapItemColors.DEPARTING.color
				: mapItemColors.OCCUPIED.color;
		}

		if (warnValue && reservedCapacity >= warnValue) return mapItemColors.WARNING.color;

		if (unitState.stolenBeam && unitState.beam <= unitState.stolenBeam)
			return mapItemColors.OCCUPIED.color;

		return mapItemColors.VACANT.color;
	}

	if (
		displayOption === displayOptions.CUSTOMER_BALANCE &&
		unitMapUnit.unit.bookingType.bookBy.value === bookBies.UNIT
	) {
		const balanceDue = reservationItems.reduce(
			(partialSum, item) => partialSum + item.customerBalance,
			0
		);

		const balanceOverDue = reservationItems.reduce(
			(partialSum, item) => partialSum + item.customerOverDueBalance,
			0
		);

		if (balanceDue <= 0) return mapItemColors.PAID_IN_FULL.color;

		if (balanceOverDue > 0) return mapItemColors.PAST_DUE_BALANCE.color;

		if (balanceDue > 0) return mapItemColors.OPEN_BALANCE;
	}

	if (!isSearched) return unitMapUnit.unit.mapColor;

	if (!minValue) minValue = 0;

	if (!maxValue) maxValue = unitMapUnit.unit.capacity;

	let occupancy = 0;

	Object.keys(availability).forEach(productId => {
		if (availability[productId][unitMapUnit.unit.id])
			occupancy = availability[productId][unitMapUnit.unit.id];
	});

	const totalOccupancy = occupancy + searchValue;

	if (availableProducts.length === 0 || searchValue < minValue || totalOccupancy > maxValue) {
		return mapItemColors.OCCUPIED.color;
	}

	if (totalOccupancy < warnValue) return mapItemColors.VACANT.color;

	return mapItemColors.WARNING.color;
};

export const getLatestOutgoingMessageDate = data => {
	if (!data?.inquiry?.messages) return null;

	const outgoingMessagesDates = data.inquiry.messages.filter(m => !m.isIncoming).map(m => m.date);

	if (outgoingMessagesDates.length === 0) return null;

	return outgoingMessagesDates.sort()[outgoingMessagesDates.length - 1];
};

export const getParam = (key, url = window.location.href) => {
	const urlObject = new URL(url);

	return urlObject.searchParams.get(key);
};

export const phoneNumberParser = value => {
	if (!value || value.length === 0) return '';

	let result = process.env.REACT_APP_PHONE_FORMAT.replaceAll('9', '_');

	[...value].forEach(char => {
		if (char >= '0' && char <= '9') result = result.replace('_', char);
	});

	return result;
};

export const getEntityId = (url = window.location.pathname) => {
	if (url.indexOf('/') === -1) return null;

	const urlComponents = url.split('/');

	const id = urlComponents.pop();

	return parseInt(id, 10);
};
